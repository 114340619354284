import React, { useState } from 'react';
import { withRouter } from 'react-router';

import dayjs from 'dayjs';

import { getExtraInformation, getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Divider from 'components/design-system/Divider';
import TwoLine from 'components/design-system/ProfileType';
import IconButton from 'components/shared/Button/IconButton';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import BlockerListModal from './BlockerListModal';
import CircleTrack from './CircleTrack';

function ListObjectiveFormalReview(props) {
  const {
    cycleName,
    phase,
    cycleId,
    statePhase,
    godView,
    history,
    cycleState,
    inProgressDeliverable,
    lockAnswerCycle,
    assignment,
    allowViewTargetResult
  } = props;

  const {
    id,
    state,
    target,
    targetPlacementId,
    tracks,
    phaseDependencies,
    cycleDependencies,
    assignmentDependencies,
    endsAt
  } = assignment;

  const [showModal, setShowModal] = useState(false);

  const nowDate = dayjs();
  const endsAtDate = dayjs(endsAt);
  const isSubmitted = state === 'done';

  const isOverDeadline = endsAt ? nowDate > endsAtDate : false;

  const showEmployeeReviewResult =
    allowViewTargetResult &&
    (inProgressDeliverable || cycleState == 'done' || isOverDeadline);

  // on case reschedule (phase state will be done) but user still can do a review if assignment ends at > now
  // on no scheduled (no ends at) assignment user can only do a review if phase state is still in progress
  const canReview =
    (statePhase == 'done' && nowDate < endsAtDate) ||
    statePhase == 'in_progress';

  const getActionTitle = () => {
    let text = '';

    if (phase === 'calibration' && statePhase === 'done') {
      text = 'Calibrate Ended';
    } else if (phase === 'calibration' && statePhase !== 'done') {
      text = 'Calibrate Now';
    } else if (state === 'not_started') {
      text = 'Waiting On';
    } else if (canReview && state == 'in_progress') {
      text = 'Perform Review';
    } else if (canReview && state === 'done' && !lockAnswerCycle) {
      text = 'Edit Review';
    } else {
      text = 'View My Response';
    }

    return getObjectiveLocale(text);
  };

  const handleClickPhaseButton = () => {
    if (state === 'not_started') {
      setShowModal(true);
    } else if (phase === 'calibration' && godView) {
      history.push({
        pathname: `/reviews/${cycleId}/calibrations/${cycleName}`,
        query: { view: 'admin' }
      });
    } else if (phase === 'calibration' && !godView) {
      history.push({
        pathname: `/appraisals/cycle/${cycleId}/calibration`
      });
    } else if (canReview && state == 'in_progress') {
      history.push({
        pathname: `/reviews/assignments/${id}`
      });
    } else if (canReview && state == 'done' && !lockAnswerCycle) {
      history.push({
        pathname: `/reviews/assignments/${id}`
      });
    } else {
      history.push({
        pathname: `/appraisals/cycle/${cycleId}/assignment/${id}`
      });
    }
  };

  const getStatusComponent = (target) => {
    return !target?.isCurrentPlacement ? (
      <div className="absolute right-0 bottom-0 bg-n-000 rounded-full">
        <SVGIcon
          size={16}
          iconName="icon-settings_backup_restore"
          fillColor="var(--g-500)"
        />
      </div>
    ) : null;
  };

  return (
    <>
      <div
        className={`row-list-formal-review relative ${
          isSubmitted ? 'done' : 'in_progress'
        }`}
        data-cy={id ? `assignment-list-${id}` : `calibration-${cycleId}`}
      >
        <div className="flex relative items-center w-full min-w-[200px] mr-[24px] overflow-hidden">
          <TwoLine
            user={target}
            title={target.name}
            subtitle={getExtraInformation(target?.title, target?.subtitle)}
            statusComponent={getStatusComponent(target)}
            size={32}
            customClass="items-center"
            titleClassName="typography-h400 text-n-900 leading-[24px]"
            subtitleClassName="typography-h100 text-n-800"
          />
        </div>

        <CircleTrack tracks={tracks} />

        <div className="flex items-center w-full min-w-[144px]">
          <div className="flex items-center absolute right-[16px]">
            <Button.Tertiary
              datacy={`button-phase-${phase}`}
              customClass={`${state} whitespace-nowrap`}
              onClick={handleClickPhaseButton}
              disabled={phase === 'calibration' && cycleState == 'done'}
            >
              {state === 'not_started' && (
                <SVGIcon
                  customClass="mr-[4px]"
                  size={24}
                  iconName="icon-lock_outline"
                  fillColor="var(--base-600)"
                />
              )}
              {getActionTitle()}
            </Button.Tertiary>
            {showEmployeeReviewResult && (
              <>
                <div className="h-[24px] mx-[16px]">
                  <Divider direction="vertical" base={false} />
                </div>
                <TooltipContainer
                  show
                  align="right"
                  text={getObjectiveLocale(
                    "View this employee's review result"
                  )}
                >
                  <IconButton
                    customClass="px-[6px] py-[6px]"
                    datacy="view-reviewee-result"
                    isTertiary
                    onClick={() => {
                      history.replace(
                        `/reviews/result/target/${target.id}/placement/${targetPlacementId}/cycle/${cycleId}?phase=self_review&assignmentId=${id}`
                      );
                    }}
                  >
                    <SVGIcon
                      size="24"
                      fillColor="var(--n-600)"
                      iconName="icon-description"
                    />
                  </IconButton>
                </TooltipContainer>
              </>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <BlockerListModal
          cycleName={cycleName}
          phaseDependencies={phaseDependencies}
          cycleDependencies={cycleDependencies}
          assignmentDependencies={assignmentDependencies}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
}

export default withRouter(ListObjectiveFormalReview);

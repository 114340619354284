import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import ctl from '@netlify/classnames-template-literals';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InlineDialogSelectFollower from 'components/inline-dialog/InlineDialogSelectFollower';
import Avatar from 'components/shared/Avatar';

import Delete from 'assets/create-objective/delete-grey';
import OwnerGroup from 'assets/create-objective/owner-group';
import DefaultObjectiveProfile from 'assets/v2/ico-default-user';

function CreateObjectiveCard({
  children,
  isParent,
  inputValue,
  id,
  level,
  fakeParentId,
  objective,
  errorWithMessage,
  type,
  setActiveLevel,
  changeValue,
  addChild,
  deleteGoal,
  lists,
  autoFocus = true,
  inputCy
}) {
  const { config } = useUser();
  const { params } = useRouteMatch();
  const assignOwnerPermission = config.permissions?.goalInvolvementsOwnerAssign;
  const [showIcon, setShowIcon] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  let parentNameIsEmpty =
    objective.name === '' &&
    objective?.children?.some((child) => child.name !== '');
  let isError =
    errorWithMessage &&
    errorWithMessage?.isError &&
    errorWithMessage?.errors?.[0] !== 'Children is invalid';

  let ownerProfile;
  let owner;
  let ownerName;
  if (objective.involvements) {
    owner = objective.involvements.filter((user) => user.role == 'assignee');
    if (owner.length > 1) {
      ownerProfile = OwnerGroup;
    } else if (owner.length === 1) {
      ownerProfile = owner[0].user.profilePicture;
      ownerName = owner[0].user.name;
    } else {
      ownerProfile = DefaultObjectiveProfile;
    }
  }

  if (location.pathname.includes('/team') && objective.fakeParentId === null) {
    ownerProfile = objective.teamPicture;
  }

  let placeholder;

  if (location.pathname.includes('toteam')) {
    placeholder == 'Add goal team';
  } else {
    const tempGoal = type === 'team' || type === 'goal' ? 'Goal' : type;
    if (isParent) {
      placeholder =
        type === 'team'
          ? 'Team Name'
          : `${getObjectiveLocale('Create ' + tempGoal)}`;
    } else {
      placeholder =
        type === 'team'
          ? `${getObjectiveLocale('Create ' + tempGoal)}`
          : `${getObjectiveLocale(`Add Sub ${tempGoal}`)}`;
    }
  }

  const showDropdownOwner = () => {
    setShowIcon(true);
    setShowDropdown(true);
    addChild(id, level, fakeParentId, inputValue, objective);
  };

  // Implement InlineDialogSelectFollower component
  let selectedOwner = objective.involvements.filter(
    ({ role }) => role == 'assignee'
  );

  function handleChangeInvolvements(involvements, type) {
    let newInvolvements = objective.involvements.filter(
      ({ role }) => role !== type
    );
    newInvolvements.push(...involvements);

    if (type == 'assignee' && params?.type !== 'team') {
      if (involvements.length > 0 && involvements[0]?.manager) {
        let newManager = [
          {
            user: involvements[0].manager,
            role: 'assigner',
            userId: involvements[0].manager.id,
            visible: true
          }
        ];
        newInvolvements = newInvolvements.filter(
          ({ role, userId }) =>
            role !== 'assigner' && userId !== newManager[0].userId
        );
        newInvolvements.push(...newManager);
      } else {
        newInvolvements = newInvolvements.filter(
          (involvement) => involvement.role !== 'assigner'
        );
      }
    }

    newInvolvements = newInvolvements.map((involvement) => {
      const { manager, ...involvementData } = involvement;
      return involvementData;
    });

    changeValue(id, { involvements: newInvolvements });
  }

  let selectedReviewer = objective.involvements.filter(
    ({ role }) => role == 'assigner'
  );
  let selectedFollower = objective.involvements.filter(
    ({ role }) => role == 'follower'
  );

  let excludeOwner = [...selectedReviewer, ...selectedFollower].map(
    ({ userId }) => userId
  );

  const inputCN = ctl(`
    h-[32px] py-[6px] px-[12px]
    border-[1px] border-solid border-n-000
    w-full text-[16px] placeholder-n-600
    focus:rounded-[3px] focus:border-[1px]
    focus:border-solid focus:border-base-600
    focus:outline-none
    outline-none
  `);

  const errorMessageCN = ctl(`
    text-r-600 top-[38px] left-[66px] font-normal tracking-normal
    leading-[20px]
    absolute
  `);

  const listCN = ctl(`
    list-none
    m-0
    pl-[0px]
    relative

    before:content-['']
    before:block
    before:w-[0px]
    before:absolute
    before:top-[-15px]
    before:bottom-[20px]
    before:left-[24px]
    before:border-n-400
    before:border-0
    before:border-l
    before:border-dashed
  `);

  const parentCN = ctl(`
    relative

    ${
      isParent
        ? `
      ml-[0px]
      mt-[0px]
    `
        : `
      ml-[72px]
      mt-[24px]
    `
    }

    ${
      level > 0 &&
      `
      before:content-['']
      before:block
      before:w-[43px]
      before:absolute
      before:top-[20px]
      before:left-[-45px]
      before:border-n-400
      before:border-0
      before:border-b
      before:border-dashed
    `
    }
  `);
  return (
    <li className={parentCN} data-cy={`create-objective-list-${id}`}>
      <div className="flex items-center h-[40px] relative">
        <div className="inline-block p-[6px] mr-[17px]">
          <Avatar
            name={ownerName}
            src={ownerProfile}
            style={!assignOwnerPermission ? { pointerEvents: 'none' } : {}}
            onClick={() => showDropdownOwner()}
          />
          {showDropdown && (
            <InlineDialogSelectFollower
              selectMultiple={true}
              type={'assignee'}
              selectedValue={selectedOwner}
              handleChange={handleChangeInvolvements}
              excludeValue={excludeOwner}
              dropdownPosition={'down'}
              showDropdown={showDropdown}
              setShowDropdown={(bool) => setShowDropdown(bool)}
              objectiveType={objective.isProject ? 'project' : objective.type}
            />
          )}
        </div>
        <div
          className="w-full mr-[16px]"
          onFocus={() => setShowIcon(true)}
          onBlur={() => setShowIcon(false)}
          data-cy="create-objective-card-container"
        >
          <input
            type="text"
            placeholder={placeholder}
            id={id}
            value={inputValue}
            className={`${inputCN} ${
              parentNameIsEmpty || isError ? 'has-error' : ''
            }`}
            onFocus={() => {
              addChild(id, level, fakeParentId, inputValue, objective);
              setActiveLevel && setActiveLevel(level);
            }}
            onChange={(e) => changeValue(id, { name: e.target.value })}
            onBlur={() => inputValue == '' && deleteGoal(id, level)}
            autoFocus={autoFocus && objective.id == '1-1'}
            data-cy={inputCy ? inputCy : `create-objective-${level}`}
          />
        </div>

        {showIcon && (
          <div className="action-button-container flex flex-row">
            <div>
              <div className="tooltip whitespace-nowrap px-[4px]">
                {`${getObjectiveLocale('Delete')} ${getObjectiveLocale(
                  level > 0 ? 'Sub Goal' : 'Goal'
                )}`}
              </div>
              {lists.length > 1 && (
                <img
                  className="w-[24px] h-[24px] mr-[8px] last:mr-[0px]"
                  src={Delete}
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => deleteGoal(id, level)}
                />
              )}
            </div>
          </div>
        )}
        {isError && (
          <p className={errorMessageCN}>
            {getObjectiveLocale(errorWithMessage?.errors?.[0]) || ''}
          </p>
        )}
      </div>

      <ul className={listCN}>{children}</ul>
    </li>
  );
}

export default CreateObjectiveCard;

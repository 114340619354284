"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSelected = exports.isAllSelected = exports.selectUser = exports.selectAllCheckbox = exports.setSelectedUser = void 0;
const TableSelectUtils_1 = require("utils/TableSelectUtils");
const setSelectedUser = (set, selectAll, include, exclude) => {
    return set({
        selectAll: selectAll,
        include: include,
        exclude: exclude
    });
};
exports.setSelectedUser = setSelectedUser;
const selectAllCheckbox = (get, set, filter, allIds, totalUsers) => {
    const { selectAll, include, exclude } = get();
    const _isAllSelected = isAllSelected(get, allIds, totalUsers, filter);
    // 1. TANPA FILTER
    if ((0, TableSelectUtils_1.isFilterEmpty)(filter)) {
        return set({ selectAll: !_isAllSelected, include: [], exclude: [] });
    }
    // 2. PAKAI FILTER
    if (!(0, TableSelectUtils_1.isFilterEmpty)(filter)) {
        // A. checkbox all tanpa filter => checkbox all dengan filter
        if (selectAll) {
            return set(!_isAllSelected
                ? // check exclude berkurang
                    { exclude: exclude === null || exclude === void 0 ? void 0 : exclude.filter((e) => !allIds.includes(e.id)) }
                : // uncheck exclude bertambah
                    { exclude: [...(exclude || []), ...allIds.map((e) => ({ id: e }))] });
        }
        // B. checkbox all dengan filter => checkbox all dengan/tanpa filter
        return set(!_isAllSelected
            ? // check include bertambah
                { include: [...(include || []), ...allIds.map((e) => ({ id: e }))] }
            : // uncheck include berkurang
                { include: include === null || include === void 0 ? void 0 : include.filter((e) => !allIds.includes(e.id)) });
    }
};
exports.selectAllCheckbox = selectAllCheckbox;
const selectUser = (get, set, selectedId) => {
    const { selectAll, include, exclude } = get();
    const isSelected = (include === null || include === void 0 ? void 0 : include.find((e) => (e === null || e === void 0 ? void 0 : e.id) == selectedId)) ||
        (exclude === null || exclude === void 0 ? void 0 : exclude.find((e) => (e === null || e === void 0 ? void 0 : e.id) == selectedId));
    // A. checkbox all tanpa filter => check / uncheck row
    if (selectAll)
        return set({
            exclude: isSelected
                ? // uncheck row
                    exclude === null || exclude === void 0 ? void 0 : exclude.filter((e) => e.id != selectedId)
                : // check row
                    [...(exclude || []), { id: selectedId }]
        });
    // B. checkbox all dengan filter => check / uncheck row
    return set({
        include: isSelected
            ? // uncheck row
                include === null || include === void 0 ? void 0 : include.filter((e) => e.id != selectedId)
            : // check row
                [...(include || []), { id: selectedId }]
    });
};
exports.selectUser = selectUser;
const isAllSelected = (get, allIds, totalUsers, filter) => {
    const { selectAll, include, exclude } = get();
    const total = (0, TableSelectUtils_1.isFilterEmpty)(filter) ? totalUsers : allIds.length;
    const totalExcludeUsers = (0, TableSelectUtils_1.isFilterEmpty)(filter)
        ? exclude === null || exclude === void 0 ? void 0 : exclude.length
        : exclude === null || exclude === void 0 ? void 0 : exclude.filter((e) => allIds.includes(e.id)).length;
    const totalSelectedUsers = include === null || include === void 0 ? void 0 : include.filter((e) => allIds.includes(e.id)).length;
    if (total !== 0) {
        return selectAll ? totalExcludeUsers == 0 : totalSelectedUsers == total;
    }
    return false;
};
exports.isAllSelected = isAllSelected;
const isSelected = (get, selectedId) => {
    const { selectAll, include, exclude } = get();
    const isChecked = selectAll
        ? !(exclude === null || exclude === void 0 ? void 0 : exclude.find((e) => (e === null || e === void 0 ? void 0 : e.id) == selectedId))
        : include === null || include === void 0 ? void 0 : include.find((e) => (e === null || e === void 0 ? void 0 : e.id) == selectedId);
    return isChecked;
};
exports.isSelected = isSelected;

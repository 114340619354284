import React from 'react';

import find from 'lodash/find';

import { phaseShortName } from 'utils/FormalReview';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import SVGIcon from 'components/shared/SVGIcon';

const setTooltip = (e, visibility) => {
  let tooltip = document.getElementById('tooltip-hc');
  tooltip.style.visibility = visibility;
  tooltip.style.left = e.clientX - 450 + 'px';
  tooltip.style.top = e.clientY - 205 + 'px';
};

const ChartItem = ({
  chart,
  bgInProgress,
  bgCompleted,
  setModalParams,
  phase
}) => {
  const completed = chart?.assignmentProgress?.done;
  const inProgress = chart?.assignmentProgress?.inProgress;
  const isOneProgress =
    completed?.percentage === 100 || completed?.percentage === 0;
  return (
    <div
      className={`flex w-full rounded-[4px] cursor-pointer ${
        completed?.percentage === 100 ? bgCompleted : bgInProgress
      }`}
      onMouseMove={(e) => setTooltip(e, 'visible')}
      onMouseOver={(e) => setTooltip(e, 'visible')}
      onMouseOut={(e) => setTooltip(e, 'hidden')}
      onClick={(e) => {
        e.stopPropagation();
        setModalParams({
          phase: chart?.type,
          progress: 'In Progress',
          count: inProgress?.count,
          userIds: inProgress?.userIds
        });
      }}
    >
      {completed?.count > 0 && (
        <div
          className={`completed bg-g-600 h-[20px] flex items-center rounded-l-[4px]
            ${bgCompleted}`}
          style={{ width: completed?.percentage + '%' }}
          onClick={(e) => {
            e.stopPropagation();
            setModalParams({
              phase: chart?.type,
              progress: 'Completed',
              count: completed?.count,
              userIds: completed?.userIds
            });
          }}
        >
          <span
            className="ml-[12px] typography-h200 text-n-000"
            data-cy="completed-desc"
          >
            {`${completed?.count || ''}`}
            <span className="typography-h100 ml-[4px]">{`(${
              completed?.percentage || ''
            }%)`}</span>
          </span>
        </div>
      )}

      <div
        className={`gap bg-n-000 h-[20px] w-[2px] ${
          isOneProgress ? 'invisible' : ''
        }`}
      />
      {inProgress?.percentage !== 0 && (
        <span
          className="ml-[12px] typography-h200 flex items-center"
          data-cy="inprogress-desc"
        >
          {`${getNumberLocale(inProgress?.count) || ''}`}
          <span className="typography-h100 ml-[4px]">{`(${
            getNumberLocale(inProgress?.percentage) || ''
          }%)`}</span>
        </span>
      )}
    </div>
  );
};

const getSkeletonLoading = () => {
  const dummyData = ['dummy', 'dummy', 'dummy', 'dummy', 'dummy'];
  return (
    <div className="w-full">
      {dummyData.map((val, index) => (
        <div key={index} index={index} className="flex mb-[16px]">
          <div className="mr-[16px] width-full">
            <Shimmer
              customClass="rounded"
              widthRandomness={0.25}
              width="100px"
              height="16px"
            />
          </div>
          <div className="w-[400px]">
            <Shimmer
              customClass="rounded"
              widthRandomness={0.25}
              width="500px"
              height="16px"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const HighChart = ({
  chartData,
  setModalParams,
  isLoading,
  dataCy = 'high-chart'
}) => {
  const legends = [
    {
      color: 'var(--g-600)',
      name: 'Completed',
      background: 'bg-g-600',
      id: 'completed'
    },
    {
      color: 'var(--n-400)',
      name: 'In Progress',
      background: 'bg-n-400 ',
      id: 'in_progress'
    }
  ];

  const bgCompleted = find(legends, ['id', 'completed']).background;
  const bgInProgress = find(legends, ['id', 'in_progress']).background;

  return (
    <>
      <div className="flex h-[32px] mt-[24px] mb-[22px] items-center">
        {legends.map((legend, index) => (
          <div
            key={index}
            className="flex items-center typography-h100 text-n-600 mr-[16px]"
          >
            <SVGIcon iconName="icon-stop" fillColor={legend.color} size="16" />
            <span className="ml-[8px]">{legend.name}</span>
          </div>
        ))}
      </div>
      <div className="relative" data-cy={dataCy}>
        {!isLoading
          ? chartData?.map((chart, index) => {
              // const descChart = getObjectiveLocale(chart.type.replace(/_|review|selections|/g, ""))
              // const desc = capitalize(descChart.replace(/_/g, " "))
              const desc = getObjectiveLocale(phaseShortName(chart.type));
              return (
                <div
                  key={index}
                  className="flex items-center mb-[16px]"
                  data-cy={`chart-container-${chart?.id}`}
                >
                  <span
                    className="typography-h100 mr-[16px] min-w-[50px] w-[50px] text-right capitalize"
                    data-cy="chart-type"
                  >
                    {desc}
                  </span>
                  <ChartItem
                    chart={chart}
                    bgCompleted={bgCompleted}
                    bgInProgress={bgInProgress}
                    phase={chart?.type}
                    setModalParams={setModalParams}
                  />
                </div>
              );
            })
          : getSkeletonLoading()}
        <div
          id="tooltip-hc"
          className="absolute invisible rounded-[4px] bg-n-900 text-n-000 typography-h100 py-[4px] px-[12px]"
        >
          {getObjectiveLocale('Click to view detail')}
        </div>
      </div>
    </>
  );
};

export default HighChart;

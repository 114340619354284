import React, { useEffect, useState } from 'react';

import {
  getFormalReviewAnswers,
  getGeneralQuestionnaireResult,
  getRevieweeFeedbackGoals,
  getRevieweeFeedbackTracks
} from 'client/FormalReviewClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import EmployeeAvatarNameTitle from 'components/shared/coaching/EmployeeAvatarNameTitle.js';

import './RevieweeFeedback.scss';
import RevieweeFeedbackList from './RevieweeFeedbackList';

const RevieweeFeedback = ({
  setOverlaySidebar,
  setCurrentRevieweeFeedbackType
}) => {
  let [{ assignmentId, cycle, involvedUser }, _] = useFormalReview();
  const { config } = useUser();
  const [loading, setLoading] = useState(false);
  const [managerTracks, setManagerTracks] = useState([]);
  const [manager, setManager] = useState(null);
  const [activeTrack, setActiveTrack] = useState('');

  // Content Type
  const [competencyAssessmentData, setCompetencyAssessmentData] = useState([]);
  const [valueScoringData, setValueScoringData] = useState([]);
  const [goalScoringData, setGoalScoringData] = useState(null);
  const [taskScoringData, setTaskScoringData] = useState(null);
  const [recognitionFeedbackData, setRecognitionFeedbackData] = useState([]);
  const [valuesRecognitionFeedbackData, setValuesRecognitionFeedbackData] =
    useState([]);
  const [generalQuestionnaireData, setGeneralQuestionnaireData] = useState([]);
  const [loadingTrack, setLoadingTrack] = useState(false);

  const getData = async () => {
    const { data } = await getRevieweeFeedbackTracks(cycle.id, assignmentId);
    if (data) {
      setManager(data.manager);
      setManagerTracks(data.tracks);
      setLoading(false);
    }
  };

  const onSetActiveTrack = async (type, mechanism, e) => {
    if (type === activeTrack) {
      // Close Dropdown
      setActiveTrack('');
      setCurrentRevieweeFeedbackType('');
    } else {
      // Show Dropdown
      setActiveTrack(type);
      setCurrentRevieweeFeedbackType(type);
    }

    let targetId = involvedUser.target.id;
    const sortingParams =
      type !== 'general_questionnaire'
        ? {
            sortColumn: 'id',
            sortDirection: 'asc'
          }
        : {};

    if (type == 'goals_scoring' || type == 'tasks_scoring') {
      sortingParams.sortColumn =
        config?.defaultObjectiveSorting?.sortColumn || 'due_date';
      sortingParams.sortDirection =
        config?.defaultObjectiveSorting?.sortDirection || 'asc';
    }

    let query = {
      targetId
    };

    // Competency Assessment
    if (
      type === 'competencies_scoring' &&
      competencyAssessmentData.length === 0
    ) {
      setLoadingTrack(type);
      const { data } = await getFormalReviewAnswers(
        cycle.id,
        type,
        'manager_review',
        query
      );
      data && setCompetencyAssessmentData(data);
      setLoadingTrack(false);
    }

    // Value Scoring
    if (type === 'values_scoring' && valueScoringData.length === 0) {
      setLoadingTrack(type);
      const { data } = await getFormalReviewAnswers(
        cycle.id,
        type,
        'manager_review',
        query
      );
      data && setValueScoringData(data);
      setLoadingTrack(false);
    }

    // Sasaran Bisnis
    if (type === 'goals_scoring' && !goalScoringData) {
      setLoadingTrack(type);
      const goalsParams = {
        limit: 15,
        reviewsVisibility: 1,
        periodBegin: cycle.reviewedPeriodStartsAt,
        periodEndBefore: cycle.reviewedPeriodEndsAt,
        state: ['running', 'completed', 'reviewed'],
        type: ['annual_goal', 'goal'],
        parentNotAssignedTo: involvedUser.target.id,
        sortColumn: config?.defaultObjectiveSorting?.sortColumn || 'due_date',
        sortDirection: config?.defaultObjectiveSorting?.sortDirection || 'asc'
      };

      const tempGoalScoringData = {
        questionnaireAnswers: [],
        answersData: null,
        objectives: []
      };

      const { data: questionnaireAnswers } =
        await getGeneralQuestionnaireResult(
          cycle.id,
          'manager_review',
          'goals_scoring',
          query
        );
      if (questionnaireAnswers) {
        tempGoalScoringData.questionnaireAnswers = questionnaireAnswers;
      }

      if (
        mechanism == 'each' ||
        mechanism == 'specific_attribute' ||
        mechanism == 'suggested_ongoing'
      ) {
        const { data } = await getFormalReviewAnswers(
          cycle.id,
          'goals_scoring',
          'manager_review',
          sortingParams
        );
        tempGoalScoringData.answersData = data;
      } else {
        const { data } = await getRevieweeFeedbackGoals(
          cycle.id,
          assignmentId,
          goalsParams
        );
        tempGoalScoringData.objectives = data;
      }

      setGoalScoringData(tempGoalScoringData);
      setLoadingTrack(false);
    }

    if (type === 'tasks_scoring' && !taskScoringData) {
      setLoadingTrack(type);
      const tasksParams = {
        limit: 15,
        reviewsVisibility: 1,
        periodBegin: cycle.reviewedPeriodStartsAt,
        periodEndBefore: cycle.reviewedPeriodEndsAt,
        state: ['running', 'completed', 'reviewed'],
        type: ['task'],
        parentNotAssignedTo: involvedUser.target.id
      };

      const tempTaskScoringData = {
        questionnaireAnswers: [],
        answersData: null,
        objectives: []
      };

      const { data: questionnaireAnswers } =
        await getGeneralQuestionnaireResult(
          cycle.id,
          'manager_review',
          'tasks_scoring',
          query
        );
      if (questionnaireAnswers) {
        tempTaskScoringData.questionnaireAnswers = questionnaireAnswers;
      }

      if (
        mechanism == 'each' ||
        mechanism == 'specific_attribute' ||
        mechanism == 'suggested_ongoing'
      ) {
        const { data } = await getFormalReviewAnswers(
          cycle.id,
          'tasks_scoring',
          'manager_review',
          sortingParams
        );
        tempTaskScoringData.answersData = data;
      } else {
        const { data } = await getRevieweeFeedbackGoals(
          cycle.id,
          assignmentId,
          tasksParams
        );
        tempTaskScoringData.objectives = data;
      }

      setTaskScoringData(tempTaskScoringData);
      setLoadingTrack(false);
    }

    // Kompetensi Perilaku
    if (
      type === 'competencies_recognition_feedback' &&
      recognitionFeedbackData.length === 0
    ) {
      setLoadingTrack(type);

      const tempRecognitionFeedbackData = {
        questionnaireAnswers: []
      };

      const { data: questionnaireAnswers } =
        await getGeneralQuestionnaireResult(
          cycle.id,
          'manager_review',
          'competencies_recognition_feedback',
          query
        );
      if (questionnaireAnswers) {
        tempRecognitionFeedbackData.questionnaireAnswers = questionnaireAnswers;
      }

      const { data } = await getFormalReviewAnswers(
        cycle.id,
        'competencies_recognition_feedback',
        'manager_review',
        query
      );
      data &&
        setRecognitionFeedbackData({ ...tempRecognitionFeedbackData, ...data });
      setLoadingTrack(false);
    }

    if (
      type === 'values_recognition_feedback' &&
      valuesRecognitionFeedbackData.length === 0
    ) {
      setLoadingTrack(type);
      const tempValuesRecognitionFeedbackData = {
        questionnaireAnswers: []
      };

      const { data: questionnaireAnswers } =
        await getGeneralQuestionnaireResult(
          cycle.id,
          'manager_review',
          'values_recognition_feedback',
          query
        );
      if (questionnaireAnswers) {
        tempValuesRecognitionFeedbackData.questionnaireAnswers =
          questionnaireAnswers;
      }

      const { data } = await getFormalReviewAnswers(
        cycle.id,
        'values_recognition_feedback',
        'manager_review',
        query
      );
      data &&
        setValuesRecognitionFeedbackData({
          ...tempValuesRecognitionFeedbackData,
          ...data
        });
      setLoadingTrack(false);
    }

    // Kompetensi Teknikal
    if (
      type === 'general_questionnaire' &&
      generalQuestionnaireData.length === 0
    ) {
      setLoadingTrack(type);
      const { data } = await getGeneralQuestionnaireResult(
        cycle.id,
        'manager_review',
        'general_questionnaire',
        query
      );
      if (data) setGeneralQuestionnaireData(data);
      setLoadingTrack(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="employee-feedback-container">
      <h5 className="summary-text"> {getObjectiveLocale('Summary')} </h5>
      <h6 className="reviewed-text">{getObjectiveLocale('Reviewed by')}:</h6>
      <div className="employee-container">
        {manager && <EmployeeAvatarNameTitle user={manager} />}
      </div>
      {loading && <LoadingComponent />}
      {!loading && managerTracks.length > 0 ? (
        managerTracks.map(({ name, type, template }) => {
          return (
            <RevieweeFeedbackList
              key={type}
              type={type}
              activeTrack={activeTrack}
              template={template}
              onSetActiveTrack={onSetActiveTrack}
              setOverlaySidebar={setOverlaySidebar}
              loadingTrack={loadingTrack}
              competencyAssessmentData={competencyAssessmentData}
              goalScoringData={goalScoringData}
              recognitionFeedbackData={recognitionFeedbackData}
              valuesRecognitionFeedbackData={valuesRecognitionFeedbackData}
              generalQuestionnaireData={generalQuestionnaireData}
              taskScoringData={taskScoringData}
              valueScoringData={valueScoringData}
              name={name}
            />
          );
        })
      ) : (
        <ListEmptyState
          title={getObjectiveLocale('No Summary Yet')}
          subtitle={getObjectiveLocale(
            'The employee has not started this review yet'
          )}
        />
      )}
    </div>
  );
};

export default RevieweeFeedback;

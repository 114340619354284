import React, { useRef, useState } from 'react';

import useClickOutside from 'hooks/useClickOutside';

import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

const DropdownGroupList = ({
  defaultValue,
  dataModel,
  isLoading,
  onScroll,
  setGroupOptionValue
}) => {
  const dropdownRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [valueDropdown, setValueDropdown] = useState(defaultValue);

  const changeValue = (value) => {
    setValueDropdown(value.name);
    setGroupOptionValue(value.id);
    setIsDropdownOpen(false);
  };

  useClickOutside(dropdownRef, () => setIsDropdownOpen(false));
  return (
    <div className="container-section-dropdown-behavior relative">
      <div onClick={() => setIsDropdownOpen(true)} className="button-dropdown">
        <p className="text-dropdown">{valueDropdown}</p>
        <SVGIcon
          iconName="icon-keyboard_arrow_down"
          fillColor="var(--base-600)"
          size={20}
        />
      </div>
      {isDropdownOpen && (
        <ul
          ref={dropdownRef}
          className="wrapper-behavior-dropdown"
          onScroll={(e) => onScroll(e)}
        >
          <div className="py-[10px] px-[16px] sticky-position"></div>
          {!isLoading &&
            dataModel.map((value, i) => (
              <div
                key={value.id}
                className="wrapper-dropdown-list"
                onClick={(e) => changeValue(value)}
              >
                <li className="py-[10px] px-[16px]">{value.name}</li>
              </div>
            ))}
          {isLoading && <LoadingComponent />}
        </ul>
      )}
    </div>
  );
};
export default DropdownGroupList;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import {
  getTeamsIdCMSCulture,
  patchTeamsIdCMSCulture,
  postTeamsCMSCulture
} from 'client/adminClient';
import useDebounce from 'hooks/useDebounce';
import useParticipantTeam from 'hooks/useParticipantTeam';
import { capitalize } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import ManageParticipant from 'components/admin/Participant-Team/ManageParticipant';
import NameDescriptionParticipant from 'components/admin/Participant-Team/NameDescriptionParticipant';
import Footer from 'components/shared/Footer';
import Loading from 'components/shared/LoadingComponent';
import VerticalStepsComponent from 'components/shared/VerticalStepsComponent';
import VerifySurvey from 'components/surveys/VerifySurvey';

const AddEditParticipantTeam = ({ route: { isReadOnly } }) => {
  const params = useParams();
  const history = useHistory();

  const {
    participantData,
    setParticipantData,
    totalParticipant,
    setTotalParticipant,
    initializeData
  } = useParticipantTeam((state) => ({
    participantData: state.participantData,
    setParticipantData: state.setParticipantData,
    totalParticipant: state.totalParticipant,
    initializeData: state.initializeData,
    setTotalParticipant: state.setTotalParticipant
  }));

  const debouncedParticipantData = useDebounce(participantData, 2000);
  const searchParams = new URLSearchParams(window.location.search);
  const stepParams = searchParams.get('step');
  const sourceParams = searchParams.get('source');
  const prevUrl = sourceParams
    ? localStorage.getItem('prevUrlRedirect')
    : '/participants';

  const [active, setActive] = useState(
    params?.id ? 'Verify' : 'Name & Description'
  );
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState([
    { name: 'Name & Description', completed: false, disabled: false },
    { name: 'Participants', completed: false, disabled: false },
    { name: 'Verify', completed: false, disabled: false }
  ]);

  const headerProps = {
    titlePage: params?.id ? participantData?.name : 'Create Participant Team',
    backToUrl: prevUrl,
    isHeaderComposer: true
  };

  const setCurrentStep = (name) => {
    setActive(name);
    if (!params.id) return;

    history.push(`/edit-participant/${params.id}?step=${name}`);
  };

  const checkAnswer = () => {
    const cloneSurvey = cloneDeep(participantData);
    const cloneSteps = cloneDeep(steps);
    cloneSteps[0].completed =
      !isEmpty(cloneSurvey?.name) && !isEmpty(cloneSurvey?.description);
    cloneSteps[1].completed =
      !isEmpty(cloneSurvey.includes) || cloneSurvey.selectAll;
    if (cloneSteps[0].completed && cloneSteps[1].completed) {
      cloneSteps[2].completed = true;
    } else {
      cloneSteps[2].completed = false;
    }

    setSteps(cloneSteps);
  };

  const checkButton = () => {
    return !steps.find((step) => step.name === active).completed;
  };

  const getCurrentData = async () => {
    setIsLoading(true);
    let { data } = await getTeamsIdCMSCulture(params?.id);
    if (data) {
      data.selectAll = false;
      data.includes = data?.users || [];
      data.excludes = [];
      setParticipantData(data);
      setTotalParticipant(data?.users?.length);
    }
    setIsLoading(false);
  };

  const handleNextPrevButton = (type) => {
    const currentIndex = steps.findIndex((step) => step.name === active);
    const index = type === 'next' ? currentIndex + 1 : currentIndex - 1;
    setActive(steps[index].name);
  };

  const submitParticipant = async () => {
    const updatedData = {
      team: participantData
    };

    if (params?.id) {
      const { isSuccess } = await patchTeamsIdCMSCulture(
        params?.id,
        updatedData
      );
      if (isSuccess) {
        history.replace(prevUrl);
      }
    } else {
      const { isSuccess } = await postTeamsCMSCulture(updatedData);
      if (isSuccess) {
        history.replace(prevUrl);
      }
    }
  };

  useEffect(() => {
    params?.id ? getCurrentData() : initializeData();
  }, [params?.id]);

  useEffect(() => {
    stepParams &&
      setActive(
        stepParams.includes('Name')
          ? 'Name & Description'
          : capitalize(stepParams)
      );
  }, [stepParams]);

  useEffect(() => {
    checkAnswer();
  }, [debouncedParticipantData]);

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="p-[0px] relative min-h-[calc(100vh-64px)] bg-n-000">
        <VerticalStepsComponent
          activeStep={active}
          setActiveStep={(name) => setCurrentStep(name)}
          steps={steps}
        />
        {isLoading ? (
          <Loading />
        ) : (
          <div className="ml-[256px] mb-[64px] px-[40px] pt-[24px]">
            {active === 'Name & Description' && (
              <NameDescriptionParticipant isReadOnly={isReadOnly} />
            )}
            {active === 'Participants' && (
              <ManageParticipant isReadOnly={isReadOnly} />
            )}
            {active === 'Verify' && (
              <VerifySurvey
                isReadOnly={isReadOnly}
                data={participantData}
                type="participant"
                totalParticipant={totalParticipant}
              />
            )}
            <Footer
              linkButton={true}
              customClass={active !== 'Participants' ? 'w-[820px]' : ''}
              style={{
                position: 'fixed',
                bottom: '0',
                left: '320px',
                width: 'calc(100vw - 320px)'
              }}
              handleClick={() =>
                active === 'Verify'
                  ? submitParticipant()
                  : handleNextPrevButton('next')
              }
              handleRoute={() => handleNextPrevButton('prev')}
              buttonPrimaryName={active === 'Verify' ? 'Submit' : 'Next'}
              linkButtonName="Back"
              buttonPrimaryDisabled={checkButton()}
              linkButtonDisabled={active === 'Name & Description'}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AddEditParticipantTeam;

import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useModalObjectives from 'hooks/useModalObjectives';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { getSelectedInvolvement } from 'utils/ObjectivesHelper';

const MenuAlign = ({ objective, page, closeAlignModal, permissions }) => {
  const {
    config: { enableTaskMultiParentAlignment }
  } = useUser();

  const cloneDeepObj = cloneDeep(objective);
  const parentIds = cloneDeepObj?.parents?.map((parent) => parent?.id);
  const [objectiveValue, setObjectiveValue] = useState({
    ...cloneDeepObj,
    parentIds: parentIds
  });
  const [showMainModal, setShowMainModal] = useState(false);

  const {
    showModalValidationChangeGoalAlignment,
    checkValidationChangeGoalAlignment,
    _handleActionCloseModal
  } = useValidationMinMax();
  const { refetchObjective, refetchSubObjectives } = useRefetchQuery();

  const { selectedOwner, selectedReviewer } = getSelectedInvolvement(
    objective?.involvements
  );
  let arrayAssignee = selectedOwner?.map(({ user }) => user.id) || [];
  let arrayAssigner = selectedReviewer?.map(({ user }) => user.id) || [];
  let assigneeId = {
    assignee_id: arrayAssignee,
    assigner_id: arrayAssigner
  };

  const { reload, reloadSidebar } = useReload();

  const showModal = useModalObjectives((state) => state.showModal);
  const closeModal = useModalObjectives((state) => state.closeModal);

  const submitParent = async (
    objectiveId,
    comment,
    metaMentionsPayload,
    parentIds
  ) => {
    let body = {
      parentIds: parentIds,
      comment,
      metaMentions: metaMentionsPayload
    };
    const oldParentsIds = objective.parents?.map((value) => value.id) || [];
    const newParentIds = parentIds || [];

    const combined = [...oldParentsIds, ...newParentIds].sort();
    const diff = [];

    combined.forEach((value, index) => {
      if (index > 0 && combined[index - 1] == value) return;
      if (index < combined.length - 1 && value == combined[index + 1]) return;

      diff.push(value);
    });

    await editObjective(objectiveId, body);

    // reload objective card and it's subs
    diff.forEach((value) => {
      refetchObjective(value);
      refetchSubObjectives(value);
    });

    reload({ reloadSidebar: !reloadSidebar });
    closeModal();
    closeAlignModal();
    reload({
      reloadApprovalPanel: ['draft', 'edited']
    });
  };

  const handleChangeParent = async (tempParentIds, tempParents) => {
    const clonedObj = cloneDeep(objectiveValue);
    clonedObj.parentIds = tempParentIds;
    clonedObj.parents = tempParents;
    setObjectiveValue(clonedObj);
    setShowMainModal(false);
    const props = {
      objectiveId: objective?.id,
      measurement: objective?.measurement,
      objectiveName: objective?.name,
      objectiveValue: clonedObj
    };
    showModal({
      modalType: 'comment',
      submitModal: submitParent,
      props: props
    });
  };

  const beforeTrigger = async () => {
    if (objective?.type == 'task') {
      setShowMainModal(true);
    } else {
      const { isSettingMinObjectivesOn } =
        await checkValidationChangeGoalAlignment({
          nextAction: () => setShowMainModal(true),
          closeAction: () => closeAlignModal()
        });
      if (!isSettingMinObjectivesOn) setShowMainModal(true);
    }
  };

  useEffect(() => {
    if (showMainModal) {
      showModal && closeModal();

      // Show modal parent suggestion
      showModal({
        modalType: 'modalAlignParent',
        props: {
          objectiveValue,
          setObjectiveValue,
          assigneeId,
          permissions,
          handleChangeParent,
          useTaskParentSuggestion: objectiveValue?.type === 'task',
          useMultipleParentAlignment:
            objectiveValue?.type == 'task'
              ? enableTaskMultiParentAlignment
              : true
        }
      });
      showModalValidationChangeGoalAlignment &&
        _handleActionCloseModal({
          type: 'showModalValidationChangeGoalAlignment'
        });
    }
  }, [showMainModal]);

  useEffect(() => {
    beforeTrigger();
  }, []);

  return (
    <>{objective?.type !== 'task' && showModalValidationChangeGoalAlignment}</>
  );
};

export default MenuAlign;

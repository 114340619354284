import { useParams, useHistory } from 'react-router-dom';
import { getObjectiveLocale } from 'utils/HelperUtils';
import useSuggestions from 'hooks/useSuggestions';
import useTags from 'hooks/useTags';
import { useQuery } from 'react-query';
import useCategories from 'hooks/useCategories';
import useObjectivePhases from 'hooks/useObjectivePhases';
import useObjectivePriorities from 'hooks/useObjectivePriorities';
import kebabCase from 'lodash/kebabCase';
import { useUser } from 'context/UserContext';
import { capitalize } from 'utils/HelperUtils';
import { getListFilter } from 'client/ObjectivesClient';
import getParentObjectiveSuggestions from 'hooks/useParentObjectiveSuggestions';

function useFilterDialog(
  filterKey,
  filterClient = null,
  suspense = true,
  filter,
  additionalQueryFn
) {
  const params = useParams();
  const history = useHistory();
  const { config, user } = useUser();
  const { getUserSuggestions } = useSuggestions();
  const { getCategories } = useCategories();
  const { getObjectivePhases } = useObjectivePhases();
  const { getObjectivePriorities } = useObjectivePriorities();
  const projectId = params.parentId || null;

  const isObjectiveAnalyticsPage =
    history?.location?.pathname?.includes('objective-analytics') || false;

  const { getTags } = useTags();

  const getFilterData = async () => {
    let params = {
      user_id: user.id,
      filter_keys: [
        'division',
        'department',
        'directorate',
        'job_title',
        'job_function',
        'job_role'
      ]
    };
    return getListFilter(params, true);
  };

  let { data: filtersData } = useQuery(
    ['filterData'],
    filterClient ? filterClient : getFilterData,
    {
      suspense
    }
  );

  let phases = [];
  config?.filterPhase?.map((phase, index) => {
    phases.push({
      id: phase,
      name: getObjectiveLocale(
        capitalize(phase == 'running' ? 'On Going' : phase)
      )
    });
  });

  const statuses = [
    { id: 'bad', name: getObjectiveLocale('At risk') },
    { id: 'warning', name: getObjectiveLocale('Left Behind') },
    { id: 'good', name: getObjectiveLocale('On-track') },
    { id: 'exceed_expectation', name: getObjectiveLocale('Exceeded') },
    { id: 'not_updated', name: getObjectiveLocale('Not Updated') }
  ];

  // temporary idk if this should be here
  const _getUserSuggestions = (role, { q }) => {
    return getUserSuggestions({
      role: role,
      type: 'goal',
      q: q
    });
  };

  const getParentSuggestion = ({ q }) => {
    return getParentObjectiveSuggestions({
      additionalParams: {
        assigneeId: [user.id],
        state: ['running', 'completed', 'reviewed', 'edited']
      },
      q: q
    });
  };

  const selectUserListItem = {
    template: 'img-init-name-label',
    config: {
      imgSize: 24,
      labelText: 'name',
      nameSrc: 'name',
      profilePictureSrc: 'profilePicture'
    }
  };
  const selectUserHeader = {
    template: 'img-init-name-list',
    config: {
      imgSize: 24,
      nameSrc: 'user.name',
      profilePictureSrc: 'user.profilePicture'
    }
  };

  const inlineTagHeader = {
    template: 'label-list'
  };

  let listFilter = [
    {
      id: 'status',
      dialog: {
        noSearch: true,
        title: 'Status',
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: statuses,
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'objective-category',
      dialog: {
        noSearch: true,
        title: 'Type',
        listItem: { template: 'default', config: { labelText: 'name' } },
        queryFn: params => getCategories({ ...params, pagination: true }),
        placement: 'right-start',
        selectMultiple: true
      }
    },
    {
      id: 'label',
      dialog: {
        title: `${getObjectiveLocale('Label')}`,
        selectMultiple: true,
        searchPlaceholder: `${getObjectiveLocale('Add Label')}`,
        listItem: { template: 'default' },
        headerItem: inlineTagHeader,
        emptyState: 'Label not found, create new label?',
        addItemSuggestion: true,
        placement: 'right-start',
        queryFn: getTags
      }
    },
    {
      id: 'phase',
      dialog: {
        noSearch: true,
        title: 'Phase',
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: phases,
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'assignee',
      dialog: {
        title: `${getObjectiveLocale('Objective / Task Owner')}'s Name`,
        selectMultiple: true,
        listItem: selectUserListItem,
        headerItem: selectUserHeader,
        emptyState:
          'Employee not found or has been assigned as follower or reviewer',
        searchPlaceholder: `${getObjectiveLocale(
          'Search'
        )} ${getObjectiveLocale('Colleagues')}`,
        placement: 'right-start',
        queryFn: param => _getUserSuggestions('assignee', param),
        compareKey: 'userId',
        payloadFormat: {
          user: '',
          userId: 'id',
          role: '/assignee',
          visible: '&true'
        }
      }
    },
    {
      id: 'assigner',
      dialog: {
        title: `${getObjectiveLocale('Objective / Task Reviewer')}`,
        selectMultiple: true,
        listItem: selectUserListItem,
        headerItem: selectUserHeader,
        emptyState:
          'Employee not found or has been assigned as assigned as owner or follower',
        searchPlaceholder: `${getObjectiveLocale(
          'Search'
        )} ${getObjectiveLocale('Colleagues')}`,
        placement: 'right-start',
        queryFn: param => _getUserSuggestions('assigner', param),
        compareKey: 'userId',
        payloadFormat: {
          user: '',
          userId: 'id',
          role: '/assigner',
          visible: '&true'
        }
      }
    },
    {
      id: 'follower',
      dialog: {
        title: `${getObjectiveLocale('Objective / Task Follower')}`,
        selectMultiple: true,
        listItem: selectUserListItem,
        headerItem: selectUserHeader,
        emptyState:
          'Employee not found or has been assigned as assigned as owner or reviewer',
        searchPlaceholder: `${getObjectiveLocale(
          'Search'
        )} ${getObjectiveLocale('Colleagues')}`,
        placement: 'right-start',
        queryFn: param => _getUserSuggestions('follower', param),
        compareKey: 'userId',
        payloadFormat: {
          user: '',
          userId: 'id',
          role: '/follower',
          visible: '&true',
          profilePicture: 'profilePicture'
        }
      }
    },
    {
      id: 'show',
      dialog: {
        noSearch: true,
        title: `${getObjectiveLocale(filterKey)}`,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          {
            id: 'direct_reports',
            name: getObjectiveLocale('My Direct Report')
          },
          {
            id: 'indirect_reports',
            name: getObjectiveLocale('My Indirect Report')
          },
          {
            id: 'indirect_descendants',
            name: getObjectiveLocale("My Indirect Report's Descendants")
          }
        ],
        placement: 'right-start',
        selectMultiple: true,
        compareKey: 'id',
        payloadFormat: { id: 'id', name: 'name' }
      }
    },
    {
      id: 'manager',
      dialog: {
        title: `${getObjectiveLocale(filterKey)}`,
        listItem: {
          template: 'img-init-name-label',
          config: {
            imgSize: 32,
            labelText: 'name',
            nameSrc: 'name',
            profilePictureSrc: 'profilePicture'
          }
        },
        headerItem: {
          template: 'img-init-name-list',
          config: {
            imgSize: 24,
            nameSrc: 'name',
            profilePictureSrc: 'profilePicture'
          }
        },
        selectMultiple: true,
        compareKey: 'id',
        searchPlaceholder: `${getObjectiveLocale('Search')}`,
        placement: 'right-start',
        queryFn: params => filterClient(params),
        payloadFormat: {
          id: 'id',
          name: 'name',
          profilePicture: 'profilePicture'
        }
      }
    },
    {
      id: 'cycle-status',
      dialog: {
        noSearch: true,
        title: `${getObjectiveLocale(filterKey)}`,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          config?.endUserCyclesFilter?.includes('active') && {
            id: 'active',
            name: 'Active'
          },
          config?.endUserCyclesFilter?.includes('ended') && {
            id: 'ended',
            name: 'Ended'
          }
        ].filter(val => val),
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'type',
      dialog: {
        noSearch: true,
        title: `${getObjectiveLocale(filterKey)}`,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          config?.typeCyclesFilter?.includes('performance_review') && {
            id: 'performance_review',
            name: '[filter-type] performance_review'
          },
          config?.typeCyclesFilter?.includes('feedback') && {
            id: 'feedback',
            name: '[filter-type] feedback'
          }
        ].filter(val => val),
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true },
        containerClassName: 'w-[208px]'
      }
    },
    {
      id: 'involvement',
      dialog: {
        noSearch: true,
        title: `${getObjectiveLocale(filterKey)}`,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          config?.involvementCyclesFilter?.includes('individual') && {
            id: 'individual',
            name: 'Individual'
          },
          config?.involvementCyclesFilter?.includes('team') && {
            // team
            id: 'team',
            name: 'Team'
          }
        ].filter(val => val),
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true },
        containerClassName: 'w-[176px]'
      }
    },
    {
      id: 'assignment-status',
      dialog: {
        noSearch: true,
        title: `${getObjectiveLocale(filterKey)}`,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          config?.assignmentsStatusFilter?.includes('in_progress') && {
            id: 'in_progress',
            name: 'In Progress'
          },
          config?.assignmentsStatusFilter?.includes('done') && {
            id: 'done',
            name: 'Done'
          },
          config?.assignmentsStatusFilter?.includes('incomplete') && {
            id: 'incomplete',
            name: 'Incomplete'
          }
        ].filter(val => val),
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'phase-status',
      dialog: {
        noSearch: true,
        title: `${getObjectiveLocale(filterKey)}`,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          config?.phasesStatusFilter?.includes('in_progress') && {
            id: 'in_progress',
            name: 'In Progress'
          },
          config?.phasesStatusFilter?.includes('done') && {
            id: 'done',
            name: 'Done'
          }
        ].filter(val => val),
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'score',
      dialog: {
        title: getObjectiveLocale('Score'),
        noSearch: true,
        hasInput: true,
        inputOptions: [
          { id: 'minimum_score', name: 'Minimum score' },
          { id: 'maximum_score', name: 'Maximum score' }
        ]
      }
    },
    {
      id: 'status-cycle',
      dialog: {
        title: getObjectiveLocale('Status'),
        noSearch: true,
        hasInput: true,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          { id: 'in_progress', name: 'Active' },
          { id: 'draft', name: 'Draft' },
          { id: 'done', name: 'Ended' },
          { id: 'deleted', name: 'Deleted' }
        ],
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'schedule-cycle',
      dialog: {
        title: getObjectiveLocale('Schedule'),
        noSearch: true,
        hasInput: true,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          { id: 'one_time', name: 'One Time' },
          { id: 'recurring', name: 'Recurring' },
          { id: 'no_schedule', name: 'No Schedule' }
        ],
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'permission-status',
      dialog: {
        title: getObjectiveLocale('Status'),
        noSearch: true,
        selectMultiple: true,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          { id: 'active', name: 'Active' },
          { id: 'archived', name: 'Archived' }
        ],
        placement: 'right-start',
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'cycle-state',
      dialog: {
        title: getObjectiveLocale('Status'),
        noSearch: true,
        selectMultiple: true,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          { id: 'in_progress', name: 'Active' },
          { id: 'draft', name: 'Draft' },
          { id: 'done', name: 'Ended' },
          { id: 'deleted', name: 'Deleted' }
        ],
        placement: 'right-start',
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'review-state',
      dialog: {
        title: getObjectiveLocale('Status'),
        noSearch: true,
        selectMultiple: true,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          { id: 'in_progress', name: 'In Progress' },
          { id: 'incomplete', name: 'Incomplete' },
          { id: 'done', name: 'Completed' },
          { id: 'deleted', name: 'Deleted' }
        ],
        placement: 'right-start',
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'cycle-schedule',
      dialog: {
        title: getObjectiveLocale('Schedule'),
        noSearch: true,
        selectMultiple: true,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          { id: 'one_time', name: 'One Time' },
          { id: 'recurring', name: 'Recurring' },
          { id: 'no_schedule', name: 'No Schedule' }
        ],
        placement: 'right-start',
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'task-phase',
      dialog: {
        noSearch: true,
        title: 'Phase',
        listItem: { template: 'default', config: { labelText: 'name' } },
        queryFn: params => {
          params = { ...params, pagination: true };
          if (filter?.showTask === 'project') {
            params.assigneeId = user?.id;
          }
          if (projectId) {
            params.projectId = projectId;
          }
          return getObjectivePhases({ ...params }, filter?.showTask);
        },
        placement: 'right-start',
        selectMultiple: true
      }
    },
    {
      id: 'task-priority',
      dialog: {
        noSearch: true,
        title: 'Priority',
        listItem: { template: 'default', config: { labelText: 'name' } },
        queryFn: params =>
          getObjectivePriorities({ ...params, pagination: true }),
        placement: 'right-start',
        selectMultiple: true
      }
    },
    {
      id: 'alignment-goal',
      dialog: {
        title: `${getObjectiveLocale('Alignment')}`,
        listItem: {
          template: 'img-init-name-label',
          config: {
            imgSize: 30,
            labelText: 'name',
            isMultiline: true,
            startDateSrc: 'startDate',
            dueDateSrc: 'dueDate',
            nameSrc: 'involvements.[role=assignee].user.name',
            profilePictureSrc:
              'involvements.[role=assignee].user.profilePicture'
          }
        },
        headerItem: {
          template: 'img-init-name-list',
          config: {
            imgSize: 24,
            nameSrc: 'objective.name',
            profilePictureSrc:
              'objective.involvements.[role=assignee].user.profilePicture'
          }
        },
        searchPlaceholder: `${getObjectiveLocale('Search')}`,
        placement: 'right-start',
        queryFn: param => getParentSuggestion(param),
        compareKey: 'objectiveId',
        payloadFormat: { objective: '', objectiveId: 'id' }
      }
    },
    {
      id: 'position-assignment-status',
      dialog: {
        title: getObjectiveLocale('Positions'),
        noSearch: true,
        selectMultiple: true,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          { id: 'ongoing', name: 'Current Position' },
          { id: 'previous', name: 'Previous Position' }
        ],
        placement: 'right-start',
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'myInvolvement',
      dialog: {
        title: getObjectiveLocale('My Involvement'),
        noSearch: true,
        selectMultiple: true,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          { id: 'owner', name: 'Owner' },
          { id: 'reviewer', name: 'Reviewer' },
          { id: 'follower', name: 'Follower' }
        ],
        placement: 'right-start',
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'myInvolvementProject',
      dialog: {
        title: getObjectiveLocale('My Involvement'),
        noSearch: true,
        selectMultiple: true,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          { id: 'leader', name: 'Leader' },
          { id: 'reviewer', name: 'Reviewer' },
          { id: 'member', name: 'Member' },
          { id: 'pmo', name: 'PMO' },
          { id: 'stakeholder', name: 'Stakeholder' }
        ],
        placement: 'right-start',
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'survey-status',
      dialog: {
        noSearch: true,
        title: 'Status Survey',
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [
          { id: 'submitted', name: 'Submitted' },
          { id: 'not_yet_submitted', name: 'Not Yet Submitted' }
        ],
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true }
      }
    },
    {
      id: 'integration',
      dialog: {
        noSearch: true,
        title: getObjectiveLocale('Filter by'),
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: [{ id: 'jira', name: 'Jira' }],
        placement: 'right-start',
        selectMultiple: true,
        payloadFormat: { valueOnly: true }
      }
    }
  ];

  const additionalQueryCallback = params => {
    let par = {
      ...params,
      userId: user.id
    };
    return additionalQueryFn(par);
  };

  filtersData?.data?.forEach(({ filterKey, filterValue }) => {
    let id = kebabCase(filterKey?.toLowerCase());
    if (filterKey == 'Score') return;
    if (filterKey == 'Manager') return;
    listFilter?.unshift({
      id,
      dialog: {
        title: `Owner's ${getObjectiveLocale(filterKey)}`,
        listItem: { template: 'default', config: { labelText: 'name' } },
        staticOptions: filterValue?.filter(value => value),
        placement: 'right-start',
        selectMultiple: !isObjectiveAnalyticsPage,
        headerItem: {
          template: 'label-list',
          config: {
            isAbleToDelete: !isObjectiveAnalyticsPage
          }
        },
        ...(additionalQueryFn
          ? { queryFn: params => additionalQueryCallback(params) }
          : [])
      }
    });
  });

  return listFilter.find(({ id }) => id === filterKey);
}

export default useFilterDialog;

import React from 'react';

import 'scss/culture/_logout-modal';

import { useAuth } from 'context/AuthContext';

function LogoutModal({ toggleLogoutModal }) {
  const { logout } = useAuth();

  return (
    <div className="logout-modal">
      <div className="modal-wrapper">
        <div className="modal-wrapper__content">
          <div className="modal-logout-content" data-cy="logout-modal">
            <div
              className="content typography-h500"
              onClick={(e) => {
                e.stopPropagation();
                logout();
              }}
            >
              Log Out
            </div>
            <div
              className="footer typography-h500"
              onClick={(e) => {
                e.stopPropagation();
                toggleLogoutModal(false);
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoutModal;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';

import { useUser } from 'context/UserContext';
import { useUserSuggestion } from 'context/UserSuggestionContext';
import useDebounce from 'hooks/useDebounce';
import { getBadgeColor, getBadgeTitle, phaseName } from 'utils/FormalReview';
import {
  getCssVariableValue,
  getDateLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import DropdownReviewCycle from 'components/dropdown/DropdownReviewCycle';
import Badge from 'components/shared/Badge';
import ProfileSection from 'components/shared/Profile/ProfileSection';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import Checkbox from 'src/components/shared/Checkbox';

import DropdownSelectPeerSelection from './sidebar-phase/DropdownSelectPeerSelection';
import DropdownSelectUserPhase from './sidebar-phase/DropdownSelectUserPhase';

function SidebarPhaseComponent({
  phase,
  phaseIndex,
  currentTab,
  assignment,
  saveData,
  remindCycle,
  isReadOnly,
  lockAnswerCycle,
  sidebarId,
  refetchSidebar
}) {
  const assignments =
    phase.type == 'peer_review' && phase.state == 'draft'
      ? phase?.previewAssignments
      : phase?.assignments;
  const history = useHistory();
  const [editMode, setEditMode] = useState(null);
  const [search, setSearch] = useState('');
  const [excludeUser, setExcludeUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [removedAssignment, setRemovedAssignment] = useState([]);
  const [rescheduledAssignments, setRescheduledAssignments] = useState([]);
  const [phaseAssignments, setPhaseAssignments] = useState(
    assignments?.length > 0 ? assignments : []
  );
  const isPeerComposerShow = editMode == 'add' && phase.type == 'peer_review';

  const { suggestion, hasMore, getSuggestionData, appendSuggestionData } =
    useUserSuggestion();

  const debouncedSearchTerm = useDebounce(search, 500);

  const {
    config: { permissions, formalReviewReminder }
  } = useUser();

  const revieweeOrReviewerWording =
    currentTab == 'reviewee' ? 'Reviewer' : 'Reviewee';

  const _onScroll = (e) => {
    const loadMore = async () => {
      await appendSuggestionData({ q: search });
    };

    let target = e.target;
    let cond = target.scrollTop + target.clientHeight >= target.scrollHeight;

    if (hasMore && cond) {
      loadMore();
    }
  };

  const selectUser = (user) => {
    let newData = [...phaseAssignments];
    let newArray = [...excludeUser];
    let newSelectedUser = [...selectedUser];
    newData.unshift(user);
    newArray.push(user.id);
    newSelectedUser.push(user);
    setPhaseAssignments(newData);
    setSelectedUser(newSelectedUser);
    setExcludeUser(newArray);
  };

  const removeAssignment = (assignment, e) => {
    if (e.currentTarget.checked) {
      let newRemovedAssignment = [...removedAssignment];
      newRemovedAssignment.push({
        id: assignment.id,
        targetId: assignment.target.id,
        targetPlacementId: assignment.targetPlacementId,
        actorId: assignment.actor.id,
        actorPlacementId: assignment.actorPlacementId
      });
      setRemovedAssignment(newRemovedAssignment);
    } else {
      let newRemovedAssignment = [...removedAssignment].filter(
        (a) => assignment.id != a.id
      );
      setRemovedAssignment(newRemovedAssignment);
    }
  };

  const handleRescheduledAssignment = (assignment, e) => {
    if (e.currentTarget.checked) {
      let newRescheduledAssignment = [...rescheduledAssignments];
      newRescheduledAssignment.push({
        id: assignment.id,
        targetId: assignment.target.id,
        targetPlacementId: assignment.targetPlacementId,
        actorId: assignment.actor.id,
        actorPlacementId: assignment.actorPlacementId
      });
      setRescheduledAssignments(newRescheduledAssignment);
    } else {
      let newRescheduledAssignment = [...rescheduledAssignments].filter(
        (a) => assignment.id != a.id
      );
      setRescheduledAssignments(newRescheduledAssignment);
    }
  };

  const removeUser = (deletedUser) => {
    let newData = [...phaseAssignments];
    let newArray = [...excludeUser];
    let newSelectedUser = cloneDeep(selectedUser);
    newData = newData.filter((user) => user.id != deletedUser.id);
    newArray = newArray.filter((id) => id != deletedUser.id);
    newSelectedUser = newSelectedUser.filter(
      (user) => user.id != deletedUser.id
    );
    setExcludeUser(newArray);
    setPhaseAssignments(newData);
    setSelectedUser(newSelectedUser);
  };

  const cancelEdit = () => {
    setEditMode(null);
    let newPhase = [...phaseAssignments];
    newPhase = newPhase.filter((user) => !excludeUser.includes(user.id));
    setPhaseAssignments(newPhase);
    setExcludeUser([]);
    setSelectedUser([]);
    setRemovedAssignment([]);
    setRescheduledAssignments([]);
  };

  const handleClickSave = () => {
    // The conditional to make Save function only works when there is new data
    if (
      (editMode == 'add' && selectedUser.length > 0) ||
      (editMode == 'reschedule' && rescheduledAssignments.length > 0) ||
      (editMode == 'reopen' && rescheduledAssignments.length > 0) ||
      (editMode == 'remove' && removedAssignment.length > 0)
    ) {
      let listUser;
      if (editMode == 'add') listUser = selectedUser;
      else if (editMode == 'remove') listUser = removedAssignment;
      else if (editMode == 'reschedule' || editMode == 'reopen')
        listUser = rescheduledAssignments;
      saveData(editMode, phase.id, listUser, phase);
    }
  };

  const ItemPhase = ({ assignment, type }) => {
    let user = currentTab === 'reviewee' ? assignment.actor : assignment.target;
    const assignmentState = assignment?.state;

    let statusAssignment;
    if (assignmentState === 'incomplete') statusAssignment = 'Incomplete';
    else if (assignmentState === 'done') statusAssignment = 'Completed';
    else if (!formalReviewReminder) statusAssignment = '';
    else statusAssignment = 'Remind';

    let isNew = false;
    if (!user) {
      user = assignment;
      isNew = true;
    }

    let reopenCheckboxRuleState =
      editMode == 'reopen'
        ? phase.state == 'done' || assignmentState == 'done'
        : true;

    const handleClickOverride = () => {
      const assignmentId = assignment.id;
      const backUrl = window.location.pathname + window.location.search;
      localStorage.setItem('backUrl', backUrl);
      history.push(`/reviews/assignments/${assignmentId}`);
    };

    return (
      <div
        key={assignment.id}
        className={`relative flex justify-between items-center px-[16px] bg-n-200 rounded-[4px] ${
          assignment?.lastRescheduledAt ? 'h-[80px]' : 'h-[48px]'
        } ${isNew ? 'bg-y-100' : ''}`}
      >
        {/* Left side: Avatar, name, New label */}
        <div className="relative">
          <div className="flex items-center">
            <ProfileSection
              name={user?.name}
              profilePicture={user?.profilePicture}
              totalOwners={1}
              avatarSize={24}
            />
            {isNew && (
              <span className="typography-h100 ml-[4px]">
                {getObjectiveLocale('New')}
              </span>
            )}
            {assignment?.lastRescheduledAt && (
              <TooltipContainer
                show
                text={`Rescheduled at ${getDateLocale(
                  assignment.lastRescheduledAt
                )}`}
              >
                <SVGIcon
                  iconName="icon-update"
                  fillColor={'var(--n-600)'}
                  size="16"
                  customClass="relative top-[4px] ml-[6px] cursor-pointer"
                />
              </TooltipContainer>
            )}
          </div>
          {assignment?.lastRescheduledAt && (
            <div className="ml-[40px]">
              <p className="typography-h100 typography-secondary">
                {getObjectiveLocale('Rescheduled to')}{' '}
                {getDateLocale(assignment.endsAt)},
              </p>
              <p className="typography-h100 typography-secondary">
                {getObjectiveLocale('by')} {assignment?.lastRescheduledBy?.name}
              </p>
            </div>
          )}
        </div>

        {/* Right side: X icon, checkbox, Completed or Remind button */}
        {isNew && (
          <SVGIcon
            iconName="icon-clear"
            fillColor={'var(--n-600)'}
            size="24"
            customClass="icon-close"
            onClick={() => removeUser(user)}
          />
        )}
        {editMode == 'remove' && (
          <Checkbox
            value={user.id}
            id={user.id}
            dataCy={`remove-checkbox-${assignment.id}`}
            checked={
              removedAssignment.findIndex((a) => a.id == assignment.id) > -1
            }
            onChange={(e) => removeAssignment(assignment, e)}
          />
        )}
        {(editMode == 'reschedule' || editMode == 'reopen') &&
          reopenCheckboxRuleState && (
            <Checkbox
              value={user.id}
              id={user.id}
              dataCy={`reschedule-checkbox-${assignment.id}`}
              checked={
                rescheduledAssignments.findIndex((a) => a.id == assignment.id) >
                -1
              }
              onChange={(e) => handleRescheduledAssignment(assignment, e)}
            />
          )}
        {editMode == 'override' && (
          <Button.Tertiary onClick={handleClickOverride}>
            {getObjectiveLocale('Review Now')}
          </Button.Tertiary>
        )}
        {!editMode && statusAssignment !== '' && !isReadOnly && (
          <div className={`${statusAssignment !== 'Remind' ? 'no-hover' : ''}`}>
            <Button.Tertiary
              disabled={statusAssignment !== 'Remind'}
              customClass={`${
                statusAssignment !== 'Remind' ? '!bg-transparent' : ''
              } !px-[8px]`}
              datacy="button-single-remind"
              onClick={() =>
                remindCycle(
                  'single',
                  type,
                  assignment?.actorPlacementId,
                  assignment?.targetPlacementId
                )
              }
            >
              {statusAssignment}
            </Button.Tertiary>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    getSuggestionData({ q: search, userState: ['active', 'pending'] });
    let newExclude = [...excludeUser];
    if (phase?.assignments?.length > 0) {
      phaseAssignments.map((user) => {
        newExclude.push(
          currentTab === 'reviewee' ? user?.actor?.id : user?.target?.id
        );
      });
    }

    // prevent choosing same person for actor and target in manager, peer, and upward review
    if (phase.type != 'self_review' && phase.type != 'peer_selection') {
      newExclude.push(assignment.userId);
    }

    setExcludeUser(newExclude);
  }, [debouncedSearchTerm, editMode]);

  useEffect(() => {
    if (editMode == 'add' && phase.type == 'self_review') {
      selectUser({
        id: assignment.userId,
        profilePicture: assignment.profilePicture,
        placementId: assignment.id,
        name: assignment.name
      });
    }
  }, [editMode]);

  return (
    <div className="mt-[24px] relative" data-cy={`phase-wrapper-${phase.id}`}>
      <div className="flex justify-between">
        <div className="flex items-center mb-[8px]">
          <p
            className="typography-h200 text-n-800 uppercase mr-[8px]"
            data-cy="phase-type"
          >
            {phaseName(phase.type)}
          </p>
          <Badge
            content={getBadgeTitle(phase.state)}
            bgColorHex={getCssVariableValue(getBadgeColor(phase.state, true))}
            colorHex={getCssVariableValue(getBadgeColor(phase.state, false))}
          />
        </div>

        <div className="flex absolute items-center top-0 right-0">
          {!editMode &&
            permissions?.reviewPerformanceCycleSee &&
            !isReadOnly && (
              <DropdownReviewCycle
                phase={phase}
                editMode={editMode}
                setEditMode={setEditMode}
                revieweeOrReviewerWording={revieweeOrReviewerWording}
                lockAnswerCycle={lockAnswerCycle}
                phaseAssignments={phaseAssignments}
              />
            )}
        </div>
      </div>
      <div className="rounded">
        {phaseAssignments.length == 0 &&
          !isPeerComposerShow &&
          editMode != 'add' && (
            <div
              className={`relative flex justify-between items-center px-[16px] h-[48px]`}
            >
              <span className="typography-h400">
                {getObjectiveLocale('No Assignment Found')}
              </span>
            </div>
          )}
        {editMode == 'add' &&
          phase.type != 'self_review' &&
          phase.type != 'peer_review' && (
            <DropdownSelectUserPhase
              revieweeOrReviewerWording={revieweeOrReviewerWording}
              setSearch={setSearch}
              _onScroll={_onScroll}
              suggestion={suggestion}
              excludeUser={excludeUser}
              selectUser={selectUser}
              cancelEdit={cancelEdit}
              handleClickSave={handleClickSave}
            />
          )}
        {(phase.type == 'peer_review' ? editMode != 'add' : true) &&
          phaseAssignments.map((assignment, index) => (
            <ItemPhase
              key={index}
              assignment={assignment}
              type={phase.type}
              phaseIndex={phaseIndex}
              index={index}
            />
          ))}
      </div>
      {isPeerComposerShow && (
        <DropdownSelectPeerSelection
          assignments={phaseAssignments}
          phase={phase}
          targetId={sidebarId}
          setEditMode={setEditMode}
          refetchSidebar={refetchSidebar}
        />
      )}
      {editMode && phase.type != 'peer_review' && (
        <div className="mt-[8px] flex justify-end">
          <Button.Secondary onClick={cancelEdit}>
            {getObjectiveLocale('Cancel')}
          </Button.Secondary>
          <Button
            customClass="ml-[8px]"
            onClick={handleClickSave}
            datacy="save"
          >
            {getObjectiveLocale('Submit')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default SidebarPhaseComponent;

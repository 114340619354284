import React from 'react';

import ctl from '@netlify/classnames-template-literals';

import { useUser } from 'context/UserContext';
import useModalObjectives from 'hooks/useModalObjectives';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import FormattedNumber from 'components/design-system/FormattedNumber';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import OverallStatusCompact from 'pages/dashboardOverallViewProgress/compactStatistics/OverallStatusCompact';

import { _conditionMinMaxObjectiveTooltipMessageHelper } from '../../create-objective-warning/CreateObjectivesWarningHelpers';

const OverallProgressCompact = ({
  progressColorHex,
  overallProgress,
  totalObjectives,
  totalWeight,
  canEditWeight,
  listStatus,
  statusActive,
  filterByStatus,
  useCompactLayout = false,
  isVisible,
  page
}) => {
  const { config } = useUser();
  const {
    maxObjectivesOn,
    minObjectivesOn,
    maxNumberOfObjectives,
    minNumberOfObjectives,
    maxSumOfObjectivesWeightPercentage: maxSumOfObjectivesBasedOnCategory,
    maxSumOfObjectivesWeightPercentageOn: maxSumOfObjectivesOnBasedOnCategory
  } = config.objectivesConfig;

  const whichTextColor = {
    '5A9AEF': 'b-600',
    '44DB5E': 'g-600',
    FFCD00: 'y-600',
    FE2851: 'r-600'
  };

  const whichStatusByHex = {
    '44DB5E': getObjectiveLocale('On track'),
    '5A9AEF': getObjectiveLocale('Exceeded'),
    FE2851: getObjectiveLocale('At risk'),
    FFCD00: getObjectiveLocale('Left behind')
  };

  const { message, warning } = _conditionMinMaxObjectiveTooltipMessageHelper({
    maxObjectivesOn,
    minObjectivesOn,
    maxNumberOfObjectives,
    minNumberOfObjectives,
    totalObjectives
  });

  const showModalAdjustWeight = useModalObjectives((state) => state.showModal);

  const isWarningWeight =
    maxSumOfObjectivesOnBasedOnCategory &&
    totalWeight > maxSumOfObjectivesBasedOnCategory;

  const propsModalAdjustWeigth = {
    maxSumOfObjectivesOnBasedOnCategory: maxSumOfObjectivesOnBasedOnCategory,
    maxSumOfObjectivesBasedOnCategory: maxSumOfObjectivesBasedOnCategory,
    isShowWarning: isWarningWeight,
    isIndividual: false,
    className: 'show-adjust-weight-modal'
  };

  const isPerformanceTab = page == 'performanceTab';

  const overallProgressCompactCN = ctl(`
  flex grow ${isPerformanceTab ? 'flex-col' : 'flex-row justify-between'}
  `);

  const wrapperAnnualProgressCN = ctl(`
  flex grow flex-row justify-between h-[40px] py-[8px] items-center
  ${isPerformanceTab && 'bg-n-000 rounded-t-[4px] px-[24px]'}
  `);

  const wrapperCN = ctl(`
  flex gap-[4px] pl-[24px] ml-[24px] first:pl-[8px] first:ml-0 first:border-0 border-y-0 border-r-0
  ${
    isPerformanceTab
      ? 'h-[24px] flex-row items-center'
      : 'h-[40px] flex-col items-start'
  }
  `);

  const tooltipProgressText = [
    overallProgress?.toLocaleString(navigator.language, {
      maximumFractionDigits: 20
    }),
    <br key="br" />,
    getObjectiveLocale('Status: '),
    whichStatusByHex[progressColorHex]
  ];

  return (
    <div className={overallProgressCompactCN}>
      <div className={wrapperAnnualProgressCN}>
        <div className={wrapperCN}>
          <p className="cursor-default typography-h200 text-n-800">
            {getObjectiveLocale(`Progress`)}
          </p>
          <TooltipContainer
            classContainer="cursor-default flex flex-row items-center "
            show={overallProgress}
            text={tooltipProgressText}
          >
            <FormattedNumber
              number={overallProgress}
              minDecimalLength={0}
              customClass={`cursor-default typography-h500 text-${whichTextColor[progressColorHex]}`}
              withTooltip
              dataCy="overall-progress-percentage"
            />
            <p
              className={`cursor-default typography-h500 text-${whichTextColor[progressColorHex]}`}
            >
              %
            </p>
          </TooltipContainer>
        </div>

        <div className={wrapperCN}>
          <p className="cursor-default typography-h200 text-n-800">
            {getObjectiveLocale('Top Parent')}
          </p>
          <TooltipContainer
            classContainer="cursor-default flex flex-row items-center "
            show={message}
            text={message}
            dataCy="overall-top-parent"
          >
            {warning && (
              <SVGIcon
                customClass="mr-[4px]"
                iconName="icon-warning"
                fillColor="var(--y-500)"
                size={16}
              />
            )}
            <FormattedNumber
              number={totalObjectives}
              minDecimalLength={0}
              customClass={`${
                warning ? 'text-r-600' : 'text-n-900'
              } typography-h500 `}
              dataCy="overall-objective-total"
            />
            {(maxObjectivesOn || minObjectivesOn) && (
              <p className="typography-h500 text-n-900">
                {`\u00A0/`}
                {`\u00A0`}
              </p>
            )}
            {(maxObjectivesOn || minObjectivesOn) && (
              <FormattedNumber
                number={
                  maxObjectivesOn
                    ? maxNumberOfObjectives
                    : minNumberOfObjectives
                }
                minDecimalLength={0}
                customClass="typography-h500 text-n-900"
              />
            )}
          </TooltipContainer>
        </div>

        {config?.permissions?.objectiveWeightManage && (
          <div className={wrapperCN}>
            <p
              className="cursor-default typography-h200 text-n-800"
              data-cy="weight-title"
            >
              {getObjectiveLocale('Total Weight')}
            </p>
            <div className="flex">
              <TooltipContainer
                classContainer="cursor-default flex flex-row items-center "
                show={isWarningWeight}
                text={`Must be ${getNumberLocale(
                  maxSumOfObjectivesBasedOnCategory
                )}%`}
                dataCy="overall-total-weight"
              >
                {isWarningWeight && (
                  <SVGIcon
                    customClass="mr-[4px]"
                    iconName={'icon-warning'}
                    fillColor={'var(--y-500)'}
                    size={16}
                  />
                )}
              </TooltipContainer>
              <FormattedNumber
                number={totalWeight}
                minDecimalLength={0}
                customClass={`cursor-default typography-h500 ${
                  isWarningWeight ? 'text-r-600' : 'text-n-900'
                }`}
                dataCy="weight"
              />
              <p
                className={`cursor-default typography-h500 ${
                  isWarningWeight ? 'text-r-600' : 'text-n-900'
                }`}
              >
                %
              </p>
              <div
                className="flex flex-row items-center cursor-pointer ml-[4px]"
                data-cy="adjust-weight"
                onClick={() =>
                  showModalAdjustWeight({
                    modalType: 'adjustWeight',
                    props: propsModalAdjustWeigth
                  })
                }
              >
                {canEditWeight && (
                  <SVGIcon
                    size={16}
                    fillColor="var(--base-600)"
                    iconName="icon-create"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {!useCompactLayout && isVisible('status') && (
          <OverallStatusCompact
            listStatus={listStatus}
            statusActive={statusActive}
            filterByStatus={filterByStatus}
            page={page}
          />
        )}
      </div>

      {useCompactLayout && (
        <OverallStatusCompact
          listStatus={listStatus}
          statusActive={statusActive}
          filterByStatus={filterByStatus}
          page={page}
        />
      )}
    </div>
  );
};

export default OverallProgressCompact;

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getObjectivesScoreAttribute } from 'client/FormalReviewClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SidebarRight from 'components/design-system/SidebarRight/SidebarRight';
import Objectives from 'components/objectives/Objectives';
import SVGIcon from 'components/shared/SVGIcon';

const SidebarCustomAttributesGoal = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeSidebar = () => {},
  title,
  currentTrackId,
  saveCallback,
  objectiveCategoryIds,
  reviewAspectId
}) => {
  const ref = useRef();
  const childRef = useRef();
  const [{ assignmentId, cycle, involvedUser, targetPlacementId }] =
    useFormalReview();
  const { config } = useUser();
  const { reviewedPeriodStartsAt, reviewedPeriodEndsAt } = cycle;
  const [isShowBackToTop, setIsShowBackToTop] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const defaultFilter = {
    assigneeId: [involvedUser?.target?.id],
    placementId: targetPlacementId,
    parentNotAssignedTo: involvedUser?.target?.id,
    objectiveCategoryId: objectiveCategoryIds,
    periodBegin: reviewedPeriodStartsAt,
    periodEndBefore: reviewedPeriodEndsAt,
    sortColumn: config?.defaultObjectiveSorting?.sortColumn,
    sortDirection: config?.defaultObjectiveSorting?.sortDirection,
    group: 'goal-type'
  };

  const checkScrollPosition = () => {
    setIsShowBackToTop(
      ref?.current.offsetHeight + ref?.current.scrollTop >=
        ref?.current.scrollHeight
    );
    setScrollPosition(ref?.current.scrollTop);
  };

  const scrollButton = (e) => {
    e.stopPropagation();
    ref.current.scroll({
      top: isShowBackToTop ? 0 : scrollPosition + 560,
      behavior: 'smooth'
    });
  };

  const getScoredAttributeObjectives = useMemo(() => {
    return (params) =>
      getObjectivesScoreAttribute(
        assignmentId,
        currentTrackId,
        reviewAspectId,
        params
      );
    // eslint-disable-next-line
  }, [currentTrackId]);

  const _saveCallback = useMemo(() => {
    return saveCallback;
    // eslint-disable-next-line
  }, [currentTrackId]);

  useEffect(() => {
    const copyRef = ref;
    const resizeSidebar = new ResizeObserver(function () {
      checkScrollPosition();
    });

    resizeSidebar.observe(copyRef?.current);
    return () => {
      if (copyRef?.current) {
        resizeSidebar.unobserve(copyRef?.current);
      }
    };
  }, [ref]);

  return (
    <SidebarRight
      dataCy="sidebar-custom-attribute-goal"
      fixed={false}
      customClass="!fixed right-0"
      customWidth="!w-[640px]"
    >
      <SidebarRight.Header customClass="flex flex-item justify-between">
        <p className="typography-h500 text-n-900">{title}</p>
        <SVGIcon
          onClick={() => closeSidebar()}
          fillColor="var(--n-600)"
          iconName="icon-clear"
          size="24"
          customClass="ml-[16px]"
          dataCy="sidebar-goal-close"
        />
      </SidebarRight.Header>
      <SidebarRight.Body
        withSpace={false}
        customClass="p-[8px] h-[calc(100vh-108px)]"
        onScroll={(e) => checkScrollPosition(e)}
        ref={ref}
      >
        <div id="sidebar-body" ref={childRef}>
          <Objectives
            cardModel="goal-list"
            page="scored-attribute-scoring"
            group="goal-type"
            extraFilter={defaultFilter}
            queryFn={getScoredAttributeObjectives}
            saveCallback={_saveCallback}
            showBadgeObjectiveCount
            isRefetch
            withMarginTopAccordion={false}
            hideDueDate={true}
            smallerTitle={true}
            customClass="!overflow-x-hidden"
            additionalWrapperClick={() =>
              setTimeout(() => {
                if (!ref.current) return;

                ref.current.scroll({
                  top: scrollPosition + 1,
                  behavior: 'smooth'
                });
                checkScrollPosition();
              }, 500)
            }
          />
        </div>

        {ref?.current?.scrollHeight > ref?.current?.offsetHeight && (
          <div
            id="back-to-top-button"
            className={`absolute cursor-pointer right-[40%] h-[40px] px-[16px] py-[8px] bg-n-900 rounded-[80px] flex justify-between ${
              isShowBackToTop ? 'top-[24px]' : 'bottom-[40px]'
            }`}
            onClick={(e) => scrollButton(e)}
            data-cy="back-to-top-button"
          >
            <p
              className={`text-n-000 typography-paragraph text-center ${
                isShowBackToTop ? 'w-[120px]' : 'w-[96px]'
              }`}
            >
              {getObjectiveLocale(isShowBackToTop ? 'Back to Top' : 'See More')}
            </p>
            <SVGIcon
              fillColor="var(--n-000)"
              iconName={
                isShowBackToTop ? 'icon-arrow_upward' : 'icon-arrow_downward'
              }
              size="24"
            />
          </div>
        )}
      </SidebarRight.Body>
    </SidebarRight>
  );
};

export default SidebarCustomAttributesGoal;

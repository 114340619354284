import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import dayjs from 'dayjs';
import domtoimage from 'dom-to-image';

import { exportObjectiveProgressSummary } from 'client/ObjectivesClient';
import S3Client from 'client/S3Client';
import { getPerformancePreSignUrl } from 'client/UploadClient';
import { useUser } from 'context/UserContext';
import {
  getBase64FromUrl,
  getFileFromDataUrl,
  getNumberLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';
import { whichColor, whichStatus } from 'utils/ObjectivesHelper';

import Button from 'components/design-system/Button';
import TwoLine from 'components/design-system/ProfileType';
import ObjectiveProgressSummaryTable from 'components/objectives/progress-summary/ObjectiveProgressSummaryTable';

const PreviewWrapper = ({ children, isConvertingToImage, setShowPreview }) => {
  return (
    <div
      className={`fixed w-screen h-screen top-0 left-0 z-[1001] overflow-y-scroll ${
        isConvertingToImage ? 'cursor-default' : 'cursor-pointer'
      }`}
      onClick={() => {
        if (!isConvertingToImage) {
          setShowPreview(false);
        }
      }}
    >
      <div className="flex flex-col justify-center items-center w-full min-h-full py-[40px]">
        {children}
      </div>
    </div>
  );
};

const ObjectiveProgressSummaryPreview = ({
  owner,
  receivers,
  overallProgress,
  parentObjective,
  objectives,
  setToast,
  setShowPreview,
  setShowModal
}) => {
  const [isConvertingToImage, setIsConvertingToImage] = useState(false);
  const [isAvatarReady, setIsAvatarReady] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [showFootNoteLessIsBetter, setShowFootNoteLessIsBetter] =
    useState(false);
  const [showFootNoteZeroTarget, setShowFootNoteZeroTarget] = useState(false);

  const imagePreviewRef = useRef(null);

  const { user } = useUser();

  const { overallProgress: progress, progressColorHex } = overallProgress || {};

  const getPreSignUrl = async () => {
    const presignQuery = {
      directory: 'files',
      extension: 'jpeg',
      public: false
    };
    const { data } = await getPerformancePreSignUrl(presignQuery);
    if (data) {
      return data;
    }
  };

  const exportImage = async (file) => {
    const presignData = await getPreSignUrl();
    if (presignData) {
      const { status } = await S3Client(presignData?.uploadUrl, file);
      if (status == 200) {
        const receiverIds = receivers?.map((receiver) => receiver?.id);
        const params = {
          recipientIds: receiverIds,
          image: presignData?.downloadUrl
        };
        const { data, error } = await exportObjectiveProgressSummary(params);
        if (data?.[0]?.status == 'wabp_invalid' || error) {
          setToast('error', data);
        } else {
          setToast('success');
        }
        setShowPreview(false);
        setShowModal(false);
      }
    }
    setIsExporting(false);
  };

  const onClickExport = () => {
    setIsConvertingToImage(true);
    setIsExporting(true);
    setToast('info');
    domtoimage
      .toJpeg(imagePreviewRef.current)
      .then(
        async (dataUrl) => {
          setIsConvertingToImage(false);
          const file = getFileFromDataUrl(
            dataUrl,
            'objective-progress-summary.jpeg'
          );
          await exportImage(file);
        },
        {
          width: 1080,
          height: imagePreviewRef?.current?.clientHeight
        }
      )
      .catch(() => {
        setToast('error');
        setIsConvertingToImage(false);
        setIsExporting(false);
      });
  };

  useEffect(() => {
    const setBase64 = async () => {
      owner.profilePicture = await getBase64FromUrl(owner.profilePicture);
      setIsAvatarReady(true);
    };
    setBase64();
  }, [owner]);

  const imagePreview = document.querySelectorAll(`[id^="image-preview"]`)[0];

  return ReactDOM.createPortal(
    <PreviewWrapper
      isConvertingToImage={isConvertingToImage}
      setShowPreview={setShowPreview}
    >
      <div
        className="w-[1080px] bg-n-000 cursor-default"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-end items-center mx-[24px] py-[16px] border-solid border-0 border-b border-n-400">
          <Button
            onClick={() => onClickExport()}
            disabled={!isAvatarReady || progress == null}
            isLoading={isExporting}
          >
            {getObjectiveLocale('Export')}
          </Button>
        </div>
        <div className="w-[1080px] bg-n-000 p-[24px]" ref={imagePreviewRef}>
          <div className="flex justify-between mb-[24px]">
            <p className="typography-h300 text-n-600 uppercase">
              {getObjectiveLocale('PROGRESS GOAL SUMMARY')}
            </p>
            <div className="flex flex-col items-end">
              <p className="typography-h100 text-n-600">
                {getObjectiveLocale('Downloaded by')} {user?.name}
              </p>
              <p className="typography-h100 text-n-600">{user?.jobTitle}</p>
              <p className="typography-h100 text-n-600">
                {dayjs().format('D MMM YYYY, HH:mm')}
              </p>
            </div>
          </div>
          <p className="typography-h500 text-n-900 mb-[24px]">
            {parentObjective
              ? parentObjective?.name
              : getObjectiveLocale('My Goals')}
            {parentObjective?.involvements?.filter(
              (involvement) => involvement?.role === 'assignee'
            )?.length > 1 && (
              <span className="text-n-600 ml-[8px]">{`(${getObjectiveLocale(
                'Multiple Owner'
              )})`}</span>
            )}
          </p>
          <div className="flex mb-[36px]">
            <div className="mr-[16px] w-[320px]">
              <p className="typography-paragraph text-n-900 mb-[16px]">
                {getObjectiveLocale('Owner')}
              </p>
              <TwoLine
                user={owner}
                title={owner?.name}
                subtitle={owner?.jobTitle}
                size={40}
                truncateSubtitle={false}
              />
            </div>
            <div>
              <p className="typography-paragraph text-n-900 mb-[16px]">
                {getObjectiveLocale('Overall Performance Metrics')}
              </p>
              <div className="w-[224px] p-[16px] border-solid border-[1px] border-n-400 rounded-[4px]">
                <p className="typography-h400 text-n-800 mb-[16px]">
                  {getObjectiveLocale('Overall Progress')}
                </p>
                <div className="flex items-end">
                  <p className="typography-h600 text-n-900 max-w-full truncate">
                    {getNumberLocale(progress)}
                  </p>
                  <p className="typography-h600 text-n-900 mr-[8px]">%</p>
                  <p
                    className={`typography-h100 whitespace-nowrap ${whichColor[progressColorHex]}`}
                  >
                    {whichStatus[progressColorHex]}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ObjectiveProgressSummaryTable
            parentObjective={parentObjective}
            objectives={objectives}
            setShowFootNoteLessIsBetter={setShowFootNoteLessIsBetter}
            setShowFootNoteZeroTarget={setShowFootNoteZeroTarget}
          />
          {showFootNoteLessIsBetter && (
            <p className="typography-h100 text-n-800 italic mt-[8px]">
              *{getObjectiveLocale('Less is better')}
            </p>
          )}
          {showFootNoteZeroTarget && (
            <p className="typography-h100 text-n-800 italic mt-[8px]">
              **{getObjectiveLocale('Goal with zero target')}
            </p>
          )}
        </div>
      </div>
    </PreviewWrapper>,
    imagePreview
  );
};

export default ObjectiveProgressSummaryPreview;

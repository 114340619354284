import React, { useEffect, useState } from 'react';

import objectiveComparativeKeys from 'consts/queryKeys/objectiveComparativeKeys';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import {
  useGetListUserPreset,
  useSharePreset
} from 'hooks/api/useObjectivesComparative';
import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import { getExtraInformation, getObjectiveLocale } from 'utils/HelperUtils';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Modal from 'components/design-system/Modal';
import TwoLine from 'components/design-system/ProfileType';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';

const ModalSharePreset = ({ onClose }) => {
  const { addToast } = useToastContext();
  const { refetchQueries } = useRefetchQuery();

  const preset = useObjectiveComparative((state) => state.preset);

  const [listUser, setListUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const params = {
    objectiveIds: [1, 2, 3]
  };

  const {
    data: usersData,
    isLoading: isLoadingGetUsers,
    isFetchingNextPage: isFetchingUsersNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetListUserPreset(params);

  const { mutate: shareUsersPreset, isLoading: isSharingPreset } =
    useSharePreset(preset?.id);

  const sharePreset = () => {
    const payloadBody = { placementIds: selectedUser };

    shareUsersPreset(payloadBody, {
      onSuccess: () => {
        refetchQueries(objectiveComparativeKeys.default);
        addToast({
          title: 'Success',
          msg: 'Your preset has been sent to selected users',
          type: 'success'
        });
        onClose();
      }
    });
  };

  const selectUser = (id) => {
    if (selectedUser.includes(id)) {
      setSelectedUser([
        ...selectedUser.filter((selectedId) => selectedId !== id)
      ]);
    } else {
      setSelectedUser([...selectedUser, id]);
    }
  };

  useEffect(() => {
    if (usersData?.pages[0]?.data?.length > 0) {
      setListUser(usersData?.pages[0]?.data);
    }
  }, [usersData]);

  return (
    <Modal onClose={onClose} minWidth={560} customClass="w-[560px]">
      <Modal.Header title="Share Preset" onClose={onClose} />
      <Modal.Body>
        <div className="flex flex-col w-full px-[24px] my-[4px]">
          <div className="flex flex-row items-start w-full h-[64px] py-[8px] px-[16px] bg-b-200 rounded-[8px] mb-[8px]">
            <SVGIcon size={24} iconName="icon-info" fillColor="var(--b-600)" />
            <p className="typography-paragraph text-n-900 ml-[18px]">
              {getObjectiveLocale(
                'You can only share this preset to employee who has permission to see these goals'
              )}
            </p>
          </div>

          <AdvancedFilter
            filterOptions={['search', 'menuFilter']}
            id="modalSharePreset"
          />

          {listUser?.map((item) => (
            <div
              className={`flex flex-row h-[56px] py-[6px] gap-[16px] cursor-pointer ${
                selectedUser.includes(item?.id) ? 'bg-base-30024' : ''
              }`}
              onClick={() => selectUser(item?.id)}
              key={item?.id}
            >
              <Checkbox
                id={item?.id}
                checked={selectedUser.includes(item?.id)}
                onClick={() => selectUser(item?.id)}
              />

              <TwoLine
                user={item.user}
                title={item?.user?.name}
                subtitle={getExtraInformation(item?.user?.name, item?.name)}
                customClass="!w-[calc(100%-160px)]"
              />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={{
          text: getObjectiveLocale('Share'),
          dataCy: 'share-preset',
          onClick: sharePreset
        }}
        secondaryButton={{
          text: getObjectiveLocale('Cancel'),
          dataCy: 'cancel-share',
          onClick: onClose
        }}
        useBorder={false}
      />
    </Modal>
  );
};

export default ModalSharePreset;

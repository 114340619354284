import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

const AuthAppliBCA = (props) => {
  const { organization, appType } = useUser();
  const [isShowPasswordValue, setIsShowPasswordValue] = useState(false);
  const typeInputField = isShowPasswordValue ? 'text' : 'password';

  const appName =
    appType === 'culture'
      ? organization?.cultureAppName
      : organization?.appName;

  return (
    <React.Fragment>
      <span className="auth-title text-center w-[304px] typography-h400-longform">
        {appName || getObjectiveLocale('Authentication')}
      </span>
      <span className="auth-text" data-cy="input-login-data">
        {getObjectiveLocale('Input verification code.')}
      </span>
      <div class="auth-notif-wrapper mb-[16px]">
        <div class="auth-notif-label-wrapper">
          <span class="auth-notif-label">
            {getObjectiveLocale('RESPONSE KEYBCA')}
          </span>
          <span class="auth-notif-label-title">
            {getObjectiveLocale('APPLI 1')}
          </span>
        </div>
      </div>
      <div className="container-input">
        <label className="styled-label" htmlFor={'password'}>
          {getObjectiveLocale('Enter the code')}
        </label>
        <input
          className={`styled-input ${
            props.errorShake ? 'input-error-shake' : ''
          }`}
          data-cy={'auth-appli'}
          name={'password'}
          id={'password'}
          type={typeInputField}
          onChange={(e) => props.setDeviceToken(e.currentTarget.value)}
          onKeyUp={props.handleKeyPress}
        />
      </div>
      {props.showWarning && (
        <div className="container-error">
          <span data-cy="wrong-login-data" className="error-message">
            {props.warningMessage}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

AuthAppliBCA.contextTypes = {
  handleChange: PropTypes.func
};

AuthAppliBCA.propTypes = {
  handleKeyPress: PropTypes.func,
  routeToRecovery: PropTypes.func,
  showWarning: PropTypes.bool,
  warningMessage: PropTypes.string
};

export default withRouter(AuthAppliBCA);

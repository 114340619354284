import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { updateWeightConfig } from 'client/ObjectivesClient';
import {
  addGoalWeight,
  deleteGoalWeight,
  getDatas,
  updateGoalWeight
} from 'client/admin/GoalWeight';
import { useAuth } from 'context/AuthContext';
import { useReload } from 'context/ReloadContext';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale, mappingColor } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import Checkbox from 'components/shared/Checkbox';
import LoadingComponent from 'components/shared/LoadingComponent';
import RadioButton from 'components/shared/RadioButton';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import Tooltips from 'components/shared/Tooltips/Tooltips';
import Modal from 'components/shared/modal/Modal';

import ObjectivesFormBoxV2 from '../form/ObjectivesFormBoxV2';

function Type({ setIsSaving, configData, isReadOnly }) {
  const { refetch } = useAuth();
  const { reload, reloadUser } = useReload();
  const [typeData, setTypeData] = useState({});
  const [requestBody, setRequestBody] = useState({});
  const [requestConfigBody, setRequestConfigBody] = useState({});
  const [typeConfig, setTypeConfig] = useState(configData);
  const [savedColor, setSavedColor] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isToggle, setIsToggle] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);
  const [preDeletedId, setPreDeletedId] = useState(0);
  const [maximumType, setMaximumType] = useState(0);
  const [temporaryCountType, setTemporaryCountType] = useState(0);
  const debouncedRequestBody = useDebounce(typeData, 2000);
  const debouncedTypeConfig = useDebounce(typeConfig, 1000);
  const [colorData] = useState({
    color: [
      '14532D',
      '7C2D12',
      '1E3A8A',
      '713F12',
      '78350F',
      '881337',
      '164E63',
      '064E3B',
      '701A75',
      '312E81',
      '0C4A6E',
      '365314',
      '134E4A'
    ],
    backgroundColor: [
      'DCFCE7',
      'FFDAD0',
      'DBEAFE',
      'FEF9C3',
      'FEF3C7',
      'FFE4E6',
      'CFFAFE',
      'D1FAE5',
      'FAE8FF',
      'E0E7FF',
      'E0F2FE',
      'ECFCCB',
      'CCFBF1'
    ]
  });
  const [newGoalType, setNewGoalType] = useState();

  const goalTypePreference = [
    { id: 1, description: 'Unselected', value: false },
    { id: 2, description: 'Must be selected', value: true }
  ];

  const colorSelection = colorData.color
    .map((value) => {
      let { colorBackground } = mappingColor(value);
      return {
        id: value.toUpperCase(),
        value: colorBackground,
        colorHex: value
      };
    })
    .sort((a, b) => a.value.localeCompare(b.value));

  const getData = async () => {
    const { data: responseData } = await getDatas();
    if (responseData) {
      setTypeData(responseData);
      setMaximumType(responseData.length);
      setTemporaryCountType(responseData.length);
      const colors = responseData.map((data) => {
        return data.colorHex;
      });
      setSavedColor(colors);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    await getData();
    setIsLoading(false);
  };

  const randColor = () => {
    const unSelectedColor = colorData.color.filter(
      (color) => !savedColor.includes(color)
    );
    return unSelectedColor[0];
  };

  const addNewType = () => {
    let newData = [...typeData];
    const randomColor = randColor();

    newData.push({
      id: null,
      name: '',
      weight: 0,
      colorHex: '230264',
      bgColorHex: 'EDE9FE',
      isDefault: false
    });
    setTypeData(newData);
    setSavedColor([...savedColor, randomColor]);
    setTemporaryCountType(temporaryCountType + 1);
  };

  const handleAddNewType = async () => {
    if (Object.entries(requestBody).length > 0) {
      setIsSaving(true);

      const newCategory = requestBody.find((data) => {
        return data.id == null;
      });

      if (newCategory) {
        delete newCategory.id;
        await addGoalWeight([newCategory]);
      } else {
        let body = requestBody?.length ? requestBody : [requestBody];
        await updateGoalWeight(body);
      }

      setRequestBody({});
      await getData();
      reload({ reloadUser: !reloadUser });
    }
  };

  const handleInput = (index, e, type) => {
    let currentInput = [...typeData];
    switch (type) {
      case 'name':
        currentInput[index].name = e.target.value;
        break;
      case 'weight': {
        let weight = 0;
        let inputWeight = e.target.value;
        if (inputWeight < 1) {
          weight = 1;
        } else if (inputWeight > 100) {
          weight = 100;
        } else {
          weight = inputWeight;
        }

        currentInput[index].weight = inputWeight === '' ? '' : weight;
        break;
      }
      case 'default':
        currentInput.map((value, idx) => (value.isDefault = idx === index));
        break;
    }

    if (type !== 'default' && currentInput[index].weight === '') {
      currentInput[index].weight = 1;
    }

    setRequestBody(currentInput);
    setTypeData(currentInput);
  };

  const toggleWeight = (index) => {
    let currentInput = [...typeData];
    if (currentInput[index].weight > 0) {
      currentInput[index].weight = 0;
    } else {
      currentInput[index].weight = 1;
    }

    setTypeData(currentInput);
    setRequestBody(currentInput);
  };

  const changeColor = async (index, e) => {
    setIsSaving(true);
    let currentInput = [...typeData];
    let selectedColor = mappingColor(e, 'bgColorHex');
    currentInput[index].colorHex = e;
    currentInput[index].bgColorHex = selectedColor;

    setTypeData(currentInput);
    setRequestBody(currentInput);
  };

  const handleWeightInputConfig = (e) => {
    setIsToggle(false);

    const newTypeConfig = { ...typeConfig };
    newTypeConfig.objectivesConfig[e.target.name] = e.target.value;
    setTypeConfig(newTypeConfig);

    setRequestConfigBody({ [e.target.name]: parseInt(e.target.value) });
  };

  const handleChangeConfig = async (body, key = null) => {
    if (
      isToggle ||
      !isNaN(Object.values(body)) ||
      Object.keys(body).length > 0
    ) {
      setIsSaving(true);
      let updatedBody = null;
      if (key === 'objectivesConfig') {
        updatedBody = {
          objectivesConfig: body
        };
      } else {
        updatedBody = body;
      }

      await updateWeightConfig(updatedBody);
      setTimeout(() => refetch(), 500);
    }
  };

  const deleteRow = (id, index, color) => {
    if (id === null) {
      let newData = [...typeData];
      newData.splice(index, 1);
      setTypeData(newData);
      setTemporaryCountType(temporaryCountType - 1);
    } else {
      setPreDeletedId(id);
      setModalConfirmDeleteIsOpen(true);
      setNewGoalType(typeData.filter((type) => type.id !== id)[0]);
    }
    const currentColor = savedColor.filter(
      (currentSavedColor) => currentSavedColor !== color
    );
    setSavedColor(currentColor);
  };

  const onConfirmDelete = async () => {
    setIsSaving(true);
    const selected = { ...newGoalType };
    const body = {
      objectiveCategoryId: selected.id
    };
    await deleteGoalWeight(preDeletedId, body);
    await updateGoalWeight([{ ...selected, isDefault: true }]);

    const newTypeData = typeData.reduce((data, type) => {
      if (type.id === selected.id && type.isDefault) {
        data.push({ ...type, isDefault: true });
      } else if (type.id !== preDeletedId) {
        data.push(type);
      }
      return data;
    }, []);
    setTypeData(newTypeData);
    setMaximumType(maximumType - 1);
    setTemporaryCountType(maximumType - 1);

    setModalConfirmDeleteIsOpen(false);
    reload({ reloadUser: !reloadUser });
  };

  const handleChangeTypeConfig = (value, key) => {
    const newTypeConfig = cloneDeep(typeConfig);
    let body;

    if (key === 'mandatoryType') {
      newTypeConfig.mandatoryObjectivesCategory = value;
      body = { mandatoryObjectivesCategory: value };
    } else if (key === 'useDefaultType') {
      newTypeConfig.useDefaultObjectiveCategory =
        !typeConfig.useDefaultObjectiveCategory;
      body = {
        useDefaultObjectiveCategory: !typeConfig.useDefaultObjectiveCategory
      };
    } else {
      setIsToggle(true);
      newTypeConfig.objectivesConfig['maxSumOfObjectivesWeightTypeOn'] =
        !typeConfig.objectivesConfig.maxSumOfObjectivesWeightTypeOn;

      newTypeConfig.objectivesConfig['maxSumOfObjectivesWeightType'] =
        newTypeConfig.objectivesConfig.maxSumOfObjectivesWeightTypeOn
          ? 100
          : null;

      body = {
        maxSumOfObjectivesWeightTypeOn:
          !typeConfig.objectivesConfig.maxSumOfObjectivesWeightTypeOn
      };
      setRequestConfigBody(body);
    }

    handleChangeConfig(
      body,
      key === 'objectivesConfig' ? 'objectivesConfig' : null
    );
    setTypeConfig(newTypeConfig);
    setTimeout(() => refetch(), 500);
  };

  const handleChangeModalDelete = (selectedData) => {
    setNewGoalType(selectedData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (
      !isEmpty(requestConfigBody) &&
      requestConfigBody?.maxSumOfObjectivesWeightType > 0 &&
      !isToggle &&
      !Object.values(requestConfigBody).some((objectBody) => isNaN(objectBody))
    ) {
      handleChangeConfig(requestConfigBody, 'objectivesConfig');
    }
  }, [debouncedTypeConfig]);

  useEffect(() => {
    let validName =
      requestBody?.name?.length >= 3 ||
      (requestBody?.[0]?.name?.length >= 3 &&
        requestBody?.[1]?.name?.length >= 3);

    if (!isEmpty(requestBody) && validName) {
      handleAddNewType();
    }
  }, [debouncedRequestBody]);

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          {modalConfirmDeleteIsOpen && (
            <Modal
              title={`${getObjectiveLocale('Delete Goal')} Type Test`}
              withCloseIcon={false}
              headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
              className="admin-page max-h-[200px] w-[400px]"
              description={getObjectiveLocale(
                'All goals with deleted goal type will not have type anymore. Set a new goal type for those goals:'
              )}
              withPrimaryBtn={{
                danger: true,
                title: 'Delete',
                dataCy: 'delete-goal',
                disabled: isReadOnly || maximumType <= 2,
                onClick: () => onConfirmDelete()
              }}
              withSecondaryBtn={{
                disabled: isReadOnly,
                title: 'Cancel',
                dataCy: 'cancel-delete-type',
                onClick: () => setModalConfirmDeleteIsOpen(false)
              }}
              headerClass="h-[72px]"
              footerClass="h-[84px]"
              dataCyModal="delete-goal-modal"
            >
              <Dropdown customClass="w-full">
                <Dropdown.Trigger
                  customClass="w-full"
                  dataCy="dropdown-color"
                  disabled={isReadOnly}
                >
                  <Trigger
                    icon={false}
                    topLabel={false}
                    label={false}
                    text={newGoalType?.name}
                    customWrapperClass="w-full"
                    disabled={isReadOnly}
                  />
                </Dropdown.Trigger>
                <Dropdown.MenuItems customClass="w-full">
                  {typeData
                    .filter((type) => type.id !== preDeletedId)
                    .map((type, index) => (
                      <Dropdown.MenuItem
                        key={index}
                        onClick={() => handleChangeModalDelete(type)}
                      >
                        {type.name}
                      </Dropdown.MenuItem>
                    ))}
                </Dropdown.MenuItems>
              </Dropdown>
            </Modal>
          )}
          <div
            className="container-weight-type top w-[768px] mt-[24px]"
            data-cy="weight-type-section"
          >
            <div className="pl-[16px] pt-[16px]">
              <div className="weight-type-title typography-h400 text-n-900">
                Weight Type
              </div>
              <p className="typography-secondary fs-14 mt-[4px] mb-[16px]">
                Set the organization weight type (minimum 2, maximum 20)
              </p>
              <p className="typography-secondary fs-12 mb-[8px]">
                {getObjectiveLocale('Goal type preference')}
              </p>

              {goalTypePreference.map((val, i) => (
                <div
                  key={val.id}
                  className={`wrapper-type-radio-button flex items-center whitespace-nowrap ${
                    i == 0 ? 'mb-[8px]' : ''
                  }`}
                >
                  <RadioButton
                    dataCy={`goal-type-preference-${val.value}`}
                    size="small"
                    value={val.id}
                    id={val.id}
                    key={i}
                    checked={
                      typeConfig.mandatoryObjectivesCategory === val.value
                    }
                    onChange={() =>
                      handleChangeTypeConfig(val.value, 'mandatoryType')
                    }
                    addClass="h-[24px]"
                    disabled={isReadOnly}
                  >
                    <span className="ml-[8px] fs-14 text-n-900">
                      {val.description}
                    </span>
                  </RadioButton>
                </div>
              ))}
            </div>

            <Checkbox
              name="use-default-type-checkbox"
              id="use-default-type-checkbox"
              checked={typeConfig.useDefaultObjectiveCategory}
              onChange={() => handleChangeTypeConfig(null, 'useDefaultType')}
              dataCy="use-default-type"
              customContainerClass="pl-[16px]"
              disabled={isReadOnly}
            >
              <span className="typography-h400 text-n-900 ml-[8px] fw-normal">
                Use default type
              </span>
            </Checkbox>

            {typeData.map((value, index) => {
              let errorMessage;
              const nameLength = value.name.length;
              const errorCheck = nameLength === 0 || nameLength < 3;

              if (nameLength === 0) {
                errorMessage = "Can't be blank";
              } else if (nameLength < 3) {
                errorMessage = 'Minimum 3 letters';
              }
              const { twBg, twText } = mappingColor(
                value.colorHex.toUpperCase(),
                'tw'
              );

              return (
                <div
                  id={`myform-${index}`}
                  key={index}
                  className={`type-input-container goal-type-input-container pl-[16px] ${
                    errorCheck && 'mb-[44px]'
                  } ${index == 0 && 'mt-[38px]'}`}
                >
                  <div className="typography-paragraph mr-[16px]">
                    <div className="type-number w-[24px] h-[24px] bg-n-600 rounded-[50px] relative">
                      <span className="text-n-000 absolute">{index + 1}</span>
                    </div>
                  </div>
                  <span className="container-input width-auto mr-[40px] relative self-left">
                    {index === 0 && (
                      <div className="header-weight-type absolute flex justify-center items-center">
                        <span className="fs-12 text-n-800 mr-[4px]">Name</span>
                        <Tooltips
                          text="Minimum 3 letters, maximum 15 letters"
                          addClass="h-[16px]"
                          isEllipsis
                        >
                          <SVGIcon
                            size="16"
                            fillColor="var(--n-600)"
                            iconName="icon-info"
                          />
                        </Tooltips>
                      </div>
                    )}
                    <div>
                      <input
                        className={`styled-input name-weight-input border-${
                          errorCheck && 'red'
                        }`}
                        placeholder={
                          value.name ? value.name : 'ex: Company OKR'
                        }
                        id={'name' + index}
                        type="text"
                        required
                        value={value.name}
                        maxLength="20"
                        onChange={(e) => handleInput(index, e, 'name')}
                        data-cy="name-weight"
                        autoComplete="off"
                        disabled={isReadOnly}
                      />
                      {errorCheck && (
                        <p className="fs-12 text-r-600 mt-[8px] mb-[16px]">
                          {errorMessage}
                        </p>
                      )}
                    </div>
                  </span>
                  <div className="relative">
                    {index === 0 && (
                      <div className="header-weight-type weight-toggle absolute">
                        <span className="fs-12 text-n-800">Weight</span>
                      </div>
                    )}
                  </div>
                  <SmallToggleSwitchPurple
                    idStr={'use-weight-' + index}
                    isChecked={value.weight !== 0}
                    onChange={() => toggleWeight(index)}
                    disabled={isReadOnly}
                  />
                  <span className="container-input container-weight ml-[16px]">
                    {(value?.weight > 0 || value?.weight === '') && (
                      <input
                        placeholder="1"
                        id={'weight' + index}
                        type="number"
                        required
                        value={value.weight}
                        min="1"
                        max="100"
                        onChange={(e) => handleInput(index, e, 'weight')}
                        className="styled-input weight weight-input"
                        data-cy="amount-weight"
                        disabled={isReadOnly}
                      />
                    )}
                  </span>
                  <div
                    className="color-container width-auto mr-[40px] relative"
                    value={value.colorHex}
                    id={'color' + (index + 1)}
                  >
                    {index === 0 && (
                      <div className="header-weight-type color absolute">
                        <span className="fs-12 text-n-800">Color</span>
                      </div>
                    )}
                    <Dropdown>
                      <Dropdown.Trigger dataCy="dropdown-color">
                        <div
                          className={`w-[36px] h-[36px] rounded-[4px] ${twText} ${twBg}`}
                        />
                      </Dropdown.Trigger>
                      <Dropdown.MenuItems
                        customClass="w-[144px]"
                        maxHeight={312}
                        dataCy="dropdown-color"
                      >
                        {colorSelection.map((color) => (
                          <Dropdown.MenuItem
                            onClick={() => changeColor(index, color.id)}
                            dataCy="dropdown-color"
                            key={color}
                          >
                            <div
                              className={`h-[20px] w-[20px] mr-[8px] rounded-[50px] ${
                                mappingColor(color.id, 'tw').twBg
                              }`}
                            />
                            {color.value}
                          </Dropdown.MenuItem>
                        ))}
                      </Dropdown.MenuItems>
                    </Dropdown>
                  </div>
                  {typeConfig.useDefaultObjectiveCategory && (
                    <div className="flex items-center relative">
                      {index === 0 && (
                        <div className="header-weight-type default-type absolute">
                          <span className="fs-12 text-n-800">Default</span>
                        </div>
                      )}
                      <div className="w-[36px] h-[24px] flex-center-center">
                        <RadioButton
                          size="small"
                          name="default"
                          id={`default-${index}`}
                          value={`default-${index}`}
                          checked={typeData[index]?.isDefault}
                          onChange={(e) => handleInput(index, e, 'default')}
                          dataCy={`default-${index}`}
                          disabled={isReadOnly}
                        >
                          <span>&nbsp;</span>
                        </RadioButton>
                      </div>
                    </div>
                  )}
                  {temporaryCountType > 2 && (
                    <div
                      className={`purple-link ${
                        typeConfig.useDefaultObjectiveCategory && 'ml-[40px]'
                      }`}
                    >
                      <SVGIcon
                        size="24"
                        fillColor="var(--n-600)"
                        iconName="icon-delete"
                        customClass="icon-delete-goal"
                        onClick={() => {
                          deleteRow(value.id, index, value.colorHex);
                        }}
                        dataCy="delete-weight"
                      />
                    </div>
                  )}
                </div>
              );
            })}
            {temporaryCountType < 15 && (
              <div className="type-input-container goal-type-input-container pl-[16px] mb-[16px]">
                <Button.Tertiary
                  onClick={addNewType}
                  datacy="add-new-type"
                  disabled={isReadOnly}
                >
                  <i className="fa fa-plus" /> Add new type
                </Button.Tertiary>
              </div>
            )}
          </div>
          <div
            className="objective-container container-weight-type mt-[24px]"
            data-cy="weight-type-section"
          >
            <ObjectivesFormBoxV2
              title={getObjectiveLocale(
                'Maximum for the sum off all objective weights'
              )}
              isChecked={
                typeConfig.objectivesConfig.maxSumOfObjectivesWeightTypeOn
              }
              desc={getObjectiveLocale(
                "Show the expected max value for total weight of user's objectives"
              )}
              onChange={() => handleChangeTypeConfig(null, 'objectivesConfig')}
              idToogleSwitch="maxSumOfObjectivesWeightTypeOn"
              useBorder
              className="px-[16px] py-[16px] w-[768px]"
              disabled={isReadOnly}
            >
              {typeConfig.objectivesConfig.maxSumOfObjectivesWeightTypeOn && (
                <div className="input-weight-section d-block">
                  <div className="big-description-input">
                    <span className="typography-paragraph typography-secondary fw-normal fs-12">
                      Maximum total weight
                    </span>
                  </div>
                  <div className="input-weight container-input">
                    <input
                      type="number"
                      name="maxSumOfObjectivesWeightType"
                      placeholder="e.g 100"
                      className={`btn-filter-name styled-input
                        ${
                          (!typeConfig.objectivesConfig
                            .maxSumOfObjectivesWeightType ||
                            typeConfig.objectivesConfig
                              .maxSumOfObjectivesWeightType == 0) &&
                          'border-red'
                        }`}
                      value={
                        typeConfig.objectivesConfig.maxSumOfObjectivesWeightType
                      }
                      onChange={(e) => handleWeightInputConfig(e)}
                      data-cy="maxSumOfObjectivesWeightType"
                      disabled={isReadOnly}
                    />
                  </div>
                </div>
              )}
            </ObjectivesFormBoxV2>
          </div>
        </>
      )}
    </>
  );
}

export default Type;

import React from 'react';

import ErrorBoundary from 'pages/ErrorBoundary';
import Status from 'pages/dashboardOverallViewProgress/new-compact-statistics/Status';
import TopParent from 'pages/dashboardOverallViewProgress/new-compact-statistics/TopParent';
import TotalWeight from 'src/pages/dashboardOverallViewProgress/new-compact-statistics/TotalWeight';

const NewOverallProgressContent = ({
  visibility,
  objectiveId,
  objectiveConfigs,
  dispatchFilter = () => null,
  page,
  customClassContainer = '',
  customStyleContainer,
  annual,
  useCompactLayout = false,
  children,
  listStatus = [],
  isWeightEditable = true,
  adjustWeightModalCustomParams,
  adjustWeightModalEventOnUpdate
}) => {
  const isVisible = (key) => visibility?.includes(key);

  return (
    <div
      className={`bg-n-000 flex flex-row gap-[40px] px-[24px] py-[16px] ${
        useCompactLayout ? 'w-full justify-between' : 'items-center'
      } ${customClassContainer}`}
      style={customStyleContainer}
    >
      {isVisible('topParent') && (
        <TopParent
          objectiveId={objectiveId}
          objectiveConfigs={objectiveConfigs}
          page={page}
          annual={annual}
          useCompactLayout={useCompactLayout}
        />
      )}
      {isVisible('totalWeight') && (
        <TotalWeight
          objectiveId={objectiveId}
          objectiveConfigs={objectiveConfigs}
          page={page}
          annual={annual}
          useCompactLayout={useCompactLayout}
          isWeightEditable={isWeightEditable}
          modalCustomParams={adjustWeightModalCustomParams}
          modalEventOnUpdate={adjustWeightModalEventOnUpdate}
        />
      )}
      {isVisible('status') && (
        <Status
          objectiveId={objectiveId}
          objectiveConfigs={objectiveConfigs}
          dispatchFilter={dispatchFilter}
          page={page}
          annual={annual}
          useCompactLayout={useCompactLayout}
          list={listStatus}
        />
      )}

      {children && children}
    </div>
  );
};

const NewOverallProgress = (props) => {
  return (
    <React.Suspense fallback={<div></div>}>
      <ErrorBoundary>
        <NewOverallProgressContent {...props} />
      </ErrorBoundary>
    </React.Suspense>
  );
};

export default NewOverallProgress;

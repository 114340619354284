import React from 'react';

import ProfileEvidence from './ProfileEvidence';
import WrapperAssessmentScore from './WrapperAssessmentScore';

const CardSuggestedOngoingSidebar = ({
  showOption,
  answersData,
  trackType
}) => {
  const goalsOrTasks = trackType.includes('goals') ? 'goal' : 'task';

  return (
    <div
      id="container-suggested-ongoing-sidebar"
      className="mt-[24px]"
      data-cy={'container-suggested-ongoing-sidebar'}
    >
      {answersData?.map((data, idx) => (
        <div
          key={idx}
          className="evidence-border px-[16px] py-[16px] mb-[16px]"
        >
          <h4 className={'mb-[8px] typography-h500-longform capitalize'}>
            overall {goalsOrTasks} ongoing scoring
          </h4>

          <div className="flex items-center mb-[16px]">
            {data.personalObjectiveCount > 0 && (
              <p className="evidence-score-box-grey typography-h200">
                Based on: {''}
                <span className="typography-h100">
                  {data.personalObjectiveCount} {goalsOrTasks}
                  {data.personalObjectiveCount > 1 ? 's' : ''}
                </span>
              </p>
            )}
            {data.personalMandays > 0 && (
              <p className="evidence-score-box-grey typography-h200">
                Mandays: {''}
                <span className="typography-h100">
                  {data.personalMandays} total
                  {data.personalMandays > 1 ? 's' : ''}
                </span>
              </p>
            )}
          </div>
          <ProfileEvidence profileData={data?.actor} />

          <WrapperAssessmentScore
            showOption={showOption}
            statusColorHex={data?.personalScore.colorHex}
            statusName={data?.personalScore.name}
            personalScore={data?.personalScore.score}
            isLastIndex={idx !== answersData.length - 1}
            initialScore={data?.initialScore}
          />
        </div>
      ))}
    </div>
  );
};

export default CardSuggestedOngoingSidebar;

import React, { createContext, useContext } from 'react';
import { useInfiniteQuery } from 'react-query';

import flatten from 'lodash/flatten';

import { getChildTeam } from 'client/ObjectivesClient';
import useFilter from 'hooks/useFilter';
import { restructureFilter } from 'utils/ObjectivesHelper';

const TeamSquadContext = createContext();

const TeamSquadProvider = (props) => {
  const filter = useFilter((state) => state.filterById?.teamSquadFilter);
  let params = restructureFilter(filter);

  const fetchTeams = async (olderThan) => {
    let allParams = {
      reviewsVisibility: 1,
      limit: 15,
      teamType: 'squad',
      query: params?.q,
      ...(olderThan && { olderThan: olderThan }),
      ...params
    };

    return getChildTeam(props.teamId, allParams);
  };

  const queryKey = ['childTribe', 'myteams', params];

  const {
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    data,
    refetch
  } = useInfiniteQuery(queryKey, ({ pageParam }) => fetchTeams(pageParam), {
    getNextPageParam: (lastPage) =>
      lastPage?.pagination?.next?.olderThan || undefined,
    enabled: props?.teamType == 'tribe'
  });

  let newSquads = flatten(
    data?.pages?.map((page) => {
      return page?.data?.map((d) => {
        return d;
      });
    })
  );
  newSquads = newSquads?.filter((squad) => squad);

  return (
    <TeamSquadContext.Provider
      value={{
        data: newSquads,
        refetchSquad: refetch,
        hasNextPage: hasNextPage,
        fetchNextPage: fetchNextPage,
        isFetching: isFetching,
        isFetchingNextPage: isFetchingNextPage
      }}
      {...props}
    />
  );
};

const useTeamSquad = () => {
  const {
    data,
    refetchSquad,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage
  } = useContext(TeamSquadContext);

  return {
    data: data,
    refetchSquad: refetchSquad,
    hasNextPage: hasNextPage,
    fetchNextPage: fetchNextPage,
    isFetching: isFetching,
    isFetchingNextPage: isFetchingNextPage
  };
};

export { TeamSquadProvider, useTeamSquad };

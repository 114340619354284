import React from 'react';

import Shimmer from 'components/design-system/shimmer/Shimmer';

function SidebarRatingTemplateSkeleton() {
  return (
    <div className="sidebar-rating-template surface px-[24px] py-[16px]">
      <div className="header py-[12px]">
        <div>
          <div className="d-inline mr-[12px]">
            <Shimmer
              customClass="rounded-lg"
              width="80px"
              height="24px"
              borderRadius="4px"
            />
          </div>
          <div className="d-inline">
            <Shimmer
              customClass="rounded-lg"
              width="80px"
              height="24px"
              borderRadius="4px"
            />
          </div>
        </div>
        <div className="close">
          <Shimmer
            customClass="rounded-lg"
            width="24px"
            height="24px"
            borderRadius="4px"
          />
        </div>
      </div>
      <div className="sidebar-body mt-[16px]">
        <div className="detail mb-[24px]">
          <div className="mb-[24px]">
            <span>
              <Shimmer
                customClass="rounded-lg"
                width="100px"
                height="24px"
                borderRadius="4px"
              />
            </span>
          </div>
          <div className="mb-[32px]">
            <Shimmer
              customClass="rounded-lg"
              width="100px"
              height="24px"
              borderRadius="4px"
            />
            <Shimmer
              customClass="rounded-lg"
              width="100%"
              height="50px"
              borderRadius="4px"
              widthRandomness={0}
            />
            <Shimmer
              customClass="rounded-lg"
              width="100%"
              height="50px"
              borderRadius="4px"
              widthRandomness={0}
            />
            <Shimmer
              customClass="rounded-lg"
              width="100%"
              height="50px"
              borderRadius="4px"
              widthRandomness={0}
            />
          </div>
        </div>
        <div className="rating-config pb-[8px] mb-[24px]">
          <Shimmer
            customClass="rounded-lg"
            width="100px"
            height="24px"
            borderRadius="4px"
            widthRandomness={0}
          />
          <Shimmer
            customClass="rounded-lg"
            width="100%"
            height="200px"
            borderRadius="4px"
            widthRandomness={0}
          />
        </div>
        <div>
          <span>
            <Shimmer
              customClass="rounded-lg"
              width="100px"
              height="24px"
              borderRadius="4px"
              widthRandomness={0}
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default SidebarRatingTemplateSkeleton;

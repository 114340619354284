import React from 'react';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SVGIcon from 'components/shared/SVGIcon';

const Followers = ({ objectiveDetail }) => {
  const { involvements } = objectiveDetail;
  const followers = involvements
    .filter((involvement) => {
      return involvement.role == 'follower';
    })
    .map(({ user }) => {
      return {
        type: 'circle',
        name: user.name,
        src: user.profilePicture,
        tooltip: true
      };
    });

  return (
    <div className="px-[24px]">
      <div className="h-[48px] flex items-center">
        <SVGIcon
          iconName="icon-people_outline"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <AvatarGroup avatars={followers} size="24" />
      </div>
    </div>
  );
};

export default Followers;

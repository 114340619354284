import client from 'client/ApiClient';

function updateGoalWeight(data) {
  return client(`v1/admin/objectives_category`, { method: 'PUT', body: data });
}

function getDatas(query) {
  return client(`v1/admin/objectives_category`, { params: query });
}

function getConfig(query) {
  return client(`v1/admin/configs`, { params: query });
}

function addGoalWeight(data) {
  return client(`v1/admin/objectives_category`, { method: 'POST', body: data });
}

function deleteGoalWeight(goalweightId, body) {
  return client(`v1/admin/objectives_category/${goalweightId}`, {
    method: 'DELETE',
    body
  });
}

export {
  updateGoalWeight,
  getDatas,
  deleteGoalWeight,
  addGoalWeight,
  getConfig
};

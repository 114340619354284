import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getObjectiveLocale } from 'utils/HelperUtils';

import DropdownVisibility from 'components/dropdown/DropdownVisibility';
import MenuOutsideClickWrapper from 'components/objectives/compact-objective/fragments/MenuOutsideClickWrapper';
import SVGIcon from 'components/shared/SVGIcon';

const Privacy = ({ teamDetail, updateTeam }) => {
  const params = useParams();
  const { visibility, teamType, permissions } = teamDetail || {};
  const isPrivate = visibility === 'private_team';
  const type = teamType || params?.teamType;
  const ableToEditPrivacy =
    useMemo(() => permissions?.includes('edit_privacy'), [permissions]) ||
    false;

  const [showDropdown, setShowDropdown] = useState(false);

  const getPrivacyText = () => {
    if (isPrivate) {
      return getObjectiveLocale('private').split(' ').pop();
    }

    return getObjectiveLocale('public').split(' ').pop();
  };

  return (
    <div
      className="height-40 bg-n-200 flex items-center px-[16px] py-[8px] mx-[8px] mt-[8px] rounded"
      data-cy="privacy-team"
    >
      <span className="typography-paragraph normal-case mr-[8px]">
        {getObjectiveLocale(`This ${type} is`)}
      </span>
      <div
        className={`flex items-center ${
          ableToEditPrivacy ? 'cursor-pointer' : 'cursor-not-allowed'
        }`}
        onClick={() => ableToEditPrivacy && setShowDropdown(true)}
        data-cy="privacy-team-button"
      >
        <SVGIcon
          iconName={isPrivate ? 'icon-private' : 'icon-remove_red_eye'}
          size="16"
          fillColor={ableToEditPrivacy ? 'var(--base-600)' : 'var(--n-600'}
          customClass="mr-[4px]"
        />
        <p
          className={`typography-paragraph ${
            ableToEditPrivacy ? 'text-base-600' : 'text-n-600'
          }`}
        >
          {getPrivacyText()}
        </p>
      </div>

      {showDropdown && (
        <MenuOutsideClickWrapper event={() => setShowDropdown(false)}>
          <DropdownVisibility
            handleChange={(e) =>
              updateTeam({
                visibility: e == 'private' ? 'private_team' : 'public_team'
              })
            }
            isTeam={true}
            useTrigger={false}
          />
        </MenuOutsideClickWrapper>
      )}
    </div>
  );
};

export default Privacy;

import React, { createRef, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';
import flatten from 'lodash/flatten';

import { useGetObjectives } from 'hooks/api/useObjectives';
import useDebounce from 'hooks/useDebounce';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import { formatTimezone } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { restructureFilter } from 'utils/ObjectivesHelper';

import Avatar from 'components/design-system/Avatar';
import Button from 'components/design-system/Button';
import InlineDialog, {
  useDialog
} from 'components/design-system/inline-dialog/InlineDialog';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

import ObjectiveCard from './ObjectiveCard';

const LIMIT = 10;

const MenuItemsContent = ({
  filter,
  rowNumber,
  onClick,
  searchObjective,
  setSearchObjective,
  columnName,
  columnType
}) => {
  const { open } = useDialog();

  const [listObjectives, setListObjectives] = useState([]);

  const ownerKey = camelCase(columnType);
  const ownerValue = columnName;

  const debounceSearchValue = useDebounce(searchObjective, 500);

  const filterParams = {
    q: debounceSearchValue,
    owner: {
      [ownerKey]: ownerValue
    }
  };

  const year = new Date().getFullYear();
  const filteredParams = restructureFilter(filterParams);

  const objectiveParams = {
    ...filteredParams,
    reviewsVisibility: 1,
    limit: LIMIT,
    periodEndBefore: formatTimezone(`${year}-12-31`, 'end'),
    periodBegin: formatTimezone(`${year}-01-01`, 'start')
  };

  const {
    data: objectivesData,
    isLoading: isLoadingGetObjectives,
    isFetchingNextPage: isFetchingObjectivesNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetObjectives('objectives_analytics', objectiveParams, {
    enabled: !!open && !!filter,
    getNextPageParam: (lastPage) =>
      lastPage?.pagination?.next?.olderThan || undefined,
    staleTime: 5 * 60 * 1000
  });

  const lastPageData =
    objectivesData?.pages?.[objectivesData?.pages?.length - 1];

  const intersectTarget = createRef();
  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting &&
      hasNextPage &&
      lastPageData?.data?.length === LIMIT &&
      fetchNextPage(),
    threshold: 0.5
  });

  useEffect(() => {
    if (objectivesData?.pages.length > 0) {
      const newObj = flatten(
        objectivesData?.pages?.map((page) => {
          if (page?.data) {
            return page?.data?.map((d) => {
              return d;
            });
          }
        })
      ).filter((data) => data);

      setListObjectives(newObj);
    }
  }, [objectivesData]);

  useEffect(() => {
    if (!open) setSearchObjective('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const dateFormatter = (date) => {
    if (!date) return false;
    return dayjs(date).format('D MMM YY');
  };

  const onClickObjective = (obj) => {
    onClick(rowNumber, obj);
  };

  return (
    <InlineDialog.MenuItems customClass="w-[360px]">
      {isLoadingGetObjectives ? (
        <LoadingComponent />
      ) : listObjectives.length === 0 ? (
        <ListEmptyState
          fullHeight
          emptyIcon={
            searchObjective ? 'icon-no-result-found' : 'icon-no-objective'
          }
          fillIcon="var(--n-600)"
          size="small"
          title={searchObjective ? 'No result found' : 'No objective found'}
        />
      ) : (
        <>
          {listObjectives?.map((obj) => (
            <InlineDialog.MenuItem
              key={obj.id}
              onClick={() => onClickObjective(obj)}
            >
              <div className="flex">
                <Avatar
                  src={obj?.createdBy?.profilePicture}
                  name={obj?.createdBy?.name}
                  size={32}
                  customClass="mr-[16px]"
                />
                <div className="flex flex-col grow overflow-hidden min-h-[44px]">
                  <span className="typography-h400-longform text-n-900 block mb-[4px]">
                    {obj.name}
                  </span>
                  <span className="typography-h100 text-n-800 truncate">
                    {`${dateFormatter(obj.startDate)} - ${dateFormatter(
                      obj.dueDate
                    )}`}
                  </span>
                </div>
              </div>
            </InlineDialog.MenuItem>
          ))}
          {hasNextPage && !isFetchingObjectivesNextPage && (
            <div className="h-[1px]" ref={intersectTarget}>
              &nbsp;
            </div>
          )}
        </>
      )}
    </InlineDialog.MenuItems>
  );
};

const AddObjective = ({
  filter,
  rowNumber,
  onClick,
  isViewGraph,
  columnName,
  columnType
}) => {
  const [searchObjective, setSearchObjective] = useState('');

  const searchOption = {
    placeholder: 'Search Objective',
    onSearch: (e) => {
      setSearchObjective(e.target.value);
    }
  };

  return (
    <InlineDialog header="Add Objective" search={searchOption}>
      <InlineDialog.Trigger>
        <div
          className={`flex justify-center items-center grow-0 flex-shrink-0 rounded-[4px] border-[1px] border-solid border-n-300 bg-n-300 w-[288px] ${
            isViewGraph ? 'h-[209px]' : 'h-[144px]'
          } text-center`}
        >
          <Button
            variant="tertiary"
            customClass="w-full h-full"
            stopPropagation={false}
          >
            <SVGIcon iconName="icon-add" size={24} />
            <p className="typography-button">
              {getObjectiveLocale('Add Objective')}
            </p>
          </Button>
        </div>
      </InlineDialog.Trigger>
      <MenuItemsContent
        filter={filter}
        rowNumber={rowNumber}
        onClick={onClick}
        searchObjective={searchObjective}
        setSearchObjective={setSearchObjective}
        columnName={columnName}
        columnType={columnType}
      />
    </InlineDialog>
  );
};

const ObjectiveCardView = ({
  filter,
  columnNumber,
  columnName,
  columnType,
  sectionData
}) => {
  const isViewGraph = useObjectiveComparative((state) => state.isViewGraph);

  const handleEditObjective = useObjectiveComparative(
    (state) => state.handleEditObjective
  );

  const onClickAddObjective = (rowNumber, objective) => {
    if (rowNumber) {
      handleEditObjective({
        columnNumber: columnNumber,
        rowNumber: rowNumber,
        objective: objective
      });
    }
  };

  const onClickRemoveObjective = (rowNumber) => {
    if (rowNumber) {
      handleEditObjective({
        columnNumber: columnNumber,
        rowNumber: rowNumber,
        objective: null
      });
    }
  };

  return sectionData?.map(({ rowNumber, objective }) =>
    objective === null ? (
      <AddObjective
        filter={filter}
        onClick={onClickAddObjective}
        rowNumber={rowNumber}
        isViewGraph={isViewGraph}
        columnName={columnName}
        columnType={columnType}
      />
    ) : (
      <ObjectiveCard
        onRemove={onClickRemoveObjective}
        columnNumber={columnNumber}
        rowNumber={rowNumber}
        objective={objective}
        isViewGraph={isViewGraph}
        key={`${objective?.name}-${objective?.id}`}
      />
    )
  );
};

export default ObjectiveCardView;

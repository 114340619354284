import React, { useRef, useState } from 'react';

import isEqual from 'lodash/isEqual';
import kebabCase from 'lodash/kebabCase';

import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

import FilterMenu from './FilterMenu';

const ButtonAddColumn = ({ isEmptyData }) => {
  const refAddColumn = useRef();
  const prevSelFilter = useRef(null);
  const handleAddNewColumn = useObjectiveComparative(
    (state) => state.handleAddNewColumn
  );

  const [isAddColumn, setIsAddColumn] = useState(false);

  const reducer = (action) => {
    const selectedFilter = Object.keys(action?.state)
      .filter((key) => kebabCase(action?.type) == key)
      .reduce((obj, key) => {
        obj[key] = action?.state[key];
        return obj;
      }, {});

    if (!isEqual(prevSelFilter.current, action?.state)) {
      handleAddNewColumn(selectedFilter);
      setIsAddColumn(false);
    }

    prevSelFilter.current = action?.state;
  };

  return (
    <div
      className={`bg-n-300 h-[60px] rounded-[4px] top-0 sticky z-index-2 ${
        isEmptyData ? 'w-full' : 'w-[288px]'
      }`}
    >
      <Button
        variant="tertiary"
        customClass="w-full h-full"
        onClick={() => setIsAddColumn(true)}
      >
        <SVGIcon iconName="icon-add" size={24} />
        <p className="typography-button">{getObjectiveLocale('Add Column')}</p>
      </Button>

      {isAddColumn && (
        <FilterMenu
          refFilter={refAddColumn}
          dispatchFilter={reducer}
          onClose={() => setIsAddColumn(false)}
        />
      )}
    </div>
  );
};

export default ButtonAddColumn;

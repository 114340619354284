import React, { useEffect } from 'react';

import startCase from 'lodash/startCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Accordion, {
  useAccordion
} from 'components/design-system/accordion/Accordion';
import SVGIcon from 'components/shared/SVGIcon';
import TeamCardView from 'components/teams/card/TeamCardView';

const HeaderUseContext = ({ text, setOpenModalCreate, showCreate }) => {
  const { isAccordionOpen, setIsOpen, isOpen } = useAccordion();
  useEffect(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <div className="flex items-center">
      <div className="flex items-center sticky left-[0px] min-w-[200px] group">
        <SVGIcon
          size="24"
          iconName={
            isAccordionOpen
              ? 'icon-keyboard_arrow_down'
              : 'icon-keyboard_arrow_right'
          }
          fillColor="var(--n-600)"
          customClass="cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        />
        <p className="typography-h500 text-n-900 ml-[16px] whitespace-nowrap">
          {getObjectiveLocale(startCase(text))}
        </p>
        {showCreate && (
          <SVGIcon
            size="24"
            iconName="icon-add"
            fillColor="var(--n-600)"
            customClass="mx-[16px] hidden group-hover:flex"
            onClick={(e) => {
              e?.stopPropagation();
              setOpenModalCreate({ show: true, type: text });
            }}
          />
        )}
      </div>
    </div>
  );
};

const AccordionTeam = React.memo(
  ({
    section,
    setOpenModalCreate,
    squadCreate,
    tribeCreate,
    selectedSwitcher
  }) => {
    const { name, isVisible } = section;

    return (
      <Accordion
        useBgColor={false}
        customClass="mb-[24px]"
        dataCy={`accordion-team-${name}`}
        contentOpen
      >
        {isVisible && (
          <Accordion.Header
            useCustomHeader
            customWrapperClass="mb-[18px] flex flex-grow"
            useWrapperClick={false}
          >
            <HeaderUseContext
              text={name}
              setOpenModalCreate={setOpenModalCreate}
              showCreate={
                selectedSwitcher === 'active'
                  ? name == 'tribe'
                    ? tribeCreate
                    : squadCreate
                  : false
              }
            />
          </Accordion.Header>
        )}

        <Accordion.Content customSpaceClass="px-[0px] py-[0px]">
          <TeamCardView
            selectedSwitcher={selectedSwitcher}
            section={section}
            setOpenModalCreate={setOpenModalCreate}
          />
        </Accordion.Content>
      </Accordion>
    );
  }
);

AccordionTeam.displayName = 'AccordionTeam';

export default AccordionTeam;

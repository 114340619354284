import React from 'react';

import { getNumberLocale } from 'utils/HelperUtils';

import NumberInput from 'components/shared/NumberInput';

const InputProgressSection = ({
  canUpdateCurrentValue,
  measurement,
  progressValue,
  setProgressValue,
  allowNegative = true
}) => {
  return (
    <>
      {canUpdateCurrentValue && measurement && (
        <div className="modal-progress-input mt-[24px]">
          <p className="mb-[8px]">Progress</p>
          <div className="flex items-center">
            <NumberInput
              className="w-[276px]"
              inputClass="h-[36px]"
              value={progressValue}
              onChange={setProgressValue}
              dataCy="update-progress-input"
              precision={13}
              allowNegative={allowNegative}
            />
            {measurement?.unit && (
              <div className="target-metric ml-[8px]">
                <span className="fs-14 fw-normal text-n-800">
                  <span className="fs-14 fw-normal text-n-600">of &nbsp;</span>
                  {getNumberLocale(measurement?.targetValue)}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InputProgressSection;

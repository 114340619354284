import React from 'react';

import GanttChartListWrapper from './GanttChartListWrapper';

const GanttChartSidebarWrapper = ({
  contentOptions,
  mandatoryParams = {},
  level = 0
}) => {
  const options = contentOptions?.options;
  const subOptions = contentOptions?.subOptions;

  if (!options) {
    return (
      <GanttChartListWrapper
        index={0}
        data={{ id: 'no-group' }}
        mandatoryParams={mandatoryParams}
        level={level}
        noGroup
      />
    );
  }

  const { staticData, paramIdentifier, paramValueKey } = options;

  const data = staticData ? staticData : []; // will be change with usequery data

  return (
    <div>
      {staticData ? (
        data.map((dt, index) => (
          <GanttChartListWrapper
            key={dt.id}
            index={index}
            data={dt}
            mandatoryParams={mandatoryParams}
            paramIdentifier={paramIdentifier}
            paramValueKey={paramValueKey || 'id'}
            subOptions={subOptions}
            level={level}
            contentOptions={contentOptions?.options}
          />
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default GanttChartSidebarWrapper;

import React, { useEffect, useState } from 'react';

import { getCustomAttributeList } from 'utils/FormalReview';

import SetWeightBox from './SetWeightBox';

const SetWeightSection = ({ cycleData }) => {
  const [listCustomAttributes, setListCustomAttributes] = useState([]);

  useEffect(() => {
    const phases = [
      'selfReview',
      'managerReview',
      'indirectManagerReview',
      'upwardReview',
      'peerReview'
    ];
    const attributes = getCustomAttributeList(cycleData, phases);
    setListCustomAttributes(attributes);
  }, [cycleData]);

  return (
    <div id="set-weight-section" className="mt-[20px] pt-[40px] pb-[24px]">
      <p className="typography-h500 mb-[4px]">Set Weight</p>
      <p className="typography-secondary">
        Set percentage weight scoring for each reviewers, maximum percentage
        weight is 100% each phase.
      </p>

      <SetWeightBox type="goal" />
      <SetWeightBox type="task" />
      <SetWeightBox type="value" />
      <SetWeightBox type="competency" />
      {listCustomAttributes?.map((attr, index) => (
        <SetWeightBox
          key={index}
          attributeSelectedName={attr?.name}
          attributeSelectedId={attr?.templateId}
          attributeSelectedPhases={attr?.selectedPhase}
          type="review-aspect"
        />
      ))}
    </div>
  );
};

export default SetWeightSection;

import React from 'react';

import dayjs from 'dayjs';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SingleCalendar from 'components/shared/Calendar';
import TimePickerV2 from 'components/shared/TimePickerV2';

const CalendarTime = ({
  title,
  setDate,
  date,
  validDate,
  isReadOnly,
  customClass = '',
  use24HFormat = false,
  useVisualGmt7 = false,
  type
}) => {
  const time = dayjs(date).format('hh:00 A').toString();

  const handleChangeDate = (selectedDate) => {
    const dateAndTime = `${dayjs(selectedDate).format('DD MMM YYYY')}, ${time}`;
    setDate(dayjs(dateAndTime).toISOString());
  };

  const handleChangeTime = (selectedTime) => {
    const changedTime = dayjs(selectedTime).format('hh:mm A');
    const dateAndTime = `${dayjs(date).format('DD MMM YYYY')}, ${changedTime}`;
    setDate(dayjs(dateAndTime).toISOString());
  };

  const disabledDate = (current, validDate) => {
    if (!current) {
      // allow empty select
      return false;
    }
    const date = dayjs(validDate);
    date.hour(0);
    date.minute(0);
    date.second(0);
    return current.valueOf() < date.valueOf();
  };

  return (
    <div
      className={`pl-[8px] py-[8px] bg-n-200 rounded-[4px] width-fit-content ${customClass}`}
    >
      <p className="typography-h100 text-n-800 mb-[8px]">
        {getObjectiveLocale(title)}
      </p>
      <div className="flex">
        <div className="relative mr-[16px]">
          <div className="flex cursor-pointer">
            <SingleCalendar
              disabledDate={(current) => disabledDate(current, validDate)}
              defaultValue={dayjs(date)}
              handleChangeCalendar={handleChangeDate}
              type={type}
              customBox="border-solid border-n-500 border bg-n-000 h-[32px] pt-[4px] rounded-[4px]"
              calendarButtonType="today"
              permissionStartDate={!isReadOnly}
              permissionDueDate={!isReadOnly}
            />
          </div>
        </div>
        <TimePickerV2
          selectedTime={date}
          handleChange={(time) => handleChangeTime(time)}
          data-cy="time-calendar"
          isReadOnly={isReadOnly}
          use24HFormat={use24HFormat}
          useVisualGmt7={useVisualGmt7}
        />
      </div>
    </div>
  );
};

export default CalendarTime;

import React from 'react';

import Config from 'utils/Config';
import { capitalize, getCultureLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import SVGIcon from 'components/shared/SVGIcon';

function GenerateListGroupSection({
  title,
  isOpen,
  setIsOpen,
  data,
  clickFunction = () => {},
  selectedGroup,
  wrapperGroupClassName,
  itemGroupClasssName = 'h-[80px]',
  onScroll = () => {},
  isUseTitle = true,
  isUseChevron = true,
  isOtherOpen
}) {
  const clickGroup = (e, group) => {
    e.stopPropagation();
    clickFunction(group);
  };

  return (
    <div className={isOpen ? (isOtherOpen ? 'h-1/2' : 'h-4/5') : ''}>
      {isUseTitle && (
        <div
          className="group-list-section typography-h500 mb-[16px] flex aligns-center justify-between cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
          data-cy="title-group-section"
        >
          {title}
          {isUseChevron && (
            <SVGIcon
              iconName={
                isOpen
                  ? 'icon-keyboard_arrow_down'
                  : 'icon-keyboard_arrow_right'
              }
              size="24"
              fillColor="var(--n-600)"
            />
          )}
        </div>
      )}
      {(isOpen || !isUseTitle) && (
        <div
          className={`overflow-hidden hover:overflow-auto hover:overflow-overlay justify-between group-list ${
            isOtherOpen ? 'h-3/4' : 'h-4/5'
          } ${wrapperGroupClassName && wrapperGroupClassName}`}
          onScroll={onScroll}
          data-cy="group-section"
        >
          {data?.length > 0 ? (
            data.map((group) => (
              <div
                key={`item-${group?.id}`}
                className={`flex flex-row items-center py-[4px] pl-[8px] cursor-pointer ${itemGroupClasssName} ${
                  group.id === selectedGroup?.id ? 'selected-group' : ''
                }`}
                data-cy="group-feeds-item"
                onClick={(e) => clickGroup(e, group)}
                id={group.id}
              >
                <AWSImage
                  className="w-[40px] h-[40px] rounded-[4px] mr-[16px] object-contain"
                  onErrorImage={Config.asset.general.defaultGroupCover}
                  src={
                    group?.groupPicture?.secureUrl ||
                    Config.asset.general.defaultGroupCover
                  }
                />
                <div className="flex flex-col">
                  <div
                    className="typography-h400 break-words"
                    data-cy="group-name"
                  >
                    {group.name}
                  </div>
                  <div
                    className="typography-paragraph text-n-800 flex"
                    data-cy="group-type"
                  >
                    {capitalize(group?.groupType?.replace(/_group|_/, ' '))}{' '}
                    {getCultureLocale('Group')}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              {getCultureLocale('Group unavailable')}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default GenerateListGroupSection;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';



import { deleteAllRevieweeReviewer, getReviewerRevieweeProgress, manageAssignment, sendEmailReminder } from 'client/FormalReviewClient';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';



import Button from 'components/design-system/Button';
import ModalRemoveReviewer from 'components/formal-review/sidebar-phase/ModalRemoveReviewer';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';
import SidebarRight from 'src/components/design-system/SidebarRight/SidebarRight';
import { trackEvent } from 'src/utils/AnalyticUtils';



import './SidebarTeamResult.scss';
import PhaseDetail from './sidebar-team-result/PhaseDetail';
import UserDetail from './sidebar-team-result/UserDetail';


const SidebarTeamResult = ({
  sidebarId,
  closeSidebar,
  currentTab,
  setToaster,
  getData,
  isReadOnly = false,
  lockAnswerCycle,
  listPhases,
  getUserCycle
}) => {
  const pageParams = useParams();
  const {
    config: { permissions, formalReviewReminder }
  } = useUser();
  const [assignment, setAssignment] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [modalRemind, setModalRemind] = useState(false);
  const [modalRemoveInfo, setModalRemoveInfo] = useState({
    show: false,
    type: null,
    data: null
  });
  let searchParams = new URLSearchParams(window.location.search);
  const placementId = parseInt(searchParams.get('placement'));

  let params = {
    activityVisibility: 1
  };
  currentTab === 'reviewer'
    ? (params.actorPlacementId = placementId)
    : (params.targetPlacementId = placementId);

  const revieweeOrReviewerWording =
    currentTab == 'reviewee' ? 'reviewee' : 'reviewer';
  const assignmentData = assignment?.assignmentProgress;
  const remindUser = assignmentData?.total - assignmentData?.done;
  const assignmentProgress =
    (assignmentData?.done / assignmentData?.total) * 100;

  const getSidebarData = async () => {
    const { data } = await getReviewerRevieweeProgress(
      pageParams.id,
      currentTab,
      placementId
    );
    if (data) {
      setAssignment(data);
      setIsLoading(false);
    }
  };

  const removeUser = async () => {
    let body = {
      role: currentTab,
      placementIds: [placementId]
    };
    const { isSuccess } = await deleteAllRevieweeReviewer(pageParams.id, body);
    if (isSuccess) {
      closeSidebar();
      setAssignment({});
      getData();
    }
    setIsLoading(false);
  };

  const removeAssignments = async () => {
    const { phaseId, listUser } = modalRemoveInfo.data;

    let body = {
      phaseId: phaseId,
      assignments: []
    };

    listUser.map((assignment) => {
      let newObject = {
        id: assignment.id,
        actorId: assignment.actorId,
        actorPlacementId: assignment.actorPlacementId,
        targetId: assignment.targetId,
        targetPlacementId: assignment.targetPlacementId,
        state: 'deleted'
      };
      body.assignments.push(newObject);
    });

    const { isSuccess } = await manageAssignment(body);
    if (isSuccess) {
      setAssignment({});
      getSidebarData();
      setModalRemoveInfo({ show: false, type: null, data: null });
    }
  };

  const handleConfirmDeleteModal = () => {
    if (modalRemoveInfo.type == 'user') {
      removeUser();
    } else if (modalRemoveInfo.type == 'assignments') {
      removeAssignments();
    }
  };

  const remindCycle = async (
    type,
    phase,
    actorPlacementId,
    targetPlacementId
  ) => {
    const body =
      type === 'all'
        ? params
        : {
            phaseType: phase,
            actorPlacementId,
            targetPlacementId
          };

    const { isSuccess } = await sendEmailReminder(pageParams.id, body);
    if (isSuccess) {
      setToaster(`Successfully sent out reminder`, false);
      setModalRemind(false);
      trackEvent({
        event: 'send email reminder',
        eventProperties: {
          type: type,
          phaseType: phase,
          actorPlacementId,
          targetPlacementId,
          cycleId: pageParams.id
        }
      });
    } else {
      setToaster(`Failed sent out reminder`, true);
    }
  };

  useEffect(() => {
    getSidebarData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarId, placementId]);

  return (
    <SidebarRight
      customClass="sidebar-team-result"
      customWidth="w-[400px]"
      fullHeight
    >
      <SidebarRight.Header>
        <div
          className={`flex items-center ${
            remindUser > 0 ? 'flex justify-between' : 'flex justify-end'
          }`}
        >
          <div>
            {
              // temporary hide for org: tiket
              remindUser > 0 && formalReviewReminder && !isReadOnly && (
                <Button
                  customClass="width-fit-content"
                  datacy="remind-sidebar-button"
                  onClick={() => setModalRemind(true)}
                >
                  {`Remind ${remindUser} Incompletes`}
                </Button>
              )
            }
          </div>
          <SVGIcon
            size="24"
            fillColor="var(--n-600)"
            iconName="icon-clear"
            customClass="hover-icon"
            onClick={() => closeSidebar()}
          />
        </div>
      </SidebarRight.Header>
      <SidebarRight.Body withSpace={false}>
        <div className="overflow-y phase-list px-[24px] my-[24px]">
          <UserDetail
            assignment={assignment}
            isLoading={isLoading}
            permissions={permissions}
            setModalRemoveInfo={setModalRemoveInfo}
            currentTab={currentTab}
            assignmentProgress={assignmentProgress}
            assignmentData={assignmentData}
            isReadOnly={isReadOnly}
            getSidebarData={getSidebarData}
            getUserCycle={getUserCycle}
          />
          <PhaseDetail
            sidebarId={sidebarId}
            currentTab={currentTab}
            assignment={assignment}
            remindCycle={remindCycle}
            isReadOnly={isReadOnly}
            lockAnswerCycle={lockAnswerCycle}
            setModalRemoveInfo={setModalRemoveInfo}
            listPhases={listPhases}
          />
        </div>
        {modalRemoveInfo.show && (
          <ModalRemoveReviewer
            isReviewerWording={currentTab == 'reviewer'}
            onSubmit={() => handleConfirmDeleteModal()}
            onCancel={() => setModalRemoveInfo({ show: false, data: null })}
          />
        )}
        {modalRemind && (
          <Modal
            title={`Remind ${remindUser} reviewers`}
            description={`Notifications will be sent out to the reviewer.`}
            className="w-[400px]"
            withCloseIcon={false}
            withPrimaryBtn={{
              title: getObjectiveLocale('Remind'),
              dataCy: 'remind',
              onClick: () => remindCycle('all')
            }}
            withSecondaryBtn={{
              title: getObjectiveLocale('Cancel'),
              dataCy: 'cancel-remind',
              onClick: () => setModalRemind(false)
            }}
          />
        )}
      </SidebarRight.Body>
    </SidebarRight>
  );
};

export default SidebarTeamResult;
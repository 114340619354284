import React, { useRef } from 'react';

import useClickOutside from 'hooks/useClickOutside';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

import Menu from './Menu';
import SelectedFilters from './SelectedFilters';

function MenuFilter({
  options,
  filter,
  resultModifier,
  dispatchFilter,
  defaultFilter,
  filterClient,
  dialogQueryFn,
  immutableFilter,
  suspense,
  defaultFilterToReset,
  generateCustomTitle
}) {
  const refFilter = useRef();

  const showMenuFilter = useFilter((state) => state.showMenuFilter);
  const setShowMenuFilter = useFilter((state) => state?.setShowMenuFilter);

  useClickOutside(refFilter, () => setShowMenuFilter(false));

  const showFilterMenu = () => {
    setShowMenuFilter(!showMenuFilter);
  };
  return (
    <>
      <div className="button-fill mr-[8px]">
        <Button.Tertiary
          datacy="advance-filter-icon"
          onClick={() => showFilterMenu()}
        >
          <SVGIcon
            size="16"
            iconName="icon-filter_list"
            fillColor={'var(--base-600)'}
          />
          <p className="ml-[8px]">{getObjectiveLocale('Filter')}</p>
        </Button.Tertiary>
        <div className="filter-component" ref={refFilter}>
          {showMenuFilter && (
            <React.Suspense fallback={() => <div></div>}>
              <Menu
                options={options}
                filter={filter}
                dispatchFilter={dispatchFilter}
                filterClient={filterClient}
                dialogQueryFn={dialogQueryFn}
                suspense={suspense}
              />
            </React.Suspense>
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-x-[8px]">
        <SelectedFilters
          resultModifier={resultModifier}
          dispatchFilter={dispatchFilter}
          filter={filter}
          immutableFilter={immutableFilter}
          generateCustomTitle={generateCustomTitle}
        />
        {defaultFilter && (
          <div className="button-fill">
            <Button.Tertiary
              onClick={() =>
                dispatchFilter({
                  type: 'RESET',
                  state: defaultFilterToReset
                    ? { ...defaultFilterToReset }
                    : { ...defaultFilter }
                })
              }
            >
              {' '}
              Reset Filters{' '}
            </Button.Tertiary>
          </div>
        )}
      </div>
    </>
  );
}

export default MenuFilter;

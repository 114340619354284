import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

const UploadCSV = ({
  chooseFile,
  selectedFile,
  removeFile,
  fileErrorMessage,
  caption = '',
  disabled = false
}) => {
  return (
    <section className="flex flex-col justify-center items-center p-[64px] bg-n-200 rounded-[4px] min-h-[222px] w-full">
      {!selectedFile && (
        <p className="typography-paragraph text-n-600 text-center mb-[24px]">
          {getObjectiveLocale(caption)}
        </p>
      )}

      {selectedFile && (
        <>
          <div className="inline-flex">
            <SVGIcon
              iconName={
                !fileErrorMessage ? 'icon-insert_drive_file' : 'icon-warning'
              }
              fillColor={!fileErrorMessage ? 'var(--n-900)' : 'var(--r-600)'}
              size="24"
            />
            <p className="typography-h700 mx-[8px] lowercase text-center multiline-text-2">
              {selectedFile?.name}
            </p>
            <SVGIcon
              iconName="icon-clear"
              fillColor="var(--n-600)"
              size="24"
              onClick={() => removeFile()}
              disabled={disabled}
            />
          </div>

          {fileErrorMessage && (
            <p className="typography-button text-r-600 mt-[16px] mb-[24px] text-center">
              {fileErrorMessage}
            </p>
          )}
        </>
      )}

      <input
        type="file"
        name="fileToUpload"
        id="fileToUpload"
        className="hidden"
        onChange={(e) => chooseFile(e)}
        disabled={disabled}
      />
      <label
        htmlFor="fileToUpload"
        className={`btn text-btn ${disabled ? 'disabled' : ''} mt-[16px]`}
      >
        <span className="typography-button">
          {getObjectiveLocale(
            selectedFile ? 'Change File' : 'Click to Upload File'
          )}
        </span>
      </label>
    </section>
  );
};

export default UploadCSV;

import { useEffect } from "react";

export default function useScrollHeaderBorder() {
  useEffect(() => {
    const mainbody = document.querySelector(".main-body")
    const headerPage = document.querySelector(".header-page")
    const handler = (e) => {
      if (e.target.scrollTop >= 52) {
        headerPage.style.borderBottom = "1px solid var(--n-400)"
      } else {
        headerPage.style.borderBottom = "none"
      }
    };

    mainbody.addEventListener("scroll", handler);
    return () => mainbody.removeEventListener("scroll", handler);
  }, [])
}
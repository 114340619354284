import React, { useEffect, useState } from 'react';

import { useUser } from 'context/UserContext';

import HeaderPage from 'components/admin/HeaderPage';

import GeneralSetting from './GeneralSetting';
import ObjectivesVisibility from './ObjectivesVisibility';
import GoalWeight from './Weight/GoalWeight';
import WeightSettings from './weightv2/WeightSettings';

const Objectives = ({ route: { title, isReadOnly } }) => {
  const { config } = useUser();

  const { enableObjectiveCategoryWithRangedWeight, permissions } = config;

  const weightTabVisibility = enableObjectiveCategoryWithRangedWeight
    ? permissions?.settingsObjectivesWeightPercentage
    : permissions?.settingsObjectivesWeightPercentage ||
      permissions?.settingsObjectivesWeightType;

  const [currentTab, setCurrentTab] = useState('');
  const [isSaving, setIsSaving] = useState('');
  const allList = [
    {
      id: 'general setting',
      name: 'General Setting',
      class: 'tab-list-survey-purple',
      isVisible: permissions?.settingsObjectivesGeneral
    },
    {
      id: 'weight',
      name: 'Weight',
      class: 'tab-list-survey-purple',
      isVisible: weightTabVisibility
    },
    {
      id: 'visibility',
      name: 'Visibility',
      class: 'tab-list-survey-purple',
      isVisible: permissions?.settingsObjectivesVisibility
    }
  ].filter((option) => option.isVisible);

  const listObjTabs = allList;

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('tab') === null) {
      const defaultTab = allList.find((list) => list.isVisible)?.id;
      setCurrentTab(defaultTab);
    } else {
      setCurrentTab(urlParams.get('tab'));
    }
  }, [allList]);

  useEffect(() => {
    if (isSaving) {
      setTimeout(() => {
        setIsSaving(false);
      }, 2000);
    }
  }, [isSaving]);

  return (
    <>
      <HeaderPage
        titlePage={title}
        saveStatus={isSaving ? 'Saved...' : ''}
        tabs={{
          tabsList: listObjTabs,
          url: '/objectives/settings',
          onChange: (tab) => {
            setCurrentTab(tab);
          }
        }}
      />
      <div className="admin-culture bg-n-000 flex flex-col">
        {currentTab == 'general setting' && (
          <GeneralSetting setIsSaving={setIsSaving} isReadOnly={isReadOnly} />
        )}
        {currentTab == 'weight' ? (
          enableObjectiveCategoryWithRangedWeight ? (
            <WeightSettings
              setIsSaving={setIsSaving}
              isReadOnly={
                permissions?.settingsObjectivesWeightPercentage ==
                  'read_only' ||
                !permissions?.settingsObjectivesWeightPercentage
              }
            />
          ) : (
            <GoalWeight setIsSaving={setIsSaving} isReadOnly={isReadOnly} />
          )
        ) : (
          <></>
        )}
        {currentTab == 'visibility' && (
          <ObjectivesVisibility isReadOnly={isReadOnly} />
        )}
      </div>
    </>
  );
};

export default Objectives;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import S3Client from 'client/S3Client';
import {
  getPreSignUrlValues,
  patchBehaviors,
  postBehaviors
} from 'client/adminClient.js';
import { useValueCompetency } from 'context/admin/ValueCompetencyContext';

import NewDictionaryCard from './NewDictionaryCard';
import NewDictionaryCreateCard from './NewDictionaryCreateCard';

const ValueAndCompetencyDictionary = ({ _onScroll, isReadOnly }) => {
  const { pathname } = useLocation();
  const {
    dataValueOrCompetency,
    showInlineCreate,
    setIsChooseImageOpen,
    showWarningMessage,
    setSelectedImgValue,
    setCurrentEventTarget,
    compentencyOrValue,
    setShowInlineCreate,
    getValueOrCompetencyData,
    showNotificationMessage,
    resetInputState,
    currentFilterTab,
    currentTab
  } = useValueCompetency();

  const abortController = new AbortController();

  const _getPreSignUrl = async () => {
    const query = {
      fileType: 'media-resources',
      fileFormat: 'png'
    };
    const { data, error } = await getPreSignUrlValues(query);
    return data;
  };

  const handleChangeInputFile = async (e, id, action) => {
    let file = e.target.files[0];
    let fileInput = document.getElementById('inputfile');
    let imgPlace = document.getElementById('imagePlace-' + id);
    let label = fileInput.nextElementSibling;

    if (file) {
      if (
        file.type == 'image/png' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/jpg'
      ) {
        if (file.size > 2200000) {
          alert('Maximum File Size Exceeded!');
        } else {
          let reader = new FileReader();
          reader.onload = function (e) {
            imgPlace.src = e.target.result;
          };
          reader.readAsDataURL(file);
          label.querySelector('img').style.display = 'none';
          setIsChooseImageOpen(null);
          let data = await _getPreSignUrl();
          if (data) {
            const { status } = await S3Client(data.presignedUrl, file);
            if (status == 200) {
              setSelectedImgValue(data.secureUrl);
              handleCreateUpdateValueImg(id, data.secureUrl, action);
            }
          } else {
            alert('Presign Link Not Found!');
          }
        }
      } else {
        showWarningMessage(
          'Wrong file type! file type must be png, jpg or jpeg!'
        );
        return false;
      }
    } else {
      showWarningMessage('Empty file image');
    }
  };

  const chooseValueImage = async (val, id, action) => {
    let value =
      'https://s3-ap-southeast-1.amazonaws.com/culture-happy5/Assets/Values/value-' +
      val +
      '.png';
    let imgPlace = document.getElementById('imagePlace-' + id);
    imgPlace.src = value;
    setSelectedImgValue(value);
    setIsChooseImageOpen(null);
    handleCreateUpdateValueImg(id, value, action);
  };

  const handleCreateUpdateValueImg = async (id, value, action) => {
    const parentName =
      compentencyOrValue.inputName || compentencyOrValue.keyBehaviorName;
    const parentDesc =
      compentencyOrValue.inputDesc || compentencyOrValue.keyBehaviorDesc;

    if (action == 'create') {
      if (parentName && parentDesc) {
        const body = {
          title: parentName,
          description: parentDesc,
          iconUrl: value,
          behaviorType: 'value'
        };

        const { data } = await postBehaviors(body);
        if (data) {
          await getValueOrCompetencyData(pathname);
          setShowInlineCreate(null);
          showNotificationMessage('Created Successfully!');
          resetInputState();
        }
      } else {
        document.getElementById('inputName-00').focus();
      }
    }

    if (action == 'edit') {
      const { data, error } = await patchBehaviors(id, { iconUrl: value });
      if (data) {
        setSelectedImgValue('');
      }
    }
  };

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  if (
    pathname.includes('values') &&
    currentFilterTab == 'active' &&
    event?.target?.id
  ) {
    setCurrentEventTarget(event.target.id);
  }

  return (
    <React.Fragment>
      <div className="mt-[24px]" data-cy="table-list-competencies-values">
        <div className="flex pb-[10px] typography-paragraph text-n-800">
          <div className="flex items-center w-[30%] typography-h100 text-n-800">
            Name
          </div>
          <div className="flex items-center pl-[16px] w-1/2 typography-h100 text-n-800">
            Description
          </div>
          <div className="flex items-center justify-center w-[10%] typography-h100 text-n-800">
            Status
          </div>
          <div className="flex items-center w-[10%] typography-h100 text-n-800" />
        </div>

        <div
          className="border-table"
          data-cy="body-dictionary-table"
          onScroll={_onScroll}
        >
          {showInlineCreate == '00' && (
            <NewDictionaryCreateCard
              currentTab={currentTab}
              pathname={pathname}
              chooseValueImage={chooseValueImage}
              handleChangeInputFile={handleChangeInputFile}
            />
          )}

          {dataValueOrCompetency &&
            dataValueOrCompetency.map((dictionary, idx) => {
              return (
                <NewDictionaryCard
                  key={idx}
                  index={idx}
                  chooseValueImage={chooseValueImage}
                  handleChangeInputFile={handleChangeInputFile}
                  dictionary={dictionary}
                  isReadOnly={isReadOnly}
                />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ValueAndCompetencyDictionary;

import React, { useEffect, useState } from 'react';

import isNil from 'lodash/isNil';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getWordingMetrics } from 'utils/ObjectivesHelper';
import { CALC_TYPE_LESS_IS_BETTER, CALC_TYPE_NORMAL } from 'utils/const';

import DropdownMetric from 'components/dropdown/DropdownMetric';
import DropdownRollUp from 'components/dropdown/DropdownRollUp';
import BannerBox from 'components/shared/BannerBox';
import NumberInput from 'components/shared/NumberInput';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const AddMetrics = ({ objectiveValue, setObjectiveValue, listMetrics }) => {
  const { config } = useUser();

  const { objectiveCalculationType: configCalculationType } = config;

  const unitId = objectiveValue?.measurement?.unitId || 0;
  const selectedMetric = listMetrics?.find(({ id }) => id === unitId) || null;

  const startingValueObjective = !isNil(
    objectiveValue?.measurement?.startingValue
  )
    ? objectiveValue?.measurement?.startingValue
    : 0;

  const targetValueObjective = !isNil(objectiveValue?.measurement?.targetValue)
    ? objectiveValue?.measurement?.targetValue
    : 100;

  const rollUp =
    objectiveValue?.measurement?.rollUp || selectedMetric?.defaultRollUp;

  const [startingValue, setStartingValue] = useState(startingValueObjective);
  const [targetValue, setTargetValue] = useState(targetValueObjective);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleChangeMetrics = (event) => {
    setObjectiveValue((draft) => {
      // only metrics percentage can use roll up average and milestone mode average
      if (event.unit !== '%') {
        if (draft.measurement.rollUp.includes('average')) {
          draft.measurement.rollUp = 'disabled';
        }
        if (draft.milestoneMode == 'average_value') {
          draft.milestoneMode = 'sum_value';
        }
      }

      draft.measurement.unitId = event.id === 0 ? null : event.id;
    });
  };

  const handleChangeRollUp = (event) => {
    setObjectiveValue((draft) => {
      draft.measurement.rollUp = event;
    });
  };

  const handleChangeCalculation = (calculation) => {
    setObjectiveValue((draft) => {
      draft.calculationType = calculation;
    });
  };

  useEffect(() => {
    setTargetValue(targetValueObjective);
    setStartingValue(startingValueObjective);
  }, [objectiveValue.measurement]);

  useEffect(() => {
    if (objectiveValue.measurement) {
      setObjectiveValue((draft) => {
        draft.measurement.startingValue = parseFloat(startingValue || '0');
      });
    }
  }, [startingValue]);

  useEffect(() => {
    if (objectiveValue.measurement) {
      setObjectiveValue((draft) => {
        draft.measurement.targetValue = parseFloat(targetValue || '0');
      });
    }
  }, [targetValue]);

  useEffect(() => {
    if (objectiveValue?.calculationType?.includes(CALC_TYPE_LESS_IS_BETTER)) {
      setStartingValue(0);
    }
  }, [objectiveValue?.calculationType]);

  return (
    <>
      <div className="flex items-center mb-[16px]">
        <div className="w-1/2 mr-[16px]">
          <p className="typography-h100 text-n-800 mb-[8px]">
            {getObjectiveLocale('Metrics')}
          </p>
          <DropdownMetric
            defaultValue={selectedMetric ? selectedMetric.id : 0}
            listMetrics={listMetrics}
            handleChange={handleChangeMetrics}
            rightIcon="icon-arrow_drop_down"
            triggerCustomClass="w-full"
            customClass="w-full"
          />
        </div>
        <div className="w-1/2">
          <NumberInput
            label={getObjectiveLocale('Target')}
            placeholder={getObjectiveLocale('Targets')}
            value={targetValue}
            onChange={setTargetValue}
            disabled={unitId === 0}
          />
        </div>
      </div>

      {objectiveValue?.calculationType && unitId != 0 && (
        <>
          {configCalculationType?.includes(CALC_TYPE_LESS_IS_BETTER) && (
            <div className="bg-n-200 h-[32px] px-[16px] py-[4px] flex items-center justify-between border border-solid border-n-300 rounded-[4px] mb-[16px]">
              <div className="flex items-center">
                <SVGIcon
                  size="24"
                  iconName="icon-trending_down"
                  fillColor="var(--b-600)"
                />
                <p className="typography-paragraph text-n-900 mx-[8px]">
                  {getObjectiveLocale('Less is better')}
                </p>
                <TooltipContainer
                  wrap
                  show
                  text={getObjectiveLocale(
                    'Progress % calculated based on how much less current value from target value'
                  )}
                >
                  <SVGIcon
                    size="16"
                    fillColor="var(--n-600)"
                    iconName="icon-info"
                    customClass="cursor-pointer"
                  />
                </TooltipContainer>
              </div>
              <SmallToggleSwitchPurple
                idStr="less-is-btn"
                isChecked={
                  objectiveValue?.calculationType == CALC_TYPE_LESS_IS_BETTER
                }
                addClass="flex"
                onChange={(e) =>
                  handleChangeCalculation(
                    e.target.checked
                      ? CALC_TYPE_LESS_IS_BETTER
                      : CALC_TYPE_NORMAL
                  )
                }
              />
            </div>
          )}

          <div
            className="flex items-center cursor-pointer"
            onClick={() => setShowAdvanced(!showAdvanced)}
          >
            <p className="typography-link text-base-600 mr-[4px] hover:!no-underline">
              {getObjectiveLocale(
                `${showAdvanced ? 'Hide' : 'Show'} advanced options`
              )}
            </p>
            <SVGIcon
              size="24"
              iconName={
                showAdvanced ? 'icon-arrow_drop_up' : 'icon-arrow_drop_down'
              }
              fillColor="var(--base-600)"
            />
          </div>
        </>
      )}

      {unitId == 0 && (
        <BannerBox
          text={getObjectiveLocale(
            'You can still update the status of this project manually in project details page'
          )}
          type="info"
          customClass="mt-[16px] !mb-[0px] !py-[8px] !items-start !rounded-[8px]"
          iconSize="24"
        />
      )}

      {showAdvanced && unitId != 0 && (
        <div className="px-[16px] bg-n-200 px-[16px] py-[16px] border border-solid border-n-300 rounded-[4px] mt-[8px]">
          <div className="flex mb-[16px]">
            <div className="w-1/2 mr-[16px]">
              <NumberInput
                label={getObjectiveLocale('Initial Value')}
                placeholder={getObjectiveLocale('0')}
                value={startingValue}
                onChange={setStartingValue}
                disabled={objectiveValue?.calculationType?.includes(
                  CALC_TYPE_LESS_IS_BETTER
                )}
                withRightIcon={{
                  tooltipText: getObjectiveLocale(
                    'Initial value is being disabled because you are using less is better'
                  )
                }}
              />
              {startingValue == targetValue && unitId != 0 && (
                <p className="error-text-value mt-[4px]">
                  {getObjectiveLocale(
                    "Initial value can't be equal with target value"
                  )}
                </p>
              )}
            </div>
            <div className="w-1/2">
              <p className="typography-h100 text-n-800 mb-[8px]">
                {getObjectiveLocale('Set Auto Roll Up')}
              </p>
              <DropdownRollUp
                type={objectiveValue?.type}
                selectedMetric={selectedMetric}
                handleChange={handleChangeRollUp}
                defaultValue={rollUp}
                customClass="w-full"
              />
            </div>
          </div>

          <div className="flex mt-[20px]">
            <SVGIcon size="24" fillColor="var(--n-600)" iconName="icon-info" />
            <p className="typography-paragraph text-n-800 ml-[8px]">
              {getWordingMetrics(rollUp)}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AddMetrics;

import React, { useEffect, useState } from 'react';

import ctl from '@netlify/classnames-template-literals';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

function ListDropdown({
  data,
  autoClose,
  dropdownAnimation,
  eventCallbackClick,
  position,
  vPosition
}) {
  const [animation, setAnimation] = useState(
    'animation-open-dropdown-fadeInDownSlow'
  );

  function onClickDropdown(e, menu) {
    e.stopPropagation();
    menu.onClick(menu, () => {
      eventCallbackClick && eventCallbackClick(menu);
    });
    if (autoClose) {
      setAnimation('animation-close-dropdown-rotate');
      setTimeout(() => autoClose(), 150);
    }
  }

  useEffect(() => {
    setAnimation(dropdownAnimation);
  }, [dropdownAnimation]);

  const dropdownCN = () => {
    return ctl(`
      list-wrapper-dropdown pt-[8px] pb-[8px]
      ${animation && animation}
      ${vPosition === 'top' && 'top-0 -translate-y-[calc(100%+8px)]'}
      ${vPosition === 'bottom' && 'bottom-0 translate-y-[calc(100%+8px)]'}
      ${position === 'left' && 'left-0'}
      ${position === 'right' && 'right-0'}
      ${position === 'middle' && 'left-0 -translate-x-1/2'}
    `);
  };

  return (
    <div className={dropdownCN()}>
      {data.map((menu, index) => {
        return (
          <div
            className={`content-dropdown cursor-pointer`}
            onClick={(e) => {
              e.stopPropagation();
              onClickDropdown(e, menu);
            }}
            key={index}
            data-cy={menu.dataCy}
          >
            <SVGIcon
              size="24"
              iconName={menu.icon}
              fillColor={menu?.colorHex || 'var(--n-600)'}
              customClass="mr-[8px]"
            />
            <p
              className="typography-button whitespace-nowrap"
              style={{ color: menu?.colorHex || '' }}
            >
              {getObjectiveLocale(menu.title)}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default ListDropdown;

import React from 'react';

import ctl from '@netlify/classnames-template-literals';

import Label from 'components/shared/Label/Label';

function LoadingComponent(props) {
  const { size, text, hasText, className } = props;

  const LoadingComponentCN = ctl(
    `flex
    justify-center
    items-center
    ${className}`
  );

  return (
    <div className={LoadingComponentCN}>
      <div
        className="inline-block relative lds-ring"
        style={{ width: size + 'px', height: size + 'px' }}
      >
        <div style={{ width: size + 'px', height: size + 'px' }}></div>
        <div style={{ width: size + 'px', height: size + 'px' }}></div>
        <div style={{ width: size + 'px', height: size + 'px' }}></div>
        <div style={{ width: size + 'px', height: size + 'px' }}></div>
      </div>
      {text && hasText && (
        <div className="loading-text">
          <Label>{text}</Label>
        </div>
      )}
    </div>
  );
}

LoadingComponent.defaultProps = {
  text: 'Loading',
  hasText: true,
  size: 24
};

export default LoadingComponent;

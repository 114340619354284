import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router';

import { getRevieweeList } from 'client/admin/FormalReviewRevieweeClient';
import { getListFilter } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { useDeepEffect } from 'utils/useDeepEffect';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Table from 'components/design-system/table/Table';
import ListEmptyState from 'components/shared/ListEmptyState';
import Loading from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';

const FILTER_ID = 'revieweelist';

const listShowHeaders = ['jobTitle', 'division'];

const RevieweeList = () => {
  const filterById = useFilter((state) => state.filterById);
  const [listData, setListData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [headerColumnFormat, setHeaderColumnFormat] = useState([]);
  const history = useHistory();

  const firstUpdate = useRef(true);

  const { config } = useUser();

  const { userSubtitleField } = config;

  let filterMenu = config?.generalFilterOptions
    ? [...config.generalFilterOptions]
    : [];

  const _getListFilter = (addedParams) => {
    const fetchFilter = () => getListFilter(addedParams);

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['filter', addedParams],
        ({ pageParam }) => fetchFilter(pageParam),
        {
          getNextPageParam: (lastPage, allPages) =>
            lastPage.pagination?.next || 0
        }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  const getFilterData = async (addedParams) =>
    await getListFilter({ q: addedParams?.q, key: addedParams?.key });

  const getData = async (additionalParams = {}) => {
    setLoading(true);
    const { ...params } = filterById[FILTER_ID];
    const defaultParams = {
      limit: 10,
      sortColumn: 'name',
      sortDirection: 'asc'
    };
    const { data, pagination } = await getRevieweeList({
      ...params,
      ...additionalParams,
      ...defaultParams
    });
    const nameHeader = {
      id: 'name',
      name: 'Name',
      widthClass: 'w-[240px]',
      grow: true
    };
    const cycleHeader = [
      {
        id: 'active-cycles',
        name: 'Active Cycles',
        widthClass: 'w-[200px]',
        grow: true
      },
      {
        id: 'ended-cycles',
        name: 'Ended Cycles',
        widthClass: 'w-[200px]',
        grow: true
      },
      { id: 'empty-space', name: '', widthClass: 'w-[60px]' }
    ];
    if (data && data.length > 0) {
      if (headerColumnFormat.length == 0) {
        const infos = data[0]?.info;
        const header = Object.keys(infos).map((info) => ({
          id: info,
          name: info.replace(/([A-Z])/g, ' $1'),
          widthClass: 'w-[200px]',
          grow: true,
          hide: !listShowHeaders.includes(info),
          isInfo: true
        }));
        setHeaderColumnFormat([nameHeader, ...header, ...cycleHeader]);
      }
      setPagination({
        olderThan: pagination?.next?.olderThan,
        newerThan: pagination?.prev?.newerThan
      });
      setHasMore(data.length == 10);
      setListData(data);
      setLoading(false);
    } else {
      currentPage == 1 && setHeaderColumnFormat([nameHeader, ...cycleHeader]);
      setHasMore(false);
      setPagination({
        olderThan: null,
        newerThan: pagination?.next?.olderThan
      });
      setListData([]);
      setLoading(false);
    }
  };

  const openDetail = (e, data) =>
    history.push(`/reviews/cycles/user-assignments/${data.id}`, {
      fullName: data.name
    });

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    getData({ olderThan: pagination.olderThan });
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
    getData({ newerThan: pagination.newerThan });
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, []);

  useDeepEffect(() => {
    if (!firstUpdate.current && filterById[FILTER_ID]) {
      getData();
    }
  }, [filterById, firstUpdate]);

  return (
    <div className="gap-[16px] grid grid-cols-1">
      <AdvancedFilter
        id={FILTER_ID}
        filterOptions={['search', 'menuFilter']}
        filterMenu={filterMenu}
        filterClient={getFilterData}
        dialogQueryFn={(params) => _getListFilter(params)}
        defaultFilter={filterById[FILTER_ID]}
      />
      {headerColumnFormat.length > 0 && (
        <Table
          headers={headerColumnFormat}
          withHideColumn={true}
          fixedLeftCount={1}
          fixedRightCount={1}
        >
          {loading ? (
            <Loading />
          ) : (
            listData?.length == 0 && (
              <ListEmptyState
                fullHeight={true}
                emptyIcon="icon-no-cycles"
                title={getObjectiveLocale('No Cycle Match Your Filter')}
                subtitle={getObjectiveLocale(
                  'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
                )}
              />
            )
          )}
          {!loading &&
            listData.map((data) => (
              <Table.Row
                id={data.id}
                onClick={(e) => openDetail(e, data)}
                dataCy={`row-${data.id}`}
                key={data.id}
              >
                {headerColumnFormat.map((header, index) => {
                  if (header.id == 'name') {
                    return (
                      <Table.Column key={index}>
                        <div className="flex flex-col min-w-[240px]">
                          <p
                            data-cy={`col-${header.id}-${data.id}`}
                            className="typography-h400 text-n-900 truncate"
                          >
                            {data.name}
                          </p>
                          <p
                            data-cy={`col-${header.id}-sub-${data.id}`}
                            className="typography-h100 text-n-800"
                          >
                            {userSubtitleField == 'job_title'
                              ? data?.jobTitle
                              : data?.info?.[userSubtitleField] || '-'}
                          </p>
                        </div>
                      </Table.Column>
                    );
                  }
                  if (header.isInfo) {
                    return (
                      <Table.Column key={index}>
                        <p
                          data-cy={`col-${header.id}-${data.id}`}
                          className="typography-paragraph text-n-800"
                        >
                          {data?.info?.[header.id] || '--'}
                        </p>
                      </Table.Column>
                    );
                  }
                  if (header.id == 'active-cycles') {
                    return (
                      <Table.Column key={index}>
                        <p
                          data-cy={`col-${header.id}-${data.id}`}
                          className="typography-paragraph text-n-800"
                        >
                          {data?.activeCyclesCount} Cycles
                        </p>
                      </Table.Column>
                    );
                  }
                  if (header.id == 'ended-cycles') {
                    return (
                      <Table.Column key={index}>
                        <p
                          data-cy={`col-${header.id}-${data.id}`}
                          className="typography-paragraph text-n-800"
                        >
                          {data?.endedCyclesCount} Cycles
                        </p>
                      </Table.Column>
                    );
                  }
                  return <Table.Column key={index}></Table.Column>;
                })}
              </Table.Row>
            ))}
        </Table>
      )}
      <div className="flex justify-end items-center pb-[40px] pt-[24px]">
        <PaginationModule
          pageNumber={currentPage}
          handlePrev={handlePrev}
          handleNext={handleNext}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
};

export default RevieweeList;

import React, { useRef } from 'react';

import { useValueCompetency } from 'context/admin/ValueCompetencyContext';
import useClickOutside from 'hooks/useClickOutside';
import Config from 'utils/Config';

import AWSImage from 'components/design-system/AWSImage';
import ToastNotif from 'components/shared/ToastNotif';

const ModalValuesImage = ({ _onChange, _onClick, id, type }) => {
  const { setIsChooseImageOpen } = useValueCompetency();
  const modalRef = useRef();
  useClickOutside(modalRef, () => setIsChooseImageOpen(null));

  const listValueImage = [
    'good-judgement',
    'accountability',
    'agile',
    'caring',
    'collaborative',
    'communication-presence',
    'courageous',
    'creative-problem-solving',
    'curious',
    'environment',
    'excellence',
    'extra-mile',
    'flexible',
    'generous',
    'honest',
    'impactful',
    'innovative',
    'integrity',
    'passionate',
    'positive-attitude',
    'resourceful',
    'selfless',
    'service',
    'social',
    'taking-ownership',
    'tech-savvy'
  ];

  return (
    <div ref={modalRef} className="modal-choose-value-image">
      <div>
        <input
          type="file"
          name="inputfile"
          id="inputfile"
          className="inputfile"
          onChange={(e) => _onChange(e, id, type)}
        />
        <label htmlFor="inputfile">
          <img className="import-image" src={Config.asset.admin.camera} />
        </label>
      </div>
      {listValueImage.map((val, idx) => (
        <AWSImage
          dataCy={
            type == 'edit' ? `edit-values-image-${val}` : `values-image-${val}`
          }
          onClick={() => _onClick(val, id, type)}
          key={idx}
          src={
            'https://s3-ap-southeast-1.amazonaws.com/culture-happy5/Assets/Values/value-' +
            val +
            '.png'
          }
        />
      ))}
    </div>
  );
};

const TooltipValueCompetency = ({ title, children, fitContent, index }) => {
  return (
    <div className="wrapper-tooltip">
      <div
        className={`tooltip
          ${fitContent ? 'width-fit-content' : ''}
          ${index == 0 ? 'top-[28px]' : '-top-28'}
        `}
      >
        {title}
      </div>
      {children}
    </div>
  );
};

const NotificationValueCompetency = () => {
  const { showNotif, notifMessage } = useValueCompetency();

  return (
    <React.Fragment>
      <ToastNotif
        showNotif={showNotif}
        message={notifMessage}
        datacy="competencies-values-show-success"
      />
    </React.Fragment>
  );
};

const DialogAddLevel = ({ onClick, setIsAddLevelOpen }) => {
  const dialogRef = useRef();
  useClickOutside(dialogRef, () => setIsAddLevelOpen(false));

  return (
    <div ref={dialogRef} className="dialog-add-level py-[14px] px-[16px]">
      <p className="cursor-pointer mb-[12px]" onClick={() => onClick('basic')}>
        Add basic
      </p>
      <p className="cursor-pointer" onClick={() => onClick('level')}>
        Add level
      </p>
    </div>
  );
};

export {
  ModalValuesImage,
  TooltipValueCompetency,
  NotificationValueCompetency,
  DialogAddLevel
};

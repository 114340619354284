import React, { useEffect, useState } from 'react';

import { getConfig } from 'client/admin/Config';
import { useUser } from 'context/UserContext';
import useOrganization from 'hooks/useOrganization';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ContentNavigator from 'components/shared/ContentNavigator';
import InputField from 'components/shared/InputField';

import {
  CheckBoxOrganization,
  ListColorBox,
  RadioButtonOrganization,
  ToggleCard,
  changeCheckboxData,
  changeUserData,
  setActiveNavigator
} from './OrganizationHelper';

const Feeds = ({ appData, setAppData, configParams }) => {
  const { config } = useUser();

  const { configData, changeConfigData, changeOriginConfigData } =
    useOrganization((configData) => ({
      configData: configData.configData,
      changeConfigData: configData.changeConfigData,
      changeOriginConfigData: configData.changeOriginConfigData
    }));
  const isReadOnly = usePermission('settingsOrganizationFeeds') === 'read_only';

  const [activeSection, setActiveSection] = useState('sharing-section');
  const bytesNumber = 1024 * 1024;

  const listSharing = [
    {
      element: 'toggle',
      labelPermission: getObjectiveLocale('Permissions'),
      isChecked: appData?.allowSharing,
      onChange: () =>
        setAppData({ ...appData, allowSharing: !appData?.allowSharing }),
      insideCardPermission: true,
      cardTitle: getObjectiveLocale('Allow Sharing'),
      cardDescription: getObjectiveLocale(
        'User can share content from this app.'
      ),
      disabled: isReadOnly,
      dataCy: 'allowSharing'
    },
    {
      element: 'toggle',
      labelPermission: getObjectiveLocale('Chat'),
      isChecked: configData?.featureConfig?.chatting,
      onChange: () =>
        changeUserData(
          'chatting',
          'featureConfig',
          null,
          configData,
          changeConfigData
        ),
      cardTitle: getObjectiveLocale('Chatting'),
      cardDescription: getObjectiveLocale('Able to use chat feature'),
      disabled: isReadOnly,
      dataCy: 'chatting'
    },
    {
      element: 'toggle',
      labelPermission: getObjectiveLocale('Button to Performance'),
      isChecked: configData?.featureConfig?.buttonToPerformance,
      onChange: () =>
        changeUserData(
          'buttonToPerformance',
          'featureConfig',
          null,
          configData,
          changeConfigData
        ),
      cardTitle: getObjectiveLocale('Button to Performance'),
      cardDescription: getObjectiveLocale('Able redirect to Performance'),
      disabled: isReadOnly,
      dataCy: 'buttonToPerformance'
    }
  ];

  const listVideo = [
    {
      element: 'toggle',
      isChecked: appData?.allowVideo,
      onChange: () =>
        setAppData({ ...appData, allowVideo: !appData?.allowVideo }),
      insideCardPermission: true,
      cardTitle: getObjectiveLocale('Allow Video'),
      cardDescription: getObjectiveLocale('User can upload video to this app.'),
      dataCy: 'allowVideo',
      inputElement: [
        {
          element: 'input',
          label: 'Admin Video Length (in seconds)',
          placeholder: 'Admin Video Length (in seconds)',
          handleChange: (e) =>
            changeUserData(
              'adminVideoLength',
              'videoConfig',
              parseInt(e.target.value),
              appData,
              setAppData
            ),
          insideCardPermission: true,
          value: appData?.videoConfig?.adminVideoLength,
          dataCy: 'adminVideoLength',
          type: 'number',
          className: 'mr-[24px]',
          isVisible: true
        },
        {
          element: 'input',
          label: 'User Video Length (in seconds)',
          placeholder: 'User Video Length (in seconds)',
          handleChange: (e) =>
            changeUserData(
              'userVideoLength',
              'videoConfig',
              parseInt(e.target.value),
              appData,
              setAppData
            ),
          insideCardPermission: true,
          value: appData?.videoConfig?.userVideoLength,
          type: 'number',
          dataCy: 'userVideoLength',
          className: 'mt-[24px]',
          isVisible: true
        }
      ],
      disabled: isReadOnly
    },
    {
      element: 'checkbox',
      label: getObjectiveLocale('Allowed Video Extension'),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.allowedVideoExtensions,
          'allowedVideoExtensions',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.allowedVideoExtensions,
      isSingleSelect: false,
      dataCy: 'allowedVideoExtensions',
      listOption: [
        { id: 'mp4', title: 'mp4' },
        { id: 'mov', title: 'mov' }
      ],
      disabled: isReadOnly
    }
  ];

  const listComment = [
    {
      element: 'checkbox',
      label: getObjectiveLocale('Toggle commenting on spesific type of post'),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.featureConfig?.commentable,
          'commentable',
          'featureConfig',
          configData,
          changeConfigData
        ),
      selected: configData?.featureConfig?.commentable,
      dataCy: 'commentable',
      isSingleSelect: false,
      listOption: [
        { id: 'thought', title: 'Thought' },
        { id: 'media', title: 'Media' },
        { id: 'multiple_media', title: 'Multiple Media (Image & Video)' },
        { id: 'link', title: 'Link' },
        { id: 'feeling', title: 'Feeling' },
        { id: 'attachment', title: 'Attachment' },
        { id: 'recognition', title: 'Recognition' },
        { id: 'poll', title: 'Poll' },
        { id: 'group_recognition', title: 'Group Recognition' },
        { id: 'leaderboard', title: 'Leaderboard' },
        {
          id: 'multiple_content',
          title: 'Multiple Content (Image, Video, & File)'
        }
      ],
      disabled: isReadOnly
    }
  ];

  const listFeature = [
    {
      element: 'toggle',
      labelPermission: getObjectiveLocale('Post Leaderboard'),
      isChecked: appData?.feedConfig?.leaderboard,
      onChange: () =>
        changeUserData('leaderboard', 'feedConfig', null, appData, setAppData),
      cardTitle: getObjectiveLocale('Post Leaderboard'),
      cardDescription: getObjectiveLocale(
        'Untuk memunculkan post leaderboard (siapa paling banyak recognize & direcognize setiap minggunya)'
      ),
      disabled: isReadOnly,
      dataCy: 'leaderboard'
    }
  ];

  const listGroup = [
    {
      element: 'radio',
      label: getObjectiveLocale('Enable user to create group'),
      handleChange: (data) =>
        changeUserData(
          'createGroupBy',
          'featureConfig',
          data,
          configData,
          changeConfigData
        ),
      selected: configData?.featureConfig?.createGroupBy,
      isSingleSelect: true,
      dataCy: 'createGroupBy',
      listOption: [
        { id: 'all', title: 'Everyone can create new group' },
        { id: 'admin', title: 'Admin only' },
        { id: 'predefined', title: 'None' }
      ],
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      labelPermission: getObjectiveLocale('Group Notification'),
      isChecked: configData?.toggleGroupNotification,
      onChange: () =>
        changeConfigData({
          ...configData,
          toggleGroupNotification: !configData?.toggleGroupNotification
        }),
      cardTitle: getObjectiveLocale('Group Notification'),
      cardDescription: getObjectiveLocale(
        'Enable user to toggle notification on every group they joined'
      ),
      disabled: isReadOnly,
      dataCy: 'toggleGroupNotification'
    }
  ];

  const listRecognition = [
    {
      element: 'toggle',
      labelPermission: getObjectiveLocale('Send Reminder Recognition'),
      isChecked: appData?.sendReminder,
      onChange: () =>
        setAppData({ ...appData, sendReminder: !appData?.sendReminder }),
      cardTitle: getObjectiveLocale('Send Reminder Recognition'),
      cardDescription: getObjectiveLocale(
        'Enable in-app push notification reminder for everyone to give recognition. Reminder will be sent every Friday at 9 AM and 3 PM (GMT+7)'
      ),
      disabled: isReadOnly,
      dataCy: 'sendReminder'
    }
  ];

  const listPost = [
    {
      element: 'checkbox',
      label: getObjectiveLocale(
        'Type of Post Content that are allowed to be posted by users'
      ),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.featureConfig?.allowedPostTypes,
          'allowedPostTypes',
          'featureConfig',
          configData,
          changeConfigData
        ),
      selected: configData?.featureConfig?.allowedPostTypes,
      isSingleSelect: false,
      dataCy: 'allowedPostTypes',
      listOption: [
        { id: 'thought', title: 'Thought' },
        { id: 'media', title: 'Media' },
        { id: 'multiple_media', title: 'Multiple Media' },
        { id: 'link', title: 'Link' },
        { id: 'feeling', title: 'Feeling' },
        { id: 'attachment', title: 'Attachment' },
        { id: 'recognition', title: 'Recognition' },
        { id: 'poll', title: 'Poll' },
        { id: 'group_recognition', title: 'Group Recognition' },
        { id: 'leaderboard', title: 'Leaderboard' },
        { id: 'multiple_content', title: 'Multiple Content' }
      ],
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      labelPermission: getObjectiveLocale('Allowed Save Post'),
      isChecked: configData?.savePostAllowed,
      onChange: () =>
        changeConfigData({
          ...configData,
          savePostAllowed: !configData?.savePostAllowed
        }),
      cardTitle: getObjectiveLocale('Allowed Save Post'),
      cardDescription: getObjectiveLocale('Enable all users to Save Post'),
      disabled: isReadOnly,
      dataCy: 'savePostAllowed'
    },
    {
      element: 'input',
      label: 'Maximum number of Media allowed in a single post',
      placeholder: 'Maximum number of Media',
      handleChange: (e) =>
        changeConfigData({
          ...configData,
          maxNumberOfMedia: parseInt(e.target.value)
        }),
      value: configData?.maxNumberOfMedia,
      type: 'number',
      dataCy: 'maxNumberOfMedia',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Maximum number of Attachments allowed in a single post',
      placeholder: 'Maximum number of Attachments',
      handleChange: (e) =>
        changeConfigData({
          ...configData,
          maxNumberOfAttachments: parseInt(e.target.value)
        }),
      value: configData?.maxNumberOfAttachments,
      type: 'number',
      dataCy: 'maxNumberOfAttachments',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Maximum size for each Attachments (in MB)',
      placeholder: 'Maximum size for each Attachments (in MB)',
      handleChange: (e) =>
        changeConfigData({
          ...configData,
          maxAttachmentSize: parseInt(e.target.value * bytesNumber)
        }),
      value: configData?.maxAttachmentSize / bytesNumber,
      type: 'number',
      dataCy: 'maxAttachmentSize',
      disabled: isReadOnly
    },
    {
      element: 'radio',
      label: getObjectiveLocale('Allow Post as Admin'),
      handleChange: (data) =>
        changeUserData(
          'allowPostAsAdmin',
          null,
          data,
          configData,
          changeConfigData
        ),
      selected: configData?.allowPostAsAdmin,
      isSingleSelect: true,
      dataCy: 'allowPostAsAdmin',
      listOption: [
        { id: 'general_group', title: 'General Group Only' },
        { id: 'all_group', title: 'All Group' }
      ],
      disabled: isReadOnly
    },
    {
      element: 'radio',
      label: getObjectiveLocale('Repost Allowed For'),
      handleChange: (data) =>
        changeUserData(
          'repostAllowedFor',
          null,
          data,
          configData,
          changeConfigData
        ),
      selected: configData?.repostAllowedFor,
      isSingleSelect: true,
      dataCy: 'repostAllowedFor',
      listOption: [
        { id: 'all', title: 'All' },
        { id: 'admin', title: 'Admin' }
      ],
      disabled: isReadOnly
    },
    {
      element: 'radio',
      label: getObjectiveLocale('Post Allowed For'),
      handleChange: (data) =>
        changeUserData(
          'postAllowedFor',
          null,
          data,
          configData,
          changeConfigData
        ),
      selected: configData?.postAllowedFor,
      isSingleSelect: true,
      dataCy: 'postAllowedFor',
      listOption: [
        { id: 'all', title: 'All' },
        { id: 'admin', title: 'Admin' }
      ],
      disabled: isReadOnly
    },
    {
      element: 'color',
      selectedColor: appData?.color?.primary,
      changeColor: (color) =>
        changeUserData('primary', 'color', color, appData, setAppData),
      useReset: false,
      label: getObjectiveLocale('Theme Mobile Culture Color'),
      disabled: isReadOnly,
      dataCy: 'color-primary'
    },
    {
      element: 'color',
      selectedColor: appData?.color?.photo,
      changeColor: (color) =>
        changeUserData('photo', 'color', color, appData, setAppData),
      useReset: false,
      label: getObjectiveLocale('Set background color for Image Post Type'),
      disabled: isReadOnly,
      dataCy: 'color-photo'
    },
    {
      element: 'color',
      selectedColor: appData?.color?.thought,
      changeColor: (color) =>
        changeUserData('thought', 'color', color, appData, setAppData),
      useReset: false,
      label: getObjectiveLocale('Set background color for Thought Post Type'),
      disabled: isReadOnly,
      dataCy: 'color-thought'
    },
    {
      element: 'color',
      selectedColor: appData?.color?.recognition,
      changeColor: (color) =>
        changeUserData('recognition', 'color', color, appData, setAppData),
      useReset: false,
      label: getObjectiveLocale(
        'Set background color for Recognition Post Type'
      ),
      disabled: isReadOnly,
      dataCy: 'color-recognition'
    },
    {
      element: 'color',
      selectedColor: appData?.color?.feeling,
      changeColor: (color) =>
        changeUserData('feeling', 'color', color, appData, setAppData),
      useReset: false,
      label: getObjectiveLocale('Set background color for Feeling Post Type'),
      disabled: isReadOnly,
      dataCy: 'color-feeling'
    },
    {
      element: 'color',
      selectedColor: appData?.color?.survey,
      changeColor: (color) =>
        changeUserData('survey', 'color', color, appData, setAppData),
      useReset: false,
      label: getObjectiveLocale('Set background color for Survey Post Type'),
      disabled: isReadOnly,
      dataCy: 'color-survey'
    }
  ];

  const listPhoto = [
    {
      element: 'checkbox',
      label: getObjectiveLocale('Allowed Image Extension'),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.allowedImageExtensions,
          'allowedImageExtensions',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.allowedImageExtensions,
      isSingleSelect: false,
      dataCy: 'allowedImageExtensions',
      listOption: [
        { id: 'jpeg', title: 'jpeg' },
        { id: 'jpg', title: 'jpg' },
        { id: 'png', title: 'png' },
        { id: 'gif', title: 'gif' },
        { id: 'bmp', title: 'bmp' }
      ],
      disabled: isReadOnly
    }
  ];

  const listAttachment = [
    {
      element: 'checkbox',
      label: getObjectiveLocale('Allowed Attachment Extensions'),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.cultureAllowedAttachmentExtensions,
          'cultureAllowedAttachmentExtensions',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.cultureAllowedAttachmentExtensions,
      isSingleSelect: false,
      dataCy: 'cultureAllowedAttachmentExtensions',
      listOption: [
        { id: 'image/*', title: 'image' },
        { id: 'pdf', title: 'pdf' },
        { id: 'zip', title: 'zip' },
        { id: 'video/', title: 'video' },
        { id: 'audio/mpeg', title: 'audio/mpeg' },
        { id: 'rar', title: 'rar' },
        { id: '7z', title: '7z' },
        { id: 'csv', title: 'csv' },
        { id: 'doc', title: 'doc' },
        { id: 'key', title: 'key' },
        { id: 'ppt', title: 'ppt' },
        { id: 'pptx', title: 'pptx' },
        { id: 'xls', title: 'xls' },
        { id: 'xlsx', title: 'xlsx' },
        { id: 'docx', title: 'docx' }
      ].sort((a, b) => a.title.localeCompare(b.title)),
      disabled: isReadOnly
    }
  ];

  let list = [
    { id: 'sharing-section', name: 'Sharing', list: listSharing },
    { id: 'comment-section', name: 'Comment', list: listComment },
    { id: 'feature-section', name: 'Feature', list: listFeature },
    { id: 'group-section', name: 'Group', list: listGroup },
    { id: 'post-section', name: 'Post', list: listPost },
    { id: 'recognition-section', name: 'Recognition', list: listRecognition },
    { id: 'video-section', name: 'Video', list: listVideo },
    { id: 'photo-section', name: 'Photo', list: listPhoto },
    { id: 'attachment-section', name: 'Attachment', list: listAttachment }
  ];

  const getData = async () => {
    const { data } = await getConfig(configParams);
    if (data) {
      changeConfigData(data);
      changeOriginConfigData(data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="overflow-auto h-[calc(100vh-64px)] w-[87%] relative"
      id="scrollable-config"
      onScroll={(e) => setActiveNavigator(e, list, setActiveSection, -48)}
    >
      <div className="w-[768px] mt-[24px]">
        <div className="fixed right-0 mt-[24px] w-[160px] z-index-10">
          <ContentNavigator
            title="CONTENTS"
            activeSection={activeSection}
            listNavigatorContents={list}
            sectionContent="scrollable-config"
            additionalOffset={20}
          />
        </div>
        <p className="typography-h300 text-n-800 uppercase mb-[24px]">
          {getObjectiveLocale('Feeds Configuration')}
        </p>
        {list.map((el, index) => (
          <section
            className={`border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px]
                ${el?.id === 'photo-section' ? 'pb-[64px]' : ''}`}
            key={index}
            id={`${el?.id}`}
          >
            <p className="typography-h500 text-n-900 mb-[24px]">
              {getObjectiveLocale(el.name)}
            </p>
            {el.list?.length > 0 &&
              el.list.map((input, idx) => {
                return input.element === 'input' ? (
                  <InputField
                    {...input}
                    className="w-[240px] mt-[16px]"
                    key={`${index}-${idx}`}
                  />
                ) : input.element === 'checkbox' ? (
                  <CheckBoxOrganization {...input} key={`${index}-${idx}`} />
                ) : input.element === 'toggle' ? (
                  <ToggleCard {...input} key={`${index}-${idx}`} />
                ) : input.element === 'radio' ? (
                  <RadioButtonOrganization {...input} key={`${index}-${idx}`} />
                ) : (
                  <ListColorBox {...input} key={`${index}-${idx}`} />
                );
              })}
          </section>
        ))}
      </div>
    </div>
  );
};

export default Feeds;

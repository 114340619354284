import React, { useState } from 'react';

import { getExtraInformation, getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Avatar from 'components/shared/Avatar';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import TruncateParagraph from 'components/shared/TruncateParagraph';

import { ADMIN, B2B, MANAGER, PEER, SELF } from '../const';

const getTooltipText = (type) => {
  if (type == MANAGER || type == SELF)
    // because only manager that can lock peer even type is self
    return 'Set by manager, you cannot remove this peer';
  if (type == PEER) return 'Selected by this peer, you cannot remove them';
  if (type == ADMIN) return 'Set by admin, you cannot remove this peer';
  return false;
};

const UserCardRow = ({
  userdata,
  useIcon = false,
  onRemove,
  isSelf,
  canRemove,
  allowLockedByManager,
  onLock,
  isForceLock
}) => {
  const profilePicture = userdata?.profilePicture || userdata?.image || null;
  const [isHover, setIsHover] = useState(false);
  const [isLocked, setLocked] = useState(userdata?.isMandatory);
  const isB2B = userdata?.source == B2B;
  const addedBy = isB2B ? PEER : userdata?.selectorType;

  // added by:
  // manager -> selectorType = manager;
  // self -> selectorType == self;
  // by peers -> source == b2b

  const tooltipText = isForceLock
    ? 'Peer Selection had ended. You cannot remove this peer'
    : getTooltipText(addedBy);

  const isCanLock =
    !isSelf &&
    allowLockedByManager &&
    addedBy != ADMIN &&
    addedBy != PEER &&
    !isForceLock;

  const onRemoveEvent = () => {
    onRemove && onRemove(userdata);
  };

  const onLockedEvent = () => {
    const currentValue = isLocked;
    onLock && onLock(userdata, !currentValue);
    setLocked(!currentValue);
  };

  const isLockedIconShowed =
    isLocked ||
    isForceLock ||
    (isSelf && addedBy == MANAGER && isLocked) ||
    addedBy == PEER ||
    addedBy == ADMIN;

  const isCanRemove = canRemove && !isLockedIconShowed;

  return (
    <div
      className={`hover:bg-base-30024 px-[16px] py-[8px] flex flex-row cursor-pointer ${
        useIcon ? 'justify-between' : ''
      } items-center`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="flex flex-row items-center">
        <Avatar
          src={profilePicture}
          name={userdata?.name}
          className="mr-[16px]"
          size={40}
        />
        <div className={`flex flex-col ${useIcon ? 'w-[242px]' : 'w-[496px]'}`}>
          <TruncateParagraph
            className="typography-h500 text-n-900"
            position="bottom"
            useMaxWidth={true}
          >
            {userdata?.name}
          </TruncateParagraph>
          <TruncateParagraph
            className="typography-paragraph text-n-800"
            position="bottom"
            useMaxWidth={true}
          >
            {getExtraInformation(userdata?.title, userdata?.subtitle)}
          </TruncateParagraph>
        </div>
      </div>
      {useIcon && (
        <div className="flex flex-row items-center">
          <div className={`${isHover ? 'visible' : 'invisible'} flex flex-row`}>
            {isCanRemove && (
              <Button
                variant="tertiary"
                customClass={`cursor-pointer mr-[16px]`}
                datacy="remove"
                onClick={onRemoveEvent}
              >
                {getObjectiveLocale('Remove')}
              </Button>
            )}
            {isCanLock && (
              <Button
                variant="tertiary"
                customClass={`cursor-pointer mr-[16px]`}
                datacy="lock"
                onClick={onLockedEvent}
              >
                {getObjectiveLocale(isLocked ? 'Unlock' : 'Lock')}
              </Button>
            )}
          </div>
          {isLockedIconShowed && (
            <TooltipContainer
              text={tooltipText}
              show={tooltipText}
              useMaxWidth={false}
              align="right"
            >
              <div
                data-cy={'isMandatory'}
                className="flex flex-row items-center leading-[16px]"
              >
                <SVGIcon
                  iconName="icon-lock_outline"
                  fillColor="var(--n-600)"
                  size="22"
                />
                <p className="typography-button text-n-800">
                  {getObjectiveLocale('Required')}
                </p>
              </div>
            </TooltipContainer>
          )}
        </div>
      )}
    </div>
  );
};

export default UserCardRow;

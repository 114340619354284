import client from './ApiClient';

function getAllUsers(query) {
  return client(`v1/users`, { method: 'GET', params: query });
}

function ImportCsv(data) {
  return client(`v3/objectives/batch`, { method: 'POST', body: data });
}

export { getAllUsers, ImportCsv };

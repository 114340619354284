import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Avatar from 'components/shared/Avatar';
import SVGIcon from 'components/shared/SVGIcon';

import './SidebarBehaviour.scss';

function SidebarBehaviour({
  behaviourData,
  cultureCount,
  performanceCount,
  type,
  closeSidebar,
  userId,
  pagination = [],
  appendData,
  behaviorType
}) {
  const { config, organization, appType } = useUser();
  const history = useHistory();
  const { nextOffset } = pagination;
  const params = useParams();

  const showPerformanceCount = appType !== 'culture';
  const showCultureCount =
    config?.showCurrentAppBehaviorCounter && type === 'strength';

  return (
    <div className="px-[0px] sidebar-behaviour surface animated-half slideInRight">
      <div className="content-sidebar-behaviour pt-[88px]">
        <div>
          <div className="header-sidebar-behaviour flex justify-between items-center">
            <p className="typography-h400 px-[24px]">
              {params?.values == 'values' || behaviorType == 'values'
                ? getObjectiveLocale('Values')
                : getObjectiveLocale('Skills')}
            </p>
            <SVGIcon
              onClick={closeSidebar}
              iconName="icon-clear"
              customClass="icon-close-grey-sidebar mr-[24px]"
              size={24}
              fillColor="var(--n-600)"
            />
          </div>
          <div className="skills-card px-[24px]">
            <div className="flex">
              {(params?.values == 'values' || behaviorType == 'values') &&
                (behaviourData[0]?.iconImageAttributes?.iconUrl ? (
                  <AWSImage
                    className="w-[40px] h-[40px] mr-[16px] rounded-[50px]"
                    src={behaviourData[0]?.iconImageAttributes?.iconUrl}
                  />
                ) : (
                  <SVGIcon
                    iconName={
                      type == 'strength' ? 'icon-thumb_up_alt' : 'icon-feedback'
                    }
                    fillColor={
                      type == 'strength' ? 'var(--g-600)' : 'var(--y-600)'
                    }
                    customClass={`w-[40px] h-[40px] items-center justify-center mr-[16px]  rounded-[50px] ${
                      type == 'strength' ? 'bg-g-200' : 'bg-y-200'
                    }`}
                    size="16"
                  />
                ))}
              <div>
                <div className="typography-h500">
                  {behaviourData[0]?.behaviorTitle}
                </div>
                <div>
                  {showPerformanceCount && (
                    <>
                      <span className="tyopgraphy-paragraph text-n-600">
                        {organization.appName ||
                          getObjectiveLocale('Performance')}
                      </span>{' '}
                      <span className="tyopgraphy-paragraph text-n-800">
                        {performanceCount}
                      </span>
                    </>
                  )}
                  {showPerformanceCount && showCultureCount && (
                    <span className="interpunct" />
                  )}
                  {showCultureCount && (
                    <>
                      <span className="tyopgraphy-paragraph text-n-600">
                        {organization.cultureAppName ||
                          getObjectiveLocale('Culture')}
                      </span>{' '}
                      <span className="tyopgraphy-paragraph text-n-800">
                        {cultureCount}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`header-icon flex mx-[24px] ${
              type == 'strength' ? 'bg-g-100' : 'bg-y-100'
            }`}
          >
            <SVGIcon
              iconName={
                type == 'strength' ? 'icon-thumb_up_alt' : 'icon-feedback'
              }
              fillColor={type == 'strength' ? 'var(--g-600)' : 'var(--y-600)'}
              customClass="mr-[8px]"
              size="16"
            />
            <span
              className={`typography-h200 ${
                type == 'strength' ? 'text-g-800' : 'text-y-800'
              }`}
            >
              {type == 'strength'
                ? getObjectiveLocale('Strength received')
                : getObjectiveLocale('Improvement received')}
            </span>
          </div>
          <div className="divider-line mt-[16px]" />
        </div>
        <div className="overflow-scroll pt-[24px]">
          {behaviourData?.map((behaviour, index) => {
            return (
              <div className="behaviour-report px-[24px]" key={index}>
                <div className="user-info flex items-center">
                  <Avatar
                    src={behaviour?.fromUser?.profilePicture}
                    name={behaviour?.fromUser?.name}
                    size={24}
                  />
                  <div className="user-name pl-[16px]">
                    <p className="typography-h200">
                      {behaviour?.fromUser?.name}
                    </p>
                    <p className="typography-h100 text-n-800">
                      {behaviour?.fromUser?.jobTitle}
                    </p>
                  </div>
                </div>
                <div className="ml-[40px] mt-[12px]">
                  <p className="typography-h100 text-n-600">Source: </p>
                  {behaviour?.objective?.name ? (
                    <p
                      className="text-base-600 typography-paragraph cursor-pointer"
                      onClick={() => {
                        history.replace({
                          pathname: `/users/${userId}/placement/${behaviour?.objective?.placementId}/objectives/${behaviour?.objective?.id}`,
                          state: {
                            behaviourId: behaviour?.behaviorId,
                            type: type,
                            values: params?.values || behaviorType
                          }
                        });
                      }}
                    >
                      {behaviour?.objective?.name}
                    </p>
                  ) : (
                    <p className="typography-paragraph">{behaviour?.source}</p>
                  )}
                  <p className="mt-[8px] typography-paragraph text-n-800">
                    {behaviour?.comment}
                  </p>
                  <p className="mt-[8px] typography-h100 text-n-600">
                    {getDateLocale(behaviour?.createdAt)}
                  </p>
                </div>
                <div className="divider-line my-[16px] ml-[40px] bg-n-300" />
              </div>
            );
          })}
          {nextOffset && (
            <div
              className="flex items-center mx-[24px] cursor-pointer"
              onClick={() => appendData()}
            >
              <SVGIcon
                iconName="icon-subdirectory_arrow_right"
                size={24}
                fillColor="var(--base-600)"
              />
              <p className="text-base-600 ml-[8px]">Show More Resources</p>
            </div>
          )}
          <div className="pb-[24px]"></div>
        </div>
      </div>
    </div>
  );
}

export default SidebarBehaviour;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import {
  getBehaviourReview,
  getPerformanceRatingSource
} from 'client/PerformanceReport';

import LoadingComponent from 'components/shared/LoadingComponent';

import BehaviourReviewComponent from '../BehaviourReviewComponent';
import ReviewSourceComponent from '../ReviewSourceComponent';

function BehaviourTab(props) {
  const [listRecognitionValues, setListRecognitionValues] = useState([]);
  const [listFeedbackValues, setListFeedbackValues] = useState([]);
  const [listRecognitionCompetencies, setListRecognitionCompetencies] =
    useState([]);
  const [listFeedbackCompetencies, setListFeedbackCompetencies] = useState([]);
  const [listReviewSource, setListReviewSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = props.userId;
  const types = [
    'recognitionValue',
    'feedbackValue',
    'recognitionCompetency',
    'feedbackCompetency'
  ];
  const getReview = async () => {
    const query = {
      targetId: userId,
      cycleState: 'done'
    };

    const { data } = await getBehaviourReview(query);
    if (data) {
      setListRecognitionValues(data.recognitionValues);
      setListFeedbackValues(data.feedbackValues);
      setListRecognitionCompetencies(data.recognitionCompetencies);
      setListFeedbackCompetencies(data.feedbackCompetencies);
    }
  };

  const getSource = async () => {
    setLoading(true);
    const query = {
      targetId: userId,
      cycleState: 'done'
    };

    const { data } = await getPerformanceRatingSource(query);
    if (data) {
      setListReviewSource(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getReview();
    getSource();
  }, []);

  return (
    <div className="behaviour-report-container">
      <div className="col-md-4">
        <BehaviourReviewComponent
          recognition={listRecognitionValues}
          feedback={listFeedbackValues}
          isHorizontal={false}
          type="values"
        />
        <BehaviourReviewComponent
          recognition={listRecognitionCompetencies}
          feedback={listFeedbackCompetencies}
          isHorizontal={false}
          type="competencies"
        />
      </div>
      <div className="col-md-8">
        {!loading ? (
          <>
            <h2>
              {listReviewSource.length > 0
                ? 'Sources'
                : 'No Behavior Report Available'}
            </h2>
            {types.map((type) =>
              listReviewSource.map((review, index) => {
                let value = null;
                if (review.answer[type]) {
                  value = type.endsWith('competency')
                    ? review.answer[type].object.name
                    : review.answer[type].object.title;
                }
                return (
                  value && (
                    <ReviewSourceComponent
                      key={index}
                      name={review.actor.name}
                      team={review.cycle.team}
                      profilePicture={review.actor.profilePicture}
                      value={value}
                      comment={review.answer[type].comment}
                      cycle={review.cycle.name}
                      type={type}
                    />
                  )
                );
              })
            )}
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  );
}

export default withRouter(BehaviourTab);

import React from 'react';

import { useLocale } from 'hooks/useLocale';
import { getNumberLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';
import ProgressBar from 'components/shared/progressBar/ProgressBarOverallProgress';
import PollOption from 'components/surveys/PollOption';

import PollFreeText from './PollFreeText';

const QuestionAnswers = ({
  currentIndex,
  surveyData,
  currentAnswer,
  isFieldDisabled,
  setCurrentAnswer
}) => {
  const currentQuestion = surveyData?.questions?.[currentIndex];
  const { setBothLocale } = useLocale();

  return (
    <div className="bg-n-000 w-[50%] min-h-[calc(100vh-64px)] overflow-y-auto">
      {/* Progress Bar */}
      <div className="flex pl-[40px] pr-[40px] mt-[40px] items-center w-full">
        <ProgressBar
          widthBackground={100}
          widthProgress={Math.round(
            ((currentIndex + 1) / surveyData?.questions?.length) * 100
          )}
          colorProgress="bg-base-600"
          containerClassName="w-[85%]"
        />
        <p
          className="ml-[8px] typography-h100 text-n-600 w-[120px] h-[16px]"
          data-cy="progress-desc"
        >
          {setBothLocale('Question')} {getNumberLocale(currentIndex + 1)}{' '}
          {setBothLocale('out of')}{' '}
          {getNumberLocale(surveyData?.questions?.length || 0)}
        </p>
      </div>
      {/* Question */}
      <div className="flex flex-col mx-[40px] min-h-[calc(100vh-240px)] h-[calc(100vh-240px)] overflow-auto mt-[24px]">
        <div className="m-auto w-full">
          <p className="text-n-900 typography-h600 mb-[24px]">
            {currentQuestion?.question}
          </p>
          {/* Poll */}
          {currentQuestion?.type === 'poll' &&
            currentQuestion?.pollOptions?.map((opt) => (
              <>
                {opt.customType ? (
                  <PollFreeText
                    key={opt.id}
                    opt={opt}
                    currentAnswer={currentAnswer}
                    onChange={(answer) => setCurrentAnswer(answer)}
                    onClick={(answer) => {
                      setCurrentAnswer(answer);
                    }}
                  />
                ) : (
                  <PollOption
                    key={opt.id}
                    currentAnswer={currentAnswer}
                    opt={opt}
                    disabled={isFieldDisabled}
                    onClick={() => setCurrentAnswer({ id: opt?.id })}
                  />
                )}
              </>
            ))}
          {/* Comment */}
          {currentQuestion?.type === 'essay' && (
            <InputField
              dataCy="answer-essay"
              inputClass="w-full border-[0px] border-b-[1px] typography-h600"
              placeholder="Type your answer here"
              value={currentAnswer.answer ? currentAnswer.answer : ''}
              handleChange={(e) => setCurrentAnswer({ answer: e.target.value })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionAnswers;

import React, { useEffect, useState } from 'react';

import { getRatingTemplates } from 'client/RatingTemplateClient';
import { getMechanism } from 'client/TrackTemplateClient.js';
import { getObjectiveLocale } from 'utils/HelperUtils';

import LoadingComponent from 'components/shared/LoadingComponent';
import RadioButton from 'components/shared/RadioButton';

import CommentSection from './CommentSection';
import CustomAttribute from './CustomAttribute/CustomAttribute';
import FormulaComponent from './FormulaComponent';
import RatingComponent from './RatingComponent';
import RatingOptionView from './RatingOptionView';

// list of mechanism that should show Rating Template Section
const listShowRating = [
  'each',
  'overall_rating',
  'specific_attribute',
  'attributes',
  'final_rating',
  'input_score',
  'overall_score'
];
const ratingTypeDict = {
  goals_scoring: 'goal_final',
  tasks_scoring: 'task_final',
  values_scoring: 'value_final',
  competencies_scoring: 'competency_final',
  review_aspects_scoring: 'review_aspect'
};

const ConfigureSection = ({
  setData,
  templateData,
  currentTrackType,
  setTemplateData,
  customAttributes
}) => {
  const [listRatingTemplate, setListRatingTemplate] = useState([]);
  const [mechanismData, setMechanismData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [defaultFormula, setDefaultFormula] = useState(null);
  const [defaultMechanism, setDefaultMechanism] = useState(null);
  const isGoalsScoringWithView =
    currentTrackType == 'goals_scoring' &&
    (templateData.config.mechanism == 'each' ||
      templateData.config.mechanism == 'overall_score');

  const getMechanismData = async (currentTrackType) => {
    const { data } = await getMechanism({ trackType: currentTrackType });
    if (data) {
      setMechanismData(data?.trackMechanism || []);
      setLoading(false);
    }
  };

  const setDefaultConfigByTrack = () => {
    const defaultConfig = { useComment: 'no' };
    if (
      currentTrackType == 'goals_scoring' ||
      currentTrackType == 'tasks_scoring'
    ) {
      defaultConfig.mechanism = 'suggested_ongoing';
      currentTrackType == 'goals_scoring' && (defaultConfig.view = 'rating');
    } else if (
      currentTrackType == 'values_scoring' ||
      currentTrackType == 'competencies_scoring'
    ) {
      defaultConfig.mechanism = 'each';
      defaultConfig.view = 'matrix';
    } else if (currentTrackType == 'review_aspects_scoring') {
      defaultConfig.view = 'rating';
      defaultConfig.mechanism = 'selected_aspect';
    } else if (
      currentTrackType == 'values_recognition_feedback' ||
      currentTrackType == 'competencies_recognition_feedback'
    ) {
      defaultConfig.mechanism = 'recognition_feedback';
    } else if (currentTrackType == 'reviewee_feedback') {
      defaultConfig.mechanism = 'reviewee_feedback';
    } else if (currentTrackType == 'summary') {
      defaultConfig.mechanism = 'all_scoring';
    }

    setDefaultMechanism(defaultConfig.mechanism);
    let newData = { ...templateData };
    newData.config = defaultConfig;
    setTemplateData(newData);
  };

  const getListRatingTemplate = async () => {
    const { data } = await getRatingTemplates({
      ratingType: ratingTypeDict[currentTrackType]
    });
    if (data) {
      setListRatingTemplate(data);
    }
  };

  const setMechanism = (value) => {
    let newData = { ...templateData };
    newData.config.mechanism = value;

    // delete formula from config, and then it will get it's default value from useEffect
    delete newData.config.formula;
    setTemplateData(newData);
  };

  const setCommentConfig = (value) => {
    let newData = { ...templateData };
    newData.config.useComment = value;
    setTemplateData(newData);
  };

  useEffect(() => {
    getMechanismData(currentTrackType);
    getListRatingTemplate();

    if (!templateData?.config?.mechanism) {
      setDefaultConfigByTrack();
    }
  }, []);

  useEffect(() => {
    const mechanism = templateData.config.mechanism;
    if (listShowRating.includes(mechanism)) {
      getListRatingTemplate();
    }

    // do not assign default formula if templateData already has a formula
    // for ex: when edit template, template would have an assigned formula from backend

    if (templateData.config.formula) return;

    let newData = { ...templateData };
    if (
      mechanism == 'each' ||
      mechanism == 'input_score' ||
      mechanism == 'suggested_ongoing' ||
      mechanism == 'suggested_ongoing_parent_only'
    ) {
      newData.config.formula = 'average';
      setDefaultFormula('average');
      setTemplateData(newData);
    } else if (mechanism == 'specific_attribute') {
      newData.config.formula = 'weighted_average';
      setDefaultFormula('weighted_average');
      setTemplateData(newData);
    }
  }, [templateData.config.mechanism]);

  return (
    <div className="create-track-template-configure" id="configuration">
      <p
        className="typography-h500 typography-primary text-n-900 mb-[4px]"
        id="configuration"
      >
        {getObjectiveLocale('Configure')}
      </p>
      <p className="typography-h400 typography-paragraph typography-secondary">
        {getObjectiveLocale(
          'What settings do you want to use in this track template?'
        )}
      </p>
      <div className="wrapper-select-setting">
        {loading ? (
          <LoadingComponent />
        ) : (
          mechanismData?.map((settingData, index) => (
            <div
              key={`mechanism-${index}`}
              className="cursor-pointer"
              onClick={(e) => {
                e?.stopPropagation();
                setData(settingData.value, 'config', 'mechanism');
              }}
            >
              <RadioButton
                size="small"
                checked={settingData.value == templateData.config.mechanism}
                onChange={(e) => {
                  e?.stopPropagation();
                  setData(settingData.value, 'config', 'mechanism');
                }}
              >
                <p
                  className="mb-[0px] ml-[8px] cursor-pointer typography-h400 typography-paragraph text-n-900"
                  data-cy={settingData.value}
                  onClick={() => setMechanism(settingData.value)}
                >
                  {getObjectiveLocale(settingData.description)}
                </p>
                {settingData.value == defaultMechanism && (
                  <span className="default-formula ml-[8px]">
                    {'(default)'}
                  </span>
                )}
              </RadioButton>
            </div>
          ))
        )}
        {currentTrackType == 'review_aspects_scoring' &&
          templateData?.config?.mechanism &&
          customAttributes && (
            <CustomAttribute
              customAttributes={customAttributes}
              templateData={templateData}
              setTemplateData={setTemplateData}
            />
          )}
      </div>
      {(currentTrackType == 'values_recognition_feedback' ||
        currentTrackType == 'competencies_recognition_feedback') && (
        <>
          <div className="wrapper-component mt-[20px]">
            <p className="typography-h500 typography-primary text-n-900 mb-[4px]">{`Minimum and maximum numbers of ${getObjectiveLocale(
              currentTrackType.includes('values') ? 'values' : 'competencies'
            ).toLowerCase()}`}</p>
            <p className="typography-h400 typography-paragraph typography-secondary">{`Set up minimum and maximum number of ${getObjectiveLocale(
              currentTrackType.includes('values') ? 'values' : 'competencies'
            ).toLowerCase()} to be recognised/feedback`}</p>
            <div className="wrapper-input inline-block mr-[24px]">
              <p className="typography-h100 typography-secondary mb-[8px]">
                Minimum{' '}
                <span className="typography-h100 typography-secondary text-n-600">
                  (255)
                </span>
              </p>
              <input
                className="track-template-input w-[124px]"
                type="number"
                value={templateData.config.minSelection}
                data-cy="min-selection"
                onChange={(e) =>
                  setData(
                    parseInt(e.currentTarget.value),
                    'config',
                    'minSelection'
                  )
                }
              />
            </div>
            <div className="wrapper-input inline-block">
              <p className="typography-h100 typography-secondary mb-[8px]">
                {getObjectiveLocale('Maximum')}{' '}
                <span className="typography-h100 typography-secondary text-n-600">
                  (255)
                </span>
              </p>
              <input
                className="track-template-input w-[124px]"
                type="number"
                value={templateData.config.maxSelection}
                data-cy="max-selection"
                onChange={(e) =>
                  setData(
                    parseInt(e.currentTarget.value),
                    'config',
                    'maxSelection'
                  )
                }
              />
            </div>
          </div>
        </>
      )}
      <RatingComponent
        listRatingTemplate={listRatingTemplate}
        templateData={templateData?.ratingTemplate}
        setTemplateData={(rating) =>
          setTemplateData({ ...templateData, ratingTemplateId: rating?.id })
        }
        mechanism={templateData?.config?.mechanism}
      />
      <FormulaComponent
        defaultFormula={defaultFormula}
        templateData={templateData}
        setData={(value, prefix, subPrefix, index, optionIndex) =>
          setData(value, prefix, subPrefix, index, optionIndex)
        }
      />
      {templateData?.config?.mechanism && (
        <CommentSection
          useComment={templateData.config.useComment}
          setUseComment={(value) => setCommentConfig(value)}
        />
      )}
      <hr className="border-separator mt-[40px]" />
      {(currentTrackType == 'values_scoring' ||
        currentTrackType == 'competencies_scoring' ||
        currentTrackType == 'review_aspects_scoring' ||
        isGoalsScoringWithView) && (
        <RatingOptionView
          currentTrackType={currentTrackType}
          templateData={templateData}
          setTemplateData={setTemplateData}
        />
      )}
    </div>
  );
};

export default ConfigureSection;

import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import isEqual from 'lodash/isEqual';
import { useImmer } from 'use-immer';

import {
  editObjective,
  getListObjectives,
  getObjectiveDetail
} from 'client/ObjectivesClient';
import { useProjectDetail } from 'context/ProjectDetailContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import useKeypress from 'hooks/useKeyPress';
import useTasks from 'hooks/useTasks';
import { useUrl } from 'hooks/useUrl';
import { getClusters, getIncludeTeamGoalsParams } from 'utils/ObjectivesHelper';

import ContextMenu from 'components/context-menu/ContextMenu';
import Table from 'components/design-system/table/Table';
import Checkbox from 'components/shared/Checkbox';
import Draggable from 'components/shared/DragAndDrop/Draggable';
import ToastNotif from 'components/shared/ToastNotif.js';

import Alignment from './task-column/Alignment';
import Assignee from './task-column/Assignee';
import Complexity from './task-column/Complexity';
import CreatedBy from './task-column/CreatedBy';
import Dependency from './task-column/Dependency';
import DueDate from './task-column/DueDate';
import LastComment from './task-column/LastComment';
import LastUpdated from './task-column/LastUpdated';
import Metrics from './task-column/Metrics';
import Phase from './task-column/Phase';
import Priority from './task-column/Priority';
import Progress from './task-column/Progress';
import TaskDialog from './task-column/TaskDialog';
import TaskName from './task-column/TaskName';

/*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
*/
const areEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

const Task = React.memo(
  ({
    taskNode,
    filter,
    level,
    isLastChild,
    groupData,
    sectionData,
    reloadTasks,
    draggedId,
    setDraggedId,
    index,
    isProject,
    cancelAddTask,
    listMetrics = []
  }) => {
    const {
      user,
      config: { includeTeamGoals }
    } = useUser();
    const { match } = useUrl();
    const history = useHistory();

    const [task, setTask] = useImmer(taskNode.value);
    const [showChild, setShowChild] = useState(false);
    const [childHeight, setChildHeight] = useState(0);
    const [isLoadChildren, setIsLoadChildren] = useState(false);

    const [objectiveChildren, setObjectiveChildren] = useState([]);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [mousePosition, setMousePosition] = useState({ xPos: 0, yPos: 0 });
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const [defaultParent, setDefaultParent] = useState(null);

    const {
      setAction,
      selectedTasks,
      startTask,
      updateStartTaskOneLevel,
      updateStartTaskTwoLevels,
      updateEndTaskOneLevel,
      updateEndTaskTwoLevels,
      updateSelectedTasks,
      removeSelected,
      removeAllSelected,
      removeTaskFromGroupOneLevel,
      removeTaskFromGroupTwoLevels,
      removeTaskChildrenFromParent,
      addTaskToGroupOneLevel,
      addTaskToGroupTwoLevels,
      addTaskAsChildren,
      dropTaskOneLevel,
      dropTaskTwoLevels,
      action
    } = useTasks();

    const { projectId } = useProjectDetail();

    const isSelected = selectedTasks.find((selTask) => selTask.id === task.id);

    const clusters = getClusters(task);

    const { refetchObjective, invalidateQueries } = useRefetchQuery();

    const isSubTask = level > 0;

    useKeypress('Escape', () => setShowContextMenu(false));

    const { id } = task || {};

    const fetchObjective = () => {
      return getObjectiveDetail(taskNode.value.id);
    };

    useQuery(['objective', taskNode.value.id], fetchObjective, {
      initialData: taskNode.value,
      staleTime: Infinity,
      onSuccess: (data) => {
        if (data.data) {
          taskNode.value = data.data;
          setTask(() => data.data);
        }
        if (showChild) {
          fetchObjectivesChildren();
        } else {
          taskNode.childs = [];
        }
      }
    });

    const fetchObjectivesChildren = async () => {
      const teamId = taskNode.value?.team?.id;
      const additionalParams = getIncludeTeamGoalsParams(
        includeTeamGoals,
        teamId,
        false
      );

      let allParams = {
        reviewsVisibility: 1,
        parentId: id,
        ...additionalParams
      };

      const { data } = await getListObjectives(allParams);

      if (data) {
        taskNode.childs = [];
        data.forEach((d) => {
          return taskNode.addChild(d);
        });
      }
    };

    const showChildren = async () => {
      setIsLoadChildren(true);
      !showChild &&
        objectiveChildren?.length === 0 &&
        (await fetchObjectivesChildren());
      setIsLoadChildren(false);
      setShowChild(!showChild);
    };

    const handleContextMenu = (e) => {
      const { pageX, pageY } = e;
      e.preventDefault();
      setShowContextMenu(true);
      setMousePosition({ xPos: pageX, yPos: pageY });
    };

    const handleChangeMultipleDeleteTask = (e, isFromCheckbox = false) => {
      if (e.shiftKey) {
        if (startTask == null) {
          if (isProject) {
            updateStartTaskTwoLevels(
              sectionData?.index,
              groupData?.index,
              task.id
            );
          } else {
            updateStartTaskOneLevel(groupData?.index, task.id);
          }
        } else {
          if (isProject) {
            updateEndTaskTwoLevels(
              sectionData?.index,
              groupData?.index,
              task.id
            );
          } else {
            updateEndTaskOneLevel(groupData?.index, task.id);
          }
        }
      } else if (e.ctrlKey || e.metaKey || isFromCheckbox) {
        if (startTask === null) {
          if (isProject) {
            updateStartTaskTwoLevels(
              sectionData?.index,
              groupData?.index,
              task.id
            );
            updateSelectedTasks({
              ...task,
              groupName: groupData?.name,
              sectionName: sectionData?.name
            });
          } else {
            updateStartTaskOneLevel(groupData?.index, task.id);
            updateSelectedTasks({ ...task, groupName: groupData?.name });
          }
        } else if (
          selectedTasks.length === 1 &&
          selectedTasks?.find((selTask) => selTask.id === task.id)
        ) {
          removeAllSelected();
        } else if (selectedTasks?.find((selTask) => selTask.id === task.id)) {
          removeSelected(task.id);
        } else {
          updateSelectedTasks({ ...task, groupName: groupData?.name });
        }
      }
    };

    const handleAddChildren = async () => {
      match && history.replace(isProject ? `/projects/${projectId}` : '/tasks'); // close sidebar detail

      task.childrenCount > 0 &&
        taskNode.getChilds().length === 0 &&
        (await fetchObjectivesChildren());

      setAction('create-sub-goal');
      const currentChilds = [...taskNode.childs];
      const child = {
        name: '',
        id: -1,
        startDate: taskNode.value.startDate,
        dueDate: taskNode.value.dueDate
      };

      taskNode.childs = [];
      taskNode.addChild(child);
      taskNode.childs = [...taskNode.childs, ...currentChilds];

      setTask((draft) => {
        draft.childrenCount = draft.childrenCount + 1;
      });
      setShowChild(true);
    };

    const cancelAddChildren = () => {
      let newChilds = [...objectiveChildren];
      newChilds.shift();

      taskNode.childs = newChilds;
      setTask((draft) => {
        draft.childrenCount = draft.childrenCount - 1;
      });
      setObjectiveChildren(newChilds);
    };

    const toastFunction = (message) => {
      setShowToast(true);
      setToastMessage(message);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    };

    const invalidateDragAndDropData = (srcData, dstData) => {
      const srcKey = isProject
        ? [`objectives`, 'mytasks', srcData?.group1, srcData?.group2]
        : [`objectives`, 'mytasks', srcData?.group];

      const dstKey = isProject
        ? [`objectives`, 'mytasks', dstData?.group1, dstData?.group2]
        : [`objectives`, 'mytasks', dstData?.group];

      invalidateQueries(srcKey, {
        refetchActive: false
      });
      invalidateQueries(dstKey, {
        refetchActive: false
      });
    };

    const drop = async (ev, sourceId, isSub, sourceElement) => {
      const srcData = {
        index: draggedId?.index,
        taskNode: draggedId?.taskNode,
        task: draggedId?.task,
        level: draggedId?.level,
        ['group' + (isProject ? '2' : '')]: draggedId?.groupName, // group is used in dropTaskOneLevel
        ...(isProject && { group1: draggedId?.sectionName }) // group1 and group2 are used in dropTaskTwoLevels
      };

      const dstData = {
        index: index,
        taskNode: taskNode,
        ['group' + (isProject ? '2' : '')]: groupData?.name, // group is used in dropTaskOneLevel
        ...(isProject && { group1: sectionData?.name }) // group1 and group2 are used in dropTaskTwoLevels
      };

      const parentId = ev?.currentTarget?.id;
      const currentGroupId = parseInt(ev.currentTarget.getAttribute('groupId'));
      const currentGroupName = ev.currentTarget
        .getAttribute('groupName')
        .toUpperCase();
      const currentSectionId = parseInt(
        ev.currentTarget.getAttribute('sectionId')
      );
      const sourceLevel =
        sourceElement && parseInt(sourceElement.getAttribute('level'));

      if (sourceLevel > 0 && !isSub) {
        let params = {
          parentId: isProject
            ? projectId
            : filter?.showTask === 'project'
            ? srcData.taskNode.getParent().value.parent.id
            : null
        };

        if (isProject) {
          params.sectionId = currentSectionId;
        }
        if (filter?.group === 'phase') {
          filter?.showTask === 'project'
            ? (params.phaseName = currentGroupName)
            : (params.phaseId = currentGroupId);
        }
        if (filter?.group === 'priority') {
          params.priorityId = currentGroupId;
        }
        if (filter?.group === 'section') {
          params.sectionName = currentGroupName;
        }

        const { isSuccess } = await editObjective(sourceId, params);
        if (isSuccess) {
          removeTaskChildrenFromParent(
            srcData?.taskNode?.getParent(),
            srcData?.task?.id
          );
          refetchObjective(srcData?.taskNode?.getParent().value.id); // need to refetch parent to trigger useEffect and setObjectivesChildren
          refetchObjective(srcData?.task?.id);
          if (isProject) {
            addTaskToGroupTwoLevels(
              dstData?.group1,
              dstData?.group2,
              dstData?.index,
              srcData?.task
            );
          } else {
            addTaskToGroupOneLevel(
              dstData?.group,
              dstData?.index,
              srcData?.task
            );
          }
          invalidateDragAndDropData(srcData, dstData);
        }
        return;
      }

      if (isSub) {
        // Drag to parent
        if (srcData?.task?.childrenCount > 0) {
          toastFunction('Task cannot have more than 2 levels of depth');
          return;
        }

        let params = { parentId: parentId };

        const { isSuccess } = await editObjective(sourceId, params);
        if (isSuccess) {
          if (srcData?.level > 0) {
            removeTaskChildrenFromParent(
              srcData?.taskNode?.getParent(),
              srcData?.task?.id
            );
            refetchObjective(srcData?.taskNode?.getParent().value.id); // need to refetch parent to trigger useEffect and setObjectivesChildren
          } else {
            if (isProject) {
              removeTaskFromGroupTwoLevels(
                srcData?.group1,
                srcData?.group2,
                srcData?.task?.id
              );
            } else {
              removeTaskFromGroupOneLevel(srcData?.group, srcData?.task?.id);
            }
          }
          addTaskAsChildren(dstData?.taskNode, srcData?.task);

          !showChild && (await showChildren());
          refetchObjective(srcData?.task?.id);
          setTask((draft) => {
            draft.childrenCount = draft.childrenCount + 1;
          });
          invalidateDragAndDropData(srcData, dstData);
        }
        return;
      }

      if (filter?.group === 'phase' || filter?.group === 'priority') {
        if (
          (isProject &&
            srcData?.group1 === dstData?.group1 &&
            srcData?.group2 === dstData?.group2) ||
          (!isProject && srcData?.group === dstData?.group)
        ) {
          return;
        }

        let body = {
          ...(isProject && { sectionId: currentSectionId }),
          ...(filter?.showTask === 'project' && filter?.group !== 'priority'
            ? {
                [filter?.group + 'Name']: currentGroupName
              }
            : { [filter?.group + 'Id']: currentGroupId })
        };

        const { isSuccess } = await editObjective(sourceId, body);
        if (isSuccess) {
          if (isProject) {
            dropTaskTwoLevels(srcData, dstData);
          } else {
            dropTaskOneLevel(srcData, dstData);
          }
          refetchObjective(sourceId);
          invalidateDragAndDropData(srcData, dstData);
        }
      }
    };

    const getDefaultParent = async () => {
      const query = {
        limit: 1,
        assigneeId: user?.id,
        ...(filter?.group === 'phase' && {
          projectPhaseName: groupData?.name.toUpperCase()
        }),
        ...(filter?.group === 'section' && {
          projectSectionName: groupData?.name.toUpperCase()
        }),
        ...(filter?.group === 'priority' && {
          isProject: true
        })
      };
      const { data } = await getListObjectives(query);
      if (data) {
        setDefaultParent(data[0]);
      }
    };

    const setParentIds = () => {
      let tempTask = task;
      tempTask.parentId = task?.parent?.id ? [task?.parent?.id] : [];
      tempTask.parentIds = task?.parents?.map((parent) => parent?.id) || [];
      setTask(() => tempTask);
    };

    useEffect(() => {
      if (!isProject && filter?.showTask === 'project' && action === 'create') {
        getDefaultParent();
      }
    }, []);

    useEffect(() => {
      if (taskNode.childs.length === 0) {
        setObjectiveChildren([]);
      } else {
        setObjectiveChildren(taskNode.childs);
      }
    }, [taskNode.childs.length]);

    useEffect(() => {
      taskNode.value = task;
    }, [task]);

    useEffect(() => {
      setParentIds();
    }, [task?.parents]);

    return (
      <div className="relative bg-n-000">
        <ToastNotif showNotif={showToast} message={toastMessage} warning />
        <Draggable
          level={level}
          id={task?.id}
          draggedId={draggedId}
          setDraggedId={setDraggedId}
          drop={drop}
          task={task}
          index={index}
          groupData={groupData}
          sectionData={sectionData}
          taskNode={taskNode}
          isDraggable={task?.id != -1}
        >
          <Table.Row
            onContextMenu={handleContextMenu}
            onClick={handleChangeMultipleDeleteTask}
            customClass={`${
              isSelected ? 'bg-base-3008' : 'bg-n-000'
            } mt-[-1px] task-list-row`}
            useRowHover={false}
            customStickyContainerCN="z-10"
            dataCy={`task-row-${groupData?.index}-${index}`}
          >
            <Table.Column
              customClass="bg-n-100 !h-[48px] flex items-center"
              noPadding
            >
              <Checkbox
                name={`checkbox-multiple-delete-task-${task.id}`}
                id={`checkbox-multiple-delete-task-${task.id}`}
                size="small"
                customContainerClass={`checkbox-multiple-delete-tasks ml-[4px] ${
                  isSelected ? '!opacity-100' : ''
                }`}
                checked={isSelected}
                onClick={(e) => handleChangeMultipleDeleteTask(e, true)}
              />
            </Table.Column>
            <Table.Column
              customClass={`relative pr-[16px] group hover:bg-base-3008 cursor-pointer rounded-l-[4px] border border-solid border-n-300 !h-[48px] ${
                level > 0 ? 'pl-[38px]' : 'pl-[8px]'
              }`}
              isClickable={task?.id != -1}
              dataCy="task-name-column"
            >
              <TaskName
                listMetrics={listMetrics}
                task={task}
                filter={filter}
                setTask={setTask}
                level={level}
                isLastChild={isLastChild}
                showChild={showChild}
                showChildren={showChildren}
                isLoadChildren={isLoadChildren}
                groupData={groupData}
                sectionData={sectionData}
                taskNode={taskNode}
                isProject={isProject}
                cancelAddTask={cancelAddTask}
                defaultParent={defaultParent}
              />
            </Table.Column>
            <Table.Column
              customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]"
              noPadding
              isClickable={task?.id != -1}
            >
              <Assignee task={task} setTask={setTask} />
            </Table.Column>
            <Table.Column
              customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]"
              noPadding
              isClickable={task?.id != -1}
            >
              <DueDate task={task} setTask={setTask} />
            </Table.Column>
            <Table.Column
              customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]"
              noPadding
              isClickable={task?.id != -1}
            >
              <Priority
                task={task}
                setTask={setTask}
                isProject={isProject}
                isSubTask={isSubTask}
              />
            </Table.Column>
            <Table.Column
              customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]"
              noPadding
              isClickable={task?.id != -1}
            >
              <Phase
                task={task}
                setTask={setTask}
                filter={filter}
                isProject={isProject}
                isSubTask={isSubTask}
              />
            </Table.Column>
            <Table.Column
              customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]"
              noPadding
              isClickable={task?.id != -1}
            >
              <Progress task={task} clusters={clusters} />
            </Table.Column>
            <Table.Column
              customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]"
              noPadding
              isClickable={task?.id != -1}
            >
              <Metrics task={task} setTask={setTask} clusters={clusters} />
            </Table.Column>
            <Table.Column
              customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]"
              noPadding
              isClickable={task?.id != -1}
            >
              <Complexity task={task} setTask={setTask} />
            </Table.Column>
            <Table.Column
              customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]"
              noPadding
              isClickable={task?.id != -1}
            >
              <Alignment
                task={task}
                setTask={setTask}
                isSubTask={isSubTask}
                defaultParentName={defaultParent?.name}
                groupData={groupData}
                filter={filter}
              />
            </Table.Column>
            <Table.Column
              customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]"
              noPadding
              isClickable={task?.id != -1}
            >
              <Dependency task={task} setTask={setTask} />
            </Table.Column>
            <Table.Column customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]">
              <LastComment task={task} />
            </Table.Column>
            <Table.Column customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]">
              <CreatedBy task={task} />
            </Table.Column>
            <Table.Column customClass="hover:bg-base-3008 cursor-pointer border border-solid border-n-300 !h-[48px]">
              <LastUpdated task={task} />
            </Table.Column>
            <Table.Column
              customClass={`hover:bg-base-3008 cursor-pointer rounded-r-[4px] border border-solid border-n-300 !h-[48px] relative ${
                isDialogOpen ? 'bg-base-3008' : ''
              }`}
              noPadding
              isClickable={task?.id != -1}
            >
              <TaskDialog
                task={task}
                level={level}
                useTrigger
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                handleAddChildren={handleAddChildren}
                useFloating
                taskNode={taskNode}
                sectionName={sectionData?.name}
                groupName={groupData?.name}
              />
            </Table.Column>
          </Table.Row>

          {showContextMenu && (
            <ContextMenu
              mousePosition={mousePosition}
              menuId="context-menu-dropdown"
            >
              <TaskDialog
                task={task}
                level={level}
                useTrigger={false}
                isDialogOpen={showContextMenu}
                setIsDialogOpen={setShowContextMenu}
                reloadTasks={reloadTasks}
                handleAddChildren={handleAddChildren}
                useFloating={false}
                taskNode={taskNode}
                sectionName={sectionData?.name}
                groupName={groupData?.name}
              />
            </ContextMenu>
          )}

          {/* Task Children */}
          <CSSTransition
            duration={300}
            classNames="children"
            in={showChild && objectiveChildren?.length > 0}
            onEnter={() => setChildHeight(0)}
            onEntered={() => setChildHeight('auto')}
            onExit={() => setChildHeight(0)}
            onExited={() => setChildHeight(0)}
          >
            <AnimateHeight
              duration={500}
              height={childHeight}
              className="overflow-unset"
            >
              {objectiveChildren.map((child, index) => {
                return (
                  <Task
                    key={`task-list-${
                      child?.value?.id ? child?.value?.id : index
                    }`}
                    taskNode={child}
                    level={level + 1}
                    isLastChild={objectiveChildren.length - 1 === index}
                    draggedId={draggedId}
                    setDraggedId={setDraggedId}
                    parentId={task?.id}
                    groupData={groupData}
                    cancelAddTask={cancelAddChildren}
                    isProject={isProject}
                    listMetrics={listMetrics}
                    sectionData={sectionData}
                    filter={filter}
                  />
                );
              })}
            </AnimateHeight>
          </CSSTransition>

          <div
            className="invisible absolute bottom-[0px] border-solid border-b-[2px] border-0 border-base-600 z-[100] w-full flex"
            id={`border-drag-${id}`}
          >
            <div className="sticky left-0 bottom-0">
              <div className="relative">
                <div className="absolute flex bottom-[-2px]">
                  <div
                    id={`border-padding-${id}`}
                    className="border-solid border-b-[2px] border-0 border-n-300"
                  />
                  <div className="w-[5px] h-[0px] border-solid border-0 border-b-[5px] border-b-base-600 border-r-[5px] border-r-transparent" />
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </div>
    );
  },
  areEqual
);

Task.displayName = 'Task';

export default Task;

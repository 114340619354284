import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { getDetailPosition } from 'client/admin/UserManagementClient';
import { useUser } from 'context/UserContext';
import useUserManagement from 'hooks/useUserManagement';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

import GroupSection from './GroupSection';
import PositionBackground from './PositionBackground';
import PositionSection from './PositionSection';
import TimeField from './TimeField';

const PositionCard = ({
  position,
  index,
  isNewPosition,
  userId,
  isReadOnly
}) => {
  const { config } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabledCard, setIsDisabledCard] = useState(false);
  const [originDetailPosition, setOriginDetailPosition] = useState(null);
  const [detailPosition, setDetailPosition] = useState(cloneDeep(position));
  const {
    editedPosition,
    isCancelChanges,
    setIsError,
    setButtonSave,
    setEditedPosition,
    setPreviousBehaviorModelGroup,
    setNewBehaviorModelGroup
  } = useUserManagement();

  const changePositionData = (e, selector) => {
    if (selector === 'name' && e.target.value.length > 0) setIsError(false);
    setDetailPosition({ ...detailPosition, [selector]: e.target.value });
    setEditedPosition({ ...detailPosition, [selector]: e.target.value });
  };

  const changePositionField = (
    fieldData,
    selector,
    parentSelector,
    type = 'text'
  ) => {
    if (parentSelector) {
      const detail = cloneDeep(detailPosition);
      detail[parentSelector][selector] =
        type === 'text' ? fieldData.target.value : fieldData;
      setDetailPosition(detail);
      setEditedPosition(detail);
    } else {
      setDetailPosition({ ...detailPosition, [selector]: fieldData });
      setEditedPosition({ ...detailPosition, [selector]: fieldData });
    }
  };

  const changePositionDate = (date, selector, parentSelector) => {
    if (selector === 'startsAt' && date) setIsError(false);
    if (parentSelector) {
      const detail = cloneDeep(detailPosition);
      detail[parentSelector][selector] = date;
      setDetailPosition(detail);
      setEditedPosition(detail);
    } else {
      const start = date?.[0]
        ? date[0]?.startOf('day')?.toISOString()
        : date?.[0];
      const end = date?.[1] ? date[1]?.endOf('day')?.toISOString() : date?.[1];
      setDetailPosition({
        ...detailPosition,
        [selector[0]]: start,
        [selector[1]]: end
      });
      setEditedPosition({
        ...detailPosition,
        [selector[0]]: start,
        [selector[1]]: end
      });
    }
  };

  const handleChangeCard = async () => {
    if (
      position?.id !== 'new' &&
      isEqual(position, detailPosition) &&
      !isOpen
    ) {
      const { data } = await getDetailPosition(userId, position?.id);
      if (data) {
        setDetailPosition(data);
        setOriginDetailPosition(data);
      }
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isCancelChanges?.id === detailPosition?.id) {
      setDetailPosition(position);
      setEditedPosition(null);
      setIsOpen(false);
    }
  }, [isCancelChanges]);

  useEffect(() => {
    if (
      originDetailPosition &&
      !isEqual(originDetailPosition, detailPosition) &&
      !isEqual(detailPosition, position)
    ) {
      setButtonSave(true);
    } else {
      !isNewPosition && setButtonSave(false);
    }
    if (detailPosition?.id === 'new') setIsOpen(true);
  }, [detailPosition]);

  useEffect(() => {
    if (editedPosition?.id) {
      editedPosition?.id === position?.id
        ? setIsDisabledCard(false)
        : setIsDisabledCard(true);
    } else {
      setIsDisabledCard(false);
    }
  }, [editedPosition]);

  return (
    <div
      className={`w-[768px] border-solid border border-n-400 rounded-[4px] mb-[24px] ${
        isDisabledCard ? 'bg-n-300' : ''
      }`}
      id={`position-${index}`}
      data-cy={`position-card-${index}`}
    >
      {/* HEADER CARD */}
      <div
        className="mx-[16px] my-[12px] flex justify-between items-center"
        onClick={() => !isDisabledCard && handleChangeCard()}
      >
        <div>
          <div
            className={`typography-h500 ${isDisabledCard ? 'text-n-600' : ''}`}
          >
            {detailPosition.name}
          </div>
          <div className="typography-paragraph">
            <span
              className={`capitalize ${
                detailPosition.state === 'inactive'
                  ? 'text-n-800'
                  : 'text-base-600'
              }`}
            >
              {detailPosition.state} ·{' '}
            </span>
            <span className={`${isDisabledCard ? 'text-n-600' : 'text-n-800'}`}>
              {detailPosition?.startsAt
                ? getDateLocale(detailPosition?.startsAt)
                : 'Now'}{' '}
              -{' '}
              {detailPosition?.endsAt
                ? getDateLocale(detailPosition?.endsAt)
                : 'Now'}
            </span>
          </div>
        </div>
        <SVGIcon
          iconName={
            isOpen ? 'icon-keyboard_arrow_down' : 'icon-keyboard_arrow_right'
          }
          fillColor="var(--n-600)"
          size="24"
          onClick={() => !isDisabledCard && handleChangeCard()}
          dataCy={`arrow-card-${index}`}
        />
      </div>
      {isOpen && (
        <>
          {/* BODY CARD */}
          <div className="h-[1px] mb-[16px] bg-n-400" />
          <div className="m-16">
            {/* SET ACTIVE POSITION */}
            {config?.editablePlacementFields?.state && (
              <div className="border-solid border border-n-400 rounded-[4px] p-16 flex justify-between items-center">
                <div>
                  <div className="typography-h400">
                    {getObjectiveLocale('Set this position as active')}
                  </div>
                  <div className="typography-paragraph text-n-800">
                    {getObjectiveLocale(
                      'If this configuration is being enabled, this position will be treat as active within certain period.'
                    )}
                  </div>
                </div>
                <SmallToggleSwitchPurple
                  idStr="position"
                  isChecked={detailPosition?.state === 'active'}
                  onChange={(e) =>
                    changePositionField(
                      detailPosition?.state === 'active'
                        ? 'inactive'
                        : 'active',
                      'state'
                    )
                  }
                  disabled={
                    isReadOnly ||
                    config?.editablePlacementFields?.state === 'disabled'
                  }
                />
              </div>
            )}
            {/* POSITION DETAILS */}
            <PositionSection
              changePositionField={changePositionField}
              detailPosition={detailPosition}
              setDetailPosition={setDetailPosition}
              changePositionData={changePositionData}
              isReadOnly={isReadOnly}
            />
            {/* POSITION BACKGROUND */}
            <PositionBackground
              detailPosition={detailPosition?.info}
              changePositionField={changePositionField}
              changePositionDate={changePositionDate}
              onGetNewGroupName={(newGroup) => {
                setPreviousBehaviorModelGroup(
                  detailPosition?.groups?.behaviorGroup[0]
                );
                setNewBehaviorModelGroup(newGroup);
              }}
              isReadOnly={isReadOnly}
            />

            {/* REGION */}
            <div className="border-solid border border-n-400 rounded-[4px] p-16 pb-[24px] flex flex-col mt-[16px]">
              <div className="typography-h400">
                {getObjectiveLocale('Region')}
              </div>
              <div className="typography-paragraph text-n-800">
                {getObjectiveLocale(
                  'Showing region informations of this position.'
                )}
              </div>
              <div className="flex">
                {config?.editablePlacementInfo?.region && (
                  <InputField
                    label="Region"
                    placeholder="Region"
                    handleChange={(e) =>
                      changePositionField(e, 'region', 'info')
                    }
                    value={detailPosition?.info?.region}
                    dataCy="user-info-region"
                    className="w-[344px] mr-[16px] mt-[24px]"
                    readonly={
                      isReadOnly ||
                      config?.editablePlacementInfo?.region === 'disabled'
                    }
                  />
                )}
                {config?.editablePlacementInfo?.city && (
                  <InputField
                    label="City"
                    placeholder="City"
                    handleChange={(e) => changePositionField(e, 'city', 'info')}
                    value={detailPosition?.info?.city}
                    dataCy="user-pos-job-city"
                    className="w-[344px] mt-[24px]"
                    readonly={
                      isReadOnly ||
                      config?.editablePlacementInfo?.city === 'disabled'
                    }
                  />
                )}
              </div>
              <div className="flex">
                {config?.editablePlacementInfo?.country && (
                  <InputField
                    label="Country"
                    placeholder="Country"
                    handleChange={(e) =>
                      changePositionField(e, 'country', 'info')
                    }
                    value={detailPosition?.info?.country}
                    dataCy="user-pos-country"
                    className="w-[344px] mr-[16px] mt-[24px]"
                    readonly={
                      isReadOnly ||
                      config?.editablePlacementInfo?.country === 'disabled'
                    }
                  />
                )}
              </div>
            </div>
            {/* GOAL PERIOD */}
            {config?.editablePlacementGoalPeriod && (
              <div className="border-solid border border-n-400 rounded-[4px] p-16 pb-[24px] flex flex-col mt-[16px]">
                <div>
                  <div className="typography-h400">
                    {getObjectiveLocale('Goal Period')}
                  </div>
                  <div className="typography-paragraph text-n-800">
                    {getObjectiveLocale(
                      'These settings will be reset to default based on Objectives setting.'
                    )}
                  </div>
                </div>
                <div className="flex">
                  {config?.editablePlacementGoalPeriod?.creation && (
                    <TimeField
                      selectedDate={{
                        start: detailPosition?.goalCreationStartsAt,
                        end: detailPosition?.goalCreationEndsAt
                      }}
                      onSelectDate={(date) =>
                        changePositionDate(date, [
                          'goalCreationStartsAt',
                          'goalCreationEndsAt'
                        ])
                      }
                      label={getObjectiveLocale('Goal Creation Period')}
                      customClass="w-[344px] mr-[16px]"
                      isSingle={false}
                      readonly={
                        isReadOnly ||
                        config?.editablePlacementGoalPeriod?.creation ===
                          'disabled'
                      }
                    />
                  )}
                  {config?.editablePlacementGoalPeriod?.edit && (
                    <TimeField
                      selectedDate={{
                        start: detailPosition?.goalEditStartsAt,
                        end: detailPosition?.goalEditEndsAt
                      }}
                      onSelectDate={(date) =>
                        changePositionDate(date, [
                          'goalEditStartsAt',
                          'goalEditEndsAt'
                        ])
                      }
                      label={getObjectiveLocale('Goal Editing Period')}
                      customClass="w-[344px]"
                      isSingle={false}
                      readonly={
                        isReadOnly ||
                        config?.editablePlacementGoalPeriod?.edit === 'disabled'
                      }
                    />
                  )}
                </div>
                {config?.editablePlacementGoalPeriod?.delete && (
                  <TimeField
                    selectedDate={{
                      start: detailPosition?.goalDeleteStartsAt,
                      end: detailPosition?.goalDeleteEndsAt
                    }}
                    onSelectDate={(date) =>
                      changePositionDate(date, [
                        'goalDeleteStartsAt',
                        'goalDeleteEndsAt'
                      ])
                    }
                    label={getObjectiveLocale('Goal Deletion Period')}
                    customClass="w-[344px]"
                    isSingle={false}
                    readonly={
                      isReadOnly ||
                      config?.editablePlacementGoalPeriod?.delete === 'disabled'
                    }
                  />
                )}
              </div>
            )}
            {/* TASK PERIOD */}
            {config?.editablePlacementTaskPeriod && (
              <div className="border-solid border border-n-400 rounded-[4px] p-16 pb-[24px] flex flex-col mt-[16px]">
                <div>
                  <div className="typography-h400">
                    {getObjectiveLocale('Task Period')}
                  </div>
                  <div className="typography-paragraph text-n-800">
                    {getObjectiveLocale(
                      'These settings will be reset to default based on Objectives setting.'
                    )}
                  </div>
                </div>
                <div className="flex">
                  {config?.editablePlacementTaskPeriod?.creation && (
                    <TimeField
                      selectedDate={{
                        start: detailPosition?.taskCreationStartsAt,
                        end: detailPosition?.taskCreationEndsAt
                      }}
                      onSelectDate={(date) =>
                        changePositionDate(date, [
                          'taskCreationStartsAt',
                          'taskCreationEndsAt'
                        ])
                      }
                      label={getObjectiveLocale('Task Creation Period')}
                      customClass="w-[344px] mr-[16px]"
                      isSingle={false}
                      readonly={
                        isReadOnly ||
                        config?.editablePlacementTaskPeriod?.creation ===
                          'disabled'
                      }
                    />
                  )}
                  {config?.editablePlacementTaskPeriod?.edit && (
                    <TimeField
                      selectedDate={{
                        start: detailPosition?.taskEditStartsAt,
                        end: detailPosition?.taskEditEndsAt
                      }}
                      onSelectDate={(date) =>
                        changePositionDate(date, [
                          'taskEditStartsAt',
                          'taskEditEndsAt'
                        ])
                      }
                      label={getObjectiveLocale('Task Editing Period')}
                      customClass="w-[344px]"
                      isSingle={false}
                      readonly={
                        isReadOnly ||
                        config?.editablePlacementTaskPeriod?.edit === 'disabled'
                      }
                    />
                  )}
                </div>
                {config?.editablePlacementTaskPeriod?.delete && (
                  <TimeField
                    selectedDate={{
                      start: detailPosition?.taskDeleteStartsAt,
                      end: detailPosition?.taskDeleteEndsAt
                    }}
                    onSelectDate={(date) =>
                      changePositionDate(date, [
                        'taskDeleteStartsAt',
                        'taskDeleteEndsAt'
                      ])
                    }
                    label={getObjectiveLocale('Task Deletion Period')}
                    customClass="w-[344px]"
                    isSingle={false}
                    readonly={
                      isReadOnly ||
                      config?.editablePlacementTaskPeriod?.delete === 'disabled'
                    }
                  />
                )}
              </div>
            )}
            {/* GROUPS */}
            <GroupSection
              isReadOnly={isReadOnly}
              changePositionField={changePositionField}
              detailPosition={detailPosition}
              setDetailPosition={setDetailPosition}
            />
            {/* ENABLE FORMAL REVIEW */}
            {config?.editablePlacementConfigs?.formalReview && (
              <div className="border-solid border border-n-400 rounded-[4px] p-16 flex justify-between items-center mt-[16px]">
                <div>
                  <div className="typography-h400">
                    {getObjectiveLocale('Enable Formal Review')}
                  </div>
                  <div className="typography-paragraph text-n-800">
                    {getObjectiveLocale(
                      'If this configuration is being enabled, formal review for this position will be activated.'
                    )}
                  </div>
                </div>
                <SmallToggleSwitchPurple
                  idStr="formal-review"
                  isChecked={detailPosition?.configs?.formalReview}
                  onChange={(e) =>
                    changePositionField(
                      { formalReview: !detailPosition?.configs?.formalReview },
                      'configs'
                    )
                  }
                  disabled={
                    isReadOnly ||
                    config?.editablePlacementConfigs?.formalReview ===
                      'disabled'
                  }
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default PositionCard;

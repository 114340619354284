import React, { useEffect, useRef, useState } from 'react';

import { getBehaviours } from 'client/FormalReviewClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import {
  convertStringToFloat,
  getObjectiveLocale,
  getUniqueArrayOfObjects
} from 'utils/HelperUtils';

import InputScoreTrack from 'components/formal-review/InputScoreTrack';
import RatingSlider from 'components/formal-review/RatingSlider';
import ScoreBox from 'components/formal-review/ScoreBox';
import SuggestedScore from 'components/formal-review/custom-attributes/SuggestedScore';
import Objectives from 'components/objectives/Objectives';
import SVGIcon from 'components/shared/SVGIcon';
import TextAreaWithMention from 'components/shared/TextAreaWithMention/TextAreaWithMention';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import PrefillSuggestion from 'src/components/formal-review/annual-review/PrefillSuggestion';

import ModalRatingInfo from './ModalRatingInfo';
import './ObjectScoring.scss';

// Tracks that use this component: values_scoring, competencies_scoring, goals_scoring, tasks_scoring, score_attributes
const ObjectScoring = ({
  showOption = 'score_and_label',
  showTooltip = true,
  object,
  setOverlaySidebar = () => {
    /**/
  },
  id,
  title,
  description,
  weight,
  currentTrack,
  currentTrackId,
  components,
  answers,
  level,
  idx,
  objective,
  useComment = 'optional',
  minimumScore = null, // GoalsScoringInputScore.js
  maximumScore = null, // GoalsScoringInputScore.js
  mechanism,
  getSingleGoalsScoring,
  getSingleTasksScoring,
  view,
  withLabelInfo = true,
  sliderLabel,
  disabled,
  template,
  withTitle = true,
  suggestions,
  phaseType,
  suggestedScoreData,
  objectiveCount = 0,
  suggestionSource,
  allowOverride = true
}) => {
  const isFirstRender = useRef(true);
  const {
    config: { permissions, formalReviewMentionInCommentEnabled }
  } = useUser();
  const { addToast } = useToastContext();
  const [
    { sliderLoading, isPrefillCompleted },
    { setAnswerContext, setAspectAnswerContext, updateAnswerContext }
  ] = useFormalReview();
  let [{ targetPlacementId }, { setOverlayRightSidebarData }] =
    useFormalReview();
  const { options } = components || {};
  const [activeScore, setActiveScore] = useState(null);
  const [modalInfo, showModalInfo] = useState(false);
  const [inputScoreValue, setInputScoreValue] = useState('');
  const [localAnswers, setLocalAnswers] = useState(answers);

  const answer = localAnswers?.filter((val) => val.objectId == id);
  const suggestionData = suggestions?.find((val) => val.objectId == id);
  const [comment, setComment] = useState(
    (answer?.length > 0 && answer?.[0]?.comment) || ''
  );

  // HR DEVELOPMENT | only for input_score
  let isObjectiveDisabled = false;
  if (mechanism === 'input_score' || view === 'raw') {
    if (objective?.[0].externalId?.split('-')?.[1] === 'HR_DEVELOPMENT') {
      isObjectiveDisabled = true;
    }
  }
  const checkOverride =
    currentTrack == 'review_aspects_scoring' &&
    !template?.reviewAspects?.[0].allowOverride
      ? true
      : false;

  const isTextAreaError =
    useComment == 'required' && answer[0]?.optionId && !answer[0]?.comment;

  const setAnswer = async (
    objectId = null,
    optionId,
    comment,
    typeQuestion,
    score,
    isScoring,
    triggerOnSuccess
  ) => {
    !isScoring && setComment(comment);
    let newAnswer = {};
    let updatedLocalAnswers = localAnswers;

    if (typeQuestion === 'option') {
      if (objectId) {
        if (updatedLocalAnswers.some((answer) => answer.objectId == objectId)) {
          for (let i = 0; i < updatedLocalAnswers?.length; i++) {
            if (updatedLocalAnswers[i].objectId === objectId) {
              updatedLocalAnswers[i].optionId = optionId;
            }
          }
        } else {
          updatedLocalAnswers.push({
            objectId,
            ...(mechanism != 'weight_type' && { optionId }),
            comment
          });
        }
      } else {
        updatedLocalAnswers?.[0]
          ? (updatedLocalAnswers[0].optionId = optionId)
          : (updatedLocalAnswers = [{ optionId }]);
      }
    } else if (typeQuestion === 'comment') {
      if (updatedLocalAnswers.some((answer) => answer.objectId == objectId)) {
        for (let i = 0; i < updatedLocalAnswers?.length; i++) {
          if (updatedLocalAnswers[i].objectId === objectId) {
            updatedLocalAnswers[i].comment = comment;
          }
          if (checkOverride) {
            delete updatedLocalAnswers[i].optionId;
          }
        }
      } else {
        updatedLocalAnswers.push(
          mechanism === 'input_score' ||
            (view == 'raw' && currentTrack === 'goals_scoring')
            ? {
                objectId,
                score: parseFloat(score || inputScoreValue),
                comment
              }
            : view == 'raw' && currentTrack === 'review_aspects_scoring'
            ? {
                objectId,
                rawScore: parseFloat(score || inputScoreValue),
                comment
              }
            : {
                objectId,
                ...(mechanism != 'weight_type' && { optionId: activeScore }),
                comment
              }
        );
      }
    } else if (typeQuestion === 'inputScore') {
      if (objectId) {
        if (updatedLocalAnswers.some((answer) => answer.objectId == objectId)) {
          for (let i = 0; i < updatedLocalAnswers?.length; i++) {
            if (updatedLocalAnswers[i].objectId === objectId) {
              updatedLocalAnswers[i][
                currentTrack == 'review_aspects_scoring' ? 'rawScore' : 'score'
              ] = score;
            }
          }
        } else {
          currentTrack == 'review_aspects_scoring'
            ? updatedLocalAnswers.push({
                objectId,
                rawScore: score,
                comment
              })
            : updatedLocalAnswers.push({
                objectId,
                score,
                comment
              });
        }
      } else {
        updatedLocalAnswers?.[0]
          ? (updatedLocalAnswers[0].score = score)
          : (updatedLocalAnswers = [{ score }]);
      }
    }

    const filterUniqueArrayAnswers = getUniqueArrayOfObjects(
      updatedLocalAnswers,
      'objectId'
    );
    newAnswer = {
      average: 0,
      answers: filterUniqueArrayAnswers.filter(
        (answer) => answer.objectId == objectId
      )
    };

    currentTrack !== 'review_aspects_scoring'
      ? typeQuestion === 'inputScore'
        ? await updateAnswerContext(
            currentTrack,
            newAnswer,
            null,
            triggerOnSuccess
          )
        : await setAnswerContext(
            currentTrack,
            newAnswer,
            null,
            triggerOnSuccess
          )
      : await setAspectAnswerContext(
          currentTrackId,
          id,
          newAnswer,
          triggerOnSuccess
        );
  };

  const getTotalRating = async () => {
    if (
      currentTrack === 'goals_scoring' &&
      ['each', 'input_score'].includes(mechanism)
    ) {
      await getSingleGoalsScoring();
    } else if (
      currentTrack === 'tasks_scoring' &&
      ['specific_attribute', 'input_score'].includes(mechanism)
    ) {
      await getSingleTasksScoring();
    }
  };

  const funcActiveScore = async (optionId) => {
    setActiveScore(optionId);
    await setAnswer(
      id,
      optionId,
      comment,
      'option',
      null,
      true,
      getTotalRating
    );
  };

  const onBlurInputScore = (score) => {
    if (score) {
      let scoreNumber = convertStringToFloat(score);
      const isScoreOutOfRange =
        scoreNumber < minimumScore || scoreNumber > maximumScore;
      if (isScoreOutOfRange) {
        scoreNumber = scoreNumber < minimumScore ? minimumScore : maximumScore;
        setInputScoreValue(parseFloat(scoreNumber));
        addToast({
          title: getObjectiveLocale('Your answer is out of range'),
          msg: getObjectiveLocale(
            'Automatic correction is applied to keep your answer in allowed range. Please double check your answer again.'
          ),
          type: 'info'
        });
        return;
      }
      if (scoreNumber.toFixed(2) !== parseFloat(inputScoreValue).toFixed(2)) {
        setInputScoreValue(scoreNumber);
      }
    } else {
      setInputScoreValue(null);
    }
  };

  const updateInputScore = async () => {
    await setAnswer(
      id,
      null,
      comment,
      'inputScore',
      inputScoreValue,
      true,
      getTotalRating
    );
  };

  const showCompetencySidebar = async (name, description, objectId) => {
    setOverlaySidebar(true);

    let query = {
      behaviorType:
        currentTrack === 'competencies_scoring' ? 'competency' : 'value',
      parentId: objectId,
      placementId: targetPlacementId
    };
    setOverlayRightSidebarData({ loading: true });
    const { data } = await getBehaviours(query);
    setOverlayRightSidebarData({ name, description, data, loading: false });
  };

  useEffect(() => {
    if (answer.length > 0) {
      const answerData = answer?.[0];
      mechanism === 'input_score' || view == 'raw'
        ? setInputScoreValue(
            mechanism === 'input_score' ||
              (view == 'raw' && currentTrack === 'goals_scoring')
              ? answerData?.score
              : answerData?.rawScore
          ) //Handle initial value from answers for input_score mechanism
        : setActiveScore(answer?.[0].optionId);
    } else {
      setActiveScore(null);
    }
    // eslint-disable-next-line
  }, [localAnswers]);

  // Sync answers & comment when prefilled
  useEffect(() => {
    if (isPrefillCompleted) {
      setLocalAnswers(answers);
      setComment((answer.length > 0 && answer[0]?.comment) || '');
    }
  }, [isPrefillCompleted, setLocalAnswers, setComment, answer, answers]);

  // Always Sync Answers & if AllowOverride = false
  useEffect(() => {
    if (!allowOverride) {
      setLocalAnswers(answers);
    }
  }, [answers, setLocalAnswers, allowOverride]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (mechanism === 'input_score' || view == 'raw') {
        const answerData = answer?.[0];
        if (
          inputScoreValue !==
          (mechanism === 'input_score'
            ? answerData?.score
            : answerData?.rawScore)
        ) {
          updateInputScore();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputScoreValue]);

  return (
    <div className={`work-attribute-container ${withTitle ? '' : '!mb-[0px]'}`}>
      {withTitle && (
        <div className="wrapper-title mb-[16px]">
          <p className="text-n-900 typography-h500-longform">
            {idx + 1}. {title}
          </p>
          {(currentTrack === 'competencies_scoring' ||
            currentTrack === 'values_scoring') && (
            <SVGIcon
              size="16"
              customClass="ml-[4px] mt-[2px]"
              iconName={'icon-info'}
              fillColor={'var(--n-500)'}
              onClick={() => showCompetencySidebar(title, description, id)}
              dataCy="info-circle"
            />
          )}
        </div>
      )}

      {objective && permissions?.goalSee && (
        <Objectives
          initialData={objective}
          key={idx}
          cardModel="goal-list"
          page={'workScoring' + id}
          customClass="mb-[16px]"
        />
      )}

      {level && (
        <React.Fragment>
          <div className="flex">
            <p className="level">Level: {level + 1}</p>
            <p className="level ml-[16px]">
              {getObjectiveLocale('Weight')}: {object.weight || 0}
            </p>
          </div>
          <p className="level mb-[16px]">{description}</p>
        </React.Fragment>
      )}

      {weight && (
        <p className="weight-text mb-[16px]">
          {getObjectiveLocale('Weight Unit')}: {weight}
        </p>
      )}

      {mechanism === 'weight_type' && disabled ? (
        <p className="typography-paragraph text-n-800 mb-[16px]">
          {getObjectiveLocale('Suggested rating given by system')}
          {!allowOverride &&
            ', ' + getObjectiveLocale("you can't override the rating.")}
        </p>
      ) : (
        mechanism === 'weight_type' && (
          <p className="typography-paragraph text-n-800 mb-[16px]">
            {getObjectiveLocale(
              'The system gives a suggested rating accumulated from [total_goal] goals.'.replace(
                '[total_goal]',
                objectiveCount
              )
            )}
            {!allowOverride &&
              ' ' + getObjectiveLocale("you can't override the rating.")}
          </p>
        )
      )}

      {withLabelInfo && (
        <div className="flex items-center mt-[16px] mb-[8px]">
          <p className="label-text">
            {getObjectiveLocale(`[${currentTrack.replace(/_/g, ' ')}] Rating`)}
          </p>
          <TooltipContainer
            show
            text={getObjectiveLocale('View rating definition')}
            classContainer="cursor-pointer"
            useMaxWidth={false}
          >
            <SVGIcon
              size="16"
              customClass="ml-[4px]"
              iconName="icon-info"
              fillColor={'var(--n-500)'}
              onClick={() => showModalInfo(true)}
              dataCy="info-circle"
            />
          </TooltipContainer>
        </div>
      )}

      {mechanism !== 'input_score' && (view === 'rating' || !view) && (
        <>
          <div
            className={`w-full overflow-y-auto ${
              showOption == 'label_only' ? 'd-webkit-inline-box' : 'flex'
            } ${
              currentTrack != 'review_aspects_scoring' &&
              currentTrack != 'goals_scoring'
                ? ' mb-[16px]'
                : ''
            }`}
            data-cy="box-scoring"
          >
            {options
              ?.sort((a, b) => a.score - b.score)
              ?.map((val, idx) => (
                <React.Fragment key={idx}>
                  <div id={`portal-score-box${val.id}`} />
                  <ScoreBox
                    option={val}
                    onClick={funcActiveScore}
                    showOption={showOption}
                    selectedId={activeScore}
                    usePortal={`portal-score-box${val.id}`}
                    disabled={disabled}
                  />
                </React.Fragment>
              ))}
          </div>
          {(currentTrack == 'review_aspects_scoring' ||
            currentTrack == 'goals_scoring') &&
            suggestedScoreData && (
              <SuggestedScore
                showOption={showOption}
                data={suggestedScoreData}
                objectiveCount={objectiveCount}
              />
            )}
        </>
      )}

      {view === 'slider' && (
        <RatingSlider
          options={options}
          funcActiveScore={funcActiveScore}
          sliderLabel={sliderLabel}
          showOption={showOption}
          activeScore={activeScore}
          loading={sliderLoading}
          disabled={disabled}
          currentTrack={currentTrack}
          suggestedScoreData={suggestedScoreData}
          objectiveCount={objectiveCount}
        />
      )}

      {/* On Hold until backend support it */}
      {/* {
        mechanism === "weight_type" && disabled && selectedOption && (
          <div className="suggested-rating bg-n-100 border-n300 py-[16px] px-[16px] mt-[16px] rounded-[4px]">
            <p className="typography-h200 text-n-600">Suggested rating from 5 goals</p>
            <div className="flex items-center mt-[16px]">
              {(showOption === "score_and_label" || showOption === "score_only") && <span className="typography-h500 text-n-900 mr-[16px]">{selectedOption?.score || 0}</span>}
              {(showOption === "score_and_label" || showOption === "label_only") && <span className="typography-paragraph text-n-800">{selectedOption?.name}</span>}
            </div>
          </div>
        )
      } */}

      {view == 'raw' && (
        <InputScoreTrack
          suggestedTooltipText={`Automatically calculated from ${objectiveCount} goals`}
          objectiveCount={objectiveCount}
          tooltipText="View rating definition"
          onClickTooltip={() => showModalInfo(true)}
          showSuggestedScore={!!suggestedScoreData}
          suggestedScore={suggestedScoreData}
          titleField={
            allowOverride
              ? getObjectiveLocale(
                  `please type from [min_value] to [max_value]`
                )
                  .replace(/\[min_value]/g, options?.[0]?.score?.toFixed(2))
                  .replace(
                    /\[max_value]/g,
                    options?.[options?.length - 1]?.score?.toFixed(2)
                  )
              : ''
          }
          inputValue={inputScoreValue}
          disabled={disabled || isObjectiveDisabled}
          onBlur={onBlurInputScore}
          showOption="score_and_label"
          showTooltip={showTooltip && allowOverride}
        />
      )}

      {mechanism == 'input_score' && (
        <>
          <input
            type="number"
            className="input-number-custom w-[120px]"
            onBlur={(e) => onBlurInputScore(e?.target?.value)}
            value={inputScoreValue}
            disabled={isObjectiveDisabled}
            data-cy="task-percentage"
          />
          {isObjectiveDisabled && (
            <p className="typography-h100 text-n-600 mt-[8px]">
              This rating is automatically pre-filled by system
            </p>
          )}
        </>
      )}
      {useComment !== 'no' && (
        <TextAreaWithMention
          setComment={(value) => setAnswer(id, null, value, 'comment')}
          defaultValue={comment}
          customClass={`h-[98px] ${isTextAreaError ? 'border-red' : ''}`}
          ableToFocusGrow={false}
          mentionComponent={(mentionProps) => {
            return <span>{mentionProps.children}</span>;
          }}
          mentionEnabled={formalReviewMentionInCommentEnabled}
          label={
            getObjectiveLocale(`[${currentTrack.replace(/_/g, ' ')}] Comment`) +
            ` (${getObjectiveLocale(useComment || 'optional')})`
          }
          placeholder={`[${currentTrack.replace(
            /_/g,
            ' '
          )}] type your comment here...`}
        />
      )}

      {suggestionData && (
        <PrefillSuggestion
          phaseType={phaseType}
          viewType={view}
          suggestionData={suggestionData}
          options={options}
          showOption={showOption}
          suggestionSource={suggestionSource}
        />
      )}
      {modalInfo && (
        <ModalRatingInfo
          onClose={() => showModalInfo(false)}
          showOption={showOption}
          options={options}
        />
      )}
    </div>
  );
};

export default ObjectScoring;

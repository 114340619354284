import React, { useRef, useState } from 'react';

import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { useCreateCycle } from 'context/admin/CreateCycleContext';
import useClickOutside from 'hooks/useClickOutside';
import { getDateWithForcedTimezone } from 'utils/DateUtils';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import AntdCalendar from 'components/shared/Calendar/AntdCalendar';
import { listOptionDate } from 'components/shared/Calendar/ListAntdDateHelper';
import SVGIcon from 'components/shared/SVGIcon';

const RangeCalendarPhases = ({
  calendarContext = '',
  withResetButton = false,
  isSchedulePhaseCard = false,
  onResetSchedule = () => {},
  startsAt = null,
  endsAt = null,
  phaseArray = [],
  withDefaultDateAnnualy = true,
  withDisabledDate = false,
  startLimitDate = null,
  endLimitDate = null,
  customFormatDate = { day: 'numeric', month: 'long' }
}) => {
  const {
    cycleData,
    changeDate,
    changeScheduleCycleDate,
    changePhaseData,
    changeShowScheduleDate
  } = useCreateCycle();
  const [showDate, setShowDate] = useState(false);
  const ref = useRef();
  useClickOutside(ref, () => {
    setShowDate(false);
  });

  const handleSelectDate = (date) => {
    if (calendarContext === 'setPhases') {
      changeDate('start', dayjs(date[0]));
      changeDate('end', dayjs(date[1]));
    } else if (calendarContext === 'setSchedule') {
      changeScheduleCycleDate('start', dayjs(date[0]));
      changeScheduleCycleDate('end', dayjs(date[1]));
      changeShowScheduleDate(true);
      phaseArray.forEach((phase) => {
        changePhaseData(phase.id, 'startsAt', dayjs(date[0]));
        changePhaseData(phase.id, 'endsAt', dayjs(date[1]));
        changePhaseData(phase.id, 'showDueDate', true);
      });
    }
    // To Update phases (ex: 'selfReview') startsAt and endsAt
    else if (calendarContext && isSchedulePhaseCard) {
      changePhaseData(calendarContext, 'startsAt', dayjs(date[0]));
      changePhaseData(calendarContext, 'endsAt', dayjs(date[1]));
    }
  };

  const selectedDateValue = () => {
    switch (calendarContext) {
      case 'setPhases':
        return [
          cycleData.reviewedPeriodStartsAt,
          cycleData.reviewedPeriodEndsAt
        ];
      case 'setSchedule':
        return [cycleData.reviewProcessStartsAt, cycleData.reviewProcessEndsAt];
      default:
        return [startsAt, endsAt];
    }
  };
  const [customDate, setCustomDate] = useState([
    selectedDateValue[0],
    selectedDateValue[1]
  ]);

  // default date can be Annually or without default date [null, null]
  const defaultCalendarValue = withDefaultDateAnnualy
    ? [
        dayjs(`${new Date().getFullYear()}-01-01`),
        dayjs(`${new Date().getFullYear()}-12-31`).endOf('day')
      ]
    : [undefined, undefined];

  const FormatDate = (customDate, customFormatDate) => {
    let isToday = isEqual(customDate, [
      dayjs().startOf('day').format('YYYY-MM-DD'),
      dayjs().endOf('day').format('YYYY-MM-DD')
    ]);

    let isAllTime = isEqual(customDate, [null, null]);

    let isAnnual = isEqual(customDate, [
      dayjs(`${new Date().getFullYear()}-01-01`).format('YYYY-MM-DD'),
      dayjs(`${new Date().getFullYear()}-12-31`)
        .endOf('day')
        .format('YYYY-MM-DD')
    ]);

    let isNextYear = isEqual(customDate, [
      dayjs(`${new Date().getFullYear() + 1}-01-01`).format('YYYY-MM-DD'),
      dayjs(`${new Date().getFullYear() + 1}-12-31`)
        .endOf('day')
        .format('YYYY-MM-DD')
    ]);

    let isLastYear = isEqual(customDate, [
      dayjs(`${new Date().getFullYear() - 1}-01-01`).format('YYYY-MM-DD'),
      dayjs(`${new Date().getFullYear() - 1}-12-31`)
        .endOf('day')
        .format('YYYY-MM-DD')
    ]);

    if (isToday) {
      return getDateLocale(customDate[0]);
    }
    if (isAllTime) {
      return getObjectiveLocale('All Time');
    }
    if (isAnnual) {
      return getObjectiveLocale(
        `${getObjectiveLocale('This Year')} (${dayjs().format('YYYY')})`
      );
    }
    if (isLastYear) {
      return getObjectiveLocale(
        `${getObjectiveLocale('Last Year')} (${dayjs()
          .subtract(1, 'year')
          .format('YYYY')})`
      );
    }
    if (isNextYear) {
      return getObjectiveLocale(
        `${getObjectiveLocale('Next Year')} (${dayjs()
          .add(1, 'year')
          .format('YYYY')})`
      );
    }
    return (
      getDateLocale(customDate[0], customFormatDate) +
      ' - ' +
      getDateLocale(customDate[1], customFormatDate)
    );
  };

  const description =
    selectedDateValue()[0] && selectedDateValue()[1]
      ? FormatDate(
          [
            getDateWithForcedTimezone(selectedDateValue()[0]),
            getDateWithForcedTimezone(selectedDateValue()[1])
          ],
          customFormatDate
        )
      : getObjectiveLocale('(No Schedule Selected)');
  const resetDate = () => {
    setCustomDate([null, null]);
    onResetSchedule();
  };

  function isDateDisabled(current) {
    if (!current) {
      return false;
    }
    if (current.valueOf() < startLimitDate.valueOf()) return true;
    if (current.valueOf() > endLimitDate.valueOf()) return true;
  }

  return (
    <div className="filter-container">
      <div className="content-filter min-w-[328px]">
        <div
          className="time-detail bg-n-000 h-[32px] flex justify-between items-center border border-solid border-n-400 rounded-[4px]"
          onClick={() => setShowDate(!showDate)}
          data-cy="filter-time"
        >
          <p className="typography-button description" data-cy="date-text">
            {description}
          </p>
          <SVGIcon
            size="24"
            iconName="icon-calendar_today"
            fillColor="var(--base-600)"
          />
        </div>

        <div className="wrapper-calendar-filter" ref={ref}>
          {showDate && (
            <AntdCalendar>
              <AntdCalendar.Content
                dateValue={
                  selectedDateValue()[0] && selectedDateValue()[1]
                    ? customDate
                    : defaultCalendarValue
                }
                setDateValueHandler={handleSelectDate}
                position="left"
                isAlwaysOpen
                disabledDate={withDisabledDate ? isDateDisabled : false}
                listOptionsTime={listOptionDate([
                  `${getObjectiveLocale('Today')}`,
                  `${getObjectiveLocale(
                    `This Year (${new Date().getFullYear().toString()})`
                  )}`,
                  `${getObjectiveLocale(
                    `Next Year (${(new Date().getFullYear() + 1).toString()})`
                  )}`,
                  `${getObjectiveLocale(`S1 - ${new Date().getFullYear()}`)}`,
                  `${getObjectiveLocale(`S2 - ${new Date().getFullYear()}`)}`,
                  `${getObjectiveLocale(`Q1 - ${new Date().getFullYear()}`)}`,
                  `${getObjectiveLocale(`Q2 - ${new Date().getFullYear()}`)}`,
                  `${getObjectiveLocale(`Q3 - ${new Date().getFullYear()}`)}`,
                  `${getObjectiveLocale(`Q4 - ${new Date().getFullYear()}`)}`,
                  'custom'
                ])}
                clickOutsideCallback={() => setShowDate(false)}
              />
            </AntdCalendar>
          )}
        </div>
      </div>
      {withResetButton && (
        <Button.Tertiary onClick={() => resetDate()} customClass="ml-[8px]">
          Reset
        </Button.Tertiary>
      )}
    </div>
  );
};

export default RangeCalendarPhases;

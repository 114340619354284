import React, { useState } from 'react';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SuggestedScore from 'components/formal-review/custom-attributes/SuggestedScore';
import NumberInput from 'components/shared/NumberInput';
import SVGIcon from 'components/shared/SVGIcon';
import Tooltips from 'components/shared/Tooltips/Tooltips';

const InputScoreTrack = ({
  tooltipText,
  showSuggestedScore = true,
  suggestedScore,
  titleField,
  inputValue,
  onChange,
  onBlur,
  disabled,
  onClickTooltip = () => null,
  objectiveCount,
  showOption,
  showTooltip
}) => {
  const { organization } = useUser();

  const handleBlur = (e) => {
    onBlur(e?.target?.value);
  };

  return (
    <>
      {(titleField || showTooltip) && (
        <div className="flex my-[8px]">
          <p className="typography-h100 text-n-800">
            {getObjectiveLocale(titleField)}
          </p>
          {showTooltip && (
            <Tooltips
              text={tooltipText}
              isEllipsis
              addClass="h-[16px] ml-[8px]"
            >
              <SVGIcon
                size="16"
                fillColor="var(--n-600)"
                iconName="icon-info"
                onClick={onClickTooltip}
              />
            </Tooltips>
          )}
        </div>
      )}
      <div className="px-[16px] py-[16px] bg-n-200 border-n-300 border-solid border-[1px] rounded-[4px]">
        <NumberInput
          className="w-[136px]"
          inputClass="h-[36px]"
          value={inputValue}
          onChange={onChange}
          onBlur={handleBlur}
          onClick={(e) => {
            e.target.select();
          }}
          dataCy="input-overall-score"
          placeholder="Type the score"
          padFractionalZeros
          ableToInputZero
          disabled={disabled}
        />
        {organization.identifier != 'indico' && showSuggestedScore && (
          <SuggestedScore
            showOption={showOption}
            data={suggestedScore}
            objectiveCount={objectiveCount}
            showLabel={false}
          />
        )}
      </div>
    </>
  );
};

export default InputScoreTrack;

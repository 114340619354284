import React, { useEffect, useState } from 'react';

import { getMostDataGiven } from 'client/admin/Overview';

import StrengthComponent from './StrengthComponent';

function MostRecognitionGiven() {
  const [strengthSkills, setStrengthSkills] = useState({});

  const _getMostDataGiven = async () => {
    const query = {
      limit: 5,
      sortColumn: 'strength_count',
      sortDirection: 'desc'
    };
    const { data, error } = await getMostDataGiven(query);
    if (data) {
      setStrengthSkills(data);
    }
  };

  useEffect(() => {
    _getMostDataGiven();
  }, []);

  return (
    <div className="col-xs-6">
      <div className="item-overview">
        <h5 className="strength-title">Most Recognition Given</h5>
        <StrengthComponent
          type="topFiveStrength"
          strengthSkills={strengthSkills}
        />
      </div>
    </div>
  );
}

export default MostRecognitionGiven;

import React, { useEffect, useRef, useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

const RatingComponent = ({
  templateData,
  setTemplateData,
  listRatingTemplate,
  alwaysShowComponent = false,
  mechanism = null
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(templateData);

  const firstSetInitialSelected = useRef(false);

  const listShowRating = [
    'each',
    'overall_rating',
    'specific_attribute',
    'attributes',
    'final_rating',
    'input_score',
    'weight_type',
    'selected_aspect',
    'overall_score',
    'suggested_ongoing',
    'suggested_ongoing_parent_only'
  ];

  const handleSelectTemplate = (e, value) => {
    e?.stopPropagation();
    setSelectedTemplate(value);
  };

  useEffect(() => {
    if (templateData && firstSetInitialSelected.current == false) {
      setSelectedTemplate(templateData);
      firstSetInitialSelected.current = true;
    }
  }, [templateData]);

  useEffect(() => {
    setTemplateData(selectedTemplate);
  }, [selectedTemplate]);

  return (
    (alwaysShowComponent || listShowRating.includes(mechanism)) && (
      <div className="rounded-[4px] border border-solid border-n-400 bg-n-000 py-[16px] px-[16px] mt-[24px]">
        <p className="typography-h400 typography-primary text-n-900 mb-[4px]">
          {getObjectiveLocale('Rating template')}
        </p>
        <p className="typography-h400 typography-paragraph typography-secondary mb-[16px]">
          {getObjectiveLocale(
            'Rating template options will be in accordance with the current track template type'
          )}
        </p>
        <Dropdown customClass="w-full">
          <Dropdown.Trigger customClass="w-full">
            <div
              className="h-[36px] rounded-[3px] border border-solid border-n-400 bg-n-000 text-n-900 py-[8px] px-[16px] pr-[40px] relative flex items-center w-full cursor-pointer"
              data-cy="rating-template-dropdown"
            >
              {getObjectiveLocale(
                selectedTemplate
                  ? selectedTemplate.name
                  : 'Choose rating template for this track'
              )}
              <SVGIcon
                size="24"
                iconName="icon-unfold_more"
                fillColor="var(--n-700)"
                customClass="absolute"
                customStyle={{ top: '4px', right: '8px' }}
              />
            </div>
          </Dropdown.Trigger>
          <Dropdown.MenuItems customClass="w-full max-h-[280px] overflow-y-auto">
            {listRatingTemplate.map((value, index) => (
              <Dropdown.MenuItem
                onClick={(e) => handleSelectTemplate(e, value)}
                dataCy={`rating-template-item-${index}`}
                key={index}
              >
                {value.name}
              </Dropdown.MenuItem>
            ))}
          </Dropdown.MenuItems>
        </Dropdown>
      </div>
    )
  );
};

export default RatingComponent;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import DropdownGroupList from 'components/admin/groups/DropdownGroupList.js';
import RadioButton from 'components/shared/RadioButton';
import Modal from 'components/shared/modal/Modal';

function ModalArchiveGroup({
  checkedRadio,
  setCheckedRadio,
  modalArchiveData,
  setModalArchiveData,
  handleArchiveGroup,
  dropdownGroupList = [],
  setGroupOptionValue,
  onScroll
}) {
  const withPrimaryBtn = {
    title: 'Submit',
    onClick: () => handleArchiveGroup(),
    disabled: false,
    dataCy: ''
  };

  const withSecondaryBtn = {
    title: 'Cancel',
    onClick: () => setModalArchiveData(false),
    disabled: false,
    dataCy: ''
  };

  return (
    <Modal
      className="w-[400px]"
      title="Archive Group"
      eventOnClickClose={() => setModalArchiveData(false)}
      withPrimaryBtn={withPrimaryBtn}
      withSecondaryBtn={withSecondaryBtn}
    >
      <div className="modal-archive-group-container">
        <p>
          {getObjectiveLocale(
            'Select action for all members within this group'
          )}
          :
        </p>
        <RadioButton
          name="archive-group-option"
          checked={checkedRadio === 'default'}
          onClick={() => setCheckedRadio('default')}
          addClass="mt-[20px] mb-[16px]"
        >
          <span className="ml-[8px]">
            {' '}
            {getObjectiveLocale('Set to default')}{' '}
          </span>
        </RadioButton>
        {!modalArchiveData?.involvedMembershipsByInfo?.length > 0 && (
          <RadioButton
            name="archive-group-option"
            checked={checkedRadio === 'certain_group'}
            onClick={() => setCheckedRadio('certain_group')}
            addClass="mb-[16px]"
          >
            <span className="ml-[8px]">
              {' '}
              {getObjectiveLocale('Transfer to certain group')}{' '}
            </span>
          </RadioButton>
        )}
        <div
          className={`select-group-container ml-[40px] mr-[32px] mb-[16px] ${
            checkedRadio === 'certain_group' ? 'd-block' : 'invisible'
          }`}
        >
          <div>
            <label className="typography-h100 typography-secondary mb-[8px]">
              Select group:
            </label>
            <DropdownGroupList
              dataModel={dropdownGroupList}
              isLoading={false}
              defaultValue={dropdownGroupList[0]?.name}
              setGroupOptionValue={setGroupOptionValue}
              onScroll={onScroll}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalArchiveGroup;

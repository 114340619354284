import React from 'react';

import PropTypes from 'prop-types';

import {
  capitalizeSentence as capitalize,
  getObjectiveLocale
} from 'utils/HelperUtils';

import Modal from 'components/shared/modal/Modal';

import TrackDetailItem from './TrackDetailItem';
import './modal-track-detail.scss';

const interfaces = {
  title: PropTypes.string,
  trackConfigs: PropTypes.array,
  onCloseModal: PropTypes.func
};
const defaultValue = {
  title: 'Self',
  trackConfigs: [
    {
      name: 'Competencies Scoring',
      type: 'competencies_scoring',
      template: null,
      config: {},
      order: 4,
      weight: 45.0
    }
  ],
  onCloseModal: () => {}
};
const ModalTrackDetail = ({ title, trackConfigs, onCloseModal }) => {
  return (
    <Modal
      title={title.replace(/([a-z](?=[A-Z]))/g, '$1 ')}
      eventOnClickClose={onCloseModal}
      withCloseIcon={true}
      withFooter={false}
      useBorder={true}
      className="w-[auto]"
      contentWrapperClass="overflow-y-scroll mr-[8px]"
    >
      <div className="flex text-n-800">
        <div className="w-[48px]"></div>
        <p className="typography-h300 w-[268px] px-[16px]">
          {getObjectiveLocale('Track Detail')}
        </p>
        <p className="typography-h300 w-[218px] px-[8px]">
          {getObjectiveLocale('Track Template')}
        </p>
        <p className="typography-h300 w-[218px] px-[8px]">
          {getObjectiveLocale('Rating Template')}
        </p>
      </div>
      {trackConfigs.map((track, i) => (
        <TrackDetailItem index={i} track={track} key={i} />
      ))}
    </Modal>
  );
};
ModalTrackDetail.propTypes = interfaces;
ModalTrackDetail.defaultProps = defaultValue;
export default ModalTrackDetail;

import client from 'client/ApiClient';

function updateConfig(data, params) {
  return client('v1/admin/configs', {
    method: 'PUT',
    body: data,
    params: params
  });
}

function getConfig(query) {
  return client('v1/admin/configs', { params: query });
}

function getJiraDescriptor() {
  return client('/jira/descriptor');
}

export { updateConfig, getConfig, getJiraDescriptor };

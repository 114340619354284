import React, { useRef } from 'react';

import S3Client from 'client/S3Client';
import { getPreSignUrlValues } from 'client/adminClient.js';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';

function CreateBadge({ badge, setBadge, totalRecipients, setPage }) {
  const uploadIconRef = useRef();

  const _getPreSignUrl = async () => {
    const query = {
      fileType: 'media-resources',
      fileFormat: 'png'
    };
    const { data, error } = await getPreSignUrlValues(query);
    return data;
  };

  const handleUploadIcon = async (e) => {
    let file = e.target.files[0];

    if (file) {
      if (
        file.type == 'image/png' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/jpg'
      ) {
        let presignData = await _getPreSignUrl();
        if (presignData) {
          const { status } = await S3Client(presignData.presignedUrl, file);

          if (status == 200) {
            setBadge({ ...badge, iconUrl: presignData?.secureUrl });
          }
        } else {
          alert('Presign Link Not Found!');
        }
      } else {
        alert('Wrong file type! file type must be png, jpg or jpeg!');
        return false;
      }
    } else {
      alert('Empty file image');
    }
  };

  return (
    <div className="w-[720px]">
      <div className="border-bottom-400 pb-[40px]">
        <p className="typography-h500-longform text-n-900 mb-[4px]">
          {getObjectiveLocale('Badge')}
        </p>
        <p className="typography-paragraph text-n-800 mb-[16px]">
          {getObjectiveLocale(
            'Define a new badge options that will be given to specific user.'
          )}
        </p>
        <div className="bg-n-200 border-n-400 px-[24px] py-[24px]">
          <div className="flex justify-between mb-[24px]">
            <div className="w-[530px] mr-[24px]">
              <p className="typography-h100 text-n-800 mb-[8px]">
                {getObjectiveLocale('Badge Name')}
              </p>
              <InputField
                data-cy="badge-name"
                placeholder="Type here..."
                value={badge?.title}
                handleChange={(e) =>
                  setBadge({ ...badge, title: e.currentTarget.value })
                }
              />
            </div>
            <div className="w-[118px]">
              <p className="typography-h100 text-n-800 mb-[8px]">
                {getObjectiveLocale('Icon')}
              </p>
              <div className="flex justify-center items-center">
                {badge?.iconUrl && (
                  <AWSImage
                    className="w-[32px] h-[32px] mr-[8px]"
                    src={badge?.iconUrl}
                  />
                )}
                <Button.Tertiary
                  className="w-[96px] h-[32px]"
                  onClick={() => uploadIconRef.current.click()}
                >
                  {badge?.iconUrl ? 'Edit Icon' : 'Upload Icon'}
                </Button.Tertiary>
                <input
                  ref={uploadIconRef}
                  id="upload-icon"
                  data-cy="upload-icon"
                  type="file"
                  className="hidden"
                  onChange={(e) => handleUploadIcon(e)}
                />
              </div>
            </div>
          </div>
          <div>
            <p className="typography-h100 text-n-800 mb-[8px]">
              {getObjectiveLocale('Badge Description (optional)')}
            </p>
            <TextArea
              className="px-[16px] py-[4px]"
              typographyClass="text-n-900"
              height={96}
              isTransparent={false}
              textPlaceholder="Type here any description about this badge. Like event name, user criteria, etc..."
              onHandleChange={(e) =>
                setBadge({ ...badge, description: e.currentTarget.value })
              }
              value={badge?.description}
              cyName="badge-description"
            />
          </div>
        </div>
      </div>
      <div className="mt-[40px]">
        <p className="typography-h500-longform text-n-900 mb-[4px]">
          {getObjectiveLocale('Select recipients')}
        </p>
        <p className="typography-paragraph text-n-800 mb-[16px]">
          {getObjectiveLocale('Who will get this badge? (optional)')}
        </p>
        {totalRecipients > 0 && (
          <p className="typography-paragraph text-base-600 mb-[16px]">
            {getObjectiveLocale(
              `[total_recipients] recipient${
                totalRecipients === 1 ? '' : 's'
              } selected`
            ).replace(/\[total_recipients]/g, getNumberLocale(totalRecipients))}
          </p>
        )}
        <Button.Secondary onClick={() => setPage('setRecipients')}>
          <SVGIcon
            iconName="icon-add"
            size="24"
            fillColor="var(--base-600)"
            customClass="mr-[4px]"
          />
          {`${totalRecipients === 0 ? 'Add' : 'Edit'} recipient`}
        </Button.Secondary>
      </div>
    </div>
  );
}

export default CreateBadge;

import React from 'react';

import ObjectiveWrapper from 'components/objectives/ObjectiveWrapper';

import ObjectiveCardModel from './ObjectiveCardModel';

function Objective({
  objective,
  objectiveId,
  defaultShowChild,
  permissions,
  clusters,
  ...rest
}) {
  objectiveId = objective?.data?.id ?? objective?.id ?? objectiveId;

  return (
    <ObjectiveWrapper
      initialData={objective}
      objectiveId={objectiveId}
      defaultShowChild={defaultShowChild}
      permissions={permissions}
      clusters={clusters}
      {...rest}
    >
      <ObjectiveCardModel
        objective={objective}
        parent={objective?.parent}
        {...rest}
      />
    </ObjectiveWrapper>
  );
}

export default Objective;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import Papa from 'papaparse';

import {
  getGroupCMSCulture,
  getUserStats as getUserStatsClient
} from 'client/adminClient';
import { getDateLocale } from 'utils/HelperUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import Table from 'components/design-system/table/Table';
import ListEmptyState from 'components/shared/ListEmptyState';
import Loader from 'components/shared/LoadingComponent';

const PersonalGrowth = ({ route: { title } }) => {
  const [selectedYear, setSelectedYear] = useState('');
  const [year, setYear] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [month, setMonth] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(-1);
  const [selectedGroupName, setSelectedGroupName] = useState('General');
  const [groups, setGroups] = useState([]);
  const [userStats, setUserStats] = useState([]);
  const [average, setAverage] = useState(0);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [period, setPeriod] = useState('yearly');

  const initYearAndMonth = () => {
    const year = Array.apply(0, Array(dayjs().year() - 2015)).map(
      (_, i) => 2016 + i
    );

    const selectedYear = year[year.indexOf(dayjs().year())];
    const month = Array.apply(0, Array(12)).map((_, i) =>
      dayjs().month(i).format('MMMM')
    );

    const selectedMonth = month.indexOf(month[dayjs().month()]);

    setYear(year);
    setSelectedYear(selectedYear);
    setMonth(month);
    setSelectedMonth(selectedMonth);
  };

  const getListOfGroup = async () => {
    let { data, error } = await getGroupCMSCulture();
    if (error) return;

    let groups = [...data];
    setGroups(groups);
  };

  const getUserStats = async (groupId, year, month) => {
    setIsLoading(true);
    let params = {
      groupId: selectedGroup,
      period: period,
      y: selectedYear,
      m: selectedMonth
    };

    if (period === 'yearly') {
      delete params.m;
    }

    let { data, error } = await getUserStatsClient(params);
    if (error) return;

    setUserStats([...data.data]);
    setAverage(data.average);
    setLastUpdate(data?.updatedAt);
    setIsLoading(false);
  };

  const handleDownloadCSV = () => {
    const rawData = userStats.map((user, id) => ({
      No: id + 1,
      Name: user.fullName,
      Email: user.email,
      Post: user.post,
      'Like Received': user.likeReceived,
      'Comment Received': user.commentReceived,
      'View a Post': user.view,
      'Personal Growth': user.personalGrowth,
      'Personal Growth Index': user.personalGrowthIndex
        ? user.personalGrowthIndex.toFixed(2)
        : 0,
      Average: average.toFixed(0)
    }));
    const csv = Papa.unparse(rawData);
    const groupName = selectedGroupName;
    const year = selectedYear;
    const month = selectedMonth;

    let fileName = `personal_growth_${groupName.toLowerCase()}_${year}.csv`;

    if (period === 'monthly')
      fileName = `personal_growth_${groupName.toLowerCase()}_${month.toLowerCase()}_${year}.csv`;

    let file;

    if (!csv.match(/^data:text\/csv/i))
      file = `data:text/csv;charset=utf-8,${csv}`;

    const data = encodeURI(file);
    const link = document.createElement('a');

    link.setAttribute('href', data);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 1500);
  };

  const selectGroup = (data) => {
    setSelectedGroupName(data.name);
    setSelectedGroup(data.id);
  };

  const tableHeader = [
    { name: getObjectiveLocale('No.'), widthClass: 'w-[20px]', grow: true },
    { name: getObjectiveLocale('Name'), widthClass: 'w-[300px]', grow: true },
    { name: getObjectiveLocale('Email'), widthClass: 'w-[300px]', grow: true },
    { name: getObjectiveLocale('Post'), widthClass: 'w-[80px]', grow: true },
    {
      name: getObjectiveLocale('Like Received'),
      widthClass: 'w-[80px]',
      grow: true
    },
    {
      name: getObjectiveLocale('Comment Received'),
      widthClass: 'w-[80px]',
      grow: true
    },
    {
      name: getObjectiveLocale('View a Post'),
      widthClass: 'w-[80px]',
      grow: true
    },
    {
      name: getObjectiveLocale('Personal Growth'),
      widthClass: 'w-[80px]',
      grow: true
    },
    {
      name: getObjectiveLocale('Personal Growth Index'),
      widthClass: 'w-[100px]',
      grow: true
    }
  ];

  useEffect(() => {
    initYearAndMonth();
    getListOfGroup();
  }, []);

  useEffect(() => {
    getUserStats();
  }, [selectedGroup, period, selectedMonth, selectedYear]);

  const headerProps = {
    titlePage: title,
    primaryAction: {
      title: 'CSV',
      icon: 'icon-file_download',
      onClick: () => handleDownloadCSV()
    }
  };

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="admin-culture col-xs-12">
        <div>
          <div className="table-menu-collaboration mb-[12px]">
            <div className="flex w-[35%]">
              <div className="info">
                <p>
                  {getObjectiveLocale('Last Update')} :{' '}
                  <strong>
                    {getDateLocale(lastUpdate ? lastUpdate : dayjs(), {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                      hourCycle: 'h12',
                      hour: 'numeric',
                      weekday: 'long'
                    })}
                  </strong>
                </p>
                <p className="typography-paragraph text-n-800">
                  {getObjectiveLocale('Average')}:{' '}
                  <strong>{average.toFixed(2)}</strong>
                </p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col mr-[8px]">
                <span>{getObjectiveLocale('Group')}</span>
                <Dropdown>
                  <Dropdown.Trigger>
                    <Trigger
                      topLabel={false}
                      label={false}
                      icon={false}
                      text={selectedGroupName}
                    />
                  </Dropdown.Trigger>
                  <Dropdown.MenuItems maxHeight={300}>
                    {groups.map((group) => (
                      <Dropdown.MenuItem
                        key={group.id}
                        onClick={() =>
                          selectGroup({ name: group?.name, id: group.id })
                        }
                        id={group.name}
                      >
                        {group.name}
                      </Dropdown.MenuItem>
                    ))}
                  </Dropdown.MenuItems>
                </Dropdown>
              </div>
              <div className="flex flex-col mr-[8px]">
                <span>{getObjectiveLocale('Period')}</span>
                <Dropdown>
                  <Dropdown.Trigger>
                    <Trigger
                      topLabel={false}
                      label={false}
                      icon={false}
                      text={period}
                      capitalize
                    />
                  </Dropdown.Trigger>
                  <Dropdown.MenuItems maxHeight={300} customClass={'w-[200px]'}>
                    <Dropdown.MenuItem onClick={() => setPeriod('yearly')}>
                      {getObjectiveLocale('Yearly')}
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem onClick={() => setPeriod('monthly')}>
                      {getObjectiveLocale('Monthly')}
                    </Dropdown.MenuItem>
                  </Dropdown.MenuItems>
                </Dropdown>
              </div>
              {period == 'monthly' && (
                <div className="flex flex-col mr-[8px]">
                  <span>{getObjectiveLocale('Month')}</span>
                  <Dropdown>
                    <Dropdown.Trigger>
                      <Trigger
                        topLabel={false}
                        label={false}
                        icon={false}
                        text={month[selectedMonth - 1]}
                        capitalize
                      />
                    </Dropdown.Trigger>
                    <Dropdown.MenuItems
                      maxHeight={300}
                      customClass={'w-[200px]'}
                    >
                      {month.map((m, index) => (
                        <Dropdown.MenuItem
                          key={index}
                          onClick={() => setSelectedMonth(index + 1)}
                        >
                          {m}
                        </Dropdown.MenuItem>
                      ))}
                    </Dropdown.MenuItems>
                  </Dropdown>
                </div>
              )}
              <div className="flex flex-col mr-[8px]">
                <span>{getObjectiveLocale('Yearly')}</span>
                {year.length !== 0 && (
                  <Dropdown>
                    <Dropdown.Trigger>
                      <Trigger
                        topLabel={false}
                        label={false}
                        icon={false}
                        text={selectedYear}
                      />
                    </Dropdown.Trigger>
                    <Dropdown.MenuItems
                      maxHeight={300}
                      customClass={'w-[200px]'}
                    >
                      {year.map((year, index) => (
                        <Dropdown.MenuItem
                          key={index}
                          onClick={() => setSelectedYear(year)}
                        >
                          {year}
                        </Dropdown.MenuItem>
                      ))}
                    </Dropdown.MenuItems>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
          <Table headers={[...tableHeader]}>
            {!isLoading && userStats.length !== 0
              ? userStats.map((v, i) => (
                  <Table.Row key={i}>
                    <Table.Column>{i + 1}</Table.Column>
                    <Table.Column>{v.fullName}</Table.Column>
                    <Table.Column>{v.email}</Table.Column>
                    <Table.Column>{v.post}</Table.Column>
                    <Table.Column>{v.likeReceived}</Table.Column>
                    <Table.Column>{v.commentReceived}</Table.Column>
                    <Table.Column>{v.view}</Table.Column>
                    <Table.Column>{v.personalGrowth}</Table.Column>
                    <Table.Column>
                      {v.personalGrowthIndex
                        ? v.personalGrowthIndex.toFixed(2)
                        : 0}
                    </Table.Column>
                  </Table.Row>
                ))
              : !isLoading && (
                  <ListEmptyState
                    fullHeight
                    emptyIcon="icon-no-result-found"
                    title={getObjectiveLocale('No Result Found')}
                    subtitle={getObjectiveLocale(
                      'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
                    )}
                    subtitleClassname="text-center mb-[24px]"
                  />
                )}
            {isLoading && <Loader className="h-[120px]" />}
          </Table>
        </div>
      </div>
    </>
  );
};

export default PersonalGrowth;

import { capitalize, getObjectiveLocale } from "utils/HelperUtils";
import dayjs from 'dayjs';
import get from "lodash/get";
import difference from "lodash/difference";

const showCycleScheduleConfirm = (diff) => {
  const phases = ["selfReview", "managerReview", "peerReview", "upwardReview", "indirectManagerReview"];
  const isPhasesScheduleChanged = difference(phases, Object.keys(diff)).length !== phases.length

  return {
    schedule: (diff.hasOwnProperty("reviewProcessStartsAt") || diff.hasOwnProperty("reviewProcessEndsAt")),
    phases: isPhasesScheduleChanged
  }
}

const getScheduleDate = ({ stateData, isPrevious, startsKey, endsKey }) => {
  const data = stateData[isPrevious ? "cycle" : "editedCycle"];

  // Check if no selected schedule
  if (!get(data, startsKey) && !get(data, endsKey)) {
    return getObjectiveLocale("No schedule selected");
  } else {
    return `${dayjs(get(data, startsKey)).format("DD MMMM YYYY")} - ${dayjs(get(data, endsKey)).format("DD MMMM YYYY")}`
  }
}

const _getConfirmData = (stateData, configuredPhases) => {
  const diff = Object.fromEntries(Object.entries(stateData?.editedCycle).filter(([key, val]) => key in stateData?.cycle && stateData?.cycle[key] !== val));

  const toBeConfirmData = []
  let warningMessage = {};

  if (diff.hasOwnProperty('name')) {
    toBeConfirmData.push({
      id: "name",
      titleSection: getObjectiveLocale("Cycle Name")
    })
  }
  if (diff.hasOwnProperty('restrictedTrackNavigation')) {
    toBeConfirmData.push({
      id: "restrictedTrackNavigation",
      titleSection: getObjectiveLocale("Switch Track")
    })
  }
  if (showCycleScheduleConfirm(diff).schedule || showCycleScheduleConfirm(diff).phases) {
    configuredPhases.map(({ id: phaseKey }) => {
      if (diff.hasOwnProperty(phaseKey) && dayjs(stateData?.cycle[phaseKey].startsAt).isBefore(stateData?.editedCycle[phaseKey].startsAt)) {
        if (!warningMessage["reviewSchedule"]) {
          warningMessage = {
            ...warningMessage,
            reviewSchedule: getObjectiveLocale("Progress made during the previous phase schedule will be closed and re-opened during the new phase schedule")
          }
        }
      }
    })

    toBeConfirmData.push({
      id: "reviewSchedule",
      titleSection: getObjectiveLocale("Schedule"),
      additionalColumn: {
        header: "Schedule",
        data: [
          ...([showCycleScheduleConfirm(diff).schedule && "Cycle"]),
          ...(showCycleScheduleConfirm(diff).phases ? configuredPhases.map(({ id: phaseKey }) => {
            return diff.hasOwnProperty(phaseKey) && capitalize(phaseKey.replace(/([a-z](?=[A-Z]))/g, '$1 '))
          }) : [])
        ].filter(data => data)
      },
      prev: [
        ...([showCycleScheduleConfirm(diff).schedule && getScheduleDate({ stateData, isPrevious: true, startsKey: "reviewProcessStartsAt", endsKey: "reviewProcessEndsAt" })]),
        ...(showCycleScheduleConfirm(diff).phases ? configuredPhases.map(({ id: phaseKey }) => {
          return diff.hasOwnProperty(phaseKey) && getScheduleDate({ stateData, isPrevious: true, startsKey: `${phaseKey}.startsAt`, endsKey: `${phaseKey}.endsAt` })
        }) : [])
      ].filter(data => data),
      current: [
        ...([showCycleScheduleConfirm(diff).schedule && getScheduleDate({ stateData, isPrevious: false, startsKey: "reviewProcessStartsAt", endsKey: "reviewProcessEndsAt" })]),
        ...(showCycleScheduleConfirm(diff).phases ? configuredPhases.map(({ id: phaseKey }) => {
          return diff.hasOwnProperty(phaseKey) && getScheduleDate({ stateData, isPrevious: false, startsKey: `${phaseKey}.startsAt`, endsKey: `${phaseKey}.endsAt` })
        }) : [])
      ].filter(data => data),
      warning: warningMessage?.reviewSchedule
    })
  }

  return toBeConfirmData;
}

export {
  _getConfirmData
}
import React from 'react';

import { getBehaviours } from 'client/FormalReviewClient';

import ProfileEvidence from './ProfileEvidence';
import WrapperAssessmentScore from './WrapperAssessmentScore';
import WrapperTotalScoring from './WrapperTotalScoring';

const CardCompetenciesOrValuesScoring = ({
  showOption,
  totalScore,
  targetId,
  trackType,
  answersData,
  setOverlaySidebar,
  setOverlayRightSidebarData = () => {
    //
  },
  isReviewResultPage
}) => {
  const showCompetencyLevelSidebar = async (name, description, objectId) => {
    let query = {
      behavior_type: trackType.includes('competencies')
        ? 'competency'
        : 'value',
      parent_id: objectId,
      user_id: targetId
    };

    setOverlayRightSidebarData({ loading: true });
    const { data } = await getBehaviours(query);
    if (data) {
      setOverlayRightSidebarData({ name, description, data, loading: false });
      setOverlaySidebar(true);
    }
  };
  return (
    <div
      id={'container-' + trackType.replace('_', '-') + '-sidebar'}
      className="mt-[24px]"
      data-cy={'container-' + trackType.replace('_', '-') + '-sidebar'}
    >
      {totalScore && (
        <WrapperTotalScoring
          totalScore={totalScore}
          dataCy="behavior-total-score-container"
        />
      )}

      {(answersData || []).map((data, idx) => (
        <div
          key={idx}
          className="evidence-border px-[16px] py-[16px] mb-[16px]"
        >
          <h4
            onClick={() =>
              !isReviewResultPage &&
              showCompetencyLevelSidebar(
                data.object.title,
                data.object.description,
                data.object.id
              )
            }
            className={`mb-[16px] typography-h500-longform capitalize objective-title ${
              isReviewResultPage ? '' : 'cursor-pointer'
            }`}
            data-cy="behavior-title"
          >
            {data?.object?.title || data?.object?.name}
          </h4>

          <div className="flex">
            {data?.object?.expectedLevel !== undefined &&
              data?.object?.expectedLevel !== null && (
                <p className="evidence-score-box-grey mb-[16px] typography-h200">
                  <span className="typography-h100">
                    {'Expected level: '}
                    {data?.object?.expectedLevel + 1}
                  </span>
                </p>
              )}

            {data?.object?.weight && (
              <p
                className={`evidence-score-box-grey mb-[16px] typography-h200`}
              >
                Weight:{' '}
                <span className="typography-h100">
                  {data?.objective?.weight}
                </span>
              </p>
            )}
          </div>

          {(data.answers || []).map((answer, i) => (
            <React.Fragment key={i}>
              <ProfileEvidence profileData={answer?.actor} />

              <WrapperAssessmentScore
                showOption={showOption}
                statusColorHex={answer?.option?.colorHex}
                statusName={answer?.option?.name}
                personalScore={answer?.option?.score}
                isLastIndex={i !== data.answers.length - 1}
                description={answer?.comment}
                components={data?.components}
              />
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CardCompetenciesOrValuesScoring;

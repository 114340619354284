import React from 'react';

import isEmpty from 'lodash/isEmpty';

import SingleUser from 'components/design-system/single-user/SingleUser';

const CreatedBy = React.memo(({ task }) => {
  const { createdBy } = task || {};

  if (isEmpty(createdBy)) return null;

  return (
    <SingleUser
      userData={{ name: createdBy?.name, src: createdBy?.profilePicture }}
      size={24}
      customClass="w-full"
    />
  );
});

export default CreatedBy;

import React from 'react';

import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import SVGIcon from 'components/shared/SVGIcon';

const getSkeletonLoading = () => {
  return (
    <div className="w-full flex justify-around items-end h-[160px]">
      <div className="mr-[16px] width-full">
        <Shimmer customClass="rounded" width="150px" height="100px" />
      </div>
      <div className="mr-[16px] width-full">
        <Shimmer customClass="rounded" width="150px" height="180px" />
      </div>
      <div className="mr-[16px] width-full">
        <Shimmer customClass="rounded" width="150px" height="150px" />
      </div>
      <div className="mr-[16px] width-full">
        <Shimmer customClass="rounded" width="150px" height="50px" />
      </div>
    </div>
  );
};

const ColumnChart = ({
  chartData = [],
  isLoading,
  chartType,
  isPerfCategory,
  withTooltip = false
}) => {
  if (chartData.length > 0) {
    chartData = chartData[0];
  }
  let categories = [];
  let seriesData = [
    {
      name: 'Over Quota',
      showInLegend: false,
      color: 'var(--r-600)',
      data: []
    },
    {
      name: 'Needed Quota',
      showInLegend: false,
      color: 'var(--n-600)',
      data: []
    },
    {
      name: 'Allowed Quota',
      showInLegend: false,
      color: 'var(--b-600)',
      data: []
    }
  ];
  if (chartData && Object.prototype.hasOwnProperty.call(chartData, 'quotas')) {
    chartData.quotas.map((data) => {
      const { bottomThreshold, upperThreshold, title, value } = data.quota;
      let total = data.total;
      if (chartType == 'calibration') {
        if (data.quota == null) {
          seriesData[1].data.push(0);
          seriesData[0].data.push(0);
          seriesData[2].data.push(data.total);
        } else {
          if (value > total) {
            seriesData[0].data.push(0);
            seriesData[2].data.push(total);
            seriesData[1].data.push(value - total);
          } else if (value < total) {
            seriesData[1].data.push(0);
            seriesData[2].data.push(value);
            seriesData[0].data.push(total - value);
          } else {
            seriesData[1].data.push(0);
            seriesData[0].data.push(0);
            seriesData[2].data.push(total);
          }
        }
      } else {
        seriesData[2].data.push(data.total);
      }
      let newCategoriesObject = {
        scoreRange: ` ${bottomThreshold !== 0 ? '> ' : ''}${
          bottomThreshold > 0 ? parseFloat(bottomThreshold).toFixed(2) : 0
        }-${parseFloat(upperThreshold).toFixed(2)}`,
        title: title
      };
      categories.push(newCategoriesObject);
    });
  }
  let chartConfig = {
    chart: {
      type: 'column',
      height: chartType == 'calibration' ? '176' : '210'
    },
    xAxis: {
      categories: categories,
      labels: {
        formatter() {
          return `<div>
              <span style="color: var(--n-600); font-size: 12px; line-height: 16px">${
                this?.value?.title || ''
              }</span> <br>
              <span style="color: var(--n-600); font-size: 12px; line-height: 16px">${
                (!isPerfCategory && this?.value?.scoreRange) || ''
              }</span>
            <div>`;
        }
      },
      tickWidth: 0
    },
    yAxis: {
      title: {
        text: null
      },

      labels: {
        formatter() {
          return `<span style="color: var(--n-600); font-size: 12px; line-height: 16px">${this?.value}</span>`;
        }
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    series: seriesData,
    title: {
      style: {
        display: 'none'
      }
    }
  };

  if (withTooltip) {
    chartConfig['tooltip'] = {
      formatter() {
        return `<b>${this.x.title}</b><br><b>${this.series.name}: ${this.point.y}</b><br/>`;
      }
    };
  }

  const defaultStackLabels = {
    enabled: true,
    style: {
      color: 'var(--n-900)'
    },
    useHTML: true
  };

  switch (chartType) {
    case 'calibration':
      chartConfig['yAxis']['stackLabels'] = {
        ...defaultStackLabels,
        formatter() {
          // series 0 = Over Quota
          // series 1 = Needed Quota
          // series 2 = Allowed Quota
          const allowedQuota = this.axis.series[2].yData[this.x] || 0;
          const overQuota = this.axis.series[0].yData[this.x] || 0;
          const neededQuota = this.axis.series[1].yData[this.x] || 0;
          const sum = allowedQuota + overQuota;
          const percentage =
            ((sum / chartData.totalTarget) * 100).toFixed(2) + '%';
          const totalQuota = allowedQuota + neededQuota;
          return `${sum} <span class="typography-tertiary fs-12">(${percentage})</span> / ${totalQuota}`;
        }
      };
      break;
    case 'result':
      chartConfig['yAxis']['stackLabels'] = {
        ...defaultStackLabels,
        formatter() {
          // series 0 = Over Quota
          // series 1 = Needed Quota
          // series 2 = Allowed Quota
          const allowedQuota = this.axis.series[2].yData[this.x] || 0;
          const overQuota = this.axis.series[0].yData[this.x] || 0;
          const sum = allowedQuota + overQuota;
          const percentage =
            ((sum / chartData.totalTarget) * 100).toFixed(2) + '%';
          return `${sum} <span class="typography-tertiary fs-12">(${percentage})</span>`;
        }
      };
      break;
    default:
      chartConfig['yAxis']['stackLabels'] = { ...defaultStackLabels };
      break;
  }

  const legends = [
    { color: 'var(--r-600)', name: 'Over Quota', background: 'bg-g-600' },
    { color: 'var(--n-600)', name: 'Needed Quota', background: 'bg-g-600' },
    { color: 'var(--b-400)', name: 'Allowed', background: 'bg-n-400 ' }
  ];

  return (
    <>
      {chartType == 'calibration' && !isLoading && (
        <div className="flex px-[14px] my-[16px]">
          {legends.map((legend, index) => (
            <div
              key={index}
              className="flex items-center typography-h100 text-n-600 mr-[16px]"
            >
              <SVGIcon
                iconName="icon-stop"
                fillColor={legend.color}
                size="16"
              />
              <span className="ml-[8px]">{legend.name}</span>
            </div>
          ))}
        </div>
      )}
      <div className="relative px-[6px]">
        {!isLoading ? (
          <Chart highcharts={Highcharts} options={chartConfig} />
        ) : (
          getSkeletonLoading()
        )}
        <div
          id="tooltip-hc"
          className="absolute invisible rounded-[4px] bg-n-900 text-n-000 typography-h100 py-[4px] px-[12px]"
        >
          Click to view detail
        </div>
      </div>
    </>
  );
};

export default ColumnChart;

import { create } from 'zustand';

const useModalObjectives = create((set) => ({
  modalType: null,
  objectiveId: null,
  showModal: ({
    modalType,
    objectiveId = null,
    submitModal = () => null,
    props
  }) =>
    set({
      modalType,
      objectiveId,
      submitModal: submitModal,
      propsModal: props
    }),
  closeModal: () => set({ modalType: null }),
  submitModal: () => null,
  propsModal: null
}));

export default useModalObjectives;

import React from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import { useLocale } from 'hooks/useLocale';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

const PulseSurveyCard = ({ surveyData, currentTab }) => {
  const history = useHistory();
  const { setBothLocale } = useLocale();
  return surveyData.map((surveyData) => {
    return (
      <div
        key={`${surveyData.id}-${surveyData.occurrence.id}`}
        className="mt-[24px] bg-n-000 border-solid border-n-400 border-[1px] typography-h500-longform min-h-[72px]"
      >
        <div className="wrapper-header">
          <div className="px-[24px] pt-[16px] cursor-pointer flex justify-between">
            <div className="flex items-center bg-n-000 max-h-[640px] overflow-y-auto w-2/3">
              <div className="pb-[12px] pr-[16px]">
                <div className="rounded-full bg-g-600 w-[36px] h-[36px] pt-[3px] pl-[3px]">
                  <SVGIcon
                    iconName="icon-assignment"
                    size="30"
                    fillColor="var(--n-000)"
                  />
                </div>
              </div>
              <div>
                <p className="typography-h500" data-cy="user-name">
                  {surveyData?.title}
                </p>
                <p className="text-n-800 pb-[12px]" data-cy="user-position">
                  Due{' '}
                  {dayjs(surveyData?.occurrence?.endDate).format('DD MMM YYYY')}
                </p>
              </div>
            </div>
            <div className="flex h-[36px] w-[304px] justify-between">
              {surveyData.occurrence.answered && (
                <div className="flex pb-[12px] pt-[8px]">
                  <div className="rounded-full bg-g-200 w-[24px] h-[24px] pt-[2px] pl-[2px]">
                    <SVGIcon
                      iconName="icon-done"
                      size="20"
                      fillColor="var(--g-600)"
                    />
                  </div>
                  <p className="pt-[2px] pl-[16px] font-medium text-g-600">
                    {setBothLocale('Completed')}
                  </p>
                </div>
              )}
              {!surveyData.occurrence.answered && (
                <div className="flex pb-[12px] pt-[8px]">
                  <div className="rounded-full bg-n-000 w-[24px] h-[24px] pt-[1px] pl-[1px] border-solid border-n-400 border-[1px]">
                    <SVGIcon
                      iconName="icon-done"
                      size="20"
                      fillColor="var(--n-500)"
                    />
                  </div>
                  <p className="pt-[2px] pl-[16px] font-medium text-n-600">
                    {setBothLocale('Incomplete')}
                  </p>
                </div>
              )}
              <div className="pl-[16px] flex justify-end">
                {surveyData.canBePostponed &&
                  !surveyData.occurrence.answered &&
                  currentTab === 'current' && (
                    <Button
                      onClick={() =>
                        history.push({
                          pathname: `/pulse-survey/answer-${
                            surveyData?.canBePostponed ? 'later' : 'now'
                          }/${surveyData.id}`,
                          search: `?occurrenceId=${surveyData?.occurrence?.id}`
                        })
                      }
                      datacy="answer-pulse-survey"
                    >
                      {setBothLocale('Answer Now')}
                    </Button>
                  )}
                {surveyData.occurrence.answered && (
                  <Button.Secondary
                    onClick={() =>
                      history.push({
                        pathname: `/pulse-survey/summary/${surveyData.id}`,
                        search: `?occurrenceId=${surveyData?.occurrence?.id}`
                      })
                    }
                    datacy="view-summary-survey"
                  >
                    {setBothLocale('View Summary')}
                  </Button.Secondary>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default PulseSurveyCard;

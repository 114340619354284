import React, { useEffect, useState } from 'react';

import {
  getDetailTrackTemplate,
  getMechanism
} from 'client/TrackTemplateClient.js';

import { getMechanismDescription } from 'components/admin/track-template/ConfigurationSettingTrackTemplate.js';
import ContentTrackTemplate from 'components/admin/track-template/ContentTrackTemplate';
import LoadingComponent from 'components/shared/LoadingComponent';
import Modal from 'components/shared/modal/Modal';

const ModalPreviewTemplate = ({ openModalView, setOpenModalView }) => {
  const { track } = openModalView;

  const [trackDetailData, setTrackDetailData] = useState([]);
  const [loadingTrack, setLoadingTrack] = useState(false);
  const [mechanismData, setMechanismData] = useState([]);

  const { name, config } = trackDetailData || {};

  const _getDetailTrackTemplate = async () => {
    setLoadingTrack(true);
    const { data } = await getDetailTrackTemplate(track.id, {
      state: 'active'
    });
    if (data) {
      setTrackDetailData(data);
      data.trackType && (await _getMechanismData(data.trackType));
    }
    setLoadingTrack(false);
  };

  const _getMechanismData = async (trackType) => {
    const { data } = await getMechanism({ trackType });
    if (data) {
      setMechanismData(data.trackMechanism);
    }
  };

  useEffect(() => {
    _getDetailTrackTemplate();
  }, []);

  return (
    <Modal
      title={name}
      withCloseIcon={true}
      className="w-[643px]"
      maxHeight={432}
      eventOnClickClose={() => setOpenModalView({ show: false })}
      withFooter={false}
    >
      <div className="h-[280px] overflow-scroll overflow-x-hidden">
        <div className="content-sidebar-track-template">
          {/* Currently in use : list track template */}
          {loadingTrack ? (
            <LoadingComponent />
          ) : (
            <ContentTrackTemplate
              trackDetailData={trackDetailData}
              mechanismDescription={getMechanismDescription(
                mechanismData,
                config?.mechanism
              )}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalPreviewTemplate;

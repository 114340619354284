import React, { createRef, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import flatten from 'lodash/flatten';
import startCase from 'lodash/startCase';

import { getAllTeam, getListTeams } from 'client/ObjectivesClient';
import useFilter from 'hooks/useFilter';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useTeam from 'hooks/useTeam';
import { restructureFilter } from 'utils/ObjectivesHelper';

import CardAddProject from 'components/project/card/CardAddProject';
import 'components/project/card/ProjectCardView.scss';
import SquadSkeleton from 'components/teams/skeleton/SquadSkeleton';
import TribeSkeleton from 'components/teams/skeleton/TribeSkeleton';
import ListEmptyState from 'src/components/shared/ListEmptyState';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

import SquadCard from './SquadCard';
import TribeCard from './TribeCard';

const LIMIT = 15;

const TeamCardView = ({ setOpenModalCreate, section, selectedSwitcher }) => {
  const [showFirstSkeleton, setShowFirstSkeleton] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const {
    tribes,
    setTribes,
    squads,
    setSquads,
    setIsTribeLoading,
    setIsSquadLoading,
    isTribeLoading,
    isSquadLoading
  } = useTeam();
  const {
    name: groupName,
    isViewAll: isViewAllTeam,
    isAbleToCreateTeam,
    isVisible,
    isContentRendered
  } = section;
  const isSquad = groupName == 'squad';
  const teams = isSquad ? squads : tribes;
  const setIsLoading = isSquad ? setIsSquadLoading : setIsTribeLoading;
  const isLoading = isSquad ? isSquadLoading : isTribeLoading;

  const filter = useFilter((state) => state.filterById?.teamFilter);
  let params = restructureFilter(filter);

  const fetchTeams = async (olderThan) => {
    let allParams = {
      reviewsVisibility: 1,
      limit: LIMIT,
      query: params?.q,
      teamType: groupName,
      ...(selectedSwitcher == 'archived' ? { archive: true } : {}),
      ...(olderThan && { olderThan: olderThan }),
      ...params
    };

    return isViewAllTeam ? getAllTeam(allParams) : getListTeams(allParams);
  };

  const key = ['objectives', 'myteams', params, groupName, selectedSwitcher];

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isFetching } =
    useInfiniteQuery(key, ({ pageParam }) => fetchTeams(pageParam), {
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || undefined,
      staleTime: 5 * 60 * 1000
    });

  const lastPageData = data?.pages?.[data?.pages?.length - 1];

  const TeamCard = isSquad ? SquadCard : TribeCard;
  const TeamSkeleton = isSquad ? SquadSkeleton : TribeSkeleton;

  useEffect(() => {
    if (data) {
      const newTeams = flatten(
        data?.pages?.map((page) => {
          return page?.data?.map((d) => {
            return d;
          });
        })
      );
      groupName == 'squad' ? setSquads(newTeams) : setTribes(newTeams);
    }
  }, [data, groupName, setSquads, setTribes]);

  useEffect(() => {
    if ((isFetching && !isFetchingNextPage) !== isLoading)
      setIsLoading(isFetching && !isFetchingNextPage);
  }, [isFetching, isFetchingNextPage, setIsLoading, isLoading]);

  useEffect(() => {
    setShowFirstSkeleton(true);
  }, [filter]);

  useEffect(() => {
    if (teams?.length > 0 && !isLoading && firstRender && isContentRendered) {
      setShowFirstSkeleton(true);
      setFirstRender(false);
    }
  }, [teams, isLoading, firstRender, isContentRendered]);

  useEffect(() => {
    if (showFirstSkeleton && !isLoading) {
      setTimeout(() => {
        setShowFirstSkeleton(false);
      }, 250);
    }
  }, [showFirstSkeleton, isLoading]);

  const intersectTarget = createRef();
  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting &&
      hasNextPage &&
      lastPageData?.data?.length === LIMIT &&
      fetchNextPage(),
    threshold: 0.5
  });

  const getContent = () => {
    let subtitle = isSquad
      ? 'The teams establishes a sense of direction that guides your decisions and task to be more aligned with your company mission.'
      : 'Once your tribes assigned to orientate the grouped tasks to better align with your company';

    if (selectedSwitcher == 'archived') {
      subtitle = `Once your ${
        isSquad ? 'squad' : 'tribe'
      } are archived, you’ll see them here.`;
    }

    return {
      title: `You don't have any ${isSquad ? 'squad' : 'tribe'} yet`,
      subtitle
    };
  };

  const content = getContent();

  return (
    <>
      {isVisible && (
        <div className="project-card-view-container !pb-[40px]">
          {teams?.length > 0 &&
            teams.map((team) =>
              showFirstSkeleton ? (
                <TeamSkeleton key={`skeleton-${team?.id}`} />
              ) : (
                <TeamCard
                  team={team}
                  setOpenModalCreate={setOpenModalCreate}
                  key={team?.id}
                />
              )
            )}
          {teams?.length === 0 && (
            <div className="flex items-center justify-center p-[16px] bg-n-000 shadow-raised rounded-[4px]">
              <ListEmptyState
                emptyIcon={isSquad ? 'icon-no-team' : 'icon-no-tribe'}
                containerClassname="h-[auto]"
                size="small"
                title={getObjectiveLocale(content.title)}
                subtitle={getObjectiveLocale(content.subtitle)}
              />
            </div>
          )}
          {isFetchingNextPage && <TeamSkeleton />}
          <div ref={intersectTarget}>
            {isAbleToCreateTeam && teams?.length > 0 && (
              <CardAddProject
                setOpenModalCreate={(open) =>
                  setOpenModalCreate({ show: open, type: groupName })
                }
                title={`New ${startCase(groupName)}`}
                customHeight={groupName == 'tribe' && 'min-h-[308px]'}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TeamCardView;

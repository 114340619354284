import React from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import {
  getNumberLocale,
  getObjectiveLocale,
  parseCaption
} from 'utils/HelperUtils';

import VerifySurveyScheduleCard from './VerifySurveyScheduleCard';

// eslint-disable-next-line @typescript-eslint/no-var-requires
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const TemplateSummary = ({
  title,
  value,
  customValueclass = 'text-n-900',
  onClick,
  children,
  dataCy
}) => {
  return (
    <div className="flex">
      <p className="pr-[16px] py-[16px] typography-h400 text-n-800 w-[216px]">
        {getObjectiveLocale(title) || '-'}
      </p>
      {children ? (
        children
      ) : (
        <div
          className={`pr-[16px] py-[16px] typography-paragraph text-wrap ${customValueclass} ${
            onClick ? 'cursor-pointer' : ''
          }`}
          dangerouslySetInnerHTML={{ __html: value && parseCaption(value) }}
          onClick={onClick}
          data-cy={dataCy}
        />
      )}
    </div>
  );
};

const VerifySurvey = ({ data, totalParticipant, type }) => {
  const history = useHistory();
  const isIndividualSurvey =
    data?.participantType === 'user' || type === 'participant';
  const searchParams = new URLSearchParams(location.search);

  const occurrenceId = searchParams.get('occurrenceId');
  const tab = searchParams.get('tab');
  let currentSearchURL = '';
  if (occurrenceId) {
    currentSearchURL += 'occurrenceId=' + occurrenceId + '&';
  }
  if (tab) {
    currentSearchURL += 'tab=' + tab + '&';
  }

  return (
    <>
      <p className="typography-h500 text-n-900 mb-[16px]">
        {getObjectiveLocale('Summary')}
      </p>
      <TemplateSummary
        title={`${type === 'survey' ? 'Survey' : 'Participant Group'} Name`}
        value={data?.[type === 'survey' ? 'title' : 'name']}
      />
      <TemplateSummary title="Description" value={data?.description || '-'} />
      {/* COMMENT FOR BCA REQUEST */}

      {/* {type === 'survey' && (
        <TemplateSummary
          title="This Survey is"
          value={data?.canBePostponed ? 'Not Required' : 'Required'}
          dataCy="verify-is-required"
        />
      )} */}
      {type === 'survey' && (
        <>
          <TemplateSummary
            title="Thank you message"
            value={data?.thankMessage}
          />
          <TemplateSummary
            title="Questions"
            value={`${getNumberLocale(data?.questions?.length)} question${
              data?.questions?.length > 1 ? 's' : ''
            }`}
            customValueclass="text-base-600"
            onClick={() =>
              history.replace(
                `${location.pathname}?${currentSearchURL}step=questions`
              )
            }
          />
        </>
      )}
      <TemplateSummary
        title="Participants"
        value={`${
          data?.selectAll ? 'All' : getNumberLocale(totalParticipant)
        } ${isIndividualSurvey ? 'participant' : 'group'}${
          totalParticipant > 1 && !data?.selectAll ? 's' : ''
        }`}
        customValueclass="text-base-600"
        onClick={() =>
          history.replace(
            `${location.pathname}?${currentSearchURL}step=participants`
          )
        }
      />
      {type === 'survey' && (
        <TemplateSummary title="Schedule">
          <div
            className="flex flex-col cursor-pointer pt-[16px]"
            onClick={() =>
              history.replace(
                `${location.pathname}?${currentSearchURL}step=schedule`
              )
            }
          >
            <VerifySurveyScheduleCard />
          </div>
        </TemplateSummary>
      )}
    </>
  );
};

export default VerifySurvey;

import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useImmer } from 'use-immer';

import { getListObjectives, getObjectiveDetail } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import useDropdown from 'hooks/useDropdown';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale, toggleDropdown } from 'utils/HelperUtils';
import { getClusters, getIncludeTeamGoalsParams } from 'utils/ObjectivesHelper';

import Button from 'components/design-system/Button';
import ModalUpdateProgress from 'components/modal/ModalUpdateProgress/ModalUpdateProgress';
import ListDropdown from 'components/objectives/ListDropdown';
import SVGIcon from 'components/shared/SVGIcon';
import CardNew from 'components/shared/card/Card';
import Modal from 'components/shared/modal/Modal';

function GoalTreeCard({
  objective,
  setChild,
  type = 'pivot',
  closing = false,
  index
}) {
  const [selfObjective, setSelfObjective] = useImmer(objective);
  const [showChild, setShowChild] = useState(false);
  const [showParent, setShowParent] = useState(false);
  const [updateProgressModal, setUpdateProgressModal] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const getOrganizationIdParams = (objectiveData) => {
    if (objectiveData?.organization?.id) {
      return { organizationId: objectiveData?.organization?.id };
    }
    return {};
  };

  const fetchObjective = () => {
    const params = getOrganizationIdParams(objective);
    return getObjectiveDetail(objective.id, params);
  };

  const getQueryKey = (type, objective) => {
    if (objective?.organization?.id) {
      return [type, objective.id, objective.organization.id];
    }
    return [type, objective.id];
  };

  let { data: objectiveData } = useQuery(
    getQueryKey('objective', objective),
    fetchObjective,
    {
      suspense: false,
      initialData: objective,
      staleTime: Infinity,
      initialStale: type === 'parent'
    }
  );

  const { refetchObjective, refetchSubObjectives, refetchQueries } =
    useRefetchQuery();
  const history = useHistory();
  const { url } = useUrl();
  const ref = useRef();
  useClickOutside(ref, () =>
    toggleDropdown(false, setDropdownAnimation, setOpen)
  );
  const [dropdownAnimation, setDropdownAnimation] = useState(
    'animation-open-dropdown-fadeInDownSlow'
  );

  const { config } = useUser();
  const { includeTeamGoals } = config;

  let childrenCount = selfObjective.childrenCount;
  const clusters = getClusters(objective);
  let goalType = selfObjective.type;
  const noParent =
    !selfObjective.parents || selfObjective?.parents?.length == 0
      ? 'no-parent'
      : '';
  const noChildren = childrenCount === 0 ? 'no-children' : '';
  let permissions = selfObjective.permissions;
  let measurement = selfObjective.measurement;
  const [closeParent, setCloseParent] = useState(false);
  const [closeChild, setCloseChild] = useState(false);
  const {
    useMenu,
    modalConfirmDeleteIsOpen,
    deleteObjective,
    setModalConfirmDeleteIsOpen
  } = useDropdown({ objective, page: 'tree', permissions });
  const listMenu = useMenu({
    except: ['viewTree', 'editReview', 'uncomplete']
  });

  let params = {
    reviewsVisibility: 1,
    parentId: selfObjective.id,
    sortDirection: 'asc',
    sortColumn: 'id',
    ...getOrganizationIdParams(selfObjective)
  };

  const additionalParams = getIncludeTeamGoalsParams(
    includeTeamGoals,
    objective?.team?.id,
    false
  );

  const fetchObjectives = (_) => {
    let newParams = { ...params, ...additionalParams };
    return getListObjectives(newParams);
  };

  let { data: children, status } = useQuery(
    getQueryKey('sub-objectives', selfObjective),
    fetchObjectives,
    {
      suspense: false,
      enabled: showChild,
      forceFetchOnMount: true
    }
  );
  children = children?.data ?? [];

  const showChildren = () => {
    refetchQueries(getQueryKey('sub-objectives', selfObjective));
    if (showChild) {
      setCloseChild(true);
      setTimeout(() => {
        setShowChild(false);
        setCloseChild(false);
      }, 1000);
    } else {
      setShowChild(true);
    }
  };

  const _showParent = () => {
    if (showParent) {
      setCloseParent(true);
      setTimeout(() => {
        setShowParent(false);
        setCloseParent(false);
      }, 1000);
    } else {
      setShowParent(true);
    }
  };

  const updateProgress = (e) => {
    if (!selfObjective?.permissions?.includes('update_current_value')) return;
    e.stopPropagation();
    setUpdateProgressModal(true);
  };

  const closeModalUpdateProgress = () => {
    setUpdateProgressModal(false);
  };

  let autoShowSubGoal = history?.location?.state?.showSubGoal;
  let autoshowParentGoal = history?.location?.state?.showParentGoal;
  useEffect(() => {
    const autoShow = async () => {
      if (selfObjective?.id === autoShowSubGoal && type !== 'parent') {
        refetchSubObjectives(selfObjective.id);
        if (!showChild) {
          setShowChild(true);
        }
      }
    };
    autoShow();
  }, [autoShowSubGoal]);

  useEffect(() => {
    const autoShow = async () => {
      if (selfObjective?.id === autoshowParentGoal && type !== 'children') {
        refetchObjective(selfObjective?.id);
        if (!showParent) {
          setShowParent(true);
        }
      }
    };
    autoShow();
  }, [autoshowParentGoal]);

  const showDetail = (id) => {
    let newUrl;
    let pathname = location.pathname;
    if (pathname.includes('users') && !pathname.includes('objectives')) {
      newUrl = `${url}/objectives/${id}`;
    } else {
      newUrl = `${url}/${id}`;
    }

    let newSearch = new URLSearchParams(location.search);
    if (selfObjective?.organization?.id) {
      newSearch.set('organizationId', selfObjective?.organization?.id);
    } else if (newSearch.has('organizationId')) {
      newSearch.delete('organizationId');
    }

    history.replace({
      pathname: newUrl,
      search: newSearch.toString()
    });
  };

  useEffect(() => {
    if (!objectiveData) return;
    if (
      setChild &&
      !objective?.parents &&
      (objectiveData?.parents || objectiveData?.data?.parents)
    ) {
      setChild((draft) => {
        draft.parents[index].parents =
          objectiveData?.parents ?? objectiveData?.data?.parents;
      });
    }
    setSelfObjective(() => objectiveData?.data ?? objectiveData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveData]);

  useEffect(() => {
    if (!objective?.parents) {
      refetchQueries(getQueryKey('objective', objective));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objective]);

  return (
    <li
      className={`goal-card-container pb-[12px] ${
        closing ? 'animated card-fade-out' : ''
      } position-${type}`}
    >
      {showParent &&
        selfObjective?.parents?.length > 0 &&
        type !== 'children' && (
          <ul
            className="goal-card-list flex items-end"
            style={!showParent ? { display: 'none' } : {}}
          >
            {selfObjective?.parents?.length > 0 &&
              selfObjective?.parents.map((objectiveParent, index) => (
                <GoalTreeCard
                  type="parent"
                  closing={closeParent}
                  objective={objectiveParent}
                  setChild={setSelfObjective}
                  key={index}
                  index={index}
                />
              ))}
          </ul>
        )}

      {selfObjective?.parents?.length > 0 && type !== 'children' && (
        <div className="arrow-button-container">
          <div className="tooltip">
            {!showParent ? 'Show parent' : 'Hide parent'}
          </div>
          <div
            className={`arrow-button ${showParent ? 'card-expand' : ''}`}
            onClick={() => _showParent()}
            data-cy="goal-tree-show-parent"
          >
            <SVGIcon
              size={24}
              fillColor="var(--n-600)"
              iconName={
                showParent
                  ? 'icon-keyboard_arrow_down'
                  : 'icon-keyboard_arrow_up'
              }
              customClass="up-arrow"
            />
          </div>
        </div>
      )}

      <div
        className={`goal-tree-card list-objective ${type} ${noChildren} ${noParent}`}
        data-cy={`goal-tree-card-${selfObjective.id}`}
      >
        <CardNew
          objective={selfObjective}
          withRightIcon
          type="goal"
          isAbleToUpdate
          onClickCard={() => showDetail(selfObjective.id)}
          updateProgress={updateProgress}
          clusters={clusters}
        />

        <div className="inline-buttons">
          <Button.Secondary
            datacy="goal-tree-actions"
            customClass="w-[82px] float-right"
            onMouseEnter={() =>
              toggleDropdown(true, setDropdownAnimation, setOpen)
            }
            onMouseLeave={() =>
              toggleDropdown(false, setDropdownAnimation, setOpen)
            }
          >
            {getObjectiveLocale('Actions')}
            {isOpen && (
              <div ref={ref}>
                <ListDropdown
                  data={listMenu}
                  dropdownAnimation={dropdownAnimation}
                />
              </div>
            )}
          </Button.Secondary>
        </div>
        {childrenCount > 0 && (
          <div className="box-alike-container">
            <div className="box-alike box1"></div>
            <div className="box-alike box2"></div>
          </div>
        )}
        <div className="total-goal-child" data-cy="goal-tree-total-children">
          {childrenCount === 1 && (
            <span>
              {childrenCount} {getObjectiveLocale('Sub Goal')} /{' '}
              {getObjectiveLocale('Task')}
            </span>
          )}
          {childrenCount > 1 && (
            <span>
              {childrenCount} {getObjectiveLocale('Sub Goals')} &{' '}
              {getObjectiveLocale('Tasks')}
            </span>
          )}
        </div>
      </div>

      {childrenCount !== 0 && type !== 'parent' && (
        <div className="arrow-button-container">
          <div className="tooltip">
            {!showChild
              ? getObjectiveLocale('Show child')
              : getObjectiveLocale('Hide child')}
          </div>
          <div
            className="arrow-button"
            onClick={() => showChildren()}
            data-cy="goal-tree-show-children"
          >
            <SVGIcon
              size="24"
              fillColor="var(--n-600)"
              iconName={
                showChild
                  ? 'icon-keyboard_arrow_up'
                  : 'icon-keyboard_arrow_down'
              }
              customClass="down-arrow"
            />
          </div>
        </div>
      )}

      {selfObjective.childrenCount !== 0 &&
        status !== 'loading' &&
        children &&
        type !== 'parent' && (
          <ul
            className={`goal-card-list  ${showChild ? 'card-expand' : ''}`}
            style={!showChild ? { display: 'none' } : {}}
          >
            {children?.map((objectiveChildren, index) => (
              <GoalTreeCard
                type="children"
                closing={closeChild}
                objective={objectiveChildren}
                key={index}
                index={index}
              />
            ))}
          </ul>
        )}

      {updateProgressModal && permissions?.includes('update') && (
        <ModalUpdateProgress
          title="Update Target"
          measurement={measurement}
          withCloseIcon
          eventOnClick={() => closeModalUpdateProgress()}
          canUpdateCurrentValue={permissions?.includes('update_current_value')}
          objectiveId={selfObjective.id}
          objectiveName={selfObjective.name}
          commentOptions={selfObjective.commentOptions}
          modalType="update"
          milestoneType={selfObjective.milestoneType}
          currentMilestone={selfObjective.currentMilestone}
          objectiveValue={selfObjective}
        />
      )}
      {modalConfirmDeleteIsOpen && (
        <Modal
          className="modal-delete-objectives"
          title={getObjectiveLocale(
            `Delete ${goalType === 'goal' ? 'Goal' : 'Task'}`
          )}
          eventOnClickClose={() => {
            setModalConfirmDeleteIsOpen(false);
          }}
          description={getObjectiveLocale(
            `Are you sure you want to delete this ${
              goalType === 'goal' ? 'goal' : 'task'
            }? Your action can’t be reversed.`
          )}
          withPrimaryBtn={{
            danger: true,
            title: getObjectiveLocale('Delete'),
            dataCy: 'goal-confirm-delete-goal',
            onClick: () => deleteObjective()
          }}
          withSecondaryBtn={{
            title: getObjectiveLocale('Cancel'),
            dataCy: 'goal-cancel-delete-goal',
            onClick: (e) => {
              e.stopPropagation();
              setModalConfirmDeleteIsOpen(false);
            }
          }}
        />
      )}
    </li>
  );
}
export default GoalTreeCard;

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { updateConfig } from 'client/admin/Config';
import { useSidebarMenu } from 'context/SidebarMenuContext';
import { useUser } from 'context/UserContext';
import { useValueCompetency } from 'context/admin/ValueCompetencyContext';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Modal from 'components/design-system/Modal';
import InputField from 'components/shared/InputField';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import { ValueCompetencyProvider } from 'src/context/admin/ValueCompetencyContext';

import AddEditCompetencyModel from './AddEditCompetencyModel';
import ValueAndCompetencyDictionary from './ValueAndCompetencyDictionary';
import ValueAndCompetencyModel from './ValueAndCompetencyModel';
import { NotificationValueCompetency } from './ValueCompetencyReuseableComponent';

function ValuesCompetencies({ route: { title, isReadOnly } }) {
  const {
    showListGroupModal,
    getValueOrCompetencyData,
    showNotificationMessage,
    searchValue,
    setSearchValue,
    appendValueOrCompetencyData,
    hasMore,
    setShowInlineCreate,
    currentFilterTab,
    setCurrentFilterTab,
    setConfigValueCompetency,
    dataValueOrCompetency,
    setCurrentTab,
    currentTab,
    setShowListGroupModal,
    resetFunction,
    listGroup
  } = useValueCompetency();
  const { pathname } = useLocation();
  const history = useHistory();

  const usp = new URLSearchParams(window.location.search);
  const state = usp.get('tab');
  const { config } = useUser();
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [addSkill, setAddSkill] = useState(false);
  const [currTab, setCurrTab] = useState(state || 'dictionary');
  const [buttonName, setButtonName] = useState({
    first: `Add New ${getObjectiveLocale('Competency')}`,
    second: `Import ${getObjectiveLocale('Competency')}`
  });

  const { toggleSidebar } = useSidebarMenu();

  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const abortController = new AbortController();

  const handleChangeSkill = async () => {
    let dataS = {
      allowAddSkill: document.getElementById('add-skill').checked
    };
    setAddSkill(document.getElementById('add-skill').checked);
    const { data } = await updateConfig(dataS);

    if (data) {
      showNotificationMessage('Updated Successfully!');
    }
  };

  const handleClickTab = (type) => {
    setCurrTab(type);
    if (pathname.includes('competencies')) {
      switch (type) {
        case 'dictionary':
          setButtonName({
            first: `Add New ${getObjectiveLocale('Competency')}`,
            second: `Import ${getObjectiveLocale('Competency')}`
          });
          break;
        case 'model':
          setButtonName({
            first: `Add New ${getObjectiveLocale('Competency')} Model`,
            second: `Import ${getObjectiveLocale('Competency')} Model`
          });
          break;
      }
    }

    if (pathname.includes('values')) {
      switch (type) {
        case 'dictionary':
          setButtonName({
            first: `Add New ${getObjectiveLocale('Value')}`,
            second: `Import ${getObjectiveLocale('Value')}`
          });
          break;
        case 'model':
          setButtonName({
            first: `Add New ${getObjectiveLocale('Value')} Model`,
            second: `Import ${getObjectiveLocale('Value')} Model`
          });
          break;
      }
    }
    setIsLoading(true);
    setCurrTab(type);
    setCurrentFilterTab('active');
  };

  const inlineCreate = async (id) => {
    if (currTab == 'dictionary') {
      await setShowInlineCreate(id);
    } else {
      let routeName = pathname.includes('competencies')
        ? 'competencies'
        : 'values';
      history.replace(`/add-${routeName}-model`);
      setCurrTab('model');
      setSearchValue('');
    }
  };

  const _getConfig = async () => {
    setAddSkill(config.allowAddSkill);
    setConfigValueCompetency({
      behaviorsFramework: config.behaviorsFramework,
      competencyDictionary: config.competencyDictionary,
      allowAddSkill: config.allowAddSkill,
      competenciesMaxLevel: config.competenciesMaxLevel,
      competenciesUseBasicLevel: config.competenciesUseBasicLevel,
      valuesMaxLevel: config.valuesMaxLevel,
      valuesUseBasicLevel: config.valuesUseBasicLevel,
      recognitionConfig: config.recognitionConfig
    });
  };

  const _getValueOrCompetencyData = async (tab) => {
    setIsLoading(true);
    await getValueOrCompetencyData(pathname, tab);
    setIsLoading(false);
  };

  useEffect(() => {
    _getConfig();
    handleClickTab(state || 'dictionary');

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentTab(state || 'dictionary');
    if (state == 'model') {
      _getValueOrCompetencyData('model');
    } else {
      _getValueOrCompetencyData('dictionary');
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, currTab, currentTab, currentFilterTab]);

  const _onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      setOnLoadMore(true);
      await appendValueOrCompetencyData(pathname);
      setOnLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 100, () => {
        loadMore();
      });
    }
  };

  useEffect(() => {
    toggleSidebar(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerProps = {
    titlePage: getObjectiveLocale(title),
    isHeaderComposer: false,
    primaryAction: !isReadOnly && {
      title: buttonName.first,
      icon: 'icon-add',
      onClick: () => inlineCreate('00'),
      dataCy: `button-create-${currTab}`
    }
  };

  const tabsList = [
    {
      id: 'dictionary',
      name: 'Dictionary'
    },
    {
      id: 'model',
      name: 'Model'
    }
  ];

  const onCloseModal = () => {
    resetFunction();
    setShowListGroupModal(false);
  };

  return (
    <>
      <HeaderPage
        {...headerProps}
        tabs={{
          tabsList: tabsList,
          url: `${pathname.includes('values') ? '/values' : '/competencies'}`,
          onChange: (tab) => {
            handleClickTab(tab);
            setCurrTab(tab);
          }
        }}
      />
      <div
        className="compentencies-wrapper overflow-auto h-[calc(100vh-64px)] bg-n-000 pb-[64px]"
        onScroll={(e) => _onScroll(e)}
      >
        <div className="import-container">
          <div className="value-competency-search-container mt-[24px]">
            <InputField
              dataCy="search-competency-value"
              inputClass="w-[360px]"
              placeholder={`Search ${
                currTab == 'dictionary' ? 'Dictionary' : 'Model'
              }`}
              value={searchValue}
              handleChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>

        <div className="tab-container">
          <div className="wrapper-type-tab">
            <ul className="type-tab pl-[0px]">
              <li
                data-cy="competencies-values-active"
                className={`type ${
                  currentFilterTab == 'active' ? 'active' : ''
                }`}
                onClick={() => setCurrentFilterTab('active')}
              >
                Active
              </li>
              <li
                data-cy="competencies-values-archive"
                className={`type bg-n-000 ${
                  currentFilterTab == 'archive' ? 'active' : ''
                }`}
                onClick={() => setCurrentFilterTab('archive')}
              >
                Archived
              </li>
            </ul>
          </div>

          {pathname.includes('competencies') && currTab == 'dictionary' && (
            <div className="switch-container flex items-center">
              <p className="allow-employees">
                Allow employees to add new skills
              </p>
              <SmallToggleSwitchPurple
                idStr={'add-skill'}
                isChecked={addSkill}
                onChange={handleChangeSkill}
                disabled={isReadOnly}
                addClass="mt-[8px]"
              />
            </div>
          )}
        </div>

        <div className="container-competencies-and-values">
          {isLoading && <LoadingComponent />}

          {!isLoading && currTab == 'dictionary' && (
            <ValueAndCompetencyDictionary isReadOnly={isReadOnly} />
          )}

          {!isLoading && currTab == 'model' && (
            <ValueAndCompetencyModel
              isLoading={isLoading}
              isReadOnly={isReadOnly}
            />
          )}

          {!isLoading && dataValueOrCompetency.length == 0 && (
            <ListEmptyState
              fullHeight
              containerClassname="my-[40px]"
              emptyIcon={
                searchValue
                  ? 'icon-no-result-found'
                  : title === 'Values'
                  ? 'icon-no-value'
                  : 'icon-no-skill'
              }
              size="large"
              title={getObjectiveLocale(
                searchValue ? 'No result found' : 'No data available yet'
              )}
              subtitle={getObjectiveLocale(
                searchValue
                  ? `Did you probably type the keyword incorrectly? We are sorry that what you searched was unfortunately not found.${
                      currentFilterTab == 'active' && !isReadOnly
                        ? '\nBut you can also start to create a new one'
                        : ''
                    }`
                  : 'But you can also start to create a new one'
              )}
              btnText={!isReadOnly && buttonName.first}
              iconBtn="icon-add"
              onClick={() => inlineCreate('00')}
            />
          )}
        </div>

        {showListGroupModal && (
          <Modal onClose={onCloseModal}>
            <Modal.Header
              title={getObjectiveLocale('Used In Groups')}
              onClose={onCloseModal}
            />
            <Modal.Body>
              <div className="w-full px-[24px]">
                {listGroup?.length === 0 ? (
                  <p className="fs-16">
                    {getObjectiveLocale('No data available yet')}
                  </p>
                ) : (
                  <div
                    className="overflow-auto max-h-[400px]"
                    data-cy="competencies-values-modal-group"
                  >
                    {listGroup?.map((name, index) => (
                      <p className="border-bottom-n-400 p-[12px]" key={index}>
                        {name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer useBorder={false} />
          </Modal>
        )}
      </div>
    </>
  );
}

function OrganizationValuesCompetencies(props) {
  const { pathname } = useLocation();
  const { id } = useParams();
  const addEditRoute = [
    '/add-competencies-model',
    '/add-values-model',
    `/edit-competencies-model/${id}`,
    `/edit-values-model/${id}`
  ];

  return (
    <ValueCompetencyProvider>
      <NotificationValueCompetency />
      {addEditRoute.includes(pathname) ? (
        <AddEditCompetencyModel {...props} />
      ) : (
        <ValuesCompetencies {...props} />
      )}
    </ValueCompetencyProvider>
  );
}

export default OrganizationValuesCompetencies;

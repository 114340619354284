import React from 'react';

import PropTypes from 'prop-types';

import './WhiteCardWithFooter.scss';

const interfaces = {
  children: PropTypes.node.isRequired, // .node = multiple elements (tags)
  containerClass: PropTypes.string,
  bodyClass: PropTypes.string,
  withFooter: PropTypes.bool,
  withAbsoluteFooter: PropTypes.bool,
  footerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  absoluteFooterContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

const WhiteCardWithFooter = ({
  footerContent = '',
  absoluteFooterContent = '',
  children,
  containerClass = '',
  withFooter = false,
  bodyClass = '',
  withAbsoluteFooter = false,
  footerClass = ''
}) => {
  return (
    <div className={`overall-objective-container ${containerClass}`}>
      <div className={`overall-objective-body height-auto ${bodyClass}`}>
        {children}
      </div>
      {withFooter && (
        <div
          data-cy="overall-objective-footer"
          className={`overall-objective-footer typography-paragraph ${footerClass}`}
        >
          {footerContent}
        </div>
      )}
      {withAbsoluteFooter && <div>{absoluteFooterContent}</div>}
    </div>
  );
};

WhiteCardWithFooter.propTypes = interfaces;

export default WhiteCardWithFooter;

import React, { useEffect, useRef } from 'react';

import { scrollEvent } from './scrollHelper';

let y = 0,
  startY = 0,
  scrollerX = 0,
  scrollerY = 0;

const initializeScroll = (trackRef) => {
  const thumb = document.querySelector('.scroll-thumb-y');
  const heightTrack = trackRef.current.offsetHeight;
  const scroller = document.querySelector('#main-scroll');
  const scrollerViewHeight = scroller.offsetHeight;
  const scrollWrapper = document.querySelector('#scroll-content');
  let designatedThumbHeight =
    (heightTrack * scrollerViewHeight) / scrollWrapper.offsetHeight;
  if (designatedThumbHeight < 24) {
    designatedThumbHeight = 24;
  }
  // eslint-disable-next-line no-undef
  const matrixScroll = new WebKitCSSMatrix(
    window.getComputedStyle(scrollWrapper).transform
  );
  const scrollPos = Math.abs(matrixScroll.m42);
  const maximumScroll = scrollWrapper.offsetHeight - scrollerViewHeight;
  const scrollPercentageDistance = scrollPos / scrollWrapper.offsetHeight;
  let scrollX = matrixScroll.m41;
  let scrollY = scrollPos * -1;
  if (scrollPos > maximumScroll) {
    scrollY = Math.floor(maximumScroll * -1);
  }
  if (designatedThumbHeight >= heightTrack) {
    thumb.style.height = heightTrack + 'px';
    thumb.style.visibility = 'hidden';
    scrollY = 0;
  } else {
    thumb.style.visibility = 'visible';
    thumb.style.height = designatedThumbHeight + 'px';
  }
  scrollWrapper.style.transform = `translate(${scrollX}px,${scrollY}px)`;
  scrollEvent(Math.abs(scrollX), Math.abs(scrollY));
  thumb.style.transform = `translateY(${Math.floor(
    scrollPercentageDistance * heightTrack
  )}px)`;
};

const ScrollbarVertical = ({ contentWidth }) => {
  const trackRef = useRef(null);

  useEffect(() => {
    if (trackRef.current) {
      const contentTimeline = document.querySelector('.tm-content');

      const initialize = () => initializeScroll(trackRef);
      const observer = new MutationObserver(async (mutationList) => {
        mutationList.forEach(function (mutation) {
          if (mutation.type != 'childList') return;
          initialize();
        });
      });
      observer.observe(contentTimeline, { childList: true, subtree: true });

      window.addEventListener('resize', initialize);

      return () => {
        observer.disconnect();
        window.removeEventListener('resize', initialize);
      };
    }
  }, [trackRef, contentWidth]);

  const mouseMoveHandler = (e) => {
    const distance = e.clientY - y;
    const scroller = document.querySelector('#main-scroll');
    const thumb = document.querySelector('.scroll-thumb-y');
    const track = document.querySelector('.scroll-track-y');
    const scrollWrapper = document.querySelector('#scroll-content');
    const trackHeight = track.offsetHeight;
    const thumbHeight = thumb.offsetHeight;
    const maxDistance = trackHeight - thumbHeight;
    const maxContentHeight = scrollWrapper.offsetHeight - scroller.offsetHeight;

    const scrollPercentageDistance = distance / maxDistance;
    const incrementScroll = scrollPercentageDistance * maxContentHeight;
    const calculateY = startY + distance;
    let finalIncrementScroll = incrementScroll;
    let finalYTransform = calculateY;

    const scrollerYTemp = scrollerY - finalIncrementScroll;
    let finalScrollerTranslateY =
      scrollerYTemp > 0
        ? 0
        : Math.abs(scrollerYTemp) > maxContentHeight
        ? maxContentHeight * -1
        : scrollerYTemp;
    if (calculateY <= 0) {
      finalYTransform = 0;
      finalScrollerTranslateY = 0;
    }
    if (calculateY > maxDistance) {
      finalYTransform = maxDistance;
      finalScrollerTranslateY = maxContentHeight * -1;
    }

    thumb.style.transform = `translateY(${finalYTransform}px)`;
    scrollWrapper.style.transform = `translate(${scrollerX}px,${finalScrollerTranslateY}px)`;
    scrollEvent(Math.abs(scrollerX), Math.abs(finalScrollerTranslateY));
  };

  const mouseUpHandler = () => {
    const thumb = document.querySelector('.scroll-thumb-y');
    thumb.style.removeProperty('user-select');
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  const mouseDownHandler = (e) => {
    const thumb = document.querySelector('.scroll-thumb-y');
    const scroller = document.querySelector('#scroll-content');
    thumb.style.userSelect = 'none';
    y = e.clientY;

    // eslint-disable-next-line no-undef
    const matrix = new WebKitCSSMatrix(
      window.getComputedStyle(thumb).transform
    );

    // eslint-disable-next-line no-undef
    const matrixScroller = new WebKitCSSMatrix(
      window.getComputedStyle(scroller).transform
    );

    startY = matrix.m42;
    scrollerX = matrixScroller.m41;
    scrollerY = matrixScroller.m42;

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  return (
    <div
      className="scroll-track-y absolute right-[2px] h-[calc(100%-95px)] w-[10px] z-[2] top-[81px]"
      ref={trackRef}
    >
      <div
        className="scroll-thumb-y bg-n-400 w-full rounded-full"
        onMouseDown={mouseDownHandler}
      />
    </div>
  );
};

export default ScrollbarVertical;

import React from 'react';

import dayjs from 'dayjs';
import compact from 'lodash/compact';

import { capitalize } from 'utils/HelperUtils';

import Card from 'components/design-system/Card';
import Shimmer from 'components/design-system/shimmer/Shimmer';

const getLabelQuarterly = (date) => {
  const month = new Date(date).getMonth();
  if (month >= 0 && month <= 3) return 'Q1 (Jan - Mar)';
  else if (month >= 4 && month <= 6) return 'Q2 (Apr - Jun)';
  else if (month >= 7 && month <= 9) return 'Q3 (Jul - Sep)';
  else if (month >= 10 && month <= 12) return 'Q4 (Oct - Dec)';
};

const getLabelSemesterly = (date) => {
  const month = new Date(date).getMonth();
  if (month >= 0 && month <= 6) return 'Semester 1';
  else if (month >= 7 && month <= 12) return 'Semester 2';
};

const getListLabel = (data, selectedTimeFrame) => {
  let labels = [];
  data.map((d) => {
    labels.push(
      selectedTimeFrame === 'quarterly'
        ? getLabelQuarterly(d.date)
        : getLabelSemesterly(d.date)
    );
  });
  return labels;
};

const generateXLabel = (data, selectedTimeFrame) => {
  let xLabel = '';
  if (selectedTimeFrame === 'quarterly' || selectedTimeFrame === 'semesterly') {
    xLabel = {
      categories: getListLabel(data, selectedTimeFrame),
      showEmpty: false
    };
  } else {
    xLabel = {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: selectedTimeFrame === 'weekly' ? '%e. %b' : "%b '%y"
      }
    };
    if (selectedTimeFrame === 'weekly' && data.length <= 3)
      xLabel.tickInterval = 1000 * 60 * 60 * 24;
  }
  return xLabel;
};

const generateX = (currData, isUpdateFormat = false, selectedTimeFrame) => {
  if (selectedTimeFrame === 'quarterly') {
    return getLabelQuarterly(currData?.date);
  } else if (selectedTimeFrame === 'semesterly') {
    return getLabelSemesterly(currData?.date);
  } else {
    let formatDate = selectedTimeFrame === 'weekly' ? "DD MMM 'YY" : "MMM 'YY";
    const utcDate = dayjs.utc(currData?.date).toString();
    let date = currData?.date ? new Date(utcDate).getTime() : null;
    const formatedDate = dayjs
      .utc(currData?.date)
      .format(formatDate)
      .toString();
    return isUpdateFormat ? formatedDate : { date, formatedDate };
  }
};

const seriesGenerator = (
  data,
  selectedTimeFrame,
  minimumProgress,
  maximumProgress,
  setMinimumProgress,
  setMaximumProgress
) => {
  const series = [];
  const categories = generateXLabel(data, selectedTimeFrame);

  Object.keys(data?.[0] || {})?.map((name) => {
    if (name !== 'date' && name !== 'percentage') {
      series.push({
        data: [],
        key: name,
        name: capitalize(name),
        dashStyle: name == 'achievement' ? 'solid' : 'shortdash',
        color: name == 'achievement' ? '#5417CF' : '#94A3B8'
      });
    }
  });

  series.map((item) => {
    data.map((item2) => {
      const tempY = parseInt(item2?.[item.key]?.toFixed(2));

      if (setMinimumProgress && tempY < minimumProgress) {
        setMinimumProgress(tempY);
      }
      if (setMaximumProgress && tempY > maximumProgress) {
        setMaximumProgress(tempY);
      }
      const generatedX = generateX(item2, false, selectedTimeFrame);
      let generatedItem = {
        x: generatedX.date ? generatedX.date : generatedX,
        xLabel: generatedX?.formatedDate
          ? generatedX?.formatedDate
          : generatedX,
        y: item2?.[item.key] ? tempY : null,
        timeFrame: item2?.date,
        percentage: item2?.['percentage']
          ? parseInt(item2?.['percentage']?.toFixed(2))
          : 0
      };
      if (
        selectedTimeFrame === 'quarterly' ||
        selectedTimeFrame === 'semesterly'
      ) {
        delete generatedItem.x;
        delete generatedItem.xLabel;
      }
      item.data.push(generatedItem);
    });
  });
  return { series, categories };
};

const changeFormatData = (data, selectedTimeFrame) => {
  return data.map((d) => {
    const date = new Date(d.date);

    if (selectedTimeFrame === 'monthly') {
      date.setDate(1);
    }
    d.date = date.toISOString();
    return d;
  });
};

const SkeletonProgressOvertime = () => {
  return (
    <>
      <div className="px-[40px] py-[16px] flex items-center justify-between bg-n-000 border-b border-t-0 border-l-0 border-r-0 border-n-400 border-solid h-[48px]">
        <Shimmer width="100%" circle />
      </div>
      <Card customClass="mx-[40px] mt-[38px]" isLoading>
        <Shimmer width="100%" height="270px" customClass="rounded" />
      </Card>
      <Card customClass="mt-[24px] mx-[40px] mb-[169px]" isLoading>
        <Shimmer width="100%" height="100px" customClass="rounded" />
      </Card>
    </>
  );
};

export {
  SkeletonProgressOvertime,
  seriesGenerator,
  generateX,
  changeFormatData
};

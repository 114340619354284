import React, { useEffect, useState } from 'react';

import {
  getCustomAttributeList,
  getOneReviewGroup,
  putListReviewGroup
} from 'client/admin/ReviewGroupClient.js';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ContentNavigator from 'components/shared/ContentNavigator';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SearchBar from 'components/shared/SearchBar';
import ToastNotif from 'components/shared/ToastNotif.js';

import AttributeListTable from './AttributeListTable';
import './DefaultTabPerformanceProfile.scss';
import FinalScoreFormula from './FinalScoreFormula';
import ModalCreateEditCustomAttribute from './ModalCreateEditCustomAttribute';

const DefaultTabPerformanceProfile = ({
  isModalCreateAttributeOpen,
  setIsModalCreateAttributeOpen,
  modalAction,
  setModalAction,
  activeSection,
  setActiveSection,
  listNavigatorContents,
  setListNavigatorContents,
  listContents
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [currentFilterTab, setCurrentFilterTab] = useState('active');
  const [currentPage, setCurrentPage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [dataFinal, setDataFinal] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [paginationProps, setPaginationProps] = useState({});

  const [isSuccessState, setIsSuccessState] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 800);
  const [isEnableSetFormula, setIsEnableSetFormula] = useState(
    'divided-by-total-weight'
  );
  const weightSetting = getObjectiveLocale('Weight Setting');

  let params = {
    state: currentFilterTab
  };

  const hasMoreHandler = (recordCount) => {
    setHasMore(recordCount == 10);
  };

  const getConfigAttribute = async () => {
    if (searchValue != '') params.q = searchValue;
    const paramsGroup = {
      groupType: 'review_group'
    };
    setLoading(true);
    // id: 0 => default group
    const { data, pagination } = await getCustomAttributeList(0, params);
    const { data: dataFinal } = await getOneReviewGroup(0, paramsGroup);

    if (data) {
      setDataList(data);
      setDataFinal(dataFinal);
      hasMoreHandler(data.length);
      setIsEnableSetFormula(dataFinal?.finalScoreFormula);
    } else {
      setIsEnableSetFormula('divided-by-total-weight');
    }
    setPaginationProps(pagination);
    setLoading(false);
  };

  const _putFinalScoreFormula = async (formulaKey) => {
    let _params = {
      finalScoreFormula: formulaKey,
      groupType: 'review_group',
      name: 'Default',
      reviewAspectsAttributes: dataFinal?.reviewAspectsAttributes.map(
        (value) => {
          return {
            id: value.id,
            weight: value.weight ? value.weight : 0
          };
        }
      )
    };
    return await putListReviewGroup(0, _params);
  };

  const actionPaginationHandler = async (action) => {
    if (searchValue != '') params.q = searchValue;
    setLoading(true);
    setDataList([]);

    if (action == 'next') {
      params.olderThan = paginationProps?.next?.olderThan;
    } else {
      params.newerThan = paginationProps?.next?.newerThan;
    }

    const { data, pagination } = await getCustomAttributeList(0, params);
    setCurrentPage((page) => (action == 'next' ? page + 1 : page - 1));

    if (data && data.length > 0) {
      setDataList(data);
      setPaginationProps(pagination);
      hasMoreHandler(data.length);
    } else {
      setHasMore(false);
    }

    setLoading(false);
  };

  const showSuccessToast = (
    message = 'Configuration is saved successfully'
  ) => {
    setIsSuccessState(true);
    setSuccessMessage(message);
    setTimeout(() => {
      setIsSuccessState(false);
    }, 4000);
  };

  useEffect(() => {
    getConfigAttribute();
    setCurrentPage(1);
    setActiveSection('final-score-formula');
    setListNavigatorContents(listContents);
  }, [currentFilterTab, debouncedSearchValue]);

  return (
    <>
      <div className="content-navigator-wrapper">
        <ContentNavigator
          additionalOffset={30}
          isIncreaseOffset={true}
          title="CONTENTS"
          activeSection={activeSection}
          listNavigatorContents={listNavigatorContents}
          sectionContent="sectioncontainer"
        />
      </div>
      <div className="flex content-container-nav w-full">
        <div className="w-9/12">
          <ToastNotif showNotif={isSuccessState} message={successMessage} />
          <p className="typography-paragraph mt-[24px] mb-[24px] n-900">
            {getObjectiveLocale(
              'All configuration in this default setting will be applied to organization level and to employees who is not inlcuded in any group. You can define behavior model for spesific employees by creating group.'
            )}
          </p>
          <FinalScoreFormula
            setFormulaFinalScore={setIsEnableSetFormula}
            formulaFinalScore={isEnableSetFormula}
            putData={_putFinalScoreFormula}
          />
          <div id="weight-setting">
            <p className="typography-h500 mb-[4px]">{weightSetting}</p>
            <p className="text-n-800 mb-[16px]">
              {getObjectiveLocale(
                'Define the weight of each scoring system below based an active attributes in default settings.'
              )}
            </p>
            <SearchBar
              placeholder="Search Attribute"
              onChange={(e) => setSearchValue(e.target.value)}
              fullWidth={true}
              dataCy={'search-attribute'}
            />
            <div className="tab-container wrapper-type-tab ">
              <ul className="type-tab pl-[0px]">
                <li
                  data-cy="attribute-status-value-active"
                  className={`type ${
                    currentFilterTab == 'active' ? 'active' : ''
                  }`}
                  onClick={() => setCurrentFilterTab('active')}
                >
                  Active
                </li>
                <li
                  data-cy="attribute-status-value-archive"
                  className={`type bg-n-000 w-[100px] ${
                    currentFilterTab == 'archived' ? 'active' : ''
                  }`}
                  onClick={() => setCurrentFilterTab('archived')}
                >
                  Archived
                </li>
              </ul>
            </div>
            <AttributeListTable
              currentFilterTab={currentFilterTab}
              setCurrentFilterTab={setCurrentFilterTab}
              dataList={dataList}
              setModalAction={setModalAction}
              setIsModalCreateAttributeOpen={setIsModalCreateAttributeOpen}
              setDataEdit={setDataEdit}
              dataEdit={dataEdit}
              isLoading={isLoading}
              showSuccessToast={showSuccessToast}
            />
            <div className="flex justify-end items-center pb-[40px] pt-[24px]">
              <PaginationModule
                pageNumber={currentPage}
                handlePrev={() => actionPaginationHandler('prev')}
                handleNext={() => actionPaginationHandler('next')}
                hasMore={hasMore}
              />
            </div>
          </div>
          {isModalCreateAttributeOpen && (
            <ModalCreateEditCustomAttribute
              setIsModalCreateAttributeOpen={setIsModalCreateAttributeOpen}
              action={modalAction}
              dataEdit={dataEdit}
              showSuccessToast={showSuccessToast}
              refetch={getConfigAttribute}
            />
          )}
        </div>
        <div className="w-3/12"></div>
      </div>
    </>
  );
};

export default DefaultTabPerformanceProfile;

import React from 'react';

function PerformanceReportPerformanceScore({
  score,
  maxScore,
  star,
  description,
  descColor,
  teamRating,
  individualRating,
  marginRight
}) {
  let starStyleSelected = {
    color: 'var(--y-600)',
    fontSize: `16px`
  };

  let bigStartSelected = {
    color: 'var(--y-600)',
    fontSize: `25px`
  };

  let starStyleUnselected = {
    color: 'var(--n-600)',
    fontSize: `25px`
  };

  const stars = [];

  for (let i = 0; i < maxScore; ++i) {
    if (i < Math.floor(star)) {
      stars.push(
        <span key={i}>
          <i className="fa fa-star" style={bigStartSelected} />
        </span>
      );
    } else {
      stars.push(
        <span key={i}>
          <i className="fa fa-star" style={starStyleUnselected} />
        </span>
      );
    }
  }

  return (
    <div
      className={`performance-report-performance-score-container ${
        marginRight ? 'mr-[16px]' : ''
      }`}
    >
      <h2>Performance Score</h2>
      <div className="score-container">
        <div className="score flex flex-col">
          <div className="score-percentage">
            <span>{score}</span>
            <span>/ {maxScore}</span>
          </div>
          <div className="score-container">{stars}</div>
          <p className="mt-[8px] multiline-text-2" style={{ color: descColor }}>
            {description}
          </p>
        </div>

        <div className="description">
          <div className="average-rating-container">
            <div className="average-rating">
              <p>Team</p>
              <span>
                <i className="fa fa-star" style={starStyleSelected} />
                {teamRating || 0}
              </span>
            </div>
            <div className="average-rating">
              <p>Individual</p>
              <span>
                <i className="fa fa-star" style={starStyleSelected} />
                {individualRating || 0}
              </span>
            </div>
          </div>
          <p>Average rating both from team and individual performance</p>
        </div>
      </div>
    </div>
  );
}

PerformanceReportPerformanceScore.defaultProps = {
  score: '-',
  maxScore: 5,
  star: '-',
  description: '-',
  descColor: '#1f7ce8',
  teamRating: '-',
  individualRating: '-'
};

export default PerformanceReportPerformanceScore;

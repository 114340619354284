import React from 'react';

import useFilter from 'hooks/useFilter';
import { getCssVariableValue } from 'utils/HelperUtils';

import Badge from 'components/shared/Badge';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

function ChildrenCount({
  childrenCount,
  isLoadChildren,
  showChildren,
  priority,
  isProject
}) {
  const filter = useFilter(
    (state) =>
      state.filterById?.[isProject ? 'projectDetailBoard' : 'tasksFilterBoard']
  );

  return (
    <TooltipContainer
      show
      text={`Subtask: ${childrenCount}`}
      classContainer={`mt-[8px] whitespace-nowrap ${
        filter?.group === 'phase' && !priority ? 'mr-[8px]' : ''
      }`}
      position="bottom"
    >
      <Badge
        content={childrenCount}
        colorHex={getCssVariableValue('--n-800')}
        bgColorHex={getCssVariableValue('--n-300')}
        iconLeft="icon-alignment"
        defaultPointer
        useEvent
        onClick={() => !isLoadChildren && showChildren()}
      />
    </TooltipContainer>
  );
}

export default ChildrenCount;

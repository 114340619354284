"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteHighlighted = exports.useEditHighlighted = void 0;
const react_query_1 = require("react-query");
const ObjectivesClient_1 = require("client/ObjectivesClient");
const successData = (response) => {
    if (response === null || response === void 0 ? void 0 : response.isSuccess) {
        return response;
    }
    throw response;
};
const useEditHighlighted = (id, options) => {
    return (0, react_query_1.useMutation)(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield (0, ObjectivesClient_1.putHighlightedGoal)(id);
        successData(response);
    }), options);
};
exports.useEditHighlighted = useEditHighlighted;
const useDeleteHighlighted = (id, options) => {
    return (0, react_query_1.useMutation)(() => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield (0, ObjectivesClient_1.deleteHighlightedGoal)(id);
        successData(response);
    }), options);
};
exports.useDeleteHighlighted = useDeleteHighlighted;

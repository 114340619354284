import { queryCache } from 'react-query';

import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

import { logoutUser } from 'client/AuthClient';
import { getENV } from 'utils/HelperUtils';
import { toSnakeCase } from 'utils/caseConverter';

import { Axios } from './Axios';

function client(
  endpoint,
  { body, params, method },
  fileTypeAccept,
  noConvertCase
) {
  const authorizationMethod = getENV('AUTHORIZATION_METHOD');
  const token = localStorage.getItem('bearer');
  const OTP_PASS_TOKEN = getENV('OTP_PASS_TOKEN');

  const headers = {
    'content-type': fileTypeAccept,
    accept: fileTypeAccept,
    ...(authorizationMethod === 'bearer' &&
      token && { Authorization: 'Bearer ' + token }),
    ...(OTP_PASS_TOKEN && { 'OTP-Pass-Token': OTP_PASS_TOKEN })
  };

  let url = getENV('PERFORMANCE_API_HOST');

  const config = {
    url: `${url}/${endpoint}`,
    headers
  };

  if (authorizationMethod === 'cookie') {
    Axios.defaults.withCredentials = true;
  }

  if (!isEmpty(params)) {
    if (!params.q) {
      delete params.q;
    }
    params = !noConvertCase ? toSnakeCase(params) : params;

    const convertedParams = qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: true,
      skipNulls: true
    });

    if (convertedParams) {
      config.url = config.url + '?' + convertedParams;
    }
  }

  if (body) {
    config.data = !noConvertCase ? toSnakeCase(body) : body;
    if (!method) {
      (() => {
        throw 'method must be present';
      })();
    }
  }

  if (method) {
    config.method = method;
  }

  return Axios(config).then(
    response => {
      return { response };
    },
    async error => {
      if (error?.response?.status === 401 && endpoint !== 'v1/auth') {
        queryCache.clear();
        await logoutUser();
        window.location.assign(window.location);
        return Promise.reject({ message: 'Please relogin again.' });
      }
      let messageRejection =
        error?.response?.data?.error?.message ||
        error?.response?.data?.errors?.message;

      if (error?.response?.status === 'ECONNABORTED') {
        messageRejection = 'Time Out Please Try Again';
      } else if (!messageRejection) {
        messageRejection = 'Something went wrong';
      }
      return Promise.reject({ message: messageRejection });
    }
  );
}

export default client;

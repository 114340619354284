import React, { useEffect, useState } from 'react';

import RadioButton from 'src/components/shared/RadioButton';

const ContainerWeightConfig = ({ state, setState, putData, isReadOnly }) => {
  const [active, setActive] = useState(2);
  const config = [
    {
      id: 1,
      description: 'Use percentage weight for each competencies and values'
    },
    { id: 2, description: 'Without percentage weight' }
  ];

  const handleChangeEnableWeight = (id) => {
    let isEnableWeight = id == 1 ? true : false;
    putData && putData(null, null, { useBehaviorWeight: isEnableWeight });

    setActive(id);
    setState(isEnableWeight);
  };

  useEffect(() => {
    setActive(state ? 1 : 2);
  }, [state]);

  return (
    <div id="configure" className="w-full pt-[24px] mb-[40px]">
      <h5 className="typography-h500 mb-[4px]">Configure</h5>
      <p className="text-n-800 mb-[16px]">
        What settings do you want to use in this competency and value model?
      </p>

      {config.map((val) => (
        <div className="wrapper-radio-button" key={val.id}>
          <RadioButton
            dataCy={'config-weight' + val.id}
            size="small"
            id={val.id}
            value={val.description}
            checked={active == val.id}
            onChange={() => handleChangeEnableWeight(val.id)}
            disabled={isReadOnly}
          >
            <span className="ml-[8px]">
              {val.description}{' '}
              {val.id == 2 && <span className="text-n-600">{'(default)'}</span>}
            </span>
          </RadioButton>
        </div>
      ))}
    </div>
  );
};

export default ContainerWeightConfig;

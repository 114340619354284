import React from 'react';

import SVGIcon from 'components/shared/SVGIcon';

const Weight = ({ objectiveDetail }) => {
  const { weight, complexity, type } = objectiveDetail;

  const getWeight = () => {
    if (type == 'task') {
      return `Task Complexity: ${complexity}`;
    } else {
      return `Weight ${weight}%`;
    }
  };

  const getIcon = () => {
    if (type == 'task') {
      return 'icon-speed';
    } else {
      return 'icon-fitness_center';
    }
  };

  return (
    <div className="px-[24px]">
      <div
        className="h-[48px] flex items-center"
        data-cy={`sidebar-detail-${type == 'task' ? 'complexity' : 'weight'}`}
      >
        <SVGIcon
          iconName={getIcon()}
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <span>{getWeight()}</span>
      </div>
    </div>
  );
};

export default Weight;

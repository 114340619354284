import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import useValidationMinMax from 'hooks/useValidationMinMax';
import getListBadge from 'utils/ListBadgeHelper';

import ListLabel from 'components/objectives/ListLabel';
import BannerBox from 'src/components/shared/BannerBox';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

import CompactObjectiveMenu from './CompactObjectiveMenu';

dayjs.extend(relativeTime);

const CompactObjectiveBelow = ({
  objective,
  objectiveConfigs,
  permissions,
  showBadgeObjectiveCount,
  page,
  clusters,
  level,
  showChild,
  isTeamGoalsApproval,
  isReadOnly,
  customListBadge
}) => {
  const { weight } = objective || [];

  const isFadeAnimationEnabled = () => {
    return page !== 'give-review' && page !== 'give-comment';
  };

  const { checkValidationSoftWarning } = useValidationMinMax(
    weight,
    objective?.parent
  );

  const listBadge = getListBadge({
    listBadge: customListBadge
      ? customListBadge
      : [
          'owner',
          'organization',
          'team',
          'goalType',
          'updated',
          'weight',
          'complexity',
          'milestone',
          'labels',
          'history'
        ],
    objective: objective,
    showBadgeObjectiveCount: showBadgeObjectiveCount,
    level: level,
    isReadOnly
  });

  useEffect(() => {
    checkValidationSoftWarning();
  }, [checkValidationSoftWarning]);

  return (
    <>
      <div className="tag-section flex-wrap relative mt-[-4px]">
        <ListLabel data={listBadge} centered={false} />
        {!isReadOnly && (
          <div id={isFadeAnimationEnabled() ? 'button-section' : ''}>
            <CompactObjectiveMenu
              objective={objective}
              objectiveConfigs={objectiveConfigs}
              permissions={permissions}
              page={page}
              clusters={clusters}
              approvalRequired={objectiveConfigs?.approvalRequired}
              isTeamGoalsApproval={isTeamGoalsApproval}
            />
          </div>
        )}
        {objectiveConfigs?.approvalRequired && !showChild && (
          <BannerBox
            text={getObjectiveLocale(
              'This goal must have subgoals and need to be approved by reviewer'
            )}
            type="warning"
            marginClass="mt-[12px] !mb-[0]"
            paddingClass="px-[16px] py-[8px]"
            customClass="!rounded-[8px] w-full h-[40px]"
            dataCy="warning-approval-required"
          />
        )}
      </div>
    </>
  );
};

export default CompactObjectiveBelow;

import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Papa from 'papaparse';

import {
  getDownloadCSV,
  getGroupCMSCulture,
  getGroupIdCMSCulture,
  getGroupPostCountCMSCulture
} from 'client/adminClient.js';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Button from 'components/design-system/Button';
import { listOptionDate } from 'components/shared/Calendar/ListAntdDateHelper';
import Loader from 'components/shared/LoadingComponent';

import { InsightCalendar } from '../admin/Insight-retention/InsightHelpers';

const GroupDetail = ({ route: { title } }) => {
  const [chartAdoption, setChartAdoption] = useState({});
  const [selectedDate, setSelectedDate] = useState([
    dayjs().startOf('week'),
    dayjs().endOf('week')
  ]);

  const [intervalButton, setIntervalButton] = useState({
    growth: {
      day: true,
      week: false,
      month: false,
      selected: 'day'
    }
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isNumber, setIsNumber] = useState(true);

  // add to compare
  const [currentChartCategories, setCurrentChartCategories] = useState([]);

  const [hideAllUsersSeries, setHideAllUsersSeries] = useState(false);

  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(-1);
  const [groupInfo, setGroupInfo] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [groupPosts, setGroupPosts] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // Error Message Group Posts
  const [errorMessageGroupInfo, setErrorMessageGroupInfo] = useState('');
  const [isLoadingGroupInfo, setIsLoadingGroupInfo] = useState(true);
  const [isLoadingGroupPosts, setIsLoadingGroupPosts] = useState(true);
  // pagination
  const [nextCursor, setNextCursor] = useState('');
  const [prevCursor, setPrevCursor] = useState('');
  const [totalOffset, setTotalOffset] = useState(0);
  const [isDisableNext, setIsDisableNext] = useState(true);

  const seriesGenerator = (data, isNumber, name) => {
    const series = [];
    let categories = [];

    Object.keys(data[0]).map((name) => {
      if (isNumber) {
        if (
          name !== 'timeframe' &&
          name !== 'percentage' &&
          name !== 'registered'
        ) {
          Object.keys(data[0][name]).map((item, idx) => {
            series.push({
              name: item.replace('_', ' '), //`All ${name} users`,
              data: [],
              key: name,
              keyName: Object.keys(data[0][name])[idx]
            });
          });
        }
      } else {
        if (name !== 'timeframe' && name === 'percentage') {
          Object.keys(data[0][name]).map((item, idx) => {
            series.push({
              name: item.replace('_', ' '), //'All active users',
              data: [],
              key: name,
              keyName: Object.keys(data[0][name])[idx]
            });
          });
        }
      }
    });

    series.map((item) => {
      data.map((item2) => {
        item.data.push({
          x: new Date(item2.timeframe.start).getTime(),
          y: isNumber
            ? item2[item.key][item.keyName]
            : parseInt(item2[item.key][item.keyName].toFixed(2)),
          timeFrame: item2.timeframe,
          percentage: isNumber
            ? item2['percentage'][item.keyName].toFixed(2) / 1
            : ''
        });
      });

      categories.push(item.keyName);
    });

    return {
      series,
      categories
    };
  };

  const handleQueryGrowth = (range) => {
    setSelectedDate(range);
    const periodBegin = selectedDate[0].toISOString(true);
    const periodEnd = selectedDate[1].toISOString(true);
    const timeUnit = intervalButton.growth.selected;
    const arrayOfFilter = arrayOfFilter;

    setIsLoading(true);
    setIsLoadingGroupPosts(true);
    setSearchQuery('');
    setGroupPosts([]);

    getRetentionData(
      periodBegin,
      periodEnd,
      timeUnit,
      isNumber,
      selectedGroupId
    );
    getGroupPosts('', selectedDate[0], selectedDate[1], selectedGroupId, '', 0);
  };

  const handleChartView = (interval, e) => {
    e.preventDefault();

    const periodBegin = selectedDate[0];
    const periodEnd = selectedDate[1];
    const arrayOfFilter = arrayOfFilter;
    const selectedGroupId = selectedGroupId;

    switch (interval) {
      case 'daily':
        getRetentionData(
          periodBegin,
          periodEnd,
          'day',
          isNumber,
          selectedGroupId
        );
        setIsLoading(true);
        setIntervalButton({
          growth: {
            day: true,
            week: false,
            month: false,
            selected: 'day'
          }
        });
        break;
      case 'weekly':
        getRetentionData(
          periodBegin,
          periodEnd,
          'week',
          isNumber,
          selectedGroupId
        );
        setIsLoading(true);
        setIntervalButton({
          growth: {
            day: false,
            week: true,
            month: false,
            selected: 'week'
          }
        });
        break;
      case 'monthly':
        getRetentionData(
          periodBegin,
          periodEnd,
          'month',
          isNumber,
          selectedGroupId
        );
        setIsLoading(true);
        setIntervalButton({
          growth: {
            day: false,
            week: false,
            month: true,
            selected: 'month'
          }
        });
        break;
      default:
      // >>->>->>-- doin nothing
    }
  };

  const handleSelectType = (e) => {
    const periodBegin = selectedDate[0];
    const periodEnd = selectedDate[1];
    const isNumber = !isNumber;
    const arrayOfFilter = arrayOfFilter;
    const selectedGroupId = selectedGroupId;

    setIsNumber(!isNumber);

    getRetentionData(
      periodBegin,
      periodEnd,
      intervalButton.growth.selected,
      isNumber,
      selectedGroupId
    );
  };

  const chart = useRef();

  const funcHideAllUsersSeries = () => {
    currentChartCategories.shift();

    let chartRef = chart;
    chartRef = chartRef.current.chart.getChart();
    chartRef.series[0].hide();
    // yang dulu
    // let chart = refs.chart.getChart();
    // chart.series[0].hide();

    setCurrentChartCategories(currentChartCategories);
    setHideAllUsersSeries(true);
  };

  const changeSelectedGroup = () => {
    let groupId = document.getElementById('valueGroup').value;
    let timeUnit = intervalButton.growth.selected;

    setSelectedGroupId(groupId);
    setIsLoading(true);
    setIsLoadingGroupPosts(true);
    setIsLoadingGroupInfo(true);
    setGroupPosts([]);

    getGroupInfo(groupId);
    getRetentionData(
      selectedDate[0],
      selectedDate[1],
      timeUnit,
      isNumber,
      groupId
    );
    getGroupPosts(
      searchQuery,
      selectedDate[0],
      selectedDate[1],
      groupId,
      '',
      0
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();

    let searchQuery = document.getElementById('searchUser').value;
    let periodBegin = selectedDate[0];
    let periodEnd = selectedDate[1];
    let selectedGroupId = selectedGroupId;

    setIsLoadingGroupPosts(true);
    setGroupPosts([]);
    setSearchQuery(searchQuery);

    getGroupPosts(searchQuery, periodBegin, periodEnd, selectedGroupId, '', 0);
  };

  const handleNext = () => {
    let periodBegin = selectedDate[0];
    let periodEnd = selectedDate[1];
    let selectedGroupId = selectedGroupId;
    let nextCursor = nextCursor;
    let searchQuery = searchQuery;
    let totalOffset = totalOffset + 10;

    setIsLoadingGroupPosts(true);
    setTotalOffset(totalOffset);

    getGroupPosts(
      searchQuery,
      periodBegin,
      periodEnd,
      selectedGroupId,
      nextCursor,
      totalOffset
    );
  };

  const getRetentionData = async (
    periodBegin,
    periodEnd,
    timeUnit,
    isNumber,
    selectedGroupId
  ) => {
    const params = {
      periodBegin: dayjs(periodBegin).startOf('day').toISOString(true),
      periodEnd: dayjs(periodEnd).endOf('day').toISOString(true),
      timeUnit,
      selectedGroupId
    };
    const { data } = await getGroupPostCountCMSCulture(params);
    const analyticData = data.result;
    const series = seriesGenerator(analyticData, isNumber);
    setCurrentChartCategories(series.categories);
    setChartAdoption({
      chart: {
        style: {
          fontFamily: 'Inter'
        },
        backgroundColor: 'var(--n-200)',
        type: 'column',
        marginTop: 100
      },
      legend: {
        align: 'center',
        verticalAlign: 'top',
        floating: true,
        x: 0,
        y: 30
      },
      title: {
        text: 'Growth'
      },
      xAxis: {
        type: 'datetime',
        labels: {
          rotation: -45
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      tooltip: {
        pointFormatter: function () {
          var point = this;
          return (
            '<span style="color:' +
            point.color +
            '">\u25CF</span> ' +
            point.series.name +
            ': <b>' +
            point.y +
            ' ' +
            (isNumber ? 'users' : '%') +
            '</b><br/>'
          );
        },
        shared: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            color: 'white',
            style: {
              textShadow: '0 0 3px black'
            },
            formatter: function () {
              var point = this;
              return isNumber ? point.y : point.y + '%';
            }
          }
        }
      },
      series: series.series,
      exporting: {
        buttons: {
          contextButton: {
            symbol: Config.asset.general.iconDownload
          }
        }
      }
    });
    setIsLoading(false);

    if (hideAllUsersSeries) {
      funcHideAllUsersSeries();
    }
  };

  const getOrgGroups = async () => {
    const { data } = await getGroupCMSCulture();
    setGroups(data);
    setIsLoadingGroupInfo(false);
    setIsLoading(false);
  };

  const getGroupInfo = async (groupId) => {
    const { data, error } = await getGroupIdCMSCulture(groupId);
    if (data) {
      let groupInfoCurr = {
        name: data.name,
        createdAt: data.createdAt,
        groupType: data.groupType,
        memberCount: data.memberCount,
        adminCount: data.adminCount
      };
      setErrorMessageGroupInfo('');
      setIsLoadingGroupInfo(false);
      setGroupInfo(groupInfoCurr);
    }

    if (error) {
      if (error.code == 404) {
        setIsLoadingGroupInfo(false);
        setErrorMessageGroupInfo(error.message);
        setGroupInfo({});
      } else if (error.code == 403) {
        setIsLoadingGroupInfo(false);
        setErrorMessageGroupInfo(error.message);
        setGroupInfo({});
      }
    }
  };

  const getGroupPosts = async (
    searchQuery,
    periodBegin,
    periodEnd,
    selectedGroupId,
    cursor,
    totalOffset
  ) => {
    let params = {
      searchQuery: searchQuery,
      periodBegin: dayjs(periodBegin).startOf('day').toISOString(true),
      periodEnd: dayjs(periodEnd).endOf('day').toISOString(true),
      groupId: selectedGroupId,
      cursor: cursor
    };

    const { data, error } = await getDownloadCSV(params);
    if (data) {
      let nextCursorUpdate = '';
      let isDisableNext = true;
      let prevData = groupPosts;
      let nextData = data;
      let currentData = prevData.concat(nextData);
      if (data.hasOwnProperty('data')) {
        if (data.data.length !== 0) {
          isDisableNext = false;
        }
      }

      if (data.hasOwnProperty('metadata')) {
        nextCursorUpdate = data.metadata.nextCursor;

        if (data.data.length !== 0) {
          isDisableNext = false;
        }
      }

      setErrorMessage('');
      setGroupPosts(currentData);
      setIsLoadingGroupPosts(false);
      setNextCursor(nextCursorUpdate);
      setPrevCursor(nextCursor);
      setTotalOffset(totalOffset);
      setIsDisableNext(isDisableNext);
    }

    if (error) {
      setIsLoadingGroupPosts(false);
      setErrorMessage(error.message);
    }
  };

  const handleDownloadCSV = async () => {
    let params = {
      userToken: localStorage.getItem('token'),
      searchQuery: searchQuery,
      periodBegin: selectedDate?.[0]?.toISOString(true),
      periodEnd: selectedDate?.[1]?.toISOString(true),
      groupId: selectedGroupId,
      cursor: ''
    };

    const { data } = await getDownloadCSV(params);
    let responseData = data;
    let CSVDataPapa = [];

    responseData.map(function (post, idx) {
      let CSVDataFormat = [];

      let postType = post.post_type;
      let postCaption = '';
      let linkUrl = '';

      switch (postType) {
        case 'thought':
          postCaption = post.thought.caption;
          break;
        case 'media':
          postCaption = post.media.caption;
          linkUrl = post.media.secure_url;
          break;
        case 'link':
          postCaption = post.link.caption;
          linkUrl = post.link.url_link;
          break;
        case 'feeling':
          postCaption = post.feeling.caption;
          break;
        case 'recognition':
          postCaption = post.recognition.caption;
          break;
        case 'poll':
          postCaption = post.poll.caption;
          break;
        case 'event':
          postCaption = post.event.caption;
          break;
        case 'attachment':
          postCaption = post.attachment.caption;
          break;
        default:
          break;
      }

      let date = dayjs(post.created_at).format('DD/MM/YYYY');
      let fullName = post.user.full_name;
      let email = post.user.email;
      postCaption = postCaption;
      let viewersCount = post.viewers_count;
      let loversCount = post.lovers_count;
      let commentsCount = post.comments_count;
      let label = post.label ? post.label.title : '';

      CSVDataFormat.push(idx + 1);
      CSVDataFormat.push(date);
      CSVDataFormat.push(fullName);
      CSVDataFormat.push(email);
      CSVDataFormat.push(postCaption);
      CSVDataFormat.push(linkUrl);
      CSVDataFormat.push(postType);
      CSVDataFormat.push(viewersCount);
      CSVDataFormat.push(loversCount);
      CSVDataFormat.push(commentsCount);
      CSVDataFormat.push(label);

      CSVDataPapa.push(CSVDataFormat);
    });

    let CSVData = Papa.unparse({
      fields: [
        'No.',
        'Published Date',
        'Owner',
        'Email',
        'Post',
        'Item',
        'Type',
        'Reach (views)',
        'Like',
        'Comment',
        'Label'
      ],
      data: CSVDataPapa
    });

    let groupName = groupInfo.name || '';

    let fileName = 'Group_' + groupName + '_Posts' + '.csv';
    let file;

    if (!CSVData.match(/^data:text\/csv/i)) {
      file = `data:text/csv;charset=utf-8,${CSVData}`;
    }

    const dataCSV = encodeURI(file);
    const link = document.createElement('a');

    link.setAttribute('href', dataCSV);
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 1500);
  };

  useEffect(() => {
    getOrgGroups();
    getRetentionData(
      selectedDate[0],
      selectedDate[1],
      'day',
      isNumber,
      selectedGroupId
    );
    getGroupPosts(
      searchQuery,
      selectedDate[0],
      selectedDate[1],
      selectedGroupId,
      '',
      0
    );
  }, [selectedDate]);

  const headerProps = {
    titlePage: title,
    primaryAction: {
      title: 'CSV',
      icon: 'icon-file_download',
      onClick: (e) => handleDownloadCSV(e)
    }
  };

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="admin-culture">
        <div
          id="dashboard_content"
          className="col-xs-12 group-detail-container"
        >
          <div className="dashboard-content-main retention">
            <div className="row">
              <div className="col-xs-12">
                <div className="card-body-chart">
                  <h4 className="body-header mt-[24px]">
                    {getObjectiveLocale('Group')}{' '}
                    <i className="fa fa-question-circle-o"></i>
                  </h4>

                  <div div className="row">
                    <div className="col-xs-6">
                      <div className="choose-group">
                        <label
                          htmlFor="selectValue"
                          className="select-value-hour"
                        >
                          <select
                            className="select-dropdown"
                            id="valueGroup"
                            onChange={changeSelectedGroup}
                          >
                            {groups &&
                              groups.map(function (group, index) {
                                return (
                                  <option key={index} value={group.id}>
                                    {group.name}
                                  </option>
                                );
                              })}
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className="col-xs-6"></div>
                  </div>

                  {isLoadingGroupInfo ? (
                    <Loader />
                  ) : Object.keys(groupInfo).length === 0 &&
                    groupInfo.constructor === Object ? (
                    errorMessageGroupInfo === '' ? (
                      ''
                    ) : (
                      <div className="row">
                        <div className="col-xs-12">
                          <p className="label-group">{errorMessageGroupInfo}</p>
                        </div>
                      </div>
                    )
                  ) : (
                    <span>
                      <div className="row">
                        <div className="col-xs-12">
                          <p className="label-group-info">
                            {getObjectiveLocale('Group Name')}
                          </p>
                          <p className="label-group">{groupInfo.name}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xs-2">
                          <p className="label-group-info">Created</p>
                          <p className="label-group">
                            {dayjs(groupInfo.createdAt).format('DD MMM YYYY')}
                          </p>
                        </div>

                        <div className="col-xs-3">
                          <p className="label-group-info">
                            {getObjectiveLocale('Group Status')}
                          </p>
                          <p className="label-group">
                            {groupInfo.groupType.replace('_', ' ')}
                          </p>
                        </div>

                        <div className="col-xs-2">
                          <p className="label-group-info">
                            {getObjectiveLocale('Members')}
                          </p>
                          <p className="label-group">{groupInfo.memberCount}</p>
                        </div>

                        <div className="col-xs-2">
                          <p className="label-group-info">
                            {getObjectiveLocale('Group Admin')}
                          </p>
                          <p className="label-group">{groupInfo.adminCount}</p>
                        </div>
                      </div>
                    </span>
                  )}

                  <br />
                  <br />

                  <h4 className="body-header">
                    {getObjectiveLocale('Engagement Details')}{' '}
                    <i className="fa fa-question-circle-o"></i>
                  </h4>

                  <div className="flex justify-between mb-[24px]">
                    <div className="container-filter-period flex flex-col">
                      <div className="btn-group mb-[24px]">
                        <div
                          className={
                            'btn btn-chartView ' +
                            (intervalButton.growth.day ? 'is-active' : '')
                          }
                          onClick={(e) => handleChartView('daily', e)}
                        >
                          {getObjectiveLocale('Day')}
                        </div>
                        <div
                          className={
                            'btn btn-chartView ' +
                            (intervalButton.growth.week ? 'is-active' : '')
                          }
                          onClick={(e) => handleChartView('weekly', e)}
                        >
                          {getObjectiveLocale('Week')}
                        </div>
                        <div
                          className={
                            'btn btn-chartView ' +
                            (intervalButton.growth.month ? 'is-active' : '')
                          }
                          onClick={(e) => handleChartView('monthly', e)}
                        >
                          {getObjectiveLocale('Month')}
                        </div>
                      </div>
                    </div>
                    <InsightCalendar
                      endDate={dayjs(selectedDate[1])
                        .format('D MMM YYYY')
                        .toString()}
                      startDate={dayjs(selectedDate[0])
                        .format('D MMM YYYY')
                        .toString()}
                      selectedDate={selectedDate}
                      handleSelectDate={(range) => handleQueryGrowth(range)}
                      listOptionsTime={listOptionDate([
                        'This Week',
                        'Last Week',
                        'This Month',
                        'Last Month'
                      ])}
                    />
                  </div>

                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="chart-box">
                      <HighchartsReact
                        highcharts={Highcharts}
                        ref={chart}
                        options={chartAdoption}
                      ></HighchartsReact>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="hr dashboard_content_main">
              <div className="row table-menu">
                <div className="search-container-cms-left col-xs-6">
                  <div className="info">
                    <span className="mb-[1px]">
                      <strong style={{ fontWeight: '500' }}>
                        {getObjectiveLocale('Group Insight Table')}
                      </strong>
                    </span>
                  </div>
                </div>

                <div className="search-container-cms-right col-xs-6">
                  <form
                    className="commentForm flex"
                    onSubmit={(e) => handleSearch(e)}
                  >
                    <input
                      id="searchUser"
                      type="text"
                      placeholder="Search Post..."
                      className="form-control"
                    />
                    <Button type="submit">{getObjectiveLocale('Go')}</Button>
                  </form>
                </div>
              </div>

              <table className="table-list-employee">
                <thead>
                  <tr>
                    <th>{getObjectiveLocale('No')}</th>
                    <th>{getObjectiveLocale('Published Date')}</th>
                    <th>{getObjectiveLocale('Owner')}</th>
                    <th>{getObjectiveLocale('Email')}</th>
                    <th>{getObjectiveLocale('Post')}</th>
                    <th>{getObjectiveLocale('Item')}</th>
                    <th>{getObjectiveLocale('Type')}</th>
                    <th>{getObjectiveLocale('Reach (views)')}</th>
                    <th>{getObjectiveLocale('Like')}</th>
                    <th>{getObjectiveLocale('Comment')}</th>
                    <th>{getObjectiveLocale('Label')}</th>
                  </tr>
                </thead>
                <tbody>
                  {errorMessage !== '' ? (
                    <tr>
                      <td colSpan="11">
                        <div style={{ textAlign: 'center' }}>
                          {errorMessage}
                        </div>
                      </td>
                    </tr>
                  ) : groupPosts.length === 0 ? (
                    <tr>
                      <td colSpan="11">
                        <div style={{ textAlign: 'center' }}>
                          {getObjectiveLocale('No Data')}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    groupPosts.map(function (post, idx) {
                      let postType = post.post_type;
                      let postCaption = '';
                      let linkUrl = '';

                      switch (postType) {
                        case 'thought':
                          postCaption = post.thought.caption;
                          break;
                        case 'media':
                          postCaption = post.media.caption;
                          linkUrl = post.media.secure_url;
                          break;
                        case 'link':
                          postCaption = post.link.caption;
                          linkUrl = post.link.url_link;
                          break;
                        case 'feeling':
                          postCaption = post.feeling.caption;
                          break;
                        case 'recognition':
                          postCaption = post.recognition.caption;
                          break;
                        case 'poll':
                          postCaption = post.poll.caption;
                          break;
                        case 'event':
                          postCaption = post.event.caption;
                          break;
                        case 'attachment':
                          postCaption = post.attachment.caption;
                          break;
                        default:
                          break;
                      }

                      let date = dayjs(post.created_at)
                        .utc()
                        .format('DD/MM/YYYY');
                      let fullName = post.user.full_name;
                      let email = post.user.email;
                      postCaption = postCaption;
                      let viewersCount = post.viewers_count;
                      let loversCount = post.lovers_count;
                      let commentsCount = post.comments_count;
                      let label = post.label ? post.label.title : '';

                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{date}</td>
                          <td>{fullName}</td>
                          <td>{email}</td>
                          <td>
                            <div className="truncate">{postCaption}</div>
                          </td>
                          <td>
                            {linkUrl ? (
                              <a href={linkUrl} target="_blank">
                                Link
                              </a>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>{postType}</td>
                          <td>{viewersCount}</td>
                          <td>{loversCount}</td>
                          <td>{commentsCount}</td>
                          <td>
                            <strong>{label}</strong>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>

              <div className="table-footer row">
                {isLoadingGroupPosts ? (
                  <div className="col-xs-12">
                    <div>
                      <Loader />
                    </div>
                  </div>
                ) : (
                  <div className="col-xs-12 btn-flex">
                    <Button onClick={handleNext} disable={isDisableNext}>
                      Load more
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupDetail;

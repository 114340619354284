import React from 'react';

import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/design-system/Avatar';
import Modal from 'components/shared/modal/Modal';

export const ModalDependencyInfo = ({
  type,
  _handleActionModal,
  dependencies,
  cycleDetail
}) => {
  const dateFormatter = (date) => dayjs(date).format('D MMM YYYY');

  return (
    <Modal
      title={getObjectiveLocale('Dependency Info')}
      minWidth="900"
      eventOnClickClose={() => _handleActionModal()}
      withFooter={false}
      dataCyModal={type}
    >
      <div className="flex flex-row items-center">
        <p className="typography-paragraph text-n-800">
          <span className="typography-h400 text-n-900">
            {cycleDetail?.name}{' '}
          </span>
          ·{' '}
          {getObjectiveLocale(startCase(dependencies?.formalReviewPhase?.type))}{' '}
          · {dateFormatter(dependencies?.formalReviewPhase?.startsAt)} -{' '}
          {dateFormatter(dependencies?.formalReviewPhase?.endsAt) || 'Now'}
        </p>
      </div>
      <div className="flex flex-row items-center">
        <p className="typography-paragraph text-n-800">
          {getObjectiveLocale(
            'Assignment(s) must be completed before reviewer can start reviewing'
          )}
          &nbsp;
        </p>
        <p className="typography-h400 text-n-900">{cycleDetail?.name}.</p>
      </div>
      <div className="w-full flex justify-between items-center py-[10px] mt-[16px] border-b border-0 border-solid border-n-300">
        <p className="typography-h300 text-n-800 min-w-[252px] pr-[32px]">
          {getObjectiveLocale('Cycle')}
        </p>
        <p className="typography-h300 text-n-800 min-w-[368px] pr-[32px]">
          {getObjectiveLocale('Phase')}
        </p>
        <p className="typography-h300 text-n-800 min-w-[168px]">
          {getObjectiveLocale('Status')}
        </p>
      </div>
      <div className="pt-[8px]">
        {dependencies?.assignmentDependencies?.map((dependency) => (
          <div
            className="w-full flex justify-between items-center py-[8px]"
            key={dependency?.key}
            data-cy={`dependency-${dependency?.id}`}
          >
            <div className="flex flex-col min-w-[252px] mr-[32px]">
              <p className="typography-h400 text-n-900">
                {startCase(dependency?.cycle?.name)}
              </p>
              <p className="typography-h100 text-n-800">
                {dateFormatter(dependency?.cycle?.reviewedPeriodStartsAt)} -{' '}
                {dateFormatter(dependency?.cycle?.reviewedPeriodEndsAt)}
              </p>
            </div>
            <div className="flex flex-row min-w-[252px] mr-[32px] grow">
              <Avatar
                size="32"
                customClass="mr-[16px]"
                src={dependency?.actor?.profilePicture}
                name={dependency?.actor?.name}
              />
              <div className="flex flex-col">
                <p className="typography-h400 text-n-900">
                  {startCase(dependency?.phase?.name)}
                </p>
                <p className="typography-h100 text-n-800">
                  {startCase(dependency?.actor?.name)} -{' '}
                  {startCase(dependency?.actorPlacement?.name)}
                </p>
              </div>
            </div>
            <p
              className={`
              typography-paragraph min-w-[168px]
              ${
                startCase(dependency.state) == 'In Progress'
                  ? 'text-y-700'
                  : 'text-g-700'
              }
            `}
            >
              {startCase(dependency.state)}
            </p>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ModalDependencyInfo;

import { create } from 'zustand';

const defaultData = {
  id: null,
  name: '',
  description: '',
  includes: [],
  excludes: [],
  selectAll: false,
  expiration: ''
};

const useParticipantTeam = create((set) => ({
  participantData: defaultData,
  totalParticipant: 0,
  setParticipantData: (data) => set({ participantData: data }),
  setTotalParticipant: (data) => set({ totalParticipant: data }),
  initializeData: () =>
    set({ participantData: defaultData, totalParticipant: 0 })
}));

export default useParticipantTeam;

import React, { useState } from 'react';

import 'emoji-mart/css/emoji-mart.css';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';

import {
  deleteComment,
  getListUser,
  getSeeMoreComment,
  submitComment,
  submitReplyComment
} from 'client/CultureClient';
import { useReload } from 'context/ReloadContext';
import Config from 'utils/Config';
import { getCultureLocale } from 'utils/HelperUtils';

import MentionEditorWithEmoticon from 'components/design-system/MentionEditorWithEmoticon';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';
import 'src/scss/web/editorStyles';
import { trackEvent } from 'src/utils/AnalyticUtils';

import PostAction from './PostAction';
import PostCommentItem from './PostCommentItem';

function PostComment({ currentFeed, groupDetail }) {
  const totalComment = currentFeed?.visibleCommentsCount;
  const postId = currentFeed?.id;
  const comments = currentFeed?.comments.sort(
    (prev, next) => new Date(prev.createdAt) - new Date(next.createdAt)
  );
  const { reload } = useReload();
  const [currentComments, setCurrentComments] = useState(comments);
  const [replyFor, setReplyFor] = useState([]);
  const [totalShowComment, setTotalShowComment] = useState(
    totalComment >= 3 ? 3 : totalComment
  );
  const [lastIdComment, setLastIdComment] = useState(
    comments?.length > 0 ? comments?.[0].id : 0
  );
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [toastData, setToastData] = useState({ show: false, error: '' });
  const [isShowModal, setIsShowModal] = useState(false);
  const [triggerReply, setTriggerReply] = useState(false);
  const [focusTrigger, setFocusTrigger] = useState(false);
  const [clearEditor, setClearEditor] = useState(false);
  const [suggestions, setSuggestion] = useState([]);
  const [previousCommentsCount, setPreviousCommentsCount] = useState('');
  const [selectedComment, setSelectedComment] = useState({
    content: '',
    index: 0,
    id: 0,
    parentIndex: 0,
    parentId: 0,
    repliesData: [],
    setRepliesData: () => {}
  });

  const seeMoreComment = async () => {
    setOnLoadMore(true);

    const params = {
      limit: 6,
      lastId: lastIdComment
    };

    const { data, pagination, previous_comments_count } =
      await getSeeMoreComment(postId, params);
    const sortedData = data.sort(
      (prev, next) => new Date(prev.createdAt) - new Date(next.createdAt)
    );
    setPreviousCommentsCount(previous_comments_count);
    setCurrentComments([...sortedData, ...currentComments]);
    setTotalShowComment(totalShowComment + data.length);
    setLastIdComment(pagination.next.olderThan);
    setOnLoadMore(false);
  };

  const getTextMoreComment = () => {
    let textMoreButton;
    if (totalShowComment == 3) {
      textMoreButton = `Show ${currentFeed?.previousCommentsCount} previous ${
        currentFeed?.previousCommentsCount == 1 ? 'comment' : 'comments'
      }`;
    } else if (totalShowComment > 3) {
      textMoreButton = `Show ${previousCommentsCount} previous ${
        previousCommentsCount == 1 ? 'comment' : 'comments'
      }`;
    }
    return textMoreButton;
  };

  const _submitComment = async (comment, mentions) => {
    const parentId = replyFor?.parentId ? replyFor?.parentId : replyFor?.id;
    const requestBody = {
      comment: {
        content: comment,
        metaMentions: mentions,
        parentId: parentId
      }
    };
    const functionComment = parentId
      ? await submitReplyComment(postId, parentId, requestBody)
      : await submitComment(postId, requestBody);
    const { data, isSuccess, error } = functionComment;

    if (isSuccess) {
      if (parentId) {
        setReplyFor([]);
        const updatedComment = currentComments.find(
          (comm) => comm?.id === parentId
        );
        updatedComment.childrenCount = updatedComment.childrenCount
          ? updatedComment.childrenCount + 1
          : 1;
        const toBeCurrentComment = merge(currentComments, { updatedComment });
        setCurrentComments(toBeCurrentComment);
        reload({ reloadCommentReply: { id: parentId, replyData: data } });
      } else {
        setCurrentComments([...currentComments, data]);
        reload({ reloadCommentCountPost: { postId: postId } });
      }

      const properties = {
        'post type': currentFeed?.postTypeV2,
        'post id': postId,
        'group name': groupDetail.name,
        'group type': groupDetail.groupType
      };

      trackEvent({
        event: 'comment',
        eventProperties: properties,
        env: 'culture'
      });
    } else {
      setToastData({ show: true, error: error?.message });
      setTimeout(() => {
        setToastData({ show: false, error: '' });
      }, 500);
    }
    return isSuccess;
  };

  const getListMentionUser = async (input) => {
    const params = {
      q: input,
      limit: 10
    };
    const { data } = await getListUser(groupDetail.id, params);
    const readablePluginData = data?.map((currentData) => {
      const newData = {
        id: currentData.id,
        name: currentData.fullName,
        title: currentData.title,
        avatar: currentData.profilePicture
          ? currentData.profilePicture.secureUrl
          : Config.asset.general.defaultPicture
      };
      return newData;
    });
    setSuggestion(readablePluginData);
  };

  const clearReplyFor = () => {
    setReplyFor({});
    setClearEditor(true);
  };

  const reloadAfterEditDelete = (type) => {
    const tempCommentsData = cloneDeep(
      selectedComment?.parentId ? selectedComment?.repliesData : currentComments
    );
    if (type === 'edit') {
      tempCommentsData[selectedComment?.index].content =
        selectedComment?.content;
      tempCommentsData[selectedComment?.index].edited = true;
    } else if (type === 'delete') {
      tempCommentsData.splice(selectedComment?.index, 1);
      if (selectedComment?.parentId) {
        const tempCurrentComments = cloneDeep(currentComments);
        tempCurrentComments[selectedComment?.parentIndex].childrenCount--;
        setCurrentComments(tempCurrentComments);
      }
    }
    selectedComment?.parentId
      ? selectedComment?.setRepliesData(tempCommentsData)
      : setCurrentComments(tempCommentsData);
  };

  const _deleteComment = async () => {
    const { isSuccess } = await deleteComment(postId, selectedComment?.id);
    if (isSuccess) {
      reloadAfterEditDelete('delete');
    }
    setIsShowModal(false);
  };

  return (
    <>
      <ToastNotif
        showNotif={toastData?.show}
        message={toastData?.error}
        warning
      />
      {isShowModal && (
        <Modal
          title="Delete Comment"
          className="w-[400px]"
          description="Are you sure you want to delete this comment?"
          eventOnClickClose={() => setIsShowModal(false)}
          withPrimaryBtn={{
            title: 'Delete',
            onClick: () => {
              _deleteComment();
            },
            dataCy: 'modal-delete-button',
            danger: true
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            onClick: () => setIsShowModal(false),
            dataCy: 'modal-cancel-button'
          }}
        />
      )}
      {currentFeed?.postTypeV2 !== 'poll' &&
        currentFeed?.postTypeV2 !== 'feeling' && (
          <PostAction
            currentFeed={currentFeed}
            postId={postId}
            groupDetail={groupDetail}
            focus={() => setFocusTrigger(true)}
          />
        )}
      {currentFeed?.commentable && (
        <div className="feed-comment" data-cy={`post-comment-${postId}`}>
          {currentComments?.length > 0 && (
            <div className="comment-list">
              {totalComment > totalShowComment && (
                <div
                  className="see-more-comment typography-h100 text-n-600 px-[16px]"
                  onClick={seeMoreComment}
                >
                  {getCultureLocale(getTextMoreComment())}
                  {onLoadMore && (
                    <span>
                      <LoadingComponent hasText={false} />
                    </span>
                  )}
                </div>
              )}
              {currentComments?.map((comment, index) => (
                <PostCommentItem
                  comment={comment}
                  key={index}
                  commentIndex={index}
                  onClickReply={(id) => {
                    setReplyFor({ ...comment, id });
                    setTriggerReply(comment);
                  }}
                  lastComment={currentComments.length - 1 === index}
                  postId={postId}
                  selectedComment={selectedComment}
                  setSelectedComment={setSelectedComment}
                  setIsShowModal={setIsShowModal}
                  reloadAfterEditDelete={reloadAfterEditDelete}
                />
              ))}
            </div>
          )}
          {!isEmpty(replyFor) && (
            <div className="h-[40px] bg-n-200 px-[16px] py-[8px] flex items-center justify-between">
              <p
                className="typography-paragraph text-n-600"
                data-cy="reply-to-marker"
              >
                {getCultureLocale('Reply to')} {replyFor?.user?.fullName}
              </p>
              <SVGIcon
                size="24"
                iconName="icon-clear"
                fillColor="var(--n-600)"
                dataCy="clear-reply"
                onClick={() => clearReplyFor()}
              />
            </div>
          )}
          <MentionEditorWithEmoticon
            submitData={_submitComment}
            autoFilled={triggerReply}
            setAutoFilled={setTriggerReply}
            clearEditor={clearEditor}
            setClearEditor={setClearEditor}
            autoFocus={focusTrigger}
            setAutoFocus={setFocusTrigger}
            placeholder={getCultureLocale('Write Comment')}
            useSendButton={true}
            useNewLineShortcut={true}
            wrapperClassName="rounded-[4px] border-solid border border-n-400"
            onSearchChange={getListMentionUser}
            suggestions={suggestions}
          />
        </div>
      )}
    </>
  );
}

export default PostComment;

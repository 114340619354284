import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  editTrackTemplate,
  getDetailTrackTemplate,
  postTrackTemplate
} from 'client/TrackTemplateClient.js';
import { getCustomAttributeList } from 'client/admin/ReviewGroupClient.js';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import BannerBox from 'components/shared/BannerBox';
import Checkbox from 'components/shared/Checkbox';
import Footer from 'components/shared/Footer';
import Toggle from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import Modal from 'components/shared/modal/Modal';
import ContentNavigator from 'src/components/shared/ContentNavigator';

import AdditionalQuestion from './AdditionalQuestion';
import {
  setParamTrackType,
  setTitleTrackTemplate,
  titleTrackTemplate
} from './ConfigurationSettingTrackTemplate';
import ConfigureMultipleSection from './ConfigureMultipleSection';
import ConfigureSection from './ConfigureSection';
import NameSection from './NameSection';

function CreateTrackTemplate() {
  const history = useHistory();
  const match = useRouteMatch();
  let currentTrackType = match.params.trackType;
  const [templateData, setTemplateData] = useState({
    name: '',
    ratingTemplateId: null,
    trackType: currentTrackType,
    config: {
      minSelection: 1,
      maxSelection: 2
    }
  });

  const [showModal, setShowModal] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [activeSection, setActiveSection] = useState('template-name');
  const [listNavigatorContents, setListNavigatorContents] = useState([]);
  const [customAttributes, setCustomAtrributes] = useState(null);

  const defaultQuestion = {
    question: '',
    questionType: 'rating',
    needComment: 'no',
    order: null,
    visibility: 'open',
    optionsLabelVisibility: true,
    options: [
      { option: 'Below Expectation' },
      { option: 'Meet Expectation' },
      { option: 'Almost Meet Expectation' },
      { option: 'Exceed Expectation' }
    ]
  };

  const defaultSection = {
    name: '',
    order: null,
    questions: [defaultQuestion],
    position: 'after'
  };

  setParamTrackType(currentTrackType);
  setTitleTrackTemplate(currentTrackType);

  const setData = (value, prefix1, prefix2, index, subIndex, sectionIndex) => {
    const newData = { ...templateData };
    if (
      (prefix2 == 'minSelection' || prefix2 == 'maxSelection') &&
      value > 255
    ) {
      return;
    }

    if (!prefix2) {
      newData[prefix1] = value;
    } else if (prefix1 == 'questions') {
      if (prefix2 !== 'options') {
        newData.sections[sectionIndex][prefix1][index][prefix2] = value;
        if (prefix2 == 'questionType') {
          if (value !== 'comments') {
            if (
              newData.sections[sectionIndex][prefix1][index]?.options
                ?.length === 0
            ) {
              newData.sections[sectionIndex][prefix1][index].options =
                defaultQuestion?.options;
            } else {
              newData.sections[sectionIndex][prefix1][index]?.options?.map(
                (option) => {
                  option?.id && delete option?.id;
                }
              );
            }
          }
          newData.sections[sectionIndex][prefix1][index]?.id &&
            delete newData.sections[sectionIndex][prefix1][index]?.id;
        }
      } else {
        let newOptions = [
          ...newData.sections[sectionIndex][prefix1][index][prefix2]
        ];
        newOptions[subIndex].option = value;
        newData.sections[sectionIndex][prefix1][index][prefix2] = [
          ...newOptions
        ];
      }
    } else if (prefix1 == 'sections' && prefix2 == 'position') {
      newData[prefix1][index][prefix2] = value;
    } else {
      newData[prefix1][prefix2] = value;
    }
    setTemplateData(newData);
  };

  const handleAddQuestion = (sectionIndex) => {
    if (templateData.sections[sectionIndex].questions.length <= 29) {
      let newData = { ...templateData };
      newData.sections[sectionIndex].questions.push(defaultQuestion);
      setTemplateData(newData);
    }
  };

  const handleAddSection = (sectionIndex) => {
    let newData = { ...templateData };
    newData.sections.splice(sectionIndex + 1, 0, defaultSection);
    setTemplateData(newData);
  };

  const addOptionTemplate = (sectionIndex, questionIndex) => {
    let newData = { ...templateData };
    let newOption =
      newData.sections[sectionIndex].questions[questionIndex].options;
    if (
      newData.sections[sectionIndex].questions[questionIndex].options.length <
      15
    ) {
      newOption.push({ option: '' });
      newData.sections[sectionIndex].questions[questionIndex].options =
        newOption;
      setTemplateData(newData);
    }
  };

  const deleteOptionTemplate = (sectionIndex, questionIndex, id) => {
    let newData = { ...templateData };
    let newOption =
      newData.sections[sectionIndex].questions[questionIndex].options;
    if (
      newData.sections[sectionIndex].questions[questionIndex].options.length > 2
    ) {
      newOption.splice(id, 1);
      newData.sections[sectionIndex].questions[questionIndex].options =
        newOption;
      setTemplateData(newData);
    }
  };

  const copyTemplate = (sectionIndex, questionIndex) => {
    let newData = JSON.parse(JSON.stringify(templateData));
    let copiedArray = JSON.parse(
      JSON.stringify(newData.sections[sectionIndex].questions[questionIndex])
    );
    copiedArray.question = `${copiedArray.question} (Copy)`;
    let currentQuestions = newData.sections[sectionIndex].questions;
    if (currentQuestions.length <= 29) {
      currentQuestions = [...currentQuestions, copiedArray];
    }
    currentQuestions[currentQuestions - 1]?.id &&
      delete currentQuestions[currentQuestions.length - 1]?.id;
    currentQuestions[currentQuestions.length - 1]?.options?.forEach(
      (option) => option?.id && delete option?.id
    );
    newData.sections[sectionIndex].questions = currentQuestions;
    setTemplateData(newData);
  };

  const deleteTemplate = (sectionIndex, questionIndex) => {
    let newData = { ...templateData };
    if (newData.sections[sectionIndex].questions.length > 1) {
      newData.sections[sectionIndex].questions.splice(questionIndex, 1);
      setTemplateData(newData);
    }
  };

  const addRemoveQuestion = () => {
    let newData = { ...templateData };
    if (templateData?.questions?.length > 0) {
      newData.questions = [];
      delete newData.position;
      setShowModal(null);
    } else {
      newData.questions = [
        {
          question: '',
          questionType: 'rating',
          needComment: 'no',
          order: null,
          visibility: 'open',
          optionsLabelVisibility: true,
          options: [
            { option: 'Below Expectation' },
            { option: 'Almost Meet Expectation' },
            { option: 'Meet Expectation' },
            { option: 'Exceed Expectation' }
          ]
        }
      ];
      newData.position = 'after';
    }
    setTemplateData(newData);
  };

  const addRemoveSection = () => {
    let newData = { ...templateData };
    if (templateData?.sections?.length > 0) {
      newData.sections = [];
      delete newData.position;
      setShowModal(null);
    } else {
      newData.sections = [defaultSection];
    }
    setTemplateData(newData);
  };

  const onScroll = (containerEl) => {
    const scrollTop = containerEl.target.scrollTop;
    let el = document.getElementById('template-name');
    if (el && scrollTop >= el.offsetTop) {
      setActiveSection('template-name');
    }
    el = document.getElementById('configuration');
    if (el && scrollTop >= el.offsetTop) {
      setActiveSection('configuration');
    }
    el = document.getElementById('rating-option-view');
    if (el && scrollTop >= el.offsetTop) {
      setActiveSection('rating-option-view');
    }
    el = document.getElementById('additional-questions');
    if (el && scrollTop >= el.offsetTop) {
      setActiveSection('additional-questions');
    }
    if (
      containerEl.target.scrollTop + containerEl.target.offsetHeight ==
      containerEl.target.scrollHeight
    ) {
      setActiveSection(
        listNavigatorContents[listNavigatorContents.length - 1].id
      );
    }
  };

  const isQuestionDataValid = () => {
    templateData.questions.map((data) => {
      if (!data.question) return true;
      if (data.questionType != 'comments') {
        data.options.map((value) => {
          if (!value.option) return true;
        });
      }
    });
    return false;
  };

  const isValidReviewAspectData = () => {
    const isRelationWithoutGoal =
      templateData?.config?.reviewAspects?.find(
        (aspect) =>
          aspect?.mechanism == 'weight_type' &&
          aspect?.objectiveCategoryIds?.length == 0
      ) || false;
    const isSliderWithoutLabel =
      templateData?.config?.view == 'slider' &&
      (templateData?.config?.sliderLabel?.leftLabel == '' ||
        templateData?.config?.sliderLabel?.rightLabel == '');

    return !isRelationWithoutGoal && !isSliderWithoutLabel;
  };

  const getDisabledButton = () => {
    if (!templateData.name) return true;

    let isValidReviewAspect = true;
    let isValidQuestion = true;

    if (Object.prototype.hasOwnProperty.call(templateData, 'questions')) {
      isValidQuestion = isQuestionDataValid();
    }
    if (templateData?.trackType == 'review_aspects_scoring') {
      isValidReviewAspect = isValidReviewAspectData();
    }

    return !isValidQuestion || !isValidReviewAspect;
  };

  const getListNav = () => {
    let defaultNav = [
      { id: 'template-name', name: 'Template name' },
      { id: 'additional-questions', name: 'Additional questions' }
    ];
    if (document.getElementById('configuration')) {
      let newData = { id: 'configuration', name: 'Configuration' };
      defaultNav.splice(1, 0, newData);
    }
    if (document.getElementById('rating-option-view')) {
      let newData = { id: 'rating-option-view', name: 'Rating Option View' };
      defaultNav.splice(2, 0, newData);
    }
    setListNavigatorContents(defaultNav);
  };

  const getDataTrackTemplate = async (id) => {
    let newData = { ...templateData };
    const { data } = await getDetailTrackTemplate(id);
    if (data) {
      newData.name = data.name;
      newData.config = data.config;
      newData.trackType = data.trackType;
      newData.position = data.position;
      newData.ratingTemplate = data.ratingTemplate;

      if (data?.sections?.length > 0) {
        newData.sections = data.sections;
      } else {
        delete newData.sections;
      }

      if (data.minSelection) {
        newData.config.minSelection = data.minSelection;
      }
      if (data.maxSelection) {
        newData.config.maxSelection = data.maxSelection;
      }
      setTemplateData(newData);
    }
  };

  const saveData = async () => {
    const body = { ...templateData };

    let mechanism = templateData.config.mechanism || null;
    const listShowFormula = [
      'each',
      'specific_attribute',
      'attributes',
      'input_score',
      'suggested_ongoing',
      'suggested_ongoing_parent_only'
    ];
    if (!listShowFormula.includes(mechanism)) {
      delete body.config.formula;
    }
    if (Object.prototype.hasOwnProperty.call(templateData, 'sections')) {
      templateData?.sections?.map((sectionData, sectionIndex) => {
        body.sections[sectionIndex].order = sectionIndex + 1;
        sectionData?.questions?.map((questionData, questionIndex) => {
          let currentQuestion = sectionData?.questions[questionIndex];
          currentQuestion.order = questionIndex + 1;
          if (questionData?.questionType == 'comments') {
            currentQuestion?.options && delete currentQuestion?.options;
            currentQuestion.needComment = 'required';
          } else {
            currentQuestion?.options?.map((value, optionIndex) => {
              currentQuestion.options[optionIndex].order = optionIndex + 1;
              currentQuestion.options[optionIndex].value = optionIndex + 1;
            });
          }
          body.sections[sectionIndex].questions[questionIndex] =
            currentQuestion;
        });
      });
    }
    if (!body.ratingTemplateId) {
      delete body.ratingTemplateId;
    }
    if (currentTrackType == 'general_questionnaire') {
      delete body.position;
    }

    if (body.config?.reviewAspects) {
      body.config?.reviewAspects.map((aspect, index) => {
        // assign order
        aspect.order = index;
        delete aspect.uniqueId;
      });
    }

    delete body.ratingTemplate;
    delete body.reviewAspects;

    if (
      body?.trackType == 'review_aspects_scoring' &&
      body?.config?.view != 'slider'
    ) {
      delete body.config.sliderLabel;
    }

    if (body?.position) {
      delete body.position;
    }

    if (match.params.id) {
      const { data } = await editTrackTemplate(match.params.id, body);
      if (data) {
        setModalSuccess(true);
        setTimeout(() => {
          history.replace('/appraisals/cycles/track-template');
        }, 2000);
      }
    } else {
      const { data } = await postTrackTemplate(body);
      if (data) {
        setModalSuccess(true);
        setTimeout(() => {
          history.replace('/appraisals/cycles/track-template');
        }, 2000);
      }
    }
  };

  const getCustomAttribute = async () => {
    let listData = [];
    let params = {
      state: 'active',
      isDefault: false
    };
    const fetchCustomAttribute = async (olderThan = null, stop) => {
      if (stop) return;
      if (olderThan) {
        params = {
          ...params,
          olderThan: olderThan
        };
      }
      const { data, pagination } = await getCustomAttributeList(0, params);
      if (data) {
        listData = [...listData, ...data];
        setCustomAtrributes(listData);
        await fetchCustomAttribute(
          pagination?.next?.olderThan,
          data.length != 10
        );
      }
    };
    await fetchCustomAttribute();
    setIsLoading(false);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    if (match.params.id) {
      getDataTrackTemplate(match.params.id);
    }
    getListNav();
  }, []);

  useEffect(() => {
    if (currentTrackType == 'review_aspects_scoring') {
      setIsLoading(true);
      getCustomAttribute();
    }
  }, []);

  return (
    <>
      {showModal && (
        <Modal
          eventOnClick={() => setShowModal(false)}
          title={
            showModal == 'question'
              ? 'Deactive additional question'
              : 'Leave track template config'
          }
          description={
            showModal == 'question'
              ? 'If you deactive the additional question now, all of your questions will be completely removed. Are you sure?'
              : 'Are you sure want to go back? If you leave now, your changes to the track template won’t be saved.'
          }
          className="w-[400px]"
          withPrimaryBtn={{
            title: showModal == 'question' ? 'Deactive Questions' : 'Leave',
            dataCy: 'confirm-button',
            onClick: () =>
              showModal == 'question'
                ? addRemoveSection()
                : history.replace('/appraisals/cycles/track-template')
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            dataCy: 'cancel-confirm',
            onClick: () => setShowModal(false)
          }}
          eventOnClickClose={() => setShowModal(false)}
        />
      )}
      <HeaderPage
        titlePage={`${titleTrackTemplate} - ${
          templateData.name || 'New Track Template'
        }`}
        backToUrl={`/appraisals/cycles/track-template`}
        isHeaderComposer={true}
      />
      <div
        className="create-track-template flex bg-n-000 h-[calc(100%-120px)]"
        onScroll={(e) => onScroll(e)}
      >
        {modalSuccess && (
          <Modal
            dataCyModal={'modal-success-save-template'}
            title={'Config Saved'}
            headerIcon={{ name: 'icon-thin-check', color: 'var(--g-600)' }}
          >
            <p>Your configuration have been saved</p>
          </Modal>
        )}
        <div className="flex-[9] wrapper-all-section pr-[56px]">
          {currentTrackType == 'review_aspects_scoring' &&
            customAttributes &&
            customAttributes.length == 0 && (
              <BannerBox
                type="error"
                customClass="mt-[24px] mb-[0px]"
                text="Your Organization doesn't have an active custom attributes"
                action={{
                  text: 'Create Custom Attributes',
                  onClick: () => {
                    history.replace('/group/performance-profile');
                  }
                }}
              />
            )}
          <NameSection
            templateData={templateData}
            errorName={errorName}
            setErrorName={setErrorName}
            setData={setData}
          />
          <hr className="border-separator" />
          {currentTrackType != 'general_questionnaire' &&
            (currentTrackType == 'review_aspects_scoring' ? (
              <ConfigureMultipleSection
                currentTrackType={currentTrackType}
                templateData={templateData}
                setData={setData}
                setTemplateData={setTemplateData}
                customAttributes={isLoading ? [] : customAttributes}
                setCustomAtrributes={setCustomAtrributes}
              />
            ) : (
              <ConfigureSection
                currentTrackType={currentTrackType}
                templateData={templateData}
                setData={setData}
                setTemplateData={setTemplateData}
                customAttributes={customAttributes}
              />
            ))}
          {currentTrackType == 'summary' &&
            templateData.config.mechanism == 'final_scoring' && (
              <div className="wrapper-component">
                <p className="typography-h500 typography-primary text-n-900 mb-[4px]">
                  Final rating question
                </p>
                <p className="typography-h400 typography-paragraph typography-secondary">
                  Choose to customise final rating question (
                  {getObjectiveLocale('optional')})
                </p>
                <p className="typography-h100 typography-secondary mb-[8px]">
                  Question
                </p>
                <input
                  className="track-template-input w-full"
                  placeholder="In summary, how is the reviewee overall performance according to you?"
                  value={templateData.config.finalQuestion}
                  onChange={(e) =>
                    setData(e.target.value, 'config', 'finalQuestion')
                  }
                  data-cy="final-question"
                />
                <div className="comment-component">
                  <p className="typography-h400 typography-paragraph typography-secondary">
                    Comment
                  </p>
                  <div className="wrapper-checkbox-toggle">
                    {(templateData.config.useComment == 'required' ||
                      templateData.config.useComment == 'optional') && (
                      <Checkbox
                        id="set-private-comment"
                        name="set-private-comment"
                        checked={templateData.config.useComment == 'optional'}
                        onChange={(e) =>
                          setData(
                            e.currentTarget.checked ? 'optional' : 'required',
                            'config',
                            'useComment'
                          )
                        }
                        dataCy="final-question-optional-comment"
                      >
                        <p className="typography-h400 typography-paragraph typography-secondary">
                          {getObjectiveLocale('optional')}
                        </p>
                      </Checkbox>
                    )}
                    <Toggle
                      onChange={() =>
                        setData(
                          templateData.config.useComment == 'required' ||
                            templateData.config.useComment == 'optional'
                            ? 'no'
                            : 'required',
                          'config',
                          'useComment'
                        )
                      }
                      isChecked={
                        templateData.config.useComment == 'required' ||
                        templateData.config.useComment == 'optional'
                      }
                      idStr={'final-question-comment'}
                    />
                  </div>
                </div>
              </div>
            )}
          <AdditionalQuestion
            currentTrackType={currentTrackType}
            templateData={templateData}
            setTemplateData={setTemplateData}
            addRemoveQuestion={() => addRemoveQuestion()}
            addRemoveSection={() => addRemoveSection()}
            setShowModal={(type) => setShowModal(type)}
            deleteOptionTemplate={(sectionIndex, questionIndex, optionIndex) =>
              deleteOptionTemplate(sectionIndex, questionIndex, optionIndex)
            }
            addOptionTemplate={(sectionIndex, questionIndex) =>
              addOptionTemplate(sectionIndex, questionIndex)
            }
            copyTemplate={(sectionIndex, questionIndex) =>
              copyTemplate(sectionIndex, questionIndex)
            }
            deleteTemplate={(sectionIndex, questionIndex) =>
              deleteTemplate(sectionIndex, questionIndex)
            }
            handleAddQuestion={handleAddQuestion}
            handleAddSection={handleAddSection}
            setData={(
              value,
              prefix,
              subPrefix,
              index,
              optionIndex,
              sectionIndex
            ) =>
              setData(
                value,
                prefix,
                subPrefix,
                index,
                optionIndex,
                sectionIndex
              )
            }
          />
        </div>
        <div className="flex-[3] content-navigator">
          <div className="fixed">
            <ContentNavigator
              title="CONTENTS"
              activeSection={activeSection}
              listNavigatorContents={listNavigatorContents}
            />
          </div>
        </div>
      </div>
      <Footer
        linkButton={true}
        handleClick={() => saveData()}
        buttonPrimaryName="Save Template"
        buttonPrimaryDisabled={getDisabledButton()}
        datacy="save-data"
      />
    </>
  );
}

export default CreateTrackTemplate;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import has from 'lodash/has';

import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { trackEvent } from 'utils/AnalyticUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import Objectives from 'components/objectives/Objectives';
import BannerBox from 'components/shared/BannerBox';
import ObjectiveSkeleton from 'components/shared/ObjectiveSkeleton';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

import ErrorBoundary from './ErrorBoundary';

function NeedResponse({ route: { title } }) {
  const { match } = useUrl();
  const { user } = useUser();
  const { pathname } = useLocation();

  const defaultFilter = {
    owner: {
      assignee: []
    },
    roles: ['assigner'],
    overdue: 'overdue',
    phase: ['running']
  };

  const extraFilter = {
    parentNotAssignedTo: user.id
  };

  const exceptFilterMenu = ['overdue', 'myInvolvement', 'reviewer', 'phase'];
  const filterOptions = ['search', 'group', 'sort', 'dateFilter', 'menuFilter'];

  const resultFilterModifier = (filter) => {
    const newFilter = { ...filter };

    if (has(newFilter, 'overdue')) {
      delete newFilter.overdue;
    }

    if (has(newFilter, 'phase')) {
      delete newFilter.phase;
    }

    return newFilter;
  };

  useEffect(() => {
    trackEvent({
      event: `open ${pathname} page`,
      eventProperties: { userId: user.id, userName: user.name, pathname }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage titlePage={title} />
      <BannerBox
        text={getObjectiveLocale(
          'These objectives are Overdue. To remind the Owners, click the "Comment" button.'
        )}
        type="info"
        marginClass="mx-[40px] mt-[24px] mb-[16px]"
        iconSize={24}
      />
      <div className="grid gap-[24px] mb-[64px]" data-cy="give-comment">
        <ErrorBoundary>
          <React.Suspense fallback={<ObjectiveSkeleton />}>
            <AdvancedFilterObjectives
              filterOptions={filterOptions}
              defaultFilter={defaultFilter}
              exceptFilterMenu={exceptFilterMenu}
              group="no-group"
              page="give-comment"
              filterId="giveCommentFilter"
              resultFilterModifier={resultFilterModifier}
            >
              <Objectives
                cardModel="goal-list"
                page="give-comment"
                extraFilter={extraFilter}
              />
            </AdvancedFilterObjectives>
          </React.Suspense>
        </ErrorBoundary>

        <React.Suspense fallback={<div></div>}>
          {match && <SidebarMyGoals />}
        </React.Suspense>
      </div>
    </>
  );
}

export default NeedResponse;

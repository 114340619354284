import React from 'react';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';

import S3Client from 'client/S3Client';
import { getPerformancePreSignUrl } from 'client/UploadClient';
import { getObjectiveLocale, setDropdownOptions } from 'utils/HelperUtils';

import TimeField from 'components/admin/usermanagement/TimeField';
import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import Checkbox from 'components/shared/Checkbox';
import ConditionalWrapper from 'components/shared/ConditionalWrapper';
import InputField from 'components/shared/InputField';
import RadioButton from 'components/shared/RadioButton';
import TimePickerV2 from 'components/shared/TimePickerV2';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import ColorItem from 'src/components/admin/theme-color/ColorItem';

const CardWrapperOrganization = (props) => {
  return (
    <div className="border border-solid border-n-400 rounded-[4px] mb-[24px]">
      <div className="flex items-center justify-between py-[16px] px-[16px] h-[72px]">
        <div>
          <p className="typography-h400 color-n-900 mb-[4px]">
            {getObjectiveLocale(props.header)}
          </p>
          {props.description && (
            <p className="typography-paragraph color-n-800">
              {getObjectiveLocale(props.description)}
            </p>
          )}
        </div>
      </div>
      <div className="border-solid border-t border-0 border-n-500" />
      {props.children}
    </div>
  );
};

const CardCheckBoxOrganization = (props) => {
  return (
    <CardWrapperOrganization
      header={props?.label}
      description={props?.description}
    >
      <div className="py-[16px] px-[16px]">
        {props?.listOption &&
          props?.listOption.map((option, index) => (
            <Checkbox
              key={index}
              name={`checkbox-org-${option.id}`}
              id={`checkbox-org-${props.dataCy}-${option.id}`}
              checked={props.selected?.includes(option.id)}
              onClick={() => props.handleChange(option)}
              value={option.id}
              disabled={props.disabled}
              dataCy={`checkbox-org-${props.dataCy}-${option.id}`}
              customContainerClass={`max-h-[24px] ${
                props?.listOption.length - 1 != index ? 'mb-[16px]' : ''
              }`}
            >
              <span className="color-n-900 ml-[8px]">{option.title}</span>
            </Checkbox>
          ))}
      </div>
    </CardWrapperOrganization>
  );
};

const CardInputOrganization = (props) => {
  return (
    <CardWrapperOrganization header={props?.cardLabel}>
      <InputField className="w-full my-[16px] px-[16px]" {...props} />
    </CardWrapperOrganization>
  );
};

const DropdownOrganization = (props) => {
  const selected = props?.listOption?.find(
    (option) => option.id === props?.selected
  );
  const options = setDropdownOptions(
    props?.listOption,
    'title',
    props?.onChange,
    'id',
    ''
  );

  return (
    <div className={props?.customWrapperClass ? props?.customWrapperClass : ''}>
      {props?.label && (
        <p
          className={`typography-h100 text-n-800 my-[16px] ${props?.customClass}`}
        >
          {props?.label}
        </p>
      )}
      <Dropdown>
        <Dropdown.Trigger disabled={props?.disabled} dataCy={props?.dataCy}>
          <Trigger
            customWrapperClass="w-[320px]"
            topLabel={false}
            label={false}
            icon={false}
            text={selected?.title || 'No selected'}
            customTextClass={selected?.customClass}
          />
        </Dropdown.Trigger>
        <Dropdown.MenuItems
          options={options}
          customClass={`w-[320px] ${!selected?.title ? 'text-n-600' : ''}`}
          dataCy={props?.dataCy}
          maxHeight={160}
          disableSelected={props.disableSelected}
        />
      </Dropdown>
    </div>
  );
};

const DropdownCard = (props) => {
  const selected = props?.listOption?.find(
    (option) => option.id === props?.selected
  );
  const options = setDropdownOptions(
    props?.listOption,
    'title',
    props?.onChange,
    'id',
    ''
  );
  return (
    <div className="my-[24px]">
      <p className="typography-h100 text-n-800 my-[16px]">
        {props?.labelPermission}
      </p>
      <div className="border border-solid border-n-400 rounded-[4px] py-[16px] px-[16px]">
        <div className="flex flex-col mb-[16px]">
          <p className="typography-h400 text-n-900 mb-[4px]">
            {props?.cardTitle}
          </p>
          <p className="typography-paragraph text-n-800">
            {props?.cardDescription}
          </p>
        </div>
        <Dropdown>
          <Dropdown.Trigger disabled={props.disabled} dataCy={props?.dataCy}>
            <Trigger
              customWrapperClass="w-[320px]"
              topLabel={false}
              label={false}
              icon={false}
              text={selected?.title || 'No selected'}
              customTextClass={selected?.customClass}
            />
          </Dropdown.Trigger>
          <Dropdown.MenuItems
            options={options}
            customClass={`w-[320px] ${!selected?.title ? 'text-n-600' : ''}`}
            dataCy={props?.dataCy}
          />
        </Dropdown>
        {props?.isChecked && props?.inputElement?.length > 0 && (
          <div className="flex flex-col">
            {props?.inputElement?.map((input, index) => (
              <RadioButtonOrganization {...input} key={index} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const ToggleCard = (props) => {
  return (
    <div className={props?.withoutCard ? '' : 'my-[24px]'}>
      <p className="typography-h100 text-n-800 my-[16px]">
        {props?.labelPermission}
      </p>
      <div
        className={
          props?.withoutCard
            ? ''
            : 'border border-solid border-n-400 rounded-[4px] py-[16px] px-[16px]'
        }
      >
        <div className="flex justify-between items-center">
          <div>
            <p className="typography-h400 text-n-900 mb-[4px]">
              {props?.cardTitle}
            </p>
            <p className="typography-paragraph text-n-800">
              {props?.cardDescription}
            </p>
          </div>
          <SmallToggleSwitchPurple {...props} />
        </div>
        {props?.isChecked && props?.inputElement?.length > 0 && (
          <div className="flex flex-col mt-[24px]">
            {props?.inputElement?.map(
              (input, index) =>
                input?.isVisible &&
                (input.element === 'input' ? (
                  <InputField
                    className="w-[240px] mt-[16px]"
                    {...input}
                    key={index}
                  />
                ) : input.element === 'dropdown' ? (
                  <DropdownOrganization {...input} key={index} />
                ) : input.element === 'radio' ? (
                  <RadioButtonOrganization
                    {...input}
                    key={index}
                    customClass={index == 0 && 'mt-[0px]'}
                  />
                ) : input.element === 'time' ? (
                  <div>
                    <p className="typography-h100 text-n-800 mt-[24px] mb-[16px]">
                      {input?.label}
                    </p>
                    <TimePickerV2
                      {...input}
                      minutesStep={30}
                      format="hh:mm a"
                      customClassIcon="right-[8px]"
                    />
                  </div>
                ) : input.element === 'toggle' ? (
                  <ToggleCard {...input} key={index} withoutCard />
                ) : (
                  <TimeField {...input} key={index} />
                ))
            )}
          </div>
        )}
        {props?.useChild && props?.isChecked && props.children}
      </div>
    </div>
  );
};

const CheckboxWithDefaultOrganization = ({
  className,
  selected,
  title,
  headerNo,
  headerName,
  listOption,
  dataCy,
  disabled,
  withRadioButton,
  withCheckboxButton = true,
  selectedDefault,
  handleChange,
  handleChangeDefault,
  handleChangeDefaultToggle,
  withInfo,
  infoText
}) => {
  return (
    <div
      className={`${className} box-config border border-solid ${
        selected?.length == 0 ? 'border-r-600' : 'border-n-400'
      } rounded-[4px] pt-[16px] my-[24px]`}
    >
      <div className="wrapper-div px-[16px] mb-[16px] border-bottom-400">
        <p className="typography-h400 mb-[24px]">{title}</p>
        <div className="flex justify-between pb-[12px] items-center section-header w-[35%]">
          <p className="uppercase typography-h300 text-n-800">{headerNo}</p>
          <p className="uppercase typography-h300 text-n-800">{headerName}</p>
        </div>
      </div>
      {listOption &&
        listOption.map((option, index) => (
          <div
            className="flex justify-between w-[35%] mb-[16px] items-center pl-[16px]"
            key={index}
          >
            <ConditionalWrapper
              condition={withCheckboxButton}
              wrapper={(children) => (
                <Checkbox
                  key={`checkbox-${index}`}
                  name={`checkbox-org-${option.id}`}
                  id={`checkbox-org-${dataCy}-${option.id}`}
                  checked={selected?.includes(option.id)}
                  onClick={(e) =>
                    handleChange(e.currentTarget.checked, option.id)
                  }
                  value={option.id}
                  disabled={disabled || option?.disabled}
                  dataCy={`checkbox-org-${dataCy}-${option.id}`}
                >
                  {children}
                </Checkbox>
              )}
            >
              <span
                className={`text-n-900 ${withCheckboxButton ? 'ml-[8px]' : ''}`}
              >
                {option.title}
              </span>
            </ConditionalWrapper>

            {withRadioButton ? (
              <RadioButton
                dataCy={`radio-org-${dataCy}-${option.id}`}
                key={`${dataCy}-${index}`}
                name={`checkbox-default-org-${dataCy}-${option.id}`}
                id={`checkbox-default-org-${dataCy}-${option.id}`}
                checked={
                  selectedDefault?.[0] === option.id &&
                  selected?.includes(option.id)
                }
                onChange={() => handleChangeDefault(option?.id)}
                value={selectedDefault?.[0]}
                disabled={
                  option?.disabled || disabled || !selected?.includes(option.id)
                }
                addClass="items-center h-[20px] mr-[24px]"
              />
            ) : (
              <SmallToggleSwitchPurple
                key={`${dataCy}-${index}`}
                idStr={`toggle-default-org-${dataCy}-${option.id}`}
                isChecked={selectedDefault?.includes(option.id)}
                disabled={!selected?.includes(option.id) && disabled}
                onChange={() => handleChangeDefaultToggle(option)}
                addClass="ml-[40px] mt-[6px]"
              />
            )}
          </div>
        ))}
      {withInfo && (
        <p className="typography-h100 italic text-n-800 mb-[16px] pl-[16px]">
          {getObjectiveLocale(infoText)}
        </p>
      )}
    </div>
  );
};

const CheckBoxOrganization = (props) => {
  return (
    <>
      <p className="typography-h100 text-n-800 my-[16px]">{props?.label}</p>
      <div
        className={`${
          props.customClass
            ? props.customClass
            : ' border border-solid border-n-400 rounded-[4px] py-[16px] px-[16px]'
        }`}
      >
        {props?.listOption &&
          props?.listOption.map((option, index) => (
            <Checkbox
              key={index}
              name={`checkbox-org-${option.id}`}
              id={`checkbox-org-${props.dataCy}-${option.id}`}
              checked={props.selected?.includes(option.id)}
              onClick={() => props.handleChange(option)}
              value={option.id}
              disabled={props.disabled}
              dataCy={`checkbox-org-${props.dataCy}-${option.id}`}
            >
              <span className="text-n-900 ml-[8px]">{option.title}</span>
            </Checkbox>
          ))}
      </div>
    </>
  );
};

const RadioButtonOrganization = (props) => {
  return (
    <>
      <p
        className={`typography-h100 text-n-800 mt-[24px] mb-[16px] ${
          props?.customClass || ''
        }`}
      >
        {props.label}
      </p>
      <div className="radio-container">
        {props?.listOption &&
          props.listOption.map((option, index) => (
            <>
              <RadioButton
                dataCy={`radio-org-${props.dataCy}-${option.id}`}
                key={`${props.dataCy}-${index}`}
                name={`checkbox-org-${props.dataCy}-${option.id}`}
                id={`checkbox-org-${props.dataCy}-${option.id}`}
                checked={
                  option?.isChecked
                    ? option?.isChecked
                    : props.selected === option?.id
                }
                onChange={() => props.handleChange(option?.id)}
                value={option.id}
                disabled={props.disabled}
                addClass="h-[24px] mb-[8px] items-center"
              >
                <span className="ml-[8px] text-n-900 whitespace-nowrap">
                  {option.title}{' '}
                </span>
                {option?.isUseDefaultDesc && (
                  <span className="text-n-600">(default)</span>
                )}
              </RadioButton>
              {option.children}
            </>
          ))}
      </div>
    </>
  );
};

const setActiveNavigator = (
  e,
  listSection,
  setActiveSection,
  scrollSize = 180
) => {
  const scrollTop = e.target.scrollTop - scrollSize;
  const listSectionId = listSection.map((section) => section.id);
  listSectionId.forEach((section) => {
    const el = document.getElementById(section);
    if (el && scrollTop >= el.offsetTop) {
      setActiveSection(section);
    }
  });
  if (e.target.scrollTop + e.target.offsetHeight == e.target.scrollHeight) {
    setActiveSection(listSectionId[listSectionId.length - 1]);
  }
};

const changeUserData = (
  selector,
  parentSelector,
  value,
  configData,
  changeConfigData
) => {
  let data = cloneDeep(configData);
  if (parentSelector) {
    if (
      data.hasOwnProperty(parentSelector) &&
      data[parentSelector]?.hasOwnProperty(selector)
    ) {
      data[parentSelector][selector] = value || !data[parentSelector][selector];
    } else if (
      data.hasOwnProperty(parentSelector) &&
      !data[parentSelector]?.hasOwnProperty(selector)
    ) {
      data[parentSelector] = {
        ...data[parentSelector],
        [selector]: value || true
      };
    } else {
      data = {
        ...data,
        [parentSelector]: {
          ...data[parentSelector],
          [selector]: value || true
        }
      };
    }
  } else {
    data = {
      ...data,
      [selector]:
        value || (data.hasOwnProperty(selector) ? !data[selector] : true)
    };
  }
  changeConfigData(data);
};

const changeCheckboxData = (
  selected,
  listSelected,
  selector,
  parentSelector,
  configData,
  changeConfigData
) => {
  let list = listSelected?.slice();
  let selectedItem = selected?.id || selected;
  if (!list) {
    list = [selectedItem];
  } else if (list && list.includes(selectedItem)) {
    list = list.filter((sel) => sel !== selectedItem);
  } else if (list && !list.includes(selectedItem)) {
    list.push(selectedItem);
  }
  changeUserData(selector, parentSelector, list, configData, changeConfigData);
};

const _getPreSignUrl = async (file) => {
  const filename = file.name;
  const extensions = filename.split('.').pop();

  const presignQuery = {
    public: true,
    directory: 'files',
    extension: extensions
  };

  const { data } = await getPerformancePreSignUrl(presignQuery);
  return data;
};

const handleChangeLogo = async (type, e, setGeneralData, generalData) => {
  const currentData = cloneDeep(generalData);
  let file = e.target.files[0];

  if (file) {
    if (
      file.type == 'image/png' ||
      file.type == 'image/jpeg' ||
      file.type == 'image/jpg'
    ) {
      let presignData = await _getPreSignUrl(file);
      if (presignData) {
        const { status } = await S3Client(presignData.uploadUrl, file);

        if (status == 200) {
          if (type === 'company') {
            currentData.companyLogo = presignData.downloadUrl;
          } else if (type === 'app') {
            currentData.logoUrl = presignData.downloadUrl;
          } else if (type === 'culture') {
            currentData.cultureLogoUrl = presignData.downloadUrl;
          }
          setGeneralData(currentData);
        }
      } else {
        alert('Presign Link Not Found!');
      }
    } else {
      alert('Wrong file type! file type must be png, jpg or jpeg!');
      return false;
    }
  } else {
    alert('Empty file image');
  }
};

const OrgPhotoField = ({
  title,
  keyId,
  id,
  src,
  setGeneralData,
  generalData,
  disabled
}) => {
  return (
    <div>
      <div className="flex mb-[8px] typography-h100 text-n-800">
        {getObjectiveLocale(title)}
      </div>
      <div>
        <input
          type="file"
          name="inputfile"
          id={id}
          className="inputfile hidden"
          onChange={(e) => {
            handleChangeLogo(keyId, e, setGeneralData, generalData),
              (document.getElementById(id).value = '');
          }}
          disabled={disabled}
          data-cy={id}
        />
        <label
          htmlFor={id}
          className={`w-[110px] h-[110px] border-dashed border`}
        >
          {src ? (
            <AWSImage
              src={src}
              imageId={`${keyId}-logo-img`}
              className={`object-contain cursor-pointer`}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-full text-center">
              <>
                <i className="fa fa-camera"></i>
                {title}
              </>
            </div>
          )}
        </label>
      </div>
    </div>
  );
};

const optionColor = [
  '#5417CF',
  '#DB2777',
  '#E11D48',
  '#D97706',
  '#EA580C',
  '#65A30D',
  '#059669',
  '#0D9488',
  '#0891B2',
  '#0284C7',
  '#4F46E5',
  '#C026D3'
];

const generalFilterandCycleDetailOptionCheckbox = [
  { id: 'directorate', title: 'Directorate' },
  { id: 'department', title: 'Department' },
  { id: 'division', title: 'Division' },
  { id: 'job-title', title: 'Job Title' },
  { id: 'job-role', title: 'Job Role' },
  { id: 'job-function', title: 'Job Function' }
];

const arrayColors = optionColor.map((color, index) => {
  return { id: index, colorHex: color };
});

const ListColorBox = ({
  label,
  selectedColor,
  changeColor,
  useReset,
  disabled,
  id,
  dataCy
}) => {
  return (
    <div className="theme-color-container">
      <p className="typography-h100 text-n-800 mt-[24px] mb-[16px]">{label}</p>
      <div className="flex mb-[24px]">
        {arrayColors.map((color) => (
          <ColorItem
            key={color.id}
            id={`${dataCy || id}-${color.id}`}
            colorHex={color.colorHex}
            onChange={() => changeColor(color.colorHex)}
            checked={color.colorHex === selectedColor}
            disabled={disabled}
          />
        ))}
      </div>
      {useReset && (
        <Button.Tertiary
          disabled={disabled}
          onClick={() => changeColor('#5417CF')}
        >
          Reset to default color
        </Button.Tertiary>
      )}
    </div>
  );
};

const checkRegexTime = (params) => {
  const expression = /[0-9]{2}:[0-9]{2}/g;
  const regex = new RegExp(expression);
  return params.match(regex);
};

const checkTimeParams = (params) => {
  if (params) {
    const matched = checkRegexTime(params);
    if (matched) {
      const hoursMinutes = params?.split(':');
      return dayjs()
        .hour(hoursMinutes?.[0])
        .minute(hoursMinutes?.[1])
        .toISOString();
    }
  }

  return dayjs(params).toISOString();
};

export {
  ToggleCard,
  setActiveNavigator,
  changeUserData,
  CheckBoxOrganization,
  changeCheckboxData,
  RadioButtonOrganization,
  DropdownOrganization,
  DropdownCard,
  OrgPhotoField,
  ListColorBox,
  CheckboxWithDefaultOrganization,
  CardCheckBoxOrganization,
  generalFilterandCycleDetailOptionCheckbox,
  checkRegexTime,
  checkTimeParams,
  CardInputOrganization
};

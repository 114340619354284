import React from 'react';

import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

const GanttHeaderWeekly = ({ daysOfYear }) => {
  return (
    <>
      {daysOfYear.map(({ date, id }) => {
        if (date.getDay() == 1) {
          return (
            <div
              date-id={id}
              className={'timeline-cells-weeks h-[36px] w-[84px]'}
              style={{ borderRight: '1px solid var(--n-300)' }}
            >
              Week {dayjs(date).week()}
            </div>
          );
        }
      })}
    </>
  );
};

export default GanttHeaderWeekly;

import React, { useEffect, useState } from 'react';

import shuffle from 'lodash/shuffle';

import { getSkills } from 'client/SkillClient';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SkillPopupComponent from 'components/header/SkillPopupComponent';
import SVGIcon from 'components/shared/SVGIcon';

function SkillReview({
  selectedSkill,
  type,
  question,
  onClickSkill,
  comment,
  onChangeComment,
  ownerIds,
  placementId
}) {
  const [showSkillPopup, setShowSkillPopup] = useState(false);
  const [nineRandomSkill, setNineRandomSkill] = useState([]);

  const _getSkills = async () => {
    let query = {
      behaviorType: 'competency',
      placementId: placementId
    };
    const { data } = await getSkills(query);
    getNineRandomizedSkills(data);
  };

  const getNineRandomizedSkills = (skills) => {
    const shuffled = shuffle(skills);
    setNineRandomSkill(shuffled.slice(0, 9));
  };

  const _onClickSkill = (skill) => {
    onClickSkill(skill);
    setShowSkillPopup(false);
  };

  useEffect(() => {
    _getSkills();
  }, []);

  const skillLabel = getObjectiveLocale('Choose one skill or value');
  const skillPlaceholder = getObjectiveLocale('Select other skills');
  const commentPlaceholder = getObjectiveLocale(
    'Type the description here. Be specific & concise'
  );

  return (
    <div className="form-group review-content-container">
      <div className="question-container">
        <span className="label question-title">{question}</span>
        <div className="pt-[16px] pb-[16px] recognition-list">
          <div className="recognition-label">
            <SVGIcon
              iconName={
                type == 'Recognition' ? 'icon-strength' : 'icon-improvement'
              }
              size={32}
            />
          </div>
          <div className="recognition-title">
            <span className="title">{type}</span>
            <span className="desc">
              {getObjectiveLocale('Maximum competency or value selection is 1')}
            </span>
          </div>
        </div>

        <div className="pb-[12px]">
          <span className="label review-label">{skillLabel}</span>
          {selectedSkill && (
            <div className="skill-container selected">
              <span
                className={'label cursor-pointer filled'}
                onClick={() => setShowSkillPopup(true)}
              >
                {selectedSkill?.title}
              </span>
              <img
                onClick={() => setShowSkillPopup(true)}
                src={Config.asset.general.editOnGoing}
              />
            </div>
          )}
          {!selectedSkill && (
            <div>
              <div
                className="skill-name-container"
                data-cy="skill-name-container"
              >
                {nineRandomSkill.map((data, index) => {
                  return (
                    <div
                      data-cy="skill-name"
                      key={index}
                      className="select-skill"
                      onClick={() => onClickSkill(data)}
                    >
                      {data?.title}
                    </div>
                  );
                })}
                <div className="skill-container">
                  <span
                    className="label cursor-pointer"
                    onClick={() => setShowSkillPopup(true)}
                  >
                    {skillPlaceholder}{' '}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="review-comment-container">
          <textarea
            className="composer objective-rating"
            maxLength="250"
            placeholder={commentPlaceholder}
            value={comment}
            onChange={(e) => onChangeComment(e.target.value)}
            data-cy="skill-comment"
          />
          <div className="form-group-divider" />
        </div>
      </div>
      {showSkillPopup && (
        <SkillPopupComponent
          ownerIds={ownerIds}
          toClose={() => setShowSkillPopup(false)}
          onClickConfirm={_onClickSkill}
          skill={selectedSkill}
          placementId={placementId}
          type={type}
        />
      )}
    </div>
  );
}

export default SkillReview;

import React, { useEffect, useState } from 'react';

import { getFormalReviewObjectivesRating } from 'client/FormalReviewClient';
import { getCountRating, getGoalsScoring } from 'client/ObjectivesClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Button from 'components/design-system/Button';
import Objectives from 'components/objectives/Objectives';
import Modal from 'components/shared/modal/Modal';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import StatistikOverallViewProgressCoaching from 'src/pages/dashboardOverallViewProgress/StatistikOverallViewProgressCoaching.js';

import './GoalScoringViewOnly.scss';

// Sasaran Bisnis in BCA
const GoalScoringViewOnly = ({
  RevieweeFeedback = false,
  goalScoringData = [],
  state,
  setAnswer,
  trackType,
  isReviewResult,
  currentAssignment,
  cycleDetail
}) => {
  const { match } = useUrl();
  const {
    organization,
    config: { defaultObjectiveSorting, objectiveWeightType }
  } = useUser();
  const [{ assignmentId, cycle, involvedUser, targetPlacementId }] =
    useFormalReview();

  const [countRatingData, setCountRatingData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isFinalized, setIsFinalized] = useState(state !== 'in_progress');

  const currAssignment = assignmentId || currentAssignment?.id;
  const targetId = involvedUser?.target.id || currentAssignment?.target?.id;
  const targetName =
    involvedUser.target.name || currentAssignment?.target?.name;

  const paramsCoaching = {
    limit: 15,
    reviewsVisibility: 1,
    periodBegin:
      cycle?.reviewedPeriodStartsAt || cycleDetail?.reviewedPeriodStartsAt,
    periodEndBefore:
      cycle?.reviewedPeriodEndsAt || cycleDetail?.reviewedPeriodEndsAt,
    state: ['running', 'completed', 'reviewed'],
    assigneeId: targetId,
    placementId: targetPlacementId || currentAssignment?.targetPlacementId,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    type: ['annual_goal', 'goal'],
    parentNotAssignedTo: targetId
  };

  const getCountRatingUser = async () => {
    const { data } = isReviewResult
      ? await getFormalReviewObjectivesRating(
          currAssignment,
          trackType,
          paramsCoaching
        )
      : await getCountRating(targetId, paramsCoaching);
    setCountRatingData(data);
  };

  const finalizeScore = async () => {
    const answer = {
      state: isFinalized ? 'in_progress' : 'done'
    };
    setAnswer('goals_scoring', answer, 'view_only');
    setShowModal(false);
  };

  useEffect(() => {
    getCountRatingUser();
  }, []);

  useEffect(() => {
    setIsFinalized(state !== 'in_progress');
  }, [state]);

  return (
    <div className="container-goal-scoring-comment">
      {!RevieweeFeedback && (
        <h5 className="title-objective-person mt-[40px]">
          {targetName}'s {getObjectiveLocale('Objectives')}
        </h5>
      )}
      {organization.identifier == 'linknet' && (
        <div className="flex items-center justify-between py-[16px] px-[16px] bg-n-200 rounded-[4px] mb-[16px] w-full mr-[16px]">
          <div>
            <p className="typography-h500 text-n-900 multiline-text-1">
              {getObjectiveLocale('Finalize [employee_name]’s goal').replace(
                /\[employee_name]/,
                involvedUser.target.name
              )}
            </p>
            <p className="typography-h100 text-n-800">
              {getObjectiveLocale(
                'Lock these score by clicking the finalize button'
              )}
            </p>
          </div>
          <Button onClick={() => setShowModal(true)} datacy="finalize-scoring">
            {getObjectiveLocale(isFinalized ? 'Finalized' : 'Finalize Score')}
          </Button>
        </div>
      )}
      <div className="animated-half fadeInDown-listemployee mb-[40px]">
        <StatistikOverallViewProgressCoaching
          paramsCoaching={paramsCoaching}
          countRatingData={countRatingData}
          assignmentId={currAssignment}
          trackType={trackType}
          isReviewResult={isReviewResult}
        />
      </div>

      {objectiveWeightType === 'type' && organization.identifier != 'bca' ? (
        <AdvancedFilter
          defaultGroup={'goal-type'}
          id="goalsScoringViewOnlyFilter"
        >
          <Objectives
            cardModel={'goal-list'}
            page={`goalScoringViewOnly-${currAssignment}`}
            action={false}
            sidebar={!isReviewResult}
            extraFilter={paramsCoaching}
            {...(!RevieweeFeedback && {
              queryFn: (params) =>
                getGoalsScoring(currAssignment, {
                  ...params,
                  sortColumn: defaultObjectiveSorting?.sortColumn || 'due_date',
                  sortDirection: defaultObjectiveSorting?.sortDirection || 'asc'
                })
            })}
            {...(RevieweeFeedback && { initialData: goalScoringData })}
          />
        </AdvancedFilter>
      ) : (
        <Objectives
          cardModel={'goal-list'}
          page={`goalScoringViewOnly-${currAssignment}`}
          action={false}
          sidebar={!isReviewResult}
          extraFilter={paramsCoaching}
          {...(!RevieweeFeedback && {
            queryFn: (params) =>
              getGoalsScoring(currAssignment, {
                ...params,
                sortColumn: defaultObjectiveSorting?.sortColumn || 'due_date',
                sortDirection: defaultObjectiveSorting?.sortDirection || 'asc'
              })
          })}
          {...(RevieweeFeedback && { initialData: goalScoringData })}
        />
      )}

      {showModal && (
        <Modal
          title={getObjectiveLocale(
            isFinalized
              ? 'Edit performance score'
              : 'Finalize Performance Score'
          )}
          eventOnClickClose={() => setShowModal(false)}
          withCloseIcon
          withPrimaryBtn={{
            title: isFinalized ? 'Edit Score' : 'Finalize Score',
            onClick: () => finalizeScore()
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            onClick: () => setShowModal(false)
          }}
        >
          <p className="text-n-800 typography-paragraph">
            {getObjectiveLocale(
              isFinalized
                ? 'Edit your finalized performance score for this reviewee?'
                : "Finalize performance score for this reviewee? Don't worry, you can come back and edit it again later."
            )}
          </p>
        </Modal>
      )}

      {/* Sidebar */}
      {match && <SidebarMyGoals customClassname="sidebar-compact" />}
    </div>
  );
};

export default GoalScoringViewOnly;

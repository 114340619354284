import React from 'react' ;

import ctl from '@netlify/classnames-template-literals';

import { useUser } from 'context/UserContext';
import { getNumberLocale } from 'utils/HelperUtils';

import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const DecimalHoverable = ({ number = 0, textClass }) => {
  const {
    config: { roundSummaryScore }
  } = useUser();
  const textCN = ctl(
    `
      ${textClass}
      ${showTooltip && 'text-underline'}
    `
  );
  const integer = Math.floor(number);
  const decimals =
    integer === number ? '0' : number?.toString()?.split('.')?.[1];
  let showTooltip = decimals?.length > 2 && roundSummaryScore;

  let displayNumber = parseFloat(
    integer + '.' + getNumberLocale(decimals?.substring(0, 2))
  );

  return (
    <TooltipContainer show={showTooltip} classContainer="grid" text={number}>
      <p className={textCN}>{displayNumber}</p>
    </TooltipContainer>
  );
};

export default DecimalHoverable;

import React, { useState } from 'react';

import ctl from '@netlify/classnames-template-literals';

import AWSImage from 'components/design-system/AWSImage';
import ModalImage from 'components/shared/ModalImage/ModalImage';
import SVGIcon from 'components/shared/SVGIcon';

const AttachmentList = ({
  fileName,
  dataCy,
  fileSize,
  handleDelete,
  takenOn = null,
  deleteAble = true,
  useNewDesign = false,
  downloadUrl,
  type
}) => {
  const [isOpenModalImage, setIsOpenModalImage] = useState(false);

  const listCN = ctl(`
    group bg-n-200 rounded
    ${type !== 'image' && 'cursor-pointer'}
    ${deleteAble ? 'pb-[4px] pt-[4px]' : 'pb-[8px] pt-[8px]'}
    ${deleteAble && 'hover:bg-base-30024'}
  `);

  const newListCN = ctl(`
    group w-full flex justify-between items-center
    bg-n-000 border-solid border-[1px] border-n-300 rounded-[4px]
    cursor-pointer
    px-[8px] py-[12px]
    ${deleteAble && 'hover:bg-base-30024'}
  `);

  const lastPeriodIndex = fileName.lastIndexOf('.');
  const extension = fileName.substr(lastPeriodIndex);

  let name = fileName.substr(0, lastPeriodIndex);
  name = name.length > 24 ? `${name.substr(0, 22)}... ` : name;

  return (
    <>
      <div className={useNewDesign ? newListCN : listCN}>
        <div className="flex items-center justify-between">
          <div className={`flex ${!useNewDesign && 'items-center'}`}>
            <SVGIcon
              iconName="icon-attachment"
              size="24"
              fillColor="var(--n-600)"
              customClass="ml-8 mr-8"
            />
            <div>
              <p
                className={`typography-h200 text-base-600 ${useNewDesign &&
                  'mb-[4px]'}`}
                data-cy={dataCy}
              >
                {name}
                {extension}{' '}
                <span className="typography-h200 typography-tertiary">
                  {fileSize}
                </span>
              </p>
              {takenOn && (
                <p className="typography-h100 typography-tertiary">{takenOn}</p>
              )}
            </div>
          </div>
          {deleteAble && (
            <div className="hidden group-hover:flex">
              <SVGIcon
                iconName="icon-delete"
                size="24"
                fillColor="var(--n-600)"
                customClass="ml-[8px] mr-[4px]"
                onClick={handleDelete}
              />
            </div>
          )}
        </div>

        {type === 'image' && downloadUrl && (
          <AWSImage
            src={downloadUrl}
            className="w-[120px] h-[80px] mt-[8px] ml-[8px] cursor-pointer"
            onClick={() => setIsOpenModalImage(true)}
          />
        )}
      </div>

      <ModalImage
        imgs={[{ src: downloadUrl }]}
        isOpen={isOpenModalImage}
        onClose={() => setIsOpenModalImage(false)}
      />
    </>
  );
};

export default AttachmentList;

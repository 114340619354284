import React from 'react';

import { useToastContext } from 'context/ToastContext';
import { useUpdatePreset } from 'hooks/api/useObjectivesComparative';
import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';

const ModalReplacePreset = ({ onClose }) => {
  const preset = useObjectiveComparative((state) => state.preset);
  const insights = useObjectiveComparative((state) => state.insights);
  const creator = useObjectiveComparative((state) => state.creator);
  const isShared = useObjectiveComparative((state) => state.isShared);
  const rows = useObjectiveComparative((state) => state.rows);
  const columns = useObjectiveComparative((state) => state.columns);

  const { addToast } = useToastContext();

  const { mutate: updatePreset } = useUpdatePreset(preset?.id);

  const updateExistingPreset = () => {
    const payloadBody = {
      creator: creator,
      isShared: isShared,
      columns: columns,
      rows: rows,
      insights: insights
    };

    updatePreset(payloadBody, {
      onSuccess: () => {
        addToast({
          title: 'Success',
          msg: 'Your preset has been replaced',
          type: 'success'
        });
        onClose();
      }
    });
  };

  return (
    <Modal onClose={onClose} minWidth={480} customClass="w-[480px]">
      <Modal.Header title="Replace Existing Preset" />
      <Modal.Body>
        <div className="w-full px-[24px] my-[4px]">
          <p className="typography-paragraph">
            {getObjectiveLocale(
              'Are you sure you want to replace existing preset? This action cannot be reversed.'
            )}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={{
          text: getObjectiveLocale('Yes'),
          dataCy: 'replace-preset',
          onClick: updateExistingPreset
        }}
        secondaryButton={{
          text: getObjectiveLocale('Cancel'),
          dataCy: 'cancel-replace',
          onClick: onClose
        }}
        useBorder={false}
      />
    </Modal>
  );
};

export default ModalReplacePreset;

import React, { createRef, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import { getActivities } from 'client/ActivitiesClient';
import { useProjectDetail } from 'context/ProjectDetailContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useUrl } from 'hooks/useUrl';
import {
  formatBytes,
  getDateLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';

import Avatar from 'components/design-system/Avatar';
import Checkbox from 'components/shared/Checkbox';
import CommentBox from 'components/sidebar/CommentBox';
import AttachmentList from 'components/sidebar/objective-detail/AttachmentList';
import { getObjectiveCloneActionText } from 'src/utils/ObjectivesHelper';

const Activities = ({
  projectId,
  isAllActivities,
  setIsAllActivities,
  lastUpdated,
  setLastUpdated
}) => {
  const [activities, setActivities] = useState([]);
  const [clearEditor, setClearEditor] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const intersectTarget = createRef();

  const { refetchQueries } = useRefetchQuery();
  const { involvements } = useProjectDetail();

  const fetchActivities = async (olderThan) => {
    const params = {
      limit: 10,
      isAll: isAllActivities,
      ...(olderThan && { olderThan: olderThan })
    };
    return await getActivities(projectId, params);
  };

  const queryKey = ['project', 'activities', +projectId, isAllActivities];
  const { hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => fetchActivities(pageParam),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || undefined,
      onSuccess: (data) => {
        const newActivities = flatten(
          data?.pages?.map((page) => {
            return page?.data?.map((d) => {
              return d;
            });
          })
        );
        setActivities(newActivities);
      }
    }
  );

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting &&
      hasNextPage &&
      !isFetchingNextPage &&
      fetchNextPage(),
    threshold: 1
  });

  const onSubmitComment = () => {
    refetchQueries(queryKey);
    setClearEditor(true);
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      refetchQueries(queryKey);
    }
    // eslint-disable-next-line
  }, [isAllActivities]);

  useEffect(() => {
    if (activities?.length > 0 && lastUpdated) {
      if (lastUpdated?.id !== activities[0]?.id) {
        setLastUpdated({
          id: activities[0]?.id,
          name: activities[0]?.user?.name,
          time: activities[0]?.doneAt
        });
      }
    }
  }, [activities, lastUpdated, setLastUpdated]);

  return (
    <div className="flex flex-col h-[calc(100vh-171px)]">
      <div className="px-[40px] py-[16px]">
        <Checkbox
          id="include-task-activity-checkbox"
          checked={isAllActivities}
          onChange={() => setIsAllActivities(!isAllActivities)}
          dataCy="include-task-activity-checkbox"
        >
          <p className="typography-paragraph text-n-900 ml-[8px]">
            {getObjectiveLocale('Include Task Activity')}
          </p>
        </Checkbox>
      </div>
      <div className="px-[40px] overflow-y-auto">
        {activities.length > 0 &&
          activities.map((activity, index) => (
            <Activity activity={activity} key={index} />
          ))}
        {hasNextPage && (
          <div className="h-[1px]" ref={intersectTarget}>
            &nbsp;
          </div>
        )}
      </div>
      <div
        className="mt-auto w-full bg-n-200 px-[40px] py-[16px]"
        style={{ boxShadow: 'inset 0px 1px 0px rgba(203, 213, 225, 1)' }}
      >
        <CommentBox
          id={projectId}
          includes={
            involvements?.length > 0
              ? involvements?.map((involvement) => involvement?.user?.id)
              : []
          }
          clearEditor={clearEditor}
          setClearEditor={setClearEditor}
          onSubmit={onSubmitComment}
        />
      </div>
    </div>
  );
};

const Activity = ({ activity }) => {
  const { url } = useUrl();
  const history = useHistory();

  const [isUpdateProgress, setIsUpdateProgress] = useState(false);
  const [hasValues, setHasValues] = useState(false);
  const [showValues, setShowValues] = useState(true);

  const { user, text, objective, comment, object, doneAt, metaClone } =
    activity || {};

  useEffect(() => {
    setHasValues(activity?.text?.previousValue && activity?.text?.newValue);
    setIsUpdateProgress(
      activity?.text?.action === 'updated the target progress'
    );
  }, [activity]);

  return (
    <div className="flex flex-col w-full py-[12px]">
      <div className="flex w-full">
        <Avatar name={user?.name} src={user?.profilePicture} size={40} />
        <div className="flex flex-col w-full ml-[16px]">
          <p className="typography-paragraph text-n-900">
            <span className="typography-h400-longform">{user?.name}</span>{' '}
            {isEmpty(metaClone) ? (
              <>
                {text?.action} {getObjectiveLocale('of')}{' '}
                <span className="typography-h400-longform">
                  {objective?.name}
                </span>
              </>
            ) : (
              getObjectiveCloneActionText(text?.action, metaClone, url, history)
            )}
            {isUpdateProgress ? (
              <span>
                {' '}
                {getObjectiveLocale('from')}{' '}
                <span className="typography-h400-longform">
                  {text?.previousValue}
                </span>{' '}
                {getObjectiveLocale('to')}{' '}
                <span className="typography-h400-longform">
                  {text?.newValue}
                </span>
              </span>
            ) : (
              hasValues && (
                <span>
                  .{' '}
                  <span
                    className="text-base-600 cursor-pointer"
                    onClick={() => setShowValues(!showValues)}
                  >
                    {getObjectiveLocale(showValues ? 'Hide' : 'Show')}
                  </span>
                </span>
              )
            )}
          </p>
          {comment && (
            <p className="typography-paragraph text-n-900 mb-[8px]">
              {comment}
            </p>
          )}
          {!isUpdateProgress && hasValues && showValues && (
            <div className="flex flex-col bg-n-000 border-solid border-[1px] border-n-300 rounded-[4px] mb-[8px] p-[12px]">
              <p className="typography-h200 text-n-800 mb-[8px]">
                {getObjectiveLocale('New')}{' '}
                <span className="typography-h100">{text?.newValue}</span>
              </p>
              <p className="typography-h200 text-n-800">
                {getObjectiveLocale('Prev')}{' '}
                <span className="typography-h100">{text?.previousValue}</span>
              </p>
            </div>
          )}
          {object &&
            object?.state === 'available' &&
            (object?.type === 'image' || object?.type === 'document') && (
              <a
                className="mb-[8px] w-full"
                href={object?.downloadUrl ? object?.downloadUrl : false}
                download
              >
                <AttachmentList
                  fileName={object?.name}
                  fileSize={formatBytes(object?.size)}
                  deleteAble={false}
                  takenOn={`taken on ${getDateLocale(object.fileTimestamp, {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hourCycle: 'h12',
                    hour: 'numeric',
                    minute: 'numeric'
                  })}`}
                  useNewDesign={true}
                />
              </a>
            )}
          <p className="typography-h100 text-n-600">
            {getDateLocale(doneAt, {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hourCycle: 'h12',
              hour: 'numeric',
              minute: 'numeric'
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Activities;

import React, { useEffect, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useRouteMatch } from 'react-router-dom';

import flatten from 'lodash/flatten';

import { getTags } from 'client/TagClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import ListEmptyState from 'components/shared/ListEmptyState';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import useFilter from 'src/hooks/useFilter';
import { usePreference } from 'src/hooks/usePreferenceFilter';
import { useDeepEffect } from 'src/utils/useDeepEffect';

import AccordionLabel from './AccordionLabel';

const LIMIT = 10;

const OverviewAnalytics = () => {
  const { match } = useUrl();
  const { params } = useRouteMatch();
  const { refetchQueries } = useRefetchQuery();
  const filterBefore = useFilter(
    (state) => state.filterById?.objectiveAnalytics
  );
  const filterPayload = useRef(null);

  const {
    data: filterPreference,
    isLoaded,
    saveFilter
  } = usePreference({
    module: 'objectives',
    page: 'dashboard',
    section: 'list'
  });

  const getRestructuredFilter = (filter) => {
    if (!filter) return;
    const filterMenu = filter?.filter;
    const outsideFilter = { ...filter };
    delete outsideFilter.filter;
    return {
      ...filterMenu,
      ...outsideFilter
    };
  };

  const dataFilterPreference = getRestructuredFilter(filterPreference?.data);

  const defaultFilter = {
    year: new Date().getFullYear()
  };

  const labelFilter = filterBefore?.label || [];

  const paramsTags = {
    name: filterBefore?.label
  };

  const fetchTags = async (olderThan) => {
    return getTags({
      limit: LIMIT,
      ...paramsTags,
      ...(olderThan && { olderThan: olderThan })
    });
  };

  const queryKey = ['objectives', 'analytics', labelFilter];

  const { data, isFetching } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => fetchTags(pageParam),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || undefined,
      staleTime: 5 * 60 * 1000,
      enabled: isLoaded
    }
  );
  const labels = flatten(
    data?.pages?.map((page) => {
      const predefinedLabel = page?.data?.predefined?.map((d) => {
        return d;
      });

      const othersLabel = page?.data?.others?.map((d) => {
        return d;
      });
      return [...predefinedLabel, ...othersLabel];
    })
  );

  const saveCallback = () => {
    if (params?.objectiveId) {
      refetchQueries(['objective', 'overview', parseInt(params?.objectiveId)]);
      refetchQueries([
        'objective',
        'progress-overtime',
        parseInt(params?.objectiveId)
      ]);
    }
  };

  const payloadFormatter = (data) => {
    return {
      module: 'objectives',
      page: 'dashboard',
      section: 'list',
      filter: {
        ...(data?.label ? { label: data.label } : []),
        ...(data?.owner ? { owner: data.owner } : [])
      },
      sortColumn: data?.sortColumn,
      sortDirection: data?.sortDirection,
      year: data?.year
    };
  };

  useDeepEffect(() => {
    const payload = payloadFormatter(filterBefore);
    filterPayload.current = payload;
  }, [filterBefore]);

  const saveFilterCallback = async () => {
    await saveFilter(filterPayload.current);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', saveFilterCallback, {
      capture: true
    });
    return () => {
      saveFilterCallback();
      window.removeEventListener('beforeunload', saveFilterCallback, {
        capture: true
      });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AdvancedFilterObjectives
        id="objectiveAnalytics"
        filterOptions={['search', 'sort', 'yearFilter', 'menuFilter']}
        group={'no-group'}
        defaultFilter={{ ...defaultFilter, ...dataFilterPreference }}
        defaultFilterToReset={defaultFilter}
        page="dashboard"
        customClass="mb-[16px]"
      />
      {isFetching || labels?.length == 0 ? (
        <ListEmptyState
          containerClassname="h-auto mt-[96px]"
          emptyIcon="icon-loading-page"
          withLoadingIndicator={isFetching}
          title={getObjectiveLocale(
            labels?.length == 0
              ? 'No objective found'
              : 'Loading your objective..'
          )}
          subtitle={getObjectiveLocale(
            labels?.length == 0
              ? 'The objective establishes a sense of direction that guides your decisions and task to be more aligned with your company mission. '
              : 'Your objective list will be shown up here'
          )}
        />
      ) : (
        labels?.map((label) => (
          <div key={label}>
            <AccordionLabel label={label} />
          </div>
        ))
      )}
      <React.Suspense fallback={<div></div>}>
        {match && <SidebarMyGoals saveCallback={saveCallback} />}
      </React.Suspense>
    </>
  );
};

export default OverviewAnalytics;

import React from 'react';

import dayjs from 'dayjs';

import useFilter from 'hooks/useFilter';
import { phaseName } from 'utils/FormalReview';
import { getCssVariableValue, getObjectiveLocale } from 'utils/HelperUtils';

import FormattedNumber from 'components/design-system/FormattedNumber';
import Dropdown, {
  useDropdown
} from 'components/design-system/dropdown/Dropdown';
import ProgressBar from 'components/design-system/progress-bar/ProgressBar';
import Table from 'components/design-system/table/Table';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';

const CyclesTable = ({
  cycles,
  cycleOptions,
  setSidebar,
  currentPage,
  handleNextPage,
  handlePrevPage,
  hasMore,
  isLoading
}) => {
  const filter = useFilter((state) => state.filterById?.cyclesFilter);

  const tableHeaders = [
    { name: 'CYCLE NAME', widthClass: 'w-[340px]', grow: true },
    { name: 'STATE', widthClass: 'w-[100px]' },
    { name: 'SCHEDULE', widthClass: 'w-[100px]' },
    { name: 'ACTIVE PHASES', widthClass: 'w-[338px]', grow: true },
    {
      name: 'OVERALL PROGRESS',
      widthClass: 'w-[160px]',
      useHeaderMargin: false
    },
    { name: '', widthClass: 'w-[56px]' }
  ];

  const cycleState = {
    draft: 'Draft',
    in_progress: 'Active',
    done: 'Ended',
    deleted: 'Deleted'
  };

  const getCyclePeriod = (data) => {
    let start = dayjs(data.reviewProcessStartsAt).format('DD MMM YYYY');
    let end = dayjs(data.reviewProcessEndsAt).format('DD MMM YYYY');
    return !data.reviewProcessStartsAt || !data.reviewProcessStartsAt
      ? 'No Schedule'
      : 'start from ' + start + ' - ' + end;
  };

  const getCycleSchedule = (data) => {
    const recurrence = data.recurrence ? 'Recurring' : 'One Time';
    return !data.reviewProcessStartsAt || !data.reviewProcessStartsAt
      ? '--'
      : recurrence;
  };

  const getActivePhases = (data) => {
    return data?.phases
      ?.map((phase) => {
        if (phase.state === 'in_progress')
          return getObjectiveLocale(phaseName(phase.type));
      })
      ?.filter((phase) => phase)
      ?.join(', ');
  };

  return (
    <div>
      {isLoading ? (
        <LoadingComponent />
      ) : cycles?.length === 0 ? (
        <ListEmptyState
          fullHeight
          emptyIcon={filter?.q ? 'icon-no-result-found' : 'icon-no-cycles'}
          title={getObjectiveLocale(
            filter?.q ? 'No result found' : 'No cycle match your filter'
          )}
          subtitle={getObjectiveLocale(
            filter?.q
              ? 'Did you probably type the keyword incorrectly? We are sorry that what you searched was unfortunately not found.'
              : "Once your cycles are set, you'll see them here."
          )}
        />
      ) : (
        <>
          <Table headers={tableHeaders} fixedLeftCount={1} fixedRightCount={1}>
            {cycles?.map((cycle, index) => (
              <Table.Row
                onClick={() => setSidebar(cycle)}
                dataCy={`table-row-${index}`}
                key={`table-row-${index}`}
              >
                <Table.Column key="table-column-0">
                  <div className="flex flex-col w-full">
                    <TruncateParagraph className="typography-h400 text-n-900">
                      {cycle?.name}
                    </TruncateParagraph>
                    <TruncateParagraph className="typography-h100 text-n-800">
                      {getCyclePeriod(cycle)}
                    </TruncateParagraph>
                  </div>
                </Table.Column>
                <Table.Column key="table-column-1">
                  {cycleState?.[cycle?.state]}
                </Table.Column>
                <Table.Column key="table-column-2">
                  {getCycleSchedule(cycle)}
                </Table.Column>
                <Table.Column key="table-column-3">
                  <TruncateParagraph>
                    {getActivePhases(cycle)}
                  </TruncateParagraph>
                </Table.Column>
                <Table.Column key="table-column-4">
                  <div className="flex items-center w-full">
                    <ProgressBar
                      width={40}
                      height={16}
                      actualProgress={cycle?.progress}
                      actualColor={getCssVariableValue('--g-600')}
                    />
                    <div className="flex items-center justify-end w-[60px] ml-[8px]">
                      <FormattedNumber
                        number={cycle?.progress}
                        minDecimalLength={2}
                        customClass="typography-h400"
                      />
                      <p className="typography-h400">%</p>
                    </div>
                  </div>
                </Table.Column>
                <Table.Column noPadding key="table-column-5">
                  <div
                    className="flex items-center justify-center w-full h-full"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown>
                      <OptionsTrigger index={index} />
                      <OptionsMenuItems options={cycleOptions(cycle)} />
                    </Dropdown>
                  </div>
                </Table.Column>
              </Table.Row>
            ))}
          </Table>
          <div className="flex justify-end w-full mt-[12px] mb-[40px]">
            <PaginationModule
              pageNumber={currentPage}
              handleNext={handleNextPage}
              handlePrev={handlePrevPage}
              hasMore={hasMore}
            />
          </div>
        </>
      )}
    </div>
  );
};

const OptionsTrigger = ({ index }) => {
  const { open } = useDropdown();
  return (
    <Dropdown.Trigger
      customClass={open ? '' : 'px-[-16px] w-full hidden group-hover:flex'}
      dataCy={`list-cycle-action-${index}`}
    >
      <SVGIcon
        iconName="icon-keyboard_control"
        size={24}
        fillColor="var(--n-600)"
      />
    </Dropdown.Trigger>
  );
};

const OptionsMenuItems = ({ options }) => {
  const { open } = useDropdown();
  return (
    open && (
      <FloatingComponent
        lockScroll={false}
        customContentHeight={
          options?.length >= 5 ? 178 : options?.length * 32 + 18
        }
      >
        <Dropdown.MenuItems
          options={options}
          type="fixed"
          position="right"
          customContainerClass="min-w-[230px] overflow-auto"
          dataCy="list-cycle-action"
        />
      </FloatingComponent>
    )
  );
};

export default CyclesTable;

import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import { useProfileFilter } from 'context/ProfileFilterContext.js';
import { useProfileValue } from 'context/ProfileValueContext';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ListEmptyState from 'components/shared/ListEmptyState';

import SkillValueComponentTab from './SkillValueComponentTab';

function ValuesTab(props) {
  const { userId, permission } = props;

  const { profileValueImprovementSee } = permission || {};

  const { appType } = useUser();

  const { filter } = useProfileFilter();
  const { strengths, weaknesses, getStrengthData, getWeaknessData } =
    useProfileValue();

  const debouncedFilter = useDebounce(filter, 1000);
  const profileValueStrengthSee = usePermission(
    'profileValueStrengthSee',
    permission
  );

  useEffect(() => {
    profileValueStrengthSee && getStrengthData(props.match.params.userId, 0);
    profileValueImprovementSee &&
      appType !== 'culture' &&
      getWeaknessData(props.match.params.userId, 0);
  }, [debouncedFilter]);

  return (
    <div className="values-tab">
      {(strengths?.length === 0 && weaknesses?.length === 0) ||
      (!profileValueStrengthSee && !profileValueImprovementSee) ? (
        <ListEmptyState
          fullHeight
          emptyIcon={
            !profileValueStrengthSee && !profileValueImprovementSee
              ? 'icon-no-permission'
              : 'icon-no-value'
          }
          title={getObjectiveLocale(
            !profileValueStrengthSee && !profileValueImprovementSee
              ? 'No permission'
              : 'There is no value to be displayed'
          )}
          subtitle={getObjectiveLocale(
            !profileValueStrengthSee && !profileValueImprovementSee
              ? "You don't have permission to see this content"
              : 'Once you are registered on Culture, your values will be displayed here'
          )}
        />
      ) : (
        <SkillValueComponentTab
          strengths={strengths}
          weaknesses={weaknesses}
          props={props}
        />
      )}
    </div>
  );
}

export default withRouter(ValuesTab);

import React, { useCallback, useEffect, useState } from 'react';

import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import { useTeamDetail } from 'context/team/TeamDetailContext';
import useFilter from 'hooks/useFilter';
import useScrollHeaderBorder from 'hooks/useScrollHeaderBorder';

import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import Objectives from 'components/objectives/Objectives';

const TeamGoals = ({ isTriggerCreate }) => {
  let template = ['filter', 'objectives'];
  let gridTemplate = "'" + template.join("' '") + "'";
  document.getElementsByTagName('body')[0].classList = 'scroll-locked';

  const { user, config } = useUser();
  const { addToast } = useToastContext();
  const filterBefore = useFilter((state) => state.filter);
  const setFilter = useFilter((state) => state.setFilter);
  const { teamId, data: team } = useTeamDetail();

  const [isInlineCreate, setInlineCreate] = useState(false);

  const { includeTeamGoals } = config;

  const additionalParams = !includeTeamGoals
    ? {
        goals: 'team'
      }
    : [];

  let extraFilter = {
    reviewsVisibility: 1,
    teamId: teamId,
    goals: 'team',
    parentNotAssignedTo: user.id,
    state: team?.archivedAt ? ['archived'] : []
  };

  const onClickCreateNewGoal = useCallback(() => {
    if (filterBefore?.group && filterBefore?.group != 'no-group') {
      setFilter({
        ...filterBefore,
        group: 'no-group'
      });
      addToast({
        title: 'Filter',
        msg: 'Filter group has been reset',
        type: 'info'
      });
    }
    setInlineCreate(true);
    const content = document.getElementsByClassName('main-body')[0];
    content.scrollTop = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, setFilter]);

  useScrollHeaderBorder();

  useEffect(() => {
    isTriggerCreate === 'goals' && !isInlineCreate && onClickCreateNewGoal();
  }, [isTriggerCreate, isInlineCreate, onClickCreateNewGoal]);

  return (
    <div
      className={`grid mb-[64px] px-[0px] gap-[16px] mt-[24px] pb-[24px]`}
      style={{ gridTemplateAreas: gridTemplate }}
    >
      <AdvancedFilterObjectives
        filterOptions={['search', 'group', 'sort', 'menuFilter', 'dateFilter']}
        gridArea
        useConfig={true}
        group={config?.myGoalsGroupByOptions?.[0] || 'no-group'}
        defaultFilter={{
          phase: config?.defaultFilterPhase
        }}
        defaultDateFilterOptions={[
          'annual',
          'all_time',
          'next_year',
          'quarter',
          'custom'
        ]}
        restructureFilter={(filter) => {
          if (filter?.phase?.includes('archived')) {
            delete filter?.phase;
          }
          if (!filter?.owner?.assignee?.length) {
            let newOwner = { ...filter.owner };
            newOwner.assignee = [{ ...user, userId: user.id }];
            return {
              ...filter,
              ...additionalParams,
              owner: newOwner
            };
          }
          return { ...filter, ...additionalParams };
        }}
        page="myTeamGoals"
      >
        <Objectives
          cardModel={'goal-list'}
          page={'myTeamGoals'}
          extraFilter={extraFilter}
          gridArea
          isInlineCreate={isInlineCreate}
          setInlineCreate={setInlineCreate}
          emptyStateAction={() => onClickCreateNewGoal()}
          isForceNewLayout={true}
          isAbleToCreate={team?.permissions?.includes('create_goal')}
          emptyStateData={
            team?.archivedAt
              ? {
                  title: `This ${team?.teamType} didn’t have any goal`,
                  description: `This ${team?.teamType} was archived and has no goals.\n  Reactivate this ${team?.teamType} to create a team goal.`
                }
              : null
          }
        />
      </AdvancedFilterObjectives>
    </div>
  );
};

export default TeamGoals;

import React, { useEffect, useState } from 'react';

import { getCssVariableValue, getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import InlineDialog from 'components/design-system/inline-dialog/InlineDialog';
import Badge from 'components/shared/Badge';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';

function InlineDialogFilter({
  subText,
  setSearch,
  title,
  wordingInfo,
  listData,
  selectedData,
  setSelectedData,
  notMultiple,
  dataCy
}) {
  const [wording, setWording] = useState(wordingInfo);

  const onOpenDialog = () => {
    setSearch('');
  };

  const SelectedItem = () => {
    if (selectedData.length === 0) return null;
    return (
      <InlineDialog.SelectedItem>
        {selectedData.map((label, id) => (
          <div className="flex items-center mr-[8px]" key={id}>
            <Badge
              content={label}
              bgColorHex={getCssVariableValue('--n-300')}
              className="mr-[4px] whitespace-nowrap cursor-pointer"
              showCursorNotAllowed={false}
            />
            <SVGIcon
              size="16"
              iconName="icon-clear"
              fillColor="var(--n-600)"
              onClick={() => handleChangeDialog(label, 'remove')}
              dataCy={`close-label-${id}`}
            />
          </div>
        ))}
      </InlineDialog.SelectedItem>
    );
  };

  const menuItemsProps = {
    customSelectedComponent: !notMultiple ? <SelectedItem /> : <></>,
    customClass: 'w-[315px]',
    onOpenDialog
  };

  const handleChangeDialog = (sel, type) => {
    if (notMultiple) {
      setSelectedData(type ? [] : [sel]);
      return;
    }
    let newSelectedValue = [...selectedData];
    if (!newSelectedValue.some((value) => value === sel)) {
      newSelectedValue.push(sel);
    } else {
      newSelectedValue = newSelectedValue.filter(
        (newSelected) => newSelected !== sel
      );
    }
    setSelectedData(newSelectedValue);
  };

  useEffect(() => {
    setWording(wordingInfo);
  }, [wordingInfo]);

  return (
    <InlineDialog
      type={notMultiple ? 'single' : 'multiple'}
      header={subText}
      search={(e) => setSearch(e.target.value)}
    >
      <InlineDialog.Trigger customClass="mr-[8px]" dataCy={dataCy}>
        <Button.Secondary stopPropagation={false}>
          <p className="typography-h400 mr-[4px]">
            {getObjectiveLocale(title)}:
          </p>
          {getObjectiveLocale(wordingInfo)}
          <SVGIcon
            size="24"
            fillColor="var(--n-800)"
            iconName="icon-dropdown-caret"
            customClass="ml-[8px]"
          />
        </Button.Secondary>
      </InlineDialog.Trigger>
      <InlineDialog.MenuItems {...menuItemsProps}>
        {listData?.map((data, index) => (
          <InlineDialog.MenuItem
            onClick={() => handleChangeDialog(data)}
            key={index}
          >
            <div className="flex flex-col">
              {!notMultiple ? (
                <Checkbox
                  name={data}
                  value={data || ''}
                  id={data}
                  checked={selectedData.includes(data)}
                  labelClass="cursor-pointer"
                  onClick={() => handleChangeDialog(data)}
                  disabled={
                    !selectedData.includes(data) && selectedData?.length == 7
                  }
                >
                  <span className="typography-button ml-[8px]">{data}</span>
                </Checkbox>
              ) : (
                <span className="typography-button ml-[8px]">{data}</span>
              )}
            </div>
          </InlineDialog.MenuItem>
        ))}
      </InlineDialog.MenuItems>
    </InlineDialog>
  );
}

export default InlineDialogFilter;

import React, { useState } from 'react';
import { useLocation } from 'react-router';

import { editObjective } from 'client/ObjectivesClient';
import { getObjectivePhases } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';

import PhaseDropdown from 'components/tasks/dropdown/PhaseDropdown';

const Phase = React.memo(
  ({ task, stopPropagation, type = 'list', filter, isProject, isSubTask }) => {
    const { id, phase, section } = task || {};

    const {
      refetchQueries,
      refetchObjective,
      invalidateQueries,
      invalidateTaskQueries
    } = useRefetchQuery();
    const { pathname } = useLocation();

    const [listPhases, setListPhases] = useState([]);

    const isProjectPage = pathname.includes('project');

    const getPhases = async () => {
      let params = {
        taskId: id
      };
      const { data } = await getObjectivePhases(params);
      if (data) {
        setListPhases(data);
      }
    };

    const beforeTrigger = async (e, setOpen) => {
      stopPropagation && e.stopPropagation();
      if (!e.shiftKey && !(e.ctrlKey || e.metaKey)) {
        setOpen();
        getPhases();
      }
    };

    const onChangeHandler = async (phaseId, phaseName) => {
      if (phaseId != phase?.id) {
        const payload =
          filter?.showTask === 'project'
            ? { phaseName: phaseName }
            : { phaseId: phaseId };

        const { isSuccess } = await editObjective(id, payload);
        if (isSuccess) {
          if (filter?.group === 'phase' && type === 'list' && !isSubTask) {
            if (isProject) {
              refetchQueries([
                'objectives',
                'mytasks',
                phase?.name?.toLowerCase(),
                section?.name?.toLowerCase()
              ]);
              refetchQueries([
                'objectives',
                'mytasks',
                phaseName?.toLowerCase(),
                section?.name?.toLowerCase()
              ]);
            } else {
              refetchQueries([
                'objectives',
                'mytasks',
                phase?.name?.toLowerCase()
              ]);
              refetchQueries([
                'objectives',
                'mytasks',
                phaseName?.toLowerCase()
              ]);
            }
          }
          refetchObjective(id);

          invalidateQueries(
            ['objectives', 'mytasks', phaseName?.toLowerCase()],
            {
              refetchActive: false
            }
          );

          invalidateTaskQueries({ task });
        }
      }
    };

    return (
      <div
        className={type === 'list' ? 'w-full h-full' : ''}
        onClick={(e) => type === 'board' && e.stopPropagation()}
      >
        <PhaseDropdown
          task={task}
          onChange={onChangeHandler}
          useTrigger
          beforeTrigger={beforeTrigger}
          customClass={type === 'list' ? 'w-full h-full' : ''}
          position={type === 'list' ? 'right' : 'left'}
          listPhases={listPhases}
          type={type}
          withAddPhaseButton={isProjectPage}
        />
      </div>
    );
  }
);

Phase.displayName = 'Phase';

export default Phase;

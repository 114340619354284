import React, { useEffect } from 'react';

import Button from 'components/design-system/Button';
import DynamicTabGrey from 'components/shared/Tabs/DynamicTabGrey';

import CardRatingConfig from './CardRatingConfig';

const ContentRatingConfiguration = ({
  configRating,
  setConfigRating,
  showOptions,
  setShowOptions,
  isWarning,
  setIsWarning,
  isWarningLabel,
  setIsWarningLabel
}) => {
  const listShowOptions = ['score_and_label', 'score_only', 'label_only'];
  const showOptionsDict = {
    label_only: 'Hide rating and show label only',
    score_and_label: 'Rating with label',
    score_only: 'Rating without label'
  };

  const onChangeRating = (value, attr, idx) => {
    let arrayConfig = [...configRating];
    if (attr == 'score' || attr == 'bottomThresholdScore') {
      arrayConfig[idx][attr] = value == 0 ? value : Number(value);
    } else {
      arrayConfig[idx][attr] = value;
    }

    if (attr == 'upperThresholdScore') {
      if (idx < arrayConfig.length) {
        for (let i = idx + 1; i < arrayConfig.length; i++) {
          let inputBottomRange = document.getElementById(
            'input-bottom-rating-' + i
          );
          let inputUpperRange = document.getElementById(
            'input-upper-rating-' + i
          );

          arrayConfig[i]['bottomThresholdScore'] = arrayConfig[i - 1][
            'upperThresholdScore'
          ]
            ? arrayConfig[i - 1]['upperThresholdScore']
            : null;
          arrayConfig[i]['upperThresholdScore'] = arrayConfig[i][
            'bottomThresholdScore'
          ]
            ? arrayConfig[i]['bottomThresholdScore'] + 1
            : null;

          inputBottomRange.value = arrayConfig[i]['bottomThresholdScore'];
          inputUpperRange.value = arrayConfig[i]['upperThresholdScore'];
        }
      }
    }

    setConfigRating(arrayConfig);
  };

  const addNewRating = () => {
    let newConfig = [...configRating];
    let lastIndex = newConfig.length;
    newConfig[lastIndex - 1].upperThresholdScore =
      newConfig[lastIndex - 2].upperThresholdScore + 1;

    newConfig.push({
      score: newConfig[lastIndex - 1].score
        ? newConfig[lastIndex - 1].score + 1
        : null,
      name: '',
      description: '',
      bottomThresholdScore: newConfig[lastIndex - 1]?.upperThresholdScore,
      upperThresholdScore: null,
      colorHex: 'ED2E1D',
      id: Math.random()
    });

    setConfigRating(newConfig);
  };

  const _onKeyDown = (e) => {
    if (e.keyCode == 188) {
      e.preventDefault();
    }
  };

  const _onKeyPress = (e) => {
    let isNumberAndDotOnly = /^[0-9]*\.?[0-9]*$/;
    if (!isNumberAndDotOnly.test(e.key)) {
      e.preventDefault();
    }
  };

  const checkIsValueEmpty = () => {
    const isSomeRatingEmpty = configRating.some((val, index) => {
      isNaN(val.bottomThresholdScore) ||
        // last upperTreshold cannot be null, except for the last one
        (!val.upperThresholdScore && index != configRating.length - 1) ||
        isNaN(val.score);
    });

    setIsWarning({
      show: isSomeRatingEmpty,
      message: isSomeRatingEmpty ? "Rating and range can't be blank" : ''
    });
  };

  const checkIsLabelEmpty = () => {
    const isSomeLabelEmpty = configRating.every((val) => val.name);
    setIsWarningLabel({
      show: !isSomeLabelEmpty,
      message: !isSomeLabelEmpty ? "Label can't be empty" : ''
    });
  };

  useEffect(() => {
    checkIsValueEmpty();
  }, [configRating]);

  useEffect(() => {
    if (showOptions != 'score_only') {
      checkIsLabelEmpty();
    }
  }, [configRating, showOptions]);

  return (
    <div
      id="rating-configuration"
      className="container-content-rating-configuration"
    >
      <p className="typography-h500">Rating Configuration</p>
      <p className="content-subtitle">
        Set the minimum and maximum rating for this rating template (minimum 2,
        maximum 15)
      </p>

      <DynamicTabGrey
        listName={listShowOptions}
        nameDict={showOptionsDict}
        state={showOptions}
        setState={setShowOptions}
      />

      <CardRatingConfig
        showOptions={showOptions}
        onChangeRating={onChangeRating}
        isWarning={isWarning}
        isWarningLabel={isWarningLabel}
        _onKeyPress={_onKeyPress}
        _onKeyDown={_onKeyDown}
        configRating={configRating}
        setConfigRating={setConfigRating}
      />

      {configRating.length < 15 && (
        <div className="flex justify-center items-center bg-n-200 rounded-[4px] h-[116px] mt-[24px] bg-n-300">
          <Button
            datacy="button-add-rating-config"
            onClick={() => addNewRating()}
          >
            <i className="fa fa-plus"></i>Add Another Attributes
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContentRatingConfiguration;

import React from 'react';

import dayjs from 'dayjs';

import { getDateWithForcedTimezone } from 'utils/DateUtils';
import { abbrevNumber, getObjectiveLocale } from 'utils/HelperUtils';
import {
  whichBgColorClassName,
  whichColor,
  whichStatus
} from 'utils/ObjectivesHelper';

import FormattedNumber from 'components/design-system/FormattedNumber';
import ProgressObjective from 'components/objectives/compact-objective/fragments/ProgressObjective';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

export default function ObjectiveProgressBar({
  startDate,
  dueDate,
  timeframeProgress,
  timeFrame,
  measurement
}) {
  const nowDate = new dayjs();

  const {
    unit,
    currentValue,
    progressUpdated,
    progressColorHex,
    progressPercentage,
    expectedProgressPercentage,
    targetValue
  } = measurement || {};

  const colorProgress =
    timeframeProgress?.type === 'stagnant'
      ? 'var(--n-600)'
      : timeframeProgress?.type == 'increase'
      ? 'var(--g-600)'
      : 'var(--r-600)';

  const tooltipProgressText = [
    getObjectiveLocale(`Expected: ${expectedProgressPercentage}%`),
    <br key="br" />,
    whichStatus[progressColorHex]
  ];

  const emptyProgress = nowDate.isAfter(startDate) ? (
    !progressUpdated ? (
      <p className={`typography-h400 text-start text-n-600`}>
        {getObjectiveLocale('Not updated')}
      </p>
    ) : (
      <p
        className={`typography-h400 text-start ${whichColor[progressColorHex]}`}
      >
        {whichStatus[progressColorHex]}
      </p>
    )
  ) : (
    <p className={`typography-h400 text-start text-n-600`}>
      {getObjectiveLocale('Not started')}
    </p>
  );

  return (
    <>
      <span className="flex flex-row mb-[14px]">
        <TooltipContainer
          show={true}
          text={getDateWithForcedTimezone(startDate, 'DD-MM-YYYY')}
        >
          <span className="typography-h100 text-n-600">
            {getDateWithForcedTimezone(startDate, 'D MMM YY')}
            &nbsp;-&nbsp;
          </span>
        </TooltipContainer>
        <TooltipContainer
          show={true}
          text={getDateWithForcedTimezone(dueDate, 'DD-MM-YYYY')}
        >
          <span className="typography-h100 text-n-600">
            {getDateWithForcedTimezone(dueDate, 'D MMM YY')}
          </span>
        </TooltipContainer>
      </span>
      <p className="mb-[4px]">
        <span className="text-n-600 typography-h700 mr-[4px]">{unit}</span>
        <span
          style={{
            color: `#${
              progressColorHex == '44DB5E' ? '16A34A' : progressColorHex
            }`
          }}
          className="typography-h700"
        >
          {abbrevNumber(currentValue)}
        </span>
        <span className="text-n-600 typography-h700 mx-[4px]">/</span>
        <span className="text-n-800 typography-h700">
          {abbrevNumber(targetValue)}
        </span>
      </p>
      <p className="flex items-center mb-[4px]">
        {timeframeProgress?.type !== 'stagnant' && (
          <SVGIcon
            iconName={`icon-arrow_drop_${
              timeframeProgress?.type == 'increase' ? 'up' : 'down'
            }`}
            size={16}
            fillColor={colorProgress}
          />
        )}
        <span style={{ color: colorProgress }} className="typography-h100">
          {timeframeProgress?.percentage && (
            <FormattedNumber
              number={timeframeProgress?.percentage}
              minDecimalLength={2}
              customClass="typography-h100"
            />
          )}
          %
        </span>
        <span className="typography-h100 ml-[4px] text-n-600">
          {getObjectiveLocale(`from last ${timeFrame.replace(/ly/, '')}`)}
        </span>
      </p>
      <ProgressObjective
        progressPercentage={progressPercentage}
        expectedProgressPercentage={expectedProgressPercentage}
        tooltipProgressText={tooltipProgressText}
        emptyProgress={emptyProgress}
        barColor={whichBgColorClassName(progressColorHex, false, '500')}
        textColor={whichColor[progressColorHex]}
        allowZero={progressUpdated}
        width={192}
      />
    </>
  );
}

import React, { useState } from 'react';

import { phaseName } from 'utils/FormalReview';

import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const ListSharedTracks = ({ trackName, trackType }) => {
  const [isEllipsis, setIsEllipsis] = useState(false);

  const handleRef = (ref) => {
    setIsEllipsis(ref?.offsetWidth < ref?.scrollWidth);
  };

  return (
    <>
      <div className="mb-[20px]">
        <TooltipContainer show={isEllipsis} text={trackName}>
          <p
            className="typography-h400 text-n-900 mb-[4px] max-w-[348px] truncate"
            ref={(ref) => handleRef(ref)}
          >
            {trackName}
          </p>
        </TooltipContainer>
        <p className="typography-h100 text-n-800">{phaseName(trackType)}</p>
      </div>
    </>
  );
};

export default ListSharedTracks;

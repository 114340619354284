import React from 'react';

import dayjs from 'dayjs';
import produce, { setAutoFreeze } from 'immer';

import { getCycle } from 'client/admin/CyclesClient';
import { modifyPhaseData } from 'utils/FormalReview';

//CREATE CONTEXT
const CreateCycleContext = React.createContext();

//PROVIDER
function CreateCycleProvider(props) {
  //INITIAL STATE
  const initialState = {
    name: null,
    reviewedPeriodStartsAt: dayjs(`${new Date().getFullYear()}-01-01`),
    reviewedPeriodEndsAt: dayjs(`${new Date().getFullYear()}-12-31`),
    calibration: null,
    selectAll: false,
    includes: [],
    excludes: [],
    groupIds: [],
    selfReview: null,
    peerReview: null,
    upwardReview: null,
    managerReview: null,
    indirectManagerReview: null,
    restrictedTrackNavigation: false,
    lockSubmittedAssignment: false,
    reviewProcessStartsAt: null,
    reviewProcessEndsAt: null,
    inProgressDeliverable: false,
    recurrence: null,
    dependencies: [],
    currentCalibrationResultVisible: false,
    finalResultVisible: false,
    state: '',
    ratingTemplate: '',
    showScheduleDate: true
  };

  const [state, setState] = React.useState(initialState);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState, setState];

  return <CreateCycleContext.Provider value={contextValue} {...props} />;
}

//MUTATION
function useCreateCycle() {
  const [cycleData, immerSetState] = React.useContext(CreateCycleContext);

  const restructureCalibrationData = (calibration) => {
    return {
      type: calibration?.maxManagerLevel ? 'level' : 'manager',
      maxManagerLevel: calibration?.maxManagerLevel,
      startsAt: calibration.startsAt,
      endsAt: calibration.endsAt,
      reminders: calibration.reminders,
      trackConfigs: calibration.trackConfigs
    };
  };

  const getCycleData = async (id) => {
    const { data, error } = await getCycle(id);

    immerSetState((draft) => {
      draft.id = data.id;
      draft.name = data.name;
      draft.includes = data.revieweeIds;
      draft.reviewedPeriodStartsAt = data.reviewedPeriodStartsAt
        ? dayjs(data.reviewedPeriodStartsAt)
        : dayjs(`${new Date().getFullYear()}-01-01`);
      draft.reviewedPeriodEndsAt = data.reviewedPeriodEndsAt
        ? dayjs(data.reviewedPeriodEndsAt)
        : dayjs(`${new Date().getFullYear()}-12-31`);
      draft.selfReview = modifyPhaseData(data.selfReview);
      draft.peerReview = modifyPhaseData(data.peerReview);
      draft.upwardReview = modifyPhaseData(data.upwardReview);
      draft.managerReview = modifyPhaseData(data.managerReview);
      draft.indirectManagerReview = modifyPhaseData(data.indirectManagerReview);
      draft.includeCalibration = data.calibration ? true : false;
      draft.calibration = data.calibration
        ? restructureCalibrationData(data.calibration)
        : null;
      draft.disclosure = data.disclosure;
      draft.restrictedTrackNavigation = data.restrictedTrackNavigation;
      draft.lockSubmittedAssignment = data.lockSubmittedAssignment || false;
      draft.groupIds = data.groupIds;
      draft.inProgressDeliverable = data.inProgressDeliverable;
      draft.recurrence = data.recurrence;
      draft.scoreAspects = data.scoreAspects;
      draft.reviewProcessStartsAt = data.reviewProcessStartsAt;
      draft.reviewProcessEndsAt = data.reviewProcessEndsAt;
      draft.dependencies = data.dependencies;
      draft.currentCalibrationResultVisible =
        data.currentCalibrationResultVisible;
      draft.finalResultVisible = data.finalResultVisible;
      draft.totalSelectedUser = parseInt(data.revieweeIds.length);
      draft.state = data?.state;
      draft.ratingTemplate = data?.ratingTemplate;
      draft.showScheduleDate = data?.showScheduleDate;
      if (data?.importAnswersEnabled) {
        draft.importAnswersEnabled = data.importAnswersEnabled;
      }
    });
    return data;
  };

  const getTrackConfig = (phaseType, identifier) => {
    const isNumber = !isNaN(identifier);

    return cycleData?.[phaseType]?.trackConfigs?.find((trackConfig) => {
      return isNumber
        ? trackConfig.template.id == identifier
        : trackConfig.type == identifier;
    });
  };

  const findTrackConfigsIndex = (phaseType, trackType, templateId) => {
    const trackConfigs = [...cycleData[phaseType].trackConfigs];
    const trackConfigIndex = trackConfigs.findIndex((trackConfig) =>
      trackType === 'review_aspects_scoring'
        ? trackConfig.template.id == templateId
        : trackConfig.type == trackType
    );
    return { trackConfigs, trackConfigIndex };
  };

  const findTrackConfigsIndexByName = (phaseType, trackName) => {
    const trackConfigs = [...cycleData[phaseType].trackConfigs];
    const trackConfigIndex = trackConfigs.findIndex(
      (trackConfig) => trackConfig.name == trackName
    );
    return { trackConfigs, trackConfigIndex };
  };

  const setCycleId = (id) => {
    immerSetState((draft) => {
      draft.id = id;
    });
  };

  const changeCycleName = (name) => {
    immerSetState((draft) => {
      draft.name = name;
    });
  };

  const changeSelectAll = (state) => {
    immerSetState((draft) => {
      draft.selectAll = state;
    });
  };

  const changeIncludesIds = (ids) => {
    immerSetState((draft) => {
      draft.includes = ids;
    });
  };

  const changeExcludeIds = (ids) => {
    immerSetState((draft) => {
      draft.excludes = ids;
    });
  };

  const changeGroupIds = (ids) => {
    immerSetState((draft) => {
      draft.groupIds = ids;
    });
  };

  const changeDisplayResult = (result, value) => {
    immerSetState((draft) => {
      draft[result] = value;
    });
  };

  const changeDisplayResultScoreAspect = (value) => {
    immerSetState((draft) => {
      draft.scoreAspects = value;
    });
  };

  const changeRatingTemplate = (ratingTemplate) => {
    immerSetState((draft) => {
      draft.ratingTemplate = ratingTemplate;
    });
  };

  const togglePhase = (phase, value) => {
    immerSetState((draft) => {
      draft[phase] = value;
    });
  };

  const changeShowScheduleDate = (showScheduleDate) => {
    immerSetState((draft) => {
      draft.showScheduleDate = showScheduleDate;
    });
  };

  const changePhaseData = (phaseType, key, option) => {
    immerSetState((draft) => {
      if (!draft[phaseType]) {
        draft[phaseType] = {};
      }
      draft[phaseType][key] = option;
    });
  };

  const changeSuggestionAndAutoFillConfig = (
    phaseType,
    trackType,
    value,
    templateId
  ) => {
    const { trackConfigs, trackConfigIndex } = findTrackConfigsIndex(
      phaseType,
      trackType,
      templateId
    );

    if (trackConfigIndex !== -1) {
      const autoFill = value === 'prefill';
      const useSuggestion = value === 'prefill' || value === 'show_answers';

      if (trackConfigs[trackConfigIndex].config) {
        trackConfigs[trackConfigIndex].config.autoFill = autoFill;
        trackConfigs[trackConfigIndex].config.useSuggestion = useSuggestion;
        trackConfigs[trackConfigIndex].config.suggestionSource =
          autoFill || useSuggestion ? 'self_review' : null;
      } else {
        trackConfigs[trackConfigIndex].config = {
          autoFill,
          useSuggestion,
          suggestionSource: autoFill || useSuggestion ? 'self_review' : null
        };
      }

      immerSetState((draft) => {
        draft[phaseType].trackConfigs = trackConfigs;
      });
    }
  };

  const changeDate = (type, value) => {
    immerSetState((draft) => {
      if (type == 'start') {
        draft.reviewedPeriodStartsAt = value;
      } else {
        draft.reviewedPeriodEndsAt = value;
      }
    });
  };

  const changeScheduleCycleDate = (type, value) => {
    immerSetState((draft) => {
      if (type == 'start') {
        draft.reviewProcessStartsAt = value;
      } else {
        draft.reviewProcessEndsAt = value;
      }
    });
  };

  const setRecurrence = (periodValue, periodType) => {
    if (periodValue && periodType) {
      immerSetState((draft) => {
        draft.recurrence = draft.recurrence || {}; // initial {} when recurrence is null
        draft.recurrence.every = periodType;
        draft.recurrence.interval = periodValue;
      });
    } else {
      immerSetState((draft) => {
        draft.recurrence = null;
      });
    }
  };

  const changeTrackWeight = (trackType, phases, attributeSelectedId) => {
    immerSetState((draft) => {
      phases.forEach((phase) => {
        const trackConfigs = [...cycleData[phase.key].trackConfigs];
        const trackConfigIndex = trackConfigs.findIndex((trackConfig) => {
          return trackType === 'review_aspects_scoring'
            ? trackConfig.template.id === attributeSelectedId
            : trackConfig.type == trackType;
        });
        trackConfigs[trackConfigIndex].weight = phase.weight;

        draft[phase.key].trackConfigs = trackConfigs;
      });
    });
  };

  const toggleCalibration = () => {
    immerSetState((draft) => {
      draft.includeCalibration = !cycleData.calibration;
      draft.calibration = cycleData.calibration ? null : { type: 'manager' };
    });
  };

  const changeCalibrationData = (key, value) => {
    immerSetState((draft) => {
      if (key == 'type' && value == 'manager') {
        draft.calibration = { type: 'manager' };
      } else {
        draft.calibration[key] = value;
      }
    });
  };

  const changePhaseDeliverable = (phaseKey, deliverable) => {
    immerSetState((draft) => {
      draft[phaseKey].deliverable = deliverable;
    });
  };

  const changePhaseDisclosure = (phaseKey, disclosure) => {
    immerSetState((draft) => {
      draft[phaseKey].disclosure = disclosure;
    });
  };

  const changeRestrictedTrackNavigation = (restrictedTrackNavigation) => {
    immerSetState((draft) => {
      draft.restrictedTrackNavigation = restrictedTrackNavigation;
    });
  };

  const changeLockSubmittedAssignment = (lockSubmittedAssignment) => {
    immerSetState((draft) => {
      draft.lockSubmittedAssignment = lockSubmittedAssignment;
    });
  };

  const setPhaseDependencies = (phaseKey, dependencies) => {
    immerSetState((draft) => {
      draft[phaseKey].dependencies = dependencies;
    });
  };

  const changeInProgressDeliverable = (inProgressDeliverable) => {
    immerSetState((draft) => {
      draft.inProgressDeliverable = inProgressDeliverable;
    });
  };

  const changeDisplayTrack = (phaseType, trackType, trackName, value) => {
    const { trackConfigs, trackConfigIndex } = findTrackConfigsIndexByName(
      phaseType,
      trackName
    );
    trackConfigs[trackConfigIndex].displayOnResult = value;
    if (trackConfigs[trackConfigIndex].config) {
      trackConfigs[trackConfigIndex].config.displayOnResult = value;
    }

    immerSetState((draft) => {
      draft[phaseType].trackConfigs = trackConfigs;
    });
  };

  const changeViewSummaryConfig = (phaseType, value) => {
    immerSetState((draft) => {
      draft[phaseType].revieweeSummaryVisibility = value;
    });
  };

  const changeTotalSelectedUser = (value) => {
    immerSetState((draft) => {
      draft.totalSelectedUser = parseInt(value);
    });
  };

  const setPhaseDependenciesNotification = (phaseKey, value) => {
    immerSetState((draft) => {
      draft[phaseKey].notificationRecurrences = value;
    });
  };

  const changeEvidenceConfig = (phaseKey, value) => {
    immerSetState((draft) => {
      draft[phaseKey] = value;
    });
  };

  return {
    cycleData,
    setCycleId,
    getCycleData,
    changeCycleName,
    changeIncludesIds,
    togglePhase,
    changePhaseData,
    changeDate,
    changeScheduleCycleDate,
    setRecurrence,
    toggleCalibration,
    changeCalibrationData,
    changePhaseDeliverable,
    changePhaseDisclosure,
    changeSelectAll,
    changeExcludeIds,
    changeRestrictedTrackNavigation,
    changeLockSubmittedAssignment,
    changeSuggestionAndAutoFillConfig,
    changeTrackWeight,
    setPhaseDependencies,
    changeGroupIds,
    changeInProgressDeliverable,
    changeDisplayResult,
    changeDisplayResultScoreAspect,
    changeDisplayTrack,
    changeViewSummaryConfig,
    changeTotalSelectedUser,
    setPhaseDependenciesNotification,
    changeRatingTemplate,
    changeEvidenceConfig,
    changeShowScheduleDate,
    getTrackConfig
  };
}

export { CreateCycleProvider, useCreateCycle };

import React from 'react';

import { MONTH_GROUP, QUARTER_GROUP, WEEK_GROUP } from '../utils/const';
import GanttBackgroundDaysColumn from './GanttBackgroundDaysColumn';
import GanttBackgroundMonthlyColumn from './GanttBackgroundMonthlyColumn';
import GanttBackgroundWeeklyColumn from './GanttBackgroundWeeklyColumn';

const backgroundComponents = {
  [WEEK_GROUP]: GanttBackgroundDaysColumn,
  [MONTH_GROUP]: GanttBackgroundWeeklyColumn,
  [QUARTER_GROUP]: GanttBackgroundMonthlyColumn
};
const GanttBackground = ({ timeGroup, daysOfYear }) => {
  const BackgroundComponent = backgroundComponents[timeGroup] || <></>;

  return (
    <div className="absolute left-[0] top-[36px] h-[calc(100%-36px)] flex">
      <BackgroundComponent daysOfYear={daysOfYear} />
    </div>
  );
};

export default GanttBackground;

import React from 'react';

import { whichBgColorClassName, whichColor } from 'utils/ObjectivesHelper';

import FormattedNumber from 'components/design-system/FormattedNumber';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';

function Progress({ measurement, level }) {
  const { progressPercentage, progressColorHex, expectedProgressPercentage } =
    measurement || [];

  return (
    <div className="flex items-center mb-[16px]">
      <LineProgressBar
        progress={progressPercentage}
        expectedProgress={expectedProgressPercentage}
        primaryColor={whichBgColorClassName(progressColorHex, false, '500')}
        secondaryColor={'var(--n-500)'}
        fullFirst={true}
        width={level === 0 ? 248 : 228}
        height={12}
        classLine="grow"
      />
      <FormattedNumber
        number={progressPercentage}
        minDecimalLength={2}
        customClass={`typography-h400 ml-[4px] ${whichColor[progressColorHex]}`}
      />
      <p className={`typography-h400 ${whichColor[progressColorHex]}`}>%</p>
    </div>
  );
}

export default Progress;

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { putObjectivesConfig } from 'client/ObjectivesClient';
import { getConfig } from 'client/admin/Config';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import useFormHelper from 'hooks/useFormHelper';
import { getObjectiveLocale } from 'utils/HelperUtils';

import MinMaxFormBox from './form/MinMaxFormBox';

const Interfaces = {
  setIsSaving: PropTypes.func.isRequired
};
const DefaultValue = {
  setIsSaving: () => {}
};
const GeneralSetting = ({ setIsSaving }) => {
  const {
    state,
    immerSetState,
    _handleOnChangeInputAutoSave,
    _handleOnChangeToogleSwitchAutoSave,
    _handleOnChangeSelectAutoSave
  } = useFormHelper();
  const handler = {
    input: _handleOnChangeInputAutoSave,
    toggle: _handleOnChangeToogleSwitchAutoSave,
    select: _handleOnChangeSelectAutoSave
  };
  const { config } = useUser();
  const { reload, reloadUser } = useReload();
  const [isInputMinError, setIsInputMinError] = useState(false);
  const [isInputMaxError, setIsInputMaxError] = useState(false);
  const debounceMinNumberOfObjectives = useDebounce(
    state.minNumberOfObjectives,
    3000
  );
  const debounceMaxNumberOfObjectives = useDebounce(
    state.maxNumberOfObjectives,
    3000
  );
  const isReadOnly =
    config?.permissions?.settingsObjectivesGeneral === 'read_only';

  const payload = {
    objectivesConfig: {
      minObjectivesOn: state.minObjectivesOn,
      maxObjectivesOn: state.maxObjectivesOn,
      minNumberOfObjectives: !state.minObjectivesOn
        ? 0
        : parseInt(state.minNumberOfObjectives),
      maxNumberOfObjectives: !state.maxObjectivesOn
        ? 1
        : parseInt(state.maxNumberOfObjectives),
      minPeriodObjectives: state.minPeriodObjectives?.toLowerCase(),
      maxPeriodObjectives: state.maxPeriodObjectives?.toLowerCase()
    }
  };

  const listDefaultValuePeriod = {
    yearly: 1,
    semesterly: 2,
    quarterly: 3
  };
  const defaultValueMin = listDefaultValuePeriod[state.minPeriodObjectives];
  const defaultValueMax = listDefaultValuePeriod[state.maxPeriodObjectives];

  const _getDataDefaultConfig = async () => {
    const { data } = await getConfig();
    if (data) {
      const {
        minObjectivesOn,
        maxObjectivesOn,
        minNumberOfObjectives,
        maxNumberOfObjectives,
        minPeriodObjectives,
        maxPeriodObjectives
      } = data.objectivesConfig;
      immerSetState((draft) => {
        draft.minObjectivesOn = minObjectivesOn;
        draft.maxObjectivesOn = maxObjectivesOn;
        draft.minNumberOfObjectives = minNumberOfObjectives;
        draft.maxNumberOfObjectives = maxNumberOfObjectives;
        draft.minPeriodObjectives = minPeriodObjectives
          ? minPeriodObjectives
          : 'Quarterly';
        draft.maxPeriodObjectives = maxPeriodObjectives
          ? maxPeriodObjectives
          : 'Quarterly';
      });
    }
  };

  const _saveData = async () => {
    const objectivesConfig = payload.objectivesConfig;
    const minObjectives = parseInt(objectivesConfig.minNumberOfObjectives);
    const maxObjectives = parseInt(objectivesConfig.maxNumberOfObjectives);
    const minError =
      minObjectives >= maxObjectives && objectivesConfig.maxObjectivesOn;
    const maxError =
      maxObjectives <= minObjectives && objectivesConfig.minObjectivesOn;
    if (!(minError && maxError)) {
      setIsSaving(true);
      await putObjectivesConfig(payload);
      immerSetState((draft) => {
        draft.autoSave = false;
      });
      reload({ reloadUser: !reloadUser });
    }
  };

  useEffect(() => {
    _getDataDefaultConfig();
  }, []);

  useEffect(() => {
    if (state.autoSave) _saveData();
  }, [
    debounceMinNumberOfObjectives,
    debounceMaxNumberOfObjectives,
    state.minObjectivesOn,
    state.maxObjectivesOn,
    state.minPeriodObjectives,
    state.maxPeriodObjectives
  ]);

  useEffect(() => {
    const minNumber = parseInt(state.minNumberOfObjectives);
    const maxNumber = parseInt(state.maxNumberOfObjectives);

    if (state.maxObjectivesOn) setIsInputMinError(minNumber >= maxNumber);
    else setIsInputMinError(false);

    if (state.minObjectivesOn) setIsInputMaxError(maxNumber <= minNumber);
    else setIsInputMaxError(false);
  }, [
    state.minNumberOfObjectives,
    state.maxNumberOfObjectives,
    state.maxObjectivesOn,
    state.minObjectivesOn
  ]);

  return (
    <div className="objective-container pl-[0px]" data-cy="min-max-section">
      <MinMaxFormBox
        formBox={{
          title: getObjectiveLocale('Enable minimum number of objectives'),
          isChecked: state.minObjectivesOn,
          subtitle: `${getObjectiveLocale('Minimum number of objectives')}:`,
          description: getObjectiveLocale(
            'If minimum number of objectives is being enabled, it will be applied when user trying to create objective.'
          ),
          idToogleSwitch: 'minObjectivesOn'
        }}
        dropdownMetric={{
          defaultValue: defaultValueMin,
          handleChangeType: 'minPeriodObjectives',
          datacy: 'dropdown-min'
        }}
        input={{
          name: 'minNumberOfObjectives',
          placeholder: 'e.g 3',
          value: state.minNumberOfObjectives
        }}
        error={{
          isError: isInputMinError,
          message: `${getObjectiveLocale(
            'Minimum number of objectives must less than'
          )} ${state.maxNumberOfObjectives}`
        }}
        handler={handler}
        disabled={isReadOnly}
      />
      <MinMaxFormBox
        formBox={{
          title: getObjectiveLocale('Enable maximum number of objectives'),
          subtitle: `${getObjectiveLocale('Maximum number of objectives')}:`,
          isChecked: state.maxObjectivesOn,
          description: getObjectiveLocale(
            'If maximum number of objectives is being enabled, it will be applied when user trying to create objective.'
          ),
          idToogleSwitch: 'maxObjectivesOn'
        }}
        dropdownMetric={{
          defaultValue: defaultValueMax,
          handleChangeType: 'maxPeriodObjectives',
          datacy: 'dropdown-max'
        }}
        input={{
          name: 'maxNumberOfObjectives',
          placeholder: 'e.g 7',
          value: state.maxNumberOfObjectives,
          className: 'btn-filter-name'
        }}
        error={{
          isError: isInputMaxError,
          message: `${getObjectiveLocale(
            'Maximum number of objectives must bigger than'
          )} ${state.minNumberOfObjectives}`
        }}
        handler={handler}
        disabled={isReadOnly}
      />
    </div>
  );
};
GeneralSetting.propTypes = Interfaces;
GeneralSetting.defaultProps = DefaultValue;
export default GeneralSetting;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import debounce from 'lodash/debounce';

import {
  getListGroupsPermission,
  updateGroupsPermission
} from 'client/adminClient';
import { getGroupById, updateStateGroupsPermission } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import ListEmptyState from 'components/shared/ListEmptyState';
import ToastNotif from 'components/shared/ToastNotif.js';

import ListGroups from './ListGroups';
import PermissionConfigurationBox from './PermissionConfigurationBox';

function Permissions({ route: { title, isReadOnly } }) {
  const { appType } = useUser();
  const history = useHistory();
  const positionsList = [
    { id: 'default', name: 'Default' },
    { id: 'groups', name: 'Groups' }
  ];

  const filter = useFilter((state) => state.filterById?.permissionsFilter);
  const setFilterById = useFilter((state) => state.setFilterById);
  const [listGroup, setListGroup] = useState([]);
  const [currentTab, setCurrentTab] = useState(positionsList[0]?.id);
  const [newerThanId, setNewerThanId] = useState(null);
  const [olderThanId, setOlderThanId] = useState(null);
  const [prevOlderThanId, setPrevOlderThanId] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentSort, setCurrentSort] = useState({
    sortColumn: 'name',
    sortDirection: 'asc'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPermission, setDefaultPermission] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setsuccessMessage] = useState('');
  const [isSuccess, setisSuccess] = useState(false);
  const debouncedSelectedValues = useDebounce(selectedValues, 1000);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [emptyStateWord, setEmptyStateWord] = useState('');
  const [saveStatus, setSaveStatus] = useState(null);

  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');
  const groupTypePermission =
    appType === 'culture' ? 'culture_permission_group' : 'permission_group';
  const _getListGroupsPermission = async (type) => {
    let query = {
      ...filter,
      groupType: groupTypePermission,
      currentApp: appType,
      sortDirection: currentSort.sortDirection,
      sortColumn: currentSort.sortColumn,
      limit: 10
    };
    if (type == 'next') {
      query.olderThan = olderThanId;
    } else if (type == 'prev') {
      if (listGroup?.length == 0) {
        query.olderThan = olderThanId;
      } else {
        query.newerThan = newerThanId;
      }
    }

    const { data, pagination, isSuccess } = await getListGroupsPermission(
      query
    );
    if (data?.length > 0 && isSuccess) {
      setListGroup(data);
      setPrevOlderThanId(olderThanId);
      setOlderThanId(pagination?.next?.olderThan);
      setNewerThanId(pagination?.prev?.newerThan);
      if (type == 'prev') {
        setHasMore(true);
      } else {
        setHasMore(data?.length === 10);
      }
    } else if (!isSuccess) {
      setEmptyStateWord("You're not allowed to see this page");
    } else if (data?.length == 0) {
      setHasMore(false);
      setListGroup([]);
      setOlderThanId(prevOlderThanId);
      setEmptyStateWord("There's no group");
    }
  };

  const onClickDelete = async (data) => {
    let body = {
      changeState: data.deleted ? 'active' : 'archived'
    };
    if (!data.deleted) {
      body = {
        ...body,
        transferMembersToGroupId: 0
      };
    }
    const datass = await updateStateGroupsPermission(data.id, body);
    if (datass) {
      _getListGroupsPermission();
      setisSuccess(true);
      setsuccessMessage('Successfully Updated! ');
      setTimeout(() => {
        setisSuccess(false);
      }, 2000);
    }
  };

  const filterMenu = [
    { option: 'states', title: 'Status', dialog: 'permission-status' }
  ];

  const onClickCreateNewGroup = () => {
    history.push(`/group-permissions/create`);
  };

  const headerProps = {
    titlePage: title,
    saveStatus: saveStatus,
    primaryAction: !isReadOnly && {
      title: getObjectiveLocale('Create Group'),
      icon: 'icon-add',
      onClick: onClickCreateNewGroup,
      dataCy: 'create-new-group'
    }
  };

  const _getDefaultPermissions = async () => {
    setLoading(true);
    const { data } =
      (await getGroupById(0, { group_type: groupTypePermission }, true)) || {};
    if (data) {
      const initialSelectedValues = Object.values(data.permissions).reduce(
        (res, permissions) => {
          res = { ...res, ...permissions };
          return res;
        },
        {}
      );
      setSelectedValues(initialSelectedValues);
      setDefaultPermission(data);
    }
    setLoading(false);
  };

  const updateGroup = async () => {
    const {
      id,
      deleted,
      member_count,
      member_ids,
      current_state,
      permissions,
      is_security_admin,
      ...body
    } = defaultPermission;

    const { data } = await updateGroupsPermission(
      id,
      {
        ...body,
        permissions: Object.entries(selectedValues).reduce(
          (res, [key, permission]) => {
            res = {
              ...res,
              [key]: permission
            };
            return res;
          },
          {}
        )
      },
      true
    );

    if (data) {
      setAllowUpdate(false);
      const initialSelectedValues = Object.values(data.permissions).reduce(
        (res, permissions) => {
          res = { ...res, ...permissions };
          return res;
        },
        {}
      );
      setSelectedValues(initialSelectedValues);
      setSaveStatus('Saved...');
    }
  };

  const EmptyPermission = useMemo(
    () => (
      <ListEmptyState
        fullHeight={true}
        emptyIcon={
          filter?.q
            ? 'icon-no-result-found'
            : listGroup?.length == 0
            ? 'icon-no-group-permission'
            : 'icon-no-permission'
        }
        title={getObjectiveLocale(
          filter?.q ? 'No group found' : emptyStateWord
        )}
        subtitle={getObjectiveLocale(
          filter?.q
            ? 'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
            : ''
        )}
      />
    ),
    [filter?.q, listGroup, emptyStateWord]
  );

  useEffect(() => {
    setFilterById('permissionsFilter', {});
    if (tabParams == 'groups') {
      setCurrentTab('groups');
    }
  }, [currentTab]);

  useEffect(() => {
    _getDefaultPermissions();
  }, []);

  useEffect(() => {
    defaultPermission?.name && allowUpdate && updateGroup();
  }, [debouncedSelectedValues]);

  const debounceFn = useCallback(debounce(_getListGroupsPermission, 500), [
    filter,
    currentSort
  ]);

  useEffect(() => {
    debounceFn();
    return debounceFn.cancel;
  }, [filter, currentSort]);

  return (
    <>
      <HeaderPage
        {...headerProps}
        tabs={{
          tabsList: positionsList,
          url: '/group-permissions',
          onChange: (tab) => {
            setCurrentTab(tab);
          }
        }}
      />
      <div className="flex flex-col bg-n-000 min-h-[calc(100vh-64px)]">
        {currentTab === 'groups' && (
          <>
            {
              <>
                <AdvancedFilter
                  filterOptions={['search', 'menuFilter']}
                  filterMenu={filterMenu}
                  id="permissionsFilter"
                  customClass="pt-[24px]"
                />
                <ToastNotif showNotif={isSuccess} message={successMessage} />
                {
                  <ListGroups
                    listData={listGroup}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    currentSort={currentSort}
                    setCurrentSort={setCurrentSort}
                    changePage={_getListGroupsPermission}
                    hasMore={hasMore}
                    onClickDelete={onClickDelete}
                    isReadOnly={isReadOnly}
                    isSecurityAdmin={defaultPermission?.is_security_admin}
                    emptyState={EmptyPermission}
                  />
                }
              </>
            }
          </>
        )}
        {currentTab === 'default' && (
          <div className="mt-[24px] mr-[98px]">
            <PermissionConfigurationBox
              boxWidth="1100px"
              tableMinWidth="880px"
              data={defaultPermission.permissions}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              loading={loading}
              setAllowUpdate={setAllowUpdate}
              isReadOnly={isReadOnly}
              setSaveStatus={setSaveStatus}
              isDefault
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Permissions;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import MilestoneDetailCard from 'components/objectives/MilestoneDetailCard';
import SVGIcon from 'components/shared/SVGIcon';

const Milestone = ({ objectiveDetail, showSidebar }) => {
  const { currentMilestone, milestoneType, milestoneCount, milestoneMode } =
    objectiveDetail;

  return (
    <div className="milestone-detail px-[24px] py-[12px] flex content-center flex-col">
      <div className="flex content-center">
        <SVGIcon size="24" fillColor="var(--n-600)" iconName="icon-timeline" />
        <p className="typography-paragraph pl-[16px] capitalize">
          {getObjectiveLocale(
            `${milestoneType} milestone (${
              milestoneMode == 'latest_value'
                ? 'Latest Value'
                : milestoneMode.split('_')[0]
            }), recent`
          )}
          :
        </p>
      </div>
      <div className="flex ml-[40px] mt-[16px]">
        <MilestoneDetailCard currentMilestone={currentMilestone} />
      </div>
      {milestoneCount > 1 && (
        <p
          className="typography-link text-base-600 flex mt-[12px] ml-[40px] cursor-pointer"
          onClick={() => {
            milestoneType !== 'disabled' && showSidebar('milestone');
          }}
        >
          {getObjectiveLocale('Show other milestone period')}
          <SVGIcon
            size="24"
            fillColor="var(--base-600)"
            iconName="icon-arrow_right"
            customClass="ml-[8px]"
          />
        </p>
      )}
    </div>
  );
};

export default Milestone;

import { useQuery } from 'react-query';
import {
  getObjectivePhases,
  getObjectivePriorities,
  getObjectivePhaseNames,
  getObjectiveSectionNames,
  getProjectSections,
  getListObjectives
} from 'client/ObjectivesClient';

const useTaskGroup = (groupBy, showTask, page, params, queryConfig = {}) => {
  const queryKey = ['group', groupBy, page, params];
  let queryFn = () => {};

  switch (groupBy) {
    case 'phase':
      if (showTask === 'project') {
        queryFn = getObjectivePhaseNames;
      } else {
        queryFn = getObjectivePhases;
      }
      break;
    case 'priority':
      queryFn = getObjectivePriorities;
      break;
    case 'section':
      if (page === 'mytasks') {
        queryFn = getObjectiveSectionNames;
      } else if (page === 'projectDetail') {
        queryFn = getProjectSections;
      }
      break;
    case 'project':
      queryFn = getListObjectives;
      break;
    default:
      break;
  }

  return useQuery(queryKey, () => queryFn(params), queryConfig);
};

export default useTaskGroup;

import React, { useEffect } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import DropdownType from 'components/dropdown/DropdownType';
import ContentWrapper from 'components/sidebar/sidebar-create/ContentWrapper';

const GoalTypeSetting = ({
  objectiveValue,
  setObjectiveValue,
  objectiveCategories,
  disabled = false,
  objectiveConfigs,
  isAddGoal
}) => {
  const handleChangeSubgoalType = (parent, el) => {
    if (parent.children) {
      parent.children.map((child) => {
        if (child.name == '') {
          child.objectiveCategoryId = el.id;
        }
      });
    }
  };

  const handleChangeType = (el) => {
    setObjectiveValue((draft) => {
      draft.objectiveCategoryId = el.id;
      draft.weight = el.weight;
    });

    let parent = objectiveValue;
    handleChangeSubgoalType(parent, el);
  };

  useEffect(() => {
    if (
      isAddGoal &&
      objectiveConfigs?.subgoalObjectiveCategoryIds?.length > 0
    ) {
      setTimeout(() => {
        setObjectiveValue((draft) => {
          draft.objectiveCategoryId =
            objectiveConfigs?.subgoalObjectiveCategoryIds?.[0];
        });
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveConfigs, isAddGoal]);

  return (
    <ContentWrapper
      iconName="icon-category_outlined"
      customWrapperClass="px-[24px] py-[6px] mt-[8px] items-center"
      dataCy="sidebar-create-objective-category-type"
    >
      <div className="flex items-center">
        <p className="typography-paragraph mr-[4px] min-w-[96px] whitespace-nowrap">
          {getObjectiveLocale(
            `${objectiveValue.isProject ? 'Project' : 'Goal'} type is`
          )}
          &nbsp;
        </p>
        <DropdownType
          defaultValue={objectiveValue?.objectiveCategoryId}
          handleChange={handleChangeType}
          objectiveCategories={objectiveCategories}
          disabled={disabled}
          useFloating
          dropdownTriggerClass="w-[240px]"
        />
      </div>
    </ContentWrapper>
  );
};

export default GoalTypeSetting;

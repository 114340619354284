import React from 'react';

import PropTypes from 'prop-types';

const Interface = {
  classes: PropTypes.string,
  children: PropTypes.node.isRequired
};

const WhiteCard = ({ classes, children }) => (
  <div className={classes ? `analytic-card ${classes}` : 'analytic-card'}>
    {children}
  </div>
);

WhiteCard.propTypes = Interface;

export default WhiteCard;

import React, { useState } from 'react';

import { useUser } from 'context/UserContext';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';
import SVGIcon from 'components/shared/SVGIcon';
import TextAreaWithMention from 'components/shared/TextAreaWithMention/TextAreaWithMention';

function ModalApprove({
  isReject,
  handleChange,
  handleSubmit,
  approvalRequests
}) {
  const { user } = useUser();
  const [metaMentions, setMetaMentions] = useState([]);
  const [comment, setComment] = useState(null);
  const [showReasonModal, setShowReasonModal] = useState(false);
  let isAll = approvalRequests.length > 1;
  let isValid = !comment;

  const onSubmit = async () => {
    let requestIds = approvalRequests.map((value) => {
      return value.id;
    });
    let body = { requestIds };
    if (isReject) {
      const metaMentionsPayload = metaMentions.map((value) => {
        return {
          userId: value.userId,
          startIndex: value.startIndex,
          length: value.length
        };
      });

      body = {
        ...body,
        comment: comment,
        metaMentions: metaMentionsPayload
      };
    }
    handleSubmit(isReject, body);
    handleChange(false);
  };

  return (
    <>
      <div
        className={`
          modal-approval
          ${isAll ? 'all' : ''}
          ${showReasonModal ? 'reason' : ''}
        `}
        data-cy="approval-modal"
      >
        {showReasonModal ? (
          <Modal
            dataCy="approval-modal"
            onClose={() => setShowReasonModal(false)}
          >
            <Modal.Header title={getObjectiveLocale('Reason')} />
            <Modal.Body>
              <div className="w-full px-[24px]">
                <p className="mb-[8px]">
                  {getObjectiveLocale('Reason (Required)')}
                </p>
                <TextAreaWithMention
                  currentUserId={user?.id}
                  setComment={setComment}
                  metaMentions={metaMentions}
                  setMetaMentions={setMetaMentions}
                  isValid={isValid}
                  source="modal"
                />
              </div>
            </Modal.Body>
            <Modal.Footer
              primaryButton={{
                text: getObjectiveLocale('Reject All'),
                dataCy: 'approval-reason-modal-action',
                onClick: onSubmit,
                disabled: isValid
              }}
              secondaryButton={{
                text: getObjectiveLocale('Cancel'),
                dataCy: 'approval-reason-modal-cancel',
                onClick: () => setShowReasonModal(false)
              }}
              useBorder={false}
            />
          </Modal>
        ) : (
          <Modal
            minWidth={400}
            dataCy="approval-modal"
            customClass="w-[400px]"
            onClose={() => handleChange(false)}
          >
            <Modal.Header
              title={
                isAll
                  ? (isReject
                      ? getObjectiveLocale('Reject All')
                      : getObjectiveLocale('Approve All')) +
                    ' ' +
                    getObjectiveLocale('Goals')
                  : isReject
                  ? getObjectiveLocale('Reject')
                  : getObjectiveLocale('Approve')
              }
              onClose={isAll ? () => handleChange(false) : null}
            />
            <Modal.Body>
              <div className="w-full px-[24px]">
                <p className="typography-paragraph text-n-800 mb-[16px]">
                  {`${getObjectiveLocale(
                    `You will ${isReject ? 'reject' : 'approve'} these ${
                      isAll ? "employee's " : ''
                    }objectives:`
                  )}`}
                </p>
                {isAll ? (
                  <div
                    className="mt-[8px] bg-y-100 py-[8px] px-[16px] rounded-[4px] leading-[24px] flex "
                    data-cy="approval-warning-card"
                  >
                    <SVGIcon
                      iconName="icon-warning"
                      fillColor="var(--y-500)"
                      size={20}
                      customClass="mt-[6px]"
                    />
                    <span className="ml-[16px] text-n-900 typography-paragraph">
                      {getObjectiveLocale(
                        `All of your direct reports requests will be ${
                          isReject ? 'rejected' : 'accepted'
                        } at once`
                      )}
                    </span>
                  </div>
                ) : (
                  <ul className="list">
                    {approvalRequests?.[0]?.analytics?.map((value, index) => {
                      let label = value.state
                        .split('_')
                        .map((subStr) => {
                          return subStr[0].toUpperCase() + subStr.slice(1);
                        })
                        .join(' ');
                      return (
                        <div
                          className="flex items-center justify-between bg-n-200 text-n-800 py-[12px] px-[16px] rounded-[4px]"
                          key={index}
                        >
                          <p className="typography-paragraph">{label}</p>
                          <p className="typography-h100 text-n-800">
                            {`
                          ${getObjectiveLocale(
                            `[min_value] Objective(s)`
                          ).replace(
                            /\[min_value]/g,
                            getNumberLocale(value.count)
                          )}`}
                          </p>
                        </div>
                      );
                    })}
                  </ul>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer
              userInfo={
                !isAll && {
                  profilePicture:
                    approvalRequests?.[0]?.requester?.profilePicture,
                  name: approvalRequests?.[0]?.requester?.name,
                  dataCy: 'image-initial-name'
                }
              }
              primaryButton={{
                text: getObjectiveLocale(isReject ? 'Next' : 'Approve All'),
                onClick: isReject ? () => setShowReasonModal(true) : onSubmit,
                dataCy: 'approval-modal-action'
              }}
              secondaryButton={{
                text: getObjectiveLocale('Cancel'),
                onClick: () => handleChange(false),
                dataCy: 'approval-modal-cancel'
              }}
              useBorder={false}
            />
          </Modal>
        )}
      </div>
    </>
  );
}

export default ModalApprove;

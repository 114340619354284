import React, { useEffect, useState } from 'react';

import { useUser } from 'context/UserContext';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { getObjectiveLocale } from 'utils/HelperUtils';

import DropdownType from 'components/dropdown/DropdownType';
import NumberInput from 'components/shared/NumberInput';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import RangeSlider from 'components/slider/RangeSlider';

const SetObjectiveTypeWeight = ({
  objectiveValue,
  setObjectiveValue,
  objectiveCategories
}) => {
  const {
    config: { objectiveWeightType, enableObjectiveCategoryWithRangedWeight }
  } = useUser();

  const defaultValue = objectiveValue.weight;

  const [weightValue, setWeightValue] = useState(defaultValue);

  const { checkValidationSoftWarning, isShowWarning, warningMessage } =
    useValidationMinMax(weightValue);

  const isPercentage = objectiveWeightType === 'percentage';
  const currentObjectiveCategory = objectiveCategories.find(
    (type) => type.id === objectiveValue?.objectiveCategoryId
  );

  const isLocked =
    (enableObjectiveCategoryWithRangedWeight &&
      currentObjectiveCategory?.isLocked) ||
    false;

  const isLockedWeightMatch =
    currentObjectiveCategory?.weight == weightValue || false;

  const handleChangeType = (el) => {
    setObjectiveValue((draft) => {
      draft.objectiveCategoryId = el.id;
      draft.weight = el.weight;
    });
  };

  const handleChangeWeight = (weight) => {
    setObjectiveValue((draft) => {
      draft.weight = weight;
    });
  };

  const toggleWeight = () => {
    let weight;
    if (weightValue > 0) {
      weight = 0;
    } else {
      weight = isPercentage ? 1 : currentObjectiveCategory?.weight || 1;
    }
    setWeightValue(weight);
    handleChangeWeight(weight);
  };

  useEffect(() => {
    setWeightValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    checkValidationSoftWarning();
  }, [weightValue, checkValidationSoftWarning]);

  return (
    <>
      <div className="flex items-center">
        {!isPercentage && (
          <div className="mr-[16px]">
            <p className="typography-h100 text-n-800 mb-[8px]">
              {getObjectiveLocale('Goal type')}
            </p>
            <DropdownType
              defaultValue={objectiveValue?.objectiveCategoryId}
              handleChange={handleChangeType}
              objectiveCategories={objectiveCategories}
              rightIcon="icon-arrow_drop_down"
              dropdownTriggerClass="w-[360px]"
              dropdownMenuItemsClass="w-[360px]"
            />
          </div>
        )}
        <div className="w-full">
          <p className="typography-h100 text-n-800 mb-[8px]">
            {getObjectiveLocale('Weight')}
          </p>
          <div className="w-full flex items-center h-[32px]">
            <SmallToggleSwitchPurple
              isChecked={weightValue > 0 || weightValue === ''}
              onChange={toggleWeight}
              idStr="add-skill"
              addClass="flex items-center ml-[-12px] mt-[2px]"
            />
            {(weightValue > 0 || weightValue === '') && (
              <>
                <WeightInput
                  weightValue={weightValue}
                  handleChangeWeight={handleChangeWeight}
                  isLocked={isLocked}
                  isShowWarning={isShowWarning}
                />
                {isPercentage && (
                  <RangeSlider
                    handleChange={(e) => setWeightValue(e)}
                    onChangeComplete={() => handleChangeWeight(weightValue)}
                    value={weightValue}
                    min={1}
                    max={100}
                    className="w-full h-[24px] mt-[16px] ml-[24px]"
                    disabled={isLocked}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {!isLocked && isShowWarning && (
        <p className="typography-h100 text-r-500 mt-[8px]">{warningMessage}</p>
      )}
      {isLocked &&
        (isLockedWeightMatch ? (
          <p className="text-n-600 typography-h100 mt-[12px]">
            {getObjectiveLocale(
              'The weight percentage for this type is locked by system'
            )}
          </p>
        ) : (
          <p className="text-r-600 typography-h100 mt-[12px]">
            {getObjectiveLocale(
              `Current weight doesn't fit with the latest requirement`
            )}
            <span
              className="text-base-600 typography-h100 cursor-pointer"
              onClick={() => setWeightValue(currentObjectiveCategory?.weight)}
            >
              {' '}
              {getObjectiveLocale('Click to adjust')}
            </span>
          </p>
        ))}
    </>
  );
};

const WeightInput = ({
  weightValue,
  handleChangeWeight,
  isLocked,
  isShowWarning
}) => {
  const [number, setNumber] = useState(weightValue);

  const handleChangeWeightInput = (inputWeight) => {
    let weight;
    if (inputWeight > 100) {
      weight = 100;
    } else {
      weight = inputWeight != '' ? parseFloat(inputWeight) : inputWeight;
    }
    setNumber(inputWeight);
    handleChangeWeight(weight);
  };

  useEffect(() => {
    setNumber(weightValue);
  }, [weightValue]);

  return (
    <div className="flex items-center ml-[6px]">
      <NumberInput
        type="number"
        placeholder="0"
        max="100"
        value={number}
        onChange={handleChangeWeightInput}
        inputClass={`${!isLocked && isShowWarning ? 'border-red' : ''}`}
        precision={13}
        className="w-[96px] mr-[8px]"
        dataCy="weight-input"
        disabled={isLocked}
      />
      <p className="typography-paragraph mt-[0px]">%</p>
    </div>
  );
};

export default SetObjectiveTypeWeight;

import React, { useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { getGroupData } from 'client/admin/UserManagementClient';
import { useUser } from 'context/UserContext';
import useUserManagement from 'hooks/useUserManagement';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InlineDialogWrapper from 'components/admin/labels/InlineDialogWrapper';
import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

import PositionField from './PositionField';

const GroupSection = ({
  changePositionField,
  detailPosition,
  setDetailPosition,
  isReadOnly
}) => {
  const { config } = useUser();
  const [dialogData, setDialogData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const setEditedPosition = useUserManagement(
    (state) => state.setEditedPosition
  );

  const _getGroupData = async (type, search) => {
    const params = {
      groupType: type,
      q: search,
      sortBy: 'name',
      order: 'asc',
      state: 'active'
    };
    const { data } = await getGroupData(params);
    if (data) {
      const defaultData = { id: 0, name: 'Default' };
      setDialogData([defaultData, ...data]);
    }
  };

  const changeCluster = (type, newCluster) => {
    let currPosition = cloneDeep(detailPosition);

    if (type === 'remove') {
      currPosition.groups.clusterGroup =
        currPosition.groups.clusterGroup.filter(
          (cluster) => cluster?.id !== newCluster?.id
        );
    } else {
      currPosition.groups.clusterGroup = newCluster;
    }
    setDetailPosition(currPosition);
    setEditedPosition(currPosition);
  };

  return (
    <div className="border-solid border border-n-400 rounded-[4px] p-16 flex flex-col mt-[16px]">
      <div>
        <div className="typography-h400">{getObjectiveLocale('Groups')}</div>
        <div className="typography-paragraph text-n-800">
          {getObjectiveLocale('Showing group informations of this position.')}
        </div>
      </div>
      <div className="flex">
        {config?.editablePlacementGroup?.permission && (
          <PositionField
            label={getObjectiveLocale('Permission')}
            selected={detailPosition?.groups?.permissionGroup?.[0]}
            customClass="mr-[16px]"
            type="permission"
            changeSelected={changePositionField}
            readonly={
              isReadOnly ||
              config?.editablePlacementGroup?.permission === 'disabled'
            }
          />
        )}
        {config?.editablePlacementGroup?.behavior && (
          <PositionField
            label={getObjectiveLocale('Behavior Model')}
            selected={detailPosition?.groups?.behaviorGroup?.[0]}
            type="behavior"
            changeSelected={changePositionField}
            readonly={
              isReadOnly ||
              config?.editablePlacementGroup?.behavior === 'disabled'
            }
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementGroup?.review && (
          <PositionField
            label={getObjectiveLocale('Performance Profile')}
            selected={detailPosition?.groups?.reviewGroup?.[0]}
            customClass="mr-[16px]"
            type="review"
            changeSelected={changePositionField}
            readonly={
              isReadOnly ||
              config?.editablePlacementGroup?.review === 'disabled'
            }
          />
        )}
        {config?.editablePlacementGroup?.ratingTemplate && (
          <PositionField
            label={getObjectiveLocale('Objective Scoring')}
            selected={detailPosition?.groups?.ratingTemplateGroup?.[0]}
            type="rating_template"
            changeSelected={changePositionField}
            readonly={
              isReadOnly ||
              config?.editablePlacementGroup?.ratingTemplate === 'disabled'
            }
          />
        )}
      </div>
      {config?.editablePlacementGroup?.cluster && (
        <>
          <div className="typography-h100 text-n-800 mb-[16px] mt-[24px]">
            {getObjectiveLocale('Objective Visibility')}
          </div>
          <div className="flex flex-wrap">
            {detailPosition?.groups?.clusterGroup?.length > 0 &&
              detailPosition?.groups?.clusterGroup?.map((cluster, index) => (
                <div className="flex items-center 8" key={index}>
                  <div className="typography-h100 text-n-800 bg-n-300 h-[20px] rounded-[4px] px-[8px] py-[2px] mr-[4px]">
                    {cluster?.name}
                  </div>
                  {config?.editablePlacementGroup?.ratingTemplate !==
                    'disabled' && (
                    <SVGIcon
                      iconName="icon-clear"
                      fillColor="var(--n-600)"
                      size="16"
                      onClick={() => changeCluster('remove', cluster)}
                    />
                  )}
                </div>
              ))}
            <div className="relative">
              {config?.editablePlacementGroup?.ratingTemplate !==
                'disabled' && (
                <Button.Tertiary
                  disabled={isReadOnly}
                  onClick={() => {
                    _getGroupData('cluster_group');
                    setIsDialogOpen(true);
                  }}
                >
                  {getObjectiveLocale('Add Group')}
                </Button.Tertiary>
              )}
              {isDialogOpen && dialogData.length > 0 && (
                <InlineDialogWrapper
                  listOfGroups={dialogData}
                  selectedValue={detailPosition?.groups?.clusterGroup || []}
                  handleChange={(cluster) => changeCluster('add', cluster)}
                  isGroup={true}
                  handleChangeAllGroup={(cluster) => {}}
                  setShow={setIsDialogOpen}
                  type={'modal'}
                  setSearchGroup={(search) =>
                    _getGroupData('cluster_group', search)
                  }
                  label={getObjectiveLocale('Objective Visibility')}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupSection;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUrl } from 'hooks/useUrl';
import { abbrevNumber, getObjectiveLocale } from 'utils/HelperUtils';
import { getValue } from 'utils/ObjectivesHelper';

import Button from 'components/design-system/Button';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

const SummaryProgressOvertime = ({ measurement, id, saveCallback }) => {
  const history = useHistory();
  const { match } = useUrl();
  const { valueLabel, formattedCurrentValue, formattedTargetValue } =
    getValue(measurement);
  const extractedCurrentValue =
    formattedCurrentValue > 999999
      ? abbrevNumber(formattedCurrentValue)
      : formattedCurrentValue;

  const whichBGColor = {
    '5A9AEF': 'var(--b-500)',
    '44DB5E': 'var(--g-500)',
    FFCD00: 'var(--y-500)',
    FE2851: 'var(--r-500)'
  };

  return (
    <>
      <div className="px-[40px] py-[16px] flex items-center bg-n-000 border-b border-t-0 border-l-0 border-r-0 border-n-400 border-solid h-[48px]">
        <div className="flex items-center mr-[40px]">
          <p className="typography-h400 text-n-600 mr-[8px]">
            {getObjectiveLocale('Progress Achievement')}
          </p>
          <LineProgressBar
            progress={
              measurement?.progressPercentage >= 0
                ? measurement?.progressPercentage
                : 0
            }
            width={80}
            primaryColor={whichBGColor[measurement?.progressColorHex]}
            strokeDashoffset={null}
            fullFirst={true}
            height={12}
            dataCy="overall-progressbar"
          />
          <p
            className="typography-h400 text-n-800 mx-[8px]"
            data-cy="progress-obj-percentage"
          >
            {measurement?.progressPercentage}%
          </p>
        </div>
        <div className="flex items-center mr-[40px]">
          <p className="typography-h400 text-n-600 mr-[4px]">
            {getObjectiveLocale('Current Value')}
          </p>
          <p className="typography-h400 text-n-800" data-cy="current-obj">
            {extractedCurrentValue || 0}
          </p>
        </div>
        <div className="flex items-center mr-[40px]">
          <p className="typography-h400 text-n-600 mr-[4px]">
            {getObjectiveLocale('Target Value')}
          </p>
          <p className="typography-h400 text-n-800" data-cy="target-obj">
            {(formattedTargetValue > 999999
              ? abbrevNumber(formattedTargetValue)
              : formattedTargetValue) || 0}
          </p>
        </div>
        <div className="flex items-center mr-[40px]">
          <p className="typography-h400 text-n-600 mr-[4px]">
            {getObjectiveLocale('Initial Value')}
          </p>
          <p className="typography-h400 text-n-800" data-cy="initial-obj">
            {measurement?.startingValue}
          </p>
        </div>
        <div className="flex items-center mr-[40px]">
          <p className="typography-h400 text-n-600 mr-[4px]">
            {getObjectiveLocale('Metrics')}
          </p>
          <p className="typography-h400 text-n-800" data-cy="metric-obj">{`${
            valueLabel || extractedCurrentValue || '-'
          } of ${measurement?.description} (${measurement?.unit})`}</p>
        </div>
        <Button.Tertiary
          datacy="view-detail-obj"
          onClick={() => history.replace(`/progress-overtime/${id}/details`)}
        >
          {getObjectiveLocale('View details')}
        </Button.Tertiary>
      </div>
      <React.Suspense fallback={<div></div>}>
        {match && <SidebarMyGoals saveCallback={saveCallback} />}
      </React.Suspense>
    </>
  );
};

export default SummaryProgressOvertime;

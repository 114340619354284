import React, { useState } from 'react';

import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { formatTimezone, getDateWithForcedTimezone } from 'utils/DateUtils';

import AntdCalendar from 'components/shared/Calendar/AntdCalendar';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import { useDeepEffect } from 'src/utils/useDeepEffect';

const DueDateObjective = ({
  overdue,
  dueDate,
  startDate,
  objectiveId,
  isCreate,
  callback,
  objectiveDetail,
  permissions
}) => {
  const enableEdit = isCreate || permissions?.includes('update');
  const { recurrence } = objectiveDetail;

  const { refetchObjective } = useRefetchQuery();
  const [dateValue, setDateValue] = useState([
    dayjs(startDate).toISOString(),
    dayjs(dueDate).toISOString()
  ]);

  const setDateValueHandler = async (value) => {
    const [start, due, recurrenceValue] = value;
    if (
      dayjs(start).startOf('day').toISOString() == dateValue[0] &&
      dayjs(due).endOf('day').toISOString() == dateValue[1] &&
      isEqual(recurrenceValue, recurrence)
    )
      return;

    const payload = {
      startDate: formatTimezone(dayjs(start).format('YYYY-MM-DD'), 'start'),
      dueDate: formatTimezone(dayjs(due).format('YYYY-MM-DD'), 'end'),
      ...(recurrenceValue && { recurrence: recurrenceValue })
    };

    if (!isCreate) {
      const { isSuccess, data } = await editObjective(objectiveId, payload);
      if (isSuccess) {
        refetchObjective(objectiveId);
        setDateValue([data?.startDate, data?.dueDate]);
      }
      return;
    }
    callback && callback(payload);
    setDateValue([start, due]);
  };

  const beforeTrigger = (e, setOpen) => {
    if (enableEdit) {
      e.stopPropagation();
      !e.shiftKey && !(e.ctrlKey || e.metaKey) && setOpen(true);
    }
  };

  useDeepEffect(() => {
    setDateValue([
      dayjs(startDate).toISOString(),
      dayjs(dueDate).toISOString()
    ]);
  }, [dueDate, startDate]);

  return (
    <AntdCalendar>
      <AntdCalendar.Trigger>
        <TooltipContainer show={overdue} text={'Overdue'}>
          <div
            className="flex flex-row items-center"
            data-cy="inline-duedate-edit"
          >
            {overdue && (
              <SVGIcon
                iconName="icon-overdue"
                fillColor="var(--y-500)"
                size="16"
                customClass="mr-[5px]"
              />
            )}
            <p
              className={`typography-button ${
                overdue ? 'text-r-600' : 'text-n-800'
              }`}
            >
              {getDateWithForcedTimezone(dateValue[1], 'D MMM YY')}
            </p>
          </div>
        </TooltipContainer>
      </AntdCalendar.Trigger>
      <AntdCalendar.Content
        position="middle"
        dateValue={dateValue}
        setDateValueHandler={setDateValueHandler}
        recurrenceValue={recurrence}
        isGoals
        useRepetition
      />
    </AntdCalendar>
  );
};

export default DueDateObjective;

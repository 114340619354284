import React, { useRef, useState } from 'react';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { abbrevNumber, getObjectiveLocale } from 'utils/HelperUtils';
import { getMetricsTargetValue, whichColor } from 'utils/ObjectivesHelper';

import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import MetricsDropdown from '../../objectives/MetricsDropdown';

const Metrics = React.memo(({ task, clusters }) => {
  const [showDialog, setShowDialog] = useState(false);
  const parentRef = useRef();

  const { refetchObjective, invalidateTaskQueries } = useRefetchQuery();

  const { id, measurement, calculationType } = task || {};

  const { description, unit, progressColorHex, currentValue, targetValue } =
    measurement || {};

  const metricsTargetValue = getMetricsTargetValue(
    currentValue,
    targetValue,
    calculationType
  );

  const metricWording = measurement == null ? 'No metrics' : 'Not Visible';

  const saveMetrics = async ({ data }) => {
    if (data?.targetValue != targetValue || data?.unitId != measurement?.id) {
      if (data.unit == '') {
        delete data.unit;
        data.unitId = null;
      }
      delete data.startingValue;
      delete data.rollUp;

      const { isSuccess } = await editObjective(id, {
        measurement: { ...data }
      });
      if (isSuccess) {
        refetchObjective(id);
        invalidateTaskQueries({ task });
      }
    }
  };

  const showMetricDialogHandler = (e) => {
    if (!e.shiftKey && !(e.ctrlKey || e.metaKey)) {
      setShowDialog(!showDialog);
    }
  };

  return (
    <div className="relative w-full h-full">
      <div
        ref={parentRef}
        onClick={(e) =>
          (metricWording != 'Not Visible' || clusters?.includes('metric')) &&
          showMetricDialogHandler(e)
        }
        data-cy="trigger-metrics"
        className="flex items-center w-full h-full px-[16px] relative"
      >
        {clusters?.includes('metric') ? (
          <>
            <TooltipContainer
              show={description != ''}
              text={getObjectiveLocale(description)}
            >
              <p className="m-unit typography-h400 text-n-600 mr-[4px]">
                {unit}
              </p>
            </TooltipContainer>
            <div className="flex flex-row items-center">
              <TooltipContainer
                show={description != ''}
                text={currentValue?.toLocaleString(navigator.language)}
              >
                <p
                  className={`typography-h400 ${whichColor[progressColorHex]}`}
                >
                  {abbrevNumber(currentValue, 1, true)}&nbsp;
                </p>
              </TooltipContainer>
              <TooltipContainer
                show={description != ''}
                text={targetValue?.toLocaleString(navigator.language)}
                classContainer="flex flex-row items-center"
              >
                <p className="typography-button text-n-600">/&nbsp;</p>
                <p className="typography-button text-n-800">
                  {metricsTargetValue}
                </p>
              </TooltipContainer>
            </div>
          </>
        ) : (
          <p className="typography-h400 text-n-600 text-right">
            {getObjectiveLocale(metricWording)}
          </p>
        )}
      </div>

      {showDialog && (
        <MetricsDropdown
          type="absolute"
          measurement={measurement}
          setShowDialog={setShowDialog}
          closeEvent={saveMetrics}
          objectiveType="task"
          useAdvanced={false}
          usePortal={true}
          calculationType={calculationType}
        />
      )}
    </div>
  );
});

Metrics.displayName = 'Metrics';

export default Metrics;

import React, { useEffect, useState } from 'react';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';

const listRepetition = [
  {
    index: 1,
    value: 'Daily',
    name: 'day'
  },
  {
    index: 2,
    value: 'Weekly',
    name: 'week'
  },
  {
    index: 3,
    value: 'Monthly',
    name: 'month'
  },
  {
    index: 4,
    value: 'Yearly',
    name: 'year'
  }
];

function DropdownCustomRepeat({
  handleChange,
  defaultValue = listRepetition[0].name
}) {
  const [selectedValue, setSelectedValue] = useState('');

  const handleChangeDropdown = (repetition) => {
    setSelectedValue(repetition.value);
    handleChange(repetition.value);
  };

  useEffect(() => {
    const value = listRepetition.find(
      ({ name }) => name === defaultValue
    ).value;
    setSelectedValue(value);
  }, []);

  return (
    <Dropdown>
      <Dropdown.Trigger>
        <Trigger
          icon={false}
          topLabel={false}
          label={false}
          text={selectedValue}
          customWrapperClass="w-[120px]"
        />
      </Dropdown.Trigger>
      <Dropdown.MenuItems customClass="w-full">
        {listRepetition.map((repetition) => (
          <Dropdown.MenuItem
            key={repetition.index}
            onClick={() => handleChangeDropdown(repetition)}
          >
            {repetition.value}
          </Dropdown.MenuItem>
        ))}
      </Dropdown.MenuItems>
    </Dropdown>
  );
}

export default DropdownCustomRepeat;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import RadioButton from 'components/shared/RadioButton';

const CycleDeliverables = ({
  configInProgressDeliverable,
  inProgressDeliverable,
  changeInProgressDeliverable
}) => {
  return (
    <>
      <p className="typography-paragraph typography-secondary mb-[16px]">
        {getObjectiveLocale(
          `Configure how the review result should be shared to reviewee after the review cycle ends.`
        )}
      </p>

      {configInProgressDeliverable.map((val) => (
        <div
          key={val.id}
          className="wrapper-radio-button flex items-center mb-[8px] whitespace-nowrap"
        >
          <RadioButton
            dataCy={`config-deliverable-${val.value}`}
            size="small"
            value={val.id}
            id={val.id}
            checked={inProgressDeliverable === val.value}
            onChange={() => changeInProgressDeliverable(val.value)}
          >
            <span className="ml-[8px]">{val.description}</span>
          </RadioButton>
        </div>
      ))}
    </>
  );
};

export default CycleDeliverables;

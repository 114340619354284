import React, { useEffect, useState } from 'react';

import {
  createAttachment,
  createComment,
  updateCurrentValue,
  updateProgressMilestone
} from 'client/ObjectivesClient';
import S3Client from 'client/S3Client';
import { getPerformancePreSignUrl } from 'client/UploadClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';
import { useUser } from 'src/context/UserContext';
import { trackEvent } from 'src/utils/AnalyticUtils';
import { CALC_TYPE_ZERO_TARGET } from 'src/utils/const';

import AttachmentSection from './AttachmentSection';
import CommentSection from './CommentSection';
import InputProgressSection from './InputProgressSection';
import MeasurementSection from './MeasurementSection';
import MilestoneProgressSection from './MilestoneProgressSection';

function ModalUpdateProgress({
  completeGoal,
  modalType,
  eventOnClick,
  measurement,
  canUpdateCurrentValue,
  objectiveId,
  objectiveName,
  commentOptions,
  goalType,
  onSubmit,
  saveCallback,
  currentMilestone = null,
  milestoneType = '',
  objectiveValue,
  objectiveConfigs,
  shouldReloadCurrentObjective = true
}) {
  const { refetchObjective, refetchQueries } = useRefetchQuery();
  const { reload, reloadSidebar } = useReload();
  const [metaMentions, setMetaMentions] = useState([]);
  const [comment, setComment] = useState('');
  const [listOfFiles, setListOfFiles] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [onUpload, setOnUpload] = useState(false);
  const [selectedTemplateComment, setSelectedTemplateComment] = useState(null);
  const [milestoneData, setMilestones] = useState([]);
  const [progressValue, setProgressValue] = useState(measurement?.currentValue);
  const [toastData, setToastData] = useState({ isShow: false });
  const { config, organization } = useUser();
  const isBCA = organization?.identifier == 'bca';
  const permissions = objectiveValue?.permissions || [];
  const enableOptional = canUpdateCurrentValue
    ? config?.enableOptionalCommentProgress
    : config?.enableOptionalCommentEdit;

  const hasPermissionsComment = permissions.includes(
    canUpdateCurrentValue && !isBCA ? 'progress_comment' : 'update_comment'
  );

  const attachFile = (e) => {
    let list = [...listOfFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      list.push(e.target.files[i]);
    }
    setListOfFiles(list);
  };

  const deleteFile = (index) => {
    let newListOfFiles = [...listOfFiles];
    newListOfFiles.splice(index, 1);
    setListOfFiles(newListOfFiles);

    document.getElementById('attachment').value = '';
  };

  const _getPresignUrl = async (file) => {
    const filename = file.name;
    const extensions = filename.split('.').pop();

    const presign_query = {
      directory: 'files',
      extension: extensions,
      public: true
    };
    const { data, error } = await getPerformancePreSignUrl(presign_query);
    return { data, error };
  };

  const uploadToServer = async (url, file) => {
    const { status } = await S3Client(url, file);
    return status;
  };

  const createCommentAction = async (metaMentionsPayload) => {
    const body = {
      comment: comment,
      metaMentions: metaMentionsPayload,
      commentOptionId: selectedTemplateComment || null
    };

    const { isSuccess, error } = await createComment(objectiveId, body);
    return isSuccess ? [] : [error];
  };

  const uploadFileAction = async (metaMentionsPayload) => {
    setOnUpload(true);
    let errorList = [];
    const promises = listOfFiles?.map(async (value) => {
      if (value) {
        const { data: url, error } = await _getPresignUrl(value);

        if (url) {
          const status = await uploadToServer(url.uploadUrl, value);
          if (status == 200) {
            const body = {
              comment: comment,
              filename: value.name,
              file_timestamp: new Date(value.lastModified),
              path: url.path,
              metaMentions: metaMentionsPayload,
              commentOptionId: selectedTemplateComment || null,
              public: true
            };

            const { isSuccess, error } = await createAttachment(
              objectiveId,
              body
            );
            if (!isSuccess) errorList.push(error?.message);
          } else {
            errorList.push('Attachment upload failed');
          }
        } else {
          errorList.push(error?.message);
        }
      }
    });

    await Promise.all(promises);
    setOnUpload(false);
    return errorList;
  };

  const onSubmitComment = async () => {
    let errorList = [];
    if (!showComment) return errorList;
    const metaMentionsPayload = metaMentions.map((value) => {
      return {
        userId: value.userId,
        startIndex: value.startIndex,
        length: value.length
      };
    });
    if (onSubmit)
      return onSubmit(
        objectiveId,
        comment,
        metaMentionsPayload,
        objectiveValue?.parentIds
      );
    if (listOfFiles.length == 0) {
      errorList = await createCommentAction(metaMentionsPayload);
    } else {
      errorList = await uploadFileAction(metaMentionsPayload);
    }
    return errorList;
  };

  const updateMilestone = async (objectiveId) => {
    let bodyMilestones = milestoneData?.map((value) => {
      return { id: value.id, currentValue: value.currentValue };
    });
    return await updateProgressMilestone(objectiveId, {
      milestones: bodyMilestones
    });
  };

  const showComment =
    enableOptional || hasPermissionsComment || modalType == 'comment';

  const submit = async () => {
    setOnLoading(true);
    const errorList = await onSubmitComment();
    if (errorList?.length > 0) {
      const errorMessage =
        errorList?.length > 1 ? errorList.join(', ') : errorList;
      setToastData({ isShow: true, msg: errorMessage });
    } else {
      let body = { current_value: parseFloat(progressValue || '0') };
      if (
        canUpdateCurrentValue &&
        measurement &&
        milestoneType &&
        milestoneType == 'disabled'
      ) {
        const { error } = await updateCurrentValue(objectiveId, body);
        if (error) errorList.push(error?.message);
      }
      if (modalType === 'update')
        sendAmplitudeUpdateGoalTask(measurement?.currentValue, progressValue);
      if (
        canUpdateCurrentValue &&
        milestoneType &&
        milestoneType !== 'disabled' &&
        milestoneData.length > 0
      ) {
        const { error } = await updateMilestone(objectiveId);
        if (error) errorList.push(error?.message);
      }
      if (modalType === 'complete') {
        const { error } = await completeGoal({
          state: 'completed',
          type: 'completed'
        });
        if (error) errorList.push(error?.message);
      }

      // backend job need time to update data (recursive data)
      setTimeout(() => {
        shouldReloadCurrentObjective && refetchObjective(objectiveId);
        if (objectiveConfigs?.approvalRequired) {
          refetchQueries(['teamGoals', 'approval']);
          refetchQueries(['overallProgress']);
        }
      }, 1000);
      reload({
        reloadSidebar: !reloadSidebar
      });
    }
    setTimeout(() => {
      setToastData({ isShow: false });
    }, 500);

    setOnLoading(false);
    eventOnClick();

    saveCallback && saveCallback(progressValue, measurement);
  };

  const sendAmplitudeUpdateGoalTask = (newFormatedValue, newValue) => {
    let properties = {
      'previous value': newFormatedValue,
      'current value': parseFloat(newValue || '0')
    };
    trackEvent({
      event: `update ${goalType == 'task' ? 'goal' : 'task'} target metric`,
      eventProperties: properties
    });
  };

  const getCommentText = () => {
    let text;
    if (onUpload) {
      text = 'Uploading...';
    } else if (onLoading) {
      text = 'Loading...';
    } else if (modalType === 'complete') {
      text = 'Complete Progress';
    } else if (modalType == 'comment' || modalType == 'edit') {
      text = `${getObjectiveLocale('Submit Comment')}`;
    } else {
      text = `${getObjectiveLocale('Update Progress')}`;
    }
    return text;
  };

  let isValid = hasPermissionsComment && !selectedTemplateComment && !comment;

  const checkModalType = (modalType) => {
    const isTask = location.pathname.includes('tasks');
    let text;

    if (modalType === 'complete') {
      text = isTask
        ? getObjectiveLocale('Complete Task')
        : objectiveValue?.isProject
        ? getObjectiveLocale('Complete Project')
        : getObjectiveLocale('Complete Goal');
    } else if (modalType === 'update') {
      text = getObjectiveLocale('Update Progress');
    } else if (modalType === 'edit') {
      text = isTask
        ? getObjectiveLocale('Edit Task')
        : getObjectiveLocale('Edit objective');
    } else if (modalType === 'comment') {
      text = 'Comment Update';
    } else {
      text = 'Follow Up';
    }
    return text;
  };

  const modalProps = {
    title: checkModalType(modalType),
    withCloseIcon: true,
    eventOnClickClose: eventOnClick,
    className:
      'modal-goal-tree modal-update-target-goal-tree modal-update-progress w-[600px]',
    withFileBtn:
      modalType !== 'comment' && showComment
        ? {
            title: 'Attach File',
            dataCy: 'attachment-input',
            onClick: attachFile
          }
        : {},
    withPrimaryBtn: {
      title: getCommentText(),
      dataCy: 'update-progress-submit',
      onClick: (e) => {
        e.stopPropagation();
        submit();
      },
      disabled:
        !(
          !hasPermissionsComment ||
          !((!comment || !comment.trim()) && !selectedTemplateComment)
        ) ||
        onUpload ||
        onLoading,
      onMouseDown: submit
    },
    withSecondaryBtn: {
      title: 'Cancel',
      dataCy: 'cancel-update-progress',
      onClick: eventOnClick
    },
    dataCyModal: 'update-modal',
    contentWrapperClass: 'overflow-auto overflow-x-hidden',
    useBorder: true,
    maxHeight: 600
  };

  useEffect(() => {
    const modalContent = document.querySelector('.modal-content');
    const inputProgress = modalContent.querySelector('.styled-input');
    if (inputProgress) {
      inputProgress.focus();
      inputProgress.select();
      return;
    }
    const commentbox = modalContent.querySelector('#editor-comment');

    setTimeout(() => {
      commentbox?.click();
    }, [100]);
  }, []);

  return (
    <>
      {toastData?.isShow && (
        <ToastNotif
          showNotif={toastData?.isShow}
          message={toastData?.msg}
          warning={true}
        />
      )}
      <Modal {...modalProps}>
        <MeasurementSection
          measurement={measurement}
          objectiveName={objectiveName}
        />
        {currentMilestone && milestoneType !== 'disabled' ? (
          <MilestoneProgressSection
            milestoneType={milestoneType}
            measurement={measurement}
            dataId={objectiveId}
            updatedOnly={milestoneData}
            setUpdated={setMilestones}
            objectiveValue={objectiveValue}
          />
        ) : (
          <InputProgressSection
            measurement={measurement}
            canUpdateCurrentValue={canUpdateCurrentValue}
            progressValue={progressValue}
            setProgressValue={setProgressValue}
            allowNegative={
              !objectiveValue?.calculationType?.includes(CALC_TYPE_ZERO_TARGET)
            }
          />
        )}
        {showComment ? (
          <>
            <CommentSection
              permission={hasPermissionsComment}
              selectedTemplateComment={selectedTemplateComment}
              setSelectedTemplateComment={setSelectedTemplateComment}
              setComment={setComment}
              metaMentions={metaMentions}
              setMetaMentions={setMetaMentions}
              isValid={isValid}
              modalType={modalType}
              commentOptions={commentOptions}
            />
            <AttachmentSection
              modalType={modalType}
              deleteFile={deleteFile}
              listOfFiles={listOfFiles}
            />
          </>
        ) : (
          <div className="mb-[24px]" />
        )}
      </Modal>
    </>
  );
}

export default ModalUpdateProgress;

import client from 'client/ApiClient';

function getUsers(query) {
  return client(`/v1/admin/users/performances`, { params: query });
}

function getScoringMark() {
  return client(`/v1/admin/scorings/displays`, { params: {} });
}

export { getUsers, getScoringMark };

import React, { useCallback, useEffect, useState } from 'react';

import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';
import PhaseDropdown from 'components/tasks/dropdown/PhaseDropdown';
import { getObjectivePhases } from 'src/client/ObjectivesClient';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

const AddPhase = ({ objectiveValue, setObjectiveValue }) => {
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [listPhases, setListPhases] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);

  const parent = objectiveValue?.parents?.[0] || null;

  const onChange = (id) => {
    const selPhase = listPhases?.find((phase) => phase.id === id) || {};
    delete selPhase?.createdAt;
    delete selPhase?.updatedAt;

    setSelectedPhase(selPhase);
    setObjectiveValue((draft) => {
      draft.phaseId = id;
      draft.phase = selPhase;
    });
  };

  const getPhases = useCallback(async () => {
    let params = {};

    if (parent?.isProject) {
      params.projectId = parent?.id;
    } else if (parent?.type == 'task') {
      params.taskId = parent?.id;
    }

    const { data } = await getObjectivePhases(params);
    if (data) {
      setListPhases(data);
    }
  }, [parent]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedPhase(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (listPhases?.length > 0) {
      const defaultPhase = listPhases?.find(
        (phase) => phase.id === objectiveValue.phaseId
      );
      if (defaultPhase) {
        setDefaultValue(defaultPhase);
      } else {
        setObjectiveValue((draft) => {
          draft.phaseId = listPhases?.[0]?.id;
        });
      }
    }
  }, [listPhases, objectiveValue, setObjectiveValue]);

  useEffect(() => {
    getPhases();
  }, [parent, getPhases]);

  return (
    <div className="px-[24px] hover:bg-base-30024 cursor-pointer">
      <PhaseDropdown
        useTrigger
        useCustomTrigger={<CustomTrigger selectedPhase={selectedPhase} />}
        position="left"
        customClass="w-full"
        useFloating={false}
        onChange={onChange}
        listPhases={listPhases}
      />
    </div>
  );
};

const CustomTrigger = ({ selectedPhase }) => {
  const { name, colorHex, bgColorHex } = selectedPhase || {};

  return (
    <div className="h-[48px] flex items-center">
      <SVGIcon
        iconName="icon-phase_outlined"
        size="24"
        fillColor="var(--n-600)"
        customClass="mr-[16px]"
      />
      {selectedPhase ? (
        <Badge
          defaultPointer
          content={name}
          colorHex={colorHex}
          bgColorHex={bgColorHex}
          className="pointer-events-none"
        />
      ) : (
        <span className="typography-paragraph text-n-600">
          {getObjectiveLocale('Add Phase')}
        </span>
      )}
    </div>
  );
};

export default AddPhase;

import React from 'react';

import Badge from 'components/shared/Badge';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

function ListLabel({ data }) {
  return (
    <>
      {data.map((list, index) => {
        return (
          <TooltipContainer
            show={list.tooltipText}
            position="bottom"
            text={list.tooltipText}
            key={list.id || index}
            useMaxWidth={list.id !== 'owner' && list.id !== 'labels'}
          >
            <Badge
              dataCy={`list-label-${index}`}
              className="mr-[8px] mt-[4px]"
              colorHex={list.colorHex}
              bgColorHex={list.bgColorHex}
              title={list.title}
              content={list.content}
              iconUrl={list.iconUrl}
              isEditable={list.editable}
              iconLeft={list.iconLeft}
              iconLeftColor={list.iconLeftColor}
              defaultPointer={list.defaultPointer}
              useEvent={list.useEvent}
              onClick={list.onClick}
              truncate={list.truncate}
              imageVariant={list.imageVariant}
              customMaxWidth={list.customMaxWidth}
            />
          </TooltipContainer>
        );
      })}
    </>
  );
}

export default ListLabel;

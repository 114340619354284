import React from 'react';

import Config from 'utils/Config';
import { getCultureLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

function RowOfLabelData({
  data,
  idx,
  showDeleteModal,
  showAddModal,
  onConfirmRestore,
  isReadOnly
}) {
  let listofGroup = [];
  data?.groups.map((group, idx) => {
    listofGroup.push(group.name);
  });
  return (
    <tr key={idx} onClick={(e) => showAddModal(e, 'Edit', data)}>
      <td>
        <span
          className="label-icon"
          style={{ background: `${data.colorCode}` }}
        >
          {data.labelType == 'announcement' && (
            <img src={Config.asset.admin.announcementLabel} />
          )}
          {data.title}
        </span>
      </td>
      <td>
        <span className="data-group">
          {data.isAllGroups
            ? 'All Groups'
            : listofGroup.length > 0
            ? listofGroup.join(', ')
            : getCultureLocale('General')}
        </span>
      </td>
      <td>
        <span>{data.deleted ? 'Archived' : 'Active'}</span>
      </td>
      <td>
        {!isReadOnly && (
          <div
            className="delete-icon"
            data-cy={`button-${data.id}`}
            onClick={(e) =>
              data.deleted
                ? onConfirmRestore(e, data)
                : showDeleteModal(e, data)
            }
          >
            <div className="tooltip">
              {data.deleted ? 'Restore' : 'Archive'}
            </div>
            <SVGIcon
              iconName={data.deleted ? 'icon-unarchive' : 'icon-archive'}
              size="24"
              fillColor="var(--n-600)"
            />
          </div>
        )}
      </td>
    </tr>
  );
}

export default RowOfLabelData;

import React, { useState } from 'react';

import { useUser } from 'context/UserContext';
import useModalObjectives from 'hooks/useModalObjectives';
import Config from 'utils/Config.js';

import ObjectiveSkeleton from 'components/shared/ObjectiveSkeleton';
import SVGIcon from 'components/shared/SVGIcon';

import { getObjectiveLocale } from '../../utils/HelperUtils';
import ListLabel from './ListLabel';
import Objective from './Objective';

function ListObjectivesReview({
  showChild,
  objective,
  showChildren,
  action = true,
  showDetail,
  isLoadChildren,
  children,
  level = 1
}) {
  const { config } = useUser();
  const {
    id,
    name,
    reviews,
    scoringMark,
    type,
    objectiveCategory,
    permissions,
    weight,
    childrenCount,
    complexity,
    scoringTemplate
  } = objective;

  const isObjectiveWeightTypeCategory =
    config && config.objectiveWeightType === 'type';
  const scoreReview = reviews?.length > 0 ? reviews[0]?.score : '';
  let scoringMarkName;
  let scoringMarkColor;

  if (scoringMark && reviews?.length > 0) {
    scoringMarkColor = scoringMark.colorHex || '';
    scoringMarkName = scoringMark.name || '';
    scoringMarkColor = `#${scoringMarkColor}`;
  } else {
    scoringMarkColor = 'var(--n-600)';
  }

  const showModalWeight = useModalObjectives((state) => state.showModal);
  const propsModal = {
    objectives: [objective],
    isIndividual: true
  };

  function insertLabel() {
    let listLabel = [];

    if (type == 'task') {
      listLabel.push({
        title: '',
        content: complexity,
        onClick: () =>
          showModalWeight({ modalType: 'adjustWeight', props: propsModal }),
        icon: Config.asset.createObjective.iconEditSidebar,
        editable: true
      });
    } else {
      if (isObjectiveWeightTypeCategory && objectiveCategory) {
        listLabel.push({
          title: '',
          content: objectiveCategory.name,
          colorHex: objectiveCategory.colorHex,
          bgColorHex: objectiveCategory.bgColorHex,
          editable: action && permissions?.includes('update'),
          onClick: () =>
            showModalWeight({ modalType: 'adjustWeight', props: propsModal }),
          icon: Config.asset.createObjective.iconEditSidebar
        });
      } else if (weight) {
        listLabel.push({
          title: 'Weight',
          content: `${weight}%`,
          editable: action && permissions?.includes('update'),
          onClick: () =>
            showModalWeight({ modalType: 'adjustWeight', props: propsModal }),
          icon: Config.asset.createObjective.iconEditSidebar
        });
      }
    }

    return listLabel;
  }

  let listHeader = insertLabel();

  return (
    <div className={`flex flex-col`}>
      <div className="flex justify-between position-r py-[16px]">
        <div
          className="flex w-[41%]"
          style={{ paddingLeft: `${(level - 1) * 40}px` }}
        >
          {childrenCount > 0 || type === 'task' ? (
            <SVGIcon
              onClick={showChildren}
              size="24"
              fillColor={`${
                type === 'task' ? 'var(--n-600)' : 'var(--base-600)'
              }`}
              iconName={`${
                type === 'task'
                  ? 'icon-task'
                  : showChild
                  ? 'icon-keyboard_arrow_down'
                  : 'icon-keyboard_arrow_right'
              }`}
            />
          ) : (
            <div className="w-[24px]" />
          )}
          <p
            className="typography-paragraph px-[16px]"
            {...(action && { onClick: () => showDetail(id) })}
          >
            {name}
          </p>
        </div>
        <div className="type-goal w-[26%] px-[16px]">
          <ListLabel data={listHeader} />
        </div>
        <div className="status-goal w-[34%] px-[16px]">
          {
            <span
              className={`exceed typography-h400-longform ${
                scoreReview ? '' : 'result'
              }`}
              style={{ color: scoringMarkColor }}
            >
              {scoreReview &&
                scoringTemplate.showOption == 'score_only' &&
                scoreReview.toFixed(2)}

              {scoreReview &&
                scoringTemplate.showOption == 'label_only' &&
                scoringMarkName}

              {scoreReview &&
                scoringTemplate.showOption == 'score_and_label' &&
                `${scoreReview.toFixed(2)} (${scoringMarkName})`}

              {!scoreReview && 'Not Yet Reviewed'}
            </span>
          }
        </div>
      </div>
      {/* CHILDREN */}
      {showChild &&
        childrenCount > 0 &&
        (isLoadChildren ? (
          <ObjectiveSkeleton />
        ) : (
          children.map((objectiveChildren, objectiveIndex) => {
            return (
              <Objective
                key={objectiveIndex}
                cardModel={'formal-review'}
                objective={objectiveChildren}
                page={'goalsScoring'}
                level={(level || 0) + 1}
              />
            );
          })
        ))}
    </div>
  );
}

export default ListObjectivesReview;

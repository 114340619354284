import React, { useEffect, useState } from 'react';

import { useUser } from 'context/UserContext';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import SetWeightComponent from 'components/shared/card/OverallProgressCard/SetWeightComponent';

import {
  _conditionMinMaxObjectivesHelper,
  _setStateHelpers
} from '././CreateObjectivesWarningHelpers';

const CreateObjectiveWarning = () => {
  const {
    config: {
      permissions: { objectiveWeightManage }
    }
  } = useUser();
  const [topDesc, setTopDesc] = useState('');
  const [maxObjectives, setMaxObjectives] = useState('');
  const [currentObjectives, setCurrentObjectives] = useState('');
  const [descObjectives, setDescObjectives] = useState('');
  const [isWarning, setIsWarning] = useState(false);
  const { getDataMinMax } = useValidationMinMax();
  const [dataMinMaxWeight, setDataMinMaxWeight] = useState({});

  const handleGetDataMinMax = async () => {
    const data = await getDataMinMax();
    setCurrentObjectives(data.totalObjectives);
    const conditionMinMaxObjectives = _conditionMinMaxObjectivesHelper(data);

    _setStateHelpers(
      conditionMinMaxObjectives,
      setTopDesc,
      setMaxObjectives,
      setDescObjectives,
      setIsWarning,
      data.maxNumberOfObjectives,
      data.minNumberOfObjectives,
      data.totalObjectives
    );
  };

  const getDataMinMaxWeight = async () => {
    const data = await getDataMinMax(true);
    setDataMinMaxWeight(data);
  };

  useEffect(() => {
    handleGetDataMinMax();
    getDataMinMaxWeight();
  }, []);
  return (
    <div
      className="panel-warning-container pt-[16px]"
      id="panel-warning-container-create-goal"
    >
      <div className="panel">
        <div
          className={`panel-left ${
            objectiveWeightManage ? '' : '!w-full !border-none'
          }`}
        >
          <div className="warning-create-objective-title typography-h500">
            {getObjectiveLocale('Top Parent Goal(s)')}
          </div>
          <div
            className="warning-create-objective-desc typography-h100"
            data-cy="top-desc-min-max-validation"
          >
            {topDesc}
          </div>
          <div className="warning-create-objective-weight">
            {isWarning && (
              <SVGIcon
                size="16"
                fillColor="var(--y-600)"
                iconName="icon-warning"
                customClass="mb-[5px] mr-[4px]"
                dataCy="icon-warning-min-max"
              />
            )}
            <div
              data-cy="current-objective-min-max-validation"
              className={`min-objective typography-h700 ${
                isWarning ? 'text-r-500' : 'text-n-900'
              }`}
            >
              {getNumberLocale(currentObjectives)}{' '}
            </div>
            <div
              data-cy="max-objective-min-max-validation"
              className="max-objective typography-h500"
            >
              {getNumberLocale(maxObjectives)}
            </div>
          </div>
          <div
            data-cy="bottom-desc-min-max-validation"
            className="warning-create-objective-desc typography-h100"
          >
            {descObjectives}
          </div>
        </div>
        {objectiveWeightManage && (
          <div className="panel-right">
            <SetWeightComponent
              useTopMessage
              totalWeight={dataMinMaxWeight.totalWeights}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateObjectiveWarning;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import TextArea from 'components/shared/TextArea';
import ContentWrapper from 'components/sidebar/sidebar-create/ContentWrapper';

const InputObjectiveTitle = ({
  activeLevel,
  objectiveValue,
  setObjectiveValue,
  disabled = false
}) => {
  const type = objectiveValue.type;

  const getPlaceholder = () => {
    if (type === 'goal' && activeLevel === 0) {
      return 'Goal name';
    } else if (type === 'goal' && activeLevel !== 0) {
      if (objectiveValue.isProject) {
        return 'Project name';
      } else {
        return 'Subgoal name';
      }
    } else {
      return 'Task title';
    }
  };

  const handleChangeTitle = (e) => {
    const { value } = e.currentTarget;

    setObjectiveValue((draft) => {
      draft.name = value;
    });
  };

  return (
    <ContentWrapper customWrapperClass="px-[24px] mb-[4px]">
      <TextArea
        textPlaceholder={getObjectiveLocale(getPlaceholder())}
        htmlFor="title"
        id="title"
        name="name"
        value={objectiveValue.name}
        onHandleChange={handleChangeTitle}
        dataCy="sidebar-create-title"
        showBorderOnHover
        typographyClass="typography-h600"
        isLimitedHeight={false}
        disabled={disabled}
      />
    </ContentWrapper>
  );
};

export default InputObjectiveTitle;

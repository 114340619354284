import React from 'react';

import ctl from '@netlify/classnames-template-literals';

import { getObjectiveLocale } from 'utils/HelperUtils';
import { activeVisibility } from 'utils/VisibilityHelper';

import SVGIcon from 'components/shared/SVGIcon';

const Privacy = ({ objectiveDetail }) => {
  const { permissions = [], isProject, type = 'goal' } = objectiveDetail || {};

  const containerClass = ctl(`
    h-[40px]
    bg-n-200
    flex
    items-center
    px-[16px] py-[8px]
    mx-[8px] mt-[8px]
    rounded
  `);

  return (
    <div className={containerClass}>
      <span className="typography-paragraph mr-[8px]">
        {`This ${
          type == 'task'
            ? getObjectiveLocale('task')
            : getObjectiveLocale(`${isProject ? 'project' : 'goal'}`)
        } is`}
      </span>
      <SVGIcon
        iconName={activeVisibility(objectiveDetail)?.iconName}
        size="16"
        fillColor={
          permissions?.includes('edit_privacy')
            ? 'var(--base-600)'
            : 'var(--n-600)'
        }
        customClass="mr-[4px]"
      />
      <p className="typography-paragraph text-base-600">
        {getObjectiveLocale(activeVisibility(objectiveDetail)?.text)}
      </p>
    </div>
  );
};

export default Privacy;

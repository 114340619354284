import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';

import EnvSetting from 'components/env-setting/EnvSetting';

import './dev-tools.scss';

const DevTools = () => {
  return (
    <div
      className="dev-tools-container fixed z-[99] bg-n-200 flex items-center p-[8px] rounded-[4px] border border-solid border-n-600 pointer shadow-raised"
      style={{
        left: process.env.NODE_ENV === 'development' ? '-140px' : '-100px',
        bottom: '-50px'
      }}
    >
      <ReactQueryDevtools
        initialIsOpen={false}
        toggleButtonProps={{
          style: { position: 'static', margin: '0px', padding: '0px' }
        }}
      />
      <EnvSetting />
    </div>
  );
};

export default DevTools;

import React, { useEffect, useState } from 'react';

import { getBehaviors } from 'client/adminClient.js';

import Modal from 'components/shared/modal/Modal';
import Checkbox from 'src/components/shared/Checkbox';

import './ModalSelectAttributes.scss';

const ModalSelectAttributes = ({
  selectedListAttribute,
  setIsModalSelectAttributeOpen,
  setSelectedListAttribute
}) => {
  const [modalListAttribute, setModalListAttribute] = useState([]);
  const [modalSelectedAttributes, setModalSelectedAttributes] = useState([]);

  const isCheckboxChecked = (id) => {
    return modalSelectedAttributes.includes(id);
  };

  const _getListWorkAttributes = async () => {
    let params = {
      state: 'active',
      behaviorType: 'value',
      subType: 'work_attribute',
      listFor: 'cms',
      groupId: 0
    };
    const { data } = await getBehaviors(params);
    if (data) {
      setModalListAttribute(data || []);
    }
  };

  const selectAttributes = (id, checked) => {
    let newSelectedAttributes = [...modalSelectedAttributes];
    if (checked) {
      newSelectedAttributes.push(id);
    } else {
      newSelectedAttributes = newSelectedAttributes.filter((val) => val != id);
    }
    setModalSelectedAttributes(newSelectedAttributes);
  };

  const handleAddAttributes = () => {
    let newObject = [];
    for (let i = 0; i < modalSelectedAttributes.length; i++) {
      newObject.push(
        modalListAttribute.find((x) => x.id == modalSelectedAttributes[i])
      );
    }
    setSelectedListAttribute(newObject);
    setIsModalSelectAttributeOpen(false);
  };

  useEffect(() => {
    _getListWorkAttributes();
  }, []);

  useEffect(() => {
    let array = [...selectedListAttribute];
    setModalSelectedAttributes(array.map((v) => v.id));
  }, []);

  const modalProps = {
    title: 'Select attributes',
    withCloseIcon: true,
    eventOnClickClose: () => setIsModalSelectAttributeOpen(false),
    withPrimaryBtn: {
      title: 'Add Attributes',
      dataCy: 'button-add-work-attributes',
      onClick: () => handleAddAttributes()
    },
    withSecondaryBtn: {
      title: 'Cancel',
      dataCy: 'cancel-add-attribute',
      onClick: () => setIsModalSelectAttributeOpen(false)
    },
    dataCyModal: 'modal-select-work-attributes'
  };

  return (
    <Modal {...modalProps}>
      {modalListAttribute.length > 8 && <div className="divider-line" />}

      {modalListAttribute.length !== 0 && (
        <div className="container-list-attribute">
          {modalListAttribute.map((val, idx) => (
            <div key={idx} className="wrapper-list-attribute">
              <Checkbox
                dataCy={'work-attributes-' + val.id}
                id={val.id}
                checked={isCheckboxChecked(val.id)}
                onChange={(e) =>
                  selectAttributes(val.id, e.currentTarget.checked)
                }
              >
                <p className="attribute-title">{val.title}</p>
              </Checkbox>
            </div>
          ))}
        </div>
      )}

      {modalListAttribute.length == 0 && (
        <p className="modal-empty-state">Data is empty</p>
      )}

      {modalListAttribute.length > 8 && <div className="divider-line" />}
    </Modal>
  );
};

export default ModalSelectAttributes;

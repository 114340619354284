import React, { useRef, useState } from 'react';

import useOnClickOutside from 'hooks/useClickOutside';

import InputField from 'components/shared/InputField';

const GanttInputName = ({ closeInputBox, parentParams, addNewCallbacks }) => {
  const ref = useRef();
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState('');

  const submitHandler = () => {
    closeInputBox();
    if (!inputValue) return;
    addNewCallbacks &&
      addNewCallbacks({
        mandatoryPayload: { name: inputValue, ...parentParams },
        parentParams
      });
  };

  useOnClickOutside(ref, () => {
    submitHandler();
  });

  const handleKeyPress = (e) => {
    if (e.key === 'Escape' || e.key === 'Enter') {
      inputRef.current.blur();
      submitHandler();
    }
  };

  return (
    <div className="h-[48px] flex items-center px-[16px] w-full" ref={ref}>
      <InputField
        className="pl-[32px]"
        ref={inputRef}
        inputClass="h-[32px] w-full"
        value={inputValue}
        handleChange={(e) => setInputValue(e.target.value)}
        handleKeyPress={handleKeyPress}
        autoFocus
      />
    </div>
  );
};

export default GanttInputName;

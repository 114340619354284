import React from 'react';

import isNumber from 'lodash/isNumber';

import { getObjectiveLocale } from 'utils/HelperUtils';
import { restructurePreviousSummaries } from 'utils/SummaryHelper';

import Tooltips from 'components/shared/Tooltips/Tooltips';

import {
  bgColorDict,
  fontColorDict,
  resultColorDict
} from './ColorDictionarySidebarEvidence';
import ProfileEvidence from './ProfileEvidence';

const SummarySidebar = ({ answersData }) => {
  const {
    sp,
    scoringMark,
    scores,
    revieweeSummaryVisibility,
    ratingTemplate,
    target,
    finalPeriodScore
  } = answersData;
  const finalScore = answersData?.totalScore;

  const userPositions = restructurePreviousSummaries(answersData);
  const lastPosition = userPositions[userPositions.length - 1];

  return (
    <div
      id="container-summary-sidebar"
      data-cy="container-summary-sidebar"
      className="evidence-border px-[16px] py-[16px] mt-[24px]"
    >
      <h5 className="typography-h500 text-n-900 mb-[16px]">
        {getObjectiveLocale('Summary')}
      </h5>
      <ProfileEvidence profileData={answersData?.actor} />
      {scoringMark && (
        <div className="mt-[12px] flex justify-between">
          {!ratingTemplate?.showOption ||
            (ratingTemplate?.showOption == 'score_and_label' && (
              <>
                <h5
                  className={`mb-[0px] typography-h500 ${
                    resultColorDict[scoringMark.colorHex]
                  }`}
                >
                  {scoringMark?.name || ''}
                </h5>
                <p
                  className={`evidence-score-box typography-h200 ${
                    bgColorDict[scoringMark.colorHex]
                  } ${fontColorDict[scoringMark.colorHex]}`}
                >
                  {finalPeriodScore?.toFixed(2) || finalScore?.toFixed(2)}
                </p>
              </>
            ))}

          {ratingTemplate?.showOption == 'score_only' && (
            <p
              className={`evidence-score-box typography-h200 ${
                bgColorDict[scoringMark.colorHex]
              } ${fontColorDict[scoringMark.colorHex]}`}
            >
              {finalScore?.toFixed(2)}
            </p>
          )}

          {ratingTemplate?.showOption == 'label_only' && (
            <h5
              className={`mb-[0px] typography-h500 ${
                resultColorDict[scoringMark.colorHex]
              }`}
            >
              {scoringMark?.name || ''}
            </h5>
          )}
        </div>
      )}

      {scores && (
        <div className="evidence-border bg-n-100 mt-[12px]">
          <div className="typography-h200 px-[16px] py-[10px] border-0 border-b-[1px] border-solid border-n-400 text-n-600">
            {getObjectiveLocale('Scoring Summary Details')}
          </div>
          <div className="px-[16px] pt-[10px]">
            {userPositions?.map((data, index) => (
              <div
                className="flex items-center justify-between py-[8px]"
                key={index}
              >
                <Tooltips
                  isEllipsis={data?.positionName?.length > 20}
                  tooltipClass="h-[20px] px-[4px] py-[2px]"
                  text={data?.positionName}
                >
                  <p className="typography-h100 text-n-800 truncate w-[148px]">
                    {data?.positionName}
                  </p>
                </Tooltips>
                <p className="typography-h100 text-n-800">
                  {data?.finalScore?.proportionalScore?.toFixed(2)}
                </p>
              </div>
            ))}
            {lastPosition?.scores?.map((score, index) => (
              <WrapperAssessment
                key={index}
                customClass="mx-[24px]"
                title={score?.description || score?.name}
                score={score?.weightedScore?.toFixed(2) || 0}
              />
            ))}
            <div className="flex justify-between py-[12px] mt-[8px] mx-[24px] border-0 border-t-[1px] border-solid border-n-300">
              <p className="typography-h100 text-n-600">
                {getObjectiveLocale('Total')}
              </p>
              <p className="typography-h100 text-n-600">
                {lastPosition?.finalScore?.score?.toFixed(2)}
              </p>
            </div>
            {isNumber(lastPosition?.finalScore?.proportional) &&
              lastPosition?.finalScore?.proportional > 0 && (
                <div className="flex justify-between pt-[4px] pb-[12px] mx-[24px]">
                  <p className="truncate typography-h100 text-n-600">
                    {getObjectiveLocale('Weighted Total Score')}
                  </p>
                  <p className="typography-h100 text-n-600">
                    {lastPosition?.finalScore?.convertedScore
                      ? lastPosition?.finalScore?.convertedScore
                      : lastPosition?.finalScore?.score}{' '}
                    x {lastPosition?.finalScore?.proportional?.toFixed(2)}
                  </p>
                </div>
              )}
            {sp &&
              Object.keys(sp).map((val, idx) => (
                <WrapperAssessment
                  customClass="pb-[20px]"
                  key={idx}
                  title={`SP ${idx + 1}`}
                  score={sp[val]?.weightedScore?.toFixed(2) || 0}
                  isSP={true}
                />
              ))}
          </div>
          {revieweeSummaryVisibility !== 'track_score_only' && (
            <div className="flex items-center justify-between p-[16px] bg-lb-300 rounded-b-4">
              <p className="typography-h300 uppercase">
                {getObjectiveLocale('Final Score')}
              </p>
              <p className="typography-h300">
                {finalPeriodScore?.toFixed(2) || finalScore?.toFixed(2)}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const WrapperAssessment = ({ title, score, isSP = false, customClass }) => {
  return (
    <div className={`flex items-center justify-between ${customClass}`}>
      <p
        className={`typography-h100 truncate mr-[16px] ${
          isSP ? 'text-n-800' : 'text-n-600'
        }`}
      >
        {title}
      </p>
      <p
        className={`typography-h100 ${
          isSP ? 'text-n-800' : score < 0 ? 'text-r-600' : 'text-n-600'
        }`}
      >
        {isSP && score != 0 ? '-' + score : score}
      </p>
    </div>
  );
};

export default SummarySidebar;

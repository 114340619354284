import React, { createRef, useEffect, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';

import flatten from 'lodash/flatten';
import snakeCase from 'lodash/snakeCase';

import {
  getListObjectives,
  getObjectiveChildren
} from 'client/ObjectivesClient';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useTeam from 'hooks/useTeam';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Switcher from 'components/design-system/Switcher';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import Objective from 'components/objectives/Objective';
import CompactSkeleton from 'components/objectives/compact-objective/CompactSkeleton';

const SubGoals = ({ objectiveId, subGoalsCount, defaultOptions, ...rest }) => {
  const [switcherOptions, setSwitcherOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('all');
  const [isLoading, setIsLoading] = useState(false);

  const intersectTarget = createRef();
  const { approvalSubGoals, setApprovalSubGoals, isAddingApprovalSubGoal } =
    useTeam();

  let statesCount = {
    all: 0,
    draft: 0,
    edited: 0,
    existing: 0,
    toBeDeleted: 0
  };

  const fetchChildrenStateCount = () => {
    const query = {
      metadata: 'count',
      group: 'state'
    };
    return getObjectiveChildren(objectiveId, query);
  };

  const setChildrenStateCount = (metadata) => {
    let options = [];

    defaultOptions?.map((option) => {
      let count = 0;
      if (option?.id === 'all') {
        for (const state in metadata) {
          if (state !== 'deleted') {
            count += metadata?.[state]?.count;
          }
        }
      } else if (option?.id === 'existing') {
        count =
          metadata?.running?.count +
          metadata?.reviewed?.count +
          metadata?.completed?.count;
      } else {
        count = metadata?.[option?.id]?.count;
      }
      if (count > 0) {
        option.name += ` (${count})`;
        options.push(option);
      }
      statesCount[option?.id] = count;
    });

    if (
      isAddingApprovalSubGoal &&
      options?.findIndex((option) => option?.id === 'draft') === -1 &&
      statesCount?.draft === 0
    ) {
      addDraftTab(options);
    } else {
      setSwitcherOptions(options);
    }

    if (
      statesCount?.draft === 0 &&
      selectedOption === 'draft' &&
      !isAddingApprovalSubGoal
    ) {
      setSelectedOption('all');
    }
  };

  const { isFetching: isFetchingStateCount } = useQuery(
    ['teamGoals', 'approval', 'childrenStateCount', objectiveId],
    fetchChildrenStateCount,
    {
      enabled: subGoalsCount > 0,
      onSuccess: (data) => {
        if (data?.metadata?.metadata) {
          setChildrenStateCount(data?.metadata?.metadata);
        }
      }
    }
  );

  const fetchSubGoals = (olderThan) => {
    let state = [];
    if (selectedOption === 'existing') {
      state = ['running', 'reviewed', 'completed'];
    } else if (selectedOption !== 'all') {
      state = [snakeCase(selectedOption)];
    }
    const query = {
      limit: 15,
      reviewsVisibility: 1,
      sortColumn: 'name',
      sortDirection: 'asc',
      parentId: objectiveId,
      goals: 'team',
      ...(state?.length > 0 && { state: state }),
      ...(olderThan && { olderThan: olderThan })
    };
    return getListObjectives(query);
  };

  const queryKey = [
    'teamGoals',
    'approval',
    'subGoals',
    objectiveId,
    selectedOption
  ];
  const {
    data,
    isFetching: isFetchingSubGoals,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(queryKey, ({ pageParam }) => fetchSubGoals(pageParam), {
    enabled: subGoalsCount > 0,
    getNextPageParam: (lastPage) =>
      lastPage?.pagination?.next?.olderThan || undefined
  });

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting && hasNextPage && fetchNextPage()
  });

  const addDraftTab = async (options) => {
    options?.splice(subGoalsCount === 0 ? 0 : 1, 0, {
      id: 'draft',
      name: `${getObjectiveLocale('New')} (0)`
    });
    setSwitcherOptions(options);
  };

  const onClickAddSubGoal = () => {
    if (!isAddingApprovalSubGoal) return;

    if (
      switcherOptions?.findIndex((option) => option?.id === 'draft') === -1 &&
      statesCount?.draft === 0
    ) {
      addDraftTab(switcherOptions);
    }
    setSelectedOption('draft');
  };

  useEffect(() => {
    onClickAddSubGoal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingApprovalSubGoal]);

  useEffect(() => {
    if (data) {
      const newSubGoals = flatten(
        data?.pages?.map((page) => {
          return page?.data?.map((pageData) => {
            return pageData;
          });
        })
      );
      setApprovalSubGoals(newSubGoals);
    }
  }, [data, setApprovalSubGoals]);

  useEffect(() => {
    setIsLoading(isFetchingSubGoals && !isFetchingNextPage);
  }, [isFetchingSubGoals, isFetchingNextPage, selectedOption]);

  return (
    <div className="mt-[16px]">
      {isFetchingStateCount || switcherOptions?.length === 0 ? (
        <div className="flex items-center h-[34px]">
          <Shimmer width="144px" height="16px" />
        </div>
      ) : (
        <Switcher
          options={switcherOptions}
          selectedOption={selectedOption}
          onChange={(id) => setSelectedOption(id)}
          textCustomClass="typography-button min-w-[112px]"
        />
      )}
      <div className="mt-[16px]">
        <div id={`add-sub-goal-for-${objectiveId}`} className="add-card"></div>
        {isLoading ? (
          <CompactSkeleton />
        ) : (
          <>
            {approvalSubGoals?.map((subGoal, index) => (
              <Objective
                objective={subGoal}
                objectiveIndex={index}
                isLastIndex={approvalSubGoals?.length == index + 1}
                showStateIcon
                isTeamGoalsApproval
                {...rest}
                key={index}
              />
            ))}
            {isFetchingNextPage ? (
              <div className="mt-[8px]">
                <CompactSkeleton />
              </div>
            ) : (
              hasNextPage && <div ref={intersectTarget} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SubGoals;

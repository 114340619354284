import React from 'react';

import startCase from 'lodash/startCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import InputField from 'components/shared/InputField';
import TextArea from 'components/shared/TextArea';

import ManageTeamProfile from './ManageTeamProfile';

const SetupTeamName = ({ teamValue, setTeamValue, type }) => {
  return (
    <>
      <InputField
        label={`${startCase(type)} Name`}
        placeholder={`Type your ${type} name here`}
        value={teamValue?.name}
        dataCy={`${type}-field-name`}
        handleChange={(e) => {
          e.persist();
          setTeamValue((draft) => {
            draft.name = e.target.value;
          });
        }}
      />

      <div className="my-[16px]">
        <p className="mb-[8px] typography-h100 text-n-800">
          {getObjectiveLocale('Description')}
        </p>
        <TextArea
          textPlaceholder={getObjectiveLocale(`Describe your ${type} here`)}
          onHandleChange={(e) => {
            e.persist();
            setTeamValue((draft) => {
              draft.description = e.target.value;
            });
          }}
          value={teamValue?.description}
          height={56}
          dataCy={`${type}-description`}
        />
      </div>

      <div className="flex flex-col my-[16px]">
        <p className="typography-h100 text-n-800 mb-[8px]">
          {getObjectiveLocale('Privacy')}
        </p>
        <Dropdown>
          <Dropdown.Trigger dataCy="privacy-team">
            <Trigger
              icon={false}
              topLabel={false}
              label={false}
              iconName="icon-arrow_drop_down"
              text={getObjectiveLocale(
                teamValue.visibility === 'private_team' ? 'Private' : 'Public'
              )}
              customWrapperClass="w-[200px]"
            />
          </Dropdown.Trigger>
          <Dropdown.MenuItems customClass="w-[200px]">
            <Dropdown.MenuItem
              dataCy="public-team"
              onClick={() =>
                setTeamValue((draft) => {
                  draft.visibility = 'public_team';
                })
              }
            >
              {getObjectiveLocale('Public')}
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              dataCy="private-team"
              onClick={() =>
                setTeamValue((draft) => {
                  draft.visibility = 'private_team';
                })
              }
            >
              {getObjectiveLocale('Private')}
            </Dropdown.MenuItem>
          </Dropdown.MenuItems>
        </Dropdown>
      </div>

      <p className="typography-h100 text-n-800 mb-[8px]">
        {getObjectiveLocale('Image')}
      </p>
      <ManageTeamProfile
        teamValue={teamValue}
        updateImage={(img) =>
          setTeamValue((draft) => {
            draft.teamPicture = img;
          })
        }
      />
    </>
  );
};

export default SetupTeamName;

import { getObjectivePriorities } from "client/ObjectivesClient";
import { useInfiniteQuery } from "react-query";

export default function useObjectivePriorities() {

  const GetObjectivePhases = (params) => {

    const fetchObjectivePriorities = () => {
      return getObjectivePriorities(params);
    };

    let {
      data,
      status,
      isFetching,
      refetch
    } = useInfiniteQuery(["objective-priorities", params], fetchObjectivePriorities);

    return {
      data,
      status,
      isFetching,
      refetch
    };
  };

  return { getObjectivePriorities: GetObjectivePhases };
}
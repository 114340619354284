"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const HelperUtils_1 = require("utils/HelperUtils");
require("./LineProgressBar.scss");
const LineProgressBar = ({ width = 200, height = 20, progress = 0, expectedProgress = 0, progressText, strokeWidth = 50, backgroundColor = 'var(--n-400)', secondaryColor = 'var(--n-600)', primaryColor = 'var(--base-600)', fullFirst = false, dataCy = '', transitionDuration = '700ms', transitionEffect = 'ease-in-out', classLine }) => {
    const pathRef = (0, react_1.useRef)(null);
    const expectedPathRef = (0, react_1.useRef)(null);
    const [pathLength, setPathLength] = (0, react_1.useState)('');
    const [expectedPathLength, setExpectedPathLength] = (0, react_1.useState)('');
    const [offset, setOffset] = (0, react_1.useState)(fullFirst ? 0 : pathLength || width);
    const [expectedOffset, setExpectedOffset] = (0, react_1.useState)(fullFirst ? 0 : expectedPathLength || width);
    (0, react_1.useEffect)(() => {
        var _a;
        if (pathRef.current) {
            const length = (_a = pathRef.current) === null || _a === void 0 ? void 0 : _a.getTotalLength();
            setPathLength(length);
            const to = length * ((100 - progress) / 100);
            setOffset(to < 0 ? 0 : to > length ? length : to);
        }
    }, [progress, setOffset, pathRef.current]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (expectedPathRef.current) {
            const length = (_a = expectedPathRef.current) === null || _a === void 0 ? void 0 : _a.getTotalLength();
            setExpectedPathLength(length);
            const to = length * ((100 - expectedProgress) / 100);
            setExpectedOffset(to < 0 ? 0 : to);
        }
    }, [
        expectedProgress,
        expectedOffset,
        setExpectedOffset,
        expectedPathRef.current
    ]);
    const transition = {
        transition: `stroke-dashoffset ${transitionDuration} ${transitionEffect}`
    };
    return (react_1.default.createElement("div", { className: `line-svg-progress-bar-container relative height-${height} flex ${classLine ? classLine : ''}`, "data-cy": dataCy },
        react_1.default.createElement("svg", { width: width, height: height, className: "rounded-[12px]" },
            react_1.default.createElement("g", { fill: "none", strokeWidth: strokeWidth },
                react_1.default.createElement("path", { d: `M0 10, ${width} 10`, stroke: backgroundColor }),
                react_1.default.createElement("path", { d: `M0 10, ${width} 10`, stroke: secondaryColor, strokeDasharray: expectedPathLength, ref: expectedPathRef, strokeDashoffset: expectedOffset, style: transition }),
                react_1.default.createElement("path", { d: `M0 10, ${width} 10`, stroke: pathRef.current ? primaryColor : backgroundColor, strokeDasharray: pathLength, ref: pathRef, strokeDashoffset: offset, style: transition }),
                progressText && (react_1.default.createElement("text", { x: "50%", y: "50%", dominantBaseline: "middle", textAnchor: "middle", fill: progress < 50 ? 'var(--base-600)' : 'var(--n-000)', className: "svg-circle-text fs-12 fw-bold" },
                    (0, HelperUtils_1.getNumberLocale)(progress),
                    "%"))))));
};
exports.default = LineProgressBar;

import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import {
  getBehaviourReview,
  getPerformanceRatingObjectives,
  getPerformanceRatingSource
} from 'client/PerformanceReport';

import BehaviourReviewComponent from '../BehaviourReviewComponent';
import PerformanceReportSourceComponent from '../PerformanceReportSourceComponent';
import PerformanceReportTeamDetailHeaderComponent from '../PerformanceReportTeamDetailHeaderComponent';

function DetailTab(props) {
  const { data, cycle } = props.detailInfo;
  const [activeGoalDetail, setActiveGoalDetail] = useState(data);
  const [activeCycleDetail, setActiveCycleDetail] = useState(cycle);
  const [goalsInCycle, setGoalsInCycle] = useState([]);
  const [activeCycleReviewDetail, setActiveCycleReviewDetail] = useState([]);

  const [listRecognitionValues, setListRecognitionValues] = useState([]);
  const [listFeedbackValues, setListFeedbackValues] = useState([]);
  const [listRecognitionCompetencies, setListRecognitionCompetencies] =
    useState([]);
  const [listFeedbackCompetencies, setListFeedbackCompetencies] = useState([]);
  const [error, setError] = useState(false);

  const userId = props.match.params.userId;

  const getCycleReviewDetail = async (cycleId) => {
    const query = {
      targetId: userId,
      cycleId: cycleId
    };
    const { data, error } = await getPerformanceRatingSource(query);

    if (data) {
      setActiveCycleReviewDetail(data);
    }
    if (error) {
      setError(true);
    }
  };

  const getAllGoalsInCycle = async () => {
    const query = {
      targetId: userId
    };
    const { data, error } = await getPerformanceRatingObjectives(
      query,
      activeCycleDetail.id
    );
    if (data) {
      setGoalsInCycle(data);
    }
    if (error) {
      setError(true);
    }
  };

  const _getBehaviourReview = async (cycleId) => {
    const query = {
      cycleId: cycleId,
      targetId: userId,
      cycleState: 'done'
    };

    const { data, error } = await getBehaviourReview(query);
    if (data) {
      setListRecognitionValues(data.recognitionValues);
      setListFeedbackValues(data.feedbackValues);
      setListRecognitionCompetencies(data.recognitionCompetencies);
      setListFeedbackCompetencies(data.feedbackCompetencies);
    }
    if (error) {
      setError(true);
    }
  };

  useEffect(() => {
    setActiveGoalDetail(data);
    setActiveCycleDetail(cycle);
    getCycleReviewDetail(cycle.id);
    getAllGoalsInCycle();
    _getBehaviourReview(cycle.id);
  }, []);

  return (
    <div className="performance-report">
      <div className="back-button-container">
        <div className="back-button">Performance Report</div>
        <div className="page-title">
          / {activeGoalDetail.name} - {activeCycleDetail.name}
        </div>
      </div>
      <PerformanceReportTeamDetailHeaderComponent
        data={activeGoalDetail}
        cycle={activeCycleDetail}
        goals={goalsInCycle}
      />
      <div className="row">
        <div className="col-md-4">
          {(listRecognitionValues.length > 0 ||
            listFeedbackValues.length > 0) && (
            <BehaviourReviewComponent
              recognition={listRecognitionValues}
              feedback={listFeedbackValues}
              isHorizontal={false}
              type="values"
            />
          )}
          {(listRecognitionCompetencies.length > 0 ||
            listFeedbackCompetencies.length > 0) && (
            <BehaviourReviewComponent
              recognition={listRecognitionCompetencies}
              feedback={listFeedbackCompetencies}
              isHorizontal={false}
              type="competencies"
            />
          )}
        </div>
        <div className="col-md-8">
          <h4>Sources</h4>

          {activeCycleReviewDetail.map((data, index) => (
            <Fragment key={index}>
              <PerformanceReportSourceComponent
                data={data}
                maxScore={
                  props.performanceReportTeam?.average_score?.max_score
                    ? props.performanceReportTeam.average_score.max_score
                    : 5
                }
              />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withRouter(DetailTab);

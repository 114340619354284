import React from 'react';

import SVGIcon from 'components/shared/SVGIcon';

const Header = ({ handleClose }) => {
  return (
    <div className="flex justify-between items-center">
      <span className="typography-h500">Attachment</span>
      <SVGIcon
        onClick={handleClose}
        fillColor="var(--n-600)"
        iconName="icon-clear"
        size="24"
        customClass="ml-[16px]"
      />
    </div>
  );
};

export default Header;

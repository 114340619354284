import React, { useEffect, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getPerformanceScore } from 'client/admin/Overview';

import LoadingComponent from 'components/shared/LoadingComponent';

import ObjectivesCreatedComponent from './ObjectivesCreatedComponent.js';

function PerformanceScoreComponent() {
  const [chartPerformanceScore, setChartPerformanceScore] = useState({});
  const [isLoading, setisLoading] = useState(true);
  let seriesPerformanceScore = [];

  const _getPerformanceScore = async () => {
    const { data, error } = await getPerformanceScore();

    if (data) {
      setisLoading(false);
      for (let propt in data) {
        seriesPerformanceScore.push({
          name: data[propt].scoringMark.name,
          data: [data[propt].total],
          color: '#' + data[propt].scoringMark.colorHex
        });
      }
    }

    setChartPerformanceScore({
      chart: {
        style: {
          fontFamily: 'Inter'
        },
        backgroundColor: 'var(--n-200)',
        type: 'column',
        height: 215,
        wdith: '100%',
        marginLeft: 50,
        marginTop: 50
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'vertical'
      },
      title: {
        text: 'Activation Trend'
      },
      tooltip: {
        headerFormat: '<b>{point.y:f}</b><br>',
        pointFormat: '{series.name}'
      },
      xAxis: {
        title: {
          text: ''
        },
        labels: {
          enabled: false
        },
        minorTickLength: 0,
        tickLength: 0
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      credits: {
        enabled: false
      },
      series: seriesPerformanceScore,
      exporting: {
        buttons: {
          contextButton: {
            enabled: false
          }
        }
      }
    });
  };

  useEffect(() => {
    _getPerformanceScore();
  }, []);

  return (
    <div className="row">
      <div className="col-xs-8">
        <div className="card">
          <div className="card-body">
            <h4 className="body-header">
              PERFORMANCE SCORE<span className="description"></span>
            </h4>
            <div className="chart-box">
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartPerformanceScore}
                ></HighchartsReact>
              )}
            </div>
          </div>
        </div>
      </div>
      <ObjectivesCreatedComponent />
    </div>
  );
}

export default PerformanceScoreComponent;

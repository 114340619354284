import React from 'react';

import cloneDeep from 'lodash/cloneDeep';

import SetupTasks from './SetupTasks';

function MissionTasks({
  mission,
  setMission,
  newTask,
  setIndex,
  setPage,
  isReadOnly,
  templateData,
  haveSubmitted,
  setHaveSubmitted
}) {
  const onChangeTask = (task) => {
    let newMission = cloneDeep(mission);
    newMission.missionTasks = task;
    setMission(newMission);
  };

  const addTask = () => {
    setHaveSubmitted(false);
    let newMission = cloneDeep(mission);
    let tempTask = cloneDeep(newTask);
    let id = newMission.missionTasks.length;
    tempTask.idDrag = id;
    newMission.missionTasks.push(tempTask);
    setMission(newMission);
  };

  const editTask = (e, key, index) => {
    let editedMission = JSON.parse(JSON.stringify(mission));
    let currentMissionTask = editedMission?.missionTasks?.[index];
    let currentMissionTaskConditions = currentMissionTask['conditions'];
    if (!e || !currentMissionTask || !key) return;

    switch (key) {
      case 'missionTaskTemplateId':
        currentMissionTask[key] = e;
        if (currentMissionTask['targetId']) {
          currentMissionTask['targetId'] = null;
        }
        break;
      case 'hashtag':
      case 'minCharacterRequired':
        if (currentMissionTaskConditions) {
          currentMissionTaskConditions[key] = e?.target?.value;
        }
        break;
      case 'groupId':
      case 'labelId':
      case 'valueId':
        if (currentMissionTaskConditions) {
          currentMissionTaskConditions[key] = e?.id;
        }
        break;
      default:
        if (currentMissionTask.hasOwnProperty(key)) {
          currentMissionTask[key] = e?.target?.value;
        }
        break;
    }
    setMission(editedMission);
  };

  const onDeleteTask = (index) => {
    let newList = JSON.parse(JSON.stringify(mission));
    let newTask = newList.missionTasks.filter((data, id) => id !== index);
    newList.missionTasks = newTask;
    setMission(newList);
  };

  return (
    <div
      id="task-section"
      className="form-group border-bottom-400 pt-[40px] pb-[45px]"
    >
      <label
        htmlFor="missionTasks"
        className="typography-h500-longform text-n-900 mt-[16px]"
      >
        Setup task
      </label>
      <p className="typography-paragraph typography-secondary mb-[16px]">
        Create tasks for this mission.
      </p>
      <SetupTasks
        onDelete={onDeleteTask}
        setIndex={setIndex}
        setPage={setPage}
        tasks={mission.missionTasks}
        onClick={addTask}
        onChange={editTask}
        haveSubmitted={haveSubmitted}
        isUsingSequence={mission.isSequential}
        isActive={mission.status == 'active' || isReadOnly}
        dragTask={onChangeTask}
        templateData={templateData}
      />
    </div>
  );
}

export default MissionTasks;

import React from 'react';

const CustomProfileCard = ({
  title,
  count,
  children,
  className,
  classNameBox = ''
}) => {
  return (
    <div
      className={`${className} ${children ? 'overview-section' : 'col-md-4'}`}
    >
      <div className={`overview-box p-16 ${classNameBox}`}>
        <div className="typography-h500">{title}</div>
        {children ? (
          children
        ) : (
          <div className="typography-h900 h-[200px] flex items-center justify-center">
            {count}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomProfileCard;

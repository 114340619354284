import React from 'react';

import createClass from 'classnames';
import PropTypes from 'prop-types';

import Config from 'utils/Config';

import './TooltipStyle.scss';

const Interfaces = {
  text: PropTypes.string,
  children: PropTypes.element,
  tooltipClass: PropTypes.string
};

const DefaultValue = {
  text: 'Tootl Tip Text',
  children: (
    <img
      className="icon-information"
      src={Config.asset.admin.informationCircledGrey}
    />
  )
};

const Tooltips = ({
  text,
  children,
  addClass,
  isEllipsis,
  tooltipClass,
  tooltipTextAlign
}) => {
  return (
    <div className={`tooltips ${createClass(addClass)}`}>
      <div className={`tooltip-component tooltip-primary`}>
        <div className="tooltip-children">{children}</div>
        {isEllipsis && (
          <span
            className={`tooltiptext typography-h100 px-[8px] ${
              tooltipClass ? tooltipClass : ''
            }`}
            style={{ textAlign: tooltipTextAlign }}
          >
            {text}
          </span>
        )}
      </div>
    </div>
  );
};

Tooltips.propTypes = Interfaces;
Tooltips.defaultProps = DefaultValue;

export default Tooltips;

import React from 'react';

import Config from 'utils/Config';

function PostLabel({ labels, postId }) {
  return (
    <div className="feed-label scroll-label" data-cy={`post-label-${postId}`}>
      {labels.map((label) => {
        return (
          <div
            className="label-item"
            data-cy="post-label-item"
            style={{ backgroundColor: label.colorCode }}
            key={label.id}
          >
            {label.labelType === 'announcement' && (
              <img
                src={Config.asset.admin.announcementLabel}
                className="announce-icon"
                data-cy="announce-icon"
              />
            )}
            {label.title}
          </div>
        );
      })}
    </div>
  );
}

export default PostLabel;

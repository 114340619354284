import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Phase from 'components/formal-review/landing-page/Phase';
import SVGIcon from 'components/shared/SVGIcon';
import ListEmptyState from 'src/components/shared/ListEmptyState';

function MyAssignmentComponent({ filteredPhases, cycle, godView, filter }) {
  return (
    <div className="column-card-phase flex-col">
      <div className="pl-[48px] mb-[16px] relative">
        <div className="icon-card-phase purple">
          <SVGIcon
            iconName="icon-assignment_turned_in"
            size="16"
            fillColor="var(--base-600)"
          />
        </div>
        <div>
          <div className="typography-h500 text-n-900 mb-[4px]">
            {getObjectiveLocale('My Assignments')}
          </div>
          <div className="typography-paragraph text-n-800">
            {getObjectiveLocale(
              'Review assignments that you need to complete and submit'
            )}
          </div>
        </div>
      </div>

      <div className="assignment-container w-full">
        {!cycle.permissions?.assignmentsSee && (
          <ListEmptyState
            fullHeight
            emptyIcon="icon-no-active-performance-review"
            containerClassname="ml-[16px] bg-n-100"
            title={getObjectiveLocale('No Assignments')}
            titleClassname="typography-h500"
            subtitle={getObjectiveLocale(
              "You don't have an active assignment on this cycle"
            )}
          />
        )}

        <div className="ml-[48px]">
          {cycle.permissions?.assignmentsSee &&
            filteredPhases.length > 0 &&
            filteredPhases.map((phase, idx) => {
              if (
                typeof phase !== 'undefined' &&
                phase.permissions.assignmentsSee
              ) {
                return (
                  <Phase
                    key={idx}
                    cycleName={cycle.name}
                    cycleId={cycle.id}
                    statePhase={phase.state}
                    phaseType={phase.type}
                    dueDate={phase.endsAt}
                    teamReview={cycle.team}
                    feedbackType={cycle.feedbackType}
                    filter={filter}
                    cycleState={cycle.state}
                    inProgressDeliverable={cycle.inProgressDeliverable}
                    showDueDate={phase?.showDueDate}
                    allowViewTargetResult={phase?.allowViewTargetResult}
                    lockAnswerCycle={cycle.lockSubmittedAssignment}
                  />
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}

export default MyAssignmentComponent;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastProvider = exports.useToastContext = void 0;
const react_1 = __importStar(require("react"));
const uuid_1 = require("uuid");
const initialState = {
    portalId: 'toast-portal',
    toasts: [],
    loaded: false,
    newToast: {}
};
const defaultUpdate = () => initialState;
const ToastContext = (0, react_1.createContext)({
    state: initialState,
    setState: defaultUpdate
});
const ToastProvider = ({ children }) => {
    const [state, setState] = (0, react_1.useState)(initialState);
    return (react_1.default.createElement(ToastContext.Provider, { value: { state, setState } }, children));
};
exports.ToastProvider = ToastProvider;
const positionHandler = (position, portalId) => {
    const el = document.getElementById(portalId);
    if (!el)
        return;
    const { right = 24, bottom = 24 } = position;
    el.style.right = right + 'px';
    el.style.bottom = bottom + 'px';
};
const generateContainer = ({ portalId, state, setState }) => {
    if (document.getElementById(portalId))
        return;
    const div = document.createElement('div');
    div.id = portalId;
    div.setAttribute('style', 'position: fixed; bottom: 24px; right: 24px; z-index: 1002');
    document.getElementsByTagName('body')[0].prepend(div);
    setState(Object.assign(Object.assign({}, state), { loaded: true }));
};
const useToastContext = () => {
    const { state, setState } = (0, react_1.useContext)(ToastContext);
    const { portalId } = state;
    (0, react_1.useEffect)(() => {
        generateContainer({ portalId, state, setState });
        // eslint-disable-next-line
    }, []);
    const addToast = (data) => {
        const newToast = Object.assign({}, data);
        if (!Object.prototype.hasOwnProperty.call(newToast, 'id')) {
            newToast.id = (0, uuid_1.v4)();
        }
        setState((draft) => {
            var _a;
            const array = ((_a = draft.toasts) === null || _a === void 0 ? void 0 : _a.slice()) || [];
            if ((data === null || data === void 0 ? void 0 : data.id) && array.find((a) => a.id == (data === null || data === void 0 ? void 0 : data.id)))
                return draft;
            if (array.length == 3) {
                array.shift();
            }
            let draftToast = [...array];
            const lastToast = array[array.length - 1];
            if ((lastToast === null || lastToast === void 0 ? void 0 : lastToast.disabledAutoClose) && (lastToast === null || lastToast === void 0 ? void 0 : lastToast.type) == 'progress') {
                // lastToast is progress type & disabled to autoclose
                array.splice(array.length - 1, 1);
                draftToast = [...array, Object.assign({}, newToast), lastToast];
            }
            else {
                draftToast = [...array, Object.assign({}, newToast)];
            }
            return Object.assign(Object.assign({}, draft), { toasts: draftToast, newToast });
        });
    };
    const editToast = (id, editData) => {
        if (!id)
            return;
        const reservedEditData = editData || [];
        setState((draft) => {
            var _a;
            const toastsClone = ((_a = draft.toasts) === null || _a === void 0 ? void 0 : _a.slice()) || [];
            const currentIndex = toastsClone.findIndex((arr) => arr.id == id);
            if (currentIndex > -1) {
                toastsClone[currentIndex] = Object.assign(Object.assign({}, toastsClone[currentIndex]), reservedEditData);
            }
            return Object.assign(Object.assign({}, draft), { toasts: [...toastsClone] });
        });
    };
    const removeToast = (id) => {
        setState((draft) => {
            var _a;
            return (Object.assign(Object.assign({}, draft), { toasts: (_a = draft === null || draft === void 0 ? void 0 : draft.toasts) === null || _a === void 0 ? void 0 : _a.filter((toast) => toast.id != id) }));
        });
    };
    const changeContainerPosition = (position) => {
        if (!portalId)
            return;
        positionHandler(position, portalId);
    };
    return Object.assign(Object.assign({}, state), { addToast,
        removeToast,
        changeContainerPosition,
        editToast });
};
exports.useToastContext = useToastContext;

import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import { useUser } from 'context/UserContext';

import Button from 'components/design-system/Button';
import Objective from 'components/objectives/Objective';
import Checkbox from 'components/shared/Checkbox';
import ObjectiveSkeleton from 'components/shared/ObjectiveSkeleton';
import useMultipleEdit from 'src/hooks/useMultipleEdit';
import { getAvailableObjectiveListIds } from 'src/utils/ObjectivesHelper';

import ListObjectiveBody from './ListObjectiveBody';
import ListObjectiveFooter from './ListObjectiveFooter';
import ListObjectiveHeader from './ObjectiveHeader';

function ListObjectivesNew({
  objective,
  permissions,
  clusters,
  /* actionable or just view mode */
  action = true,
  sidebar = true,
  showDetail,
  /* if you have children */
  nested = true,
  showChildren,
  children,
  isLoadChildren,
  parentId,
  isParent,
  showChild,
  rotateDirection,
  /*if you need modal action */
  cardModel,
  depthLevel,
  level,
  page,
  fetchMore,
  canFetchMore,
  showBadgeObjectiveCount,
  ...rest
}) {
  // DESTRUCT OBJECTIVE VALUE
  const { id, childrenCount } = objective || [];

  // For Hardcode BCA
  const { organization, config } = useUser();
  const { includeTeamGoals } = config;

  // ALIGN PARENT
  const [currentSelectedParent, setCurrentSelectedParent] = useState(null);
  const [isHover, setHover] = useState(false);
  const limit = 50;

  // MULTIPLE EDIT
  const selectedObjective = useMultipleEdit((state) => state.selectedObjective);
  const updateSelected = useMultipleEdit((state) => state.updateSelected);
  const updateStartId = useMultipleEdit((state) => state.updateStartId);
  const startId = useMultipleEdit((state) => state.startId);
  const removeSelected = useMultipleEdit((state) => state.removeSelected);
  const removeAllSelected = useMultipleEdit((state) => state.removeAllSelected);

  const queryClient = useQueryClient();

  const isCheckedSelected = selectedObjective.find(
    (selObjective) => selObjective.id === objective.id
  );
  const hideMultipleDelete =
    page?.includes('approvalPanel+to_be_deleted') ||
    page?.includes('Scoring') ||
    page?.includes('scoring'); // Page include Scoring for all formal review

  const updateSelect = (e) => {
    if (e.target.checked) {
      updateSelected(objective);
    } else {
      removeSelected(objective.id);
    }
  };

  const holdSelectObjective = () => {
    if (selectedObjective.length === 0) {
      updateStartId(id);
      updateSelected(objective);
    } else {
      removeAllSelected();

      const availableObj = getAvailableObjectiveListIds();

      const stIdx = availableObj.findIndex((allObjs) => allObjs == startId);
      const endIdx = availableObj.findIndex((allObjs) => allObjs == id);

      let tmpArr = [];

      if (stIdx > endIdx) {
        tmpArr = availableObj.slice(endIdx, stIdx + 1);
      } else {
        tmpArr = availableObj.slice(stIdx, endIdx + 1);
      }
      tmpArr.forEach(async (obj) => {
        let queryKey = [page === 'myteams' ? 'team' : 'objective', obj];
        const data = queryClient.getQueryData(queryKey);
        await updateSelected(data?.data ? data?.data : data);
      });
    }

    if (
      selectedObjective.find(
        (sel) => sel.id === objective.id && selectedObjective.length === 1
      )
    ) {
      removeSelected(objective.id);
    }
  };

  return (
    <div
      className={`list-wrapper-objectives ${isParent ? '' : 'mt-[-1px]'}`}
      data-cy={`goal-${id}`}
      selector-id={id}
    >
      <div
        className={`flex relative
                ${
                  page?.includes('approvalPanel') ||
                  window.location.pathname.includes('/goals') ||
                  window.location.pathname.includes('/mytask') ||
                  window.location.pathname.includes('/companyGoals') ||
                  window.location.pathname.includes('/explorer')
                    ? 'ml-[-40px] pl-[40px]'
                    : 'ml-[-30px] pl-[30px]'
                }`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {!hideMultipleDelete &&
          organization.identifier != 'bca' && ( // maintained until multiple restore is complete
            <Checkbox
              dataCy="confirm-bulk-delete"
              name={`objective-selected-${objective.id}`}
              id={objective.id}
              style={{
                visibility: isHover || isCheckedSelected ? '' : 'hidden'
              }}
              customContainerClass={`checkbox-objectives absolute top-1/2 -translate-x-full -translate-y-1/2
                            ${
                              page?.includes('approvalPanel')
                                ? 'mx-[-5px]'
                                : window.location.pathname.includes('/goals') ||
                                  window.location.pathname.includes(
                                    '/mytask'
                                  ) ||
                                  window.location.pathname.includes(
                                    '/companyGoals'
                                  ) ||
                                  window.location.pathname.includes('/explorer')
                                ? 'mx-[-11px]'
                                : 'mx-[-7px]'
                            }`}
              checked={isCheckedSelected !== undefined}
              onChange={updateSelect}
              size="small"
            />
          )}
        <div
          className={`content-objectives ${level} pr-[16px]
                    ${
                      cardModel === 'checkin-list' && level > 1
                        ? 'py-[8px] last:rounded-b-[4px] border-t border-0 border-solid border-n-400'
                        : childrenCount > 0 && showChild && !isParent
                        ? 'py-[8px] rounded-[4px]'
                        : isParent
                        ? 'pt-[0px] pb-[8px] rounded-[4px]'
                        : level !== 0
                        ? 'py-[8px] rounded-[4px]'
                        : 'py-[8px]'
                    }`}
          style={{
            backgroundColor:
              isHover || isCheckedSelected ? 'var(--base-100)' : 'var(--n-000)',
            'flex-grow': '1',
            paddingLeft: (level - 1) * 40 + 16 + 'px',
            border:
              cardModel === 'checkin-list'
                ? ''
                : isCheckedSelected
                ? '1px solid var(--base-600)'
                : '1px solid var(--n-400)'
          }}
          {...(sidebar && {
            onClick: (e) => {
              !hideMultipleDelete && // maintained until multiple restore is complete
              (e.metaKey || e.ctrlKey)
                ? holdSelectObjective()
                : showDetail(id);
            }
          })}
        >
          {cardModel !== 'checkin-list' && isParent && (
            <ListObjectiveHeader
              objective={objective}
              currentSelectedParent={currentSelectedParent}
              setCurrentSelectedParent={setCurrentSelectedParent}
              parentId={parentId}
              action={action}
              permissions={permissions}
              clusters={clusters}
              showDetail={showDetail}
              page={page}
              isHover={isHover || isCheckedSelected}
              includeTeamGoals={includeTeamGoals}
            />
          )}
          <ListObjectiveBody
            objective={objective}
            action={action}
            isCheckin={cardModel == 'checkin-list'}
            permissions={permissions}
            clusters={clusters}
            page={page}
            level={level}
            showBadgeObjectiveCount={showBadgeObjectiveCount}
          />
          <ListObjectiveFooter
            objective={objective}
            showChild={showChild}
            clickShow={showChildren}
            rotateDirection={rotateDirection}
            nested={nested}
            action={action}
            permissions={permissions}
            clusters={clusters}
            depthLevel={depthLevel}
            level={level}
            page={page}
          />
          <div id={`add-sub-goal-for-${id}`} className="add-card"></div>
        </div>
      </div>
      {nested && showChild && childrenCount > 0 && (
        <>
          <div id={`objective-card-${id}`} className="children">
            {/* CHILDREN */}
            {isLoadChildren ? (
              <ObjectiveSkeleton />
            ) : (
              children.map((objectiveChildren, objectiveIndex) => {
                return (
                  <Objective
                    objective={objectiveChildren}
                    key={objectiveIndex}
                    cardModel={cardModel}
                    action={action}
                    nested={true}
                    level={level + 1}
                    depthLevel={depthLevel}
                    page={page}
                    {...rest}
                  />
                );
              })
            )}
          </div>
          {!isLoadChildren &&
            canFetchMore &&
            showChild &&
            childrenCount > limit && (
              <div className={`${level ? 'list-container' : ''} py-[18px]`}>
                <Button.Tertiary fullWidth onClick={fetchMore}>
                  Load More Objectives
                </Button.Tertiary>
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default ListObjectivesNew;

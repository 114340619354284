import React from 'react';
import Scrollbar from 'react-scrollbars-custom';

import ctl from '@netlify/classnames-template-literals';

import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ListEmptyState from 'components/shared/ListEmptyState';

import AnalyticsColumnSection from './section/column/AnalyticsColumnSection';
import InsightRowSection from './section/row/InsightRowSection';

const ObjectiveAnalyticsContent = () => {
  const insights = useObjectiveComparative((state) => state.insights);
  const isEmptyInsight = insights?.length === 0;

  const wrapperCN = ctl(`inline-block ${isEmptyInsight && 'w-full'}`);
  const containerCN = ctl(
    `flex flex-row w-full 
    ${isEmptyInsight ? 'h-[calc(100vh-100px)]' : 'h-full'}`
  );

  return (
    <div className="w-full h-full pt-[24px] px-[40px]">
      <Scrollbar
        scrollerProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return <span {...restProps} ref={elementRef} />;
          }
        }}
      >
        <div className={wrapperCN}>
          <div className={containerCN}>
            <InsightRowSection />

            <div className="w-full h-full flex flex-col">
              <AnalyticsColumnSection />
              {isEmptyInsight && (
                <ListEmptyState
                  containerClassname="grow"
                  emptyIcon="icon-no-objective"
                  title={getObjectiveLocale('There is nothing to compare')}
                  subtitle={getObjectiveLocale(
                    'Add the insight and object to be compared to use the analytics'
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </Scrollbar>
    </div>
  );
};

export default ObjectiveAnalyticsContent;

import React, { useState } from 'react';

import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getPeriod, getProjectRolesParams } from 'utils/ObjectivesHelper';
import { useFilterMenuObjectives } from 'utils/ObjectivesHelper';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import ObjectivesModal from 'components/objectives/ObjectivesModal';
import ModalCreateProject from 'components/project/ModalCreateProject/ModalCreateProject';
import ProjectCardView from 'components/project/card/ProjectCardView';
import ProjectListView from 'components/project/list/ProjectListView';

const MyProject = ({ route: { title } }) => {
  const { config } = useUser();
  const {
    defaultObjectiveSorting,
    timeFilterOptions: dateFilterOptions,
    permissions
  } = config;

  const filter = useFilter((state) => state.filterById?.projectFilter);
  const { switch: currentView } = filter || {};

  const [openModalCreateProject, setOpenModalCreateProject] = useState(false);

  const periodFilter = getPeriod(dateFilterOptions?.[0]);

  const defaultFilter = {
    group: 'phase',
    sortColumn: defaultObjectiveSorting?.sortColumn || 'due_date',
    sortDirection: defaultObjectiveSorting?.sortDirection || 'asc',
    assignTo: 'everyone',
    periodBegin: periodFilter?.periodBegin,
    periodEndBefore: periodFilter?.periodEndBefore,
    switch: 'card',
    myInvolvement: ['leader', 'reviewer', 'member', 'pmo', 'stakeholder'],
    phase: ['running', 'completed'],
    goalType: 'goal'
  };

  let filterMenu = useFilterMenuObjectives({
    config,
    except: ['follower', 'reviewer', 'status'],
    page: 'project'
  });

  const resultModifier = (filter) => {
    let assignee = filter?.owner?.assignee?.map(({ user }) => user?.name);
    let phase = filter?.phase?.map((status) =>
      status === 'running' ? 'On Going' : status
    );

    const whichStatus = {
      bad: getObjectiveLocale('At Risk'),
      warning: getObjectiveLocale('Left Behind'),
      good: getObjectiveLocale('On Track'),
      exceed_expectation: getObjectiveLocale('Exceeded'),
      not_updated: getObjectiveLocale('Not Updated')
    };

    let status = filter?.status?.map((progress) => whichStatus[progress]);

    let type = filter?.type?.map((type) => type.name);
    const result = {
      owner: [
        { directorate: filter?.owner?.directorate || [] },
        { department: filter?.owner?.department || [] },
        { division: filter?.owner?.division || [] },
        ...(assignee || []),
        filter?.owner?.ownerType
      ].filter(Boolean),
      phase: phase || [],
      overdue: filter?.overdue,
      status: status,
      reviewer: filter?.reviewer?.map(({ user }) => user?.name),
      follower: filter?.follower?.map(({ user }) => user?.name),
      alignment: filter.alignment || '',
      lastUpdated: [filter?.lastUpdated?.lastUpdatedType || []],
      label: filter?.label,
      type: type,
      myInvolvement: filter?.myInvolvement
    };
    return result;
  };

  const switchOptions = [
    {
      id: 'list',
      iconName: 'icon-format_list_bulleted',
      filter: { switch: 'list' },
      dataCy: 'switcher-list'
    },
    {
      id: 'card',
      iconName: 'icon-apps',
      filter: { switch: 'card' },
      dataCy: 'switcher-card'
    }
  ];

  const restructureFilter = (filter) => {
    let newFilter = { ...filter };
    const myInvolvement = newFilter?.myInvolvement || [];

    if (!newFilter?.phase?.length) {
      newFilter = { ...newFilter, phase: config.defaultFilterPhase };
    }

    if (myInvolvement?.length > 0) {
      newFilter = {
        ...newFilter,
        roles: getProjectRolesParams(myInvolvement)
      };
    }

    return newFilter;
  };

  return (
    <>
      <HeaderPage
        titlePage={title}
        primaryAction={
          permissions?.projectCreate && {
            title: getObjectiveLocale('Create New Project'),
            icon: 'icon-add',
            onClick: () => setOpenModalCreateProject(true),
            dataCy: 'add-new-project-button'
          }
        }
      />

      <div className="my-[24px]">
        <AdvancedFilter
          id="projectFilter"
          filterOptions={[
            'menuFilter',
            'search',
            'sort',
            'switcherIcon',
            'dateFilter'
          ]}
          switchOptions={switchOptions}
          filterMenu={filterMenu}
          defaultFilter={defaultFilter}
          resultModifier={resultModifier}
          restructureFilter={restructureFilter}
        />
      </div>

      {currentView === 'list' ? (
        <ProjectListView
          setOpenModalCreateProject={setOpenModalCreateProject}
          isAbleToCreate={permissions?.projectCreate}
        />
      ) : (
        <ProjectCardView
          setOpenModalCreateProject={setOpenModalCreateProject}
          isAbleToCreate={permissions?.projectCreate}
        />
      )}

      {openModalCreateProject && (
        <ModalCreateProject
          eventOnClickClose={() => setOpenModalCreateProject(false)}
        />
      )}

      <ObjectivesModal />
    </>
  );
};

export default MyProject;

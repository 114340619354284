import React, { useEffect, useRef, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalDeleteSection from 'components/admin/track-template/QuestionSections/ModalDeleteSection';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import Tooltips from 'components/shared/Tooltips/Tooltips';

const SectionName = ({
  templateData,
  setTemplateData,
  sectionName = '',
  sectionIndex = 0
}) => {
  const [name, setName] = useState('');
  const [defaultName, setDefaultName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [showModalDeleteSection, setShowModalDeleteSection] = useState(false);

  const inputRef = useRef();

  const updateSectionName = () => {
    let tempData = cloneDeep(templateData);
    tempData.sections[sectionIndex].name = name;
    setTemplateData(tempData);
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key == 'Enter') {
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    name != sectionName && setName(sectionName);
    // eslint-disable-next-line
  }, [sectionName]);

  useEffect(() => {
    setDefaultName(`Untitled Section ${sectionIndex + 1}`);
  }, [sectionIndex]);

  useEffect(() => {
    isEditing && inputRef.current.focus();
  }, [isEditing]);

  return (
    <>
      {sectionIndex > 0 && (
        <div className="w-full border-solid border-0 border-t-[1px] border-n-400 my-[40px]"></div>
      )}
      <div className={`flex items-center mb-[16px] group`}>
        {sectionName?.length === 0 || isEditing ? (
          <InputField
            ref={inputRef}
            value={name}
            placeholder={defaultName}
            className="w-[240px]"
            handleChange={(e) => setName(e?.target?.value)}
            handleKeyPress={handleKeyPress}
            onBlur={() => updateSectionName()}
            dataCy={`input-section-name-${sectionIndex}`}
          />
        ) : (
          <p
            className="typography-h600 text-n-900 cursor-pointer mb-[0px]"
            onClick={() => setIsEditing(true)}
          >
            {sectionName}
          </p>
        )}
        {templateData?.sections?.length > 1 && (
          <Tooltips
            text={getObjectiveLocale('Delete section')}
            isEllipsis
            tooltipClass="ml-[10px] mb-[4px] hidden group-hover:flex"
            addClass="h-[24px]"
          >
            <SVGIcon
              iconName="icon-delete"
              size="24"
              fillColor="var(--n-600)"
              customClass="ml-[16px] hidden group-hover:flex"
              onClick={() => setShowModalDeleteSection(true)}
            />
          </Tooltips>
        )}
      </div>
      {showModalDeleteSection && (
        <ModalDeleteSection
          templateData={templateData}
          setTemplateData={setTemplateData}
          sectionIndex={sectionIndex}
          setShowModal={setShowModalDeleteSection}
        />
      )}
    </>
  );
};

export default SectionName;

import React, { useEffect, useState } from 'react';

import ctl from '@netlify/classnames-template-literals';
import kebabCase from 'lodash/kebabCase';

import { useUser } from 'context/UserContext';
import { useGetListPreset } from 'hooks/api/useObjectivesComparative';
import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { useDeepEffect } from 'utils/useDeepEffect';

import Button from 'components/design-system/Button';
import Dropdown, {
  useDropdown
} from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import { getUserCreator } from './helper/objectiveAnalyticsHelper';

const TriggerPreset = ({ presetValue }) => {
  const { open } = useDropdown();

  const wrapperTriggerCN = ctl(`
  flex justify-between items-center gap-[8px]
  rounded-[4px] px-[16px] w-[240px] h-[32px] 
  bg-n-000 text-n-900 border border-solid border-n-400 
  hover:bg-base-30024 active:bg-base-30024
  `);

  return (
    <div className={wrapperTriggerCN}>
      <TooltipContainer
        show={!open && presetValue?.name}
        text={`Preset: ${presetValue?.name}`}
        position="bottom"
        classContainer="flex flex-row items-center min-w-[184px]"
      >
        <p className="typography-h400 text-n-900">
          {getObjectiveLocale('Preset:')}
        </p>
        &nbsp;
        <p className="typography-paragraph text-n-900 truncate">
          {getObjectiveLocale(presetValue?.name)}
        </p>
      </TooltipContainer>

      <SVGIcon
        size={24}
        fillColor="var(--n-600)"
        iconName="icon-arrow_drop_down"
      />
    </div>
  );
};

const Preset = ({ currentPreset, onChangePreset, presetData }) => {
  const { user } = useUser();

  const preset = useObjectiveComparative((state) => state.preset);
  const setPreset = useObjectiveComparative((state) => state.setPreset);
  const resetAllData = useObjectiveComparative((state) => state.resetAllData);
  const isDataChanged = useObjectiveComparative((state) => state.isDataChanged);

  const initialPreset = {
    id: 0,
    name: 'none',
    isShared: false,
    creator: getUserCreator(user)
  };
  const [listPreset, setListPreset] = useState([initialPreset]);

  let { data: analyticsPreset } = useGetListPreset();

  const onClickPreset = (preset) => {
    if (preset?.id === 0) {
      if (isDataChanged(presetData?.data)) {
        onChangePreset(preset);
      } else {
        resetAllData(getUserCreator(user));
        setPreset(preset);
      }
    } else {
      if (isDataChanged(presetData?.data)) {
        onChangePreset(preset);
      } else {
        setPreset(preset);
      }
    }
  };

  useEffect(() => {
    setListPreset(
      analyticsPreset?.data?.length > 0
        ? [initialPreset, ...analyticsPreset.data]
        : [initialPreset]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsPreset]);

  useDeepEffect(() => {
    setPreset(
      analyticsPreset?.data?.find((e) => e.id === currentPreset.id)
        ? currentPreset
        : initialPreset
    );
  }, [currentPreset]);

  return (
    <Dropdown>
      <Dropdown.Trigger dataCy="list-preset">
        <TriggerPreset presetValue={preset} />
      </Dropdown.Trigger>
      <Dropdown.MenuItems type="fixed" position="right" maxHeight={256}>
        {listPreset?.length > 0 &&
          listPreset.map((preset, index) => (
            <Dropdown.MenuItem
              key={index}
              customClass="w-[240px] text-left"
              onClick={() => onClickPreset(preset)}
              dataCy={`option-${kebabCase(preset?.name)}`}
            >
              {getObjectiveLocale(preset.name)}
            </Dropdown.MenuItem>
          ))}
      </Dropdown.MenuItems>
    </Dropdown>
  );
};

const SavePreset = ({ setOpenModal, disabled }) => (
  <Button
    onClick={() => setOpenModal('savePreset')}
    disabled={disabled}
    datacy="save-preset"
  >
    <SVGIcon
      size={24}
      fillColor={disabled ? 'var(--n-600)' : 'var(--n-100)'}
      iconName="icon-save"
    />
    <p className="typography-button ml-[4px]">
      {getObjectiveLocale('Save Preset')}
    </p>
  </Button>
);

const MoreDropdown = ({ disabled, setOpenModal }) => {
  const preset = useObjectiveComparative((state) => state.preset);
  const isViewGraph = useObjectiveComparative((state) => state.isViewGraph);
  const setIsViewGraph = useObjectiveComparative(
    (state) => state.setIsViewGraph
  );

  const wrapperTriggerCN = ctl(`
    flex justify-center items-center w-[32px] h-[32px] rounded-[4px] 
    text-n-900 ${
      disabled
        ? 'bg-n-300'
        : 'border bg-n-000 border-solid border-n-400 hover:bg-base-30024 active:bg-base-30024'
    } 
  `);

  const listMenu = [
    {
      text: getObjectiveLocale(
        isViewGraph ? 'View Progress Bar' : 'View Graph'
      ),
      iconName: isViewGraph ? 'icon-short_text' : 'icon-show_chart',
      onClick: () => setIsViewGraph(!isViewGraph),
      dataCy: isViewGraph ? 'view-progress-bar' : 'view-graph'
    },
    {
      text: getObjectiveLocale('Rename Preset'),
      iconName: 'icon-create',
      onClick: () => setOpenModal('renamePreset'),
      dataCy: 'rename-preset'
    },
    // Hold For Next Release BE
    // {
    //   text: getObjectiveLocale('Share Preset'),
    //   iconName: 'icon-share',
    //   onClick: () => setOpenModal('sharePreset'),
    //   dataCy: 'share-preset'
    // },
    {
      text: getObjectiveLocale('Delete Preset'),
      iconName: 'icon-delete',
      onClick: () => setOpenModal('deletePreset'),
      iconColor: 'var(--r-500)',
      textColor: 'text-r-500',
      dataCy: 'delete-preset'
    }
  ];

  const options = preset.id === 0 ? [listMenu[0]] : listMenu;

  return (
    <Dropdown>
      <Dropdown.Trigger dataCy="more-dropdown" disabled={disabled}>
        <div className={wrapperTriggerCN}>
          <SVGIcon
            size={24}
            fillColor="var(--n-600)"
            iconName="icon-keyboard_control"
          />
        </div>
      </Dropdown.Trigger>
      <Dropdown.MenuItems type="fixed" options={options} position="right" />
    </Dropdown>
  );
};

const HeaderActions = ({
  currentPreset,
  isDisabled,
  setOpenModal,
  presetData,
  onChangePreset
}) => {
  return (
    <div className="flex items center gap-[8px]">
      <Preset
        currentPreset={currentPreset}
        onChangePreset={onChangePreset}
        presetData={presetData}
      />
      <SavePreset setOpenModal={setOpenModal} disabled={isDisabled} />
      <MoreDropdown setOpenModal={setOpenModal} disabled={isDisabled} />
    </div>
  );
};

export default HeaderActions;

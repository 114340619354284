import React, { useEffect, useState } from 'react';

import { useAuth } from './AuthContext';

//CREATE CONTEXT
const ReloadContext = React.createContext();

//PROVIDER
function ReloadProvider(props) {
  const initialState = {
    reloadObjectives: false,
    reloadApprovalPanel: [],
    reloadUser: false,
    reloadObjectiveId: { id: 0 },
    reloadType: '',
    reloadOveralStatus: false,
    reloadOverallProgress: false,
    reloadSidebar: false,
    reloadActivityNotifCount: false,
    reloadNeedResponseNotifCount: false,
    reloadApprovalNotifCount: false,
    reloadFeeds: false,
    reloadGroups: false,
    reloadCommentCountPost: { postId: 0 },
    reloadLikeCountPost: { postId: 0, action: '' },
    reloadFeedsId: { id: 0 },
    reloadAdminCycles: false,
    reloadTeam: false,
    reloadCommentReply: { id: 0, replyData: {} },
    reloadHighlightPinned: false,
    reloadCurrentGroup: { id: 0 }
  };

  const [state, setState] = useState(initialState);
  const contextValue = [state, setState];

  return <ReloadContext.Provider value={contextValue} {...props} />;
}

//MUTATION / GETTER
function useReload() {
  const [state, setState] = React.useContext(ReloadContext);

  const reload = (data) => {
    setState((draft) => ({ ...draft, ...data }));
  };

  return {
    reload,
    reloadObjectives: state.reloadObjectives,
    reloadApprovalPanel: state.reloadApprovalPanel,
    reloadUser: state.reloadUser,
    reloadObjectiveId: state.reloadObjectiveId,
    reloadType: state.reloadType,
    reloadOveralStatus: state.reloadOveralStatus,
    reloadOverallProgress: state.reloadOverallProgress,
    reloadSidebar: state.reloadSidebar,
    reloadActivityNotifCount: state.reloadActivityNotifCount,
    reloadNeedResponseNotifCount: state.reloadNeedResponseNotifCount,
    reloadApprovalNotifCount: state.reloadApprovalNotifCount,
    reloadFeeds: state.reloadFeeds,
    reloadCommentCountPost: state.reloadCommentCountPost,
    reloadLikeCountPost: state.reloadLikeCountPost,
    reloadFeedsId: state.reloadFeedsId,
    reloadGroups: state.reloadGroups,
    reloadAdminCycles: state.reloadAdminCycles,
    reloadTeam: state.reloadTeam,
    reloadCommentReply: state.reloadCommentReply,
    reloadHighlightPinned: state.reloadHighlightPinned,
    reloadCurrentGroup: state.reloadCurrentGroup
  };
}

export { ReloadProvider, ReloadContext, useReload };

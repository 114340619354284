import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import union from 'lodash/union';

import {
  getMissionTemplate,
  getMissionbyId,
  postMission,
  updateMission
} from 'client/adminClient.js';
import { useUser } from 'context/UserContext';
import { UsersAdminProvider } from 'context/admin/UsersAdminContext';
import { setActiveNavigator } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import ContentNavigator from 'components/shared/ContentNavigator';
import Footer from 'components/shared/Footer';

import CreateQuestion from './CreateQuestion';
import MissionConfiguration from './MissionConfiguration';
import MissionDescription from './MissionDescription';
import MissionName from './MissionName';
import MissionPeriod from './MissionPeriod';
import MissionRecipients from './MissionRecipients';
import MissionTasks from './MissionTasks';
import SetRecipients from './SetRecipients';

function CreateMission({ route: { title, isReadOnly } }) {
  const history = useHistory();
  const { config } = useUser();
  const [page, setPage] = useState('createMission');
  const [indexQuestion, setIndex] = useState(null);
  const params = useParams();
  const [haveSubmitted, setHaveSubmitted] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [isEditMission, setIsEditMission] = useState(false);

  let newTask = {
    name: null,
    repetition: 1,
    targetId: null,
    point: null,
    missionTaskTemplateId: 1,
    description: '',
    orderLevel: null,
    idDrag: 0,
    conditions: {
      labelId: null,
      hashtag: '',
      groupId: '',
      minCharacterRequired: 0
    }
  };

  const [mission, setMission] = useState({
    name: null,
    description: null,
    startsAt: dayjs().startOf('day').toISOString(),
    endsAt: dayjs().add(1, 'day').endOf('day').toISOString(),
    missionTasks: [newTask],
    selectAll: false,
    includes: [],
    excludes: [],
    isEvent: false,
    isDefault: true,
    isSequential: false
  });

  const [selectAll, setSelectAll] = useState(mission?.selectAll);
  const [includes, setIncludesRecipients] = useState(mission?.includes);
  const [excludes, setExcludesRecipients] = useState(mission?.excludes);
  const [activeSection, setActiveSection] = useState('name-section');

  let listNavigation = [
    { id: 'name-section', name: 'Name' },
    { id: 'description-section', name: 'Description' },
    { id: 'recipients-section', name: 'Recipients' },
    { id: 'configuration-section', name: 'Configuration' },
    { id: 'task-section', name: 'Task' },
    { id: 'period-section', name: 'Period' }
  ];

  const clearSelection = () => {
    setSelectAll(false);
    setMission({
      ...mission,
      includes: [],
      excludes: [],
      selectAll: false
    });
  };

  const changeTargetId = (total, data) => {
    let editedMission = JSON.parse(JSON.stringify(mission));

    if (data !== undefined) {
      editedMission.missionTasks[indexQuestion]['targetId'] = data;
    }
    editedMission.missionTasks[indexQuestion]['totalQuestion'] = total;
    setMission(editedMission);
  };

  const restructureData = (type) => {
    let body = JSON.parse(JSON.stringify(mission));
    if (body.missionTasks.length == 0) {
      delete body.missionTasks;
    }

    body.missionTasks.map((data, index) => {
      if (data.totalQuestion) {
        delete data.totalQuestion;
      }
      body.missionTasks[index].orderLevel = index + 1;

      const notCreate =
        templateData?.find((item) => item.id == data.missionTaskTemplateId)
          ?.eventType !== 'post.create';
      const notRecognition =
        templateData?.find((item) => item.id == data.missionTaskTemplateId)
          ?.eventType !== 'post.recognition';

      if (
        templateData?.find((item) => item.id == data.missionTaskTemplateId)
          ?.eventType === 'post.comment'
      ) {
        const minCharacterRequiredA =
          body?.missionTasks[index]?.conditions?.minCharacterRequired;
        body.missionTasks[index].conditions = {
          minCharacterRequired: minCharacterRequiredA
        };
      } else if (notCreate && notRecognition) {
        body.missionTasks[index].conditions = {};
      }

      if (body.missionTasks[index].conditions?.groupId == -2) {
        delete body.missionTasks[index].conditions?.groupId;
      }

      if (body.missionTasks[index].conditions?.valueId == -2) {
        delete body.missionTasks[index].conditions?.valueId;
      }

      if (body.missionTasks[index].conditions?.hashtag == '') {
        delete body.missionTasks[index].conditions?.hashtag;
      }

      if (!data?.conditions?.labelId) {
        delete data?.conditions?.labelId;
      }
    });

    if (type === 'create') {
      body.missionTasks?.forEach((data, index) => {
        delete data.id;
      });
    } else if (type === 'update') {
      if (body.memberIds) {
        delete body.memberIds;
      }
    }

    return body;
  };

  const onConfirmSubmitMission = async () => {
    setHaveSubmitted(true);
    let requestBody = restructureData(params.id ? 'update' : 'create');
    const { data } = params.id
      ? await updateMission(params.id, requestBody)
      : await postMission(requestBody);
    if (data) {
      history.push({ pathname: '/missions' });
    }
  };

  const getDataById = async (id) => {
    const { data } = await getMissionbyId(id);
    if (data) {
      let newTasks = cloneDeep(data?.missionTasks);
      newTasks.sort(function (a, b) {
        return a.orderLevel - b.orderLevel;
      });
      newTasks?.forEach((data, index) => {
        data.idDrag = index;
      });
      setMission({
        ...data,
        includes: data.memberIds,
        excludes: [],
        selectAll: false,
        missionTasks: newTasks
      });
      setIncludesRecipients(data.memberIds);
      setIsEditMission(true);
    }
  };

  const saveRecipients = () => {
    setIncludesRecipients(mission.includes);
    setExcludesRecipients(mission.excludes);
    setSelectAll(mission.selectAll);
    setPage('createMission');
  };

  const backToCreateMission = () => {
    setMission((old) => ({
      ...old,
      includes: includes,
      excludes: excludes,
      selectAll: selectAll
    }));
    setPage('createMission');
  };

  const fetchMissionTemplate = async () => {
    const { data } = await getMissionTemplate();
    if (data) {
      setTemplateData(data);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getDataById(params?.id);
    }
    fetchMissionTemplate();
  }, []);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    let newSelected = JSON.parse(JSON.stringify(mission));
    newSelected.excludes = [];
    newSelected.includes = [];
    newSelected.selectAll = !selectAll;
    setMission(newSelected);
  };

  const selectRecipients = (e, value) => {
    let checked = e.currentTarget.checked;
    let newSelected = JSON.parse(JSON.stringify(mission));

    if (selectAll) {
      if (!checked) {
        newSelected['excludes'].push(value);
      } else {
        let removedValue = value;
        newSelected['excludes'] = newSelected['excludes'].filter(
          (value) => value !== removedValue
        );
      }
    } else {
      if (checked) {
        newSelected['includes'].push(value);
      } else {
        let removedValue = value;
        newSelected['includes'] = newSelected['includes'].filter(
          (value) => value !== removedValue
        );
      }
    }
    setMission(newSelected);
  };

  const selectBulkRecipients = (recipients, checked) => {
    let newSelected = cloneDeep(mission);
    if (checked) {
      newSelected['includes'] = union(newSelected['includes'], recipients);
    } else {
      newSelected['includes'] = newSelected['includes'].filter(function (
        value
      ) {
        return this.indexOf(value) < 0;
      },
      recipients);
    }
    setMission(newSelected);
  };

  return (
    <>
      <HeaderPage
        titlePage={title}
        backToUrl={'/missions'}
        isHeaderComposer={true}
      />
      {page == 'createMission' && (
        <ContentNavigator
          title="CONTENTS"
          activeSection={activeSection}
          listNavigatorContents={listNavigation}
          sectionContent="scrollable-content"
          customClassContainer="mission-content-navigator fixed right-0 mr-[40px] mt-[24px] z-index-10"
        />
      )}
      <div
        id="scrollable-content"
        className="relative overflow-auto bg-n-000 flex justify-center min-h-[calc(100vh-64px)]"
        onScroll={(e) =>
          setActiveNavigator(e, listNavigation, setActiveSection)
        }
      >
        <div className={page == 'setRecipients' ? 'w-full' : 'w-[720px]'}>
          <div
            className="create-organization bg-n-000 mt-[24px]"
            data-cy="modal-success"
          >
            {page == 'setRecipients' ? (
              <UsersAdminProvider>
                <SetRecipients
                  selectRecipients={selectRecipients}
                  includes={mission.includes}
                  excludes={mission.excludes}
                  selectBulkRecipients={selectBulkRecipients}
                  selectAll={selectAll}
                  setSelectAll={handleSelectAll}
                  isReadOnly={isReadOnly}
                  clearSelection={clearSelection}
                />
              </UsersAdminProvider>
            ) : page == 'createMission' ? (
              <div className="h-[calc(100%-137px)]">
                <MissionName
                  mission={mission}
                  setMission={setMission}
                  isReadOnly={isReadOnly}
                  haveSubmitted={haveSubmitted}
                />
                <MissionDescription
                  mission={mission}
                  setMission={setMission}
                  isReadOnly={isReadOnly}
                />
                <MissionRecipients
                  mission={mission}
                  setPage={setPage}
                  haveSubmitted={haveSubmitted}
                />
                <MissionConfiguration
                  config={config}
                  mission={mission}
                  setMission={setMission}
                  isEditMission={isEditMission}
                />
                <MissionTasks
                  mission={mission}
                  setMission={setMission}
                  newTask={newTask}
                  setIndex={setIndex}
                  setPage={setPage}
                  isReadOnly={isReadOnly}
                  templateData={templateData}
                  haveSubmitted={haveSubmitted}
                  setHaveSubmitted={setHaveSubmitted}
                />
                <MissionPeriod
                  mission={mission}
                  setMission={setMission}
                  isReadOnly={isReadOnly}
                />
              </div>
            ) : (
              page == 'createQuestion' && (
                <CreateQuestion
                  backToCreateMission={backToCreateMission}
                  changeTargetId={changeTargetId}
                  mission={mission}
                  indexQuestion={indexQuestion}
                />
              )
            )}
          </div>
        </div>
      </div>
      {page !== 'createQuestion' && (
        <Footer
          secondaryButton={true}
          linkButtonName={'Back'}
          datacy={'btn-submit'}
          save={true}
          handleTesting="btn-submit"
          handleClick={
            page == 'setRecipients'
              ? () => saveRecipients()
              : page == 'createMission'
              ? () => onConfirmSubmitMission()
              : ''
          }
          handleRoute={
            page == 'setRecipients'
              ? () => backToCreateMission()
              : page == 'createMission'
              ? () => history.push({ pathname: '/missions' })
              : ''
          }
          style={{ display: 'flex', justifyContent: 'center' }}
          customClass={page == 'setRecipients' ? 'w-full' : 'w-[720px]'}
          customButtonContainer="pr-[0px] py-[14px]"
        />
      )}
    </>
  );
}

export default withRouter(CreateMission);

import React, { useRef } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import S3Client from 'client/S3Client';
import { getPerformancePreSignUrl } from 'client/UploadClient';
import { useUser } from 'context/UserContext';
import useUserManagement from 'hooks/useUserManagement';
import { capitalize, getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import BannerBox from 'components/shared/BannerBox';
import Checkbox from 'components/shared/Checkbox';
import InputField from 'components/shared/InputField';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const InformationDetail = ({ setData, data, isReadOnly }) => {
  const { appType, user, config } = useUser();
  const profilePictureRef = useRef();
  const isError = useUserManagement((state) => state.isError);
  const setIsError = useUserManagement((state) => state.setIsError);

  const changeInfoData = (e, selector) => {
    if (selector === 'name' || selector === 'email') {
      e?.target?.value?.length > 0 ? setIsError(false) : setIsError(true);
    }

    const currentData = cloneDeep(data);
    currentData[selector] = e?.target?.value;
    setData(currentData);
  };

  const listOfRole = [
    {
      id: 'company',
      name: getObjectiveLocale('Set as Company Goal Creator'),
      selector: 'isCompanyGoalCreator'
    },
    {
      id: 'directorate',
      name: getObjectiveLocale('Set as Directorate Goal Creator'),
      selector: 'isDirectorateGoalCreator'
    },
    {
      id: 'department',
      name: getObjectiveLocale('Set as Department Goal Creator'),
      selector: 'isDepartmentGoalCreator'
    },
    {
      id: 'division',
      name: getObjectiveLocale('Set as Division Goal Creator'),
      selector: 'isDivisionGoalCreator'
    }
  ];

  const _getPreSignUrl = async (extensions) => {
    const query = {
      directory: 'profiles',
      extension: extensions,
      public: true
    };
    const { data } = await getPerformancePreSignUrl(query);
    return data;
  };

  const handleChangeProfilePicture = async (e) => {
    let file = e?.target?.files?.[0];

    if (file) {
      if (
        file.type == 'image/png' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/jpg'
      ) {
        const extensions = file.name.split('.').pop();
        let presignData = await _getPreSignUrl(extensions);
        if (presignData) {
          const { status } = await S3Client(presignData.uploadUrl, file);

          if (status == 200) {
            setData({
              ...data,
              profilePicture: presignData.downloadUrl
            });
            document.getElementById('profile').value = '';
          }
        } else {
          alert('Presign Link Not Found!');
        }
      } else {
        alert('Wrong file type! file type must be png, jpg or jpeg!');
        return false;
      }
    } else {
      alert('Empty file image');
    }
  };

  const changeTogglePerformance = () => {
    const currentData = cloneDeep(data);
    currentData.role =
      data?.role === 'user' || data?.role === 'admin' ? null : 'user';
    currentData.isCompanyGoalCreator = false;
    currentData.isDirectorateGoalCreator = false;
    currentData.isDepartmentGoalCreator = false;
    currentData.isDivisionGoalCreator = false;
    setData(currentData);
  };

  return (
    <div className="flex" style={{ height: window.innerHeight - 150 }}>
      <div className="w-[798px] ml-[16px]">
        {/* GENERAL */}
        <div id="general-section" data-cy="general-section">
          <div className="typography-h500 mb-[24px]">
            {getObjectiveLocale('General')}
          </div>
          <div className="flex items-center">
            <div className="mr-[32px]">
              <label htmlFor="profile" className="text-center cursor-pointer">
                <Avatar
                  name={data?.name}
                  src={data?.profilePicture}
                  size={120}
                  onClick={(e) => {
                    e?.stopPropagation();
                    profilePictureRef?.current?.click();
                  }}
                />
              </label>
              <input
                id="profile"
                type="file"
                className="hidden"
                ref={profilePictureRef}
                accept="image/jpg,image/png,image/jpeg"
                onChange={(e) => handleChangeProfilePicture(e)}
                disabled={isReadOnly}
              />
            </div>
            <div className="w-[616px] mt-[16px]">
              {config?.editableUserFields?.name && (
                <>
                  <InputField
                    label="Name"
                    placeholder="Name"
                    handleChange={(e) => changeInfoData(e, 'name')}
                    value={data?.name}
                    dataCy="user-name"
                    readonly={
                      isReadOnly ||
                      config?.editableUserFields?.name === 'disabled'
                    }
                  />
                  {isError && !data?.name && (
                    <div className="typography-h100 text-r-500 mt-[8px]">
                      {getObjectiveLocale('Name field must be filled')}
                    </div>
                  )}
                </>
              )}
              <div className="flex mt-[16px]">
                {config?.editableUserFields?.email && (
                  <div className="flex flex-col">
                    <InputField
                      label="Email"
                      placeholder="Email"
                      handleChange={(e) => changeInfoData(e, 'email')}
                      value={data?.email}
                      dataCy="user-email"
                      className="mr-[24px] w-[352px]"
                      readonly={
                        isReadOnly ||
                        config?.editableUserFields?.email === 'disabled'
                      }
                      autoComplete="new-email"
                    />
                    {isError && !data?.email && (
                      <div className="typography-h100 text-r-500 mt-[8px]">
                        {getObjectiveLocale('Email field must be filled')}
                      </div>
                    )}
                  </div>
                )}
                {config?.editableUserFields?.password && (
                  <InputField
                    label="Password"
                    password={true}
                    placeholder="Password"
                    handleChange={(e) => changeInfoData(e, 'password')}
                    value={data?.password}
                    dataCy="user-password"
                    className="w-[240px]"
                    readonly={
                      isReadOnly ||
                      config?.editableUserFields?.password === 'disabled'
                    }
                    autoComplete="new-password"
                  />
                )}
              </div>
              <div className="flex">
                {config?.editableUserFields?.userDomain && (
                  <InputField
                    label="User Domain"
                    placeholder="User Domain"
                    handleChange={(e) => changeInfoData(e, 'trueEmail')}
                    value={data?.trueEmail}
                    dataCy="user-domain"
                    className="mr-[24px] w-[352px] mt-[16px]"
                    readonly={
                      isReadOnly ||
                      config?.editableUserFields?.userDomain === 'disabled'
                    }
                  />
                )}
                {config?.editableUserFields?.externalId && (
                  <InputField
                    label="NIP"
                    placeholder="NIP"
                    handleChange={(e) => changeInfoData(e, 'externalId')}
                    value={data?.externalId}
                    dataCy="user-nip"
                    className={`${
                      isReadOnly ? 'w-[240px]' : 'mr-[24px] w-[352px]'
                    } mt-[16px]`}
                    readonly={
                      isReadOnly ||
                      config?.editableUserFields?.externalId === 'disabled'
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[1px] my-[40px] bg-n-400" />
        {/* ROLE */}
        <div id="role-section" data-cy="role-section" className="pb-[120px]">
          <div className="typography-h500">
            {getObjectiveLocale('Platorm & Role')}
          </div>
          {isError && !data?.role && !data?.cultureRole && (
            <div className="typography-h100 text-r-500 mb-[24px] mt-[8px]">
              {getObjectiveLocale('Minimum 1 active platform and role')}
            </div>
          )}
          <div className="border-solid border border-n-400 rounded-[4px] mt-[16px] flex flex-col bg-n-000">
            <div className="flex flex-col">
              <div className="flex justify-between items-center px-[16px] pt-[16px]">
                <div>
                  <div
                    className={`typography-h400 ${
                      isReadOnly ? 'text-n-700' : ''
                    } `}
                  >
                    {getObjectiveLocale('Enable access to Performance')}
                  </div>
                  <div
                    className={`typography-paragraph ${
                      isReadOnly ? 'text-n-600' : 'text-n-800'
                    } `}
                  >
                    {getObjectiveLocale(
                      'If this configuration is being enabled, this user will have access to Performance app.'
                    )}
                  </div>
                </div>
                <SmallToggleSwitchPurple
                  idStr="toggle-access-perf"
                  isChecked={data?.role ? true : false}
                  onChange={() => changeTogglePerformance()}
                  disabled={
                    isReadOnly ||
                    !config.editableUserInformation?.platformAndRole
                  }
                />
              </div>
              {data?.role &&
                !isReadOnly &&
                !config.editableUserInformation?.platformAndRole && (
                  <BannerBox
                    type="info"
                    customClass="mx-[16px] mt-[16px] rounded-[8px] mb-0"
                    text={getObjectiveLocale(
                      'You can still change the configurations below'
                    )}
                    paddingClass="px-[16px] py-[8px]"
                  />
                )}
            </div>
            <div className="my-[16px]">
              {data?.role &&
                appType !== 'culture' &&
                user?.role &&
                listOfRole.map((role, index) => {
                  const isChecked =
                    role.selector === 'role'
                      ? data?.[role.selector] === 'admin'
                      : data?.[role.selector];

                  const changeCheckbox = () => {
                    let currentData = !data?.[role.selector];
                    if (role.selector === 'role') {
                      currentData =
                        data?.[role.selector] === 'admin' ? 'user' : 'admin';
                    }
                    setData({ ...data, [role.selector]: currentData });
                  };

                  const isDisabled =
                    role?.id !== 'company' &&
                    !data[`has${capitalize(role.id)}`];

                  const tooltipText = `Can’t set this user as a ${role?.id} goal creator if the user does not have a ${role?.id}.`;

                  return (
                    <Checkbox
                      name={role.id}
                      id={role.id}
                      checked={isChecked}
                      onChange={() => changeCheckbox()}
                      dataCy={`select-${role.id}`}
                      customContainerClass={`px-[16px] ${
                        !isReadOnly && isDisabled ? 'bg-n-200' : ''
                      }`}
                      disabled={isReadOnly || isDisabled}
                      key={index}
                    >
                      <TooltipContainer
                        key={index}
                        show={isDisabled}
                        text={tooltipText}
                        useMaxWidth={false}
                        align="left"
                      >
                        <p
                          className={`ml-[8px] typography-button ${
                            isReadOnly || isDisabled
                              ? 'text-n-700'
                              : 'text-n-900'
                          }`}
                        >
                          {role?.name}
                        </p>
                      </TooltipContainer>
                    </Checkbox>
                  );
                })}
            </div>
          </div>
          <div className="border-solid border border-n-400 rounded-[4px] p-16 flex flex-col mt-[16px] bg-n-000">
            <div className="flex justify-between items-center">
              <div>
                <div className="typography-h400">
                  {getObjectiveLocale('Enable access to Culture')}
                </div>
                <div className="typography-paragraph text-n-800">
                  {getObjectiveLocale(
                    'If this configuration is being enabled, this user will have access to Culture app.'
                  )}
                </div>
              </div>
              <SmallToggleSwitchPurple
                idStr="toggle-access-cult"
                isChecked={data?.cultureRole ? true : false}
                onChange={() =>
                  setData({
                    ...data,
                    cultureRole:
                      data?.cultureRole === 'culture_user' ||
                      data?.cultureRole === 'culture_admin'
                        ? null
                        : 'culture_user'
                  })
                }
                disabled={
                  isReadOnly || !config.editableUserInformation?.platformAndRole
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationDetail;

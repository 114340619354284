import React from 'react';

import ctl from '@netlify/classnames-template-literals';

import Shimmer from 'components/design-system/shimmer/Shimmer';

function BoardCardSkeleton({ useShimmer = true, customClass = '' }) {
  const cardCN = ctl(`
    rounded-[4px]
    shadow-overlay
    bg-n-000
    w-full
    min-w-[328px]
    p-[12px]
    mb-[8px]
    ${customClass}
  `);

  return (
    <div className={cardCN}>
      {useShimmer ? (
        <div className="flex flex-col">
          <Shimmer height="16px" customClass="rounded-[4px] mb-[12px]" />
          <Shimmer height="16px" customClass="rounded-[4px] mb-[12px]" />
          <div className="flex mb-[12px]">
            <Shimmer height="20px" width="60px" customClass="rounded-[4px]" />
            <Shimmer
              height="20px"
              width="60px"
              customClass="rounded-[4px] ml-[8px]"
            />
            <Shimmer
              height="20px"
              width="60px"
              customClass="rounded-[4px] ml-[8px]"
            />
          </div>
          <div className="flex justify-between items-center">
            <Shimmer height="24px" width="120px" customClass="rounded-[4px]" />
            <Shimmer height="12px" width="60px" customClass="rounded-[4px]" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="h-[16px] bg-n-400 rounded-[4px] mb-[12px]" />
          <div className="h-[16px] bg-n-400 rounded-[4px] mb-[12px]" />
          <div className="flex mb-[12px]">
            <div className="h-[20px] w-[60px] bg-n-400 rounded-[4px]" />
            <div className="h-[20px] w-[60px] bg-n-400 rounded-[4px] ml-[8px]" />
            <div className="h-[20px] w-[60px] bg-n-400 rounded-[4px] ml-[8px]" />
          </div>
          <div className="flex justify-between items-center">
            <div className="h-[24px] w-[120px] bg-n-400 rounded-[4px]" />
            <div className="h-[12px] w-[60px] bg-n-400 rounded-[4px]" />
          </div>
        </div>
      )}
    </div>
  );
}

export default BoardCardSkeleton;

import React, { useEffect, useState } from 'react';

import { getListReviewGroup } from 'client/admin/ReviewGroupClient.js';
import { UsersAdminProvider } from 'context/admin/UsersAdminContext';
import {
  getNumberLocale,
  getObjectiveLocale,
  loadMoreValidator
} from 'utils/HelperUtils';

import ModalShowMember from 'components/admin/groups/ModalShowMember.js';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

import VerifySection from './VerifySection';

function SummaryVerify({ cycleData }) {
  const [modalType, setModalType] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [GroupPagination, setGroupPagination] = useState({});

  let groupIdsLength = cycleData?.groupIds?.length;
  let includesLength = cycleData?.includes?.length;
  let isEmptyData = groupIdsLength === 0 && includesLength === 0;

  let summaryArray = [
    {
      name: 'Cycle name',
      content: <p className="typography-paragraph">{cycleData.name}</p>
    },
    {
      name: 'Reviewees',
      content: (
        <p
          className={`typography-paragraph ${
            isEmptyData ? 'typography-tertiary' : 'cursor-pointer text-base-600'
          }`}
          onClick={() => {
            isEmptyData
              ? null
              : groupIdsLength > 0
              ? setModalType('group')
              : setModalType('employee');
          }}
        >
          {isEmptyData
            ? '--'
            : groupIdsLength > 0
            ? `${getNumberLocale(groupIdsLength)} ${
                groupIdsLength === 1 ? 'group' : 'groups'
              }`
            : `${getNumberLocale(includesLength)} employees`}
        </p>
      )
    },
    {
      name: 'Calibration',
      listClassName: 'h-[56px]',
      content: (
        <div>
          {cycleData.calibration === null && (
            <p className="typography-paragraph typography-tertiary">
              {getObjectiveLocale('Not using calibration')}
            </p>
          )}
          {cycleData.calibration?.type === 'manager' && (
            <p className="typography-paragraph">
              {getObjectiveLocale(
                'All manager who have subordinates may calibrate'
              )}
            </p>
          )}
          {cycleData.calibration?.type === 'level' && (
            <>
              <p className="typography-paragraph">
                {getObjectiveLocale(
                  'Only certain levels in the organization may calibrate their subordinates'
                )}
              </p>
              <p className="typography-h100 typography-secondary">
                Level {cycleData.calibration?.maxManagerLevel}
              </p>
            </>
          )}
        </div>
      )
    }
  ];

  const getListOfGroup = async () => {
    let params = {
      groupType: 'review_group',
      ids: cycleData?.groupIds
    };
    const { data, pagination } = await getListReviewGroup(params);
    if (data) {
      setGroupList(data);
      setGroupPagination(pagination);
    }
  };

  const onScroll = (e) => {
    const target = e.target;
    const loadMore = async () => {
      await appendGroupData();
    };

    if (GroupPagination.nextOffset) {
      loadMoreValidator(target, 200, () => {
        loadMore();
      });
    }
  };

  const appendGroupData = async () => {
    const query = {
      group_type: 'review_group',
      offset: 10,
      ids: cycleData?.groupIds
    };
    const { data, pagination } = await getListReviewGroup(query);
    if (data) {
      let newGroupList = [...groupList, ...data];
      setGroupList(newGroupList);
      setGroupPagination(pagination);
    }
  };

  useEffect(() => {
    if (modalType === 'group') {
      getListOfGroup();
    } else if (modalType === 'phase') {
      getListOfTrackConfigs();
    }
  }, [modalType]);

  return (
    <>
      <VerifySection
        title="Summary"
        dataArray={summaryArray}
        className="pb-[40px]"
      />
      {modalType === 'group' && (
        <Modal
          className="w-[400px]"
          title={'Reviewees'}
          withCloseIcon={true}
          eventOnClickClose={() => setModalType('')}
          withFooter={false}
        >
          <div
            className="max-h-[530px] overflow-y-auto"
            onScroll={(e) => onScroll(e)}
          >
            {groupList.map((group) => {
              return (
                <a
                  href={`/group/performance-profile/edit/${group.id}`}
                  target="_blank"
                  key={group.id}
                  className="group-list-card flex justify-between flex items-center px-[16px] py-[8px] -ml-[8px] hover-list cursor-pointer"
                >
                  <div className="flex">
                    <div className="w-[32px] h-[32px] bg-n-300 rounded-[50px] flex-center-center mr-[16px]">
                      <SVGIcon
                        size="16"
                        iconName="icon-group"
                        fillColor="var(--n-600)"
                      />
                    </div>
                    <div className="flex flex-col">
                      <h4 className="typography-h400 mb-[0px]">{group.name}</h4>
                      <p className="typography-paragraph typography-secondary">
                        {getNumberLocale(group.memberCount)}{' '}
                        {getObjectiveLocale('members')}
                      </p>
                    </div>
                  </div>
                  <span className="typography-h200 typography-secondary">
                    ID {group.id}
                  </span>
                </a>
              );
            })}
          </div>
        </Modal>
      )}
      {modalType === 'employee' && (
        <UsersAdminProvider>
          <ModalShowMember
            title="Reviewees"
            setShowModalMembers={setModalType}
            listIdUser={cycleData?.includes}
            excludedId={cycleData?.excludes}
            isSelectAll={cycleData?.selectAll}
          />
        </UsersAdminProvider>
      )}
    </>
  );
}

export default SummaryVerify;

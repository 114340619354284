import isEmpty from 'lodash/isEmpty';

const isFilterEmpty = (filter) => {
  return (
    filter &&
    Object.keys(filter)
      ?.map((key) => {
        if (!isEmpty(filter[key])) return filter[key];
      })
      ?.filter((e) => e)?.length === 0
  );
};

export { isFilterEmpty };

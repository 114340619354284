import React, { Fragment, useEffect, useState } from 'react';

import { getBehaviors } from 'client/adminClient.js';
import { useUser } from 'context/UserContext';
import { capitalizeSentence, getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import TextAreaWithMention from 'components/shared/TextAreaWithMention/TextAreaWithMention';

function FormDevelopmentPlan({
  id,
  objModel,
  scrollToElement,
  objType,
  needComment,
  setAnswer,
  comment,
  deleteCompetency,
  behaviorType
}) {
  const [object, setObject] = useState(null);
  const {
    config: { formalReviewMentionInCommentEnabled }
  } = useUser();

  const params = {
    state: 'active',
    behavior_type: behaviorType,
    ids: id
  };

  useEffect(() => {
    const fetchBehavior = async () => {
      const { data } = await getBehaviors(params);
      // should be setObject
      setObject(data[0]);
      return;
    };

    if (objModel) {
      setObject(objModel);
    } else {
      fetchBehavior();
    }
  }, [objModel]);

  return object ? (
    <Fragment key={object.id}>
      <div
        className="title-subtitle-competency-container"
        ref={scrollToElement}
      >
        <div className="title-trash-container">
          <h5 className="competency-title">
            {object.name || capitalizeSentence(object.title)}
          </h5>
          <SVGIcon
            iconName="icon-delete_grey"
            size={16}
            customClass="custom-trashbin"
            onClick={() => deleteCompetency(object.id, objType)}
          />
        </div>
        <p className="competency-subtitle">{object.description}</p>
      </div>
      <p className="comment-competency-text">
        {getObjectiveLocale(
          `Development Plan (${getObjectiveLocale(needComment || 'optional')})`
        )}
      </p>
      <div className="textarea-competency">
        <TextAreaWithMention
          setComment={(value) => setAnswer(id, null, value)}
          defaultValue={comment}
          customClass="h-[98px]"
          ableToFocusGrow={false}
          mentionComponent={(mentionProps) => {
            return <span>{mentionProps.children}</span>;
          }}
          mentionEnabled={formalReviewMentionInCommentEnabled}
          placeholder={''}
        />
      </div>
    </Fragment>
  ) : (
    <></>
  );
}

export default FormDevelopmentPlan;

import React, { useState } from 'react';

import isEqual from 'lodash/isEqual';

import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalV2 from 'components/design-system/Modal';
import Tabs from 'components/design-system/tabs/Tabs';

import ModalPeerHistoryTab from './ModalPeerHistoryTab';
import ModalPeerRevieweeTab from './ModalPeerRevieweeTab';
import ModalPeerReviewerTab from './ModalPeerReviewerTab';

export default function ModalPeerSelection({
  selectedUsers,
  onConfirmSelectUsers,
  target,
  onHandleCloseModal,
  minimumApprovalCount,
  approvalCount,
  statePhase,
  cycleId,
  isSelf,
  allowLockedByManager,
  actor,
  maximumPeerCountEnabled,
  maximumPeerSelectedEnabled,
  isPeerReviewDraft,
  nominationCount
}) {
  const [currentSelectedUsers, setCurrentSelectedUsers] =
    useState(selectedUsers);

  const isReadOnly = isSelf && nominationCount == 0;

  const [currentTab, setCurrentTab] = useState('reviewer-tab');

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const onRemoveUser = (user) => {
    const newSelectedUsers = currentSelectedUsers.filter((prevSelected) => {
      return prevSelected.id != user.id;
    });

    setCurrentSelectedUsers(newSelectedUsers);
  };

  const onLockUser = (user, isLocked) => {
    setCurrentSelectedUsers((draft) => {
      const currentUsers = draft.slice();
      const result = currentUsers.map((data) => {
        const currentData = {
          id: data.id,
          image: data.image,
          jobTitle: data.jobTitle,
          title: data.title,
          subtitle: data.subtitle,
          name: data.name,
          isMandatory: data.isMandatory,
          selectorType: data.selectorType,
          selectorId: data.selectorId,
          targetIdIndicator: data.targetIdIndicator,
          source: data.source
        };
        if (data.id == user.id) {
          currentData.isMandatory = isLocked;
        }
        return currentData;
      });
      return result;
    });
  };

  const sortedSelectedUsers = selectedUsers.sort((a, b) => a.id - b.id);
  const sortedCurrentSelectedUsers = currentSelectedUsers.sort(
    (a, b) => a.id - b.id
  );

  let isEqualSelectedUsers = isEqual(
    sortedSelectedUsers.map((user) => user.id),
    sortedCurrentSelectedUsers.map((user) => user.id)
  );

  const isEqualLockedUser = isEqual(
    sortedSelectedUsers.map((user) => user.isMandatory),
    sortedCurrentSelectedUsers.map((user) => user.isMandatory)
  );

  const maximumApprovalCount = approvalCount !== 0 ? approvalCount : Infinity;

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    await onConfirmSelectUsers(currentSelectedUsers);
    setIsSubmitLoading(false);
  };

  const listTabs = [
    {
      id: 'reviewer-tab',
      name: 'Reviewer'
    },
    {
      id: 'reviewee-tab',
      name: 'Reviewee'
    },
    {
      id: 'history-tab',
      name: 'History'
    }
  ];

  return (
    <ModalV2
      customClass="cursor-default w-[600px] min-h-[275px]"
      onClose={onHandleCloseModal}
    >
      <ModalV2.Header customClass="px-[24px] mt-[24px]">
        <p className="typography-h600 text-n-900">
          {getObjectiveLocale('Peer for') + ' ' + target.name}
        </p>
      </ModalV2.Header>
      <ModalV2.Body>
        <div className="flex flex-col w-full cursor-default">
          <Tabs currentTab={currentTab} type="small">
            <Tabs.Buttons fullWidth customClass="mt-[24px]">
              <div className="px-[24px] flex flex-row">
                {listTabs.map((tab) => (
                  <Tabs.Button
                    key={tab.id}
                    id={tab.id}
                    onClick={() => setCurrentTab(tab.id)}
                  >
                    <p>{tab.name}</p>
                  </Tabs.Button>
                ))}
              </div>
            </Tabs.Buttons>
            <Tabs.Contents customWrapperClass="overflow-auto max-h-[360px] min-h-[192px]">
              <ModalPeerReviewerTab
                currentSelectedUsers={currentSelectedUsers}
                minimumApprovalCount={minimumApprovalCount}
                selectedUsers={selectedUsers}
                statePhase={statePhase}
                maximumApprovalCount={maximumApprovalCount}
                approvalCount={approvalCount}
                target={target}
                cycleId={cycleId}
                isSelf={isSelf}
                onRemoveUser={onRemoveUser}
                onLockUser={onLockUser}
                setCurrentSelectedUsers={setCurrentSelectedUsers}
                allowLockedByManager={allowLockedByManager}
                actor={actor}
                maximumPeerCountEnabled={maximumPeerCountEnabled}
                maximumPeerSelectedEnabled={maximumPeerSelectedEnabled}
                isPeerReviewDraft={isPeerReviewDraft}
                isReadOnly={isReadOnly}
              />
              <ModalPeerRevieweeTab cycleId={cycleId} target={target} />
              <ModalPeerHistoryTab cycleId={cycleId} targetId={target?.id} />
            </Tabs.Contents>
          </Tabs>
        </div>
      </ModalV2.Body>
      <ModalV2.Footer
        primaryButton={
          (!isEqualSelectedUsers || !isEqualLockedUser) && {
            text: 'Submit',
            onClick: handleSubmit,
            isLoading: isSubmitLoading
          }
        }
        secondaryButton={{ text: 'Cancel', onClick: onHandleCloseModal }}
      />
    </ModalV2>
  );
}

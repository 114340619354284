const convertGroupNameToLowerCase = groups => {
  if (groups) {
    return groups?.map(group => {
      const groupName = group?.name || group;
      return {
        ...group,
        name: groupName?.toLowerCase()
      };
    });
  }
};

export { convertGroupNameToLowerCase };

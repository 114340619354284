import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import cloneDeep from 'lodash/cloneDeep';

import {
  ParentSuggestionProvider,
  useParentSuggestion
} from 'context/ParentSuggestionContext';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import useModalConfirmAlignTask from 'hooks/useModalConfirmAlignTask';
import { getCssVariableValue, getObjectiveLocale } from 'utils/HelperUtils';
import { getObjectiveType, getOwners } from 'utils/ObjectivesHelper';

import ModalV2 from 'components/design-system/Modal';
import TwoLine from 'components/design-system/ProfileType';
import Badge from 'components/shared/Badge';
import BannerBox from 'components/shared/BannerBox';
import Checkbox from 'components/shared/Checkbox';
import ConditionalWrapper from 'components/shared/ConditionalWrapper';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import SearchBar from 'components/shared/SearchBar';
import Tooltips from 'components/shared/Tooltips/Tooltips';

dayjs.extend(updateLocale);

function ModalParentSuggestion({
  objectiveValue,
  assigneeId,
  useTaskParentSuggestion = false,
  closeModal,
  handleChangeParent,
  useMultipleParentAlignment = true
}) {
  const {
    startDate: periodBegin,
    dueDate: periodEndBefore,
    parentIds: selectedMultipleValue,
    parentId: selectedSingleValue,
    parents: defaultMultipleValue,
    parent: defaultSingleValue,
    id: objectiveId
  } = objectiveValue;

  const { checkAlignmentTask, isModalConfirmAlignTaskShow } =
    useModalConfirmAlignTask();

  const defaultTempParents = useMultipleParentAlignment
    ? defaultMultipleValue
    : [defaultSingleValue];

  const defaultTempParentIds = useMultipleParentAlignment
    ? selectedMultipleValue
    : selectedSingleValue;

  const { config } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tempParents, setTempParents] = useState(cloneDeep(defaultTempParents));
  const [tempParentIds, setTempParentIds] = useState(
    cloneDeep(defaultTempParentIds)
  );

  const [search, setSearch] = useState(null);
  const debouncedSearchTerm = useDebounce(search, 500);

  const {
    listValue,
    hasMore,
    getParentSuggestionData,
    appendParentSuggestionData
  } = useParentSuggestion();
  let listValuePayload = listValue.filter((val) => val.id !== objectiveId);

  const objectiveType = getObjectiveType(objectiveValue);

  const options = listValuePayload.map((value) => {
    const involvements =
      value?.involvements?.length > 0
        ? value?.involvements
        : value?.stakeholders;
    const { goalOwnersProfilePic, goalOwnersName, goalOwners } = getOwners(
      involvements,
      value.isProject
    );

    let startDate = dayjs(value.startDate).format('MMM, DD YYYY');
    dayjs.updateLocale(dayjs.locale(), {
      invalidDate: 'no due date'
    });
    let dueDate = dayjs(value.dueDate).format('MMM, DD YYYY');

    return {
      id: value?.id,
      user: goalOwners?.[0]?.user,
      image: goalOwnersProfilePic,
      avatarName: goalOwnersName,
      text: value.name,
      subText: `${startDate} - ${dueDate}`,
      onClick: () => handleChangeDialog(value),
      selected: tempParentIds?.includes(value?.id),
      customClass: 'w-[350px]',
      dataCy: `parent-${value?.id}`,
      dataCyTitle: 'parent-suggestion-title'
    };
  });

  const handleChangeDialog = (sel, type = 'edit') => {
    if (config?.enableMultiParentAlignment && useMultipleParentAlignment) {
      let newSelectedValue = tempParents ? [...tempParents] : [];
      let newSelectedValueIds = tempParentIds ? [...tempParentIds] : [];

      if (newSelectedValueIds?.some((value) => value == sel?.id)) {
        newSelectedValueIds = newSelectedValueIds?.filter(
          (newSelected) => newSelected !== sel?.id
        );
        newSelectedValue = newSelectedValue?.filter(
          (newSelected) => newSelected?.id !== sel?.id
        );
      } else {
        newSelectedValueIds.push(sel?.id);
        newSelectedValue.push(sel);
      }

      setTempParents(newSelectedValue);
      setTempParentIds(newSelectedValueIds);
    } else {
      setTempParents(type === 'delete' ? [] : [sel]);
      setTempParentIds(type === 'delete' ? [] : [sel?.id]);
    }
  };

  const _getParentSuggestionData = async () => {
    const query = getAlignParentParams();
    setIsLoading(true);
    await getParentSuggestionData(query, useTaskParentSuggestion);
    setIsLoading(false);
  };

  const getAlignParentParams = () => {
    const query = {
      ...assigneeId,
      q: search,
      periodBegin: periodBegin,
      periodEndBefore: periodEndBefore,
      state: ['running', 'completed', 'reviewed', 'edited'],
      id: objectiveId
    };

    if (typeof objectiveId !== 'number') {
      if (objectiveId?.includes('-')) {
        delete query.id;
      }
    }
    if (objectiveValue?.type == 'goal' && !objectiveValue.isProject) {
      query.isProject = false;
    }
    return query;
  };

  const onScroll = (e) => {
    const loadMore = async () => {
      const query = getAlignParentParams();
      await appendParentSuggestionData(query, useTaskParentSuggestion);
    };

    let target = e.target;
    let cond = target.scrollTop + target.clientHeight == target.scrollHeight;

    if (hasMore && cond) {
      loadMore();
    }
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    if (objectiveType === 'task') {
      setIsSubmitting(false);
      const isModalConfirmAlignTaskShow = checkAlignmentTask({
        objectiveValue,
        newParent: tempParents[0] || null,
        nextAction: async () =>
          await handleChangeParent(tempParentIds, tempParents)
      });

      if (isModalConfirmAlignTaskShow) {
        return;
      }
    }

    await handleChangeParent(tempParentIds, tempParents);
    setIsSubmitting(false);
  };

  useEffect(() => {
    _getParentSuggestionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <ModalV2
        onClose={closeModal}
        customClass="!max-w-[600px] !min-h-[272px]"
        minWidth={600}
        closeOnClickOutside={!isModalConfirmAlignTaskShow}
        dataCy="modal"
      >
        <ModalV2.Header title="Align to Parent" onClose={closeModal} />
        <ModalV2.Body>
          <div className="px-[24px] pt-[4px] pb-[24px] w-full">
            <p className="typography-paragraph text-n-800 mb-[16px]">
              {getObjectiveLocale('Select parent to be aligned')}
            </p>
            <SearchBar
              fullWidth={true}
              placeholder={getObjectiveLocale('Search parent...')}
              onChange={(e) => setSearch(e.target.value)}
            />
            {tempParentIds?.length > 0 && (
              <>
                <p className="typography-paragraph text-n-800 py-[16px]">
                  {getObjectiveLocale('Parent Selected')}
                </p>
                <SelectedItem
                  tempParents={tempParents}
                  handleChangeDialog={handleChangeDialog}
                />
              </>
            )}
            <div
              className={`overflow-y-auto overflow-x-hidden h-[276px] ml-[-24px] ${
                tempParentIds?.length > 0 ? '' : 'mt-[16px]'
              }`}
              onScroll={onScroll}
            >
              {isLoading ? (
                <LoadingComponent />
              ) : options?.length == 0 ? (
                <ListEmptyState
                  fullHeight
                  emptyIcon={
                    search ? 'icon-no-result-found' : 'icon-no-objective'
                  }
                  containerClassname="px-[24px]"
                  title={getObjectiveLocale(
                    search ? 'No Result Found' : 'No objective can be selected'
                  )}
                  subtitle={getObjectiveLocale(
                    search
                      ? 'Did you probably type the keyword incorrectly? We are sorry that what you searched was unfortunately not found.'
                      : 'The objective establishes a sense of direction that guides your decisions and task to be more aligned with your company mission.'
                  )}
                />
              ) : (
                options?.map((goal, id) => {
                  return (
                    <div
                      key={id}
                      data-cy={`parent-${id}`}
                      className={`flex items-center h-[56px] w-full hover:bg-base-30016 ml-[24px] cursor-pointer ${
                        goal?.selected ? 'bg-base-30024' : ''
                      }`}
                      onClick={goal?.onClick}
                    >
                      <ConditionalWrapper
                        condition={
                          config?.enableMultiParentAlignment &&
                          useMultipleParentAlignment
                        }
                        wrapper={(children) => (
                          <Checkbox
                            name={goal?.text}
                            value={goal?.id}
                            id={id}
                            checked={goal?.selected}
                            dataCy="tag-checkbox"
                            labelClass="cursor-pointer"
                            onClick={goal?.onClick}
                          >
                            {children}
                          </Checkbox>
                        )}
                      >
                        <TwoLine
                          customClass="pl-[16px] !w-[512px]"
                          user={goal?.user}
                          title={goal?.text}
                          subtitle={`${goal?.avatarName}`}
                        />
                      </ConditionalWrapper>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </ModalV2.Body>
        <ModalV2.Footer
          useBorder={false}
          primaryButton={{
            onClick: onSubmit,
            text: getObjectiveLocale('Select'),
            isLoading: isSubmitting,
            dataCy: 'submit-align-parent'
          }}
          secondaryButton={{
            onClick: closeModal,
            text: getObjectiveLocale('Back')
          }}
        />
      </ModalV2>
    </>
  );
}

const SelectedItem = ({ tempParents, handleChangeDialog }) => {
  if (tempParents?.length === 0) return null;
  return (
    <div className="flex flex-wrap">
      {tempParents?.map((goal, id) => (
        <div className="flex items-center mr-[8px] mb-[16px]" key={id}>
          <Tooltips
            isEllipsis
            tooltipClass="px-12 py-4 height-auto"
            text={goal?.name}
          >
            <Badge
              truncate
              customMaxWidth="max-w-[138px]"
              content={goal?.name}
              bgColorHex={getCssVariableValue('--n-300')}
              className={'mr-4 whitespace-nowrap cursor-pointer'}
              showCursorNotAllowed={false}
              dataCy={`badge-selected-${id}`}
            />
          </Tooltips>
          <SVGIcon
            size="16"
            iconName="icon-clear"
            fillColor="var(--n-600)"
            onClick={() => handleChangeDialog(goal, 'delete')}
            dataCy={`close-label-${id}`}
            customClass="ml-[4px]"
          />
        </div>
      ))}
    </div>
  );
};

const ModalAlignParent = (props) => (
  <ParentSuggestionProvider>
    <ModalParentSuggestion {...props} />
  </ParentSuggestionProvider>
);

export default ModalAlignParent;

import React, { useRef, useState } from 'react';

import startCase from 'lodash/startCase';

import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

const DropdownSources = ({ objectKey, listValue, onChange, defaultValue }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedDropdownData, setSelectedDropdownData] =
    useState(defaultValue);
  const dropdown = useRef();
  useClickOutside(dropdown, () => setOpenDropdown(false));

  const selectDropdown = (data) => {
    setOpenDropdown(false);
    setSelectedDropdownData(data);
    onChange(data);
  };

  const getSelectedData = () => {
    return getObjectiveLocale(
      selectedDropdownData?.name ||
        selectedDropdownData?.title ||
        startCase(selectedDropdownData?.type)
    );
  };

  const isDataEmpty = listValue?.length == 0 || !listValue;

  return (
    <div className={`wrapper-dropdown-sources flex items-center relative`}>
      <div
        data-cy={`${objectKey}-dropdown`}
        className={`dropdown-template ${!isDataEmpty ? '' : 'disabled'}`}
        onClick={() => setOpenDropdown(true)}
      >
        <p className="dropdown-value">
          {getSelectedData() || 'No available data'}
        </p>
        <SVGIcon
          customClass="icon-fold"
          iconName="icon-unfold_more"
          fillColor="var(--n-600)"
          size={24}
        />
      </div>
      {openDropdown && listValue && (
        <div
          data-cy={`${objectKey}-list`}
          className="list-filter-component"
          ref={dropdown}
        >
          {listValue.map((data, index) => (
            <div
              key={index}
              className="row-filter"
              data-cy={'dropdown-' + index}
              onClick={() => selectDropdown(data)}
            >
              {getObjectiveLocale(
                data?.name || data?.title || startCase(data?.type)
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownSources;

import React from 'react';

import { abbrevNumber } from 'utils/HelperUtils';

import ChartObjectiveAnalytics from 'components/objectives/progress-overtime/ChartObjectiveAnalytics';

export default function ObjectiveGraph({
  formattedProgressData,
  minimumProgress,
  maximumProgress,
  measurement
}) {
  const { unit, currentValue, progressColorHex, targetValue } =
    measurement || {};

  return (
    <>
      <div className="ml-[16px]">
        <div className="flex flex-row">
          <p
            style={{
              color: `#${
                progressColorHex == '44DB5E' ? '16A34A' : progressColorHex
              }`
            }}
            className="typography-h700 z-2"
          >
            {unit} {abbrevNumber(currentValue)}
          </p>
          <p className="text-n-600 typography-h700 mx-[4px] z-2">/</p>
          <p className="text-n-800 typography-h700 z-2">
            {abbrevNumber(targetValue)}
          </p>
        </div>
      </div>

      <ChartObjectiveAnalytics
        data={formattedProgressData}
        minimumProgress={minimumProgress}
        maximumProgress={maximumProgress}
        height={180}
        containerProps={{
          className: 'mt-[-30px]'
        }}
      />
    </>
  );
}

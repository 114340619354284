import React, { useState } from 'react';

import ModalInviteMemberV2 from 'components/modal/culture/ModalInviteMemberV2';
import ModalManageMemberV2 from 'components/modal/culture/ModalManageMemberV2';

const ModalGroupMember = (props) => {
  const [showInviteModal, setShowInviteModal] = useState(false);

  return (
    <>
      {showInviteModal ? (
        <ModalInviteMemberV2
          {...props}
          setShowInviteModal={setShowInviteModal}
        />
      ) : (
        <ModalManageMemberV2
          {...props}
          setShowInviteModal={setShowInviteModal}
        />
      )}
    </>
  );
};

export default ModalGroupMember;

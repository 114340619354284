import React from 'react';

import dayjs from 'dayjs';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import DateFilter from '../../advance-filter/DateFilter';
import './EditScheduleCalendar.scss';

const EditScheduleCalendar = ({
  withResetButton = true,
  selectedValue = [null, null],
  onSelect = () => {},
  onResetSchedule = () => {},
  withDisabledDate = false,
  startLimitDate,
  endLimitDate
}) => {
  const { organization } = useUser();

  let filterOptions = [
    {
      id: 'today',
      name: `${getObjectiveLocale('Today')}`,
      description: `${dayjs(new Date()).format('DD MMMM YYYY')}`,
      value: [
        dayjs().startOf('day').toISOString(),
        dayjs().endOf('day').toISOString()
      ],
      datacy: 'today'
    },
    {
      id: 'all_time',
      name: `${getObjectiveLocale('All Time')}`,
      value: [
        dayjs(organization?.createdAt).startOf('day').toISOString(),
        dayjs().add(1, 'year').startOf('day').toISOString()
      ],
      datacy: 'all_time'
    },
    {
      id: 'annual',
      name: `${getObjectiveLocale('Annual')}`,
      description: `Jan 2017 - ${dayjs(`${new Date().getFullYear()}-12-31`)
        .endOf('day')
        .format('MMM YYYY')}`,
      description: '1 January - 31 December',
      value: [
        dayjs(`${new Date().getFullYear()}-01-01`).toISOString(),
        dayjs(`${new Date().getFullYear()}-12-31`).endOf('day').toISOString()
      ],
      datacy: 'annual'
    },
    {
      id: 'quarter_1',
      name: `Q1 - ${new Date().getFullYear()}`,
      description: '1 January - 31 March',
      value: [
        dayjs(`${new Date().getFullYear()}-01-01`).toISOString(),
        dayjs(`${new Date().getFullYear()}-03-31`).endOf('day').toISOString()
      ],
      datacy: 'q1'
    },
    {
      id: 'quarter_2',
      name: `Q2 - ${new Date().getFullYear()}`,
      description: '1 April - 30 June',
      value: [
        dayjs(`${new Date().getFullYear()}-04-01`).toISOString(),
        dayjs(`${new Date().getFullYear()}-06-30`).endOf('day').toISOString()
      ],
      datacy: 'q2'
    },
    {
      id: 'quarter_3',
      name: `Q3 - ${new Date().getFullYear()}`,
      description: '1 July - 30 September',
      value: [
        dayjs(`${new Date().getFullYear()}-07-01`).toISOString(),
        dayjs(`${new Date().getFullYear()}-09-30`).endOf('day').toISOString()
      ],
      datacy: 'q3'
    },
    {
      id: 'quarter_4',
      name: `Q4 - ${new Date().getFullYear()}`,
      description: '1 October - 31 December',
      value: [
        dayjs(`${new Date().getFullYear()}-10-01`).toISOString(),
        dayjs(`${new Date().getFullYear()}-12-31`).endOf('day').toISOString()
      ],
      datacy: 'q4'
    }
  ];

  const timeFilterOptions = [
    'today',
    'all_time',
    'annual',
    'quarter',
    'custom'
  ];
  let timeFilter = filterOptions.filter((option) =>
    timeFilterOptions.some((filter) => filter.includes(option.id.slice(0, 3)))
  );

  return (
    <DateFilter
      formatType={'DD MMMM YYYY'}
      lists={timeFilter}
      custom={timeFilterOptions.includes('custom')}
      onSelect={onSelect}
      containerClassName="edit-schedule-calendar"
      selectedValue={selectedValue}
      startLimitDate={startLimitDate}
      endLimitDate={endLimitDate}
      withDisabledDate={withDisabledDate}
      withResetButton={withResetButton}
      onResetSchedule={onResetSchedule}
    />
  );
};

export default EditScheduleCalendar;

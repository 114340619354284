import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';

import {
  deleteLeague,
  getListLeagues,
  restoreLeague,
  sortingListLeagues
} from 'client/adminClient.js';
import { useUser } from 'context/UserContext';
import { getCultureLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import AdminTabWithCount from 'components/shared/Tabs/AdminTabWithCount.js';

const ListLeague = ({ setWarning, setShowNotif, setNotifMessage }) => {
  const { config } = useUser();
  const history = useHistory();
  const [ready, setReady] = useState(false);
  const [tab, setTab] = useState('active');
  const [listLeagueActive, setListLeagueActive] = useState([]);
  const [listLeagueArchived, setListLeagueArchived] = useState([]);
  const [afterArchived, setAfterArchived] = useState(false);
  const positionsList = [
    { key: 'active', name: 'Active' },
    { key: 'archived', name: 'Archived' }
  ];
  const isReadOnly =
    config?.permissions?.cultureSettingsLeaguesSee === 'read_only';

  const changeTab = (tab) => {
    setTab(tab);
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    let style = { userSelect: 'none', ...draggableStyle };

    if (isDragging) {
      style.background = 'var(--base-30024)';
      style.boxShadow = '0 6px 12px 0 rgba(50, 50, 50, 0.2)';
    }

    return style;
  };

  const reorder = (startIndex, endIndex) => {
    const result = [...listLeagueActive];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const _onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedArray = reorder(
      result.source.index,
      result.destination.index
    );
    const temp = cloneDeep(reorderedArray);
    temp?.forEach((data, index) => {
      data.orderLevel = index + 1;
    });
    setListLeagueActive(temp);
    setAfterArchived(false);
  };

  const changeStateLeague = async (id, type) => {
    let data;
    if (type == 'delete') {
      data = await deleteLeague(id);
    } else {
      data = await restoreLeague(id);
    }
    if (data) {
      getListofLeagues();
      setShowNotif(true);
      setNotifMessage(
        type == 'delete'
          ? 'League successfully archived'
          : 'League successfully restored'
      );
      setTimeout(() => {
        setShowNotif(false);
      }, 3000);
      setAfterArchived(true);
    }
  };

  const handleCreateLeague = () => {
    if (listLeagueActive.length > 3) {
      setWarning(true);
      setShowNotif(true);
      setNotifMessage('You can only create four leagues at max.');
      setTimeout(() => {
        setShowNotif(false);
      }, 3000);
    } else {
      history.replace('/missions/create-league');
    }
  };

  const getListofLeagues = async () => {
    const { data: listActive } = await getListLeagues({ state: 'active' });
    const { data: listArchived } = await getListLeagues({ state: 'archived' });
    if (listActive || listArchived) {
      setListLeagueActive(listActive);
      setListLeagueArchived(listArchived);
    }
  };

  const sortingLeagues = async () => {
    let body = cloneDeep(listLeagueActive);
    body?.forEach((data) => {
      delete data?.badges;
      delete data?.sourceInfo;
      delete data?.status;
      delete data?.name;
    });
    const { data } = await sortingListLeagues({ leagues: body });
    if (data) {
      setWarning(false);
      setShowNotif(true);
      setNotifMessage('League hierarchy successfully saved');
      setTimeout(() => {
        setShowNotif(false);
      }, 3000);
    }
  };

  useEffect(() => {
    !afterArchived && ready && tab == 'active' && sortingLeagues();
  }, [listLeagueActive]);

  useEffect(() => {
    const getData = async () => {
      await getListofLeagues();
      setTimeout(() => {
        setReady(true);
      }, 2000);
    };
    getData();
  }, []);

  return (
    <div className="list-league my-[24px] border-n400 py-[24px] rounded-[4px] w-[70%]">
      <div className="flex justify-between mb-[10px] px-[24px]">
        <div>
          <p className="typography-h500">{getCultureLocale('League')}</p>
          <p className="typography-paragraph text-n-800">
            {getCultureLocale(
              'Define league and badge that describe employee’s rank.'
            )}
          </p>
        </div>
        {!isReadOnly && (
          <Button onClick={() => handleCreateLeague()}>
            <SVGIcon
              iconName="icon-add"
              size="24"
              fillColor="var(--n-000)"
              customClass="mr-[4px]"
            />
            {getCultureLocale('Create League')}
          </Button>
        )}
      </div>
      <AdminTabWithCount
        active={tab}
        listObjTabs={positionsList}
        changeTab={(id) => changeTab(id)}
        withCount={false}
        customClassWrapper={'px-[24px]'}
      />
      <div className="px-[24px]">
        <div className="flex items-center header-table mt-[24px] border-bottom-400">
          {tab == 'active' && (
            <div className="px-[16px] w-[18%] pb-[10px]">
              <p className="typography-h300 text-n-800">
                {getCultureLocale('HIERARCHY')}
              </p>
            </div>
          )}
          <div
            className={`px-[16px] ${
              tab == 'active' ? 'w-[63%]' : 'w-[82%]'
            } pb-[10px]`}
          >
            <p className="typography-h300 text-n-800">
              {getCultureLocale('LEAGUE NAME')}
            </p>
          </div>
          <div className="px-[16px] w-[40%] pb-[10px]">
            <p className="typography-h300 text-n-800">
              {getCultureLocale('BADGE')}
            </p>
          </div>
          <div className="w-[6%]" />
        </div>
        <div className="border-bottom-400">
          <DragDropContext onDragEnd={_onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className=".body-table-dnd"
                  id="table-dnd"
                  style={{
                    height: snapshot.isDraggingOver
                      ? document.getElementById('table-dnd')?.offsetHeight
                      : '100%'
                  }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {(tab == 'active'
                    ? listLeagueActive
                    : listLeagueArchived
                  )?.map((value, index) => {
                    return (
                      <Draggable
                        isDragDisabled={tab !== 'active' || isReadOnly}
                        draggableId={String(value?.id)}
                        index={index}
                        key={value?.id}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              className={`flex items-center league-area flex task-${
                                index + 1
                              }`}
                              key={index}
                            >
                              {tab == 'active' && (
                                <div className="px-[16px] w-[15%] py-[16px] flex items-center">
                                  <SVGIcon
                                    size="24"
                                    fillColor="var(--n-600)"
                                    iconName={'icon-drag_indicator'}
                                    customClass="mr-[24px]"
                                  />
                                  {index + 1}
                                </div>
                              )}
                              <div
                                className={`${
                                  tab == 'active' ? 'w-[49%]' : 'w-[64%]'
                                } px-[16px] py-[16px] h-[56px]`}
                              >
                                {value?.name}
                              </div>
                              <div className="w-[18%] px-[16px] py-[16px]">{`${getCultureLocale(
                                value.badges.length
                              )} badges`}</div>
                              {tab == 'active' && (
                                <div className="icon-league w-[8%] py-[16px]">
                                  <SVGIcon
                                    dataCy={'icon-edit'}
                                    onClick={() =>
                                      history.replace(
                                        `/missions/edit-league/${value?.id}`
                                      )
                                    }
                                    size="24"
                                    fillColor="var(--n-600)"
                                    iconName={'icon-create'}
                                    customClass="mr-[24px]"
                                  />
                                </div>
                              )}
                              <div className="icon-league w-[8%] py-[16px]">
                                {
                                  <SVGIcon
                                    dataCy={'icon-state'}
                                    onClick={() =>
                                      changeStateLeague(
                                        value?.id,
                                        tab == 'active' ? 'delete' : 'restore'
                                      )
                                    }
                                    size="24"
                                    fillColor="var(--n-600)"
                                    iconName={
                                      tab == 'active'
                                        ? 'icon-archive'
                                        : 'icon-reply'
                                    }
                                    customClass="mr-[24px]"
                                  />
                                }
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default ListLeague;

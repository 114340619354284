import React, { useState } from 'react';

import { useToastContext } from 'context/ToastContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Checkbox from 'components/shared/Checkbox';

const WeightSettingsObjOptions = ({ defaultValue, changeConfigData }) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue || []);
  const { addToast } = useToastContext();

  const goalTypeOptions = [
    { id: 'goal', label: 'Goals' },
    { id: 'team_goal', label: 'Team Goals' }
  ];

  const toggleCheck = async (value) => {
    let selected = [...selectedOptions];

    if (selected.includes(value)) {
      if (selected.length == 1) {
        addToast({
          msg: 'You must select at least one of this objective type to apply all the goal types defined below Otherwise you can disable this Goal Type setting overall by toggling off Goal Type above',
          type: 'error'
        });
        return;
      }
      selected = selected.filter((val) => val != value);
    } else {
      selected = [...selected, value];
    }
    setSelectedOptions(selected);
    await changeConfigData({ objectiveWeightTypeOptions: selected });
  };

  return (
    <div className="flex flex-[1] flex-col pr-[24px] pb-[20px]">
      <p className="typography-h100 text-n-800 mb-[8px]">
        {getObjectiveLocale('Goal Type will only be applicable to')}
      </p>
      <div className="flex flex-col">
        {goalTypeOptions.map((value) => (
          <Checkbox
            key={value.id}
            name={'goal-type-checkbox' + value.id}
            id={'goal-type-checkbox' + value.id}
            checked={selectedOptions.includes(value.id)}
            onClick={() => toggleCheck(value.id)}
            style={{ marginLeft: '-3px' }}
            dataCy={'goal-type-checkbox' + value.id}
          >
            <p className={`typography-paragraph ml-[5px] text-n-900`}>
              {getObjectiveLocale(value.label)}
            </p>
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

export default WeightSettingsObjOptions;

import { getObjectiveLocale } from 'utils/HelperUtils';

const localeGoal = getObjectiveLocale('goal').toLowerCase();
const localeWeight = getObjectiveLocale('weight').toLowerCase();

let titleTrackTemplate = '';
let paramTrackType = '';

const getMechanismDescription = (mechanismData, selectedMechanism) => {
  if (!mechanismData.length > 0 || !selectedMechanism) return '';

  const getMechanismArr = mechanismData.filter(
    mech => mech.value === selectedMechanism
  );
  return getMechanismArr?.[0]?.description || '';
};

const setParamTrackType = trackType => {
  switch (trackType) {
    case 'goals_scoring':
      paramTrackType = 'goal_final';
      break;
    case 'tasks_scoring':
      paramTrackType = 'task_final';
      break;
    case 'values_scoring':
      paramTrackType = 'value_final';
      break;
    case 'competencies_scoring':
      paramTrackType = 'competency_final';
      break;
    default:
      break;
  }
};

const setTitleTrackTemplate = trackType => {
  switch (trackType) {
    case 'goals_scoring':
      titleTrackTemplate = `${getObjectiveLocale('Goal')} Final Scoring`;
      break;
    case 'tasks_scoring':
      titleTrackTemplate = 'Task Final Scoring';
      break;
    case 'values_scoring':
      titleTrackTemplate = `${getObjectiveLocale('Value')} Final Scoring`;
      break;
    case 'competencies_scoring':
      titleTrackTemplate = `${getObjectiveLocale('Competency')} Final Scoring`;
      break;
    case 'values_recognition_feedback':
      titleTrackTemplate = `${getObjectiveLocale(
        'Value'
      )} Recognition & Feedback`;
      break;
    case 'competencies_recognition_feedback':
      titleTrackTemplate = 'Competency recognition & feedback';
      break;
    case 'summary':
      titleTrackTemplate = 'Summary';
      break;
    case 'reviewee_feedback':
      titleTrackTemplate = 'Review Feedback';
      break;
    case 'general_questionnaire':
      titleTrackTemplate = 'General questions';
      break;
    case 'performance_rating':
      titleTrackTemplate = 'Performance Rating';
      break;
    case 'review_aspects_scoring':
      titleTrackTemplate = 'Scored Attributes';
      break;
    default:
      break;
  }
};

const getSelectedFormula = value => {
  switch (value) {
    case 'total_rating':
      return 'Total Rating';
    case 'average':
      return 'Average of total rating';
    case 'weighted':
      return `Total rating times ${localeGoal} ${localeWeight} percentage`;
    case 'weighted_average':
      return `Average of total rating times ${localeGoal} ${localeWeight} percentage`;
    case 'weighted_total':
      return `Total rating times ${localeGoal} ${localeWeight}`;
    default:
      return value;
  }
};

const dynamicFormulaArray = (trackType, customValue) => {
  const localeTrackType = getObjectiveLocale(trackType).toLowerCase();
  return [
    { value: 'total_rating', name: 'Total Rating' },
    { value: 'average', name: 'Average of total rating' },
    {
      value: 'weighted',
      name: `Total rating times ${localeTrackType} ${localeWeight} percentage`
    },
    {
      value: 'weighted_average',
      name: `Average of total rating times ${localeTrackType} ${localeWeight} percentage`
    }, //default
    {
      value: 'weighted_total',
      name: `Total rating times ${localeTrackType} ${localeWeight}`
    },
    trackType == 'goal' && {
      value: customValue,
      name: 'Custom'
    }
  ].filter(formula => formula);
};

export {
  getSelectedFormula,
  dynamicFormulaArray,
  getMechanismDescription,
  titleTrackTemplate,
  paramTrackType,
  setParamTrackType,
  setTitleTrackTemplate
};

import React from 'react';

import dayjs from 'dayjs';

const GanttBackgroundMonthlyColumn = ({ daysOfYear }) => {
  let ident = false;
  const today = new Date();
  return (
    <>
      {daysOfYear.map(({ date, id }, index) => {
        const isThisMonth =
          date.getMonth() == today.getMonth() &&
          date.getFullYear() == today.getFullYear();
        const endOfMonth = dayjs(date).endOf('month').date();
        if (ident == false) {
          ident = true;
          const width = 6 * (endOfMonth - dayjs(daysOfYear[0].date).date() + 1);
          return (
            <div
              key={'bg-month' + id}
              className="tm-bg-wrapper relative h-[100%] "
              style={{
                width: width + 'px'
              }}
            />
          );
        }
        if (daysOfYear.length - 1 == index) {
          return;
        }
        if (date.getDate() == 1) {
          return (
            <div
              key={'bg-month' + id}
              className={`tm-bg-wrapper relative h-[100%] ${
                isThisMonth ? 'bg-n-100' : ''
              }`}
              style={{
                width: 6 * endOfMonth + 'px'
              }}
            />
          );
        }
      })}
    </>
  );
};

export default GanttBackgroundMonthlyColumn;

import React, { useState } from 'react';

import ctl from '@netlify/classnames-template-literals';

import { getDateLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import ModalImage from 'components/shared/ModalImage/ModalImage';
import SVGIcon from 'components/shared/SVGIcon';

const ImageItem = ({ item, deleteAttachmentData }) => {
  const [onHover, setOnHover] = useState(true);

  const [isOpenModalImage, setIsOpenModalImage] = useState(false);

  const containerClass = ctl(`
    relative
    flex
    px-[24px] py-[16px]
    ${onHover && 'bg-base-30024'}
  `);

  return (
    <div
      className={containerClass}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      data-cy="image-item"
    >
      <SVGIcon fillColor="var(--n-600)" iconName="icon-attachment" size="24" />
      <div className="ml-[16px] flex flex-col">
        <span className="typography-h400">{item.filename}</span>
        <span className="mt-[4px] typography-secondary">
          {`By ${item.user.name} ${getDateLocale(item.createdAt)}`}
        </span>
        {item?.fileTimestamp && (
          <span className="typography-secondary">
            Taken on {getDateLocale(item?.fileTimestamp)}
          </span>
        )}
        <AWSImage
          src={item.downloadUrl}
          className="w-[120px] h-[80px] mt-[8px] cursor-pointer"
          onClick={() => setIsOpenModalImage(true)}
        />
      </div>
      <SVGIcon
        fillColor="var(--n-600)"
        iconName="icon-delete"
        size="24"
        onClick={() => deleteAttachmentData(item.id)}
        customClass="absolute top-50p right-16 -translate-y-1/2"
      />

      <ModalImage
        imgs={[{ src: item.downloadUrl }]}
        isOpen={isOpenModalImage}
        onClose={() => setIsOpenModalImage(false)}
      />
    </div>
  );
};

export default ImageItem;

import React from 'react';

import { archiveTeam, reactivateTeam } from 'client/TeamClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import { useTeamDetail } from 'context/team/TeamDetailContext';
import { trackEvent } from 'utils/AnalyticUtils';
import { capitalize } from 'utils/HelperUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';

const ModalArchiveSquad = ({ setShowModal, type }) => {
  const { refetchQueries, invalidateQueries } = useRefetchQuery();

  const { data: team } = useTeamDetail();
  const { addToast } = useToastContext();

  const onSubmit = async () => {
    const { isSuccess } =
      type === 'archive'
        ? await archiveTeam(team.id)
        : await reactivateTeam(team.id);

    if (isSuccess) {
      refetchQueries(['team', +team?.id, team?.teamType]);
      refetchQueries(['team', 'activities', +team?.id]);
      invalidateQueries(['activity-obj']);
      invalidateQueries(['objectives', 'myteams']);

      addToast({
        title: getObjectiveLocale(
          (type === 'archive' ? 'Archive ' : 'Reactivate ') +
            capitalize(team?.teamType)
        ),
        msg: getObjectiveLocale(
          `${team?.teamType} has been ${
            type == 'archive' ? 'archived' : 'reactivated'
          }`
        ),
        type: 'success'
      });

      trackEvent({
        event: `${type === 'archive' ? 'ArchiveTeam' : 'Reactivate Team'}`,
        eventProperties: {
          teamId: team.id,
          teamName: team.name
        },
        env: 'performance'
      });
    }
    setShowModal({ show: false });
  };

  return (
    <Modal
      onClose={() => setShowModal({ show: false })}
      minWidth={400}
      maxWidthClass="max-w-[400px]"
    >
      <Modal.Header
        title={getObjectiveLocale(
          type === 'archive' ? 'Archived' : 'Reactivate'
        )}
        onClose={() => setShowModal({ show: false })}
      />
      <Modal.Body>
        <p
          className="typography-paragraph text-n-800 px-[24px] mb-[16px]"
          dangerouslySetInnerHTML={{
            __html: getObjectiveLocale(
              `Are you sure want to ${
                type === 'archive' ? 'archive' : 'reactivate'
              } [team_name] ${
                team?.teamType
              }? Goals, projects, and team feedback in this ${
                team?.teamType
              } will also be ${
                type === 'archive' ? 'archived' : 'reactivated'
              }.`
            ).replace(
              '[team_name]',
              `<span style="color:var(--n-900)">${team?.name}</span>`
            )
          }}
        ></p>
      </Modal.Body>
      <Modal.Footer
        primaryButton={{
          text: getObjectiveLocale(
            type == 'archive' ? 'Archive' : 'Reactivate'
          ),
          dataCy: 'btn-submit-archive',
          onClick: onSubmit
        }}
        secondaryButton={{
          text: getObjectiveLocale('Cancel'),
          dataCy: 'btn-cancel-archive',
          onClick: () => setShowModal({ show: false })
        }}
        useBorder={false}
      />
    </Modal>
  );
};

export default ModalArchiveSquad;

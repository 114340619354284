const scrollEvent = (x, y) => {
  const scrollLeft = x;
  const scrollTop = y;
  let elements = document.getElementsByClassName('timeline-sidebar')[0];
  let elementsHeader = document.getElementsByClassName('header-date')[0];
  let bgelements = document.getElementsByClassName('bg-sidebar')[0];
  const sidebarName = document.getElementsByClassName('sidebar-name');
  let sidebarNameArray = [...sidebarName];
  elements.style.transform = `translate(${scrollLeft}px, ${scrollTop}px)`;
  elementsHeader.style.transform = `translateY(${scrollTop}px)`;
  bgelements.style.transform = `translateX(${scrollLeft}px)`;
  elements.style.webkitTransform = `translate(${scrollLeft}px, ${scrollTop}px)`;
  elementsHeader.style.webkitTransform = `translateY(${scrollTop}px)`;
  bgelements.style.webkitTransform = `translateX(${scrollLeft}px)`;
  sidebarNameArray.map(el => {
    el.style.transform = `translateX(${scrollLeft}px)`;
    el.style.webkitTransform = `translateX(${scrollLeft}px)`;
  });
};

const modifyScrollbarThumb = (finalTransform, isHorizontal = true) => {
  const testScroller = document.querySelector('#scroll-content');
  const scroller = document.querySelector('#main-scroll');
  const axis = isHorizontal ? 'x' : 'y';
  const thumb = document.querySelector(`.scroll-thumb-${axis}`);
  const track = document.querySelector(`.scroll-track-${axis}`);
  let trackLength = track.offsetWidth;
  let thumbWidth = thumb.offsetWidth;

  let contentOffset = testScroller.offsetWidth;
  let scrollerOffset = scroller.offsetWidth;
  if (!isHorizontal) {
    contentOffset = testScroller.offsetHeight;
    scrollerOffset = scroller.offsetHeight;
    trackLength = track.offsetHeight;
    thumbWidth = thumb.offsetHeight;
  }
  const maxDistanceTrackBar = trackLength - thumbWidth;
  const maxContentScroll = contentOffset - scrollerOffset;
  const percentagedistance = Math.abs(finalTransform) / maxContentScroll;
  const distanceTrack = percentagedistance * maxDistanceTrackBar;
  thumb.style.transform = `translate${axis.toUpperCase()}(${distanceTrack}px)`;
};

const getFinalTransform = (designatedTransform, correctionValue) => {
  if (designatedTransform > 0) return 0;
  if (Math.abs(designatedTransform) > correctionValue)
    return correctionValue * -1;
  return designatedTransform;
};

const modifyScrollPosition = (designatedXtransform, designatedYtransform) => {
  const testScroller = document.querySelector('#scroll-content');
  const scroller = document.querySelector('#main-scroll');

  let correctionHeight = testScroller.offsetHeight - scroller.offsetHeight;
  let correctionWidth = testScroller.offsetWidth - scroller.offsetWidth;

  correctionHeight = correctionHeight > 0 ? correctionHeight : 0;
  correctionWidth = correctionWidth > 0 ? correctionWidth : 0;

  const finalXTransform = Math.floor(
    getFinalTransform(designatedXtransform, correctionWidth)
  );

  const finalYTransform = Math.floor(
    getFinalTransform(designatedYtransform, correctionHeight)
  );
  testScroller.style.webkitTransform = `translate(${finalXTransform}px, ${finalYTransform}px)`;

  modifyScrollbarThumb(finalXTransform);
  modifyScrollbarThumb(finalYTransform, false); // vertical thumbs
  scrollEvent(Math.abs(finalXTransform), Math.abs(finalYTransform));
};

export { modifyScrollPosition, scrollEvent };

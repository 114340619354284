import React, { useEffect, useState } from 'react';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';
import Modal from 'components/shared/modal/Modal';

const EditProjectNameModal = ({ setShowModal, objective }) => {
  const { id: projectId, name: projectName } = objective || [];

  const [currentProjectName, setCurrentProjectName] = useState('');

  const { match } = useUrl();
  const { reload, reloadSidebar } = useReload();
  const { refetchQueries } = useRefetchQuery();

  const handleClickSave = async () => {
    if (currentProjectName === '') return;

    const { isSuccess } = await editObjective(projectId, {
      name: currentProjectName
    });
    if (isSuccess) {
      refetchQueries(['objective', projectId]);
      match &&
        reload({
          reloadSidebar: !reloadSidebar
        });
      setShowModal(false);
    }
  };

  useEffect(() => {
    setCurrentProjectName(projectName || '');
  }, [projectName]);

  return (
    <Modal
      title="Rename Project"
      withPrimaryBtn={{
        title: 'Save',
        onClick: () => handleClickSave(),
        dataCy: 'edit-project-name-save'
      }}
      withSecondaryBtn={{ title: 'Cancel', onClick: () => setShowModal(false) }}
      withCloseIcon
      eventOnClickClose={() => setShowModal(false)}
    >
      <p className="typography-h100 text-n-800 mb-[8px]">
        {getObjectiveLocale('Project name')}
      </p>
      <InputField
        inputClass="typography-paragraph text-n-900 px-[16px] py-[4px]"
        placeholder="Project name"
        dataCy="edit-project-name-input"
        value={currentProjectName}
        handleChange={(e) => setCurrentProjectName(e.currentTarget.value)}
      />
    </Modal>
  );
};

export default EditProjectNameModal;

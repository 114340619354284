import React, { useEffect, useRef, useState } from 'react';

import 'emoji-mart/css/emoji-mart.css';
import Picker from 'emoji-mart/dist-es/components/picker/nimble-picker';
import 'scss/culture/modal/modal-post-feeling';

import {
  getFeelings,
  getReasons,
  getStickers,
  submitPost
} from 'client/CultureClient';
import useClickOutside from 'hooks/useClickOutside';
import { trackEvent } from 'utils/AnalyticUtils';
import { getCultureLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import PostFeeling from 'components/posts/PostFeeling';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

import ModalPostTemplate from './ModalPostTemplate';

function ModalPostFeeling({ eventOnClick, groupDetail, emoticon }) {
  const [currentSection, setCurrentSection] = useState('feelings');
  const [feelings, setFeelings] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [stickers, setStickers] = useState([]);
  const [contentPostFeeling, setContentPostFeeling] = useState({});
  const [selectedFeeling, setSelectedFeeling] = useState({});
  const [selectedReason, setSelectedReason] = useState({});
  const [selectedSticker, setSelectedSticker] = useState('');
  const [isButtonShow, setIsButtonShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isChangeSticker, setIsChangeSticker] = useState(false);
  const [isEmojiCartShow, setIsEmojiCartShow] = useState(false);

  const emojiRef = useRef();

  useClickOutside(emojiRef, () => {
    setIsEmojiCartShow(false);
  });

  const addEmoji = (emoji) => {
    let currentCaption = selectedReason.title ? selectedReason.title : '';
    currentCaption += emoji.native;
    setSelectedReason({ id: null, title: currentCaption });
    setIsEmojiCartShow(false);
  };

  const getFeelingList = async () => {
    const { data } = await getFeelings();

    if (data) {
      setFeelings(data);
      setIsLoading(false);
    }
  };

  const getReasonList = async (id) => {
    setIsLoading(true);

    const { data } = await getReasons(id);

    if (data) {
      setReasons(data);
      setIsLoading(false);
    }
  };

  const getStickerList = async (id) => {
    setIsLoading(true);

    const { data } = await getStickers(id);

    if (data) {
      setStickers(data);
      setIsLoading(false);
    }
  };

  const chooseFeeling = (feeling) => {
    const currentFeeling = {
      id: feeling?.id,
      title: feeling.title,
      imgUrl: feeling.defaultSticker.secureUrl,
      imgId: feeling.defaultSticker?.id,
      colorCode: feeling.colorCode
    };

    setSelectedSticker(feeling.defaultSticker.secureUrl);
    setSelectedFeeling(currentFeeling);
    setCurrentSection('reasons');
    getReasonList(feeling?.id);
  };

  const chooseReason = (reason) => {
    const currentReason = {
      id: reason?.id,
      title: reason.title
    };

    const content = {
      colorCode: selectedFeeling.colorCode,
      type: selectedFeeling.title,
      sticker: {
        secureUrl: selectedFeeling.imgUrl
      },
      reason: reason.title,
      locked: false
    };
    setSelectedReason(currentReason);
    setContentPostFeeling(content);
    setIsButtonShow(true);
    getStickerList(selectedFeeling?.id);
    setCurrentSection('stickers');
  };

  const changeSticker = (sticker) => {
    setSelectedSticker(sticker.secureUrl);

    const currentFeeling = selectedFeeling;
    currentFeeling.imgUrl = sticker.secureUrl;
    currentFeeling.imgId = sticker?.id;
    setIsChangeSticker(true);
    setSelectedFeeling(currentFeeling);
  };

  const shareFeeling = async () => {
    setIsSubmit(true);
    const requestBody = {
      post: {
        postType: 'feeling',
        groupId: groupDetail?.id,
        feelingAttributes: {
          feelingTypeId: selectedFeeling?.id,
          feelingStickerId: selectedFeeling.imgId,
          feelingReason: selectedReason.title
        }
      }
    };

    const { isSuccess } = await submitPost(requestBody);

    if (isSuccess) {
      eventOnClick(true);
      const properties = {
        feeling: selectedFeeling.title,
        reason: selectedReason.title,
        'change sticker': isChangeSticker,
        'group name': groupDetail?.name,
        'group type': groupDetail?.groupType,
        'post from inside group': true
      };

      trackEvent({
        event: 'share feeling',
        eventProperties: properties,
        env: 'culture'
      });
    }
  };

  const showOtherReason = () => {
    setCurrentSection('other-reason');
    setIsButtonShow(true);
  };

  const setFunction = () => {
    if (currentSection === 'other-reason') {
      return chooseReason(selectedReason);
    } else {
      return shareFeeling();
    }
  };

  const changeCustomReason = (e) => {
    if (e.target.value.length <= 30) {
      setSelectedReason({ id: null, title: e.target.value });
    }
  };

  useEffect(() => {
    getFeelingList();
  }, []);

  return (
    <ModalPostTemplate
      dataCy="modal-post-feeling"
      modalTitle={getCultureLocale("How's your feeling?").toUpperCase()}
      isButton={isButtonShow}
      buttonText={
        currentSection === 'other-reason'
          ? `${getCultureLocale('Next')}`
          : `${getCultureLocale('Share')}`
      }
      isButtonDisabled={isSubmit}
      onClickButton={setFunction}
      eventOnClick={eventOnClick}
      className="modal-post-feeling"
      dataCyButton="share-feeling"
      isSubmit={isSubmit}
    >
      <div className="content">
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            {currentSection === 'feelings' && (
              <div className="feeling-list overflow-y" data-cy="feelings">
                {feelings.map((feeling) => {
                  return (
                    <div
                      className="feeling-item"
                      data-cy="feeling-item"
                      key={feeling?.id}
                      style={{ backgroundColor: `#${feeling.colorCode}` }}
                      onClick={(e) => {
                        e.stopPropagation();
                        chooseFeeling(feeling);
                      }}
                    >
                      <AWSImage src={feeling.defaultSticker.secureUrl} />
                      <span className="typography-h700">{feeling.title}</span>
                    </div>
                  );
                })}
              </div>
            )}
            {currentSection === 'reasons' && (
              <div
                className="reasons"
                style={{ backgroundColor: `#${selectedFeeling.colorCode}` }}
                data-cy="reasons"
              >
                <div className="selected-feeling">
                  <AWSImage
                    src={selectedFeeling.imgUrl}
                    dataCy="selected-feeling-image"
                  />
                  <span
                    className="typography-h700"
                    data-cy="selected-feeling-title"
                  >
                    {selectedFeeling.title}
                  </span>
                </div>
                <div className="reason-list overflow-y">
                  {reasons.map((reason) => {
                    return (
                      <div
                        className="reason-item typography-h100"
                        data-cy="reason-item"
                        key={reason?.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          chooseReason(reason);
                        }}
                      >
                        {reason.title}
                      </div>
                    );
                  })}
                  <div
                    className="reason-item typography-h100"
                    data-cy="reason-item"
                    key="other-reason"
                    onClick={(e) => {
                      e.stopPropagation();
                      showOtherReason();
                    }}
                  >
                    {getCultureLocale('Other reason...')}
                  </div>
                </div>
              </div>
            )}
            {currentSection === 'other-reason' && (
              <>
                <div className="other-reason" data-cy="other-reason">
                  <textarea
                    placeholder={getCultureLocale("What's happening?")}
                    onChange={(e) => {
                      e.stopPropagation();
                      changeCustomReason(e);
                    }}
                    value={selectedReason.title}
                  />
                  <div ref={emojiRef}>
                    <SVGIcon
                      iconName="icon-insert_emoticon"
                      customClass="absolute bottom-[0px] right-[15px]"
                      size={18}
                      fillColor="var(--n-600)"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsEmojiCartShow(true);
                      }}
                    />
                    {isEmojiCartShow && (
                      <Picker onSelect={addEmoji} data={emoticon} />
                    )}
                  </div>
                </div>
                <div className="footer-other-reason">
                  {selectedReason.title ? selectedReason.title.length : 0} / 30
                </div>
              </>
            )}
            {currentSection === 'stickers' && (
              <div className="stickers" data-cy="stickers">
                <PostFeeling
                  postId={selectedFeeling?.id}
                  stickerUrl={selectedSticker}
                  content={contentPostFeeling}
                  groupDetail={groupDetail}
                />
                <div className="sticker-list overflow-y">
                  {stickers.map((sticker) => {
                    return (
                      <div
                        className="sticker-item"
                        data-cy="sticker-item"
                        key={sticker?.id}
                      >
                        <AWSImage
                          src={sticker.secureUrl}
                          onClick={(e) => {
                            e.stopPropagation();
                            changeSticker(sticker);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </ModalPostTemplate>
  );
}

export default ModalPostFeeling;

import React from 'react';

import { useLocale } from 'hooks/useLocale';

import DateSelector from 'components/surveys/survey-schedule/fragments/DateSelector';

const ScheduleRow = ({ isReadOnly, id, changeDateList, date }) => {
  const { setBothLocale } = useLocale();

  const callbackChangeStartDate = (dateValue) => {
    const tempSelectedDate = new Date(dateValue);
    const tempDueDate = new Date(
      tempSelectedDate.setDate(tempSelectedDate.getDate() + 1)
    ).toISOString();
    const duedate =
      new Date(dateValue) > new Date(date.due) ? tempDueDate : date.due;
    const resultDate = { start: dateValue, due: duedate };
    changeDateList(resultDate, id);
  };

  const callbackChangeDueDate = (dateValue) => {
    const resultDate = { start: date.start, due: dateValue };
    changeDateList(resultDate, id);
  };

  return (
    <div className="mb-[12px] flex flex-row">
      <div className="start flex flex-col">
        <p className="typography-h100 text-n-800">
          {setBothLocale('Start Date')}
        </p>
        <div className="flex flex-row items-center mt-[8px] relative">
          <DateSelector
            isReadOnly={isReadOnly}
            id="start"
            selectedDate={date.start}
            callbackFn={callbackChangeStartDate}
          />
          <p className="px-[13px] typography-h500 text-n-900">-</p>
        </div>
      </div>
      <div className="start flex flex-col">
        <p className="typography-h100 text-n-800">
          {setBothLocale('End Date')}
        </p>
        <div className="flex flex-row items-center mt-[8px] relative">
          <DateSelector
            isReadOnly={isReadOnly}
            id="due"
            selectedDate={date.due}
            callbackFn={callbackChangeDueDate}
            start={date.start}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleRow;

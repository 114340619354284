import React from 'react';

import { getCultureLocale } from 'utils/HelperUtils';

import LoadingComponent from 'components/shared/LoadingComponent';
import Modal from 'components/shared/modal/Modal';

const ModalConfirmationDialogue = ({
  isAdminPost,
  isSubmit,
  setShowModalConfirmation,
  sharePost
}) => {
  const modalCaption = !isAdminPost
    ? 'Official Post requires you to Post as Admin, so this will be posted without company theme'
    : 'The contents required for Official post is missing (images, videos, or caption), so this will be posted without the company theme';
  const modalHeight = !isAdminPost ? 'h-[215px]' : 'h-[251px]';

  return (
    <Modal
      title="Post without company theme?"
      headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
      description={getCultureLocale(modalCaption)}
      className={`max-w-[400px] ${modalHeight}`}
      withCloseIcon={false}
      withPrimaryBtn={{
        title: 'No, Edit Post',
        dataCy: 'cancel-post-template',
        onClick: () => setShowModalConfirmation(false)
      }}
      withSecondaryBtn={{
        title: isSubmit ? (
          <LoadingComponent hasText={false} />
        ) : (
          'Yes, Post Anyway'
        ),
        onClick: () => (setShowModalConfirmation(false), sharePost(true)),
        dataCy: 'share-post-from-modal-confirm'
      }}
    />
  );
};

export default ModalConfirmationDialogue;

import React, { useEffect, useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import RangeSlider from 'components/slider/RangeSlider';

import SuggestedScore from './custom-attributes/SuggestedScore';

const RatingSlider = ({
  options,
  funcActiveScore,
  sliderLabel,
  showOption,
  activeScore,
  disabled,
  loading,
  suggestedScoreData,
  objectiveCount
}) => {
  const [value, setValue] = useState(null);
  const [activeLabel, setActiveLabel] = useState(null);

  const { leftLabel, centerLabel, rightLabel } = sliderLabel || {};

  const scoreOptions = [];

  options?.length > 0 &&
    options?.map((option) => {
      scoreOptions.push(option.score);
    });

  const sortedScores = scoreOptions.sort((a, b) => a - b);

  const onChangeSlider = (newValue) => {
    if (!newValue) return;

    let filteredScore = [];
    let filteredOption = [];
    let newScore = '';

    // get all score that is less than newValue and select the last one
    if (newValue < value) {
      filteredScore = sortedScores.filter((score) => score <= newValue);
      newScore = filteredScore[filteredScore.length - 1];
      filteredOption = options?.filter(
        (option) => option.score === newScore
      )[0];

      setValue(newScore);
      setActiveLabel(filteredOption.name);
    } else {
      // get all score that is greater than newValue and select the first one
      filteredScore = sortedScores.filter((score) => score >= newValue);
      newScore = filteredScore[0];
      filteredOption = options?.filter(
        (option) => option.score === newScore
      )[0];

      setValue(newScore);
      setActiveLabel(filteredOption.name);
    }
  };

  const onChangeComplete = () => {
    const optionId = options?.filter((option) => option?.score === value)?.[0]
      ?.id;
    funcActiveScore(optionId);
  };

  useEffect(() => {
    const selectedOption = options?.filter(
      (option) => option.id === activeScore
    )?.[0];
    setValue(selectedOption?.score || null);
    setActiveLabel(selectedOption?.name || null);
  }, [activeScore]);

  return (
    <div className="bg-n-200 px-[16px] py-[16px] rounded-[4px] border border-solid border-n-300 relative">
      <div className="flex items-end justify-between">
        {leftLabel && (
          <span
            className={`typography-h100 text-n-600 mr-[16px] ${
              centerLabel ? 'w-1/3' : 'w-1/2'
            }`}
          >
            {leftLabel}
          </span>
        )}
        {centerLabel && (
          <span className="typography-h100 text-n-600 mr-[16px] text-center w-1/3">
            {centerLabel}
          </span>
        )}
        {rightLabel && (
          <span
            className={`typography-h100 text-n-600 text-right ${
              centerLabel ? 'w-1/3' : 'w-1/2'
            }`}
          >
            {rightLabel}
          </span>
        )}
      </div>
      <div className="my-[18px]">
        <RangeSlider
          handleChange={(newValue) => onChangeSlider(newValue)}
          onChangeComplete={onChangeComplete}
          value={value}
          min={sortedScores?.[0]}
          max={sortedScores[sortedScores?.length - 1]}
          disabled={disabled}
        />
      </div>
      <div className="flex">
        {!value && (
          <div className="flex justify-center items-center w-full">
            <SVGIcon
              size="24"
              iconName={'icon-info'}
              fillColor={'var(--b-600)'}
              customClass="mr-[8px]"
            />
            <p className="typography-h600 text-b-600">
              {getObjectiveLocale(
                'Click and move slider to rate this attributes'
              )}
            </p>
          </div>
        )}
        {value && (
          <>
            {(showOption === 'score_and_label' ||
              showOption === 'score_only') && (
              <span className="typography-h600 text-n-900 mr-[16px]">
                {value}
              </span>
            )}
            {(showOption === 'score_and_label' ||
              showOption === 'label_only') && (
              <p className="typography-paragraph text-n-800">{activeLabel}</p>
            )}
          </>
        )}
      </div>
      {suggestedScoreData && (
        <SuggestedScore
          showOption={showOption}
          label={activeLabel}
          data={suggestedScoreData}
          objectiveCount={objectiveCount}
        />
      )}
      {loading && (
        <div className="absolute h-full w-full flex-center-center top-0 left-0 opacity-5 bg-n400">
          <LoadingComponent />
        </div>
      )}
    </div>
  );
};

export default RatingSlider;

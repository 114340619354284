import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import ListEmptyState from 'components/shared/ListEmptyState';

function ProjectEmptyState({
  isAbleToCreate,
  isSearch,
  setOpenModalCreate,
  isArchived
}) {
  let titleText, subtitle, btnText;
  if (isSearch) {
    titleText = 'No Objective Found';
    subtitle =
      'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.';
  } else if (isArchived) {
    titleText = 'This squad didn’t have any project';
    subtitle =
      'This squad was archived and has no projects.\n Reactivate this squad to create a team project.';
  } else {
    titleText = "You don't have any project yet";
    subtitle = 'Align your project into bigger objective.';
    isAbleToCreate && (btnText = 'Create New Project');
  }

  return (
    <ListEmptyState
      emptyIcon="icon-no-result-found"
      size="large"
      fullHeight
      containerClassname="flex aligns-center justify-center h-[calc(100vh-183px)]"
      title={getObjectiveLocale(titleText)}
      subtitle={getObjectiveLocale(subtitle)}
      btnText={getObjectiveLocale(btnText)}
      iconBtn="icon-add"
      onClick={() => setOpenModalCreate(true)}
    />
  );
}

export default ProjectEmptyState;

import React from 'react';

import isNil from 'lodash/isNil';

import { getObjectiveLocale } from 'utils/HelperUtils';

import DecimalHoverable from './DecimalHoverable';

function SPComponent({ spName, spData, scoreModifierConfig }) {
  const getColor = (data) => {
    if (data >= 0) return 'text-n-900';
    return 'text-r-500';
  };

  // we don't give 0 as default value for score because
  // in score modifier this key will not given and should not rendered at all
  let score = spData.score;
  let weight = spData.weight || 0;
  let weightedScore = spData.weightedScore || 0;

  let displayWeight = weight;

  if (scoreModifierConfig == 'score_modifier') {
    // for score modifier weight that is given by backend is number in percentage
    // hence we need to append '%' when rendering
    displayWeight += '%';
  } else if (scoreModifierConfig == 'sp_reduction') {
    // all number given by backend means negative value if the config is sp_reduction
    score = -Math.abs(score);
    weight = -Math.abs(weight);
    weightedScore = -Math.abs(weightedScore);
  }

  // if(scoreModifierConfig == "score_modifier") displayWeight += "%"
  return (
    <div className="grid grid-assessment py-[10px] border-bottom-300">
      <p className="typography-h100 text-n-900">{getObjectiveLocale(spName)}</p>
      <p className={`text-center typography-h200 ${getColor(score)}`}>
        {isNil(score) ? '' : score}
      </p>
      <p className={`text-center typography-h200 ${getColor(weight)}`}>
        {displayWeight}
      </p>
      <DecimalHoverable
        number={weightedScore}
        textClass={`text-right typography-h200 ${getColor(weightedScore)}`}
      />
    </div>
  );
}

export default SPComponent;

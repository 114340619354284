import React, { createRef, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import { getListObjectives } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { restructureFilter } from 'utils/ObjectivesHelper';

import ProjectEmptyState from 'components/project/ProjectEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';

import CardAddProject from './CardAddProject';
import ProjectCard from './ProjectCard';
import './ProjectCardView.scss';

const ProjectCardView = ({
  setOpenModalCreateProject,
  addedParams,
  isAbleToCreate,
  isArchived = false
}) => {
  const [objectives, setObjectives] = useState([]);

  const { user } = useUser();
  const filter = useFilter((state) => state.filterById?.projectFilter);

  let params = restructureFilter(filter);

  const fetchObjectives = async (olderThan) => {
    let allParams = {
      reviewsVisibility: 1,
      limit: 15,
      isProject: true,
      ...(olderThan && { olderThan: olderThan }),
      ...params,
      ...addedParams
    };

    !addedParams && (allParams.involvedUserId = user.id);
    const allEmployee = filter?.owner?.ownerType === 'all_employees';

    if (allEmployee) {
      delete allParams.involvedUserId;
    }

    return getListObjectives(allParams);
  };

  const queryKey = ['objectives', 'myprojects', { ...params, ...addedParams }];

  const { hasNextPage, isFetchingNextPage, fetchNextPage, remove, isFetching } =
    useInfiniteQuery(queryKey, ({ pageParam }) => fetchObjectives(pageParam), {
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || undefined,
      onSuccess: (data) => {
        const newObjectives = flatten(
          data?.pages?.map((page) => {
            return page?.data?.map((d) => {
              return d;
            });
          })
        );

        setObjectives(newObjectives);
      }
    });

  useEffect(() => {
    return () => remove();
  }, [remove]);

  const intersectTarget = createRef();

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting &&
      hasNextPage &&
      !isFetchingNextPage &&
      fetchNextPage(),
    threshold: 0.5
  });

  return (
    <>
      {isFetching && !isFetchingNextPage && objectives?.length === 0 && (
        <LoadingComponent />
      )}
      {objectives?.length > 0 && (
        <div className="project-card-view-container !pb-[40px]">
          {objectives?.map((objective) => (
            <ProjectCard objective={objective} key={objective?.id} />
          ))}
          <div ref={intersectTarget}>
            {isAbleToCreate && (
              <CardAddProject setOpenModalCreate={setOpenModalCreateProject} />
            )}
          </div>
          {!isFetching && isFetchingNextPage && <LoadingComponent />}
        </div>
      )}

      {!isFetching && objectives?.length == 0 && (
        <ProjectEmptyState
          setOpenModalCreate={setOpenModalCreateProject}
          isAbleToCreate={isAbleToCreate}
          isSearch={!isEmpty(filter?.q)}
          isArchived={isArchived}
        />
      )}
    </>
  );
};

export default ProjectCardView;

import React from 'react';

import {
  daysLeftInQuarter,
  getQuarterRangeMonth
} from '../utils/quarterHelper';

const GanttTopHeaderQuarterly = ({ daysOfYear }) => {
  let ident = false;
  return (
    <>
      {daysOfYear.map(({ date, id }, index) => {
        if (ident == false && index == 0) {
          const dayLeft = daysLeftInQuarter(new Date(date));
          ident = true;
          const width = 6 * dayLeft;
          return (
            <div
              key={'week' + id}
              className="flex h-[30px] justify-center items-center"
              style={{
                borderRight: '1px solid var(--n-300)',
                width: width + 'px'
              }}
            >
              Q{Math.floor((date.getMonth() + 3) / 3)} (
              {getQuarterRangeMonth(Math.floor((date.getMonth() + 3) / 3))})
            </div>
          );
        }
        if (date.getDate() == 1 && (date.getMonth() + 3) % 3 == 0) {
          const dayLeft = daysLeftInQuarter(new Date(date));

          if (daysOfYear.length - index < 90) {
            return;
          }

          return (
            <div
              key={'week' + id}
              className="flex h-[30px] justify-center items-center"
              style={{
                borderRight: '1px solid var(--n-300)',
                width: 6 * dayLeft + 'px'
              }}
            >
              Q{Math.floor((date.getMonth() + 3) / 3)} (
              {getQuarterRangeMonth(Math.floor((date.getMonth() + 3) / 3))})
            </div>
          );
        }
      })}
    </>
  );
};

export default GanttTopHeaderQuarterly;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from 'context/UserContext';

import ListObjectivesNew from 'components/objectives/ListObjectivesNew';
import ListObjectivesReview from 'components/objectives/ListObjectivesReview';
import CardNew from 'components/shared/card/Card';

import CompactObjective from './compact-objective/CompactObjective';

function ObjectiveCardModel({
  cardModel,
  objective,
  parent,
  isParent,
  showBadgeObjectiveCount,
  isForceNewLayout,
  ...rest
}) {
  const history = useHistory();
  const {
    config: { newObjectiveLayout }
  } = useUser();

  return (
    <>
      {(cardModel === 'project' || cardModel === 'team') && (
        <CardNew
          objective={objective}
          withInvolvement
          withRightIcon
          type={cardModel}
          customClass="animate-card mb-[25px]"
          onClickCard={() =>
            history.replace(`${history.location.pathname}/${objective.id}`)
          }
          isParent={isParent}
          {...rest}
        />
      )}

      {cardModel === 'goal-card' && (
        <CardNew
          objective={objective}
          withRightIcon
          type="goal"
          customClass="animate-card mb-[25px]"
          onClickCard={() =>
            history.replace(`${history.location.pathname}/${objective.id}`)
          }
          isParent={isParent}
          {...rest}
        />
      )}

      {cardModel?.includes('goal-list') &&
        (newObjectiveLayout || isForceNewLayout ? (
          <div className={isParent ? 'compact-border' : ''}>
            <CompactObjective
              objective={objective}
              cardModel={cardModel}
              isParent={isParent}
              parent={parent}
              showBadgeObjectiveCount={showBadgeObjectiveCount}
              isForceNewLayout={isForceNewLayout}
              {...rest}
            />
          </div>
        ) : (
          <ListObjectivesNew
            objective={objective}
            cardModel={cardModel}
            isParent={isParent}
            showBadgeObjectiveCount={showBadgeObjectiveCount}
            {...rest}
          />
        ))}

      {cardModel === 'formal-review' && (
        <ListObjectivesReview
          objective={objective}
          cardModel={cardModel}
          isParent={isParent}
          {...rest}
        />
      )}

      {cardModel === 'checkin-list' && (
        <ListObjectivesNew
          objective={objective}
          cardModel={cardModel}
          isParent={isParent}
          sidebar={false}
          {...rest}
        />
      )}
    </>
  );
}

export default ObjectiveCardModel;

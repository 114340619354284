import React from 'react';

import isNil from 'lodash/isNil';

import SVGIcon from 'components/shared/SVGIcon';

const CompetencyScoringList = ({
  object,
  answers,
  showCompetencyLevelSidebar
}) => {
  return (
    <div
      key={object.id}
      className="animated-half fadeInDown-listemployee"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="competency-assessment-list-feedback border-bottom-400">
        <div className="upper-ca-container">
          <h6 className="ca-title">{object.title}</h6>
          <SVGIcon
            iconName="icon-help"
            customClass="help-circled-question"
            fillColor="var(--n-600)"
            onClick={(e) =>
              showCompetencyLevelSidebar(
                object.title,
                object.description,
                object.id,
                e
              )
            }
          />
          <span className="level-text">{answers?.[0]?.option?.name}</span>
        </div>
        <p className="ca-expected-level mt-[4px]">
          {!isNil(object?.expectedLevel) &&
            `Expected Level ${object?.expectedLevel + 1}`}
        </p>
        {answers?.[0].comment && (
          <p className="typography-paragraphy typography-secondary mt-[8px]">
            &quot;{answers?.[0].comment}&quot;
          </p>
        )}
      </div>
    </div>
  );
};

export default CompetencyScoringList;

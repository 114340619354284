import React, { useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import Toggle from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import PeerSelectionMode from './components/PeerSelectionMode';
import PeersNumber from './components/PeersNumber';

const PhasePeerSelection = ({
  configPeerSelection,
  activePeerSelection,
  onChangePeerConfig,
  reviewEachOtherDefaultValue,
  togglePhase,
  cycleData
}) => {
  const [eachToggleValue, setEachToggleValue] = useState(
    reviewEachOtherDefaultValue || false
  );

  const eachToggleChange = () => {
    togglePhase('peerReview', {
      ...cycleData,
      reviewForEachOther: !eachToggleValue
    });
    setEachToggleValue(!eachToggleValue);
  };

  return (
    <div className="border border-solid border-n-400 my-[24px] py-[16px] rounded-[4px] bg-n-000">
      <PeerSelectionMode
        configPeerSelection={configPeerSelection}
        activePeerSelection={activePeerSelection}
        onChangePeerConfig={onChangePeerConfig}
        lockedDefaultValue={cycleData.allowLockedByManager}
        togglePhase={togglePhase}
        cycleData={cycleData}
      />
      <div className="flex px-[16px] border-solid border-n-400 border-0 border-t mt-[8px] pt-[16px]">
        <PeersNumber
          label="Minimum Peers"
          tooltipText="Minimum number of people user can add as their Peer Reviewer"
          defaultValue={cycleData.minimumPeerCount}
          inputFlag="minimumPeerCount"
          id="peer-count"
          togglePhase={togglePhase}
          cycleData={cycleData}
        />
        <PeersNumber
          label="Maximum Peers"
          tooltipText="Maximum number of people user can add as their Peer Reviewer"
          defaultValue={cycleData.maximumPeerCount}
          defaultToggle={cycleData.maximumPeerCountEnabled}
          toggleFlag="maximumPeerCountEnabled"
          inputFlag="maximumPeerCount"
          id="peer-count"
          togglePhase={togglePhase}
          cycleData={cycleData}
          useToggle
        />
        <PeersNumber
          label="Availability Quota"
          tooltipText="Limit user availability to be selected as a Peer Reviewer"
          defaultValue={cycleData.maximumPeerSelected}
          defaultToggle={cycleData.maximumPeerSelectedEnabled}
          toggleFlag="maximumPeerSelectedEnabled"
          inputFlag="maximumPeerSelected"
          id="peer-quota"
          togglePhase={togglePhase}
          cycleData={cycleData}
          useToggle
        />
      </div>
      <div className="flex space-between px-[16px] pt-[16px] border-solid border-n-400 border-0 border-t mt-[17px]">
        <div className="flex flex-row items-center">
          <p className="typography-paragraph-text-n-900">
            {getObjectiveLocale('Set employee to review each other')}
          </p>
          <TooltipContainer
            show
            position="top"
            text={getObjectiveLocale(
              'Person that user add as their Peer Reviewer will be also get reviewed by them'
            )}
            useMaxWidth={false}
          >
            <SVGIcon
              iconName={'icon-info'}
              size="16"
              fillColor="var(--n-600)"
              customClass="ml-[4px]"
            />
          </TooltipContainer>
        </div>
        <Toggle
          isChecked={eachToggleValue}
          idStr="toggle-each-other-peers"
          onChange={eachToggleChange}
        />
      </div>
    </div>
  );
};

export default PhasePeerSelection;

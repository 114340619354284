import React, { useReducer, useState } from 'react';
import { useQuery } from 'react-query';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isEmpty from 'lodash/isEmpty';

import { getListObjectives } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import { restructureFilter } from 'utils/ObjectivesHelper';
import { useDeepEffect } from 'utils/useDeepEffect';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import Table from 'components/design-system/table/Table';
import ProjectEmptyState from 'components/project/ProjectEmptyState';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';

import ProjectRow from './ProjectRow';

dayjs.extend(relativeTime);

const LIMIT = 15;

const reducer = (state, action) => {
  const { type } = action;
  const { olderThan, newerThan } = action.payload || {};
  const newState = { ...state };

  switch (type) {
    case 'next': {
      newState?.newerThan && delete newState?.newerThan;

      return {
        ...newState,
        olderThan: olderThan
      };
    }

    case 'prev': {
      newState?.olderThan && delete newState?.olderThan;

      if (newerThan) {
        newState.newerThan = newerThan;
      } else {
        newState?.newerThan && delete newState?.newerThan;
      }

      return newState;
    }

    case 'filter': {
      newState?.newerThan && delete newState?.newerThan;
      newState?.olderThan && delete newState?.olderThan;

      return newState;
    }

    default: {
      return newState;
    }
  }
};

const ProjectListView = ({
  addedParams,
  setOpenModalCreateProject,
  isAbleToCreate,
  isArchived = false
}) => {
  const filter = useFilter((state) => state.filterById?.projectFilter);
  let params = restructureFilter(filter);

  const { user } = useUser();

  const [queryParams, dispatchQueryParams] = useReducer(reducer, {
    reviewsVisibility: 1,
    limit: LIMIT,
    isProject: true
  });

  const [objectives, setObjectives] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [olderThan, setOlderThan] = useState(null);
  const [newerThan, setNewerThan] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  const headers = [
    { name: 'Project Name', widthClass: 'w-[424px]', grow: true },
    { name: 'Leader / Team', widthClass: 'w-[280px]' },
    { name: 'Members', widthClass: 'w-[136px]' },
    { name: 'Status', widthClass: 'w-[120px]' },
    { name: 'Last Updated', widthClass: 'w-[144px]' },
    { name: '', widthClass: 'w-[56px]' }
  ];

  const getQueryParams = () => {
    const allParams = {
      ...queryParams,
      ...params,
      ...addedParams
    };

    if (!addedParams) {
      allParams.involvedUserId = user.id;
    }

    const allEmployee = filter?.owner?.ownerType === 'all_employees';
    if (allEmployee) {
      delete allParams.involvedUserId;
    }

    return allParams;
  };

  const fetchObjectives = () => {
    const queryParams = getQueryParams();

    return getListObjectives(queryParams);
  };

  const queryKey = ['objectives', 'myprojects', getQueryParams()];

  const { isFetching } = useQuery(queryKey, fetchObjectives, {
    onSuccess: (data) => {
      if (data?.data) {
        setObjectives(data?.data);
        setOlderThan(data?.pagination?.next?.olderThan);
        setNewerThan(data?.pagination?.prev?.newerThan);
        setHasMore(data?.data?.length === LIMIT);
      }
    }
  });

  const handleNextPage = () => {
    dispatchQueryParams({
      type: 'next',
      payload: {
        olderThan: olderThan
      }
    });
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    dispatchQueryParams({
      type: 'prev',
      payload: {
        newerThan: currentPage - 1 === 1 ? null : newerThan
      }
    });
    setCurrentPage(currentPage - 1);
  };

  useDeepEffect(() => {
    dispatchQueryParams({
      type: 'filter'
    });
    setCurrentPage(1);
  }, [params]);

  return (
    <>
      {!isFetching && objectives?.length === 0 ? (
        <ProjectEmptyState
          setOpenModalCreate={setOpenModalCreateProject}
          isAbleToCreate={isAbleToCreate}
          isSearch={!isEmpty(filter?.q)}
          isArchived={isArchived}
        />
      ) : (
        <>
          <Table
            headers={headers}
            fixedLeftCount={1}
            fixedRightCount={1}
            customHeaderStickyContainerCN="bg-n-200 z-[1]"
          >
            {isFetching
              ? [...new Array(10)].map((_, index) => (
                  <Table.Row key={index} customStickyContainerCN="bg-n-200">
                    {headers.map((_, index) => (
                      <Table.Column key={index} customClass="bg-n-200">
                        <Shimmer height="20px" widthRandomness={0.25} circle />
                      </Table.Column>
                    ))}
                  </Table.Row>
                ))
              : objectives?.length > 0 &&
                objectives?.map((objective) => (
                  <ProjectRow key={objective?.id} objective={objective} />
                ))}
          </Table>

          <div className="flex justify-end items-center pb-[40px] pt-[24px]">
            <PaginationModule
              pageNumber={currentPage}
              handlePrev={() => handlePrevPage('prev')}
              handleNext={() => handleNextPage('next')}
              hasMore={hasMore}
              loading={isFetching}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ProjectListView;

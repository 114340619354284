import React from 'react';

import Avatar from 'components/shared/Avatar';
import Modal from 'components/shared/modal/Modal';
import { getExtraInformation } from 'src/utils/HelperUtils';

const ModalOwnerList = ({ titleModal, eventOnClick, goalOwners }) => {
  const modalProps = {
    title: titleModal,
    withCloseIcon: true,
    eventOnClickClose: eventOnClick,
    className: 'w-[500px]',
    dataCyModal: 'owner-list-modal',
    contentWrapperClass: 'overflow-auto',
    useBorder: true,
    maxHeight: 500,
    withFooter: false
  };

  return (
    <Modal {...modalProps}>
      <div className="mt-[12px]">
        {goalOwners.map((data, index) => (
          <div className="flex flex-row pb-[24px] items-center" key={index}>
            <Avatar
              src={data.user.profilePicture}
              name={data.user.name}
              size={40}
              className="mr-[16px]"
              dataCy="list-detail-owner-image"
            />
            <div>
              <p className="typography-h400 truncate">{data.user.name}</p>
              <p className="typography-h100 typography-secondary truncate">
                {getExtraInformation(data?.user?.title, data?.user?.subtitle)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ModalOwnerList;

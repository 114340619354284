import React, { useEffect, useState } from 'react';

import { getFormerDirectReport } from 'client/ObjectivesClient';
import { ColleagueProvider } from 'context/ColleagueContext';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import Switcher from 'components/advance-filter/Switcher';
import Objectives from 'components/objectives/Objectives';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

import { generatePeerFeedback } from '../client/FormalReviewClient';
import GeneratePeerFeedbackModal from '../components/modal/GeneratePeerFeedbackModal';

function DirectReport({ route: { title } }) {
  const { config, user } = useUser();
  const { match } = useUrl();
  const [directSwitch, setDirectSwitch] = useState('');
  const [formerData, setFormerData] = useState([]);
  const [target, setTarget] = useState(false);
  const [showModal, setShowModal] = useState(false);

  let filter = {
    phase: ['running', 'completed']
  };

  const _getFormerDirectReport = async () => {
    const params = {
      state: 'inactive',
      placementId: user?.placementId
    };
    const { data } = await getFormerDirectReport(user.id, params);
    if (data) {
      setFormerData(data);
    }
  };

  const onHandleCloseModal = () => {
    setShowModal(false);
  };

  const onHandleSubmitModal = async (selectedUsers) => {
    const body = {
      targetId: [target.id],
      actorIds: selectedUsers.map((user) => user.id)
    };

    const { data } = await generatePeerFeedback(body);
    if (data) {
      setShowModal(false);
    }
  };

  let switchOptions = [
    { id: 'current', name: getObjectiveLocale('Current') },
    { id: 'former', name: getObjectiveLocale('Previous') }
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const placementType = urlParams.get('placement');
    setDirectSwitch(placementType ? placementType : 'current');
    _getFormerDirectReport();
  }, []);

  return (
    <>
      <HeaderPage titlePage={title} />
      {directSwitch && (
        <div className="pb-[40px] bg-n-100 mt-[24px] min-h-[calc(100vh-64px)]">
          {formerData.length > 0 && (
            <Switcher
              options={switchOptions}
              onChange={(type) => setDirectSwitch(type)}
              value={directSwitch}
              customClass="mt-[0px] height-fit-content mb-[16px]"
            />
          )}
          {directSwitch === 'current' ? (
            <AdvancedFilterObjectives
              filterOptions={['search', 'sort', 'dateFilter', 'menuFilter']}
              defaultFilter={filter}
              group={'direct-report'}
              page="directReport"
              customClass="mb-[24px]"
              filterId="directReportFilter"
            >
              <Objectives
                cardModel={'goal-list'}
                page={'directReport'}
                switchType={directSwitch}
                group={'direct-report'}
                useFetchObjective={directSwitch === 'current'}
              />
            </AdvancedFilterObjectives>
          ) : (
            <Objectives
              cardModel={'goal-list'}
              page={'directReport'}
              switchType={directSwitch}
              group={'direct-report'}
              useFetchObjective={directSwitch === 'current'}
            />
          )}
        </div>
      )}

      {showModal && (
        <>
          <ColleagueProvider>
            <GeneratePeerFeedbackModal
              target={target}
              onHandleCloseModal={onHandleCloseModal}
              onHandleSubmitModal={onHandleSubmitModal}
            />
          </ColleagueProvider>
        </>
      )}

      <React.Suspense fallback={<div></div>}>
        {match && <SidebarMyGoals />}
      </React.Suspense>
    </>
  );
}

export default DirectReport;

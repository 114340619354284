import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { getListFilter } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { useUsersAdmin } from 'context/admin/UsersAdminContext';
import { UsersAdminProvider } from 'context/admin/UsersAdminContext';
import useFilter from 'hooks/useFilter';

import NewManageUserTable from 'components/admin/NewManageUserTable';
import ModalShowMember from 'components/admin/groups/ModalShowMember.js';
import UserTableInfo from 'components/admin/groups/UserTableInfo.js';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';

const TableUser = ({
  isSelectAll,
  setIsSelectAll,
  isSelectAllRow,
  setIsSelectAllRow,
  listIdUser,
  setListIdUser,
  excludedId,
  setExcludedId,
  saveStatus
}) => {
  const { cycleData, changeIncludesIds, changeSelectAll, changeExcludeIds } =
    useCreateCycle();

  const { hasMore, totalUser, users, getUsersData, nextPage, prevPage } =
    useUsersAdmin();

  const { config } = useUser();

  const filter = useFilter((state) => state.filterById?.employeeTableFilter);
  const [isLoading, setIsLoading] = useState(true);
  const [showModalMembers, setShowModalMembers] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({
    sortColumn: 'name',
    sortDirection: 'asc'
  });

  const getUserParams = () => {
    const { ...allFilter } = filter || {};
    const query = {
      ...allFilter,
      state: ['active', 'pending'],
      limit: 10,
      sortDirection: currentSort.sortDirection,
      sortColumn: currentSort.sortColumn
    };

    return query;
  };

  const getFilterData = async (addedParams) => {
    return await getListFilter({ q: addedParams?.q, key: addedParams?.key });
  };

  const _getListFilter = (addedParams) => {
    const fetchFilter = () => {
      return getListFilter(addedParams);
    };

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['filter', addedParams],
        ({ pageParam }) => fetchFilter(pageParam),
        {
          getNextPageParam: (lastPage, allPages) =>
            lastPage.pagination?.next || 0
        }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  // END OF FILTER

  const _getUsersData = async (data) => {
    let body = {
      ...data,
      ...getUserParams()
    };
    setIsLoading(true);
    await getUsersData(body, false, 'totalAvailableUsers');
    setIsLoading(false);
  };

  const _prevPage = () => {
    setCurrentPage(currentPage - 1);
    prevPage(getUserParams());
  };

  const _nextPage = () => {
    setCurrentPage(currentPage + 1);
    nextPage(getUserParams());
    setIsSelectAllRow(false);
  };

  const handleUnCheckUser = (tempListIdUser, user) => {
    let userIdTempArr = [user.id];
    tempListIdUser = tempListIdUser.filter((val) => val !== user.id);
    setIsSelectAllRow(false);

    const { selectAll, excludes } = cycleData;
    if (selectAll) {
      changeExcludeIds(excludes.concat(userIdTempArr));
      setExcludedId(excludes.concat(userIdTempArr));
    } else {
      changeIncludesIds(tempListIdUser);
    }
    setListIdUser(tempListIdUser);
  };

  const handleCheckUser = (tempListIdUser, user) => {
    tempListIdUser.push(user.id);
    if (tempListIdUser.length == users.length) {
      setIsSelectAllRow(true);
    }

    const { selectAll, excludes, includes } = cycleData;

    if (selectAll) {
      let userIdTempArr = [user.id];
      let newArray = excludes.filter(
        (value) => userIdTempArr.indexOf(value) == -1
      );
      changeExcludeIds(newArray);
    } else {
      let uniqueIds = new Set([...includes, ...tempListIdUser]);
      changeIncludesIds([...uniqueIds]);
    }

    setListIdUser(tempListIdUser);
  };

  const handleSelectUser = (e, user) => {
    let tempListIdUser = [...listIdUser];
    let checked = tempListIdUser.includes(user.id);

    checked
      ? handleUnCheckUser(tempListIdUser, user)
      : handleCheckUser(tempListIdUser, user);
  };

  const handleSelectAllUsers = (isTriggeredByUseEffect = false) => {
    let arrayOfIds = users?.map((user) => user.id);

    if (isTriggeredByUseEffect) {
      arrayOfIds = arrayOfIds.filter(
        (val) => !cycleData.excludes.includes(val)
      );
    } else {
      // Conditional runs when user clicks 'Select all ## employees' in UserTableInfo
      setExcludedId([]);
      setIsSelectAll(true);
      setIsSelectAllRow(true);

      // Update Context
      changeSelectAll(true);
      changeIncludesIds([]);
      changeExcludeIds([]);
    }

    setListIdUser([...new Set(arrayOfIds)]);
  };

  const handleSelectAllRow = (checked) => {
    let arrayOfIds = users?.map((user) => user.id);

    if (checked) {
      arrayOfIds = [...listIdUser, ...arrayOfIds];

      if (isSelectAll) {
        let newExcludeId = [...cycleData.excludes].filter(
          (val) => !arrayOfIds.includes(val)
        );
        setExcludedId(newExcludeId);
        changeExcludeIds(newExcludeId);
      } else {
        changeIncludesIds([...new Set(arrayOfIds)]);
      }
    }

    if (!checked) {
      if (isSelectAll) {
        let newExcludeId = [...cycleData.excludes, ...arrayOfIds];
        let filteredNewExcludeId = [...new Set(newExcludeId)];
        setExcludedId(filteredNewExcludeId);
        changeExcludeIds(filteredNewExcludeId);
        arrayOfIds = [...listIdUser].filter((val) => !arrayOfIds.includes(val));
      } else {
        arrayOfIds = [...listIdUser].filter((val) => !arrayOfIds.includes(val));
        changeIncludesIds([...new Set(arrayOfIds)]);
      }
    }

    setListIdUser([...new Set(arrayOfIds)]);
    setIsSelectAllRow(checked);
  };

  const resetFunction = () => {
    // Context
    changeSelectAll(false);
    changeIncludesIds([]);
    changeExcludeIds([]);

    // Local state
    setListIdUser([]);
    setExcludedId([]);
    setIsSelectAll(false);
    setIsSelectAllRow(false);
  };

  useEffect(() => {
    if (listIdUser.length != 0) {
      const isAllChildSelected = users?.every((user) =>
        listIdUser.includes(user.id)
      );
      setIsSelectAllRow(isAllChildSelected);
    }

    if (isSelectAll) {
      handleSelectAllUsers(true);
      const isAllChildSelected = users?.every(
        (user) => !cycleData.excludes.includes(user.id)
      );
      setIsSelectAllRow(isAllChildSelected ? true : false);
    }
  }, [users]);

  useEffect(() => {
    _getUsersData({ countAvailableUsers: true });
  }, []);

  // FILTER
  useEffect(() => {
    filter && _getUsersData({});
  }, [filter, currentSort]);

  useEffect(() => {
    if (cycleData.selectAll) {
      setIsSelectAll(true);
      setExcludedId(cycleData.excludes);
    } else {
      setListIdUser(cycleData.includes);
    }
  }, []);

  let filterMenu = config?.generalFilterOptions;

  return (
    <div className="review-group-user-table mt-[16px]">
      <AdvancedFilter
        filterOptions={['search', 'menuFilter']}
        filterMenu={filterMenu}
        dialogQueryFn={(params) => _getListFilter(params)}
        filterClient={getFilterData}
        id="employeeTableFilter"
      />

      <UserTableInfo
        setShowModal={setShowModalMembers}
        isSelectAll={isSelectAll}
        handleSelectAll={handleSelectAllUsers}
        listIdUser={listIdUser}
        totalLength={totalUser}
        excludedId={cycleData.excludes}
        resetFunction={resetFunction}
        totalSelectedUser={cycleData.totalSelectedUser}
        saveStatus={saveStatus}
      />

      <NewManageUserTable
        usersList={users}
        selectedUserIds={listIdUser}
        isHeaderChecked={isSelectAllRow}
        handleSelectUser={handleSelectUser}
        handleSelectAllRow={handleSelectAllRow}
        currentSort={currentSort}
        setCurrentSort={setCurrentSort}
        currentPage={currentPage}
        handleNextPage={_nextPage}
        handlePrevPage={_prevPage}
        hasMore={hasMore}
        isLoading={isLoading}
      />

      {showModalMembers && (
        <UsersAdminProvider>
          <ModalShowMember
            setShowModalMembers={setShowModalMembers}
            listIdUser={listIdUser}
            excludedId={excludedId}
            isSelectAll={isSelectAll}
          />
        </UsersAdminProvider>
      )}
    </div>
  );
};

const EmployeeTable = (props) => (
  <UsersAdminProvider>
    <TableUser {...props} />
  </UsersAdminProvider>
);

export default EmployeeTable;

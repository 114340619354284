import React from 'react';

import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';

import AnalyticsColumnName from './AnalyticsColumnName';
import ButtonAddColumn from './ButtonAddColumn';

const AnalyticsColumnSection = () => {
  const insights = useObjectiveComparative((state) => state.insights)?.sort(
    (a, b) => a.columnNumber - b.columnNumber
  );

  return (
    <div className="flex flex-row gap-[16px]">
      {insights?.length > 0 &&
        insights?.map((data, index) => (
          <AnalyticsColumnName key={index} data={data} />
        ))}

      <ButtonAddColumn isEmptyData={insights?.length === 0} />
    </div>
  );
};

export default AnalyticsColumnSection;

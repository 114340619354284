import client from '../ApiClient';

const getRevieweeList = query => {
  return client('admin/formal_reviews/reviewees', { params: query });
};

const getInclusions = (id, query) => {
  return client(`v1/formal_reviews/reviewees/${id}/grouped_inclusions`, {
    params: query
  });
};

const putInclusions = (id, cycleCategoryId, cyclePeriod, payload) => {
  const url = `v1/formal_reviews/reviewees/${id}/grouped_inclusions/${cycleCategoryId}/periods/${cyclePeriod}`;
  return client(url, { method: 'PUT', body: payload });
};

const getAssignmentsCycles = (id, query) => {
  return client(`admin/formal_reviews/cycles/${id}/assignments`, {
    params: query
  });
};

export { getRevieweeList, getInclusions, putInclusions, getAssignmentsCycles };

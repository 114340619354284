import React, { useState } from 'react';

import startCase from 'lodash/startCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

const options = [
  { id: 0, value: 'week' },
  { id: 1, value: 'month' },
  { id: 2, value: 'quarter' }
];

const TimeGroup = ({ defaultValue, onSelect }) => {
  const [selected, setSelected] = useState(defaultValue || options[0]?.value);

  const handleSelect = (value) => {
    setSelected(value);
    onSelect && onSelect(value);
  };

  return (
    <Dropdown customClass="ml-[8px]">
      <Dropdown.Trigger customClass="flex flex-row items-center justify-between rounded border-n-400 border-solid border w-[240px] bg-n-000 h-[32px] pl-[16px] pr-[8px]">
        <div className="flex items-center">
          <p className="mr-[4px] typography-h400">
            {getObjectiveLocale('Time:')}
          </p>
          <p className="typography-paragraph truncate w-[85px]">
            {startCase(selected)}
          </p>
        </div>
        <SVGIcon
          size="24"
          fillColor="var(--n-600)"
          iconName="icon-unfold_more"
        />
      </Dropdown.Trigger>
      <Dropdown.MenuItems customClass="w-[240px]" maxHeight={186}>
        {options?.map((option) => (
          <Dropdown.MenuItem
            key={option.id}
            onClick={() => handleSelect(option.value)}
            customClass="w-[240px]"
            dataCy="dropdown-filter-time-group"
          >
            <p className="typography-button text-n-900">
              {startCase(option.value)}
            </p>
          </Dropdown.MenuItem>
        ))}
      </Dropdown.MenuItems>
    </Dropdown>
  );
};

export default TimeGroup;

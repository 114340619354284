import { getObjectiveUserSugestion } from "client/SuggestionClient";
import { useInfiniteQuery } from "react-query";

export default function useSuggestions() {

  // GET OBJECTIVES PAGINATION
  const GetUserSuggestions = ({ role, excludeArray, type, assigneeId, q }) => {
    const fetchUserSuggestions = (pageParam) => {
      let params = {
        role: role,
        exclude: excludeArray,
        type: type,
        limit: 20,
        q: q,
        ...(role === "assigner" && { assigneeId: assigneeId } ),
        olderThan: pageParam,
      };

      return getObjectiveUserSugestion(params);
    };

    const {
      data,
      status,
      isFetching,
      isFetchingNextPage,
      fetchNextPage,
      hasNextPage,
    } = useInfiniteQuery(["userSuggestions", { q, excludeArray }], ({ pageParam }) => fetchUserSuggestions(pageParam), {
      getNextPageParam: (lastPage, allPages) => lastPage.pagination?.next?.olderThan
    });

    return {
      data,
      status,
      isFetching,
      isFetchingMore: isFetchingNextPage,
      fetchMore: fetchNextPage,
      canFetchMore: hasNextPage,
    };
  };

  return { getUserSuggestions : GetUserSuggestions };
}

import React, { useEffect } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import SVGIcon from 'components/shared/SVGIcon';
import { useDeepEffect } from 'src/utils/useDeepEffect';

import { ADD_BOTTOM, ADD_TOP } from '../utils/const';
import { useItemLists } from '../utils/taskListHelper';
import GanttInputName from './GanttInputName';
import GanttObjective from './GanttObjective';

const GanttObjectivesWrapper = ({
  contentLoaded,
  setContentLoaded,
  headerRef,
  setIsFetching,
  isFetching,
  contentOptions,
  subContentOptions,
  parentParams,
  setQueryKey,
  group,
  setOpenAdd,
  openAdd
}) => {
  const options = contentOptions;

  const {
    getQueryKey,
    getQueryParam,
    defaultParams,
    addNewCallbacks,
    singleObjectiveOptions
  } = options;

  const params = getQueryParam(defaultParams, parentParams);
  const queryKeyParam = getQueryKey([params]);

  useDeepEffect(() => {
    setQueryKey(queryKeyParam);
  }, [queryKeyParam]);

  const queryOptions = {
    ...options,
    queryKey: queryKeyParam,
    queryParams: params,
    getSingleQueryKey: singleObjectiveOptions.getQueryKey
  };

  const { isFetching: isFetchingQuery, data } = useItemLists({
    queryOptions,
    targetPaginateId: 'target-load-more-' + group.id,
    isLoaded: contentLoaded,
    setLoaded: setContentLoaded,
    targetAppear: headerRef
  });

  const closeInputBox = () => setOpenAdd(null);

  useEffect(() => {
    setIsFetching(isFetchingQuery);
  }, [isFetchingQuery, setIsFetching]);

  return (
    <div className="flex flex-col items-center" id={'content'}>
      {openAdd == ADD_TOP && (
        <GanttInputName
          closeInputBox={closeInputBox}
          listQueryKeys={queryKeyParam}
          parentParams={parentParams}
          addNewCallbacks={addNewCallbacks}
        />
      )}
      {contentLoaded &&
        data.length > 0 &&
        data.map((objective) => {
          return (
            <GanttObjective
              objective={objective}
              level={0}
              key={'name' + objective.id}
              objectiveOptions={contentOptions?.singleObjectiveOptions}
              subContentOptions={subContentOptions}
            />
          );
        })}
      {contentLoaded && data.length == 0 && !isFetching && (
        <div className="h-[48px] main-row flex w-full items-center px-[16px]">
          <p className="typoghraphy-h400 text-n-600 pl-[32px] ">
            {getObjectiveLocale('There is no task')}
          </p>
        </div>
      )}
      {openAdd == ADD_BOTTOM && (
        <GanttInputName
          closeInputBox={closeInputBox}
          listQueryKeys={queryKeyParam}
          parentParams={parentParams}
          addNewCallbacks={addNewCallbacks}
        />
      )}
      <div
        className={`${
          isFetching ? 'load-more h-[48px] ' : ''
        } flex flex-start w-full px-[16px] items-center`}
      >
        {(isFetching || !contentLoaded) && (
          <Shimmer width="100%" height="24px" customClass="rounded-[4px]" />
        )}
      </div>
      <div
        id={'target-load-more-' + group.id}
        className="w-full pl-[32px] h-[40px] flex items-center cursor-pointer hover:bg-base-3008"
        onClick={() => setOpenAdd(ADD_BOTTOM)}
      >
        <SVGIcon
          id={`icon-add-task-bot-${group.id}`}
          size={24}
          fillColor="var(--base-600)"
          customClass="ml-[8px] mr-[4px]"
          iconName="icon-add"
        />
        <p className="typography-button text-base-600">
          {getObjectiveLocale('Add Task')}
        </p>
      </div>
    </div>
  );
};

export default GanttObjectivesWrapper;

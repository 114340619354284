import React, { useEffect, useState } from 'react';

import ToastNotif from '../components/shared/ToastNotif';
import { useNetworkError } from '../context/NetworkErrorContext';

const NetworkErrorBoundary = ({ children }) => {
  const { queueHead, popError } = useNetworkError();
  const [showNotif, setShowNotif] = useState(false);

  useEffect(() => {
    if (queueHead?.message) {
      setShowNotif(true);
      setTimeout(() => {
        setShowNotif(false);
        setTimeout(() => {
          popError();
        }, 300);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueHead]);

  return (
    <>
      <ToastNotif
        warning={!queueHead?.isSuccess}
        datacy="warning-toast"
        showNotif={showNotif}
        message={queueHead?.message}
        usePortal
      />

      {children}
    </>
  );
};

export default NetworkErrorBoundary;

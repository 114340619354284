import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import startCase from 'lodash/startCase';

const getDefaultFilterSaved = ({
  dataFilter,
  phases,
  priorities,
  defaultObjectiveSorting,
  periodFilter,
  isProject
}) => {
  const getListPhase = () => {
    let listPhase = [];
    dataFilter?.filter?.phaseId?.map((savedId) => {
      const phase = phases?.find((e) => e?.id === savedId) || null;
      if (phase) {
        listPhase.push(phase);
      }
    });
    return listPhase;
  };

  const getListPriority = () => {
    let listPriority = [];
    dataFilter?.filter?.priorityId?.map((savedId) => {
      const priority = priorities?.find((e) => e?.id === savedId) || null;
      if (priority) {
        listPriority.push(priority);
      }
    });
    return listPriority;
  };

  const getParentAlignemnt = (data) => {
    if (!data || Object.keys(data).length == 0) return;
    return [{ objective: data, objectiveId: data.id }];
  };

  let defaultFilter = {
    ...dataFilter?.filter,
    showTask: dataFilter?.showTask || 'personal',
    group: dataFilter?.groupBy || 'phase',
    phase: getListPhase(),
    priority: getListPriority(),
    sortColumn:
      dataFilter?.sortColumn ||
      defaultObjectiveSorting?.sortColumn ||
      'due_date',
    sortDirection:
      dataFilter?.sortDirection ||
      defaultObjectiveSorting?.sortDirection ||
      'asc',
    periodBegin: dataFilter?.periodBegin || periodFilter?.periodBegin,
    periodEndBefore:
      dataFilter?.periodEndBefore || periodFilter?.periodEndBefore,
    parentAlignment: getParentAlignemnt(dataFilter?.filter?.parentAlignment),
    taskColumn: dataFilter?.taskColumn,
    lastUpdated: getFilterLastUpdate(dataFilter),
    timeGroup: dataFilter?.timeGroup || 'week'
  };

  if (isProject) {
    defaultFilter.assignTo = dataFilter?.filter?.assignTo || 'everyone';
  }

  delete defaultFilter?.phaseId;
  delete defaultFilter?.priorityId;

  defaultFilter = omitBy(defaultFilter, isNil);

  return defaultFilter;
};

const getDefaultHeaderColumnSaved = ({
  headerColumnName,
  filterColumnName,
  enableTaskMultiParentAlignment,
  isProject
}) => {
  const isColumnHide = (colomnName) => {
    const isThereColumnName =
      filterColumnName
        ?.map((e) => startCase(e))
        .find((e) => e === colomnName) !== undefined;

    return !isThereColumnName;
  };

  const headerColumn = [
    {
      name: isProject ? 'arrow-icon' : '',
      widthClass: 'w-[36px] min-w-[36px]',
      grow: false,
      customClass: 'mr-[-1px] !justify-start',
      hide: false,
      useMinHeight: false,
      useHeaderMargin: false,
      useHeaderPadding: false,
      isAlwaysVisible: true
    },
    {
      name: 'Task Name',
      widthClass: 'w-[344px] min-w-[344px]',
      grow: 1,
      customClass: 'mr-[-1px]',
      hide: false,
      useMinHeight: false
    },
    {
      name: 'Assignee',
      widthClass: 'w-[200px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Assignee'),
      useMinHeight: false
    },
    {
      name: 'Period',
      widthClass: 'w-[160px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Period'),
      useMinHeight: false
    },
    {
      name: 'Priority',
      widthClass: 'w-[120px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Priority'),
      useMinHeight: false
    },
    {
      name: 'Phase',
      widthClass: 'w-[160px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Phase'),
      useMinHeight: false
    },
    {
      name: 'Progress',
      widthClass: 'w-[160px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Progress'),
      useMinHeight: false
    },
    {
      name: 'Metrics',
      widthClass: 'w-[176px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Metrics'),
      useMinHeight: false
    },
    {
      name: 'Complexity',
      widthClass: 'w-[96px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Complexity'),
      useMinHeight: false
    },
    {
      name: 'Alignment Goal',
      widthClass: enableTaskMultiParentAlignment ? 'w-[280px]' : 'w-[200px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Alignment Goal'),
      useMinHeight: false
    },
    {
      name: 'Dependency',
      widthClass: 'w-[280px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Dependency'),
      useMinHeight: false
    },
    {
      name: 'Last Comment',
      widthClass: 'w-[240px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Last Comment'),
      useMinHeight: false
    },
    {
      name: 'Created by',
      widthClass: 'w-[160px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Created By'),
      useMinHeight: false
    },
    {
      name: 'Last Updated',
      widthClass: 'w-[128px]',
      customClass: 'mr-[-1px]',
      hide: isColumnHide('Last Updated'),
      useMinHeight: false
    },
    { name: '', widthClass: 'w-[56px]', hide: false, useMinHeight: false }
  ].filter(({ name }) => headerColumnName.includes(name));

  return headerColumn;
};

const getFilterPayloadBody = ({
  module,
  page,
  section,
  filter,
  headerTable,
  isProject
}) => {
  const getParentAlignment = (data) => {
    if (!data) return;
    const result = data.map((data) => data?.objective)[0] || undefined;
    if (!result) return;
    return {
      id: result.id,
      startDate: result.startDate,
      dueDate: result.dueDate,
      name: result.name,
      involvements: result.involvements,
      type: result.type
    };
  };

  let payloadBody = {
    module: module,
    page: page,
    section: section,
    filter: {
      phaseId: filter?.phase?.map(({ id }) => id),
      priorityId: filter?.priority?.map(({ id }) => id),
      overdue: filter?.overdue,
      lastUpdated: getParamsLastUpdate(filter?.lastUpdated?.lastUpdatedType),
      parentAlignment: getParentAlignment(filter?.parentAlignment)
    },
    groupBy: filter?.group,
    sortColumn: filter?.sortColumn,
    sortDirection: filter?.sortDirection,
    showTask: filter?.showTask,
    periodBegin: filter?.periodBegin,
    periodEndBefore: filter?.periodEndBefore,
    taskColumn:
      headerTable
        ?.filter(({ hide, name }) => !hide && name)
        ?.map(({ name }) => name) || [],
    timeGroup: filter?.time
  };

  if (isProject) {
    payloadBody.filter.assignTo = filter?.assignTo;
  }

  payloadBody.filter = omitBy(payloadBody.filter, isNil);
  payloadBody = omitBy(payloadBody, isNil);

  return payloadBody;
};

const getFilterLastUpdate = (dataFilter) => {
  const filterLastUpdated = dataFilter?.filter?.lastUpdated;

  let lastUpdate;
  if (filterLastUpdated?.type === 'newer_than' && filterLastUpdated?.period) {
    lastUpdate = `lastUpdated.${filterLastUpdated?.period}`;
  } else if (filterLastUpdated?.date) {
    lastUpdate = `lastUpdated.${
      filterLastUpdated?.type + '.' + filterLastUpdated?.date
    }`;
  } else {
    lastUpdate = `lastUpdated.${
      filterLastUpdated?.type + '_since_' + filterLastUpdated?.period
    }`;
  }

  return filterLastUpdated ? { lastUpdatedType: lastUpdate } : null;
};

const getParamsLastUpdate = (lastUpdatedType) => {
  const filterLastUpdated = lastUpdatedType
    ?.split('lastUpdated.')
    .filter((e) => e)[0]
    ?.split('.');

  let paramsLastUpdate;
  if (filterLastUpdated) {
    switch (filterLastUpdated[0]) {
      case 'last_week':
        paramsLastUpdate = { type: 'newer_than', period: 'last_week' };
        break;
      case 'last_2_weeks':
        paramsLastUpdate = {
          type: 'newer_than',
          period: 'last_2_weeks'
        };
        break;
      case 'newer_than':
        paramsLastUpdate = {
          type: 'newer_than',
          date: filterLastUpdated[1] + '.' + filterLastUpdated[2]
        };
        break;
      case 'older_than':
        paramsLastUpdate = {
          type: 'older_than',
          date: filterLastUpdated[1] + '.' + filterLastUpdated[2]
        };
        break;
      case 'no_updates_since_last_month':
        paramsLastUpdate = { type: 'no_updates', period: 'last_month' };
        break;
      case 'no_updates_since_last_two_weeks':
        paramsLastUpdate = {
          type: 'no_updates',
          period: 'last_two_weeks'
        };
        break;
      case 'no_updates_since_last_week':
        paramsLastUpdate = { type: 'no_updates', period: 'last_week' };
        break;
    }
  }

  return paramsLastUpdate;
};

const getDataFilter = (data, currentTab, module, page) => {
  return data
    ?.filter((e) => e.module === module)
    .filter((e) => e.page === page)
    .filter((e) => e.section === currentTab)[0]?.data;
};

export {
  getDefaultFilterSaved,
  getDefaultHeaderColumnSaved,
  getFilterPayloadBody,
  getFilterLastUpdate,
  getParamsLastUpdate,
  getDataFilter
};

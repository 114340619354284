import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import isNil from 'lodash/isNil';

import { getGoalsScoring } from 'client/ObjectivesClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import { getSelectedFormula } from 'components/admin/track-template/ConfigurationSettingTrackTemplate';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Button from 'components/design-system/Button';
import FormattedNumber from 'components/design-system/FormattedNumber';
import Objectives from 'components/objectives/Objectives';
import BannerBox from 'components/shared/BannerBox';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import Modal from 'components/shared/modal/Modal';

function GoalsScoring({
  state,
  target,
  assignmentId,
  setAnswer,
  isReviewResult = false,
  cycle,
  formula,
  showOption
}) {
  const params = useParams();
  const { config } = useUser();

  let [{ goalsScoringData }, { getGoalsScoringData }] = useFormalReview();

  const [isLoading, setIsLoading] = useState(false);
  const [finalize, setFinalize] = useState(false);
  const [modalEditPerformance, setModalEditPerformance] = useState(false);
  const [modalFinalize, setModalFinalize] = useState(false);
  const currentAssingmentId = assignmentId ? assignmentId : params.assignmentId;
  const goal = getObjectiveLocale('Goal');
  const { reviewedPeriodStartsAt, reviewedPeriodEndsAt } = cycle;

  const listScoring = goalsScoringData || {};
  const { colorHex, name } = listScoring?.initial?.overallScore || {};

  const showFinalizeModal = () => {
    finalize ? setModalEditPerformance(true) : setModalFinalize(true);
  };

  const closeModal = () => {
    setModalEditPerformance(false);
    setModalFinalize(false);
  };

  const finalizeScore = async (finalize) => {
    const answer = {
      state: finalize ? 'in_progress' : 'done'
    };
    setAnswer('goals_scoring', answer, 'suggested_ongoing');
    setModalEditPerformance(false);
    setModalFinalize(false);
  };

  useEffect(() => {
    getGoalsScoringData(currentAssingmentId);
  }, []);

  useEffect(() => {
    setFinalize(state === 'in_progress' ? false : true);
  }, [state]);

  const isObjectiveWeightTypeCategory =
    config && config.objectiveWeightType === 'type';

  const filterOptions =
    config?.ratingMechanism == 'automatic'
      ? ['search', 'dateFilter']
      : ['search', 'dateFilter', 'switch'];

  return (
    <React.Fragment>
      <div className="border border-solid border-n-400 rounded-[4px]">
        <div data-cy="goals-scoring-header">
          <div className="flex items-center justify-between py-[16px] px-[16px] border-b border-0 border-solid border-n-400 mb-[16px] mr-[16px]">
            <div>
              <p className="typography-h500 text-n-900 multiline-text-1">
                {getObjectiveLocale('Finalize [employee_name]’s goal').replace(
                  /\[employee_name]/,
                  target?.name
                )}
              </p>
              <p className="typography-h100 text-n-800">
                {getObjectiveLocale(
                  'Lock these score by clicking the finalize button'
                )}
              </p>
            </div>
            {!isReviewResult && (
              <Button onClick={showFinalizeModal} datacy="finalize-scoring">
                {getObjectiveLocale(finalize ? 'Finalized' : 'Finalize Score')}
              </Button>
            )}
          </div>
          {/* Warning banner */}
          {!isReviewResult &&
            listScoring &&
            listScoring.personal &&
            listScoring.personal.totalWeight !== 100 &&
            !isObjectiveWeightTypeCategory && (
              <BannerBox type="warning" customClass="mx-[16px]">
                Total weight for {` ${goal.toLowerCase()} `} is above/below
                100%. Please make sure the total weight is 100% (you can still
                finalize if not).
              </BannerBox>
            )}
          <div className="flex">
            {/* Score section */}
            <div className="px-[16px]">
              <div className="flex items-center mb-[8px]">
                <p>{getObjectiveLocale('Score')}</p>
                <TooltipContainer
                  show
                  text={getSelectedFormula(formula)}
                  classContainer="cursor-pointer"
                  useMaxWidth={false}
                >
                  <SVGIcon
                    size="16"
                    fillColor="var(--n-600)"
                    iconName="icon-info"
                    customClass="ml-[8px]"
                  />
                </TooltipContainer>
              </div>
              <div className="mb-[4px] flex items-end">
                <FormattedNumber
                  number={listScoring.personal?.score || 0}
                  customClass="typography-h600 text-base-600 mr-[8px]"
                  minDecimalLength={2}
                />
                <p>
                  {(
                    listScoring.personal?.score - listScoring.initial?.score
                  ).toFixed(2) > 0 ? (
                    <FormattedNumber
                      number={
                        listScoring.personal?.score - listScoring.initial?.score
                      }
                      customClass="typography-h400 text-g-600"
                      minDecimalLength={2}
                    />
                  ) : listScoring.personal?.score -
                      listScoring.initial?.score ===
                    0 ? (
                    ''
                  ) : (
                    <FormattedNumber
                      number={
                        listScoring.personal?.score - listScoring.initial?.score
                      }
                      customClass="typography-h400 text-r-600"
                      minDecimalLength={2}
                    />
                  )}
                </p>
                {showOption == 'score_and_label' && (
                  <span
                    className="typography-paragraph ml-[8px] mb-[-2px]"
                    style={{ color: `#${colorHex || '94A3B8'}` }}
                  >
                    ({name})
                  </span>
                )}
              </div>
              <p className="typography-h100 text-n-800">
                Updated on {getDateLocale(listScoring.updatedAt)}
              </p>
            </div>
            {/* Total weight section */}
            {!isReviewResult &&
              !isNil(listScoring?.personal?.totalWeight) &&
              !isObjectiveWeightTypeCategory && (
                <div className="ml-[40px]">
                  <p className="mb-[8px]">
                    {getObjectiveLocale('Total Weight')}
                  </p>
                  <div className="flex items-center">
                    {listScoring?.personal?.totalWeight !== 100 && (
                      <SVGIcon
                        size="16"
                        fillColor="var(--y-600)"
                        iconName="icon-warning"
                      />
                    )}
                    <p
                      className={`${
                        listScoring?.personal?.totalWeight !== 100
                          ? 'text-r-600'
                          : 'text-base-600'
                      } typography-h600 mx-[4px]`}
                    >
                      {listScoring?.personal?.totalWeight || '0'}%
                    </p>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className={`table-responsive mt-[24px]`}>
          <AdvancedFilter
            switchOptions={[
              {
                id: 'all',
                name: getObjectiveLocale('All'),
                filter: { state: ['completed', 'running', 'reviewed'] }
              },
              {
                id: 'unreviewed',
                name: getObjectiveLocale('Unreviewed'),
                filter: { state: ['completed', 'running'] }
              },
              {
                id: 'reviewed',
                name: getObjectiveLocale('Reviewed'),
                filter: { state: ['reviewed'] }
              }
            ]}
            filterOptions={filterOptions}
            defaultFilter={{
              periodBegin: reviewedPeriodStartsAt,
              periodEndBefore: reviewedPeriodEndsAt,
              state: ['completed', 'running', 'reviewed']
            }}
            dateFormatType="DD MMMM YYYY"
            customClass="px-[16px]"
            id="goalsScoringFilter"
          >
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <Objectives
                cardModel={'formal-review'}
                page={'goalsScoring'}
                queryFn={(params) =>
                  getGoalsScoring(assignmentId, {
                    ...params,
                    sortColumn:
                      config?.defaultObjectiveSorting?.sortColumn || 'due_date',
                    sortDirection:
                      config?.defaultObjectiveSorting?.sortDirection || 'asc'
                  })
                }
                extraFilter={{ parentNotAssignedTo: target?.id }}
                withMarginTop
                target={target}
                customClass="px-[16px]"
                headerCardList={['Name', 'Weight', 'Rating']}
              />
            )}
          </AdvancedFilter>
        </div>
      </div>
      {modalFinalize && (
        <Modal
          title="Finalize Performance Score"
          eventOnClickClose={closeModal}
          withCloseIcon
          withPrimaryBtn={{
            title: 'Finalize Score',
            onClick: () => finalizeScore(finalize)
          }}
          withSecondaryBtn={{ title: 'Cancel', onClick: closeModal }}
        >
          <p className="text-n-800 typography-paragraph">
            {getObjectiveLocale(
              "Finalize performance score for this reviewee? Don't worry, you can come back and edit it again later."
            )}
          </p>
        </Modal>
      )}

      {modalEditPerformance && (
        <Modal
          title="Edit performance score"
          body="Edit your finalized performance score for this reviewee?"
          eventOnClickClose={closeModal}
          withPrimaryBtn={{
            title: 'Edit Score',
            onClick: () => finalizeScore(finalize)
          }}
          withSecondaryBtn={{ title: 'Cancel', onClick: closeModal }}
        >
          <p className="text-n-800 typography-paragraph">
            {getObjectiveLocale(
              'Edit your finalized performance score for this reviewee?'
            )}
          </p>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default GoalsScoring;

import React from 'react';

import isEqual from 'lodash/isEqual';

import usePulseSurvey from 'hooks/usePulseSurvey';
import { parseUrlinString } from 'utils/HelperUtils';

import TwoLine from 'components/design-system/ProfileType';
import FooterLimit from 'components/design-system/footer/FooterLimit';
import Table from 'components/design-system/table/Table';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import TruncateParagraph from 'components/shared/TruncateParagraph';

const areEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

// eslint-disable-next-line react/display-name
const TableBreakdownSurvey = React.memo(
  ({
    breakdownData,
    currentPage,
    currentLimit,
    setCurrentPage,
    setCurrentLimit,
    setIsLoading,
    getData,
    pagination,
    total,
    lastUpdated,
    hasMore
  }) => {
    const { surveyData } = usePulseSurvey();

    let headers = [
      { name: 'name', widthClass: 'w-[240px]', grow: true },
      { name: 'job title', widthClass: 'w-[160px]' },
      { name: 'job role', widthClass: 'w-[160px]' },
      { name: 'job function', widthClass: 'w-[160px]' },
      { name: 'directorate', widthClass: 'w-[160px]' },
      { name: 'department', widthClass: 'w-[160px]' },
      { name: 'division', widthClass: 'w-[160px]' },
      { name: 'status', widthClass: 'w-[160px]' },
      { name: 'start at', widthClass: 'w-[200px]' },
      { name: 'finish at', widthClass: 'w-[200px]' },
      { name: 'duration', widthClass: 'w-[120px]' }
    ];

    surveyData?.questions?.length > 0 &&
      surveyData?.questions?.map((q) =>
        headers.push({ name: q.question, widthClass: 'w-[420px]' })
      );

    const changePage = async (type) => {
      setIsLoading(true);
      let paginationKey;
      let changedPage;

      switch (type) {
        case 'next':
          paginationKey = 'olderThan';
          changedPage = currentPage + 1;
          break;
        case 'prev':
          paginationKey = 'newerThan';
          changedPage = currentPage - 1;
          break;
      }
      setCurrentPage(changedPage);
      await getData(
        paginationKey ? { [paginationKey]: pagination?.[paginationKey] } : null
      );
      setIsLoading(false);
    };

    return (
      <>
        <Table headers={headers} fixedLeftCount={1}>
          {breakdownData.map((data) => (
            <Table.Row key={data?.id} dataCy={`participant-${data?.id}`}>
              <Table.Column key={1}>
                <TwoLine
                  user={data}
                  title={data.name}
                  subtitle={data.email}
                  size={32}
                  titleClassName="typography-h400"
                  subtitleClassName="typography-h100 text-n-800"
                />
              </Table.Column>
              <Table.Column key={2} dataCy="job-title">
                <TooltipContainer
                  show={data?.jobTitle?.length > 40}
                  position="top"
                  text={data?.jobTitle}
                  key={data?.id}
                  useMaxWidth={false}
                >
                  <p className="multiline-text-2">{data.jobTitle || '-'}</p>
                </TooltipContainer>
              </Table.Column>
              <Table.Column key={3} dataCy="job-role">
                {data?.info?.jobRole || '-'}
              </Table.Column>
              <Table.Column key={4} dataCy="job-function">
                {data.info?.jobFunction || '-'}
              </Table.Column>
              <Table.Column key={5} dataCy="directorate">
                <TruncateParagraph className="typography-paragraph text-n-900 max-w-[144px]">
                  {data.info?.directorate || '-'}
                </TruncateParagraph>
              </Table.Column>
              <Table.Column key={6} dataCy="department">
                <TruncateParagraph className="typography-paragraph text-n-900 max-w-[144px]">
                  {data.info?.department || '-'}
                </TruncateParagraph>
              </Table.Column>
              <Table.Column key={7} dataCy="division">
                <TruncateParagraph className="typography-paragraph text-n-900 max-w-[144px]">
                  {data.info?.division || '-'}
                </TruncateParagraph>
              </Table.Column>
              <Table.Column key={8} dataCy="status">
                <span
                  className={
                    data.status === 'Submitted' ? 'text-g-600' : 'text-n-600'
                  }
                >
                  {data.status || '-'}
                </span>
              </Table.Column>
              <Table.Column key={9} dataCy="start-time">
                {data?.startsAt || '-'}
              </Table.Column>
              <Table.Column key={10} dataCy="ends-time">
                {data?.endsAt || '-'}
              </Table.Column>
              <Table.Column key={11} dataCy="duration">
                {data?.duration || '-'}
              </Table.Column>
              {data?.answers.map((answer, index) => {
                return (
                  <Table.Column
                    key={index + 12}
                    widthClass="w-[420px]"
                    dataCy={`question-${index}`}
                  >
                    <TruncateParagraph className="typography-paragraph text-n-900 max-w-[400px]">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: parseUrlinString(
                            answer || '-',
                            'color:inherit!important'
                          )
                        }}
                      />
                    </TruncateParagraph>
                  </Table.Column>
                );
              })}
            </Table.Row>
          ))}
        </Table>
        <FooterLimit
          changePage={changePage}
          currentPage={currentPage}
          setCurrentLimit={setCurrentLimit}
          currentLimit={currentLimit}
          listLimit={[20, 50, 100]}
          total={total}
          currentDataLength={breakdownData?.length}
          lastUpdated={lastUpdated}
          hasMore={hasMore}
          withFirstLastPage={false}
        />
      </>
    );
  },
  areEqual
);

export default TableBreakdownSurvey;

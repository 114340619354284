import React, { useState } from 'react';

import { resetPassword } from 'client/admin/UsersClient';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/shared/modal/Modal';

function ModalChangePassword({ onClose, showNotif }) {
  const { user } = useUser();
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [showError, setShowError] = useState(false);
  const submitPassword = async () => {
    const params = {
      oldPassword: formData.oldPassword,
      password: formData.confirmPassword
    };
    if (formData.newPassword == formData.confirmPassword) {
      setShowError(false);
      const { data, error } = await resetPassword(user.id, params);
      if (data) {
        showNotif({ message: 'Change Password Success', isSuccess: true });
      } else {
        showNotif({ message: 'Current Password Not Match', isSuccess: false });
      }
    } else {
      setShowError(true);
    }
  };
  return (
    <Modal
      title="Change Password"
      className="w-[400px]"
      eventOnClickClose={() => onClose()}
      withPrimaryBtn={{
        title: getObjectiveLocale('Submit'),
        dataCy: 'submit-password',
        disabled:
          !formData.oldPassword ||
          !formData.newPassword ||
          !formData.confirmPassword,
        onClick: () => submitPassword()
      }}
      withFooter={true}
    >
      <div className="mb-[16px]">
        <p className="typography-h100 typography-secondary mb-[8px] capitalize">
          current password
        </p>
        <input
          className={`typography-h400 border-gray rounded-[4px] h-[36px] w-full py-[12px] px-[8px]`}
          type="password"
          onChange={(e) =>
            setFormData({ ...formData, oldPassword: e.currentTarget.value })
          }
        />
      </div>
      <div className="mb-[16px]">
        <p className="typography-h100 typography-secondary mb-[8px] capitalize">
          new password
        </p>
        <input
          className={`typography-h400 border-gray rounded-[4px] h-[36px] w-full py-[12px] px-[8px]`}
          type="password"
          onChange={(e) =>
            setFormData({ ...formData, newPassword: e.currentTarget.value })
          }
        />
      </div>
      <div className="mb-[16px]">
        <p className="typography-h100 typography-secondary mb-[8px] capitalize">
          confirm password
        </p>
        <input
          className={`typography-h400 border-${
            showError ? 'red' : 'gray'
          } rounded-[4px] h-[36px] w-full py-[12px] px-[8px]`}
          type="password"
          onChange={(e) =>
            setFormData({ ...formData, confirmPassword: e.currentTarget.value })
          }
        />
        {showError && (
          <p className="typography-h100 typography-secondary mt-[8px] text-r-500">
            Confirm password not match
          </p>
        )}
      </div>
    </Modal>
  );
}

export default ModalChangePassword;

import React, { useReducer, useRef, useState } from 'react';

import ctl from '@netlify/classnames-template-literals';
import isEqual from 'lodash/isEqual';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';

import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';

import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import ObjectiveCardView from 'pages/objectiveAnalytics/card/ObjectiveCardView';
import SVGWithTooltip from 'pages/objectiveAnalytics/helper/SVGWithTooltip';

import FilterMenu from './FilterMenu';

const shadowStyle = {
  boxShadow:
    '0px 1px 2px rgba(15, 23, 42, 0.06), 0px 1px 3px rgba(15, 23, 42, 0.1)'
};

const wrapperColumnCN = ctl(`
  flex justify-center items-center 
  grow-0 flex-shrink-0 rounded-[4px] 
  border-[1px] border-solid border-n-300
  bg-n-000 flex-col w-[288px] h-[60px] 
  top-0 sticky z-10 group cursor-pointer
`);

const AnalyticsColumnName = ({ data }) => {
  const redEditColumn = useRef();
  const prevSelFilter = useRef(null);
  const [isEditColumn, setIsEditColumn] = useState(false);
  const handleEditColumn = useObjectiveComparative(
    (state) => state.handleEditColumn
  );
  const handleRemoveColumn = useObjectiveComparative(
    (state) => state.handleRemoveColumn
  );

  const reducer = (oldState, action) => {
    const selectedFilter = Object.keys(action?.state)
      .filter((key) => kebabCase(action?.type) == key)
      .reduce((obj, key) => {
        obj[key] = action?.state[key];
        return obj;
      }, {});

    prevSelFilter.current = action?.state;

    if (!isEqual(currentFilter, action?.state)) {
      handleEditColumn(selectedFilter, data?.columnNumber);
      setIsEditColumn(false);
    }

    return { ...selectedFilter };
  };

  const [currentFilter, dispatchCurrentFilter] = useReducer(reducer, {
    [data?.columnType.replace(' ', '-').toLocaleLowerCase()]: [data?.columnName]
  });

  return (
    <div className="flex flex-col">
      <div
        className={wrapperColumnCN}
        style={shadowStyle}
        onClick={() => setIsEditColumn(true)}
      >
        <SVGWithTooltip
          iconName="icon-clear"
          text="Remove Column"
          customClass="absolute right-[16px] top-[18px] invisible group-hover:visible"
          action={() => handleRemoveColumn(data.columnNumber)}
          isColumn
        />
        <p className="typography-h100 text-n-600">
          {startCase(data?.columnType)}
        </p>
        <TooltipContainer
          show={true}
          text={startCase(data?.columnName)}
          position="bottom"
          customContainer=""
        >
          <p className="w-[256px] text-center typography-h400 text-n-800 truncate">
            {startCase(data?.columnName)}
          </p>
        </TooltipContainer>
      </div>

      {isEditColumn && (
        <FilterMenu
          refFilter={redEditColumn}
          filter={currentFilter}
          dispatchFilter={dispatchCurrentFilter}
          onClose={() => setIsEditColumn(false)}
        />
      )}

      <div className="flex flex-col gap-[16px] mt-[16px]">
        <ObjectiveCardView
          filter={currentFilter}
          columnNumber={data?.columnNumber}
          columnName={data?.columnName}
          columnType={data?.columnType}
          sectionData={data?.sectionData}
        />
      </div>
    </div>
  );
};

export default AnalyticsColumnName;

import React, { createRef, useRef, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import {
  getFormerDirectReport,
  getFormerInDirectReport,
  getObjectiveCategory,
  getObjectiveInvolvements,
  getTopParent
} from 'client/ObjectivesClient';
import { getManagerV2 } from 'client/UserProfile';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useUrl } from 'hooks/useUrl';
import { restructureFilter } from 'utils/ObjectivesHelper';
import { useDeepEffect } from 'utils/useDeepEffect';

import Button from 'components/design-system/Button';
import Accordion from 'components/design-system/accordion/Accordion';
import Objectives from 'components/objectives/Objectives';
import ExportSummaryButton from 'components/objectives/progress-summary/ExportSummaryButton';
import Avatar from 'components/shared/Avatar';
import ObjectiveEmptyState from 'components/shared/ObjectiveEmptyState';
import ObjectiveSkeleton from 'components/shared/ObjectiveSkeleton';
import StatisticsOverallViewProgressCompact from 'pages/dashboardOverallViewProgress/compactStatistics/StatisticsOverallViewProgressCompact';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

function AccordionHeader({
  data,
  group,
  useFetchObjective,
  additionalWrapperClick,
  newParams,
  fixedFilter,
  groupFilter
}) {
  const { config } = useUser();

  let assigneeId = data?.id || null;

  const queryClient = useQueryClient();
  const overallData = queryClient.getQueryData([
    'overallProgress',
    assigneeId,
    null
  ]);

  return (
    <Accordion.Header
      additionalWrapperClick={additionalWrapperClick}
      dataCyChevronButton={`direct-report-chevron-${data?.user?.id}`}
      customSpaceClass={`${
        config?.showSubordinatesPage &&
        (group === 'direct-report' || group === 'indirect-report')
          ? 'px-[24px] pt-[16px]'
          : 'px-[24px] py-[16px]'
      } items-center`}
    >
      <div
        className={
          group === 'direct-report' ? 'flex justify-between w-full' : ''
        }
      >
        <div className="flex items-center">
          {(group === 'manager' ||
            group === 'direct-report' ||
            group === 'indirect-report' ||
            group === 'owner') && (
            <Avatar
              name={data?.user?.name || data?.name}
              src={data?.user?.profilePicture || data?.profilePicture || null}
              size={40}
              wrapperClassName="mr-[16px]"
            />
          )}
          <div>
            <p className="typography-h500" data-cy="user-name">
              {data?.user?.name || data?.name}
            </p>
            {config?.showSubordinatesPage &&
            (group === 'direct-report' || group === 'indirect-report') ? (
              <p className="text-n-800" data-cy="user-position">
                {data?.positions?.[0]?.name} &#183;{' '}
                {dayjs(data?.positions?.[0]?.startsAt).format("MMM 'YY")} -{' '}
                {data?.positions?.[0]?.endsAt
                  ? dayjs(data?.positions?.[0]?.endsAt).format("MMM 'YY")
                  : 'Current'}{' '}
              </p>
            ) : (
              <p className="text-n-800" data-cy="user-position">
                {useFetchObjective
                  ? data?.user
                    ? data?.name
                    : data?.jobTitle
                  : `${data?.positions?.length || 0} position(s)`}
              </p>
            )}
          </div>
        </div>
        {group === 'direct-report' &&
          overallData?.data?.totalObjectives > 0 &&
          config?.exportObjectivesSummaryFeature &&
          config?.permissions?.goalSummaryExport && (
            <div className="flex items-center">
              <ExportSummaryButton
                owner={
                  data?.user || {
                    id: data?.id,
                    name: data?.name,
                    jobTitle: data?.positions?.[0]?.name,
                    profilePicture: data?.profilePicture
                  }
                }
                filter={newParams}
                extraFilter={fixedFilter}
                placementId={groupFilter?.placementId || null}
              />
              <div className="border-solid border-y-0 border-r-0 border-l-[1px] border-n-400 border h-[24px] ml-[16px]"></div>
            </div>
          )}
      </div>
    </Accordion.Header>
  );
}

function GroupModeObjectives({
  filter,
  extraFilter,
  group,
  page,
  user,
  switchType,
  useFetchObjective,
  withFilterMenu,
  showBadgeObjectiveCount,
  isRefetch,
  filterId,
  profileFilter,
  queryFnTopParent,
  customClass,
  additionalWrapperClick,
  ...rest
}) {
  const [groupData, setGroupData] = useState({ data: [] });
  const { config, organization } = useUser();
  const { url } = useUrl();
  const filterById = useFilter((state) => state.filterById[filterId]);
  const history = useHistory();

  let newfilter = withFilterMenu ? restructureFilter(filter) : filter;
  let params = { ...newfilter, ...extraFilter };
  const noMaxHeight = page == 'scored-attribute-scoring';

  const getInvolvements = (pageParam) => {
    const params = {
      limit: 10,
      role: extraFilter?.involves?.role,
      periodBegin: filter?.periodBegin,
      periodEnd: filter?.periodEndBefore,
      sort_column: 'name',
      sort_direction: 'asc',
      olderThan: pageParam
    };
    return getObjectiveInvolvements(user?.id, params);
  };

  const getCategory = () => {
    let allParams = params;
    allParams.sort_column = 'name';
    allParams.sort_direction = 'asc';
    return getObjectiveCategory(allParams);
  };

  const getParent = () => {
    return getManagerV2(user?.placementId);
  };

  const getDirectReport = (pageParam) => {
    const state = filterById?.state || null;
    const params = {
      state:
        config?.showSubordinatesPage || !url?.includes('direct')
          ? state || 'active'
          : switchType === 'current'
          ? 'active'
          : 'inactive',
      placementId: user?.placementId,
      olderThan: pageParam
    };
    return getFormerDirectReport(user.id, params);
  };

  const getIndirectReport = (pageParam) => {
    const state = filterById?.state || null;
    const params = {
      state: state || 'active',
      placementId: user?.placementId,
      olderThan: pageParam
    };
    return getFormerInDirectReport(user.id, params);
  };

  const _geTopParent = () => {
    let allParams = {
      reviewsVisibility: 1,
      ...params
    };
    if (allParams.q || allParams.ownerType === 'all_employees') {
      delete allParams.parentNotAssignedTo;
    }

    if (allParams.objectiveCategoryId) {
      delete allParams.objectiveCategoryId;
    }

    return queryFnTopParent
      ? queryFnTopParent(allParams)
      : getTopParent(allParams);
  };

  let getGroupData;
  switch (group) {
    case 'top-parent':
      getGroupData = _geTopParent;
      break;
    case 'goal-type':
      getGroupData = getCategory;
      break;
    case 'manager':
      getGroupData = getParent;
      break;
    case 'direct-report':
      getGroupData = getDirectReport;
      break;
    case 'owner':
      getGroupData = getInvolvements;
      break;
    case 'indirect-report':
      getGroupData = getIndirectReport;
      break;
  }

  const getPlacement = (positions) => {
    const position = positions?.find((position) => position.endsAt == null);
    return position?.placementId || positions?.[0].placementId || null;
  };

  const getGroupFilter = (data) => {
    let filter = {};
    let placementId = null;
    switch (group) {
      case 'goal-type':
        filter = { objectiveCategoryId: data.id };
        break;
      case 'top-parent':
        filter = { parentId: data?.id != -1 ? data.id : null };
        break;
      case 'manager':
        filter = { assigneeId: data?.id };
        break;
      case 'direct-report':
        placementId = getPlacement(data?.positions);
        filter = {
          assigneeId: data?.id,
          parentNotAssignedTo: data?.id,
          placementId
        };
        break;
      case 'indirect-report':
        placementId = getPlacement(data?.positions);
        filter = {
          assigneeId: data?.id,
          parentNotAssignedTo: data?.id,
          placementId
        };
        break;
      case 'owner':
        filter = {
          assigneeId: data.id,
          sortColumn: 'name',
          sortDirection: 'asc'
        };
        break;
    }
    placementId == null && delete filter.placementId;

    return filter;
  };

  let { refetch, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ['group', group, page],
      ({ pageParam }) => getGroupData(pageParam),
      {
        getNextPageParam: (lastPage, allPages) =>
          lastPage.pagination?.next?.olderThan,
        suspense: false,
        onSuccess: (data) => {
          let newData = [];

          data?.pages?.map((page) => {
            if (group === 'manager') {
              newData.push(page?.data);
            } else {
              page?.data?.map((d) => {
                newData.push(d);
              });
            }
          });

          if (
            group == 'top-parent' &&
            page != 'scored-attribute-scoring' &&
            organization.identifier != 'asuransitugu'
          ) {
            newData.push({ id: -1, name: 'No Parent' });
          }

          setGroupData({ data: newData });
        }
      }
    );

  const intersectTarget = createRef();
  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting && hasNextPage && !isFetching && fetchNextPage(),
    threshold: 0.5
  });

  const DetailPlacements = ({ placement }) => {
    return placement?.positions?.map((positionDetail, index) => {
      return (
        <div
          className="flex items-center justify-between bg-n-000 max-h-[640px] overflow-y-auto"
          data-cy={`placement-${positionDetail?.id}`}
          key={index}
        >
          <div>
            <p className="typography-h500" data-cy="title-placement">
              {positionDetail?.name}
            </p>
            <p className="text-n-800" data-cy="region-placement">
              {positionDetail?.info?.region}
            </p>
          </div>
          <Button.Secondary
            onClick={() =>
              history.replace(
                `/users/${placement?.id}/placement/${positionDetail?.placementId}?source=direct-report&tab=objective`
              )
            }
            datacy={`view-profile-${positionDetail?.id}`}
          >
            {getObjectiveLocale('View Profile')}
          </Button.Secondary>
        </div>
      );
    });
  };

  const firstRender = useRef(false);
  useDeepEffect(() => {
    if (firstRender.current || switchType) {
      refetch();
    } else {
      firstRender.current = true;
    }
  }, [filter, extraFilter, switchType]);

  return (
    <div className={rest.withMarginTopAccordion ? 'mt-[40px]' : ''}>
      {isFetching && !isFetchingNextPage ? (
        <ObjectiveSkeleton addClass={'mt-[16px]'} />
      ) : (
        groupData?.data &&
        groupData?.data?.length > 0 &&
        groupData?.data?.map((data, index) => {
          const groupFilter = getGroupFilter(data);
          const fixedFilter = { ...extraFilter, ...groupFilter };
          let newParams = cloneDeep(filter);
          if (newParams?.owner?.ownerType) {
            delete newParams?.owner?.ownerType;
          }

          return (
            !isEmpty(data) && (
              <div
                data-cy="group-objectives"
                ref={
                  index == groupData?.data?.length - 1 &&
                  groupData?.data?.length > 9
                    ? intersectTarget
                    : undefined
                }
              >
                <Accordion customClass="mb-[16px]" useBorder key={index}>
                  <AccordionHeader
                    data={data}
                    group={group}
                    useFetchObjective={useFetchObjective}
                    withFilterMenu={withFilterMenu}
                    filter={filter}
                    additionalWrapperClick={additionalWrapperClick}
                    newParams={newParams}
                    fixedFilter={fixedFilter}
                    groupFilter={groupFilter}
                  />
                  {config?.showSubordinatesPage &&
                    (group === 'direct-report' ||
                      group === 'indirect-report') && (
                      <StatisticsOverallViewProgressCompact
                        visibility={['progress', 'status']}
                        page={group}
                        border="no-border"
                        assigneeId={data?.id}
                        placementId={groupFilter?.placementId || null}
                        filter={filter}
                      />
                    )}
                  <Accordion.Content
                    customClass={`overflow-y-auto
                    ${noMaxHeight ? '' : 'max-h-[568px]'}
                    `}
                    useSeparator
                    customSpaceClass={
                      page === 'scored-attribute-scoring' && 'p-[8px]'
                    }
                  >
                    {useFetchObjective ? (
                      <Objectives
                        filter={newParams}
                        extraFilter={fixedFilter}
                        withFilterMenu={withFilterMenu}
                        useFetchObjective={useFetchObjective}
                        userPermission={{ goalCreate: data.ableToCreateGoal }}
                        page={`${page}+${data?.user?.id || data?.id}`}
                        showBadgeObjectiveCount={showBadgeObjectiveCount}
                        isRefetch={isRefetch}
                        childComponent={true}
                        profileFilter={profileFilter}
                        groupName={data?.name}
                        customClass={customClass}
                        useObjectiveGroup
                        {...rest}
                      />
                    ) : (
                      data?.positions?.length > 0 && (
                        <DetailPlacements placement={data} />
                      )
                    )}
                  </Accordion.Content>
                </Accordion>
              </div>
            )
          );
        })
      )}
      {(groupData?.data?.length == 0 ||
        !groupData?.data ||
        isEmpty(groupData?.data?.[0])) && (
        <ObjectiveEmptyState
          page={page}
          customContainerClassname={
            page == 'scored-attribute-scoring' ? 'px-[24px]' : ''
          }
          {...rest}
        />
      )}
    </div>
  );
}

export default GroupModeObjectives;

import React from 'react';

import createClass from 'classnames';
import PropTypes from 'prop-types';

const Interfaces = {
  idStr: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func
};

const DefaultValue = {
  idStr: 'toogle-1',
  isChecked: false,
  onChange: () => {}
};

const SmallToggleSwitchPurple = ({
  idStr,
  isChecked,
  disabled = false,
  onChange,
  addClass,
  labelClass = '',
  dataCy = ''
}) => {
  return (
    <div
      className={`
          small-toogle-switch-purple
          ${createClass(addClass)}
          ${disabled ? 'disabled' : ''}
        `}
    >
      <label className={`switch ${labelClass}`}>
        <input
          type="checkbox"
          id={`${idStr}`}
          checked={isChecked}
          onChange={(e) => onChange(e)}
          data-cy={dataCy || idStr}
          disabled={disabled}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

SmallToggleSwitchPurple.propTypes = Interfaces;
SmallToggleSwitchPurple.defaultProps = DefaultValue;

export default SmallToggleSwitchPurple;

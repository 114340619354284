import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

const ObjectiveCategory = ({ objectiveDetail }) => {
  const { objectiveCategory } = objectiveDetail;
  return (
    <div className="px-[24px]">
      <div className="h-[48px] flex items-center" data-cy="sidebar-detail-type">
        <SVGIcon
          iconName="icon-category_outlined"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <div className="flex">
          <span className="mr-[4px]">
            {getObjectiveLocale(
              `${objectiveDetail.isProject ? 'Project' : 'Goal'} type is`
            )}
          </span>
          <p style={{ color: `#${objectiveCategory.colorHex}` }}>
            {objectiveCategory?.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ObjectiveCategory;

import React, { useRef, useState } from 'react';
import Linkify from 'react-linkify';

import { getBehaviours } from 'client/FormalReviewClient';
import { useAuth } from 'context/AuthContext';
import { useFormalReview } from 'context/FormalReviewContext';
import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Avatar from 'components/shared/Avatar';
import CircleComponent from 'components/shared/CircleComponent';
import SVGIcon from 'components/shared/SVGIcon';
import Textarea from 'src/components/formal-review/Textarea';

function MatrixScaleRow({
  object,
  options,
  setOverlaySidebar,
  answer,
  suggestion,
  setAnswer,
  type,
  preFill,
  hasPrefill,
  useComment
}) {
  const {
    data: { config }
  } = useAuth();
  let [{ involvedUser }, { setOverlayRightSidebarData }] = useFormalReview();

  const { id, name, description, expectedLevel, useBasic } = object;
  const [openComment, setOpenComment] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const commentBox = useRef();
  useClickOutside(commentBox, () => setOpenComment(false));
  const showCompetencyLevelSidebar = async (name, description, objectId) => {
    setOverlaySidebar(true);

    let query = {
      behavior_type: 'competency',
      parent_id: objectId,
      user_id: involvedUser.target.id
    };
    setOverlayRightSidebarData({ loading: true });
    const { data } = await getBehaviours(query);
    setOverlayRightSidebarData({ name, description, data, loading: false });
  };

  function isTruncated(el) {
    return el.scrollWidth > el.clientWidth;
  }

  return (
    <>
      <tr className={`${answer ? 'background-grey' : ''} flex min-h-[40px]`}>
        <td className="text-left pl-[8px]">
          <div className="tooltip-competency-container flex items-center w-[202px]">
            <div className="tooltip-competency typography-h100">
              Click to view details
            </div>
            <h6
              className="competency-name typography-link cursor-pointer"
              onClick={() => showCompetencyLevelSidebar(name, description, id)}
            >
              {name}
            </h6>
            {/* Don't delete (?) <span onClick={() => setBehaviourEvidence(object.id, object.name)} className="see-evidence-text">{getObjectiveLocale('See reference')}</span> */}
          </div>
        </td>
        {config.behaviorsFramework !==
          'development_dimensions_international' && (
          <td>{parseInt(expectedLevel) + 1}</td>
        )}
        {options.map((option) => {
          const isChecked = answer?.optionId == option.id;
          const isSuggestionChecked = suggestion?.optionId == option.id;

          return (
            <td key={option?.id}>
              <div className="cursor-pointer">
                {useBasic == false && option?.name.toLowerCase() === '1' ? (
                  <CircleComponent
                    backgroundColor="transparent"
                    borderColor="grey"
                    isChecked
                    pointerEvent="cursor-not-allowed"
                    opacity="0.16"
                    size="small"
                  />
                ) : (
                  <CircleComponent
                    onClick={() => setAnswer(object.id, option.id)} // put set answer here
                    isChecked={isChecked || isSuggestionChecked ? true : false}
                    size={isChecked || isSuggestionChecked ? 'normal' : 'small'}
                    opacity={isSuggestionChecked && !isChecked ? '0.4' : '1'}
                  >
                    {isChecked && (
                      <SVGIcon
                        iconName="icon-check"
                        size={20}
                        fillColor="var(--n-000)"
                      />
                    )}
                    {isSuggestionChecked && (
                      <SVGIcon
                        iconName="icon-check"
                        size={20}
                        fillColor="var(--n-000)"
                        customClass={isChecked ? 'd-none' : ''}
                      />
                    )}
                  </CircleComponent>
                )}
              </div>
            </td>
          );
        })}
        {hasPrefill && (
          <>
            {preFill?.comment ? (
              <td className="pr-[8px] relative">
                <Button.Tertiary onClick={() => setOpenComment(!openComment)}>
                  View
                </Button.Tertiary>
                {openComment && (
                  <div className="previous-comment-box" ref={commentBox}>
                    <div className="flex items-center header px-[16px] relative">
                      <Avatar
                        src={preFill?.actor?.profilePicture}
                        name={preFill?.actor?.name}
                        size={24}
                      />
                      <p
                        className="ml-[8px] typography-h100 text-n-800 truncate"
                        onMouseLeave={() => setOpenTooltip(false)}
                        onMouseEnter={(e) => {
                          if (isTruncated(e.currentTarget)) {
                            setOpenTooltip(true);
                          }
                        }}
                      >
                        {preFill?.actor?.name} · {preFill?.actor?.jobTitle}
                      </p>
                      {openTooltip && (
                        <p className="tooltip">
                          {preFill?.actor?.name} · {preFill?.actor?.jobTitle}
                        </p>
                      )}
                    </div>
                    <Linkify properties={{ target: '_blank' }}>
                      <p className="whitespace-pre-wrap break-words my-[8px] text-left overflow-y-auto max-h-[209px] px-[16px] py-[8px] border-top-300 border-bottom-300">
                        {preFill?.comment}
                      </p>
                    </Linkify>
                    <p className="typography-h100 text-n-600 text-left px-[16px]">
                      {getObjectiveLocale(
                        `Comment from ${
                          type === 'indirect_manager_review'
                            ? 'manager review'
                            : 'self review'
                        }`
                      )}
                    </p>
                  </div>
                )}
              </td>
            ) : (
              <td className="pr-[8px] relative text-n-600">--</td>
            )}
          </>
        )}
        {useComment !== 'no' && (
          <td className="pr-[8px]">
            <Textarea
              withTitle={false}
              comment={answer?.comment || ''}
              id={id}
              setAnswer={setAnswer}
              competencyAssessment={true}
              isError={
                useComment == 'required' && answer?.optionId && !answer?.comment
              }
            />
          </td>
        )}
      </tr>
    </>
  );
}

export default MatrixScaleRow;

import React from 'react';

function VerifySection({
  title,
  dataArray,
  withBorder = true,
  className = '',
  alignItemsClass = 'items-center',
  children
}) {
  return (
    <div className={`${withBorder ? 'border-bottom-n-400' : ''} ${className}`}>
      <h4 className="typography-h500 mb-[28px]">{title}</h4>
      {dataArray?.length > 0
        ? dataArray?.map((data, index) => {
            return (
              <div
                className={`flex ${alignItemsClass} ${
                  data?.listClassName ? data.listClassName : 'h-[48px]'
                }`}
                key={index}
              >
                <p
                  className={`flex items-center w-[216px] mr-[32px] typography-paragraph typography-secondary`}
                >
                  {data.name}
                </p>
                <div className="flex items-center">{data.content}</div>
              </div>
            );
          })
        : children}
    </div>
  );
}

export default VerifySection;

import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import objectiveComparativeKeys from 'consts/queryKeys/objectiveComparativeKeys';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import {
  useCreatePreset,
  useUpdatePreset
} from 'hooks/api/useObjectivesComparative';
import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';
import InputField from 'components/shared/InputField';
import RadioButton from 'components/shared/RadioButton';
import { restructureInsightsPayload } from 'pages/objectiveAnalytics/helper/objectiveAnalyticsHelper';

const CreateNewPreset = ({ presetName, setPresetName, showWarning }) => {
  return (
    <div className="w-full pl-[32px]">
      <InputField
        inputClass="typography-paragraph text-n-900 px-[16px] py-[4px]"
        placeholder="Preset name"
        dataCy="save-preset-name-input"
        value={presetName}
        inputError={showWarning}
        handleChange={(e) => setPresetName(e.currentTarget.value)}
      />
      {showWarning && (
        <p className="typography-h100 text-r-600 mt-[12px]">
          {getObjectiveLocale('The name is already exists')}
        </p>
      )}
    </div>
  );
};

const ModalSavePreset = ({ onClose, setClosePrompt }) => {
  const { addToast } = useToastContext();
  const { refetchQueries } = useRefetchQuery();

  const preset = useObjectiveComparative((state) => state.preset);
  const insights = useObjectiveComparative((state) => state.insights);
  const creator = useObjectiveComparative((state) => state.creator);
  const rows = useObjectiveComparative((state) => state.rows);
  const columns = useObjectiveComparative((state) => state.columns);

  const setPreset = useObjectiveComparative((state) => state.setPreset);

  const [presetName, setPresetName] = useState('');
  const [selectedOption, setSelectedOption] = useState('newPreset');
  const [showWarning, setShowWarning] = useState(false);

  const isDisabled = selectedOption === 'newPreset' && presetName.length === 0;

  const queryClient = useQueryClient();
  const { data: listPreset } = queryClient.getQueryData([
    'objective-comparative'
  ]);

  const { mutate: savePreset } = useCreatePreset();

  const { mutate: updatePreset } = useUpdatePreset(preset?.id);

  const checkPresetIsExists = (currentPreset) => {
    return listPreset?.some(
      (previousPreset) => previousPreset?.name === currentPreset
    );
  };

  const createNewPreset = () => {
    const payloadBody = {
      name: presetName,
      insights: restructureInsightsPayload(insights, columns)
    };

    savePreset(payloadBody, {
      onSuccess: (presetSaved) => {
        refetchQueries(objectiveComparativeKeys.default);
        setPreset({
          id: presetSaved?.data?.id,
          name: presetSaved?.data?.name,
          isShared: presetSaved?.data?.isShared,
          creator: presetSaved?.data?.creator
        });
        addToast({
          title: 'Success',
          msg: 'Your preset has been saved',
          type: 'success'
        });
        setClosePrompt(true);
        onClose();
      }
    });
  };

  const updateExistingPreset = () => {
    const payloadBody = {
      id: preset.id,
      name: preset.name,
      isShared: preset.isShared,
      creator: creator,
      columns: columns,
      rows: rows,
      insights: insights
    };

    updatePreset(payloadBody, {
      onSuccess: (presetSaved) => {
        refetchQueries(objectiveComparativeKeys.default);
        setPreset({
          id: presetSaved?.data?.id,
          name: presetSaved?.data?.name,
          isShared: presetSaved?.data?.isShared,
          creator: presetSaved?.data?.creator
        });
        addToast({
          title: 'Success',
          msg: 'Your preset has been updated',
          type: 'success'
        });
        setClosePrompt(true);
        onClose();
      }
    });
  };

  const savePresetHandler = () => {
    if (selectedOption === 'newPreset') {
      if (checkPresetIsExists(presetName)) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
        createNewPreset();
      }
    } else {
      updateExistingPreset();
    }
  };

  return (
    <Modal onClose={onClose} minWidth={480} customClass="w-[480px]">
      <Modal.Header title="Save Preset" onClose={onClose} />
      <Modal.Body>
        <div className="flex flex-col gap-[16px] w-full px-[24px] my-[4px]">
          <RadioButton
            name="new-preset"
            labelClass="typography-button text-n-900 ml-[10px]"
            checked={selectedOption == 'newPreset'}
            onClick={() => setSelectedOption('newPreset')}
          >
            {getObjectiveLocale('Save as a new preset')}
          </RadioButton>

          {selectedOption == 'newPreset' && (
            <CreateNewPreset
              presetName={presetName}
              setPresetName={setPresetName}
              showWarning={showWarning}
            />
          )}

          <RadioButton
            name="update-preset"
            labelClass="typography-button text-n-900 ml-[10px]"
            checked={selectedOption == 'updatePreset'}
            onClick={() => setSelectedOption('updatePreset')}
            disabled={preset.id === 0}
          >
            {getObjectiveLocale('Update existing preset')}
          </RadioButton>
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={{
          text: getObjectiveLocale('Save'),
          dataCy: 'save-preset',
          onClick: savePresetHandler,
          disabled: isDisabled
        }}
        secondaryButton={{
          text: getObjectiveLocale('Cancel'),
          dataCy: 'cancel-save',
          onClick: onClose
        }}
        useBorder={false}
      />
    </Modal>
  );
};

export default ModalSavePreset;

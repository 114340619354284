import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import { importResults } from 'client/FormalReviewClient';
import useChangeAssignment from 'context/ChangeAssignmentContext';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Table from 'components/design-system/table/Table';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import { FOUND_TAB_KEY } from '../../Utils/const';
import StatusColumn from './StatusColumn';

const TableAssignment = ({ tab, phase }) => {
  const { id: cycleId } = useParams();

  const filterById = useFilter((state) => state.filterById);

  const { excludeIds, setExcludeIds } = useChangeAssignment() || {};

  const { config } = useUser();

  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataPagination, setDataPagination] = useState(null);
  const [prevPagination, setPrevPagination] = useState(null);
  const [firstFetch, setFirstFetch] = useState(false);
  const [sortState, setSortState] = useState('asc');

  const sortNameHandler = (direction) => {
    setCurrentPage(1);
    const sortParams = {
      sortName: direction
    };
    setSortState(direction);
    getData(sortParams);
  };

  const headers = [
    {
      name: 'NAME',
      widthClass: 'w-[300px]',
      grow: true,
      sort: { default: 'asc', onSort: sortNameHandler }
    },
    { name: 'NIP', widthClass: 'w-[168px]', grow: true }
  ];

  let additionalColumn = [];

  if (tab == FOUND_TAB_KEY) {
    additionalColumn = [
      { name: 'WORK DURATION', widthClass: 'w-[208px]', grow: true },
      { name: 'REVIEWER', widthClass: 'w-[168px]', grow: true },
      {
        name: getObjectiveLocale(startCase(phase.name)) + ' STATUS',
        widthClass: 'w-[180px]',
        grow: true
      },
      { name: '', widthClass: 'w-[50px]' }
    ];
  } else {
    additionalColumn = [
      { name: 'REASON', widthClass: 'w-[208px]', grow: true }
    ];
  }

  const filterPosition = [
    {
      option: 'positions',
      title: 'Positions',
      dialog: 'position-assignment-status'
    }
  ];

  let filterMenu = config?.cycleDetailFilterOptions.filter(
    (filter) => filter != 'show' && filter != 'score' && filter != 'status'
  );

  const resultModifier = ({ positions, ...rest }) => {
    let newResult = {
      positions: positions?.map((position) =>
        position === 'ongoing'
          ? 'Current position'
          : position == 'previous'
          ? 'Previous position'
          : position
      ),
      ...rest
    };
    return newResult;
  };

  const getData = async (paramProps = {}) => {
    setDataTable([]);
    const { ...filterParams } = filterById?.[tab] || {};
    const defaultFilterData = {
      limit: 10,
      sortName: sortState
    };
    const params = {
      ...defaultFilterData,
      ...filterParams,
      ...paramProps,
      listType: tab
    };
    const id = cycleId;
    const { data, pagination } = await importResults(id, params);
    if (data?.length > 0) {
      setPrevPagination(dataPagination);
      setDataTable(data);
      setDataPagination(pagination);
      if (data?.length == 10) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setDataTable([]);
      setDataPagination(prevPagination);
      setHasMore(false);
    }
    if (!firstFetch) setFirstFetch(true);
  };

  const nextPageHandler = () => {
    getData({ olderThan: dataPagination?.next?.olderThan });
    setCurrentPage(currentPage + 1);
  };

  const prevPageHandle = () => {
    getData(
      dataTable?.length > 0
        ? { newerThan: dataPagination?.prev?.newerThan }
        : { olderThan: dataPagination?.next?.olderThan }
    );
    setCurrentPage(currentPage - 1);
  };

  const excludeIdsHandler = (id) => {
    let ids = [...excludeIds];
    const index = ids.findIndex((el) => el == id);
    if (index == -1) {
      ids.push(id);
    } else {
      ids.splice(index, 1);
    }
    setExcludeIds(ids);
  };

  const dateFormatter = (date) => {
    if (!date) return false;
    return dayjs(date).format('D MMM YY');
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (firstFetch) getData();
    setCurrentPage(1);
  }, [filterById[tab]]);

  return (
    <div className="mt-[16px]">
      <AdvancedFilter
        filterOptions={['search', 'menuFilter']}
        filterMenu={[...filterMenu, ...filterPosition]}
        id={tab}
        resultModifier={resultModifier}
      />
      <Table headers={[...headers, ...additionalColumn]}>
        {tab == FOUND_TAB_KEY
          ? dataTable?.map((data) => (
              <Table.Row
                key={data.id}
                customClass={
                  excludeIds.includes(data?.jobPlacement?.id)
                    ? 'opacity-30'
                    : ''
                }
              >
                <Table.Column>
                  <div className="flex flex-col">
                    <p className="typography-h400 text-n-900">{data?.name}</p>
                    <p className="typography-h100 text-n-800">
                      {data?.jobPlacement?.positionName}
                    </p>
                  </div>
                </Table.Column>
                <Table.Column>
                  <p className="typography-paragraph text-n-900">
                    {data?.externalId}
                  </p>
                </Table.Column>
                <Table.Column>
                  <p className="typography-paragraph text-n-900">
                    {dateFormatter(data?.jobPlacement?.startsAt)} -{' '}
                    {dateFormatter(data?.jobPlacement?.endsAt) || 'Present'}
                  </p>
                </Table.Column>
                <Table.Column>
                  <p className="typography-paragraph text-n-900">
                    {data?.manager?.name || '-'}
                  </p>
                </Table.Column>
                <Table.Column>
                  <div className="flex flex-col">
                    <StatusColumn
                      assignment={data?.assignmentProgresses}
                      phase={phase}
                    />
                  </div>
                </Table.Column>
                <Table.Column>
                  <div className="w-full justify-between hidden group-hover:flex">
                    <TooltipContainer
                      show
                      text={getObjectiveLocale(
                        excludeIds.includes(data?.jobPlacement?.id)
                          ? 'Include user'
                          : 'Exclude user'
                      )}
                    >
                      <SVGIcon
                        size="24"
                        iconName={
                          excludeIds.includes(data?.jobPlacement?.id)
                            ? 'icon-reply'
                            : 'icon-delete'
                        }
                        fillColor="var(--n-600)"
                        onClick={() =>
                          excludeIdsHandler(data?.jobPlacement?.id)
                        }
                        dataCy={`include-exclude-${data?.jobPlacement?.id}`}
                      />
                    </TooltipContainer>
                  </div>
                </Table.Column>
              </Table.Row>
            ))
          : dataTable?.map((data, index) => (
              <Table.Row key={index}>
                <Table.Column>
                  {data?.name == '-' ? (
                    <p className="typography-h400 text-n-900">-</p>
                  ) : (
                    <div className="flex flex-col">
                      <p className="typography-h400 text-n-900">{data?.name}</p>
                      <p className="typography-h100 text-n-800">
                        {data?.jobTitle || '-'}
                      </p>
                    </div>
                  )}
                </Table.Column>
                <Table.Column>
                  <p className="typography-paragraph text-n-900">
                    {data?.externalId}
                  </p>
                </Table.Column>
                <Table.Column>
                  <p className="typography-paragraph text-n-900">
                    {getObjectiveLocale(
                      data?.name == '-'
                        ? 'No match'
                        : 'Not a reviewee in this cycle'
                    )}
                  </p>
                </Table.Column>
              </Table.Row>
            ))}
      </Table>
      <div className="flex justify-end items-center pb-[65px] pt-[24px]">
        <PaginationModule
          pageNumber={currentPage}
          handlePrev={prevPageHandle}
          handleNext={nextPageHandler}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
};

export default TableAssignment;

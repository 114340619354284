"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zustand_1 = require("zustand");
const initialValue = {
    integrationType: 'project',
    data: { key: '', id: '' }
};
const useJira = (0, zustand_1.create)((set) => ({
    initialValueJira: initialValue,
    stepModal: 'choose-data',
    syncOption: 'all',
    isImportChild: false,
    removeChildrenLink: false,
    selectedData: '',
    selectedJIRA: initialValue,
    jiraData: [],
    additionalParams: {},
    setStepModal: (data) => set({ stepModal: data }),
    setSyncOption: (data) => set({ syncOption: data }),
    setImportChild: (data) => set({ isImportChild: data }),
    setRemoveChildrenLink: (data) => set({ removeChildrenLink: data }),
    setSelectedData: (data) => set({ selectedData: data }),
    setSelectedJIRA: (data) => set({ selectedJIRA: data }),
    setJiraData: (data) => set({ jiraData: data }),
    setAdditionalParams: (data) => set({ additionalParams: data })
}));
exports.default = useJira;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { getObjectiveLocale } from 'utils/HelperUtils';

import DropdownCustomEvery from 'components/dropdown/DropdownCustomEvery';
import DropdownCustomRepeat from 'components/dropdown/DropdownCustomRepeat';
import Modal from 'components/shared/modal/Modal';

function ModalCustomRepetition({
  closeModal,
  handleChange,
  defaultValue = []
}) {
  const [repeatData, setRepeatData] = useState('');
  const [everyData, setEveryData] = useState(1);
  const [displayData, setDisplayData] = useState('Day');
  const [typeData, setTypeData] = useState('days');
  const [recurrencesDate, setRecurrencesDate] = useState(null);
  const [tempDueDate, setTempDueDate] = useState(null);

  const formatStr = 'MMM DD, YYYY';
  function format(v) {
    return v ? v.format(formatStr) : '';
  }

  function isValidRange(v) {
    return v && v[0] && v[1];
  }

  useEffect(() => {
    switch (repeatData) {
      case 'Daily':
        setDisplayData('Day');
        setTypeData('days');
        break;
      case 'Weekly':
        setDisplayData('Week');
        setTypeData('weeks');
        break;
      case 'Monthly':
        setDisplayData('Month');
        setTypeData('months');
        break;
      case 'Yearly':
        setDisplayData('Year');
        setTypeData('years');
        break;
    }
    if (isValidRange(defaultValue)) {
      setRecurrencesDate(
        dayjs(defaultValue[0]).add(parseInt(everyData), typeData)
      );
      setTempDueDate(dayjs(defaultValue[1]).add(parseInt(everyData), typeData));
    }
  }, [repeatData, everyData, typeData]);

  return (
    <Modal
      className="modal-repetition"
      withCloseIcon={false}
      title="Custom Repetition"
      withPrimaryBtn={{
        title: getObjectiveLocale('Apply'),
        dataCy: 'submit-rep',
        onClick: () =>
          handleChange(recurrencesDate, tempDueDate, displayData, everyData)
      }}
      withSecondaryBtn={{
        title: getObjectiveLocale('Cancel'),
        dataCy: 'cancel-rep',
        onClick: () => closeModal(false)
      }}
    >
      <div className="repetition-content">
        <div className="repetition-details">
          <p className="repetition-title mb-[8px]">
            {getObjectiveLocale('Repeat')}
          </p>
          <DropdownCustomRepeat handleChange={setRepeatData} />
        </div>
        <div className="repetition-details">
          <p className="repetition-title mb-[8px]">
            {getObjectiveLocale('Every')}
          </p>
          <div className="repetition-every">
            <DropdownCustomEvery handleChange={setEveryData} />
            <span>{displayData}</span>
          </div>
        </div>
      </div>
      <div>
        <p className="repetition-title mb-[8px]">
          {getObjectiveLocale('Summary')}
        </p>
        {
          <p>
            {getObjectiveLocale('Every')} {everyData}{' '}
            {displayData.toLowerCase()}(s).
            {getObjectiveLocale('Next occurrence will start on')}{' '}
            {format(recurrencesDate)}, end on {format(tempDueDate)}
          </p>
        }
      </div>
    </Modal>
  );
}

export default ModalCustomRepetition;

import React, { useEffect, useRef, useState } from 'react';

import ctl from '@netlify/classnames-template-literals';
import PropTypes from 'prop-types';

import { useLocale } from 'hooks/useLocale';

import SVGIcon from 'components/shared/SVGIcon';

const InputField = React.forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [offsetWidth, setOffSetWidth] = useState(0);
  const spanRef = useRef();
  const { setBothLocale } = useLocale();

  const _onChange = (e) => {
    props.handleChange && props.handleChange(e);
  };

  const _onKeyPress = (e) => {
    props.handleKeyPress(e);
  };

  const inputCN = ctl(`
    styled-input
    ${props.showBorderOnHover && 'show-border-on-hover'}
    ${props.inputClass && props.inputClass}
    ${props.bgColorClass ? props.bgColorClass : 'bg-n-000'}
    ${
      (props.disabled || props.readonly) &&
      'cursor-not-allowed border border-solid border-gray bg-n-100 text-n-800'
    }
    ${props.inputError && 'input-error'}
  `);

  useEffect(() => {
    setOffSetWidth(spanRef?.current?.offsetWidth);
  }, [spanRef?.current?.offsetWidth]);

  return (
    <div
      className={`container-input ${props.className ? props.className : ''}`}
    >
      {props.label && (
        <label className="styled-label" htmlFor={props.htmlFor}>
          {setBothLocale(props.label)}
        </label>
      )}
      {props.search && (
        <SVGIcon
          size="18"
          fillColor="var(--base-600)"
          iconName="icon-search"
          customClass={
            props?.positionSearch
              ? props?.positionSearch
              : 'absolute left-8 mt-[10px]'
          }
        />
      )}
      <input
        ref={ref}
        className={inputCN}
        name={props.htmlFor}
        onChange={(!props.disabled || !props.readOnly) && _onChange}
        onKeyUp={props.handleKeyPress && _onKeyPress}
        id={props.htmlFor}
        type={props.password ? (isVisible ? 'text' : 'password') : props.type}
        value={props?.value}
        defaultValue={props.defaultValue}
        placeholder={setBothLocale(props.placeholder)}
        data-cy={props.dataCy}
        disabled={props.disabled}
        readOnly={props.readonly}
        autoFocus={props.autoFocus}
        onKeyDown={props.onKeyDown}
        onBlur={props?.onBlur}
        onClick={props.onClick}
        autoComplete={props?.autoComplete}
        min={props.min}
        onFocus={props.onFocus}
        style={{
          width: props.useAutoGrowInput ? `${offsetWidth + 8}px` : '100%'
        }}
      />
      {props.useAutoGrowInput && (
        <span
          ref={spanRef}
          className={`invisible ${inputCN}`}
          style={{
            width: 'fit-content',
            height: '0px',
            paddingTop: '0px',
            paddingBottom: '0px',
            borderTop: '0px',
            borderBottom: '0px'
          }}
        >
          {props?.value}
        </span>
      )}
      {props.password && (
        <SVGIcon
          iconName="icon-remove_red_eye"
          fillColor="var(--n-600)"
          onClick={() => setIsVisible(!isVisible)}
          size="22"
          customClass="absolute right-8 mt-[36px]"
        />
      )}
      {props.calendar && (
        <SVGIcon
          iconName="icon-calendar_today"
          fillColor={
            props.disabled || props.readonly
              ? 'var(--n-600)'
              : 'var(--base-600)'
          }
          size="24"
          customClass="absolute right-8 mt-[32px]"
        />
      )}
      {props.cancelIcon && (
        <SVGIcon
          iconName="icon-cancel"
          fillColor="var(--n-600)"
          onClick={() => props.cancelChanges()}
          size="24"
          disabled={props.disabled}
          customClass={`absolute right-8 ${
            props.label ? props.customClassIconLabel || 'mt-[36px]' : 'mt-[4px]'
          }`}
        />
      )}
    </div>
  );
});

InputField.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  dataCy: PropTypes.string
};

InputField.displayName = 'InputField';

export default InputField;

import React from 'react';

import BoardCard from 'components/tasks/board/BoardCard';
import BoardCardContent from 'components/tasks/board/BoardCardContent';

const BoardDropzone = ({
  groupData,
  sectionData,
  index,
  top,
  height,
  dragAndDrop,
  setShowSkeleton,
  isProject
}) => {
  return isProject ? (
    <div
      groupId={groupData?.id}
      groupName={groupData?.name}
      sectionId={sectionData?.id}
      sectionName={sectionData?.name}
      index={index}
      style={{
        position: 'absolute',
        top: top,
        left: 0,
        zIndex: 100,
        width: '100%',
        height: height,
        display: 'flex'
      }}
      className={
        (dragAndDrop?.draggedId?.groupId !== groupData?.id ||
          dragAndDrop?.draggedId?.sectionId !== sectionData?.id) &&
        dragAndDrop?.draggable
          ? ''
          : 'invisible'
      }
      onDragEnter={(e) => dragAndDrop?.onDragEnter(e, setShowSkeleton)}
      onDragOver={(e) => dragAndDrop?.onDragOver(e)}
      onDragLeave={(e) => dragAndDrop?.onDragLeave(e, setShowSkeleton)}
      onDrop={(e) => dragAndDrop?.onDrop(e, setShowSkeleton, index)}
    />
  ) : (
    <div
      groupId={groupData?.id}
      groupName={groupData?.name}
      index={index}
      style={{
        position: 'absolute',
        top: top,
        left: 0,
        zIndex: 100,
        width: '100%',
        height: height,
        display: 'flex'
      }}
      className={
        dragAndDrop?.draggedId?.groupId !== groupData?.id &&
        dragAndDrop?.draggable
          ? ''
          : 'invisible'
      }
      onDragEnter={(e) => dragAndDrop?.onDragEnter(e, setShowSkeleton)}
      onDragOver={(e) => dragAndDrop?.onDragOver(e)}
      onDragLeave={(e) => dragAndDrop?.onDragLeave(e, setShowSkeleton)}
      onDrop={(e) => dragAndDrop?.onDrop(e, setShowSkeleton, index)}
    />
  );
};

const CardSkeleton = ({ index, draggedId, shadowStyle, isProject }) => {
  return (
    <div
      className="relative min-w-[328px] opacity-50 mb-[8px]"
      style={shadowStyle}
    >
      <BoardCardContent
        task={draggedId?.task}
        groupId={draggedId?.groupId}
        sectionId={draggedId?.sectionId}
        level={0}
        borderRadiusCN={
          draggedId?.showChild ? 'rounded-t-[4px]' : 'rounded-[4px]'
        }
        isProject={isProject}
      />
      {draggedId?.showChild &&
        draggedId?.objectiveChildren.map((child, childIndex) => (
          <BoardCard
            key={`subtask-${child?.value?.id ? child?.value?.id : childIndex}`}
            taskNode={child}
            groupId={draggedId?.groupId}
            groupName={draggedId?.groupName}
            sectionId={draggedId?.sectionId}
            sectionName={draggedId?.sectionName}
            level={1}
            index={index}
            isLastChild={childIndex === draggedId?.objectiveChildren.length - 1}
          />
        ))}
    </div>
  );
};

BoardDropzone.CardSkeleton = CardSkeleton;

export default BoardDropzone;

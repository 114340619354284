import React, { useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import { setActiveNavigator } from 'components/admin/organization/OrganizationHelper';
import ContentNavigator from 'components/shared/ContentNavigator';

import FinalScoreFormula from './FinalScoreFormula';
import PerformanceProfileWeightConfig from './PerformanceProfileWeightConfig';

const Configuration = ({
  handleEditReviewAspects,
  inputFinalScore,
  setInputFinalScore,
  _putFinalScoreFormula,
  dataList,
  reviewAspectList,
  finalScoreFormula,
  weightSetting
}) => {
  const [activeSection, setActiveSection] = useState('final-score-formula');
  let list = [
    { id: 'final-score-formula', name: finalScoreFormula },
    { id: 'weight-setting', name: weightSetting }
  ];
  return (
    <div className="container-landing-behavior-model">
      <div className="fixed right-0 min-w-[230px] z-10 mt-[24px] mr-[40px]">
        <ContentNavigator
          title="CONTENTS"
          activeSection={activeSection}
          listNavigatorContents={list}
          sectionContent="scrollable-content"
        />
      </div>
      <div
        data-cy="competency-model-scrollable-content"
        id="scrollable-content"
        className="h-[calc(100%-137px)] overflow-auto relative bg-n-000"
        onScroll={(e) =>
          setActiveNavigator(e, list, setActiveSection, true, 80)
        }
      >
        <FinalScoreFormula
          setFormulaFinalScore={setInputFinalScore}
          formulaFinalScore={inputFinalScore}
          putData={_putFinalScoreFormula}
        />
        <div id="weight-setting">
          <p className="typography-h500 mb-[4px]">{weightSetting}</p>
          <p className="typography-paragraph typography-secondary mb-[24px]">
            {getObjectiveLocale(
              'Define the weight of each scoring system below.'
            )}
          </p>
          <PerformanceProfileWeightConfig
            dataList={dataList}
            handleEditReviewAspects={handleEditReviewAspects}
            reviewAspectList={reviewAspectList}
          />
        </div>
      </div>
    </div>
  );
};
export default Configuration;

import React, { useEffect, useRef, useState } from 'react';

import { format } from 'timeago.js';

import { editComment, getReplyComments } from 'client/CultureClient';
import { useReload } from 'context/ReloadContext';
import useClickOutside from 'hooks/useClickOutside';
import { getCultureLocale, parseCaption } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Avatar from 'components/shared/Avatar';
import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';

const PostCommentItem = ({
  comment,
  commentIndex,
  onClickReply,
  postId,
  lastComment,
  parentIndex = null,
  parentId = null,
  parentRepliesData,
  setParentRepliesData,
  selectedComment,
  setSelectedComment,
  setIsShowModal,
  reloadAfterEditDelete
}) => {
  const { reloadCommentReply, reload } = useReload();
  const [showSeeMoreCaption, setShowSeeMoreCaption] = useState(false);
  const [repliesData, setRepliesData] = useState([]);
  const [lastIdComment, setLastIdComment] = useState('none');
  const [isHoveringComment, setIsHoveringComment] = useState(false);
  const [isMoreOptionsClicked, setIsMoreOptionsClicked] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [previousRepliesCount, setPreviousRepliesCount] = useState('');
  let dropdownOptions = [
    {
      text: getCultureLocale('Edit'),
      iconName: 'icon-create',
      onClick: () => {
        handleClickEditDelete('edit');
      }
    },
    {
      text: getCultureLocale('Delete'),
      iconName: 'icon-delete',
      onClick: () => {
        handleClickEditDelete('delete');
      }
    }
  ];

  let commentRef = useRef();
  let dropdownRef = useRef();

  useClickOutside(dropdownRef, () => setIsMoreOptionsClicked(false));

  const getLines = (ref) => {
    const element = getComputedStyle(ref.current);
    const height = parseInt(element.height);
    const lineHeight = parseInt(element.lineHeight);

    return height / lineHeight;
  };

  const checkLines = () => {
    const lines = getLines(commentRef);
    if (lines > 2) {
      commentRef.current.classList.add('truncate-2-lines');
      setShowSeeMoreCaption(true);
    }
  };

  const deleteTruncateClass = () => {
    commentRef.current.classList.remove('truncate-2-lines');
    setShowSeeMoreCaption(false);
  };

  const _getReplyComment = async () => {
    const params = {
      limit: 3,
      lastId: lastIdComment === 'none' ? 0 : lastIdComment
    };

    const { data, pagination, previous_replies_count, isSuccess } =
      await getReplyComments(postId, comment.id, params);
    if (isSuccess) {
      setLastIdComment(pagination?.next?.olderThan);
      setPreviousRepliesCount(previous_replies_count);
      const sortedData = data.sort(
        (prev, next) => new Date(prev.createdAt) - new Date(next.createdAt)
      );
      setRepliesData(
        lastIdComment === 'none' ? sortedData : [...sortedData, ...repliesData]
      );
    }
  };

  const getFunctionButtonReply = () => {
    if (repliesData?.length === comment?.childrenCount) {
      setLastIdComment('none');
      setRepliesData([]);
    } else {
      _getReplyComment();
    }
  };

  const getTextButtonReply = () => {
    const totalReplyComment = comment?.childrenCount;
    let textButton;
    if (repliesData?.length === 0) {
      textButton = `Show ${totalReplyComment} ${
        totalReplyComment == 1 ? 'reply' : 'replies'
      }`;
    } else if (repliesData?.length === comment?.childrenCount) {
      textButton = 'Hide replies';
    } else if (repliesData?.length > 0) {
      textButton = `Show ${previousRepliesCount} previous ${
        previousRepliesCount == 1 ? 'reply' : 'replies'
      }`;
    }

    return textButton;
  };

  const _editComment = async () => {
    const requestBody = {
      comment: {
        content: selectedComment?.content,
        metaMentions: comment?.metaMentions
      }
    };
    const { isSuccess } = await editComment(postId, comment.id, requestBody);
    if (isSuccess) {
      reloadAfterEditDelete('edit');
      closeCommentEditor();
    }
  };

  const handleClickEditDelete = (type) => {
    if (type === 'edit') {
      setIsEditing(true);
    } else if (type === 'delete') {
      setIsShowModal(true);
    }

    setSelectedComment({
      content: comment.content,
      index: commentIndex,
      id: comment.id,
      parentIndex: parentIndex,
      parentId: parentId,
      repliesData: parentRepliesData ? parentRepliesData : repliesData,
      setRepliesData: setParentRepliesData
        ? setParentRepliesData
        : setRepliesData
    });
  };

  const closeCommentEditor = () => {
    setIsEditing(false);
    setIsMoreOptionsClicked(false);
  };

  useEffect(() => {
    commentRef?.current && checkLines();
  }, []);

  useEffect(() => {
    if (
      reloadCommentReply?.id === comment?.id &&
      reloadCommentReply?.replyData
    ) {
      setRepliesData([...repliesData, reloadCommentReply?.replyData]);
      reload({ reloadCommentReply: { id: 0, replyData: {} } });
    }
  }, [reloadCommentReply]);

  return (
    <>
      <div
        className={`comment-item w-full
          ${parentId ? 'pl-[68px] pr-[24px]' : 'px-[24px]'}
          ${commentIndex === 0 ? 'pt-[16px]' : 'pt-[12px]'}
          ${
            repliesData?.length > 0
              ? 'pb-[8px]'
              : lastComment
              ? 'pb-[16px]'
              : 'pb-[12px]'
          }`}
        key={comment.id}
        onMouseEnter={() => setIsHoveringComment(true)}
        onMouseLeave={() => setIsHoveringComment(false)}
      >
        {!comment.deleted ? (
          <Avatar
            src={
              comment.user.profilePicture
                ? comment.user.profilePicture.secureUrl
                : null
            }
            name={comment.user.fullName}
            dataCy="comment-photo"
            size={parentId ? 24 : 32}
            style={{ marginRight: '12px' }}
          />
        ) : (
          <div
            className={`w-[32px] h-[32px] min-w-[32px] rounded-[50px] bg-n-300 mr-[16px] flex items-center justify-center mt-[4px]`}
          >
            <SVGIcon
              size="16"
              iconName="icon-delete_forever"
              fillColor="var(--n-600)"
            />
          </div>
        )}
        <div className="comment-detail w-full">
          {!comment?.deleted && (
            <div className="w-full flex justify-between items-center mb-[8px]">
              <div
                className="h-[24px] flex items-center"
                data-cy="comment-fullname"
              >
                <p className="typography-h400">{comment.user.fullName}</p>
              </div>
              {(isHoveringComment || isMoreOptionsClicked) && !isEditing && (
                <div ref={dropdownRef} className="h-[24px]">
                  <Dropdown>
                    <Dropdown.Trigger
                      onClick={() =>
                        setIsMoreOptionsClicked(!isMoreOptionsClicked)
                      }
                    >
                      <SVGIcon
                        size="24"
                        iconName="icon-keyboard_control"
                        fillColor="var(--n-600)"
                      />
                    </Dropdown.Trigger>
                    <Dropdown.MenuItems
                      options={dropdownOptions}
                      position="right"
                    />
                  </Dropdown>
                </div>
              )}
            </div>
          )}
          <div
            className={`w-full word-break webkit-box typography-paragraph
              ${
                comment?.deleted
                  ? 'rounded-[4px] bg-n-200 text-n-600 w-full h-[40px] px-[16px] flex items-center'
                  : 'text-n-800'
              }`}
            data-cy="comment-caption"
            ref={commentRef}
          >
            {!isEditing ? (
              <div className="h-full flex items-center">
                <div
                  dangerouslySetInnerHTML={{
                    __html: parseCaption(
                      comment?.content,
                      comment?.metaMentions
                    )
                  }}
                />
              </div>
            ) : (
              <TextArea
                id="edit-comment"
                cyName="edit-comment"
                defaultValue={comment.content}
                textPlaceholder=""
                onHandleChange={(e) =>
                  setSelectedComment({
                    ...selectedComment,
                    content: e.currentTarget.value
                  })
                }
              />
            )}
          </div>
          {showSeeMoreCaption && (
            <span
              className="text-base-600 cursor-pointer"
              onClick={() => deleteTruncateClass()}
            >
              {getCultureLocale('See more')}
            </span>
          )}
          {!isEditing ? (
            !comment?.deleted && (
              <div className="typography-h100 text-n-600 mt-[8px] flex">
                {format(comment.createdAt)}
                {comment?.edited && (
                  <p className="typography-h100 text-n-600 ml-[4px]">
                    {' '}
                    ∙ Edited
                  </p>
                )}
                <p
                  className="ml-[24px] typography-h200 text-n-800 cursor-pointer"
                  data-cy="reply-comment"
                  onClick={() =>
                    onClickReply(parentId ? parentId : comment?.id)
                  }
                >
                  {getCultureLocale('Reply')}
                </p>
              </div>
            )
          ) : (
            <div>
              <Button.Tertiary onClick={closeCommentEditor}>
                <span className="text-n-600">{getCultureLocale('Cancel')}</span>
              </Button.Tertiary>
              <Button.Tertiary onClick={_editComment}>
                {getCultureLocale('Save')}
              </Button.Tertiary>
            </div>
          )}
          {comment?.childrenCount > 0 && (
            <>
              {/* IF REPLY MORE THAN 1 ITEMS */}
              <p
                className={`typography-h100 text-n-600 mt-[16px] w-full flex items-center cursor-pointer`}
                onClick={(e) => {
                  e.stopPropagation(), getFunctionButtonReply();
                }}
                data-cy="reply-text"
              >
                {getCultureLocale(getTextButtonReply())}
              </p>
            </>
          )}
        </div>
      </div>
      {/* IF COMMENT REPLY  */}
      {repliesData?.length > 0 &&
        repliesData.map((replies, index) => (
          <PostCommentItem
            key={index}
            comment={replies}
            commentIndex={index}
            postId={postId}
            onClickReply={onClickReply}
            parentIndex={commentIndex}
            parentId={comment?.id}
            parentRepliesData={repliesData}
            setParentRepliesData={setRepliesData}
            selectedComment={selectedComment}
            setSelectedComment={setSelectedComment}
            setIsShowModal={setIsShowModal}
            reloadAfterEditDelete={reloadAfterEditDelete}
          />
        ))}
    </>
  );
};

export default PostCommentItem;

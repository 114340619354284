import React, { useState } from 'react';

import { getGroupData } from 'client/admin/UserManagementClient';

import Dialog from 'components/shared/Dialog/Dialog';
import SVGIcon from 'components/shared/SVGIcon';
import Tooltips from 'components/shared/Tooltips/Tooltips';

const PositionField = ({
  selected,
  label,
  customClass,
  type = '',
  changeSelected,
  readonly
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogData, setDialogData] = useState([]);

  const disabled = selected?.involvedMembershipsByInfo?.length > 0 || readonly;

  const _getGroupData = async (search = '') => {
    const params = {
      groupType: `${type}_group`,
      q: search,
      sortBy: 'name',
      order: 'asc',
      state: 'active'
    };
    const { data } = await getGroupData(params);
    if (data) {
      const defaultData = { id: 0, name: 'Default' };
      let dataList = data;
      if (type === 'behavior' && data?.length > 0) {
        dataList = dataList?.filter(
          (value) =>
            !value?.involvedMembershipsByInfo ||
            value?.involvedMembershipsByInfo?.length === 0
        );
      }
      setDialogData(
        data.length > 0 ? [defaultData, ...dataList] : [defaultData]
      );
    }
  };

  return (
    <div className={`${customClass} mt-[24px] relative`}>
      <div className="typography-h100 text-n-800 mb-[8px]">{label}</div>
      <Tooltips text={selected?.name} isEllipsis={selected?.name?.length > 30}>
        <div
          className={`w-[344px] h-[36px] border border-solid border-n-400 rounded-[4px] p-16 flex items-center justify-between cursor-pointer ${
            disabled ? 'bg-n-300 cursor-not-allowed' : ''
          }`}
          onClick={() => {
            !disabled && setIsOpen(true);
            _getGroupData();
          }}
        >
          <div
            className={`multiline-text-1 ${
              disabled ? 'text-n-600' : 'text-n-800'
            }`}
          >
            {selected?.name}
          </div>
          <SVGIcon
            size="24"
            iconName="icon-arrow_drop_down"
            fillColor={disabled ? 'var(--n-600)' : 'var(--n-800)'}
          />
        </div>
      </Tooltips>

      {isOpen && dialogData.length > 0 && (
        <Dialog
          noSearch
          title=""
          placement="fixed-bottom"
          containerClassName="w-[344px] mr-[0px]"
          handleChange={(sel, selectedValue) => {
            changeSelected(
              sel,
              type === 'rating_template'
                ? 'ratingTemplateGroup'
                : `${type}Group`,
              'groups',
              'group'
            ),
              setIsOpen(false);
          }}
          listItem={{
            template: 'default',
            config: {
              labelText: 'name',
              imgSize: '24',
              fillColor: 'var(--n-600)'
            }
          }}
          staticOptions={dialogData}
          setShowDialog={setIsOpen}
        />
      )}
    </div>
  );
};

export default PositionField;

import React, { useEffect } from 'react';

import { SplideSlide } from '@splidejs/react-splide';
import isEmpty from 'lodash/isEmpty';

import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { userAgentUtils } from 'utils/UserAgentUtils';

import HeaderMobile from 'components/admin/HeaderMobile';
import AWSImage from 'components/design-system/AWSImage';
import SVGIcon from 'components/shared/SVGIcon';
import SlickSlider from 'components/slider/SlickSlider';

function LandingPage({ forceDisplayWeb = false }) {
  const { organization, appType, user, config } = useUser();

  let title = '';
  let description = '';
  let performanceUrlAndroid = null;
  let cultureUrlAndroid = null;
  let performanceUrlIos = null;
  let cultureUrlIos = null;
  let platform = userAgentUtils();
  const isMobileDevice = platform === 'Android' || platform === 'iOS';
  const isAdmin =
    appType === 'culture'
      ? user?.cultureRole === 'culture_admin'
      : user?.role === 'admin';
  const widthCarousel = screen?.width > 370 ? 240 : 200;
  const currentCultureAndroidUrl =
    config?.cultureApkUrl || organization?.cultureAndroidUrl;
  const currentCultureIosUrl =
    config?.cultureIpaUrl || organization?.cultureIosUrl;
  const cultureUrl =
    platform === 'Android' ? currentCultureAndroidUrl : currentCultureIosUrl;
  const performanceUrl =
    platform === 'Android' ? organization?.androidUrl : organization?.iosUrl;
  // display web if url empty or screen smaller than 768
  const displayWeb = isEmpty(
    appType === 'culture' ? cultureUrl : performanceUrl
  );
  const organizationName =
    appType === 'culture'
      ? organization?.cultureAppName
      : organization?.appName;
  let url = window?.location;
  const domain = url?.origin;
  const pathname = url?.pathname;
  const isMissionLandingPage = pathname?.includes('mission');
  const usp = new URLSearchParams(url?.search);

  const carouselData = [
    {
      title: getObjectiveLocale('1) Go to Settings and Select General'),
      image: Config.asset.culture.installStep1
    },
    {
      title: getObjectiveLocale('2) Select Profile & Device Management'),
      image: Config.asset.culture.installStep2
    },
    {
      title: getObjectiveLocale('3) Select Bank Central ASIA, PT TBK'),
      image: Config.asset.culture.installStep3
    },
    {
      title: getObjectiveLocale('4) Tap Trust “Bank Central Asia, PT TBK”'),
      image: Config.asset.culture.installStep4
    }
  ];

  if (displayWeb) {
    platform = 'unknown';
    title = 'Access this page on your Desktop Browser';
    description =
      'Please access the application on your computer desktop web browser';
  } else if (
    platform != 'android' &&
    platform != 'iOS' &&
    window.screen.width > 768
  ) {
    platform = 'desktop';
    if (isMissionLandingPage) {
      title = 'Please access this page from mobile apps.';
      description = 'Currently it is only accessible on mobile apps.';
    } else {
      title = 'Access this page on your Mobile Web Browser';
      description =
        'Hi, thank you for opening this page, Please open this page on your mobile browser to Download the App';
    }
    if (appType === 'performance') {
      performanceUrlAndroid = organization?.androidUrl;
      performanceUrlIos = organization?.iosUrl;
    } else if (appType === 'culture') {
      cultureUrlAndroid = currentCultureAndroidUrl;
    } else {
      performanceUrlAndroid = organization?.androidUrl;
      cultureUrlAndroid = currentCultureAndroidUrl;
    }
  } else if (platform == 'Android') {
    if (isMissionLandingPage) {
      title = 'Please access this page from mobile apps.';
      description = 'Click the icon below to download it from Play Store.';
    } else {
      title = 'Access mobile app on your android';
      description = 'Please download your app below here';
    }
    if (appType === 'performance') {
      performanceUrlAndroid = organization?.androidUrl;
    } else if (appType === 'culture') {
      cultureUrlAndroid = currentCultureAndroidUrl;
    } else {
      performanceUrlAndroid = organization?.androidUrl;
      cultureUrlAndroid = currentCultureAndroidUrl;
    }
  } else if (platform == 'iOS') {
    if (isMissionLandingPage) {
      title = 'Please access this page from mobile apps.';
      description = 'Click the icon below to download it from App Store.';
    } else {
      title = 'Access mobile app on your iOS';
      description = 'Please download your app below here';
    }
    if (appType === 'performance') {
      performanceUrlIos = organization?.iosUrl;
    } else if (appType === 'culture') {
      cultureUrlIos = currentCultureIosUrl;
    } else {
      performanceUrlIos = organization?.iosUrl;
      cultureUrlIos = currentCultureIosUrl;
    }
  }

  useEffect(() => {
    if (usp && usp.get('token')) {
      window.history.replaceState(null, null, `${domain}${pathname}`);
    }
  }, [usp]);

  return (
    <>
      {window.innerWidth < 768 && user?.id && <HeaderMobile />}
      <div className="landing-page">
        {(isMobileDevice || platform === 'unknown') && (
          <>
            <div className="wrapper-diamond-image">
              <div className="diamond-image relative" />
            </div>
            <div className="wrapper-two-diamond-image">
              <div className="two-diamond-image relative" />
            </div>
          </>
        )}
        <div
          className={`landing-page-content text-center ${
            isMobileDevice ? 'pt-[52px]' : ''
          }`}
        >
          <p className="mt-[16px] typography-h900">
            {getObjectiveLocale(title)}
          </p>
          <p className={`text-n-800 mt-[16px] typography-paragraph`}>
            {getObjectiveLocale(description)}
          </p>
          {platform === 'unknown' && (
            <img
              className="laptop-image my-[32px]"
              src={Config.asset.general.laptopAsset}
            />
          )}
          {platform === 'desktop' && (
            <>
              <img
                src={Config.asset.culture.cultureInPhone}
                className="culture-in-app"
              />
              <div className="text-n-800 typography-paragraph">
                {getObjectiveLocale(
                  'Copy this link on your mobile browser to Download Android or IOS App Above'
                )}
              </div>
            </>
          )}
          {isMobileDevice && (
            <div className="app-wrapper">
              {performanceUrlAndroid && (
                <a
                  className={`app-content ${
                    performanceUrlIos || cultureUrlAndroid ? 'mr-[16px]' : ''
                  }`}
                  href={performanceUrlAndroid}
                >
                  <AWSImage src={organization?.logoUrl} />
                  <div className="typography-h500">{organization?.appName}</div>
                </a>
              )}
              {cultureUrlAndroid && (
                <a
                  className={`app-content ${
                    performanceUrlIos || cultureUrlIos ? 'mr-[16px]' : ''
                  }`}
                  href={cultureUrlAndroid}
                >
                  <AWSImage
                    src={organization?.cultureLogoUrl}
                    className="culture"
                  />
                  <div className="typography-h500">
                    {organization?.cultureAppName}
                  </div>
                </a>
              )}
              {performanceUrlIos && (
                <a
                  className={`app-content ${
                    performanceUrlIos || cultureUrlIos ? 'mr-[16px]' : ''
                  }`}
                  href={performanceUrlIos}
                >
                  <AWSImage src={organization?.logoUrl} />
                  <div className="typography-h500">{organization?.appName}</div>
                </a>
              )}
              {cultureUrlIos && (
                <a className="app-content" href={cultureUrlIos}>
                  <AWSImage
                    src={organization?.cultureLogoUrl}
                    className="culture"
                  />
                  <div className="typography-h500">
                    {organization?.cultureAppName}
                  </div>
                </a>
              )}
            </div>
          )}
          {platform === 'iOS' && organization?.identifier === 'bca' && (
            <>
              <SVGIcon
                iconName="icon-double_arrow_down"
                size={24}
                fillColor="var(--base-500)"
              />
              <div className="typography-h700 mt-[24px] mb-[16px]">
                {getObjectiveLocale('How to Install')}
              </div>
              <SlickSlider
                useDots={false}
                isInfinite={false}
                slidesToShow={1}
                slidesToScroll={1}
                gap="8px"
                fixedWidth={`${widthCarousel}px`}
              >
                {carouselData.map((step, idx) => (
                  <SplideSlide key={idx}>
                    <div className="install-app-mobile text-n-800 typography-paragraph">
                      <p>{step.title}</p>
                      <img
                        src={step.image}
                        className={`culture-in-app width-${widthCarousel}`}
                      />
                    </div>
                  </SplideSlide>
                ))}
              </SlickSlider>
            </>
          )}
          {(platform == 'unknown' || (isMobileDevice && isAdmin)) && (
            <div className="text-n-800 typography-paragraph mb-[4px]">
              {getObjectiveLocale(
                'You can type this link on your web browser to access'
              )}{' '}
              {organizationName || 'Happy5 Performance'}
            </div>
          )}
          {(platform == 'desktop' ||
            platform == 'unknown' ||
            (isMobileDevice && isAdmin)) && (
            <div className="text-n-800">{domain}</div>
          )}
          <p
            className={`typography-h100 text-n-600 mb-[4px] ${
              isMobileDevice ? 'mt-[48px]' : 'mt-[64px]'
            }`}
          >
            {getObjectiveLocale(
              'If you find any difficulty, please tell us at'
            )}
          </p>
          <div className="typography-h400 text-n-800">
            {config?.supportEmail || getObjectiveLocale('support@happy5.co')}
          </div>
          {platform == 'desktop' && organization?.identifier === 'bca' && (
            <>
              <div className="typography-h900 install-title">
                {getObjectiveLocale('How to Install App (IOS Only)')}
              </div>
              <img src={Config.asset.culture.howToInstallApp} />
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default LandingPage;

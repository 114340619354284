import React from 'react';

import { useFormalReview } from 'context/FormalReviewContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

import './WhiteCardEvidence.scss';

const WhiteCardEvidence = ({
  title,
  children,
  withCloseButton = true,
  closeBehaviour,
  topCornerRight
}) => {
  let [{ activeEvidence }] = useFormalReview();
  return (
    <React.Fragment>
      <div className="source-evidence-card mb-[16px]">
        <div className="wrapper-title">
          <h4
            className={`card-title typography-h500 ${
              activeEvidence == 'OnGoing' ? 'mb-[12px]' : 'mb-[16px]'
            }`}
          >
            {getObjectiveLocale(title)}
          </h4>
          {withCloseButton && (
            <SVGIcon
              iconName="icon-clear"
              size="24"
              fillColor="var(--n-600)"
              customClass="close-icon"
              onClick={closeBehaviour}
            />
          )}
          {topCornerRight && (
            <span className="top-corner-right-content">{topCornerRight}</span>
          )}
        </div>
        {children}
      </div>
    </React.Fragment>
  );
};
export default WhiteCardEvidence;

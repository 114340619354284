import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { capitalize, getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import ModalV2 from 'components/design-system/Modal';
import ListEmptyState from 'components/shared/ListEmptyState';

import HighlightedIllustration from './Info/HighlightedIllustration';

function ObjectiveEmptyState({
  type = 'goal',
  page,
  title,
  description,
  link,
  withRedirectButton = true,
  svgEmptyState = 'icon-lock_outline',
  useSvg = false,
  cantSee = false,
  isProfile = false,
  isSearch,
  switcherValue,
  userPermission,
  emptyStateAction,
  customContainerClassname = '',
  isAbleToCreate = true,
  size = ''
}) {
  const history = useHistory();
  const { url } = useUrl();
  const params = useParams();
  const { config, user } = useUser();
  const [showModal, setShowModal] = useState(false);
  const currentPermission = userPermission
    ? userPermission
    : config?.permissions;

  const tabValidation = (type) => {
    return location.search.includes(type);
  };

  const closeModal = () => setShowModal(false);
  let titleText,
    descriptionText,
    redirectLink,
    textOnButton,
    datacy,
    secondaryDescription = true,
    privateCustomClass = '',
    iconBtn = 'icon-add',
    ModalContent = () => <></>,
    modalProps = {
      onClose: closeModal
    },
    permission = currentPermission?.goalCreate,
    extraElementBelow = <></>,
    extraElementAbove = <></>;

  titleText =
    title || `${getObjectiveLocale('No objective has been created yet')}`;
  descriptionText =
    description ||
    `${getObjectiveLocale(
      'The objective establishes a sense of direction that guides your decisions and task to be more aligned with your company mission.'
    )}`;
  redirectLink = link || `/create-objective/goal`;
  textOnButton = `${getObjectiveLocale('Create New Objective')}`;

  if (isSearch) {
    titleText =
      title ||
      `${getObjectiveLocale(
        `No ${
          page?.includes('task') ||
          switcherValue?.includes('task') ||
          !config?.permissions?.goalSee
            ? 'task'
            : 'objective'
        } found`
      )}`;
    descriptionText = `${getObjectiveLocale(
      'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
    )}`;
    withRedirectButton = false;

    if (page?.includes('others')) {
      titleText = `No activities`;
      descriptionText = `${getObjectiveLocale(
        'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
      )}`;
    }
  } else if (cantSee) {
    customContainerClassname = 'w-full';
    titleText = `${getObjectiveLocale(
      `You are not allowed to see this Colleague's ${
        isProfile ? 'Profile' : 'Objectives'
      }`
    )}`;
    descriptionText = isProfile
      ? `${getObjectiveLocale(
          `You do not have permission to see this colleague's profile page.`
        )}`
      : `${getObjectiveLocale(
          'You do not have sufficient permission or not working in the same department with this colleague.'
        )}`;
    withRedirectButton = false;
  } else if (
    page?.includes('task') ||
    switcherValue?.includes('task') ||
    !config?.permissions?.goalSee
  ) {
    titleText =
      title || `${getObjectiveLocale('Not task has been created yet')}`;
    descriptionText =
      description ||
      `${getObjectiveLocale(
        'Align your task into the larger company vision!'
      )}`;
    redirectLink = link || `/create-objective/task`;
    textOnButton = `${getObjectiveLocale('Create New Task')}`;
    permission = currentPermission?.taskCreate;
  } else if (page?.includes('response')) {
    titleText = `${getObjectiveLocale('No activities to be responded')}`;
    descriptionText = `${getObjectiveLocale(
      'Once any activities need your response, you’ll see them here.'
    )}`;
    datacy = 'empty-need-response';
  } else if (page?.includes('project') || switcherValue?.includes('project')) {
    titleText = `${getObjectiveLocale("You don't have any project yet")}`;
    descriptionText = `${getObjectiveLocale(
      'Align your project into bigger objective.'
    )}`;
    textOnButton = `Create New Project`;
    redirectLink = link || {
      pathname: '/create-objective/goal',
      state: { prevPath: location.pathname }
    };
  } else if (page?.includes('team')) {
    titleText = getObjectiveLocale("You don't have any team yet");
    descriptionText = getObjectiveLocale(
      'The teams establishes a sense of direction that guides your decisions and task to be more aligned with your company mission.'
    );
    textOnButton = getObjectiveLocale('Create New Team');
    redirectLink = link || '/create-objective/team';
  } else if (page?.includes('manager')) {
    titleText = `${getObjectiveLocale('No managers')}`;
    descriptionText = `${getObjectiveLocale(
      'If your manager owns the company objective, you’ll find it here.'
    )}`;
  } else if (page?.includes('company') || switcherValue?.includes('company')) {
    const permissionCreator = {
      company: 'isCompanyGoalCreator',
      directorate: 'isDirectorateGoalCreator',
      department: 'isDepartmentGoalCreator',
      division: 'isDivisionGoalCreator'
    };
    const listTab = ['company', 'directorate', 'department', 'division'];
    const currentTab = listTab.find((tab) => tabValidation(tab)) || 'company';

    titleText = `${getObjectiveLocale(
      `You don’t have ${getObjectiveLocale(`${currentTab} objectives`)}`
    )}`;
    descriptionText = `${getObjectiveLocale(
      `Once your ${getObjectiveLocale(
        `${currentTab} objectives`
      )} are set, you’ll see them here.`
    )}`;
    textOnButton = `${getObjectiveLocale(
      `Create New ${capitalize(currentTab)} Goals`
    )}`;
    permission = user[permissionCreator[currentTab]];
  } else if (page?.includes('others')) {
    titleText = `No activities`;
    descriptionText = `${getObjectiveLocale(
      "Check back later for updates on the goal and task you're involved"
    )}.`;
  } else if (type == 'parent-suggestion') {
    titleText = '';
    descriptionText = `${getObjectiveLocale('No parent or goal found')}.`;
  } else if (
    page === 'directReport+direct' ||
    page?.includes('subordinates/direct/tree')
  ) {
    titleText = 'No Direct Report';
    descriptionText = 'You have no subordinates yet.';
    withRedirectButton = false;
  } else if (
    page === 'directReport+indirect' ||
    page?.includes('subordinates/indirect/tree')
  ) {
    titleText = 'No Indirect Report';
    descriptionText = 'You have no subordinates yet.';
    withRedirectButton = false;
  } else if (page?.includes('highlighted')) {
    titleText = '';
    descriptionText = '';
    textOnButton = "Let's highlight some goals!";
    secondaryDescription = false;
    privateCustomClass = 'w-[1040px] m-[auto]';
    iconBtn = null;
    emptyStateAction = () =>
      history.push({ pathname: '/goals', state: { onboardState: true } });
    modalProps = {
      ...modalProps,
      customClass: 'w-[600px]'
    };
    extraElementBelow = (
      <Button.Tertiary
        customClass="mt-[16px]"
        onClick={() => history.push('/about/highlighted')}
      >
        {getObjectiveLocale('Learn more')}
      </Button.Tertiary>
    );
    extraElementAbove = <HighlightedIllustration />;
    useSvg = true;
    svgEmptyState = null;
  } else if (switcherValue?.includes('all')) {
    textOnButton = `Create New Objective`;
  } else if (page === 'myTeamGoals' && title && description) {
    titleText = getObjectiveLocale(title);
    descriptionText = getObjectiveLocale(description);
    secondaryDescription = false;
  }

  if (url?.includes('projects/')) {
    redirectLink = `${url}/addgoal/${params.parentId}`;
  }

  const onClickHandler = () => {
    history.replace({
      pathname: redirectLink,
      state: { prevPath: location.pathname }
    });
  };

  const getEmptyStateIcon = () => {
    if (isSearch) {
      return 'icon-no-result-found';
    }

    if (cantSee) {
      return 'icon-no-permission';
    }

    if (page === 'directReport+direct') {
      return 'icon-no-direct-report';
    }

    if (url.includes('direct-reports')) {
      return 'icon-no-goals-direct-report';
    }

    if (url.includes('company')) {
      return 'icon-no-company-found';
    }

    if (url.includes('users')) {
      return 'icon-no-profile-objective';
    }

    if (url.includes('others')) {
      return 'icon-no-activities';
    }

    if (page?.includes('team')) {
      return 'icon-no-team';
    }

    if (page === 'myTeamGoals' && title && description) {
      return 'icon-no-permission';
    }

    return 'icon-no-objective';
  };

  String.prototype.splice = function (index, count, add) {
    if (index < 0) {
      index = this.length + index;
      if (index < 0) {
        index = 0;
      }
    }
    return this.slice(0, index) + (add || '') + this.slice(index + count);
  };

  const isApprovalTeamDetailPage =
    url.includes('approval') && url.includes('team');

  const withButton =
    permission &&
    withRedirectButton &&
    !url.includes('/appraisals') &&
    !url.includes('others') &&
    !isApprovalTeamDetailPage;
  const btnText =
    withButton &&
    `${
      textOnButton ? textOnButton : getObjectiveLocale('Create New Objective')
    } ${' '}`;
  const subtitleText = `${descriptionText} \n
  ${
    withButton && secondaryDescription
      ? `${`Click the button below to`} ${
          textOnButton
            ? textOnButton.splice(6, 0, ' a').toLowerCase()
            : getObjectiveLocale('Create a New Objective')
                .splice(6, 0, ' a')
                .toLowerCase()
        }.`
      : ''
  }`;

  return (
    <>
      {showModal && (
        <ModalV2 {...modalProps}>
          <ModalContent onClose={closeModal} />
        </ModalV2>
      )}
      <ListEmptyState
        fullHeight
        containerClassname={`${customContainerClassname} ${privateCustomClass} my-[40px]`}
        emptyIcon={useSvg ? svgEmptyState : getEmptyStateIcon()}
        fillIcon={useSvg ? 'var(--n-600)' : ''}
        size={size ? size : 'large'}
        title={titleText}
        subtitle={subtitleText}
        btnText={isAbleToCreate && btnText}
        iconBtn={iconBtn}
        onClick={() =>
          emptyStateAction ? emptyStateAction() : onClickHandler()
        }
        datacy={datacy}
        extraElementBelow={extraElementBelow}
        extraElementAbove={extraElementAbove}
      />
    </>
  );
}

export default ObjectiveEmptyState;

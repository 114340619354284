import React from 'react';

import Config from 'utils/Config';
import { getCultureLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Avatar from 'components/shared/Avatar';

function PostRecognition({ type, content, postId }) {
  const isGroup = type === 'group_recognition' ? true : false;

  const errorImage = (e) => {
    e.target.setAttribute('src', Config.asset.culture.brokenImageCenter);
    e.target.setAttribute('class', 'broken-image-recognition');
  };

  return (
    <div className="feed-recognition" data-cy={`post-recognition-${postId}`}>
      <div className="ribbon-recognition">
        <img src={Config.asset.culture.ribbonRecognition} />
        <div className="typography-h400" data-cy="name-recognition">
          {isGroup
            ? getCultureLocale('GROUP RECOGNITION').toUpperCase()
            : getCultureLocale('RECOGNITION').toUpperCase()}
        </div>
      </div>
      <div className="detail-recognition">
        <div className="target">
          {!isGroup &&
            content.targetUsers.map((target) => {
              return (
                <div className="target-item" key={target.id}>
                  <Avatar
                    name={target.fullName}
                    src={
                      target.profilePicture
                        ? target.profilePicture.secureUrl
                        : null
                    }
                    className="icon-target"
                    dataCy={`image-${target.id}`}
                    size={48}
                  />
                  <p className="typography-h200" data-cy={`name-${target.id}`}>
                    {target.fullName}
                  </p>
                </div>
              );
            })}
          {isGroup && (
            <div className="target-item">
              <Avatar
                name={content.group.name}
                src={
                  content.group.groupPicture
                    ? content.group.groupPicture.secureUrl
                    : null
                }
                addClass="icon-target"
                dataCy={`group-image-${content.group.id}`}
                size={48}
              />
              <p
                className="typography-h200"
                data-cy={`group-name-${content.group.id}`}
              >
                {content.group.name}
              </p>
            </div>
          )}
        </div>
        <div className="recognition-detail-section">
          <div className="header mb-[24px]">
            <img src={Config.asset.culture.quoteRecognition} />
          </div>
          <p
            className="typography-paragraph whitespace-pre-wrap text-justify"
            data-cy="caption-recognition"
          >
            {content.caption}
          </p>
          {content.imageUrl && (
            <AWSImage
              src={content.imageUrl}
              className="caption-photo"
              onError={(e) => errorImage(e)}
            />
          )}
        </div>
        <div className="recognition-detail-section">
          <div className="header">
            <span className="typography-h200">
              {getCultureLocale('VALUE').toUpperCase()}
            </span>
          </div>
          {content.values.map((value) => {
            return (
              <div key={value.id}>
                <AWSImage
                  src={
                    value.iconImage
                      ? value.iconImage.secureUrl
                      : Config.asset.culture.brokenImage
                  }
                  className="value-image"
                  dataCy={`value-image-${value.id}`}
                />
                <div className="endorse-list">
                  <div
                    className="endorse-item typography-h400"
                    data-cy={`value-title-${value.id}`}
                  >
                    {value.title}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {content.skills.length > 0 && (
          <div className="recognition-detail-section">
            <div className="header">
              <span className="typography-h200">
                {getCultureLocale('SKILL').toUpperCase()}
              </span>
            </div>
            <div className="endorse-list">
              {content.skills.map((skill) => {
                return (
                  <div
                    className="endorse-item typography-h400"
                    data-cy={`endorse-title-${skill.id}`}
                    key={skill.id}
                  >
                    {skill.title}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PostRecognition;

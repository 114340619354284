import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { getCultureLocale } from 'utils/HelperUtils';

import ModalPostTemplate from './ModalPostTemplate';
import TaggedUser from './components/TaggedUser';

function ModalTaggedUser({
  isRecognition,
  closeModal,
  setIsModalTaggedUserOpen,
  groupId,
  setTaggedUsers,
  taggedUsers,
  maxReceiver,
  type = 'default'
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(
    JSON.parse(JSON.stringify(taggedUsers))
  );

  const updateTaggedUser = () => {
    setTaggedUsers(selectedUsers);
    setIsModalTaggedUserOpen(false);
  };

  return (
    <ModalPostTemplate
      dataCy="tagged-user"
      className="modal-tagged-user"
      eventOnClick={() => closeModal()}
      modalTitle={getCultureLocale('Select Person').toUpperCase()}
      isButton
      isButtonDisabled={isRecognition ? selectedUsers.length === 0 : false}
      buttonText={
        isRecognition
          ? `${getCultureLocale('Next')}`
          : `${getCultureLocale('Done')}`
      }
      onClickButton={updateTaggedUser}
      dataCyButton="done-select-user"
      isLoading={isLoading}
    >
      <TaggedUser
        isRecognition={isRecognition}
        groupId={groupId}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        taggedUsers={taggedUsers}
        setTaggedUsers={setTaggedUsers}
        maxReceiver={maxReceiver}
        setIsLoading={setIsLoading}
        type={type}
      />
    </ModalPostTemplate>
  );
}

const ModalTaggedUserPortal = (props) => {
  let sharedModal = document.querySelectorAll(`[id^="shared-modal"]`)[0];

  return ReactDOM.createPortal(<ModalTaggedUser {...props} />, sharedModal);
};

export default ModalTaggedUserPortal;

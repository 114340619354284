import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Objectives from 'components/objectives/Objectives';
import ObjectiveSkeleton from 'components/shared/ObjectiveSkeleton';
import AdminTabWithCount from 'components/shared/Tabs/AdminTabWithCount';

function SwitchAbleObjective({ options, page, ...rest }) {
  const history = useHistory();
  let params = useParams();
  let defaultTab =
    options?.find((tab) => tab?.id === params.tab)?.id || options[0]?.id;
  let listOptions = [];
  options.map((option) =>
    listOptions.push({
      key: option.id,
      name: option.name,
      onClick: option.onClick
    })
  );

  const [activeTab, changeTab] = useState(defaultTab);

  let optionActive = options.find((option) => option.id === activeTab);
  let extraFilter = optionActive?.filter || {};
  return (
    <>
      <div
        className={`position-tabs-container ${
          page === 'directReport' ? 'mb-[32px]' : ''
        }`}
      >
        <AdminTabWithCount
          listObjTabs={listOptions}
          changeTab={(tab) => changeTab(tab)}
          active={activeTab}
          withCount={false}
        />
      </div>
      <React.Suspense fallback={<ObjectiveSkeleton />}>
        <Objectives
          {...rest}
          extraFilter={extraFilter}
          {...extraFilter}
          page={`${page}+${activeTab}`}
          cardModel="goal-list"
          switchAble={false}
        />
      </React.Suspense>
    </>
  );
}

export default SwitchAbleObjective;

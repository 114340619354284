const whichSection = path => {
  let response;
  if (path.includes("goals")) {
    response = "My Goals";
  } else if (path.includes("tasks")) {
    response = "My Tasks";
  } else if (path.includes("responses")) {
    response = "Need Response";
  } else if (path.includes("company")) {
    response = "Company Goals";
  } else if (path.includes("projects")) {
    response = "Projects";
  } else if (path.includes("direct-reports")) {
    response = "Direct Reports";
  } else if (path.includes("others")) {
    response = "Others";
  } else if (path.includes("activities")) {
    response = "Activities";
  } else if (path.includes("users")) {
    response = "User Profile";
  } else {
    response = "";
  }
  return response;
};

export { whichSection };

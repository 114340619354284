import React from 'react';

import { Splide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import 'scss/culture/_carousel';

{
  /*
  NOTES:
  Use import below for all wrapper item slider
  import { SplideSlide } from '@splidejs/react-splide';
*/
}

const SlickSlider = ({
  children,
  useDots,
  isInfinite,
  slidesToShow,
  slidesToScroll = 1,
  gap,
  fixedWidth,
  afterChange = () => {}
}) => {
  const elements = document.getElementsByClassName('splide__arrow');
  Array.from(elements).forEach(function (element) {
    element.addEventListener('click', afterChange);
  });

  return (
    <Splide
      options={{
        rewind: isInfinite,
        perPage: slidesToShow,
        perMove: slidesToScroll,
        gap: gap,
        fixedWidth: fixedWidth,
        start: 0,
        pagination: useDots,
        click: afterChange
      }}
    >
      {children}
    </Splide>
  );
};

export default SlickSlider;

import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import {
  getListObjectives,
  getObjectiveConfigs,
  getObjectiveDetail
} from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import useObjectives from 'hooks/useObjectives';
import { useUrl } from 'hooks/useUrl';
import { getClusters, getIncludeTeamGoalsParams } from 'utils/ObjectivesHelper';

function ObjectiveWrapper(props) {
  let {
    initialData,
    defaultShowChild = false,
    objectiveId,
    permissions,
    clusters,
    page,
    queryFn,
    isParent = false,
    isApproval,
    isTeamGoalsApproval,
    isReadOnly
  } = props;

  const history = useHistory();
  const params = useParams();
  const { url } = useUrl();
  const { refetchObjective, refetchSubObjectives } = useRefetchQuery();

  objectiveId = objectiveId ?? params.objectiveId;

  const fetchObjective = () => {
    return getObjectiveDetail(objectiveId);
  };

  const type = page === 'myteams' ? 'team' : 'objective';
  const organizationId = initialData?.organization?.id || null;

  let queryKey = organizationId
    ? [type, objectiveId, organizationId]
    : [type, objectiveId];
  if (isTeamGoalsApproval) {
    queryKey = ['teamGoals', 'approval', 'subGoal', objectiveId];
  }

  // CREATE QUERY KEY
  let { data: objective } = useQuery(queryKey, () => fetchObjective(), {
    suspense: initialData ? false : true,
    initialData: initialData,
    refetchOnMount: initialData ? false : true,
    enabled: true,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000
  });

  objective = objective?.data ? objective.data : objective;

  permissions = permissions || objective?.permissions || [];
  clusters = clusters ? clusters : objective?.id ? getClusters(objective) : [];

  // SHOW HIDE CHILDREN
  const [showChild, setShowChild] = useState(defaultShowChild);
  const [rotateDirection, setRotateDirection] = useState('accordion__icon');

  // GET OBJECTIVE CHILDREN
  const stateSubObjective = [
    'running',
    'completed',
    'reviewed',
    'draft',
    'edited',
    'to_be_deleted'
  ];

  const { config, organization } = useUser();

  const { includeTeamGoals } = config;

  const isBCA = organization?.identifier == 'bca';

  const additionalParams = getIncludeTeamGoalsParams(
    includeTeamGoals,
    objective?.team?.id,
    false
  );

  const filter = useObjectives((state) => state.filter);

  const paramsObjective = {
    parentId: objectiveId,
    state: isApproval ? stateSubObjective : filter?.state || [],
    ...(objective?.organization?.id
      ? { organizationId: objective?.organization?.id }
      : {}),
    ...additionalParams
  };

  const filterIncludeChildren = filter?.filterScope == 'include-children';
  const subgoalsSorting = config.subgoalsSorting;

  const fetchObjectives = (pageParam, page) => {
    let params = {
      reviewsVisibility: 1,
      limit: isBCA ? 50 : 10,
      ...subgoalsSorting,
      ...paramsObjective
    };

    if (pageParam) {
      params.olderThan = pageParam;
    }

    if (filterIncludeChildren) {
      params = {
        ...filter,
        ...params
      };
    }

    queryFn =
      page?.includes('scored-attribute-scoring') || !queryFn
        ? getListObjectives
        : queryFn;
    return queryFn(params);
  };

  let children = [];
  const subObjectivesQueryKey = organizationId
    ? ['sub-objectives', objectiveId, organizationId]
    : ['sub-objectives', objectiveId];

  let {
    data: appendChildren,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useInfiniteQuery(
    subObjectivesQueryKey,
    ({ pageParam }) => fetchObjectives(pageParam, page),
    {
      getNextPageParam: (lastPage) => lastPage.pagination?.next?.olderThan,
      suspense: true,
      initialData: children,
      enabled: showChild
    }
  );

  if (appendChildren?.pages?.length > 0) {
    appendChildren?.pages?.map((child) => {
      if (child.data != null) {
        children.push(...child.data);
      }
    });
  }

  const _refetchSubObjectives = () => {
    refetchSubObjectives(['sub-objectives', objectiveId]);
  };

  // OBJECTIVE CONFIGS
  const [objectiveConfigs, setObjectiveConfigs] = useState({});

  const fetchObjectiveConfigs = async () => {
    const { data } = await getObjectiveConfigs(objectiveId);
    if (data) {
      setObjectiveConfigs(data);
    }
  };

  useQuery(['objectiveConfigs', objectiveId], fetchObjectiveConfigs, {
    enabled: page === 'myTeamGoals' && isParent
  });

  // SHOW CHILDREN
  const showChildren = () => {
    if (objective.childrenCount == 0) return;

    if (!showChild) {
      _refetchSubObjectives();
    }
    setShowChild(!showChild);
    setRotateDirection(
      showChild ? 'accordion__icon' : 'accordion__icon rotate'
    );
  };

  const showDetail = (id) => {
    let newUrl;
    let pathname = location.pathname;
    if (
      (pathname.includes('users') ||
        (pathname.includes('approval') && pathname.includes('team'))) &&
      !pathname.includes('objectives')
    ) {
      newUrl = `${url}/objectives/${id}`;
    } else {
      newUrl = `${url}/${id}`;
    }

    let newSearch = new URLSearchParams(location.search);
    if (objective?.organization?.id) {
      newSearch.set('organizationId', objective?.organization?.id);
    } else if (newSearch.has('organizationId')) {
      newSearch.delete('organizationId');
    }

    history.replace({
      pathname: newUrl,
      search: newSearch.toString()
    });
  };

  // PERMODALAN
  let autoShowSubGoal = useObjectives((state) => state.showChildrenId);
  let setGlobalObjectiveState = useObjectives((state) => state.set);

  useEffect(() => {
    if (objectiveId === autoShowSubGoal) {
      setGlobalObjectiveState((state) => {
        state.showChildrenId = null;
      });
      _refetchSubObjectives();
      if (!showChild) {
        showChildren();
      }
    }
  }, [autoShowSubGoal]);

  const firstRender = useRef(false);
  useEffect(() => {
    if (firstRender.current && showChild) {
      _refetchSubObjectives();
    } else {
      firstRender.current = true;
    }
  }, [filterIncludeChildren]);

  useEffect(() => {
    if (initialData?.integrations?.length === objective?.integrations?.length)
      return;
    refetchObjective(objectiveId);
  }, [initialData?.integrations]);

  return (
    <>
      {React.cloneElement(props.children, {
        showChildren: showChildren,
        children: children,
        isLoadChildren: isFetching && !isFetchingNextPage,
        rotateDirection: rotateDirection,
        showChild: showChild,
        showDetail: showDetail,
        objective: objective,
        permissions: permissions,
        clusters: clusters,
        fetchMore: fetchNextPage,
        canFetchMore: hasNextPage,
        isLoadMoreChildren: isFetchingNextPage,
        objectiveConfigs: objectiveConfigs,
        isReadOnly
      })}
    </>
  );
}

export default ObjectiveWrapper;

import React, { useEffect, useState } from 'react';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { whichBgColorClassName, whichStatus } from 'utils/ObjectivesHelper';

import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const Status = ({ dispatchFilter, page, annual, useCompactLayout, list }) => {
  const [listStatus, setListStatus] = useState([]);
  const [statusActive, setStatusActive] = useState('all');
  const [sum, setSum] = useState(0);

  const label =
    (page === 'teamGoals' ? 'Sub Goal ' : annual ? 'Annual ' : '') + 'Status';

  const setStatus = () => {
    let tempList = list;
    tempList = tempList?.map((status, index) => {
      const progressTitle = whichStatus[status?.progressColorHex];
      const colorLine = whichBgColorClassName(status?.progressColorHex, true);

      return {
        ...status,
        key: index,
        progressTitle: progressTitle,
        colorLine: colorLine
      };
    });

    const tempSum = tempList?.reduce((total, num) => total + num.percentage, 0);

    setListStatus(tempList);
    setSum(tempSum);
  };

  const filterByStatus = (status) => {
    setStatusActive(statusActive == status ? 'all' : status);
    dispatchFilter({ type: 'STATUS-OVERALL', state: status, annual });
  };

  useEffect(() => {
    setStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <div
      className={`status flex ${
        useCompactLayout
          ? 'flex-col gap-[8px] w-auto'
          : 'items-center min-w-[184px]'
      }`}
    >
      <div
        className={`w-[80px] h-[8px] flex flex-row rounded-full overflow-hidden ${
          useCompactLayout ? 'my-[4px]' : ''
        }`}
      >
        {listStatus?.map((status, i) => (
          <TooltipContainer
            key={i}
            classContainer={`${status?.colorLine} cursor-pointer
              h-[8px] ${
                i !== 0 &&
                listStatus[i - 1].percentage !== 0 &&
                listStatus[i - 1].percentage !== 100
                  ? `ml-[2px]`
                  : ''
              }
              ${
                statusActive == 'all' || statusActive == status?.progressTitle
                  ? ''
                  : 'opacity-50'
              }`}
            customStyle={{
              width: `${parseInt(status?.percentage.toFixed(0))}%`
            }}
            show
            text={[
              `${getNumberLocale(status?.count)} Objectives`,
              <br key="br" />,
              status?.progressTitle
            ]}
            dataCy={`line-color-${status?.progressTitle}`}
            dataCyTooltip={`tooltip-${status?.progressTitle}`}
            onClick={() => filterByStatus(status?.progressTitle)}
          />
        ))}
        {sum == 0 && <div className="bg-n-400 w-full" />}
      </div>
      <p
        className={`typography-h400 text-n-600 ${
          useCompactLayout ? '' : 'ml-[8px]'
        }`}
      >
        {getObjectiveLocale(label)}
      </p>
    </div>
  );
};

export default Status;

import { useUser } from 'context/UserContext';
import { getObjectiveLocale, getCultureLocale } from 'utils/HelperUtils';

const useLocale = () => {
  const userData = useUser();
  const appType = userData?.appType || 'performance';

  const setBothLocale = key => {
    return appType === 'culture'
      ? getCultureLocale(key)
      : getObjectiveLocale(key);
  };
  return { setBothLocale };
};

export { useLocale };

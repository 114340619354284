import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

function Faq(props) {
  const goal = getObjectiveLocale('Goal');
  const low_case_goal = goal.toLowerCase();
  const goals = getObjectiveLocale('Goals');
  const low_case_goals = goals.toLowerCase();
  const sub_goal = getObjectiveLocale('Sub Goal');
  const low_case_sub_goal = sub_goal.toLowerCase();
  const sub_goals = getObjectiveLocale('Sub Goals');
  const low_case_sub_goals = sub_goals.toLowerCase();

  return (
    <div className="faq">
      <section className="animated fadeInDownSlow slow">
        <h2 id="manual">Manual</h2>
        <img
          src="../assets/img/general/faq-manual@3x.png"
          className="img-fluids"
        />

        <p>
          {goal + ' '} progress is calculated manually.
          <br />
          The target progress is fixed until you update it.
        </p>
      </section>

      <section className="animated fadeInDownSlow delay-p3 slow">
        <h2 id="auto-avg">Auto Avg</h2>
        <img
          src="../assets/img/general/faq-average@3x.png"
          className="img-fluids"
        />

        <p>
          {goal + ' '} progress is calculated automatically by averaging the
          percentage progress of all the {' ' + low_case_sub_goals + ' '} or
          tasks. Choose Auto Avg when you have {' ' + low_case_sub_goals + ' '}{' '}
          that don't directly accumulated to your {' ' + low_case_goals + ' '}{' '}
          target value and you want to view the progress achievement for each of
          the {low_case_sub_goal} relative to your {low_case_goal}.
        </p>
        <p>
          Below is an example of {' ' + low_case_goals + ' '} that suitable for
          using auto avg option.
        </p>

        <div className="faq-goal">
          <p>{goal}:</p>
          <p>Launch Product v3 successfully in Q1</p>
          <br />
          <p>{sub_goals}:</p>
          <ul>
            <li>Get over 10,000 new signups</li>
            <li>Get published product reviews in 15 publications</li>
            <li>Achieve sign-up to % ratio of over 25%</li>
            <li>Achieve trial to % paid ratio of over 50%</li>
          </ul>
        </div>

        <p>
          Auto AVG <strong>can only be used</strong> on parent
          {' ' + low_case_goal + ' '} using metric:{' '}
          <strong>Percentage (%)</strong>.
        </p>
      </section>

      <section className="animated fadeIn delay-p3 slow">
        <h2 id="auto-sum">Auto Sum</h2>
        <img
          src="../assets/img/general/faq-sum@3x.png"
          className="img-fluids"
        />

        <p>
          {goal} progress is accumulated automatically by adding the current
          values of all the {low_case_sub_goals} or tasks together. Choose Auto
          Sum when you have {low_case_sub_goals} that directly accumulated to
          your {low_case_goal} target value.
        </p>
        <p>
          Below is an example of {' ' + low_case_goal + ' '} that suitable for
          using auto sum option.
        </p>
        <div className="faq-goal">
          <p>{goal}:</p>
          <p>Generate 10.000 Marketing Qualified Leads (MQLs)</p>
          <br />
          <p>{sub_goals}:</p>
          <ul>
            <li>Generate 3,000 MQLs from email marketing</li>
            <li>Generate 5,000 MQLs from offline advertising</li>
            <li>Generate 2,000 MQLs from online advertising</li>
          </ul>
        </div>
        <p>
          Auto Sum can be used on parent {low_case_goal} using any type of
          metric:
        </p>
        <ul>
          <li>
            <strong>Percentage (%)</strong>
          </li>
          <li>
            <strong>Units (#)</strong>
          </li>
          <li>
            <strong>Dollars (USD)</strong>
          </li>
          <li>
            <strong>Rupiah (IDR)</strong>
          </li>
        </ul>
      </section>

      <section className="animated fadeIn delay-p3 slow">
        <h2 id="auto-weighted-avg">Auto Weighted Avg</h2>
        <img
          src="../assets/img/general/faq-sum@3x.png"
          className="img-fluids"
        />

        <p>
          {' '}
          This how the weighted average option will work: Progress of a parent
          goal is taken by summing the progress of all sub goals multiplied by
          each sub goal’s weight and dividing it by total weight of all sub
          goals associated with the parent goal. When this option is being used
          on a parent goal that have sub goals and tasks, it will only calculate
          progress contribution from goals only and not including tasks.
        </p>
        <p>
          Below is an example of {' ' + low_case_goal + ' '} that suitable for
          using auto sum option.
        </p>
        <div className="faq-goal">
          <table>
            <tr>
              <th> Sub Goal</th>
              <th> Weight</th>
              <th> Progress </th>
            </tr>
            <tr>
              <td>Sub Goal A</td>
              <td>20%</td>
              <td>30%</td>
            </tr>
            <tr>
              <td>Sub Goal B</td>
              <td>50%</td>
              <td>50%</td>
            </tr>
            <tr>
              <td>Sub Goal C</td>
              <td>30%</td>
              <td>20%</td>
            </tr>
          </table>
          <p className="weighted-formula">Formula: </p>
          <p className="weighted-formula">
            (SubGoal1 Weight x SubGoal1 progress) + (SubGoal2 Weight x SubGoal2
            progress) + … + (SubGoal(n) Weight x SubGoal(n) progress) / (total
            weight of SubGoal)
          </p>
          <p className="weighted-formula-description">
            Application of the formula :{' '}
          </p>
          <p className="weighted-formula">
            ((20 x 30) + (50 x 40) + (30 x 20) ) / (20+50+30) = ( 600 + 2000 +
            600) / (100) = 32%
          </p>
        </div>
        <p>
          Auto weighted avg can only be used on parent goal using metric:
          Percentage (%).
        </p>
      </section>

      <section className="animated fadeInDownSlow delay-p3 slow">
        <h2 id="initial-value">Initial Value</h2>
        <ol>
          <li>
            Use initial value whenever you want to create a reduction target
            metric where less is better.
            <br />
            <div className="faq-goal">
              Examples of {getObjectiveLocale('Goal/Tasks').toLowerCase()}:
              <br />
              <ul>
                <li>Reduce number of bugs from 50 to 20 bugs.</li>
                <li>Reduce number of customer complaints from 20% to 5%.</li>
              </ul>
            </div>
          </li>

          <li>
            You can also use initial value when you already have a starting
            value by the time you create your {low_case_goal} or task.
            <br />
            <div className="faq-goal">
              Examples of {getObjectiveLocale('Goal/Tasks').toLowerCase()}:
              <ul>
                <li>Increase customer satisfaction from 10% to 25%</li>
              </ul>
            </div>
          </li>
        </ol>
      </section>
    </div>
  );
}
export default Faq;

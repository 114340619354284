import React, { useCallback, useEffect, useState } from 'react';

import {
  addGoalWeight,
  deleteGoalWeight,
  getDatas,
  updateGoalWeight
} from 'client/admin/GoalWeight';
import { useGoalWeightSettingsContext } from 'context/GoalWeightSettingContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Table from 'components/design-system/table/Table';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import WeightSettingsObjCategoriesTableRow from '../fragments/WeightSettingsObjCategoriesTableRow';
import WeightSettingsObjDeleteModal from './WeightSettingsObjDeleteModal';

const colors = [
  '14532D',
  '7C2D12',
  '1E3A8A',
  '713F12',
  '78350F',
  '881337',
  '164E63',
  '064E3B',
  '701A75',
  '312E81',
  '0C4A6E',
  '365314',
  '134E4A'
];
const backgroundColor = [
  'DCFCE7',
  'FFDAD0',
  'DBEAFE',
  'FEF9C3',
  'FEF3C7',
  'FFE4E6',
  'CFFAFE',
  'D1FAE5',
  'FAE8FF',
  'E0E7FF',
  'E0F2FE',
  'ECFCCB',
  'CCFBF1'
];

const randomSelector = (array) => {
  const randomIndex = Math.floor(Math.random(array.length) * 10);
  return array[randomIndex];
};

const NameHeaderCell = (
  <div className="flex flex-row items-center">
    <p className="typography-h100text-n-800">{getObjectiveLocale('Name')}</p>
    <TooltipContainer
      show
      position="top"
      text={getObjectiveLocale('Minimum 3 letters, maximum 15 letters')}
    >
      <SVGIcon
        iconName={'icon-info'}
        size="16"
        fillColor="var(--n-600)"
        customClass="ml-[4px]"
      />
    </TooltipContainer>
  </div>
);

const DefaultHeaderColumn = {
  name: 'Default',
  widthClass: 'w-[72px]',
  grow: false,
  useHeaderMargin: false
};

const tableHeaders = [
  { name: '', widthClass: 'w-[40px]', useHeaderMargin: false },
  {
    name: NameHeaderCell,
    widthClass: 'w-[280px]',
    grow: false,
    useHeaderMargin: false
  },
  {
    name: 'Weight (%)',
    widthClass: 'w-[176px]',
    grow: false,
    useHeaderMargin: false
  },
  {
    name: 'Color',
    widthClass: 'w-[76px]',
    grow: false,
    useHeaderMargin: false
  },
  DefaultHeaderColumn,
  { name: '', widthClass: 'w-[24px]', grow: false, useHeaderMargin: false }
];

const WeightSettingsObjCategoriesTable = ({ useDefaultType }) => {
  const { isReadOnly } = useGoalWeightSettingsContext();

  const [dataType, setDataType] = useState([]);
  const [headers, setHeaders] = useState(tableHeaders);
  const [defaultType, setDefaultType] = useState(null);
  const [modalDeleteId, setModalDeleteId] = useState(null);

  const getData = useCallback(async () => {
    const { data: responseData } = await getDatas();
    if (responseData) {
      const responseDefaultTYpe = responseData.find((data) => data.isDefault);
      setDefaultType(responseDefaultTYpe);
      setDataType(responseData);
    }
  }, []);

  const modifyHeader = useCallback((isDefault) => {
    if (isDefault) {
      setHeaders((dataHeader) => {
        if (dataHeader.find((data) => data.name == 'Default')) {
          return dataHeader;
        }
        let result = [...dataHeader];
        result.splice(4, 0, DefaultHeaderColumn);
        return result;
      });
    } else {
      setHeaders((dataHeader) => {
        if (dataHeader.find((data) => data.name == 'Default')) {
          return dataHeader.filter((data) => data.name != 'Default');
        }
        return dataHeader;
      });
    }
  }, []);

  const handleAddType = () => {
    const idIndex = dataType.filter(
      (data) => data.id.toString().indexOf('add') >= 0
    ).length;

    const newType = {
      id: `add-${idIndex + 1}`,
      name: '',
      weight: 0,
      colorHex: randomSelector(colors),
      bgColorHex: randomSelector(backgroundColor),
      isDefault: false
    };
    setDataType((prevData) => [...prevData, newType]);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    modifyHeader(useDefaultType);
  }, [useDefaultType, modifyHeader]);

  return (
    <div className="mt-[18px]">
      {modalDeleteId && (
        <WeightSettingsObjDeleteModal
          setModalDeleteId={setModalDeleteId}
          modalDeleteId={modalDeleteId}
          dataType={dataType}
          deleteGoalWeight={deleteGoalWeight}
          setDataType={setDataType}
        />
      )}
      <Table
        headers={headers}
        useBorderTopBottom={false}
        customClassHeader="px-[0px] min-h-[16px]"
        customClassTypographyHeader="typography-h100 text-n-800"
      >
        {dataType?.map((data, index) => (
          <WeightSettingsObjCategoriesTableRow
            key={data.id}
            useDefault={useDefaultType && headers?.length == 6}
            data={data}
            index={index}
            setDataType={setDataType}
            updateGoalWeight={updateGoalWeight}
            defaultType={defaultType}
            setDefaultType={setDefaultType}
            setModalDeleteId={setModalDeleteId}
            addGoalWeight={addGoalWeight}
            refetch={getData}
          />
        ))}
        <div
          className={`flex flex-row items-center mt-[8px] rounded w-[142px] h-[32px] px-[4px] ${
            isReadOnly
              ? 'cursor-not-allowed'
              : 'hover:cursor-pointer hover:bg-base-30024 '
          }`}
          onClick={!isReadOnly && handleAddType}
        >
          <SVGIcon
            iconName={'icon-add'}
            size="24"
            fillColor="var(--base-600)"
            customClass="mr-[4px]"
          />
          <p className="typography-button text-base-600">
            {getObjectiveLocale('Add New Type')}
          </p>
        </div>
      </Table>
    </div>
  );
};

export default WeightSettingsObjCategoriesTable;

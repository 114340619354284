import React, { Component } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

class OverviewTeamIndividualPerformanceComponent extends Component {
  constructor(props) {
    super(props);
    this.updateChart(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.teamHighchartData !== nextProps.teamHighchartData ||
      this.props.roleHighchartData !== nextProps.roleHighchartData
    ) {
      this.updateChart(nextProps);
    }
  }

  updateChart(props) {
    const default_data = {
      chart: {
        style: {
          fontFamily: 'Inter'
        },
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'line',
        height: '202'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: [
          '0',
          'Q1 Jan - Mar',
          'Q2 Apr - Jun',
          'Q3 Jul - Sep',
          'Q4 Oct - Des'
        ]
      },
      yAxis: {
        title: {
          text: 'Score'
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 1
        }
      },
      series: [
        {
          data: props.teamHighchartData
        }
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }
        ]
      }
    };
    const role_data = {
      chart: {
        style: {
          fontFamily: 'Inter'
        },
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'line',
        height: '202'
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      xAxis: {
        categories: [
          '0',
          'Q1 Jan - Mar',
          'Q2 Apr - Jun',
          'Q3 Jul - Sep',
          'Q4 Oct - Des'
        ]
      },
      yAxis: {
        title: {
          text: 'Score'
        }
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 1
        }
      },
      series: [
        {
          data: props.roleHighchartData
        }
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }
        ]
      }
    };

    this.state = {
      optionSummaryLineChart: default_data,
      roleHighchart: role_data
    };
  }

  render() {
    return (
      <div className="performance-container">
        <div className="team-performance">
          <div className="header-content">
            <span className="title-performance"> Team Performance </span>
            <div className="rating-detail">
              <SVGIcon
                iconName="icon-star-rounded"
                size={16}
                fillColor="var(--y-500)"
              />
              {this.props.annualTeamData &&
              this.props.annualTeamData.averageScore ? (
                <>
                  <span className="score">
                    {' '}
                    {this.props.annualTeamData &&
                    this.props.annualTeamData.averageScore
                      ? this.props.annualTeamData.averageScore.score
                      : '-'}{' '}
                  </span>
                  <span className="interpunct"> · </span>
                  <span
                    className="team"
                    style={
                      this.props.annualTeamData &&
                      this.props.annualTeamData.averageScore && {
                        color: `#${this.props.annualTeamData.averageScore.scoringOption.colorHex}`
                      }
                    }
                  >
                    {this.props.annualTeamData &&
                    this.props.annualTeamData.averageScore
                      ? this.props.annualTeamData.averageScore.scoringOption
                          .description
                      : '-'}
                  </span>
                </>
              ) : (
                <p className="typography-paragraph ml-[4px] text-n-600 w-full">
                  {getObjectiveLocale('Not yet available')}
                </p>
              )}
            </div>
          </div>
          <div className="highchart">
            <HighchartsReact
              highcharts={Highcharts}
              options={this.state.optionSummaryLineChart}
            />
          </div>
        </div>

        <div className="individual-performance">
          <div className="header-content">
            <span className="title-performance"> Individual Performance </span>
            <div className="rating-detail">
              <SVGIcon iconName="icon-star-rounded" size={16} />
              {this.props.annualRoleData &&
              this.props.annualRoleData.averageScore ? (
                <>
                  <span className="score">
                    {' '}
                    {this.props.annualRoleData &&
                    this.props.annualRoleData.averageScore
                      ? this.props.annualRoleData.averageScore.score
                      : '-'}{' '}
                  </span>
                  <span className="interpunct"> · </span>
                  <span
                    className="team"
                    style={
                      this.props.annualRoleData &&
                      this.props.annualRoleData.averageScore && {
                        color: `#${this.props.annualRoleData.averageScore.scoringOption.colorHex}`
                      }
                    }
                  >
                    {this.props.annualRoleData &&
                    this.props.annualRoleData.averageScore
                      ? this.props.annualRoleData.averageScore.scoringOption
                          .description
                      : '-'}
                  </span>
                </>
              ) : (
                <p className="typography-paragraph ml-[4px] text-n-600 w-full">
                  {getObjectiveLocale('Not yet available')}
                </p>
              )}
            </div>
          </div>

          <div className="highchart">
            <HighchartsReact
              highcharts={Highcharts}
              options={this.state.roleHighchart}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OverviewTeamIndividualPerformanceComponent;

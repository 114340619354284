import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getConfig, getJiraDescriptor } from 'client/admin/Config';
import { useUser } from 'context/UserContext';
import useOrganization from 'hooks/useOrganization';
import { copyToClipboard, getObjectiveLocale } from 'utils/HelperUtils';

import ContentNavigator from 'components/shared/ContentNavigator';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import TruncateParagraph from 'src/components/shared/TruncateParagraph';

import { ToggleCard, setActiveNavigator } from './OrganizationHelper';

const Integration = ({ configParams }) => {
  const {
    configData,
    originConfigData,
    changeConfigData,
    changeOriginConfigData
  } = useOrganization();
  const { user } = useUser();
  const isAdministrator = user?.email == 'administrator@happy5.co';

  const [activeSection, setActiveSection] = useState('google-section');

  const JiraDescriptor = () => {
    const url =
      'https://developer.atlassian.com/cloud/jira/platform/getting-started-with-connect/';

    const stepDescription = [
      '1. Copy app descriptor URL above.',
      '2. In Jira, navigate to Settings > Apps > Manage.',
      '3. Enable development mode in your site. For further information, visit',
      '4. Click button Upload App.',
      '5. Paste app descriptor URL.',
      '6. Click upload.'
    ];
    return (
      <div className="mt-[16px]">
        <p className="typography-h100 text-n-800">
          {getObjectiveLocale('App descriptor URL')}
        </p>
        <div className="flex items-center mt-[8px] w-full">
          <div className="px-[8px] py-[4px] bg-n-300 border-n-400 border-solid border-[1px] rounded-[4px] !w-[calc(100%-32px)] flex">
            <SVGIcon
              iconName="icon-link"
              size="24"
              fillColor="var(--n-600)"
              customClass="mr-[8px]"
            />
            <TruncateParagraph
              position="bottom"
              className="typography-paragraph text-n-600"
              align="left"
              classContainer="!w-[calc(100%-40px)]"
            >
              {jiraUrl ||
                getObjectiveLocale(
                  'enable & save settings to view Jira app descriptor URL'
                )}
            </TruncateParagraph>
          </div>
          <TooltipContainer show useMaxWidth={false} text="Copy URL">
            <SVGIcon
              iconName="icon-content_copy"
              size="24"
              fillColor="var(--n-600)"
              customClass="ml-[8px]"
              onClick={() => jiraUrl && copyToClipboard(jiraUrl)}
              disabled={!jiraUrl}
            />
          </TooltipContainer>
        </div>
        <p className="typography-h100 text-n-800 mt-[16px] mb-[8px]">
          {getObjectiveLocale('Steps to set up integration:')}
        </p>
        {stepDescription?.map((desc, index) => (
          <p className="typography-h100 text-n-800" key={index}>
            {getObjectiveLocale(desc)}
            {index == 2 && (
              <span
                className="ml-[4px] typography-h100 text-base-600 cursor-pointer"
                onClick={() => window.open(url, '_blank')}
              >
                {getObjectiveLocale('this page.')}
              </span>
            )}
          </p>
        ))}
      </div>
    );
  };

  const listGoogle = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Enable Google Fit'),
      cardDescription: getObjectiveLocale(
        'Allow user to connect to their Google Fit account.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          googleFitIntegration: !configData.googleFitIntegration
        }),
      isChecked: configData?.googleFitIntegration,
      dataCy: 'user-googleFitIntegration'
    }
  ];

  const listJira = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Jira Integration'),
      cardDescription: getObjectiveLocale(
        'Updates on Jira issues will automatically track progress with this integration.'
      ),
      disabled: !isAdministrator,
      onChange: () =>
        changeConfigData({
          ...configData,
          enableJiraIntegration: !configData.enableJiraIntegration
        }),
      isChecked: configData?.enableJiraIntegration,
      dataCy: 'user-enableJiraIntegration',
      useChild: true,
      subElement: 'jira-descriptor'
    }
  ];

  const showJiraOption = configData?.enableJiraIntegration || isAdministrator;

  let list = [
    { id: 'google-section', name: 'Google', list: listGoogle },
    showJiraOption && { id: 'jira-section', name: 'JIRA', list: listJira }
  ].filter((opt) => opt);

  const getData = async () => {
    const { data } = await getConfig(configParams);
    if (data) {
      changeConfigData(data);
      changeOriginConfigData(data);
    }
  };

  const getJiraURL = () => {
    return getJiraDescriptor(configParams);
  };

  let { data: jiraData } = useQuery(
    ['settings', 'organization', 'integration', 'jira'],
    getJiraURL,
    {
      enabled: originConfigData?.enableJiraIntegration,
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000
    }
  );

  const jiraUrl = jiraData?.data?.url;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    originConfigData?.enableJiraIntegration && !jiraUrl && getJiraURL();
  }, [originConfigData?.enableJiraIntegration]);

  return (
    <div
      className="overflow-auto h-[calc(100vh-136px)] w-[87%]"
      id="scrollable-config"
      onScroll={(e) => setActiveNavigator(e, list, setActiveSection)}
    >
      <div className="w-[768px] mt-[24px]">
        <div className="fixed right-0 mt-[24px] w-[160px] z-index-10">
          <ContentNavigator
            title="CONTENTS"
            activeSection={activeSection}
            listNavigatorContents={list}
            additionalOffset={100}
            sectionContent="scrollable-config"
          />
        </div>
        <p className="typography-h300 text-n-800 uppercase mb-[24px]">
          {getObjectiveLocale('Integration')}
        </p>
        {list?.map((el, index) => (
          <section className="mt-[24px]" id={el?.id} key={index}>
            <p className="typography-h500 text-n-900 mb-[24px]">
              {getObjectiveLocale(el?.name)}
            </p>
            {el?.list?.map((config, index) => (
              <ToggleCard {...config} key={index}>
                {config?.useChild &&
                  config?.subElement == 'jira-descriptor' && <JiraDescriptor />}
              </ToggleCard>
            ))}
          </section>
        ))}
      </div>
    </div>
  );
};

export default Integration;

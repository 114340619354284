import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import FormattedNumber from 'components/design-system/FormattedNumber';
import SVGIcon from 'components/shared/SVGIcon';
import Tooltips from 'components/shared/Tooltips/Tooltips';

const SuggestedScore = ({ showOption, data, objectiveCount, showLabel }) => {
  const { score, label, overallScore } = data || {};
  const { colorHex, name } = overallScore || {};
  return (
    <div className="flex items-center mt-[8px]">
      <span className="typography-paragraph text-n-600">
        {getObjectiveLocale('Suggested score')}
      </span>
      {objectiveCount !== 0 && (
        <Tooltips
          text={getObjectiveLocale(
            'Automatically calculated from [total_goal] goals'.replace(
              '[total_goal]',
              objectiveCount
            )
          )}
          isEllipsis={true}
          addClass="h-[16px]"
          tooltipClass="mb-[10px] flex-center-center"
        >
          <SVGIcon
            iconName="icon-info"
            size="16"
            fillColor="var(--n-600)"
            customClass="mx-[4px]"
            dataCy="suggested-score-info"
          />
        </Tooltips>
      )}
      :
      <>
        {(showOption === 'score_and_label' || showOption === 'score_only') &&
          score && (
            <FormattedNumber
              number={score}
              minDecimalLength={2}
              customClass="typography-paragraph text-n-600 ml-[4px]"
            />
          )}
        {showLabel && (
          <>
            <span className="mr-[4px]" />
            {showOption === 'score_and_label' && (
              <p className="typography-paragraph text-n-600">(</p>
            )}
            {(showOption === 'score_and_label' ||
              showOption === 'label_only') && (
              <p
                className="typography-paragraph text-n-600"
                style={{ color: `#${colorHex || '94A3B8'}` }}
              >
                {label || name}
              </p>
            )}
            {showOption === 'score_and_label' && (
              <p className="typography-paragraph text-n-600">)</p>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default SuggestedScore;

import React, { useEffect, useState } from 'react';

import { getConfig, updateConfig } from 'client/admin/Config';
import { useAuth } from 'context/AuthContext';
import { useUser } from 'context/UserContext';
import { capitalize } from 'utils/HelperUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import LoadingComponent from 'components/shared/LoadingComponent';
import DynamicTabGrey from 'src/components/shared/Tabs/DynamicTabGrey.js';

import Percentage from './Percentage';
import Type from './Type';

function GoalWeight({ setIsSaving }) {
  const { refetch } = useAuth();
  const {
    config: { permissions }
  } = useUser();
  const [typeConfig, setTypeConfig] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [configData, setConfigData] = useState({});
  const [viewTab, setViewTab] = useState('Type');
  let greyTabsList = [];
  permissions?.settingsObjectivesWeightPercentage &&
    greyTabsList.push('Percentage');
  permissions?.settingsObjectivesWeightType && greyTabsList.push('Type');
  const isTypeReadOnly =
    permissions?.settingsObjectivesWeightType === 'read_only';
  const isPercentageReadOnly =
    permissions?.settingsObjectivesWeightPercentage === 'read_only';

  const changeActiveObjective = async (value) => {
    setIsSaving(true);
    let newValue = value.toLowerCase();
    setTypeConfig(newValue);
    setViewTab(value);

    const newtypeConfig = {
      objectiveWeightType: newValue
    };

    const { data: configData, error: configError } = await updateConfig(
      newtypeConfig
    );

    refetch();
  };

  const getData = async () => {
    setIsLoading(true);
    const { data: configData, error: configError } = await getConfig();
    if (configData) {
      setConfigData(configData);
      setTypeConfig(configData.objectiveWeightType);
      setIsLoading(false);
      setViewTab(capitalize(configData.objectiveWeightType));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="goalweight-container">
      <div className="goal-page-container">
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div className="goal-weight-box pb-[36px]">
            <div className="choose-goal-container">
              <div className="choose-goal typography-h500">
                {getObjectiveLocale('Choose objective weight')} :
              </div>
              <p className="typography-secondary fs-14 mt-[4px] mb-[16px]">
                What method do you want to use in this organization?
              </p>
              <span className="typography-secondary fs-12">
                {getObjectiveLocale('Objective weight')}
              </span>
              <div className="grey-tabs mt-[8px]">
                <DynamicTabGrey
                  listName={greyTabsList}
                  state={viewTab}
                  setState={changeActiveObjective}
                  disabled={
                    viewTab === 'Percentage'
                      ? isTypeReadOnly
                      : isPercentageReadOnly
                  }
                />
              </div>
            </div>

            {typeConfig === 'type' && (
              <Type
                setIsSaving={setIsSaving}
                configData={configData}
                isReadOnly={isTypeReadOnly}
              />
            )}
            {typeConfig === 'percentage' && (
              <Percentage
                setIsSaving={setIsSaving}
                configData={configData}
                isReadOnly={isPercentageReadOnly}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default GoalWeight;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import {
  getSkillValueStrength,
  getSkillValueWeakness
} from 'client/UserProfile';

import AdminTabWithCount from 'components/shared/Tabs/AdminTabWithCount.js';

import BehaviorCard from './BehaviorCard';

const OngoingRecognitionFeedbackSection = ({
  behaviorType,
  targetId,
  year
}) => {
  const [currentTab, setCurrentTab] = useState('recognition');
  const [listBehaviors, setListBehaviors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const listObjTabs = [
    { key: 'recognition', name: 'Recognition' },
    { key: 'feedback', name: 'Feedback' }
  ];

  const getBehaviorType = (type) => {
    let object = {
      values_recognition_feedback: 'value',
      competencies_recognition_feedback: 'competency'
    };
    return object[type];
  };

  const getData = async () => {
    setIsLoading(true);
    let fn =
      currentTab == 'recognition'
        ? getSkillValueStrength
        : getSkillValueWeakness;
    const params = {
      periodBegin: dayjs(`${year.value}-01-01`).startOf('year').toISOString(),
      periodEndBefore: dayjs(`${year.value}-01-01`).endOf('year').toISOString()
    };
    const { data } = await fn(params, targetId, getBehaviorType(behaviorType));
    setListBehaviors(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [currentTab, behaviorType]);

  return (
    <div className="mt-[24px]">
      <AdminTabWithCount
        listObjTabs={listObjTabs}
        changeTab={(tab) => setCurrentTab(tab)}
        active={currentTab}
        withCount={false}
      />
      <div className="pt-[24px] px-[24px]">
        {!isLoading &&
          listBehaviors.map((behavior) => {
            return (
              <BehaviorCard
                targetId={targetId}
                behavior={behavior}
                behaviorType={getBehaviorType(behaviorType)}
                type={currentTab}
                year={year}
              />
            );
          })}
      </div>
    </div>
  );
};

export default OngoingRecognitionFeedbackSection;

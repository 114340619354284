import React from 'react';

import camelCase from 'lodash/camelCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

const StatusColumn = ({ assignment, phase }) => {
  const phaseName = camelCase(phase?.name);
  const review = assignment?.[phaseName] || false;

  if (review && Object.keys(review).length > 0) {
    const completeCount = review?.done;
    const totalCount = review?.total;
    const progressText =
      completeCount == totalCount ? 'Completed' : 'In Progress';

    return (
      <>
        <p className="typography-h200 text-n-900">{progressText}</p>
        <p className="typography-h100 text-n-800">{`${completeCount}/${totalCount} ${getObjectiveLocale(
          'received'
        )}`}</p>
      </>
    );
  }

  return <p className="typography-h200 text-n-900">-</p>;
};

export default StatusColumn;

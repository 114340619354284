import React from 'react'
import WeightSettingsCard from './fragments/WeightSettingsCard'
import { getObjectiveLocale } from "utils/HelperUtils";
import WeightSettingsInputNumber from './fragments/WeightSettingsInputNumber';

const WeightSettingsMaximumSub = ({
  configData,
  changeObjectiveConfig
}) => {
  const { objectivesConfig: {maxSubObjectiveWeightOn, minSubObjectiveWeightOn, maxSubObjectiveWeight, minSubObjectiveWeight } } = configData

  const changeCallback = async ({value, keys}) => {
    const isSuccess = await changeObjectiveConfig({[keys]: value})
    return isSuccess
  }

  const isError = minSubObjectiveWeightOn && maxSubObjectiveWeight <= minSubObjectiveWeight

  return (
    <WeightSettingsCard
      title={"Maximum Weight Value For Sub Objective"}
      subTitle={"Show the expected maximum weight of each sub objectives"}
      isOpen={maxSubObjectiveWeightOn}
      toggleCallback={({value}) => changeCallback({value, keys: "maxSubObjectiveWeightOn"})}
      toggleCy={"toggle-maximum-sub"}
    >
      <div className="flex flex-col">
        <p className="typography-h100 text-n-800 mb-[8px]">{getObjectiveLocale("Maximum weight (%)")}</p>
        <div className="flex flex-row items-center">
          <WeightSettingsInputNumber
            defaultValue={maxSubObjectiveWeight}
            onChangeCallback={(value) => changeCallback({value, keys: "maxSubObjectiveWeight"})}
            isError={isError}
            dataCy='maximum-weight-input-sub'
          />
          {isError && <p className="typography-paragraph text-r-600 ml-[16px]">{getObjectiveLocale(`Maximum weight value must more than`)} {minSubObjectiveWeight}%</p>}
        </div>
      </div>
    </WeightSettingsCard>
  )
}

export default WeightSettingsMaximumSub

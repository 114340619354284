import React, { useEffect, useMemo, useState } from 'react';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getObjectiveType } from 'utils/ObjectivesHelper';

import BannerBox from 'components/shared/BannerBox';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';
import useMultipleEdit from 'src/hooks/useMultipleEdit';
import useTasks from 'src/hooks/useTasks';

const ConfirmDeleteModal = ({
  modalType,
  closeModal,
  submitModal,
  propsModal,
  totalSubgoal,
  totalObjectives,
  dataCy,
  objectiveDetail
}) => {
  const {
    config: { objectiveDeleteChildren }
  } = useUser();
  const [deleteChildren, setDeleteChildren] = useState(false);

  // Mutiple edit
  const selectedObjective = useMultipleEdit((state) => state.selectedObjective);
  const closeSelected = useMultipleEdit((state) => state.closeSelected);
  const selectedTasks = useTasks((state) => state.selectedTasks);

  const objectiveType = useMemo(
    () => getObjectiveType(objectiveDetail),
    [objectiveDetail]
  );

  const type =
    location.pathname.includes('task') || modalType == 'confirmDeleteTask'
      ? 'task'
      : objectiveType;

  const notAllowedPermission = (
    type === 'goal' ? selectedObjective : selectedTasks
  ).filter((item) => !item.permissions?.includes('delete'));
  const checkPermission = notAllowedPermission.length > 0;
  const checkChildrenSelected =
    (type === 'goal' ? selectedObjective : selectedTasks).find(
      (x) => x.childrenCount > 0
    ) !== undefined;

  useEffect(() => {
    if (modalType !== 'mutipleDelete') {
      type === 'goal' && closeSelected();
    }
  }, [modalType]);

  return (
    <Modal
      className="max-w-[400px]"
      maxHeight={400}
      contentWrapperClass={'overflow-auto'}
      title={getObjectiveLocale(
        `Delete ${
          location.pathname.includes('task') ||
          propsModal?.typeObjective === 'task'
            ? 'Task'
            : type === 'goal'
            ? 'Objective'
            : 'Project'
        }`
      )}
      description={
        checkPermission
          ? ''
          : getObjectiveLocale(
              `Are you sure you want to delete this ${type}? ${
                modalType == 'ValidationDeleteGoal'
                  ? type === 'goal'
                    ? `This will affect of ${type} you own. Your total ${type}s right now:`
                    : ''
                  : 'Your action can’t be reversed.'
              }`
            )
      }
      eventOnClickClose={closeModal}
      withPrimaryBtn={{
        title: getObjectiveLocale('Delete'),
        danger: !checkPermission,
        disabled: checkPermission,
        dataCy: dataCy ? dataCy : 'goal-confirm-delete-goal',
        onClick: () => {
          submitModal(deleteChildren);
        }
      }}
      withSecondaryBtn={{
        title: getObjectiveLocale('Cancel'),
        dataCy: 'goal-cancel-delete-goal',
        onClick: () => closeModal()
      }}
      {...propsModal}
    >
      {modalType === 'ValidationDeleteGoal' && type === 'goal' && (
        <div className="bg-y-100 heigth-40 rounded-[4px] mb-[8px] flex items-center">
          <SVGIcon
            iconName="icon-warning"
            fillColor="var(--y-500)"
            size="20"
            customClass="py-[8px] ml-[10px]"
          />
          <span className="ml-[20px] py-[8px] typography-paragraph">
            {getObjectiveLocale('Total goals,')} {totalObjectives}{' '}
            {getObjectiveLocale('Top Parent Goal(s)')}
          </span>
        </div>
      )}

      {objectiveDeleteChildren &&
        ((propsModal?.totalSubgoal || totalSubgoal) > 0 ||
          !checkPermission) && (
          <>
            {(propsModal?.totalSubgoal || totalSubgoal) > 0 && type === 'goal' && (
              <div className="bg-y-100 heigth-40 rounded-[4px] flex items-center">
                <SVGIcon
                  iconName="icon-warning"
                  fillColor="var(--y-500)"
                  size="20"
                  customClass="py-[8px] ml-[10px]"
                />
                <span className="ml-[20px] py-[8px] typography-paragraph">
                  {' '}
                  {getObjectiveLocale('This goal has')}{' '}
                  {propsModal?.totalSubgoal
                    ? propsModal?.totalSubgoal
                    : totalSubgoal}{' '}
                  {getObjectiveLocale('sub goal')}
                </span>
              </div>
            )}

            {((propsModal?.totalSubgoal || totalSubgoal) > 0 ||
              checkChildrenSelected) && (
              <div>
                <Checkbox
                  dataCy="confirm-delete-children"
                  name="confirm-delete-children"
                  id="confirm-delete-children"
                  customContainerClass="mt-[16px] min-h-[24px]"
                  checked={deleteChildren}
                  onClick={() => setDeleteChildren(!deleteChildren)}
                >
                  <span className="text-n-900 ml-[8px] typography-paragraph">
                    {getObjectiveLocale(
                      `Include deletion of ${
                        type === 'goal' ? 'subgoal' : 'task'
                      }`
                    )}
                  </span>
                </Checkbox>
                {objectiveType == 'project' && (
                  <BannerBox
                    type="warning"
                    marginClass="mt-[16px]"
                    paddingClass="py-[8px] px-[16px]"
                    alignItemsClass="items-start"
                    iconSize={24}
                  >
                    {getObjectiveLocale(
                      'Tasks that do not have an assignee will be deleted'
                    )}
                  </BannerBox>
                )}
              </div>
            )}
          </>
        )}

      {checkPermission && (
        <div className="bg-y-100 rounded-[4px] px-[16px] grid items-center">
          <SVGIcon
            iconName="icon-warning"
            fillColor="var(--y-500)"
            size="22"
            customClass="py-[8px]"
          />
          <span className="ml-[16px] pt-[8px] typography-paragraph">
            {getObjectiveLocale(
              'You don’t have permission to delete these objective(s):'
            )}
          </span>
          <ul className="ml-[56px] mb-[8px] col-span-2">
            {notAllowedPermission.map((obj, id) => (
              <li key={id}>
                <span className="typography-h400-longform">{obj.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Modal>
  );
};

export default ConfirmDeleteModal;

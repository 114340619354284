import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import { getObjectiveDetail } from 'client/ObjectivesClient';
import { abbrevNumber, getObjectiveLocale } from 'utils/HelperUtils';
import {
  getOwners,
  whichBadgeBgColorHex,
  whichBadgeColorHex,
  whichBgColorClassName,
  whichColor,
  whichStatus
} from 'utils/ObjectivesHelper';

import Card from 'components/design-system/Card';
import FormattedNumber from 'components/design-system/FormattedNumber';
import ProgressObjective from 'components/objectives/compact-objective/fragments/ProgressObjective';
import Avatar from 'components/shared/Avatar';
import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';

import AnalyticProgressOvertime from './AnalyticProgressOvertime';

const ObjectiveLabelCard = ({ obj, isLast }) => {
  const nowDate = new dayjs();
  const history = useHistory();
  const [timeframeProgress, setTimeframeProgress] = useState({});

  const fetchObjective = () => {
    return getObjectiveDetail(obj.id);
  };

  let { data: objectiveData } = useQuery(
    ['objective', 'overview', obj.id],
    fetchObjective,
    {
      initialData: obj,
      staleTime: 5 * 60 * 1000
    }
  );

  const currentObj = objectiveData?.data ?? objectiveData;

  const {
    name,
    measurement,
    isProject,
    startDate,
    progressUpdated,
    id,
    permissions,
    involvements
  } = currentObj || [];

  const { progressPercentage, progressColorHex, expectedProgressPercentage } =
    measurement || [];

  const tooltipProgressText = [
    getObjectiveLocale(`Expected: ${expectedProgressPercentage}%`),
    <br key="br" />,
    whichStatus[progressColorHex]
  ];

  const emptyProgress = nowDate.isAfter(startDate) ? (
    !progressUpdated ? (
      <p className={`typography-h400 text-start text-n-600`}>
        {getObjectiveLocale('Not updated')}
      </p>
    ) : (
      <p
        className={`typography-h400 text-start ${whichColor[progressColorHex]}`}
      >
        {whichStatus[progressColorHex]}
      </p>
    )
  ) : (
    <p className={`typography-h400 text-start text-n-600`}>
      {getObjectiveLocale('Not started')}
    </p>
  );

  const { goalOwnersName, goalOwnersRoles, goalOwnersProfilePic } = getOwners(
    involvements,
    isProject,
    false
  );

  const colorProgress =
    timeframeProgress?.type === 'stagnant'
      ? 'var(--n-600)'
      : timeframeProgress?.type == 'increase'
      ? 'var(--g-600)'
      : 'var(--r-600)';

  return (
    <Card
      customClass={`!px-[16px] !pb-[0px] mt-[16px] first:mt-[0px] flex cursor-pointer ${
        isLast ? 'mb-[-16px]' : ''
      }`}
      useHorizontalPadding={false}
      key={`obj-card-${id}`}
    >
      <div
        className="w-[288px]"
        onClick={() => history.replace(`/objective-overview/analytics/${id}`)}
        data-cy={`detail-obj-${id}`}
      >
        <TruncateParagraph className="typography-h500 text-n-900">
          {name}
        </TruncateParagraph>
        <Badge
          defaultPointer
          colorHex={whichBadgeColorHex[progressColorHex || '44DB5E']}
          bgColorHex={whichBadgeBgColorHex[progressColorHex || '44DB5E']}
          useStopPropagation={false}
          className="mt-[16px] mb-[8px]"
        >
          <p className="typography-h200">
            {whichStatus[progressColorHex || '44DB5E']}
          </p>
        </Badge>
        <p className="mb-[8px]">
          <span
            style={{
              color: `#${
                progressColorHex == '44DB5E' ? '16A34A' : progressColorHex
              }`
            }}
            className="typography-h800"
          >
            {measurement?.unit} {abbrevNumber(measurement?.currentValue)}
          </span>
          <span className="text-n-600 typography-h800 mx-[4px]">/</span>
          <span className="text-n-800 typography-h800">
            {abbrevNumber(measurement?.targetValue)}
          </span>
        </p>
        <p className="flex mb-[8px]">
          {timeframeProgress?.type !== 'stagnant' && (
            <SVGIcon
              iconName={`icon-arrow_drop_${
                timeframeProgress?.type == 'increase' ? 'up' : 'down'
              }`}
              size="24"
              fillColor={colorProgress}
            />
          )}

          <span
            style={{ color: colorProgress }}
            className="typography-paragraph"
          >
            {timeframeProgress?.percentage && (
              <FormattedNumber
                number={timeframeProgress?.percentage}
                minDecimalLength={2}
              />
            )}
            %
          </span>
          <span className="typography-paragraph ml-[4px] text-n-600">
            {getObjectiveLocale(
              `from last ${timeframeProgress?.selectedTimeFrame?.replace(
                /ly/,
                ''
              )}`
            )}
          </span>
        </p>
        <ProgressObjective
          progressPercentage={progressPercentage}
          expectedProgressPercentage={expectedProgressPercentage}
          tooltipProgressText={tooltipProgressText}
          emptyProgress={emptyProgress}
          barColor={whichBgColorClassName(progressColorHex, false, '500')}
          textColor={whichColor[progressColorHex]}
          permissions={permissions}
          objective={obj}
          allowZero={progressUpdated}
          width={192}
        />
        <div className="flex items-center my-[16px]">
          <Avatar src={goalOwnersProfilePic} name={goalOwnersName} size={16} />
          <p className="ml-[4px] max-w-[120px] flex">
            <TruncateParagraph
              className="typography-h100 text-n-800"
              position="bottom"
              dataCy="goal-owner"
            >
              {goalOwnersName}
            </TruncateParagraph>
          </p>
          <div className="ml-[4px] max-w-[136px]">
            <TruncateParagraph
              className="typography-h100 text-n-800"
              position="bottom"
              dataCy="tribe-child-members"
            >
              · {goalOwnersRoles}
            </TruncateParagraph>
          </div>
        </div>
      </div>
      <AnalyticProgressOvertime
        objId={id}
        editPermission={permissions?.includes('update')}
        setTimeframeProgress={setTimeframeProgress}
        dataCy={`progress-obj-${id}`}
      />
    </Card>
  );
};

export default ObjectiveLabelCard;

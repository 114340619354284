import React, { useState } from 'react';

import { useUser } from 'context/UserContext';
import { getCultureLocale } from 'utils/HelperUtils';

import ModalAddLink from 'components/modal/culture/ModalAddLink';
import ModalTaggedUser from 'components/modal/culture/ModalTaggedUser';
import SVGIcon from 'components/shared/SVGIcon';

const FooterPostPhoto = ({
  mediaAttachments,
  fileAttachments,
  type,
  groupDetail,
  taggedUsers,
  setTaggedUsers,
  addPhoto,
  linkAttachment,
  addFile,
  setLinkAttachment,
  disabled
}) => {
  const { config } = useUser();

  const [isModalTaggedUserOpen, setIsModalTaggedUserOpen] = useState(false);
  const [isModalLinkOpen, setIsModalLinkOpen] = useState(false);

  const allowToUploadVideo = config?.videoConfig?.allowed;
  const maxMedia = config?.maxNumberOfMedia;
  const maxFile = config?.maxNumberOfAttachments;

  const isFileCanBeAdded =
    fileAttachments.length > 0 && fileAttachments.length < maxFile;
  const isMediaCanBeAdded =
    mediaAttachments.length > 0 && mediaAttachments.length < maxMedia;

  const allowedImageExtensions = config?.allowedImageExtensions
    ? config?.allowedImageExtensions.map((ext) => '.' + ext)
    : [];
  const allowedVideoExtensions = config?.allowedVideoExtensions
    ? config?.allowedVideoExtensions.map((ext) => '.' + ext)
    : [];

  const allowedPhotoVideoExtensions = allowToUploadVideo
    ? [...allowedImageExtensions, ...allowedVideoExtensions]
    : allowedImageExtensions;

  let allowAttachmentExtensions = config?.allowedAttachmentExtensions
    ? config?.allowedAttachmentExtensions
        .map((ext) => {
          if (!ext.includes('image') && !ext.includes('video')) {
            if (ext.charAt(0) === '/') {
              ext = ext.slice(1, ext.length);
            }
            ext = '.' + ext;
            return ext;
          }
        })
        .filter((ext) => {
          return ext !== undefined;
        })
    : [];
  allowAttachmentExtensions = [
    ...allowAttachmentExtensions,
    ...allowedImageExtensions,
    ...allowedVideoExtensions
  ];

  const closeModal = () => {
    setIsModalTaggedUserOpen(false);
  };

  return (
    <div className="footer-section">
      {type === 'create' && (
        <div data-cy="add-photo">
          <input
            type="file"
            name="media"
            id="media"
            accept={allowedPhotoVideoExtensions}
            className={`input-file ${
              disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            multiple
            onChange={addPhoto}
            data-cy="input-photo"
            disabled={mediaAttachments.length === maxMedia || disabled}
          />
          <label
            htmlFor="media"
            className={
              isMediaCanBeAdded
                ? 'selected-attachment'
                : disabled
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }
          >
            <span>
              <SVGIcon
                size="40"
                iconName="icon-camera_alt"
                fillColor={
                  isMediaCanBeAdded ? 'var(--base-600)' : 'var(--n-600)'
                }
              />
              {allowToUploadVideo
                ? getCultureLocale('Photo / Videos')
                : getCultureLocale('Photo')}
            </span>
          </label>
          {mediaAttachments.length === maxMedia && (
            <div className="typography-h100 tooltip-footer">
              {getCultureLocale(
                `Max Items for Photos/VIdeos is ${maxMedia} Items`
              )}
            </div>
          )}
        </div>
      )}
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsModalTaggedUserOpen(true);
        }}
        data-cy="tag-user"
      >
        <SVGIcon
          size="40"
          iconName="icon-people_alt"
          fillColor={
            taggedUsers.length > 0 ? 'var(--base-600)' : 'var(--n-600)'
          }
        />
        <span
          className={
            taggedUsers.length > 0 ? 'selected-attachment ml-[8px]' : 'ml-[8px]'
          }
        >
          {getCultureLocale('With')}
        </span>
      </div>
      {type === 'create' && (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation();
              !disabled && setIsModalLinkOpen(true);
            }}
            data-cy="add-link"
          >
            <SVGIcon
              size="40"
              iconName="icon-link"
              fillColor={
                linkAttachment.length > 0 ? 'var(--base-600)' : 'var(--n-600)'
              }
            />
            <span
              className={
                linkAttachment.length > 0
                  ? 'selected-attachment ml-[8px]'
                  : 'ml-[8px]'
              }
            >
              {getCultureLocale('Link')}
            </span>
          </div>
          <div className="add-file">
            <input
              type="file"
              name="attachment"
              id="attachment"
              accept={allowAttachmentExtensions}
              className={`input-file ${
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              multiple
              onChange={addFile}
              data-cy="add-attachment"
              disabled={fileAttachments.length === maxFile || disabled}
            />
            <label
              htmlFor="attachment"
              className={
                isFileCanBeAdded
                  ? 'selected-attachment'
                  : disabled
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              }
            >
              <span>
                <SVGIcon
                  size="40"
                  iconName="icon-insert_drive_file"
                  fillColor={
                    isFileCanBeAdded ? 'var(--base-600)' : 'var(--n-600)'
                  }
                />
                {getCultureLocale('File')}
              </span>
            </label>
            {fileAttachments.length === maxFile && (
              <div className="typography-h100 tooltip-footer">
                {getCultureLocale(`Max Items for Files is ${maxFile} Items`)}
              </div>
            )}
          </div>
        </>
      )}
      {isModalTaggedUserOpen && (
        <ModalTaggedUser
          isRecognition={false}
          closeModal={closeModal}
          setIsModalTaggedUserOpen={setIsModalTaggedUserOpen}
          groupId={groupDetail.id}
          setTaggedUsers={setTaggedUsers}
          taggedUsers={taggedUsers}
        />
      )}
      {isModalLinkOpen && (
        <ModalAddLink
          setIsModalLinkOpen={setIsModalLinkOpen}
          setLinkAttachment={setLinkAttachment}
        />
      )}
    </div>
  );
};

export default FooterPostPhoto;

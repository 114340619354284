import React, { useEffect } from 'react';

import { useFormalReview } from 'context/FormalReviewContext.js';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderWorkScore from './HeaderWorkScore';
import ObjectScoring from './ObjectScoring';

function GoalsScoringWork({
  objects,
  components,
  assignmentId,
  phase,
  answers,
  currentTrack,
  template,
  showOption,
  actor,
  preFill,
  suggestions
}) {
  const [
    { goalsScoringData, tasksScoringData },
    { getSingleTrack, getGoalsScoringData, getTasksScoringData }
  ] = useFormalReview();
  const { trackConfig, trackType } = getSingleTrack(currentTrack);
  const currentAssingmentId = assignmentId || params.assignmentId;
  const { type } = phase;
  const { useComment } = template;

  const getScore = async () => {
    currentTrack == 'goals_scoring'
      ? getGoalsScoringData(currentAssingmentId)
      : getTasksScoringData(currentAssingmentId);
  };

  useEffect(() => {
    getScore();
  }, []);

  const score =
    currentTrack == 'goals_scoring'
      ? goalsScoringData?.personal?.score
      : tasksScoringData?.personal.score;

  return (
    <div>
      <HeaderWorkScore
        totalNilai={score}
        type="work attribute"
        trackType={trackType}
      />
      <div className="mt-[16px]">
        {objects?.map((object, index) => {
          return (
            <ObjectScoring
              key={object.id}
              title={object.name}
              id={object.id}
              weight={object?.weight || '0'}
              idx={index}
              components={components}
              useComment={useComment}
              answers={answers}
              preFill={preFill}
              currentTrack={currentTrack}
              type={type}
              getSingleGoalsScoring={() =>
                getGoalsScoringData(currentAssingmentId)
              }
              getSingleTasksScoring={() =>
                getTasksScoringData(currentAssingmentId)
              }
              mechanism={template?.mechanism}
              showOption={showOption}
              actor={actor}
              suggestions={suggestions}
              phaseType={type}
              suggestionSource={trackConfig?.suggestionSource}
            />
          );
        })}
      </div>
    </div>
  );
}

export default GoalsScoringWork;

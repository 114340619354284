import React, { Component } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import AxiosRequestUtils from 'utils/AxiosRequestUtils';
import Config from 'utils/Config';
import { getENV, getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import AWSImage from 'components/design-system/AWSImage';
import LoadingComponent from 'components/shared/LoadingComponent';

import TalentMappingData from './TalentMappingMocky';

const Axios = require('axios').default;
let chartReflow = undefined;

const TableType = {
  SINGLE: 'SINGLE',
  GROUP: 'GROUP'
};

class TalentMapping extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      categories: TalentMappingData.data.map((box) => box.title),
      name: {},
      description: {},
      employees: [],
      nineBoxes: TalentMappingData.data,
      uniqueScoreEmployees: [],
      duplicateScoreEmployees: [],
      groupedStartIndex: [],
      groupedDuplicateScoreEmployees: {},
      maximumScore: 0,
      nineBoxesDistribution: [],
      xAverageScore: 0,
      yAverageScore: 0,
      listReviews: [],
      listCycles: [],
      listActiveCycles: [],
      listEndedCycles: [],
      cycleId: 0,
      descriptionActiveId: -1,
      tableNodeCategory: '',
      tableXRating: 0.0,
      tableYRating: 0.0,
      tableGroupedEmployeeIndex: -1,
      tablePaginationActiveChoice: 5,
      tablePaginationPerPageChoice: [5, 10, 20],
      tablePaginationIndexes: [1, 2, 3, 4, 5],
      tablePaginationActiveIndex: 1,
      tableGroupedEmployee: [],
      tableSingleEmployee: null,
      tableType: '',
      xAxisLabel: `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )} & ${getObjectiveLocale('Tasks')})`,
      yAxisLabel: `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )} & ${getObjectiveLocale('Competencies')})`,
      showData: false,
      inner: '',
      xAxisLabelArray: [
        `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )} & ${getObjectiveLocale('Tasks')})`,
        `${getObjectiveLocale('Performance')} (${getObjectiveLocale('Goals')})`,
        `${getObjectiveLocale('Performance')} (${getObjectiveLocale('Tasks')})`
      ],
      yAxisLabelArray: [
        `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )} & ${getObjectiveLocale('Competencies')})`,
        `${getObjectiveLocale('Behavior')} (${getObjectiveLocale('Values')})`,
        `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Competencies'
        )})`
      ],
      xAverageTitle: `${getObjectiveLocale('Performance')}`,
      yAverageTitle: `${getObjectiveLocale('Behaviour')}`
    };

    this.renderXOption = this.renderXOption.bind(this);
    this.renderYOption = this.renderYOption.bind(this);
    this.showDescription = this.showDescription.bind(this);
    this.hideDescriptionWithOutsideClick =
      this.hideDescriptionWithOutsideClick.bind(this);
    this.onChangeCycle = this.onChangeCycle.bind(this);
    this.onChangePaginationChoice = this.onChangePaginationChoice.bind(this);
    this.getActiveCycles = this.getActiveCycles.bind(this);
    this.getEndedCycles = this.getEndedCycles.bind(this);
    this.getTalentDistribution = this.getTalentDistribution.bind(this);
    this.getScoringComponent = this.getScoringComponent.bind(this);
    this.getAverageScore = this.getAverageScore.bind(this);
    this.handleXAxisLabelChange = this.handleXAxisLabelChange.bind(this);
    this.handleYAxisLabelChange = this.handleYAxisLabelChange.bind(this);
    this.getNodeValue = this.getNodeValue.bind(this);
    this.validateNodeValue = this.validateNodeValue.bind(this);
    this.getScoringKey = this.getScoringKey.bind(this);
    this.getUniqueValues = this.getUniqueValues.bind(this);
    this.getDuplicateValues = this.getDuplicateValues.bind(this);
    this.mappingUniqueAndDuplicateEmployees =
      this.mappingUniqueAndDuplicateEmployees.bind(this);
    this.mappingChartData = this.mappingChartData.bind(this);
    this.getMaxYChartAxis = this.getMaxYChartAxis.bind(this);
    this.getProfilePicture = this.getProfilePicture.bind(this);
    this.getEmployeeCategory = this.getEmployeeCategory.bind(this);
    this.displaySingleTable = this.displaySingleTable.bind(this);
    this.displayGroupedTable = this.displayGroupedTable.bind(this);
    this.onClickPaginationIndex = this.onClickPaginationIndex.bind(this);
    this.onClickPaginationNext = this.onClickPaginationNext.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  scrollToBottom() {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
  }

  displaySingleTable(employee) {
    const tableNodeCategory = this.getEmployeeCategory(employee);
    let tableXRating = 0.0;
    let tableYRating = 0.0;

    switch (this.state.xAxisLabel) {
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )} & ${getObjectiveLocale('Tasks')})`:
        tableXRating = employee.score.performance_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )})`:
        tableXRating = employee.score.goals_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Tasks'
      )})`:
        tableXRating = employee.score.tasks_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )} & ${getObjectiveLocale('Competencies')})`:
        tableXRating = employee.score.behavior_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )})`:
        tableXRating = employee.score.values_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Competencies'
      )})`:
        tableXRating = employee.score.competencies_score.toFixed(1);
        break;
      default:
        break;
    }

    switch (this.state.yAxisLabel) {
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )} & ${getObjectiveLocale('Tasks')})`:
        tableYRating = employee.score.performance_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )})`:
        tableYRating = employee.score.goals_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Tasks'
      )})`:
        tableYRating = employee.score.tasks_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )} & ${getObjectiveLocale('Competencies')})`:
        tableYRating = employee.score.behavior_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )})`:
        tableYRating = employee.score.values_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Competencies'
      )})`:
        tableYRating = employee.score.competencies_score.toFixed(1);
        break;
      default:
        break;
    }

    const minIndex = 1;
    const maxTotalIndexesPerPage = 5;
    const totalIndexes = 1 / this.state.tablePaginationActiveChoice + 1;
    let tablePaginationIndexes = [];

    if (totalIndexes > maxTotalIndexesPerPage) {
      for (var i = minIndex; i <= maxTotalIndexesPerPage; ++i) {
        tablePaginationIndexes.push(i);
      }
    } else {
      for (var i = minIndex; i <= totalIndexes; ++i) {
        tablePaginationIndexes.push(i);
      }
    }

    this.setState(
      {
        tableSingleEmployee: employee,
        tablePaginationIndexes,
        tableNodeCategory,
        tableXRating,
        tableYRating,
        tableType: TableType.SINGLE
      },
      () => {
        this.scrollToBottom();
      }
    );
  }

  displayGroupedTable(groupedEmployees, index) {
    const tableNodeCategory = this.getEmployeeCategory(groupedEmployees[0]);
    let tableXRating = 0.0;
    let tableYRating = 0.0;

    switch (this.state.xAxisLabel) {
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )} & ${getObjectiveLocale('Tasks')})`:
        tableXRating = groupedEmployees[0].score.performance_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )})`:
        tableXRating = groupedEmployees[0].score.goals_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Tasks'
      )})`:
        tableXRating = groupedEmployees[0].score.tasks_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )} & ${getObjectiveLocale('Competencies')})`:
        tableXRating = groupedEmployees[0].score.behavior_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )})`:
        tableXRating = groupedEmployees[0].score.values_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Competencies'
      )})`:
        tableXRating = groupedEmployees[0].score.competencies_score.toFixed(1);
        break;
      default:
        break;
    }

    switch (this.state.yAxisLabel) {
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )} & ${getObjectiveLocale('Tasks')})`:
        tableYRating = groupedEmployees[0].score.performance_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )})`:
        tableYRating = groupedEmployees[0].score.goals_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Tasks'
      )})`:
        tableYRating = groupedEmployees[0].score.tasks_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )} & ${getObjectiveLocale('Competencies')})`:
        tableYRating = groupedEmployees[0].score.behavior_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )})`:
        tableYRating = groupedEmployees[0].score.values_score.toFixed(1);
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Competencies'
      )})`:
        tableYRating = groupedEmployees[0].score.competencies_score.toFixed(1);
        break;
      default:
        break;
    }

    const minIndex = 1;
    const maxTotalIndexesPerPage = 5;
    const totalIndexes =
      groupedEmployees.length / this.state.tablePaginationActiveChoice + 1;
    let tablePaginationIndexes = [];

    if (totalIndexes > maxTotalIndexesPerPage) {
      for (var i = minIndex; i <= maxTotalIndexesPerPage; ++i) {
        tablePaginationIndexes.push(i);
      }
    } else {
      for (var i = minIndex; i <= totalIndexes; ++i) {
        tablePaginationIndexes.push(i);
      }
    }

    this.setState(
      {
        tableGroupedEmployee: groupedEmployees,
        tablePaginationIndexes,
        tableNodeCategory,
        tableXRating,
        tableYRating,
        tableGroupedEmployeeIndex: index,
        tableType: TableType.GROUP
      },
      () => {
        this.scrollToBottom();
      }
    );
  }

  getProfilePicture(employee) {
    const defaultPicture = Config.asset.general.defaultPicture;

    if (employee.user && employee.user.profile_picture) {
      return employee.user.profile_picture;
    }

    return defaultPicture;
  }

  getMaxYChartAxis() {
    const yTickDistance = 5;
    let maxValue = yTickDistance;

    this.state.nineBoxesDistribution.map((value) => {
      if (value > maxValue) {
        maxValue = value;
      }
    });

    const mod = maxValue % yTickDistance;
    maxValue += yTickDistance - mod;

    return maxValue;
  }

  getEmployeeCategory(employee) {
    const xComp = this.getScoringKey('x');
    const yComp = this.getScoringKey('y');

    const dividerOne = (this.state.maximumScore / 3).toFixed(1);
    const dividerTwo = ((this.state.maximumScore * 2) / 3).toFixed(1);
    const dividers = [0, dividerOne, dividerTwo, this.state.maximumScore];

    if (employee.score !== null) {
      if (
        employee.score[xComp] >= dividers[0] &&
        employee.score[xComp] < dividers[1] &&
        employee.score[yComp] >= dividers[0] &&
        employee.score[yComp] < dividers[1]
      ) {
        return this.state.nineBoxes[6].title;
      } else if (
        employee.score[xComp] >= dividers[1] &&
        employee.score[xComp] < dividers[2] &&
        employee.score[yComp] >= dividers[0] &&
        employee.score[yComp] < dividers[1]
      ) {
        return this.state.nineBoxes[7].title;
      } else if (
        employee.score[xComp] >= dividers[2] &&
        employee.score[yComp] >= dividers[0] &&
        employee.score[yComp] < dividers[1]
      ) {
        return this.state.nineBoxes[8].title;
      } else if (
        employee.score[xComp] >= dividers[0] &&
        employee.score[xComp] < dividers[1] &&
        employee.score[yComp] >= dividers[1] &&
        employee.score[yComp] < dividers[2]
      ) {
        return this.state.nineBoxes[3].title;
      } else if (
        employee.score[xComp] >= dividers[1] &&
        employee.score[xComp] < dividers[2] &&
        employee.score[yComp] >= dividers[1] &&
        employee.score[yComp] < dividers[2]
      ) {
        return this.state.nineBoxes[4].title;
      } else if (
        employee.score[xComp] >= dividers[2] &&
        employee.score[yComp] >= dividers[1] &&
        employee.score[yComp] < dividers[2]
      ) {
        return this.state.nineBoxes[5].title;
      } else if (
        employee.score[xComp] >= dividers[0] &&
        employee.score[xComp] < dividers[1] &&
        employee.score[yComp] >= dividers[2]
      ) {
        return this.state.nineBoxes[0].title;
      } else if (
        employee.score[xComp] >= dividers[1] &&
        employee.score[xComp] < dividers[2] &&
        employee.score[yComp] >= dividers[2]
      ) {
        return this.state.nineBoxes[1].title;
      } else if (
        employee.score[xComp] >= dividers[2] &&
        employee.score[yComp] >= dividers[2]
      ) {
        return this.state.nineBoxes[2].title;
      }
    }
  }

  mappingChartData(xComp, yComp) {
    const dividerOne = (this.state.maximumScore / 3).toFixed(1);
    const dividerTwo = ((this.state.maximumScore * 2) / 3).toFixed(1);
    const dividers = [0, dividerOne, dividerTwo, this.state.maximumScore];

    let nineBoxesDistribution = [0, 0, 0, 0, 0, 0, 0, 0, 0];

    this.state.employees.map((employee) => {
      if (employee.score !== null) {
        if (
          employee.score[xComp] >= dividers[0] &&
          employee.score[xComp] < dividers[1] &&
          employee.score[yComp] >= dividers[0] &&
          employee.score[yComp] < dividers[1]
        ) {
          nineBoxesDistribution[6] += 1;
        } else if (
          employee.score[xComp] >= dividers[1] &&
          employee.score[xComp] < dividers[2] &&
          employee.score[yComp] >= dividers[0] &&
          employee.score[yComp] < dividers[1]
        ) {
          nineBoxesDistribution[7] += 1;
        } else if (
          employee.score[xComp] >= dividers[2] &&
          employee.score[yComp] >= dividers[0] &&
          employee.score[yComp] < dividers[1]
        ) {
          nineBoxesDistribution[8] += 1;
        } else if (
          employee.score[xComp] >= dividers[0] &&
          employee.score[xComp] < dividers[1] &&
          employee.score[yComp] >= dividers[1] &&
          employee.score[yComp] < dividers[2]
        ) {
          nineBoxesDistribution[3] += 1;
        } else if (
          employee.score[xComp] >= dividers[1] &&
          employee.score[xComp] < dividers[2] &&
          employee.score[yComp] >= dividers[1] &&
          employee.score[yComp] < dividers[2]
        ) {
          nineBoxesDistribution[4] += 1;
        } else if (
          employee.score[xComp] >= dividers[2] &&
          employee.score[yComp] >= dividers[1] &&
          employee.score[yComp] < dividers[2]
        ) {
          nineBoxesDistribution[5] += 1;
        } else if (
          employee.score[xComp] >= dividers[0] &&
          employee.score[xComp] < dividers[1] &&
          employee.score[yComp] >= dividers[2]
        ) {
          nineBoxesDistribution[0] += 1;
        } else if (
          employee.score[xComp] >= dividers[1] &&
          employee.score[xComp] < dividers[2] &&
          employee.score[yComp] >= dividers[2]
        ) {
          nineBoxesDistribution[1] += 1;
        } else if (
          employee.score[xComp] >= dividers[2] &&
          employee.score[yComp] >= dividers[2]
        ) {
          nineBoxesDistribution[2] += 1;
        }
      }
    });

    this.setState({
      nineBoxesDistribution
    });
  }

  mappingUniqueAndDuplicateEmployees() {
    const xScore = this.getScoringKey('x');
    const yScore = this.getScoringKey('y');

    this.mappingChartData(xScore, yScore);

    const uniqueEmployees = this.getUniqueValues(
      this.state.employees.map((employee) => employee),
      xScore,
      yScore
    );

    const duplicateEmployees = this.getDuplicateValues(
      this.state.employees.map((employee) => employee),
      xScore,
      yScore
    );

    this.setState({
      uniqueScoreEmployees: uniqueEmployees,
      duplicateScoreEmployees: duplicateEmployees
    });
  }

  getUniqueValues(arr, xComp, yComp) {
    const unique = arr
      .map((e) => e.score[xComp].toFixed(1) || e.score[yComp].toFixed(1))
      .map((e, i, final) => final.indexOf(e) === final.lastIndexOf(e) && i)
      .filter((e) => arr[e])
      .map((e) => arr[e]);
    return unique;
  }

  getDuplicateValues(arr, xComp, yComp) {
    let groupedStartIndex = [];
    let groupedIndex = 0;
    let groupedDuplicateScoreEmployees = {};
    let groupedEmployees = [];
    let prevEmployee = null;

    const duplicate = arr
      .map((e) => e.score[xComp].toFixed(1) || e.score[yComp].toFixed(1))
      .map((e, i, final) => final.indexOf(e) !== final.lastIndexOf(e) && i)
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    duplicate.sort((a, b) => {
      return a.score[yComp] - b.score[yComp];
    });

    duplicate.sort((a, b) => {
      return a.score[xComp] - b.score[xComp];
    });

    duplicate.map((employee, index) => {
      if (prevEmployee == null) {
        groupedStartIndex.push(index);
      } else {
        if (
          prevEmployee.score[xComp].toFixed(1) !==
            employee.score[xComp].toFixed(1) ||
          prevEmployee.score[yComp].toFixed(1) !==
            employee.score[yComp].toFixed(1)
        ) {
          groupedStartIndex.push(index);
          groupedIndex += 1;
          groupedEmployees = [];
        }
      }

      groupedEmployees.push(employee);
      groupedDuplicateScoreEmployees[groupedIndex] = groupedEmployees;
      prevEmployee = employee;
    });

    this.setState({
      groupedStartIndex,
      groupedDuplicateScoreEmployees
    });

    return duplicate;
  }

  renderXOption(x) {
    if (x === this.state.yAxisLabel) {
      return <option disabled="true">{x}</option>;
    }
    return <option>{x}</option>;
  }

  renderYOption(y) {
    if (y === this.state.xAxisLabel) {
      return <option disabled="true">{y}</option>;
    }
    return <option>{y}</option>;
  }

  showDescription(event, id) {
    const inner = document.getElementById(id).innerHTML;
    event.stopPropagation();

    if (
      !this.state.showData ||
      (this.state.showData && this.state.inner != inner)
    ) {
      this.setState({
        showData: true,
        descriptionActiveId: id,
        inner
      });
    } else if (
      (this.state.showData && this.state.inner == inner) ||
      !insideClick
    ) {
      this.setState({
        showData: false,
        descriptionActiveId: -1,
        inner
      });
    }
  }

  hideDescriptionWithOutsideClick() {
    this.setState({
      showData: false,
      descriptionActiveId: -1
    });
  }

  onChangeCycle(event) {
    this.setState(
      {
        tableNodeCategory: '',
        cycleId: event.target.value
      },
      () => this.getTalentDistribution()
    );
  }

  onChangePaginationChoice(event) {
    this.setState(
      {
        tablePaginationActiveChoice: event.target.value,
        tablePaginationActiveIndex: 1
      },
      () => {
        const minIndex = 1;
        const maxTotalIndexesPerPage = 5;
        const totalIndexes =
          this.state.tableGroupedEmployee.length /
            this.state.tablePaginationActiveChoice +
          1;
        let tablePaginationIndexes = [];

        if (totalIndexes > maxTotalIndexesPerPage) {
          for (var i = minIndex; i <= maxTotalIndexesPerPage; ++i) {
            tablePaginationIndexes.push(i);
          }
        } else {
          for (var i = minIndex; i <= totalIndexes; ++i) {
            tablePaginationIndexes.push(i);
          }
        }

        this.setState({
          tablePaginationIndexes
        });
      }
    );
  }

  onClickPaginationIndex(index) {
    this.setState({
      tablePaginationActiveIndex: index
    });
  }

  onClickPaginationNext() {
    const currentIndex = this.state.tablePaginationActiveIndex;
    if (
      currentIndex <
      this.state.tablePaginationIndexes[
        this.state.tablePaginationIndexes.length - 1
      ]
    ) {
      const nextIndex = currentIndex + 1;
      this.setState(
        {
          tablePaginationActiveIndex: nextIndex
        },
        () => {
          if (
            this.state.tablePaginationIndexes[
              this.state.tablePaginationIndexes.length - 1
            ] > 5 &&
            this.state.tablePaginationActiveIndex !== 1
          ) {
            const minIndex = this.state.tablePaginationActiveIndex - 1;
            const maxTotalIndexesPerPage = 5 + minIndex;
            const totalIndexes =
              this.state.tableGroupedEmployee.length /
                this.state.tablePaginationActiveChoice +
              1;
            let tablePaginationIndexes = [];

            if (totalIndexes > maxTotalIndexesPerPage) {
              for (var i = minIndex; i <= maxTotalIndexesPerPage; ++i) {
                tablePaginationIndexes.push(i);
              }
            } else {
              for (var i = minIndex; i <= totalIndexes; ++i) {
                tablePaginationIndexes.push(i);
              }
            }

            this.setState({
              tablePaginationIndexes
            });
          }
        }
      );
    }
  }

  getScoringKey(axis) {
    if (axis === 'x') {
      switch (this.state.xAxisLabel) {
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )} & ${getObjectiveLocale('Tasks')})`:
          return 'performance_score';
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )})`:
          return 'goals_score';
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Tasks'
        )})`:
          return 'tasks_score';
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )} & ${getObjectiveLocale('Competencies')})`:
          return 'behavior_score';
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )})`:
          return 'values_score';
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Competencies'
        )})`:
          return 'competencies_score';
        default:
          break;
      }
    } else {
      switch (this.state.yAxisLabel) {
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )} & ${getObjectiveLocale('Tasks')})`:
          return 'performance_score';
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )})`:
          return 'goals_score';
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Tasks'
        )})`:
          return 'tasks_score';
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )} & ${getObjectiveLocale('Competencies')})`:
          return 'behavior_score';
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )})`:
          return 'values_score';
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Competencies'
        )})`:
          return 'competencies_score';
        default:
          break;
      }
    }
  }

  validateNodeValue(score) {
    return score <= this.state.maximumScore
      ? score
      : this.state.maximumScore.toFixed(1);
  }

  getNodeValue(axis, employee) {
    if (axis === 'x') {
      switch (this.state.xAxisLabel) {
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )} & ${getObjectiveLocale('Tasks')})`:
          return employee.score.performance_score;
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )})`:
          return employee.score.goals_score;
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Tasks'
        )})`:
          return employee.score.tasks_score;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )} & ${getObjectiveLocale('Competencies')})`:
          return employee.score.behavior_score;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )})`:
          return employee.score.values_score;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Competencies'
        )})`:
          return employee.score.competencies_score;
        default:
          break;
      }
    } else {
      switch (this.state.yAxisLabel) {
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )} & ${getObjectiveLocale('Tasks')})`:
          return employee.score.performance_score;
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )})`:
          return employee.score.goals_score;
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Tasks'
        )})`:
          return employee.score.tasks_score;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )} & ${getObjectiveLocale('Competencies')})`:
          return employee.score.behavior_score;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )})`:
          return employee.score.values_score;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Competencies'
        )})`:
          return employee.score.competencies_score;
        default:
          break;
      }
    }
  }

  getActiveCycles(state) {
    var url = `/v1/admin/formal_reviews/cycles?state[]=in_progress`;
    var params = {};

    var method = 'GET';

    AxiosRequestUtils(url, method, params, (isSuccess, response) => {
      if (isSuccess) {
        let listReviews = response.data;
        let listCycles = [];
        let listActiveCycles = [];

        listReviews.map((cycle) => {
          listCycles.push({
            id: cycle.id,
            name: cycle.name
          }),
            listActiveCycles.push({
              id: cycle.id,
              name: cycle.name
            });
        });

        const cycleId = listCycles.length > 0 ? listCycles[0].id : 0;

        this.setState(
          {
            listReviews: listReviews,
            listCycles: listCycles,
            listActiveCycles: listActiveCycles,
            cycleId: cycleId
          },
          () => this.getEndedCycles()
        );
      } else {
        this.setState({
          error: true,
          errorMessage: response
        });
      }
    });
  }

  getEndedCycles() {
    var url = `/v1/admin/formal_reviews/cycles?state[]=done`;
    var params = {};

    var method = 'GET';

    AxiosRequestUtils(url, method, params, (isSuccess, response) => {
      if (isSuccess) {
        let listReviews = response.data;
        let listCycles = this.state.listCycles;

        listReviews.map((cycle) =>
          listCycles.push({
            id: cycle.id,
            name: cycle.name
          })
        );

        const cycleId = listCycles.length > 0 ? listCycles[0].id : 0;

        this.setState(
          {
            listReviews: listReviews,
            listCycles: listCycles,
            listEndedCycles: listReviews,
            cycleId: cycleId
          },
          () => this.getTalentDistribution()
        );
      } else {
        this.setState({
          error: true,
          errorMessage: response
        });
      }
    });
  }

  getTalentDistribution() {
    var self = this;
    var token = localStorage.getItem('bearer');
    var authorizationMethod = getENV('AUTHORIZATION_METHOD');

    var config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(authorizationMethod === 'bearer' &&
          token && { Authorization: 'Bearer ' + token })
      }
    };

    if (authorizationMethod === 'cookie') {
      Axios.defaults.withCredentials = true;
    }

    Axios.get(
      `${Config.apiHost}/v1/formal_reviews/cycle/${this.state.cycleId}/reviewee_inclusions`,
      config
    )
      .then((response) => {
        const employees = response.data.data.filter(
          (employee) => employee.score !== null
        );

        this.setState({
          employees
        });

        this.getAverageScore();
        this.mappingUniqueAndDuplicateEmployees();
      })
      .catch((error) => {
        self.setState({
          modalError: true,
          errorMessage: 'There is an error'
        });
      });
  }

  getScoringComponent() {
    var self = this;
    var token = localStorage.getItem('bearer');
    var authorizationMethod = getENV('AUTHORIZATION_METHOD');

    var config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...(authorizationMethod === 'bearer' &&
          token && { Authorization: 'Bearer ' + token })
      }
    };

    if (authorizationMethod === 'cookie') {
      Axios.defaults.withCredentials = true;
    }

    Axios.get(`${Config.apiHost}/v1/scorings/1`, config)
      .then((response) => {
        this.setState({
          maximumScore: response.data.data.scoring_components[0].maximal_score
        });
      })
      .catch((error) => {
        self.setState({
          modalError: true,
          errorMessage: 'There is an error'
        });
      });
  }

  getAverageScore() {
    const employees = this.state.employees;
    var scores = [];
    var totalPerformanceScore = 0;
    var totalGoalsScore = 0;
    var totalTasksScore = 0;
    var totalBehaviorScore = 0;
    var totalValuesScore = 0;
    var totalCompetenciesScore = 0;

    employees.map((employee) => {
      if (employee.score !== null) {
        scores.push(employee.score);
      }
    });

    if (scores.length > 0) {
      scores.map((score) => {
        totalPerformanceScore += score.performance_score;
        totalGoalsScore += score.goals_score;
        totalTasksScore += score.tasks_score;
        totalBehaviorScore += score.behavior_score;
        totalValuesScore += score.values_score;
        totalCompetenciesScore += score.behavior_score;
      });

      let totalXScore = 0.0;
      let totalYScore = 0.0;

      switch (this.state.xAxisLabel) {
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )} & ${getObjectiveLocale('Tasks')})`:
          totalXScore = totalPerformanceScore;
          break;
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )})`:
          totalXScore = totalGoalsScore;
          break;
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Tasks'
        )})`:
          totalXScore = totalTasksScore;
          break;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )} & ${getObjectiveLocale('Competencies')})`:
          totalXScore = totalBehaviorScore;
          break;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )})`:
          totalXScore = totalValuesScore;
          break;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Competencies'
        )})`:
          totalXScore = totalCompetenciesScore;
          break;
        default:
          break;
      }

      switch (this.state.yAxisLabel) {
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )} & ${getObjectiveLocale('Tasks')})`:
          totalYScore = totalPerformanceScore;
          break;
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Goals'
        )})`:
          totalYScore = totalGoalsScore;
          break;
        case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
          'Tasks'
        )})`:
          totalYScore = totalTasksScore;
          break;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )} & ${getObjectiveLocale('Competencies')})`:
          totalYScore = totalBehaviorScore;
          break;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Values'
        )})`:
          totalYScore = totalValuesScore;
          break;
        case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
          'Competencies'
        )})`:
          totalYScore = totalCompetenciesScore;
          break;
        default:
          break;
      }

      const xAverageScore = (totalXScore / scores.length).toFixed(1);
      const yAverageScore = (totalYScore / scores.length).toFixed(1);

      this.setState({
        xAverageScore,
        yAverageScore
      });
    } else {
      this.setState({
        xAverageScore: (0).toFixed(1),
        yAverageScore: (0).toFixed(1)
      });
    }
  }

  handleXAxisLabelChange(event) {
    const targetValue = event.target.value;
    let xAverageTitle = '';

    switch (targetValue) {
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )} & ${getObjectiveLocale('Tasks')})`:
        xAverageTitle = `${getObjectiveLocale('Performance')}`;
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )})`:
        xAverageTitle = `${getObjectiveLocale('Goals')}`;
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Tasks'
      )})`:
        xAverageTitle = `${getObjectiveLocale('Tasks')}`;
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )} & ${getObjectiveLocale('Competencies')})`:
        xAverageTitle = `${getObjectiveLocale('Behaviour')}`;
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )})`:
        xAverageTitle = `${getObjectiveLocale('Values')}`;
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Competencies'
      )})`:
        xAverageTitle = `${getObjectiveLocale('Competencies')}`;
        break;
      default:
        break;
    }

    this.setState(
      {
        xAxisLabel: targetValue,
        xAverageTitle,
        tableNodeCategory: ''
      },
      () => this.mappingUniqueAndDuplicateEmployees()
    );
  }

  handleYAxisLabelChange(event) {
    const targetValue = event.target.value;
    let yAverageTitle = '';

    switch (targetValue) {
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )} & ${getObjectiveLocale('Tasks')})`:
        yAverageTitle = `${getObjectiveLocale('Performance')}`;
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Goals'
      )})`:
        yAverageTitle = `${getObjectiveLocale('Goals')}`;
        break;
      case `${getObjectiveLocale('Performance')} (${getObjectiveLocale(
        'Tasks'
      )})`:
        yAverageTitle = `${getObjectiveLocale('Tasks')}`;
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )} & ${getObjectiveLocale('Competencies')})`:
        yAverageTitle = `${getObjectiveLocale('Behaviour')}`;
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Values'
      )})`:
        yAverageTitle = `${getObjectiveLocale('Values')}`;
        break;
      case `${getObjectiveLocale('Behavior')} (${getObjectiveLocale(
        'Competencies'
      )})`:
        yAverageTitle = `${getObjectiveLocale('Competencies')}`;
        break;
      default:
        break;
    }

    this.setState(
      {
        yAxisLabel: targetValue,
        yAverageTitle,
        tableNodeCategory: ''
      },
      () => this.mappingUniqueAndDuplicateEmployees()
    );
  }

  handleRoundedValue(value, precision) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  componentDidMount() {
    this.getActiveCycles('');
    this.getScoringComponent();
  }

  componentDidUpdate(nextProps, nextState) {
    const chart = this.chartRef.chart ? this.chartRef.chart.getChart() : {};
    chartReflow = chartReflow || chart.reflow;
    chart.reflow = () => {};
    setTimeout(() => {
      chart.reflow = chartReflow;
    });
  }

  render() {
    const summaryChartConfig = {
      title: '',
      legend: {
        enabled: false
      },
      chart: {
        style: {
          fontFamily: 'Inter'
        },
        height: 500
      },
      tooltip: { enabled: false },
      xAxis: {
        categories: this.state.categories,
        title: {
          text: 'Categories',
          style: { color: 'var(--n-600)' }
        },
        labels: {
          useHTML: true,
          style: {
            width: '50px',
            whiteSpace: 'normal'
          },
          step: 1,
          formatter() {
            return `<div align="center" style="word-wrap: normal;word-break: normal;width:50px">${this.value}</div>`;
          }
        },
        lineWidth: 1,
        lineColor: '#f7f8fb',
        minorGridLineColor: 'transparent'
      },
      yAxis: {
        title: {
          text: 'People',
          style: { color: 'var(--n-600)' }
        },
        max: this.getMaxYChartAxis(),
        gridLineDashStyle: 'LongDash',
        gridLineColor: '#ddf0ff',
        allowDecimals: false
      },
      colors: [
        'rgba(255, 180, 0, 0.2)',
        'rgba(107, 175, 1, 0.2)',
        'rgba(107, 175, 1, 0.2)',
        'rgba(208, 2, 27, 0.2)',
        'rgba(255, 180, 0, 0.2)',
        'rgba(107, 175, 1, 0.2)',
        'rgba(208, 2, 27, 0.2)',
        'rgba(208, 2, 27, 0.2)',
        'rgba(255, 180, 0, 0.2)'
      ],
      plotOptions: {
        column: {
          colorByPoint: true
        }
      },
      series: [
        {
          name: this.state.categories,
          type: 'column',
          data: this.state.nineBoxesDistribution,
          dataLabels: {
            enabled: true
          }
        }
      ]
    };

    var showData = this.state.showData;
    var inner = this.state.inner;
    var lefty;

    return (
      <>
        <HeaderPage titlePage={getObjectiveLocale('Talent Mapping')} />
        <div
          className="container-fluid"
          onClick={() => this.hideDescriptionWithOutsideClick()}
        >
          <div className="row talent-mapping mt-[24px]">
            <div className="row source-view">
              <div className="source-label">Source: </div>
              <div className="dropdown">
                {this.state.listCycles.length > 0 ? (
                  <select
                    className="form-control"
                    id="cycleFilter"
                    onChange={this.onChangeCycle}
                  >
                    <optgroup label="Active Cycle">
                      {this.state.listActiveCycles.map((cycle) => (
                        <option value={cycle.id}>{cycle.name}</option>
                      ))}
                    </optgroup>
                    <optgroup label="Ended Cycle">
                      {this.state.listEndedCycles.map((cycle) => (
                        <option value={cycle.id}>{cycle.name}</option>
                      ))}
                    </optgroup>
                  </select>
                ) : (
                  <select className="form-control">
                    <option label="No cycle available" />
                  </select>
                )}
                <i className="fa fa-chevron-down" />
              </div>
            </div>
            {this.state.listCycles.length > 0 &&
            this.state.employees.length > 0 ? (
              <div className="row" style={{ display: 'flex' }}>
                <div className="col-md-6 talent-distribution-chart">
                  <div className="row nine-box-dropdowns">
                    <div className="x-label">
                      <span>X:</span>
                    </div>
                    <div className="dropdown">
                      <select
                        className="form-control"
                        onChange={this.handleXAxisLabelChange}
                      >
                        {this.state.xAxisLabelArray.map(this.renderXOption)}
                      </select>
                      <i className="fa fa-chevron-down" />
                    </div>
                    <div className="y-label">Y:</div>
                    <div className="dropdown">
                      <select
                        className="form-control"
                        onChange={this.handleYAxisLabelChange}
                        defaultValue={this.state.yAxisLabelArray[0]}
                      >
                        {this.state.yAxisLabelArray.map(this.renderYOption)}
                      </select>
                      <i className="fa fa-chevron-down" />
                    </div>
                  </div>

                  {this.state.nineBoxes.length !== 0 ? (
                    <div className="nine-box-plot">
                      <div className="nine-box-y-axis-label">
                        <div className="nine-box-y-axis-maximum-label">
                          {this.state.maximumScore}
                        </div>
                      </div>
                      <div className="nine-box-y-axis-title">
                        {this.state.yAxisLabel}
                      </div>
                      <div className="nine-box">
                        {this.state.nineBoxes.map((box, index) => {
                          const id = `box-${index}`;

                          return (
                            <div
                              className="boxk"
                              onClick={(event) =>
                                this.showDescription(event, id)
                              }
                            >
                              {this.state.showData &&
                                inner === this.state.nineBoxes[index].title && (
                                  <div className="description">
                                    <div className="description-title">{`${box.title}`}</div>
                                    <div className="description-subtitle">
                                      {`${box.subtitle}`}
                                    </div>

                                    <div className="description-desc">
                                      {`${box.description}`}
                                    </div>
                                  </div>
                                )}
                              <span
                                className="level"
                                id={id}
                              >{`${box.title}`}</span>
                            </div>
                          );
                        })}
                        {this.state.uniqueScoreEmployees.map((employee) => (
                          <span
                            onClick={() => {
                              this.displaySingleTable(employee);
                            }}
                            className="spread-dots"
                            style={{
                              left: `calc(${
                                ((this.validateNodeValue(
                                  this.getNodeValue('x', employee)
                                )
                                  ? this.validateNodeValue(
                                      this.getNodeValue('x', employee).toFixed(
                                        1
                                      )
                                    )
                                  : 0) /
                                  this.state.maximumScore) *
                                100
                              }% - 4px)`,
                              bottom: `calc(${
                                ((this.validateNodeValue(
                                  this.getNodeValue('y', employee)
                                )
                                  ? this.validateNodeValue(
                                      this.getNodeValue('y', employee).toFixed(
                                        1
                                      )
                                    )
                                  : 0) /
                                  this.state.maximumScore) *
                                100
                              }% - 4px)`,
                              display: `inline-block`
                            }}
                          >
                            <div className="hover">
                              <div className="involvement upper">
                                <AWSImage
                                  className="employee-avatar img-circle"
                                  src={this.getProfilePicture(employee)}
                                />
                                <div className="employee-name">
                                  {employee.user.name}
                                </div>
                              </div>
                              <div className="bottom">
                                <div>
                                  Category:{' '}
                                  <span>
                                    {this.getEmployeeCategory(employee)}
                                  </span>
                                </div>
                                <div className="scores row">
                                  <div className="col-xs-6">
                                    {this.state.xAverageTitle}
                                    <span>
                                      {this.getNodeValue('x', employee)
                                        ? this.getNodeValue(
                                            'x',
                                            employee
                                          ).toFixed(1)
                                        : 0}
                                    </span>
                                  </div>
                                  <div className="col-xs-6">
                                    {this.state.yAverageTitle}
                                    <span>
                                      {this.getNodeValue('y', employee)
                                        ? this.getNodeValue(
                                            'y',
                                            employee
                                          ).toFixed(1)
                                        : 0}
                                    </span>
                                  </div>
                                </div>
                                <div
                                  className="see-details-button"
                                  onClick={() =>
                                    this.displaySingleTable(employee)
                                  }
                                >
                                  See Details
                                </div>
                              </div>
                              <div className="arrow-down" />
                              <div className="arrow-down-2" />
                            </div>
                          </span>
                        ))}
                        {Object.keys(
                          this.state.groupedDuplicateScoreEmployees
                        ).map((key) => (
                          <span
                            onClick={() =>
                              this.displayGroupedTable(
                                this.state.groupedDuplicateScoreEmployees[key],
                                key
                              )
                            }
                            className="spread-dots spread-dots-multiple"
                            style={{
                              left: `calc(${
                                ((this.validateNodeValue(
                                  this.getNodeValue(
                                    'x',
                                    this.state.groupedDuplicateScoreEmployees[
                                      key
                                    ][0]
                                  )
                                )
                                  ? this.validateNodeValue(
                                      this.getNodeValue(
                                        'x',
                                        this.state
                                          .groupedDuplicateScoreEmployees[
                                          key
                                        ][0]
                                      ).toFixed(1)
                                    )
                                  : 0) /
                                  this.state.maximumScore) *
                                100
                              }% - 4px)`,
                              bottom: `calc(${
                                ((this.validateNodeValue(
                                  this.getNodeValue(
                                    'y',
                                    this.state.groupedDuplicateScoreEmployees[
                                      key
                                    ][0]
                                  )
                                )
                                  ? this.validateNodeValue(
                                      this.getNodeValue(
                                        'y',
                                        this.state
                                          .groupedDuplicateScoreEmployees[
                                          key
                                        ][0]
                                      ).toFixed(1)
                                    )
                                  : 0) /
                                  this.state.maximumScore) *
                                100
                              }% - 4px)`,
                              display: `inline-block`
                            }}
                          >
                            <div className="hover">
                              <div className="involvement upper">
                                {this.state.groupedDuplicateScoreEmployees[
                                  key
                                ] !== undefined
                                  ? this.state.groupedDuplicateScoreEmployees[
                                      key
                                    ].map((employee, index) => {
                                      if (index < 3) {
                                        return (
                                          <AWSImage
                                            className="employee-avatar grouped-employee-avatar img-circle"
                                            src={this.getProfilePicture(
                                              employee
                                            )}
                                          />
                                        );
                                      }
                                    })
                                  : null}

                                <div className="employees-count-label">
                                  {this.state.groupedDuplicateScoreEmployees[
                                    key
                                  ].length < 3
                                    ? `${this.state.groupedDuplicateScoreEmployees[key].length} people`
                                    : `+ ${
                                        this.state
                                          .groupedDuplicateScoreEmployees[key]
                                          .length - 3
                                      } more`}
                                </div>
                              </div>
                              <div className="bottom">
                                <div>
                                  Category:{' '}
                                  <span>
                                    {this.getEmployeeCategory(
                                      this.state.groupedDuplicateScoreEmployees[
                                        key
                                      ][0]
                                    )}
                                  </span>
                                </div>
                                <div className="scores row">
                                  <div className="col-xs-6">
                                    {this.state.xAverageTitle}
                                    <span>
                                      {this.getNodeValue(
                                        'x',
                                        this.state
                                          .groupedDuplicateScoreEmployees[
                                          key
                                        ][0]
                                      )
                                        ? this.getNodeValue(
                                            'x',
                                            this.state
                                              .groupedDuplicateScoreEmployees[
                                              key
                                            ][0]
                                          ).toFixed(1)
                                        : 0}
                                    </span>
                                  </div>
                                  <div className="col-xs-6">
                                    {this.state.yAverageTitle}
                                    <span>
                                      {this.getNodeValue(
                                        'y',
                                        this.state
                                          .groupedDuplicateScoreEmployees[
                                          key
                                        ][0]
                                      )
                                        ? this.getNodeValue(
                                            'y',
                                            this.state
                                              .groupedDuplicateScoreEmployees[
                                              key
                                            ][0]
                                          ).toFixed(1)
                                        : 0}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="see-details-button"
                                onClick={() =>
                                  this.displaySingleTable(
                                    this.state.groupedDuplicateScoreEmployees[
                                      key
                                    ][0]
                                  )
                                }
                              >
                                See Details
                              </div>
                              <div className="arrow-down" />
                              <div className="arrow-down-2" />
                            </div>
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <LoadingComponent />
                  )}
                  {this.state.nineBoxes.length !== 0 ? (
                    <div className="nine-box-x-axis-label">
                      <div className="nine-box-x-axis-minimum-label">0</div>
                      <div className="nine-box-x-axis-maximum-label">
                        {this.state.maximumScore}
                      </div>
                    </div>
                  ) : null}

                  {this.state.nineBoxes.length !== 0 ? (
                    <div className="nine-box-x-axis-title">
                      {this.state.xAxisLabel}
                    </div>
                  ) : null}

                  {this.state.nineBoxes.length !== 0 ? (
                    <div className="nine-box-legend">
                      <div className="dot-single" />
                      <div className="label-single">Single</div>
                      <div className="dot-single dot-multiple" />
                      <div className="label-single">Multiple</div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-6">
                  <div className="average-view-section">
                    <div className="average-view-title">Average</div>
                    {this.state.nineBoxes.length !== 0 ? (
                      <div className="row-average-view-sections">
                        <div className="col-md-5">
                          <div className="average-title performance-average-title">
                            {this.state.xAverageTitle}
                          </div>
                          <h1 className="average-title average-value performance-average-title">
                            {this.state.xAverageScore}
                          </h1>
                        </div>
                        <div className="col-md-2">
                          <div className="average-view-divider" />
                        </div>
                        <div className="col-md-5">
                          <div className="average-title behavior-average-title">
                            {this.state.yAverageTitle}
                          </div>
                          <h1 className="average-title average-value behavior-average-title">
                            {this.state.yAverageScore}
                          </h1>
                        </div>
                      </div>
                    ) : (
                      <LoadingComponent />
                    )}
                  </div>

                  <div className="chart-view-section">
                    <div className="chart-view-header">
                      <div className="col-md-12 chart-view-title">Summary</div>
                      <div className="col-md-12 chart-view-count-label">
                        {this.state.employees.length} people
                      </div>
                    </div>

                    {this.state.nineBoxes.length !== 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={summaryChartConfig}
                        ref={this.chartRef}
                      />
                    ) : (
                      <LoadingComponent />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="empty-state-container">
                <div className="header">No data available yet</div>
                <div className="sub-header">
                  When the data is available, you will see it here
                </div>
              </div>
            )}
          </div>

          {this.state.tableNodeCategory !== '' ? (
            <div
              className="bottom-section"
              ref={(el) => {
                this.messagesEnd = el;
              }}
            >
              <div className="table-title-label">
                {this.state.tableNodeCategory}
              </div>
              <div className="table-rating">
                <div className="table-label">{this.state.xAverageTitle} :</div>
                <div className="table-score-label">
                  {this.state.tableXRating}
                </div>
                <div className="table-score-label">|</div>
                <div className="table-label">{this.state.yAverageTitle} :</div>
                <div className="table-score-label">
                  {this.state.tableYRating}
                </div>
              </div>
              <div className="table-section">
                <div className="table-header">
                  <div className="table-header-column table-header-name-column">
                    Name
                  </div>
                  <div className="table-header-column-divider" />
                  <div className="table-header-column table-header-position-column">
                    Position
                  </div>
                  <div className="table-header-column-divider" />
                  <div className="table-header-performance-column">
                    <div className="table-header-column">{`${getObjectiveLocale(
                      'Performance'
                    )} Score`}</div>
                    <div className="table-header-row-divider" />
                    <div className="table-header-performance-bottom-row">
                      <div className="table-header-column table-header-performance-column">{`${getObjectiveLocale(
                        'Goals'
                      )}`}</div>
                      <div className="table-header-subcolumn-divider" />
                      <div className="table-header-column table-header-performance-column">{`${getObjectiveLocale(
                        'Tasks'
                      )}`}</div>
                      <div className="table-header-subcolumn-divider" />
                      <div className="table-header-column table-header-performance-column">
                        Total
                      </div>
                    </div>
                  </div>
                  <div className="table-header-column-divider" />
                  <div className="table-header-performance-column">
                    <div className="table-header-column">{`${getObjectiveLocale(
                      'Behaviour'
                    )} Score`}</div>
                    <div className="table-header-row-divider" />
                    <div className="table-header-performance-bottom-row">
                      <div className="table-header-column table-header-performance-column">{`${getObjectiveLocale(
                        'Values'
                      )}`}</div>
                      <div className="table-header-subcolumn-divider" />
                      <div className="table-header-column table-header-performance-column">{`${getObjectiveLocale(
                        'Competencies'
                      )}`}</div>
                      <div className="table-header-subcolumn-divider" />
                      <div className="table-header-column table-header-performance-column">
                        Total
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.tableType === TableType.SINGLE ? (
                  <div className="table-header">
                    <div className="table-name-column">
                      <AWSImage
                        className="table-employee-avatar img-circle"
                        src={this.getProfilePicture(
                          this.state.tableSingleEmployee
                        )}
                      />
                      {this.state.tableSingleEmployee.user.name}
                    </div>
                    <div className="table-position-column">
                      {this.state.tableSingleEmployee.user.job_title !== ''
                        ? this.state.tableSingleEmployee.user.job_title
                        : '-'}
                    </div>
                    <div className="table-performance-goal-column">
                      {this.state.tableSingleEmployee.score.goals_score.toFixed(
                        1
                      )}
                    </div>
                    <div className="table-performance-task-column">
                      {this.state.tableSingleEmployee.score.tasks_score.toFixed(
                        1
                      )}
                    </div>
                    <div className="table-performance-total-column">
                      {this.state.tableSingleEmployee.score.performance_score.toFixed(
                        1
                      )}
                    </div>
                    <div className="table-behaviour-value-column">
                      {this.state.tableSingleEmployee.score.values_score.toFixed(
                        1
                      )}
                    </div>
                    <div className="table-behaviour-competency-column">
                      {this.state.tableSingleEmployee.score.competencies_score.toFixed(
                        1
                      )}
                    </div>
                    <div className="table-behaviour-total-column">
                      {this.state.tableSingleEmployee.score.behavior_score.toFixed(
                        1
                      )}
                    </div>
                  </div>
                ) : (
                  this.state.groupedDuplicateScoreEmployees[
                    this.state.tableGroupedEmployeeIndex
                  ].map((employee, index) => {
                    const firstIndex =
                      (this.state.tablePaginationActiveIndex - 1) *
                        this.state.tablePaginationActiveChoice +
                      1;
                    const lastIndex =
                      this.state.tablePaginationActiveIndex *
                      this.state.tablePaginationActiveChoice;
                    if (firstIndex <= index + 1 && lastIndex >= index + 1) {
                      return (
                        <div className="table-header">
                          <div className="table-name-column">
                            <AWSImage
                              className="table-employee-avatar img-circle"
                              src={this.getProfilePicture(employee)}
                            />
                            {employee.user.name}
                          </div>
                          <div className="table-position-column">
                            {employee.user.job_title !== ''
                              ? employee.user.job_title
                              : '-'}
                          </div>
                          <div className="table-performance-goal-column">
                            {employee.score.goals_score.toFixed(1)}
                          </div>
                          <div className="table-performance-task-column">
                            {employee.score.tasks_score.toFixed(1)}
                          </div>
                          <div className="table-performance-total-column">
                            {employee.score.performance_score.toFixed(1)}
                          </div>
                          <div className="table-behaviour-value-column">
                            {employee.score.values_score.toFixed(1)}
                          </div>
                          <div className="table-behaviour-competency-column">
                            {employee.score.competencies_score.toFixed(1)}
                          </div>
                          <div className="table-behaviour-total-column">
                            {employee.score.behavior_score.toFixed(1)}
                          </div>
                        </div>
                      );
                    }
                  })
                )}
              </div>
              <div className="table-pagination">
                <div className="table-pagination-set-section">
                  <div className="table-pagination-show-label">Show</div>
                  <div className="table-pagination-show-dropdown">
                    <select
                      className="form-control"
                      id="paginationChoice"
                      onChange={this.onChangePaginationChoice}
                    >
                      {this.state.tablePaginationPerPageChoice.map((choice) => (
                        <option value={choice}>{choice}</option>
                      ))}
                    </select>
                  </div>
                  <div className="table-pagination-entries-of">
                    <span>{'  '}entries of</span>
                  </div>
                  <div className="table-pagination-total-employees">
                    {this.state.tableType === TableType.SINGLE
                      ? 1
                      : this.state.groupedDuplicateScoreEmployees[
                          this.state.tableGroupedEmployeeIndex
                        ].length}
                  </div>
                </div>

                <div className="table-pagination-indexes">
                  {this.state.tablePaginationIndexes.map((index) => {
                    if (index != this.state.tablePaginationActiveIndex) {
                      return (
                        <div
                          className="table-pagination-index"
                          onClick={() => this.onClickPaginationIndex(index)}
                        >
                          {index}
                        </div>
                      );
                    } else {
                      return (
                        <div className="table-pagination-index pagination-active">
                          {index}
                        </div>
                      );
                    }
                  })}
                  {this.state.tablePaginationActiveIndex !=
                  this.state.tablePaginationIndexes[
                    this.state.tablePaginationIndexes.length - 1
                  ] ? (
                    <div
                      className="table-pagination-index"
                      onClick={() => this.onClickPaginationNext()}
                    >
                      {'>'}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}
export default TalentMapping;

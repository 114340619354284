import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import ctl from '@netlify/classnames-template-literals';

import { getListMeasurement } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getWordingMetrics } from 'utils/ObjectivesHelper';

import DropdownMetric from 'components/dropdown/DropdownMetric';
import DropdownRollUp from 'components/dropdown/DropdownRollUp';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import NumberInput from 'components/shared/NumberInput';
import SVGIcon from 'components/shared/SVGIcon';
import {
  CALC_TYPE_EARLY_COMPLETION,
  CALC_TYPE_LESS_IS_BETTER,
  CALC_TYPE_ZERO_TARGET
} from 'src/utils/const';
import { useDeepEffect } from 'src/utils/useDeepEffect';

import TooltipContainer from '../shared/Tooltips/TooltipContainer';

const noMetricOption = {
  id: 0,
  description: 'No Metrics',
  unit: '',
  unitIcon: null
};

const MetricsDropdown = (props) => {
  const {
    config: { showMetricForObjective, showMetricForTask }
  } = useUser();

  const configSelector =
    props?.objectiveType == 'task' ? showMetricForTask : showMetricForObjective;

  const ref = useRef();

  const [listMetrics, setListMetrics] = useState(null);

  const getMetrics = useCallback(async () => {
    let list = configSelector?.includes('no_metric') ? [noMetricOption] : [];

    if (configSelector?.includes('all')) {
      const { data } = await getListMeasurement();
      if (data) {
        list.push(...data);
      }
    }

    setListMetrics(list);
  }, [configSelector]);

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  return (
    <div
      ref={ref}
      onClick={(e) => e.stopPropagation()}
      className="cursor-default"
    >
      {ref && listMetrics && (
        <MetricsDropdownWrapper
          parentRef={ref}
          listMetrics={listMetrics}
          {...props}
        />
      )}
    </div>
  );
};

const MetricsModalContent = ({
  listMetrics,
  style,
  parentRef,
  vPosition = 'top',
  position = 'right',
  type = 'fixed',
  customClass,
  measurement,
  setShowDialog,
  closeEvent,
  objectiveType,
  useAdvanced = true,
  calculationType,
  isGoalType
}) => {
  const {
    config: {
      defaultRollupForParentObjective,
      defaultRollupForParentTask,
      objectiveCalculationType
    }
  } = useUser();
  const { targetValue, startingValue, rollUp, id: unitId } = measurement || {};

  const isLessIsBetter = calculationType == CALC_TYPE_LESS_IS_BETTER;

  const defaultRollUp =
    objectiveType == 'task'
      ? defaultRollupForParentTask
      : defaultRollupForParentObjective;

  const selected =
    listMetrics.find((data) => data.id == unitId) || listMetrics[0];

  const [localRect, setLocalRect] = useState(false);
  const [isShowAdvanced, setIsShowAdvanced] = useState(false);

  const [targetValueInput, setTargetValueInput] = useState(targetValue || 0);
  const [startingValueInput, setStartingValueInput] = useState(
    startingValue || 0
  );

  const isZeroTarget = useMemo(
    () =>
      isGoalType &&
      objectiveCalculationType?.includes(CALC_TYPE_ZERO_TARGET) &&
      calculationType != CALC_TYPE_LESS_IS_BETTER &&
      targetValueInput == 0,
    [isGoalType, objectiveCalculationType, calculationType, targetValueInput]
  );
  const [allowStartingNegative, setAllowStartingNegative] = useState(
    !isZeroTarget
  );
  const [selectedMetric, setSelectedMetric] = useState(selected);
  const [rollUpValue, setRollupValue] = useState(rollUp);

  const getZeroTargetInfo = (value) => {
    if (!objectiveCalculationType?.includes(CALC_TYPE_ZERO_TARGET))
      return false;
    if (
      calculationType?.includes(CALC_TYPE_LESS_IS_BETTER) ||
      calculationType?.includes(CALC_TYPE_EARLY_COMPLETION)
    )
      return false;
    if (value != 0) return false;
    return true;
  };

  const handleInputTarget = (value) => {
    setTargetValueInput(value);
    const isZeroInfo = getZeroTargetInfo(value);
    if (isZeroInfo && startingValueInput < 0) {
      setStartingValueInput((draft) => Math.abs(draft));
    }
  };

  useDeepEffect(() => {
    setAllowStartingNegative(!isZeroTarget);
  }, [isZeroTarget]);

  const localRef = useRef();
  useClickOutside(localRef, () => {
    closeEvent &&
      closeEvent({
        data: {
          targetValue: parseFloat(targetValueInput || 0),
          startingValue: parseFloat(startingValueInput || 0),
          unitId: selectedMetric?.id,
          unit: selectedMetric?.unit,
          rollUp: rollUpValue,
          calculationType: isZeroTarget
            ? CALC_TYPE_ZERO_TARGET
            : calculationType == CALC_TYPE_ZERO_TARGET
            ? 'normal'
            : calculationType
        }
      });
    setShowDialog(false);
  });

  const rect = parentRef?.current?.getBoundingClientRect();
  let fixedPositionProps = {
    ...(position == 'left' && { left: rect?.left }),
    ...(position == 'middle' && {
      left: rect?.left + rect?.width / 2 - localRect.width / 2
    }),
    ...(position == 'right' && { left: rect?.right - localRect.width }),
    ...(vPosition == 'top' && { top: rect?.top - 8 - localRect?.height }),
    ...(vPosition == 'bottom' && { top: rect?.bottom })
  };

  const fixedPositionStyle = {
    left: fixedPositionProps.left,
    top: fixedPositionProps.top,
    transform: `translate(${fixedPositionProps.translateX || 0}, ${
      fixedPositionProps.translateY || 0
    })`,
    zIndex: 100001
  };

  const MenuCN = () => {
    return ctl(`
    flex
    shadow-raised
    rounded-md
    z-20
    bg-n-000
    p-16
    flex-col
    items-start
    w-[360px]
    ${
      type == 'absolute' &&
      `
      absolute
      ${vPosition === 'top' && 'top-0 -translate-y-full'}
      ${vPosition === 'bottom' && 'bottom-0 translate-y-full'}
      ${position === 'left' && 'left-0'}
      ${position === 'right' && 'right-0'}
      ${position === 'middle' && 'left-0 -translate-x-1/2'}
      `
    }
      ${type == 'fixed' && 'fixed'}
      ${customClass ? customClass : ''}
    `);
  };

  // ${position === 'top-right' && 'top-auto right-0 bottom-32'}
  const mergedStyle = {
    ...style,
    ...(type == 'fixed' && fixedPositionStyle)
  };
  // ...{
  //   '--vertical-transform-origin': vPosition === 'bottom' ? 'top' : 'bottom'
  // }

  useEffect(() => {
    const rect = localRef?.current?.getBoundingClientRect();
    setLocalRect({ width: rect?.width, height: rect?.height });
    const eventScroll = () => {
      setShowDialog(false);
    };
    document
      .getElementsByTagName('html')[0]
      .addEventListener('scroll', eventScroll, true);
    return () => {
      document
        .getElementsByTagName('html')[0]
        .removeEventListener('scroll', eventScroll, true);
    };
  }, []);

  useEffect(() => {
    if (
      (defaultRollUp == 'average' || defaultRollUp == 'weighted_average') &&
      (selectedMetric.description == 'Percentage' ||
        selectedMetric.description == 'Percentages')
    ) {
      setRollupValue(defaultRollUp);
    } else {
      setRollupValue('disabled');
    }
  }, [selectedMetric, defaultRollUp]);

  return (
    <div ref={localRef} className={MenuCN()} style={mergedStyle}>
      <div className="flex items-center">
        <div className="w-[160px] mr-[8px]">
          <p className="typography-h100 text-n-800 mb-8">
            {getObjectiveLocale('Target Value')}
          </p>
          <NumberInput
            placeholder="Targets"
            onChange={handleInputTarget}
            value={targetValueInput}
            inputClass="mb-0"
            dataCy="metrics-input-target"
            disabled={selectedMetric?.id == 0}
            precision={13}
          />
        </div>
        <div className="w-[160px]">
          <p className="typography-h100 text-n-800 mb-[8px]">
            {getObjectiveLocale('Metrics')}
          </p>
          {listMetrics && (
            <DropdownMetric
              defaultValue={selectedMetric ? selectedMetric.id : 0}
              handleChange={setSelectedMetric}
              listMetrics={listMetrics || []}
              triggerCustomClass={'w-[160px]'}
              wrapFloating
            />
          )}
        </div>
      </div>
      {isZeroTarget && (
        <div className="mt-[8px] w-[100%] py-[4px] px-[16px] bg-b-200 rounded flex">
          <TooltipContainer
            wrap
            show
            text={getObjectiveLocale(
              'The closer the current value to 0, the better progress percentage and its status will be'
            )}
            useMaxWidth={false}
            tooltipClass="max-w-[329px]"
          >
            <SVGIcon
              size="24"
              fillColor="var(--b-600)"
              iconName="icon-info"
              customClass="info-less-is cursor-pointer"
            />
          </TooltipContainer>
          <p className="typography-paragraph text-n-900 ml-[16px]">
            {getObjectiveLocale('Zero Target mode is on')}
          </p>
        </div>
      )}
      {parseFloat(startingValueInput || '0') ==
        parseFloat(targetValueInput || '0') &&
        selectedMetric?.id != 0 && (
          <p className="error-text-value">
            {getObjectiveLocale(
              isLessIsBetter
                ? "Target value can't be 0"
                : "Initial value can't be equal with target value"
            )}
          </p>
        )}

      {useAdvanced && selectedMetric?.id != 0 && (
        <>
          <div
            className="flex items-center mt-[10px] cursor-pointer"
            onClick={() => setIsShowAdvanced(!isShowAdvanced)}
          >
            <p className="typography-paragraph text-base-600 mr-[4px]">
              {getObjectiveLocale(
                `${isShowAdvanced ? 'Hide' : 'Show'} advanced options`
              )}
            </p>
            <SVGIcon
              size="24"
              iconName={
                isShowAdvanced ? 'icon-arrow_drop_up' : 'icon-arrow_drop_down'
              }
              fillColor="var(--base-600)"
            />
          </div>
          {isShowAdvanced && (
            <div className="bg-n-200 px-[16px] py-[16px] rounded-[4px] mt-[12px] w-full">
              <div className="w-full mb-[16px]">
                <p className="typography-h100 text-n-800 mb-[8px]">
                  {getObjectiveLocale('Initial Value')}
                </p>
                {isLessIsBetter ? (
                  <p className="typography-paragraph text-n-600">
                    {getObjectiveLocale(
                      'Initial value is being disabled because you are using less is better'
                    )}
                  </p>
                ) : (
                  <NumberInput
                    value={startingValueInput}
                    placeholder="Initial Value"
                    onChange={setStartingValueInput}
                    inputClass="mb-[0px]"
                    allowNegative={allowStartingNegative}
                  />
                )}
              </div>
              {rollUp && (
                <>
                  <div
                    className="advanced_input"
                    data-cy="sidebar-create-rollup"
                  >
                    <p className="typography-h100 text-n-800 mb-[8px]">
                      {getObjectiveLocale('Set Auto Roll Up')}
                    </p>
                    <DropdownRollUp
                      selectedMetric={selectedMetric}
                      handleChange={setRollupValue}
                      defaultValue={rollUpValue}
                    />
                  </div>
                  <div className="flex items-center mt-[16px]">
                    <SVGIcon
                      size={16}
                      fillColor="var(--n-800)"
                      iconName="icon-info"
                    />
                    <p className="typography-paragraph text-n-800 ml-[8px]">
                      {getWordingMetrics(rollUpValue)}
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const MetricsDropdownWrapper = (props) => {
  const { usePortal } = props;

  if (usePortal) {
    return (
      <FloatingComponent>
        <MetricsModalContent {...props} />
      </FloatingComponent>
    );
  }

  return <MetricsModalContent {...props} />;
};

export default MetricsDropdown;

import { useEffect, useState } from "react";

function useParseNumber(number) {

  const [newNumber, setNewNumber] = useState(number);
  const [formatedNumber, setFormatedNumber] = useState((parseFloat(number) || 0).toLocaleString(navigator.language));

  function whatDecimalSeparator() {
    var n = 1.1;
    return n.toLocaleString(navigator.language).substring(1, 2);
  }

  function whatNumberSeparator() {
    var n = 1111;
    return n.toLocaleString(navigator.language).substring(1,2);
  }

  const setNumber = stringNumber => {
    const decimalSeparator = whatDecimalSeparator();
    const numberSeparator = whatNumberSeparator();
    
    if(stringNumber.length == 0){
      setNewNumber(0);
      return;
    }

    // replace all locale decimal separator to "."
    stringNumber = stringNumber.replace(new RegExp(`\\${numberSeparator}`, "g"), "")
    stringNumber = stringNumber.replace(new RegExp(`\\${decimalSeparator}`, "g"), ".")

    // handle when input started "0"
    if (stringNumber.indexOf("0") == 0 && stringNumber.slice(1,2) !== ".") {
      setNewNumber(parseFloat(stringNumber));
      return;
    } else {
      // determine if number is decimal format
      if (stringNumber.indexOf(".") > -1) {
        const [number, decimals] = stringNumber.split(".")

        // reject input when decimal has "."
        if (decimals.indexOf(".") > -1) return;

        setNewNumber(stringNumber);

      } else {
        setNewNumber(parseFloat(stringNumber));
      }
    }
  };

  useEffect(() => {
    const useDecimal = String(newNumber).indexOf('.') > -1
    const decimalSeparator = whatDecimalSeparator();
    const [num, dec] = String(newNumber).split('.')
    setFormatedNumber(useDecimal ? `${(parseFloat(num) || 0).toLocaleString(navigator.language)}${decimalSeparator}${dec}` : (newNumber || 0).toLocaleString(navigator.language));
    }, [newNumber]);

  useEffect(() => {
    setNewNumber(number);
  }, [number]);

  return {
    number: parseFloat(newNumber),
    formatedNumber,
    setNumber
  };
}

export default useParseNumber;
import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import WeightSettingsCard from './fragments/WeightSettingsCard';
import WeightSettingsInputNumber from './fragments/WeightSettingsInputNumber';

const WeightSettingsMaximum = ({ configData, changeObjectiveConfig }) => {
  const {
    objectivesConfig: {
      maxObjectiveWeightOn,
      minObjectiveWeightOn,
      maxObjectiveWeight,
      minObjectiveWeight
    }
  } = configData;

  const changeCallback = async ({ value, keys }) => {
    const isSuccess = await changeObjectiveConfig({ [keys]: value });
    return isSuccess;
  };

  const isError =
    minObjectiveWeightOn && maxObjectiveWeight <= minObjectiveWeight;

  return (
    <WeightSettingsCard
      title={'Maximum Weight Value'}
      subTitle={'Show the expected maximum weight of each objectives'}
      isOpen={maxObjectiveWeightOn}
      toggleCallback={({ value }) =>
        changeCallback({ value, keys: 'maxObjectiveWeightOn' })
      }
      toggleCy={'toggle-maximum'}
    >
      <div className="flex flex-col">
        <p className="typography-h100 text-n-800 mb-[8px]">
          {getObjectiveLocale('Maximum weight (%)')}
        </p>
        <div className="flex flex-row items-center">
          <WeightSettingsInputNumber
            defaultValue={maxObjectiveWeight}
            onChangeCallback={(value) =>
              changeCallback({ value, keys: 'maxObjectiveWeight' })
            }
            isError={isError}
            dataCy="maximum-weight-input"
          />
          {isError && (
            <p className="typography-paragraph text-r-600 ml-[16px]">
              {getObjectiveLocale(`Maximum weight value must more than`)}{' '}
              {minObjectiveWeight}%
            </p>
          )}
        </div>
      </div>
    </WeightSettingsCard>
  );
};

export default WeightSettingsMaximum;

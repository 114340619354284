import React, { useEffect, useState } from 'react';

import { SplideSlide } from '@splidejs/react-splide';
import isEmpty from 'lodash/isEmpty';

import { getLabelList } from 'client/CultureClient';
import { useUser } from 'context/UserContext';
import { getCultureLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import TooltipPortal from 'components/shared/Tooltips/TooltipPortal';
import SlickSlider from 'components/slider/SlickSlider';

const LabelFilter = ({
  setCurrentFilter,
  currentFilter,
  setFilter,
  setPage
}) => {
  const { config } = useUser();
  const [portalData, setPortalData] = useState({});
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [labels, setLabels] = useState([]);
  const activeFilterStyle = 'border-solid border-0 border-b-2 border-base-600';

  const setPortal = (e, title) => {
    setIsTooltipShown(true);
    setPortalData({
      name: title,
      left: e.currentTarget.getBoundingClientRect().x + 'px',
      top: e.currentTarget.getBoundingClientRect().top + 40 + 'px'
    });
  };

  const staticFilter = [
    {
      id: 'recent',
      title: getCultureLocale('Recent'),
      icon: 'icon-recent-timeline',
      isVisible: true
    },
    {
      id: 'recognition-filter',
      title: getCultureLocale('Recognition'),
      icon: 'icon-recognition-timeline',
      isVisible: config.featureConfig.allowedPostTypes.includes('recognition')
    },
    {
      id: 'feeling-filter',
      title: getCultureLocale('Feeling'),
      icon: 'icon-feeling-timeline',
      isVisible: config.featureConfig.allowedPostTypes.includes('feeling')
    },
    {
      id: 'poll-filter',
      title: getCultureLocale('Polling'),
      icon: 'icon-polling-timeline',
      isVisible: config.featureConfig.allowedPostTypes.includes('poll')
    }
  ].filter((filter) => filter.isVisible);

  const _getLabelList = async () => {
    const params = {
      adminLabel: true,
      announcementLabel: true,
      admin: 1,
      status: ['Active']
    };
    const { data } = await getLabelList(params);

    if (data) {
      setLabels(data);
    }
  };

  const selectFilter = (filterId) => {
    setCurrentFilter(filterId);
    setPage(`homepage-${filterId}`);
    setFilter(filterId);
  };

  useEffect(() => {
    _getLabelList();
  }, []);

  return (
    <>
      <div id="portal-label-timeline" />
      <div
        className="w-[560px] h-[120px] border border-solid border-n-400 rounded-lg my-[24px] flex items-center pr-[8px] bg-n-000"
        data-cy="filter-section"
      >
        <SlickSlider
          useDots={false}
          isInfinite={false}
          slidesToShow={5}
          slidesToScroll={3}
          gap="8px"
          fixedWidth="88px"
        >
          {staticFilter.map((filter) => (
            <SplideSlide>
              <div
                className={`flex flex-col justify-center items-center min-w-[88px] w-[88px] mr-[8px] cursor-pointer h-[120px]
                  ${filter?.id === currentFilter ? activeFilterStyle : ''}`}
                onClick={() => selectFilter(filter?.id)}
                data-cy="filter-timeline"
              >
                <SVGIcon size="48" iconName={filter.icon} />
                <div
                  className={`typography-h100 mt-[8px] h-[16px] ${
                    filter?.id === currentFilter ? 'text-n-900' : 'text-n-600'
                  }`}
                >
                  {filter.title}
                </div>
              </div>
            </SplideSlide>
          ))}
          {labels.map((label) => (
            <SplideSlide>
              <div
                className={`flex flex-col justify-center items-center min-w-[88px] w-[88px] mr-[8px] cursor-pointer h-[120px]
                  ${label?.id == currentFilter ? activeFilterStyle : ''}`}
                onClick={() => {
                  selectFilter(label?.id);
                }}
                data-cy="filter-timeline"
              >
                <SVGIcon
                  size="24"
                  iconName="icon-circle"
                  fillColor={label.colorCode}
                  customClass="border border-solid border-n-400 rounded-full p-8"
                />
                <div
                  className={`typography-h100 mt-[16px] text-center multiline-text-1 w-full h-[16px] ${
                    label?.id === currentFilter ? 'text-n-900' : 'text-n-600'
                  }`}
                  onMouseEnter={(e) =>
                    label.title.length > 15 && setPortal(e, label.title)
                  }
                  onMouseLeave={() => setIsTooltipShown(false)}
                >
                  {label.title}
                </div>
              </div>
            </SplideSlide>
          ))}
        </SlickSlider>
      </div>
      {!isEmpty(portalData) && (
        <TooltipPortal
          show={isTooltipShown}
          portalData={portalData}
          portalId="portal-label-timeline"
          className="max-w-[420px]"
        />
      )}
    </>
  );
};

export default LabelFilter;

import React from 'react';

import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import startCase from 'lodash/startCase';

import { capitalize, getObjectiveLocale } from 'utils/HelperUtils';

const customPhaseNames = {
  team_review: getObjectiveLocale('Member Review'),
  team_leader_review: getObjectiveLocale('Leader Review')
};

const customTrackType = {
  values_scoring: getObjectiveLocale('Behaviour Scoring'),
  competencies_scoring: getObjectiveLocale('Behaviour Scoring'),
  goals_scoring: getObjectiveLocale('Performance Scoring'),
  task_scoring: getObjectiveLocale('Performance Scoring')
};

const customPhaseTypes = {
  team_review: getObjectiveLocale('Member'),
  team_leader_review: getObjectiveLocale('Leader')
};

const customPhaseInstructions = {
  manager_review: getObjectiveLocale('Review your direct report'),
  indirect_manager_review: getObjectiveLocale('Review your indirect report'),
  upward_review: getObjectiveLocale('Review your direct supervisor'),
  self_review: getObjectiveLocale('Review yourself'),
  peer_review: getObjectiveLocale('Review your peer'),
  calibration: getObjectiveLocale(
    "Start doing calibration for your team's final score"
  ),
  team_review: getObjectiveLocale('Review team members'),
  team_leader_review: getObjectiveLocale('Review the team leader')
};

const customPhaseDescriptionsForTarget = {
  manager_review: getObjectiveLocale('Review from your manager'),
  indirect_manager_review: getObjectiveLocale(
    'Review from your indirect manager'
  ),
  upward_review: getObjectiveLocale('Review from your direct reports'),
  self_review: getObjectiveLocale('Review from yourself'),
  peer_review: getObjectiveLocale('Review from your peers'),
  calibration: getObjectiveLocale(''),
  team_review: getObjectiveLocale('Review from your fellow team members'),
  team_leader_review: getObjectiveLocale('Review from your team reviewer')
};

const customPhaseDescriptionsForActor = {
  manager_review: getObjectiveLocale(
    'Review given by you to your direct reports'
  ),
  indirect_manager_review: getObjectiveLocale(
    'Review given by you to your indirect report'
  ),
  upward_review: getObjectiveLocale(
    'Review given by you to your direct supervisor'
  ),
  self_review: getObjectiveLocale('Review from yourself'),
  peer_review: getObjectiveLocale('Review given by you to your peers'),
  team_review: getObjectiveLocale(
    'Review given by you to your fellow team members'
  ),
  team_leader_review: getObjectiveLocale(
    'Review given by you to the team leader'
  )
};

function phaseName(phase) {
  if (phase) {
    return customPhaseNames[phase] || getObjectiveLocale(startCase(phase));
  }
}

function phaseShortName(phase) {
  if (phase) {
    return phaseName(phase).replace(' Review', '');
  }
}

function phaseType(phase) {
  return customPhaseTypes[phase] || '';
}

function phaseInstruction(phase) {
  return customPhaseInstructions[phase] || '';
}

function phaseDescriptionForTarget(phase) {
  return customPhaseDescriptionsForTarget[phase] || '';
}

function phaseDescriptionForActor(phase) {
  return customPhaseDescriptionsForActor[phase] || '';
}

function trackType(track) {
  return customTrackType[track];
}

function getCycleDisplayName(cycle) {
  const cycleName = cycle.name;

  if (
    cycle.showScheduleDate &&
    cycle.reviewProcessStartsAt &&
    cycle.reviewProcessEndsAt
  ) {
    return `${cycleName} - (${dayjs(cycle.reviewProcessStartsAt).format(
      'DD MMM YYYY'
    )} - ${dayjs(cycle.reviewProcessEndsAt).format('DD MMM YYYY')})`;
  } else {
    return cycleName;
  }
}

function getCurrentTrackConfig(
  currentTrackType,
  currentTrackName,
  currentPhase,
  phases
) {
  const currentPhaseData = phases?.find((phase) => phase.type === currentPhase);
  const currentTrackConfig = currentPhaseData?.trackConfigs?.find(
    (trackConfig) => {
      return (
        trackConfig.type === currentTrackType &&
        trackConfig.name === currentTrackName
      );
    }
  );

  return currentTrackConfig;
}

function getMessageDepedencyNotification(phase) {
  const { notificationRecurrences, dependencies } = phase || {};

  if (notificationRecurrences == 'each_time') {
    return 'Notify each time an assignment has been unlocked';
  } else if (
    notificationRecurrences !== 'each_time' &&
    notificationRecurrences
  ) {
    return `Notify when dependent assignment(s) has been unlocked periodically (${capitalize(
      notificationRecurrences
    )})`;
  } else if (!notificationRecurrences && dependencies?.length > 0) {
    return 'No notification';
  }

  return '';
}

function modifyPhaseData(phase) {
  let phaseData = phase;

  if (phaseData) {
    if (!phaseData.revieweeSummaryVisibility) {
      phaseData.revieweeSummaryVisibility = 'detail_summary';
    }

    phaseData.trackConfigs.map((trackConfig) => {
      if (!trackConfig.displayOnResult) {
        trackConfig.displayOnResult = false;
      }
    });

    if (phase.reminders && phase.reminders.length > 0) {
      phaseData.reminders = phase.reminders.map((reminder) => {
        return { remindsAt: reminder.remindsAt };
      });
    }
  }
  return phaseData;
}

const getCustomAttributeList = (cycleData, phases) => {
  let listCustomAttributeTrack = [];

  phases.map((phase) => {
    if (cycleData?.[phase]?.trackConfigs?.length > 0) {
      cycleData?.[phase]?.trackConfigs?.forEach((trackConfig) => {
        const listCustomAttributeTrackIds = listCustomAttributeTrack?.map(
          (customAttr) => customAttr?.templateId
        );

        if (trackConfig?.type === 'review_aspects_scoring') {
          if (listCustomAttributeTrackIds?.includes(trackConfig.template.id)) {
            let currentAttr = listCustomAttributeTrack.find(
              (listCustomAttr) =>
                listCustomAttr.templateId === trackConfig.template.id
            );
            currentAttr.selectedPhase.push(phase);
          } else {
            listCustomAttributeTrack.push({
              name: trackConfig.template.name,
              trackType: trackConfig.type,
              templateId: trackConfig.template.id,
              selectedPhase: [phase]
            });
          }
        }
      });
    }
  });

  return listCustomAttributeTrack;
};

function getSingleTrack(cycleData, phaseType, identifier) {
  const isNumber = !isNaN(identifier);

  const singleTrack = cycleData?.[phaseType]?.trackConfigs?.find((track) => {
    return isNumber
      ? track.template.id === identifier
      : track.type === identifier;
  });

  return singleTrack;
}

function getPrefillConfigWording(trackData) {
  if (trackData) {
    if (trackData?.config?.suggestionSource === 'previous_cycle') {
      return 'Show the answers from previous cycle';
    }

    if (trackData?.config?.autoFill && trackData?.config?.useSuggestion) {
      return 'Show and prefill the answers';
    }

    if (!trackData?.config?.autoFill && trackData?.config?.useSuggestion) {
      return 'Show the answers';
    }

    if (!trackData?.config?.autoFill && !trackData?.config?.useSuggestion) {
      return 'Do not show or prefill the answers';
    }
  }
}

function getPrefillConfiguration(cycleData, phaseType, trackType) {
  const trackData1 = getSingleTrack(cycleData, phaseType, trackType);
  const trackData2 = getSingleTrack(
    cycleData,
    phaseType === 'managerReview' ? 'selfReview' : 'managerReview',
    trackType
  );

  const isMeetRequirements =
    trackData1 &&
    trackData2 &&
    trackData1?.template?.id === trackData2?.template?.id
      ? true
      : false;
  const prefillConfigWording = getPrefillConfigWording(trackData1);

  return {
    isMeetRequirements,
    prefillConfigWording,
    trackData: trackData1
  };
}

const getProgressTooltipComponent = (data) => {
  return (
    <div className="px-[16px] py-[12px] w-[320px]">
      <div className="flex justify-between mb-[8px]">
        <p className="typography-h100 text-n-800">
          {getObjectiveLocale('Progress from Active & Pending Users')}
        </p>
        <p className="typography-h200 text-n-900">
          {data?.progressPercentage.toFixed(2)}%
        </p>
      </div>
      <div className="flex justify-between">
        <p className="typography-h100 text-n-800">
          {getObjectiveLocale('Progress including Deleted Users')}
        </p>
        <p className="typography-h200 text-n-600">
          {data?.allProgressPercentage.toFixed(2)}%
        </p>
      </div>
    </div>
  );
};

const getDefaultReviewDeadline = (deadlineDate) => {
  if (dayjs() <= dayjs(deadlineDate) && deadlineDate) {
    return dayjs(deadlineDate).toISOString();
  } else {
    return dayjs().toISOString();
  }
};

const getAssignmentsGroupByDate = (assignments) => {
  const sortedAssignmentDate = assignments?.sort((a, b) => {
    return new Date(a.endsAt) - new Date(b.endsAt);
  });

  const newAssignments = groupBy(sortedAssignmentDate, (data) =>
    data?.endsAt ? dayjs(data.endsAt).format('MMMM DD, YYYY') : data?.endsAt
  );

  return newAssignments;
};

const destructTrackConfigs = (data) => {
  const PHASES = [
    'selfReview',
    'peerReview',
    'upwardReview',
    'managerReview',
    'indirectManagerReview'
  ];

  PHASES.forEach((phase) => {
    if (data[phase]) {
      data[phase].trackConfigs = data[phase]?.trackConfigs?.map(
        (track, index) => {
          const track_template_id = track?.template?.id;
          delete track.template;
          delete track.selected;
          delete track?.savedPreFill;

          return { ...track, track_template_id, order: index + 1 };
        }
      );
    }
  });
};

const getBadgeTitle = (state) => {
  switch (state) {
    case 'in_progress':
      return 'Started';
    case 'draft':
      return 'Not Started';
    case 'ended':
      return 'Ended';
  }
};

const getBadgeColor = (state, isBackgroundColor) => {
  switch (state) {
    case 'in_progress':
      return `--g-${isBackgroundColor ? 2 : 8}00`;
    case 'draft':
      return `--n-${isBackgroundColor ? 3 : 8}00`;
    case 'ended':
      return `--b-${isBackgroundColor ? 2 : 8}00`;
  }
};

export {
  phaseName,
  phaseType,
  phaseInstruction,
  phaseDescriptionForTarget,
  phaseDescriptionForActor,
  trackType,
  getCycleDisplayName,
  phaseShortName,
  getCurrentTrackConfig,
  getMessageDepedencyNotification,
  modifyPhaseData,
  getCustomAttributeList,
  getPrefillConfiguration,
  getProgressTooltipComponent,
  getDefaultReviewDeadline,
  getAssignmentsGroupByDate,
  destructTrackConfigs,
  getBadgeTitle,
  getBadgeColor
};

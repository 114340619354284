import React, { useEffect } from 'react';

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      const isPortalClicked = (element) => {
        const hasPortal = !!element?.querySelector('div.is-portal');
        if (hasPortal) {
          const portalDiv = document.querySelectorAll(
            `[id^=shared-dropdown]`
          )[0];

          return portalDiv.contains(event.target);
        }

        return false;
      };

      const isRefClicked = (r) =>
        !r.current ||
        r.current.contains(event.target) ||
        isPortalClicked(r.current);

      if (Array.isArray(ref)) {
        const isClicked = ref.find((r) => isRefClicked(r)) || false;
        if (isClicked) return;
      } else {
        if (isRefClicked(ref)) return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;

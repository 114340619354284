import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import { getCultureLocale, getObjectiveLocale } from 'utils/HelperUtils';

import { trackEvent } from 'src/utils/AnalyticUtils';

import PermissionBox from './PermissionBox';

const PermissionConfigurationBox = ({
  data,
  selectedValues,
  setSelectedValues,
  setAllowUpdate,
  isReadOnly = false,
  isSecurityAdmin,
  setSaveStatus,
  isDefault = false,
  setGroup = {},
  info = {}
}) => {
  const { user, appType } = useUser();
  const pageParams = useParams();
  const disabledSetting =
    (isSecurityAdmin && pageParams.type === 'edit') || isReadOnly;

  const menuRef = useRef();

  const handleSelectValue = (key, value) => {
    setSaveStatus && setSaveStatus('Saving...');
    setAllowUpdate && setAllowUpdate(true);
    trackEvent({
      event: 'update permission',
      eventProperties: {
        email: user.email,
        menu: menuRef?.current?.toLowerCase(),
        permission: getObjectiveLocale(key)?.toLowerCase(),
        'previous value':
          typeof selectedValues[key] == 'number'
            ? selectedValues[key]
            : selectedValues[key]?.toLowerCase(),
        'current value': typeof value == 'number' ? value : value?.toLowerCase()
      },
      env: appType
    });
    setSelectedValues({
      ...selectedValues,
      [key]: value
    });
  };

  const changeLocale = (key) => {
    return appType === 'culture'
      ? getCultureLocale(key)
      : getObjectiveLocale(key);
  };

  const initialFormat = [
    {
      header: changeLocale('Goal Management Process')
    },
    {
      header: 'All',
      value: 'all'
    },
    {
      header: 'Self',
      value: 'self'
    },
    {
      header: 'Sub-\nordinates',
      value: 'subordinates'
    },
    {
      header: 'Self & Sub-\nordinates',
      value: 'self_and_subordinates'
    },
    {
      header: 'Manager',
      value: 'manager'
    },
    {
      header: 'None',
      value: 'none'
    }
  ];

  const approvalFormat = initialFormat?.filter(
    (format) => format?.header !== 'Manager'
  );
  const formalReviewFormat = initialFormat?.filter(
    (format) =>
      format?.value !== 'self' &&
      format?.value !== 'self_and_subordinates' &&
      format?.value !== 'manager'
  );

  const readOnlyFormat = [
    {
      header: changeLocale('Goal Management Process')
    },
    {
      header: 'All',
      value: 'all'
    },
    {
      header: 'Read Only',
      value: 'read_only'
    },
    {
      header: 'None',
      value: 'none'
    }
  ];

  const byRoleFormat = [
    {
      header: changeLocale('Team Management Process')
    },
    {
      header: 'All',
      value: 'true'
    },
    {
      header: 'By Role',
      value: 'false'
    }
  ];

  const [tableFormat, setTableFormat] = useState(initialFormat);

  const handleChangeMenu = (menu) => {
    let format = [];

    if (menu === 'settings') {
      format = [...readOnlyFormat];
    } else if (menu === 'approval') {
      format = [...approvalFormat];
    } else if (menu === 'formal_review') {
      format = [...formalReviewFormat];
    } else if (menu === 'squad' || menu === 'tribe') {
      format = [...byRoleFormat];
    } else {
      format = [...initialFormat];
    }

    format[0].header = `${menu.replace('_', ' ')} management process`;
    setTableFormat(format);
  };

  useEffect(() => {
    setTableFormat(
      tableFormat.map((format) => {
        return {
          ...format,
          selectedRadio: selectedValues,
          handleChange: handleSelectValue
        };
      })
    );
  }, [selectedValues]);

  return (
    <PermissionBox
      title="Permissions"
      description="Permissions that related to all management process in this organization"
      data={data}
      info={info}
      isDefault={isDefault}
      disabled={disabledSetting}
      tableFormat={tableFormat}
      handleSelectValue={handleSelectValue}
      handleChangeMenu={handleChangeMenu}
      selectedValues={selectedValues}
      setGroup={setGroup}
      listConfig={{
        menus: [
          'insight',
          'team',
          'features',
          'managers_approval',
          'feeds',
          'profile_culture',
          'culture_group',
          'goal_attributes',
          'subsidiary'
        ],
        selectKey: 'id',
        trueValue: 'true',
        falseValue: 'false'
      }}
    />
  );
};

export default PermissionConfigurationBox;

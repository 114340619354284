import React from 'react';

import AWSImage from 'components/design-system/AWSImage';
import { trackEvent } from 'src/utils/AnalyticUtils';

function PostLink({ content, postId, postType, groupDetail }) {
  const errorImage = (e) => {
    e.target.setAttribute('class', 'hidden-image');
  };

  const openLink = () => {
    const properties = {
      'post id': postId,
      'post type': postType,
      'group name': groupDetail.name,
      'group type': groupDetail.groupType,
      'url link': content.urlLink
    };

    trackEvent({
      event: 'view link',
      eventProperties: properties,
      env: 'culture'
    });
  };

  return (
    <div className="feed-link" data-cy="feed-link">
      <a href={content.urlLink} target="_blank" onClick={openLink}>
        <AWSImage
          src="https://api.happy5.co/culture/helper/crawler"
          className={content.imageUrl ? '' : 'hidden-image'}
          onError={(e) => errorImage(e)}
        />
        <div className="title-link typography-h500">{content.urlDesc}</div>
        <div className="domain-link typography-h100">{content.urlDomain}</div>
      </a>
    </div>
  );
}

export default PostLink;

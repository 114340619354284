import React, { useState } from 'react';

import { useGoalWeightSettingsContext } from 'context/GoalWeightSettingContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import RadioButton from 'components/shared/RadioButton';

const goalTypePreference = [
  { id: 1, description: 'Unselected', value: false },
  { id: 2, description: 'Must be selected', value: true }
];

const WeightSettingsPreference = ({ defaultValue, changeConfigData }) => {
  const { isReadOnly } = useGoalWeightSettingsContext();

  const [value, setValue] = useState(defaultValue);

  const toggleChange = async (e) => {
    const targetValue = e.target.value == 'true';
    setValue(targetValue);
    const isSuccess = await changeConfigData({
      mandatoryObjectivesCategory: targetValue
    });
    !isSuccess && setValue(defaultValue);
  };

  return (
    <div className="flex flex-col pl-[24px] pb-[20px] flex-[1]">
      <p className="typography-h100 text-n-800 mb-[8px]">
        {getObjectiveLocale('Goal type preference')}
      </p>
      {goalTypePreference.map((val, i) => (
        <div
          key={val.id}
          className={`flex items-center whitespace-nowrap ${
            i == 0 ? 'mb-[8px]' : ''
          }`}
        >
          <RadioButton
            dataCy={`goal-type-preference-${val.value}`}
            size="small"
            value={val.value}
            id={val.id}
            key={i}
            checked={value === val.value}
            onChange={toggleChange}
            addClass="h-[24px]"
            disabled={isReadOnly}
          >
            <span
              className={`ml-[8px] fs-14 ${
                isReadOnly ? 'text-n-600' : 'text-n-900 '
              }`}
            >
              {val.description}
            </span>
          </RadioButton>
        </div>
      ))}
    </div>
  );
};

export default WeightSettingsPreference;

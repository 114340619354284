import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import { format } from 'timeago.js';

import { getObjectiveDetail } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import useDropdown from 'hooks/useDropdown';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { toggleDropdown } from 'utils/HelperUtils';
import {
  getObjectiveLeader,
  getUserRole,
  whichBadgeBgColorHex,
  whichBadgeColorHex,
  whichStatus
} from 'utils/ObjectivesHelper';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';
import ListDropdown from 'components/objectives/ListDropdown';
import BookmarkToggle from 'components/objectives/compact-objective/BookmarkToggle';
import MenuOutsideClickWrapper from 'components/objectives/compact-objective/fragments/MenuOutsideClickWrapper';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import ProjectLabel from './ProjectLabel';

const ProjectCard = ({ objective }) => {
  const [objectiveData, setObjectiveData] = useState(objective);
  const {
    id,
    name,
    involvements,
    valueLastUpdatedAt,
    measurement,
    permissions = [],
    isHighlighted
  } = objectiveData || {};

  const { progressColorHex } = measurement || {};

  const { user: currentUser } = useUser();
  const history = useHistory();
  const { useMenu } = useDropdown({
    objective: objectiveData,
    permissions,
    page: 'myprojects',
    isInNewProjectPage: true,
    onComplete: (data) => setObjectiveData(data)
  });
  const listMenu = useMenu({ except: ['addGoal', 'addTask'] });
  const enableHighlighted = permissions?.includes('highlight');
  const [isEllipsis, setIsEllipsis] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [dropdownAnimation, setDropdownAnimation] = useState(
    'animation-open-dropdown-fadeInDownSlow'
  );

  const fetchObjective = () => {
    return getObjectiveDetail(objectiveData.id);
  };

  useQuery(['objective', objectiveData.id], fetchObjective, {
    initialData: objectiveData,
    staleTime: Infinity,
    onSuccess: (data) => {
      data.data && setObjectiveData((_) => data.data);
    }
  });

  const openProjectDetail = () => {
    const { pathname } = history.location;
    const redirectUrl = pathname.includes('teams') ? '/projects' : pathname;
    history.push(`${redirectUrl}/${id}`);
  };

  const leader = getObjectiveLeader(involvements);

  const getLabel = () => {
    return [
      {
        useBadge: true,
        content: whichStatus[progressColorHex || '44DB5E'],
        tooltipText: whichStatus[progressColorHex || '44DB5E'],
        iconLabel: 'status',
        colorHex: whichBadgeColorHex[progressColorHex || '44DB5E'],
        bgColorHex: whichBadgeBgColorHex[progressColorHex || '44DB5E'],
        isVisible: !isEmpty(measurement)
      },
      {
        useBadge: false,
        title: getUserRole(involvements, currentUser),
        tooltipText: getUserRole(involvements, currentUser).replace('As', ''),
        iconLabel: getUserRole(involvements, currentUser).includes('leader')
          ? 'leader'
          : 'member',
        isVisible: getUserRole(involvements, currentUser) !== ''
      },
      {
        useBadge: false,
        title: format(valueLastUpdatedAt),
        tooltipText: format(valueLastUpdatedAt),
        iconLabel: 'history',
        isVisible: true
      }
    ].filter(({ isVisible }) => isVisible);
  };

  const menuMoreHandler = (e, status) => {
    e.stopPropagation();
    toggleDropdown(status, setDropdownAnimation, setMenuOpen);
  };

  const listLabel = getLabel();

  const listMembers = involvements
    .filter(({ extendedRole }) => extendedRole != 'leader')
    ?.map((involvement) => ({
      name: involvement.user.name,
      src: involvement.user.profilePicture
    }));

  return (
    <div
      className="py-[16px] px-[16px] bg-n-000 shadow-raised rounded-[4px] cursor-pointer min-h-[124px] relative group"
      onClick={openProjectDetail}
      data-cy="project-card"
    >
      <TooltipContainer
        show={isEllipsis}
        position="top"
        text={name}
        useMaxWidth={false}
        classContainer="flex items-center"
      >
        {enableHighlighted && (
          <BookmarkToggle isHighlighted={isHighlighted} id={objectiveData.id} />
        )}
        <p
          className="typography-h500 text-n-900 truncate"
          ref={(ref) => setIsEllipsis(ref?.offsetWidth < ref?.scrollWidth)}
        >
          {name}
        </p>
      </TooltipContainer>
      <div
        className={`absolute right-[16px] top-[8px] ${
          isMenuOpen ? 'visible' : 'invisible group-hover:visible'
        }`}
        data-cy="card-project-kebab-action-container"
      >
        <MenuOutsideClickWrapper
          customClass={`relative ml-[8px]`}
          event={() => toggleDropdown(false, setDropdownAnimation, setMenuOpen)}
        >
          <TooltipContainer
            show={!isMenuOpen}
            position="bottom"
            text={getObjectiveLocale('More')}
          >
            <div className="flex">
              <div
                className="w-[16px] h-[24px]"
                style={{
                  background:
                    'linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0.64) 100%)'
                }}
              />
              <div
                data-cy="kebab-action-button"
                onClick={(e) => {
                  menuMoreHandler(e, !isMenuOpen);
                }}
                className="h-[24px]"
              >
                <SVGIcon
                  fillColor="var(--n-600)"
                  iconName="icon-keyboard_control"
                  size="24"
                  dataCy="sidebar-detail-kebab"
                  customClass="cursor-pointer border border-solid border-n-400 rounded-[4px] bg-n-000"
                />
              </div>
            </div>
          </TooltipContainer>
          {isMenuOpen && (
            <ListDropdown
              autoClose={() =>
                toggleDropdown(false, setDropdownAnimation, setMenuOpen)
              }
              data={listMenu}
              dropdownAnimation={dropdownAnimation}
            />
          )}
        </MenuOutsideClickWrapper>
      </div>
      <div className="mt-[8px] inline-flex gap-[8px] items-center">
        {listLabel.map((label, index) => (
          <ProjectLabel label={label} key={index} />
        ))}
      </div>
      <div className="mt-[16px] flex items-center justify-between">
        <div className="w-[calc(100%-84px)]">
          {leader && <SingleUser userData={leader} customClass="w-full" />}
        </div>
        {involvements?.length > 1 && (
          <AvatarGroup avatars={listMembers} size="24" max={3} />
        )}
      </div>
    </div>
  );
};

export default ProjectCard;

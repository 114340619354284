"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useToastAutoClose = ({ removeToast, autoClose, autoCloseTime, newToast }) => {
    const [removing, setRemoving] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        if (removing && removeToast) {
            removeToast(removing);
        }
        setRemoving('');
    }, [removing, removeToast]);
    (0, react_1.useEffect)(() => {
        if (autoClose && newToast && !(newToast === null || newToast === void 0 ? void 0 : newToast.disabledAutoClose)) {
            const id = newToast.id;
            id && setTimeout(() => setRemoving(id), autoCloseTime);
        }
    }, [autoClose, autoCloseTime, newToast]);
};
exports.default = useToastAutoClose;

import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useImmer } from 'use-immer';

import {
  approveApprovalRequests,
  getApprovalAvailability,
  rejectApprovalRequests,
  requestApproval,
  withdrawApproval
} from 'client/ApprovalClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getPeriod } from 'utils/ObjectivesHelper';

import Button from 'components/design-system/Button';
import Accordion from 'components/design-system/accordion/Accordion';
import Tabs from 'components/design-system/tabs/Tabs';
import Objectives from 'components/objectives/Objectives';
import Avatar from 'components/shared/Avatar';
import BannerBox from 'components/shared/BannerBox';
import Modal from 'components/shared/modal/Modal';

import ModalApprove from './ModalApprove';

function ApprovalPanel({
  user,
  mode = 'manager',
  type = 'goal',
  approvalRequest,
  reloadAllRequests,
  clearApprovalRequest = () => null,
  pageObjective,
  fetchDataOnPanel = false,
  useDateFilterConfig = false
}) {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const { refetchObjectives, refetchQueries } = useRefetchQuery();
  const autoExpand = parseInt(query.get('id')) === approvalRequest?.id;
  const { match, url } = useUrl();
  const {
    reload,
    reloadApprovalPanel,
    reloadSidebar,
    reloadApprovalNotifCount
  } = useReload();
  const { config, user: currentUser } = useUser();
  const { permissions, approvalTimeFilterOptions } = config;
  const initialRender = useRef(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    approvalRequest?.analytics?.[0]?.state || ''
  );
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showRequestBanModal, setShowRequestBanModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [selfRequest, setSelfRequest] = useImmer(approvalRequest);
  const [totalObjectives, setTotalObjectives] = useState(
    approvalRequest?.analytics?.[approvalRequest?.analytics?.length]?.count
  );
  const [cannotRequest, setCannotRequest] = useState(
    approvalRequest?.warnings?.individual?.length > 0 ||
      approvalRequest?.warnings?.overall?.length > 0
  );
  const [showIndividualError, setShowIndividualError] = useState(
    approvalRequest?.warnings?.individual?.length > 0
  );
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [triggerTabsModifierRun, setTriggerTabsModifierRun] = useState(false);
  const periodFilter = useDateFilterConfig
    ? getPeriod(approvalTimeFilterOptions)
    : [];

  const pageParams = {
    limit: 15,
    type: ['goal'],
    ...(type === 'goal' && { assigneeId: user.id }),
    ...(type === 'goal' && { reviewsVisibility: 1 }),
    ...(type === 'goal' && { parentNotAssignedTo: user.id }),
    ...(type === 'project' && { involvedUserId: user.id, isProject: true }),
    state:
      currentTab === 'approved'
        ? ['running', 'completed', 'reviewed']
        : [currentTab],
    placementId: user?.placementId,
    ...periodFilter
  };

  const tabsData = cloneDeep(selfRequest?.analytics);

  // Add new tab Approved if mode == manager
  if (mode === 'manager' && !tabsData?.find((tab) => tab.state == 'approved')) {
    tabsData.push({
      state: 'approved',
      count: totalObjectives
    });
  }

  const inactiveApproval = selfRequest?.approvalStatus === 'inactive';
  const [reloadList, setReloadList] = useState(false);

  const expandPanel = async () => {
    setDataLoaded(true);
    setIsExpanded(!isExpanded);
  };

  const handleEmployeeAction = async (e) => {
    e.stopPropagation();
    showWithdrawModal && setShowWithdrawModal(false);

    if (cannotRequest && selfRequest?.permissions?.includes('request')) {
      setShowRequestBanModal(true);
      return;
    }

    setIsButtonLoading(true);
    let employeeAction = selfRequest?.permissions?.includes('request')
      ? requestApproval
      : withdrawApproval;

    const { data } = await employeeAction({ placementId: user?.placementId });
    if (data) {
      setIsButtonLoading(false);
      setSelfRequest((draft) => {
        draft.permissions = data.permissions;
        draft.approvalStatus = data.approvalStatus;
      });
    }
    pageObjective && (await refetchObjectives(pageObjective));
    reload({
      reloadApprovalPanel: ['draft', 'edited', 'to_be_deleted'],
      ...(match && { reloadSidebar: !reloadSidebar })
    });
  };

  const handleManagerAction = async (isReject, body) => {
    let managerAction = isReject
      ? rejectApprovalRequests
      : approveApprovalRequests;
    let params = body;
    query.get('placement') &&
      (params.placementManagerId = parseInt(query.get('placement')));
    const data = await managerAction(params);
    if (data?.isSuccess) {
      reloadAllRequests();
      reload({
        reloadApprovalNotifCount: !reloadApprovalNotifCount
      });
    }
  };

  const _getApprovalAvailability = (_) => {
    const params = {
      ...(type === 'project' && { is_project: true }),
      approverId: user?.id,
      placementId: user?.placementId,
      approverPlacementId: currentUser?.id
    };
    return getApprovalAvailability(params);
  };

  let queryKey = ['approvalAvailability', pageObjective, currentUser?.id];
  // CREATE QUERY KEY
  let { data: requestData } = useQuery(queryKey, _getApprovalAvailability, {
    enabled: mode !== 'manager'
  });

  const data = requestData?.data;

  useEffect(() => {
    if (!data) return;
    if (data?.analytics?.length === 0) {
      clearApprovalRequest();
    } else {
      const tabChange =
        data?.analytics?.filter(({ state }) => state === currentTab).length ===
        0;
      if (tabChange) {
        setCurrentTab(data.analytics[0].state);
        setTriggerTabsModifierRun(!triggerTabsModifierRun);
      }
    }

    setCannotRequest(
      data?.warnings?.individual?.length > 0 ||
        data?.warnings?.overall?.length > 0
    );
    setShowIndividualError(data?.warnings?.individual?.length > 0);
    setSelfRequest((draft) => data);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (mode !== 'manager') {
        refetchQueries(queryKey);
        reloadApprovalPanel.includes(currentTab) && setReloadList(!reloadList);
      }
    }
  }, [reloadApprovalPanel]);

  useEffect(() => {
    fetchDataOnPanel && refetchQueries(queryKey);
  }, [fetchDataOnPanel]);

  useEffect(() => {
    !isEqual(approvalRequest, selfRequest) &&
      setSelfRequest((draft) => approvalRequest);
    setDataLoaded(false);
    setIsExpanded(false);
    autoExpand && expandPanel();
  }, [approvalRequest]);

  return (
    <>
      {selfRequest?.analytics?.length > 0 && (
        <>
          <Accordion
            onChange={() => expandPanel()}
            useBorder
            customClass="mt-[16px]"
          >
            <Accordion.Header
              customSpaceClass="py-[16px] px-[16px]"
              dataCyChevronButton="approval-right-chevron"
              useButtonSeparator
            >
              <div className="approval-panel-wrapper bg-n-000 w-100 mb-[0px]">
                <div className="panel-header" data-cy="approval-panel-header">
                  <div className="panel-left">
                    {mode === 'manager' && (
                      <Avatar
                        src={user.profilePicture}
                        name={user.name}
                        size={36}
                        dataCy="image-initial-name"
                        wrapperClassName="mr-[8px]"
                      />
                    )}
                    <div className="flex flex-col">
                      <span
                        className="title mr-[8px]"
                        data-cy="approval-panel-title"
                      >
                        {mode === 'manager'
                          ? user.name
                          : getObjectiveLocale('Approval')}
                      </span>
                      <span
                        className="status"
                        data-cy="approval-panel-subtitle"
                        style={
                          inactiveApproval && mode !== 'manager'
                            ? { display: 'none' }
                            : {}
                        }
                      >
                        {mode === 'manager'
                          ? user.jobTitle
                          : getObjectiveLocale(selfRequest?.approvalStatus)}
                      </span>
                    </div>
                  </div>
                  <div className={`panel-right ${mode}`}>
                    <span className="count" data-cy="approval-requests-count">
                      {selfRequest?.weight > 0 &&
                        url?.includes('approval') &&
                        getObjectiveLocale(
                          `${selfRequest?.weight}% total weight  |  `
                        )}
                      {selfRequest?.analytics &&
                        selfRequest?.analytics.map((value, index) => {
                          let label = value.state.split('_').join(' ');
                          let countSeparator = '';
                          if (index > 0) {
                            countSeparator = '  ·  ';
                          }
                          return `${countSeparator}${
                            value.count
                          } ${getObjectiveLocale(label)}${
                            value.count > 1 ? '(s)' : ''
                          }`;
                        })}
                    </span>
                    {mode === 'manager' ? (
                      <>
                        {selfRequest?.permissions?.includes('reject') && (
                          <Button.Secondary
                            onClick={() => setShowRejectModal(!showRejectModal)}
                            datacy="approval-panel-reject"
                            customClass="mr-[12px]"
                          >
                            {getObjectiveLocale('Reject')}
                          </Button.Secondary>
                        )}
                        {selfRequest?.permissions?.includes('approve') && (
                          <Button
                            onClick={() =>
                              setShowApproveModal(!showApproveModal)
                            }
                            datacy="approval-panel-approve"
                          >
                            {getObjectiveLocale('Approve')}
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        {type === 'goal' &&
                          (selfRequest?.permissions?.includes('request') ? (
                            <Button.Secondary
                              onClick={handleEmployeeAction}
                              datacy="approval-request-button"
                              isLoading={isButtonLoading}
                            >
                              {cannotRequest && (
                                <i className="fa fa-exclamation-triangle" />
                              )}
                              {getObjectiveLocale('Request Approval')}
                            </Button.Secondary>
                          ) : (
                            selfRequest?.permissions?.includes('withdraw') && (
                              <Button.Secondary
                                onClick={() => setShowWithdrawModal(true)}
                                datacy="approval-withdraw-button"
                              >
                                <i className="fa fa-times-circle" />
                                {getObjectiveLocale('Withdraw Approval')}
                              </Button.Secondary>
                            )
                          ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Content customSpaceClass="px-[24px]">
              {dataLoaded && (
                <>
                  <div className="mr-[-24px] ml-[-24px]">
                    <Tabs type="small" currentTab={currentTab}>
                      <Tabs.Buttons fullWidth customClass="px-[24px]">
                        {tabsData?.map((value, index) => {
                          const label = value.state
                            .split('_')
                            .map(
                              (subStr) =>
                                subStr[0].toUpperCase() + subStr.slice(1)
                            )
                            .join(' ');
                          return (
                            <Tabs.Button
                              key={index}
                              onClick={() => setCurrentTab(value.state)}
                              badge={value.count}
                              id={value.state}
                              dataCy={`tab-${index}`}
                            >
                              {getObjectiveLocale(label)}
                            </Tabs.Button>
                          );
                        })}
                      </Tabs.Buttons>
                      <Tabs.Contents>
                        <Tabs.Content>
                          <div
                            className={`panel-content list-goals max-h-[393px] overflow-auto py-[24px] ${
                              isExpanded ? '' : 'hidden'
                            }`}
                            data-cy="approval-panel-content"
                          >
                            {!permissions.approvalRequestProgressUpdate && (
                              <BannerBox
                                text={getObjectiveLocale(
                                  "You can't update goal progress while on this status"
                                )}
                                type="warning"
                                customClass="mb-[8px]"
                                dataCy="approval-warning-card"
                              />
                            )}
                            {type === 'goal' &&
                              showIndividualError &&
                              selfRequest?.warnings?.individual.map(
                                (warning, index) => {
                                  const lastIndex =
                                    selfRequest?.warnings?.individual?.length -
                                      1 ===
                                    index;
                                  return (
                                    <BannerBox
                                      text={getObjectiveLocale(warning)}
                                      type="error"
                                      key={index}
                                      customClass={
                                        lastIndex ? 'mb-[16px]' : 'mb-[8px]'
                                      }
                                      dataCy="approval-error-card"
                                    />
                                  );
                                }
                              )}
                            <div className="pl-[28px] pr-[24px] pb-[14px]">
                              <Objectives
                                withMarginTop
                                filter={pageParams}
                                grid={type !== 'goal'}
                                cardModel={'goal-list'}
                                page={`approvalPanel+${currentTab}${
                                  user && '+' + user.id
                                }`}
                                refetchKey={reloadList}
                                isRefetch={true}
                                isApproval
                              />
                            </div>
                          </div>
                        </Tabs.Content>
                      </Tabs.Contents>
                    </Tabs>
                  </div>
                </>
              )}
            </Accordion.Content>
          </Accordion>

          {showApproveModal && (
            <ModalApprove
              handleChange={setShowApproveModal}
              handleSubmit={handleManagerAction}
              approvalRequests={[selfRequest]}
            />
          )}
          {showRejectModal && (
            <ModalApprove
              isReject={true}
              handleChange={setShowRejectModal}
              handleSubmit={handleManagerAction}
              approvalRequests={[selfRequest]}
            />
          )}
          {showRequestBanModal && (
            <div
              className="modal-approval request-ban"
              data-cy="approval-request-ban-modal"
            >
              <Modal
                headerIcon={{ name: 'icon-warning', color: 'var(--r-600)' }}
                withCloseIcon={false}
                title={getObjectiveLocale("Can't request approval")}
                className="approval-tab-content modal-approval request-ban"
                dataCyModal="approval-request-ban-modal"
                withSecondaryBtn={{
                  title: getObjectiveLocale('Cancel'),
                  dataCy: 'approval-request-ban-cancel',
                  onClick: () => setShowRequestBanModal(false)
                }}
                withFooter={true}
              >
                <div className="body-approval">
                  <span>
                    {getObjectiveLocale(
                      'You have to fix these status before you can request approval:'
                    )}
                  </span>
                  {selfRequest?.warnings?.overall?.length > 0 &&
                    selfRequest?.warnings?.overall?.map((warning, index) => (
                      <div
                        className="notice-card error"
                        data-cy="approval-error-card"
                        key={index}
                      >
                        <i className="fa fa-exclamation-triangle" />
                        <span className="typography-paragraph">
                          {getObjectiveLocale(warning)}
                        </span>
                      </div>
                    ))}
                  {selfRequest?.warnings?.individual?.length > 0 &&
                    selfRequest?.warnings?.individual?.map((warning, index) => (
                      <div
                        className="notice-card error"
                        data-cy="approval-error-card"
                        key={index}
                      >
                        <i className="fa fa-exclamation-triangle" />
                        <span className="typography-paragraph">
                          {getObjectiveLocale(warning)}
                        </span>
                      </div>
                    ))}
                </div>
              </Modal>
            </div>
          )}
          {showWithdrawModal && (
            <div
              className="modal-approval withdraw"
              data-cy="approval-withdrawal-modal"
            >
              <Modal
                withCloseIcon={false}
                title={getObjectiveLocale('Withdraw approval request?')}
                dataCyModal="approval-withdrawal-modal"
                className="approval-tab-content modal-approval withdraw"
                withPrimaryBtn={{
                  title: `Yes, ${getObjectiveLocale('Withdraw')}`,
                  dataCy: 'approval-withdrawal-action',
                  onClick: handleEmployeeAction
                }}
                withSecondaryBtn={{
                  title: 'No',
                  dataCy: 'approval-withdrawal-cancel',
                  onClick: () => setShowWithdrawModal(false)
                }}
                withFooter={true}
              >
                <div className="body-approval">
                  <span>
                    {getObjectiveLocale(
                      'Are you sure you want to withdraw your appoval request?'
                    ) +
                      ' ' +
                      getObjectiveLocale('Your status right now:')}
                  </span>
                  <div className="notice-card info">
                    <img src={Config.asset.createObjective.iconInfoGrey} />
                    <span>
                      {getObjectiveLocale(selfRequest?.approvalStatus)}
                    </span>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ApprovalPanel;

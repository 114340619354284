import React, { useState } from 'react';

import Label from 'components/shared/Label/Label';

import Star from './Star';

function Rating({
  options,
  selectedOptionId,
  onSelectRating,
  customIcon,
  showLabel
}) {
  const [hoverAt, setHoverAt] = useState(null);
  const selectedId = hoverAt ? hoverAt : selectedOptionId;
  const sortedOptions = options.sort((a, b) => (a.score > b.score ? 1 : -1));
  const selectedIndex = sortedOptions.findIndex(
    (option) => option.id == selectedId
  );

  const selectionValid = selectedIndex && selectedIndex === -1;
  const textStyle = selectionValid
    ? {}
    : { color: '#' + sortedOptions[selectedIndex].colorHex };
  const text = selectionValid ? '' : sortedOptions[selectedIndex].name;

  return (
    <div className="option-container">
      {!customIcon && showLabel != 'score_only' && (
        <Label className="radio-image-text" style={textStyle}>
          {text}
        </Label>
      )}
      <div className="scoring-icon">
        {sortedOptions.map((option, index) => {
          const data = {
            id: option.id,
            askForStrength: option.askForStrength,
            askForWeakness: option.askForWeakness,
            requireForStrength: option.requireForStrength,
            requireForWeakness: option.requireForWeakness
          };
          return (
            <Star
              onMouseOver={() => setHoverAt(option.id)}
              onMouseOut={() => setHoverAt(null)}
              key={option.id}
              withLabel={true}
              selected={
                customIcon ? index == selectedIndex : index <= selectedIndex
              }
              isSelected={index == selectedIndex}
              onClick={() => onSelectRating(data)}
              optionId={option.id}
              text={option.name}
              color={option.colorHex}
              customIcon={customIcon}
              iconUrl={option.iconUrl}
              showLabel={showLabel}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Rating;

import { trackEvent } from './AnalyticUtils';

async function linkToCreateObjectives(
  e,
  history,
  checkValidationCreateGoal,
  customMethod
) {
  e?.stopPropagation?.();
  const { isSettingMaxnObjectivesOn } = await checkValidationCreateGoal({
    nextUrl: () => {
      trackEvent({
        event: 'create goal'
      });
      customMethod
        ? customMethod()
        : history.replace({ pathname: '/create-objective/goal' });
    }
  });

  if (!isSettingMaxnObjectivesOn) {
    trackEvent({
      event: 'create goal'
    });
    customMethod
      ? customMethod()
      : history.replace({
          pathname: '/create-objective/goal'
        });
  }
}

export { linkToCreateObjectives };

import React, { useEffect, useRef, useState } from 'react';

import { useSidebarMenu } from 'context/SidebarMenuContext';
import useTasks from 'hooks/useTasks';

import Accordion, {
  useAccordion
} from 'components/design-system/accordion/Accordion';
import AddSection from 'components/project/detail/AddSection';
import InputSectionName from 'components/project/detail/InputSectionName';
import SectionOptions from 'components/project/detail/SectionOptions';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';
import Tasks from 'components/tasks/Tasks';
import useIsFetchingTasks from 'src/hooks/useIsFetchingTasks';

const HeaderUseContext = ({
  filter,
  groupData,
  sectionData,
  setAutoShowPlaceholder,
  isTopTaskOpen,
  setIsTopTaskOpen,
  isProject,
  projectId,
  setIsAdding,
  setAddAbove,
  setCurrentSectionIndex,
  setShowModalDeleteSection,
  headerRef
}) => {
  const isFetchingTasks = useIsFetchingTasks({
    isProject,
    groupName: groupData?.name,
    sectionName: sectionData?.name
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [showAction, setShowAction] = useState(false);

  const { isAccordionOpen, setIsOpen, isOpen } = useAccordion();
  const { isMinimized } = useSidebarMenu();

  const groupBy = filter?.group;

  const { addNewTaskOneLevel, addNewTaskTwoLevels, setAction, listGroups } =
    useTasks();

  const { section } = listGroups;

  const handleAddTask = () => {
    if (isFetchingTasks) return;

    if (isOpen) {
      if (isProject) {
        addNewTaskTwoLevels(sectionData?.name, groupData?.name, 'top');
      } else {
        addNewTaskOneLevel(groupData?.name, 'top');
      }
    } else {
      setIsOpen(true);
      setAutoShowPlaceholder(true);
    }

    setAction('create');
  };

  useEffect(() => {
    setIsOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBy]);

  useEffect(() => {
    if (!isProject || (sectionData?.index !== 0 && sectionData?.index !== -1))
      return;
    isTopTaskOpen && !isOpen && setIsOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTopTaskOpen]);

  useEffect(() => {
    if (!isProject || (sectionData?.index !== 0 && sectionData?.index !== -1))
      return;
    isTopTaskOpen !== isOpen && setIsTopTaskOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    dialogOpen && setCurrentSectionIndex(sectionData?.index);
  }, [dialogOpen, setCurrentSectionIndex, sectionData?.index]);

  return (
    <div
      className="flex items-center w-full group"
      data-cy={`accordion-task-${
        isProject ? sectionData?.index : groupData?.index
      }`}
      onMouseEnter={() => !isRenaming && setShowAction(true)}
      onMouseLeave={() => !isRenaming && setShowAction(false)}
    >
      <div ref={headerRef} />
      <div
        className="flex items-center sticky left-[0px] min-w-[200px] z-[99]"
        style={{
          maxWidth: `calc(100vw - ${
            isMinimized
              ? isRenaming
                ? '174px'
                : showAction || dialogOpen
                ? '252px'
                : '177px'
              : isRenaming
              ? '369px'
              : showAction || dialogOpen
              ? '440px'
              : '370px'
          })`
        }}
      >
        <SVGIcon
          size="24"
          iconName={
            isAccordionOpen
              ? 'icon-keyboard_arrow_down'
              : 'icon-keyboard_arrow_right'
          }
          fillColor="var(--n-600)"
          customClass="cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        />
        {isRenaming ? (
          <InputSectionName
            sectionIndex={sectionData?.index}
            projectId={projectId}
            isShowing={isRenaming}
            setIsShowing={setIsRenaming}
            isCreateNew={false}
          />
        ) : (
          <TruncateParagraph
            className="typography-h400 text-n-900 uppercase ml-[16px]"
            dataCy="accordion-task-text"
          >
            {isProject ? sectionData?.name : groupData?.name}
          </TruncateParagraph>
        )}
        {!isRenaming && (
          <div
            className={`flex items-center group-hover:flex
            ${!isProject || !dialogOpen ? 'hidden' : ''}`}
          >
            <SVGIcon
              size="24"
              iconName="icon-add"
              fillColor="var(--n-600)"
              customClass="mx-[16px] group-hover:flex"
              onClick={handleAddTask}
            />
            {isProject && (
              <SectionOptions
                projectId={projectId}
                sectionIndex={sectionData?.index}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                setIsRenaming={setIsRenaming}
                setIsAdding={setIsAdding}
                setAddAbove={setAddAbove}
                setShowModalDeleteSection={setShowModalDeleteSection}
                isCanMove={section?.length > 1}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const AccordionTask = React.memo(
  ({
    filter,
    group,
    groupData,
    sectionData,
    draggedId,
    setDraggedId,
    currentGroup,
    autoShowPlaceholder,
    setAutoShowPlaceholder,
    isTopTaskOpen,
    setIsTopTaskOpen,
    isProject,
    projectId,
    setCurrentSectionIndex,
    setShowModalDeleteSection,
    listMetrics = []
  }) => {
    const [isAdding, setIsAdding] = useState(false);
    const [addAbove, setAddAbove] = useState(false);

    const headerRef = useRef(null);

    return (
      <>
        {isProject && isAdding && addAbove && (
          <AddSection
            sectionIndex={sectionData?.index}
            projectId={projectId}
            isShowing={isAdding}
            setIsShowing={setIsAdding}
            addAbove={addAbove}
            customClass="left-[0px] mb-[40px]"
          />
        )}
        <Accordion useBgColor={false} customClass="mb-[40px] min-w-full w-max">
          <Accordion.Header
            useCustomHeader
            customWrapperClass="mb-[16px] flex flex-grow"
            useWrapperClick={false}
          >
            <HeaderUseContext
              group={group}
              groupData={isProject ? currentGroup[0] : groupData}
              sectionData={isProject ? sectionData : null}
              currentGroup={currentGroup}
              filter={filter}
              setAutoShowPlaceholder={setAutoShowPlaceholder}
              isTopTaskOpen={isTopTaskOpen}
              setIsTopTaskOpen={setIsTopTaskOpen}
              isProject={isProject}
              projectId={projectId}
              setIsAdding={setIsAdding}
              setAddAbove={setAddAbove}
              setCurrentSectionIndex={setCurrentSectionIndex}
              setShowModalDeleteSection={setShowModalDeleteSection}
              headerRef={headerRef}
            />
          </Accordion.Header>
          <Accordion.Content
            customSpaceClass="px-[0px] py-[0px]"
            useAnimateOnOpen
          >
            {isProject ? (
              currentGroup?.length > 0 &&
              currentGroup?.map(({ name, id, colorHex, bgColorHex }, index) => (
                <Tasks
                  key={index}
                  groupData={{
                    id: id,
                    name: name?.toLowerCase(),
                    index: index,
                    colorHex: colorHex,
                    bgColorHex: bgColorHex
                  }}
                  filter={filter}
                  sectionData={sectionData}
                  draggedId={draggedId}
                  setDraggedId={setDraggedId}
                  autoShowPlaceholder={autoShowPlaceholder}
                  setAutoShowPlaceholder={setAutoShowPlaceholder}
                  isProject={isProject}
                  headerRef={headerRef}
                  listMetrics={listMetrics}
                  customClass={index === 0 ? '' : 'mt-[16px]'}
                />
              ))
            ) : (
              <Tasks
                groupData={groupData}
                filter={filter}
                draggedId={draggedId}
                setDraggedId={setDraggedId}
                autoShowPlaceholder={autoShowPlaceholder}
                setAutoShowPlaceholder={setAutoShowPlaceholder}
                isProject={isProject}
                headerRef={headerRef}
                listMetrics={listMetrics}
              />
            )}
          </Accordion.Content>
        </Accordion>
        {isProject && isAdding && !addAbove && (
          <AddSection
            sectionIndex={sectionData?.index}
            projectId={projectId}
            isShowing={isAdding}
            setIsShowing={setIsAdding}
            addAbove={addAbove}
            customClass={`left-[0px] ${sectionData?.isLast ? '' : 'mb-[40px]'}`}
          />
        )}
      </>
    );
  }
);

AccordionTask.displayName = 'AccordionTask';

export default AccordionTask;

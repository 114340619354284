import React, { useEffect } from 'react';
import ImgsViewer from 'react-images-viewer';

import './ModalImage.scss';

const ModalImage = ({ imgs = [], isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const images = document.querySelectorAll('#viewerBackdrop figure img');
        images.forEach((image) => {
          image.setAttribute(
            'crossorigin',
            process.env.NODE_ENV != 'development' ? 'anonymous' : null
          );
        });
      }, 0);
    }
  }, [isOpen]);

  return (
    <ImgsViewer
      imgs={imgs}
      isOpen={isOpen}
      onClose={onClose}
      backdropCloseable
      showImgCount={false}
    />
  );
};

export default ModalImage;

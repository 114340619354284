import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import {
  manageAssignment,
  manageBulkAssignment
} from 'client/FormalReviewClient';
import { getAssignmentsCycles } from 'client/admin/FormalReviewRevieweeClient';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';

import AssignmentItem from './AssigmentItem';
import ModalDeleteAssignment from './ModalDeleteAssignment';

const AssigmentContent = ({ inclusion }) => {
  const history = useHistory();
  const [modalType, setModalType] = useState('');
  const [modalDeleteProps, setModalDeleteProps] = useState(null);
  const [reviewerList, setReviewerList] = useState(null);
  const [isAllDeleted, setAllDeleted] = useState(false);
  const { id: userId } = useParams();

  const dateFormatter = (date) => dayjs(date).format('D MMM YYYY');

  const handleDeleteAssignment = async ({ reviewer, state, callback }) => {
    const targetId = reviewer?.target?.id;
    const targetPlacementId = reviewer?.targetPlacement?.id;
    const actorId = reviewer?.actor?.id;
    const actorPlacementId = reviewer?.actorPlacement?.id;
    const payload = {
      phaseId: reviewer?.formalReviewPhase?.id,
      assignments: [
        {
          targetPlacementId,
          actorPlacementId,
          targetId,
          actorId,
          state: state == 'delete' ? 'deleted' : 'undelete'
        }
      ]
    };
    const { isSuccess } = await manageAssignment(payload);
    if (isSuccess) {
      getData();
      callback && callback();
    }
  };

  const closeDeleteModal = () => {
    setModalType('');
    setModalDeleteProps(null);
  };

  const handleDeleteAllAssignment = async (state) => {
    const payload = reviewerList?.map((data) => ({
      phaseId: data?.formalReviewPhase?.id,
      actorId: data?.actor?.id,
      targetId: data?.target?.id,
      actorPlacementId: data?.actorPlacement?.id,
      targetPlacementId: data?.targetPlacement?.id,
      state: state == 'deleteAllAssignment' ? 'deleted' : 'undelete'
    }));
    const { isSuccess } = await manageBulkAssignment(payload);
    isSuccess && getData();
    closeDeleteModal();
  };

  const getData = async () => {
    const params = {
      state: ['done', 'in_progress', 'incomplete', 'deleted'],
      target_id: userId,
      target_placement_id: inclusion?.revieweePlacement?.id
    };
    const id = inclusion?.cycle?.id;
    const { data } = await getAssignmentsCycles(id, params);
    setReviewerList(data || null);
    setAllDeleted(
      data?.length == data?.filter((list) => list.state == 'deleted').length ||
        false
    );
  };

  const openDeleteAllModal = () => {
    let state = 'deleteAllAssignment';
    if (isAllDeleted) {
      state = 'undeleteAllAssignment';
    }
    setModalType(state);
    setModalDeleteProps({
      type: state,
      _handleNext: () => handleDeleteAllAssignment(state),
      _handleActionModal: closeDeleteModal,
      assigmentTitle: inclusion?.revieweePlacement?.name,
      cycle: inclusion?.cycle
    });
  };

  const openCycleDetail = () =>
    history.push(`/cycles/detail-cycle/${inclusion?.cycle?.id}`, {
      back: true
    });

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="w-full bg-n-000 rounded-[4px]">
        <div className="flex flex-row items-center justify-between py-[8px] border-b-1 px-[24px]">
          <div className="flex flex-col">
            <p
              className="typography-h400 text-n-800 pb-[4px]"
              data-cy={`inclusion-cycle-name-${inclusion?.id}`}
            >
              {inclusion?.cycle?.name}
            </p>
            <p className="typography-button text-n-600">
              {dateFormatter(inclusion?.cycle?.reviewedPeriodStartsAt)} -
              {inclusion?.cycle?.reviewedPeriodEndsAt
                ? dateFormatter(inclusion.cycle.reviewedPeriodEndsAt)
                : 'Now'}
            </p>
          </div>
          <Button
            variant="secondary"
            customClass="max-h-[32px]"
            datacy={`btn-open-detail-${inclusion?.id}`}
            onClick={openCycleDetail}
          >
            {getObjectiveLocale('Open Cycle Details')}
          </Button>
        </div>
        {reviewerList?.length > 0 && (
          <>
            <div className="flex flex-row justify-between items-center py-[10px] border-solid border-n-300 border-t border-b border-r-0 border-l-0 px-[24px]">
              <p className="typography-h300 text-n-800">
                {getObjectiveLocale('phase & reviewer')}
              </p>
              <div className="flex flex-row justify-between items-center">
                <p className="typography-h300 text-n-800 min-w-[200px]">
                  {getObjectiveLocale('Assignment dependency')}
                </p>
                <p className="typography-h300 text-n-800 min-w-[142px]">
                  {getObjectiveLocale('status')}
                </p>
                <p
                  className="typography-paragraph text-n-600 text-right hover:cursor-pointer min-w-[82px]"
                  data-cy={`delete-all-${inclusion?.id}`}
                  onClick={openDeleteAllModal}
                >
                  {getObjectiveLocale(
                    isAllDeleted ? 'Undelete All' : 'Delete All'
                  )}
                </p>
              </div>
            </div>
            <div className="pb-[10px]">
              {reviewerList?.map((reviewer) => (
                <AssignmentItem
                  key={reviewer?.id}
                  reviewer={reviewer}
                  assignmentId={reviewer.id}
                  refetch={getData}
                  handleDeleteAssignment={handleDeleteAssignment}
                  setModalDeleteProps={setModalDeleteProps}
                  setModalType={setModalType}
                  closeDeleteModal={closeDeleteModal}
                  cycleDetail={inclusion?.cycle}
                />
              ))}
            </div>
          </>
        )}
      </div>

      {modalType && modalDeleteProps && (
        <ModalDeleteAssignment {...modalDeleteProps} />
      )}
    </>
  );
};

export default AssigmentContent;

import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { getListBehavior } from 'client/PrivateRecognitionClient';
import { getGroupCMSCulture, getLabelCMSCulture } from 'client/adminClient.js';
import { getCultureLocale, setDropdownOptions } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import BannerBox from 'components/shared/BannerBox';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';

function SetupTask({
  tasks,
  onChange,
  onClick,
  haveSubmitted,
  isActive,
  setPage,
  setIndex,
  onDelete,
  isUsingSequence,
  dragTask,
  templateData
}) {
  const [listLabel, setLabel] = useState([]);
  const [listGroup, setGroup] = useState([]);
  const [listValue, setValue] = useState([]);

  const getLabel = async () => {
    let params = {
      status: ['Active'],
      admin: 1
    };
    const { data } = await getLabelCMSCulture(params);
    if (data) {
      setLabel(data);
    }
  };

  const getValue = async (getStatus) => {
    const { data, error, pagination } = await getListBehavior({
      behaviorType: 'value'
    });
    if (data) {
      const valueData = [{ id: -2, title: 'Any Value' }, ...data];
      setValue(valueData);
    }
  };

  const getGroup = async () => {
    const { data } = await getGroupCMSCulture();
    if (data) {
      const groupData = [{ id: -2, name: 'Any Group' }, ...data];
      setGroup(groupData);
    }
  };

  const chooseTemplateValue = (value, property, index) => {
    onChange(value.id, property, index);
  };

  const addQuestion = () => {
    setPage('createQuestion');
  };

  const reorder = (startIndex, endIndex) => {
    const result = [...tasks];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const _onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedArray = reorder(
      result.source.index,
      result.destination.index
    );
    dragTask(reorderedArray);
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    let style = { userSelect: 'none', ...draggableStyle };

    if (isDragging) {
      style.background = 'rgba(233, 199, 249, 0.16)';
      style.boxShadow = '0 6px 12px 0 rgba(50, 50, 50, 0.2)';
    }

    return style;
  };

  const _setDropdownOptions = (type, index) => {
    let dataList, onClickKey, textProp;
    switch (type) {
      case 'taskType':
        dataList = templateData;
        onClickKey = 'missionTaskTemplateId';
        textProp = 'name';
        break;
      case 'group':
        dataList = listGroup;
        onClickKey = 'groupId';
        textProp = 'name';
        break;
      case 'value':
        dataList = listValue;
        onClickKey = 'valueId';
        textProp = 'title';
        break;
      case 'label':
        dataList = listLabel;
        onClickKey = 'labelId';
        textProp = 'title';
        break;
    }
    const onClickFunction = (onClickData) =>
      type === 'taskType'
        ? chooseTemplateValue(onClickData, onClickKey, index)
        : onChange(onClickData, onClickKey, index);
    let options = setDropdownOptions(
      dataList,
      textProp,
      onClickFunction,
      '',
      ''
    );
    return options;
  };

  let sequenceView = {
    gridTemplateAreas: `"order type name name"
    "order points actions actions"
    "order target value value"
    "order description description description"
    "order question question question"`,
    gridTemplateColumns: '10% 30% 30% 30%'
  };

  let withoutSequenceView = {
    gridTemplateAreas: `"type name name"
    "points actions actions"
    "target value value"
    "description description description"
    "question question question"`,
    gridTemplateColumns: '30% 30% 40%'
  };

  useEffect(() => {
    getGroup();
    getLabel();
    getValue();
  }, []);

  return (
    <div>
      <DragDropContext onDragEnd={_onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className=".body-table-dnd setup-tasks-container bg-n-200 mb-[16px]"
              id="table-dnd"
              style={{
                height: snapshot.isDraggingOver
                  ? document.getElementById('table-dnd')?.offsetHeight
                  : '100%'
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {tasks?.map((value, index) => {
                let valueName =
                  templateData?.length > 0
                    ? templateData?.find(
                        (item) => item.id == value.missionTaskTemplateId
                      )?.name
                    : '';
                let valueEventType =
                  templateData.length > 0
                    ? templateData.find(
                        (item) => item.id == value.missionTaskTemplateId
                      )?.eventType
                    : '';
                return (
                  <Draggable
                    isDragDisabled={!isUsingSequence}
                    draggableId={String(value?.idDrag)}
                    index={index}
                    key={value?.idDrag}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div className="border-bottom-400 flex justify-between items-center py-[16px]">
                          <p className="typography-h500 ml-[24px]">
                            Task {index + 1}
                          </p>
                          <Button.Tertiary
                            datacy="delete-task"
                            onClick={() => onDelete(index)}
                            customClass="mr-[24px]"
                          >
                            <SVGIcon
                              size="24"
                              fillColor="var(--base-600)"
                              iconName={'icon-delete'}
                              customClass="mr-[4px]"
                            />
                            {getCultureLocale('Delete')}
                          </Button.Tertiary>
                        </div>

                        <div
                          className={`task-area grid task-${
                            index + 1
                          } pt-[24px] pl-[24px]`}
                          key={index}
                          style={
                            isUsingSequence ? sequenceView : withoutSequenceView
                          }
                        >
                          {isUsingSequence && (
                            <div
                              className="pr-[24px] pb-[24px]"
                              style={{ gridArea: 'order' }}
                            >
                              <p className="typography-h100 typography-secondary mb-[12px]">
                                {getCultureLocale('Order')}
                              </p>
                              <p className="flex items-center">
                                <SVGIcon
                                  size="24"
                                  fillColor="var(--n-600)"
                                  iconName={'icon-drag_indicator'}
                                  customClass="mr-[24px]"
                                />
                                {index + 1}
                              </p>
                            </div>
                          )}
                          <div
                            className="form-group relative pr-[24px] pb-[24px]"
                            style={{ gridArea: 'type' }}
                          >
                            <p className="typography-h100 typography-secondary mb-[8px]">
                              {getCultureLocale('Type')}
                            </p>
                            <Dropdown customClass="w-full">
                              <Dropdown.Trigger
                                disabled={isActive}
                                dataCy="task-mission-template"
                              >
                                <Trigger
                                  customWrapperClass="w-full"
                                  topLabel={false}
                                  label={false}
                                  icon={false}
                                  text={valueName}
                                  iconName="icon-arrow_drop_down"
                                />
                              </Dropdown.Trigger>
                              <Dropdown.MenuItems
                                options={_setDropdownOptions('taskType', index)}
                                customClass="w-full"
                                dataCy="task-mission-template"
                              />
                            </Dropdown>
                          </div>
                          <div
                            className="form-group pr-[24px] pb-[24px]"
                            style={{ gridArea: 'name' }}
                          >
                            <p className="typography-h100 typography-secondary mb-[8px]">
                              {getCultureLocale('Name')}
                            </p>
                            <InputField
                              dataCy="task-mission-name"
                              value={value.name}
                              disabled={isActive}
                              handleChange={(e) => onChange(e, 'name', index)}
                            />
                            {haveSubmitted && !value.name && (
                              <p
                                className="error-message typography-h100 mt-[8px]"
                                data-cy={`error-task-name-${index}`}
                              >
                                {getCultureLocale('This cannot be blank.')}
                              </p>
                            )}
                          </div>
                          {valueEventType?.toLowerCase() !== 'quiz.answer' && (
                            <>
                              <div
                                className="form-group pr-[24px] pb-[24px]"
                                style={{ gridArea: 'points' }}
                              >
                                <p className="typography-h100 typography-secondary mb-[8px]">
                                  {getCultureLocale('Points')}
                                </p>
                                <InputField
                                  dataCy="task-mission-point"
                                  value={value.point}
                                  disabled={isActive}
                                  handleChange={(e) =>
                                    onChange(e, 'point', index)
                                  }
                                />
                                {haveSubmitted && !value.point && (
                                  <p className="error-message typography-h100 mt-[8px]">
                                    {getCultureLocale('This cannot be blank.')}
                                  </p>
                                )}
                              </div>
                              {(valueEventType?.toLowerCase() == 'post.view' ||
                                valueEventType?.toLowerCase() == 'post.share' ||
                                valueEventType?.toLowerCase() ==
                                  'post.comment') && (
                                <div
                                  className="form-group pr-[24px] pb-[24px]"
                                  style={{ gridArea: 'actions' }}
                                >
                                  <p className="typography-h100 typography-secondary mb-[8px]">
                                    {getCultureLocale('Action URL / ID')}
                                  </p>
                                  <InputField
                                    dataCy="task-mission-targetid"
                                    value={value.targetId}
                                    disabled={isActive}
                                    handleChange={(e) =>
                                      onChange(e, 'targetId', index)
                                    }
                                  />
                                  {haveSubmitted && !value.targetId && (
                                    <p className="error-message typography-h100 mt-[8px]">
                                      {getCultureLocale(
                                        'This cannot be blank.'
                                      )}
                                    </p>
                                  )}
                                </div>
                              )}
                              {(valueEventType?.toLowerCase() ==
                                'post.create' ||
                                valueEventType?.toLowerCase() ==
                                  'post.recognition') && (
                                <div
                                  className="form-group pr-[24px] pb-[24px]"
                                  style={{ gridArea: 'actions' }}
                                >
                                  <p className="typography-h100 typography-secondary mb-[8px]">
                                    {getCultureLocale('Group')}{' '}
                                  </p>
                                  <Dropdown customClass="w-full">
                                    <Dropdown.Trigger disabled={isActive}>
                                      <Trigger
                                        customWrapperClass="w-full"
                                        topLabel={false}
                                        label={false}
                                        icon={false}
                                        iconName="icon-arrow_drop_down"
                                        text={
                                          value?.conditions?.hasOwnProperty(
                                            'groupId'
                                          )
                                            ? listGroup?.find(
                                                ({ id }) =>
                                                  id ===
                                                  value?.conditions?.groupId
                                              )?.name || 'Choose group'
                                            : 'Any Group'
                                        }
                                      />
                                    </Dropdown.Trigger>
                                    <Dropdown.MenuItems
                                      options={_setDropdownOptions(
                                        'group',
                                        index
                                      )}
                                      customClass="w-full"
                                      maxHeight={150}
                                    />
                                  </Dropdown>
                                  {haveSubmitted &&
                                    !value?.conditions?.groupId && (
                                      <p className="error-message typography-h100 mt-[8px]">
                                        {getCultureLocale(
                                          'This cannot be blank.'
                                        )}
                                      </p>
                                    )}
                                </div>
                              )}
                            </>
                          )}
                          {valueEventType?.toLowerCase() == 'quiz.answer' && (
                            <>
                              <div
                                className="form-group pr-[24px] pb-[24px]"
                                style={{ gridArea: 'description' }}
                              >
                                <p className="typography-h100 typography-secondary mb-[8px]">
                                  {getCultureLocale('Description')}
                                </p>
                                <textarea
                                  disabled={isActive}
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    onChange(e, 'description', index)
                                  }
                                  value={value.description}
                                />
                              </div>
                              <div
                                className="pr-[24px] pb-[24px]"
                                style={{ gridArea: 'question' }}
                              >
                                <p className="typography-h100 typography-secondary mb-[8px]">
                                  {getCultureLocale('Question')}
                                </p>
                                <div className="flex items-center">
                                  {value.totalQuestion > 0 && (
                                    <p
                                      className="color-p-600 mr-[16px]"
                                      data-cy="total-question"
                                    >
                                      {value.totalQuestion}{' '}
                                      {getCultureLocale('questions added')}
                                    </p>
                                  )}
                                  <Button.Secondary
                                    datacy="add-question"
                                    onClick={() => {
                                      addQuestion(), setIndex(index);
                                    }}
                                    disabled={isActive}
                                  >
                                    <SVGIcon
                                      size="24"
                                      fillColor="var(--n-600)"
                                      iconName={
                                        value.totalQuestion
                                          ? 'icon-pencil'
                                          : 'icon-add'
                                      }
                                      customClass="mr-[4px]"
                                    />
                                    {value.totalQuestion ? 'Edit' : 'Create'}{' '}
                                    {getCultureLocale('question')}
                                  </Button.Secondary>
                                </div>
                              </div>
                            </>
                          )}
                          {valueEventType?.toLowerCase() == 'post.create' ? (
                            <div
                              className="form-group pr-[24px] pb-[24px]"
                              style={{ gridArea: 'value' }}
                            >
                              <p className="typography-h100 typography-secondary mb-[8px]">
                                {getCultureLocale('Prefilled Hashtag')}
                              </p>
                              <InputField
                                dataCy="task-mission-hashtag"
                                value={value?.conditions?.hashtag}
                                disabled={isActive}
                                handleChange={(e) =>
                                  onChange(e, 'hashtag', index)
                                }
                              />
                              {haveSubmitted &&
                                !value?.conditions?.hashtag.includes('#') && (
                                  <p className="error-message typography-h100 mt-[8px]">
                                    {getCultureLocale('Invalid format.')}
                                  </p>
                                )}
                            </div>
                          ) : (
                            valueEventType?.toLowerCase() == 'post.comment' && (
                              <div className="form-group pr-[24px] pb-[24px]">
                                <p className="typography-h100 typography-secondary mb-[8px]">
                                  {getCultureLocale(
                                    'Minimum Character Required'
                                  )}
                                </p>
                                <InputField
                                  dataCy="task-mission-character"
                                  value={
                                    value?.conditions?.minCharacterRequired
                                  }
                                  disabled={isActive}
                                  handleChange={(e) =>
                                    onChange(e, 'minCharacterRequired', index)
                                  }
                                />
                                {haveSubmitted &&
                                  !value?.conditions?.minCharacterRequired && (
                                    <p className="error-message typography-h100 mt-[8px]">
                                      {getCultureLocale(
                                        'This cannot be blank.'
                                      )}
                                    </p>
                                  )}
                              </div>
                            )
                          )}
                          {(valueEventType?.toLowerCase() == 'post.create' ||
                            valueEventType?.toLowerCase() == 'step.count' ||
                            valueEventType?.toLowerCase() ==
                              'post.recognition') && (
                            <div
                              className="form-group pr-[24px] pb-[24px]"
                              style={{ gridArea: 'target' }}
                            >
                              <p className="typography-h100 typography-secondary mb-[8px]">
                                {valueEventType?.toLowerCase() == 'post.create'
                                  ? 'Prefilled Label'
                                  : valueEventType?.toLowerCase() ==
                                    'post.recognition'
                                  ? 'Total Recognition Required'
                                  : 'Target'}
                              </p>
                              {valueEventType?.toLowerCase() ==
                              'post.create' ? (
                                <Dropdown customClass="w-full">
                                  <Dropdown.Trigger disabled={isActive}>
                                    <Trigger
                                      customWrapperClass="w-full"
                                      topLabel={false}
                                      label={false}
                                      icon={false}
                                      iconName="icon-arrow_drop_down"
                                      text={
                                        listLabel?.find(
                                          ({ id }) =>
                                            id === value?.conditions?.labelId
                                        )?.title ||
                                        getCultureLocale('Choose label')
                                      }
                                    />
                                  </Dropdown.Trigger>
                                  <Dropdown.MenuItems
                                    options={_setDropdownOptions(
                                      'label',
                                      index
                                    )}
                                    customClass="w-full"
                                    maxHeight={150}
                                  />
                                </Dropdown>
                              ) : (
                                <InputField
                                  type="number"
                                  dataCy="task-mission-repetition"
                                  placeholder="1"
                                  value={value.repetition}
                                  handleChange={(e) =>
                                    onChange(e, 'repetition', index)
                                  }
                                  disabled={isActive}
                                />
                              )}
                            </div>
                          )}
                          {valueEventType?.toLowerCase() ==
                            'post.recognition' && (
                            <div
                              className="form-group pr-[24px] pb-[24px]"
                              style={{ gridArea: 'value' }}
                            >
                              <p className="typography-h100 typography-secondary mb-[8px]">
                                {getCultureLocale('Value')}{' '}
                              </p>
                              <Dropdown customClass="w-full">
                                <Dropdown.Trigger disabled={isActive}>
                                  <Trigger
                                    customWrapperClass="w-full"
                                    topLabel={false}
                                    label={false}
                                    icon={false}
                                    iconName="icon-arrow_drop_down"
                                    text={
                                      value?.conditions?.hasOwnProperty(
                                        'valueId'
                                      )
                                        ? listValue?.find(
                                            ({ id }) =>
                                              id === value?.conditions?.valueId
                                          )?.title ||
                                          getCultureLocale('Choose value')
                                        : getCultureLocale('Any Value')
                                    }
                                  />
                                </Dropdown.Trigger>
                                <Dropdown.MenuItems
                                  options={_setDropdownOptions('value', index)}
                                  customClass="w-full"
                                  maxHeight={150}
                                />
                              </Dropdown>
                              {haveSubmitted && !value?.conditions?.valueId && (
                                <p className="error-message typography-h100 mt-[8px]">
                                  {getCultureLocale('This cannot be blank.')}
                                </p>
                              )}
                            </div>
                          )}
                          {value.warning && (
                            <BannerBox type="warning" customClass="mb-[24px]">
                              <p className="typography-paragraph">
                                {getCultureLocale(
                                  'This task has already assigned to some of the selected recipients above.'
                                )}
                              </p>
                            </BannerBox>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="bg-n-200 h-[116px] flex justify-center items-center border-n300 rounded-[4px]">
        <Button.Secondary onClick={onClick} disabled={isActive}>
          <SVGIcon
            size="24"
            fillColor="var(--base-600)"
            iconName="icon-add"
            customClass="mr-[4px]"
          />
          <p>{getCultureLocale('Add New Task')}</p>
        </Button.Secondary>
      </div>
    </div>
  );
}

export default SetupTask;

import React, { useRef, useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import useClickOutside from 'hooks/useClickOutside';
import { useLocale } from 'hooks/useLocale';
import { getDateLocale } from 'utils/HelperUtils';

import SingleCalendar from 'components/shared/Calendar';
import AntdCalendar from 'components/shared/Calendar/AntdCalendar';
import InputField from 'components/shared/InputField';

dayjs.extend(utc);

const TimeField = ({
  selectedDate,
  label,
  onSelectDate,
  customClass,
  isSingle,
  readonly = false,
  disabledDate = false,
  dataCy,
  isBeforeDate = false,
  datePlaceholder,
  inputClassName = 'mt-[24px]',
  calendarButtonType = 'no',
  type = 'startDate',
  allowClear
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const cal = useRef();
  const { setBothLocale } = useLocale();
  useClickOutside(cal, () => setIsCalendarOpen(false));

  const valueInput = isSingle
    ? selectedDate
      ? getDateLocale(selectedDate)
      : datePlaceholder
      ? datePlaceholder
      : 'Choose date'
    : `${
        selectedDate?.start ? getDateLocale(selectedDate?.start) : 'Choose date'
      } - ${
        selectedDate?.end ? getDateLocale(selectedDate?.end) : 'Choose date'
      }`;

  const selectDate = (value) => {
    onSelectDate(value);
    setIsCalendarOpen(false);
  };

  const onDisabledDate = (current) => {
    if (!current) {
      // allow empty select
      return false;
    }
    const date = dayjs(disabledDate).add(1, 'days');
    date.hour(0);
    date.minute(0);
    date.second(0);
    return isBeforeDate
      ? current.valueOf() > date.valueOf()
      : current.valueOf() < date.valueOf();
  };

  const resetDate = () => {
    selectDate(isSingle ? null : [null, null]);
  };

  const clickDate = (e) => {
    e.stopPropagation();
    !readonly && setIsCalendarOpen(!isCalendarOpen);
  };

  return (
    <div className={`relative ${customClass}`} ref={cal}>
      {isSingle ? (
        <>
          <p className={`container-input ${inputClassName}`} data-cy={dataCy}>
            <label className="styled-label">{setBothLocale(label)}</label>
          </p>

          <SingleCalendar
            handleChangeCalendar={selectDate}
            customBox={`border-solid border-n-500 border bg-n-000 h-[32px] rounded-[4px] ${customClass}`}
            defaultValue={selectedDate ? dayjs(selectedDate) : null}
            disabledDate={onDisabledDate}
            type={type}
            calendarButtonType={calendarButtonType}
            allowClear={allowClear}
            customDropdown="w-[344px]"
          />
        </>
      ) : (
        <>
          <InputField
            calendar={!isCalendarOpen}
            cancelIcon={isCalendarOpen}
            cancelChanges={resetDate}
            onClick={clickDate}
            label={label}
            dataCy={dataCy}
            value={valueInput}
            placeholder={datePlaceholder || 'Choose date'}
            readonly={readonly}
            className={inputClassName}
            inputClass={
              valueInput.includes('Choose date') ||
              valueInput == datePlaceholder ||
              readonly
                ? 'text-n-600'
                : 'text-n-900'
            }
            customClassIconLabel="mt-[32px]"
          />
          <AntdCalendar>
            {isCalendarOpen && (
              <AntdCalendar.Content
                dateValue={[
                  selectedDate?.start ? dayjs(selectedDate?.start) : null,
                  selectedDate?.end ? dayjs(selectedDate?.end) : null
                ]}
                setDateValueHandler={selectDate}
                isAlwaysOpen
                leftSidebarContent="no"
                position="left"
              />
            )}
          </AntdCalendar>
        </>
      )}
    </div>
  );
};

export default TimeField;

import React, { useEffect, useState } from 'react';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { UserSuggestionProvider } from 'context/UserSuggestionContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ChooseTeamOrMember from 'components/project/ModalCreateProject/ChooseTeamOrMember';
import Modal from 'components/shared/modal/Modal';

const EditMembersModal = ({ project, setShowModal }) => {
  const [members, setMembers] = useState([]);
  const { refetchQueries } = useRefetchQuery();

  const handleClickSave = async () => {
    let newInvolvements = members.map((member) => ({
      userId: member?.user?.id,
      role: member?.role
    }));
    await editObjective(project?.id, { involvements: newInvolvements });
    await refetchQueries('project', project?.id);
    setShowModal(false);
  };

  useEffect(() => {
    setMembers(project?.involvements);
  }, []);

  return (
    <Modal
      title={getObjectiveLocale('Member')}
      withPrimaryBtn={{
        title: getObjectiveLocale('Save'),
        onClick: () => handleClickSave()
      }}
      withSecondaryBtn={{
        title: getObjectiveLocale('Cancel'),
        onClick: () => setShowModal(false)
      }}
      withCloseIcon
      eventOnClickClose={() => setShowModal(false)}
    >
      <UserSuggestionProvider>
        <ChooseTeamOrMember members={members} setMembers={setMembers} />
      </UserSuggestionProvider>
    </Modal>
  );
};

export default EditMembersModal;

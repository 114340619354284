import React from 'react';

import { useFormalReview } from 'context/FormalReviewContext';
import { phaseName } from 'utils/FormalReview';
import {
  getDateLocale,
  getExtraInformation,
  getObjectiveLocale
} from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import ProgressTracker from 'src/components/design-system/ProgressTracker/ProgressTracker';

function SidebarLeft() {
  const [
    {
      tracks,
      phase,
      cycle,
      involvedUser,
      currentTrackId,
      endsAt: assignmentEndsAt,
      status
    },
    { changeTrack }
  ] = useFormalReview();
  const endsAt = assignmentEndsAt
    ? getDateLocale(assignmentEndsAt, {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      })
    : '-';

  const { target } = involvedUser;

  const { name } = target;
  const restrictedTrackNavigation = cycle.restrictedTrackNavigation;

  const options = tracks.map((track, index) => {
    return {
      name: track.name,
      iconText: index + 1,
      iconName: track.state == 'done' ? 'icon-check' : null,
      active: track.state == 'done' || currentTrackId == track.id,
      disabled: status == 'Saving...' || (restrictedTrackNavigation && true),
      onClick: !restrictedTrackNavigation ? () => changeTrack(track.id) : null,
      dataCy: `track-${track.name}`.toLocaleLowerCase().replace(/ /g, '-')
    };
  });

  return (
    <div className="col-md-2 fixed-width-left h-full w-[256px]">
      <div className="sidebar-container" data-cy="sidebar-left-formal-review">
        <div className="profile">
          <h3 className="mb-[12px] text-n-900 capitalize typography-h600 non-italic">
            {phaseName(phase.type)}
          </h3>
          {phase?.showDueDate && (
            <span className="mb-[24px] typography-h200 text-n-800">
              <span className="inline-block typography-h100">
                {getObjectiveLocale('[Formal Review] Due Date')}:{' '}
              </span>{' '}
              {endsAt}
            </span>
          )}
          <h5>{getObjectiveLocale('REVIEW FOR').toUpperCase()}</h5>
          <div className="profile-container row">
            <div className="col-xs-3">
              <Avatar
                size={35}
                src={target?.profilePicture}
                name={target?.name}
              />
            </div>
            <div
              className="col-xs-9 reviewee-container"
              data-cy="reviewee-container"
            >
              <span className="reviewer">{name}</span>
              <span className="job-title">
                {getExtraInformation(target?.title, target?.subtitle)}
              </span>
            </div>
          </div>
        </div>
        <div
          className="ml-[-24px] py-[24px] border-0 border-b border-t border-solid border-n-600"
          data-cy="steps-container"
        >
          <ProgressTracker options={options} />
        </div>
      </div>
    </div>
  );
}

export default SidebarLeft;

import React from 'react';

import ModalDeletePreset from './modal/ModalDeletePreset';
import ModalRenamePreset from './modal/ModalRenamePreset';
import ModalReplacePreset from './modal/ModalReplacePreset';
import ModalSavePreset from './modal/ModalSavePreset';
import ModalSharePreset from './modal/ModalSharePreset';
import ModalUnsavedPreset from './modal/ModalUnsavedPreset';

const ObjectiveAnalyticsModal = (props) => {
  const { openModal } = props;

  if (openModal === 'deletePreset') return <ModalDeletePreset {...props} />;
  if (openModal === 'renamePreset') return <ModalRenamePreset {...props} />;
  if (openModal === 'replacePreset') return <ModalReplacePreset {...props} />;
  if (openModal === 'savePreset') return <ModalSavePreset {...props} />;
  if (openModal === 'sharePreset') return <ModalSharePreset {...props} />;
  if (openModal === 'unsavedPreset') return <ModalUnsavedPreset {...props} />;
};

export default ObjectiveAnalyticsModal;

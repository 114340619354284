import React, { useEffect, useRef, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import {
  addProjectSectionAbove,
  addProjectSectionBelow,
  renameProjectSection
} from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import useTasks from 'hooks/useTasks';

import InputField from 'components/shared/InputField';

const InputSectionName = ({
  sectionIndex,
  projectId,
  isShowing,
  setIsShowing,
  addAbove = false,
  isCreateNew = false,
  customInputClass = '',
  useAutoGrowInput = true
}) => {
  const { invalidateQueries } = useRefetchQuery();

  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef();

  const { listGroups, setListGroups } = useTasks();
  const { section } = listGroups;

  const handleKeyPress = (e) => {
    if (e.key === 'Escape' || e.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  const addSection = async (addAbove) => {
    const addSection = addAbove
      ? addProjectSectionAbove
      : addProjectSectionBelow;
    const sectionId = section[sectionIndex]?.id;
    const { data } = await addSection(projectId, sectionId, {
      name: inputValue
    });

    if (data) {
      let lowerCaseData = data;
      lowerCaseData.name = lowerCaseData?.name?.toLowerCase();
      let tempListGroups = cloneDeep(listGroups);
      tempListGroups?.section.splice(
        sectionIndex + (addAbove ? 0 : 1),
        0,
        lowerCaseData
      );
      setListGroups(tempListGroups);
      invalidateQueries(['group', 'section', 'projectDetail'], {
        refetchActive: false
      });
    }
  };

  const renameSection = async () => {
    if (section[sectionIndex]?.name.toLowerCase() == inputValue.toLowerCase())
      return;
    const sectionId = section[sectionIndex]?.id;
    const { data } = await renameProjectSection(projectId, sectionId, {
      name: inputValue
    });

    if (data) {
      let tempListGroups = cloneDeep(listGroups);
      tempListGroups.section[sectionIndex].name = data?.name;
      setListGroups(tempListGroups);
      invalidateQueries(['group', 'section', 'projectDetail'], {
        refetchActive: false
      });
    }
  };

  const onBlur = () => {
    if (inputValue !== '') {
      if (isCreateNew) {
        addSection(addAbove);
      } else {
        renameSection();
      }
    }
    setIsShowing(false);
  };

  useEffect(() => {
    if (isShowing) {
      inputRef.current.focus();
      inputRef.current.scrollLeft = inputRef.current.scrollWidth;
    }
  }, [isShowing, inputValue]);

  useEffect(() => {
    !isCreateNew && setInputValue(section[sectionIndex]?.name);
  }, [isCreateNew]);

  return (
    <InputField
      ref={inputRef}
      value={inputValue}
      handleChange={(e) => setInputValue(e.target.value)}
      onBlur={() => onBlur()}
      useAutoGrowInput={useAutoGrowInput}
      handleKeyPress={handleKeyPress}
      inputClass={`${
        customInputClass
          ? customInputClass
          : `${
              inputValue === '' ? 'w-[248px]' : ''
            } min-w-[240px] typography-paragraph text-n-900 uppercase `
      }`}
      dataCy="section-input-field"
    />
  );
};

export default InputSectionName;

import React, { useEffect, useState } from 'react';

import { getListObjectives } from 'client/ObjectivesClient';

import ObjectivesCard from 'components/objectives/ObjectivesCard';
import SVGIcon from 'components/shared/SVGIcon';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import ObjectiveEmptyState from 'src/components/shared/ObjectiveEmptyState';
import { loadMoreValidator } from 'src/utils/HelperUtils';

function ObjectivesCardList({ showGoals, setShowGoals, user, filter }) {
  const [loading, setLoading] = useState(false);
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [objectives, setObjectives] = useState([]);
  const [olderThan, setOlderThan] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  const fetchObjectives = async () => {
    const params = {
      limit: 10,
      ...filter
    };
    setLoading(true);
    const { data, pagination } = await getListObjectives(params);
    if (data) {
      setObjectives(data);
      setOlderThan(pagination.next.olderThan);
      setHasMore(data?.length === 10);
    }
    setLoading(false);
  };

  const appendObjectives = async () => {
    let params = {
      ...filter,
      olderThan: olderThan,
      limit: 10
    };
    let newList = [...objectives];
    const { data, pagination } = await getListObjectives(params);
    if (data) {
      newList = [...newList, ...data];
      if (
        pagination.next.olderThan === null ||
        pagination.prev.newerThan === null ||
        data?.length < 10
      ) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      setOlderThan(pagination.next.olderThan);
      setObjectives(newList);
    }
  };

  const onScroll = (e) => {
    const loadMore = async () => {
      setOnLoadMore(true);
      await appendObjectives();
      setOnLoadMore(false);
    };

    const target = e.target;
    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 500, () => {
        loadMore();
      });
    }
  };

  useEffect(() => {
    fetchObjectives();
  }, [showGoals]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="wrapper-card bg-n-000 border-n400 rounded-[6px] mt-[8px] py-[20px] w-[408px] m-auto">
          <div className="flex justify-between items-center mb-[32px] px-[20px]">
            <p className="typography-h400">{user?.name}</p>
            <SVGIcon
              onClick={() => setShowGoals(false)}
              fillColor="var(--n-600)"
              iconName="icon-clear"
              size={24}
            />
          </div>
          <div className="overflow-y-auto max-h-[1500px]" onScroll={onScroll}>
            {objectives.length == 0 ? (
              <ObjectiveEmptyState
                size="small"
                customContainerClassname="!my-[0px] mx-[20px]"
              />
            ) : (
              objectives?.map((objective, index) => (
                <ObjectivesCard key={index} objective={objective} user={user} />
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ObjectivesCardList;

import React, { useEffect, useState } from 'react';

import { submitFormalReview } from 'client/FormalReviewClient';
import {
  FormalReviewProvider,
  useFormalReview
} from 'context/FormalReviewContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import CustomInnerIcon from 'components/design-system/CustomInnerIcon';
import EvidenceSidebar from 'components/formal-review/EvidenceSidebar';
import FormalReviewContent from 'components/formal-review/FormalReviewContent.js';
import SidebarLeft from 'components/formal-review/SidebarLeft';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingSplashScreen from 'components/shared/LoadingSplashScreen';
import Modal from 'components/shared/modal/Modal';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import OverlayRightSidebar from 'src/components/formal-review/evidence-sidebar/OverlayRightSidebar.js';

function FormalReviewScoring({ match, history }) {
  const [
    {
      ready,
      status,
      cycle,
      involvedUser,
      phase,
      currentTrack,
      currentTrackId,
      tracks,
      assignmentId,
      lastUpdate,
      error,
      formalReviewPhase
    },
    {
      getSingleFormalReview,
      changeTrack,
      getSingleTrack,
      getGoalsScoringData,
      getTasksScoringData,
      setSliderLoading
    }
  ] = useFormalReview();

  const track = getSingleTrack(currentTrack);
  const { sidebarEvidenceEnabled } = formalReviewPhase || {};
  const { invalidateQueries } = useRefetchQuery();

  const [isModalSubmitShow, showModalSubmit] = useState(false);
  const { target } = involvedUser;
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [isSidebarCollapse, setSidebarCollapse] = useState(true);
  const [isFirstRendered, setIsFirstRendered] = useState(true);
  const [isModalReminderShow, setIsModalReminderShow] = useState(false);

  // overlay right sidebar
  const [overlaySidebar, setOverlaySidebar] = useState(false);
  const [currentRevieweeFeedbackType, setCurrentRevieweeFeedbackType] =
    useState('');
  const [isSubmited, setIsSubmited] = useState(false);

  const backUrl = localStorage.getItem('backUrl')
    ? localStorage.getItem('backUrl')
    : '/appraisals';

  let currentTrackIndex,
    nextTrackIndex,
    previousTrackIndex,
    nextTrack,
    backTrack,
    state;
  if (tracks && tracks?.length > 0) {
    currentTrackIndex = tracks?.findIndex(
      (track) => track?.id === currentTrackId
    );
    nextTrackIndex = currentTrackIndex + 1;
    previousTrackIndex = currentTrackIndex - 1;

    nextTrack =
      tracks?.length === nextTrackIndex
        ? tracks[currentTrackIndex]?.id
        : tracks[nextTrackIndex]?.id;

    backTrack =
      currentTrackIndex === 0
        ? tracks?.[0]?.id
        : tracks[previousTrackIndex]?.id;

    state = tracks?.find(({ id }) => id === currentTrackId)?.state;
  }

  let cycleName = cycle?.name || '';
  if (phase?.type == 'peer_review') {
    cycleName = cycleName + ` - ${target.name}`;
  }

  const closeScoring = () => {
    return history.replace(cycle?.team?.id ? '/feedbacks' : backUrl);
  };

  const submitScoring = async () => {
    const { isSuccess } = await submitFormalReview(assignmentId);
    if (isSuccess) {
      invalidateQueries(['assignments', cycle.id, phase.type]);
      invalidateQueries(['progress', cycle.id, phase.type]);
      invalidateQueries(['notification', 'formal-review']);
      localStorage.removeItem('backUrl');
      closeScoring();
    }
  };

  const saveAndExit = () => {
    invalidateQueries(['assignments', cycle.id, phase.type]);
    closeScoring();
    localStorage.removeItem('backUrl');
  };

  const isAssignmentComplete = () => {
    const isNotSaving = status != 'Saving...' && status != 'Saving Failed';
    const count = tracks?.filter((track) => {
      return track?.state == 'in_progress';
    });
    return count?.length == 0 && isNotSaving ? true : false;
  };

  const isLastTrack = () => {
    return tracks?.[tracks?.length - 1]?.id === currentTrackId;
  };

  const isFirstTrack = () => {
    return tracks?.[0]?.id == currentTrackId;
  };

  useEffect(() => {
    getSingleFormalReview(match.params.assignmentId, ready);
  }, [lastUpdate]);

  useEffect(() => {
    setShowRightSidebar(sidebarEvidenceEnabled);

    setTimeout(function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 2);
  }, [currentTrack]);

  // To show a wipe animation only at first render
  useEffect(() => {
    if (ready) {
      setTimeout(() => {
        setIsFirstRendered(false);
      }, 2000);
    }
  }, [ready]);

  const handleCloseAction = () => {
    if (isAssignmentComplete()) {
      setIsModalReminderShow(true);
    } else {
      saveAndExit();
    }
  };

  const headerProps = {
    titlePage: cycleName,
    primaryAction: tracks?.length > 0 && {
      title: getObjectiveLocale('Submit Review'),
      onClick: () => showModalSubmit(true),
      disabled: !isAssignmentComplete() || status === 'Saving...',
      showTooltip: !isAssignmentComplete(),
      textTooltip:
        'There are still some mandatory questions that need to be answered',
      dataCy: 'submit-formal-review'
    },
    saveStatus: status,
    lastUpdate: lastUpdate,
    isHeaderComposer: true,
    closeAction: handleCloseAction,
    backToUrl: `/${backUrl}`
  };

  const emptyState = {
    title: getObjectiveLocale('Can’t view review assignments yet'),
    subtitle: getObjectiveLocale(
      'Still waiting for other reviewers to complete submitting their review feedback'
    )
  };

  const isButtonNextRestricted =
    cycle?.restrictedTrackNavigation && track.state != 'done';

  const getObjectiveSaveCallback = () => {
    switch (track?.trackType) {
      case 'goals_scoring': {
        getGoalsScoringData(assignmentId);
        break;
      }
      case 'tasks_scoring': {
        getTasksScoringData(assignmentId);
        break;
      }
      case 'review_aspects_scoring': {
        setSliderLoading(true);
        setTimeout(() => {
          getSingleFormalReview(assignmentId);
          setSliderLoading(false);
        }, 2000);
        break;
      }
    }
  };

  const handleClickNext = () => {
    if (isLastTrack()) {
      showModalSubmit(true);
    } else {
      changeTrack(nextTrack);
    }
  };

  const handleClickBack = () => {
    changeTrack(backTrack);
  };

  useEffect(() => {
    if (ready) {
      document.getElementById('formal-review-content-container').scrollTop = 0;
    }
  }, [currentTrack]);

  return ready && tracks ? (
    <div style={{ height: 'calc(100vh - 1px)' }}>
      <HeaderPage {...headerProps} />
      <div className="formal-review px-[0px]">
        {error && error?.code === 403 ? (
          <ListEmptyState {...emptyState} />
        ) : (
          <div className="row-formal-review-scoring w-full h-full">
            <SidebarLeft />
            <div
              className={`pt-[0px] z-[1] ${
                showRightSidebar
                  ? `${
                      isSidebarCollapse
                        ? 'fixed-width-center '
                        : 'fixed-width-full'
                    } h-full overflow-auto float-left ${
                      isFirstRendered ? 'center-content-animation' : ''
                    }`
                  : 'fixed-width-right-full h-full overflow-hidden hover:overflow-auto'
              }`}
              id="formal-review-content-container"
            >
              {
                <FormalReviewContent
                  state={state}
                  setShowRightSidebar={setShowRightSidebar}
                  overlaySidebar={overlaySidebar}
                  setOverlaySidebar={setOverlaySidebar}
                  currentRevieweeFeedbackType={currentRevieweeFeedbackType}
                  setCurrentRevieweeFeedbackType={
                    setCurrentRevieweeFeedbackType
                  }
                  saveCallback={getObjectiveSaveCallback}
                  handleClickBack={handleClickBack}
                  handleClickNext={handleClickNext}
                  isNextDisabled={
                    (isLastTrack() && !isAssignmentComplete()) ||
                    status === 'Saving...' ||
                    isButtonNextRestricted
                  }
                  isPrevDisabled={isFirstTrack()}
                  isLastTrack={isLastTrack}
                />
              }

              {isModalSubmitShow && (
                <Modal
                  title={getObjectiveLocale('Success review')}
                  withCloseIcon
                  withPrimaryBtn={{
                    title: 'Submit',
                    onClick: () => {
                      submitScoring();
                      setIsSubmited(true);
                    },
                    isLoading: isSubmited
                  }}
                  withSecondaryBtn={{
                    title: 'Cancel',
                    onClick: () => showModalSubmit(false)
                  }}
                  eventOnClickClose={() => showModalSubmit(false)}
                >
                  <p className="text-n-800 typography-paragraph">
                    {[
                      getObjectiveLocale(
                        'Please make sure your reviews are already finalized'
                      ),
                      <br />,
                      cycle?.lockSubmittedAssignment
                        ? getObjectiveLocale(
                            'You will not be able to come back and change your answers.'
                          )
                        : ''
                    ]}
                  </p>
                </Modal>
              )}

              {isModalReminderShow && (
                <Modal
                  title="Reviews not yet submitted"
                  headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
                  className="max-w-[400px]"
                  withCloseIcon
                  withPrimaryBtn={{
                    title: 'Submit Now',
                    onClick: () => {
                      submitScoring();
                      setIsSubmited(true);
                    },
                    isLoading: isSubmited
                  }}
                  withSecondaryBtn={{
                    title: 'Save & Exit',
                    onClick: saveAndExit()
                  }}
                  eventOnClickClose={() => setIsModalReminderShow(false)}
                >
                  <p className="text-n-800">
                    {getObjectiveLocale(
                      'We won’t get your review response until you submit it. Want to submit it now?'
                    )}
                  </p>
                </Modal>
              )}
            </div>
            {overlaySidebar && (
              <OverlayRightSidebar onClose={() => setOverlaySidebar(false)} />
            )}
            {showRightSidebar && (
              <>
                <div
                  className={`mb-[65px] px-[0px] py-[0px] h-full overflow-overlay overflow-auto ${
                    isSidebarCollapse
                      ? 'fixed-width-right float-right'
                      : 'w-[40px] fixed right-0 h-[0px] clear-left'
                  }   ${
                    isFirstRendered ? 'right-content-animation' : ''
                  } section-sidebar-evidence`}
                >
                  <div
                    className={`${
                      isFirstRendered ? 'right-container-animation' : ''
                    } ${overlaySidebar ? 'animated-half fadeOut' : 'relative'}`}
                  >
                    {!overlaySidebar && (
                      <div
                        onClick={() => setSidebarCollapse(!isSidebarCollapse)}
                        className={`cursor-pointer z-[20] fixed ${
                          isSidebarCollapse
                            ? 'fixed-chevron'
                            : 'fixed-chevron-min'
                        }`}
                      >
                        <CustomInnerIcon
                          size={24}
                          iconName={
                            !isSidebarCollapse
                              ? 'icon-keyboard_arrow_left'
                              : 'icon-keyboard_arrow_right'
                          }
                        />
                      </div>
                    )}
                    <EvidenceSidebar
                      setOverlaySidebar={setOverlaySidebar}
                      visible={isSidebarCollapse}
                    />
                  </div>
                </div>
                <div id="dialog-history-portal" />
              </>
            )}
          </div>
        )}
      </div>
      <React.Suspense fallback={<div></div>}>
        {match?.params?.objectiveId && (
          <SidebarMyGoals
            saveCallback={getObjectiveSaveCallback}
            customClassname="top-[64px]"
          />
        )}
      </React.Suspense>
    </div>
  ) : (
    <LoadingSplashScreen />
  );
}

const FormalReviewScoringComponent = (props) => (
  <FormalReviewProvider>
    <FormalReviewScoring {...props} />
  </FormalReviewProvider>
);

export default FormalReviewScoringComponent;

import React from 'react';

import { capitalize, getObjectiveLocale } from 'utils/HelperUtils';

import Card from 'components/design-system/Card';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

import ChartObjectiveAnalytics from './ChartObjectiveAnalytics';

const ChartProgressOvertime = ({
  selectedTimeFrame,
  setSelectedTimeFrame,
  selectedDataPeriod,
  setSelectedDataPeriod,
  progressData,
  objectiveData,
  minimumProgress
}) => {
  let listTimeFrame = [
    { id: 1, title: 'weekly' },
    { id: 2, title: 'monthly' },
    { id: 3, title: 'quarterly' },
    { id: 4, title: 'semesterly' }
  ];
  if (objectiveData?.milestoneType !== 'disabled') {
    const currentTimeFrame = listTimeFrame.find(
      (timeframe) =>
        timeframe.title.toLowerCase() === objectiveData?.milestoneType
    )?.id;
    listTimeFrame = listTimeFrame.filter(
      (timeframe) => timeframe.id >= currentTimeFrame
    );
  }

  const startYear = new Date(objectiveData.createdAt).getUTCFullYear();
  const endYear = new Date(objectiveData.dueDate).getUTCFullYear();
  const listDataPeriod = [];
  for (let i = startYear; i <= endYear; i++) listDataPeriod.push(i.toString());

  const ChartDropdown = ({
    titleDropdown,
    dataCy,
    listDropdown,
    setDropdown,
    selectedDropdown,
    isDisabled = false
  }) => {
    return (
      <Dropdown customClass="mr-[16px]">
        <Dropdown.Trigger disabled={isDisabled}>
          <div
            className="h-[36px] rounded-[3px] border border-solid border-n-400 bg-n-000 text-n-900 py-[8px] px-[16px] pr-[40px] relative flex items-center w-full"
            data-cy={dataCy}
          >
            {getObjectiveLocale(titleDropdown)}: {capitalize(selectedDropdown)}
            {listDropdown.length > 1 && (
              <SVGIcon
                size="24"
                iconName="icon-unfold_more"
                fillColor="var(--n-700)"
                customClass="absolute"
                customStyle={{ top: '4px', right: '8px' }}
              />
            )}
          </div>
        </Dropdown.Trigger>
        <Dropdown.MenuItems customClass="w-full max-h-[200px] overflow-auto">
          {listDropdown.map((data, index) => (
            <Dropdown.MenuItem
              onClick={() => setDropdown(data?.title || data)}
              dataCy={`${dataCy}-${index}`}
            >
              {capitalize(data?.title || data)}
            </Dropdown.MenuItem>
          ))}
        </Dropdown.MenuItems>
      </Dropdown>
    );
  };

  const FilterProgressOvertime = () => {
    return (
      <div className="flex items-center">
        <ChartDropdown
          titleDropdown="Time frame"
          dataCy="time-frame-dropdown"
          listDropdown={listTimeFrame}
          setDropdown={setSelectedTimeFrame}
          selectedDropdown={selectedTimeFrame}
        />
        <ChartDropdown
          titleDropdown="Year"
          dataCy="data-period-dropdown"
          listDropdown={listDataPeriod}
          setDropdown={setSelectedDataPeriod}
          selectedDropdown={selectedDataPeriod}
          isDisabled={listDataPeriod.length == 1}
        />
      </div>
    );
  };
  return (
    <Card
      titleCard="Progress Over Time"
      useHorizontalPadding={false}
      customClass="mx-[40px] mt-[38px]"
      rightComponent={<FilterProgressOvertime />}
    >
      <div className="w-full px-[16px]">
        <ChartObjectiveAnalytics
          data={progressData}
          minimumProgress={minimumProgress}
        />
      </div>
    </Card>
  );
};

export default ChartProgressOvertime;

import React, { useEffect, useRef, useState } from 'react';

import NumberInput from 'components/shared/NumberInput';

function InputMilestone({
  data,
  index,
  handleChange,
  className,
  isCurrentValue = false,
  dataCy
}) {
  const [number, _] = useState(
    isCurrentValue ? data?.currentValue : data?.targetValue
  );
  const firstRender = useRef(true);

  const [numberValue, setNumberValue] = useState(number);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      handleChange(
        parseFloat(numberValue || '0'),
        isCurrentValue ? data?.periodName : index
      );
    }
  }, [numberValue]);

  return (
    <NumberInput
      className={className ? className : ''}
      inputClass="input-cycles"
      placeholder="0"
      value={numberValue}
      onChange={setNumberValue}
      dataCy={dataCy}
      precision={13}
    />
  );
}

export default InputMilestone;

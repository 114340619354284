import React from 'react';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

import './GroupListTable.scss';

function GroupListTable({
  groupList,
  groupType,
  searchValue,
  loadingList,
  setModalArchiveData,
  routeToEditPage,
  reactiveGroup,
  isReadOnly = false
}) {
  return (
    <div className="groups-tab-table">
      <div className="gt-table-header">
        <div className="flex px-[16px] py-[10px]">
          <div className="w-[58%] typography-h300 typography-secondary">
            {getObjectiveLocale('GROUP NAME')}
          </div>
          <div className="w-[15%] typography-h300 typography-secondary">
            {getObjectiveLocale('MEMBERS')}
          </div>
          <div className="w-[15%] typography-h300 typography-secondary">
            {getObjectiveLocale('STATUS')}
          </div>
          <div className="w-[12%]"></div>
        </div>
      </div>
      <div className="gt-table-body mt-[4px]">
        {/* Row */}
        {loadingList ? (
          <LoadingComponent />
        ) : groupList.length > 0 ? (
          groupList.map((group) => {
            return (
              <div
                className="flex flex-row justify-between leading-[52px] text-[14px] text-n-900 mt-4 px-16 group"
                key={group.id}
              >
                <div className="template-name w-[58%]">{group.name}</div>
                <div className="w-[15%]">
                  {group?.memberCount
                    ? getNumberLocale(group.memberCount)
                    : '-'}{' '}
                  {getObjectiveLocale(
                    group.memberCount > 1
                      ? 'employees'
                      : group.memberCount === 1
                      ? 'employee'
                      : ''
                  )}
                </div>
                <div className="w-[15%]">
                  {group.deleted ? 'Archived' : 'Active'}
                </div>
                <div className="w-[12%]">
                  {!isReadOnly && (
                    <div className="gt-action-container">
                      {group.deleted ? (
                        <>
                          <SVGIcon
                            iconName="icon-reply"
                            size="24"
                            customClass="ml-[18px] hidden group-hover:flex cursor-pointer"
                            fillColor="var(--n-600)"
                            onClick={() => reactiveGroup(group.id)}
                          />
                        </>
                      ) : (
                        <>
                          <SVGIcon
                            iconName="icon-pencil"
                            size="24"
                            customClass="ml-[18px] hidden group-hover:flex cursor-pointer"
                            fillColor="var(--n-600)"
                            onClick={() => routeToEditPage(group.id)}
                          />
                          <SVGIcon
                            iconName="icon-archive"
                            size="24"
                            customClass="ml-[18px] hidden group-hover:flex cursor-pointer"
                            fillColor="var(--n-600)"
                            onClick={() => setModalArchiveData(group)}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <ListEmptyState
            title={getObjectiveLocale('No Result Found')}
            subtitle={getObjectiveLocale(
              `Did you probably type the keyword incorrectly? We are sorry that what you searched was unfortunately not found.${
                groupType !== 'review_group'
                  ? '\nYou might also find the result by switching to tab above.'
                  : ''
              }`
            )}
            emptyIcon={
              searchValue ? 'icon-no-result-found' : 'icon-no-behaviour-model'
            }
            fullHeight
            containerClassname="!mt-[0px] mb-[30px]"
          />
        )}
        {/* Gap */}
        <div className="mt-[4px]"></div>
      </div>
    </div>
  );
}

export default GroupListTable;

import React from 'react';
import { ErrorBoundary as ErrorBoundaryComponent } from 'react-error-boundary';
import { withRouter } from 'react-router';

import SVGIcon from 'src/components/shared/SVGIcon';
import { useUser } from 'src/context/UserContext';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

function ErrorBoundary({ children, titleMessage, subtitleMessage }) {
  const { config } = useUser();

  titleMessage = titleMessage ?? 'Sorry, something went wrong';

  const ErrorFallback = () => (
    <div className="empty-goal h-[100vh] flex items-center justify-center flex-col w-[400px] m-auto">
      <SVGIcon iconName="icon-server-down" size={160} customClass="mb-[24px]" />
      <p className="text-n-900 typography-h700 mb-[12px]">
        {getObjectiveLocale(titleMessage)}
      </p>
      <p className="text-n-800 typography-paragraph">
        {getObjectiveLocale(subtitleMessage)}
        {subtitleMessage ? (
          getObjectiveLocale(subtitleMessage)
        ) : (
          <>
            {getObjectiveLocale(
              'Some stuff is happening at our end but we will surely fix it soon. Please try again later or feel free to'
            )}{' '}
            <a href={`mailto:${config?.supportEmail || 'support@happy5.co'}`}>
              {getObjectiveLocale('email us')}
            </a>{' '}
            {getObjectiveLocale('and tell us what happened.')}
          </>
        )}
      </p>
    </div>
  );

  return (
    <ErrorBoundaryComponent FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundaryComponent>
  );
}

export default withRouter(ErrorBoundary);

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import {
  getCalibrationPermission,
  getCyclesById
} from 'client/FormalReviewClient';
import { useUser } from 'context/UserContext';
import { loadMoreValidator } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import CalibrationFilterSection from 'components/formal-review/calibration/CalibrationFilterSection';
import CalibrationQuotaSection from 'components/formal-review/calibration/CalibrationQuotaSection';
import CalibrationTableSection from 'components/formal-review/calibration/CalibrationTableSection';
import ListCalibratorUsers from 'components/formal-review/calibration/ListCalibratorUsers';
import BannerBox from 'components/shared/BannerBox';
import SidebarLeftMenuNavigator from 'components/shared/SidebarLeftMenuNavigator';

import './team-result.scss';

function NewCalibration() {
  const { user } = useUser();
  const calibrationTableRef = useRef();
  const pageParams = useParams();

  const [quotaConfig, setQuotaConfig] = useState('enabled');
  const [calibrationMechanism, setCalibrationMechanism] = useState(null);
  const [scoringMarks, setScoringMarks] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [currentCalibrator, setCurrentCalibrator] = useState(null);
  const [listCalibratorUsers, setListCalibratorUsers] = useState([]);
  const [cycleData, setCycleData] = useState(null);
  const [isAbleToShowCurrentScore, setIsAbleToShowCurrentScore] =
    useState(false);

  const listMenu = [
    { id: 'calibration', title: 'Calibration', onClick: () => null }
  ];

  const isPerfCategory = calibrationMechanism === 'calibration_categories';

  const showCalibratorAssistant = () => {
    if (listCalibratorUsers?.length > 0) {
      if (listCalibratorUsers?.length === 1) {
        return user?.id !== listCalibratorUsers?.[0]?.id;
      }

      return true;
    }
  };

  const onScroll = (e) => {
    const target = e.target;
    loadMoreValidator(target, 100, () => setLoadMore(true));
  };

  // Basic Flow
  // On First Load
  // Get Permission -> IF current user != first assisted calibrator
  // auto select the first assisted calibrator as current calibrator
  const setupCalibration = async () => {
    const { data } = await getCalibrationPermission(pageParams.id);
    if (listCalibratorUsers.length == 0) {
      setListCalibratorUsers(data.assistedCalibrators);
      initializeCalibrator(data);
    } else {
      initializeCalibrationData(data);
    }
  };

  const initializeCalibrator = (data) => {
    let tempCalibrator;
    if (
      listCalibratorUsers.length == 0 &&
      data?.assistedCalibrators?.[0]?.id !== user?.id
    ) {
      tempCalibrator = {
        id: data?.assistedCalibrators?.[0]?.id,
        name: data?.assistedCalibrators?.[0]?.name
      };
    } else {
      tempCalibrator = {
        id: user?.id,
        name: user?.name
      };
    }

    setCurrentCalibrator(tempCalibrator);
  };

  const initializeCalibrationData = (data) => {
    setCalibrationMechanism(data.calibrationMechanism);
    setQuotaConfig(data.quota);
    setIsAbleToShowCurrentScore(data?.currentCalibrationCard);

    setScoringMarks(
      data?.ratingTemplate?.scoringComponents?.[0]?.scoringMarks || []
    );
  };

  const getCycleData = async () => {
    const response = await getCyclesById(pageParams.id);
    const { data } = response;
    if (data) {
      setCycleData(data);
    }
  };

  const headerProps = {
    titlePage: cycleData?.name,
    backToUrl: cycleData?.team?.id ? '/feedbacks' : '/appraisals',
    isHeaderComposer: true
  };

  useEffect(() => {
    setupCalibration();
  }, [currentCalibrator?.id]);

  useEffect(() => {
    getCycleData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="team-result" onScroll={onScroll}>
      <HeaderPage {...headerProps} />
      <div className="flex">
        <div className="w-[256px] relative">
          <SidebarLeftMenuNavigator
            listMenu={listMenu}
            title={'View Cycle Detail By'}
            activeMenu={'calibration'}
            customClass="w-[256px] relative !pl-[0px]"
          >
            {showCalibratorAssistant() && (
              <ListCalibratorUsers
                listCalibratorUsers={listCalibratorUsers}
                currentCalibratorId={currentCalibrator?.id}
                setCurrentCalibrator={setCurrentCalibrator}
              />
            )}
          </SidebarLeftMenuNavigator>
        </div>
        <div className="team-result-content bg-n-000">
          <CalibrationFilterSection
            currentCalibratorId={currentCalibrator?.id}
          />
          {currentCalibrator?.id !== user?.id && (
            <BannerBox
              text={`You are currently filling out this calibration for ${currentCalibrator?.name}`}
              type="info"
              customClass="mx-[40px] my-[24px] rounded-[8px]"
            />
          )}
          {currentCalibrator?.id && calibrationMechanism && (
            <>
              <CalibrationQuotaSection
                isPerfCategory={isPerfCategory}
                quotaConfig={quotaConfig}
                currentCalibratorId={currentCalibrator?.id}
              />
              <CalibrationTableSection
                ref={calibrationTableRef}
                isPerfCategory={isPerfCategory}
                calibrationMechanism={calibrationMechanism}
                scoringMarks={scoringMarks}
                loadMore={loadMore}
                setLoadMore={setLoadMore}
                currentCalibrator={currentCalibrator}
                isAbleToShowCurrentScore={isAbleToShowCurrentScore}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewCalibration;

import client from './ApiClient';

function getTrackTemplateData(query = {}) {
  return client(`v1/admin/track_templates`, { params: query });
}

function getDetailTrackTemplate(trackId, query = {}) {
  return client(`v1/admin/track_templates/${trackId}`, { params: query });
}

function activateTrackTemplate(trackId, data) {
  return client(`v1/admin/track_templates/${trackId}/reactivate`, {
    method: 'PUT',
    body: data
  });
}

function deleteOneTrackTemplate(trackId) {
  return client(`v1/admin/track_templates/${trackId}`, {
    method: 'DELETE',
    body: {}
  });
}

function postTrackTemplate(data) {
  return client(`v1/admin/track_templates`, { method: 'POST', body: data });
}

function editTrackTemplate(id, data) {
  return client(`v1/admin/track_templates/${id}`, {
    method: 'PUT',
    body: data
  });
}

function getMechanism(query) {
  return client(`admin/tracks/mechanism`, { params: query });
}

export {
  getTrackTemplateData,
  getDetailTrackTemplate,
  activateTrackTemplate,
  deleteOneTrackTemplate,
  postTrackTemplate,
  editTrackTemplate,
  getMechanism
};

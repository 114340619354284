/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import useMultipleEdit from 'hooks/useMultipleEdit';
import useTeam from 'hooks/useTeam';
import Config from 'utils/Config.js';
import { getCssVariableValue, getObjectiveLocale } from 'utils/HelperUtils';
import { getAvailableObjectiveListIds } from 'utils/ObjectivesHelper';

import Button from 'components/design-system/Button';
import CustomInnerIcon from 'components/design-system/CustomInnerIcon';
import Objective from 'components/objectives/Objective';
import Badge from 'components/shared/Badge';
import Checkbox from 'components/shared/Checkbox';
import LoadingComponent from 'components/shared/LoadingComponent';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import TeamGoalApproval from 'pages/teams/approval/TeamGoalApproval';
import FormattedNumber from 'src/components/design-system/FormattedNumber';

import CompactActivityWrapper from './CompactActivityWrapper';
import './CompactObjective.scss';
import CompactObjectiveBelow from './CompactObjectiveBelow';
import CompactObjectiveBody from './CompactObjectiveBody';
import CompactSkeleton from './CompactSkeleton';

const limit = 10;

function CompactObjective({
  objective,
  parent,
  permissions,
  clusters,
  /* actionable or just view mode */
  action = true,
  sidebar = true,
  showDetail,
  /* if you have children */
  nested = true,
  showChildren,
  children,
  isLoadChildren,
  isParent,
  showChild,
  /*if you need modal action */
  cardModel,
  depthLevel,
  level,
  page,
  fetchMore,
  canFetchMore,
  showBadgeObjectiveCount,
  isLoadMoreChildren,
  hideDueDate = false,
  smallerTitle = false,
  useObjectiveGroup,
  objectiveConfigs,
  showStateIcon = false,
  isTeamGoalsApproval = false,
  isReadOnly,
  customListBadge,
  showActivity,
  activityData,
  ...rest
}) {
  const queryClient = useQueryClient();
  const { config } = useUser();
  const { isAddingApprovalSubGoal, setApprovalParentGoal } = useTeam();
  const { customIconOnReview, ratingMechanism } = config;

  // TEAM APPROVAL
  const isApprovalCardModel = cardModel?.includes('goal-list-approval');
  const showTeamGoalApproval =
    objectiveConfigs?.approvalRequired && isAddingApprovalSubGoal;

  // DESTRUCT OBJECTIVE VALUE
  const { id, childrenCount, reviews, scoringTemplate, involvements } =
    objective || [];

  // MULTIPLE EDIT
  const selectedObjective = useMultipleEdit((state) => state.selectedObjective);
  const updateSelected = useMultipleEdit((state) => state.updateSelected);
  const updateStartId = useMultipleEdit((state) => state.updateStartId);
  const startId = useMultipleEdit((state) => state.startId);
  const removeSelected = useMultipleEdit((state) => state.removeSelected);
  const removeAllSelected = useMultipleEdit((state) => state.removeAllSelected);
  const isCheckedSelected = selectedObjective.find(
    (selObjective) => selObjective.id === objective?.id
  );
  const hideMultipleDelete =
    page?.includes('approvalPanel+to_be_deleted') ||
    page?.includes('Scoring') ||
    page?.includes('scoring');

  const cardRef = useRef();

  const holdSelectObjective = () => {
    if (selectedObjective?.length === 0) {
      updateStartId(id); // actually its Ids
      updateSelected(objective);
    } else {
      removeAllSelected();
      const availableObj = getAvailableObjectiveListIds();

      const stIdx = availableObj.findIndex((allObjs) => allObjs == startId);
      const endIdx = availableObj.findIndex((allObjs) => allObjs == id);

      let tmpArr = [];

      if (stIdx > endIdx) {
        tmpArr = availableObj.slice(endIdx, stIdx + 1);
      } else {
        tmpArr = availableObj.slice(stIdx, endIdx + 1);
      }
      tmpArr.forEach(async (obj) => {
        let queryKey = [page === 'myteams' ? 'team' : 'objective', obj];
        const data = queryClient.getQueryData(queryKey);
        await updateSelected(data?.data ? data?.data : data);
      });
    }

    if (
      selectedObjective.find(
        (sel) => sel?.id === objective?.id && selectedObjective.length === 1
      )
    ) {
      removeSelected(objective?.id);
    }
  };

  const updateSelect = (e) => {
    if (e.target.checked) {
      updateSelected(objective);
    } else {
      removeSelected(objective?.id);
    }
  };

  const isObjectiveSelected = () => {
    if (params.objectiveId == id || isCheckedSelected) {
      if (!objective?.organization?.id) return true;
      const search = new URLSearchParams(location.search);
      if (!search.has('organizationId')) return false;
      return (
        parseInt(search.get('organizationId')) === objective?.organization?.id
      );
    }
  };

  const [showChildLocal, setShowChildLocal] = useState(showChild);
  const [childHeight, setChildHeight] = useState(0);
  const [maxParent, setMaxParent] = useState(3);
  const [cardWidth, setCardWidth] = useState(0);

  // ALIGN PARENT
  const params = useParams();
  const [isHover, setHover] = useState(false);
  const padding = level > 1 ? (level - 1) * 40 : null;
  const outerPadding = rest?.outerPadding
    ? rest?.outerPadding + 40
    : level > 1
    ? (level - 1) * 28
    : null;
  let parentList = objective?.parents || (parent ? [parent] : []);
  const displayParentList =
    parentList.length > maxParent ? parentList.slice(0, maxParent) : parentList;

  const usingWorkAttribute =
    reviews?.[0]?.reviewChoices?.[0]?.workAttribute && customIconOnReview;
  const iconReviewUrl =
    reviews?.[0]?.reviewChoices?.[0]?.scoringMark?.iconUrl ||
    reviews?.scoringMark?.iconUrl;
  const scoringData = usingWorkAttribute
    ? reviews?.[0]?.reviewChoices?.[0]?.workAttribute
    : reviews?.[0]?.reviewChoices?.[0]?.scoringMark;
  const contentReviews = scoringData?.name;
  const scoreReviews =
    ratingMechanism == 'progress_times_weight' ||
    ratingMechanism == 'custom_formula'
      ? reviews?.[0]?.score
      : scoringData?.score;
  const scoringShowOption = scoringTemplate?.showOption;

  useEffect(() => {
    if (
      (showChildLocal && !showChild) ||
      (!isAddingApprovalSubGoal && childrenCount == 0)
    ) {
      setTimeout(() => {
        setShowChildLocal(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChild, childrenCount, isAddingApprovalSubGoal]);

  useEffect(() => {
    if (objectiveConfigs?.approvalRequired && childrenCount === 0) {
      setTimeout(
        () => {
          setShowChildLocal(showTeamGoalApproval);
        },
        showTeamGoalApproval ? 0 : 500
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    objectiveConfigs?.approvalRequired,
    isAddingApprovalSubGoal,
    showTeamGoalApproval
  ]);

  const resizeParent = new ResizeObserver(function () {
    setCardWidth(cardRef?.current?.clientWidth);
    setMaxParent(cardRef?.current?.clientWidth > 880 ? 3 : 2);
  });

  useEffect(() => {
    resizeParent.observe(cardRef?.current);
    return () => {
      if (cardRef?.current) {
        resizeParent.unobserve(cardRef?.current);
      }
    };
  }, [cardRef]);

  useEffect(() => {
    if (objectiveConfigs?.approvalRequired) {
      setApprovalParentGoal(objective);
    }
  }, [objectiveConfigs]);

  return (
    <div
      className={`compact-list-wrapper ${showChild ? 'show-child ' : ''} ${
        isApprovalCardModel ? 'bg-n-000' : ''
      }`}
      data-cy={`goal-${id}`}
      // eslint-disable-next-line react/no-unknown-property
      selector-id={id}
      key={id}
      ref={cardRef}
    >
      {!isParent && !isApprovalCardModel && !rest?.isLastIndex && level > 1 && (
        <div
          className="vertical-line-tree"
          style={{ left: outerPadding, zIndex: 1 }}
        />
      )}
      <div
        className="ml-[-40px] pl-[40px]"
        onMouseEnter={() => !isReadOnly && setHover(true)}
        onMouseLeave={() => !isReadOnly && setHover(false)}
      >
        {!hideMultipleDelete && ( // maintained until multiple restore is complete
          <Checkbox
            dataCy="confirm-bulk-delete"
            name={`objective-selected-${objective?.id}`}
            id={`objective-selected-${objective?.id}`}
            style={{ visibility: isHover || isCheckedSelected ? '' : 'hidden' }}
            customContainerClass={`checkbox-objectives ${
              isHover || isCheckedSelected ? 'fade-in' : 'fade-out'
            } absolute top-40 ${
              useObjectiveGroup ? 'left-[6px]' : ''
            } transform -translate-x-full -translate-y-1/2
                    ${
                      page?.includes('approvalPanel')
                        ? `mx-[-5px]`
                        : window.location.pathname.includes('/goals') ||
                          window.location.pathname.includes('/mytask') ||
                          window.location.pathname.includes('/companyGoals') ||
                          window.location.pathname.includes('/explorer')
                        ? 'mx-[-11px]'
                        : isApprovalCardModel
                        ? 'mx-[-31px]'
                        : 'mx-[-7px]'
                    }`}
            checked={isCheckedSelected !== undefined}
            onChange={updateSelect}
            size="small"
          />
        )}
        <div
          className={`content-compact-objectives ${
            isApprovalCardModel
              ? `pl-[0px] cursor-pointer ${
                  cardModel?.includes('detail') ? 'pt-[8px]' : 'mt-[8px]'
                }`
              : 'pt-[4px]'
          } ${isReadOnly ? 'hover:bg-n-000 cursor-default' : ''}`}
          {...(sidebar && {
            onClick: (e) => {
              if (isReadOnly) return;

              !hideMultipleDelete && // maintained until multiple restore is complete
              (e.metaKey || e.ctrlKey)
                ? holdSelectObjective()
                : (showDetail(id), showChildren());
            }
          })}
          style={{
            backgroundColor: isObjectiveSelected() ? 'var(--base-100)' : '',
            borderTop:
              level == 1 || isApprovalCardModel
                ? 'none'
                : '1px solid var(--n-300)',
            paddingLeft: padding
          }}
        >
          {cardModel !== 'checkin-list' &&
            ((isParent && parentList?.length > 0) || reviews?.length > 0) && (
              <div
                className={`px-[4px] py-[4px] mx-[4px] h-[24px] flex-grow flex ${
                  reviews?.length > 0 ? 'flex-row-reverse' : ''
                } items-center justify-between ${
                  !isParent ? 'badge-border' : ''
                }`}
              >
                {reviews?.length > 0 && (
                  <div
                    className={`review-list ${
                      isParent && parentList?.length > 0 ? 'grow-0' : 'grow'
                    }`}
                  >
                    <Badge
                      iconUrl={
                        customIconOnReview
                          ? iconReviewUrl
                          : Config.asset.createObjective.starYellow
                      }
                      colorHex={reviews?.[0]?.scoringMark?.colorHex}
                      bgColorHex={getCssVariableValue('--n-200')}
                      dataCy={`review-${reviews?.[0]?.id}`}
                      truncate
                      customMaxWidth="mr-[8px] w-full justify-end"
                    >
                      <FormattedNumber
                        number={scoreReviews || 0}
                        customClass="typography-h100 mr-[4px]"
                        minDecimalLength={1}
                      />
                      {scoringShowOption != 'score_only' ? contentReviews : ''}
                    </Badge>
                  </div>
                )}
                {isParent && displayParentList?.length > 0 && (
                  <>
                    {clusters.includes('parent') ? (
                      <div className="parent-list flex flex-grow items-center grow overflow-hidden mx-[4px]">
                        {displayParentList.map((parent, index) => (
                          <TooltipContainer
                            show
                            text={parent?.name}
                            classContainer="w-full"
                            wrap
                            align="left"
                            key={index}
                          >
                            <Badge
                              iconLeft="icon-arrow_upward"
                              iconLeftColor="var(--base-600)"
                              content={parent?.name}
                              colorHex={getCssVariableValue('--base-600')}
                              bgColorHex={getCssVariableValue('--n-200')}
                              dataCy={`parent-goal-${id}`}
                              useEvent
                              truncate
                              className={`grow ${
                                displayParentList?.length === 1
                                  ? '!max-w-full w-full'
                                  : '!max-w-[240px]'
                              }`}
                              customMaxWidth={
                                displayParentList?.length === 1
                                  ? 'max-w-full w-full'
                                  : 'max-w-[240px]'
                              }
                              onClick={() => {
                                if (isReadOnly) return;

                                action && showDetail(parent.id);
                              }}
                              key={index}
                            />
                          </TooltipContainer>
                        ))}
                        {parentList.length > maxParent && (
                          <Badge
                            content={`+ ${parentList.length - maxParent}`}
                            colorHex={getCssVariableValue('--base-600')}
                            bgColorHex={getCssVariableValue('--n-300')}
                            className="mx-[4px] grow-0 min-w-[40px]"
                          />
                        )}
                      </div>
                    ) : (
                      <p className="typography-h100">
                        {getObjectiveLocale('Parent not visible')}
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
          <div
            className={`compact-container ${
              showChild ? 'show-child' : ''
            } position-relative`}
          >
            {!isParent && !isApprovalCardModel && (
              <div className="curved-line-tree" />
            )}
            {objective?.childrenCount !== 0 || showTeamGoalApproval ? (
              <div
                onClick={(e) => {
                  if (isReadOnly) return;
                  e.stopPropagation();
                  showChildren();
                }}
              >
                <div
                  className={`${isObjectiveSelected() ? 'active' : ''} ${
                    isReadOnly
                      ? 'hover:bg-n-000 cursor-default'
                      : 'icon-container'
                  }`}
                >
                  <CustomInnerIcon
                    backgroundColor="transparent"
                    borderColor="var(--n-400)"
                    size={24}
                    iconColor={'var(--n-600)'}
                    iconName={
                      showChild
                        ? 'icon-keyboard_arrow_down'
                        : 'icon-keyboard_arrow_right'
                    }
                  />
                </div>
              </div>
            ) : objective?.type == 'task' ? (
              <div
                className={`icon-container ${
                  params.objectiveId == id || isCheckedSelected ? 'active' : ''
                }`}
              >
                <CustomInnerIcon
                  backgroundColor="var(--n-300)"
                  borderColor="var(--n-300)"
                  size={24}
                  iconColor={'var(--n-600)'}
                  iconName="icon-assignment_turned_in"
                />
              </div>
            ) : (
              !isApprovalCardModel && <div className="w-[28px]" />
            )}
            <div className={`content ${isApprovalCardModel ? 'pl-[0px]' : ''}`}>
              <CompactObjectiveBody
                objective={objective}
                clusters={clusters}
                isHover={isHover}
                hideDueDate={hideDueDate}
                smallerTitle={smallerTitle}
                cardWidth={cardWidth}
                showStateIcon={showStateIcon}
                isReadOnly={isReadOnly}
              />
              <CompactObjectiveBelow
                showChild={showChild}
                objective={objective}
                objectiveConfigs={objectiveConfigs}
                permissions={permissions}
                showBadgeObjectiveCount={showBadgeObjectiveCount}
                isHover={isHover}
                page={page}
                level={level}
                clusters={clusters}
                isTeamGoalsApproval={isTeamGoalsApproval}
                isReadOnly={isReadOnly}
                customListBadge={customListBadge}
              />
              {showActivity && level == 1 && (
                <CompactActivityWrapper
                  id={id}
                  involvements={involvements}
                  isReadOnly={isReadOnly}
                  activityData={activityData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: `${padding ? padding + 28 : 28}px` }}>
        <div
          id={showTeamGoalApproval ? '' : `add-sub-goal-for-${id}`}
          className="add-card relative"
          style={
            showChild && childrenCount > 0
              ? { borderLeft: '1px solid var(--n-400)' }
              : {}
          }
        ></div>
      </div>
      <ChildObjectives
        {...rest}
        nested={nested}
        showChild={showChild}
        showTeamGoalApproval={showTeamGoalApproval}
        isLoadChildren={isLoadChildren}
        padding={padding}
        objectiveConfigs={objectiveConfigs}
        objective={objective}
        action={action}
        level={level}
        depthLevel={depthLevel}
        page={page}
        objectiveChildren={children}
        cardModel={cardModel}
        outerPadding={outerPadding}
        hideDueDate={hideDueDate}
        smallerTitle={smallerTitle}
        canFetchMore={canFetchMore}
        isLoadMoreChildren={isLoadMoreChildren}
        fetchMore={fetchMore}
      />
    </div>
  );
}

const ChildObjectives = ({
  nested,
  showChild,
  showTeamGoalApproval,
  isLoadChildren,
  padding,
  objectiveConfigs,
  objective,
  action,
  level,
  depthLevel,
  page,
  objectiveChildren,
  cardModel,
  outerPadding,
  hideDueDate,
  smallerTitle,
  canFetchMore,
  isLoadMoreChildren,
  fetchMore,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState();
  const { id, childrenCount } = objective || [];

  useEffect(() => {
    if (isLoadChildren) {
      setIsLoading(true);
    } else {
      // delay hide skeleton loading
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [isLoadChildren]);

  return (
    <div id={`objective-card-${id}`} className="children">
      <AnimateHeight
        duration={1000}
        height={
          showChild && !isLoadChildren && objectiveChildren.length > 0
            ? 'auto'
            : 0
        }
      >
        {(nested || showTeamGoalApproval) && (
          <>
            {objectiveConfigs?.approvalRequired ? (
              <TeamGoalApproval
                objectiveId={id}
                objectiveConfigs={objectiveConfigs}
                subGoalsCount={childrenCount}
                cardModel="goal-list-approval"
                action={action}
                level={level + 1}
                depthLevel={depthLevel}
                page={page}
                nested
                {...rest}
              />
            ) : (
              objectiveChildren.map((objective, objectiveIndex) => {
                return (
                  <div
                    className={`child-wrapper`}
                    key={`${objectiveIndex}-${objective?.id}`}
                  >
                    <Objective
                      {...rest}
                      objective={objective}
                      key={`${objectiveIndex}-${objective?.id}`}
                      cardModel={cardModel}
                      action={action}
                      nested={true}
                      level={level + 1}
                      depthLevel={depthLevel}
                      objectiveIndex={objectiveIndex}
                      isLastIndex={
                        objectiveChildren?.length == objectiveIndex + 1
                      }
                      parentIsLastIndex={rest?.isLastIndex}
                      outerPadding={outerPadding}
                      page={page}
                      hideDueDate={hideDueDate}
                      smallerTitle={smallerTitle}
                    />
                  </div>
                );
              })
            )}
            {!isLoadChildren &&
              canFetchMore &&
              showChild &&
              childrenCount > limit && (
                <div
                  className={`${
                    level ? 'flex items-center justify-center' : ''
                  } py-[18px]`}
                >
                  {isLoadMoreChildren ? (
                    <LoadingComponent />
                  ) : (
                    <Button.Tertiary fullWidth onClick={fetchMore}>
                      Load More Objectives
                    </Button.Tertiary>
                  )}
                </div>
              )}
          </>
        )}
      </AnimateHeight>
      {isLoading && childrenCount > 0 && (
        <div style={{ paddingLeft: `${padding ? padding + 28 : 28}px` }}>
          <CompactSkeleton />
        </div>
      )}
    </div>
  );
};

export default CompactObjective;

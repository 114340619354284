import React from 'react';
import Linkify from 'react-linkify';

import SVGIcon from 'components/shared/SVGIcon';

const Description = ({ objectiveDetail }) => {
  const { description } = objectiveDetail;

  return (
    <div className="px-[24px] py-[12px]">
      <div className="flex" data-cy="sidebar-detail-description">
        <SVGIcon
          iconName="icon-notes"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <Linkify properties={{ target: '_blank' }}>
          <span className="whitespace-pre-wrap">{description}</span>
        </Linkify>
      </div>
    </div>
  );
};

export default Description;

import React from 'react';

import produce, { setAutoFreeze } from 'immer';

import { getJobPlacementUsers, getUsers } from 'client/admin/UsersClient';

//CREATE CONTEXT
const UsersAdminContext = React.createContext();
//PROVIDER
function UsersAdminProvider(props) {
  //INITIAL STATE
  const initialState = {
    users: [],
    limit: 10,
    hasMore: true,
    olderThan: '',
    newerThan: '',
    totalUser: null,
    allIds: [],
    getAllIsLoading: false,
    total: 0,
    metadata: {}
  };

  const [state, setState] = React.useState(initialState);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];
  return (
    <UsersAdminContext.Provider value={contextValue} {...props}>
      {props?.children}
    </UsersAdminContext.Provider>
  );
}
//MUTATION
function useUsersAdmin() {
  const [
    {
      limit,
      users,
      hasMore,
      olderThan,
      newerThan,
      totalUser,
      allIds,
      getAllIsLoading,
      total,
      metadata
    },
    immerSetState
  ] = React.useContext(UsersAdminContext);

  const getUsersData = async (
    query,
    jobPlacement = false,
    metadataType = 'totalAllPlacements'
  ) => {
    const _query = {
      limit: limit,
      state: ['active'],
      ...query
    };

    const { data, pagination, metadata, total } = jobPlacement
      ? await getJobPlacementUsers(_query)
      : await getUsers(_query);

    if (data) {
      immerSetState((draft) => {
        draft.users = data.length > 0 ? data : [];
        draft.metadata = metadata?.metadata;
        draft.olderThan = pagination?.next?.olderThan;
        draft.newerThan = data.length > 0 ? pagination?.next?.newerThan : null;
        draft.hasMore = pagination?.next?.olderThan;
        draft.total = total;
      });
    }
    if (metadata?.metadata?.[metadataType]) {
      immerSetState((draft) => {
        draft.totalUser = metadata?.metadata?.[metadataType];
      });
    }
  };
  const getAllIds = async (query, jobPlacement = false) => {
    let _query = {
      columns: ['id'],
      ...query
    };

    _query?.limit && delete _query?.limit;

    const getIds = async () => {
      const { data } = jobPlacement
        ? await getJobPlacementUsers(_query)
        : await getUsers(_query);

      if (data) {
        immerSetState((draft) => {
          draft.allIds = data;
        });

        return data;
      }
    };
    return getIds();
  };
  const nextPage = async (query, jobPlacement = false) => {
    const _query = {
      limit: limit,
      olderThan: olderThan,
      state: ['active'],
      ...query
    };
    const { data, pagination } = jobPlacement
      ? await getJobPlacementUsers(_query)
      : await getUsers(_query);

    if (data) {
      let lengthNextData = 0;

      if (data.length === limit) {
        const changedQuery = {
          ...query,
          olderThan: pagination?.next?.olderThan
        };
        const { data: nextData } = jobPlacement
          ? await getJobPlacementUsers(changedQuery)
          : await getUsers(changedQuery);
        if (nextData) {
          lengthNextData = nextData.length;
        }
      }
      immerSetState((draft) => {
        draft.users = data;
        draft.olderThan = pagination?.next?.olderThan;
        draft.newerThan = pagination?.prev?.newerThan;
        draft.hasMore =
          pagination?.next?.olderThan && data.length === limit
            ? lengthNextData > 0
            : false;
      });
    }
  };
  const prevPage = async (query, jobPlacement = false) => {
    const _query = {
      limit: limit,
      newerThan: newerThan,
      state: ['active'],
      ...query
    };
    const { data, pagination } = jobPlacement
      ? await getJobPlacementUsers(_query)
      : await getUsers(_query);

    if (data) {
      immerSetState((draft) => {
        draft.users = data;
        draft.olderThan = pagination?.next?.olderThan;
        draft.newerThan = pagination?.prev?.newerThan;
        draft.hasMore = true;
      });
    }
  };
  const appendUserData = async (query, jobPlacement = false) => {
    const _query = {
      limit: limit,
      olderThan: olderThan,
      state: ['active'],
      ...query
    };
    const { data, pagination, metadata } = jobPlacement
      ? await getJobPlacementUsers(_query)
      : await getUsers(_query);

    if (data) {
      immerSetState((draft) => {
        draft.users.push.apply(draft.users, data);
        draft.hasMore = pagination?.next?.olderThan == null ? false : true;
        draft.olderThan = pagination?.next?.olderThan;
        draft.newerThan = pagination?.next?.newerThan;
      });
    }
  };
  return {
    users,
    hasMore,
    getUsersData,
    nextPage,
    prevPage,
    limit,
    appendUserData,
    totalUser,
    getAllIds,
    allIds,
    getAllIsLoading,
    olderThan,
    newerThan,
    total,
    metadata
  };
}
export { UsersAdminProvider, useUsersAdmin };

import React, { useEffect, useState } from 'react';

import ctl from '@netlify/classnames-template-literals';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import QuestionComposer, {
  Content,
  Header
} from 'components/design-system/composer/Question';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Checkbox from 'components/shared/Checkbox';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';
import Toggle from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TooltipPortal from 'components/shared/Tooltips/TooltipPortal';

const QuestionInterface = {
  active: PropTypes.string,
  changeTab: PropTypes.func,
  customClassWrapper: PropTypes.string,
  customClassTab: PropTypes.string,
  customClassCount: PropTypes.string,
  listObjTabs: PropTypes.array,
  type: PropTypes.array,
  useComment: PropTypes.bool,
  usePrivate: PropTypes.bool,
  titleTypeDropdown: PropTypes.string,
  useIconLeft: PropTypes.bool,
  keyType: PropTypes.string,
  optionSubPrefix: PropTypes.string,
  isReadOnly: PropTypes.bool
};

const QuestionDefaultValue = {
  question: null,
  questionType: 'rating',
  needComment: 'no',
  order: null,
  visibility: 'open',
  options: [
    { option: 'Below Expectation' },
    { option: 'Meet Expectation' },
    { option: 'Almost Meet Expectation' },
    { option: 'Exceed Expectation' }
  ],
  type: [
    { name: 'Rating', value: 'rating' },
    { name: 'Multiple Choices', value: 'multiple_choices' },
    { name: 'Multiple Selection', value: 'multiple_selections' },
    { name: 'Comment', value: 'comments' }
  ],
  useComment: true,
  usePrivate: true,
  titleTypeDropdown: 'Rating type',
  useIconLeft: true,
  keyType: 'questionType',
  optionSubPrefix: 'options',
  isReadOnly: false,
  enableAddOther: false
};
function QuestionTemplate({
  question,
  questionType,
  needComment,
  visibility,
  options,
  sectionIndex,
  questionIndex,
  setQuestion,
  deleteOptionTemplate,
  addOptionTemplate,
  copyTemplate,
  deleteTemplate,
  useComment,
  usePrivate,
  type,
  useIconLeft,
  keyType,
  optionSubPrefix,
  isReadOnly,
  labelVisibility,
  enableAddOther
}) {
  const optionType =
    questionType == 'multiple_selections'
      ? Config.asset.admin.checkboxOff
      : Config.asset.admin.radioButtonOff;

  const [errorName, setErrorName] = useState(false);
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [portalData, setPortalData] = useState({});
  const isCommentOption = questionType != 'comments' && questionType != 'essay';

  const TriggerButtonCN = ctl(
    `h-[32px] py-[8px] pl-[16px] pr-[8px]
    bg-n-000 rounded capitalize flex items-center cursor-pointer
    border border-solid border-n-400
    ${isReadOnly ? 'bg-n-300 cursor-not-allowed' : 'cursor-pointer'}`
  );

  const onHandleChangeDetails = (e) => {
    const el = e.currentTarget;
    el.style.cssText = `height: ${el.scrollHeight}px`;
    if (el.scrollHeight > 111) {
      el.style.cssText = `height: 120px; max-height: 120px !important; overflow-y: scroll;`;
    }
    if (setQuestion) {
      setQuestion(el.value, 'question', questionIndex);
    }
  };

  const setPortal = (e) => {
    setIsTooltipShown(true);
    setPortalData({
      name: "can't be blank",
      left: e.currentTarget.getBoundingClientRect().x + 'px',
      top: e.currentTarget.getBoundingClientRect().top + 40 + 'px'
    });
  };

  const generateTitle = (typeName) => {
    switch (typeName) {
      case 'poll':
        return 'Multiple Choices';
      case 'essay':
        return 'Comment';
      default:
        return typeName;
    }
  };

  useEffect(() => {
    if (questionType != 'rating' && labelVisibility == false) {
      setQuestion(true, 'optionsLabelVisibility', questionIndex);
    }
    // eslint-disable-next-line
  }, [questionType]);

  return (
    <QuestionComposer customClass="mb-[16px]">
      <Header
        title={`${getObjectiveLocale('Question')}`}
        onCopy={() => !isReadOnly && copyTemplate(questionIndex)}
        onDelete={() => !isReadOnly && deleteTemplate(questionIndex)}
      />
      <Content>
        <div className="flex px-[24px] py-[16px] w-full">
          <div className="flex flex-col flex-grow mr-[16px]">
            <span className="typography-h100 text-n-800 mb-[8px]">
              {getObjectiveLocale('Question')} {questionIndex + 1}
            </span>
            <div className="relative">
              <TextArea
                className={`w-full ${errorName ? 'error-message' : ''}`}
                value={question}
                textPlaceholder={'Enter Your Question'}
                maxHeight={96}
                onHandleChange={(e) => onHandleChangeDetails(e)}
                onMouseEnter={(e) => errorName && setPortal(e)}
                onMouseLeave={() => setIsTooltipShown(false)}
                onBlur={() =>
                  !question ? setErrorName(true) : setErrorName(false)
                }
                paddingClass={'px-[8px] py-[4px]'}
                dataCy="question-template-name"
              />
              {!question && !isEmpty(portalData) && (
                <TooltipPortal
                  show={isTooltipShown}
                  portalData={portalData}
                  portalId="shared-tooltip"
                />
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <span className="typography-h100 text-n-800 mb-[8px]">
              {getObjectiveLocale('Answer Type')}
            </span>
            <Dropdown>
              <Dropdown.Trigger
                customClass="w-[212px] h-[32px]"
                disabled={isReadOnly}
              >
                <div
                  className={TriggerButtonCN}
                  data-cy="question-type-dropdown"
                >
                  {generateTitle(questionType.replace('_', ' '))}
                  <SVGIcon
                    size="24"
                    iconName="icon-unfold_more"
                    fillColor="var(--n-700)"
                    customClass="absolute"
                    customStyle={{ top: '4px', right: '8px' }}
                  />
                </div>
              </Dropdown.Trigger>
              <Dropdown.MenuItems customClass="w-[212px]">
                {type.map((type, index) => (
                  <Dropdown.MenuItem
                    onClick={() =>
                      setQuestion(type.value, keyType, questionIndex)
                    }
                    dataCy={type.value}
                    key={index}
                  >
                    {getObjectiveLocale(type?.name)}
                  </Dropdown.MenuItem>
                ))}
              </Dropdown.MenuItems>
            </Dropdown>
          </div>
        </div>
      </Content>
      {isCommentOption && (
        <Content>
          <div className="bg-n-200 px-[24px] py-[16px]">
            {options &&
              options.map((value, optionIndex) => (
                <div
                  key={optionIndex}
                  className="flex items-center mb-[16px] group"
                >
                  {useIconLeft &&
                    (questionType != 'rating' ? (
                      <AWSImage
                        src={optionType}
                        className="w-[24px] h-[24px] mr-[16px]"
                      />
                    ) : (
                      <div className="rounded-full w-[26px] h-[24px] bg-n-600 mr-[16px] text-center text-n-000 leading-[24px]">
                        {optionIndex + 1}
                      </div>
                    ))}
                  <div
                    className="w-full"
                    onMouseEnter={(e) =>
                      !value.option && !value.customType && setPortal(e)
                    }
                    onMouseLeave={() => setIsTooltipShown(false)}
                  >
                    <InputField
                      dataCy={`input-option-${optionIndex}`}
                      inputClass={`w-full ${
                        !value.option && !value.customType
                          ? 'error-message'
                          : ''
                      }`}
                      disabled={isReadOnly || value.customType}
                      placeholder={
                        value.customType
                          ? 'Other (Type your answer here)'
                          : 'Enter an answer choice'
                      }
                      value={value.option}
                      handleChange={(e) =>
                        setQuestion(
                          e.currentTarget.value,
                          optionSubPrefix,
                          questionIndex,
                          optionIndex
                        )
                      }
                    />
                    {!value.option &&
                      !isEmpty(portalData) &&
                      !value.customType && (
                        <TooltipPortal
                          show={isTooltipShown}
                          portalData={portalData}
                          portalId="shared-tooltip"
                        />
                      )}
                  </div>
                  {options.length > 2 && !isReadOnly && (
                    <SVGIcon
                      size={24}
                      iconName="icon-delete"
                      fillColor="var(--n-600)"
                      customClass="opacity-0 transition-opacity group-hover:opacity-100 ml-[16px]"
                      dataCy={`delete-option-${optionIndex}`}
                      onClick={() =>
                        deleteOptionTemplate(questionIndex, optionIndex)
                      }
                    />
                  )}
                </div>
              ))}
            <div
              className={`flex items-center
            ${
              useIconLeft
                ? 'ml-[40px] w-[calc(100%-78px)]'
                : 'w-[calc(100%-40px)]'
            }`}
            >
              <Button.Tertiary
                datacy={'add-option'}
                onClick={() => addOptionTemplate(questionIndex)}
                disabled={isReadOnly}
              >
                <SVGIcon
                  size="24"
                  fillColor="var(--v-600)"
                  iconName="icon-add"
                  customClass="mr-[4px]"
                />
                {getObjectiveLocale('Add Another Option')}
              </Button.Tertiary>

              {enableAddOther &&
                options.filter((option) => option.customType).length == 0 && (
                  <>
                    {getObjectiveLocale('or')}
                    <Button.Tertiary
                      datacy={'add-option'}
                      onClick={() =>
                        addOptionTemplate(questionIndex, {
                          option: null,
                          customType: true
                        })
                      }
                      disabled={isReadOnly}
                    >
                      {getObjectiveLocale('Add "Other"')}
                    </Button.Tertiary>
                  </>
                )}
            </div>
          </div>
        </Content>
      )}
      {isCommentOption && useComment && (
        <Content>
          <div className="bg-n-200">
            <div className="relative h-[48px] px-[16px] flex items-center justify-between">
              <p className="typography-h400">{getObjectiveLocale('Comment')}</p>
              <div className="flex items-center">
                {(needComment == 'required' || needComment == 'optional') && (
                  <div className="flex items-center mr-[24px]">
                    <Checkbox
                      customContainerClass="mr-[8px]"
                      name="set-optional"
                      id={'checkbox-set-optional-' + questionIndex}
                      checked={needComment == 'optional'}
                      disabled={isReadOnly}
                      onChange={(e) =>
                        setQuestion(
                          e.currentTarget.checked ? 'optional' : 'required',
                          'needComment',
                          questionIndex
                        )
                      }
                    />
                    <p className="typography-paragraph typography-secondary">
                      {getObjectiveLocale('optional')}
                    </p>
                  </div>
                )}
                <Toggle
                  onChange={() =>
                    setQuestion(
                      needComment == 'required' || needComment == 'optional'
                        ? 'no'
                        : 'required',
                      'needComment',
                      questionIndex
                    )
                  }
                  isChecked={
                    needComment == 'required' || needComment == 'optional'
                  }
                  idStr={`toggle-question-${questionIndex}`}
                  disabled={isReadOnly}
                />
              </div>
            </div>
          </div>
        </Content>
      )}
      <Content>
        {questionType == 'rating' && (
          <div className="bg-n-200 pt-[12px] px-[16px]">
            <div className="flex items-center mr-[24px]">
              <Checkbox
                customContainerClass="mr-[8px]"
                name="set-label"
                id={'checkbox-set-label-' + sectionIndex + '-' + questionIndex}
                checked={!labelVisibility}
                disabled={isReadOnly}
                onChange={() =>
                  setQuestion(
                    !labelVisibility,
                    'optionsLabelVisibility',
                    questionIndex
                  )
                }
              />
              <p className="typography-paragraph typography-secondary">
                {getObjectiveLocale('Only show score number without label')}
              </p>
            </div>
          </div>
        )}
        {usePrivate && (
          <div className="bg-n-200 py-[12px] px-[16px]">
            <div className="mr-[24px] flex items-center">
              <Checkbox
                customContainerClass="mr-[8px]"
                name="set-private"
                id={
                  'checkbox-set-private-' + sectionIndex + '-' + questionIndex
                }
                checked={visibility != 'open'}
                disabled={isReadOnly}
                onChange={() =>
                  setQuestion(
                    visibility == 'open' ? 'confidential' : 'open',
                    'visibility',
                    questionIndex
                  )
                }
                dataCy="checkbox-set-private"
              />
              <p className="typography-paragraph typography-secondary">
                {getObjectiveLocale('Set as Private')}
              </p>
              <div className="flex items-center">
                <img
                  className="h-[16px] w-[16px] ml-[24px] mr-[4px]"
                  src={Config.asset.admin.helpCircleGrey}
                />
                <span className="typography-paragraph typography-secondary">
                  {getObjectiveLocale(
                    'Only managers and admins can see the answers from reviewers for this question'
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
      </Content>
    </QuestionComposer>
  );
}

QuestionTemplate.propTypes = QuestionInterface;
QuestionTemplate.defaultProps = QuestionDefaultValue;

export default QuestionTemplate;

import React from 'react';

import TextArea from 'components/shared/TextArea';

function MissionDescription({ mission, setMission, isReadOnly }) {
  return (
    <div
      id="description-section"
      className="form-group border-bottom-400 py-[40px]"
    >
      <label
        htmlFor="missionDescription"
        className="typography-h500-longform text-n-900 mb-[4px]"
      >
        Tell something about this
      </label>
      <p className="typography-paragraph typography-secondary mb-[16px]">
        Describe the purpose of creating this mission.
      </p>
      <p className="typography-h100 typography-secondary mb-[8px]">
        Description
      </p>
      <TextArea
        id="teamName"
        className="px-[16px] py-[4px]"
        height={96}
        textPlaceholder="Type here..."
        disabled={mission?.status == 'active' || isReadOnly}
        onHandleChange={(e) =>
          setMission({
            ...mission,
            description: e.currentTarget.value
          })
        }
        value={mission.description}
        dataCy="mission-description"
      />
    </div>
  );
}

export default MissionDescription;

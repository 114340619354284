import React, { createRef, useEffect, useState } from 'react';

import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { isSelected, selectMembers } from 'utils/ObjectiveOverviewHelper.js';

import TwoLine from 'components/design-system/ProfileType';
import Checkbox from 'components/shared/Checkbox';
import LoadingComponent from 'components/shared/LoadingComponent';
import Modal from 'components/shared/modal/Modal';

function ModalSelectedMembers({
  handleCloseModal,
  selectedMembers,
  setSelectedMembers,
  getListUsers,
  isSelectAll,
  hasMore,
  appendListUsers,
  setAppendListUsers
}) {
  const [temporaryMembers, setTemporaryMembers] = useState(selectedMembers);
  const intersectTarget = createRef();

  const _appendData = () => {
    if (hasMore) {
      getListUsers('next', true);
    }
  };

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting && isSelectAll && _appendData(),
    threshold: 0.3
  });

  useEffect(() => {
    getListUsers('', true);

    return () => {
      setAppendListUsers([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      contentClass="overflow-scroll overflow-x-hidden"
      className="w-[400px]"
      maxHeight={440}
      title="Selected Employee"
      eventOnClickClose={handleCloseModal}
      withCloseIcon
      withPrimaryBtn={{
        title: 'Send',
        onClick: () => {
          setSelectedMembers(temporaryMembers);
          handleCloseModal(false);
        }
      }}
      withSecondaryBtn={{
        title: 'Cancel',
        onClick: () => handleCloseModal(false)
      }}
    >
      {appendListUsers.length == 0 ? (
        <LoadingComponent />
      ) : (
        <>
          {appendListUsers?.map((user, index) => {
            const isLast = appendListUsers.length - 1 == index;
            return (
              <div
                ref={isLast ? intersectTarget : null}
                className={`row-user flex items-center py-[12px] cursor-pointer hover:bg-base-30016 ${
                  isSelected(user, temporaryMembers) ? 'bg-base-30024' : ''
                }`}
                key={index}
              >
                <Checkbox
                  id={`select-user-${user?.id}`}
                  checked={isSelected(user, temporaryMembers)}
                  onChange={(e) => {
                    e.stopPropagation();
                    selectMembers(
                      e,
                      user,
                      temporaryMembers,
                      setTemporaryMembers
                    );
                  }}
                />
                <TwoLine
                  customClass="ml-[16px] w-[300px]"
                  user={user}
                  title={user.name}
                  subtitle={user.jobTitle}
                />
              </div>
            );
          })}
        </>
      )}
    </Modal>
  );
}

export default ModalSelectedMembers;

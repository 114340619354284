import client from './ApiClient';

function requestApproval(body = {}) {
  const url = `v1/objectives/approvals/request`;
  return client(url, { method: 'POST', body: body });
}

function withdrawApproval(body = {}) {
  const url = `v1/objectives/approvals/withdraw`;
  return client(url, { method: 'POST', body: body });
}

function approveApprovalRequests(body = {}) {
  const url = `v1/objectives/approvals/approve`;
  return client(url, { method: 'POST', body: body });
}

function rejectApprovalRequests(body = {}) {
  const url = `v1/objectives/approvals/reject`;
  return client(url, { method: 'POST', body: body });
}

function getApprovalAvailability(params) {
  const url = `v1/objectives/approvals/availability`;
  return client(url, { params });
}

function getApprovalRequests(params) {
  const url = `v1/objectives/approvals`;
  return client(url, { params });
}

function getApprovalRequestsCount() {
  const url = `v1/objectives/approvals/count`;
  return client(url, {});
}

function getOtherApproval(params) {
  const url = `/v1/users/approval_others`;
  return client(url, { params });
}

function getApproval(params) {
  const url = `v1/approvals`;
  return client(url, { params });
}

function getApprovalDetail(id, params) {
  const url = `v1/approvals/${id}`;
  return client(url, { params });
}

function getTeamGoalApproval(teamId, objectiveId) {
  return client(`v1/teams/${teamId}/objectives/${objectiveId}/approval`);
}

function requestTeamGoalApproval(teamId, objectiveId) {
  return client(`v1/teams/${teamId}/objectives/${objectiveId}/request`, {
    method: 'POST'
  });
}

function getUsersApprovalTeams(params) {
  const url = `v1/users/approval_teams`;
  return client(url, { params });
}

function withdrawApprovalById(id, params) {
  return client(`v1/approvals/${id}/withdraw`, {
    method: 'POST',
    params
  });
}

function approveApprovalById(id, params) {
  return client(`v1/approvals/${id}/approve`, {
    method: 'POST',
    params
  });
}

function rejectApprovalById(id, params) {
  return client(`v1/approvals/${id}/reject`, {
    method: 'POST',
    params
  });
}

export {
  requestApproval,
  withdrawApproval,
  approveApprovalRequests,
  rejectApprovalRequests,
  getApprovalAvailability,
  getApprovalRequests,
  getApprovalRequestsCount,
  getOtherApproval,
  getApproval,
  getApprovalDetail,
  getTeamGoalApproval,
  requestTeamGoalApproval,
  withdrawApprovalById,
  approveApprovalById,
  rejectApprovalById,
  getUsersApprovalTeams
};

import React from 'react';

import Shimmer from 'components/design-system/shimmer/Shimmer';

const NewSkeletonChildDictionary = () => {
  return (
    <div className="new-dictionary-child-card py-[16px] flex justify-between items-start bg-n-200">
      <div className="w-[30%] pl-[16px] column-inline-child">
        <div className="pl-[24px]">
          <Shimmer width="80%" height="24px" />
        </div>
      </div>

      <div className="w-1/2 column-inline-child">
        <div className="pl-[16px]">
          <Shimmer customClass="mb-[8px]" width="90%" height="24px" />
          <Shimmer width="80%" height="24px" />
        </div>
      </div>

      <div className="w-[10%] flex justify-center" />
      <div className="w-[10%] pr-[16px] flex justify-center" />
    </div>
  );
};

export default NewSkeletonChildDictionary;

import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import RadioButton from 'components/shared/RadioButton';
import Modal from 'components/shared/modal/Modal';

const ModalDeleteSection = ({
  templateData,
  setTemplateData,
  sectionIndex,
  setShowModal
}) => {
  const [moveQuestions, setMoveQuestions] = useState(true);
  const [selectedOption, setSelectedOption] = useState({
    sectionName: '',
    index: 0
  });

  const onClickDelete = () => {
    let tempData = cloneDeep(templateData);
    if (moveQuestions) {
      let questionsToMove = templateData?.sections[sectionIndex]?.questions;
      questionsToMove?.map((question) => {
        question?.id && delete question?.id;
        question?.options &&
          question?.options?.map((option) => option?.id && delete option?.id);
      });
      tempData?.sections?.[selectedOption?.index]?.questions?.push(
        ...questionsToMove
      );
    }
    tempData?.sections?.splice(sectionIndex, 1);
    setTemplateData(tempData);
    setShowModal(false);
  };

  const checkEmptySectionName = (sectionName, sectionIndex) => {
    if (sectionName?.length === 0) {
      return `Untitled Section ${sectionIndex + 1}`;
    }
    return sectionName;
  };

  useEffect(() => {
    const firstOptionIndex = sectionIndex === 0 ? 1 : 0;
    setSelectedOption({
      sectionName: checkEmptySectionName(
        templateData?.sections[firstOptionIndex]?.name,
        firstOptionIndex
      ),
      index: firstOptionIndex
    });
  }, [templateData?.sections, sectionIndex]);

  return (
    <Modal
      title="Delete Section"
      headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
      eventOnClickClose={() => setShowModal(false)}
      withPrimaryBtn={{
        title: 'Delete',
        dataCy: 'delete-section-button',
        onClick: () => onClickDelete()
      }}
      withSecondaryBtn={{
        title: 'Cancel',
        dataCy: 'cancel-delete-section-button',
        onClick: () => setShowModal(false)
      }}
      className="w-[455px]"
    >
      <p className="typography-paragraph text-n-800 mb-[16px]">
        {getObjectiveLocale('Are you sure you want to delete this section?')}
      </p>
      <RadioButton
        name="delete-section-questions-radio"
        checked={moveQuestions}
        onClick={() => setMoveQuestions(true)}
        addClass="items-start mb-[8px]"
      >
        <div className="ml-[16px]">
          <p className="typography-paragraph text-n-800 mb-[8px] top-[-4px]">
            {getObjectiveLocale('Move all the questions in this section to: ')}
          </p>
          <Dropdown>
            <Dropdown.Trigger>
              <Trigger
                topLabel={false}
                label={false}
                icon={false}
                text={selectedOption?.sectionName}
                customWrapperClass="w-[240px]"
              />
            </Dropdown.Trigger>
            <Dropdown.MenuItems customClass="w-[240px]">
              {templateData?.sections?.map((section, index) => {
                if (index !== sectionIndex) {
                  return (
                    <Dropdown.MenuItem
                      onClick={() =>
                        setSelectedOption({
                          sectionName: checkEmptySectionName(
                            section?.name,
                            index
                          ),
                          index: index
                        })
                      }
                      key={index}
                    >
                      {checkEmptySectionName(section?.name, index)}
                    </Dropdown.MenuItem>
                  );
                }
              })}
            </Dropdown.MenuItems>
          </Dropdown>
        </div>
      </RadioButton>
      <RadioButton
        name="delete-section-questions-radio"
        checked={!moveQuestions}
        onClick={() => setMoveQuestions(false)}
      >
        <p className="typography-paragraph text-n-800 ml-[16px]">
          {getObjectiveLocale('Delete all the questions in this section')}
        </p>
      </RadioButton>
    </Modal>
  );
};

export default ModalDeleteSection;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import 'scss/culture/_timeline';

import { getStatus } from 'client/CultureClient';
import { FeedsProvider } from 'context/FeedsContext';
import { useReload } from 'context/ReloadContext';
import { useSidebarMenu } from 'context/SidebarMenuContext';
import { useUser } from 'context/UserContext';
import { getCultureLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import GenerateModalPost from 'components/modal/culture/GenerateModalPost';

const WrapperTimeline = ({ title, children }) => {
  const { config } = useUser();
  const { reloadCurrentGroup } = useReload();

  const { toggleSidebar } = useSidebarMenu();
  const [groupDetail, setGroupDetail] = useState({});
  const [isModalPostOpen, setIsModalPostOpen] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const [modalType, setModalType] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);

  const allowToUploadVideo = config.videoConfig.allowed;
  const photoTitle = allowToUploadVideo
    ? getCultureLocale('Photo / Videos')
    : getCultureLocale('Photo');
  const params = useParams();

  const showModal = (type) => {
    setModalType(type);
    setIsModalPostOpen(true);
  };

  const dropdownTimeline = [
    {
      icon: 'icon-post-photo',
      title: getCultureLocale('Post') + ' ' + photoTitle,
      dataCy: 'post-photo',
      onClick: () => showModal('photo'),
      isVisible: config.featureConfig.allowedPostTypes.includes(
        'media' ||
          'multiple_media' ||
          'link' ||
          'attachment' ||
          'video' ||
          'multiple_content'
      )
    },
    {
      icon: 'icon-post-thought',
      title: getCultureLocale('Post your thought'),
      dataCy: 'post-thought',
      onClick: () => showModal('photo'),
      isVisible: config.featureConfig.allowedPostTypes.includes('thought')
    },
    {
      icon: 'icon-give-recognition',
      title: getCultureLocale('Give a Recognition'),
      dataCy: 'give-recognition',
      onClick: () => showModal('recognition'),
      isVisible: config.featureConfig.allowedPostTypes.includes('recognition')
    },
    {
      icon: 'icon-post-feeling',
      title: getCultureLocale('ShareYourFeelings'),
      dataCy: 'share-feeling',
      onClick: () => showModal('feeling'),
      isVisible: config.featureConfig.allowedPostTypes.includes('feeling')
    },
    {
      icon: 'icon-post-survey',
      title: getCultureLocale('Post a Survey'),
      dataCy: 'post-survey',
      onClick: () => showModal('poll'),
      isVisible: config.featureConfig.allowedPostTypes.includes('poll')
    }
  ].filter((menu) => menu.isVisible);

  const setSelectedGroup = async (groupId) => {
    const { data } = await getStatus(groupId);
    if (data) {
      setGroupDetail(data);
    }
  };

  const headerProps = {
    titlePage: title,
    primaryAction: groupDetail?.allowPost && {
      title: 'New',
      icon: 'icon-add',
      dropdown: dropdownTimeline,
      dataCy: 'new-button'
    }
  };

  const eventOnClick = (isRefetch) => {
    setIsModalPostOpen(false);
    if (isRefetch) {
      const scrollableDiv = document.getElementById('scroll-feeds');
      if (scrollableDiv) {
        scrollableDiv.scrollTop = 0;
      }
      setIsRefetch(true);
      setTimeout(() => {
        setIsRefetch(false);
      }, [500]);
    }
  };

  useEffect(() => {
    params?.groupId && setSelectedGroup(params?.groupId);
  }, [params?.groupId, reloadCurrentGroup]);

  useEffect(() => {
    toggleSidebar(true);
  }, []);

  return (
    <FeedsProvider>
      <HeaderPage {...headerProps} />
      {isModalPostOpen && (
        <GenerateModalPost
          modalType={modalType}
          groupDetail={groupDetail}
          eventOnClick={eventOnClick}
        />
      )}
      {children &&
        React.cloneElement(children, {
          groupDetail,
          isRefetch,
          eventOnClick,
          setScrollPosition,
          scrollPosition,
          setGroupDetail,
          setSelectedGroup
        })}
    </FeedsProvider>
  );
};

export default WrapperTimeline;

import React from 'react';

import RadioButton from 'src/components/shared/RadioButton';

import './RadioGroup.scss';

const RadioGroup = ({ options, align, name, id, answer, setAnswer }) => {
  return (
    <div className={`circle-radio ${align}`}>
      {options.map((option, index) => {
        const checked = option.id === answer;

        return (
          <RadioButton
            key={index}
            name={name}
            value={option.value}
            id={option.id}
            checked={checked}
            onChange={(e) =>
              setAnswer(id, [parseInt(e.target.id)], null, 'option')
            }
            labelClass="ml-[8px]"
            addClass="min-h-[24px] mb-[8px]"
          >
            {option.option}
          </RadioButton>
        );
      })}
    </div>
  );
};

export default RadioGroup;

import React, { useRef } from 'react';

import ConfigurationBox from 'components/shared/ConfigurationBox/ConfigurationBox';

function ClusterConfigurationBox({
  boxWidth,
  tableMinWidth,
  selectedValues,
  setSelectedValues,
  loading,
  data,
  setAllowUpdate,
  withBorder,
  useHeader,
  title,
  description,
  isReadOnly
}) {
  const menuRef = useRef();

  const handleSelectValue = (key, value) => {
    setAllowUpdate && setAllowUpdate(true);
    setSelectedValues({
      ...selectedValues,
      [key]: value
    });
  };

  return (
    <>
      <ConfigurationBox
        type="table"
        useHeader={useHeader}
        boxWidth={boxWidth}
        title={title}
        description={description}
        tableMinWidth={tableMinWidth}
        data={data}
        withBorder={withBorder}
        loading={loading}
        listConfig={{
          menus: data && Object.keys(data),
          selectKey: 'id',
          selectedValues,
          handleSelectValue,
          trueValue: 'true',
          falseValue: 'false'
        }}
        disabled={isReadOnly}
        ref={menuRef}
      />
    </>
  );
}

export default ClusterConfigurationBox;

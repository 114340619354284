import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { getAttachment } from 'client/AttachmentClient';
import { updateStateObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useTeam from 'hooks/useTeam';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { whichSection } from 'utils/WhichSectionUtils';

import Divider from 'components/design-system/Divider';
import ModalUpdateProgress from 'components/modal/ModalUpdateProgress/ModalUpdateProgress';
import ActivitiesDetail from 'components/objectives/ActivitiesDetail';
import MilestoneDetail from 'components/objectives/MilestoneDetail';
import JiraCard from 'components/objectives/jira/JiraCard';
import BannerBox from 'components/shared/BannerBox';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';
import CommentBox from 'components/sidebar/CommentBox';
import SidebarAttachment from 'components/sidebar/SidebarAttachment';
import Attachment from 'components/sidebar/objective-detail/Attachment';
import DateSection from 'components/sidebar/objective-detail/DateSection';
import Dependencies from 'components/sidebar/objective-detail/Dependencies';
import Description from 'components/sidebar/objective-detail/Description';
import Followers from 'components/sidebar/objective-detail/Followers';
import Header from 'components/sidebar/objective-detail/Header';
import Milestone from 'components/sidebar/objective-detail/Milestone';
import ObjectiveCategory from 'components/sidebar/objective-detail/ObjectiveCategory';
import OwnerReviewer from 'components/sidebar/objective-detail/OwnerReviewer';
import Parent from 'components/sidebar/objective-detail/Parent';
import Phase from 'components/sidebar/objective-detail/Phase';
import Priority from 'components/sidebar/objective-detail/Priority';
import Privacy from 'components/sidebar/objective-detail/Privacy';
import ProgressMetric from 'components/sidebar/objective-detail/ProgressMetric';
import ProjectInvolvements from 'components/sidebar/objective-detail/ProjectInvolvements';
import Review from 'components/sidebar/objective-detail/Review';
import Tags from 'components/sidebar/objective-detail/Tags';
import Title from 'components/sidebar/objective-detail/Title';
import Weight from 'components/sidebar/objective-detail/Weight';
import SidebarRight from 'src/components/design-system/SidebarRight/SidebarRight';
import { trackEvent } from 'src/utils/AnalyticUtils';

function SidebarDetail({
  objectiveConfigs,
  singleObjectiveDetail,
  saveCallback = () => null,
  header = { isEditable: true, onClickClose: null }
}) {
  let history = useHistory();
  const { config } = useUser();
  const { reload, reloadOverallProgress, reloadOverallStatus } = useReload();
  const {
    objectiveWeightType,
    taskManagementFeature,
    objectiveWeightTypeOptions,
    enableJiraIntegration
  } = config;

  const objectiveDetail = singleObjectiveDetail;
  const [active, setActive] = useState('detail');
  const { refetchQueries } = useRefetchQuery();

  const {
    measurement,
    reviews = [],
    involvements = [],
    id,
    name,
    description,
    startDate,
    priority,
    phase,
    permissions = [],
    weight,
    tags = [],
    parent,
    type = 'goal',
    complexity,
    objectiveCategory,
    commentOptions,
    currentMilestone,
    milestoneType,
    milestoneMode,
    blockedBy,
    blocking,
    organization
  } = objectiveDetail || {};

  const includes =
    config?.objectiveCommentMentionTarget !== 'all'
      ? involvements.length > 0
        ? involvements.map((involvements) => involvements.user.id)
        : []
      : [];
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const { checkValidationSoftWarning } = useValidationMinMax(weight);
  const { approvalParentGoal } = useTeam();

  const followers = involvements?.filter(
    ({ role, visible }) => role === 'follower' && visible
  );
  const hasReviews = reviews.length > 0;

  const getAttachmentData = async () => {
    const params = {
      ...(organization?.id ? { organizationId: organization?.id } : {})
    };
    return getAttachment(id, params);
  };

  let { data } = useQuery(
    ['objective', parseInt(id), 'attachments'],
    getAttachmentData,
    {
      staleTime: 1 * 60 * 1000,
      cacheTime: 1 * 60 * 1000
    }
  );
  const attachmentFiles =
    data?.data?.filter(({ state }) => state !== 'deleted') || [];

  const completeGoal = async (newState) => {
    const res = await updateStateObjective(id, newState);
    const { data } = res;
    const { type } = newState;

    if (data && type === 'decline')
      trackEvent({
        event: 'decline',
        eventProperties: { status: 'success', id: id, type: data.type }
      });
    if (data && type === 'redo')
      trackEvent({
        event: 'redo',
        eventProperties: { status: 'success', id: id, type: data.type }
      });
    if (data && type === 'completed')
      trackEvent({
        event: 'complete',
        eventProperties: { status: 'success', id: id, type: data.type }
      });

    refetchQueries(['objective', parseInt(id)]);
    reload({
      reloadObjectiveId: { id: id },
      reloadType: 'complete',
      reloadOverallProgress: !reloadOverallProgress,
      reloadOverallStatus: !reloadOverallStatus
    });

    return res;
  };

  const triggerCallback = (progressValue, measurement) => {
    if (
      type == 'task' &&
      singleObjectiveDetail?.phase?.name.toLowerCase() === 'to do'
    ) {
      refetchQueries(['objectives', 'mytasks', 'to do']);

      if (progressValue < measurement?.targetValue) {
        refetchQueries(['objectives', 'mytasks', 'in progress']);
      } else {
        refetchQueries(['objectives', 'mytasks', 'done']);
      }
    }
    saveCallback();
  };

  let parentId = parent ? parent.id : undefined;

  const goalType = objectiveDetail?.team?.id ? 'team_goal' : type;
  const isAllowedObjectiveCategory =
    objectiveWeightTypeOptions.includes(goalType);

  const sendAmplitudeopenTaskDetailAndOpenGoalDetail = () => {
    const objectiveOwnerEmail = singleObjectiveDetail.involvements?.find(
      (involvement) => {
        return involvement.role == 'assignee';
      }
    )?.user.email;
    const objectiveReviewerEmail = singleObjectiveDetail.involvements?.find(
      (involvement) => {
        return involvement.role == 'assigner';
      }
    )?.user.email;
    const objectiveFollowerEmail = singleObjectiveDetail.involvements
      ?.filter((involvement) => {
        return involvement.role == 'follower';
      })
      .map((involvement) => involvement?.user?.email);

    const amplitudeProperties = {
      source: 'direct',
      id: objectiveDetail?.id,
      'open in section': whichSection(history.location.pathname),
      owner: objectiveOwnerEmail || '',
      reviewer: objectiveReviewerEmail || '',
      follower: objectiveFollowerEmail || ''
    };

    trackEvent({
      event:
        singleObjectiveDetail.type == 'task'
          ? 'open task detail'
          : 'open goal detail',
      eventProperties: amplitudeProperties
    });
  };

  const showSidebar = (type = 'detail') => {
    setActive(type);
  };

  const isTask = location.pathname.includes('tasks');

  useEffect(() => {
    sendAmplitudeopenTaskDetailAndOpenGoalDetail();
    checkValidationSoftWarning();
  }, [weight]);

  return (
    <>
      {active == 'detail' && (
        <SidebarRight
          dataCy={'sidebar-detail'}
          fixed={false}
          customClass="!fixed right-0"
          customWidth="!w-[640px]"
        >
          <SidebarRight.Header>
            <Header
              approvalRequired={objectiveConfigs?.approvalRequired}
              objectiveDetail={objectiveDetail}
              completeGoal={completeGoal}
              setModalOpen={setModalOpen}
              setModalType={setModalType}
              isEditable={header?.isEditable}
              onClickClose={header?.onClickClose}
            />
          </SidebarRight.Header>
          <SidebarRight.Body withSpace={false}>
            {!permissions?.includes('update') &&
              objectiveConfigs?.approvalRequired &&
              id !== approvalParentGoal?.id && (
                <BannerBox
                  text="You can’t edit this goal while in this status"
                  type="warning"
                  marginClass="mt-[8px] mb-[16px] mx-[8px]"
                  paddingClass="px-[16px] py-[8px]"
                  customClass="!rounded-[8px] w-full h-[40px]"
                  dataCy="warning-approval-required"
                />
              )}
            <Privacy objectiveDetail={objectiveDetail} />
            {objectiveDetail?.isProject ? (
              <ProjectInvolvements objectiveDetail={objectiveDetail} />
            ) : (
              <OwnerReviewer objectiveDetail={objectiveDetail} />
            )}
            <Title objectiveDetail={objectiveDetail} />
            {/* Details */}
            <div className="mt-[16px]">
              {enableJiraIntegration &&
                type == 'goal' &&
                !objectiveDetail?.isProject &&
                config?.permissions?.jiraComponentSee && (
                  <JiraCard objectiveDetail={objectiveDetail} />
                )}
              {description && <Description objectiveDetail={objectiveDetail} />}
              {startDate && <DateSection objectiveDetail={objectiveDetail} />}
              {type == 'task' && taskManagementFeature && priority && (
                <Priority objectiveDetail={objectiveDetail} />
              )}
              {type == 'task' && taskManagementFeature && phase && (
                <Phase objectiveDetail={objectiveDetail} />
              )}
              {(measurement?.progressColorHex || measurement?.unit) && (
                <ProgressMetric
                  objectiveDetail={objectiveDetail}
                  setModalOpen={setModalOpen}
                  setModalType={setModalType}
                />
              )}
              {milestoneType &&
                milestoneType !== 'disabled' &&
                currentMilestone !== null && (
                  <Milestone
                    objectiveDetail={objectiveDetail}
                    showSidebar={showSidebar}
                  />
                )}
              {objectiveWeightType === 'type' &&
                isAllowedObjectiveCategory &&
                objectiveCategory &&
                type !== 'task' && (
                  <ObjectiveCategory objectiveDetail={objectiveDetail} />
                )}
              {type !== 'task' &&
                weight > 0 &&
                config?.permissions?.objectiveWeightManage && (
                  <Weight objectiveDetail={objectiveDetail} />
                )}
              {type == 'task' &&
                complexity &&
                config?.permissions?.objectiveWeightManage && (
                  <Weight objectiveDetail={objectiveDetail} />
                )}
              {parent && <Parent objectiveDetail={objectiveDetail} />}
              {type == 'task' &&
                taskManagementFeature &&
                (blockedBy?.length > 0 || blocking?.length > 0) && (
                  <Dependencies blockedBy={blockedBy} blocking={blocking} />
                )}
              {tags.length > 0 && permissions?.includes('label_manage') && (
                <Tags objectiveDetail={objectiveDetail} />
              )}
              {followers.length > 0 && !objectiveDetail?.isProject > 0 && (
                <Followers objectiveDetail={objectiveDetail} />
              )}
              {attachmentFiles.length > 0 && (
                <Attachment onClick={() => showSidebar('attachment')} />
              )}
              {hasReviews && <Review objectiveDetail={objectiveDetail} />}
              <div className="px-[24px] mt-[24px] mb-[16px]">
                <Divider />
              </div>
              <ActivitiesDetail dataId={id} organizationId={organization?.id} />
            </div>
            {isModalOpen && modalType == 'incomplete' && (
              <Modal
                title={`Incomplete ${
                  isTask
                    ? getObjectiveLocale('task', true)
                    : getObjectiveLocale('Goal')
                }`}
                withCloseIcon={false}
                withPrimaryBtn={{
                  title: 'Incomplete',
                  dataCy: 'button-primary-modal',
                  onClick: () => {
                    completeGoal({ state: 'running', type: 'redo' });
                    setModalOpen(false);
                  }
                }}
                withSecondaryBtn={{
                  title: 'Cancel',
                  dataCy: 'button-secondary-modal',
                  onClick: () => setModalOpen(false)
                }}
                description={`Are you sure you want to incomplete this ${
                  isTask
                    ? getObjectiveLocale('task', true)
                    : getObjectiveLocale('Goal', true)
                }?`}
              />
            )}
          </SidebarRight.Body>
          <SidebarRight.Footer customClass="bg-n-200">
            {permissions?.includes('comment') && (
              <CommentBox id={id} includes={includes} />
            )}
          </SidebarRight.Footer>
        </SidebarRight>
      )}
      {active == 'attachment' && (
        <SidebarAttachment
          objectiveId={id}
          attachmentFiles={attachmentFiles}
          getAttachmentData={getAttachmentData}
          handleClose={() => setActive('detail')}
        />
      )}
      {active == 'milestone' && (
        <SidebarRight fixed={false} customClass="!fixed right-0">
          <SidebarRight.Header>
            <div className="flex justify-between items-center">
              <span className="typography-h500">Milestone Progress</span>
              <SVGIcon
                onClick={() => setActive('detail')}
                fillColor="var(--n-600)"
                iconName="icon-clear"
                size="24"
                customClass="ml-[16px]"
              />
            </div>
          </SidebarRight.Header>
          <SidebarRight.Body withSpace={false} customClass="py-[16px]">
            <MilestoneDetail
              currentMilestone={currentMilestone}
              dataId={id}
              milestoneMode={milestoneMode}
              milestoneType={milestoneType}
              measurement={measurement}
              permissions={permissions}
              setModalOpen={setModalOpen}
              setModalType={setModalType}
              objectiveValue={objectiveDetail}
            />
          </SidebarRight.Body>
        </SidebarRight>
      )}

      {isModalOpen && (modalType == 'complete' || modalType == 'update') && (
        <ModalUpdateProgress
          measurement={measurement ? measurement : null}
          withCloseIcon
          eventOnClick={() => setModalOpen(false)}
          canUpdateCurrentValue={permissions?.includes('update_current_value')}
          objectiveId={id}
          parentId={parentId}
          completeGoal={completeGoal}
          modalType={modalType}
          objectiveName={name}
          commentOptions={commentOptions}
          saveCallback={triggerCallback}
          currentMilestone={currentMilestone}
          milestoneType={milestoneType}
          objectiveValue={objectiveDetail}
        />
      )}
    </>
  );
}

export default SidebarDetail;

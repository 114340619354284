import React, { useEffect, useMemo, useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';
import { getObjectiveInvolvements } from 'utils/ObjectivesHelper';

import InlineDialogSelectUser from 'components/inline-dialog/InlineDialogSelectUser';
import { getNewInvolvements } from 'components/sidebar/sidebar-create/SidebarCreateHelper';
import useDisabledUsers from 'src/hooks/useDisabledUsers';

const Leader = ({ objectiveValue, setObjectiveValue, updateObjective }) => {
  const { permissions, involvements } = objectiveValue;

  const { checkIsUserDisabled } = useDisabledUsers({
    isEdit: true,
    isProject: true,
    objective: objectiveValue
  });

  const currentInvolvements = useMemo(() => {
    return [...involvements];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isRemoveIconClicked, setIsRemoveIconClicked] = useState(false);

  const selectedLeader = getObjectiveInvolvements(involvements, 'leader');

  const handleChangeDialog = (involvements, type, extendedRoleType) => {
    const lastIndex = involvements?.length - 1;
    const selectedUser = involvements[lastIndex];

    checkIsUserDisabled(selectedUser?.user?.id, () => {
      let newInvolvements = getNewInvolvements(
        involvements,
        type,
        false,
        objectiveValue,
        null,
        extendedRoleType,
        true
      );

      setObjectiveValue((draft) => {
        draft.involvements = newInvolvements;
      });
    });
  };

  const updateLeader = async () => {
    const newInvolvements = objectiveValue.involvements.map((involvement) => ({
      userId: involvement.user.id,
      role: involvement.role,
      placementId: involvement.user.placementId,
      extendedRole: involvement.extendedRole
    }));

    const { isSuccess } = await updateObjective({
      involvements: newInvolvements
    });

    if (!isSuccess) {
      setObjectiveValue((draft) => {
        draft.involvements = currentInvolvements;
      });
    }
  };

  const removeUserCallback = () => {
    // ** If user click remove icon dialog
    // ** setIsRemoveIconClicked to true, to trigger useEffect to update reviewer
    setIsRemoveIconClicked(true);

    // ** Reset state
    setTimeout(() => {
      setIsRemoveIconClicked(false);
    }, 50);
  };

  useEffect(() => {
    // ** To update leader when the remove icon was clicked
    if (isRemoveIconClicked) {
      updateLeader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemoveIconClicked]);

  return (
    <div className="w-1/2">
      <p className="typography-h100 text-n-800">
        {getObjectiveLocale('Leader')}
      </p>
      <div className="ml-[-8px]">
        <InlineDialogSelectUser
          objectiveValue={objectiveValue}
          selectedValue={selectedLeader}
          handleChange={handleChangeDialog}
          permission={permissions?.includes('assign_leader')}
          type="assignee"
          extendedRoleType="leader"
          position="left"
          header="Leader"
          onCloseDialog={updateLeader}
          dataCy="inline-dialog-project-leader"
          removeUserCallback={removeUserCallback}
        />
      </div>
    </div>
  );
};

export default Leader;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import ProfileEvidence from './ProfileEvidence';
import WrapperAssessmentScore from './WrapperAssessmentScore';
import WrapperTotalScoring from './WrapperTotalScoring';

const CardOverallInputScore = ({
  showOption,
  answersData,
  totalScore,
  components,
  view
}) => {
  const getComponentById = (id) => {
    return components.find((component) => component.id == id);
  };
  return (
    <div
      id={'container-overall-sidebar'}
      className="mt-[24px] mb-[64px]"
      data-cy={'container-overall-sidebar'}
    >
      {totalScore > 0 && <WrapperTotalScoring totalScore={totalScore} />}
      <div className="evidence-border px-[16px] py-[16px]">
        <p className="typography-h500 text-n-900 mb-[16px]">
          {getObjectiveLocale('Input Score for Overall Goals')}
        </p>
        {answersData?.map((answer, i) => (
          <React.Fragment key={i}>
            <ProfileEvidence profileData={answer?.actor} />

            <WrapperAssessmentScore
              showOption={showOption}
              personalScore={answer?.score || answer?.option?.score}
              isLastIndex={i !== answersData?.length - 1}
              description={answer?.comment}
              view={view}
              statusColorHex={answer?.option?.colorHex}
              statusName={answer?.option?.name}
              components={getComponentById(answer?.option?.ratingTemplateId)}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CardOverallInputScore;

import React, { useRef, useState } from 'react';

import useClickOutside from 'hooks/useClickOutside';

import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

const DropdownBehaviorModel = ({
  defaultValue,
  dataModel,
  isLoading,
  type,
  onClick,
  onChange,
  onScroll,
  setSearch,
  isReadOnly
}) => {
  const dropdownRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [valueDropdown, setValueDropdown] = useState(defaultValue);
  const _onClick = () => {
    setIsDropdownOpen(true);
    onClick();
  };
  const _onChange = (e, data) => {
    onChange(e, data);
    setValueDropdown(data.title);
    resetState();
  };
  const resetState = () => {
    setIsDropdownOpen(false);
    setSearch('');
  };
  useClickOutside(dropdownRef, () => resetState());
  return (
    <div className="relative">
      <div
        onClick={!isReadOnly && _onClick}
        className="button-dropdown"
        data-cy={`dropdown-behavior-${type}`}
      >
        <p className="text-dropdown">{valueDropdown}</p>
        {!isReadOnly && (
          <SVGIcon
            iconName="icon-keyboard_arrow_down"
            fillColor="var(--base-600)"
            size={20}
          />
        )}
      </div>
      {isDropdownOpen && (
        <ul
          ref={dropdownRef}
          className="wrapper-behavior-dropdown"
          onScroll={(e) => onScroll(e)}
        >
          <div className="py-[10px] px-[16px] sticky-position">
            <input
              autoFocus
              data-cy={'behavior-search-' + type}
              className="px-[16px] py-[16px] input-behavior-weight"
              placeholder="Search model"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {!isLoading &&
            dataModel.map((value, i) => (
              <div
                key={i}
                className="wrapper-dropdown-list"
                data-cy={`dropdown-list-${value.id}`}
                onClick={(e) => _onChange(e, value)}
              >
                <li className="py-[10px] px-[16px]">{value.title}</li>
              </div>
            ))}
          {isLoading && <LoadingComponent />}
        </ul>
      )}
    </div>
  );
};
export default DropdownBehaviorModel;

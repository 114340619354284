import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Rating from 'components/objective-review-popup/Rating';

function RatingReview({
  options,
  selectedRating,
  onSelectRating,
  ratingComment,
  onComment
}) {
  return (
    <div className="team-rating-box-body">
      <div className="question-container">
        <p className="p-body-team-header">
          {getObjectiveLocale(
            'How was this person’s contribution to the team?'
          )}
        </p>
        <Rating
          selectedOptionId={selectedRating}
          onSelectRating={onSelectRating}
          options={options}
        />
        <div className="comment-container">
          <label className="label-comment">
            {getObjectiveLocale('Comment')}{' '}
            <p className="label-comment-optional">
              ({getObjectiveLocale('Optional')})
            </p>
          </label>
          <textarea
            className="comment-text-field"
            placeholder={getObjectiveLocale('Write description here...')}
            defaultValue={ratingComment}
            onChange={(e) => onComment(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default RatingReview;

import React, { useState } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

import { UseAuthPage } from 'context/AuthPageContext';
import { getObjectiveLocale, setDropdownOptions } from 'utils/HelperUtils';

import TimeField from 'components/admin/usermanagement/TimeField';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';

const AuthRegistration = (props) => {
  const { handleOnChange, birthdate, workUnitList } = UseAuthPage();
  const [selectedWorkUnit, setSelectedWorkUnit] = useState('Select work unit');
  const workUnitOptions = setDropdownOptions(
    workUnitList,
    'title',
    (data) => {
      handleOnChange('workUnit', data);
      setSelectedWorkUnit(data.title);
    },
    '',
    ''
  );

  let titleRegistration, descRegistration;

  if (props?.type === 'birthdate') {
    titleRegistration = 'Birth Date';
    descRegistration = 'Please enter your birth date';
  } else {
    titleRegistration = 'Work Unit';
    descRegistration = 'Please enter your work unit';
  }

  return (
    <>
      <span className="typography-h600 text-n-900">
        {getObjectiveLocale(titleRegistration)}
      </span>
      <span className="auth-text" data-cy="desc-reg">
        {getObjectiveLocale(descRegistration)}
      </span>
      <div className="container-input">
        {props?.type === 'birthdate' && (
          <TimeField
            onSelectDate={(date) => handleOnChange('birthdate', date)}
            selectedDate={birthdate}
            label={getObjectiveLocale('Birth Date')}
            datePlaceholder={getObjectiveLocale('Please enter your birth date')}
            formatDate="YYYY-MM-DD"
            isBeforeDate={true}
            disabledDate={Date.now()}
            inputClassName="mt-[0px]"
            isSingle={true}
          />
        )}
        {props?.type === 'workUnit' && (
          <>
            <label className="styled-label">
              {getObjectiveLocale('Work Unit')}
            </label>
            <Dropdown>
              <Dropdown.Trigger dataCy="reg-work-unit">
                <Trigger
                  customWrapperClass="w-[350px]"
                  topLabel={false}
                  label={false}
                  icon={false}
                  text={selectedWorkUnit}
                  capitalize={true}
                />
              </Dropdown.Trigger>
              <Dropdown.MenuItems
                options={workUnitOptions}
                customClass="w-[350px]"
                dataCy="reg-work-unit"
              />
            </Dropdown>
          </>
        )}
      </div>
      {props.showWarning && (
        <div className="container-error">
          <span data-cy="wrong-login-data" className="error-message">
            {props.warningMessage}
          </span>
        </div>
      )}
    </>
  );
};

AuthRegistration.contextTypes = {
  handleChange: PropTypes.func
};

AuthRegistration.propTypes = {
  handleKeyPress: PropTypes.func,
  routeToRecovery: PropTypes.func,
  showWarning: PropTypes.bool,
  warningMessage: PropTypes.string
};

export default withRouter(AuthRegistration);

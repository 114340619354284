import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import { getProgressOvertimeData } from 'client/ObjectivesClient';
import { useSidebarMenu } from 'context/SidebarMenuContext';
import {
  changeFormatData,
  seriesGenerator
} from 'utils/ProgressOvertimeHelper';

import Switcher from 'components/design-system/Switcher';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import ChartObjectiveAnalytics from 'components/objectives/progress-overtime/ChartObjectiveAnalytics';
import SVGIcon from 'components/shared/SVGIcon';
import ToastNotif from 'components/shared/ToastNotif';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

const AnalyticProgressOvertime = ({
  objId,
  editPermission,
  setTimeframeProgress,
  dataCy = ''
}) => {
  const history = useHistory();
  const { isMinimized } = useSidebarMenu();
  const [selectedTimeFrame, setSelectedTimeFrame] = useState('monthly');
  const [minimumProgress, setMinimumProgress] = useState(0);
  const [maximumProgress, setMaximumProgress] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [formattedProgressData, setFormattedProgressData] = useState({});
  const [sizeCard, setSizeCard] = useState(false);

  const timeframeList = [
    { id: 'weekly', name: 'Weekly' },
    { id: 'monthly', name: 'Monthly' },
    { id: 'quarterly', name: 'Quarterly' },
    { id: 'semesterly', name: 'Semesterly' }
  ];

  const otherOptions = [
    {
      text: getObjectiveLocale('View Detail'),
      iconName: 'icon-remove_red_eye',
      onClick: () => history.replace(`/objective-overview/analytics/${objId}`),
      isVisible: true
    },
    {
      text: getObjectiveLocale('Edit Goal'),
      iconName: 'icon-create',
      onClick: () =>
        history.replace(`/objective-overview/analytics/edit/${objId}`),
      isVisible: editPermission
    },

    {
      text: getObjectiveLocale('View on Tree'),
      iconName: 'icon-device_hub',
      onClick: () => window.open(`/tree/${objId}`),
      isVisible: true
    }
  ].filter(({ isVisible }) => isVisible);

  const fetchProgress = () => {
    return getProgressOvertimeData(objId, {
      timeFrame: selectedTimeFrame
    });
  };

  const { refetch, isFetching } = useInfiniteQuery(
    ['objective', 'progress-overtime', objId],
    ({ pageParam }) => fetchProgress(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.pagination?.next || 0,
      staleTime: 5 * 60 * 1000,
      onSuccess: (data) => {
        const progressData = flatten(
          data?.pages?.map((page) => {
            const metadata = page?.metadata?.metadata;
            setTimeframeProgress({ ...metadata, selectedTimeFrame });

            return page?.data?.map((d) => {
              return d;
            });
          })
        );
        const changedData = changeFormatData(progressData, selectedTimeFrame);
        setFormattedProgressData(
          seriesGenerator(
            changedData,
            selectedTimeFrame,
            minimumProgress,
            maximumProgress,
            setMinimumProgress,
            setMaximumProgress
          )
        );
      },
      onError: () => {
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      }
    }
  );

  const getXAxisData = () => {
    const dateSeries = formattedProgressData?.series?.[0]?.data;

    if (selectedTimeFrame == 'weekly' || selectedTimeFrame == 'monthly') {
      return {
        min: dateSeries?.[0]?.x,
        max: dateSeries?.[12]?.x
      };
    } else {
      const dataSeriesLength = dateSeries?.length;
      return { min: 0, max: dataSeriesLength < 12 ? dataSeriesLength - 1 : 12 };
    }
  };

  useEffect(() => {
    setMinimumProgress(0);
    !isEmpty(selectedTimeFrame) && refetch();
  }, [selectedTimeFrame]);

  useEffect(() => {
    setSizeCard(true);
    setTimeout(() => {
      setSizeCard(false);
    }, 500);
  }, [isMinimized]);

  return (
    <>
      <ToastNotif
        showNotif={showToast}
        message={getObjectiveLocale('Something went wrong...')}
        warning
      />
      <div
        className="w-[calc(100%-288px)] h-full flex flex-col items-end"
        data-cy={dataCy}
      >
        <div className="flex z-index-2">
          <Switcher
            options={timeframeList}
            selectedOption={selectedTimeFrame}
            onChange={(tab) => setSelectedTimeFrame(tab)}
            customClass="pr-[16px]"
            textCustomClass="typography-button"
          />
          <Dropdown>
            <Dropdown.Trigger dataCy="trigger-header-other-action-button">
              <div className="flex items-center border-n-400 border-solid border-[1px] rounded-[4px] p-[4px]">
                <SVGIcon
                  size="24"
                  fillColor="var(--n-600)"
                  iconName="icon-keyboard_control"
                />
              </div>
            </Dropdown.Trigger>
            <Dropdown.MenuItems
              type="fixed"
              options={otherOptions}
              position="right"
            />
          </Dropdown>
        </div>
        {isFetching || sizeCard ? (
          <>
            <Shimmer
              width="728px"
              height="18px"
              customClass="mb-[24px] mt-[40px]"
            />
            <Shimmer width="728px" height="18px" customClass="mb-[24px]" />
            <Shimmer width="728px" height="18px" customClass="mb-[24px]" />
          </>
        ) : (
          <ChartObjectiveAnalytics
            data={formattedProgressData}
            minimumProgress={minimumProgress}
            maximumProgress={maximumProgress + 20}
            height={185}
            reversed={true}
            containerProps={{
              className: `!w-full mt-[-32px] ${`obj-analytic-card-${objId}`}`
            }}
            enabledScroll={
              formattedProgressData?.series?.[0]?.data?.length > 12
            }
            xAxis={getXAxisData()}
          />
        )}
      </div>
    </>
  );
};

export default AnalyticProgressOvertime;

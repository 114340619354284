import React from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { useLocale } from 'hooks/useLocale';
import usePulseSurvey from 'hooks/usePulseSurvey';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

import FormTemplate from './FormTemplate';
import SurveyQuestionComposer from './SurveyQuestionComposer';

const QuestionForm = ({ isReadOnly }) => {
  const { setBothLocale } = useLocale();
  const { surveyData, setSurveyData } = usePulseSurvey();
  const defaultPollOptions = [{ option: '' }, { option: '' }];

  const type = [
    { name: 'Multiple Choices', value: 'poll' },
    { name: 'Comment', value: 'essay' }
  ];

  const changeData = (value, subPrefix, index, optionIndex) => {
    let clonedQuestions = cloneDeep(surveyData?.questions);
    clonedQuestions[index].orderLevel = index;
    if (subPrefix !== 'pollOptionsAttributes') {
      clonedQuestions[index][subPrefix] = value;
      if (subPrefix === 'type') {
        clonedQuestions[index]?.id && delete clonedQuestions[index]?.id;
        value === 'essay'
          ? delete clonedQuestions[index].pollOptionsAttributes
          : (clonedQuestions[index].pollOptionsAttributes = defaultPollOptions);
      }
    } else {
      let newOptions = [...clonedQuestions[index][subPrefix]];
      newOptions[optionIndex].option = value;
      clonedQuestions[index][subPrefix] = [...newOptions];
    }
    setSurveyData({ ...surveyData, questions: clonedQuestions });
  };

  const addSingleOption = (listOptions, optionAdditionalAttribute) => {
    // take out customType option
    const customTypeIndex = listOptions.findIndex(
      (option) => option.customType
    );
    const customTypeElement =
      customTypeIndex == -1 ? [] : listOptions.splice(customTypeIndex, 1);

    // insert new option and add back the customTypeElement
    const newOption = {
      option: '',
      ...optionAdditionalAttribute
    };

    listOptions.push(newOption, ...customTypeElement);
  };

  const addDeleteOptionTemplate = (
    type,
    index,
    optionIndex,
    additionalAttribute
  ) => {
    let clonedQuestions = cloneDeep(surveyData?.questions);
    if (type === 'add') {
      addSingleOption(
        clonedQuestions[index].pollOptionsAttributes,
        additionalAttribute
      );
    } else {
      clonedQuestions[index].pollOptionsAttributes.splice(optionIndex, 1);
    }
    setSurveyData({ ...surveyData, questions: clonedQuestions });
  };

  const addOrCopyTemplate = (type, index) => {
    let clonedQuestions = cloneDeep(surveyData?.questions);
    if (type === 'copy') {
      let copiedArray = cloneDeep(clonedQuestions[index]);
      copiedArray.question = `${copiedArray.question} (Copy)`;
      if (copiedArray.pollOptionsAttributes) {
        const pollOptionsAttributes = clonedQuestions[
          index
        ].pollOptionsAttributes.map((value) => ({ option: value.option }));
        copiedArray.pollOptionsAttributes = pollOptionsAttributes;
      }
      clonedQuestions = [...clonedQuestions, copiedArray];
    } else {
      clonedQuestions.push({
        question: null,
        type: 'poll',
        pollOptionsAttributes: [{ option: '' }, { option: '' }]
      });
    }
    setSurveyData({ ...surveyData, questions: clonedQuestions });
  };

  const deleteTemplate = (index) => {
    let clonedQuestions = cloneDeep(surveyData?.questions);
    if (clonedQuestions.length > 1) {
      clonedQuestions.splice(index, 1);
      setSurveyData({ ...surveyData, questions: clonedQuestions });
    }
  };

  return (
    <FormTemplate
      title="Create question"
      description="Create some questions for this survey."
    >
      {surveyData?.questions?.map((data, index) => (
        <SurveyQuestionComposer
          key={index}
          questionIndex={index}
          options={type}
          changeData={changeData}
          isReadOnly={isReadOnly}
          addDeleteOptionTemplate={addDeleteOptionTemplate}
          addOrCopyTemplate={addOrCopyTemplate}
          deleteTemplate={deleteTemplate}
          isMaxAnswerOption={
            data?.pollOptionsAttributes?.filter(({ option }) => option !== null)
              ?.length > 9
          }
          {...data}
        />
      ))}
      <div
        className={
          isReadOnly
            ? 'hidden'
            : 'my-[24px] bg-n-200 rounded-[4px] h-[112px] flex items-center justify-center'
        }
      >
        <Button.Secondary
          onClick={() => addOrCopyTemplate('add')}
          disabled={isReadOnly}
        >
          <SVGIcon
            size="24"
            fillColor="var(--v-600)"
            iconName="icon-add"
            customClass="mr-[4px]"
          />
          {setBothLocale('Add Question')}
        </Button.Secondary>
      </div>
    </FormTemplate>
  );
};

export default QuestionForm;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildErrorData = exports.handleSentry = exports.progressClientMiddleware = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const Sentry = __importStar(require("@sentry/browser"));
const caseConverter_1 = require("utils/caseConverter");
const socket_1 = require("utils/socket");
const progressClientMiddleware = ({ queryFn = () => null, queryFnCallback, socketChannelParams = [], socketCallback }) => {
    const socketListener = new socket_1.SocketListener();
    return socketListener.register({
        name: socket_1.SocketChannels.backgroundJobProgress(socketChannelParams),
        callback: (data) => {
            if (!data || !socketCallback)
                return;
            socketCallback(data, socketListener);
        },
        callbackConnected: () => __awaiter(void 0, void 0, void 0, function* () {
            const result = yield queryFn();
            queryFnCallback && queryFnCallback(result, socketListener);
        })
    });
};
exports.progressClientMiddleware = progressClientMiddleware;
class NetworkError extends Error {
    constructor(name, message) {
        super(message);
        this.name = name;
        this.stack = new Error().stack;
    }
}
const handleSentry = ({ error, endpoint, config, method, raw }) => {
    if (error.name == 'ERR_CANCELED')
        return;
    Sentry.withScope(function (scope) {
        const getUserData = localStorage.getItem('userData');
        if (getUserData) {
            const userData = JSON.parse(getUserData);
            scope.setUser({ email: userData.email });
            scope.setTag('organization', userData.organizationName);
        }
        scope.setTag('pathname', location.pathname);
        scope.setTag('endpoint', endpoint);
        scope.setTag('type', `${(error === null || error === void 0 ? void 0 : error.code) ? error === null || error === void 0 ? void 0 : error.code : ''} ${error.message}`);
        scope.setExtras({
            config: config,
            responseData: error === null || error === void 0 ? void 0 : error.response
        });
        scope.setExtras({
            raw: JSON.stringify(raw)
        });
        scope.setFingerprint([method, error === null || error === void 0 ? void 0 : error.code.toString(), error === null || error === void 0 ? void 0 : error.message]);
        Sentry.captureException(new NetworkError((error === null || error === void 0 ? void 0 : error.name) || error.code.toString(), error === null || error === void 0 ? void 0 : error.message));
    });
};
exports.handleSentry = handleSentry;
const buildErrorData = (e) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    let error = ((_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error)
        ? (_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.error
        : (_f = (_e = e === null || e === void 0 ? void 0 : e.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.errors;
    error = (0, caseConverter_1.toCamelCase)(error);
    error = {
        name: e.code || 'ERR',
        message: (error === null || error === void 0 ? void 0 : error.message) || 'Something Went Wrong',
        code: (error === null || error === void 0 ? void 0 : error.code) || e.code,
        response: e.response,
        retryAfter: error === null || error === void 0 ? void 0 : error.retryAfter
    };
    if ((e === null || e === void 0 ? void 0 : e.code) == 'ERR_NETWORK') {
        error.message = 'Network Error';
        error.response = {
            data: {
                response: (_g = e === null || e === void 0 ? void 0 : e.request) === null || _g === void 0 ? void 0 : _g.response,
                responseText: (_h = e === null || e === void 0 ? void 0 : e.request) === null || _h === void 0 ? void 0 : _h.responseText,
                responseType: (_j = e === null || e === void 0 ? void 0 : e.request) === null || _j === void 0 ? void 0 : _j.responseType,
                responseUrl: (_k = e === null || e === void 0 ? void 0 : e.request) === null || _k === void 0 ? void 0 : _k.responseUrl,
                responseXML: (_l = e === null || e === void 0 ? void 0 : e.request) === null || _l === void 0 ? void 0 : _l.responseXML
            }
        };
    }
    return error;
};
exports.buildErrorData = buildErrorData;

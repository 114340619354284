import React, { useEffect, useReducer, useState } from 'react';

import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ContentSwitcherIcon from 'components/shared/ContentSwitcherIcon';

import AssignTo from './AssignTo';
import DateFilter from './DateFilter';
import Group from './Group';
import MenuFilter from './MenuFilter';
import Position from './Position';
import Search from './Search';
import Show from './Show';
import Sort from './Sort';
import Switcher from './Switcher';
import TimeGroup from './TimeGroup';
import ViewBy from './ViewBy';
import YearFilter from './YearFilter';

function AdvancedFilter({
  useConfig,
  children,
  childrenInFilter,
  switchOptions,
  filterOptions,
  dateFilterOptions,
  defaultFilter = {},
  filterReducer,
  filterMenu,
  resultModifier,
  defaultGroup,
  defaultShowTask,
  useFilterShowTask,
  useFilterGroup,
  gridArea,
  noFilterDate,
  dateFormatType,
  restructureFilter,
  filterClient,
  immutableFilter,
  dialogQueryFn = (params) => {
    params;
  },
  suspense,
  sortList,
  id = null || '',
  type,
  defaultFilterToReset = null,
  addedQueryParams = {},
  generateCustomTitle = null,
  customYearBefore,
  ...rest
}) {
  const { config } = useUser();
  const {
    objectiveWeightType,
    myGoalsGroupByOptions,
    includeTeamGoals,
    objectiveWeightTypeOptions
  } = config;
  const { url } = useUrl();

  if (filterMenu?.some((option) => typeof option === 'string')) {
    filterMenu = filterMenu.map((option) => {
      if (typeof option === 'string') {
        return {
          option: kebabCase(option),
          title: startCase(option),
          dialog: kebabCase(option)
        };
      }
      return option;
    });
  }
  const defaultReducer = (oldState, action) => {
    const { state, type } = action;
    switch (type) {
      case 'SWITCH': {
        let selectedSwitchFilter = switchOptions.find(
          ({ id }) => id === state
        )?.filter;
        return { ...oldState, ...selectedSwitchFilter };
      }
      case 'RESET':
        return { ...state };
    }

    return { ...oldState, ...state };
  };
  const reducer = filterReducer ?? defaultReducer;
  const [group, setGroup] = useState(defaultGroup);
  const [showTask, setShowTask] = useState(defaultShowTask);
  const [isFirstRender, setFirstRender] = useState(true);
  const [filter, dispatchFilter] = useReducer(reducer, defaultFilter ?? {});
  const withFilter = (key) => filterOptions?.includes(key);
  let sortOptions = [
    {
      direction: 'asc',
      id: 0,
      name: `${getObjectiveLocale('Due Date')}`,
      type: 'due_date',
      visible: true
    },
    {
      direction: 'desc',
      id: 1,
      name: `${getObjectiveLocale('Newest')}`,
      type: 'created_at',
      visible: true
    },
    {
      direction: 'asc',
      id: 2,
      name: `${getObjectiveLocale('Oldest')}`,
      type: 'created_at',
      visible: true
    },
    {
      direction: 'asc',
      id: 3,
      name: `${getObjectiveLocale('A to Z')}`,
      type: 'name',
      visible: true
    },
    {
      direction: 'desc',
      id: 4,
      name: `${getObjectiveLocale('Z to A')}`,
      type: 'name',
      visible: true
    }
  ];
  const positionOptions = [
    { id: 'all', name: 'All', visible: true },
    { id: 'inactive', name: 'Previous', visible: true },
    { id: 'active', name: 'Current', visible: true }
  ];
  const sortValue = {
    direction: filter?.sortDirection,
    type: filter?.sortColumn
  };
  let groupOptions = [];

  const viewByOptions = [
    { id: 'team', name: 'Team', visible: true },
    { id: 'individual', name: 'Individual', visible: true }
  ];

  if (useConfig) {
    myGoalsGroupByOptions?.map((group, index) => {
      groupOptions.push({
        id: myGoalsGroupByOptions[index],
        name:
          myGoalsGroupByOptions[index] == 'no-group'
            ? 'None'
            : startCase(myGoalsGroupByOptions[index]),
        visible: true
      });
    });

    const isGoalTypeEnabled = includeTeamGoals
      ? objectiveWeightTypeOptions?.length != 2
      : !objectiveWeightTypeOptions?.includes('goal');

    if (objectiveWeightType != 'type' || isGoalTypeEnabled) {
      groupOptions = groupOptions.filter((group) => group.id !== 'goal-type');
    }
  } else {
    switch (type) {
      case 'task-list':
        groupOptions = [
          { id: 'no-group', name: 'None', visible: true },
          { id: 'phase', name: 'Phase', visible: true },
          { id: 'priority', name: 'Priority', visible: true }
        ];
        break;
      case 'task-board':
      case 'project-list':
      case 'project-board':
        groupOptions = [
          { id: 'phase', name: 'Phase', visible: true },
          { id: 'priority', name: 'Priority', visible: true }
        ];
        break;
      case 'task-list-show-project':
        groupOptions = [
          { id: 'phase', name: 'Phase', visible: true },
          { id: 'priority', name: 'Priority', visible: true },
          { id: 'section', name: 'Section', visible: true },
          { id: 'project', name: 'Project', visible: true }
        ];
        break;
      case 'task-board-show-project':
      case 'task-timeline':
      case 'project-timeline':
        groupOptions = [
          { id: 'phase', name: 'Phase', visible: true },
          { id: 'priority', name: 'Priority', visible: true },
          { id: 'section', name: 'Section', visible: true }
        ];
        break;
      case 'explorer': {
        groupOptions = [
          { id: 'no-group', name: 'None', visible: true },
          { id: 'top-parent', name: 'Top Parent', visible: true },
          { id: 'owner', name: 'Owner', visible: true }
        ];

        const selectedSwitch = filter?.switch?.split('-').join('_');
        const isGoalTypeEnabled =
          includeTeamGoals && filter?.switch == 'all'
            ? objectiveWeightTypeOptions?.length == 2
            : objectiveWeightTypeOptions?.includes(selectedSwitch);
        if (
          objectiveWeightType == 'type' &&
          isGoalTypeEnabled &&
          filter?.switch !== 'task' &&
          !url.includes('tasks')
        ) {
          groupOptions.splice(1, 0, {
            id: 'goal-type',
            name: 'Goal Type',
            visible: true
          });
        }
        break;
      }
      case 'scored-attribute':
        groupOptions = [
          { id: 'no-group', name: 'None', visible: true },
          { id: 'top-parent', name: 'Top Parent', visible: true },
          { id: 'goal-type', name: 'Goal Type', visible: true }
        ];
        break;
      default: {
        groupOptions = [
          { id: 'no-group', name: 'None', visible: true },
          { id: 'top-parent', name: 'Top Parent', visible: true },
          { id: 'manager', name: 'Manager', visible: true },
          { id: 'direct-report', name: 'Direct Report', visible: true }
        ];
        const selectedSwitch = filter?.switch?.split('-').join('_');
        const isGoalTypeEnabled =
          includeTeamGoals && filter?.switch == 'all'
            ? objectiveWeightTypeOptions?.length == 2
            : objectiveWeightTypeOptions?.includes(selectedSwitch);
        if (
          objectiveWeightType == 'type' &&
          isGoalTypeEnabled &&
          filter?.switch !== 'task' &&
          !url.includes('tasks')
        ) {
          groupOptions.splice(1, 0, {
            id: 'goal-type',
            name: 'Goal Type',
            visible: true
          });
        }
        if (type === 'others') {
          groupOptions.splice(3, 0, {
            id: 'owner',
            name: 'Owner',
            visible: true
          });
        }
        break;
      }
    }
  }

  let assignToOptions = [
    { id: 'me', name: 'Me', visible: true },
    { id: 'everyone', name: 'Everyone', visible: true }
  ];

  let showTaskOptions = [
    { id: 'personal', name: 'Personal Task', visible: true },
    { id: 'project', name: 'Project Task', visible: true }
  ];

  let restructuredFilter = restructureFilter
    ? restructureFilter(filter)
    : filter;
  const setFilter = useFilter((state) => state.setFilter);
  const setFilterById = useFilter((state) => state.setFilterById);
  const filterById = useFilter((state) => state.filterById);

  const onChangeGroup = (group) => {
    setGroup(group);
    dispatchFilter({ state: { group } });
  };

  const onChangeAssignTo = (assignTo) => {
    dispatchFilter({ state: { assignTo } });
  };

  const onChangeShowTask = (showTask) => {
    setShowTask(showTask);
    dispatchFilter({ state: { showTask } });
  };

  useEffect(() => {
    if (id) {
      if (filterById?.[id]) {
        setFilterById(id, restructuredFilter || {});
      }
    } else {
      setFilter(restructuredFilter);
    }
  }, [filter]);

  useEffect(() => {
    if (id) {
      if (filterById?.[id]) {
        dispatchFilter({ state: filterById?.[id] });
      } else {
        const restructuredFilter = restructureFilter
          ? restructureFilter(defaultFilter)
          : defaultFilter;
        setFilterById(id, restructuredFilter || {});
        dispatchFilter({ state: restructuredFilter });
      }
    }
  }, [id]);

  useEffect(() => {
    if (
      (type === 'task-board' || type === 'task-board-show-project') &&
      group === 'no-group'
    ) {
      onChangeGroup('phase');
    }
  }, [type]);

  useEffect(() => {
    if (type?.includes('task-board') && group === 'section') {
      onChangeGroup('phase');
    }
    if (type?.includes('task-list')) {
      if (
        showTask === 'personal' &&
        (group === 'section' || group === 'project')
      ) {
        onChangeGroup('no-group');
      }
      if (showTask === 'project' && group === 'no-group') {
        onChangeGroup('phase');
      }
    }
  }, [showTask]);

  useEffect(() => {
    setGroup(defaultGroup);
  }, [defaultGroup]);

  useEffect(() => {
    setShowTask(defaultShowTask);
  }, [defaultShowTask]);

  useDeepCompareEffect(() => {
    let storedFilter = { ...defaultFilter };

    if (isFirstRender) {
      storedFilter = {
        ...storedFilter,
        ...(withFilter('group') && {
          group: useFilterGroup
            ? filter?.group
            : filter?.group || group || groupOptions[0]
        }),
        ...(withFilter('showTask') && {
          showTask: useFilterShowTask
            ? filter?.showTask
            : filter?.showTask || showTask || showTaskOptions[0]
        })
      };
      setFirstRender(false);
    }
    dispatchFilter({ type: 'RESET', state: storedFilter });
  }, [defaultFilter]);

  return (
    <>
      {withFilter('showTask') && (
        <Show
          lists={showTaskOptions}
          showTask={showTask}
          onChange={(showTask) => onChangeShowTask(showTask)}
        />
      )}
      <div
        data-cy="advance-filter"
        style={gridArea ? { gridArea: 'filter' } : {}}
        className={`advance-filter ${rest?.customClass && rest?.customClass}`}
      >
        <div className="search-filter-wrapper">
          {withFilter('search') && (
            <div className="left-wrapper flex">
              <Search
                onKeyDown={(q) => dispatchFilter({ state: { q } })}
                onChange={(q) => dispatchFilter({ state: { q } })}
                defaultValue={filter?.q}
              />
            </div>
          )}
          <div className="right-wrapper flex items-center">
            {withFilter('group') && (
              <Group
                lists={groupOptions}
                defaultValue={
                  filter?.group
                    ? filter?.group
                    : group
                    ? group
                    : groupOptions[0]
                }
                onChange={(group) => onChangeGroup(group)}
              />
            )}
            {withFilter('position') && (
              <Position
                lists={positionOptions}
                defaultValue={filter?.state}
                onChange={(position) =>
                  dispatchFilter({ state: { state: position } })
                }
              />
            )}
            {withFilter('viewBy') && (
              <ViewBy
                options={viewByOptions}
                selectedValue={filter?.viewBy}
                onSelect={(id) => dispatchFilter({ state: { viewBy: id } })}
              />
            )}
            {withFilter('sort') && (
              <Sort
                options={sortList || sortOptions}
                selectedValue={sortValue}
                onSelect={({ direction, type }) =>
                  dispatchFilter({
                    state: { sortDirection: direction, sortColumn: type }
                  })
                }
              />
            )}
            {withFilter('timeGroup') && (
              <TimeGroup
                defaultValue={filter?.timeGroup}
                onSelect={(timeGroup) =>
                  dispatchFilter({ state: { timeGroup } })
                }
              />
            )}
            {withFilter('assignTo') && (
              <AssignTo
                lists={assignToOptions}
                defaultValue={filter?.assignTo || assignToOptions[0]}
                onChange={(assignTo) => onChangeAssignTo(assignTo)}
              />
            )}
            {withFilter('dateFilter') && (
              <DateFilter
                containerClassName="mx-[8px]"
                options={dateFilterOptions}
                selectedValue={[filter?.periodBegin, filter?.periodEndBefore]}
                onSelect={([periodBegin, periodEnd]) => {
                  dispatchFilter({
                    state: {
                      periodBegin: periodBegin,
                      periodEndBefore: periodEnd
                    }
                  });
                }}
                noFilterDate={noFilterDate}
                formatType={dateFormatType}
              />
            )}
            {withFilter('switcherIcon') && (
              <ContentSwitcherIcon
                switcherOptions={switchOptions}
                onChange={(type) => {
                  setGroup(defaultGroup);
                  dispatchFilter({ type: 'SWITCH', state: type });
                }}
                currentOption={filter?.switch}
              />
            )}
            {withFilter('yearFilter') && (
              <YearFilter
                onSelect={(year) => dispatchFilter({ state: { year } })}
                customYearBefore={customYearBefore}
              />
            )}
          </div>
        </div>
        {withFilter('menuFilter') && (
          <div className="wrapper-selected-filter">
            <div className="mr-[8px] mt-[8px] grid-filter">
              <MenuFilter
                options={filterMenu}
                filter={filter}
                dispatchFilter={dispatchFilter}
                defaultFilter={defaultFilter}
                resultModifier={resultModifier}
                filterClient={filterClient}
                generateCustomTitle={generateCustomTitle}
                suspense={suspense}
                dialogQueryFn={(params) =>
                  dialogQueryFn({ ...params, ...addedQueryParams })
                }
                immutableFilter={immutableFilter}
                defaultFilterToReset={defaultFilterToReset}
              />
            </div>
          </div>
        )}
        {withFilter('switch') && (
          <Switcher
            options={switchOptions}
            onChange={(type) => {
              setGroup(defaultGroup);
              dispatchFilter({ type: 'SWITCH', state: type });
            }}
            value={filter?.switch}
          />
        )}
        {childrenInFilter && childrenInFilter}
      </div>

      {children &&
        React.cloneElement(children, {
          filter: restructuredFilter,
          group: filter?.group ? filter?.group : group,
          withFilterMenu: withFilter('menuFilter'),
          switcherValue: filter?.switch,
          dispatchFilter
        })}
    </>
  );
}

export default AdvancedFilter;

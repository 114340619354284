import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { getListFilter } from 'client/adminClient';
// context
import { useUser } from 'context/UserContext';
import { useUsersAdmin } from 'context/admin/UsersAdminContext';
import { UsersAdminProvider } from 'context/admin/UsersAdminContext';
// hooks
import useFilter from 'hooks/useFilter';
import { useDeepEffect } from 'utils/useDeepEffect';

// Components
import NewManageUserTable from 'components/admin/NewManageUserTable';
import ModalShowMember from 'components/admin/groups/ModalShowMember.js';
import UserTableInfo from 'components/admin/groups/UserTableInfo.js';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';

const selectorFilter = (state) => state.filterById?.groupTableUserFilter;

const TableUser = ({
  isSelectAll,
  setIsSelectAll,
  isSelectAllRow,
  setIsSelectAllRow,
  listIdUser,
  setListIdUser,
  excludedId,
  setExcludedId,
  groupType,
  setTotalUser,
  involvedMembershipsByInfo
}) => {
  const { hasMore, totalUser, users, getUsersData, nextPage, prevPage } =
    useUsersAdmin();

  const { config } = useUser();

  const filter = useFilter(selectorFilter);
  const filteredUserList = users.map((user) => {
    if (
      user.groupName === 'default_behavior_group' ||
      user.groupName === 'default_review_group'
    ) {
      return { ...user, groupName: 'Default' };
    } else {
      return user;
    }
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isFirst, setFirst] = useState(true);
  const customColumn = [
    { name: 'Group', widthClass: 'w-[240px]', hide: false, key: 'groupName' }
  ];

  // FILTER
  const [currentSort, setCurrentSort] = useState({
    sortColumn: 'name',
    sortDirection: 'asc'
  });

  let filterMenu = config?.generalFilterOptions;

  const getUserParams = () => {
    const { ...allFilter } = filter || {};
    const query = {
      ...allFilter,
      state: ['active', 'pending'],
      userState: ['active', 'pending'],
      limit: 10,
      sortDirection: currentSort.sortDirection,
      sortColumn: currentSort.sortColumn,
      groupType
    };

    return query;
  };

  const getFilterData = async (addedParams) => {
    return await getListFilter({ q: addedParams?.q, key: addedParams?.key });
  };

  const _getListFilter = (addedParams) => {
    const fetchFilter = () => {
      return getListFilter(addedParams);
    };

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['filter', addedParams],
        ({ pageParam }) => fetchFilter(pageParam),
        {
          getNextPageParam: (lastPage, allPages) =>
            lastPage.pagination?.next || 0
        }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  // END OF FILTER
  const [showModalMembers, setShowModalMembers] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const _getUsersData = async (data) => {
    let body = {
      ...data,
      ...getUserParams()
    };
    setIsLoading(true);
    await getUsersData(body, true);
    setIsLoading(false);
  };

  const _prevPage = () => {
    setCurrentPage(currentPage - 1);
    prevPage(getUserParams(), true);
  };

  const _nextPage = () => {
    setCurrentPage(currentPage + 1);
    nextPage(getUserParams(), true);
    setIsSelectAllRow(false);
  };

  const handleUnCheckUser = (selectedId, user) => {
    selectedId = selectedId.filter((val) => val !== user.id);
    setIsSelectAllRow(false);

    if (isSelectAll) {
      setExcludedId([...excludedId, user.id]);
    }

    setListIdUser(selectedId);
  };

  const handleCheckUser = (selectedId, user) => {
    selectedId.push(user.id);
    if (selectedId.length == users.length) {
      setIsSelectAllRow(true);
    }

    if (isSelectAll) {
      let newArray = [...excludedId];
      newArray = newArray.filter((val) => val != user.id);
      setExcludedId(newArray);
    }

    setListIdUser(selectedId);
  };

  const handleSelectUser = (e, user) => {
    let selectedId = [...listIdUser];
    let checked = selectedId.includes(user.id);

    checked
      ? handleUnCheckUser(selectedId, user)
      : handleCheckUser(selectedId, user);
  };

  const handleSelectAllUsers = (isTriggeredByUseEffect) => {
    let arrayOfIds = users?.map((user) => user.id);

    if (isTriggeredByUseEffect) {
      arrayOfIds = arrayOfIds.filter((val) => !excludedId.includes(val));
    } else {
      setExcludedId([]);
      setIsSelectAll(true);
      setIsSelectAllRow(true);
    }

    setListIdUser([...new Set(arrayOfIds)]);
  };

  const handleSelectAllRow = (checked) => {
    let arrayOfIds = users?.map((user) => user.id);

    if (checked) {
      arrayOfIds = [...listIdUser, ...arrayOfIds];

      if (isSelectAll) {
        let newExcludeId = [...excludedId].filter(
          (val) => !arrayOfIds.includes(val)
        );
        setExcludedId(newExcludeId);
      }
    }

    if (!checked) {
      if (isSelectAll) {
        let newExcludeId = [...excludedId, ...arrayOfIds];
        setExcludedId([...new Set(newExcludeId)]);
      }

      arrayOfIds = [...listIdUser].filter((val) => !arrayOfIds.includes(val));
    }
    setListIdUser([...new Set(arrayOfIds)]);
    setIsSelectAllRow(checked);
  };

  const resetFunction = () => {
    setListIdUser([]);
    setExcludedId([]);
    setIsSelectAll(false);
    setIsSelectAllRow(false);
  };

  useEffect(() => {
    if (listIdUser.length != 0) {
      const isAllChildSelected = users?.every((user) =>
        listIdUser.includes(user.id)
      );
      setIsSelectAllRow(isAllChildSelected ? true : false);
    }

    if (isSelectAll) {
      handleSelectAllUsers(true);
      if (excludedId.length != 0) {
        const isAllChildSelected = users?.every(
          (user) => !excludedId.includes(user.id)
        );
        setIsSelectAllRow(isAllChildSelected ? true : false);
      }
    }

    if (totalUser) {
      setTotalUser(totalUser);
    }
  }, [users]);

  useEffect(() => {
    _getUsersData({ countAvailablePlacements: true });
    setFirst(false);
  }, []);

  // FILTER
  useDeepEffect(() => {
    if (!isFirst) {
      filter && _getUsersData({});
      setCurrentPage(1);
    }
  }, [filter, currentSort]);

  return (
    <div className="review-group-user-table">
      <AdvancedFilter
        filterOptions={['search', 'menuFilter']}
        filterMenu={filterMenu}
        dialogQueryFn={(params) => _getListFilter(params)}
        filterClient={getFilterData}
        id="groupTableUserFilter"
      />

      <UserTableInfo
        setShowModal={setShowModalMembers}
        isSelectAll={isSelectAll}
        handleSelectAll={handleSelectAllUsers}
        listIdUser={listIdUser}
        totalLength={totalUser}
        excludedId={excludedId}
        resetFunction={resetFunction}
        disableSelectClearAll={involvedMembershipsByInfo?.length > 0}
      />

      <NewManageUserTable
        usersList={filteredUserList}
        selectedUserIds={listIdUser}
        isHeaderChecked={isSelectAllRow}
        handleSelectUser={handleSelectUser}
        handleSelectAllRow={handleSelectAllRow}
        customHeaders={customColumn}
        currentSort={currentSort}
        setCurrentSort={setCurrentSort}
        involvedMembershipsByInfo={involvedMembershipsByInfo}
        currentPage={currentPage}
        handleNextPage={_nextPage}
        handlePrevPage={_prevPage}
        hasMore={hasMore}
        isLoading={isLoading}
      />

      {showModalMembers && (
        <UsersAdminProvider>
          <ModalShowMember
            setShowModalMembers={setShowModalMembers}
            listIdUser={listIdUser}
            excludedId={excludedId}
            isSelectAll={isSelectAll}
            withJobPlacement={true}
          />
        </UsersAdminProvider>
      )}
    </div>
  );
};

const GroupTableUser = (props) => (
  <UsersAdminProvider>
    <TableUser {...props} />
  </UsersAdminProvider>
);

export default GroupTableUser;

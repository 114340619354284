import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import omit from 'lodash/omit';

import { getBehaviors } from 'client/adminClient.js';
import { useUser } from 'context/UserContext';
import { useValueCompetency } from 'context/admin/ValueCompetencyContext';
import Config from 'utils/Config';

import AWSImage from 'components/design-system/AWSImage';
import SVGIcon from 'components/shared/SVGIcon';

import ShowMoreCard from './ShowMoreCard';
import SingleCardSkeletonLoading from './SingleCardSkeletonLoading';

const ChildModelCard = ({ value, id, modelId }) => {
  const { pathname } = useLocation();
  const { config } = useUser();
  const { paramsDictionaryOrModel, currentFilterTab, isValuesPage } =
    useValueCompetency();
  const [accordionActive, setAccordionActive] = useState(null);
  const [dataKeyBehavior, setDataKeyBehavior] = useState([]);
  const [singleLoading, setSingleLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [hasMoreChild, setHasMoreChild] = useState(false);
  const [nextOffsetChild, setNextOffsetChild] = useState(0);
  const [currentSizeChild, setCurrentSizeChild] = useState(0);

  const handleOpenChildAccordion = async (id) => {
    let active = accordionActive == id ? null : id;
    await _getChildData(active);
  };

  const _getChildData = async (id, refreshState) => {
    if (
      (dataKeyBehavior.length == 0 && !isDataEmpty) ||
      refreshState == 'refresh'
    ) {
      let params = paramsDictionaryOrModel(
        pathname,
        { modelId, parentId: id },
        'dictionary',
        true
      );
      let _params = omit(params, 'q');
      setSingleLoading(true);
      const { data, pagination } = await getBehaviors(_params);
      if (data) {
        setDataKeyBehavior(data);
        setHasMoreChild(pagination.nextOffset == null ? false : true);
        setNextOffsetChild(pagination.nextOffset);
        setCurrentSizeChild(pagination.currentSize + currentSizeChild);
        setSingleLoading(false);
      }
      if (data.length == 0) {
        setSingleLoading(false);
        setIsDataEmpty(true);
      }
    }
    setAccordionActive(id);
  };

  const loadMore = async () => {
    const _params = paramsDictionaryOrModel(
      pathname,
      {
        modelId,
        parentId: id,
        offset: nextOffsetChild
      },
      'dictionary',
      true
    );
    setLoadMoreLoading(true);
    const { data, pagination } = await getBehaviors(_params);
    if (data) {
      setDataKeyBehavior(dataKeyBehavior.concat(data));
      setHasMoreChild(pagination.nextOffset == null ? false : true);
      setNextOffsetChild(pagination.nextOffset);
      setCurrentSizeChild(pagination.currentSize + currentSizeChild);
      setLoadMoreLoading(false);
    }
  };

  useEffect(() => {
    setAccordionActive(null);
    setDataKeyBehavior([]);
  }, [currentFilterTab]);

  const sourceImage =
    value?.iconImageAttributes?.iconUrl || Config.asset.admin.defaultValueImg;

  return (
    <React.Fragment>
      <tr
        data-cy={'competencies-values-model-kb-' + id}
        className="row-table-child-model"
        onClick={() => handleOpenChildAccordion(id)}
      >
        <td valign="top" className="table-name">
          <div className="flex items-center">
            {value.hasKeyBehaviors && (
              <SVGIcon
                iconName="icon-chevron_right"
                size={16}
                fillColor="var(--n-600)"
                id={`icon-accordion-${id}`}
                customClass={`icon-accordion ${
                  accordionActive == id ? 'accordion-open' : ''
                }`}
              />
            )}

            {pathname.includes('values') && (
              <AWSImage className="icon-value ml-[16px]" src={sourceImage} />
            )}
            <p className={`typography-paragraph text-n-900 ml-[18px]`}>
              {value.title}
            </p>
          </div>
        </td>
        <td valign="top" colSpan="4" className="table-description">
          <p className="typography-paragraph text-n-900">{value.description}</p>
        </td>
      </tr>

      {singleLoading && <SingleCardSkeletonLoading isModelKeyBehavior={true} />}

      {isDataEmpty && accordionActive == id && (
        <tr className="table-row-empty-state">
          <td colSpan="5">No data is available</td>
        </tr>
      )}

      {!singleLoading &&
        accordionActive == id &&
        dataKeyBehavior &&
        dataKeyBehavior.map((keyValue, idx) => (
          <tr key={idx} className="body-table-key-behavior">
            <td
              valign="top"
              className={`table-name ${isValuesPage(
                pathname,
                'custom-padding'
              )}`}
            >
              <div className="flex flex-col">
                {config?.behaviorsFramework == 'proficiency_levels' && (
                  <div>
                    <p className="typography-h300 text-n-600 uppercase mb-[16px]">
                      Level {keyValue?.orderLevel + 1}
                    </p>
                    {keyValue.isExpected && (
                      <p className="typography-h100 text-base-600 ml-[10px]">
                        Expected
                      </p>
                    )}
                  </div>
                )}
                <p>{keyValue.title}</p>
              </div>
            </td>
            <td valign="top" colSpan="4" className="table-description">
              {config?.behaviorsFramework == 'proficiency_levels' && (
                <div className="mb-[32px]" />
              )}
              <p>{keyValue.description}</p>
            </td>
          </tr>
        ))}

      {!loadMoreLoading && hasMoreChild && accordionActive == id && (
        <ShowMoreCard
          dataCy="competencies-values-show-more-kb"
          parentClass={'body-table-key-behavior'}
          colSpan={'5'}
          funcOnClick={loadMore}
        />
      )}

      {loadMoreLoading && (
        <SingleCardSkeletonLoading isModelKeyBehavior={true} />
      )}
    </React.Fragment>
  );
};

export default ChildModelCard;

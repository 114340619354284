import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

function ViewBy({ options, onSelect, selectedValue }) {
  const selectedValueName =
    options.find((option) => option?.id === selectedValue)?.name ||
    options[0]?.name;

  return (
    <div className="ml-[8px]">
      <Dropdown>
        <Dropdown.Trigger dataCy="advance-view-by">
          <div className="flex justify-between items-center bg-n-000 h-[32px] py-[8px] pr-[4px] pl-[16px] w-[224px] border border-solid border-n-400 rounded-[4px]">
            <div className="flex items-center">
              <p className="mr-[4px] typography-h400">
                {getObjectiveLocale('View by')}:
              </p>
              <p className="typography-button">{selectedValueName}</p>
            </div>
            <SVGIcon
              size="24"
              fillColor="var(--n-600)"
              iconName="icon-unfold_more"
            />
          </div>
        </Dropdown.Trigger>
        <Dropdown.MenuItems customClass="w-full !w-[224px]" type="fixed">
          {options.map((list, index) => (
            <Dropdown.MenuItem
              key={index}
              onClick={() => onSelect(list?.id)}
              dataCy="list-view-by"
            >
              {list.name}
            </Dropdown.MenuItem>
          ))}
        </Dropdown.MenuItems>
      </Dropdown>
    </div>
  );
}

export default ViewBy;

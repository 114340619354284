import React from 'react';

import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';

import { getListFilter } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import { useGetListFilter } from 'hooks/api/useListFilter';
import useOnClickOutside from 'hooks/useClickOutside';

import Menu from 'components/advance-filter/Menu';

const FilterMenu = ({ refFilter, onClose, filter = {}, dispatchFilter }) => {
  useOnClickOutside(refFilter, () => onClose());

  const {
    config: { objectiveFilterOptions }
  } = useUser();

  let filterMenu = objectiveFilterOptions?.comparativeDashboard || [];
  if (filterMenu?.some((option) => typeof option === 'string')) {
    filterMenu = filterMenu?.map((option) => {
      if (typeof option === 'string') {
        return {
          option: kebabCase(option),
          title: startCase(option),
          dialog: kebabCase(option)
        };
      }
      return option;
    });
  }

  const _getListFilter = (addedParams) => {
    const params = {
      page: 'comparative_dashboard',
      ...addedParams
    };

    return useGetListFilter('objective-comparative', params, {
      getNextPageParam: (lastPage) => lastPage.pagination?.next || 0
    });
  };

  const getFilterData = async (addedParams) => {
    const params = {
      page: 'comparative_dashboard',
      q: addedParams?.q,
      key: addedParams?.key
    };

    return await getListFilter(params);
  };

  return (
    <div className="flex justify-center" ref={refFilter}>
      <React.Suspense fallback={() => <div></div>}>
        <Menu
          customClass="!left-auto !top-[60px]"
          filter={filter}
          options={filterMenu}
          dispatchFilter={dispatchFilter}
          filterClient={getFilterData}
          dialogQueryFn={(params) => _getListFilter(params)}
        />
      </React.Suspense>
    </div>
  );
};

export default FilterMenu;

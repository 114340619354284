import React from 'react';

import Sidebar from 'components/sidebar/Sidebar';
import useSidebar from 'src/hooks/useSidebar';
import ErrorBoundary from 'src/pages/ErrorBoundary.js';

const SidebarMyGoals = ({
  customClassname,
  reloadFunction,
  saveCallback,
  isEditable,
  team
}) => {
  const topSidebar = useSidebar((state) => state?.topSidebar);

  return (
    <div
      className={`sidebar-my-goals ${customClassname}`}
      style={{
        top: `${topSidebar || 0}px`,
        height: `calc(100vh - ${topSidebar || 0}px)`
      }}
    >
      <ErrorBoundary>
        <Sidebar
          reloadFunction={reloadFunction}
          saveCallback={saveCallback}
          isEditable={isEditable}
          team={team}
        />
      </ErrorBoundary>
    </div>
  );
};

export default SidebarMyGoals;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils.js';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

const CardAddProject = ({
  setOpenModalCreate,
  title = 'New Project',
  customHeight
}) => {
  return (
    <div
      className={`flex items-center justify-center bg-n-300 rounded-[4px] ${
        customHeight ? customHeight : 'min-h-[124px]'
      }`}
      data-cy={`card-add-${title.toLocaleLowerCase().replace(/ /g, '-')}`}
    >
      <Button.Tertiary onClick={() => setOpenModalCreate(true)}>
        <SVGIcon
          size="24"
          iconName="icon-add"
          fillColor="var(--base-600)"
          customClass="mr-[4px]"
        />
        {getObjectiveLocale(title)}
      </Button.Tertiary>
    </div>
  );
};

export default CardAddProject;

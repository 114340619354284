import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { getConfig } from 'client/admin/Config';
import { useUser } from 'context/UserContext';
import useOrganization from 'hooks/useOrganization';
import usePermission from 'hooks/usePermission';
import { formatTimezone } from 'utils/DateUtils';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import BannerBox from 'components/shared/BannerBox';
import InputField from 'components/shared/InputField';

import ModalSetDate from './ModalSetDate';
import {
  DropdownOrganization,
  RadioButtonOrganization,
  ToggleCard
} from './OrganizationHelper';

const OnGoingReview = ({ configParams }) => {
  const { configData, changeConfigData, changeOriginConfigData } =
    useOrganization((configData) => ({
      configData: configData.configData,
      changeConfigData: configData.changeConfigData,
      changeOriginConfigData: configData.changeOriginConfigData
    }));
  const { config } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    dayjs() || configData?.automateGoalRatingSince
  );

  const setDateValueHandler = (value) => {
    const date = formatTimezone(value, 'start');
    setCurrentDate(date);
  };
  const isReadOnly =
    usePermission('settingsOrganizationOngoingReview') === 'read_only';

  const listGeneral = [
    {
      element: 'input',
      label: 'Reminder Objective are going to Overdue (days)',
      placeholder: 'Reminder Objective are going to Overdue',
      handleChange: (e) =>
        changeConfigData({
          ...configData,
          autoRemindDays: parseInt(e.currentTarget.value || 0)
        }),
      value: configData?.autoRemindDays || 0,
      dataCy: 'autoRemindDays',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Custom Icon On Review'),
      cardDescription: getObjectiveLocale(
        'Using Custom Rating Icon (Star Icon)'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          customIconOnReview: !configData.customIconOnReview
        }),
      isChecked: configData?.customIconOnReview,
      dataCy: 'customIconOnReview',
      disabled: isReadOnly
    }
  ];
  const listRatingMechanism = [
    {
      element: 'radio',
      label:
        'This Configuration will be applied when user doing on-going review for any completed objectives.',
      handleChange: (option) =>
        changeConfigData({ ...configData, ratingMechanism: option }),
      selected: configData?.ratingMechanism,
      listOption: [
        {
          id: 'manual',
          title: 'Manual on-going review',
          isUseDefaultDesc: true
        },
        {
          id: 'progress_times_weight',
          title: 'Automatic rating',
          isChecked: !['manual'].includes(configData?.ratingMechanism),
          children: configData?.ratingMechanism !== 'manual' && (
            <DropdownOrganization
              onChange={(id) => {
                setShowModal(true);
                changeConfigData({
                  ...configData,
                  ratingMechanism: id
                });
              }}
              selected={configData?.ratingMechanism}
              isSingleSelect
              listOption={[
                {
                  id: 'progress_times_weight',
                  title: 'By Progress Percentage x Weight'
                },
                { id: 'automatic', title: 'Based on Performance Review Cycle' },
                { id: 'custom_formula', title: 'Based on Custom Formula' }
              ]}
              disabled={isReadOnly}
              customWrapperClass="ml-[24px]"
            />
          )
        }
      ]
    }
  ];

  let list = [{ id: 'general-section', name: 'General', list: listGeneral }];

  config?.scoredAttributesFeature &&
    list.push({
      id: 'rating-mechanism-section',
      name: 'Rating Mechanism',
      list: listRatingMechanism
    });

  const getData = async () => {
    const { data } = await getConfig(configParams);
    if (data) {
      changeConfigData(data);
      changeOriginConfigData(data);
    }
  };

  const submitModal = () => {
    changeConfigData({ ...configData, automateGoalRatingSince: currentDate });
    setShowModal(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="overflow-auto h-[calc(100vh-64px)] w-[87%]">
      <div className="w-[768px] mt-[24px]">
        <p className="typography-h300 text-n-800 uppercase mb-[24px]">
          {getObjectiveLocale('On Going Review Configuration')}
        </p>
        {list.map((el, index) => (
          <section
            className={`pb-[40px] mb-[40px] mt-[24px] ${
              index != list.length - 1 &&
              'border-solid border-b border-0 border-n-500'
            } `}
            id={el.id}
            key={index}
          >
            <p className="typography-h500 text-n-900 mb-[24px]">
              {getObjectiveLocale(el.name)}
            </p>
            {el.list?.length > 0 &&
              el.list.map((options, idx) =>
                options.element === 'toggle' ? (
                  <ToggleCard {...options} key={`${index}-${idx}`} />
                ) : options.element === 'input' ? (
                  <InputField
                    {...options}
                    inputClass="w-[250px]"
                    key={`${index}-${idx}`}
                  />
                ) : options.element === 'radio' ? (
                  <>
                    <RadioButtonOrganization
                      {...options}
                      key={`${index}-${idx}`}
                    />
                    {configData?.automateGoalRatingSince &&
                      configData?.ratingMechanism !== 'manual' && (
                        <BannerBox
                          type="info"
                          customClass="mt-[8px] ml-[24px]"
                          text={`This configuration will affect all the objectives from ${getDateLocale(
                            configData?.automateGoalRatingSince
                          )} onwards`}
                          paddingClass="px-[16px] py-[8px]"
                        />
                      )}
                  </>
                ) : null
              )}
          </section>
        ))}
      </div>
      {showModal && (
        <ModalSetDate
          title="Set Configuration Date"
          bodyText="The automatic rating configuration will affect all objectives from:"
          setDateValueHandler={setDateValueHandler}
          handleClose={() => setShowModal(false)}
          currentDate={currentDate}
          submitModal={submitModal}
        />
      )}
    </div>
  );
};

export default OnGoingReview;

import React, { useState } from 'react';

import { editObjective, getObjectivePhases } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';

import PhaseDropdownComponent from 'components/tasks/dropdown/PhaseDropdown';

const PhaseObjective = ({ objective, permissions }) => {
  const enableEdit = permissions?.includes('update');

  const { refetchObjective } = useRefetchQuery();
  const [listPhases, setListPhases] = useState([]);

  const onChangeHandler = async (phaseId) => {
    let body = { phaseId };
    const { isSuccess } = await editObjective(objective?.id, body);
    isSuccess && refetchObjective(objective?.id);
  };

  const getPhases = async () => {
    const { data } = await getObjectivePhases({
      taskId: objective?.id
    });
    if (data) {
      setListPhases(data);
    }
  };

  const beforeTrigger = async (e, setOpen) => {
    if (enableEdit) {
      e.stopPropagation();
      if (!e.shiftKey && !(e.ctrlKey || e.metaKey)) {
        await getPhases();
        setOpen();
      }
    }
  };

  return (
    <div className="w-[144px] flex" data-cy="list-detail-progress">
      <PhaseDropdownComponent
        task={objective}
        onChange={onChangeHandler}
        useTrigger
        beforeTrigger={beforeTrigger}
        customClass="w-full h-full"
        position="right"
        triggerContainerClass={'w-full'}
        listPhases={listPhases}
      />
    </div>
  );
};

export default PhaseObjective;

import React, { useEffect, useState } from 'react';

const ContextMenu = ({ children, mousePosition, menuId }) => {
  const [style, setStyle] = useState({});

  const getMenuPosition = (x = 0, y = 0) => {
    let menuStyles = {
      top: y,
      left: x
    };

    const { innerWidth, innerHeight } = window;
    const rect = document.getElementById(menuId)?.getBoundingClientRect();

    if (y + rect.height > innerHeight) {
      menuStyles.top -= rect.height;
    }

    if (x + rect.width > innerWidth) {
      menuStyles.left -= rect.width;
    }

    setStyle(menuStyles);
  };

  useEffect(() => {
    const { xPos, yPos } = mousePosition;
    getMenuPosition(xPos, yPos);
  }, []);

  return (
    <div style={{ ...style, position: 'fixed', zIndex: 99 }}>{children}</div>
  );
};

export default ContextMenu;

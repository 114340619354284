import React, { Fragment, useEffect, useState } from 'react';

import { getBehaviors } from 'client/adminClient.js';
import { capitalizeSentence } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';

function CompetencyTag({ objModel, id, isActive, onClick, behaviorType }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [object, setObject] = useState(null);

  const params = {
    state: 'active',
    behavior_type: behaviorType,
    ids: id
  };

  useEffect(() => {
    const fetchBehavior = async () => {
      const { data } = await getBehaviors(params);
      // should be setObject
      setObject(data?.[0]);
      return;
    };

    if (objModel) {
      setObject(objModel);
    } else {
      fetchBehavior();
    }
  }, []);

  return (
    <>
      {object && (
        <div className="relative inline-block" key={id}>
          {showTooltip && (
            <div
              className={`absolute bg-n-900 py-[4px] px-[8px] text-n-000 rounded-lg mb-[4px] bot-100`}
            >
              <p>{object.name || object.title}</p>
            </div>
          )}
          <Button.Secondary
            onClick={onClick}
            customClass={`mr-[8px] mb-[12px] ${
              isActive ? 'border-base-600 bg-base-100 text-base-600' : ''
            }`}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <span className="max-w-[328px] truncate">
              {(object && object.name) ||
                (object && capitalizeSentence(object.title))}
            </span>
          </Button.Secondary>
        </div>
      )}
    </>
  );
}

export default CompetencyTag;

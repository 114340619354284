import React from 'react';
import ReactDOM from 'react-dom';

import LoadingComponent from 'components/shared/LoadingComponent';

import './LoadingOverlay.scss';

const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="wrapper-content">
        <LoadingComponent />
      </div>
    </div>
  );
};

const LoadingOverlay = (props) => {
  let sharedModal = document.querySelectorAll(`[id^="loading-overlay"]`)[0];

  return ReactDOM.createPortal(<Loading {...props} />, sharedModal);
};

export default LoadingOverlay;

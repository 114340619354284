import React from 'react';

import { useUser } from 'context/UserContext';
import { getCultureLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';

import './ConfigurationBox.scss';

const LeftBar = ({
  menu = [],
  loading,
  selectedMenu,
  handleChangeMenu = () => null
}) => {
  const { appType } = useUser();

  return (
    <div className="configuration-box-left-bar flex flex-col w-[160px] pt-[8px]">
      {loading
        ? [1, 2, 4].map((id) => {
            return (
              <span key={id} className="px-[16px] py-[12px]">
                <Shimmer
                  height="100%"
                  width="130px"
                  widthRandomness={0.25}
                  circle
                />
              </span>
            );
          })
        : menu.map((item, index) => {
            return (
              <span
                key={index}
                className={`
                capitalize selection cursor-pointer px-[16px] py-[12px] 
            ${
              selectedMenu === item
                ? 'selected typography-h400 text-base-600'
                : 'text-n-800'
            }
          `}
                onClick={() => handleChangeMenu(item)}
              >
                {appType == 'culture'
                  ? getCultureLocale(item)
                  : getObjectiveLocale(item)}
              </span>
            );
          })}
    </div>
  );
};

export default LeftBar;

import client from './ApiClient';

function getNotificationList(query) {
  return client(`/v1/notifications`, { method: 'GET', params: query });
}

function getCultureNotificationList(query) {
  return client(`/culture/notifications`, { method: 'GET', params: query });
}

function getActivities(objectiveId, params) {
  let url = `v2/objectives/${objectiveId}/activities`;
  return client(url, { params: params });
}

function getTeamActivities(objectiveId, params) {
  let url = `v1/teams/${objectiveId}/activities`;
  return client(url, { params: params });
}

function seenNotificationCulture(notifId) {
  return client(`/culture/notifications/${notifId}/see`, {
    method: 'POST',
    body: {}
  });
}

export {
  getNotificationList,
  getCultureNotificationList,
  getActivities,
  getTeamActivities,
  seenNotificationCulture
};

import client from './ApiClient';

function getListBehavior(params) {
  let url = `v1/behaviors`;
  return client(url, { params: params });
}

function submitRecognitionFeedback(body) {
  let url = `/v1/recognition_feedback`;
  return client(url, { method: 'POST', body: body });
}

export { getListBehavior, submitRecognitionFeedback };

import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import produce, { setAutoFreeze } from 'immer';
import { v4 as uuidv4 } from 'uuid';

import { createMultipleObjective } from 'client/ObjectivesClient';
import { useMetrics } from 'context/MetricsContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import usePrevUser from 'hooks/usePrevUser';
import { formatTimezone, getSemester } from 'utils/DateUtils';
import {
  extractDefaultRollup,
  getMilestoneDefault
} from 'utils/ObjectivesHelper';

import { trackEvent } from 'src/utils/AnalyticUtils';

const CreateObjectiveContext = React.createContext();

const defaultPermissions = [
  'edit_name',
  'edit_description',
  'edit_weight',
  'edit_measurement_unit',
  'edit_roll_up',
  'edit_calculation_type',
  'edit_milestones',
  'edit_objective_category',
  'edit_tags',
  'edit_starting_value',
  'edit_target_value'
];

function onChangeAction(id, newValue, currentList) {
  currentList.map((list, index) => {
    if (list.id != id) {
      if (list.children) {
        onChangeAction(id, newValue, list.children);
      }
    } else {
      currentList[index] = { ...list, ...newValue };
    }
  });
  return currentList;
}

function onChangeObjective(id, newValue, currentList) {
  currentList.map((list, index) => {
    if (list.id != id) {
      if (list.children) {
        onChangeObjective(id, newValue, list.children);
      }
    } else {
      currentList[index] = newValue;
    }
  });
  return currentList;
}

function newGoal({
  user,
  locationState,
  fakeParentId,
  newMeasurement,
  objectiveCategoryId,
  config,
  level,
  defaultWeight,
  parentStartDate,
  parentDueDate,
  parentOjectiveCategoryId,
  isParent
}) {
  let isTeam = location.pathname.includes('/team') && level === 0;
  const {
    defaultObjectiveVisibilityOption,
    defaultPeriodOnCreate,
    defaultRollupForParentObjective,
    defaultRollupForParentTask,
    objectiveMilestonesFeature,
    objectiveWeightTypeOptions
  } = config;
  const id = uuidv4();
  const type = location.pathname.includes('task') ? 'task' : 'goal';
  const defaultRollUp =
    type == 'task'
      ? defaultRollupForParentTask
      : defaultRollupForParentObjective;

  const startDate =
    defaultPeriodOnCreate === 'semester'
      ? getSemester('start')
      : dayjs().startOf(defaultPeriodOnCreate);

  const dueDate =
    defaultPeriodOnCreate === 'day'
      ? dayjs().add(1, 'month')
      : defaultPeriodOnCreate === 'semester'
      ? getSemester('due')
      : dayjs().endOf(defaultPeriodOnCreate);

  let obj = {
    id: id,
    name: '',
    description: '',
    fakeParentId: fakeParentId,
    isPrivate: defaultObjectiveVisibilityOption.includes('private'),
    isCompanyGoal: user.isCompanyGoalCreator && locationState?.isCompanyGoal,
    isDirectorateGoal:
      user.isDirectorateGoalCreator && locationState?.isDirectorateGoal,
    isDepartmentGoal:
      user.isDepartmentGoalCreator && locationState?.isDepartmentGoal,
    isDivisionGoal: user.isDivisionGoalCreator && locationState?.isDivisionGoal,
    isProject: false,
    weight: defaultWeight,
    startDate: !fakeParentId
      ? formatTimezone(startDate.format('YYYY-MM-DD'), 'start')
      : parentStartDate,
    dueDate: !fakeParentId
      ? formatTimezone(dueDate.format('YYYY-MM-DD'), 'end')
      : parentDueDate,
    involvements: [],
    tags: [],
    complexity: 1,
    type: type,
    measurement: { ...newMeasurement },
    isShowAdvanced: false,
    calculationType: 'normal',
    objectiveCategoryId: isParent
      ? objectiveCategoryId
      : parentOjectiveCategoryId,
    permissions: defaultPermissions
  };
  const goalType = isTeam ? 'team_goal' : type;
  const isAllowedObjectiveCategory =
    objectiveWeightTypeOptions.includes(goalType);
  if (!isAllowedObjectiveCategory) {
    delete obj.objectiveCategoryId;
  }

  if (objectiveMilestonesFeature && type !== 'task') {
    Object.assign(obj, {
      milestones: getMilestoneDefault(),
      milestoneType: 'disabled',
      milestoneMode: 'sum_value'
    });
  }

  if (fakeParentId === null) {
    obj.measurement.rollUp = defaultRollUp;
  }

  if (type == 'task' && !fakeParentId) {
    obj.startDate = formatTimezone(dayjs().format('YYYY-MM-DD'), 'start');
    obj.dueDate = formatTimezone(
      dayjs().add(7, 'day').endOf('day').format('YYYY-MM-DD'),
      'end'
    );
  }

  return obj;
}

function newSubGoal({
  user,
  locationState,
  fakeParentId,
  newMeasurement,
  objectiveCategoryId,
  config,
  defaultWeight,
  parentStartDate,
  parentDueDate,
  parentObjectiveCategoryId
}) {
  const {
    defaultObjectiveVisibilityOption,
    defaultRollupForParentObjective,
    defaultRollupForParentTask,
    objectiveMilestonesFeature
  } = config;
  const id = uuidv4();
  const type = location.pathname.includes('task') ? 'task' : 'goal';
  const defaultRollUp =
    type == 'task'
      ? defaultRollupForParentTask
      : defaultRollupForParentObjective;

  let obj = {
    id: id,
    name: '',
    fakeParentId: fakeParentId,
    description: '',
    isPrivate: defaultObjectiveVisibilityOption.includes('private'),
    isCompanyGoal: user.isCompanyGoalCreator && locationState?.isCompanyGoal,
    isDirectorateGoal:
      user.isDirectorateGoalCreator && locationState?.isDirectorateGoal,
    isDepartmentGoal:
      user.isDepartmentGoalCreator && locationState?.isDepartmentGoal,
    isDivisionGoal: user.isDivisionGoalCreator && locationState?.isDivisionGoal,
    isProject: false,
    weight: defaultWeight,
    startDate: parentStartDate,
    dueDate: parentDueDate,
    involvements: [],
    tags: [],
    complexity: 1,
    type: type,
    measurement: { ...newMeasurement },
    isShowAdvanced: false,
    objectiveCategoryId: parentObjectiveCategoryId,
    calculationType: 'normal',
    permissions: defaultPermissions
  };

  if (objectiveMilestonesFeature && type !== 'task') {
    Object.assign(obj, {
      milestones: getMilestoneDefault(),
      milestoneType: 'disabled',
      milestoneMode: 'sum_value'
    });
  }

  if (fakeParentId === null) {
    obj.measurement.rollUp = defaultRollUp;
  }

  return obj;
}

function onFocusAddSibling({
  id,
  currentList,
  currentValue,
  level,
  fakeParentId,
  newParentMeasurement,
  newChildMeasurement,
  objectiveCategoryId,
  config,
  defaultWeight,
  user,
  locationState
}) {
  if (fakeParentId == null) {
    if (
      currentList[currentList.length - 1].name != '' ||
      currentList[currentList.length - 1].id == id
    ) {
      currentList.push(
        newGoal({
          user,
          locationState,
          fakeParentId,
          newMeasurement: newParentMeasurement,
          objectiveCategoryId,
          config,
          level,
          defaultWeight,
          isParent: true
        })
      );
    }
    onFocusAddChildren({
      id,
      currentList,
      level,
      newMeasurement: newChildMeasurement,
      objectiveCategoryId,
      config,
      defaultWeight,
      user,
      locationState
    });
  } else {
    currentList.map((list) => {
      if (list.id == fakeParentId) {
        const parentStartDate = list.startDate;
        const parentDueDate = list.dueDate;
        const parentOjectiveCategoryId = list.objectiveCategoryId;
        if (
          list.children[list.children.length - 1].id == id ||
          !list.children.some((child) => child.name === '')
        ) {
          list.children.push(
            newGoal({
              user,
              locationState,
              fakeParentId,
              newMeasurement: newChildMeasurement,
              objectiveCategoryId,
              config,
              level,
              defaultWeight,
              parentStartDate,
              parentDueDate,
              parentOjectiveCategoryId
            })
          );
        }
        onFocusAddChildren({
          id,
          currentList: list.children,
          level,
          newMeasurement: newChildMeasurement,
          objectiveCategoryId,
          config,
          defaultWeight,
          user,
          locationState
        });
      } else if (list.children) {
        onFocusAddSibling({
          id,
          currentList: list.children,
          currentValue,
          level,
          fakeParentId,
          newParentMeasurement,
          newChildMeasurement,
          objectiveCategoryId,
          config,
          defaultWeight,
          user,
          locationState
        });
      }
    });
  }
  return currentList;
}

function onFocusAddChildren({
  id,
  currentList,
  level,
  newMeasurement,
  objectiveCategoryId,
  config,
  defaultWeight,
  user,
  locationState
}) {
  currentList.map((list) => {
    if ((list.id == id) & (level < 3)) {
      const parentStartDate = list.startDate;
      const parentDueDate = list.dueDate;
      const parentObjectiveCategoryId = list.objectiveCategoryId;
      if (!list.children) {
        list.children = [
          newSubGoal({
            user,
            locationState,
            fakeParentId: list.id,
            newMeasurement,
            objectiveCategoryId,
            config,
            defaultWeight,
            parentStartDate,
            parentDueDate,
            parentObjectiveCategoryId
          })
        ];
      } else if (!list.children.some((child) => child.name === '')) {
        list.children.push(
          newSubGoal({
            user,
            locationState,
            fakeParentId: list.id,
            newMeasurement,
            objectiveCategoryId,
            config,
            defaultWeight,
            parentStartDate,
            parentDueDate,
            parentObjectiveCategoryId
          })
        );
      }
    }
  });
  return currentList;
}

function deleteById(id, currentList) {
  for (var i = 0; i < currentList.length; i++) {
    if (currentList[i].id === id) {
      currentList.splice(i, 1);
    } else if (currentList[i].children) {
      deleteById(id, currentList[i].children);
    }
  }
  return currentList;
}

function restructureObjective(li) {
  for (var i = 0; i < li.length; i++) {
    if (li[i].weight == '') {
      li[i].weight = 0;
    }
    li[i].involvements.map((involvement) => delete involvement.user);
    li[i].milestoneType = li[i].milestoneType?.toLowerCase();
    if (li[i].milestoneType == 'disabled') {
      delete li[i].milestones;
    }
    if (li[i].name === '') {
      li.splice(i, 1);
    } else if (li[i].children) {
      restructureObjective(li[i].children);
    }
  }
  return li;
}

// MUTATION
function useCreateObjective() {
  const [
    {
      lists,
      groupedList,
      singleObjective,
      isSuccess,
      isError,
      errorMessage,
      errorCode,
      manager,
      defaultWeight,
      isGrouped,
      activeGroup,
      keyIntegrationList
    },
    immerSetState
  ] = useContext(CreateObjectiveContext);
  const { reload, reloadTeam } = useReload();

  const { user, config } = useUser();
  const prevUser = usePrevUser((state) => state?.user);
  const {
    objectiveWeightType,
    defaultRollupForParentObjective,
    defaultRollupForParentTask
  } = config;
  const { invalidateQueries } = useRefetchQuery();
  const params = useParams();
  const history = useHistory();
  const locationState = history?.location?.state;
  const prevPath = locationState?.prevPath;
  let isFromProfile = prevPath?.includes('/users');

  const newUser = {
    role: 'assignee',
    user: isFromProfile ? prevUser : user,
    userId: isFromProfile ? prevUser?.id : user?.id,
    visible: true,
    placementId: isFromProfile ? prevUser?.placementId : user?.placementId
  };

  const newLeader = {
    role: 'leader',
    user: isFromProfile ? prevUser : user,
    userId: isFromProfile ? prevUser?.id : user?.id
  };

  const { listMetrics, objectiveCategories } = useMetrics() || {};

  const defaultObjectiveCategory =
    objectiveCategories?.find(({ isDefault }) => isDefault === true) ||
    objectiveCategories?.[0];
  let objectiveCategoryId =
    objectiveWeightType === 'type' && config.useDefaultObjectiveCategory
      ? defaultObjectiveCategory?.id
      : null;

  const type = location.pathname.includes('task') ? 'task' : 'goal';
  let detailDefaultMetric = listMetrics?.find(
    ({ isDefaultForGoal, isDefaultForTask }) =>
      type === 'goal' ? isDefaultForGoal : isDefaultForTask
  );
  const defaultRollUp =
    type == 'task'
      ? defaultRollupForParentTask
      : defaultRollupForParentObjective;

  let newParentMeasurement = {
    startingValue: 0,
    targetValue: 100,
    rollUp:
      defaultRollUp ||
      extractDefaultRollup(detailDefaultMetric?.defaultRollUp) ||
      'disabled',
    unitId: detailDefaultMetric?.id || listMetrics?.[0].id
  };
  let newChildMeasurement = {
    startingValue: 0,
    targetValue: 100,
    rollUp:
      defaultRollUp ||
      extractDefaultRollup(detailDefaultMetric?.defaultRollUp) ||
      'disabled',
    unitId: detailDefaultMetric?.id || listMetrics?.[0].id
  };

  function addNewCategories({
    groupId,
    id,
    level,
    fakeParentId,
    currentValue
  }) {
    const groupedIndex = groupedList.findIndex((list) => list.id == groupId);
    let groupedListTemp = [...groupedList];
    const objectiveCategory = objectiveCategories?.find(
      (category) =>
        category.id == groupedListTemp[groupedIndex]?.objectiveCategoryId
    );
    let newList = onFocusAddSibling({
      id,
      currentList: groupedListTemp[groupedIndex]?.lists,
      currentValue,
      level,
      fakeParentId,
      newParentMeasurement,
      newChildMeasurement,
      objectiveCategoryId: groupedListTemp[groupedIndex]?.objectiveCategoryId,
      config,
      defaultWeight: objectiveCategory?.weight || 0,
      user,
      locationState
    });

    groupedListTemp[groupedIndex] = {
      ...groupedListTemp[groupedIndex],
      lists: newList
    };

    let totalAvailableCategories = objectiveCategories.length;
    if (!config?.mandatoryObjectivesCategory) {
      totalAvailableCategories++;
    }
    if (groupedList.length >= totalAvailableCategories) {
      groupedListTemp[groupedIndex].lists = newList;
      return groupedListTemp;
    }

    if (level == 0 && !groupedList[groupedIndex + 1]) {
      let categoryId = null;
      let mandatoryWeight = 0;

      const newList = newGoal({
        user,
        locationState,
        newMeasurement: newParentMeasurement,
        objectiveCategoryId: categoryId,
        config,
        level,
        defaultWeight: mandatoryWeight,
        isParent: true
      });
      groupedListTemp.push({
        id: uuidv4(),
        objectiveCategoryId: categoryId,
        lists: [newList]
      });
    }
    return groupedListTemp;
  }

  function changeValue(id, newValue) {
    //SETTER
    if (isGrouped && activeGroup) {
      let groupedListTemp = [...groupedList];
      const edittedListIndex = groupedListTemp.findIndex(
        (list) => list.id == activeGroup
      );
      let newList = onChangeAction(
        id,
        newValue,
        groupedListTemp[edittedListIndex].lists
      );
      groupedListTemp[edittedListIndex].lists = newList;
      immerSetState((draft) => {
        draft.groupedList = [...groupedListTemp];
        draft.singleObjective = { ...draft.singleObjective, ...newValue };
      });
    } else {
      let newList = onChangeAction(id, newValue, lists);
      immerSetState((draft) => {
        draft.lists = [...newList];
        draft.singleObjective = { ...draft.singleObjective, ...newValue };
      });
    }
  }

  function changeListCategoryId({ lists, categoryId, categoryWeight }) {
    let resultList = [...lists];

    const editRecursive = (lists) => {
      for (let i = 0; i < lists?.length; i++) {
        lists[i].objectiveCategoryId = categoryId;
        lists[i].weight = categoryWeight;
        if (lists[i]?.children?.length > 0) {
          editRecursive(lists[i].children);
        }
      }
    };

    editRecursive(resultList);
    return resultList;
  }

  function changeGroupType({ id, categoryId, categoryWeight }) {
    let groupedListTemp = [...groupedList];
    const edittedListIndex = groupedListTemp.findIndex((list) => list.id == id);

    if (errorMessage && errorCode == 'empty-type') {
      let errors = [...errorMessage];
      let filteredErrors =
        errors.filter((err) => err.index != edittedListIndex) || null;
      immerSetState((draft) => {
        draft.isError = filteredErrors ? true : false;
        (draft.errorMessage = filteredErrors),
          (draft.errorCode = filteredErrors ? 'empty-type' : null);
      });
    }
    groupedListTemp[edittedListIndex].objectiveCategoryId = categoryId;
    const newLists = changeListCategoryId({
      lists: groupedListTemp[edittedListIndex].lists,
      categoryId,
      categoryWeight
    });
    groupedListTemp[edittedListIndex].lists = newLists;
    immerSetState((draft) => {
      draft.groupedList = [...groupedListTemp];
      draft.singleObjective = {
        ...draft.singleObjective,
        ...(activeGroup == id && {
          objectiveCategoryId: categoryId,
          weight: categoryWeight
        })
      };
    });
  }

  async function addChildGrouped(
    id,
    level,
    fakeParentId,
    currentValue,
    objective,
    groupId
  ) {
    let isTeam = location.pathname.includes('/team') && level === 0;

    if (objective.involvements.length === 0) {
      objective.involvements.push(isTeam ? newLeader : newUser);

      if (manager?.id) {
        const newReviewer = {
          role: 'assigner',
          user: manager,
          userId: manager?.id,
          visible: true
        };
        objective.involvements.push(newReviewer);
      }
    }
    let newGroupedList = addNewCategories({
      groupId,
      id,
      level,
      fakeParentId,
      currentValue
    });
    immerSetState((draft) => {
      draft.groupedList = [...newGroupedList];
      draft.singleObjective = objective;
      draft.activeGroup = groupId;
    });
  }

  async function addChild(id, level, fakeParentId, currentValue, objective) {
    if (objective.involvements.length === 0) {
      objective.involvements.push(newUser);

      if (manager?.id) {
        const newReviewer = {
          role: 'assigner',
          user: manager,
          userId: manager?.id,
          visible: true
        };
        objective.involvements.push(newReviewer);
      }
    }

    let newList = onFocusAddSibling({
      id,
      currentList: lists,
      currentValue,
      level,
      fakeParentId,
      newParentMeasurement,
      newChildMeasurement,
      objectiveCategoryId,
      config,
      defaultWeight,
      user,
      locationState
    });
    immerSetState((draft) => {
      draft.lists = [...newList];
      draft.singleObjective = objective;
    });
  }

  function deleteGoal(id, level) {
    document.getElementById('panel-container-create-goal').blur();
    let singleObjective = newGoal({
      user,
      locationState,
      newMeasurement: newParentMeasurement,
      objectiveCategoryId,
      config,
      level,
      defaultWeight
    });
    if (isGrouped) {
      let groupedListTemp = [...groupedList];
      const edittedListIndex = groupedListTemp.findIndex(
        (list) => list.id == activeGroup
      );
      const isNextEmpty =
        groupedListTemp[edittedListIndex + 1]?.lists?.length == 1;
      let newList = deleteById(id, groupedListTemp[edittedListIndex].lists);
      if (newList.length == 1 && newList[0].name == '') {
        if (isNextEmpty) {
          groupedListTemp.splice(edittedListIndex + 1, 1);
        } else {
          groupedListTemp = groupedListTemp.filter(
            (list) => list.id != activeGroup
          );
        }
      } else {
        groupedListTemp[edittedListIndex].lists = newList;
      }
      immerSetState((draft) => {
        draft.groupedList = [...groupedListTemp];
        draft.singleObjective = singleObjective;
        draft.activeGroup = null;
      });
    } else {
      let newList = deleteById(id, lists);
      immerSetState((draft) => {
        draft.lists = [...newList];
        draft.singleObjective = singleObjective;
      });
    }
  }

  function handleChangeObjective(id, newObjective) {
    if (isGrouped && activeGroup) {
      let groupedListTemp = [...groupedList];
      const edittedListIndex = groupedListTemp.findIndex(
        (list) => list.id == activeGroup
      );
      let newList = onChangeAction(
        id,
        newObjective,
        groupedListTemp[edittedListIndex].lists
      );
      groupedListTemp[edittedListIndex].lists = newList;
      immerSetState((draft) => {
        draft.groupedList = [...groupedListTemp];
        draft.singleObjective = { ...draft.singleObjective, ...newObjective };
      });
    } else {
      let newList = onChangeObjective(id, newObjective, lists);

      immerSetState((draft) => {
        draft.lists = [...newList];
        draft.singleObjective = { ...draft.singleObjective, ...newObjective };
      });
    }
  }

  function removeVisible(listData) {
    listData.map((list) => {
      if (list.involvements) {
        list.involvements.map((data) => delete data.visible);
      }

      if (list?.measurement?.unitId == 0) {
        delete list.measurement;
      }

      if (list?.children?.length > 0) {
        removeVisible(list.children);
      }
    });
  }
  async function saveGoal() {
    const type = location.pathname.includes('task') ? 'task' : 'goal';
    let newList = [];
    if (isGrouped) {
      let emptyType = [];
      groupedList.map((group, index) => {
        if (
          config?.mandatoryObjectivesCategory &&
          group?.objectiveCategoryId == null &&
          group?.lists?.length > 1
        ) {
          emptyType = [
            ...emptyType,
            {
              message: 'Goal type must be selected',
              index,
              is_error: true,
              children: []
            }
          ];
        }
        const extractedObj = restructureObjective(
          JSON.parse(JSON.stringify(group.lists))
        );
        newList = [...newList, ...extractedObj];
      });
      if (emptyType.length > 0) {
        immerSetState((draft) => {
          draft.isError = true;
          (draft.errorMessage = emptyType), (draft.errorCode = 'empty-type');
        });
        return;
      }
    } else {
      newList = restructureObjective(JSON.parse(JSON.stringify(lists)));
    }
    let listPayload = newList;
    let teamId = params ? parseInt(params.teamId) : null;

    removeVisible(listPayload);

    if (teamId) {
      listPayload.map((list) => (list.teamId = teamId));
    }

    let saveObjective = createMultipleObjective;

    const checkChildIntegrations = async (dataIntegration) => {
      const dataCurrentIntegration = dataIntegration?.integrations?.[0];
      const keyIntegration = dataCurrentIntegration?.data?.key;
      keyIntegration &&
        dataCurrentIntegration?.importChildIssues &&
        keyIntegrations.push(keyIntegration);
      if (dataIntegration?.children?.length > 0) {
        dataIntegration?.children?.map(
          async (child) => await checkChildIntegrations(child)
        );
      }
    };

    let keyIntegrations = [];
    if (
      (type == 'task' && config?.taskManagementFeature) ||
      config?.enableJiraIntegration
    ) {
      listPayload.map((list) => {
        if (
          list?.blockedBy?.length > 0 &&
          type == 'task' &&
          config?.taskManagementFeature
        ) {
          list.blockedBy = list.blockedBy.map(({ id }) => id);
        }
        if (config?.enableJiraIntegration) {
          checkChildIntegrations(list);
        }
      });
    }

    const { isSuccess, error, data } = await saveObjective(listPayload);

    if (isSuccess) {
      const {
        assigneesArr,
        listAssigneeUserId,
        reviewerId,
        followersArr,
        followersUserId
      } = createObjectiveHelper(listPayload);

      // invalidate task data in new task management page;
      data?.length > 0 &&
        data?.map((objective) => {
          if (objective?.type === 'task') {
            const phaseName = objective?.phase?.name?.toLowerCase();
            const priorityName = objective?.priority?.name?.toLowerCase();

            invalidateQueries(['objectives', 'mytasks', phaseName], {
              refetchActive: false
            });
            invalidateQueries(['objectives', 'mytasks', priorityName], {
              refetchActive: false
            });
          }
        });

      if (data.length > 1)
        trackEvent({
          event: 'create multiple objectives',
          eventProperties: {
            status: 'success',
            'total objectives': data.length
          }
        });
      if (data.length == 1)
        trackEvent({
          event: 'create objective',
          eventProperties: {
            status: 'success',
            source: 'direct',
            weight: data[0].weight || '',
            complexity: data[0].complexity || '',
            'auto sums': data[0].measurement?.rollUp || '',
            'goal-parent': data[0].parent || '',
            'total assignees': assigneesArr.length,
            'assignees id': listAssigneeUserId || '',
            'reviewer id': reviewerId || '',
            metric: data[0].measurement?.description || '',
            labels: data[0].tags || '',
            'total followers': followersArr.length,
            'followers id': followersUserId || '',
            recurring: data[0].recurrence?.type || 'Does Not Repeat',
            grouped: false,
            type: data[0].type || '',
            'start date': data[0].startDate || '',
            'due date': data[0].dueDateDate || ''
          }
        });
      immerSetState((draft) => {
        draft.isSuccess = true;
        draft.keyIntegrationList = keyIntegrations;
      });
      localStorage.setItem('prevUrl', 'create-objective');
    } else {
      let errors = [];
      if (isGrouped) {
        errors = [];
        let indexCounter = 0;
        for (let i = 0; i < groupedList.length; i++) {
          if (groupedList[i]?.lists?.length > 1) {
            let parentErrors = [];
            for (let j = 0; j < groupedList[i].lists.length - 1; j++) {
              parentErrors.push(error.message[indexCounter]);
              indexCounter++;
            }
            errors.push(parentErrors);
          }
        }
      } else {
        errors = error.message;
      }

      immerSetState((draft) => {
        draft.isError = true;
        (draft.errorMessage = errors), (draft.errorCode = error.code);
      });
    }
  }

  const createObjectiveHelper = (listPayload, isTeam) => {
    let assigneesArr,
      listAssigneeUserId,
      reviewerId,
      followersArr,
      followersUserId;

    const assignees = listPayload[0].involvements.filter(
      (v) => v.role === 'assignee'
    );
    if (assignees) {
      assigneesArr = assignees.map((v) => v.userId);
      listAssigneeUserId = assigneesArr.join(',');
    }

    const followers = listPayload[0].involvements.filter(
      (v) => v.role === 'follower'
    );
    followersArr = followers.map((v) => v.userId);
    followersUserId = followersArr.join(',');

    reviewerId = listPayload[0].involvements.filter(
      (v) => v.role === 'assigner'
    )[0]?.userId;

    return {
      assigneesArr,
      listAssigneeUserId,
      reviewerId,
      followersArr,
      followersUserId
    };
  };

  function setIsSuccess(isSuccess) {
    immerSetState((draft) => {
      draft.isSuccess = isSuccess;
      draft.keyIntegrationList = [];
    });
  }

  function setIsError(isError) {
    immerSetState((draft) => {
      draft.isError = isError;
    });
  }

  return {
    lists,
    changeValue,
    addChild,
    deleteGoal,
    singleObjective,
    handleChangeObjective,
    saveGoal,
    isSuccess,
    isError,
    setIsSuccess,
    setIsError,
    errorMessage,
    errorCode,
    groupedList,
    addChildGrouped,
    changeGroupType,
    isGrouped,
    keyIntegrationList
  };
}

function CreateObjectiveProvider(props) {
  const { user, config, manager } = useUser();
  const prevUser = usePrevUser((state) => state?.user);
  const prevManager = usePrevUser((state) => state?.manager);
  const {
    objectiveWeightType,
    defaultObjectiveVisibilityOption,
    defaultPeriodOnCreate,
    defaultRollupForParentObjective,
    defaultRollupForParentTask,
    objectiveMilestonesFeature,
    objectiveWeightTypeOptions
  } = config;
  const history = useHistory();
  const locationState = history?.location?.state;
  const prevPath = locationState?.prevPath;
  let isTeam = location.pathname.includes('/team');
  let isFromProfile = prevPath?.includes('/users');

  const newUser = {
    role: 'assignee',
    user: isFromProfile ? prevUser : user,
    userId: isFromProfile ? prevUser?.id : user?.id,
    visible: true,
    placementId: isFromProfile ? prevUser?.placementId : user?.placementId
  };
  const newInvolvements = [newUser];

  if (manager?.id || (isFromProfile && prevManager?.id)) {
    const newReviewer = {
      role: 'assigner',
      user: isFromProfile ? prevManager : manager,
      userId: isFromProfile ? prevManager?.id : manager?.id,
      visible: true
    };
    newInvolvements.push(newReviewer);
  }
  const type = location.pathname.includes('task') ? 'task' : 'goal';
  const { listMetrics, objectiveCategories } = useMetrics() || {};

  const goalType = isTeam ? 'team_goal' : type;
  const isAllowedObjectiveCategory =
    objectiveWeightTypeOptions.includes(goalType);
  const defaultObjectiveCategory =
    objectiveCategories?.find(({ isDefault }) => isDefault === true) ||
    objectiveCategories?.[0];
  let objectiveCategoryId =
    objectiveWeightType === 'type' &&
    isAllowedObjectiveCategory &&
    config?.useDefaultObjectiveCategory
      ? defaultObjectiveCategory?.id
      : null;
  let detailDefaultMetric = listMetrics?.find(
    ({ isDefaultForGoal, isDefaultForTask }) =>
      type === 'goal' ? isDefaultForGoal : isDefaultForTask
  );
  const defaultRollUp =
    type == 'goal'
      ? defaultRollupForParentObjective
      : defaultRollupForParentTask;
  let newParentMeasurement = {
    startingValue: 0,
    targetValue: 100,
    rollUp:
      defaultRollUp ||
      extractDefaultRollup(detailDefaultMetric?.defaultRollUp) ||
      'disabled',
    unitId: detailDefaultMetric?.id || listMetrics?.[0]?.id
  };
  let newChildMeasurement = {
    startingValue: 0,
    targetValue: 100,
    rollUp:
      defaultRollUp ||
      extractDefaultRollup(detailDefaultMetric?.defaultRollUp) ||
      'disabled',
    unitId: detailDefaultMetric?.id || listMetrics?.[0]?.id
  };

  let singleObjectiveId = '1-1';
  let defaultWeight =
    (objectiveWeightType === 'type' &&
      config.useDefaultObjectiveCategory &&
      defaultObjectiveCategory?.weight) ||
    0;

  const startDate =
    defaultPeriodOnCreate === 'semester'
      ? getSemester('start')
      : dayjs().startOf(defaultPeriodOnCreate);

  const dueDate =
    defaultPeriodOnCreate === 'day'
      ? dayjs().add(1, 'month')
      : defaultPeriodOnCreate === 'semester'
      ? getSemester('due')
      : dayjs().endOf(defaultPeriodOnCreate);

  let singleObjective = {
    id: singleObjectiveId,
    name: '',
    description: '',
    fakeParentId: null,
    isPrivate: defaultObjectiveVisibilityOption.includes('private'),
    isCompanyGoal: user?.isCompanyGoalCreator && locationState?.isCompanyGoal,
    isDirectorateGoal:
      user?.isDirectorateGoalCreator && locationState?.isDirectorateGoal,
    isDepartmentGoal:
      user?.isDepartmentGoalCreator && locationState?.isDepartmentGoal,
    isDivisionGoal:
      user?.isDivisionGoalCreator && locationState?.isDivisionGoal,
    isProject: prevPath === '/projects',
    weight: defaultWeight,
    startDate: formatTimezone(startDate.format('YYYY-MM-DD'), 'start'),
    dueDate: formatTimezone(dueDate.format('YYYY-MM-DD'), 'end'),
    involvements: newInvolvements,
    tags: [],
    complexity: 1,
    type: type,
    measurement: newParentMeasurement,
    children: [],
    isShowAdvanced: false,
    calculationType: 'normal',
    permissions: defaultPermissions
  };

  if (type == 'task') {
    singleObjective.startDate = formatTimezone(
      dayjs().format('YYYY-MM-DD'),
      'start'
    );
    singleObjective.dueDate = formatTimezone(
      dayjs().add(7, 'day').endOf('day').format('YYYY-MM-DD'),
      'end'
    );
  }

  if (objectiveMilestonesFeature && type !== 'task') {
    Object.assign(singleObjective, {
      milestones: getMilestoneDefault(),
      milestoneType: 'disabled',
      milestoneMode: 'sum_value'
    });
  }

  let singleObjectiveChildren = JSON.parse(JSON.stringify(singleObjective));

  singleObjectiveChildren.startDate = singleObjective.startDate;
  singleObjectiveChildren.dueDate = singleObjective.dueDate;
  singleObjectiveChildren.isProject = false;
  singleObjectiveChildren.id = uuidv4();
  singleObjectiveChildren.fakeParentId = singleObjectiveId;
  singleObjectiveChildren.involvements = [];
  if (newChildMeasurement.unitId !== 0) {
    singleObjectiveChildren.measurement = newChildMeasurement;
  }

  let singleObjectiveSecond = JSON.parse(JSON.stringify(singleObjective));
  singleObjectiveSecond.id = uuidv4();
  singleObjectiveSecond.involvements = [];

  singleObjective.children.push(singleObjectiveChildren);

  if (objectiveCategoryId) {
    singleObjective.objectiveCategoryId = objectiveCategoryId;
    singleObjectiveSecond.objectiveCategoryId = objectiveCategoryId;
    singleObjectiveChildren.objectiveCategoryId = objectiveCategoryId;
  }
  if (objectiveWeightType === 'type') {
    singleObjective.objectiveCategory = objectiveCategoryId
      ? defaultObjectiveCategory
      : {};
  }

  const LIST = [singleObjective, singleObjectiveSecond];

  let singleGroupedList = {
    id: uuidv4(),
    objectiveCategoryId,
    lists: [singleObjectiveSecond]
  };

  const groupedList = [singleGroupedList];

  let initialValue = {
    lists: LIST,
    groupedList,
    singleObjective,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    errorCode: '',
    manager: isFromProfile ? prevManager : manager,
    defaultWeight,
    isGrouped: props?.isGrouped || false,
    activeGroup: null,
    keyIntegrationList: []
  };

  const [state, setState] = React.useState(initialValue);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];

  return <CreateObjectiveContext.Provider value={contextValue} {...props} />;
}

export { useCreateObjective, CreateObjectiveProvider };

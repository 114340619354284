import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { requestFeedback } from 'client/TeamClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import {
  TeamActivitiesProvider,
  useActivitiesTeam
} from 'context/team/TeamActivitiesContext';
import {
  TeamDetailProvider,
  useTeamDetail
} from 'context/team/TeamDetailContext';
import { TeamSquadProvider, useTeamSquad } from 'context/team/TeamSquadContext';
import { useUrl } from 'hooks/useUrl';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import EditTeamNameModal from 'components/teams/EditTeamNameModal';
import TeamGoals from 'components/teams/TeamGoals';
import TeamOverview from 'components/teams/TeamOverview';
import TeamProjects from 'components/teams/TeamProjects';
import ModalCreateTeam from 'components/teams/modal/ModalCreateTeam';

import ModalArchiveSquad from './ModalArchiveSquad';

const NewProjectDetailContent = () => {
  const params = useParams();
  const history = useHistory();
  const { refetchSquad } = useTeamSquad();
  const { lastContributor } = useActivitiesTeam();
  const { addToast } = useToastContext();
  const { data: team, breadcrumbs } = useTeamDetail();
  const { match } = useUrl();
  const {
    config: { permissions }
  } = useUser();
  const { refetchQueries } = useRefetchQuery();

  const [currentTab, setCurrentTab] = useState('overview');
  const [isShowModal, setShowModal] = useState({ show: false });
  const [isTriggerCreate, setIsTriggerCreate] = useState('');
  const [showModalEditTeamName, setShowModalEditTeamName] = useState(false);

  const modifiedListMenu = useMemo(
    () =>
      [
        {
          text: getObjectiveLocale('Archive'),
          iconName: 'icon-archive',
          onClick: () => setShowModal({ show: true, type: 'archive' }),
          isVisible: team?.permissions?.includes('archive'),
          dataCy: 'archive-team'
        }
      ].filter(({ isVisible }) => isVisible),
    [team?.permissions]
  );

  const tabsList = useMemo(
    () =>
      [
        { id: 'overview', name: 'Overview', isVisible: true },
        { id: 'goals', name: '[team] Goals', isVisible: true },
        {
          id: 'projects',
          name: 'Projects',
          isVisible: params?.teamType == 'squad' && permissions?.projectSee
        }
      ].filter(({ isVisible }) => isVisible),
    [params?.teamType, permissions?.projectSee]
  );

  const newListDropdown = useMemo(
    () =>
      [
        {
          title: 'Goal',
          icon: 'icon-track_changes',
          onClick: (e) => setNewButton(e, 'goals'),
          isVisible: team?.permissions?.includes('create_goal'),
          dataCy: 'create-goal'
        },
        {
          title: 'Project',
          icon: 'icon-dvr',
          onClick: (e) => setNewButton(e, 'projects'),
          isVisible:
            params?.teamType == 'squad' &&
            permissions?.projectSee &&
            permissions?.projectCreate &&
            team?.permissions?.includes('create_project'),
          dataCy: 'create-project'
        },
        {
          title: 'Squad',
          icon: 'icon-group-work',
          onClick: () => setShowModal({ show: true, type: 'squad' }),
          isVisible:
            params?.teamType == 'tribe' &&
            team?.permissions?.includes('create_squad'),
          dataCy: 'create-squad'
        }
      ].filter(({ isVisible }) => isVisible),
    [params?.teamType, setNewButton, team?.permissions, permissions]
  );

  const setNewButton = useCallback(
    (e, type) => {
      e.stopPropagation();
      history.push(`/teams/${params?.teamType}/${params?.teamId}?tab=${type}`);
      setCurrentTab(type);
      setIsTriggerCreate(type);

      setTimeout(() => {
        setIsTriggerCreate('');
      }, [1000]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params?.teamType, params?.teamId]
  );

  const sentTeamFeedback = async () => {
    const { data } = await requestFeedback(team.id);
    if (data) {
      refetchQueries(['team', 'feedback', team.id]);
      addToast({
        title: getObjectiveLocale('Request Feedback'),
        msg: getObjectiveLocale('Request has been sent.'),
        type: 'success'
      });
    }
  };

  return (
    <>
      <HeaderPage
        titlePage={team?.name}
        primaryAction={
          team?.permissions?.includes('restore')
            ? {
                title: getObjectiveLocale('Reactivate'),
                dataCy: 'reactivate-button',
                onClick: () => setShowModal({ show: true, type: 'reactivate' })
              }
            : newListDropdown?.length > 0 && {
                title: getObjectiveLocale('New'),
                icon: 'icon-add',
                dropdown: newListDropdown,
                dataCy: 'new-button'
              }
        }
        secondaryAction={
          params?.teamType == 'squad' &&
          team?.permissions?.includes('request_feedback') && {
            title: getObjectiveLocale('Request Feedback'),
            icon: 'icon-announcement',
            onClick: sentTeamFeedback,
            dataCy: 'request-feedback'
          }
        }
        otherAction={
          !team?.archivedAt &&
          modifiedListMenu?.length > 0 && { dropdown: modifiedListMenu }
        }
        titleOnHover={{
          showIcon: !team?.archivedAt,
          iconName: 'icon-pencil',
          onClickIcon: () => setShowModalEditTeamName(true)
        }}
        tabs={{
          tabsList: tabsList,
          defaultIndex: 0,
          url: `/teams/${params?.teamType}/${params?.teamId}`,
          onChange: (tab) => {
            setCurrentTab(tab);
          }
        }}
        preTitle={getObjectiveLocale(
          params?.teamType === 'tribe' ? '[T]' : '[S]'
        )}
        headerPicture={team?.teamPicture || Config.asset.general.brokenImage}
        lastUpdated={
          team?.archivedAt ? team?.archivedAt : lastContributor?.doneAt
        }
        updateTitle={team?.archivedAt ? 'Archived' : 'Last edit was made'}
        lastUpdatedBy={lastContributor?.user?.name}
        breadcrumbsData={[
          {
            title: getObjectiveLocale('My Teams'),
            link: '/teams'
          },
          ...breadcrumbs
        ]}
      />
      <div
        className={`flex flex-col
          ${currentTab === 'overview' ? '!h-[calc(100vh-106px)]' : 'h-full'}
        `}
      >
        {currentTab === 'overview' && <TeamOverview />}
        {currentTab === 'goals' && (
          <TeamGoals isTriggerCreate={isTriggerCreate} />
        )}
        {currentTab === 'projects' && (
          <TeamProjects isTriggerCreate={isTriggerCreate} />
        )}
      </div>
      {isShowModal?.show &&
        (isShowModal?.type === 'archive' ||
          isShowModal?.type === 'reactivate') && (
          <ModalArchiveSquad
            setShowModal={setShowModal}
            type={isShowModal?.type}
          />
        )}

      {showModalEditTeamName && (
        <EditTeamNameModal
          setShowModal={setShowModalEditTeamName}
          objective={team}
        />
      )}
      {isShowModal?.show && isShowModal?.type === 'squad' && (
        <ModalCreateTeam
          eventOnClickClose={() => setShowModal({ show: false })}
          type="squad"
          saveCallback={() => refetchSquad()}
          tribe={team}
        />
      )}
      {/* Sidebar */}
      {match && (
        <SidebarMyGoals customClassname="sidebar-compact" team={team} />
      )}
    </>
  );
};

const NewDetailTeam = ({
  match: {
    params: { teamId, teamType }
  }
}) => {
  return (
    <TeamDetailProvider teamId={teamId} teamType={teamType}>
      <TeamActivitiesProvider teamId={teamId}>
        <TeamSquadProvider teamId={teamId} teamType={teamType}>
          <NewProjectDetailContent />
        </TeamSquadProvider>
      </TeamActivitiesProvider>
    </TeamDetailProvider>
  );
};

export default NewDetailTeam;

import React, { useEffect, useState } from 'react';

import {
  getOneReviewGroup,
  putListReviewGroup
} from 'client/admin/ReviewGroupClient.js';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import BannerBox from 'components/shared/BannerBox';
import ContentNavigator from 'components/shared/ContentNavigator';
import ListEmptyState from 'components/shared/ListEmptyState';

import CardBehaviorModel from './CardBehaviorModel';
import ContainerWeightConfig from './ContainerWeightConfig';
import HeaderTableBehaviorModel from './HeaderTableBehaviorModel';
import './LandingBehaviorModelPage.scss';
import SectionDropdownBehavior from './SectionDropdownBehavior';

const DefaultTabGroup = ({
  isReadOnly = false,
  setSections,
  activeSection,
  setActiveSection,
  listNavigatorContents,
  setListNavigatorContents
}) => {
  const { config } = useUser();
  const [dataDefault, setDataDefault] = useState([]);
  const [dataCompetencyDictionary, setDataCompetencyDictionary] = useState([]);
  const [dataValueDictionary, setDataValueDictionary] = useState([]);
  const [dataWorkDictionary, setDataWorkDictionary] = useState([]);

  const [behaviorWeightsCompetency, setBehaviorWeightsCompetency] = useState(
    []
  );
  const [behaviorWeightsValue, setBehaviorWeightsValue] = useState([]);
  const [behaviorWeightsWork, setBehaviorWeightsWork] = useState([]);

  const [isEnableSetPercentage, setIsEnableSetPercentage] = useState(false);
  const [isHideTableCompetency, setIsHideTableCompetency] = useState(false);
  const [isHideTableValue, setIsHideTableValue] = useState(false);
  const [isHideTableWork, setIsHideTableWork] = useState(false);
  const [isLoadingDefaultData, setIsLoadingDefaultData] = useState(true);
  const [isDataEmpty, setIsDataEmpty] = useState({
    competency: false,
    value: false,
    work: false
  });
  const [isWarningCompetency, setIsWarningCompetency] = useState(false);
  const [isWarningValue, setIsWarningValue] = useState(false);
  const [isWarningWork, setIsWarningWork] = useState(false);

  const [isWeightUpdated, setWeightUpdated] = useState(false);
  const enforceValue =
    config?.enforceZeroOrOneHundredTotalValueWeightPercentage;
  const enforceCompetencies =
    config?.enforceZeroOrOneHundredTotalCompetencyWeightPercentage;
  const enforceWorkAttr =
    config?.enforceZeroOrOneHundredTotalWorkAttributeWeightPercentage;

  const _getDefaultData = async () => {
    let params = { groupType: 'behavior_group' };
    // id: 0 => default group
    const { data } = await getOneReviewGroup(0, params);

    if (data) {
      setDataDefault(data);
      setIsLoadingDefaultData(false);
      setIsEnableSetPercentage(data?.useBehaviorWeight);
    }

    if (!data) {
      setDataDefault([]);
      setIsLoadingDefaultData(false);
      setIsEnableSetPercentage(false);
    }
  };

  const _putListReviewGroup = async (type, model, params) => {
    let _params = {
      ...params,
      name: dataDefault?.name || '',
      groupType: 'behavior_group'
    };

    if (type) {
      if (type == 'competency') {
        _params.competencyModelId = model.id;
      } else if (type == 'value') {
        _params.valueModelId = model.id;
      } else {
        _params.workAttributeModelId = model.id;
      }
    }

    const { data } = await putListReviewGroup(dataDefault.id, _params);
  };

  let listContents = [
    { id: 'configure', name: 'Configure' },
    { id: 'competency-model', name: 'Competency Model' },
    { id: 'value-model', name: 'Value Model' },
    { id: 'work-attribute-model', name: 'Work Attribute Model' }
  ];

  let listSections = [
    'configure',
    'competency-model',
    'value-model',
    'work-attribute-model'
  ];

  useEffect(() => {
    _getDefaultData();
    if (!config?.allowUsingWorkAttributes) {
      listContents.splice(listContents?.length - 1, 1);
      listSections.splice(listSections?.length - 1, 1);
    }
    setSections(listSections);
    setActiveSection('configure');
    setListNavigatorContents(listContents);
  }, []);

  useEffect(() => {
    if (isWeightUpdated && behaviorWeightsCompetency) {
      let competencyDictionaryLength = dataCompetencyDictionary?.length !== 0;
      if (isEnableSetPercentage && competencyDictionaryLength) {
        let total = [...behaviorWeightsCompetency].reduce(
          (a, b) => a + b.weight,
          0
        );
        if (enforceCompetencies ? total == 0 || total == 100 : true) {
          _putListReviewGroup('competency', dataDefault.competencyModel.id, {
            competencyWeights: behaviorWeightsCompetency
          });
        }
        setIsWarningCompetency(
          enforceCompetencies ? total != 0 && total != 100 : false
        );
      }
    }
  }, [behaviorWeightsCompetency, isEnableSetPercentage]);

  useEffect(() => {
    if (isWeightUpdated && behaviorWeightsValue) {
      let valueDictionaryLength = dataValueDictionary?.length !== 0;
      if (isEnableSetPercentage && valueDictionaryLength) {
        let total = [...behaviorWeightsValue].reduce((a, b) => a + b.weight, 0);
        if (enforceValue ? total == 0 || total == 100 : true) {
          _putListReviewGroup('value', dataDefault.valueModel.id, {
            valueWeights: behaviorWeightsValue
          });
        }
        setIsWarningValue(enforceValue ? total != 0 && total != 100 : false);
      }
    }
  }, [behaviorWeightsValue, isEnableSetPercentage]);

  useEffect(() => {
    if (isWeightUpdated && behaviorWeightsWork) {
      let workDictionaryLength = dataWorkDictionary?.length !== 0;
      if (isEnableSetPercentage && workDictionaryLength) {
        let total = [...behaviorWeightsWork].reduce((a, b) => a + b.weight, 0);
        if (enforceWorkAttr ? total == 0 || total == 100 : true) {
          _putListReviewGroup('work', dataDefault.workAttributeModel.id, {
            workAttributeWeights: behaviorWeightsWork
          });
        }
        setIsWarningWork(enforceWorkAttr ? total != 0 && total != 100 : false);
      }
    }
  }, [behaviorWeightsWork, isEnableSetPercentage]);

  return (
    <>
      <div className="content-navigator-wrapper">
        <ContentNavigator
          additionalOffset={16}
          title="CONTENTS"
          activeSection={activeSection}
          listNavigatorContents={listNavigatorContents}
          sectionContent="sectioncontainer"
        />
      </div>
      <div className="flex content-container-nav">
        <div className="w-9/12">
          <p id="configure" className="mt-[24px] mb-[40px]">
            All configuration in this default setting will be applied to
            organization level and to employees who is not inlcuded in any
            group. You can define behavior model for spesific employees by
            creating group.
          </p>

          {dataDefault?.length !== 0 && (
            <ContainerWeightConfig
              setState={setIsEnableSetPercentage}
              state={isEnableSetPercentage}
              putData={_putListReviewGroup}
              isReadOnly={isReadOnly}
            />
          )}

          {!isLoadingDefaultData && (
            <>
              <div className="divider-line" />
              <SectionDropdownBehavior
                id={'competency-model'}
                type="competency"
                groupId={dataDefault?.id}
                behaviorWeights={behaviorWeightsCompetency}
                setBehaviorWeights={setBehaviorWeightsCompetency}
                dataDefaultModel={dataDefault?.competencyModel}
                dataDictionary={dataCompetencyDictionary}
                setDataDictionary={setDataCompetencyDictionary}
                getOrUpdateDataDropdown={_putListReviewGroup}
                stateTable={isHideTableCompetency}
                setStateTable={setIsHideTableCompetency}
                setIsDataEmpty={setIsDataEmpty}
                isDataEmpty={isDataEmpty}
                isReadOnly={isReadOnly}
              />
            </>
          )}

          {isWarningCompetency && isEnableSetPercentage && (
            <BannerBox
              text="Total weight of all competencies must be 100%, or this change will not be saved."
              type="warning"
              dataCy="warning-container-competency"
            />
          )}
          {/* TABLE COMPETENCY */}
          {behaviorWeightsCompetency !== 0 &&
            !isHideTableCompetency &&
            dataCompetencyDictionary?.length != 0 && (
              <div className="table-landing-behavior-model mb-[40px]">
                <HeaderTableBehaviorModel type="competency" />

                <div className="body-table">
                  {dataCompetencyDictionary.map((competency, idx) => {
                    let weight = behaviorWeightsCompetency?.find(
                      (val) => val.behaviorId == competency.id
                    );
                    return (
                      <CardBehaviorModel
                        key={competency.id}
                        value={competency}
                        type="competency"
                        canEdit={isEnableSetPercentage && !isReadOnly}
                        weight={weight?.weight || 0}
                        behaviorWeights={behaviorWeightsCompetency}
                        setBehaviorWeights={setBehaviorWeightsCompetency}
                        setWeightUpdated={setWeightUpdated}
                        isWeightUpdated={isWeightUpdated}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          {isDataEmpty.competency && dataCompetencyDictionary?.length == 0 && (
            <ListEmptyState
              fullHeight
              emptyIcon="icon-no-result-found"
              title={getObjectiveLocale('No Result Found')}
              subtitle={getObjectiveLocale(
                'Select another competency model instead.'
              )}
            />
          )}

          {!isLoadingDefaultData && (
            <>
              <div className="divider-line" />
              <SectionDropdownBehavior
                id={'value-model'}
                type="value"
                groupId={dataDefault?.id}
                behaviorWeights={behaviorWeightsValue}
                setBehaviorWeights={setBehaviorWeightsValue}
                dataDefaultModel={dataDefault?.valueModel}
                dataDictionary={dataValueDictionary}
                setDataDictionary={setDataValueDictionary}
                getOrUpdateDataDropdown={_putListReviewGroup}
                stateTable={isHideTableValue}
                setStateTable={setIsHideTableValue}
                setIsDataEmpty={setIsDataEmpty}
                isDataEmpty={isDataEmpty}
                isReadOnly={isReadOnly}
              />
            </>
          )}

          {isWarningValue && isEnableSetPercentage && (
            <BannerBox
              text="Total weight of all values must be 100%, or this change will not be saved."
              type="warning"
              dataCy="warning-container-value"
            />
          )}

          {/* TABLE VALUE */}
          {behaviorWeightsValue?.length !== 0 &&
            !isHideTableValue &&
            dataValueDictionary?.length != 0 && (
              <div className="table-landing-behavior-model mb-[40px]">
                <HeaderTableBehaviorModel type="value" />
                <div className="body-table">
                  {dataValueDictionary.map((value, idx) => {
                    let weight = behaviorWeightsValue?.find(
                      (val) => val.behaviorId == value.id
                    );
                    return (
                      <CardBehaviorModel
                        key={value.id}
                        value={value}
                        type="value"
                        canEdit={isEnableSetPercentage && !isReadOnly}
                        weight={weight?.weight || 0}
                        behaviorWeights={behaviorWeightsValue}
                        setBehaviorWeights={setBehaviorWeightsValue}
                        setWeightUpdated={setWeightUpdated}
                        isWeightUpdated={isWeightUpdated}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          {isDataEmpty.value && dataValueDictionary?.length == 0 && (
            <ListEmptyState
              fullHeight
              emptyIcon="icon-no-result-found"
              title={getObjectiveLocale('No Result Found')}
              subtitle={getObjectiveLocale(
                'Select another value model instead.'
              )}
            />
          )}

          {config?.allowUsingWorkAttributes && (
            <>
              {!isLoadingDefaultData && (
                <>
                  <div className="divider-line" />
                  <SectionDropdownBehavior
                    id={'work-attribute-model'}
                    type="work"
                    groupId={dataDefault?.id}
                    behaviorWeights={behaviorWeightsWork}
                    setBehaviorWeights={setBehaviorWeightsWork}
                    dataDefaultModel={dataDefault?.workAttributeModel}
                    dataDictionary={dataWorkDictionary}
                    setDataDictionary={setDataWorkDictionary}
                    getOrUpdateDataDropdown={_putListReviewGroup}
                    stateTable={isHideTableWork}
                    setStateTable={setIsHideTableWork}
                    setIsDataEmpty={setIsDataEmpty}
                    isDataEmpty={isDataEmpty}
                    isReadOnly={isReadOnly}
                  />
                </>
              )}

              {isWarningWork && isEnableSetPercentage && (
                <BannerBox
                  text="Total weight of all values must be 100%, or this change will not be saved."
                  type="warning"
                  dataCy="warning-container-work"
                />
              )}

              {/* TABLE WORK ATTRIBUTE */}
              {behaviorWeightsWork?.length !== 0 &&
                !isHideTableWork &&
                dataWorkDictionary?.length != 0 && (
                  <div className="table-landing-behavior-model mb-[40px] pb-[64px]">
                    <HeaderTableBehaviorModel type="work attribute" />
                    <div className="body-table">
                      {dataWorkDictionary.map((work, idx) => {
                        let weight = behaviorWeightsWork?.find(
                          (val) => val.behaviorId == work.id
                        );
                        return (
                          <CardBehaviorModel
                            key={work.id}
                            value={work}
                            type="work"
                            canEdit={isEnableSetPercentage && !isReadOnly}
                            weight={weight?.weight || 0}
                            behaviorWeights={behaviorWeightsWork}
                            setBehaviorWeights={setBehaviorWeightsWork}
                            setWeightUpdated={setWeightUpdated}
                            isWeightUpdated={isWeightUpdated}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              {dataWorkDictionary?.length == 0 && (
                <ListEmptyState
                  fullHeight
                  emptyIcon="icon-no-result-found"
                  title={getObjectiveLocale('No Result Found')}
                  subtitle={getObjectiveLocale(
                    'Select another work attribute model instead.'
                  )}
                />
              )}
            </>
          )}
        </div>
        <div className="w-3/12"></div>
      </div>
    </>
  );
};
export default DefaultTabGroup;

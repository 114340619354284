import React, { useState } from 'react';

import isNumber from 'lodash/isNumber';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Accordion from 'components/design-system/accordion/Accordion';
import SVGIcon from 'components/shared/SVGIcon';

import DecimalHoverable from './DecimalHoverable';

function PreviousSummaries({ data, isOpen, trackConfig }) {
  const [showDetail, setShowDetail] = useState(isOpen);
  const totalScore = data?.finalScore?.convertedScore
    ? data?.finalScore?.convertedScore
    : data?.finalScore?.score;

  return (
    <Accordion contentOpen={isOpen}>
      <Accordion.Header useCustomHeader>
        <div
          className="bg-lb-100 flex justify-between items-center px-[16px] py-[10px] cursor-pointer flex items-center ml-[-16px] mr-[-16px]"
          onClick={() => setShowDetail(!showDetail)}
        >
          <p className="typography-h200 text-n-900 truncate">
            {data?.positionName}
          </p>
          <div className="flex justify-between items-center">
            <DecimalHoverable
              number={data?.finalScore?.proportionalScore}
              textClass="text-right typography-h200"
            />
            <SVGIcon
              size="16"
              iconName={
                showDetail
                  ? 'icon-keyboard_arrow_up'
                  : 'icon-keyboard_arrow_down'
              }
              fillColor="var(--n-600)"
              customClass="items-center ml-[16px]"
            />
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Content customSpaceClass="px-[0px] py-[0px]" noBorder>
        <div className="ml-[-16px] mr-[-16px] bg-n-000 pt-[10px]">
          {data?.scores?.map((score, index) => {
            // add config here
            if (trackConfig?.hideAspectWithZeroWeight && score.weight == 0)
              return <></>;

            return (
              <div
                className="grid grid-assessment py-[10px] px-[16px]"
                key={index}
              >
                <p className="typography-h100 text-n-700 pl-[36px]">
                  {getObjectiveLocale(score?.name || score?.description)}
                </p>
                <DecimalHoverable
                  number={score?.score}
                  textClass="text-center typography-h100 text-n-700"
                />
                <DecimalHoverable
                  number={score?.weight}
                  textClass={'text-center typography-h100 text-n-700'}
                />
                <DecimalHoverable
                  number={score?.weightedScore}
                  textClass={'text-right typography-h100 text-n-700 pr-[74px]'}
                />
              </div>
            );
          })}
          <div className="grid grid-assessment px-[16px]">
            <p className="typography-h100 py-[10px] ml-[36px] border-top-300">
              {getObjectiveLocale('Total')}
            </p>
            <p className="text-center typography-h100 py-[10px] border-top-300 "></p>
            <p className="text-center typography-h10 py-[10px] border-top-300 "></p>
            <DecimalHoverable
              number={data?.finalScore?.score}
              textClass="text-right typography-h100 py-[10px] border-top-300 pr-[74px]"
            />
          </div>
          {data?.finalScore?.convertedScore && (
            <div className="grid grid-assessment px-[16px]">
              <p className="typography-h100 py-[10px] ml-[36px]">
                {getObjectiveLocale('Converted Score')}
              </p>
              <p className="text-center typography-h100 py-[10px]"></p>
              <p className="text-center typography-h10 py-[10px]"></p>
              <DecimalHoverable
                number={data?.finalScore?.convertedScore}
                textClass="text-right typography-h100 py-[10px] border-top-300 pr-[74px]"
              />
            </div>
          )}
          {isNumber(data?.finalScore?.proportional) &&
            data?.finalScore?.proportional > 0 && (
              <div className="grid grid-assessment py-[10px] px-[16px]">
                <p className="typography-h100 text-n-900 pl-[36px]">
                  {getObjectiveLocale('Weighted Total Score')}
                </p>
                <DecimalHoverable
                  number={totalScore}
                  textClass="text-center typography-h100 text-n-900"
                />
                <DecimalHoverable
                  number={data?.finalScore?.proportional}
                  textClass="text-center typography-h100 text-n-900"
                />
                <DecimalHoverable
                  number={data?.finalScore?.proportionalScore}
                  textClass="text-right typography-h100 text-n-900 pr-[74px]"
                />
              </div>
            )}
        </div>
      </Accordion.Content>
    </Accordion>
  );
}

export default PreviousSummaries;

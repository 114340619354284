import React, { useEffect, useState } from 'react';

import { getViewerLikers } from 'client/CultureClient';
import { useReload } from 'context/ReloadContext';
import {
  capitalize,
  getCultureLocale,
  getNumberLocale,
  loadMoreValidator
} from 'utils/HelperUtils';

import ListDetailUser from 'components/modal/culture/components/ListDetailUser';
import Modal from 'components/shared/modal/Modal';

function PostInfo({
  views,
  likes,
  comments,
  isSurvey,
  voter,
  participant,
  postId,
  edited
}) {
  const { reloadCommentCountPost, reloadLikeCountPost } = useReload();
  const [currentComments, setCurrentComments] = useState(comments);
  const [currentLikes, setCurrentLikes] = useState(likes);
  const [showModal, setShowModal] = useState({ show: false, type: '' });
  const [modalData, setModalData] = useState([]);
  const [olderThan, setOlderThan] = useState(0);
  const [onLoadMore, setOnLoadMore] = useState(false);

  const getModalData = async (type, newOlderThan) => {
    const params = {
      limit: 10,
      cursor: newOlderThan ? 0 : olderThan
    };
    const data = await getViewerLikers(postId, type, params);
    const userData = data?.data;
    const nextCursor = data?.next_cursor;

    if (userData) {
      setModalData(newOlderThan ? userData : [...modalData, ...userData]);
      setOlderThan(nextCursor);
    }
  };

  const viewerLikerFunction = (type) => {
    setOlderThan(0);
    setModalData([]);
    setShowModal({ show: true, type: type });
    getModalData(type, true);
  };

  const scrollUser = (e) => {
    const target = e.target;
    const hasMore = olderThan > 0;

    const loadMore = async () => {
      setOnLoadMore(true);
      await getModalData(showModal?.type);
      setOnLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 50, () => {
        loadMore();
      });
    }
  };

  useEffect(() => {
    if (reloadCommentCountPost.postId === postId) {
      setCurrentComments(currentComments + 1);
    }
  }, [reloadCommentCountPost]);

  useEffect(() => {
    if (reloadLikeCountPost.postId === postId) {
      reloadLikeCountPost.action === 'like'
        ? setCurrentLikes(currentLikes + 1)
        : setCurrentLikes(currentLikes - 1);
    }
  }, [reloadLikeCountPost]);

  return (
    <>
      <div className="feed-info" data-cy={`post-info-${postId}`}>
        {isSurvey && (
          <span className="typography-h400" data-cy="votes">
            {getNumberLocale(voter)}/{getNumberLocale(participant)}{' '}
            {getCultureLocale('Voted')}
          </span>
        )}
        {currentLikes > 0 && (
          <span
            className="typography-h400 cursor-pointer"
            data-cy="likes"
            onClick={() => viewerLikerFunction('likers')}
          >
            {getNumberLocale(currentLikes)}{' '}
            {getCultureLocale(`Like${currentLikes > 1 ? 's' : ''}`)}
          </span>
        )}
        {views > 0 && (
          <span
            className="typography-h400 cursor-pointer"
            data-cy="views"
            onClick={() => viewerLikerFunction('viewers')}
          >
            {getNumberLocale(views)}{' '}
            {getCultureLocale(`View${views > 1 ? 's' : ''}`)}
          </span>
        )}
        {currentComments > 0 && (
          <span className="typography-h400" data-cy="comments">
            {getNumberLocale(currentComments)}{' '}
            {getCultureLocale(`Comment${currentComments > 1 ? 's' : ''}`)}
          </span>
        )}
        {edited && (
          <span className="typography-h400" data-cy="edited-post">
            {getCultureLocale('Edited')}
          </span>
        )}
      </div>
      {showModal?.show && (
        <Modal
          withCloseIcon={true}
          maxHeight={616}
          className="w-[400px]"
          withFooter={false}
          withContentMarginBottom={false}
          contentClass="pt-[4px] pb-[8px] px-[0px]"
          eventOnClickClose={() => setShowModal(false)}
          title={getCultureLocale(capitalize(showModal?.type))}
        >
          <div
            className="max-h-[450px] overflow-hidden hover:overflow-auto"
            onScroll={(e) => scrollUser(e)}
          >
            {modalData.map((data) => (
              <ListDetailUser
                id={data.id}
                title={data.fullName}
                desc={data.jobTitle}
                image={
                  data.profilePicture ? data.profilePicture.secureUrl : null
                }
                dataCy={`user-${showModal?.type}`}
              />
            ))}
          </div>
        </Modal>
      )}
    </>
  );
}

export default PostInfo;

import React from 'react';

import ctl from '@netlify/classnames-template-literals';

import useTasks from 'hooks/useTasks';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Table from 'components/design-system/table/Table';
import SVGIcon from 'components/shared/SVGIcon';
import useIsFetchingTasks from 'src/hooks/useIsFetchingTasks';

const AddTaskButton = ({ groupName, sectionName, isProject, tableHeaders }) => {
  const { addNewTaskOneLevel, addNewTaskTwoLevels, setAction } = useTasks();

  const isFetchingTasks = useIsFetchingTasks({
    isProject,
    groupName,
    sectionName
  });

  const handleAddTask = () => {
    if (isFetchingTasks) return;

    if (isProject) {
      addNewTaskTwoLevels(sectionName, groupName, 'bottom');
    } else {
      addNewTaskOneLevel(groupName, 'bottom');
    }
    setAction('create');
  };

  const getColumnClass = (index) => {
    const isLastIndex = index === tableHeaders.length - 1;
    return ctl(`
      !h-[40px]
      ${
        index == 0
          ? `bg-n-100`
          : `border-0
        border-solid
        border-b 
        border-t
        border-n-300
        border-0`
      }
      ${index == 1 && 'rounded-l-[4px] border-l'}
      ${isLastIndex && 'rounded-r-[4px] border-r'}
    `);
  };

  return (
    <Table.Row customClass="bg-n-000 mt-[-1px]" useRowHover={false}>
      {tableHeaders?.length > 0 &&
        tableHeaders?.map((header, index) => (
          <Table.Column
            customClass={getColumnClass(index)}
            key={header.name}
            noPadding
          >
            {index === 1 && (
              <Button.Tertiary
                onClick={handleAddTask}
                customClass="ml-[24px]"
                datacy="task-list-add-task-button"
              >
                <SVGIcon
                  size="24"
                  iconName="icon-add"
                  fillColor="var(--base-600)"
                />
                {getObjectiveLocale('Add Task')}
              </Button.Tertiary>
            )}
          </Table.Column>
        ))}
    </Table.Row>
  );
};

export default AddTaskButton;

import React, { useEffect, useState } from 'react';

import { getObjectivesComponent } from 'client/admin/Overview';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import LoadingComponent from 'components/shared/LoadingComponent';

function ObjectivesCreatedComponent() {
  const [objectives, setObjectives] = useState(0);
  const [activeObj, setActiveObj] = useState(0);
  const [reviewedObj, setReviewedObj] = useState(0);
  const [isLoading, setisLoading] = useState(true);

  const _getPerformanceScore = async () => {
    const { data } = await getObjectivesComponent();
    if (data) {
      setObjectives(data.running + data.completed + data.reviewed);
      setActiveObj(data.running + data.completed);
      setReviewedObj(data.reviewed);
      setisLoading(false);
    }
  };

  useEffect(() => {
    _getPerformanceScore();
  }, []);

  return (
    <div className="col-xs-4">
      <div className="card">
        <div className="card-body">
          <h4 className="body-header">
            {getObjectiveLocale('OBJECTIVES CREATED')}
            <span className="description"></span>
          </h4>
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <div>
              <div className="row">
                <div className="col-xs-12">
                  <span className="number-first">
                    {getNumberLocale(objectives)}
                  </span>
                  <span className="info-first">
                    {getObjectiveLocale('Objectives')}
                  </span>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-xs-6">
                  <span className="number">{getNumberLocale(activeObj)}</span>
                  <span className="info">{getObjectiveLocale('Active')}</span>
                </div>
                <div className="col-xs-6">
                  <span className="number">{getNumberLocale(reviewedObj)}</span>
                  <span className="info">{getObjectiveLocale('Reviewed')}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ObjectivesCreatedComponent;

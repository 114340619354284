import React from 'react';

import Badge from 'components/shared/Badge';

const Title = ({ objectiveDetail }) => {
  const { id, name, organization } = objectiveDetail;

  return (
    <div className="mt-[16px] px-[24px]">
      <div className="flex items-center gap-[8px] mb-[8px]">
        <Badge content={`#${id}`} />
        {organization?.id && (
          <Badge
            iconUrl={organization?.companyLogo}
            content={organization?.name}
          />
        )}
      </div>
      <p className="typography-h600" data-cy="sidebar-detail-title">
        {name}
      </p>
    </div>
  );
};

export default Title;

import React from 'react';

import has from 'lodash/has';

import { getObjectiveLocale } from 'utils/HelperUtils';

const ImportSummary = ({
  fileSuccessMessage,
  successCaptions = 'Successfully Imported',
  useErrorCount
}) => {
  return (
    <section>
      <h1 className="typography-h300 text-n-600 my-0 h-[40px] flex items-center">
        {getObjectiveLocale(successCaptions).toUpperCase()}
      </h1>

      <div className="flex flex-col">
        {has(fileSuccessMessage, 'created') && (
          <span className="inline-flex">
            <p className="typography-paragraph text-n-800 w-[162px] p-[8px]">
              {getObjectiveLocale('Created')}
            </p>
            <p className="typography-paragraph text-n-900 py-[8px]">
              {fileSuccessMessage.created}
            </p>
          </span>
        )}
        <span className="inline-flex">
          <p className="typography-paragraph text-n-800 w-[162px] p-[8px]">
            {getObjectiveLocale('Updated')}
          </p>
          <p className="typography-paragraph text-n-900 py-[8px]">
            {fileSuccessMessage.updated}
          </p>
        </span>
      </div>

      <h1 className="typography-h300 text-n-600 my-0 h-[40px] flex items-center">
        {getObjectiveLocale('Error Details').toUpperCase()}
      </h1>

      {useErrorCount && (
        <span className="inline-flex">
          <p className="typography-paragraph text-n-800 w-[162px] p-[8px]">
            {getObjectiveLocale('Total error rows')}
          </p>
          <p className="typography-paragraph text-n-900 py-[8px]">
            {fileSuccessMessage?.errors?.length}
          </p>
        </span>
      )}

      {fileSuccessMessage.errors.map((error, index) => (
        <p className="typography-paragraph text-n-800 p-[8px]" key={index}>
          •{' '}
          {typeof error == 'string'
            ? error
            : `Row ${error.row}: ${error.message}`}
        </p>
      ))}

      {has(fileSuccessMessage, 'notUpdated') && (
        <>
          <h1 className="typography-h300 text-n-600 my-0 h-[40px] flex items-center">
            {getObjectiveLocale('Not Updated').toUpperCase()}
          </h1>

          {fileSuccessMessage.notUpdated.map((error, index) => (
            <p className="typography-paragraph text-n-800 p-[8px]" key={index}>
              • {error}
            </p>
          ))}
        </>
      )}
    </section>
  );
};

export default ImportSummary;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';

import { getMilestoneDetail } from 'client/ObjectivesClient';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import {
  filteredDateMilestone,
  getInfoIconMilestones
} from 'utils/MilestoneHelper';

import SVGIcon from 'components/shared/SVGIcon';
import InputMilestone from 'components/sidebar/InputMilestone';

function MilestoneProgressSection({
  measurement,
  dataId,
  updatedOnly,
  setUpdated,
  objectiveValue
}) {
  const objStartDate = dayjs(objectiveValue?.startDate).format('YYYY-MM-DD');
  const [milestoneData, setMilestones] = useState([]);
  const objDueDate = dayjs(objectiveValue?.dueDate).format('YYYY-MM-DD');
  const [showOther, setShowOther] = useState(false);

  let totalTargetValue = milestoneData?.reduce((curr, arr) => {
    return curr + (arr.currentValue || 0);
  }, 0);

  const currentMilestone = objectiveValue?.currentMilestone;
  const otherMilestone = milestoneData?.filter(
    (data) => data?.periodName !== currentMilestone?.periodName
  );

  const inBetweenMilestone = filteredDateMilestone(
    otherMilestone,
    objStartDate,
    objDueDate
  );

  const handleChangeData = (value, periodName) => {
    let tempData = cloneDeep(milestoneData);
    tempData?.map((mileData, index) => {
      if (mileData?.periodName == periodName) {
        mileData.currentValue = value;
        let indexUpdated = updatedOnly?.findIndex(
          (list) => list.periodName == mileData.periodName
        );
        if (indexUpdated !== -1) {
          updatedOnly[indexUpdated].currentValue = value;
        } else {
          setUpdated([...updatedOnly, mileData]);
        }
      }
    });
    setMilestones(tempData);
  };

  const getDetailMilestone = async () => {
    const { data } = await getMilestoneDetail(dataId);
    if (data) {
      setMilestones(data);
    }
  };

  useEffect(() => {
    getDetailMilestone();
  }, []);

  return (
    <div className="mt-[24px] mb-[24px]">
      <div className="header-milestone flex justify-between mb-[12px]">
        <p className="typography-h300 text-n-800">
          {getObjectiveLocale('RECENT PERIOD')}
        </p>
        {objectiveValue?.milestoneMode == 'sum_value' && (
          <p className="typography-h100 text-n-600">
            {getNumberLocale(totalTargetValue)}/
            {getNumberLocale(measurement?.targetValue)}
          </p>
        )}
      </div>
      {currentMilestone && (
        <div className="body-milestone flex items-center mb-[12px]">
          <div className="date w-[128px] flex flex-col mr-[16px]">
            <p className="typography-h400 text-n-900 mb-[4px]">
              {currentMilestone?.periodName}
            </p>
            <p className="typography-h100 text-n-600">
              {dayjs(currentMilestone?.startsAt).format('DD MMM')}-
              {dayjs(currentMilestone?.endsBefore).format('DD MMM')}
            </p>
          </div>
          {currentMilestone?.progressState == 'not_started' ? (
            <div className="notstarted-milestone w-[348px] mr-[16px] h-[36px] bg-n-200 px-[24px] py-[12px] flex items-center justify-center">
              <p className="typography-paragraph text-n-600">
                {getObjectiveLocale('Not started yet')}
              </p>
            </div>
          ) : (
            <>
              <InputMilestone
                className="w-[176px]"
                data={currentMilestone}
                index={0}
                handleChange={handleChangeData}
                isCurrentValue={true}
              />
              <p className="typography-h400 mx-[16px] text-n-800 w-[168px]">
                / {getNumberLocale(currentMilestone?.targetValue)}
              </p>
            </>
          )}
          {getInfoIconMilestones(currentMilestone, true)}
        </div>
      )}
      {inBetweenMilestone?.length > 0 && (
        <>
          <p
            className="typography-link text-base-600 cursor-pointer flex items-center mb-[24px] mt-[8px]"
            onClick={() => setShowOther(!showOther)}
          >
            {!showOther
              ? 'View other milestone period'
              : 'Hide other milestone period'}
            <SVGIcon
              size="24"
              iconName={
                showOther ? 'icon-arrow_drop_up' : 'icon-arrow_drop_down'
              }
              fillColor="var(--base-600)"
              customClass="ml-[8px]"
            />
          </p>
          {showOther && (
            <>
              <p className="header-milestone mb-[8px] typography-h300 text-n-800">
                {getObjectiveLocale('OTHERS PERIOD')}
              </p>
              {inBetweenMilestone?.map((data, index) => {
                return (
                  <div
                    className="body-milestone flex items-center py-[8px] border-bottom-300"
                    key={index}
                  >
                    <div className="date w-[128px] flex flex-col mr-[24px]">
                      <p className="typography-h400 text-n-900 mb-[4px]">
                        {data?.periodName}
                      </p>
                      <p className="typography-h100 text-n-600">
                        {dayjs(data?.startsAt).format('DD MMM')}-
                        {dayjs(data?.endsBefore).format('DD MMM')}
                      </p>
                    </div>
                    {data?.progressState == 'not_started' ? (
                      <div className="notstarted-milestone w-[348px] mr-[16px] h-[36px] bg-n-200 px-[24px] py-[12px] flex items-center justify-center">
                        <p className="typography-paragraph text-n-600">
                          {getObjectiveLocale('Not started yet')}
                        </p>
                      </div>
                    ) : (
                      <>
                        <InputMilestone
                          className="w-[176px]"
                          data={data}
                          index={index}
                          handleChange={handleChangeData}
                          isCurrentValue={true}
                        />
                        <p className="typography-h400 mx-[16px] text-n-800 w-[168px]">
                          / {getNumberLocale(data?.targetValue)}
                        </p>
                      </>
                    )}
                    {getInfoIconMilestones(data, true)}
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MilestoneProgressSection;

import React, { useState } from 'react';

import { UsersAdminProvider } from 'context/admin/UsersAdminContext';

import ModalShowMember from './ModalShowMember';
import './VerifyView.scss';

const VerifyView = ({
  totalUser,
  excludedId,
  children,
  inputGroupName,
  inputGroupDesc,
  listIdUser,
  isSelectAll
}) => {
  const [showModalMembers, setShowModalMembers] = useState(false);
  const totalEmployee = isSelectAll
    ? totalUser - excludedId.length
    : listIdUser.length;

  return (
    <div className="verify-view-container pt-[24px]">
      <h4 className="typography-h500 mb-[32px]">Summary</h4>
      <div className="summary-detail-container">
        <div className="flex mb-[24px]">
          <h6 className="w-[216px] typography-paragraph typography-secondary">
            Group Name
          </h6>
          <h6 className="typography-paragraph">{inputGroupName}</h6>
        </div>
        <div className="flex mb-[24px]">
          <h6 className="w-[216px] typography-paragraph typography-secondary">
            Description
          </h6>
          <h6 className="typography-paragraph">{inputGroupDesc || '-'}</h6>
        </div>
        <div className="flex">
          <h6 className="w-[216px] typography-paragraph typography-secondary">
            Members
          </h6>
          <h6 className="typography-paragraph">
            <span
              className="text-base-600 cursor-pointer"
              onClick={() => setShowModalMembers(true)}
            >
              {totalEmployee} {totalEmployee < 2 ? 'employee' : 'employees'}
            </span>
          </h6>
        </div>
      </div>

      {children}

      {showModalMembers && (
        <UsersAdminProvider>
          <ModalShowMember
            setShowModalMembers={setShowModalMembers}
            listIdUser={listIdUser}
            isSelectAll={isSelectAll}
            withJobPlacement={true}
          />
        </UsersAdminProvider>
      )}
    </div>
  );
};

export default VerifyView;

import React, { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';

import ctl from '@netlify/classnames-template-literals';
import dayjs from 'dayjs';

import { getObjectivesCount } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import { formatTimezone } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { restructureFilter } from 'utils/ObjectivesHelper';

import ErrorBoundary from 'pages/ErrorBoundary';
import useLoadingStatus from 'src/hooks/useLoadingStatus';
import useSidebar from 'src/hooks/useSidebar';

import OverallPhaseCompact from './OverallPhaseCompact';
import OverallProgressCompact from './OverallProgressCompact';
import OverallStatusCompact from './OverallStatusCompact';

const Component = (props) => {
  const {
    children,
    assigneeId,
    dispatchFilter,
    visibility,
    annual,
    gridArea,
    filter,
    profileFilter,
    userId,
    placementId,
    page,
    useCompactLayout,
    ...rest
  } = props;
  const { user } = useUser();
  const currentUserId = userId ?? user.id;
  const setStatusChange = useLoadingStatus((state) => state?.setStatusChange);
  const filterByStatus = (status) => {
    setStatusChange(true);
    dispatchFilter({ type: 'STATUS-OVERALL', state: status, annual });
  };

  const filterByPhase = (phase) => {
    dispatchFilter({ type: 'PHASE-OVERALL', state: phase });
  };

  const isVisible = (key) => visibility.includes(key);
  const statusActive =
    filter?.status?.length === 4 || filter?.status?.length === 0
      ? 'all'
      : filter?.status || 'all';
  const phaseActive =
    filter?.phase?.length === 3 || filter?.phase?.length === 0
      ? 'all'
      : filter?.phase;

  const getCount = () => {
    let newFilter = restructureFilter({ ...filter, ...profileFilter });
    let assigneeIdParams;
    if (assigneeId) {
      // if there is props assigneeId
      assigneeIdParams = [assigneeId];
    } else {
      assigneeIdParams = newFilter?.assigneeId || [user.id];
    }

    const periodBegin = annual
      ? formatTimezone(
          `${dayjs(newFilter.periodEndBefore).year()}-01-01`,
          'start'
        )
      : newFilter?.periodBegin;
    const periodEndBefore = annual
      ? formatTimezone(
          `${dayjs(newFilter.periodEndBefore).year()}-12-31`,
          'end'
        )
      : newFilter?.periodEndBefore;

    newFilter = {
      assigneeId: assigneeIdParams,
      parentNotAssignedTo: currentUserId,
      periodBegin: periodBegin,
      periodEndBefore: periodEndBefore,
      state: ['running', 'completed', 'reviewed', 'draft', 'edited'],
      type: ['goal', 'annual_goal']
    };
    if (placementId) {
      newFilter.placementId = placementId;
    }
    return getObjectivesCount(newFilter);
  };

  let { data, refetch } = useQuery(
    ['overallProgress', assigneeId, profileFilter],
    getCount,
    {
      suspense: true,
      forceFetchOnMount: true,
      enabled: page !== 'performanceTab'
    }
  );

  let overallData = data?.data;
  let listStatus = overallData?.listStatus;
  let listPhases = overallData?.listPhases;
  let totalObjectives = overallData?.totalObjectives;
  let progressColorHex = overallData?.progressColorHex;
  let overallProgress = overallData?.overallProgress;
  let totalWeight = overallData?.totalWeights;
  let canEditWeight =
    page == 'direct-report' || page == 'indirect-report'
      ? false
      : overallData?.editWeightGoal;

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      refetch();
    }
  }, [filter, profileFilter]);

  const gridStyle = gridArea ? { gridArea: 'analytics' } : '';

  listStatus = listStatus?.map((v, i) => {
    let progressTitle, colorLine;
    if (v.progressColorHex === 'FE2851') {
      progressTitle = getObjectiveLocale('At risk');
      colorLine = 'bg-r-500';
    }
    if (v.progressColorHex === 'FFCD00') {
      progressTitle = getObjectiveLocale('Left behind');
      colorLine = 'bg-y-500';
    }
    if (v.progressColorHex === '44DB5E') {
      progressTitle = getObjectiveLocale('On track');
      colorLine = 'bg-g-500';
    }
    if (v.progressColorHex === '5A9AEF') {
      progressTitle = getObjectiveLocale('Exceeded');
      colorLine = 'bg-b-500';
    }

    return {
      ...v,
      key: i,
      progressTitle: progressTitle,
      colorLine: colorLine
    };
  });

  let mainStyle = {
    ...gridStyle
  };
  if (isVisible('progress')) {
    mainStyle = {
      ...mainStyle,
      borderBottom: '1px solid var(--n-400)'
    };
  }

  if (isVisible('phase')) {
    mainStyle = {
      ...mainStyle,
      border: '1px solid var(--n-300)'
    };
  }

  if (props?.border == 'no-border') {
    mainStyle = {
      ...mainStyle,
      borderBottom: '0px',
      display: '-webkit-box'
    };
  }

  // for set top sidebar
  const ref = useRef(null);
  const setTopSidebar = useSidebar((state) => state?.setTopSidebar);

  useEffect(() => {
    if (ref?.current && page == 'mygoals') {
      setTopSidebar(ref?.current?.getBoundingClientRect()?.bottom);
    }
  }, [ref?.current]);

  const containerCompactOverallViewProgressCN = ctl(`
  container-compact-overall-view-progress bg-n-000 flex justify-between
  ${
    isVisible('progress')
      ? page == 'mygoals'
        ? 'pb-[12px] top-[56px] sticky z-10'
        : page == 'performanceTab'
        ? 'rounded-[4px]'
        : (page == 'direct-report' || page == 'indirect-report') &&
          'mx-[24px] py-[16px]'
      : isVisible('phase') && 'justify-between px-[24px] py-[12px]'
  }`);

  const wrapperStatusOverallViewProgressCN = ctl(`
  w-full flex flex-row justify-between bg-n-200 rounded-r-[4px]
  ${
    page == 'performanceTab'
      ? 'border-solid border-[1px] border-n-400 py-0 px-0'
      : 'py-[8px] px-[16px]'
  }`);

  return (
    <>
      <div
        className={containerCompactOverallViewProgressCN}
        style={mainStyle}
        ref={ref}
      >
        {annual && (
          <div className="flex py-[8px] px-[24px] border-y-0 border-r-0 h-[56px] w-[144px] flex-col bg-n-300 rounded-l-[4px]">
            <p className="typography-h200 font-[500] text-n-600 leading-[16px]">
              {getObjectiveLocale('Annual')}
            </p>
            <p className=" text-n-600 typography-h600">
              {filter.periodEndBefore
                ? dayjs(filter.periodEndBefore).year()
                : dayjs().year()}
            </p>
          </div>
        )}
        <div className={wrapperStatusOverallViewProgressCN}>
          {isVisible('progress') && (
            <OverallProgressCompact
              progressColorHex={progressColorHex}
              overallProgress={overallProgress}
              totalObjectives={totalObjectives}
              totalWeight={totalWeight}
              canEditWeight={
                page == 'performanceTab'
                  ? user?.id == currentUserId && canEditWeight
                  : canEditWeight
              }
              listStatus={listStatus}
              statusActive={statusActive}
              filterByStatus={dispatchFilter && filterByStatus}
              useCompactLayout={useCompactLayout}
              isVisible={isVisible}
              annual={annual}
              page={page}
            />
          )}
          {isVisible('phase') && listPhases && (
            <OverallPhaseCompact
              filterByPhase={dispatchFilter && filterByPhase}
              phaseActive={phaseActive}
              listPhases={listPhases}
              totalObjectives={totalObjectives}
            />
          )}
          {isVisible('status') && !isVisible('progress') && (
            <OverallStatusCompact
              listStatus={listStatus}
              statusActive={statusActive}
              filterByStatus={dispatchFilter && filterByStatus}
              page={page}
            />
          )}
        </div>
      </div>

      {children &&
        React.cloneElement(children, { dispatchFilter, filter, ...rest })}
    </>
  );
};

const StatisticsOverallViewProgressCompact = (props) => {
  return (
    <React.Suspense fallback={<div></div>}>
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    </React.Suspense>
  );
};

export default StatisticsOverallViewProgressCompact;

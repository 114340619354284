import React, { useState } from 'react';

import { useGoalWeightSettingsContext } from 'context/GoalWeightSettingContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Checkbox from 'components/shared/Checkbox';

const WeightSettingsCheckbox = ({ defaultValue, onChangeCallback, label }) => {
  const { isReadOnly } = useGoalWeightSettingsContext();
  const [value, setValue] = useState(defaultValue);

  const toggleCheck = async () => {
    setValue((data) => !data);
    if (onChangeCallback) {
      const isSuccess = await onChangeCallback(!value);
      !isSuccess && setValue(defaultValue);
    }
  };

  return (
    <Checkbox
      name="use-default-type-checkbox"
      id="use-default-type-checkbox"
      checked={value}
      onChange={toggleCheck}
      style={{ marginLeft: '-3px' }}
      dataCy="use-default-type"
      disabled={isReadOnly}
    >
      <span
        className={`typography-paragraph ml-[5px] ${
          isReadOnly ? 'text-n-600' : 'text-n-900'
        }`}
      >
        {getObjectiveLocale(label)}
      </span>
    </Checkbox>
  );
};

export default WeightSettingsCheckbox;

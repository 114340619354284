import React, { useState } from 'react';

import Button from 'components/design-system/Button';
import RadioButton from 'src/components/shared/RadioButton';

import { configScoringMechanism } from '../ConfigRatingTemplate';
import ContentPercentageRangeProgress from './ContentPercentageRangeProgress';
import ModalSelectAttributes from './ModalSelectAttributes';

const ContentScoringMechanism = ({
  // state and setState scoring mechanism
  activeMechanismId,
  setActiveMechanismId,
  selectedListAttribute,
  setSelectedListAttribute,

  // state and setState for percentage range progress
  configArray,
  setConfigArray
}) => {
  const [isModalSelectAttributeOpen, setIsModalSelectAttributeOpen] =
    useState(false);

  const removeAttribute = (idx) => {
    let newLisAttributes = [...selectedListAttribute];
    newLisAttributes.splice(idx, 1);

    setSelectedListAttribute(newLisAttributes);
  };

  return (
    <div
      id="scoring-mechanism"
      className="container-content-scoring-mechanism mb-[100px]"
    >
      <p className="typography-h500">Scoring mechanism</p>
      <p className="content-subtitle">
        Set how this rating template will be given
      </p>

      {configScoringMechanism.map((val, idx) => (
        <div className="wrapper-scoring-type" key={idx}>
          <RadioButton
            dataCy={'scoring-mechanism-' + val.id}
            size="small"
            value={val.title}
            id={val.id}
            checked={activeMechanismId == val.titleId}
            onChange={() => {
              setActiveMechanismId(val.titleId);
            }}
          >
            <span className="ml-[8px]">
              {location.pathname.includes('task')
                ? val.title.replace('goal', 'task')
                : val.title}
            </span>
          </RadioButton>
        </div>
      ))}

      {activeMechanismId == 'work_attributes' && (
        <div className="container-rating-template-card">
          <p className="card-title">Attributes</p>
          <p className="card-subtitle">
            You can specify and choose how many attributes to assess (minimum 1)
          </p>

          {selectedListAttribute &&
            selectedListAttribute.map((val, idx) => (
              <div
                data-cy={'wrapper-work-attibutes-' + val.id}
                className="wrapper-attributes"
                key={idx}
              >
                <p className="attribute-title">{val?.title || val?.name}</p>
                <Button.Tertiary
                  datacy={'remove-work-attributes-' + val.id}
                  onClick={() => removeAttribute(idx)}
                >
                  Remove
                </Button.Tertiary>
              </div>
            ))}

          <Button.Secondary
            customClass="mt-[16px] w-full"
            datacy="button-select-work-attributes"
            onClick={() => setIsModalSelectAttributeOpen(true)}
          >
            <i className="fa fa-plus"></i>Add Another Attributes
          </Button.Secondary>
        </div>
      )}

      {activeMechanismId == 3 && (
        <ContentPercentageRangeProgress
          configArray={configArray}
          setConfigArray={setConfigArray}
          customSubtitle={'You can set percentage for each rating'}
        />
      )}

      {isModalSelectAttributeOpen && (
        <ModalSelectAttributes
          selectedListAttribute={selectedListAttribute}
          setSelectedListAttribute={setSelectedListAttribute}
          setIsModalSelectAttributeOpen={setIsModalSelectAttributeOpen}
        />
      )}
    </div>
  );
};

export default ContentScoringMechanism;

import React from 'react';

import CustomInnerIcon from 'components/design-system/CustomInnerIcon';

const CategoryNumberCell = ({ value }) => {
  return (
    <CustomInnerIcon
      iconText={value}
      backgroundColor={'var(--n-600)'}
      borderColor={'var(--n-600)'}
    />
  );
};

export default CategoryNumberCell;

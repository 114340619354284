import React from 'react';

const HeaderTableBehaviorModel = ({ type }) => {
  return (
    <div className="header-table mb-[8px]">
      <div className="pl-[16px] w-[35%]">
        <span className="typography-h100 typography-secondary uppercase">
          {type} list
        </span>
      </div>
      <div className="pl-[16px] w-1/2">
        <span className="typography-h100 typography-secondary uppercase">
          Description
        </span>
      </div>
      <div className="pl-[16px] w-[15%]">
        <span className="typography-h100 typography-secondary uppercase">
          Weight (%)
        </span>
      </div>
    </div>
  );
};

export default HeaderTableBehaviorModel;

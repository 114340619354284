import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { formatTimezone } from 'utils/DateUtils';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import Objectives from 'components/objectives/Objectives';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

function OtherGoals({ route: { title } }) {
  const history = useHistory();
  const { match } = useUrl();
  const { user } = useUser();
  let params = useParams();

  const filter = {
    state: ['running', 'completed'],
    limit: 50,
    markStarts: 5,
    periodBegin: formatTimezone(`${new Date().getFullYear()}-01-01`, 'start'),
    periodEndBefore: formatTimezone(`${new Date().getFullYear()}-12-31`, 'end')
  };

  const switchOptions = [
    {
      id: 'follower',
      name: 'As Follower',
      filter: {
        followerId: user.id,
        involves: { userId: user.id, role: 'follower' }
      },
      onClick: () => history.replace('/goals/others/follower')
    },
    {
      id: 'reviewer',
      name: 'As Reviewer',
      filter: {
        assignerId: user.id,
        involves: { userId: user.id, role: 'assigner' }
      },
      onClick: () => history.replace('/goals/others/reviewer')
    }
  ];

  let defaultTab =
    switchOptions?.find((tab) => tab?.id === params.tab)?.id ||
    switchOptions[0]?.id;

  const [activeTab, changeTab] = useState(defaultTab);

  let optionActive = switchOptions.find((option) => option.id === activeTab);
  let extraFilter = optionActive?.filter || {};

  return (
    <>
      <HeaderPage
        titlePage={title}
        tabs={{
          tabsList: switchOptions,
          url: '/goals/others/follower',
          onChange: (tab) => {
            changeTab(tab);
          }
        }}
      />
      <div className="mt-[24px]">
        <AdvancedFilterObjectives
          filterOptions={[
            'search',
            'group',
            'sort',
            'dateFilter',
            'menuFilter'
          ]}
          defaultFilter={filter}
          group={'owner'}
          page={`directReport+${activeTab}`}
          filterId={'filter-other'}
          type="others"
          customClass="mb-[24px]"
        >
          <Objectives
            extraFilter={extraFilter}
            page={`others+${activeTab}`}
            group={'owner'}
            cardModel="goal-list"
          />
        </AdvancedFilterObjectives>
      </div>
      <React.Suspense fallback={<div></div>}>
        {match && <SidebarMyGoals />}
      </React.Suspense>
    </>
  );
}

export default OtherGoals;

import React, { useEffect } from 'react';

import { getObjectivePhases } from 'client/ObjectivesClient';
import useEditPhase from 'hooks/useEditPhase';
import useModalObjectives from 'hooks/useModalObjectives';
import useTasks from 'hooks/useTasks';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

const PhaseOptions = ({
  projectId,
  dialogOpen,
  setDialogOpen,
  type = 'list',
  iconSize = '24'
}) => {
  const { setEditedPhaseData, setCurrentPhaseData } = useEditPhase();
  const { showModal } = useModalObjectives();
  const { sectionDialogOpen, setSectionDialogOpen, setPhaseDialogOpen } =
    useTasks();

  const options = [{ text: 'Edit Phase', onClick: () => onClickRename() }];

  const getPhases = async () => {
    let params = {};
    if (projectId) {
      params.projectId = projectId;
    }
    const { data } = await getObjectivePhases(params);
    return data ? data : [];
  };

  const onClickRename = async () => {
    const listPhases = await getPhases();
    showModal({ modalType: 'editPhases', props: { projectId, type } });
    setEditedPhaseData(listPhases);
    setCurrentPhaseData(listPhases);
    setDialogOpen(false);
  };

  useEffect(() => {
    if (dialogOpen) {
      sectionDialogOpen && setSectionDialogOpen(false);
    }
    setPhaseDialogOpen(dialogOpen);
  }, [dialogOpen, sectionDialogOpen, setPhaseDialogOpen, setSectionDialogOpen]);

  return (
    <Dropdown>
      <Dropdown.Trigger onClick={() => setDialogOpen(!dialogOpen)}>
        <SVGIcon
          size={iconSize.toString()}
          fillColor="var(--n-600)"
          iconName="icon-keyboard_control"
        />
      </Dropdown.Trigger>
      <Dropdown.MenuItems
        customClass="w-[220px]"
        options={options}
        setDialogOpen={() => setDialogOpen(false)}
        type={type === 'list' ? 'fixed' : 'absolute'}
      />
    </Dropdown>
  );
};

export default PhaseOptions;

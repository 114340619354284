import React from 'react';

import { phaseName } from 'utils/FormalReview';

import ProgressTracker from 'src/components/design-system/ProgressTracker/ProgressTracker';

function ReviewResultSidebar({
  phases,
  currentPhase,
  loadAssignment,
  tracks,
  loadTrack,
  currentTrack,
  currentTrackId
}) {
  const options = tracks?.map((track, index) => {
    return {
      name: track.name,
      iconText: index + 1,
      iconName: null,
      active: currentTrackId == track.id,
      disabled: false,
      onClick: () => loadTrack(track.trackType, track.id, track.name),
      dataCy: `track-${track.name}`.toLocaleLowerCase().replace(/ /g, '-')
    };
  });

  return (
    <div className="result-other-container">
      <div className="col-md-2 result-other-sidebar pt-[24px] px-[0px]">
        <div className="upper-sidebar mb-[24px]">
          <h5 className="typography-h300 typography-secondary mb-[16px] pr-[24px] pl-[24px]">
            VIEW REVIEW BY
          </h5>
          {phases?.map((phase, index) => {
            return (
              <div
                className={`sidebar-left-navigator-menu py-[12px] px-[40px] ${
                  currentPhase === phase.type ? 'active' : ''
                }`}
                key={index}
                onClick={() => loadAssignment(phase.type)}
                data-cy={phaseName(phase.type)}
              >
                <p className="typography-h400 cl-gray-600">
                  {phaseName(phase.type)}
                </p>
              </div>
            );
          })}
        </div>
        {(tracks || []).length > 0 && (
          <div className="border-bottom-400 border-top-400">
            <div className="all-steps-container py-[24px]">
              <ProgressTracker options={options} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReviewResultSidebar;

import React, { useEffect, useState } from 'react';

import { getConfig, updateConfig } from 'client/admin/Config';
import { GoalWeightSettingsContext } from 'context/GoalWeightSettingContext';

import LoadingComponent from 'components/shared/LoadingComponent';

import WeightSettingsMaximum from './WeightSettingsMaximum';
import WeightSettingsMaximumSub from './WeightSettingsMaximumSub';
import WeightSettingsMaximumSum from './WeightSettingsMaximumSum';
import WeightSettingsMinimum from './WeightSettingsMinimum';
import WeightSettingsMinimumSub from './WeightSettingsMinimumSub';
import WeightSettingsType from './WeightSettingsType';

const WeightSettings = ({ setIsSaving, isReadOnly }) => {
  const [configData, setConfigData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const changeObjectiveConfig = async (config) => {
    const { isSuccess } = await updateConfig({ objectivesConfig: config });
    setIsSaving(true);
    if (isSuccess) {
      setConfigData((data) => ({
        ...data,
        objectivesConfig: { ...data?.objectivesConfig, ...config }
      }));
      return true;
    }
    return false;
  };

  const getData = async () => {
    setLoading(true);
    const { data: configData } = await getConfig();
    if (configData) {
      setConfigData(configData);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="min-h-[calc(100vh-64px-46px)] py-[8px] inline-grid">
      {isLoading && !configData ? (
        <LoadingComponent />
      ) : (
        <div className="w-[768px]">
          <GoalWeightSettingsContext.Provider
            value={{ isReadOnly, setIsSaving }}
          >
            <WeightSettingsMinimum
              configData={configData}
              changeObjectiveConfig={changeObjectiveConfig}
              isReadOnly={isReadOnly}
            />
            <WeightSettingsMaximum
              configData={configData}
              changeObjectiveConfig={changeObjectiveConfig}
              isReadOnly={isReadOnly}
            />
            <WeightSettingsMinimumSub
              configData={configData}
              changeObjectiveConfig={changeObjectiveConfig}
              isReadOnly={isReadOnly}
            />
            <WeightSettingsMaximumSub
              configData={configData}
              changeObjectiveConfig={changeObjectiveConfig}
              isReadOnly={isReadOnly}
            />
            <WeightSettingsMaximumSum
              configData={configData}
              changeObjectiveConfig={changeObjectiveConfig}
              isReadOnly={isReadOnly}
            />
            <WeightSettingsType
              configData={configData}
              setConfigData={setConfigData}
              setIsSaving={setIsSaving}
            />
          </GoalWeightSettingsContext.Provider>
        </div>
      )}
    </div>
  );
};

export default WeightSettings;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';

const OwnerReviewer = ({ objectiveDetail }) => {
  const owners = objectiveDetail?.involvements
    ?.filter(({ role, visible }) => {
      return role == 'assignee' && visible;
    })
    .map(({ user }) => {
      return {
        type: 'circle',
        name: user.name,
        src: user.profilePicture,
        tooltip: true
      };
    });

  const reviewers = objectiveDetail?.involvements
    ?.filter(({ role, visible }) => {
      return role == 'assigner' && visible;
    })
    .map(({ user }) => {
      return {
        type: 'circle',
        name: user.name,
        src: user.profilePicture,
        tooltip: true
      };
    });

  return (
    <div className="flex px-[24px] mt-[16px] w-full">
      {owners?.length > 0 && (
        <div className="w-1/2 mr-[12px]" data-cy="sidebar-detail-owner">
          <p className="typography-h200 mb-[8px]">
            {getObjectiveLocale('Objective / Task Owner')}
          </p>
          {owners?.length == 1 && <SingleUser userData={owners[0]} />}
          {owners?.length > 1 && <AvatarGroup avatars={owners} size="24" />}
        </div>
      )}
      {reviewers?.length > 0 && (
        <div className="w-1/2 ml-[12px]" data-cy="sidebar-detail-reviewer">
          <p className="typography-h200 mb-[8px]">
            {getObjectiveLocale('Objective / Task Reviewer')}
          </p>
          <SingleUser userData={reviewers[0]} />
        </div>
      )}
    </div>
  );
};

export default OwnerReviewer;

import client from '../ApiClient';

function getActivationTrend(query) {
  return client(`/v1/admin/users/state_changes`, { params: query });
}

function getPerformanceScore(query) {
  return client(`/v1/admin/objectives/scoring_counts`, { params: query });
}

function getObjectivesComponent(query) {
  return client(`/v1/admin/objectives/counts`, { params: query });
}

function getMostDataGiven(query) {
  return client(`/v1/admin/skills`, { params: query });
}

export {
  getActivationTrend,
  getPerformanceScore,
  getObjectivesComponent,
  getMostDataGiven
};

import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  getGroupById,
  postGroupsPermission,
  updateGroupsPermission
} from 'client/adminClient';
import { useUser } from 'context/UserContext';
import { UsersAdminProvider } from 'context/admin/UsersAdminContext';
import useFilter from 'hooks/useFilter';

import HeaderPage from 'components/admin/HeaderPage';
import Footer from 'components/shared/Footer';
import VerticalStepsComponent from 'components/shared/VerticalStepsComponent';
import { toSnakeCase } from 'src/utils/caseConverter';

import './GroupPermissions.scss';
import StepConfigurePermissions from './Steps/StepConfigurePermissions';
import StepNameDescription from './Steps/StepNameDescription';
import StepSetMembers from './Steps/StepSetMembers';
import StepVerify from './Steps/StepVerify';

function CreateGroup({ route: { title } }) {
  const setFilter = useFilter((state) => state.setFilter);
  const { appType } = useUser();
  const { type, id } = useParams();
  const initialEdit = useRef(false);
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [members, setMembers] = useState({
    includes: [],
    excludes: [],
    select_all: false
  });
  const [group, setGroup] = useState({
    name: '',
    description: '',
    info: {},
    group_type:
      appType === 'culture' ? 'culture_permission_group' : 'permission_group'
  });

  const isSecurityAdmin = group?.is_security_admin;

  const hasMember =
    group.member_count > 0 || members.select_all || members.includes.length > 0;
  const [stepsArray, setStepsArray] = useState([
    {
      name: 'Name & Description',
      completed: false,
      disabled: false
    },
    {
      name: 'Members',
      completed: false,
      disabled: type === 'create'
    },
    {
      name: 'Configuration',
      completed: type === 'edit',
      disabled: type === 'create'
    },
    {
      name: 'Verify',
      completed: false,
      disabled: type === 'create'
    }
  ]);

  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [loading, setLoading] = useState(false);

  const changeGroupKey = (key, data) => {
    setGroup({
      ...group,
      [key]: data
    });
  };

  const submitStep1 = async () => {
    appType === 'culture' && delete group?.info;
    const { data } = await postGroupsPermission(group);
    if (data) {
      return data;
    } else {
      return false;
    }
  };

  const updateGroup = async () => {
    const body = {
      description: group.description,
      group_type: group.group_type,
      info: group.info,
      name: group.name
    };
    appType === 'culture' && delete body?.info;
    const { data } = await updateGroupsPermission(
      id,
      {
        ...body,
        ...toSnakeCase(members),
        permissions: selectedPermissions
      },
      true
    );
    if (data) {
      return data;
    } else {
      return false;
    }
  };

  const _getGroupData = async (id) => {
    setLoading(true);
    const { data } = await getGroupById(id, null, true);

    if (data) {
      const initialSelectedValues = Object.values(data?.permissions).reduce(
        (res, permissions) => {
          res = { ...res, ...permissions };
          return res;
        },
        {}
      );
      setMembers({
        excludes: [],
        select_all: false,
        includes: data.member_ids
      });
      setSelectedPermissions(initialSelectedValues);
      setGroup(data);

      if (initialEdit.current) {
        setStepsArray([
          {
            name: 'Name & Description',
            completed: data.name,
            disabled: false
          },
          {
            name: 'Members',
            completed: data.member_count > 0,
            disabled: type === 'create'
          },
          {
            name: 'Configuration',
            completed: true,
            disabled: type === 'create'
          },
          {
            name: 'Verify',
            completed: data.name && data.member_count > 0,
            disabled: type === 'create'
          }
        ]);
      }

      setLoading(false);
    }
  };

  const submitName = async (noStep = false) => {
    let tempStepsArray = JSON.parse(JSON.stringify(stepsArray));
    const data = id ? await updateGroup() : await submitStep1();
    if (data) {
      const { id } = data;
      history.push(`/group-permissions/edit/${id}`);
      tempStepsArray[step].completed = Boolean(group.name);
      tempStepsArray.map((data) => {
        data.disabled = false;
      });
      setStepsArray(tempStepsArray);
      !noStep && setStep(step + 1);
    }
  };

  const submitStepAfter = async (type, noStep = false) => {
    let tempStepsArray = JSON.parse(JSON.stringify(stepsArray));
    const data = await updateGroup();
    if (data) {
      tempStepsArray[step].completed = type == 'member' ? hasMember : true;
      tempStepsArray[3].completed =
        tempStepsArray[0].completed &&
        tempStepsArray[1].completed &&
        tempStepsArray[2].completed;
      setStepsArray(tempStepsArray);
      !noStep && setStep(step + 1);
    }
  };

  const handleStepChange = async (noStep = false) => {
    switch (step) {
      case 0:
        submitName(noStep);
        break;
      case 1:
        submitStepAfter('member', noStep);
        setFilter('');
        break;
      case 2:
        submitStepAfter('configure', noStep);
        break;
    }
  };

  const setActiveStepNow = (activeName) => {
    handleStepChange(true);
    stepsArray.map((step, index) => {
      if (step.name == activeName) {
        setStep(index);
      }
    });
  };

  useEffect(() => {
    if (id) {
      _getGroupData(id);
    }
  }, [step]);

  useEffect(() => {
    if (type === 'edit') {
      initialEdit.current = true;
    } else {
      initialEdit.current = false;
    }
  }, []);

  return (
    <>
      <HeaderPage
        titlePage={
          location.pathname.includes('edit') ? 'Edit Permissions Group' : title
        }
        backToUrl={`/group-permissions`}
        isHeaderComposer={true}
      />
      <div className="create-group-permissions flex pl-[0px] pl-[0px] bg-n-000">
        <VerticalStepsComponent
          steps={stepsArray}
          activeStep={stepsArray[step].name}
          setActiveStep={setActiveStepNow}
        />
        <div className="mt-[24px] w-[83%]">
          <div className="ml-[40px] min-h-[calc(100vh-137px)]">
            {(() => {
              switch (step) {
                case 0:
                  return (
                    <StepNameDescription group={group} setGroup={setGroup} />
                  );
                case 1:
                  return (
                    <UsersAdminProvider>
                      <StepSetMembers
                        members={members}
                        setMembers={setMembers}
                        customTableClass="mb-[64px]"
                      />
                    </UsersAdminProvider>
                  );
                case 2:
                  return (
                    <StepConfigurePermissions
                      permissionsData={group.permissions || {}}
                      setGroup={changeGroupKey}
                      selectedPermissions={selectedPermissions}
                      setSelectedPermissions={setSelectedPermissions}
                      loading={loading}
                      isSecurityAdmin={isSecurityAdmin}
                      info={group?.info}
                    />
                  );
                case 3:
                  return (
                    <UsersAdminProvider>
                      <StepVerify group={group} loading={loading} />
                    </UsersAdminProvider>
                  );
              }
            })()}
          </div>
          <Footer
            linkButton={true}
            linkButtonName={'Back'}
            linkButtonDisabled={loading}
            save={false}
            buttonPrimaryName={step === 3 ? 'Submit' : 'Next'}
            buttonPrimaryDisabled={loading}
            handleTesting="btn-submit"
            handleClick={
              step != 3
                ? () => handleStepChange()
                : () => history.replace('/group-permissions?tab=groups')
            }
            handleRoute={
              step == 0
                ? () => history.replace(`/group-permissions?tab=default`)
                : () => setStep(step - 1)
            }
          />
        </div>
      </div>
    </>
  );
}
export default CreateGroup;

import React, { useEffect, useState } from 'react';

import {
  getCalibrationHistories,
  getScoreCardDetail
} from 'client/FormalReviewClient';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { capitalize } from 'utils/HelperUtils';

import WhiteCard from 'components/admin/analytic/team-review/WhiteCard.js';
import CalibrationHistorySidebar from 'components/formal-review/evidence-sidebar/CalibrationHistorySidebar.js';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';

function ScoreCard({
  cycleId,
  currentPhaseId,
  currentPhase,
  targetId,
  targetObj,
  showCalibrationHistories
}) {
  const [showCalibrationSidebar, setShowCalibrationSidebar] = useState(false);
  const [cardLoading, setCardLoading] = useState(true);
  const [scoreDetail, setScoreDetail] = useState({});

  const getData = async () => {
    const { data } = await getScoreCardDetail(cycleId, targetId, {
      type: currentPhase,
      phaseId: currentPhaseId
    });

    if (data) {
      setScoreDetail(data);
    }
    setCardLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const shouldShowBreakdown = scoreDetail.scoreAspects
    ? scoreDetail.scoreAspects.length != 0
    : false;

  const leftContent =
    scoreDetail.performanceCategory ||
    scoreDetail.final ||
    showCalibrationHistories;

  if (
    !scoreDetail.performanceCategory &&
    !scoreDetail.final &&
    !shouldShowBreakdown
  ) {
    return (
      <ListEmptyState
        fullHeight
        emptyIcon="icon-no-result-found"
        title={getObjectiveLocale('Results are coming soon')}
        subtitle={getObjectiveLocale(
          'When your reviewer have submitted their responses, you will see it here'
        )}
        containerClassname="mt-[64px]"
      />
    );
  }

  return (
    <>
      <WhiteCard classes="px-[24px] pb-[24px] pt-[16px]">
        <div className="score-header mb-[16px]">
          <h4 className="typography-h500">Score Card</h4>
        </div>
        {cardLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="score-body flex">
              {leftContent && (
                <div className="score-left-side w-[256px]">
                  {scoreDetail.performanceCategory && (
                    <div className="border border-solid border-n-300 p-[12px] mb-[16px] rounded-lg">
                      <div className="typography-h100 typography-tertiary mb-[12px]">
                        Performance Scoring
                      </div>
                      <h2
                        className="typography-h800 mb-[0px]"
                        style={{
                          color: `#${scoreDetail.performanceCategory.statusColorHex}`
                        }}
                      >
                        {scoreDetail.performanceCategory.performanceCategory}
                      </h2>
                    </div>
                  )}
                  {scoreDetail.final && (
                    <div
                      className={
                        'border border-solid border-n-300 p-[12px] mb-[16px] rounded-lg'
                      }
                    >
                      <div className="typography-h100 typography-tertiary mb-[12px]">
                        Final Score
                      </div>
                      <h2
                        className="typography-h800 mb-[0px]"
                        style={{
                          color: `#${scoreDetail.final.statusColorHex}`
                        }}
                      >
                        {scoreDetail.final.statusName}
                      </h2>
                      <h2 className="typography-h800 my-[12px]">
                        {scoreDetail.final.score.toFixed(2)}
                      </h2>
                    </div>
                  )}
                  {showCalibrationHistories && (
                    <p
                      className="typography-link typography-h400 cursor-pointer"
                      onClick={() => setShowCalibrationSidebar(true)}
                    >
                      View Calibration History
                    </p>
                  )}
                </div>
              )}
              {shouldShowBreakdown && (
                <div
                  className={`breakdown-wrapper ml-[24px] ${
                    !leftContent ? 'full' : ''
                  }`}
                >
                  <div className="score-right-side bg-n-200 p-12">
                    <h5 className="typography-h100 typography-tertiary mb-[12px]">
                      Breakdown score
                    </h5>
                    {scoreDetail.scoreAspects.map((data, index) => (
                      <div
                        className={`flex justify-between items-start min-h-[32px] ${
                          index != 0 ? 'mt-[16px]' : ''
                        }`}
                        key={index}
                      >
                        <p
                          className={`typography-paragraph min-w-[120px] ${
                            leftContent ? 'max-w-[200px]' : 'max-w-[400px]'
                          } mr-[8px]`}
                        >
                          {capitalize(data.name)}
                        </p>
                        <p className="py-[4px] typography-h400 text-right">
                          ({data.score.toFixed(2)}){' '}
                          <span
                            className="typography-h400"
                            style={{ color: `#${data.statusColorHex}` }}
                          >
                            {data.statusName}
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </WhiteCard>
      {showCalibrationSidebar && (
        <CalibrationHistorySidebar
          cycleId={cycleId}
          setShowCalibrationSidebar={setShowCalibrationSidebar}
          userData={targetObj}
          withHeaderButton={false}
        />
      )}
    </>
  );
}

export default ScoreCard;

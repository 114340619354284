import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getBehaviors, putBehaviors } from 'client/adminClient.js';
import { useValueCompetency } from 'context/admin/ValueCompetencyContext';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import SVGIcon from 'components/shared/SVGIcon';

import { Textarea } from './InlineTextarea';
import NewSkeletonChildDictionary from './NewSkeletonChildDictionary';
import {
  DialogAddLevel,
  ModalValuesImage,
  TooltipValueCompetency
} from './ValueCompetencyReuseableComponent';

const NewDictionaryCard = ({
  dictionary,
  isReadOnly,
  chooseValueImage,
  handleChangeInputFile,
  index
}) => {
  const { pathname } = useLocation();
  const {
    showInlineCreate,
    setShowInlineCreate,
    isChooseImageOpen,
    setIsChooseImageOpen,
    configValueCompetency,
    paramsDictionaryOrModel,
    getValueOrCompetencyData,
    showNotificationMessage,
    currentTab,
    currentFilterTab,
    isValuesPage,
    dataValueOrCompetency
  } = useValueCompetency();
  const [isCardActive, setIsCardActive] = useState(null);
  const [isCardChildActive, setIsCardChildActive] = useState(null);
  const [accordionActive, setAccordionActive] = useState(null);
  const [dataKeyBehavior, setDataKeyBehavior] = useState([]);
  const [hasMoreChild, setHasMoreChild] = useState(false);
  const [nextOffsetChild, setNextOffsetChild] = useState(0);
  const [currentSizeChild, setCurrentSizeChild] = useState(0);
  const [singleLoading, setSingleLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [isAddLevelOpen, setIsAddLevelOpen] = useState(false);
  const [levelBasic, setLevelBasic] = useState({ isActive: false });

  const config = configValueCompetency;
  const competenciesUseBasicLevel = config?.competenciesUseBasicLevel;
  const valuesUseBasicLevel = config?.valuesUseBasicLevel;
  const id = dictionary?.id;

  const archiveOrRestoreDictionary = async (id, childId) => {
    let text = currentFilterTab == 'archive' ? 'Restore' : 'Archive';
    let body = {
      behaviorIds: childId ? [childId] : [id],
      state: currentFilterTab == 'archive' ? 'active' : 'archive'
    };

    if (childId) setSingleLoading(true);

    const { data, error } = await putBehaviors(body);
    if (data) {
      showNotificationMessage(`${text} Successfully!`);
      if (childId) _getKeyBehaviorData(id, 'refresh');
      else await getValueOrCompetencyData(pathname);
    }
  };

  const createKeyBehavior = (id, hasKeyBehaviors, type = 'level') => {
    setLevelBasic({ ...levelBasic, isActive: type == 'basic' });
    setTimeout(async () => {
      if (hasKeyBehaviors) await _getKeyBehaviorData(id);
      setShowInlineCreate(id);
      setIsAddLevelOpen(false);
      document.getElementById(`keyBehaviorName-${id}`).focus();
    }, 50);
  };

  const handleOpenAccordion = async (id) => {
    let active = accordionActive == id ? null : id;
    await _getKeyBehaviorData(active);
  };

  const handleAddKeyBehavior = () => {
    if (
      config?.behaviorsFramework == 'proficiency_levels' &&
      !(dictionary?.levels || []).includes(0) &&
      (competenciesUseBasicLevel || valuesUseBasicLevel)
    ) {
      setIsAddLevelOpen(true);
    } else {
      createKeyBehavior(id, dictionary.hasKeyBehaviors);
    }
  };

  const _getKeyBehaviorData = async (id, refreshState) => {
    if (
      (dataKeyBehavior?.length == 0 &&
        dictionary.hasKeyBehaviors &&
        !isDataEmpty) ||
      refreshState == 'refresh'
    ) {
      let _params = paramsDictionaryOrModel(pathname, { parentId: id });
      setSingleLoading(true);
      setShowInlineCreate(null);
      const { data, pagination } = await getBehaviors(_params);
      if (data) {
        setDataKeyBehavior(data);
        setHasMoreChild(pagination.nextOffset == null ? false : true);
        setNextOffsetChild(pagination.nextOffset);
        setCurrentSizeChild(pagination.currentSize + currentSizeChild);
        setSingleLoading(false);
        setIsDataEmpty(false);
      }
      if (data?.length == 0) {
        setSingleLoading(false);
        setIsDataEmpty(true);
      }
    }
    setAccordionActive(id);
  };

  const loadMore = async () => {
    const _params = paramsDictionaryOrModel(pathname, {
      parentId: id,
      offset: nextOffsetChild
    });
    setLoadMoreLoading(true);
    const { data, pagination } = await getBehaviors(_params);
    if (data) {
      setDataKeyBehavior(dataKeyBehavior.concat(data));
      setHasMoreChild(pagination.nextOffset == null ? false : true);
      setNextOffsetChild(pagination.nextOffset);
      setCurrentSizeChild(pagination.currentSize + currentSizeChild);
    }
    setLoadMoreLoading(false);
  };

  useEffect(() => {
    setAccordionActive(null);
    setDataKeyBehavior([]);
  }, [currentFilterTab]);

  useEffect(() => {
    setIsCardActive(null);
  }, [dataValueOrCompetency]);

  useEffect(() => {
    setIsCardChildActive(null);
  }, [dataKeyBehavior]);

  const sourceImage =
    dictionary?.iconImageAttributes?.iconUrl ||
    Config.asset.admin.defaultValueImg;
  const highestLevel = Math.max(
    ...(dictionary?.levels?.length > 0 ? dictionary.levels : [0])
  );

  return (
    <>
      <div
        className={`new-dictionary-card py-[16px] flex justify-between items-start ${
          isCardActive == id && 'card-active'
        }`}
      >
        <div className="w-[30%] pl-[16px] column-inline">
          <div className="flex relative">
            <SVGIcon
              dataCy={`dictionary-accordion-${id}`}
              customClass={`mt-[6px] icon-chevron ${
                dictionary?.hasKeyBehaviors ? 'visible' : 'invisible'
              } `}
              id={`icon-accordion-${id}`}
              onClick={() => handleOpenAccordion(id)}
              size="24"
              fillColor="var(--base-600)"
              iconName={`${
                accordionActive != id
                  ? 'icon-keyboard_arrow_right'
                  : 'icon-keyboard_arrow_down'
              }`}
            />

            {pathname.includes('values') && (
              <AWSImage
                dataCy={'values-image-' + id}
                imageId={'imagePlace-' + id}
                onClick={() =>
                  currentFilterTab == 'active' &&
                  !isReadOnly &&
                  setIsChooseImageOpen(id)
                }
                className="icon-value ml-[16px] mt-[6px]"
                src={sourceImage}
              />
            )}

            {currentFilterTab == 'active' && !isReadOnly ? (
              <Textarea
                key={id}
                action="edit"
                type="inputName"
                valueId={id}
                value={dictionary.title}
                isCardActive={isCardActive}
                setIsCardActive={setIsCardActive}
                additionalClassName={`${isValuesPage(
                  pathname,
                  'values-input-name ml-[12px]'
                )} bg-n-000`}
                className="active-text-area"
              />
            ) : (
              <p
                className={`ml-[16px] typography-paragraph text-n-900 ${isValuesPage(
                  pathname,
                  'ml-[80px]'
                )}`}
              >
                {dictionary.title}
              </p>
            )}

            {isChooseImageOpen == id && (
              <ModalValuesImage
                type="edit"
                id={id}
                _onChange={handleChangeInputFile}
                _onClick={chooseValueImage}
              />
            )}
          </div>
        </div>

        <div className="w-1/2 column-inline">
          {currentFilterTab == 'active' && !isReadOnly ? (
            <Textarea
              key={id}
              action="edit"
              type="inputDesc"
              placeholder={`Input ${
                pathname.includes('competencies')
                  ? getObjectiveLocale('competency').toLowerCase()
                  : getObjectiveLocale('Value').toLowerCase()
              } description`}
              valueId={id}
              value={dictionary.description}
              isCardActive={isCardActive}
              setIsCardActive={setIsCardActive}
              additionalClassName="bg-n-000"
              className="active-text-area"
            />
          ) : (
            <p className={'typography-paragraph text-n-900'}>
              {dictionary.description}
            </p>
          )}
        </div>

        <div className="w-[10%]">
          <p className="typography-paragraph text-n-900 text-center">
            {dictionary.deleted ? 'Archived' : 'Active'}
          </p>
        </div>
        <div className="w-[10%] pr-[16px] column-action relative">
          {currentFilterTab == 'active' &&
            !isReadOnly &&
            ((config?.competencyDictionary !== 'no_key_behaviors' &&
              pathname.includes('competencies')) ||
              (config?.recognitionConfig?.groupedValues &&
                pathname.includes('values'))) && (
              <TooltipValueCompetency
                index={index}
                title={'Add new key behavior'}
              >
                <SVGIcon
                  dataCy={`dictionary-create-behavior-${id}`}
                  onClick={() =>
                    handleAddKeyBehavior(id, dictionary.hasKeyBehaviors)
                  }
                  iconName="icon-add"
                  size="24"
                  fillColor="var(--n-600)"
                  customClass="mr-[8px] z-[1]"
                />
              </TooltipValueCompetency>
            )}

          {(currentFilterTab == 'active' ||
            (currentFilterTab == 'archive' && dictionary.deleted)) &&
            !isReadOnly && (
              <TooltipValueCompetency
                index={index}
                fitContent={true}
                title={currentFilterTab == 'active' ? 'Archived' : 'Restore'}
              >
                <SVGIcon
                  dataCy={`${currentTab}-${currentFilterTab}-${id}`}
                  onClick={() => archiveOrRestoreDictionary(id, null)}
                  iconName={
                    dictionary.deleted ? 'icon-unarchive' : 'icon-archive'
                  }
                  size="24"
                  fillColor="var(--n-600)"
                />
              </TooltipValueCompetency>
            )}

          {isAddLevelOpen &&
            config?.behaviorsFramework == 'proficiency_levels' &&
            !(dictionary?.levels || []).includes(0) &&
            (competenciesUseBasicLevel || valuesUseBasicLevel) && (
              <DialogAddLevel
                onClick={(type) =>
                  createKeyBehavior(id, dictionary.hasKeyBehaviors, type)
                }
                setIsAddLevelOpen={setIsAddLevelOpen}
              />
            )}
        </div>
      </div>

      {showInlineCreate == id && (
        <div className="new-dictionary-child-card py-[16px] flex justify-between items-start bg-n-200">
          <div className="w-[30%] pl-[40px] column-inline-child">
            {config?.behaviorsFramework == 'proficiency_levels' && (
              <p
                className={`typography-h300 text-n-600 uppercase mb-[10px] pl-[16px]`}
              >
                Level {levelBasic.isActive ? 'Basic' : highestLevel + 1}
              </p>
            )}

            <Textarea
              key={id}
              valueId={id}
              action="create"
              type="keyBehaviorName"
              dictionary={dictionary}
              levelBasic={levelBasic}
              setLevelBasic={setLevelBasic}
              orderLevel={highestLevel}
              placeholder="Input key behavior name"
              dataCy={'input-create-dictionary-kb-name-' + id}
              hasKeyBehaviors={dictionary?.hasKeyBehaviors}
              _getKeyBehaviorData={_getKeyBehaviorData}
            />
          </div>

          <div className="w-1/2 column-inline-child">
            <Textarea
              additionalClassName={`${
                config?.behaviorsFramework == 'proficiency_levels'
                  ? 'mt-[26px]'
                  : 'mt-[0px]'
              }`}
              key={id}
              valueId={id}
              action="create"
              type="keyBehaviorDesc"
              dictionary={dictionary}
              levelBasic={levelBasic}
              setLevelBasic={setLevelBasic}
              orderLevel={highestLevel}
              dataCy={'input-create-dictionary-kb-desc-' + id}
              hasKeyBehaviors={dictionary?.hasKeyBehaviors}
              placeholder="Input key behavior description"
              _getKeyBehaviorData={_getKeyBehaviorData}
            />
          </div>

          <div className="w-[10%]" />
          <div className="w-[10%] pr-[16px] column-action" />
        </div>
      )}

      {singleLoading && <NewSkeletonChildDictionary />}

      {!singleLoading &&
        accordionActive == id &&
        dataKeyBehavior &&
        dataKeyBehavior.map((behavior, i) => (
          <div
            key={i}
            className="new-dictionary-child-card py-[16px] flex justify-between items-start bg-n-200"
          >
            <div className="w-[30%] pl-[40px] column-inline-child">
              {config?.behaviorsFramework == 'proficiency_levels' && (
                <p className="typography-h300 text-n-600 uppercase mb-[10px] pl-[16px]">
                  Level{' '}
                  {behavior?.orderLevel == 0 ? 'basic' : behavior?.orderLevel}
                </p>
              )}

              {currentFilterTab == 'active' && !isReadOnly ? (
                <Textarea
                  action="edit"
                  type="keyBehaviorName"
                  placeholder="Input key behavior name"
                  orderLevel={behavior?.orderLevel}
                  valueId={behavior.id}
                  value={behavior.title}
                  isCardActive={isCardChildActive}
                  setIsCardActive={setIsCardChildActive}
                  dataKeyBehavior={dataKeyBehavior}
                  setDataKeyBehavior={setDataKeyBehavior}
                />
              ) : (
                <p className={'ml-[16px] typography-paragraph text-n-900'}>
                  {behavior.title}
                </p>
              )}
            </div>

            <div className="w-1/2 column-inline-child">
              {currentFilterTab == 'active' && !isReadOnly ? (
                <Textarea
                  action="edit"
                  type="keyBehaviorDesc"
                  placeholder="Input key behavior description"
                  additionalClassName={`${
                    config?.behaviorsFramework == 'proficiency_levels'
                      ? 'mt-[26px]'
                      : 'mt-[0px]'
                  }`}
                  orderLevel={behavior?.orderLevel}
                  valueId={behavior.id}
                  value={behavior.description}
                  isCardActive={isCardChildActive}
                  setIsCardActive={setIsCardChildActive}
                  dataKeyBehavior={dataKeyBehavior}
                  setDataKeyBehavior={setDataKeyBehavior}
                />
              ) : (
                <p
                  className={`typography-paragraph text-n-900 ${
                    config?.behaviorsFramework == 'proficiency_levels'
                      ? 'mt-[26px]'
                      : 'mt-[0px]'
                  }`}
                >
                  {behavior.description}
                </p>
              )}
            </div>

            <div className="w-[10%] flex justify-center items-center">
              <p
                className={`typography-paragraph text-n-900 ${
                  config?.behaviorsFramework == 'proficiency_levels'
                    ? 'mt-[26px]'
                    : 'mt-[0px]'
                }`}
              >
                {behavior.deleted ? 'Archived' : 'Active'}
              </p>
            </div>

            <div className="w-[10%] pr-[16px] column-action">
              {config?.behaviorsFramework !== 'proficiency_levels' && (
                <TooltipValueCompetency
                  fitContent={true}
                  title={currentFilterTab == 'active' ? 'Archived' : 'Restore'}
                >
                  <SVGIcon
                    dataCy={`kb-dictionary-${currentFilterTab}-${behavior.id}`}
                    onClick={() => archiveOrRestoreDictionary(id, behavior.id)}
                    iconName={
                      dictionary.deleted ? 'icon-unarchive' : 'icon-archive'
                    }
                    size="24"
                    fillColor="var(--n-600)"
                  />
                </TooltipValueCompetency>
              )}
            </div>
          </div>
        ))}

      {isDataEmpty && accordionActive == id && (
        <div className="bg-n-200 py-[16px] flex justify-center items-center">
          <p className="typography-paragraph text-n-900">
            No data is available or data is already{' '}
            {currentFilterTab == 'active' ? 'archived' : 'actived'}
          </p>
        </div>
      )}

      {!loadMoreLoading && hasMoreChild && accordionActive == id && (
        <div
          onClick={loadMore}
          data-cy={`competencies-values-show-more`}
          className="bg-n-200 cursor-pointer py-[16px] flex justify-center items-center"
        >
          <p className="text-base-600">Show more card</p>
          <SVGIcon
            customClass={`ml-[8px] mb-[0px] icon-chevron`}
            size="24"
            fillColor="var(--base-600)"
            iconName="icon-keyboard_arrow_down"
          />
        </div>
      )}

      {loadMoreLoading && <NewSkeletonChildDictionary />}
    </>
  );
};

export default NewDictionaryCard;

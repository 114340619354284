import produce from 'immer';
import { create } from 'zustand';

const useObjectives = create((set) => ({
  showChildrenId: null,
  set: (fn) => set(produce(fn)),
  filter: {},
  addSubGoalId: null
}));

export default useObjectives;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateBubble = void 0;
const generateLeft = ({ bubbleRect, targetLeft, targetWidth, targetTop, targetHeight, maxHeight, targetMargin, targetRight }) => {
    const bubbleHeight = bubbleRect.height || 0;
    const bubbleLeft = targetLeft + targetWidth + 48;
    let bubbleTop = targetTop - bubbleHeight / 2 + targetHeight / 2;
    if (bubbleTop < 24) {
        bubbleTop = 24;
    }
    if (bubbleTop + bubbleHeight > maxHeight) {
        bubbleTop = maxHeight - bubbleHeight;
    }
    let heightSVG = Math.abs(bubbleTop + bubbleHeight / 2 - (targetTop + targetHeight / 2));
    let widthSVG = 0, topSVG = 0, leftSVG = 0, pathLine = '', endPath = { x: 0, y: 0 };
    if (heightSVG < 20) {
        heightSVG = 20;
    }
    if (heightSVG <= 24) {
        widthSVG = Math.abs(bubbleLeft - targetRight) + 6;
        topSVG = targetTop + targetMargin + targetHeight / 2 - 10;
        leftSVG = targetRight - 8;
        pathLine = `M10 10 L${widthSVG - 8} 10`;
        endPath = { x: widthSVG - 8, y: 10 };
    }
    else {
        widthSVG = Math.abs(bubbleLeft - targetLeft - targetMargin) - 6;
        const lineHeight = (heightSVG || targetHeight) - 4;
        topSVG = targetTop + targetHeight + targetMargin;
        leftSVG = targetLeft + targetWidth / 2 + targetMargin - 8;
        pathLine = `M10 10 
      L10 ${lineHeight - 20} 
      C10 ${lineHeight - 10} 10 ${lineHeight} 30 ${lineHeight} L${widthSVG - 8} ${lineHeight}`;
        endPath = { x: widthSVG - 8, y: lineHeight };
    }
    return {
        widthSVG,
        topSVG,
        leftSVG,
        pathLine,
        bubbleTop,
        pathArrow: `M${endPath.x} ${endPath.y - 4} L${endPath.x + 8} ${endPath.y} L${endPath.x} ${endPath.y + 4} z`,
        heightSVG,
        bubbleLeft,
        circleX: 10,
        circleY: 10
    };
};
const generateRight = ({ bubbleRect, targetLeft, targetHeight, targetTop, maxHeight, targetMargin, targetWidth }) => {
    const bubbleHeight = bubbleRect.height || 0;
    const bubbleWidth = bubbleRect.width || 0;
    const bubbleLeft = targetLeft - bubbleWidth - 48;
    let bubbleTop = targetTop - bubbleHeight / 2 + targetHeight / 2;
    if (bubbleTop < 24) {
        bubbleTop = 24;
    }
    if (bubbleTop + bubbleHeight > maxHeight) {
        bubbleTop = maxHeight - bubbleHeight;
    }
    let heightSVG = Math.abs(bubbleTop + bubbleHeight / 2 - (targetTop + targetHeight / 2));
    if (heightSVG < 20) {
        heightSVG = 20;
    }
    let widthSVG = 0, topSVG = 0, leftSVG = 0, pathLine = '', endPath = { x: 0, y: 0 };
    if (heightSVG <= 24) {
        widthSVG = Math.abs(bubbleLeft + bubbleWidth - targetLeft) + 6;
        topSVG = targetTop + targetMargin + targetHeight / 2 - 10;
        leftSVG = targetLeft - widthSVG + 10;
        pathLine = `M10 10 L${widthSVG - 8} 10`;
        endPath = { x: 10, y: 10 };
    }
    else {
        widthSVG =
            Math.abs(bubbleLeft + bubbleWidth - (targetLeft + targetWidth / 2 + targetMargin)) + 10;
        const lineHeight = (heightSVG || targetHeight) - 4;
        topSVG = targetTop + targetHeight + targetMargin;
        leftSVG = bubbleLeft + bubbleWidth;
        pathLine = `M${widthSVG - 10} 10 
        L${widthSVG - 10} ${lineHeight - 20} 
        C${widthSVG - 10} ${lineHeight - 10} ${widthSVG - 10} ${lineHeight} 30 ${lineHeight} L10 ${lineHeight}`;
        endPath = { x: 10, y: lineHeight };
    }
    return {
        widthSVG,
        topSVG,
        leftSVG,
        pathLine,
        bubbleTop,
        pathArrow: `M${endPath.x} ${endPath.y - 4} L${endPath.x - 8} ${endPath.y} L${endPath.x} ${endPath.y + 4} z`,
        heightSVG,
        bubbleLeft,
        circleX: widthSVG - 10,
        circleY: 10
    };
};
const generateTop = ({ bubbleRect, targetLeft, targetWidth, targetTop, targetMargin }) => {
    const bubbleWidth = bubbleRect.width || 0;
    const bubbleHeight = bubbleRect.height || 0;
    const bubbleLeft = targetLeft + targetWidth / 2 - bubbleWidth / 2;
    const bubbleTop = targetTop - targetMargin - bubbleHeight - 48;
    const heightSVG = 48 + 10;
    const widthSVG = 20, topSVG = targetTop - heightSVG + 10, leftSVG = targetLeft + targetMargin + targetWidth / 2 - 10, pathLine = `M 10 ${heightSVG - 10} L 10 10`, endPath = { x: 10, y: 10 };
    return {
        widthSVG,
        topSVG,
        leftSVG,
        pathLine,
        bubbleTop,
        pathArrow: `M${endPath.x - 4} ${endPath.y} L${endPath.x} ${endPath.y - 8} L${endPath.x + 4} ${endPath.y} z`,
        heightSVG,
        bubbleLeft,
        circleX: 10,
        circleY: heightSVG - 10
    };
};
const generateBottom = ({ bubbleRect, targetLeft, targetWidth, targetTop, targetMargin, targetHeight }) => {
    const bubbleWidth = bubbleRect.width || 0;
    const bubbleLeft = targetLeft + targetWidth / 2 - bubbleWidth / 2;
    const bubbleTop = targetTop + targetMargin * 2 + targetHeight + 48;
    const heightSVG = 48 + 10;
    const widthSVG = 20, topSVG = bubbleTop - 48 - 10, leftSVG = targetLeft + targetMargin + targetWidth / 2 - 8, pathLine = `M 10 10 L 10 ${heightSVG - 10}`, endPath = { x: 10, y: heightSVG - 10 };
    return {
        widthSVG,
        topSVG,
        leftSVG,
        pathLine,
        bubbleTop,
        pathArrow: `M${endPath.x - 4} ${endPath.y} L${endPath.x} ${endPath.y + 8} L${endPath.x + 4} ${endPath.y} z`,
        heightSVG,
        bubbleLeft,
        circleX: 10,
        circleY: 10
    };
};
const generateBubble = ({ position, targetRect, bubbleRect, maxHeight, targetMargin }) => {
    const targetLeft = targetRect.left || 0;
    const targetWidth = targetRect.width || 0;
    const targetRight = targetRect.right || 0;
    const targetHeight = targetRect.height || 0;
    const targetTop = targetRect.top || 0;
    if (position == 'left') {
        return generateLeft({
            bubbleRect,
            targetLeft,
            targetWidth,
            targetTop,
            targetHeight,
            maxHeight,
            targetMargin,
            targetRight
        });
    }
    if (position == 'right') {
        return generateRight({
            bubbleRect,
            targetLeft,
            targetHeight,
            targetTop,
            maxHeight,
            targetMargin,
            targetWidth
        });
    }
    if (position == 'top') {
        return generateTop({
            bubbleRect,
            targetLeft,
            targetWidth,
            targetTop,
            targetMargin
        });
    }
    if (position == 'bottom') {
        return generateBottom({
            bubbleRect,
            targetLeft,
            targetWidth,
            targetTop,
            targetMargin,
            targetHeight
        });
    }
    return null;
};
exports.generateBubble = generateBubble;

import React from 'react';

import useModalObjectives from 'hooks/useModalObjectives';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AdjustWeightModal from 'components/modal/AdjustWeightModal/AdjustWeightModal';
import ConfirmDeleteModal from 'components/modal/ConfirmDeleteModal';
import ErrorModal from 'components/modal/ErrorModal';
import ModalAlignParent from 'components/modal/ModalAlignParent';
import ModalConfirmAlignTask from 'components/modal/ModalConfirmAlignTask';
import ModalCustomRepetition from 'components/modal/ModalCustomRepetition';
import ModalIncompleteGoal from 'components/modal/ModalIncompleteGoal';
import ModalOwnerList from 'components/modal/ModalOwnerList';
import ModalUpdateProgress from 'components/modal/ModalUpdateProgress/ModalUpdateProgress';
import ObjectiveReviewPopup from 'components/objective-review-popup/ObjectiveReviewPopup';
import ObjectiveProgressSummaryModal from 'components/objectives/progress-summary/ObjectiveProgressSummaryModal';
import EditProjectNameModal from 'components/project/detail/EditProjectNameModal';
import ModalConfirmUpdatePhase from 'components/project/detail/ModalConfirmUpdatePhase';
import ModalEditPhase from 'components/project/detail/ModalEditPhase/ModalEditPhase';
import MinMaxValidationModal from 'components/shared/modal/MinMaxValidationModal';
import Modal from 'components/shared/modal/Modal';

import AdjustWeightLockedModal from './AdjustWeightLockedModal';
import ModalCloneObjective from './ModalCloneObjective';
import MenuAlign from './compact-objective/fragments/MenuAlign';

function ObjectivesModal({ saveCallback }) {
  const modalType = useModalObjectives((state) => state.modalType);

  const closeModal = useModalObjectives((state) => state.closeModal);
  const submitModal = useModalObjectives((state) => state.submitModal);
  const propsModal = useModalObjectives((state) => state.propsModal);

  return (
    <>
      {modalType == 'adjustWeight' && (
        <AdjustWeightModal
          eventOnClick={closeModal}
          className="show-adjust-weight-modal"
          {...propsModal}
        />
      )}

      {modalType == 'updateProgress' && (
        <ModalUpdateProgress
          title="Update Target"
          withCloseIcon
          eventOnClick={closeModal}
          modalType="update"
          saveCallback={saveCallback}
          {...propsModal}
        />
      )}

      {modalType == 'adjustWeightType' && (
        <AdjustWeightLockedModal eventOnClick={closeModal} {...propsModal} />
      )}

      {modalType == 'cloneGoal' && (
        <ModalCloneObjective
          eventOnClick={closeModal}
          saveCallback={saveCallback}
          {...propsModal}
        />
      )}

      {modalType == 'complete' && (
        <ModalUpdateProgress
          title="Complete Goal"
          withCloseIcon
          eventOnClick={closeModal}
          modalType="complete"
          saveCallback={saveCallback}
          {...propsModal}
        />
      )}

      {modalType == 'incomplete' && (
        <ModalIncompleteGoal onClose={closeModal} {...propsModal} />
      )}

      {modalType == 'review' && (
        <ObjectiveReviewPopup onCloseModal={closeModal} {...propsModal} />
      )}

      {modalType == 'comment' && (
        <ModalUpdateProgress
          titleModal="Comment Update"
          withCloseIcon
          eventOnClick={closeModal}
          modalType="comment"
          onSubmit={submitModal}
          {...propsModal}
        />
      )}

      {modalType == 'restore' && (
        <Modal
          className="max-w-[400px]"
          useBorder={true}
          maxHeight={400}
          contentWrapperClass={'overflow-auto'}
          title={`Restore ${
            propsModal?.typeObjective === 'task' ? 'Task' : 'Objective'
          }`}
          description={`Are you sure you want to restore this ${propsModal?.typeObjective}? Your action can’t be reversed.`}
          eventOnClickClose={closeModal}
          withPrimaryBtn={{
            title: 'Restore',
            dataCy: 'goal-confirm-restore-goal',
            onClick: () => submitModal()
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            dataCy: 'goal-cancel-restore-goal',
            onClick: () => closeModal()
          }}
        />
      )}

      {modalType == 'ownerList' && (
        <ModalOwnerList
          titleModal="Goal Owners"
          withCloseIcon
          eventOnClick={closeModal}
          {...propsModal}
        />
      )}

      {modalType == 'repetition' && (
        <ModalCustomRepetition
          onSubmit={submitModal}
          closeModal={closeModal}
          {...propsModal}
        />
      )}

      {modalType == 'error' && (
        <ErrorModal closeModal={closeModal} {...propsModal} />
      )}

      {(modalType == 'confirmDelete' || modalType == 'confirmDeleteTask') && (
        <ConfirmDeleteModal
          modalType={modalType}
          closeModal={closeModal}
          submitModal={submitModal}
          propsModal={propsModal}
        />
      )}

      {modalType == 'reminder' && (
        <Modal
          title="Success"
          withCloseIcon={true}
          {...propsModal}
          maxHeight="470"
        >
          <div className="save-success">
            <img src={Config.asset.general.dialog7} alt="Clock" />
            <p className="text-content typography-paragraph mb-[14px] pt-[32px]">
              <strong>{getObjectiveLocale('Thank you!')}</strong>&nbsp;
              <span>{getObjectiveLocale('Your reminder has been sent.')}</span>
            </p>
          </div>
        </Modal>
      )}

      {/*NOT WORKING */}
      {modalType == 'minMaxValidation' && (
        <MinMaxValidationModal {...propsModal} />
      )}

      {modalType == 'editProjectName' && (
        <EditProjectNameModal
          setShowModal={closeModal}
          objective={propsModal?.objectiveDetail}
        />
      )}

      {modalType == 'confirmUpdatePhase' && (
        <ModalConfirmUpdatePhase closeModal={closeModal} {...propsModal} />
      )}

      {modalType == 'editPhases' && (
        <ModalEditPhase closeModal={closeModal} {...propsModal} />
      )}

      {modalType == 'exportSummary' && (
        <ObjectiveProgressSummaryModal
          setShowModal={closeModal}
          {...propsModal}
        />
      )}

      {modalType == 'confirmAlignTask' && (
        <ModalConfirmAlignTask onClose={closeModal} {...propsModal} />
      )}

      {modalType == 'menuAlign' && (
        <MenuAlign closeAlignModal={closeModal} {...propsModal} />
      )}

      {modalType === 'modalAlignParent' && (
        <ModalAlignParent closeModal={closeModal} {...propsModal} />
      )}
    </>
  );
}

export default ObjectivesModal;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import debounce from 'lodash/debounce';

import { getCssVariableValue } from 'utils/HelperUtils';
import { loadMoreValidator } from 'utils/HelperUtils';

import InlineDialog from 'components/design-system/inline-dialog/InlineDialog';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import Badge from 'components/shared/Badge';
import { getObjectiveDependencySuggestions } from 'src/client/ObjectivesClient';
import SVGIcon from 'src/components/shared/SVGIcon';
import useDebounce from 'src/hooks/useDebounce';

const DependencyDropdown = ({
  task,
  selectedTask = [],
  onChange,
  isCreate = false
}) => {
  const { parentId: projectId } = useParams();

  const [searchValue, setSearchValue] = useState('');
  const [listOption, setListOption] = useState([]);
  const [olderThan, setOlderThan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const debounceSearchValue = useDebounce(searchValue, 500);

  const firstRender = useRef();

  const { id } = task || {};

  const getDependencySuggestions = async () => {
    setIsLoading(true);
    let query = { q: searchValue };

    !isCreate && (query.id = id);
    task?.taskType === 'project' && (query.projectId = projectId);

    const { data: suggestions, pagination } =
      await getObjectiveDependencySuggestions(query);

    if (suggestions) {
      setListOption(suggestions);
      setOlderThan(pagination.next.olderThan);
    }
    setIsLoading(false);
  };

  const appendDependencySuggestions = async () => {
    let query = {
      q: searchValue,
      olderThan: olderThan
    };

    !isCreate && (query.id = id);
    task?.taskType === 'project' && (query.projectId = projectId);

    const { data: suggestions, pagination } =
      await getObjectiveDependencySuggestions(query);

    if (suggestions) {
      setListOption([...listOption, ...suggestions]);
      setOlderThan(pagination.next.olderThan);
    }
  };

  const debounceFn = useCallback(debounce(appendDependencySuggestions, 500), [
    olderThan
  ]);

  const onScroll = (e) => {
    e.stopPropagation();
    const target = e.target;

    loadMoreValidator(target, 20, () => {
      olderThan !== null && debounceFn();
    });
  };

  const options = listOption.map((option) => ({
    id: option.id,
    text: option.name,
    onClick: () => onChange(option, false),
    customClass: 'w-[300px]',
    selected: selectedTask.some((task) => task.id === option.id)
  }));

  useEffect(() => {
    getDependencySuggestions();
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      getDependencySuggestions();
    }
  }, [debounceSearchValue]);

  const SelectedItem = () => {
    if (selectedTask.length === 0) return null;

    return (
      <InlineDialog.SelectedItem>
        {selectedTask?.map((dependency) => (
          <div className="flex items-center mr-[8px]" key={dependency.id}>
            <Badge
              content={dependency.name}
              bgColorHex={getCssVariableValue('--n-300')}
              className="mr-[4px] bg-n-300 whitespace-nowrap cursor-pointer"
              showCursorNotAllowed={false}
            />
            <SVGIcon
              size="16"
              iconName="icon-clear"
              fillColor="var(--n-600)"
              onClick={() => onChange(dependency, true)}
            />
          </div>
        ))}
      </InlineDialog.SelectedItem>
    );
  };

  return (
    <InlineDialog
      customClass="w-full h-full"
      position="absolute"
      search={{
        placeholder: 'Search task',
        onSearch: (e) => {
          setSearchValue(e.target.value);
        }
      }}
      header="This task is blocked by"
      type="multiple"
    >
      <FloatingComponent lockScroll={false} customContentHeight={480}>
        <InlineDialog.MenuItems
          isLoading={isLoading}
          options={options}
          position="right"
          type="absolute"
          customSelectedComponent={<SelectedItem />}
          customClass="w-[300px]"
          onScroll={onScroll}
          useTrigger={false}
        />
      </FloatingComponent>
    </InlineDialog>
  );
};

export default DependencyDropdown;

import React, { useEffect, useRef, useState } from 'react';
import Linkify from 'react-linkify';

import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import { getCssVariableValue, getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/design-system/Avatar';
import Badge from 'components/shared/Badge';
import Checkbox from 'components/shared/Checkbox';
import RadioButton from 'components/shared/RadioButton';

const PrefillSuggestion = ({
  phaseType,
  suggestionData,
  viewType,
  options,
  showOption = 'score_and_label',
  showLabel = true,
  suggestionSource
}) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [showReadMoreButton, setShowReadMoreButton] = useState(false);

  let commentSuggestion = useRef();

  const handleShowMore = () => {
    setIsShowMore(true);
    commentSuggestion?.current?.classList.remove('max-h-[72px]');
    commentSuggestion?.current?.classList.remove('multiline-text-3');
  };

  const handleHide = () => {
    setIsShowMore(false);
    commentSuggestion?.current?.classList.add('max-h-[72px]');
    commentSuggestion?.current?.classList.add('multiline-text-3');
  };

  const getTitle = () => {
    let text = 'Answers from ';
    if (suggestionSource === 'previous_cycle') {
      return (text = text + startCase(phaseType));
    }

    if (phaseType === 'manager_review') {
      return (text = text + 'Self Review');
    }

    return (text = text + 'Manager Review');
  };

  useEffect(() => {
    if (suggestionData?.comment) {
      setShowReadMoreButton(
        commentSuggestion?.current?.scrollHeight >
          commentSuggestion?.current?.clientHeight
      );
    }
  }, [suggestionData?.comment]);

  return (
    <div className="mt-[16px]" data-cy="prefill-suggestion-card">
      <p className="typography-h100 text-n-800 mb-[16px]">
        {getObjectiveLocale(getTitle())}
      </p>
      <div className="bg-n-300 p-[16px] rounded-[4px] border border-solid border-n-300">
        <div className="flex items-center justify-between mb-[8px]">
          <div className="flex items-center">
            <Avatar
              src={suggestionData?.actor?.profilePicture}
              name={suggestionData?.actor?.name}
              size={24}
              customClass="mr-[16px]"
            />
            <div className="flex flex-col">
              <p className="typography-h200 text-n-900">
                {suggestionData?.actor?.name}
              </p>
              <p className="typography-h100 text-n-800">
                {suggestionData?.actor?.jobTitle}
              </p>
            </div>
          </div>
          {suggestionData?.updatedAt && (
            <p className="typography-h200 text-n-600">
              {dayjs(suggestionData?.updatedAt).format('MMM DD')}
            </p>
          )}
        </div>

        <SuggestionAnswer
          suggestionData={suggestionData}
          viewType={viewType}
          options={options}
          showOption={showOption}
          showLabel={showLabel}
        />

        {suggestionData?.comment && (
          <>
            <Linkify properties={{ target: '_blank' }}>
              <p
                className="whitespace-pre-wrap break-words mt-[12px] typography-paragraph text-n-800 max-h-[72px] overflow-hidden multiline-text-3"
                ref={commentSuggestion}
              >
                {suggestionData?.comment}
              </p>
            </Linkify>
            {showReadMoreButton && !isShowMore && (
              <p
                onClick={() => handleShowMore()}
                className="cursor-pointer text-base-600 typography-paragraph"
              >
                {getObjectiveLocale('Read more')}
              </p>
            )}
            {isShowMore && (
              <p
                onClick={() => handleHide()}
                className="cursor-pointer text-base-600 typography-paragraph"
              >
                {getObjectiveLocale('Show less')}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const SuggestionAnswer = ({
  suggestionData,
  viewType,
  options,
  showOption,
  showLabel
}) => {
  const optionsIds =
    suggestionData?.optionIds?.length > 0
      ? suggestionData?.optionIds
      : [suggestionData?.optionId];

  const getAnswers = () => {
    const optionsAnswers = options?.filter((option) =>
      optionsIds?.includes(option.id)
    );

    if (viewType === 'raw') {
      return [suggestionData];
    }

    if (optionsAnswers?.length > 0) {
      return optionsAnswers;
    }

    if (suggestionData) {
      return [suggestionData];
    }

    return [];
  };

  const answers = getAnswers();

  return (
    <div>
      {(viewType === 'rating' ||
        viewType === 'slider' ||
        viewType === 'raw' ||
        !viewType) &&
        answers?.length > 0 &&
        answers?.map((answer, index) => (
          <div className="flex items-center" key={index}>
            {(showOption === 'score_and_label' ||
              showOption === 'score_only') && (
              <Badge
                content={answer?.value || answer?.score || answer?.rawScore}
                colorHex={getCssVariableValue('--v-800')}
                bgColorHex={getCssVariableValue('--v-200')}
                className="mr-[8px] whitespace-nowrap"
                showCursorNotAllowed={false}
              />
            )}

            {(showOption === 'score_and_label' ||
              showOption === 'label_only') &&
              showLabel && (
                <p className="typography-h400 text-v-600">
                  {answer?.option || answer?.name}
                </p>
              )}
          </div>
        ))}

      {viewType === 'multiple_choices' &&
        answers?.length > 0 &&
        answers?.map((answer, index) => (
          <div className="flex items-center" key={index}>
            <RadioButton checked disabled />
            <span className="ml-[8px] text-base-600 typography-h400">
              {answer?.option}
            </span>
          </div>
        ))}

      {viewType === 'multiple_selections' &&
        answers?.length > 0 &&
        answers?.map((answer, index) => (
          <div
            className={`flex items-center h-[24px] ${
              answers?.length - 1 !== index ? 'mb-[8px]' : ''
            }`}
            key={index}
          >
            <Checkbox checked disabled />
            <span className="ml-[8px] text-base-600 typography-h400">
              {answer?.option}
            </span>
          </div>
        ))}
    </div>
  );
};

export default PrefillSuggestion;

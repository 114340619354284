import React from 'react'
import WeightSettingsCard from './fragments/WeightSettingsCard';
import { getObjectiveLocale } from "utils/HelperUtils";
import WeightSettingsInputNumber from './fragments/WeightSettingsInputNumber';

const WeightSettingsMinimumSub = ({
  configData,
  changeObjectiveConfig
}) => {
  const { objectivesConfig: {maxSubObjectiveWeightOn, minSubObjectiveWeightOn, maxSubObjectiveWeight, minSubObjectiveWeight } } = configData

  const changeCallback = async ({value, keys}) => {
    const isSuccess = await changeObjectiveConfig({[keys]: value})
    return isSuccess
  }

  const isError = maxSubObjectiveWeightOn && minSubObjectiveWeight >= maxSubObjectiveWeight

  return (
    <WeightSettingsCard
      title={"Minimum Weight Value for Sub Objective"}
      subTitle={"Show the expected minimum weight of each sub objectives"}
      isOpen={minSubObjectiveWeightOn}
      toggleCallback={({value}) => changeCallback({value, keys: "minSubObjectiveWeightOn"})}
      toggleCy="toggle-minimum-sub"
    >
      <div className="flex flex-col">
        <p className="typography-h100 text-n-800 mb-[8px]">{getObjectiveLocale("Minimum weight (%)")}</p>
        <div className="flex flex-row items-center">
          <WeightSettingsInputNumber
            defaultValue={minSubObjectiveWeight}
            onChangeCallback={(value) => changeCallback({value, keys: "minSubObjectiveWeight"})}
            isError={isError}
            dataCy='minimum-weight-input-sub'
          />
          {isError && <p className="typography-paragraph text-r-600 ml-[16px]">{getObjectiveLocale(`Minimum weight value must less than`)} {maxSubObjectiveWeight}%</p>}
        </div>
      </div>
    </WeightSettingsCard>
  )
}

export default WeightSettingsMinimumSub

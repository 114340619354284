import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { followUpNeedResponse } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import useDropdown from 'hooks/useDropdown';
import useModalObjectives from 'hooks/useModalObjectives';
import { getObjectiveLocale, toggleDropdown } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import IconButton from 'components/shared/Button/IconButton';
import SVGIcon from 'components/shared/SVGIcon';

import ListDropdown from './ListDropdown';

function ListObjectiveFooter({
  objective,
  rotateDirection,
  showChild,
  clickShow,
  nested,
  action,
  permissions,
  depthLevel,
  level = 1,
  page
}) {
  const { pathname } = useLocation();
  const ref = useRef();
  const [isOpen, setOpen] = useState(false);

  useClickOutside(ref, () => {
    toggleDropdown(false, setDropdownAnimation, setOpen),
      toggleDropdown(false, setDropdownAddAnimation, setOpenAdd);
  });

  const { useMenu } = useDropdown({ objective, permissions, page });
  const { config } = useUser();

  const listMenuAdd = useMenu({ only: ['addGoal', 'addTask'] });
  const [isOpenAdd, setOpenAdd] = useState(isOpen);
  const listMenu = useMenu({ except: ['addGoal', 'addTask'] });
  const [dropdownAnimation, setDropdownAnimation] = useState(
    'animation-open-dropdown-fadeInDownSlow'
  );
  const [dropdownAddAnimation, setDropdownAddAnimation] = useState(
    'animation-open-dropdown-fadeInDownSlow'
  );

  const clickImage = () => {
    toggleDropdown(!isOpen, setDropdownAnimation, setOpen);
  };

  const openDropdownAdd = () => {
    toggleDropdown(!isOpenAdd, setDropdownAddAnimation, setOpenAdd);
  };

  const showModal = useModalObjectives((state) => state.showModal);
  const closeModal = useModalObjectives((state) => state.closeModal);

  const isInAssignment = pathname.includes('/assignments');

  let modalProps = {
    measurement: objective?.measurement,
    canUpdateCurrentValue: objective?.permissions?.includes(
      'update_current_value'
    ),
    objectiveId: objective?.id,
    objectiveName: objective?.name,
    permission: permissions?.includes('update_comment'),
    commentOptions: objective?.commentOptions,
    milestoneType: objective?.milestoneType,
    objectiveValue: objective,
    currentMilestone: objective?.currentMilestone
  };

  const handleFollowUp = async (objectiveId, comment, mention) => {
    let body = {
      comment: comment,
      metaMentions: mention
    };
    const { data } = await followUpNeedResponse(objective.id, body);
    if (data) {
      closeModal();
    }
  };

  const followUpButton = (
    <Button.Tertiary
      customClass="mr-[8px]"
      onClick={() =>
        showModal({
          modalType: 'comment',
          props: {
            measurement: objective?.measurement,
            objectiveName: objective?.name
          },
          submitModal: handleFollowUp
        })
      }
      datacy={`follow-up-icon`}
    >
      <i className="fa fa-bell need-response-icon" />
      {getObjectiveLocale('Follow Up')}
    </Button.Tertiary>
  );

  return (
    <React.Fragment>
      <div className="footer flex justify-between mt-[8px]">
        <div className="button-info">
          {objective?.type === 'task' ? (
            <div className="text-base-600 px-[8px] py-[8px] flex items-center">
              <SVGIcon
                size="24"
                iconName={'icon-task'}
                customClass="mr-[4px]"
                fillColor={'var(--base-600)'}
              />
              Task Based
            </div>
          ) : objective?.childrenCount > 0 ? (
            nested ? (
              depthLevel === level ? (
                <p className="px-[8px] py-[8px] text-n-600">
                  You reached the depthLevel view, visit Goal Tree page to view
                  all child goals/tasks{' '}
                </p>
              ) : (
                <Button.Tertiary
                  customClass="pl-[0px]"
                  onClick={(e) => {
                    clickShow(e);
                  }}
                >
                  <SVGIcon
                    size="24"
                    iconName="icon-keyboard_arrow_right"
                    customClass={`${
                      objective?.childrenCount === 0
                        ? 'accordion__icon'
                        : rotateDirection
                    }`}
                    fillColor={'var(--base-600)'}
                  />
                  {getObjectiveLocale(
                    `${showChild ? 'Hide' : 'Expand'} Sub Goals`
                  )}
                </Button.Tertiary>
              )
            ) : (
              <p className="pl-[0px] pt-[8px] pb-[8px] pr-[8px] text-n-600 typography-button">
                {objective?.childrenCount} Sub Goals & Tasks
              </p>
            )
          ) : (
            <p className="pl-[0px] pt-[8px] pb-[8px] pr-[8px] text-n-600 typography-button flex items-center">
              <SVGIcon
                iconName="icon-keyboard_arrow_right"
                size="24"
                fillColor="var(--n-600)"
                customClass="mr-[8px]"
              />
              {getObjectiveLocale(
                `No Sub Goal Available${
                  objective?.childrenCountFilter == 0
                    ? ' With Current Filter'
                    : ''
                }`
              )}
            </p>
          )}
        </div>
        {action && (
          <div className="button-action flex-center-center">
            {!pathname.includes('/objectives/need-response') ? (
              <>
                {listMenuAdd?.length > 0 && !isInAssignment && (
                  <div className="relative">
                    <Button.Tertiary
                      customClass="mr-[8px] px-[8px]"
                      onClick={openDropdownAdd}
                      datacy="dropdown-add"
                    >
                      <SVGIcon
                        size="16"
                        iconName={'icon-add'}
                        customClass="mr-[4px]"
                        fillColor={'var(--base-600)'}
                      />
                      Add New
                    </Button.Tertiary>
                    {isOpenAdd && (
                      <div ref={ref}>
                        <ListDropdown
                          autoClose={() =>
                            toggleDropdown(
                              false,
                              setDropdownAddAnimation,
                              setOpenAdd
                            )
                          }
                          data={listMenuAdd}
                          dropdownAnimation={dropdownAddAnimation}
                        />
                      </div>
                    )}
                  </div>
                )}
                {permissions?.includes('update_current_value') && (
                  <Button.Tertiary
                    customClass="mr-[8px] px-[8px]"
                    onClick={() => {
                      showModal({
                        modalType: 'updateProgress',
                        props: modalProps
                      });
                    }}
                    datacy="update-progress-button"
                  >
                    <SVGIcon
                      size="16"
                      iconName="icon-bar_chart"
                      customClass="mr-[4px]"
                      fillColor={'var(--base-600)'}
                    />
                    Update Progress
                  </Button.Tertiary>
                )}
                {listMenu?.length > 0 && (
                  <div className="relative">
                    <IconButton
                      customClass="w-[36px] h-[36px] px-[6px] py-[6px] cursor-pointer"
                      isTertiary={true}
                      onClick={clickImage}
                    >
                      <SVGIcon
                        size="24"
                        fillColor="var(--n-600)"
                        iconName="icon-keyboard_control"
                        dataCy="kebab-action-button"
                      />
                    </IconButton>
                    {isOpen && (
                      <div ref={ref}>
                        <ListDropdown
                          autoClose={() =>
                            toggleDropdown(false, setDropdownAnimation, setOpen)
                          }
                          data={listMenu}
                          dropdownAnimation={dropdownAnimation}
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : permissions?.includes('review') ? (
              config.ratingMechanism &&
              config.ratingMechanism == 'automatic' ? null : (
                <Button.Tertiary
                  customClass="mr-[8px]"
                  onClick={() => {
                    showModal({
                      modalType: 'review',
                      props: {
                        objectiveDetail: objective
                      }
                    });
                  }}
                  datacy={`need-response-icon`}
                >
                  <SVGIcon
                    size="24"
                    iconName="icon-review-badge"
                    customClass="mr-[8px]"
                    fillColor={'var(--base-600)'}
                  />
                  {getObjectiveLocale('Review Now')}
                </Button.Tertiary>
              )
            ) : config.ratingMechanism &&
              config.ratingMechanism == 'automatic' ? (
              objective.overdue && followUpButton
            ) : (
              permissions?.includes('comment') && followUpButton
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default ListObjectiveFooter;

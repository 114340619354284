import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import startCase from 'lodash/startCase';

import useClickOutside from 'hooks/useClickOutside';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

const TableRowTrackList = (props) => {
  let {
    name,
    index,
    id,
    cycleCount,
    trackType = '',
    isEditable,
    getTrackDetail,
    setShowDeactivateModal,
    activeTab,
    handleActivateTrack,
    isReadOnly
  } = props;

  const history = useHistory();
  const meatballRef = useRef();
  const [showDropDown, setShowDropDown] = useState(false);

  useClickOutside(meatballRef, () => setShowDropDown(false));

  return (
    <tr
      onClick={() => getTrackDetail(id, activeTab)}
      data-cy={`template-${index}`}
    >
      <td>
        <div className="ellipsis-track-table">{name}</div>
      </td>
      <td>
        {getNumberLocale(cycleCount)}{' '}
        {getObjectiveLocale(cycleCount > 1 ? `reviews` : `review`)}
      </td>
      <td>{getObjectiveLocale(startCase(trackType))}</td>
      <td>
        {isEditable ? (
          <div
            className="track-template-meatball-container"
            ref={meatballRef}
            data-cy="meatball-icon"
          >
            {!isReadOnly && (
              <SVGIcon
                iconName="icon-keyboard_control"
                fillColor="var(--n-600)"
                size="24"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropDown(true);
                }}
              />
            )}
            {showDropDown && (
              <div className="track-template-dropdown">
                <div
                  className="dropdown-button"
                  data-cy="edit-track-template"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(
                      `/appraisals/cycles/tracks/edit/${trackType}/${id}`
                    );
                  }}
                >
                  {getObjectiveLocale('Edit')}
                </div>
                {activeTab === 'active' ? (
                  <div
                    className="dropdown-button deactivate-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeactivateModal(id);
                    }}
                  >
                    {getObjectiveLocale('Deactivate')}
                  </div>
                ) : (
                  <div
                    className="dropdown-button active-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleActivateTrack(id);
                    }}
                  >
                    {getObjectiveLocale('Activate')}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="track-template-lock-container" data-cy="lock-icon">
            <span className="lock-tooltip-text">
              {getObjectiveLocale('Templates that are in use are not editable')}
            </span>
            <SVGIcon
              iconName="icon-lock_outline"
              fillColor="var(--n-600)"
              size="24"
            />
          </div>
        )}
      </td>
    </tr>
  );
};

export default TableRowTrackList;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import InputField from 'components/shared/InputField';

import './SetName.scss';

const SetName = (props) => {
  return (
    <div
      className={`set-name w-full ${
        props?.customClass ? props?.customClass : ''
      }`}
    >
      <div>
        <h4>Cycle Name</h4>
        <div className="flex">
          <div className="grow">
            <InputField
              dataCy="input-cycle-name"
              inputClass="input-cycles"
              placeholder="Write cycle name.."
              value={props?.cycleData?.name}
              handleChange={(e) =>
                props?.changeCycleName(e.currentTarget.value)
              }
            />
          </div>
          {props?.useButton && (
            <Button
              customClass="ml-[16px]"
              onClick={
                props?.cycleData.id == null
                  ? () => props.handleFirstSave()
                  : () => props.toogleTab('Set Up Phases')
              }
              datacy="button-save-set-name"
            >
              {getObjectiveLocale('Save')} & {getObjectiveLocale('Continue')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetName;

import React from 'react';

import Config from 'utils/Config';

function SelectedFilter({ listOfData, title, handleChange }) {
  let listofGroup = [];
  listOfData.map((group, idx) => {
    listofGroup.push(group.name);
  });

  return (
    <>
      {listOfData.length !== 0 && (
        <ul
          className="list-selected-label selected-labels"
          data-cy="selected-filter"
        >
          <li role="presentation" className="label-card selected">
            <span>
              {title}: {listofGroup.join(', ')}
            </span>
          </li>
          <img
            src={Config.asset.formalReview.icCloseGrey}
            className="close-label"
            onClick={(e) => {
              e.stopPropagation(), handleChange([]);
            }}
          />
        </ul>
      )}
    </>
  );
}

export default SelectedFilter;

import React, { useEffect } from 'react';

import { useValueCompetency } from 'context/admin/ValueCompetencyContext';

import { ModelCard } from './ModelCard';

const ValueAndCompetencyModel = ({ isLoading, isReadOnly }) => {
  const { dataValueOrCompetency } = useValueCompetency();

  const abortController = new AbortController();

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <React.Fragment>
      {dataValueOrCompetency.map((value, idx) => {
        return (
          <ModelCard
            value={value}
            id={value.id}
            key={idx}
            isReadOnly={isReadOnly}
          />
        );
      })}
    </React.Fragment>
  );
};

export default ValueAndCompetencyModel;

import React, { createRef, useEffect, useState } from 'react';

import startCase from 'lodash/startCase';

import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useActivitiesTeam } from 'context/team/TeamActivitiesContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import {
  formatBytes,
  getDateLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Avatar from 'components/design-system/Avatar';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import ModalImage from 'components/shared/ModalImage/ModalImage';
import AttachmentList from 'components/sidebar/objective-detail/AttachmentList';

const TeamActivitiesSkeleton = () => {
  return (
    <>
      <div className="w-full flex py-[12px]">
        <Shimmer circle width="40px" height="40px" customClass="mr-[16px]" />
        <div className="flex flex-col">
          <Shimmer width="320px" height="16px" customClass="mb-[4px]" />
          <Shimmer width="240px" height="12px" />
        </div>
      </div>
      <div className="w-full flex py-[12px]">
        <Shimmer circle width="40px" height="40px" customClass="mr-[16px]" />
        <div className="flex flex-col">
          <Shimmer width="320px" height="16px" customClass="mb-[4px]" />
          <Shimmer width="240px" height="12px" />
        </div>
      </div>
      <div className="w-full flex py-[12px]">
        <Shimmer circle width="40px" height="40px" customClass="mr-[16px]" />
        <div className="flex flex-col">
          <Shimmer width="320px" height="16px" customClass="mb-[4px]" />
          <Shimmer width="240px" height="12px" />
        </div>
      </div>
    </>
  );
};

const TeamActivities = ({ teamId }) => {
  const intersectTarget = createRef();
  const { refetchQueries } = useRefetchQuery();
  const {
    data: activities,
    hasNextPage,
    fetchNextPage,
    isFetching
  } = useActivitiesTeam();

  const [isFirstRender, setIsFirstRender] = useState(true);

  const queryKey = ['team', 'activities', +teamId];

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting && hasNextPage && fetchNextPage(),
    threshold: 1
  });

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      refetchQueries(queryKey);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col h-[calc(100vh-170px)]">
      <div className="overflow-y-auto pr-[40px]">
        {activities?.length > 0 &&
          activities?.map((activity, index) => (
            <Activity activity={activity} key={index} />
          ))}
        {isFetching && <TeamActivitiesSkeleton />}
        {hasNextPage && (
          <div className="h-[1px]" ref={intersectTarget}>
            &nbsp;
          </div>
        )}
      </div>
    </div>
  );
};

const SetActivitiesValue = ({
  keyActivities,
  value,
  isUpdateProfilePicture,
  modalImageHandler
}) => {
  return (
    <p
      className={`typography-h200 text-n-800 flex items-start ${
        keyActivities == 'new' ? 'mb-[8px]' : ''
      }`}
    >
      {getObjectiveLocale(`${startCase(keyActivities)}`)}
      {isUpdateProfilePicture ? (
        <AWSImage
          imageId={`team-${keyActivities}-profile`}
          className="w-[40px] h-[40px] cursor-pointer ml-[4px]"
          src={value}
          dataCy={`team-${keyActivities}-profile`}
          onClick={() => modalImageHandler(true, value)}
        />
      ) : (
        <span className="typography-h100 ml-[4px]"> {value}</span>
      )}
    </p>
  );
};

const Activity = ({ activity }) => {
  const [isUpdateProgress, setIsUpdateProgress] = useState(false);
  const [isOpenModalImage, setIsOpenModalImage] = useState(false);
  const [isUpdateProfilePicture, setIsUpdateProfilePicture] = useState(false);
  const [hasValues, setHasValues] = useState(false);
  const [showValues, setShowValues] = useState(true);
  const [imgPreview, setImgPreview] = useState([]);

  const { user, text, objective, comment, object, doneAt } = activity || {};

  const modalImageHandler = (show, url) => {
    url && setImgPreview([{ src: url }]);
    setIsOpenModalImage(show);
  };

  useEffect(() => {
    setHasValues(activity?.text?.previousValue && activity?.text?.newValue);
    setIsUpdateProgress(
      activity?.text?.action === 'updated the target progress'
    );
    setIsUpdateProfilePicture(activity?.event === 'team.change_team_picture');
  }, [activity]);

  return (
    <>
      <div className="flex flex-col w-full py-[12px]">
        <div className="flex w-full">
          <Avatar name={user?.name} src={user?.profilePicture} size={40} />
          <div className="flex flex-col w-full ml-[16px]">
            <p
              className="typography-paragraph text-n-900"
              data-cy="activities-action"
            >
              <span className="typography-h400-longform">{user?.name}</span>{' '}
              {getObjectiveLocale(text?.action)}{' '}
              {objective && getObjectiveLocale('of')}{' '}
              {objective && (
                <span className="typography-h400-longform">
                  {objective?.name}
                </span>
              )}
              {isUpdateProgress ? (
                <span>
                  {' '}
                  {getObjectiveLocale('from')}{' '}
                  <span className="typography-h400-longform">
                    {text?.previousValue}
                  </span>{' '}
                  {getObjectiveLocale('to')}{' '}
                  <span className="typography-h400-longform">
                    {text?.newValue}
                  </span>
                </span>
              ) : (
                hasValues && (
                  <span>
                    .{' '}
                    <span
                      className="text-base-600 cursor-pointer"
                      onClick={() => setShowValues(!showValues)}
                    >
                      {getObjectiveLocale(showValues ? 'Hide' : 'Show')}
                    </span>
                  </span>
                )
              )}
            </p>
            {comment && (
              <p className="typography-paragraph text-n-900 mb-[8px]">
                {comment}
              </p>
            )}
            {!isUpdateProgress && hasValues && showValues && (
              <div className="flex flex-col bg-n-000 border-solid border-[1px] border-n-300 rounded-[4px] mb-[8px] p-[12px]">
                <SetActivitiesValue
                  keyActivities="new"
                  value={text?.newValue}
                  modalImageHandler={modalImageHandler}
                  isUpdateProfilePicture={isUpdateProfilePicture}
                />
                <SetActivitiesValue
                  keyActivities="prev"
                  value={text?.previousValue}
                  modalImageHandler={modalImageHandler}
                  isUpdateProfilePicture={isUpdateProfilePicture}
                />
              </div>
            )}
            {object &&
              object?.state === 'available' &&
              (object?.type === 'image' || object?.type === 'document') && (
                <a
                  className="mb-[8px] w-full"
                  href={object?.downloadUrl ? object?.downloadUrl : false}
                  download
                >
                  <AttachmentList
                    fileName={object?.name}
                    fileSize={formatBytes(object?.size)}
                    deleteAble={false}
                    takenOn={`taken on ${getDateLocale(object.fileTimestamp, {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                      hourCycle: 'h12',
                      hour: 'numeric',
                      minute: 'numeric'
                    })}`}
                    useNewDesign={true}
                  />
                </a>
              )}
            <p className="typography-h100 text-n-600" data-cy="activities-date">
              {getDateLocale(doneAt, {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hourCycle: 'h12',
                hour: 'numeric',
                minute: 'numeric'
              })}
            </p>
          </div>
        </div>
      </div>
      <ModalImage
        imgs={imgPreview}
        isOpen={isOpenModalImage}
        onClose={() => modalImageHandler(false)}
      />
    </>
  );
};

export default TeamActivities;

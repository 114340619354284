import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';
import Papa from 'papaparse';

import {
  getGroupCMSCulture,
  getLeaderboardsPostsCMSCulture,
  getLeaderboardsUsersCMSCulture
} from 'client/adminClient';
import { getCultureLocale, getNumberLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Button from 'components/design-system/Button';
import Avatar from 'components/shared/Avatar';
import { listOptionDate } from 'components/shared/Calendar/ListAntdDateHelper';
import InputField from 'components/shared/InputField';
import Loader from 'components/shared/LoadingComponent';

import { InsightCalendar } from '../Insight-retention/InsightHelpers';

function LeaderboardUser({ route: { title } }) {
  const [selectedDate, setSelectedDate] = useState([
    dayjs().startOf('week'),
    dayjs().endOf('week')
  ]);
  const [totalPage, setTotalPage] = useState(1);
  const [isDisableNext, setIsDisableNext] = useState(false);
  const [isDisablePrev, setIsDisablePrev] = useState(true);
  const [selectedGroupId, setSelectedGroupId] = useState(-2);
  const [groups, setGroup] = useState([]);
  const [totalOffset, setTotalOffset] = useState(0); // Pagination
  const [leaderboardData, setLeaderboardData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [activeFilter, setActiveFilter] = useState('creator_list');
  const [currentDateFilter, setCurrentDateFilter] = useState('');
  const [showDate, setShowDate] = useState({
    begin: dayjs().format('D MMM YYYY').toString(),
    end: dayjs().format('D MMM YYYY').toString()
  });

  const handleDownloadCSV = async () => {
    const params = {
      periodEnd: selectedDate[1]
        ? dayjs(selectedDate?.[1])?.toISOString()
        : null,
      groupId: selectedGroupId == -2 ? null : selectedGroupId,
      periodBegin: selectedDate[0]
        ? dayjs(selectedDate?.[0])?.toISOString()
        : null,
      offset: totalOffset,
      q: search,
      limit: 1000
    };

    const { data: ContentData } = await getLeaderboardsPostsCMSCulture(
      'users',
      activeFilter,
      params
    );

    if (ContentData) {
      let CSVDataPapa = [];
      ContentData[camelCase(activeFilter)].map((user, idx) => {
        let CSVDataFormat = [];

        CSVDataFormat.push(idx + 1);
        CSVDataFormat.push(user.fullName);
        CSVDataFormat.push(user.email || '-');
        CSVDataFormat.push(user.postsCount || 0);
        CSVDataFormat.push(user.commentsCount || 0);
        CSVDataFormat.push(user.lovesCount || 0);
        CSVDataFormat.push(user.recognizingCount || 0);
        CSVDataFormat.push(user.recognizedCount || 0);

        CSVDataPapa.push(CSVDataFormat);
      });

      let csv = Papa.unparse({
        fields: [
          'No.',
          'User Name',
          'Email',
          'Total Post',
          'Total Comment',
          'Like',
          'Recognition Given',
          'Recognition Received'
        ],
        data: CSVDataPapa
      });

      let data, filename, link;

      if (csv == null) return;

      filename = 'leaderboard_user_' + activeFilter + '.csv';

      if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
      }
      data = encodeURI(csv);

      link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1500);
    }
  };

  const handleSelectDate = (range) => {
    let format = 'D MMM YYYY';
    setCurrentDateFilter('');
    setShowDate({
      begin: dayjs(range[0])?.format(format).toString(),
      end: dayjs(range[1])?.format(format).toString()
    });
    setSelectedDate(range);
  };

  const handleChangeDateButton = (filterDate) => {
    if (filterDate === 'all_time') {
      setCurrentDateFilter('all_time');
      setShowDate({
        begin: 'Last Update',
        end: dayjs().format('D MMM YYYY').toString()
      });
      setSelectedDate([null, dayjs()]);
    }
    if (filterDate === 'last_month') {
      setCurrentDateFilter('last_month');
      setShowDate({
        begin: dayjs().subtract(1, 'month').format('D MMM YYYY').toString(),
        end: dayjs().format('D MMM YYYY').toString()
      });
      setSelectedDate([dayjs().subtract(1, 'month'), dayjs()]);
    }
    if (filterDate === 'two_month') {
      setCurrentDateFilter('two_month');
      setShowDate({
        begin: dayjs().subtract(2, 'month').format('D MMM YYYY').toString(),
        end: dayjs().format('D MMM YYYY').toString()
      });
      setSelectedDate([dayjs().subtract(2, 'month'), dayjs()]);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const params = {
      periodEnd: selectedDate[1] ? dayjs(selectedDate[1])?.toISOString() : null,
      groupId: selectedGroupId == -2 ? null : selectedGroupId,
      periodBegin: selectedDate[0]
        ? dayjs(selectedDate[0])?.toISOString()
        : null,
      offset: totalOffset,
      q: search,
      limit: 10
    };

    const { data, error } = await getLeaderboardsUsersCMSCulture(
      activeFilter,
      params
    );
    if (data) {
      setLeaderboardData(data[camelCase(activeFilter)]);
      setIsLoading(false);
      setIsDisableNext(false);
      if (data['nextOffset'] == -1) {
        setIsDisableNext(true);
      }
      if (totalOffset === 0) {
        setIsDisablePrev(true);
      } else {
        setIsDisablePrev(false);
      }
    }
  };

  const getGroup = async () => {
    let id;
    const { data, error } = await getGroupCMSCulture();
    if (data) {
      setGroup(data);
    }
  };

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  };

  useEffect(() => {
    getData();
    getGroup();
  }, [activeFilter, selectedDate, selectedGroupId, totalOffset]);

  const headerProps = {
    titlePage: title,
    primaryAction: {
      title: 'CSV',
      icon: 'icon-file_download',
      onClick: () => handleDownloadCSV()
    }
  };

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="admin-culture col-xs-12">
        <div className="dashboard_content_main retention">
          <div className="leaderboard-user">
            <div className="card mt-[24px]">
              <div className="card-body">
                <h4 className="body-header">
                  {getCultureLocale('Group')}{' '}
                  <i className="fa fa-question-circle-o"></i>
                </h4>
                <div className="wrapper-dropdown-leaderboard-user">
                  <div className="choose-group">
                    <label htmlFor="selectValue" className="select-value-hour">
                      <select
                        className="select-dropdown"
                        onChange={(e) =>
                          setSelectedGroupId(e.currentTarget.value)
                        }
                      >
                        <option key={-2} value={-2}>
                          {getCultureLocale('Overall')} -{' '}
                          {getCultureLocale('All')}
                        </option>
                        {groups.map(function (group, index) {
                          return (
                            <option key={index} value={group.id}>
                              {group.name}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  </div>
                  <div className="date-picker-wrapper--leaderboard">
                    <div className="leaderboard-date-picker">
                      <InsightCalendar
                        endDate={showDate.end}
                        startDate={showDate.begin}
                        selectedDate={selectedDate}
                        handleSelectDate={handleSelectDate}
                        customButtonClass="h-[36px]"
                        listOptionsTime={listOptionDate([
                          'This Week',
                          'Last Week',
                          'This Month',
                          'Last Month',
                          'Last 2 Months'
                        ])}
                      />
                    </div>
                    <div className="leaderboard-custom-filter mt-[8px]">
                      <div className="filter-date-wrapper">
                        <button
                          className={`btn-chartView ${
                            currentDateFilter === 'all_time' ? 'is-active' : ''
                          } button-left-radius`}
                          onClick={() => handleChangeDateButton('all_time')}
                        >
                          {getCultureLocale('All Time')}
                        </button>
                        <button
                          className={`btn-chartView ${
                            currentDateFilter === 'last_month'
                              ? 'is-active'
                              : ''
                          }`}
                          onClick={() => handleChangeDateButton('last_month')}
                        >
                          {getCultureLocale('Last Month')}
                        </button>
                        <button
                          className={`btn-chartView ${
                            currentDateFilter === 'two_month' ? 'is-active' : ''
                          } button-right-radius`}
                          onClick={() => handleChangeDateButton('two_month')}
                        >
                          {getCultureLocale('Last 2 Months')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-box-wrapper">
                  <div
                    onClick={() => setActiveFilter('creator_list')}
                    className={`filter-box ${
                      activeFilter === 'creator_list' ? 'filter-box-active' : ''
                    }`}
                  >
                    <div className="filter-box-heading">
                      <b>{getCultureLocale('TOP CREATOR')}</b>
                    </div>
                    <div className="filter-box-label">
                      {getCultureLocale('user with most post')}
                    </div>
                  </div>

                  <div
                    onClick={() => setActiveFilter('contributor_list')}
                    className={`filter-box ${
                      activeFilter === 'contributor_list'
                        ? 'filter-box-active'
                        : ''
                    }`}
                  >
                    <div className="filter-box-heading">
                      <b>{getCultureLocale('TOP CONTRIBUTOR')}</b>
                    </div>
                    <div className="filter-box-label">
                      {getCultureLocale('user with most comment')}
                    </div>
                  </div>

                  <div
                    onClick={() => setActiveFilter('awesome_list')}
                    className={`filter-box ${
                      activeFilter === 'awesome_list' ? 'filter-box-active' : ''
                    }`}
                  >
                    <div className="filter-box-heading">
                      <b>{getCultureLocale('TOP AWESOME PEOPLE')}</b>
                    </div>
                    <div className="filter-box-label">
                      {getCultureLocale('user with most recognition')}
                    </div>
                  </div>

                  <div
                    onClick={() => setActiveFilter('generous_list')}
                    className={`filter-box ${
                      activeFilter === 'generous_list'
                        ? 'filter-box-active'
                        : ''
                    }`}
                  >
                    <div className="filter-box-heading">
                      <b>{getCultureLocale('TOP GENEROUS PEOPLE')}</b>
                    </div>
                    <div className="filter-box-label">
                      {getCultureLocale('user who give most recognition')}
                    </div>
                  </div>

                  <div
                    onClick={() => setActiveFilter('influential_list')}
                    className={`filter-box ${
                      activeFilter === 'influential_list'
                        ? 'filter-box-active'
                        : ''
                    }`}
                  >
                    <div className="filter-box-heading">
                      <b>{getCultureLocale('TOP INFLUENTIAL PEOPLE')}</b>
                    </div>
                    <div className="filter-box-label">
                      {getCultureLocale('user with most like')}
                    </div>
                  </div>
                </div>
                <div className="hr dashboard_content_main mt-[5px]">
                  <div className="table-menu">
                    <div xs={6} className="search-container">
                      <h4 className="title-table">
                        <strong>{getCultureLocale('User Leaderboard')}</strong>
                      </h4>
                      <div className="wrapper-search-csv flex">
                        <InputField
                          id="searchUser"
                          type="text"
                          placeholder="Search by Name, Email and Role"
                          value={search}
                          handleChange={(e) => setSearch(e.currentTarget.value)}
                          onKeyDown={(e) => e.keyCode == 13 && getData()}
                        />
                        <Button onClick={() => getData()}>Go</Button>
                      </div>
                    </div>
                  </div>
                  <table className="table-list-employee table-user-leaderboard">
                    <thead>
                      <tr>
                        <th>{getCultureLocale('No')}</th>
                        <th>{getCultureLocale('Profile Pic')}</th>
                        <th>{getCultureLocale('User Name')}</th>
                        <th>{getCultureLocale('Email')}</th>
                        <th>{getCultureLocale('Total Post')}</th>
                        <th>{getCultureLocale('Total Comment')}</th>
                        <th>{getCultureLocale('Like')}</th>
                        <th>{getCultureLocale('Recognition Given')}</th>
                        <th>{getCultureLocale('Recognition Received')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="9">
                            <Loader />
                          </td>
                        </tr>
                      ) : leaderboardData.length === 0 ? (
                        <tr>
                          <td colSpan="9">{getCultureLocale('No Data')}</td>
                        </tr>
                      ) : (
                        leaderboardData.map(function (user, idx) {
                          var profilePic = '';
                          var hasProfilePic =
                            user.hasOwnProperty('profilePicture');

                          if (hasProfilePic) {
                            profilePic = user.profilePicture.secureUrl;
                          }

                          return (
                            <tr key={idx}>
                              <td>{idx + 1 + totalOffset}</td>
                              <td>
                                <Avatar
                                  src={profilePic}
                                  name={user.fullName}
                                  size={40}
                                />
                              </td>
                              <td>{user.fullName}</td>
                              <td>{user.email || '-'}</td>
                              <td>{getNumberLocale(user.postsCount || 0)}</td>
                              <td>
                                {getNumberLocale(user.commentsCount || 0)}
                              </td>
                              <td>{getNumberLocale(user.lovesCount || 0)}</td>
                              <td>
                                {getNumberLocale(user.recognizingCount || 0)}
                              </td>
                              <td>
                                {getNumberLocale(user.recognizedCount || 0)}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  <div className="table-footer text-right">
                    <span className="page-info">
                      {getCultureLocale('Page')} {totalPage}
                    </span>
                    <button
                      className={`btn-white btn-next ${
                        isDisableNext ? 'disabled' : ''
                      }`}
                      onClick={() => {
                        setTotalOffset(totalOffset + 10);
                        setTotalPage(totalPage + 1);
                      }}
                      disabled={isDisableNext}
                    >
                      {getCultureLocale('Next')}{' '}
                      <i className="fa fa-arrow-right"></i>
                    </button>
                    <button
                      className={`btn-white btn-next ${
                        isDisablePrev ? 'disabled' : ''
                      }`}
                      onClick={() => {
                        setTotalOffset(totalOffset - 10);
                        setTotalPage(totalPage - 1);
                      }}
                      disabled={isDisablePrev}
                    >
                      <i className="fa fa-arrow-left"></i>{' '}
                      {getCultureLocale('Previous')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaderboardUser;

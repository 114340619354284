import React from 'react';

import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import {
  abbrevNumber,
  countDecimals,
  formattedFloat,
  getObjectiveLocale
} from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Avatar from 'components/design-system/Avatar';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import FormattedNumber from '../design-system/FormattedNumber';

function ReviewDetail({ data, showOption, weight, measurement }) {
  const { config } = useUser();
  const { customIconOnReview: customIcon, ratingMechanism } = config;
  const ratingOnly = data[0];

  const extractedProgressPercentage =
    measurement?.progressPercentage > 1000
      ? abbrevNumber(measurement?.progressPercentage)
      : formattedFloat(measurement?.progressPercentage);

  const isUsingTooltip =
    measurement?.progressPercentage > 1000 ||
    countDecimals(measurement?.progressPercentage) > 2;

  function BehaviourComponent({ type }) {
    return (
      <div className="flex flex-col mt-[16px]">
        <p className="typography-h100 text-n-600 header-title mb-[8px]">
          {type == 'strength' ? 'Strength' : 'Improvement (private)'}
        </p>
        <p
          className={`typography-h400 ${
            type == 'strength' ? 'text-g-600' : 'text-y-600'
          } flex items-center`}
        >
          <SVGIcon
            iconName={
              type == 'strength' ? 'icon-thumb_up_alt' : 'icon-feedback'
            }
            fillColor={type == 'strength' ? 'var(--g-600)' : 'var(--y-600)'}
            customClass={`w-[24px] h-[24px] items-center justify-center mr-[8px] rounded-[50px] ${
              type == 'srength' ? 'bg-g-200' : 'bg-y-200'
            }`}
            size="16"
          />
          {ratingOnly?.[type]?.skill}
        </p>
        <p className="typography-paragraph whitespace-pre-wrap break-words">
          {ratingOnly?.[type]?.comment}
        </p>
      </div>
    );
  }

  return (
    <div className="review-detail flex-col flex bg-n-200 py-[16px] px-[16px] rounded">
      {ratingMechanism == 'progress_times_weight' ? (
        <>
          <FormattedNumber
            number={ratingOnly?.score}
            customClass="typography-h500 mb-[8px]"
          />
          <TooltipContainer
            show={isUsingTooltip}
            text={measurement?.progressPercentage}
            align="left"
          >
            <p className="typography-h100 text-n-600 flex items-center">
              {`${extractedProgressPercentage || 0} (Progress %) `}
              {weight > 0 && (
                <span className="typography-h100 text-n-600 ml-[4px]">{`x ${weight}% (Weight)`}</span>
              )}
            </p>
          </TooltipContainer>
        </>
      ) : (
        <>
          <p className="typography-h100 text-n-600 mt-[0px]">
            {getObjectiveLocale('Reviewed by')}
          </p>
          <div className="user-reviewed flex items-center mt-[8px]">
            <Avatar
              src={data[0]?.fromUser?.profilePicture}
              name={data[0]?.fromUser?.name}
              size={24}
            />
            <p className="typography-paragraph whitespace-pre-wrap break-words ml-[8px]">
              {data[0]?.fromUser?.name}
            </p>
          </div>
          {
            // product request
            // for review that has work attribute we need to map all rating
            // if not, only render the first one
            !customIcon ? (
              <>
                <p className="typography-h100 text-n-600 mt-[16px] mb-[8px]">
                  {getObjectiveLocale('Review Rating')}
                </p>
                <div className="flex items-center">
                  <img
                    src={Config.asset.createObjective.starYellow}
                    className="mr-[8px] mt-[0px] w-[24px] h-[24px]"
                  />
                  {showOption != 'label_only' && (
                    <p className="typography-h400 mr-[8px]">
                      {formattedFloat(ratingOnly?.score)}
                    </p>
                  )}

                  {showOption != 'score_only' && (
                    <p
                      className="typography-h400"
                      style={{ color: `#${ratingOnly?.scoringMark?.colorHex}` }}
                    >
                      {ratingOnly?.scoringMark?.name}
                    </p>
                  )}
                </div>
                {data[0]?.comment && (
                  <p className="typography-paragraph whitespace-pre-wrap break-words mt-[8px]">
                    {data[0]?.comment}
                  </p>
                )}
                <div className="review-rating flex flex-col mb-[0px]">
                  {ratingOnly.strength && (
                    <BehaviourComponent type="strength" />
                  )}
                  {ratingOnly?.weakness && (
                    <BehaviourComponent type="weakness" />
                  )}
                </div>
              </>
            ) : (
              data?.map((rating, index) => {
                return (
                  <>
                    <div
                      className="review-rating flex flex-col mt-[16px]"
                      key={index}
                    >
                      {
                        <div className="rating-icon">
                          <p className="typography-h100 text-n-600">
                            {rating?.reviewChoices[0]?.workAttribute?.name}
                          </p>
                          <div className="flex items-center">
                            <AWSImage
                              className="w-[24px] h-[24px]"
                              src={rating?.reviewChoices[0].scoringMark.iconUrl}
                            />
                            <p
                              className="typography-h400 mb-[0px] ml-[8px]"
                              style={{
                                color: `#${rating?.reviewChoices[0]?.scoringMark?.colorHex}`
                              }}
                            >
                              {rating?.reviewChoices[0]?.scoringMark?.name}
                            </p>
                          </div>
                        </div>
                      }
                      {rating?.comment && (
                        <p className="typography-paragraph whitespace-pre-wrap break-words mt-[8px]">
                          {rating?.comment}
                        </p>
                      )}
                    </div>
                  </>
                );
              })
            )
          }
        </>
      )}
    </div>
  );
}

export default ReviewDetail;

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import {
  getActiveCycles,
  getFormalReviewAnswers,
  getFormalReviewAnswersAspectScoring,
  getGeneralQuestionnaireResult,
  getPreviousCycle
} from 'client/FormalReviewClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { arrayRangeYears } from 'utils/DateHelper';
import { getObjectiveLocale } from 'utils/HelperUtils';

import CardBehaviorRecognitionFeedbackSidebar from 'components/formal-review/evidence-sidebar/CardBehaviorRecognitionSidebar';
import CardCompetenciesOrValuesScoring from 'components/formal-review/evidence-sidebar/CardCompetenciesOrValuesScoring';
import CardEachOrWorkSidebar from 'components/formal-review/evidence-sidebar/CardEachOrWorkSidebar';
import CardGeneralQuestionnaireSidebar from 'components/formal-review/evidence-sidebar/CardGeneralQuestionnaireSidebar';
import CardOverallInputScore from 'components/formal-review/evidence-sidebar/CardOverallInputScore';
import CardReviewAspectsScoringItem from 'components/formal-review/evidence-sidebar/CardReviewAspectsScoringItem';
import CardSuggestedOngoingSidebar from 'components/formal-review/evidence-sidebar/CardSuggestedOngoingSidebar';
import EvidenceSidebarHeader from 'components/formal-review/evidence-sidebar/EvidenceSidebarHeader';
import OngoingRecognitionFeedbackSection from 'components/formal-review/evidence-sidebar/OngoingRecognitionFeedbackSection';
import SummarySidebar from 'components/formal-review/evidence-sidebar/SummarySidebar';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';

import './EvidenceSidebar.scss';

const EvidenceSidebar = ({ setOverlaySidebar, visible = true }) => {
  const firstRender = useRef(true);

  const { config } = useUser();
  const { assignmentId } = useParams();
  const {
    evidenceSidebarSource,
    sidebarEvidenceDefaultCycle = 'current',
    sidebarEvidenceDefaultPhase = 'started_first'
  } = config || {};

  const rangeYears = arrayRangeYears(4);

  const formalReviewSource = evidenceSidebarSource.map((type, idx) => {
    return { id: idx, type };
  });

  const [isLoadingAnswer, setIsLoadingAnswer] = useState(true);
  const [isLoadingState, setIsLoadingState] = useState(true);
  const [answersData, setAnswersData] = useState(null);
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [sectionsBefore, setSectionsBefore] = useState([]);
  const [sectionsAfter, setSectionsAfter] = useState([]);
  const [selectedState, setSelectedState] = useState({
    year: rangeYears[0],
    source: formalReviewSource[0],
    cycle: {},
    phase: {},
    track: {}
  });

  const [stateDropdown, setStateDropdown] = useState({
    year: { show: true, data: rangeYears },
    source: { show: true, data: formalReviewSource },
    cycle: { show: true, data: [] },
    phase: { show: true, data: [] },
    track: { show: true, data: [] }
  });

  const { track } = selectedState;
  const trackType = track?.type;
  const template = track?.template;
  const mechanism = template?.mechanism;
  const questionsCount = template?.questionsCount;

  const [
    {
      cycle: currentCycle,
      involvedUser,
      phase: { type: phaseType },
      currentTrack,
      currentTrackId
    },
    { setOverlayRightSidebarData, getSingleTrack }
  ] = useFormalReview();

  const { target } = involvedUser;

  const filterPhaseDataToSelfAndPeer = (phases) => {
    return phases?.filter(
      (phase) => phase.type == 'self_review' || phase.type == 'peer_review'
    );
  };

  const filterPhases = (phases) => {
    const data = phases?.filter(
      (phase) =>
        phase?.type !== 'peer_selection' && phase?.type != 'calibration'
    );
    return phaseType == 'peer_review'
      ? filterPhaseDataToSelfAndPeer(data)
      : data;
  };

  const findSelectedTrack = (trackConfigs) => {
    const { name: trackName } = getSingleTrack(currentTrackId) || {};
    return trackConfigs?.find((trackConfig) => {
      return currentTrack !== 'review_aspects_scoring'
        ? trackConfig.type == currentTrack
        : trackConfig.type == currentTrack && trackConfig.name == trackName;
    });
  };

  const findSelectedCycle = (data, previousCycleId) => {
    // select currently opened cycle
    if (sidebarEvidenceDefaultCycle == 'current') {
      return data.find((cycle) => cycle.id == currentCycle.id);
    }
    // select latest ended cycle before current cycle
    else {
      return data.find((cycle) => cycle.id == previousCycleId);
    }
  };

  const findSelectedPhase = (filteredPhases) => {
    // select last index of filtered phases
    if (sidebarEvidenceDefaultPhase == 'started_first') {
      return filteredPhases[filteredPhases.length - 1];
    }
    // select current user review phase
    else {
      return (filteredPhases || []).find((phase) => phase.type == phaseType);
    }
  };

  const setupEvidenceDropdownData = (data, year, previousCycleId) => {
    const selectedCycle = findSelectedCycle(data, previousCycleId);
    const filteredPhases = filterPhases(selectedCycle?.phases) || '';
    const selectedPhase = findSelectedPhase(filteredPhases);
    const selectedTrack = findSelectedTrack(selectedPhase?.trackConfigs);

    setSelectedState({
      ...selectedState,
      source: formalReviewSource[0],
      cycle: selectedCycle,
      phase: selectedPhase,
      track: selectedTrack,
      year: rangeYears.find((currentYear) => currentYear.value === year)
    });

    setStateDropdown({
      ...stateDropdown,
      cycle: { show: true, data: data },
      phase: { show: true, data: filteredPhases },
      track: { show: true, data: selectedPhase?.trackConfigs }
    });

    setIsLoadingState(false);
    if (filteredPhases.length == 0) {
      setAnswersData([]);
      setIsLoadingAnswer(false);
    } else {
      getAnswersData(
        selectedCycle?.id,
        selectedPhase?.type,
        selectedTrack?.type,
        selectedTrack?.id
      );
    }
  };

  const getSidebarSourcesData = async (
    year = rangeYears[0].value,
    previousCycleId
  ) => {
    const currentYear = rangeYears[0]?.value;

    const params = {
      result: true,
      targetId: target.id,
      periodBegin: dayjs()
        .subtract(currentYear - year, 'year')
        .startOf('year')
        .toISOString(),
      periodEndBefore: dayjs()
        .subtract(currentYear - year, 'year')
        .endOf('year')
        .toISOString(),
      state: ['in_progress', 'done'],

      // backend need web to send assignmentId during peer review to do some validation
      ...(phaseType == 'peer_review' && { assignmentId: +assignmentId })
    };

    const { data } = await getActiveCycles(params);
    data && setupEvidenceDropdownData(data, year, previousCycleId);
  };

  const getAnswersData = async (
    cycleId,
    phaseType,
    trackType,
    trackConfigId
  ) => {
    setIsLoadingAnswer(true);
    const params = {
      targetId: target.id,
      requesterAssignmentId: assignmentId,
      sortColumn: 'id',
      sortDirection: 'asc'
    };

    if (trackType == 'goals_scoring' || trackType == 'tasks_scoring') {
      params.sortColumn =
        config?.defaultObjectiveSorting?.sortColumn || 'due_date';
      params.sortDirection =
        config?.defaultObjectiveSorting?.sortDirection || 'asc';
    }

    if (
      ![
        'general_questionnaire',
        'reviewee_feedback',
        'performance_rating'
      ].includes(trackType)
    ) {
      const { data } =
        trackType !== 'review_aspects_scoring' &&
        cycleId &&
        trackType &&
        phaseType
          ? await getFormalReviewAnswers(cycleId, trackType, phaseType, params)
          : (trackConfigId &&
              (await getFormalReviewAnswersAspectScoring(
                trackConfigId,
                'all',
                params
              ))) ||
            {};

      if (data) {
        setAnswersData(data);
      }
    }
    setIsLoadingAnswer(false);
  };

  const getGeneralQuestionnaireData = async (cycleId, phaseType, trackType) => {
    setIsLoadingAnswer(true);

    const params = {
      targetId: target.id,
      requesterAssignmentId: assignmentId
    };

    const { data } = await getGeneralQuestionnaireResult(
      cycleId,
      phaseType,
      trackType,
      params
    );
    if (data) {
      setQuestionnaireData(data);
    }
    setIsLoadingAnswer(false);
  };

  const getData = async () => {
    const { data } = await getPreviousCycle(target.id);
    if (data) {
      const { reviewedPeriodStartsAt, id: previousCycleId } = data;
      const year =
        sidebarEvidenceDefaultCycle == 'current'
          ? dayjs(currentCycle?.reviewedPeriodStartsAt).year()
          : dayjs(reviewedPeriodStartsAt).year();
      getSidebarSourcesData(year, previousCycleId);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (
      selectedState?.cycle?.id &&
      selectedState?.phase?.type &&
      selectedState?.track?.type
    ) {
      getGeneralQuestionnaireData(
        selectedState?.cycle?.id,
        selectedState?.phase?.type,
        selectedState?.track?.type
      );
    }
  }, [selectedState]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      // set active track evidence sidebar based on track is currently opened
      const selectedTrack = findSelectedTrack(
        selectedState?.phase?.trackConfigs
      );
      setSelectedState({
        ...selectedState,
        track: selectedTrack
      });

      // get answers data
      if (selectedTrack) {
        const trackConfigId = selectedTrack?.id || selectedState?.track?.id;
        getAnswersData(
          selectedState?.cycle?.id,
          selectedState?.phase?.type,
          currentTrack,
          trackConfigId
        );
      } else {
        setIsLoadingAnswer(true);
        setAnswersData(null);
        setTimeout(() => {
          setIsLoadingAnswer(false);
        }, 250);
      }
    }
  }, [currentTrack, currentTrackId]);

  const isAnswersDataEmpty = () => {
    if (trackType == 'general_questionnaire') {
      return questionnaireData?.length == 0;
    }

    if (trackType == 'summary') {
      return isEmpty(answersData);
    }

    if (isArray(answersData)) {
      return (
        answersData?.length == 0 ||
        answersData?.[0]?.answersData?.[0]?.answers?.length === 0
      );
    } else {
      return (
        answersData?.answersData?.length == 0 ||
        answersData?.performanceScoringData?.length == 0 ||
        answersData == null
      );
    }
  };

  const isEmptyStateShown =
    isAnswersDataEmpty() && isEmpty(questionnaireData) && !isLoadingAnswer;

  useEffect(() => {
    let tempSectionsBefore = [];
    let tempSectionsAfter = [];
    if (questionnaireData?.length > 0) {
      tempSectionsBefore = questionnaireData?.filter(
        (section) => section?.position == 'before'
      );
      tempSectionsAfter = questionnaireData?.filter(
        (section) => section?.position == 'after'
      );
    }
    setSectionsBefore(tempSectionsBefore);
    setSectionsAfter(tempSectionsAfter);
  }, [questionnaireData]);

  return (
    <div
      className={`h-full container-evidence-sidebar ${
        !visible ? 'hidden' : ''
      }`}
      data-cy="container-evidence-sidebar"
    >
      {!isLoadingState && (
        <EvidenceSidebarHeader
          stateDropdown={stateDropdown}
          setStateDropdown={setStateDropdown}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          getAnswersData={getAnswersData}
          getSidebarSourcesData={getSidebarSourcesData}
          setAnswersData={setAnswersData}
          filterPhases={filterPhases}
          setIsLoadingAnswer={setIsLoadingAnswer}
          getData={getData}
        />
      )}

      {selectedState?.source?.type == 'formal_review' && (
        <div
          className={`evidence-content ${
            isEmptyStateShown ? 'empty-state-container' : ''
          } px-[24px]`}
        >
          {!isEmpty(sectionsBefore) && questionsCount > 0 && (
            <CardGeneralQuestionnaireSidebar
              questionnaireData={sectionsBefore}
              trackType={trackType}
              position="before"
              isEvidenceSidebar
            />
          )}

          {!isEmpty(answersData) &&
            !isLoadingAnswer &&
            trackType == 'goals_scoring' &&
            (mechanism == 'each' || mechanism == 'input_score') && (
              <CardEachOrWorkSidebar
                id="each"
                showOption={answersData?.showOption}
                totalScore={answersData?.totalScore}
                answersData={answersData?.answersData}
                view={answersData.view}
              />
            )}

          {!isEmpty(answersData) &&
            !isLoadingAnswer &&
            (trackType == 'goals_scoring' || trackType == 'tasks_scoring') &&
            mechanism == 'specific_attribute' && (
              <CardEachOrWorkSidebar
                id="work"
                showOption={answersData?.showOption}
                totalScore={answersData?.totalScore}
                answersData={answersData?.answersData}
                view={answersData.view}
              />
            )}

          {!isEmpty(answersData) &&
            !isLoadingAnswer &&
            trackType == 'goals_scoring' &&
            mechanism == 'overall_score' && (
              <CardOverallInputScore
                showOption={answersData?.showOption}
                totalScore={answersData?.totalScore}
                components={answersData?.components}
                answersData={answersData.answers}
                view={answersData.view}
              />
            )}

          {!isEmpty(answersData) &&
            !isLoadingAnswer &&
            (trackType == 'goals_scoring' || trackType == 'tasks_scoring') &&
            mechanism?.includes('suggested_ongoing') && (
              <CardSuggestedOngoingSidebar
                showOption={answersData?.showOption}
                answersData={answersData?.performanceScoringData}
                trackType={trackType}
              />
            )}

          {!isEmpty(answersData?.answersData) &&
            !isLoadingAnswer &&
            (trackType == 'competencies_scoring' ||
              trackType == 'values_scoring') && (
              <CardCompetenciesOrValuesScoring
                setOverlaySidebar={setOverlaySidebar}
                setOverlayRightSidebarData={setOverlayRightSidebarData}
                trackType={trackType}
                targetId={target.id}
                totalScore={answersData?.totalScore}
                answersData={answersData?.answersData}
                showOption={answersData?.showOption}
              />
            )}

          {!isEmpty(answersData?.[0]?.answersData?.[0]?.answers) &&
            !isLoadingAnswer &&
            trackType === 'review_aspects_scoring' && (
              <CardReviewAspectsScoringItem
                answersData={answersData}
                trackType={trackType}
                showOption={answersData?.showOption}
              />
            )}

          {!isEmpty(answersData) &&
            !isLoadingAnswer &&
            (trackType == 'competencies_recognition_feedback' ||
              trackType == 'values_recognition_feedback') && (
              <CardBehaviorRecognitionFeedbackSidebar
                showOption={answersData?.showOption}
                answersData={answersData}
                trackType={trackType}
              />
            )}

          {!isEmpty(answersData) &&
            !isLoadingAnswer &&
            trackType === 'summary' && (
              <SummarySidebar answersData={answersData} />
            )}
          {!isEmpty(sectionsAfter) && questionsCount > 0 && (
            <CardGeneralQuestionnaireSidebar
              questionnaireData={sectionsAfter}
              trackType={trackType}
              position="after"
            />
          )}

          {isLoadingAnswer ? (
            <LoadingComponent />
          ) : (
            isEmptyStateShown && (
              <ListEmptyState
                emptyIcon="icon-no-result-found"
                title={getObjectiveLocale('No Result Found')}
                subtitle={getObjectiveLocale(
                  'The reviewer have not submitted the response yet'
                )}
                subtitleClassname="text-center"
                fullHeight={true}
              />
            )
          )}
        </div>
      )}

      {(selectedState?.source?.type == 'values_recognition_feedback' ||
        selectedState?.source?.type == 'competencies_recognition_feedback') && (
        <OngoingRecognitionFeedbackSection
          behaviorType={selectedState.source.type}
          targetId={target.id}
          year={selectedState.year}
        />
      )}
    </div>
  );
};

export default EvidenceSidebar;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';

import { getListTarget } from 'client/FormalReviewClient';
import useDebounce from 'hooks/useDebounce';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { SocketChannels, SocketListener } from 'utils/socket';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import Table from 'components/design-system/table/Table';
import ListEmptyState from 'components/shared/ListEmptyState';
import SearchBar from 'components/shared/SearchBar';
import { useRefetchQuery } from 'src/context/RefetchQueryContext';
import { toCamelCase } from 'src/utils/caseConverter';

import CalibrationUserRow from './CalibrationUserRow';

let abortController;
const LIMIT = 50;

const CalibrationTableSection = ({
  isPerfCategory,
  loadMore,
  setLoadMore,
  calibrationMechanism,
  scoringMarks,
  currentCalibrator,
  isAbleToShowCurrentScore
}) => {
  const [hasMore, setHasMore] = useState(false);
  const [scoreAspectsHeader, setScoreAspectsHeader] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [sort, setSort] = useState(null);
  const [paginationData, setPaginationData] = useState({});
  const [configResult, setConfigResult] = useState({
    goalResultVisible: false,
    taskResultVisible: false,
    competencyResultVisible: false,
    valueResultVisible: false,
    finalResultVisible: false,
    finalPerformanceCategoryResultVisible: false,
    currentCalibrationPerformanceCategoryResultVisible: false
  });
  const [search, setSearch] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCalibrationSidebar, setShowCalibrationSidebar] = useState(false);

  const debouncedSearchValue = useDebounce(search, 1000);
  const initializeSocketTimeout = useRef(false);

  const { refetchQueries } = useRefetchQuery();

  const pageParams = useParams();
  const filter = useFilter((state) => state.filterById?.calibration);
  const firstRender = useRef(true);
  const queryClient = useQueryClient();

  const cycleId = pageParams?.id;

  const finalScoreData = listUsers?.[0]?.score?.final;
  const finalPerformanceCategoryData =
    listUsers?.[0]?.score?.finalPerformanceCategory;

  const headers = [
    { name: getObjectiveLocale('no'), widthClass: 'w-[64px]' },
    {
      name: getObjectiveLocale('reviewee'),
      widthClass: 'w-[256px]',
      grow: true,
      sort: {
        default: sort?.sortDirection,
        onSort: (direction) =>
          setSort({ sortColumn: 'name', sortDirection: direction })
      }
    },
    {
      name: getObjectiveLocale('status'),
      customClass: 'justify-center',
      widthClass: 'w-[100px]',
      useHeaderMargin: false
    },
    {
      name: getObjectiveLocale('manager'),
      widthClass: 'w-[256px]',
      grow: true
    },
    ...scoreAspectsHeader,
    configResult?.finalResultVisible && {
      name: getObjectiveLocale('final score'),
      widthClass:
        finalScoreData?.showOption?.includes('label') &&
        finalScoreData?.statusName != null
          ? 'w-[200px]'
          : 'w-[144px]',
      sort: {
        default: sort?.sortDirection,
        onSort: (direction) =>
          setSort({ sortColumn: 'final', sortDirection: direction })
      }
    },
    configResult?.finalPerformanceCategoryResultVisible && {
      name: getObjectiveLocale('performance category'),
      widthClass:
        finalPerformanceCategoryData?.showOption?.includes('label') &&
        finalScoreData?.statusName != null
          ? 'w-[200px]'
          : 'w-[144px]'
    },
    { name: '', widthClass: 'w-[60px]' }
  ].filter((val) => val);

  const getUserCycle = async () => {
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();

    setUserLoading(true);
    let query = {
      limit: LIMIT,
      q: search,
      sortColumn: sort?.sortColumn,
      sortDirection: sort.sortDirection,
      sortScore: sort.sortDirection,
      showType: filter?.show?.map((e) => e?.id),
      managerIds: filter?.manager?.map((e) => e?.id),
      calibratorId: currentCalibrator?.id,
      ...filter
    };

    if (query.sortColumn == 'final') {
      delete query.sortColumn;
      delete query.sortDirection;
    }

    if (query.sortColumn == 'name') {
      delete query.sortScore;
    }

    delete query.show;
    filter.manager?.length > 0 && delete query.manager;

    const {
      data,
      metadata,
      pagination: dataPagination
    } = await getListTarget(cycleId, query, abortController.signal);
    if (data) {
      const displayCycleResult = metadata?.metadata?.displayCycleResult;
      const scoreAspects =
        displayCycleResult?.scoreAspects?.length != 0 &&
        displayCycleResult?.scoreAspects?.map((data) => {
          const scoreAspect = listUsers?.[0]?.score?.scoreAspects?.find(
            (scoreAspect) => scoreAspect?.id == data?.id
          );
          return {
            name: data.name,
            idAspect: data.id,
            widthClass:
              scoreAspect?.showOption?.includes('label') &&
              scoreAspect?.statusName != null
                ? 'w-[200px]'
                : 'w-[144px]'
          };
        });

      setScoreAspectsHeader(scoreAspects || []);
      setConfigResult(displayCycleResult);
      setListUsers(data);
      setPaginationData(dataPagination);

      const hasMore = data?.length === LIMIT;
      setHasMore(hasMore);
      setUserLoading(false);
      setLoadMore(false);
    }
    if (!socketListener.getChannels()) {
      // temp fix with set timeout
      // need to wait all query to be defined first in CalibrationUserRow
      // if not handled some user will not rendered correctly (only * is rendered)
      initializeSocketTimeout.current = setTimeout(() => {
        setupSocket();
      }, 2000);
    }
  };

  const appendUserCycle = async () => {
    let query = {
      q: search,
      sortColumn: sort?.sortColumn,
      sortDirection: sort.sortDirection,
      sortScore: sort.sortDirection,
      showType: filter?.show?.map((e) => e?.id),
      managerIds: filter?.manager?.map((e) => e?.id),
      olderThan: paginationData?.next?.olderThan,
      calibratorId: currentCalibrator?.id,
      ...filter
    };

    if (query.sortColumn == 'final') {
      delete query.sortColumn;
      delete query.sortDirection;
    }

    if (query.sortColumn == 'name') {
      delete query.sortScore;
    }

    delete query.show;
    filter.manager?.length > 0 && delete query.manager;

    const { data, pagination } = await getListTarget(cycleId, query);
    if (data) {
      setListUsers([...listUsers, ...data]);
      setPaginationData(pagination);

      const hasMore = pagination?.next?.olderThan !== null;
      setLoadMore(false);
      setHasMore(hasMore);
    }
  };

  const handleLoadMoreUser = async () => {
    if (!loadMoreLoading && hasMore && !userLoading) {
      setLoadMoreLoading(true);
      await appendUserCycle();
      setLoadMoreLoading(false);
    }
  };

  const updateUserRowData = useCallback(
    (data) => {
      const queryKey = ['calibration', data.userId, data.placementId];
      const queryData = queryClient.getQueryData(queryKey);

      // We only need to update data of user which already loaded & rendered
      // User that hasn't loaded yet will not have a query data
      // hence we don't need to update the data
      if (!queryData) return;

      queryClient.setQueryData(queryKey, (oldData) => {
        if (data.status) {
          return {
            ...oldData,
            data: {
              ...oldData?.data,
              status: data.status
            }
          };
        } else {
          // ignore is_calibrate from socket
          // because it is from sender perspective
          // not from current user / receiver
          const newData = { ...data };
          delete newData.isCalibrator;
          return {
            ...oldData,
            data: { ...oldData.data, ...newData }
          };
        }
      });
    },
    [queryClient]
  );

  const setupTargetsStatus = useCallback(
    (data) => {
      data.forEach((value) => {
        updateUserRowData(value);
      });
    },
    [updateUserRowData]
  );
  const socketListener = React.useMemo(() => new SocketListener(), []);

  const setupSocket = useCallback(() => {
    socketListener.register({
      name: SocketChannels.calibration(pageParams.id),
      callback: (data) => {
        const response = toCamelCase(data);
        if (Array.isArray(response)) {
          setupTargetsStatus(response);
        } else {
          // update User Row
          updateUserRowData(response);
        }

        // updateQuotaCalibration
        if (!response.status) {
          refetchQueries(['calibration', 'quota']);
        }
      }
    });
  }, [
    pageParams.id,
    refetchQueries,
    setupTargetsStatus,
    socketListener,
    updateUserRowData
  ]);

  useEffect(() => {
    const getData = async () => {
      if (calibrationMechanism && filter && sort) {
        setIsLoading(true);
        await getUserCycle();
        setIsLoading(false);
      }
    };

    getData();
    // eslint-disable-next-line
  }, [filter, sort, currentCalibrator?.id]);

  useEffect(() => {
    if (!firstRender.current) {
      getUserCycle();
    }

    // eslint-disable-next-line
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (isPerfCategory) {
      setSort({ sortColumn: 'final', sortDirection: 'desc' });
    } else {
      setSort({ sortColumn: 'name', sortDirection: 'asc' });
    }
  }, [isPerfCategory]);

  useEffect(() => {
    if (!firstRender.current) {
      handleLoadMoreUser();
    }
    // eslint-disable-next-line
  }, [loadMore]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);

  useEffect(() => {
    return () => {
      if (socketListener) {
        socketListener.unregister();
      }
      if (initializeSocketTimeout.current) {
        clearTimeout(initializeSocketTimeout.current);
      }
    };
  }, [socketListener, initializeSocketTimeout]);

  return (
    <div className="team-result-table-wrapper mt-[26px] px-[40px]">
      <div className="mb-[16px]">
        <SearchBar
          fullWidth={true}
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <Table
        headers={headers}
        fixedLeftCount={2}
        fixedRightCount={1}
        withHideColumn
      >
        {isLoading
          ? [...new Array(10)].map((_, index) => (
              <Table.Row key={`loading+row+${index}`}>
                {headers.map((_, index) => (
                  <Table.Column key={`loading+column+${index}`}>
                    <Shimmer height="20px" widthRandomness={0.25} circle />
                  </Table.Column>
                ))}
              </Table.Row>
            ))
          : listUsers?.length > 0 &&
            listUsers?.map((user, index) => (
              <CalibrationUserRow
                key={user.id}
                cycleId={cycleId}
                index={index}
                user={user}
                scoreAspectsHeader={scoreAspectsHeader}
                configResult={configResult}
                isPerfCategory={isPerfCategory}
                scoringMarks={scoringMarks} // props drilling
                currentCalibrator={currentCalibrator}
                isAbleToShowCurrentScore={isAbleToShowCurrentScore}
                showCalibrationSidebar={showCalibrationSidebar}
                setShowCalibrationSidebar={setShowCalibrationSidebar}
              />
            ))}

        {!isLoading && loadMoreLoading && (
          <Table.Row>
            {headers.map((_, index) => (
              <Table.Column key={`loadmore+${index}`}>
                <Shimmer height="20px" widthRandomness={0.25} circle />
              </Table.Column>
            ))}
          </Table.Row>
        )}

        {listUsers?.length === 0 && (
          <ListEmptyState
            fullHeight
            emptyIcon="icon-no-reviewee"
            title={getObjectiveLocale('No Reviewee')}
            subtitle={getObjectiveLocale(
              'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
            )}
            containerClassname="mb-[24px]"
          />
        )}
      </Table>
    </div>
  );
};

CalibrationTableSection.displayName = 'CalibrationTableSection';

export default CalibrationTableSection;

import React, { useEffect, useState } from 'react';

import classSetter from 'classnames';

import { useColleague } from 'context/ColleagueContext';
import debounce from 'utils/Debounce';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';

import FormUserComponent from 'components/shared/FormUserComponent';
import InputField from 'components/shared/InputField';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

function SearchUserComponent(props) {
  const [search, setSearch] = useState('');
  const [onLoadMore, setLoadMore] = useState(false);
  const [onSearch, setOnSearch] = useState(false);
  const [debounceHandler, setDebounceHandler] = useState(null);
  const { colleague, hasMore, getColleagueData, appendColleagueData } =
    useColleague();

  const classname = {
    'card-search-user': true
  };

  const onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      let query = {};
      if (search != '') {
        query.q = search;
      }

      setLoadMore(true);
      await appendColleagueData(query);
      setLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 500, () => {
        loadMore();
      });
    }
  };

  const searchColleague = async (value) => {
    const callback = async () => {
      setOnSearch(true);
      setSearch(value);
      await getColleagueData({ q: value });
      setOnSearch(false);
    };

    const handler = await debounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  useEffect(() => {
    const _getColleagueData = async () => {
      setLoadMore(true);
      await getColleagueData();
      setLoadMore(false);
    };

    _getColleagueData();
  }, []);

  return (
    <div className={classSetter(classname, props.className)}>
      <div className="search-container no-selected">
        <div className="search-input">
          <SVGIcon iconName="icon-search" size="24" fillColor="var(--n-600)" />
          <InputField
            placeholder="Search colleagues"
            handleChange={(e) => searchColleague(e.target.value)}
            className="-mt-[2px] h-[28px]"
            inputClass="py-[0px]"
            autoFocus
          />
        </div>
      </div>
      <div
        className="user-list-container"
        onScroll={(e) => {
          onScroll(e);
        }}
      >
        {onSearch ? (
          <div>
            <LoadingComponent className="user-loading" />
          </div>
        ) : colleague.length > 0 || search == '' ? (
          colleague.map((data, key) => {
            return (
              <FormUserComponent
                type="search"
                data={data}
                key={key}
                onClick={() => props.onClick(data)}
              />
            );
          })
        ) : (
          <ListEmptyState
            fullHeight
            containerClassname="mx-[20px] mb-[12px]"
            emptyIcon="icon-no-user-found"
            size="small"
            title={getObjectiveLocale('User not found')}
            subtitle={getObjectiveLocale(
              'We are sorry that the User you searched was unfortunately not found.'
            )}
          />
        )}
        {onLoadMore && (
          <div>
            <LoadingComponent className="user-loading" />
          </div>
        )}
      </div>
      {props.children}
    </div>
  );
}

export default SearchUserComponent;

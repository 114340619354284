import React from 'react';

function PerformanceReportEmployeeRole({ numberOfRole }) {
  return (
    <div className="performance-report-employee-role-container">
      <h1>Employee Role</h1>
      <div>
        <p>{numberOfRole}</p>
        <p>Role</p>
      </div>
    </div>
  );
}

PerformanceReportEmployeeRole.defaultProps = {
  numberOfRole: 2
};

export default PerformanceReportEmployeeRole;

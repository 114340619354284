import React from 'react';

import './CompactSkeleton.scss';

const CompactSkeleton = () => {
  return (
    <div className="p-16 border border-solid border-n-300 rounded bg-n-000 flex flex-row">
      <div className="w-[24px] h-[24px] rounded-full animate"></div>
      <div className="flex flex-row flex-[1] justify-between">
        <div className="flex flex-col flex-[1] pl-[16px] pr-[24px]">
          <div className="h-[20px] w-full animate " />
          <div className="flex flex-row mt-[8px]">
            <div className="h-[20px] w-[120px] w-full animate mr-[8px]" />
            <div className="h-[20px] w-[120px] w-full animate" />
          </div>
        </div>
        <div className="w-[452px] flex flex-row">
          <div className="h-[20px] w-[104px] w-full animate mr-[24px]" />
          <div className="h-[20px] w-[144px] w-full animate mr-[24px]" />
          <div className="h-[20px] w-[120px] w-full animate mr-[16px]" />
          <div className="w-[20px] h-[20px] rounded-full animate"></div>
        </div>
      </div>
    </div>
  );
};

export default CompactSkeleton;

import React, { useState } from 'react';

import { loginUser } from 'client/AuthClient';
import { useAuth } from 'context/AuthContext';

import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import Modal from 'components/shared/modal/Modal';

const RedGear = ({ buildSettingList }) => {
  const redLsEnv = JSON.parse(localStorage.getItem('redLsEnv') || '{}');

  const { login } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [setting, setSetting] = useState({
    email: '',
    organization: '',
    password: '',
    USE_PRODUCTION_ENV: redLsEnv ? redLsEnv?.USE_PRODUCTION_ENV : false
  });
  const [error, setError] = useState({
    isError: false,
    message: ''
  });

  const settingList = buildSettingList({
    state: setting,
    setState: setSetting,
    redLsEnv
  });

  const handleSubmit = async () => {
    const { USE_PRODUCTION_ENV } = setting;
    if (USE_PRODUCTION_ENV) {
      const newEnv = {
        // TO DO: change to PERFORMANCE_API_HOST: 'https://api.performance.co.id'
        PERFORMANCE_API_HOST: 'https://api.happy5.co',
        AUTHORIZATION_METHOD: 'bearer',
        USE_PRODUCTION_ENV: USE_PRODUCTION_ENV
      };
      localStorage.setItem('redLsEnv', JSON.stringify(newEnv));
    } else {
      localStorage.removeItem('redLsEnv');
      window.location.href = '/';
      return;
    }

    const { data } = await loginUser({
      email: 'administrator@happy5.co',
      password: setting.password,
      organization: setting.organization
    });
    localStorage.setItem('bearer', data?.token);

    const { error } =
      (await login({
        email: setting.email,
        organization: setting.organization
      })) || {};
    if (error) {
      setError({ isError: true, message: error?.message });
    } else {
      window.location.href = '/';
    }
  };

  return (
    <>
      <SVGIcon
        size="48"
        iconName="icon-settings"
        fillColor="var(--r-700)"
        onClick={() => setShowModal((prev) => !prev)}
      />
      {showModal && (
        <Modal
          useBorder
          withFooter
          className="max-w-[400px]"
          title="Environment Configuration"
          eventOnClickClose={() => setShowModal(false)}
          contentClass="overflow-auto grid gap-xs"
          withCloseIcon={false}
          withPrimaryBtn={{
            onClick: handleSubmit,
            title: 'Save'
          }}
          maxHeight={500}
        >
          {settingList.map((setting, index) => {
            return setting.type === 'toggle' && setting.config === 'redGear' ? (
              <div className="flex justify-between" key={index}>
                <span className="typography-h200">{setting.title}</span>
                <SmallToggleSwitchPurple {...setting} />
              </div>
            ) : (
              (setting.type === 'text' || setting.type === 'password') &&
                setting.config === 'redGear' && (
                  <InputField {...setting} className="mt-[16px]" />
                )
            );
          })}
          {error.isError && (
            <span className="fs-12 font-light mt-[12px] text-r-500">
              {error.message}
            </span>
          )}
        </Modal>
      )}
    </>
  );
};

export default RedGear;

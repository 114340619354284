import React from 'react';

import DynamicTabGrey from '../../../shared/Tabs/DynamicTabGrey';
import { dictValueRecognition } from '../ConfigRatingTemplate';

const ContentValueRecognitionActivites = ({
  stateValueRecognition,
  setStateValueRecognition
}) => {
  const configValueRecognition = ['given', 'received', 'entire'];

  return (
    <div id="value-recognition" className="container-content-value-recognition">
      <p className="typography-h500">Value recognition activities</p>
      <p className="content-subtitle">
        Determinate the type of activities to be taken into the calculation
      </p>

      <DynamicTabGrey
        listName={configValueRecognition}
        state={stateValueRecognition}
        setState={setStateValueRecognition}
        nameDict={dictValueRecognition}
      />
    </div>
  );
};

export default ContentValueRecognitionActivites;

import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import {
  getNonMemberGroup,
  getSearchUserNew,
  inviteMemberGroup
} from 'client/CultureClient';
import useDebounce from 'hooks/useDebounce';
import { getCultureLocale, loadMoreValidator } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import SearchBar from 'components/shared/SearchBar';
import Modal from 'components/shared/modal/Modal';
import Checkbox from 'src/components/shared/Checkbox';

const ModalInviteMemberV2 = ({
  groupDetail,
  setShowInviteModal,
  type = 'edit',
  submitGroup
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [searchUser, setSearchUser] = useState('');
  const [totalUser, setTotalUser] = useState(0);
  const [listUser, setListUser] = useState([]);
  const [nextScrollCursor, setNextScrollCursor] = useState(0);
  const [selectedUser, setSelectedUser] = useState([]);
  const selectedUserIds = selectedUser?.map((user) => user?.id);
  const debouncedSearch = useDebounce(searchUser, 2000);

  const getUsers = async (isScroll) => {
    const params = {
      limit: 10,
      cursor: isScroll ? nextScrollCursor : 0,
      q: debouncedSearch
    };
    const getFunction =
      type == 'edit'
        ? await getNonMemberGroup(groupDetail?.id, params)
        : await getSearchUserNew(params);
    const { data, next_cursor, metadata } = await getFunction;

    if (data) {
      setListUser((list) => (isScroll ? [...list, ...data] : data));
      isAll &&
        setSelectedUser((selected) =>
          isScroll ? [...selected, ...data] : data
        );
      setNextScrollCursor(next_cursor);
      setTotalUser(metadata?.totalNonMembers);
      setIsLoading(false);
    }
  };

  const inviteMember = async () => {
    if (selectedUser?.length > 0) {
      setIsButtonLoading(true);
      if (type == 'edit') {
        const params = {
          all: isAll,
          targetIds: isAll ? [] : selectedUserIds
        };
        const { isSuccess } = await inviteMemberGroup(groupDetail.id, params);
        if (isSuccess) {
          setShowInviteModal(false);
        }
      } else {
        submitGroup(selectedUser, isAll);
      }
      setIsButtonLoading(true);
    }
  };

  const onScrollUser = async (e) => {
    if (e.target) {
      const target = e.target;

      const loadMore = async () => {
        setOnLoadMore(true);
        await getUsers(true);
        setOnLoadMore(false);
      };

      if (!onLoadMore && nextScrollCursor) {
        loadMoreValidator(target, 50, () => {
          loadMore();
        });
      }
    }
  };

  const onClickUser = (currentUser) => {
    let clonedUser = cloneDeep(selectedUser);
    if (selectedUserIds?.includes(currentUser?.id)) {
      clonedUser = clonedUser.filter((user) => user?.id !== currentUser?.id);
    } else {
      clonedUser.push(currentUser);
    }

    setSelectedUser(clonedUser);
  };

  const setAllMember = () => {
    setIsAll(!isAll);
    setSelectedUser(isAll ? [] : listUser);
  };

  useEffect(() => {
    setIsLoading(true);
    getUsers(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <Modal
      dataCyModal="manage-member-modal"
      title={getCultureLocale('Invite Members')}
      className="w-[600px]"
      eventOnClickClose={() => setShowInviteModal(false)}
      maxHeight={560}
      withPrimaryBtn={{
        title: getCultureLocale('Invite'),
        onClick: () => inviteMember(),
        isLoading: isButtonLoading,
        disabled: selectedUser?.length == 0,
        dataCy: 'invite-member-btn'
      }}
      withSecondaryBtn={{
        title: getCultureLocale('GenericCancel'),
        onClick: () => setShowInviteModal(false),
        dataCy: 'secondary-member-btn'
      }}
    >
      <div className="flex mb-[16px] w-full overflow-x-auto overflow-y-hidden">
        {selectedUser?.map((user) => (
          <div
            className="flex relative mr-[8px]"
            key={`selected-member-${user?.id}`}
          >
            <Avatar
              name={user?.fullName}
              className="mr-[-4px]"
              src={user?.profilePicture?.secureUrl || null}
              size={24}
            />
            <SVGIcon
              iconName="icon-clear"
              size="24"
              fillColor="var(--n-000)"
              dataCy="cancel-user"
              customClass="w-[16px] h-[16px] rounded-[50%] bg-n-600 flex items-center ml-[-4px] z-[2]"
              onClick={() => onClickUser(user)}
            />
          </div>
        ))}
      </div>
      <SearchBar
        fullWidth
        placeholder={getCultureLocale('Search member to be invited...')}
        onChange={(e) => setSearchUser(e.target.value)}
        value={searchUser}
        dataCy="search-member"
        containerClass="mb-[24px]"
      />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          {listUser?.length > 0 ? (
            <>
              <div className="flex">
                <Checkbox
                  dataCy="check-all-member"
                  onChange={() => setAllMember()}
                  checked={selectedUser?.length === totalUser || isAll}
                  name="checkbox"
                  size="18"
                  id="select-all-member"
                />
                <span className="ml-[8px] flex items-center">
                  {getCultureLocale('SelectAll')} {getCultureLocale('Members')}
                </span>
              </div>
              <div
                className="overflow-auto h-[200px]"
                onScroll={(e) => onScrollUser(e)}
              >
                {listUser?.map((user) => (
                  <div className="flex" key={`invite-user-${user?.id}`}>
                    <Checkbox
                      dataCy={`check-${user?.id}`}
                      onChange={() => onClickUser(user)}
                      checked={selectedUserIds?.includes(user?.id)}
                      name="checkbox"
                      size="18"
                      id={`check-${user?.id}`}
                    />
                    <span className="ml-[8px] flex items-center typography-button">
                      <Avatar
                        name={user?.fullName}
                        className="mr-[4px]"
                        src={user?.profilePicture?.secureUrl || null}
                        size={24}
                      />
                      {user?.fullName}
                    </span>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <ListEmptyState
              title={getCultureLocale('Member not found or already invited')}
              fullHeight
              subtitleClassname="text-center mb-[24px]"
              emptyIcon="icon-no-user-found"
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default ModalInviteMemberV2;

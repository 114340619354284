export default {
  data: {
    id: 1,
    type: 'goal',
    name: 'MY NEW CREATE GOAL',
    created_at: '2019-09-11T08:31:50.202Z',
    updated_at: '2019-10-18T11:02:55.144Z',
    state_object: {
      state: 'completed',
      state_medium_icon_url:
        'https://s3-ap-southeast-1.amazonaws.com/production-performance-happy5/assets/ico-complete%402x.png',
      state_small_icon_url:
        'https://s3-ap-southeast-1.amazonaws.com/production-performance-happy5/assets/ico-status-complete%402x.png'
    },
    milestone_type: 'disabled',
    overdue: false,
    start_date: '2019-08-08T17:00:00.793Z',
    due_date: '2019-08-21T16:59:59.999Z',
    weight: 50,
    complexity: 1.0,
    is_project: false,
    is_company_goal: false,
    team_id: null,
    team: null,
    objective_category: {
      id: 2,
      name: 'Less Important',
      weight: 0.0,
      color_hex: '03b54d',
      bg_color_hex: 'A9FDAC'
    },
    calculation_type: 'normal',
    permissions: [
      'update',
      'update_current_value',
      'comment',
      'uncomplete',
      'delete',
      'review',
      'align_parent',
      'add_subgoal',
      'add_subtask',
      'edit_privacy',
      'label_manage',
      'edit_name',
      'update_comment',
      'progress_comment',
      'edit_description',
      'edit_weight',
      'edit_measurement_unit',
      'edit_roll_up',
      'edit_calculation_type',
      'edit_milestones',
      'edit_objective_category',
      'edit_tags',
      'edit_starting_value',
      'edit_target_value'
    ],
    scoring_mark: null,
    measurement: {
      id: 2,
      description: 'Percentage',
      unit: '%',
      unit_icon: null,
      roll_up: 'auto',
      starting_value: 100.0,
      current_value: 100.0,
      progress_percentage: 0,
      target_value: 10000000.0,
      progress_color: 3,
      progress_color_hex: 'FE2851',
      expected_progress_value: 10000000.0,
      expected_progress_percentage: 100
    },
    parents: null,
    parent: null,
    description: 'AAAA',
    children_count: 0,
    scoring_template: {
      id: 1,
      name: 'Default Template Scoring'
    },
    reviews: [],
    tags: ['android', 'javascript'],
    recurrence: {
      every: 1,
      type: 'day',
      state: 'active',
      next_date: '2020-04-01T17:00:00.000Z'
    },
    is_private: false,
    reviews_visibility: true,
    involvements: [
      {
        user: {
          id: 156,
          email: 'hari@happy5.co',
          name: 'Hari',
          role: 'admin',
          culture_role: null,
          state: 'active',
          job_title: 'Frontend Developer',
          info: {
            city: '-',
            region: 'Asia',
            country: 'Singapore',
            division: '-',
            job_role: 'front end',
            department: '-',
            directorate: '-',
            job_function: 'front end',
            salary_level: '-'
          },
          phone_number: null,
          profile_picture:
            'http://staging-performance-happy5.imgix.net/happy5-1/4a9b4644-028d-42aa-bec8-455f36ef764a.jpg?fit=crop\u0026h=96\u0026w=96\u0026mask=ellipse\u0026fm=png',
          workload_status: 'manageable',
          is_company_goal_creator: true
        },
        role: 'assignee',
        review_weight: null,
        visible: true
      },
      {
        user: {
          id: 159,
          email: 'christopher@happy5.co',
          name: 'Christopher',
          role: 'admin',
          culture_role: null,
          state: 'active',
          job_title: 'front end developer',
          info: {
            city: '-',
            region: 'Asia',
            country: 'Indonesia',
            division: '-',
            job_role: 'front end',
            department: '-',
            directorate: '-',
            job_function: 'front end',
            salary_level: '-'
          },
          phone_number: null,
          profile_picture:
            'https://staging-performance-happy5.s3-ap-southeast-1.amazonaws.com/profiles/happy5-1/e1cb768e-b6af-425f-96f3-1f0821a1a3c0.jpg',
          workload_status: 'manageable',
          is_company_goal_creator: false
        },
        role: 'assigner',
        review_weight: 1,
        visible: true
      },
      {
        user: {
          id: 4,
          email: 'yashael@happy5.co',
          name: 'Yashael',
          role: 'admin',
          culture_role: null,
          state: 'active',
          job_title: 'Frontend Developer',
          info: {
            city: '-',
            region: 'Asia',
            country: 'Singapore',
            division: '-',
            job_role: 'front end',
            department: '-',
            directorate: '-',
            job_function: 'front end',
            salary_level: '-'
          },
          phone_number: null,
          profile_picture: null,
          workload_status: 'manageable',
          is_company_goal_creator: true
        },
        role: 'follower',
        review_weight: null,
        visible: true
      }
    ]
  }
};

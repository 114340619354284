import startCase from 'lodash/startCase';
import cloneDeep from 'lodash/cloneDeep';

const isAllCheckHandler = (listUsers, selectedUsers) => {
  const { selectAll, includes, excludes } = selectedUsers;
  if (!listUsers) return;
  if (selectAll) {
    let filteredLength = listUsers.filter(
      value => excludes?.indexOf(value.id) > -1
    ).length;
    return filteredLength === 0;
  } else {
    let filteredLength = listUsers.filter(
      value => includes?.indexOf(value.id) > -1
    ).length;
    return filteredLength === listUsers.length;
  }
};

const handleCheckMembers = (ids, selectedUsers, setSelectedUsers) => {
  const { selectAll, includes, excludes } = selectedUsers;
  let tempIncludes = cloneDeep(includes);
  let tempExcludes = cloneDeep(excludes);
  if (selectAll) {
    let newArray = tempExcludes.filter(value => ids.indexOf(value) == -1);
    setSelectedUsers({ ...selectedUsers, excludes: newArray });
  } else {
    let uniqueIds = new Set([...tempIncludes, ...ids]);
    setSelectedUsers({ ...selectedUsers, includes: [...uniqueIds] });
  }
};

const handleUncheckMembers = (ids, selectedUsers, setSelectedUsers) => {
  const { selectAll, includes, excludes } = selectedUsers;
  let tempIncludes = cloneDeep(includes);
  let tempExcludes = cloneDeep(excludes);
  if (selectAll) {
    setSelectedUsers({
      ...selectedUsers,
      excludes: tempExcludes.concat(ids)
    });
  } else {
    let newArray = tempIncludes.filter(value => ids.indexOf(value) == -1);
    setSelectedUsers({ ...selectedUsers, includes: newArray });
  }
};

const checkAllHandler = (listUsers, selectedUsers, setSelectedUsers) => {
  let usersArray = [...listUsers].map((v, i) => {
    return v.id;
  });
  if (isAllCheckHandler(listUsers, selectedUsers)) {
    handleUncheckMembers(usersArray, selectedUsers, setSelectedUsers);
  } else {
    handleCheckMembers(usersArray, selectedUsers, setSelectedUsers);
  }
};

const selectMembers = (e, value, selectedUsers, setSelectedUsers) => {
  let checked = e.currentTarget.checked;
  if (checked) {
    handleCheckMembers([value.id], selectedUsers, setSelectedUsers);
  } else {
    handleUncheckMembers([value.id], selectedUsers, setSelectedUsers);
  }
};

const isSelected = (user, selectedUsers) => {
  const { selectAll, includes, excludes } = selectedUsers;
  if (selectAll) {
    return excludes.indexOf(user.id) === -1 ? true : false;
  } else {
    return includes.indexOf(user.id) > -1 ? true : false;
  }
};

const seriesGenerator = data => {
  const haveCreated = data?.map(overviewData => {
    return overviewData?.have;
  });
  const haveNotCreated = data?.map(overviewData => {
    return overviewData?.haveNot;
  });
  const series = [
    {
      name: 'have Not',
      data: haveNotCreated
    },
    {
      name: 'have',
      data: haveCreated
    }
  ];
  return series;
};

const getCategories = (data, type) => {
  const categories = data?.map(overviewData => {
    return startCase(overviewData?.[type]?.toLowerCase());
  });
  return categories;
};

export {
  seriesGenerator,
  getCategories,
  selectMembers,
  isAllCheckHandler,
  isSelected,
  checkAllHandler
};

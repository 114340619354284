import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  getTeamGoalApproval,
  requestTeamGoalApproval
} from 'client/ApprovalClient';
import {
  getObjectiveChildren,
  getObjectivePermissions
} from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import useTeam from 'hooks/useTeam';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import BannerBox from 'components/shared/BannerBox';
import SVGIcon from 'components/shared/SVGIcon';
import NewOverallProgress from 'pages/dashboardOverallViewProgress/new-compact-statistics/NewOverallProgress';
import ApprovalWarnings from 'pages/teams/approval/ApprovalWarnings';
import WithdrawConfirmationModal from 'pages/teams/approval/WithdrawConfirmationModal';
import TooltipContainer from 'src/components/shared/Tooltips/TooltipContainer';

const SubGoalStatistics = ({ objectiveId, objectiveConfigs }) => {
  const [permissions, setPermissions] = useState([]);
  const [approvalDetail, setApprovalDetail] = useState({});
  const [listStatus, setListStatus] = useState([]);
  const [warningList, setWarningList] = useState([]);
  const [showWithdrawConfirmationModal, setShowWithdrawConfirmationModal] =
    useState(false);
  const [isRequestButtonLoading, setIsRequestButtonLoading] = useState(false);

  const params = useParams();

  const { addToast } = useToastContext();
  const { refetchQueries, refetchObjective } = useRefetchQuery();
  const { approvalSubGoals } = useTeam();

  const adjustWeightModalCustomParams = {
    reviewsVisibility: 1,
    sortColumn: 'name',
    sortDirection: 'asc',
    parentId: objectiveId,
    goals: 'team',
    approvalRequired: true,
    teamId: params?.teamId,
    state: ['draft', 'edited', 'running', 'reviewed', 'completed']
  };

  const adjustWeightModalEventOnUpdate = () => {
    setWarningList([]);
    refetchQueries(['teamGoals', 'approval', 'permissions', objectiveId]);
    refetchQueries(['teamGoals', 'approval', 'approvalWarnings', objectiveId]);
  };

  const requestApproval = async () => {
    setIsRequestButtonLoading(true);
    const { data } = await requestTeamGoalApproval(params?.teamId, objectiveId);
    if (data) {
      refetchQueries(['teamGoals', 'approval', 'permissions', objectiveId]);
      addToast({
        title: 'Request for Approval Sent',
        msg: 'Your request for approval has been sent to the reviewer',
        type: 'success'
      });
    }
    setIsRequestButtonLoading(false);
  };

  const handleClickRequest = async () => {
    await requestApproval();
    refetchSubGoals();
    refetchQueries(['teamGoals', 'approval', 'approvalData']);
  };

  const refetchSubGoals = () => {
    refetchObjective(objectiveId);
    approvalSubGoals?.map((subGoal) => {
      refetchQueries(['teamGoals', 'approval', 'subGoal', subGoal?.id]);
    });
  };

  const fetchObjectivePermissions = () => {
    const query = {
      pageType: 'requester'
    };
    return getObjectivePermissions(objectiveId, query);
  };

  const fetchApprovalTeam = () => {
    return getTeamGoalApproval(params?.teamId, objectiveId);
  };

  useQuery(
    ['teamGoals', 'approval', 'approvalData', objectiveId],
    fetchApprovalTeam,
    {
      onSuccess: (data) => {
        if (data?.data) {
          setApprovalDetail(data?.data);
        }
      }
    }
  );

  const fetchChildrenProgress = () => {
    const query = {
      metadata: 'progress',
      group: 'status'
    };
    return getObjectiveChildren(objectiveId, query);
  };

  const { isFetching } = useQuery(
    ['teamGoals', 'approval', 'permissions', objectiveId],
    fetchObjectivePermissions,
    {
      onSuccess: (data) => {
        if (data?.data) {
          setPermissions(data?.data?.permissions);
        }
      }
    }
  );

  useQuery(
    ['teamGoals', 'approval', 'progress', objectiveId],
    fetchChildrenProgress,
    {
      onSuccess: (data) => {
        if (data?.metadata) {
          let list = [];
          Object.keys(data?.metadata?.metadata).forEach((key) => {
            list.push(data?.metadata?.metadata[key]);
          });
          setListStatus(list);
        }
      }
    }
  );

  return (
    <>
      <NewOverallProgress
        listStatus={listStatus}
        visibility={['topParent', 'totalWeight', 'status']}
        objectiveId={objectiveId}
        objectiveConfigs={objectiveConfigs}
        page="teamGoals"
        customClassContainer="w-full justify-between items-center"
        passPropsToChildren={false}
        isWeightEditable={permissions?.includes('approval_request')}
        adjustWeightModalCustomParams={adjustWeightModalCustomParams}
        adjustWeightModalEventOnUpdate={adjustWeightModalEventOnUpdate}
      >
        {isFetching ? (
          <Shimmer width="150px" height="16px" customClass="my-[8px]" />
        ) : permissions?.includes('approval_request') ? (
          <TooltipContainer
            show={isRequestButtonLoading || warningList?.length > 0}
            text="You must meet the requirements"
          >
            <Button.Secondary
              onClick={handleClickRequest}
              disabled={isRequestButtonLoading || warningList?.length > 0}
            >
              <>
                {warningList?.length > 0 && (
                  <SVGIcon
                    iconName="icon-warning"
                    fillColor="var(--r-600)"
                    size={24}
                    customClass="mr-[4px]"
                  />
                )}
                <p className="typography-button text-n-900">
                  {getObjectiveLocale('Request Approval')}
                </p>
              </>
            </Button.Secondary>
          </TooltipContainer>
        ) : (
          permissions?.includes('approval_withdraw') && (
            <Button.Secondary
              onClick={() => setShowWithdrawConfirmationModal(true)}
            >
              <p className="typography-button text-n-900">
                {getObjectiveLocale('Withdraw Approval')}
              </p>
            </Button.Secondary>
          )
        )}
      </NewOverallProgress>
      {approvalDetail?.id !== null && approvalDetail?.state && (
        <BannerBox
          text={approvalDetail?.state}
          type="info"
          marginClass="my-[16px]"
          paddingClass="px-[16px] py-[8px]"
          customClass="!rounded-[8px]"
          iconSize="24"
        />
      )}
      <ApprovalWarnings
        objectiveId={objectiveId}
        warningList={warningList}
        setWarningList={setWarningList}
      />
      {showWithdrawConfirmationModal && (
        <WithdrawConfirmationModal
          objectiveId={objectiveId}
          setShowModal={setShowWithdrawConfirmationModal}
          refetchSubGoals={refetchSubGoals}
        />
      )}
    </>
  );
};

export default SubGoalStatistics;

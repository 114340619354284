import React, { useEffect } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import ListEmptyState from 'components/shared/ListEmptyState';

function SelectableCard({
  data,
  isSelected = false,
  selectData = () => {},
  comment,
  showAddSkillBtn = false,
  customClass
}) {
  useEffect(() => {
    if (comment && data) {
      data.description = comment;
    }
  }, []);

  return (
    <div
      className={`py-[12px] ${
        isSelected ? 'bg-n-100 rounded-[4px] py-[16px] px-[16px]' : 'border-b'
      } ${customClass ? customClass : ''}`}
      onClick={() =>
        selectData({
          id: data.id,
          title: data.title,
          description: data.description
        })
      }
    >
      {!showAddSkillBtn && (
        <>
          <p className="typography-h400 text-n-900">{data?.title}</p>
          <p className="typography-paragraph text-n-800">{data?.description}</p>
        </>
      )}
      {showAddSkillBtn && (
        <ListEmptyState
          emptyIcon="icon-no-skill"
          size="small"
          containerClassname="w-full h-[256px]"
          title={getObjectiveLocale('No Skill Found')}
          subtitle={getObjectiveLocale(
            'You can add this skill to dictionary by clicking action button bellow'
          )}
          iconBtn="icon-add"
          btnText="Add this skill"
          onClick={() =>
            selectData({
              id: data.id,
              title: data.title,
              description: data.description
            })
          }
        />
      )}
    </div>
  );
}

export default SelectableCard;

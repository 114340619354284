import React from 'react';

import ClusterConfigurationBox from './ClusterConfigurationBox';

function StepConfigureClulsters({
  clustersData,
  setSelectedClusters,
  selectedClusters,
  loading,
  isReadOnly
}) {
  return (
    <>
      <ClusterConfigurationBox
        data={clustersData}
        selectedValues={selectedClusters}
        setSelectedValues={setSelectedClusters}
        boxWidth="1000px"
        tableMinWidth="880px"
        loading={loading}
        title={'Objective’s Attribute Visibility'}
        description={'Manage the visibility of all objective’s attributes.'}
        isReadOnly={isReadOnly}
      />
    </>
  );
}

export default StepConfigureClulsters;

import React from 'react';

import dayjs from 'dayjs';

import { getDateLocale } from 'utils/HelperUtils';

import SVGIcon from 'components//shared/SVGIcon';
import AntdCalendar from 'components/shared/Calendar/AntdCalendar';

export const paramsHelperHandleChartView = (
  boolDay,
  boolWeek,
  boolMonth,
  strSelected
) => ({
  growth: {
    day: boolDay,
    week: boolWeek,
    month: boolMonth,
    selected: strSelected
  }
});

export const getParamsHelper = (type) => ({
  periodBegin: dayjs().subtract(1, `${type}s`).startOf(type).toISOString(true),
  periodEnd: dayjs().endOf(type).toISOString(true),
  time_unit: type
});

export const defaultRangesHelper = () => ({
  'This Day': {
    startDate: dayjs().startOf('day').toISOString(),
    endDate: dayjs().endOf('day').toISOString()
  },
  'This Week': {
    startDate: dayjs().startOf('week').toISOString(),
    endDate: dayjs().endOf('week').toISOString()
  },
  'This Month': {
    startDate: dayjs().startOf('month').toISOString(),
    endDate: dayjs().endOf('month').toISOString()
  }
});

export const selectedDateHelpers = () => ({
  begin: dayjs().startOf('week').toISOString(true),
  end: dayjs().endOf('week').toISOString(true)
});

export const btnApplyCSS = {
  right: '570px',
  top: '172px',
  color: 'white',
  borderRadius: '4px',
  fontSize: '1.2em',
  position: 'absolute',
  zIndex: '9',
  width: '140px',
  height: '35px',
  padding: '0',
  borderColor: '#5cb85c',
  background: '#5cb85c'
};

export const btnCancelCSS = {
  right: '570px',
  top: '214px',
  color: 'white',
  borderRadius: '4px',
  backgroundColor: 'var(--base-400)',
  border: '1px solid var(--base-400)',
  fontSize: '1.2em',
  position: 'absolute',
  zIndex: '9',
  width: '140px',
  height: '35px',
  padding: '0'
};

export const _dailyHandleChartView = (
  periodBegin,
  periodEnd,
  isNumber,
  arrayOfFilter,
  getRetentionData,
  setIntervalButton,
  paramsHelperHandleChartView
) => {
  getRetentionData(periodBegin, periodEnd, 'day', isNumber, arrayOfFilter);
  setIntervalButton(paramsHelperHandleChartView(true, false, false, 'day'));
};
export const _weeklyHandleChartView = (
  periodBegin,
  periodEnd,
  isNumber,
  arrayOfFilter,
  getRetentionData,
  setIntervalButton,
  paramsHelperHandleChartView
) => {
  getRetentionData(periodBegin, periodEnd, 'week', isNumber, arrayOfFilter);
  setIntervalButton(paramsHelperHandleChartView(false, true, false, 'week'));
};

export const _monthlyHandleChartView = (
  periodBegin,
  periodEnd,
  isNumber,
  arrayOfFilter,
  getRetentionData,
  setIntervalButton,
  paramsHelperHandleChartView
) => {
  getRetentionData(periodBegin, periodEnd, 'month', isNumber, arrayOfFilter);
  setIntervalButton(paramsHelperHandleChartView(false, false, true, 'month'));
};

export const InsightCalendar = ({
  endDate,
  startDate,
  selectedDate,
  handleSelectDate = () => {},
  customButtonClass = '',
  listOptionsTime
}) => {
  return (
    <div className="flex min-h-min justify-end">
      <div className="relative">
        <AntdCalendar>
          <AntdCalendar.Trigger>
            <div
              className={`border-[1px] border-solid border-n-500 rounded
                py-4 px-8 flex items-center flex justify-between
                ${customButtonClass}
              `}
            >
              <span className="typography-paragraph mr-16 cursor-pointer">
                {getDateLocale(startDate)}
                &nbsp;&nbsp;&nbsp;~&nbsp;&nbsp;&nbsp;
                {getDateLocale(endDate)}
              </span>
              <SVGIcon
                size={16}
                iconName="icon-calendar_today"
                fillColor="var(--n-700)"
              />
            </div>
          </AntdCalendar.Trigger>
          <AntdCalendar.Content
            dateValue={selectedDate}
            setDateValueHandler={handleSelectDate}
            listOptionsTime={listOptionsTime}
          />
        </AntdCalendar>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { getListLeagues, getMissionList } from 'client/adminClient.js';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Checkbox from 'components/shared/Checkbox';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import SearchBar from 'components/shared/SearchBar';
import Modal from 'components/shared/modal/Modal';

function Events({ league, setLeague, modal, setModal, lastSelectedGroup }) {
  const [activeLeaguesList, setActiveLeaguesList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [currentEventsList, setCurrentEventsList] = useState([]);
  const [selectedEventsList, setSelectedEventsList] = useState([]); // help sort league.missionIds with help of index
  const [isLoading, setIsLoading] = useState(false);
  const [isAllEventsChecked, setIsAllEventsChecked] = useState(false);
  const [search, setSearch] = useState('');

  const event = {
    index: 0,
    id: '',
    name: '',
    isSelected: false
  };

  const getActiveLeaguesList = async () => {
    const { data } = await getListLeagues({ state: 'active' });
    if (data) {
      setActiveLeaguesList(data);
    }
  };

  const getEventsList = async () => {
    const checkIsSelected = (eventId) => {
      for (let i = 0; i < league?.missionIds.length; i++) {
        if (eventId === league?.missionIds[i]) {
          return true;
        }
      }
      return false;
    };

    setIsLoading(true);
    const { data } = await getMissionList({ event: true });
    if (data) {
      const tempEventsList = [];
      const tempSelectedEvents = [];

      data.map((value, index) => {
        const tempEvent = cloneDeep(event);
        tempEvent.index = index;
        tempEvent.id = value.id;
        tempEvent.name = value.name;
        tempEvent.isSelected = checkIsSelected(value.id);
        tempEventsList.push(tempEvent);
        if (tempEvent.isSelected) {
          tempSelectedEvents.push(tempEvent);
        }
      });

      setEventsList(tempEventsList);
      setSelectedEventsList(tempSelectedEvents);
    }
    setIsLoading(false);
  };

  const updateCurrentEventsList = () => {
    if (search === '') {
      setCurrentEventsList(eventsList);
    } else {
      const tempEvents = cloneDeep(eventsList).filter((value) =>
        value.name.includes(search)
      );
      setCurrentEventsList(tempEvents);
    }
  };

  const selectDeselectEvent = (index, isSelect) => {
    const tempLeague = cloneDeep(league);
    const tempEventsList = cloneDeep(eventsList);
    const tempSelectedEventsList = cloneDeep(selectedEventsList);

    tempEventsList[index].isSelected = !tempEventsList[index].isSelected;

    if (isSelect) {
      if (
        tempSelectedEventsList.length === 0 ||
        index > tempSelectedEventsList[tempSelectedEventsList.length - 1].index
      ) {
        tempLeague.missionIds.push(tempEventsList[index].id);
        tempSelectedEventsList.push(tempEventsList[index]);
      } else {
        for (let i = 0; i < tempSelectedEventsList.length; i++) {
          if (tempSelectedEventsList[i].index > index) {
            tempLeague.missionIds.splice(i, 0, tempEventsList[index].id);
            tempSelectedEventsList.splice(i, 0, tempEventsList[index]);
            break;
          }
        }
      }
    } else {
      for (let i = 0; i < tempSelectedEventsList.length; i++) {
        if (tempSelectedEventsList[i].index === index) {
          tempLeague.missionIds.splice(i, 1);
          tempSelectedEventsList.splice(i, 1);
          break;
        }
      }
    }

    setLeague(tempLeague);
    setEventsList(tempEventsList);
    setSelectedEventsList(tempSelectedEventsList);

    if (
      tempSelectedEventsList.length === 0 &&
      modal.isShowing &&
      modal.type === 'selectedEvents'
    ) {
      setModal({ isShowing: false, type: 'selectedEvents' });
    }
  };

  const selectDeselectAllEvents = (isSelectAll) => {
    const tempLeague = cloneDeep(league);
    const tempEventsList = cloneDeep(eventsList);
    const tempCurrentEvents = cloneDeep(currentEventsList);

    tempLeague.missionIds = [];

    for (let i = 0; i < tempCurrentEvents.length; i++) {
      tempEventsList[tempCurrentEvents[i].index].isSelected = isSelectAll;
      tempCurrentEvents[i].isSelected = isSelectAll;
      if (
        isSelectAll &&
        tempLeague?.missionIds[i] !== tempCurrentEvents[i].id
      ) {
        tempLeague?.missionIds.push(tempCurrentEvents[i].id);
      }
    }

    setLeague(tempLeague);
    setEventsList(tempEventsList);
    setSelectedEventsList(tempEventsList.filter((value) => value.isSelected));
  };

  const checkAllEventsCheckbox = () => {
    let isChecked = false;
    for (let i = 0; i < currentEventsList.length; i++) {
      isChecked = currentEventsList[i].isSelected;
      if (!isChecked) {
        break;
      }
    }
    return isChecked;
  };

  const handleChangeEvent = (isCheckbox, index) => {
    selectDeselectEvent(
      index,
      isCheckbox ? !eventsList[index].isSelected : false
    );
  };

  const handleChangeAllEvents = (isCheckbox) => {
    selectDeselectAllEvents(isCheckbox ? !isAllEventsChecked : false);
  };

  const handleClickShowSelectedEventsModal = (isShowing) => {
    setModal({ isShowing: isShowing, type: 'selectedEvents' });
  };

  const handleClickCloseDuplicateLeagueModal = () => {
    setModal({ isShowing: false, type: 'duplicateLeague' });
    setLeague({ ...league, sourceInfo: lastSelectedGroup, isEvent: false });
  };

  useEffect(() => {
    getActiveLeaguesList();
    getEventsList();
  }, []);

  useEffect(() => {
    updateCurrentEventsList();
  }, [eventsList]);

  useEffect(() => {
    const tempEventLeague = cloneDeep(activeLeaguesList).filter(
      (value) => value.isEvent
    );
    if (
      tempEventLeague.length > 0 &&
      tempEventLeague.filter((value) => value.id === league.id).length === 0
    ) {
      setModal({ isShowing: true, type: 'duplicateLeague' });
    }
  }, [activeLeaguesList]);

  useEffect(() => {
    updateCurrentEventsList();
  }, [search]);

  useEffect(() => {
    setIsAllEventsChecked(() => checkAllEventsCheckbox());
  }, [currentEventsList]);

  return (
    <div>
      {modal.isShowing && modal.type === 'selectedEvents' && (
        <Modal
          title="Selected Events"
          className="w-[480px] height-fit-content"
          maxHeight={480}
          contentWrapperClass="overflow-scroll"
          withFooter={false}
          eventOnClickClose={() => handleClickShowSelectedEventsModal(false)}
        >
          {selectedEventsList.map((value, index) => {
            return (
              <div
                className={`flex justify-between items-center pt-[16px] pb-[16px] ${
                  index !== 0 ? 'border-top-400' : ''
                }`}
                key={index}
              >
                <p>{value.name}</p>
                <Button.Tertiary
                  id={`remove-event-${value.index}`}
                  onClick={() => handleChangeEvent(false, value.index)}
                >
                  <p className="typography-tertiary">Remove</p>
                </Button.Tertiary>
              </div>
            );
          })}
        </Modal>
      )}
      {modal.isShowing && modal.type === 'duplicateLeague' && (
        <Modal
          title="Duplicated League"
          headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
          className="w-[400px]"
          withPrimaryBtn={{
            title: 'Ok',
            onClick: () => handleClickCloseDuplicateLeagueModal(),
            dataCy: 'modal-ok-button'
          }}
          eventOnClickClose={() => handleClickCloseDuplicateLeagueModal()}
        >
          <p>
            There is another active league with the same type. You need to
            change type or archive that league first to continue this process.
          </p>
        </Modal>
      )}
      {isLoading ? (
        <LoadingComponent />
      ) : eventsList.length === 0 ? (
        <div className="border-n400 rounded-[4px] bg-n-200 mt-[16px] py-[24px] flex flex-col items-center">
          <p className="typography-h500 mb-[8px]">
            There is no event to show in the leaderboard
          </p>
          <p className="typography-secondary">
            You need to create a mission and set as event in mission admin page
          </p>
        </div>
      ) : (
        <div>
          <p className="typography-secondary mt-[24px] mb-[8px]">
            Select which event that want to show in the leaderboard
          </p>
          <SearchBar
            fullWidth={true}
            placeholder="Search event"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="bg-n-200 my-[16px] py-[12px] flex justify-center items-center">
            <p className="mr-[16px]">
              {selectedEventsList.length} event
              {selectedEventsList.length === 0 || selectedEventsList.length > 1
                ? 's are'
                : ' is'}{' '}
              selected
            </p>
            <Button.Tertiary
              id="see-selected-events"
              disabled={selectedEventsList.length === 0}
              onClick={() => handleClickShowSelectedEventsModal(true)}
            >
              See selected events
            </Button.Tertiary>
            <Button.Tertiary
              id="clear-selections"
              disabled={selectedEventsList.length === 0}
              onClick={() => handleChangeAllEvents(false)}
            >
              Clear selections
            </Button.Tertiary>
          </div>
          {currentEventsList.length === 0 ? (
            <ListEmptyState
              fullHeight
              emptyIcon="icon-no-result-found"
              title={getObjectiveLocale('No Result Found')}
              subtitle={
                search
                  ? getObjectiveLocale(
                      'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
                    )
                  : ''
              }
            />
          ) : (
            <div className="border-n400 rounded-[4px] bg-n-000">
              <div className="border-bottom-n-400">
                <Checkbox
                  id="select-all-events-checkbox"
                  checked={isAllEventsChecked}
                  onChange={() => handleChangeAllEvents(true)}
                  customContainerClass="ml-[16px] my-[8px]"
                >
                  <p className="typography-h300 text-n-800 ml-[16px]">
                    EVENT NAME
                  </p>
                </Checkbox>
              </div>
              <div className="h-auto max-h-[400px] px-[16px] py-[16px] overflow-scroll">
                {currentEventsList.length > 0 &&
                  currentEventsList.map((value, index) => {
                    return (
                      <Checkbox
                        key={index}
                        id={`event-checkbox-${value.index}`}
                        dataCy={`event-checkbox-${value.index}`}
                        checked={eventsList[value.index].isSelected}
                        onChange={() => handleChangeEvent(true, value.index)}
                      >
                        <p className="text-n-800 ml-[16px]">{value.name}</p>
                      </Checkbox>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Events;

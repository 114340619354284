import client from '../ApiClient';

function getCycles(query) {
  return client(`v1/admin/formal_reviews/cycles`, { params: query });
}

function startPhase(cycleId, body) {
  return client(`v1/admin/formal_reviews/cycles/${cycleId}/start`, {
    method: 'POST',
    body: body
  });
}

function stopPhase(cycleId, body) {
  return client(`v1/admin/formal_reviews/cycles/${cycleId}/stop`, {
    method: 'POST',
    body: body
  });
}

function endCycle(cycleId) {
  return client(`v1/admin/formal_reviews/cycles/${cycleId}/end`, {
    method: 'PUT',
    body: {}
  });
}

function deleteCycle(cycleId) {
  return client(`v1/admin/formal_reviews/cycles/${cycleId}`, {
    method: 'DELETE',
    body: {}
  });
}

function updateCycle(cycleId, body) {
  return client(`v1/admin/formal_reviews/cycles/${cycleId}`, {
    method: 'PATCH',
    body: body
  });
}

function createCycle(body) {
  return client(`v1/admin/formal_reviews/cycles`, {
    method: 'POST',
    body: body
  });
}

function getCycle(cycleId) {
  return client(`v1/admin/formal_reviews/cycles/${cycleId}`, { params: {} });
}

function getCycleProgress(cycleId, params) {
  return client(`v1/formal_reviews/cycles/${cycleId}/progresses/overall`, {
    params
  });
}

function addNewReviewee(cycleId, body) {
  return client(`v1/admin/formal_reviews/cycles/${cycleId}/reviewees`, {
    method: 'POST',
    body
  });
}

function editReviewDeadline(cycleId, placementId, body) {
  return client(
    `v1/admin/formal_reviews/cycles/${cycleId}/reviewees/${placementId}/update_ends_at`,
    {
      method: 'PATCH',
      body
    }
  );
}

export {
  getCycles,
  startPhase,
  stopPhase,
  endCycle,
  createCycle,
  updateCycle,
  getCycle,
  deleteCycle,
  getCycleProgress,
  addNewReviewee,
  editReviewDeadline
};

import React from "react";
import { capitalize } from "utils/HelperUtils";
import { updateStateObjective } from "client/ObjectivesClient";
import { useReload } from "context/ReloadContext";
import Modal from "components/shared/modal/Modal";

const ModalIncompleteGoal = ({ objective, onIncomplete, onClose }) => {
  const { reload, reloadOverallProgress, reloadOverallStatus, reloadSidebar } = useReload();

  const getObjectiveType = () => {
    if (objective?.type === "goal") {
      if (objective?.isProject) {
        return "project";
      } else {
        return "goal";
      }
    } else if (objective?.type === "task") {
      return "task";
    }
  }

  const incompleteGoal = async() => {
    const { data } = await updateStateObjective(objective?.id, { state: "running", type: 'redo' });
    reload ({
        reloadObjectiveId: { id: objective?.id },
        reloadType: "complete",
        reloadSidebar: !reloadSidebar,
        reloadOverallProgress: !reloadOverallProgress,
        reloadOverallStatus: !reloadOverallStatus
    });
    onIncomplete && onIncomplete(data);
    onClose();
  }

  return (
    <Modal
      title={`Incomplete ${capitalize(getObjectiveType())}`}
      description={`Are you sure you want to incomplete this ${getObjectiveType()}?`}
      withPrimaryBtn={{ title: "Incomplete", dataCy: "button-incomplete", onClick: () => incompleteGoal() }}
      withSecondaryBtn={{ title: "Cancel", dataCy: "button-cancel-incomplete", onClick: () => onClose() }}
      eventOnClickClose={onClose}
      withCloseIcon
    />
  );
}

export default ModalIncompleteGoal;
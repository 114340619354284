"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const HelperUtils_1 = require("utils/HelperUtils");
function Switcher({ options, onChange, value, customClass = '' }) {
    value = value !== null && value !== void 0 ? value : options[0].id;
    const [activeList, setActiveList] = (0, react_1.useState)(value);
    const setContentSwitcher = (id) => {
        setActiveList(id);
        onChange(id);
    };
    (0, react_1.useEffect)(() => {
        setActiveList(value);
    }, [value]);
    return (react_1.default.createElement("div", { className: `content-switcher mt-[24px] ${customClass}` }, options.map((list, index) => (react_1.default.createElement("span", { "data-cy": `${list.name.toLowerCase().replace(' ', '-')}-switcher`, className: `filter-menu-btn ${index == 0
            ? 'left-toggle'
            : index == options.length - 1 && 'right-toggle'} ${activeList == list.id ? 'active' : 'bg-n-000'}`, key: index, onClick: () => {
            setContentSwitcher(list.id);
        } },
        ' ',
        (0, HelperUtils_1.capitalize)(list.name))))));
}
exports.default = Switcher;

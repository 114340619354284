import React, { useState } from 'react';

import ctl from '@netlify/classnames-template-literals';

import { getObjectiveLocale } from 'utils/HelperUtils';

import RadioButton from 'components/shared/RadioButton';
import TextArea from 'components/shared/TextArea';

const PollFreeText = ({ opt, onChange, onClick, disabled, currentAnswer }) => {
  const [text, setText] = useState(currentAnswer?.answer);
  const isSelected = opt?.id == currentAnswer?.id;
  const getConditionalClasses = () => {
    if (isSelected && !disabled) {
      return currentAnswer?.answer
        ? 'bg-base-30024 border-base-600'
        : 'bg-n-000 border-red';
    } else {
      return 'bg-n-000 border-n-400';
    }
  };

  const buttonCN = ctl(`
    flex items-start
    py-[16px] px-[16px] border-solid border-[1px]
    rounded-[4px] typography-h600 text-n-800 mb-[16px]
    ${getConditionalClasses()}
  `);

  const _onClick = () => {
    onClick({ id: opt.id, answer: text });
  };

  const _onChange = (e) => {
    setText(e.target.value);
    onChange({ id: opt.id, answer: e.target.value });
  };

  return (
    <div className={buttonCN} onClick={_onClick}>
      <RadioButton checked={isSelected} addClass="mt-[6px] mr-[16px]" />
      <TextArea
        className="border-0 focus:outline-none bg-transparent w-full"
        typographyClass="typography-h-600"
        defaultValue={text || ''}
        onHandleChange={(e) => _onChange(e)}
        textPlaceholder={getObjectiveLocale('Other (Type your answer here)')}
        paddingClass={'px-[0px] py-[4px]'}
        dataCy="input-free-text-option"
      />
    </div>
  );
};

export default PollFreeText;

import React, { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

import { getTaggedUsers } from 'client/CultureClient';

import Avatar from 'components/shared/Avatar';
import Loading from 'components/shared/LoadingComponent';
import Modal from 'components/shared/modal/Modal';

const ModalTaggedUsers = ({ onClickClose, postId }) => {
  const [listTaggedUsers, setListTaggedUsers] = useState([]);
  const [olderThan, setOlderThan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getTaggedUsersData = async () => {
    setIsLoading(true);
    const _params = { lastId: olderThan };
    const { data, pagination } = await getTaggedUsers(postId, _params);

    if (data) {
      setListTaggedUsers([...listTaggedUsers, ...data]);
      setOlderThan(pagination.next.olderThan);
    }
    setIsLoading(false);
  };

  const debounceFn = useCallback(debounce(getTaggedUsersData, 500), [
    olderThan
  ]);

  const loadMore = (isScrollBottom) => {
    if (isScrollBottom && olderThan !== null) {
      debounceFn();

      return debounceFn.cancel;
    }
  };

  useEffect(() => {
    getTaggedUsersData();
  }, []);

  return (
    <Modal
      title="Tagged Users"
      maxHeight={500}
      className="max-w-[450px]"
      contentWrapperClass={`overflow-auto ${
        listTaggedUsers.length === 6 ? 'mb-[24px]' : 'mb-[4px]'
      }`}
      isScrollBottom={loadMore}
      useBorder
      withFooter={false}
      eventOnClickClose={onClickClose}
      withContentMarginBottom={false}
    >
      {listTaggedUsers.map((user, index) => (
        <div
          className="flex items-center mt-[20px]"
          data-cy={`tagged-users-${user.id}`}
          key={index}
        >
          <Avatar
            name={user.fullName}
            size={40}
            src={user?.profilePicture?.secureUrl || ''}
          />
          <span className="ml-[12px] truncate-width typography-h400 text-n-900">
            {user.fullName}
          </span>
        </div>
      ))}
      {isLoading && <Loading />}
    </Modal>
  );
};

export default ModalTaggedUsers;

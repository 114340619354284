import React, { useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import {
  deleteLabelCMSCulture,
  getGroupCMSCulture,
  getLabelCMSCulture,
  postLabelCMSCulture,
  updateLabelCMSCulture
} from 'client/adminClient';
import useClickOutside from 'hooks/useClickOutside';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

import InlineDialogWrapper from '../labels/InlineDialogWrapper';
import ModalAddNewLabel from './ModalAddNewLabel';
import ModalDeleteLabel from './ModalDeleteLabel';
import ModalSelectedGroup from './ModalSelectedGroups';
import RowOfLabelData from './RowOfLabelData';
import SelectedFilter from './SelectedFilter';

function OrgDetailsLabels({ route: { title: titlePage, isReadOnly } }) {
  const [listOfLabels, setListOfLabels] = useState([]);
  const [totalEntries, setTotalEntries] = useState(null);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [isModalAddOpen, setModalAddOpen] = useState(false);
  const [isModalShowAllOpen, setModalShowAllOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [listOfStatus, setListOfStatus] = useState([
    {
      id: 1,
      name: 'Active'
    },
    {
      id: 2,
      name: 'Archived'
    }
  ]);
  const [listOfGroups, setListOfGroups] = useState([]);
  const [totalGroup, setTotalGroup] = useState(0);
  const [status, setStatus] = useState([
    {
      id: 1,
      name: 'Active'
    }
  ]);
  const [search, setSearch] = useState(null);
  const [searchGroup, setSearchGroup] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [showInlineDialogGroup, setShowInlineDialogGroup] = useState(false);
  const [showInlineDialogStatus, setShowInlineDialogStatus] = useState(false);
  const [showInlineFirst, setShowInlineFirst] = useState(false);
  const [temporaryEditData, setTemporaryEditData] = useState({});
  const [listAddedGroups, setListAddedGroups] = useState([]);
  const [newerThanId, setNewerThanId] = useState(null);
  const [olderThanId, setOlderThanId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevDisable, setPrevDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');
  const [isSuccess, setisSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const refFilter = useRef();

  useClickOutside(refFilter, () => {
    setShowInlineFirst(false);
  });

  const handleChangePage = (type) => {
    let params = getQuery();
    if (type == 'next') {
      params = { ...params, olderThan: olderThanId };
    } else if (type == 'prev') {
      params = { ...params, newerThan: newerThanId };
    }
    getData(params, type);
  };

  const showFilter = (setState, setShowDialog) => {
    setState([
      {
        name: 'Any'
      }
    ]);
    setShowDialog(true);
    setShowInlineFirst(false);
  };

  const showDeleteModal = (e, data) => {
    e.stopPropagation();
    if (!data.deleted) {
      setModalDeleteOpen(true);
    }
    setTemporaryEditData(data);
  };

  const onConfirmRestore = async (e, dataRestore) => {
    e.stopPropagation();
    let body = {
      label: {
        ...dataRestore,
        deleted: !dataRestore.deleted
      }
    };
    const { data } = await updateLabelCMSCulture(dataRestore.id, body);
    if (data) {
      setisSuccess(true);
      setNotifMessage('Successfully Restored! ');
      setTimeout(() => {
        setisSuccess(false);
      }, 2000);
      getData();
    }
  };

  const onConfirmDelete = async () => {
    const { data } = await deleteLabelCMSCulture(temporaryEditData.id);
    if (data) {
      getData();
      setisSuccess(true);
      setNotifMessage('Successfully Archived! ');
      setModalDeleteOpen(false);
      setTimeout(() => {
        setisSuccess(false);
      }, 2000);
    }
  };

  const showAddModal = (e, title, data = { isAllGroups: true }) => {
    e.stopPropagation();
    setTitle(title);
    setModalAddOpen(true);
    setTemporaryEditData(data);
    setListAddedGroups(data?.groups || listOfGroups);
  };

  const handleChangeGroup = (sel) => {
    let newSelectedValue = [...listAddedGroups];
    if (!newSelectedValue.some((value) => value === sel)) {
      newSelectedValue.push(sel);
    } else {
      newSelectedValue = newSelectedValue.filter(
        (newSelected) => newSelected !== sel
      );
    }
    setListAddedGroups(newSelectedValue);
  };

  const handleChangeSearch = () => {
    const search = document.getElementById('search-label').value;
    setSearch(search);
  };

  const getStatusParam = () => {
    let selectedStatus = status.map(({ name }) => name);
    const defaultStatus = ['Active', 'Archived'];
    if (selectedStatus[0] === 'Any') {
      return defaultStatus;
    }

    return selectedStatus.length > 0 ? selectedStatus : defaultStatus;
  };

  const getQuery = () => {
    let selectedGroupsIds = selectedGroups.map(({ id }) => id);

    let params = {
      limit: 10,
      admin: 1,
      q: search,
      group_ids: selectedGroupsIds,
      announcement_label: true,
      status: getStatusParam()
    };

    if (selectedGroups.length == listOfGroups.length) {
      delete params.group_ids;
    }
    return params;
  };

  const onConfirmAddData = async (title) => {
    setTemporaryEditData({
      ...temporaryEditData,
      groups: listAddedGroups
    });

    let body = {
      label: {
        ...temporaryEditData,
        groups: listAddedGroups,
        isAllGroups: listAddedGroups.length === totalGroup
      }
    };

    delete body.label.deleted;

    let confirmData =
      title === 'Edit'
        ? await updateLabelCMSCulture(temporaryEditData.id, body)
        : await postLabelCMSCulture(body);
    const { data } = confirmData;
    if (data) {
      setModalAddOpen(false);
      getData();
      setisSuccess(true);
      setNotifMessage(
        `Successfully ${title === 'Edit' ? 'Updated' : 'Added'}! `
      );
      setTimeout(() => {
        setisSuccess(false);
      }, 2000);
    }
  };

  const getDataGroup = async () => {
    let params = {
      limit: 10,
      q: searchGroup,
      all: true
    };
    const { data } = await getGroupCMSCulture(params);
    if (data) {
      setListOfGroups(data);
      isEmpty(searchGroup) && setTotalGroup(data.length);
    }
  };

  const getData = async (params, type = null) => {
    let query = params ? params : getQuery();
    const { data, pagination, analytics, error } = await getLabelCMSCulture(
      query
    );
    if (data) {
      setIsLoading(false);
      if (data.length < 10) {
        setNextDisable(true);
      } else {
        setNextDisable(false);
      }
      setListOfLabels(data);
      setOlderThanId(pagination.next.olderThan);
      setNewerThanId(pagination.prev.newerThan);
      setTotalEntries(Math.ceil(analytics.totalEntries / 10));
      if (type == 'next') {
        setCurrentPage(currentPage + 1);
        setPrevDisable(false);
      } else if (type == 'prev') {
        setNextDisable(false);
        setCurrentPage(currentPage - 1);
      }
    } else {
      if (type == 'next') {
        setNextDisable(true);
      } else if (type == 'prev') {
        setPrevDisable(true);
      }
    }
    if (error) {
      if (error.code == 500) {
        setIsLoading(false);
      }
    }
  };

  const headerProps = {
    titlePage: titlePage,
    primaryAction: !isReadOnly && {
      title: 'Add Label',
      icon: 'icon-add',
      onClick: (e) => showAddModal(e, 'Add new')
    }
  };

  useEffect(() => {
    getData();
    getDataGroup();
    setCurrentPage(1);
  }, [search, selectedGroups, status, searchGroup]);

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="admin-culture org-details-labels bg-n-000 min-h-[calc(100vh-64px)]">
        <div className="dashboard_content pt-[24px]">
          <div className="dashboard_content_main">
            <div className="content_main_header">
              <div className="col-xs-5">
                <div className="form-group has-search">
                  <div
                    className="fa fa-search form-control-feedback"
                    onClick={() => handleChangeSearch()}
                  ></div>
                  <input
                    id="search-label"
                    type="text"
                    className="form-control"
                    placeholder="Search label"
                    onKeyDown={(e) => e.keyCode === 13 && handleChangeSearch()}
                  />
                </div>
              </div>
              <div className="col-xs-12">
                <div className="list-filter">
                  <div
                    className="filter-group"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInlineDialogGroup(true);
                    }}
                  >
                    <SelectedFilter
                      listOfData={selectedGroups}
                      title={'Group'}
                      handleChange={setSelectedGroups}
                    />
                    {showInlineDialogGroup && selectedGroups.length != 0 && (
                      <InlineDialogWrapper
                        setSearchGroup={setSearchGroup}
                        listOfGroups={listOfGroups}
                        selectedValue={selectedGroups}
                        handleChange={setSelectedGroups}
                        setShow={setShowInlineDialogGroup}
                        isGroup={true}
                      />
                    )}
                  </div>
                  <div
                    className="filter-status"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInlineDialogStatus(true);
                    }}
                  >
                    <SelectedFilter
                      listOfData={status}
                      title={'Status'}
                      handleChange={setStatus}
                    />
                    {showInlineDialogStatus && status.length != 0 && (
                      <InlineDialogWrapper
                        listOfGroups={listOfStatus}
                        selectedValue={status}
                        handleChange={setStatus}
                        setShow={setShowInlineDialogStatus}
                      />
                    )}
                  </div>
                  {(selectedGroups.length == 0 || status.length == 0) && (
                    <div className="inline-dialog-content">
                      <div
                        ref={refFilter}
                        className="inline-filter flex items-center"
                        onClick={() => setShowInlineFirst(!showInlineFirst)}
                      >
                        <SVGIcon
                          iconName="icon-settings-filter"
                          size="24"
                          fillColor="var(--n-600)"
                          customClass="mr-[8px]"
                        />
                        Add Filter
                        {showInlineFirst && (
                          <div className="inline-first">
                            {selectedGroups.length == 0 && (
                              <button
                                onClick={() =>
                                  showFilter(
                                    setSelectedGroups,
                                    setShowInlineDialogGroup
                                  )
                                }
                              >
                                Group
                              </button>
                            )}
                            {status.length == 0 && (
                              <button
                                onClick={() =>
                                  showFilter(
                                    setStatus,
                                    setShowInlineDialogStatus
                                  )
                                }
                              >
                                Status
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="content-main">
              {listOfLabels?.length > 0 && (
                <table className="table-list-employee">
                  <thead>
                    <tr>
                      <th style={{ width: '240px' }}>Label</th>
                      <th style={{ width: '600px' }}>Groups</th>
                      <th>Status</th>
                      <th className="delete-row"></th>
                    </tr>
                  </thead>
                  <tbody data-cy="table-of-label">
                    {isLoading ? (
                      <tr>
                        <td colSpan="10">
                          <LoadingComponent />
                        </td>
                      </tr>
                    ) : (
                      listOfLabels.map(function (data, idx) {
                        return (
                          <RowOfLabelData
                            key={idx}
                            data={data}
                            idx={idx}
                            showDeleteModal={showDeleteModal}
                            showAddModal={showAddModal}
                            onConfirmRestore={onConfirmRestore}
                            isReadOnly={isReadOnly}
                          />
                        );
                      })
                    )}
                  </tbody>
                </table>
              )}
              {!isLoading && listOfLabels?.length === 0 && (
                <ListEmptyState
                  containerClassname="mt-[130px]"
                  emptyIcon={search ? 'icon-no-result-found' : 'icon-no-label'}
                  title={getObjectiveLocale(
                    search ? 'No result found' : 'There is no label'
                  )}
                  subtitle={getObjectiveLocale(
                    search
                      ? `Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.${
                          !isReadOnly
                            ? '\nBut you can also start to create a new one'
                            : ''
                        }`
                      : !isReadOnly
                      ? 'you can also start to create a new one'
                      : ''
                  )}
                  btnText={!isReadOnly && 'Add Label'}
                  iconBtn="icon-add"
                  onClick={(e) => showAddModal(e, 'Add new')}
                />
              )}
            </div>
            <div className="footer-label">
              {totalEntries > 0 && (
                <>
                  <span>
                    Show page <strong>{currentPage}</strong> of{' '}
                    <strong>{totalEntries}</strong>
                  </span>
                  <button
                    className="btn admin-btn-prev"
                    style={{ opacity: nextDisable && '0.5' }}
                    onClick={() => handleChangePage('next')}
                    disabled={nextDisable}
                  >
                    Next
                    <i className="fa fa-arrow-right" />
                  </button>
                  <button
                    className="btn admin-btn-next"
                    style={{ opacity: prevDisable && '0.5' }}
                    onClick={() => handleChangePage('prev')}
                    disabled={prevDisable}
                  >
                    <i className="fa fa-arrow-left" />
                    Prev
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        {isModalShowAllOpen && (
          <ModalSelectedGroup
            isReadOnly={isReadOnly}
            setModalShowAllOpen={setModalShowAllOpen}
            setModalAddOpen={setModalAddOpen}
            listAddedGroups={listAddedGroups}
            handleChangeGroup={handleChangeGroup}
          />
        )}
        {isModalAddOpen && (
          <ModalAddNewLabel
            isReadOnly={isReadOnly}
            title={title}
            setTemporaryEditData={setTemporaryEditData}
            temporaryEditData={temporaryEditData}
            onConfirmAddData={onConfirmAddData}
            handleChangeGroup={handleChangeGroup}
            listAddedGroups={listAddedGroups}
            setListAddedGroups={setListAddedGroups}
            listOfGroups={listOfGroups}
            setModalAddOpen={setModalAddOpen}
            setSearchGroup={setSearchGroup}
            setModalShowAllOpen={setModalShowAllOpen}
            totalGroup={totalGroup}
          />
        )}

        {isModalDeleteOpen && (
          <ModalDeleteLabel
            setModalDeleteOpen={setModalDeleteOpen}
            onConfirmDelete={onConfirmDelete}
          />
        )}
      </div>
    </>
  );
}

export default OrgDetailsLabels;

import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import dayjs from 'dayjs';
import has from 'lodash/has';

import { useUser } from 'context/UserContext';
import { formatTimezone } from 'utils/DateUtils';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { getMilestoneDefault } from 'utils/ObjectivesHelper';

import DropdownRepetition from 'components/dropdown/DropdownRepetition';
import ModalCustomRepetition from 'components/modal/ModalCustomRepetition';
import SVGIcon from 'components/shared/SVGIcon';
import ContentWrapper from 'components/sidebar/sidebar-create/ContentWrapper';
import DualCalendar from 'src/components/shared/Calendar/DualCalendar';

const DateSection = ({
  singleObjectiveAction,
  objectiveValue,
  setObjectiveValue,
  parent,
  isDeleteRecurrence,
  setDeleteRecurrence,
  isDisabled
}) => {
  let isObjectiveRecurrenceCustom = false;
  if (objectiveValue.recurrence) {
    isObjectiveRecurrenceCustom = objectiveValue.recurrence.every > 1;
  }
  const permissions = singleObjectiveAction.permissions;

  const { params } = useRouteMatch();
  const { config } = useUser();
  const [modalRepetitionCustom, setmodalRepetitionCustom] = useState(false);
  const [tempDueDate, setTempDueDate] = useState(
    (singleObjectiveAction.recurrence &&
      singleObjectiveAction.dueDate &&
      singleObjectiveAction.startDate &&
      dayjs(singleObjectiveAction.recurrence.nextDate)
        .add(
          dayjs(singleObjectiveAction.dueDate).diff(
            dayjs(singleObjectiveAction.startDate)
          )
        )
        .toISOString()) ||
      ''
  );
  const [displayData, setDisplayData] = useState(
    singleObjectiveAction?.recurrence?.type || ''
  );
  const [isRecurrenceCustom, setRecurrenceCustom] = useState(
    isObjectiveRecurrenceCustom
  );

  const type = objectiveValue.type;
  const disableAllDate = parent
    ? [parent.startDate, parent.dueDate]
    : undefined;
  const recurrence = objectiveValue.recurrence;

  const {
    taskPeriodStartDateEdit: taskPermissionStartDate,
    projectPeriodStartDateEdit: projectPermissionStartDate,
    goalPeriodStartDateEdit: goalPermissionStartDate,
    taskPeriodDueDateEdit: taskPermissionDueDate,
    projectPeriodDueDateEdit: projectPermissionDueDate,
    goalPeriodDueDateEdit: goalPermissionDueDate,
    taskPeriodRecurrenceEdit: taskRecurrence,
    projectPeriodRecurrenceEdit: projectRecurrence,
    goalPeriodRecurrenceEdit: goalRecurrence
  } = config.permissions;

  const permissionStartDate =
    params?.type === 'edit'
      ? permissions?.includes('edit_start_date')
      : params?.type?.includes('task')
      ? taskPermissionStartDate
      : objectiveValue?.isProject
      ? projectPermissionStartDate
      : goalPermissionStartDate;
  const permissionDueDate =
    params?.type === 'edit'
      ? permissions?.includes('edit_due_date')
      : params?.type?.includes('task')
      ? taskPermissionDueDate
      : objectiveValue?.isProject
      ? projectPermissionDueDate
      : goalPermissionDueDate;
  const objectiveHasRecurrence =
    params?.type == 'edit' ? has(objectiveValue, 'recurrence') : true;
  const recurrencePermission =
    params?.type === 'edit'
      ? permissions?.includes('update_recurrence')
      : params?.type?.includes('task')
      ? taskRecurrence
      : objectiveValue?.isProject
      ? projectRecurrence
      : goalRecurrence;

  const listRepetition = [
    {
      value: 'Does Not Repeat',
      name: 'never'
    },
    {
      value: 'Daily',
      name: 'day'
    },
    {
      value: 'Weekly',
      name: 'week'
    },
    {
      value: 'Monthly',
      name: 'month'
    },
    {
      value: 'Yearly',
      name: 'year'
    }
  ];

  const handleChangeChildrenDate = (parent) => {
    if (parent.children) {
      parent.children.map((child) => {
        if (
          child.name === '' ||
          dayjs(parent.startDate).isAfter(child.dueDate) ||
          dayjs(child.startDate).isAfter(parent.dueDate)
        ) {
          child.startDate = parent.startDate;
          child.dueDate = parent.dueDate;
        } else if (dayjs(parent.startDate).isAfter(child.startDate)) {
          child.startDate = parent.startDate;
        } else if (dayjs(child.dueDate).isAfter(parent.dueDate)) {
          child.dueDate = parent.dueDate;
        }

        let lessThanWeek =
          dayjs(child?.dueDate)?.diff(dayjs(child?.startDate), 'days') <= 7;
        if (lessThanWeek) {
          child.milestoneType = 'disabled';
          child.milestones = getMilestoneDefault();
        }

        handleChangeChildrenDate(child);
      });
    }
  };

  function handleChangeCalendar(value, calendarPosition) {
    setObjectiveValue((draft) => {
      // if Q1 Q2 Q3 Q4 is pressed, the value = [ {}, {} ]
      if (value.length === 2) {
        draft.startDate = formatTimezone(value[0], 'start');
        draft.dueDate = formatTimezone(value[1], 'end');
        // If the user has clicked the calendar at startDate or dueDate calendar
      } else {
        if (calendarPosition === 'startDate') {
          const date = formatTimezone(value, 'start');
          draft.startDate = date;
        } else if (calendarPosition === 'dueDate') {
          const date = formatTimezone(value, 'end');
          draft.dueDate = date; // endOf("day") = to be more precise
        }
      }

      if (draft.recurrence) {
        let recurrencesDate = dayjs(objectiveValue.startDate).add(
          1,
          draft.recurrence.type
        );
        let nextDate = recurrencesDate.toISOString();
        draft.recurrence.nextDate = nextDate;
      }

      let lessThanWeek =
        dayjs(draft?.dueDate)?.diff(dayjs(draft?.startDate), 'days') <= 7;
      if (lessThanWeek) {
        draft.milestoneType = 'disabled';
        draft.milestones = getMilestoneDefault();
      }

      handleChangeChildrenDate(draft);
    });
  }

  function handleChangeRepetition(event) {
    if (event === getObjectiveLocale('Custom')) {
      setmodalRepetitionCustom(true);
    } else {
      setRecurrenceCustom(false);
      setObjectiveValue((draft) => {
        if (event !== 'Does Not Repeat') {
          setDeleteRecurrence(false);
          if (!draft.recurrence) {
            draft.recurrence = {};
          }
          let recurrencesDate;
          if (event === getObjectiveLocale('Daily')) {
            recurrencesDate = dayjs(objectiveValue.startDate).add(1, 'days');
            setTempDueDate(
              dayjs(objectiveValue.dueDate).add(1, 'days').toISOString()
            );
            draft.recurrence.type = 'day';
          }
          if (event === getObjectiveLocale('Weekly')) {
            recurrencesDate = dayjs(objectiveValue.startDate).add(1, 'weeks');
            setTempDueDate(
              dayjs(objectiveValue.dueDate).add(1, 'weeks').toISOString()
            );
            draft.recurrence.type = 'week';
          }
          if (event === getObjectiveLocale('Monthly')) {
            recurrencesDate = dayjs(objectiveValue.startDate).add(1, 'months');
            setTempDueDate(
              dayjs(objectiveValue.dueDate).add(1, 'months').toISOString()
            );
            draft.recurrence.type = 'month';
          }
          if (event === getObjectiveLocale('Yearly')) {
            recurrencesDate = dayjs(objectiveValue.startDate).add(1, 'years');
            setTempDueDate(
              dayjs(objectiveValue.dueDate).add(1, 'years').toISOString()
            );
            draft.recurrence.type = 'year';
          }
          let nextDate = recurrencesDate.toISOString();
          draft.recurrence.nextDate = nextDate;
          draft.recurrence.every = 1;
          draft.recurrence.state = 'active';
        } else {
          if (event === 'Does Not Repeat') {
            setDeleteRecurrence(true);
          }
        }
      });
    }
  }

  function handleChangeCustomRepetition(
    recurrencesDateLoad,
    tempDueDate,
    repeatData,
    everyData
  ) {
    if (recurrencesDateLoad && tempDueDate && repeatData) {
      setObjectiveValue((draft) => {
        if (!draft.recurrence) {
          draft.recurrence = {};
        }
        setTempDueDate(tempDueDate);
        setDisplayData(repeatData.toLowerCase());
        draft.recurrence.type = repeatData.toLowerCase();
        setRecurrenceCustom(everyData > 1);
        draft.recurrence.nextDate = recurrencesDateLoad.toISOString();
        draft.recurrence.every = everyData;
        draft.recurrence.state = 'active';
      });
    }
    setmodalRepetitionCustom(false);
  }

  return (
    <>
      <ContentWrapper
        customWrapperClass="px-[24px] pb-[8px]"
        dataCy="sidebar-create-period"
      >
        <div className="content_calendar" data-cy="content-calendar">
          <div className="flex items-center">
            <SVGIcon
              customClass="mr-[16px]"
              iconName="icon-today"
              size="24"
              fillColor="var(--n-600)"
            />
            <DualCalendar
              defaultValue={[
                dayjs(objectiveValue?.startDate),
                dayjs(objectiveValue?.dueDate)
              ]}
              handleChangeStartDate={(value) =>
                handleChangeCalendar(value, 'startDate')
              }
              handleChangeDueDate={(value) =>
                handleChangeCalendar(value, 'dueDate')
              }
              permissionStartDate={permissionStartDate}
              permissionDueDate={permissionDueDate}
              disabledDates={disableAllDate}
              isDisabled={isDisabled}
            />
          </div>
          {objectiveHasRecurrence && (
            <div
              className="content_repetition flex items-center ml-[42px]"
              data-cy="sidebar-create-recurrence"
            >
              <p className="typography-paragraph text-n-800">
                {type == 'task'
                  ? getObjectiveLocale('Task Repetition')
                  : getObjectiveLocale(
                      objectiveValue.isProject
                        ? 'Project Repetition'
                        : 'Objective Recurrence'
                    )}
                :&nbsp;
              </p>
              {recurrencePermission ? (
                <DropdownRepetition
                  isTask={type == 'task'}
                  handleChange={handleChangeRepetition}
                  defaultValue={
                    objectiveValue.recurrence
                      ? objectiveValue.recurrence.state === 'deleted'
                        ? 'never'
                        : objectiveValue.recurrence.every == 1
                        ? objectiveValue.recurrence.type
                        : 'custom'
                      : 'never'
                  }
                />
              ) : recurrence ? (
                <p
                  className="ml-[8px] not-visible typography-paragraph cursor-not-allowed"
                  data-cy="recurrence-no-permission"
                >
                  {objectiveValue.recurrence.every == 1
                    ? listRepetition?.find(
                        ({ name }) => name === objectiveValue.recurrence.type
                      ).value
                    : 'Custom'}
                </p>
              ) : (
                <p
                  className="ml-[8px] not-visible typography-paragraph cursor-not-allowed"
                  data-cy="recurrence-not-visible"
                >
                  {getObjectiveLocale('Does Not Repeat')}
                </p>
              )}
            </div>
          )}
          {recurrence &&
            recurrence.state !== 'deleted' &&
            !isDeleteRecurrence && (
              <p className="typography-h100 text-n-800 ml-[42px]">
                {isRecurrenceCustom
                  ? `Every ${recurrence.every} ${displayData}(s). `
                  : ''}{' '}
                {getObjectiveLocale('Next occurrence will start on')}{' '}
                {getDateLocale(recurrence.nextDate)}, end on{' '}
                {getDateLocale(tempDueDate)}
              </p>
            )}
        </div>
      </ContentWrapper>
      {modalRepetitionCustom && (
        <ModalCustomRepetition
          handleChange={handleChangeCustomRepetition}
          closeModal={setmodalRepetitionCustom}
          defaultValue={[
            dayjs(objectiveValue.startDate),
            dayjs(objectiveValue.dueDate)
          ]}
        />
      )}
    </>
  );
};

export default DateSection;

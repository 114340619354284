import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';

import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';

import { getCycle } from 'client/admin/CyclesClient';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalWarningEdit from 'components/admin/Cycles/CyclesDetails/ModalWarningEdit';
import Button from 'components/design-system/Button';
import SidebarRight from 'components/design-system/SidebarRight/SidebarRight';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import SVGIcon from 'components/shared/SVGIcon';

import CyclePhaseCard from './CyclePhaseCard';
import './CycleSidebar.scss';

const CycleSidebar = ({
  sidebar,
  setSidebar,
  cycleOptions,
  isReadOnly = false,
  setCurrentPage
}) => {
  const [page, setPage] = useState('detail');
  const [cycleData, setCycleData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const start = dayjs(sidebar?.reviewProcessStartsAt).format('DD MMM YYYY');
  const end = dayjs(sidebar?.reviewProcessEndsAt).format('DD MMM YYYY');
  const borderBottom = 'border-solid border-b border-0 border-n-400';
  const fadeIn = 'animated fadeIn';

  const fetchCycle = () => {
    return getCycle(sidebar?.id);
  };

  const { isLoading } = useQuery(
    ['cycle', 'sidebar', sidebar?.id],
    fetchCycle,
    {
      onSuccess: (data) => {
        if (data?.data) {
          setCycleData(data?.data);
        }
      }
    }
  );

  useEffect(() => {
    setPage('detail');
  }, [sidebar]);

  return (
    <SidebarRight
      dataCy="cycle-sidebar"
      fixed={false}
      customClass="!fixed right-0"
      fullWidth={false}
      customWidth="w-[400px]"
    >
      <SidebarRight.Header customClass="flex justify-between">
        {page === 'detail' && (
          <>
            <div className={`grid grid-flow-col gap-[8px] items-center`}>
              {sidebar?.state !== 'done' && !isReadOnly && (
                <Button.Secondary
                  onClick={() =>
                    sidebar?.state === 'draft'
                      ? history.push(`/appraisals/cycles/${sidebar?.id}/edit`)
                      : setShowModal(true)
                  }
                  disabled={isReadOnly}
                >
                  <SVGIcon
                    customClass="mr-[4px]"
                    iconName="icon-create"
                    size={20}
                    fillColor="var(--n-600)"
                  />
                  {getObjectiveLocale('Edit')}
                </Button.Secondary>
              )}
              {sidebar?.state === 'draft' && !isReadOnly && (
                <Button.Secondary
                  onClick={() =>
                    history.push(
                      `/appraisals/cycles/${sidebar?.id}/edit?step=set-up-schedule`
                    )
                  }
                  disabled={isReadOnly}
                >
                  {getObjectiveLocale('Set Schedule')}
                </Button.Secondary>
              )}
            </div>
            <div className="grid grid-flow-col gap-[8px] items-center">
              <div className="dialog-portal" id="dialog-portal-sidebar" />
              <Dropdown>
                <Dropdown.Trigger>
                  <SVGIcon
                    iconName="icon-keyboard_control"
                    size={24}
                    fillColor="var(--n-600)"
                  />
                </Dropdown.Trigger>
                <Dropdown.MenuItems
                  options={cycleOptions(cycleData) || []}
                  position="right"
                  customContainerClass="min-w-[230px] max-h-[162px] overflow-auto"
                  dataCy="list-cycle-action"
                />
              </Dropdown>
              <SVGIcon
                iconName="icon-clear"
                size={24}
                fillColor="var(--n-600)"
                onClick={() => setSidebar(null)}
              />
            </div>
          </>
        )}
        {page === 'dependency' && (
          <div className="my-[1px]">
            <Button.Tertiary onClick={() => setPage('detail')}>
              <SVGIcon
                iconName="icon-arrow_back"
                fillColor="var(--base-600)"
                size={20}
                customClass="mr-[8px]"
              />
              {getObjectiveLocale('Back')}
            </Button.Tertiary>
          </div>
        )}
      </SidebarRight.Header>
      <SidebarRight.Body customClass="px-[24px]">
        {page === 'detail' && (
          <>
            <div
              className={`d-inline bg-base-200 text-base-800 px-[8px] py-[2px] rounded-[4px] my-[0px] ${fadeIn}`}
            >
              {!sidebar?.reviewProcessStartsAt ||
              !sidebar?.reviewProcessStartsAt ? (
                <span className="typography-h200">
                  {getObjectiveLocale('No Schedule')}
                </span>
              ) : (
                <>
                  <span className="typography-h200 mr-[4px]">
                    {getObjectiveLocale('No Schedule')}:
                  </span>
                  <span className="typography-h100">
                    {getObjectiveLocale(
                      sidebar?.recurrence ? 'Recurring' : 'One Time'
                    )}
                  </span>
                </>
              )}
            </div>
            <div className={`grid gap-[4px] mt-[8px] mb-[24px] ${fadeIn}`}>
              <p className="typography-h100 text-n-800">#{sidebar?.id}</p>
              <p className="typography-h600">{sidebar?.name}</p>
              {sidebar?.reviewProcessStartsAt &&
                sidebar?.reviewProcessStartsAt && (
                  <>
                    <span className="typography-h400">
                      {getObjectiveLocale('Start from')}:
                    </span>
                    &nbsp;
                    <span>{start + ' - ' + end}</span>
                  </>
                )}
              <p>
                {sidebar?.dependencies?.length > 0 && (
                  <>
                    <span className="typography-h400">
                      {getObjectiveLocale('Dependent on')}:
                    </span>
                    &nbsp;
                    <span className="typography-paragraph">
                      {sidebar?.dependencies?.[0]?.name || ''}
                    </span>
                    &nbsp;
                    {sidebar?.dependencies?.length > 1 && (
                      <>
                        <span className="typography-paragraphy">
                          {getObjectiveLocale('and')}
                        </span>
                        <span
                          className="text-base-600 cursor-pointer"
                          onClick={() => setPage('dependency')}
                          data-cy="toggle-sidebar-dependencies"
                        >
                          {sidebar?.dependencies?.length - 1}{' '}
                          {getObjectiveLocale('Others')}
                        </span>
                      </>
                    )}
                  </>
                )}
              </p>
            </div>
            <p
              className={`typography-h500 ${
                sidebar?.phases.length > 0 ? 'mb-[16px]' : 'mb-[4px]'
              } ${fadeIn}`}
            >
              {getObjectiveLocale('Phase List')}
            </p>
            <div className={`grid gap-[24px] ${fadeIn}`}>
              {isLoading ? (
                <Shimmer width="136px" height="20px" circle />
              ) : sidebar?.phases?.length > 0 && cycleData ? (
                sidebar?.phases?.map((phase, index) => {
                  const keyCyclePhaseInfos = camelCase(phase?.type);
                  return (
                    <CyclePhaseCard
                      key={index}
                      cycleId={sidebar?.id}
                      phase={phase}
                      data={cycleData[keyCyclePhaseInfos]}
                      isReadOnly={isReadOnly}
                      setCurrentPage={setCurrentPage}
                      cycleData={cycleData}
                    />
                  );
                })
              ) : (
                <p
                  className={`typography-paragraph text-n-800 pb-[24px] border-solid ${borderBottom}`}
                >
                  {getObjectiveLocale('No Phase Listed')}
                </p>
              )}
            </div>
            <div className={`mt-[16px] text-center ${fadeIn}`}>
              <Button.Tertiary
                onClick={() =>
                  history.push(`/cycles/detail-cycle/${sidebar?.id}`)
                }
              >
                {getObjectiveLocale('Go to Cycle Detail')}
              </Button.Tertiary>
            </div>
          </>
        )}
        {page === 'dependency' && (
          <>
            <p
              className={`text-n-800 ${fadeIn}`}
              data-cy="sidebar-dependencies-title"
            >
              {getObjectiveLocale('Dependent on')} {sidebar?.name}
            </p>
            {sidebar?.dependencies.map((cycle, index) => (
              <p
                key={index}
                className={`${
                  index === 0 ? 'pt-[8px] pb-[24px]' : 'py-[24px]'
                } typography-h500 ${borderBottom} ${fadeIn}`}
              >
                {cycle?.name}
              </p>
            ))}
          </>
        )}
        {showModal && (
          <ModalWarningEdit
            cycleId={sidebar?.id}
            stateData={sidebar?.state}
            totalReviewer={sidebar?.totalReviewees}
            source="cycles"
            onCloseModal={() => setShowModal(false)}
          />
        )}
      </SidebarRight.Body>
    </SidebarRight>
  );
};

export default CycleSidebar;

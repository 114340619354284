import axios from 'axios';

const S3Client = async (
  preSignUrl,
  file,
  checkProgress,
  handleSetSource,
  type
) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let options = {
    headers: {
      'Content-Type': file.type
    },
    onUploadProgress: progressEvent => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      if (checkProgress) {
        checkProgress(percentCompleted, file.secureUrl);
      }
    },
    cancelToken: source.token
  };

  axios.defaults.withCredentials = false;

  if (handleSetSource) {
    handleSetSource(source, type);
  }

  try {
    const response = await axios.put(preSignUrl, file, options);
    return response;
  } catch (error) {
    return error;
  }
};

export default S3Client;

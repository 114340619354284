import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import {
  getSummaryProgress,
  patchBulkUpdateUser,
  postAssignmentsImportUser
} from 'client/FormalReviewClient';
import { ChangeAssignmentContext } from 'context/ChangeAssignmentContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Modal from 'components/design-system/Modal';
import Footer from 'components/shared/Footer';
import SVGIcon from 'components/shared/SVGIcon';

import InputUserIds from './InputUserIds/InputUserIds';
import UserResults from './UserResults/UserResults';
import { INPUT_PAGE, SUBMIT_PAGE } from './Utils/const';

const ChangeAssignment = () => {
  const { id: cycleId } = useParams();
  const history = useHistory();
  const [userIds, setUserIds] = useState('');
  const [page, setPage] = useState(INPUT_PAGE);
  const [phaseItems, setPhaseItems] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [rescheduleDateMoment, setRescheduleDateMoment] = useState(null);
  const [excludeIds, setExcludeIds] = useState([]);
  const [userCount, setUserCount] = useState({
    usersFoundCount: 0,
    usersNotFoundCount: 0
  });
  const [modalOpen, setModalOpen] = useState(false);

  const contextValue = {
    excludeIds,
    setExcludeIds
  };

  const headerProps = {
    titlePage: 'Change Assignment Status',
    backToUrl: `/cycles/detail-cycle/${cycleId}?detailBy=progress`,
    isHeaderComposer: true
  };

  const submitChangeHandler = async () => {
    const payload = {
      phaseId: selectedPhase.id,
      excludeAssignmentByTargetPlacements: excludeIds,
      state: selectedStatus?.id,
      ...(rescheduleDateMoment && {
        endsAt: dayjs(rescheduleDateMoment).toISOString()
      })
    };
    const { isSuccess } = await patchBulkUpdateUser(cycleId, payload);
    if (isSuccess) {
      history.push(`/cycles/detail-cycle/${cycleId}?detailBy=progress`);
    }
  };

  const handleClick = async () => {
    if (page == INPUT_PAGE) {
      const parseIds = parseArrayIds(userIds);
      const { data, isSuccess } = await postAssignmentsImportUser(cycleId, {
        externalIds: parseIds
      });

      if (isSuccess) {
        setUserCount({
          usersFoundCount: data.usersFound,
          usersNotFoundCount: data.usersNotFound
        });
        setPage(SUBMIT_PAGE);
      }
    } else {
      setModalOpen(true);
    }
  };

  const handleRoute = () => {
    if (page == INPUT_PAGE) {
      history.push(`/cycles/detail-cycle/${cycleId}?detailBy=progress`);
    } else {
      setPage(INPUT_PAGE);
    }
  };

  const buttonPrimaryDisabled =
    page == INPUT_PAGE
      ? userIds == ''
      : userCount?.usersFoundCount > 0
      ? false
      : true;

  const parseArrayIds = (ids) => {
    return ids?.split(/[\r\n\s?:;,]+/) || [];
  };

  useEffect(() => {
    const getPhaseItems = async () => {
      const { data } = await getSummaryProgress(cycleId);
      if (data && data.length > 0) {
        const result = data
          .map((dt) => {
            return {
              id: dt.id,
              name: dt.type
            };
          })
          .filter((dt) => dt.name != 'peer_selection');
        setPhaseItems(result);
      }
    };
    getPhaseItems();
  }, []);

  useEffect(() => {
    setRescheduleDateMoment(null);
  }, [selectedStatus]);

  return (
    <ChangeAssignmentContext.Provider value={{ ...contextValue }}>
      <HeaderPage {...headerProps} />
      <div className="px-[72px] py-[23px] bg-n-000 h-full overflow-auto">
        <InputUserIds
          setUserIds={setUserIds}
          userIds={userIds}
          hide={page != INPUT_PAGE}
        />
        {page == SUBMIT_PAGE && (
          <UserResults
            phaseItems={phaseItems}
            goBack={() => setPage(INPUT_PAGE)}
            selectedPhase={selectedPhase}
            setSelectedPhase={setSelectedPhase}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            rescheduleDateMoment={rescheduleDateMoment}
            setRescheduleDateMoment={setRescheduleDateMoment}
            usersFoundCount={userCount.usersFoundCount}
            usersNotFoundCount={userCount.usersNotFoundCount}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          />
        )}
      </div>
      <Footer
        linkButton={true}
        style={{ position: 'fixed', bottom: '0', left: '0' }}
        handleClick={handleClick}
        handleRoute={handleRoute}
        buttonPrimaryName={page == INPUT_PAGE ? 'Next' : 'Submit Change'}
        linkButtonName={page == INPUT_PAGE ? 'Cancel' : 'Back'}
        buttonPrimaryDisabled={buttonPrimaryDisabled}
        datacy="footer-next"
      />
      {modalOpen && (
        <Modal
          minWidth={400}
          customClass="!max-w-[400px]"
          onClose={() => setModalOpen(false)}
        >
          <Modal.Header
            title={getObjectiveLocale('Proceed?')}
            onClose={() => setModalOpen(false)}
          />
          <Modal.Body>
            <div className="px-[24px]">
              <p className="text-n-800 typography-paragraph">
                <span className="text-n-900 typography-h400">
                  {userCount?.usersFoundCount - excludeIds.length}
                </span>{' '}
                {getObjectiveLocale('reviewees of')}
                <span className="text-n-900 typography-h400">
                  {' '}
                  {getObjectiveLocale(startCase(selectedPhase?.name))}
                </span>{' '}
                {getObjectiveLocale('assignment will be changed to')}
                <span className="text-n-900 typography-h400">
                  {' '}
                  {selectedStatus?.name}
                </span>
                .
              </p>
              <div className="h-[40px] bg-y-200 flex items-center mt-[16px] rounded-lg px-[17px]">
                <SVGIcon
                  iconName="icon-warning"
                  fillColor="var(--y-600)"
                  size={24}
                  customClass="mr-[16px]"
                />
                <p className="text-n-900 typography-paragraph">
                  {excludeIds.length > 0
                    ? `${excludeIds.length} ${getObjectiveLocale(
                        'users excluded'
                      )}, `
                    : ''}{' '}
                  {`${userCount?.usersNotFoundCount} ${getObjectiveLocale(
                    'users not found'
                  )}`}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer
            primaryButton={{
              text: getObjectiveLocale('Submit Changes'),
              onClick: submitChangeHandler,
              dataCy: 'submit-change-assignment'
            }}
            secondaryButton={{
              text: getObjectiveLocale('Cancel'),
              onClick: () => setModalOpen(false),
              dataCy: 'cancel'
            }}
            useBorder={false}
          />
        </Modal>
      )}
    </ChangeAssignmentContext.Provider>
  );
};

export default ChangeAssignment;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(quarterOfYear);

// Force Set Timezone
dayjs.tz.setDefault('Asia/Jakarta');

const formatTimezone = function(date, type) {
  if (type == 'start') {
    return dayjs
      .tz(date)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toISOString();
  } else {
    return dayjs
      .tz(date)
      .hour(23)
      .minute(59)
      .second(59)
      .millisecond(999)
      .toISOString();
  }
};

const daytz = date => {
  return dayjs(date).tz();
};

const getDateWithForcedTimezone = (date, format) => {
  return dayjs(date)
    .tz()
    .format(format ? format : 'YYYY-MM-DD');
};

const getQuarterRange = (quarterType, year) => {
  const quarterRanges = {
    q1: { start: '-01-01', end: '-03-31' },
    q2: { start: '-04-01', end: '-06-30' },
    q3: { start: '-07-01', end: '-09-30' },
    q4: { start: '-10-01', end: '-12-31' },
    default: { start: '-01-01', end: '-12-31' }
  };
  const range =
    quarterType in quarterRanges
      ? quarterRanges[quarterType]
      : quarterRanges.default;
  const start = formatTimezone(
    dayjs(year + range.start).format('YYYY-MM-DD'),
    'start'
  );
  const due = formatTimezone(
    dayjs(year + range.end).format('YYYY-MM-DD'),
    'end'
  );

  return { start, due };
};

const getSemesterRange = (semesterType, year) => {
  const semesterRanges = {
    s1: { start: '-01-01', end: '-06-30' },
    s2: { start: '-07-01', end: '-12-31' },
    default: { start: '-01-01', end: '-12-31' }
  };
  const range =
    semesterType in semesterRanges
      ? semesterRanges[semesterType]
      : semesterRanges.default;
  const start = formatTimezone(
    dayjs(year + range.start).format('YYYY-MM-DD'),
    'start'
  );
  const due = formatTimezone(
    dayjs(year + range.end).format('YYYY-MM-DD'),
    'end'
  );

  return { start, due };
};

const getSemester = type => {
  if (type === 'start') {
    const startMonth = dayjs().month() < 6 ? 0 : 6;
    return dayjs()
      .month(startMonth)
      .startOf('month');
  } else {
    const endMonth = dayjs().month() < 6 ? 5 : 11;
    return dayjs()
      .month(endMonth)
      .endOf('month');
  }
};

export {
  formatTimezone,
  daytz,
  getDateWithForcedTimezone,
  getQuarterRange,
  getSemesterRange,
  getSemester
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { getConfig } from 'client/admin/Config';
import { getOrganizationDetail } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import useOrganization from 'hooks/useOrganization';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ContentNavigator from 'components/shared/ContentNavigator';
import InputField from 'components/shared/InputField';
import LoadingComponent from 'components/shared/LoadingComponent';
import TextArea from 'components/shared/TextArea';
import 'src/components/admin/theme-color/ThemeColor.scss';

import {
  CheckBoxOrganization,
  ListColorBox,
  OrgPhotoField,
  RadioButtonOrganization,
  ToggleCard
} from './OrganizationHelper';

const GeneralV2 = ({ appData, setAppData }) => {
  const { orgId } = useParams();
  const { organization, appType, config, user } = useUser();
  const isReadOnly =
    usePermission('settingsOrganizationGeneral') === 'read_only';

  const [activeSection, setActiveSection] = useState(
    'organization-info-section'
  );
  const orgData = useOrganization();
  const [isLoading, setIsLoading] = useState(true);
  const { currentColor, changeCurrentColor, isCancel } = useOrganization(
    (state) => ({
      currentColor: state.currentColor,
      isCancel: state.isCancel,
      changeCurrentColor: state.setCurrentColor,
      originGeneralData: state.originGeneralData
    })
  );

  let list = [
    { id: 'organization-info-section', name: 'Organization Info' },
    { id: 'performance-section', name: 'Performance' },
    { id: 'culture-section', name: 'Culture' }
  ];

  const listPerformance = [
    {
      element: 'inputPhoto',
      title: 'Logo',
      keyId: 'app',
      id: 'inputFile-app',
      src: appData?.logoUrl,
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Performance Name',
      placeholder: 'Performance Name',
      onBlur: (e) => setAppData({ ...appData, appName: e.target.value }),
      defaultValue: appData?.appName,
      dataCy: 'group-performance-name',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Subdomain',
      placeholder: 'Subdomain',
      onBlur: (e) => setAppData({ ...appData, subdomain: e.target.value }),
      defaultValue: appData?.subdomain,
      dataCy: 'group-performance-subdomain',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Host',
      placeholder: 'Host',
      onBlur: (e) => setAppData({ ...appData, host: e.target.value }),
      defaultValue: appData?.host,
      dataCy: 'group-performance-host',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Android Url',
      placeholder: 'Android Url',
      onBlur: (e) => setAppData({ ...appData, androidUrl: e.target.value }),
      defaultValue: appData?.androidUrl,
      dataCy: 'group-android-url',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'IOS Url',
      placeholder: 'IOS Url',
      onBlur: (e) => setAppData({ ...appData, iosUrl: e.target.value }),
      defaultValue: appData?.iosUrl,
      dataCy: 'group-ios-url',
      disabled: isReadOnly
    },
    // {/* ON HOLD UNTIL BACKEND SUPPORT */}
    // {
    //   element: 'input',
    //   label: 'Performance IPA Url',
    //   placeholder: 'Performance IPA Url',
    //   handleChange: e => setAppData({...appData, performanceIpaUrl: e.target.value}),
    //   defaultValue: appData?.performanceIpaUrl,
    //   dataCy: 'group-performance-name'
    // },
    // {
    //   element: 'input',
    //   label: 'Performance APK Url',
    //   placeholder: 'Performance APK Url',
    //   handleChange: e => setAppData({...appData, performanceApkUrl: e.target.value}),
    //   defaultValue: appData?.performanceApkUrl,
    //   dataCy: 'group-performance-name'
    // },
    {
      element: 'color',
      selectedColor: currentColor?.assets,
      changeColor: (color) =>
        changeCurrentColor({ ...currentColor, assets: color }),
      useReset: true,
      label: getObjectiveLocale('Theme Color'),
      disabled: isReadOnly,
      id: 'theme-perf'
    }
  ];

  const listCulture = [
    {
      element: 'inputPhoto',
      title: 'Logo',
      keyId: 'culture',
      id: 'inputFile-culture',
      src: appData?.cultureLogoUrl,
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Culture Name',
      placeholder: 'Culture Name',
      onBlur: (e) => setAppData({ ...appData, cultureAppName: e.target.value }),
      defaultValue: appData?.cultureAppName,
      dataCy: 'group-culture-name',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Culture Subdomain',
      placeholder: 'Culture Subdomain',
      onBlur: (e) =>
        setAppData({ ...appData, cultureSubdomain: e.target.value }),
      defaultValue: appData?.cultureSubdomain,
      dataCy: 'group-culture-subdomain',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Culture Host',
      placeholder: 'Culture Host',
      onBlur: (e) => setAppData({ ...appData, cultureHost: e.target.value }),
      defaultValue: appData?.cultureHost,
      dataCy: 'group-culture-host',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Culture Android Url',
      placeholder: 'Culture Android Url',
      onBlur: (e) =>
        setAppData({ ...appData, cultureAndroidUrl: e.target.value }),
      defaultValue: appData?.cultureAndroidUrl,
      dataCy: 'group-culture-android-url',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Culture IOS Url',
      placeholder: 'Culture IOS Url',
      onBlur: (e) => setAppData({ ...appData, cultureIosUrl: e.target.value }),
      defaultValue: appData?.cultureIosUrl,
      dataCy: 'group-culture-ios-url',
      disabled: isReadOnly
    },
    // {/* ON HOLD UNTIL BACKEND SUPPORT */}
    // {
    //   element: 'input',
    //   label: 'Culture IPA Url',
    //   placeholder: 'Culture IPA Url',
    //   onBlur: e => setAppData({...appData, cultureIpaUrl: e.target.value}),
    //   value: appData?.cultureIpaUrl,
    //   dataCy: 'group-performance-name'
    // },
    // {
    //   element: 'input',
    //   label: 'Culture APK Url',
    //   placeholder: 'Culture APK Url',
    //   onBlur: e => setAppData({...appData, cultureApkUrl: e.target.value}),
    //   value: appData?.cultureApkUrl,
    //   dataCy: 'group-performance-name'
    // },
    {
      element: 'color',
      selectedColor: currentColor?.cultureAssets,
      changeColor: (color) =>
        changeCurrentColor({ ...currentColor, cultureAssets: color }),
      useReset: true,
      label: getObjectiveLocale('Theme Color'),
      disabled: isReadOnly,
      id: 'theme-culture'
    }
  ];

  const inputList = [
    {
      element: 'inputPhoto',
      title: 'Company Logo',
      keyId: 'company',
      id: 'inputFile-company',
      src: appData?.companyLogo,
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Organization Name',
      placeholder: 'Organization Name',
      onBlur: (e) => setAppData({ ...appData, name: e.target.value }),
      defaultValue: appData?.name,
      dataCy: 'group-name',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Organization Identifier',
      placeholder: 'Organization Identifier',
      onBlur: (e) => setAppData({ ...appData, identifier: e.target.value }),
      defaultValue: appData?.identifier,
      dataCy: 'group-identifier',
      disabled: isReadOnly
    },
    // {
    //   element: 'input',
    //   label: 'Email Domain',
    //   placeholder: 'Email Domain',
    //   onBlur: e => setAppData({...appData, emailDomains: e.target.value}),
    //   defaultValue: appData?.emailDomains,
    //   dataCy: 'group-email-domains'
    // },
    {
      element: 'input',
      label: 'No Reply Email',
      placeholder: 'No Reply Email',
      onBlur: (e) => setAppData({ ...appData, noreplyEmail: e.target.value }),
      defaultValue: appData?.noreplyEmail,
      dataCy: 'group-no-reply-email',
      disabled: isReadOnly
    },
    {
      element: 'textarea',
      id: 'group-description',
      label: 'Description',
      textPlaceholder: 'Tell something about this organization',
      onBlur: (e) => setAppData({ ...appData, description: e.target.value }),
      defaultValue: appData?.description,
      dataCy: 'group-description',
      name: 'Description',
      className: 'content-comment max-h-[88px] max-h-[88px]',
      height: 88,
      disabled: isReadOnly
    },
    {
      element: 'textarea',
      id: 'group-vision',
      label: getObjectiveLocale('Vision'),
      textPlaceholder: getObjectiveLocale(
        'Describe the vision of this organization'
      ),
      onBlur: (e) => setAppData({ ...appData, vision: e.target.value }),
      defaultValue: appData?.vision,
      dataCy: 'group-vision',
      name: 'group-vision',
      className: 'content-comment h-[88px]',
      height: 88,
      disabled: isReadOnly
    },
    {
      element: 'textarea',
      id: 'group-mission',
      label: getObjectiveLocale('Mission'),
      textPlaceholder: getObjectiveLocale(
        'Describe the mission of this organization'
      ),
      onBlur: (e) => setAppData({ ...appData, mission: e.target.value }),
      defaultValue: appData?.mission || '',
      dataCy: 'group-mission',
      name: 'group-mission',
      className: 'content-comment h-[88px]',
      height: 88,
      disabled: isReadOnly
    }
  ];

  const _getData = async (orgId) => {
    const { data } = await getOrganizationDetail(orgId || organization.id);
    if (data) {
      orgData.changeOriginGeneralData(data);
      setAppData(data);
    }
    setIsLoading(false);
  };

  const getConfigData = async () => {
    const params = {
      settingsOrganization: 'general',
      organizationId: orgId
    };
    const { data } = await getConfig(params);
    if (data) {
      changeCurrentColor({
        assets: data?.assets?.mainColor,
        cultureAssets: data?.cultureAssets?.mainColor
      });
    }
  };

  const setActiveNavigator = (e) => {
    const scrollTop = e.target.scrollTop;
    const sections = [
      'organization-info-section',
      'performance-section',
      'culture-section'
    ];
    sections.forEach((section) => {
      const el = document.getElementById(section);
      if (el && scrollTop + 100 >= el.offsetTop) {
        setActiveSection(section);
      }
    });
    if (e.target.scrollTop + e.target.offsetHeight == e.target.scrollHeight) {
      setActiveSection(sections[sections.length - 1]);
    }
  };

  useEffect(() => {
    _getData(orgId);
    getConfigData();
  }, []);

  useEffect(() => {
    if (!isEmpty(appData)) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }
  }, [isCancel]);

  return (
    <>
      <div className="fixed right-0 mt-[24px] w-[160px] z-index-10">
        <ContentNavigator
          title="CONTENTS"
          activeSection={activeSection}
          listNavigatorContents={list}
          sectionContent="scrollable-config"
          additionalOffset={100}
        />
      </div>
      <div
        className="overflow-auto w-[87%] h-[calc(100vh-132px)]"
        id="scrollable-config"
        onScroll={(e) => setActiveNavigator(e)}
      >
        <div className="w-[768px] mt-[24px]">
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <>
              <p
                className="typography-h300 text-n-800 uppercase mb-[24px]"
                id="organization-info-section"
              >
                {getObjectiveLocale('General Configuration')}
              </p>
              <section
                className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px]"
                id="organization-info-section"
              >
                <p className="typography-h500 text-n-900 mb-[24px]">
                  {getObjectiveLocale('Organization Info')}
                </p>
                {inputList?.length > 0 &&
                  inputList.map((input, index) => {
                    return input.element === 'inputPhoto' ? (
                      <OrgPhotoField
                        {...input}
                        setGeneralData={setAppData}
                        generalData={appData}
                        key={`0-${index}`}
                      />
                    ) : input.element == 'input' ? (
                      <InputField
                        {...input}
                        className="mt-[16px]"
                        key={`0-${index}`}
                      />
                    ) : (
                      <React.Fragment key={`0-${index}`}>
                        <div className="mb-[8px] mt-[16px] typography-h100 text-n-800">
                          {input?.label}
                        </div>
                        <TextArea {...input} key={`0-${index}`} />
                      </React.Fragment>
                    );
                  })}
              </section>
              {appType !== 'culture' && user.role && (
                <section
                  className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px]"
                  id="performance-section"
                >
                  <p className="typography-h500 text-n-900 mb-[24px]">
                    {getObjectiveLocale('Performance')}
                  </p>
                  {listPerformance?.length > 0 &&
                    listPerformance.map((input, index) => {
                      return input.element === 'inputPhoto' ? (
                        <OrgPhotoField
                          {...input}
                          setGeneralData={setAppData}
                          generalData={appData}
                          key={`1-${index}`}
                        />
                      ) : input.element === 'input' ? (
                        <InputField
                          {...input}
                          className="mt-[16px]"
                          key={`1-${index}`}
                        />
                      ) : input.element === 'checkbox' ? (
                        <CheckBoxOrganization {...input} key={`1-${index}`} />
                      ) : (
                        <ListColorBox {...input} key={`1-${index}`} />
                      );
                    })}
                </section>
              )}
              {appType !== 'performance' && user.cultureRole && (
                <section className="max-h-[800px]" id="culture-section">
                  <p className="typography-h500 text-n-900 mb-[24px]">
                    {getObjectiveLocale('Culture')}
                  </p>
                  {listCulture?.length > 0 &&
                    listCulture.map((input, index) => {
                      return input.element === 'inputPhoto' ? (
                        <OrgPhotoField
                          {...input}
                          setGeneralData={setAppData}
                          generalData={appData}
                          key={`2-${index}`}
                        />
                      ) : input.element === 'input' ? (
                        <InputField
                          {...input}
                          className={`mt-[16px] ${
                            input?.type === 'number' ? 'w-[240px]' : ''
                          }`}
                          key={`2-${index}`}
                        />
                      ) : input.element === 'color' ? (
                        <ListColorBox {...input} key={`2-${index}`} />
                      ) : input.element === 'checkbox' ? (
                        <CheckBoxOrganization {...input} key={`2-${index}`} />
                      ) : input.element === 'radio' ? (
                        <RadioButtonOrganization
                          {...input}
                          key={`2-${index}`}
                        />
                      ) : (
                        input.insideCardPermission && (
                          <ToggleCard
                            {...input}
                            allowVideo={appData?.allowVideo}
                            key={`2-${index}`}
                          />
                        )
                      );
                    })}
                </section>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GeneralV2;

import create from 'zustand';

const useOrganization = create((set) => ({
  generalData: {},
  configData: {},
  originGeneralData: {},
  originConfigData: {},
  currentColor: '',
  isCancel: false,
  generalInformation: {},
  appData: {},
  originMeasurementUnitData: [],
  measurementUnitData: [],
  changeGeneralData: (data) => set({ generalData: data }),
  setGeneralInformation: (data) => set({ generalInformation: data }),
  setAppData: (data) => set({ appData: data }),
  changeConfigData: (data) => set({ configData: data }),
  changeOriginGeneralData: (data) => set({ originGeneralData: data }),
  changeOriginConfigData: (data) => set({ originConfigData: data }),
  setCurrentColor: (data) => set({ currentColor: data }),
  setIsCancel: (data) => set({ isCancel: data }),
  setMeasurementUnitData: (data) => set({ measurementUnitData: data }),
  setOriginMeasurementUnitData: (data) =>
    set({ originMeasurementUnitData: data })
}));

export default useOrganization;

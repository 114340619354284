import React, { useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Avatar from 'components/shared/Avatar';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

function UserCard({ user, onRemove, canRemove = true }) {
  const profilePicture = user.profilePicture || user.image;
  const isMandatory = user?.isMandatory;
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className="peer-card h-[56px]"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="profile-pic-container">
        <Avatar
          src={profilePicture}
          name={user.name}
          className="profile-img profile-picture-peer-selection"
          size={32}
          style={{ margin: '16px' }}
        />
        <div className="user-info">
          <p className="user-name">{user.name}</p>
          <p className="user-position">{user.jobTitle}</p>
        </div>
      </div>

      {!isMandatory && canRemove && (
        <Button
          variant="tertiary"
          customClass={`cursor-pointer ${
            isHover ? 'visible' : 'invisible'
          } mr-[16px]`}
          datacy="remove"
          onClick={() => onRemove(user)}
        >
          {getObjectiveLocale('Remove')}
        </Button>
      )}

      {isMandatory && (
        <TooltipContainer
          text="Set by system, you cannot remove this peer"
          show={true}
          useMaxWidth={false}
          align="right"
        >
          <div
            data-cy={'isMandatory'}
            className={`flex flex-row items-center pr-[16px]`}
          >
            <SVGIcon
              customClass="mr-[8px]"
              iconName="icon-lock_outline"
              fillColor="var(--n-600)"
              size="22"
            />
            <span className="typography-button text-n-800">
              {getObjectiveLocale('Required')}
            </span>
          </div>
        </TooltipContainer>
      )}
    </div>
  );
}

export default UserCard;

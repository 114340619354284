import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import isEmpty from 'lodash/isEmpty';

import {
  getManagerV2,
  getSubordinateV2,
  setPosition
} from 'client/UserProfile';
import { useColleague } from 'context/ColleagueContext';
import { useUser } from 'context/UserContext';
import usePrevUser from 'hooks/usePrevUser';
import debounce from 'utils/Debounce';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

import UserCardBar from './UserCardBar';
import UserProfileComponent from './UserProfileComponent';
import UserSubordinateProfileComponent from './UserSubordinateProfileComponent';

function UserProfileInfo({
  userId,
  placementId,
  performanceProfile,
  cultureProfile,
  permission,
  getData
}) {
  const { user } = useUser();
  const { colleague, hasMore, getColleagueData, appendColleagueData } =
    useColleague();
  const [managers, setManagers] = useState([]);
  const [subordinates, setSubordinates] = useState([]);
  const [totalSubordinates, setTotalSubordinates] = useState(0);
  const [olderThan, setOlderThan] = useState(0);
  const [search, setSearch] = useState('');
  const [onSearch, setOnSearch] = useState('');
  const [debounceHandler, setDebounceHandler] = useState(null);
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [isModalUserEnabled, setIsModalUserEnabled] = useState(false);

  const setPrevManager = usePrevUser((state) => state?.setManager);
  const getUserManager = async (userId) => {
    const { data } = await getManagerV2(placementId);
    !isEmpty(data) && (setManagers(data), setPrevManager(data));
  };

  const getUserSubordinate = async (isScrollFunction = false) => {
    let params = { placementId: placementId, state: 'active' };
    isScrollFunction && (params.olderThan = olderThan);
    const { data, pagination, metadata } = await getSubordinateV2(
      userId,
      params
    );
    if (data?.length > 0)
      setSubordinates(isScrollFunction ? [...subordinates, ...data] : data);
    !isScrollFunction &&
      setTotalSubordinates(metadata?.metadata?.directUserAvailableCount);
    setOlderThan(pagination?.next?.olderThan);
  };

  const onScrollSubordinate = (e) => {
    const target = e.target;
    const loadMore = async () => {
      setOnLoadMore(true);
      await getUserSubordinate(true);
      setOnLoadMore(false);
    };

    if (!onLoadMore && olderThan) {
      loadMoreValidator(target, 500, () => {
        loadMore();
      });
    }
  };

  const onAddMember = async (e) => {
    const selectedUserId = e.currentTarget.getAttribute('data-card');

    const query = {
      parentUserId: parseInt(userId)
    };
    await setPosition(query, selectedUserId);
    await getUserSubordinate();
  };

  const onRemoveMember = async (e) => {
    const selectedUserId = e.currentTarget.getAttribute('data-card');
    const query = {
      parentUserId: null
    };
    await setPosition(query, selectedUserId);
    await getUserSubordinate();
  };

  const searchColleague = async (value) => {
    const callback = async () => {
      setOnSearch(true);
      setSearch(value);
      const subordinatesIds = subordinates?.map((user) => {
        return user.id;
      });
      const query = {
        q: value,
        exclude: subordinatesIds?.concat([user.id])
      };
      await getColleagueData(query);
      setOnSearch(false);
    };

    const handler = await debounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  const onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      let query = {};
      if (search != '') {
        query.q = search;
      }
      const subordinatesIds = subordinates?.map((user) => {
        return user.id;
      });
      if (subordinatesIds?.length > 0) {
        query.exclude = subordinatesIds.concat([user.id]);
      }

      setOnLoadMore(true);
      await appendColleagueData(query);
      setOnLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 500, () => {
        loadMore();
      });
    }
  };

  useEffect(() => {
    if (user.role) {
      getUserManager(userId);
      getUserSubordinate();
    }
  }, [performanceProfile]);

  useEffect(() => {
    const _getColleagueData = async () => {
      let subordinatesIds = subordinates?.map((user) => {
        return user.id;
      });

      setOnLoadMore(true);
      await getColleagueData({ exclude: subordinatesIds?.concat([user.id]) });
      setOnLoadMore(false);
    };
    _getColleagueData();
  }, [subordinates]);

  return (
    <div className="user-info-container">
      <Fragment>
        <UserProfileComponent
          permission={permission}
          setTeamMember={() => setIsModalUserEnabled(true)}
          user={performanceProfile || cultureProfile}
          getData={getData}
        />
        {user.role && (
          <UserSubordinateProfileComponent
            managers={managers}
            subordinates={subordinates}
            totalSubordinates={totalSubordinates}
            onScroll={onScrollSubordinate}
          />
        )}
      </Fragment>

      {isModalUserEnabled && (
        <Modal
          title={getObjectiveLocale('Edit your direct report')}
          description={getObjectiveLocale(
            'You can manage your team by adding or removing person using table below'
          )}
          eventOnClickClose={() => setIsModalUserEnabled(false)}
          className="users-modal__profile"
          withCloseIcon
          useBorder
          maxHeight={612}
          withSecondaryBtn={{
            title: getObjectiveLocale('Close'),
            dataCy: 'close-direct-edit',
            onClick: () => setIsModalUserEnabled(false)
          }}
        >
          <div className="row">
            <div className="col-xs-5 h-pr0">
              <h3>
                {getObjectiveLocale('Member of your team')}
                <span className="total-member">
                  {totalSubordinates} {getObjectiveLocale('members')}
                </span>
              </h3>
              <div
                className="list-wrapper list-member"
                onScroll={(e) => onScrollSubordinate(e)}
              >
                {subordinates?.map((subordinate) => (
                  <UserCardBar
                    key={subordinate.id}
                    cardData={subordinate.id}
                    name={subordinate.name}
                    email={subordinate?.email}
                    avatarSource={subordinate.profilePicture || ''}
                    textButton="Delete"
                    eventOnClickCard={(e) => onRemoveMember(e)}
                  />
                ))}
              </div>
            </div>
            <div className="col-xs-2 exchange-arrows">
              <SVGIcon
                iconName="icon-arrow_right_alt"
                size="41"
                fillColor="var(--base-600)"
                customClass="left"
              />
              <SVGIcon
                iconName="icon-arrow_right_alt"
                size="41"
                fillColor="var(--base-600)"
                customClass="right"
              />
            </div>
            <div className="col-xs-5 pl-[0px]">
              <h3>{getObjectiveLocale('Non Member')}</h3>
              <div className="search-user-wrapper mb-[14px]">
                <InputField
                  placeholder="Search users"
                  handleChange={(e) => searchColleague(e.target.value)}
                />
              </div>
              <div
                className="list-wrapper list-not-member"
                onScroll={(e) => {
                  onScroll(e);
                }}
              >
                {onSearch ? (
                  <LoadingComponent className="user-loading" />
                ) : (
                  colleague.map((user) => (
                    <UserCardBar
                      key={user.id}
                      cardData={user.id}
                      name={user.name}
                      email={user.email}
                      avatarSource={user?.profilePicture || ''}
                      textButton="Add"
                      eventOnClickCard={(e) => onAddMember(e)}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default withRouter(UserProfileInfo);

import React, { useEffect, useRef } from 'react';

import { scrollEvent } from './scrollHelper';

let x = 0,
  startX = 0,
  scrollerY = 0;

const initializeScroll = (trackRef, contentWidth) => {
  const thumb = document.querySelector('.scroll-thumb-x');
  const widthTrack = trackRef.current.offsetWidth;
  const scroller = document.querySelector('#main-scroll');
  const scrollerViewWidth = scroller.offsetWidth;

  const scrollWrapper = document.querySelector('#scroll-content');
  const designatedThumbWidth = (widthTrack * scrollerViewWidth) / contentWidth;

  // eslint-disable-next-line no-undef
  const matrixScroll = new WebKitCSSMatrix(
    window.getComputedStyle(scrollWrapper).transform
  );
  const scrollPos = Math.abs(matrixScroll.m41);

  const maximumScroll = contentWidth - scrollerViewWidth;
  const scrollPercentageDistance = scrollPos / contentWidth;
  let scrollY = matrixScroll.m42;
  let scrollX = scrollPos * -1;
  if (scrollPos > maximumScroll) {
    scrollY = Math.floor(maximumScroll * -1);
  }
  if (designatedThumbWidth >= widthTrack) {
    thumb.style.width = widthTrack + 'px';
    thumb.style.visibility = 'hidden';
    scrollY = 0;
  } else {
    thumb.style.visibility = 'visible';
    thumb.style.width = designatedThumbWidth + 'px';
  }
  scrollWrapper.style.transform = `translate(${scrollX}px,${scrollY}px)`;

  thumb.style.width = designatedThumbWidth + 'px';
  scrollEvent(Math.abs(scrollX), Math.abs(scrollY));
  thumb.style.transform = `translateX(${Math.floor(
    scrollPercentageDistance * widthTrack
  )}px)`;
};

const ScrollbarHorizontal = ({ contentWidth }) => {
  const trackRef = useRef(null);

  useEffect(() => {
    const scroller = document.querySelector('#main-scroll');
    if (trackRef.current && scroller) {
      const initialize = () => initializeScroll(trackRef, contentWidth);
      initialize();
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentBoxSize) {
            initialize();
          }
        }
      });

      resizeObserver.observe(scroller);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [trackRef, contentWidth]);

  const mouseMoveHandler = (e) => {
    const distance = e.clientX - x;
    const scroller = document.querySelector('#main-scroll');
    const thumb = document.querySelector('.scroll-thumb-x');
    const track = document.querySelector('.scroll-track-x');
    const scrollWrapper = document.querySelector('#scroll-content');
    const trackWidth = track.offsetWidth;
    const thumbWidth = thumb.offsetWidth;
    const maxDistance = trackWidth - thumbWidth;
    const maxContentWidth = contentWidth - scroller.offsetWidth;

    const calculateX = startX + distance;
    let finalXTransform = calculateX;

    if (calculateX < 0) {
      finalXTransform = 0;
    }
    if (calculateX > maxDistance) {
      finalXTransform = maxDistance;
    }

    const currentScroll = (calculateX / maxDistance) * maxContentWidth;

    if (currentScroll < maxContentWidth && currentScroll >= 0) {
      scrollWrapper.style.transform = `translate(${
        currentScroll * -1
      }px,${scrollerY}px)`;
      scrollEvent(Math.abs(currentScroll * -1), Math.abs(scrollerY));
    }

    thumb.style.transform = `translateX(${finalXTransform}px)`;
  };

  const mouseUpHandler = () => {
    const thumb = document.querySelector('.scroll-thumb-x');
    thumb.style.removeProperty('user-select');
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  const mouseDownHandler = (e) => {
    const thumb = document.querySelector('.scroll-thumb-x');
    const scroller = document.querySelector('#scroll-content');
    thumb.style.userSelect = 'none';
    x = e.clientX;

    // eslint-disable-next-line no-undef
    const matrix = new WebKitCSSMatrix(
      window.getComputedStyle(thumb).transform
    );

    // eslint-disable-next-line no-undef
    const matrixScroller = new WebKitCSSMatrix(
      window.getComputedStyle(scroller).transform
    );

    startX = matrix.m41;
    scrollerY = matrixScroller.m42;

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  return (
    <div
      className="scroll-track-x absolute bottom-[2px] w-[calc(100%-360px)] h-[10px] z-[2] left-[360px]"
      ref={trackRef}
    >
      <div
        className="scroll-thumb-x bg-n-400 h-full rounded-full"
        onMouseDown={mouseDownHandler}
      />
    </div>
  );
};

export default ScrollbarHorizontal;

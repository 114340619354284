import React from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/shared/modal/Modal';

const ModalLeaveRatingTemplate = ({ setIsModalLeaveOpen }) => {
  const history = useHistory();

  const modalProps = {
    title: 'Leave rating template config',
    description:
      'Are you sure want to go back? If you leave now, your changes to the track template won’t be saved.',
    withCloseIcon: true,
    eventOnClickClose: () => setIsModalLeaveOpen(false),
    withPrimaryBtn: {
      danger: true,
      title: 'Leave',
      dataCy: 'leave-rating',
      onClick: () => history.push('/reviews/rating-template')
    },
    withSecondaryBtn: {
      title: 'Cancel',
      dataCy: 'cancel-leave-progress',
      onClick: () => setIsModalLeaveOpen(false)
    }
  };

  return <Modal {...modalProps} />;
};

export default ModalLeaveRatingTemplate;

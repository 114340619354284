import React from 'react';

import { useCreateCycle } from 'context/admin/CreateCycleContext';

import RadioButton from 'components/shared/RadioButton';

const ListConfig = ({ trackType, phaseType, templateId }) => {
  const { getTrackConfig, changeSuggestionAndAutoFillConfig } =
    useCreateCycle();

  const trackConfig = getTrackConfig(
    phaseType,
    trackType === 'review_aspects_scoring' ? templateId : trackType
  );

  const listConfig = [
    {
      description: 'Do not show or prefill the answers',
      value: 'do_not_show_or_prefill'
    },
    {
      description: `Show the answers based on ${
        phaseType === 'managerReview' ? 'Self Review' : 'Manager Review'
      }`,
      value: 'show_answers'
    },
    {
      description: `Show and prefill the answers based on ${
        phaseType === 'managerReview' ? 'Self Review' : 'Manager Review'
      }`,
      value: 'prefill'
    }
  ];

  const isRadioChecked = (config) => {
    if (trackConfig?.config?.suggestionSource === 'previous_cycle') {
      return false;
    }

    if (config.value === 'do_not_show_or_prefill') {
      return (
        !trackConfig?.config?.autoFill && !trackConfig?.config?.useSuggestion
      );
    }

    if (config.value === 'show_answers') {
      return (
        !trackConfig?.config?.autoFill && trackConfig?.config?.useSuggestion
      );
    }

    if (config.value === 'prefill') {
      return (
        trackConfig?.config?.autoFill && trackConfig?.config?.useSuggestion
      );
    }
  };

  return listConfig.map((config, index) => (
    <RadioButton
      key={index}
      size="small"
      value={config.value}
      id={`${phaseType}-${trackType}-${config.value}-${templateId}`}
      checked={isRadioChecked(config)}
      onChange={() =>
        changeSuggestionAndAutoFillConfig(
          phaseType,
          trackType,
          config.value,
          templateId
        )
      }
      addClass="mb-[4px] h-[24px]"
      dataCy={`${phaseType}-suggestion-${trackType}-${config.value}`}
    >
      <span className="typography-paragraph text-n-900 ml-[8px]">
        {config.description}
      </span>
    </RadioButton>
  ));
};

export default ListConfig;

import React, { useState } from 'react';

import ctl from '@netlify/classnames-template-literals';

import { getDateLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

const DocumentItem = ({ item, deleteAttachmentData }) => {
  const [onHover, setOnHover] = useState(false);

  const containerClass = ctl(`
    relative
    flex
    px-[24px] py-[16px]
    ${onHover && 'bg-base-30024'}
  `);

  return (
    <div
      className={containerClass}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      data-cy="document-item"
    >
      <SVGIcon fillColor="var(--n-600)" iconName="icon-attachment" size="24" />
      <a className="no-underline" href={item.downloadUrl} download>
        <div className="ml-[16px] flex flex-col">
          <span className="typography-h400">{item.filename}</span>
          <span className="mt-[4px] typography-secondary">
            {`By ${item.user.name} ${getDateLocale(item.createdAt)}`}
          </span>
        </div>
      </a>
      <SVGIcon
        fillColor="var(--n-600)"
        iconName="icon-delete"
        size="24"
        onClick={() => deleteAttachmentData(item.id)}
        customClass="absolute top-50p right-16 -translate-y-1/2"
        dataCy="delete-document-item"
      />
    </div>
  );
};

export default DocumentItem;

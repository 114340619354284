import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import WeightSettingsCard from './fragments/WeightSettingsCard';
import WeightSettingsInputNumber from './fragments/WeightSettingsInputNumber';

const WeightSettingsMinimum = ({ configData, changeObjectiveConfig }) => {
  const {
    objectivesConfig: {
      maxObjectiveWeightOn,
      minObjectiveWeightOn,
      maxObjectiveWeight,
      minObjectiveWeight
    }
  } = configData;

  const changeCallback = async ({ value, keys }) => {
    const isSuccess = await changeObjectiveConfig({ [keys]: value });
    return isSuccess;
  };

  const isError =
    maxObjectiveWeightOn && minObjectiveWeight >= maxObjectiveWeight;

  return (
    <WeightSettingsCard
      title={'Minimum Weight Value'}
      subTitle={'Show the expected minimum weight of each objectives'}
      isOpen={minObjectiveWeightOn}
      toggleCallback={({ value }) =>
        changeCallback({ value, keys: 'minObjectiveWeightOn' })
      }
      toggleCy="toggle-minimum"
    >
      <div className="flex flex-col">
        <p className="typography-h100 text-n-800 mb-[8px]">
          {getObjectiveLocale('Minimum weight (%)')}
        </p>
        <div className="flex flex-row items-center">
          <WeightSettingsInputNumber
            defaultValue={minObjectiveWeight}
            onChangeCallback={(value) =>
              changeCallback({ value, keys: 'minObjectiveWeight' })
            }
            isError={isError}
            dataCy="minimum-weight-input"
          />
          {isError && (
            <p className="typography-paragraph text-r-600 ml-[16px]">
              {getObjectiveLocale(`Minimum weight value must less than`)}{' '}
              {maxObjectiveWeight}%
            </p>
          )}
        </div>
      </div>
    </WeightSettingsCard>
  );
};

export default WeightSettingsMinimum;

import React, { useEffect, useMemo, useState } from 'react';

import { useUser } from 'context/UserContext';
import { useTeamDetail } from 'context/team/TeamDetailContext';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';
import {
  getPeriod,
  getProjectRolesParams,
  useFilterMenuObjectives
} from 'utils/ObjectivesHelper';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import ObjectivesModal from 'components/objectives/ObjectivesModal';
import ModalCreateProject from 'components/project/ModalCreateProject/ModalCreateProject';
import ProjectCardView from 'components/project/card/ProjectCardView';
import ProjectListView from 'components/project/list/ProjectListView';

const TeamProjects = ({ isTriggerCreate }) => {
  const { config } = useUser();
  const {
    defaultObjectiveSorting,
    timeFilterOptions: dateFilterOptions,
    includeTeamGoals
  } = useMemo(() => config, [config]);

  const filter = useFilter((state) => state.filterById?.projectFilter);
  const periodFilter = useMemo(() => {
    getPeriod(dateFilterOptions?.[0]);
  }, [dateFilterOptions]);
  const { teamId, data: teamDetail } = useTeamDetail();
  const { switch: currentView } = filter || {};
  const addedParams = {
    teamId: teamId,
    goals: 'team',
    state: teamDetail?.archivedAt ? ['archived'] : []
  };
  let filterMenu = useFilterMenuObjectives({
    config,
    except: ['follower', 'reviewer', 'status'],
    page: 'project'
  });

  const additionalParams = !includeTeamGoals ? { goals: 'team' } : {};

  const [openModalCreateProject, setOpenModalCreateProject] = useState(false);

  const defaultFilter = {
    group: 'phase',
    sortColumn: defaultObjectiveSorting?.sortColumn || 'due_date',
    sortDirection: defaultObjectiveSorting?.sortDirection || 'asc',
    assignTo: 'everyone',
    periodBegin: periodFilter?.periodBegin,
    periodEndBefore: periodFilter?.periodEndBefore,
    switch: 'card',
    phase: ['running', 'completed'],
    myInvolvement: ['leader', 'reviewer', 'member', 'pmo', 'stakeholder'],
    ...additionalParams
  };

  const switchOptions = [
    {
      id: 'list',
      iconName: 'icon-format_list_bulleted',
      filter: { switch: 'list' }
    },
    {
      id: 'card',
      iconName: 'icon-apps',
      filter: { switch: 'card' }
    }
  ];

  const resultModifier = (filter) => {
    let assignee = filter?.owner?.assignee?.map(({ user }) => user?.name);
    let phase = filter?.phase?.map((status) =>
      status === 'running' ? 'On Going' : status
    );

    const whichStatus = {
      bad: getObjectiveLocale('At Risk'),
      warning: getObjectiveLocale('Left Behind'),
      good: getObjectiveLocale('On Track'),
      exceed_expectation: getObjectiveLocale('Exceeded'),
      not_updated: getObjectiveLocale('Not Updated')
    };

    let status = filter?.status?.map((progress) => whichStatus[progress]);

    let type = filter?.type?.map((type) => type.name);
    const result = {
      owner: [
        { directorate: filter?.owner?.directorate || [] },
        { department: filter?.owner?.department || [] },
        { division: filter?.owner?.division || [] },
        ...(assignee || []),
        filter?.owner?.ownerType
      ].filter(Boolean),
      phase: phase || [],
      overdue: filter?.overdue,
      status: status,
      reviewer: filter?.reviewer?.map(({ user }) => user?.name),
      follower: filter?.follower?.map(({ user }) => user?.name),
      alignment: filter.alignment || '',
      lastUpdated: [filter?.lastUpdated?.lastUpdatedType || []],
      label: filter?.label,
      type: type,
      myInvolvement: filter?.myInvolvement
    };
    return result;
  };

  const restructureFilter = (filter) => {
    let newFilter = { ...filter };
    const myInvolvement = newFilter?.myInvolvement || [];

    if (!newFilter?.phase?.length) {
      newFilter = {
        ...newFilter,
        phase: config.defaultFilterPhase
      };
    }

    if (myInvolvement?.length > 0) {
      newFilter = {
        ...newFilter,
        roles: getProjectRolesParams(myInvolvement)
      };
    }

    return newFilter;
  };

  useEffect(() => {
    isTriggerCreate &&
      !openModalCreateProject &&
      setOpenModalCreateProject(true);
  }, [isTriggerCreate, openModalCreateProject]);

  return (
    <>
      <div className="my-[24px]">
        <AdvancedFilter
          id="projectFilter"
          filterOptions={[
            'menuFilter',
            'search',
            'sort',
            'switcherIcon',
            'dateFilter'
          ]}
          switchOptions={switchOptions}
          filterMenu={filterMenu}
          defaultFilter={defaultFilter}
          resultModifier={resultModifier}
          restructureFilter={restructureFilter}
        />
      </div>

      {currentView === 'list' ? (
        <ProjectListView
          addedParams={addedParams}
          setOpenModalCreateProject={setOpenModalCreateProject}
          isAbleToCreate={teamDetail?.permissions?.includes('create_project')}
          isArchived={!!teamDetail?.archivedAt}
        />
      ) : (
        <ProjectCardView
          setOpenModalCreateProject={setOpenModalCreateProject}
          addedParams={addedParams}
          isAbleToCreate={teamDetail?.permissions?.includes('create_project')}
          isArchived={!!teamDetail?.archivedAt}
        />
      )}

      {openModalCreateProject && (
        <ModalCreateProject
          eventOnClickClose={() => setOpenModalCreateProject(false)}
          team={teamDetail}
        />
      )}

      <ObjectivesModal />
    </>
  );
};

export default TeamProjects;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import { useImmer } from 'use-immer';

import {
  approveApprovalRequests,
  rejectApprovalRequests
} from 'client/ApprovalClient';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import ModalApprove from 'components/approval/ModalApprove';

import OthersApproval from './OthersApproval';
import TeamsApproval from './TeamsApproval';
import YourApproval from './YourApproval';

function Approval({ route: { title } }) {
  const { config } = useUser();
  const { userId } = useParams();
  const { url } = useUrl();
  const [currentTab, setCurrentTab] = useState('your-approval');
  const [requestIsEmpty, setRequestIsEmpty] = useState(true);
  const [showApproveAllModal, setShowApproveAllModal] = useState(false);
  const [showRejectAllModal, setShowRejectAllModal] = useState(false);
  const [approvalRequests, setApprovalRequests] = useImmer([]);
  const [refetch, setRefetch] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const showButtonApprove =
    currentTab === 'your-approval'
      ? config?.permissions?.approvalApprove
      : currentTab === 'others' &&
        url?.includes('others') &&
        config?.permissions?.approvalOthersApprove;
  const showButtonReject =
    currentTab === 'your-approval'
      ? config?.permissions?.approvalReject
      : currentTab === 'others' &&
        url?.includes('others') &&
        config?.permissions?.approvalOthersReject;

  const allList = [
    { id: 'your-approval', name: 'Your Approval', isVisible: true },
    {
      id: 'teams-approval',
      name: 'Team',
      isVisible: true
    },
    {
      id: 'others',
      name: 'Others',
      isVisible: config?.permissions?.approvalOthersSee
    }
  ].filter((opt) => opt?.isVisible);

  const headerProps = {
    titlePage: userId ? urlParams.get('name') : title,
    isHeaderComposer: !isEmpty(userId),
    backToUrl: '/approval?tab=others',
    primaryAction: showButtonApprove && {
      title: getObjectiveLocale('Approve All Requests'),
      onClick: () => setShowApproveAllModal(true),
      disabled: requestIsEmpty,
      dataCy: 'approval-approve-all'
    },
    secondaryAction: showButtonReject && {
      title: getObjectiveLocale('Reject All Requests'),
      onClick: () => setShowRejectAllModal(true),
      disabled: requestIsEmpty,
      dataCy: 'approval-reject-all'
    },
    tabs: !userId && {
      tabsList: allList,
      url: '/approval',
      onChange: (tab) => {
        setCurrentTab(tab);
      }
    }
  };

  const handleAction = async (isReject, body) => {
    let managerAction = isReject
      ? rejectApprovalRequests
      : approveApprovalRequests;
    delete body.requestIds;

    const { isSuccess } = await managerAction({
      ...body,
      all: true,
      placementId: urlParams.get('placement')
    });
    if (isSuccess) {
      setRefetch(!refetch);
    }
  };

  useEffect(() => {
    const tabType = urlParams.get('tab');
    tabType && setCurrentTab(tabType);
  }, []);

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="page-my-goals bg-n-000 min-h-[calc(100vh-64px)]">
        {currentTab === 'others' && !userId ? (
          <OthersApproval
            setRequestIsEmpty={setRequestIsEmpty}
            setApprovalRequests={setApprovalRequests}
            approvalRequests={approvalRequests}
          />
        ) : currentTab === 'your-approval' ||
          (currentTab === 'others' && userId) ? (
          <YourApproval
            setRequestIsEmpty={setRequestIsEmpty}
            setApprovalRequests={setApprovalRequests}
            approvalRequests={approvalRequests}
            refetch={refetch}
          />
        ) : (
          currentTab === 'teams-approval' && <TeamsApproval />
        )}
      </div>

      {showApproveAllModal && (
        <ModalApprove
          handleChange={setShowApproveAllModal}
          handleSubmit={handleAction}
          approvalRequests={approvalRequests}
        />
      )}
      {showRejectAllModal && (
        <ModalApprove
          isReject={true}
          handleChange={setShowRejectAllModal}
          handleSubmit={handleAction}
          approvalRequests={approvalRequests}
        />
      )}
    </>
  );
}

export default Approval;

import Papa from 'papaparse';

const ParseFile = (file, config = {}, resultFull) => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      ...config,
      header: true,
      complete: (results) => {
        if (resultFull) {
          return resolve(results);
        }
        resolve(results.data);
      }
    });
  });
};

const UnparseData = (data) => {
  let csv = Papa.unparse(data);
  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }
  data = encodeURI(csv);
  data = data.replace(/#/g, ':hashtag');
  return data;
};

const downloadExportCSV = (path) => {
  let link = document.createElement('a');
  link.setAttribute('href', path);

  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body && document.body.removeChild(link);
  }, 1500);
};

export { ParseFile, UnparseData, downloadExportCSV };

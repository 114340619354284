import React from 'react';

import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const LastComment = React.memo(({ task }) => {
  const { lastComment } = task;

  return (
    <TooltipContainer
      show
      text={lastComment}
      position="top"
      useMaxWidth={false}
      classContainer="truncate"
    >
      {lastComment && (
        <p className="typography-h100 text-n-800 truncate">{lastComment}</p>
      )}
    </TooltipContainer>
  );
});

export default LastComment;

import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import dayjs from 'dayjs';
import flatten from 'lodash/flatten';

import { getSurveyOccurencesPerId } from 'client/adminClient';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import Table from 'components/design-system/table/Table';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import ProgressBarOverallProgress from 'components/shared/progressBar/ProgressBarOverallProgress';

import ManageSurveyOptions from './ManageSurveyOptions';

const formatDateTime = (date, withTime = true) => {
  const format = withTime ? 'MMM DD, YYYY - LT' : 'MMM DD, YYYY';
  return dayjs(date).format(format);
};
const LIMIT = 3;
const stateOccurrences = {
  current: 'current',
  upcoming: 'upcoming',
  past: 'past'
};

const getQueryParams = ({
  state,
  pageParams,
  excludeIdOccurrence,
  firstOccurenceStart,
  currentTab
}) => {
  if (currentTab == 'upcoming') {
    return {
      excludes: excludeIdOccurrence,
      state,
      ...(pageParams?.currentDate
        ? { currentDate: pageParams?.currentDate, limit: LIMIT }
        : { currentDate: firstOccurenceStart, limit: LIMIT - 1 })
    };
  }
  return {
    excludes: excludeIdOccurrence,
    state,
    ...(pageParams?.olderThan
      ? { olderThan: pageParams?.olderThan, limit: LIMIT }
      : { limit: LIMIT - 1 })
  };
};

const ManageSurveyListRowRecurrences = ({
  currentTab,
  idSurvey,
  excludeIdOccurrence,
  onClickRow,
  setModalOpen,
  firstOccurenceStart,
  totalOccurrences
}) => {
  const [hasMore, setHasMore] = useState(false);
  const [data, setData] = useState([]);
  const fetchReccurence = (pageParams) => {
    const params = getQueryParams({
      state: stateOccurrences?.[currentTab],
      pageParams,
      excludeIdOccurrence,
      firstOccurenceStart,
      currentTab
    });
    return getSurveyOccurencesPerId(idSurvey, params);
  };

  const queryKey = ['survey', idSurvey, 'occurences', currentTab];

  const {
    data: surveyDatas,
    isSuccess,
    isFetching,
    fetchNextPage
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => fetchReccurence(pageParam),
    {
      getNextPageParam: (lastPage) => {
        if (currentTab == 'upcoming') {
          const data = lastPage?.data;
          return { currentDate: data?.[data.length - 1].startDate };
        }
        return lastPage.pagination?.next || 0;
      },
      staleTime: 0,
      cacheTime: 0
    }
  );

  const checkHasMore = (pages) => {
    const currentIndex = pages.length - 1;
    const currentPage = pages?.[currentIndex];
    if (!currentPage || !currentPage?.isSuccess) return;
    const currentData = currentPage.data;
    const nextPageParams = currentPage?.pagination?.next?.olderThan;
    const limitIndicator = currentIndex == 0 ? LIMIT - 1 : LIMIT;
    setHasMore(currentData.length == limitIndicator && nextPageParams);
  };

  const nextPageHandler = (e) => {
    e.stopPropagation();
    fetchNextPage();
  };

  useEffect(() => {
    if (!isSuccess) return;
    if (!isFetching && surveyDatas) {
      const newObj = flatten(
        surveyDatas?.pages?.map((page) => {
          if (page?.data) {
            return page?.data?.map((d) => {
              return d;
            });
          }
        })
      ).filter((data) => data);
      setData(newObj);
      if (currentTab == 'upcoming') {
        return setHasMore(newObj.length != totalOccurrences - 1);
      }
      checkHasMore(surveyDatas?.pages);
    }
  }, [surveyDatas, isSuccess, isFetching, currentTab, totalOccurrences]);

  return (
    <>
      {data?.map((dt, index) => (
        <RecurrenceRow
          key={dt.id}
          data={dt}
          index={index}
          currentTab={currentTab}
          onClickRow={onClickRow}
          idSurvey={idSurvey}
          setModalOpen={setModalOpen}
        />
      ))}
      {isFetching && (
        <div className="px-[16px]">
          <Shimmer width="100%" customClass="rounded" />
        </div>
      )}
      {hasMore && (
        <div
          className="flex items-center justify-center h-[32px] cursor-pointer px-[8px] py-[6px]"
          onClick={nextPageHandler}
        >
          <SVGIcon
            iconName="icon-arrow_downward"
            size="24"
            fillColor="var(--base-600)"
          />
          <p className="typography-button text-base-600 ml-[4px]">
            {getObjectiveLocale('Load more')}
          </p>
        </div>
      )}
    </>
  );
};

const getCompletionProgress = (data) => {
  return (
    parseFloat(
      (
        (data?.completionRate?.submitted / data?.completionRate?.total) *
        100
      ).toFixed(2)
    ) || 0
  );
};

const RecurrenceRow = ({
  currentTab,
  data,
  index,
  idSurvey,
  onClickRow,
  setModalOpen
}) => {
  const rowProps = {
    dataCy: `survey-row-${index}`,
    ...(currentTab != 'upcoming'
      ? { onClick: () => onClickRow(idSurvey, data?.id) }
      : { useRowHover: false })
  };
  return (
    <Table.Row {...rowProps}>
      <Table.Column></Table.Column>
      <Table.Column></Table.Column>
      <Table.Column>
        <p className="typography-paragraph text-n-900">
          {formatDateTime(data.startDate)}
        </p>
      </Table.Column>
      <Table.Column>
        <p className="typography-paragraph text-n-900">
          {formatDateTime(data.endDate)}
        </p>
      </Table.Column>
      <Table.Column>
        <TooltipContainer
          show
          position="top"
          text={`${data?.completionRate?.submitted} / ${
            data?.completionRate?.total || 0
          } ${getObjectiveLocale('Participants')}`}
          key={`progress-${index}`}
          useMaxWidth={false}
          classContainer="flex"
        >
          <ProgressBarOverallProgress
            widthBackground={100}
            widthProgress={parseInt(getCompletionProgress(data))}
            height={16}
            colorProgress="bg-g-600"
            containerClassName="w-[136px] h-[16px] overflow-hidden rounded-full"
          />
          <span className="typography-h400 ml-[8px] min-w-[40px] text-right">
            {getCompletionProgress(data)}%
          </span>
        </TooltipContainer>
      </Table.Column>
      <Table.Column></Table.Column>
      <Table.Column>
        {currentTab != 'upcoming' && (
          <ManageSurveyOptions
            id={idSurvey}
            occurrenceId={data?.id}
            withRemindOptions={
              data?.completionRate?.submitted != data?.completionRate?.total &&
              currentTab === 'current'
            }
            setModalOpen={setModalOpen}
          />
        )}
      </Table.Column>
    </Table.Row>
  );
};

export default ManageSurveyListRowRecurrences;

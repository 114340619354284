import React from 'react';

import Shimmer from 'components/design-system/shimmer/Shimmer';

const SidebarSkeleton = () => {
  return (
    <div className="skeleton-sidebar">
      <div className="sidebar_container w-[640px]">
        <div className="info-skeleton-section">
          <div className="complete-button-section">
            <Shimmer customClass="rounded" width="100%" height="36px" />
          </div>
          <div className="modify-buttons-section">
            {[1, 2, 3].map((_, idx) => (
              <Shimmer
                key={idx}
                width="23%"
                height="24px"
                customClass="rounded"
              />
            ))}
          </div>
        </div>
        <div className="sidebar_content">
          <div className="info-skeleton-section header-section">
            <Shimmer
              width="100%"
              height="36px"
              customClass="rounded"
              widthRandomness={0.25}
            />
          </div>
          <div className="involvement-section">
            <div className="owner-skeleton-section">
              <OwnerSkeleton />
            </div>
            <div className="reviewer-skeleton-section">
              <OwnerSkeleton />
            </div>
          </div>
          <TitleSectionSkeleton
            array={[{}, { width: '100%', height: '36px' }]}
          />
          <TitleSectionSkeleton array={[{ width: '100%' }, { width: '60%' }]} />
          <TitleSectionSkeleton array={[{ width: '70%' }, { width: '40%' }]} />
        </div>
      </div>
    </div>
  );
};

const TitleSectionSkeleton = ({ array }) => (
  <div className="title-section">
    {array.map((item, idx) => {
      return (
        <div key={idx} className="title-section-skeleton">
          <Shimmer
            width={item?.width || '20%'}
            height={item?.height || '16px'}
            customClass="rounded"
            widthRandomness={0.3}
          />
        </div>
      );
    })}
  </div>
);

const OwnerSkeleton = () => (
  <div className="owner-skeleton">
    <div className="profile-header">
      <Shimmer width="80px" height="16px" customClass="rounded" />
    </div>
    <div className="profile-header2">
      <div className="profile-picture">
        <Shimmer width="32px" height="32px" circle />
      </div>
      <div className="profile-name">
        <Shimmer width="124px" height="16px" customClass="rounded" />
      </div>
    </div>
  </div>
);

export default SidebarSkeleton;

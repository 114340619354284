import React, { useRef } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';

import Config from 'utils/Config';

const PropTypesParam = {
  data: PropTypes.object
};

const DefaultPropsParam = {
  data: {}
};

const LineChart = ({ data, isNumber, chartRef }) => {
  let config = {
    chart: {
      style: {
        fontFamily: 'Inter'
      },
      backgroundColor: 'var(--n-000)',
      type: 'line',
      marginTop: 50
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      floating: true,
      x: 0,
      y: 0,
      enabled: false
    },
    title: {
      text: 'Growth'
    },
    xAxis: {
      type: 'datetime',
      crosshair: {
        width: 1,
        color: 'gray',
        dashStyle: 'ShortDash'
      }
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    tooltip: {
      pointFormatter: function () {
        var point = this;
        return (
          '<span style="color:' +
          point.color +
          '">\u25CF</span> ' +
          startCase(point.series.name) +
          ': <b>' +
          point.y +
          ' ' +
          (isNumber ? 'users' : '%') +
          ' ' +
          (isNumber ? `(${point.percentage})%` : '') +
          '</b><br/>'
        );
      },
      shared: true
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.2
      }
    },
    series: data.series,
    exporting: {
      buttons: {
        contextButton: {
          symbol: Config.asset.general.iconDownload
        }
      }
    }
  };
  return (
    <HighchartsReact highcharts={Highcharts} options={config} ref={chartRef} />
  );
};

LineChart.propTypes = PropTypesParam;
LineChart.defaultProps = DefaultPropsParam;

export default LineChart;

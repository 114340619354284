import React from 'react';

import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';

const Priority = ({ objectiveDetail }) => {
  const { priority } = objectiveDetail || {};

  const { name, colorHex, bgColorHex } = priority || {};

  return (
    <div className="px-[24px]">
      <div className="h-[48px] flex items-center">
        <SVGIcon
          iconName="icon-outlined_flag"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <Badge
          defaultPointer
          content={name}
          colorHex={colorHex}
          bgColorHex={bgColorHex}
          className="pointer-events-none"
        />
      </div>
    </div>
  );
};

export default Priority;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import { format } from 'timeago.js';

import {
  getCalibrationHistories,
  getCalibrationResultHistories,
  getCurrentCalibrationScore,
  getCurrentResultCalibrationScore
} from 'client/FormalReviewClient';
import { useUser } from 'context/UserContext';
import { getNumberWithOrdinal, getObjectiveLocale } from 'utils/HelperUtils';

import WhiteCard from 'components/admin/analytic/team-review/WhiteCard';
import Button from 'components/design-system/Button';
import SidebarRight from 'components/design-system/SidebarRight/SidebarRight';
import Avatar from 'components/shared/Avatar';
import SVGIcon from 'components/shared/SVGIcon';
import Tooltips from 'components/shared/Tooltips/Tooltips';
import TruncateParagraph from 'components/shared/TruncateParagraph';
import WhiteCardWithFooter from 'components/shared/WhiteCardWithFooter';
import EmployeeAvatarNameTitle from 'components/shared/coaching/EmployeeAvatarNameTitle';

import CalibrationHistorySidebarSkeleton from './CalibrationHistorySidebarSkeleton';

function CalibrationHistorySidebar({
  cycleId = 0,
  setShowCalibrationSidebar = () => null,
  userData = {},
  withHeaderButton = true,
  calibrationHistoryLoading = false,
  isPerfCategory = false,
  currentCalibrator,
  isResultPage = false,
  isAbleToShowCurrentScore = false
}) {
  let numberCalibration = 0;
  const { organization } = useUser();
  const history = useHistory();
  const [currentScore, setCurrentScore] = useState(null);

  const handleClickReviewResult = () => {
    localStorage.setItem('backUrl', history.location.pathname);
    history.push(
      `/reviews/result/target/${userData.userId}/placement/${userData.placementId}/cycle/${cycleId}/`
    );
  };

  const getHistories = async () => {
    return isResultPage
      ? getCalibrationResultHistories(
          cycleId,
          userData?.userId || userData?.id,
          {
            isCalibrate: true
          }
        )
      : getCalibrationHistories(cycleId, userData?.userId || userData?.id, {
          isCalibrate: true,
          calibratorId: currentCalibrator?.id
        });
  };

  let historiesData = [];
  let { data, refetch } = useQuery(
    ['calibration', 'history', cycleId, userData.userId],
    getHistories
  );

  if (data) {
    historiesData = data.data;
  }

  const updatedHistoriesData = isAbleToShowCurrentScore
    ? historiesData?.filter((historyData) => historyData?.isCalibrate)
    : historiesData;

  useEffect(() => {
    if (isAbleToShowCurrentScore) {
      const getData = async () => {
        const getScore = isResultPage
          ? await getCurrentResultCalibrationScore(cycleId, userData?.userId)
          : await getCurrentCalibrationScore(cycleId, userData?.userId, {
              calibratorId: currentCalibrator?.id
            });
        const { data } = getScore;
        if (data) {
          setCurrentScore(data);
        }
      };

      getData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetch();
  }, [userData, refetch]);

  return (
    <>
      <SidebarRight
        dataCy="calibration-sidebar"
        fixed={false}
        customClass="!fixed right-0"
        fullWidth={false}
        customWidth="w-[400px]"
        fullHeight
      >
        <SidebarRight.Header customClass="flex justify-between">
          {
            withHeaderButton ? (
              <Button.Secondary onClick={handleClickReviewResult}>
                {getObjectiveLocale('View Review Result')}
              </Button.Secondary>
            ) : (
              <div></div>
            ) // dummy div, it is needed to justify-content elements
          }
          <SVGIcon
            iconName="icon-clear"
            size={24}
            fillColor="var(--n-600)"
            onClick={() => setShowCalibrationSidebar(false)}
          />
        </SidebarRight.Header>
        <SidebarRight.Body withSpace={false}>
          {calibrationHistoryLoading ? (
            <CalibrationHistorySidebarSkeleton
              setShowCalibrationSidebar={setShowCalibrationSidebar}
            />
          ) : (
            <>
              <div className="flex flex-col border-0 border-b border-solid border-n-400">
                <div className="px-[24px] py-[16px]">
                  <EmployeeAvatarNameTitle user={userData} />
                </div>
                {isAbleToShowCurrentScore && (
                  <div className="px-[16px] mb-[16px]">
                    <WhiteCard classes="border-n400">
                      <h5 className="typography-h300 typography-secondary mb-[16px]">
                        {getObjectiveLocale('Current Score').toUpperCase()}
                      </h5>
                      <h4 className="typography-h600 mb-[0px]">
                        {isPerfCategory ? (
                          currentScore?.performanceCategory
                        ) : (
                          <>
                            {currentScore?.score?.toFixed(2)}
                            {currentScore?.statusName && (
                              <span
                                className="typography-h600 ml-[8px]"
                                style={{
                                  color: `#${currentScore?.statusColorHex}`
                                }}
                              >
                                {currentScore.statusName}
                              </span>
                            )}
                          </>
                        )}
                      </h4>
                      <p className="typography-paragraph text-n-800 mt-[16px]">
                        {currentScore?.comment}
                      </p>
                      {currentScore?.calibrator && (
                        <div className="flex flex-col mt-[16px]">
                          <p className="typography-h100 text-n-600 mb-[8px]">
                            {getObjectiveLocale('Last updated by')}
                          </p>
                          <div className="flex items-center">
                            <Avatar
                              src={currentScore?.calibrator?.profilePicture}
                              name={currentScore?.calibrator?.name}
                              size={24}
                            />
                            <p className="typography-h400 text-n-800 ml-[8px]">
                              {currentScore?.calibrator?.name}
                            </p>
                          </div>
                        </div>
                      )}
                    </WhiteCard>
                  </div>
                )}
              </div>
              <div className="p-[24px] overflow-auto">
                {historiesData?.length !== 0 && (
                  <h5 className="typography-h300 typography-secondary mb-[16px]">
                    {getObjectiveLocale('CALIBRATION HISTORY')}
                  </h5>
                )}
                {historiesData?.length === 0 ||
                updatedHistoriesData?.length === 0 ? (
                  // Empty State
                  <div className="empty-state-calibration flex-center-center flex-col text-center mt-[64px]">
                    <SVGIcon
                      size="80"
                      iconName="icon-error"
                      fillColor="var(--n-700)"
                      customClass="mb-[16px]"
                    />
                    <h5 className="typography-h500 mb-[8px]">
                      {getObjectiveLocale('No Calibration History')}
                    </h5>
                    <p className="typography-paragraph typography-secondary">
                      {getObjectiveLocale(
                        'This user has not received any changes regarding the calibration process.'
                      )}
                    </p>
                  </div>
                ) : (
                  updatedHistoriesData
                    ?.map((calibration, index) => {
                      const calibrator = calibration?.calibrator || {};
                      const calibrationOwner =
                        calibration?.calibrationOwner || {};
                      if (calibration?.isCalibrate) {
                        numberCalibration += 1;
                      }

                      return (
                        <WhiteCardWithFooter
                          key={calibration?.id}
                          withFooter={true}
                          containerClass="shadow-raised mt-[16px] no-border"
                          bodyClass="p-16 pb-[48px]"
                          footerClass="footer-card-calibration"
                          footerContent={
                            <div className="flex items-center">
                              <div className="user-avatar w-[24px] mr-[0px]">
                                <Avatar
                                  src={
                                    calibrator?.profilePicture ||
                                    organization.logoUrl
                                  }
                                  name={calibrator?.name}
                                  size={24}
                                />
                              </div>
                              <span className="typography-paragraph ml-[16px]">
                                {calibrator?.name || 'By System'}
                              </span>
                            </div>
                          }
                        >
                          <div className="calibration-history-card w-full">
                            <div className="flex items-center justify-between mb-[16px]">
                              <h5 className="typography-h400 mb-[0px]">
                                {numberCalibration == 0
                                  ? getObjectiveLocale('Initial Score')
                                  : `${getNumberWithOrdinal(
                                      numberCalibration
                                    )} calibration`}
                              </h5>
                              <div>
                                <Tooltips
                                  text={dayjs(calibration?.updatedAt).format(
                                    'DD MMM YYYY HH:mm'
                                  )}
                                  isEllipsis
                                >
                                  <span className="typography-h100 text-n-600">
                                    {format(calibration?.updatedAt)}
                                  </span>
                                </Tooltips>
                              </div>
                            </div>
                            <div className="score-changes flex">
                              {isPerfCategory ? (
                                <span className="typography-h600 text-b-600">
                                  {calibration?.performanceCategory}
                                </span>
                              ) : (
                                <span className="typography-h600 flex items-center">
                                  {calibration?.score?.toFixed(2)}
                                  {calibration?.statusName && (
                                    <span
                                      className="typography-h600 ml-[8px]"
                                      style={{
                                        color: `#${calibration?.statusColorHex}`
                                      }}
                                    >
                                      {calibration?.statusName}
                                    </span>
                                  )}
                                </span>
                              )}
                            </div>
                            <p className="typography-paragraph mt-[16px] mb-[16px]">
                              {calibration?.comment}
                            </p>
                          </div>
                        </WhiteCardWithFooter>
                      );
                    })
                    .reverse()
                )}
              </div>
            </>
          )}
        </SidebarRight.Body>
      </SidebarRight>
    </>
  );
}

export default CalibrationHistorySidebar;

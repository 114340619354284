"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.restructureInsightsPayload = exports.getUserCreator = void 0;
const getUserCreator = (user) => {
    return {
        id: user === null || user === void 0 ? void 0 : user.id,
        externalId: user === null || user === void 0 ? void 0 : user.externalId,
        email: user === null || user === void 0 ? void 0 : user.email,
        name: user === null || user === void 0 ? void 0 : user.name,
        role: user === null || user === void 0 ? void 0 : user.role,
        cultureRole: user === null || user === void 0 ? void 0 : user.cultureRole,
        state: user === null || user === void 0 ? void 0 : user.state,
        profilePicture: user === null || user === void 0 ? void 0 : user.profilePicture,
        workloadStatus: user === null || user === void 0 ? void 0 : user.workloadStatus
    };
};
exports.getUserCreator = getUserCreator;
const restructureInsightsPayload = (insights, columns) => {
    const dataInsight = [];
    const sectionData = insights.map(({ sectionData }) => sectionData);
    for (let i = 0; i < sectionData.length; i++) {
        sectionData[i].map((row) => {
            var _a, _b, _c, _d;
            dataInsight.push({
                rowNumber: row === null || row === void 0 ? void 0 : row.rowNumber,
                rowName: row === null || row === void 0 ? void 0 : row.rowName,
                columnNumber: (_a = columns === null || columns === void 0 ? void 0 : columns[i]) === null || _a === void 0 ? void 0 : _a.order,
                columnName: (_b = columns === null || columns === void 0 ? void 0 : columns[i]) === null || _b === void 0 ? void 0 : _b.name,
                columnType: (_c = columns === null || columns === void 0 ? void 0 : columns[i]) === null || _c === void 0 ? void 0 : _c.type,
                objectiveId: ((_d = row.objective) === null || _d === void 0 ? void 0 : _d.id) || null
            });
        });
    }
    return dataInsight;
};
exports.restructureInsightsPayload = restructureInsightsPayload;

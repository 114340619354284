import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import omit from 'lodash/omit';

import {
  getOneReviewGroup,
  postListReviewGroup,
  putListReviewGroup
} from 'client/admin/ReviewGroupClient.js';
import { useUser } from 'context/UserContext';

import HeaderPage from 'components/admin/HeaderPage';
import Button from 'components/design-system/Button';

import VerticalStepsComponent from '../../../shared/VerticalStepsComponent';
import GroupTableUser from '../GroupTableUser';
import NameDescriptionReviewGroup from '../NameDescriptionReviewGroup';
import VerifyView from '../VerifyView';
import './CreateEditBehaviorModelPage.scss';
import StepConfiguration from './StepConfiguration';

const CreateEditBehaviorModelPage = ({ route: { isReadOnly } }) => {
  const { action, id } = useParams();
  const history = useHistory();
  const { config } = useUser();
  const [isLoadingDefaultData, setIsLoadingDefaultData] = useState(true);
  const [dataDefault, setDataDefault] = useState({});
  const [modelData, setModelData] = useState({});
  const [isEnableSetPercentage, setIsEnableSetPercentage] = useState(false);
  const [behaviorWeightsCompetency, setBehaviorWeightsCompetency] = useState(
    []
  );
  const [behaviorWeightsValue, setBehaviorWeightsValue] = useState([]);
  const [behaviorWeightsWork, setBehaviorWeightsWork] = useState([]);
  const [isWarningCompetency, setIsWarningCompetency] = useState(false);
  const [isWarningValue, setIsWarningValue] = useState(false);
  const [inputGroupName, setInputGroupName] = useState('');
  const [inputGroupDesc, setInputGroupDesc] = useState('');
  const [dataCompetencyDictionary, setDataCompetencyDictionary] = useState([]);
  const [dataWorkDictionary, setDataWorkDictionary] = useState([]);
  const [dataValueDictionary, setDataValueDictionary] = useState([]);
  const [involvedMembershipsByInfo, setInvolvedMembershipsByInfo] = useState(
    []
  );

  // user data
  const [isSelectAllRow, setIsSelectAllRow] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [listIdUser, setListIdUser] = useState([]);
  const [excludedId, setExcludedId] = useState([]);
  const [totalUser, setTotalUser] = useState(0);

  // state for sidebar composer
  const [activeStep, setActiveStep] = useState('Name & Description');
  const [steps, setSteps] = useState([
    { disabled: false, completed: false, name: 'Name & Description' },
    { disabled: true, completed: false, name: 'Members' },
    { disabled: true, completed: false, name: 'Configuration' },
    { disabled: true, completed: false, name: 'Verify' }
  ]);
  const [isSubmitAble, setIsSubmitAble] = useState(false);

  const bodyData = () => {
    let body = {
      ...modelData,
      name: inputGroupName,
      description: inputGroupDesc || '',
      groupType: 'behavior_group',
      useBehaviorWeight: isEnableSetPercentage,
      selectAll: isSelectAll
    };

    if (behaviorWeightsCompetency) {
      body.competencyWeights = behaviorWeightsCompetency;
    }

    if (behaviorWeightsValue) {
      body.valueWeights = behaviorWeightsValue;
    }

    if (behaviorWeightsWork && config?.allowUsingWorkAttributes) {
      body.workAttributeWeights = behaviorWeightsWork;
    }

    if (!isSelectAll) {
      body.includes = listIdUser;
    } else {
      body.excludes = excludedId;
    }

    const _body = omit(body, [
      'competencyModel',
      'valueModel',
      'workAttributeModel'
    ]);
    return _body;
  };

  const saveData = async () => {
    const body = bodyData();
    const { data } = await (action == 'create'
      ? postListReviewGroup(body)
      : putListReviewGroup(id, body));
    if (data) {
      history.push(`/group/behavior-model?tab=groups`);
    }
  };

  const currentIndex = steps.findIndex((step) => step.name === activeStep);

  const handleNextButton = () => {
    const nextIndex = currentIndex + 1;
    setActiveStep(steps[nextIndex].name);
  };

  const handleBackButton = () => {
    const previousIndex = currentIndex - 1;
    setActiveStep(steps[previousIndex].name);
  };

  const updateCompletedStep = (stepName, boolean) => {
    let tempSteps = [...steps];
    let intendedIndex = tempSteps.findIndex((step) => step.name === stepName);
    let edittedStep = { ...tempSteps[intendedIndex] };

    edittedStep.completed = boolean;
    tempSteps[intendedIndex] = edittedStep;

    setSteps(tempSteps);
  };

  const handleDisabledAndCompletedStep = ({
    stepName,
    disabledBoolean,
    completedBoolean
  }) => {
    let changeStepDisabledValue = steps.map((step) => {
      return { ...step, disabled: disabledBoolean };
    });
    let intendedIndex = changeStepDisabledValue.findIndex(
      (step) => step.name === stepName
    );
    let edittedStep = { ...changeStepDisabledValue[intendedIndex] };
    edittedStep.completed = completedBoolean;
    changeStepDisabledValue[intendedIndex] = edittedStep;
    setSteps(changeStepDisabledValue);
  };

  const getSingleGroupData = async () => {
    const { data } = await getOneReviewGroup(id);
    if (data) {
      setListIdUser(data?.memberIds || []);
      setIsEnableSetPercentage(data?.useBehaviorWeight);
      setModelData({
        competencyModel: data?.competencyModel || [],
        valueModel: data?.valueModel || [],
        workAttributeModel: data?.workAttributeModel || [],
        competencyModelId: data?.competencyModel?.id,
        valueModelId: data?.valueModel?.id,
        workAttributeModelId: data?.workAttributeModel?.id
      });
      setBehaviorWeightsCompetency(data?.competencyModel?.weights || []);
      setBehaviorWeightsValue(data?.valueModel?.weights || []);
      setBehaviorWeightsWork(data?.workAttributeModel?.weights || []);

      setDataDefault(data);
      setInputGroupName(data?.name);
      setInputGroupDesc(data?.description);
      setInvolvedMembershipsByInfo(data?.involvedMembershipsByInfo);
      setIsLoadingDefaultData(false);
    }
  };

  useEffect(() => {
    action == 'edit' ? getSingleGroupData() : setIsLoadingDefaultData(false);
  }, []);

  useEffect(() => {
    const findFalse = steps.find((step) => step.completed === false);
    if (!findFalse) {
      setIsSubmitAble(true);
    }

    return () => {
      setIsSubmitAble(false);
    };
  }, [steps]);

  // Check is Name & Description COMPLETED
  useEffect(() => {
    if (inputGroupName) {
      handleDisabledAndCompletedStep({
        stepName: 'Name & Description',
        disabledBoolean: false,
        completedBoolean: true
      });
    } else {
      handleDisabledAndCompletedStep({
        stepName: 'Name & Description',
        disabledBoolean: true,
        completedBoolean: false
      });
    }
  }, [inputGroupName]);

  // Check is Members COMPLETED
  useEffect(() => {
    updateCompletedStep('Members', listIdUser.length > 0 ? true : false);
  }, [listIdUser]);

  // Check is Configuration COMPLETED
  useEffect(() => {
    let cond2 = modelData.competencyModelId && modelData.valueModelId;
    if (
      isEnableSetPercentage &&
      !isWarningCompetency &&
      !isWarningValue &&
      cond2
    ) {
      updateCompletedStep('Configuration', true);
      return;
    }

    if (!isEnableSetPercentage && cond2) {
      updateCompletedStep('Configuration', true);
      return;
    }

    updateCompletedStep('Configuration', false);
  }, [isEnableSetPercentage, isWarningValue, isWarningCompetency, modelData]);

  // Check is Verify COMPLETED
  const divMainBody = document.getElementById('main-body');
  useEffect(() => {
    const areAllStepsCompleted =
      steps[0].completed && steps[1].completed && steps[2].completed;
    if (areAllStepsCompleted) {
      updateCompletedStep('Verify', true);
    } else {
      updateCompletedStep('Verify', false);
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    if (activeStep == 'Configuration') {
      if (divMainBody) divMainBody.style.overflow = 'hidden';
    } else {
      if (divMainBody) divMainBody.style.overflow = 'auto';
    }
  }, [activeStep]);

  return (
    <>
      <HeaderPage
        titlePage={`${
          action == 'create' ? 'Create' : 'Edit'
        } Behavior Model Group`}
        backToUrl={'/group/behavior-model?tab=groups'}
        isHeaderComposer={true}
      />
      <div className="container-create-edit-behavior-group bg-n-000 pl-[0px] min-h-[calc(100vh-64px)]">
        <div className="cgp-body-container">
          <VerticalStepsComponent
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
          />

          <div
            className={`cgp-body-content ${
              activeStep == 'Members' ? 'custom-width-step-member' : 'w-full'
            }`}
          >
            {activeStep === 'Name & Description' && (
              <NameDescriptionReviewGroup
                inputGroupName={inputGroupName}
                inputGroupDesc={inputGroupDesc}
                setInputGroupName={setInputGroupName}
                setInputGroupDesc={setInputGroupDesc}
              />
            )}

            {activeStep === 'Members' && (
              <>
                <h4 className="typography-h500 pt-[24px] mb-[24px]">
                  Select members
                </h4>
                <GroupTableUser
                  groupType={'behavior_group'}
                  setTotalUser={setTotalUser}
                  involvedMembershipsByInfo={involvedMembershipsByInfo}
                  // selectAll config
                  isSelectAll={isSelectAll}
                  setIsSelectAll={setIsSelectAll}
                  isSelectAllRow={isSelectAllRow}
                  setIsSelectAllRow={setIsSelectAllRow}
                  // include exclude config
                  listIdUser={listIdUser}
                  setListIdUser={setListIdUser}
                  excludedId={excludedId}
                  setExcludedId={setExcludedId}
                />
              </>
            )}

            {activeStep === 'Configuration' && (
              <StepConfiguration
                isEnableSetPercentage={isEnableSetPercentage}
                setIsEnableSetPercentage={setIsEnableSetPercentage}
                isLoadingDefaultData={isLoadingDefaultData}
                dataDefault={dataDefault}
                modelData={modelData}
                setModelData={setModelData}
                // behavior
                behaviorWeightsCompetency={behaviorWeightsCompetency}
                setBehaviorWeightsCompetency={setBehaviorWeightsCompetency}
                behaviorWeightsValue={behaviorWeightsValue}
                setBehaviorWeightsValue={setBehaviorWeightsValue}
                behaviorWeightsWork={behaviorWeightsWork}
                setBehaviorWeightsWork={setBehaviorWeightsWork}
                // warning
                isWarningCompetency={isWarningCompetency}
                setIsWarningCompetency={setIsWarningCompetency}
                isWarningValue={isWarningValue}
                setIsWarningValue={setIsWarningValue}
                // dictionary
                dataCompetencyDictionary={dataCompetencyDictionary}
                setDataCompetencyDictionary={setDataCompetencyDictionary}
                dataValueDictionary={dataValueDictionary}
                setDataValueDictionary={setDataValueDictionary}
                dataWorkDictionary={dataWorkDictionary}
                setDataWorkDictionary={setDataWorkDictionary}
                isReadOnly={isReadOnly}
              />
            )}

            {activeStep === 'Verify' && (
              <VerifyView
                isSelectAll={isSelectAll}
                inputGroupName={inputGroupName}
                inputGroupDesc={inputGroupDesc}
                listIdUser={listIdUser}
                excludedId={excludedId}
                totalUser={totalUser}
              >
                <div className="verify-view-container">
                  <h5 className="typography-h500 mb-[32px]">Behavior Model</h5>
                  <div>
                    <div className="flex mb-[32px]">
                      <p className="w-[216px] typography-paragraph typography-secondary">
                        Competency Model
                      </p>
                      <p className="typography-paragraph">
                        {modelData?.competencyModel?.title}
                      </p>
                    </div>
                    <div className="flex mb-[32px]">
                      <p className="w-[216px] typography-paragraph typography-secondary">
                        Value Model
                      </p>
                      <p className="typography-paragraph">
                        {modelData?.valueModel?.title}
                      </p>
                    </div>
                    {config?.allowUsingWorkAttributes && (
                      <div className="flex mb-[32px]">
                        <p className="w-[216px] typography-paragraph typography-secondary">
                          Work Attribute Model
                        </p>
                        <p className="typography-paragraph">
                          {modelData?.workAttributeModel?.title}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </VerifyView>
            )}
          </div>

          <div className="cgp-fixed-footer">
            <div className="flex float-right">
              <Button.Tertiary
                disabled={currentIndex == 0}
                onClick={() => handleBackButton()}
              >
                Back
              </Button.Tertiary>
              {currentIndex == steps.length - 1 ? (
                <Button
                  datacy={'submit-button'}
                  onClick={() => saveData()}
                  disabled={!isSubmitAble}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  datacy={'next-button'}
                  onClick={
                    steps[currentIndex + 1].disabled
                      ? () => {}
                      : () => handleNextButton()
                  }
                  disabled={steps[currentIndex + 1].disabled}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEditBehaviorModelPage;

const getCurrentPerfomanceCategoryScore = (tempCurrentScoreData, user) => {
  if (tempCurrentScoreData?.performanceCategory) {
    return tempCurrentScoreData?.performanceCategory;
  } else {
    return (
      user?.score?.currentCalibrationPerformanceCategory?.performanceCategory ||
      user?.score?.finalPerformanceCategory?.performanceCategory
    );
  }
};

const getCurrentScore = (tempCurrentScoreData, user) => {
  if (tempCurrentScoreData?.score) {
    return tempCurrentScoreData?.score;
  } else {
    return user?.score?.currentCalibration?.score || user?.score?.final?.score;
  }
};

const getTargetObj = (user) => {
  return {
    id: user?.userId,
    userId: user?.userId,
    name: user?.name,
    profilePicture: user?.profilePicture,
    jobTitle: user?.jobTitle,
    title: user?.title,
    subtitle: user?.subtitle,
    placementId: user?.placementId
  };
};

const modifyCurrentScoreObj = (data, isPerfCategory, user) => {
  let tempCurrentScoreData = { ...data };
  if (isPerfCategory) {
    tempCurrentScoreData.performanceCategory =
      getCurrentPerfomanceCategoryScore(tempCurrentScoreData, user);
  } else {
    tempCurrentScoreData.score = getCurrentScore(tempCurrentScoreData, user);
  }
  return tempCurrentScoreData;
};

export {
  getCurrentPerfomanceCategoryScore,
  getCurrentScore,
  getTargetObj,
  modifyCurrentScoreObj
};

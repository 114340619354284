import React, { useEffect, useRef, useState } from 'react';

import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import Config from 'utils/Config';
import { getCultureLocale } from 'utils/HelperUtils';

import Checkbox from 'components/shared/Checkbox';
import Modal from 'components/shared/modal/Modal';

import InlineDialogWrapper from './InlineDialogWrapper';

function ModalAddNewLabel({
  title,
  setTemporaryEditData,
  temporaryEditData,
  onConfirmAddData,
  handleChangeGroup,
  listAddedGroups,
  setListAddedGroups,
  listOfGroups,
  setModalAddOpen,
  setModalShowAllOpen,
  setSearchGroup,
  isReadOnly,
  totalGroup
}) {
  const { config } = useUser();
  const [buttonAddDisabled, setButtonAddDisabled] = useState(true);
  const setOfColor = [
    '#ff6b83',
    '#fdac45',
    '#fe0100',
    '#f51557',
    '#ab6fc9',
    '#5e20b3',
    '#4169e1',
    '#00c5f2',
    '#5bc4bd',
    '#67b70c'
  ];
  const [showAddGroupInline, setShowAddGroupInline] = useState(false);
  const refAddGroup = useRef();

  useClickOutside(refAddGroup, () => {
    setShowAddGroupInline(false);
  });

  useEffect(() => {
    if (!temporaryEditData?.title || !temporaryEditData?.colorCode) {
      setButtonAddDisabled(true);
    } else {
      setButtonAddDisabled(false);
    }
  }, [temporaryEditData]);

  const handleChangeAllGroup = (state) => {
    if (state) {
      setTemporaryEditData({
        ...temporaryEditData,
        isAllGroups: true
      });
    } else {
      setTemporaryEditData({
        ...temporaryEditData,
        isAllGroups: false
      });
    }
  };

  const handleChangeAnnouncement = (e) => {
    if (e) {
      setTemporaryEditData({
        ...temporaryEditData,
        labelType: 'announcement'
      });
    } else {
      setTemporaryEditData({
        ...temporaryEditData,
        labelType: 'casual'
      });
    }
  };

  return (
    <div className="modal-add" data-cy="modal-add">
      <Modal
        title={`${title} label`}
        withCloseIcon={false}
        className="admin-culture modal-add org-details-labels"
        withPrimaryBtn={{
          disabled: isReadOnly,
          title: 'Submit',
          dataCy: 'button-submit',
          onClick: () => onConfirmAddData(title)
        }}
        withSecondaryBtn={{
          title: 'Cancel',
          dataCy: 'cancel-submit-label',
          onClick: () => setModalAddOpen(false)
        }}
        eventOnClickClose={() => setModalAddOpen(false)}
      >
        <div className="body-add-modal">
          <form className="overflow-hidden">
            <div className="form-group">
              <p htmlFor="name">Label name</p>
              <input
                data-cy="input-label-name"
                onChange={(e) =>
                  setTemporaryEditData({
                    ...temporaryEditData,
                    title: e.target.value
                  })
                }
                disabled={isReadOnly}
                id="name"
                type="text"
                placeholder="e.g Communication"
                className="form-control"
                value={temporaryEditData?.title}
              />
            </div>

            <div className="form-group">
              <p>Select label color</p>
              <row className="badge-container" data-cy="input-label-color">
                <row>
                  {setOfColor.map((color, id) => (
                    <row>
                      <row className="radio-color" key={id}>
                        <input
                          type="radio"
                          name="badge-color"
                          checked={
                            temporaryEditData?.colorCode == color ? true : false
                          }
                          onChange={() =>
                            !isReadOnly &&
                            setTemporaryEditData({
                              ...temporaryEditData,
                              colorCode: color
                            })
                          }
                        />
                        <label
                          className="lbl"
                          data-color={`${color}`}
                          style={{ backgroundColor: `${color}` }}
                        ></label>
                      </row>
                    </row>
                  ))}
                </row>
              </row>
            </div>
            <p>Which groups that use this label?</p>
            <div className="with-announcement">
              <p className="description">
                {getCultureLocale(
                  'All labels are applicable for General group. Not selecting group will apply the label to General group only.'
                )}
              </p>
            </div>
            <ul className="list-selected-label selected-labels">
              {temporaryEditData?.isAllGroups && listAddedGroups.length > 0 ? (
                <div>
                  <li role="presentation" className="label-card selected">
                    <span data-cy="all-groups"> All Groups</span>
                  </li>
                  {!isReadOnly && (
                    <img
                      src={Config.asset.formalReview.icCloseGrey}
                      className="close-label"
                      onClick={() => {
                        setListAddedGroups([]);
                        handleChangeAllGroup(false);
                      }}
                    />
                  )}
                </div>
              ) : (
                listAddedGroups.length > 0 &&
                listAddedGroups.map((label, id) => {
                  if (id < 10) {
                    return (
                      <div>
                        <li
                          role="presentation"
                          key={id}
                          className="label-card selected"
                        >
                          <span>{label.name}</span>
                        </li>
                        {!isReadOnly && (
                          <img
                            src={Config.asset.formalReview.icCloseGrey}
                            className="close-label"
                            onClick={() => handleChangeGroup(label)}
                          />
                        )}
                      </div>
                    );
                  }
                  if (id === 10) {
                    return (
                      <>
                        <div>
                          <li
                            role="presentation"
                            key={id}
                            className="label-card selected"
                          >
                            <span>{`+${
                              listAddedGroups.length - 10
                            } more`}</span>
                          </li>
                        </div>
                        <div className="add-group-content">
                          <li
                            data-cy="show-all"
                            role="presentation"
                            className="label-card selected add-group"
                            onClick={() => {
                              setModalAddOpen(false), setModalShowAllOpen(true);
                            }}
                          >
                            Show All
                          </li>
                        </div>
                      </>
                    );
                  }
                  return null;
                })
              )}
              <div className="add-group-content" ref={refAddGroup}>
                {!isReadOnly && (
                  <li
                    data-cy="add-group"
                    role="presentation"
                    className="label-card selected add-group"
                    onClick={() => setShowAddGroupInline(!showAddGroupInline)}
                  >
                    Add group
                  </li>
                )}
                {showAddGroupInline && (
                  <InlineDialogWrapper
                    listOfGroups={listOfGroups}
                    selectedValue={listAddedGroups}
                    handleChange={setListAddedGroups}
                    isGroup={true}
                    handleChangeAllGroup={handleChangeAllGroup}
                    setShow={setShowAddGroupInline}
                    type={'modal'}
                    setSearchGroup={setSearchGroup}
                    totalGroup={totalGroup}
                  />
                )}
              </div>
            </ul>
            {config?.allowAnnouncementLabel !== 'none' && (
              <Checkbox
                id="input-label-check"
                onChange={(e) =>
                  handleChangeAnnouncement(e.currentTarget.checked)
                }
                checked={
                  temporaryEditData?.labelType == 'announcement' ? true : false
                }
                disabled={isReadOnly}
                dataCy="input-label-check"
              >
                <p className="ml-[16px] mb-[0px]">
                  Add label with announcement
                </p>
              </Checkbox>
            )}
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default ModalAddNewLabel;

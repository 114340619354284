import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import omit from 'lodash/omit';

import { getBehaviors, putBehaviorsModels } from 'client/adminClient.js';
import { useValueCompetency } from 'context/admin/ValueCompetencyContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

import ChildModelCard from './ChildModelCard';
import ShowMoreCard from './ShowMoreCard';
import SingleCardSkeletonLoading from './SingleCardSkeletonLoading';

export const ModelCard = ({ value, id, isReadOnly }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [modelActive, setModelActive] = useState(null);
  const [dataSingleParent, setDataSingleParent] = useState([]);
  const [singleLoading, setSingleLoading] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [nextOffset, setNextOffset] = useState(0);
  const [currentSize, setCurrentSize] = useState(0);
  const [groupName, setGroupName] = useState([]);
  const {
    setCurrentTab,
    getListGroup,
    paramsDictionaryOrModel,
    setSearchValue,
    currentTab,
    currentFilterTab,
    getValueOrCompetencyData,
    showNotificationMessage
  } = useValueCompetency();

  const handleOpenAccordion = async (id) => {
    let active = modelActive == id ? null : id;
    await _getKeyBehaviorData(active);
  };

  const archiveOrRestoreModel = async (id) => {
    let text = currentFilterTab == 'archive' ? 'Restore' : 'Archive';
    let body = {
      modelIds: [id],
      state: currentFilterTab == 'archive' ? 'active' : 'archive'
    };

    const { data, error } = await putBehaviorsModels(body);
    if (data) {
      showNotificationMessage(`${text} Successfully!`);
      getValueOrCompetencyData(pathname);
    }
  };

  const _getKeyBehaviorData = async (id, refreshState) => {
    if (
      (dataSingleParent.length == 0 && !isDataEmpty) ||
      refreshState == 'refresh'
    ) {
      let params = paramsDictionaryOrModel(
        pathname,
        { modelId: id },
        'dictionary',
        true,
        false
      );
      let _params = omit(params, 'q');
      setSingleLoading(true);
      const { data, pagination } = await getBehaviors(_params);
      if (data) {
        setDataSingleParent(data);
        setHasMore(pagination.nextOffset == null ? false : true);
        setNextOffset(pagination.nextOffset);
        setCurrentSize(pagination.currentSize + currentSize);
        setSingleLoading(false);
      }
      if (data.length == 0) {
        setIsDataEmpty(true);
        setSingleLoading(false);
      }
    }
    setModelActive(id);
  };

  const editCompetencyModelPage = async () => {
    let routeName = pathname.includes('competencies')
      ? 'competencies'
      : 'values';
    history.replace(`/edit-${routeName}-model/${id}`);
    setCurrentTab('model');
    setSearchValue('');
  };

  const loadMore = async () => {
    const _params = paramsDictionaryOrModel(
      pathname,
      {
        modelId: id,
        offset: nextOffset
      },
      'dictionary',
      true
    );
    setLoadMoreLoading(true);
    const { data, pagination } = await getBehaviors(_params);
    if (data) {
      setDataSingleParent(dataSingleParent.concat(data));
      setHasMore(pagination.nextOffset == null ? false : true);
      setNextOffset(pagination.nextOffset);
      setCurrentSize(pagination.currentSize + currentSize);
      setLoadMoreLoading(false);
    }
  };

  useEffect(() => {
    setModelActive(null);
    setDataSingleParent([]);

    if (value?.behaviorGroups) {
      let groupNameTemp = [...groupName];
      value?.behaviorGroups?.map((group, index) => {
        groupNameTemp.push(group.name);
      });
      setGroupName(groupNameTemp);
    }
  }, [currentFilterTab]);

  let isGroupEmpty = value?.behaviorGroups?.length == 0;

  return (
    <div className="pt-[16px]">
      <div
        data-cy={'list-parent-model-' + id}
        onClick={() => handleOpenAccordion(id)}
        className="row-table-competencies-values-model bg-n-000"
      >
        <div className="grid table-values px-[16px] py-[16px] relative">
          <div>
            <div className="grid-header">
              <p className="typography-h500">{value.title}</p>
              <p className="typography-secondary">
                {value.description || 'No description'}
              </p>
            </div>
            <div className="grid-body mt-[24px]">
              <div>
                <p className="typography-secondary typography-h100 mb-[8px]">
                  Type
                </p>
                <p className="capitalize">
                  {value?.subType == 'work_attribute'
                    ? getObjectiveLocale('Work Attribute')
                    : getObjectiveLocale(value.behaviorModelType)}
                </p>
              </div>
              <div>
                <p className="typography-secondary typography-h100 mb-[8px]">
                  Status
                </p>
                <p>{value.deleted ? 'Archived' : 'Active'}</p>
              </div>
              <div
                data-cy={`competencies-values-reviews-groups-${id}`}
                onClick={(e) => getListGroup(e, value.behaviorGroups)}
              >
                <p className="typography-secondary typography-h100 mb-[8px]">
                  Used in Groups
                </p>
                <p className="truncate">
                  {groupName.length > 0 ? groupName.join(', ') : 'No Group'}
                </p>
              </div>
            </div>
          </div>
          <div className="grid-menu">
            {currentFilterTab == 'active' && !isReadOnly && (
              <div className="content-edit justify-center height-fit-content">
                <p className="tooltip">
                  Edit{' '}
                  {pathname.includes('competencies') ? 'competency' : 'value'}{' '}
                  model
                </p>
                <SVGIcon
                  dataCy={'competencies-values-model-edit-button-' + id}
                  customClass={'icon-edit'}
                  onClick={() => editCompetencyModelPage()}
                  size="24"
                  iconName="icon-create"
                  fillColor="var(--n-600)"
                />
              </div>
            )}
            {!isReadOnly && (
              <div className="content-edit justify-center ml-[24px] height-fit-content">
                <p className="tooltip">
                  {currentFilterTab == 'active' ? 'Archived' : 'Restore'}
                </p>
                <SVGIcon
                  dataCy={`${currentTab}-${currentFilterTab}-${id}`}
                  customClass={'icon-archive'}
                  onClick={() => archiveOrRestoreModel(id)}
                  size="24"
                  iconName={value.deleted ? 'icon-reply' : 'icon-archive'}
                  fillColor="var(--n-600)"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <table className="container-list-competencies-values">
        <tbody className="table-body-model">
          {singleLoading && (
            <SingleCardSkeletonLoading
              firstClassName={'row-table-competencies-values-model'}
              secondClassName={`table-name-model`}
              thirdClassName={`table-description-model`}
            />
          )}

          {!singleLoading && modelActive == id && dataSingleParent && (
            <React.Fragment>
              <tr valign="center" className="row-divider-models">
                <td>
                  <p className="px-[24px] py-[8px] mb-[0px]">
                    {pathname.includes('competencies') ? 'COMPETENCY' : 'VALUE'}{' '}
                    LIST
                  </p>
                </td>
                <td>
                  <p className="py-[8px] mb-[0px]">DESCRIPTION</p>
                </td>
                <td />
                <td />
                <td />
              </tr>

              {(!value.hasBehaviors || isDataEmpty) && (
                <tr className="table-row-empty-state">
                  <td colSpan="5">No data is available</td>
                </tr>
              )}

              {value.hasBehaviors &&
                dataSingleParent.map((competency, idx) => {
                  return (
                    <ChildModelCard
                      value={competency}
                      id={competency.id}
                      modelId={id}
                      index={idx}
                      key={idx}
                    />
                  );
                })}

              {!loadMoreLoading && hasMore && modelActive == id && (
                <ShowMoreCard
                  parentClass={'row-table-child-model'}
                  colSpan={'5'}
                  funcOnClick={loadMore}
                />
              )}

              {loadMoreLoading && (
                <SingleCardSkeletonLoading
                  firstClassName={'row-table-competencies-values-model'}
                  secondClassName={`table-name-model`}
                  thirdClassName={`table-description-model`}
                />
              )}
            </React.Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import {
  cancelRequestGroup,
  getListGroup,
  getStatus,
  requestJoinGroup,
  searchGroup
} from 'client/CultureClient';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import Config from 'utils/Config';
import { getCultureLocale, loadMoreValidator } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import SearchBar from 'components/shared/SearchBar';
import Modal from 'components/shared/modal/Modal';
import { trackEvent } from 'src/utils/AnalyticUtils';

import ModalManageGroup from '../modal/culture/ModalManageGroup';
import GenerateListGroupSection from './GenerateListGroupSection';
import './group-section';

function GroupSection({
  typeGroupList,
  groupDetail,
  setFilter,
  setGroupDetail
}) {
  const history = useHistory();
  const {
    config: { featureConfig, permissions },
    user
  } = useUser();
  const isReadOnly = permissions?.cultureFeedsTimelineSee === 'read_only';
  const { reloadGroups } = useReload();

  const [isModalOpen, setIsModalOpen] = useState({ type: '', isShow: false });
  const [isYourGroupOpen, setIsYourGroupOpen] = useState(true);
  const [isAllGroupOpen, setIsAllGroupOpen] = useState(false);
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [olderThan, setOlderThan] = useState(null);

  const [listYourGroup, setListYourGroup] = useState([]);
  const [listAllGroup, setListAllGroup] = useState([]);
  const [joinGroupData, setJoinGroupData] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(groupDetail);
  const [searchValue, setSearchValue] = useState(null);

  const debounceSearchValue = useDebounce(searchValue, 2000);

  const checkGroup = async (selectedGroup) => {
    const properties = {
      'group name': selectedGroup.name,
      'group type': selectedGroup.groupType,
      source: 'direct'
    };

    trackEvent({
      event: 'open group page',
      eventProperties: properties,
      env: 'culture'
    });
  };

  const _getListYourGroup = async () => {
    const params = {
      limit: 20
    };
    const { data } = await getListGroup(params);

    if (data) {
      setListYourGroup(data);
    }
  };

  const _getListAllGroup = async (isNewSearch) => {
    setOnLoadMore(true);
    let params = {
      limit: 20,
      q: searchValue,
      lastId: isNewSearch ? 0 : olderThan
    };
    const { data, isSuccess } = await searchGroup(params);

    if (isSuccess && data.length > 0) {
      isNewSearch
        ? setListAllGroup(data)
        : setListAllGroup([...listAllGroup, ...data]);
      setOlderThan(data[data.length - 1].id);
    } else {
      isNewSearch && setListAllGroup([]);
      setOlderThan(0);
    }
    setOnLoadMore(false);
  };

  const changeGroup = (changedGroup) => {
    setGroupDetail(changedGroup);
    setSelectedGroup(changedGroup);
    setFilter('group-recent');
    const scrollableDiv = document.getElementById('scroll-feeds');
    if (scrollableDiv) {
      scrollableDiv.scrollTop = 0;
    }
    history.push(`/timeline/group/${changedGroup?.id}`);
  };

  const checkExistingGroup = async (group) => {
    const { data } = await getStatus(group.id);

    if (data) {
      if (data.membershipStatus === 'member') {
        history.push(`/timeline/group/${group?.id}`);
      } else {
        setJoinGroupData(data);
        setIsModalOpen({ type: 'join', isShow: true });
      }
    }
  };

  const requestGroup = async (id, status) => {
    const { isSuccess } =
      status === 'pending'
        ? await cancelRequestGroup(id)
        : await requestJoinGroup(id);

    if (isSuccess) {
      setIsModalOpen({ type: '', isShow: false });
    }
  };

  const onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      setOnLoadMore(true);
      await _getListAllGroup(false);
      setOnLoadMore(false);
    };

    const hasMore = olderThan ? true : false;

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 50, () => {
        loadMore();
      });
    }
  };

  const back = (e) => {
    e.stopPropagation();
    history.replace('/timeline');
  };

  useEffect(() => {
    _getListAllGroup(true);
    if (!isEmpty(debounceSearchValue)) {
      setIsAllGroupOpen(true);
    }
  }, [debounceSearchValue]);

  useEffect(() => {
    _getListYourGroup();
  }, [reloadGroups]);

  useEffect(() => {
    checkGroup(groupDetail);
    setSelectedGroup(groupDetail);
  }, [groupDetail?.id]);

  return (
    <div
      className={`group-card flex flex-col w-[280px] min-w-[240px]`}
      data-cy="group-detail-section"
    >
      <div
        className="cursor-pointer typography-button text-n-600 flex items-center my-[16px]"
        onClick={(e) => back(e)}
      >
        <SVGIcon
          iconName="icon-keyboard_arrow_left"
          size="24"
          fillColor="var(--n-600)"
        />
        {getCultureLocale('Back')}
      </div>
      <SearchBar
        fullWidth={true}
        placeholder="Search a group..."
        onChange={(e) => setSearchValue(e.target.value)}
        dataCy="search-group"
      />
      {((featureConfig?.createGroupBy === 'admin' &&
        user.cultureRole === 'culture_admin') ||
        featureConfig?.createGroupBy === 'all') && (
        <Button.Secondary
          disabled={isReadOnly}
          fullWidth
          customClass="mt-[16px]"
          onClick={() => setIsModalOpen({ type: 'create', isShow: true })}
          datacy="create-new-group"
        >
          <SVGIcon
            iconName="icon-add"
            size="16"
            fillColor="var(--base-600)"
            customClass="mr-[8px]"
          />
          {getCultureLocale('Create new group')}
        </Button.Secondary>
      )}
      <div className="border-section mt-[24px] pt-[24px] flex flex-col h-4/5">
        {isEmpty(searchValue) ? (
          <>
            {/* Your Group */}
            <GenerateListGroupSection
              title="My Groups"
              isOpen={isYourGroupOpen}
              isOtherOpen={isAllGroupOpen}
              setIsOpen={setIsYourGroupOpen}
              data={listYourGroup}
              clickFunction={(group) => changeGroup(group)}
              selectedGroup={selectedGroup}
              isReadOnly={isReadOnly}
              ableToSee={true}
            />

            {/* All Group */}

            <GenerateListGroupSection
              title="All Groups"
              isOpen={isAllGroupOpen}
              isOtherOpen={isYourGroupOpen}
              setIsOpen={setIsAllGroupOpen}
              data={listAllGroup}
              clickFunction={(group) => checkExistingGroup(group)}
              selectedGroup={selectedGroup}
              onScroll={onScroll}
              isReadOnly={isReadOnly}
            />
          </>
        ) : (
          <GenerateListGroupSection
            title="Search Result"
            isOpen={isAllGroupOpen}
            isOtherOpen={false}
            setIsOpen={setIsAllGroupOpen}
            data={listAllGroup}
            clickFunction={(group) => checkExistingGroup(group)}
            selectedGroup={selectedGroup}
            onScroll={onScroll}
            isUseChevron={false}
            isReadOnly={isReadOnly}
          />
        )}
      </div>
      {isModalOpen.type === 'create' && isModalOpen.isShow && !isReadOnly && (
        <ModalManageGroup
          closeModal={() => setIsModalOpen({ type: '', isShow: false })}
          typeGroupList={typeGroupList}
          isReadOnly={isReadOnly}
          typeManage="create"
        />
      )}
      {isModalOpen.type === 'join' && isModalOpen.isShow && (
        <Modal
          dataCyModal="join-modal"
          title={joinGroupData.name}
          description={`${joinGroupData.groupType.replace(
            /_/g,
            ' '
          )} ${getCultureLocale('group')} - ${
            joinGroupData.memberCount
          } ${getCultureLocale('members')}`}
          className="modal-create-group"
          eventOnClickClose={() => setIsModalOpen({ type: '', isShow: false })}
          withPrimaryBtn={{
            disabled: isReadOnly,
            title:
              joinGroupData.membershipStatus === 'pending'
                ? 'Cancel request'
                : 'Join group',
            onClick: () =>
              requestGroup(joinGroupData.id, joinGroupData.membershipStatus)
          }}
        >
          <div className="relative">
            <AWSImage
              src={
                joinGroupData?.groupPicture?.secureUrl
                  ? joinGroupData?.groupPicture?.secureUrl
                  : Config.asset.general.defaultGroupCover
              }
              onErrorImage={Config.asset.general.defaultGroupCover}
              className="w-full h-[150px] cover-photo"
            />
            <div className="typography-h400 text-n-000 center-component-modal w-full flex justify-center items-center text-align-center">
              {getCultureLocale(
                'You are not member of this group. Join this group to see the contents.'
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default GroupSection;

import React, { useEffect } from 'react';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

function QuestionComponent({
  question,
  index,
  onClick,
  onChange,
  onDelete,
  haveSubmitted,
  wordingPolls
}) {
  return (
    <div className="question-component">
      <div className="question-title">
        <p>Question {index + 1}</p>
        <Button.Tertiary
          isSecondary
          onClick={() => onDelete(index)}
          datacy={`delete-question-${index}`}
        >
          <SVGIcon
            size="24"
            fillColor="var(--base-600)"
            iconName="icon-delete"
            customClass="mr-[4px]"
          />
          Delete
        </Button.Tertiary>
      </div>
      <div className="questions-name">
        <div className="form-group w-4/5 mr-[24px]">
          <p className="typography-h100 typography-secondary mb-[8px]">
            Question
          </p>
          <input
            type="text"
            className="form-control"
            data-cy="question"
            placeholder="Enter your question"
            value={question.question}
            onChange={(e) => onChange(e, 'question', index)}
          />
          {haveSubmitted && !question.question && (
            <p className="error-message pl-[1px] typography-h100 mt-[8px] text-r-600">
              This cannot be blank.
            </p>
          )}
        </div>
        <div className="form-group">
          <p className="typography-h100 typography-secondary mb-[8px]">
            Points
          </p>
          <input
            type="text"
            className="form-control"
            data-cy="question-point"
            value={question.questionPoint}
            onChange={(e) => onChange(e, 'questionPoint', index)}
          />
          {haveSubmitted && !question.questionPoint && (
            <p className="error-message pl-[1px] typography-h100 mt-[8px] text-r-600">
              This cannot be blank.
            </p>
          )}
        </div>
      </div>
      <div className="question-options">
        <div className="title">
          <p className="typography-h100 typography-secondary mb-[8px] w-[87%]">
            Options
          </p>
          <p className="typography-h100 typography-secondary mb-[8px] w-[10%] h-[15px] text-center">
            Answer
          </p>
          <p className="w-[3%]"></p>
        </div>
        {question[`${wordingPolls}`]?.map((options, id) => (
          <div className="mb-[16px]">
            <div className="list-question flex-center-center">
              <input
                type="text"
                className="form-control w-[87%]"
                placeholder="Enter an answer option"
                value={options.option}
                onChange={(e) => onChange(e, 'option', index, true, id)}
                data-cy={`answer-${id}`}
              />
              <input
                type="radio"
                className="form-control w-[10%]"
                name={`answer-correct-${index}`}
                checked={options.isCorrectOption}
                onChange={(e) =>
                  onChange(e, 'isCorrectOption', index, true, id)
                }
                data-cy={`correct-answer-${id}`}
              />
              <p className="w-[3%]">
                <SVGIcon
                  size="16"
                  fillColor="var(--n-600)"
                  iconName="icon-delete"
                  onClick={() => onDelete(index, id)}
                  customClass="icon-delete mr-[4px]"
                  dataCy={`delete-answer-${id}`}
                />
              </p>
            </div>
            {haveSubmitted && !options.option && (
              <p className="error-message typography-h100 mt-[8px] pl-[1px] text-r-600">
                This cannot be blank.
              </p>
            )}
          </div>
        ))}
        <div className="w-[87%]">
          <Button.Secondary
            customClass="w-full flex-center-center"
            onClick={() => onClick(index)}
          >
            <SVGIcon
              size="24"
              fillColor="var(--base-600)"
              iconName="icon-add"
              customClass="mr-[4px]"
            />
            Add another option
          </Button.Secondary>
        </div>
      </div>
    </div>
  );
}

export default QuestionComponent;

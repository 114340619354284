const bgColorDict = {
  "E6004E": "bg-r-200",
  "e44832": "bg-r-200",
  "ba3029": "bg-r-200",
  "E53303": "bg-r-200",
  "ED2E1D": "bg-r-200",
  "f87929": "bg-y-200",
  "fbc600": "bg-y-200",
  "fca100": "bg-y-200",
  "f9dc5b": "bg-y-200",
  "FFC947": "bg-y-200",
  "EDA01D": "bg-y-200",
  "038337": "bg-g-200",
  "3ca95c": "bg-g-200",
  "7ed321": "bg-g-200",
  "1DED31": "bg-g-200",
  "0B9193": "bg-b-200",
  "50acd5": "bg-b-200",
  "2480cc": "bg-b-200",
  "1D4DED": "bg-b-200",
  "5E20B3": "bg-base-200",
  "9362ba": "bg-base-200",
  "553686": "bg-base-200",
  "5417CF": "bg-base-200"
}

const bgRatingTemplate = {
  "E6004E": "bg-r-200",
  "e44832": "bg-r-200",
  "ba3029": "bg-r-200",
  "E53303": "bg-r-200",
  "ED2E1D": "bg-r-200",
  "f87929": "bg-y-600",
  "fbc600": "bg-y-600",
  "fca100": "bg-y-600",
  "f9dc5b": "bg-y-600",
  "FFC947": "bg-y-600",
  "EDA01D": "bg-y-600",
  "038337": "bg-g-600",
  "3ca95c": "bg-g-600",
  "7ed321": "bg-g-600",
  "1DED31": "bg-g-600",
  "0B9193": "bg-b-600",
  "50acd5": "bg-b-600",
  "2480cc": "bg-b-600",
  "1D4DED": "bg-b-600",
  "5E20B3": "bg-base-600",
  "9362ba": "bg-base-600",
  "553686": "bg-base-600",
  "5417CF": "bg-v-600"
}

const fontColorDict = {
  "E6004E": "text-r-800",
  "e44832": "text-r-800",
  "ba3029": "text-r-800",
  "E53303": "text-r-800",
  "ED2E1D": "text-r-800",
  "f87929": "text-y-800",
  "fbc600": "text-y-800",
  "fca100": "text-y-800",
  "f9dc5b": "text-y-800",
  "FFC947": "text-y-800",
  "EDA01D": "text-y-800",
  "038337": "text-g-800",
  "3ca95c": "text-g-800",
  "7ed321": "text-g-800",
  "1DED31": "text-g-800",
  "0B9193": "text-b-800",
  "50acd5": "text-b-800",
  "2480cc": "text-b-800",
  "1D4DED": "text-b-800",
  "5E20B3": "text-base-800",
  "9362ba": "text-base-800",
  "553686": "text-base-800",
  "5417CF": "text-base-800"
}

const resultColorDict = {
  "E6004E": "text-r-700",
  "e44832": "text-r-700",
  "ba3029": "text-r-700",
  "E53303": "text-r-700",
  "ED2E1D": "text-r-700",
  "f87929": "text-y-700",
  "fbc600": "text-y-700",
  "fca100": "text-y-700",
  "f9dc5b": "text-y-700",
  "FFC947": "text-y-700",
  "EDA01D": "text-y-700",
  "038337": "text-g-700",
  "3ca95c": "text-g-700",
  "7ed321": "text-g-700",
  "1DED31": "text-g-700",
  "0B9193": "text-b-700",
  "50acd5": "text-b-700",
  "2480cc": "text-b-700",
  "1D4DED": "text-b-700",
  "5E20B3": "text-v-600",
  "9362ba": "text-v-600",
  "553686": "text-v-600",
  "5417CF": "text-v-600"
}

export { bgColorDict, fontColorDict, resultColorDict, bgRatingTemplate }
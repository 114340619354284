import React from 'react';

import { getCustomAttributeList } from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Table from 'components/design-system/table/Table';

const WeightScoringVerify = ({ cycleData }) => {
  const tableHeaders = [
    { name: 'Phase', widthClass: 'w-[248px]' },
    { name: getObjectiveLocale('Indirect'), widthClass: 'w-[96px]' },
    { name: getObjectiveLocale('Manager'), widthClass: 'w-[96px]' },
    { name: getObjectiveLocale('Upward'), widthClass: 'w-[96px]' },
    { name: getObjectiveLocale('Peer'), widthClass: 'w-[96px]' },
    { name: getObjectiveLocale('Self'), widthClass: 'w-[96px]' }
  ];

  const phases = [
    'selfReview',
    'managerReview',
    'indirectManagerReview',
    'upwardReview',
    'peerReview'
  ];
  const customAttributeTrack = getCustomAttributeList(cycleData, phases);

  const getPercentageScoringPerPhase = (scoringType, phase) => {
    let getWeight = cycleData[phase]?.trackConfigs?.find(
      (track) => track.type === scoringType
    )?.weight;
    return getWeight ? `${getWeight}%` : '--';
  };

  let tableData = [
    {
      name: 'Goal final scoring',
      indirectWeight: getPercentageScoringPerPhase(
        'goals_scoring',
        'indirectManagerReview'
      ),
      managerWeight: getPercentageScoringPerPhase(
        'goals_scoring',
        'managerReview'
      ),
      upwardWeight: getPercentageScoringPerPhase(
        'goals_scoring',
        'upwardReview'
      ),
      peerWeight: getPercentageScoringPerPhase('goals_scoring', 'peerReview'),
      selfWeight: getPercentageScoringPerPhase('goals_scoring', 'selfReview')
    },
    {
      name: 'Task final scoring',
      indirectWeight: getPercentageScoringPerPhase(
        'tasks_scoring',
        'indirectManagerReview'
      ),
      managerWeight: getPercentageScoringPerPhase(
        'tasks_scoring',
        'managerReview'
      ),
      upwardWeight: getPercentageScoringPerPhase(
        'tasks_scoring',
        'upwardReview'
      ),
      peerWeight: getPercentageScoringPerPhase('tasks_scoring', 'peerReview'),
      selfWeight: getPercentageScoringPerPhase('tasks_scoring', 'selfReview')
    },
    {
      name: 'Competency final scoring',
      indirectWeight: getPercentageScoringPerPhase(
        'competencies_scoring',
        'indirectManagerReview'
      ),
      managerWeight: getPercentageScoringPerPhase(
        'competencies_scoring',
        'managerReview'
      ),
      upwardWeight: getPercentageScoringPerPhase(
        'competencies_scoring',
        'upwardReview'
      ),
      peerWeight: getPercentageScoringPerPhase(
        'competencies_scoring',
        'peerReview'
      ),
      selfWeight: getPercentageScoringPerPhase(
        'competencies_scoring',
        'selfReview'
      )
    },
    {
      name: 'Value final scoring',
      indirectWeight: getPercentageScoringPerPhase(
        'values_scoring',
        'indirectManagerReview'
      ),
      managerWeight: getPercentageScoringPerPhase(
        'values_scoring',
        'managerReview'
      ),
      upwardWeight: getPercentageScoringPerPhase(
        'values_scoring',
        'upwardReview'
      ),
      peerWeight: getPercentageScoringPerPhase('values_scoring', 'peerReview'),
      selfWeight: getPercentageScoringPerPhase('values_scoring', 'selfReview')
    }
  ];

  customAttributeTrack.map((attr) => {
    tableData.push({
      name: attr?.name,
      indirectWeight: getPercentageScoringPerPhase(
        'review_aspects_scoring',
        'indirectManagerReview'
      ),
      managerWeight: getPercentageScoringPerPhase(
        'review_aspects_scoring',
        'managerReview'
      ),
      upwardWeight: getPercentageScoringPerPhase(
        'review_aspects_scoring',
        'upwardReview'
      ),
      peerWeight: getPercentageScoringPerPhase(
        'review_aspects_scoring',
        'peerReview'
      ),
      selfWeight: getPercentageScoringPerPhase(
        'review_aspects_scoring',
        'selfReview'
      )
    });
  });

  return (
    <div className="border-bottom-n-400 py-[40px]">
      <h4 className="typography-h500 mb-[28px]">Weight scoring</h4>
      <Table headers={tableHeaders} customClassHeader="py-[8px]">
        {tableData?.map((data, index) => (
          <Table.Row key={`table-row-${index}`}>
            <Table.Column key="table-column-0">{data?.name}</Table.Column>
            <Table.Column key="table-column-1">
              {data?.indirectWeight}
            </Table.Column>
            <Table.Column key="table-column-2">
              {data?.managerWeight}
            </Table.Column>
            <Table.Column key="table-column-3">
              {data?.upwardWeight}
            </Table.Column>
            <Table.Column key="table-column-4">{data?.peerWeight}</Table.Column>
            <Table.Column key="table-column-5">{data?.selfWeight}</Table.Column>
          </Table.Row>
        ))}
      </Table>
    </div>
  );
};

export default WeightScoringVerify;

import React from 'react';

import Shimmer from 'components/design-system/shimmer/Shimmer';

const CalibrationModalSkeleton = () => {
  return (
    <>
      <div className="flex justify-between items-center h-[56px] relative shadow-raised bg-transparent py-[0px] px-[0px] mb-[16px] rounded-[8px]">
        <div className="flex items-center py-[12px] pl-[16px]">
          <Shimmer circle width={32} height={32} />
          <div className="ml-[16px] flex flex-col">
            <Shimmer width={295} height={16} customClass="mb-[4px]" />
            <Shimmer width={160} height={16} />
          </div>
        </div>
        <div
          className={`flex flex-col justify-center items-end h-full pr-[16px] pl-[24px] rounded-[8px]`}
        >
          <Shimmer width={40} height={16} customClass="mb-[4px]" />
          <Shimmer width={80} height={16} />
        </div>
      </div>
      <div className="modal-body">
        <Shimmer width={200} height={16} />
        <Shimmer width={'100%'} height={32} customClass="mb-[16px]" />
        <Shimmer width={200} height={16} />
        <Shimmer width={'100%'} height={80} customClass="mb-[16px]" />
      </div>
    </>
  );
};

export default CalibrationModalSkeleton;

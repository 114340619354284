import React from 'react';

import Avatar from 'components/shared/Avatar';
import { getExtraInformation } from 'src/utils/HelperUtils';

const ProfileEvidence = ({ profileData }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <Avatar
          src={profileData?.profilePicture || null}
          name={profileData?.name}
          size={24}
        />
        <div className="ml-[16px]">
          <h6 className="typography-h200 text-n-900 mb-[0px]">
            {profileData?.name}
          </h6>
          <h6 className="typography-h100 mb-[0px] text-n-800">
            {getExtraInformation(profileData?.title, profileData?.subtitle)}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default ProfileEvidence;

import React from 'react';

import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';

import HeaderPage from 'components/admin/HeaderPage';
import Zoomer from 'components/shared/Zoomer';
import Sidebar from 'components/sidebar/Sidebar';
import ErrorBoundary from 'pages/ErrorBoundary';

import './SubordinateTree.scss';
import SubordinateTreeCard from './SubordinateTreeCard';

function SubordinateTree() {
  const { match } = useUrl();
  const { user } = useUser();

  return (
    <>
      <div className="subordinate-tree" id="subordinate">
        <HeaderPage
          isHeaderComposer={true}
          titlePage="Subordinates' Goal Tree"
          backToUrl="/goals/reports/direct"
        />
        <div className="list-view-container tree-container relative">
          <div className="overflow-alignment-tree goal-tree-page">
            <Zoomer />
            <div className="goal-tree-super-parent pt-[80px]">
              <SubordinateTreeCard user={user} />
            </div>
          </div>
        </div>
      </div>
      <React.Suspense fallback={<div></div>}>
        {match && (
          <div className="sidebar-my-goals">
            <ErrorBoundary>
              <Sidebar />
            </ErrorBoundary>
          </div>
        )}
      </React.Suspense>
    </>
  );
}

export default SubordinateTree;

import React from 'react';

import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';

import { useUser } from 'context/UserContext';
import { capitalizeSentence, getObjectiveLocale } from 'utils/HelperUtils';

function DetailsInfoProfile({ userInfo }) {
  const { config } = useUser();

  const profilePositionDetails = config?.profilePositionDetails || [];
  const profileRegion = config?.profileRegion || [];

  const listPositionDetails = [...profilePositionDetails, ...profileRegion]
    ?.map((opt) => {
      return {
        title: capitalizeSentence(opt.replace('_', ' ')),
        val: userInfo?.[camelCase(opt)],
        isVisible: !isEmpty(userInfo?.[camelCase(opt)])
      };
    })
    .filter((opt) => opt.isVisible);

  const listRegion = profileRegion?.map((opt) => {
    return {
      title: capitalizeSentence(opt.replace('_', ' ')),
      val: userInfo?.[camelCase(opt)]
    };
  });

  const listTopDetail = merge(listPositionDetails, listRegion);

  const listPositionBackground = config?.profilePositionBackground?.map(
    (opt) => {
      return {
        title: capitalizeSentence(opt.replace('_', ' ')),
        val: userInfo?.[camelCase(opt)]
      };
    }
  );

  return (
    <div className="wrapper-details bg-n-000">
      {listTopDetail?.length > 0 && (
        <>
          <p className="title-details typography-h300 text-n-600 uppercase mb-[32px]">
            {getObjectiveLocale('Position Details')}
          </p>
          {listTopDetail?.map((pos, index) => (
            <div className="info-background flex mb-[32px]" key={index}>
              <p className="typography-paragraph text-n-800 w-[216px] mr-[32px]">
                {getObjectiveLocale(pos.title)}
              </p>
              <p className="typography-paragraph">{pos.val ? pos.val : '-'}</p>
            </div>
          ))}
        </>
      )}
      {listTopDetail?.length > 0 && listPositionBackground?.length > 0 && (
        <div className="divider mb-[40px]" />
      )}
      {listPositionBackground?.length > 0 && (
        <>
          <p className="title-details typography-h300 text-n-600 uppercase mb-[32px]">
            {getObjectiveLocale('Position Background')}
          </p>
          {listPositionBackground?.map((pos, index) => (
            <div className="info-background flex mb-[32px]" key={index}>
              <p className="typography-paragraph text-n-800 w-[216px] mr-[32px]">
                {getObjectiveLocale(pos.title)}
              </p>
              <p className="typography-paragraph">{pos.val ? pos.val : '-'}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default DetailsInfoProfile;

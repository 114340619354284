import React from 'react';

import { getNumberLocale } from 'utils/HelperUtils';
import {
  whichBgColorClassName,
  whichColor,
  whichStatus
} from 'utils/ObjectivesHelper';

import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';

const OverallProgressBar = ({
  progressPercentage,
  colorHex,
  widthBar = 155
}) => {
  const objProgressBar = {
    progress: progressPercentage >= 0 ? parseInt(progressPercentage) : 0,
    width: widthBar,
    height: 12,
    primaryColor: whichBgColorClassName(colorHex),
    fullFirst: true
  };
  return (
    <>
      <div className="flex mt-[16px] mb-[4px]">
        <span className="flex flex-row items-end">
          <p
            className="flex-center-center typography-h600"
            data-cy="overall-progress-percentage"
          >
            {getNumberLocale(progressPercentage)}
          </p>
          <p className="overall-percentage typography-h400 line-height-20">%</p>
        </span>
        <span
          data-cy="overall-progress-status"
          className={`mt-[4px] pl-[8px] typography-h400 overall-status whitespace-nowrap ${whichColor[colorHex]}`}
        >
          &nbsp;{whichStatus[colorHex]}
        </span>
      </div>
      <LineProgressBar {...objProgressBar} dataCy="overall-progressbar" />
    </>
  );
};

export default OverallProgressBar;

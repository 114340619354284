import React from 'react';

import Config from 'utils/Config';

import Avatar from 'components/shared/Avatar';

import './_profile-section.scss';

function ProfileSection({
  name,
  profilePicture,
  useNotif,
  status,
  desc,
  totalOwners,
  showDescription = true,
  avatarSize = 32,
  nameSize = 'h400',
  customNameJobSectionClass,
  customWrapperClass = ''
}) {
  const editedName = totalOwners > 1 ? `${totalOwners} Owners` : name;
  return (
    <div className={`profile-detail ${customWrapperClass}`}>
      {
        <Avatar
          src={
            totalOwners > 1
              ? Config.asset.objective.iconOwnerGroup
              : profilePicture
          }
          name={name}
          size={avatarSize}
          dataCy="goal-card-owner-image"
          usePortal="shared-tooltip"
        />
      }
      {useNotif && (
        <div
          className={`min-w-[10px] min-h-[10px] rounded-[50px] circle-notification ${
            status === 'manageable' ? 'bg-g-600' : 'bg-r-600'
          }`}
        />
      )}
      {showDescription && (
        <div
          className={`name-job-section ${
            customNameJobSectionClass ? customNameJobSectionClass : ''
          }`}
          data-cy="name-job-section"
        >
          <div
            className={`typography-${nameSize} name multiline-text-1`}
            data-cy="profile-name"
          >
            {editedName}
          </div>
          {desc && (
            <div
              className="typography-h100 job multiline-text-1"
              data-cy="profile-desc"
            >
              {desc}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ProfileSection;

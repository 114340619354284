import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';

import { submitFormalReview } from 'client/FormalReviewClient';
import { getPerformanceRating } from 'client/ObjectivesClient';
import { submitPerformanceRating } from 'client/PerformanceRating';
import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import RatingReview from 'components/formal-review/performance-rating/RatingReview';
import SkillRecognitionReview from 'components/formal-review/performance-rating/SkillRecognitionReview';
import Summary from 'components/formal-review/performance-rating/Summary';
import Objectives from 'components/objectives/Objectives';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

function PerformanceRating({
  type,
  target,
  assignmentId,
  history,
  isTeamCycle
}) {
  const [_, { getSingleTrack }] = useFormalReview();
  let track = getSingleTrack(type);

  const [currentIndex, setCurrentIndex] = useState(
    track.answers.option?.id ? 5 : 0
  );
  const [selectedRating, setSelectedRating] = useState(
    track.answers.option?.id || null
  );
  const [ratingComment, setRatingComment] = useState(
    track.answers.ratingComment
  );
  const [selectedRecognitionSOL, setSelectedRecognitionSOL] = useState(
    track.answers.recognitionValue
  );
  const [recognitionSOLComment, setRecognitionSOLComment] = useState(
    track.answers.recognitionValueComment
  );
  const [selectedFeedbackSOL, setSelectedFeedbackSOL] = useState(
    track.answers.feedbackValue
  );
  const [feedbackSOLComment, setFeedbackSOLComment] = useState(
    track.answers.feedbackValueComment
  );
  const [selectedRecognitionSkill, setSelectedRecognitionSkill] = useState(
    track.answers.recognitionCompetency
  );
  const [recognitionSkillComment, setRecognitionSkillComment] = useState(
    track.answers.recognitionCompetencyComment
  );
  const [selectedFeedbackSkill, setSelectedFeedbackSkill] = useState(
    track.answers.feedbackCompetency
  );
  const [feedbackSkillComment, setFeedbackSkillComment] = useState(
    track.answers.feedbackCompetencyComment
  );
  const [askForStrength, setAskForStrength] = useState(
    track.answers.option ? track.answers.option.askForStrength : false
  );
  const [askForWeakness, setAskForWeakness] = useState(
    track.answers.option ? track.answers.option.askForWeakness : false
  );

  const { user } = useUser();
  const { match } = useUrl();

  const next = () => {
    if (!allowContinue()) {
      return;
    }
    switch (currentIndex) {
      case 0:
        if (askForStrength) {
          setCurrentIndex(1);
        } else {
          setCurrentIndex(2);
        }
        break;
      case 1:
        if (askForWeakness) {
          setCurrentIndex(2);
        } else {
          setCurrentIndex(3);
        }
        break;
      case 2:
        if (askForStrength) {
          setCurrentIndex(3);
        } else {
          setCurrentIndex(4);
        }
        break;
      case 3:
        if (askForWeakness) {
          setCurrentIndex(4);
        } else {
          setCurrentIndex(5);
        }
        break;
      case 4:
        setCurrentIndex(5);
        break;
      default:
        break;
    }
  };

  const back = () => {
    switch (currentIndex) {
      case 5:
        if (askForWeakness) {
          setCurrentIndex(4);
        } else {
          setCurrentIndex(3);
        }
        break;
      case 4:
        if (askForStrength) {
          setCurrentIndex(3);
        } else {
          setCurrentIndex(2);
        }
        break;
      case 3:
        if (askForWeakness) {
          setCurrentIndex(2);
        } else {
          setCurrentIndex(1);
        }
        break;
      case 2:
        if (askForStrength) {
          setCurrentIndex(1);
        } else {
          setCurrentIndex(0);
        }
        break;
      case 1:
        setCurrentIndex(0);
        break;
      default:
        break;
    }
  };

  const allowContinue = () => {
    let flag;
    switch (currentIndex) {
      case 0:
        flag = selectedRating ? true : false;
        break;
      case 1:
        flag = selectedRecognitionSOL ? true : false;
        break;
      case 2:
        flag = selectedFeedbackSOL ? true : false;
        break;
      case 3:
        flag = selectedRecognitionSkill ? true : false;
        break;
      case 4:
        flag = selectedFeedbackSkill ? true : false;
        break;
      default:
        break;
    }
    return flag;
  };

  const getTitle = () => {
    let title;
    if (currentIndex == 0) {
      title = getObjectiveLocale('Performance Rating');
    } else if (currentIndex == 1 || currentIndex == 2) {
      title = getObjectiveLocale('Value');
    } else if (currentIndex == 3 || currentIndex == 4) {
      title = getObjectiveLocale('Competency');
    } else {
      title = getObjectiveLocale('Summary');
    }
    return title;
  };

  const clearData = () => {
    setSelectedRecognitionSOL(null);
    setRecognitionSOLComment(null);
    setSelectedFeedbackSOL(null);
    setFeedbackSOLComment(null);
    setSelectedRecognitionSkill(null);
    setRecognitionSkillComment(null);
    setSelectedFeedbackSkill(null);
    setFeedbackSkillComment(null);
  };

  const onSelectRating = ({ id, askForStrength, askForWeakness }) => {
    clearData();
    setSelectedRating(id);
    setAskForStrength(askForStrength);
    setAskForWeakness(askForWeakness);
  };

  const onChangeNavigation = (tab) => {
    switch (tab) {
      case 'rating':
        setCurrentIndex(0);
        break;
      case 'recognitionSOL':
        setCurrentIndex(1);
        break;
      case 'feedbackSOL':
        setCurrentIndex(2);
        break;
      case 'recognitionSkill':
        setCurrentIndex(3);
        break;
      case 'feedbackSkill':
        setCurrentIndex(4);
        break;
      default:
        break;
    }
  };

  const onSubmit = async () => {
    let params = {
      option_id: selectedRating,
      rating_comment: ratingComment,
      recognition_competency_id: selectedRecognitionSkill?.id || null,
      recognition_competency_comment: recognitionSkillComment,
      feedback_competency_id: selectedFeedbackSkill?.id || null,
      feedback_competency_comment: feedbackSkillComment,
      recognition_value_id: selectedRecognitionSOL?.id || null,
      recognition_value_comment: recognitionSOLComment,
      feedback_value_id: selectedFeedbackSOL?.id || null,
      feedback_value_comment: feedbackSOLComment
    };

    const { isSuccess } = await submitPerformanceRating(assignmentId, params);
    if (isSuccess) {
      const { isSuccess: successSubmit } = await submitFormalReview(
        assignmentId
      );

      if (successSubmit) {
        history.replace(isTeamCycle ? '/feedbacks' : '/appraisals');
      }
    }
  };

  return (
    <Fragment>
      <div className="outer-team-rating">
        {[...Array(currentIndex + 1)].map((_, index) => {
          return <span key={index} className="team-box-indicator" />;
        })}
        <div className="team-rating-box">
          <div className="team-rating-box-header">
            <h4>{getTitle()}</h4>
          </div>
          {currentIndex == 0 && (
            <RatingReview
              selectedRating={selectedRating}
              ratingComment={ratingComment}
              onSelectRating={onSelectRating}
              onComment={setRatingComment}
              options={track.components.options}
            />
          )}
          {currentIndex == 1 && (
            <SkillRecognitionReview
              action="recognition"
              selectedSkill={selectedRecognitionSOL}
              setSelectedSkill={setSelectedRecognitionSOL}
              skills={track.values}
              skillType="sol"
              comment={recognitionSOLComment}
              onComment={setRecognitionSOLComment}
            />
          )}
          {currentIndex == 2 && (
            <SkillRecognitionReview
              selectedSkill={selectedFeedbackSOL}
              setSelectedSkill={setSelectedFeedbackSOL}
              action="feedback"
              skills={track.values}
              skillType="sol"
              comment={feedbackSOLComment}
              onComment={setFeedbackSOLComment}
            />
          )}
          {currentIndex == 3 && (
            <SkillRecognitionReview
              selectedSkill={selectedRecognitionSkill}
              setSelectedSkill={setSelectedRecognitionSkill}
              action="recognition"
              skills={track.competencies}
              skillType="skill"
              comment={recognitionSkillComment}
              onComment={setRecognitionSkillComment}
            />
          )}
          {currentIndex == 4 && (
            <SkillRecognitionReview
              selectedSkill={selectedFeedbackSkill}
              setSelectedSkill={setSelectedFeedbackSkill}
              action="feedback"
              skills={track.competencies}
              skillType="skill"
              comment={feedbackSkillComment}
              onComment={setFeedbackSkillComment}
            />
          )}
          {currentIndex == 5 && (
            <Summary
              selectedRating={selectedRating}
              ratingComment={ratingComment}
              selectedRecognitionSOL={selectedRecognitionSOL}
              recognitionSOLComment={recognitionSOLComment}
              selectedFeedbackSOL={selectedFeedbackSOL}
              feedbackSOLComment={feedbackSOLComment}
              selectedRecognitionSkill={selectedRecognitionSkill}
              recognitionSkillComment={recognitionSkillComment}
              selectedFeedbackSkill={selectedFeedbackSkill}
              feedbackSkillComment={feedbackSkillComment}
              options={track.components.options}
              onChangeNavigation={onChangeNavigation}
            />
          )}
          <div className="team-rating-box-footer flex justify-between">
            <Button.Tertiary
              onClick={() => back()}
              disabled={currentIndex == 0}
            >
              {getObjectiveLocale('Back')}
            </Button.Tertiary>
            {currentIndex == 5 ? (
              <Button.Secondary onClick={() => onSubmit()}>
                {getObjectiveLocale('Submit')}
              </Button.Secondary>
            ) : (
              <Button.Secondary
                onClick={() => next()}
                disabled={!allowContinue()}
              >
                {getObjectiveLocale('Next')}
              </Button.Secondary>
            )}
          </div>
        </div>
      </div>
      {currentIndex == 0 && (
        <div className="team-review-list-goals">
          <span className="goals-Name">
            {getObjectiveLocale("All Goals of [name]'s").replace(
              '[name]',
              target.name
            )}
          </span>
          <div className={`my-goals-container expanded`}>
            <div className="list-goals">
              <Objectives
                cardModel={'goal-list'}
                page={'performanceRatingScoring'}
                queryFn={() => getPerformanceRating(assignmentId)}
              />
            </div>

            {match && <SidebarMyGoals />}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default withRouter(PerformanceRating);

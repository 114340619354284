import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import {
  exportCSVUsers,
  getLatestUsersCSV,
  importUsers
} from 'client/admin/UsersClient';
import { getListFilter } from 'client/adminClient';
import { useAuth } from 'context/AuthContext';
import { useUser } from 'context/UserContext';
import { useUsersAdmin } from 'context/admin/UsersAdminContext';
import {
  SelectedUserProvider,
  useSelectedUser
} from 'context/useSelectedUser/useSelectedUser';
import useFilter from 'hooks/useFilter';
import useUserManagement from 'hooks/useUserManagement';
import { ParseFile } from 'utils/CsvHelper';

import HeaderPage from 'components/admin/HeaderPage';
import UserManagementDetail from 'components/admin/usermanagement/UserManagementDetail';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import ExportCSVButton from 'components/shared/ExportCSVButton';
import DynamicTabGrey from 'components/shared/Tabs/DynamicTabGrey';
import ToastNotif from 'components/shared/ToastNotif';

import {
  deactivationUser,
  promiseParsedData,
  reactivationUser,
  resendActivation,
  validateCSV
} from './ListUserHelper';
import ListUserTable from './ListUserTable';
import {
  ModalDeactivation,
  ModalImportUser,
  ModalReactivation,
  ModalResendActivation,
  ModalSwitchTab
} from './ModalListUser';

function ListUsers({ isReadOnly, listType }) {
  const [isModalResendActivationOpen, setIsModalResendActivationOpen] =
    useState(false);

  // Permodalan
  // 1. import user
  const [showModalImportUser, setShowModalImportUser] = useState(false);
  const [canImport, setCanImport] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [listErrorUser, setListErrorUser] = useState([]);
  // 2. modal activation
  const [isModalDeactivateOpen, setIsModalDeactivateOpen] = useState(false);
  const [isModalReactivateOpen, setIsModalReactivateOpen] = useState(false);
  const [targetUser, setTargetUser] = useState(null);
  // 3. modal switchTab
  const [showModalSwitchTab, setShowModalSwitchTab] = useState(false);

  // Toast Notif
  const [toastData, setToastData] = useState({
    isShow: false,
    isError: true,
    msg: ''
  });

  // Per-Table an
  const [currentState, setCurrentState] = useState('Active');
  const [reloadState, setReloadState] = useState(false);
  const [continueState, setContinueState] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // useSelectedUser
  const selectAll = useSelectedUser((state) => state.selectAll);
  const include = useSelectedUser((state) => state.include);
  const exclude = useSelectedUser((state) => state.exclude);
  const resetSelectedUser = useSelectedUser((state) => state.resetSelectedUser);

  const { organization, config } = useUser();
  const { login } = useAuth();

  const listStateUser = ['Active', 'Not Yet Sign Up', 'Deleted'];

  const location = useLocation();
  const history = useHistory();

  const filterById = useFilter((state) => state.filterById);
  const filter = filterById?.['userFilter'] || {};

  const isDetailPage =
    location.pathname.includes('add') || location.pathname.includes('edit');
  const editedInfoData = useUserManagement((state) => state.editedInfoData);
  const setEditedInfoData = useUserManagement(
    (state) => state.setEditedInfoData
  );

  const { users } = useUsersAdmin();

  const emptyInfoData = {
    name: null,
    email: null,
    cultureRole: null,
    jobTitle: null,
    role: null,
    isCompanyGoalCreator: null,
    isDirectorateGoalCreator: null,
    isDepartmentGoalCreator: null,
    isDivisionGoalCreator: null,
    info: {
      profilePicture: null
    }
  };

  const _getListFilter = (addedParams) => {
    const fetchFilter = () => {
      return getListFilter(addedParams);
    };

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['filter', addedParams],
        ({ pageParam }) => fetchFilter(pageParam),
        {
          getNextPageParam: (lastPage) => lastPage.pagination?.next || 0
        }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  const getFilterData = async (addedParams) => {
    return await getListFilter({ q: addedParams?.q, key: addedParams?.key });
  };

  const getUserParams = () => {
    const { q, ...allFilter } = filter;
    let state = ['active'];
    if (currentState === 'Not Yet Sign Up') {
      state = ['pending'];
    } else if (listType === 'inactive' || currentState === 'Deleted') {
      state = ['deleted'];
    }
    const query = {
      q: q,
      state: state,
      info: omit(allFilter, ['job-title']),
      jobTitle: allFilter?.['job-title'] || []
    };

    const filterRole = allFilter.role || [];
    if (!(filterRole?.includes('admin') && filterRole?.includes('user'))) {
      if (filterRole?.includes('admin') || (q || '')?.includes('admin')) {
        query.role = 'admin';
        query.cultureRole = 'culture_admin';
      } else if (filterRole?.includes('user')) {
        query.role = 'user';
        query.cultureRole = 'culture_user';
      }
    }

    query.info.role = [];

    return query;
  };

  const params = getUserParams();
  const query = { objective_count: 1, include_manager: true, ...params };
  const openDialogResendActivation = (targetUser) => {
    setTargetUser(targetUser);
    setIsModalResendActivationOpen(true);
  };

  const openDialogReactiveUser = (targetUser) => {
    setTargetUser(targetUser);
    setIsModalReactivateOpen(true);
  };

  const openDialogDeactivateUser = (targetUser) => {
    setTargetUser(targetUser);
    setIsModalDeactivateOpen(true);
  };

  const resetToastData = () => {
    return setTimeout(() => {
      setToastData({
        isShow: false,
        isError: false,
        msg: ''
      });
    }, 2000);
  };

  const showSuccessMessage = (
    isSuccess,
    message,
    error,
    callback = () => null
  ) => {
    const handleReload = () => {
      setIsLoading(true);
      history.replace('/users?source=action');
      setReloadState(!reloadState);
    };

    if (isSuccess) {
      setToastData({
        isShow: true,
        isError: false,
        msg: message
      });
      setTargetUser(null);
      resetToastData();
      handleReload();
      callback();
    }

    if (!isEmpty(error)) {
      setToastData({
        isShow: true,
        isError: true,
        msg: message
      });
      setTargetUser(null);
      resetToastData();
      handleReload();
      callback();
    }
  };

  const modalAction = async (type) => {
    let action;
    let callback;

    if (type == 'resendActivate') {
      action = resendActivation;
      callback = setIsModalResendActivationOpen;
    }

    if (type == 'deactivate') {
      action = deactivationUser;
      callback = setIsModalDeactivateOpen;
    }

    if (type == 'reactivate') {
      action = reactivationUser;
      callback = setIsModalReactivateOpen;
    }

    const body = { selectAll: false, includes: [{ id: targetUser.id }] };

    const { isSuccess, message, error } = await action(body);
    showSuccessMessage(isSuccess, message, error, () => callback());
  };

  const chooseFile = (e) => {
    var file = e.target.files[0];
    e.target.value = '';
    if (file) {
      setFileErrorMessage('');
      setListErrorUser([]);
      if (file.type == 'text/csv' || file.type == 'application/vnd.ms-excel') {
        setSelectedFile(file);
        setCanImport(true);
      } else {
        setToastData({
          isShow: true,
          isError: true,
          msg: 'Please upload a file with .csv extension.'
        });
        setSelectedFile(null);
        setCanImport(false);
        resetToastData();
      }
    }
  };

  const handleFileUpload = async () => {
    setCanImport(false);
    setLoadingButton(true);
    let file = selectedFile;
    let totalSuccess = 0;
    let totalFail = 0;
    let errorList = [];
    let parsedData = await ParseFile(file);
    let { flag, editedParsedData } = validateCSV({
      users: parsedData,
      config: config,
      setErrorMessage: (message) => {
        setFileErrorMessage(message);
        return false;
      }
    });

    if (flag) {
      let promises = promiseParsedData({
        parsedData: editedParsedData,
        config: config
      });

      while (promises.length > 0) {
        const toBeSend = promises.splice(0, 50);
        const { data } = await importUsers({ data: toBeSend });
        if (data) {
          const successData = data.filter(
            (editedData) => typeof editedData === 'object'
          );
          totalSuccess += successData.length;

          const failedData = data.filter(
            (editedData) => typeof editedData === 'string'
          );

          failedData.map((failed) => {
            totalFail += 1;
            errorList.push(failed);
            setListErrorUser(errorList);
          });
        }
      }

      if (totalFail < 1) {
        const message =
          'Success added ' + totalSuccess + ' users & ' + totalFail + ' failed';
        setToastData({
          isShow: true,
          isError: false,
          msg: message
        });
        setSelectedFile('');
        setCanImport(false);
        setFileErrorMessage('');
        setShowModalImportUser(false);
        resetToastData();
      }
    }
    setLoadingButton(false);
    setCanImport(true);
  };

  const loginAsUser = async (email) => {
    await login({
      organization: organization.identifier,
      email: email
    });
    history.replace('/');
  };

  useEffect(() => {
    if (showModalImportUser) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showModalImportUser]);

  useEffect(() => {
    if (location.search.includes('not-yet-sign-up')) {
      setCurrentState('Not Yet Sign Up');
    }
  }, []);

  useEffect(() => {
    editedInfoData?.id === targetUser?.id && setTargetUser(editedInfoData);
  }, [editedInfoData]);

  const roleOption = {
    option: 'role',
    title: 'Role',
    subOption: [
      { option: 'admin', title: 'Admin' },
      { option: 'user', title: 'User' }
    ]
  };

  let filterMenu = config?.generalFilterOptions
    ? [...config.generalFilterOptions, roleOption]
    : [roleOption];

  const headerProps = {
    primaryAction: !isDetailPage &&
      config?.addNewUser &&
      !isReadOnly && {
        onClick: () => {
          setTargetUser(null),
            history.replace('/users/add'),
            setEditedInfoData(emptyInfoData);
        },
        icon: 'icon-add',
        title: 'Add Single Employee',
        disabled: isReadOnly,
        dataCy: 'add-single-employee'
      },
    secondaryAction: !isDetailPage &&
      config?.addNewUser &&
      !isReadOnly && {
        onClick: () => {
          setShowModalImportUser(true),
            setFileErrorMessage(''),
            setSelectedFile(null);
        },
        icon: 'icon-file_upload',
        title: 'Import Employee List',
        disabled: isReadOnly
      },
    titlePage: isDetailPage
      ? targetUser?.name || 'Add Employee'
      : 'Manage Users',
    isHeaderComposer: isDetailPage,
    backToUrl: '/users'
  };

  const handleClickContinue = () => {
    setCurrentState(continueState);
    history.replace('/users');
    resetSelectedUser();
    setShowModalSwitchTab(false);
  };
  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="manage-user bg-n-000">
        <div className="manage-user">
          <ToastNotif
            warning={toastData?.isError}
            showNotif={toastData?.isShow}
            message={toastData?.msg}
          />
          <div
            className="grid table-menu pt-[24px]"
            style={{
              gridTemplateColumns: '100%',
              gridTemplateAreas: "'filter' 'switcher' 'listUserTable'"
            }}
          >
            <AdvancedFilter
              filterOptions={['search', 'menuFilter']}
              filterMenu={filterMenu}
              defaultFilter={{ role: [], ...filter }}
              defaultFilterToReset={{ role: [] }}
              filterClient={getFilterData}
              dialogQueryFn={(params) => _getListFilter(params)}
              gridArea
              id="userFilter"
            >
              <ListUserTable
                currentState={currentState}
                handleEdit={(user) => {
                  history.replace({ pathname: `/users/edit/${user?.id}` });
                  setTargetUser(user);
                }}
                showSuccessMessage={showSuccessMessage}
                openDialogDeactivateUser={openDialogDeactivateUser}
                openDialogReactiveUser={openDialogReactiveUser}
                openDialogResendActivation={openDialogResendActivation}
                loginAsUser={loginAsUser}
                reloadState={reloadState}
                setReloadState={setReloadState}
                isReadOnly={isReadOnly}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                toasData={toastData}
                setToastData={setToastData}
                resetToastData={resetToastData}
                defaultFilterToReset={{ role: [] }}
              />
            </AdvancedFilter>
            <div
              className="wrapper-switch-and-button mt-[8px] mb-[16px]"
              style={{ gridArea: 'switcher' }}
            >
              <div className="w-[60%]">
                <DynamicTabGrey
                  listName={listStateUser}
                  state={currentState}
                  setState={(state) => {
                    if (include.length > 0 || exclude.length > 0 || selectAll) {
                      setShowModalSwitchTab(true);
                      setContinueState(state);
                    } else {
                      setCurrentState(state);
                      history.replace('/users');
                    }
                  }}
                />
              </div>
              <ExportCSVButton
                ready={!isLoading && users.length > 0}
                getLatestCSV={() => getLatestUsersCSV(query)}
                exportCSV={() => exportCSVUsers(query)}
              />
            </div>
          </div>

          {isDetailPage && <UserManagementDetail isReadOnly={isReadOnly} />}

          {isModalResendActivationOpen && (
            <ModalResendActivation
              action={() => modalAction('resendActivate')}
              setOpenModal={setIsModalDeactivateOpen}
              targetUser={targetUser}
            />
          )}

          {isModalDeactivateOpen && (
            <ModalDeactivation
              action={() => modalAction('deactivate')}
              setOpenModal={setIsModalDeactivateOpen}
              targetUser={targetUser}
            />
          )}

          {isModalReactivateOpen && (
            <ModalReactivation
              action={() => modalAction('reactivate')}
              setOpenModal={setIsModalReactivateOpen}
              targetUser={targetUser}
            />
          )}

          {showModalSwitchTab && (
            <ModalSwitchTab
              action={() => handleClickContinue()}
              setOpenModal={setShowModalSwitchTab}
            />
          )}

          {showModalImportUser && (
            <ModalImportUser
              config={config}
              action={() => handleFileUpload()}
              uploadFile={(e) => chooseFile(e)}
              setOpenModal={setShowModalImportUser}
              setCanImport={setCanImport}
              setSelectedFile={setSelectedFile}
              setFileErrorMessage={setFileErrorMessage}
              setListErrorUser={setListErrorUser}
              canImport={canImport}
              selectedFile={selectedFile}
              fileErrorMessage={fileErrorMessage}
              listErrorUser={listErrorUser}
              loadingButton={loadingButton}
            />
          )}
        </div>
      </div>
    </>
  );
}

const ListUserWrapper = (props) => {
  return (
    <SelectedUserProvider>
      <ListUsers {...props} />
    </SelectedUserProvider>
  );
};

export default withRouter(ListUserWrapper);

let params = {
  sortBy: "created_at",
  order: "desc",
  state: "active",
}
const paramsBehaviorModel = (type, props) => {
  return {
    ...params,
    ...props,
    modelType: type == "competency" ? "competency" : "value",
    subType: type == "work" ? "work_attribute" : "regular"
  }
}
const paramsBehaviorDictionary = (type, props) => {
  return {
    ...params,
    ...props,
    behaviorType: type == "work" ? "value" : type,
    listFor: "cms"
  }
}

export {
  paramsBehaviorModel,
  paramsBehaviorDictionary
}
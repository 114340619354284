import React from 'react';

import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import RadioButton from 'src/components/shared/RadioButton';

const ContentFormulaCalculation = ({
  activeFormulaCalculationID,
  setActiveFormulaCalculationID,
  configFormulaCalculation,
  configSP,
  setConfigSP
}) => {
  const onChangeFormulaCalculation = (value, attr, idx) => {
    let arrayConfig = [...configSP];
    arrayConfig[idx][attr] = value;

    setConfigSP(arrayConfig);
  };

  return (
    <div
      id="performance-final"
      className="container-content-formula-calculation"
    >
      <p className="typography-h500">Formula calculation</p>
      <p className="content-subtitle">
        Set how this rating template will use SP or not
      </p>

      {configFormulaCalculation.map((val, idx) => (
        <div className="wrapper-scoring-type" key={idx}>
          <RadioButton
            dataCy={'rating-template-sp-config-' + val.id}
            size="small"
            value={val.title}
            id={val.id}
            checked={activeFormulaCalculationID == val.id}
            onChange={(e) => setActiveFormulaCalculationID(Number(e.target.id))}
          >
            <span className="ml-[8px]">{val.title}</span>
          </RadioButton>
        </div>
      ))}

      {activeFormulaCalculationID == 2 && (
        <div className="container-rating-template-card">
          <p className="card-title">Deduction value of each SP</p>
          <p className="card-subtitle">Set up the deduction value of each SP</p>

          {configSP.length !== 0 && (
            <div className="header-table-sp">
              <span className="col-name">SP name</span>
              <span className="col-score">Score reduction</span>
            </div>
          )}

          {configSP.map((val, idx) => (
            <div
              data-cy={'wrapper-formula-calculation-' + idx}
              className="body-table-sp"
              key={idx}
            >
              <div className="col-name">
                <p className="sp-circle-number img-24">{idx + 1}</p>
                <p className="typography-paragraph">
                  {getObjectiveLocale(val.name)}
                </p>
              </div>
              <div className="col-score">
                <img
                  className="sp-circle-minus img-16"
                  src={Config.asset.admin.removeCircleRed}
                />
                <input
                  key={idx}
                  onChange={(e) =>
                    onChangeFormulaCalculation(
                      Number(e.target.value),
                      'valueReduction',
                      idx
                    )
                  }
                  type="number"
                  className="input-rating normal-border input-sp-score"
                  id={'input-sp-score-' + idx}
                  data-cy={'input-sp-score-' + idx}
                  defaultValue={val.valueReduction}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ContentFormulaCalculation;

import React from 'react';

import Modal from 'components/shared/modal/Modal';

function ModalDeleteLabel({ onConfirmDelete, setModalDeleteOpen }) {
  return (
    <div className="modal-delete">
      <Modal
        title={'Archive label'}
        withCloseIcon={false}
        className="w-[400px]"
        description="This label will be archived from the groups that used this label.
        Are you sure want to archive this label?"
        withPrimaryBtn={{
          danger: true,
          title: 'Archive',
          dataCy: 'archive-btn',
          onClick: () => onConfirmDelete()
        }}
        withSecondaryBtn={{
          title: 'Cancel',
          dataCy: 'cancel-archive-label',
          onClick: () => setModalDeleteOpen(false)
        }}
      />
    </div>
  );
}

export default ModalDeleteLabel;

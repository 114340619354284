import dayjs from 'dayjs';

import {
  getSurveyIdCMSCulture,
  sendPulseSurveyReminder
} from 'client/adminClient';

import { getDurationDate } from 'utils/HelperUtils';

const restructureFilter = filter => {
  let changedFilter = {};
  Object.keys(filter).map(key => {
    if (
      filter[key] &&
      filter[key].includes('N/A') &&
      Array.isArray(filter[key])
    ) {
      changedFilter['no_info'] = key;
    } else {
      changedFilter[key] = filter[key];
    }
  });
  return changedFilter;
};

const sendReminderHandler = async (surveyId, params) => {
  const { data } = await getSurveyIdCMSCulture(surveyId, params);

  let remindSuccess = false;
  const currentDate = dayjs();
  const nextRemindAt = dayjs(data?.lastRemindAt || null).add(1, 'day');

  const { hours: remainingHours, minutes: remainingMinutes } = getDurationDate(
    nextRemindAt,
    currentDate
  );

  if (
    (remainingHours <= 0 && remainingMinutes <= 0) ||
    currentDate >= nextRemindAt ||
    !data?.lastRemindAt
  ) {
    const { isSuccess } = await sendPulseSurveyReminder(surveyId, params);

    if (isSuccess) {
      remindSuccess = true;
    }
  }

  return {
    remindSuccess,
    remainingHours,
    remainingMinutes
  };
};

export { restructureFilter, sendReminderHandler };

import React, { useState } from 'react';

import { capitalize } from 'utils/HelperUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

function SkillRecognitionReview({
  action,
  selectedSkill,
  setSelectedSkill,
  skills,
  skillType,
  onComment,
  comment
}) {
  const [showSkillPopup, setShowSkillPopup] = useState(false);

  let assetImg =
    action === 'recognition' ? 'icon-strength' : 'icon-improvement';

  return (
    <div className="team-rating-box-body">
      <div className="question-recognition">
        <SVGIcon iconName={assetImg} size={48} />
        <p className="p-body-team-header">
          {action === 'recognition'
            ? getObjectiveLocale('One thing this person excels the most')
            : getObjectiveLocale('One thing this person could improve')}
        </p>
        <p className="p-body-team">{capitalize(action)}</p>
        <div className="skill-recognition-layout">
          {selectedSkill && (
            <div
              className={
                action == 'recognition'
                  ? 'skill list-recognition green-list'
                  : 'skill list-recognition'
              }
            >
              <span className="text">{selectedSkill.name}</span>
              <span className="edit-label">
                <p className="text-edit" onClick={() => setSelectedSkill(null)}>
                  {getObjectiveLocale('Edit')}
                </p>
                <SVGIcon
                  iconName="icon-compose"
                  customClass="icon-compose"
                  size={20}
                  onClick={() => setSelectedSkill(null)}
                />
              </span>
            </div>
          )}
          {!selectedSkill &&
            skills.slice(0, 8).map((value, index) => {
              return (
                <div
                  className="skill-recognition-button"
                  key={index}
                  onClick={() =>
                    setSelectedSkill({ id: value.id, name: value.name })
                  }
                >
                  <span className="skill-text-g-800">{value.name}</span>
                </div>
              );
            })}
        </div>
        <Button.Tertiary onClick={() => setShowSkillPopup(true)}>
          {skillType == 'sol'
            ? getObjectiveLocale('Select other ' + getObjectiveLocale('Value'))
            : getObjectiveLocale(
                'Select other ' + getObjectiveLocale('Competency')
              )}
        </Button.Tertiary>
      </div>
      <span className="comment-container">
        <label className="label-comment">
          {getObjectiveLocale('Comment')}
          <p className="label-comment-optional">
            ({getObjectiveLocale('Optional')})
          </p>
        </label>
        <textarea
          className="comment-text-field"
          placeholder={getObjectiveLocale('Write description here...')}
          onChange={(e) => onComment(e.target.value)}
          defaultValue={comment}
        />
      </span>
      {showSkillPopup && (
        <Modal
          title={
            skillType == 'sol'
              ? getObjectiveLocale('Choose ' + getObjectiveLocale('Value'))
              : getObjectiveLocale('Choose ' + getObjectiveLocale('Competency'))
          }
          eventOnClickClose={() => setShowSkillPopup(false)}
          className="overflow-hidden-team-review"
          footerClass="h-[0px]"
        >
          <div className="body-search-team-review">
            {skills.map((value, index) => {
              return (
                <p
                  key={index}
                  className="list-skill-feedback"
                  onClick={() => {
                    setSelectedSkill({ id: value.id, name: value.name });
                    setShowSkillPopup(false);
                  }}
                >
                  {value.name}
                </p>
              );
            })}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default SkillRecognitionReview;

import React, { useState } from 'react';

import BlueGear from './BlueGear';
import RedGear from './RedGear';

const switchSetting = (setState, key, val) =>
  setState((prev) => ({ ...prev, [key]: val }));
const buildSettingList = ({ state, setState, config, user, redLsEnv } = {}) => {
  let data = [
    {
      type: 'tabs',
      title: 'API Host',
      listName: ['api.development', 'api.staging'],
      disabled: redLsEnv?.USE_PRODUCTION_ENV,
      state: state?.PERFORMANCE_API_HOST,
      setState: (val) => switchSetting(setState, 'PERFORMANCE_API_HOST', val),
      config: 'blueGear'
    },
    {
      type: 'tabs',
      title: 'Authorization Method',
      listName: ['cookie', 'bearer'],
      disabled: redLsEnv?.USE_PRODUCTION_ENV,
      state: state?.AUTHORIZATION_METHOD,
      setState: (val) => switchSetting(setState, 'AUTHORIZATION_METHOD', val),
      config: 'blueGear'
    },
    {
      type: 'toggle',
      title: 'Using Mock',
      idStr: 'mock-toggle',
      isChecked: state?.USE_MOCK,
      disabled: redLsEnv?.USE_PRODUCTION_ENV,
      onChange: () =>
        setState((prev) => ({ ...prev, USE_MOCK: !prev.USE_MOCK })),
      config: 'blueGear'
    },
    {
      type: 'toggle',
      title: 'Using Production ENV',
      idStr: 'use-production-toggle',
      isChecked: state?.USE_PRODUCTION_ENV,
      onChange: () =>
        setState((prev) => ({
          ...prev,
          USE_PRODUCTION_ENV: !prev.USE_PRODUCTION_ENV
        })),
      config: 'redGear'
    },
    {
      label: 'Email',
      placeholder: 'Email address',
      handleChange: (e) => setState({ ...state, email: e.target.value }),
      value: state?.email,
      disabled: !state?.USE_PRODUCTION_ENV,
      description:
        'Enter email address to login as user in happy5 organization',
      type: 'text',
      config: 'redGear'
    },
    {
      label: 'Organization Name',
      placeholder: 'e.g. Happy5',
      handleChange: (e) => setState({ ...state, organization: e.target.value }),
      value: state?.organization,
      disabled: !state?.USE_PRODUCTION_ENV,
      type: 'text',
      config: 'redGear'
    },
    {
      label: 'Administrator Password',
      placeholder: 'Enter administrator password',
      handleChange: (e) => setState({ ...state, password: e.target.value }),
      value: state?.password,
      disabled: !state.USE_PRODUCTION_ENV,
      type: 'password',
      config: 'redGear'
    }
  ];

  if (process.env.NODE_ENV == 'development') {
    data.push({
      type: 'toggle',
      title: 'Is BCA',
      idStr: 'bca-toggle',
      isChecked: state?.IS_BCA,
      disabled: redLsEnv?.USE_PRODUCTION_ENV,
      onChange: () => setState((prev) => ({ ...prev, IS_BCA: !prev.IS_BCA })),
      config: 'blueGear',
      localOnly: true
    });
  }

  return data;
};

const EnvSetting = () => {
  return (
    <>
      <BlueGear buildSettingList={buildSettingList} />
      <RedGear buildSettingList={buildSettingList} />
    </>
  );
};

export default EnvSetting;

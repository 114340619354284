import React, { useEffect, useState } from 'react';

import 'scss/culture/modal/modal-tagged-user';

import { getListUser, getSearchUserNew } from 'client/CultureClient';
import useDebounce from 'hooks/useDebounce';
import { getCultureLocale, loadMoreValidator } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

import ListDetailUser from './ListDetailUser';

function ModalTaggedUser({
  isRecognition,
  groupId,
  setTaggedUsers,
  selectedUsers,
  setSelectedUsers,
  maxReceiver,
  setIsLoading = () => {},
  type
}) {
  const [users, setUsers] = useState([]);
  const [searchUser, setSearchUser] = useState('');
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [onSearch, setOnSearch] = useState(false);
  const [isShowWarning, setIsShowWarning] = useState(false);
  const [nextCursor, setNextCursor] = useState(null);
  const debouncedSearch = useDebounce(searchUser, 2000);

  const getSelected = (currentUsers) => {
    const selectedUserIds = selectedUsers.map((user) => user.id);
    const updatedUsers = currentUsers.map((user) => {
      if (selectedUserIds.includes(user.id)) {
        user.isSelected = true;
      }
      return user;
    });
    setUsers(updatedUsers);
  };

  const getTaggedUserData = async () => {
    const params = {
      limit: 10,
      q: searchUser,
      cursor: nextCursor
    };
    const { data, next_cursor } =
      type === 'default'
        ? await getListUser(groupId, params)
        : await getSearchUserNew(params);

    if (data && data?.length > 0) {
      data.map((user) => (user.isSelected = false));
      if (users?.length === 0) {
        setUsers(data);
        getSelected(data);
      } else {
        getSelected([...users, ...data]);
      }
      setNextCursor(next_cursor);
    } else {
      setNextCursor(null);
    }
  };

  const changeSearchUser = async (e) => {
    e.stopPropagation();
    setOnSearch(true);
    setSearchUser(e.target.value);
  };

  const searchUserList = async () => {
    const params = {
      limit: 10,
      q: searchUser
    };

    const { data, next_cursor } =
      type === 'default'
        ? await getListUser(groupId, params)
        : await getSearchUserNew(params);
    if (data) {
      getSelected(data);
      const cursor = data.length > 0 ? next_cursor : null;
      setNextCursor(cursor);
      setOnSearch(false);
    } else {
      setNextCursor(null);
      setOnSearch(false);
    }
  };

  const setTaggedUser = (id, fullName, profilePicture, isSelected, index) => {
    const isAbleToAdd = isRecognition
      ? selectedUsers?.length < maxReceiver
        ? true
        : false
      : true;

    if (isAbleToAdd || isSelected) {
      let currentSelected = JSON.parse(JSON.stringify(selectedUsers));
      let updatedUser = JSON.parse(JSON.stringify(users));
      const updatedUserIndex = users.findIndex((user) => user.id === id);

      if (isSelected) {
        const deletedIndex = currentSelected.findIndex(
          (selected) => selected.id === id
        );
        currentSelected.splice(deletedIndex, 1);
      } else {
        currentSelected = [
          ...selectedUsers,
          {
            id: id,
            fullName: fullName,
            profilePicture: profilePicture
          }
        ];
      }

      if (updatedUserIndex >= 0) {
        updatedUser[updatedUserIndex].isSelected = !isSelected;
      }
      setSelectedUsers(currentSelected);
      setTaggedUsers(currentSelected);
      setUsers(updatedUser);
    } else if (!isAbleToAdd) {
      setIsShowWarning(true);
      setTimeout(() => {
        setIsShowWarning(false);
      }, 1000);
    }
  };

  const onScroll = (e) => {
    if (e.target) {
      const target = e.target;

      const loadMore = async () => {
        setOnLoadMore(true);
        await getTaggedUserData();
        setOnLoadMore(false);
      };

      const hasMore = nextCursor ? true : false;

      if (!onLoadMore && hasMore) {
        loadMoreValidator(target, 50, () => {
          loadMore();
        });
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getTaggedUserData();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (searchUser !== '') {
      searchUserList();
    }
  }, [debouncedSearch]);

  return (
    <div className="content">
      {isShowWarning && (
        <div className="warning-select-user">
          You reached your maximum selected users
        </div>
      )}
      <input
        placeholder={getCultureLocale('Select Person')}
        onChange={(e) => changeSearchUser(e)}
        value={searchUser}
      />
      <div
        className={`list-user scrollable ${
          selectedUsers?.length > 0 ? 'height-min' : 'height-max'
        }`}
        onScroll={onScroll}
      >
        {onSearch && <LoadingComponent text={getCultureLocale('Loading')} />}
        {!onSearch &&
          users?.length > 0 &&
          users.map((user, index) => {
            return (
              <ListDetailUser
                type="tagged"
                id={user.id}
                title={user.fullName}
                desc={user.title}
                image={
                  user.profilePicture ? user.profilePicture.secureUrl : null
                }
                isSelected={user.isSelected}
                onClickListDetail={(e) => {
                  e.stopPropagation();
                  setTaggedUser(
                    user.id,
                    user.fullName,
                    user.profilePicture ? user.profilePicture.secureUrl : null,
                    user.isSelected,
                    index
                  );
                }}
                isCheckboxExist
                dataCy={`user-item-${user.id}`}
                key={index}
              />
            );
          })}
        {onLoadMore && <LoadingComponent />}
      </div>
      {selectedUsers?.length > 0 && (
        <div className="selected-user">
          {selectedUsers.map((user, index) => {
            return (
              <div key={user.id} className="user-selected-detail flex">
                <div>
                  <Avatar
                    name={user.fullName}
                    src={user.profilePicture}
                    size={48}
                    dataCy="user-profile"
                  />
                  <p className="tyopgraphy-200 name truncate w-[60px]">
                    {user.fullName}
                  </p>
                </div>
                <SVGIcon
                  customClass="absolute -top-8 -right-8"
                  iconName="icon-clear"
                  size="24"
                  fillColor="var(--n-600)"
                  dataCy="close-modal"
                  onClick={(e) => {
                    setTaggedUser(
                      user.id,
                      user.fullName,
                      user.profilePicture,
                      true,
                      index
                    );
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ModalTaggedUser;

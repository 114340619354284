import React from 'react';

import { UsersAdminProvider } from 'context/admin/UsersAdminContext';
import { SelectedUserProvider } from 'context/useSelectedUser/useSelectedUser';
import useParticipantTeam from 'hooks/useParticipantTeam';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SelectMemberComponent from 'components/SelectMemberComponents/SelectMemberComponent';

const ManageParticipant = ({ isReadOnly }) => {
  const { participantData, setParticipantData, setTotalParticipant } =
    useParticipantTeam((state) => ({
      participantData: state.participantData,
      setParticipantData: state.setParticipantData,
      setTotalParticipant: state.setTotalParticipant
    }));

  const handleSetParticipant = ({ selectAll, include, exclude }) => {
    setParticipantData({
      ...participantData,
      includes: include,
      excludes: exclude,
      selectAll: selectAll
    });
  };

  return (
    <div>
      <p className="typography-h500 text-n-900">
        {getObjectiveLocale('Select participant for this group')}
      </p>
      <UsersAdminProvider>
        <SelectedUserProvider
          initialValue={{
            selectAll: participantData?.selectAll,
            include: participantData?.includes?.map((e) => ({ id: e })),
            exclude: participantData?.excludes?.map((e) => ({ id: e }))
          }}
        >
          <SelectMemberComponent
            isReadOnly={isReadOnly}
            customClass="mt-[16px]"
            setTotalParticipant={setTotalParticipant}
            handleSet={(e) => handleSetParticipant(e)}
            type="users"
          />
        </SelectedUserProvider>
      </UsersAdminProvider>
    </div>
  );
};

export default ManageParticipant;

import React, { useEffect, useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import ContentWrapper from 'components/sidebar/sidebar-create/ContentWrapper';

function DropdownComplexity({ objectiveValue, setObjectiveValue }) {
  const [selectedValue, setSelectedValue] = useState('');
  const defaultValue = objectiveValue.complexity;

  const listValue = [{ value: 1 }, { value: 2 }, { value: 3 }];

  const handleChangeDropdown = (value) => {
    setSelectedValue(value.value);
    setObjectiveValue((draft) => {
      draft.complexity = parseInt(value.value);
    });
  };

  useEffect(() => {
    defaultValue && setSelectedValue(defaultValue);
  }, [defaultValue]);

  return (
    <ContentWrapper
      iconName="icon-speed"
      customWrapperClass="items-center px-[24px] py-[6px]"
      dataCy="sidebar-create-complexity"
    >
      <div className="flex items-center">
        <p className="typography-paragraph mr-[8px]">
          {getObjectiveLocale('Task Complexity')}
        </p>
        <Dropdown>
          <Dropdown.Trigger dataCy="dropdown-complexity">
            <Trigger
              icon={false}
              topLabel={false}
              label={false}
              text={selectedValue}
            />
          </Dropdown.Trigger>
          <Dropdown.MenuItems customClass="w-full">
            {listValue.map((value, index) => (
              <Dropdown.MenuItem
                key={index}
                onClick={() => handleChangeDropdown(value)}
                dataCy={`dropdown-complexity-option-${index}`}
              >
                {value.value}
              </Dropdown.MenuItem>
            ))}
          </Dropdown.MenuItems>
        </Dropdown>
      </div>
    </ContentWrapper>
  );
}

export default DropdownComplexity;

import React, { createRef, useEffect, useState } from 'react';

import {
  UsersAdminProvider,
  useUsersAdmin
} from 'context/admin/UsersAdminContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

import BadgeReplacementConfirmation from 'components/admin/mission/badge/BadgeReplacementConfirmation';
import SelectMembers from 'components/admin/mission/badge/SelectMembers';

function BadgeRecipients({
  badge,
  setBadge,
  members,
  setMembers,
  setInitialMembers,
  setTotalRecipients,
  setInitialTotalRecipients,
  setPage,
  isModalOpen,
  setIsModalOpen,
  checkBadgeReplacement,
  setCheckBadgeReplacement,
  isReadOnly
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [previouslyAssignedUsers, setPreviouslyAssignedUsers] = useState([]);
  const intersectTarget = createRef();

  const { includes, excludes, selectAll } = members;
  const { totalUser, users, getUsersData, appendUserData, hasMore } =
    useUsersAdmin();

  const filterOptions = ['search', 'menuFilter'];

  const getQuery = () => {
    const query = { state: ['active', 'pending'], limit: 10 };
    if (!selectAll) {
      query['id'] = includes;
    } else {
      query['exclude'] = excludes;
    }
    return query;
  };

  const _appendData = () => {
    if (hasMore) {
      appendUserData({ ...getQuery() });
    }
  };

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) => entry.isIntersecting && _appendData(),
    threshold: 0.3
  });

  const _getUsersData = async () => {
    setIsLoading(true);
    await getUsersData({ ...getQuery() });
    setIsLoading(false);
  };

  const handleCancelBadgeReplacement = () => {
    setIsModalOpen(false);
    setCheckBadgeReplacement(false);
  };

  const handleConfirmBadgeReplacement = () => {
    setBadge({
      ...badge,
      includes: members.includes,
      excludes: members.excludes,
      selectAll: members.selectAll
    });
    setInitialMembers(members);
    setInitialTotalRecipients(
      selectAll ? totalUser - excludes.length : includes.length
    );
    setIsModalOpen(false);
    setCheckBadgeReplacement(false);
    setPage('createBadge');
  };

  useEffect(() => {
    _getUsersData();
  }, []);

  useEffect(() => {
    checkBadgeReplacement && _getUsersData();
  }, [checkBadgeReplacement]);

  useEffect(() => {
    setTotalRecipients(
      selectAll ? totalUser - excludes.length : includes.length
    );
  }, [members]);

  useEffect(() => {
    if (checkBadgeReplacement) {
      const tempUsers = users.filter(
        (user) =>
          user?.assignedBadge?.title &&
          user?.assignedBadge?.title !== badge?.title
      );
      if (tempUsers?.length > 0) {
        setPreviouslyAssignedUsers(tempUsers);
        setIsModalOpen(true);
      } else {
        handleConfirmBadgeReplacement();
      }
    }
  }, [users]);

  return (
    <>
      {isModalOpen && (
        <UsersAdminProvider>
          <BadgeReplacementConfirmation
            users={previouslyAssignedUsers}
            members={members}
            setMembers={setMembers}
            handleCancel={handleCancelBadgeReplacement}
            handleConfirm={handleConfirmBadgeReplacement}
            isLoading={isLoading}
          />
        </UsersAdminProvider>
      )}
      <div className="mx-[48px]">
        <UsersAdminProvider>
          <SelectMembers
            members={members}
            setMembers={setMembers}
            type="user"
            pageTitle="Select recipient for this badge"
            filterOptions={filterOptions}
            withHideColumn={false}
            useBadge={true}
            isReadOnly={isReadOnly}
          />
        </UsersAdminProvider>
      </div>
    </>
  );
}

export default BadgeRecipients;

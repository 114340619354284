import { getObjectiveCategory } from "client/ObjectivesClient";
import { useInfiniteQuery } from "react-query";

export default function useCategories() {

  const GetCategories = (params) => {

    const fetchCategories = () => {
      return getObjectiveCategory(params);
    };

    let {
      data,
      status,
      isFetching,
      refetch
    } = useInfiniteQuery(["categories", params], fetchCategories);

    return {
      data,
      status,
      isFetching,
      refetch
    };
  };

  return { getCategories: GetCategories };
}

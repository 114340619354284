import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useSidebarMenu } from 'context/SidebarMenuContext';

import HeaderPage from 'components/admin/HeaderPage';
import AdminTabWithCount from 'components/shared/Tabs/AdminTabWithCount.js';

import GroupTabReviewGroup from '../GroupTabReviewGroup';
import DefaultTabGroup from './DefaultTabGroup';
import './LandingBehaviorModelPage.scss';

const LandingBehaviorModelPage = ({ route: { isReadOnly } }) => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('default');
  const [activeSection, setActiveSection] = useState('');
  const [listNavigatorContents, setListNavigatorContents] = useState([]);
  const [sections, setSections] = useState([]);

  const { toggleSidebar } = useSidebarMenu();

  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');

  const listObjTabs = [
    { id: 'default', name: 'Default' },
    { id: 'groups', name: 'Groups' }
  ];

  const routeToCreatePage = () => {
    history.push(`/group/behavior-model/create`);
  };

  const routeToEditPage = (id) => {
    history.push(`/group/behavior-model/edit/${id}`);
  };

  const changeTab = (tab) => {
    history.push(`/group/behavior-model?tab=${tab}`);
    setCurrentTab(tab);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    if (tabParams == 'groups') {
      setCurrentTab('groups');
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [currentTab]);

  useEffect(() => {
    toggleSidebar(false);
  }, []);

  const headerProps = {
    titlePage: 'Behavior Model',
    primaryAction: currentTab === 'groups' &&
      !isReadOnly && {
        title: 'Create Group',
        icon: 'icon-add',
        onClick: routeToCreatePage,
        dataCy: 'create-new-behavior-group'
      }
  };

  const _onScroll = (e) => {
    const scrollTop = e.target.scrollTop + 16;
    sections.forEach((section) => {
      const el = document.getElementById(section);
      if (el && scrollTop >= el.offsetTop) {
        setActiveSection(section);
      }
    });
    if (e.target.scrollTop + e.target.offsetHeight == e.target.scrollHeight) {
      setActiveSection(sections[sections.length - 1]);
    }
  };

  return (
    <>
      <HeaderPage
        {...headerProps}
        tabs={{
          tabsList: listObjTabs,
          url: '/group/behavior-model',
          onChange: (tab) => {
            setCurrentTab(tab);
          }
        }}
      />
      <div
        className="container-landing-behavior-model bg-n-000 h-[calc(100vh-64px)] overflow-auto"
        onScroll={(e) => _onScroll(e)}
        id="sectioncontainer"
      >
        {currentTab == 'default' && (
          <DefaultTabGroup
            isReadOnly={isReadOnly}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            listNavigatorContents={listNavigatorContents}
            setListNavigatorContents={setListNavigatorContents}
            setSections={setSections}
          />
        )}

        {currentTab == 'groups' && (
          <GroupTabReviewGroup
            description={
              'You can define behavior group for spesific employees by creating group here.'
            }
            groupType={'behavior_group'}
            routeToCreatePage={routeToCreatePage}
            routeToEditPage={routeToEditPage}
            isReadOnly={isReadOnly}
          />
        )}
      </div>
    </>
  );
};

export default LandingBehaviorModelPage;

import React, { useState } from 'react';

import debounce from 'lodash/debounce';

import { useGoalWeightSettingsContext } from 'context/GoalWeightSettingContext';

import NumberInput from 'components/shared/NumberInput';

let _debounced = debounce((action) => action(), 1000);

const WeightSettingsInputNumber = ({
  defaultValue,
  className,
  inputClass,
  onChangeCallback,
  max = 100,
  isError,
  useDebounce = true,
  children,
  dataCy = 'weight-input'
}) => {
  const { isReadOnly } = useGoalWeightSettingsContext();

  const [value, setValue] = useState(defaultValue);
  const [isRender, setRender] = useState(false);

  const setValueHandler = async (value) => {
    setValue(value);
    const parsedValue = parseFloat(value);
    if (onChangeCallback && !isNaN(parsedValue)) {
      await onChangeCallback(parsedValue);
    }
  };

  const onChangeFunction = (value) => {
    if (isRender) {
      if (useDebounce) {
        _debounced(() => {
          setValueHandler(value);
        });
      } else {
        setValueHandler(value);
      }
    } else {
      setRender(true);
    }
  };

  return (
    <NumberInput
      className={`${className ? className : 'w-[200px]'}`}
      inputClass={`${inputClass ? inputClass : 'h-[32px]'} ${
        isError ? 'input-error' : ''
      }`}
      value={value}
      onChange={onChangeFunction}
      max={max}
      customNextElement={children}
      disabled={isReadOnly}
      dataCy={dataCy}
    />
  );
};

export default WeightSettingsInputNumber;

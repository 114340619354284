import React from 'react';

import SelectedFilter from './SelectedFilter';

function SelectedFilters({
  resultModifier,
  filter,
  dispatchFilter,
  immutableFilter,
  generateCustomTitle
}) {
  let result = resultModifier ? resultModifier(filter) : filter;
  const checkValue = (filterValue) => {
    return Array.isArray(filterValue) &&
      filterValue?.some((value) => typeof value == 'object')
      ? {}
      : Array.isArray(filterValue)
      ? []
      : '';
  };

  return (
    <>
      {Object.entries(result).map(([filterKey, filterValue], index) => {
        if (filterKey !== 'q') {
          return (
            <SelectedFilter
              selectedFilter={filterValue}
              title={filterKey}
              handleChange={(filterKey) =>
                dispatchFilter({
                  state: { [filterKey]: checkValue(filterValue) }
                })
              }
              key={index}
              immutableFilter={immutableFilter}
              generateCustomTitle={generateCustomTitle}
            />
          );
        }
      })}
    </>
  );
}

export default SelectedFilters;

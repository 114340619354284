import React, { Fragment } from 'react';

import { getReviewResultObjectivesScoreAttribute } from 'client/FormalReviewClient';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Divider from 'components/design-system/Divider';
import Objectives from 'components/objectives/Objectives';

import ProfileEvidence from './ProfileEvidence';
import WrapperAssessmentScore from './WrapperAssessmentScore';

const CardReviewAspectsScoringItem = (props) => {
  const {
    showOption,
    trackType,
    targetId,
    answersData,
    cycleDetail,
    trackTemplate,
    currentTrackConfigId,
    showObjectives = false
  } = props;

  const resultsData = Array.isArray(answersData) ? [...answersData] : [];

  return (
    <div
      id={'container-' + trackType.replace('_', '-') + '-sidebar'}
      className="mt-[16px]"
      data-cy={'container-' + trackType.replace('_', '-') + '-sidebar'}
    >
      {resultsData?.map((result, resultIdx) => {
        return result?.answersData?.map((data, idx) => (
          <>
            <div
              key={idx}
              className="evidence-border px-[16px] py-[16px] mb-[16px]"
            >
              <h4 className="mb-[16px] typography-h500-longform capitalize objective-title cursor-pointer">
                {data?.object?.title || data?.object?.name}
              </h4>

              {(data.answers || []).map((answer, i) => (
                <Fragment key={i}>
                  <ProfileEvidence profileData={answer?.actor} />
                  <WrapperAssessmentScore
                    showOption={showOption || result?.showOption}
                    statusColorHex={answer?.option?.colorHex}
                    statusName={answer?.option?.name}
                    personalScore={answer?.option?.score || answer?.rawScore}
                    isLastIndex={i !== data.answers.length - 1}
                    description={answer?.comment}
                    components={data?.components}
                    view={data?.view}
                  />
                </Fragment>
              ))}
            </div>
            {showObjectives && data?.mechanism == 'weight_type' && (
              <AdvancedFilter
                defaultFilter={{
                  objectiveCategoryId:
                    trackTemplate?.reviewAspects?.[resultIdx]
                      ?.objectiveCategoryIds,
                  parentNotAssignedTo: targetId,
                  targetId: targetId
                }}
                defaultGroup={'goal-type'}
                customClass={'pl-[0px]'}
                id={`cardReviewAspectsScoringFilter${trackTemplate?.reviewAspects?.[resultIdx]?.id}`}
              >
                <Objectives
                  cardModel="goal-list"
                  page={`result-score-aspect-${trackTemplate?.reviewAspects?.[resultIdx]?.id}`}
                  queryFn={(params) =>
                    getReviewResultObjectivesScoreAttribute(
                      cycleDetail.id,
                      currentTrackConfigId,
                      data?.object?.id,
                      params
                    )
                  }
                  showBadgeObjectiveCount={true}
                  withMarginTopAccordion={false}
                />
              </AdvancedFilter>
            )}
            {resultsData?.length - 1 !== resultIdx && (
              <Divider customClass="mb-[16px]" />
            )}
          </>
        ));
      })}
    </div>
  );
};

export default CardReviewAspectsScoringItem;

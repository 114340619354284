import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  getBadgeById,
  getPreSignUrlValues,
  postBadge,
  updateBadge
} from 'client/adminClient.js';
import { UsersAdminProvider } from 'context/admin/UsersAdminContext';

import HeaderPage from 'components/admin/HeaderPage';
import BadgeRecipients from 'components/admin/mission/badge/BadgeRecipients';
import CreateBadgeContents from 'components/admin/mission/badge/CreateBadgeContents';
import Footer from 'components/shared/Footer';

function CreateBadge({ route: { title, isReadOnly } }) {
  const [badge, setBadge] = useState({
    title: '',
    description: '',
    iconUrl: null,
    includes: [],
    excludes: [],
    selectAll: false
  });
  const [members, setMembers] = useState({
    includes: [],
    excludes: [],
    selectAll: false
  });
  const [totalRecipients, setTotalRecipients] = useState(0);
  const [initialMembers, setInitialMembers] = useState(members);
  const [initialTotalRecipients, setInitialTotalRecipients] = useState(0);
  const [page, setPage] = useState('createBadge');
  const [checkBadgeReplacement, setCheckBadgeReplacement] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const history = useHistory();
  const params = useParams();

  const getDataById = async (id) => {
    const { data } = await getBadgeById(id);
    if (data) {
      setBadge({
        ...data,
        includes: data?.userIds,
        excludes: [],
        selectAll: false
      });
      setMembers({ includes: data?.userIds, excludes: [], selectAll: false });
      setTotalRecipients(data?.userIds?.length);
      setInitialTotalRecipients(data?.userIds?.length);
    }
  };

  const _getPreSignUrl = async () => {
    const query = {
      fileType: 'media-resources',
      fileFormat: 'png'
    };
    const { data, error } = await getPreSignUrlValues(query);
    return data;
  };

  const handleClickCancel = () => {
    if (page === 'setRecipients') {
      setPage('createBadge');
      setMembers(initialMembers);
      setTotalRecipients(initialTotalRecipients);
    } else {
      history.replace('/missions?tab=badge');
    }
  };

  const handleClickSave = () => {
    if (page === 'setRecipients') {
      setCheckBadgeReplacement(true);
    } else {
      submit();
    }
  };

  const submit = async () => {
    const body = badge;
    const { data } = params?.id
      ? await updateBadge(params?.id, body)
      : await postBadge(body);
    if (data) {
      history.replace('/missions?tab=badge');
    }
  };

  useEffect(() => {
    if (params?.id) {
      getDataById(params?.id);
    }
    setInitialMembers(members);
  }, []);

  return (
    <>
      <HeaderPage
        titlePage={title}
        backToUrl={'/missions?tab=badge'}
        isHeaderComposer={true}
      />
      <div
        className={`bg-n-000 h-full overflow-auto ${
          page === 'createBadge' ? 'flex justify-center' : ''
        }`}
      >
        <div className="mt-[24px]">
          {page === 'setRecipients' && (
            <UsersAdminProvider>
              <BadgeRecipients
                badge={badge}
                setBadge={setBadge}
                members={members}
                setMembers={setMembers}
                setInitialMembers={setInitialMembers}
                setTotalRecipients={setTotalRecipients}
                setInitialTotalRecipients={setInitialTotalRecipients}
                setPage={setPage}
                isModalOpen={openConfirmationModal}
                setIsModalOpen={setOpenConfirmationModal}
                checkBadgeReplacement={checkBadgeReplacement}
                setCheckBadgeReplacement={setCheckBadgeReplacement}
                isReadOnly={isReadOnly}
              />
            </UsersAdminProvider>
          )}
          {page === 'createBadge' && (
            <CreateBadgeContents
              badge={badge}
              setBadge={setBadge}
              totalRecipients={totalRecipients}
              setPage={setPage}
            />
          )}
        </div>
      </div>
      <Footer
        save={page === 'createBadge'}
        buttonPrimaryName="Next"
        secondaryButton={true}
        linkButtonName={'Cancel'}
        datacy={'btn-submit'}
        customClass={
          page === 'setRecipients' ? 'w-full mr-[48px]' : 'w-[720px]'
        }
        customButtonContainer={'pr-[0px] py-[14px]'}
        style={{ display: 'flex', 'justify-content': 'center' }}
        handleClick={handleClickSave}
        handleRoute={handleClickCancel}
      />
    </>
  );
}

export default CreateBadge;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';
import InlineDialogSelectMultipleUser from 'components/inline-dialog/InlineDialogSelectMultipleUser';
import InlineDialogSelectUser from 'components/inline-dialog/InlineDialogSelectUser';
import SVGIcon from 'components/shared/SVGIcon';
import { getNewInvolvements } from 'src/components/sidebar/sidebar-create/SidebarCreateHelper';
import { getObjectiveInvolvements } from 'src/utils/ObjectivesHelper';

const InvolvedUsers = ({
  objectiveDetail,
  cloneObj,
  assignOwner,
  assignReviewer,
  setCloneObj,
  objectiveType
}) => {
  const { involvements } = cloneObj || {};

  const selectedOwner = getObjectiveInvolvements(involvements, 'assignee');
  const selectedReviewer = getObjectiveInvolvements(involvements, 'reviewer');
  const selectedFollower = getObjectiveInvolvements(involvements, 'follower');
  const selectedLeader = getObjectiveInvolvements(involvements, 'leader');
  const selectedMember = getObjectiveInvolvements(involvements, 'member');
  const selectedPmo = getObjectiveInvolvements(involvements, 'pmo');

  let excludeOwner = [...selectedReviewer, ...selectedFollower].map(
    ({ userId }) => userId
  );
  let excludeReviewer = [...selectedOwner, ...selectedFollower].map(
    ({ userId }) => userId
  );

  const handleChangeInvolvements = (involvements, type, extendedRoleType) => {
    const finalInvolve = getNewInvolvements(
      involvements,
      type,
      false,
      cloneObj,
      null,
      extendedRoleType,
      objectiveType === 'project'
    );

    setCloneObj({ ...cloneObj, involvements: finalInvolve });
  };

  return (
    <>
      <div className="flex items-center justify-between mb-[16px]">
        <div className="w-[50%]">
          <p className="typography-h200 text-n-900">
            {getObjectiveLocale(
              `Select ${objectiveType === 'project' ? 'Leader' : 'Owner'}`
            )}
          </p>
          {objectiveType === 'project' ? (
            <InlineDialogSelectUser
              objectiveValue={objectiveDetail}
              selectedValue={selectedLeader}
              handleChange={handleChangeInvolvements}
              permission
              type="assignee"
              extendedRoleType="leader"
              position="left"
              header="Leader"
              useRemoveIcon={false}
            />
          ) : (
            <InlineDialogSelectMultipleUser
              objectiveValue={objectiveDetail}
              selectedValue={selectedOwner}
              handleChange={handleChangeInvolvements}
              excludeValue={excludeOwner}
              permission={assignOwner}
              type="assignee"
              role="assignee"
              position="left"
            />
          )}
        </div>
        <div className="w-[50%]">
          <p className="typography-h200 text-n-900">
            {getObjectiveLocale('Select Reviewer')}
          </p>
          <InlineDialogSelectUser
            objectiveValue={objectiveDetail}
            selectedValue={selectedReviewer}
            handleChange={handleChangeInvolvements}
            excludeValue={excludeReviewer}
            permission={assignReviewer}
            selectedOwner={selectedOwner}
            type="assigner"
          />
        </div>
      </div>
      {objectiveType === 'project' && (
        <>
          <div className="flex items-center justify-between mt-[8px]">
            <div className="w-[50%]">
              <p className="typography-h200 text-n-900">
                {getObjectiveLocale('Select Members')}
              </p>
              <InlineDialogSelectMultipleUser
                customTriggerComponent={() => (
                  <CustomTrigger users={selectedMember} />
                )}
                header="Members"
                role="assignee"
                position="bottom"
                objectiveValue={objectiveDetail}
                selectedValue={selectedMember}
                handleChange={handleChangeInvolvements}
              />
            </div>
            <div className="w-[50%]">
              <p className="typography-h200 text-n-900">
                {getObjectiveLocale('Select Stakeholders')}
              </p>
              <InlineDialogSelectMultipleUser
                customTriggerComponent={() => (
                  <CustomTrigger users={selectedFollower} />
                )}
                header="Stakeholders"
                role="follower"
                type="follower"
                position="right"
                objectiveValue={objectiveDetail}
                handleChange={handleChangeInvolvements}
                selectedValue={selectedFollower}
              />
            </div>
          </div>
          <div className="flex items-center justify-between mt-[8px]">
            <div className="w-[50%]">
              <p className="typography-h200 text-n-900">
                {getObjectiveLocale('Select PMO')}
              </p>
              <InlineDialogSelectMultipleUser
                customTriggerComponent={() => (
                  <CustomTrigger users={selectedPmo} />
                )}
                header="PMO"
                role="follower"
                extendedRoleType="pmo"
                position="bottom"
                objectiveValue={objectiveDetail}
                selectedValue={selectedPmo}
                handleChange={handleChangeInvolvements}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

const CustomTrigger = ({ users }) => {
  users = users.map(({ user }) => user) || [];

  return users.length > 0 ? (
    <div className="px-[8px] py-[8px] rounded-full hover:bg-base-30024 w-full">
      {users.length === 1 ? (
        <SingleUser userData={users[0]} />
      ) : (
        users.length > 1 && <AvatarGroup avatars={users} size="24" />
      )}
    </div>
  ) : (
    <div className="text-n-600 px-[8px] py-[8px] rounded-full hover:bg-base-30024 w-full flex items-center">
      <SVGIcon
        size="24"
        fillColor="var(--n-600)"
        iconName="icon-default-user"
        customClass="mr-[8px]"
      />
      {getObjectiveLocale('Select User')}
    </div>
  );
};

export default InvolvedUsers;

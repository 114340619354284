import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';

import { useCreateCycle } from 'context/admin/CreateCycleContext';
import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils';

import RangeCalendarPhases from 'components/admin/Cycles/RangeCalendarPhases';
import BannerBox from 'components/shared/BannerBox';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

import SchedulePhaseCard from './SchedulePhaseCard';
import './SetUpSchedule.scss';

function SetUpSchedule() {
  const {
    cycleData: {
      name,
      selfReview,
      peerReview,
      upwardReview,
      managerReview,
      indirectManagerReview,
      reviewProcessStartsAt,
      reviewProcessEndsAt,
      recurrence,
      showScheduleDate
    },
    changeScheduleCycleDate,
    setRecurrence,
    changePhaseData,
    changeShowScheduleDate
  } = useCreateCycle();

  const dropdownData = [
    {
      index: 1,
      description: 'Every 3 Months',
      periodValue: 3,
      periodType: 'month'
    },
    {
      index: 2,
      description: 'Every 6 Months',
      periodValue: 6,
      periodType: 'month'
    },
    {
      index: 3,
      description: 'Every Year',
      periodValue: 12,
      periodType: 'month'
    }
  ];

  let phasesArray = [
    {
      key: selfReview ? getObjectiveLocale('Self Review') : null,
      id: 'selfReview',
      ...selfReview
    },
    {
      key: peerReview ? getObjectiveLocale('Peer Review') : null,
      id: 'peerReview',
      ...peerReview
    },
    {
      key: upwardReview ? getObjectiveLocale('Upward Review') : null,
      id: 'upwardReview',
      ...upwardReview
    },
    {
      key: managerReview ? getObjectiveLocale('Manager Review') : null,
      id: 'managerReview',
      ...managerReview
    },
    {
      key: indirectManagerReview
        ? getObjectiveLocale('Indirect Manager Review')
        : null,
      id: 'indirectManagerReview',
      ...indirectManagerReview
    }
  ];

  let removeNullPhasesArray = phasesArray.filter((phase) => phase.key !== null);

  const [isRecurringDisabled, setIsRecurringDisabled] = useState(true);
  const [recurringToggle, setRecurringToggle] = useState(false);
  const [openModalListTrack, setOpenModalListTrack] = useState(false);
  const [selectedDropdownData, setSelectedDropdownData] = useState(
    dropdownData[2]
  ); // Default = Every year
  const [nextRecurringDate, setNextRecurringDate] = useState({
    start: null,
    end: null
  });

  const modalListTrackRef = useRef();

  const selectDropdown = (data) => {
    setOpenModalListTrack(false);
    setSelectedDropdownData(data);
    setRecurrence(data.periodValue, data.periodType);
  };

  useClickOutside(modalListTrackRef, () => setOpenModalListTrack(false));

  const onResetSchedule = () => {
    setRecurringToggle(false);
    setRecurrence(null, null);
    changeScheduleCycleDate('start', null);
    changeScheduleCycleDate('end', null);
    changeShowScheduleDate(false);
    removeNullPhasesArray.forEach((phase) => {
      changePhaseData(phase.id, 'startsAt', null);
      changePhaseData(phase.id, 'endsAt', null);
      changePhaseData(phase.id, 'showDueDate', false);
      changePhaseData(phase.id, 'reminders', []);
    });
  };

  const handleSetRecurrence = () => {
    if (recurringToggle) {
      setRecurrence(
        selectedDropdownData?.periodValue,
        selectedDropdownData?.periodType
      );
    } else {
      setRecurrence(null, null);
    }
  };

  useEffect(() => {
    handleSetRecurrence();
  }, [recurringToggle]);

  useEffect(() => {
    if (recurrence && reviewProcessStartsAt && reviewProcessEndsAt) {
      let start = dayjs(reviewProcessStartsAt)
        .add(recurrence.interval, 'months')
        .format('DD MMM YYYY');
      let end = dayjs(reviewProcessEndsAt)
        .add(recurrence.interval, 'months')
        .format('DD MMM YYYY');
      setNextRecurringDate({ start, end });
    }
  }, [recurrence]);

  useEffect(() => {
    if (recurrence?.hasOwnProperty('interval')) {
      setRecurringToggle(true);
      let recurrenceObject = dropdownData.find(
        (data) => data.periodValue === recurrence.interval
      );
      setSelectedDropdownData(recurrenceObject);
    }
  }, []);

  useEffect(() => {
    if (reviewProcessStartsAt && reviewProcessEndsAt) {
      setIsRecurringDisabled(false);
    } else {
      setIsRecurringDisabled(true);
    }
  }, [reviewProcessStartsAt]);

  return (
    <div className="set-up-schedule-container pl-[24px] pt-[24px] max-w-[768px] pb-[200px]">
      <div className="set-up-schedule pb-[40px]">
        <h5 className="typography-h500 mb-[4px]">Set Up Cycle Schedule</h5>
        <p className="typography-secondary">
          Configure schedule for <span className="typography-h400">{name}</span>
        </p>
        <div className="flex mt-[16px] mb-[16px]">
          <div className="mr-[16px]">
            <h6 className="typography-h100 typography-secondary mb-[8px]">
              Choose your start and end date
            </h6>
            <RangeCalendarPhases
              calendarContext="setSchedule"
              withResetButton={true}
              onResetSchedule={onResetSchedule}
              phaseArray={removeNullPhasesArray}
              withDefaultDateAnnualy={false}
              customFormatDate={{
                month: 'long',
                day: 'numeric',
                year: 'numeric'
              }}
              useContainerClassName={false}
            />
          </div>
        </div>
        <BannerBox
          text="System will send emails when review cycle is started and closed"
          type="info"
        />
        {/* Advance Configuration */}
        <div className="mt-[16px]">
          <p className="text-n-800 typography-h300">
            {getObjectiveLocale('Advanced Configuration')}
          </p>
          <Checkbox
            name="show-hide-date"
            id="show-hide-date"
            checked={showScheduleDate}
            onChange={() => changeShowScheduleDate(!showScheduleDate)}
            dataCy="show-hide-date"
            customContainerClass="py-[16px]"
            disabled={isRecurringDisabled}
          >
            <span
              className={`typography-paragraph ml-[8px] ${
                isRecurringDisabled ? 'text-n-600' : 'text-n-900'
              }`}
            >
              {getObjectiveLocale(
                'View schedule on performance review cycle name'
              )}
            </span>
          </Checkbox>
        </div>
        <div className="recurring-cycle-box bg-n-000">
          <div>
            <h5 className="typography-h400 mb-[4px]">Recurring Cycle</h5>
            <p className="typography-secondary">
              {isRecurringDisabled
                ? 'Set up your phase schedule first'
                : 'Set up your recurrence setting'}
            </p>
            {recurringToggle && (
              <div className="mt-[16px]">
                <p className="typography-h100">The cycle will repeat</p>
                <div className="wrapper-dropdown-period flex items-center relative mt-[8px]">
                  <div
                    className="dropdown-template-period"
                    onClick={() => setOpenModalListTrack(true)}
                  >
                    {selectedDropdownData?.description}
                    <SVGIcon
                      customClass="icon-fold"
                      iconName="icon-unfold_more"
                      fillColor="var(--n-600)"
                      size={24}
                    />
                  </div>
                  <p className="typography-h100 typography-secondary">
                    Next review cycle will be on {nextRecurringDate.start} and
                    end on {nextRecurringDate.end}
                  </p>
                  {openModalListTrack && (
                    <div
                      className="list-filter-component"
                      ref={modalListTrackRef}
                    >
                      {dropdownData.map((data, index) => (
                        <div
                          key={index}
                          className="row-filter"
                          data-cy={'dropdown-' + index}
                          onClick={() => selectDropdown(data)}
                        >
                          {data.description}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <SmallToggleSwitchPurple
            isChecked={recurringToggle}
            onChange={() => {
              !isRecurringDisabled && setRecurringToggle(!recurringToggle);
            }}
            addClass="recurring-toggle-position"
            disabled={isRecurringDisabled}
          />
        </div>
      </div>
      {removeNullPhasesArray.length > 0 && (
        <div className="set-up-phase-schedule">
          <h5 className="typography-h500 mb-[4px]">Set Up Phase Schedule</h5>
          <p className="typography-secondary">
            Configure your schedule and reminder for each phases
          </p>
          {!reviewProcessStartsAt && !reviewProcessEndsAt && (
            <BannerBox
              text="Please set cycle schedule in order to use this option"
              type="info"
              customClass="mt-[24px] mb-[16px]"
            />
          )}
          {reviewProcessStartsAt &&
            reviewProcessEndsAt &&
            removeNullPhasesArray.map((phase, index) => (
              <SchedulePhaseCard key={index} phase={phase} />
            ))}
        </div>
      )}
    </div>
  );
}

export default SetUpSchedule;

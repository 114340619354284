import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

import useDebounce from '../../hooks/useDebounce';
import './ToastNotif.scss';

const ToastNotifContent = ({
  warning,
  showNotif,
  message,
  datacy,
  children,
  isToastComposer = false,
  usePortal
}) => {
  const [isNotifChanged, setIsNotifChanged] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const debouncedMessage = useDebounce(message, 300);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else {
      setIsNotifChanged(true);
    }
    setTimeout(
      () => {
        setIsVisible(showNotif);
      },
      showNotif ? 0 : 300
    );
  }, [showNotif, firstRender]);

  return (
    <div
      data-cy={datacy}
      className={`
        notif-box animated z-9999999
        ${isToastComposer ? 'toast-composer' : ''}
        ${!isNotifChanged || !isVisible ? 'invisible ' : ''}
        ${warning ? 'h-warning ' : 'h-success '}
        ${isNotifChanged && (showNotif ? 'fadeInDown' : 'fadeOutUp')}
        ${
          usePortal
            ? 'max-w-[700px] left-[50%] translate-x-[-50%]'
            : message?.length > 125
            ? 'max-w-[700px] left-[30%]'
            : 'max-w-[500px] left-[40%]'
        }
      `}
    >
      <SVGIcon
        iconName={warning ? 'icon-info' : 'icon-check_circle'}
        size={24}
        fillColor={warning ? 'var(--r-600)' : 'var(--g-600)'}
        customClass="mr-[16px]"
      />
      <p className="typography-h400 typography-paragraph text-n-900 whitespace-pre-line">
        {getObjectiveLocale(message) || getObjectiveLocale(debouncedMessage)}
      </p>
      {children}
    </div>
  );
};

const ToastNotif = (props) => {
  const { usePortal } = props;

  let toastNotif = document.querySelectorAll(`[id^=toast-notif]`)[0];

  if (usePortal) {
    return ReactDOM.createPortal(
      <div className="w-full">
        <ToastNotifContent {...props} />
      </div>,
      toastNotif
    );
  }

  return <ToastNotifContent {...props} />;
};

export default ToastNotif;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTeamDetail } from 'context/team/TeamDetailContext';
import useSidebar from 'hooks/useSidebar';

import ContentTabSwitcher from 'src/components/design-system/ContentTabSwitcher/ContentTabSwitcher';

import SidebarTeam from './SidebarTeam';
import TeamActivities from './TeamActivities';
import TeamFeedbacks from './TeamFeedbacks';
import TeamSquads from './TeamSquads';

const TeamOverview = ({ setLastContributor }) => {
  const params = useParams();
  const topSidebar = useSidebar((state) => state?.topSidebar);
  const { data: teamDetail, refetchTeam, teamId } = useTeamDetail();
  const [currentTab, setCurrentTab] = useState(
    params?.teamType == 'squad' ? 'activities' : 'squad'
  );

  const tabsList = [
    {
      id: 'squad',
      name: 'Squad',
      dataCy: 'tab-squad',
      isVisible: params?.teamType == 'tribe'
    },
    {
      id: 'activities',
      name: 'Activities',
      dataCy: 'tab-activities',
      isVisible: true
    },
    {
      id: 'feedback',
      name: 'Team Feedback',
      dataCy: 'tab-feedback',
      isVisible: params?.teamType == 'squad'
    }
  ].filter(({ isVisible }) => isVisible);

  useEffect(() => {
    setCurrentTab(params?.teamType == 'squad' ? 'activities' : 'squad');
  }, [params?.teamType]);

  return (
    <div className="mt-[24px]">
      <div className="w-[calc(100%-360px)]">
        <ContentTabSwitcher
          tabs={tabsList}
          onChange={(tab) => setCurrentTab(tab)}
          defaultTabIndex={tabsList.findIndex((tab) => tab?.id === currentTab)}
        />
        {currentTab === 'squad' && <TeamSquads teamId={teamId} />}
        {currentTab === 'activities' && (
          <TeamActivities
            teamId={teamId}
            setLastContributor={setLastContributor}
          />
        )}
        {currentTab === 'feedback' && <TeamFeedbacks teamId={teamId} />}
      </div>

      {teamDetail && (
        <div
          className="sidebar-my-goals sidebar-compact !w-[400px]"
          style={{
            top: `${topSidebar || 0}px`,
            height: `calc(100vh - ${topSidebar || 0}px)`
          }}
        >
          <SidebarTeam
            teamDetail={teamDetail}
            currentTab={currentTab}
            refetchTeam={refetchTeam}
          />
        </div>
      )}
    </div>
  );
};

export default TeamOverview;

import React from 'react';

import { useCreateObjective } from 'context/CreateObjectiveContext';
import { useUser } from 'context/UserContext';

import CreateObjectiveCard from './CreateObjectiveCard';
import CreateObjectiveGroup from './CreateObjectiveGroup';

function CreateObjectivePanel({ type, setActiveLevel }) {
  const { config } = useUser();

  const {
    lists,
    errorMessage,
    groupedList,
    addChild,
    changeValue,
    deleteGoal,
    changeGroupType,
    isGrouped,
    errorCode
  } = useCreateObjective();

  function renderList(root, level, errorWithMessage) {
    let teamConfig = type === 'team' && level > 1;
    let isProject = root.isProject;
    let maximumGoalLevelConfig =
      config.maximumGoalLevel != null ? level > config.maximumGoalLevel : false;
    if (maximumGoalLevelConfig || teamConfig) {
      return;
    }

    return (
      <CreateObjectiveCard
        key={root.id}
        isParent={level == 0}
        level={level}
        inputValue={root.name}
        id={root.id}
        fakeParentId={root.fakeParentId}
        objective={root}
        errorWithMessage={errorWithMessage}
        type={type}
        setActiveLevel={setActiveLevel}
        lists={lists}
        addChild={addChild}
        changeValue={changeValue}
        deleteGoal={deleteGoal}
      >
        {type &&
          type !== 'task' &&
          (isProject
            ? config.permissions?.projectSubGoalsAdd
            : config.permissions?.goalSubGoalsAdd) &&
          root.children &&
          root.children.map((c, index) => (
            <>{renderList(c, level + 1, errorWithMessage?.children?.[index])}</>
          ))}
      </CreateObjectiveCard>
    );
  }
  return (
    <div className="panel-container" id="panel-container-create-goal">
      {type !== 'team' &&
        !location.pathname.includes('/add/toteam') &&
        (!isGrouped
          ? lists.map((list, index) => {
              return (
                <div
                  className="p-[16px] mb-[20px] rounded bg-n-000 shadow-raised"
                  key={index}
                >
                  <ul className={'list-none m-0 pl-[0px]'}>
                    {renderList(list, 0, errorMessage?.[index])}
                  </ul>
                </div>
              );
            })
          : groupedList.map((group, index) => {
              return (
                <div className="mt-[8px]" key={group.id}>
                  <CreateObjectiveGroup
                    data={group}
                    groupIndex={index}
                    type={type}
                    setActiveLevel={setActiveLevel}
                    errorMessage={
                      errorCode == 'empty-type'
                        ? errorMessage?.find((msg) => msg.index == index)
                        : errorMessage?.[index]
                    }
                    errorCode={errorCode}
                    groupedList={groupedList}
                    changeGroupType={changeGroupType}
                  />
                </div>
              );
            }))}

      {type === 'team' && (
        <div className="panel">
          <ul>{renderList(lists[0], 0, errorMessage?.[0])}</ul>
        </div>
      )}

      {location.pathname.includes('/add/toteam') && (
        <div className="panel">
          {lists.map((list, index) => (
            <ul className="mb-[10px]" key={index}>
              <CreateObjectiveCard
                key={list.id}
                isParent={true}
                level={0}
                inputValue={list.name}
                id={list.id}
                fakeParentId={list.fakeParentId}
                objective={list}
                errorWithMessage={errorMessage?.[0]}
                type={type}
                index={index}
                lists={lists}
                addChild={addChild}
                changeValue={changeValue}
                deleteGoal={deleteGoal}
              />
            </ul>
          ))}
        </div>
      )}
    </div>
  );
}

export default CreateObjectivePanel;

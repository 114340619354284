import React, { useState } from 'react';

import ctl from '@netlify/classnames-template-literals';
import isEqual from 'lodash/isEqual';

import { useCreateObjective } from 'context/CreateObjectiveContext';
import { useMetrics } from 'context/MetricsContext';
import { useUser } from 'context/UserContext';
import { mappingColor } from 'utils/HelperUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

import CreateObjectiveCard from './CreateObjectiveCard';

const areEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

const ObjectiveList = ({
  inputCy,
  list,
  level,
  type,
  setActiveLevel,
  groupId,
  errorMessage
}) => {
  const { config } = useUser();
  const { groupedList, addChildGrouped, changeValue, deleteGoal } =
    useCreateObjective();

  let teamConfig = type === 'team' && level > 1;
  let isProject = list.isProject;
  let maximumGoalLevelConfig =
    config.maximumGoalLevel != null ? level > config.maximumGoalLevel : false;
  if (maximumGoalLevelConfig || teamConfig) {
    return;
  }

  const addChildFunction = (id, level, fakeParentId, name, objective) => {
    addChildGrouped(id, level, fakeParentId, name, objective, groupId);
  };

  return (
    <CreateObjectiveCard
      key={list.id}
      isParent={level == 0}
      level={level}
      inputValue={list?.name}
      id={list?.id}
      fakeParentId={list.fakeParentId}
      objective={list}
      errorWithMessage={errorMessage}
      type={type}
      setActiveLevel={setActiveLevel}
      lists={groupedList}
      addChild={addChildFunction}
      changeValue={changeValue}
      deleteGoal={deleteGoal}
      autoFocus={false}
      inputCy={'create-objective-input-' + inputCy}
    >
      {type &&
        type !== 'task' &&
        (isProject
          ? config.permissions?.projectSubGoalsAdd
          : config.permissions?.goalSubGoalsAdd) &&
        list.children &&
        list.children.map((child, index) => (
          <ObjectiveList
            list={child}
            level={level + 1}
            key={child.id}
            type={type}
            setActiveLevel={setActiveLevel}
            errorMessage={errorMessage?.children?.[index]}
            lists={groupedList}
            groupId={groupId}
            inputCy={`${inputCy}-${index}`}
          />
        ))}
    </CreateObjectiveCard>
  );
};

const CreateObjectiveGroup = React.memo(
  ({
    groupIndex,
    data,
    type,
    setActiveLevel,
    errorMessage,
    groupedList,
    changeGroupType,
    errorCode
  }) => {
    const { objectiveCategories } = useMetrics();
    const {
      config: { mandatoryObjectivesCategory, useDefaultObjectiveCategory }
    } = useUser();

    const emptyList = [
      { id: null, name: 'No type selected', colorHex: '000', isDefault: false }
    ];

    let categorylists = [
      ...(!mandatoryObjectivesCategory ? emptyList : []),
      ...objectiveCategories
    ];

    let selectedCategories = groupedList.map(
      (list) => list.objectiveCategoryId
    );

    selectedCategories = selectedCategories.filter(
      (list) => list != data.objectiveCategoryId
    );

    categorylists = categorylists.filter(
      (list) => !selectedCategories.includes(list.id)
    );

    let defaultObjectiveCategory = useDefaultObjectiveCategory
      ? categorylists.find(({ id }) => id == data.objectiveCategoryId) ||
        emptyList[0]
      : emptyList[0];

    const [selectedGroup, setSelectedGroup] = useState(
      defaultObjectiveCategory
    );

    const changeDropdown = (value) => {
      setSelectedGroup(value);
      changeGroupType({
        id: data.id,
        categoryId: value.id,
        categoryWeight: value?.weight || 0
      });
    };

    const errorNotSelectedType =
      mandatoryObjectivesCategory &&
      errorCode == 'empty-type' &&
      errorMessage?.message;

    const getMappingColor = (color, bg = true) => {
      const { twBg, twText } = mappingColor(color, 'tw');
      return bg ? { twBg } : twText;
    };

    return (
      <div className="bg-n-000 px-[16px] pt-[24px] rounded mb-[24px]">
        <Dropdown customClass="mb-[16px]">
          <Dropdown.Trigger dataCy={`dropdown-category-${groupIndex}`}>
            <div
              className={`min-w-[280px] h-[32px] border border-solid rounded bg-n-000 pl-[16px] pr-[8px] flex flex-row space-between items-center mr-[8px]`}
              style={{
                borderColor: errorNotSelectedType
                  ? 'var(--r-600)'
                  : 'var(--n-400)'
              }}
            >
              <p className="typography-h400 text-n-900">
                {getObjectiveLocale(`Goal Type`)}:{' '}
                <span
                  className={`typography-h400 ${getMappingColor(
                    selectedGroup?.colorHex,
                    false
                  )}`}
                >
                  {selectedGroup?.name}
                </span>
              </p>
              <SVGIcon
                iconName="icon-arrow_drop_down"
                size="24"
                fillColor="var(--n-800)"
              />
            </div>
          </Dropdown.Trigger>
          <p className="text-r-600 typoghraphy-paragraph mt-[4px] ml-[16px]">
            {getObjectiveLocale(errorNotSelectedType)}
          </p>
          <Dropdown.MenuItems
            maxHeight={300}
            customClass={'w-[280px]'}
            type="fixed"
          >
            {categorylists.map((category, index) =>
              category.id ? (
                <Dropdown.MenuItem
                  key={category?.id}
                  onClick={() => changeDropdown(category)}
                  id={category?.name}
                  dataCyParent="category-item"
                  index={index}
                >
                  <div className="flex items-center w-full">
                    <div
                      className={ctl(
                        `w-[20px] h-[20px] mr-[8px] rounded-full ${
                          getMappingColor(category?.colorHex).twBg
                        }`
                      )}
                    />
                    <span>{category?.name}</span>
                  </div>
                </Dropdown.MenuItem>
              ) : (
                <Dropdown.MenuItem
                  divider
                  onClick={() => changeDropdown(category)}
                  dataCyParent="category-item"
                  index={index}
                  key={0}
                >
                  {getObjectiveLocale(`No type`)}
                </Dropdown.MenuItem>
              )
            )}
          </Dropdown.MenuItems>
        </Dropdown>
        {data?.lists?.map((dt, index) => (
          <div className="pb-[16px]" key={dt.id}>
            <ul className="list-none m-[0px] pl-[0px]">
              <ObjectiveList
                groupId={data?.id}
                list={dt}
                level={0}
                type={type}
                setActiveLevel={setActiveLevel}
                errorMessage={errorMessage?.[index]}
                inputCy={`group-${groupIndex}-list-${index}`}
              />
            </ul>
          </div>
        ))}
      </div>
    );
  },
  areEqual
);

CreateObjectiveGroup.displayName = 'CreateObjectiveGroup';

export default CreateObjectiveGroup;

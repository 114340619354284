import { useInfiniteQuery } from 'react-query';
import { getParentSuggestions } from 'client/ObjectivesClient';

const GetParentObjectiveSuggestions = ({ additionalParams, q, olderThan }) => {
  const fetchObjectives = pageParam => {
    let params = {
      reviewsVisibility: 1,
      q: q,
      offset: pageParam,
      ...additionalParams
    };

    return getParentSuggestions(params);
  };

  const {
    data,
    status,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(
    ['parentSuggestions', { q }],
    ({ pageParam }) => fetchObjectives(pageParam),
    {
      getNextPageParam: lastPage =>
        data && data?.length >= 20 ? lastPage.pagination?.nextOffset : null
    }
  );

  return {
    data,
    status,
    isFetchingMore: isFetchingNextPage,
    fetchMore: fetchNextPage,
    hasMore: hasNextPage
  };
};

export default GetParentObjectiveSuggestions;

import React, { useState } from 'react';

import 'scss/culture/post-type/_feed-feeling';

import Config from 'utils/Config';
import { getCultureLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import ModalPostFeeling from 'components/modal/culture/ModalPostFeeling';

function PostFeeling({ content, stickerUrl, postId, groupDetail }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {isModalOpen && (
        <ModalPostFeeling
          eventOnClick={(e) => {
            e?.stopPropagation?.();
            setIsModalOpen(false);
          }}
          groupDetail={groupDetail}
        />
      )}
      {content?.locked && (
        <div className="feed-feeling-locked">
          <p className="typography-h700">
            {getCultureLocale('Oops! Feeling locked!')}
          </p>
          <img src={Config.asset.culture.lockedFeelingIcon} />
          <p className="typography-h400 desc">
            {getCultureLocale(
              'Have you shared your feeling today? You can see others feelings after you share yours!'
            )}
          </p>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setIsModalOpen(true);
            }}
          >
            {getCultureLocale('Share your feeling')}
          </Button>
        </div>
      )}
      {!content?.locked && content?.type && (
        <div
          className="feed-feeling"
          style={{ backgroundColor: '#' + content?.colorCode }}
          data-cy={`post-feeling-${postId}`}
        >
          <div className="type">
            <div className="type-text">
              <span className="typography-h600 description">
                {getCultureLocale("I'm feeling")}
              </span>
              <span
                className="typography-h700 description-type"
                data-cy="feeling-type"
              >
                {content?.type}
              </span>
              <span className="typography-h600 description">
                {getCultureLocale('Because Of')}
              </span>
            </div>
            <div className="type-sticker">
              <AWSImage src={stickerUrl} dataCy="feeling-sticker" />
            </div>
          </div>
          <div className="caption">
            <span className="typography-h500" data-cy="feeling-reason">
              {content?.reason}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default PostFeeling;

import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import {
  getAlignmentsCount,
  getObjectivesCount,
  getScore
} from 'client/UserProfile';
import { useProfileFilter } from 'context/ProfileFilterContext';
import { useProfileSkill } from 'context/ProfileSkillContext';
import { useProfileValue } from 'context/ProfileValueContext';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ListEmptyState from 'components/shared/ListEmptyState';

import CircleChart from './CircleChart';
import GoalAlignment from './GoalAlignment';
import OverallPerformance from './OverallPerformance';
import ValuesSkillCard from './ValuesSkillCard';

function OverviewNonTeam(props) {
  const { isHavePerfRole } = props;
  const permission = props.permission;
  const CALCULATE_ACCOMPLISHMENTS = (object) => {
    if (object) {
      return ((object.completed + object.reviewed) / object.sum) * 100;
    } else {
      return 0;
    }
  };

  const DEFAULT_ACTIVITIES = {
    running: 0,
    reviewed: 0,
    completed: 0
  };

  const DEFAULT_ALIGNMENT = {
    hasParent: 0,
    noParent: 0
  };
  const [taskActivities, setTaskActivities] = useState(DEFAULT_ACTIVITIES);
  const [goalActivities, setGoalActivities] = useState(DEFAULT_ACTIVITIES);
  const [goalAlignment, setGoalAlignment] = useState(DEFAULT_ALIGNMENT);
  const [userScoring, setUserScoring] = useState('');
  const [error, setError] = useState(null);
  const {
    strengths: strengthsValue,
    weaknesses: weaknessesValue,
    getWeaknessData: getWeaknessDataValue,
    getStrengthData: getStrengthDataValue
  } = useProfileValue();
  const { filter } = useProfileFilter();
  const {
    strengths: strengthsSkill,
    weaknesses: weaknessesSkill,
    getWeaknessData: getWeaknessDataSkill,
    getStrengthData: getStrengthDataSkill
  } = useProfileSkill();

  let goalAccomplishments = CALCULATE_ACCOMPLISHMENTS(goalActivities);
  let taskAccomplishments = CALCULATE_ACCOMPLISHMENTS(taskActivities);
  const profileSkillStrengthSee = usePermission(
    'profileSkillStrengthSee',
    permission
  );
  const profileValueStrengthSee = usePermission(
    'profileValueStrengthSee',
    permission
  );

  const getUserActivity = async (type) => {
    const userId = props.userId;
    const query = {
      ...filter,
      type: type,
      placementId: props.placementId
    };
    const { data, error } = await getObjectivesCount(query, userId);
    if (data) {
      const sum = Object.values(data).reduce((a, b) => a + b);
      data.sum = sum;
      if (type === 'goal') {
        setGoalActivities(data);
      } else {
        setTaskActivities(data);
      }
    }
  };

  const getUserAlignment = async () => {
    const userId = props.userId;
    const query = {
      ...filter,
      placementId: props.placementId,
      type: 'goal'
    };
    const { data, error } = await getAlignmentsCount(query, userId);
    if (data) {
      setGoalAlignment(data);
    }
  };

  const getUserScoring = async () => {
    const query = {
      ...filter
    };

    const { data, error } = await getScore(query, props.userId);
    if (data) {
      setUserScoring(data?.scores);
    }
  };

  let isEmptyData =
    !isHavePerfRole &&
    !permission?.profileValueStrengthSee &&
    !permission?.profileValueImprovementSee &&
    !permission?.profileSkillStrengthSee &&
    !permission?.profileSkillImprovementSee;

  useEffect(() => {
    if (!props.isAppCulture) {
      permission?.profileGoalOverallAccomplishment && getUserActivity('goal');
      permission?.profileTaskOverallAccomplishment && getUserActivity('task');
      permission?.profileGoalOverallPerformance &&
        permission?.profileTaskOverallPerformance &&
        getUserScoring();
      permission?.profileGoalOverallAlignment && getUserAlignment();
    }
    profileSkillStrengthSee && getStrengthDataSkill(props.userId);
    profileValueStrengthSee && getStrengthDataValue(props.userId);
    if (isHavePerfRole) {
      permission?.profileSkillImprovementSee &&
        getWeaknessDataSkill(props.userId);
      permission?.profileValueImprovementSee &&
        getWeaknessDataValue(props.userId);
    }
  }, [JSON.stringify(filter)]);

  return (
    <div className="h-full">
      <div className="overview-section mb-[64px]">
        <div className="row">
          {/* Goal Accomplishments Components */}
          {isHavePerfRole && (
            <>
              {permission?.profileGoalOverallAccomplishment && (
                <CircleChart
                  title={getObjectiveLocale('Goal Accomplishments')}
                  highchartValue={goalAccomplishments.toFixed(0)}
                  runningValue={goalActivities.running}
                  completedValue={goalActivities.completed}
                  reviewedValue={goalActivities.reviewed}
                  color1={'#3ad8b6'}
                  color2={'#bcfff0'}
                  datacy={'goal-accomplishment'}
                />
              )}
              {/* Task Accomplishments Components */}
              {permission?.profileTaskOverallAccomplishment && (
                <CircleChart
                  title={getObjectiveLocale('Task Accomplishments')}
                  highchartValue={taskAccomplishments.toFixed(0)}
                  runningValue={taskActivities.running}
                  completedValue={taskActivities.completed}
                  reviewedValue={taskActivities.reviewed}
                  color1={'#68d600'}
                  color2={'#d6ffa8'}
                  datacy={'task-accomplishment'}
                />
              )}
              {permission?.profileGoalOverallAlignment && (
                <div className="col-md-4" data-cy="goal-alignment">
                  <GoalAlignment
                    alignment={goalAlignment.hasParent}
                    total={goalAlignment.hasParent + goalAlignment.noParent}
                  />
                </div>
              )}
              {permission?.profileGoalOverallPerformance && (
                <div className="col-md-4" data-cy="goal-performance">
                  <OverallPerformance userScoring={userScoring} type="goal" />
                </div>
              )}
              {permission?.profileTaskOverallPerformance && (
                <div className="col-md-4" data-cy="task-performance">
                  <OverallPerformance userScoring={userScoring} type="task" />
                </div>
              )}
            </>
          )}
          {profileValueStrengthSee && (
            <ValuesSkillCard
              typeCard="Strength"
              titleCard="Values"
              typeOverview="value"
              color="g"
              data={strengthsValue}
              withIcon={true}
              datacy={'value-strength-see'}
              userId={props.userId}
            />
          )}
          {isHavePerfRole && permission?.profileValueImprovementSee && (
            <ValuesSkillCard
              typeCard="Improvement"
              titleCard="Values"
              typeOverview="value"
              color="y"
              data={weaknessesValue}
              withIcon={true}
              datacy={'value-improvement-see'}
              userId={props.userId}
            />
          )}
          {profileSkillStrengthSee && (
            <ValuesSkillCard
              typeCard="Strength"
              typeOverview="competency"
              titleCard="Skills"
              color="g"
              data={strengthsSkill}
              datacy={'skill-strength-see'}
              userId={props.userId}
            />
          )}
          {isHavePerfRole && permission?.profileSkillImprovementSee && (
            <ValuesSkillCard
              typeCard="Improvement"
              typeOverview="competency"
              titleCard="Skills"
              color="y"
              data={weaknessesSkill}
              datacy={'skill-improvement-see'}
              userId={props.userId}
            />
          )}
          {isEmptyData && (
            <ListEmptyState
              emptyIcon="icon-no-profile-overview"
              containerClassname="w-full h-full"
              title={getObjectiveLocale('No result found')}
              subtitle={getObjectiveLocale(
                'Try to adjust the filter setting and try again.'
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(OverviewNonTeam);

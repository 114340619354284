import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useAuth } from 'context/AuthContext';
import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale, getUTCOffset } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import ToastNotif from 'components/shared/ToastNotif';

dayjs.extend(utc);

const ReminderList = ({
  reminderObj,
  reminderList,
  endsAt,
  changeStateReminderKey,
  changeStateRemindsAt,
  handleDeleteReminder,
  indexProp
}) => {
  const {
    data: {
      config: { reviewReminderTime }
    }
  } = useAuth();
  const dropdownRef = useRef();

  const [toastData, setToastData] = useState({ show: false, msg: '' });

  const reminderDropdownList = [
    {
      key: 'deadlineDay',
      description: 'On the day of deadline'
    },
    {
      key: 'beforeDeadline',
      description: 'Before Deadline'
    }
  ];

  let endsAtMoment = dayjs(endsAt);
  let remindsAtMoment = dayjs(reminderObj.remindsAt);
  let utcTime = dayjs.utc(reviewReminderTime, 'h:mm a');
  let localTime = dayjs(utcTime).local().format('h:mm a');

  const [dateReminderString, setDateReminderString] = useState('');
  const [daysValue, setDaysValue] = useState(0);
  const [selectedDropdownData, setSelectedDropdownData] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useClickOutside(dropdownRef, () => setShowDropdown(false));

  const selectDropdown = (data) => {
    setShowDropdown(false);
    setSelectedDropdownData(data);
    changeStateReminderKey(data, indexProp);
  };

  const handleChangeDaysValue = (inputtedDays) => {
    const isNaN = Number.isNaN(inputtedDays);
    setDaysValue(inputtedDays);
    if (isNaN) {
      if (!toastData?.show) {
        setToastData({
          show: true,
          msg: getObjectiveLocale(
            'Please enter a valid number for reminder date'
          )
        });
        setTimeout(() => {
          setToastData({ show: false, msg: '' });
        }, 3000);
      }
    } else {
      let calculatedDate = dayjs(endsAt)
        .subtract(inputtedDays, 'days')
        .toISOString();
      changeStateRemindsAt(calculatedDate, indexProp);
    }
  };

  useEffect(() => {
    let filterSelectedDropdown = reminderDropdownList.find(
      (dropdownList) => dropdownList.key === reminderObj.key
    );
    let defaultDateString = dayjs(reminderObj.remindsAt).format('DD MMM YYYY');

    let defaultDaysValue;
    if (reminderObj.key === 'deadlineDay') {
      defaultDaysValue = '';
    } else {
      defaultDaysValue = endsAtMoment.diff(remindsAtMoment, 'days');
    }

    setDateReminderString(defaultDateString);
    setSelectedDropdownData(filterSelectedDropdown);
    setDaysValue(defaultDaysValue);
  }, [reminderList]);

  return (
    <div className="reminder-list-container mt-[16px]">
      <ToastNotif
        showNotif={toastData?.show}
        message={toastData?.msg}
        warning
      />
      <p className="typography-h100 typography-secondary">
        Select your reminder date
      </p>
      <div className="single-reminder-list mt-[8px]">
        <div className="flex items-center left-side">
          <div className="input-days-container">
            {reminderObj.key === 'beforeDeadline' && (
              <>
                <input
                  type="number"
                  className="small-input-number"
                  onChange={(e) => {
                    handleChangeDaysValue(
                      parseInt(e.target.value == 0 ? '' : e.target.value)
                    );
                  }}
                  value={daysValue}
                  min="1"
                  data-cy="value-percentage"
                />
                <span className="ml-[8px] mr-[16px]">days</span>
              </>
            )}
          </div>
          <div className="wrapper-dropdown-period mb-[0px] flex items-center relative">
            <div
              className="dropdown-template-period"
              onClick={() => setShowDropdown(true)}
            >
              {selectedDropdownData?.description}
              <SVGIcon
                customClass="icon-fold"
                iconName="icon-unfold_more"
                fillColor="var(--n-600)"
                size={24}
              />
            </div>
            <p className="typography-h100 typography-secondary">
              Reminder will be sent on, {dateReminderString} at {localTime}{' '}
              {getUTCOffset()}
            </p>
            {showDropdown && (
              <div className="list-filter-component" ref={dropdownRef}>
                {reminderDropdownList.map((data, index) => (
                  <div
                    key={index}
                    className="row-filter"
                    data-cy={'dropdown-' + index}
                    onClick={() => selectDropdown(data)}
                  >
                    {data.description}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {reminderList.length > 1 && (
          <SVGIcon
            size="24"
            iconName="icon-delete"
            fillColor="var(--n-600)"
            customClass="cursor-pointer"
            onClick={() => handleDeleteReminder(indexProp)}
          />
        )}
      </div>
    </div>
  );
};

export default ReminderList;

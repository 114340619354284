import React from 'react';
import { useQuery } from 'react-query';

import { useObserveChildToggle } from '../utils/barHelper';
import ChildBarWrapper from './ChildBarWrapper';
import SingleBarComponent from './SingleBarComponent';

const SingleBarContainer = ({
  objective,
  section,
  group,
  subContentOptions
}) => {
  const singleObjectiveOptions = subContentOptions?.singleObjectiveOptions;

  const getQueryKey = singleObjectiveOptions?.getQueryKey;

  const queryKey = getQueryKey(objective?.id);
  const { data: dataQuery } = useQuery(queryKey, {
    staleTime: 1000,
    refetchOnMount: false
  });

  const dataObjective = dataQuery?.data ? dataQuery.data : dataQuery;

  const { startDate, dueDate } = dataObjective || objective;

  //show sub objective based on sidebar
  const { showChild, childLoading } = useObserveChildToggle({
    effectDependencies: [dataObjective],
    observeElementId: 'task' + dataObjective?.id
  });

  return (
    <div className="w-full flex flex-col">
      <SingleBarComponent
        dataObjective={dataObjective}
        startDate={startDate}
        dueDate={dueDate}
        section={section}
        group={group}
        subContentOptions={subContentOptions}
      />
      {showChild && dataObjective.childrenCount > 0 && (
        <>
          <ChildBarWrapper
            subContentOptions={subContentOptions}
            group={group}
            parentId={dataObjective?.id}
            section={section}
            childLoading={childLoading}
          />
        </>
      )}
    </div>
  );
};

export default SingleBarContainer;

import React, { useState } from 'react';

import { useGoalWeightSettingsContext } from 'context/GoalWeightSettingContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';

const CategoryInputName = ({
  defaultValue,
  id,
  changeDataObject,
  errorMessage,
  setErrorMessage
}) => {
  const [value, setValue] = useState(defaultValue || '');
  const { isReadOnly } = useGoalWeightSettingsContext();

  const onChangeHandler = (e) => {
    let changeValue = '';
    if (e != '') changeValue = e.target.value;
    if (changeValue.length > 20) {
      return;
    }
    setErrorMessage(
      changeValue
        ? changeValue.length < 3
          ? 'Minimum 3 Letter'
          : null
        : "Can't be blank"
    );
    setValue(changeValue);
    if (changeDataObject) {
      changeDataObject({ name: changeValue });
    }
  };

  return (
    <div className="flex flex-col">
      <InputField
        dataCy={'type-name-input-' + id}
        inputClass="w-[240px]"
        placeholder={`ex: Company OKR`}
        value={value}
        handleChange={onChangeHandler}
        cancelChanges={() => onChangeHandler('')}
        inputError={errorMessage}
        disabled={isReadOnly}
        cancelIcon
      />
      {errorMessage && (
        <p className="typography-h100 text-r-600 absolute bottom-[0px]">
          {getObjectiveLocale(errorMessage)}
        </p>
      )}
    </div>
  );
};

export default CategoryInputName;

import React, { useEffect, useState } from 'react';

import { getGroupBehaviorWeights } from 'client/admin/ReviewGroupClient.js';
import { getBehaviors, getBehaviorsModels } from 'client/adminClient.js';
import useDebounce from 'hooks/useDebounce';
import { loadMoreValidator } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';

import DropdownBehaviorModel from './DropdownBehaviorModel';
import {
  paramsBehaviorDictionary,
  paramsBehaviorModel
} from './ParamsBehaviorGroup';
import './SectionDropdownBehavior.scss';

const SectionDropdownBehavior = ({
  id,
  groupId,
  behaviorWeights,
  setBehaviorWeights,
  isDataEmpty,
  setIsDataEmpty,
  stateTable,
  setStateTable,
  dataDictionary,
  setDataDictionary,
  defaultValueDropdown,
  getOrUpdateDataDropdown,
  type,
  dataDefaultModel,
  setWeightUpdated,
  isReadOnly = false
}) => {
  const [dataModel, setDataModel] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [nextOffset, setNextOffset] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const _getBehaviorsDictionary = async (
    type,
    modelId,
    props,
    oldData = []
  ) => {
    let params = paramsBehaviorDictionary(type, props);
    // should have modelId in the params - Hazim Hazom
    if (params.modelId) {
      const { data, pagination } = await getBehaviors(params);
      if (data) {
        setIsDataEmpty({ ...isDataEmpty, [type]: true });
        if (pagination.totalSize <= 10) {
          setDataDictionary(data);
          return;
        }
        let hasMore = pagination.nextOffset == null ? false : true;
        let newData = [...oldData, ...data];
        if (hasMore) {
          _getBehaviorsDictionary(
            type,
            modelId,
            { modelId, offset: pagination.nextOffset },
            newData
          );
        }
        setDataDictionary(newData);
      }
      if (data?.length === 0) {
        setIsDataEmpty({ ...isDataEmpty, [type]: false });
      }
    } else {
      setIsDataEmpty({ ...isDataEmpty, [type]: true });
    }
  };

  const _getBehaviorsModels = async (props = {}) => {
    let params = paramsBehaviorModel(type, props);
    setIsLoading(true);
    const { data, pagination } = await getBehaviorsModels(params);
    if (data) {
      setDataModel(data);
      setNextOffset(pagination.nextOffset);
      setHasMore(pagination.nextOffset == null ? false : true);
    }
    setIsLoading(false);
  };
  const _getGroupBehaviorWeights = async (groupId, behaviorModelId) => {
    const params = {
      groupId,
      behaviorModelId
    };
    // should have behaviorModelId, otherwise, backend will give a 422 error
    if (behaviorModelId) {
      const { data } = await getGroupBehaviorWeights(params);
      if (data) {
        setBehaviorWeights(data);
      }
    }
  };
  const _appendBehaviorsModels = async () => {
    let params = paramsBehaviorModel(type, { offset: nextOffset });
    const { data, pagination } = await getBehaviorsModels(params);
    if (data) {
      setDataModel(dataModel.concat(data));
      setNextOffset(pagination.nextOffset);
      setHasMore(pagination.nextOffset == null ? false : true);
    }
  };
  const _onScroll = (e) => {
    const target = e.target;
    const loadMore = async () => {
      await _appendBehaviorsModels();
    };
    if (hasMore) {
      loadMoreValidator(target, 100, () => {
        loadMore();
      });
    }
  };
  const handleChangeDropdown = (e, data) => {
    e.stopPropagation();
    setDataDictionary([]);
    _getBehaviorsDictionary(type, data.id, { modelId: data.id });

    groupId !== undefined
      ? _getGroupBehaviorWeights(groupId, data.id)
      : setBehaviorWeights([]);
    getOrUpdateDataDropdown && getOrUpdateDataDropdown(type, data);
    setWeightUpdated && setWeightUpdated(false);
  };
  const handleClickDropdown = async () => {
    await _getBehaviorsModels();
  };

  const initialGet = () => {
    _getBehaviorsDictionary(type, dataDefaultModel?.id, {
      modelId: dataDefaultModel?.id
    });
    _getGroupBehaviorWeights(groupId, dataDefaultModel?.id);
  };

  useEffect(() => {
    // create hash
    const behaviorWeightHash = {};
    behaviorWeights.forEach((value) => {
      behaviorWeightHash[value.behaviorId] = value.weight;
    });

    if (dataDictionary.length != 0) {
      let newArray = [];
      for (let i = 0; i < dataDictionary.length; i++) {
        // get default weight from hash
        newArray[i] = {
          behaviorId: dataDictionary[i].id,
          weight: behaviorWeightHash[dataDictionary[i].id] || 0
        };
      }
      setBehaviorWeights(newArray);
    }
  }, [dataDictionary]);

  useEffect(() => {
    if (searchValue) {
      _getBehaviorsModels({ q: searchValue });
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (dataDefaultModel) {
      initialGet();
    }
  }, [dataDefaultModel]);

  const wordingType = type == 'work' ? 'work attribute' : type;

  return (
    <div id={id} className="mb-[40px] container-section-dropdown-behavior">
      <p className="typography-h500 capitalize mb-[8px]">{wordingType} model</p>
      <p className="typography-secondary mb-[16px]">
        Choose {wordingType} model for this group.
      </p>
      <div className="flex items-center">
        <DropdownBehaviorModel
          isLoading={isLoading}
          type={type}
          onClick={handleClickDropdown}
          onChange={handleChangeDropdown}
          onScroll={_onScroll}
          dataModel={dataModel}
          defaultValue={
            dataDefaultModel?.title ||
            dataDefaultModel?.name ||
            defaultValueDropdown ||
            `Select ${type} model`
          }
          setSearch={setSearchValue}
          isReadOnly={isReadOnly}
        />
        <Button.Tertiary
          customClass="ml-[16px]"
          datacy={`button-show-hide-table-${type}`}
          onClick={() => setStateTable(!stateTable)}
        >
          {!stateTable ? 'Hide details' : 'Show details'}
        </Button.Tertiary>
      </div>
    </div>
  );
};
export default SectionDropdownBehavior;

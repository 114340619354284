export const listOptionDate = (list) => {
  let listDate = [];
  list.map((content) => {
    let toLower = content.toLowerCase();
    let value = null;

    if (content.includes('S') || content.includes('Q')) {
      value = content.split(' ').shift(); // just take S1, S2, Q1, Q2, Q3, Q4 (first word)
    } else if (/days/.test(content)) {
      //for 7 days, 14 days, 30 days
      value = content.split(' ').shift() + '-day'; // just take number and add '-day' ex 7-day
    } else {
      value = /\s/.test(content) ? toLower.replaceAll(' ', '-') : toLower;
    }
    listDate.push({
      name: content,
      val: value,
      dataCy: value + '-date'
    });
  });

  return listDate;
};

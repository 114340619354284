import React, { useEffect } from 'react';

import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import { getPrefillConfiguration } from 'src/utils/FormalReview';

import SuggestionAndPrefilledConfig from './SuggestionAndPrefilledConfig';

const SuggestionAndPrefilledCard = ({ trackType, label, templateId }) => {
  const { cycleData, changeSuggestionAndAutoFillConfig } = useCreateCycle();

  const {
    isMeetRequirements: isManagerMeetRequirements,
    prefillConfigWording: managerPrefillConfigWording,
    trackData: managerTrackData
  } = getPrefillConfiguration(
    cycleData,
    'managerReview',
    trackType === 'review_aspects_scoring' ? templateId : trackType
  );

  const {
    isMeetRequirements: isIndirectManagerMeetRequirements,
    prefillConfigWording: indirectManagerPrefillConfigWording,
    trackData: indirectManagerTrackData
  } = getPrefillConfiguration(
    cycleData,
    'indirectManagerReview',
    trackType === 'review_aspects_scoring' ? templateId : trackType
  );

  const listPhases = [
    {
      phaseType: 'managerReview',
      isMeetRequirements: isManagerMeetRequirements
    },
    {
      phaseType: 'indirectManagerReview',
      isMeetRequirements: isIndirectManagerMeetRequirements
    }
  ];

  const filteredPhases = listPhases.filter(
    ({ isMeetRequirements }) => isMeetRequirements
  );

  // check if user disable track with config suggestion / prefill -> then update config to do not show or prefill
  useEffect(() => {
    if (
      managerTrackData &&
      !isManagerMeetRequirements &&
      managerPrefillConfigWording !== 'Do not show or prefill the answers'
    ) {
      changeSuggestionAndAutoFillConfig(
        'managerReview',
        trackType,
        'do_not_show_or_prefill',
        templateId
      );
    }
  }, [
    managerTrackData,
    isManagerMeetRequirements,
    changeSuggestionAndAutoFillConfig,
    managerPrefillConfigWording,
    trackType,
    templateId
  ]);

  // check if user disable track with config suggestion / prefill -> then update config to do not show or prefill
  useEffect(() => {
    if (
      indirectManagerTrackData &&
      !isIndirectManagerMeetRequirements &&
      indirectManagerPrefillConfigWording !==
        'Do not show or prefill the answers'
    ) {
      changeSuggestionAndAutoFillConfig(
        'indirectManagerReview',
        trackType,
        'do_not_show_or_prefill',
        templateId
      );
    }
  }, [
    indirectManagerTrackData,
    isIndirectManagerMeetRequirements,
    changeSuggestionAndAutoFillConfig,
    indirectManagerPrefillConfigWording,
    trackType,
    templateId
  ]);

  return (
    <div className="surface mt-[16px] px-[24px] py-[16px] bg-n-000">
      <p className="typography-h500 text-n-900 mb-[4px]">
        {getObjectiveLocale(label)}
      </p>

      {filteredPhases.length > 0 ? (
        <SuggestionAndPrefilledConfig
          listPhases={listPhases}
          trackType={trackType}
          templateId={templateId}
        />
      ) : (
        <p className="typography-button text-n-800 bg-n-100 p-[8px]">
          {getObjectiveLocale(
            'Manager Review and Indirect Manager Review do not meet requirements to use pre-filled answers'
          )}
        </p>
      )}
    </div>
  );
};

export default SuggestionAndPrefilledCard;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import { format } from 'timeago.js';

import { deleteActivitiesCount } from 'client/ObjectivesClient';
import { useReload } from 'context/ReloadContext';
import { useToastContext } from 'context/ToastContext';
import { useLocale } from 'hooks/useLocale';
import { useUrl } from 'hooks/useUrl';
import { getActivitiesIcon } from 'utils/ActivitiesUtils';

function ActivityCardBar(props) {
  const { activity, getCounter, currentTab } = props;
  const { reload, reloadActivityNotifCount } = useReload();
  const [isSeen, setIsSeen] = useState(activity?.seen);
  const history = useHistory();
  const { url } = useUrl();
  const { setBothLocale } = useLocale();
  const { addToast } = useToastContext();
  const objectiveId = activity.resource.id;
  const userId = activity.resource.toUserId;

  const showSidebar = (e) => {
    history.replace({
      pathname: `${url}/${objectiveId}`,
      state: currentTab
    });
  };

  const redirectTo = (path) => {
    history.replace(path);
  };

  const activityAction = async (e) => {
    const { data, isSuccess } = await deleteActivitiesCount(activity?.id);
    if (isSuccess) {
      setIsSeen(data?.seen);
      getCounter(currentTab);
      reload({
        reloadActivityNotifCount: !reloadActivityNotifCount
      });
    }
    if (
      activity.event.includes('approval_request.request') ||
      activity.event.includes('approval_request.withdraw')
    ) {
      return redirectTo('/approval');
    }
    if (
      activity.event.includes('approval_request.approve') ||
      activity.event.includes('approval_request.reject')
    ) {
      return redirectTo('/goals');
    }
    if (
      activity?.resource?.type?.includes('survey') &&
      activity?.resource?.deleted
    ) {
      return addToast({
        msg: setBothLocale('This survey has been deleted'),
        type: 'info'
      });
    }
    if (
      activity.resource.type.includes('survey') &&
      activity.resource.answered
    ) {
      return redirectTo(
        `/pulse-survey/summary/${activity?.resource?.id}?occurrenceId=${activity?.resource?.surveyOccurrenceId}`
      );
    }
    if (
      activity.resource.type.includes('survey') &&
      !activity.resource.answered &&
      dayjs.utc().format() < activity.resource.expiration
    ) {
      return redirectTo(
        `/pulse-survey/answer-later/${activity?.resource?.id}?occurrenceId=${activity?.resource?.surveyOccurrenceId}`
      );
    }
    if (activity.event.includes('skill.strength')) {
      return props?.getDetailSkill(
        activity?.resource?.id,
        activity?.resource?.behaviorId,
        activity?.resource?.behaviorType,
        'strength',
        userId,
        activity?.source
      );
    }
    if (activity.event.includes('skill.weakness')) {
      return props?.getDetailSkill(
        activity?.resource?.id,
        activity?.resource?.behaviorId,
        activity?.resource?.behaviorType,
        'weaknesss',
        userId,
        activity?.source
      );
    }
    return showSidebar();
  };

  useEffect(() => {
    setIsSeen(activity?.seen);
  }, [activity?.seen]);

  return (
    <div
      role="presentation"
      className={`list-activity ${isSeen ? 'seen' : 'unseen'}`}
      onClick={() => activityAction()}
      data-cy={`activity-${props.index}`}
    >
      {getActivitiesIcon(activity.event, activity.user)}
      <div className="detail-wrapper">
        <span
          className="activity-detail"
          dangerouslySetInnerHTML={{ __html: activity.html }}
        />
        <span className="activity-time">{format(activity.doneAt)}</span>
      </div>
    </div>
  );
}

export default ActivityCardBar;

import React from 'react';

function StepNameDescription({ group, setGroup, isReadOnly }) {
  const { name, description } = group;

  return (
    <div className="w-[60%]">
      <div className="form-group">
        <label htmlFor="missionName" className="typography-h500">
          Give it a name
        </label>
        <p className="typography-secondary mb-[16px]">
          Something clear and easily to be recognized.
        </p>
        <p className="typography-h100 typography-secondary mb-[8px]">
          Group Name
        </p>
        <input
          type="text"
          data-cy="field-name"
          className="form-control"
          onChange={(e) =>
            setGroup({
              ...group,
              name: e.currentTarget.value
            })
          }
          disabled={isReadOnly}
          value={name}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor="missionDescription"
          className="mt-[40px] typography-h500"
        >
          Tell something about this
        </label>
        <p className="typography-secondary mb-[16px]">
          Describe the purpose of creating this group.
        </p>
        <p className="typography-h100 typography-secondary mb-[8px]">
          Description
        </p>
        <textarea
          type="text"
          className="form-control h-[100px]"
          onChange={(e) =>
            setGroup({
              ...group,
              description: e.currentTarget.value
            })
          }
          data-cy="field-desc"
          value={description}
          disabled={isReadOnly}
        />
      </div>
    </div>
  );
}

export default StepNameDescription;

import React from 'react';
import Linkify from 'react-linkify';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { getActivities } from 'client/ActivitiesClient';
import { useUrl } from 'hooks/useUrl';
import {
  formatBytes,
  getDateLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';
import { getObjectiveCloneActionText } from 'utils/ObjectivesHelper';

import Avatar from 'components/design-system/Avatar';
import Button from 'components/design-system/Button';
import ConditionalWrapper from 'components/shared/ConditionalWrapper';

import AttachmentList from '../sidebar/objective-detail/AttachmentList';

function ActivitiesDetail({ dataId }) {
  const { url } = useUrl();
  const history = useHistory();

  const getActivitiesList = (pageParam) => {
    const params = {
      limit: 5,
      olderThan: pageParam
    };
    return getActivities(dataId, params);
  };

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['objective', 'activities', parseInt(dataId)],
    ({ pageParam }) => getActivitiesList(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.pagination?.next?.olderThan,
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000
    }
  );

  let activities = [];
  data?.pages?.forEach((page) => {
    page?.data?.forEach((d) => {
      activities.push(d);
    });
  });

  return (
    <>
      {Object.values(activities).length > 0 && (
        <div className="flex flex-col px-[24px]">
          <p className="typography-h500 mb-[16px] text-n-900">
            {getObjectiveLocale('Activities')}
          </p>
          {hasNextPage && (
            <div className="flex">
              <Button.Tertiary onClick={() => fetchNextPage()}>
                {getObjectiveLocale('Show more activities')}
              </Button.Tertiary>
            </div>
          )}
          {Object.values(activities)
            ?.reverse()
            .map((activity, index) => {
              const newValue = isNaN(activity.text.newValue)
                ? activity.text.newValue
                : parseFloat(activity.text.newValue).toLocaleString('id-ID');
              const previousValue = isNaN(activity.text.previousValue)
                ? activity.text.previousValue
                : parseFloat(activity.text.previousValue).toLocaleString(
                    'id-ID'
                  );

              return (
                <React.Fragment key={index}>
                  <div className="flex justify-start py-[12px]" key={index}>
                    <Avatar
                      name={activity.user.name}
                      src={activity.user.profilePicture}
                      size={40}
                      customClass="mr-[16px]"
                    />
                    {
                      <div className="flex flex-col grow">
                        <p className="typography-h-200">
                          {`${activity.user.name}`}
                          {isEmpty(activity?.metaClone) ? (
                            <span
                              className="ml-[4px]"
                              dangerouslySetInnerHTML={{
                                __html: getObjectiveLocale(
                                  activity?.text?.action
                                )
                              }}
                            />
                          ) : (
                            <span className="ml-[4px]">
                              {getObjectiveCloneActionText(
                                activity?.text?.action,
                                activity?.metaClone,
                                url,
                                history
                              )}
                            </span>
                          )}
                        </p>
                        {activity?.commentOption?.text && (
                          <p className="text-n-900 mt-[8px]">
                            {activity?.commentOption?.text}
                          </p>
                        )}
                        {activity.comment && (
                          <Linkify
                            className="mt-[8px] typography-paragraph text-n-900 white-space-break"
                            properties={{ target: '_blank' }}
                          >
                            {activity.comment}
                          </Linkify>
                        )}
                        {activity.text.newValue &&
                          activity.text.previousValue &&
                          (activity?.event?.includes('start_date') ||
                          activity?.event?.includes('due_date') ? (
                            <>
                              <p className="typography-paragraph mt-[8px]">
                                <i>
                                  {getObjectiveLocale('New')}:{' '}
                                  <b>
                                    {getDateLocale(activity.text.newValue, {
                                      day: 'numeric',
                                      month: 'long',
                                      year: 'numeric',
                                      hourCycle: 'h12',
                                      hour: 'numeric',
                                      minute: 'numeric'
                                    })}
                                  </b>{' '}
                                </i>
                              </p>
                              <p className="typography-paragraph">
                                <i>
                                  {getObjectiveLocale('Prev')}:{' '}
                                  <b>
                                    {getDateLocale(
                                      activity.text.previousValue,
                                      {
                                        day: 'numeric',
                                        month: 'long',
                                        year: 'numeric',
                                        hourCycle: 'h12',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                      }
                                    )}
                                  </b>
                                </i>
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="typography-paragraph mt-[8px]">
                                <i>
                                  {getObjectiveLocale('New')}:{' '}
                                  <b>{newValue.replaceAll('<br />', ', ')}</b>
                                </i>
                              </p>
                              <p className="typography-paragraph">
                                <i>
                                  {getObjectiveLocale('Prev')}:{' '}
                                  <b>
                                    {previousValue.replaceAll('<br />', ', ')}
                                  </b>
                                </i>
                              </p>
                            </>
                          ))}
                        {activity.object &&
                          activity.object.type &&
                          activity.object.state == 'available' &&
                          (activity.object.type == 'image' ||
                            activity.object.type == 'document') && (
                            <ConditionalWrapper
                              condition={activity.object.type !== 'image'}
                              wrapper={(children) => (
                                <a
                                  className="mt-[8px]"
                                  href={activity.object.downloadUrl || false}
                                  download
                                >
                                  {children}
                                </a>
                              )}
                            >
                              <AttachmentList
                                fileName={activity.object.name}
                                fileSize={formatBytes(activity.object.size)}
                                deleteAble={false}
                                downloadUrl={activity.object.downloadUrl}
                                type={activity.object.type}
                                takenOn={`taken on ${getDateLocale(
                                  activity.object.fileTimestamp,
                                  {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                    hourCycle: 'h12',
                                    hour: 'numeric',
                                    minute: 'numeric'
                                  }
                                )}`}
                              />
                            </ConditionalWrapper>
                          )}
                        <p className="typography-h100 text-n-600 mt-[8px]">
                          {getDateLocale(activity.doneAt, {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                            hourCycle: 'h12',
                            hour: 'numeric',
                            minute: 'numeric'
                          })}
                        </p>
                      </div>
                    }
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      )}
    </>
  );
}

export default ActivitiesDetail;

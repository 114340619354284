import React from 'react';

const NameSection = ({ templateData, setData, errorName, setErrorName }) => {
  return (
    <div className="create-track-template-set-name" id="template-name">
      <p className="typography-h500 typography-primary text-n-900 mb-[4px]">
        Give it a name
      </p>
      <p className="typography-h400 typography-paragraph typography-secondary">
        Something that’s clear and easily recognizable.
      </p>
      <p className="typography-h100 typography-secondary mb-[8px]">
        Track template name
      </p>
      <div className="wrapper-input">
        <input
          className={`track-template-input w-full ${
            errorName ? 'error-message' : ''
          }`}
          placeholder="Marketing team goal scoring 2020"
          value={templateData.name}
          onChange={(e) => setData(e.target.value, 'name', null)}
          data-cy="track-template-set-name"
          onBlur={() =>
            !templateData.name ? setErrorName(true) : setErrorName(false)
          }
        />
        {errorName && (
          <p className="error-message mt-[8px]">Template name can't be blank</p>
        )}
      </div>
    </div>
  );
};

export default NameSection;

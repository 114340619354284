import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  followObjective,
  remindObjective,
  unfollowObjective
} from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import useGoalTree from 'hooks/useGoalTree';
import useKeypress from 'hooks/useKeyPress';
import { useUrl } from 'hooks/useUrl';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import ObjectiveReviewPopup from 'components/objective-review-popup/ObjectiveReviewPopup';
import SecondaryButton from 'components/shared/Button/SecondaryButton';
import SVGIcon from 'components/shared/SVGIcon';
import HeaderDropdown from 'components/sidebar/objective-detail/HeaderDropdown';
import { trackEvent } from 'src/utils/AnalyticUtils';

const Header = ({
  objectiveDetail,
  setModalOpen,
  setModalType,
  completeGoal,
  isEditable = true,
  onClickClose,
  approvalRequired
}) => {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const { reload } = useReload();
  const { refetchObjective } = useRefetchQuery();
  const { addToast } = useToastContext();
  const treeObjective = useGoalTree((state) => state.treeObjective);

  const { user, config } = useUser();
  const { url } = useUrl();
  let history = useHistory();

  const { ratingMechanism } = config;
  const {
    reviews = [],
    stateObject = [],
    id,
    permissions = [],
    type = 'goal'
  } = objectiveDetail || {};
  const { state } = stateObject || '';

  const { state: historyState } = useLocation();
  const isParent = historyState && historyState.isParentObjective;
  const showNeedApprovalText =
    approvalRequired &&
    (state === 'draft' || state === 'edited' || state === 'to_be_deleted');

  const editObjective = () => {
    history.replace({
      pathname: `${url}/edit/${id}`,
      search: location.search,
      state: { isParentObjective: isParent }
    });
  };

  const closeSidebar = async () => {
    let newUrl = history?.location?.state
      ? url.replace(
          '/objectives',
          `/${history?.location?.state?.values}/${history?.location?.state?.type}/${history?.location?.state?.behaviourId}`
        )
      : history.location.pathname.includes('users') ||
        history.location.pathname.includes('approval')
      ? url.replace('/objectives', '')
      : url;

    let newSearch = new URLSearchParams(location.search);
    if (newSearch.has('organizationId')) {
      newSearch.delete('organizationId');
    }
    if (
      history.location.pathname.includes('tree') &&
      treeObjective?.organization?.id
    ) {
      newSearch.append('organizationId', treeObjective?.organization?.id);
    }

    history.replace({
      pathname: newUrl,
      search: newSearch.toString()
    });
  };

  const followGoal = async () => {
    let body = {
      role: 'follower',
      userId: user.id
    };

    const { isSuccess } = await followObjective(id, body);
    if (isSuccess) {
      refetchObjective(parseInt(id));
    }
  };

  const unfollowGoal = async () => {
    const { isSuccess } = await unfollowObjective(id, user.id);
    if (isSuccess) {
      refetchObjective(parseInt(id));
    }
  };

  const remindGoal = async () => {
    const { isSuccess } = await remindObjective(id);
    if (isSuccess) {
      addToast({
        msg: getObjectiveLocale('Thank you! Your reminder has been sent.'),
        title: getObjectiveLocale('Reminder sent!'),
        type: 'success'
      });
      reload({ reloadObjectiveId: { id: id } });
    }
  };

  useKeypress('Escape', () => (onClickClose ? onClickClose() : closeSidebar()));
  return (
    <div className="flex justify-between items-center">
      <div className="flex">
        {showNeedApprovalText && (
          <p className="typography-button text-n-600">
            {getObjectiveLocale('This goal need approval by team reviewer')}
          </p>
        )}
        {permissions?.includes('review') ? (
          <>
            {ratingMechanism !== 'automatic' && (
              <SecondaryButton
                datacy="sidebar-detail-review"
                onClick={() => {
                  trackEvent({
                    event: 'review',
                    eventProperties: {
                      status: 'success',
                      id: id,
                      type: type
                    }
                  });
                  setShowReviewModal(!showReviewModal);
                }}
                customClass="mr-[8px]"
              >
                <SVGIcon
                  iconName="icon-review"
                  size={24}
                  fillColor="var(--base-600)"
                  customClass="mr-[8px]"
                />
                {getObjectiveLocale('Review')}
              </SecondaryButton>
            )}

            <SecondaryButton
              onClick={() =>
                completeGoal({ state: 'running', type: 'decline' })
              }
              datacy="sidebar-detail-decline"
              customClass="mr-[8px]"
            >
              {getObjectiveLocale('Decline')}
            </SecondaryButton>
          </>
        ) : (
          <>
            {permissions?.includes('complete') && (
              <SecondaryButton
                datacy="sidebar-detail-complete"
                onClick={() => {
                  setModalOpen(true);
                  setModalType('complete');
                }}
                customClass="mr-[8px]"
              >
                Mark as Complete
              </SecondaryButton>
            )}
            {permissions?.includes('uncomplete') && (
              <div className="flex">
                <SecondaryButton
                  datacy="sidebar-detail-uncomplete"
                  onClick={() => {
                    setModalOpen(true);
                    setModalType('incomplete');
                  }}
                  customClass="mr-[8px]"
                >
                  <SVGIcon
                    iconName="icon-check_circle"
                    fillColor={'var(--g-600)'}
                    size="24"
                    customClass="mr-[8px]"
                  />
                  Completed
                </SecondaryButton>

                {permissions?.includes('remind') &&
                  ratingMechanism !== 'automatic' && (
                    <SecondaryButton
                      datacy="sidebar-detail-remind"
                      onClick={() => remindGoal()}
                      customClass="mr-[8px]"
                    >
                      {getObjectiveLocale('Remind')}
                    </SecondaryButton>
                  )}
              </div>
            )}
          </>
        )}

        {permissions?.includes('follow') && (
          <SecondaryButton
            datacy="sidebar-detail-follow"
            onClick={() => followGoal()}
            customClass="mr-[8px]"
          >
            Follow
          </SecondaryButton>
        )}

        {permissions?.includes('unfollow') && (
          <SecondaryButton
            datacy="sidebar-detail-unfollow"
            onClick={() => unfollowGoal()}
            customClass="mr-[8px]"
          >
            Unfollow
          </SecondaryButton>
        )}

        {state == 'reviewed' &&
          !permissions?.includes('follow') &&
          !permissions?.includes('unfollow') &&
          reviews.length != 0 && (
            <div>
              <p className="typography-paragraph fw-bold text-n-800">
                Reviewed on {getDateLocale(reviews[0]?.createdAt)}
              </p>
            </div>
          )}
      </div>
      <div className="flex justify-end">
        {permissions?.includes('update') &&
          !url.includes('activities') &&
          isEditable && (
            <SVGIcon
              onClick={editObjective}
              iconName="icon-pencil"
              fillColor="var(--n-600)"
              size="24"
              dataCy="sidebar-detail-edit"
            />
          )}
        <HeaderDropdown
          objectiveDetail={objectiveDetail}
          completeGoal={completeGoal}
          setShowReviewModal={setShowReviewModal}
          isEditable={isEditable}
        />
        <SVGIcon
          onClick={() => {
            onClickClose ? onClickClose() : closeSidebar();
          }}
          fillColor="var(--n-600)"
          iconName="icon-clear"
          size="24"
          customClass="ml-[16px]"
          dataCy="sidebar-detail-close"
        />
      </div>

      {showReviewModal && (
        <ObjectiveReviewPopup
          objectiveDetail={objectiveDetail}
          onCloseModal={() => setShowReviewModal(false)}
        />
      )}
    </div>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';

import useFilter from 'hooks/useFilter';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ListEmptyState from 'components/shared/ListEmptyState';
import { getApproval } from 'src/client/ApprovalClient';
import ObjectiveSkeleton from 'src/components/shared/ObjectiveSkeleton';

import ApprovalTeamCard from './ApprovalTeamCard';

function TeamsApproval({
  filter,
  requesterId,
  withTop = true,
  page = 'teams'
}) {
  const globalFilter = useFilter(
    (state) => state.filterById?.approvalTeamFilter
  );
  const filterId = filter ? filter : globalFilter;

  const { url } = useUrl();
  const [approvalList, setApprovalList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getApprovalTeamList = async () => {
    setIsLoading(true);
    let params = {
      pageType: url.includes('team') ? 'requester' : 'approver',
      type: 'team',
      ...filterId
    };
    if (requesterId) {
      params.requesterPlacementId = requesterId;
    }
    const { data } = await getApproval(params);
    if (data) {
      setApprovalList(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getApprovalTeamList();
  }, [filterId]);

  return isLoading ? (
    <div className={page === 'teams' ? 'px-[40px]' : ''}>
      <ObjectiveSkeleton />
    </div>
  ) : approvalList?.length === 0 ? (
    <ListEmptyState
      containerClassname="h-auto mt-[96px]"
      emptyIcon="icon-no-approval"
      title={getObjectiveLocale('No Approval')}
      subtitle={getObjectiveLocale(
        'You don’t have any ongoing approval requests.'
      )}
    />
  ) : (
    <div className="team-list">
      {approvalList?.map((data, index) => (
        <ApprovalTeamCard
          key={index}
          data={data}
          withTop={withTop}
          isLast={index === approvalList?.length - 1}
        />
      ))}
    </div>
  );
}

export default TeamsApproval;

import React from 'react';

import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const OverallPhaseCompact = ({
  filterByPhase = () => null,
  phaseActive,
  totalObjectives,
  listPhases
}) => {
  let mapPhaseValues = listPhases?.map(({ qty }) => qty);

  const tooltipPhaseFilterText = 'Filter on this specific phase';

  return (
    <div className="phase flex flex-row">
      <div className="flex flex-row mr-[24px] py-[4px]">
        <p className="mr-[8px] typography-h400 text-n-900">{totalObjectives}</p>
        <p className="typography-h400 text-n-600">Total Objective</p>
      </div>
      <div className="flex flex-row phase-section">
        <TooltipContainer
          show
          text={tooltipPhaseFilterText}
          classContainer={`cursor-pointer flex flex-row ml-[24px] mr-[24px] py-[4px] ${
            phaseActive.includes('running') || phaseActive === 'all'
              ? ''
              : 'opacity-50'
          }`}
          onClick={() => filterByPhase('running')}
        >
          <SVGIcon
            iconName="icon-access_time"
            size="16"
            fillColor="var(--n-600)"
            customClass="mr-[4px]"
          />
          <p className="typography-h400 text-n-900 mr-[8px]">
            {mapPhaseValues[0]}
          </p>
          <p className="typography-h400 text-n-600">On-going</p>
        </TooltipContainer>
        <TooltipContainer
          show
          text={tooltipPhaseFilterText}
          classContainer={`cursor-pointer flex flex-row mr-[24px] py-[4px] ${
            phaseActive.includes('completed') || phaseActive === 'all'
              ? ''
              : 'opacity-50'
          }`}
          onClick={() => filterByPhase('completed')}
        >
          <SVGIcon
            iconName="icon-check_circle"
            size="16"
            fillColor="var(--g-600)"
            customClass="mr-[4px]"
          />
          <p className="typography-h400 text-n-900 mr-[8px]">
            {mapPhaseValues[1]}
          </p>
          <p className="typography-h400 text-n-600">Completed</p>
        </TooltipContainer>
        <TooltipContainer
          show
          text={tooltipPhaseFilterText}
          classContainer={`cursor-pointer flex flex-row mr-[24px] py-[4px] ${
            phaseActive.includes('reviewed') || phaseActive === 'all'
              ? ''
              : 'opacity-50'
          }`}
          onClick={() => filterByPhase('reviewed')}
        >
          <SVGIcon
            iconName="icon-reviewed"
            size="16"
            fillColor="var(--base-600)"
            customClass="mr-[4px]"
          />
          <p className="typography-h400 text-n-900 mr-[8px]">
            {mapPhaseValues[2]}
          </p>
          <p className="typography-h400 text-n-600">Reviewed</p>
        </TooltipContainer>
      </div>
      <TooltipContainer
        show
        text={tooltipPhaseFilterText}
        classContainer={`cursor-pointer flex flex-row ml-[24px] mr-[24px] py-[4px]`}
        onClick={() => filterByPhase('overdue')}
      >
        <SVGIcon
          iconName="icon-overdue"
          size="16"
          fillColor="var(--base-600)"
          customClass="mr-[4px]"
        />
        <p className="typography-h400 text-n-900 mr-[8px]">
          {mapPhaseValues[3]}
        </p>
        <p className="typography-h400 text-n-600">Overdue</p>
      </TooltipContainer>
    </div>
  );
};

export default OverallPhaseCompact;

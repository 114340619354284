import React from 'react';
import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import SingleUser from 'components/design-system/single-user/SingleUser';

function UserSubordinateProfileComponent(props) {
  const { managers, subordinates, totalSubordinates, onScroll } = props;
  const history = useHistory();

  return (
    <div className="user-info-involve-card">
      <div className="user-info-involve-content">
        <span className="direct-report-description">
          {getObjectiveLocale('Line Manager')}
        </span>
        {[managers]?.length > 0 &&
          [managers]?.map(
            (manager, index) =>
              !isEmpty(manager) && (
                <div key={index} className="py-[8px]">
                  <SingleUser
                    userData={manager}
                    size="32"
                    onClick={() =>
                      history.replace(
                        `/users/${manager?.id}/placement/${manager?.placementId}`
                      )
                    }
                  />
                </div>
              )
          )}
      </div>
      {managers?.length === 0 || subordinates?.length === 0 ? null : (
        <div className="divider" />
      )}
      <div className="user-info-involve-content">
        <span className="direct-report-description">
          {getObjectiveLocale('Direct Reports')}:{' '}
          {getNumberLocale(totalSubordinates)}
        </span>
        <div
          className="overflow-auto max-h-[200px]"
          onScroll={(e) => onScroll(e)}
        >
          {subordinates?.map((subordinate, index) => (
            <div key={index} className="py-[8px]">
              <SingleUser
                userData={subordinate}
                size="32"
                onClick={() =>
                  history.replace(
                    `/users/${subordinate?.id}/placement/${subordinate?.positions?.[0]?.placementId}`
                  )
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserSubordinateProfileComponent;

import client from './ApiClient';

function getUser(userId, query) {
  const url = `v1/users/${userId}`;
  return client(url, { params: query });
}

function getUserCulture(userId, params) {
  const url = `culture/users/${userId}`;
  return client(url, { params });
}

function getManager(userId, query = {}) {
  const url = `v1/users/${userId}/parents`;
  return client(url, { params: query });
}

function getManagerV2(placementId, query = {}) {
  const url = `v1/placements/${placementId}/parent`;
  return client(url, { params: query });
}

function getSubordinate(userId, query = {}) {
  const url = `v1/users/${userId}/children`;
  return client(url, { params: query });
}

function getSubordinateV2(userId, query = {}) {
  let url = `v1/users/${userId}/direct_reports`;
  return client(url, { params: query });
}

function updateConfigurations(userId, placementId, body) {
  const url = `v1/users/${userId}/placements/${placementId}`;
  return client(url, { method: 'PATCH', body: body });
}

function getScore(query, userId) {
  const url = `v1/users/${userId}/score`;
  return client(url, { params: query });
}

function getRecognition(query, userId) {
  const url = `v1/users/${userId}/skills/merged_strengths`;
  return client(url, { params: query });
}

function getSkills(query, userId) {
  const url = `/v1/users/${userId}/skills`;
  return client(url, { params: query });
}

function getObjectivesCount(query, userId) {
  const url = `v1/users/${userId}/objectives/count`;
  return client(url, { params: query });
}

function getAlignmentsCount(query, userId) {
  const url = `v1/users/${userId}/objectives/count/parentage`;
  return client(url, { params: query });
}

function getSkillValueWeakness(query, userId, type) {
  const url = `v1/users/${userId}/${type}/weakness`;
  return client(url, { params: query });
}

function getSkillValueStrength(query, userId, type) {
  const url = `v1/users/${userId}/${type}/merged_strength`;
  return client(url, { params: query });
}

function getDetailStrength(userId, behaviourType, behaviorId, query) {
  const url = `v1/users/${userId}/${behaviourType}/merged_strength/${behaviorId}`;
  return client(url, { params: query });
}

function getCountStrength(userId, behaviourType, behaviorId, query) {
  const url = `v1/users/${userId}/${behaviourType}/merged_strength/${behaviorId}/count`;
  return client(url, { params: query });
}

function getCountWeakness(userId, behaviourType, behaviorId, query) {
  const url = `v1/users/${userId}/${behaviourType}/weakness/${behaviorId}/count`;
  return client(url, { params: query });
}

function getDetailWeakness(userId, behaviourType, behaviorId, query) {
  const url = `v1/users/${userId}/${behaviourType}/weakness/${behaviorId}`;
  return client(url, { params: query });
}

function getValueSource(query, type, valueId, userId) {
  const url = `v1/values_scoring/${type}/values/${valueId}/user/${userId}`;
  return client(url, { params: query });
}

function updateWorkloadStatus(data, userId) {
  const url = `v1/users/${userId}`;
  return client(url, { method: 'PATCH', body: data });
}

function getSkillCulture(query, skillId) {
  const url = `v1/culture/skills/${skillId}`;
  return client(url, { params: query });
}
function getSkillPerformance(query, userId, source) {
  const url = `v1/users/${userId}/${source}`;
  return client(url, { params: query });
}

function setPosition(query, userId) {
  const url = `v1/users/${userId}/position`;
  return client(url, { method: 'PUT', body: query });
}

function updateUser(body, userId) {
  const url = `v1/users/${userId}`;
  return client(url, { method: 'PATCH', body: body });
}

export {
  getUser,
  getUserCulture,
  getManager,
  getSubordinate,
  getScore,
  getRecognition,
  getSkills,
  getObjectivesCount,
  getAlignmentsCount,
  getSkillValueWeakness,
  getSkillValueStrength,
  getValueSource,
  getDetailStrength,
  getDetailWeakness,
  updateWorkloadStatus,
  getSkillCulture,
  getSkillPerformance,
  setPosition,
  updateUser,
  getCountStrength,
  getCountWeakness,
  updateConfigurations,
  getManagerV2,
  getSubordinateV2
};

import React, { useEffect, useState } from 'react';

import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import RangeCalendarPhases from 'components/admin/Cycles/RangeCalendarPhases';
import ReminderPhase from 'components/admin/Cycles/ReminderPhase';
import Checkbox from 'components/shared/Checkbox';
import RadioButton from 'components/shared/RadioButton';

import './SchedulePhaseCard.scss';

function SchedulePhaseCard({ phase }) {
  const {
    cycleData,
    cycleData: { reviewProcessStartsAt, reviewProcessEndsAt },
    changePhaseData
  } = useCreateCycle();

  const { key, id, startsAt, endsAt, showDueDate, notifyOnStart } = phase;
  const [useReminder, setUseReminder] = useState(false);

  const handleNotUsingReminder = () => {
    setUseReminder(false);
    changePhaseData(id, 'reminders', []);
  };

  useEffect(() => {
    if (cycleData[id]?.reminders?.length > 0) {
      setUseReminder(true);
    }
  }, [cycleData]);

  return (
    <div className="phase-schedule-box mt-[24px] bg-n-000">
      <h5 className="typography-h400">{key}</h5>
      <p className="typography-secondary mb-[4px]">
        Set schedule for this phase
      </p>
      <div className="flex my-[16px]">
        <div>
          <h6 className="typography-h100 typography-secondary mb-[8px]">
            Choose your start and end date
          </h6>
          <RangeCalendarPhases
            calendarContext={id}
            startsAt={startsAt}
            endsAt={endsAt}
            disabled={!reviewProcessStartsAt && !reviewProcessEndsAt}
            isSchedulePhaseCard={true}
            withDisabledDate={true}
            startLimitDate={cycleData.reviewProcessStartsAt}
            endLimitDate={cycleData.reviewProcessEndsAt}
            withDefaultDateAnnualy={false}
            useContainerClassName={false}
          />
        </div>
      </div>
      <Checkbox
        name={`show-due-date-${id}`}
        id={`show-due-date-${id}`}
        checked={showDueDate}
        onClick={() => changePhaseData(id, 'showDueDate', !showDueDate)}
        dataCy={`show-due-date-${id}`}
        customContainerClass="pb-[16px]"
      >
        <span className="typography-paragraph ml-[8px] text-n-900">
          {getObjectiveLocale('View schedule on assignment phase')}
        </span>
      </Checkbox>
      <Checkbox
        name={`notify-on-start-${id}`}
        id={`notify-on-start-${id}`}
        checked={notifyOnStart}
        onClick={() => changePhaseData(id, 'notifyOnStart', !notifyOnStart)}
        dataCy={`notify-on-start-${id}`}
        customContainerClass="pb-[16px]"
      >
        <span className="typography-paragraph ml-[8px] text-n-900">
          {getObjectiveLocale(
            'Send email notification for reviewers when the phase is started'
          )}
        </span>
      </Checkbox>
      <div className="reminder-settings-box">
        <p className="typography-h100 mb-[8px]">Reminder Settings</p>
        <RadioButton
          size="small"
          name={`reminder-${id}`}
          checked={useReminder === false}
          onClick={() => handleNotUsingReminder()}
          addClass="mb-[8px]"
        >
          <span className="ml-[8px]"> Not Using Reminder </span>
        </RadioButton>
        <RadioButton
          size="small"
          name={`reminder-${id}`}
          checked={useReminder === true}
          onClick={() => setUseReminder(true)}
        >
          <span className="ml-[8px]"> Use Reminder </span>
        </RadioButton>
        {useReminder && (
          <ReminderPhase
            endsAt={endsAt}
            useReminder={useReminder}
            phaseId={id}
          />
        )}
      </div>
    </div>
  );
}

export default SchedulePhaseCard;

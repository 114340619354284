/* eslint-disable no-prototype-builtins */
import dayjs from 'dayjs';

import { getMilestoneDefault } from 'utils/ObjectivesHelper';

export function objectiveRestructuring(objective) {
  const newObjective = {
    id: objective.id,
    name: objective.name,
    type: objective.type,
    description: objective.description,
    tags: objective.tags || null,
    startDate: objective.startDate,
    dueDate: objective.dueDate
      ? objective.dueDate
      : dayjs().endOf('quarter').toISOString(),
    comment: '',
    isPrivate: objective.isPrivate,
    isProject: objective.isProject,
    isCompanyGoal: objective.isCompanyGoal,
    isDirectorateGoal: objective.isDirectorateGoal,
    isDepartmentGoal: objective.isDepartmentGoal,
    isDivisionGoal: objective.isDivisionGoal,
    involvements: objective?.involvements?.map((involvement) => {
      return {
        userId: involvement.user.id,
        role: involvement.role,
        user: involvement.user,
        visible: involvement.visible,
        extendedRole: involvement.extendedRole || null
      };
    }),
    complexity: objective.complexity,
    weight: objective.weight,
    permissions: objective?.permissions,
    calculationType: objective.calculationType,
    milestoneCount: objective.milestoneCount,
    parents: objective?.parents,
    parent: objective?.parent,
    integrations: objective?.integrations
  };

  if (objective.type === 'task') {
    Object.assign(newObjective, { taskType: objective.taskType });
  }

  if (objective.hasOwnProperty('milestoneCount')) {
    Object.assign(newObjective, { milestoneCount: objective.milestoneCount });
  }

  if (objective.hasOwnProperty('currentMilestone')) {
    Object.assign(newObjective, {
      currentMilestone: objective.currentMilestone
    });
  }

  if (objective.hasOwnProperty('milestoneMode')) {
    Object.assign(newObjective, { milestoneMode: objective.milestoneMode });
  }

  if (objective.hasOwnProperty('milestoneType')) {
    Object.assign(newObjective, { milestoneType: objective.milestoneType });
  }

  if (objective.hasOwnProperty('milestones')) {
    Object.assign(newObjective, { milestones: getMilestoneDefault() });
  }

  if (objective.hasOwnProperty('recurrence')) {
    Object.assign(newObjective, { recurrence: objective.recurrence });
  }

  if (objective.measurement) {
    newObjective.measurement = {
      unitId: objective.measurement.unit ? objective.measurement.id : 0,
      rollUp: objective.measurement.rollUp,
      startingValue: objective.measurement.startingValue,
      targetValue: objective.measurement.targetValue,
      description: objective.measurement.description
    };
  } else if (objective.measurement === null) {
    newObjective.measurement = null;
  }

  if (objective.measurement?.hasOwnProperty('unit')) {
    Object.assign(newObjective, {
      measurement: {
        ...newObjective.measurement,
        unit: objective.measurement.unit
      }
    });
  }
  for (var key in newObjective) {
    if (!(key in objective)) {
      delete newObjective[key];
    }
  }

  Object.assign(newObjective, { comment: '' });

  if (objective.hasOwnProperty('parent')) {
    Object.assign(newObjective, {
      parentId: objective.parent ? [objective.parent.id] : []
    });
  }

  if (objective.hasOwnProperty('parents')) {
    const parentIds = objective?.parents?.map((parent) => parent?.id);
    Object.assign(newObjective, {
      parentIds: objective.parents ? parentIds : []
    });
  }

  if (objective.hasOwnProperty('objectiveCategory')) {
    Object.assign(newObjective, {
      objectiveCategoryId: objective.objectiveCategory
        ? objective.objectiveCategory.id
        : null
    });
  }

  if (objective.priority) {
    Object.assign(newObjective, { priority: objective.priority });
    Object.assign(newObjective, { priorityId: objective.priority.id });
  }

  if (objective.phase) {
    Object.assign(newObjective, { phase: objective.phase });
    Object.assign(newObjective, { phaseId: objective.phase.id });
  }

  if (objective?.blockedBy?.length > 0) {
    Object.assign(newObjective, {
      blockedBy: objective.blockedBy.map((task) => task)
    });
  }

  if (objective?.team) {
    Object.assign(newObjective, { team: objective?.team });
  }

  return newObjective;
}

import React from 'react';

import dayjs from 'dayjs';

import { useUser } from 'context/UserContext';
import useUserManagement from 'hooks/useUserManagement';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';

import ManagerField from './ManagerField';
import TimeField from './TimeField';

const PositionSection = ({
  changePositionField,
  detailPosition,
  setDetailPosition,
  changePositionData,
  isReadOnly
}) => {
  const { config } = useUser();
  const isError = useUserManagement((state) => state.isError);

  return (
    <div className="border-solid border border-n-400 rounded-[4px] p-16 pb-[24px] flex flex-col mt-[16px]">
      <div className="typography-h400">
        {getObjectiveLocale('Position Details')}
      </div>
      <div className="typography-paragraph text-n-800">
        {getObjectiveLocale('Showing detail informations of this position.')}
      </div>
      {config?.editablePlacementFields?.positionName && (
        <>
          <InputField
            label="Position Name"
            placeholder="Position Name"
            handleChange={(e) => changePositionData(e, 'name')}
            value={detailPosition?.name}
            dataCy="user-pos-name"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.positionName === 'disabled'
            }
          />
          {isError && !detailPosition?.name && (
            <div className="typography-h100 text-r-500 mt-[8px]">
              {getObjectiveLocale('Position name field must be filled')}
            </div>
          )}
        </>
      )}
      <div className="flex mt-[16px]">
        {config?.editablePlacementFields?.startDate && (
          <div>
            <TimeField
              selectedDate={detailPosition?.startsAt}
              onSelectDate={(date) =>
                changePositionField(dayjs(date)?.toISOString(), 'startsAt')
              }
              type="startDate"
              label={getObjectiveLocale('Start Date')}
              dataCy="startsAt"
              customClass="w-[344px] mr-[16px]"
              isSingle
              readonly={
                isReadOnly ||
                config?.editablePlacementFields?.startDate === 'disabled'
              }
            />
            {isError && !detailPosition?.startsAt && (
              <div className="typography-h100 text-r-500 mt-[8px]">
                {getObjectiveLocale('Start date field must be filled')}
              </div>
            )}
          </div>
        )}
        {config?.editablePlacementFields?.endDate && (
          <TimeField
            selectedDate={detailPosition?.endsAt}
            onSelectDate={(date) =>
              changePositionField(
                date ? dayjs(date)?.toISOString() : null,
                'endsAt'
              )
            }
            label={getObjectiveLocale('End Date')}
            customClass="w-[344px]"
            isSingle
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.endDate === 'disabled'
            }
            disabledDate={detailPosition?.startsAt}
            type="dueDate"
            allowClear={true}
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementFields?.echelon && (
          <InputField
            label="Echelon Level"
            placeholder="Echelon Level"
            handleChange={(e) => changePositionField(e, 'echelon', 'info')}
            value={detailPosition?.info?.echelon}
            dataCy="user-pos-echelon"
            className="w-[344px] mr-[16px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.echelon === 'disabled'
            }
          />
        )}
        {config?.editablePlacementFields?.workUnit && (
          <InputField
            label="Work Unit"
            placeholder="Work Unit"
            handleChange={(e) => changePositionField(e, 'workUnit', 'info')}
            value={detailPosition?.info?.workUnit}
            dataCy="user-pos-workUnit"
            className="w-[344px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.workUnit === 'disabled'
            }
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementFields?.positionEid && (
          <InputField
            label="Position Code"
            placeholder="Position Code"
            handleChange={(e) => changePositionField(e, 'positionEid', 'info')}
            value={detailPosition?.info?.positionEid}
            dataCy="user-pos-positionEid"
            className="w-[344px] mr-[16px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.positionEid === 'disabled'
            }
          />
        )}
        {config?.editablePlacementFields?.jobEid && (
          <InputField
            label="Job Code"
            placeholder="Job Code"
            handleChange={(e) => changePositionField(e, 'jobEid', 'info')}
            value={detailPosition?.info?.jobEid}
            dataCy="user-pos-jobEid"
            className="w-[344px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.jobEid === 'disabled'
            }
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementFields?.job && (
          <InputField
            label="Job Name"
            placeholder="Job Name"
            handleChange={(e) => changePositionField(e, 'job', 'info')}
            value={detailPosition?.info?.job}
            dataCy="user-job-name"
            className="w-[344px] mr-[16px] mt-[24px]"
            readonly={
              isReadOnly || config?.editablePlacementFields?.job === 'disabled'
            }
          />
        )}
        {config?.editablePlacementFields?.jobFamilyEid && (
          <InputField
            label="Job Family Code"
            placeholder="Job Family Code"
            handleChange={(e) => changePositionField(e, 'jobFamilyEid', 'info')}
            value={detailPosition?.info?.jobFamilyEid}
            dataCy="user-pos-jobFamilyEid"
            className="w-[344px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.jobFamilyEid === 'disabled'
            }
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementFields?.jobFamily && (
          <InputField
            label="Job Family Name"
            placeholder="Job Family Name"
            handleChange={(e) => changePositionField(e, 'jobFamily', 'info')}
            value={detailPosition?.info?.jobFamily}
            dataCy="user-pos-jobFamily"
            className="w-[344px] mr-[16px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.jobFamily === 'disabled'
            }
          />
        )}
        {config?.editablePlacementFields?.area && (
          <InputField
            label="Area"
            placeholder="Area"
            handleChange={(e) => changePositionField(e, 'area', 'info')}
            value={detailPosition?.info?.area}
            dataCy="user-pos-area"
            className="w-[344px] mt-[24px]"
            readonly={
              isReadOnly || config?.editablePlacementFields?.area === 'disabled'
            }
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementFields?.regionEid && (
          <InputField
            label="Region Code"
            placeholder="Region Code"
            handleChange={(e) => changePositionField(e, 'regionEid', 'info')}
            value={detailPosition?.info?.regionEid}
            dataCy="user-pos-regionEid"
            className="w-[344px] mr-[16px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.regionEid === 'disabled'
            }
          />
        )}
        {config?.editablePlacementFields?.region && (
          <InputField
            label="Region Name"
            placeholder="Region Name"
            handleChange={(e) => changePositionField(e, 'region', 'info')}
            value={detailPosition?.info?.region}
            dataCy="user-pos-region"
            className="w-[344px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.region === 'disabled'
            }
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementFields?.branchEid && (
          <InputField
            label="Branch Code"
            placeholder="Branch Code"
            handleChange={(e) => changePositionField(e, 'branchEid', 'info')}
            value={detailPosition?.info?.branchEid}
            dataCy="user-pos-branchEid"
            className="w-[344px] mr-[16px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.branchEid === 'disabled'
            }
          />
        )}
        {config?.editablePlacementFields?.branch && (
          <InputField
            label="Branch Name"
            placeholder="Branch Name"
            handleChange={(e) => changePositionField(e, 'branch', 'info')}
            value={detailPosition?.info?.branch}
            dataCy="user-pos-branch"
            className="w-[344px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.branch === 'disabled'
            }
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementFields?.orgUnitEid && (
          <InputField
            label="Organization Unit Code"
            placeholder="Organization Unit Code"
            handleChange={(e) => changePositionField(e, 'orgUnitEid', 'info')}
            value={detailPosition?.info?.orgUnitEid}
            dataCy="user-pos-orgUnitEid"
            className="w-[344px] mr-[16px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.orgUnitEid === 'disabled'
            }
          />
        )}
        {config?.editablePlacementFields?.previousContract && (
          <InputField
            label="Previous Contract"
            placeholder="Previous Contract"
            handleChange={(e) =>
              changePositionField(e, 'previousState', 'info')
            }
            value={detailPosition?.info?.previousContract}
            dataCy="user-pos-previousContract"
            className="w-[344px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementFields?.previousContract === 'disabled'
            }
          />
        )}
      </div>
      <ManagerField
        detailPosition={detailPosition}
        setDetailPosition={setDetailPosition}
        config={config?.editablePlacementFields?.directManager}
      />
    </div>
  );
};

export default PositionSection;

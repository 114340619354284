import React from 'react';

import { getCultureLocale } from 'utils/HelperUtils';

import Dialog from 'components/shared/Dialog/Dialog';
import ProfileSection from 'components/shared/Profile/ProfileSection';
import SVGIcon from 'components/shared/SVGIcon';

const ManageMemberItem = ({
  member,
  setShowOption,
  setOptionFunction,
  showOption,
  isGroupDefaultModerated,
  totalAdmin,
  type
}) => {
  const optionList = [
    {
      id: 'remove',
      name: getCultureLocale('Remove Member'),
      isVisible: !isGroupDefaultModerated && !member.isAdmin
    },
    {
      id: 'admin',
      name: getCultureLocale('Assign as Admin Group'),
      isVisible: !member.isAdmin
    },
    {
      id: 'remove-admin',
      name: getCultureLocale('Remove as Admin Group'),
      isVisible: member.isAdmin && totalAdmin > 1
    }
  ].filter((option) => option.isVisible);

  return (
    <div
      className="mb-[16px] flex justify-between relative"
      key={`${type}-item-${member?.id}`}
    >
      <ProfileSection
        name={member?.fullName || member?.name}
        profilePicture={
          member?.profilePicture?.secureUrl || member?.profilePicture || null
        }
        desc={member?.email}
        avatarSize={40}
        nameSize="h500"
        totalOwners={1}
      />
      {optionList?.length > 0 && (
        <SVGIcon
          iconName="icon-keyboard_control"
          size="24"
          fillColor="var(--n-600)"
          onClick={() => setShowOption(member.id)}
        />
      )}
      {showOption === member.id && (
        <Dialog
          noSearch
          title=""
          listItem={{
            template: 'default',
            config: {
              labelText: 'name',
              checkMark: false
            }
          }}
          staticOptions={optionList}
          placement="bottom-end"
          containerClassName="mt-[24px] w-[200px]"
          returnSingle
          handleChange={(e) => setOptionFunction(e, member)}
          setShowDialog={setShowOption}
        />
      )}
    </div>
  );
};

export default ManageMemberItem;

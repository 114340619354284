import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import usePermission from 'hooks/usePermission';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Tabs from 'components/design-system/tabs/Tabs';
import SVGIcon from 'components/shared/SVGIcon';

function ProfileTab(props) {
  const parentRef = useRef(null);
  const childrenRef = useRef(null);
  const { user, appType } = useUser();
  const permission = props.permission;
  const isAppCulture =
    appType === 'culture' || (!appType && user.cultureRole && !user.role);
  const isHavePerfRole = user.role;
  const isHaveCultureRole = user.cultureRole;
  let history = useHistory();
  const { url } = useUrl();
  const params = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const isObjectiveActive = urlParams.get('tab') === 'objective';
  const addedParams = urlParams.get('source')
    ? `?source=${urlParams.get('source')}`
    : '';
  const isValueStrengthVisible = usePermission(
    'profileValueStrengthSee',
    permission
  );
  const isValueImprovementVisible = permission?.profileValueImprovementSee;
  const isSkillStrengthVisible = usePermission(
    'profileSkillStrengthSee',
    permission
  );
  const isSkillImprovementVisible = permission?.profileSkillImprovementSee;
  const valuePermission = isValueStrengthVisible || isValueImprovementVisible;
  const skillPermission = isSkillStrengthVisible || isSkillImprovementVisible;
  const [showButtonRight, setShowButtonRight] = useState(false);
  const [showButtonLeft, setShowButtonLeft] = useState(false);

  const resizeParent = new ResizeObserver(function () {
    handleShowChevron();
  });

  const changeScroll = (numberScroll) => {
    childrenRef.current.scrollBy(numberScroll, 0);
    handleShowChevron();
  };

  const handleShowChevron = () => {
    if (!childrenRef.current) return;
    const clientWidth = childrenRef.current.clientWidth;
    const scrollWidth = childrenRef.current.scrollWidth;
    const scrollLeft = Math.ceil(childrenRef.current.scrollLeft);
    if (clientWidth === scrollWidth) {
      setShowButtonRight(false);
      setShowButtonLeft(false);
    } else if (scrollLeft === 0) {
      setShowButtonRight(true);
      setShowButtonLeft(false);
    } else if (scrollLeft + clientWidth >= scrollWidth) {
      setShowButtonRight(false);
      setShowButtonLeft(true);
    } else {
      setShowButtonRight(true);
      setShowButtonLeft(true);
    }
  };

  useEffect(() => {
    resizeParent.observe(parentRef?.current);
    return () => {
      if (parentRef?.current) {
        resizeParent.unobserve(parentRef?.current);
      }
    };
  }, [parentRef]);

  useEffect(() => {
    if (childrenRef?.current) {
      setTimeout(() => handleShowChevron(), 1000);
    }
  }, [childrenRef]);

  useEffect(() => {
    if (params?.values == 'values' && valuePermission) {
      props?.setCurrentTab('values');
    } else if (params?.values == 'skills' && skillPermission) {
      props?.setCurrentTab('skills');
    } else if (
      params?.values == 'team-overview' &&
      permission?.profileTeamOverviewSee
    ) {
      props?.setCurrentTab('team-overview');
    } else {
      props?.setCurrentTab(
        isAppCulture
          ? valuePermission || skillPermission
            ? 'overview'
            : 'activities'
          : 'objective'
      );
    }
    if (isObjectiveActive || url.includes('objectives')) {
      props?.setCurrentTab('objective');
    }
  }, []);

  return (
    <div className="flex mx-[16px] overflow-hidden" ref={parentRef}>
      {showButtonLeft && (
        <SVGIcon
          size="16"
          iconName="icon-keyboard_arrow_left"
          fillColor="var(--n-600)"
          onClick={() => changeScroll(-700)}
          customClass="z-index-1 p-[3px] cursor-pointer m-auto sticky left-[0px] h-[24px] min-w-[24px] flex align-center  bg-n-000 border-radius-50 border-solid border-[1px] border-gray"
        />
      )}
      <Tabs type="large" currentTab={props.currentTab}>
        <Tabs.Buttons
          childrenRef={childrenRef}
          customClass={'mt-[8px] overflow-hidden'}
        >
          {(isAppCulture
            ? valuePermission || skillPermission
            : permission?.profileOverviewSee) && (
            <Tabs.Button
              id={'overview'}
              onClick={() => {
                history.push(
                  `/users/${props?.userId}/placement/${props?.placementId}${addedParams}`
                );
                props.setCurrentTab('overview');
              }}
              role="presentation"
              dataTitle="overview"
              dataCy="profile-overview"
            >
              {getObjectiveLocale('Overview')}
            </Tabs.Button>
          )}
          {isHavePerfRole &&
            !isAppCulture &&
            permission?.profileTeamOverviewSee && (
              <Tabs.Button
                id={'team-overview'}
                onClick={() => {
                  history.push(
                    `/users/${props?.userId}/placement/${props?.placementId}${addedParams}`
                  );
                  props.setCurrentTab('team-overview');
                }}
                role="presentation"
                dataTitle="team-overview"
                dataCy="team-overview"
              >
                {getObjectiveLocale('Team Overview')}
              </Tabs.Button>
            )}
          {isHavePerfRole && !isAppCulture && (
            <Tabs.Button
              id={'objective'}
              onClick={() => {
                history.push(
                  `/users/${props?.userId}/placement/${props?.placementId}${addedParams}`
                );
                props.setCurrentTab('objective');
              }}
              role="presentation"
              dataTitle="objective"
              dataCy="objective"
            >
              {getObjectiveLocale('Goals & Tasks')}
            </Tabs.Button>
          )}
          {valuePermission && (
            <Tabs.Button
              id={'values'}
              onClick={() => {
                history.push(
                  `/users/${props?.userId}/placement/${props?.placementId}/values/strength${addedParams}`
                );
                props.setCurrentTab('values');
              }}
              role="presentation"
              dataTitle="values"
              dataCy="profile-recognition-value"
            >
              {getObjectiveLocale('Values')}
            </Tabs.Button>
          )}
          {skillPermission && (
            <Tabs.Button
              id={'skills'}
              onClick={() => {
                history.push(
                  `/users/${props?.userId}/placement/${props?.placementId}/skills/strength${addedParams}`
                );
                props.setCurrentTab('skills');
              }}
              role="presentation"
              dataTitle="skills"
              dataCy="profile-recognition-strength"
            >
              {getObjectiveLocale('Skills')}
            </Tabs.Button>
          )}
          {permission?.profileTeamPerformanceSee && (
            <Tabs.Button
              id={'performance'}
              onClick={() => {
                history.push(
                  `/users/${props?.userId}/placement/${props?.placementId}${addedParams}`
                );
                props.setCurrentTab('performance');
              }}
              role="presentation"
              dataTitle="performance_report"
            >
              {getObjectiveLocale('Performance Report')}
            </Tabs.Button>
          )}
          {permission?.profileTeamBehaviorSee && (
            <Tabs.Button
              id={'behaviour'}
              onClick={() => {
                history.push(
                  `/users/${props?.userId}/placement/${props?.placementId}${addedParams}`
                );
                props.setCurrentTab('behaviour');
              }}
              role="presentation"
              dataTitle="behaviour_report"
            >
              {getObjectiveLocale('Behaviour Report')}
            </Tabs.Button>
          )}
          <Tabs.Button
            id={'details'}
            onClick={() => {
              history.push(
                `/users/${props?.userId}/placement/${props?.placementId}`
              );
              props.setCurrentTab('details');
            }}
            role="presentation"
            dataTitle="performance_report"
          >
            {getObjectiveLocale('Details')}
          </Tabs.Button>
          {isAppCulture && isHaveCultureRole && (
            <Tabs.Button
              id={'activities'}
              onClick={() => {
                history.push(
                  `/users/${props?.userId}/placement/${props?.placementId}${addedParams}`
                );
                props.setCurrentTab('activities');
              }}
              role="presentation"
              dataTitle="activities"
              dataCy="activities"
            >
              {getObjectiveLocale('Activities')}
            </Tabs.Button>
          )}
        </Tabs.Buttons>
      </Tabs>
      {showButtonRight && (
        <SVGIcon
          size="16"
          iconName="icon-keyboard_arrow_right"
          fillColor="var(--n-600)"
          customClass="p-[3px] cursor-pointer sticky right-[0px] m-auto h-[24px] min-w-[24px] flex align-center  bg-n-000 border-radius-50 border-solid border-[1px] border-gray"
          onClick={() => changeScroll(700)}
        />
      )}
    </div>
  );
}

export default ProfileTab;

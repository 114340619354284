import React from 'react';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { getCssVariableValue } from 'utils/HelperUtils';

import Dropdown, {
  useDropdown
} from 'components/design-system/dropdown/Dropdown';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import Badge from 'components/shared/Badge';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const Trigger = ({ task }) => {
  const { open } = useDropdown();

  const { complexity } = task || null;

  const isHaveComplexity = complexity !== null && complexity > 0;

  return (
    <TooltipContainer
      show={!open && !isHaveComplexity}
      text="Add complexity"
      classContainer="w-full h-full flex items-center px-[16px] relative"
      position="bottom"
    >
      {isHaveComplexity && (
        <Badge
          defaultPointer
          content={complexity}
          colorHex={getCssVariableValue('--n-800')}
          bgColorHex={getCssVariableValue('--n-300')}
          className="pointer-events-none"
        />
      )}
    </TooltipContainer>
  );
};

const Complexity = React.memo(({ task }) => {
  const { open } = useDropdown();
  const { refetchObjective, invalidateTaskQueries } = useRefetchQuery();
  const listComplexity = [
    {
      content: '1'
    },
    {
      content: '2'
    },
    {
      content: '3'
    }
  ];

  const onChangeComplexity = async (value) => {
    const complexity = value ? parseInt(value) : null;

    const { isSuccess } = await editObjective(task.id, {
      complexity: complexity
    });
    if (isSuccess) {
      refetchObjective(task.id);
      invalidateTaskQueries({ task });
    }
  };

  const beforeTrigger = (e, setOpen) => {
    !e.shiftKey && !(e.ctrlKey || e.metaKey) && setOpen();
  };

  return (
    <>
      <Dropdown.Trigger
        customClass="w-full h-full"
        beforeTrigger={beforeTrigger}
        dataCy="trigger-complexity"
      >
        <Trigger task={task} onChangeComplexity={onChangeComplexity} />
      </Dropdown.Trigger>
      {open && (
        <FloatingComponent lockScroll={false}>
          <Dropdown.MenuItems type="absolute" useScrollClose position="right">
            {listComplexity.map((value, index) => (
              <Dropdown.MenuItem
                customClass="w-[96px]"
                onClick={() => onChangeComplexity(value.content)}
                key={index}
                dataCy="menu-item-complexity"
              >
                <Badge
                  defaultPointer
                  content={value.content}
                  colorHex={getCssVariableValue('--n-800')}
                  bgColorHex={getCssVariableValue('--n-300')}
                  className="pointer-events-none"
                />
              </Dropdown.MenuItem>
            ))}
          </Dropdown.MenuItems>
        </FloatingComponent>
      )}
    </>
  );
});

Complexity.displayName = 'Complexity';

const ComplexityComponent = (props) => {
  return (
    <Dropdown customClass="w-full h-full">
      <Complexity {...props} />
    </Dropdown>
  );
};

export default ComplexityComponent;

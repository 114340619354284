import React, { useEffect, useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import { percentageRangeConfig } from '../ConfigRatingTemplate';
import { clearInputElements } from '../RatingTemplateHelper';

const ContentPercentageRangeProgress = ({
  configRating,
  customSubtitle,
  configArray,
  setConfigArray
}) => {
  const [isTyping, setIsTyping] = useState(false);

  const disabledBottomTreshold = (array, className) => {
    for (let i = 1; i < configArray.length; i++) {
      let inputBottomRange = document.getElementById(className + (i + 1));
      inputBottomRange.disabled = true;
      inputBottomRange.type = 'text';
      inputBottomRange.value =
        '>   ' + (array[i - 1].upperThresholdPercentage || '');
    }
  };

  const _dynamicValue = () => {
    let newArray = [];
    let lastIndex = configRating.length - 1;

    for (let i = 0; i < configRating.length; i++) {
      const newItem = {
        score: configRating[i]?.score || 0,
        name: configRating[i]?.name || '',
        colorHex: 'EDA01D',
        bottomThresholdPercentage:
          configRating[i]?.bottomThresholdPercentage ||
          percentageRangeConfig[i]?.bottomThresholdPercentage ||
          0,
        upperThresholdPercentage:
          configRating[i]?.upperThresholdPercentage ||
          percentageRangeConfig[i]?.upperThresholdPercentage ||
          0
      };

      newArray.push(newItem);
    }

    if (configArray.length !== configRating.length) {
      newArray[lastIndex].bottomThresholdPercentage =
        newArray[lastIndex - 1].bottomThresholdPercentage + 25;
      newArray[lastIndex].upperThresholdPercentage =
        newArray[lastIndex - 1].upperThresholdPercentage + 25;
    }

    setConfigArray(newArray);
  };

  useEffect(() => {
    if (!isTyping && configRating) {
      _dynamicValue();
    }
  }, [configRating]);

  useEffect(() => {
    disabledBottomTreshold(configArray, 'input-bottom-range-');
  }, [configArray]);

  const onChangePercentageRange = (value, key, idx) => {
    let arrayConfig = [...configArray];
    setIsTyping(true);
    arrayConfig[idx][key] = Number(value);

    if (key == 'upperThresholdPercentage') {
      if (idx == 0) clearInputElements(arrayConfig, 'input-upper-range-', key);
      let inputBottomRange = document.getElementById(
        'input-bottom-range-' + (idx + 2)
      );
      inputBottomRange.value = '>   ' + value;
      arrayConfig[idx + 1]['bottomThresholdPercentage'] = Number(value) + 1;
    }

    setConfigArray(arrayConfig);

    // detect finish typing
    setTimeout(() => {
      setIsTyping(false);
    }, 1000);
  };

  return (
    <div
      id="percentage-range-progress"
      className="container-content-percentage-range-progress"
    >
      <p className="typography-h500">Percentage range progress </p>
      <p className="content-subtitle">{customSubtitle}</p>

      <div className="header-range">
        <span className="col-rating">{getObjectiveLocale('Rating')}</span>
        <span className="col-label">Label</span>
        <span className="col-range">Range</span>
      </div>

      {configArray.map((val, idx) => {
        const inputProps = (key) => ({
          onChange: (e) => onChangePercentageRange(e.target.value, key, idx),
          type: 'number',
          defaultValue: val[key]
        });

        return (
          <div className="body-range" key={idx + 1}>
            <span className="col-rating">{val.score}</span>
            <span className="col-label">{val.name}</span>
            <div className="col-range">
              <input
                id={'input-bottom-range-' + (idx + 1)}
                className="input-range"
                {...inputProps('bottomThresholdPercentage')}
              />
              <div
                className={`${
                  idx == configArray.length - 1 ? 'd-none' : 'inline-block'
                } dash`}
              />
              <input
                className={`${
                  idx == configArray.length - 1 ? 'd-none' : 'inline-block'
                } input-range`}
                id={'input-upper-range-' + (idx + 1)}
                {...inputProps('upperThresholdPercentage')}
              />
              <span
                className={`${
                  idx == configArray.length - 1
                    ? 'd-none ml-[104px]'
                    : 'inline-block ml-[16px]'
                } percent`}
              >
                %
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContentPercentageRangeProgress;

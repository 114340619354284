import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { createSingleObjective, editObjective } from 'client/ObjectivesClient';
import { useProjectDetail } from 'context/ProjectDetailContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useObjectiveDropdown from 'hooks/useDropdown';
import useTasks from 'hooks/useTasks';
import { useUrl } from 'hooks/useUrl';
import { formatTimezone, getSemester } from 'utils/DateUtils';
import { getObjectiveLocale, toggleDropdown } from 'utils/HelperUtils';
import { extractDefaultRollup } from 'utils/ObjectivesHelper';

import ListDropdown from 'components/objectives/ListDropdown';
import MenuOutsideClickWrapper from 'components/objectives/compact-objective/fragments/MenuOutsideClickWrapper';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

dayjs.extend(quarterOfYear);

const InputObjectiveName = ({
  task,
  setTask,
  filter,
  groupName = 'task',
  groupId,
  taskNode,
  sectionId,
  sectionName,
  type = 'list',
  page = 'mytasks',
  isProject,
  cancelAddTask,
  defaultParent,
  listMetrics = [],
  level,
  setShowChild,
  fetchObjectivesChildren
}) => {
  const { id, name, permissions, blocking, blockedBy } = task;

  const history = useHistory();
  const { match } = useUrl();
  const { reload, reloadSidebar } = useReload();
  const { refetchQueries, refetchObjective, invalidateTaskQueries } =
    useRefetchQuery();
  const { user, config, manager } = useUser();
  const {
    projectId,
    involvements,
    startDate: projectStartDate,
    dueDate: projectDueDate,
    team
  } = useProjectDetail();
  const { defaultPeriodOnCreate, defaultRollupForParentTask } = config;

  const {
    setAction,
    action,
    removeNullTaskOneLevel,
    removeNullTaskTwoLevels,
    removeTaskFromGroupOneLevel,
    removeTaskFromGroupTwoLevels,
    removeTaskChildrenFromParent
  } = useTasks();

  const [inputValue, setInputValue] = useState(name);
  const [isEditing, setIsEditing] = useState(false);
  const [isEllipsis, setIsEllipsis] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [dropdownAnimation, setDropdownAnimation] = useState(
    'animation-open-dropdown-fadeInDownSlow'
  );
  const onChangeEditTask = () => {
    setIsEditing(true);
    setAction('edit');
  };

  const inputRef = useRef();
  const objective = task;
  const isTaskBoard = true;
  const { useMenu } = useObjectiveDropdown({
    objective,
    permissions,
    page,
    onChangeEditTask,
    isTaskBoard,
    groupName,
    sectionName,
    level
  });
  const listMenu = useMenu({
    only: ['editName', 'addSubTask', 'clone', 'delete']
  });

  const handleEditObjectiveName = async () => {
    if (inputValue === name) return;

    if (inputValue === '') {
      setInputValue(name);
      return;
    }

    const { isSuccess } = await editObjective(id, { name: inputValue });
    if (isSuccess) {
      refetchObjective(parseInt(id));
      refetchQueries(['objectives', 'mytasks', groupName?.toLowerCase()]);
      invalidateTaskQueries({ task });
      setTask((draft) => {
        draft.name = inputValue;
      });
      match &&
        reload({
          // if sidebar detail open -> then reload sidebar
          reloadSidebar: !reloadSidebar
        });
    } else {
      setInputValue(name);
    }
    setAction('');
  };

  const getTaskDefaultDate = (parent) => {
    let startDate, dueDate;
    if (parent) {
      startDate = parent.startDate;
      dueDate = parent.dueDate;
      return { startDate, dueDate };
    }
    if (defaultParent) {
      startDate = defaultParent.startDate;
      dueDate = defaultParent.dueDate;
      return { startDate, dueDate };
    }
    if (isProject) {
      const today = dayjs();
      startDate = formatTimezone(today.format('YYYY-MM-DD'), 'start');
      dueDate = formatTimezone(
        today.add(7, 'day').endOf('day').format('YYYY-MM-DD'),
        'end'
      );
      if (today > dayjs(projectDueDate) || today < dayjs(projectStartDate)) {
        startDate = projectStartDate;
        dueDate = formatTimezone(
          dayjs(projectStartDate)
            .add(7, 'day')
            .endOf('day')
            .format('YYYY-MM-DD'),
          'end'
        );
      }
      if (dayjs(dueDate) > dayjs(projectDueDate)) {
        dueDate = projectDueDate;
      }
      return { startDate, dueDate };
    }
    startDate = (
      defaultPeriodOnCreate === 'semester'
        ? getSemester('start')
        : dayjs().startOf(defaultPeriodOnCreate)
    ).toISOString();
    dueDate = formatTimezone(
      dayjs().add(7, 'day').endOf('day').format('YYYY-MM-DD'),
      'end'
    );

    return { startDate, dueDate };
  };

  const handleCreateObjective = async () => {
    if (action === 'create-sub-goal' && inputValue === '') {
      cancelAddTask();
      return;
    } else if (inputValue === '') {
      if (isProject) {
        if (type === 'list') {
          removeNullTaskTwoLevels(sectionName, groupName);
        } else {
          removeNullTaskTwoLevels(groupName, sectionName);
        }
      } else {
        removeNullTaskOneLevel(groupName);
      }
      setIsEditing(false);
      return;
    }

    let assigner = [];
    if (isProject && involvements?.some((user) => user?.role === 'assigner')) {
      assigner.push({
        role: 'assigner',
        userId: involvements?.find(
          (involvement) => involvement?.role === 'assigner'
        )?.user?.id
      });
    } else if (!isProject && manager?.id) {
      assigner.push({ role: 'assigner', userId: manager?.id });
    }

    let measurement = {};
    if (listMetrics?.length > 0) {
      const defaultMetric =
        listMetrics?.find(({ isDefaultForTask }) => isDefaultForTask) || false;
      if (defaultMetric) {
        measurement.startingValue = 0;
        measurement.targetValue = 100;
        measurement.rollUp =
          extractDefaultRollup(defaultMetric?.defaultRollUp) ||
          defaultRollupForParentTask;
        measurement.unitId = defaultMetric?.id;
      }
    }

    let payload = {
      name: inputValue,
      ...getTaskDefaultDate(taskNode?.getParent()?.value),
      type: 'task',
      ...(filter?.showTask === 'project' && filter?.group !== 'priority'
        ? {
            [(filter?.group === 'no-group' ? 'section' : filter.group) +
            'Name']: groupName
          }
        : {
            phaseId: filter?.group === 'phase' ? groupId : null,
            priorityId: filter?.group === 'priority' ? groupId : null
          }),
      involvements: isProject
        ? assigner
        : [
            {
              role: 'assignee',
              userId: user.id,
              placementId: user?.placementId
            },
            ...assigner
          ],
      ...(isProject && { sectionId: sectionId }),
      ...(isProject && { grouped: true }),
      ...((action === 'create-sub-goal' || isProject) && {
        parentId:
          action === 'create-sub-goal'
            ? taskNode.getParent().value.id
            : projectId
      })
    };

    if (measurement) {
      payload.measurement = measurement;
    }

    if (defaultParent?.id) {
      payload.parentId = defaultParent?.id;
    }
    if (team?.id) {
      payload.teamId = team?.id;
    }

    const { isSuccess, data } = await createSingleObjective(payload);

    if (isSuccess && data) {
      if (action === 'create-sub-goal') {
        setTask(() => data[0]);
      } else {
        if (isProject) {
          refetchQueries(['objectives', 'mytasks', groupName, sectionName]);
        } else {
          refetchQueries(['objectives', 'mytasks', groupName?.toLowerCase()]);
        }
      }
      invalidateTaskQueries({ task: data[0], isSubTask: true });
    } else {
      if (action == 'create-sub-goal') {
        cancelAddTask();
      } else {
        if (isProject) {
          if (type === 'list') {
            removeTaskFromGroupTwoLevels(sectionName, groupName, -1);
          } else {
            removeTaskFromGroupTwoLevels(groupName, sectionName, -1);
          }
        } else {
          removeTaskFromGroupOneLevel(groupName, -1);
        }
      }
    }
    setAction('');
  };

  const menuMoreHandler = (e, status) => {
    e.stopPropagation();
    toggleDropdown(status, setDropdownAnimation, setMenuOpen);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Escape' || e.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  const handleRef = (ref) => {
    setIsEllipsis(
      type == 'list'
        ? ref?.offsetWidth < ref?.scrollWidth
        : ref?.offsetHeight < ref?.scrollHeight
    );
  };

  const openSidebarDetail = (e) => {
    const id = isProject ? projectId : task.id;
    if (!id) return;

    if (!isEditing) {
      !e.shiftKey &&
        !(e.ctrlKey || e.metaKey) &&
        history.replace(
          `/${isProject ? `projects/${projectId}` : 'tasks'}/${task.id}`
        );
    } else {
      e.stopPropagation();
    }
  };

  const eventCallbackClick = async (menu) => {
    if (menu.type === 'delete') {
      [...blocking, ...blockedBy]?.map(({ id }) => refetchObjective(id)); // refetch blocking and blocked task to sync dependency between tasks

      level > 0 &&
        removeTaskChildrenFromParent(taskNode.getParent(), taskNode.value.id);

      invalidateTaskQueries({ task });
    } else if (menu.type === 'addSubTask') {
      match && history.replace(isProject ? `/projects/${projectId}` : '/tasks'); // close sidebar detail

      task.childrenCount > 0 &&
        taskNode.getChilds().length === 0 &&
        (await fetchObjectivesChildren());

      setAction('create-sub-goal');
      const currentChilds = [...taskNode.childs];
      const child = {
        name: '',
        id: -1,
        startDate: taskNode.value.startDate,
        dueDate: taskNode.value.dueDate
      };

      taskNode.childs = [];
      taskNode.addChild(child);
      taskNode.childs = [...taskNode.childs, ...currentChilds];

      setTask((draft) => {
        draft.childrenCount = draft.childrenCount + 1;
      });
      setShowChild(true);
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (name === '' && action === 'create') {
      setIsEditing(true);
    } else if (name === '' && action === 'create-sub-goal') {
      setTimeout(() => {
        setIsEditing(true);
      }, 500);
    }
  }, [action]);

  useEffect(() => {
    setInputValue(name);
  }, [name]);

  return (
    <>
      <div
        className={`ml-[8px] w-full h-full flex items-center group ${
          type === 'list' ? 'truncate' : ''
        }`}
        onClick={openSidebarDetail}
        data-cy="trigger-sidebar-detail"
      >
        {isEditing ? (
          <InputField
            ref={inputRef}
            htmlFor="input-task-name"
            dataCy="input-task-name"
            value={inputValue}
            handleChange={(e) => setInputValue(e.target.value)}
            onBlur={() => {
              setIsEditing(false);
              action === 'edit'
                ? handleEditObjectiveName()
                : handleCreateObjective();
            }}
            handleKeyPress={handleKeyPress}
            inputClass="typography-paragraph text-n-900"
          />
        ) : (
          <TooltipContainer
            show={isEllipsis}
            text={inputValue}
            useMaxWidth={false}
            classContainer={type === 'list' ? 'truncate' : ''}
            align="left"
            wrap
          >
            <p
              className={`text-n-900 ${
                type === 'list'
                  ? 'typography-h400 truncate'
                  : 'typography-h400-longform multiline-text-2'
              }`}
              ref={(ref) => handleRef(ref)}
            >
              {inputValue}
            </p>
          </TooltipContainer>
        )}
      </div>
      {!isEditing &&
        (type === 'list' ? (
          <div className="icon-clear-container absolute w-[48px] h-full flex items-center justify-center right-0 hidden group-hover:flex">
            <SVGIcon
              iconName="icon-pencil"
              size="24"
              fillColor="var(--n-600)"
              onClick={(e) => {
                !e.shiftKey && !(e.ctrlKey || e.metaKey) && setIsEditing(true);
                setAction('edit');
              }}
              dataCy="trigger-edit-task"
            />
          </div>
        ) : (
          <div
            className={`absolute right-[16px] top-[8px] ${
              isMenuOpen ? 'visible' : 'invisible group-hover:visible'
            }`}
          >
            <MenuOutsideClickWrapper
              customClass={`relative ml-[8px]`}
              event={() =>
                toggleDropdown(false, setDropdownAnimation, setMenuOpen)
              }
            >
              <TooltipContainer
                show={!isMenuOpen}
                position="bottom"
                text={getObjectiveLocale('More')}
              >
                <div className="flex">
                  <div
                    className="w-[16px] h-[24px]"
                    style={{
                      background:
                        'linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0.64) 100%)'
                    }}
                  />
                  <div
                    data-cy="kebab-action-button"
                    onClick={(e) => {
                      menuMoreHandler(e, !isMenuOpen);
                    }}
                    className="h-[24px]"
                  >
                    <SVGIcon
                      fillColor="var(--n-600)"
                      iconName="icon-keyboard_control"
                      size="24"
                      dataCy="sidebar-detail-kebab"
                      customClass="cursor-pointer border border-solid border-n-400 rounded-[4px] bg-n-000"
                    />
                  </div>
                </div>
              </TooltipContainer>
              {isMenuOpen && (
                <ListDropdown
                  autoClose={() =>
                    toggleDropdown(false, setDropdownAnimation, setMenuOpen)
                  }
                  data={listMenu}
                  dropdownAnimation={dropdownAnimation}
                  eventCallbackClick={eventCallbackClick}
                />
              )}
            </MenuOutsideClickWrapper>
          </div>
        ))}
    </>
  );
};

export default InputObjectiveName;

import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import startCase from 'lodash/startCase';

import {
  deleteRatingTemplate,
  getRatingTemplates,
  putRatingTemplate
} from 'client/admin/RatingTemplateClient';
import useClickOutside from 'hooks/useClickOutside';
import useDebounce from 'hooks/useDebounce';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SearchBar from 'components/shared/SearchBar';
import Modal from 'components/shared/modal/Modal';

import './HomeRatingTemplate.scss';
import ModalSelectRatingType from './ModalSelectRatingType';
import SidebarRatingTemplate from './SidebarRatingTemplate';

const HomeRatingTemplate = ({ route: { title, isReadOnly } }) => {
  const [isModalRatingTypeOpen, setIsModalRatingTypeOpen] = useState(false);
  const [modalDeleteInfo, setModalDeleteInfo] = useState({
    open: false,
    id: null
  });
  const params = useParams();
  const [sidebarInfo, setSidebarInfo] = useState({ open: false, id: null });
  const [listObjTabs, setListObjTabs] = useState([
    { id: 'active', name: 'Active', count: 0 },
    { id: 'inactive', name: 'Inactive', count: 0 }
  ]);

  let defaultTab =
    listObjTabs?.find((tab) => tab?.id === params.tab)?.id ||
    listObjTabs[0]?.id;
  const [activeTab, onChangeTab] = useState(defaultTab);
  const [ratingTemplates, setRatingTemplates] = useState([]);
  const [pagination, setPagination] = useState({});
  const [tmpPagination, setTmpPagination] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const debouncedSearchValue = useDebounce(search, 1000);
  const [pageNumber, setPageNumber] = useState(1);
  const [activeToggleId, setActiveToggleId] = useState(null);

  const history = useHistory();
  const ref = useRef();

  useClickOutside(ref, () => {
    setActiveToggleId(null);
  });

  const changeTab = (tab) => {
    setPageNumber(1);
    onChangeTab(tab);
  };

  const hasMoreHandler = (recordCount) => {
    if (recordCount == 10) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  };

  const getData = async (query) => {
    setIsLoading(true);
    query = {
      ...query,
      limit: 10,
      state: activeTab == 'active' ? ['draft', 'published'] : 'deleted',
      q: search != '' ? search : null
    };

    const {
      data,
      pagination: dataPagination,
      metadata
    } = await getRatingTemplates(query);
    if (data) {
      setRatingTemplates(data);
      if (data.length > 0) {
        setTmpPagination(pagination);
        setPagination(dataPagination);
        hasMoreHandler(data.length);
      } else {
        setPagination(tmpPagination);
        setHasMore(false);
      }
      setListObjTabs([
        { id: 'active', name: 'Active', count: metadata.totalActive },
        { id: 'inactive', name: 'Inactive', count: metadata.totalInactive }
      ]);

      setIsLoading(false);
    }
  };

  const handleEdit = (e, templateId, ratingType) => {
    e.stopPropagation();
    ratingType = ratingType.replace(/_/g, '-');

    if (activeTab == 'active') {
      history.replace(
        `/reviews/rating-template/edit/${ratingType}/${templateId}`
      );
    } else {
      history.replace(
        `/reviews/rating-template/edit/${ratingType}/${templateId}/inactive`
      );
    }
  };

  const handleDelete = (e, templateId) => {
    e.stopPropagation();

    if (activeTab == 'active') {
      setModalDeleteInfo({ open: true, id: templateId });
    } else {
      handleActivate(templateId);
      closeSidebar();
    }
  };

  const handleNext = () => {
    getData({ olderThan: pagination.next.olderThan });
    setPageNumber(pageNumber + 1);
  };

  const handlePrev = () => {
    getData(
      ratingTemplates.length == 0
        ? { olderThan: tmpPagination.next.olderThan }
        : { newerThan: pagination.prev.newerThan }
    );
    setPageNumber(pageNumber - 1);
    setHasMore(true);
  };

  const handleClickToggle = (e, templateId) => {
    e.stopPropagation();
    setActiveToggleId(templateId);
  };

  const getUsedOn = (template) => {
    return `${getNumberLocale(template.usageCount)} ${getObjectiveLocale(
      template.ratingType == 'goal_ongoing' ||
        template.ratingType == 'task_ongoing' ||
        template.ratingType == 'value_recognition'
        ? 'Groups'
        : 'Cycles'
    )}`;
  };

  const handleShowSidebar = (id) => {
    setSidebarInfo({ open: true, id: id });
  };

  const handleCloseDeleteModal = () => {
    setModalDeleteInfo({ open: false, id: null });
  };

  const handleDeactivate = async () => {
    await deleteRatingTemplate(modalDeleteInfo.id);
    handleCloseDeleteModal();
    changeTab('inactive');
  };

  const handleActivate = async (id) => {
    setActiveToggleId(null);
    await putRatingTemplate({ state: 'published' }, id);
    changeTab('active');
  };

  const closeSidebar = () => {
    setSidebarInfo({ open: false, id: null });
  };

  const headerProps = {
    titlePage: title,
    primaryAction: {
      disabled: isReadOnly,
      title: 'Create Rating Template',
      icon: 'icon-add',
      dataCy: 'button-show-modal-select-rating-type',
      onClick: () => setIsModalRatingTypeOpen(true)
    }
  };

  const getRatingType = (ratingType) => {
    if (ratingType == 'review_aspect') {
      return 'scored_attributes';
    }
    return ratingType;
  };

  useEffect(() => {
    getData();
  }, [debouncedSearchValue, activeTab]);

  return (
    <>
      <HeaderPage
        {...headerProps}
        tabs={{
          tabsList: listObjTabs,
          url: '/reviews/rating-template',
          onChange: (tab) => {
            onChangeTab(tab);
          },
          withCount: true
        }}
      />
      <div className="rating-template-container-home-page bg-n-000 min-h-[calc(100vh-64px)] pt-[8px]">
        <div className="my-[16px]">
          <SearchBar
            placeholder="Search Template"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="table-container">
          <div className="header-container">
            <div className="header-row px-[16px] py-[10px]">
              <div className="w-[58%]">
                <span className="typography-h100 typography-secondary">
                  {getObjectiveLocale('Name')}
                </span>
              </div>
              <div className="w-[12%]">
                <span className="typography-h100 typography-secondary">
                  {getObjectiveLocale('Used On')}
                </span>
              </div>
              <div className="w-[26%]">
                <span className="typography-h100 typography-secondary">
                  {getObjectiveLocale('Type')}
                </span>
              </div>
              <div className="w-[4%]"></div>
            </div>
          </div>
          <div className="body-container mt-[4px]">
            {isLoading && <LoadingComponent />}
            {!isLoading &&
              (ratingTemplates.length == 0 ? (
                <ListEmptyState
                  fullHeight
                  emptyIcon="icon-no-result-found"
                  containerClassname="my-[40px]"
                  title={getObjectiveLocale('No results found')}
                  subtitle={getObjectiveLocale(
                    'Did you probably type the keyword incorrectly? We are sorry that what you searched was unfortunately not found.'
                  )}
                />
              ) : (
                ratingTemplates.map((template, idx) => (
                  <div
                    data-cy={`template-${idx}`}
                    key={idx}
                    className="body-row mt-[4px] px-[16px]"
                    onClick={() => {
                      handleShowSidebar(template.id);
                    }}
                  >
                    <div className="template-name w-[58%]">
                      <span className="typography-h400">{template.name}</span>
                    </div>
                    <div className="w-[12%]">{getUsedOn(template)}</div>
                    <div className="w-[26%]">
                      {startCase(getRatingType(template.ratingType))}
                    </div>
                    {!isReadOnly && (
                      <div
                        className="icon w-[4%]"
                        onClick={(e) =>
                          !template.usageCount &&
                          handleClickToggle(e, template.id)
                        }
                      >
                        <div
                          data-cy="action-icon"
                          className="wrapper-icon-and-tooltip"
                        >
                          <i
                            data-cy={'action-icon-' + template.id}
                            className="material-icons"
                          >
                            {!template.usageCount ? 'more_horiz' : 'lock'}
                          </i>
                          {template.usageCount > 0 && (
                            <div className="wrapper-tooltip">
                              <div className="tooltip">
                                {getObjectiveLocale(
                                  'Templates that are in use are not editable'
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        {activeToggleId == template.id && (
                          <div className="pop-up px-[16px] py-[14px]" ref={ref}>
                            <span
                              data-cy={'edit-action-' + template.id}
                              className={`edit ${
                                template.state != 'published' && 'mb-[12px]'
                              }`}
                              onClick={(e) => {
                                handleEdit(
                                  e,
                                  template.id,
                                  getRatingType(template.ratingType)
                                );
                              }}
                            >
                              Edit
                            </span>
                            {!template.usageCount &&
                              template.state == 'draft' && (
                                <span
                                  className="archive"
                                  onClick={(e) => {
                                    handleDelete(e, template.id);
                                  }}
                                >
                                  {getObjectiveLocale('Deactivate')}
                                </span>
                              )}
                            {!template.usageCount &&
                              template.state == 'deleted' && (
                                <span
                                  onClick={() => {
                                    handleActivate(template.id);
                                  }}
                                >
                                  {getObjectiveLocale('Activate')}
                                </span>
                              )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))
              ))}
          </div>
          <div className="footer-container">
            <PaginationModule
              pageNumber={pageNumber}
              handleNext={handleNext}
              handlePrev={handlePrev}
              hasMore={hasMore}
            />
          </div>
        </div>

        {isModalRatingTypeOpen && (
          <ModalSelectRatingType
            setIsModalRatingTypeOpen={setIsModalRatingTypeOpen}
          />
        )}

        {modalDeleteInfo.open && (
          <Modal
            className="w-[400px]"
            title={'Deactivate Template'}
            description={
              'Deactivate this template removes it from your active table. You can always access it again from from your "Inactive" section'
            }
            withPrimaryBtn={{
              danger: true,
              title: 'Deactivate',
              dataCy: 'deactive-template',
              onClick: () => handleDeactivate()
            }}
            withSecondaryBtn={{
              title: 'Cancel',
              dataCy: 'cancel-deactive-template',
              onClick: () => handleCloseDeleteModal(false)
            }}
            eventOnClickClose={() => handleCloseDeleteModal(false)}
          />
        )}
      </div>
      {sidebarInfo.open && (
        <SidebarRatingTemplate
          currentTab={activeTab}
          closeSidebar={closeSidebar}
          templateId={sidebarInfo.id}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          getRatingType={getRatingType}
        />
      )}
    </>
  );
};

export default HomeRatingTemplate;

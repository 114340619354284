import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import { useSidebarMenu } from 'context/SidebarMenuContext';
import { useUser } from 'context/UserContext';

import HeaderPage from 'components/admin/HeaderPage';
import ToastNotif from 'components/shared/ToastNotif';

import HeaderFilter from './HeaderFilter';
import './ObjectiveOverview.scss';
import OverviewAnalytics from './OverviewAnalytics';
import OverviewChart from './OverviewChart';
import OverviewUser from './OverviewUser';

function ObjectiveOverview({ route: { title } }) {
  const usp = new URLSearchParams(window.location.search);
  const { typeObj: type } = useParams();
  const { toggleSidebar } = useSidebarMenu();
  const {
    config: { permissions }
  } = useUser();
  const departmentParams = usp.get('department');

  const history = useHistory();
  const { pathname } = useLocation();
  const tabsList = [
    {
      id: 'owned',
      name: 'Goals Ownership',
      isVisible: permissions?.insightGoalOwnershipSee
    },
    {
      id: 'approved',
      name: 'Goals Approval',
      isVisible: permissions?.insightGoalApprovalSee
    },
    {
      id: 'updated',
      name: 'Goals Update',
      isVisible: permissions?.insightGoalUpdateSee
    },
    {
      id: 'analytics',
      name: 'Goals Analytics',
      isVisible: permissions?.insightGoalAnalyticSee
    }
  ].filter(({ isVisible }) => isVisible);

  const [currentTab, setCurrentTab] = useState(type || tabsList[0]?.id);
  const [selectedParams, setSelectedParams] = useState({
    division: [],
    department: [],
    date: [dayjs().startOf('year'), dayjs().endOf('year')]
  });
  const [toastData, setToastData] = useState({ show: false, message: '' });
  const [switchOption, setSwitchOption] = useState('owned');

  const changeTab = (tab) => {
    if (departmentParams) {
      history.replace(
        `/objective-overview?tab=${tab}/department=${departmentParams}`
      );
    } else {
      history.replace(`/objective-overview?tab=${tab}`);
    }

    if (tab == 'owned') {
      setSwitchOption('owned');
    } else if (tab == 'approved') {
      setSwitchOption('all');
    } else if (tab == 'updated') {
      setSwitchOption('updated_regularly');
    }

    setCurrentTab(tab);
  };

  useEffect(() => {
    toggleSidebar(true);
  }, []);

  useEffect(() => {
    if (pathname.includes('analytics')) {
      changeTab('analytics');
    }
  }, []);

  return (
    <>
      <HeaderPage
        titlePage={title}
        isHeaderComposer={departmentParams}
        backToUrl={`/objective-overview/${currentTab}`}
        useCloseButton={false}
        tabs={{
          tabsList: tabsList,
          url: '/objective-overview',
          onChange: (tab) => {
            changeTab(tab);
          }
        }}
      />
      <ToastNotif
        showNotif={toastData?.show}
        message={toastData?.message}
        warning={toastData?.warning}
        usePortal
      />
      <div
        className={`wrapper min-h-[100vh] pt-[24px] ${
          currentTab == 'analytics' ? 'bg-n-100' : 'bg-n-000'
        }`}
      >
        {currentTab == 'analytics' ? (
          <OverviewAnalytics />
        ) : (
          <>
            <HeaderFilter
              selectedParams={selectedParams}
              setSelectedParams={setSelectedParams}
              currentDepartment={departmentParams}
            />
            <OverviewChart
              currentTab={currentTab}
              selectedParams={selectedParams}
              departmentParams={departmentParams}
            />
            <OverviewUser
              currentTab={currentTab}
              switchOption={switchOption}
              setSwitchOption={setSwitchOption}
              selectedParams={selectedParams}
              departmentParams={departmentParams}
              setToastData={setToastData}
            />
          </>
        )}
      </div>
    </>
  );
}

export default ObjectiveOverview;

import React, { useMemo } from 'react';

import usePulseSurvey from 'src/hooks/usePulseSurvey';

import DateSelector from './DateSelector';

const DateUntilRecurring = ({ isReadOnly }) => {
  const { surveyData, setSurveyData, defaultUntilDate } = usePulseSurvey();
  const { recurrence: recurrenceData } = surveyData;

  const untilValue = useMemo(() => recurrenceData?.until, [recurrenceData]);
  const defaultUntil = useMemo(() => defaultUntilDate, [defaultUntilDate]);

  const handleChangeDate = (value) => {
    setSurveyData({
      ...surveyData,
      recurrence: {
        ...recurrenceData,
        until: value
      }
    });
  };

  return (
    <DateSelector
      isReadOnly={isReadOnly}
      id="recurring-until"
      selectedDate={untilValue}
      callbackFn={handleChangeDate}
      useTime={false}
      disabledDateUntil={defaultUntil}
    />
  );
};

export default DateUntilRecurring;

import React, { Fragment } from 'react';

import { useUser } from 'context/UserContext';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

const SkillValueCard = ({ index, data, type, selector, getDetailSkill }) => {
  const { organization, appType } = useUser();

  const showPerformanceCount = appType !== 'culture';
  const showCultureCount = type === 'strength';

  return (
    <Fragment key={index}>
      <div className="skills-card">
        <div className="flex">
          {data?.iconImageAttributes?.iconUrl && selector === 'value' ? (
            <AWSImage
              className="w-[40px] h-[40px] mr-[16px] rounded-[50px]"
              src={data?.iconImageAttributes?.iconUrl}
            />
          ) : (
            <SVGIcon
              iconName={
                type == 'strength' ? 'icon-thumb_up_alt' : 'icon-feedback'
              }
              fillColor={type == 'strength' ? 'var(--g-600)' : 'var(--y-600)'}
              customClass={`w-[40px] h-[40px] items-center justify-center mr-[16px]  rounded-[50px] ${
                type == 'strength' ? 'bg-g-200' : 'bg-y-200'
              }`}
              size="16"
            />
          )}
          <div>
            <div className="typography-h500">{data?.behaviorTitle}</div>
            <div>
              {showPerformanceCount && (
                <>
                  <span className="tyopgraphy-paragraph text-n-800">
                    {getNumberLocale(data.performanceCount)}
                  </span>{' '}
                  <span className="tyopgraphy-paragraph text-n-600">
                    {organization.appName || getObjectiveLocale('Performance')}
                  </span>
                </>
              )}
              {showPerformanceCount && showCultureCount && (
                <span className="interpunct" />
              )}
              {showCultureCount && (
                <>
                  <span className="tyopgraphy-paragraph text-n-800">
                    {getNumberLocale(data.cultureCount)}
                  </span>{' '}
                  <span className="tyopgraphy-paragraph text-n-600">
                    {organization.cultureAppName ||
                      getObjectiveLocale('Culture')}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        {(data.cultureCount > 0 || data.performanceCount > 0) && (
          <div className="body-skills-card">
            <Button.Tertiary
              onClick={() => getDetailSkill(data?.behaviorId)}
              customClass="mt-[16px]"
            >
              {getObjectiveLocale(
                `${
                  type == 'strength'
                    ? 'See all recognitions'
                    : 'See all feedbacks'
                }`
              )}
            </Button.Tertiary>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SkillValueCard;

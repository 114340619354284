import React from 'react';

const CardKeyBehaviorModel = ({ value }) => {
  return (
    <div className="card-key-behavior-model">
      <div className={`py-[12px] pl-[48px] card-title w-[35%]`}>
        <p>{value.title}</p>
      </div>
      <div className="py-[12px] pl-[16px] w-1/2">
        <p className="">{value.description}</p>
      </div>
      <div className="py-[12px] pl-[16px] pr-[16px] w-[15%]" />
    </div>
  );
};

export default CardKeyBehaviorModel;

import React, { useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { useUser } from 'context/UserContext';
import { useGetPreset } from 'hooks/api/useObjectivesComparative';
import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import { usePreference } from 'hooks/usePreferenceFilter';
import { useUrl } from 'hooks/useUrl';
import { useDeepEffect } from 'utils/useDeepEffect';

import HeaderPage from 'components/admin/HeaderPage';
import LoadingComponent from 'components/shared/LoadingComponent';
import RouterPrompt from 'components/shared/Prompt';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

import HeaderActions from './HeaderActions';
import ObjectiveAnalyticsContent from './ObjectiveAnalyticsContent';
import ObjectiveAnalyticsModal from './ObjectiveAnalyticsModal';
import { getUserCreator } from './helper/objectiveAnalyticsHelper';

function ObjectiveAnalytics({ route: { title } }) {
  const preset = useObjectiveComparative((state) => state.preset);
  const columns = useObjectiveComparative((state) => state.columns);
  const rows = useObjectiveComparative((state) => state.rows);
  const insights = useObjectiveComparative((state) => state.insights);

  const user = useUser();

  const setComparationData = useObjectiveComparative(
    (state) => state.setComparationData
  );
  const isDataChanged = useObjectiveComparative((state) => state.isDataChanged);
  const setPreset = useObjectiveComparative((state) => state.setPreset);
  const resetAllData = useObjectiveComparative((state) => state.resetAllData);

  const [openModal, setOpenModal] = useState('');
  const [closePrompt, setClosePrompt] = useState(false);
  const [nextPreset, setNextPreset] = useState(null);

  const { match } = useUrl();
  const filterPayload = useRef(null);

  const {
    data: preferencesFilter,
    isLoaded,
    saveFilter
  } = usePreference({
    module: 'objectives',
    page: 'analytics',
    section: 'board'
  });

  const preferencesPreset = !isEmpty(preferencesFilter?.data?.filter?.preset)
    ? preferencesFilter?.data?.filter?.preset
    : {
        id: 0,
        name: 'none'
      };

  const disabledHeaderButton =
    preset?.id === 0 &&
    columns?.length === 0 &&
    rows?.length === 0 &&
    insights?.length === 0;

  const presetId = preset?.id ? preset?.id : preferencesPreset?.id;
  const { data: presetData, isLoading } = useGetPreset(presetId, {
    enabled: presetId !== 0 && isLoaded
  });

  const onChangePreset = (preset) => {
    setNextPreset(preset);
    setOpenModal('unsavedPreset');
  };

  const handleActionUnsave = () => {
    if (nextPreset?.id === 0) {
      resetAllData(getUserCreator(user));
    }

    setPreset(nextPreset);
    setOpenModal('');
  };

  useDeepEffect(() => {
    filterPayload.current = {
      filter: {
        preset: preset
      }
    };
  }, [preset]);

  const saveFilterCallback = async () => {
    await saveFilter(filterPayload.current);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', saveFilterCallback, {
      capture: true
    });
    return () => {
      saveFilterCallback();
      window.removeEventListener('beforeunload', saveFilterCallback, {
        capture: true
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (presetId !== 0 && presetData?.data) {
      setComparationData(presetData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetId, presetData]);

  const modalProps = {
    openModal: openModal,
    setOpenModal: setOpenModal,
    onClose: () => setOpenModal(''),
    setClosePrompt: setClosePrompt,
    presetData: presetData,
    actionUnsave: handleActionUnsave
  };

  return (
    <>
      <div className="flex flex-col w-full h-full">
        <HeaderPage
          titlePage={title}
          customAction={
            <HeaderActions
              currentPreset={preferencesPreset}
              isDisabled={disabledHeaderButton}
              setOpenModal={setOpenModal}
              presetData={presetData}
              onChangePreset={onChangePreset}
            />
          }
        />

        {isLoading ? (
          <LoadingComponent className="pt-[24px]" />
        ) : (
          <ObjectiveAnalyticsContent />
        )}
      </div>

      {match && <SidebarMyGoals />}

      {openModal !== '' && <ObjectiveAnalyticsModal {...modalProps} />}

      <RouterPrompt
        when={isDataChanged(presetData?.data)}
        urlException="objective-analytics"
      >
        <ModalUnsave
          setOpenModal={setOpenModal}
          closePrompt={closePrompt}
          presetId={preset?.id}
        />
      </RouterPrompt>
    </>
  );
}

const ModalUnsave = (props) => {
  const {
    onClose,
    showPrompt,
    handleConfirm,
    setOpenModal,
    closePrompt,
    presetId
  } = props;

  const user = useUser();

  const resetAllData = useObjectiveComparative((state) => state.resetAllData);

  const handleActionUnsave = () => {
    if (presetId === 0) {
      resetAllData(getUserCreator(user));
    }

    handleConfirm();
  };

  useEffect(() => {
    if (closePrompt) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePrompt]);

  return (
    <React.Suspense fallback={<div></div>}>
      {showPrompt && (
        <ObjectiveAnalyticsModal
          openModal="unsavedPreset"
          actionSave={() => setOpenModal('savePreset')}
          actionUnsave={handleActionUnsave}
          onClose={onClose}
          isStatus="exitPage"
        />
      )}
    </React.Suspense>
  );
};

export default ObjectiveAnalytics;

import client from './ApiClient';

function getOverviewObjective(params) {
  const url = `/v1/objective_analytics/detail`;
  return client(url, { params });
}

function getOverviewUsers(type, params) {
  const url = `/v1/objective_analytics/users/${type}`;
  return client(url, { params });
}

function remindUsers(body = {}) {
  const url = `/v1/objective_reminders`;
  return client(url, { method: 'PATCH', body });
}

export { getOverviewObjective, getOverviewUsers, remindUsers };

import React from 'react';

import Config from 'utils/Config';

import Modal from 'components/shared/modal/Modal';

function ModalSelectedGroup({
  setModalShowAllOpen,
  setModalAddOpen,
  listAddedGroups,
  handleChangeGroup,
  isReadOnly
}) {
  return (
    <div className="modal-add" data-cy="modal-selected-groups">
      <Modal
        title={'Selected groups'}
        className="admin-culture modal-add org-details-labels"
        withCloseIcon
        eventOnClickClose={() => {
          setModalShowAllOpen(false), setModalAddOpen(true);
        }}
      >
        <div className="selected-groups-body">
          <div className="list-of-selected-group">
            {listAddedGroups.map((label, id) => (
              <div className="selected-group" key={id}>
                {label.name}
                {!isReadOnly && (
                  <div className="close-icon">
                    <img
                      src={Config.asset.formalReview.icCloseGrey}
                      className="close-label"
                      onClick={() => handleChangeGroup(label)}
                    />
                    <div className="tooltip">Remove Group</div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="footer-add-modal">
            <p className="total-group">
              {listAddedGroups.length} groups selected
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalSelectedGroup;

import React, { useState } from 'react';

import { useProjectDetail } from 'context/ProjectDetailContext';
import useSidebar from 'hooks/useSidebar';

import Activities from 'components/project/detail/Activities';
import SidebarProject from 'components/sidebar/SidebarProject';
import ContentTabSwitcher from 'src/components/design-system/ContentTabSwitcher/ContentTabSwitcher';

import Statistics from './Statistics';

const ProjectOverview = ({ projectId, lastUpdated, setLastUpdated }) => {
  const topSidebar = useSidebar((state) => state?.topSidebar);

  const [isAllActivities, setIsAllActivities] = useState(true);

  const { data: objectiveDetail } = useProjectDetail();

  const [currentTab, setCurrentTab] = useState('activities');

  const tabsList = [
    { id: 'activities', name: 'Activities' },
    { id: 'statistics', name: 'Statistics' }
    // { id: "feedback", name: "Feedback" } // TEMPORARILY HIDDEN - will be used for next story
  ];

  return (
    <div className="mt-[24px]">
      <div className="w-[calc(100%-400px)]">
        <ContentTabSwitcher
          tabs={tabsList}
          onChange={(tab) => setCurrentTab(tab)}
          customContainerClass="mx-[40px]"
        />
        {currentTab === 'activities' && (
          <Activities
            projectId={projectId}
            isAllActivities={isAllActivities}
            setIsAllActivities={setIsAllActivities}
            lastUpdated={lastUpdated}
            setLastUpdated={setLastUpdated}
          />
        )}
        {currentTab === 'statistics' && <Statistics />}
      </div>

      {objectiveDetail && (
        <div
          className="sidebar-my-goals sidebar-compact !w-[400px]"
          style={{
            top: `${topSidebar || 0}px`,
            height: `calc(100vh - ${topSidebar || 0}px)`
          }}
        >
          <SidebarProject
            objectiveDetail={objectiveDetail}
            currentTab={currentTab}
            isAllActivities={isAllActivities}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectOverview;

import React from 'react';

import dayjs from 'dayjs';

import { useEditCycle } from 'context/admin/EditCycleContext';
import { capitalize, getObjectiveLocale } from 'utils/HelperUtils';

import EditScheduleCalendar from './EditScheduleCalendar';

const EditSchedulePhaseCard = ({ phaseKey }) => {
  const { stateData, changePhaseSchedule } = useEditCycle();
  const { editedCycle } = stateData;

  const title = capitalize(phaseKey.replace(/([a-z](?=[A-Z]))/g, '$1 '));

  const handleSelectPhaseSchedule = (date) => {
    const [startDate, endDate] = date;
    changePhaseSchedule(phaseKey, startDate, endDate);
  };

  const selectedDateValue =
    editedCycle[phaseKey].startsAt && editedCycle[phaseKey].endsAt
      ? [editedCycle[phaseKey].startsAt, editedCycle[phaseKey].endsAt]
      : [null, null];

  return (
    <div className="border border-solid border-n-400 rounded py-[16px] px-[16px] mt-[24px]">
      <h5 className="typography-h400">{title}</h5>
      <p className="typography-secondary mb-[4px]">
        {getObjectiveLocale('Set schedule for this phase')}
      </p>
      <div className="mt-[16px]">
        <h6 className="typography-h100 typography-secondary mb-[8px]">
          {getObjectiveLocale('Choose your start and end date')}
        </h6>
        <EditScheduleCalendar
          withResetButton={false}
          onSelect={handleSelectPhaseSchedule}
          selectedValue={selectedDateValue}
          withDisabledDate={true}
          startLimitDate={dayjs(editedCycle.reviewProcessStartsAt)}
          endLimitDate={dayjs(editedCycle.reviewProcessEndsAt)}
        />
      </div>
    </div>
  );
};

export default EditSchedulePhaseCard;

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import { getObjectiveChildren } from 'src/client/ObjectivesClient';
import Button from 'src/components/design-system/Button';
import TwoLine from 'src/components/design-system/ProfileType';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

function ApprovalTeamCard({ data, withTop = true, isLast = false }) {
  const history = useHistory();

  const [statesCount, setStatesCount] = useState({});

  const fetchChildrenStateCount = () => {
    const query = {
      metadata: 'count',
      group: 'state'
    };
    return getObjectiveChildren(data?.parentObjectiveIds[0], query);
  };

  const { isFetching: isFetchingStateCount } = useQuery(
    ['teamGoals', 'childrenStateCount', data?.parentObjectiveIds[0]],
    fetchChildrenStateCount,
    {
      onSuccess: (data) => {
        if (data?.metadata?.metadata) {
          const dataApproval = data?.metadata?.metadata;
          setStatesCount({
            draft: dataApproval?.draft?.count,
            edited: dataApproval?.edited?.count,
            toBeDeleted: dataApproval?.toBeDeleted?.count
          });
        }
      }
    }
  );

  return isFetchingStateCount ? (
    <LoadingComponent />
  ) : (
    <div
      className={`border-n400 rounded-[4px] bg-n-000 py-[16px] px-[24px] flex items-center justify-between
        ${withTop ? 'mt-[16px]' : ''} ${isLast ? '' : 'mb-[16px]'}`}
    >
      <TwoLine
        customClass="items-center"
        title={data?.teamName}
        subtitle={`${data?.requesterName} · Approval ${dayjs(
          data?.createdAt
        ).year()} · ${data?.state}`}
        size={40}
        srcPicture={data?.teamPicture}
      />
      <div className="flex items-center">
        <p className="typography-h100 text-n-800 whitespace-nowrap">
          {`${statesCount?.draft} ${getObjectiveLocale('new')} · ${
            statesCount?.edited
          } ${getObjectiveLocale('edited')} · ${
            statesCount?.toBeDeleted
          } ${getObjectiveLocale('To be deleted')}`}
        </p>
        <Button.Tertiary
          customClass="ml-[16px]"
          onClick={() =>
            history.replace({
              pathname: `approval/${data?.id}/team/${data?.teamId}/parent/${data?.parentObjectiveIds[0]}`,
              state: { prevPath: location.pathname }
            })
          }
        >
          {getObjectiveLocale('View Detail')}
        </Button.Tertiary>
      </div>
    </div>
  );
}

export default ApprovalTeamCard;

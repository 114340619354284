import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import { useLocale } from 'src/hooks/useLocale';

import AdminTabsGeneral from '../Tabs/AdminTabsGeneral';
import './ConfigurationBox.scss';
import LeftBar from './LeftBar';

const getListInfo = (key) => {
  let text = '';

  if (key === 'objective.detail.weight') {
    text = 'Weight visibility will refer to weight setting permission first';
  }

  return getObjectiveLocale(text);
};

const ConfigurationBox = React.forwardRef(
  (
    {
      title,
      description,
      tabs,
      boxWidth,
      data,
      loading,
      handleChangeMenu,
      listConfig,
      useHeader = true,
      withBorder = true,
      disabled = false
    },
    ref
  ) => {
    const [currentTab, setCurrentTab] = useState(tabs?.[0]?.id);
    const menu = Object.keys(data);

    const [currentMenu, setCurrentMenu] = useState(menu[0]);

    const tableData = Object.entries(data[currentMenu] || {}).map(
      ([key, value]) => {
        return { id: key, name: key, value, info: getListInfo(key) };
      }
    );

    const _handleChangeMenu = (menu) => {
      handleChangeMenu && handleChangeMenu(menu);
      ref.current = menu;
      setCurrentMenu(menu);
    };

    useEffect(() => {
      if (!currentMenu) {
        setCurrentMenu(menu[0]);
        ref.current = menu[0];
      }
    }, [menu]);

    return (
      <div
        className={`configuration-box bg-n-000 ${
          withBorder ? 'border-gray' : ''
        } mb-[30px]`}
        style={{ width: boxWidth }}
      >
        <div
          className={`header flex flex-col ${
            useHeader ? 'pt-[16px]' : 'pt-[0px]'
          } px-[16px]`}
        >
          <span className="typography-h500">{title}</span>
          <span className="typography-paragraph text-n-800 mt-[0px]">
            {description}
          </span>
        </div>
        <div
          className={`tabs ${
            tabs ? '' : !useHeader ? '' : 'horizontal-divider mt-[16px]'
          }`}
        >
          {tabs && (
            <AdminTabsGeneral
              small
              customClass="mt-[12px]"
              active={currentTab}
              changeTab={(id) => setCurrentTab(id)}
              listObjTabs={tabs}
            />
          )}
        </div>
        <LeftBar
          menu={menu}
          selectedMenu={currentMenu}
          handleChangeMenu={_handleChangeMenu}
          loading={loading}
        />
        <div className="relative">
          <div className="overflow-auto px-[16px] mt-[8px]">
            {tableData.map((data, index) => (
              <ConfigurationItem
                key={data?.name}
                index={index}
                data={data}
                listConfig={listConfig}
                tableData={tableData}
                disabled={disabled}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
);

const ConfigurationItem = ({
  index,
  data,
  listConfig,
  tableData,
  disabled
}) => {
  const { setBothLocale } = useLocale();

  const {
    selectedValues,
    handleSelectValue,
    selectKey,
    trueValue = true,
    falseValue = false
  } = listConfig;

  const valueKey = get(data, selectKey, data?.id);

  const isChecked = data.id.includes('approval')
    ? selectedValues[data.id] !== 'none'
    : selectedValues?.[valueKey] === trueValue;

  const newValue = data.id.includes('approval')
    ? getValueApproval(data.id, selectedValues[data.id])
    : selectedValues[data.id] === trueValue
    ? falseValue
    : trueValue;

  const getValueApproval = (key, currentValue) => {
    let value = true;
    if (currentValue === 'none') {
      if (key === 'approval.request') {
        value = 'self';
      } else {
        value = 'subordinates';
      }
    } else {
      value = 'none';
    }

    return value;
  };

  return (
    <div
      className={`flex justify-between pl-[16px] pr-[6px] py-[22px] ${
        index == tableData.length - 1
          ? ''
          : 'border-solid border-0 border-b border-n-400'
      }`}
    >
      <div className="flex items-center">
        <p>{setBothLocale(data.name)}</p>
        {!isEmpty(data?.info) && (
          <TooltipContainer
            show
            text={data?.info}
            useMaxWidth={false}
            classContainer="ml-[8px]"
          >
            <SVGIcon
              size={14}
              fillColor="var(--n-600)"
              iconName="icon-info"
              customClass="cursor-pointer"
            />
          </TooltipContainer>
        )}
      </div>
      <SmallToggleSwitchPurple
        idStr={`toggle-${data.id}`}
        isChecked={isChecked}
        onChange={() => handleSelectValue(data.id, newValue)}
        disabled={disabled}
      />
    </div>
  );
};

ConfigurationBox.displayName = 'ConfigurationBox';

const ConfigurationBoxInterface = {
  title: PropTypes.string,
  description: PropTypes.string,
  tabs: PropTypes.array,
  boxWidth: PropTypes.string,
  getPeriods: PropTypes.func,
  handleChangePeriods: PropTypes.func,
  data: PropTypes.object,
  listConfig: PropTypes.object
};

const ConfigurationBoxDefaultValue = {
  title: '',
  description: '',
  tabs: null,
  boxWidth: 'unset',
  getPeriods: () => null,
  handleChangePeriods: () => null,
  data: {},
  listConfig: {
    menus: [],
    selectKey: 'id',
    compareKey: '',
    trueValue: true,
    falseValue: false,
    selectedValues: [],
    handleSelectValue: () => null
  }
};

ConfigurationBox.propTypes = ConfigurationBoxInterface;
ConfigurationBox.defaultProps = ConfigurationBoxDefaultValue;

export default ConfigurationBox;

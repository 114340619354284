"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const adminClient_1 = require("client/adminClient");
const HelperUtils_1 = require("utils/HelperUtils");
const CommentBox = ({ surveyId, essayId, occurrenceId }) => {
    const [essayData, setEssayData] = (0, react_1.useState)([]);
    const [offset, setOffset] = (0, react_1.useState)(null);
    const [hasMore, setHasMore] = (0, react_1.useState)(false);
    const getEssay = () => __awaiter(void 0, void 0, void 0, function* () {
        const paramEssay = {
            limit: 10,
            surveyOccurrenceId: +occurrenceId,
            olderThan: offset
        };
        const { data, pagination } = yield (0, adminClient_1.getEssayResult)(surveyId, essayId, paramEssay);
        if (data) {
            setEssayData(essayData.concat(data));
            setOffset(pagination.next.olderThan);
            setHasMore(pagination.next.olderThan == null ? false : true);
        }
    });
    const onScroll = (e) => {
        const target = e.target;
        const loadMore = () => __awaiter(void 0, void 0, void 0, function* () {
            yield getEssay();
        });
        if (hasMore) {
            (0, HelperUtils_1.loadMoreValidator)(target, 100, () => {
                loadMore();
            });
        }
    };
    (0, react_1.useEffect)(() => {
        getEssay();
    }, []);
    return (react_1.default.createElement("div", { className: "flex items-center w-[992px] p-[16px] bg-n-200 rounded-[4px] max-h-[264px]" },
        react_1.default.createElement("div", { className: "overflow-y-scroll", onScroll: onScroll },
            react_1.default.createElement("div", { className: "grid gap-y-[8px] w-full max-h-[232px]" }, (essayData === null || essayData === void 0 ? void 0 : essayData.length) > 0 &&
                essayData.map(({ id, answer }) => {
                    return (react_1.default.createElement("div", { className: "flex flex-row bg-n-000", key: id },
                        react_1.default.createElement("div", { className: "flex mr-[8px]" },
                            react_1.default.createElement("p", { className: "typography-paragraph text-n-900 px-[16px] py-[4px]" }, answer))));
                })))));
};
exports.default = CommentBox;

import React, { Fragment, useState } from 'react';

import { useUser } from 'context/UserContext';
import { capitalize, getCultureLocale } from 'utils/HelperUtils';

import ModalGroupMember from 'components/modal/culture/ModalGroupMember';
import Avatar from 'components/shared/Avatar';

const TimelineDescription = ({
  groupDetail,
  typeGroupList,
  scrollPosition,
  isGroupLoading
}) => {
  const { config } = useUser();
  const [isManageMemberOpen, setIsManageMemberOpen] = useState(false);

  const typeGroup = groupDetail?.groupType?.replace(/_group|_/g, ' ') || '-';
  const typeDescription = typeGroupList.find(
    (type) => type.id === typeGroup.replace(' ', '')
  );
  const top = scrollPosition > 200 && scrollPosition < 318 ? '0px' : '112px';
  const isGroupDefaultModerated =
    groupDetail?.groupType == 'default' ||
    groupDetail?.groupType == 'default_moderated';
  return (
    <div className="ml-[24px]">
      <div
        style={{ top: top }}
        data-cy="timeline-description-section"
        onScroll={(e) => e.stopPropagation()}
        className={`timeline-desc w-[240px] height-fit-content max-h-[480px] overflow-y-auto bg-n-000 p-16 rounded-md border-gray ${
          scrollPosition > 318 ? 'fixed' : 'sticky'
        }`}
      >
        {isGroupLoading ? (
          <div>updating...</div>
        ) : (
          <>
            <div className="typography-h200 mb-[8px]">
              {getCultureLocale('GroupDescription')}
            </div>
            <div
              className="typography-paragraph text-n-800 mb-[24px]"
              data-cy="timeline-description"
            >
              {groupDetail?.description ? groupDetail.description : '-'}
            </div>
            <div className="typography-h200 mb-[8px]">
              {getCultureLocale('GroupType')}
            </div>
            <div
              className="typography-paragraph text-n-800"
              data-cy="timeline-type"
            >
              {capitalize(typeGroup)} {getCultureLocale('Group')}
            </div>
            {typeDescription && (
              <div
                className="typography-paragraph text-n-600"
                data-cy="timeline-type-description"
              >
                ({typeDescription?.desc})
              </div>
            )}
            <div className="typography-h200 mb-[12px] mt-[24px]">
              {getCultureLocale('Group Member')}
            </div>
            <div
              className="flex"
              onClick={() => setIsManageMemberOpen(true)}
              data-cy="timeline-member-section"
            >
              {groupDetail?.members?.length > 0 &&
                groupDetail?.members?.map((member, i) => {
                  return (
                    <Avatar
                      name={member?.fullName}
                      src={member?.profilePicture?.secureUrl || null}
                      className="mr-[4px]"
                      usePortal="portal-avatar"
                      size="medium"
                      key={i}
                    />
                  );
                })}
              {groupDetail?.memberCount > 5 && (
                <div className="min-w-[24px] px-[4px] h-[24px] bg-n-300 text-n-800 typography-h200 rounded-full flex justify-center items-center">
                  +{groupDetail?.memberCount - 5}
                </div>
              )}
            </div>
          </>
        )}
        {isManageMemberOpen &&
          (isGroupDefaultModerated
            ? config?.permissions?.cultureGroupDefaultManage
            : groupDetail.id !== -1) && (
            <ModalGroupMember
              setIsManageMemberOpen={setIsManageMemberOpen}
              groupDetail={groupDetail}
            />
          )}
        <div id="portal-avatar"></div>
      </div>
    </div>
  );
};

export default TimelineDescription;

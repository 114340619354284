import React, { useState } from 'react';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { patchTeamAchievement, postBulkTeam } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import { ParseFile } from 'utils/CsvHelper';
import { getObjectiveLocale, validateEmail } from 'utils/HelperUtils';

import RadioButton from 'components/shared/RadioButton';
import Modal from 'components/shared/modal/Modal';

import ImportSummary from './ImportSummary';
import UploadCSV from './UploadCsv';

const listModalSteps = ['Choose CSV Format', 'Upload CSV', 'Imported Squad'];

const numberRegex = /^\d+$/;
const groupNumberRegex = /^[0-9,\s]+$/;
const quarterRegex = /^[1-4]+$/;
const spaceRegex = /\s/g;

const clearSpace = (value) => {
  return value?.replace(' ', '');
};

const ModalTeamImport = ({ eventOnClickClose }) => {
  const {
    config: { bcaTeamsDefaultPmo },
    organization
  } = useUser();
  const isBca = organization.identifier === 'bca';

  const { refetchQueries } = useRefetchQuery();

  const [currentStep, setCurrentStep] = useState(listModalSteps[0]);

  const [CSVFormat, setCSVFormat] = useState('importSquad');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileErrorMessage, setFileErrorMessage] = useState(null);
  const [fileSuccessMessage, setFileSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const stepNumber =
    listModalSteps.findIndex((step) => step === currentStep) + 1;

  const chooseFile = (e) => {
    let file = e.target.files[0];
    e.target.value = '';
    setFileErrorMessage(null);

    if (file) {
      if (
        file.type === 'text/csv' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        setSelectedFile(file);
      } else {
        setFileErrorMessage('Please upload a file with .csv extension.');
        setSelectedFile(file);
      }
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    setFileErrorMessage(null);
  };

  const setErrorMessage = (message) => {
    setFileErrorMessage(message);
    return false;
  };

  const validateCSV = (csv) => {
    let flag = true;
    let editedParsedData = cloneDeep(csv);
    let findDuplicates = (arr) =>
      arr.filter((item, index) => arr.indexOf(item) != index);
    const listHeader = isBca
      ? [
          {
            name: 'Product Team ID',
            type: 'string',
            length: 255,
            required: true
          },
          {
            name: 'Created Date (YYYYMMDD)',
            type: 'date',
            length: 8,
            required: true
          },
          {
            name: 'Product Team Name',
            type: 'string',
            length: 255,
            required: true
          },
          { name: 'Description', type: 'string', length: 1020, required: true },
          { name: 'NIP of CPO', type: 'number', length: 255, required: true },
          { name: 'NIP of PO', type: 'number', length: 255, required: true },
          {
            name: 'NIP of Reviewer',
            type: 'number',
            length: 255,
            required: true
          },
          {
            name: 'NIP of DT',
            type: 'group-number',
            length: 1020,
            required: true
          }
        ]
      : [
          { name: 'Squad ID', type: 'string', length: 255, required: true },
          {
            name: 'Created Date (YYYYMMDD)',
            type: 'date',
            length: 8,
            required: true
          },
          { name: 'Squad Name', type: 'string', length: 255, required: true },
          { name: 'Description', type: 'string', length: 1020, required: true },
          {
            name: 'Email of Leader',
            type: 'email',
            length: 255,
            required: true
          },
          {
            name: 'Email of Member',
            type: 'email',
            length: 255,
            required: true
          },
          {
            name: 'Email of Reviewer',
            type: 'email',
            length: 255,
            required: true
          },
          {
            name: 'Email of Stakeholder',
            type: 'email',
            length: 1020,
            required: true
          }
        ];

    editedParsedData.map((key, index) => {
      const row = index + 1;
      const haveAllKey = listHeader.every((header) =>
        Object.prototype.hasOwnProperty.call(key, header.name)
      );

      if (!haveAllKey) {
        delete editedParsedData[index];
        flag = setErrorMessage(`Invalid csv header`);

        return;
      }

      listHeader.some((header) => {
        const isRequired = header.required;
        const type = header.type;
        const maxLength = header.length;
        const columnName = header.name;

        if (isRequired && (!key[columnName] || key[columnName] == '')) {
          flag = setErrorMessage(`${columnName} is required at row ${row}`);
          return { flag, editedParsedData };
        }
        const errorMessage = `${columnName} is Invalid at row ${row}`;
        if (
          type == 'date' &&
          !dayjs(key[columnName], 'YYYYMMDD', true).isValid()
        ) {
          flag = setErrorMessage(errorMessage);
          return { flag, editedParsedData };
        }
        if (
          type == 'number' &&
          key[columnName] != '' &&
          key[columnName] != 'null' &&
          !numberRegex.test(key[columnName])
        ) {
          flag = setErrorMessage(errorMessage);
          return { flag, editedParsedData };
        }
        if (
          type == 'group-number' &&
          key[columnName] != '' &&
          key[columnName] != 'null' &&
          !groupNumberRegex.test(key[columnName])
        ) {
          flag = setErrorMessage(errorMessage);
          return { flag, editedParsedData };
        }

        if (type == 'email' && !validateEmail(key[columnName])) {
          flag = setErrorMessage(errorMessage);
          return { flag, editedParsedData };
        }

        if (key[columnName].length > maxLength) {
          flag = setErrorMessage(
            `${columnName} exceeds the character limit(${maxLength}) at row ${row}`
          );
          return { flag, editedParsedData };
        }

        if (
          (columnName == 'Product Team ID' || columnName == 'Squad ID') &&
          spaceRegex.test(key[columnName])
        ) {
          flag = setErrorMessage(errorMessage);
          return { flag, editedParsedData };
        }
      });

      // need for reference
      // listHeader.some(mandatory => {
      //   if (
      //     mandatory.name === 'NIP of DT' ||
      //     mandatory.name === 'Email of Stakeholder'
      //   ) {
      //     if (key[mandatory.name] === '') {
      //       return;
      //     }
      //   } else if (!key[mandatory.name] || key[mandatory.name] === '') {
      //     flag = setErrorMessage(`${mandatory.name} is required at row ${row}`);
      //     return { flag, editedParsedData };
      //   } else if (
      //     (isBca &&
      //       mandatory.name.includes('NIP') &&
      //       isNaN(key[mandatory.name])) ||
      //     (!isBca &&
      //       mandatory.name.includes('Email') &&
      //       !validateEmail(key[mandatory.name])) ||
      //     (mandatory.name.includes('Created Date') &&
      //       !dayjs(key[mandatory.name], 'YYYYMMDD', true).isValid())
      //   ) {
      //     flag = setErrorMessage(
      //       `${mandatory.name} is invalid format at row ${row}`
      //     );
      //     return { flag, editedParsedData };
      //   }
      // });

      const arrayNIPorEmail = isBca
        ? [
            clearSpace(key['NIP of CPO']),
            clearSpace(key['NIP of PO']),
            clearSpace(key['NIP of Reviewer']),
            ...(clearSpace(key['NIP of DT'])?.split(',') || [])
          ].filter((e) => e)
        : [
            key['Email of Leader'],
            key['Email of Member'],
            key['Email of Reviewer'],
            ...(key['Email of Stakeholder']?.split(',') || [])
          ].filter((e) => e);

      if (
        arrayNIPorEmail.filter((val) => bcaTeamsDefaultPmo.includes(val))
          .length > 0
      ) {
        // check NIP yang sudah terdaftar di PMO per row
        const NIPofPMO = arrayNIPorEmail.filter((val) =>
          bcaTeamsDefaultPmo.includes(val)
        )[0];

        flag = setErrorMessage(
          `User with NIP ${NIPofPMO} has already listed as PMO and cannot be involved as any other roles. Please remove them from row ${row}`
        );
        return { flag, editedParsedData };
      }

      if ([...new Set(findDuplicates(arrayNIPorEmail))].length > 0) {
        flag = setErrorMessage(
          `Collection of ${
            isBca ? 'NIP' : 'Email'
          }s should be Unique at row ${row}`
        );
        return { flag, editedParsedData };
      }
    });

    editedParsedData = editedParsedData.filter((e) => e);

    return { flag, editedParsedData };
  };

  const importAchievementHandler = async (parsedData) => {
    let body = [];
    let editedParsedData = cloneDeep(parsedData);
    let flag = true;

    const validateColumn = [
      {
        name: 'Updated Date (YYYYMMDD)',
        type: 'date',
        length: 8,
        required: true
      },
      { name: 'Product Team ID', type: 'string', length: 255, required: true },
      {
        name: 'Product Team Name',
        type: 'string',
        length: 255,
        required: true
      },
      { name: 'Tipe KPI', type: 'string', length: 255, required: true },
      { name: 'Quarter', type: 'number', length: 1, required: false },
      { name: 'Year', type: 'number', length: 4, required: false },
      { name: 'NIP', type: 'number', length: 255, required: false },
      { name: 'Current Value', type: 'number', length: 255, required: false },
      { name: 'Target Value', type: 'number', length: 255, required: false }
    ];

    for (let i = 0; i < editedParsedData.length; i++) {
      const key = editedParsedData[i];
      const haveAllKey = validateColumn.every((header) =>
        Object.prototype.hasOwnProperty.call(key, header.name)
      );

      if (!haveAllKey) {
        flag = setErrorMessage(`Invalid csv header`);
        return;
      }

      const row = i + 2;
      for (let j = 0; j < validateColumn.length; j++) {
        const isRequired = validateColumn[j].required;
        const type = validateColumn[j].type;
        const maxLength = validateColumn[j].length;
        const columnName = validateColumn[j].name;

        if (isRequired && (!key[columnName] || key[columnName] == '')) {
          flag = setErrorMessage(`${columnName} is required at row ${row}`);
          break;
        }
        const errorMessage = `${columnName} is Invalid at row ${row}`;
        if (
          type == 'date' &&
          !dayjs(key[columnName], 'YYYYMMDD', true).isValid()
        ) {
          flag = setErrorMessage(errorMessage);
          break;
        }
        if (
          type == 'number' &&
          key[columnName] != '' &&
          key[columnName] != 'null' &&
          !numberRegex.test(key[columnName])
        ) {
          flag = setErrorMessage(errorMessage);
          break;
        }

        if (columnName == 'Year' && key[columnName].length != maxLength) {
          flag = setErrorMessage(errorMessage);
          break;
        }

        if (key[columnName].length > maxLength) {
          flag = setErrorMessage(
            `${columnName} exceeds the character limit(${maxLength}) at row ${row}`
          );
          break;
        }
        if (
          columnName == 'Product Team ID' &&
          spaceRegex.test(key[columnName])
        ) {
          flag = setErrorMessage(errorMessage);
          return { flag, editedParsedData };
        }
      }

      const type = key['Tipe KPI'].toUpperCase();
      if (type != 'COMMITMENT' && type != 'DEVELOPMENT') {
        flag = setErrorMessage(`Tipe KPI is invalid at row ${row}`);
      }
      if (flag && type == 'COMMITMENT') {
        if (!key['NIP'] || key['NIP'] == '' || key['NIP'] == 'null') {
          flag = setErrorMessage(`NIP is required at row ${row}`);
        }
        if (
          flag &&
          key['Quarter'] &&
          key['Quarter'] != '' &&
          key['Quarter'] != 'null'
        ) {
          flag = setErrorMessage(`Quarter need to be empty at row ${row}`);
        }
      }

      if (flag && type == 'DEVELOPMENT') {
        if (
          !key['Quarter'] ||
          key['Quarter'] == '' ||
          key['Quarter'] == 'null'
        ) {
          flag = setErrorMessage(`Quarter is required at row ${row}`);
        }
        if (flag && key['Quarter'] && !quarterRegex.test(key['Quarter'])) {
          flag = setErrorMessage(`Quarter is invalid at row ${row}`);
        }
        if (flag && key['NIP'] && key['NIP'] != '' && key['NIP'] != 'null') {
          flag = setErrorMessage(`NIP need to be empty at row ${row}`);
        }
      }

      if (!flag) {
        setIsLoading(false);
        return;
      }

      let query = {
        updatedAt: dayjs(key['Updated Date (YYYYMMDD)'], 'YYYYMMDD').format(
          'DD-MM-YYYY'
        ),
        teamExternalId: key['Product Team ID'],
        teamName: key['Product Team Name'],
        goalType: key['Tipe KPI'],
        quarter: parseInt(key['Quarter']) || null,
        year: parseInt(key['Year']),
        userExternalId: key['NIP'],
        currentValue: parseFloat(key['Current Value']),
        targetValue: parseFloat(key['Target Value'])
      };
      body = [...body, query];
    }
    if (flag) {
      const { data } = await patchTeamAchievement({ objectives: body });
      if (data) {
        const queryKey = ['objectives', 'myteams'];
        refetchQueries(queryKey);
        setFileSuccessMessage(data);
        setCurrentStep(listModalSteps[stepNumber]);
        setIsLoading(false);
      }
    }
  };

  const handleUploadCSV = async () => {
    setIsLoading(true);
    let file = selectedFile;
    let parsedData = await ParseFile(file, {
      delimiter: '',
      skipEmptyLines: true
    });
    if (CSVFormat == 'importSquad') {
      let { flag, editedParsedData } = validateCSV(parsedData);

      if (flag) {
        let body = editedParsedData.map((key) => {
          let query = {
            externalId: key['Product Team ID'] || key['Squad ID'],
            createdAt: key['Created Date (YYYYMMDD)'],
            name: key['Product Team Name'] || key['Squad Name'],
            description: key['Description'],
            leader: key['NIP of CPO'] || key['Email of Leader'],
            member: key['NIP of PO'] || key['Email of Member'],
            reviewer: key['NIP of Reviewer'] || key['Email of Reviewer'],
            stakeholder:
              key['NIP of DT']?.trim()?.split(',') ||
              key['Stakeholder']?.trim()?.split(',') ||
              []
          };
          return query;
        });
        const { data } = await postBulkTeam({ teams: body });
        if (data) {
          const queryKey = ['objectives', 'myteams'];
          refetchQueries(queryKey);
          setFileSuccessMessage(data);
          setCurrentStep(listModalSteps[stepNumber]);
        }
        setIsLoading(false);
      }
    } else {
      await importAchievementHandler(parsedData);
    }
    setIsLoading(false);
  };

  const optionsCSVFormat = [
    {
      name: 'importSquad',
      title: 'Import Squad',
      onClick: () => setCSVFormat('importSquad'),
      checkedOption: CSVFormat === 'importSquad'
    },
    ...(isBca
      ? [
          {
            name: 'importSquadAchievement',
            title: 'Import Squad Achievement',
            onClick: () => setCSVFormat('importSquadAchievement'),
            checkedOption: CSVFormat === 'importSquadAchievement'
          }
        ]
      : [])
  ];

  const propsModal = {
    maxHeight: 640,
    className: 'overflow-auto',
    title: getObjectiveLocale(
      currentStep === 'Imported Squad'
        ? CSVFormat === 'importSquad'
          ? 'Imported Squad'
          : 'Imported Squad Goals Achievement'
        : selectedFile
        ? CSVFormat === 'importSquad'
          ? 'Upload your Squad List'
          : 'Upload Product Squad Goals Achievement '
        : 'Import CSV'
    ),
    withSteps: {
      lastStepNumber: listModalSteps.length
    },
    withPrimaryBtn:
      currentStep !== 'Imported Squad'
        ? {
            title:
              currentStep === 'Upload CSV'
                ? CSVFormat === 'importSquad'
                  ? getObjectiveLocale('Import Squad')
                  : getObjectiveLocale('Import Progress')
                : getObjectiveLocale('Next'),
            onClick: () =>
              currentStep === 'Upload CSV'
                ? handleUploadCSV()
                : setCurrentStep(listModalSteps[stepNumber]),
            disabled:
              currentStep == 'Choose CSV Format'
                ? isEmpty(CSVFormat)
                : currentStep === 'Upload CSV' &&
                  (fileErrorMessage || !selectedFile),
            isLoading: isLoading
          }
        : {},
    withSecondaryBtn:
      currentStep == 'Choose CSV Format'
        ? {
            title: getObjectiveLocale('Cancel'),
            onClick: () => eventOnClickClose()
          }
        : {},
    withFileBtn: currentStep === 'Choose CSV Format',
    withDownloadFileBtn:
      currentStep === 'Upload CSV'
        ? {
            title: getObjectiveLocale('Download CSV Template'),
            href:
              CSVFormat === 'importSquad'
                ? isBca
                  ? '/template/CSV-Predefined-Squad.csv'
                  : '/template/CSV-Predefined-Squad-template.csv'
                : '/template/CSV-Predefined-Achievement-Template.csv',
            fileName:
              CSVFormat === 'importSquad'
                ? 'template_CSV_Predefined_Squad.csv'
                : 'template_CSV_Squad_Achievement.csv'
          }
        : false,
    withCloseIcon: true,
    eventOnClickClose: eventOnClickClose
  };

  return (
    <Modal {...propsModal}>
      {currentStep === 'Choose CSV Format' && (
        <section>
          <h1 className="typography-paragraph text-n-800 mt-[0px] mb-[10px]">
            {getObjectiveLocale('Please Choose CSV Format')}
          </h1>

          {optionsCSVFormat.map((option, index) => (
            <RadioButton
              key={index}
              name={option.name}
              id={option.name}
              addClass="mb-[10px]"
              onClick={option.onClick}
              checked={option.checkedOption}
            >
              <p className="typography-paragraph text-n-900 ml-[8px]">
                {getObjectiveLocale(option.title)}
              </p>
            </RadioButton>
          ))}
        </section>
      )}

      {currentStep === 'Upload CSV' && (
        <UploadCSV
          chooseFile={(e) => chooseFile(e)}
          selectedFile={selectedFile}
          fileErrorMessage={fileErrorMessage}
          removeFile={() => removeFile()}
          caption="You can add new squads in bulk by uploading the CSV and upload it in this modal section"
        />
      )}

      {currentStep === 'Imported Squad' && (
        <ImportSummary fileSuccessMessage={fileSuccessMessage} />
      )}
    </Modal>
  );
};

export default ModalTeamImport;

import React from 'react';
import { useInfiniteQuery } from 'react-query';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import merge from 'lodash/merge';
import startCase from 'lodash/startCase';

import { getObjectiveProgressSummaryPdf } from 'client/ObjectivesClient';
import { getListFilter } from 'client/adminClient';
import Config from 'utils/Config.js';
import { formatTimezone, getQuarterRange, getSemester } from 'utils/DateUtils';
import { abbrevNumber, getObjectiveLocale } from 'utils/HelperUtils';
import { CALC_TYPE_LESS_IS_BETTER } from 'utils/const';

dayjs.extend(isoWeek);

const stateIcons = {
  draft: {
    text: 'New',
    iconName: 'icon-add',
    iconColor: 'var(--g-800)',
    backgroundColor: 'var(--g-200)'
  },
  edited: {
    text: 'Edited',
    iconName: 'icon-create',
    iconColor: 'var(--y-800)',
    backgroundColor: 'var(--y-200)'
  },
  existing: {
    text: 'Existing',
    iconName: 'icon-check',
    iconColor: 'var(--b-800)',
    backgroundColor: 'var(--b-200)'
  },
  to_be_deleted: {
    text: 'to_be_deleted',
    iconName: 'icon-delete',
    iconColor: 'var(--n-600)',
    backgroundColor: 'var(--n-300)'
  }
};

function getOwners(involvements, isProject, useVisible = true) {
  let goalOwners = [];

  if (isProject) {
    const projectLeader = getObjectiveInvolvements(involvements, 'leader');
    const projectMember = getObjectiveInvolvements(involvements, 'member');

    goalOwners = [...projectLeader, ...projectMember];
  } else {
    goalOwners = involvements?.filter(
      (owner) =>
        owner.role === 'assignee' && (useVisible ? owner.visible : true)
    );
  }

  let goalOwnersName =
    goalOwners && goalOwners?.length === 1
      ? goalOwners[0].user.name
      : goalOwners && goalOwners.slice(0, 2).map((owner) => owner.user.name);

  let goalOwnersRoles =
    goalOwners && goalOwners?.length === 1
      ? goalOwners[0].user.jobTitle
      : goalOwners?.length > 1 && goalOwners?.length + ' roles';

  let goalOwnersDirectorate =
    goalOwners && goalOwners?.length === 1
      ? goalOwners[0].user?.info?.directorate
      : goalOwners?.length > 1 && goalOwners?.length + ' roles';

  let goalOwnersProfilePic =
    goalOwners && goalOwners.length === 1
      ? goalOwners?.[0]?.user?.profilePicture
      : goalOwners?.length > 1
      ? Config.asset.v2.icoGroup
      : Config.asset.general.userIcon;

  return {
    goalOwners,
    goalOwnersName,
    goalOwnersRoles,
    goalOwnersProfilePic,
    goalOwnersDirectorate
  };
}

function getValue(measurement) {
  let unit = measurement && measurement.unit ? measurement.unit : null;
  let currentValue = measurement && measurement.currentValue;

  let targetValue = measurement && measurement.targetValue;
  let whichSmaller = currentValue >= targetValue ? targetValue : currentValue;
  let valueLabel =
    whichSmaller > 9999999999
      ? 'In billions'
      : whichSmaller > 999999
      ? 'In millions'
      : null;
  let formattedCurrentValue =
    (valueLabel == 'In billions'
      ? currentValue / 1000000000
      : valueLabel == 'In millions'
      ? currentValue / 1000000
      : currentValue) || null;
  let formattedTargetValue =
    (valueLabel == 'In billions'
      ? targetValue / 1000000000
      : valueLabel == 'In millions'
      ? targetValue / 1000000
      : targetValue) || null;
  // formattedCurrentValue % 1 == 0 - > 20 % 1 = 0 | 20.5 % 1 = 0.5
  formattedCurrentValue =
    formattedCurrentValue % 1 == 0
      ? formattedCurrentValue
      : formattedCurrentValue.toFixed(2);
  formattedTargetValue =
    formattedTargetValue % 1 == 0
      ? formattedTargetValue
      : formattedTargetValue.toFixed(2);

  return {
    unit,
    currentValue,
    targetValue,
    whichSmaller,
    valueLabel,
    formattedCurrentValue,
    formattedTargetValue
  };
}

function getSelectedInvolvement(involvements) {
  let selectedOwner =
    involvements?.filter(({ role }) => role == 'assignee') || [];
  let selectedReviewer =
    involvements?.filter(({ role }) => role == 'assigner') || [];
  let selectedFollower =
    involvements?.filter(({ role }) => role == 'follower') || [];

  return { selectedOwner, selectedReviewer, selectedFollower };
}

function getGoalOwnersRoles(goalOwners, useJobTitle = true) {
  const listOfRolesTemp =
    goalOwners?.length > 1
      ? goalOwners.map((index) => {
          return index.user.jobTitle;
        })
      : goalOwners?.[0]?.user.jobTitle;
  const listOfRoles = [...new Set(listOfRolesTemp)];

  const roles =
    goalOwners && goalOwners?.length === 1 ? (
      <>
        {useJobTitle
          ? goalOwners?.[0]?.user.jobTitle
          : goalOwners?.[0]?.user?.info?.directorate}
      </>
    ) : (
      goalOwners?.length > 1 && <>{listOfRoles?.length + ' roles'}</>
    );

  return { roles };
}

const getUserRole = (involvements, currentUser) => {
  const { role, extendedRole } =
    involvements.find(({ user }) => user?.id == currentUser?.id) || {};

  if (role == 'assignee') {
    if (extendedRole == 'leader') {
      return getObjectiveLocale('As leader');
    }

    if (extendedRole == 'pmo') {
      return getObjectiveLocale('As pmo');
    }

    if (extendedRole != 'leader' && extendedRole != 'pmo') {
      return getObjectiveLocale('As member');
    }
  }

  if (role == 'assigner') {
    return getObjectiveLocale('As reviewer');
  }

  if (role == 'follower') {
    return getObjectiveLocale('As stakeholder');
  }

  return '';
};

function getInvolvement(type, involvements) {
  const role = type === 'project' ? 'assignee' : 'leader';

  let sortedAssignee =
    involvements?.filter(
      (obj) =>
        obj.role &&
        obj.role === role &&
        (type === 'project' ? obj.visible : true)
    ) || [];
  let sortedNonAssignee =
    involvements?.filter(
      (obj) =>
        obj.role &&
        obj.role !== role &&
        (type === 'project' ? obj.visible : true)
    ) || [];

  // sortedInvolvements = [sortedAssignee[0]].concat(sortedNonAssignee);

  return { sortedAssignee, sortedNonAssignee };
}

function getClusters(objective) {
  let array = [];

  // eslint-disable-next-line no-prototype-builtins
  const checkCluster = (key) => objective.hasOwnProperty(key);

  Object.keys(objective).forEach((key) => {
    switch (key) {
      case 'involvements': {
        let visibleInvolvements = objective.involvements.filter(
          (involvement) => involvement.visible
        );
        visibleInvolvements.forEach((involvement) => {
          array.push(involvement.role);
        });
        break;
      }
      case 'measurement':
        objective.measurement?.unit && array.push('metric');
        objective.measurement?.progressColorHex && array.push('progress');
        // eslint-disable-next-line no-prototype-builtins
        objective.measurement?.hasOwnProperty('rollUp') && array.push('rollUp');
        break;
      case 'weight':
        checkCluster(key) && array.push(key);
        break;
      case 'parent':
        checkCluster(key) && array.push(key);
        break;
      case 'dueDate':
        checkCluster(key) && array.push(key);
        break;
      case 'complexity':
        checkCluster(key) && array.push(key);
        break;
      case 'recurrence':
        checkCluster(key) && array.push(key);
        break;
      case 'tags':
        checkCluster(key) && array.push(key);
        break;
      case 'startDate':
        checkCluster(key) && array.push(key);
        break;
      case 'objectiveCategory':
        checkCluster(key) && array.push(key);
        break;
      case 'valueLastUpdatedAt':
        checkCluster(key) && array.push(key);
        break;
    }
  });

  return array;
}

function restructureFilter(filter) {
  let allEmployee = filter?.owner?.ownerType === 'all_employees';
  let result = {
    assigneeId: filter?.owner?.assignee?.map(({ userId }) => userId),
    assignerId: filter?.reviewer?.map(({ userId }) => userId),
    followerId: filter?.follower?.map(({ userId }) => userId),
    assigneeDirectorate: filter?.owner?.directorate,
    assigneeDivision: filter?.owner?.division,
    assigneeDepartment: filter?.owner?.department,
    assigneeOrganization: filter?.owner?.organization,
    assigneeSquad: filter?.owner?.squad,
    assigneeRegion: filter?.owner?.region,
    assigneeJobPosition: filter?.owner?.jobPosition,
    assigneeJobTitle: filter?.owner?.jobTitle,
    assigneeJobRole: filter?.owner?.jobRole,
    assigneeJobFunction: filter?.owner?.jobFunction,
    ownerType: filter?.owner?.ownerType,
    state: filter?.position || filter?.phase,
    progressLevel: filter?.status,
    objectiveCategoryId: filter?.type?.map(({ id }) => id),
    filter: filter?.overdue,
    alignment: filter?.alignment,
    sortColumn: filter?.sortColumn,
    sortDirection: filter?.sortDirection,
    q: filter?.q,
    tags: filter?.label,
    periodBegin: filter?.periodBegin,
    periodEndBefore: filter?.periodEndBefore,
    all: 1,
    filterScope: filter?.filterScope,
    parentId: filter?.parentAlignment?.map(({ objectiveId }) => objectiveId),
    roles: filter?.roles,
    integration: filter?.integration,
    type: filter?.goalType
  };

  let filterLastUpdated = filter?.lastUpdated;

  if (filterLastUpdated) {
    let lastUpdated = filterLastUpdated?.lastUpdatedType?.split('.');
    if (lastUpdated) {
      switch (lastUpdated[1]) {
        case 'last_week':
          result.progressUpdatedNewerThan = dayjs()
            .subtract(1, 'weeks')
            .isoWeekday(1)
            .startOf('day')
            .toISOString();
          break;
        case 'last_2_weeks':
          result.progressUpdatedNewerThan = dayjs()
            .subtract(2, 'weeks')
            .isoWeekday(1)
            .startOf('day')
            .toISOString();
          break;
        case 'older_than':
          result.progressUpdatedOlderThan = lastUpdated[2];
          break;
        case 'newer_than':
          result.progressUpdatedNewerThan = lastUpdated[2];
          break;
        case 'no_updates_since_last_month':
          result.progressUpdatedOlderThan = dayjs()
            .subtract(2, 'months')
            .endOf('months')
            .add(1, 'days')
            .startOf('day')
            .toISOString();
          break;
        case 'no_updates_since_last_two_weeks':
          result.progressUpdatedOlderThan = dayjs()
            .subtract(2, 'weeks')
            .isoWeekday(1)
            .startOf('day')
            .toISOString();
          break;
        case 'no_updates_since_last_week':
          result.progressUpdatedOlderThan = dayjs()
            .subtract(1, 'weeks')
            .isoWeekday(1)
            .startOf('day')
            .toISOString();
          break;
      }
    }
  }

  switch (filter?.switch) {
    case 'goal':
      result.type = ['goal', 'annual_goal'];
      result.goals = 'individual';
      break;
    case 'task':
      result.type = ['task'];
      break;
    case 'project':
      result.isProject = true;
      break;
    case 'company-goal':
      result.isCompanyGoal = true;
      break;
    case 'team-goal':
      result.goals = 'team';
      break;
    case 'all':
      delete result.type;
      delete result.isProject;
      delete result.isCompanyGoal;
      break;
  }

  if (allEmployee) {
    result.assigneeId = [];
    result.assigneeDirectorate = [];
    result.assigneeDivision = [];
    result.assigneeDepartment = [];
    result.assigneeOrganization = [];
    result.assigneeSquad = [];
    result.assigneeRegion = [];
    result.assigneeJobPosition = [];
    result.assigneeJobTitle = [];
    result.assigneeJobRole = [];
    result.assigneeJobFunction = [];
    result.ownerType = '';
  }

  if (filter?.goals) result.goals = filter.goals;

  Object.keys(result).forEach((key) => !result[key] && delete result[key]);

  // eslint-disable-next-line no-prototype-builtins
  if (filter?.hasOwnProperty('parentId')) result.parentId = filter?.parentId;

  if (result.filter) {
    result.priorities = 1;
  }

  return result;
}

function whichMilestoneMode(type) {
  switch (type) {
    case 'sum_value':
      return {
        icon: 'icon-functions',
        name: 'Sum',
        calcDesc: 'Cumulative target of all milestone period',
        desc: 'Sum of milestone progress value from the first period until current period reflected to current progress value'
      };
    case 'average_value':
      return {
        icon: 'icon-average',
        name: 'Average',
        calcDesc: 'Cumulative target of all milestone period',
        desc: 'Average of progress percentage from the first period until current period reflected to current progress value'
      };
    case 'latest_value':
      return {
        icon: 'icon-latest',
        name: 'Latest Values',
        calcDesc: 'Total value is last milestone target period',
        desc: 'Display latest milestone progress value and target of the current period reflected to target metrics and current progress'
      };
  }
  return true;
}

function whichBgColorClassName(color, isBackground, variant = '600') {
  switch (color) {
    case '44DB5E':
      return isBackground ? 'bg-g-600' : `var(--g-${variant})`;
    case '5A9AEF':
      return isBackground ? 'bg-b-600' : `var(--b-${variant})`;
    case 'FE2851':
      return isBackground ? 'bg-r-600' : `var(--r-${variant})`;
    case 'FFCD00':
      return isBackground ? 'bg-y-600' : `var(--y-${variant})`;
  }
  return true;
}

const whichMonth = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11
};

const whichStatus = {
  '44DB5E': getObjectiveLocale('On track'),
  '5A9AEF': getObjectiveLocale('Exceeded'),
  FE2851: getObjectiveLocale('At risk'),
  FFCD00: getObjectiveLocale('Left behind')
};

const whichColor = {
  '5A9AEF': 'text-b-600',
  '44DB5E': 'text-g-600',
  FFCD00: 'text-y-600',
  FE2851: 'text-r-600'
};

const whichBadgeColorHex = {
  FFCD00: '713F12', // yellow
  '5A9AEF': '1E3A8A', // blue,
  FE2851: '7F1D1D', // red,
  '44DB5E': '14532D' // green
};

const whichBadgeBgColorHex = {
  FFCD00: 'FEF9C3', // yellow,
  '5A9AEF': 'DBEAFE', // blue
  FE2851: 'FEE2E2', // red
  '44DB5E': 'DCFCE7' // green
};

function getPeriod(data) {
  let periodBegin, periodEndBefore;
  switch (data) {
    case 'today':
      periodBegin = formatTimezone(
        dayjs().startOf('day').format('YYYY-MM-DD'),
        'start'
      );
      periodEndBefore = formatTimezone(
        dayjs().endOf('day').format('YYYY-MM-DD'),
        'end'
      );
      break;
    case 'semester':
      periodBegin = formatTimezone(
        getSemester('start').format('YYYY-MM-DD'),
        'start'
      );
      periodEndBefore = formatTimezone(
        getSemester('end').format('YYYY-MM-DD'),
        'end'
      );
      break;
    case 'quarter':
      periodBegin = formatTimezone(
        dayjs().startOf('quarter').format('YYYY-MM-DD'),
        'start'
      );
      periodEndBefore = formatTimezone(
        dayjs().endOf('quarter').format('YYYY-MM-DD'),
        'end'
      );
      break;
    case 'annual':
    case 'current_year':
      periodBegin = formatTimezone(
        dayjs().startOf('year').format('YYYY-MM-DD'),
        'start'
      );
      periodEndBefore = formatTimezone(
        dayjs().endOf('year').format('YYYY-MM-DD'),
        'end'
      );
      break;
    case 'all_time':
      periodBegin = null;
      periodEndBefore = null;
      break;
    case 'next_year':
      periodBegin = formatTimezone(
        dayjs().add(1, 'year').startOf('year').format('YYYY-MM-DD'),
        'start'
      );
      periodEndBefore = formatTimezone(
        dayjs().add(1, 'year').startOf('year').format('YYYY-MM-DD'),
        'end'
      );
      break;
    case 'last_year':
      periodBegin = formatTimezone(
        dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        'start'
      );
      periodEndBefore = formatTimezone(
        dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
        'end'
      );
      break;
    default:
      periodBegin = formatTimezone(
        dayjs().startOf('day').format('YYYY-MM-DD'),
        'start'
      );
      periodEndBefore = formatTimezone(
        dayjs().endOf('day').format('YYYY-MM-DD'),
        'end'
      );
  }

  return { periodBegin: periodBegin, periodEndBefore: periodEndBefore };
}

function getMilestoneDefault() {
  let initialState = ['Q1', 'Q2', 'Q3', 'Q4']?.map((value) => {
    const { start, due } = getQuarterRange(
      value.toLowerCase(),
      new Date().getFullYear()
    );
    return {
      periodName: value,
      currentValue: 0,
      targetValue: 0,
      startsAt: start,
      endsBefore: due
    };
  });
  return initialState;
}

const getRollupText = (type) => {
  let text;
  switch (type) {
    case 'auto':
      text = 'Auto Sum';
      break;
    case 'average':
      text = 'Auto Avg';
      break;
    case 'weighted_average':
      text = 'Auto Weighted Avg';
      break;
    case 'average_by_phase':
      text = 'Auto Completed Sum';
      break;
    case 'average_by_jira_phase':
      text = 'JIRA';
      break;
    default:
      text = 'Manual';
      break;
  }
  return getObjectiveLocale(text);
};

const getWordingMetrics = (rollUp) => {
  let wordingMetrics;
  switch (rollUp) {
    case 'disabled':
      wordingMetrics =
        'Manual: objective progress is calculated manually, the target progress is fixed until you update it';
      break;
    case 'auto':
      wordingMetrics =
        'Auto Sum: objective progress is accumulated automatically by adding the current values of all the sub objectives or tasks together';
      break;
    case 'average':
      wordingMetrics =
        'Auto Avg: objective progress is calculated automatically by averaging the percentage progress of all the sub objectives or tasks';
      break;
    case 'weighted_average':
      wordingMetrics =
        'Auto Weighted Avg: objective progress is calculated automatically by weighted averaging the percentage progress of all the sub objectives';
      break;
    case 'average_by_phase':
      wordingMetrics =
        'Auto Completed Sum: Update progress automatically by the number of completed tasks or sub goals';
      break;
    case 'average_by_jira_phase':
      wordingMetrics = 'JIRA: Update progress automatically by JIRA progress';
      break;
    default:
      wordingMetrics = '';
      break;
  }

  return getObjectiveLocale(wordingMetrics);
};

const restructureInvolments = (involvements) => {
  return involvements.map((involvement) => ({
    userId: involvement.user.id,
    role: involvement.role,
    user: involvement.user,
    visible: involvement.visible
  }));
};

const getSelectedFollower = (objectiveValue) => {
  if (objectiveValue?.involvements?.length > 0) {
    const selectedFollower = objectiveValue.involvements?.filter(
      ({ role, visible }) => role == 'follower' && visible
    );

    return restructureInvolments(selectedFollower);
  } else return [];
};

const getSelectedReviewer = (objectiveValue) => {
  if (objectiveValue?.involvements?.length > 0) {
    const selectedReviewer = objectiveValue.involvements?.filter(
      ({ role, visible }) => role == 'assigner' && visible
    );

    return restructureInvolments(selectedReviewer);
  } else {
    return [];
  }
};

const getSelectedOwner = (objectiveValue) => {
  if (objectiveValue?.involvements?.length > 0) {
    const selectedOwner = objectiveValue.involvements?.filter(
      ({ role, visible }) => role == 'assignee' && visible
    );

    return restructureInvolments(selectedOwner);
  } else {
    return [];
  }
};

const getExcludeOwner = (objectiveValue) => {
  const selectedReviewer = getSelectedReviewer(objectiveValue);
  const selectedFollower = getSelectedFollower(objectiveValue);

  const excludeOwner = restructureInvolments([
    ...selectedReviewer,
    ...selectedFollower
  ]);

  return excludeOwner.map(({ userId }) => userId);
};

const getMetricsTargetValue = (currentValue, targetValue, calculationType) => {
  const metricsValue = `${abbrevNumber(currentValue, 1, true)} / ${abbrevNumber(
    targetValue,
    1,
    true
  )}`;

  let metricsTargetValue = abbrevNumber(targetValue, 1, true);

  if (calculationType?.includes(CALC_TYPE_LESS_IS_BETTER)) {
    if (metricsValue?.length > 12) {
      metricsTargetValue =
        String(metricsTargetValue).substr(
          0,
          metricsValue?.length < 14 ? 5 : metricsValue?.length > 16 ? 1 : 3
        ) + '..';
    }
  } else {
    if (metricsValue?.length > 16) {
      metricsTargetValue = String(metricsTargetValue).substr(0, 5) + '..';
    }
  }

  return metricsTargetValue;
};

const useFilterMenuObjectives = ({ only, except, config, userId, page }) => {
  const { objectiveWeightType, filterAlignment, filterLastUpdate } =
    config || {};
  const _getFilter = (key, userId, addedParams) => {
    const getFilterOwners = async (key, userId, par) => {
      let params = {
        key,
        userId,
        ...addedParams,
        ...par
      };
      return getListFilter(params);
    };

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['filter', addedParams],
        ({ pageParam }) => getFilterOwners(key, userId, pageParam),
        {
          getNextPageParam: (lastPage) => lastPage.pagination?.next || 0 // will be changes
        }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  let filterOwner = [
    { option: 'owner.assignee', title: "Owner's Name", dialog: 'assignee' },
    {
      option: 'owner.directorate',
      title: "Owner's Directorate",
      dialog: 'directorate',
      additionalQueryFn: (params) => _getFilter('directorate', userId, params)
    },
    {
      option: 'owner.division',
      title: "Owner's Division",
      dialog: 'division',
      additionalQueryFn: (params) => _getFilter('division', userId, params)
    },
    {
      option: 'owner.department',
      title: "Owner's Department",
      dialog: 'department',
      additionalQueryFn: (params) => _getFilter('department', userId, params)
    },
    ...(config?.filterOwner?.map((owner) => {
      let title;
      switch (owner) {
        case 'direct_report':
          title = 'My Direct Reports';
          break;
        case 'subordinates':
          title = 'All Subordinates';
          break;
        case 'all_employees':
          title = 'All Employees';
          break;
        default:
          break;
      }
      return {
        option: owner,
        title: title
      };
    }) || [])
  ];

  const allMenu = {
    owner: {
      option: 'owner',
      title: 'Owner',
      subOption: filterOwner.filter((owner) => owner),
      isVisible: true
    },
    phase: {
      option: 'phase',
      title: 'Phase',
      dialog: 'phase',
      isVisible: true
    },
    overdue: {
      option: 'overdue',
      title: 'Overdue',
      subOption: [{ option: 'overdue', title: 'Overdue Only' }],
      isVisible: true
    },
    status: {
      option: 'status',
      title: 'Status',
      dialog: 'status',
      isVisible: true
    },
    reviewer: {
      option: 'reviewer',
      title: 'Reviewer',
      dialog: 'assigner',
      isVisible: true
    },
    follower: {
      option: 'follower',
      title: 'Follower',
      dialog: 'follower',
      isVisible: true
    },
    alignment: {
      option: 'alignment',
      title: 'Aligned',
      subOption: filterAlignment?.map((option) => ({
        option: option,
        title: startCase(option)
      })),
      isVisible: true
    },
    lastUpdated: {
      option: 'lastUpdated',
      title: 'Last Updated',
      subOption: filterLastUpdate?.map((option) => ({
        option: 'lastUpdated.' + option,
        title: startCase(option),
        calendar: option == 'newer_than' || option == 'older_than'
      })),
      isVisible: true
    },
    label: {
      option: 'label',
      title: 'Label',
      dialog: 'label',
      isVisible: config?.permissions?.objectiveLabelsManage
    },
    type: {
      option: 'type',
      title: 'Type',
      dialog: 'objective-category',
      isVisible: objectiveWeightType == 'type'
    },
    myInvolvement: {
      option: 'myInvolvement',
      title: 'My Involvement',
      dialog: page === 'project' ? 'myInvolvementProject' : 'myInvolvement',
      isVisible: true
    },
    integration: {
      option: 'integration',
      title: 'Integration',
      dialog: 'integration',
      isVisible: config?.enableJiraIntegration
    }
  };

  let dataReturn = [];

  if (only?.length > 0) {
    only.map((menuKey) => {
      if (allMenu[menuKey].isVisible) {
        dataReturn.push(allMenu[menuKey]);
      }
    });
  } else if (except?.length > 0) {
    Object.keys(allMenu).map((key) => {
      if (!except.includes(key) && allMenu[key].isVisible) {
        dataReturn.push(allMenu[key]);
      }
    });
  } else {
    Object.keys(allMenu).map((key) => {
      allMenu[key].isVisible && dataReturn.push(allMenu[key]);
    });
  }

  return dataReturn;
};

const getObjectiveLeader = (involvements, key = 'extendedRole') => {
  const leader = involvements?.find(
    (involvement) => involvement[key] == 'leader'
  );
  return leader?.user;
};

const extractDefaultRollup = (value) => {
  let defaultRollUp;

  if (value == 'auto_sum') {
    defaultRollUp = 'auto';
  } else if (value == 'auto_average') {
    defaultRollUp = 'average';
  } else {
    defaultRollUp = value;
  }

  return defaultRollUp;
};

const getObjectiveInvolvements = (involvements, role, restructureData) => {
  const getCondition = (userRole, extendedRole) => {
    switch (role) {
      case 'assignee':
        return userRole == 'assignee' && extendedRole == null;
      case 'leader':
        return userRole == 'assignee' && extendedRole == 'leader';
      case 'reviewer':
        return userRole === 'assigner';
      case 'member':
        return (
          userRole === 'assignee' &&
          extendedRole !== 'leader' &&
          extendedRole !== 'pmo'
        );
      case 'stakeholder':
      case 'follower':
        return userRole == 'follower' && extendedRole == null;
      case 'pmo':
        return userRole === 'follower' && extendedRole === 'pmo';
    }
  };

  const user =
    involvements?.filter(({ role: userRole, extendedRole, visible }) =>
      getCondition(userRole, extendedRole, visible)
    ) || [];
  return restructureData ? restructureData(user) : user;
};

const getRoles = (myInvolvement) => {
  return myInvolvement.map((involvement) => {
    if (involvement === 'owner') {
      return 'assignee';
    } else if (involvement === 'reviewer') {
      return 'assigner';
    } else {
      return involvement;
    }
  });
};

const getDurationDays = (start, end) => {
  return Math.ceil(Math.abs(end - start) / (1000 * 60 * 60 * 24));
};

const getIncludeTeamGoalsParams = (
  includeTeamGoalsConfig,
  teamId,
  withTeamId = true
) => {
  return !includeTeamGoalsConfig
    ? teamId
      ? { goals: 'team', ...(withTeamId ? teamId : []) }
      : { goals: 'individual' }
    : {};
};

const getAvailableObjectiveListIds = () => {
  let availableObj = [];
  const listElement = document.querySelectorAll(`div[selector-id]`);
  for (let i = 0; i < listElement.length; i++) {
    const selectorId = listElement[i].getAttribute('selector-id');
    availableObj.push(parseInt(selectorId));
  }
  return availableObj;
};

const getObjectiveType = (objective) => {
  if (objective?.isProject) {
    return 'project';
  }

  if (objective?.type == 'task') {
    return 'task';
  }

  return 'goal';
};

const getObjectiveTaskType = (objective) => {
  if (objective) {
    return objective?.taskType || null;
  }
};

const getStateIcons = (key, state) => {
  return (stateIcons?.[state] ? stateIcons?.[state] : stateIcons?.existing)?.[
    key
  ];
};

const getObjectiveCloneActionText = (actionText, metaClone, url, history) => {
  const { startIndex, objectiveId } = metaClone || {};

  const text = actionText.substring(startIndex, 0);
  const clickAbleText = actionText.substring(startIndex, actionText.length);

  const openClickedObjective = () => {
    const newUrl = `${url}/${objectiveId}`;
    history.replace({
      pathname: newUrl,
      search: location.search
    });
  };

  return (
    <>
      {text}{' '}
      <a
        className="typography-link text-base-600 cursor-pointer"
        onClick={openClickedObjective}
      >
        {clickAbleText}
      </a>
    </>
  );
};

const getObjectiveSummaryPdf = async (data) => {
  const {
    ownerId,
    recipientId,
    filter,
    extraFilter,
    placementId,
    parentObjective
  } = data;

  const mergedFilter = merge(restructureFilter(filter), extraFilter);

  let ownerIds = [ownerId];
  if (mergedFilter?.assigneeId) {
    ownerIds =
      mergedFilter?.assigneeId?.length > 0
        ? mergedFilter?.assigneeId?.map((id) => parseInt(id))
        : [mergedFilter?.assigneeId];
    delete mergedFilter.assigneeId;
  }

  const parentId = parentObjective?.childrenCount === 0 ? 'id' : 'parentId';

  const params = {
    reviewsVisibility: 1,
    limit: parentObjective ? 99 : 100,
    recipientId: recipientId,
    ownerIds: ownerIds,
    ...(placementId && { placementId: placementId }),
    ...(parentObjective && {
      [parentId]: parentObjective?.id,
      sortColumn: 'start_date',
      sortDirection: 'asc',
      caseInsensitive: false,
      goals: 'individual'
    }),
    ...mergedFilter
  };

  const { data: summaryData } = await getObjectiveProgressSummaryPdf(params);
  if (summaryData) {
    const newWindow = window.open(
      summaryData?.url,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  }
};

const getObjectiveSummaryPdfToast = (owner, user) => {
  const message = `Processing ${
    owner?.id === user?.id ? 'your' : owner?.name + "'s"
  } objective summary`;

  return {
    title: 'Exporting to PDF',
    msg: message,
    type: 'info'
  };
};

const getObjectiveRoleWording = ({
  user,
  isProject = false,
  isTeam = false
}) => {
  if (user?.role === 'assignee' && user?.extendedRole === 'leader') {
    return 'leader';
  }

  if (user?.role === 'assigner') {
    return 'reviewer';
  }

  if (user?.role === 'assignee' && !user?.extendedRole) {
    return isProject || isTeam ? 'member' : 'owner';
  }

  if (user?.role === 'follower' && !user?.extendedRole) {
    return isProject || isTeam ? 'stakeholder' : 'follower';
  }

  if (user?.role === 'follower' && user?.extendedRole === 'pmo') {
    return 'pmo';
  }
};

const getProjectRolesParams = (myInvolvements) => {
  return myInvolvements.map((involvement) => {
    if (involvement === 'member') {
      return 'assignee';
    } else if (involvement === 'reviewer') {
      return 'assigner';
    } else if (involvement === 'stakeholder') {
      return 'follower';
    } else {
      return involvement;
    }
  });
};

export {
  getOwners,
  getValue,
  getSelectedInvolvement,
  getGoalOwnersRoles,
  getInvolvement,
  getClusters,
  restructureFilter,
  whichBgColorClassName,
  getPeriod,
  getMilestoneDefault,
  getRollupText,
  whichColor,
  whichStatus,
  whichMonth,
  getWordingMetrics,
  getSelectedOwner,
  getExcludeOwner,
  getSelectedReviewer,
  getMetricsTargetValue,
  useFilterMenuObjectives,
  whichBadgeColorHex,
  whichBadgeBgColorHex,
  getObjectiveLeader,
  extractDefaultRollup,
  getObjectiveInvolvements,
  getRoles,
  getDurationDays,
  getIncludeTeamGoalsParams,
  getUserRole,
  getAvailableObjectiveListIds,
  getObjectiveType,
  getObjectiveCloneActionText,
  getObjectiveTaskType,
  getStateIcons,
  getObjectiveSummaryPdf,
  getObjectiveSummaryPdfToast,
  getObjectiveRoleWording,
  getProjectRolesParams,
  whichMilestoneMode
};

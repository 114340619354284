import React, { useEffect, useState } from 'react';

import { getFormalReviewGoalsScoring } from 'client/FormalReviewClient';
import { getGoalsScoring } from 'client/ObjectivesClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import GeneralQuestion from 'components/formal-review/GeneralQuestion';
import HeaderWorkScore from 'components/formal-review/annual-review/HeaderWorkScore';
import ObjectScoring from 'components/formal-review/annual-review/ObjectScoring';
import Objectives from 'components/objectives/Objectives';

const GoalScoringOverallScore = ({
  assignmentId,
  showAdditionalQuestion,
  currentTrackId,
  sectionsAfter,
  target,
  setAnswerContext
}) => {
  const [
    { currentTrack, cycle, involvedUser, goalsScoringData, phase },
    { getSingleTrack }
  ] = useFormalReview();
  const {
    components,
    template,
    preFill,
    suggestions,
    answers,
    showOption = 'score_and_label'
  } = getSingleTrack(currentTrack) || {};
  const { type } = phase;
  const { useComment, view, sliderLabel } = template;

  const [personalScore, setPersonalScore] = useState({});
  const [initialScore, setInitialScore] = useState({});

  const _getScoring = async () => {
    const { data, isSuccess } = await getFormalReviewGoalsScoring(assignmentId);
    if (isSuccess && data) {
      setPersonalScore(data?.personal);
      setInitialScore(data?.initial);
    }
  };

  const sortedOptionsBasedOnScore = components.options.sort(
    (a, b) => a.score - b.score
  );
  const minimumScore = sortedOptionsBasedOnScore[0].score;
  const maximumScore =
    sortedOptionsBasedOnScore[sortedOptionsBasedOnScore.length - 1].score;

  const paramsCoaching = {
    limit: 15,
    reviewsVisibility: 1,
    periodBegin: cycle?.reviewedPeriodStartsAt,
    periodEndBefore: cycle?.reviewedPeriodEndsAt,
    state: 'available',
    assigneeId: involvedUser?.target.id,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    type: ['annual_goal', 'goal'],
    parentNotAssignedTo: involvedUser?.target.id
  };

  useEffect(() => {
    setInitialScore(goalsScoringData?.initial);
  }, [goalsScoringData]);

  useEffect(() => {
    _getScoring();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <HeaderWorkScore
        type="input_score"
        totalNilai={personalScore?.score}
        trackType="goals_scoring"
        showScore={false}
        withBorderBottom={false}
      />
      <p className="mb-[16px] typography-h500 text-n-900">
        {getObjectiveLocale('Input Score for Overall Goals')}
      </p>
      <ObjectScoring
        showOption={showOption}
        key={`object-score-overall-score-${assignmentId}`}
        components={components}
        useComment={useComment}
        view={view}
        minimumScore={minimumScore}
        maximumScore={maximumScore}
        answers={answers}
        sliderLabel={sliderLabel}
        preFill={preFill}
        suggestions={suggestions}
        currentTrack={currentTrack}
        type={type}
        mechanism={template?.mechanism}
        phaseType={type}
        suggestedScoreData={initialScore}
        withLabelInfo={view !== 'raw'}
        withTitle={false}
        objectiveCount={personalScore?.objectiveCount}
      />
      {showAdditionalQuestion && (
        <GeneralQuestion
          key={`${currentTrack}-${currentTrackId}-after`}
          sections={sectionsAfter}
          position="after"
          target={target}
          setAnswer={setAnswerContext}
          assignmentId={assignmentId}
          cycle={cycle}
        />
      )}
      <p className="typography-h500 text-n-900 mb-[16px] mt-[40px]">
        {getObjectiveLocale("All objective's of")}{' '}
        <span className="typography-h500 text-n-800">
          {involvedUser?.target?.name}
        </span>
      </p>
      <Objectives
        cardModel="goal-list"
        page={`goalScoringViewOnly-${assignmentId}`}
        action={false}
        extraFilter={paramsCoaching}
        queryFn={(params) => getGoalsScoring(assignmentId, params)}
      />
    </>
  );
};

export default GoalScoringOverallScore;

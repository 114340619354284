import client from 'client/ApiClient';

function getListPosition(userId, params) {
  return client(`/v1/users/${userId}/placements`, { params });
}

function getGroupData(params) {
  return client(`/v1/admin/groups`, { params });
}

function getDirectManager(params) {
  return client(`/v1/user_placements`, { params });
}

function getIndirectManager(placementId, params) {
  return client(`/v1/placements/${placementId}/parent`, { params });
}

function getDetailPosition(userId, placementId) {
  return client(`/v1/users/${userId}/placements/${placementId}`);
}

function savePosition(userId, body) {
  let url = `/v1/users/${userId}/placements/`;
  return client(url, { method: 'POST', body });
}

function editPosition(userId, placementId, body) {
  let url = `/v1/users/${userId}/placements/${placementId}`;
  return client(url, { method: 'PATCH', body });
}

function checkBehaviorModelGroup(params) {
  return client(`v1/admin/groups/movement`, { params });
}

export {
  getListPosition,
  getGroupData,
  savePosition,
  editPosition,
  getDirectManager,
  getIndirectManager,
  getDetailPosition,
  checkBehaviorModelGroup
};

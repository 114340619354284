import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUrl } from 'hooks/useUrl';

import ProfileEvidence from './ProfileEvidence';
import WrapperAssessmentScore from './WrapperAssessmentScore';
import WrapperTotalScoring from './WrapperTotalScoring';

const CardEachOrWorkSidebar = ({
  showOption,
  id,
  answersData,
  totalScore,
  view
}) => {
  const history = useHistory();
  const { url } = useUrl();

  const showSidebar = (id) => {
    history.replace(`${url}/${id}${history.location.search}`);
  };

  return (
    <div
      id={'container-' + id + '-sidebar'}
      className="mt-[24px]"
      data-cy={'container-' + id + '-sidebar'}
    >
      {totalScore > 0 && <WrapperTotalScoring totalScore={totalScore} />}

      {(answersData || []).map((data, idx) => (
        <div
          key={idx}
          className="evidence-border px-[16px] py-[16px] mb-[16px]"
        >
          <p
            onClick={() => id == 'each' && showSidebar(data?.objective?.id)}
            className={
              'mb-[8px] typography-h500-longform ' +
              (id == 'each' && 'cursor-pointer objective-title')
            }
          >
            {data?.objective?.name}
          </p>

          {data?.objective?.weight > 0 && (
            <p className="evidence-score-box-grey mb-[16px] typography-h200">
              Weight:{' '}
              <span className="typography-h100">{data?.objective?.weight}</span>
            </p>
          )}

          {data?.answers.map((answer, i) => (
            <React.Fragment key={i}>
              <ProfileEvidence profileData={answer?.actor} />

              <WrapperAssessmentScore
                showOption={showOption}
                statusColorHex={answer?.option?.colorHex}
                statusName={answer?.option?.name}
                personalScore={answer?.option?.score || answer?.score}
                isLastIndex={i !== data?.answers?.length - 1}
                description={answer?.comment}
                view={view}
                components={answer?.components}
              />
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CardEachOrWorkSidebar;

import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { getObjectiveLocale } from 'utils/HelperUtils';
import { getCssVariableValue } from 'utils/HelperUtils';

import Badge from 'components/shared/Badge';

const TabsInterface = {
  active: PropTypes.string,
  changeTab: PropTypes.func,
  customClassWrapper: PropTypes.string,
  customClassTab: PropTypes.string,
  customClassText: PropTypes.string,
  customClassCount: PropTypes.string,
  listObjTabs: PropTypes.array,
  withCount: PropTypes.bool,
  withBadge: PropTypes.bool
};

const TabsDefaultValue = {
  active: 'active',
  changeTab: () => {},
  customClassWrapper: '',
  customClassTab: '',
  customClassText: '',
  customClassCount: '',
  listObjTabs: [
    { key: 'active', name: 'Active', count: 0, tooltipText: null },
    { key: 'archived', name: 'Archived', count: 0, tooltipText: null }
  ],
  withCount: true,
  withBadge: false
};

function AdminTabWithCount({
  active,
  changeTab,
  customClassWrapper,
  customClassTab,
  customClassText,
  customClassCount,
  listObjTabs,
  withCount,
  withBadge
}) {
  const [leftPosition, setLeftPosition] = useState(null);
  return (
    <div className={`relative admin-tab-with-count ${customClassWrapper}`}>
      {listObjTabs.map((tab, index) => (
        <div
          key={index}
          data-cy={`tab-${tab.key}`}
          className={`tab-component ${customClassTab} ${
            active == tab.key ? 'active' : ''
          }`}
          onMouseEnter={(e) => setLeftPosition(e.target.offsetLeft)}
          onClick={() => {
            tab?.onClick && tab?.onClick();
            changeTab(tab?.key);
          }}
        >
          <span
            className={`${
              customClassText === '' ? 'typography-h500' : customClassText
            } ${active === tab.key ? 'text-n-900' : 'text-n-600'}`}
          >
            {getObjectiveLocale(tab.name)}
          </span>
          {withCount &&
            (withBadge ? (
              tab.count > 0 && (
                <Badge
                  bgColorHex={getCssVariableValue('--r-600')}
                  colorHex={getCssVariableValue('--n-000')}
                  className="ml-[4px]"
                  content={tab.count}
                />
              )
            ) : (
              <span className={`count ${customClassCount}`}>{`(${
                tab.count ? tab.count : 0
              })`}</span>
            ))}
          {tab?.tooltipText && (
            <div style={{ left: leftPosition }} className="wrapper-tooltip">
              {getObjectiveLocale(tab?.tooltipText)}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

AdminTabWithCount.propTypes = TabsInterface;
AdminTabWithCount.defaultProps = TabsDefaultValue;

export default AdminTabWithCount;

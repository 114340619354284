import React, { createRef } from 'react';
import { Redirect } from 'react-router';
import { matchRoutes, renderRoutes } from 'react-router-config';
import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { SidebarMenuProvider } from 'context/SidebarMenuContext';
import { useUser } from 'context/UserContext';

import Sidebar from 'components/admin/sidebar/Sidebar';

function DashboardPage({ route }) {
  const { config } = useUser();
  const history = useHistory();
  const branch = matchRoutes(route.routes, history.location.pathname);

  const data = route.routes;

  const getRedirectUrl = (routes) => {
    let home = null;
    if (config?.rootPath) {
      const availableRoutes = routes?.filter((route) => {
        return !isEmpty(route?.parent) && route.isVisible;
      });
      const rootPath =
        config?.rootPath == '/reviews' ? '/appraisals' : config?.rootPath;
      home = availableRoutes.find((route) => {
        const path = Array.isArray(route?.path)
          ? route?.path?.[0]
          : route?.path;
        return path == rootPath;
      });
    }

    if (home) {
      return Array.isArray(home?.path) ? home?.path?.[0] : home?.path;
    }

    const filteredRoute = routes?.find((route) => {
      return !isEmpty(route?.parent);
    });

    return Array.isArray(filteredRoute?.path)
      ? filteredRoute?.path?.[0]
      : filteredRoute?.path;
  };

  const RedirectHome = () => {
    let redirectUrl = getRedirectUrl(data);

    return <Redirect to={redirectUrl} />;
  };

  data.push({
    path: '*',
    component: RedirectHome,
    withSidebar: true,
    withHeader: true,
    isVisible: true,
    title: ''
  });

  const mainRef = createRef();
  return (
    <div className="admin-page">
      <SidebarMenuProvider>
        {branch[0]?.route?.withSidebar && <Sidebar />}
        <div
          className={`main-body overflow-overlay overflow-auto`}
          ref={mainRef}
        >
          {renderRoutes(route.routes, { ref: mainRef })}
        </div>
      </SidebarMenuProvider>
    </div>
  );
}

export default DashboardPage;

import React, { useEffect, useState } from 'react';

import {
  camelCaseToSentence,
  capitalize,
  getObjectiveLocale
} from 'utils/HelperUtils';

import ProfileType from 'components/design-system/ProfileType';
import Table from 'components/design-system/table/Table';
import Checkbox from 'components/shared/Checkbox';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import TruncateParagraph from 'components/shared/TruncateParagraph';

const NewManageUserTable = ({
  usersList = [],
  selectedUserIds = [],
  isHeaderChecked,
  handleSelectUser,
  handleSelectAllRow,
  customHeaders = [], // name, widthClass, hide, key, tableColumnClass
  currentSort,
  setCurrentSort,
  involvedMembershipsByInfo = [],
  currentPage,
  handleNextPage,
  handlePrevPage,
  hasMore,
  isLoading
}) => {
  const fixedHeaders = [
    { name: 'No', widthClass: 'w-[64px]', hide: false },
    { name: 'checkbox', widthClass: 'w-[64px]', hide: false },
    { name: 'Name', widthClass: 'w-[240px]', hide: false, grow: true }
  ];

  const defaultHeaders = [
    { name: 'Job Title', widthClass: 'w-[240px]' },
    { name: 'Job Role', widthClass: 'w-[240px]', hide: true },
    { name: 'Job Function', widthClass: 'w-[240px]', hide: true },
    { name: 'Directorate', widthClass: 'w-[240px]', hide: true },
    { name: 'Department', widthClass: 'w-[240px]', hide: true },
    { name: 'Division', widthClass: 'w-[240px]', hide: true },
    { name: 'Region', widthClass: 'w-[240px]', hide: true },
    { name: 'City', widthClass: 'w-[240px]', hide: true },
    { name: 'Country', widthClass: 'w-[240px]', hide: true }
  ];

  const [headers, setHeaders] = useState([
    ...fixedHeaders,
    ...customHeaders,
    ...defaultHeaders
  ]);

  const [infoList, setInfoList] = useState('');

  const setHeaderSort = () => {
    const headerSortIndex = headers?.findIndex(
      (header) =>
        header?.name?.toLowerCase() == currentSort?.sortColumn?.toLowerCase()
    );
    let tempHeaders = headers;
    tempHeaders[headerSortIndex].sort = {
      default: currentSort?.sortDirection || 'asc',
      onSort: (direction) =>
        setCurrentSort({
          sortColumn: currentSort?.sortColumn,
          sortDirection: direction
        })
    };
    setHeaders(tempHeaders);
  };

  const _setInfoList = () => {
    const length = involvedMembershipsByInfo?.length;
    let tempInfoList = '';
    involvedMembershipsByInfo?.map((info, index) => {
      tempInfoList += capitalize(camelCaseToSentence(Object.keys(info)[0]));
      if (length > 1 && index < length - 1) {
        if (length === 2) {
          tempInfoList += ' and ';
        } else {
          tempInfoList += index < length - 2 ? ', ' : ', and ';
        }
      }
    });
    setInfoList(tempInfoList);
  };

  const tooltipText = (checked, groupName) => {
    if (checked) {
      return getObjectiveLocale(
        'This employee has been selected in group [groupName] based on their [infoList]'
      )
        .replace('[groupName]', groupName)
        .replace('[infoList]', infoList);
    } else {
      return getObjectiveLocale(
        'You cannot select members manually because this Behavior Model Group uses auto-assignment'
      );
    }
  };

  useEffect(() => {
    currentSort?.sortColumn && setHeaderSort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSort?.sortColumn]);

  useEffect(() => {
    if (involvedMembershipsByInfo?.length > 0) {
      _setInfoList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [involvedMembershipsByInfo]);

  return (
    <>
      <Table
        headers={headers}
        fixedLeftCount={3}
        withHideColumn={{ customContainerClass: '!z-[9]' }}
        headerCheckbox={{
          checked: isHeaderChecked && usersList?.length > 0,
          onClick: () => handleSelectAllRow(!isHeaderChecked),
          disabled:
            usersList?.length == 0 || involvedMembershipsByInfo?.length > 0
        }}
      >
        {isLoading ? (
          <LoadingComponent />
        ) : usersList?.length > 0 ? (
          usersList?.map((user, index) => {
            const { id, name, email, groupName, jobTitle, info } = user;
            const {
              jobRole,
              jobFunction,
              directorate,
              department,
              division,
              region,
              city,
              country
            } = info;
            return (
              <Table.Row key={id} dataCy={`table-row-${index}`}>
                {/* fixed headers */}
                <Table.Column key="fixed-0">
                  {10 * (currentPage - 1) + index + 1}
                </Table.Column>
                <Table.Column key="fixed-1">
                  <TooltipContainer
                    show={
                      involvedMembershipsByInfo?.length > 0 ||
                      user?.involvedByInfo
                    }
                    text={tooltipText(selectedUserIds?.includes(id), groupName)}
                    useMaxWidth={false}
                  >
                    <Checkbox
                      id={`checkbox-${id}`}
                      value={`checkbox-${id}`}
                      dataCy={`checkbox-${id}`}
                      checked={selectedUserIds?.includes(id)}
                      onChange={(e) => handleSelectUser(e, user)}
                      disabled={
                        involvedMembershipsByInfo?.length > 0 ||
                        user?.involvedByInfo
                      }
                    />
                  </TooltipContainer>
                </Table.Column>
                <Table.Column key="fixed-2">
                  <ProfileType
                    user={user}
                    title={name}
                    subtitle={email}
                    size={32}
                    titleClassName="typography-h400"
                    subtitleClassName="typography-h100"
                  />
                </Table.Column>

                {/* custom headers */}
                {customHeaders?.length > 0 &&
                  customHeaders?.map((header, index) => (
                    <Table.Column
                      key={`custom-${index}`}
                      customClass={header?.tableColumnClass || ''}
                    >
                      <TruncateParagraph>
                        {user?.[header?.key]}
                      </TruncateParagraph>
                    </Table.Column>
                  ))}

                {/* default headers */}
                <Table.Column key="default-0">
                  <TruncateParagraph>{jobTitle}</TruncateParagraph>
                </Table.Column>
                <Table.Column key="default-1">
                  <TruncateParagraph>{jobRole || '-'}</TruncateParagraph>
                </Table.Column>
                <Table.Column key="default-2">
                  <TruncateParagraph>{jobFunction || '-'}</TruncateParagraph>
                </Table.Column>
                <Table.Column key="default-3">
                  <TruncateParagraph>{directorate || '-'}</TruncateParagraph>
                </Table.Column>
                <Table.Column key="default-4">
                  <TruncateParagraph>{department || '-'}</TruncateParagraph>
                </Table.Column>
                <Table.Column key="default-5">
                  <TruncateParagraph>{division || '-'}</TruncateParagraph>
                </Table.Column>
                <Table.Column key="default-6">
                  <TruncateParagraph>{region || '-'}</TruncateParagraph>
                </Table.Column>
                <Table.Column key="default-7">
                  <TruncateParagraph>{city || '-'}</TruncateParagraph>
                </Table.Column>
                <Table.Column key="default-8">
                  <TruncateParagraph>{country || '-'}</TruncateParagraph>
                </Table.Column>
              </Table.Row>
            );
          })
        ) : (
          <ListEmptyState
            emptyIcon="icon-no-user"
            size="large"
            title={getObjectiveLocale('No user found')}
            containerClassname="my-[40px]"
            fullHeight
          />
        )}
      </Table>
      <div className="flex justify-end w-full mt-[12px]">
        <PaginationModule
          pageNumber={currentPage}
          handleNext={handleNextPage}
          handlePrev={handlePrevPage}
          hasMore={hasMore}
        />
      </div>
    </>
  );
};

export default NewManageUserTable;

import React from 'react';

import useParticipantTeam from 'hooks/useParticipantTeam';

import Divider from 'components/design-system/Divider';
import FormTemplate from 'components/surveys/FormTemplate';

const NameDescriptionParticipant = () => {
  const { participantData, setParticipantData } = useParticipantTeam(
    (state) => ({
      participantData: state.participantData,
      setParticipantData: state.setParticipantData
    })
  );

  return (
    <div className="overflow-y-auto overflow-x-hidden">
      <FormTemplate
        title="Give it a name"
        description="Title of this participant group."
        staticChildren={{
          type: 'input',
          title: 'Participant Group Name',
          placeholder: 'Type name here...',
          value: participantData?.name,
          onChange: (value) =>
            setParticipantData({ ...participantData, name: value })
        }}
      />

      <Divider
        style={{
          marginTop: '40px',
          marginBottom: '40px',
          height: '1px',
          width: '720px'
        }}
      />

      <FormTemplate
        title="Say something about this group"
        description="Describe the purpose of creating this group."
        staticChildren={{
          type: 'textarea',
          title: 'Description',
          placeholder: 'Type description here...',
          value: participantData?.description,
          onChange: (value) =>
            setParticipantData({ ...participantData, description: value })
        }}
      />
    </div>
  );
};

export default NameDescriptionParticipant;

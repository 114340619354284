import React, { useEffect, useState } from 'react';

import get from 'lodash/get';

import { useUser } from 'context/UserContext';
import { getCultureLocale, getObjectiveLocale } from 'utils/HelperUtils';

import CircleComponent from 'components/shared/CircleComponent';
import LeftBar from 'components/shared/ConfigurationBox/LeftBar';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import TerritoryBox from './TerritoryBox';

function PermissionConfigurationBoxNew({
  useHeader = true,
  title = '',
  description = '',
  data = {},
  tableFormat,
  handleChangeMenu,
  selectedValues,
  listConfig,
  disabled = false,
  handleSelectValue,
  isDefault,
  setGroup,
  info
}) {
  const { appType, user } = useUser();
  let menu = Object?.keys(data) || data;
  !isDefault && menu?.unshift('Territory');
  const [currentMenu, setCurrentMenu] = useState(menu?.[0]);
  const { selectKey, trueValue = true, falseValue = false } = listConfig;
  const isAdministrator = user?.email == 'administrator@happy5.co';

  const tableData = Object.entries(data?.[currentMenu] || {}).map(
    ([key, value]) => {
      return { id: key, name: key, value };
    }
  );

  const getValueApproval = (key, currentValue) => {
    let value = true;
    if (currentValue === 'none') {
      if (key === 'approval.request') {
        value = 'self';
      } else {
        value = 'subordinates';
      }
    } else {
      value = 'none';
    }

    return value;
  };

  useEffect(() => {
    if (!currentMenu) {
      setCurrentMenu(menu[0]);
    }
    // eslint-disable-next-line
  }, [menu]);

  return (
    <div className="configuration-box bg-n-000 border-gray mb-[30px] w-[1100px]">
      <div
        className={`header flex flex-col ${
          useHeader ? 'pt-[16px]' : 'pt-[0px]'
        } px-[16px]`}
      >
        <span className="typography-h500">{title}</span>
        <span className="typography-paragraph text-n-800 mt-[0px]">
          {description}
        </span>
      </div>
      <div
        className={`tabs ${!useHeader ? '' : 'horizontal-divider mt-[16px]'}`}
      ></div>
      <LeftBar
        menu={menu}
        selectedMenu={currentMenu}
        handleChangeMenu={(menu) => {
          handleChangeMenu(menu);
          setCurrentMenu(menu);
        }}
        loading={false}
      />
      <div className="relative">
        {currentMenu == 'Territory' && !isDefault ? (
          <TerritoryBox info={info} setGroup={setGroup} />
        ) : listConfig.menus?.includes(currentMenu) ? (
          <div className="overflow-auto px-[16px] mt-[8px]">
            {tableData.map((data, index) => {
              const valueKey = get(data, selectKey, data?.id);
              const isChecked =
                data?.id?.includes('approval') && !data?.id?.includes('insight')
                  ? selectedValues?.[data?.id] !== 'none'
                  : selectedValues?.[valueKey] === trueValue;
              const newValue =
                data?.id?.includes('approval') && !data?.id?.includes('insight')
                  ? getValueApproval(data?.id, selectedValues?.[data?.id])
                  : selectedValues?.[data?.id] === trueValue
                  ? falseValue
                  : trueValue;
              return (
                <div
                  key={index}
                  className={`flex justify-between pl-[16px] pr-[6px] py-[22px] ${
                    index == tableData.length - 1
                      ? ''
                      : 'border-solid border-0 border-b border-n-400'
                  }`}
                >
                  <TooltipContainer
                    show={isAdministrator}
                    text={`key: ${data?.name}`}
                  >
                    <p className="typography-paragraph text-n-900">
                      {appType === 'culture'
                        ? getCultureLocale(data.name)
                        : getObjectiveLocale(data.name)}
                    </p>
                  </TooltipContainer>
                  <SmallToggleSwitchPurple
                    idStr={`toggle-${data.id}`}
                    isChecked={isChecked}
                    onChange={() => handleSelectValue(data.id, newValue)}
                    disabled={disabled}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <div className="flex items-center px-[16px] py-[8px] justify-between border-b border-0 border-solid border-n-400">
              {tableFormat?.map((data, index) => {
                return (
                  <p
                    key={index}
                    className={`${index == 0 ? 'w-[248px]' : 'w-[110px]'} ${
                      index !== 0 ? 'justify-center text-center' : ''
                    } typography-h200 typography-secondary uppercase`}
                  >
                    {data?.header}
                  </p>
                );
              })}
            </div>
            {tableData?.map((permissionData, permissionIndex) => {
              return (
                <div
                  className="flex items-center px-[16px] py-[12px] justify-between hover:bg-base-30024 cursor-pointer"
                  key={permissionIndex}
                >
                  <TooltipContainer
                    show={isAdministrator}
                    text={`key: ${permissionData?.name}`}
                  >
                    <p className="w-[248px] typography-paragraph text-n-900">
                      {appType === 'culture'
                        ? getCultureLocale(permissionData.name)
                        : getObjectiveLocale(permissionData.name)}
                    </p>
                  </TooltipContainer>

                  {tableFormat?.map((valueData, valueIndex) => {
                    if (valueIndex !== 0) {
                      return (
                        <CircleComponent
                          onClick={() =>
                            handleSelectValue(
                              permissionData.id,
                              valueData?.value
                            )
                          }
                          isChecked={
                            selectedValues[permissionData.id] ==
                            valueData?.value
                          }
                          disabled={disabled}
                          name={`${valueData?.header}+${valueIndex}`}
                          containerClass="w-[110px] flex justify-center"
                          key={valueIndex}
                        >
                          <SVGIcon
                            size="16"
                            iconName="icon-check"
                            fillColor="var(--n-000)"
                          />
                        </CircleComponent>
                      );
                    }
                  })}
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default PermissionConfigurationBoxNew;

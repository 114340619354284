import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

const RouterPrompt = ({ when, urlException, children }) => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const unblockRef = useRef();

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when) {
        if (urlException && location.pathname.includes(urlException))
          return true;

        setCurrentPath(location.pathname);
        setShowPrompt(true);
        return false;
      }
      return true;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when]);

  const handleConfirm = () => {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    history.push(currentPath);
  };

  return (
    children &&
    React.cloneElement(children, {
      showPrompt: showPrompt,
      onClose: () => setShowPrompt(false),
      handleConfirm: handleConfirm
    })
  );
};

export default RouterPrompt;

import React from 'react';

import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';

import SVGIcon from 'components/shared/SVGIcon';

import './TimePickerV2.scss';

const TimePickerV2 = ({
  selectedTime,
  handleChange,
  isReadOnly,
  use24HFormat = false,
  useVisualGmt7 = false,
  customClass = '',
  minutesStep,
  format,
  customClassIcon = '',
  TextFieldComponent,
  customColor
}) => {
  const labelFunction = (date) => {
    const format = use24HFormat ? 'HH:mm' : 'hh:mm A';
    const returnTime = dayjs(date).format(format);
    return `${returnTime} ${useVisualGmt7 ? '(GMT+7)' : ''}`;
  };

  return (
    <div
      className={`relative ${
        isReadOnly ? 'disabled-time-picker' : ''
      } ${customClass}`}
    >
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        {!TextFieldComponent && (
          <SVGIcon
            size="24"
            iconName="icon-access_time"
            fillColor={customColor ? customColor : 'var(--base-600)'}
            customClass={`absolute z-index-10 right-[24px] top-[4px] pointer-events-none ${customClassIcon}`}
          />
        )}
        <TimePicker
          value={selectedTime}
          onChange={(time) => handleChange(time)}
          minutesStep={minutesStep}
          format={format}
          ampm={!use24HFormat}
          labelFunc={labelFunction}
          readOnly={isReadOnly}
          TextFieldComponent={TextFieldComponent}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default TimePickerV2;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useImmer } from 'use-immer';

import { getOtherApproval } from 'client/ApprovalClient';
import { getListFilter } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Button from 'components/design-system/Button';
import ProfileType from 'components/design-system/ProfileType';
import Table from 'components/design-system/table/Table';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';

function OthersApproval({ setRequestIsEmpty }) {
  const history = useHistory();
  const { config } = useUser();
  const isUsingApprovalApprove = config.permissions?.approvalSee;
  const [approvalRequests, setApprovalRequests] = useImmer([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [olderThanId, setOlderThanId] = useState(false);
  const [newerThanId, setNewerThanId] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentSort, setCurrentSort] = useState({
    sortColumn: 'approval_count',
    sortDirection: 'desc'
  });
  const [sortName, setSortName] = useState({
    sortColumn: 'name',
    sortDirection: 'asc'
  });
  const [sortApprovalCount, setSortApprovalCount] = useState({
    sortColumn: 'approval_count',
    sortDirection: 'desc'
  });
  const filter = useFilter((state) => state.filterById?.othersApprovalFilter);

  const isFirstRunName = useRef(true);
  const isFirstRunApprovalCount = useRef(true);

  const tableHeaders = [
    {
      name: 'Approver Name',
      widthClass: 'w-[480px]',
      sort: {
        sortColumn: sortName?.sortColumn,
        sortDirection: sortName?.sortDirection,
        onSort: (direction) => {
          setSortName({
            sortColumn: sortName?.sortColumn,
            sortDirection: direction
          });
        },
        default: 'asc'
      }
    },
    {
      name: 'Approval Request',
      widthClass: 'w-[200px]',
      sort: {
        sortColumn: sortApprovalCount?.sortColumn,
        sortDirection: sortApprovalCount?.direction,
        onSort: (direction) => {
          setSortApprovalCount({
            sortColumn: sortApprovalCount?.sortColumn,
            sortDirection: direction
          });
        },
        default: 'desc'
      }
    },
    { name: '', widthClass: 'w-[200px]' }
  ];

  const getOthersApproval = async (type = null) => {
    setIsLoading(true);
    const { q, ...allFilter } = filter || {};
    const limit = 10;

    let query = {
      limit: limit,
      q: q,
      info: allFilter,
      ...currentSort
    };
    if (type == 'next') {
      query.olderThan = olderThanId;
    } else if (type == 'prev') {
      query.newerThan = newerThanId;
    }
    const { data, pagination } = await getOtherApproval(query);

    if (data) {
      setApprovalRequests((draft) => data);
      setRequestIsEmpty(data.length === 0);
      setOlderThanId(pagination?.next?.olderThan);
      setNewerThanId(pagination?.prev?.newerThan);
      if (type == 'prev') {
        setHasMore(true);
      } else {
        setHasMore(data.length === limit);
      }
    }
    if (pagination) {
      setOlderThanId(pagination.next.olderThan);
    }
    setIsLoading(false);
  };

  const _getListFilter = (addedParams) => {
    const fetchFilter = () => {
      return getListFilter(addedParams);
    };

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['filter', addedParams],
        ({ pageParam }) => fetchFilter(pageParam),
        {
          getNextPageParam: (lastPage, allPages) =>
            lastPage.pagination?.next || 0
        }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  const getFilterData = async (addedParams) => {
    return await getListFilter({ q: addedParams?.q, key: addedParams?.key });
  };

  const debounceFn = useCallback(debounce(getOthersApproval, 500), [filter]);

  useEffect(() => {
    if (isUsingApprovalApprove) {
      debounceFn();
      setCurrentPage(1);
    }
    return debounceFn.cancel;
  }, [filter]);

  useEffect(() => {
    if (isFirstRunName.current) {
      isFirstRunName.current = false;
    } else {
      setCurrentSort(sortName);
    }
  }, [sortName]);

  useEffect(() => {
    if (isFirstRunApprovalCount.current) {
      isFirstRunApprovalCount.current = false;
    } else {
      setCurrentSort(sortApprovalCount);
    }
  }, [sortApprovalCount]);

  useEffect(() => {
    getOthersApproval();
    setCurrentPage(1);
  }, [currentSort]);

  let isThereFilter =
    (filter &&
      Object.keys(
        Object.fromEntries(
          Object.entries(filter)
            .filter(([key, value]) => key !== 'role')
            .filter(([key, value]) => !isEmpty(value))
        )
      )) ||
    [];

  return (
    <div className="viewport-wrapper container-input">
      <AdvancedFilter
        filterOptions={['search', 'menuFilter']}
        filterMenu={config?.generalFilterOptions}
        defaultFilter={{ q: '', role: [] }}
        filterClient={getFilterData}
        dialogQueryFn={(params) => _getListFilter(params)}
        customClass="mt-[24px] mb-[24px]"
        id="othersApprovalFilter"
      />
      {approvalRequests?.length === 0 ? (
        <ListEmptyState
          fullHeight
          containerClassname="my-[40px]"
          emptyIcon={
            !isEmpty(isThereFilter)
              ? 'icon-no-result-found'
              : 'icon-no-objective'
          }
          title={getObjectiveLocale(
            !isEmpty(isThereFilter)
              ? 'No result found'
              : 'No activities to be responded'
          )}
          subtitle={getObjectiveLocale(
            !isEmpty(isThereFilter)
              ? 'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
              : 'Once any activities need your response, you’ll see them here.'
          )}
        />
      ) : (
        <>
          <Table headers={tableHeaders} fixedLeftCount={1}>
            {isLoading ? (
              <LoadingComponent className="py-[4px]" />
            ) : (
              approvalRequests?.map((request, index) => {
                const {
                  id,
                  name,
                  role,
                  jobTitle,
                  placementId,
                  approvalCount,
                  profilePicture
                } = request || {};
                return (
                  <Table.Row key={`table-row-${index}`}>
                    <Table.Column key="table-column-0">
                      <ProfileType
                        user={{
                          name: name,
                          profilePicture: profilePicture,
                          role: role
                        }}
                        title={name}
                        subtitle={jobTitle}
                        size={32}
                        titleClassName="typography-h400"
                        subtitleClassName="typography-h100"
                      />
                    </Table.Column>
                    <Table.Column key="table-column-1">
                      <p className="text-n-900">{approvalCount}</p>
                    </Table.Column>
                    <Table.Column key="table-column-2">
                      <Button.Tertiary
                        onClick={() =>
                          history.push(
                            `/approval/others/${id}?name=${name}&placement=${placementId}`
                          )
                        }
                        datacy="view-approval"
                      >
                        {getObjectiveLocale('View Approval')}
                      </Button.Tertiary>
                    </Table.Column>
                  </Table.Row>
                );
              })
            )}
          </Table>
          <div className="flex justify-end w-full mt-[12px] mb-[40px]">
            <PaginationModule
              pageNumber={currentPage}
              handleNext={() => {
                getOthersApproval('next');
                setCurrentPage(currentPage + 1);
              }}
              handlePrev={() => {
                getOthersApproval('prev');
                setCurrentPage(currentPage - 1);
              }}
              hasMore={hasMore}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default OthersApproval;

import React, { useEffect, useRef, useState } from 'react';

import classSetter from 'classnames';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import {
  getAvaragePostsCMSCulture,
  getUserInfosCMSCulture
} from 'client/adminClient.js';

import Loader from 'components/shared/LoadingComponent';
import BarChart from 'components/shared/charts/BarChart';

import { camelCaseToSpace } from '../../../utils/caseConverter';
import { InsightCalendar, paramsHelperHandleChartView } from './InsightHelpers';
import {
  _dailyHandleChartView,
  _monthlyHandleChartView,
  _weeklyHandleChartView
} from './InsightHelpers';
import { CardChartWrapper, ItemCompared } from './InsigthRetentionComponent';

const CurrentChartAdoption = (props) => {
  const [chartAdoption, setChartAdoption] = useState({});
  const [selectedDate, setSelectedDate] = useState([
    dayjs().startOf('week'),
    dayjs().endOf('week')
  ]);

  const [isSecondDatePickerShown, setIsSecondDatePickerShown] = useState(false);
  const [intervalButton, setIntervalButton] = useState(
    paramsHelperHandleChartView(true, false, false, 'day')
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isNumber, setIsNumber] = useState(true);
  const [currentData, setCurrentData] = useState([]);
  const [isItemComparedDropdownShown, setIsItemComparedDropdownShown] =
    useState(false);
  const [currentChartCategories, setCurrentChartCategories] = useState([]);
  const [userInfoState, setUserInfoState] = useState({});
  const [isViewFilterDropdownShown, setisViewFilterDropdownShown] =
    useState(false);
  const [arrayOfFilter, setArrayOfFilter] = useState([]);
  const [viewFilterState, setViewFilterState] = useState('All');
  const [hideAllUsersSeries, setHideAllUsersSeries] = useState(false);
  const [userInfos, setUserInfos] = useState([]);
  const [localeUserActivity, setLocaleUserActivity] = useState({});
  const [analyticDataLengths, setAnalyticDataLengths] = useState([]);

  const seriesGenerator = (data, isNumber, name) => {
    const series = [];
    var categories = [];

    Object.keys(data).map((name) => {
      series.push({
        name: name,
        data: []
      });
    });

    Object.keys(series).map((seriesIdx) => {
      categories = [];
      let seriesName = series[seriesIdx].name;

      Object.keys(data[seriesName]).map((key, index) => {
        var yValue = data[seriesName][key].toFixed(2) / 1;
        series[seriesIdx].data.push(yValue);

        categories.push(camelCaseToSpace(key));
      });
    });

    series.map((v, i) => (series[i].name = camelCaseToSpace(series[i].name)));

    return { series, categories };
  };

  const objectLength = (object) => {
    let length = 0;
    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        ++length;
      }
    }
    return length;
  };

  const handleCancel = () => {
    setIsSecondDatePickerShown(false);
    setIsItemComparedDropdownShown(false);
    setisViewFilterDropdownShown(false);
  };

  const handleQueryGrowth = (range) => {
    setSelectedDate(range);
    const periodBegin = selectedDate[0];
    const periodEnd = selectedDate[1];
    const timeUnit = intervalButton.growth.selected;

    setIsLoading(true);
    getAdoptionData(periodBegin, periodEnd, timeUnit, isNumber, arrayOfFilter);
  };

  const handleChartView = (interval, e) => {
    e.preventDefault();

    const periodBegin = selectedDate[0];
    const periodEnd = selectedDate[1];

    switch (interval) {
      case 'daily':
        _dailyHandleChartView(
          periodBegin,
          periodEnd,
          isNumber,
          arrayOfFilter,

          getAdoptionData,
          setIntervalButton,
          paramsHelperHandleChartView
        );
        break;
      case 'weekly':
        _weeklyHandleChartView(
          periodBegin,
          periodEnd,
          isNumber,
          arrayOfFilter,

          getAdoptionData,
          setIntervalButton,
          paramsHelperHandleChartView
        );
        break;
      case 'monthly':
        _monthlyHandleChartView(
          periodBegin,
          periodEnd,
          isNumber,
          arrayOfFilter,

          getAdoptionData,
          setIntervalButton,
          paramsHelperHandleChartView
        );
        break;
      default:
    }
  };

  const handleShowItemComparedDrowdown = (param, e) => {
    e.preventDefault();
    if (param === 'growth') {
      setIsItemComparedDropdownShown(true);
    }
  };

  const confirmAddMetric = () => {
    let tempObj = {};

    Object.keys(userInfoState).map(function (item, idx) {
      let sel = document.getElementById(item);
      let key = item;

      if (sel.value !== 'Choose a value') {
        tempObj[key] = sel.value;
      }
    });

    arrayOfFilter.push(tempObj);

    setArrayOfFilter(arrayOfFilter);
    setIsItemComparedDropdownShown(false);
    setIsLoading(true);

    getAdoptionData(
      selectedDate[0],
      selectedDate[1],
      'day',
      isNumber,
      arrayOfFilter
    );
  };

  const removeFilter = (index, textLabel) => {
    if (textLabel === 'allUsers') {
      funcHideAllUsersSeries();
    } else {
      let selectedIndex = hideAllUsersSeries ? index : index - 1;
      arrayOfFilter.splice(selectedIndex, 1);

      setArrayOfFilter(arrayOfFilter);
      setIsLoading(true);

      getAdoptionData(
        selectedDate[0],
        selectedDate[1],
        'day',
        isNumber,
        arrayOfFilter
      );
    }
  };

  const showViewFilterDropdown = () => {
    setisViewFilterDropdownShown(!isViewFilterDropdownShown);
  };

  const funcHideAllUsersSeries = () => {
    currentChartCategories.shift();

    let chartScope = chart.current.getChart();
    chartScope.series[0].hide();

    setCurrentChartCategories(currentChartCategories);
    setHideAllUsersSeries(true);
  };

  const handleSelectView = async (itemFilter) => {
    const { data } = await getUserInfosCMSCulture(itemFilter);
    let arrayOfFilter = [];
    let tempObj = {};
    let userInfoData = data;
    for (let i = 0; i < userInfoData.length; i++) {
      tempObj[itemFilter] = userInfoData[i];
      arrayOfFilter.push(tempObj);
      tempObj = {};
    }

    setArrayOfFilter(arrayOfFilter);
    setIsLoading(true);
    setisViewFilterDropdownShown(false);
    setViewFilterState(itemFilter);
    setHideAllUsersSeries(false);

    getAdoptionData(
      selectedDate[0],
      selectedDate[1],
      'day',
      isNumber,
      arrayOfFilter
    );
  };

  const changeItemLabelFormat = (item) => {
    let itemLabel = item.replace('_', ' ');
    itemLabel = itemLabel.charAt(0).toUpperCase() + itemLabel.slice(1);

    if (itemLabel !== 'All') {
      let lastChar = itemLabel[itemLabel.length - 1];

      if (lastChar !== 'y') {
        itemLabel += 's';
      } else {
        itemLabel = itemLabel.slice(0, -1);
        itemLabel += 'ies';
      }
    }

    itemLabel = itemLabel === 'All' ? itemLabel : 'All ' + itemLabel;

    return itemLabel;
  };

  const getUserInfos = async () => {
    const { data } = await getUserInfosCMSCulture('');
    if (data) {
      let dataCurr = data;
      dataCurr.unshift('All');
      setUserInfos(dataCurr);
      let userInfoData = data;

      for (let i = 0; i < userInfoData.length; i++) {
        let keyData = userInfoData[i];
        getUserInfosKey(keyData, userInfoData);
      }
    }
  };

  const getUserInfosKey = async (keyData, userInfoData) => {
    const { data } = await getUserInfosCMSCulture(keyData);
    let userInfoStateCurr = userInfoState;
    let userInfoDataCurr = data;
    userInfoDataCurr?.unshift('Choose a value');
    userInfoStateCurr[keyData] = userInfoDataCurr;

    setUserInfoState(userInfoStateCurr);
  };

  const _getAdoptionParamHelper = (
    periodBegin,
    periodEnd,
    customFilter,
    timeUnit
  ) => ({
    periodBegin: dayjs(periodBegin).startOf('day').toISOString(true),
    periodEnd: dayjs(periodEnd).endOf('day').toISOString(true),
    customFilter,
    timeUnit
  });

  const getAdoptionData = async (
    periodBegin,
    periodEnd,
    timeUnit,
    isNumber,
    customFilter
  ) => {
    const params = _getAdoptionParamHelper(
      periodBegin,
      periodEnd,
      customFilter,
      timeUnit
    );

    const { data } = await getAvaragePostsCMSCulture(
      params.periodBegin,
      params.periodEnd,
      params.timeUnit,
      params.customFilter
    );
    if (data) {
      const analyticData = data.result;
      const analyticDataLength = objectLength(analyticData.attachment);
      const series = seriesGenerator(analyticData, isNumber);
      let currentData = [];
      currentData.push(analyticData[analyticData.length - 1]);
      setCurrentChartCategories(series.categories);
      setCurrentData(currentData);
      setChartAdoption(series);
      setAnalyticDataLengths(analyticDataLength);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfos();
  }, []);

  useEffect(() => {
    getAdoptionData(
      selectedDate[0],
      selectedDate[1],
      intervalButton.growth.selected,
      isNumber,
      arrayOfFilter
    );
  }, [isNumber, selectedDate]);

  const localeUserActivityDay = 'DAU';
  const localeUserActivityWeek = 'WAU';
  const localeUserActivityMonth = 'MAU';

  let currViewFilterState = Object.assign({ All: [] }, userInfoState);
  let selectedViewLabel = changeItemLabelFormat(viewFilterState);

  if (Object.keys(localeUserActivity).length !== 0) {
    localeUserActivityDay = localeUserActivity.properties.day;
    localeUserActivityWeek = localeUserActivity.properties.week;
    localeUserActivityMonth = localeUserActivity.properties.month;
  }

  const chart = useRef();
  return (
    <span>
      {(isItemComparedDropdownShown || isViewFilterDropdownShown) && (
        <div className="datepicker-overlay" onClick={handleCancel}></div>
      )}
      <CardChartWrapper title="Features Usage">
        <div className="view-filter">
          <div className="view-label">View by</div>
          <div
            className="item-filter-option view-option "
            onClick={showViewFilterDropdown}
          >
            {selectedViewLabel}
          </div>

          {isViewFilterDropdownShown && (
            <div className="list-view-option-wrapper">
              {Object.keys(currViewFilterState).map(function (item, idx) {
                let itemLabel = changeItemLabelFormat(item);
                let activeClass = viewFilterState === item ? true : false;

                return (
                  <div
                    key={idx}
                    className={classSetter('view-option-list', {
                      active: activeClass
                    })}
                    onClick={() => handleSelectView(item)}
                  >
                    {itemLabel}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="filter-compare-chart">
          {currentChartCategories.map((item, idx) => (
            <ItemCompared
              key={idx}
              text={startCase(item)}
              addOnClick={() => removeFilter(idx, item.replace('_', ' '))}
            />
          ))}

          <div className="item-compared-dropdown">
            <div
              className="item-compared-add item-compared-name"
              onClick={(e) => handleShowItemComparedDrowdown('growth', e)}
            >
              Add to Compare
            </div>

            {isItemComparedDropdownShown && (
              <div className="item-dropdown-content">
                {Object.keys(userInfoState).map(function (item, idx) {
                  let itemInside = userInfoState[item].map(function (
                    itemInside,
                    idx
                  ) {
                    return (
                      <option key={idx} value={itemInside}>
                        {itemInside}
                      </option>
                    );
                  });
                  let itemLabel;
                  itemLabel = item.replace('_', ' ');
                  itemLabel =
                    itemLabel.charAt(0).toUpperCase() + itemLabel.slice(1);

                  return (
                    <div key={idx}>
                      <div className="item-filter">{itemLabel}</div>
                      <select id={item} className="item-filter-option">
                        {itemInside}
                      </select>
                    </div>
                  );
                })}
                <div className="btn-confirm-filter" onClick={confirmAddMetric}>
                  <div className="item-compared-add item-compared-name">
                    Add Metric
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between mb-[24px]">
          <div className="container-filter-period flex flex-col">
            <div className="btn-group mb-[24px]">
              <div
                className={
                  'btn btn-chartView ' +
                  (intervalButton.growth.day ? 'is-active' : '')
                }
                onClick={(e) => handleChartView('daily', e)}
              >
                Day
              </div>
              <div
                className={
                  'btn btn-chartView ' +
                  (intervalButton.growth.week ? 'is-active' : '')
                }
                onClick={(e) => handleChartView('weekly', e)}
              >
                Week
              </div>
              <div
                className={
                  'btn btn-chartView ' +
                  (intervalButton.growth.month ? 'is-active' : '')
                }
                onClick={(e) => handleChartView('monthly', e)}
              >
                Month
              </div>
            </div>
          </div>
          <InsightCalendar
            endDate={dayjs(selectedDate[1]).format('D MMM YYYY').toString()}
            startDate={dayjs(selectedDate[0]).format('D MMM YYYY').toString()}
            selectedDate={selectedDate}
            handleSelectDate={(range) => handleQueryGrowth(range)}
            id="usage"
            listOptionsTime={props.listOptionsTime}
          />
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <BarChart
              data={chartAdoption}
              isNumber={isNumber}
              analyticDataLengths={analyticDataLengths}
            />
          </div>
        )}
      </CardChartWrapper>
    </span>
  );
};

export default CurrentChartAdoption;

import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';

import startCase from 'lodash/startCase';

import { getObjectiveDetail } from 'client/ObjectivesClient';

// CREATE CONTEXT
const ProjectDetailContext = createContext();

// PROVIDER
const ProjectDetailProvider = (props) => {
  const parentId = props.parentId || null;

  const fetchObjective = () => {
    return getObjectiveDetail(parentId);
  };

  const { data } = useQuery(['objective', +parentId], fetchObjective, {
    enabled: parentId !== null
  });

  return (
    <ProjectDetailContext.Provider value={{ data: data?.data }} {...props} />
  );
};

// MUTATION
const useProjectDetail = () => {
  const { data } = useContext(ProjectDetailContext);

  let breadcrumbsData = [];
  const setBreadCrumbsName = (data) =>
    `[${startCase(data?.teamType?.[0])}] ${data?.name}`;
  const setBreadCrumbsLink = (linkData) =>
    linkData?.teamType == data?.teamType
      ? null
      : `/teams/${linkData?.teamType}/${linkData?.id}`;

  const setBreadcrumbsTeam = (bcData = null) => {
    const processedData = bcData || data?.teamBreadcrumbs;
    if (processedData) {
      breadcrumbsData.unshift({
        title: setBreadCrumbsName(processedData),
        link: setBreadCrumbsLink(processedData)
      });

      if (processedData?.parent) setBreadcrumbsTeam(processedData?.parent);
    }
  };

  setBreadcrumbsTeam();

  return {
    data: data,
    projectId: data?.id || null,
    projectName: data?.name,
    involvements: data?.involvements,
    startDate: data?.startDate,
    dueDate: data?.dueDate,
    breadcrumbs: breadcrumbsData,
    team: data?.team || null,
    permissions: data?.permissions
  };
};

export { ProjectDetailProvider, useProjectDetail };

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { generatePeerFeedback } from 'client/FormalReviewClient';
import { ColleagueProvider } from 'context/ColleagueContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import GeneratePeerFeedbackModal from 'components/modal/GeneratePeerFeedbackModal';
import Objectives from 'components/objectives/Objectives';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

const FILTER_ID = 'filter-direct-report';

function Subordinate({ route: { title } }) {
  const history = useHistory();
  const { match } = useUrl();
  const params = useParams();

  const switchOptions = [
    {
      id: 'direct',
      name: getObjectiveLocale('Direct'),
      filter: { group: 'direct-report' },
      onClick: () => history.replace('/goals/reports/direct')
    },
    {
      id: 'indirect',
      name: getObjectiveLocale('Indirect'),
      filter: { group: 'indirect-report' },
      onClick: () => history.replace('/goals/reports/indirect')
    }
  ];

  const defaultTab =
    switchOptions?.find((tab) => tab?.id === params.tab)?.id ||
    switchOptions[0]?.id;

  const [activeTab, changeTab] = useState(defaultTab);
  const [directSwitch, setDirectSwitch] = useState('');
  const [showModal, setShowModal] = useState(false);

  const optionActive = switchOptions.find((option) => option.id === activeTab);
  const extraFilter = optionActive?.filter || {};

  const filter = {
    phase: ['running', 'completed'],
    state: 'active'
  };

  const onHandleSubmitModal = async (selectedUsers) => {
    const body = {
      actorIds: selectedUsers.map((user) => user.id)
    };

    const { data } = await generatePeerFeedback(body);
    if (data) {
      setShowModal(false);
    }
  };

  const headerProps = {
    titlePage: title,
    secondaryAction: {
      title: getObjectiveLocale('View Tree'),
      icon: 'icon-device_hub',
      onClick: () => history.replace(`/subordinates/${activeTab}/tree`),
      dataCy: 'view-tree'
    },
    tabs: {
      tabsList: switchOptions,
      url: '/goals/reports/direct',
      defaultIndex: 0,
      onChange: (tab) => changeTab(tab)
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const placementType = urlParams.get('placement');
    setDirectSwitch(placementType ? placementType : 'current');
  }, []);

  return (
    <>
      <HeaderPage {...headerProps} />
      {directSwitch && (
        <div className="pb-[40px] bg-n-100 mt-[24px] min-h-[calc(100vh-64px)]">
          <AdvancedFilterObjectives
            filterOptions={[
              'search',
              'position',
              'sort',
              'dateFilter',
              'menuFilter'
            ]}
            defaultFilter={filter}
            group={optionActive?.filter?.group}
            page={`directReport+${activeTab}`}
            customClass="mb-[24px]"
            filterId={FILTER_ID}
          >
            <Objectives
              cardModel="goal-list"
              page={`directReport+${activeTab}`}
              extraFilter={extraFilter}
              group={optionActive?.filter?.group}
            />
          </AdvancedFilterObjectives>
        </div>
      )}

      {showModal && (
        <ColleagueProvider>
          <GeneratePeerFeedbackModal
            onHandleCloseModal={() => setShowModal(false)}
            onHandleSubmitModal={onHandleSubmitModal}
          />
        </ColleagueProvider>
      )}

      <React.Suspense fallback={<div></div>}>
        {match && <SidebarMyGoals />}
      </React.Suspense>
    </>
  );
}

export default Subordinate;

import React, { useEffect, useRef, useState } from 'react';

import classSetter from 'classnames';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import orderBy from 'lodash/orderBy';
import Papa from 'papaparse';

import {
  getGroupPostCountTotalCMSCulture,
  getUserInfosCMSCulture
} from 'client/adminClient.js';
import Config from 'utils/Config';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Button from 'components/design-system/Button';
import Checkbox from 'components/shared/Checkbox';
import Loader from 'components/shared/LoadingComponent';

import './GroupPopulation.scss';

const GroupPopulation = ({ route: { title } }) => {
  const [chartAdoption, setChartAdoption] = useState({});

  const [selectedDate, setSelectedDate] = useState([
    dayjs().startOf('week'),
    dayjs().endOf('week')
  ]);

  const [intervalButton, setIntervalButton] = useState({
    growth: {
      day: true,
      week: false,
      month: false,
      selected: 'day'
    }
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isNumber, setIsNumber] = useState(true);

  // user info key
  const [userInfoState, setUserInfoState] = useState({});
  const [userInfos, setUserInfos] = useState([]);
  const [isViewFilterDropdownShown, setisViewFilterDropdownShown] =
    useState(false);

  // add to compare
  const [currentChartCategories, setCurrentChartCategories] = useState([]);
  const [arrayOfFilter, setArrayOfFilter] = useState([]);
  const [isItemComparedDropdownShown, setIsItemComparedDropdownShown] =
    useState(false);

  const [hideAllUsersSeries, setHideAllUsersSeries] = useState(false);

  //table state
  const [analyticData, setAnalyticData] = useState([]);

  const [currentColumnName, setCurrentColumnName] = useState('');
  const [isSortByDropdownShown, setIsSortByDropdownShown] = useState(false);
  const [sortType, setSortType] = useState('');

  const seriesGenerator = (data, isNumber, name) => {
    const series = [];
    let categories = [];

    Object.keys(data[0].counts).map((name) => {
      series.push({
        name: name.replace('_', ' '),
        data: []
        // key: key,
        // keyName: key,
      });
    });

    Object.keys(series).map((seriesIdx) => {
      categories = [];
      let seriesName = series[seriesIdx].name;

      for (let i = 0; i < data.length; i++) {
        Object.keys(data[i].counts).map((key, index) => {
          if (seriesName === key) {
            let yValue = isNumber
              ? data[i].counts[key].toFixed(2) / 1
              : data[i].percentage[key].toFixed(2) / 1;
            series[seriesIdx].data.push(yValue);
            categories.push(data[i].group.replace('_', ' '));
          }
        });
      }
    });

    return {
      series,
      categories
    };
  };

  const handleCancel = () => {
    setIsItemComparedDropdownShown(false);
    setisViewFilterDropdownShown(false);
    setIsSortByDropdownShown(false);
  };

  const handleSelectType = (e) => {
    const periodBegin = selectedDate[0];
    const periodEnd = selectedDate[1];
    const arrayOfFilter = arrayOfFilter;

    setIsNumber(!isNumber);
    setIsLoading(true);

    getAdoptionData(
      periodBegin,
      periodEnd,
      intervalButton.growth.selected,
      isNumber,
      arrayOfFilter
    );
  };

  const handleShowItemComparedDrowdown = (param, e) => {
    e.preventDefault();
    if (param === 'growth') {
      setIsItemComparedDropdownShown(true);
    }
  };

  const removeFilter = (index, textLabel) => {
    if (textLabel === 'All users') {
      funcHideAllUsersSeries();
    } else {
      let timeUnit = intervalButton.growth.selected;
      let selectedIndex = hideAllUsersSeries ? index : index - 1;
      let arrayOfFilter = arrayOfFilter;
      arrayOfFilter.splice(selectedIndex, 1);

      setArrayOfFilter(arrayOfFilter);
      setIsLoading(true);

      getAdoptionData(
        selectedDate[0],
        selectedDate[1],
        timeUnit,
        isNumber,
        arrayOfFilter
      );
    }
  };

  const funcHideAllUsersSeries = () => {
    currentChartCategories.shift();

    let chart = refs.chart.getChart();
    chart.series[0].hide();

    setCurrentChartCategories(currentChartCategories);
    setHideAllUsersSeries(true);
  };

  const sortByType = (sortType, columnName) => {
    let stringSort = '';

    if (columnName === 'group' || columnName === 'type') {
      stringSort += columnName;
    } else {
      stringSort += 'counts.' + columnName;
    }

    const newData = orderBy(analyticData, [stringSort], [sortType]);

    setAnalyticData(newData);
    setCurrentColumnName(columnName);
    setSortType(sortType);
  };

  const confirmAddMetric = () => {
    let timeUnit = intervalButton.growth.selected;
    let arrayOfFilter = arrayOfFilter;
    let tempObj = {};

    Object.keys(userInfoState).map(function (item, idx) {
      let sel = document.getElementById(item);
      let key = item;

      if (sel.value !== 'Choose a value') {
        tempObj[key] = sel.value;
      }
    });

    arrayOfFilter.push(tempObj);

    setArrayOfFilter(arrayOfFilter);
    setIsItemComparedDropdownShown(false);
    setIsLoading(true);

    getAdoptionData(
      selectedDate[0],
      selectedDate[1],
      timeUnit,
      isNumber,
      arrayOfFilter
    );
  };

  const handleDownloadCSV = () => {
    let CSVDataFormat = [];
    let CSVDataPapa = [];

    analyticData.map(function (group, idx) {
      CSVDataFormat = [];
      CSVDataFormat.push(idx + 1);
      CSVDataFormat.push(group.group);
      CSVDataFormat.push(group.type.replace('_', ' '));

      CSVDataFormat.push(group.counts.thought);
      CSVDataFormat.push(group.counts.media);
      CSVDataFormat.push(group.counts.event);
      CSVDataFormat.push(group.counts.feeling);
      CSVDataFormat.push(group.counts.recognition);
      CSVDataFormat.push(group.counts.poll);
      CSVDataFormat.push(group.counts.attachment);
      CSVDataFormat.push(group.counts.link);

      CSVDataPapa.push(CSVDataFormat);
    });

    let CSVData = Papa.unparse({
      fields: [
        'No.',
        'Group Name',
        'Type',
        'Thought',
        'Media',
        'Event',
        'Feeling',
        'Recognition',
        'Polling',
        'Attachment',
        'Link'
      ],
      data: CSVDataPapa
    });

    let fileName = 'Group_Population_Feature_Usage.csv';
    let file;

    if (!CSVData.match(/^data:text\/csv/i)) {
      file = `data:text/csv;charset=utf-8,${CSVData}`;
    }

    const data = encodeURI(file);
    const link = document.createElement('a');

    link.setAttribute('href', data);
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 1500);
  };

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const scrollFunction = () => {
    let buttonToTop = document.getElementById('btn-to-top');
    if (buttonToTop) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        buttonToTop.style.display = 'block';
      } else {
        buttonToTop.style.display = 'none';
      }
    }
  };

  const getUserInfos = async () => {
    const { data } = await getUserInfosCMSCulture('');
    let userInfoData = data;

    for (let i = 0; i < userInfoData.length; i++) {
      let keyData = userInfoData[i];
      getUserInfosKey(keyData, userInfoData);
    }
  };

  const getUserInfosKey = async (keyData, userInfoData) => {
    const { data } = await getUserInfosCMSCulture(keyData);
    let userInfoStateCurr = userInfoState;
    let userInfoDataCurr = data;
    userInfoDataCurr.unshift('Choose a value');
    userInfoStateCurr[keyData] = userInfoDataCurr;

    setUserInfoState(userInfoStateCurr);
  };

  const getAdoptionData = async (
    periodBegin,
    periodEnd,
    timeUnit,
    isNumber,
    customFilter
  ) => {
    const params = {
      periodBegin: dayjs('20111031', 'YYYYMMDD') // eight yrs ago, needed cus backend requires params to be sent
        .startOf('day')
        .toISOString(true),
      periodEnd: dayjs(new Date()).endOf('day').toISOString(true),
      timeUnit,
      customFilter
    };

    const { data } = await getGroupPostCountTotalCMSCulture(params);
    const analyticData = data.result;
    const analyticDataLength = data.length;
    const series = seriesGenerator(analyticData, isNumber);

    setCurrentChartCategories(series.categories);
    setAnalyticData(analyticData);
    setChartAdoption({
      chart: {
        style: {
          fontFamily: 'Inter'
        },
        backgroundColor: 'var(--n-200)',
        type: 'bar',
        height: analyticDataLength * 60,
        marginTop: 50,
        marginBottom: 100
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        floating: true,
        x: 0,
        y: 0
      },
      title: {
        text: 'Growth'
      },
      xAxis: {
        categories: series.categories,
        labels: {
          style: {
            width: '100px',
            minWidth: '100px',
            left: '125px'
          },
          useHTML: true
        }
      },
      yAxis: {
        title: {
          text: ''
        }
      },
      tooltip: {
        pointFormatter: function () {
          var point = this;
          return (
            '<span style="color:' +
            point.color +
            '">\u25CF</span> ' +
            point.series.name +
            ': <b>' +
            point.y +
            ' ' +
            (isNumber ? 'users' : '%') +
            '</b><br/>'
          );
        },
        shared: true
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          // width: 150,
          pointWidth: 30,
          groupPadding: 0
        },
        bar: {
          dataLabels: {
            enabled: true,
            align: 'right',
            formatter: function () {
              var point = this;
              return isNumber ? point.y : point.y + '%';
            }
          }
        }
      },
      series: series.series,
      exporting: {
        buttons: {
          contextButton: {
            symbol: Config.asset.general.iconDownload
          }
        }
      }
    });
    setIsLoading(false);
    if (hideAllUsersSeries) {
      funcHideAllUsersSeries();
    }
  };

  // let viewFilterState = Object.assign({All: []}, userInfoState);
  // let selectedViewLabel = changeItemLabelFormat(viewFilterState);
  window.onscroll = function () {
    scrollFunction();
  };

  useEffect(() => {
    getUserInfos();
    scrollFunction();
  }, []);

  useEffect(() => {
    getAdoptionData(selectedDate[0], selectedDate[1], 'day', isNumber);
  }, [isNumber]);

  const chart = useRef();

  const headerProps = {
    titlePage: title,
    primaryAction: {
      title: 'Download CSV',
      icon: 'icon-file_download',
      onClick: () => {
        handleDownloadCSV();
      }
    }
  };

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="admin-culture mt-[8px]">
        <div
          className="col-xs-12 group-population-container"
          id="dashboard_content"
        >
          {(isItemComparedDropdownShown || isSortByDropdownShown) && (
            <div
              className="datepicker-overlay"
              onClick={(e) => handleCancel(e)}
            ></div>
          )}
          <div className="fixed-content">
            <Button id="btn-to-top" onClick={topFunction}>
              {getObjectiveLocale('Back to top')}
              <i className="ml-[2px] fa fa-arrow-up" />
            </Button>
          </div>
          <div className="hr dashboard_content_main">
            <div className="row table-menu">
              <div className="search-container col-xs-4">
                <div className="info">
                  <span className="mb-[1px]">
                    <strong style={{ fontWeight: '600' }}>
                      Feature Usage Table
                    </strong>
                  </span>
                  {/* <span className="mb-[1px]">Last Update: <strong>{lastUpdate}</strong></span> */}
                  {/* <span>Average: <strong>{average.toFixed(2)}</strong></span> */}
                </div>
              </div>
            </div>
            <div className="scrollable-table">
              <table className="table-list-employee">
                <thead>
                  <tr>
                    <th>{getObjectiveLocale('No')}</th>
                    <th>
                      <DropdownSortTypeComponent
                        columnName="group"
                        columnLabel="Group Name"
                        hideDropdown={isSortByDropdownShown}
                        sortByType={sortByType}
                        currentColumnName={currentColumnName}
                        sortType={sortType}
                      />
                    </th>
                    <th>
                      <DropdownSortTypeComponent
                        columnName="type"
                        columnLabel="Type"
                        hideDropdown={isSortByDropdownShown}
                        sortByType={sortByType}
                        currentColumnName={currentColumnName}
                        sortType={sortType}
                      />
                    </th>
                    <th>
                      <DropdownSortTypeComponent
                        columnName="thought"
                        columnLabel="Thought"
                        hideDropdown={isSortByDropdownShown}
                        sortByType={sortByType}
                        currentColumnName={currentColumnName}
                        sortType={sortType}
                      />
                    </th>
                    <th>
                      <DropdownSortTypeComponent
                        columnName="media"
                        columnLabel="Media"
                        hideDropdown={isSortByDropdownShown}
                        sortByType={sortByType}
                        currentColumnName={currentColumnName}
                        sortType={sortType}
                      />
                    </th>
                    <th>
                      <DropdownSortTypeComponent
                        columnName="event"
                        columnLabel="Event"
                        hideDropdown={isSortByDropdownShown}
                        sortByType={sortByType}
                        currentColumnName={currentColumnName}
                        sortType={sortType}
                      />
                    </th>
                    <th>
                      <DropdownSortTypeComponent
                        columnName="feeling"
                        columnLabel="Feeling"
                        hideDropdown={isSortByDropdownShown}
                        sortByType={sortByType}
                        currentColumnName={currentColumnName}
                        sortType={sortType}
                      />
                    </th>
                    <th>
                      <DropdownSortTypeComponent
                        columnName="recognition"
                        columnLabel="Recognition"
                        hideDropdown={isSortByDropdownShown}
                        sortByType={sortByType}
                        currentColumnName={currentColumnName}
                        sortType={sortType}
                      />
                    </th>
                    <th>
                      <DropdownSortTypeComponent
                        columnName="polling"
                        columnLabel="Polling"
                        hideDropdown={isSortByDropdownShown}
                        sortByType={sortByType}
                        currentColumnName={currentColumnName}
                        sortType={sortType}
                      />
                    </th>
                    <th>
                      <DropdownSortTypeComponent
                        columnName="attachment"
                        columnLabel="Attachment"
                        hideDropdown={isSortByDropdownShown}
                        sortByType={sortByType}
                        currentColumnName={currentColumnName}
                        sortType={sortType}
                      />
                    </th>
                    <th>
                      <DropdownSortTypeComponent
                        columnName="link"
                        columnLabel="Link"
                        hideDropdown={isSortByDropdownShown}
                        sortByType={sortByType}
                        currentColumnName={currentColumnName}
                        sortType={sortType}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="11">
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    analyticData.map(function (group, idx) {
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{group.group}</td>
                          <td>{group.type.replace('_', ' ')}</td>
                          <td className="text-right">
                            {getNumberLocale(group.counts.thought)}
                          </td>
                          <td className="text-right">
                            {getNumberLocale(group.counts.media)}
                          </td>
                          <td className="text-right">
                            {getNumberLocale(group.counts.event)}
                          </td>
                          <td className="text-right">
                            {getNumberLocale(group.counts.feeling)}
                          </td>
                          <td className="text-right">
                            {getNumberLocale(group.counts.recognition)}
                          </td>
                          <td className="text-right">
                            {getNumberLocale(group.counts.poll)}
                          </td>
                          <td className="text-right">
                            {getNumberLocale(group.counts.attachment)}
                          </td>
                          <td className="text-right">
                            {getNumberLocale(group.counts.link)}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="dashboard-content-main">
            <div className="row">
              <div className="col-xs-12 pl-[0px] pl-[0px]">
                <div className="card-body-chart">
                  <h4 className="body-header">
                    {getObjectiveLocale('Features Usage')}{' '}
                    <i className="fa fa-question-circle-o"></i>
                  </h4>
                  <div className="row">
                    <div className="col-xs-6">
                      <div className="checkbox-wrapper flex gap-[16px]">
                        <Checkbox
                          customContainerClass="inline-block type"
                          id="number"
                          onChange={handleSelectType}
                          checked={isNumber}
                          value="number"
                        >
                          <p className="ml-[4px]">
                            {' '}
                            {getObjectiveLocale('Number')}
                          </p>
                        </Checkbox>
                        <Checkbox
                          customContainerClass="inline-block type"
                          id="percentage"
                          onChange={handleSelectType}
                          checked={!isNumber}
                          value="number"
                        >
                          <p className="ml-[4px]">
                            {' '}
                            {getObjectiveLocale('Percentage')}
                          </p>
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                </div>

                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="chart-box">
                    <HighchartsReact
                      highcharts={Highcharts}
                      ref={chart}
                      options={chartAdoption}
                    ></HighchartsReact>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DropdownSortTypeComponent = (props) => {
  const [isSortByDropdownShown, setIsSortByDropdownShown] = useState(false);
  const [columnName, setColumnName] = useState('');
  const [iconFilter, setIconFilter] = useState('fa-caret-down');

  const showSortByDropdown = (columnName) => {
    setColumnName(columnName);
    setIsSortByDropdownShown(true);
  };

  const handleCancel = () => {
    setIsSortByDropdownShown(!isSortByDropdownShown);
  };

  const _sortByType = (sortType) => {
    props.sortByType(sortType, columnName);
  };

  useEffect(() => {
    if (props.currentColumnName === props.columnName) {
      if (props.sortType === 'asc') {
        setIconFilter('fa-arrow-up red-icon');
      } else if (props.sortType === 'desc') {
        setIconFilter('fa-arrow-down red-icon');
      }
    }
  }, [props.currentColumnName, props.sortType]);

  return (
    <span>
      {isSortByDropdownShown && (
        <div className="datepicker-overlay" onClick={handleCancel}></div>
      )}

      <div
        className="clickable list-group"
        onClick={() => showSortByDropdown(props.columnName)}
      >
        {getObjectiveLocale(props.columnLabel)}
        <div className="sort-by-wrapper">
          <div className="sort-by-icon">
            <i className={'fa ' + iconFilter}></i>
          </div>

          {isSortByDropdownShown && (
            <div className="sort-by-option-wrapper">
              <div className="view-option-list-label">
                {getObjectiveLocale('ORDER')}
              </div>
              <div
                className={classSetter('view-option-list', { active: true })}
                onClick={() => _sortByType('asc')}
              >
                {getObjectiveLocale('Ascending')}
              </div>
              <div
                className={classSetter('view-option-list', { active: false })}
                onClick={() => _sortByType('desc')}
              >
                {getObjectiveLocale('Descending')}
              </div>
            </div>
          )}
        </div>
      </div>
    </span>
  );
};

export default GroupPopulation;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import './PerformanceProfileWeightConfig.scss';

const PerformanceProfileWeightConfig = ({
  isInputAble = true,
  dataList,
  handleEditReviewAspects,
  reviewAspectList
}) => {
  return (
    <div>
      <div className="mt-[24px] four-configuration-percentages-container">
        <div className="fcp-header flex !w-[320px]">
          <h5 className="typography-h300 mb-[10px] w-[240px]">
            {getObjectiveLocale('CONFIGURATION')}
          </h5>
          <h5 className="typography-h300 mb-[10px] ">
            {getObjectiveLocale('WEIGHT')}
          </h5>
        </div>
        <div className="performance-profile-percentage-container">
          {dataList.map((data) => {
            const initialData =
              reviewAspectList &&
              reviewAspectList.find((list) => list.id == data.id);
            return (
              <div className="pp-percentage-container mt-[20px]">
                <h5 className="w-[240px] typography-paragraph">{data.name}</h5>
                <input
                  type="number"
                  className={`pp-input-percentage`}
                  onChange={(e) => {
                    handleEditReviewAspects(
                      data.id,
                      parseFloat(e.target.value)
                    );
                  }}
                  disabled={!isInputAble}
                  defaultValue={initialData ? initialData.weight : data.weight}
                  min="0"
                  data-cy={`goal-percentage-${data.id}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PerformanceProfileWeightConfig;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
  getPeerSelections,
  submitPeerSelections
} from 'client/FormalReviewClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { getExtraInformation, getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import TwoLine from 'components/design-system/ProfileType';
import { ADMIN, B2B } from 'components/modal/ModalPeerSelection/const';
import SVGIcon from 'components/shared/SVGIcon';
import ModalPeerSelection from 'src/components/modal/ModalPeerSelection/ModalPeerSelection';

function getSelectedUsers(peerSelection) {
  return peerSelection.map((selection) => {
    let result = {
      id: selection.actor.id,
      name: selection.actor.name,
      image: selection.actor.profilePicture,
      jobTitle: selection.actor.jobTitle,
      title: selection.actor.title,
      subtitle: selection.actor.subtitle,
      isMandatory: selection.locked,
      selectorType: selection.selectorType,
      selectorId: selection.selectorId,
      targetIdIndicator: selection.target.id,
      source: selection.source
    };

    return result;
  });
}
function PeerSelection({
  assignment,
  cycleId,
  approvalCount,
  statePhase,
  minimumApprovalCount,
  allowLockedByManager,
  assignments,
  maximumPeerCountEnabled,
  maximumPeerSelectedEnabled,
  peerReviewData,
  nominationCount
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { refetchQueries } = useRefetchQuery();

  const { remove } = useQuery(
    ['peer_assignment_reviewer_list', cycleId, assignment.target.id],
    () => getPeerSelections(cycleId, assignment.target.id),
    {
      onSuccess: ({ data }) => {
        if (data) {
          setSelectedUsers(getSelectedUsers(data, assignment.target.id));
        }
      }
    }
  );

  const refetchAllAssignments = () => {
    assignments.map((assign) => {
      refetchQueries([
        'peer_assignment_reviewer_list',
        cycleId,
        assign.target.id
      ]);
      refetchQueries([
        'peer_assignment_reviewee_list',
        cycleId,
        assign.target.id
      ]);
    });
  };

  const onConfirmSelectUsers = async (selectedUsersValue) => {
    let actorIds = [];
    let lockedIds = [];
    let unlockedIds = [];
    const filterredSelected = selectedUsersValue.filter(
      (data) => data.selectorType != ADMIN && data.source != B2B
    );

    for (let i = 0; i < filterredSelected.length; i++) {
      actorIds = [...actorIds, filterredSelected[i].id];
      if (!allowLockedByManager) continue;
      const { isMandatory } =
        (selectedUsers?.length > 0 &&
          selectedUsers.find((user) => user.id == filterredSelected[i].id)) ||
        [];
      if (isMandatory == filterredSelected[i].isMandatory) continue;
      if (filterredSelected[i].isMandatory) {
        lockedIds = [...lockedIds, filterredSelected[i].id];
      } else {
        unlockedIds = [...unlockedIds, filterredSelected[i].id];
      }
    }
    const payload = {
      actorIds,
      lockedIds,
      unlockedIds
    };
    const { error } = await submitPeerSelections(
      cycleId,
      assignment.target.id,
      payload
    );
    if (!error) {
      setSelectedUsers(selectedUsersValue);
      setIsModalOpen(false);
      refetchAllAssignments();
    }
  };

  const onHandleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onCancelSelectUsers = () => {
    onHandleCloseModal();
  };

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  const getStatusComponent = (target) => {
    return !target?.isCurrentPlacement ? (
      <div className="absolute right-0 bottom-0 bg-n-000 rounded-full">
        <SVGIcon
          size={16}
          iconName="icon-settings_backup_restore"
          fillColor="var(--g-500)"
        />
      </div>
    ) : null;
  };

  return (
    <>
      <div
        data-cy={`peer-selection-${assignment.id}`}
        className={`peer-selection flex justify-between px-[16px] py-[8px] ${
          selectedUsers.length > 0 ? 'done' : ''
        }`}
        onClick={() => setIsModalOpen(true)}
      >
        <div className="flex relative items-center grow min-w-[240px] mr-[24px] overflow-hidden">
          <TwoLine
            user={assignment.target}
            title={assignment.target.name}
            subtitle={getExtraInformation(
              assignment?.target?.title,
              assignment?.target?.subtitle
            )}
            statusComponent={getStatusComponent(assignment.target)}
            size={32}
            customClass="items-center"
            titleClassName="typography-h400 text-n-900 leading-[24px]"
            subtitleClassName="typography-h100 text-n-800"
          />
        </div>
        <div className="flex items-center justify-end">
          {selectedUsers.length > 0 && (
            <div className="typography-h-100 text-n-800 mr-[24px]">
              {getObjectiveLocale('[num] peers have been selected').replace(
                '[num]',
                selectedUsers.length
              )}
            </div>
          )}
          <Button.Tertiary onClick={() => setIsModalOpen(true)}>
            {selectedUsers.length > 0 || statePhase === 'done'
              ? getObjectiveLocale('View Selection')
              : getObjectiveLocale('Select Peers')}
          </Button.Tertiary>
        </div>
      </div>
      {isModalOpen && (
        <div className="select-peers-formal-review">
          <ModalPeerSelection
            target={assignment.target}
            actor={assignment.actor}
            isSelf={assignment.actor.id == assignment.target.id}
            selectedUsers={selectedUsers}
            onCancelSelectUsers={onCancelSelectUsers}
            onConfirmSelectUsers={onConfirmSelectUsers}
            onHandleCloseModal={onHandleCloseModal}
            approvalCount={approvalCount}
            minimumApprovalCount={minimumApprovalCount}
            statePhase={statePhase}
            cycleId={cycleId}
            allowLockedByManager={allowLockedByManager}
            maximumPeerCountEnabled={maximumPeerCountEnabled}
            maximumPeerSelectedEnabled={maximumPeerSelectedEnabled}
            isPeerReviewDraft={
              peerReviewData ? peerReviewData?.state == 'draft' : true
            }
            nominationCount={nominationCount}
          />
        </div>
      )}
    </>
  );
}

export default PeerSelection;

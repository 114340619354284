import React from 'react';

import Checkbox from 'components/shared/Checkbox';

function CheckboxGroup({ options, name, answers, id, setAnswer }) {
  const onChange = () => {
    const x = document.getElementsByName(name);
    const option_ids = [];

    for (let i = 0; i < x.length; i++) {
      if (x[i].checked) {
        option_ids.push(parseInt(x[i].id));
      }
    }
    setAnswer(id, option_ids, null, 'option');
  };
  return (
    <div className="checkbox-group-container">
      {options.map((option, index) => {
        let answer = answers?.includes(option?.id) || false;
        return (
          <Checkbox
            key={index}
            checked={answer}
            name={name}
            value={option?.id}
            id={option?.id}
            onChange={onChange}
            customContainerClass="min-h-[24px] mb-[8px]"
          >
            <span className="ml-[8px]">{option?.option}</span>
          </Checkbox>
        );
      })}
    </div>
  );
}

export default CheckboxGroup;

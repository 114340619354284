import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

import ctl from '@netlify/classnames-template-literals';
import 'scss/admin/_sidebar';

import { getApprovalRequestsCount } from 'client/ApprovalClient';
import { getActivities, getNeedResponses } from 'client/ObjectivesClient';
import { getFeedbackCount, getSurveyCount } from 'client/adminClient';
import { useReload } from 'context/ReloadContext';
import { useSidebarMenu } from 'context/SidebarMenuContext';
import { useUser } from 'context/UserContext';
import { useGetNeedResponsesCount } from 'hooks/api/useObjectives';
import useClickOutside from 'hooks/useClickOutside';
import usePermission from 'hooks/usePermission';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { formatTimezone } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { linkToCreateObjectives } from 'utils/objectiveUtils';

import ModalCheckin from 'components/checkin/ModalCheckin';
import CustomInnerIcon from 'components/design-system/CustomInnerIcon';
import ModalChangePassword from 'components/modal/ModalChangePassword';
import ModalPrivateRecognition from 'components/modal/PrivateRecognition';
import ToastNotif from 'components/shared/ToastNotif';

import SidebarMaximized from './SidebarMaximized';
import SidebarMinimized from './SidebarMinimized';

function Sidebar() {
  const history = useHistory();
  const { user, config, appType } = useUser();
  const {
    reloadActivityNotifCount,
    reloadNeedResponseNotifCount,
    reloadApprovalNotifCount
  } = useReload();

  const [showCheckin, setShowCheckin] = useState(false);
  const [dropDownSearchOpen, onSearchCollagues] = useState(false);
  const [showFeedbackPopup, toggleFeedbackForm] = useState(false);
  const [showRecognitionPopup, toggleRecognitionForm] = useState(false);
  const [modalChangePassword, setModalChangePassword] = useState(false);
  const [isHaveNotif, setIsHaveNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');
  const [isSuccessPost, setShowNotif] = useState(false);
  const [warning, setWarning] = useState(false);
  const [isSwitchOpen, setIsSwitchOpen] = useState(false);
  const [approvalCount, setApprovalCount] = useState(0);
  const [surveyCount, setSurveyCount] = useState(0);
  const [needResponseCount, setNeedResponseCount] = useState(0);
  const [activitiesCount, setActivitiesCount] = useState(0);

  const { isMinimized, toggleSidebar, isUseToggle } = useSidebarMenu();
  const isAppCulture =
    appType === 'culture' || (!appType && user.cultureRole && !user.role);
  const isAbleToSwitch =
    !appType && user.cultureRole && user.role
      ? true
      : isAppCulture
      ? user.role
      : user.cultureRole;
  const isSearchCollagueVisible = usePermission('searchColleaguesSee');
  const isProfileVisible = usePermission('searchColleaguesSee');

  const { showModalValidationCreateGoal, checkValidationCreateGoal } =
    useValidationMinMax();

  const refColleague = useRef();
  const sidebarScroll = useRef();

  const sidebarClasses = ctl(`
    sidebar-fixed-menu
    ${
      isSwitchOpen || dropDownSearchOpen ? 'overflow-hidden' : 'overflow-y-auto'
    }
    ${isMinimized && '!w-[64px]'}
  `);

  useClickOutside(refColleague, () => onSearchCollagues(false));

  const isGiveReviewCommentEnabled =
    config?.permission?.objectiveNeedResponseSee &&
    config.separateObjectiveOngoingReviewPage;

  const { data: needResponseReviewCountData } = useGetNeedResponsesCount(
    'give-review',
    { filter: 'needreview' },
    { enabled: isGiveReviewCommentEnabled }
  );

  const { data: needResponseCommentCountData } = useGetNeedResponsesCount(
    'give-comment',
    { filter: 'overdue' },
    { enabled: isGiveReviewCommentEnabled }
  );

  let queryKey = ['notification', 'formal-review'];
  let { data: formalReviewCountData } = useQuery(
    queryKey,
    () => getFeedbackCount({ type: 'performance_review' }),
    {
      enabled: config.permissions?.reviewPerformanceSee || false
    }
  );

  let { data: feedbackCountData } = useQuery(
    ['notification', 'feedback'],
    () => getFeedbackCount({ type: 'feedback' }),
    {
      enabled: config.permissions?.reviewFeedbackSee || false
    }
  );

  const formalReviewCount = formalReviewCountData?.data;
  const feedbackCount = feedbackCountData?.data;
  const needResponseReviewCount = needResponseReviewCountData?.data;
  const needResponseCommentCount = needResponseCommentCountData?.data;

  const toggleCheckin = () => {
    setShowCheckin(!showCheckin);
    if (showCheckin) {
      history.replace({ state: undefined });
    } else {
      history.replace({ state: { checkin: true } });
    }
  };

  const goToCreateTask = (e) => {
    e.stopPropagation();
    history.replace(`/create-objective/task`);
  };

  const handleShowNotif = (data) => {
    setNotifMessage(data.message);
    setShowNotif(true);
    setWarning(false);
    if (!data.isSuccess) {
      setWarning(true);
    }
    setModalChangePassword(false);
    setTimeout(() => {
      setNotifMessage('');
      setShowNotif(false);
    }, 2000);
  };

  const showRecognitionFeedback = (e, type) => {
    e.stopPropagation();
    type === 'recognition'
      ? toggleRecognitionForm(true)
      : toggleFeedbackForm(true);
  };

  let shortcutMenu = [
    {
      title: getObjectiveLocale('Create Goal'),
      icon: 'icon-track_changes',
      onClick: (e) =>
        linkToCreateObjectives(e, history, checkValidationCreateGoal),
      isVisible:
        config.favoriteMenus?.includes('create_goal') &&
        config.permissions?.goalCreate,
      dataCy: 'create-goal-btn'
    },
    {
      title: getObjectiveLocale('Create Task'),
      icon: 'icon-assignment_outline',
      onClick: goToCreateTask,
      isVisible:
        config.favoriteMenus?.includes('create_task') &&
        config.permissions?.taskCreate,
      dataCy: 'create-task-btn'
    },
    {
      title: getObjectiveLocale('Recognition'),
      icon: 'icon-star_outline',
      onClick: (e) => showRecognitionFeedback(e, 'recognition'),
      isVisible: config?.permissions?.privateRecognitionCreate
    },
    {
      title: getObjectiveLocale('Feedback'),
      icon: 'icon-announcement_outline',
      onClick: (e) => showRecognitionFeedback(e, 'feedback'),
      isVisible: config?.permissions?.privateFeedbackCreate
    },
    {
      title: getObjectiveLocale('Search colleagues'),
      icon: 'icon-group-outline',
      onClick: (e) => onSearchCollagues(!dropDownSearchOpen),
      isVisible: isSearchCollagueVisible && isProfileVisible,
      dataCy: 'search-collagues'
    },
    {
      title: getObjectiveLocale('Explore Objective'),
      icon: 'icon-search',
      onClick: (e) => {
        history.push('/explorer');
      },
      isVisible: config?.permissions?.objectiveExplorerSee,
      dataCy: 'explorer'
    },
    {
      title: getObjectiveLocale('Notifications'),
      icon: 'icon-notifications_none',
      onClick: (e) => {
        history.push('/activities');
      },
      isVisible:
        config?.permissions?.activitiesSelfSee ||
        config?.permissions?.activitiesAsReviewerSee ||
        config?.permissions?.activitiesAsFollowerSee,
      dataCy: 'notifications',
      notificationCount: activitiesCount
    }
  ];

  shortcutMenu = shortcutMenu.filter((menu) => menu.isVisible);

  const sidebarProps = {
    toggleCheckin: toggleCheckin,
    shortcutMenu: shortcutMenu,
    dropDownSearchOpen: dropDownSearchOpen,
    sidebarMinimized: isMinimized,
    onSearchCollagues: onSearchCollagues,
    setIsHaveNotif: setIsHaveNotif,
    setModalChangePassword: () => setModalChangePassword(!modalChangePassword),
    isHaveNotif: isHaveNotif,
    isAppCulture: isAppCulture,
    isAbleToSwitch: isAbleToSwitch,
    activitiesCount: activitiesCount,
    approvalCount: approvalCount,
    surveyCount: surveyCount,
    feedbackCount: feedbackCount,
    formalReviewCount: formalReviewCount,
    needResponseCount: needResponseCount,
    needResponseReviewCount: needResponseReviewCount,
    needResponseCommentCount: needResponseCommentCount,
    isSwitchOpen: isSwitchOpen,
    setIsSwitchOpen: setIsSwitchOpen
  };

  const getNeedResponsesData = async () => {
    if (config.permissions?.objectiveNeedResponseSee) {
      const params = {
        periodBegin: formatTimezone(
          `${new Date().getFullYear()}-01-01`,
          'start'
        ),
        periodEndBefore: formatTimezone(
          `${new Date().getFullYear()}-12-31`,
          'end'
        )
      };
      const { data: objectiveCount } = await getNeedResponses(params);
      if (objectiveCount) {
        setNeedResponseCount(objectiveCount);
      }
    }
    if (config.permissions?.approvalSee) {
      const { data: approvalCount } = await getApprovalRequestsCount();
      if (approvalCount) {
        setApprovalCount(approvalCount);
      }
    }

    const { data: surveyCount } = await getSurveyCount();
    if (surveyCount) {
      setSurveyCount(surveyCount);
    }
  };

  const getActivitiesCount = async () => {
    const { data, isSuccess } = await getActivities();
    if (isSuccess) {
      setActivitiesCount(data);
    }
  };

  const getAnalytics = async () => {
    user.role && (await getNeedResponsesData());
  };

  const getActivitiesCounter = async () => {
    if (
      config.permissions?.activitiesSelfSee ||
      config.permissions?.activitiesAsReviewerSee ||
      config.permissions?.activitiesAsFollowerSee
    ) {
      user.role && (await getActivitiesCount());
    }
  };

  useEffect(() => {
    if ((appType === 'performance' || !appType) && user.role) {
      getAnalytics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadNeedResponseNotifCount, reloadApprovalNotifCount]);

  useEffect(() => {
    if ((appType === 'performance' || !appType) && user.role) {
      getActivitiesCounter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadActivityNotifCount]);

  return (
    <div className="sidebar-fixed relative z-10">
      <div className="absolute top-[15px] z-20 right-0 translate-x-1/2 fade-in">
        {isUseToggle && (
          <label
            className="cursor-pointer fade-in"
            onClick={() => toggleSidebar(!isMinimized)}
          >
            <CustomInnerIcon
              iconName={
                isMinimized
                  ? 'icon-keyboard_arrow_right'
                  : 'icon-keyboard_arrow_left'
              }
              type="rounded"
            />
          </label>
        )}
      </div>
      <ToastNotif
        showNotif={isSuccessPost}
        message={notifMessage}
        warning={warning}
      />
      <div
        ref={sidebarScroll}
        id="sidebar-fixed-menu"
        className={sidebarClasses}
      >
        {!isMinimized && (
          <div className="maximize justify-between fade-in">
            <SidebarMaximized {...sidebarProps} />
          </div>
        )}

        {isMinimized && (
          <div className="minimize">
            <SidebarMinimized {...sidebarProps} />
          </div>
        )}
      </div>
      {showCheckin && <ModalCheckin onClose={() => toggleCheckin()} />}
      {modalChangePassword && (
        <ModalChangePassword
          onClose={() => setModalChangePassword(false)}
          showNotif={(data) => handleShowNotif(data)}
        />
      )}
      {showModalValidationCreateGoal}
      {showRecognitionPopup && (
        <ModalPrivateRecognition
          type="recognition"
          closeModal={() => toggleRecognitionForm(false)}
        />
      )}
      {showFeedbackPopup && (
        <ModalPrivateRecognition
          type="feedback"
          closeModal={() => toggleFeedbackForm(false)}
        />
      )}
    </div>
  );
}

export default withRouter(Sidebar);

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';

import {
  addGroup,
  editGroup,
  getPresignUrlCulture
} from 'client/CultureClient';
import S3Client from 'client/S3Client';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import {
  capitalize,
  getCultureLocale,
  setDropdownOptions
} from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import ModalInviteMemberV2 from 'components/modal/culture/ModalInviteMemberV2';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';
import Modal from 'components/shared/modal/Modal';

const ModalManageGroup = ({
  isReadOnly,
  closeModal,
  typeGroupList,
  typeManage,
  existingGroup
}) => {
  const { config } = useUser();
  const history = useHistory();
  const { reload, reloadGroups } = useReload();

  const existingData = typeManage === 'create' ? {} : existingGroup;
  const existingPicture =
    typeManage === 'create'
      ? {}
      : { src: existingGroup?.groupPicture?.secureUrl };

  const [isLoading, setIsLoading] = useState(false);
  const [groupPicture, setGroupPicture] = useState(existingPicture);
  const [toBeAddedGroup, setToBeAddedGroup] = useState(existingData);
  const [selectedDropdown, setSelectedDropdown] = useState(
    existingData?.groupType
  );
  const [currentSection, setCurrentSection] = useState(
    typeManage === 'create' ? 'type' : 'detail'
  );

  const [listMember, setListMember] = useState([]);
  const [groupTypeOptions, setGroupTypeOptions] = useState([]);

  const allowedImageExtensions = config.allowedImageExtensions.map(
    (ext) => '.' + ext
  );
  const isTypeDisabled =
    (existingGroup?.groupType == 'default' ||
      existingGroup?.groupType == 'default_moderated') &&
    !config?.permissions?.cultureGroupDefaultManage;
  const isNotDefaultModerated =
    selectedDropdown != 'default' && selectedDropdown != 'default_moderated';

  const backToLastSection = () => {
    if (currentSection === 'detail') {
      setCurrentSection('type');
    } else {
      setCurrentSection('detail');
    }
  };

  const submitGroup = async (selectedMember, isAll) => {
    setIsLoading(true);
    const body = {
      group: {
        memberIds: isAll ? [] : selectedMember?.map((member) => member.id),
        all: isAll,
        groupType: selectedDropdown,
        name: toBeAddedGroup.name,
        description: toBeAddedGroup.description
      }
    };

    const promise = new Promise(async (resolve) => {
      if (groupPicture.file) {
        const presignData = {
          files: [
            {
              fileType: 'image',
              fileFormat: groupPicture.fileExtension
            }
          ]
        };
        const { data } = await getPresignUrlCulture(presignData);

        if (data) {
          const { status } = await S3Client(
            data[0].presignedUrl,
            groupPicture.file
          );
          if (status == 200) {
            body.group.groupPictureAttributes = {
              secureUrl: data[0].secureUrl
            };
          }
        }
      }
      resolve(body);
    });

    await Promise.resolve(promise).then(async (body) => {
      const manageFunction = () =>
        typeManage === 'create'
          ? addGroup(body)
          : editGroup(existingData.id, body);
      const { data: groupData } = await manageFunction();
      if (groupData) {
        typeManage === 'create'
          ? reload({ reloadGroups: !reloadGroups })
          : reload({ reloadCurrentGroup: { id: groupData.id } });
        typeManage === 'create' &&
          history.replace(`/timeline/group/${groupData.id}`);
      }
      resetModal();
    });
  };

  const changeGroupData = (e, type) => {
    let currentData = cloneDeep(toBeAddedGroup);
    currentData[type] = e.target.value;
    setToBeAddedGroup(currentData);
  };

  const resetModal = () => {
    setCurrentSection('type');
    setListMember([]);
    setToBeAddedGroup({ type: '' });
    setGroupPicture({});
    closeModal();
  };

  const addPhoto = (e) => {
    e.stopPropagation();

    const photo = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(photo);

    let selectedPhoto = new Image();
    reader.onload = (r) => {
      selectedPhoto.src = r.target.result;
      selectedPhoto.fileExtension = photo.name.split('.').pop().toLowerCase();
    };

    reader.onloadend = () => {
      setGroupPicture({
        src: selectedPhoto.src,
        file: photo,
        fileExtension: selectedPhoto.fileExtension
      });
    };
  };

  const _setDropdownOptions = () => {
    const onClickFunction = (onClickData) => {
      !isReadOnly && setSelectedDropdown(onClickData);
    };
    const options = setDropdownOptions(
      typeGroupList,
      'name',
      (onClickData) => onClickFunction(onClickData),
      'id',
      ''
    );
    setGroupTypeOptions(options);
  };

  useEffect(() => {
    _setDropdownOptions();
  }, []);

  return (
    <Modal
      dataCyModal="manage-group-modal"
      useBorder={true}
      withCloseIcon={false}
      title={`${capitalize(typeManage)} Group`}
      className="modal-create-group w-[400px]"
      eventOnClickClose={() => resetModal()}
      maxHeight={640}
      contentClass="overflow-auto max-h-[400px]"
      headerIcon={
        currentSection !== 'type' &&
        typeManage === 'create' && {
          name: 'icon-keyboard_arrow_left',
          color: 'var(--n-600)',
          onClick: () => backToLastSection()
        }
      }
      withPrimaryBtn={
        currentSection !== 'type' && {
          title:
            typeManage === 'create' &&
            currentSection === 'detail' &&
            isNotDefaultModerated
              ? 'Next'
              : 'Submit',
          disabled: isReadOnly
            ? isReadOnly
            : currentSection === 'detail'
            ? !toBeAddedGroup?.name
            : listMember?.length === 0,
          onClick: () =>
            typeManage === 'create' && isNotDefaultModerated
              ? setCurrentSection('participant')
              : submitGroup(),
          isLoading: isLoading,
          dataCy: 'primary-btn'
        }
      }
      withSecondaryBtn={{
        title: 'Cancel',
        onClick: () => resetModal(),
        dataCy: 'secondary-btn'
      }}
    >
      {currentSection === 'type' &&
        typeManage === 'create' &&
        typeGroupList.map((type, index) => (
          <div
            className="flex items-start py-[8px] border-bottom-n-400 cursor-pointer list-group"
            {...(!isReadOnly && {
              onClick: () => {
                setSelectedDropdown(type.id), setCurrentSection('detail');
              }
            })}
            key={index}
          >
            <div className="flex items-center mr-[16px] icon-type">
              <SVGIcon
                iconName={type.icon}
                size="24"
                fillColor="var(--base-600)"
              />
            </div>
            <div>
              <div className="typography-h400" data-cy="type-name">
                {type.name}
              </div>
              <div className="typography-h200" data-cy="type-desc">
                {type.desc}
              </div>
            </div>
          </div>
        ))}
      {currentSection === 'detail' && (
        <div data-cy="group-detail">
          <div className="relative">
            <AWSImage
              src={
                groupPicture?.src
                  ? groupPicture?.src
                  : Config.asset.general.defaultGroupCover
              }
              className="w-full h-[150px] cover-photo"
            />
            {!isReadOnly && (
              <div className="mb-[16px] center-component-modal button">
                <input
                  className="input-file"
                  type="file"
                  accept={allowedImageExtensions}
                  id="group-profile"
                  onChange={addPhoto}
                />
                <label htmlFor="group-profile" className="cursor-pointer">
                  {getCultureLocale('EditCoverPhoto')}
                </label>
              </div>
            )}
          </div>
          <InputField
            className="mt-[16px]"
            label="Group Name"
            placeholder="Name your group"
            handleChange={(e) => changeGroupData(e, 'name')}
            value={toBeAddedGroup?.name}
            dataCy="group-name"
            readonly={isReadOnly}
          />
          <div className="mt-[16px] mb-[8px] typography-h100 text-n-800">
            {getCultureLocale('GroupDescription')}
          </div>
          <TextArea
            name="desc"
            onHandleChange={(e) => changeGroupData(e, 'description')}
            textPlaceholder={getCultureLocale('Describe your group')}
            value={toBeAddedGroup.description}
            className="max-h-[72px] max-h-[72px]"
            height={72}
            dataCy="group-description"
            disabled={isReadOnly}
          />
          {typeManage === 'edit' && (
            <>
              <div className="mt-[16px] mb-[8px] typography-h100 text-n-800">
                {getCultureLocale('Group Type')}
              </div>
              <Dropdown customClass="w-full">
                <Dropdown.Trigger
                  disabled={isReadOnly || isTypeDisabled}
                  dataCy="dropdown-group-type"
                >
                  <Trigger
                    customWrapperClass="w-full"
                    disabled={isReadOnly || isTypeDisabled}
                    topLabel={false}
                    label={false}
                    icon={false}
                    text={`${capitalize(
                      selectedDropdown.replace('_', ' ')
                    )} ${getCultureLocale('Group')}`}
                  />
                </Dropdown.Trigger>
                <FloatingComponent lockScroll={false}>
                  <Dropdown.MenuItems
                    options={groupTypeOptions}
                    type="fixed"
                    customClass="w-[347px] h-[144px] overflow-auto"
                    dataCy="dropdown-group-type"
                    position="right"
                    verticalAlign="bottom"
                  />
                </FloatingComponent>
              </Dropdown>
            </>
          )}
        </div>
      )}
      {currentSection === 'participant' && typeManage === 'create' && (
        <ModalInviteMemberV2
          setShowInviteModal={closeModal}
          submitGroup={submitGroup}
          type="create"
          currentGroup={selectedDropdown}
        />
      )}
    </Modal>
  );
};

export default ModalManageGroup;

import React, { useEffect, useState } from 'react';

import {
  activateTrackTemplate,
  deleteOneTrackTemplate,
  getDetailTrackTemplate,
  getTrackTemplateData
} from 'client/TrackTemplateClient.js';
import debounce from 'utils/Debounce';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import SidebarTrackTemplate from 'components/admin/track-template/SidebarTrackTemplate.js';
import TableRowTrackList from 'components/admin/track-template/TableRowTrackList.js';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SearchBar from 'components/shared/SearchBar';
import Modal from 'components/shared/modal/Modal';

import ModalCreateTrackTemplate from './ModalCreateTrackTemplate';
import './TrackTemplate.scss';

function TrackTemplate({ route: { title, isReadOnly } }) {
  // Active and Archived Tabs
  const [listOfTab, setListOftab] = useState([
    { id: 'active', name: 'Active', count: 0 },
    { id: 'archived', name: 'Archived', count: 0 }
  ]);
  const [activeTab, setActiveTab] = useState('active');

  // List state
  const [trackList, setTrackList] = useState([]);
  const [previousPaginationData, setPreviousPaginationData] = useState(false);
  const [loading, setLoading] = useState(false);

  // Pagination
  const [paginationObject, setPaginationObject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  // Modals
  const [showDeactivateModal, setShowDeactivateModal] = useState(false); // false and id
  const [showCreateTrackModal, setShowCreateTrackModal] = useState(false); // false and id

  // Search State
  const [searchValue, setSearchValue] = useState('');
  const [debounceHandler, setDebounceHandler] = useState(null);

  // Sidebar State
  const [showSidebar, setShowSidebar] = useState(false);
  const [trackDetailData, setTrackDetailData] = useState(null); // object
  const [sidebarLoading, setSidebarLoading] = useState(false);

  // Initial params
  let params = {
    limit: 10
  };

  const getActiveTrackList = async () => {
    // Populate params with conditional
    if (searchValue !== '') {
      params.q = searchValue;
    }

    if (activeTab === 'active') {
      params.state = 'active';
    }
    if (activeTab === 'archived') {
      params.state = 'inactive';
    }

    setTrackList([]);
    setLoading(true);
    setCurrentPage(1);
    const { data, active_count, inactive_count, pagination } =
      await getTrackTemplateData(params);
    let tempListofTab = [...listOfTab];
    let newListofTab = tempListofTab?.map((tab) => {
      if (tab.id === 'active') return { ...tab, count: active_count };
      if (tab.id === 'archived') return { ...tab, count: inactive_count };
    });
    setListOftab(newListofTab);
    setTrackList(data);
    setLoading(false);

    setPaginationObject(pagination);
    setHasMore(data?.length === params.limit && pagination?.next?.olderThan);
  };

  const getTrackDetail = async (trackId, activeTab) => {
    let params = {};
    if (activeTab === 'active') {
      params.state = 'active';
    }
    if (activeTab === 'archived') {
      params.state = 'inactive';
    }
    setSidebarLoading(true);
    setShowSidebar(true);
    const { data } = await getDetailTrackTemplate(trackId, params);
    setTrackDetailData(data);
    setSidebarLoading(false);
  };

  const searchDebounce = async (value) => {
    const callback = async () => {
      setSearchValue(value);
    };

    const handler = await debounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  const handleNextOrPrevPagination = async (nextOrPrev) => {
    setTrackList([]);
    setLoading(true);
    if (nextOrPrev === 'next') {
      params.olderThan = paginationObject.next.olderThan;
    } else {
      params.newerThan = paginationObject.prev.newerThan;
    }
    if (activeTab === 'active') {
      params.state = 'active';
    }
    if (activeTab === 'archived') {
      params.state = 'inactive';
    }

    const { data, pagination } = await getTrackTemplateData(params);
    if (data?.length > 0) {
      setTrackList(data);
      setPreviousPaginationData(data);
      setPaginationObject(pagination);
      setHasMore(data?.length === params.limit && pagination?.next?.olderThan);
      setCurrentPage(nextOrPrev === 'next' ? currentPage + 1 : currentPage - 1);
    } else {
      // To present previous data when there is no new data but Next pagination button is available
      setTrackList(previousPaginationData);
      setHasMore(false);
    }
    setLoading(false);
  };

  const handleDeactivate = async (id) => {
    const { data } = await deleteOneTrackTemplate(id);
    if (data) {
      const newList = trackList.filter((track) => track.id !== id);
      setTrackList(newList);

      let tempListofTab = [...listOfTab];
      let newListofTab = tempListofTab?.map((tab) => {
        if (tab.id === 'active') return { ...tab, count: tab.count - 1 };
        if (tab.id === 'archived') return { ...tab, count: tab.count + 1 };
      });
      setListOftab(newListofTab);
      setShowDeactivateModal(false);
    }
  };

  const handleActivateTrack = async (id) => {
    let params = {
      state: 'active'
    };
    const { data } = await activateTrackTemplate(id, params);

    if (data) {
      const newList = trackList.filter((track) => track.id !== id);
      setTrackList(newList);

      let tempListofTab = [...listOfTab];
      let newListofTab = tempListofTab?.map((tab) => {
        if (tab.id === 'active') return { ...tab, count: tab.count + 1 };
        if (tab.id === 'archived') return { ...tab, count: tab.count - 1 };
      });
      setListOftab(newListofTab);
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  useEffect(() => {
    getActiveTrackList();
  }, [searchValue, activeTab]);

  const headerProps = {
    titlePage: title,
    primaryAction: {
      title: 'Create Track Template',
      icon: 'icon-add',
      onClick: () => {
        setShowCreateTrackModal(true);
      },
      dataCy: 'create-template',
      disabled: isReadOnly
    }
  };

  return (
    <>
      <HeaderPage
        {...headerProps}
        tabs={{
          tabsList: listOfTab,
          url: '/appraisals/cycles/track-template',
          onChange: (tab) => {
            setActiveTab(tab);
          },
          withCount: true
        }}
      />
      <div
        className="track-template pt-[8px] bg-n-000 min-h-[calc(100vh-64px)]"
        data-cy="list-templates"
      >
        <div className="mt-[16px] mb-[16px]">
          <SearchBar
            placeholder="Search template"
            onChange={(e) => searchDebounce(e.target.value)}
          />
        </div>
        {/* Table Track Template */}
        <table className="track-template-table">
          <thead>
            <tr>
              <th className="w-[59%]">{getObjectiveLocale('Name')}</th>
              <th className="w-[10%]">{getObjectiveLocale('Used on')}</th>
              <th className="w-[26%]">{getObjectiveLocale('Type')}</th>
              <th className="w-[5%]"> </th>
            </tr>
          </thead>

          <tbody>
            {trackList?.map((track, index) => {
              return (
                <TableRowTrackList
                  key={track.id}
                  index={index}
                  id={track.id}
                  name={track.name}
                  cycleCount={track.cycleCount}
                  trackType={track.trackType}
                  isEditable={track.isEditable}
                  getTrackDetail={getTrackDetail}
                  setShowDeactivateModal={setShowDeactivateModal}
                  activeTab={activeTab}
                  handleActivateTrack={handleActivateTrack}
                  isReadOnly={isReadOnly}
                />
              );
            })}
          </tbody>
        </table>
        {/* EMPTY STATE AND LOADING */}
        {loading ? (
          <div className="flex-center-center mt-[16px]">
            <LoadingComponent />
          </div>
        ) : (
          !loading &&
          trackList?.length === 0 && (
            <ListEmptyState
              fullHeight
              emptyIcon={
                searchValue
                  ? 'icon-no-result-found'
                  : 'icon-no-active-performance-review'
              }
              containerClassname="my-[40px]"
              title={getObjectiveLocale('No Result Found')}
              subtitle={getObjectiveLocale(
                searchValue
                  ? 'Did you probably type the keyword incorrectly? We are sorry that what you searched was unfortunately not found.\n You might also find the records by switching to tab above.'
                  : "Once your track templates are set, you'll see them here.\n You might also find the records by switching to tab above."
              )}
              subtitleClassname="text-center"
              datacy="empty-state-track-template-table"
            />
          )
        )}

        {/* Sidebar */}
        {showSidebar && (
          <SidebarTrackTemplate
            trackDetailData={trackDetailData}
            setTrackDetailData={setTrackDetailData}
            setShowSidebar={setShowSidebar}
            setShowDeactivateModal={setShowDeactivateModal}
            sidebarLoading={sidebarLoading}
            activeTab={activeTab}
            handleActivateTrack={handleActivateTrack}
            isReadOnly={isReadOnly}
          />
        )}

        {/* Pagination button */}
        <div className="pagination-track-template-container">
          <PaginationModule
            pageNumber={currentPage}
            handlePrev={() => handleNextOrPrevPagination('prev')}
            handleNext={() => handleNextOrPrevPagination('next')}
            hasMore={hasMore}
          />
        </div>

        {/* Deactivate Modal  */}
        {showDeactivateModal && (
          <Modal
            eventOnClickClose={() => setShowDeactivateModal(false)}
            title="Deactivate Template"
            className="w-[400px] h-[230px]"
            description="Deactivate this template removes it from your active table. You can always access it again from your “Inactive” section."
            withPrimaryBtn={{
              danger: true,
              title: 'Deactivate',
              dataCy: 'deactivate-btn',
              onClick: () => handleDeactivate(showDeactivateModal)
            }}
            withSecondaryBtn={{
              title: 'Cancel',
              dataCy: 'cancel-deactivate',
              onClick: () => setShowDeactivateModal(false)
            }}
          />
        )}

        {/* Create Track Modal */}
        {showCreateTrackModal && (
          <ModalCreateTrackTemplate
            setShowCreateTrackModal={setShowCreateTrackModal}
          />
        )}
      </div>
    </>
  );
}

export default TrackTemplate;

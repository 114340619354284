import React from 'react';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

function MissionRecipients({ mission, setPage, haveSubmitted }) {
  let isEditRecipients = mission.selectAll
    ? true
    : mission.includes?.length > 0;
  let wordingRecipients = mission.selectAll
    ? mission.excludes?.length > 0
      ? `All members except ${mission.excludes?.length} recipients excluded`
      : `All members`
    : mission.includes?.length > 0
    ? `${mission.includes.length} recipients selected`
    : '';
  let errorRecipients = mission.selectAll
    ? false
    : mission.includes?.length == 0;

  return (
    <div
      id="recipients-section"
      className="form-group border-bottom-400 py-[40px]"
    >
      <label
        htmlFor="missionRecipients"
        className="typography-h500-longform text-n-900 mb-[4px] "
      >
        Select recipients
      </label>
      <p className="typography-paragraph typography-secondary mb-[16px]">
        Who will do this mission?
      </p>
      {wordingRecipients !== '' && (
        <p className="selected-recipients mb-[16px]">{wordingRecipients}</p>
      )}
      <Button.Secondary
        datacy="select-recipients"
        onClick={() => setPage('setRecipients')}
      >
        <SVGIcon
          size="24"
          fillColor="var(--base-600)"
          iconName={isEditRecipients ? 'icon-create' : 'icon-add'}
          customClass="mr-[4px]"
        />
        <p>{isEditRecipients ? 'Edit recipient' : 'Add recipient'}</p>
      </Button.Secondary>
      {haveSubmitted && errorRecipients && (
        <p
          className="relative text-r-600 typography-h100 mt-[8px]"
          data-cy="error-recipients"
        >
          This cannot be blank.
        </p>
      )}
    </div>
  );
}

export default MissionRecipients;

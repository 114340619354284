import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import { deleteOrganization, getOrganization } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import Config from 'utils/Config';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import Modal from 'components/design-system/Modal';
import ToastNotif from 'components/shared/ToastNotif';
import Table from 'src/components/design-system/table/Table';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

function ListOrganizations({ history, route: { title, isReadOnly } }) {
  const { config } = useUser();
  const filter = useFilter(
    (state) => state?.filterById?.manageOrganizationFilter
  );
  const permission = config.permissions;
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState('');
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [dataOrg, setDataOrg] = useState([]);

  const onConfirmDelete = async () => {
    const { data } = await deleteOrganization(idToDelete);

    if (data) {
      _getData();
      setIsModalDeleteOpen(false);
      setShowAlert(true);
      setShowAlertMessage('Deleted Successfully! ');
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  };

  const closeModal = () => {
    setIsModalDeleteOpen(false);
  };

  const _getData = async () => {
    const { data } = await getOrganization();
    if (data) {
      setOrganizationsList(data);
      setDataOrg(data);
    }
  };

  const headerProps = {
    titlePage: title,
    primaryAction: permission?.settingsOrganizationCreate && {
      title: 'Create Organization',
      icon: 'icon-add',
      onClick: () => {
        history.push('/create-organization');
      }
    }
  };

  useEffect(() => {
    _getData();
  }, []);

  useEffect(() => {
    const searchValue =
      typeof filter?.q === 'undefined' ? '' : filter?.q?.toLowerCase();
    const filteredOrganizations = organizationsList?.filter(
      (organization) =>
        organization?.name?.toLowerCase()?.includes(searchValue) ||
        organization?.identifier?.toLowerCase()?.includes(searchValue)
    );
    setDataOrg(filteredOrganizations);
  }, [filter?.q, organizationsList]);

  const headers = [
    {
      name: 'Organization',
      widthClass: 'w-[20%]',
      heightClass: 'h-auto'
    },
    {
      name: 'Identifier',
      widthClass: 'w-[15%]',
      heightClass: 'h-auto'
    },
    {
      name: 'Performance App Name',
      widthClass: 'w-[20%]',
      heightClass: 'h-auto'
    },
    {
      name: 'Culture App Name',
      widthClass: 'w-[15%]',
      heightClass: 'h-auto'
    },
    {
      name: 'Settings',
      widthClass: 'w-[20%]',
      heightClass: 'h-auto'
    },
    {
      name: 'Action',
      widthClass: 'w-[10%]',
      heightClass: 'h-auto'
    }
  ];

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="my-[24px]">
        <AdvancedFilter
          id="manageOrganizationFilter"
          filterOptions={['search']}
        />
      </div>
      <div className="admin-culture">
        <div className="list-organizations mt-[12px]">
          <ToastNotif showNotif={showAlert} message={showAlertMessage} />
          <div id="dashboard_content" className="px-[0px]">
            <Table headers={headers} customClass="table-list-organization">
              {dataOrg.map((data, idx) => {
                const companyLogo = data.companyLogo
                  ? data.companyLogo
                  : Config.asset.general.defaultPicture;

                return (
                  <Table.Row key={idx} id="user-info" customClass="mb-[12px]">
                    <Table.Column customClass="organization-name">
                      <AWSImage
                        src={companyLogo}
                        className="img-thumb img-circle company-logo object-contain"
                        alt={data.name}
                      />
                      <span className="ml-[80px] bolder">{data.name}</span>
                    </Table.Column>
                    <Table.Column customClass="organization-identifier">
                      {data.identifier}
                    </Table.Column>
                    <Table.Column customClass="app-name">
                      {data.appName}
                    </Table.Column>
                    <Table.Column customClass="app-name">
                      {data.cultureAppName}
                    </Table.Column>
                    <Table.Column customClass="video-settings flex-col justify-center">
                      <p className="mb-[1px] w-full items-start">
                        {getObjectiveLocale(
                          `Allow Sharing: ${data.allowSharing ? 'Yes' : 'No'}`
                        )}
                      </p>
                      <p className="mb-[1px] w-full items-start">
                        {getObjectiveLocale(
                          `Allow Video: ${data.allowVideo ? 'Yes' : 'No'}`
                        )}
                      </p>
                      <p className="mb-[1px] w-full items-start">
                        {getObjectiveLocale(
                          `User Video Length: [video_length]`
                        ).replace(
                          /\[video_length]/,
                          data.allowVideo === false
                            ? '-'
                            : data.videoConfig.userVideoLength + ' seconds'
                        )}
                      </p>
                      <p className="mb-[1px] w-full items-start">
                        {getObjectiveLocale(
                          `Admin Video Length: [video_length]`
                        ).replace(
                          /\[video_length]/,
                          data.allowVideo === false
                            ? '-'
                            : data.videoConfig.adminVideoLength + ' seconds'
                        )}
                      </p>
                    </Table.Column>
                    <Table.Column customClass="button-action list-organization justify-center">
                      <Button
                        customClass="w-full flex-start"
                        onClick={() =>
                          history.replace(`/organization/${data.id}/view`)
                        }
                      >
                        {getObjectiveLocale('View')}
                      </Button>
                      <Button
                        customClass="w-full flex-start"
                        onClick={() =>
                          history.replace(`/organization/${data.id}/edit`)
                        }
                      >
                        {getObjectiveLocale('Edit')}
                      </Button>
                      <Button
                        customClass="w-full flex-start"
                        onClick={() => {
                          setIsModalDeleteOpen(true);
                          setIdToDelete(data.id);
                        }}
                      >
                        {getObjectiveLocale('Delete')}
                      </Button>
                    </Table.Column>
                  </Table.Row>
                );
              })}
            </Table>
          </div>
          {isModalDeleteOpen && (
            <Modal onClose={closeModal}>
              <Modal.Header
                title={getObjectiveLocale('Delete Organization')}
                onClose={closeModal}
              />
              <Modal.Body>
                <p className="px-[24px]">
                  {getObjectiveLocale(
                    'Are you sure you want to delete organization?'
                  )}
                </p>
              </Modal.Body>
              <Modal.Footer
                primaryButton={{
                  text: getObjectiveLocale('Delete'),
                  onClick: onConfirmDelete,
                  dataCy: 'delete-org'
                }}
                secondaryButton={{
                  text: getObjectiveLocale('Cancel'),
                  onClick: closeModal,
                  dataCy: 'cancel-delete-org'
                }}
                useBorder={false}
              />
            </Modal>
          )}
        </div>
      </div>
    </>
  );
}

export default withRouter(ListOrganizations);

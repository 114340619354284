import React, { useState } from 'react';

import { getUrlInfo } from 'client/CultureClient';
import { getCultureLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Modal from 'components/shared/modal/Modal';

function ModalAddLink({ setIsModalLinkOpen, setLinkAttachment }) {
  const [link, setLink] = useState('');

  const changeLink = (e) => {
    setLink(e.target.value);
  };

  const saveUrl = async () => {
    const { data } = await getUrlInfo(link);

    if (data) {
      const linkAttachment = {
        image: data.imageUrl,
        title: data.title,
        providerUrl: data.domain,
        url: data.uri
      };
      setLinkAttachment([linkAttachment]);
    }
    setIsModalLinkOpen(false);
  };

  return (
    <Modal
      dataCyModal="modal-add-link"
      className="modal-add-link"
      eventOnClickClose={() => setIsModalLinkOpen(false)}
      title={getCultureLocale('AddLink')}
      isButton={false}
      withPrimaryBtn={{
        title: getCultureLocale('OK'),
        onClick: () => saveUrl(),
        dataCy: 'get-url-info',
        disabled: !link
      }}
    >
      <div className="content">
        <input
          placeholder={getCultureLocale('ComposerAddLinkMessageLabel')}
          onChange={(e) => changeLink(e)}
          value={link}
          data-cy="input-link"
        />
      </div>
    </Modal>
  );
}

export default ModalAddLink;

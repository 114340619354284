import React, { useEffect, useState } from 'react';

import { getCssVariableValue, getObjectiveLocale } from 'utils/HelperUtils';

import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import MenuOutsideClickWrapper from 'components/objectives/compact-objective/fragments/MenuOutsideClickWrapper';
import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';

import DependencyDropdown from '../../tasks/dropdown/DependencyDropdown';

const AddDependency = ({ objectiveValue, setObjectiveValue, defaultValue }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  const [removeBlockerTask, setRemoveBlockerTask] = useState([]);

  const handleChangeDependency = (sel) => {
    let newSelectedTask = [...selectedTask];
    let removeBlocker = [...removeBlockerTask];

    if (!newSelectedTask.some((task) => task.id === sel.id)) {
      newSelectedTask.push({
        id: sel.id,
        name: sel.name,
        iconLeft:
          sel.state === 'completed'
            ? 'icon-check_circle'
            : 'icon-hourglass_empty',
        iconLeftColor:
          sel.state === 'completed' ? 'var(--g-600)' : 'var(--y-600)'
      });
    } else {
      removeBlocker.push(sel);
      newSelectedTask = newSelectedTask.filter(
        (newSelected) => newSelected.id !== sel.id
      );
    }

    setSelectedTask(newSelectedTask);
    setRemoveBlockerTask(removeBlocker);

    setObjectiveValue((draft) => {
      draft.blockedBy = newSelectedTask;
      draft.blockerBy = removeBlocker;
    });
  };

  useEffect(() => {
    let newSelectedTask =
      defaultValue?.map((task) => ({
        id: task.id,
        name: task.name,
        iconLeft:
          task.state === 'completed'
            ? 'icon-check_circle'
            : 'icon-hourglass_empty',
        iconLeftColor:
          task.state === 'completed' ? 'var(--g-600)' : 'var(--y-600)'
      })) || [];

    setSelectedTask(newSelectedTask);
  }, [defaultValue]);

  return (
    <MenuOutsideClickWrapper event={() => setShowDialog(false)}>
      <div className="px-[24px] hover:bg-base-30024 cursor-pointer">
        <div className="py-[12px] flex" onClick={() => setShowDialog(true)}>
          <SVGIcon
            iconName="icon-hourglass_empty"
            size="24"
            fillColor="var(--n-600)"
            customClass="mr-[16px]"
          />
          {selectedTask?.length > 0 ? (
            <div className="flex flex-wrap gap-[9px]">
              {selectedTask.map((task) => (
                <Badge
                  content={task.name}
                  colorHex={getCssVariableValue('--n-800')}
                  bgColorHex={getCssVariableValue('--n-300')}
                  iconLeft={task.iconLeft}
                  iconLeftColor={task.iconLeftColor}
                />
              ))}
            </div>
          ) : (
            <span className="typography-paragraph text-n-600">
              {getObjectiveLocale('Add Dependency')}
            </span>
          )}
        </div>
      </div>

      {showDialog && (
        <DependencyDropdown
          task={objectiveValue}
          setTask={setObjectiveValue}
          selectedTask={selectedTask}
          position="middle"
          onChange={(e) => handleChangeDependency(e)}
          isCreate
        />
      )}
    </MenuOutsideClickWrapper>
  );
};

export default AddDependency;

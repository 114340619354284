import React, { useState } from 'react';

import ctl from '@netlify/classnames-template-literals';
import startCase from 'lodash/startCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/design-system/Avatar';
import SVGIcon from 'components/shared/SVGIcon';

import ModalDependencyInfo from './ModalDependencyInfo';

const AssignmentItem = ({
  reviewer,
  assignmentId,
  handleDeleteAssignment,
  setModalDeleteProps,
  setModalType,
  closeDeleteModal,
  cycleDetail
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const statusPhaseString =
    reviewer?.state == 'done' ? 'Completed' : startCase(reviewer?.state); //phase status
  const isAssignmentDeleted = reviewer?.state == 'deleted';
  const totalDependencies = reviewer?.assignmentDependencies?.length || 0;

  const assignmentClass = ctl(`
  flex
  flex-row
  justify-between
  py-[10px]
  px-[24px]
  hover:bg-base-3008
  ${isAssignmentDeleted ? 'opacity-50' : ''}
  `);

  const toggleDelete = (type) => {
    let state = 'delete';
    if (type == 'undeleteAssignment') {
      state = 'undelete';
    }
    setModalType(type);
    setModalDeleteProps({
      type: type,
      _handleNext: () =>
        handleDeleteAssignment({ reviewer, state, callback: closeDeleteModal }),
      _handleActionModal: closeDeleteModal
    });
  };

  return (
    <>
      <div
        className={assignmentClass}
        key={assignmentId}
        onMouseOver={() => setIsHover(assignmentId)}
        onMouseLeave={() => setIsHover()}
        data-cy={`assignment-${assignmentId}`}
      >
        <div className="flex flex-row">
          <Avatar
            size="32"
            customClass="mr-[16px]"
            src={reviewer?.actor?.profilePicture}
            name={reviewer?.actor?.name}
          />
          <div className="flex flex-col">
            <p className="typography-h400 text-n-900">
              {getObjectiveLocale(startCase(reviewer?.formalReviewPhase?.type))}
            </p>
            <p className="typography-h100 text-n-800">
              {reviewer?.actor?.name} - {reviewer?.actorPlacement?.name}
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className={`min-w-[168px] mr-[32px]`}>
            <p
              className={`typography-paragraph text-right ${
                totalDependencies == 0
                  ? 'text-n-800'
                  : 'text-base-600 hover:cursor-pointer'
              }`}
              onClick={() => {
                totalDependencies > 0 && setModalOpen(true);
              }}
              data-cy={`open-dependency-${assignmentId}`}
            >
              {totalDependencies == 0
                ? '--'
                : totalDependencies + ` ${getObjectiveLocale('Dependency')}`}
            </p>
          </div>
          <p
            className={`typography-paragraph min-w-[118px] mr-[44px]
              ${
                statusPhaseString == 'deleted'
                  ? 'text-r-700'
                  : statusPhaseString == 'Completed'
                  ? 'text-g-700'
                  : statusPhaseString == 'In Progress' ||
                    statusPhaseString == 'Incomplete'
                  ? 'text-y-700'
                  : 'text-r-700'
              }`}
          >
            {statusPhaseString}
          </p>
          <div className="min-w-[62px]">
            {isAssignmentDeleted ? (
              <p
                className="typography-paragraph text-n-600 hover:cursor-pointer"
                onClick={() => toggleDelete('undeleteAssignment')}
                data-cy={`undelete-assignment-${assignmentId}`}
              >
                {getObjectiveLocale('Undelete')}
              </p>
            ) : (
              <SVGIcon
                iconName="icon-delete"
                size="24"
                fillColor="var(--n-500)"
                customClass={`hover:cursor-pointer justify-end ${
                  isHover === assignmentId ? 'visible' : 'invisible'
                }`}
                onClick={() => toggleDelete('deleteAssignment')}
                dataCy={`delete-assignment-${assignmentId}`}
              />
            )}
          </div>
        </div>
      </div>

      {modalOpen && (
        <ModalDependencyInfo
          type={'dependencyInfo'}
          _handleActionModal={() => setModalOpen(false)}
          dependencies={reviewer}
          cycleDetail={cycleDetail}
        />
      )}
    </>
  );
};

export default AssignmentItem;

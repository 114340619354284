import React, { useEffect, useRef, useState } from 'react';

import { TagProvider, useTag } from 'context/TagContext';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getCssVariableValue } from 'utils/HelperUtils';

import InlineDialog from 'components/design-system/inline-dialog/InlineDialog';
import Badge from 'components/shared/Badge';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';
import ContentWrapper from 'components/sidebar/sidebar-create/ContentWrapper';

const InlineDialogSelectTag = ({
  setObjectiveValue,
  selectedValue = [],
  updateObjective,
  disabled = false
}) => {
  const { predefined, others, getTagsData } = useTag() || [];
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const debouncedSearchTerm = useDebounce(search, 500);

  const mergeLabel = [...predefined, ...others];
  const newOthersLabel = selectedValue.filter(
    (sel) => mergeLabel.indexOf(sel) == -1
  );

  const listLabel = [...mergeLabel, ...newOthersLabel];

  const unavailableTags = predefined?.length === 0 && others?.length === 0;

  const isFirstRender = useRef(true);

  const onOpenDialog = () => {
    setSearch('');
  };

  const handleChangeDialog = (sel) => {
    let newSelectedValue = [...selectedValue];
    if (!newSelectedValue.some((value) => value === sel)) {
      newSelectedValue.push(sel);
    } else {
      newSelectedValue = newSelectedValue.filter(
        (newSelected) => newSelected !== sel
      );
    }
    setObjectiveValue((draft) => {
      draft.tags = newSelectedValue;
    });
    updateObjective && updateObjective({ tags: newSelectedValue });
  };

  const _getTagsData = async () => {
    setIsLoading(true);
    await getTagsData({ q: search });
    setIsLoading(false);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      _getTagsData();
    }
  }, [debouncedSearchTerm]);

  const SelectedItem = () => {
    if (selectedValue.length === 0) return null;
    return (
      <InlineDialog.SelectedItem>
        {selectedValue.map((label, id) => (
          <div className="flex items-center mr-[8px]" key={id}>
            <Badge
              content={label}
              bgColorHex={getCssVariableValue('--n-300')}
              className={'mr-[4px] whitespace-nowrap cursor-pointer'}
              showCursorNotAllowed={false}
            />
            <SVGIcon
              size="16"
              iconName="icon-clear"
              fillColor="var(--n-600)"
              onClick={() => handleChangeDialog(label)}
              dataCy={`close-label-${id}`}
            />
          </div>
        ))}
      </InlineDialog.SelectedItem>
    );
  };

  const menuItemsProps = {
    vPosition: 'top',
    customSelectedComponent: <SelectedItem />,
    customClass: 'w-[256px] ml-[10px]',
    onOpenDialog,
    isLoading,
    emptyState: {
      subtitle: 'Label not found, create new label?',
      show: unavailableTags,
      fullHeight: true
    }
  };

  return (
    <InlineDialog
      type="multiple"
      header="Label"
      search={(e) => setSearch(e.target.value)}
      isBlock
    >
      <InlineDialog.Trigger disabled={disabled}>
        <ContentWrapper
          iconName="icon-label_outline"
          customWrapperClass="px-[24px] py-[12px] hover:bg-base-30024"
          dataCy="sidebar-create-label"
        >
          <div className="flex flex-wrap gap-[8px]">
            {selectedValue.length > 0 ? (
              selectedValue.map((label, id) => (
                <Badge
                  bgColorHex={getCssVariableValue('--n-300')}
                  content={label}
                  className="whitespace-nowrap pointer-events-none"
                  key={id}
                />
              ))
            ) : (
              <p className="text-n-600 typography-paragraph">
                {getObjectiveLocale('Add Label')}
              </p>
            )}
          </div>
        </ContentWrapper>
      </InlineDialog.Trigger>
      <InlineDialog.MenuItems {...menuItemsProps}>
        {(unavailableTags ? [search] : listLabel).map((label, id) => (
          <InlineDialog.MenuItem
            onClick={() => handleChangeDialog(label)}
            key={id}
            customClass="h-[32px]"
          >
            <div className="flex items-center">
              <Checkbox
                name={label}
                value={label || ''}
                id={label}
                checked={selectedValue.includes(label)}
                dataCy="tag-checkbox"
                labelClass="cursor-pointer"
                onClick={() => handleChangeDialog(label)}
              >
                <span className="typography-button ml-[8px]">{label}</span>
              </Checkbox>
            </div>
          </InlineDialog.MenuItem>
        ))}
      </InlineDialog.MenuItems>
    </InlineDialog>
  );
};

const InlineDialogSelectTagComponent = (props) => (
  <TagProvider>
    <InlineDialogSelectTag {...props} />
  </TagProvider>
);

export default InlineDialogSelectTagComponent;

import React from 'react';

import Config from 'utils/Config';

import AWSImage from 'components/design-system/AWSImage';

function PerformanceReportSourceComponent(props) {
  const { data, maxScore } = props;
  const defaultPicture = Config.asset.general.defaultPicture;
  const ratingDescriptionColor = `#${data.answer.rating.option.colorHex}`;
  const ratingDescriptionStyle = {
    color: ratingDescriptionColor
  };

  const getProfilePicture = (member) => {
    if (member && member.profilePicture) {
      return member.profilePicture;
    }

    return defaultPicture;
  };
  const renderStar = () => {
    const stars = [];

    for (let i = 0; i < maxScore; ++i) {
      if (i < data.answer.rating.option?.score) {
        stars.push(<span key={i} className="fa fa-star" />);
      } else {
        stars.push(
          <span key={i} className="fa fa-star-empty">
            ☆
          </span>
        );
      }
    }

    return stars;
  };

  return (
    <div className="source-card">
      <div className="source-testimony">
        <div className="source-user-avatar">
          {data.actor && (
            <div className="time-period-team-member-avatar">
              <AWSImage
                src={getProfilePicture(data.actor)}
                className="img-circle"
              />
            </div>
          )}
        </div>
        <div className="source-user-detail-testimony">
          <div className="source-user-name-rating">
            <div className="source-user-name">
              {data.actor && data.actor.name}
            </div>
            <div className="source-rating">
              <div className="source-star-container">{renderStar()}</div>
              <div className="source-rating-dot">·</div>
              <div
                className="source-rating-description"
                style={ratingDescriptionStyle}
              >
                {data.answer.rating.option.name}
              </div>
            </div>
          </div>
          {data.cycle.team && (
            <div className="source-user-position">
              {data.cycle.team.actorRole === 'member'
                ? 'Team Member'
                : data.cycle.team.actorRole === 'leader'
                ? 'Team Leader'
                : 'Team Reviewer'}
            </div>
          )}
        </div>
      </div>
      <div className="source-user-detail-testimony">
        <div className="source-period-goal">
          <div className="source-period">On {data.cycle.name}: </div>
          <div className="source-goal">
            {data.cycle.team && data.cycle.team.name}
          </div>
        </div>
        {data.answer.rating.comment != null && (
          <div className="source-comment">{data.answer.rating.comment}</div>
        )}
      </div>
      {(data.answer.recognitionValue != null ||
        data.answer.feedbackValue != null) && (
        <div className="source-divider" />
      )}
      {(data.answer.recognitionValue != null ||
        data.answer.feedbackValue != null) && (
        <div className="source-strength-improvement-container">
          <div className="source-label">STANDARD OF LEADERSHIP</div>
          <div className="source-strength-improvement-card">
            {data.answer.recognitionValue != null && (
              <div className="source-strength">
                <div className="source-type-container">
                  <div className="source-strength-rounded-card">
                    Strength Area
                  </div>
                </div>
                <div className="source-strength-detail">
                  <div className="source-behaviour-card">
                    <div className="behaviour">
                      {data.answer.recognitionValue.object.title}
                    </div>
                  </div>
                  <div className="source-behaviour-description">
                    {data.answer.recognitionValue.comment}
                  </div>
                </div>
              </div>
            )}
            {data.answer.feedbackValue != null && (
              <div className="source-improvement">
                <div className="source-type-container">
                  <div className="source-improvement-rounded-card">
                    Development Area
                  </div>
                </div>
                <div className="source-improvement-detail">
                  <div className="source-behaviour-card improvement">
                    <div className="behaviour">
                      {data.answer.feedbackValue.object.title}
                    </div>
                  </div>
                  <div className="source-behaviour-description">
                    {data.answer.feedbackValue.comment}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {(data.answer.recognitionCompetency != null ||
        data.answer.feedbackCompetency != null) && (
        <div className="source-divider" />
      )}
      {(data.answer.recognitionCompetency != null ||
        data.answer.feedbackCompetency != null) && (
        <div className="source-strength-improvement-container">
          <div className="source-label">SKILLS</div>
          <div className="source-strength-improvement-card">
            {data.answer.recognitionCompetency != null && (
              <div className="source-strength">
                <div className="source-type-container">
                  <div className="source-strength-rounded-card">
                    Strength Area
                  </div>
                </div>
                <div className="source-strength-detail">
                  <div className="source-behaviour-card">
                    <div className="behaviour">
                      {data.answer.recognitionCompetency.object.name}
                    </div>
                  </div>
                  <div className="source-behaviour-description">
                    {data.answer.recognitionCompetency.comment}
                  </div>
                </div>
              </div>
            )}
            {data.answer.feedbackCompetency != null && (
              <div className="source-improvement">
                <div className="source-type-container">
                  <div className="source-improvement-rounded-card">
                    Development Area
                  </div>
                </div>
                <div className="source-improvement-detail">
                  <div className="source-behaviour-card improvement">
                    <div className="behaviour">
                      {data.answer.feedbackCompetency.object.name}
                    </div>
                  </div>
                  <div className="source-behaviour-description">
                    {data.answer.feedbackCompetency.comment}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PerformanceReportSourceComponent;

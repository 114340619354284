import React, { useEffect, useState } from 'react';

import { getUsers } from 'client/admin/UsersClient';
import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ProfileType from 'components/design-system/ProfileType';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import Table from 'components/design-system/table/Table';
import LoadingComponent from 'components/shared/LoadingComponent';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TruncateParagraph from 'src/components/shared/TruncateParagraph';

import './ConfigureCalibration.scss';

function ConfigureCalibration() {
  const { cycleData, toggleCalibration, changeCalibrationData } =
    useCreateCycle();
  const [selectedCalibrator, setSelectedCalibrator] = useState([]);
  const [loader, setLoader] = useState(false);

  const listValue = [
    { id: 0, text: 'Level 0', onClick: () => handleChangeLevelCalibrator(0) },
    { id: 1, text: 'Level 1', onClick: () => handleChangeLevelCalibrator(1) },
    { id: 2, text: 'Level 2', onClick: () => handleChangeLevelCalibrator(2) },
    { id: 3, text: 'Level 3', onClick: () => handleChangeLevelCalibrator(3) },
    { id: 4, text: 'Level 4', onClick: () => handleChangeLevelCalibrator(4) },
    { id: 5, text: 'Level 5', onClick: () => handleChangeLevelCalibrator(5) },
    { id: 6, text: 'Level 6', onClick: () => handleChangeLevelCalibrator(6) },
    { id: 7, text: 'Level 7', onClick: () => handleChangeLevelCalibrator(7) },
    { id: 8, text: 'Level 8', onClick: () => handleChangeLevelCalibrator(8) },
    { id: 9, text: 'Level 9', onClick: () => handleChangeLevelCalibrator(9) },
    { id: 10, text: 'Level 10', onClick: () => handleChangeLevelCalibrator(10) }
  ];

  const tableHeaders = [
    { name: 'Name', widthClass: 'w-[240px]', hide: false },
    { name: 'Directorate', widthClass: 'w-[240px]' },
    { name: 'Department', widthClass: 'w-[240px]' },
    { name: 'Division', widthClass: 'w-[240px]' },
    { name: 'Job Role', widthClass: 'w-[240px]' },
    { name: 'Job Function', widthClass: 'w-[240px]' }
  ];

  const handleChangeLevelCalibrator = (value) => {
    setSelectedCalibrator([]);
    changeCalibrationData('maxManagerLevel', value);
  };

  const handleGetCalibrator = async (level) => {
    setLoader(true);
    let calibratorss = [...selectedCalibrator];

    for (let i = 0; i <= level; i++) {
      let params = { depth: i, state: ['active'] };
      const { data } = await getUsers(params);
      if (data && data?.length > 0) {
        calibratorss[i] = data;
        setLoader(false);
      }
    }
    setSelectedCalibrator(calibratorss);
  };

  useEffect(() => {
    if (
      cycleData?.calibration &&
      cycleData?.calibration?.maxManagerLevel !== null
    ) {
      handleGetCalibrator(cycleData?.calibration?.maxManagerLevel);
    }
  }, [cycleData.calibration]);

  return (
    <div className="deliverables">
      <h4>{getObjectiveLocale('CONFIGURE CALIBRATION')}</h4>
      <div>
        <div className="card">
          <h4 className="title-card">
            {getObjectiveLocale(
              'Configure on who will be assigned to do calibration for this review cycle'
            )}
          </h4>
          <SmallToggleSwitchPurple
            addClass="float-right mt-[12px] mb-[0px] mx-[12px]"
            onChange={(e) => {
              toggleCalibration(e);
            }}
            isChecked={cycleData.includeCalibration}
          />
          {cycleData.includeCalibration ? (
            <p className="p-true-false mt-[8px]">{getObjectiveLocale('Yes')}</p>
          ) : (
            <p className="p-true-false mt-[8px]">{getObjectiveLocale('No')}</p>
          )}

          {cycleData.includeCalibration ? (
            <div>
              <p>{getObjectiveLocale('Calibration will be done by')}:</p>
              <label className="container-radio">
                <input
                  type="radio"
                  name="automatic"
                  checked={cycleData?.calibration?.maxManagerLevel == null}
                  onChange={(e) => {
                    changeCalibrationData('maxManagerLevel', null);
                  }}
                  value="manager"
                />
                <p className="p-card">
                  {getObjectiveLocale(
                    'All managers who have subordinates may calibrate.'
                  )}
                </p>
                <span className="checkmark-radio"></span>
              </label>
              <label className="container-radio">
                <input
                  type="radio"
                  name="automatic"
                  checked={cycleData?.calibration?.maxManagerLevel != null}
                  onChange={() => {
                    changeCalibrationData('type', 'level');
                    changeCalibrationData('maxManagerLevel', 0);
                  }}
                  value="level"
                />
                <p className="p-card">
                  {getObjectiveLocale(
                    'Only certain levels in the organization may calibrate their subordinates.'
                  )}
                </p>
                <span className="checkmark-radio"></span>
              </label>
              {cycleData?.calibration?.maxManagerLevel != null ? (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      <p className="select-level-title">
                        {getObjectiveLocale('Select Level')}
                      </p>
                      <p className="select-level-description mb-[8px]">
                        {getObjectiveLocale(
                          'Levels allowed to do calibration until level'
                        )}
                        :
                      </p>
                      <Dropdown>
                        <Dropdown.Trigger dataCy="dropdown-level">
                          <Trigger
                            topLabel={false}
                            label={false}
                            icon={false}
                            text={
                              cycleData?.calibration?.maxManagerLevel
                                ? `Level ${cycleData?.calibration?.maxManagerLevel}`
                                : `Level 0`
                            }
                          />
                        </Dropdown.Trigger>
                        <Dropdown.MenuItems
                          options={listValue}
                          customClass="w-[120px]"
                          maxHeight={312}
                        />
                      </Dropdown>
                    </div>
                  </div>
                  <div className="mt-[32px]">
                    {loader ? (
                      <LoadingComponent />
                    ) : (
                      selectedCalibrator.map((v, i) => {
                        return (
                          <div key={i} className="mb-[32px]">
                            <div className="level-info">
                              <span className="level">
                                {getObjectiveLocale('Level')} {i}{' '}
                              </span>
                              <span>-</span>
                              <span className="employees-count">
                                {' '}
                                {v.length} {getObjectiveLocale('Employees')}
                              </span>
                            </div>
                            <Table
                              headers={tableHeaders}
                              fixedLeftCount={1}
                              withHideColumn
                            >
                              {v?.map((user, index) => {
                                const { id, name, email, info } = user;
                                const {
                                  directorate,
                                  department,
                                  division,
                                  jobRole,
                                  jobFunction
                                } = info;
                                return (
                                  <Table.Row
                                    key={id}
                                    dataCy={`table-row-${index}`}
                                  >
                                    <Table.Column key="0">
                                      <ProfileType
                                        user={user}
                                        title={name}
                                        subtitle={email}
                                        size={32}
                                        titleClassName="typography-h400"
                                        subtitleClassName="typography-h100"
                                      />
                                    </Table.Column>
                                    <Table.Column key="1">
                                      <TruncateParagraph>
                                        {directorate || '-'}
                                      </TruncateParagraph>
                                    </Table.Column>
                                    <Table.Column key="2">
                                      <TruncateParagraph>
                                        {department || '-'}
                                      </TruncateParagraph>
                                    </Table.Column>
                                    <Table.Column key="3">
                                      <TruncateParagraph>
                                        {division || '-'}
                                      </TruncateParagraph>
                                    </Table.Column>
                                    <Table.Column key="4">
                                      <TruncateParagraph>
                                        {jobRole || '-'}
                                      </TruncateParagraph>
                                    </Table.Column>
                                    <Table.Column key="5">
                                      <TruncateParagraph>
                                        {jobFunction || '-'}
                                      </TruncateParagraph>
                                    </Table.Column>
                                  </Table.Row>
                                );
                              })}
                            </Table>
                          </div>
                        );
                      })
                    )}
                  </div>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default ConfigureCalibration;

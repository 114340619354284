import React, { useEffect } from 'react';

import Shimmer from 'src/components/design-system/shimmer/Shimmer';

import { useItemLists } from '../utils/taskListHelper';
import GanttObjective from './GanttObjective';

const GanttSubObjectiveContainer = ({
  showChild,
  objectiveData,
  subContentOptions,
  level,
  timeGroup,
  objectiveOptions,
  setChildLoaded,
  childLoaded
}) => {
  if (showChild && objectiveData.childrenCount > 0) {
    return (
      <div className="child-section overflow-hidden w-full">
        <SubObjectiveWrapper
          subContentOptions={subContentOptions}
          parentId={objectiveData.id}
          level={level}
          timeGroup={timeGroup}
          objectiveOptions={objectiveOptions}
          setChildLoaded={setChildLoaded}
          childLoaded={childLoaded}
        />
      </div>
    );
  }
  return <></>;
};

const SubObjectiveWrapper = ({
  subContentOptions,
  parentId,
  level,
  timeGroup,
  objectiveOptions,
  setChildLoaded,
  childLoaded
}) => {
  const getQueryKey = subContentOptions?.getQueryKey;
  const defaultParams = subContentOptions?.defaultParams;
  const singleObjectiveOptions = subContentOptions?.singleObjectiveOptions;

  const queryKey = getQueryKey(parentId);

  const queryParams = { ...defaultParams, parentId };

  const queryOptions = {
    ...subContentOptions,
    queryParams,
    queryKey,
    getSingleQueryKey: singleObjectiveOptions.getQueryKey
  };

  const { isFetching, data } = useItemLists({
    queryOptions,
    targetPaginateId: 'load-more-' + parentId,
    isLoaded: childLoaded,
    getSingleQueryKey: singleObjectiveOptions.getQueryKey
  });

  useEffect(() => {
    setChildLoaded(!isFetching);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <>
      {data?.length > 0 &&
        data.map((childTask) => (
          <GanttObjective
            objective={childTask}
            level={level + 1}
            key={'name' + childTask?.id}
            objectiveOptions={objectiveOptions}
            timeGroup={timeGroup}
            subContentOptions={subContentOptions}
          />
        ))}
      <div id={'load-more-' + parentId} />
      <div
        className={`${
          isFetching ? 'load-more' : ''
        } flex flex-start w-full px-[16px]`}
      >
        {isFetching && (
          <Shimmer width="100%" height="24px" customClass="rounded-[4px]" />
        )}
      </div>
    </>
  );
};

export default GanttSubObjectiveContainer;

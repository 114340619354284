import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

import { checkShareSurveyToken } from 'client/adminClient';
import { useSurveyContext } from 'context/SurveyContext';
import { useToastContext } from 'context/ToastContext';

const SurveyRedirect = () => {
  const { setSurveyId, setOccurrenceId } = useSurveyContext();
  const { replace } = useHistory();
  const { search } = useLocation();
  const firstRender = useRef(true);
  const { addToast } = useToastContext();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get('token');
    if (!token) return replace('/');

    if (firstRender.current) {
      (async () => {
        const { data, isSuccess, error } = await checkShareSurveyToken({
          token
        });
        if (isSuccess) {
          const { survey } = data;
          setSurveyId(survey?.id);
          setOccurrenceId(survey?.occurrence?.id);
        } else {
          setTimeout(() => {
            addToast({
              msg:
                error?.message?.message ||
                error?.message ||
                'Something went wrong!',
              type: 'error'
            });
          }, 500);
        }
      })();
      firstRender.current = false;
    }
  }, [search, replace, setSurveyId, addToast, setOccurrenceId]);

  return null;
};

export default SurveyRedirect;

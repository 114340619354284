import React, { useEffect, useState } from 'react';

import { phaseName } from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalRevieweeOption from '../components/ModalRevieweeOption';
import ModalSharedTracks from '../components/ModalSharedTracks';
import VerifySection from './VerifySection';

const DeliverableRevieweeVerify = ({ cycleData }) => {
  const [phaseType, setPhaseType] = useState('');
  const [showModalRevieweeOption, setShowModalRevieweeOption] = useState(false);
  const [showRevieweeOption, setShowRevieweeOption] = useState(false);

  const deliverableContent = (phase) => {
    let deliverableValue = cycleData[phase]?.deliverable;
    let disclosureValue = cycleData[phase]?.disclosure;
    let sharedTracks =
      cycleData[phase]?.trackConfigs?.filter(
        (trackConfig) => trackConfig?.displayOnResult === true
      ) || [];
    let length = sharedTracks.length;

    if (deliverableValue === false) {
      return (
        <p className="typography-paragraph typography-tertiary">
          {getObjectiveLocale('Not shared')}
        </p>
      );
    } else if (disclosureValue === 'anonymous_disclosure') {
      return (
        <div>
          <p
            className={`typography-paragraph ${
              length > 0 ? 'text-base-600 cursor-pointer' : 'text-n-900'
            }`}
            onClick={() => length > 0 && setPhaseType(phase)}
          >
            {getObjectiveLocale('Shared Tracks')} ({length})
          </p>
          <p className="typography-h100 typography-secondary">
            {getObjectiveLocale('Shared to the reviewee anonymously')}
          </p>
        </div>
      );
    } else if (disclosureValue === 'full_disclosure') {
      return (
        <div>
          <p
            className={`typography-paragraph ${
              length > 0 ? 'text-base-600 cursor-pointer' : 'text-n-900'
            }`}
            onClick={() => length > 0 && setPhaseType(phase)}
          >
            {getObjectiveLocale('Shared Tracks')} ({length})
          </p>
          <p className="typography-h100 typography-secondary">
            {getObjectiveLocale(
              'The review result will openly be shared to the reviewee'
            )}
          </p>
        </div>
      );
    } else if (deliverableValue && length >= 0) {
      return (
        <p
          className={`typography-paragraph ${
            length > 0 ? 'text-base-600 cursor-pointer' : 'text-n-900'
          }`}
          onClick={() => length > 0 && setPhaseType(phase)}
        >
          {getObjectiveLocale('Shared Tracks')} ({length})
        </p>
      );
    } else {
      return (
        <p className="typography-paragraph typography-tertiary">
          {getObjectiveLocale('Not an active phase')}
        </p>
      );
    }
  };

  let deliverableForRevieweeArray = [
    {
      name: 'Deliverable time',
      content: (
        <p className="typography-paragraph">
          {cycleData.inProgressDeliverable
            ? 'While cycle still running'
            : 'After cycle has ended'}
        </p>
      )
    },
    {
      name: getObjectiveLocale('Self'),
      content: deliverableContent('selfReview')
    },
    {
      name: getObjectiveLocale('Upward'),
      content: deliverableContent('upwardReview')
    },
    {
      name: getObjectiveLocale('Peer'),
      content: deliverableContent('peerReview')
    },
    {
      name: getObjectiveLocale('Manager'),
      content: deliverableContent('managerReview')
    },
    {
      name: getObjectiveLocale('Indirect Manager'),
      content: deliverableContent('indirectManagerReview')
    }
  ];

  let revieweeOption = {
    name: 'Reviewee Option',
    content: (
      <div className="flex flex-col">
        <p
          className="typography-paragraph text-base-600 cursor-pointer"
          onClick={() => setShowModalRevieweeOption(true)}
        >
          {getObjectiveLocale('Show selected option for each phase')}
        </p>
        <p className="typography-h100 typography-secondary">
          {getObjectiveLocale('Scoring Breakdown Summary View Options')}
        </p>
      </div>
    )
  };

  showRevieweeOption && deliverableForRevieweeArray.push(revieweeOption);

  useEffect(() => {
    const phases = [
      'selfReview',
      'upwardReview',
      'peerReview',
      'managerReview',
      'indirectManagerReview'
    ];

    phases.map((phase) => {
      if (cycleData[phase]) {
        cycleData[phase]?.deliverable &&
          cycleData[phase].trackConfigs.findIndex(
            (track) => track.type === 'summary'
          ) >= 0 &&
          setShowRevieweeOption(true);
      }
    });
  }, []);

  return (
    <>
      <VerifySection
        title="Deliverable for reviewee"
        dataArray={deliverableForRevieweeArray}
        className="py-[40px]"
      />
      {phaseType && (
        <ModalSharedTracks
          title={getObjectiveLocale(phaseName(phaseType))}
          trackConfigs={cycleData[phaseType]?.trackConfigs}
          onCloseModal={() => setPhaseType('')}
        />
      )}
      {showModalRevieweeOption && (
        <ModalRevieweeOption
          cycleData={cycleData}
          onCloseModal={() => setShowModalRevieweeOption(false)}
        />
      )}
    </>
  );
};

export default DeliverableRevieweeVerify;

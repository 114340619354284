import React, { createRef, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';

import flatten from 'lodash/flatten';

import { getListObjectives } from 'client/ObjectivesClient';
import useFilter from 'hooks/useFilter';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { formatTimezone } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Card from 'components/design-system/Card';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import ListEmptyState from 'components/shared/ListEmptyState';
import { restructureFilter } from 'src/utils/ObjectivesHelper';

import ObjectiveLabelCard from './ObjectiveLabelCard';

const LIMIT = 10;

const ObjectiveAnalyticContent = ({ name, objectives, setObjectives }) => {
  const filter = useFilter((state) => state.filterById?.objectiveAnalytics);

  const fetchObjectives = async (olderThan) => {
    const { year, ...rest } = filter;
    let restFilter = restructureFilter(rest);

    let allParams = {
      ...restFilter,
      reviewsVisibility: 1,
      limit: LIMIT,
      page: 'goal_analytic',
      tags: name,
      ...(olderThan && { olderThan: olderThan }),
      periodEndBefore: formatTimezone(`${year}-12-31`, 'end'),
      periodBegin: formatTimezone(`${year}-01-01`, 'start')
    };

    return getListObjectives(allParams);
  };

  const queryKey = ['objectives', 'label', name, filter];

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, isFetching } =
    useInfiniteQuery(queryKey, ({ pageParam }) => fetchObjectives(pageParam), {
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || undefined,
      staleTime: 5 * 60 * 1000
    });
  const lastPageData = data?.pages?.[data?.pages?.length - 1];

  const intersectTarget = createRef();
  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting &&
      hasNextPage &&
      lastPageData?.data?.length === LIMIT &&
      fetchNextPage(),
    threshold: 0.5
  });

  useEffect(() => {
    if (data) {
      const newObj = flatten(
        data?.pages?.map((page) => {
          if (page?.data) {
            return page?.data?.map((d) => {
              return d;
            });
          }
        })
      ).filter((data) => data);

      setObjectives(newObj);
    }
  }, [data, setObjectives]);

  return (
    <>
      {isFetching && !isFetchingNextPage ? (
        <ListEmptyState
          containerClassname="h-auto mt-[96px]"
          emptyIcon="icon-loading-page"
          withLoadingIndicator={isFetching}
          title={getObjectiveLocale('Loading your objective..')}
          subtitle={getObjectiveLocale(
            'Your objective list will be shown up here'
          )}
        />
      ) : objectives.length === 0 ? (
        <ListEmptyState
          title={getObjectiveLocale('No objective found')}
          fullHeight
          emptyIcon="icon-no-result-found"
          subtitle={getObjectiveLocale(
            'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
          )}
        />
      ) : (
        objectives?.map((obj, idx) => (
          <ObjectiveLabelCard
            obj={obj}
            key={obj?.id}
            isLast={idx == objectives?.length - 1 && objectives?.length > 2}
          />
        ))
      )}
      {isFetchingNextPage && (
        <Card customClass="!px-[16px] flex" useHorizontalPadding={false}>
          <div className="w-[288px] flex flex-col">
            <Shimmer width="168px" height="20px" customClass="mb-[16px]" />
            <Shimmer width="77px" height="16px" customClass="mb-[8px]" />
            <Shimmer width="160px" height="32px" customClass="mb-[8px]" />
            <Shimmer width="80px" height="24px" customClass="mb-[8px]" />
            <Shimmer width="120px" height="16px" customClass="mb-[16px]" />
            <Shimmer width="80px" height="20px" customClass="mb-[16px]" />
          </div>
          <div className="w-[calc(100%-288px)]">
            <Shimmer
              width="896px"
              height="18px"
              customClass="mb-[24px] mt-[40px]"
            />
            <Shimmer width="896px" height="18px" customClass="mb-[24px]" />
            <Shimmer width="896px" height="18px" customClass="mb-[24px]" />
          </div>
        </Card>
      )}
      {hasNextPage && !isFetchingNextPage && (
        <div className="h-[1px]" ref={intersectTarget}>
          &nbsp;
        </div>
      )}
    </>
  );
};

export default ObjectiveAnalyticContent;

import React, { useEffect, useState } from 'react';

import 'scss/culture/modal/modal-post-recognition';

import {
  getBehaviors,
  getPresignUrlCulture,
  submitPost
} from 'client/CultureClient';
import S3Client from 'client/S3Client';
import { useUser } from 'context/UserContext';
import { getCultureLocale } from 'utils/HelperUtils';

import ModalPostTemplate from 'components/modal/culture/ModalPostTemplate';
import { trackEvent } from 'src/utils/AnalyticUtils';

import ModalTaggedUser from './ModalTaggedUser';
import AdditionalFeedback from './components/AdditionalFeedback';
import ListDetailUser from './components/ListDetailUser';
import RecognitionSkill from './components/RecognitionSkill';

function ModalPostRecognition({ eventOnClick, groupDetail }) {
  const { config } = useUser();

  const [modalTitle, setModalTitle] = useState(
    getCultureLocale('Select Value')
  );
  const [buttonText, setButtonText] = useState(getCultureLocale('Next'));
  const [currentSection, setCurrentSection] = useState('taggedUser');
  const [recognitionUsers, setRecognitionUsers] = useState([]);
  const [recognitionValues, setRecognitionValues] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  const [caption, setCaption] = useState('');
  const [photo, setPhoto] = useState([]);
  const [isModalTaggedOpen, setIsModalTaggedOpen] = useState(true);
  const [isButtonShow, setIsButtonShow] = useState(false);
  const [isButtonModalDisabled, setIsButtonModalDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const getValues = async () => {
    setIsLoading(true);
    const userIds = recognitionUsers.map((user) => user?.id);
    const { data } = await getBehaviors({
      behaviorType: 'value',
      userIds: userIds,
      parentId: null
    });
    setRecognitionValues(data);
    setIsLoading(false);
  };

  const getSkill = (id, title) => {
    setSelectedValue({
      id: id,
      title: title
    });
    setIsButtonShow(true);
    setIsButtonModalDisabled(true);
    setModalTitle(getCultureLocale('AddSkills'));
    setButtonText(getCultureLocale('Next'));

    config.recognitionConfig.skills
      ? setCurrentSection('skills')
      : setCurrentSection('feedback');
  };

  const setFunction = () => {
    if (currentSection === 'skills') {
      return setCurrentSection('feedback');
    } else if (currentSection === 'feedback') {
      return postModal();
    }
  };

  const uploadToServer = async (url, file) => {
    const { status } = await S3Client(url, file);
    return status;
  };

  const postModal = async () => {
    setIsButtonModalDisabled(true);
    setIsSubmit(true);
    let requestBody = {
      post: {
        postType: 'recognition',
        groupId: groupDetail.id,
        recognitionAttributes: {
          caption: caption,
          userIds: recognitionUsers.map((user) => user.id),
          skillIds: selectedSkills.map((skill) => skill.id),
          valueIds: [selectedValue.id]
        }
      }
    };

    let currentAttachment = photo;

    if (currentAttachment.length > 0) {
      const promises = currentAttachment.map(async (value, index) => {
        const presignData = {
          files: [
            {
              fileType: 'image',
              fileFormat: photo[index].fileExtension
            }
          ]
        };
        const { data } = await getPresignUrlCulture(presignData);

        if (data) {
          currentAttachment[index].secureUrl = photo[index].src;

          const status = await uploadToServer(data[0].presignedUrl, value.file);
          if (status == 200) {
            requestBody.post.recognitionAttributes.imageUrl = data[0].secureUrl;
          }
        }
      });
      await Promise.all(promises);
    }

    const { data } = await submitPost(requestBody);

    if (data) {
      eventOnClick(true);
      const properties = {
        media: currentAttachment.length > 0 ? 'photo' : 'none',
        value: selectedValue.title,
        'number of skills': selectedSkills.length,
        'group name': groupDetail.name,
        'group type': groupDetail.groupType,
        'post from inside group': true,
        'selected users': recognitionUsers.length,
        'with caption': caption
      };

      trackEvent({
        event: 'give recognition',
        eventProperties: properties,
        env: 'culture'
      });
    }
    setIsSubmit(false);
  };

  const getFeedback = () => {
    setIsButtonShow(true);
    setIsButtonModalDisabled(true);
    setModalTitle(
      `${getCultureLocale('add').toUpperCase()} ${getCultureLocale(
        'feedback'
      ).toUpperCase()}`
    );
    setButtonText(getCultureLocale('Done'));
  };

  const deleteAllModal = () => {
    setIsModalTaggedOpen(false);
    eventOnClick();
  };

  const prevOnClick = () => {
    if (currentSection === 'values') {
      setCurrentSection('taggedusers');
      setIsModalTaggedOpen(true);
    } else if (currentSection === 'skills') {
      setCurrentSection('values');
      setModalTitle(getCultureLocale('Select Value'));
    } else if (currentSection === 'feedback') {
      setCurrentSection('skills');
      setModalTitle(getCultureLocale('AddSkills'));
    }
  };

  useEffect(() => {
    if (recognitionUsers.length > 0) {
      setCurrentSection('values');
    }
  }, [recognitionUsers]);

  useEffect(() => {
    if (currentSection === 'values') {
      getValues();
    } else if (currentSection === 'feedback') {
      getFeedback();
    }
  }, [currentSection]);

  return (
    <>
      {isModalTaggedOpen ? (
        <ModalTaggedUser
          isRecognition
          closeModal={deleteAllModal}
          setIsModalTaggedUserOpen={setIsModalTaggedOpen}
          groupId={groupDetail.id}
          setTaggedUsers={setRecognitionUsers}
          taggedUsers={recognitionUsers}
          maxReceiver={config.recognitionConfig.maxReceiver}
        />
      ) : (
        <ModalPostTemplate
          dataCy="modal-post-recognition"
          modalTitle={modalTitle}
          isButton={isButtonShow}
          isRecognition
          buttonText={buttonText}
          onClickButton={setFunction}
          isButtonDisabled={isButtonModalDisabled}
          eventOnClick={eventOnClick}
          className="modal-post-recognition"
          dataCyButton="next-share-button"
          isLoading={isLoading}
          isSubmit={isSubmit}
          isSecondaryButton
          secondaryButton={{
            buttonText: getCultureLocale('Back'),
            dataCy: 'button-prev-recog',
            onClick: () => prevOnClick()
          }}
        >
          {currentSection === 'values' && (
            <div className="value-list overflow-y">
              {recognitionValues.map((value) => {
                return (
                  <ListDetailUser
                    key={value.id}
                    type="value"
                    id={value.id}
                    title={value.title}
                    desc={value.description}
                    image={value.iconImage ? value.iconImage.secureUrl : null}
                    isSelected={value.isSelected}
                    onClickListDetail={(e) => {
                      e.stopPropagation();
                      getSkill(value.id, value.title);
                    }}
                    dataCy="value-item"
                  />
                );
              })}
            </div>
          )}
          {currentSection === 'skills' && (
            <RecognitionSkill
              selectedSkills={selectedSkills}
              setSelectedSkills={setSelectedSkills}
              setIsButtonModalDisabled={setIsButtonModalDisabled}
              setIsLoading={setIsLoading}
            />
          )}
          {currentSection === 'feedback' && (
            <AdditionalFeedback
              caption={caption}
              setCaption={setCaption}
              photo={photo}
              setPhoto={setPhoto}
              setIsButtonModalDisabled={setIsButtonModalDisabled}
            />
          )}
        </ModalPostTemplate>
      )}
    </>
  );
}

export default ModalPostRecognition;

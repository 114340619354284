import React from 'react';

import ctl from '@netlify/classnames-template-literals';

import RadioButton from 'components/shared/RadioButton';

const PollOption = ({ opt, onClick, currentAnswer, disabled }) => {
  const isSelected = opt?.id === currentAnswer?.id;

  const buttonCN = ctl(`
    flex align-start
    py-[12px] px-[16px] border-solid border-[1px]
    rounded-[4px] typography-h500 text-n-800 mb-[16px]
    ${
      isSelected && !disabled
        ? 'bg-base-30024 border-base-600'
        : 'bg-n-000 border-n-400'
    }
    ${
      disabled
        ? 'bg-n-200 text-n-700 border-n-400 cursor-default'
        : 'cursor-pointer'
    }
  `);

  return (
    <div onClick={onClick} data-cy="option" className={buttonCN}>
      <RadioButton checked={isSelected} addClass="mr-[16px]" />
      {opt?.option || opt?.answer}
    </div>
  );
};

export default PollOption;

import React from 'react';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ReviewDetail from 'components/objectives/ReviewDetail';
import SVGIcon from 'components/shared/SVGIcon';

const Review = ({ objectiveDetail }) => {
  const { config } = useUser();
  const { ratingMechanism } = config;
  const { scoringTemplate, reviews, measurement, weight } = objectiveDetail;

  return (
    <div className="px-[24px]">
      <div className="h-[48px] flex items-center">
        <SVGIcon
          iconName="icon-star_outline"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        {
          <p className="typography-paragraph">
            {getObjectiveLocale(
              ratingMechanism == 'progress_times_weight'
                ? 'Score'
                : 'Performance Review'
            )}
          </p>
        }
      </div>
      <div className="flex flex-col pl-[40px]">
        <ReviewDetail
          showOption={scoringTemplate.showOption}
          data={reviews}
          measurement={measurement}
          weight={weight}
        />
      </div>
    </div>
  );
};

export default Review;

import React, { Fragment, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SummaryAnnualReview from 'components/formal-review/annual-review/summary/SummaryAnnualReview';
import BehaviorAverageResult from 'components/formal-review/evidence-sidebar/BehaviorAverageResult';
import CardGeneralQuestionnaireSidebar from 'components/formal-review/evidence-sidebar/CardGeneralQuestionnaireSidebar';
import CardOverallInputScore from 'components/formal-review/evidence-sidebar/CardOverallInputScore';
import ScoreCard from 'components/formal-review/evidence-sidebar/ScoreCard.js';
import ResultHeader from 'components/formal-review/result/ResultHeader';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';

import GoalScoringViewOnly from '../GoalScoringViewOnly';
import CardBehaviorRecognitionSidebar from '../evidence-sidebar/CardBehaviorRecognitionSidebar';
import CardCompetenciesOrValuesScoring from '../evidence-sidebar/CardCompetenciesOrValuesScoring';
import CardEachOrWorkSidebar from '../evidence-sidebar/CardEachOrWorkSidebar';
import CardReviewAspectsScoringItem from '../evidence-sidebar/CardReviewAspectsScoringItem';
import CardSuggestedOngoingSidebar from '../evidence-sidebar/CardSuggestedOngoingSidebar';
import './ResultContent.scss';

function ResultContent({
  cycleDetail,
  currentPhase,
  currentPhaseId,
  currentAssignment,
  currentTrack,
  currentTrackConfigId,
  trackTemplate,
  isLoading,
  answersData,
  questionnaireData,
  cycleId,
  targetId,
  showCalibrationHistories = false,
  isAverageResult,
  trackConfig
}) {
  const [sectionsBefore, setSectionsBefore] = useState([]);
  const [sectionsAfter, setSectionsAfter] = useState([]);

  const mechanism = answersData?.scoringMechanism;
  let contentAnswers = null;
  let isEmptyContentAnswers = false;
  let isEmptyAllAnswers = false;

  const isEmptyRecognitionFeedbackAnswers = () => {
    return (
      isEmpty(contentAnswers?.recognition) && isEmpty(contentAnswers?.feedback)
    );
  };

  const getGoalsScoringContentAnswers = () => {
    if (mechanism == 'suggested_ongoing') {
      return answersData?.performanceScoringData;
    } else if (mechanism == 'overall_score') {
      return answersData?.answers;
    } else {
      return answersData?.answersData;
    }
  };

  switch (currentTrack) {
    case 'goals_scoring':
    case 'tasks_scoring':
      contentAnswers = getGoalsScoringContentAnswers();
      isEmptyContentAnswers = isEmpty(contentAnswers);
      break;
    case 'competencies_scoring':
    case 'values_scoring':
      contentAnswers = answersData?.answersData;
      isEmptyContentAnswers = isEmpty(contentAnswers);
      break;
    case 'competencies_recognition_feedback':
    case 'values_recognition_feedback':
      contentAnswers = answersData;
      isEmptyContentAnswers = isEmptyRecognitionFeedbackAnswers();
      break;
    case 'review_aspect_scorings':
      contentAnswers = contentAnswers?.[0]?.answers;
      isEmptyContentAnswers = isEmpty(contentAnswers);
      break;
    default:
      contentAnswers = answersData;
      isEmptyContentAnswers = isEmpty(contentAnswers);
      break;
  }
  isEmptyAllAnswers = isEmptyContentAnswers && isEmpty(questionnaireData);

  useEffect(() => {
    let tempSectionsBefore = [];
    let tempSectionsAfter = [];
    if (questionnaireData?.length > 0) {
      tempSectionsBefore = questionnaireData?.filter(
        (section) => section?.position == 'before'
      );
      tempSectionsAfter = questionnaireData?.filter(
        (section) => section?.position == 'after'
      );
    }
    setSectionsBefore(tempSectionsBefore);
    setSectionsAfter(tempSectionsAfter);
  }, [questionnaireData]);

  return (
    <div className="w-full overflow-hidden hover:overflow-auto hover:overflow-overlay">
      {!currentAssignment && (
        <ListEmptyState
          emptyIcon="icon-no-cycles"
          title={getObjectiveLocale('Review Result')}
          subtitle={getObjectiveLocale(
            'Sorry, you cannot access this page because the reviewer has not completed the assignment.'
          )}
          fullHeight={true}
        />
      )}

      {currentAssignment && (
        <div className="pt-[52px]">
          <ResultHeader
            currentPhase={currentPhase}
            user={currentAssignment?.target || {}}
            currentTrack={currentTrack}
            cycleDetail={cycleDetail}
          />
          {isLoading && <LoadingComponent />}
          {!isLoading && (
            <Fragment>
              <div className="result-content pb-[40px]">
                {currentTrack != 'score_card' &&
                  sectionsBefore?.length > 0 &&
                  trackTemplate?.questionsCount > 0 && (
                    <CardGeneralQuestionnaireSidebar
                      questionnaireData={sectionsBefore}
                      trackType={currentTrack}
                      position="before"
                    />
                  )}

                {!isEmptyContentAnswers &&
                  currentTrack == 'goals_scoring' &&
                  (mechanism == 'each' || mechanism == 'input_score') && (
                    <CardEachOrWorkSidebar
                      id={mechanism}
                      showOption={answersData?.showOption}
                      totalScore={answersData?.totalScore}
                      answersData={contentAnswers}
                      view={answersData?.view}
                    />
                  )}

                {!isEmptyContentAnswers &&
                  (currentTrack == 'goals_scoring' ||
                    currentTrack == 'tasks_scoring') &&
                  mechanism == 'specific_attribute' && (
                    <CardEachOrWorkSidebar
                      id="work"
                      showOption={answersData?.showOption}
                      totalScore={answersData?.totalScore}
                      answersData={contentAnswers}
                      view={answersData?.view}
                    />
                  )}

                {!isEmptyContentAnswers &&
                  (currentTrack == 'goals_scoring' ||
                    currentTrack == 'tasks_scoring') &&
                  mechanism?.includes('suggested_ongoing') && (
                    <CardSuggestedOngoingSidebar
                      showOption={answersData?.showOption}
                      answersData={contentAnswers}
                      trackType={currentTrack}
                    />
                  )}

                {!isEmptyContentAnswers &&
                  (currentTrack === 'competencies_recognition_feedback' ||
                    currentTrack == 'values_recognition_feedback') && (
                    <CardBehaviorRecognitionSidebar
                      answersData={contentAnswers}
                    />
                  )}

                {!isEmptyContentAnswers &&
                  currentTrack == 'goals_scoring' &&
                  mechanism == 'overall_score' && (
                    <CardOverallInputScore
                      showOption={answersData?.showOption}
                      totalScore={answersData?.totalScore}
                      components={answersData?.components}
                      answersData={contentAnswers}
                      view={answersData?.view}
                    />
                  )}

                {!isEmptyContentAnswers &&
                  (currentTrack == 'competencies_scoring' ||
                    currentTrack == 'values_scoring') &&
                  (isAverageResult ? (
                    <BehaviorAverageResult answersData={answersData} />
                  ) : (
                    <CardCompetenciesOrValuesScoring
                      totalScore={answersData.totalScore}
                      answersData={contentAnswers}
                      trackType={currentTrack}
                      showOption={answersData?.showOption}
                      showSlider={currentTrack == 'review_aspects_scoring'}
                      isReviewResultPage
                    />
                  ))}

                {!isEmptyContentAnswers &&
                  currentTrack === 'review_aspects_scoring' && (
                    <CardReviewAspectsScoringItem
                      cycleDetail={cycleDetail}
                      currentTrackConfigId={currentTrackConfigId}
                      trackTemplate={trackTemplate}
                      trackType={currentTrack}
                      targetId={targetId}
                      showObjectives={true} // should not show objectives on evidence sidebar
                      answersData={contentAnswers}
                      reviewAspects={trackTemplate?.reviewAspects}
                    />
                  )}

                {!isEmptyContentAnswers && currentTrack === 'summary' && (
                  <SummaryAnnualReview
                    user={currentAssignment?.target || {}}
                    answersData={contentAnswers}
                    phase={currentPhase}
                    trackTemplate={trackTemplate}
                    trackConfig={trackConfig}
                  />
                )}

                {currentTrack === 'goals_scoring' &&
                  mechanism === 'view_only' && (
                    <GoalScoringViewOnly
                      hideFinalizeSection
                      isReviewResult
                      isAbleToOpenSidebar={false}
                      trackType={currentTrack}
                      currentAssignment={currentAssignment}
                      cycleDetail={cycleDetail}
                    />
                  )}

                {isEmptyAllAnswers &&
                  currentTrack !== 'score_card' &&
                  mechanism !== 'view_only' && (
                    <ListEmptyState
                      title="Results are coming soon"
                      subtitle="When your reviewer have submitted their responses, you will see it here"
                      containerClassname="mt-[64px]"
                    />
                  )}

                {currentTrack === 'score_card' && (
                  <ScoreCard
                    cycleId={cycleId}
                    currentPhaseId={currentPhaseId}
                    currentPhase={currentPhase}
                    targetId={targetId}
                    targetObj={currentAssignment?.target || {}}
                    showCalibrationHistories={showCalibrationHistories}
                  />
                )}
                {currentTrack != 'score_card' &&
                  sectionsAfter?.length > 0 &&
                  trackTemplate?.questionsCount > 0 && (
                    <CardGeneralQuestionnaireSidebar
                      questionnaireData={sectionsAfter}
                      trackType={currentTrack}
                      position="after"
                    />
                  )}
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
}

export default ResultContent;

const listSelectionColors = [
  {
    displayColorClass: 'bg-n-500',
    colorHex: '475569',
    bgColorHex: 'F1F5F9'
  },
  {
    displayColorClass: 'bg-g-500',
    colorHex: '14532D',
    bgColorHex: 'DCFCE7'
  },
  {
    displayColorClass: 'bg-y-500',
    colorHex: '713F12',
    bgColorHex: 'FEF9C3'
  },
  {
    displayColorClass: 'bg-b-500',
    colorHex: '1E3A8A',
    bgColorHex: 'DBEAFE'
  },
  {
    displayColorClass: 'bg-r-500',
    colorHex: '7F1D1D',
    bgColorHex: 'FEE2E2'
  },
  {
    displayColorClass: 'bg-v-500',
    colorHex: '230264',
    bgColorHex: 'EDE9FE'
  },
  {
    displayColorClass: 'bg-p-500',
    colorHex: '831843',
    bgColorHex: 'FCE7F3'
  }
];

export { listSelectionColors };

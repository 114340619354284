import React from 'react';

import Config from 'utils/Config';

import Avatar from 'components/shared/Avatar';

function ReviewSourceComponent(props) {
  const { name, team, value, comment, profilePicture, cycle, type } = props;
  return (
    <div className="on-going-review">
      <Avatar
        src={profilePicture}
        name={name}
        size={40}
        alt="Profile Picture"
      />
      <div className="detail">
        <span className="name">{name}</span>
        {team && (
          <span className="job">
            {team.actor_role === 'member'
              ? 'Team Member'
              : team.actor_role === 'leader'
              ? 'Team Leader'
              : 'Team Reviewer'}
          </span>
        )}
        <span className="type">
          {type.endsWith('value') ? 'Standard of Leadership' : 'Skill'}
        </span>
        <div className="quarter-time">
          <span className="info">on {cycle} : </span>
          {team && <span className="team">{team.name}</span>}
        </div>
        <div
          className={
            type.startsWith('recognition') ? 'skill strength' : 'skill'
          }
        >
          <span className="text">{value}</span>
        </div>
        {comment !== null && <span className="comment">{comment}</span>}
      </div>
    </div>
  );
}

export default ReviewSourceComponent;

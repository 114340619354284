import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { getConfig } from 'client/admin/Config';
import useOrganization from 'hooks/useOrganization';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ContentNavigator from 'components/shared/ContentNavigator';

import {
  CardInputOrganization,
  CheckBoxOrganization,
  CheckboxWithDefaultOrganization,
  DropdownOrganization,
  ToggleCard,
  changeCheckboxData,
  changeUserData,
  generalFilterandCycleDetailOptionCheckbox,
  setActiveNavigator
} from './OrganizationHelper';

const FormalReview = ({ configParams }) => {
  const { configData, changeConfigData, changeOriginConfigData } =
    useOrganization((configData) => ({
      configData: configData.configData,
      changeConfigData: configData.changeConfigData,
      changeOriginConfigData: configData.changeOriginConfigData
    }));
  const [activeSection, setActiveSection] = useState('list-section');
  const isReadOnly =
    usePermission('settingsOrganizationPerformanceReview') === 'read_only';
  const additionalListFilter = [
    { id: 'show', title: 'Show' },
    { id: 'score', title: 'Score' },
    { id: 'status', title: 'Status' },
    { id: 'user-state', title: 'User State' }
  ];

  const listFormalReview = [
    {
      element: 'dropdown',
      label: getObjectiveLocale('Sorting Column Cycles'),
      onChange: (id) =>
        changeUserData(
          'sortColumn',
          'cyclesSorting',
          id,
          configData,
          changeConfigData
        ),
      selected: configData?.cyclesSorting?.sortColumn,
      isSingleSelect: true,
      dataCy: 'sort-column-cycles',
      listOption: [
        { id: 'id', title: 'ID' },
        { id: 'reviewed_period_starts_at', title: 'Reviewed period starts at' },
        { id: 'reviewed_period_ends_at', title: 'Reviewed period ends at' },
        { id: 'created_at', title: 'Created at' },
        { id: 'updated_at', title: 'Updated at' },
        { id: 'name', title: 'Name' },
        { id: 'state', title: 'State' },
        { id: 'creator_id', title: 'Creator ID' },
        { id: 'review_process_starts_at', title: 'Review process starts at' },
        { id: 'review_process_ends_at', title: 'Review process ends at' }
      ],
      disabled: isReadOnly
    },
    {
      element: 'dropdown',
      label: getObjectiveLocale('Sorting Direction Cycles'),
      onChange: (id) =>
        changeUserData(
          'sortDirection',
          'cyclesSorting',
          id,
          configData,
          changeConfigData
        ),
      selected: configData?.cyclesSorting?.sortDirection,
      isSingleSelect: true,
      dataCy: 'sort-direction-cycles',
      listOption: [
        { id: 'asc', title: 'Ascending' },
        { id: 'desc', title: 'Descending' }
      ],
      disabled: isReadOnly
    }
  ];

  const listTrack = [
    {
      element: 'card-input',
      cardLabel: 'Goal Scoring',
      label: 'Default Goal Rating Formula',
      placeholder:
        'Formula perhitungan score individual di profile (hanya berlaku jika goal driven)',
      handleChange: (e) =>
        changeConfigData({
          ...configData,
          defaultGoalFormula: e.currentTarget.value
        }),
      value: configData?.defaultGoalFormula,
      dataCy: 'defaultGoalFormula',
      disabled: true
    },
    {
      element: 'card-input',
      cardLabel: 'Task Scoring',
      label: 'Default Task Rating Formula',
      placeholder:
        'Formula perhitungan score individual di profile (hanya berlaku jika task driven)',
      handleChange: (e) =>
        changeConfigData({
          ...configData,
          defaultTaskFormula: e.currentTarget.value
        }),
      value: configData?.defaultTaskFormula,
      dataCy: 'defaultTaskFormula',
      disabled: true
    }
  ];

  const handleCheckConfigData = (
    data,
    keyData,
    keyDefault,
    phaseData,
    defaultData
  ) => {
    phaseData.push(data);
    defaultData.length == 0 && defaultData.push(data);
    changeConfigData({
      ...configData,
      [keyData]: phaseData,
      [keyDefault]: defaultData
    });
  };

  const handleUncheckConfigData = (
    data,
    keyData,
    keyDefault,
    phaseData,
    defaultData,
    isSingleSelected
  ) => {
    const index = phaseData.indexOf(data);
    const defaultIndex = defaultData.indexOf(data);
    index > -1 && phaseData.splice(index, 1);
    defaultIndex > -1 && defaultData.splice(defaultIndex, 1);
    if (defaultData.length == 0 && isSingleSelected) {
      phaseData[0] && defaultData.push(phaseData[0]);
    }
    changeConfigData({
      ...configData,
      [keyData]: phaseData,
      [keyDefault]: defaultData
    });
  };

  const changeConfigAndDefaultData = (
    checked,
    data,
    keyData,
    keyDefault,
    isSingleSelected
  ) => {
    const phaseData = cloneDeep(configData?.[keyData]);
    const defaultData = cloneDeep(configData?.[keyDefault]);
    if (checked) {
      handleCheckConfigData(data, keyData, keyDefault, phaseData, defaultData);
    } else {
      handleUncheckConfigData(
        data,
        keyData,
        keyDefault,
        phaseData,
        defaultData,
        isSingleSelected
      );
    }
  };

  const listFilter = [
    {
      element: 'checkbox',
      section: 'performance',
      title: getObjectiveLocale('Time Period'),
      withRadioButton: true,
      headerNo: getObjectiveLocale('Allowed Filter Status'),
      headerName: getObjectiveLocale('Default'),
      handleChange: (checked, data) =>
        changeConfigAndDefaultData(
          checked,
          data,
          'cyclesTimeFilterOptions',
          'defaultCyclesTimeFilter',
          true
        ),
      selected: configData?.cyclesTimeFilterOptions,
      dataCy: 'cyclesTimeFilterOptions',
      listOption: [
        { id: 'today', title: 'Today' },
        { id: 'all_time', title: 'All Time' },
        { id: 'annual', title: 'Annual' },
        { id: 'last_year', title: 'Previous Year' },
        { id: 'quarter', title: 'Q1 - Q4 of this year' },
        { id: 'custom', title: 'Custom' }
      ],
      disabled: isReadOnly,
      handleChangeDefault: (data) =>
        changeConfigData({ ...configData, defaultCyclesTimeFilter: [data] }),
      selectedDefault: configData?.defaultCyclesTimeFilter || [],
      handleChangeDefaultToggle: (data) =>
        changeCheckboxData(
          data,
          configData?.defaultCyclesTimeFilter,
          'defaultCyclesTimeFilter',
          null,
          configData,
          changeConfigData
        )
    },
    {
      element: 'checkbox',
      section: 'performance',
      title: getObjectiveLocale('Cycle Status'),
      headerNo: getObjectiveLocale('Allowed Filter Status'),
      headerName: getObjectiveLocale('Default'),
      handleChange: (checked, data) =>
        changeConfigAndDefaultData(
          checked,
          data,
          'endUserCyclesFilter',
          'defaultEndUserCyclesFilter'
        ),
      selected: configData?.endUserCyclesFilter,
      dataCy: 'endUserCyclesFilter',
      listOption: [
        { id: 'active', title: 'Active' },
        { id: 'ended', title: 'Ended' }
      ],
      disabled: isReadOnly,
      handleChangeDefault: (data) =>
        changeConfigData({ ...configData, endUserCyclesFilter: [data] }),
      selectedDefault: configData?.defaultEndUserCyclesFilter || [],
      handleChangeDefaultToggle: (data) =>
        changeCheckboxData(
          data,
          configData?.defaultEndUserCyclesFilter,
          'defaultEndUserCyclesFilter',
          null,
          configData,
          changeConfigData
        )
    },
    {
      element: 'checkbox',
      section: 'performance',
      title: getObjectiveLocale('Phase Status'),
      headerNo: getObjectiveLocale('Allowed Filter Status'),
      headerName: getObjectiveLocale('Default'),
      handleChange: (checked, data) =>
        changeConfigAndDefaultData(
          checked,
          data,
          'phasesStatusFilter',
          'defaultPhasesStatusFilter'
        ),
      selected: configData?.phasesStatusFilter,
      dataCy: 'phasesStatusFilter',
      listOption: [
        { id: 'in_progress', title: 'In Progress' },
        { id: 'done', title: 'Done' }
      ],
      disabled: isReadOnly,
      handleChangeDefault: (data) =>
        changeConfigData({ ...configData, defaultPhasesStatusFilter: [data] }),
      selectedDefault: configData?.defaultPhasesStatusFilter || [],
      handleChangeDefaultToggle: (data) =>
        changeCheckboxData(
          data,
          configData?.defaultPhasesStatusFilter,
          'defaultPhasesStatusFilter',
          null,
          configData,
          changeConfigData
        )
    },
    {
      element: 'checkbox',
      section: 'performance',
      title: getObjectiveLocale('Assigment Status'),
      headerNo: getObjectiveLocale('Allowed Filter Status'),
      headerName: getObjectiveLocale('Default'),
      handleChange: (checked, data) =>
        changeConfigAndDefaultData(
          checked,
          data,
          'assignmentsStatusFilter',
          'defaultAssignmentsStatusFilter'
        ),
      selected: configData?.assignmentsStatusFilter,
      dataCy: 'assignmentsStatusFilter',
      listOption: [
        { id: 'in_progress', title: 'In Progress' },
        { id: 'done', title: 'Done' },
        { id: 'incomplete', title: 'Incomplete' }
      ],
      disabled: isReadOnly,
      handleChangeDefault: (data) =>
        changeConfigData({
          ...configData,
          defaultAssignmentsStatusFilter: [data]
        }),
      selectedDefault: configData?.defaultAssignmentsStatusFilter || [],
      handleChangeDefaultToggle: (data) =>
        changeCheckboxData(
          data,
          configData?.defaultAssignmentsStatusFilter,
          'defaultAssignmentsStatusFilter',
          null,
          configData,
          changeConfigData
        )
    }
  ];

  const listCycle = [
    {
      element: 'checkboxOrganization',
      label: getObjectiveLocale('Cycle Detail Filter Options'),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.cycleDetailFilterOptions,
          'cycleDetailFilterOptions',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.cycleDetailFilterOptions,
      isSingleSelect: true,
      dataCy: 'cycleDetailFilterOption',
      listOption: [
        ...generalFilterandCycleDetailOptionCheckbox,
        ...additionalListFilter
      ],
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Cycle notify on end'),
      cardDescription: getObjectiveLocale(
        'Allow to change cycle notify on end'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          cycleNotifyOnEnd: !configData.cycleNotifyOnEnd
        }),
      isChecked: configData?.cycleNotifyOnEnd,
      dataCy: 'cycleNotifyOnEnd',
      disabled: isReadOnly
    }
  ];

  const listPhase = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Phase notify on start'),
      cardDescription: getObjectiveLocale(
        'Allow to change phase notify on start'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          phaseNotifyOnStart: !configData.phaseNotifyOnStart
        }),
      isChecked: configData?.phaseNotifyOnStart,
      dataCy: 'phaseNotifyOnStart',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Enable Reopen Dependency'),
      cardDescription: getObjectiveLocale(
        'Enable admin to reopen assignment that has dependency'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          formalReviewAssignmentDependantReopen:
            !configData.formalReviewAssignmentDependantReopen
        }),
      isChecked: configData?.formalReviewAssignmentDependantReopen,
      dataCy: 'reopen-dependency',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Enable Multiple Position on View Result'),
      cardDescription: getObjectiveLocale(
        'Enable multiple postition on performance review view result and download pdf'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          viewResultPerPlacement: !configData.viewResultPerPlacement
        }),
      isChecked: configData?.viewResultPerPlacement,
      dataCy: 'view-result-per-placement',
      disabled: isReadOnly
    }
  ];

  const listReminder = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Formal review reminder'),
      cardDescription: getObjectiveLocale('Allow to send reminder'),
      onChange: () =>
        changeConfigData({
          ...configData,
          formalReviewReminder: !configData.formalReviewReminder
        }),
      isChecked: configData?.formalReviewReminder,
      dataCy: 'formalReviewReminder',
      disabled: isReadOnly
    }
  ];

  const listRating = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Show Range on Rating Definition'),
      cardDescription: getObjectiveLocale(
        'Show score range of each rating on Rating Definition modal in review assignment'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          showRatingRange: !configData.showRatingRange
        }),
      isChecked: configData?.showRatingRange,
      dataCy: 'show-range-rating',
      disabled: isReadOnly
    }
  ];

  let list = [
    { id: 'list-section', name: 'List', list: listFormalReview },
    { id: 'filter-section', name: 'Filter', list: listFilter },
    { id: 'cycle-section', name: 'Cycle', list: listCycle },
    { id: 'phase-section', name: 'Phase', list: listPhase },
    { id: 'track-section', name: 'Track', list: listTrack },
    { id: 'reminder-section', name: 'Reminder', list: listReminder },
    { id: 'rating-section', name: 'Rating Template', list: listRating }
  ];

  const getData = async () => {
    const { data } = await getConfig(configParams);
    if (data) {
      changeConfigData(data);
      changeOriginConfigData(data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="overflow-auto h-[calc(100vh-64px)] w-[87%] relative"
      id="scrollable-config"
      onScroll={(e) => setActiveNavigator(e, list, setActiveSection, -48)}
    >
      <div className="w-[768px] mt-[24px]">
        <div className="fixed right-0 mt-[24px] w-[160px] z-index-10">
          <ContentNavigator
            title="CONTENTS"
            activeSection={activeSection}
            listNavigatorContents={list}
            additionalOffset={20}
            sectionContent="scrollable-config"
          />
        </div>
        <p className="typography-h300 text-n-800 uppercase mb-[24px]">
          {getObjectiveLocale('Formal Review Configuration')}
        </p>
        {list.map((el, index) => (
          <section
            className={`${
              el?.id === 'phase-section'
                ? 'pb-[40px] mb-[40px] mt-[24px] pb-[64px]'
                : 'border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]'
            }`}
            id={`${el?.id}`}
            key={index}
          >
            <p className="typography-h500 text-n-900 mb-[24px]">
              {getObjectiveLocale(el.name)}
            </p>
            {el.list?.length > 0 &&
              el.list.map((config, idx) => {
                return config?.element === 'checkbox' ? (
                  <CheckboxWithDefaultOrganization
                    {...config}
                    key={`${index}-${idx}`}
                  />
                ) : config?.element === 'checkboxOrganization' ? (
                  <CheckBoxOrganization {...config} key={`${index}-${idx}`} />
                ) : config?.element === 'dropdown' ? (
                  <DropdownOrganization {...config} key={`${index}-${idx}`} />
                ) : config?.element === 'card-input' ? (
                  <CardInputOrganization {...config} key={`${index}-${idx}`} />
                ) : (
                  <ToggleCard {...config} key={`${index}-${idx}`} />
                );
              })}
          </section>
        ))}
      </div>
    </div>
  );
};

export default FormalReview;

import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidV4 } from 'uuid';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale, setDropdownOptions } from 'utils/HelperUtils';

import RatingOptionView from 'components/admin/track-template/RatingOptionView';
import Button from 'components/design-system/Button';
import Card from 'components/design-system/Card';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import BannerBox from 'components/shared/BannerBox';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

import CommentSection from '../CommentSection';

const reducer = (state, action) => {
  switch (action.type) {
    case 'update_attribute':
      return { ...state, selectedAttribute: action.attribute };
    case 'update_categories':
      return { ...state, selectedCategories: action.categories };
    case 'update_template':
      return { ...state, selectedTemplate: action.template };
    case 'update_allow_override':
      return { ...state, allowOverride: action.allowOverride };
    case 'update_show_option':
      return { ...state, showOption: action.showOption };
    case 'update_use_comment':
      return { ...state, useComment: action.useComment };
    case 'update_current_mechanism':
      return { ...state, currentMechanism: action.mechanism };
  }
};

const CustomAttributesCard = ({
  customAttributes,
  templateData,
  setTemplateData,
  dataAttributes,
  listRatingTemplate,
  index,
  categories
}) => {
  const match = useRouteMatch();
  let currentTrackType = match.params.trackType;

  const [state, dispatch] = useReducer(reducer, {
    uniqueId: dataAttributes?.uniqueId || uuidV4(),
    selectedAttribute: dataAttributes
      ? { id: dataAttributes.id, name: dataAttributes.name }
      : null,
    selectedCategories: categories?.filter((category) => {
      return dataAttributes?.objectiveCategoryIds.includes(category.id);
    }),
    selectedTemplate: listRatingTemplate?.find((template) => {
      return template.id == dataAttributes?.ratingTemplateId;
    }),
    allowOverride: dataAttributes?.allowOverride,
    showOption: {
      config: {
        view: dataAttributes?.view,
        sliderLabel: dataAttributes?.sliderLabel
      }
    },
    useComment: dataAttributes?.useComment,
    currentMechanism: dataAttributes?.mechanism
  });

  const [attributesOptions, setAttributesOptions] = useState([]);
  const [initialSetup, setInitialSetup] = useState(false);

  const {
    config: { objectiveWeightType }
  } = useUser();

  const {
    selectedAttribute,
    selectedCategories,
    selectedTemplate,
    allowOverride,
    showOption,
    useComment,
    currentMechanism
  } = state;

  const deleteCurrentAttribute = () => {
    const allAttributes = templateData?.config?.reviewAspects;
    allAttributes?.splice(index, 1);

    const newConfig = {
      ...templateData.config,
      reviewAspects: allAttributes
    };

    setTemplateData({ ...templateData, config: newConfig });
  };

  const handleSelectCheckbox = (checked, value) => {
    if (checked) {
      const newSelectedCategories = [...selectedCategories];
      newSelectedCategories.push({ ...value });
      dispatch({
        type: 'update_categories',
        categories: newSelectedCategories
      });
    } else {
      const newSelectedCategories = [...selectedCategories]?.filter(
        (selected) => {
          return selected.id != value.id;
        }
      );

      dispatch({
        type: 'update_categories',
        categories: newSelectedCategories
      });
    }
  };

  const handleSelectTemplate = (e, value) => {
    e?.stopPropagation();
    dispatch({ type: 'update_template', template: value });
  };

  const getAvailableAttributes = useCallback(() => {
    const selectedIds = templateData.config?.reviewAspects?.map((aspect) => {
      return aspect.id;
    });
    const filteredAttributes = customAttributes?.filter((attributes) => {
      return !selectedIds.includes(attributes.id);
    });

    return filteredAttributes;
  }, [customAttributes, templateData]);

  useEffect(() => {
    if (initialSetup) {
      const destructureConfigFromSelected = () => {
        return {
          uniqueId: state.uniqueId,
          id: state.selectedAttribute?.id,
          name: state.selectedAttribute?.name,
          allowOverride: state.allowOverride,
          objectiveCategoryIds:
            state.selectedCategories?.map((category) => {
              return category.id;
            }) || [],
          ratingTemplateId: state.selectedTemplate?.id,
          mechanism: state.currentMechanism,
          useComment: state.useComment,
          view: state.showOption?.config?.view,
          sliderLabel: state.showOption?.config?.sliderLabel
        };
      };

      setTemplateData((prevValue) => {
        const newConfig = cloneDeep(prevValue.config);
        newConfig.reviewAspects[index] = destructureConfigFromSelected();
        return { ...prevValue, config: newConfig };
      });
    }
  }, [state, initialSetup, index, setTemplateData]);

  useEffect(() => {
    setInitialSetup(true);
  }, []);

  useEffect(() => {
    setAttributesOptions(
      setDropdownOptions(
        getAvailableAttributes(),
        'name',
        (val) => dispatch({ type: 'update_attribute', attribute: val }),
        null,
        null,
        selectedAttribute
      )
    );
  }, [customAttributes, selectedAttribute, getAvailableAttributes]);

  return (
    <Card customClass="mb-[24px]">
      <div className="flex justify-between mb-[24px]">
        <div className="flex flex-col">
          <p className="typography-h400 text-n-900 mb-[4px]">
            {getObjectiveLocale('Custom attribute')}
          </p>
          <p className="typography-paragraph text-n-800">
            {getObjectiveLocale(
              'Select which attribute to be used from the list of active custom final score attributes.'
            )}
          </p>
        </div>
        <Button.Secondary
          datacy="add-custom-attributes"
          onClick={() => deleteCurrentAttribute()}
          customClass="h-[36px]"
        >
          <SVGIcon
            size="24"
            fillColor="var(--n-600)"
            iconName="icon-delete"
            customClass="mr-[4px]"
          />
          {getObjectiveLocale('Delete')}
        </Button.Secondary>
      </div>
      <div className="flex">
        <div className="flex flex-col mr-[32px]">
          <p className="uppercase typography-h300 text-n-800">
            {getObjectiveLocale('Attribute')}
          </p>
          <Dropdown customClass="w-[224px]">
            <Dropdown.Trigger dataCy="dropdown-select-attribute">
              <Trigger
                customWrapperClass="w-[224px]"
                textStyle={{ marginBottom: '0' }}
                topLabel={false}
                label={false}
                icon={false}
                text={selectedAttribute?.name}
              />
            </Dropdown.Trigger>
            <Dropdown.MenuItems
              options={attributesOptions}
              customClass="w-[224px]"
              maxHeight={150}
              dataCy="select-attribute"
            />
          </Dropdown>
        </div>
        <div className="flex flex-col">
          <p className="uppercase typography-h300 text-n-800">
            {getObjectiveLocale('Rating template')}
          </p>
          <Dropdown customClass="w-full">
            <Dropdown.Trigger customClass="w-full">
              <div
                className="h-[36px] rounded-[3px] border border-solid border-n-400 bg-n-000 text-n-900 py-[8px] px-[16px] pr-[40px] relative flex items-center w-full cursor-pointer"
                data-cy="rating-template-dropdown"
              >
                {getObjectiveLocale(
                  selectedTemplate?.name
                    ? selectedTemplate?.name
                    : 'Choose rating template for this track'
                )}
                <SVGIcon
                  size="24"
                  iconName="icon-unfold_more"
                  fillColor="var(--n-700)"
                  customClass="absolute"
                  customStyle={{ top: '4px', right: '8px' }}
                />
              </div>
            </Dropdown.Trigger>
            <Dropdown.MenuItems customClass="w-full max-h-[280px] overflow-y-auto">
              {listRatingTemplate.map((value, index) => (
                <Dropdown.MenuItem
                  onClick={(e) => handleSelectTemplate(e, value)}
                  dataCy={`rating-template-item-${index}`}
                  key={index}
                >
                  {value.name}
                </Dropdown.MenuItem>
              ))}
            </Dropdown.MenuItems>
          </Dropdown>
        </div>
      </div>
      <div className="flex justify-between mt-[16px] items-center">
        <div className="flex flex-col">
          <p className="typography-h400 text-n-900 mb-[4px]">
            {getObjectiveLocale('Relation')}
          </p>
          <p className="typography-paragraph text-n-800 mb-[0px]">
            {getObjectiveLocale('Choose to add relation to certain goal type')}
          </p>
        </div>
        <SmallToggleSwitchPurple
          idStr="toggle-relation"
          isChecked={currentMechanism === 'weight_type'}
          onChange={() =>
            dispatch({
              type: 'update_current_mechanism',
              mechanism:
                currentMechanism === 'weight_type'
                  ? 'selected_aspect'
                  : 'weight_type'
            })
          }
        />
      </div>

      {currentMechanism === 'weight_type' && objectiveWeightType == 'type' && (
        <>
          <div className="mb-[16px] mt-[24px]">
            {selectedCategories.length == 0 && (
              <BannerBox
                text={getObjectiveLocale(
                  'Please select at least one goal type'
                )}
                type="info"
              />
            )}
          </div>
          <div className="mb-[16px]">
            {categories.map((category, idx) => {
              return (
                <div key={idx} className="flex items-center w-full">
                  <Checkbox
                    id={`category-${index}-${category.id}`}
                    name={`category-${index}-${category.id}`}
                    checked={selectedCategories.some(
                      (selected) => selected.id == category.id
                    )}
                    onChange={(e) =>
                      handleSelectCheckbox(e.target.checked, category)
                    }
                    labelClass="cursor-pointer w-full"
                    customContainerClass="w-full"
                    dataCy={`checkbox-${category.id}`}
                  >
                    <span className="ml-[8px] typography-paragraph text-n-900">
                      {category.name}
                    </span>
                  </Checkbox>
                </div>
              );
            })}
          </div>
        </>
      )}

      <RatingOptionView
        currentTrackType={currentTrackType}
        templateData={showOption}
        setTemplateData={(data) => {
          dispatch({ type: 'update_show_option', showOption: data });
        }}
        customTitleClass="typography-h400"
        customWrapperClass="!py-[16px]"
      />

      {currentMechanism === 'weight_type' && (
        <div className="flex justify-between mt-[16px] items-center">
          <div className="flex flex-col">
            <p className="typography-h400 text-n-900 mb-[4px]">
              {getObjectiveLocale('Override Score')}
            </p>
            <p className="typography-paragraph text-n-800 mb-[0px]">
              {getObjectiveLocale(
                'Reviewer can override the score suggested by system'
              )}
            </p>
          </div>
          <SmallToggleSwitchPurple
            idStr="toggle-override"
            isChecked={allowOverride}
            onChange={() =>
              dispatch({
                type: 'update_allow_override',
                allowOverride: !allowOverride
              })
            }
          />
        </div>
      )}
      <CommentSection
        uniqueId={state.uniqueId || state.id}
        useComment={useComment}
        setUseComment={(value) =>
          dispatch({ type: 'update_use_comment', useComment: value })
        }
      />
    </Card>
  );
};

export default CustomAttributesCard;

import React from 'react';

import dayjs from 'dayjs';

import { useEditCycle } from 'context/admin/EditCycleContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import EditScheduleCalendar from './EditScheduleCalendar';
import EditSchedulePhaseCard from './EditSchedulePhaseCard';

const EditSchedule = () => {
  const {
    stateData,
    changeEditedCycle,
    changePhaseSchedule,
    configuredPhases
  } = useEditCycle();
  const { editedCycle, cycle } = stateData;

  const configurePhaseScheduleDate = (startDate, endDate) => {
    configuredPhases.map(({ id: phaseKey }) => {
      const phaseStartsAt = editedCycle[phaseKey].startsAt;
      const phaseEndsAt = editedCycle[phaseKey].endsAt;
      const cycleStartsAt = startDate;
      const cycleEndsAt = endDate;

      // For Review Phases that starts before cycle start date and ends after cycle end, phases start date and end date will follow cycle date
      if (
        dayjs(phaseStartsAt).isBefore(cycleStartsAt) &&
        dayjs(phaseEndsAt).isAfter(cycleEndsAt)
      ) {
        changePhaseSchedule(phaseKey, cycleStartsAt, cycleEndsAt);
      } else {
        // For Review Phases that starts before cycle start date, phases start date will follow cycle start date
        if (dayjs(phaseStartsAt).isBefore(cycleStartsAt)) {
          changePhaseSchedule(phaseKey, cycleStartsAt, null);
        }

        // For Review Phases that ends after cycle end date, phase end date will follow cycle end date
        if (dayjs(phaseEndsAt).isAfter(cycleEndsAt)) {
          changePhaseSchedule(phaseKey, null, cycleEndsAt);
        }
      }
    });
  };

  const handleSelectCycleSchedule = (date) => {
    const [startDate, endDate] = date;
    changeEditedCycle('reviewProcessStartsAt', startDate);
    changeEditedCycle('reviewProcessEndsAt', endDate);
    configurePhaseScheduleDate(startDate, endDate);
  };

  const handleResetCycleSchedule = () => {
    changeEditedCycle('reviewProcessStartsAt', cycle.reviewProcessStartsAt);
    changeEditedCycle('reviewProcessEndsAt', cycle.reviewProcessEndsAt);
    configurePhaseScheduleDate(
      cycle.reviewProcessStartsAt,
      cycle.reviewProcessEndsAt
    );
  };

  const selectedDateValue =
    editedCycle.reviewProcessStartsAt && editedCycle.reviewProcessEndsAt
      ? [editedCycle.reviewProcessStartsAt, editedCycle.reviewProcessEndsAt]
      : [null, null];

  return (
    <div className="py-[24px] bg-n-000 w-full pb-[100px] overflow-auto w-[87%] h-[calc(100vh-64px)]">
      <div className="max-w-[768px]">
        <h5 className="typography-h500 mb-[4px]">
          {getObjectiveLocale('Set Up Cycle Schedule')}
        </h5>
        <p className="typography-secondary">
          {getObjectiveLocale('Configure schedule for')}{' '}
          <span className="typography-h400">{editedCycle.name}</span>
        </p>
        <div className="mt-[16px]">
          <h6 className="typography-h100 typography-secondary mb-[8px]">
            {getObjectiveLocale('Choose your start and end date')}
          </h6>
          <EditScheduleCalendar
            onSelect={handleSelectCycleSchedule}
            onResetSchedule={handleResetCycleSchedule}
            selectedValue={selectedDateValue}
            withDisabledDate={true}
            startLimitDate={dayjs()}
            endLimitDate={dayjs().add(3, 'y')}
          />
        </div>

        <div className="horizontal-divider my-[40px]"></div>

        <h5 className="typography-h500 mb-[4px]">
          {getObjectiveLocale('Set Up Phase Schedule')}
        </h5>
        <p className="typography-secondary">
          {getObjectiveLocale('Configure your schedule for each phases')}
        </p>
        {configuredPhases.map(({ id: phaseKey }) => (
          <EditSchedulePhaseCard key={phaseKey} phaseKey={phaseKey} />
        ))}
      </div>
    </div>
  );
};

export default EditSchedule;

import React, { useRef, useState } from 'react';

import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import isEqual from 'lodash/isEqual';

import useClickOutside from 'hooks/useClickOutside';
import { formatTimezone, getDateWithForcedTimezone } from 'utils/DateUtils';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { useDeepEffect } from 'utils/useDeepEffect';

import Button from 'components/design-system/Button';
import AntdCalendar from 'components/shared/Calendar/AntdCalendar';
import SVGIcon from 'components/shared/SVGIcon';

dayjs.extend(utc);
dayjs.extend(quarterOfYear);
dayjs.extend(localeData);

const FormatDate = (customDate, customFormatDate) => {
  let isToday = isEqual(customDate, [
    dayjs().startOf('day').format('YYYY-MM-DD'),
    dayjs().endOf('day').format('YYYY-MM-DD')
  ]);

  let isAllTime = isEqual(customDate, [null, null]);

  let isAnnual = isEqual(customDate, [
    dayjs(`${new Date().getFullYear()}-01-01`).format('YYYY-MM-DD'),
    dayjs(`${new Date().getFullYear()}-12-31`).endOf('day').format('YYYY-MM-DD')
  ]);

  let isNextYear = isEqual(customDate, [
    dayjs(`${new Date().getFullYear() + 1}-01-01`).format('YYYY-MM-DD'),
    dayjs(`${new Date().getFullYear() + 1}-12-31`)
      .endOf('day')
      .format('YYYY-MM-DD')
  ]);

  let isLastYear = isEqual(customDate, [
    dayjs(`${new Date().getFullYear() - 1}-01-01`).format('YYYY-MM-DD'),
    dayjs(`${new Date().getFullYear() - 1}-12-31`)
      .endOf('day')
      .format('YYYY-MM-DD')
  ]);

  if (isToday) {
    return getDateLocale(customDate[0]);
  }
  if (isAllTime) {
    return getObjectiveLocale('All Time');
  }
  if (isAnnual) {
    return getObjectiveLocale(
      `${getObjectiveLocale('This Year')} (${dayjs().format('YYYY')})`
    );
  }
  if (isLastYear) {
    return getObjectiveLocale(
      `${getObjectiveLocale('Last Year')} (${dayjs()
        .subtract(1, 'year')
        .format('YYYY')})`
    );
  }
  if (isNextYear) {
    return getObjectiveLocale(
      `${getObjectiveLocale('Next Year')} (${dayjs()
        .add(1, 'year')
        .format('YYYY')})`
    );
  }

  return `${dayjs(customDate[0]).format(customFormatDate)} - ${dayjs(
    customDate[1]
  ).format(customFormatDate)}`;
};

function DateFilter({
  options,
  onSelect,
  selectedValue,
  containerClassName = '',
  className = '',
  noFilterDate = true,
  withResetButton = false,
  onResetSchedule = () => null,
  withDisabledDate = false,
  startLimitDate = null,
  endLimitDate = null,
  customFormatDate = { day: 'numeric', month: 'long' },
  customClass,
  position = 'right',
  listOptionsTime
}) {
  let semester = Array(2)
    .fill()
    .map((_, index) => ({
      id: `semester_${index + 1}`,
      name: `${getObjectiveLocale(
        `S${index + 1} - ${new Date().getFullYear()}`
      )}`,
      value: [
        dayjs()
          .month(index * 6)
          .startOf('month')
          .toISOString(),
        dayjs()
          .month((index + 1) * 6 - 1)
          .endOf('month')
          .toISOString()
      ],
      datacy: `s${index + 1}`
    }));

  let quarter = Array(4)
    .fill()
    .map((_, index) => ({
      id: `quarter_${index + 1}`,
      name: `${getObjectiveLocale(
        `Q${index + 1} - ${new Date().getFullYear()}`
      )}`,
      value: [
        dayjs()
          .quarter(index + 1)
          .startOf('quarter')
          .toISOString(),
        dayjs()
          .quarter(index + 1)
          .endOf('quarter')
          .toISOString()
      ],
      datacy: `q${index + 1}`
    }));

  let filterOptions = [
    {
      id: 'today',
      name: `${getObjectiveLocale('Today')}`,
      value: [
        dayjs().startOf('day').toISOString(),
        dayjs().endOf('day').toISOString()
      ],
      datacy: 'today'
    },
    {
      id: 'all_time',
      name: `${getObjectiveLocale('All Time')}`,
      value: [null, null],
      datacy: 'all_time'
    },
    {
      id: 'annual',
      name: `${getObjectiveLocale(
        `This Year (${new Date().getFullYear().toString()})`
      )}`,
      value: [
        dayjs(`${new Date().getFullYear()}-01-01`).toISOString(),
        dayjs(`${new Date().getFullYear()}-12-31`).endOf('day').toISOString()
      ],
      datacy: 'annual'
    },
    {
      id: 'next_year',
      name: `${getObjectiveLocale(
        `Next Year (${(new Date().getFullYear() + 1).toString()})`
      )}`,
      value: [
        dayjs(`${new Date().getFullYear() + 1}-01-01`).toISOString(),
        dayjs(`${new Date().getFullYear() + 1}-12-31`)
          .endOf('day')
          .toISOString()
      ],
      datacy: 'next_year'
    },
    {
      id: 'last_year',
      name: `${getObjectiveLocale(
        `Last Year (${(new Date().getFullYear() - 1).toString()})`
      )}`,
      value: [
        dayjs(`${new Date().getFullYear() - 1}-01-01`).toISOString(),
        dayjs(`${new Date().getFullYear() - 1}-12-31`)
          .endOf('day')
          .toISOString()
      ],
      datacy: 'last_year'
    },
    ...semester,
    ...quarter
  ];

  const PresetValues = () => {
    return (
      <>
        {lists
          .filter((val) => val)
          .map((val, index) => (
            <div
              className="px-[16px] py-[6px] cursor-pointer relative hover:bg-base-30024"
              data-cy={val.datacy}
              key={index}
              onClick={() => {
                selectTimeline(val);
              }}
            >
              {val.name}
            </div>
          ))}
      </>
    );
  };

  let lists = filterOptions;
  const custom = !noFilterDate ? options?.includes('custom') : true;
  if (!noFilterDate) {
    lists = filterOptions?.filter((option) => {
      if (
        options?.includes(option.id) ||
        (options?.includes('quarter') && option.id?.includes('quarter')) ||
        (options?.includes('semester') && option.id?.includes('semester'))
      ) {
        return option;
      }
    });
  }

  const [showDate, setShowDate] = useState(false);
  const [customDate, setCustomDate] = useState([
    selectedValue[0],
    selectedValue[1]
  ]);
  const [timeline, setTimeline] = useState(null);

  const description =
    customDate[0] && customDate[1]
      ? FormatDate(
          [
            getDateWithForcedTimezone(customDate[0]),
            getDateWithForcedTimezone(customDate[1])
          ],
          customFormatDate,
          timeline
        )
      : getObjectiveLocale('All Time');

  const ref = useRef();
  useClickOutside(ref, () => {
    setShowDate(false);
  });

  const handleSelectDate = (date) => {
    let selectedDate = [
      formatTimezone(date[0].format('YYYY-MM-DD'), 'start'),
      formatTimezone(date[1].format('YYYY-MM-DD'), 'end')
    ];
    setCustomDate(selectedDate);
    setShowDate(false);
    onSelect(selectedDate);
  };

  const selectTimeline = (timeline) => {
    const formattedDate = {
      value: [
        timeline.value?.[0]
          ? formatTimezone(
              dayjs(timeline.value?.[0])?.format('YYYY-MM-DD'),
              'start'
            )
          : null,
        timeline.value?.[1]
          ? formatTimezone(
              dayjs(timeline.value?.[1])?.format('YYYY-MM-DD'),
              'end'
            )
          : null
      ]
    };
    setCustomDate([formattedDate.value[0], formattedDate.value[1]]);
    setTimeline(formattedDate);
    onSelect(formattedDate.value);
    setShowDate(false);
  };

  const resetDate = () => {
    setCustomDate([null, null]);
    onResetSchedule();
  };

  // disabledDate for setPhases, initiated first in SchedulePhaseCard.js
  function isDateDisabled(current) {
    if (!current) {
      return false;
    }
    if (current.valueOf() < startLimitDate.valueOf()) return true;
    if (current.valueOf() > endLimitDate.valueOf()) return true;
  }

  useDeepEffect(() => {
    setCustomDate([
      selectedValue[0] ? selectedValue[0] : null,
      selectedValue[1] ? selectedValue[1] : null
    ]);
  }, [selectedValue]);
  return (
    <div
      className={`filter-container ${containerClassName} ${
        lists.length > 1 ? 'changeable' : ''
      }`}
    >
      <div className={`content-filter ${className}`}>
        <div
          className="time-detail bg-n-000 h-[32px] flex justify-between items-center border border-solid border-n-400 rounded-[4px]"
          style={
            lists.length === 1 ? { cursor: 'unset', pointerEvents: 'none' } : {}
          }
          onClick={() => setShowDate(!showDate)}
          data-cy="filter-time"
        >
          <p className="typography-button description" data-cy="date-text">
            {description}
          </p>
          <SVGIcon
            size="24"
            iconName="icon-calendar_today"
            fillColor="var(--base-600)"
          />
        </div>
        {
          <div className="wrapper-calendar-filter" ref={ref}>
            {showDate && !custom && (
              <div className="absolute bg-n-000 z-[2] w-full border-[1px] border-solid border-n-400 mt-[8px] rounded">
                <PresetValues />
              </div>
            )}
            {showDate && custom && (
              <AntdCalendar>
                <AntdCalendar.Content
                  dateValue={customDate}
                  setDateValueHandler={handleSelectDate}
                  position={position}
                  isAlwaysOpen
                  leftSidebarContent={<PresetValues />}
                  customClass={customClass}
                  listOptionsTime={listOptionsTime}
                  disabledDate={withDisabledDate ? isDateDisabled : false}
                  clickOutsideCallback={() => setShowDate(false)}
                />
              </AntdCalendar>
            )}
          </div>
        }
      </div>
      {withResetButton && (
        <Button.Tertiary onClick={() => resetDate()} customClass="ml-[8px]">
          Reset
        </Button.Tertiary>
      )}
    </div>
  );
}

export default DateFilter;

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import findLastIndex from 'lodash/findLastIndex';
import isEmpty from 'lodash/isEmpty';

import {
  completeSurvey,
  getPulseSurvey,
  postStartTimeSurvey,
  submitEssaySurvey,
  voteSurvey
} from 'client/CultureClient';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import { useLocale } from 'hooks/useLocale';
import { getDateLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import PulseSurveyModal from 'components/modal/culture/PulseSurveyModal';
import Footer from 'components/shared/Footer';
import Modal from 'components/shared/modal/Modal';
import QuestionAnswers from 'components/surveys/QuestionAnswers';
import SummaryAnswers from 'components/surveys/SummaryAnswers';
import { trackEvent } from 'src/utils/AnalyticUtils';

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const PulseSurveyAnswer = ({ isReadOnly }) => {
  const { user, appType } = useUser();
  const { id: pulseSurveyId, type } = useParams();
  const history = useHistory();

  // upcoming occurrenceId
  const searchParams = new URLSearchParams(location.search);
  const occurrenceId = searchParams.get('occurrenceId');
  const { setBothLocale } = useLocale();
  const { addToast, changeContainerPosition } = useToastContext();

  const [surveyData, setSurveyData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [isLoadingAnswer, setLoadingAnswer] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [originalAnswer, setOriginalAnswer] = useState(null);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const currentQuestion = surveyData?.questions?.[currentIndex];
  const isSummaryPage = type === 'summary';
  const isFieldDisabled = isSummaryPage || isReadOnly;
  const totalQuestion = surveyData?.questions?.length;
  const lastQuestion = totalQuestion - 1 === currentIndex ? true : false;
  const [isSurveyShow, setIsSurveyShow] = useState(false);

  const generateCurrentAnswer = useCallback(
    (questionIndex) => {
      let id, ans;
      if (surveyData?.questions?.[questionIndex]?.type === 'poll') {
        if (surveyData?.questions?.[questionIndex].voted) {
          surveyData?.questions?.[questionIndex].pollOptions.map((option) => {
            if (option.voted) {
              id = option.id;
              ans = option.customType && option.answer;
            }
          });
        }
      } else {
        ans = surveyData?.questions?.[questionIndex]?.answer;
      }
      return { id: id, answer: ans };
    },
    [surveyData?.questions]
  );

  const changeQuestion = useCallback(
    (questionIndex) => {
      const index =
        questionIndex != null && questionIndex != undefined
          ? questionIndex
          : currentIndex;
      const ans = generateCurrentAnswer(index);
      setOriginalAnswer(ans);
      setCurrentAnswer(ans);
      setCurrentIndex(index);
    },
    [generateCurrentAnswer, currentIndex]
  );

  const getSurveyData = useCallback(
    async (isFirstVisit = false) => {
      const { data } = await getPulseSurvey(pulseSurveyId, true, {
        surveyOccurrenceId: parseInt(occurrenceId)
      });
      if (data) {
        let lastAnsweredIndex = findLastIndex(data.questions, (survey) =>
          survey?.type === 'poll' ? survey?.voted : survey?.answer !== null
        );

        lastAnsweredIndex = lastAnsweredIndex < 0 ? 0 : lastAnsweredIndex;
        setSurveyData(data);
        setLoadingAnswer(false);
        setLoading(false);
        isFirstVisit &&
          setCurrentIndex((prevState) =>
            isFirstVisit ? lastAnsweredIndex : prevState + 1
          );
        return data;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pulseSurveyId]
  );

  const setStartTimeSurvey = useCallback(async () => {
    if (isSummaryPage) return;
    await postStartTimeSurvey(pulseSurveyId, user?.id, {
      surveyOccurrenceId: parseInt(occurrenceId)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pulseSurveyId, user?.id]);

  const updateAnswer = async () => {
    if (currentAnswer.answer == '' && currentQuestion.type == 'essay') return;

    setIsSaving(true);

    const updateFunction =
      currentQuestion?.type === 'poll'
        ? await voteSurvey(currentQuestion.id, currentAnswer.id, {
            status: 'draft',
            answer: currentAnswer.answer,
            surveyOccurrenceId: parseInt(occurrenceId)
          })
        : await submitEssaySurvey(currentQuestion.id, {
            status: 'draft',
            answer: currentAnswer.answer,
            surveyOccurrenceId: parseInt(occurrenceId)
          });
    const { isSuccess } = updateFunction;
    if (isSuccess) {
      getSurveyData(); // sync data with backend

      let answeredQuestion =
        surveyData.questions.filter((question) => question.voted).length + 1;
      const properties = {
        'survey id': pulseSurveyId,
        'survey title': surveyData.title,
        'answered question': answeredQuestion,
        'total question': totalQuestion,
        'last view question': currentQuestion,
        status: isSuccess ? 'success' : 'fail'
      };

      trackEvent({
        event: 'answer survey',
        eventProperties: properties,
        env: appType
      });
      setIsSaving(false);
    }
  };

  const debounceFn = debounce(async () => {
    if (
      !isEmpty(surveyData) &&
      surveyData?.questions?.length > 0 &&
      !isLoadingAnswer &&
      currentAnswer != originalAnswer
    ) {
      await updateAnswer();
    }
  }, 3000);

  const handleNext = async () => {
    // validate customType option
    // if selected option is custom type and there is no answer, show toastr
    const selectedOption = currentQuestion?.pollOptions?.find(
      (option) => option.id == currentAnswer?.id
    );
    if (selectedOption?.customType && !currentAnswer.answer) {
      addToast({
        msg: 'Please type your answer to proceed',
        type: 'error'
      });
      return;
    }

    if (currentAnswer != generateCurrentAnswer()) {
      await updateAnswer();
    }
    if (currentIndex + 1 === totalQuestion) {
      submitSurvey();
    } else {
      changeQuestion(currentIndex + 1);
    }
  };

  const submitSurvey = async () => {
    setIsSaving(true);

    const { isSuccess } = await completeSurvey(pulseSurveyId, {
      surveyOccurrenceId: parseInt(occurrenceId)
    });
    if (isSuccess) {
      setIsSubmit(true);
      setIsSurveyShow(true);
    }
    setIsSaving(false);
  };

  const backToPrevQuestion = () => {
    if (currentIndex >= 1) {
      changeQuestion(currentIndex - 1);
    }
  };

  const isAnswersValid = () => {
    if (!currentAnswer) return false;

    // check essay type
    if (currentQuestion?.type == 'essay') {
      return !!currentAnswer.answer;
    }

    // check poll types
    const selectedOption = currentQuestion?.pollOptions.find(
      (option) => option.id == currentAnswer?.id
    );

    return !!selectedOption;
  };

  useEffect(() => {
    getSurveyData(true);
    setStartTimeSurvey();
  }, [getSurveyData]);

  useEffect(() => {
    if (!surveyData) return;

    changeQuestion(currentIndex);
  }, [surveyData, changeQuestion, currentIndex]);

  useEffect(() => {
    debounceFn();
    return debounceFn.cancel;
  }, [currentAnswer, debounceFn]);

  useEffect(() => {
    changeContainerPosition({ bottom: 68 });
  }, [changeContainerPosition]);

  const isAbleToSubmit = isAnswersValid() && lastQuestion;
  const headerProps = {
    titlePage: setBothLocale('Pulse Survey'),
    isHeaderComposer: true,
    closeAction: () =>
      isSummaryPage ? history.replace('/pulse-surveys') : setIsModalShow(true),
    primaryAction: !isSummaryPage && {
      title: 'Submit Survey',
      disabled: !isAbleToSubmit,
      onClick: () => submitSurvey(),
      dataCy: 'submit-answer-button'
    },
    useCloseButton: surveyData.canBePostponed || isSummaryPage ? true : false
  };

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="flex !px-[0px] w-full relative">
        {/* Left Component */}
        {
          <div className="bg-base-800 w-[50%] flex items-center flex-col px-[40px] h-[calc(100vh-60px)] overflow-y-auto">
            <div className="m-auto">
              {isLoading ? (
                <div className="flex flex-col items-center">
                  <Shimmer
                    customClass="rounded-lg mb-[16px]"
                    width="300px"
                    height="40px"
                    widthRandomness={0.25}
                    isWhiteBackground={false}
                    circle
                  />
                  <Shimmer
                    customClass="rounded-lg mb-[36px]"
                    width="200px"
                    height="24px"
                    widthRandomness={0.25}
                    isWhiteBackground={false}
                    circle
                  />
                  {[...Array(2)].map((index) => (
                    <Shimmer
                      customClass="rounded-lg mb-[4px]"
                      width="600px"
                      height="20px"
                      isWhiteBackground={false}
                      circle
                      key={index}
                    />
                  ))}
                  <Shimmer
                    customClass="rounded-lg"
                    width="600px"
                    height="20px"
                    isWhiteBackground={false}
                    circle
                  />
                </div>
              ) : (
                <>
                  <p
                    data-cy="survey-answer-title"
                    className="text-n-000 typography-h900 mb-[16px] px-[40px] mt-[40px] text-center"
                  >
                    {surveyData?.title}
                  </p>
                  <p
                    data-cy="survey-answer-date"
                    className="text-n-000 typography-paragraph mb-[36px] text-center"
                  >
                    {setBothLocale('Due')}:{' '}
                    {getDateLocale(surveyData?.occurrence.endDate)}
                  </p>
                  <p
                    data-cy="survey-answer-description"
                    className="text-n-000 typography-h600 text-center mb-[40px]"
                  >
                    {surveyData?.description}
                  </p>
                </>
              )}
            </div>
          </div>
        }
        {/* Right Component */}
        {isLoadingAnswer ? (
          <div className="w-[50%] bg-n-000">
            <div className="mx-[40px] mt-[40px]">
              {[...Array(3)].map((index) => (
                <>
                  <Shimmer
                    customClass="rounded-lg mb-[4px]"
                    width="608px"
                    height="40px"
                    circle
                    key={index}
                  />
                  <Shimmer
                    customClass="rounded-lg mb-[36px]"
                    width="608px"
                    height="28px"
                    circle
                    key={index}
                  />
                </>
              ))}
            </div>
          </div>
        ) : type.includes('answer') ? (
          <QuestionAnswers
            currentIndex={currentIndex}
            currentAnswer={currentAnswer}
            surveyData={surveyData}
            setCurrentAnswer={!isSaving ? setCurrentAnswer : () => {}}
            isFieldDisabled={isFieldDisabled}
          />
        ) : (
          <SummaryAnswers
            currentIndex={currentIndex}
            currentAnswer={currentAnswer}
            surveyData={surveyData}
            setCurrentAnswer={setCurrentAnswer}
            isFieldDisabled={isFieldDisabled}
          />
        )}
        {!isSummaryPage && (
          <Footer
            secondaryButton
            isButtonPrimaryLoading={isSaving}
            buttonPrimaryDisabled={!isAnswersValid() || isFieldDisabled}
            linkButtonName="Back"
            buttonPrimaryName={lastQuestion ? 'Submit' : 'Next'}
            datacy="btn-submit"
            linkButtonDataCy="btn-back"
            linkButtonDisabled={currentIndex === 0}
            handleClick={() => handleNext()}
            customClass="w-[720px]"
            customButtonContainer="pr-[40px] pt-[16px]"
            handleRoute={() => backToPrevQuestion()}
            style={{ display: 'flex', justifyContent: 'end' }}
          />
        )}
        {isModalShow && (
          <Modal
            className="w-[400px]"
            title="Leave the survey"
            description="Are you sure want to leave this survey? The answer you made so far will be saved."
            withPrimaryBtn={{
              title: 'Yes',
              dataCy: 'leave-survey',
              onClick: () => history.replace('/pulse-surveys')
            }}
            withSecondaryBtn={{
              title: 'Cancel',
              dataCy: 'button-secondary-modal',
              onClick: () => setIsModalShow(false)
            }}
            withCloseIcon={false}
            withWarningIcon={true}
          />
        )}
        {isSubmit && isSurveyShow && (
          <PulseSurveyModal
            pulseSurveyId={pulseSurveyId}
            setIsSurveyShow={setIsSurveyShow}
            isThanks={true}
          />
        )}
      </div>
    </>
  );
};

export default PulseSurveyAnswer;

import React from 'react';

import startCase from 'lodash/startCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

const DropdownAssignment = ({
  containerClass,
  label,
  items,
  keyName,
  onSelect,
  selectedItem,
  id
}) => {
  return (
    <div className={containerClass ? containerClass : ''}>
      <p className="typography-h100 text-n-800 mb-[8px]">{label}</p>
      <Dropdown>
        <Dropdown.Trigger
          customClass="flex flex-row items-center justify-between rounded border-n-400 border-solid border w-[320px] bg-n-000 h-[32px] pl-[16px] pr-[8px]"
          dataCy={`${id}-trigger`}
        >
          <p className="text-n-900 typography-button">
            {getObjectiveLocale(startCase(selectedItem[keyName]))}
          </p>
          <SVGIcon
            size="24"
            iconName="icon-unfold_more"
            fillColor="var(--n-600)"
          />
        </Dropdown.Trigger>
        <Dropdown.MenuItems
          type="fixed"
          customClass={'w-[320px]'}
          maxHeight={186}
        >
          {items?.map((item) => (
            <Dropdown.MenuItem
              dataCy={`${id}-${item.id}`}
              key={item.id}
              onClick={() => onSelect(item)}
            >
              {getObjectiveLocale(startCase(item[keyName]))}
            </Dropdown.MenuItem>
          ))}
        </Dropdown.MenuItems>
      </Dropdown>
    </div>
  );
};

export default DropdownAssignment;

import camelCase from "lodash/camelCase";
import { useUser } from "context/UserContext";

const usePermission = (permissionKey, useCustomPermission=null) => {
  const { config, appType } = useUser();
  const permission = useCustomPermission ? useCustomPermission : config.permissions;
  const cultureKey = camelCase(`culture_`+permissionKey)
  const key = appType == "culture" ? cultureKey : permissionKey
  return appType ? permission?.[key] : (permission[permissionKey] || permission[cultureKey])
}

export default usePermission

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useParams } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { getUserCulture } from 'client/UserProfile';
import { FeedsProvider } from 'context/FeedsContext';
import { useProfileFilter } from 'context/ProfileFilterContext';
import { ProfileSkillProvider } from 'context/ProfileSkillContext';
import { ProfileValueProvider } from 'context/ProfileValueContext';
import { useUser } from 'context/UserContext';
import usePermission from 'hooks/usePermission';

import ProfileFeeds from 'components/culture/ProfileFeeds';
import BehaviourTab from 'components/performance-report/tabs/BehaviourTab';
import DetailTab from 'components/performance-report/tabs/DetailTab';
import OverviewTab from 'components/performance-report/tabs/OverviewTab';
import PerformanceReportTab from 'components/performance-report/tabs/PerformanceReportTab';
import SVGIcon from 'components/shared/SVGIcon';
import PerformanceTab from 'components/user-profile/tabs/PerformanceTab';

import OverviewNonTeam from './OverviewNonTeam';
import ProfileFilter from './ProfileFilter';
import ProfileTab from './ProfileTab';
import CustomProfileCard from './tabs/CustomProfileCard';
import DetailsInfoProfile from './tabs/DetailsInfoProfile';
// Skills Components
import SkillTab from './tabs/SkillTab.js';
// Values Components
import ValuesTab from './tabs/ValuesTab.js';

function UserProfileContent({
  objectivesVisibility,
  permission,
  performanceProfile,
  cultureProfile,
  setCultureProfile
}) {
  const { user, config, appType } = useUser();
  const [detailInfo, setDetailInfo] = useState({});
  const [isActivitiesDetailOpen, setIsActivitiesDetailOpen] = useState('');
  const [performanceReportTeam, setPerformanceReportTeam] = useState(null);
  const params = useParams();
  const userId = params.userId;
  const placementId = params.placementId;
  const isAppCulture =
    appType === 'culture' || (!appType && user.cultureRole && !user.role);
  const isHavePerfRole = user.role;
  const isHaveCultureRole = user.cultureRole;
  const { filter: profileFilter } = useProfileFilter();
  const [filter, setFilter] = useState(cloneDeep(profileFilter));

  const isValueStrengthVisible = usePermission(
    'profileValueStrengthSee',
    permission
  );
  const isSkillStrengthVisible = usePermission(
    'profileSkillStrengthSee',
    permission
  );
  const isValueImprovementVisible = permission?.profileValueImprovementSee;
  const isSkillImprovementVisible = permission?.profileSkillImprovementSee;
  const valuePermission = isValueStrengthVisible || isValueImprovementVisible;
  const skillPermission = isSkillStrengthVisible || isSkillImprovementVisible;

  const seeOverview = isAppCulture
    ? valuePermission || skillPermission
    : permission?.profileOverviewSee;

  const [currentTab, setCurrentTab] = useState(
    appType === 'culture' || user.cultureRole
      ? seeOverview
        ? 'overview'
        : 'activities'
      : 'objective'
  );

  const isTeam =
    config.permissions?.objectiveTeamSee && !config.permissions?.projectSee;
  const activitiesList = [
    {
      id: 'recent',
      title: 'Recent Post',
      count: cultureProfile?.activities?.posts
    },
    {
      id: 'recognition',
      title: 'Recognition Received',
      count: cultureProfile?.activities?.recognition
    },
    {
      id: 'feeling',
      title: 'Feeling Shared',
      count: cultureProfile?.activities?.feeling
    },
    {
      id: 'saved',
      title: 'Saved Post',
      count: cultureProfile?.activities?.savedPosts
    }
  ];

  let myTeam;
  if (user) {
    if (user.name.includes(' ')) {
      myTeam = `${user.name.substr(0, user.name.indexOf(' '))}'s Team`;
    } else {
      myTeam = `${user.name}'s Team`;
    }
  }

  const _setDetailInfo = (data, cycle) => {
    setDetailInfo({
      data: data,
      cycle: cycle
    });
    setCurrentTab('detail');
  };

  useEffect(() => {
    // init set scroll position at top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    let startPos = -1;

    function findPosY(obj) {
      let localObj = obj;
      let curtop = 0;
      if (
        typeof localObj.offsetParent !== 'undefined' &&
        localObj.offsetParent
      ) {
        while (localObj.offsetParent) {
          curtop += localObj?.offsetTop;
          localObj = localObj.offsetParent;
        }
        curtop += localObj?.offsetTop;
      } else if (localObj.y) curtop += localObj.y;
      return curtop;
    }

    window.onscroll = function onScroll() {
      const sticky = document.querySelector('.sticky');
      if (sticky) {
        if (startPos < 0) startPos = findPosY(sticky);

        const containerWidth =
          document.querySelector('.skills-tab')?.offsetWidth ?? 0;
        const bodyWidth = document.body.offsetWidth;

        if (pageYOffset > startPos) {
          sticky.classList.add('is-sticky');
          sticky.style.right = `${(bodyWidth - containerWidth) / 2}px`;
        } else {
          sticky.classList.remove('is-sticky');
          sticky.style.right = 'initial';
        }
      }
    };
  }, []);

  useEffect(() => {
    if (
      profileFilter &&
      isHaveCultureRole &&
      isAppCulture &&
      !isEqual(profileFilter, filter)
    ) {
      const _getUser = async (userId) => {
        const params = {
          ...profileFilter
        };
        const { data } = await getUserCulture(userId, params);
        if (data) {
          setCultureProfile(data);
        }
      };
      _getUser(userId);
    }
  }, [profileFilter]);

  return (
    <>
      {isActivitiesDetailOpen ? (
        <FeedsProvider>
          <ProfileFeeds
            userId={userId}
            filter={isActivitiesDetailOpen}
            backFunction={() => setIsActivitiesDetailOpen(false)}
          />
        </FeedsProvider>
      ) : (
        <>
          <div className="filter-section">
            <ProfileFilter
              showQuarterFilter={currentTab !== 'overview' || !isTeam}
            />
            <div>
              <ProfileTab
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                permission={permission}
                userId={userId}
                placementId={placementId}
              />
            </div>
          </div>
          <ProfileValueProvider>
            <ProfileSkillProvider>
              {currentTab === 'team-overview' &&
                permission?.profileTeamOverviewSee && (
                  <OverviewTab userId={userId} setDetailInfo={_setDetailInfo} />
                )}
              {currentTab === 'overview' && seeOverview && (
                <OverviewNonTeam
                  userId={userId}
                  placementId={placementId}
                  setCurrentTab={setCurrentTab}
                  permission={permission}
                  isHavePerfRole={isHavePerfRole}
                  isAppCulture={isAppCulture}
                />
              )}

              {currentTab === 'values' && valuePermission && (
                <ValuesTab
                  permission={permission}
                  userId={userId}
                  startDate={profileFilter.periodBegin}
                  dueDate={profileFilter.periodEndBefore}
                  currentTab={currentTab}
                />
              )}

              {currentTab == 'objective' && isHavePerfRole && !isAppCulture && (
                <PerformanceTab
                  user={performanceProfile}
                  placementId={placementId}
                  userId={userId}
                  objectivesVisibility={objectivesVisibility}
                  permission={permission}
                />
              )}

              {currentTab == 'skills' && skillPermission && (
                <SkillTab
                  permission={permission}
                  userId={userId}
                  startDate={profileFilter.periodBegin}
                  dueDate={profileFilter.periodEndBefore}
                  currentTab={currentTab}
                />
              )}
              {currentTab == 'details' && (
                <DetailsInfoProfile
                  userInfo={
                    isAppCulture
                      ? cultureProfile?.info
                      : performanceProfile?.info
                  }
                />
              )}
              {currentTab === 'activities' && isHaveCultureRole && (
                <div className="flex">
                  <CustomProfileCard
                    title=""
                    className="w-full"
                    classNameBox="height-auto"
                  >
                    <div>
                      {activitiesList.map((activity) => (
                        <div
                          key={activity.id}
                          className="flex justify-between items-center cursor-pointer border-bottom-n-400 h-[64px]"
                          onClick={() => setIsActivitiesDetailOpen(activity.id)}
                        >
                          <div>{activity.title}</div>
                          <div className="flex">
                            {activity.count}
                            <SVGIcon
                              iconName="icon-keyboard_arrow_right"
                              size="24"
                              fillColor="var(--n-600)"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </CustomProfileCard>
                </div>
              )}
            </ProfileSkillProvider>
          </ProfileValueProvider>
          {isHavePerfRole && !isAppCulture && (
            <>
              {currentTab == 'performance' && (
                <PerformanceReportTab
                  userId={userId}
                  setDetailInfo={_setDetailInfo}
                  profileUser={performanceProfile}
                />
              )}
              {currentTab == 'detail' && (
                <DetailTab
                  detailInfo={detailInfo}
                  performanceReportTeam={performanceReportTeam}
                />
              )}
              {currentTab == 'behaviour' && <BehaviourTab userId={userId} />}
            </>
          )}
        </>
      )}
    </>
  );
}

export default withRouter(UserProfileContent);

import React from 'react';

import PropTypes from 'prop-types';

import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

const Interfaces = {
  title: PropTypes.string,
  desc: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  useBorderBottom: PropTypes.bool,
  colMdDescBeforeSwitch: PropTypes.number,
  idToogleSwitch: PropTypes.string,
  className: PropTypes.string
};

const DefaultValue = {
  title: 'title',
  desc: 'description',
  isChecked: false,
  onChange: () => {
    return false || true;
  },
  useBorderBottom: false,
  colMdDescBeforeSwitch: 4,
  idToogleSwitch: 'id-for-set-toogle',
  className: ''
};

const ObjectivesFormBox = ({
  title,
  desc,
  children,
  isChecked,
  onChange,
  useBorderBottom,
  idToogleSwitch,
  className,
  disabled
}) => {
  return (
    <div className={`${useBorderBottom && className}`}>
      <div>
        <div className="flex mt-[24px]">
          <span className="enable-minimum-number">{title}</span>
          <SmallToggleSwitchPurple
            disabled={disabled}
            idStr={`${idToogleSwitch}`}
            isChecked={isChecked}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="if-minimum-number-of">{desc}</div>
      {children}
    </div>
  );
};

ObjectivesFormBox.propTypes = Interfaces;
ObjectivesFormBox.defaultProps = DefaultValue;

export default ObjectivesFormBox;

import React from 'react';
import { useQueryClient } from 'react-query';

//CREATE CONTEXT
const RefetchQueryContext = React.createContext();

//PROVIDER
function RefetchQueryProvider(props) {
  const queryClient = useQueryClient();
  return <RefetchQueryContext.Provider value={{ queryClient }} {...props} />;
}

function useRefetchQuery() {
  const { queryClient } = React.useContext(RefetchQueryContext);

  const invalidateQueries = async (queryKey, options) => {
    await queryClient.invalidateQueries(queryKey, options);
  };

  const refetchQueries = async (queryKey) => {
    await queryClient.refetchQueries(queryKey, { active: true });
  };

  const removeQueries = (queryKey) => {
    queryClient.removeQueries(queryKey);
  };

  const refetchObjective = async (objectiveId) => {
    objectiveId = parseInt(objectiveId);
    await recursivelyRefetchObjective(objectiveId);

    // refetch overallProgress
    refetchQueries(['overallProgress']);
    removeQueries(['objective', 'activities']);
  };

  const recursivelyRefetchObjective = async (objectiveId) => {
    const query = queryClient?.getQueryData(['objective', objectiveId]) || {};
    if (query) {
      refetchQueries(['objective', objectiveId]);
    }

    let data = Object.prototype.hasOwnProperty.call(query, 'data')
      ? query.data
      : query;

    if (data?.parents?.length > 0) {
      const parentIds = data?.parents?.map(({ id }) => id);
      parentIds.map(
        (id) => id !== objectiveId && recursivelyRefetchObjective(id)
      );
    }

    if (location.pathname.includes('teams') && data?.teamId !== objectiveId) {
      recursivelyRefetchObjective(data?.teamId);
    }
  };

  const refetchObjectives = (page) => {
    const query = queryClient?.getQueryData(['objectives', page]);
    if (query) {
      refetchQueries(['objectives', page]);
    } else {
      refetchQueries(['objectives']);
    }

    // refetch overallProgress
    refetchQueries(['overallProgress']);
  };

  const refetchSubObjectives = (objectiveId) => {
    const query = queryClient?.getQueryData(['sub-objectives', objectiveId]);
    if (query) {
      queryClient.refetchQueries(['sub-objectives', objectiveId]);
    }

    query?.data?.parents?.length > 0 &&
      query?.data?.parents?.map((parent) => refetchSubObjectives(parent?.id));
  };

  const invalidateTaskQueries = ({
    task,
    isSubTask,
    groupData,
    refetchActive = false
  }) => {
    if (!task) return;
    const taskData = isSubTask ? task?.parent : task;

    if (isSubTask && taskData) {
      invalidateQueries(['objective', taskData.id]);
    }

    // My Task:
    // invalidate for phase group
    invalidateQueries(
      ['objectives', 'mytasks', taskData?.phase?.name?.toLowerCase()],
      { refetchActive }
    );

    // invalidate for priority group
    invalidateQueries(
      ['objectives', 'mytasks', taskData?.priority?.name?.toLowerCase()],
      { refetchActive }
    );

    // invalidate for section group
    invalidateQueries(
      [
        'objectives',
        'mytasks',
        taskData?.section?.name?.toLowerCase() || 'task'
      ],
      { refetchActive }
    );

    // invalidate for project group
    invalidateQueries(
      ['objectives', 'mytasks', taskData?.parent?.name?.toLowerCase()],
      { refetchActive }
    );

    // invalidate current group
    invalidateQueries(
      ['objectives', 'mytasks', groupData?.name?.toLowerCase()],
      { refetchActive }
    ),
      // Project Task:
      // invalidate for phase section group
      invalidateQueries(
        [
          'objectives',
          'mytasks',
          taskData?.phase?.name?.toLowerCase(),
          taskData?.section?.name?.toLowerCase() || 'task'
        ],
        { refetchActive }
      );

    // invalidate for priority section group
    invalidateQueries(
      [
        'objectives',
        'mytasks',
        taskData?.priority?.name?.toLowerCase(),
        taskData?.section?.name?.toLowerCase() || 'task'
      ],
      { refetchActive }
    );
  };

  return {
    refetchQueries,
    refetchObjective,
    refetchObjectives,
    refetchSubObjectives,
    invalidateQueries,
    invalidateTaskQueries,
    removeQueries
  };
}

export { RefetchQueryProvider, useRefetchQuery };

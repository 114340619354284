import React, { useEffect, useRef, useState } from 'react';

import useClickOutside from 'hooks/useClickOutside';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import NumberInput from 'components/shared/NumberInput';
import SVGIcon from 'components/shared/SVGIcon';
import RangeSlider from 'components/slider/RangeSlider';

const Weight = ({ objectiveDetail, updateObjective }) => {
  const [weightValue, setWeightValue] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { weight } = objectiveDetail;

  useEffect(() => {
    setWeightValue(weight);
  }, [weight]);

  return (
    <div className="px-[24px] hover:bg-base-3008">
      <div className="flex py-[16px]" data-cy="sidebar-project-weight">
        <SVGIcon
          iconName="icon-fitness_center"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <div className="relative flex flex-col w-full">
          <p
            className={`typography-paragraph ${
              !weight || weight === 0
                ? 'text-n-600 cursor-pointer'
                : 'text-n-900'
            }`}
            onClick={() => (!weight || weight === 0) && setIsDialogOpen(true)}
            data-cy="weight-dialog"
          >
            {getObjectiveLocale('Weight')}
            {weight > 0 && (
              <span
                className="text-base-600 cursor-pointer"
                onClick={() => setIsDialogOpen(true)}
              >
                {' '}
                {getNumberLocale(weight)}%
              </span>
            )}
          </p>
          {isDialogOpen && (
            <WeightDialog
              weightValue={weightValue}
              setWeightValue={setWeightValue}
              setIsDialogOpen={setIsDialogOpen}
              updateObjective={updateObjective}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const WeightDialog = ({
  weightValue,
  setWeightValue,
  setIsDialogOpen,
  updateObjective
}) => {
  const ref = useRef();

  const handleChangeWeightInput = (inputWeight) => {
    let weight;
    if (inputWeight < 0) {
      weight = 0;
    } else if (inputWeight > 100) {
      weight = 100;
    } else {
      weight = parseFloat(inputWeight);
    }
    setWeightValue(isNaN(weight) ? 0 : weight);
  };

  const handleChangeWeight = (weight) => {
    if (typeof weight !== 'undefined') {
      updateObjective({ weight: weight });
    }
  };

  useClickOutside(ref, () => {
    handleChangeWeight(weightValue);
    setIsDialogOpen(false);
  });

  return (
    <div
      className="absolute top-[24px] right-[0px] flex justify-between items-center p-[16px] z-20 bg-n-000 rounded-[4px]"
      style={{
        boxShadow:
          '0px 2px 4px rgba(15, 23, 42, 0.06), 0px 4px 6px rgba(15, 23, 42, 0.1)'
      }}
      ref={ref}
    >
      <RangeSlider
        handleChange={(e) => setWeightValue(e)}
        onChangeComplete={() => handleChangeWeight(weightValue)}
        value={weightValue}
        min={0}
        max={100}
        className="h-[24px] w-[220px] pt-[10px]"
      />
      <NumberInput
        placeholder="0"
        max={100}
        value={weightValue}
        onChange={(e) => handleChangeWeightInput(e)}
        className="!h-[32px] !w-[72px] ml-[16px]"
        inputClass="!px-[16px] !py-[4px]"
        dataCy="weight-input"
      />
      <p className="typography-paragraph ml-[8px]">%</p>
    </div>
  );
};

export default Weight;

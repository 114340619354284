import cloneDeep from 'lodash/cloneDeep';
import { create } from 'zustand';

import { editPosition, savePosition } from 'client/admin/UserManagementClient';

const saveData = async (detailPosition, userId) => {
  const payload = cloneDeep(detailPosition);
  const idPosition = detailPosition?.id === 'new' ? null : detailPosition?.id;
  const clusterIds =
    detailPosition?.groups?.clusterGroup.length > 0
      ? detailPosition?.groups?.clusterGroup?.map((cluster) => cluster?.id)
      : [];
  payload.id = idPosition;
  (payload.parentId = detailPosition.directManager?.placementId || null),
    (payload.groups = {
      permissionGroupId: detailPosition?.groups?.permissionGroup?.[0]?.id,
      behaviorGroupId: detailPosition?.groups?.behaviorGroup?.[0]?.id,
      reviewGroupId: detailPosition?.groups?.reviewGroup?.[0]?.id,
      ratingTemplateGroupId:
        detailPosition?.groups?.ratingTemplateGroup?.[0]?.id,
      clusterGroupId: clusterIds
    });
  delete payload.directManager;
  delete payload.indirectManager;
  const { data } =
    detailPosition?.id !== 'new'
      ? await editPosition(userId, idPosition, payload)
      : await savePosition(userId, payload);
  return data ? true : false;
};

const useUserManagement = create((set) => ({
  editedPosition: {},
  editedInfoData: {},
  isButtonSaveShow: {},
  isCancelChanges: null,
  isRefetchList: false,
  isError: false,
  toastData: { isShow: false, message: '', isWarning: false },
  isEditingBackground: false,
  previousBehaviorModelGroup: {},
  newBehaviorModelGroup: {},
  isFetchingNewBehaviorModelGroup: false,
  setEditedPosition: (pos) => set({ editedPosition: pos }),
  setEditedInfoData: (infoData) => set({ editedInfoData: infoData }),
  saveDataPosition: (pos, userId) => saveData(pos, userId),
  setToastData: (data) => set({ toastData: data }),
  setButtonSave: (data) => set({ isButtonSaveShow: data }),
  cancelChanges: (data) => set({ isCancelChanges: data }),
  refetchList: (data) => set({ isRefetchList: data }),
  setIsError: (data) => set({ isError: data }),
  setIsEditingBackground: (data) => set({ isEditingBackground: data }),
  setPreviousBehaviorModelGroup: (data) =>
    set({ previousBehaviorModelGroup: data }),
  setNewBehaviorModelGroup: (data) => set({ newBehaviorModelGroup: data }),
  setIsFetchingNewBehaviorModelGroup: (data) =>
    set({ isFetchingNewBehaviorModelGroup: data })
}));

export default useUserManagement;

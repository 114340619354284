import client from './ApiClient';

function requestFeedback(teamId) {
  return client(`v1/teams/${teamId}/feedback`, { method: 'POST', body: {} });
}

function updateTeam(teamId, body) {
  return client(`v1/teams/${teamId}`, { method: 'PUT', body: body });
}

function archiveTeam(teamId) {
  return client(`v1/teams/${teamId}/archive`, { method: 'PATCH' });
}

function reactivateTeam(teamId) {
  return client(`/v1/teams/${teamId}/restore`, { method: 'PATCH' });
}

export { requestFeedback, updateTeam, archiveTeam, reactivateTeam };

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';

const DropdownTrigger = ({ selectedValueName }) => {
  return (
    <Dropdown.Trigger dataCy="advance-sort">
      <div className="flex justify-between items-center bg-n-000 h-[32px] py-[8px] pr-[4px] pl-[16px] min-w-[180px] max-w-[224px] border border-solid border-n-400 rounded-[4px]">
        <div className="w-[124px]">
          <TruncateParagraph className="mr-[4px] typography-h400">
            {getObjectiveLocale('Sort')} by: {selectedValueName}
          </TruncateParagraph>
        </div>
        <SVGIcon
          size="24"
          fillColor="var(--n-600)"
          iconName="icon-unfold_more"
        />
      </div>
    </Dropdown.Trigger>
  );
};

function Sort({ options, onSelect, selectedValue }) {
  options = options ?? [
    {
      direction: 'asc',
      id: 0,
      name: `${getObjectiveLocale('Due Date')}`,
      type: 'due_date'
    },
    {
      direction: 'desc',
      id: 1,
      name: `${getObjectiveLocale('Newest')}`,
      type: 'created_at'
    },
    {
      direction: 'asc',
      id: 2,
      name: `${getObjectiveLocale('Oldest')}`,
      type: 'created_at'
    },
    {
      direction: 'asc',
      id: 3,
      name: `${getObjectiveLocale('A to Z')}`,
      type: 'name'
    },
    {
      direction: 'desc',
      id: 4,
      name: `${getObjectiveLocale('Z to A')}`,
      type: 'name'
    }
  ];

  const selectedValueName =
    options.find(
      (option) =>
        option.direction === selectedValue.direction &&
        option.type === selectedValue.type
    )?.name || options[0].name;

  const onSelectSort = (sortData) => {
    onSelect({ direction: sortData.direction, type: sortData.type });
  };

  return (
    <div className="ml-[8px]">
      <Dropdown>
        <DropdownTrigger selectedValueName={selectedValueName} />
        <Dropdown.MenuItems type="fixed">
          {options.map((list, index) => (
            <Dropdown.MenuItem
              key={index}
              customClass="!min-w-[180px] !max-w-[224px]"
              onClick={() => onSelectSort(list)}
              dataCy="list-sort"
            >
              {list.name}
            </Dropdown.MenuItem>
          ))}
        </Dropdown.MenuItems>
      </Dropdown>
    </div>
  );
}

export default Sort;

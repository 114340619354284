import React from 'react';

import TextArea from 'components/shared/TextArea';
import ContentWrapper from 'components/sidebar/sidebar-create/ContentWrapper';

const InputObjectiveDescription = ({
  objectiveValue,
  setObjectiveValue,
  disabled = false
}) => {
  const onChangeHandler = (e) => {
    const value = e?.target?.value || '';
    setObjectiveValue((draft) => {
      draft.description = value;
    });
  };

  return (
    <ContentWrapper
      iconName="icon-notes"
      iconCustomClass="mt-[6px]"
      customWrapperClass="px-[24px] py-[6px]"
      dataCy="sidebar-create-description"
    >
      <TextArea
        name="description"
        onHandleChange={onChangeHandler}
        textPlaceholder="Description"
        value={objectiveValue.description || ''}
        className="text-area-description typography-paragraph"
        isLimitedHeight={false}
        showBorderOnHover
        disabled={disabled}
      />
    </ContentWrapper>
  );
};

export default InputObjectiveDescription;

import React, { useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalReminderList from '../components/ModalReminderList';
import VerifySection from './VerifySection';

const NotificationReminderVerify = ({ cycleData }) => {
  const [modal, setModal] = useState({ show: false, reminders: [], phase: '' });

  const notificationReminderContent = (phase) => {
    let reminderArray = cycleData[phase]?.reminders;
    if (reminderArray?.length > 0) {
      return (
        <p
          className="typography-paragraph text-base-600 cursor-pointer"
          onClick={() =>
            setModal({ show: true, reminders: reminderArray, phase: phase })
          }
        >
          {reminderArray?.length}{' '}
          {reminderArray?.length > 1 ? 'reminders' : 'reminder'}
        </p>
      );
    } else {
      return (
        <p className="typography-paragraph typography-tertiary">
          {reminderArray === undefined
            ? 'Not an active phase'
            : 'Not using reminder'}
        </p>
      );
    }
  };

  let notificationReminderArray = [
    {
      name: getObjectiveLocale('Self'),
      content: notificationReminderContent('selfReview')
    },
    {
      name: getObjectiveLocale('Upward'),
      content: notificationReminderContent('upwardReview')
    },
    {
      name: getObjectiveLocale('Peer'),
      content: notificationReminderContent('peerReview')
    },
    {
      name: getObjectiveLocale('Manager'),
      content: notificationReminderContent('managerReview')
    },
    {
      name: getObjectiveLocale('Indirect Manager'),
      content: notificationReminderContent('indirectManagerReview')
    }
  ];

  return (
    <>
      <VerifySection
        title="Notification reminder"
        dataArray={notificationReminderArray}
        className="py-[40px]"
        withBorder={false}
      />
      {modal?.show && (
        <ModalReminderList
          cycleData={cycleData}
          phase={modal?.phase}
          reminders={modal?.reminders}
          setModal={setModal}
        />
      )}
    </>
  );
};

export default NotificationReminderVerify;

import React from 'react';
import Linkify from 'react-linkify';

import SVGIcon from 'components/shared/SVGIcon';
import WhiteCardEvidence from 'components/shared/card/WhiteCardEvidence.js';

import ProfileEvidence from './ProfileEvidence';

const CardBehaviorRecognitionSidebar = ({ answersData, trackType }) => {
  const { recognition, feedback } = answersData;
  return (
    <div
      className="card-behavior-recognition mt-[24px]"
      id="container-behavior-recognition-sidebar"
    >
      {recognition?.length > 0 &&
        recognition.map((data, index) => (
          <WhiteCardEvidence
            key={index}
            title={'Masukan & Arahan Pengembangan'}
          >
            <ProfileEvidence profileData={data?.assignment?.actor} />
            <div
              className="wrapper-description-detail ml-[40px]"
              data-cy="feedback-container"
            >
              {data.answers.map((answer, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="flex items-center text-g-800 mt-[12px] mb-[8px]">
                      <div
                        className={`flex justify-center items-center img-24 rounded-full ${
                          answer?.object?.title ? 'bg-g-200' : 'bg-n-200'
                        }`}
                      >
                        <SVGIcon
                          iconName={
                            answer?.object?.title
                              ? 'icon-thumb_up_alt'
                              : 'icon-info'
                          }
                          size="16"
                          fillColor={
                            answer?.object?.title
                              ? 'var(--g-600)'
                              : 'var(--n-600)'
                          }
                        />
                      </div>
                      <p
                        className={`typography-h400 ml-[8px] ${
                          answer?.object?.title ? 'text-g-700' : 'text-n-700'
                        }`}
                        data-cy="behavior-title"
                      >
                        {answer?.object?.title
                          ? answer?.object?.title
                          : 'No Value'}
                      </p>
                    </div>
                    <div className="mt-[8px]">
                      <Linkify
                        className="typography-paragraph text-n-800 whitespace-pre-wrap break-words"
                        properties={{ target: '_blank' }}
                      >
                        {answer?.comment}
                      </Linkify>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </WhiteCardEvidence>
        ))}
      {feedback?.length > 0 &&
        feedback.map((data, index) => {
          return (
            <WhiteCardEvidence
              key={index}
              title={'Masukan & Arahan Pengembangan'}
            >
              <ProfileEvidence profileData={data?.assignment?.actor} />
              <div
                className="wrapper-description-detail ml-[40px]"
                data-cy="feedback-container"
              >
                {data.answers.map((answer, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="flex items-center text-y-800 mt-[12px] mb-[8px]">
                        <div
                          className={`flex justify-center items-center img-24 rounded-full ${
                            answer?.object?.title ? 'bg-y-200' : 'bg-n-200'
                          }`}
                        >
                          <SVGIcon
                            iconName={
                              answer?.object?.title
                                ? 'icon-feedback'
                                : 'icon-info'
                            }
                            size="16"
                            fillColor={
                              answer?.object?.title
                                ? 'var(--y-600)'
                                : 'var(--n-600)'
                            }
                          />
                        </div>
                        <p
                          className={`typography-h400 ml-[8px] ${
                            answer?.object?.title ? 'text-y-700' : 'text-n-700'
                          }`}
                          data-cy="behavior-title"
                        >
                          {answer?.object?.title
                            ? answer?.object?.title
                            : 'No Value'}
                        </p>
                      </div>
                      <div className="mt-[8px]">
                        <Linkify
                          className="typography-paragraph text-n-800 whitespace-pre-wrap break-words"
                          properties={{ target: '_blank' }}
                        >
                          {answer?.comment}
                        </Linkify>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </WhiteCardEvidence>
          );
        })}
    </div>
  );
};

export default CardBehaviorRecognitionSidebar;

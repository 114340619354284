import React from 'react';

import { updateWeightConfig } from 'client/ObjectivesClient';

import Divider from 'components/design-system/Divider';

import WeightSettingsObjCategories from './components/WeightSettingsObjCategories';
import WeightSettingsObjOptions from './components/WeightSettingsObjOptions';
import WeightSettingsPreference from './components/WeightSettingsPreference';
import WeightSettingsCard from './fragments/WeightSettingsCard';

const WeightSettingsType = ({ configData, setConfigData, setIsSaving }) => {
  const {
    objectiveWeightType,
    mandatoryObjectivesCategory,
    useDefaultObjectiveCategory,
    objectiveWeightTypeOptions
  } = configData; // PERMISSION REMINDER

  const changeConfigData = async (config) => {
    const { isSuccess } = await updateWeightConfig(config);
    setIsSaving(true);
    if (isSuccess) {
      setConfigData((data) => ({ ...data, ...config }));
      return true;
    }
    return false;
  };

  const toggleFunction = async ({ value }) => {
    const weightType = value ? 'type' : 'percentage';
    const isSuccess = await changeConfigData({
      objectiveWeightType: weightType
    });
    return isSuccess;
  };

  return (
    <WeightSettingsCard
      title={'Goal type'}
      subTitle={'Set the organization goal type (minimum 2, maximum 15)'} // change to variables
      toggleCallback={toggleFunction}
      isOpen={objectiveWeightType == 'type'}
      clearContentSpace
      toggleCy={'toggle-type'}
    >
      <div className="flex flex-row">
        <WeightSettingsPreference
          defaultValue={mandatoryObjectivesCategory}
          changeConfigData={changeConfigData}
        />
        <WeightSettingsObjOptions
          defaultValue={objectiveWeightTypeOptions}
          changeConfigData={changeConfigData}
        />
      </div>
      <Divider />
      <WeightSettingsObjCategories
        useDefaultType={useDefaultObjectiveCategory}
        changeConfigData={changeConfigData}
      />
    </WeightSettingsCard>
  );
};

export default WeightSettingsType;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import { getUser, getUserCulture } from 'client/UserProfile';
import { ColleagueProvider } from 'context/ColleagueContext';
import { ProfileFilterProvider } from 'context/ProfileFilterContext';
import { useUser } from 'context/UserContext';
import { useLocale } from 'hooks/useLocale';
import usePermission from 'hooks/usePermission';
import usePrevUser from 'hooks/usePrevUser';

import HeaderPage from 'components/admin/HeaderPage';
import BannerBox from 'components/shared/BannerBox';
import LoadingComponent from 'components/shared/LoadingComponent';
import ObjectiveEmptyState from 'components/shared/ObjectiveEmptyState';
import UserProfileContent from 'components/user-profile/UserProfileContent';
import UserProfileInfo from 'components/user-profile/UserProfileInfo';

function UserProfile(props) {
  const { user, appType } = useUser();
  const history = useHistory();
  const { setBothLocale } = useLocale();
  const [performanceProfile, setPerformanceProfile] = useState(null);
  const [cultureProfile, setCultureProfile] = useState(null);
  const [permission, setPermission] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userId, placementId } = useParams();
  const [objectivesVisibility, setObjectiveVisibility] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const isSourceDirectReport = urlParams.get('source') === 'direct-report';

  const setPrevUser = usePrevUser((state) => state?.setUser);
  const _getPerformanceProfile = async () => {
    const { data } = await getUser(userId, { placementId: placementId });
    setPerformanceProfile(data);
    setPermission(data?.permissions);
    setObjectiveVisibility(data?.objectivesVisibility);
    setPrevUser(data);
  };

  const _getCultureProfile = async () => {
    setIsLoading(true);
    const { data } = await getUserCulture(userId);
    setCultureProfile(data);
    setPermission(data?.permissions);
  };

  const _getData = async () => {
    setIsLoading(true);
    (appType === 'culture' && user.cultureRole) || !user.role
      ? await _getCultureProfile()
      : await _getPerformanceProfile();
    setIsLoading(false);
  };

  useEffect(() => {
    if (isNaN(userId) || isNaN(placementId)) {
      history.replace(`/users/${user?.id}/placement/${user?.placement?.id}`);
    } else {
      _getData();
    }
  }, [userId, placementId]);

  return (
    <>
      <HeaderPage
        titlePage={props.route.title}
        breadcrumbsData={
          isSourceDirectReport && [
            {
              link: 'goals/reports/direct?placement=former',
              title: 'back to Direct Report'
            }
          ]
        }
      />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div className="viewport-wrapper user-profile">
          {!performanceProfile?.currentPosition && appType !== 'culture' && (
            <BannerBox type="info" customClass="mt-[24px]">
              {setBothLocale(
                'You are currently viewing the previous position details of this user.'
              )}
            </BannerBox>
          )}
          {(performanceProfile?.state === 'deleted' ||
            cultureProfile?.state === 'deleted') && (
            <BannerBox type="error" customClass="mb-[24px] mt-[8px]">
              {setBothLocale('This user has been deactivated')}
            </BannerBox>
          )}
          <div className="row mt-[24px] flex">
            <div className="col-md-3 user-left-section">
              <ColleagueProvider>
                <UserProfileInfo
                  userId={userId}
                  placementId={placementId}
                  getData={_getData}
                  performanceProfile={performanceProfile}
                  cultureProfile={cultureProfile}
                  permission={permission}
                />
              </ColleagueProvider>
            </div>
            {usePermission('profileSee', permission) ? (
              <ProfileFilterProvider>
                <div className="col-md-9 user-right-section">
                  <UserProfileContent
                    permission={permission}
                    performanceProfile={performanceProfile}
                    cultureProfile={cultureProfile}
                    setCultureProfile={setCultureProfile}
                    objectivesVisibility={objectivesVisibility}
                  />
                </div>
              </ProfileFilterProvider>
            ) : (
              <ObjectiveEmptyState cantSee={true} isProfile={true} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(UserProfile);

import React, { useEffect, useState } from 'react';

import { getPlacementInfoKey } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import BannerBox from 'components/shared/BannerBox';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import PlacementSection from './PlacementSection';

function TerritoryBox({ info, setGroup }) {
  const { config } = useUser();
  const [listKeyPlacement, setListKeyPlacement] = useState([]);
  const placementKeys = Object.keys(info)?.filter(
    (data) => !data.includes('eid')
  );

  const getKeyPlacementInfo = async () => {
    const { data } = await getPlacementInfoKey();
    if (data) {
      setListKeyPlacement(data);
    }
  };

  const filteredListKeyPlacement = listKeyPlacement?.filter(
    (placementData) => !placementKeys.includes(placementData)
  );

  useEffect(() => {
    getKeyPlacementInfo();
  }, []);

  return (
    <div className="px-[24px] py-[24px]">
      <BannerBox
        text={getObjectiveLocale(
          'The territory you set here will only affecting permissions with value ‘All’.'
        )}
        type="info"
        customClass="!py-[8px] rounded-[8px]"
      />
      <div className="flex items-center">
        <p className="typography-h100 text-n-600 mb-[8px] w-[240px]">
          {getObjectiveLocale('Type')}
        </p>
        <p className="flex items-center typography-h100 text-n-600 mb-[8px] mr-[4px] w-[240px]">
          {getObjectiveLocale('Info Value')}
          <TooltipContainer
            useMaxWidth={false}
            wrap
            show
            text={getObjectiveLocale(
              `User's current territory will be used if disabled`
            )}
          >
            <SVGIcon
              size="16"
              fillColor="var(--n-600)"
              iconName="icon-info"
              customClass="cursor-pointer"
            />
          </TooltipContainer>
        </p>
      </div>
      {placementKeys?.map((placementKey, index) => {
        return (
          <PlacementSection
            key={index}
            listKeyPlacement={filteredListKeyPlacement}
            setGroup={setGroup}
            data={info}
            placementKey={placementKey}
          />
        );
      })}

      {config?.enablePermissionMultiTerritory && (
        <Button.Tertiary
          datacy="button-add-territory"
          customClass="mt-[16px]"
          onClick={() =>
            setGroup('info', {
              ...info,
              [filteredListKeyPlacement?.[0]]: 'self',
              [`${filteredListKeyPlacement?.[0]}_eid`]: 'self'
            })
          }
        >
          <SVGIcon
            iconName="icon-add"
            fillColor="var(--base-600)"
            size="24"
            customClass="cursor-pointer"
          />
          {getObjectiveLocale('Add Territory')}
        </Button.Tertiary>
      )}
    </div>
  );
}

export default TerritoryBox;

import React from 'react';

import SVGIcon from 'components/shared/SVGIcon';

const COMPLETED = 'completed';

const DependencyList = ({ lists }) => {
  const getIcon = (state) => {
    if (state == COMPLETED) {
      return 'icon-check_circle';
    }
    return 'icon-check_circle_outline';
  };

  const getIconColor = (state) => {
    if (state == COMPLETED) {
      return 'var(--g-600)';
    }
    return 'var(--n-600)';
  };

  return (
    <div>
      {lists?.map((list, index) => (
        <div
          className="flex flex-row items-center mt-[8px]"
          key={list?.id || index}
        >
          <SVGIcon
            iconName={getIcon(list?.state)}
            size="24"
            fillColor={getIconColor(list?.state)}
            customClass="mr-[8px]"
          />
          <p className="typography-paraghraph text-n-900">{list?.name}</p>
        </div>
      ))}
    </div>
  );
};

const Dependencies = ({ blocking, blockedBy }) => {
  return (
    <div className="px-[24px] py-[12px] flex flex-row">
      <SVGIcon
        iconName={'icon-hourglass_empty'}
        size="24"
        fillColor="var(--n-600)"
        customClass="mr-[16px]"
      />
      <div className="flex flex-col">
        {blockedBy?.length > 0 && (
          <div
            className={`flex flex-col ${
              blocking?.length > 0 ? 'mb-[16px]' : ''
            } `}
          >
            <p className="typography-h100 text-n-600">
              This task is blocked by
            </p>
            <DependencyList lists={blockedBy} />
          </div>
        )}
        {blocking?.length > 0 && (
          <div className="flex flex-col">
            <p className="typography-h100 text-n-600">This task is blocking</p>
            <DependencyList lists={blocking} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dependencies;

import React, { useMemo, useState } from 'react';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import {
  getObjectiveType,
  getSelectedOwner,
  getSelectedReviewer
} from 'utils/ObjectivesHelper';

import ModalAlignParent from 'components/modal/ModalAlignParent';
import Badge from 'components/shared/Badge';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const Alignment = React.memo(
  ({
    task,
    setTask,
    isSubTask,
    setIsTaskClickable,
    type = 'list',
    defaultParentName,
    groupData,
    filter
  }) => {
    const {
      config: { enableTaskMultiParentAlignment }
    } = useUser();
    const { refetchObjective, invalidateTaskQueries } = useRefetchQuery();

    const [showModal, setShowModal] = useState(false);

    const { permissions } = task;
    const { showTask } = filter || {};

    let arrayAssignee = getSelectedOwner(task)?.map(({ userId }) => userId);
    let arrayAssigner = getSelectedReviewer(task)?.map(({ userId }) => userId);

    const assigneeId = {
      assignee_id: arrayAssignee,
      assigner_id: arrayAssigner
    };

    const refetchGroup = async (newParent) => {
      await Promise.all([
        invalidateTaskQueries({ task, groupData, refetchActive: true }),
        invalidateTaskQueries({
          task: newParent,
          groupData,
          refetchActive: true
        })
      ]);
    };

    const saveAlignment = async (tempParentIds, tempParents) => {
      const newParent = tempParents?.[0] || null;
      const parentType = getObjectiveType(newParent);

      const { isSuccess } = await editObjective(task.id, {
        parentIds: tempParentIds
      });
      if (isSuccess) {
        if (
          showTask === 'personal' && !isSubTask ? parentType != 'goal' : true
        ) {
          await refetchGroup(newParent);
        }

        refetchObjective(task?.parent?.id);
        refetchObjective(task?.id);
        refetchObjective(tempParentIds[0]);
      }

      setIsTaskClickable && setIsTaskClickable(true);
      setShowModal(false);
    };

    return (
      <div
        className={type === 'list' ? 'flex items-center w-full h-full' : ''}
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        data-cy="trigger-alignment"
      >
        {type === 'list' ? (
          <TaskListAlignment
            task={task}
            enableTaskMultiParentAlignment={enableTaskMultiParentAlignment}
            setShowModal={setShowModal}
            defaultParentName={defaultParentName}
          />
        ) : (
          <TaskBoardAlignment
            task={task}
            defaultParentName={defaultParentName}
            enableTaskMultiParentAlignment={enableTaskMultiParentAlignment}
          />
        )}

        {showModal && (
          <ModalAlignParent
            objectiveValue={task}
            setObjectiveValue={setTask}
            assigneeId={assigneeId}
            permissions={permissions}
            closeModal={() => setShowModal(false)}
            handleChangeParent={saveAlignment}
            useTaskParentSuggestion
            useMultipleParentAlignment={enableTaskMultiParentAlignment}
          />
        )}
      </div>
    );
  }
);

const AlignmentBadge = ({ name, maxWidth, setShowModal }) => {
  return (
    <TooltipContainer
      show
      text={name}
      position="top"
      useMaxWidth={false}
      classContainer="px-[16px]"
    >
      <Badge
        truncate
        customMaxWidth={maxWidth}
        content={name}
        defaultPointer
        useEvent
        onClick={() => setShowModal(true)}
      />
    </TooltipContainer>
  );
};

const TaskListAlignment = ({
  task,
  enableTaskMultiParentAlignment,
  setShowModal,
  defaultParentName
}) => {
  const { parent, parents } = task;

  if (enableTaskMultiParentAlignment) {
    return (
      <>
        {parents?.length > 0 &&
          parents?.map((parent, index) => {
            if (index < 2) {
              return (
                <AlignmentBadge
                  name={parent?.name}
                  maxWidth={
                    parents.length > 2
                      ? 'max-w-[100px]'
                      : parents.length > 1
                      ? 'max-w-[120px]'
                      : enableTaskMultiParentAlignment
                      ? 'max-w-[247px]'
                      : 'max-w-[160px]'
                  }
                  setShowModal={setShowModal}
                  key={index}
                />
              );
            }
          })}
      </>
    );
  }

  if (parent) {
    return (
      <AlignmentBadge
        name={parent?.name}
        maxWidth={
          parents.length > 2
            ? 'max-w-[100px]'
            : parents.length > 1
            ? 'max-w-[120px]'
            : enableTaskMultiParentAlignment
            ? 'max-w-[247px]'
            : 'max-w-[160px]'
        }
        setShowModal={setShowModal}
      />
    );
  }

  if (defaultParentName) {
    return (
      <AlignmentBadge
        name={defaultParentName}
        maxWidth="max-w-[247px]"
        setShowModal={setShowModal}
      />
    );
  }

  return null;
};

const TaskBoardAlignment = ({
  task,
  defaultParentName,
  enableTaskMultiParentAlignment
}) => {
  const [isEllipsis, setIsEllipsis] = useState(false);

  const { parent, parents } = task;

  const objectiveParents = useMemo(() => {
    return enableTaskMultiParentAlignment ? parents : [parent];
  }, [enableTaskMultiParentAlignment, parent, parents]);

  const parentNames = useMemo(() => {
    return (
      objectiveParents?.reduce((result, parent, index) => {
        let string = result + parent.name;
        if (index < objectiveParents.length - 1) {
          string += ', ';
        }
        return string;
      }, '') || ''
    );
  }, [objectiveParents]);

  return (
    <>
      {(parents?.length > 0 || defaultParentName) && (
        <TooltipContainer
          show={isEllipsis}
          text={parentNames}
          position="top"
          useMaxWidth={false}
          classContainer="w-full h-full flex items-center relative"
          dataCy="trigger-alignment"
        >
          <p
            ref={(ref) => setIsEllipsis(ref?.offsetWidth < ref?.scrollWidth)}
            className="typography-h100 truncate typography-h100 text-n-600 h-[16px] mb-[8px] cursor-pointer"
          >
            {parentNames || defaultParentName}
          </p>
        </TooltipContainer>
      )}
    </>
  );
};

Alignment.displayName = 'Alignment';

export default Alignment;

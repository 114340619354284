import React from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import { useUser } from 'context/UserContext';
import { getDateWithForcedTimezone } from 'utils/DateUtils';
import { formattedNumber, getObjectiveLocale } from 'utils/HelperUtils';
import getListBadge from 'utils/ListBadgeHelper';
import {
  getGoalOwnersRoles,
  getInvolvement,
  getValue
} from 'utils/ObjectivesHelper';

import AWSImage from 'components/design-system/AWSImage';
import ListLabel from 'components/objectives/ListLabel';
import ProgressBar from 'components/objectives/ProgressBar';
import Avatar from 'components/shared/Avatar';
import CardLabel from 'components/shared/CardLabel/CardLabel';
import ProfileSection from 'components/shared/Profile/ProfileSection';
import SVGIcon from 'components/shared/SVGIcon';

import './_card.scss';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

function Card({
  customClass,
  objective,
  dataCy,
  onClickCard = () => null,
  withRightIcon,
  withInvolvement,
  type,
  isAbleToUpdate,
  updateProgress,
  clusters
}) {
  let {
    id,
    involvements = [],
    dueDate,
    measurement,
    permissions = [],
    currentUserAs,
    progress,
    description,
    teamPicture,
    stateObject,
    state,
    calculationType,
    integrations = []
  } = objective;

  permissions = permissions ?? [];
  const { config } = useUser();
  const formattedDueDate = dueDate
    ? getDateWithForcedTimezone(dueDate, 'DD MMM YYYY')
    : '-';

  let goalOwners = objective?.involvements?.filter(
    owner => owner?.role === 'assignee' && owner?.visible
  );

  const { roles } = getGoalOwnersRoles(
    goalOwners,
    config?.userSubtitleField == 'job_title'
  );
  const { sortedAssignee, sortedNonAssignee } = getInvolvement(
    type,
    involvements
  );
  const {
    unit,
    currentValue,
    targetValue,
    valueLabel,
    formattedCurrentValue,
    formattedTargetValue
  } = getValue(measurement);

  const hasCluster = key => clusters?.includes(key);

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: 'Few seconds ago',
      ss: 'Few seconds ago',
      m: '%d minute ago',
      mm: '%d minutes ago',
      h: '%d hour ago',
      hh: '%d hours ago',
      d: 'Yesterday',
      dd: '%d days ago',
      M: 'Last month',
      MM: '%d months ago',
      y: 'Last year',
      yy: '%d years ago'
    }
  });

  const listBadge = getListBadge({
    listBadge: ['weight', 'goalType', 'history'],
    objective: objective
  });

  return (
    <div
      className={`obj-card typography-h500-longform ${
        type === 'goal' ? 'h-[252px]' : 'h-[236px]'
      } ${customClass && customClass}`}
      data-cy={dataCy}
      onClick={e => {
        e.stopPropagation();
        onClickCard();
      }}
    >
      <div className="top">
        {/* Title + icon header */}
        <div className="title-card">
          <div className="title-section">
            {type === 'team' && teamPicture && (
              <AWSImage src={teamPicture} className="team-icon" />
            )}
            {objective.type === 'task' && !objective.isProject && (
              <SVGIcon
                iconName="icon-task"
                fillColor="var(--n-600)"
                size="16"
                customClass="mr-[16px]"
              />
            )}
            {objective.isProject && (
              <SVGIcon
                iconName="icon-project-badge"
                fillColor="var(--n-400)"
                size="24"
                customClass="mr-[16px]"
              />
            )}
            <p
              className="typography-h500 multiline-text-2"
              data-cy="goal-tree-title"
            >
              {objective.name}
            </p>
            {integrations?.[0]?.integration?.integrationType === 'jira' &&
              config?.permissions?.jiraComponentSee && (
                <SVGIcon
                  iconName="icon-jira"
                  size={16}
                  customClass="ml-[16px] py-[2px]"
                />
              )}
          </div>
          {withRightIcon && (
            <div data-cy="goal-tree-state">
              {objective.overdue === true ? (
                <SVGIcon
                  iconName="icon-overdue"
                  fillColor="var(--y-600)"
                  size="24"
                />
              ) : stateObject?.state === 'reviewed' || state === 'reviewed' ? (
                <SVGIcon
                  iconName="icon-reviewed"
                  fillColor="var(--base-600)"
                  size="24"
                />
              ) : (
                (stateObject?.state === 'completed' ||
                  state === 'completed') && (
                  <SVGIcon
                    iconName="icon-thin-check"
                    fillColor="var(--g-600)"
                    size="24"
                  />
                )
              )}
            </div>
          )}
        </div>
        {/* Label */}
        <div className="objective-label mt-[8px]">
          <ListLabel data={listBadge} centered={false} />
          {type === 'team' && (
            <CardLabel
              title={currentUserAs}
              iconLabel={currentUserAs === 'leader' ? 'leader' : 'member'}
              isTextPurple={currentUserAs === 'leader'}
            />
          )}
        </div>
        {/* Profile Section */}
        {type === 'goal' && (
          <div className="profile-goal-owner">
            <ProfileSection
              showDescription={goalOwners?.length > 0}
              profilePicture={goalOwners?.[0]?.user?.profilePicture}
              name={goalOwners?.[0]?.user?.name}
              desc={roles}
              isSingleProfile={goalOwners?.length === 1}
              totalOwners={goalOwners?.length}
              nameSize="h200"
            />
            {hasCluster('dueDate') ? (
              dueDate &&
              formattedDueDate !== '-' && (
                <span
                  data-cy="goal-card-due-date"
                  className="typography-h200 text-n-800"
                >
                  {formattedDueDate}
                </span>
              )
            ) : (
              <div data-cy="goal-card-due-date" className="text-n-600">
                Due date not visible
              </div>
            )}
          </div>
        )}
        {/* Description */}
        {type !== 'goal' && (
          <div className="typography-paragraph multiline-text-2 mt-[12px] text-n-600">
            {description}
          </div>
        )}
      </div>
      <div
        className={`bottom ${
          type !== 'team' ? 'border-solid border-t border-0 border-n-400' : ''
        }`}
      >
        {/* Progress Bar */}
        {objective.measurement ? (
          <div>
            {!hasCluster('progress') && !hasCluster('metric') ? (
              <div
                data-cy="goal-card-no-progress-metric"
                className="typography-paragraph text-n-600"
              >
                Metric & progress not visible
              </div>
            ) : (
              <>
                {hasCluster('progress') ? (
                  <ProgressBar
                    measurement={measurement}
                    permission={permissions}
                    objectiveId={id}
                    customTitle={type === 'goal' && ' Progression'}
                    progressWidth="w-full"
                    isAbleToUpdate={
                      isAbleToUpdate &&
                      permissions?.includes('update_current_value')
                    }
                    updateProgress={updateProgress}
                    calculationType={calculationType}
                    useNewProgressBar
                  />
                ) : (
                  <div className="typography-paragraph text-n-600">
                    Progress not visible
                  </div>
                )}
                {/* Description unit */}
                {type === 'goal' &&
                  (hasCluster('metric') ? (
                    <div
                      className="display-value typography-paragraph mt-[8px] multiline-text-1"
                      data-cy="goal-tree-value"
                    >
                      {valueLabel} {unit == 'USD' && 'Dollar (USD)'}{' '}
                      {unit === '%' &&
                        getObjectiveLocale('Percentage') + ' (%)'}{' '}
                      {unit === '#' && getObjectiveLocale('Metrics') + ' (#)'}{' '}
                      {unit === 'IDR' && 'Rupiah (IDR)'}{' '}
                      {(currentValue
                        ? formattedNumber(parseInt(formattedCurrentValue))
                        : 0) +
                        ' / ' +
                        (targetValue
                          ? formattedNumber(formattedTargetValue)
                          : 0)}
                    </div>
                  ) : (
                    <div className="typography-paragraph text-n-600">
                      Metric not visible
                    </div>
                  ))}
              </>
            )}
          </div>
        ) : (
          type !== 'team' && (
            <div
              className="typography-paragraph text-n-600 mt-[0px] my-[24px]"
              data-cy="goal-card-no-progress-metric"
            >
              {objective.measurement === null
                ? 'No metric and progress'
                : 'Metric & progress not visible'}
            </div>
          )
        )}

        {/* Description progress */}
        {progress && type === 'team' && (
          <div className="status">
            {progress === 'left_behind' ? (
              <span className="left-behind text-y-600 flex items-center">
                <SVGIcon
                  iconName="icon-circle"
                  fillColor="var(--y-600)"
                  size="8"
                  customClass="mr-[4px]"
                />
                {getObjectiveLocale('Left behind')}
              </span>
            ) : progress === 'on_track' ? (
              <span className="on-track text-g-600 flex items-center">
                <SVGIcon
                  iconName="icon-circle"
                  fillColor="var(--g-600)"
                  size="8"
                  customClass="mr-[4px]"
                />
                {getObjectiveLocale('On track')}
              </span>
            ) : (
              <span className="at-risk text-r-600 flex items-center">
                <SVGIcon
                  iconName="icon-circle"
                  fillColor="var(--r-600)"
                  size="8"
                  customClass="mr-[4px]"
                />
                {getObjectiveLocale('At risk')}
              </span>
            )}
          </div>
        )}
        {/* Involvement Picture */}
        {withInvolvement && (
          <div className="involvement-list mt-[16px]">
            {sortedAssignee?.length > 0 &&
              sortedAssignee.map((assignee, index) => (
                <Avatar
                  src={assignee?.user?.profilePicture || ''}
                  name={assignee?.user?.name}
                  size={24}
                  key={index}
                  className="picture-involvement"
                  dataCy="project-involvement"
                />
              ))}
            {sortedAssignee?.length > 0 && sortedNonAssignee?.length > 0 && (
              <div className="w-[1px] mx-[4px] bg-n-600" />
            )}
            {sortedNonAssignee.slice(0, 6).map((involvement, index) => (
              <Avatar
                src={involvement?.user?.profilePicture || ''}
                name={involvement?.user?.name}
                size={24}
                className="picture-involvement"
                key={index}
              />
            ))}
            {sortedNonAssignee?.length > 6 && (
              <div className="extra-count typography-h100">{`+${sortedNonAssignee.length -
                6}`}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Card;

import React from 'react';

import {
  formatBytes,
  getCultureLocale,
  strToMiddleEllipsis
} from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

function PostAttachment({ content, postId }) {
  return content.map((file) => {
    return (
      <div
        className="feed-attachment"
        data-cy={`post-attachment-${postId}`}
        key={file.filename}
      >
        <div className="title">
          <SVGIcon
            iconName="icon-attach_file"
            size={24}
            fillColor="var(--n-600)"
          />
          <div className="typography-h500 text-n-900" data-cy="file-name">
            {strToMiddleEllipsis(15, file.filename)}
          </div>
        </div>
        <div className="size-download" data-cy="download-attachment">
          <div className="typography-h500 text-n-600" data-cy="file-size">
            {formatBytes(file.size)}
          </div>
          <a
            href={file.url}
            className="typography-h500 text-base-600 mt-[0px] ml-[8px]"
          >
            {getCultureLocale('Download')}
          </a>
        </div>
      </div>
    );
  });
}

export default PostAttachment;

import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { getConfig } from 'client/admin/Config';
import { useUser } from 'context/UserContext';
import useOrganization from 'hooks/useOrganization';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';
import {
  CALC_TYPE_EARLY_COMPLETION,
  CALC_TYPE_LESS_IS_BETTER,
  CALC_TYPE_ZERO_TARGET
} from 'utils/const';

import MeasurementUnit from 'components/admin/organization/MeasurementUnit';
import ContentNavigator from 'components/shared/ContentNavigator';
import InputField from 'components/shared/InputField';

import {
  CardCheckBoxOrganization,
  CheckBoxOrganization,
  CheckboxWithDefaultOrganization,
  DropdownCard,
  DropdownOrganization,
  RadioButtonOrganization,
  ToggleCard,
  changeCheckboxData,
  changeUserData,
  checkTimeParams,
  setActiveNavigator
} from './OrganizationHelper';

const Objectives = ({ configParams }) => {
  const { organization } = useUser();
  const {
    configData,
    changeConfigData,
    changeOriginConfigData,
    measurementUnitData
  } = useOrganization((configData) => ({
    measurementUnitData: configData.measurementUnitData,
    configData: configData.configData,
    changeConfigData: configData.changeConfigData,
    changeOriginConfigData: configData.changeOriginConfigData
  }));
  const [activeSection, setActiveSection] = useState('activities-section');
  const isReadOnly =
    usePermission('settingsOrganizationObjectives') === 'read_only';

  const handleChangeCalculationType = (configValue) => {
    const currentCalculationType = configData?.objectiveCalculationType;
    if (currentCalculationType?.includes(configValue)) {
      changeConfigData({
        ...configData,
        objectiveCalculationType: currentCalculationType?.filter(
          (draft) => draft != configValue
        )
      });
    } else {
      changeConfigData({
        ...configData,
        objectiveCalculationType: [...currentCalculationType, configValue]
      });
    }
  };

  const removeTimeFilter = (data) => {
    const index = configData?.timeFilterOptions.indexOf(data);
    if (index > 0) {
      configData?.timeFilterOptions.splice(index, 1);
    }
  };

  const changeDefaultTimeFilter = (data) => {
    removeTimeFilter(data);
    const filterOptions = configData?.timeFilterOptions || {};
    const list = [...filterOptions];
    list.unshift(data);
    changeUserData(
      'timeFilterOptions',
      null,
      list,
      configData,
      changeConfigData
    );
  };

  let list = [
    { id: 'activities-section', name: 'Activities' },
    { id: 'alignment-section', name: 'Alignment' },
    { id: 'create-section', name: 'Create' },
    { id: 'metric-section', name: 'Metric' },
    { id: 'objective-section', name: 'Objective' },
    { id: 'owner-section', name: 'Owner' },
    { id: 'task-section', name: 'Task' },
    { id: 'threshold-section', name: 'Threshold' },
    { id: 'configuration-section', name: 'Objectives Configuration' },
    { id: 'team-section', name: 'Team' }
  ];

  const listActivities = [
    {
      element: 'checkbox',
      label: getObjectiveLocale('Allowed Attachment Extensions'),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.allowedAttachmentExtensions,
          'allowedAttachmentExtensions',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.allowedAttachmentExtensions,
      isSingleSelect: false,
      dataCy: 'sort-column-cycles',
      listOption: [
        { id: 'image/*', title: 'image' },
        { id: 'pdf', title: 'pdf' },
        { id: 'zip', title: 'zip' },
        { id: 'video/', title: 'video' },
        { id: 'audio/mpeg', title: 'audio/mpeg' },
        { id: 'rar', title: 'rar' },
        { id: '7z', title: '7z' },
        { id: 'csv', title: 'csv' },
        { id: 'doc', title: 'doc' },
        { id: 'key', title: 'key' },
        { id: 'ppt', title: 'ppt' },
        { id: 'pptx', title: 'pptx' },
        { id: 'xls', title: 'xls' },
        { id: 'xlsx', title: 'xlsx' },
        { id: 'docx', title: 'docx' }
      ].sort((a, b) => a.title.localeCompare(b.title)),
      disabled: isReadOnly
    },
    {
      element: 'checkbox',
      label: getObjectiveLocale('Filter Last Update'),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.filterLastUpdate,
          'filterLastUpdate',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.filterLastUpdate,
      dataCy: 'filterLastUpdate',
      listOption: [
        { id: 'last_week', title: 'Last Week' },
        { id: 'last_2_weeks', title: 'Last 2 Weeks' },
        { id: 'newer_than', title: 'Newer Than' },
        { id: 'older_than', title: 'Older Than' }
      ],
      disabled: isReadOnly
    },
    {
      element: 'dropdown',
      isChecked: configData?.objectiveCommentMentionTarget === 'all',
      selected: configData?.objectiveCommentMentionTarget,
      onChange: (data) =>
        changeConfigData({
          ...configData,
          objectiveCommentMentionTarget: data
        }),
      insideCardPermission: true,
      cardTitle: getObjectiveLocale('Allow to mention'),
      cardDescription: getObjectiveLocale(
        'This configuration will be applied when giving comment on objective and task.'
      ),
      dataCy: 'allowMention',
      listOption: [
        { id: 'all', title: 'All Users' },
        { id: 'involvement', title: 'Involved only' }
      ],
      disabled: isReadOnly,
      inputElement: [
        {
          element: 'radio',
          label: getObjectiveLocale('Set as follower by mentioning'),
          handleChange: (data) =>
            changeConfigData({
              ...configData,
              objectiveCommentMentionAddFollower:
                configData?.objectiveCommentMentionTarget === 'involvement'
                  ? 'involvement'
                  : data
            }),
          selected: configData?.objectiveCommentMentionAddFollower,
          isSingleSelect: true,
          dataCy: 'objectiveCommentMentionAddFollower',
          listOption: [
            { id: 'all', title: 'Yes' },
            { id: 'involvement', title: 'No' }
          ],
          isVisible: true,
          disabled: isReadOnly
        }
      ]
    }
  ];

  const listAlignment = [
    {
      element: 'checkbox',
      label: getObjectiveLocale('Filter Alignment'),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.filterAlignment,
          'filterAlignment',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.filterAlignment,
      dataCy: 'filterAlignment',
      listOption: [
        { id: 'no_alignment', title: 'No Alignment' },
        { id: 'aligned', title: 'Aligned' },
        { id: 'aligned_to_me', title: 'Aligned To Me' }
      ],
      disabled: isReadOnly
    }
  ];

  const milestoneTypes = [
    { id: 'quarterly', title: 'Quarterly' },
    { id: 'monthly', title: 'Monthly' }
  ];

  const arrayOfDates = Array.from(Array(31)).map((e, i) => {
    return { id: i + 1, title: (i + 1).toString() };
  });
  const daysOfWeek = [
    { id: 'sunday', title: 'Sunday' },
    { id: 'monday', title: 'Monday' },
    { id: 'tuesday', title: 'Tuesday' },
    { id: 'wednesday', title: 'Wednesday' },
    { id: 'thursday', title: 'Thursday' },
    { id: 'friday', title: 'Friday' },
    { id: 'saturday', title: 'Saturday' }
  ];

  const listCreate = [
    {
      element: 'radio',
      label: getObjectiveLocale('Default Objective Visibility Option'),
      handleChange: (data) =>
        changeConfigData({
          ...configData,
          defaultObjectiveVisibilityOption: data
        }),
      selected: configData?.defaultObjectiveVisibilityOption,
      isSingleSelect: true,
      dataCy: 'defaultObjectiveVisibilityOption',
      listOption: [
        { id: 'public', title: 'Public' },
        { id: 'private', title: 'Private' }
      ],
      isVisible: true,
      disabled: isReadOnly
    },
    {
      element: 'radio',
      label: getObjectiveLocale('Default Parent Objective Period'),
      handleChange: (data) =>
        changeConfigData({ ...configData, defaultPeriodOnCreate: data }),
      selected: configData?.defaultPeriodOnCreate,
      dataCy: 'defaultPeriodOnCreate',
      listOption: [
        { id: 'semester', title: 'Current Semester' },
        { id: 'quarter', title: 'Current Quarter' },
        { id: 'day', title: 'Today' },
        { id: 'year', title: 'Year' }
      ],
      isVisible: true,
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      isChecked: configData?.showCheckInButton,
      onChange: () =>
        changeConfigData({
          ...configData,
          showCheckInButton: !configData?.showCheckInButton
        }),
      cardTitle: getObjectiveLocale('Enable Check-in Feature'),
      cardDescription: getObjectiveLocale(
        'Enable user to use check-in feature'
      ),
      isVisible: true,
      disabled: isReadOnly,
      dataCy: 'show-check-in-button',
      inputElement: [
        {
          element: 'toggle',
          isChecked: configData?.checkinSetReminder,
          onChange: () =>
            changeConfigData({
              ...configData,
              checkinSetReminder: !configData?.checkinSetReminder
            }),
          cardTitle: getObjectiveLocale('Reminder Check-In'),
          cardDescription: getObjectiveLocale('Allow to set reminder Check-In'),
          isVisible: true,
          disabled: isReadOnly,
          dataCy: 'checkinSetReminder',
          inputElement: [
            {
              element: 'radio',
              label: getObjectiveLocale('Checkin Load Prioritize'),
              handleChange: (data) =>
                changeConfigData({
                  ...configData,
                  checkinLoadPrioritize: data
                }),
              selected: configData?.checkinLoadPrioritize,
              dataCy: 'checkinLoadPrioritize',
              listOption: [
                { id: 'corporate', title: 'Corporate' },
                { id: 'individual', title: 'Individual' }
              ],
              isVisible: true,
              disabled: isReadOnly
            },
            {
              element: 'radio',
              label: getObjectiveLocale('Recurrence Option'),
              handleChange: (data) =>
                changeUserData(
                  'every',
                  'checkinParams',
                  data,
                  configData,
                  changeConfigData
                ),
              selected: configData?.checkinParams?.every,
              dataCy: 'checkinParams-every',
              listOption: [
                { id: 'day', title: 'Day' },
                { id: 'week', title: 'Week' },
                { id: 'month', title: 'Month' },
                { id: 'year', title: 'Year' }
              ],
              isVisible: configData?.checkinLoadPrioritize === 'corporate',
              disabled: isReadOnly
            },
            {
              element: 'input',
              label: 'Reccurence Interval',
              placeholder: 'Reccurence Interval',
              handleChange: (e) =>
                changeUserData(
                  'interval',
                  'checkinParams',
                  parseInt(e.target.value),
                  configData,
                  changeConfigData
                ),
              value: configData?.checkinParams?.interval,
              dataCy: 'checkinParams-interval',
              type: 'number',
              isVisible: configData?.checkinLoadPrioritize === 'corporate',
              disabled: isReadOnly
            },
            {
              element: 'dropdown',
              label: getObjectiveLocale(
                configData?.checkinParams?.every == 'month'
                  ? 'Select Date of the Month'
                  : 'Select Day of the Week'
              ),
              onChange: (data) =>
                changeUserData(
                  'on',
                  'checkinParams',
                  data,
                  configData,
                  changeConfigData
                ),
              selected: configData?.checkinParams?.on,
              isSingleSelect: true,
              dataCy: 'checkinParams-on',
              listOption:
                configData?.checkinParams?.every == 'month'
                  ? arrayOfDates
                  : daysOfWeek,
              disabled: isReadOnly,
              isVisible:
                configData?.checkinLoadPrioritize === 'corporate' &&
                (configData?.checkinParams?.every == 'month' ||
                  configData?.checkinParams?.every == 'week')
            },
            {
              element: 'time',
              label: getObjectiveLocale('Select Hour of the Day'),
              handleChange: (data) =>
                changeUserData(
                  'at',
                  'checkinParams',
                  data.toISOString(),
                  configData,
                  changeConfigData
                ),
              selectedTime: configData?.checkinParams?.at,
              dataCy: 'checkinParams-at',
              isVisible: configData?.checkinLoadPrioritize === 'corporate',
              customClass: 'w-[184px]'
            },
            {
              element: 'calendar',
              label: getObjectiveLocale('Starts from'),
              onSelectDate: (data) =>
                changeUserData(
                  'starts',
                  'checkinParams',
                  data.toISOString(),
                  configData,
                  changeConfigData
                ),
              selectedDate: configData?.checkinParams?.starts,
              dataCy: 'checkinParams-starts',
              isSingle: 'true',
              isVisible: configData?.checkinLoadPrioritize === 'corporate',
              customClass: 'w-[240px]',
              disabledDate: Date.now(),
              disabled: isReadOnly
            }
          ]
        }
      ]
    },

    {
      element: 'toggle',
      isChecked: configData?.objectiveClone,
      onChange: () =>
        changeConfigData({
          ...configData,
          objectiveClone: !configData?.objectiveClone
        }),
      cardTitle: getObjectiveLocale('Enable objective cloning'),
      cardDescription: getObjectiveLocale(
        'Enable user can clone existing objective'
      ),
      isVisible: true,
      disabled: isReadOnly,
      dataCy: 'objectiveClone'
    },
    {
      element: 'toggle',
      isChecked: configData?.enableMultiParentAlignment,
      onChange: () =>
        changeConfigData({
          ...configData,
          enableMultiParentAlignment: !configData?.enableMultiParentAlignment
        }),
      cardTitle: getObjectiveLocale('Enable multiple parent alignment'),
      cardDescription: getObjectiveLocale(
        'Enable user to align goal with multiple parent goal'
      ),
      isVisible: true,
      disabled: isReadOnly,
      dataCy: 'objectiveMultipleParents'
    },
    {
      element: 'toggle',
      isChecked: configData?.objectiveMilestonesFeature,
      onChange: () =>
        changeConfigData({
          ...configData,
          objectiveMilestonesFeature: !configData?.objectiveMilestonesFeature
        }),
      cardTitle: getObjectiveLocale('Enable Milestone on Objectives'),
      cardDescription: getObjectiveLocale(
        'Enable user to set milestone per decided period on objectives'
      ),
      isVisible: true,
      disabled: isReadOnly,
      dataCy: 'objective-milestones-feature',
      useChild: true,
      subElement: {
        element: 'checkbox',
        label: getObjectiveLocale('Milestone types'),
        customClass: ' ',
        handleChange: (data) => {
          let selectedType = [];
          if (configData.objectiveMilestoneTypes.includes(data.id)) {
            selectedType = configData.objectiveMilestoneTypes.filter(
              (type) => type != data.id
            );
          } else {
            selectedType = [...configData.objectiveMilestoneTypes, data.id];
          }
          let list = milestoneTypes
            .map((type) => type.id)
            .filter((type) => selectedType.includes(type));
          if (list.length == 0) {
            changeConfigData({
              ...configData,
              objectiveMilestonesFeature: false
            });
            return;
          }
          changeUserData(
            'objectiveMilestoneTypes',
            null,
            ['disabled', ...list],
            configData,
            changeConfigData
          );
        },
        selected: configData?.objectiveMilestoneTypes,
        dataCy: 'sub-milestone',
        listOption: milestoneTypes,
        isVisible: true,
        disabled: isReadOnly
      }
    },
    {
      element: 'toggle',
      isChecked: configData?.objectiveCreationGroupBy != 'none',
      onChange: () =>
        changeConfigData({
          ...configData,
          objectiveCreationGroupBy:
            configData?.objectiveCreationGroupBy == 'none'
              ? 'objective_category'
              : 'none'
        }), // ["objective_category", "none"] other options is posible
      insideCardPermission: true,
      cardTitle: getObjectiveLocale('Enable Goal Creation By Goal Type'),
      cardDescription: getObjectiveLocale(
        'Change goal creation page using creation by goal type.'
      ),
      dataCy: 'creationGroupBy',
      disabled: isReadOnly,
      isVisible: organization?.identifier !== 'bca'
    }
  ].filter((el) => el.isVisible);

  const listColumnObjectives = [
    { id: 'id', title: 'By id' },
    { id: 'name', title: 'By name' },
    { id: 'description', title: 'By description' },
    { id: 'state', title: 'By state' },
    { id: 'due_date', title: 'By due date' },
    { id: 'created_at', title: 'By created at' },
    { id: 'updated_at', title: 'By updated at' },
    { id: 'current_value', title: 'By current value' },
    { id: 'start_date', title: 'By start date' },
    { id: 'creator_id', title: 'By creator id' }
  ];

  const listDirectionObjectives = [
    { id: 'asc', title: 'By Ascending' },
    { id: 'desc', title: 'By Descending' }
  ];

  const listObjectives = [
    {
      element: 'dropdown',
      label: getObjectiveLocale('Sort Column List Objective'),
      onChange: (data) =>
        changeUserData(
          'sortColumn',
          'defaultObjectiveSorting',
          data,
          configData,
          changeConfigData
        ),
      selected: configData?.defaultObjectiveSorting?.sortColumn,
      isSingleSelect: true,
      dataCy: 'defaultObjectiveSorting-sortColumn',
      listOption: listColumnObjectives,
      disabled: isReadOnly
    },
    {
      element: 'dropdown',
      label: getObjectiveLocale('Sort Direction List Objective'),
      onChange: (data) =>
        changeUserData(
          'sortDirection',
          'defaultObjectiveSorting',
          data,
          configData,
          changeConfigData
        ),
      selected: configData?.defaultObjectiveSorting?.sortDirection,
      isSingleSelect: true,
      dataCy: 'defaultObjectiveSorting-sortDirection',
      listOption: listDirectionObjectives,
      disabled: isReadOnly
    },
    {
      element: 'dropdown',
      label: getObjectiveLocale('Sort Column List Sub Objective'),
      onChange: (data) =>
        changeUserData(
          'sortColumn',
          'subgoalsSorting',
          data,
          configData,
          changeConfigData
        ),
      selected: configData?.subgoalsSorting?.sortColumn,
      isSingleSelect: true,
      dataCy: 'subgoalsSorting-sortColumn',
      listOption: listColumnObjectives,
      disabled: isReadOnly
    },
    {
      element: 'dropdown',
      label: getObjectiveLocale('Sort Direction List Sub Objective'),
      onChange: (data) =>
        changeUserData(
          'sortDirection',
          'subgoalsSorting',
          data,
          configData,
          changeConfigData
        ),
      selected: configData?.subgoalsSorting?.sortDirection,
      isSingleSelect: true,
      dataCy: 'subgoalsSorting-sortDirection',
      listOption: listDirectionObjectives,
      disabled: isReadOnly
    },
    {
      element: 'checkbox',
      section: 'performance',
      withRadioButton: true,
      label: getObjectiveLocale('Time Filter Options'),
      className: 'mt-[24px]',
      handleChange: (_, data) =>
        changeCheckboxData(
          data,
          configData?.timeFilterOptions,
          'timeFilterOptions',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.timeFilterOptions,
      isSingleSelect: false,
      dataCy: 'timeFilterOptions',
      listOption: [
        { id: 'today', title: 'Today' },
        { id: 'all_time', title: 'All Time' },
        { id: 'last_year', title: 'Last Year' },
        { id: 'annual', title: 'Annual' },
        { id: 'semester', title: 'Semester' },
        { id: 'quarter', title: 'Quarter' },
        { id: 'custom', title: 'Custom' }
      ],
      headerNo: getObjectiveLocale('Allowed Time Filter'),
      headerName: getObjectiveLocale('Default'),
      disabled: isReadOnly,
      handleChangeDefault: (data) => changeDefaultTimeFilter(data),
      selectedDefault: configData?.timeFilterOptions || []
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Enable Subgoal Deletion'),
      cardDescription: getObjectiveLocale(
        'Enable user to delete subgoal when deleting their parent'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          objectiveDeleteChildren: !configData.objectiveDeleteChildren
        }),
      isChecked: configData?.objectiveDeleteChildren,
      dataCy: 'objective-delete-children',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Include Squad Goals in My Goals'),
      cardDescription: getObjectiveLocale(
        'Enable to including Squad Goals to Goal list in My Goals page'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          includeTeamGoals: !configData.includeTeamGoals
        }),
      isChecked: configData?.includeTeamGoals,
      dataCy: 'objective-include-team',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Enable Optional Comment Edit'),
      cardDescription: getObjectiveLocale(
        'Enable to set optional comment when editing detail goals'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          enableOptionalCommentEdit: !configData.enableOptionalCommentEdit
        }),
      isChecked: configData?.enableOptionalCommentEdit,
      dataCy: 'objective-optional-edit',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Enable Optional Comment Update'),
      cardDescription: getObjectiveLocale(
        'Enable to set optional comment when updating progress goals'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          enableOptionalCommentProgress:
            !configData.enableOptionalCommentProgress
        }),
      isChecked: configData?.enableOptionalCommentProgress,
      dataCy: 'objective-optional-progress',
      disabled: isReadOnly
    }
  ];

  const listConfiguration = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Enable Subordinate Feature'),
      cardDescription: getObjectiveLocale(
        'Enable Direct Report page to include all subordinate of user'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          showSubordinatesPage: !configData.showSubordinatesPage
        }),
      isChecked: configData?.showSubordinatesPage,
      dataCy: 'show-subordinate',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Enable align to any goal'),
      cardDescription: getObjectiveLocale(
        'Enable user to align goal to any public goal in organization'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          alignAnyGoal: !configData.alignAnyGoal
        }),
      isChecked: configData?.alignAnyGoal,
      dataCy: 'align-any-goal',
      disabled: isReadOnly
    }
  ];

  const getOptionsRollUp = (type = '') => {
    const defaultMetricObjective = measurementUnitData?.find(
      (data) => data?.isDefaultForGoal
    );
    const defaultMetricTask = measurementUnitData?.find(
      (data) => data?.isDefaultForTask
    );

    const arrayMetrics =
      type == 'goal' ? defaultMetricObjective : defaultMetricTask;

    let arrayRollUp = [
      { id: 'disabled', title: getObjectiveLocale('Manual'), forTask: false },
      { id: 'auto', title: getObjectiveLocale('Auto Sum'), forTask: true },
      {
        id: 'average',
        title: getObjectiveLocale('Auto Average*'),
        disabled: arrayMetrics?.unit !== '%',
        forTask: true
      },
      {
        id: 'weighted_average',
        title: getObjectiveLocale('Auto Weighted Average*'),
        disabled: arrayMetrics?.unit !== '%',
        forTask: false
      },
      {
        id: 'average_by_phase',
        title: getObjectiveLocale('Auto Completed Sum*'),
        disabled: arrayMetrics?.unit !== '%',
        forTask: false
      }
    ];

    return type == 'task'
      ? arrayRollUp.filter((value) => value.forTask)
      : arrayRollUp;
  };

  const optionsTaskView = [
    { id: 'list', title: getObjectiveLocale('List') },
    { id: 'board', title: getObjectiveLocale('Board') }
  ];

  const handleCheckDefaultRollup = (checked, data, isTask) => {
    const keyData = isTask
      ? 'rollupOptionsForTask'
      : 'rollupOptionsForObjective';
    const keyDefault = isTask
      ? 'defaultRollupForParentTask'
      : 'defaultRollupForParentObjective';

    const optionsRollUp = getOptionsRollUp(isTask ? 'task' : 'goal');
    const changedData = optionsRollUp.find((dt) => dt.id == data);
    changeCheckboxData(
      changedData,
      configData?.[keyData],
      keyData,
      null,
      configData,
      changeConfigData
    );
    if (configData?.[keyData]?.length == 1 && checked) {
      changeConfigData({
        ...configData,
        [keyDefault]: data
      });
    }
  };

  const listMetric = [
    {
      element: 'combined-checkbox',
      section: 'performance',
      title: getObjectiveLocale('Rollup for Parent Objective'),
      withRadioButton: true,
      headerNo: getObjectiveLocale('Allowed Rollup Options'),
      headerName: getObjectiveLocale('Default'),
      handleChange: (checked, data) => handleCheckDefaultRollup(checked, data),
      selected: configData?.rollupOptionsForObjective,
      dataCy: 'rollupOptionsForObjective',
      listOption: getOptionsRollUp('goal'),
      disabled: isReadOnly,
      handleChangeDefault: (data) =>
        changeConfigData({
          ...configData,
          defaultRollupForParentObjective: data
        }),
      selectedDefault: [configData?.defaultRollupForParentObjective] || [],
      withInfo: true,
      infoText: '*Applicable for metric Percentage only'
    },
    {
      element: 'checkbox',
      label: getObjectiveLocale('Show Metric For Objective'),
      description: getObjectiveLocale(
        `This configurations will be applied when add/edit objective’s metric`
      ),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.showMetricForObjective,
          'showMetricForObjective',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.showMetricForObjective,
      dataCy: 'showMetricForObjective',
      listOption: [
        { id: 'no_metric', title: 'No Metric' },
        { id: 'all', title: 'All' }
      ],
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      isChecked: configData?.objectiveCalculationType?.includes(
        CALC_TYPE_LESS_IS_BETTER
      ),
      onChange: () => handleChangeCalculationType(CALC_TYPE_LESS_IS_BETTER),
      cardTitle: getObjectiveLocale('Enable Less Is Better Metrics'),
      cardDescription: getObjectiveLocale(
        'Enable less is better metrics when user composing goal'
      ),
      isVisible: true,
      disabled: isReadOnly,
      dataCy: 'objectiveCalculationType'
    },
    {
      element: 'toggle',
      isChecked: configData?.objectiveCalculationType?.includes(
        CALC_TYPE_EARLY_COMPLETION
      ),
      onChange: () => handleChangeCalculationType(CALC_TYPE_EARLY_COMPLETION),
      cardTitle: getObjectiveLocale('Enable Early Completion Metrics'),
      cardDescription: getObjectiveLocale(
        'Enable early completion metrics when user composing goal'
      ),
      isVisible: true,
      disabled: isReadOnly,
      dataCy: 'objectiveCalculationTypeEarly'
    },
    {
      element: 'toggle',
      isChecked: configData?.objectiveCalculationType?.includes(
        CALC_TYPE_ZERO_TARGET
      ),
      onChange: () => handleChangeCalculationType(CALC_TYPE_ZERO_TARGET),
      cardTitle: getObjectiveLocale('Enable Zero Target progress'),
      cardDescription: getObjectiveLocale(
        'Enable zero target progress mode when composing goal.'
      ),
      isVisible: true,
      disabled: isReadOnly,
      dataCy: 'objectiveCalculationTypeZero'
    }
  ];

  const listOwner = [
    {
      element: 'dropdown',
      label: getObjectiveLocale('Default Owner For Create Objective'),
      onChange: (data) =>
        changeConfigData({ ...configData, defaultFilterOwner: data }),
      selected: configData?.defaultFilterOwner,
      isSingleSelect: true,
      dataCy: 'defaultFilterOwner',
      listOption: [
        { id: 'direct_report', title: 'Direct Report' },
        { id: 'subordinates', title: 'Subordinates' },
        { id: 'all_employees', title: 'All Employees' }
      ],
      disabled: isReadOnly
    },
    {
      element: 'checkbox',
      label: getObjectiveLocale('Filter Owner Options For Create Objective'),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.filterOwner,
          'filterOwner',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.filterOwner,
      isSingleSelect: true,
      dataCy: 'filterOwner',
      listOption: [
        { id: 'direct_report', title: 'Direct Report' },
        { id: 'subordinates', title: 'Subordinates' },
        { id: 'all_employees', title: 'All Employees' }
      ],
      disabled: isReadOnly
    }
  ];

  const listTask = [
    {
      element: 'combined-checkbox',
      section: 'performance',
      title: getObjectiveLocale('Rollup for Parent Task'),
      withRadioButton: true,
      headerNo: getObjectiveLocale('Allowed Rollup Options'),
      headerName: getObjectiveLocale('Default'),
      handleChange: (checked, data) =>
        handleCheckDefaultRollup(checked, data, true),
      selected: configData?.rollupOptionsForTask,
      dataCy: 'rollupOptionsForTask',
      listOption: getOptionsRollUp('task'),
      disabled: isReadOnly,
      handleChangeDefault: (data) =>
        changeConfigData({
          ...configData,
          defaultRollupForParentTask: data
        }),
      selectedDefault: [configData?.defaultRollupForParentTask] || [],
      withInfo: true,
      infoText: '*Applicable for metric Percentage only'
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Enable Task Management'),
      cardDescription: getObjectiveLocale(
        'Enable user used new task management features'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          taskManagementFeature: !configData.taskManagementFeature
        }),
      isChecked: configData?.taskManagementFeature,
      dataCy: 'task-management-feature',
      disabled: isReadOnly
    },
    {
      element: 'combined-checkbox',
      section: 'task',
      title: getObjectiveLocale('Default Task Management View'),
      withRadioButton: true,
      withCheckboxButton: false,
      headerNo: getObjectiveLocale('View Name'),
      headerName: getObjectiveLocale('Default'),
      handleChange: (checked, data) => handleCheckDefaultRollup(checked, data),
      selected: configData?.defaultTaskView,
      dataCy: 'defaultTaskView',
      listOption: optionsTaskView,
      disabled: isReadOnly,
      handleChangeDefault: (data) =>
        changeConfigData({
          ...configData,
          defaultTaskView: data
        }),
      selectedDefault: [configData?.defaultTaskView] || []
    },
    {
      element: 'toggle',
      isChecked: configData?.includeTaskRollUp,
      onChange: () =>
        changeConfigData({
          ...configData,
          includeTaskRollUp: !configData?.includeTaskRollUp
        }),
      cardTitle: getObjectiveLocale(
        `Include Task's progress on roll up mechanism`
      ),
      cardDescription: getObjectiveLocale(
        `Task's progress will be rolled up to its parent objective if this setting is enabled`
      ),
      isVisible: organization?.identifier !== 'bca',
      disabled: isReadOnly
    },
    {
      element: 'checkbox',
      label: getObjectiveLocale('Show Metric For Task'),
      description: getObjectiveLocale(
        `This configurations will be applied when add/edit task’s metric`
      ),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.showMetricForTask,
          'showMetricForTask',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.showMetricForTask,
      isSingleSelect: true,
      dataCy: 'showMetricForTask',
      listOption: [
        { id: 'no_metric', title: 'No Metric' },
        { id: 'all', title: 'All' }
      ],
      disabled: isReadOnly
    }
  ];

  const listThreshold = [
    {
      element: 'input',
      section: 'culture',
      label: 'Maximum Goal Level',
      placeholder: 'Maximum Goal Level',
      handleChange: (e) =>
        changeConfigData({
          ...configData,
          maximumGoalLevel: parseInt(e.target.value)
        }),
      insideCardPermission: true,
      value: configData?.maximumGoalLevel,
      type: 'number',
      dataCy: 'maximumGoalLevel',
      disabled: isReadOnly
    }
  ];

  const listTeam = [
    {
      element: 'dropdown',
      label: getObjectiveLocale('Sort Column List Team'),
      onChange: (data) =>
        changeUserData(
          'sortColumn',
          'defaultTeamSorting',
          data,
          configData,
          changeConfigData
        ),
      selected: configData?.defaultTeamSorting?.sortColumn,
      isSingleSelect: true,
      dataCy: 'defaultTeamSorting-sortColumn',
      listOption: [
        { id: 'name', title: 'By name' },
        { id: 'created_at', title: 'By created at' }
      ],
      disabled: isReadOnly
    },
    {
      element: 'dropdown',
      label: getObjectiveLocale('Sort Direction List Team'),
      onChange: (data) =>
        changeUserData(
          'sortDirection',
          'defaultTeamSorting',
          data,
          configData,
          changeConfigData
        ),
      selected: configData?.defaultTeamSorting?.sortDirection,
      isSingleSelect: true,
      dataCy: 'defaultTeamSorting-sortDirection',
      listOption: [
        { id: 'asc', title: 'By Ascending' },
        { id: 'desc', title: 'By Descending' }
      ],
      disabled: isReadOnly
    }
  ];

  const getData = async () => {
    const { data } = await getConfig(configParams);
    if (data) {
      let updatedData = cloneDeep(data);
      const checkinParamsAt = updatedData?.checkinParams?.at;
      if (checkinParamsAt) {
        updatedData.checkinParams.at = checkTimeParams(checkinParamsAt);
      }
      changeConfigData(updatedData);
      changeOriginConfigData(updatedData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="overflow-auto h-[calc(100vh-64px)] w-[87%] relative"
      id="scrollable-config"
      onScroll={(e) => setActiveNavigator(e, list, setActiveSection, -48)}
    >
      <div className="w-[768px] mt-[24px]">
        <div className="fixed right-0 mt-[24px] w-[160px] z-index-10">
          <ContentNavigator
            title="CONTENTS"
            activeSection={activeSection}
            listNavigatorContents={list}
            additionalOffset={20}
            sectionContent="scrollable-config"
          />
        </div>
        <p className="typography-h300 text-n-800 uppercase mb-[24px]">
          {getObjectiveLocale('Organization Configuration')}
        </p>
        <section
          className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]"
          id="activities-section"
        >
          <p className="typography-h500 text-n-900 mb-[24px]">
            {getObjectiveLocale('Activities')}
          </p>
          {listActivities.map((config, index) =>
            config?.element === 'dropdown' ? (
              <DropdownCard {...config} key={`0-${index}`} />
            ) : (
              <CheckBoxOrganization {...config} key={`0-${index}`} />
            )
          )}
        </section>
        <section
          className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]"
          id="alignment-section"
        >
          <p className="typography-h500 text-n-900 mb-[24px]">
            {getObjectiveLocale('Alignment')}
          </p>
          {listAlignment.map((config, index) => (
            <CheckBoxOrganization {...config} key={`1-${index}`} />
          ))}
        </section>
        {/* ON HOLD UNTIL BACKEND SUPPORT */}
        {/* <section className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]" id="approval-section">
          <p className="typography-h500 text-n-900 mb-[24px]">{getObjectiveLocale("Approval")}</p>
          {
            listApproval.map(config => <InputField {...config} />)
          }
        </section> */}
        <section
          className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]"
          id="create-section"
        >
          <p className="typography-h500 text-n-900 mb-[24px]">
            {getObjectiveLocale('Create')}
          </p>
          <p className="typography-paragraph text-n-800 mt-[4px] mb-[24px]">
            {getObjectiveLocale(
              'Define objective configurations that will be applied.'
            )}
          </p>
          {listCreate.map((config, index) =>
            config?.element === 'input' ? (
              <InputField
                {...config}
                className="w-[240px] mt-[16px]"
                key={`2-${index}`}
              />
            ) : config?.element === 'checkbox' ? (
              <CheckBoxOrganization {...config} key={`2-${index}`} />
            ) : config?.element === 'toggle' ? (
              <ToggleCard {...config} key={`2-${index}`}>
                {config.useChild &&
                  config?.subElement?.element == 'checkbox' && (
                    <CheckBoxOrganization
                      {...config.subElement}
                      key={`2-${index}-sub`}
                    />
                  )}
              </ToggleCard>
            ) : config?.element === 'dropdown' ? (
              <DropdownOrganization {...config} key={`2-${index}`} />
            ) : (
              <RadioButtonOrganization {...config} key={`2-${index}`} />
            )
          )}
        </section>
        {/* ON HOLD UNTIL BACKEND SUPPORT */}
        {/* <section className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]" id="label-section">
          <p className="typography-h500 text-n-900 mb-[24px]">{getObjectiveLocale("Labels")}</p>
          {
            listLabels.map(config => (
              config?.element === "input"
              ? <InputField {...config} />
              : config?.element === "checkbox"
              ? <CheckBoxOrganization {...config} />
              : <DropdownOrganization {...config} />
            ))
          }
        </section> */}
        <section
          className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]"
          id="metric-section"
        >
          <p className="typography-h500 text-n-900 mb-[24px]">
            {getObjectiveLocale('Metric')}
          </p>
          <MeasurementUnit objectiveType="goal" />
          {listMetric.map((config, index) =>
            config?.element === 'input' ? (
              <InputField
                {...config}
                className="mt-[16px]"
                key={`3-${index}`}
              />
            ) : config?.element === 'checkbox' ? (
              <CardCheckBoxOrganization {...config} key={`3-${index}`} />
            ) : config?.element === 'toggle' ? (
              <ToggleCard {...config} key={`3-${index}`} />
            ) : config?.element === 'combined-checkbox' ? (
              <CheckboxWithDefaultOrganization {...config} key={`3-${index}`} />
            ) : (
              <DropdownOrganization {...config} key={`3-${index}`} />
            )
          )}
        </section>
        <section
          className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]"
          id="objective-section"
        >
          <p className="typography-h500 text-n-900 mb-[24px]">
            {getObjectiveLocale('Objective Configuration')}
          </p>
          {listObjectives.map((config, index) =>
            config?.element === 'input' ? (
              <InputField
                {...config}
                className="mt-[16px]"
                key={`4-${index}`}
              />
            ) : config?.element === 'checkbox' ? (
              <CheckboxWithDefaultOrganization {...config} key={`4-${index}`} />
            ) : config?.element === 'toggle' ? (
              <ToggleCard {...config} key={`4-${index}`} />
            ) : (
              <DropdownOrganization {...config} key={`4-${index}`} />
            )
          )}
        </section>
        <section
          className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]"
          id="owner-section"
        >
          <p className="typography-h500 text-n-900 mb-[24px]">
            {getObjectiveLocale('Owner')}
          </p>
          {listOwner.map((config, index) =>
            config?.element === 'input' ? (
              <InputField
                {...config}
                className="mt-[16px]"
                key={`5-${index}`}
              />
            ) : config?.element === 'checkbox' ? (
              <CheckBoxOrganization {...config} key={`5-${index}`} />
            ) : (
              <DropdownOrganization {...config} key={`5-${index}`} />
            )
          )}
        </section>
        <section
          className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]"
          id="task-section"
        >
          <p className="typography-h500 text-n-900 mb-[24px]">
            {getObjectiveLocale('Task')}
          </p>
          <MeasurementUnit objectiveType="task" />
          {listTask.map((config, index) =>
            config?.element === 'toggle' ? (
              <ToggleCard {...config} key={`7-${index}`} />
            ) : config?.element === 'combined-checkbox' ? (
              <CheckboxWithDefaultOrganization {...config} key={`7-${index}`} />
            ) : (
              <CardCheckBoxOrganization {...config} key={`7-${index}`} />
            )
          )}
        </section>
        <section
          className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px] pb-[64px]"
          id="threshold-section"
        >
          <p className="typography-h500 text-n-900 mb-[24px]">
            {getObjectiveLocale('Threshold')}
          </p>
          {listThreshold.map((config, index) => (
            <InputField
              {...config}
              className="w-[240px] mt-[16px]"
              key={`8-${index}`}
            />
          ))}
        </section>
        <section
          className="border-solid border-b border-0 border-n-500 pb-40 mb-40 mt-24 pb-64"
          id="configuration-section"
        >
          <p className="typography-h500 text-n-900 mb-24">
            {getObjectiveLocale('Objectives Configuration')}
          </p>
          {listConfiguration.map((config, index) => (
            <ToggleCard {...config} key={`9-${index}`} />
          ))}
        </section>
        <section
          className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]"
          id="team-section"
        >
          <p className="typography-h500 text-n-900 mb-[24px]">
            {getObjectiveLocale('Team')}
          </p>
          {listTeam.map((config, index) => (
            <DropdownOrganization {...config} key={`10-${index}`} />
          ))}
        </section>
      </div>
    </div>
  );
};

export default Objectives;

import React, { useState } from 'react';
import { useQueryClient } from 'react-query';

import objectiveComparativeKeys from 'consts/queryKeys/objectiveComparativeKeys';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import { usePatchPreset } from 'hooks/api/useObjectivesComparative';
import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';
import InputField from 'components/shared/InputField';
import { getUserCreator } from 'pages/objectiveAnalytics/helper/objectiveAnalyticsHelper';

const ModalRenamePreset = ({ onClose }) => {
  const { user } = useUser();
  const { addToast } = useToastContext();
  const { refetchQueries } = useRefetchQuery();

  const preset = useObjectiveComparative((state) => state.preset);
  const setPreset = useObjectiveComparative((state) => state.setPreset);

  const { id, name } = preset || {};

  const [currentPresetName, setCurrentPresetName] = useState(name);
  const [showWarning, setShowWarning] = useState(false);
  const queryClient = useQueryClient();
  const { data: listPreset } = queryClient.getQueryData([
    'objective-comparative'
  ]);

  const { mutate: renamePreset } = usePatchPreset(id);

  const checkPresetIsExists = (currentPreset) => {
    return listPreset?.some(
      (previousPreset) => previousPreset?.name === currentPreset
    );
  };

  const renamePresetHandler = () => {
    if (checkPresetIsExists(currentPresetName)) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      const payloadBody = { name: currentPresetName };

      renamePreset(payloadBody, {
        onSuccess: (newPreset) => {
          setPreset({
            id: newPreset?.data?.id,
            name: newPreset?.data?.name,
            isShared: false,
            creator: getUserCreator(user)
          });
          refetchQueries(objectiveComparativeKeys.default);
          addToast({
            title: 'Success',
            msg: `Your preset has been renamed to ${newPreset?.data?.name}`,
            type: 'success'
          });
          onClose();
        }
      });
    }
  };

  return (
    <Modal onClose={onClose} minWidth={400} customClass="w-[400px]">
      <Modal.Header title="Rename Preset" onClose={onClose} />
      <Modal.Body>
        <div className="w-full px-[24px] my-[4px]">
          <InputField
            inputClass="typography-paragraph text-n-900 px-[16px] py-[4px]"
            placeholder="Preset name"
            dataCy="edit-preset-name-input"
            value={currentPresetName}
            handleChange={(e) => setCurrentPresetName(e.currentTarget.value)}
            inputError={showWarning}
          />
          {showWarning && (
            <p className="typography-h100 text-r-600 mt-[12px]">
              {getObjectiveLocale('The name is already exists')}
            </p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={{
          text: getObjectiveLocale('Save'),
          dataCy: 'rename-preset',
          onClick: renamePresetHandler
        }}
        secondaryButton={{
          text: getObjectiveLocale('Cancel'),
          dataCy: 'cancel-rename',
          onClick: onClose
        }}
        useBorder={false}
      />
    </Modal>
  );
};

export default ModalRenamePreset;

import dayjs from 'dayjs';
import produce from 'immer';
import { create } from 'zustand';

import { calculateRecurrenceSurvey } from 'src/client/CultureClient';

const date = new Date();
const defaultData = {
  id: null,
  title: '',
  description: '',
  thankMessage: '',
  participantType: 'user',
  canBePostponed: false, //CHANGED FOR BCA REQUEST
  includes: [],
  excludes: [],
  selectAll: false,
  expiration: dayjs(date).add(1, 'days').toISOString(true),
  startTime: dayjs(date).toISOString(true),
  recurrence: {},
  questions: [
    {
      question: null,
      type: 'poll',
      pollOptionsAttributes: [{ option: '' }, { option: '' }],
      orderLevel: 0
    }
  ]
};

const defaultNextSurvey = {
  start: dayjs(date).toISOString(true),
  end: dayjs(date).add(1, 'days').toISOString(true)
};

const setDesignatedUntilDate = async (set, data) => {
  const { startTime, expiration, repetition, interval } = data;
  const payload = {
    starts: startTime,
    ends: expiration,
    every: repetition,
    interval: interval
  };
  const { isSuccess, metadata } = await calculateRecurrenceSurvey(payload);
  if (!isSuccess) return;
  const { nextPeriod } = metadata;
  set(
    produce((state) => {
      state.surveyData.recurrence.until =
        new Date(state?.surveyData?.recurrence?.until).getTime() >
        new Date(nextPeriod?.endDate).getTime()
          ? state?.surveyData?.recurrence?.until
          : nextPeriod?.endDate;
      state.nextSurvey = nextPeriod;
      state.defaultUntilDate = nextPeriod?.endDate;
    })
  );
};

const usePulseSurvey = create((set) => ({
  surveyData: defaultData,
  scheduleType: '',
  totalParticipant: '',
  nextSurvey: defaultNextSurvey,
  defaultUntilDate: null,
  setSurveyData: (data) => set({ surveyData: data }),
  setNextSurvey: (data) => set({ nextSurvey: data }),
  setScheduleType: (data) => set({ scheduleType: data }),
  setTotalParticipant: (data) => set({ totalParticipant: data }),
  initializeData: () =>
    set({ surveyData: defaultData, scheduleType: '', totalParticipant: '' }),
  setDesignatedUntilDate: (data) => setDesignatedUntilDate(set, data)
}));

export default usePulseSurvey;

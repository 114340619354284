import React from 'react';

import Config from 'utils/Config';
import { formatBytes, strToMiddleEllipsis } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import SVGIcon from 'components/shared/SVGIcon';
import CircleProgressBar from 'components/shared/SVGProgressBar/Circle/CircleProgressBar';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';

const AttachmentPostPhoto = ({
  fileAttachments,
  linkAttachment,
  mediaAttachments,
  deleteAttachment,
  deleteFile,
  deletePhoto
}) => {
  return (
    <div className="attachment-section">
      <div
        className="attachment-type-section overflow-auto medias flex"
        id="scollbar-attachment"
      >
        {mediaAttachments.length > 0 &&
          mediaAttachments.map((attachment, index) => {
            return (
              <div className="attachment-photo" key={index}>
                <AWSImage src={attachment.src} />
                {attachment.type === 'video' && attachment.progress === 100 && (
                  <SVGIcon
                    size="40"
                    customClass="play-button"
                    iconName="icon-play-button"
                    viewBox="0 0 32 32"
                  />
                )}
                <SVGIcon
                  size="16"
                  customClass="close-icon"
                  iconName="icon-cancel"
                  fillColor="var(--n-800)"
                  onClick={() => deletePhoto(index)}
                />
                {attachment.progress < 100 && (
                  <CircleProgressBar
                    progress={attachment.progress}
                    dataCy="attachment-media-progress"
                  />
                )}
              </div>
            );
          })}
      </div>
      <div className="attachment-type-section files flex flex-col scrollable">
        {fileAttachments.length > 0 &&
          fileAttachments.map((attachment, index) => {
            return (
              <div className="attachment-file" key={index}>
                <div className="info">
                  <SVGIcon
                    size="24"
                    customClass="icon-attachment mr-[10px]"
                    iconName="icon-attachment"
                    fillColor="var(--n-600)"
                  />
                  <div className="typography-h500 mr-[4px]" data-cy="file-name">
                    {strToMiddleEllipsis(15, attachment.fileName)}
                  </div>
                  <div
                    className="typography-h500 text-n-600 mr-[4px]"
                    data-cy="file-size"
                  >
                    {formatBytes(attachment.size)}
                  </div>
                  {attachment.progress < 100 && (
                    <LineProgressBar
                      progress={attachment.progress}
                      width={150}
                      dataCy="attachment-progress"
                    />
                  )}
                </div>
                <SVGIcon
                  size="24"
                  customClass="mr-[10px]"
                  iconName="icon-clear"
                  fillColor="var(--n-700)"
                  onClick={() => deleteFile(index)}
                />
              </div>
            );
          })}
      </div>
      <div className="attachment-type-section flex">
        {linkAttachment.length > 0 && (
          <div className="attachment-link">
            <div className="detail">
              <AWSImage
                src={linkAttachment[0].image}
                onErrorImage={Config.asset.culture.brokenImage}
              />
              <div>
                <p>{linkAttachment[0].title}</p>
                <p>{linkAttachment[0].providerUrl}</p>
              </div>
            </div>
            <SVGIcon
              size={16}
              customClass="close-icon"
              iconName="icon-cancel"
              fillColor="#a258af"
              onClick={() => deleteAttachment()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AttachmentPostPhoto;

import React, { useState } from 'react';

import { getNumberLocale } from 'utils/HelperUtils';

import TooltipPortal from 'components/shared/Tooltips/TooltipPortal';

const MeasurementSection = ({ measurement, objectiveName }) => {
  const [isEllipsis, setIsEllipsis] = useState(false);
  const [portalData, setPortalData] = useState({});
  const [clientRect, setClientRect] = useState('');
  const rect = (clientRect && clientRect?.getBoundingClientRect()) || {};

  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const { progressColorHex, unit, description, progressPercentage } =
    measurement || {};

  let progressbarStyle = {
    width: `${progressPercentage}%`,
    backgroundColor: `#${progressColorHex}`
  };

  const setPortal = (e) => {
    if (isEllipsis) {
      setIsTooltipShown(true);
      setPortalData({
        name: objectiveName,
        left: '50%',
        top: rect?.top - 36 + 'px',
        translateX: '-50%',
        noWrap: true
      });
    }
  };

  return (
    <div className="measurement-section bg-n-200 px-[16px] py-[16px] rounded-[4px]">
      <TooltipPortal
        show={isTooltipShown}
        portalData={portalData}
        portalId="shared-tooltip"
      />
      <p
        ref={(ref) => {
          setIsEllipsis(ref?.offsetWidth < ref?.scrollWidth);
          setClientRect(ref);
        }}
        className={`${
          measurement ? 'mb-[8px] ' : ''
        }typography-button text-n-900 max-w-[520px] truncate`}
        onMouseOver={(e) => setPortal(e)}
        onMouseOut={() => setIsTooltipShown(false)}
      >
        {objectiveName}
      </p>
      {measurement && (
        <div data-cy="update-progress-metric">
          <div className="flex justify-between">
            <span className="typography-h200 fw-normal text-n-800">{`${description} (${unit})`}</span>
            <span className="typography-h200 fw-normal text-n-800">{`${getNumberLocale(
              progressPercentage
            )}%`}</span>
          </div>
          <div
            className="overflow-hidden progress-bar mt-[8px] w-full h-[8px] bg-n-400  rounded-[4px] relative"
            data-cy="update-progress-progress-bar"
          >
            <div
              className="goal-status-bar-value status-bar-detail-value"
              style={progressbarStyle}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MeasurementSection;

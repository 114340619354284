import React, { useEffect, useState } from 'react';

import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';
import DynamicTabGrey from 'components/shared/Tabs/DynamicTabGrey';


import EmployeeTable from './EmployeeTable';
import PerformanceProfileGroup from './PerformanceProfileGroup.js';
import './SetReviewee.scss';

const SetReviewee = ({ saveStatus }) => {
  const {
    cycleData: { includes, selectAll, groupIds },
    changeIncludesIds,
    changeSelectAll,
    changeExcludeIds,
    changeGroupIds
  } = useCreateCycle();

  // Grey Tab
  const [viewTab, setViewTab] = useState('Employee Individually');
  const greyTabs = ['Employee Individually', 'Performance Profile Group'];

  const [modalObject, setModalObject] = useState({
    show: false,
    wording: null,
    targetState: null
  });

  // Employee Individually Table States
  const [isSelectAllRow, setIsSelectAllRow] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [listIdUser, setListIdUser] = useState([]);
  const [excludedId, setExcludedId] = useState([]);
  const [sortStatus, setSortStatus] = useState('asc');

  const handleSetViewTab = (tab) => {
    if (
      viewTab === 'Employee Individually' &&
      (includes.length > 0 || selectAll === true)
    ) {
      setModalObject({ show: true, wording: 'employee', targetState: tab });
    } else if (viewTab === 'Performance Profile Group' && groupIds.length > 0) {
      setModalObject({ show: true, wording: 'group', targetState: tab });
    } else {
      setViewTab(tab);
    }
  };

  const handleClearSelection = () => {
    if (modalObject.wording) {
      changeIncludesIds([]);
      changeSelectAll(false);
      changeExcludeIds([]);
      changeGroupIds([]);
      setIsSelectAllRow(false);
      setIsSelectAll(false);
      setListIdUser([]);
      setModalObject({ ...modalObject, show: false });
      setViewTab(modalObject.targetState);
    }
  };

  const closeModal = () => {
    setModalObject({ show: false, wording: null, targetState: null });
  };

  useEffect(() => {
    if (groupIds?.length > 0) {
      setViewTab('Performance Profile Group');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-[24px] bg-n-000">
      <h4 className="typography-h500">{getObjectiveLocale('Reviewees')}</h4>
      <p className="typography-paragraph text-n-800">
        {getObjectiveLocale('Who would you like to have receive a review?')}
      </p>
      <label className="typography-h100 text-n-800">
        {getObjectiveLocale('Select by')}
      </label>
      <DynamicTabGrey
        listName={greyTabs}
        state={viewTab}
        setState={handleSetViewTab}
      />
      {viewTab === 'Employee Individually' && (
        <EmployeeTable
          isSelectAll={isSelectAll}
          setIsSelectAll={setIsSelectAll}
          isSelectAllRow={isSelectAllRow}
          setIsSelectAllRow={setIsSelectAllRow}
          listIdUser={listIdUser}
          excludedId={excludedId}
          setExcludedId={setExcludedId}
          setListIdUser={setListIdUser}
          sortStatus={sortStatus}
          setSortStatus={setSortStatus}
          saveStatus={saveStatus}
        />
      )}
      {viewTab === 'Performance Profile Group' && <PerformanceProfileGroup />}
      {modalObject.show && (
        <Modal minWidth={400} customClass="!max-w-[400px]" onClose={closeModal}>
          <Modal.Header
            title={getObjectiveLocale(`Clear ${modalObject.wording} selection`)}
          />
          <Modal.Body>
            <p className="typography-paragraph text-n-800 px-[24px] mb-[16px]">
              {getObjectiveLocale(
                `If you chose this option, your ${modalObject.wording} selections will be cleared. Are you sure?`
              )}
            </p>
          </Modal.Body>
          <Modal.Footer
            primaryButton={{
              text: getObjectiveLocale('Clear selection'),
              variant: 'danger',
              onClick: handleClearSelection,
              dataCy: 'button-primary-modal'
            }}
            secondaryButton={{
              text: getObjectiveLocale('Cancel'),
              onClick: closeModal,
              dataCy: 'button-secondary-modal'
            }}
            useBorder={false}
          />
        </Modal>
      )}
    </div>
  );
};

export default SetReviewee;

import React, { createRef, useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

function BadgeReplacementConfirmation({
  users,
  members,
  setMembers,
  handleCancel,
  handleConfirm,
  isLoading
}) {
  const { includes, excludes, selectAll } = members;
  const intersectTarget = createRef();

  const handleRemoveUser = (id, index) => {
    if (selectAll) {
      let tempExcludes = cloneDeep(excludes);
      setMembers({ ...members, excludes: tempExcludes.push(id) });
    } else {
      let tempIncludes = cloneDeep(includes);
      tempIncludes.splice(tempIncludes.indexOf(id), 1);
      setMembers({ ...members, includes: tempIncludes });
    }
    users.splice(index, 1);
  };

  return (
    <Modal
      title="Badge Replacement Confirmation"
      description={`You have selected ${users?.length} user${
        users?.length === 1 ? '' : 's'
      } with assigned badge. You can choose to remove the users here or confirm the badge replacement.`}
      className="w-[440px]"
      maxHeight={640}
      contentWrapperClass="overflow-y-auto overflow-x-hidden"
      useBorder={true}
      eventOnClickClose={() => handleCancel()}
      withCloseIcon={true}
      withFooter={true}
      withPrimaryBtn={{
        title: 'Confirm',
        onClick: () => handleConfirm(users?.length),
        dataCy: 'confirm-badge-replacement',
        isLoading: isLoading
      }}
      withSecondaryBtn={{
        title: 'Cancel',
        onClick: () => handleCancel(),
        dataCy: 'cancel-badge-replacement',
        isLoading: isLoading
      }}
    >
      {isLoading ? (
        <LoadingComponent />
      ) : (
        users.length > 0 && (
          <div className="w-full mt-[16px]">
            <p className="typography-h100 text-n-800">
              {getObjectiveLocale('Selected user(s):')}
            </p>
            <div className="w-full">
              {users.map((user, index) => {
                const isLast = users.length - 1 === index;
                return (
                  <div
                    className="bg-n-200 flex items-center w-full mt-[8px] px-[16px] py-[16px]"
                    ref={isLast ? intersectTarget : null}
                    key={index}
                  >
                    <Avatar
                      name={user?.name}
                      src={user?.profilePicture}
                      size={40}
                    />
                    <div
                      className="w-full px-[16px]"
                      style={{ 'max-width': 'calc(100% - 64px)' }}
                    >
                      <p className="typography-paragraph text-n-900 w-full truncate">
                        {user?.name}
                      </p>
                      <p className="typography-h-100 text-n-800 w-full truncate">
                        {getObjectiveLocale('Current badge:')}{' '}
                        {user?.assignedBadge?.title
                          ? user?.assignedBadge?.title
                          : 'no badge'}
                      </p>
                    </div>
                    <SVGIcon
                      iconName="icon-cancel"
                      size="24"
                      fillColor="var(--n-600)"
                      customClass="justify-self-end"
                      onClick={() => handleRemoveUser(user?.id, index)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )
      )}
    </Modal>
  );
}

export default BadgeReplacementConfirmation;

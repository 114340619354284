import React from 'react';

import PropTypes from 'prop-types';

import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

const Interfaces = {
  className: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  idToogleSwitch: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  width: PropTypes.string,
  useBorder: PropTypes.bool
};

const DefaultValue = {
  className: '',
  title: 'Title',
  desc: 'Description',
  idToogleSwitch: 'id-for-set-toogle',
  isChecked: false,
  onChange: () => {},
  width: '768px',
  useBorder: false
};

const ObjectivesFormBoxV2 = (props) => {
  const {
    className,
    title,
    desc,
    children,
    idToogleSwitch,
    isChecked,
    onChange,
    useBorder,
    disabled
  } = props;

  const border = useBorder ? 'border-gray rounded-[4px]' : '';

  return (
    <div className={`flex space-between ${className} ${border}`}>
      <div>
        <p className="typography-h400 text-n-900">{title}</p>
        <p className="typography-paragraph typography-secondary fw-normal">
          {desc}
        </p>
        <div>{children}</div>
      </div>
      <SmallToggleSwitchPurple
        idStr={`${idToogleSwitch}`}
        isChecked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

ObjectivesFormBoxV2.propTypes = Interfaces;
ObjectivesFormBoxV2.defaultProps = DefaultValue;

export default ObjectivesFormBoxV2;

import React from 'react';

import camelCase from 'lodash/camelCase';

import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import WhiteCard from 'components/admin/analytic/team-review/WhiteCard.js';
import Checkbox from 'components/shared/Checkbox';

const ScoreCardSection = ({ phase, configs, reviewAspectsScoreCardList }) => {
  const REVIEW_ASPECTS = 'scoreAspects';
  const { changePhaseData } = useCreateCycle();

  const options = [
    ...reviewAspectsScoreCardList,
    { display: 'Final score', key: 'final_score' },
    { display: 'Calibration history', key: 'calibration_histories' },
    { display: 'Performance category', key: 'final_performance_category' }
  ];

  const toggleCheckScoreAspect = (checked, params, id) => {
    if (checked) {
      params[REVIEW_ASPECTS] = params[REVIEW_ASPECTS]
        ? [...params[REVIEW_ASPECTS], { id: parseInt(id), visibility: true }]
        : [{ id: parseInt(id), visibility: true }];
    } else {
      const filteredParams = params[REVIEW_ASPECTS]?.filter(
        (data) => data.id != parseInt(id)
      );
      if (filteredParams.length != 0) {
        params[REVIEW_ASPECTS] = filteredParams;
      } else {
        delete params[REVIEW_ASPECTS];
      }
    }
  };

  const toggleCheckDefaultAspect = (checked, params, key) => {
    if (checked) {
      params[key] = true;
    } else {
      delete params[key];
    }
  };

  const onChange = (e, key, id) => {
    const checked = e.currentTarget.checked;
    let params = { ...configs };

    if (key.indexOf(REVIEW_ASPECTS) > -1) {
      toggleCheckScoreAspect(checked, params, id);
    } else {
      toggleCheckDefaultAspect(checked, params, key);
    }

    changePhaseData(phase, 'scoreCard', params);
  };

  const valueChecker = (value, id) => {
    return value.indexOf(REVIEW_ASPECTS) > -1
      ? configs[REVIEW_ASPECTS]?.find((data) => data.id == id)?.visibility ||
          false
      : configs[value] || false;
  };

  return (
    <WhiteCard classes="mt-[16px] px-[12px] py-[12px] bg-n-200">
      <p className="typography-h100 typography-secondary">
        {getObjectiveLocale(
          `Configure what attributes will be shown on review results`
        )}
      </p>
      {options.map((option, index) => {
        return (
          <Checkbox
            key={index}
            name={`${phase}-${option.key}`}
            id={`${phase}-${option.key}`}
            dataCy={`${phase}-${option.key}`}
            checked={valueChecker(camelCase(option.key), option.id)}
            onChange={(e) => onChange(e, camelCase(option.key), option.id)}
            labelClass="cursor-pointer w-full"
            customContainerClass="w-full"
          >
            <div>
              <span className="typography-paragraph text-n-900 ml-[8px]">
                {option.display}
              </span>
            </div>
          </Checkbox>
        );
      })}
    </WhiteCard>
  );
};

export default ScoreCardSection;

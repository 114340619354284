import React, { useEffect, useState } from 'react';

import { useRefetchQuery } from 'context/RefetchQueryContext';
import useScroll from 'hooks/useScroll';
import { getCssVariableValue } from 'utils/HelperUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InlineDialog from 'components/design-system/inline-dialog/InlineDialog';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import MenuOutsideClickWrapper from 'components/objectives/compact-objective/fragments/MenuOutsideClickWrapper';
import Badge from 'components/shared/Badge';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import {
  addObjectiveDependencySuggestions,
  deleteObjectiveDependencySuggestions
} from 'src/client/ObjectivesClient';
import SVGIcon from 'src/components/shared/SVGIcon';

import DependencyDropdown from './../dropdown/DependencyDropdown';

const BadgeDependency = ({
  dependencies,
  dependency,
  onChange,
  iconLeft,
  iconLeftColor
}) => {
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  return (
    <div
      onMouseEnter={() => setShowCloseIcon(true)}
      onMouseLeave={() => setShowCloseIcon(false)}
    >
      <Badge
        truncate
        customMaxWidth={
          dependencies.length > 2
            ? showCloseIcon
              ? 'max-w-[80px]'
              : 'max-w-[100px]'
            : dependencies.length > 1
            ? showCloseIcon
              ? 'max-w-[100px]'
              : 'max-w-[120px]'
            : showCloseIcon
            ? 'max-w-[227px]'
            : 'max-w-[247px]'
        }
        content={dependency?.name || dependency}
        colorHex={getCssVariableValue('--n-800')}
        bgColorHex={getCssVariableValue('--n-300')}
        iconLeft={iconLeft}
        iconLeftColor={iconLeftColor}
        defaultPointer
        withRemove={
          showCloseIcon
            ? {
                onClick: () =>
                  onChange(dependency, true, dependency.isTaskBlocked)
              }
            : false
        }
      />
    </div>
  );
};

const Dependency = React.memo(({ task, setTask }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showMoreDependency, setShowMoreDependency] = useState(false);

  const { refetchObjective, invalidateTaskQueries } = useRefetchQuery();

  const { id } = task;

  const taskBlocked =
    task?.blockedBy?.length > 0
      ? [...task.blockedBy].map((task) => ({
          id: task.id,
          name: task.name,
          iconLeft:
            task.state === 'completed'
              ? 'icon-check_circle'
              : 'icon-hourglass_empty',
          iconLeftColor:
            task.state === 'completed' ? 'var(--g-600)' : 'var(--y-600)',
          state: task.state,
          isTaskBlocked: true
        }))
      : [];

  const taskBlocking =
    task?.blocking?.length > 0
      ? [...task.blocking].map((task) => ({
          id: task.id,
          name: task.name,
          iconLeft: 'icon-remove_circle_outline',
          iconLeftColor: 'var(--r-600)',
          isTaskBlocked: false
        }))
      : [];

  const allDependency = [...taskBlocked, ...taskBlocking];

  const moreDependencies = allDependency.slice(2);
  const filteredTaskBlocked = moreDependencies.filter(
    ({ isTaskBlocked }) => isTaskBlocked
  );
  const filteredTaskBlocking = moreDependencies.filter(
    ({ isTaskBlocked }) => !isTaskBlocked
  );

  const onChange = async (
    { id: dependencyId },
    isRemove,
    isTaskBlocked = true
  ) => {
    let body = { [isTaskBlocked ? 'blockedById' : 'blockingId']: dependencyId };
    isRemove
      ? await deleteObjectiveDependencySuggestions(id, body)
      : await addObjectiveDependencySuggestions(id, body);
    refetchObjective(id);
    refetchObjective(dependencyId);
    invalidateTaskQueries({ task });
  };

  const openDropdown = (e) => {
    !e.shiftKey && !(e.ctrlKey || e.metaKey) && setIsDialogOpen(true);
  };

  useScroll({
    id: 'my-task-section',
    condition: isDialogOpen,
    eventScroll: () => setIsDialogOpen(false)
  });

  return (
    <MenuOutsideClickWrapper
      customClass="w-full h-full relative"
      event={() => setIsDialogOpen(false)}
    >
      <TooltipContainer
        show={!isDialogOpen && allDependency.length == 0}
        text="Add dependency"
        position="bottom"
        classContainer="w-full h-full flex items-center px-[16px]"
        onClick={(e) => openDropdown(e)}
        dataCy="trigger-dependency"
      >
        <div className="inline-flex gap-[8px]">
          {allDependency.map((dependency, index) => {
            if (index < 2) {
              return (
                <TooltipContainer
                  show
                  text={
                    dependency.isTaskBlocked
                      ? `This task is blocked by “${dependency.name}”`
                      : `This task is blocking “${dependency.name}”`
                  }
                  position="top"
                  useMaxWidth={false}
                  key={dependency.id}
                >
                  <BadgeDependency
                    dependencies={allDependency}
                    dependency={dependency}
                    onChange={onChange}
                    iconLeft={dependency.iconLeft}
                    iconLeftColor={dependency.iconLeftColor}
                  />
                </TooltipContainer>
              );
            }
          })}

          {/* if dependencies more than 2 dependency */}
          {allDependency.length > 2 && (
            <MenuOutsideClickWrapper
              customClass="w-full h-full"
              event={() => setShowMoreDependency(false)}
            >
              <InlineDialog type="multiple">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMoreDependency(true);
                  }}
                >
                  <BadgeDependency
                    dependencies={allDependency}
                    dependency={`+ ${moreDependencies.length}`}
                    withCloseIcon={false}
                  />
                </div>

                <FloatingComponent lockScroll={false}>
                  {showMoreDependency ? (
                    <InlineDialog.MenuItems position="right" useTrigger={false}>
                      {filteredTaskBlocked.length > 0 && (
                        <>
                          <p className="mb-[8px] px-[16px] typography-h100 text-n-600">
                            {getObjectiveLocale('This task is blocked by')}
                          </p>

                          {filteredTaskBlocked.map((task) => (
                            <InlineDialog.MenuItem
                              key={task.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                onChange(task, true);
                              }}
                            >
                              <div className="flex items-center">
                                <SVGIcon
                                  size="24"
                                  fillColor={
                                    task.state === 'completed'
                                      ? 'var(--g-600)'
                                      : 'var(--y-600)'
                                  }
                                  iconName={
                                    task.state === 'completed'
                                      ? 'icon-check_circle'
                                      : 'icon-hourglass_empty'
                                  }
                                />
                                <p className="ml-[16px] typography-h400 text-n-900">
                                  {task.name}
                                </p>
                              </div>
                            </InlineDialog.MenuItem>
                          ))}
                        </>
                      )}

                      {filteredTaskBlocking.length > 0 && (
                        <>
                          <p className="mt-[16px] mb-[8px] px-[16px] typography-h100 text-n-600">
                            {getObjectiveLocale('This task is blocking')}
                          </p>

                          {filteredTaskBlocking.map((task) => (
                            <InlineDialog.MenuItem
                              key={task.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                onChange(task, true, task.isTaskBlocked);
                              }}
                            >
                              <div className="flex items-center">
                                <SVGIcon
                                  size="24"
                                  fillColor="var(--r-600)"
                                  iconName="icon-remove_circle_outline"
                                />
                                <p className="ml-[16px] typography-h400 text-n-900">
                                  {task.name}
                                </p>
                              </div>
                            </InlineDialog.MenuItem>
                          ))}
                        </>
                      )}
                    </InlineDialog.MenuItems>
                  ) : (
                    <></>
                  )}
                </FloatingComponent>
              </InlineDialog>
            </MenuOutsideClickWrapper>
          )}
        </div>
      </TooltipContainer>

      {isDialogOpen && (
        <div className="absolute top-0 left-0 w-full h-full">
          <DependencyDropdown
            task={task}
            setTask={setTask}
            position="middle"
            selectedTask={taskBlocked}
            onChange={onChange}
          />
        </div>
      )}
    </MenuOutsideClickWrapper>
  );
});

Dependency.displayName = 'Dependency';

export default Dependency;

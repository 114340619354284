import React, { useCallback, useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';

import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';

import Tabs from 'components/design-system/tabs/Tabs';
import BannerBox from 'components/shared/BannerBox';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import SearchBar from 'components/shared/SearchBar';
import TruncateParagraph from 'components/shared/TruncateParagraph';
import { getPeerSuggestions } from 'src/client/FormalReviewClient';

import UserCardRow from './components/UserCardRow';
import UserCardSuggestion from './components/UserCardSuggestion';
import { ADMIN, B2B } from './const';

let _debounced = debounce((action) => action(), 1000);

const ModalPeerReviewerTab = ({
  currentSelectedUsers,
  minimumApprovalCount,
  selectedUsers,
  statePhase,
  approvalCount,
  maximumApprovalCount,
  target,
  cycleId,
  isSelf,
  setCurrentSelectedUsers,
  onRemoveUser,
  onLockUser,
  allowLockedByManager,
  actor,
  maximumPeerCountEnabled,
  maximumPeerSelectedEnabled,
  isPeerReviewDraft,
  isReadOnly
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [others, setOthers] = useState([]);
  const [onLoadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pagination, setPagination] = useState({});

  const isPhaseDone = statePhase == 'done' || !isPeerReviewDraft;

  const ref = useRef();
  useClickOutside(ref, () => {
    setShowPopup(false);
  });

  const onSelect = (user) => {
    let newSelectedUsers = [...currentSelectedUsers];
    const newUser = {
      id: user.id,
      image: user.profilePicture,
      jobTitle: user.jobTitle,
      title: user.title,
      subtitle: user.subtitle,
      isMandatory: false,
      name: user.name,
      selectorType: isSelf ? 'self' : 'manager',
      selectorId: actor.id,
      targetIdIndicator: target.id,
      source: 'original'
    };
    newSelectedUsers.push(newUser);
    setCurrentSelectedUsers(newSelectedUsers);
    document.getElementById('input-select-peer').value = '';
    setShowPopup(false);
  };

  const getSuggestion = useCallback(
    async (params) => {
      const exclude = currentSelectedUsers.map((user) => user.id);

      let query = {
        excludeActorIds: exclude,
        limit: 10
      };

      if (params?.olderThan != null) {
        query.olderThan = params.olderThan;
      }

      if (params?.q != null) {
        query.q = params.q;
      }

      const {
        data,
        isSuccess,
        pagination: pg
      } = await getPeerSuggestions(cycleId, target.id, query);
      if (!isSuccess) return;
      const selectedIds = selectedUsers?.map((user) => user.id) || [];
      let cloneData = data.map((user) => {
        let cloned = { ...user };
        if (selectedIds.includes(user.id)) {
          cloned.selectedCount = user.selectedCount - 1;
          cloned.remainingQuota = user.remainingQuota + 1;
        }
        return cloned;
      });
      setOthers((draft) => {
        if (!params?.olderThan) return cloneData;
        return [...draft, ...cloneData];
      });
      if (cloneData.length == 10) {
        setHasMore(true);
        setPagination(pg);
      } else {
        setHasMore(false);
      }
    },
    [setOthers, currentSelectedUsers, cycleId, target.id, selectedUsers]
  );

  const onScroll = (e) => {
    const targetScroll = e.target;
    const loadMore = async () => {
      let query = { q: search };

      setLoadMore(true);
      if (pagination) {
        query.olderThan = pagination.next.olderThan;
      }
      await getSuggestion(query);
      setLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(targetScroll, 200, () => {
        loadMore();
      });
    }
  };

  const getPeerHandler = async (value) => {
    setIsLoading(true);
    setSearch(value);

    let query = { q: value };

    await getSuggestion(query);
    setIsLoading(false);
  };

  const searchColleague = async (value) => {
    _debounced(async () => {
      setOthers([]);
      getPeerHandler(value);
    });
  };

  const currentSelectedClone = [...currentSelectedUsers];
  const selectedUsersClone = [...selectedUsers];

  const selectedUsersCounter = selectedUsersClone.filter(
    (data) => data.selectorType != ADMIN && data.source != B2B
  ).length;

  const currentSelectedCounter = currentSelectedClone.filter(
    (data) => data.selectorType != ADMIN && data.source != B2B
  ).length; // injected peer & back to back peer (selected user side) not counted as selected

  useEffect(() => {
    getPeerHandler();
    const closePopup = () => {
      setShowPopup(false);
    };
    window.addEventListener('resize', closePopup);

    return () => {
      window.removeEventListener('resize', closePopup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedUsers]);

  return (
    <Tabs.Content id="reviewer-tab">
      <div className="px-[8px] my-[16px]">
        {!isPhaseDone &&
          !isReadOnly &&
          currentSelectedCounter < minimumApprovalCount &&
          selectedUsersCounter < minimumApprovalCount && (
            <BannerBox
              customClass="px-[16px] mx-[16px]"
              text={getObjectiveLocale(
                `The minimum number of peers that must be selected is ([minimum_approval_count])`
              ).replace(/\[minimum_approval_count]/g, minimumApprovalCount)}
              type="warning"
              dataCy="bannerbox-warning-peerselection"
            />
          )}

        {isPhaseDone && (
          <BannerBox
            customClass="px-[16px] mx-[16px]"
            text={getObjectiveLocale('Time for Peer Selection has ended')}
            type="warning"
            dataCy="bannerbox-warning-peerselection-phase"
          />
        )}
        {!isPhaseDone && isReadOnly && (
          <BannerBox
            customClass="px-[16px] mx-[16px]"
            text={getObjectiveLocale(
              'Your peer selection period has ended, and the manager will review your chosen peer.'
            )}
            type="warning"
            dataCy="bannerbox-warning-peerselection-read-only"
          />
        )}
        {!isPhaseDone &&
        !isReadOnly &&
        maximumPeerCountEnabled &&
        currentSelectedCounter >= maximumApprovalCount ? (
          <BannerBox
            customClass="px-[16px] mx-[16px]"
            text={getObjectiveLocale(
              `You've selected the maximum number ([approval_count]) of Peer Reviewers.`
            ).replace(/\[approval_count]/g, approvalCount)}
            type="info"
            dataCy="bannerbox-info-peerselection"
          />
        ) : (
          !isPhaseDone &&
          !isReadOnly && (
            <>
              <p className="typography-h100 text-n-800 mx-[16px]">
                {getObjectiveLocale('Select Peers')}
              </p>
              <div
                ref={ref}
                className={`peer-search-container relative mx-[16px] ${
                  currentSelectedCounter === 0 ? 'mb-[0px]' : ''
                }`}
              >
                <SearchBar
                  fullWidth={true}
                  onChange={(e) => searchColleague(e.target.value)}
                  placeholder={getObjectiveLocale('Select employee')}
                  onFocus={() => setShowPopup(true)}
                  dataCy="search-peers"
                  id="input-select-peer"
                  iconFillColor="var(--n-600)"
                  containerClass="mt-[8px]"
                  customClass="pl-[40px]"
                />
                {showPopup == true && (
                  <div
                    className="search-popup fixed z-[1] bg-n-000 shadow-overlay rounded max-h-[288px] overflow-auto"
                    onScroll={(e) => onScroll(e)}
                    style={{
                      top: ref?.current?.getBoundingClientRect()?.bottom || 0,
                      left: ref?.current?.getBoundingClientRect()?.left || 0,
                      width: ref?.current?.getBoundingClientRect()?.width || 0
                    }}
                  >
                    <p className="typography-h100 text-n-600 h-[32px] px-[16px] flex items-center">
                      {getObjectiveLocale('Suggested')}
                    </p>
                    {isLoading && <LoadingComponent />}
                    {!isLoading && others?.length > 0 ? (
                      others.map((user, index) => {
                        return (
                          <UserCardSuggestion
                            key={index}
                            user={user}
                            onSelect={(value) => {
                              onSelect(value);
                              setShowPopup(false);
                            }}
                            maximumPeerSelectedEnabled={
                              maximumPeerSelectedEnabled
                            }
                          />
                        );
                      })
                    ) : (
                      <ListEmptyState
                        emptyIcon="icon-no-result-found"
                        size="small"
                        title={getObjectiveLocale('No result found')}
                        subtitle={getObjectiveLocale(
                          "We couldn't find what you're looking for"
                        )}
                        containerClassname="mb-[24px]"
                        fullHeight
                      />
                    )}
                    {!isLoading && onLoadMore && <LoadingComponent />}
                  </div>
                )}
              </div>
            </>
          )
        )}
        <>
          {currentSelectedUsers.length > 0 && (
            <div className="mt-[30px]">
              <div className="flex items-center justify-between mx-[16px] mb-[14px]">
                <div className="w-[272px]">
                  <TruncateParagraph
                    className="typography-h200 text-n-800 uppercase"
                    position="bottom"
                    useMaxWidth={true}
                  >
                    {getObjectiveLocale(
                      'People Who Will Review [user]'
                    ).replace('[user]', target.name)}
                  </TruncateParagraph>
                </div>
                <span className="typography-h200 text-n-800 uppercase">
                  {currentSelectedCounter} Selected&nbsp;
                  <>
                    · {minimumApprovalCount} {getObjectiveLocale('Minimum')}{' '}
                  </>
                  {maximumPeerCountEnabled && approvalCount && (
                    <>
                      · {approvalCount} {getObjectiveLocale('Maximum')}
                    </>
                  )}
                </span>
              </div>
              <div>
                {currentSelectedUsers.map((user) => (
                  <UserCardRow
                    key={user.id + '-' + user.targetIdIndicator}
                    userdata={user}
                    selectedBySelf={actor.id == user.selectorId}
                    isSelf={isSelf}
                    canRemove={!isPhaseDone && !isReadOnly}
                    onRemove={onRemoveUser}
                    onLock={onLockUser}
                    allowLockedByManager={allowLockedByManager}
                    isForceLock={isPhaseDone && !isReadOnly}
                    useIcon
                  />
                ))}
              </div>
            </div>
          )}
        </>
      </div>
    </Tabs.Content>
  );
};

export default ModalPeerReviewerTab;

import React, { useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import 'scss/admin/_sidebar';

import { updateWorkloadStatus } from 'client/UserProfile';
import {
  getListSwitchPosition,
  updateSwitchPosition
} from 'client/adminClient.js';
import { useAuth } from 'context/AuthContext';
import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import {
  getDateLocale,
  getNumberLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';
import { getCssVariableValue } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import DropdownProfile from 'components/dropdown/DropdownProfile';
import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipPortal from 'components/shared/Tooltips/TooltipPortal';

import { AppLogo, ModalColleague, ModalSwitchApp } from './HelperSidebar';
import MenuSection from './MenuSection';

function SidebarMaximized({
  toggleCheckin,
  shortcutMenu,
  dropDownSearchOpen,
  sidebarMinimized,
  onSearchCollagues,
  setIsHaveNotif,
  setModalChangePassword,
  isHaveNotif,
  isAppCulture,
  isAbleToSwitch,
  activitiesCount,
  approvalCount,
  surveyCount,
  feedbackCount,
  formalReviewCount,
  needResponseCount,
  needResponseReviewCount,
  needResponseCommentCount,
  setIsSwitchOpen
}) {
  const { user, config, organization, appType } = useUser();
  const { logout } = useAuth();

  const [isSwitchMaxOpen, setIsSwitchMaxOpen] = useState(false);
  const [portalData, setPortalData] = useState({});
  const [manageableStatus, setManageableStatus] = useState(user.workloadStatus);
  const [showListPosition, setShowListPosition] = useState(false);
  const [listPosition, setListPosition] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const refColleague = useRef();
  const switchAppRef = useRef();
  const profileRef = useRef();

  useClickOutside(refColleague, () => onSearchCollagues(false));
  useClickOutside(switchAppRef, () => setIsSwitchMaxOpen(false));

  const getList = async () => {
    const { data } = await getListSwitchPosition(user.id);
    if (data) {
      setListPosition(data);
    }
  };

  const handleSwitchPosition = async (placementId) => {
    const { isSuccess } = await updateSwitchPosition(user.id, placementId);
    if (isSuccess) {
      location.reload();
    }
  };

  const scrollDiv = document.getElementById('sidebar-fixed-menu');

  const getPositionModal = (id, additionalOffset) => {
    const col = document.getElementById(id);
    return col?.offsetTop - scrollDiv?.scrollTop + additionalOffset;
  };

  function isTruncated(el) {
    return el.scrollWidth > el.clientWidth;
  }

  let tempPosition =
    listPosition?.length > 2
      ? showMore
        ? listPosition
        : listPosition.slice(0, 2)
      : listPosition;

  useEffect(() => {
    config?.enableSwitchPosition && user?.ableToSwitchPosition && getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsSwitchOpen(isSwitchMaxOpen);
  }, [isSwitchMaxOpen]);
  return (
    <>
      <div className="menu-upper">
        {/* Switch App */}
        <div
          id="switch-app"
          className={`with-border ${
            isAbleToSwitch ? 'switch-app' : 'unswitch-app'
          }`}
        >
          <div ref={switchAppRef}>
            <div
              className="current-app"
              onClick={() =>
                isAbleToSwitch && setIsSwitchMaxOpen(!isSwitchMaxOpen)
              }
            >
              <AppLogo isAppCulture={isAppCulture} />
              <div className="truncate typography-h500 mx-[8px]">
                {isAppCulture
                  ? organization.cultureAppName
                  : organization.appName}
              </div>
              {isAbleToSwitch && (
                <SVGIcon
                  iconName="icon-unfold_more"
                  size="16"
                  fillColor="var(--n-000)"
                />
              )}
            </div>
            {isSwitchMaxOpen && (
              <ModalSwitchApp
                setIsSwitchOpen={setIsSwitchMaxOpen}
                isAppCulture={isAppCulture}
                organization={organization}
              />
            )}
          </div>
        </div>
        {/* Profile */}
        <div
          ref={profileRef}
          id="profile-maximized"
          className="profile-sidebar-section"
        >
          <DropdownProfile
            isSidebarMaximized={true}
            user={user}
            manageableStatus={manageableStatus}
            setManageableStatus={setManageableStatus}
            setModalChangePassword={setModalChangePassword}
            updateWorkloadStatus={updateWorkloadStatus}
            logout={logout}
          />
        </div>
        {/* Check in */}
        {user.role && appType !== 'culture' && config?.showCheckInButton && (
          <div className="check-in">
            <Button
              onClick={toggleCheckin}
              datacy="button-check-in"
              customClass="w-full"
            >
              {getObjectiveLocale('Check-in')}
            </Button>
          </div>
        )}
        {/* Shortcut Menu */}
        <div className="shortcut-menu">
          {shortcutMenu.map((menu, key) => {
            return (
              <div
                className="shortcut-menu-item justify-between py-[8px] px-[16px] h-[32px]"
                data-cy={menu.dataCy}
                onClick={(e) => menu.onClick(e)}
                key={key}
              >
                <div className="flex items-center S800">
                  <SVGIcon
                    customClass="mr-[8px]"
                    size="16"
                    fillColor="var(--n-000)"
                    iconName={menu.icon}
                    id={`${menu.dataCy}-maximized`}
                  />
                  {menu.title}
                </div>
                {menu?.notificationCount > 0 && (
                  <Badge
                    bgColorHex={getCssVariableValue('--r-600')}
                    colorHex={getCssVariableValue('--n-000')}
                    className="ml-[4px]"
                    content={getNumberLocale(menu?.notificationCount)}
                  />
                )}
              </div>
            );
          })}
          {dropDownSearchOpen && !sidebarMinimized && (
            <ModalColleague
              ref={refColleague}
              onSearchCollagues={onSearchCollagues}
              top={getPositionModal('search-collagues-maximized', -20)}
              left={scrollDiv?.offsetWidth}
            />
          )}
        </div>
        {/* Sidebar Menu */}
        <div className="sidebar-menu-new">
          <MenuSection
            isHaveNotif={isHaveNotif}
            setIsHaveNotif={setIsHaveNotif}
            activitiesCount={activitiesCount}
            approvalCount={approvalCount}
            needResponseCount={needResponseCount}
            surveyCount={surveyCount}
            feedbackCount={feedbackCount}
            formalReviewCount={formalReviewCount}
            needResponseReviewCount={needResponseReviewCount}
            needResponseCommentCount={needResponseCommentCount}
          />
        </div>
      </div>
      {/* Switch Position */}
      {config?.enableSwitchPosition && user?.ableToSwitchPosition && (
        <div className="menu-lower sticky bottom-0 bg-base-800 z-10 w-full">
          <div
            className="switch-role cursor-pointer flex items-center justify-between p-16 border-solid border-t border-0 border-n-900 h-[48px]"
            onClick={() => setShowListPosition(!showListPosition)}
          >
            <p className="flex items-center typography-h300 capitalize">
              <SVGIcon
                size="16"
                fillColor="var(--n-000)"
                iconName="icon-business_center"
                customClass="mr-[8px]"
              />
              {getObjectiveLocale('SWITCH ROLE')}
            </p>
            <SVGIcon
              size="24"
              fillColor="var(--n-000)"
              iconName={
                showListPosition
                  ? 'icon-keyboard_arrow_down'
                  : 'icon-keyboard_arrow_right'
              }
              customClass="toggle-menu-icon"
            />
          </div>
          {showListPosition && (
            <>
              {tempPosition?.map((role, index) => {
                const setPortal = (e) => {
                  setIsTooltipShown(true);
                  setPortalData({
                    name: role?.name,
                    desc: role?.user?.info?.workUnit,
                    left: '264px',
                    top:
                      document
                        .getElementById(`row-position-${index}`)
                        .getBoundingClientRect().top +
                      0 +
                      'px',
                    noWrap: true
                  });
                };
                return (
                  <div key={index}>
                    <div
                      id={`row-position-${index}`}
                      onClick={() => handleSwitchPosition(role?.placementId)}
                      className="row-position-sidebar relative h-[76px] px-[16px] py-[12px] cursor-pointer"
                    >
                      <p
                        onMouseEnter={(e) => {
                          if (isTruncated(e.currentTarget)) {
                            setPortal(e);
                          }
                        }}
                        onMouseLeave={() => setIsTooltipShown(false)}
                        className="truncate typography-h400 text-n-000"
                      >
                        {role?.name}
                      </p>
                      <div className="mt-[4px]">
                        {role?.user?.info?.orgUnit && (
                          <p
                            onMouseEnter={(e) => {
                              if (isTruncated(e.currentTarget)) {
                                setPortal(e);
                              }
                            }}
                            onMouseLeave={() => setIsTooltipShown(false)}
                            className="typography-h100 text-n-000 truncate"
                          >
                            {role?.user?.info?.orgUnit}
                          </p>
                        )}
                        <p className="typography-h100 text-n-600 truncate">
                          {getDateLocale(role?.user?.placement?.startsAt, {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                          })}{' '}
                          -{' '}
                          {role?.user?.placement?.endsAt
                            ? getDateLocale(role?.user?.placement?.endsAt, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric'
                              })
                            : getObjectiveLocale('Now')}
                        </p>
                      </div>
                    </div>
                    {!isEmpty(portalData) && (
                      <TooltipPortal
                        show={isTooltipShown}
                        portalData={portalData}
                        portalId={'shared-tooltip'}
                      />
                    )}
                  </div>
                );
              })}
              {listPosition.length > 2 && (
                <div
                  className="cursor-pointer px-[16px] py-[8px]"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore
                    ? 'Show less'
                    : `Show ${listPosition.length - 2} more`}
                </div>
              )}
              <div className="h-[16px]" />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default SidebarMaximized;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  deleteTeamsIdCMSCulture as deleteParticipantTeam,
  getTeamsCMSCulture as getParticipantTeam
} from 'client/adminClient';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import ParticipantSkeletonLoading from 'components/admin/Participant-Team/ParticipantSkeletonLoading';
import Button from 'components/design-system/Button';
import Table from 'components/design-system/table/Table';
import ListEmptyState from 'components/shared/ListEmptyState';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SearchBar from 'components/shared/SearchBar';
import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';
import TruncateParagraph from 'src/components/shared/TruncateParagraph';

const ParticipantTeam = ({ route: { title, isReadOnly } }) => {
  const history = useHistory();
  const [responderGroupLists, setResponderGroupLists] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [modalOpen, setModalOpen] = useState({ show: false, data: null });
  const [toastData, setToastData] = useState({ show: false, msg: '' });
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);

  const hasMore = pagination?.olderThan !== null;
  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const tableHeaders = [
    {
      name: getObjectiveLocale('Participant Group Name'),
      widthClass: 'w-[480px]',
      hide: false,
      grow: true
    },
    { name: getObjectiveLocale('Total participants'), widthClass: 'w-[240px]' },
    !isReadOnly && { name: '', widthClass: 'w-[96px]' },
    !isReadOnly && { name: '', widthClass: 'w-[96px]' }
  ]?.filter((val) => val);

  const _handleGetParticipant = async (additionalParams) => {
    const params = {
      ...additionalParams,
      limit: '10',
      q: searchValue
    };
    let { data, pagination } = await getParticipantTeam(params);
    if (data) {
      setResponderGroupLists(data);
      setPagination({
        olderThan: data.length < 10 ? null : pagination?.next?.olderThan,
        newerThan: pagination?.prev?.newerThan
      });
    }
    setIsloading(false);
  };

  const deleteSurvey = async () => {
    const { isSuccess, error } = await deleteParticipantTeam(
      modalOpen?.data?.id
    );
    if (isSuccess) {
      _handleGetParticipant();
      setModalOpen({ show: false, data: null });
    }
    setToastData({
      show: true,
      msg: isSuccess
        ? getObjectiveLocale('Success delete team')
        : error?.message
    });
    setTimeout(() => {
      setToastData({ show: false, msg: '' });
    }, 3000);
  };

  const changePage = (type) => {
    const paginationKey = type === 'next' ? 'olderThan' : 'newerThan';
    _handleGetParticipant({ [paginationKey]: pagination[paginationKey] });
    setCurrentPage(currentPage + (type === 'next' ? 1 : -1));
  };

  const headerProps = {
    titlePage: title,
    primaryAction: !isReadOnly && {
      title: 'Create Participant Team',
      icon: 'icon-add',
      onClick: () => history.replace('/add-participant')
    }
  };

  useEffect(() => {
    _handleGetParticipant();
  }, [debouncedSearchValue]);

  return (
    <>
      <HeaderPage {...headerProps} />
      {toastData?.show && (
        <ToastNotif showNotif={toastData?.show} message={toastData?.msg} />
      )}
      <div className="pt-[24px] bg-n-000 min-h-[calc(100vh-64px)]">
        <SearchBar
          fullWidth={true}
          placeholder="Search group"
          onChange={(e) => setSearchValue(e.target.value)}
          containerClass="mb-[24px]"
        />
        {isLoading ? (
          <ParticipantSkeletonLoading />
        ) : responderGroupLists?.length === 0 ? (
          <ListEmptyState
            fullHeight
            containerClassname="py-[80px]"
            emptyIcon="icon-no-user"
            title={getObjectiveLocale(
              searchValue
                ? 'The participant group name is not found'
                : "You don't have any participants"
            )}
            subtitle={getObjectiveLocale(
              searchValue
                ? 'Did you probably type the keyword incorrectly? We are sorry that what you searched was unfortunately not found.'
                : "Once the participant teams are set, you'll see them here."
            )}
          />
        ) : (
          <>
            <Table headers={tableHeaders} fixedLeftCount={1}>
              {responderGroupLists?.map((group, index) => (
                <Table.Row key={`table-row-${index}`}>
                  <Table.Column key="table-column-0">
                    <TruncateParagraph className="typography-h300">
                      {group?.name}
                    </TruncateParagraph>
                  </Table.Column>
                  <Table.Column key="table-column-1">
                    {group?.userCount}
                  </Table.Column>
                  {!isReadOnly && (
                    <Table.Column key="table-column-2">
                      <Button.Tertiary
                        onClick={() =>
                          history.replace(`edit-participant/${group?.id}`)
                        }
                        datacy="edit-participant"
                      >
                        {getObjectiveLocale('Edit')}
                      </Button.Tertiary>
                    </Table.Column>
                  )}
                  {!isReadOnly && (
                    <Table.Column key="table-column-3">
                      <Button.Tertiary
                        onClick={() =>
                          setModalOpen({ show: true, data: group })
                        }
                        datacy="delete-participant"
                      >
                        {getObjectiveLocale('Delete')}
                      </Button.Tertiary>
                    </Table.Column>
                  )}
                </Table.Row>
              ))}
            </Table>
            <div className="flex justify-end w-full mt-[12px] pb-[40px]">
              <PaginationModule
                pageNumber={currentPage}
                handleNext={() => changePage('next')}
                handlePrev={() => changePage('prev')}
                hasMore={hasMore}
              />
            </div>
          </>
        )}
        {modalOpen?.show && (
          <Modal
            title={`${getObjectiveLocale('Delete')} ${
              modalOpen?.data?.name || 'team'
            }`}
            description={getObjectiveLocale(
              'Are you sure you want to delete this team?'
            )}
            headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
            eventOnClickClose={() =>
              setModalOpen({ isShow: false, data: null })
            }
            className="w-[400px]"
            withPrimaryBtn={{
              danger: true,
              title: getObjectiveLocale('Delete'),
              dataCy: 'submit-delete-survey',
              onClick: () => deleteSurvey(),
              isLoading: isLoading
            }}
            withSecondaryBtn={{
              title: getObjectiveLocale('Cancel'),
              dataCy: 'cancel-delete-survey',
              onClick: () => setModalOpen({ isShow: false, id: null })
            }}
          />
        )}
      </div>
    </>
  );
};

export default ParticipantTeam;

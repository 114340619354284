import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import debounce from 'lodash/debounce';

import {
  getCalibrationPermission,
  getResultQuota,
  getResultTarget
} from 'client/FormalReviewClient';
import {
  exportCycleResultCSV,
  getLatestCycleResultCSV
} from 'client/FormalReviewClient';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import { getTargetObj } from 'components/formal-review/calibration/CalibrationUtils';
import CalibrationHistorySidebar from 'components/formal-review/evidence-sidebar/CalibrationHistorySidebar';
import ResultContentTable from 'components/formal-review/progress-result/ResultContentTable';
import ExportCSVButton from 'components/shared/ExportCSVButton';
import ListEmptyState from 'components/shared/ListEmptyState';
import SearchBar from 'components/shared/SearchBar';
import ColumnChart from 'components/shared/charts/columnChart';

const ResultContent = ({
  filter,
  isAdmin,
  currentMenu,
  setFilter,
  searchValue,
  searchDebounce,
  isAbleToShowCurrentScore
}) => {
  const pageParams = useParams();
  const history = useHistory();

  const firstRender = useRef(true);
  const searchFirstRender = useRef(true);
  const { organization } = useUser();

  const [configResult, setConfigResult] = useState({});
  const [listUsers, setListUsers] = useState([]);
  const [scoreAspectsHeader, setScoreAspectsHeader] = useState([]);
  const [pagination, setPagination] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState({});
  const [highChartData, setHighChartData] = useState(null);
  const [chartLoading, setChartLoading] = useState(false);
  const [calibrationMechanism, setCalibrationMechanism] = useState('');
  const [targetObj, setTargetObj] = useState({});
  const [showCalibrationSidebar, setShowCalibrationSidebar] = useState(false);

  const isPerfCategory = calibrationMechanism === 'calibration_categories';

  const getParams = () => {
    let query = {
      sortDirection: sort.sortDirection,
      ...filter
    };

    if (filter) {
      delete query.show;
      query.showType = filter?.show?.map((e) => e?.id);
      query.q = filter.q;
    }

    return query;
  };

  const params = getParams();
  const getUserCycle = async (paginateParam) => {
    setUserLoading(true);
    let query = {
      sortColumn: sort.sortColumn,
      sortDirection: sort.sortDirection,
      sortScore: sort.sortDirection,
      ...filter
    };
    if (query.sortColumn == 'final') {
      delete query.sortColumn;
    }
    if (filter) {
      delete query.show;
      query.showType = filter?.show?.map((e) => e?.id);
      query.q = filter.q;
      query.role = 'reviewee';
    }
    if (paginateParam == 'prev') {
      query.newerThan = pagination?.prev?.newerThan;
    }
    if (paginateParam == 'next') {
      query.olderThan = pagination?.next?.olderThan;
    }

    if (isAdmin) query.adminMode = true;
    const {
      data,
      pagination: dataPagination,
      metadata
    } = await getResultTarget(pageParams.id, query);
    if (data) {
      const displayCycleResult = metadata?.metadata?.displayCycleResult;
      const scoreAspects =
        displayCycleResult?.scoreAspects?.length != 0 &&
        displayCycleResult?.scoreAspects?.map((data) => ({
          header: data.name,
          template: 'scoring-cell',
          width: 200,
          idAspect: data.id,
          name: data.name,
          widthClass: 'w-[200px]'
        }));

      setScoreAspectsHeader(scoreAspects || []);
      setConfigResult(displayCycleResult);
      setListUsers(data);
      setPagination(dataPagination);

      const hasMore = dataPagination?.next?.olderThan !== null;
      setHasMore(hasMore);
      setUserLoading(false);
    }
  };

  const getHighChartData = async () => {
    setChartLoading(true);
    const params = {
      division: filter?.Division,
      department: filter?.Department,
      showType: filter?.show?.map((e) => e?.id),
      managerIds: filter?.manager?.map((e) => e?.id),
      ...filter
    };
    filter.manager?.length > 0 && delete params.manager;
    delete params.show;

    if (isAdmin) {
      params.adminMode = true;
    }

    if (history.location.pathname.includes('/result/myteam') || isAdmin) {
      params.graphType = 'team_result_graph';
    } else {
      params.graphType = 'quota_graph';
    }

    const { data } = await getResultQuota(pageParams.id, params);
    if (data) {
      setHighChartData(data);
    }
    setChartLoading(false);
  };

  const _getCalibrationPermission = async () => {
    const { data } = await getCalibrationPermission(pageParams.id);
    if (data) {
      setCalibrationMechanism(data.calibrationMechanism);
    }
  };

  const handleSelectRow = async (e, user) => {
    e.stopPropagation();
    setShowCalibrationSidebar(true);

    const targetObjData = getTargetObj(user);
    setTargetObj(targetObjData);
  };

  const getData = () => {
    getHighChartData();
    if (isPerfCategory) {
      setSort({ sortDirection: 'desc', sortScore: 'desc' });
    } else {
      setSort({ sortDirection: 'asc' });
    }
  };

  const debounceFn = useCallback(debounce(getData, 500), [
    filter,
    currentMenu,
    calibrationMechanism
  ]);

  useEffect(() => {
    debounceFn();

    return debounceFn.cancel;
  }, [filter, currentMenu, debounceFn, calibrationMechanism]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      getUserCycle();
    }
    // eslint-disable-next-line
  }, [sort]);

  useEffect(() => {
    if (currentMenu === 'result') {
      _getCalibrationPermission();
    }
    // eslint-disable-next-line
  }, [currentMenu]);

  useEffect(() => {
    if (searchFirstRender.current) {
      searchFirstRender.current = false;
    } else {
      filter.q = searchValue;
      setFilter(filter);
      getUserCycle();
      setCurrentPage(1);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  const csvManageDisable = ['tiket', 'tikettrial'].includes(
    organization.identifier
  );

  return (
    <div className="px-[40px]">
      <div className="wrapper-chart-result h-[280px]" id="chart-container">
        {highChartData ? (
          <>
            <div className="h-[68px] px-[16px] items-center flex justify-between">
              <p className="typography-h500 text-n-900">
                {getObjectiveLocale('Final score distribution')}
              </p>
              {!csvManageDisable && (
                <ExportCSVButton
                  getLatestCSV={() =>
                    getLatestCycleResultCSV(pageParams.id, params)
                  }
                  exportCSV={() => exportCycleResultCSV(pageParams.id, params)}
                  ready={!userLoading && listUsers.length > 0}
                />
              )}
            </div>
            <ColumnChart
              chartData={highChartData}
              isLoading={chartLoading}
              chartType="distribution"
              isPerfCategory={isPerfCategory}
              withTooltip={false}
            />
          </>
        ) : (
          <ListEmptyState
            fullHeight
            emptyIcon="icon-no-result-found"
            title={getObjectiveLocale('No Data Matches Your Filter')}
            subtitle={getObjectiveLocale(
              'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
            )}
          />
        )}
      </div>
      <div className="mb-[16px]">
        <SearchBar
          fullWidth={true}
          placeholder="Search"
          containerClass="mt-[8px]"
          onChange={(e) => searchDebounce(e.target.value)}
        />
      </div>
      <div className="team-result-table-wrapper mt-[26px]">
        {listUsers && listUsers.length == 0 ? (
          <ListEmptyState
            fullHeight
            emptyIcon="icon-no-result-found"
            title={getObjectiveLocale('No Reviewee Matches Your Filter')}
            subtitle={getObjectiveLocale(
              'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
            )}
          />
        ) : (
          <ResultContentTable
            users={listUsers}
            scoreAspects={scoreAspectsHeader}
            configResult={configResult}
            onClickRow={handleSelectRow}
            currentPage={currentPage}
            prevPage={() => {
              getUserCycle('prev');
              setCurrentPage(currentPage - 1);
            }}
            nextPage={() => {
              getUserCycle('next');
              setCurrentPage(currentPage + 1);
            }}
            hasMore={hasMore}
            isPerfCategory={isPerfCategory}
            isLoading={userLoading}
          />
        )}
      </div>
      {showCalibrationSidebar && (
        <CalibrationHistorySidebar
          cycleId={pageParams.id}
          setShowCalibrationSidebar={setShowCalibrationSidebar}
          userData={targetObj}
          isPerfCategory={isPerfCategory}
          isResultPage={true}
          isAbleToShowCurrentScore={isAbleToShowCurrentScore}
        />
      )}
    </div>
  );
};

export default ResultContent;

import React, { useEffect, useState } from 'react';

import { useImmer } from 'use-immer';

import { editObjective, updateStateObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { objectiveRestructuring } from 'utils/ObjectiveRestructuring';

import InlineDialogParentSuggestion from 'components/inline-dialog/InlineDialogParentSuggestion';
import InlineDialogSelectTag from 'components/inline-dialog/InlineDialogSelectTag';
import ModalUpdateProgress from 'components/modal/ModalUpdateProgress/ModalUpdateProgress';
import Modal from 'components/shared/modal/Modal';
import DateSection from 'components/sidebar/sidebar-project/DateSection';
import Description from 'components/sidebar/sidebar-project/Description';
import GoalType from 'components/sidebar/sidebar-project/GoalType';
import Leader from 'components/sidebar/sidebar-project/Leader';
import Members from 'components/sidebar/sidebar-project/Members';
import Milestone from 'components/sidebar/sidebar-project/Milestone';
import PMO from 'components/sidebar/sidebar-project/PMO';
import Privacy from 'components/sidebar/sidebar-project/Privacy';
import ProgressMetric from 'components/sidebar/sidebar-project/ProgressMetric';
import Reviewer from 'components/sidebar/sidebar-project/Reviewer';
import Squad from 'components/sidebar/sidebar-project/Squad';
import Stakeholders from 'components/sidebar/sidebar-project/Stakeholders';
import Status from 'components/sidebar/sidebar-project/Status';
import Weight from 'components/sidebar/sidebar-project/Weight';
import SidebarRight from 'src/components/design-system/SidebarRight/SidebarRight';
import { trackEvent } from 'src/utils/AnalyticUtils';

const SidebarProject = ({ objectiveDetail, currentTab, isAllActivities }) => {
  const [singleObjectiveAction, setSingleObjectiveAction] = useState(null);
  const [objectiveValue, setObjectiveValue] = useImmer(
    objectiveRestructuring(objectiveDetail)
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [assigneeId, setAssigneeId] = useState([]);

  const {
    id,
    name,
    involvements = [],
    startDate,
    permissions = [],
    commentOptions,
    currentMilestone,
    milestoneType,
    measurement
  } = objectiveDetail || {};

  const { config } = useUser();
  const { objectiveMilestonesFeature } = config;

  const { reload, reloadOverallProgress, reloadOverallStatus, reloadSidebar } =
    useReload();

  const { refetchObjective, refetchQueries } = useRefetchQuery();

  const updateObjective = async (body) => {
    const { isSuccess } = await editObjective(id, body);
    if (isSuccess) {
      refetchObjective(id);
      currentTab == 'activities' &&
        refetchQueries(['project', 'activities', id, isAllActivities]);
    }

    return { isSuccess: isSuccess };
  };

  const getGoalData = () => {
    let tempObjective = objectiveRestructuring(objectiveDetail);
    tempObjective.childrenCount = objectiveDetail.childrenCount;
    tempObjective.stateObject = objectiveDetail.stateObject;
    objectiveDetail.parent && (tempObjective.parent = objectiveDetail.parent);
    setSingleObjectiveAction(tempObjective);
  };

  const completeGoal = async (newState) => {
    const res = await updateStateObjective(id, newState);
    const { data } = res;
    const { type } = newState;

    if (data && type === 'decline')
      trackEvent({
        event: 'decline',
        eventProperties: { status: 'success', id: id, type: data.type }
      });
    if (data && type === 'redo')
      trackEvent({
        event: 'redo',
        eventProperties: { status: 'success', id: id, type: data.type }
      });
    if (data && type === 'completed')
      trackEvent({
        event: 'complete',
        eventProperties: { status: 'success', id: id, type: data.type }
      });

    if (data) setObjectiveValue(data);
    refetchObjective(id);
    reload({
      reloadObjectiveId: { id: id },
      reloadType: 'complete',
      reloadSidebar: !reloadSidebar,
      reloadOverallProgress: !reloadOverallProgress,
      reloadOverallStatus: !reloadOverallStatus
    });

    return res;
  };

  useEffect(() => {
    objectiveDetail && getGoalData();
  }, [objectiveDetail]);

  useEffect(() => {
    singleObjectiveAction &&
      setObjectiveValue((draft) => singleObjectiveAction);
  }, [singleObjectiveAction]);

  useEffect(() => {
    if (involvements) {
      let tempAssignees = involvements?.filter(
        ({ role, visible }) => role === 'assignee' && visible
      );
      let tempAssigners = involvements?.filter(
        ({ role, visible }) => role === 'assigner' && visible
      );
      setAssigneeId({
        assignee_id: tempAssignees?.map(({ user }) => user?.id),
        assigner_id: tempAssigners?.map(({ user }) => user?.id)
      });
    }
  }, [involvements]);

  return (
    <SidebarRight
      dataCy={'sidebar-project'}
      fixed={false}
      customClass="!fixed right-0"
      fullWidth={false}
      customWidth="w-[400px]"
    >
      <SidebarRight.Body withSpace={false} customClass="pb-[60px]">
        <Privacy
          objectiveDetail={objectiveDetail}
          updateObjective={updateObjective}
        />
        <Status
          objectiveDetail={objectiveDetail}
          updateObjective={updateObjective}
        />
        <p className="px-[24px] typography-h400 text-n-900 mt-[32px] mb-[16px]">
          {getObjectiveLocale('Project Involvement')}
        </p>
        {objectiveValue?.team && <Squad objectiveValue={objectiveValue} />}
        <div className="px-[24px] flex items-center">
          <Leader
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            updateObjective={updateObjective}
          />
          <Reviewer
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            updateObjective={updateObjective}
          />
        </div>
        <div className="px-[24px] flex items-center">
          <Members
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            updateObjective={updateObjective}
          />
          <Stakeholders
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            updateObjective={updateObjective}
          />
        </div>
        <div className="px-[24px] flex items-center">
          <PMO
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            updateObjective={updateObjective}
          />
        </div>
        <div className="mt-[16px]">
          <p className="px-[24px] typography-h400 text-n-900 mt-[32px] mb-[8px]">
            {getObjectiveLocale('Project Details')}
          </p>
          <Description
            objectiveDetail={objectiveDetail}
            updateObjective={updateObjective}
          />
          {startDate && (
            <DateSection
              objectiveDetail={objectiveDetail}
              updateObjective={updateObjective}
            />
          )}
          <ProgressMetric
            objectiveDetail={objectiveDetail}
            setModalOpen={setModalOpen}
            setModalType={setModalType}
            updateObjective={updateObjective}
          />
          {objectiveMilestonesFeature && (
            <Milestone
              objectiveDetail={objectiveDetail}
              objectiveValue={objectiveValue}
              setObjectiveValue={setObjectiveValue}
              updateObjective={updateObjective}
            />
          )}
          <GoalType
            objectiveDetail={objectiveDetail}
            objectiveValue={objectiveValue}
            updateObjective={updateObjective}
          />
          <Weight
            objectiveDetail={objectiveDetail}
            updateObjective={updateObjective}
          />
          <InlineDialogParentSuggestion
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            assigneeId={assigneeId}
            permissions={permissions}
            updateObjective={updateObjective}
            isEditAlignment
          />
          <InlineDialogSelectTag
            setObjectiveValue={setObjectiveValue}
            selectedValue={objectiveValue?.tags}
            updateObjective={updateObjective}
          />
        </div>

        {isModalOpen && modalType == 'incomplete' && (
          <Modal
            title={'Incomplete Project'}
            withCloseIcon={false}
            withPrimaryBtn={{
              title: 'Incomplete',
              dataCy: 'button-primary-modal',
              onClick: () => completeGoal({ state: 'running', type: 'redo' })
            }}
            withSecondaryBtn={{
              title: 'Cancel',
              dataCy: 'button-secondary-modal',
              onClick: () => setModalOpen(false)
            }}
            description={'Are you sure you want to incomplete this project?'}
          />
        )}

        {isModalOpen && (modalType == 'complete' || modalType == 'update') && (
          <ModalUpdateProgress
            measurement={measurement ? measurement : null}
            withCloseIcon
            eventOnClick={() => setModalOpen(false)}
            canUpdateCurrentValue={permissions?.includes(
              'update_current_value'
            )}
            objectiveId={id}
            completeGoal={completeGoal}
            modalType={modalType}
            objectiveName={name}
            commentOptions={commentOptions}
            currentMilestone={currentMilestone}
            milestoneType={milestoneType}
            objectiveValue={objectiveDetail}
            saveCallback={() => refetchQueries(['project', 'activities', id])}
          />
        )}
      </SidebarRight.Body>
    </SidebarRight>
  );
};

export default SidebarProject;

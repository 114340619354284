import React, { useState } from 'react';

import debounce from 'lodash/debounce';

import { getObjectiveLocale } from 'utils/HelperUtils';

import NumberInput from 'components/shared/NumberInput';
import SVGIcon from 'components/shared/SVGIcon';
import Toggle from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

let _debounced = debounce((action) => action(), 1000);

const PeersNumber = ({
  label = 'label',
  useToggle = false,
  toggleFlag,
  inputFlag,
  cycleData,
  togglePhase,
  tooltipText,
  id,
  defaultValue,
  defaultToggle
}) => {
  const [value, setValue] = useState(defaultValue || 0);
  const [toggleEnabled, setToggleEnabled] = useState(defaultToggle);

  const onChange = (value) => {
    setValue(value);
    _debounced(() => {
      togglePhase('peerReview', {
        ...cycleData,
        [inputFlag]: parseInt(value) || 0
      });
    });
  };

  const onChangeToggle = () => {
    const currentValue = toggleEnabled;
    setToggleEnabled(!currentValue);
    togglePhase('peerReview', {
      ...cycleData,
      [toggleFlag]: !currentValue
    });
  };

  return (
    <div className="flex flex-col mr-[16px]">
      <div className="flex flex-row mb-[8px]">
        <p className="typography-h100 text-n-800">
          {getObjectiveLocale(label)}
        </p>
        <TooltipContainer
          show
          position="top"
          text={getObjectiveLocale(tooltipText)}
          useMaxWidth={false}
        >
          <SVGIcon
            iconName={'icon-info'}
            size="16"
            fillColor="var(--n-600)"
            customClass="ml-[4px]"
          />
        </TooltipContainer>
      </div>
      <div className="flex flex-row items-center">
        {useToggle && (
          <Toggle
            isChecked={toggleEnabled}
            idStr={`toggle-${id}`}
            onChange={onChangeToggle}
            addClass="flex items-center ml-[-9px]"
          />
        )}
        <NumberInput
          className="w-[188px]"
          value={value}
          onChange={onChange}
          dataCy={`input-${id}`}
          disabled={useToggle && !toggleEnabled}
        />
      </div>
    </div>
  );
};

export default PeersNumber;

import React from 'react';

import produce, { setAutoFreeze } from 'immer';

//CREATE CONTEXT
const SidebarMenuContext = React.createContext();

//PROVIDER
function SidebarMenuProvider(props) {
  const initialState = {
    isMinimized: false,
    isUseToggle: true
  };

  const [state, setState] = React.useState(initialState);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];

  return <SidebarMenuContext.Provider value={contextValue} {...props} />;
}

//MUTATION
function useSidebarMenu() {
  const [{ isMinimized, isUseToggle }, immerSetState] =
    React.useContext(SidebarMenuContext);

  const toggleSidebar = (state) => {
    immerSetState((draft) => {
      draft.isMinimized = state;
    });
  };

  const setUseToggle = (state) => {
    immerSetState((draft) => {
      draft.isUseToggle = state;
    });
  };

  return {
    isMinimized,
    toggleSidebar,
    setUseToggle,
    isUseToggle
  };
}

export { SidebarMenuProvider, useSidebarMenu };

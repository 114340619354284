import React, { useEffect, useState } from 'react';

import { getConfig } from 'client/admin/Config';
import { useUser } from 'context/UserContext';
import useOrganization from 'hooks/useOrganization';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ContentNavigator from 'components/shared/ContentNavigator';

import {
  CardInputOrganization,
  CheckBoxOrganization,
  ToggleCard,
  changeCheckboxData,
  changeUserData,
  generalFilterandCycleDetailOptionCheckbox,
  setActiveNavigator
} from './OrganizationHelper';

const Administration = ({ configParams }) => {
  const { appType, user } = useUser();
  const { configData, changeConfigData, changeOriginConfigData } =
    useOrganization((configData) => ({
      configData: configData.configData,
      changeConfigData: configData.changeConfigData,
      changeOriginConfigData: configData.changeOriginConfigData
    }));
  const [activeSection, setActiveSection] = useState('permission-section');
  const isReadOnly =
    usePermission('settingsOrganizationAdministration') === 'read_only';

  const listUsers = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Show Manage Users menu'),
      cardDescription: getObjectiveLocale(
        'Allow user to see Manage Users menu in organization settings.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          manageUsers: !configData.manageUsers
        }),
      isChecked: configData?.manageUsers,
      dataCy: 'user-manageUsers',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Show import CSV feature'),
      cardDescription: getObjectiveLocale(
        'Allow user to see import CSV button in Manage Users menu.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          showImportCsv: !configData.showImportCsv
        }),
      isChecked: configData?.showImportCsv,
      dataCy: 'user-showImportCsv',
      disabled: isReadOnly
    },
    // {/* ON HOLD UNTIL BACKEND SUPPORT */}
    // {
    //   element: 'toggle',
    //   cardTitle: getObjectiveLocale('Set password when creating account'),
    //   cardDescription: getObjectiveLocale('Allow user to set password when creating new user account.'),
    //   onChange: () => changeUserData("setPassword", "cmsOption", null, configData, changeConfigData),
    //   isChecked: configData?.cmsOption?.setPassword,
    //   dataCy: 'user-cmsOption-setPassword'
    // },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change general information'),
      cardDescription: getObjectiveLocale(
        'Allow user to change general information of individual in Manage Users menu.'
      ),
      onChange: () =>
        changeUserData(
          'general',
          'editableUserInformation',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editableUserInformation?.general,
      dataCy: 'user-editableUserInformation-general',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change job background'),
      cardDescription: getObjectiveLocale(
        'Allow user to change job background of individual in Manage Users menu.'
      ),
      onChange: () =>
        changeUserData(
          'jobBackground',
          'editableUserInformation',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editableUserInformation?.jobBackground,
      dataCy: 'user-editableUserInformation-jobBackground',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change region'),
      cardDescription: getObjectiveLocale(
        'Allow user to change region of individual in Manage Users menu.'
      ),
      onChange: () =>
        changeUserData(
          'region',
          'editableUserInformation',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editableUserInformation?.region,
      dataCy: 'user-editableUserInformation-region',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change platform access'),
      cardDescription: getObjectiveLocale(
        'Allow user to change platform access to Performance and Culture app.'
      ),
      onChange: () =>
        changeUserData(
          'platformAndRole',
          'editableUserInformation',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editableUserInformation?.platformAndRole,
      dataCy: 'user-editableUserInformation-platformAndRole',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Show download app'),
      cardDescription: getObjectiveLocale(
        'Allow user to see Download App page'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          showDownloadApp: !configData.showDownloadApp
        }),
      isChecked: configData?.showDownloadApp,
      dataCy: 'user-showDownloadApp',
      disabled: isReadOnly
    }
  ];

  const listBehavior = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Behavior Model'),
      cardDescription: getObjectiveLocale(
        'Enable Behavior Model (group of competencies and value) in this organization'
      ),
      onChange: () =>
        changeUserData(
          'behavior',
          'editablePlacementGroup',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementGroup?.behavior,
      dataCy: 'user-editablePlacementGroup-behaviorModel',
      disabled: isReadOnly
    }
  ];

  const listFormalReview = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Custom Attribute'),
      cardDescription: getObjectiveLocale(
        'Enable Custom Attribute features and functions in this organization'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          scoredAttributesFeature: !configData.scoredAttributesFeature
        }),
      isChecked: configData?.scoredAttributesFeature,
      dataCy: 'user-editablePlacementGroup-formalReview',
      disabled: isReadOnly
    },
    {
      element: 'card-input',
      cardLabel: 'Goal Scoring',
      label: 'Default Goal Rating Formula',
      placeholder:
        'Formula perhitungan score individual di profile (hanya berlaku jika goal driven)',
      handleChange: (e) =>
        changeConfigData({
          ...configData,
          defaultGoalFormula: e.currentTarget.value
        }),
      value: configData?.defaultGoalFormula,
      dataCy: 'defaultGoalFormula',
      disabled: isReadOnly
    },
    {
      element: 'card-input',
      cardLabel: 'Task Scoring',
      label: 'Default Task Rating Formula',
      placeholder:
        'Formula perhitungan score individual di profile (hanya berlaku jika task driven)',
      handleChange: (e) =>
        changeConfigData({
          ...configData,
          defaultTaskFormula: e.currentTarget.value
        }),
      value: configData?.defaultTaskFormula,
      dataCy: 'defaultTaskFormula',
      disabled: isReadOnly
    }
  ];

  const listPermission = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change permission group'),
      cardDescription: getObjectiveLocale(
        'Allow user to change permission group for individual.'
      ),
      onChange: () =>
        changeUserData(
          'permission',
          'editablePlacementGroup',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementGroup?.permission,
      dataCy: 'user-editablePlacementGroup-permission',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Ignore involvement permission'),
      cardDescription: getObjectiveLocale(
        'Allow user to ignore involvement permission.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          ignoreInvolvementPermission: !configData.ignoreInvolvementPermission
        }),
      isChecked: configData?.ignoreInvolvementPermission,
      dataCy: 'ignoreInvolvementPermission',
      disabled: isReadOnly
    }
  ];

  const listPosition = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Add new position'),
      cardDescription: getObjectiveLocale(
        'Allow user to add new position for individual.'
      ),
      onChange: () =>
        changeUserData(
          'editableNewPosition',
          null,
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editableNewPosition,
      dataCy: 'user-editableNewPosition',
      isVisible: true,
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change position state'),
      cardDescription: getObjectiveLocale(
        'Allow user to change position state for individual.'
      ),
      onChange: () =>
        changeUserData(
          'state',
          'editablePlacementFields',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementFields?.state,
      dataCy: 'user-editablePlacementFields-state',
      isVisible: true,
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change position name'),
      cardDescription: getObjectiveLocale(
        'Allow user to change position name for individual.'
      ),
      onChange: () =>
        changeUserData(
          'positionName',
          'editablePlacementFields',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementFields?.positionName,
      dataCy: 'user-editablePlacementFields-positionName',
      isVisible: true,
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change position start date'),
      cardDescription: getObjectiveLocale(
        'Allow user to change position start date for individual.'
      ),
      onChange: () =>
        changeUserData(
          'startDate',
          'editablePlacementFields',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementFields?.startDate,
      dataCy: 'user-editablePlacementFields-startDate',
      isVisible: true,
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change position end date'),
      cardDescription: getObjectiveLocale(
        'Allow user to change position end date for individual.'
      ),
      onChange: () =>
        changeUserData(
          'endDate',
          'editablePlacementFields',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementFields?.endDate,
      dataCy: 'user-editablePlacementFields-endDate',
      isVisible: true,
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change direct manager'),
      cardDescription: getObjectiveLocale(
        'Allow user to change direct manager for individual.'
      ),
      onChange: () =>
        changeUserData(
          'directManager',
          'editablePlacementFields',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementFields?.directManager,
      dataCy: 'user-editablePlacementFields-directManager',
      isVisible: appType !== 'culture',
      disabled: isReadOnly
    }
  ].filter((menu) => menu.isVisible);

  const listPerformanceProfile = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change performance profile group'),
      cardDescription: getObjectiveLocale(
        'Allow user to change performance profile group for individual.'
      ),
      onChange: () =>
        changeUserData(
          'review',
          'editablePlacementGroup',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementGroup?.review,
      dataCy: 'user-editablePlacementGroup-review',
      disabled: isReadOnly
    }
  ];

  const listObjective = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Editable Goal Creation Period'),
      cardDescription: getObjectiveLocale(
        'Enable to edit period of goal creation'
      ),
      onChange: () =>
        changeUserData(
          'creation',
          'editablePlacementGoalPeriod',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementGoalPeriod?.creation,
      dataCy: 'user-editablePlacementGoalPeriod-creation',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Editable Goal Edit Period'),
      cardDescription: getObjectiveLocale('Enable to edit period of goal edit'),
      onChange: () =>
        changeUserData(
          'edit',
          'editablePlacementGoalPeriod',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementGoalPeriod?.edit,
      dataCy: 'user-editablePlacementGoalPeriod-edit',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Editable Goal Deletion Period'),
      cardDescription: getObjectiveLocale(
        'Enable to edit period of goal deletion'
      ),
      onChange: () =>
        changeUserData(
          'delete',
          'editablePlacementGoalPeriod',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementGoalPeriod?.delete,
      dataCy: 'user-editablePlacementGoalPeriod-delete',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Editable Task Creation Period'),
      cardDescription: getObjectiveLocale(
        'Enable to edit period of task creation'
      ),
      onChange: () =>
        changeUserData(
          'creation',
          'editablePlacementTaskPeriod',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementTaskPeriod?.creation,
      dataCy: 'user-editablePlacementTaskPeriod-creation',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Editable Task Edit Period'),
      cardDescription: getObjectiveLocale('Enable to edit period of task edit'),
      onChange: () =>
        changeUserData(
          'edit',
          'editablePlacementTaskPeriod',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementTaskPeriod?.edit,
      dataCy: 'user-editablePlacementTaskPeriod-edit',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Editable Task Deletion Period'),
      cardDescription: getObjectiveLocale(
        'Enable to edit period of task deletion'
      ),
      onChange: () =>
        changeUserData(
          'delete',
          'editablePlacementTaskPeriod',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementTaskPeriod?.delete,
      dataCy: 'user-editablePlacementTaskPeriod-delete',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Editable Position Cluster'),
      cardDescription: getObjectiveLocale('Enable to edit position cluster'),
      onChange: () =>
        changeUserData(
          'cluster',
          'editablePlacementGroup',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementGroup?.cluster,
      dataCy: 'user-editablePlacementGroup-cluster',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Editable Rating Template'),
      cardDescription: getObjectiveLocale('Enable to edit rating template'),
      onChange: () =>
        changeUserData(
          'ratingTemplate',
          'editablePlacementGroup',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.editablePlacementGroup?.ratingTemplate,
      dataCy: 'user-editablePlacementGroup-ratingTemplate',
      disabled: isReadOnly
    }
  ];

  const listFilter = [
    {
      element: 'checkbox',
      label: getObjectiveLocale('General filter options'),
      handleChange: (data) =>
        changeCheckboxData(
          data,
          configData?.generalFilterOptions,
          'generalFilterOptions',
          null,
          configData,
          changeConfigData
        ),
      selected: configData?.generalFilterOptions,
      isSingleSelect: true,
      dataCy: 'generalFilterOptions',
      listOption: generalFilterandCycleDetailOptionCheckbox,
      disabled: isReadOnly
    }
  ];

  const listApproval = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Send approval email'),
      cardDescription: getObjectiveLocale('Allow user to send approval email.'),
      onChange: () =>
        changeConfigData({
          ...configData,
          sendApprovalEmail: !configData.sendApprovalEmail
        }),
      isChecked: configData?.sendApprovalEmail,
      dataCy: 'sendApprovalEmail',
      disabled: isReadOnly
    }
  ];

  let list = [
    { id: 'permission-section', name: 'Permission', list: listPermission },
    { id: 'position-section', name: 'Position', list: listPosition },
    { id: 'user-section', name: 'User', list: listUsers },
    { id: 'behavior-section', name: 'Behavior', list: listBehavior },
    {
      id: 'formal-review-section',
      name: 'Formal Review',
      list: listFormalReview
    },
    { id: 'filter-section', name: 'Filter', list: listFilter },
    { id: 'approval-section', name: 'Approval', list: listApproval },

    appType !== 'culture' &&
      user.role && {
        id: 'objective-section',
        name: 'Objective',
        list: listObjective
      },
    {
      id: 'performance-profile-section',
      name: 'Performance Profile',
      list: listPerformanceProfile
    }
  ];

  const getData = async () => {
    const { data } = await getConfig(configParams);
    if (data) {
      changeConfigData(data);
      changeOriginConfigData(data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="overflow-auto h-[calc(100vh-64px)] w-[87%] relative"
      id="scrollable-config"
      onScroll={(e) => setActiveNavigator(e, list, setActiveSection, -40)}
    >
      <div className="w-[768px] mt-[24px]">
        <div className="fixed right-0 mt-[24px] w-[160px] z-index-10">
          <ContentNavigator
            title="CONTENTS"
            activeSection={activeSection}
            listNavigatorContents={list}
            additionalOffset={20}
            sectionContent="scrollable-config"
          />
        </div>
        <p className="typography-h300 text-n-800 uppercase mb-[24px]">
          {getObjectiveLocale('Administration Configuration')}
        </p>
        {list.map((el, index) => (
          <section
            className={`${
              el?.id === 'performance-profile-section'
                ? 'pb-[40px] mb-[40px] mt-[24px] pb-[64px]'
                : 'border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]'
            }`}
            id={`${el?.id}`}
            key={index}
          >
            <p className="typography-h500 text-n-900 mb-[24px]">
              {getObjectiveLocale(el.name)}
            </p>
            {el.list?.length > 0 &&
              el.list.map((config, idx) => {
                return config?.element === 'checkbox' ? (
                  <CheckBoxOrganization {...config} key={`${index}-${idx}`} />
                ) : config.element === 'toggle' ? (
                  <ToggleCard {...config} key={`1-${index}`} />
                ) : (
                  <CardInputOrganization {...config} key={`1-${index}`} />
                );
              })}
          </section>
        ))}
      </div>
    </div>
  );
};

export default Administration;

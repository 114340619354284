import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getBehaviors } from 'client/adminClient.js';
import { useValueCompetency } from 'context/admin/ValueCompetencyContext';
import Config from 'utils/Config';

import AWSImage from 'components/design-system/AWSImage';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';
import RadioButton from 'src/components/shared/RadioButton';

function KeyBehaviorSkeletonLoading() {
  return (
    <div className="py-[16px] px-[30px] mb-[8px] bg-n-200">
      <div className="mb-[8px]">
        <Shimmer width="80%" height="24px" />
      </div>
      <div>
        <Shimmer width="100%" height="24px" />
      </div>
    </div>
  );
}

const NewModelCard = ({
  dictionary,
  index,
  behaviorsFramework,
  selectDictionary,
  selectKeyBehaviour,
  dataCompetency,
  setDataCompetency,
  selectedData,
  setSelectedData,
  arrayAccordion,
  setArrayAccordion,
  expectedLevel,
  setExpectedLevel
}) => {
  const [openAccordion, setOpenAccordion] = useState(null);
  const [dataKeyBehavior, setDataKeyBehavior] = useState([]);
  const { paramsDictionaryOrModel } = useValueCompetency();
  const { pathname } = useLocation();
  const [hasMoreChild, setHasMoreChild] = useState(false);
  const [nextOffsetChild, setNextOffsetChild] = useState(0);
  const [currentSizeChild, setCurrentSizeChild] = useState(0);
  const [isSingleLoading, setIsSingleLoading] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [relationExpectedLevel, setRelationExpectedLevel] = useState({});

  const handleOpenAccordion = async (id) => {
    let activeAccordion = openAccordion == id ? null : id;
    if (dataKeyBehavior.length == 0) {
      await _getKeyBehaviorData(id);
    }

    if (arrayAccordion.includes(id)) {
      setArrayAccordion(arrayAccordion.filter((val) => val != id));
    } else {
      setArrayAccordion([...arrayAccordion, id]);
    }

    setOpenAccordion(activeAccordion);
  };

  const _getKeyBehaviorData = async (parentId) => {
    let _params = paramsDictionaryOrModel(pathname, { parentId }, 'dictionary');
    let newDataCompetency = [...dataCompetency];
    setIsSingleLoading(true);
    const { data, pagination } = await getBehaviors(_params);

    if (data) {
      setDataKeyBehavior(data);
      setHasMoreChild(pagination.nextOffset == null ? false : true);
      setNextOffsetChild(pagination.nextOffset);
      setCurrentSizeChild(pagination.currentSize + currentSizeChild);
      setIsSingleLoading(false);
    }

    let newSelectedId = data.map((val) => val.id);
    newDataCompetency[index].keyBehaviorIds = newSelectedId;
    setDataCompetency(newDataCompetency);
    behaviorsFramework == 'proficiency_levels' &&
      findCurrentExpectedLevel(parentId, newSelectedId);

    if (parentId in selectedData && pathname.includes('add')) {
      let newSelectedData = JSON.parse(JSON.stringify(selectedData));
      newSelectedData[parentId] = newSelectedId;
      setSelectedData(newSelectedData);
    }
  };

  const loadMore = async (parentId) => {
    const _params = paramsDictionaryOrModel(
      pathname,
      {
        parentId,
        offset: nextOffsetChild
      },
      'dictionary'
    );

    setIsLoadMoreLoading(true);
    const { data, pagination } = await getBehaviors(_params);
    if (data) {
      setDataKeyBehavior(dataKeyBehavior.concat(data));
      setHasMoreChild(pagination.nextOffset == null ? false : true);
      setNextOffsetChild(pagination.nextOffset);
      setCurrentSizeChild(pagination.currentSize + currentSizeChild);
      setIsLoadMoreLoading(false);
    }
  };

  const findCurrentExpectedLevel = (parentId, array) => {
    for (let i of array) {
      if (expectedLevel.includes(i)) {
        setRelationExpectedLevel({ ...relationExpectedLevel, [parentId]: i });
        break;
      }
    }
  };

  const onChangeExpectedLevel = (parentId, childId) => {
    const prevExpected = relationExpectedLevel[parentId];
    const newExpectedLevel = expectedLevel.filter((ex) => ex !== prevExpected);
    newExpectedLevel.push(childId);
    setExpectedLevel(newExpectedLevel);
    setRelationExpectedLevel({ ...relationExpectedLevel, [parentId]: childId });
  };

  return (
    <>
      <div className={`py-[16px] state-hover flex  mb-[10px]`}>
        <div className="w-[10%]">
          <div className="flex-center-center height-fit-content">
            <Checkbox
              name={String(dictionary.id)}
              id={dictionary.id}
              onChange={(e) =>
                selectDictionary(dictionary, e.currentTarget.checked)
              }
              checked={dictionary.id in selectedData}
              dataCy={`competencies-values-model-checkbox-${dictionary.id}`}
            />
          </div>
        </div>
        <div className="pl-[16px] w-[30%]">
          <div className="flex wrapper-accordion-img-name">
            {dictionary.hasKeyBehaviors && (
              <SVGIcon
                dataCy={'model-accordion-' + dictionary.id}
                size="24"
                fillColor="var(--base-600)"
                iconName="icon-keyboard_arrow_right"
                onClick={() => handleOpenAccordion(dictionary.id)}
                customClass={`icon-chevron ${
                  openAccordion == dictionary.id ? 'accordion-open' : ''
                }`}
              />
            )}
            {pathname.includes('values') && (
              <AWSImage
                className="w-[32px] h-[32px] img-value"
                src={
                  dictionary?.iconImageAttributes?.iconUrl ||
                  Config.asset.admin.defaultValueImg
                }
              />
            )}
            <p className={`typography-paragraph text-n-900`}>
              {dictionary.title}
            </p>
          </div>
        </div>
        <div className="pl-[16px] w-[45%]">{dictionary.description}</div>

        {behaviorsFramework == 'proficiency_levels' && (
          <div className="pl-[16px] w-[15%] flex flex-center-center" />
        )}
      </div>
      {isSingleLoading && <KeyBehaviorSkeletonLoading />}

      <div className="bg-n-200">
        {openAccordion == dictionary.id &&
          dataKeyBehavior &&
          dataKeyBehavior.map((val, idx) => (
            <div
              key={idx}
              className={`py-[16px] flex justify-between items-start mb-[12px]`}
            >
              <div className="w-[10%]">
                <div className="flex-center-center height-fit-content">
                  <Checkbox
                    dataCy={'competencies-values-model-kb-checkbox-' + val.id}
                    name={String(val.id)}
                    id={val.id}
                    onChange={(e) =>
                      selectKeyBehaviour(
                        dictionary,
                        val.id,
                        e.currentTarget.checked
                      )
                    }
                    checked={(selectedData[dictionary.id] || []).includes(
                      val.id
                    )}
                  />
                </div>
              </div>
              <div className="w-[30%] pl-[16px]">
                {behaviorsFramework == 'proficiency_levels' && (
                  <p className="typography-h300 text-n-600 uppercase mb-[16px]">
                    Level {val?.orderLevel + 1}
                  </p>
                )}
                <p className="typography-paragraph text-n-900">{val.title}</p>
              </div>
              <div className="pl-[16px] w-[45%] typography-paragraph text-n-900">
                {behaviorsFramework == 'proficiency_levels' && (
                  <div className="mb-[32px]" />
                )}
                <p className="typography-paragraph text-n-900">
                  {val.description}
                </p>
              </div>
              {behaviorsFramework == 'proficiency_levels' && (
                <div className="pl-[16px] w-[15%] flex flex-center-center">
                  <RadioButton
                    dataCy={`expected-${val.id}`}
                    size="small"
                    value={val.id}
                    id={val.id}
                    checked={expectedLevel.includes(val.id)}
                    onChange={() =>
                      onChangeExpectedLevel(dictionary.id, val.id)
                    }
                  />
                </div>
              )}
            </div>
          ))}
        {!isLoadMoreLoading && hasMoreChild && openAccordion == dictionary.id && (
          <div
            className="table-row-show-more pb-[12px]"
            onClick={() => loadMore(dictionary.id)}
          >
            <div
              className="wrapper-show-more"
              data-cy={'competencies-values-show-more'}
            >
              <span className="text-show-more">Show more</span>
              <img src={Config.asset.v2.dropdown} className="icon-show-more" />
            </div>
          </div>
        )}
        {isLoadMoreLoading && <KeyBehaviorSkeletonLoading />}
      </div>
    </>
  );
};

export default NewModelCard;

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useImmer } from 'use-immer';

import { createSingleObjective } from 'client/ObjectivesClient';
import { updateTeam } from 'client/TeamClient';
import { getManager } from 'client/UserProfile';
import { MetricsProvider, useMetrics } from 'context/MetricsContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import { UserSuggestionProvider } from 'context/UserSuggestionContext';
import { getSemester } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { extractDefaultRollup } from 'utils/ObjectivesHelper';

import Modal from 'components/shared/modal/Modal';

import AddAlignment from './AddAlignment';
import AddMetrics from './AddMetrics';
import ChooseTeamOrMember from './ChooseTeamOrMember';
import SetObjectiveTypeWeight from './SetObjectiveTypeWeight';
import SetupProjectName from './SetupProjectName';

dayjs.extend(quarterOfYear);

const listModalSteps = [
  'Setup Project Name',
  'Choose Team or Add Member',
  'Add Metrics',
  'Set type & weight',
  'Add Alignment'
];

const ModalCreateProject = ({
  eventOnClickClose,
  team = {},
  setShowConfirmationBox
}) => {
  const history = useHistory();
  const {
    user,
    config: {
      defaultPeriodOnCreate,
      defaultRollupForParentObjective,
      objectiveWeightType,
      useDefaultObjectiveCategory
    }
  } = useUser();

  const isMyProjectPage = location.pathname === '/projects';

  const { addToast } = useToastContext();
  const { listMetrics, objectiveCategories } = useMetrics() || {};
  const { refetchObjectives } = useRefetchQuery();

  const startDate =
    defaultPeriodOnCreate === 'semester'
      ? getSemester('start')
      : dayjs().startOf(defaultPeriodOnCreate);

  const dueDate =
    defaultPeriodOnCreate === 'day'
      ? dayjs().add(1, 'month')
      : defaultPeriodOnCreate === 'semester'
      ? getSemester('due')
      : dayjs().endOf(defaultPeriodOnCreate);

  const initialObjectiveValue = useMemo(() => {
    return {
      name: '',
      description: '',
      isPrivate: false,
      startDate: startDate.toISOString(),
      dueDate: dueDate.toISOString(),
      milestoneType: 'disabled',
      calculationType: 'normal',
      team: team,
      teamId: team?.id
    };
  }, [startDate, dueDate, team]);

  const [objectiveValue, setObjectiveValue] = useImmer(initialObjectiveValue);
  const [currentStep, setCurrentStep] = useState(listModalSteps[0]);
  const [initialDefaultMembers, setInitialDefaultMembers] = useState([]);
  const [withAnimation, setWithAnimation] = useState(true);
  const [showModalConfirmSquad, setShowModalConfirmSquad] = useState(false);
  const [newSquadMembersIds, setNewSquadMembersIds] = useState([]);

  const [tempMembers, setTempMembers] = useState({
    teamMember: [],
    projectMember: []
  });

  const [currentTabMember, setCurrentTabMember] = useState(
    team?.id ? 'Choose Team' : 'Add Member'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const stepNumber =
    listModalSteps.findIndex((step) => step === currentStep) + 1;
  const isFirstStep = stepNumber === 1;
  const isLastStep = stepNumber === listModalSteps.length;

  const showToastError = (message) => {
    addToast({
      title: getObjectiveLocale('Cannot go to the next step'),
      msg: getObjectiveLocale(message),
      type: 'error'
    });
  };

  const getModalTitle = () => {
    let text = '';
    switch (currentStep) {
      case 'Setup Project Name':
        text = 'Give the project a name and describe it';
        break;
      case 'Choose Team or Add Member':
        text = 'Select a team or add member';
        break;
      case 'Add Metrics':
        text = 'Set metrics';
        break;
      case 'Set type & weight':
        text = 'Set type & weight';
        break;
      default:
        text = 'Align this project to goals';
        break;
    }
    return getObjectiveLocale(`Step ${stepNumber} - ${text}`);
  };

  const getNotInvolvedUsersInSquad = () => {
    const squadMembersIds = objectiveValue?.team?.involvements?.map(
      (involvement) => {
        return involvement?.user?.id;
      }
    );

    return objectiveValue?.involvements?.filter((involvement) => {
      return !squadMembersIds?.includes(involvement?.user?.id);
    });
  };

  const isModalConfirmSquadShow = () => {
    const users = getNotInvolvedUsersInSquad();

    return users?.length > 0;
  };

  const checkNextStep = (isSkipCheck = false) => {
    if (!isSkipCheck) {
      if (stepNumber === 1) {
        if (isEmpty(objectiveValue?.name)) {
          showToastError('Project name cannot be empty');
          return;
        }
      } else if (stepNumber === 2) {
        if (currentTabMember === 'Choose Team') {
          if (isEmpty(objectiveValue?.team)) {
            showToastError('You need to select a team');
            return;
          }

          if (isModalConfirmSquadShow()) {
            setShowModalConfirmSquad(true);
            return;
          }
        } else if (currentTabMember === 'Add Member') {
          let leader = objectiveValue?.involvements?.filter(
            (member) =>
              member?.role === 'assignee' && member?.extendedRole === 'leader'
          );
          let reviewer = objectiveValue?.involvements?.filter(
            (member) => member?.role === 'assigner'
          );
          if (leader.length !== 1 || reviewer.length !== 1) {
            showToastError('Must have 1 leader and 1 reviewer');
            return;
          }
        }
      }
    }

    setCurrentStep(listModalSteps[stepNumber]);
  };

  const closeWithConfirmationBox = () => {
    if (isEqual(objectiveValue, initialObjectiveValue)) {
      eventOnClickClose();
    } else {
      setShowConfirmationBox(true);
    }
  };

  useEffect(() => {
    const getInitialDefaultMembers = async () => {
      const projectMember = [
        {
          user: user,
          userId: user.id,
          role: 'assignee',
          extendedRole: 'leader',
          visible: true
        }
      ];

      const { data } = await getManager(user?.id);

      if (data?.[0]?.user) {
        projectMember.push({
          userId: data?.[0]?.user?.id,
          user: data?.[0]?.user,
          role: 'assigner',
          extendedRole: null,
          visible: true
        });
      }

      const teamMember = objectiveValue?.team?.involvements?.map(
        (involvement) => {
          return {
            userId: involvement?.user?.id,
            user: involvement?.user,
            role: involvement?.role,
            extendedRole: involvement?.extendedRole || null,
            visible: true
          };
        }
      );

      setObjectiveValue((draft) => {
        draft.involvements = isMyProjectPage ? projectMember : teamMember;
      });

      setInitialDefaultMembers({
        projectMember,
        teamMember
      });

      // ** To retain selected member in project and team
      setTempMembers({ projectMember, teamMember });
    };

    getInitialDefaultMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listMetrics?.length > 0 && stepNumber == 3) {
      const defaultMetric =
        listMetrics?.find(({ isDefaultForGoal }) => isDefaultForGoal) ||
        listMetrics?.[0];

      setObjectiveValue((draft) => {
        if (!draft.measurement) {
          draft.measurement = {};
          (draft.measurement.startingValue = 0),
            (draft.measurement.targetValue = 100),
            (draft.measurement.rollUp =
              extractDefaultRollup(defaultMetric?.defaultRollUp) ||
              defaultRollupForParentObjective),
            (draft.measurement.unitId = defaultMetric?.id);
        }
      });
    }
  }, [
    listMetrics,
    defaultRollupForParentObjective,
    setObjectiveValue,
    stepNumber
  ]);

  useEffect(() => {
    if (
      objectiveCategories?.length > 0 &&
      objectiveWeightType === 'type' &&
      useDefaultObjectiveCategory &&
      stepNumber == 4
    ) {
      const defaultObjectiveCategory =
        objectiveCategories?.find(({ isDefault }) => isDefault === true) ||
        objectiveCategories?.[0];

      setObjectiveValue((draft) => {
        if (!draft.objectiveCategoryId) {
          draft.objectiveCategoryId = defaultObjectiveCategory?.id;
        }

        if (!draft.weight) {
          draft.weight = defaultObjectiveCategory?.weight;
        }
      });
    }
  }, [
    objectiveCategories,
    objectiveWeightType,
    useDefaultObjectiveCategory,
    setObjectiveValue,
    stepNumber
  ]);

  useEffect(() => {
    setWithAnimation(isEqual(objectiveValue, initialObjectiveValue));
  }, [objectiveValue, initialObjectiveValue]);

  const handleUpdateTeamInvolvements = async () => {
    // ** Mapping current team involvements
    const currentTeamInvolvements = objectiveValue?.team?.involvements?.map(
      (involvement) => {
        return {
          userId: involvement?.user?.id,
          role: involvement?.role,
          ...(involvement?.extendedRole && {
            extendedRole: involvement?.extendedRole
          })
        };
      }
    );

    // Mapping additinal team involvements
    const additionalTeamInvolvements = newSquadMembersIds?.map((id) => {
      return {
        userId: id,
        role: 'assignee'
      };
    });

    const body = {
      // ** Merge current and addtional involvements
      involvements: [...currentTeamInvolvements, ...additionalTeamInvolvements]
    };

    // ** Update team involvements
    await updateTeam(objectiveValue?.team?.id, body);
  };

  const handleCreateProject = async () => {
    let body = {
      ...objectiveValue,
      isProject: true,
      type: 'goal',
      involvements: objectiveValue?.involvements?.map((member) => ({
        userId: member.user.id,
        placementId: member.user.placementId,
        role: member.role,
        extendedRole: member.extendedRole
      })),
      startDate: dayjs(objectiveValue.startDate).toISOString(),
      dueDate: dayjs(objectiveValue.dueDate).toISOString(),
      grouped: true
    };

    body?.parent && delete body?.parent;

    // ** Delete team and teamId attribute
    // ** If users choose add member when creating project from My Project Page
    if (
      isMyProjectPage &&
      currentTabMember === 'Add Member' &&
      has(body, 'team') &&
      has(body, 'teamId')
    ) {
      delete body.team;
      delete body.teamId;
    }

    const { isSuccess } = await createSingleObjective(body);

    return isSuccess;
  };

  const handleSubmitModal = async () => {
    setIsSubmitting(true);

    const isSuccess = await handleCreateProject();

    if (isSuccess) {
      if (currentTabMember === 'Choose Team') {
        const teamPermissions = objectiveValue?.team?.permissions;

        if (teamPermissions?.includes('change_member')) {
          if (newSquadMembersIds?.length > 0) {
            await handleUpdateTeamInvolvements();
          }
        }

        history.push(`/teams/squad/${objectiveValue?.teamId}?tab=projects`);
      }

      eventOnClickClose();
      refetchObjectives('myprojects');
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (currentTabMember === 'Add Member') {
      setObjectiveValue((draft) => {
        draft.involvements = tempMembers.projectMember;
      });
    } else {
      setObjectiveValue((draft) => {
        draft.involvements = tempMembers.teamMember;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTabMember]);

  useEffect(() => {
    setTempMembers({
      ...tempMembers,
      [currentTabMember === 'Add Member' ? 'projectMember' : 'teamMember']:
        objectiveValue?.involvements
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveValue?.involvements]);

  return (
    <Modal
      title={getModalTitle()}
      isUseOptionalHeader={currentStep === 'Add Alignment'}
      withSteps={{
        currentStepNumber: stepNumber,
        lastStepNumber: listModalSteps.length
      }}
      withPrimaryBtn={{
        title: isLastStep
          ? getObjectiveLocale('Create')
          : getObjectiveLocale('Next'),
        onClick: () => (isLastStep ? handleSubmitModal() : checkNextStep()),
        dataCy: 'primary-button-create-project',
        isLoading: isSubmitting
      }}
      withSecondaryBtn={{
        title: isFirstStep
          ? getObjectiveLocale('Cancel')
          : getObjectiveLocale('Back'),
        onClick: () =>
          isFirstStep
            ? closeWithConfirmationBox()
            : setCurrentStep(listModalSteps[stepNumber - 2])
      }}
      withCloseIcon
      eventOnClickClose={() => closeWithConfirmationBox()}
      dataCyModal="modal-create-project"
      withCloseAnimation={withAnimation}
    >
      {currentStep === 'Setup Project Name' && (
        <SetupProjectName
          objectiveValue={objectiveValue}
          setObjectiveValue={setObjectiveValue}
        />
      )}
      {currentStep === 'Choose Team or Add Member' && (
        <UserSuggestionProvider>
          <ChooseTeamOrMember
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            currentTabMember={currentTabMember}
            setCurrentTabMember={setCurrentTabMember}
            initialDefaultMembers={initialDefaultMembers}
            isMyProjectPage={isMyProjectPage}
            showModalConfirmSquad={showModalConfirmSquad}
            setShowModalConfirmSquad={setShowModalConfirmSquad}
            checkNextStep={checkNextStep}
            getNotInvolvedUsersInSquad={getNotInvolvedUsersInSquad}
            newSquadMembersIds={newSquadMembersIds}
            setNewSquadMembersIds={setNewSquadMembersIds}
          />
        </UserSuggestionProvider>
      )}
      {currentStep === 'Add Metrics' && (
        <AddMetrics
          objectiveValue={objectiveValue}
          setObjectiveValue={setObjectiveValue}
          listMetrics={listMetrics}
        />
      )}
      {currentStep === 'Set type & weight' && (
        <SetObjectiveTypeWeight
          objectiveValue={objectiveValue}
          setObjectiveValue={setObjectiveValue}
          objectiveCategories={objectiveCategories}
        />
      )}
      {currentStep === 'Add Alignment' && (
        <AddAlignment
          objectiveValue={objectiveValue}
          setObjectiveValue={setObjectiveValue}
          teamId={team?.id}
        />
      )}
    </Modal>
  );
};

const ModalCreateProjectComponent = (props) => {
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);

  return (
    <>
      <MetricsProvider type="goal" showLoading={false}>
        <ModalCreateProject
          {...props}
          setShowConfirmationBox={setShowConfirmationBox}
          showConfirmationBox={showConfirmationBox}
        />
      </MetricsProvider>
      {showConfirmationBox && (
        <Modal
          title={getObjectiveLocale('Discard Draft?')}
          headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
          withPrimaryBtn={{
            title: getObjectiveLocale('Yes, Discard'),
            onClick: () => props?.eventOnClickClose(),
            dataCy: 'cancel-confirm-create-project',
            danger: true
          }}
          withSecondaryBtn={{
            title: getObjectiveLocale('No'),
            onClick: () => setShowConfirmationBox(false)
          }}
          dataCyModal="confirmation-create-project"
          className="w-[400px]"
          eventOnClickClose={() => setShowConfirmationBox(false)}
        >
          <p className="text-n-800">
            {getObjectiveLocale('Are you sure you want to discard the draft?')}
          </p>
          <p className="text-n-800">
            {getObjectiveLocale('Changes you made may not be saved.')}
          </p>
        </Modal>
      )}
    </>
  );
};

export default ModalCreateProjectComponent;

import React from 'react';

import dayjs from 'dayjs';

import { getObjectiveLocale } from 'utils/HelperUtils';

import DualCalendar from 'components/shared/Calendar/DualCalendar';
import SVGIcon from 'components/shared/SVGIcon';

const DateSection = ({ objectiveDetail, updateObjective }) => {
  const handleChangeCalendar = (value, calendarPosition) => {
    let body = {};

    if (value.length === 2) {
      body.startDate = dayjs(value[0]).startOf('day').toISOString();
      body.dueDate = dayjs(value[1]).endOf('day').toISOString();
      // If the user has clicked the calendar at startDate or dueDate calendar
    } else {
      if (calendarPosition === 'startDate') {
        body.startDate = dayjs(value).startOf('day').toISOString();
      } else if (calendarPosition === 'dueDate') {
        body.dueDate = dayjs(value).endOf('day').toISOString(); // endOf("day") = to be more precise
      }
    }

    if (dayjs(body?.dueDate)?.diff(dayjs(body?.startDate), 'days') < 8) {
      if (
        confirm(
          getObjectiveLocale(
            'You’ve changed period objective to less than a week. Milestone will be disabled. Proceed?'
          )
        )
      ) {
        updateObjective(body);
      }
    } else {
      updateObjective(body);
    }
  };

  return (
    <div
      className="px-[24px] relative hover:bg-base-3008"
      data-cy="content-calendar"
    >
      <div className="h-[48px] flex items-center">
        <SVGIcon
          iconName="icon-today"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-16"
        />
        <DualCalendar
          defaultValue={[
            dayjs(objectiveDetail.startDate),
            dayjs(objectiveDetail.dueDate)
          ]}
          handleChangeStartDate={(value) =>
            handleChangeCalendar(value, 'startDate')
          }
          handleChangeDueDate={(value) =>
            handleChangeCalendar(value, 'dueDate')
          }
        />
      </div>
    </div>
  );
};

export default DateSection;

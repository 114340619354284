import React from 'react';

import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { formatTimezone } from 'utils/DateUtils';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import Objectives from 'components/objectives/Objectives';
import ObjectiveSkeleton from 'components/shared/ObjectiveSkeleton';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

import ErrorBoundary from './ErrorBoundary';

function NeedResponse({ route: { title } }) {
  const { match } = useUrl();
  const { user } = useUser();

  return (
    <>
      <HeaderPage titlePage={title} />
      <div
        className=" grid gap-[24px] mb-[64px] mt-[24px]"
        data-cy="need-response-list"
      >
        <ErrorBoundary>
          <React.Suspense fallback={<ObjectiveSkeleton />}>
            <AdvancedFilterObjectives
              filterOptions={[
                'search',
                'group',
                'sort',
                'dateFilter',
                'menuFilter'
              ]}
              defaultFilter={{
                periodBegin: formatTimezone(
                  `${new Date().getFullYear()}-01-01`,
                  'start'
                ),
                periodEndBefore: formatTimezone(
                  `${new Date().getFullYear()}-12-31`,
                  'end'
                ),
                owner: {
                  assignee: []
                },
                reviewer: [{ user, userId: user.id }],
                phase: ['running', 'completed']
              }}
              group={'no-group'}
              page="needResponse"
              filterId="needResponseFilter"
            >
              <Objectives
                cardModel="goal-list"
                page="response"
                extraFilter={{
                  parentNotAssignedTo: user.id
                }}
              />
            </AdvancedFilterObjectives>
          </React.Suspense>
        </ErrorBoundary>

        <React.Suspense fallback={<div></div>}>
          {match && <SidebarMyGoals />}
        </React.Suspense>
      </div>
    </>
  );
}

export default NeedResponse;

import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { getListMeasurement } from 'client/ObjectivesClient';
import useOrganization from 'hooks/useOrganization';
import { capitalize, getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import BannerBox from 'components/shared/BannerBox';
import InputField from 'components/shared/InputField';
import RadioButton from 'components/shared/RadioButton';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

const MeasurementUnit = ({ objectiveType = '' }) => {
  const {
    configData,
    changeConfigData,
    setMeasurementUnitData,
    setOriginMeasurementUnitData,
    measurementUnitData
  } = useOrganization((configData) => ({
    setMeasurementUnitData: configData.setMeasurementUnitData,
    setOriginMeasurementUnitData: configData.setOriginMeasurementUnitData,
    measurementUnitData: configData.measurementUnitData,
    changeConfigData: configData.changeConfigData,
    configData: configData.configData
  }));
  const [showError, setShowError] = useState(false);
  const [openModal, setOpenModal] = useState({ type: null, index: null });
  const [dataModal, setDataModal] = useState({ description: '', unit: '' });

  const typeRollUp =
    objectiveType == 'goal'
      ? 'defaultRollupForParentObjective'
      : 'defaultRollupForParentTask';

  const getData = async () => {
    const { data } = await getListMeasurement();
    if (data) {
      setMeasurementUnitData(data);
      setOriginMeasurementUnitData(data);
    }
  };

  const resetModal = () => {
    setOpenModal({ type: null, index: null });
    setDataModal({ description: '', unit: '' });
  };

  const addNewMeasurement = async () => {
    const addedData = {
      ...dataModal,
      action: 'add'
    };
    setMeasurementUnitData([...measurementUnitData, addedData]);
    resetModal();
  };

  const updateDefaultMeasurement = async (defaultUnit) => {
    const keyData =
      objectiveType !== 'goal'
        ? 'rollupOptionsForTask'
        : 'rollupOptionsForObjective';
    const keyDefault =
      objectiveType !== 'goal'
        ? 'defaultRollupForParentTask'
        : 'defaultRollupForParentObjective';

    let currentUnit = cloneDeep(measurementUnitData);
    currentUnit?.map((unit) => {
      if (objectiveType == 'goal') {
        unit['isDefaultForGoal'] = false;
      } else {
        unit['isDefaultForTask'] = false;
      }
    });
    let changedUnit =
      defaultUnit?.unit &&
      currentUnit.find((unit) => unit.unit === defaultUnit.unit);
    if (objectiveType == 'goal') {
      changedUnit['isDefaultForGoal'] = true;
    } else {
      changedUnit['isDefaultForTask'] = true;
    }
    changedUnit.action = 'edit';

    // if the selected unit is percentages, should remove Auto Average, Auto Weighted Average and Auto Completed Sum from roll up options
    let options = ['disabled', 'auto'];
    if (defaultUnit.unit == '%') {
      options.push('average', 'weighted_average', 'average_by_phase');
    }
    const defaultOption =
      defaultUnit.unit != '%'
        ? objectiveType == 'goal'
          ? 'disabled'
          : 'auto'
        : 'average';

    changeConfigData({
      ...configData,
      [keyData]: options,
      [keyDefault]: defaultOption
    });
    setMeasurementUnitData(currentUnit);
  };

  const editCurrentMeasurement = async (edited, type = 'edit') => {
    let currentUnit = cloneDeep(measurementUnitData);
    const editedUnit = edited?.id
      ? currentUnit.find((unit) => unit.id === edited.id)
      : currentUnit[openModal.index];
    editedUnit.description = dataModal.description;
    editedUnit.unit = dataModal.unit;
    editedUnit.action =
      type === 'edit' ? (edited?.id ? 'edit' : 'add') : 'delete';
    setMeasurementUnitData(currentUnit);
    resetModal();
  };

  const deleteCurrentMeasurement = async () => {
    let currentUnit = cloneDeep(measurementUnitData);
    const deletedUnit = currentUnit.find((unit) =>
      isEmpty(dataModal?.id)
        ? unit.description === dataModal?.description
        : unit.id === dataModal.id
    );
    deletedUnit.action = 'delete';
    setMeasurementUnitData(deletedUnit);
    resetModal();
  };

  const checkError = () => {
    const alreadyExist = measurementUnitData.filter(
      (unit, idx) =>
        idx !== openModal.index &&
        (unit.unit === dataModal.unit ||
          unit.description === dataModal.description)
    );
    setShowError(alreadyExist.length > 0);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    checkError();
  }, [dataModal]);

  const modalProps = {
    eventOnClickClose: () => resetModal(),
    withSecondaryBtn: {
      title: getObjectiveLocale('Cancel'),
      dataCy: 'cancel-metric',
      onClick: () => resetModal()
    }
  };

  return (
    <div className="border border-solid border-n-400 rounded-[4px] mb-[24px]">
      <div className="flex justify-between items-center py-[16px] px-[16px] h-[72px]">
        <div>
          <p className="typography-h400 text-n-900 mb-[4px]">
            {getObjectiveLocale('Metric Dictionary')}
          </p>
          <p className="typography-paragraph text-n-800">
            {getObjectiveLocale(
              `Metric options that can be used when add/edit ${
                objectiveType == 'goal' ? 'objective' : 'task'
              } metric`
            )}
          </p>
        </div>
        <Button.Secondary
          onClick={() => setOpenModal({ type: 'add', index: null })}
          customClass=""
        >
          {getObjectiveLocale('Add Metric')}
        </Button.Secondary>
      </div>
      <div className="flex justify-between items-center py-[12px] px-[16px]">
        <p className="typography-h300 uppercase text-n-800">
          {getObjectiveLocale('Metrics Name')}
        </p>
        <div className="flex">
          <p className="typography-h300 uppercase text-n-800 w-[130px]">
            {getObjectiveLocale(`Default For ${objectiveType}`)}
          </p>
          {/* Divider No Header Name */}
          <p className="w-[110px]"></p>
        </div>
      </div>
      <div className="border-solid border-t border-0 border-n-500" />
      {measurementUnitData.length > 0 &&
        measurementUnitData.map((unit, idx) => {
          let disabledUnit = false;
          const percentageOnlyRollup = [
            'average',
            'weighted_average',
            'average_by_phase'
          ].includes(configData?.[typeRollUp]);
          if (unit?.unit !== '%' && percentageOnlyRollup) {
            disabledUnit = true;
          }
          return (
            <>
              {unit.action !== 'delete' && (
                <div className="px-[16px] flex justify-between" key={idx}>
                  <div className="h-[56px] flex items-center typography-paragraph">
                    {unit?.description} ({unit?.unit}){' '}
                    {unit?.unit == '%' ? '*' : ''}
                  </div>
                  <div className="flex">
                    <RadioButton
                      name={`default-measurement-${objectiveType}`}
                      checked={
                        objectiveType == 'goal'
                          ? unit?.isDefaultForGoal
                          : unit?.isDefaultForTask
                      }
                      onClick={() => updateDefaultMeasurement(unit)}
                      addClass="mx-[16px] w-[100px]"
                      disabled={disabledUnit}
                    />
                    <SVGIcon
                      size="24"
                      customClass="px-[16px] py-[16px] w-[56px] h-[56px]"
                      iconName="icon-pencil"
                      fillColor="var(--n-600)"
                      onClick={() => {
                        setOpenModal({ type: 'edit', index: idx }),
                          setDataModal(unit);
                      }}
                    />
                    <SVGIcon
                      size="24"
                      customClass="px-[16px] py-[16px] w-[56px] h-[56px]"
                      iconName="icon-delete"
                      fillColor="var(--n-600)"
                      onClick={() => {
                        setOpenModal({ type: 'delete', index: idx }),
                          setDataModal(unit);
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          );
        })}
      {!isEmpty(openModal.type) && openModal.type !== 'delete' && (
        <Modal
          title={getObjectiveLocale(
            openModal.type === 'edit' ? 'Edit Metric' : 'Add Metric'
          )}
          {...modalProps}
          className="w-[400px] max-h-[272px]"
          withPrimaryBtn={{
            title: getObjectiveLocale(capitalize(openModal.type)),
            dataCy: 'submit-metric',
            onClick: () =>
              openModal.type === 'edit'
                ? editCurrentMeasurement(dataModal)
                : addNewMeasurement(),
            disabled:
              dataModal.description === '' || dataModal.unit === '' || showError
          }}
        >
          {showError && (
            <BannerBox
              type="warning"
              customClass="mb-[24px] px-[16px] items-flex-start"
              iconClass="ml-[0px] mr-[16px]"
            >
              {getObjectiveLocale(
                'Name or unit has been used by another metric, please use different name or metric unit'
              )}
            </BannerBox>
          )}
          <div
            className={`${
              showError ? 'h-[68px]' : ' h-[108px]'
            } flex items-center`}
          >
            <InputField
              label="Name"
              placeholder="ex: Percentage"
              handleChange={(e) =>
                setDataModal({ ...dataModal, description: e.target.value })
              }
              value={dataModal?.description}
              className="w-[222px]"
              dataCy="name-measurement"
            />
            <InputField
              label="Unit"
              placeholder="ex: %"
              handleChange={(e) =>
                setDataModal({ ...dataModal, unit: e.target.value })
              }
              value={dataModal?.unit}
              className="w-[120px] ml-[8px]"
              dataCy="unit-measurement"
            />
          </div>
        </Modal>
      )}
      {!isEmpty(openModal.type) && openModal.type === 'delete' && (
        <Modal
          title={getObjectiveLocale('Delete Metric')}
          {...modalProps}
          className="w-[400px] max-h-[212px]"
          withPrimaryBtn={{
            danger: true,
            title: getObjectiveLocale(capitalize(openModal.type)),
            dataCy: 'delete-metric',
            onClick: () => editCurrentMeasurement(dataModal, 'delete')
          }}
        >
          <div className="flex items-center">
            <div>
              <span className="typography-paragraph">
                {getObjectiveLocale(
                  'This action will affect all objectives with '
                )}
              </span>
              <span className="typography-h400">
                {dataModal?.description} ({dataModal?.unit}){' '}
              </span>
              <span className="typography-paragraph">
                {getObjectiveLocale(
                  'metric. Are you sure you want to delete this metric?'
                )}
              </span>
            </div>
          </div>
        </Modal>
      )}
      <p className="typography-h100 italic text-n-800 my-[16px] pl-[16px]">
        {getObjectiveLocale(
          '*Auto Average, Auto Weighted Average, and Auto Completed Sum Roll Up options only apply for Percentage metric'
        )}
      </p>
    </div>
  );
};

export default MeasurementUnit;

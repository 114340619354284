import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import qs from 'qs';

import { useUser } from 'context/UserContext';
import {
  CreateCycleProvider,
  useCreateCycle
} from 'context/admin/CreateCycleContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Verify from 'components/admin/Cycles/Verify';
import HeaderPage from 'components/admin/HeaderPage';
import SidebarLeftMenuNavigator from 'components/shared/SidebarLeftMenuNavigator';
import TeamResult from 'pages/TeamResult';

import CurrentCyclePhase from './CurrentCyclePhase';
import ModalChangeReviewer from './ModalChangeReviewer';
import ModalWarningEdit from './ModalWarningEdit';

const CycleDetail = ({ history, route: { isReadOnly } }) => {
  const { config, organization } = useUser();
  const menu = qs.parse(location.search, { ignoreQueryPrefix: true }).detailBy;
  const { id: cycleId } = useParams();
  const { cycleData, getCycleData } = useCreateCycle();
  const [viewCycleDetailBy, setViewCycleDetailBy] = useState(
    menu || 'currentCyclePhase'
  );
  const [isModalRemindOpen, setIsModalRemindOpen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showModalChangeReviewer, setShowModalChangeReviewer] = useState(false);

  const headerPropsCurrentCyclePhase = {
    titlePage: cycleData.name,
    backToUrl: '/appraisals/cycles',
    isHeaderComposer: true,
    secondaryAction: cycleData.state === 'draft' &&
      !isReadOnly && {
        title: 'Edit Cycle & Phase',
        icon: 'icon-create',
        onClick: () => {
          localStorage.setItem('prevUrlRedirect', location.pathname);
          history.push(`/appraisals/cycles/${cycleId}/edit`);
        },
        dataCy: 'edit-cycle-phase'
      }
  };

  const tempAllowRemindAllOrg = [
    'oy-id',
    'linknet',
    'shipper',
    'sirclo',
    'andalin'
  ];

  const phaseSecondaryAction = () => {
    switch (viewCycleDetailBy) {
      case 'configuration':
        if (cycleData.state !== 'done' && !isReadOnly) {
          return {
            title: getObjectiveLocale('Edit Cycle'),
            onClick: () => setShowWarningModal(true),
            icon: 'icon-settings'
          };
        }
        return;
      case 'progress':
        if (!isReadOnly && config?.permissions?.reviewPerformanceCycleSee) {
          return {
            title: getObjectiveLocale('Change Assignment Status'),
            onClick: () => {
              history.push(`/reviews/cycles/change-assignment/${cycleId}`);
            }
          };
        }
        return;
      default:
        null;
    }
  };

  const hasActiveManagerOrIndirectPhase =
    cycleData?.managerReview?.state == 'in_progress' ||
    cycleData.indirectManager?.state == 'in_progress';

  const headerPropsPhaseProgress = {
    primaryAction:
      viewCycleDetailBy == 'progress' &&
      config.formalReviewReminder &&
      !isReadOnly &&
      tempAllowRemindAllOrg.includes(organization.identifier)
        ? {
            title: 'Remind Incompletes',
            onClick: () => setIsModalRemindOpen(true)
          }
        : null,
    secondaryAction: phaseSecondaryAction(),
    tertiaryAction: viewCycleDetailBy == 'progress' &&
      organization.identifier == 'bca' &&
      config.permissions.reviewPerformanceAssignmentsManage && {
        title: getObjectiveLocale('Change Reviewer'),
        onClick: () => {
          setShowModalChangeReviewer(true);
        },
        textTooltip: getObjectiveLocale(
          'Available if the Manager Review or Indirect Manager Review phase is in progress.'
        ),
        showTooltip: !hasActiveManagerOrIndirectPhase,
        disabled: !hasActiveManagerOrIndirectPhase
      },
    titlePage: cycleData.name,
    backToUrl: '/appraisals/cycles',
    isHeaderComposer: true
  };

  const headerProps =
    viewCycleDetailBy === 'currentCyclePhase'
      ? headerPropsCurrentCyclePhase
      : headerPropsPhaseProgress;

  const listMenu = [
    {
      id: 'currentCyclePhase',
      title: 'Current Cycle & Phase',
      onClick: () => {
        changeMenu('currentCyclePhase');
      },
      isVisible: true
    },
    {
      id: 'configuration',
      title: 'Cycle Configuration',
      onClick: () => {
        changeMenu('configuration');
      },
      isVisible: true
    },
    {
      id: 'progress',
      title: 'Phase Progress',
      onClick: () => {
        changeMenu('progress');
      },
      isVisible: config?.permissions?.reviewPerformanceCycleProgressSee
    },
    {
      id: 'result',
      title: 'Result',
      onClick: () => {
        changeMenu('result');
      },
      isVisible: config?.permissions?.reviewPerformanceCycleResultSee
    }
  ].filter((opt) => opt.isVisible);

  const changeMenu = (menu) => {
    window.history.replaceState(null, null, `?detailBy=${menu}`);
    setViewCycleDetailBy(menu);
  };

  const _handleGetCycleData = async () => {
    await getCycleData(cycleId);
  };

  const listMenuFilter =
    cycleData.state != ''
      ? cycleData?.state == 'draft'
        ? listMenu?.filter(
            (list) => list.id != 'progress' && list.id != 'result'
          )
        : listMenu
      : [];

  useEffect(() => {
    _handleGetCycleData();
  }, []);

  const backProps = history.location.state
    ? { closeAction: () => history.goBack() }
    : {};

  const mergedHeaderProps = { ...headerProps, ...backProps };

  return (
    <div className="cycles-details">
      {showWarningModal && (
        <ModalWarningEdit
          cycleId={cycleData?.id}
          stateData={cycleData?.state}
          totalReviewer={cycleData?.includes?.length}
          source="details"
          onCloseModal={() => setShowWarningModal(false)}
        />
      )}
      <HeaderPage {...mergedHeaderProps} />
      <div className="flex">
        <div className="relative">
          <SidebarLeftMenuNavigator
            listMenu={listMenuFilter}
            title={'View Cycle Detail By'}
            activeMenu={viewCycleDetailBy}
            customClass="w-[256px]"
          />
        </div>
        {viewCycleDetailBy === 'configuration' && (
          <Verify cycleData={cycleData} />
        )}
        {viewCycleDetailBy === 'currentCyclePhase' && (
          <CurrentCyclePhase cycleData={cycleData} />
        )}
        {(viewCycleDetailBy === 'progress' ||
          viewCycleDetailBy === 'result') && (
          <TeamResult
            key={viewCycleDetailBy}
            withHeaderAndSidebar={false}
            isModalRemindOpenProps={isModalRemindOpen}
            setIsModalRemindOpenProps={setIsModalRemindOpen}
            initialCurrentMenu={viewCycleDetailBy}
            isAdmin
            isReadOnly={isReadOnly}
            lockAnswerCycle={cycleData?.lockSubmittedAssignment}
          />
        )}
      </div>
      {showModalChangeReviewer && (
        <ModalChangeReviewer
          cycleId={cycleId}
          setShowModalChangeReviewer={setShowModalChangeReviewer}
        />
      )}
    </div>
  );
};

const cycleDetailWrapper = (props) => {
  return (
    <CreateCycleProvider>
      <CycleDetail {...props} />
    </CreateCycleProvider>
  );
};

export default cycleDetailWrapper;

import React, { createRef, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { Tree } from '@coutcout/tree-datastructure';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';

import { editObjective, getListObjectives } from 'client/ObjectivesClient';
import { useProjectDetail } from 'context/ProjectDetailContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import useTasks from 'hooks/useTasks';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { restructureFilter } from 'utils/ObjectivesHelper';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import BoardCard from 'components/tasks/board/BoardCard';
import BoardCardSkeleton from 'components/tasks/board/BoardCardSkeleton';
import BoardDropzone from 'components/tasks/board/BoardDropzone';
import useIsFetchingTasks from 'src/hooks/useIsFetchingTasks';

const AddButtonContainer = React.forwardRef(
  ({ isFetchingTasks, handleAddTask }, ref) => {
    return (
      <div ref={ref}>
        <Button.Tertiary
          customClass="w-full"
          onClick={() => !isFetchingTasks && handleAddTask()}
          datacy="task-board-add-task-button"
        >
          <SVGIcon iconName="icon-add" fillColor="var(--base-600)" size={24} />
          <p className="text-base-600">{getObjectiveLocale('Add Task')}</p>
        </Button.Tertiary>
      </div>
    );
  }
);

AddButtonContainer.displayName = 'AddButtonContainer';

function BoardCards({
  filter,
  groupData = { id: 0, name: '', index: 0 },
  sectionData = { id: 0, name: '', index: 0 },
  draggedId,
  setDraggedId,
  draggable,
  setDraggable,
  handleAddTask,
  shadowStyle,
  isProject,
  projectId,
  customClass = '',
  listMetrics = []
}) {
  const isFetchingTasks = useIsFetchingTasks({
    isProject,
    groupName: groupData?.name,
    sectionName: sectionData?.name
  });

  const [showSkeleton, setShowSkeleton] = useState(false);
  const [queryEnabled, setQueryEnabled] = useState(false);

  const ref = createRef();
  const cardsRef = useRef([]);

  const { user } = useUser();
  const {
    tasks,
    updateTasksOneLevel,
    updateTasksTwoLevels,
    setEmptyTasksOneLevel,
    setEmptyTasksTwoLevels,
    dropTaskOneLevel,
    dropTaskTwoLevels
  } = useTasks();
  const { refetchObjective, invalidateQueries } = useRefetchQuery();
  const { permissions } = useProjectDetail();

  const allowAddNewTask = isProject
    ? permissions?.includes('add_subtask')
    : true;

  let newFilter = restructureFilter(filter);

  const getQueryParams = (olderThan) => {
    let priorityIds = filter?.priority?.map(({ id }) => id);
    let phaseIds = filter?.phase?.map(({ id }) => id);

    // page: project details (isProject), my tasks (!isProject)
    const appendParamsByProject = (isProject) => {
      if (isProject) {
        return {
          parentId: projectId,
          sectionId: sectionData?.id,
          ...(filter?.assignTo === 'me' && { assigneeId: [user?.id] })
        };
      } else {
        return {
          taskType: filter?.showTask,
          assigneeId: [user?.id]
        };
      }
    };

    // group by: phase, priority, section (only in my tasks > show project tasks)
    const appendParamsByGroup = (group) => {
      if (group === 'phase') {
        if (filter?.showTask === 'project') {
          return { phaseName: groupData?.name.toUpperCase() };
        } else {
          return { phaseId: groupData?.id };
        }
      } else if (group === 'priority') {
        return { priorityId: groupData?.id };
      } else if (group === 'section' && filter?.showTask === 'project') {
        return { sectionName: groupData?.name.toUpperCase() };
      }
    };

    // filter: phase / priority
    const appendParamsByFilterPhasePriority = (group) => {
      if (group !== 'phase' && phaseIds?.length > 0) {
        return { phaseId: phaseIds };
      }
      if (group !== 'priority' && priorityIds?.length > 0) {
        return { priorityId: priorityIds };
      }
    };

    let allParams = {
      type: ['task'],
      reviewsVisibility: 1,
      limit: 10,
      olderThan,
      parentNotAssignedTo: user.id,
      ...appendParamsByProject(isProject),
      ...appendParamsByGroup(filter?.group),
      ...appendParamsByFilterPhasePriority(filter?.group),
      ...newFilter
    };

    allParams.state && delete allParams.state;

    if (isProject) {
      delete allParams.periodBegin;
      delete allParams.periodEndBefore;
    }

    return allParams;
  };

  const fetchObjectives = async (olderThan) => {
    const queryParams = getQueryParams(olderThan);
    return getListObjectives(queryParams);
  };

  const queryParamsKey = getQueryParams();
  delete queryParamsKey.phaseId;
  delete queryParamsKey.priorityId;
  delete queryParamsKey.sectionId;
  delete queryParamsKey.phaseName;
  delete queryParamsKey.sectionName;

  const queryKey = isProject
    ? [
        'objectives',
        'mytasks',
        groupData?.name.toLowerCase(),
        sectionData?.name,
        queryParamsKey
      ]
    : ['objectives', 'mytasks', groupData?.name.toLowerCase(), queryParamsKey];

  const {
    data,
    status,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isStale
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => fetchObjectives(pageParam),
    {
      staleTime: 5 * 60 * 1000,
      enabled: queryEnabled,
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination?.next?.olderThan || undefined;
      }
    }
  );

  useEffect(() => {
    if (data && status === 'success' && !isFetching && !isStale) {
      const hash = {};
      const tasksList = isProject
        ? tasks?.[groupData?.name]?.[sectionData?.name]
        : tasks?.[groupData?.name];

      tasksList?.length > 0 &&
        tasksList?.forEach((task) => {
          hash[task.getRoot().value.id] = task;
        });

      const newTasks = flatten(
        data?.pages?.map((page) => {
          return page?.data?.map((d) => {
            return hash[d.id] ? hash[d.id] : new Tree(d);
          });
        })
      );

      isProject
        ? updateTasksTwoLevels(groupData?.name, sectionData?.name, newTasks)
        : updateTasksOneLevel(groupData?.name, newTasks);
    }
    //eslint-disable-next-line
  }, [data, status, isFetching, isStale]);

  const callbackFn = (entries) => {
    const [entry] = entries;
    entry.isIntersecting &&
      hasNextPage &&
      !isFetchingNextPage &&
      fetchNextPage();
  };

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  };

  const invalidateDragAndDropData = (srcData, dstData, isProject) => {
    const srcKey = isProject
      ? [`objectives`, 'mytasks', srcData?.group1, srcData?.group2]
      : [`objectives`, 'mytasks', srcData?.group];

    const dstKey = isProject
      ? [`objectives`, 'mytasks', dstData?.group1, dstData?.group2]
      : [`objectives`, 'mytasks', dstData?.group];

    invalidateQueries(srcKey, {
      refetchActive: false
    });
    invalidateQueries(dstKey, {
      refetchActive: false
    });
  };

  const drop = async (ev, sourceId, dstIndex) => {
    ev.preventDefault();

    const srcData = {
      index: draggedId?.index,
      taskNode: draggedId?.taskNode,
      task: draggedId?.task,
      level: draggedId?.level,
      ['group' + (isProject ? '1' : '')]: draggedId?.groupName,
      ...(isProject && { group2: draggedId?.sectionName })
    };

    const dstData = {
      index: dstIndex,
      ['group' + (isProject ? '1' : '')]: groupData?.name,
      ...(isProject && { group2: sectionData?.name })
    };

    const currentGroupId = parseInt(ev.currentTarget.getAttribute('groupId'));
    const currentSectionId = isProject
      ? parseInt(ev.currentTarget.getAttribute('sectionId'))
      : null;
    const currentGroupName = ev.currentTarget
      .getAttribute('groupName')
      .toUpperCase();

    let body = {
      ...(filter?.showTask === 'project' && filter?.group !== 'priority'
        ? { [filter?.group + 'Name']: currentGroupName }
        : { [filter?.group + 'Id']: currentGroupId }),
      // [filter?.group === 'phase' ? 'phaseId' : 'priorityId']: currentGroupId,
      ...(isProject && { sectionId: currentSectionId })
    };

    const { isSuccess } = await editObjective(sourceId, body);
    if (isSuccess) {
      isProject
        ? dropTaskTwoLevels(srcData, dstData)
        : dropTaskOneLevel(srcData, dstData);
      refetchObjective(sourceId);
      invalidateDragAndDropData(srcData, dstData, isProject);
    }
  };

  const onDragEnter = (e, _setShowSkeleton) => {
    e.stopPropagation();
    _setShowSkeleton(true);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragLeave = (e, _setShowSkeleton) => {
    e.preventDefault();
    _setShowSkeleton(false);
  };

  const onDrop = (e, _setShowSkeleton, dstIndex) => {
    e.preventDefault();
    const sourceId = e.dataTransfer.getData('text/plain');
    const sourceElement = document.getElementById(sourceId);
    sourceElement.style.opacity = 1;
    drop(e, sourceId, dstIndex);
    _setShowSkeleton(false);
    setDraggable(false);
  };

  useEffect(() => {
    return () => {
      isProject
        ? setEmptyTasksTwoLevels(groupData?.name, sectionData?.name)
        : setEmptyTasksOneLevel(groupData?.name);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFn, options);
    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [ref, options]);

  useEffect(() => {
    // FETCH ONLY WHEN TARGET ELEMENT IS APPEAR IN SCROLL VIEW
    const target = ref.current;

    if (target) {
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setQueryEnabled(true);
          }
        });
      }, options);
      observer.observe(target);
      return () => {
        observer.disconnect();
      };
    }
  }, [groupData, filter, isProject, projectId, ref]);

  let dragAndDrop = {
    draggedId: draggedId,
    setDraggedId: setDraggedId,
    draggable: draggable,
    setDraggable: setDraggable,
    onDragEnter: onDragEnter,
    onDragOver: onDragOver,
    onDragLeave: onDragLeave,
    onDrop: onDrop
  };

  return (
    <div
      className={`h-full w-full bg-n-300 overflow-y-auto ${customClass}`}
      id="my-task-section"
    >
      <div className="relative w-full p-[16px]">
        {isFetching && !isFetchingNextPage ? (
          <BoardCardSkeleton />
        ) : (isProject
            ? tasks?.[groupData?.name]?.[sectionData?.name]
            : tasks?.[groupData?.name]
          )?.length > 0 ? (
          (isProject
            ? tasks?.[groupData?.name]?.[sectionData?.name]
            : tasks?.[groupData?.name]
          )?.map((task, index) => (
            <BoardTree
              key={`task-board-${
                task?.getRoot()?.value?.id ? task?.getRoot()?.value?.id : index
              }`}
              listMetrics={listMetrics}
              tree={task}
              groupData={groupData}
              sectionData={sectionData}
              isLastCard={
                index ===
                (isProject
                  ? tasks?.[groupData?.name]?.[sectionData?.name]
                  : tasks?.[groupData?.name]
                )?.length -
                  1
              }
              index={index}
              cardsRef={cardsRef}
              dragAndDrop={dragAndDrop}
              shadowStyle={shadowStyle}
              isProject={isProject}
              filter={filter}
            />
          ))
        ) : (
          <>
            <BoardDropzone
              groupData={groupData}
              sectionData={sectionData}
              index={-1}
              top={0}
              height="100%"
              dragAndDrop={dragAndDrop}
              setShowSkeleton={setShowSkeleton}
              isProject={isProject}
            />
            {showSkeleton ? (
              <BoardDropzone.CardSkeleton
                index={-1}
                draggedId={draggedId}
                shadowStyle={shadowStyle}
                isProject={isProject}
              />
            ) : (
              !isFetching &&
              !isFetchingNextPage &&
              !allowAddNewTask && (
                <p className="border border-solid border-n-300 rounded-[4px] h-[32px] flex justify-center px-[16px] typography-paragraph text-n-600">
                  {getObjectiveLocale('There is no task')}
                </p>
              )
            )}
          </>
        )}
        {isFetchingNextPage && <BoardCardSkeleton />}
        {allowAddNewTask && (
          <AddButtonContainer
            isFetchingTasks={isFetchingTasks}
            handleAddTask={handleAddTask}
            ref={ref}
          />
        )}
      </div>
    </div>
  );
}

const BoardTree = React.memo(
  ({
    tree,
    groupData,
    sectionData,
    isLastCard,
    index,
    cardsRef,
    dragAndDrop,
    shadowStyle,
    isProject,
    filter,
    listMetrics = []
  }) => {
    const root = tree?.getRoot();
    return (
      <BoardCard
        taskNode={root}
        level={0}
        listMetrics={listMetrics}
        groupData={groupData}
        sectionData={sectionData}
        isLastCard={isLastCard}
        index={index}
        cardsRef={cardsRef}
        dragAndDrop={dragAndDrop}
        shadowStyle={shadowStyle}
        isProject={isProject}
        filter={filter}
      />
    );
  },
  areEqual
);

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

BoardTree.displayName = 'BoardTree';

export default BoardCards;

import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

const GoalAlignment = ({ alignment, total }) => {
  const configCircleChart = {
    title: {
      text: `<div style="font-size: 20px; font-weight: 500; margin-top: 8px">${
        alignment == 0 ? '0' : ((alignment / total) * 100).toFixed(0)
      }<span style="color: var(--n-600); font-size: 20px; font-weight: 500;">%</span></div>`,
      align: 'center',
      verticalAlign: 'middle',
      useHTML: true,
      floating: true,
      y: -3
    },
    chart: {
      style: {
        fontFamily: 'Inter'
      },
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      width: '108',
      height: '108'
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'auto',

        dataLabels: {
          enabled: false
        },
        events: {
          legendItemClick: function () {
            return false;
          }
        },
        allowPointSelect: false,
        showInLegend: false,
        size: 100
      }
    },
    series: [
      {
        type: 'pie',
        animation: false,
        colorByPoint: true,
        innerSize: '80%',
        states: {
          hover: {
            enabled: false
          }
        },

        data: [
          {
            name: 'Align',
            y: (alignment / total) * 100,
            color: '#9320a2'
          },
          {
            name: 'Not Align',
            y: ((total - alignment) / total) * 100,
            color: '#0099f2'
          }
        ]
      }
    ]
  };
  return (
    <div className="overview-box">
      <div className="alignment-card-container">
        <span className="typography-h200 mb-[24px]">
          {getObjectiveLocale('Goal Alignment')}
        </span>
        <div className="wrapper-highchart">
          <HighchartsReact
            highcharts={Highcharts}
            options={configCircleChart}
          />
          <span className="alignment-information">
            {getNumberLocale(alignment)} {getObjectiveLocale('out')}{' '}
            {getNumberLocale(total)} {getObjectiveLocale('Objectives')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GoalAlignment;

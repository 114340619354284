import React, { useEffect, useState } from 'react';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { capitalize } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';

const DropdownRepetition = ({
  handleChange,
  defaultValue,
  isTask,
  customTrigger,
  overrideOptionValues,
  customClassItems,
  maxHeightItems,
  disabled
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const { config } = useUser();
  const { goalRecurrenceOptions, taskRecurrenceOptions } =
    config?.objectivesConfig?.recurrence || {};
  let optionsRepetition = [];
  const usedRecurrence = overrideOptionValues
    ? overrideOptionValues
    : isTask
    ? taskRecurrenceOptions
    : goalRecurrenceOptions;

  const getNameRecurrence = (value) => {
    switch (value) {
      case 'none':
        return 'never';
      case 'daily':
        return 'day';
      case 'weekly':
        return 'week';
      case 'monthly':
        return 'month';
      case 'quarterly':
        return 'quarter';
      case 'semesterly':
        return 'semester';
      case 'yearly':
        return 'year';
      default:
        return 'custom';
    }
  };

  const handleChangeDropdown = (text) => {
    setSelectedValue(text);
    handleChange(text);
  };

  if (config.objectivesConfig?.recurrence) {
    usedRecurrence?.map((recurrence, index) => {
      const text = getObjectiveLocale(
        recurrence == 'none' ? 'Does Not Repeat' : capitalize(recurrence)
      );
      optionsRepetition.push({
        name: getNameRecurrence(recurrence),
        text: text,
        onClick: () => handleChangeDropdown(text),
        dataCy: `dropdown-repetition-option-${index + 1}`
      });
    });
  }

  useEffect(() => {
    const value =
      optionsRepetition.find(({ name }) => name === defaultValue)?.text ||
      'never';
    setSelectedValue(value);
  }, [defaultValue]);

  const CustomTrigger = customTrigger;

  const maxHeightProps = maxHeightItems ? { maxHeight: maxHeightItems } : [];

  return (
    <Dropdown>
      <Dropdown.Trigger
        customClass="cursor-pointer"
        dataCy="dropdown-repetition"
        disabled={disabled}
      >
        {customTrigger ? (
          <CustomTrigger value={selectedValue} disabled={disabled} />
        ) : (
          <p className="text-base-600">{selectedValue}</p>
        )}
      </Dropdown.Trigger>
      <Dropdown.MenuItems
        options={optionsRepetition}
        customClass={customClassItems}
        {...maxHeightProps}
      />
    </Dropdown>
  );
};

export default DropdownRepetition;

import { getTags as _getTags} from "client/TagClient";
import { useQuery } from "react-query";

export default function useTags() {

  // GET OBJECTIVES PAGINATION
  const GetTags = ({ q }) => {

    const fetchTags = async () => {
      let params = { q };

      const { data } = await _getTags(params);
      return [ data.predefined, data.others ]
    };

    const {
      data,
      status,
      isFetching,
      refetch
    } = useQuery(['tags', { q }], fetchTags)

    return {
      data,
      status,
      isFetching,
      refetch
    };
  };

  return { getTags : GetTags };
}
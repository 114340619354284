import React, { useState } from 'react';

import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

import { updatePhaseData } from 'client/FormalReviewClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import BannerBox from 'src/components/shared/BannerBox';
import { trackEvent } from 'src/utils/AnalyticUtils';

const CycleSidebarToggleNotification = ({
  cycleData,
  phaseType,
  phaseDetail,
  loading,
  onSuccess
}) => {
  const { config } = useUser();
  const { invalidateQueries } = useRefetchQuery();
  const {
    permissions: { reviewPerformanceCycleSee }
  } = config;

  const { addToast } = useToastContext();
  const [isUpdating, setIsUpdating] = useState(false);

  const onChangeReminderPhase = async (phaseType, value) => {
    setIsUpdating(true);
    let body = {
      notifyOnStart: value
    };

    const { isSuccess } = await updatePhaseData(
      cycleData?.id,
      snakeCase(phaseType),
      body
    );
    if (isSuccess) {
      trackEvent({
        event: `toggle ${phaseType} notification config`,
        eventProperties: {
          cycleId: cycleData?.id,
          cycleName: cycleData?.name,
          phase: phaseType,
          notifyOnStart: value
        }
      });

      await invalidateQueries(['cycle', 'sidebar', cycleData?.id]);
      onSuccess && (await onSuccess());

      addToast({
        title: 'Your changes have been saved!',
        msg: 'Email notification configuration has been successfully updated.',
        type: 'success'
      });
    } else {
      addToast({
        title: 'Your changes could not be saved!',
        msg: 'We could not save your changes, please contact our customer support.',
        type: 'error'
      });
    }
    setIsUpdating(false);
  };

  return (
    <BannerBox
      paddingClass="py-[8px] px-[16px]"
      marginClass="mb-[0px]"
      withIcon={false}
      type="neutral"
      customClass="rounded-[4px]"
    >
      <div className="flex items-center justify-between">
        {isUpdating || loading ? (
          <>
            <Shimmer
              width={'100%'}
              height={18}
              customClass="rounded-[4px] mr-[12px]"
            />
            <Shimmer width={36} height={18} customClass="rounded-[4px]" />
          </>
        ) : (
          <>
            <p className="typography-paragraph text-n-900">
              {getObjectiveLocale('Send email notification for reviewers')}
            </p>

            <SmallToggleSwitchPurple
              idStr={`toggle-reminder-${phaseType}`}
              addClass="flex mt-[3px]"
              disabled={
                phaseDetail?.state !== 'draft' || !reviewPerformanceCycleSee
              }
              onChange={() =>
                !isUpdating &&
                onChangeReminderPhase(
                  camelCase(phaseType),
                  !phaseDetail?.notifyOnStart
                )
              }
              isChecked={phaseDetail?.notifyOnStart}
              labelClass="mx-[0px]"
            />
          </>
        )}
      </div>
    </BannerBox>
  );
};

export default CycleSidebarToggleNotification;

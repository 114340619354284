import React, { useReducer } from 'react';

import { unSubcribeFirebase } from 'utils/FirebaseUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

//CREATE CONTEXT
const NetworkErrorContext = React.createContext();

//REDUCER
function NetworkErrorReducer(state, action) {
  const { type, error } = action;
  switch (type) {
    case 'ENQUEUE': {
      return [...state, error];
    }
    case 'DEQUEUE': {
      return state.splice(1);
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

//PROVIDER
function NetworkErrorProvider(props) {
  const [queue, dispatch] = useReducer(NetworkErrorReducer, []);
  const queueHead = queue[0];
  const contextValue = [{ queue, queueHead }, dispatch];

  return <NetworkErrorContext.Provider value={contextValue} {...props} />;
}

//HOOKS
function useNetworkError() {
  const [{ queue, queueHead }, dispatch] =
    React.useContext(NetworkErrorContext);

  const pushError = (payload) => {
    let isSuccess = payload?.isSuccess || false;
    let message = payload?.message;
    if (!(message && typeof message === 'string')) {
      message = getObjectiveLocale("There's an error please recheck again");
    }

    const error = { code: payload.code, message, isSuccess };
    dispatch({ type: 'ENQUEUE', error });
  };

  const popError = () => {
    dispatch({ type: 'DEQUEUE' });
  };

  const globalHandler = (error) => {
    let endpoint = error.config.url;

    let err = error.response.data.error
      ? error.response.data.error
      : error.response.data.errors;

    if (
      !endpoint.includes('auth') &&
      !endpoint.includes('password') &&
      !endpoint.includes('send_activation') &&
      !endpoint.includes('integrations')
    ) {
      switch (err.code) {
        case 401:
          if (!window.Cypress && !localStorage.getItem('tokenInvalid')) {
            'Notification' in window &&
              Notification.permission !== 'denied' &&
              unSubcribeFirebase();

            localStorage.setItem('tokenInvalid', true);
            alert(err.message);
            location.replace('/login/email');
          }

          break;

        case 422:
          err.message =
            err?.message?.length > 200
              ? 'Something went wrong on our side! Please try again later'
              : err?.message;
          pushError(err);
          break;

        case 403:
          pushError({
            message: err?.message || 'Not Allowed',
            code: 403
          });
          break;

        case 404:
          pushError(err);
          break;

        case 500:
          pushError({
            message: 'Something went wrong on our side! Please try again later',
            code: 500
          });
          break;

        default:
          break;
      }
    }
    return Promise.reject(error);
  };

  return {
    queue,
    queueHead,
    pushError,
    popError,
    globalHandler
  };
}

export { NetworkErrorProvider, NetworkErrorContext, useNetworkError };

import React, { useEffect, useState } from 'react';

import isNil from 'lodash/isNil';

import { getAnnualReviewSummary } from 'client/FormalReviewClient';
import { phaseName } from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { restructurePreviousSummaries } from 'utils/SummaryHelper';

import TwoLine from 'components/design-system/ProfileType';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

import { resultColorDict } from '../../evidence-sidebar/ColorDictionarySidebarEvidence';
import DecimalHoverable from './DecimalHoverable';
import PreviousSummaries from './PreviousSummaries';
import SPComponent from './SPComponent';
import './SummaryAnnualReview.scss';

const SummaryAnnualReview = ({
  assignmentId,
  user,
  phase,
  answersData,
  trackTemplate,
  trackConfig
}) => {
  const [isModalDetailAssessmentOpen, setIsModalDetailAssessmentOpen] =
    useState(false);
  const [summaryData, setSummaryData] = useState(answersData || {});
  const [summariesData, setSummariesData] = useState([]);
  const [scoreModifier, setScoreModifier] = useState([]);
  const [options, setOptions] = useState(
    answersData?.ratingTemplate?.scoringComponents?.[0]?.scoringMarks || []
  );
  const [showOption, setShowOption] = useState(
    answersData?.ratingTemplate?.showOption || null
  );

  const getSummary = async () => {
    const { data } = await getAnnualReviewSummary(assignmentId);
    if (data) {
      setSummaryData(data);
      setScoreModifier(data.finalScoreModifiers);
      setOptions(data.ratingTemplate?.scoringComponents?.[0]?.scoringMarks);
      setShowOption(data?.ratingTemplate?.showOption);
      getSummariesData(data);
    }
  };

  const getSummariesData = (data) => {
    const temp = restructurePreviousSummaries(data);
    setSummariesData(temp);
  };

  const getRatingRange = (option, index, lastIndex) => {
    if (index == 0) {
      return `${option.bottomThresholdScore} s.d ${option.upperThresholdScore}`;
    } else if (index == lastIndex) {
      return `\u003e ${option.bottomThresholdScore}`;
    } else {
      return `\u003e ${option.bottomThresholdScore} s.d ${option.upperThresholdScore}`;
    }
  };

  useEffect(() => {
    if (answersData) {
      getSummariesData(answersData);
      setScoreModifier(answersData.finalScoreModifiers);
    } else {
      getSummary();
    }
  }, []);

  let finalSummaryScore = 0;
  if (!isNil(summaryData?.finalPeriodScore)) {
    finalSummaryScore = summaryData.finalPeriodScore;
  } else if (!isNil(summaryData?.totalScore)) {
    finalSummaryScore = summaryData.totalScore;
  }

  const scoreModifierConfig = summaryData?.ratingTemplate?.finalScoreModifier;

  return (
    <>
      {answersData && (
        <>
          <p className="typography-h600 mb-[16px]">
            {getObjectiveLocale('Review Summary')}
          </p>
          <TwoLine
            customClass="p-[16px] bg-n-100 rounded-[4px] border border-solid border-n-400 mb-[16px]"
            user={user}
            title={user?.name}
            subtitle={user?.title}
          />
        </>
      )}
      <div className="summary-annual-review-container">
        <div className="summary-target-assessment-container">
          <div className="summary-target-assessment-card">
            <div className="px-[16px]">
              <p className="mt-[16px] !mb-[8px] w-[100%] text-center typography-h200 typography-secondary uppercase">
                {phaseName(phase.type)}
              </p>
              <div className="flex items-center flex-col justify-center bg-n-100 rounded-[4px] py-[16px] border border-solid border-n-300">
                {showOption != 'score_only' && (
                  <h2
                    className={`typography-h800 ${
                      resultColorDict[summaryData?.scoringMark?.colorHex]
                    }`}
                  >
                    {summaryData?.scoringMark?.name}
                  </h2>
                )}
                {showOption != 'label_only' && (
                  <DecimalHoverable
                    number={finalSummaryScore}
                    textClass={`typography-h500 ml-[8px] mt-[4px] ${
                      resultColorDict[summaryData?.scoringMark?.colorHex]
                    }`}
                  />
                )}
              </div>
            </div>
            <div className="mx-[16px] mt-[16px]">
              {trackTemplate?.config?.summaryRatingTooltipEnabled && (
                <div className="flex items-center mb-[8px]">
                  <h4 className="typography-h200 typography-tertiary mr-[8px]">
                    {getObjectiveLocale('Summary of given scoring.')}
                  </h4>
                  <SVGIcon
                    onClick={() => setIsModalDetailAssessmentOpen(true)}
                    size="16"
                    iconName="icon-info"
                    fillColor="var(--n-600)"
                  />
                </div>
              )}
              <div className="grid">
                <div className="grid grid-assessment py-[10px] border-bottom-n-400 ml-[-16px] mr-[-16px] px-[16px]">
                  <p className="typography-h300 text-n-800">Detail</p>
                  <p className="text-center typography-h300 text-n-800">
                    {getObjectiveLocale('[Summary] Score')}
                  </p>
                  <p className="text-center typography-h300 text-n-800">
                    {getObjectiveLocale('Weight')}
                  </p>
                  <p className="text-right typography-h300 text-n-800">
                    {getObjectiveLocale('Weighted Score')}
                  </p>
                  <p className="w-[58px]"></p>
                </div>
                {summariesData?.map((data, index) => (
                  <PreviousSummaries
                    key={index}
                    data={data}
                    isOpen={index == summariesData?.length - 1}
                    trackConfig={trackConfig}
                  />
                ))}
              </div>
              {scoreModifier?.map((value, index) => {
                return (
                  <SPComponent
                    key={index}
                    spName={value.name}
                    spData={value}
                    scoreModifierConfig={scoreModifierConfig}
                  />
                );
              })}
              <div className="ml-[-16px] mr-[-16px] between-to-center py-[16px] pl-[16px] pr-[50px] bg-lb-300 rounded-b-4">
                <p className="typography-h300 uppercase text-n-900">
                  {getObjectiveLocale('Final Score')}
                </p>
                <DecimalHoverable
                  number={finalSummaryScore}
                  textClass="typography-h300 text-n-900"
                />
              </div>
            </div>
          </div>

          {isModalDetailAssessmentOpen && (
            <Modal
              title={'Rating dan Nilai Pengurangan'}
              eventOnClickClose={() => setIsModalDetailAssessmentOpen(false)}
              withCloseIcon
              className="w-[600px]"
              footerClass="h-[20px]"
            >
              <p className="fw-bold mb-[18px]">
                {getObjectiveLocale('Rating Nilai')}{' '}
                {summaryData?.ratingTemplate?.name}
              </p>
              <div className="grid gap-[20px]">
                <div className="d-grid-2">
                  <p className="typography-h100 typography-secondary">
                    {getObjectiveLocale('Nilai')}
                  </p>
                  <p className="typography-h100 typography-secondary">
                    {getObjectiveLocale('Nilai Akhir')}{' '}
                    {summaryData?.ratingTemplate?.name}
                  </p>
                </div>
                {options.map((option, index) => {
                  return (
                    <div className="d-grid-2" key={index}>
                      <p className="fw-bold">
                        {getRatingRange(option, index, options.length - 1)}
                      </p>
                      <p>{option.name}</p>
                    </div>
                  );
                })}
              </div>
              {summaryData?.ratingTemplate?.spConfigs?.length > 0 && (
                <>
                  <p className="fw-bold mb-[18px] mt-[18px]">
                    {getObjectiveLocale('Nilai Pengurangan')}{' '}
                    {summaryData?.ratingTemplate?.name}
                  </p>
                  <div className="grid gap-[20px]">
                    <div className="d-grid-2">
                      <p className="typography-h100 typography-secondary">
                        {getObjectiveLocale('SP')}
                      </p>
                      <p className="typography-h100 typography-secondary">
                        {getObjectiveLocale('Nilai Pengurangan')}
                      </p>
                    </div>
                    {summaryData?.ratingTemplate?.spConfigs?.map(
                      (option, index) => {
                        return (
                          <div className="d-grid-2" key={index}>
                            <p className="fw-bold">{option.name}</p>
                            <p>{option.valueReduction}</p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </>
              )}
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default SummaryAnnualReview;

import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useFeeds } from 'context/FeedsContext';
import { useUser } from 'context/UserContext';
import { getCultureLocale } from 'utils/HelperUtils';

import Feeds from 'components/culture/Feeds';
import GroupPageHeader from 'components/culture/GroupPageHeader';
import GroupSection from 'components/culture/GroupSection';
import HighlightPinnedSection from 'components/culture/HighlightPinnedSection';
import TimelineDescription from 'components/culture/TimelineDescription';

const GroupFeed = ({
  groupDetail,
  isRefetch,
  eventOnClick,
  setScrollPosition,
  scrollPosition,
  setGroupDetail
}) => {
  let timelineRef = useRef();
  const params = useParams();
  const { user, config } = useUser();
  const { setPage, setFilter, filter, onScroll } = useFeeds(
    params?.groupId || groupDetail?.id,
    user?.id
  );

  const typeGroupList = [
    {
      id: 'default',
      icon: 'icon-manage_account_online',
      name: getCultureLocale('Default Group'),
      desc: getCultureLocale(
        'This group will automatically be shown up for all users and everyone will be able to post a new content.'
      ),
      isVisible: config?.permissions?.cultureGroupDefaultManage
    },
    {
      id: 'open',
      icon: 'icon-public',
      name: getCultureLocale('OpenGroup'),
      desc: getCultureLocale('Anyone can find your group and view posts.'),
      isVisible: true
    },
    {
      id: 'closed',
      icon: 'icon-lock_outline',
      name: getCultureLocale('ClosedGroup'),
      desc: getCultureLocale('Only invited members can join and view posts.'),
      isVisible: true
    },
    {
      id: 'private',
      icon: 'icon-vpn_lock',
      name: getCultureLocale('PrivateGroup'),
      desc: getCultureLocale(
        'Invisible in search and only invited members can join and view posts.'
      ),
      isVisible: true
    },
    {
      id: 'default_moderated',
      icon: 'icon-default-moderated',
      name: getCultureLocale('Default Moderated Group'),
      desc: getCultureLocale(
        'Like default group but only group admin can post a new content'
      ),
      isVisible: config?.permissions?.cultureGroupDefaultManage
    },
    {
      id: 'open_moderated',
      icon: 'icon-moderated',
      name: getCultureLocale('Open Moderated Group'),
      desc: getCultureLocale(
        'Anyone can find your group and view post. Only Admin can create post'
      ),
      isVisible: true
    },
    {
      id: 'closed_moderated',
      icon: 'icon-moderated-lock',
      name: getCultureLocale('Closed Moderated Group'),
      desc: getCultureLocale(
        'Only invited members can join and view post. Only Admin can create post'
      ),
      isVisible: true
    }
  ].filter(({ isVisible }) => isVisible);

  const setScrollPage = (e) => {
    e.stopPropagation();
    setScrollPosition(timelineRef?.current?.scrollTop);
    onScroll(e);
  };

  useEffect(() => {
    setFilter('group-recent');
  }, []);

  useEffect(() => {
    filter && params?.groupId && setPage(`group-${params?.groupId}-${filter}`);
  }, [filter, params?.groupId]);

  return (
    <div
      className="flex scrollable h-[calc(100vh-64px)]"
      ref={timelineRef}
      id="scroll-feeds"
      onScroll={(e) => !isRefetch && setScrollPage(e)}
    >
      <GroupSection
        groupDetail={groupDetail}
        typeGroupList={typeGroupList}
        setGroupDetail={setGroupDetail}
        setFilter={setFilter}
      />
      <div>
        <GroupPageHeader
          groupDetail={groupDetail}
          typeGroupList={typeGroupList}
          setFilter={setFilter}
          filter={filter}
        />
        <div className="flex">
          <div className="w-[560px] ml-[24px]">
            {filter === 'group-recent' && (
              <HighlightPinnedSection
                widthWrapper={560}
                type="pinned"
                groupId={params.groupId || groupDetail.id}
              />
            )}
            <Feeds
              widthWrapper="w-[560px]"
              groupDetail={params.groupId || groupDetail.id}
              isRefetch={isRefetch}
              eventOnClick={eventOnClick}
            />
          </div>
          <TimelineDescription
            groupDetail={groupDetail}
            typeGroupList={typeGroupList}
            scrollPosition={scrollPosition}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupFeed;

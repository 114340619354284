import React, { createRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import { getManagerChildren } from 'client/FormalReviewClient';
import { useSidebarMenu } from 'context/SidebarMenuContext';
import { useUser } from 'context/UserContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getPeriod } from 'utils/ObjectivesHelper';

import HeaderPage from 'components/admin/HeaderPage';
import CycleComponent from 'components/formal-review/landing-page/CycleComponent';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import Modal from 'components/shared/modal/Modal';
import { useGetActiveCycles } from 'src/api/useFormalReview';
import { useAdvanceFilter } from 'src/components/filter/AdvanceFilter';
import AdvanceFilter from 'src/components/filter/AdvanceFilter';
import FormalReviewFilterSection from 'src/components/formal-review/landing-page/FormalReviewFilterSection';
import useFilter from 'src/hooks/global-state/useFilter';

const LIMIT = 10;

const EmptyObjectives = ({ searchKey, isMyReview }) => (
  <ListEmptyState
    fullHeight
    size="large"
    containerClassname="my-[40px]"
    emptyIcon={
      searchKey
        ? 'icon-no-result-found'
        : isMyReview
        ? 'icon-no-active-performance-review'
        : 'icon-no-previous-performance-review'
    }
    title={getObjectiveLocale(
      searchKey
        ? 'No searched items found '
        : `No ${isMyReview ? 'Active' : 'Progress & Result'} Assignment Review`
    )}
    subtitle={getObjectiveLocale(
      searchKey
        ? 'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
        : `You don't have any assignment reviews yet. When you have assignment reviews in a review cycle, you will find them here.`
    )}
  />
);

const FormalReview = ({ currentTab }) => {
  const history = useHistory();
  const { user, config } = useUser();
  const { permissions, permissionInfos } = config;
  const withoutShowType =
    permissions?.reviewPerformanceCycleProgressAllSee ||
    permissions?.reviewPerformanceCycleResultAllSee;
  const [listCycles, setListCycles] = useState([]);
  const [errorInfo, setErrorInfo] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);

  const actorId = parseInt(user.id);
  const { toggleSidebar } = useSidebarMenu();

  const pathname = history.location.pathname;

  const isFeedbackPage = pathname == '/feedbacks';

  const { id } = useAdvanceFilter();
  const filter = useFilter((state) => state.filter);

  const filterCannotBeCalibrated = (cycleData) => {
    if (cycleData?.length > 0) {
      let filteredCycles = cycleData?.filter((cycle) => {
        return !(
          cycle?.phases?.length == 1 &&
          cycle?.phases?.[0]?.type == 'calibration' &&
          !cycle?.phases?.[0]?.ableToCalibrate
        );
      });

      return filteredCycles;
    }

    return [];
  };

  const {
    q,
    status,
    assignmentStatus,
    phaseStatus,
    involvement,
    ...allFilter
  } = filter[id] || {};

  const params = {
    limit: LIMIT,
    involverId: actorId,
    search: q,
    state: [],
    assignmentState: assignmentStatus,
    phaseState: phaseStatus,
    type: isFeedbackPage ? 'feedback' : 'performance_review',
    ...allFilter
  };

  if (status?.includes('active')) {
    params.state.push('in_progress', 'reopen');
  }
  if (status?.includes('ended')) {
    params.state.push('done');
  }
  if (involvement?.length == 1) {
    params.involvementBased = involvement[0];
  }
  if (currentTab == 'progress and results') {
    if (!withoutShowType) {
      params.showType = 'direct_reports';
    }
    delete params.involverId;
  }

  const {
    data: activeCyclesData,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useGetActiveCycles(params, {
    staleTime: 10000, // 10 seconds
    enabled: !!filter,
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.length === LIMIT) {
        return lastPage.pagination?.next?.olderThan;
      }
    },
    onError: (error) => {
      setShowErrorModal(true);
      setErrorInfo({ code: error.code, message: error.message });
    }
  });

  useEffect(() => {
    const newCycles = flatten(
      activeCyclesData?.pages?.map((page) => {
        return page?.data?.map((d) => {
          return d;
        });
      })
    );

    const filteredCycles = filterCannotBeCalibrated(newCycles);
    setListCycles(filteredCycles);
  }, [activeCyclesData]);

  const intersectTarget = createRef();
  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting &&
      !isFetchingNextPage &&
      hasNextPage &&
      fetchNextPage(),
    threshold: 1
  });

  useEffect(() => {
    if (
      (history.action == 'REPLACE' || history.action == 'POP') &&
      window.innerWidth > 1200
    ) {
      toggleSidebar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <div className="my-[24px]">
        <FormalReviewFilterSection />
      </div>
      {isFetching && !isFetchingNextPage ? (
        <LoadingComponent />
      ) : (
        <div>
          <div data-cy="list-cycles-container">
            {listCycles?.length == 0 ? (
              <EmptyObjectives
                searchKey={filter?.q}
                isMyReview={currentTab === 'my reviews'}
              />
            ) : (
              <>
                {listCycles?.map((cycle, idx) => (
                  <div
                    ref={
                      idx == listCycles.length - 1 ? intersectTarget : undefined
                    }
                    key={`${cycle.id}-${idx}-${currentTab}`}
                    className="cycle-component-container"
                  >
                    <CycleComponent
                      cycle={cycle}
                      currentTab={currentTab}
                      filter={filter[id]}
                    />
                  </div>
                ))}
                {isFetchingNextPage && (
                  <LoadingComponent className="pb-[30px]" />
                )}
              </>
            )}
          </div>
          {showErrorModal && (
            <Modal
              title={`Error (Code: ${errorInfo.code})`}
              eventOnClickClose={() => setShowErrorModal(false)}
              withCloseIcon
            >
              <p className="text-n-800 typography-paragraph">
                {errorInfo.message}
              </p>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

FormalReview.displayName = 'FormalReview';

const FormalReviewWrapper = ({ route: { title } }) => {
  const history = useHistory();
  const { user, config } = useUser();
  const pathname = history.location.pathname;
  const isFeedbackPage = pathname == '/feedbacks';
  const { permissions, permissionInfos } = config;

  const [listObjTabs, setListObjTabs] = useState([
    {
      id: 'my reviews',
      name: isFeedbackPage ? 'To Do' : 'My Reviews',
      tooltipText: 'Tab to view your review assignments',
      onClick: () => history.replace(pathname)
    }
  ]);

  const [currentTab, setCurrentTab] = useState('my reviews');

  let periodFilter = getPeriod(config?.defaultCyclesTimeFilter?.[0]);
  const withoutShowType =
    permissions?.reviewPerformanceCycleProgressAllSee ||
    permissions?.reviewPerformanceCycleResultAllSee;

  let defaultFilter = {
    periodBegin: periodFilter?.periodBegin,
    periodEndBefore: periodFilter?.periodEndBefore,
    status: config?.defaultEndUserCyclesFilter || [],
    assignmentStatus: config?.defaultAssignmentsStatusFilter || [],
    phaseStatus: config?.defaultPhasesStatusFilter || [],
    sortColumn: config?.cyclesSorting?.sortColumn || 'id',
    sortDirection: config?.cyclesSorting?.sortDirection || 'desc',
    ...(config?.involvementCyclesFilter?.length > 0 && {
      involvement: config?.defaultInvolvementCyclesFilter || []
    }),
    ...(history.action == 'PUSH' &&
      history?.location?.state && { ...history?.location?.state })
  };

  const showProgressResultTab = async () => {
    let showTab = false;
    const isAdminWilayah = permissionInfos && withoutShowType;

    if (isAdminWilayah) {
      showTab = true;
    } else {
      const { data } = await getManagerChildren(user.id);
      if (data?.length > 0) {
        showTab = true;
      }
    }

    if (showTab) {
      let newTabs = [...listObjTabs];
      newTabs.push({
        id: 'progress and results',
        name: 'Progress and Results',
        tooltipText:
          'Tab to view your team member review assignments progress and result',
        onClick: () => history.replace('/appraisals/others')
      });
      setListObjTabs(newTabs);
    }
  };

  useEffect(() => {
    showProgressResultTab();
  }, []);

  return (
    <>
      <HeaderPage
        titlePage={title}
        tabs={{
          tabsList: listObjTabs,
          url: pathname,
          onChange: (tab) => {
            setCurrentTab(tab);
          }
        }}
      />
      <AdvanceFilter id="formal-review-end-user" defaultFilter={defaultFilter}>
        <FormalReview currentTab={currentTab} />
      </AdvanceFilter>
    </>
  );
};

export default FormalReviewWrapper;

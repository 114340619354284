import React, { useRef, useState } from 'react';

import dayjs from 'dayjs';

import SingleCalendar from 'components/shared/Calendar';
import SVGIcon from 'src/components/shared/SVGIcon';
import TimePickerV2 from 'src/components/shared/TimePickerV2';
import { useToastContext } from 'src/context/ToastContext';
import { useDeepEffect } from 'src/utils/useDeepEffect';

const timeComponent = ({ value, onClick, readOnly }) => (
  <div
    className={`w-[180px] h-[32px]  pl-[16px] pr-[4px] flex flex-row items-center justify-between rounded border border-solid border-n-400 cursor-pointer ${
      readOnly
        ? 'bg-n-300 hover:cursor-not-allowed'
        : 'bg-n-000 hover:cursor-pointer'
    }`}
    onClick={() => !readOnly && onClick()}
  >
    <p
      className={`typoghraphy-paragrapgh ${
        readOnly ? 'text-n-600' : 'text-n-900'
      }`}
    >
      {value}
    </p>

    <SVGIcon
      size="24"
      iconName="icon-access_time"
      fillColor="var(--base-600)"
    />
  </div>
);

const CustomDateTrigger = ({ value, disabled }) => {
  return (
    <p
      className={`w-full typoghraphy-paragraph ${
        disabled ? 'text-n-600' : 'text-n-900'
      }`}
    >
      {value}
    </p>
  );
};

const DateSelector = ({
  isReadOnly,
  selectedDate,
  callbackFn,
  due,
  start,
  useTime = true,
  disabledDateUntil,
  id
}) => {
  const [date, setDate] = useState(selectedDate);
  const firstRender = useRef(null);
  const { addToast } = useToastContext();

  const handleChangeDate = (date) => {
    const time = dayjs(date).format('hh:mm A');

    const dateAndTime = `${dayjs(date).format('DD MMM YYYY')}, ${time}`;
    callbackFn && callbackFn(dayjs(dateAndTime).toISOString());
    setDate(dayjs(dateAndTime).toISOString());
  };

  const handleChangeTime = (selectedTime) => {
    const timestamp = dayjs(selectedTime).valueOf();
    if (start && timestamp < new Date(start).getTime()) {
      return addToast({
        msg: 'The end date must be greater than start date',
        type: 'error'
      });
    }
    const changedTime = dayjs(selectedTime).format('hh:mm A');
    const dateAndTime = `${dayjs(date).format('DD MMM YYYY')}, ${changedTime}`;
    callbackFn && callbackFn(dayjs(dateAndTime).toISOString());
    setDate(dayjs(dateAndTime).toISOString());
  };

  const disabledDateHandler = (current) => {
    if (!current) return false;
    if (due || start) {
      const date = dayjs(due ? due : start);
      let isInvalid = false;
      date.hour(0);
      date.minute(0);
      date.second(0);
      return (
        (due
          ? current.valueOf() > date.valueOf()
          : current.valueOf() < date.valueOf()) || isInvalid
      );
    }
    const date = disabledDateUntil
      ? dayjs(disabledDateUntil)
      : dayjs().subtract(1, 'day');
    return current.valueOf() < date.valueOf();
  };

  useDeepEffect(() => {
    if (firstRender.current && selectedDate) {
      handleChangeDate(selectedDate);
      return;
    }
    firstRender.current = true;
  }, [selectedDate]);

  return (
    <>
      <div className={`w-[180px] h-[32px] ${useTime ? 'mr-[8px]' : ''}`}>
        <SingleCalendar
          disabledDate={disabledDateHandler}
          defaultValue={dayjs(date)}
          handleChangeCalendar={handleChangeDate}
          type={id || 'date'}
          customBox={`border-solid border-n-400 border h-[32px] rounded-[4px] w-[180px] ${
            isReadOnly ? 'bg-n-300' : 'bg-n-000 '
          }`}
          calendarButtonType="today"
          permissionStartDate={!isReadOnly}
          permissionDueDate={!isReadOnly}
          dateFormat="MMM D, YYYY"
          customTrigger={CustomDateTrigger}
        />
      </div>
      {useTime && (
        <TimePickerV2
          selectedTime={date}
          handleChange={handleChangeTime}
          isReadOnly={isReadOnly}
          TextFieldComponent={timeComponent}
          use24HFormat
          useVisualGmt7
        />
      )}
    </>
  );
};

export default DateSelector;

import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';
import { format } from 'timeago.js';

import {
  deletePost,
  getGroupPostable,
  getPostById,
  highlightPinnedPost,
  mutePost,
  reportPost,
  repostPost,
  savePost,
  unmutePost,
  unsavePost
} from 'client/CultureClient';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import Config from 'utils/Config';
import { capitalize, getCultureLocale } from 'utils/HelperUtils';
import { getCssVariableValue } from 'utils/HelperUtils';

import ModalPostPhoto from 'components/modal/culture/ModalPostPhoto';
import Avatar from 'components/shared/Avatar';
import Badge from 'components/shared/Badge';
import ProfileSection from 'components/shared/Profile/ProfileSection';
import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';
import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';

function PostHeader({ feed, goToPost, eventOnClick }) {
  const { user: currentFeedUser, createdAt, id: postId } = feed;
  const params = useParams();
  const history = useHistory();
  const { user, organization } = useUser();
  const { reload, reloadHighlightPinned } = useReload();
  const userPosition = currentFeedUser?.title;

  const [currentFeed, setCurrentFeed] = useState(cloneDeep(feed));
  const [modalType, setModalType] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toastData, setToastData] = useState({ isShow: false, message: '' });
  const [reportData, setReportData] = useState({ reason: '', comment: '' });
  const [groupList, setGroupList] = useState([]);

  const dropdownOptionRef = useRef();
  useClickOutside(dropdownOptionRef, () => {
    setIsDialogOpen(false);
  });

  let listOption = [
    {
      id: 'pinned',
      name: getCultureLocale('Pin post'),
      iconName: 'icon-pin',
      isVisible: currentFeed.pinnable && !currentFeed.pinned
    },
    {
      id: 'unpinned',
      name: getCultureLocale('Unpin post'),
      iconName: 'icon-unpin',
      isVisible: currentFeed.pinnable && currentFeed.pinned
    },
    {
      id: 'highlight',
      name: getCultureLocale('Highlight post'),
      iconName: 'icon-star-rounded',
      isVisible: currentFeed.highlightable && !currentFeed.highlighted
    },
    {
      id: 'unhighlight',
      name: getCultureLocale('Unhighlight post'),
      iconName: 'icon-delete',
      isVisible: currentFeed.highlightable && currentFeed.highlighted
    },
    {
      id: 'edit',
      name: getCultureLocale('Edit'),
      iconName: 'icon-create',
      isVisible: currentFeed.editStatus.editable
    },
    {
      id: 'save',
      name: getCultureLocale('Save post'),
      iconName: 'icon-bookmark',
      isVisible:
        currentFeed.saveStatus.saveable && !currentFeed.saveStatus.saved
    },
    {
      id: 'unsave',
      name: getCultureLocale('Unsave post'),
      iconName: 'icon-bookmark_outline',
      isVisible: currentFeed.saveStatus.saveable && currentFeed.saveStatus.saved
    },
    {
      id: 'copy',
      name: getCultureLocale('Copy post ID'),
      iconName: 'icon-content_copy',
      isVisible: true
    },
    {
      id: 'repost',
      name: getCultureLocale('Repost to other group'),
      iconName: 'icon-repeat',
      isVisible: currentFeed.repostStatus.repostable
    },
    {
      id: 'off',
      name: getCultureLocale('Turn off post notification'),
      iconName: 'icon-notifications_off',
      isVisible: currentFeed.notification
    },
    {
      id: 'on',
      name: getCultureLocale('Turn on post notification'),
      iconName: 'icon-notifications',
      isVisible: !currentFeed.notification
    },
    {
      id: 'report',
      name: getCultureLocale('Report post'),
      iconName: 'icon-warning',
      isVisible: user.id !== currentFeed.user.id
    },
    {
      id: 'delete',
      name: getCultureLocale('Delete post'),
      iconName: 'icon-delete',
      isVisible: currentFeed.deletable,
      color: 'var(--r-600)'
    }
  ];
  listOption = listOption.filter((option) => option.isVisible);

  let reportList = [
    {
      key: 'inappropriate',
      title: getCultureLocale('This post is inappropriate')
    },
    { key: 'offensive', title: getCultureLocale('This post is offensive') },
    {
      key: 'unsuitable',
      title: `${getCultureLocale("This post shouldn't be on")} ${
        organization.name
      }`
    }
  ];

  const setShowNotif = (msg, isSuccess) => {
    setToastData({ isShow: true, message: msg, isWarning: !isSuccess });
    setTimeout(() => {
      setToastData({ isShow: false, message: '', isWarning: false });
    }, 2000);
  };

  const setNotifResponse = (isSuccess, message, isReload) => {
    if (isSuccess) {
      setShowNotif(message.success, isSuccess);
      isReload && eventOnClick(true);
    } else {
      setShowNotif(message.failed, isSuccess);
    }
    setModalType('');
  };

  const _getPostById = async (isSuccess) => {
    if (isSuccess) {
      const { data } = await getPostById(currentFeed.id);

      if (data) {
        setCurrentFeed(data);
      }
    }
  };

  const getListGroupRepost = async () => {
    const { data } = await getGroupPostable();
    if (data) {
      setGroupList(data);
      setIsModalOpen(true);
    }
  };

  const copyPostId = () => {
    const el = document.createElement('textarea');
    el.value = postId;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    setTimeout(() => {
      document.body.removeChild(el);
    }, 1500);
    setShowNotif('Copied');
    setModalType('');
  };

  const _deletePost = async () => {
    setIsLoading(true);
    const { isSuccess } = await deletePost(currentFeed.id);
    if (params.postId) {
      history.replace('/timeline');
    }
    setNotifResponse(
      isSuccess,
      { success: 'The post has been deleted', failed: 'Delete post failed' },
      true
    );
  };

  const _mutePost = async () => {
    const { isSuccess } = await mutePost(currentFeed.id);
    _getPostById(isSuccess);
    setNotifResponse(
      isSuccess,
      { success: 'Post notifications off', failed: 'Turn off post failed' },
      false
    );
  };

  const _unmutePost = async () => {
    const { isSuccess } = await unmutePost(currentFeed.id);
    _getPostById(isSuccess);
    setNotifResponse(
      isSuccess,
      { success: 'Post notifications on', failed: 'Turn on post failed' },
      false
    );
  };

  const _unsavePost = async () => {
    const { isSuccess } = await unsavePost(currentFeed.id);
    _getPostById(isSuccess);
    setNotifResponse(
      isSuccess,
      {
        success: 'This post has been removed from your Saved Posts',
        failed: 'Remove saved post failed'
      },
      false
    );
  };

  const _savePost = async () => {
    const body = {
      savedPost: {
        postId: currentFeed.id
      }
    };
    const { isSuccess } = await savePost(body);
    _getPostById(isSuccess);
    setNotifResponse(
      isSuccess,
      {
        success: 'This post has been added to your Saved Posts',
        failed: 'Save post failed'
      },
      false
    );
  };

  const _repostPost = async (groupId) => {
    const body = {
      repost: {
        groupId: groupId
      }
    };
    const { isSuccess } = await repostPost(currentFeed.id, body);
    setNotifResponse(
      isSuccess,
      { success: 'Successfully reposted', failed: 'Repost failed' },
      groupId === currentFeed.group.id
    );
  };

  const _reportPost = async () => {
    const { isSuccess } = await reportPost(currentFeed.id, reportData);
    if (isSuccess) {
      setReportData({ reason: '', comment: '' });
    }
    setNotifResponse(
      isSuccess,
      {
        success:
          'Thank you for notifying us. Our moderators have been alerted and will attend to the matter as soon as possible.',
        failed: 'Report failed'
      },
      false
    );
  };

  const updateFeed = async () => {
    const { data } = await getPostById(currentFeed.id);

    if (data) setCurrentFeed(data);
  };

  const pinOrHighlightPost = async (type) => {
    const { isSuccess } = await highlightPinnedPost(feed.id, type);
    if (isSuccess) {
      updateFeed();
      reload({ reloadHighlightPinned: !reloadHighlightPinned });
    }
    setNotifResponse(
      isSuccess,
      { success: `Successfully ${type} post`, failed: `${type} failed` },
      false
    );
  };

  const setModalFunction = (type) => {
    setModalType(type);
    setIsDialogOpen(false);
    switch (type) {
      case 'delete':
      case 'report':
        setIsModalOpen(true);
        break;
      case 'repost':
        getListGroupRepost();
        break;
      case 'save':
        _savePost();
        break;
      case 'unsave':
        _unsavePost();
        break;
      case 'off':
        _mutePost();
        break;
      case 'on':
        _unmutePost();
        break;
      case 'copy':
        copyPostId();
        break;
      case 'highlight':
      case 'unhighlight':
        pinOrHighlightPost(type);
        break;
      case 'unpinned':
        currentFeed.highlighted
          ? setIsModalOpen(true)
          : pinOrHighlightPost(type);
        break;
      case 'pinned':
        currentFeed.highlightable
          ? setIsModalOpen(true)
          : pinOrHighlightPost(type);
        break;
    }
  };

  const changeReportData = (reason = '', comment = '') =>
    setReportData({ reason: reason, comment: comment });

  const changeComment = (e) => {
    setReportData({ reason: reportData.reason, comment: e.target.value });
  };

  return (
    <>
      {toastData.isShow && (
        <ToastNotif
          showNotif={toastData.isShow}
          message={toastData.message}
          warning={toastData.isWarning}
        />
      )}
      <div
        className="feed-header flex justify-between"
        data-cy={`post-header-${postId}`}
      >
        <div className="flex w-[90%]" onClick={(e) => goToPost(e, feed)}>
          <Avatar
            name={currentFeedUser?.fullName}
            src={
              currentFeedUser?.profilePicture
                ? currentFeedUser?.profilePicture?.secureUrl
                : null
            }
            dataCy="user-profile"
            size={48}
            style={{ marginRight: '12px' }}
          />
          <div className="detail">
            <div className="name typography-h500" data-cy="user-name">
              {currentFeedUser?.fullName}
            </div>
            <div className="title-date typography-paragraph">
              {userPosition && (
                <>
                  <span data-cy="user-position">{userPosition}</span>
                  <span className="dot" />
                </>
              )}
              <span data-cy="post-date">{format(createdAt)}</span>
            </div>
            {currentFeedUser?.assignedBadge?.title && (
              <Badge
                bgColorHex={getCssVariableValue('--base-200')}
                className="typography-h100 h-[20px] w-max"
                iconUrl={currentFeedUser?.assignedBadge?.iconUrl}
                imageCN="w-[16px] h-[16px] mr-[4px]"
              >
                <span className="text-base-800">
                  {currentFeedUser?.assignedBadge?.title}
                </span>
              </Badge>
            )}
          </div>
        </div>
        <div className="relative mr-[24px]">
          <SVGIcon
            iconName="icon-keyboard_control"
            size="24"
            fillColor="var(--n-600)"
            onClick={() => setIsDialogOpen(true)}
          />
          {isDialogOpen && (
            <div className="dropdown-option-post" ref={dropdownOptionRef}>
              {listOption.map((opt, index) => (
                <div
                  key={index}
                  className="option py-[8px] flex items-center"
                  onClick={() => setModalFunction(opt.id)}
                >
                  <SVGIcon
                    iconName={opt.iconName}
                    size="24"
                    fillColor={opt?.color ? opt?.color : 'var(--n-600)'}
                    customClass="min-w-[24px]"
                  />
                  <span
                    className={`typography-button mt-[0px] ml-[8px] w-full ${
                      opt?.color && 'text-r-600'
                    }`}
                  >
                    {opt.name}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        {modalType === 'edit' && (
          <ModalPostPhoto
            type="edit"
            eventOnClick={() => setModalType('')}
            existingData={feed}
            groupDetail={feed?.group}
          />
        )}
        {modalType === 'delete' && isModalOpen && (
          <Modal
            className="w-[200px]"
            withCloseIcon={true}
            eventOnClickClose={() => setIsModalOpen(false)}
            title="Delete Post"
            description="Are you sure?"
            withPrimaryBtn={{
              danger: true,
              title: 'Yes',
              dataCy: 'confirm-btn',
              onClick: () => _deletePost(),
              isLoading: isLoading
            }}
            withSecondaryBtn={{
              title: 'No',
              dataCy: 'cancel-confirm-btn',
              onClick: () => setIsModalOpen(false)
            }}
          />
        )}
        {modalType === 'repost' && isModalOpen && (
          <Modal
            className="w-[400px]"
            withCloseIcon={true}
            eventOnClickClose={() => setIsModalOpen(false)}
            title="Repost post"
            description="Choose groups"
            withFooter={false}
          >
            <div className="overflow-hidden hover:overflow-auto h-[300px] mb-[24px]">
              {groupList.map((group, index) => (
                <div
                  key={index}
                  className="cursor-pointer py-[8px] group-option"
                  onClick={() => _repostPost(group.id)}
                >
                  <ProfileSection
                    name={group.name}
                    profilePicture={
                      group?.groupPicture?.secureUrl
                        ? group?.groupPicture?.secureUrl
                        : Config.asset.general.defaultGroupCover
                    }
                    desc={group.groupType.replace(/_/g, ' ')}
                    totalOwners={1}
                  />
                </div>
              ))}
            </div>
          </Modal>
        )}
        {modalType === 'report' && isModalOpen && (
          <Modal
            className="w-[300px]"
            withCloseIcon={true}
            eventOnClickClose={() => setIsModalOpen(false)}
            title="Report post"
            withPrimaryBtn={
              reportData?.reason && {
                title: 'Done',
                dataCy: 'report-btn',
                onClick: () => _reportPost()
              }
            }
            headerIcon={
              reportData?.reason && {
                name: 'icon-arrow_back_ios',
                color: 'var(--n-600)',
                onClick: () => changeReportData()
              }
            }
          >
            {!reportData?.reason ? (
              reportList.map((report, index) => {
                return (
                  <div
                    key={index}
                    className="py-[16px] border-bottom-n-400 cursor-pointer"
                    onClick={() => changeReportData(report.key)}
                  >
                    {report.title}
                  </div>
                );
              })
            ) : (
              <div>
                <p className="typography-h300 mb-[16px]">
                  {getCultureLocale('Additional Notes')}
                </p>
                <TextArea
                  name="comment"
                  onHandleChange={(e) => changeComment(e)}
                  textPlaceholder={getCultureLocale(
                    'Tell the reason why you report this post...'
                  )}
                  value={reportData.comment}
                  className="max-h-[100px] h-[100px]"
                />
              </div>
            )}
          </Modal>
        )}
        {(modalType === 'pinned' || modalType === 'unpinned') && isModalOpen && (
          <Modal
            className="w-[200px]"
            withCloseIcon={true}
            eventOnClickClose={() => setIsModalOpen(false)}
            title={`${capitalize(modalType)} Post`}
            description={
              modalType === 'pinned'
                ? 'If you choose Pin & Highlight, this post will be pinned in this group and highlighted on homepage banner'
                : 'This post is being highligted. If you unpin this post, it also will be removed from highlighted post'
            }
            withPrimaryBtn={{
              title:
                modalType === 'pinned'
                  ? 'Pin & Highlight'
                  : capitalize(modalType),
              dataCy: 'confirm-btn',
              onClick: () =>
                pinOrHighlightPost(
                  modalType === 'pinned' ? 'highlight' : modalType
                ),
              isLoading: isLoading
            }}
            withSecondaryBtn={{
              title: modalType === 'pinned' ? 'Pin Only' : 'Cancel',
              dataCy: 'cancel-confirm-btn',
              onClick: () =>
                modalType === 'pinned'
                  ? pinOrHighlightPost(modalType)
                  : setIsModalOpen(false)
            }}
          />
        )}
      </div>
    </>
  );
}

export default PostHeader;

import { toCamelCase } from 'utils/caseConverter';

import CableSocketManager from './socket-manager';

export default class SocketListener {
  register(channels) {
    this.channels = channels;
    if (this.channels instanceof Array) {
      for (let i = 0; i < this.channels.length; i++) {
        CableSocketManager.getInstance().subscribe(
          this.channels[i].name,
          this.callbackResultFormatter(this.channels[i].callback),
          this.channels[i].callbackConnected
        );
      }
    } else {
      CableSocketManager.getInstance().subscribe(
        this.channels?.name,
        this.callbackResultFormatter(this?.channels?.callback),
        this.channels?.callbackConnected
      );
    }
  }

  sendAction(channels) {
    this.channels = channels;
    CableSocketManager.getInstance().perform(
      this.channels?.name,
      this.channels?.action,
      this?.channels?.data,
      this?.channels?.callback
    );
  }

  unregister(autoDisconnect) {
    if (!this.channels) return;

    if (this.channels instanceof Array) {
      for (let i = 0; i < this.channels.length; i++) {
        CableSocketManager.getInstance().unsubscribe(
          this.channels[i].name,
          autoDisconnect
        );
      }
    } else {
      CableSocketManager.getInstance().unsubscribe(
        this.channels?.name,
        autoDisconnect
      );
    }
  }

  unregisterMatch(identifier) {
    CableSocketManager.getInstance().unsubscribeMatch(identifier);
  }

  getChannels() {
    return this.channels;
  }

  callbackResultFormatter(callback) {
    if (!callback) return () => null;
    return (result) => callback(toCamelCase(result));
  }
}

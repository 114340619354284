import React from 'react';

import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import get from 'lodash/get';

import { capitalize, getDateLocale, getNested } from 'utils/HelperUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import SVGIcon from 'components/shared/SVGIcon';

dayjs.extend(updateLocale);

const defaultLabel = (index, option, selectMultiple, props = {}) => {
  const {
    labelText,
    checkMark,
    labelDescription,
    labelSize,
    labelStartDate,
    labelEndDate,
    useGap,
    containerClass
  } = props;
  let text;

  if (typeof option === 'object') {
    text = getNested(option, labelText);
  } else {
    text = option;
  }

  const gridContainer = {
    display: 'grid',
    gridTemplateColumns: checkMark ? `1fr 24px` : '1fr',
    alignItems: 'center',
    width: '100%',
    gap: useGap ? '8px' : '0px'
  };

  return (
    <>
      <div style={gridContainer} className={containerClass}>
        <span
          className={`${
            labelSize ? labelSize : 'typography-paragraph'
          } mt-[0px] capitalize ${
            selectMultiple ? 'ml-[8px]' : ''
          } whitespace-pre-wrap`}
        >
          {text ? getObjectiveLocale(capitalize(text)) : '-'}
        </span>
        {option[labelDescription] && (
          <span className="text-n-800">
            {option[labelDescription] && capitalize(option[labelDescription])}
          </span>
        )}
        {(option[labelStartDate] || option[labelEndDate]) && (
          <span className="typography-paragraph text-n-600">
            {option[labelStartDate]
              ? getDateLocale(option[labelStartDate])
              : 'Now'}{' '}
            -{' '}
            {option[labelEndDate] ? getDateLocale(option[labelEndDate]) : 'Now'}
          </span>
        )}
        {checkMark && <SVGIcon iconName="icon-done" size="24" />}
      </div>
    </>
  );
};

const imageLabel = (index, option, selectMultiple, props = {}, dataCy) => {
  const {
    imgSrc,
    imgSize,
    fillColor,
    labelText,
    dataCyPrefix,
    checkMark,
    imgColorKey
  } = props;
  let img, text, imgColor;
  dataCy = dataCy || dataCyPrefix || 'image-label';

  if (typeof option === 'object') {
    img = getNested(option, imgSrc);
    text = getNested(option, labelText);
    imgColor = get(option, imgColorKey, fillColor);
  } else {
    img = imgSrc;
    text = option;
    imgColor = fillColor;
  }

  const gridContainer = {
    display: 'grid',
    gridTemplateColumns: `${imgSize}px 1fr ${checkMark ? '24px' : ''}`,
    alignItems: 'center',
    width: '100%',
    gap: '8px'
  };

  return (
    <>
      <div style={gridContainer} data-cy={`${dataCy}-${index}`}>
        <SVGIcon
          customClass={selectMultiple ? 'ml-[8px]' : ''}
          size={imgSize}
          iconName={img}
          fillColor={fillColor}
        />
        <span
          className="typography-paragprah mt-[0px] w-full"
          data-cy={dataCy?.item}
        >
          {capitalize(text)}
        </span>
        {checkMark && <SVGIcon iconName="icon-done" size="24" />}
      </div>
    </>
  );
};

const initialNameLabel = (
  index,
  option,
  selectMultiple,
  props = {},
  dataCy
) => {
  const {
    imgSize,
    labelText,
    isMultiline,
    labelSubtitle,
    nameSrc,
    profilePictureSrc,
    startDateSrc,
    dueDateSrc,
    checkMark
  } = props;
  dayjs.updateLocale(dayjs.locale(), {
    invalidDate: 'no due date'
  });
  let startDate = dayjs(option?.[startDateSrc]).format('MMM, DD YYYY');
  let dueDate = dayjs(option?.[dueDateSrc]).format('MMM, DD YYYY');
  const gridContainer = {
    display: 'grid',
    gridTemplateColumns: `${imgSize}px 1fr 24px`,
    gap: '8px',
    alignItems: 'center'
  };

  return (
    option && (
      <div
        style={gridContainer}
        className={`${selectMultiple ? 'ml-[8px]' : ''}`}
      >
        <div className="flex items-start h-full">
          <Avatar
            name={getNested(option, nameSrc)}
            src={getNested(option, profilePictureSrc)}
            size={imgSize}
          />
        </div>
        <div className={`flex flex-col overflow-hidden`}>
          <span
            className={`typography-h400-longform text-n-900 mt-[0px] w-100 ${
              isMultiline ? '' : 'text-ellipsis'
            }`}
            data-cy={dataCy?.item}
          >
            {option[labelText]}
          </span>
          {option[labelSubtitle] && (
            <span className="typography-h100 text-n-800 mt-[0px]">
              {option[labelSubtitle]}
            </span>
          )}
          {option[startDateSrc] && (
            <span className="typography-h100 text-n-800 mt-[4px] text-ellipsis w-100">
              {startDate}
              {option[dueDateSrc] && ` - ${dueDate}`}
            </span>
          )}
        </div>
        {checkMark && <SVGIcon iconName="icon-done" size="24" />}
      </div>
    )
  );
};

const imageSubMenu = (index, option, selectMultiple, props = {}, dataCy) => {
  const {
    imgSize,
    labelText,
    labelSubtitle,
    nameSrc,
    idSrc,
    profilePictureSrc
  } = props;
  dayjs.updateLocale(dayjs.locale(), {
    invalidDate: 'no due date'
  });
  const gridContainer = {
    display: 'grid',
    gridTemplateColumns: `${imgSize}px 190px 24px`,
    gap: '8px',
    alignItems: 'center',
    width: '100%'
  };

  return (
    option && (
      <>
        <div id={`sub-menu-templates-${option[idSrc]}`} />
        <div
          style={gridContainer}
          className={`${selectMultiple ? 'ml-[8px]' : ''}`}
        >
          <Avatar
            name={getNested(option, nameSrc)}
            src={getNested(option, profilePictureSrc)}
            size={imgSize}
          />
          <div className={`inline-flex flex-col overflow-hidden`}>
            <span
              className="typography-paragprah mt-[0px] truncate w-full"
              data-cy={dataCy?.item}
            >
              {option[labelText]}
            </span>
            {option[labelSubtitle] && (
              <span className="typography-paragprah mt-[0px]">
                {option[labelSubtitle]}
              </span>
            )}
          </div>
          <SVGIcon
            size="16"
            iconName="icon-arrow_right"
            fillColor="var(--n-600)"
          />
        </div>
      </>
    )
  );
};

const ListItemTemplates = {
  default: defaultLabel,
  'img-label': imageLabel,
  'img-init-name-label': initialNameLabel,
  'img-sub-menu': imageSubMenu
};

export default ListItemTemplates;

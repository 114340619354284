import React, { useEffect, useState } from 'react';

import { putCustomAttribute } from 'client/admin/ReviewGroupClient.js';
import useDebounce from 'hooks/useDebounce';

import Table from 'components/design-system/table/Table';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';

const AttributeListTableCard = ({
  data,
  currentFilterTab,
  setModalAction,
  setIsModalCreateAttributeOpen,
  setDataEdit,
  setModalConfirmationOpen,
  showSuccessToast
}) => {
  const [weight, setWeight] = useState(data.weight || 0);
  const [isWeightUpdated, setWeightUpdated] = useState(false);
  const debouncedWeightValue = useDebounce(weight, 500);

  const onHandleSave = async () => {
    if (isWeightUpdated) {
      const { isSuccess } = await putCustomAttribute(0, {
        review_aspects_attributes: [{ id: data.id, weight }]
      });
      if (isSuccess) {
        showSuccessToast();
      }
    }
  };

  const updateWeight = (value) => {
    setWeight(value);
    setWeightUpdated(true);
  };

  const moveState = async () => {
    setDataEdit(data);
    setModalConfirmationOpen(true);
  };

  const editHandler = () => {
    setDataEdit(data);
    setModalAction('edit');
    setIsModalCreateAttributeOpen(true);
  };

  useEffect(() => {
    onHandleSave();
  }, [debouncedWeightValue]);

  return (
    <Table.Row
      dataCy={`attribute-${data.id}`}
      customClass="py-16"
      heightClass="h-auto"
    >
      <Table.Column>
        <div className="flex template-name row-wrapper-content">
          <p className="h400">{data.name}</p>
          <p className="h100">{data.default ? 'Default' : 'Custom'}</p>
        </div>
      </Table.Column>
      <Table.Column
        customClass={`h-auto ${!data.description ? 'text-n-600' : ''}`}
      >
        {data.description ? data.description : '--'}
      </Table.Column>
      <Table.Column>
        {currentFilterTab == 'active' && (
          <div className="w-[104px] pr-16 row-wrapper-content">
            <InputField
              className="!w-[72px]"
              type="number"
              inputClass="input-attribute-weight"
              defaultValue={data.weight || 0}
              handleChange={(e) => updateWeight(Number(e.target.value))}
              min="0"
              dataCy={`attribute-weight-${data.id}`}
            />
          </div>
        )}
      </Table.Column>
      <Table.Column>
        <div className="!mt-[5px] action-wrapper">
          {currentFilterTab == 'active' ? (
            <>
              <SVGIcon
                iconName="icon-pencil"
                size="24"
                fillColor="var(--n-600)"
                customClass="w-[56px] invisible group-hover:visible cursor-pointer"
                dataCy={`attribute-edit-${data.id}`}
                onClick={editHandler}
              />
              <SVGIcon
                iconName="icon-archive"
                size="24"
                fillColor="var(--n-600)"
                customClass="w-[56px] invisible group-hover:visible cursor-pointer"
                onClick={moveState}
                dataCy={`attribute-archive-${data.id}`}
              />
            </>
          ) : (
            <SVGIcon
              iconName="icon-reply"
              size="24"
              fillColor="var(--n-600)"
              customClass="hidden group-hover:flex cursor-pointer"
              onClick={moveState}
              dataCy={`attribute-activate-${data.id}`}
            />
          )}
        </div>
      </Table.Column>
    </Table.Row>
  );
};

export default AttributeListTableCard;

import React, { useRef } from 'react';

import useClickOutside from 'hooks/useClickOutside';

const MenuOutsideClickWrapper = ({
  children,
  event,
  customClass,
  menuAlignObjective,
  whitelistRef
}) => {
  const ref = useRef();
  const refList = whitelistRef ? [ref, whitelistRef] : ref;
  useClickOutside(
    refList,
    () => {
      event && event();
    },
    menuAlignObjective
  );

  return (
    <div className={customClass} ref={ref}>
      {children}
    </div>
  );
};

export default MenuOutsideClickWrapper;

import React, { Fragment } from 'react';
import { withRouter } from 'react-router';

import { UsersAdminProvider } from 'context/admin/UsersAdminContext';

import ActiveUsers from 'components/admin/usermanagement/ListUsers';

function ListUsersWrapper({ route: { isReadOnly }, ...props }) {
  const type = props.match.params.type;

  return (
    <>
      {
        <UsersAdminProvider>
          <ActiveUsers listType={type} isReadOnly={isReadOnly} />
        </UsersAdminProvider>
      }
    </>
  );
}

export default withRouter(ListUsersWrapper);

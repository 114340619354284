import React, { createContext, useContext, useState } from 'react';

const SurveyContext = createContext();
const useSurveyContext = () => useContext(SurveyContext);

const SurveyProvider = ({ children }) => {
  const [surveyId, setSurveyId] = useState(null);
  const [occurrenceId, setOccurrenceId] = useState(null);

  return (
    <SurveyContext.Provider
      value={{ surveyId, setSurveyId, occurrenceId, setOccurrenceId }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

export { useSurveyContext, SurveyProvider };

import React, { useRef, useState } from 'react';

import { createAttachment, createComment } from 'client/ObjectivesClient';
import S3Client from 'client/S3Client';
import { getPerformancePreSignUrl } from 'client/UploadClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import {
  formatBytes,
  getObjectiveLocale,
  mappingExtension
} from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import TextAreaWithMention from 'components/shared/TextAreaWithMention/TextAreaWithMention';
import Modal from 'components/shared/modal/Modal';

import AttachmentList from './objective-detail/AttachmentList';

function CommentBox({
  id,
  includes = [],
  clearEditor,
  setClearEditor,
  onSubmit
}) {
  const [metaMentions, setMetaMentions] = useState([]);
  const [comment, setComment] = useState(null);
  const [listOfFiles, setListOfFiles] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [onUpload, setOnUpload] = useState(false);
  const [onAlert, setOnAlert] = useState(false);
  const { user, config } = useUser();
  const { addToast } = useToastContext();

  const inputFileRef = useRef();
  let allowAttachmentExtensions = config?.allowedAttachmentExtensions
    ? mappingExtension(config?.allowedAttachmentExtensions)
    : [];
  const { refetchQueries, invalidateQueries } = useRefetchQuery();
  let isValid = !comment && listOfFiles.length > 0;
  const _getPresignUrl = async (file) => {
    const filename = file.name;
    const extensions = filename.split('.').pop();

    const presign_query = {
      public: true,
      directory: 'files',
      extension: extensions
    };

    const { data, isSuccess } = await getPerformancePreSignUrl(presign_query);

    if (!isSuccess) {
      return addToast({
        msg: 'Failed to attach file',
        type: 'error'
      });
    }

    return data;
  };

  const uploadToServer = async (url, file) => {
    const { status } = await S3Client(url, file);
    return status;
  };

  const onSubmitComment = async () => {
    const metaMentionsPayload = metaMentions.map((value) => {
      return {
        userId: value.userId,
        startIndex: value.startIndex,
        length: value.length
      };
    });

    if (listOfFiles?.length == 0) {
      setOnLoading(true);
      const body = {
        comment: comment,
        metaMentions: metaMentionsPayload
      };

      await createComment(id, body);
      setOnLoading(false);
      invalidateQueries(['objective', 'activities', parseInt(id)]);
      refetchQueries(['objective', parseInt(id)]);
    } else {
      setOnUpload(true);
      const promises = listOfFiles.map(async (value) => {
        if (value) {
          const url = await _getPresignUrl(value);
          if (!url) return;
          const status = await uploadToServer(url?.uploadUrl, value);
          if (status == 200) {
            const body = {
              comment: comment,
              filename: value.name,
              file_timestamp: new Date(value.lastModified),
              path: url.path,
              metaMentions: metaMentionsPayload,
              public: true
            };

            await createAttachment(id, body);
          }
        }
      });
      await Promise.all(promises);
      setOnUpload(false);
      setListOfFiles([]);
      invalidateQueries(['objective', 'activities', parseInt(id)]);
      refetchQueries(['objective', parseInt(id)]);
    }
    onSubmit && onSubmit();
  };

  const attachFile = (e) => {
    let list = [];
    for (let i = 0; i < e.target.files.length; i++) {
      list.push(e.target.files[i]);
    }

    setListOfFiles(list);
  };

  const getCommentText = () => {
    let text;
    if (onUpload) {
      text = 'Uploading...';
    } else if (onLoading) {
      text = 'Loading...';
    } else {
      text = getObjectiveLocale('Comment');
    }
    return text;
  };

  const deleteFile = (e, index) => {
    let newListOfFiles = [...listOfFiles];
    newListOfFiles.splice(index, 1);
    setListOfFiles(newListOfFiles);
  };

  return (
    <section className="comment relative" data-cy="comment-box">
      {onAlert && (
        <Modal
          title="Something went wrong"
          eventOnClickClose={() => setOnAlert(false)}
          dataCyModal="unsupported-file-modal-error"
          description="Please input files with supported types only"
        />
      )}
      <TextAreaWithMention
        currentUserId={user?.id}
        setComment={setComment}
        metaMentions={metaMentions}
        setMetaMentions={setMetaMentions}
        isValid={isValid}
        includes={includes}
        clearEditor={clearEditor}
        setClearEditor={setClearEditor}
      />
      <div className="mt-[8px] mb-[8px]">
        {listOfFiles &&
          listOfFiles.map((file, index) => {
            return (
              <AttachmentList
                key={index + file.name}
                fileName={file.name}
                fileSize={formatBytes(file.size)}
                handleDelete={() => deleteFile(index)}
                dataCy={`attached-file-${index}`}
              />
            );
          })}
      </div>
      <div className="mt-[16px] flex justify-between items-center">
        <div>
          <Button.Tertiary
            onClick={() => {
              inputFileRef.current.click();
            }}
          >
            <SVGIcon
              iconName="icon-attach_file"
              size="24"
              fillColor="var(--base-600)"
              customClass="mr-[4px]"
            />
            {getObjectiveLocale('Objective Attach File')}
          </Button.Tertiary>
          <input
            ref={inputFileRef}
            className="hidden"
            id="attachment-input"
            type="file"
            accept={allowAttachmentExtensions}
            multiple
            onClick={(e) => (e.target.value = null)}
            onChange={(e) => attachFile(e)}
            data-cy="attach-file"
          />
        </div>
        <Button
          disabled={onLoading || onUpload || comment == '' ? true : false}
          onClick={onSubmitComment}
          datacy="button-comment"
        >
          {getCommentText()}
        </Button>
      </div>
    </section>
  );
}

export default CommentBox;

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

import { UseAuthPage } from 'context/AuthPageContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import env from 'utils/env';

const AuthForgotPassword = (props) => {
  const { handleOnChange } = UseAuthPage();
  const { organization, appType } = useUser();

  const appName =
    appType === 'culture'
      ? organization?.cultureAppName
      : organization?.appName;
  let method =
    env.AUTHENTICATION_METHOD == 'username_password' ? 'Username' : 'Email';

  useEffect(() => {
    let input = document.getElementById('email');
    input?.focus();
  }, []);

  return props.isSuccessForgotPassword ? (
    <React.Fragment>
      <span className="auth-title text-center w-[304px] typography-h400-longform">
        {appName || getObjectiveLocale('Forgot Password')}
      </span>
      <span className="auth-text">
        {getObjectiveLocale(
          'Enter your email address associated with your organization/company & we’ll send you link to create/reset password.'
        )}
      </span>
      <div className="container-input">
        <label className="styled-label" htmlFor={'email'}>
          {getObjectiveLocale('Email')}
        </label>
        <input
          className="styled-input"
          data-cy="forgot-email"
          name={'email'}
          id={'email'}
          type={'text'}
          onChange={(e) => handleOnChange(e.target.name, e.target.value)}
          onKeyUp={props.handleKeyPress}
          placeholder={getObjectiveLocale('Email Address')}
        />
      </div>
      {props.showWarning && (
        <div className="container-error">
          <span data-cy="wrong-password" className="error-message">
            {props.warningMessage}
          </span>
        </div>
      )}
    </React.Fragment>
  ) : (
    <div className="auth-notif-body">
      <span data-cy="sent-email" className="auth-notif-title">
        {getObjectiveLocale('Email Sent!')}
      </span>
      <span className="auth-notif-subtitle">
        {getObjectiveLocale(
          'Link to reset your password has been sent to your email'
        )}
      </span>
      <button
        className="btn btn-primary footer-link-button auth-notif-button"
        onClick={props.routeToOrganization}
      >
        {getObjectiveLocale('Back to login')}
      </button>
    </div>
  );
};

AuthForgotPassword.propTypes = {
  handleKeyPress: PropTypes.func,
  routeToOrganization: PropTypes.func,
  showWarning: PropTypes.bool,
  isSuccessForgotPassword: PropTypes.bool,
  warningMessage: PropTypes.string
};

AuthForgotPassword.contextTypes = {
  handleChange: PropTypes.func
};

export default withRouter(AuthForgotPassword);

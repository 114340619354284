import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/shared/modal/Modal';

const ModalSuccessSaveTemplate = ({ setIsModalSuccessSaveTemplateOpen }) => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      _onClick();
    }, 2000);
  }, []);

  const _onClick = () => {
    setIsModalSuccessSaveTemplateOpen(false);
    history.push('/reviews/rating-template');
  };

  const modalProps = {
    title: 'Config Saved',
    withCloseIcon: true,
    eventOnClickClose: () => _onClick(),
    withPrimaryBtn: {
      title: 'Ok',
      dataCy: 'success-save-template',
      onClick: () => _onClick()
    },
    dataCyModal: 'modal-success-save-template'
  };

  return (
    <Modal {...modalProps}>
      <p>Your configuration have been saved</p>
    </Modal>
  );
};

export default ModalSuccessSaveTemplate;

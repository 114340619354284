import React from 'react';
import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import { format } from 'timeago.js';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getObjectiveInvolvements } from 'utils/ObjectivesHelper';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';
import Badge from 'components/shared/Badge';
import GroupingCardLabel from 'components/shared/Label/GroupingCardLabel';
import TruncateParagraph from 'components/shared/TruncateParagraph';
import SVGIcon from 'src/components/shared/SVGIcon';
import { getCurrentUserRole } from 'src/utils/TeamHelper';

const SquadCard = ({ team }) => {
  const { id, name, involvements, updatedAt, parent, archivedAt } = team || {};
  const { user: currentUser } = useUser();
  const history = useHistory();

  const openTeamDetail = () => {
    history.push(`/teams/squad/${id}`);
  };

  const leader = getObjectiveInvolvements(involvements, 'leader')[0]?.user;

  const getLabel = () => {
    const role =
      involvements?.length > 0 &&
      getCurrentUserRole(involvements, currentUser?.id);

    return [
      {
        useBadge: false,
        title: `As ${role}`,
        iconLabel: role && role === 'leader' ? 'leader' : 'member',
        isVisible: !isEmpty(role),
        dataCy: 'label-role'
      },
      {
        useBadge: false,
        title: format(updatedAt),
        iconLabel: 'history',
        isVisible: true,
        useMaxWidth: '!max-w-[240px]',
        dataCy: 'history'
      }
    ].filter(({ isVisible }) => isVisible);
  };

  const listLabel = getLabel();

  const listMembers = involvements
    ?.filter(({ extendedRole }) => extendedRole !== 'leader')
    ?.map((involvement) => ({
      name: involvement?.user?.name,
      src: involvement?.user?.profilePicture
    }));

  return (
    <div
      className="py-[16px] px-[16px] bg-n-000 shadow-raised rounded-[4px] cursor-pointer relative group flex flex-col"
      onClick={openTeamDetail}
      data-cy={`squad-card-${id}`}
    >
      {parent && (
        <div className="flex items-center mb-[8px] h-[16px] ">
          <SVGIcon
            iconName="icon-arrow_upward"
            size="16"
            fillColor="var(--n-600)"
          />
          <p className="ml-[8px] typography-h00 text-n-600">
            {getObjectiveLocale('Tribe')}: {parent.name}
          </p>
        </div>
      )}
      {!!archivedAt && (
        <Badge
          className="rounded-[4px] typography-h100 text-n-800 bg-n-300 absolute right-[16px]"
          content={getObjectiveLocale('Archived')}
        />
      )}

      <div className="max-w-[324px]">
        <TruncateParagraph
          className="typography-h500 text-n-900"
          position="bottom"
          dataCy="squad-name"
        >
          {name}
        </TruncateParagraph>
      </div>

      <div className="mt-[8px] mb-[auto] inline-flex gap-12 items-center">
        {listLabel.map((label, index) => (
          <GroupingCardLabel isSquad label={label} key={index} />
        ))}
      </div>
      <div className="mt-[16px] flex items-center justify-between">
        <div className="w-[calc(100%-84px)]">
          {leader && <SingleUser userData={leader} customClass="w-full" />}
        </div>
        {involvements?.length > 1 && (
          <AvatarGroup avatars={listMembers} size="24" max={3} />
        )}
      </div>
    </div>
  );
};

export default SquadCard;

import React, { useLayoutEffect } from 'react';

import { updateBulkObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

const AdjustWeightLockedModal = ({ eventOnClick, objectives }) => {
  const { weight, objectiveCategory, id } = objectives[0];
  const { weight: objWeight } = objectiveCategory;
  const { refetchObjective } = useRefetchQuery();

  useLayoutEffect(() => {
    const el = document.getElementById('designated-text');
    const text = getObjectiveLocale(
      'The weight of this goal type is <b>locked by system</b> at <b>[weight_value]%</b>. Do you want to change the weight?'
    ).replace('[weight_value]', objWeight);
    el.innerHTML = text;
  }, [objWeight]);

  const submitEvent = async () => {
    const payload = {
      comment:
        'Adjust weight to adapt with new locked percentage set by system',
      objectives: [
        {
          id,
          weight: objWeight
        }
      ]
    };
    const { isSuccess } = await updateBulkObjective(payload);

    eventOnClick && eventOnClick();

    isSuccess && refetchObjective(id);
  };

  return (
    <Modal
      title={getObjectiveLocale('Change goal weight?')}
      eventOnClickClose={eventOnClick}
      className="w-[400px]"
      withPrimaryBtn={{
        title: getObjectiveLocale('Yes, Change'),
        dataCy: 'submit-change-assignment',
        onClick: submitEvent,
        isLoading: false
      }}
      withSecondaryBtn={{
        title: getObjectiveLocale('Cancel'),
        dataCy: 'cancel',
        onClick: eventOnClick
      }}
    >
      <p className="typography-paragraph text-n-800" id="designated-text" />
      <div className="h-40 bg-y-200 flex items-center mt-[16px] rounded-lg px-[17px]">
        <SVGIcon
          iconName="icon-warning"
          fillColor="var(--y-600)"
          size="24"
          customClass="mr-[16px]"
        />
        <p className="text-n-900 typography-paragraph">
          {getObjectiveLocale('Your current weight is')} {weight}%
        </p>
      </div>
    </Modal>
  );
};

export default AdjustWeightLockedModal;

import { create } from 'zustand';

const useMultipleEdit = create((set) => ({
  selectedObjective: [],
  startId: null,
  updateStartId: (id) => set(() => ({ startId: id })),
  updateSelected: (objective) =>
    set((state) => ({
      selectedObjective: [objective, ...state.selectedObjective]
    })),
  removeSelected: (id) =>
    set((state) => ({
      selectedObjective: state.selectedObjective.filter(
        (selectedObjective) => selectedObjective.id !== id
      )
    })),
  removeAllSelected: () => set({ selectedObjective: [] }),
  closeSelected: () =>
    set({ selectedObjective: [], startId: null, endIndex: null })
}));

export default useMultipleEdit;

import client from './ApiClient';

function getAttachment(objectiveId, params = {}) {
  let url = `v1/objectives/${objectiveId}/attachments`;
  return client(url, { params });
}

function deleteAttachment(objectiveId, dataId) {
  let url = `v1/objectives/${objectiveId}/attachments/${dataId}`;
  return client(url, { method: 'DELETE', body: {} });
}

export { getAttachment, deleteAttachment };

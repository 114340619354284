import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import startCase from 'lodash/startCase';

import { createCycle, updateCycle } from 'client/admin/CyclesClient';
import {
  CreateCycleProvider,
  useCreateCycle
} from 'context/admin/CreateCycleContext';
import { UsersAdminProvider } from 'context/admin/UsersAdminContext';
import useDebounce from 'hooks/useDebounce';
import { convertStateToParam } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Footer from 'components/shared/Footer';
import ToastNotif from 'components/shared/ToastNotif';
import VerticalStepsComponent from 'components/shared/VerticalStepsComponent';

import ConfigureCalibration from './ConfigureCalibration';
import ConfigureDeliverables from './ConfigureDeliverables';
import './CreateCycle.scss';
import SetName from './SetName';
import SetPhases from './SetPhases';
import SetReviewee from './SetReviewee';
import SetUpSchedule from './SetUpSchedule';
import Verify from './Verify';
import ModalDisablePhase from './components/ModalDisablePhase';

function CreateCycle() {
  const PHASES = [
    'selfReview',
    'peerReview',
    'upwardReview',
    'managerReview',
    'indirectManagerReview'
  ];
  const [steps, setSteps] = useState([
    { name: 'Set Up Name', completed: false, disabled: false },
    { name: 'Set Up Phases', completed: false, disabled: false },
    { name: 'Set Reviewee', completed: false, disabled: false },
    { name: 'Configure Calibration', completed: false, disabled: false },
    { name: 'Configure Deliverables', completed: false, disabled: false },
    { name: 'Set Up Schedule', completed: false, disabled: false },
    { name: 'Verify', completed: false, disabled: false }
  ]);
  const [showNotif, setShowNotif] = useState(false);
  const [showModalDeactivate, setShowModalDeactivate] = useState({
    data: {},
    show: false
  });
  const [notifMessage, setNotifMessage] = useState('');
  const [active, setActive] = useState('Set Up Name');
  const [saveStatus, setSaveStatus] = useState(null);
  const [enableAutoSave, setEnableAutoSave] = useState(false);
  const params = useParams();
  const history = useHistory();
  const {
    cycleData,
    getCycleData,
    setCycleId,
    changeCycleName,
    changeTotalSelectedUser
  } = useCreateCycle();
  const debouncedCycleData = useDebounce(cycleData, 1000);
  const pathShowCycles = location.pathname.includes('show-cycles');

  const usp = new URLSearchParams(window.location.search);
  const stepParams = usp.get('step');

  const toogleTab = (name) => {
    if (!params.id) return;

    history.push(
      `/appraisals/cycles/${params.id}/edit?step=${convertStateToParam(name)}`
    );
    setActive(name);
  };

  const handleFirstSave = async (name, isCopyAction = false) => {
    const body = {
      name: name ? name : cycleData.name
    };

    const { data, error } = await createCycle(body);
    if (data) {
      !isCopyAction && setActive('Set Up Phases');
      setEnableAutoSave(true);
      setCycleId(data.id);
      history.replace('/appraisals/cycles/' + data.id + '/edit');
    }

    if (history.location.pathname.includes('show-cycles')) {
      setActive('Verify');
    }
  };

  const updateLastStep = async () => {
    if (cycleData.id) {
      const body = {
        lastStep: active
      };
      await updateCycle(cycleData.id, body);
    }
  };

  const destructTrackConfigs = (data) => {
    PHASES.forEach((phase) => {
      if (data[phase]) {
        data[phase].trackConfigs = data[phase]?.trackConfigs?.map(
          (track, index) => {
            const track_template_id = track?.template?.id;
            delete track.template;
            delete track.selected;
            delete track?.savedPreFill;

            return { ...track, track_template_id, order: index + 1 };
          }
        );
      }
    });
  };

  const destructureParams = () => {
    let data = JSON.parse(JSON.stringify(cycleData));
    delete data.id;
    delete data.includeCalibration;
    delete data.dependencies;
    delete data.totalSelectedUser;
    delete data.state;
    if (!data.disclosure) delete data.disclosure;

    const start = cycleData.reviewedPeriodStartsAt.startOf('day').toISOString();
    const end = cycleData.reviewedPeriodEndsAt.endOf('day').toISOString();

    data.reviewedPeriodStartsAt = start;
    data.reviewedPeriodEndsAt = end;
    data.restrictedTrackNavigation = cycleData.restrictedTrackNavigation;
    data.ratingTemplateId = cycleData?.ratingTemplate?.id;
    delete data.ratingTemplate;
    destructTrackConfigs(data);

    if (data.calibration) {
      data.calibration['starts_at'] = null;
      data.calibration['ends_at'] = null;
    }

    return data;
  };

  const handleAutoSave = async (type) => {
    setSaveStatus('saving...');
    const newParams = destructureParams();
    const { data } = await updateCycle(cycleData.id, newParams);
    if (type === 'autosave') {
      if (data) {
        changeTotalSelectedUser(data.revieweeIds ? data.revieweeIds.length : 0);
        setSaveStatus('saved...');
      } else {
        setSaveStatus('Failed!');
      }
    } else if (type === 'verify') {
      if (data) {
        setShowNotif(true);
        setNotifMessage('Save Cycle Success');
        setTimeout(() => {
          setShowNotif(false);
          history.replace('/appraisals/cycles');
        }, 2000);
      }
    }
  };

  const _onClickModalDeactivate = () => {
    const { onClick } = showModalDeactivate;
    onClick && onClick();
    setShowModalDeactivate({ show: false });
  };

  const _getCycle = async () => {
    const data = await getCycleData(params.id);
    if (data) {
      if (history.location.pathname.includes('copy-cycles')) {
        let copyName = data.name + ' (Copy)';
        setEnableAutoSave(false);
        handleFirstSave(copyName, true);
        changeCycleName(copyName);
      } else {
        // if there is a step param ex: ?step=set-up-schedule -> set the query to setActive
        if (stepParams) {
          // convert 'set-up-schedule' to 'Set Up Schedule', and set state
          setActive(startCase(stepParams));
        } else {
          const activeArr = steps.filter((step) => data.lastStep === step.name);
          if (activeArr.length > 0) {
            setActive(activeArr[0].name);
          } else {
            setActive('Set Up Name');
          }
        }

        setTimeout(() => {
          setEnableAutoSave(true);
        }, 2000);
      }
    }
  };

  const currentIndex = steps.findIndex((step) => step.name === active);

  const handleNextButton = () => {
    switch (active) {
      case 'Set Up Name':
        return handleFirstSave();
      case 'Verify':
        return handleAutoSave('verify');
      default:
        break;
    }

    const nextIndex = currentIndex + 1;
    setActive(steps[nextIndex].name);
  };

  const handleBackButton = () => {
    const previousIndex = currentIndex - 1;
    setActive(steps[previousIndex].name);
  };

  useEffect(() => {
    if (params.id) {
      _getCycle();
    }
  }, []);

  useEffect(() => {
    if (enableAutoSave && saveStatus !== 'saving...') {
      handleAutoSave('autosave');
    }
  }, [debouncedCycleData, active]);

  useEffect(() => {
    if (!pathShowCycles) {
      updateLastStep();
    } else if (pathShowCycles) {
      setSteps([{ name: 'Verify', completed: false, disabled: false }]);
    }

    const mainBody = document.getElementById('main-body');
    if (mainBody) {
      mainBody.style.overflow = active == 'Set Up Phases' ? 'hidden' : 'auto';
    }
  }, [active]);

  return (
    <>
      <HeaderPage
        titlePage={`Create Cycle - ${cycleData.name || 'New Cycles'}`}
        saveStatus={saveStatus}
        isHeaderComposer={true}
        backToUrl={'/appraisals/cycles'}
      />
      {showNotif && <ToastNotif showNotif={showNotif} message={notifMessage} />}
      <div className="dashboard_content_cycles bg-n-000 min-h-[calc(100vh-64px)]">
        <div>
          <VerticalStepsComponent
            activeStep={active}
            setActiveStep={(name) => toogleTab(name)}
            steps={steps}
          />
          <div className="body-for-cycles">
            <div>
              {active === 'Set Up Name' && (
                <div id="setName">
                  <SetName
                    toogleTab={toogleTab}
                    handleFirstSave={handleFirstSave}
                    useButton={true}
                    cycleData={cycleData}
                    changeCycleName={changeCycleName}
                  />
                </div>
              )}
              {active == 'Set Up Phases' && (
                <div id="setPhases">
                  <SetPhases setShowModalDeactivate={setShowModalDeactivate} />
                </div>
              )}
              {active === 'Set Reviewee' && (
                <div id="setReviewee">
                  <UsersAdminProvider>
                    <SetReviewee saveStatus={saveStatus} />
                  </UsersAdminProvider>
                </div>
              )}
              {active === 'Configure Calibration' && (
                <div id="configureCalibration">
                  <ConfigureCalibration />
                </div>
              )}
              {active === 'Configure Deliverables' && (
                <div id="configureDeliverables">
                  <ConfigureDeliverables />
                </div>
              )}
              {active === 'Set Up Schedule' && <SetUpSchedule />}
              {active === 'Verify' && (
                <div id="verify">
                  <Verify
                    mode={pathShowCycles ? 'show' : 'edit'}
                    toogleTab={toogleTab}
                    handleSaveCycle={handleAutoSave}
                    cycleData={cycleData}
                  />
                </div>
              )}
              <Footer
                linkButton={true}
                style={{ position: 'fixed', bottom: '0', left: '0' }}
                handleClick={() => handleNextButton()}
                handleRoute={() => handleBackButton()}
                buttonPrimaryName={active === 'Verify' ? 'Save' : 'Next'}
                linkButtonName="Back"
                buttonPrimaryDisabled={cycleData.name === null ? true : false}
                linkButtonDisabled={active === 'Set Up Name' ? true : false}
              />
            </div>
          </div>
          {showModalDeactivate.show && (
            <ModalDisablePhase
              onClickDisablePhase={_onClickModalDeactivate}
              onCloseModal={() => setShowModalDeactivate({ show: false })}
            />
          )}
        </div>
      </div>
    </>
  );
}

const CreateCycleWrapper = () => {
  return (
    <CreateCycleProvider>
      <CreateCycle />
    </CreateCycleProvider>
  );
};

export default CreateCycleWrapper;

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { listSelectionColors } from 'utils/SelectionColorUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';
import ConditionalWrapper from 'src/components/shared/ConditionalWrapper';

const dragPortal = document.querySelectorAll(`[id^="shared-drag"]`)[0];

const CardPhase = ({
  phase,
  containerClass,
  isCompletedPhase,
  onClickDeletePhase,
  onChangePhaseName,
  onChangePhaseColor,
  showIconDelete,
  autoFocus = false,
  index,
  isDraggable = false,
  provided,
  snapshot
}) => {
  const { id, name, bgColorHex, colorHex, isNew } = phase || {};

  const [isEditing, setIsEditing] = useState(autoFocus);
  const [inputValue, setInputValue] = useState(name);

  const currentColor = listSelectionColors.find(
    (color) => color.colorHex == colorHex && color.bgColorHex == bgColorHex
  );

  const _onChangePhaseName = () => {
    setIsEditing(false);
    onChangePhaseName(inputValue.trim(), index);
  };

  const _onChangePhaseColor = (e, color) => {
    e.stopPropagation();
    onChangePhaseColor(color, index);
  };

  const DropdownTrigger = () => {
    const dropdownTriggerCN = `
      w-[24px]
      h-[24px]
      rounded-full
      mx-[8px]
      cursor-pointer
      relative
      ${currentColor?.displayColorClass}
    `;

    return <div className={dropdownTriggerCN} />;
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    const style = {
      ...draggableStyle,
      userSelect: 'none',
      ...(isDragging && {
        background: 'var(--base-100)',
        boxShadow: '0 6px 12px 0 rgba(50, 50, 50, 0.2)'
      })
    };

    return style;
  };

  useEffect(() => {
    if (isEditing) {
      setInputValue(name);
    }
  }, [isEditing, name]);

  return (
    <ConditionalWrapper
      condition={isDraggable}
      wrapper={(children) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {children}
        </div>
      )}
    >
      <div
        className={`border border-solid border-n-400 h-[40px] rounded-[4px] flex items-center justify-between px-[8px] ${
          containerClass ? containerClass : ''
        }`}
      >
        <div className="flex items-center w-full">
          {!isCompletedPhase && (
            <SVGIcon
              size="24"
              iconName="icon-drag_indicator"
              fillColor="var(--n-600)"
            />
          )}
          <Dropdown>
            <Dropdown.Trigger dataCy={`phase-color-${index}`}>
              <DropdownTrigger />
            </Dropdown.Trigger>
            <Dropdown.MenuItems type="fixed" customClass="px-[16px]">
              <div className="flex items-center">
                {listSelectionColors.map((color, colorIndex) => (
                  <Dropdown.MenuItem
                    key={colorIndex}
                    useHover={false}
                    customClass={`!px-[0px] !h-[24px] ${
                      colorIndex != 0 ? 'ml-[8px]' : ''
                    }`}
                    onClick={(e) => _onChangePhaseColor(e, color)}
                    dataCy={`color-option-${colorIndex}`}
                  >
                    <div
                      className={`w-[24px] h-[24px] rounded-full cursor-pointer ${color.displayColorClass} `}
                    />
                  </Dropdown.MenuItem>
                ))}
              </div>
            </Dropdown.MenuItems>
          </Dropdown>
          {isEditing ? (
            <InputField
              value={inputValue}
              handleChange={(e) => setInputValue(e.target.value)}
              onBlur={_onChangePhaseName}
              inputClass="typography-paragraph text-n-900 uppercase"
              autoFocus
              className="w-full"
              dataCy="phase-input-field"
            />
          ) : (
            <div
              className={`${
                isCompletedPhase ? 'w-full' : 'w-[224px]'
              } overflow-hidden`}
              onClick={() => setIsEditing(true)}
              data-cy={`phase-name-${index}`}
            >
              <TruncateParagraph className="typography-paragraph text-n-900 cursor-pointer uppercase">
                {name}
              </TruncateParagraph>
            </div>
          )}
        </div>
        {!isCompletedPhase && showIconDelete && (
          <SVGIcon
            size="24"
            iconName="icon-clear"
            fillColor="var(--n-600)"
            customClass="flex-end cursor-pointer ml-[16px]"
            onClick={() => onClickDeletePhase(id, isNew)}
            dataCy={`delete-phase-${index}-button`}
          />
        )}
      </div>
    </ConditionalWrapper>
  );
};

const CardPhaseWrapper = (props) => {
  const usePortal = props?.snapshot?.isDragging;

  if (usePortal) {
    return ReactDOM.createPortal(<CardPhase {...props} />, dragPortal);
  }

  return <CardPhase {...props} />;
};

export default CardPhaseWrapper;

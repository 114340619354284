import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import dayjs from 'dayjs';

import { useUser } from 'context/UserContext';
import useDisabledUsers from 'hooks/useDisabledUsers';
import { formatTimezone, getSemester } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InlineDialogSelectMultipleUser from 'components/inline-dialog/InlineDialogSelectMultipleUser';
import InlineDialogSelectUser from 'components/inline-dialog/InlineDialogSelectUser';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import ContentWrapper from './ContentWrapper';
import { getNewInvolvements } from './SidebarCreateHelper';

const OwnerReviewer = ({
  objectiveValue,
  selectedOwner,
  selectedReviewer,
  setObjectiveValue,
  assignOwner,
  assignReviewer,
  team,
  setIsDateDisabled
}) => {
  const [currentOwner, setCurrentOwner] = useState(null);
  const [isDefaultInvolvementsAssigned, setIsDefaultInvolvementsAssigned] =
    useState(false);
  const [objectiveIdsWithDefaultDatesSet, setObjectiveIdsWithDefaultDatesSet] =
    useState([]); // list of objective ids where the default dates have been set

  const { id, involvements, fakeParentId, children } = objectiveValue || {};

  const {
    config: { defaultPeriodOnCreate }
  } = useUser();
  const { params } = useRouteMatch();

  const { checkIsUserDisabled } = useDisabledUsers({
    isEdit: params?.type == 'edit',
    objective: objectiveValue
  });

  const clusterOwner =
    params?.type == 'edit'
      ? involvements?.filter(
          ({ role, visible }) => role == 'assignee' && !visible
        ).length > 0
      : false;

  const clusterReviewer =
    params?.type == 'edit'
      ? involvements?.filter(
          ({ role, visible }) => role == 'assigner' && !visible
        ).length > 0
      : false;

  const checkEmploymentPeriod = (owner, onCreateGoal = false) => {
    let startDate, dueDate;
    const willEndAt =
      owner?.user?.placement?.willEndAt || owner?.user?.willEndAt;

    if (willEndAt) {
      startDate = owner?.user?.placement?.startsAt || owner?.user?.startsAt;
      dueDate = willEndAt;

      setIsDateDisabled(true);
    } else {
      startDate =
        defaultPeriodOnCreate === 'semester'
          ? getSemester('start').format('YYYY-MM-DD')
          : dayjs().startOf(defaultPeriodOnCreate).format('YYYY-MM-DD');

      dueDate =
        defaultPeriodOnCreate === 'semester'
          ? getSemester('due').format('YYYY-MM-DD')
          : dayjs().endOf(defaultPeriodOnCreate).format('YYYY-MM-DD');

      setIsDateDisabled(false);
    }

    if (params.type != 'task') {
      setObjectiveValue((draft) => {
        draft.startDate = formatTimezone(startDate, 'start');
        draft.dueDate = formatTimezone(dueDate, 'end');
      });

      // set same dates for dummy child
      if (children?.length === 1 && children?.[0]?.name === '') {
        setObjectiveValue((draft) => {
          draft.children[0].startDate = startDate;
          draft.children[0].dueDate = dueDate;
        });
      }
    }

    if (!onCreateGoal) return; // on change owner -> no need to push
    let tempObjectiveIds = objectiveIdsWithDefaultDatesSet;
    tempObjectiveIds.push(id);
    setObjectiveIdsWithDefaultDatesSet(tempObjectiveIds);
  };

  const handleChangeDialog = (involvements, type) => {
    const lastIndex = involvements?.length - 1;
    const selectedUser = involvements[lastIndex];

    checkIsUserDisabled(selectedUser?.user?.id, () => {
      const newInvolvements = getNewInvolvements(
        involvements,
        type,
        true,
        objectiveValue,
        team ? { type: 'team' } : params
      );

      setObjectiveValue((draft) => {
        draft.involvements = newInvolvements;
      });

      if (
        newInvolvements?.length === 0 ||
        type !== 'assignee' ||
        currentOwner?.user?.id === newInvolvements[0]?.user?.id
      )
        return;
      const prevOwner = currentOwner;
      setCurrentOwner(newInvolvements[0]);

      const isPrevOwnerPKWT =
        prevOwner?.user?.willEndAt || prevOwner?.user?.placement?.willEndAt;
      const isCurrentOwnerPKWT =
        newInvolvements[0]?.user?.willEndAt ||
        newInvolvements[0]?.user?.placement?.willEndAt;

      if (!isPrevOwnerPKWT && !isCurrentOwnerPKWT) return;
      checkEmploymentPeriod(newInvolvements[0]);
    });
  };

  const getAdditionalQuery = () => {
    if (location.pathname.includes('team')) {
      return {
        teamId: team?.id,
        teamInvolvements: ['leader', 'member']
      };
    }

    if (objectiveValue && location.pathname.includes('project')) {
      return {
        taskId: id
      };
    }
    return {};
  };

  useEffect(() => {
    if (currentOwner !== null) return;
    const ownerIndex = involvements?.findIndex(
      (involvement) => involvement?.role === 'assignee'
    );
    if (ownerIndex === -1) return;
    setCurrentOwner(involvements[ownerIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [involvements]);

  useEffect(() => {
    if (
      (involvements?.length > 0 && isDefaultInvolvementsAssigned) ||
      fakeParentId
    )
      return;
    setIsDefaultInvolvementsAssigned(involvements?.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, involvements?.length, isDefaultInvolvementsAssigned]);

  useEffect(() => {
    if (
      fakeParentId ||
      !isDefaultInvolvementsAssigned ||
      params?.type == 'edit'
    )
      return;
    const isObjectiveDefaultDatesSet = objectiveIdsWithDefaultDatesSet?.find(
      (objectiveId) => objectiveId === id
    );
    if (isObjectiveDefaultDatesSet) return;

    // proceed to check employment period if objective is parent and default dates have not been set
    const ownerIndex = involvements?.findIndex(
      (involvement) => involvement?.role === 'assignee'
    );
    checkEmploymentPeriod(involvements[ownerIndex], true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fakeParentId, id, isDefaultInvolvementsAssigned]);

  return (
    <ContentWrapper customWrapperClass="pl-[16px] pr-[24px] mt-[16px] mb-[8px]">
      {!clusterOwner && (
        <div
          className="people_involved w-[164px] mr-[24px]"
          data-cy="sidebar-create-owner"
        >
          <div className="flex items-center">
            <p className="ml-[8px] typography-h200 text-n-900">
              {getObjectiveLocale('Objective / Task Owner')}
            </p>
            <TooltipContainer
              wrap
              show
              text={getObjectiveLocale(
                'Those who are responsible to own and update the goal progress'
              )}
            >
              <SVGIcon
                size="14"
                fillColor="var(--n-600)"
                iconName="icon-info"
                customClass="ml-[8px] cursor-pointer"
              />
            </TooltipContainer>
          </div>
          <InlineDialogSelectMultipleUser
            objectiveValue={objectiveValue}
            type="assignee"
            role="assignee"
            selectedValue={selectedOwner}
            handleChange={handleChangeDialog}
            position="left"
            permission={assignOwner}
            header="Objective / Task Owner"
            dataCy="assignee-select-inline"
            placeholderWording="Select Owner"
            additionalQuery={getAdditionalQuery()}
          />
        </div>
      )}
      {!clusterReviewer && (
        <div
          className="people_involved w-[164px]"
          data-cy="sidebar-create-reviewer"
        >
          <div className="flex items-center">
            <p className="ml-[8px] typography-h200 text-n-900">
              {getObjectiveLocale('Objective / Task Reviewer')}
            </p>
            <TooltipContainer
              wrap
              show
              tooltipClass="w-[250px]"
              text={getObjectiveLocale(
                'Those who are responsible to review the goal achievement'
              )}
            >
              <SVGIcon
                size="14"
                fillColor="var(--n-600)"
                iconName="icon-info"
                customClass="ml-[8px] cursor-pointer"
              />
            </TooltipContainer>
          </div>
          <InlineDialogSelectUser
            objectiveValue={objectiveValue}
            selectedValue={selectedReviewer}
            handleChange={handleChangeDialog}
            permission={assignReviewer}
            selectedOwner={selectedOwner}
            additionalQuery={getAdditionalQuery()}
            type="assigner"
            position="right"
            dataCy="reviewer-status-inline"
          />
        </div>
      )}
    </ContentWrapper>
  );
};

export default OwnerReviewer;

import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { CSSTransition } from 'react-transition-group';

import isEqual from 'lodash/isEqual';
import { useImmer } from 'use-immer';

import { getListObjectives, getObjectiveDetail } from 'client/ObjectivesClient';
import { useProjectDetail } from 'context/ProjectDetailContext';
import { useUser } from 'context/UserContext';
import useTasks from 'hooks/useTasks';
import { getIncludeTeamGoalsParams } from 'utils/ObjectivesHelper';

import Draggable from 'components/shared/DragAndDrop/Draggable';
import BoardCardContent from 'components/tasks/board/BoardCardContent';
import BoardDropzone from 'components/tasks/board/BoardDropzone';

const BoardCard = React.memo(
  ({
    taskNode,
    level = 0,
    groupData = { id: 0, name: '', index: 0 },
    sectionData = { id: 0, name: '', index: 0 },
    isLastCard = false,
    isLastChild = false,
    index = 0,
    cardsRef,
    dragAndDrop = {},
    shadowStyle = {},
    isProject = false,
    filter,
    listMetrics = [],
    cancelAddTask
  }) => {
    const [task, setTask] = useImmer(taskNode.value);
    const [showChild, setShowChild] = useState(false);
    const [childHeight, setChildHeight] = useState(0);
    const [isLoadChildren, setIsLoadChildren] = useState(false);
    const [objectiveChildren, setObjectiveChildren] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [showTopSkeleton, setShowTopSkeleton] = useState(false);

    const history = useHistory();

    const {
      config: { includeTeamGoals }
    } = useUser();

    const {
      selectedTasks,
      startTask,
      updateStartTaskOneLevel,
      updateStartTaskTwoLevels,
      updateEndTaskOneLevel,
      updateEndTaskTwoLevels,
      updateSelectedTasks,
      removeSelected,
      removeAllSelected
    } = useTasks();

    const { projectId } = useProjectDetail();

    const isSelected = selectedTasks.find((selTask) => selTask.id === task.id);

    const { id } = task || 0;

    const fetchObjective = () => {
      return getObjectiveDetail(taskNode.value.id);
    };

    useQuery(['objective', taskNode.value.id], fetchObjective, {
      initialData: taskNode.value,
      staleTime: Infinity,
      onSuccess: (data) => {
        data.data && setTask(() => data.data);
        if (showChild) {
          fetchObjectivesChildren();
        } else {
          taskNode.childs = [];
        }
      }
    });

    const fetchObjectivesChildren = async () => {
      const teamId = taskNode.value?.team?.id;

      const additionalParams = getIncludeTeamGoalsParams(
        includeTeamGoals,
        teamId,
        false
      );

      let allParams = {
        reviewsVisibility: 1,
        parentId: id,
        ...additionalParams
      };

      const { data } = await getListObjectives(allParams);

      if (data) {
        taskNode.childs = [];
        data.forEach((d) => {
          return taskNode.addChild(d);
        });
      }
    };

    const showChildren = async () => {
      setIsLoadChildren(true);
      !showChild &&
        objectiveChildren?.length === 0 &&
        (await fetchObjectivesChildren());
      setIsLoadChildren(false);
      setShowChild(!showChild);
    };

    const handleClickCard = (e) => {
      if (!e.shiftKey && !(e.ctrlKey || e.metaKey)) {
        openSidebarDetail();
      } else {
        handleHoldSelectTask(e);
      }
    };

    const openSidebarDetail = () => {
      history.replace(
        `/${isProject ? `projects/${projectId}` : 'tasks'}/${task.id}`
      );
    };

    const handleHoldSelectTask = (e) => {
      if (e.ctrlKey || e.metaKey) {
        if (startTask === null) {
          isProject
            ? updateStartTaskTwoLevels(
                groupData?.index,
                sectionData?.index,
                task.id
              )
            : updateStartTaskOneLevel(groupData?.index, task.id);
          updateSelectedTasks({ ...task, groupName: groupData?.name });
        } else if (
          selectedTasks.length === 1 &&
          selectedTasks?.find((selTask) => selTask.id === task.id)
        ) {
          removeAllSelected();
        } else if (selectedTasks?.find((selTask) => selTask.id === task.id)) {
          removeSelected(task.id);
        } else {
          updateSelectedTasks({ ...task, groupName: groupData?.name });
        }
      }

      if (e.shiftKey && !isProject) {
        if (startTask == null) {
          updateStartTaskOneLevel(groupData?.index, task.id);
        } else {
          updateEndTaskOneLevel(groupData?.index, task.id);
        }
      }
    };

    const setParentIds = () => {
      let tempTask = task;
      tempTask.parentId = task?.parent?.id ? [task?.parent?.id] : [];
      tempTask.parentIds = task?.parents?.map((parent) => parent?.id) || [];
      setTask(() => tempTask);
    };

    const cancelAddChildren = () => {
      let newChilds = [...objectiveChildren];
      newChilds.shift();

      taskNode.childs = newChilds;
      setTask((draft) => {
        draft.childrenCount = draft.childrenCount - 1;
      });

      setObjectiveChildren(newChilds);
    };

    useEffect(() => {
      taskNode.value = task;
    }, [task]);

    useEffect(() => {
      if (taskNode.childs.length === 0) {
        setObjectiveChildren([]);
      } else {
        setObjectiveChildren(taskNode.childs);
      }
    }, [taskNode.childs.length]);

    useEffect(() => {
      if (!isEqual(taskNode.value, task)) {
        setTask(() => taskNode.value);
      }
    }, [taskNode]);

    useEffect(() => {
      setParentIds();
    }, [task?.parents]);

    return (
      <div ref={level === 0 ? (e) => (cardsRef.current[index] = e) : null}>
        <div
          className={`relative min-w-[328px] ${level === 0 ? 'mb-[8px]' : ''}`}
        >
          {level === 0 && index === 0 && (
            <>
              <BoardDropzone
                groupData={groupData}
                sectionData={sectionData}
                index={-1}
                top={showTopSkeleton ? -8 : 0}
                height={`calc(50% + ${showTopSkeleton ? 8 : 0}px)`}
                dragAndDrop={dragAndDrop}
                setShowSkeleton={setShowTopSkeleton}
                isProject={isProject}
              />
              {showTopSkeleton && (
                <BoardDropzone.CardSkeleton
                  index={-1}
                  draggedId={dragAndDrop?.draggedId}
                  shadowStyle={shadowStyle}
                  isProject={isProject}
                />
              )}
            </>
          )}
          <div style={level === 0 ? shadowStyle : {}}>
            {level === 0 ? (
              <Draggable
                level={0}
                id={id}
                draggedId={dragAndDrop?.draggedId}
                setDraggedId={dragAndDrop?.setDraggedId}
                setDraggable={dragAndDrop?.setDraggable}
                task={task}
                index={index}
                groupData={groupData}
                sectionData={sectionData}
                objectiveChildren={objectiveChildren}
                showChild={showChild}
                useDrop={false}
                isDraggable={task?.id !== -1}
              >
                <BoardCardContent
                  taskNode={taskNode}
                  task={task}
                  setTask={setTask}
                  filter={filter}
                  listMetrics={listMetrics}
                  groupData={groupData}
                  sectionData={sectionData}
                  level={level}
                  handleClickCard={handleClickCard}
                  showChild={showChild}
                  showChildren={showChildren}
                  isLoadChildren={isLoadChildren}
                  isLastChild={isLastChild}
                  isSelected={isSelected}
                  isProject={isProject}
                  setShowChild={setShowChild}
                  fetchObjectivesChildren={fetchObjectivesChildren}
                  cancelAddTask={cancelAddTask}
                />
              </Draggable>
            ) : (
              <BoardCardContent
                taskNode={taskNode}
                task={task}
                setTask={setTask}
                filter={filter}
                groupData={groupData}
                sectionData={sectionData}
                level={level}
                handleClickCard={handleClickCard}
                showChild={showChild}
                showChildren={showChildren}
                isLoadChildren={isLoadChildren}
                isLastChild={isLastChild}
                isSelected={isSelected}
                isProject={isProject}
                listMetrics={listMetrics}
                setShowChild={setShowChild}
                fetchObjectivesChildren={fetchObjectivesChildren}
                cancelAddTask={cancelAddTask}
              />
            )}

            <CSSTransition
              duration={300}
              timeout={300}
              classNames="children"
              in={showChild && objectiveChildren?.length > 0}
              onEnter={() => setChildHeight(0)}
              onEntered={() => setChildHeight('auto')}
              onExit={() => setChildHeight(0)}
              onExited={() => setChildHeight(0)}
            >
              <AnimateHeight
                duration={500}
                height={childHeight}
                className="overflow-unset"
              >
                {objectiveChildren.map((child, index) => (
                  <BoardCard
                    key={`subtask-${
                      child?.value?.id ? child?.value?.id : index
                    }`}
                    taskNode={child}
                    level={1}
                    groupData={groupData}
                    sectionData={sectionData}
                    isLastChild={index === objectiveChildren.length - 1}
                    cancelAddTask={cancelAddChildren}
                    cardsRef={cardsRef}
                  />
                ))}
              </AnimateHeight>
            </CSSTransition>
          </div>

          {level === 0 && (
            <BoardDropzone
              groupData={groupData}
              sectionData={sectionData}
              index={index}
              top="50%"
              height={`calc(50% + 8px + ${
                !isLastCard ? cardsRef.current[index + 1]?.clientHeight / 2 : 0
              }px)`}
              dragAndDrop={dragAndDrop}
              setShowSkeleton={setShowSkeleton}
              isProject={isProject}
            />
          )}
        </div>
        {showSkeleton && (
          <BoardDropzone.CardSkeleton
            index={index}
            draggedId={dragAndDrop?.draggedId}
            shadowStyle={shadowStyle}
            isProject={isProject}
          />
        )}
      </div>
    );
  },
  areEqual
);

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

BoardCard.displayName = 'BoardCard';

export default BoardCard;

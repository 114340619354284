import React, { useEffect, useState } from 'react';

import hexRgb from 'hex-rgb';

import {
  getScoringTemplates,
  getScoringTemplatesbyId,
  updateScoringTemplate
} from 'client/admin/GoalReview';
import { useReload } from 'context/ReloadContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import LoadingComponent from 'components/shared/LoadingComponent';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

import ToastNotif from '../shared/ToastNotif';
import DuoCheckBox from './editreview/DuoCheckBox';

function GoalReviewComponent() {
  const { reload, reloadUser } = useReload();
  const [isColorPickerActive, setIsColorPickerActive] = useState(false);
  const [templateId, setTemplateId] = useState(-1);
  const [colorPicker, setColorPicker] = useState([
    '3ca95c',
    '2480cc',
    '553686',
    '7ed321',
    '50acd5',
    '9362ba',
    'f9dc5b',
    'fca100',
    'e44832',
    'fbc600',
    'f87929',
    'ba3029'
  ]);
  const [detailScoringTemplate, setDetailScoringTemplate] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [ratingOptions, setRatingOptions] = useState([]);
  const [currentActiveColor, setCurrentActiveColor] = useState('');
  const [customOverallValue, setCustomOverallValue] = useState(false);
  const [successMesage, setsuccessMessage] = useState('');
  const [isSuccess, setisSuccess] = useState(false);
  const [error, setError] = useState({
    errorReviewValue: false,
    errorCommentValue: false,
    errorRecognitionValue: false,
    errorFeedbackValue: false,
    errorRatingOption: [false, false, false],
    errorOverallValue: [false, false, false]
  });
  const [onSubmit, setOnSubmit] = useState(false);
  const [colorPickerIdActive, setColorPickerIdActive] = useState(0);
  const [reviewQuestion, setReviewQuestion] = useState('');
  const [commentQuestion, setCommentQuestion] = useState('');
  const [recognitionQuestion, setRecognitionQuestion] = useState('');
  const [feedbackQuestion, setFeedbackQuestion] = useState('');

  const getListScoringTemplates = async () => {
    const { data, error } = await getScoringTemplates();
    if (data) {
      data.map((data, index) => {
        if (data.isDefault) {
          setTemplateId(data.id);
          getDetailScoringTemplate(data.id);
          setIsLoading(false);
        }
      });
    }
  };

  const getDetailScoringTemplate = async (templateId) => {
    const { data, error } = await getScoringTemplatesbyId(templateId);
    if (data) {
      let ratingOptions = data.scoringComponents[0].scoringMarks;

      setDetailScoringTemplate(data);
      setRatingOptions(ratingOptions);
    }
  };

  const onClickDisplayColorPicker = (index, color) => {
    setIsColorPickerActive(!isColorPickerActive);
    setColorPickerIdActive(index);
    setCurrentActiveColor(color);
    dragElement(document.getElementById('mydiv'));
  };

  const dragElement = (elmnt) => {
    if (isColorPickerActive) {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + 'header')) {
        document.getElementById(elmnt.id + 'header').onmousedown =
          dragMouseDown;
      } else {
        elmnt.onmousedown = dragMouseDown;
      }

      const dragMouseDown = (e) => {
        e = e || window.event;
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmousemove = closeDragElement;
        document.onmousemove = elementDrag;
      };

      const elementDrag = (e) => {
        e = e || window.event;
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
      };

      const closeDragElement = () => {
        document.onmouseup = null;
        document.onmousemove = null;
      };
    }
  };

  const addMoreOption = () => {
    let ratingOptionsTemp = ratingOptions;
    ratingOptionsTemp.push({
      name: 'Option',
      colorHex: 'e44832'
    });
    setRatingOptions(ratingOptionsTemp);
  };

  const removeOption = (index) => {
    let ratingOptionsTemp = ratingOptions;
    ratingOptionsTemp.splice(index, 1);
    setRatingOptions(ratingOptionsTemp);
  };

  const changeColor = (color) => {
    let ratingOptionsTemp = ratingOptions;

    for (let i = 0; i < ratingOptionsTemp.length; i++) {
      if (i === colorPickerIdActive) {
        ratingOptionsTemp[i].colorHex = color;
      }
    }

    setRatingOptions(ratingOptionsTemp);
    onClickDisplayColorPicker(-1);
  };

  const checkEmptyField = () => {
    let reviewValue = document.getElementById('review-value').value;
    let commentValue = document.getElementById('comment-value').value;
    let recognitionValue = document.getElementById('recognition-value').value;
    let feedbackValue = document.getElementById('feedback-value').value;
    let ratingOptionsTemp = ratingOptions;

    let errorReviewValue = false;
    let errorCommentValue = false;
    let errorRecognitionValue = false;
    let errorFeedbackValue = false;
    let errorRatingOption = [];
    let errorOverallValue = [];
    let isError = false;

    if (reviewValue === '') {
      errorReviewValue = true;
      isError = true;
    }

    if (commentValue === '') {
      errorCommentValue = true;
      isError = true;
    }

    if (recognitionValue === '') {
      errorRecognitionValue = true;
      isError = true;
    }

    if (feedbackValue === '') {
      errorFeedbackValue = true;
      isError = true;
    }

    for (let i = 0; i < ratingOptionsTemp.length; i++) {
      let option = document.getElementById('option-' + i).value;
      let optionError = false;
      let overallValueError = false;

      if (option === '') {
        optionError = true;
        isError = true;
        errorRatingOption.push(optionError);
      } else {
        errorRatingOption.push(optionError);
      }
    }

    let customOverallValueTemp = customOverallValue;

    if (customOverallValueTemp) {
      let optionDescription = document.getElementById('option-desc-' + i).value;
      if (optionDescription === '') {
        overallValueError = true;
        isError = true;
        errorOverallValue.push(overallValueError);
      } else {
        errorOverallValue.push(overallValueError);
      }
    }

    let error = {
      errorReviewValue,
      errorCommentValue,
      errorRecognitionValue,
      errorFeedbackValue,
      errorRatingOption,
      errorOverallValue
    };

    setError(error);

    return isError;
  };

  const saveReviewAsDefault = async () => {
    if (!checkEmptyField()) {
      let reviewValue = document.getElementById('review-value').value;
      let commentValue = document.getElementById('comment-value').value;
      let recognitionValue = document.getElementById('recognition-value').value;
      let feedbackValue = document.getElementById('feedback-value').value;
      let ratingOptionsTemp = ratingOptions;

      let customOverallValueTemp = customOverallValue;

      for (let i = 0; i < ratingOptionsTemp.length; i++) {
        let option = document.getElementById('option-' + i).value;
        ratingOptionsTemp[i].name = option;

        delete ratingOptionsTemp[i]['percentage'];

        if (customOverallValueTemp) {
          let optionDescription = document.getElementById(
            'option-desc-' + i
          ).value;
          ratingOptionsTemp[i].description = optionDescription;
        } else {
          ratingOptionsTemp[i].description = option;
        }

        document.getElementById('strength-' + i).checked
          ? (ratingOptionsTemp[i].askForStrength = true)
          : (ratingOptionsTemp[i].askForStrength = false);
        document.getElementById('weakness-' + i).checked
          ? (ratingOptionsTemp[i].askForWeakness = true)
          : (ratingOptionsTemp[i].askForWeakness = false);

        document.getElementById('requiredStrength-' + i) === null
          ? (ratingOptionsTemp[i].requireForStrength = false)
          : document.getElementById('requiredStrength-' + i).checked
          ? (ratingOptionsTemp[i].requireForStrength = true)
          : (ratingOptionsTemp[i].requireForStrength = false);
        document.getElementById('requiredWeakness-' + i) === null
          ? (ratingOptionsTemp[i].requireForWeakness = false)
          : document.getElementById('requiredWeakness-' + i).checked
          ? (ratingOptionsTemp[i].requireForWeakness = true)
          : (ratingOptionsTemp[i].requireForWeakness = false);
      }

      let templateId2 = templateId;
      let payload = detailScoringTemplate;
      payload.commentQuestion = commentValue;
      payload.recognitionQuestion = recognitionValue;
      payload.feedbackQuestion = feedbackValue;
      payload.scoringComponents[0].question = reviewValue;
      payload.scoringComponents[0].scoringMarks = ratingOptionsTemp;

      delete payload.scoringComponents[0].maximalScore;

      const { data, error } = await updateScoringTemplate(templateId2, payload);
      if (data) {
        reload({ reloadUser: !reloadUser });
        setisSuccess(true);
        setsuccessMessage('Successfully Updated! ');
        setTimeout(() => {
          setisSuccess(false);
        }, 2000);
      }
    }
  };

  const switchOverallValue = () => {
    let customOverallValueTemp = document.getElementById(
      'switch-overall-value'
    ).checked;
    setCustomOverallValue(customOverallValueTemp);
  };

  useEffect(() => {
    dragElement(document.getElementById('mydiv'));
    getListScoringTemplates();
  }, []);

  useEffect(() => {
    if (detailScoringTemplate.hasOwnProperty('name')) {
      let scoringComponentsTemp = detailScoringTemplate.scoringComponents;

      setReviewQuestion(scoringComponentsTemp[0].question);
      setCommentQuestion(detailScoringTemplate.commentQuestion);
      setRecognitionQuestion(detailScoringTemplate.recognitionQuestion);
      setFeedbackQuestion(detailScoringTemplate.feedbackQuestion);
    }
  }, [detailScoringTemplate]);

  return (
    <div>
      <div className="col-xs-12" id="dashboard_content">
        {/* <Progress.Component /> */}
        <ToastNotif showNotif={isSuccess} message={successMesage} />
        <div className="dashboard_content_main" id="review-objective">
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <div className="timeline">
              <div className="column">
                <p className="title">Review Question</p>
                <div className="timeline-form">
                  <textarea
                    id="review-value"
                    placeholder={`${getObjectiveLocale(
                      'How would you rate your the result?'
                    )} (default)`}
                    value={reviewQuestion}
                    onChange={(e) => setReviewQuestion(e.currentTarget.value)}
                    className="btn-filter-name textarea-form"
                    style={
                      error.errorReviewValue
                        ? { border: '1px solid #e44832' }
                        : {}
                    }
                  />
                  {error.errorReviewValue && (
                    <p className="error-message">this field is required!</p>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="rating-option-wrapper">
                      <p className="title-rating">
                        Rating option (from best to worst)
                      </p>
                      {isColorPickerActive && (
                        <div id="mydiv">
                          <div id="mydivheader">
                            <div style={{ display: 'inline-block' }}>
                              <p className="title-color">Color Picker</p>
                            </div>
                            <button
                              className="btn-white btn-close"
                              type="submit"
                              onClick={onClickDisplayColorPicker.bind(null, -1)}
                            >
                              x
                            </button>
                          </div>
                          <div className="color-picker-box">
                            {colorPicker.map(function (color, idx) {
                              let activeColor = currentActiveColor;
                              let colorBgStyle = {
                                backgroundColor: '#' + color,
                                border:
                                  activeColor === color ? '2px solid #000' : ''
                              };
                              return (
                                <div
                                  key={idx}
                                  className="color-box"
                                  style={colorBgStyle}
                                  onClick={changeColor.bind(null, color)}
                                ></div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {ratingOptions.map(function (option, idx) {
                        let name = option.name;
                        let color = option.colorHex;
                        let optionLength = ratingOptions.length;
                        let overallValue = option.description;
                        let strengthValue = option.askForStrength;
                        let weaknessValue = option.askForWeakness;
                        let requiredStrength = option.requireForStrength;
                        let requiredWeakness = option.requireForWeakness;

                        let rgbColor = hexRgb(color);

                        let descStyle = {
                          backgroundColor: `rgb(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.21)`,
                          border: error.errorOverallValue[idx]
                            ? '1px solid #e44832'
                            : 'none',
                          borderRadius: '4px',
                          color: '#4a4d4f !important',
                          marginLeft: '0px',
                          marginTop: '15px',
                          marginBottom: '15px',
                          width: '100%'
                        };

                        return (
                          <div key={idx} className="rating-option-input">
                            <input
                              type="text"
                              id={'option-' + idx}
                              defaultValue={name}
                              placeholder={name}
                              className="form-control btn-filter-name input-rating"
                              style={
                                error.errorRatingOption[idx]
                                  ? { border: '1px solid #e44832' }
                                  : {}
                              }
                            />
                            <div
                              className="current-color"
                              style={{ backgroundColor: '#' + color }}
                              onClick={onClickDisplayColorPicker.bind(
                                null,
                                idx,
                                color
                              )}
                            ></div>
                            {optionLength > 3 && (
                              <button
                                className="btn-white btn-remove btn-close"
                                type="submit"
                                onClick={removeOption.bind(null, idx)}
                              >
                                x
                              </button>
                            )}
                            {idx === 0 ? (
                              <DuoCheckBox
                                className="checkboxes"
                                id={idx}
                                strengthChecked={
                                  typeof strengthValue !== 'undefined'
                                    ? strengthValue
                                    : true
                                }
                                requiredStrength={requiredStrength}
                                weaknessChecked={
                                  typeof weaknessValue !== 'undefined'
                                    ? weaknessValue
                                    : false
                                }
                                requiredWeakness={requiredWeakness}
                                idReqStrength={'requiredStrength-' + idx}
                                idReqWeakness={'requiredWeakness-' + idx}
                              />
                            ) : (
                              <DuoCheckBox
                                className="checkboxes"
                                id={idx}
                                strengthChecked={
                                  typeof strengthValue !== 'undefined'
                                    ? strengthValue
                                    : true
                                }
                                requiredStrength={requiredStrength}
                                weaknessChecked={
                                  typeof weaknessValue !== 'undefined'
                                    ? weaknessValue
                                    : false
                                }
                                requiredWeakness={requiredWeakness}
                                idReqStrength={'requiredStrength-' + idx}
                                idReqWeakness={'requiredWeakness-' + idx}
                              />
                            )}

                            {error.errorRatingOption[idx] && (
                              <p className="error-message">
                                this field is required!
                              </p>
                            )}
                            {customOverallValue && (
                              <input
                                type="text"
                                id={'option-desc-' + idx}
                                defaultValue={overallValue}
                                placeholder={overallValue}
                                style={descStyle}
                              />
                            )}
                            {customOverallValue && (
                              <span>
                                {error.errorOverallValue[idx] && (
                                  <p
                                    className="error-message"
                                    style={{ marginLeft: '15px' }}
                                  >
                                    this field is required!
                                  </p>
                                )}
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="rating-option-wrapper">
                      <p className="title-rating">Ask for Recognition</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="rating-option-wrapper">
                      <p className="title-rating">Ask for Feedback</p>
                    </div>
                  </div>
                </div>{' '}
                {/* row */}
                <div className="overall-value-wrapper">
                  <div className="overall-value-header">
                    <div className="col-xs-10">
                      <p className="overall-value-title">
                        Custom overall value based on you rating option. (
                        {getObjectiveLocale('optional')})
                      </p>
                      <p className="overall-value-desc">
                        If you put this option off (default), the overall result
                        will be the same as the score.
                      </p>
                    </div>
                    <div className="col-xs-2">
                      <SmallToggleSwitchPurple
                        idStr={'switch-overall-value'}
                        isChecked={customOverallValue}
                        onChange={switchOverallValue}
                      />
                    </div>
                  </div>
                  <div className="overall-value-body">
                    <div className="col-xs-12">
                      <p className="sub-title">Example</p>
                      <p className="overall-value-desc no-margin">
                        Review Question :{' '}
                        <span className="sub-title">
                          <i>
                            Would you like he/she to be in the next project?
                          </i>
                        </span>
                      </p>
                      <p className="overall-value-desc">
                        Score option :{' '}
                        <span className="sub-title">
                          <i>Yes - No - Maybe</i>
                        </span>
                      </p>
                    </div>
                    <div className="col-xs-6">
                      <p className="sub-title">Result ( off )</p>
                      <p className="sub-title">
                        Convert Value{' '}
                        <span className="convert-value-ex">Yes</span>
                      </p>
                    </div>
                    <div className="col-xs-6 border-left">
                      <p className="sub-title">Result ( on )</p>
                      <p className="sub-title">
                        Convert Value{' '}
                        <span className="convert-value-ex">
                          Likely to be in the next project
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>{' '}
              {/* column */}
              <div className="column">
                <p className="title">Comment Question</p>
                <div className="timeline-form">
                  <textarea
                    id="comment-value"
                    value={commentQuestion}
                    onChange={(e) => setCommentQuestion(e.currentTarget.value)}
                    placeholder="Please provide his/her overall performance in this project. (default)"
                    className="btn-filter-name textarea-form"
                    style={
                      error.errorCommentValue
                        ? { border: '1px solid #e44832' }
                        : {}
                    }
                  />
                  {error.errorCommentValue && (
                    <p className="error-message">this field is required!</p>
                  )}
                </div>
              </div>
              <div className="column">
                <p className="title">{getObjectiveLocale('Recognition')}</p>
                <div className="timeline-form">
                  <textarea
                    id="recognition-value"
                    value={recognitionQuestion}
                    onChange={(e) =>
                      setRecognitionQuestion(e.currentTarget.value)
                    }
                    placeholder="What is the one thing did he/she do well? (default)"
                    className="btn-filter-name textarea-form"
                    style={
                      error.errorRecognitionValue
                        ? { border: '1px solid #e44832' }
                        : {}
                    }
                  />
                  {error.errorRecognitionValue && (
                    <p className="error-message">this field is required!</p>
                  )}
                </div>
              </div>
              <div className="column">
                <p className="title">{getObjectiveLocale('Feedback')}</p>
                <div className="timeline-form">
                  <textarea
                    id="feedback-value"
                    value={feedbackQuestion}
                    onChange={(e) => setFeedbackQuestion(e.currentTarget.value)}
                    placeholder="What is the one thing did he/she need to improve? (default)"
                    className="btn-filter-name textarea-form"
                    style={
                      error.errorFeedbackValue
                        ? { border: '1px solid #e44832' }
                        : {}
                    }
                  />
                  {error.errorFeedbackValue && (
                    <p className="error-message">this field is required!</p>
                  )}
                  <p>
                    Feedback will be private only to project/goal owner and
                    reviewer
                  </p>
                </div>
              </div>
              <br />
              <div className="column">
                <Button type="submit" onClick={saveReviewAsDefault}>
                  {getObjectiveLocale('Save')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GoalReviewComponent;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSidebarMenu } from 'context/SidebarMenuContext';
import {
  EditCycleProvider,
  useEditCycle
} from 'context/admin/EditCycleContext';

import EditSchedule from 'components/admin/Cycles/EditSchedule';
import ModalConfirmEditCycle from 'components/admin/Cycles/ModalConfirmEditCycle';
import EditPhase from 'components/admin/Cycles/phases/EditPhase';
import HeaderPage from 'components/admin/HeaderPage';
import Footer from 'components/shared/Footer';
import SidebarLeftMenuNavigator from 'components/shared/SidebarLeftMenuNavigator';

import SetName from './SetName';

const EditCycle = () => {
  const {
    getCycleData,
    checkDifference,
    stateData,
    editCycle,
    changeEditedCycle,
    getConfirmData
  } = useEditCycle();
  const { id: cycleId } = useParams();
  const [activeMenu, setActiveMenu] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const { toggleSidebar } = useSidebarMenu();

  const headerProps = {
    titlePage: stateData?.editedCycle?.name,
    backToUrl:
      params.get('source') === 'details'
        ? `/cycles/detail-cycle/${cycleId}?detailBy=configuration`
        : `/appraisals/cycles`,
    isHeaderComposer: true
  };

  const listMenu = [
    {
      id: 'cycleName',
      title: 'Cycle Name',
      onClick: () => {
        setActiveMenu('cycleName');
      },
      isVisible: true
    },
    {
      id: 'phase',
      title: 'Phase',
      onClick: () => {
        setActiveMenu('phase');
      },
      isVisible: true
    },
    {
      id: 'schedule',
      title: 'Review Schedule',
      onClick: () => {
        setActiveMenu('schedule');
      },
      isVisible: false
    } // ON HOLD
  ].filter((opt) => opt.isVisible);

  const getCurrentCycle = async () => {
    await getCycleData(cycleId);
  };

  const submitEditCycle = async () => {
    setIsLoading(true);
    await editCycle(stateData.editedCycle);
    setShowModal(false);
    setIsLoading(false);
  };

  useEffect(() => {
    getCurrentCycle();
    toggleSidebar(true);
    setActiveMenu(listMenu[0]?.id);
  }, []);

  useEffect(() => {
    checkDifference();
  }, [stateData.editedCycle]);

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="px-[0px] flex w-full bg-n-000">
        <div className="relative mr-[40px]">
          <SidebarLeftMenuNavigator
            listMenu={listMenu}
            title={'Settings for'}
            activeMenu={activeMenu}
            customClass="w-[256px]"
          />
        </div>
        {activeMenu === 'cycleName' && (
          <SetName
            useButton={false}
            customClass="px-[0px]"
            cycleData={stateData.editedCycle}
            changeCycleName={(name) => changeEditedCycle('name', name)}
          />
        )}
        {activeMenu === 'phase' && <EditPhase />}
        {activeMenu === 'schedule' && <EditSchedule />}
      </div>
      <Footer
        buttonPrimaryName="Save Changes"
        buttonPrimaryDisabled={!stateData.isDiff}
        handleClick={() => {
          setShowModal(true), getConfirmData();
        }}
      />
      {showModal && (
        <ModalConfirmEditCycle
          submitEditCycle={submitEditCycle}
          closeModal={() => setShowModal(false)}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

const EditCycleWrapper = () => {
  return (
    <EditCycleProvider>
      <EditCycle />
    </EditCycleProvider>
  );
};

export default EditCycleWrapper;

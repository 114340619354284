import React, { useEffect, useState } from 'react';

import kebabCase from 'lodash/kebabCase';

import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import BannerBox from 'components/shared/BannerBox';

const SetWeightBox = ({
  type,
  attributeSelectedName,
  attributeSelectedPhases,
  attributeSelectedId
}) => {
  const [listPhases, setListPhases] = useState([]);
  const { cycleData, changeTrackWeight } = useCreateCycle();
  const [isWeightUpdated, setIsWeightUpdated] = useState(false);

  let trackType, title, description;
  switch (type) {
    case 'goal':
      trackType = 'goals_scoring';
      title = 'Goal Final Scoring';
      description = 'Set percentage weight on goals scoring for each phases.';
      break;
    case 'task':
      trackType = 'tasks_scoring';
      title = 'Task Final Scoring';
      description = 'Set percentage weight on tasks scoring for each phases.';
      break;
    case 'value':
      trackType = 'values_scoring';
      title = 'Value Final Scoring';
      description = 'Set percentage weight on value scoring for each phases.';
      break;
    case 'review-aspect':
      trackType = 'review_aspects_scoring';
      title = attributeSelectedName;
      description = 'Set percentage weight on aspect scoring for each phases.';
      break;
    default:
      trackType = 'competencies_scoring';
      title = 'Competency Final Scoring';
      description =
        'Set percentage weight on competency scoring for each phases.';
      break;
  }

  const phaseHasCurrentTrack = () => {
    const defaultPhases = [
      {
        title: getObjectiveLocale('Self Review'),
        key: 'selfReview',
        weight: 0
      },
      {
        title: getObjectiveLocale('Peer Review'),
        key: 'peerReview',
        weight: 0
      },
      {
        title: getObjectiveLocale('Upward Review'),
        key: 'upwardReview',
        weight: 0
      },
      {
        title: getObjectiveLocale('Manager Review'),
        key: 'managerReview',
        weight: 0
      },
      {
        title: getObjectiveLocale('Indirect Manager Review'),
        key: 'indirectManagerReview',
        weight: 0
      }
    ];

    let phases = [];
    defaultPhases.forEach((phase) => {
      if (cycleData[phase.key]) {
        const found = cycleData[phase.key].trackConfigs?.find((config) => {
          if (trackType == 'review_aspects_scoring')
            return (
              config.type == trackType &&
              config.template.id == attributeSelectedId &&
              (attributeSelectedPhases
                ? attributeSelectedPhases?.includes(phase?.key)
                : true)
            );

          return config.type == trackType;
        });

        if (found) {
          let temp = { ...phase };
          temp.weight = found['weight'];
          phases.push(temp);
        }
      }
    });

    return phases;
  };

  const setWeight = (phaseKey, weight) => {
    const temp = [...listPhases];
    temp.find((phase) => phase.key == phaseKey).weight = Number(weight);
    setListPhases(temp);
    setIsWeightUpdated(true);
  };

  const _onKeyPress = (e) => {
    let isNumberAndDotOnly = /^[0-9]*\.?[0-9]*$/;
    if (!isNumberAndDotOnly.test(e.key)) {
      e.preventDefault();
    }
  };

  const getTotalWeight = () => {
    return listPhases.reduce((accumulator, phase) => {
      return accumulator + (phase.weight || 0);
    }, 0);
  };

  const handleSaveWeight = () => {
    if (getTotalWeight() != 100 || !isWeightUpdated) return;
    changeTrackWeight(trackType, listPhases, attributeSelectedId);
  };

  const getListPhases = () => {
    const phase = phaseHasCurrentTrack();
    setListPhases(phase);
    setIsWeightUpdated(false);
  };

  useEffect(() => {
    getListPhases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleData, attributeSelectedPhases]);

  useEffect(() => {
    handleSaveWeight();
  }, [listPhases]);

  return (
    <div className="surface mt-[24px] px-[16px] py-[16px] bg-n-000">
      <p className="typography-h400 mb-[4px]">{title}</p>
      <p className="typography-secondary">{description}</p>
      {listPhases.length == 0 && (
        <p className="mt-[16px] typography-tertiary">
          There are no phases using this track
        </p>
      )}
      {listPhases.length > 0 && (
        <>
          {getTotalWeight() != 100 && (
            <BannerBox
              text="Percentage weight scoring can’t be lower or over than 100%"
              type="warning"
              customClass="mt-[16px]"
            />
          )}
          <div className="flex items-center h-[36px] mt-[26px]">
            <div className="flex-[3]">
              <p className="typography-h200 typography-secondary">PHASE</p>
            </div>
            <div className="flex-[5]">
              <p className="typography-h200 typography-secondary">WEIGHT (%)</p>
            </div>
          </div>
          {listPhases.map((phase, idx) => {
            return (
              <div key={idx} className="flex items-center mt-[12px]">
                <div className="flex-[3]">{phase.title}</div>
                <div className="flex-[5] pt-[6px] pb-[6px]">
                  <input
                    data-cy={kebabCase(`${trackType}-${phase.key}`)}
                    type="text"
                    className={`input-number-custom ${
                      getTotalWeight() != 100 ? 'error' : ''
                    }`}
                    defaultValue={phase.weight || 0}
                    onChange={(e) =>
                      setWeight(phase.key, e.currentTarget.value)
                    }
                    onKeyPress={(e) => _onKeyPress(e)}
                  />
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default SetWeightBox;

import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { getQuoteCalibration } from 'client/FormalReviewClient';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import ModalSetQuota from 'components/formal-review/calibration/ModalSetQuota';
import ColumnChart from 'components/shared/charts/columnChart';

const CalibrationQuotaSection = ({
  isPerfCategory,
  quotaConfig,
  currentCalibratorId
}) => {
  const [highChartData, setHighChartData] = useState([]);
  const [quotaModal, setQuotaModal] = useState({ show: false });

  const pageParams = useParams();
  const filter = useFilter((state) => state.filterById?.calibration);

  // if config quota is set to disabled
  // we need to change the graph to show only the distribution
  const graphType =
    quotaConfig != 'disabled' ? 'quota_graph' : 'team_result_graph';
  const chartType = quotaConfig != 'disabled' ? 'calibration' : 'distribution';

  const query = useMemo(() => {
    const params = {
      division: filter?.Division,
      department: filter?.Department,
      showType: filter?.show?.map((e) => e?.id),
      managerIds: filter?.manager?.map((e) => e?.id),
      admin_mode: true,
      calibratorId: currentCalibratorId,
      ...filter
    };
    delete params.show;
    filter?.manager?.length > 0 && delete params.manager;
    params.graphType = graphType;
    return params;
  }, [filter, graphType, currentCalibratorId]);

  let { data, isLoading } = useQuery(['calibration', 'quota', query], () =>
    getQuoteCalibration(pageParams.id, query)
  );

  useEffect(() => {
    if (data) {
      let newData = [];
      newData.push(data.data);
      setHighChartData(newData);
    } else {
      setHighChartData([]);
    }
  }, [data]);

  return (
    <div className="px-[40px]">
      <div className="wrapper-chart-result" id="chart-container">
        <div className="h-[68px] px-[16px] flex items-center flex justify-between">
          <p className="typography-h500 text-n-900">
            {getObjectiveLocale('Final score distribution')}
          </p>
          {quotaConfig == 'enabled' && (
            <div>
              <Button.Secondary
                onClick={() => setQuotaModal({ show: true })}
                disabled={!highChartData[0]?.quotas}
              >
                {getObjectiveLocale('Set quota')}
              </Button.Secondary>
            </div>
          )}
        </div>
        <ColumnChart
          chartData={highChartData}
          isLoading={isLoading}
          chartType={chartType}
          isPerfCategory={isPerfCategory}
          withTooltip={chartType == 'calibration'}
        />
      </div>
      {quotaModal?.show && (
        <ModalSetQuota
          setQuotaModal={setQuotaModal}
          dataOptions={highChartData[0]?.quotas}
          cycle={pageParams.id}
          total={highChartData[0]?.totalTarget}
          isPerfCategory={isPerfCategory}
          currentCalibratorId={currentCalibratorId}
        />
      )}
    </div>
  );
};

export default CalibrationQuotaSection;

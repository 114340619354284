import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

import Config from 'utils/Config';

const PropTypesParam = {
  data: PropTypes.object,
  isNumber: PropTypes.bool,
  analyticDataLengths: PropTypes.number
};

const DefaultPropsParam = {
  data: {},
  isNumber: PropTypes.bool,
  analyticDataLengths: PropTypes.number
};

const BarChart = ({ data, isNumber, analyticDataLengths }) => {
  let config = {
    chart: {
      style: {
        fontFamily: 'Inter'
      },
      backgroundColor: 'var(--n-000)',
      type: 'bar',
      height: analyticDataLengths <= 3 ? 500 : analyticDataLengths * 100,
      marginTop: 50,
      marginBottom: 100
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      floating: true,
      x: 0,
      y: 0
    },
    title: {
      text: 'Growth'
    },
    xAxis: {
      categories: data.categories,
      labels: {
        style: {
          width: '100px',
          minWidth: '100px',
          left: '125px'
        },
        useHTML: true
      }
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    tooltip: {
      pointFormatter: function () {
        var point = this;
        return (
          '<span style="color:' +
          point.color +
          '">\u25CF</span> ' +
          point.series.name +
          ': <b>' +
          point.y +
          ' ' +
          (isNumber ? 'users' : '%') +
          '</b><br/>'
        );
      },
      shared: true
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        // width: 150,
        pointWidth: 30,
        groupPadding: 0
      },
      bar: {
        dataLabels: {
          enabled: true,
          align: 'right',
          formatter: function () {
            return isNumber ? this.point.y : this.point.y + '%';
          }
        }
      }
    },
    series: data.series,
    exporting: {
      buttons: {
        contextButton: {
          symbol: Config.asset.general.iconDownload
        }
      }
    }
  };
  return <HighchartsReact highcharts={Highcharts} options={config} />;
};

BarChart.propTypes = PropTypesParam;
BarChart.defaultProps = DefaultPropsParam;

export default BarChart;

import React, { useCallback, useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { getAllTeam } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import useDebounce from 'hooks/useDebounce';
import Config from 'utils/Config';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';
import { getLeader } from 'utils/TeamHelper';

import AWSImage from 'components/design-system/AWSImage';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import SearchBar from 'components/shared/SearchBar';

const TribeList = ({ tribe, index, onClick, customClass = '' }) => {
  const leader = getLeader(tribe?.involvements)?.user;
  return (
    <div
      className={`py-[8px] px-[16px] flex ${
        onClick ? 'hover:bg-base-3008 cursor-pointer' : ''
      } ${customClass}`}
      key={`tribe-${tribe?.id}-${index}`}
      onClick={() => onClick && onClick(tribe)}
      data-cy={`tribe-${tribe?.id}`}
    >
      <AWSImage
        src={tribe?.teamPicture || Config.asset.general.brokenImage}
        className="h-[32px] w-[32px] mr-[16px] rounded-[4px]"
      />
      <div>
        <p className="typography-h400 text-n-900" data-cy="tribe-name">
          {tribe?.name}
        </p>
        <p className="typography-h100 text-n-800" data-cy="tribe-description">
          {leader?.name} · {leader?.jobTitle}
        </p>
      </div>
    </div>
  );
};

const AlignTribe = ({ selectedTribe, setSelectedTribe }) => {
  const { user } = useUser();
  const [showPopupTribe, setShowPopupTribe] = useState(false);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [onLoadMore, setLoadMore] = useState(false);
  const [tribes, setTribes] = useState([]);
  const [olderThan, setOlderThan] = useState(0);

  const firstRender = useRef(true);

  const debounceSearchValue = useDebounce(search, 500);

  const popupMemberRef = useRef();
  useClickOutside(popupMemberRef, () => setShowPopupTribe(false));

  const onClickTribeSuggestion = (tribe) => {
    setSelectedTribe(tribe);
    setShowPopupTribe(false);
  };

  const _getData = useCallback(async (query) => {
    setIsLoading(true);
    let allParams = {
      reviewsVisibility: 1,
      limit: 15,
      query: query?.q,
      teamType: 'tribe',
      involvement: ['pmo', 'leader', 'member'],
      involvedUserId: user?.id,
      ...query
    };
    const { data, isSuccess, pagination } = await getAllTeam(allParams);
    if (isSuccess) {
      data?.length > 0 &&
        setTribes((state) =>
          query?.olderThan ? [...state.tribes, ...data] : data
        );
      setOlderThan(pagination?.next?.olderThan);
    }
    setIsLoading(false);
  }, []);

  const onScroll = (e) => {
    const target = e.target;
    const query = { olderThan: olderThan };

    const loadMore = async () => {
      if (search != '') {
        query.q = search;
      }

      setLoadMore(true);
      await _getData(query);
      setLoadMore(false);
    };

    if (!onLoadMore && olderThan) {
      loadMoreValidator(target, 200, () => {
        loadMore();
      });
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      _getData({ q: debounceSearchValue });
    }
  }, [debounceSearchValue, _getData]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  return (
    <>
      {isEmpty(selectedTribe) && (
        <>
          <p className="mb-[8px] typography-h100 text-n-800">
            {getObjectiveLocale('Search tribe')}
          </p>
          <div className="relative">
            <SearchBar
              placeholder="Ex: Mortage Service..."
              onChange={(e) => setSearch(e.target.value)}
              fullWidth
              iconFillColor="var(--n-600)"
              onFocus={() => setShowPopupTribe(true)}
              dataCy="search-tribe"
            />
            {showPopupTribe && (
              <div
                className="absolute w-full overflow-auto max-h-[200px] bg-n-000 z-[2] shadow-raised"
                onScroll={(e) => onScroll(e)}
                ref={popupMemberRef}
              >
                {isLoading && !onLoadMore && (
                  <LoadingComponent className="min-h-[64px]" />
                )}
                {tribes?.length > 0 &&
                  tribes?.map((tribe, index) => (
                    <TribeList
                      tribe={tribe}
                      index={index}
                      onClick={onClickTribeSuggestion}
                    />
                  ))}
                {onLoadMore && <LoadingComponent />}
              </div>
            )}
          </div>
        </>
      )}
      {!isEmpty(selectedTribe) && (
        <>
          <div className="flex justify-between">
            <p className="typography-h300 text-n-600 uppercase h-[36px] flex items-center max-w-[344px]">
              {getObjectiveLocale('Selected tribe')}
            </p>
            <p className="typography-h300 text-n-600 uppercase h-[36px] flex items-center w-[144px] mr-[64px] justify-end">
              {getObjectiveLocale('Member')}
            </p>
          </div>

          <div className="max-h-[280px] flex justify-between items-center">
            <TribeList
              tribe={selectedTribe}
              index="selected-tribe"
              customClass="w-[344px]"
            />
            <div className="w-[144px] flex items-center">
              <p
                className="typography-paragraph text-n-800 w-[144px] mr-[40px] flex items-center justify-end"
                data-cy="total-member"
              >
                {selectedTribe?.totalMembers}
              </p>
              <SVGIcon
                size="24"
                iconName="icon-delete"
                fillColor="var(--n-600)"
                onClick={() => setSelectedTribe({})}
                dataCy="delete-selected-tribe"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AlignTribe;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

import { useUser } from 'context/UserContext';

import AuthPage from 'components/authentication/AuthPage';
import Faq from 'oldPages/Faq';
import LandingPageSharePost from 'pages/LandingPageSharePost';

import LoginAuto from './components/authentication/LoginAuto';

function RedirectLogin() {
  const { organization } = useUser();

  const isErrorQuery = qs.parse(location.search, {
    ignoreQueryPrefix: true
  })?.error;

  if (isErrorQuery) {
    const authFailed = isErrorQuery === 'Authentication failed';
    alert(
      authFailed
        ? "Your account had been deactivated, you're no longer can access this site."
        : "Sorry, we couldn't find your account. Please make sure your email has been registered."
    );
  }

  if (
    !location.pathname.includes('login') ||
    !location.pathname.includes('recovery') ||
    !location.pathname.includes('activation')
  ) {
    localStorage.setItem('prevUrlRedirect', location.href);
  }

  let url = '/login/email';
  if (
    (!isEmpty(organization?.loginType) &&
      organization?.loginType !== 'default') ||
    isErrorQuery
  ) {
    url = '/login/auth';
  }

  return <Redirect to={url} />;
}

function UnAuthenticatedApp() {
  return (
    <>
      <Router>
        <Switch>
          <Route path={['/guide/auto-roll-up', '/faq']} component={Faq} />
          <Route
            exact
            path={[
              '/login/organization',
              '/login/authentication',
              '/login/email',
              '/login/recovery',
              '/login/password',
              '/recovery',
              '/activation',
              '/login/appli',
              '/login/auth',
              '/login/email-registration',
              '/login/workunit',
              '/login/manager-registration',
              '/login/birthdate'
            ]}
            component={AuthPage}
          />
          <Route exact path="/login/auto" component={LoginAuto} />
          <Route path={['/posts']} component={LandingPageSharePost} />
          <RedirectLogin />
        </Switch>
      </Router>
    </>
  );
}

export default UnAuthenticatedApp;

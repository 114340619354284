import React, { useContext } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import produce, { setAutoFreeze } from 'immer';

import {
  getFeedsTimeline,
  getFeedsbyLabel,
  getFilterFeeds,
  getPostById,
  getRecentFeelingPost,
  getRecognitionPost,
  getSavedPost
} from 'client/CultureClient';
import { useUser } from 'context/UserContext';
import { loadMoreValidator } from 'utils/HelperUtils';

const FeedsContext = React.createContext();

function useFeeds(groupId, userId, extraParams) {
  const queryClient = useQueryClient();
  const [{ filter, page, isLoading }, immerSetState] = useContext(FeedsContext);

  function setFilter(filter) {
    immerSetState((draft) => {
      draft.filter = filter;
    });
  }

  function setIsLoading(isLoading) {
    immerSetState((draft) => {
      draft.isLoading = isLoading;
    });
  }

  function setPage(page) {
    immerSetState((draft) => {
      draft.page = page;
    });
  }

  const fetchFeeds = (pageParam) => {
    let allParams = {
      limit: 10,
      lastId: pageParam
    };
    let typeFunction = () => {};
    switch (filter) {
      case 'recent':
      case 'feeling':
        if (filter === 'feeling') allParams.postType = filter;
        typeFunction = getRecentFeelingPost(userId, allParams);
        break;
      case 'recognition':
        typeFunction = getRecognitionPost(userId, allParams);
        break;
      case 'saved':
        typeFunction = getSavedPost({ limit: 20 });
        break;
      case 'recognition-filter':
      case 'poll-filter':
      case 'feeling-filter':
        const typeFilter = filter.split('-')[0];
        typeFunction = getFilterFeeds(typeFilter, allParams);
        break;
      case 'group-recent':
      case 'group-feeling':
      case 'group-popular':
        filter === 'group-popular' && (allParams.popular = 1);
        filter === 'group-feeling' && (allParams.postType = 'feeling');
        typeFunction = getFeedsTimeline(groupId, allParams);
        break;
      case 'single-feed':
        typeFunction = getPostById(extraParams);
        break;
      default:
        if (typeof filter === 'number') {
          typeFunction = getFeedsbyLabel(filter, allParams);
        }
    }

    return typeFunction;
  };

  let feeds = [];
  let queryKey = ['culture', page];

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery(queryKey, ({ pageParam }) => fetchFeeds(pageParam), {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.pagination?.next?.olderThan,
      suspense: false,
      enabled: !isFetching
    });

  if (data?.pages?.[0]?.data?.id) {
    feeds = [data?.pages?.[0]?.data];
  } else {
    data?.pages?.forEach((page) =>
      page?.data?.forEach((feed) => {
        feeds.push(feed);
      })
    );
  }

  const refetch = () => {
    queryClient.invalidateQueries(queryKey);
  };

  const onScroll = (e) => {
    const target = e.target;
    document.querySelectorAll('video').forEach((v) => {
      v.pause();
    });

    const loadMore = async () => {
      setIsLoading(true);
      await fetchNextPage();
      setIsLoading(false);
    };

    if (!isLoading && hasNextPage) {
      loadMoreValidator(target, 50, () => {
        loadMore();
      });
    }
  };

  return {
    feeds,
    onScroll,
    refetch,
    isFetchingMore: isFetchingNextPage,
    isFetching,
    filter,
    setFilter,
    page,
    setPage,
    isLoading
  };
}

function FeedsProvider(props) {
  let initialValue = {
    feeds: [],
    onScroll: () => {},
    refetch: () => {},
    isFetchingMore: false,
    isFetching: false,
    filter: '',
    setFilter: () => {},
    page: '',
    setPage: () => {},
    isLoading: false
  };

  const [state, setState] = React.useState(initialValue);
  setAutoFreeze(false);

  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];

  return <FeedsContext.Provider value={contextValue} {...props} />;
}

export { useFeeds, FeedsProvider };

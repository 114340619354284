import React from "react";
import { getObjectiveLocale } from "utils/HelperUtils";
import AWSImage from "components/design-system/AWSImage";

const Squad = ({ objectiveValue }) => {
  return (
    <div className="w-full px-[24px] mb-[16px]">
      <p className="typography-h100 text-n-800 mb-8">{getObjectiveLocale("Squad")}</p>
      <div className="flex items-center">
        <AWSImage className="w-[24px] h-[24px] rounded-[4px] mr-[8px]" src={objectiveValue?.team?.teamPicture} />
        <p className="typography-paragraph text-n-900">
          {objectiveValue?.team?.name}
        </p>
      </div>
    </div>
  )
}

export default Squad;
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import flatten from 'lodash/flatten';

import { useUrl } from 'hooks/useUrl';
import { formatTimezone } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import ObjectivesCardList from 'components/objectives/ObjectivesCardList';
import ObjectiveEmptyState from 'components/shared/ObjectiveEmptyState';
import ProfileSection from 'components/shared/Profile/ProfileSection';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import SVGIcon from 'src/components/shared/SVGIcon';
import { useGetSubordinates } from 'src/hooks/api/useSubordinates';

import SubordinateCardContent from './subordinateTree/SubordinateCardContent';

function SubordinateTreeCard({ user, isParent = true, closing }) {
  const { url } = useUrl();
  const typeOfSubordinate = url?.includes('indirect') ? 'indirect' : 'direct';
  const [showGoals, setShowGoals] = useState(false);
  const [isShowSubordinates, setIsShowSubordinates] = useState(false);
  const [closeChild, setCloseChild] = useState(false);
  const [formerData, setFormerData] = useState([]);
  const [subordinateCount, setSubordinateCount] = useState(0);

  const params = {
    state: 'active',
    placementId: user?.placementId
  };

  const { hasNextPage, fetchNextPage, isFetchingNextPage, isFetching } =
    useGetSubordinates(typeOfSubordinate, user?.id, params, {
      enabled: isParent,
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || undefined,
      onSuccess: (data) => {
        const newSubordinates = flatten(
          data?.pages?.map((page) => {
            return page?.data?.map((d) => {
              return d;
            });
          })
        );
        setSubordinateCount(
          typeOfSubordinate == 'direct'
            ? data?.pages?.[0]?.metadata?.metadata?.directUserAvailableCount
            : data?.pages?.[0]?.metadata?.metadata?.indirectUserAvailableCount
        );
        setFormerData(newSubordinates);
      }
    });

  const showSubordinates = () => {
    if (isShowSubordinates) {
      setCloseChild(true);
      setTimeout(() => {
        setIsShowSubordinates(false);
        setCloseChild(false);
      }, 300);
    } else {
      setIsShowSubordinates(true);
    }
  };

  const newFilter = {
    assigneeId: user?.id,
    parentNotAssignedTo: user?.id,
    periodBegin: formatTimezone(`${new Date().getFullYear()}-01-01`, 'start'),
    periodEndBefore: formatTimezone(`${new Date().getFullYear()}-12-31`, 'end'),
    state: ['running', 'completed', 'reviewed', 'draft', 'edited'],
    type: ['goal', 'annual_goal'],
    placementId: user?.placementId
  };

  useEffect(() => {
    if (isShowSubordinates && formerData.length > 3) {
      document.getElementById('subordinate').style.width = 'fit-content';
    } else {
      document.getElementById('subordinate').style.width = null;
    }
  }, [isShowSubordinates]);

  return (
    <>
      {isFetchingNextPage || isFetching ? (
        <LoadingComponent />
      ) : formerData?.length == 0 && isParent ? (
        <ObjectiveEmptyState page={url} customContainerClassname="w-full" />
      ) : (
        <li
          className={`goal-card-container pb-[12px] ${
            closing ? 'animated card-fade-out' : ''
          } position-${isParent ? 'parent' : 'children'}`}
        >
          <div className="w-[408px] m-auto goal-card-wrapper">
            <div
              data-cy="goal-card"
              className={`goal-tree-card list-objective pivot ${
                isParent ? 'parent no-parent' : 'children no-children'
              } py-[16px]`}
            >
              <div className="card-subordinates">
                <div className="flex justify-between items-center px-[16px]">
                  <ProfileSection
                    name={user.name}
                    desc={user.jobTitle}
                    profilePicture={user.profilePicture}
                    totalOwners={1}
                    avatarSize={32}
                  />
                  <p className="typography-paragraph">
                    {dayjs(user?.positions?.[0]?.startsAt).format("MMM 'YY")} -{' '}
                    {user?.positions?.[0]?.endsAt
                      ? dayjs(user?.positions?.[0]?.endsAt).format("MMM 'YY")
                      : 'Current'}
                  </p>
                </div>
                <SubordinateCardContent
                  isParent={isParent}
                  filter={newFilter}
                  user={user}
                />
              </div>
              <div className="box-alike-container">
                <div className="box-alike box1"></div>
                <div className="box-alike box2"></div>
              </div>
              {!isParent && (
                <div className="inline-buttons">
                  <Button.Secondary
                    datacy="goal-tree-actions"
                    customClass="float-right"
                    onClick={() => {
                      setShowGoals(!showGoals);
                    }}
                  >
                    {getObjectiveLocale('View Goals')}
                  </Button.Secondary>
                </div>
              )}
              {isParent && subordinateCount && (
                <>
                  <div
                    className="total-goal-child"
                    data-cy="goal-tree-total-subordinate"
                  >
                    <span>
                      {subordinateCount} {getObjectiveLocale('Subordinates')}
                    </span>
                  </div>
                  <div className="arrow-button-container">
                    <div
                      className="arrow-button"
                      onClick={() => showSubordinates()}
                      data-cy="goal-tree-show-children"
                    >
                      <SVGIcon
                        size={24}
                        fillColor="var(--n-600)"
                        iconName={
                          isShowSubordinates
                            ? 'icon-keyboard_arrow_up'
                            : 'icon-keyboard_arrow_down'
                        }
                        customClass="up-arrow"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {formerData?.length > 0 && (
            <ul
              className={`goal-card-list ${
                isShowSubordinates ? 'card-expand' : 'hidden'
              }`}
            >
              {formerData?.map((user, index) => (
                <SubordinateTreeCard
                  key={index}
                  user={user}
                  isParent={false}
                  closing={closeChild}
                />
              ))}
            </ul>
          )}
          {showGoals && (
            <ObjectivesCardList
              user={user}
              setShowGoals={setShowGoals}
              showGoals={showGoals}
              filter={newFilter}
            />
          )}
          {hasNextPage && isParent && isShowSubordinates && (
            <div className="flex justify-end mr-[24px]">
              <div
                onClick={() => fetchNextPage()}
                className="rounded-full w-[35px] h-[35px] border-2 border-solid border-n-600 cursor-pointer flex items-center pl-[4px] bg-n-000"
              >
                <SVGIcon
                  size={24}
                  fillColor="var(--n-600)"
                  iconName="icon-keyboard_arrow_right"
                />
              </div>
            </div>
          )}
        </li>
      )}
    </>
  );
}

export default SubordinateTreeCard;

import React from 'react';

const GanttBackgroundDaysColumn = ({ daysOfYear }) => {
  const todayTimestamp = new Date().setHours(0, 0, 0, 0);

  return (
    <>
      {daysOfYear.map(({ id }) => (
        <div
          className={`tm-bg-wrapper relative h-[100%] w-[48px] ${
            todayTimestamp == id ? 'bg-n-100' : ''
          }`}
          key={'days' + id}
        />
      ))}
    </>
  );
};

export default GanttBackgroundDaysColumn;

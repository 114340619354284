import React from 'react';

import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';
import AntdCalendar from 'components/shared/Calendar/AntdCalendar';
import SVGIcon from 'components/shared/SVGIcon';

const ModalSetDate = ({
  title,
  bodyText,
  handleClose,
  setDateValueHandler,
  currentDate,
  submitModal
}) => {
  return (
    <Modal onClose={handleClose} minWidth={400} customClass="w-[400px]">
      <Modal.Header title={getObjectiveLocale(title)} onClose={handleClose} />
      <Modal.Body>
        <div className="flex flex-col px-[24px] relative">
          <p className="mb-[10px]">{getObjectiveLocale(bodyText)}</p>
          <AntdCalendar>
            <AntdCalendar.Trigger>
              <div className="border-[1px] border-solid border-n-400 rounded-[4px] py-[4px] pr-[8px] pl-[16px] items-center flex justify-between w-[100%]">
                <span className="typography-paragraph mr-[16px] cursor-pointer">
                  {getDateLocale(currentDate)}
                </span>
                <SVGIcon
                  size={24}
                  iconName="icon-calendar_today"
                  fillColor="var(--base-600)"
                />
              </div>
            </AntdCalendar.Trigger>
            <AntdCalendar.Content
              setDateValueHandler={setDateValueHandler}
              customPosition="!left-[200px]"
              customBox="w-[352px]"
              isDual
              leftSidebarContent="no"
              calendarType="reviewDeadline"
            />
          </AntdCalendar>
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={{
          text: getObjectiveLocale('Set the Date'),
          onClick: submitModal
        }}
        secondaryButton={{
          text: getObjectiveLocale('Cancel'),
          onClick: handleClose
        }}
        useBorder={false}
      />
    </Modal>
  );
};

export default ModalSetDate;

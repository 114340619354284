import React, { useEffect, useState } from 'react';

import { finalizeFormalReview } from 'client/FormalReviewClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { capitalize, getObjectiveLocale } from 'utils/HelperUtils';

import BehaviorScoringMatrixScale from 'components/formal-review/BehaviorScoringMatrixScale.js';
import GeneralQuestion from 'components/formal-review/GeneralQuestion';
import GoalScoringViewOnly from 'components/formal-review/GoalScoringViewOnly.js';
import GoalsScoring from 'components/formal-review/GoalsScoring';
import GoalsScoringInputScore from 'components/formal-review/GoalsScoringInputScore';
import RecognitionFeedback from 'components/formal-review/RecognitionFeedback.js';
import RevieweeFeedback from 'components/formal-review/RevieweeFeedback.js';
import TasksScoring from 'components/formal-review/TasksScoring';
import BehaviorScoringEach from 'components/formal-review/annual-review/BehaviorScoringEach';
import HeaderWorkScore from 'components/formal-review/annual-review/HeaderWorkScore';
import SummaryAnnualReview from 'components/formal-review/annual-review/summary/SummaryAnnualReview';
import PerformanceRating from 'components/formal-review/performance-rating/PerformanceRating';
import BannerBox from 'components/shared/BannerBox';
import Checkbox from 'components/shared/Checkbox';
import Footer from 'components/shared/Footer';
import GoalScoringOverallScore from 'src/components/formal-review/GoalScoringOverallScore';
import TaskScoringViewOnly from 'src/components/formal-review/TaskScoringViewOnly.js';
import GoalsScoringEach from 'src/components/formal-review/annual-review/GoalsScoringEach';
import MultipleReviewScorings from 'src/components/formal-review/custom-attributes/MultipleReviewScorings';
import { ValueCompetencyProvider } from 'src/context/admin/ValueCompetencyContext';

import BannerPrefillAnswers from './BannerPrefillAnswers';
import './FormalReviewContent.scss';
import GoalsScoringWork from './annual-review/GoalsScoringWork';

const FormalReviewContent = (props) => {
  const [sectionsBefore, setSectionsBefore] = useState([]);
  const [sectionsAfter, setSectionsAfter] = useState([]);

  let [
    {
      cycle,
      involvedUser,
      currentTrack,
      currentTrackId,
      assignmentId,
      phase,
      trackLoading
    },
    {
      setAnswerContext,
      setBehaviourEvidence,
      getSingleTrack,
      getSingleFormalReview
    }
  ] = useFormalReview();

  const {
    state,
    setShowRightSidebar,
    setOverlaySidebar,
    overlaySidebar,
    currentRevieweeFeedbackType,
    setCurrentRevieweeFeedbackType,
    saveCallback,
    handleClickBack,
    handleClickNext,
    isNextDisabled,
    isPrevDisabled,
    isLastTrack
  } = props;
  const { user } = useUser();
  const { actor, target } = involvedUser;
  const {
    preFill,
    suggestions,
    questionnairePreFill,
    questionnaireSuggestion,
    components,
    objects,
    template,
    answers,
    average = 0,
    showOption = 'score_and_label',
    name,
    trackConfig,
    reviewAspects
  } = getSingleTrack(currentTrackId) || {};
  const { mechanism, view, sections, questionsCount, formula } = template || {};
  const showBannerBox = user.id !== actor.id;
  const showCurrentSection =
    currentTrack == 'goals_scoring' ? mechanism !== 'overall_score' : true;
  const _finalizeFormalReview = async () => {
    let stateTemp = state == 'in_progress' ? 'done' : 'in_progress';
    const { data } = await finalizeFormalReview(assignmentId, 'summary', {
      state: stateTemp
    });
    if (data) {
      getSingleFormalReview(assignmentId);
    }
  };

  const isStickyBannerPrefillShow = () => {
    if (
      currentTrack != 'general_questionnaire' &&
      currentTrack != 'review_aspects_scoring'
    ) {
      return false;
    }

    if (
      phase.type === 'manager_review' ||
      phase.type === 'indirect_manager_review'
    ) {
      if (currentTrack == 'review_aspects_scoring') {
        if (trackConfig?.autoFill) {
          return (
            reviewAspects?.some((aspect) => aspect.preFill?.length > 0) ||
            questionnairePreFill?.length > 0
          );
        }

        return (
          reviewAspects?.some((aspect) => aspect.suggestions?.length > 0) ||
          questionnaireSuggestion?.length > 0
        );
      }

      return suggestions?.length > 0 || questionnaireSuggestion?.length > 0;
    }
  };

  const isBannerPrefillShow = () => {
    if (
      currentTrack == 'general_questionnaire' ||
      currentTrack === 'review_aspects_scoring'
    ) {
      return false;
    }

    if (
      phase.type === 'manager_review' ||
      phase.type === 'indirect_manager_review'
    ) {
      return suggestions?.length > 0 || questionnaireSuggestion?.length > 0;
    }
  };

  useEffect(() => {
    let tempSectionsBefore = [];
    let tempSectionsAfter = [];
    if (sections?.length > 0) {
      tempSectionsBefore = sections?.filter(
        (section) => section?.position == 'before'
      );
      tempSectionsAfter = sections?.filter(
        (section) => section?.position == 'after'
      );
    }
    setSectionsBefore(tempSectionsBefore);
    setSectionsAfter(tempSectionsAfter);
  }, [sections]);

  return (
    !trackLoading && (
      <div
        className={`formal-review-content ${
          currentTrack === 'summary' ? 'w-[720px]' : ''
        }`}
      >
        {/* currently only track general_questionnaire & custom attribute track using this sticky banner */}
        {isStickyBannerPrefillShow() && (
          <BannerPrefillAnswers
            currentTrackId={currentTrackId}
            currentTrack={currentTrack}
            isStickyBanner
          />
        )}

        {showBannerBox && (
          <BannerBox
            text={`You are currently filling out this performance review as ${actor.name}`}
            type="info"
            customClass="mt-[24px] !mb-[0px]"
          />
        )}
        <div
          data-cy="scoring-title"
          className={`${
            isStickyBannerPrefillShow() ? 'mt-[24px]' : 'mt-[40px]'
          } ${
            currentTrack == 'review_aspects_scoring' ? 'mb-[8px]' : 'mb-[24px]'
          } text-n-900 typography-h700`}
        >
          {getObjectiveLocale(name)}
        </div>
        <p
          data-cy="scoring-description"
          className="typography-paragraph text-n-800 mb-[24px] whitespace-pre-line"
        >
          {trackConfig?.description}
        </p>
        {currentTrack == 'review_aspects_scoring' && (
          <HeaderWorkScore showScore={false} />
        )}
        {isBannerPrefillShow() && (
          <BannerPrefillAnswers currentTrackId={currentTrackId} />
        )}

        {sectionsBefore?.length > 0 && questionsCount > 0 && (
          <GeneralQuestion
            key={`${currentTrack}-${currentTrackId}-before`}
            sections={sectionsBefore}
            position="before"
            target={target}
            setAnswer={setAnswerContext}
            assignmentId={assignmentId}
            cycle={cycle}
          />
        )}
        {currentTrack === 'goals_scoring' && mechanism === 'view_only' && (
          <GoalScoringViewOnly state={state} setAnswer={setAnswerContext} />
        )}
        {currentTrack === 'goals_scoring' && mechanism === 'each' && (
          <GoalsScoringEach
            showOption={showOption}
            template={template}
            assignmentId={assignmentId}
            cycle={cycle}
            target={target}
            involvedUser={involvedUser}
            answers={answers}
            components={components}
            currentTrack={currentTrack}
            phase={phase}
            preFill={preFill}
            suggestions={suggestions}
            currentTrackId={currentTrackId}
          />
        )}
        {currentTrack === 'goals_scoring' && mechanism === 'input_score' && (
          <GoalsScoringInputScore
            assignmentId={assignmentId}
            phase={phase}
            preFill={preFill}
            suggestions={suggestions}
            view={view}
          />
        )}
        {(currentTrack === 'goals_scoring' ||
          currentTrack === 'tasks_scoring') &&
          mechanism === 'specific_attribute' && (
            <div>
              <GoalsScoringWork
                components={components}
                cycle={cycle}
                objects={objects}
                assignmentId={assignmentId}
                currentTrack={currentTrack}
                currentTrackId={currentTrackId}
                phase={phase}
                answers={answers}
                template={template}
                showOption={showOption}
                preFill={preFill}
                suggestions={suggestions}
              />
            </div>
          )}
        {currentTrack === 'goals_scoring' &&
          mechanism?.includes('suggested_ongoing') && (
            <GoalsScoring
              state={state}
              setShowRightSidebar={setShowRightSidebar}
              target={target}
              setAnswer={setAnswerContext}
              assignmentId={assignmentId}
              cycle={cycle}
              formula={formula}
              showOption={showOption}
            />
          )}
        {currentTrack === 'goals_scoring' && mechanism === 'overall_score' && (
          <GoalScoringOverallScore
            assignmentId={assignmentId}
            currentTrackId={currentTrackId}
            showAdditionalQuestion={
              sectionsAfter?.length > 0 && questionsCount > 0
            }
            sectionsAfter={sectionsAfter}
            target={target}
            setAnswerContext={setAnswerContext}
          />
        )}
        {currentTrack === 'tasks_scoring' && mechanism === 'view_only' && (
          <TaskScoringViewOnly state={state} setAnswer={setAnswerContext} />
        )}
        {currentTrack === 'tasks_scoring' &&
          mechanism?.includes('suggested_ongoing') && (
            <TasksScoring
              state={state}
              setShowRightSidebar={setShowRightSidebar}
              target={target}
              setAnswer={setAnswerContext}
              assignmentId={assignmentId}
              cycle={cycle}
            />
          )}
        {/* { currentTrack === 'values_scoring' &&
        <BehaviorScoringEach
          showOption={showOption}
          template={template}
          assignmentId={assignmentId}
          cycle={cycle}
          objects={objects}
          components={components}
          seeBehaviour={setBehaviourEvidence}
          answers={answers}
          currentTrack={currentTrack}
          phase={phase}
          average={average}
        />
      } */}
        {(currentTrack === 'competencies_scoring' ||
          currentTrack == 'values_scoring') &&
          (mechanism === 'each' && view === 'matrix' ? (
            <BehaviorScoringMatrixScale
              answers={answers}
              setOverlaySidebar={setOverlaySidebar}
              phase={phase}
              preFill={preFill}
            />
          ) : (
            <BehaviorScoringEach
              preFill={preFill}
              suggestions={suggestions}
              showOption={showOption}
              template={template}
              assignmentId={assignmentId}
              cycle={cycle}
              objects={objects}
              setOverlaySidebar={setOverlaySidebar}
              components={components}
              seeBehaviour={setBehaviourEvidence}
              answers={answers}
              currentTrack={currentTrack}
              phase={phase}
              average={average}
              currentTrackId={currentTrackId}
              view={view}
            />
          ))}
        {(currentTrack === 'competencies_recognition_feedback' ||
          currentTrack === 'values_recognition_feedback') && (
          <ValueCompetencyProvider>
            <RecognitionFeedback key={template?.id} />
          </ValueCompetencyProvider>
        )}
        {currentTrack === 'summary' && (
          <SummaryAnnualReview
            assignmentId={assignmentId}
            trackTemplate={template}
            phase={phase}
            trackConfig={trackConfig}
          />
        )}
        {currentTrack === 'reviewee_feedback' && (
          <RevieweeFeedback
            setOverlaySidebar={setOverlaySidebar}
            overlaySidebar={overlaySidebar}
            currentRevieweeFeedbackType={currentRevieweeFeedbackType}
            setCurrentRevieweeFeedbackType={setCurrentRevieweeFeedbackType}
          />
        )}
        {currentTrack == 'performance_rating' && (
          <PerformanceRating
            assignmentId={assignmentId}
            type={currentTrack}
            target={target}
            isTeamCycle={cycle?.team?.id}
          />
        )}
        {currentTrack === 'review_aspects_scoring' && (
          <MultipleReviewScorings
            currentTrack={currentTrack}
            currentTrackId={currentTrackId}
            saveCallback={saveCallback}
          />
        )}

        {/* {currentTrack === 'review_aspects_scoring' && (
        <ScoredAttributes
          components={components}
          currentTrack={currentTrack}
          currentTrackId={currentTrackId}
          answers={answers}
          trackId={trackId}
          objects={objects}
          showOption={showOption}
          view={view}
          sliderLabel={sliderLabel}
          mechanism={mechanism}
          configReviewAspects={configReviewAspects}
          useComment={template?.useComment}
          saveCallback={saveCallback}
          template={template}
          suggestions={suggestions}
          phase={phase}
        />
      )} */}

        {sectionsAfter?.length > 0 &&
          questionsCount > 0 &&
          showCurrentSection && (
            <GeneralQuestion
              key={`${currentTrack}-${currentTrackId}-after`}
              sections={sectionsAfter}
              position="after"
              target={target}
              setAnswer={setAnswerContext}
              assignmentId={assignmentId}
              cycle={cycle}
            />
          )}
        <Footer
          secondaryButton={true}
          style={{
            position: 'fixed',
            bottom: '0',
            left: '320px',
            width: 'calc(100% - 320px)',
            borderLeft: '1px solid var(--n-400)'
          }}
          customClass={
            currentTrack == 'summary' ? 'flex items-center justify-between' : ''
          }
          handleClick={handleClickNext}
          handleRoute={handleClickBack}
          buttonPrimaryName={
            isLastTrack()
              ? getObjectiveLocale('Submit Review')
              : capitalize(getObjectiveLocale('Next'))
          }
          linkButtonName={getObjectiveLocale('Back')}
          buttonPrimaryDisabled={isNextDisabled}
          linkButtonDisabled={isPrevDisabled}
        >
          {currentTrack === 'summary' && (
            <Checkbox
              dataCy="finalized-score"
              name="finalized-score"
              id="finalized-score"
              customContainerClass="ml-[40px]"
              checked={state !== 'in_progress'}
              labelClass="w-full"
              onChange={() => _finalizeFormalReview()}
            >
              <p className="typography-paragraph ml-[8px]">
                {getObjectiveLocale(
                  `[${phase.type.replace(
                    /_/g,
                    ' '
                  )}] Check here to confirm that all the detailed scores above are correct before you submit the review.`
                )}
              </p>
            </Checkbox>
          )}
        </Footer>
      </div>
    )
  );
};

export default FormalReviewContent;

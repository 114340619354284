import React, { useEffect, useState } from 'react';

import { getConfig } from 'client/admin/Config';
import { useUser } from 'context/UserContext';
import useOrganization from 'hooks/useOrganization';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ContentNavigator from 'components/shared/ContentNavigator';

import {
  CheckboxWithDefaultOrganization,
  DropdownOrganization,
  ToggleCard,
  changeUserData,
  setActiveNavigator
} from './OrganizationHelper';

const Profile = ({ appData, configParams }) => {
  const { appType, user } = useUser();
  const { configData, changeConfigData, changeOriginConfigData } =
    useOrganization((configData) => ({
      configData: configData.configData,
      changeConfigData: configData.changeConfigData,
      changeOriginConfigData: configData.changeOriginConfigData,
      appData: configData.appData
    }));
  const [activeSection, setActiveSection] = useState('auth-section');
  const isReadOnly =
    usePermission('settingsOrganizationProfile') === 'read_only';

  let list = [
    { id: 'auth-section', name: 'Authentication' },
    { id: 'overview-section', name: 'Overview' },
    { id: 'other-section', name: 'Other' },
    { id: 'cover-section', name: 'Cover' }
  ];

  const listCover = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Update Cover Picture'),
      cardDescription: getObjectiveLocale(
        'Enable all users to update cover picture'
      ),
      onChange: () =>
        changeUserData(
          'updateCoverPicture',
          'featureConfig',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.featureConfig?.updateCoverPicture,
      dataCy: 'updateCoverPicture',
      disabled: isReadOnly
    }
  ];

  const optionsFilter = [
    { id: 'today', title: getObjectiveLocale('Today') },
    { id: 'all_time', title: getObjectiveLocale('All Time') },
    { id: 'current_year', title: getObjectiveLocale('This Year') },
    { id: 'past_years', title: getObjectiveLocale('Past Year') }
  ];

  const defaultListFilter = ['today', 'all_time', 'current_year', 'past_years'];

  const handleCheckFilter = (checked, data) => {
    const changedData = optionsFilter.find((dt) => dt.id == data);
    let listFilterClone = [...defaultListFilter];
    let result = [];
    let reservedData = [...configData?.profileTimeFilterOptions];
    if (reservedData.length > 0) {
      const defaultFilter =
        configData?.profileTimeFilterOptions[0] || changedData.id;
      reservedData.shift();
      const indexData = reservedData.findIndex(
        (list) => list == changedData.id
      );
      if (indexData > -1) {
        reservedData.splice(indexData, 1);
      } else {
        reservedData.push(changedData.id);
      }
      let filteredResult = listFilterClone.filter((list) => {
        const isDefault =
          defaultFilter == changedData.id ? list != defaultFilter : true;
        return reservedData.includes(list) && isDefault;
      });
      if (defaultFilter != changedData.id) {
        filteredResult.unshift(defaultFilter);
      }
      result = [...filteredResult];
    } else {
      result = [changedData.id];
    }
    changeConfigData({ ...configData, profileTimeFilterOptions: result });
  };

  const listOthers = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Send Email'),
      cardDescription: getObjectiveLocale(
        `Show "Send E-mail" menu button on user's profile`
      ),
      onChange: () =>
        changeUserData(
          'sendEmail',
          'actionOnOtherUsers',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.actionOnOtherUsers?.sendEmail,
      dataCy: 'sendEmail',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Call'),
      cardDescription: getObjectiveLocale(
        `Show "Call" menu button on user's profile`
      ),
      onChange: () =>
        changeUserData(
          'call',
          'actionOnOtherUsers',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.actionOnOtherUsers?.call,
      dataCy: 'call',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Save Contact'),
      cardDescription: getObjectiveLocale(
        `Show "Save Contact" menu button on user's profile`
      ),
      onChange: () =>
        changeUserData(
          'saveContact',
          'actionOnOtherUsers',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.actionOnOtherUsers?.saveContact,
      dataCy: 'saveContact',
      disabled: isReadOnly
    }
  ];

  const listUserField = [
    { id: '', title: 'Hidden', customClass: 'italic' },
    { id: 'job_title', title: 'Job Title' },
    { id: 'directorate', title: 'Directorate' },
    { id: 'department', title: 'Department' },
    { id: 'division', title: 'Division' },
    { id: 'salary_level', title: 'Salary Level' },
    { id: 'city', title: 'City' },
    { id: 'region', title: 'Region' }
  ];

  const listAuthentication = [
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Change Password Allowed'),
      cardDescription: getObjectiveLocale(
        'Enable all users to change password on their own'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          changePasswordAllowed: !configData.changePasswordAllowed
        }),
      isChecked: configData?.changePasswordAllowed,
      dataCy: 'changePasswordAllowed',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Reset Password Allowed'),
      cardDescription: getObjectiveLocale(
        'Enable all users to reset password on their own'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          resetPasswordAllowed: !configData.resetPasswordAllowed
        }),
      isChecked: configData?.resetPasswordAllowed,
      dataCy: 'resetPasswordAllowed',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Update Profile'),
      cardDescription: getObjectiveLocale(
        'Enable all user to update profile on their own'
      ),
      onChange: () =>
        changeUserData(
          'updateProfile',
          'featureConfig',
          !configData?.featureConfig?.updateProfile,
          configData,
          changeConfigData
        ),
      isChecked: configData?.featureConfig?.updateProfile,
      dataCy: 'updateProfile',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: getObjectiveLocale('Update Profile Picture'),
      cardDescription: getObjectiveLocale(
        'Enable all user to update profile picture on their own'
      ),
      onChange: () =>
        changeUserData(
          'updateProfilePicture',
          'featureConfig',
          !configData?.featureConfig?.updateProfilePicture,
          configData,
          changeConfigData
        ),
      isChecked: configData?.featureConfig?.updateProfilePicture,
      dataCy: 'updateProfilePicture',
      disabled: isReadOnly
    },
    {
      element: 'dropdown',
      label: getObjectiveLocale(
        "Default title that is being shown under user's name"
      ),
      onChange: (data) =>
        changeConfigData({
          ...configData,
          userTitleField: data
        }),
      selected: configData?.userTitleField,
      isSingleSelect: true,
      dataCy: 'userTitleField',
      listOption: listUserField,
      disabled: isReadOnly,
      customWrapperClass: 'float-left mr-[24px]',
      disableSelected: configData?.userSubtitleField
    },
    {
      element: 'dropdown',
      label: getObjectiveLocale('Subtitle shown next to the default title'),
      onChange: (data) =>
        changeConfigData({
          ...configData,
          userSubtitleField: data
        }),
      selected: configData?.userSubtitleField,
      isSingleSelect: true,
      dataCy: 'userSubtitleField',
      listOption: listUserField,
      disabled: configData?.userTitleField === '' || isReadOnly,
      customWrapperClass: 'inline-block',
      disableSelected: configData?.userTitleField
    },
    {
      element: 'combined-checkbox',
      section: 'performance',
      title: getObjectiveLocale('Filter option shows on period'),
      withRadioButton: true,
      headerNo: getObjectiveLocale('Filter'),
      headerName: getObjectiveLocale('Default'),
      handleChange: (checked, data) => handleCheckFilter(checked, data),
      selected: configData?.profileTimeFilterOptions,
      dataCy: 'profileTimeFilterOptions',
      listOption: optionsFilter,
      disabled: isReadOnly,
      handleChangeDefault: (data) =>
        changeConfigData({ ...configData, defaultProfileTimeFilter: data }),
      selectedDefault: [configData?.defaultProfileTimeFilter] || []
    }
  ];

  const getData = async () => {
    const { data } = await getConfig(configParams);
    if (data) {
      changeConfigData(data);
      changeOriginConfigData(data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="overflow-auto h-[calc(100vh-64px)] w-[87%] relative"
      id="scrollable-config"
      onScroll={(e) => setActiveNavigator(e, list, setActiveSection, -48)}
    >
      <div className="w-[768px] mt-[24px]">
        <div className="fixed right-0 mt-[24px] w-[160px] z-index-10">
          <ContentNavigator
            title="CONTENTS"
            activeSection={activeSection}
            listNavigatorContents={list}
            additionalOffset={20}
            sectionContent="scrollable-config"
          />
        </div>
        <p className="typography-h300 text-n-800 uppercase mb-[24px]">
          {getObjectiveLocale('Profile Configuration')}
        </p>
        <section
          className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]"
          id="auth-section"
        >
          <p className="typography-h500 text-n-900 mb-[24px]">
            {getObjectiveLocale('Authentication')}
          </p>
          {listAuthentication.map((config, index) =>
            config.element === 'toggle' ? (
              <ToggleCard {...config} key={`0-${index}`} />
            ) : config.element === 'dropdown' ? (
              <DropdownOrganization {...config} key={`0-${index}`} />
            ) : (
              <CheckboxWithDefaultOrganization {...config} key={`2-${index}`} />
            )
          )}
        </section>
        {appType !== 'performance' && user.cultureRole && (
          <>
            <section
              className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]"
              id="other-section"
            >
              <p className="typography-h500 text-n-900 mb-[24px]">
                {getObjectiveLocale('Others')}
              </p>
              {listOthers.map((config, index) =>
                config.element === 'toggle' ? (
                  <ToggleCard {...config} key={`2-${index}`} />
                ) : (
                  <CheckboxWithDefaultOrganization
                    {...config}
                    key={`2-${index}`}
                  />
                )
              )}
            </section>
            <section
              className="pb-[40px] mb-[40px] mt-[24px] pb-[64px]"
              id="cover-section"
            >
              <p className="typography-h500 text-n-900 mb-[24px]">
                {getObjectiveLocale('Cover')}
              </p>
              {listCover.map((config, index) => (
                <ToggleCard {...config} key={`3-${index}`} />
              ))}
            </section>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;

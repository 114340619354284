import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import RadioButton from 'components/shared/RadioButton';
import Toggle from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

function MissionConfiguration({ config, mission, setMission, isEditMission }) {
  return (
    <div
      id="configuration-section"
      className="form-group border-bottom-400 pb-[40px] mt-[40px]"
    >
      <p className="typography-h500-longform text-n-900 mb-[16px]">
        Setup configuration
      </p>
      <div className="border-n400 rounded-[4px] bg-n-000 mb-[16px]">
        <div
          className={`px-[24px] py-[16px] flex justify-between items-center ${
            mission?.isEvent ? 'border-bottom-400' : ''
          }`}
        >
          <div className="flex-col justify-center items-center">
            <p className="typography-h500 mb-[4px]">
              Set this mission as event
            </p>
            <p className="typography-secondary">
              You can show this mission in event leaderboard
            </p>
          </div>
          <Toggle
            id="event-toggle"
            idStr="event-toggle"
            isChecked={mission?.isEvent}
            disabled={
              config?.missionAsEvent
                ? isEditMission
                  ? mission?.status !== 'upcoming'
                  : false
                : true
            }
            onChange={(e) => {
              e.stopPropagation();
              setMission({ ...mission, isEvent: !mission?.isEvent });
            }}
          />
        </div>
        {mission?.isEvent && (
          <div className="px-[24px] py-[16px]">
            <RadioButton
              id="event-points-option-0"
              name="event-points-option"
              dataCy="event-points-option-0"
              checked={mission?.isDefault}
              disabled={isEditMission ? mission?.status !== 'upcoming' : false}
              onClick={() => setMission({ ...mission, isDefault: true })}
              addClass="mb-[8px] h-[24px]"
            >
              <p className="text-n-900 ml-[8px]">
                {getObjectiveLocale('Exclude points from general leaderboard')}{' '}
                <span className="typography-tertiary">(default)</span>
              </p>
            </RadioButton>
            <RadioButton
              id="event-points-option-1"
              name="event-points-option"
              dataCy="event-points-option-1"
              checked={!mission?.isDefault}
              disabled={isEditMission ? mission?.status !== 'upcoming' : false}
              onClick={() => setMission({ ...mission, isDefault: false })}
              addClass="h-[24px]"
            >
              <p className="text-n-900 ml-[8px]">
                {getObjectiveLocale('Include points to general leaderboard')}
              </p>
            </RadioButton>
          </div>
        )}
      </div>
      <div className="border-n400 rounded-[4px] bg-n-000">
        <div className="px-[24px] py-[16px] flex justify-between items-center">
          <div className="flex-col justify-center items-center">
            <p className="typography-h500 mb-[4px]">
              Enable sequential task for this mission
            </p>
            <p className="typography-secondary">
              Tasks must be completed one by one according to the order that you
              set in task setup below
            </p>
          </div>
          <Toggle
            id="sequential-toggle"
            idStr="sequential-toggle"
            isChecked={mission?.isSequential}
            onChange={(e) => {
              e.stopPropagation();
              setMission({ ...mission, isSequential: !mission?.isSequential });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default MissionConfiguration;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Checkbox from 'components/shared/Checkbox';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

const CommentSection = ({ useComment, setUseComment, uniqueId }) => {
  return (
    <div className="mt-[24px] wrapper-component">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <p className="typography-h400 text-n-900 mb-[4px]">
            {getObjectiveLocale('Comment')}
          </p>
          <p className="typography-paragraph text-n-800 mb-[0px]">
            {getObjectiveLocale('Choose to add comment to this configuration')}
          </p>
        </div>
        <SmallToggleSwitchPurple
          idStr="use-comment-toggle"
          isChecked={useComment == 'required' || useComment == 'optional'}
          onChange={() =>
            setUseComment(
              useComment == 'required' || useComment == 'optional'
                ? 'no'
                : 'required'
            )
          }
        />
      </div>

      {(useComment == 'required' || useComment == 'optional') && (
        <Checkbox
          id={uniqueId ? `comment-${uniqueId}` : 'comment'}
          name={'comment'}
          checked={useComment !== 'required'}
          onChange={(e) => {
            setUseComment(e.currentTarget.checked ? 'optional' : 'required');
          }}
          dataCy={'comment-optional'}
        >
          <span className="ml-[8px]">
            {getObjectiveLocale('Set comment as optional')}
          </span>
        </Checkbox>
      )}
    </div>
  );
};

export default CommentSection;

import React, { useEffect, useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import {
  getCustomAttributeList,
  getPrefillConfiguration
} from 'src/utils/FormalReview';

import VerifySection from './VerifySection';

const initialListTracks = [
  {
    trackType: 'values_scoring',
    name: 'Value Final Scoring'
  },
  {
    trackType: 'competencies_scoring',
    name: 'Competencies Final Scoring'
  },
  {
    trackType: 'goals_scoring',
    name: 'Goal Scoring'
  },
  {
    trackType: 'tasks_scoring',
    name: 'Task Scoring'
  },
  {
    trackType: 'general_questionnaire',
    name: 'General Questionnaire'
  }
];

const PrefilledVerify = ({ cycleData }) => {
  return (
    <VerifySection
      title="Suggestion and Pre-filled Answers"
      className="py-[40px]"
      alignItemsClass="items-start"
    >
      <PrefillItems cycleData={cycleData} />
    </VerifySection>
  );
};

const PrefillItems = ({ cycleData }) => {
  const [listTracks, setListTracks] = useState(initialListTracks);

  useEffect(() => {
    const phases = ['selfReview', 'managerReview', 'indirectManagerReview'];
    const customAttributes = getCustomAttributeList(cycleData, phases);

    if (customAttributes?.length > 0) {
      setListTracks((prev) => [...prev, ...customAttributes]);
    } else {
      setListTracks(initialListTracks);
    }
  }, [cycleData]);

  return listTracks.map((track, index) => (
    <PrefillItem
      cycleData={cycleData}
      trackType={track.trackType}
      trackName={track.name}
      templateId={track.templateId}
      key={index}
    />
  ));
};

const PrefillItem = ({ cycleData, trackType, trackName, templateId }) => {
  const {
    isMeetRequirements: isManagerMeetRequirements,
    prefillConfigWording: managerPrefillConfigWording
  } = getPrefillConfiguration(
    cycleData,
    'managerReview',
    trackType === 'review_aspects_scoring' ? templateId : trackType
  );

  const {
    isMeetRequirements: isIndirectManagerMeetRequirements,
    prefillConfigWording: indirectManagerPrefillConfigWording
  } = getPrefillConfiguration(
    cycleData,
    'indirectManagerReview',
    trackType === 'review_aspects_scoring' ? templateId : trackType
  );

  return (
    <div
      className={`flex items-center ${
        !isManagerMeetRequirements && !isIndirectManagerMeetRequirements
          ? 'h-[48px]'
          : 'h-[72px]'
      } `}
    >
      <div className="flex">
        <p className="w-[216px] mr-[32px]">{trackName}</p>
        {!isManagerMeetRequirements && !isIndirectManagerMeetRequirements ? (
          <p className="typography-paragraph text-n-600">
            {getObjectiveLocale('Do not meet requirement')}
          </p>
        ) : (
          <div>
            <p
              className={`typography-paragraph ${
                isManagerMeetRequirements ? 'text-n-900' : 'text-n-600'
              }`}
              data-cy={`${trackType}_manager_review`}
            >
              {getObjectiveLocale(
                `Manager review: ${
                  isManagerMeetRequirements
                    ? managerPrefillConfigWording
                    : 'Do not meet requirement'
                }`
              )}
            </p>
            <p
              className={`typography-paragraph ${
                isIndirectManagerMeetRequirements ? 'text-n-900' : 'text-n-600'
              }`}
              data-cy={`${trackType}_indirect_manager_review`}
            >
              {getObjectiveLocale(
                `Indirect manager Review: ${
                  isIndirectManagerMeetRequirements
                    ? indirectManagerPrefillConfigWording
                    : 'Do not meet requirement'
                }`
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrefilledVerify;

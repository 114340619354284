import React, { createRef, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';

import { getListObjectives } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useLoadingStatus from 'hooks/useLoadingStatus';
import useObjectives from 'hooks/useObjectives';
import useTeam from 'hooks/useTeam';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { restructureFilter } from 'utils/ObjectivesHelper';
import { useDeepEffect } from 'utils/useDeepEffect';

import ConfirmDeleteModal from 'components/modal/ConfirmDeleteModal';
import ObjectivesModal from 'components/objectives/ObjectivesModal';
import FloatingAction from 'components/shared/FloatingAction/FloatingAction';
import HighlightedBubble from 'components/shared/Info/HighlightedBubble';
import LoadingComponent from 'components/shared/LoadingComponent';
import ObjectiveEmptyState from 'components/shared/ObjectiveEmptyState';
import ObjectiveSkeleton from 'components/shared/ObjectiveSkeleton';
import ProjectCardSkeleton from 'components/shared/ProjectCardSkeleton';
import ErrorBoundary from 'pages/ErrorBoundary';
import { updateStateObjective } from 'src/client/ObjectivesClient';
import useMultipleEdit from 'src/hooks/useMultipleEdit';

import GroupModeObjectives from './GroupModeObjectives';
import Objective from './Objective';
import SwitchAbleObjective from './SwitchAbleObjective';
import CompactSkeleton from './compact-objective/CompactSkeleton';
import InlineCreate from './inline-create/InlineCreate';

// ERROR FALLBACK
const ErrorFallback = () => (
  <div className="empty-goal">There's an error when open objectives</div>
);

// COMPONENT
function ObjectivesComponent({
  customClass,
  cantSee = false,
  initialData,
  filter,
  extraFilter,
  cardModel,
  totalColumn = 3,
  depthLevel = 7,
  group = 'no-group',
  page,
  queryFn,
  refetchKey,
  isRefetch = false,
  switchAble,
  switchOptions,
  gridArea,
  profileFilter,
  withFilterMenu,
  withMarginTop,
  user,
  bonusFilter,
  switchType,
  useFetchObjective = true,
  switcherValue,
  userPermission,
  saveCallback,
  showBadgeObjectiveCount,
  headerCardList = [],
  isInlineCreate,
  setInlineCreate,
  groupName,
  queryFnTopParent,
  emptyStateAction,
  isAbleToCreate,
  additionalWrapperClick = () => null,
  onBoardStatus,
  setOnBoardStatus,
  emptyStateData = {},
  ...rest
}) {
  const {
    user: userDefault,
    config: { newObjectiveLayout }
  } = useUser();
  const {
    refetchObjectives,
    refetchObjective,
    refetchSubObjectives,
    refetchQueries
  } = useRefetchQuery();
  const { approvalParentGoal } = useTeam();
  const queryClient = useQueryClient();
  const isMyGoalPage = page == 'mygoals';
  user = user ? user : userDefault;
  let newfilter = withFilterMenu
    ? restructureFilter(filter, page, bonusFilter)
    : filter;
  let params = merge(newfilter, extraFilter, profileFilter);
  // QUERY FN
  const fetchObjectives = (pageParam) => {
    let allParams = {
      reviewsVisibility: 1,
      limit: 15,
      olderThan: pageParam,
      ...params
    };

    if (page === 'response') {
      delete allParams.all;
    }
    if (
      allParams.q ||
      allParams.ownerType === 'all_employees' ||
      groupName == 'No Parent'
    ) {
      delete allParams.parentNotAssignedTo;
    }
    if (page === 'myteams' || page === 'myTeamGoals') {
      delete allParams.assigneeId;
    }
    queryFn = queryFn ?? getListObjectives;

    return queryFn(allParams);
  };

  let queryKey = [
    page === 'myteams' || page === 'myTeamGoals' ? 'teams' : 'objectives',
    page,
    params
  ];
  // QUERY
  const {
    data,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    isFetching
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => fetchObjectives(pageParam),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.pagination?.next?.olderThan,
      suspense: true,
      initialData: initialData
        ? initialData?.[0]?.id
          ? initialData
          : { pages: initialData, pageParams: [] }
        : initialData,
      enabled: !switchAble && group != 'top-parent',
      staleTime: initialData && Infinity
    }
  );

  // DATA
  let objectives = [];
  if (data?.[0]?.id) {
    objectives = data;
  } else {
    data?.pages?.forEach((page) =>
      page?.data?.forEach((objective) => {
        objectives.push(objective);
      })
    );
  }

  const queryToBeReset = () => {
    queryClient.invalidateQueries(['objectives', 'goalsScoring']);
  };

  const setParams = useObjectives((state) => state.set);
  // EFFECT
  const firstRender = useRef(false);

  useDeepEffect(() => {
    if (firstRender.current && !switchAble && group != 'top-parent') {
      queryToBeReset();
      refetchQueries(queryKey);
    } else {
      firstRender.current = true;
    }
    setParams((state) => {
      state.filter = params;
    });
  }, [refetchKey, filter, profileFilter]);

  useEffect(() => {
    if (!isFetching) {
      const fromCreate = localStorage.getItem('prevUrl');
      !isEmpty(fromCreate) && refetch();
      localStorage.removeItem('prevUrl');
    }
  }, [queryKey]);

  // REF
  const intersectTarget = createRef();
  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting &&
      hasNextPage &&
      fetchNextPage() &&
      additionalWrapperClick(),
    threshold: 0.5
  });

  // * MULTIPLE EDIT
  const selectedObjective = useMultipleEdit((state) => state.selectedObjective);
  const closeSelected = useMultipleEdit((state) => state.closeSelected);
  const removeSelected = useMultipleEdit((state) => state.removeSelected);
  const [deleteDone, setDeleteDone] = useState(false);
  const [showMutipleDelete, setShowMutipleDelete] = useState(false);
  const [reloadTeamGoalApproval, setReloadTeamGoalApproval] = useState(false);

  const { url } = useUrl();
  const { reload } = useReload();
  let history = useHistory();

  async function deleteObjective(deleteChildren) {
    let body = { state: 'deleted', top_parent: null, deleteChildren };

    selectedObjective.forEach(async (item, index) => {
      await updateStateObjective(item.id, body);
      await refetchObjective(item?.parent?.id);

      if (item?.parent?.id) {
        if (
          url.includes('teams') &&
          item?.parent?.id === approvalParentGoal?.id
        ) {
          setReloadTeamGoalApproval(true);
        }
        await refetchSubObjectives(item?.parent?.id);
      } else {
        history.replace({
          pathname: url.includes('company')
            ? '/goals/level/company'
            : url.includes('users')
            ? url.replace(/\/objectives/, '')
            : url.includes('direct')
            ? '/goals/reports/direct'
            : '/goals',
          search: location.search
        });
      }
      removeSelected(item.id);
      await refetchObjectives(page);
      setDeleteDone(index === selectedObjective?.length - 1);
    });

    setShowMutipleDelete(!showMutipleDelete);
    reload({
      reloadType: 'delete',
      reloadApprovalPanel: ['edited', 'to_be_deleted']
    });
  }

  useEffect(() => {
    closeSelected();
  }, [page]);
  // * MUTIPLE EDIT

  const showObjective = isFetchingNextPage ? true : !isFetching;

  let grid =
    cardModel === 'project' ||
    cardModel === 'team' ||
    cardModel === 'goal-card';

  const useHeader =
    newObjectiveLayout &&
    cardModel != 'checkin-list' &&
    cardModel != 'formal-review' &&
    !page?.includes('scored-attribute-scoring');

  const isApprovalPage = page.includes('approvalPanel');

  // FOR OVERALL STATUS COMPACT LOADING OBJECTIVE
  const isStatusChange = useLoadingStatus((state) => state?.isStatusChange);
  const setStatusChange = useLoadingStatus((state) => state?.setStatusChange);

  useEffect(() => {
    setStatusChange(true);
  }, [filter?.status]);

  useEffect(() => {
    if (!isFetching) setStatusChange(false);
  }, [isFetching]);

  useEffect(() => {
    if (deleteDone && reloadTeamGoalApproval) {
      refetchQueries(['teamGoals', 'approval']);
      setDeleteDone(false);
      setReloadTeamGoalApproval(false);
    }
  }, [deleteDone, reloadTeamGoalApproval, refetchQueries]);

  return (
    <>
      {headerCardList.length > 0 && (
        <div className="flex h-[36px] items-center border-b border-0 border-n-400 border-solid px-[16px] mt-[16px]">
          <p className="typography-h300 text-n-800 w-[41%]">
            {getObjectiveLocale(headerCardList[0])}
          </p>
          <p className="typography-h300 text-n-800 w-[26%]">
            {getObjectiveLocale(headerCardList[1])}
          </p>
          <p className="typography-h300 text-n-800 w-[36%]">
            {getObjectiveLocale(headerCardList[2])}
          </p>
        </div>
      )}
      {!switchAble &&
        group === 'no-group' &&
        (objectives.length > 0 || isInlineCreate) &&
        showObjective && (
          <div
            className={`${withMarginTop ? 'mt-[16px] objectives-list' : ''}`}
            style={gridArea ? { gridArea: 'objectives' } : {}}
          >
            {!grid && !switchAble && group == 'no-group' && (
              <div
                id="styled-scrollbar"
                className={
                  cardModel == 'checkin-list'
                    ? `grid gap-[24px] overflow-hidden overflow-y-auto h-[520px] pt-[15px] grid-cols-1 ${
                        customClass ? customClass : ''
                      }`
                    : `grid ${
                        newObjectiveLayout
                          ? 'gap-[8px] overflow-y-overlay overflow-y-auto'
                          : cardModel == 'goal-list-approval-detail'
                          ? 'gap-[16px]'
                          : cardModel !== 'formal-review'
                          ? 'pt-[4px] gap-[24px]'
                          : ''
                      }  overflow-y-auto grid-cols-1  ${
                        customClass ? customClass : ''
                      }` +
                      `${
                        isApprovalPage
                          ? ' ml-[-24px] pl-[24px]'
                          : ' ml-[-40px] pl-[40px]'
                      } `
                }
              >
                {' '}
                {/*BEFORE COMPACT CLASS: gap-24*/}
                {useHeader && (
                  <div className="flex flex-row justify-between">
                    <p className="ml-[14px] typography-h300 text-n-600">
                      {getObjectiveLocale('OBJECTIVE')}
                    </p>
                    <div className="flex flex-row justify-end mr-[76px]">
                      <p className="typography-h300 text-n-600 text-left w-[90px] mr-[24px]">
                        {getObjectiveLocale('DUE DATE')}
                      </p>
                      <p className="typography-h300 text-n-600 text-left w-[144px] mr-[24px]">
                        {getObjectiveLocale('PROGRESS')}
                      </p>
                      <p className="typography-h300 text-n-600 text-left w-[160px]">
                        {getObjectiveLocale('METRICS')}
                      </p>
                    </div>
                  </div>
                )}
                {isInlineCreate && (
                  <InlineCreate setInlineCreate={setInlineCreate} />
                )}
                {objectives.map((objective, index) => (
                  <div
                    className={`list-objectives-body cursor-pointer ${
                      newObjectiveLayout ||
                      cardModel == 'goal-list-approval-detail'
                        ? ''
                        : 'bg-n-000'
                    } `}
                    page={index}
                    key={index}
                    ref={
                      index == objectives.length - 3 && objectives.length > 14
                        ? intersectTarget
                        : undefined
                    }
                  >
                    <Objective
                      objective={objective}
                      index={index}
                      page={page}
                      key={objective.id}
                      cardModel={cardModel}
                      isParent={true}
                      depthLevel={depthLevel}
                      useFetchObjective={useFetchObjective}
                      level={1}
                      queryFn={queryFn}
                      showBadgeObjectiveCount={showBadgeObjectiveCount}
                      showActivity={page == 'highlighted'}
                      {...rest}
                    />
                  </div>
                ))}
              </div>
            )}

            {grid &&
              !switchAble &&
              objectives.length > 0 &&
              group == 'no-group' &&
              showObjective && (
                <div
                  className="project-goals-container row"
                  style={{ gridTemplateColumns: `repeat(${totalColumn}, 1fr` }}
                >
                  {objectives.map((objective, index) => (
                    <div
                      ref={
                        index == objectives.length - 3 && objectives.length > 14
                          ? intersectTarget
                          : undefined
                      }
                      key={index}
                    >
                      <Objective
                        objective={objective}
                        page={page}
                        key={index}
                        cardModel={cardModel}
                        useFetchObjective={useFetchObjective}
                        isParent={true}
                        showActivity={page == 'highlighted'}
                        {...rest}
                      />
                    </div>
                  ))}
                </div>
              )}

            {isFetchingNextPage && (
              <div className="mt-[24px]">
                {grid ? (
                  <ProjectCardSkeleton />
                ) : newObjectiveLayout ? (
                  <CompactSkeleton />
                ) : (
                  <ObjectiveSkeleton />
                )}
              </div>
            )}

            {selectedObjective.length > 0 &&
              !page.includes('approvalPanel+to_be_deleted') && ( // maintained until multiple restore is complete
                <FloatingAction
                  text={`${selectedObjective.length} objectives selected`}
                  deleteAction={{
                    onClick: () => setShowMutipleDelete(!showMutipleDelete),
                    text: 'Delete objectives',
                    dataCy: 'delete-multiple-delete'
                  }}
                  cancelAction={{
                    onClick: () => closeSelected(),
                    text: 'Cancel selection',
                    dataCy: 'cancel-multiple-delete'
                  }}
                  dataCy="goal-selected"
                />
              )}
          </div>
        )}

      {isFetching && !isFetchingNextPage && (
        <div
          className={`${
            newObjectiveLayout && isMyGoalPage ? 'px-[40px] mt-[16px]' : ''
          }`}
        >
          {newObjectiveLayout ? (
            isStatusChange ? (
              <LoadingComponent />
            ) : (
              <CompactSkeleton />
            )
          ) : (
            <ObjectiveSkeleton addClass={`mt-[16px] `} />
          )}
        </div>
      )}
      {!isFetching &&
        objectives.length == 0 &&
        !isInlineCreate &&
        !switchAble &&
        group === 'no-group' && (
          <ObjectiveEmptyState
            page={page}
            cantSee={cantSee}
            isSearch={params?.q}
            switcherValue={switcherValue}
            userPermission={userPermission}
            emptyStateAction={emptyStateAction}
            isAbleToCreate={isAbleToCreate}
            description={emptyStateData?.description}
            title={
              emptyStateData?.title
                ? emptyStateData?.title
                : page === 'highlighted' && 'No objective has been highlighted'
            }
          />
        )}
      {onBoardStatus && !isFetching && (
        <HighlightedBubble
          onBoardStatus={onBoardStatus}
          setOnboardStatus={setOnBoardStatus}
        />
      )}
      {group !== 'no-group' && !switchAble && showObjective && (
        <GroupModeObjectives
          filter={filter}
          extraFilter={extraFilter}
          group={group}
          grid={grid}
          cardModel={cardModel}
          totalColumn={totalColumn}
          page={page}
          objectives={objectives}
          withFilterMenu={withFilterMenu}
          user={user}
          switchType={switchType}
          useFetchObjective={useFetchObjective}
          queryFn={queryFn}
          showBadgeObjectiveCount={showBadgeObjectiveCount}
          isSearch={params?.q}
          profileFilter={profileFilter}
          queryFnTopParent={queryFnTopParent}
          customClass={customClass}
          additionalWrapperClick={additionalWrapperClick}
          {...rest}
        />
      )}

      {switchAble && (
        <SwitchAbleObjective
          filter={filter}
          extraFilter={extraFilter}
          group={group}
          grid={grid}
          cardModel={cardModel}
          page={page}
          options={switchOptions}
          withFilterMenu={withFilterMenu}
          {...rest}
        />
      )}

      {showMutipleDelete && (
        <ConfirmDeleteModal
          modalType="mutipleDelete"
          closeModal={() => setShowMutipleDelete(!showMutipleDelete)}
          submitModal={(deleteChildren) => deleteObjective(deleteChildren)}
        />
      )}
    </>
  );
}

let SkeletonWrapper = () => {
  const {
    config: { newObjectiveLayout }
  } = useUser();

  // FOR OVERALL STATUS COMPACT LOADING
  const isStatusChange = useLoadingStatus((state) => state?.isStatusChange);

  return (
    <div>
      {newObjectiveLayout ? (
        isStatusChange ? (
          <LoadingComponent />
        ) : (
          <CompactSkeleton />
        )
      ) : (
        <ObjectiveSkeleton />
      )}
    </div>
  );
};

const Objectives = (props) => {
  let grid =
    props.cardModel === 'project' ||
    props.cardModel === 'team' ||
    props.cardModel === 'goal-card';

  let Suspense = grid ? ProjectCardSkeleton : SkeletonWrapper;

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <React.Suspense fallback={<Suspense />}>
          <ObjectivesComponent {...props} />
        </React.Suspense>
      </ErrorBoundary>

      {!props.childComponent && (
        <ObjectivesModal saveCallback={props?.saveCallback} />
      )}
    </>
  );
};

const isEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export default React.memo(Objectives, isEqualProps);

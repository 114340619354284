"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePermission = exports.activeVisibility = exports.activeGoalType = exports.visibility = exports.activeVisibilityList = exports.allVisibility = exports.visibilityWithoutPermission = exports.visibilityWithPermission = exports.listGoalType = exports.permissionGrouping = exports.permissionShowTab = exports.permissionCreator = void 0;
const UserContext_1 = require("context/UserContext");
exports.permissionCreator = {
    company: 'isCompanyGoalCreator',
    directorate: 'isDirectorateGoalCreator',
    department: 'isDepartmentGoalCreator',
    division: 'isDivisionGoalCreator'
};
exports.permissionShowTab = {
    directorate: 'showDirectorateObjective',
    department: 'showDepartmentObjective',
    division: 'showDivisionObjective'
};
exports.permissionGrouping = {
    directorate: 'goalDirectorateAllSee',
    department: 'goalDepartmentAllSee',
    division: 'goalDivisionAllSee'
};
exports.listGoalType = {
    public: 'isPrivate',
    private: 'isPrivate',
    company: 'isCompanyGoal',
    directorate: 'isDirectorateGoal',
    department: 'isDepartmentGoal',
    division: 'isDivisionGoal'
};
exports.visibilityWithPermission = [
    'company',
    'directorate',
    'department',
    'division'
];
exports.visibilityWithoutPermission = ['public', 'private'];
exports.allVisibility = [
    ...exports.visibilityWithoutPermission,
    ...exports.visibilityWithPermission
];
exports.activeVisibilityList = [
    ...exports.visibilityWithPermission.reverse(),
    ...exports.visibilityWithoutPermission
];
exports.visibility = {
    private: {
        text: 'Private',
        iconName: 'icon-private'
    },
    company: {
        text: 'Company Goal',
        iconName: 'icon-building'
    },
    directorate: {
        text: 'Directorate Goal',
        iconName: 'icon-home_work'
    },
    department: {
        text: 'Department Goal',
        iconName: 'icon-hub'
    },
    division: {
        text: 'Division Goal',
        iconName: 'icon-diversity'
    },
    public: {
        text: 'Public',
        iconName: 'icon-remove_red_eye'
    }
};
const activeGoalType = (objective) => {
    return exports.activeVisibilityList.find((type) => {
        if (type === 'public') {
            return !objective[exports.listGoalType[type]];
        }
        return objective[exports.listGoalType[type]];
    });
};
exports.activeGoalType = activeGoalType;
const activeVisibility = (objective) => {
    return exports.visibility[(0, exports.activeGoalType)(objective) || ''];
};
exports.activeVisibility = activeVisibility;
const usePermission = () => {
    const { user, config } = (0, UserContext_1.useUser)();
    const isGoalCreator = (type) => {
        return user[exports.permissionCreator[type]];
    };
    const isGrouping = (type) => {
        return type !== 'company' && (config === null || config === void 0 ? void 0 : config.permissions[exports.permissionGrouping[type]]);
    };
    const isShowTab = (type) => {
        return config[exports.permissionShowTab[type]];
    };
    return {
        isGoalCreator,
        isGrouping,
        isShowTab
    };
};
exports.usePermission = usePermission;

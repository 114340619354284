import React from 'react';

import Config from 'utils/Config';

const ShowMoreCard = ({
  dataCy = dataCy || 'competencies-values-show-more',
  parentClass,
  funcOnClick,
  colSpan
}) => {
  return (
    <tr className={parentClass} onClick={funcOnClick}>
      <td className="wrapper-show-more" data-cy={dataCy} colSpan={colSpan}>
        <span className="text-show-more">Show more</span>
        <img src={Config.asset.v2.dropdown} className="icon-show-more" />
      </td>
    </tr>
  );
};

export default ShowMoreCard;

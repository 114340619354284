import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import { followUpNeedResponse } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import useDropdown from 'hooks/useDropdown';
import useModalObjectives from 'hooks/useModalObjectives';
import { getObjectiveLocale, toggleDropdown } from 'utils/HelperUtils';

import CustomInnerIcon from 'components/design-system/CustomInnerIcon';
import ListDropdown from 'components/objectives/ListDropdown';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import FloatingComponent from './fragments/FloatingComponent';
import MenuOutsideClickWrapper from './fragments/MenuOutsideClickWrapper';

const CompactObjectiveMenu = ({
  objective,
  objectiveConfigs,
  permissions,
  page,
  clusters,
  approvalRequired,
  isTeamGoalsApproval
}) => {
  const { pathname } = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMenuOpenAdd, setMenuOpenAdd] = useState(false);
  const [isMenuAlign, setMenuAlign] = useState(false);
  const [dropdownAnimation, setDropdownAnimation] = useState(
    'animation-open-dropdown-fadeInDownSlow'
  );
  const [dropdownAddAnimation, setDropdownAddAnimation] = useState(
    'animation-open-dropdown-fadeInDownSlow'
  );

  const showModal = useModalObjectives((state) => state.showModal);
  const closeModal = useModalObjectives((state) => state.closeModal);

  const { config } = useUser();
  const ref = useRef();

  let modalProps = {
    measurement: objective?.measurement,
    canUpdateCurrentValue: objective?.permissions?.includes(
      'update_current_value'
    ),
    objectiveId: objective?.id,
    objectiveName: objective?.name,
    permission: permissions?.includes('update_comment'),
    commentOptions: objective?.commentOptions,
    milestoneType: objective?.milestoneType,
    objectiveValue: objective,
    currentMilestone: objective?.currentMilestone
  };

  const handleFollowUp = async (objectiveId, comment, mention) => {
    let body = {
      comment: comment,
      metaMentions: mention
    };
    const { data } = await followUpNeedResponse(objective?.id, body);
    if (data) {
      closeModal();
    }
  };

  const { useMenu } = useDropdown({
    objective,
    objectiveConfigs,
    permissions,
    page,
    isTeamGoalsApproval,
    isCompact: true,
    clusters
  });

  const listMenu = useMenu({
    except: ['addGoal', 'addTask', 'addProject', 'progressOvertime']
  });
  const listMenuAdd = useMenu({ only: ['addGoal', 'addTask', 'addProject'] });

  const menuMoreHandler = (e, status) => {
    e.stopPropagation();
    toggleDropdown(status, setDropdownAnimation, setMenuOpen);
    toggleDropdown(false, setDropdownAddAnimation, setMenuOpenAdd);
    setMenuAlign(false);
  };

  const menuAddHandler = (e, status) => {
    e.stopPropagation();
    toggleDropdown(status, setDropdownAddAnimation, setMenuOpenAdd);
    toggleDropdown(false, setDropdownAnimation, setMenuOpen);
    setMenuAlign(false);
  };

  useEffect(() => {
    const element = document.querySelector(
      `[selector-id='${objective?.id}'] .content-compact-objectives`
    );
    if (!element) return;
    if (isMenuOpenAdd || isMenuOpen) {
      element.classList.add('active');
    } else {
      element.classList.remove('active');
    }
  }, [isMenuOpen, isMenuOpenAdd, objective]);

  const isUsingMenuBackground = () => {
    if (approvalRequired || page === 'give-review' || page === 'give-comment') {
      return false;
    }

    return true;
  };

  const onClickReviewCommentBtn = (e, type) => {
    e.stopPropagation();
    if (type === 'give-review') {
      showModal({
        modalType: 'review',
        props: {
          objectiveDetail: objective
        }
      });
    } else {
      showModal({
        modalType: 'comment',
        props: {
          measurement: objective?.measurement,
          objectiveName: objective?.name
        },
        submitModal: handleFollowUp
      });
    }
  };

  const followUpButton = (
    <TooltipContainer
      show
      position="bottom"
      text={getObjectiveLocale('Follow up')}
    >
      <div
        data-cy="follow-up-icon"
        className="mr-[8px]"
        onClick={(e) => {
          e.stopPropagation();
          showModal({
            modalType: 'comment',
            props: {
              measurement: objective?.measurement,
              objectiveName: objective?.name
            },
            submitModal: handleFollowUp
          });
        }}
      >
        <CustomInnerIcon iconName="icon-notifications" />
      </div>
    </TooltipContainer>
  );

  const isInAssignment = pathname.includes('/assignments');

  const seeGoalTree =
    objective?.type == 'task' ? config.permissions?.taskAlignmentEdit : true;

  const onClickViewTree = (e) => {
    e.stopPropagation();
    let search = new URLSearchParams(location.search);
    if (objective?.organization?.id) {
      search.append('organizationId', objective?.organization?.id);
    }
    search = search.toString();
    search = (search?.length > 0 ? '?' : '') + search;
    window.open(`/tree/${objective?.id}${search}`, '_blank');
  };

  return (
    <div
      className={`${
        isUsingMenuBackground() ? 'menu-background' : ''
      } !bottom-[-8px] absolute right-0`}
    >
      {/* Custom Portal Div */}
      <div ref={ref}></div>

      <div className="flex flex-row layer py-[6px] pl-[28px]">
        {page === 'give-review' || page === 'give-comment' ? (
          <div
            className="flex items-center px-[8px] py-[4px] rounded-[16px] bg-base-600"
            onClick={e => onClickReviewCommentBtn(e, page)}
          >
            <SVGIcon
              iconName={
                page === 'give-review' ? 'icon-review-v2' : 'icon-comment'
              }
              fillColor="var(--n-000)"
              size={16}
            />
            <p className="typography-h100 text-n-000 ml-[4px]">
              {getObjectiveLocale(
                page === 'give-review' ? 'Review' : 'Comment'
              )}
            </p>
          </div>
        ) : !pathname.includes('/objectives/need-response') ? (
          <>
            {seeGoalTree && (
              <div
                className="flex items-center min-h-[24px] px-[8px] rounded-[16px] bg-base-600"
                onClick={(e) => onClickViewTree(e)}
              >
                <SVGIcon
                  iconName="icon-device_hub"
                  fillColor="var(--n-000)"
                  size={16}
                />
                <p className="typography-h100 text-n-000 ml-[4px]">
                  {getObjectiveLocale('View Tree')}
                </p>
              </div>
            )}
            {listMenuAdd?.length > 0 && !isInAssignment && (
              <MenuOutsideClickWrapper
                customClass={`relative ml-[8px]`}
                event={() =>
                  toggleDropdown(false, setDropdownAddAnimation, setMenuOpenAdd)
                }
              >
                <TooltipContainer
                  show={!isMenuOpenAdd}
                  position="bottom"
                  text={getObjectiveLocale('Add New')}
                >
                  <div
                    data-cy="dropdown-add"
                    onClick={(e) => {
                      menuAddHandler(e, !isMenuOpenAdd);
                    }}
                  >
                    <CustomInnerIcon iconName="icon-add" />
                  </div>

                  {isMenuOpenAdd && (
                    <FloatingComponent>
                      <ListDropdown
                        autoClose={() =>
                          toggleDropdown(
                            false,
                            setDropdownAddAnimation,
                            setMenuOpenAdd
                          )
                        }
                        data={listMenuAdd}
                        dropdownAnimation={dropdownAddAnimation}
                      />
                    </FloatingComponent>
                  )}
                </TooltipContainer>
              </MenuOutsideClickWrapper>
            )}
            {permissions?.includes('update_current_value') && (
              <TooltipContainer
                show
                position="bottom"
                text={getObjectiveLocale('Update Progress')}
              >
                <div
                  data-cy={`update-progress-button`}
                  className="ml-[8px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    showModal({
                      modalType: 'updateProgress',
                      props: modalProps
                    });
                  }}
                >
                  <CustomInnerIcon iconName="icon-bar_chart" />
                </div>
              </TooltipContainer>
            )}
            {config?.enableProgressOverTime && !objective?.organization?.id && (
              <div className="ml-[8px]">
                <TooltipContainer
                  show={!isMenuAlign}
                  position="bottom"
                  text={getObjectiveLocale('Progress Over Time')}
                >
                  <div
                    data-cy="progress-overtime-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        `/progress-overtime/${objective?.id}`,
                        '_blank'
                      );
                    }}
                  >
                    <CustomInnerIcon iconName="icon-show_chart" />
                  </div>
                </TooltipContainer>
              </div>
            )}

            {listMenu.length > 0 && (
              <MenuOutsideClickWrapper
                customClass={`relative ml-[8px]`}
                event={() =>
                  toggleDropdown(false, setDropdownAnimation, setMenuOpen)
                }
                whitelistRef={ref}
              >
                <TooltipContainer
                  show={!isMenuOpen}
                  position="bottom"
                  text={getObjectiveLocale('More')}
                >
                  <div
                    data-cy="kebab-action-button"
                    onClick={(e) => {
                      menuMoreHandler(e, !isMenuOpen);
                    }}
                  >
                    <CustomInnerIcon iconName="icon-keyboard_control" />
                  </div>
                </TooltipContainer>
                {isMenuOpen && (
                  <FloatingComponent customPortalElement={ref.current}>
                    <ListDropdown
                      autoClose={() =>
                        toggleDropdown(false, setDropdownAnimation, setMenuOpen)
                      }
                      data={listMenu}
                      dropdownAnimation={dropdownAnimation}
                    />
                  </FloatingComponent>
                )}
              </MenuOutsideClickWrapper>
            )}
          </>
        ) : permissions?.includes('review') ? (
          config.ratingMechanism &&
          config.ratingMechanism == 'automatic' ? null : (
            <TooltipContainer
              show
              position="bottom"
              text={getObjectiveLocale('Review Now')}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  showModal({
                    modalType: 'review',
                    props: {
                      objectiveDetail: objective
                    }
                  });
                }}
                data-cy={`need-response-icon`}
              >
                <CustomInnerIcon iconName="icon-review-badge" />
              </div>
            </TooltipContainer>
          )
        ) : config.ratingMechanism && config.ratingMechanism == 'automatic' ? (
          objective.overdue && followUpButton
        ) : (
          permissions?.includes('comment') && followUpButton
        )}
      </div>
    </div>
  );
};

export default CompactObjectiveMenu;

import React, { useEffect, useState } from 'react';

import { useAuth } from 'context/AuthContext';
import { useFormalReview } from 'context/FormalReviewContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import CircleComponent from 'components/shared/CircleComponent';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

import './BehaviorScoringMatrixScale.scss';
import MatrixScaleRow from './MatrixScaleRow';

// Competency Assessment | competencies_scoring
const BehaviorScoringMatrixScale = ({
  setOverlaySidebar,
  actor,
  phase,
  preFill,
  answers
}) => {
  const {
    data: { config }
  } = useAuth();
  const { type } = phase;
  let [
    { currentTrack, isPrefillCompleted },
    { getSingleTrack, setAnswerContext }
  ] = useFormalReview();
  const track = getSingleTrack(currentTrack);
  let { objects, components, template } = track || {};

  const [modalInfo, showModalInfo] = useState(false);
  const [currentAnswers, setCurrentAnswers] = useState(answers || []);

  const hasPrefill =
    preFill?.filter((pref) => pref?.comment).length > 0 ? true : false;

  const preFillByObjectId = {};
  preFill?.forEach((data) => {
    preFillByObjectId[data.objectId] = data;
  });

  const answerByObjectId = {};
  currentAnswers.forEach((answer) => {
    answerByObjectId[answer.objectId] = answer;
  });

  const suggestionByObjectId = {};
  track?.suggestions.forEach((suggestion) => {
    suggestionByObjectId[suggestion.objectId] = suggestion;
  });

  // options sort to render based on score ascending
  components?.options?.sort((a, b) => a['score'] - b['score']);
  const trackLocaleKey = currentTrack.replace('_scoring', '');

  const setAnswer = (objectId, optionId = null, comment = '') => {
    const newAnswers = currentAnswers.map((answer) => {
      let result = {
        comment: answer.comment,
        objectId: answer.objectId,
        optionId: answer.optionId
      };
      return result;
    });

    const answerIndex = currentAnswers.findIndex((answer) => {
      return answer.objectId == objectId;
    });

    if (answerIndex > -1) {
      if (optionId) {
        newAnswers[answerIndex].optionId = optionId;
      } else {
        newAnswers[answerIndex].comment = comment;
      }
    } else {
      newAnswers.push({
        objectId,
        optionId,
        comment
      });
    }

    setCurrentAnswers(newAnswers);
    setAnswerContext(currentTrack, newAnswers);
  };

  // Sync answers & comment when prefilled
  useEffect(() => {
    if (isPrefillCompleted) {
      setCurrentAnswers(answers);
    }
  }, [isPrefillCompleted, setCurrentAnswers, answers]);

  return (
    <div className="container-competency-assessment">
      <div className="result-container">
        {/* Previous Result and New Result Instruction */}
        <div className="inline-block">
          <CircleComponent
            pointerEvent="cursor-default"
            size="small"
            containerClass="result-hint-assessment"
            opacity="0.4"
            isChecked
          >
            <SVGIcon iconName="icon-check" size={12} fillColor="var(--n-000)" />
          </CircleComponent>
          {getObjectiveLocale('Previous result')}
        </div>
        <div className="new-result-container inline-block">
          <CircleComponent
            pointerEvent="cursor-default"
            size="small"
            containerClass="result-hint-assessment"
            isChecked
          >
            <SVGIcon iconName="icon-check" size={12} fillColor="var(--n-000)" />
          </CircleComponent>
          {getObjectiveLocale('New result')}
        </div>
      </div>

      <table className="text-center table-assessment">
        <thead>
          <tr className="flex flex-row justify-between items-center">
            <th>
              <div className="flex flex-row text-n-800 w-[210px] typography-h100">
                {getObjectiveLocale(`[${trackLocaleKey} scoring] Rating`)}
                <SVGIcon
                  size="16"
                  customClass="ml-[4px]"
                  iconName={'icon-info'}
                  fillColor={'var(--n-500)'}
                  onClick={() => showModalInfo(true)}
                  dataCy="info-circle"
                />
              </div>
            </th>
            {config.behaviorsFramework !==
              'development_dimensions_international' && (
              <th className="w-[48px] text-center px-[4px] py-[4px]">
                {getObjectiveLocale('Expected Level')}
              </th>
            )}
            {components?.options?.map((option) => {
              return (
                <th key={option?.id} className="text-center px-[4px] py-[4px]">
                  {option?.name}
                </th>
              );
            })}
            {hasPrefill && <th>Previous Comment</th>}
            {template.useComment !== 'no' && (
              <th
                className="block w-[192px] text-center !grow-0 !basis-[200px]"
                id="competency-scoring-comment"
              >
                {getObjectiveLocale(`[${trackLocaleKey} scoring] Comment`) +
                  ` (${getObjectiveLocale(template.useComment || 'optional')})`}
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {objects?.length > 0 &&
            objects?.map((object, index) => {
              return (
                <MatrixScaleRow
                  key={index}
                  object={object}
                  options={components.options}
                  setOverlaySidebar={setOverlaySidebar}
                  answer={answerByObjectId[object.id]}
                  suggestion={suggestionByObjectId[object.id]}
                  setAnswer={setAnswer}
                  actor={actor}
                  type={type}
                  preFill={preFillByObjectId[object.id]}
                  hasPrefill={hasPrefill}
                  useComment={template.useComment}
                />
              );
            })}
        </tbody>
      </table>

      {modalInfo && (
        <Modal
          title="Definisi Skala"
          eventOnClickClose={() => showModalInfo(false)}
          withCloseIcon
          footerClass="h-[20px]"
        >
          <div className="grid gap-[20px]">
            <div className="d-grid-2">
              <p className="typography-h100 typography-secondary">
                {getObjectiveLocale(`Rating`)}
              </p>
              <p className="typography-h100 typography-secondary">
                {getObjectiveLocale(`Rating Description`)}
              </p>
            </div>
            {components.options &&
              components?.options?.map((option, index) => {
                return (
                  <div className="d-grid-2" key={index}>
                    <p className="fw-bold">{option.score}</p>
                    <p data-cy="option-name">{option.description}</p>
                  </div>
                );
              })}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BehaviorScoringMatrixScale;

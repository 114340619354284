import React, { useState } from 'react';

import dayjs from 'dayjs';

import { editObjective, getParentSuggestions } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import useModalObjectives from 'hooks/useModalObjectives';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { getObjectiveLocale } from 'utils/HelperUtils';
import {
  getIncludeTeamGoalsParams,
  getSelectedInvolvement
} from 'utils/ObjectivesHelper';

import Avatar from 'components/design-system/Avatar';
import InlineDialog from 'components/design-system/inline-dialog/InlineDialog';
import LoadingComponent from 'components/shared/LoadingComponent';
import ObjectiveEmptyState from 'components/shared/ObjectiveEmptyState';
import SVGIcon from 'components/shared/SVGIcon';

function ListObjectiveHeader({
  objective,
  action,
  permissions,
  clusters,
  showDetail,
  page,
  isHover,
  includeTeamGoals
}) {
  const [parentSuggestions, setParentSuggestions] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(null);

  const additionalParams = getIncludeTeamGoalsParams(
    includeTeamGoals,
    objective?.team?.id
  );

  const { refetchObjectives } = useRefetchQuery();
  const {
    showModalValidationChangeGoalAlignment,
    checkValidationChangeGoalAlignment
  } = useValidationMinMax();
  const { selectedOwner, selectedReviewer } = getSelectedInvolvement(
    objective?.involvements
  );
  let arrayAssignee = selectedOwner?.map(({ user }) => user.id) || [];
  let arrayAssigner = selectedReviewer?.map(({ user }) => user.id) || [];
  let assigneeId = {
    assignee_id: arrayAssignee,
    assigner_id: arrayAssigner
  };
  const alignParentPermission = permissions?.includes('align_parent');

  const { reload, reloadSidebar } = useReload();

  const getParentSuggestion = async (search = '', offset = null) => {
    setIsLoading(true);
    const params = {
      ...assigneeId,
      reviewsVisibility: 1,
      periodBegin: objective.startDate,
      periodEndBefore: objective.dueDate,
      state: ['running', 'completed', 'reviewed', 'edited'],
      id: objective.id,
      ...additionalParams,
      ...(offset && { offset: offset }),
      q: search
    };
    const { data, pagination } = await getParentSuggestions(params);
    if (data) {
      setParentSuggestions([...parentSuggestions, ...data]);
      setOffset(data?.length >= 20 ? pagination?.nextOffset : null);
    } else {
      setOffset(null);
    }
    setIsLoading(false);
  };

  const onScroll = (e) => {
    const loadMore =
      e?.target?.scrollTop + e?.target?.clientHeight == e?.target?.scrollHeight;
    if (!isLoading && offset && loadMore) {
      getParentSuggestion(search, offset);
    }
  };

  const showModalComment = useModalObjectives((state) => state.showModal);
  const closeModal = useModalObjectives((state) => state.closeModal);
  const submitParent = async (parentId, comment, metaMentionsPayload) => {
    let body = { parentId, comment, metaMentions: metaMentionsPayload };
    await editObjective(objective.id, body);
    await refetchObjectives(page);
    reload({ reloadSidebar: !reloadSidebar });
    closeModal();
    reload({
      reloadApprovalPanel: ['draft', 'edited']
    });
  };

  const handleChangeParent = async (parent) => {
    const { isSettingMinObjectivesOn } =
      await checkValidationChangeGoalAlignment({
        nextAction: () =>
          showModalComment({
            modalType: 'comment',
            submitModal: submitParent,
            props: {
              objectiveId: parent.id,
              measurement: objective?.measurement,
              objectiveName: objective?.name
            }
          })
      });
    if (!isSettingMinObjectivesOn)
      showModalComment({
        modalType: 'comment',
        submitModal: submitParent,
        props: {
          objectiveId: parent.id,
          measurement: objective?.measurement,
          objectiveName: objective?.name
        }
      });
  };

  let parentName = objective?.parent?.name;
  return (
    <div className="parent-info flex items-center">
      <SVGIcon
        size="16"
        iconName="icon-arrow_upward"
        fillColor="var(--n-600)"
      />
      <div>
        {clusters.includes('parent') ? (
          parentName ? (
            <p
              data-cy="parent-info-name"
              className={`parent-name typography-link px-[8px] py-[8px] ${
                parentName ? 'text-base-600' : ''
              }`}
              {...(action && {
                onClick: (e) => {
                  e.stopPropagation();
                  showDetail(objective.parent.id);
                }
              })}
            >
              {parentName}
            </p>
          ) : (
            <InlineDialog
              header={getObjectiveLocale('Align to Parent')}
              search={{
                placeholder: getObjectiveLocale('Search parent'),
                onSearch: (e) => {
                  setSearch(e?.target?.value);
                  getParentSuggestion(e?.target?.value, null);
                }
              }}
              customClass="w-[256px]"
            >
              <InlineDialog.Trigger customClass="w-fit">
                <div
                  className={`inline-part flex items-center py-[8px] px-[8px] rounded-[20px] state-secondary-purple no-outline ${
                    alignParentPermission
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed'
                  }`}
                >
                  {alignParentPermission && isHover ? (
                    <p className="flex whitespace-nowrap typography-button mt-[0px] text-base-600 hover-underline">
                      {getObjectiveLocale('Align to Parent')}
                    </p>
                  ) : (
                    <p className="flex whitespace-nowrap typography-button mt-[0px] text-n-600">
                      {getObjectiveLocale('No parent available')}
                    </p>
                  )}
                </div>
              </InlineDialog.Trigger>
              <InlineDialog.MenuItems
                onOpenDialog={getParentSuggestion}
                onCloseDialog={() => {
                  setParentSuggestions([]);
                  setSearch('');
                }}
                onScroll={(e) => onScroll(e)}
                customClass="w-[256px]"
              >
                {parentSuggestions?.length > 0
                  ? parentSuggestions?.map((suggestion, index) => {
                      const assignee = suggestion?.involvements?.find(
                        (involvement) => involvement?.role == 'assignee'
                      )?.user;
                      return (
                        <InlineDialog.MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            handleChangeParent(suggestion);
                          }}
                          key={index}
                        >
                          <div className="flex gap-[8px]">
                            <Avatar
                              name={assignee?.name}
                              src={assignee?.profilePicture}
                              size={30}
                              tooltip
                            />
                            <div>
                              <p className="typography-h400-longform text-n-900 w-full">
                                {suggestion?.name}
                              </p>
                              <p className="typography-h100 text-n-800 w-full mt-[4px]">
                                {`${dayjs(suggestion?.startDate)?.format(
                                  'MMM, DD YYYY'
                                )} - ${dayjs(suggestion?.dueDate)?.format(
                                  'MMM, DD YYYY'
                                )}`}
                              </p>
                            </div>
                          </div>
                        </InlineDialog.MenuItem>
                      );
                    })
                  : !isLoading && (
                      <ObjectiveEmptyState
                        type="parent-suggestion"
                        withRedirectButton={false}
                      />
                    )}
                {isLoading && <LoadingComponent />}
              </InlineDialog.MenuItems>
            </InlineDialog>
          )
        ) : (
          <p className="px-[8px] py-[8px]">Parent not visible</p>
        )}
      </div>

      {objective?.type !== 'task' && showModalValidationChangeGoalAlignment}
    </div>
  );
}

export default ListObjectiveHeader;

import React, { useEffect, useMemo, useState } from 'react';

import { getCountRating } from 'client/ObjectivesClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';

import CustomAttributeCard from 'components/formal-review/custom-attributes/CustomAttributeCard';
import StatistikOverallViewProgressCoaching from 'src/pages/dashboardOverallViewProgress/StatistikOverallViewProgressCoaching';

const MultipleReviewScorings = ({
  currentTrack,
  currentTrackId,
  saveCallback
}) => {
  const [countRatingData, setCountRatingData] = useState([]);
  const [
    { assignmentId, cycle, involvedUser, targetPlacementId },
    { getSingleTrack }
  ] = useFormalReview();
  const { config } = useUser();

  const { reviewAspects, template } = getSingleTrack(currentTrackId) || {};

  const paramsCoaching = {
    limit: 15,
    reviewsVisibility: 1,
    periodBegin: cycle?.reviewedPeriodStartsAt,
    periodEndBefore: cycle?.reviewedPeriodEndsAt,
    state: ['running', 'completed', 'reviewed'],
    assigneeId: involvedUser?.target.id,
    placementId: targetPlacementId,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    type: ['annual_goal', 'goal'],
    parentNotAssignedTo: involvedUser?.target.id
  };

  const getCountRatingUser = async () => {
    const { data } = await getCountRating(
      involvedUser?.target.id,
      paramsCoaching
    );
    setCountRatingData(data);
  };

  const _saveCallback = useMemo(() => {
    return saveCallback;
    // eslint-disable-next-line
  }, [currentTrackId]);

  useEffect(() => {
    getCountRatingUser();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {config?.showScoredAttributeOverallProgress && (
        <StatistikOverallViewProgressCoaching
          paramsCoaching={paramsCoaching}
          countRatingData={countRatingData}
          assignmentId={assignmentId}
          useRatingCard={false}
          customClass="mb-[24px]"
        />
      )}
      {reviewAspects &&
        reviewAspects?.map((aspect, idx) => {
          return (
            <CustomAttributeCard
              key={aspect?.id}
              aspect={aspect}
              saveCallback={_saveCallback}
              index={idx}
              currentTrack={currentTrack}
              assignmentId={assignmentId}
              currentTrackId={currentTrackId}
              template={template}
            />
          );
        })}
    </div>
  );
};

export default MultipleReviewScorings;

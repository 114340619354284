import React, { useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Checkbox from 'components/shared/Checkbox';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

import { removeProperty } from './ReactPropertyUtils';

function DuoCheckbox(props) {
  const [strengthChecked, setStrengthChecked] = useState(props.strengthChecked);
  const [weaknessChecked, setWeaknessChecked] = useState(props.weaknessChecked);
  const [requiredStrength, setRequiredStrength] = useState(
    props.requiredStrength
  );
  const [requiredWeakness, setRequiredWeakness] = useState(
    props.requiredWeakness
  );
  const [otherProps, setOtherProps] = useState(props);
  let excludeProps = ['text'];
  if (otherProps) {
    setOtherProps(removeProperty(otherProps, excludeProps));
  }

  const onCheckboxRequired = (type, e) => {
    if (type === 'strength') {
      setStrengthChecked(!strengthChecked);
    } else if (type === 'weakness') {
      setWeaknessChecked(!weaknessChecked);
    }
  };

  const handleChangeStrength = () => {
    if (requiredStrength) {
      setRequiredStrength(false);
    } else {
      setRequiredStrength(true);
    }
  };

  const handleChangeWeakness = () => {
    if (requiredWeakness) {
      setRequiredWeakness(false);
    } else {
      setRequiredWeakness(true);
    }
  };

  return (
    <>
      <div className="checkbox-style">
        <SmallToggleSwitchPurple
          {...otherProps}
          idStr={'strength-' + props.id}
          isChecked={strengthChecked}
          onChange={(e) => onCheckboxRequired('strength', e)}
        />

        <div className="require">
          {strengthChecked ? (
            <Checkbox
              customContainerClass="ml-[-14px]"
              name={props.idReqStrength}
              id={props.idReqStrength}
              onChange={handleChangeStrength}
              checked={requiredStrength}
            >
              {' '}
              {getObjectiveLocale('Required')}
            </Checkbox>
          ) : null}
        </div>
      </div>

      <div className="checkbox-style">
        <SmallToggleSwitchPurple
          {...otherProps}
          idStr={'weakness-' + props.id}
          isChecked={weaknessChecked}
          onChange={(e) => onCheckboxRequired('weakness', e)}
        />
        <div className="require">
          {weaknessChecked ? (
            <Checkbox
              customContainerClass="ml-[-14px]"
              name={props.idReqWeakness}
              id={props.idReqWeakness}
              onChange={handleChangeWeakness}
              checked={requiredWeakness}
            >
              {' '}
              {getObjectiveLocale('Required')}
            </Checkbox>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default DuoCheckbox;

import React, { useEffect, useState } from 'react';

import {
  getListObjectives,
  updateStateObjective
} from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useTasks from 'hooks/useTasks';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalUpdateProgress from 'components/modal/ModalUpdateProgress/ModalUpdateProgress';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import Modal from 'components/shared/modal/Modal';
import InputObjectiveName from 'components/tasks/InputObjectiveName';
import Alignment from 'components/tasks/task-column/Alignment';
import { trackEvent } from 'src/utils/AnalyticUtils';

const TaskName = ({
  task,
  setTask,
  filter,
  taskNode,
  groupData,
  sectionData,
  level = 0,
  isProject,
  setIsTaskClickable,
  listMetrics = [],
  setShowChild,
  fetchObjectivesChildren,
  cancelAddTask
}) => {
  const { user } = useUser();

  const {
    id,
    name,
    stateObject,
    measurement,
    permissions = [],
    commentOptions,
    currentMilestone,
    milestoneType,
    parent,
    dependencyState,
    blocking
  } = task || {};

  const { state } = stateObject || {};
  const parentId = parent ? parent.id : undefined;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalIncompleteOpen, setIsModalIncompleteOpen] = useState(false);
  const [defaultParent, setDefaultParent] = useState(null);

  const { refetchObjective, refetchQueries } = useRefetchQuery();
  const { reload, reloadOverallProgress, reloadOverallStatus, reloadSidebar } =
    useReload();
  const { getCompletePhase, getFirstPhase, action } = useTasks();

  const completeGoal = async (newState) => {
    const completePhase = getCompletePhase();
    const firstPhase = getFirstPhase();

    const res = await updateStateObjective(id, newState);
    const { data, isSuccess } = res;
    const { type } = newState;

    if (data && type === 'redo')
      trackEvent({
        event: 'redo',
        eventProperties: { status: 'success', id: id, type: data.type }
      });
    if (data && type === 'completed')
      trackEvent({
        event: 'complete',
        eventProperties: { status: 'success', id: id, type: data.type }
      });

    if (isSuccess) {
      refetchObjective(id);
      blocking?.length > 0 &&
        blocking?.map(({ id: taskBlockingId }) => {
          refetchObjective(taskBlockingId);
        });

      reload({
        reloadObjectiveId: { id: id },
        reloadType: 'complete',
        reloadSidebar: !reloadSidebar,
        reloadOverallProgress: !reloadOverallProgress,
        reloadOverallStatus: !reloadOverallStatus
      });

      if (filter?.group === 'phase') {
        if (isProject) {
          refetchQueries([
            'objectives',
            'mytasks',
            completePhase?.name,
            sectionData?.name
          ]);
          refetchQueries([
            'objectives',
            'mytasks',
            type === 'completed' ? groupData?.name : firstPhase?.name,
            sectionData?.name
          ]);
        } else {
          refetchQueries(['objectives', 'mytasks', completePhase?.name]);
          refetchQueries([
            'objectives',
            'mytasks',
            type === 'completed' ? groupData?.name : firstPhase?.name
          ]);
        }
      }
    }

    return res;
  };

  const handleIncompleteTask = async () => {
    await completeGoal({ state: 'running', type: 'redo' });
    setIsModalIncompleteOpen(false);
  };

  const getDefaultParent = async () => {
    const query = {
      limit: 1,
      assigneeId: user?.id,
      ...(filter?.group === 'phase' && {
        projectPhaseName: groupData?.name.toUpperCase()
      }),
      ...(filter?.group === 'section' && {
        projectSectionName: groupData?.name.toUpperCase()
      }),
      ...(filter?.group === 'priority' && {
        isProject: true
      })
    };
    const { data } = await getListObjectives(query);
    if (data) {
      setDefaultParent(data[0]);
    }
  };

  useEffect(() => {
    if (!isProject && filter?.showTask === 'project' && action === 'create') {
      getDefaultParent();
    }
  }, []);

  return (
    <>
      {!isProject &&
        ((parent && level === 0) ||
          (filter?.showTask === 'project' && action === 'create')) && (
          <Alignment
            task={task}
            setTask={setTask}
            setIsTaskClickable={setIsTaskClickable}
            type="board"
            isProject={isProject}
            defaultParentName={defaultParent?.name}
          />
        )}
      <div className="flex items-start">
        {state === 'completed' ? (
          <SVGIcon
            size="24"
            fillColor="var(--g-600)"
            iconName="icon-check_circle"
            onClick={() => setIsModalIncompleteOpen(true)}
          />
        ) : dependencyState === 'blocked' ? (
          <TooltipContainer
            show
            text="This task is blocked by other task"
            useMaxWidth={false}
          >
            <SVGIcon
              size="24"
              fillColor="var(--n-600)"
              iconName="icon-hourglass_empty"
            />
          </TooltipContainer>
        ) : (
          <TooltipContainer show text="Mark as Complete" useMaxWidth={false}>
            <SVGIcon
              size="24"
              fillColor="var(--n-600)"
              iconName="icon-check_circle_outline"
              eventStopPropagation={false}
              onClick={(e) => {
                !e.shiftKey && !(e.ctrlKey || e.metaKey) && e.stopPropagation(),
                  setIsModalOpen(!isModalOpen);
              }}
              dataCy="trigger-complete-modal-objective"
            />
          </TooltipContainer>
        )}

        <InputObjectiveName
          task={task}
          setTask={setTask}
          filter={filter}
          taskNode={taskNode}
          groupId={groupData?.id}
          groupName={groupData?.name}
          sectionId={sectionData?.id}
          sectionName={sectionData?.name}
          type="board"
          completeGoal={completeGoal}
          isProject={isProject}
          defaultParent={defaultParent}
          listMetrics={listMetrics}
          level={level}
          setShowChild={setShowChild}
          fetchObjectivesChildren={fetchObjectivesChildren}
          cancelAddTask={cancelAddTask}
        />
      </div>

      {isModalIncompleteOpen && (
        <Modal
          title={`Incomplete ${getObjectiveLocale('task', true)}`}
          withCloseIcon={false}
          withPrimaryBtn={{
            title: 'Incomplete',
            dataCy: 'button-primary-modal',
            onClick: () => handleIncompleteTask()
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            dataCy: 'button-secondary-modal',
            onClick: () => setIsModalIncompleteOpen(false)
          }}
          description={`Are you sure you want to incomplete this ${getObjectiveLocale(
            'task',
            true
          )}?`}
        />
      )}

      {isModalOpen && (
        <ModalUpdateProgress
          measurement={measurement ? measurement : null}
          withCloseIcon
          eventOnClick={() => setIsModalOpen(false)}
          canUpdateCurrentValue={permissions?.includes('update_current_value')}
          objectiveId={id}
          parentId={parentId}
          completeGoal={completeGoal}
          modalType="complete"
          objectiveName={name}
          commentOptions={commentOptions}
          currentMilestone={currentMilestone}
          milestoneType={milestoneType}
          objectiveValue={task}
        />
      )}
    </>
  );
};

export default TaskName;

import React, { useState } from 'react';

const ContentTemplateName = ({ type, title, setTitle }) => {
  const [isWarning, setIsWarning] = useState(false);
  const currentYear = new Date().getFullYear();
  const modifiedType = type.replace(/-/g, ' ');
  const customPlaceholder =
    'Marketing team ' + modifiedType + ' ' + currentYear;

  const _onChange = (value) => {
    let warning = false;
    if (!value) warning = true;

    setIsWarning(warning);
    setTitle(value);
  };

  return (
    <div id="template-name" className="container-content-template-name">
      <p className="typography-h500">Give it a name</p>
      <p className="content-subtitle">
        Something that’s clear and easily recognizable.
      </p>

      <label className="label-template-name">Rating template name</label>
      <div className="relative">
        <input
          autoFocus
          data-cy="rating-template-input-title"
          placeholder={customPlaceholder}
          className={`input-rating w-full ${
            isWarning ? 'error-border' : 'normal-border'
          }`}
          type="text"
          id="templateName"
          value={title || ''}
          onChange={(e) => _onChange(e.target.value)}
          maxLength={256}
        />
        {isWarning && (
          <p className="typography-h100">Template name can't be blank</p>
        )}
      </div>
    </div>
  );
};

export default ContentTemplateName;

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  getBehaviors,
  getSingleBehaviorsModels,
  patchBehaviorsModels,
  postBehaviorsModels
} from 'client/adminClient.js';
import { useUser } from 'context/UserContext';
import { useValueCompetency } from 'context/admin/ValueCompetencyContext';
import useDebounce from 'hooks/useDebounce';
import { loadMoreValidator } from 'utils/HelperUtils';
import { capitalize } from 'utils/HelperUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import { setActiveNavigator } from 'components/admin/organization/OrganizationHelper';
import Checkbox from 'components/shared/Checkbox';
import ContentNavigator from 'components/shared/ContentNavigator';
import Footer from 'components/shared/Footer';
import LoadingComponent from 'components/shared/LoadingComponent';
import RadioButton from 'components/shared/RadioButton';
import SVGIcon from 'components/shared/SVGIcon';

import NewModelCard from './NewModelCard';
import OrderingBehavior from './OrderingBehavior';

function AddEditCompetencyModel({ route: { title } }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const {
    setCurrentTab,
    bodyDictionaryOrModel,
    searchValue,
    setSearchValue,
    onChangeValueOrCompetency,
    resetInputState,
    showWarningMessage,
    showNotificationMessage,
    paramsDictionaryOrModel
  } = useValueCompetency();
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const [initialRender, setInitialRender] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [isAllParentChecked, setIsAllParentChecked] = useState(false);
  const [arrayAccordion, setArrayAccordion] = useState([]);
  const [dataCompetency, setDataCompetency] = useState([]);
  const [hasMoreChild, setHasMoreChild] = useState(false);
  const [nextOffsetChild, setNextOffsetChild] = useState(0);
  const [currentSizeChild, setCurrentSizeChild] = useState(0);
  const [checkedRadio, setCheckedRadio] = useState('regular');
  const [activeSection, setActiveSection] = useState('name-section');
  const [selectedData, setSelectedData] = useState({});
  const [orderingData, setOrderingData] = useState([]);
  const [expectedLevel, setExpectedLevel] = useState([]);
  const { config } = useUser();

  let routeName = pathname.includes('competencies') ? 'competencies' : 'values';

  let list = [
    { id: 'name-section', name: 'Name' },
    { id: 'description-section', name: 'Description' },
    { id: 'model-type-section', name: 'Model Type' },
    {
      id: `${routeName}-section`,
      name: capitalize(getObjectiveLocale(routeName))
    },
    { id: 'ordering-section', name: 'Ordering' }
  ];

  if (!config?.allowUsingWorkAttributes || routeName === 'competencies') {
    delete list[2];
  }

  const selectDictionary = (data, checked) => {
    const parentId = data.id;
    let newOrderingData = [...orderingData];
    let newSelectedData = JSON.parse(JSON.stringify(selectedData));
    if (checked) {
      newOrderingData.push(data);
      newSelectedData[parentId] =
        data?.keyBehaviorIds?.length > 0 ? data.keyBehaviorIds : [];

      let isAllDictionaryChecked = dataCompetency.every(
        (val) => val.id in newSelectedData
      );
      if (isAllDictionaryChecked && searchValue == '') {
        setIsAllParentChecked(true);
      }
    } else {
      newOrderingData = newOrderingData.filter((val) => val.id !== parentId);
      delete newSelectedData[parentId];
      setIsAllParentChecked(false);
    }

    setOrderingData(newOrderingData);
    setSelectedData(newSelectedData);
  };

  const selectKeyBehaviour = (parentData, keyBehaviorId, checked) => {
    const parentId = parentData.id;
    let newOrderingData = [...orderingData];
    let newSelectedData = JSON.parse(JSON.stringify(selectedData));

    if (checked) {
      newSelectedData[parentId] = [
        ...(newSelectedData[parentId] || []),
        keyBehaviorId
      ];
      if (newSelectedData[parentId].length == 1) {
        newOrderingData.push(parentData);
      }
    } else {
      newSelectedData[parentId] = newSelectedData[parentId].filter(
        (val) => val !== keyBehaviorId
      );
      setIsAllParentChecked(false);

      if (newSelectedData[parentId].length == 0) {
        delete newSelectedData[parentId];
        newOrderingData = newOrderingData.filter((val) => val.id !== parentId);
      }
    }

    setOrderingData(newOrderingData);
    setSelectedData(newSelectedData);
  };

  const selectAllDictionary = async (checked) => {
    let newSelectedData = {};
    let newOrderingData = [];
    if (checked) {
      newSelectedData = modifiedSelectedData(dataCompetency, newSelectedData);
      newOrderingData = dataCompetency;
    }

    setSelectedData(newSelectedData);
    setOrderingData(newOrderingData);
    setIsAllParentChecked(checked);
  };

  const handleError = (error) => {
    if (error.code == 422) showWarningMessage(error.message);
  };

  const orderingBodyBehaviorIds = () => {
    let newBehaviorIds = [];
    orderingData.forEach((d) => {
      if (selectedData?.[d.id]?.length > 0)
        newBehaviorIds = [...newBehaviorIds, ...selectedData[d.id]];
      else newBehaviorIds.push(d.id);
    });

    return newBehaviorIds;
  };

  const saveData = async () => {
    let name = document.getElementById('inputModelName').value;
    let desc = document.getElementById('inputModelDesc').value;
    let body = bodyDictionaryOrModel(name, desc, pathname, null, null, 'model');
    let behaviorIds = orderingBodyBehaviorIds();

    body.subType = checkedRadio;
    if (isAllParentChecked) {
      body.behaviorIds = Object.keys(selectedData).map(Number);
    } else {
      body.behaviorIds = behaviorIds;
    }

    if (config?.behaviorsFramework == 'proficiency_levels') {
      body.expectedLevelIds = expectedLevel;
    }

    if (pathname.includes('add')) {
      const { data, error } = await postBehaviorsModels(body);
      if (data) {
        showNotificationMessage('Created Successfully');
        backFunction();
      }

      if (error) {
        handleError(error);
      }
    }

    if (pathname.includes('edit')) {
      delete body.subType;
      delete body.modelType;
      const { data, error } = await patchBehaviorsModels(id, body);
      if (data) {
        showNotificationMessage('Updated Successfully');
        backFunction();
      }

      if (error) {
        handleError(error);
      }
    }
  };

  const _getValueOrCompetencyDictionary = async () => {
    let params = paramsDictionaryOrModel(
      pathname,
      { limit: 100 },
      'dictionary',
      false,
      false
    );
    setIsLoading(true);
    const { data, pagination } = await getBehaviors(params);
    if (data) {
      setDataCompetency(data);
      setHasMoreChild(pagination.nextOffset == null ? false : true);
      setNextOffsetChild(pagination.nextOffset);
      setCurrentSizeChild(pagination.currentSize + currentSizeChild);

      setIsLoading(false);
    }

    if (pathname.includes('edit') && initialRender) {
      await _getSingleBehaviorModel();
    }
  };

  const _onScroll = (e) => {
    e.stopPropagation();
    const target = e.target;

    const loadMore = async () => {
      setOnLoadMore(true);
      const _params = paramsDictionaryOrModel(
        pathname,
        { offset: nextOffsetChild },
        'dictionary',
        false,
        false
      );
      const { data, pagination } = await getBehaviors(_params);
      if (data) {
        setDataCompetency(dataCompetency.concat(data));
        setHasMoreChild(pagination.nextOffset == null ? false : true);
        setNextOffsetChild(pagination.nextOffset);
        setCurrentSizeChild(pagination.currentSize + currentSizeChild);

        if (isAllParentChecked) {
          let newSelectedData = modifiedSelectedData(
            data,
            JSON.parse(JSON.stringify(selectedData))
          );
          setSelectedData(newSelectedData);
        }
      }
      setOnLoadMore(false);
    };

    if (!onLoadMore && hasMoreChild) {
      loadMoreValidator(target, 500, () => {
        loadMore();
      });
    }
  };

  const modifiedSelectedData = (array, newSelectedData) => {
    array.forEach((d) => {
      if (d?.keyBehaviorIds?.length > 0) {
        newSelectedData[d.id] = d.keyBehaviorIds;
      } else {
        newSelectedData[d.id] = [];
      }
    });

    return newSelectedData;
  };

  const backFunction = async () => {
    resetInputState();
    setIsAllParentChecked(false);
    setSelectedData({});
    setOrderingData([]);
    setDataCompetency([]);
    setIsLoading(false);
    setCurrentTab('model');
    history.replace(`/${routeName}/model`);
  };

  const _getSingleBehaviorModel = async () => {
    const { data } = await getSingleBehaviorsModels(id);
    if (data) {
      let newSelectedData = {};
      let newOrderingData = [];
      let inputTitle = document.getElementById('inputModelName');
      let inputDesc = document.getElementById('inputModelDesc');

      inputTitle.value = data.title;
      inputDesc.value = data.description;

      if (data.subType == 'work_attribute') {
        setCheckedRadio('work_attribute');
      }
      let params = paramsDictionaryOrModel(
        pathname,
        { limit: 100, ids: data?.behaviorIds },
        'dictionary',
        false,
        false
      );
      const { data: behaviorData } = await getBehaviors(params);

      data?.behaviorIds &&
        data.behaviorIds.forEach((d) => {
          newSelectedData[d] = [];
          behaviorData.forEach(
            (dictionary) =>
              dictionary.id == d && newOrderingData.push(dictionary)
          );
        });
      data?.keyBehaviorParentIds &&
        data.keyBehaviorParentIds.forEach(
          (d) =>
            (newSelectedData[d.parentId] = [
              ...(newSelectedData[d.parentId] || []),
              d.id
            ])
        );

      setSelectedData(newSelectedData);
      setOrderingData(newOrderingData);
      data?.expectedLevelIds && setExpectedLevel(data?.expectedLevelIds);
    }
  };

  useEffect(() => {
    _getValueOrCompetencyDictionary();
  }, [debouncedSearchValue]);

  const divMainBody = document.getElementById('main-body');
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setInitialRender(false);

    if (divMainBody) divMainBody.style.overflow = 'hidden';
  }, []);

  return (
    <>
      <HeaderPage
        titlePage={getObjectiveLocale(title)}
        backToUrl={`/${routeName}/model`}
        isHeaderComposer={true}
      />
      <div className="px-[0px] py-[0px] h-[calc(100vh-120px)]">
        <div className="add-new-competencies">
          <div className="fixed right-0 min-w-[230px] z-index-10 mt-[24px] mr-[40px]">
            <ContentNavigator
              title="CONTENTS"
              activeSection={activeSection}
              listNavigatorContents={list}
              sectionContent="scrollable-content"
            />
          </div>
          <div
            data-cy="competency-model-scrollable-content"
            id="scrollable-content"
            className="h-[calc(100vh-120px)] overflow-auto relative pl-[168px] bg-n-000"
            onScroll={(e) => setActiveNavigator(e, list, setActiveSection)}
          >
            <div className="body-add-competencies">
              <div
                className="wrapper-input-competencies pt-[24px]"
                id="name-section"
              >
                <label htmlFor="missionName" className="fw-bold">
                  Give it a name
                </label>
                <p className="typography-secondary mb-[16px]">
                  Something clear and easily to be recognized.
                </p>
                <p className="typography-h100 typography-secondary mb-[8px]">
                  Model name
                </p>
                <input
                  data-cy="competencies-values-model-input-name"
                  id="inputModelName"
                  className="input-competencies"
                  placeholder={`Input ${getObjectiveLocale(
                    routeName
                  )} model name`}
                  onChange={(e) =>
                    onChangeValueOrCompetency(
                      e.currentTarget.value,
                      e.currentTarget.id
                    )
                  }
                />
              </div>
              <div className="divider-line mt-[40px]" />
              <div
                className="wrapper-input-competencies"
                id="description-section"
              >
                <label
                  htmlFor="missionDescription"
                  className="fw-bold mt-[40px]"
                >
                  Tell something about this
                </label>
                <p className="typography-secondary mb-[16px]">
                  Describe the purpose of creating this group.
                </p>
                <p className="typography-h100 typography-secondary mb-[8px]">
                  Description
                </p>
                <textarea
                  data-cy="competencies-values-model-input-desc"
                  id="inputModelDesc"
                  className="input-competencies"
                  rows="8"
                  cols="50"
                  placeholder={`Input description of this ${getObjectiveLocale(
                    routeName
                  )} model`}
                  onChange={(e) =>
                    onChangeValueOrCompetency(
                      e.currentTarget.value,
                      e.currentTarget.id
                    )
                  }
                />
              </div>
              <div className="divider-line mt-[40px]" />
              {config?.allowUsingWorkAttributes && routeName === 'values' && (
                <>
                  <div
                    className="wrapper-input-competencies mb-[40px]"
                    id="model-type-section"
                  >
                    <label htmlFor="modelType" className="fw-bold mt-[40px]">
                      Model Type
                    </label>
                    <p className="typography-secondary mb-[16px]">
                      What type do you want to use in this value model?
                    </p>
                    <RadioButton
                      name="archive-group-option"
                      checked={checkedRadio == 'regular'}
                      onClick={() => setCheckedRadio('regular')}
                      addClass="mt-[20px] mb-[16px]"
                      disabled={pathname.includes('edit')}
                    >
                      <span className="ml-[8px]">Set this model as value</span>
                    </RadioButton>
                    <RadioButton
                      name="archive-group-option"
                      checked={checkedRadio == 'work_attribute'}
                      onClick={() => setCheckedRadio('work_attribute')}
                      addClass="mb-[16px]"
                      disabled={pathname.includes('edit')}
                    >
                      <span className="ml-[8px]">
                        Set this model as work attribute
                      </span>
                    </RadioButton>
                  </div>
                  <div className="divider-line mt-[40px]" />
                </>
              )}
              <div id={`${routeName}-section`} className="mb-[64px]">
                <div className="wrapper-input-competencies mt-[40px]">
                  <p className="capitalize text-n-900 typography-h500 mb-[4px]">
                    {getObjectiveLocale(routeName)}
                  </p>
                  <p className="text-n-800 typography-paragraph mb-[16px]">
                    Select {getObjectiveLocale(routeName)} to be included in
                    this model{' '}
                  </p>
                  <div className="wrapper-search-competencies">
                    <input
                      className="input-competencies search-competencies"
                      placeholder={`Input ${getObjectiveLocale(
                        routeName
                      )} model name`}
                      id="search"
                      name="search"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <SVGIcon
                      iconName="icon-search"
                      size="24"
                      fillColor="var(--base-600)"
                    />
                  </div>
                </div>

                {isLoading && <LoadingComponent />}

                {!isLoading && (
                  <div className="mt-[32px]">
                    <div className="flex pb-[10px] border-table typography-paragraph text-n-800">
                      <div className="flex-center-center w-[10%]">
                        <Checkbox
                          name={'parentCheckbox'}
                          id={'parentCheckbox'}
                          checked={isAllParentChecked}
                          onChange={(e) =>
                            selectAllDictionary(e.currentTarget.checked)
                          }
                        />
                      </div>
                      <div className="flex items-center uppercase pl-[16px] w-[30%] typography-h300 text-n-800">
                        Name
                      </div>
                      <div className="flex items-center uppercase pl-[16px] w-[45%] typography-h300 text-n-800">
                        Description
                      </div>

                      {config?.behaviorsFramework == 'proficiency_levels' && (
                        <div className="flex items-center justify-center uppercase pl-[16px] w-[15%] typography-h300 text-n-800">
                          Expected
                        </div>
                      )}
                    </div>

                    <div
                      className="py-[10px] border-table h-[400px] overflow-auto"
                      onScroll={(e) => _onScroll(e)}
                    >
                      {dataCompetency.length > 0 &&
                        dataCompetency.map((dictionary, index) => (
                          <NewModelCard
                            key={index}
                            index={index}
                            isAllParentChecked={isAllParentChecked}
                            selectDictionary={selectDictionary}
                            selectKeyBehaviour={selectKeyBehaviour}
                            dictionary={dictionary}
                            dictionaryData={dataCompetency}
                            dataCompetency={dataCompetency}
                            setDataCompetency={setDataCompetency}
                            selectedData={selectedData}
                            setSelectedData={setSelectedData}
                            arrayAccordion={arrayAccordion}
                            setArrayAccordion={setArrayAccordion}
                            expectedLevel={expectedLevel}
                            setExpectedLevel={setExpectedLevel}
                            behaviorsFramework={config?.behaviorsFramework}
                          />
                        ))}
                    </div>
                  </div>
                )}

                {!isLoading && (
                  <>
                    <div className="divider-line mt-[40px]" />

                    <OrderingBehavior
                      routeName={routeName}
                      orderingData={orderingData}
                      setOrderingData={setOrderingData}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          {!isLoading && (
            <Footer
              linkButton={true}
              handleClick={() => saveData()}
              datacy="competencies-values-model-button-save"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AddEditCompetencyModel;

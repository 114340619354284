import React, { useEffect, useState } from 'react';

import { useValueCompetency } from 'context/admin/ValueCompetencyContext';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';

import Modal from 'components/shared/modal/Modal';

const ModalCompetencies = ({
  type,
  activeIds,
  addCompetency,
  close,
  currentPath
}) => {
  const {
    getValueOrCompetencyData,
    appendValueOrCompetencyData,
    hasMore,
    dataValueOrCompetency
  } = useValueCompetency();
  const abortController = new AbortController();

  const [onLoadMore, setOnLoadMore] = useState(false);

  const _getValueOrCompetencyData = async () => {
    await getValueOrCompetencyData(currentPath, 'dictionary');
  };

  const _onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      setOnLoadMore(true);
      await appendValueOrCompetencyData(currentPath);
      setOnLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 100, () => {
        loadMore();
      });
    }
  };

  useEffect(() => {
    _getValueOrCompetencyData();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Modal
      eventOnClickClose={close}
      title={getObjectiveLocale(`Add Competency`)}
      headerIcon={{
        name: type === 'recognition' ? 'icon-strength' : 'icon-improvement'
      }}
      footerClass="h-[0px]"
    >
      <div className="add-competency-container" onScroll={(e) => _onScroll(e)}>
        {dataValueOrCompetency &&
          dataValueOrCompetency.map((object) => {
            return (
              <div
                className={`single-competency-container ${
                  activeIds.includes(object.id) ? 'on-hover' : ''
                }`}
                onClick={() => {
                  addCompetency(object.id, type);
                  close();
                }}
                key={object.id}
              >
                <h6 className="competency-title">{object.title}</h6>
                <p className="competency-detail">{object.description}</p>
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

export default ModalCompetencies;

import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';

import { checkinSetReminder, getReminderCheckin } from 'client/CheckinClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import { formatTimezone } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Objectives from 'components/objectives/Objectives';
import TimePickerV2 from 'components/shared/TimePickerV2';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import Modal from 'components/shared/modal/Modal';
import { trackEvent } from 'src/utils/AnalyticUtils';

function ModalCheckin({ onClose }) {
  const [reminder, setReminder] = useState(false);
  const [interval, setInterval] = useState(1);
  const [every, setEvery] = useState('day');
  const [date, setDate] = useState(1);
  const [day, setDay] = useState('Monday');
  const [hours, setHours] = useState(null);
  const [showReminder, setShowReminder] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef();
  useClickOutside(ref, () => {
    setShowDropdown(false);
  });
  const { refetchObjectives } = useRefetchQuery();

  const { user, config } = useUser();
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';
    getReminder();

    return () => {
      const body = document.getElementsByTagName('body')[0];
      body.removeAttribute('style');
    };
  }, []);

  const getReminder = async () => {
    const { data } = await getReminderCheckin();

    if (data) {
      const params = data.params;
      const reminder = data.setReminder;
      let interval = 1;
      let every = 'day';
      let date = 1;
      let day = 'Monday';
      let hours = dayjs();
      if (Object.keys(params).length > 0) {
        const lastDate = dayjs(params?.starts);
        interval = params.interval;
        every = params.every;
        date = lastDate.date();
        day = lastDate.format('dddd');
        hours = lastDate;
      }

      setReminder(reminder);
      setInterval(interval);
      setEvery(every);
      setDate(date);
      setDay(day);
      setHours(hours);
    }
  };

  const toggleShowSetReminder = () => {
    setShowReminder(!showReminder);
  };

  const toggleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const setNewHours = (time) => {
    setHours(time);
  };

  const changeRecurring = async (
    reminder,
    interval,
    every,
    date,
    day,
    hours
  ) => {
    let localDate = date;
    let startsAt = dayjs('00:00:00', 'hh:mm:ss A');
    let changedDate;

    const daysInMonth = dayjs().daysInMonth();
    if (localDate > daysInMonth) {
      localDate = daysInMonth;
    }

    if (hours) {
      startsAt = dayjs(hours).format('hh:mm A');
    }

    const payload = {
      setReminder: reminder,

      params: {
        interval,
        every,
        at: startsAt
      }
    };

    let period = every === 'day' ? 'dai' : every;
    let properties = {
      'set reminder': reminder,
      period: `${period}ly`,
      time: changedDate,
      ...(every === 'week' && { day: day.toLowerCase() }),
      ...(every === 'month' && { date: date })
    };
    const { isSuccess } = await checkinSetReminder(payload);
    isSuccess &&
      trackEvent({
        event: 'check in-set reminder',
        eventProperties: properties
      });

    setReminder(reminder);
    setInterval(interval);
    setEvery(every);
    setDate(localDate);
    setDay(day);
    setHours(hours);
    setShowDropdown(false);
  };

  const thisYear = new Date().getFullYear();
  const yearBasedOnConfig =
    config?.checkInPeriod === 'past_year' ? thisYear - 1 : thisYear;
  const arrayOfDates = Array.from(Array(31)).map((e, i) => i + 1);
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  const pageParams = {
    assigneeId: [user.id],
    type: ['annual_goal', 'goal'],
    parentNotAssignedTo: user.id,
    state: ['running'],
    sortColumn: 'due_date',
    sortDirection: 'asc',
    periodBegin: formatTimezone(`${yearBasedOnConfig}-01-01`, 'start'),
    periodEndBefore: formatTimezone(`${yearBasedOnConfig}-12-31`, 'end')
  };

  return (
    <Modal
      title="Check In"
      eventOnClickClose={onClose}
      className="!w-[976px] modal-checkin"
      withTertiaryBtn={
        config?.checkinSetReminder &&
        config?.checkinLoadPrioritize == 'individual' && {
          title: 'Set reminder',
          dataCy: 'btn-set-reminder',
          onClick: () => toggleShowSetReminder()
        }
      }
      withPrimaryBtn={{
        title: 'Done',
        dataCy: 'btn-done',
        onClick: () => {
          onClose();
          refetchObjectives('all');
        }
      }}
    >
      <div className={`my-goals-container`}>
        <div className="list-goals">
          <Objectives
            extraFilter={pageParams}
            cardModel={'checkin-list'}
            page={'checkin'}
            action={false}
            isParent
          />
        </div>
      </div>
      <div
        className={`modal-bottom flex ${
          config && config.checkinLoadPrioritize == 'individual'
            ? 'justify-between'
            : 'justify-end'
        }`}
      >
        {showReminder && (
          <div
            ref={ref}
            className={`set-reminder-box pt-[8px] w-[288px] ${
              !reminder ? 'toggle' : ''
            }`}
          >
            <div className="typography-h200 text-n-600 px-[16px] pb-[16px]">
              {getObjectiveLocale('Set reminder')}
            </div>
            <div className="typography-button px-[16px] mb-[16px]">
              {getObjectiveLocale('Remind me to check in')}
              <SmallToggleSwitchPurple
                addClass="inline-block float-right"
                idStr="chekin-switch"
                onChange={() =>
                  changeRecurring(!reminder, interval, every, date, day, hours)
                }
                isChecked={reminder}
              />
            </div>
            {reminder && (
              <div className="border-solid border-t border-0 border-n-400 px-[16px] pb-[16px]">
                <div className="timeline">
                  <span
                    className={`bg-n-100 h-[36px] typography-button ${
                      every === 'day' ? 'text-n-000 bg-base-600' : ''
                    }`}
                    onClick={() =>
                      changeRecurring(
                        reminder,
                        interval,
                        'day',
                        date,
                        day,
                        hours
                      )
                    }
                  >
                    {getObjectiveLocale('Daily')}
                  </span>
                  <span
                    className={`bg-n-100 h-[36px] typography-button ${
                      every === 'week' ? 'text-n-000 bg-base-600' : ''
                    }`}
                    onClick={() =>
                      changeRecurring(
                        reminder,
                        interval,
                        'week',
                        date,
                        day,
                        hours
                      )
                    }
                  >
                    {getObjectiveLocale('Weekly')}
                  </span>
                  <span
                    className={`bg-n-100 h-[36px] typography-button ${
                      every === 'month' ? 'text-n-000 bg-base-600' : ''
                    }`}
                    onClick={() =>
                      changeRecurring(
                        reminder,
                        interval,
                        'month',
                        date,
                        day,
                        hours
                      )
                    }
                  >
                    {getObjectiveLocale('Monthly')}
                  </span>
                </div>
                {every === 'day' && (
                  <TimePickerV2
                    selectedTime={dayjs(hours)}
                    handleChange={(time) => {
                      setNewHours(time),
                        changeRecurring(
                          reminder,
                          interval,
                          every,
                          date,
                          day,
                          time
                        );
                    }}
                  />
                )}
                {every === 'month' && (
                  <div className="flex flex-col">
                    <div className="dropdown dropdown-days mb-[16px]">
                      <span className="label"> {getObjectiveLocale('on')}</span>
                      <Button.Tertiary
                        customClass="btn btn-secondary dropdown-toggle"
                        onClick={toggleShowDropdown}
                      >
                        {' '}
                        {date}
                        <i className="fa fa-caret-down" />
                      </Button.Tertiary>

                      {showDropdown && (
                        <div
                          className="dropdown-menu w-[76px]"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div>
                            {arrayOfDates.map((dateOfMonth) => (
                              <span
                                onClick={() =>
                                  changeRecurring(
                                    reminder,
                                    interval,
                                    every,
                                    dateOfMonth,
                                    day,
                                    hours
                                  )
                                }
                              >
                                {dateOfMonth}
                              </span>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="dropdown">
                      <span className="label"> {getObjectiveLocale('at')}</span>
                      <TimePickerV2
                        selectedTime={dayjs(hours)}
                        handleChange={(time) => {
                          setNewHours(time),
                            changeRecurring(
                              reminder,
                              interval,
                              every,
                              date,
                              day,
                              time
                            );
                        }}
                      />
                    </div>
                  </div>
                )}
                {every === 'week' && (
                  <div>
                    <div className="dropdown dropdown-days mb-[16px]">
                      <span className="label">{getObjectiveLocale('on')}</span>
                      <Button.Tertiary
                        customClass="btn btn-secondary dropdown-toggle"
                        onClick={toggleShowDropdown}
                      >
                        {' '}
                        {day}
                        <i className="fa fa-caret-down" />
                      </Button.Tertiary>

                      {showDropdown && (
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div>
                            {daysOfWeek.map((dayOfWeek) => (
                              <span
                                onClick={() =>
                                  changeRecurring(
                                    reminder,
                                    interval,
                                    every,
                                    date,
                                    dayOfWeek,
                                    hours
                                  )
                                }
                              >
                                {dayOfWeek}
                              </span>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="dropdown">
                      <span className="label">{getObjectiveLocale('at')}</span>
                      <TimePickerV2
                        selectedTime={dayjs(hours)}
                        handleChange={(time) => {
                          setNewHours(time),
                            changeRecurring(
                              reminder,
                              interval,
                              every,
                              date,
                              day,
                              time
                            );
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ModalCheckin;

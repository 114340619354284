import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getDatas } from 'client/admin/GoalWeight.js';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale, setDropdownOptions } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import BannerBox from 'components/shared/BannerBox';
import Checkbox from 'components/shared/Checkbox';

const CustomAttribute = ({
  customAttributes,
  templateData,
  setTemplateData
}) => {
  const [selectedAttribute, setSelectedAttribute] = useState(
    customAttributes[0] || []
  );
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allowOverride, setAllowOverride] = useState(false);
  const [categories, setCategories] = useState([]);
  const [initialSetup, setInitialSetup] = useState(false);
  const [attributesOptions, setAttributesOptions] = useState([]);

  const { mechanism } = templateData?.config || {};
  const {
    config: { objectiveWeightType }
  } = useUser();
  const history = useHistory();

  const openObjectivesSettings = () => {
    history.replace('/objectives/settings');
  };

  const getObjectiveCategories = async () => {
    const { data } = await getDatas();
    setCategories(data);
  };

  const handleSelectCheckbox = (checked, value) => {
    if (checked) {
      const newSelectedCategories = [...selectedCategories];
      newSelectedCategories.push({ ...value });
      setSelectedCategories(newSelectedCategories);
    } else {
      const newSelectedCategories = [...selectedCategories].filter(
        (selected) => {
          return selected.id != value.id;
        }
      );
      setSelectedCategories(newSelectedCategories);
    }
  };

  const destructureConfigFromSelected = () => {
    return [
      {
        id: selectedAttribute?.id,
        allowOverride: mechanism == 'selected_aspect' ? true : allowOverride,
        objectiveCategoryIds: selectedCategories.map((category) => {
          return category.id;
        })
      }
    ];
  };

  const getDescription = () => {
    return mechanism == 'selected_aspect'
      ? 'Select which attribute to be used from the list of active custom final score attributes.'
      : 'System will show suggested rating for selected custom attribute based on average ongoing rating from reviewed parent goals incorporated with each weight types.';
  };

  useEffect(() => {
    getObjectiveCategories();
    setAttributesOptions(
      setDropdownOptions(customAttributes, 'name', setSelectedAttribute, '', '')
    );
  }, []);

  useEffect(() => {
    if (
      categories.length != 0 &&
      templateData?.reviewAspects &&
      initialSetup == false
    ) {
      const reviewAspects = templateData.reviewAspects[0];

      setSelectedAttribute(
        customAttributes?.find((attribute) => {
          return attribute.id == reviewAspects.id;
        })
      );
      setSelectedCategories(
        categories.filter((category) => {
          return reviewAspects?.objectiveCategoryIds.includes(category.id);
        })
      );
      setAllowOverride(reviewAspects.allowOverride);
      setInitialSetup(true);
    }
  }, [categories, templateData]);

  useEffect(() => {
    const newConfig = templateData.config;
    newConfig.reviewAspects = destructureConfigFromSelected();

    setTemplateData({ ...templateData, config: newConfig });
  }, [selectedAttribute, selectedCategories, allowOverride]);

  return (
    <>
      <p className="mt-[24px] mb-[16px] typography-h300">
        Advanced Configuration
      </p>
      <div className="py-[16px] border-n400 bg-n-000 rounded-[4px]">
        <h4 className="typography-h400 text-n-900 mb-[4px] px-[16px]">
          Custom Attribute
        </h4>
        <div>
          <p className="typography-paragraph text-n-800 mb-[16px] px-[16px]">
            {getObjectiveLocale(getDescription())}
          </p>
          {
            // show warning box when organization doesn’t set the objective weight method to type
            mechanism == 'weight_type' && objectiveWeightType !== 'type' && (
              <>
                <BannerBox
                  text={getObjectiveLocale(
                    'Your organization doesn’t set the objective weight method to type'
                  )}
                  type="error"
                />
                <p className="typography-paragraph text-n-800">
                  {getObjectiveLocale(
                    'To activate the method type go to objective weight setting under the setting menu and open the weight tab or You can'
                  )}{' '}
                  <span
                    className="text-b-600 cursor-pointer"
                    onClick={openObjectivesSettings}
                  >
                    {getObjectiveLocale('click here.')}
                  </span>
                </p>
              </>
            )
          }
          {((mechanism == 'weight_type' && objectiveWeightType == 'type') ||
            mechanism == 'selected_aspect') &&
            customAttributes && (
              <div className="px-[16px]">
                <p className="typography-h100 text-n-800 mb-[8px]">
                  {getObjectiveLocale('Attribute')}
                </p>
                <Dropdown customClass="w-full">
                  <Dropdown.Trigger>
                    <Trigger
                      customWrapperClass="w-full"
                      textStyle={{ marginBottom: '0' }}
                      topLabel={false}
                      label={false}
                      icon={false}
                      text={selectedAttribute?.name}
                    />
                  </Dropdown.Trigger>
                  <Dropdown.MenuItems
                    options={attributesOptions}
                    customClass="w-full"
                    maxHeight={150}
                  />
                </Dropdown>
              </div>
            )}

          {mechanism == 'weight_type' && objectiveWeightType == 'type' && (
            <>
              <div className="mb-[16px] mt-[16px] px-[16px]">
                <p className="typography-h300 text-n-800 mb-[8px]">
                  {categories.length} goal type available
                </p>
                {selectedCategories.length == 0 && (
                  <BannerBox
                    text={getObjectiveLocale(
                      'Please select at least one goal type'
                    )}
                    type="info"
                  />
                )}
              </div>
              <div className="mb-[16px] px-[16px]">
                {categories.map((category, idx) => {
                  return (
                    <div key={idx} className="flex items-center w-full">
                      <Checkbox
                        id={`category-${category.id}`}
                        name={`category-${category.id}`}
                        checked={selectedCategories.some(
                          (selected) => selected.id == category.id
                        )}
                        onChange={(e) =>
                          handleSelectCheckbox(e.target.checked, category)
                        }
                        labelClass="cursor-pointer w-full"
                        customContainerClass="w-full"
                      >
                        <span className="ml-[8px] typography-paragraph text-n-900">
                          {category.name}
                        </span>
                      </Checkbox>
                    </div>
                  );
                })}
              </div>

              {/*
              ==========================================
                Temporarily hide this section
                this will be needed in the future if
                backend already support allow override
              ==========================================

              <div className="border-solid border-t border-0 border-n-400 pt-[16px] px-[16px]">
                <div className="flex items-center w-full">
                  <Checkbox
                    id={'allow-override'}
                    name={'allow-override'}
                    checked={allowOverride}
                    onChange={() => setAllowOverride(!allowOverride)}
                    labelClass="cursor-pointer w-full"
                    customContainerClass="w-full"
                  >
                    <span className="ml-[8px] typography-paragraph text-n-900">Allow reviewer override the suggested rating given by system</span>
                  </Checkbox>
                </div>
              </div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomAttribute;

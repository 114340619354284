import useModalObjectives from 'hooks/useModalObjectives';
import {
  getObjectiveTaskType,
  getObjectiveType
} from 'src/utils/ObjectivesHelper';

const useModalConfirmAlignTask = () => {
  const { showModal, modalType } = useModalObjectives();

  const showConfirmationModal = (taskType, action, nextAction) => {
    showModal({
      modalType: 'confirmAlignTask',
      props: { taskType, action, onSubmit: nextAction }
    });
  };

  const checkAlignmentTask = ({ objectiveValue, newParent, nextAction }) => {
    const parentType = getObjectiveType(newParent);
    const parentTaskType = getObjectiveTaskType(newParent);
    const taskType = objectiveValue?.taskType;

    if (taskType === 'personal') {
      if (parentType === 'project') {
        showConfirmationModal(taskType, 'align-to-project', nextAction);
        return true;
      }

      if (parentType === 'task' && parentTaskType === 'project') {
        showConfirmationModal(taskType, 'align-to-task-project', nextAction);
        return true;
      }
    } else {
      if (!newParent) {
        showConfirmationModal(taskType, 'remove-alignment', nextAction);
        return true;
      }

      if (parentType === 'goal') {
        showConfirmationModal(taskType, 'align-to-goal', nextAction);
        return true;
      }

      if (parentType === 'task' && parentTaskType === 'personal') {
        showConfirmationModal(taskType, 'align-to-task-personal', nextAction);
        return true;
      }
    }
  };

  return {
    checkAlignmentTask,
    isModalConfirmAlignTaskShow: modalType !== null
  };
};

export default useModalConfirmAlignTask;

import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  getGroupById,
  postGroupsPermission,
  updateGroupsPermission
} from 'client/adminClient';
import { UsersAdminProvider } from 'context/admin/UsersAdminContext';

import HeaderPage from 'components/admin/HeaderPage';
import StepNameDescription from 'components/admin/permissions/Steps/StepNameDescription';
import StepSetMembers from 'components/admin/permissions/Steps/StepSetMembers';
import StepVerify from 'components/admin/permissions/Steps/StepVerify';
import Footer from 'components/shared/Footer';
import VerticalStepsComponent from 'components/shared/VerticalStepsComponent';

import StepConfigureClusters from './StepConfigureClusters';

function CreateGroup({ route: { title, isReadOnly } }) {
  const history = useHistory();
  const initialEdit = useRef(false);
  const { type, id } = useParams();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedClusters, setSelectedClusters] = useState({});
  const [group, setGroup] = useState({
    name: '',
    description: '',
    group_type: 'cluster_group'
  });
  const [members, setMembers] = useState({
    includes: [],
    excludes: [],
    select_all: false
  });
  const hasMember =
    group.member_count > 0 || members.select_all || members.includes.length > 0;
  const [stepsArray, setStepsArray] = useState([
    {
      name: 'Name & Description',
      completed: false,
      disabled: false
    },
    {
      name: 'Members',
      completed: false,
      disabled: type === 'create'
    },
    {
      name: 'Configuration',
      completed: type === 'edit',
      disabled: type === 'create'
    },
    {
      name: 'Verify',
      completed: false,
      disabled: type === 'create'
    }
  ]);

  const submitStep1 = async () => {
    const { data } = await postGroupsPermission(group);
    if (data) {
      return data;
    } else {
      return false;
    }
  };

  const updateGroup = async () => {
    const {
      id,
      deleted,
      member_count,
      member_ids,
      current_state,
      clusters,
      ...body
    } = group;
    const { data } = await updateGroupsPermission(
      id,
      {
        ...body,
        ...members,
        clusters: selectedClusters
      },
      true
    );
    if (data) {
      return data;
    } else {
      return false;
    }
  };

  const submitName = async (noStep = false) => {
    let tempStepsArray = JSON.parse(JSON.stringify(stepsArray));
    const data = id ? await updateGroup() : await submitStep1();
    if (data) {
      const { id } = data;
      history.push(`/objective-visibility/edit/${id}`);
      tempStepsArray[step].completed = Boolean(group.name);
      tempStepsArray.map((data) => {
        data.disabled = false;
      });
      setStepsArray(tempStepsArray);
      !noStep && setStep(step + 1);
    }
  };

  const submitStepAfter = async (type, noStep = false) => {
    let tempStepsArray = JSON.parse(JSON.stringify(stepsArray));
    const data = await updateGroup();
    if (data) {
      tempStepsArray[step].completed = type == 'member' ? hasMember : true;
      tempStepsArray[3].completed =
        tempStepsArray[0].completed &&
        tempStepsArray[1].completed &&
        tempStepsArray[2].completed;
      setStepsArray(tempStepsArray);
      !noStep && setStep(step + 1);
    }
  };

  const _getGroupData = async (id) => {
    setLoading(true);
    const { data } = await getGroupById(id, null, true);

    if (data) {
      const initialSelectedValues = Object.values(data?.clusters).reduce(
        (res, clusters) => {
          res = { ...res, ...clusters };
          return res;
        },
        {}
      );
      setMembers({
        excludes: [],
        select_all: false,
        includes: data.member_ids
      });
      setSelectedClusters(initialSelectedValues);
      setGroup(data);

      if (initialEdit.current) {
        setStepsArray([
          {
            name: 'Name & Description',
            completed: data.name,
            disabled: false
          },
          {
            name: 'Members',
            completed: data.member_count > 0,
            disabled: type === 'create'
          },
          {
            name: 'Configuration',
            completed: true,
            disabled: type === 'create'
          },
          {
            name: 'Verify',
            completed: data.name && data.member_count > 0,
            disabled: type === 'create'
          }
        ]);
      }

      setLoading(false);
    }
  };

  const handleStepChange = async (noStep = false) => {
    switch (step) {
      case 0:
        submitName(noStep);
        break;
      case 1:
        submitStepAfter('member', noStep);
        break;
      case 2:
        submitStepAfter('configure', noStep);
        break;
    }
  };

  const setActiveStepNow = (activeName) => {
    handleStepChange(true);
    stepsArray.map((step, index) => {
      if (step.name == activeName) {
        setStep(index);
      }
    });
  };

  useEffect(() => {
    if (id) {
      _getGroupData(id);
    }
  }, [step]);

  useEffect(() => {
    if (type === 'edit') {
      initialEdit.current = true;
    } else {
      initialEdit.current = false;
    }
  }, []);

  return (
    <>
      <HeaderPage
        titlePage={title}
        backToUrl={`/objectives/settings?type=visibility&tab=groups`}
        isHeaderComposer={true}
      />
      <div className="create-group-permissions flex pl-[0px] pl-[0px] tw bg-n-000">
        <VerticalStepsComponent
          steps={stepsArray}
          activeStep={stepsArray[step].name}
          setActiveStep={setActiveStepNow}
        />
        <div className="mt-[24px] w-[83%]">
          <div className="ml-[40px] h-[1000px]">
            {(() => {
              switch (step) {
                case 0:
                  return (
                    <StepNameDescription
                      group={group}
                      setGroup={setGroup}
                      isReadOnly={isReadOnly}
                    />
                  );
                case 1:
                  return (
                    <UsersAdminProvider>
                      <StepSetMembers
                        members={members}
                        setMembers={setMembers}
                        isCluster={true}
                        disabled={isReadOnly}
                      />
                    </UsersAdminProvider>
                  );
                case 2:
                  return (
                    <StepConfigureClusters
                      clustersData={group.clusters || {}}
                      setSelectedClusters={setSelectedClusters}
                      selectedClusters={selectedClusters}
                      loading={loading}
                      isReadOnly={isReadOnly}
                    />
                  );
                case 3:
                  return (
                    <UsersAdminProvider>
                      <StepVerify
                        group={group}
                        loading={loading}
                        isCluster={true}
                        isReadOnly={isReadOnly}
                      />
                    </UsersAdminProvider>
                  );
              }
            })()}
          </div>
          <Footer
            linkButton={true}
            linkButtonName={'Back'}
            linkButtonDisabled={loading}
            save={false}
            datacy={'save-group'}
            buttonPrimaryName={step === 3 ? 'Submit' : 'Next'}
            buttonPrimaryDisabled={loading}
            handleTesting="btn-submit"
            handleClick={
              step != 3
                ? () => handleStepChange()
                : () =>
                    history.replace(
                      '/objectives/settings?type=visibility&tab=groups'
                    )
            }
            handleRoute={
              step == 0
                ? () =>
                    history.replace(
                      `/objectives/settings?type=visibility&tab=groups`
                    )
                : () => setStep(step - 1)
            }
          />
        </div>
      </div>
    </>
  );
}

export default CreateGroup;

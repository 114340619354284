import { create } from 'zustand';

const useTeam = create((set) => ({
  tribes: [],
  squads: [],
  appovalSubGoals: [],
  approvalParentGoal: {},
  isTribeLoading: true,
  isSquadLoading: true,
  isAddingApprovalSubGoal: false,
  setTribes: (data) => set({ tribes: data }),
  setSquads: (data) => set({ squads: data }),
  setApprovalSubGoals: (data) => set({ approvalSubGoals: data }),
  setApprovalParentGoal: (data) => set({ approvalParentGoal: data }),
  setIsTribeLoading: (data) => set({ isTribeLoading: data }),
  setIsSquadLoading: (data) => set({ isSquadLoading: data }),
  setIsAddingApprovalSubGoal: (data) => set({ isAddingApprovalSubGoal: data })
}));

export default useTeam;

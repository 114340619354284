import React from 'react';

import 'scss/web/_progress-bar.scss';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { whichBgColorClassName, whichStatus } from 'utils/ObjectivesHelper';

import ProgressBarDesignSystem from 'components/design-system/progress-bar/ProgressBar';
import CalculationTypeIndicator from 'components/shared/CalculationTypeIndicator';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const ProgressBar = ({
  calculationType,
  measurement,
  customTitle,
  isAbleToUpdate,
  updateProgress,
  progressWidth,
  useNewProgressBar
}) => {
  let { progressPercentage, progressColorHex, expectedProgressPercentage } =
    measurement;
  if (progressColorHex) {
    progressColorHex = progressColorHex || 'CED6DC';
  }

  const showUpdateProgressModal = (e) => {
    e.stopPropagation();
    isAbleToUpdate && updateProgress(e);
  };

  const ProgressTitlePercentage = () => {
    return (
      <div className="flex justify-between">
        <p
          className="typography-h400 flex items-center"
          style={{ color: whichBgColorClassName(progressColorHex, false, 500) }}
        >
          <span className="typography-h400 max-w-[54px] inline-block overflow-hidden fw-bold">
            {getNumberLocale(progressPercentage)}
          </span>
          {`% ${customTitle || ''} ${
            useNewProgressBar
              ? `(${whichStatus[progressColorHex] || 'In progress'})`
              : ''
          }`}
        </p>
        <CalculationTypeIndicator
          calculationType={calculationType}
          customClass="ml-[8px] !h-[18px]"
        />
      </div>
    );
  };

  return (
    <div className="progress-bar-card" data-cy="list-detail-progress">
      <section className="flex text-g-600 flex-col">
        <ProgressTitlePercentage />
        <TooltipContainer
          text={getObjectiveLocale('Click to update the progress')}
          show={isAbleToUpdate}
          position="top"
          useMaxWidth={false}
          onClick={showUpdateProgressModal}
          dataCy="update-progress"
        >
          {useNewProgressBar ? (
            <ProgressBarDesignSystem
              containerClass="mt-[8px]"
              actualProgress={progressPercentage}
              expectedProgress={expectedProgressPercentage}
              actualColor={whichBgColorClassName(
                progressColorHex,
                false,
                '500'
              )}
              useActualColorHex={false}
              height={16}
            />
          ) : (
            <div
              className={`progress-percentage ${
                progressWidth ? progressWidth : 'w-[72px]'
              }`}
              data-cy="update-progress"
            >
              <p className="typography-h100 text-n-000 absolute z-index-10 h-[18px] text-n-000 fs-12 left-[5px] top-[0px] h-[18px] leading-[18px] z-[3px] mt-[0px]">
                {whichStatus[progressColorHex] || 'In progress'}
              </p>
              <div
                className="expected-progress-bar"
                style={{ width: expectedProgressPercentage + '%', zIndex: '2' }}
              />
              <div
                className="filler"
                style={{
                  width: progressPercentage + '%',
                  background: whichBgColorClassName(
                    progressColorHex,
                    false,
                    500
                  )
                }}
              />
            </div>
          )}
        </TooltipContainer>
      </section>
    </div>
  );
};

export default ProgressBar;

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import { v4 as uuidV4 } from 'uuid';

import { getDueDateClone, postCloneObjective } from 'client/ObjectivesClient';
import { getManager } from 'client/UserProfile';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { getSemester } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getObjectiveType } from 'utils/ObjectivesHelper';

import ModalConfirmation from 'components/modal/ModalConfirmation';
import BannerBox from 'components/shared/BannerBox';
import Modal from 'components/shared/modal/Modal';

import CloneSubObjective from './modal-clone-objectives/CloneSubObjective';
import InvolvedUsers from './modal-clone-objectives/InvolvedUsers';
import ObjectiveDate from './modal-clone-objectives/ObjectiveDate';
import ObjectiveTitle from './modal-clone-objectives/ObjectiveTitle';

function ModalCloneObjective({ objectiveDetail, eventOnClick }) {
  const { refetchObjectives, invalidateQueries } = useRefetchQuery();
  const { user, config } = useUser();
  const { url } = useUrl();
  const history = useHistory();

  const { addToast, removeToast } = useToastContext();

  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [cloneObj, setCloneObj] = useState(null);

  const {
    goalInvolvementsReviewerAssign: goalAssignReviewer,
    goalInvolvementsOwnerAssign: goalAssignOwner,
    projectInvolvementsOwnerAssign: projectAssignOwner,
    projectInvolvementsReviewerAssign: projectAssignReviewer,
    taskInvolvementsOwnerAssign: taskAssignOwner,
    taskInvolvementsReviewerAssign: taskAssignReviewer,
    goalPeriodStartDateEdit: goalPermissionStartDate,
    taskPeriodStartDateEdit: taskPermissionStartDate,
    projectPeriodStartDateEdit: projectPermissionStartDate
  } = config?.permissions || {};

  const objectiveType = useMemo(
    () => getObjectiveType(objectiveDetail),
    [objectiveDetail]
  );

  let assignOwner, assignReviewer, startDatePermission;

  switch (objectiveType) {
    case 'task':
      assignOwner = taskAssignOwner;
      assignReviewer = taskAssignReviewer;
      startDatePermission = taskPermissionStartDate;
      break;
    case 'project':
      assignOwner = projectAssignOwner;
      assignReviewer = projectAssignReviewer;
      startDatePermission = projectPermissionStartDate;
      break;
    default:
      assignOwner = goalAssignOwner;
      assignReviewer = goalAssignReviewer;
      startDatePermission = goalPermissionStartDate;
      break;
  }

  const getDueDate = async (date) => {
    const { data } = await getDueDateClone(objectiveDetail?.id, {
      startDate: date
    });
    if (data) {
      return data?.dueDate || null;
    }
  };

  const getManagerData = async (id) => {
    const { data, isSuccess } = await getManager(id);
    if (data[0] && isSuccess) {
      return {
        user: data[0]?.user,
        role: 'assigner',
        userId: data[0]?.user?.id,
        visible: true
      };
    } else {
      return null;
    }
  };

  const getInvolvements = async () => {
    let temp;
    if (assignOwner && assignReviewer) {
      temp = objectiveDetail?.involvements;
    } else if (assignOwner && !assignReviewer) {
      temp = objectiveDetail?.involvements?.filter(
        ({ role }) => role !== 'assigner'
      );
      let owner = objectiveDetail?.involvements?.filter(
        ({ role }) => role === 'assignee'
      );
      let manager = await getManagerData(owner[0]?.user?.id);
      manager && temp.push(manager);
    } else if (!assignOwner && assignReviewer) {
      temp = objectiveDetail?.involvements?.filter(
        ({ role }) => role !== 'assigner' && role !== 'assignee'
      );
      let newOwner = {
        user: user,
        role: 'assignee',
        userId: user?.id,
        visible: true
      };
      let manager = await getManagerData(user?.id);
      manager ? temp.push(newOwner, manager) : temp.push(newOwner);
    }
    return temp;
  };

  const openClonedObjective = (clonedObjectiveId, toastId) => {
    const newUrl = `${url}/${clonedObjectiveId}`;
    history.replace({
      pathname: newUrl,
      search: location.search
    });
    removeToast(toastId);
  };

  const showToast = (objectiveId) => {
    const toastId = uuidV4();
    addToast({
      id: toastId,
      title: 'Clone Successful',
      msg: (
        <>
          {getObjectiveLocale(`The ${objectiveType} was successfully cloned`)}
          <br /> {getObjectiveLocale('Click')}{' '}
          <span
            className="bold text-n-900 underline cursor-pointer"
            onClick={() => openClonedObjective(objectiveId, toastId)}
          >
            {getObjectiveLocale('here')}
          </span>{' '}
          {getObjectiveLocale(`to open the ${objectiveType}`)}
        </>
      ),
      type: 'success',
      disabledAutoClose: true
    });
  };

  const postClone = async () => {
    setIsCloning(true);
    let newCloneObj = cloneDeep(cloneObj);
    newCloneObj?.involvements.map((involvement) => {
      delete involvement.visible;
      delete involvement.manager, delete involvement.user;
    });
    const { data, isSuccess } = await postCloneObjective(
      objectiveDetail?.id,
      newCloneObj
    );
    if (isSuccess) {
      showToast(data?.id);
      refetchObjectives();
      eventOnClick();
      setConfirmationModal(false);
      invalidateQueries([
        'objective',
        'activities',
        parseInt(objectiveDetail?.id)
      ]);
    }
    setIsCloning(false);
  };

  const getSetupCloneDefault = async () => {
    let tempInvolve = await getInvolvements();

    const startDate =
      config?.defaultPeriodOnCreate === 'semester'
        ? getSemester('start')
        : dayjs().startOf(config?.defaultPeriodOnCreate);

    const dueDate = await getDueDate(startDate.toISOString());

    let tempClone = {
      name: objectiveDetail?.name,
      startDate: startDate.toISOString(),
      dueDate: dueDate,
      cloneParams: {
        subObjectives: false
      },
      involvements: tempInvolve?.map((involvement) => {
        return {
          userId: involvement.user.id,
          role: involvement.role,
          user: involvement.user,
          visible: involvement.visible,
          extendedRole: involvement?.extendedRole || null
        };
      })
    };
    setCloneObj(tempClone);
  };

  useEffect(() => {
    getSetupCloneDefault();
  }, []);

  return (
    <>
      <Modal
        dataCyModal={'modal-clone'}
        title={`Clone ${startCase(objectiveType)}`}
        className={'w-[400px] modal-clone-objective'}
        maxHeight={objectiveType === 'project' ? '700' : '600'}
        description={`All ${objectiveType} detail and configuration from selected ${objectiveType}s will be applied.`}
        withPrimaryBtn={{
          title: 'Clone',
          dataCy: 'clone-obj',
          onClick: () => setConfirmationModal(true),
          disabled: cloneObj?.name == ''
        }}
        withSecondaryBtn={{
          title: 'Cancel',
          dataCy: 'cancel-clone-obj',
          onClick: eventOnClick
        }}
        eventOnClickClose={eventOnClick}
      >
        <InvolvedUsers
          objectiveDetail={objectiveDetail}
          cloneObj={cloneObj}
          setCloneObj={setCloneObj}
          assignOwner={assignOwner}
          assignReviewer={assignReviewer}
          objectiveType={objectiveType}
        />
        <ObjectiveTitle
          objectiveDetail={objectiveDetail}
          cloneObj={cloneObj}
          setCloneObj={setCloneObj}
        />
        <ObjectiveDate
          cloneObj={cloneObj}
          setCloneObj={setCloneObj}
          goalPermissionStartDate={goalPermissionStartDate}
          startDatePermission={startDatePermission}
          getDueDate={getDueDate}
        />
        <BannerBox type="info" customClass="my-[16px]">
          {getObjectiveLocale(
            `${startCase(
              objectiveType
            )}s due date will be adjusted automatically based on original ${objectiveType} period`
          )}
        </BannerBox>
        {objectiveDetail?.type !== 'task' && (
          <CloneSubObjective
            objectiveDetail={objectiveDetail}
            cloneObj={cloneObj}
            setCloneObj={setCloneObj}
            objectiveType={objectiveType}
          />
        )}
      </Modal>
      {openConfirmationModal && (
        <ModalConfirmation
          handleChange={setConfirmationModal}
          postClone={postClone}
          isCloning={isCloning}
          eventOnClickClose={() => setConfirmationModal(false)}
          objectiveType={objectiveType}
        />
      )}
    </>
  );
}

export default ModalCloneObjective;

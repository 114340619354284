import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { UseAuthPage } from 'context/AuthPageContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Checkbox from 'components/shared/Checkbox';

const AuthUser = (props) => {
  const { handleOnChange, managerEmail, email, trueEmail } = UseAuthPage();
  const { organization, appType } = useUser();

  const appName =
    appType === 'culture'
      ? organization?.cultureAppName
      : organization?.appName;
  const isGeneralEmail = props?.match?.path === '/login/email';
  const isManagerEmail = props?.match?.path === '/login/manager-registration';
  const valueManagerEmail = managerEmail === 'none' ? '' : managerEmail;
  const titleEmail = isGeneralEmail
    ? appName || getObjectiveLocale('Email')
    : isManagerEmail
    ? getObjectiveLocale('Your Manager')
    : getObjectiveLocale('Email Registration');
  const descEmail = isGeneralEmail
    ? getObjectiveLocale('Email Address')
    : isManagerEmail
    ? getObjectiveLocale("Manager's Email")
    : getObjectiveLocale('Email Registration Address');

  const onChangeCheckbox = () => {
    handleOnChange('managerEmail', managerEmail === 'none' ? '' : 'none');
    props?.setShowWarning(false);
  };

  useEffect(() => {
    let input = document.getElementById('email');
    input.focus();

    if (localStorage.deviceId == null) {
      const id = uuidv4();
      localStorage.setItem('deviceId', id);
    }
  }, []);

  return (
    <>
      <span className="auth-title text-center w-[304px] typography-h400-longform">
        {titleEmail}
      </span>
      <span className="auth-text" data-cy="input-login-data">
        {getObjectiveLocale('Please enter your registered')}{' '}
        {descEmail.toLowerCase()}.
      </span>
      <div className="container-input">
        <label className="styled-label" htmlFor={'email'}>
          {descEmail}
        </label>
        <input
          className={`styled-input ${
            props.errorShake ? 'input-error-shake' : ''
          }`}
          name={
            isGeneralEmail
              ? 'email'
              : isManagerEmail
              ? 'managerEmail'
              : 'trueEmail'
          }
          id={'email'}
          data-cy={'auth-email'}
          type={'email'}
          value={
            isGeneralEmail
              ? email
              : isManagerEmail
              ? valueManagerEmail
              : trueEmail
          }
          disabled={isManagerEmail && managerEmail === 'none'}
          onChange={(e) => handleOnChange(e.target.name, e.target.value)}
          onKeyUp={props.handleKeyPress}
          placeholder={`${getObjectiveLocale(
            'Enter your'
          )} ${descEmail.toLowerCase()}`}
        />
      </div>
      {props.showWarning && (
        <div className="container-error mb-[16px]">
          <span data-cy="wrong-login-data" className="error-message">
            {props.warningMessage}
          </span>
        </div>
      )}
      {isManagerEmail && (
        <Checkbox
          onChange={() => onChangeCheckbox()}
          value={managerEmail}
          id="manager-email"
          checked={managerEmail === 'none'}
          dataCy="input-manager-email"
          labelClass="cursor-pointer w-full"
          customContainerClass=" w-full"
        >
          <p className="ml-[16px]">
            {getObjectiveLocale('I don’t have any manager')}
          </p>
        </Checkbox>
      )}
    </>
  );
};

AuthUser.contextTypes = {
  handleChange: PropTypes.func
};

AuthUser.propTypes = {
  handleKeyPress: PropTypes.func,
  routeToRecovery: PropTypes.func,
  showWarning: PropTypes.bool,
  warningMessage: PropTypes.string
};

export default withRouter(AuthUser);

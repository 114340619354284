import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { getInclusions } from 'client/admin/FormalReviewRevieweeClient';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { useDeepEffect } from 'utils/useDeepEffect';

import HeaderPage from 'components/admin/HeaderPage';
import YearFilter from 'components/advance-filter/YearFilter';
import ListEmptyState from 'components/shared/ListEmptyState';
import Loading from 'components/shared/LoadingComponent';

import AssignmentList from './components/AssignmentList';

const thisYear = new Date().getFullYear();

const RevieweeAssignmentList = () => {
  const location = useLocation();
  const { id: userId } = useParams();
  const fullName = location?.state?.fullName || null;
  const [listData, setListData] = useState([]);
  const [period, setPeriod] = useState(thisYear);
  const [isLoading, setLoading] = useState(false);

  const dropdownYear = (
    <YearFilter selectedValue={period} onSelect={setPeriod} />
  );

  const headerProps = {
    titlePage: `Assignment for ${fullName}`,
    isHeaderComposer: true,
    backToUrl: '/appraisals/cycles?tab=reviewee',
    customAction: dropdownYear
  };

  const getData = async () => {
    setLoading(true);
    const params = {
      cyclePeriod: period
    };
    const { isSuccess, data } = await getInclusions(userId, params);
    if (isSuccess) {
      setListData(data);
    }
    setLoading(false);
  };

  useDeepEffect(() => {
    getData();
  }, [period]);

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="px-[70px] mt-[24px] mb-[64px]">
        {isLoading ? (
          <Loading />
        ) : listData?.length > 0 ? (
          listData?.map((data) => (
            <AssignmentList
              key={data.id}
              inclusions={data}
              refetchInclusions={getData}
              periodCycle={period}
            />
          ))
        ) : (
          <ListEmptyState
            fullHeight
            emptyIcon="icon-no-result-found"
            title={getObjectiveLocale('No Assignment Found')}
            subtitle={getObjectiveLocale(
              'This user has no formal review assignment in the selected year'
            )}
          />
        )}
      </div>
    </>
  );
};

export default RevieweeAssignmentList;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import { setActiveNavigator } from 'components/admin/organization/OrganizationHelper';
import AdminTabWithCount from 'components/shared/Tabs/AdminTabWithCount.js';

import GroupTabReviewGroup from '../GroupTabReviewGroup';
import DefaultTabPerformanceProfile from './DefaultTabPerformanceProfile';

const PerformanceProfile = ({ route: { isReadOnly } }) => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState('default');
  const [isModalCreateAttributeOpen, setIsModalCreateAttributeOpen] =
    useState(false);
  const [modalAction, setModalAction] = useState('create');
  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');
  const { config } = useUser();
  const [activeSection, setActiveSection] = useState('');
  const [listNavigatorContents, setListNavigatorContents] = useState([]);
  const weightSetting = getObjectiveLocale('Weight Setting');
  const finalScoreFormula = getObjectiveLocale('Final Score Formula');

  const listObjTabs = [
    { id: 'default', name: 'Default' },
    { id: 'groups', name: 'Groups' }
  ];

  const listContents = [
    { id: 'final-score-formula', name: finalScoreFormula },
    { id: 'weight-setting', name: weightSetting }
  ];

  const routeToCreatePage = () => {
    history.push(`/group/performance-profile/create`);
  };

  const routeToEditPage = (id) => {
    history.push(`/group/performance-profile/edit/${id}`);
  };

  const changeTab = (tab) => {
    history.push(`/group/performance-profile?tab=${tab}`);
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (tabParams == 'groups') {
      setCurrentTab('groups');
    } else {
      setCurrentTab('default');
    }
  }, [tabParams]);

  const openModal = () => {
    setIsModalCreateAttributeOpen(true);
    setModalAction('create');
  };

  const headerProps = {
    titlePage: 'Performance Profile',
    primaryAction:
      currentTab === 'groups'
        ? {
            disabled: isReadOnly,
            title: 'Create Group',
            icon: 'icon-add',
            onClick: routeToCreatePage,
            dataCy: 'create-new-goal'
          }
        : config?.scoredAttributesFeature && {
            disabled: isReadOnly,
            title: 'Create Custom Attribute',
            icon: 'icon-add',
            onClick: openModal,
            dataCy: 'create-custom-attribute'
          }
  };

  return (
    <>
      <HeaderPage
        {...headerProps}
        tabs={{
          tabsList: listObjTabs,
          url: '/group/performance-profile',
          onChange: (tab) => {
            setCurrentTab(tab);
          }
        }}
      />
      <div
        className="container-landing-behavior-model bg-n-000 h-[calc(100vh-64px)] overflow-auto"
        onScroll={(e) =>
          setActiveNavigator(e, listContents, setActiveSection, true, 80)
        }
        id="sectioncontainer"
      >
        {currentTab === 'default' && (
          <DefaultTabPerformanceProfile
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            listNavigatorContents={listNavigatorContents}
            setListNavigatorContents={setListNavigatorContents}
            isReadOnly={isReadOnly}
            isModalCreateAttributeOpen={isModalCreateAttributeOpen}
            setIsModalCreateAttributeOpen={setIsModalCreateAttributeOpen}
            modalAction={modalAction}
            setModalAction={setModalAction}
            listContents={listContents}
          />
        )}
        {currentTab === 'groups' && (
          <GroupTabReviewGroup
            description={
              'You can define performance profile for spesific employees by creating group here.'
            }
            groupType={'review_group'}
            routeToCreatePage={routeToCreatePage}
            routeToEditPage={routeToEditPage}
            isReadOnly={isReadOnly}
          />
        )}
      </div>
    </>
  );
};

export default PerformanceProfile;

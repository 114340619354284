import React, { useEffect, useState } from 'react';

import { useUser } from 'context/UserContext';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { getCssVariableValue } from 'utils/HelperUtils';
import { whichBgColorClassName } from 'utils/ObjectivesHelper';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import FormattedNumber from 'components/design-system/FormattedNumber';
import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import GeneralCard from 'components/shared/card/GeneralCard';
import SetWeightComponent from 'components/shared/card/OverallProgressCard/SetWeightComponent';
import Modal from 'components/shared/modal/Modal';
import ProgressBarOverallProgress from 'src/components/shared/progressBar/ProgressBarOverallProgress.js';

import './overallViewProgressCoaching.scss';

const OverallProgressCoaching = ({
  dataCountObjectives,
  dataMinMax,
  dataMinMaxWeight,
  countRatingData,
  useProgressCard = true,
  useRatingCard = true
}) => {
  const { config } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [colorHex, setColorHex] = useState('');
  const [overallProgressPercentage, setOverallProgressPercentage] = useState(0);
  const [totalRating, setTotalRating] = useState(0);
  const [objProgressBar, setObjProgressBar] = useState({
    widthProgress: 0,
    colorProgress: ''
  });

  const whichStatus = {
    '44DB5E': 'On track',
    '5A9AEF': 'Exceeded',
    FE2851: 'At risk',
    FFCD00: 'Left behind'
  };

  const whichColorClassName = {
    '44DB5E': 'text-g-600',
    '5A9AEF': 'text-b-600',
    FE2851: 'text-r-600',
    FFCD00: 'text-y-600'
  };

  const _getDataMinMaxvalidation = () => {
    const { overallProgress, progressColorHex } = dataCountObjectives;
    setObjProgressBar({
      widthProgress: parseInt(overallProgress),
      colorProgress: whichBgColorClassName(progressColorHex, true)
    });
    setColorHex(progressColorHex);
    setOverallProgressPercentage(overallProgress);

    if (countRatingData?.length > 0) {
      const totalRating = countRatingData?.reduce((curr, rating) => {
        return curr + (rating?.count || 0);
      }, 0);
      setTotalRating(totalRating);
    }
  };

  useEffect(() => {
    _getDataMinMaxvalidation();
  }, [dataCountObjectives, dataMinMax, countRatingData]);

  return (
    <div className="flex flex-col w-full">
      {useProgressCard && (
        <GeneralCard customClass="h-[106px] flex justify-between">
          <div className="progressbar-container w-[160px]">
            <p className="typography-h200 text-n-800">
              {getObjectiveLocale('Overall Progress')}
            </p>
            <div className="flex mt-[16px]">
              <TooltipContainer
                classContainer="cursor-default flex flex-row items-center "
                show={overallProgressPercentage}
                text={overallProgressPercentage?.toLocaleString(
                  navigator.language,
                  {
                    maximumFractionDigits: 20
                  }
                )}
              >
                <FormattedNumber
                  number={overallProgressPercentage}
                  minDecimalLength={0}
                  customClass={`cursor-default typography-h600 flex-center-center text-${whichColorClassName[colorHex]}`}
                  withTooltip={false}
                  dataCy="overall-progress-percentage"
                />
                <p
                  className={`cursor-default typography-h600 text-${whichColorClassName[colorHex]}`}
                >
                  %
                </p>
              </TooltipContainer>
              <span
                data-cy="overall-progress-status"
                className={`no-wrap typography-h400 flex items-center ${whichColorClassName[colorHex]}`}
              >
                &nbsp;{whichStatus[colorHex]}
              </span>
            </div>
            <ProgressBarOverallProgress {...objProgressBar} height={12} />
          </div>
          <div className="progressbar-container">
            <p className="typography-h200 text-n-800">
              {getObjectiveLocale('Top Parent')}
            </p>
            <p className="typography-h600 mt-[16px]" data-cy="top-parent">
              {getNumberLocale(dataMinMax?.totalObjectives)}
            </p>
          </div>
          <SetWeightComponent totalWeight={dataMinMaxWeight.totalWeights} />
        </GeneralCard>
      )}
      {totalRating > 0 && useRatingCard && config?.permissions?.goalReviewSee && (
        <GeneralCard
          customClass={`h-[48px] flex items-center justify-between mt-[16px] ${
            useProgressCard ? '' : 'mb-[40px]'
          }`}
        >
          <div className="flex items-center">
            <SVGIcon
              size="16"
              iconName="icon-star"
              fillColor="var(--y-600)"
              customClass="h-[24px] w-[24px] rounded-[50px] bg-y-100 flex items-center justify-center"
            />
            <p className="ml-[16px] typography-paragraph text-n-800">
              {getObjectiveLocale('Total')}{' '}
              <span className="typography-h400 text-n-900">
                {getNumberLocale(totalRating)}
              </span>{' '}
              {getObjectiveLocale('rating received')}
            </p>
          </div>
          <Button.Tertiary onClick={() => setShowModal(true)}>
            {getObjectiveLocale('View Rating')}
          </Button.Tertiary>
        </GeneralCard>
      )}
      {showModal && (
        <Modal
          title={getObjectiveLocale('Total rating received')}
          eventOnClickClose={() => setShowModal(false)}
          className="w-[400px]"
          withFooter={false}
        >
          {countRatingData?.map((scoring, index) => (
            <div className="flex justify-between items-center" key={index}>
              <div className="flex items-center">
                <div className="h-[48px] flex items-center">
                  {scoring?.iconUrl ? (
                    <AWSImage
                      src={scoring?.iconUrl}
                      className="w-[24px] h-[24px]"
                    />
                  ) : (
                    <Badge
                      className="ml-[4px] typography-h400"
                      content={scoring?.scoringMark?.score}
                      bgColorHex={getCssVariableValue('--n-200')}
                      colorHex={scoring?.scoringMark?.colorHex}
                    />
                  )}
                </div>
                <div
                  className="ml-[16px] typography-h400"
                  style={{ color: '#' + scoring?.scoringMark?.colorHex }}
                >
                  {scoring?.scoringMark?.name}
                </div>
              </div>
              <div className="typography-h200 text-n-600">
                <span className="typography-h400 text-n-800">
                  {getNumberLocale(scoring?.count)}
                </span>{' '}
                {getObjectiveLocale('received')}
              </div>
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default OverallProgressCoaching;

import React, { useState } from 'react';

import { updateStateObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import useTasks from 'hooks/useTasks';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalUpdateProgress from 'components/modal/ModalUpdateProgress/ModalUpdateProgress';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import Modal from 'components/shared/modal/Modal';
import { trackEvent } from 'src/utils/AnalyticUtils';

import InputObjectiveName from '../InputObjectiveName';

const TaskName = ({
  task,
  setTask,
  filter,
  level,
  isLastChild,
  showChild,
  showChildren,
  isLoadChildren,
  groupData,
  sectionData,
  taskNode,
  isProject,
  project,
  cancelAddTask,
  defaultParent,
  listMetrics = []
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalIncompleteOpen, setIsModalIncompleteOpen] = useState(false);

  const { refetchObjective, refetchQueries } = useRefetchQuery();
  const { reload, reloadOverallProgress, reloadOverallStatus, reloadSidebar } =
    useReload();
  const { getCompletePhase, getFirstPhase } = useTasks();

  const {
    id,
    name,
    childrenCount,
    stateObject,
    measurement,
    permissions = [],
    commentOptions,
    currentMilestone,
    milestoneType,
    parent,
    dependencyState,
    blocking
  } = task || {};

  const parentId = parent ? parent.id : undefined;

  const { state } = stateObject || {};

  const completeGoal = async (newState) => {
    const completePhase = getCompletePhase();
    const firstPhase = getFirstPhase();

    const res = await updateStateObjective(id, newState);
    const { data, isSuccess } = res;
    const { type } = newState;

    if (data && type === 'redo')
      trackEvent({
        event: 'redo',
        eventProperties: { status: 'success', id: id, type: data.type }
      });
    if (data && type === 'completed')
      trackEvent({
        event: 'complete',
        eventProperties: { status: 'success', id: id, type: data.type }
      });

    if (isSuccess) {
      refetchObjective(id);
      blocking?.length > 0 &&
        blocking?.map(({ id: taskBlockingId }) => {
          refetchObjective(taskBlockingId);
        });

      reload({
        reloadObjectiveId: { id: id },
        reloadType: 'complete',
        reloadSidebar: !reloadSidebar,
        reloadOverallProgress: !reloadOverallProgress,
        reloadOverallStatus: !reloadOverallStatus
      });

      if (filter?.group === 'phase') {
        if (isProject) {
          refetchQueries([
            'objectives',
            'mytasks',
            sectionData?.name,
            completePhase?.name
          ]);
          refetchQueries([
            'objectives',
            'mytasks',
            sectionData?.name,
            type === 'completed' ? groupData?.name : firstPhase?.name
          ]);
        } else {
          refetchQueries(['objectives', 'mytasks', completePhase?.name]);
          refetchQueries([
            'objectives',
            'mytasks',
            type === 'completed' ? groupData?.name : firstPhase?.name
          ]);
        }
      }

      return res;
    }
  };

  const handleIncompleteTask = async () => {
    await completeGoal({ state: 'running', type: 'redo' });
    setIsModalIncompleteOpen(false);
  };

  return (
    <>
      {level > 0 && !isLastChild && <div className="vertical-line" />}
      {level > 0 && <div className="curved-line" />}
      {level === 0 && childrenCount > 0 ? (
        <div
          className={`arrow-icon mr-[4px] ${showChild ? 'show-children' : ''}`}
          onClick={!isLoadChildren && showChildren}
        >
          <SVGIcon
            size="24"
            fillColor="var(--n-600)"
            iconName={`${
              showChild ? 'icon-arrow_drop_down' : 'icon-arrow_right'
            }`}
          />
        </div>
      ) : (
        <div className="w-[28px] mr-[4px]"></div>
      )}

      {state === 'completed' ? (
        <SVGIcon
          size="24"
          fillColor="var(--g-600)"
          iconName="icon-check_circle"
          onClick={() => setIsModalIncompleteOpen(true)}
        />
      ) : dependencyState === 'blocked' ? (
        <TooltipContainer
          show
          text="This task is blocked by other task"
          useMaxWidth={false}
        >
          <SVGIcon
            size="24"
            fillColor="var(--n-600)"
            iconName="icon-hourglass_empty"
          />
        </TooltipContainer>
      ) : (
        <TooltipContainer show text="Mark as Complete" useMaxWidth={false}>
          <SVGIcon
            size="24"
            fillColor="var(--n-600)"
            iconName="icon-check_circle_outline"
            eventStopPropagation={false}
            onClick={(e) => {
              !e.shiftKey && !(e.ctrlKey || e.metaKey) && e.stopPropagation(),
                setIsModalOpen(!isModalOpen);
            }}
            dataCy="trigger-complete-modal-objective"
          />
        </TooltipContainer>
      )}

      <InputObjectiveName
        listMetrics={listMetrics}
        task={task}
        setTask={setTask}
        filter={filter}
        groupId={groupData?.id}
        groupName={groupData?.name}
        sectionId={sectionData?.id}
        sectionName={sectionData?.name}
        taskNode={taskNode}
        isProject={isProject}
        project={project}
        cancelAddTask={cancelAddTask}
        defaultParent={defaultParent}
      />

      {isModalIncompleteOpen && (
        <Modal
          title={`Incomplete ${getObjectiveLocale('task', true)}`}
          withCloseIcon={false}
          withPrimaryBtn={{
            title: 'Incomplete',
            dataCy: 'button-primary-modal',
            onClick: () => handleIncompleteTask()
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            dataCy: 'button-secondary-modal',
            onClick: () => setIsModalIncompleteOpen(false)
          }}
          description={`Are you sure you want to incomplete this ${getObjectiveLocale(
            'task',
            true
          )}?`}
        />
      )}

      {isModalOpen && (
        <ModalUpdateProgress
          measurement={measurement ? measurement : null}
          withCloseIcon
          eventOnClick={() => setIsModalOpen(false)}
          canUpdateCurrentValue={permissions?.includes('update_current_value')}
          objectiveId={id}
          parentId={parentId}
          completeGoal={completeGoal}
          modalType="complete"
          objectiveName={name}
          commentOptions={commentOptions}
          currentMilestone={currentMilestone}
          milestoneType={milestoneType}
          objectiveValue={task}
        />
      )}
    </>
  );
};

export default TaskName;

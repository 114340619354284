import React from 'react';

import dayjs from 'dayjs';

import { getRange } from '../utils/quarterHelper';

const GanttTopHeaderMonthly = ({ daysOfYear, isQuarter = false }) => {
  const fixedWidth = isQuarter ? 6 : 12;
  let ident = false;
  return (
    <>
      {daysOfYear.map(({ date, id }, index) => {
        const endOfMonth = dayjs(date).endOf('month').date();
        if (ident == false) {
          ident = true;
          const width =
            fixedWidth * (endOfMonth - dayjs(daysOfYear[0].date).date() + 1);
          const currentMonth = dayjs(date).format('MMMM');
          const endQuarter = getRange(Math.floor((date.getMonth() + 3) / 3))[1];
          const useBorder = isQuarter ? currentMonth == endQuarter : true;
          return (
            <div
              key={'th-month-' + id}
              className="flex h-[100%] justify-center items-center"
              style={{
                width: width + 'px',
                ...(useBorder && { borderRight: '1px solid var(--n-300)' })
              }}
            >
              {currentMonth}
            </div>
          );
        }
        if (daysOfYear.length - 1 == index) {
          return;
        }
        if (date.getDate() == 1) {
          const currentMonth = dayjs(date).format('MMMM');
          const endQuarter = getRange(Math.floor((date.getMonth() + 3) / 3))[1];
          const useBorder = isQuarter ? currentMonth == endQuarter : true;
          return (
            <div
              key={'th-month-' + id}
              className="flex h-[100%] justify-center items-center"
              style={{
                width: fixedWidth * endOfMonth + 'px',
                ...(useBorder && { borderRight: '1px solid var(--n-300)' })
              }}
            >
              {dayjs(date).format('MMMM')}
            </div>
          );
        }
      })}
    </>
  );
};

export default GanttTopHeaderMonthly;

import React, { useEffect, useState } from 'react';

import { useUser } from 'context/UserContext';
import { useSelectedUser } from 'context/useSelectedUser/useSelectedUser';
import useUserManagement from 'hooks/useUserManagement';

import TwoLine from 'components/design-system/ProfileType';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Table from 'components/design-system/table/Table';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const UserRow = ({ userData, props }) => {
  const {
    handleEdit,
    openDialogDeactivateUser,
    openDialogReactiveUser,
    openDialogResendActivation,
    loginAsUser,
    isReadOnly
  } = props;
  const {
    user: currentUser,
    organization: { loginType }
  } = useUser();
  const [user, setUser] = useState(userData);
  const { editedInfoData } = useUserManagement();

  const getPlatform = (user) => {
    const { role: isPerformance, cultureRole: isCulture } = user;
    const performance = {
      text: 'P',
      color: 'var(--base-800)',
      bgColor: 'var(--base-200)'
    };
    const culture = {
      text: 'C',
      color: 'var(--base-800)',
      bgColor: 'var(--base-200)'
    };

    if (isPerformance && isCulture) {
      return [performance, culture];
    } else if (isPerformance) {
      return [performance];
    } else if (isCulture) {
      return [culture];
    }
  };

  const getRole = (user) => {
    const {
      role: isPerformance,
      cultureRole: isCulture,
      isCompanyGoalCreator
    } = user;
    let role = [];
    if (isPerformance) {
      role.push(isPerformance);
    }
    if (isCulture) {
      role.push(isCulture.replace('culture_', ''));
    }
    if (isCompanyGoalCreator) {
      role.push('company goal creator');
    }
    return role;
  };

  const getActivationState = (user) => {
    const { state } = user;
    const pending = {
      text: 'Not Yet Sign Up',
      color: 'var(--r-800)',
      bgColor: 'var(--r-200)'
    };
    const deleted = {
      text: 'Deleted',
      color: 'var(--r-800)',
      bgColor: 'var(--r-200)'
    };
    const active = {
      text: 'Sign Up',
      color: 'var(--g-800)',
      bgColor: 'var(--g-200)'
    };

    if (state === 'pending') {
      return [pending];
    } else if (state === 'deleted') {
      return [deleted];
    } else {
      return [active];
    }
  };

  const getDropdownOptions = (user) => {
    let options = [];

    options.push(
      {
        text: 'Edit',
        onClick: () => {
          handleEdit(user);
        },
        isVisible:
          (user.state == 'active' || user.state == 'pending') && !isReadOnly
      },
      {
        text: 'Deactivate',
        onClick: () => {
          openDialogDeactivateUser(user);
        },
        isVisible:
          (user.state == 'active' || user.state == 'pending') && !isReadOnly
      }
    );
    options.push({
      text: 'Resend Activation',
      onClick: () => {
        openDialogResendActivation(user);
      },
      isVisible:
        loginType == 'default' && user.state == 'pending' && !isReadOnly
    });
    options.push({
      text: 'Reactivate',
      onClick: () => {
        openDialogReactiveUser(user);
      },
      isVisible: user.state == 'deleted' && !isReadOnly
    });
    options.push({
      text: 'Login',
      onClick: () => loginAsUser(user.email),
      isVisible: currentUser.email == 'administrator@happy5.co'
    });
    return options.filter(({ isVisible }) => isVisible);
  };

  const platforms = getPlatform(user);
  const states = getActivationState(user);
  const roles = getRole(user);
  let dropdownOption = getDropdownOptions(user);

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  useEffect(() => {
    editedInfoData?.id === user?.id && setUser(editedInfoData);
  }, [editedInfoData]);

  // Bulk Selected
  const selectUser = useSelectedUser((state) => state.selectUser);
  const isSelected = useSelectedUser((state) => state.isSelected);

  return (
    <Table.Row
      key={user.id}
      onClick={() => handleEdit(user)}
      dataCy={`user-${user?.id}`}
      customClass={isSelected(user.id) ? 'bg-base-30024' : ''}
    >
      <Table.Column key={0}>
        <Checkbox
          id={`select-user-${user?.id}`}
          checked={isSelected(user.id)}
          onChange={(e) => {
            e.stopPropagation();
            selectUser(user.id);
          }}
        />
      </Table.Column>
      <Table.Column key={1}>
        <TwoLine user={user} title={user.name} subtitle={user.email} />
      </Table.Column>
      <Table.Column key={2}>
        <TooltipContainer
          show={user?.jobTitle?.length > 40}
          position="bottom"
          text={user?.jobTitle}
          key={user.id}
          useMaxWidth={false}
        >
          <p className="multiline-text-2">{user.jobTitle || '-'}</p>
        </TooltipContainer>
      </Table.Column>
      <Table.Column key={3}>
        <TooltipContainer
          show={user?.permissionName?.length > 40}
          position="top"
          text={user?.permissionName}
          key={user.id}
          useMaxWidth={false}
        >
          <p className="multiline-text-2">{user?.permissionName || '-'}</p>
        </TooltipContainer>
      </Table.Column>
      <Table.Column key={4}>{user?.info?.jobRole || '-'}</Table.Column>
      <Table.Column key={5}>{user?.info?.jobFunction || '-'}</Table.Column>
      <Table.Column key={6}>{user?.info?.directorate || '-'}</Table.Column>
      <Table.Column key={7}>{user?.info?.department || '-'}</Table.Column>
      <Table.Column key={8}>{user?.info?.division || '-'}</Table.Column>
      <Table.Column key={9}>{user?.info?.region || '-'}</Table.Column>
      <Table.Column key={10}>{user?.info?.city || '-'}</Table.Column>
      <Table.Column key={11}>{user?.info?.country || '-'}</Table.Column>
      <Table.Column key={12}>{roles.join(',')}</Table.Column>
      <Table.Column key={13}>
        {platforms &&
          platforms.map((platform, index) => {
            return (
              <p
                key={`${platform.text}-${index}`}
                className="typography-h100 px-8 py-4 border-radius-4 mr-8 mb-8"
                style={{ color: platform.color, background: platform.bgColor }}
              >
                {platform.text}
              </p>
            );
          })}
      </Table.Column>
      <Table.Column key={14}>
        {states &&
          states.map((state, index) => {
            return (
              <p
                key={`${state.text}-${index}`}
                className="typography-h100 px-8 py-4 border-radius-4 mr-8 mb-8"
                style={{ color: state.color, background: state.bgColor }}
              >
                {state.text}
              </p>
            );
          })}
      </Table.Column>
      <Table.Column key={15}>
        {dropdownOption.length > 0 && (
          <Dropdown>
            <Dropdown.Trigger>
              <SVGIcon
                size="24"
                iconName={'icon-keyboard_control'}
                fillColor="var(--n-500)"
              />
            </Dropdown.Trigger>
            <Dropdown.MenuItems
              options={dropdownOption}
              position="right"
              type="fixed"
            />
          </Dropdown>
        )}
      </Table.Column>
    </Table.Row>
  );
};

export default UserRow;

import React, { useState } from 'react';

import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getClusters } from 'utils/ObjectivesHelper';

import Card from 'components/shared/card/Card';

import Rating from './Rating';

function ObjectiveRating({
  customIcon,
  selectedOptionId,
  questionsTemplate,
  setSelectedOptionId,
  setAskForStrength,
  setAskForWeakness,
  setRequireForStrength,
  setRequireForWeakness,
  reviewComment,
  onChangeComment,
  clearData,
  objective,
  useWorkAttribute,
  listWorkAttribute,
  currentId,
  showLabel
}) {
  const clusters = getClusters(objective);
  const reviewCommentPlaceholder = getObjectiveLocale(
    'Sharing your thoughts on performance will help people learn and grow'
  );
  const scoringComponents =
    questionsTemplate && questionsTemplate.scoringComponents[0];

  const onSelectRating = ({
    id,
    askForStrength,
    askForWeakness,
    requireForStrength,
    requireForWeakness
  }) => {
    setSelectedOptionId(id);
    setAskForStrength(askForStrength);
    setAskForWeakness(askForWeakness);
    setRequireForStrength(requireForStrength);
    setRequireForWeakness(requireForWeakness);
    clearData();
  };

  return (
    <div className="form-group review-content-container">
      {scoringComponents && (
        <div className="question-container">
          <span className="label question-title">
            {useWorkAttribute
              ? listWorkAttribute[currentId]?.name
              : scoringComponents.question}
          </span>
          <Rating
            selectedOptionId={selectedOptionId}
            onSelectRating={onSelectRating}
            options={scoringComponents.scoringMarks}
            customIcon={customIcon}
            showLabel={showLabel}
          />
          <div className="review-comment-container">
            <textarea
              className="composer objective-rating"
              maxLength="255"
              placeholder={getObjectiveLocale(reviewCommentPlaceholder)}
              value={reviewComment}
              onChange={(e) => onChangeComment(e.target.value)}
              data-cy="comment-rating"
            />
            <div className="form-group-divider" />
          </div>
          <div className="objective-summary pb-[0px]">
            <span className="title">
              {getObjectiveLocale('Objective Summary')}
            </span>
            <Card
              objective={objective}
              withRightIcon
              type="goal"
              customClass="mb-[25px] bg-n-200"
              clusters={clusters}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ObjectiveRating;

import React, { useEffect, useRef, useState } from 'react';

import { getObjectiveLocale, parseCaption } from 'utils/HelperUtils';

function Textarea({
  id,
  type,
  comment,
  need_comment,
  isSummary = false,
  setAnswer,
  smallCommentText,
  isRecognitionFeedback = false,
  recognitionFeedbackType,
  withTitle = true,
  competencyAssessment = false,
  placeholder = '',
  title = null,
  isError = false
}) {
  const [hideScrollBar, setHideScrollBar] = useState(0);
  const isRequired = need_comment === 'required' || isSummary;
  const textAreaRef = useRef();

  const getDefaultLabel = () => {
    return getObjectiveLocale(
      `Comment (${isRequired ? 'required' : 'optional'})`
    );
  };

  const onChangeAnswer = (e) => {
    const value = e.target.value;

    if (isSummary) {
      setAnswer();
    } else if (isRecognitionFeedback) {
      setAnswer(id, recognitionFeedbackType, value);
    } else {
      setAnswer(id, null, value, 'comment', type);
    }
  };

  // useEffect for CompetencyAssessment only = resizeable
  useEffect(() => {
    if (competencyAssessment) {
      let input = document.getElementById(`textarea-${id}`);
      input.style = null;
      const scrollHeight = Math.min(input.scrollHeight, 96);
      input.style.height = scrollHeight + 'px';

      if (input.offsetHeight >= 96) {
        input.style.overflow = 'auto';
      } else if (input.offsetHeight < 96) {
        input.style.overflow = 'hidden';
      }
    }
  }, [comment]);

  return (
    <div
      className={`textarea-formal-review ${
        competencyAssessment ? 'width-192' : ''
      }`}
    >
      {withTitle && (
        <span
          className={smallCommentText ? 'comment-text-small' : ''}
          dangerouslySetInnerHTML={{
            __html: parseCaption(title ? title : getDefaultLabel())
          }}
        />
      )}
      <textarea
        ref={textAreaRef}
        onFocus={() => setHideScrollBar(id)}
        onBlur={() => setHideScrollBar(0)}
        id={`textarea-${id}`}
        className={`
          ${competencyAssessment ? 'assessment-text-area' : ''}
          ${hideScrollBar != id ? 'custom-scrollbar' : ''}
          ${isError ? 'border-red' : ''}
        `}
        rows="4"
        onChange={onChangeAnswer}
        required={isRequired}
        spellCheck="false"
        placeholder={getObjectiveLocale(placeholder)}
        value={comment || ''}
      />
    </div>
  );
}

export default Textarea;

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';

import debounce from 'lodash/debounce';
import omit from 'lodash/omit';

import {
  UsersAdminProvider,
  useUsersAdmin
} from 'context/admin/UsersAdminContext';
import { useSelectedUser } from 'context/useSelectedUser/useSelectedUser';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { isFilterEmpty } from 'utils/TableSelectUtils';

import ModalShowMember from 'components/admin/groups/ModalShowMember';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import Table from 'components/design-system/table/Table';
import FloatingAction from 'components/shared/FloatingAction/FloatingAction';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingOverlay from 'components/shared/LoadingOverlay/LoadingOverlay';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import { useDeepEffect } from 'src/utils/useDeepEffect';

import {
  deactivationUser,
  reactivationUser,
  resendActivation
} from './ListUserHelper';
import UserRow from './UserRow';

const ListUserTable = (props) => {
  const {
    currentState,
    reloadState,
    isLoading,
    setIsLoading,
    isReadOnly,
    handleUncheckUsers,
    showSuccessMessage,
    defaultFilterToReset,
    dispatchFilter
  } = props;

  let urlParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  const isDeactivate = useMemo(() => {
    return currentState === 'Active' || currentState === 'Not Yet Sign Up';
  }, [currentState]);

  const [totalAllUser, setTotalAllUser] = useState(0);
  const [paginationData, setPaginationData] = useState({
    olderThan: 0,
    newerThan: 0
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState('asc');

  const [showModal, setShowModal] = useState(false);

  // Bulk Selected
  const isAllSelected = useSelectedUser((state) => state.isAllSelected);
  const selectAllCheckbox = useSelectedUser((state) => state.selectAllCheckbox);
  const resetSelectedUser = useSelectedUser((state) => state.resetSelectedUser);
  const selectAll = useSelectedUser((state) => state.selectAll);
  const include = useSelectedUser((state) => state.include);
  const exclude = useSelectedUser((state) => state.exclude);
  const [isShowOnlySelected, setShowOnlySelected] = useState(false);

  // Filter
  const filterById = useFilter((state) => state.filterById);
  const filter = filterById?.['userFilter'] || {};
  const { q, ...allFilter } = filter;
  const isPageStay = urlParams.get('source');

  const {
    limit,
    hasMore,
    users,
    getUsersData,
    getAllIds,
    nextPage,
    prevPage,
    olderThan,
    newerThan,
    total,
    allIds
  } = useUsersAdmin();

  const getState = () => {
    let state = ['active'];
    if (currentState === 'Not Yet Sign Up') {
      state = ['pending'];
    } else if (props.listType === 'inactive' || currentState === 'Deleted') {
      state = ['deleted'];
    }

    return state;
  };

  const getUserParams = (type, forcePaginationData) => {
    delete allFilter.currentPage;
    const state = getState();

    const additionalParams = {
      selectAll: selectAll,
      includes: include.map((e) => e.id) || [],
      excludes: exclude.map((e) => e.id) || []
    };

    let query = {
      q: q,
      state: state,
      limit: limit,
      info: omit(allFilter, ['job-title']),
      jobTitle: allFilter?.['job-title'] || [],
      sortDirection: sortDirection,
      sortColumn: 'name',
      ...additionalParams
    };

    if (!isShowOnlySelected) {
      delete query.selectAll;
      delete query.includes;
      delete query.excludes;
    }

    if ((isPageStay && currentPage != 1) || forcePaginationData) {
      if (type === 'prev' && paginationData?.newerThan) {
        query.newerThan = paginationData?.newerThan;
      } else if (paginationData?.olderThan) {
        query.olderThan = paginationData?.olderThan;
      }
    }

    const filterRole = allFilter.role || [];
    if (filterRole?.includes('admin') || (q || '')?.includes('admin')) {
      query.role = 'admin';
      query.cultureRole = 'culture_admin';
    } else if (filterRole?.includes('user')) {
      query.role = 'user';
      query.cultureRole = 'culture_user';
    }
    query.info.role = [];

    return query;
  };

  const _getUsersData = async (forcePaginationData = false) => {
    setCurrentPage(isPageStay || forcePaginationData ? currentPage || 1 : 1);
    setIsLoading(true);
    const params = getUserParams(null, forcePaginationData);
    await getUsersData(params);
    setIsLoading(false);
    isPageStay && history?.replace('/users');
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    setPaginationData({ olderThan: olderThan, newerThan: newerThan });
    setCurrentPage(currentPage + 1);
    await nextPage(getUserParams('next'));
    setIsLoading(false);
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    setPaginationData({ olderThan: olderThan, newerThan: newerThan });
    setCurrentPage(currentPage - 1);
    await prevPage(getUserParams('prev'));
    setIsLoading(false);
  };

  const _selectAllCheckbox = () => {
    if (isShowOnlySelected) return resetSelection();

    return (
      selectAllCheckbox({
        filter: filter,
        allIds: allIds,
        totalUsers: totalAllUser
      }),
      setShowOnlySelected(false)
    );
  };

  const successAction = (isSuccess, message, error) => {
    showSuccessMessage(isSuccess, message, error);
    resetSelection();
    resetFilter();
  };

  const _resendActivation = async () => {
    const body = {
      selectAll: selectAll,
      includes: include,
      excludes: exclude
    };

    const { isSuccess, message, error } = await resendActivation(body);
    successAction(isSuccess, message, error);
  };

  const deleteAction = async () => {
    const body = {
      selectAll: selectAll,
      includes: include,
      excludes: exclude
    };

    const { isSuccess, message, error } = isDeactivate
      ? await deactivationUser(body)
      : await reactivationUser(body);
    successAction(isSuccess, message, error);
  };

  const resetSelection = () => {
    resetSelectedUser();
    setShowOnlySelected(false);
  };

  const resetFilter = () => {
    return dispatchFilter({
      type: 'RESET',
      state: defaultFilterToReset
    });
  };

  const ShowFloatingAction = ({ totalSelected }) => {
    return (
      <FloatingAction
        checkboxAction={{
          name: 'showOnlySelectedManageUser',
          id: 'showOnlySelectedManageUser',
          isChecked: isShowOnlySelected,
          onClick: () => setShowOnlySelected(!isShowOnlySelected)
        }}
        text={`Show only ${totalSelected} selected users`}
        deleteAction={{
          onClick: deleteAction,
          text: isDeactivate ? 'Deactivate' : 'Reactivate'
        }}
        additionalAction={
          currentState === 'Not Yet Sign Up' && {
            onClick: _resendActivation,
            text: 'Resend Activation'
          }
        }
        cancelAction={{
          onClick: resetSelection,
          text: 'Cancel selection'
        }}
        dataCy="showOnlySelectedManageUser"
      />
    );
  };

  const headers = [
    { name: 'checkbox', widthClass: 'w-[64px]', hide: isReadOnly },
    {
      name: 'name',
      widthClass: 'w-[240px]',
      grow: true,
      sort: {
        default: sortDirection,
        onSort: (direction) => {
          setSortDirection(direction);
        }
      }
    },
    { name: 'job title', widthClass: 'w-[200px]', grow: true },
    { name: 'permission', widthClass: 'w-[200px]', grow: true },
    { name: 'job role', widthClass: 'w-[200px]', grow: true, hide: true },
    { name: 'job function', widthClass: 'w-[200px]', grow: true, hide: true },
    { name: 'directorate', widthClass: 'w-[200px]', grow: true, hide: true },
    { name: 'department', widthClass: 'w-[200px]', grow: true, hide: true },
    { name: 'division', widthClass: 'w-[200px]', grow: true, hide: true },
    { name: 'region', widthClass: 'w-[200px]', grow: true, hide: true },
    { name: 'city', widthClass: 'w-[200px]', grow: true, hide: true },
    { name: 'country', widthClass: 'w-[200px]', grow: true, hide: true },
    { name: 'role', widthClass: 'w-[200px]', grow: true, hide: true },
    { name: 'platform', widthClass: 'w-[200px]', grow: true },
    { name: 'activation state', widthClass: 'w-[200px]', grow: true },
    { name: '', widthClass: 'w-[60px]' }
  ].filter((val) => val);

  const debounceFn = useCallback(debounce(_getUsersData, 1000), [
    currentState,
    sortDirection,
    reloadState,
    filter
  ]);

  const _getAllIds = async () => {
    return await getAllIds(getUserParams(null, null));
  };

  const debounceGetAllIds = useCallback(debounce(_getAllIds, 1000), [
    currentState,
    sortDirection,
    reloadState,
    filter
  ]);

  useEffect(() => {
    if (!isFilterEmpty(filter)) debounceGetAllIds();
    debounceFn();

    return (
      !isFilterEmpty(filter) && debounceGetAllIds.cancel, debounceFn.cancel
    );
  }, [currentState, sortDirection, reloadState, filter]);

  useDeepEffect(() => {
    if (isFilterEmpty(filter) && !isShowOnlySelected) {
      setTotalAllUser(total);
    }
  }, [total]);

  const showOnlyAction = () => {
    if (isFilterEmpty(filter)) {
      _getUsersData();
    } else {
      setIsLoading(true);
      resetFilter();
    }
  };

  useLayoutEffect(() => {
    showOnlyAction();
  }, [isShowOnlySelected]);

  useEffect(() => {
    return () => {
      resetSelection();
      resetFilter();
    };
  }, []);

  return (
    <div style={{ gridArea: 'listUserTable' }}>
      {isLoading && <LoadingOverlay />}
      <Table
        headers={headers}
        customClass={
          location.pathname.includes('edit') ||
          location.pathname.includes('add')
            ? 'hidden'
            : ''
        }
        withHideColumn={true}
        fixedLeftCount={2}
        fixedRightCount={1}
        headerCheckbox={{
          checked: isShowOnlySelected
            ? true
            : isAllSelected({
                allIds: allIds,
                totalUsers: totalAllUser,
                filter: filter
              }),
          onClick: () => _selectAllCheckbox()
        }}
      >
        {isLoading &&
          [...new Array(10)].map((_, index) => {
            return (
              <Table.Row key={index}>
                {headers.map((_, index) => {
                  return (
                    <Table.Column key={index}>
                      <Shimmer height="20px" widthRandomness={0.25} circle />
                    </Table.Column>
                  );
                })}
              </Table.Row>
            );
          })}
        {!isLoading &&
          users &&
          users.map((user, index) => (
            <UserRow userData={user} props={props} key={index} />
          ))}
        {!isLoading && users.length === 0 && (
          <ListEmptyState
            fullHeight
            size="large"
            containerClassname="my-[40px]"
            emptyIcon={q ? 'icon-no-user-found' : 'icon-no-user'}
            title={getObjectiveLocale('No user found')}
            subtitle={getObjectiveLocale(
              'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.\n You might also find the records by switching to tab above.'
            )}
          />
        )}
      </Table>
      {showModal && (
        <UsersAdminProvider>
          <ModalShowMember
            setShowModalMembers={setShowModal}
            isSelectAll={selectAll}
            listIdUser={include.map((e) => e.id)}
            excludedId={exclude.map((e) => e.id)}
            isReadOnly={isReadOnly}
            isAbleToRemoveUser={true}
            onClickRemove={handleUncheckUsers}
            title="Selected Employees"
            titleNoMember="No Selected Employees"
            additionalState="deleted"
          />
        </UsersAdminProvider>
      )}
      <div className="mt-[12px] pb-[40px] flex justify-end">
        <PaginationModule
          pageNumber={currentPage}
          handlePrev={() => handlePrevPage()}
          handleNext={() => handleNextPage()}
          hasMore={hasMore}
        />
      </div>
      {totalAllUser > 0 && (selectAll || include?.length > 0) && (
        <ShowFloatingAction
          totalSelected={
            selectAll ? totalAllUser - exclude?.length : include?.length
          }
        />
      )}
    </div>
  );
};

export default ListUserTable;

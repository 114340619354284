// Load Package
import React from 'react';

import HeaderPage from 'components/admin/HeaderPage';

import ActivationComponent from './overview/ActivationComponent';
import MostFeedbackGiven from './overview/MostFeedbackGiven';
import MostRecognitionGiven from './overview/MostRecognitionGiven';
import PerformanceScoreComponent from './overview/PerformanceScoreComponent';

function Overview({ route: { title } }) {
  return (
    <>
      <HeaderPage titlePage={title} />
      <div id="dashboard_content" className="mt-[12px]">
        <div className="dashboard_content_main">
          {/* Show Activation and Performance Chart Component */}
          <ActivationComponent />
          <PerformanceScoreComponent />
          <div className="row">
            <MostRecognitionGiven />
            <MostFeedbackGiven />
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;

import React, { useEffect, useState } from 'react';

import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';
import PriorityDropdown from 'components/tasks/dropdown/PriorityDropdown';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

const CustomTrigger = ({ selectedPriority }) => {
  const { name, colorHex, bgColorHex } = selectedPriority || {};

  return (
    <div className="h-[48px] flex items-center">
      <SVGIcon
        iconName="icon-outlined_flag"
        size="24"
        fillColor="var(--n-600)"
        customClass="mr-[16px]"
      />
      {selectedPriority ? (
        <Badge
          defaultPointer
          content={name}
          colorHex={colorHex}
          bgColorHex={bgColorHex}
          className="pointer-events-none"
        />
      ) : (
        <span className="typography-paragraph text-n-600">
          {getObjectiveLocale('Add Priority')}
        </span>
      )}
    </div>
  );
};

const AddPriority = ({ listPriorities, setObjectiveValue, defaultValue }) => {
  const [selectedPriority, setSelectedPriority] = useState(null);

  const onChange = (id) => {
    const selPriority =
      listPriorities?.find((priority) => priority.id === id) || {};
    delete selPriority?.createdAt;
    delete selPriority?.updatedAt;

    setSelectedPriority(selPriority);
    setObjectiveValue((draft) => {
      draft.priorityId = id;
      draft.priority = selPriority;
    });
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedPriority(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className="px-[24px] hover:bg-base-30024 cursor-pointer">
      <PriorityDropdown
        useTrigger
        useCustomTrigger={<CustomTrigger selectedPriority={selectedPriority} />}
        position="left"
        customClass="w-full"
        useFloating={false}
        onChange={onChange}
        listPriorities={listPriorities}
      />
    </div>
  );
};

export default AddPriority;

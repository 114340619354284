import { create } from 'zustand';

const usePrevUser = create((set) => ({
  user: null,
  manager: null,
  setUser: (user) => set({ user: user }),
  setManager: (manager) => set({ manager: manager })
}));

export default usePrevUser;

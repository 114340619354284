import React from 'react';

import ctl from '@netlify/classnames-template-literals';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const ContentWrapper = ({
  children,
  iconName,
  iconCustomClass,
  dataCy,
  onClick,
  customWrapperClass,
  withInfo = false,
  infoWording = '',
  wrapperChildrenClass
}) => {
  const contentWrapperCN = ctl(`
    flex
    bg-n-000
    relative
    ${onClick && 'hover:bg-base-30024 cursor-pointer'}
    ${customWrapperClass && customWrapperClass}
  `);

  return (
    <div
      className={contentWrapperCN}
      data-cy={dataCy}
      onClick={() => onClick && onClick()}
    >
      <div
        className={`flex w-full ${
          wrapperChildrenClass ? wrapperChildrenClass : 'items-center'
        }`}
      >
        {iconName && (
          <SVGIcon
            customClass={ctl(`mr-[16px] ${iconCustomClass && iconCustomClass}`)}
            iconName={iconName}
            size="24"
            fillColor="var(--n-600)"
          />
        )}
        {children}
      </div>
      {withInfo && (
        <TooltipContainer
          align="right"
          useMaxWidth={false}
          show
          text={getObjectiveLocale(infoWording)}
        >
          <SVGIcon
            size="18"
            fillColor="var(--n-600)"
            iconName="icon-info"
            customClass="ml-[8px] cursor-pointer hidden group-hover:flex"
          />
        </TooltipContainer>
      )}
    </div>
  );
};

export default ContentWrapper;

import React, { useEffect, useState } from 'react';

import useFilter from 'hooks/useFilter';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import TwoLine from 'components/design-system/ProfileType';
import Accordion from 'components/design-system/accordion/Accordion';
import { getUsersApprovalTeams } from 'src/client/ApprovalClient';
import ObjectiveSkeleton from 'src/components/shared/ObjectiveSkeleton';
import { useUser } from 'src/context/UserContext';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

import TeamsApproval from '../teams/TeamsApproval';

function ListingTeamsApproval() {
  const { user } = useUser();
  const filter = useFilter((state) => state.filterById?.teamApprovalFilter);
  const [listUser, setListUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getListUsersApprovalTeam = async () => {
    setIsLoading(true);
    const params = {
      type: 'team',
      approverPlacementId: user?.placementId,
      ...filter
    };
    const { data } = await getUsersApprovalTeams(params);
    if (data) {
      setListUser(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    filter?.viewBy == 'individual' && getListUsersApprovalTeam();
  }, [filter]);

  return (
    <>
      <AdvancedFilter
        filterOptions={['search', 'viewBy', 'sort']}
        customClass="pt-[24px] pb-[24px]"
        id="teamApprovalFilter"
      />
      {isLoading ? (
        <ObjectiveSkeleton />
      ) : filter?.viewBy == 'individual' ? (
        listUser?.map((data, index) => (
          <Accordion useBorder customClass="mb-[8px] py-[16px]" key={index}>
            <Accordion.Header
              dataCyChevronButton="open-assignment"
              customSpaceClass="px-[24px] items-center"
              useButtonSeparator
            >
              <div className="h-[40px] flex items-center">
                <TwoLine user={data} title={data?.name} />
              </div>
              <p className="typography-h100 text-n-800 flex-grow text-right">
                {`${data?.approvalCount} ${getObjectiveLocale(
                  'Request Approval'
                )}`}
              </p>
            </Accordion.Header>
            <Accordion.Content customSpaceClass="pt-[16px] px-[16px] border-solid border-0 border-t-[1px] border-n-400 mt-[14px]">
              <TeamsApproval
                requesterId={data?.placementId}
                filter={filter}
                withTop={false}
                page="approval"
              />
            </Accordion.Content>
          </Accordion>
        ))
      ) : (
        <TeamsApproval filter={filter} withTop={false} page="approval" />
      )}
    </>
  );
}

export default ListingTeamsApproval;

import React, { useEffect, useState } from 'react';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';

let rollUpOptions = {
  //INI JUGA DENG
  disabled: {
    index: 1,
    value: getObjectiveLocale('Manual'),
    description: `${getObjectiveLocale('Manual')}: ${getObjectiveLocale(
      'Update progress manually'
    )}`,
    rollUp: 'disabled'
  },
  auto: {
    index: 2,
    value: getObjectiveLocale('Auto Sum'),
    description: `${getObjectiveLocale('Auto Sum')}: ${getObjectiveLocale(
      'Update progress automatically by sum of target'
    )}`,
    rollUp: 'auto'
  },
  average: {
    index: 3,
    value: getObjectiveLocale('Auto Avg'),
    description: `${getObjectiveLocale('Auto Avg')}: ${getObjectiveLocale(
      'Update progres automatically by avg of percentage progress'
    )}`,
    rollUp: 'average'
  },
  weighted_average: {
    index: 4,
    value: getObjectiveLocale('Auto Weighted Avg'),
    description: `${getObjectiveLocale(
      'Auto Weighted Avg'
    )}: ${getObjectiveLocale(
      'Update progress automatically by weighted avg of percentage progress (objective only)'
    )}`,
    rollUp: 'weighted_average'
  },
  average_by_phase: {
    index: 5,
    value: getObjectiveLocale('Auto Completed Sum'),
    description: `${getObjectiveLocale(
      'Auto Completed Sum'
    )}: ${getObjectiveLocale(
      'Update progress automatically by the number of completed tasks or sub goals'
    )}`,
    rollUp: 'average_by_phase'
  },
  average_by_jira_phase: {
    index: 5,
    value: getObjectiveLocale('JIRA'),
    description: `${getObjectiveLocale('JIRA')}: ${getObjectiveLocale(
      'Update progress automatically by JIRA progress'
    )}`,
    rollUp: 'average_by_jira_phase'
  }
};

function DropdownRollUp({
  handleChange,
  defaultValue,
  selectedMetric,
  type,
  disabled = false,
  isIntegrated = false
}) {
  const [selectedValue, setSelectedValue] = useState('');
  const { config } = useUser();

  let defaultRollUpOptions =
    type == 'task'
      ? config?.rollupOptionsForTask
      : config?.rollupOptionsForObjective;

  if (
    type != 'task' &&
    !defaultRollUpOptions.includes('average_by_jira_phase') &&
    config?.enableJiraIntegration &&
    isIntegrated
  ) {
    defaultRollUpOptions.push('average_by_jira_phase');
  } else if (
    type != 'task' &&
    defaultRollUpOptions.includes('average_by_jira_phase') &&
    !isIntegrated
  ) {
    defaultRollUpOptions = defaultRollUpOptions.filter(
      (opt) => opt != 'average_by_jira_phase'
    );
  }

  const listRollUp = defaultRollUpOptions
    ?.map((option) => {
      return rollUpOptions[option];
    })
    ?.filter((listRollUp) => listRollUp);

  const listDropdownValue =
    selectedMetric?.description == 'Percentages' ||
    selectedMetric?.description == 'Percentage'
      ? listRollUp
      : listRollUp.filter((option) => !option?.rollUp?.includes('average'));

  const handleChangeDropdown = (value) => {
    setSelectedValue(value?.value);
    handleChange(value?.rollUp);
  };

  useEffect(() => {
    const value = rollUpOptions[defaultValue].value;
    setSelectedValue(value);
  }, [defaultValue]);
  return (
    <Dropdown customClass="w-full">
      <Dropdown.Trigger
        dataCy="dropdown-rollup"
        customClass="w-full"
        disabled={disabled}
      >
        <Trigger
          icon={false}
          topLabel={false}
          label={false}
          text={selectedValue}
          customWrapperClass="w-full"
          disabled={disabled}
        />
      </Dropdown.Trigger>
      <Dropdown.MenuItems
        position="right"
        customClass="w-full"
        style={{ width: '100%' }}
      >
        {listDropdownValue?.map((value, index) => (
          <Dropdown.MenuItem
            index={value?.index}
            onClick={() => handleChangeDropdown(value)}
            customClass="w-full"
            dataCy={`dropdown-rollup-option-${value?.index}`}
            key={index}
          >
            {value?.value}
          </Dropdown.MenuItem>
        ))}
      </Dropdown.MenuItems>
    </Dropdown>
  );
}

export default DropdownRollUp;

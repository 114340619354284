import React from 'react';

import produce, { setAutoFreeze } from 'immer';

import {
  getParentSuggestions,
  getTaskParentSuggestions
} from 'client/ObjectivesClient';

// CREATE CONTEXT
const ParentSuggestionContext = React.createContext();

// PROVIDER
const ParentSuggestionProvider = (props) => {
  // INITIAL STATE
  const initialState = {
    listValue: [],
    hasMore: true,
    offset: '',
    newerThan: ''
  };

  const [state, setState] = React.useState(initialState);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];

  return <ParentSuggestionContext.Provider value={contextValue} {...props} />;
};

const useParentSuggestion = () => {
  const [{ listValue, hasMore, offset }, immerSetState] = React.useContext(
    ParentSuggestionContext
  );

  const getParentSuggestionData = async (
    query,
    useTaskParentSuggestion = false
  ) => {
    const _query = {
      ...query
    };

    const { data, pagination } = useTaskParentSuggestion
      ? await getTaskParentSuggestions(_query)
      : await getParentSuggestions(_query);
    if (data) {
      immerSetState((draft) => {
        draft.listValue = data;
        draft.offset = data?.length >= 20 ? pagination?.nextOffset : null;
        draft.hasMore = data?.length >= 20;
      });
    } else {
      immerSetState((draft) => {
        draft.offset = null;
        draft.hasMore = false;
      });
    }
  };

  const appendParentSuggestionData = async (
    query,
    useTaskParentSuggestion = false
  ) => {
    const _query = {
      ...(offset && { offset: offset }),
      ...query
    };

    const { data, pagination } = useTaskParentSuggestion
      ? await getTaskParentSuggestions(_query)
      : await getParentSuggestions(_query);
    if (data) {
      immerSetState((draft) => {
        draft.listValue.push.apply(draft.listValue, data);
        draft.offset = data?.length >= 20 ? pagination?.nextOffset : null;
        draft.hasMore = data?.length >= 20;
      });
    } else {
      immerSetState((draft) => {
        draft.offset = null;
        draft.hasMore = false;
      });
    }
  };

  return {
    listValue,
    hasMore,
    getParentSuggestionData,
    appendParentSuggestionData
  };
};

export { ParentSuggestionProvider, useParentSuggestion };

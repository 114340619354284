import React from 'react';

import useEmoticon from 'hooks/useEmoticon';

import ModalPostFeeling from './ModalPostFeeling';
import ModalPostPhoto from './ModalPostPhoto';
import ModalPostRecognition from './ModalPostRecognition';
import ModalPostSurvey from './ModalPostSurvey';

function GenerateModalPost({ modalType, eventOnClick, groupDetail }) {
  let emoticon = useEmoticon();

  return (
    <>
      {modalType === 'photo' && (
        <ModalPostPhoto
          eventOnClick={eventOnClick}
          groupDetail={groupDetail}
          emoticon={emoticon}
        />
      )}
      {modalType === 'recognition' && (
        <ModalPostRecognition
          eventOnClick={eventOnClick}
          groupDetail={groupDetail}
          emoticon={emoticon}
        />
      )}
      {modalType === 'feeling' && (
        <ModalPostFeeling
          eventOnClick={eventOnClick}
          groupDetail={groupDetail}
          emoticon={emoticon}
        />
      )}
      {modalType === 'poll' && (
        <ModalPostSurvey
          eventOnClick={eventOnClick}
          groupDetail={groupDetail}
          emoticon={emoticon}
        />
      )}
    </>
  );
}

export default GenerateModalPost;

import client from './ApiClient';

function getObjectiveUserSugestion(role) {
  return client(`v1/objective_user_suggestions`, {
    method: 'GET',
    params: role
  });
}

export { getObjectiveUserSugestion };

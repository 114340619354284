import client from './ApiClient';

function getListGroup(params) {
  let url = `culture/groups/v2`;
  return client(url, { params: params });
}

function searchGroup(params) {
  let url = `culture/search_groups`;
  return client(url, { params: params });
}

function getStatus(id) {
  let url = `culture/groups/${id}`;
  return client(url);
}

function getFeedsTimeline(groupId, params) {
  let url = `culture/feeds/${groupId}/recent`;
  return client(url, { params: params });
}

function getPostById(postId) {
  let url = `culture/posts/${postId}`;
  return client(url);
}

function getListUser(groupId, params) {
  let url = `culture/groups/${groupId}/search_members_v2`;
  return client(url, { params: params });
}

function getSearchUserNew(params) {
  let url = `culture/search_users_new`;
  return client(url, { params: params });
}

function getSeeMoreComment(postId, params) {
  let url = `culture/posts/${postId}/comments`;
  return client(url, { params: params });
}

function getReplyComments(postId, parentId, params) {
  let url = `culture/posts/${postId}/comments/${parentId}/comments`;
  return client(url, { params: params });
}

function getLabelList(params) {
  let url = `culture/labels`;
  return client(url, { params: params });
}

function getUrlInfo(urlString) {
  let url = `culture/helper/crawler?url=${urlString}`;
  return client(url);
}

function getRecognitionConfig(urlString) {
  let url = `culture/config/recognition`;
  return client(url);
}

function getRecognitionValues() {
  let url = `culture/values/`;
  return client(url);
}

function getBehaviors(params) {
  let url = `behaviors/`;
  return client(url, { params: params });
}

function getRecognitionSkills(params) {
  let url = `culture/skills/`;
  return client(url, { params: params });
}

function getFeelings() {
  const url = `culture/feelings`;
  return client(url);
}

function getReasons(feelingId) {
  const url = `culture/feelings/${feelingId}/reasons`;
  return client(url);
}

function getStickers(feelingId) {
  const url = `culture/feelings/${feelingId}/stickers`;
  return client(url);
}

function getPulseSurvey(surveyId, useInterceptor = true, params) {
  const url = `culture/surveys/${surveyId}`;
  return client(url, { useInterceptor, params });
}

function getGroupPostable() {
  const url = `culture/groups/postables`;
  return client(url);
}

function getNonMemberGroup(groupId, params) {
  const url = `culture/groups/${groupId}/non_members_v2`;
  return client(url, { params });
}

function getAdminMemberGroup(groupId, params) {
  const url = `culture/groups/${groupId}/members/admin`;
  return client(url, { params });
}

function getAcceptedMemberGroup(groupId, params) {
  const url = `culture/groups/${groupId}/members/accepted`;
  return client(url, { params });
}

function getRecentFeelingPost(userId, params) {
  const url = `culture/feeds/users/${userId}`;
  return client(url, { params });
}

function getRecognitionPost(userId, params) {
  const url = `culture/feeds/recognition/${userId}`;
  return client(url, { params });
}

function getSavedPost(params) {
  const url = `culture/saved_posts`;
  return client(url, { params });
}

function getHighlightPost(params) {
  const url = `culture/feeds`;
  return client(url, { params });
}

function getFilterFeeds(filterType, params) {
  const url = `culture/feeds/post_type/${filterType}`;
  return client(url, { params });
}

function getFeedsbyLabel(labelId, params) {
  const url = `culture/feeds/labels/${labelId}`;
  return client(url, { params });
}

function getPinnedPost(groupId) {
  const url = `culture/feeds/${groupId}/pinned`;
  return client(url);
}

function getTaggedUsers(postId, params) {
  const url = `culture/posts/${postId}/tagged_users`;
  return client(url, { params });
}

function getViewerLikers(postId, type, params) {
  const url = `culture/posts/${postId}/${type}`;
  return client(url, { params });
}

// POST

function getPresignUrlCulture(body) {
  const url = `culture/uploads/aws_sign_multiple_media`;
  return client(url, { method: 'POST', body });
}

function submitSurvey(pollId, optionId) {
  const url = `culture/poll/${pollId}/vote/${optionId}`;
  return client(url, { method: 'POST', body: {} });
}

function likePost(pollId) {
  const url = `culture/posts/${pollId}/love`;
  return client(url, { method: 'POST', body: {} });
}

function unlikePost(pollId) {
  const url = `culture/posts/${pollId}/unlove`;
  return client(url, { method: 'POST', body: {} });
}

function submitComment(postId, body) {
  const url = `culture/posts/${postId}/comments`;
  return client(url, { method: 'POST', body });
}

function submitReplyComment(postId, parentId, body) {
  const url = `culture/posts/${postId}/comments/${parentId}/comments`;
  return client(url, { method: 'POST', body });
}

function submitPost(body) {
  const url = `culture/posts`;
  return client(url, { method: 'POST', body });
}

function viewPost(postId) {
  const url = `culture/posts/${postId}/view`;
  return client(url, { method: 'POST', body: {} });
}

function addGroup(body) {
  const url = `culture/groups`;
  return client(url, { method: 'POST', body: body });
}

function requestJoinGroup(id) {
  const url = `culture/groups/${id}/join_group`;
  return client(url, { method: 'POST', body: {} });
}

function cancelRequestGroup(id) {
  const url = `culture/groups/${id}/cancel_request`;
  return client(url, { method: 'POST', body: {} });
}

function voteSurvey(pollId, optionId, body) {
  const url = `culture/poll/${pollId}/vote/${optionId}`;
  return client(url, { method: 'POST', body: body });
}

function submitEssaySurvey(pollId, body) {
  const url = `culture/essay/${pollId}/answer`;
  return client(url, { method: 'POST', body });
}

function postStartTimeSurvey(surveyId, userId, body) {
  const url = `surveys/${surveyId}/start/${userId}`;
  return client(url, { method: 'POST', body });
}

function answerLaterSurvey(id, body) {
  const url = `culture/surveys/${id}/postpone_answer`;
  return client(url, { method: 'POST', body });
}

function savePost(body) {
  const url = `culture/saved_posts/`;
  return client(url, { method: 'POST', body });
}

function mutePost(postId) {
  const url = `culture/posts/${postId}/mute`;
  return client(url, { method: 'POST', body: {} });
}

function unmutePost(postId) {
  const url = `culture/posts/${postId}/unmute`;
  return client(url, { method: 'POST', body: {} });
}

function repostPost(postId, body) {
  const url = `culture/posts/${postId}/repost`;
  return client(url, { method: 'POST', body });
}

function reportPost(postId, body) {
  const url = `culture/posts/${postId}/inappropriate`;
  return client(url, { method: 'POST', body });
}

function muteGroup(groupId) {
  const url = `culture/groups/${groupId}/mute`;
  return client(url, { method: 'POST', body: {} });
}

function unmuteGroup(groupId) {
  const url = `culture/groups/${groupId}/unmute`;
  return client(url, { method: 'POST', body: {} });
}

function leaveGroup(groupId) {
  const url = `culture/groups/${groupId}/leave`;
  return client(url, { method: 'POST', body: {} });
}

function inviteMemberGroup(groupId, body) {
  const url = `culture/groups/${groupId}/invite/`;
  return client(url, { method: 'POST', body });
}

function removeMemberGroup(groupId, memberId) {
  const url = `culture/groups/${groupId}/remove/${memberId}`;
  return client(url, { method: 'POST', body: {} });
}

function assignAdminGroup(groupId, memberId) {
  const url = `culture/groups/${groupId}/add_admin/${memberId}`;
  return client(url, { method: 'POST', body: {} });
}

function removeAdminGroup(groupId, memberId) {
  const url = `culture/groups/${groupId}/remove_admin/${memberId}`;
  return client(url, { method: 'POST', body: {} });
}

function highlightPinnedPost(postId, type) {
  const url = `culture/posts/${postId}/${type}`;
  return client(url, { method: 'POST', body: {} });
}

function getShareToken(postId, body) {
  const url = `culture/posts/${postId}/share_public`;
  return client(url, { method: 'POST', body: body });
}

// PATCH

function editPost(postId, body) {
  const url = `culture/posts/${postId}`;
  return client(url, { method: 'PATCH', body });
}

function editComment(postId, commentId, body) {
  const url = `culture/posts/${postId}/comments/${commentId}`;
  return client(url, { method: 'PATCH', body });
}

function editGroup(groupId, body) {
  const url = `culture/groups/${groupId}`;
  return client(url, { method: 'PATCH', body });
}

// PUT
function completeSurvey(surveyId, body) {
  const url = `culture/surveys/${surveyId}/complete`;
  return client(url, { method: 'PUT', body });
}

// DELETE

function deletePost(postId) {
  const url = `culture/posts/${postId}`;
  return client(url, { method: 'DELETE', body: {} });
}

function deleteComment(postId, commentId) {
  const url = `culture/posts/${postId}/comments/${commentId}`;
  return client(url, { method: 'DELETE', body: {} });
}

function unsavePost(postId) {
  const url = `culture/saved_posts/${postId}`;
  return client(url, { method: 'DELETE', body: {} });
}

function deleteGroup(groupId) {
  const url = `culture/groups/${groupId}`;
  return client(url, { method: 'DELETE', body: {} });
}

function calculateRecurrenceSurvey(params) {
  const url = `culture/cms/surveys/recurrence/calculate`;
  return client(url, { method: 'GET', params });
}

export {
  getListGroup,
  searchGroup,
  getStatus,
  getFeedsTimeline,
  getPostById,
  getListUser,
  getSearchUserNew,
  getSeeMoreComment,
  submitSurvey,
  likePost,
  unlikePost,
  submitComment,
  editComment,
  deleteComment,
  getLabelList,
  getUrlInfo,
  getPresignUrlCulture,
  getRecognitionConfig,
  submitPost,
  getRecognitionValues,
  getRecognitionSkills,
  getFeelings,
  getReasons,
  getStickers,
  viewPost,
  getPulseSurvey,
  postStartTimeSurvey,
  voteSurvey,
  submitEssaySurvey,
  completeSurvey,
  answerLaterSurvey,
  addGroup,
  requestJoinGroup,
  cancelRequestGroup,
  editPost,
  deletePost,
  savePost,
  unsavePost,
  mutePost,
  unmutePost,
  getGroupPostable,
  repostPost,
  reportPost,
  editGroup,
  muteGroup,
  unmuteGroup,
  leaveGroup,
  deleteGroup,
  getNonMemberGroup,
  inviteMemberGroup,
  removeMemberGroup,
  assignAdminGroup,
  removeAdminGroup,
  getAdminMemberGroup,
  getAcceptedMemberGroup,
  getRecentFeelingPost,
  getRecognitionPost,
  getSavedPost,
  getHighlightPost,
  getFilterFeeds,
  getFeedsbyLabel,
  getPinnedPost,
  highlightPinnedPost,
  getTaggedUsers,
  getShareToken,
  getReplyComments,
  submitReplyComment,
  getViewerLikers,
  getBehaviors,
  calculateRecurrenceSurvey
};

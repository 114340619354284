import React from 'react';
import { useQuery } from 'react-query';

import { getObjectivesCount } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { whichBgColorClassName } from 'utils/ObjectivesHelper';

import Divider from 'components/design-system/Divider';
import FormattedNumber from 'components/design-system/FormattedNumber';
import SVGIcon from 'components/shared/SVGIcon';
import ProgressBar from 'components/shared/progressBar/ProgressBarOverallProgress';
import { _conditionMinMaxObjectiveTooltipMessageHelper } from 'src/pages/create-objective-warning/CreateObjectivesWarningHelpers';

function SubordinateCardContent({ filter, user, isParent }) {
  const { config } = useUser();

  const {
    maxObjectivesOn,
    minObjectivesOn,
    maxNumberOfObjectives,
    minNumberOfObjectives,
    maxSumOfObjectivesWeightPercentage: maxSumOfObjectivesBasedOnCategory,
    maxSumOfObjectivesWeightPercentageOn: maxSumOfObjectivesOnBasedOnCategory
  } = config.objectivesConfig;

  const getCount = async () => {
    return await getObjectivesCount(filter);
  };

  let { data } = useQuery(['overallProgress', user?.id], getCount, {
    enabled: true,
    suspense: true,
    forceFetchOnMount: true
  });

  const overallProgressData = data?.data;

  const totalObjectives = overallProgressData?.totalObjectives || 0;
  const totalWeight = overallProgressData?.totalWeights || 0;

  const { warning } = _conditionMinMaxObjectiveTooltipMessageHelper({
    maxObjectivesOn,
    minObjectivesOn,
    maxNumberOfObjectives,
    minNumberOfObjectives,
    totalObjectives
  });

  const isWarningWeight =
    maxSumOfObjectivesOnBasedOnCategory &&
    totalWeight > maxSumOfObjectivesBasedOnCategory;

  return (
    <>
      <Divider customClass="mt-[16px]" />
      <p
        className="typography-h400 mt-[16px] px-[16px]"
        style={{ color: `#${overallProgressData?.progressColorHex}` }}
      >
        <FormattedNumber
          number={overallProgressData?.overallProgress}
          minDecimalLength={2}
          customClass="typography-h400"
        />
        % {getObjectiveLocale('Progression')}
      </p>
      <div className="px-[16px] mt-[8px] h-[16px]">
        <ProgressBar
          widthBackground={100}
          widthProgress={Math.round(overallProgressData?.overallProgress)}
          colorProgress={whichBgColorClassName(
            overallProgressData?.progressColorHex,
            true
          )}
          height={16}
        />
      </div>
      <div className="flex items-center px-[16px] mt-[16px]">
        {warning && (
          <SVGIcon
            customClass="mr-[4px]"
            iconName="icon-warning"
            fillColor="var(--y-500)"
            size={16}
          />
        )}
        <FormattedNumber
          number={overallProgressData?.totalObjectives}
          minDecimalLength={0}
          customClass={`${
            warning ? 'text-r-600' : 'text-n-900'
          } typography-h400 `}
          data-cy="overall-objective-total"
        />
        <p className="typography-h400 text-n-900">
          {maxObjectivesOn
            ? `\u00A0/ ${maxNumberOfObjectives}`
            : minObjectivesOn
            ? `\u00A0/ ${minNumberOfObjectives}`
            : ''}
        </p>
        <p className="ml-[4px] typography-h400 text-n-600 mr-[16px]">
          {getObjectiveLocale('Top Parent')}
        </p>
        {isWarningWeight && (
          <SVGIcon
            customClass="mr-[4px]"
            iconName="icon-warning"
            fillColor="var(--y-500)"
            size={16}
          />
        )}
        <p
          className={`typography-h400 ${
            isWarningWeight ? 'text-r-600' : 'text-n-900'
          } `}
          data-cy="weight"
        >
          <FormattedNumber
            number={overallProgressData?.totalWeights || 0}
            minDecimalLength={2}
            customClass={`typography-h400 ${
              isWarningWeight ? 'text-r-600' : 'text-n-900'
            } `}
          />
          %
        </p>
        <p
          className="ml-[4px] typography-h400 text-n-600"
          data-cy="weight-title"
        >
          {getObjectiveLocale('Total Weight')}
        </p>
      </div>
    </>
  );
}

export default SubordinateCardContent;

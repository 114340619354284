import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { getCssVariableValue } from 'utils/HelperUtils';

import Badge from 'components/shared/Badge';

import '../../../scss/admin-culture/_tabs.scss';

const TabsInterface = {
  changeTab: PropTypes.func,
  active: PropTypes.string,
  listObjTabs: PropTypes.array,
  customClass: PropTypes.string,
  customClassCount: PropTypes.string,
  small: PropTypes.bool,
  useBorder: PropTypes.bool,
  withCount: PropTypes.bool,
  withBadge: PropTypes.bool,
  typographyClass: PropTypes.string,
  customClassButton: PropTypes.string,
  customClassContainer: PropTypes.string
};

const TabsDefaultValue = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeTab: () => {},
  active: 'general setting',
  listObjTabs: [{ id: 'general setting', name: 'General Setting' }],
  customClass: '',
  customClassCount: '',
  small: false,
  useBorder: true,
  withCount: false,
  withBadge: false,
  typographyClass: 'typography-h500',
  customClassButton: '',
  customClassContainer: ''
};

const AdminTabsGeneral = ({
  changeTab,
  active,
  listObjTabs,
  customClass,
  customClassCount,
  small,
  useBorder,
  withCount,
  withBadge,
  typographyClass,
  customClassButton,
  customClassContainer
}) => {
  return (
    <div className={`z-index-9 ${customClass}`} data-cy="tab-general">
      <div
        className={`px-[0px] ${
          useBorder ? 'border-bottom-grey' : ''
        } ${customClassContainer}`}
      >
        {listObjTabs.map((v, i) => (
          <button
            key={i}
            className={`tab-list-survey-purple state-secondary-purple text-n-900 ${typographyClass} ${customClassButton}
                    ${small ? 'small' : ''}
                    ${v?.disabled ? 'text-n-600 cursor-not-allowed' : ''}
                    ${v.class || ''}`}
            disabled={v?.disabled}
            onClick={() => changeTab(`${v.id}`)}
            style={
              active === `${v?.id}`
                ? { color: 'var(--base-600)' }
                : { borderBottom: 0, opacity: '0.5' }
            }
            data-cy={v?.name?.toLowerCase()}
          >
            {v?.name}
            {withCount &&
              (withBadge ? (
                v.count > 0 && (
                  <Badge
                    bgColorHex={getCssVariableValue('--r-600')}
                    colorHex={getCssVariableValue('--n-000')}
                    className="ml-[4px]"
                    content={v.count}
                  />
                )
              ) : (
                <span className={`count ${customClassCount} ml-[4px]`}>
                  {`(${v.count ? v.count : 0})`}
                </span>
              ))}
          </button>
        ))}
      </div>
    </div>
  );
};

AdminTabsGeneral.propTypes = TabsInterface;
AdminTabsGeneral.defaultProps = TabsDefaultValue;

export default AdminTabsGeneral;

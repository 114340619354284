import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import { ContentState, EditorState } from 'draft-js';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SuggestionList from 'components/shared/MentionSuggestion/SuggestionList';
import 'src/scss/web/editorStyles';

const MentionEditor = ({
  wrapperClassName = '',
  dataCy = '',
  setFocus,
  placeholder = '',
  source = 'comment',
  getListUsers,
  setComment,
  setMetaMentions,
  suggestions = [],
  metaMentions = [],
  isClearEditor,
  onClearEditorFunc,
  defaultValue,
  mentionComponent,
  shouldUpdateValue,
  setShouldUpdateValue,
  mentionEnabled
}) => {
  let editor = useRef(Editor);

  const [open, setOpen] = useState(true);
  const [editorState, setEditorState] = useState(
    defaultValue
      ? EditorState.createWithContent(ContentState.createFromText(defaultValue))
      : EditorState.createEmpty()
  );
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      positionSuggestions: (settings) => {
        return {
          top:
            source == 'modal' ? 'auto' : settings.decoratorRect.top - 40 + 'px',
          display: 'block',
          transform: 'scale(1) translateY(-100%)',
          transformOrigin: '1em 0% 0px',
          transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)',
          position: 'fixed',
          zIndex: 10
        };
      },
      mentionComponent: mentionComponent,
      supportWhitespace: true,
      mentionTrigger: '@'
    });

    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, [source]);

  const onChange = (newEditorState) => {
    // To prevent the onChange function fired when changing focus.
    // https://stackoverflow.com/questions/40911570/draftjs-triggers-content-change-on-focus
    const currentContentState = editorState.getCurrentContent();
    const newContentState = newEditorState.getCurrentContent();
    setEditorState(newEditorState);

    if (currentContentState !== newContentState) {
      const comment = newEditorState.getCurrentContent().getPlainText();
      setComment && setComment(comment);
    }
  };

  const onOpenChange = useCallback((open) => {
    setOpen(open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }) => {
      setOpen(true);
      getListUsers && getListUsers(value);
    },
    [getListUsers]
  );

  const onAddMention = (user) => {
    const currentCommentText = editorState.getCurrentContent().getPlainText();

    const startIndex = currentCommentText.indexOf('@');
    const newMentions = {
      userId: user.id,
      startIndex: startIndex,
      length: user.name.length,
      name: user.name
    };

    setMetaMentions && setMetaMentions([...metaMentions, newMentions]);
  };

  const focusArea = (e) => {
    e.stopPropagation();
    const endEditorState = EditorState.moveSelectionToEnd(editorState);
    EditorState.forceSelection(endEditorState, endEditorState.getSelection());
    setFocus && setFocus(true);
  };

  useEffect(() => {
    if (isClearEditor) {
      setEditorState(() => EditorState.createEmpty());
      onClearEditorFunc && onClearEditorFunc();
    }
  }, [isClearEditor, onClearEditorFunc]);

  useEffect(() => {
    if (shouldUpdateValue) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromText(defaultValue || '')
        )
      );
      setShouldUpdateValue && setShouldUpdateValue(false);
    }
  }, [shouldUpdateValue, setShouldUpdateValue, defaultValue]);

  return (
    <div
      className={`editor ${wrapperClassName}`}
      id="editor-comment"
      onClick={(e) => focusArea(e)}
      data-cy={dataCy}
    >
      <Editor
        editorState={editorState}
        onChange={onChange}
        plugins={mentionEnabled ? plugins : []}
        ref={(element) => {
          editor = element;
        }}
        placeholder={getObjectiveLocale(placeholder)}
        onBlur={() => setFocus && setFocus(false)}
      />
      <div className="mention-suggestions">
        <MentionSuggestions
          style={{
            margin: 0,
            padding: 0,
            height: '150px',
            overflowY: `auto`
          }}
          onSearchChange={onSearchChange}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          open={open}
          onAddMention={onAddMention}
          entryComponent={SuggestionList}
        />
      </div>
    </div>
  );
};

export default MentionEditor;

import React, { useCallback, useEffect, useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import { asset } from 'src/utils/Config.js';

const PulseSurveyEmptyState = ({
  currentTab,
  onClick,
  isReadOnly,
  isPulseSurvey = false
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState([]);

  const emptyState = useCallback(() => {
    if (currentTab) {
      currentTab == 'current' &&
        isPulseSurvey &&
        (setTitle('No survey yet'),
        setDescription(
          "You don't have any active survey".split('.').filter((desc) => desc)
        ));
      currentTab == 'past' &&
        isPulseSurvey &&
        (setTitle('No Past Survey Yet'),
        setDescription(
          "You don't have any past survey.".split('.').filter((desc) => desc)
        ));
      currentTab == 'current' &&
        !isPulseSurvey &&
        (setTitle('No Active Survey Available'),
        setDescription(
          'You can check the next survey in ‘Upcoming’ section. Click the button below to create a new pulse survey.'
            .split('.')
            .filter((desc) => desc)
        ));
      currentTab == 'upcoming' &&
        !isPulseSurvey &&
        (setTitle('No Upcoming Survey Scheduled'),
        setDescription(
          'All upcoming survey will be stored on this page. Click the button below to create a new pulse survey.'
            .split('.')
            .filter((desc) => desc)
        ));
      currentTab == 'past' &&
        !isPulseSurvey &&
        (setTitle('No Past Survey Yet'),
        setDescription(
          'All expired surveys will be stored on this page. Click the button below to create a new pulse survey.'
            .split('.')
            .filter((desc) => desc)
        ));
    }
  }, [currentTab, isPulseSurvey]);

  useEffect(() => {
    emptyState();
  }, [emptyState]);

  return (
    <div className="w-full flex flex-col items-center justify-center py-[36px] fade-in">
      {isPulseSurvey ? (
        <SVGIcon iconName="icon-no-survey" size="256" customClass="mb-[24px]" />
      ) : (
        <SVGIcon iconName="icon-no-survey" size="160" />
      )}
      <div className="py-[24px] flex flex-col items-center justify-center">
        <span
          className="typography-h700 text-n-900 mb-[12px]"
          data-cy={currentTab}
        >
          {getObjectiveLocale(title)}
        </span>
        {description.map((desc, index) => (
          <span
            className="typography-paragraph text-n-800 max-w-[400px]"
            key={`desc-empty-state-${index}`}
            data-cy="description-empty-state"
          >
            {getObjectiveLocale(`${desc}.`)}
          </span>
        ))}
      </div>
      {!isReadOnly && !isPulseSurvey && (
        <Button variant="primary" onClick={onClick}>
          <SVGIcon
            iconName="icon-add"
            size="24"
            fillColor="var(--n-100)"
            customClass="mr-[8px]"
          />
          {getObjectiveLocale('Create Pulse Survey')}
        </Button>
      )}
    </div>
  );
};

export default PulseSurveyEmptyState;

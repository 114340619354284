import React from 'react';

import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

const GanttBackgroundWeeklyColumn = ({ daysOfYear }) => {
  return (
    <>
      {daysOfYear.map(({ date, id }) => {
        if (date.getDay() == 1) {
          const dateWeek = dayjs(date).week();
          const nowWeek = dayjs().week();
          const isNowWeek =
            dateWeek == nowWeek && dayjs(date).year() == dayjs().year();
          return (
            <div
              className={`tm-bg-wrapper relative h-[100%] w-[84px] ${
                isNowWeek ? 'bg-n-100' : ''
              }`}
              key={'bg-weeks' + id}
            />
          );
        }
      })}
    </>
  );
};

export default GanttBackgroundWeeklyColumn;

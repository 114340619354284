import React, { createRef, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { Tree } from '@coutcout/tree-datastructure';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';

import { getListObjectives, getObjectivesCount } from 'client/ObjectivesClient';
import { editObjective } from 'client/ObjectivesClient';
import { useProjectDetail } from 'context/ProjectDetailContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import useTableHeader from 'hooks/useTableHeader';
import useTasks from 'hooks/useTasks';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { restructureFilter } from 'utils/ObjectivesHelper';

import Table from 'components/design-system/table/Table';
import ConditionalWrapper from 'components/shared/ConditionalWrapper';
import Draggable from 'components/shared/DragAndDrop/Draggable';

import AddTaskButton from './AddTaskButton';
import Task from './Task';
import TaskSkeleton from './TaskSkeleton';
import './Tasks.scss';

const AddButtonContainer = React.forwardRef(
  ({ groupData, sectionData, headers, isProject }, ref) => {
    return (
      <div ref={ref} className="mt-[-1px]">
        <AddTaskButton
          groupName={groupData?.name}
          sectionName={sectionData?.name}
          tableHeaders={headers}
          isProject={isProject}
        />
      </div>
    );
  }
);

AddButtonContainer.displayName = 'AddButtonContainer';

const TaskTree = React.memo(
  ({
    tree,
    filter,
    draggedId,
    setDraggedId,
    setSub,
    groupData,
    sectionData,
    index,
    isProject,
    listMetrics = []
  }) => {
    const root = tree?.getRoot();
    return (
      <Task
        index={index}
        taskNode={root}
        filter={filter}
        level={0}
        draggedId={draggedId}
        setDraggedId={setDraggedId}
        setSub={setSub}
        groupData={groupData}
        sectionData={sectionData}
        isProject={isProject}
        listMetrics={listMetrics}
      />
    );
  },
  areEqual
);

/*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
*/
const areEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

const Tasks = ({
  filter,
  groupData,
  sectionData,
  draggedId,
  setDraggedId,
  autoShowPlaceholder,
  setAutoShowPlaceholder,
  isProject,
  customClass = '',
  isDataReady,
  headerRef,
  listMetrics = []
}) => {
  const [tasksCount, setTasksCount] = useState(0);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const headers = useTableHeader(
    (state) =>
      state.headers?.[
        isProject
          ? `project-${filter?.group}-${sectionData?.id}-${groupData?.id}`
          : 'taskManagementTable'
      ]
  );
  const setHeaders = useTableHeader((state) => state.setHeaders);
  const { user } = useUser();

  const { refetchObjective, invalidateQueries } = useRefetchQuery();
  const {
    tasks,
    updateTasksOneLevel,
    updateTasksTwoLevels,
    dropTaskOneLevel,
    dropTaskTwoLevels,
    removeTaskChildrenFromParent,
    setEmptyTasksOneLevel,
    setEmptyTasksTwoLevels
  } = useTasks();
  const { projectId, permissions } = useProjectDetail();

  const allowAddNewTask = isProject
    ? permissions?.includes('add_subtask')
    : true;

  let newFilter = restructureFilter(filter);

  const getQueryParams = (olderThan) => {
    let priorityIds = filter?.priority?.map(({ id }) => id);
    let phaseIds = filter?.phase?.map(({ id }) => id);

    // page: project details (isProject), my tasks (!isProject)
    const appendParamsByProject = (isProject) => {
      if (isProject) {
        return {
          parentId: projectId,
          sectionId: sectionData?.id,
          ...(filter?.assignTo === 'me' && { assigneeId: [user?.id] })
        };
      } else {
        return {
          assigneeId: [user?.id],
          taskType: filter?.showTask
        };
      }
    };

    // group by: phase, priority, section, project
    const appendParamsByGroup = (group) => {
      if (group === 'phase') {
        if (filter?.showTask === 'project') {
          return { phaseName: groupData?.name?.toUpperCase() };
        } else {
          return { phaseId: groupData?.id };
        }
      } else if (group === 'priority') {
        return { priorityId: groupData?.id };
      } else if (group === 'section') {
        return { sectionName: groupData?.name.toUpperCase() };
      } else if (group === 'project') {
        return { parentId: groupData?.id };
      }
    };

    // filter: phase / priority
    const appendParamsByFilterPhasePriority = (group) => {
      if (group !== 'phase' && phaseIds?.length > 0) {
        return { phaseId: phaseIds };
      }
      if (group !== 'priority' && priorityIds?.length > 0) {
        return { priorityId: priorityIds };
      }
    };

    let allParams = {
      type: ['task'],
      reviewsVisibility: 1,
      limit: 10,
      olderThan,
      parentNotAssignedTo: user.id,
      ...appendParamsByProject(isProject),
      ...appendParamsByGroup(filter?.group),
      ...appendParamsByFilterPhasePriority(filter?.group),
      ...newFilter
    };

    allParams.state && delete allParams.state;

    if (isProject) {
      delete allParams.periodBegin;
      delete allParams.periodEndBefore;
    }

    return allParams;
  };

  const fetchObjectives = async (olderThan) => {
    const queryParams = getQueryParams(olderThan);
    return getListObjectives(queryParams);
  };

  const queryParamsKey = getQueryParams();
  delete queryParamsKey.phaseId;
  delete queryParamsKey.priorityId;
  delete queryParamsKey.sectionId;
  delete queryParamsKey.phaseName;
  delete queryParamsKey.sectionName;

  const queryKey = isProject
    ? [
        'objectives',
        'mytasks',
        groupData?.name.toLowerCase(),
        sectionData?.name.toLowerCase(),
        queryParamsKey
      ]
    : ['objectives', 'mytasks', groupData?.name.toLowerCase(), queryParamsKey];

  const {
    data,
    status,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    isIdle,
    isStale
  } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => fetchObjectives(pageParam),
    {
      staleTime: 5 * 60 * 1000,
      enabled: queryEnabled,
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination?.next?.olderThan || undefined;
      },
      onSuccess: (data) => {
        const currentData = data.pages[data?.pages?.length - 1];
        if (currentData?.data?.length < 10) return setHasMore(false);
        setHasMore(true);
      }
    }
  );

  const getTasksCount = async () => {
    let params = {
      sectionId: sectionData?.id,
      ...(filter?.group === 'phase' && { phaseId: groupData?.id }),
      ...(filter?.group === 'priority' && { priorityId: groupData?.id }),
      ...(filter?.assignTo !== 'me' && { ignoreInvolvements: true }),
      ...(newFilter?.progressUpdatedOlderThan && {
        progressUpdatedOlderThan: newFilter?.progressUpdatedOlderThan
      })
    };
    const { data } = await getObjectivesCount(params);
    if (data) {
      data?.totalObjectives !== tasksCount &&
        setTasksCount(data?.totalObjectives);
    }
  };

  const updateHeader = (changeType) => {
    let tempHeaders = cloneDeep(headers);

    // need to update header index number 1
    // because it's header for the task name
    // and we need to replace the header name with the task count
    // and add badge component to show the phase/priority group table
    // and it's only implemented for task in project detail page
    tempHeaders[1].isAlwaysVisible = true;
    if (changeType === 'tasksCount') {
      tempHeaders[1].name = `${tasksCount} task(s)`;
    } else if (changeType === 'badge') {
      tempHeaders[1].badge = {
        content: groupData?.name?.toUpperCase(),
        colorHex: groupData?.colorHex,
        bgColorHex: groupData?.bgColorHex
      };
    }
    setHeaders(
      `project-${filter?.group}-${sectionData?.id}-${groupData?.id}`,
      tempHeaders
    );
  };

  useEffect(() => {
    if (data && status === 'success' && !isFetching && !isStale) {
      // get current tree hash
      const hash = {};
      const tasksList = isProject
        ? tasks?.[sectionData?.name]?.[groupData?.name]
        : tasks?.[groupData?.name];
      tasksList?.length > 0 &&
        tasksList?.forEach((task) => {
          hash[task.getRoot().value.id] = task;
        });

      const newTasks = flatten(
        data?.pages?.map((page) => {
          return page?.data?.map((d) => {
            // use previous tree if there is any for this id
            // why keep the tree
            // because in multiple select we need to keep if the tree has children or not
            // if we replace it with the new tree it getChilds will always return []
            // even if the children is expanded
            return hash[d.id] ? hash[d.id] : new Tree(d);
          });
        })
      );

      if (autoShowPlaceholder) {
        if (isProject) {
          updateTasksTwoLevels(sectionData?.name, groupData?.name, [
            new Tree({ name: '' }),
            ...newTasks
          ]);
        } else {
          updateTasksOneLevel(groupData?.name, [
            new Tree({ name: '' }),
            ...newTasks
          ]);
        }
        setAutoShowPlaceholder(false);
      } else {
        if (isProject) {
          updateTasksTwoLevels(sectionData?.name, groupData?.name, newTasks);
        } else {
          updateTasksOneLevel(groupData?.name, newTasks);
        }
      }
    }
    //eslint-disable-next-line
  }, [data, status, isFetching, isStale]);

  const invalidateDragAndDropData = (srcData, dstData) => {
    const srcKey = isProject
      ? [`objectives`, 'mytasks', srcData?.section, srcData?.group]
      : [`objectives`, 'mytasks', srcData?.group];

    const dstKey = isProject
      ? [`objectives`, 'mytasks', dstData?.section, dstData?.group]
      : [`objectives`, 'mytasks', dstData?.group];

    invalidateQueries(srcKey, {
      refetchActive: false
    });
    invalidateQueries(dstKey, {
      refetchActive: false
    });
  };

  const drop = async (ev, sourceId, isSub) => {
    ev.preventDefault();

    const srcData = {
      index: draggedId?.index,
      level: draggedId?.level,
      ['group' + (isProject ? '2' : '')]: draggedId?.groupName,
      ...(isProject && { group1: draggedId?.sectionName }),
      groupId: draggedId?.groupId,
      section: draggedId?.sectionName,
      sectionId: draggedId?.sectionId,
      task: draggedId?.task,
      taskNode: draggedId?.taskNode
    };

    const dstData = {
      ['group' + (isProject ? '2' : '')]: groupData?.name,
      ...(isProject && { group1: sectionData?.name }),
      groupId: groupData?.id,
      section: sectionData?.name,
      sectionId: sectionData?.id
    };

    const currentGroupId = parseInt(ev.currentTarget.getAttribute('groupId'));
    const currentSectionId = isProject
      ? parseInt(ev.currentTarget.getAttribute('sectionId'))
      : null;
    const currentGroupName = ev.currentTarget
      .getAttribute('groupName')
      .toUpperCase();

    if (filter?.group === 'phase' || filter?.group === 'priority') {
      if (
        (isProject &&
          srcData?.group1 === dstData?.group1 &&
          srcData?.group2 === dstData?.group2) ||
        (!isProject && srcData.group === dstData.group)
      ) {
        return;
      }
      let body = {
        ...(isProject && { sectionId: currentSectionId }),
        ...(filter?.showTask === 'project' && filter?.group !== 'priority'
          ? {
              [filter?.group + 'Name']: currentGroupName
            }
          : {
              [filter?.group === 'phase' ? 'phaseId' : 'priorityId']:
                currentGroupId
            })
      };

      if (draggedId?.level > 0 && !isSub) {
        body.parentId = isProject
          ? projectId
          : filter?.showTask === 'project'
          ? srcData.taskNode.getParent().value.parent.id
          : null;
      }

      const { isSuccess } = await editObjective(sourceId, body);
      if (isSuccess) {
        if (isProject) {
          dropTaskTwoLevels(srcData, dstData);
        } else {
          dropTaskOneLevel(srcData, dstData);
        }

        refetchObjective(sourceId);

        if (draggedId?.level > 0 && !isSub) {
          removeTaskChildrenFromParent(
            srcData?.taskNode?.getParent(),
            srcData?.task?.id
          );
          refetchObjective(srcData?.taskNode?.getParent()?.value?.id);
        }
        invalidateDragAndDropData(srcData, dstData);
      }
    }
  };

  const ref = createRef();

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  };

  useEffect(() => {
    return () => {
      if (isProject) {
        setEmptyTasksTwoLevels(sectionData?.name, groupData?.name);
      } else {
        setEmptyTasksOneLevel(groupData?.name);
      }
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!ref.current) return;
    const callbackFn = (entries) => {
      const [entry] = entries;
      if (!entry.isIntersecting) return;
      if (!hasMore) return;
      if (isFetchingNextPage) return;
      fetchNextPage();
    };
    const observer = new IntersectionObserver(callbackFn, (entries) => {
      const [entry] = entries;
      if (!entry.isIntersecting) return;
      if (!hasMore) return;
      if (isFetchingNextPage) return;
      fetchNextPage();
    });
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, options, isFetchingNextPage, hasMore]);

  useEffect(() => {
    isProject && getTasksCount();
  }, [
    filter,
    groupData?.id,
    groupData?.name,
    tasks?.[sectionData?.name]?.[groupData?.name]
  ]);

  useEffect(() => {
    isProject && headers && updateHeader('tasksCount');
  }, [tasksCount]);

  useEffect(() => {
    if (isProject && headers) {
      const { content, colorHex, bgColorHex } = headers[1].badge;
      if (
        content !== groupData?.name?.toUpperCase() ||
        colorHex !== groupData?.colorHex ||
        bgColorHex !== groupData?.bgColorHex
      ) {
        updateHeader('badge');
      }
    }
  }, [groupData?.name, groupData?.colorHex, groupData?.bgColorHex]);

  useEffect(() => {
    // FETCH ONLY WHEN TARGET ELEMENT IS APPEAR IN SCROLL VIEW
    const target = headerRef.current;
    if (target) {
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && (isProject ? projectId !== null : true)) {
            setQueryEnabled(true);
          }
        });
      }, options);
      observer.observe(target);
      return () => {
        observer.disconnect();
      };
    }
  }, [headerRef, filter, groupData, isProject, isDataReady, projectId]);

  return (
    <div className={`grow table-task-list ${customClass}`}>
      <Table
        headers={headers}
        setTableHeaders={(newHeaders) =>
          setHeaders(
            isProject ? `projectManagementTable` : 'taskManagementTable',
            newHeaders
          )
        }
        fixedLeftCount={2}
        customClass="pr-[40px] w-max min-w-full"
        customHeaderStickyContainerCN="!bg-n-100"
        customClassTypographyHeader="typography-h100 text-n-600"
        customClassHeader={`!h-[16px] ${isProject ? 'my-[2px]' : 'mb-[8px]'}`}
        useBorderTopBottom={false}
        useOverflowTable={false}
        customHideColumnIcon={{
          iconName: 'icon-add_circle',
          fillColor: 'var(--n-500)',
          size: '16',
          customMenuItemsClass: 'mr-[20px]'
        }}
        withHideColumn={true}
      >
        {(isFetching || isIdle) && !isFetchingNextPage ? (
          <TaskSkeleton tableHeaders={headers} />
        ) : (isProject
            ? tasks?.[sectionData?.name]?.[groupData?.name]
            : tasks?.[groupData?.name]
          )?.length > 0 ? (
          (isProject
            ? tasks?.[sectionData?.name]?.[groupData?.name]
            : tasks?.[groupData?.name]
          )?.map((task, index) => (
            <TaskTree
              key={`task-list-${
                task?.getRoot()?.value?.id ? task?.getRoot()?.value?.id : index
              }`}
              tree={task}
              filter={filter}
              isLastTask={false}
              index={index}
              groupData={groupData}
              sectionData={sectionData}
              draggedId={draggedId}
              setDraggedId={setDraggedId}
              isSub={task?.parent}
              isProject={isProject}
              listMetrics={listMetrics}
            />
          ))
        ) : (
          !allowAddNewTask && (
            <div className="flex items-center relative bg-n-000 border border-solid border-n-300 rounded-[4px] h-[40px] ml-[35px]">
              <div className="flex flex-col items-start sticky left-[0px] typography-paragraph text-n-600 px-[16px]">
                {getObjectiveLocale('There is no task')}
              </div>
            </div>
          )
        )}

        {isFetchingNextPage && <TaskSkeleton tableHeaders={headers} />}

        <MemoConditionalWrapperComponent
          isProject={isProject}
          tasks={tasks}
          sectionData={sectionData}
          groupData={groupData}
          drop={drop}
          allowAddNewTask={allowAddNewTask}
          ref={ref}
          headers={headers}
        />
      </Table>
    </div>
  );
};

const ConditionalWrapperComponent = React.forwardRef(
  (
    {
      isProject,
      tasks,
      sectionData,
      groupData,
      drop,
      allowAddNewTask,
      headers
    },
    ref
  ) => {
    return (
      <ConditionalWrapper
        condition={
          isProject
            ? !tasks?.[sectionData?.name]?.[groupData?.name]?.length
            : !tasks?.[groupData?.name]?.length
        }
        wrapper={(children) => (
          <DraggableWrapper
            drop={drop}
            id={(isProject ? sectionData?.name + '-' : '') + groupData?.id}
            groupData={groupData}
            sectionData={sectionData}
          >
            {children}
          </DraggableWrapper>
        )}
      >
        {allowAddNewTask ? (
          <AddButtonContainer
            ref={ref}
            groupData={groupData}
            sectionData={sectionData}
            headers={headers}
            isProject={isProject}
          />
        ) : (
          <></>
        )}
      </ConditionalWrapper>
    );
  }
);

ConditionalWrapperComponent.displayName = 'ConditionalWrapperComponent';
const MemoConditionalWrapperComponent = React.memo(
  ConditionalWrapperComponent,
  areEqual
);

const DraggableWrapper = React.memo(
  ({ drop, id, children, groupData, sectionData }) => {
    return (
      <div className="relative">
        <Draggable
          drop={drop}
          id={id}
          groupData={groupData}
          sectionData={sectionData}
          isDraggable={false}
        >
          {children}
          <div
            className="invisible absolute top-[0px] border-solid border-t-[2px] border-0 border-base-600 z-[100] w-full"
            id={`border-drag-${id}`}
          >
            <div className="relative">
              <div className="absolute flex bottom-[0px]">
                <div
                  id={`border-padding-${id}`}
                  className="border-solid border-b-[2px] border-0 border-n-300 w-[36px]"
                />
                <div className="w-[5px] h-[0px] border-solid border-0 border-b-[5px] border-b-base-600 border-r-[5px] border-r-transparent" />
              </div>
            </div>
          </div>
        </Draggable>
      </div>
    );
  },
  areEqual
);

DraggableWrapper.displayName = 'DraggableWrapper';

TaskTree.displayName = 'TaskTree';

export default Tasks;

import React, { useEffect, useState } from 'react';

import { getBehaviors } from 'client/adminClient.js';
import useDebounce from 'hooks/useDebounce';
import Config from 'utils/Config';

import AWSImage from 'components/design-system/AWSImage';
import SVGIcon from 'src/components/shared/SVGIcon';

import './CardBehaviorModel.scss';
import CardKeyBehaviorModel from './CardKeyBehaviorModel';
import { paramsBehaviorDictionary } from './ParamsBehaviorGroup';

const CardBehaviorModel = ({
  weight,
  type,
  value,
  canEdit = true,
  behaviorWeights,
  setBehaviorWeights,
  setWeightUpdated,
  isWeightUpdated
}) => {
  const [isAccordionActive, setIsAccordionActive] = useState(false);
  const [dataKeyBehavior, setDataKeyBehavior] = useState([]);
  const [weightValue, setWeightValue] = useState(weight || '');
  const debouncedWeightValue = useDebounce(weightValue, 500);

  const handleOpenAccordion = async (id) => {
    if (!isAccordionActive && dataKeyBehavior.length == 0) {
      await _getKeyBehaviorData(id, { parentId: id });
    }
    setIsAccordionActive(!isAccordionActive);
  };

  const _getKeyBehaviorData = async (id, props, oldData = []) => {
    let _params = paramsBehaviorDictionary(type, props);
    const { data, pagination } = await getBehaviors(_params);
    if (data) {
      if (pagination.totalSize <= 10) {
        setDataKeyBehavior(data);
        return;
      }

      let hasMore = pagination.nextOffset == null ? false : true;
      let newData = [...oldData, ...data];
      if (hasMore) {
        _getKeyBehaviorData(
          id,
          { parentId: id, offset: pagination.nextOffset },
          data
        );
      }
      setDataKeyBehavior(newData);
    }
  };

  const onChangeWeight = (weight) => {
    setWeightValue(weight);
    setWeightUpdated && setWeightUpdated(true);
  };

  const onHandleSave = (weight) => {
    if (behaviorWeights.length > 0 && isWeightUpdated) {
      let newArray = [...behaviorWeights];
      let index = newArray.findIndex((val) => val.behaviorId == value.id);
      if (newArray[index]) {
        newArray[index].weight = Number(weight);
      }
      setBehaviorWeights(newArray);
    }
  };

  const _onKeyPress = (e) => {
    let isNumberAndDotOnly = /^[0-9]*\.?[0-9]*$/;
    if (!isNumberAndDotOnly.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    onHandleSave(weightValue);
  }, [debouncedWeightValue]);

  useEffect(() => {
    setWeightValue(weight);
  }, [behaviorWeights]);

  const paddingLeftTitle =
    type == 'work' || type == 'competency' ? 'pl-[48px]' : 'pl-[88px]';
  const sourceImage =
    value?.iconImageAttributes?.iconUrl || Config.asset.admin.defaultValueImg;

  return (
    <React.Fragment>
      <div className="card-behavior-model">
        <div className={`${paddingLeftTitle} py-[12px] card-title w-[35%]`}>
          {value.hasKeyBehaviors && (
            <SVGIcon
              iconName="icon-chevron_right"
              customClass={`icon-accordion ${
                isAccordionActive ? 'accordion-open' : ''
              }`}
              dataCy={`accordion-${type}-${value.id}`}
              id={`accordion-${type}-${value.id}`}
              onClick={() => handleOpenAccordion(value.id)}
              size="16"
            />
          )}
          {type == 'value' && (
            <AWSImage className="image-value" src={sourceImage} />
          )}
          <p>{value.title}</p>
        </div>
        <div className="py-[12px] pl-[16px] w-1/2">
          <p className="">{value.description}</p>
        </div>
        <div className="py-[12px] pl-[16px] pr-[16px] w-[15%]">
          <input
            type="text"
            onKeyPress={(e) => _onKeyPress(e)}
            data-cy={'input-behavior-weight-' + type + '-' + value.id}
            className="input-behavior-weight"
            defaultValue={weightValue}
            disabled={!canEdit}
            onChange={(e) => onChangeWeight(Number(e.target.value))}
          />
        </div>
      </div>
      {isAccordionActive &&
        dataKeyBehavior.map((data) => (
          <CardKeyBehaviorModel key={data.id} value={data} />
        ))}
    </React.Fragment>
  );
};

export default CardBehaviorModel;

import React, { useState } from 'react';

import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { getMessageDepedencyNotification } from 'utils/FormalReview';
import { capitalize } from 'utils/HelperUtils';

import ModalSetDependencies from 'components/admin/Cycles/phases/ModalSetDependencies';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Table from 'components/design-system/table/Table';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';

const SetDependencies = () => {
  const { cycleData, setPhaseDependencies, setPhaseDependenciesNotification } =
    useCreateCycle();

  const [showModal, setShowModal] = useState(null);
  const [tempDependencies, setTempDependencies] = useState([]);

  const tableHeaders = [
    {
      name: 'Phase',
      widthClass: 'w-[168px]',
      useMinHeight: false
    },
    {
      name: 'Dependency',
      widthClass: 'w-[472px]',
      useMinHeight: false,
      grow: true
    },
    {
      name: '',
      widthClass: 'w-[56px]',
      useMinHeight: false
    }
  ];

  const dependencyOptions = (data) => [
    {
      text: 'Edit',
      iconName: 'icon-create',
      onClick: () => {
        setTempDependencies(data.dependencies);
        setShowModal(data);
      }
    },
    {
      text: 'Clear Dependency',
      iconName: 'icon-delete',
      onClick: () => {
        setPhaseDependencies(data?.id, []);
        setPhaseDependenciesNotification(data?.id, null);
      }
    }
  ];

  const tableData = [
    cycleData.selfReview && {
      id: 'selfReview',
      name: 'Self',
      value: 'self_review',
      dependencies: cycleData.selfReview.dependencies || [],
      description: getMessageDepedencyNotification(cycleData.selfReview),
      notificationRecurrences: cycleData.selfReview.notificationRecurrences
    },
    cycleData.peerReview && {
      id: 'peerReview',
      name: 'Peer',
      value: 'peer_review',
      dependencies: cycleData.peerReview.dependencies || [],
      description: getMessageDepedencyNotification(cycleData.peerReview),
      notificationRecurrences: cycleData.peerReview.notificationRecurrences
    },
    cycleData.upwardReview && {
      id: 'upwardReview',
      name: 'Upward',
      value: 'upward_review',
      dependencies: cycleData.upwardReview.dependencies || [],
      description: getMessageDepedencyNotification(cycleData.upwardReview),
      notificationRecurrences: cycleData.upwardReview.notificationRecurrences
    },
    cycleData.managerReview && {
      id: 'managerReview',
      name: 'Manager',
      value: 'manager_review',
      dependencies: cycleData.managerReview.dependencies || [],
      description: getMessageDepedencyNotification(cycleData.managerReview),
      notificationRecurrences: cycleData.managerReview.notificationRecurrences
    },
    cycleData.indirectManagerReview && {
      id: 'indirectManagerReview',
      name: 'Indirect Manager',
      value: 'indirect_manager_review',
      dependencies: cycleData.indirectManagerReview.dependencies || [],
      description: getMessageDepedencyNotification(
        cycleData.indirectManagerReview
      ),
      notificationRecurrences:
        cycleData.indirectManagerReview.notificationRecurrences
    }
  ].filter((phase) => phase);

  const getDependencyText = (data) => {
    if (data.dependencies.length > 0) {
      return (
        data.dependencies
          .map((dep) => {
            let deps = dep.split('_');
            deps.pop();
            return deps.map((text) => capitalize(text)).join(' ');
          })
          .join(', ') + ' Review'
      );
    } else {
      return 'No Dependency (default)';
    }
  };

  const onSubmitModal = (useNotification, notificationRecurrence) => {
    setPhaseDependencies(showModal.id, tempDependencies);
    setPhaseDependenciesNotification(
      showModal.id,
      (useNotification && notificationRecurrence) || null
    );
    setShowModal(null);
  };

  const toggleTempDependencies = (value) => {
    if (tempDependencies.findIndex((phase) => phase === value) === -1) {
      setTempDependencies([...tempDependencies, value]);
    } else {
      setTempDependencies(tempDependencies.filter((phase) => phase !== value));
    }
  };

  return (
    <>
      <div className="wrapper-phase-and-track bg-n-000 ">
        <div className="px-[16px] pt-[12px] mb-[12px]">
          <p className="typography-h500">Set Dependencies</p>
          <p className="typography-secondary">
            Set up dependency relations for each review phases used in the cycle
          </p>
        </div>
        <div className="horizontal-divider mt-[0px]" />
        <Table
          headers={tableHeaders}
          customClassHeader="py-[8px]"
          fixedLeftCount={1}
          fixedRightCount={1}
          fixedLeftGrow={false}
        >
          {tableData?.map((data, index) => (
            <Table.Row key={index}>
              <Table.Column key="0">
                <TruncateParagraph>{data?.name}</TruncateParagraph>
              </Table.Column>
              <Table.Column key="1">
                <div className="flex flex-col justify-center">
                  <TruncateParagraph
                    className={
                      data?.dependencies?.length === 0 ? 'text-n-600' : ''
                    }
                  >
                    {getDependencyText(data)}
                  </TruncateParagraph>
                  <TruncateParagraph className="typography-h100 text-n-800">
                    {data?.description}
                  </TruncateParagraph>
                </div>
              </Table.Column>
              <Table.Column key="2">
                {data?.dependencies?.length === 0 ? (
                  <SVGIcon
                    iconName="icon-add"
                    size={24}
                    fillColor="var(--n-600)"
                    onClick={() => {
                      setTempDependencies([]);
                      setShowModal(data);
                    }}
                    dataCy={`dependency-action-${index}`}
                  />
                ) : (
                  <Dropdown>
                    <Dropdown.Trigger dataCy={`dependency-action-${index}`}>
                      <SVGIcon
                        iconName="icon-keyboard_control"
                        size={24}
                        fillColor="var(--n-600)"
                      />
                    </Dropdown.Trigger>
                    <Dropdown.MenuItems
                      options={dependencyOptions(data)}
                      position="right"
                      type="fixed"
                      dataCy="dependency-action"
                    />
                  </Dropdown>
                )}
              </Table.Column>
            </Table.Row>
          ))}
        </Table>
      </div>
      {showModal && (
        <ModalSetDependencies
          showModal={showModal}
          setShowModal={setShowModal}
          onSubmitModal={onSubmitModal}
          tableData={tableData}
          toggleTempDependencies={toggleTempDependencies}
          tempDependencies={tempDependencies}
        />
      )}
    </>
  );
};

export default SetDependencies;

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import debounce from 'lodash/debounce';

import {
  getGroupById,
  getListGroupsPermission,
  updateGroupsPermission,
  updateStateGroupsPermission
} from 'client/adminClient';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ListGroups from 'components/admin/permissions/ListGroups';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import AdminTabWithCount from 'components/shared/Tabs/AdminTabWithCount.js';
import ToastNotif from 'components/shared/ToastNotif.js';

import ClusterConfigurationBox from './ClusterConfigurationBox';

const selectorFilter = (state) => state.filterById?.objectivesVisibilityFilter;

function ObjectivesVisibility() {
  const history = useHistory();
  const positionsList = [
    { key: 'default', name: 'Default' },
    { key: 'groups', name: 'Groups' }
  ];
  const {
    config: { permissions }
  } = useUser();
  const filter = useFilter(selectorFilter);
  const [selectedValues, setSelectedValues] = useState({});
  const [listGroup, setListGroup] = useState([]);
  const [currentTab, setCurrentTab] = useState(positionsList[0]?.key);
  const [newerThanId, setNewerThanId] = useState(null);
  const [olderThanId, setOlderThanId] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentSort, setCurrentSort] = useState({
    sortColumn: 'name',
    sortDirection: 'asc'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPermission, setDefaultPermission] = useState({});
  const [loading, setLoading] = useState(false);
  const debouncedSelectedValues = useDebounce(selectedValues, 1000);
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [successMessage, setsuccessMessage] = useState('');
  const [isSuccess, setisSuccess] = useState(false);
  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');
  const isReadOnly = permissions.settingsObjectivesVisibility === 'read_only';

  const filterMenu = [
    { option: 'states', title: 'Status', dialog: 'permission-status' }
  ];

  const _getListGroupsPermission = async (type) => {
    let query = {
      ...filter,
      groupType: 'cluster_group',
      sortDirection: currentSort.sortDirection,
      sortColumn: currentSort.sortColumn,
      limit: 10
    };
    if (type == 'next') {
      query.olderThan = olderThanId;
    } else if (type == 'prev') {
      query.newerThan = newerThanId;
    }

    const { data, pagination } = await getListGroupsPermission(query);
    if (data) {
      setListGroup(data);
      setOlderThanId(pagination?.next?.olderThan);
      setNewerThanId(pagination?.prev?.newerThan);
      if (type == 'prev') {
        setHasMore(true);
      } else {
        setHasMore(data.length === 10);
      }
    }
  };

  const onClickCreateNewGroup = () => {
    history.push(`/objective-visibility/create`);
  };

  const changeTab = (tab) => {
    history.replace(`/objectives/settings?tab=visibility&tab=${tab}`);
    setCurrentTab(tab);
  };

  const _getDefaultPermissions = async () => {
    setLoading(true);
    const { data } =
      (await getGroupById(0, { group_type: 'cluster_group' }, true)) || {};
    if (data) {
      const initialSelectedValues = Object.values(data.clusters).reduce(
        (res, clusters) => {
          res = { ...res, ...clusters };
          return res;
        },
        {}
      );
      setSelectedValues(initialSelectedValues);
      setDefaultPermission(data);
    }
    setLoading(false);
  };

  const updateGroup = async () => {
    const {
      id,
      deleted,
      member_count,
      member_ids,
      current_state,
      permissions,
      ...body
    } = defaultPermission;

    const { data } = await updateGroupsPermission(
      id,
      {
        ...body,
        clusters: Object.entries(selectedValues).reduce(
          (res, [key, cluster]) => {
            res = {
              ...res,
              [key]: cluster
            };
            return res;
          },
          {}
        )
      },
      true
    );

    if (data) {
      setAllowUpdate(false);
      const initialSelectedValues = Object.values(data.clusters).reduce(
        (res, clusters) => {
          res = { ...res, ...clusters };
          return res;
        },
        {}
      );
      setSelectedValues(initialSelectedValues);
    }
  };

  const onClickDelete = async (data) => {
    let body = {
      changeState: data.deleted ? 'active' : 'archived'
    };
    if (!data.deleted) {
      body = {
        ...body,
        transferMembersToGroupId: 0
      };
    }
    const datass = await updateStateGroupsPermission(data.id, body);
    if (datass) {
      _getListGroupsPermission();
      setisSuccess(true);
      setsuccessMessage('Successfully Updated! ');
      setTimeout(() => {
        setisSuccess(false);
      }, 2000);
    }
  };

  useEffect(() => {
    _getDefaultPermissions();
  }, []);

  const debounceFn = useCallback(debounce(_getListGroupsPermission, 500), [
    filter,
    currentSort
  ]);

  useEffect(() => {
    debounceFn();
    return debounceFn.cancel;
  }, [filter, currentSort]);

  useEffect(() => {
    defaultPermission?.name && allowUpdate && updateGroup();
  }, [debouncedSelectedValues]);

  useEffect(() => {
    if (tabParams == 'groups') {
      setCurrentTab('groups');
    }
  }, [currentTab]);

  return (
    <div className="pt-[24px] pb-[90px]">
      <div
        className={`config-box-visibility bg-n-000 mb-[64px] ${
          currentTab == 'groups' ? 'h-[850px]' : ''
        }`}
      >
        <p className="typography-h500 mb-[8px]">
          {getObjectiveLocale('Objective’s Attribute Visibility')}
        </p>
        <p className="typography-paragraph text-n-800 mt-[0px]">
          All configurations in the Default Tab will be applied to organization
          level and to all employees who are not included in any group.
          Employees who are included in active groups will follow the
          configuration set in each respective group they belong to in the
          Groups Tab.
        </p>
        <AdminTabWithCount
          active={currentTab}
          listObjTabs={positionsList}
          changeTab={(id) => changeTab(id)}
          withCount={false}
          customClassTab={'fw-normal fs-14'}
        />
        {currentTab === 'groups' && (
          <>
            <div className="flex justify-between items-end mt-[24px]">
              {
                <AdvancedFilter
                  filterOptions={['search', 'menuFilter']}
                  filterMenu={filterMenu}
                  id="objectivesVisibilityFilter"
                />
              }
              <Button
                onClick={() => onClickCreateNewGroup()}
                datacy="create-group"
                disabled={isReadOnly}
              >
                <SVGIcon
                  customClass="mr-[8px]"
                  iconName="icon-add"
                  fillColor="var(--n-000)"
                  size={24}
                />
                Create Group
              </Button>
            </div>
            <ToastNotif showNotif={isSuccess} message={successMessage} />
            <ListGroups
              listData={listGroup}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              currentSort={currentSort}
              setCurrentSort={setCurrentSort}
              changePage={_getListGroupsPermission}
              hasMore={hasMore}
              onClickDelete={onClickDelete}
              isReadOnly={isReadOnly}
            />
          </>
        )}
        {currentTab === 'default' && (
          <ClusterConfigurationBox
            data={defaultPermission?.clusters}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            tableMinWidth="880px"
            loading={loading}
            setAllowUpdate={setAllowUpdate}
            withBorder={false}
            useHeader={false}
            isReadOnly={isReadOnly}
          />
        )}
      </div>
    </div>
  );
}

export default ObjectivesVisibility;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isEmpty from 'lodash/isEmpty';
import Papa from 'papaparse';

import {
  getListMeasurement,
  getObjectiveCategory
} from 'client/ObjectivesClient';
import { ImportCsv } from 'client/getAllUsers';
import { formatTimezone } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { CALC_TYPE_LESS_IS_BETTER, CALC_TYPE_NORMAL } from 'utils/const';

import Button from 'components/design-system/Button';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

dayjs.extend(customParseFormat);

// create component
function UploadCsv(props) {
  const [locale, setLocale] = useState({});
  const [onLoad, setOnLoad] = useState(false);
  const [body, setBody] = useState([]);
  const [isImportSuccess, setIsImportSuccess] = useState(true);
  const [objCategory, setObjCategory] = useState([]);
  const [objMetrics, setObjMetrics] = useState([]);
  const [fileName, setFileName] = useState('');
  const [isImport, setIsImport] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [errorCsvValidation, setErrorCsvValidation] = useState([]);
  const [isBadGateway, setBadGateway] = useState(false);

  const _getLocale = () => {
    const locale = JSON.parse(localStorage.getItem('formObjectiveCsv'));
    setLocale(locale);
  };

  const getCategoryList = async () => {
    const { data } = await getObjectiveCategory();
    if (data) setObjCategory(data);
  };

  const getMetricList = async () => {
    const { data } = await getListMeasurement();
    if (data) setObjMetrics(data);
  };

  const getCategoryId = (categoryName) => {
    return objCategory.find(
      (obj) => obj.name.toLowerCase() === categoryName?.toLowerCase()
    )?.id;
  };

  const getMetricUnitId = (metricName) => {
    return objMetrics.find(
      (obj) => obj.unit.toLowerCase() === metricName?.toLowerCase()
    )?.id;
  };

  const getRollUp = (rollUp) => {
    switch (rollUp) {
      case 'disabled':
      case 'manual': {
        return 'disabled';
      }
      case 'auto':
      case 'sum':
      case 'autosum':
      case 'auto sum': {
        return 'auto';
      }
      default:
        return rollUp;
    }
  };

  useEffect(() => {
    _getLocale();
    getCategoryList();
    getMetricList();
  }, []);

  const invalidKey = (type, key) => {
    const weightType = type === 'Goal' ? 'Weight' : 'Complexity';
    const minWeight = type === 'Goal' ? 0 : 1;
    const maxWeight = type === 'Goal' ? 100 : 3;

    const mandatoryKeys = [
      `${type} Owner (mandatory)`,
      `${type} Title (mandatory)`,
      'Start Date (mandatory)',
      'Due Date (mandatory)',
      `${weightType} (mandatory)`
    ];

    if (mandatoryKeys.some((mandatory) => !key[mandatory])) return true;

    const weight = parseInt(key[`${weightType} (mandatory)`]);
    if (weight < minWeight && weight > maxWeight) return true;

    if (type === 'Goal') {
      return (
        key['Complexity (mandatory)'] != null ||
        key['Task Owner (mandatory)'] != null
      );
    } else {
      return (
        key['Weight (mandatory)'] != null ||
        key['Goal Owner (mandatory)'] != null
      );
    }
  };

  const handleUploadCSV = () => {
    const fileInput = document.getElementById('fileToUpload');
    const file = fileInput.files[0];
    const label = document.getElementById('placeholder');
    const importStatus = document.getElementById('import-status');
    const data = {
      objectives: []
    };
    let errorMessage = [];
    let errorMessageList = [];
    const pushErrorMessage = (
      firstBool,
      firstMessage,
      secondBool,
      secondMessage
    ) => {
      if (firstBool) {
        errorMessage.push(firstMessage);
      } else if (secondBool) {
        errorMessage.push(secondMessage);
      }
    };

    importStatus.innerHTML = '';
    setIsImport(true);
    setIsImportSuccess(true);
    setErrorCsvValidation([]);
    setErrorList([]);

    if (fileInput.value.toLowerCase().lastIndexOf('.csv') == -1) {
      alert('Please upload a file with .csv extension.');
      return false;
    } else {
      const typeOfObjective = props.type;

      label.innerHTML = file.name;
      setFileName(file.name);

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        quotes: true,
        comments: '**',
        quoteChar: '"',

        complete(results) {
          results.data.map((key) => {
            const isColumnEmpty = Object.values(key).every((d) => d === '');
            if (isColumnEmpty) return;
            const dateFormat = [
              'DD-MM-YYYY',
              'DD/MM/YYYY',
              'DD MMM YYYY',
              'DD-MM-YY',
              'DD/MM/YY',
              'DD MMM YY'
            ];
            let startDate = dayjs(
              key['Start Date (mandatory)'],
              dateFormat,
              true
            );
            let dueDate = dayjs(key['Due Date (mandatory)'], dateFormat, true);

            const statusStartDate = startDate.isValid();
            const statusDueDate = dueDate.isValid();
            const isValidDate = statusStartDate && statusDueDate;
            if (typeOfObjective === 'goal') {
              const weight = parseFloat(
                key['Weight (mandatory)'].replace(',', '.')
              );

              if (invalidKey('Goal', key) || !isValidDate) {
                Object.keys(key).map((csvData) => {
                  switch (csvData) {
                    case 'Task Owner (mandatory)':
                      pushErrorMessage(
                        key[csvData] != null,
                        'Task Header cannot be in Goal Page'
                      );
                      break;

                    case 'Goal Owner (mandatory)':
                    case 'Goal Title (mandatory)':
                      pushErrorMessage(
                        key[csvData] === '',
                        `${csvData} column is empty`
                      );
                      break;
                    case 'Start Date (mandatory)':
                      pushErrorMessage(
                        key[csvData] === '',
                        `${csvData} column is empty`,
                        !statusStartDate,
                        'Start Date format is invalid'
                      );
                      break;
                    case 'Due Date (mandatory)':
                      pushErrorMessage(
                        key[csvData] === '',
                        `${csvData} column is empty`,
                        !statusDueDate,
                        'Due Date format is invalid'
                      );
                      break;

                    case 'Weight (mandatory)': {
                      const invalidWeight = weight < 0 || weight > 100;
                      pushErrorMessage(
                        key[csvData] === '',
                        `${csvData} column is empty`,
                        invalidWeight,
                        `${csvData} should be between 0 to 100`
                      );
                      break;
                    }
                  }
                });

                if (errorMessage.length > 0) {
                  let dataError = {
                    objectiveTitle: key['Goal Title (mandatory)']
                      ? key['Goal Title (mandatory)']
                      : 'Please fill mandatory column',

                    errorMessage
                  };
                  errorMessageList.push(dataError);

                  errorMessage = [];
                }
              } else {
                var startDateISO = formatTimezone(startDate, 'start');
                var dueDateISO = formatTimezone(dueDate, 'end');
                var labels = key['Label (optional)']
                  ?.replace(/^"|"$/g, '')
                  ?.split(',');
                var label = [];
                var target = key['Target (optional)'];
                var metrics = key['Metric (%,$,# optional)'];
                var metric = getMetricUnitId(metrics);
                var followers = key['Followers (optional)']
                  ?.replace(/^"|"$/g, '')
                  ?.split(',');
                var follower = [];
                var ownerList = key['Goal Owner (mandatory)']
                  ?.replace(/^"|"$/g, '')
                  ?.split(',');
                var mappingOwner = [];
                let reviewerValue =
                  key['Goal Reviewer (optional)'] ||
                  key['Goal Reviewer (mandatory)'];
                let reviewer = reviewerValue
                  ? [
                      {
                        role: 'assigner',
                        userEmail: reviewerValue.trim()
                      }
                    ]
                  : [];
                var rollUp = isEmpty(key['roll up (optional)'])
                  ? undefined
                  : getRollUp(key['roll up (optional)'].toLowerCase());
                let type = key['Objective Repetetition (optional)'];
                let nextDate =
                  type == 'day'
                    ? startDate.add(1, 'days')
                    : type == 'weekly'
                    ? startDate.add(7, 'days')
                    : startDate.add(1, 'months');
                let changedType =
                  type == 'daily'
                    ? 'day'
                    : type == 'weekly'
                    ? 'week'
                    : type == 'monthly'
                    ? 'month'
                    : 'year';

                var recurrence = {
                  every: 1,
                  nextDate: formatTimezone(
                    dayjs(nextDate).format('YYYY-MM-DD'),
                    'start'
                  ),
                  type: changedType
                };
                var dummyId = key['Dummy Id (optional)'];
                var dummyParentId = key['Dummy Parent Id (optional)'];
                var complexity = key['Complexity (mandatory)'];
                var categoryName = (
                  key['Goal Type (optional)'] || key['Goal Type (mandatory)']
                )?.trim();
                ownerList?.length > 0 &&
                  ownerList.map((key) => {
                    !isEmpty(key.trim()) &&
                      mappingOwner.push({
                        userEmail: key.trim(),
                        role: 'assignee'
                      });
                  });
                followers?.length > 0 &&
                  followers.map((key) => {
                    !isEmpty(key.trim()) &&
                      follower.push({
                        userEmail: key.trim(),
                        role: 'follower'
                      });
                  });
                labels?.length > 0 &&
                  labels.map((key) => {
                    if (key !== '') {
                      label.push(key.trim());
                    }
                  });
                switch (metrics) {
                  case '$':
                    metric = 1;
                    break;
                  case 'USD':
                    metric = 1;
                    break;
                  case '%':
                    metric = 2;
                    break;
                  case '#':
                    metric = 3;
                    break;
                  case 'IDR':
                    metric = 4;
                    break;
                  default:
                    break;
                }

                let temp = {
                  fakeId: dummyId ? parseInt(dummyId) : null,
                  fakeParentId: dummyParentId ? parseInt(dummyParentId) : null,
                  name: key['Goal Title (mandatory)'],
                  tags: label,
                  description: key['Detailed Expectation (optional)'],
                  startDate: startDateISO,
                  dueDate: dueDateISO,
                  involvements: [...reviewer, ...mappingOwner, ...follower],
                  measurement: {
                    unitId: metric,
                    rollUp: rollUp
                  },
                  type: typeOfObjective,
                  complexity: complexity ? complexity : 1,
                  weight: weight || 0,
                  objectiveCategoryId:
                    categoryName && getCategoryId(categoryName),
                  calculationType:
                    key['Less is Better']?.trim().toLowerCase() == 'yes'
                      ? CALC_TYPE_LESS_IS_BETTER
                      : CALC_TYPE_NORMAL
                };
                type && (temp.recurrence = recurrence);
                target && (temp.measurement.targetValue = parseFloat(target));
                data.objectives.push(temp);
              }
            }
            if (typeOfObjective === 'task') {
              if (invalidKey('Task', key)) {
                Object.keys(key).map((csvData) => {
                  switch (csvData) {
                    case 'Goal Owner (mandatory)':
                      pushErrorMessage(
                        key[csvData] != null,
                        'Goal Header cannot be in Goal Page'
                      );
                      break;

                    case 'Task Owner (mandatory)':
                    case 'Task Title (mandatory)':
                      pushErrorMessage(
                        !key[csvData],
                        `${csvData} column is empty`
                      );
                      break;
                    case 'Start Date (mandatory)':
                      pushErrorMessage(
                        key[csvData] === '',
                        `${csvData} column is empty`,
                        !statusStartDate,
                        'Start Date format is invalid'
                      );
                      break;
                    case 'Due Date (mandatory)':
                      pushErrorMessage(
                        key[csvData] === '',
                        `${csvData} column is empty`,
                        !statusDueDate,
                        'Due Date format is invalid'
                      );
                      break;
                    case 'Weight (mandatory)':
                      pushErrorMessage(
                        key[csvData] != null,
                        `${csvData} can't be in task`
                      );
                      break;

                    default:
                      break;
                  }

                  if (errorMessage.length > 0) {
                    let dataError = {
                      objectiveTitle: key['Task Title (mandatory)']
                        ? key['Task Title (mandatory)']
                        : 'Please fill mandatory column',

                      errorMessage
                    };
                    errorMessageList.push(dataError);

                    errorMessage = [];
                  }
                });
              } else {
                var startDateISO = formatTimezone(startDate, 'start');
                var dueDateISO = formatTimezone(dueDate, 'end');
                var labels = key['Label (optional)']
                  ?.replace(/^"|"$/g, '')
                  ?.split(',');
                var label = [];
                var target = key['Target (optional)']?.replace(/^"|"$/g, '');
                var metrics = key['Metric (%,$,# optional)'];
                var metric = getMetricUnitId(metrics);
                var followers = key['Followers (optional)']
                  ?.replace(/^"|"$/g, '')
                  ?.split(',');
                var follower = [];
                var ownerList = key['Task Owner (mandatory)']
                  ?.replace(/^"|"$/g, '')
                  ?.split(',');
                var mappingOwner = [];
                let reviewerValue =
                  key['Task Reviewer (optional)'] ||
                  key['Task Reviewer (mandatory)'];
                let reviewer = reviewerValue
                  ? [
                      {
                        role: 'assigner',
                        userEmail: reviewerValue.trim()
                      }
                    ]
                  : [];
                var complexity = key['Complexity (mandatory)'];
                const weight = parseFloat(
                  key['Weight (mandatory)'].replace(',', '.')
                );
                var lessIsBetter =
                  key['Less is Better']?.trim().toLowerCase() == 'yes';
                ownerList?.length > 0 &&
                  ownerList.map((key) => {
                    !isEmpty(key.trim()) &&
                      mappingOwner.push({
                        userEmail: key.trim(),
                        role: 'assignee'
                      });
                  });
                followers?.length > 0 &&
                  followers.map((key) => {
                    !isEmpty(key.trim()) &&
                      follower.push({
                        userEmail: key.trim(),
                        role: 'follower'
                      });
                  });
                labels?.length > 0 &&
                  labels.map((key) => {
                    if (key !== '') {
                      label.push(key.trim());
                    }
                  });

                data.objectives.push({
                  name: key['Task Title (mandatory)'],
                  tags: label,
                  description: key['Detailed Expectation (optional)'],
                  startDate: startDateISO,
                  dueDate: dueDateISO,

                  involvements: [...reviewer, ...mappingOwner, ...follower],
                  type: typeOfObjective,
                  measurement: {
                    unitId: metric,
                    targetValue: parseFloat(target)
                  },

                  complexity: complexity ? parseInt(complexity) : 1,
                  weight: weight || 0,
                  calculationType: lessIsBetter
                    ? CALC_TYPE_LESS_IS_BETTER
                    : CALC_TYPE_NORMAL
                });
              }
            }
          });
          if (errorMessageList?.length > 0) {
            importStatus.innerHTML = `Import of ${file.name} failed with error`;
            setIsImportSuccess(false);
            setErrorList(errorMessageList);
            setIsImport(false);
          } else {
            setBody(data);
            setIsImport(true);
          }
        }
      });

      fileInput.value = null;
    }
  };

  const importCsv = async () => {
    const importStatus = document.getElementById('import-status');
    const bodyParams = body;

    setErrorCsvValidation([]);
    setIsImportSuccess(true);
    setOnLoad(true);
    const { data, error } = await ImportCsv(bodyParams);
    if (data) {
      setIsImportSuccess(true);
      props.eventOnClick(false);
      props.history.replace('/goals');
    } else {
      error?.code == 504 ? setBadGateway(true) : setErrorCsvValidation(error);

      setIsImport(false);
      setOnLoad(false);
      setIsImportSuccess(false);
      importStatus.innerHTML = `Import of ${fileName} failed with errors`;
    }
  };

  const type = props.type;

  let component = (
    <div className="upload-csv overflow-auto max-h-[446px] min-h-[300px]">
      {onLoad && (
        <LoadingComponent
          className="uploading-file"
          hasText={true}
          text="Uploading File"
        />
      )}
      {!onLoad && (
        <div className="form-group">
          <h4>{getObjectiveLocale('Download CSV Template')}</h4>
          <p>
            {getObjectiveLocale(
              'Goal templates are designed to help you set actionable goals that meet standard format of our Performance'
            )}
          </p>
          <a
            className="btn btn-default no-bg"
            href={
              type == 'goal'
                ? '/template/CSV-Performance-TemplateGoal.csv'
                : '/template/CSV-Performance-TemplateTask.csv'
            }
            download="CSV_Performance_Template.csv"
          >
            {getObjectiveLocale('Download CSV Template')}
          </a>
        </div>
      )}
      {!onLoad && (
        <div className="container-button-upload">
          <h4>Import CSV</h4>
          <div className="drop-file">
            <input
              type="file"
              name="fileToUpload"
              id="fileToUpload"
              className="input-upload"
              onChange={() => handleUploadCSV()}
            />
            <label htmlFor="fileToUpload" className="btn btn-default">
              {getObjectiveLocale('Choose File')}
            </label>
            <span id="placeholder" />
          </div>

          <Button disabled={!isImport} onClick={importCsv}>
            {getObjectiveLocale('Upload')}
          </Button>
          <span
            id="import-status"
            className={isImportSuccess ? 'green' : 'red'}
          />
        </div>
      )}
      {!isImportSuccess &&
        (!isBadGateway ? (
          <div className="csv-error-message">
            <h4>{getObjectiveLocale('Steps to recover')}</h4>
            <ol>
              <li>
                1.{' '}
                {getObjectiveLocale(
                  'Your CSV has some instances of Goals with invalid values'
                )}
                <table className="error-table">
                  <thead>
                    <tr>
                      <th>{getObjectiveLocale('Row')}</th>
                      <th>{getObjectiveLocale('Task Title')}</th>
                      <th>{getObjectiveLocale('Error')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorList?.length > 0 &&
                      errorList?.map((error, index) => (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{error.objectiveTitle}</td>
                            <td>
                              {error?.errorMessage?.map((err) => {
                                if (error.errorMessage.length === 1) {
                                  return err;
                                } else {
                                  return `${err}, `;
                                }
                              })}
                            </td>
                          </tr>
                        </>
                      ))}
                    {errorCsvValidation.hasOwnProperty('message') &&
                      errorList.length == 0 &&
                      (typeof errorCsvValidation.message !== 'string' ? (
                        errorCsvValidation.message.map((error, index) => {
                          return (
                            <>
                              {error.isError ? (
                                <tr key={index}>
                                  <td>{error.index + 1}</td>
                                  <td>{body.objectives[error.index].name}</td>
                                  <td>
                                    {error.errors.map((err, idx) => {
                                      if (error.errors.length === 1) {
                                        return err;
                                      } else {
                                        return `${err}, `;
                                      }
                                    })}
                                  </td>
                                </tr>
                              ) : null}
                            </>
                          );
                        })
                      ) : (
                        <>
                          <tr>
                            <td>1</td>
                            <td>-</td>
                            <td>{errorCsvValidation.message}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </li>
              <li>2. {getObjectiveLocale('Correct these rows')}</li>
              <li>
                3.{' '}
                {getObjectiveLocale(
                  'Save the file, being sure to keep it in CSV format'
                )}
              </li>
              <li>4. {getObjectiveLocale('Reimport the CSV file')}</li>
            </ol>
          </div>
        ) : (
          <div className="flex mx-[10px] mt-[8px]">
            <SVGIcon
              iconName="icon-warning"
              fillColor="var(--y-600)"
              size="24"
              customClass="mr-[16px]"
            />
            <div className="flex flex-col">
              <p className="typography-h400 text-n-900 mb-[4px]">
                {getObjectiveLocale(
                  'The importing process takes longer than usual, and currently still in progress.'
                )}
              </p>
              <p className="typography-h400 text-n-900">
                {getObjectiveLocale(
                  'Please check again later if all the objectives has been uploaded before retrying.'
                )}
              </p>
            </div>
          </div>
        ))}
    </div>
  );

  return component;
}

export default UploadCsv;

import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { getTeamsCMSCulture, postCreateReminder } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Button from 'components/design-system/Button';
import Table from 'components/design-system/table/Table';
import SingleCalendar from 'components/shared/Calendar';
import Checkbox from 'components/shared/Checkbox';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SVGIcon from 'components/shared/SVGIcon';
import TimePickerV2 from 'components/shared/TimePickerV2';

import ToastNotif from '../shared/ToastNotif';

dayjs.extend(customParseFormat);

const CreateReminder = ({ history, route: { title, isReadOnly } }) => {
  const { config } = useUser();
  const [errorPage, setErrorPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    group: '',
    subject: '',
    body: '',
    sent_at: ''
  });
  const [isSuccessPost, setIsSuccessPost] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [groupData, setGroupData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const headers = [
    { name: '', widthClass: 'w-[64px]' },
    { name: 'Participant Team Name', widthClass: 'w-[300px]', grow: true },
    { name: 'Total', widthClass: 'w-[168px]', grow: true }
  ];

  // input state
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [teamId, setTeamId] = useState('');
  const [totalReminder, setTotalReminder] = useState([
    dayjs().add(1, 'days').startOf('day')
  ]);

  const addNewReminder = (newDate) => {
    setTotalReminder([...totalReminder, newDate]);
  };

  const deleteReminder = (date) => {
    setTotalReminder(totalReminder.filter((val) => val != date));
  };

  const changeRoute = () => {
    localStorage.setItem('prevUrlRedirect', `${location.pathname}`);
    history.replace('/add-participant');
  };

  const changePage = (type) => {
    let paginationKey;

    if (type == 'next') {
      paginationKey = 'olderThan';
      setCurrentPage(currentPage + 1);
    } else {
      paginationKey = 'newerThan';
      setCurrentPage(currentPage - 1);
      setHasMore(true);
    }

    fetchGroups({ limit: 10, [paginationKey]: pagination[paginationKey] });
  };

  const fetchGroups = async (params) => {
    const { data, pagination, error } = await getTeamsCMSCulture(params);
    if (data) {
      setGroupData(data);
    }
    if (pagination)
      setPagination({
        olderThan: data.length < 10 ? null : pagination.next.olderThan,
        newerThan: pagination.prev.newerThan
      });
    if (data.length < 10) setHasMore(false);

    if (error) return;
  };

  const handleCheckedGroup = (id) => {
    if (id === teamId) return true;
    else return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let groupMessage = '';
    let subjectMessage = '';
    let bodyMessage = '';
    let sent_atMessage = '';
    let isError = false;

    if (teamId === '') {
      isError = true;
      groupMessage = 'You have to select a group';
    }

    if (subject === '') {
      isError = true;
      subjectMessage = "Subject can't be empty";
    }

    if (body === '') {
      isError = true;
      bodyMessage = "Body can't be empty";
    }

    totalReminder.map((value) => {
      let now = dayjs();
      if (value === '' || value === undefined) {
        isError = true;
        sent_atMessage = "Sent at can't be empty";
      } else if (now - value > 0) {
        // datetime comparison between now and the inputed data
        isError = true;
        sent_atMessage =
          'Your chosen date and time is already passed, please choose after the current date and time';
      }
    });

    if (isError === false) {
      let params = {
        team_id: parseInt(teamId),
        times: totalReminder.map((value) => {
          return dayjs(value).toISOString();
        }),
        body: body,
        subject: subject
      };
      const { isSuccess } = await postCreateReminder(params);
      if (isSuccess) {
        setSubject('');
        setBody('');
        setTotalReminder([dayjs().add(1, 'days').startOf('day')]);
        setTeamId('');
        setIsSuccessPost(true);
        setSuccessMessage('Success Create Scheduled Email');
        setErrorPage(false);
        setErrorMessage({
          group: '',
          subject: '',
          body: '',
          sent_at: ''
        });
        window.scrollTo(0, 0);
        setTimeout(function () {
          setSuccessMessage('');
          setIsSuccessPost(false);
        }, 3000);
      }
    } else {
      setErrorPage(true);
      setErrorMessage({
        group: groupMessage,
        subject: subjectMessage,
        body: bodyMessage,
        sent_at: sent_atMessage
      });
    }
  };

  const handleDate = (type, datetime, index) => {
    let newDate = [...totalReminder];
    let date, time;

    if (type === 'date') {
      date = dayjs(datetime).format('YYYY-MM-DD');
      time = dayjs(newDate[index]).format('hh:mm:ss A');
    } else {
      date = dayjs(newDate[index]).format('YYYY-MM-DD');
      time = dayjs(datetime).format('hh:mm A');
    }

    newDate[index] = dayjs(date + ' ' + time, 'YYYY-MM-DD hh:mm A');
    setTotalReminder(newDate);
  };

  const headerProps = {
    titlePage: title,
    secondaryAction: config?.permissions?.cultureSettingsParticipantsSee !==
      'read_only' && {
      title: 'Create Participant Team',
      icon: 'icon-add',
      onClick: () => {
        changeRoute();
      }
    }
  };

  const disabledDate = (current) => {
    if (!current) {
      // allow empty select
      return false;
    }
    const date = dayjs();
    date.hour(0);
    date.minute(0);
    date.second(0);
    return current.valueOf() < date.valueOf();
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const sentAtComponent = totalReminder.map((value, index) => {
    return (
      <div
        className="input-list-container mb-[16px] flex items-center"
        key={index}
        id={index}
      >
        <div className="relative h-[32px] mr-[16px] flex">
          <SingleCalendar
            disabledDate={disabledDate}
            defaultValue={value}
            handleChangeCalendar={(date) => handleDate('date', date, index)}
            type="startDate"
            customBox="border-solid border-n-500 border bg-n-000 h-[32px] rounded-[4px]"
            calendarButtonType="no"
          />
        </div>
        <TimePickerV2
          selectedTime={value}
          handleChange={(time) => handleDate('time', time, index)}
        />
        <Button
          customClass="flex items-center"
          onClick={() => deleteReminder(value)}
        >
          <SVGIcon size="16" iconName="icon-delete" fillColor="var(--n-000)" />
        </Button>
      </div>
    );
  });

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="admin-culture create-reminder col-xs-12">
        <ToastNotif showNotif={isSuccessPost} message={successMessage} />
        <div id="dashboard_content col-xs-10" className="mt-[24px]">
          <div className="dashboard_content_main">
            <div className="form-group">
              <p className="typography-paragraph pb-[8px]">
                {getObjectiveLocale('Choose Participant Team (only one)')}
              </p>
              <Table headers={headers}>
                {groupData?.length !== 0 &&
                  groupData.map((group, index) => (
                    <Table.Row key={index}>
                      <Table.Column>
                        <Checkbox
                          id={index}
                          onChange={(e) =>
                            e.currentTarget.checked
                              ? setTeamId(group.id)
                              : setTeamId(null)
                          }
                          disabled={isReadOnly}
                          checked={handleCheckedGroup(group.id)}
                        />
                      </Table.Column>
                      <Table.Column>{group.name}</Table.Column>
                      <Table.Column>
                        {getNumberLocale(group.userCount)}
                      </Table.Column>
                    </Table.Row>
                  ))}
              </Table>
              {errorPage && errorMessage.group !== '' ? (
                <p className="error-message">{errorMessage.group}</p>
              ) : (
                ''
              )}
              <div className="float-right pt-[12px]">
                <PaginationModule
                  pageNumber={currentPage}
                  handlePrev={() => changePage('prev')}
                  handleNext={() => changePage('next')}
                  hasMore={hasMore}
                />
              </div>
            </div>
            <div className="form-group">
              <p
                className="typography-paragraph pb-[8px]"
                htmlFor="subject-email"
              >
                {getObjectiveLocale('Subject')}
              </p>
              <input
                onChange={(e) => setSubject(e.currentTarget.value)}
                id="subject-email"
                placeholder="Subject"
                className="form-control"
                value={subject}
              />
              {errorPage && errorMessage.subject !== '' ? (
                <p className="error-message">{errorMessage.subject}</p>
              ) : (
                ''
              )}
            </div>
            <div className="form-group">
              <p className="typography-paragraph pb-[8px]" htmlFor="body-email">
                {getObjectiveLocale('Body')}
              </p>
              <textarea
                onChange={(e) => setBody(e.currentTarget.value)}
                id="body-email"
                className="textarea-form"
                style={{ height: 200 }}
                placeholder="Email Content"
                value={body}
              />
              {errorPage && errorMessage.body !== '' ? (
                <p className="error-message">{errorMessage.body}</p>
              ) : (
                ''
              )}
            </div>
            <div className="form-group">
              <p className="typography-paragraph pb-[8px]">
                {getObjectiveLocale('Sent At')}
              </p>
              {sentAtComponent}
              <Button
                onClick={() =>
                  addNewReminder(dayjs().add(1, 'days').startOf('day'))
                }
              >
                <SVGIcon
                  iconName="icon-add"
                  size="20"
                  customClass="mr-[4px]"
                  fillColor="var(--n-000)"
                />
                {getObjectiveLocale('Add')}
              </Button>
              {errorPage && errorMessage.sent_at !== '' ? (
                <p className="error-message">{errorMessage.sent_at}</p>
              ) : (
                ''
              )}
            </div>
            <div className="mb-[16px]">
              <Button
                onClick={(e) => handleSubmit(e)}
                customClass="w-full py-[12px] "
              >
                {getObjectiveLocale('Submit')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(CreateReminder);

import React from 'react';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import { useGetTeams } from 'hooks/api/useTeams';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';

import Dropdown from 'src/components/design-system/dropdown/Dropdown';
import Trigger from 'src/components/design-system/dropdown/Trigger';
import ModalConfirmNewSquadMembers from 'src/components/modal/ModalConfirmNewSquadMembers';
import DynamicTabGrey from 'src/components/shared/Tabs/DynamicTabGrey';
import ProjectInvolvements from 'src/components/sidebar/sidebar-create/ProjectInvolvements';
import { useUser } from 'src/context/UserContext';

import BannerReset from './BannerReset';

const tabs = [
  {
    name: 'Choose Team'
  },
  {
    name: 'Add Member'
  }
];

const LIMIT = 15;

const ChooseTeamOrMember = ({
  objectiveValue,
  setObjectiveValue,
  currentTabMember,
  setCurrentTabMember,
  initialDefaultMembers,
  isMyProjectPage,
  showModalConfirmSquad,
  setShowModalConfirmSquad,
  checkNextStep,
  getNotInvolvedUsersInSquad,
  newSquadMembersIds,
  setNewSquadMembersIds
}) => {
  const { user } = useUser();
  const { url } = useUrl();
  const { team: selectedTeam } = objectiveValue;

  const disabledDropdownTeam = url?.includes('/teams');

  // ** Params for teams suggestions
  const params = {
    reviewsVisibility: 1,
    limit: LIMIT,
    involvement: ['pmo', 'leader', 'member'],
    involvedUserId: user?.id,
    teamType: 'squad'
  };

  // ** Get teams suggestions data on mount
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetTeams(
    'project-teams-suggestions',
    params
  );

  // ** Teams data
  const teams = flatten(data?.pages?.map((page) => page?.data?.map((d) => d)));

  const onScroll = (e) => {
    const target = e.target;

    if (!isFetchingNextPage && hasNextPage) {
      loadMoreValidator(target, 200, () => {
        fetchNextPage();
      });
    }
  };

  const onChangeTeam = (team) => {
    // ** Mapping team involvements as project involvements
    const newInvolvements = team?.involvements?.map((involvement) => ({
      extendedRole: involvement?.extendedRole || null,
      role: involvement?.role,
      user: involvement?.user,
      userId: involvement?.user?.id,
      visible: true
    }));

    setObjectiveValue((draft) => {
      draft.team = team;
      draft.teamId = team?.id;
      draft.involvements = newInvolvements;
    });
  };

  const showInvolvementsBanner = () => {
    if (currentTabMember === 'Choose Team') {
      return !isEmpty(selectedTeam);
    }

    return true;
  };

  return (
    <>
      {isMyProjectPage && (
        <div className="my-[16px]">
          <p className="mb-[8px] typography-h100 text-n-800">
            {getObjectiveLocale('Project member')}
          </p>
          <DynamicTabGrey
            listName={tabs}
            state={currentTabMember}
            setState={(tab) => {
              setCurrentTabMember(tab);
            }}
          />
        </div>
      )}

      {currentTabMember === 'Choose Team' && (
        <>
          <p className="typography-h100 text-n-800 mb-[8px]">
            {getObjectiveLocale('Team name')}
          </p>
          <Dropdown customClass="w-full mb-[16px]">
            <Dropdown.Trigger
              customClass="w-full"
              disabled={disabledDropdownTeam}
            >
              <Trigger
                icon={false}
                topLabel={false}
                label={false}
                iconName="icon-arrow_drop_down"
                text={selectedTeam?.name}
                customWrapperClass="w-full"
                disabled={disabledDropdownTeam}
              />
            </Dropdown.Trigger>
            <Dropdown.MenuItems
              customClass="w-[552px]"
              maxHeight={200}
              onScroll={onScroll}
            >
              {teams?.map((team) => (
                <Dropdown.MenuItem
                  key={team?.id}
                  onClick={() => onChangeTeam(team)}
                >
                  {team?.name}
                </Dropdown.MenuItem>
              ))}
            </Dropdown.MenuItems>
          </Dropdown>
        </>
      )}

      {showInvolvementsBanner() && (
        <>
          <BannerReset
            initialDefaultMembers={initialDefaultMembers}
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            isTeam={currentTabMember === 'Choose Team'}
          />
          <ProjectInvolvements
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            useFloating
          />
        </>
      )}
      {showModalConfirmSquad && (
        <ModalConfirmNewSquadMembers
          teamData={objectiveValue?.team}
          getNotInvolvedUsersInSquad={getNotInvolvedUsersInSquad}
          onClose={() => setShowModalConfirmSquad(false)}
          checkNextStep={checkNextStep}
          newSquadMembersIds={newSquadMembersIds}
          setNewSquadMembersIds={setNewSquadMembersIds}
        />
      )}
    </>
  );
};

export default ChooseTeamOrMember;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  FormalReviewProvider,
  useFormalReview
} from 'context/FormalReviewContext';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import SidebarLeft from 'components/formal-review/SidebarLeft';
import PerformanceRating from 'components/formal-review/performance-rating/PerformanceRating';
import LoadingSplashScreen from 'components/shared/LoadingSplashScreen';

function TeamReview({ match, history }) {
  const [isLeaderReview, setIsLeaderReview] = useState(false);
  const [
    { ready, status, cycle, involvedUser, currentTrack, tracks, phase },
    { getSingleFormalReview }
  ] = useFormalReview();
  const { actor, target } = involvedUser;

  const assignmentId = match.params.assignmentId;

  const getTeamReviewData = async () => {
    getSingleFormalReview(assignmentId);
  };

  useEffect(() => {
    getTeamReviewData();
  }, []);

  return ready && tracks ? (
    <>
      <HeaderPage
        isHeaderComposer
        titlePage={cycle?.name}
        backToUrl="/appraisals"
      />
      <div className="formal-review">
        <div className="row-formal-review-scoring">
          <SidebarLeft />
          <div className="col-md-10">
            <div className="team-review-rating">
              <span className="title-review">
                {isLeaderReview
                  ? getObjectiveLocale('Team Leader Review')
                  : phase.type !== 'peer_review'
                  ? getObjectiveLocale('Team Member Review')
                  : getObjectiveLocale('Peer Review')}
              </span>
              {currentTrack == 'performance_rating' && (
                <PerformanceRating
                  assignmentId={assignmentId}
                  type={currentTrack}
                  target={target}
                  isTeamCycle={cycle?.team?.id}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoadingSplashScreen />
  );
}

function TeamReviewComponent(props) {
  return (
    <FormalReviewProvider>
      <TeamReview {...props} />
    </FormalReviewProvider>
  );
}

export default TeamReviewComponent;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import get from 'lodash/get';

import { getScorings } from 'client/Scoring';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Modal from 'components/shared/modal/Modal';

import {
  getReview,
  submitReview,
  updateReview
} from '../../client/ObjectiveReviewClient';
import ObjectiveRating from './ObjectiveRating';
import ReviewSummaryComponent from './ReviewSummaryComponent';
import SkillReview from './SkillReview';
import ObjectiveAmplitude from './amplitude/ObjectiveAmplitude';

const getAssignee = (key, assigneeIds) => {
  return assigneeIds
    .filter((data) => {
      return data.role == 'assignee';
    })
    .map((data) => {
      return get(data, key);
    });
};

function ObjectiveReviewPopup({ objectiveDetail, onCloseModal }) {
  let history = useHistory();
  const { refetchObjectives, refetchObjective } = useRefetchQuery();
  const [reviewId, setReviewId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [reviewComment, setReviewComment] = useState('');
  const [askForStrength, setAskForStrength] = useState(false);
  const [askForWeakness, setAskForWeakness] = useState(false);
  const [requireForStrength, setRequireForStrength] = useState(false);
  const [requireForWeakness, setRequireForWeakness] = useState(false);
  const [selectedRecognition, setSelectedRecognition] = useState(null);
  const [recognitionComment, setRecogntionComment] = useState('');
  const [feedbackComment, setFeedbackComment] = useState('');
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [questionsTemplate, setQuestionsTemplate] = useState(null);
  const showLabel = questionsTemplate?.showOption;
  const { config } = useUser();
  const { url } = useUrl();
  const { reload, reloadSidebar, reloadNeedResponseNotifCount } = useReload();
  const { workAttributes, scoringMechanism } = questionsTemplate || [];
  const useWorkAttribute = scoringMechanism == 'work_attributes' ? true : false;
  const listWorkAttribute = workAttributes || [];
  const customIcon = config.customIconOnReview;
  const [currentWorkAttribute, setCurrentWorkAttribute] = useState(0);
  const [tempWorkData, setTempWorkData] = useState([]);
  const [loading, setLoading] = useState(false);

  const recognitionType = currentIndex === 1;
  let headerText = getObjectiveLocale('Quick Insight');
  let confirmButtonText = getObjectiveLocale('Next');
  const { involvements } = objectiveDetail;
  const listOwner = involvements?.filter(
    ({ role, visible }) => role == 'assignee' && visible
  );

  switch (currentIndex) {
    case 0:
      if (useWorkAttribute && !selectedOptionId) {
        confirmButtonText = getObjectiveLocale('Skip');
      }
      break;

    case 1:
      headerText = getObjectiveLocale('Give Recognition');
      if (!selectedRecognition && !requireForStrength) {
        confirmButtonText = getObjectiveLocale('Skip');
      }
      break;

    case 2:
      headerText = getObjectiveLocale('Give Feedback');
      if (!selectedFeedback && !requireForWeakness) {
        confirmButtonText = getObjectiveLocale('Skip');
      }
      break;

    case 3:
      headerText = getObjectiveLocale('Summary');
      confirmButtonText = getObjectiveLocale('Complete');
      break;
  }

  const _completeReview = async (body) => {
    const { data } = await completeReview(body);
    if (data) {
      onCloseModal();
      reload({ reloadSidebar: !reloadSidebar });
      if (url.includes('reviews') || url.includes('tree')) {
        refetchObjective(objectiveDetail.id);
        if (reloadSidebar) reload({ reloadSidebar: !reloadSidebar });
      } else if (objectiveDetail.parent) {
        onCloseModal();
        refetchObjective(objectiveDetail.id);
      } else {
        if (url.includes('projects')) history.replace('/goals');
        else refetchObjectives();
      }
    }
    reload({ reloadNeedResponseNotifCount: !reloadNeedResponseNotifCount });
  };

  const onComplete = async () => {
    tempWorkData.forEach(function (v) {
      delete v.name;
    });

    const body = useWorkAttribute
      ? {
          workAttributes: tempWorkData.filter((data) => data.scoringMarkId)
        }
      : {
          scoringMarkIds: [tempWorkData[0].scoringMarkId],
          comment: tempWorkData[0].comment
        };

    if (selectedRecognition) {
      body.strength = selectedRecognition?.id
        ? {
            comment: recognitionComment,
            behaviorId: selectedRecognition?.id
          }
        : {
            comment: recognitionComment,
            skill: selectedRecognition?.title
          };
    }
    if (selectedFeedback) {
      body.weakness = selectedFeedback?.id
        ? { comment: feedbackComment, behaviorId: selectedFeedback?.id }
        : { comment: feedbackComment, skill: selectedFeedback?.title };
    }
    await _completeReview(body);
  };

  const completeReview = async (body) => {
    setLoading(true);

    ObjectiveAmplitude.sendAmplitudeIdentifyStrengthAndSuggestImprovement(
      body,
      objectiveDetail
    );
    if (reviewId) {
      let { data } = await updateReview(objectiveDetail.id, reviewId, body);
      if (data) {
        ObjectiveAmplitude.sendAmplitudeReviewTaskGoals(data);
      }
      setLoading(false);
      return { data };
    } else {
      let { data } = await submitReview(objectiveDetail.id, body);
      if (data) {
        ObjectiveAmplitude.sendAmplitudeReviewTaskGoals(data);
      }
      setLoading(false);
      return { data };
    }
  };

  const _getReview = async () => {
    const lastReview =
      objectiveDetail.reviews[objectiveDetail.reviews.length - 1];
    const { data } = await getReview(objectiveDetail.id, lastReview.id);
    if (data) {
      const temp = [
        {
          scoringMarkId: data?.reviewChoices[0]?.scoringMark?.id,
          name: data?.reviewChoices[0]?.scoringMark?.name,
          comment: data?.comment
        }
      ];
      setTempWorkData(temp);

      setReviewId(lastReview.id);
      setSelectedOptionId(data?.scoringMark?.id);
      setReviewComment(data.comment);
      setAskForStrength(data?.scoringMark?.askForStrength);
      setAskForWeakness(data?.scoringMark?.askForWeakness);
      setRequireForStrength(data?.scoringMark?.requireForStrength);
      setRequireForWeakness(data?.scoringMark?.requireForWeakness);
      setSelectedRecognition(data.strength ? data.strength.skill : null);
      setRecogntionComment(data.strength ? data.strength.comment : '');
      setSelectedFeedback(data.weakness ? data.weakness.skill : null);
      setFeedbackComment(data.weakness ? data.weakness.comment : '');
      setCurrentIndex(3);
    }
  };

  const getData = async () => {
    let params = {
      placementId: listOwner[0]?.placementId,
      objectiveCategoryId: objectiveDetail?.objectiveCategory?.id || null
    };
    const { data } = await getScorings(objectiveDetail?.type, params);
    if (data) {
      setQuestionsTemplate(data);

      if (objectiveDetail.reviews.length > 0) {
        const isWorkAttribute = data.scoringMechanism === 'work_attributes';

        if (isWorkAttribute) {
          setCurrentIndex(3);
          setReviewId(
            objectiveDetail.reviews[objectiveDetail.reviews.length - 1].id
          );
          let temps = data.workAttributes;
          const workAttributeDataIds = temps.map((data) => {
            return data.id;
          });
          objectiveDetail.reviews.map((attribute) => {
            if (
              attribute &&
              workAttributeDataIds.includes(
                attribute.reviewChoices?.[0]?.workAttribute?.id
              )
            ) {
              const index = temps.findIndex(
                (temp) =>
                  temp.id === attribute.reviewChoices?.[0]?.workAttribute?.id
              );
              temps[index].scoringMarkId =
                attribute.reviewChoices?.[0]?.scoringMark?.id;
              temps[index].comment = attribute.comment;
            }
          });
          setTempWorkData(temps);
          setCurrentWorkAttribute(objectiveDetail.reviews.length);
        } else {
          _getReview();
        }
      }
    }
  };

  const newTempWorkData = (temp) => {
    if (temp.some((e) => e.id === listWorkAttribute[currentWorkAttribute].id)) {
      temp[currentWorkAttribute] = {
        scoringMarkId: selectedOptionId,
        comment: reviewComment,
        id: listWorkAttribute[currentWorkAttribute].id,
        name: listWorkAttribute[currentWorkAttribute].name
      };
    } else {
      temp.push({
        scoringMarkId: selectedOptionId,
        comment: reviewComment,
        id: listWorkAttribute[currentWorkAttribute].id,
        name: listWorkAttribute[currentWorkAttribute].name
      });
    }
    if (
      temp.some((e) => e.id === listWorkAttribute[currentWorkAttribute + 1]?.id)
    ) {
      setSelectedOptionId(
        tempWorkData[currentWorkAttribute + 1]?.scoringMarkId
      );
      setReviewComment(tempWorkData[currentWorkAttribute + 1].comment);
    } else {
      setSelectedOptionId(null);
      setReviewComment('');
    }
    return temp;
  };

  const setNextIndex = () => {
    switch (currentIndex) {
      case 0:
        setTempWorkData([
          {
            scoringMarkId: selectedOptionId,
            comment: reviewComment
          }
        ]);
        if (askForStrength) {
          setCurrentIndex(1);
        } else {
          setCurrentIndex(askForWeakness ? 2 : 3);
        }
        break;

      case 1:
        setCurrentIndex(askForWeakness ? 2 : 3);
        break;

      case 2:
        setCurrentIndex(3);
        break;

      case 3:
        onComplete();
        break;
    }
  };

  const onClickNext = () => {
    if (!isAllowContinue()) {
      return null;
    }

    if (useWorkAttribute) {
      if (currentIndex == 3) {
        onComplete();
      } else {
        if (currentWorkAttribute == listWorkAttribute.length - 1) {
          setCurrentIndex(3);
        }
        let temp = newTempWorkData(tempWorkData);
        setCurrentWorkAttribute(currentWorkAttribute + 1);
        setTempWorkData(temp);
      }
    } else {
      setNextIndex();
    }
  };

  const onClickBack = () => {
    if (useWorkAttribute) {
      setSelectedOptionId(
        !(currentWorkAttribute - 1 < 0)
          ? tempWorkData[currentWorkAttribute - 1]?.scoringMarkId
          : null
      );
      setReviewComment(
        !(currentWorkAttribute - 1 < 0)
          ? tempWorkData[currentWorkAttribute - 1].comment
          : ''
      );
      if (currentIndex == 3) {
        setCurrentWorkAttribute(listWorkAttribute.length - 1);
        setCurrentIndex(0);
      } else if (currentWorkAttribute == 0) {
        onCloseModal();
      } else {
        setCurrentWorkAttribute(currentWorkAttribute - 1);
      }
    } else {
      switch (currentIndex) {
        case 0:
          onCloseModal();
          break;
        case 1:
          setCurrentIndex(0);
          break;
        case 2:
          if (askForStrength) {
            setCurrentIndex(1);
          } else if (!askForStrength) {
            setCurrentIndex(0);
          }
          break;
        case 3:
          if (askForWeakness) {
            setCurrentIndex(2);
          } else if (!askForWeakness && askForStrength) {
            setCurrentIndex(1);
          } else if (!askForWeakness && !askForStrength) {
            setCurrentIndex(0);
          }
          break;
        default:
          break;
      }
    }
  };

  const isAllowContinue = () => {
    let flag;
    if (useWorkAttribute) {
      let emptyTemp =
        tempWorkData.filter((data) => data?.scoringMarkId !== null).length == 0;
      let lastWorkAttribute =
        currentWorkAttribute == listWorkAttribute.length - 1;
      if (lastWorkAttribute && emptyTemp)
        flag = selectedOptionId ? true : false;
      else flag = true;
    } else {
      switch (currentIndex) {
        case 0:
          flag = selectedOptionId ? true : false;
          break;
        case 1:
          flag =
            !requireForStrength || (requireForStrength && selectedRecognition);
          break;
        case 2:
          flag =
            !requireForWeakness || (requireForWeakness && selectedFeedback);
          break;
        case 3:
          return !loading;
      }
    }
    return flag;
  };

  const onChangeNavigation = (tab) => {
    if (!useWorkAttribute) {
      switch (tab) {
        case 'review':
          setCurrentIndex(0);
          break;
        case 'recognition':
          setCurrentIndex(1);
          break;
        case 'feedback':
          setCurrentIndex(2);
          break;
        default:
          break;
      }
    } else {
      setCurrentIndex(0);
      setSelectedOptionId(tab?.scoringMarkId);
      setReviewComment(tab.comment);
      const tempKey = listWorkAttribute.findIndex((data) => data.id === tab.id);
      setCurrentWorkAttribute(tempKey);
    }
  };

  const clearData = () => {
    setSelectedRecognition(null);
    setSelectedFeedback(null);
    setRecogntionComment('');
    setFeedbackComment('');
  };

  useEffect(() => {
    getData();
  }, []);

  let progressBar = 0;
  if (useWorkAttribute) {
    let tempId = 100 / (listWorkAttribute.length + 1);
    progressBar = Math.ceil((currentWorkAttribute + 1) * tempId);
  } else {
    progressBar = 25 * (currentIndex + 1);
  }
  progressBar = progressBar > 100 ? 100 : progressBar;

  return (
    <Modal
      title={
        useWorkAttribute && currentIndex == 0
          ? getObjectiveLocale('Faktor Penilaian')
          : headerText
      }
      eventOnClickClose={onCloseModal}
      className="form-review-component modal-objective-review"
      withFooter={false}
    >
      <div className="body-modal">
        <div className="progress-bar-wrapper">
          <div className={`progress-bar progress-${progressBar}`}></div>
        </div>
        {currentIndex === 0 && (
          <ObjectiveRating
            listWorkAttribute={listWorkAttribute}
            currentId={currentWorkAttribute}
            useWorkAttribute={useWorkAttribute}
            selectedOptionId={selectedOptionId}
            reviewComment={reviewComment}
            onChangeComment={setReviewComment}
            questionsTemplate={questionsTemplate}
            setSelectedOptionId={setSelectedOptionId}
            setAskForStrength={setAskForStrength}
            setAskForWeakness={setAskForWeakness}
            setRequireForStrength={setRequireForStrength}
            setRequireForWeakness={setRequireForWeakness}
            clearData={clearData}
            objective={objectiveDetail}
            customIcon={customIcon}
            showLabel={showLabel}
          />
        )}
        {(currentIndex === 1 || currentIndex === 2) && (
          <SkillReview
            placementId={listOwner[0]?.placementId}
            type={recognitionType ? 'Recognition' : 'Feedback'}
            selectedSkill={
              recognitionType ? selectedRecognition : selectedFeedback
            }
            onClickSkill={
              recognitionType ? setSelectedRecognition : setSelectedFeedback
            }
            comment={recognitionType ? recognitionComment : feedbackComment}
            onChangeComment={
              recognitionType ? setRecogntionComment : setFeedbackComment
            }
            question={
              recognitionType
                ? questionsTemplate.recognitionQuestion
                : questionsTemplate.feedbackQuestion
            }
            ownerIds={getAssignee('user.id', objectiveDetail.involvements)}
          />
        )}
        {currentIndex === 3 && (
          <ReviewSummaryComponent
            listOfSelected={tempWorkData}
            useWorkAttribute={useWorkAttribute}
            selectedOptionId={selectedOptionId}
            reviewComment={reviewComment}
            selectedRecognition={selectedRecognition}
            recognitionComment={recognitionComment}
            selectedFeedback={selectedFeedback}
            feedbackComment={feedbackComment}
            options={questionsTemplate.scoringComponents[0]?.scoringMarks}
            onChangeNavigation={onChangeNavigation}
            ownerName={getAssignee('user', objectiveDetail.involvements)}
            objective={objectiveDetail}
            useCustomIcon={customIcon}
            title={questionsTemplate.scoringComponents[0].question}
          />
        )}
      </div>
      <div className="footer-modal-objective mb-[12px]">
        <div
          style={{
            visibility:
              currentWorkAttribute == 0 && currentIndex == 0 && 'hidden'
          }}
        >
          <Button.Tertiary onClick={() => onClickBack()} datacy="back-button">
            <i className="fa fa-chevron-left" /> {getObjectiveLocale('Back')}
          </Button.Tertiary>
        </div>
        <Button
          datacy="confirm-button-container"
          disabled={!isAllowContinue()}
          onClick={() => onClickNext()}
        >
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  );
}

export default ObjectiveReviewPopup;

import React, { useRef, useState } from 'react';

import Dropdown, {
  useDropdown
} from 'components/design-system/dropdown/Dropdown';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
// TEMPORARY COMMENT - might be used in next story
// import { useLocation } from 'react-router-dom';
// import { getObjectiveLocale } from 'utils/HelperUtils';
import Badge from 'components/shared/Badge';
import ConditionalWrapper from 'components/shared/ConditionalWrapper';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const PriorityDropdown = ({
  task,
  useTrigger = false,
  beforeTrigger,
  position = 'left',
  useCustomTrigger,
  useFloating = true,
  onChange,
  listPriorities = [],
  type = 'list'
}) => {
  const ref = useRef();
  // TEMPORARY COMMENT - might be used in next story
  // const { pathname } = useLocation();
  const { open } = useDropdown();

  const { priority } = task || {};
  const { name, colorHex, bgColorHex } = priority || {};

  // TEMPORARY COMMENT - might be used in next story
  // const isProjectPage = pathname.includes('project');

  return (
    <>
      {useTrigger && (
        <Dropdown.Trigger
          customClass="w-full h-full"
          beforeTrigger={beforeTrigger}
          dataCy="trigger-dialog-priority"
        >
          {useCustomTrigger ? (
            useCustomTrigger
          ) : (
            <TooltipContainer
              show={!open}
              text={priority ? `Priority: ${name}` : 'Add priority'}
              classContainer={
                type === 'list'
                  ? 'w-full h-full flex items-center px-[16px] group'
                  : 'mt-[8px] mr-[8px]'
              }
              position="bottom"
            >
              {priority ? (
                <>
                  <Badge
                    defaultPointer
                    content={name}
                    colorHex={colorHex}
                    bgColorHex={bgColorHex}
                    className="pointer-events-none"
                  />
                  {type === 'list' ? (
                    <div className="icon-clear-container absolute w-[48px] h-full flex items-center justify-center right-0 hidden group-hover:flex">
                      <SVGIcon
                        iconName="icon-clear"
                        size="24"
                        fillColor="var(--n-600)"
                        onClick={() => onChange(null, null)}
                      />
                    </div>
                  ) : (
                    <div className="w-full h-full" ref={ref} />
                  )}
                </>
              ) : (
                type === 'board' && (
                  <SVGIcon
                    iconName="icon-flag"
                    size="20"
                    fillColor="var(--n-600)"
                  />
                )
              )}
            </TooltipContainer>
          )}
        </Dropdown.Trigger>
      )}

      {open && (
        <ConditionalWrapper
          condition={useFloating}
          wrapper={(children) => (
            <FloatingComponent lockScroll={false} customContentHeight={320}>
              {children}
            </FloatingComponent>
          )}
        >
          <Dropdown.MenuItems
            type="absolute"
            position={position}
            useScrollClose
          >
            <div
              className="overflow-hidden hover:overflow-auto hover:overflow-overlay max-h-[287px]"
              // TEMPORARY COMMENT - might be used in next story
              // className={`overflow-hidden hover:overflow-auto hover:overflow-overlay max-h-[287px] ${
              //   isProjectPage
              //     ? 'border-solid border-0 border-b border-n-300 pb-[8px]'
              //     : ''
              // }`}
            >
              {listPriorities?.map((value, index) => (
                <MenuItem onChange={onChange} value={value} key={index} />
              ))}
            </div>
            {/* TEMPORARY COMMENT - might be used in next story */}
            {/* {isProjectPage && (
              <Dropdown.MenuItem
                customClass="w-[192px] h-[32px] mt-[4px]"
                onClick={() => setShowModalEditPhase(true)}
              >
                <SVGIcon
                  size="24"
                  iconName="icon-add"
                  fillColor="var(--base-600)"
                />
                <p className="typography-button text-base-600">
                  {getObjectiveLocale('Add priority')}
                </p>
              </Dropdown.MenuItem>
            )} */}
          </Dropdown.MenuItems>
        </ConditionalWrapper>
      )}
    </>
  );
};

const MenuItem = ({ value, onChange }) => {
  const [isEllipsis, setIsEllipsis] = useState(false);
  return (
    <Dropdown.MenuItem
      customClass="w-[192px] h-[36px]"
      onClick={() => onChange(value.id, value.name)}
      dataCy="menu-item-priority"
    >
      <TooltipContainer show={isEllipsis} text={value.name}>
        <Badge
          defaultPointer
          content={value.name}
          colorHex={value.colorHex}
          bgColorHex={value.bgColorHex}
          className="pointer-events-none"
          customMaxWidth="max-w-[160px]"
          truncate
          setIsEllipsis={setIsEllipsis}
        />
      </TooltipContainer>
    </Dropdown.MenuItem>
  );
};

const PriorityDropdownComponent = (props) => {
  return (
    <Dropdown autoOpen={!props.useTrigger} customClass={props.customClass}>
      <PriorityDropdown {...props} />
    </Dropdown>
  );
};

export default PriorityDropdownComponent;

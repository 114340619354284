import React from 'react';

import dayjs from 'dayjs';
import produce, { setAutoFreeze } from 'immer';

import { useUser } from 'context/UserContext';

//CREATE CONTEXT
const ProfileFilterContext = React.createContext();
//PROVIDER
function ProfileFilterProvider(props) {
  const { isBca } = useUser();
  //INITIAL STATE
  let initialState = {
    filter: {
      periodBegin: dayjs().startOf('day').toISOString(),
      periodEndBefore: dayjs().endOf('day').toISOString()
    },
    year: new Date().getFullYear()
  };

  let bcaFilter = {
    filter: {
      periodBegin: dayjs().startOf('year').toISOString(),
      periodEndBefore: dayjs().endOf('year').toISOString()
    },
    year: new Date().getFullYear()
  };

  initialState = isBca ? bcaFilter : initialState;

  const [state, setState] = React.useState(initialState);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];

  return <ProfileFilterContext.Provider value={contextValue} {...props} />;
}

//MUTATION
function useProfileFilter() {
  const [{ filter, year }, immerSetState] =
    React.useContext(ProfileFilterContext);

  const setFilter = (data) => {
    immerSetState((draft) => {
      draft.filter = data;
    });
  };

  const setYear = (data) => {
    immerSetState((draft) => {
      draft.year = data;
    });
  };

  return {
    year,
    filter,
    setFilter,
    setYear
  };
}

export { ProfileFilterProvider, useProfileFilter };

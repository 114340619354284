import React from 'react';

import dayjs from 'dayjs';

import Config from 'utils/Config';

import AWSImage from 'components/design-system/AWSImage';

function PerformanceReportTeamComponent(props) {
  const { data, setDetailInfo } = props;
  const defaultPicture = Config.asset.general.defaultPicture;
  const maxAvatar = 8;

  let leaderStyle = {
    color: '#FFFFFF',
    backgroundColor: 'var(--base-600)'
  };

  return (
    <div className="list-team-card">
      <div className="list-team-card-header">
        <div className="team-goal-title-container">
          <div className="team-goal-title">{data.name}</div>
          <div className="team-goal-member-status-container">
            {data.involvement && (
              <div
                className="team-goal-member-status"
                style={
                  data.involvement && data.involvement.role === 'leader'
                    ? leaderStyle
                    : null
                }
              >
                {data.involvement && data.involvement.role}
              </div>
            )}
          </div>
        </div>
        <div className="team-goal-rating-container">
          <div className="rating-star-and-count-container">
            <i className="fa fa-star small" />
            <div className="total-rating-star">
              {data.averageScore && data.averageScore.toFixed(1)}
            </div>
          </div>
          <div className="dot-divider" />
          <span
            className="team-goal-rating"
            style={
              data.scoringOption && { color: `#${data.scoringOption.colorHex}` }
            }
          >
            {data.scoringOption && data.scoringOption.name}
          </span>
        </div>
      </div>
      <div className="list-team-card-content">
        {data.cycles.map((cycle, index) => (
          <div
            key={index}
            className="list-team-period-card"
            onClick={() => {
              setDetailInfo(data, cycle);
            }}
          >
            <div className="title">{cycle.name}</div>
            <div className="rating-star-and-count-container">
              <i className="fa fa-star small" />
              <div className="total-rating-star">{cycle.score.toFixed(1)}</div>
              <i className="fa fa-chevron-right" />
            </div>
          </div>
        ))}
      </div>
      <div className="list-team-card-footer">
        <div className="list-team-card-footer-container">
          {data.involvement && (
            <div className="date-join">
              Joined{' '}
              {data.involvement &&
                dayjs(data.involvement.createdAt).format('DD MMMM YYYY')}
            </div>
          )}
          <div className="involve-container">
            {data.members &&
              data.members
                .filter((member) => member != null)
                .map((member, idx) => {
                  if (idx < maxAvatar) {
                    return (
                      <AWSImage
                        key={idx}
                        className="img-involvement img-circle"
                        src={
                          member.profilePicture === null
                            ? defaultPicture
                            : member.profilePicture
                        }
                        dataName={member.name}
                      />
                    );
                  } else if (
                    data.members.length > maxAvatar &&
                    idx === maxAvatar
                  ) {
                    return (
                      <span className="counter-involvement">{`+${
                        data.members.length - (maxAvatar + 1)
                      }`}</span>
                    );
                  }
                })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PerformanceReportTeamComponent;

import React from 'react';

function Question({ question, children }) {
  return (
    <div className="question">
      <h5 className="question-title typography-h500 mb-[16px]">{question}</h5>
      {children}
    </div>
  );
}

export default Question;

import React, { useState } from 'react';

import produce, { setAutoFreeze } from 'immer';

import {
  getSkillValueStrength,
  getSkillValueWeakness
} from 'client/UserProfile';
import { useUser } from 'context/UserContext';
import { loadMoreValidator } from 'utils/HelperUtils';

import { useProfileFilter } from './ProfileFilterContext';

//CREATE CONTEXT
const ProfileSkillContext = React.createContext();

//PROVIDER
function ProfileSkillProvider(props) {
  //INITIAL STATE
  const initialState = {
    strengths: [],
    weaknesses: [],
    strengthOffset: 0,
    weaknessOffset: 0
  };

  const [state, setState] = React.useState(initialState);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];

  return <ProfileSkillContext.Provider value={contextValue} {...props} />;
}

//MUTATION
function useProfileSkill() {
  const [onLoadMore, setOnLoadMore] = useState(false);
  const { filter } = useProfileFilter();
  const [
    { strengths, weaknesses, weaknessOffset, strengthOffset },
    immerSetState
  ] = React.useContext(ProfileSkillContext);

  const { appType } = useUser();

  const getStrengthData = async (userId, offset) => {
    const query = {
      ...filter,
      limit: 10,
      offset: offset,
      ...(appType === 'culture' && { source: 'culture' })
    };
    const { data, pagination } = await getSkillValueStrength(
      query,
      userId,
      'competency'
    );

    if (data) {
      immerSetState((draft) => {
        draft.strengths = offset ? [...strengths, ...data] : data;
        draft.strengthOffset = pagination?.nextOffset;
      });
    }
  };

  const getWeaknessData = async (userId, offset) => {
    const query = {
      ...filter,
      limit: 10,
      offset: offset
    };

    const { data, pagination } = await getSkillValueWeakness(
      query,
      userId,
      'competency'
    );

    if (data) {
      immerSetState((draft) => {
        draft.weaknesses = offset ? [...weaknesses, ...data] : data;
        draft.weaknessOffset = pagination?.nextOffset;
      });
    }
  };

  const scroll = (e, type, userId, offset) => {
    const target = e.target;

    const loadMore = async () => {
      setOnLoadMore(true);
      type == 'strength'
        ? await getStrengthData(userId, offset)
        : await getWeaknessData(userId, offset);
      setOnLoadMore(false);
    };

    const hasMore = (type == 'strength' ? strengthOffset : weaknessOffset)
      ? true
      : false;

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 50, () => {
        loadMore();
      });
    }
  };

  return {
    strengths,
    weaknesses,
    strengthOffset,
    weaknessOffset,
    getStrengthData,
    getWeaknessData,
    scroll
  };
}

export { ProfileSkillProvider, useProfileSkill };

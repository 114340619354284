import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useLocale } from 'hooks/useLocale';

import DateUntilRecurring from 'components/surveys/survey-schedule/fragments/DateUntilRecurring';
import Trigger from 'src/components/design-system/dropdown/Trigger';
import DropdownCustomEvery from 'src/components/dropdown/DropdownCustomEvery';
import DropdownRepetition from 'src/components/dropdown/DropdownRepetition';
import usePulseSurvey from 'src/hooks/usePulseSurvey';

dayjs.extend(utc);

const RepetitionTrigger = ({ value, disabled }) => (
  <Trigger
    icon={false}
    topLabel={false}
    label={false}
    text={value}
    customWrapperClass="w-[100%]"
    disabled={disabled}
    {...(disabled && { customTextClass: '!text-n-600' })}
  />
);

const repetitionKey = {
  daily: 'day',
  weekly: 'week',
  monthly: 'month',
  yearly: 'year',
  custom: 'custom'
};

const RecurringCard = ({ isReadOnly }) => {
  const { surveyData, setSurveyData, nextSurvey, setDesignatedUntilDate } =
    usePulseSurvey();
  const { recurrence: recurrenceData } = surveyData;
  const { setBothLocale } = useLocale();
  const repetitionValue = useMemo(
    () => recurrenceData?.every || 'day',
    [recurrenceData]
  );
  const { pathname } = useLocation();

  const intervalValue = useMemo(
    () => recurrenceData?.interval || 1,
    [recurrenceData]
  );
  const [recurringBoxValue, setRecurringBoxValue] = useState(
    intervalValue > 1 ? 'custom' : 'day'
  );

  const changeRecurrence = (every, intervalValue) => {
    const interval = intervalValue ? intervalValue : recurrenceData.interval;
    setSurveyData({
      ...surveyData,
      recurrence: {
        ...recurrenceData,
        every,
        interval
      }
    });
    setDesignatedUntilDate({
      startTime: surveyData?.startTime,
      expiration: surveyData?.expiration,
      repetition: every,
      interval
    });
  };

  const handleChange = (text) => {
    setRecurringBoxValue(text.toLowerCase());
    if (text.toLowerCase() == 'custom') return;
    changeRecurrence(repetitionKey?.[text.toLowerCase()], 1);
  };

  const handleChangeRepetition = (text) => {
    changeRecurrence(repetitionKey?.[text.toLowerCase()]);
  };

  const handleChangeInterval = (interval) => {
    setSurveyData({
      ...surveyData,
      recurrence: {
        ...recurrenceData,
        interval
      }
    });
    setDesignatedUntilDate({
      startTime: surveyData?.startTime,
      expiration: surveyData?.expiration,
      repetition: recurrenceData?.every,
      interval
    });
  };

  return (
    <div className=" pb-[16px] px-[16px]">
      <div className="flex items-end flex-wrap">
        <div className="flex flex-col w-[200px]">
          <p className="typography-h100 text-n-800 mb-[8px]">
            {setBothLocale('The survey will repeat every')}
          </p>
          <DropdownRepetition
            handleChange={handleChange}
            defaultValue={intervalValue > 1 ? 'custom' : 'day'}
            isTask={false}
            customTrigger={RepetitionTrigger}
            customClassItems="w-[200px]"
            maxHeightItems={120}
            disabled={isReadOnly}
            overrideOptionValues={[
              'daily',
              'weekly',
              'monthly',
              'yearly',
              'custom'
            ]}
          />
        </div>
        {recurringBoxValue == 'custom' && (
          <>
            <div className="flex flex-col ml-[8px]">
              <p className="typography-h100 text-n-800 mb-[8px]">
                {setBothLocale('Interval')}
              </p>
              <DropdownCustomEvery
                handleChange={handleChangeInterval}
                disabled={isReadOnly}
                triggerCustomClass="w-[160px]"
                itemsCustomClass="w-[160px]"
                defaultValue={intervalValue}
                customTextClass={isReadOnly ? 'text-n-600' : ''}
              />
            </div>
            <div className="flex flex-col ml-[8px] w-[160px]">
              <p className="typography-h100 text-n-800 mb-[8px]">
                {setBothLocale('Recurrence')}
              </p>
              <DropdownRepetition
                handleChange={handleChangeRepetition}
                defaultValue={repetitionValue}
                isTask={false}
                customTrigger={RepetitionTrigger}
                customClassItems="w-[160px]"
                maxHeightItems={120}
                disabled={isReadOnly}
                overrideOptionValues={['daily', 'weekly', 'monthly', 'yearly']}
              />
            </div>
          </>
        )}
        <div className="relative flex flex-col ml-[8px]">
          <p className="typography-h100 text-n-800 mb-[8px]">
            {setBothLocale('Until')}
          </p>
          <DateUntilRecurring
            isReadOnly={pathname.includes('survey-details')}
            isEditing={pathname.includes('edit-pulse-survey')}
          />
        </div>
      </div>
      <div className={`mt-[12px] flex items-center `}>
        <p className="typography-h100 text-n-800">
          {setBothLocale(
            'Next survey will be on [start_date] and end on [end_date]'
          )
            .replace(
              '[start_date]',
              dayjs(nextSurvey?.startDate).format('D MMM YYYY')
            )
            .replace(
              '[end_date]',
              dayjs(nextSurvey?.endDate).format('D MMM YYYY')
            )}
        </p>
      </div>
    </div>
  );
};

export default RecurringCard;

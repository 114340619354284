import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { withdrawApprovalById } from 'client/ApprovalClient';
import { getObjectiveApproval } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import SVGIcon from 'components/shared/SVGIcon';

const WithdrawConfirmationModal = ({
  objectiveId,
  setShowModal,
  refetchSubGoals
}) => {
  const [approvalState, setApprovalState] = useState('');
  const [approvalId, setApprovalId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const history = useHistory();
  const { url } = useUrl();
  const { refetchQueries } = useRefetchQuery();

  const handleClickWithdraw = async () => {
    setIsLoading(true);
    const params = {
      teamId: params?.teamId,
      objectiveId: objectiveId
    };
    const { data } = await withdrawApprovalById(approvalId, params);
    if (data) {
      refetchQueries(['teamGoals', 'approval', 'approvalData']);
      refetchQueries(['teamGoals', 'approval', 'permissions', objectiveId]);
      setShowModal(false);
      if (url?.includes('approval')) {
        history.replace('/teams?tab=teams-approval');
      } else {
        refetchSubGoals();
        refetchQueries(['teamGoals', 'approval', 'approvalData']);
      }
    }
    setIsLoading(false);
  };

  const fetchObjectiveApproval = () => {
    return getObjectiveApproval(params?.teamId, objectiveId);
  };

  const { isFetching } = useQuery(
    ['teamGoals', 'approval', 'approvalData', objectiveId],
    fetchObjectiveApproval,
    {
      onSuccess: (data) => {
        if (data?.data) {
          setApprovalId(data?.data?.id);
          setApprovalState(data?.data?.state);
        }
      }
    }
  );

  return (
    <Modal
      onClose={() => setShowModal(false)}
      customClass="!max-w-[400px] cursor-default"
      minWidth={400}
    >
      <Modal.Header
        title={getObjectiveLocale('Withdraw approval request?')}
        titleIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
      />
      <Modal.Body>
        <div className="flex flex-col px-[24px]">
          <p className="typography-paragraph text-n-800 mb-[16px]">
            {getObjectiveLocale(
              'Are you sure want to withdraw your approval request? Your status right now'
            )}
            :
          </p>
          {isFetching ? (
            <Shimmer
              width="320px"
              height="24px"
              customClass="mx-[16px] my-[8px]"
            />
          ) : (
            <div className="flex items-center px-[16px] py-[8px] rounded-[8px] bg-b-200">
              <SVGIcon
                iconName="icon-info"
                fillColor="var(--b-600)"
                size={24}
              />
              <p className="typography-paragraph text-n-900 ml-[16px]">
                {approvalState}
              </p>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        useBorder={false}
        primaryButton={{
          text: getObjectiveLocale('Yes, Withdraw'),
          onClick: () => handleClickWithdraw(),
          variant: 'danger',
          disabled: isFetching,
          isLoading: isLoading
        }}
        secondaryButton={{
          text: getObjectiveLocale('No'),
          onClick: () => setShowModal(false)
        }}
      />
    </Modal>
  );
};

export default WithdrawConfirmationModal;

import { create } from 'zustand';

const useFilter = create((set) => ({
  filter: {},
  setFilter: (filter) => set({ filter: filter }),
  filterById: {},
  setFilterById: (id, filter) =>
    set((state) => ({ filterById: { ...state.filterById, [id]: filter } })),
  deleteFilterById: (id) =>
    set((state) => {
      const filter = { ...state.filterById };
      delete filter[id];
      return { filterById: filter };
    }),
  showMenuFilter: false,
  setShowMenuFilter: (show) => set(() => ({ showMenuFilter: show }))
}));

export default useFilter;

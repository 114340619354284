import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';

import { getCycleProgress } from 'client/FormalReviewClient';
import { startPhase, stopPhase } from 'client/admin/CyclesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useToastContext } from 'context/ToastContext';
import {
  getBadgeTitle,
  getProgressTooltipComponent,
  phaseName
} from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalReminderList from 'components/admin/Cycles/components/ModalReminderList';
import ModalTrackDetail from 'components/admin/Cycles/components/ModalTrackDetail';
import Button from 'components/design-system/Button';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import Modal from 'components/shared/modal/Modal';
import ProgressBarOverallProgress from 'components/shared/progressBar/ProgressBarOverallProgress';

import './CycleSidebar.scss';
import CycleSidebarToggleNotification from './CycleSidebarToggleNotification';

const CyclePhaseCard = ({
  cycleId,
  phase,
  data,
  isReadOnly,
  setCurrentPage,
  cycleData
}) => {
  const { addToast } = useToastContext();
  const { reload, reloadAdminCycles, reloadSidebar } = useReload();
  const { refetchQueries } = useRefetchQuery();

  const [phaseDetail, setPhaseDetail] = useState(cloneDeep(data));
  const [phaseProgress, setPhaseProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(null);
  const [isEllipsis, setIsEllipsis] = useState({});
  const progress = phaseProgress?.progressPercentage
    ? phaseProgress?.progressPercentage?.toFixed(2)
    : 0;

  const trackQty =
    phase?.type !== 'peer_selection' ? data?.trackConfigs?.length : 0;
  const notificationReminderQty =
    phase?.type !== 'peer_selection' ? data?.reminders?.length : 0;

  const _getPhaseDetail = async () => {
    setLoading(true);
    const { data: progressData } = await getCycleProgress(
      cycleId,
      phase?.type,
      {
        userState: ['active', 'pending', 'deleted']
      }
    );
    if (progressData) setPhaseProgress(progressData);
    setLoading(false);
  };

  const confirmPhase = async (type) => {
    const phaseAction = type === 'start' ? startPhase : stopPhase;
    const body = { type: phase.type };
    const { data } = await phaseAction(cycleId, body);
    if (data) {
      setPhaseDetail((prev) => ({
        ...prev,
        state: type === 'start' ? 'in_progress' : 'done'
      }));
      reload({
        reloadAdminCycles: !reloadAdminCycles,
        reloadSidebar: !reloadSidebar
      });
      refetchQueries(['cycle', 'sidebar', cycleId]);
      setCurrentPage(1);
      addToast({
        type: 'success',
        title: getObjectiveLocale(`Phase ${type}ed`),
        msg: getObjectiveLocale(
          `${startCase(phase?.type)} phase has successfully ${type}ed`
        )
      });
    }
    setModal(null);
  };

  const handlePhase = (type) => {
    setModal({
      type,
      title: startCase(type) + 'ing Phase',
      description: `Do you want to ${startCase(type)} this ${phaseName(
        phase?.type
      )} phase?`,
      action: () => confirmPhase(type)
    });
  };

  const checkDependencies = () => {
    let cycleDependentOn = phaseDetail?.dependencies;
    if (cycleDependentOn === null) cycleDependentOn = [];
    if (cycleDependentOn?.length > 0) {
      let listDependencies = '';
      const logicAddComa = (i, dependencies) =>
        dependencies?.length > 1 && i != dependencies?.length - 1 ? `, ` : '';

      cycleDependentOn.map((dependency, i) => {
        listDependencies += `${startCase(dependency)}${logicAddComa(
          i,
          cycleDependentOn
        )}`;
      });
      return (
        <div className="typography-h100 text-n-600 w-[350px] truncate">
          {getObjectiveLocale('Dependent On')}{' '}
          <span className="text-n-900">{listDependencies}</span>
        </div>
      );
    } else
      return (
        <span className="text-n-800">
          {' '}
          {getObjectiveLocale('Not Dependent on any phase')}
        </span>
      );
  };

  const getActions = () => {
    if (isReadOnly) return;

    if (phaseDetail?.state === 'draft') {
      return (
        <Button.Secondary
          disabled={
            loading ||
            (trackQty === 0 &&
              phase?.type !== 'peer_selection' &&
              phase?.type !== 'calibration')
          }
          onClick={() => handlePhase('start')}
        >
          {getObjectiveLocale(' Start Phase')}
        </Button.Secondary>
      );
    } else if (phaseDetail?.state === 'in_progress') {
      return (
        <Button.Secondary disabled={loading} onClick={() => handlePhase('end')}>
          {getObjectiveLocale('End Phase')}
        </Button.Secondary>
      );
    }
  };

  useEffect(() => {
    _getPhaseDetail();
  }, [phase]);

  useEffect(() => {
    setPhaseDetail(data);
  }, [data]);

  return (
    <div className="pb-[24px] border-solid border-b border-0 border-n-400 relative">
      <p className="mb-[4px]">
        <span className="typography-h400">
          {loading ? (
            <Shimmer circle width="126px" height="16px" />
          ) : (
            getObjectiveLocale(phaseName(phase?.type))
          )}
        </span>
        &nbsp;
        <span className="typography-h400 text-n-600">
          {loading ? (
            <Shimmer circle width="126px" height="16px" />
          ) : (
            `(${getBadgeTitle(phaseDetail?.state)})`
          )}
        </span>
      </p>
      <p className="typography-h200 mb-[4px]">
        {loading ? (
          <Shimmer circle width="130px" height="14px" />
        ) : (
          phaseDetail?.startsAt &&
          phaseDetail?.endsAt &&
          `Start from: ${dayjs(phaseDetail?.startsAt).format(
            'DD MMM YYYY'
          )} - ${dayjs(phaseDetail?.endsAt).format('DD MMM YYYY')}`
        )}
      </p>
      <div
        ref={(ref) => setIsEllipsis(ref?.offsetWidth < ref?.scrollWidth)}
        className={`max-w-[352px] mb-[8px] flex flex-col ${
          phaseDetail?.dependencies && isEllipsis ? 'truncate' : ''
        }`}
      >
        {loading ? (
          <Shimmer circle width="136px" height="14px" />
        ) : (
          <>
            <p className="typography-h400 text-n-800">{checkDependencies()}</p>
            {trackQty === 0 &&
            notificationReminderQty === 0 &&
            phase?.type !== 'peer_selection' &&
            phase?.type !== 'calibration' ? (
              <span className="text-r-600 typography-paragraph">
                {getObjectiveLocale(
                  'No track selected, please add a track on set phase setting'
                )}
              </span>
            ) : (
              phase?.type !== 'peer_selection' &&
              phase?.type !== 'calibration' && (
                <div className="flex mt-[8px]">
                  <span
                    className="cursor-pointer text-base-600 hover-track"
                    onClick={() => trackQty && setModal('tracks')}
                  >
                    {trackQty} track(s)
                  </span>{' '}
                  ·&nbsp;
                  <span
                    className="cursor-pointer text-base-600 hover-track"
                    onClick={() =>
                      notificationReminderQty && setModal('reminders')
                    }
                  >
                    {notificationReminderQty} Notification Reminders
                  </span>
                </div>
              )
            )}
          </>
        )}
      </div>

      {phase?.type !== 'calibration' && (
        <div className="my-[8px]">
          <CycleSidebarToggleNotification
            cycleData={cycleData}
            phaseType={phase?.type}
            phaseDetail={phaseDetail}
            loading={loading}
          />
        </div>
      )}

      <div className="flex mb-[16px]">
        {loading ? (
          <Shimmer height="14px" widthRandomness={0.25} circle />
        ) : (
          <TooltipContainer
            show
            classContainer="cursor-pointer flex"
            useMaxWidth={false}
            tooltipClass="!bg-n-000 shadow-overlay"
            tooltipContent={() => getProgressTooltipComponent(phaseProgress)}
          >
            <ProgressBarOverallProgress
              widthBackground={100}
              widthProgress={parseInt(progress)}
              height={16}
              colorProgress="bg-g-600"
              containerClassName="w-[256px] h-[16px] overflow-hidden rounded-full"
            />
            <span className="typography-h400 ml-[8px] min-w-[60px] text-right">
              {progress}%
            </span>
          </TooltipContainer>
        )}
      </div>
      {getActions()}
      {modal?.type && (
        <Modal
          className="max-w-[400px]"
          eventOnClickClose={() => setModal(null)}
          withCloseIcon={true}
          withPrimaryBtn={{ title: 'Yes', onClick: () => modal.action() }}
          withSecondaryBtn={{ title: 'No', onClick: () => setModal(null) }}
          withFooter={true}
          {...modal}
        />
      )}
      {modal == 'tracks' && (
        <ModalTrackDetail
          title={startCase(phase?.type)}
          trackConfigs={data?.trackConfigs}
          onCloseModal={() => setModal(null)}
        />
      )}
      {modal == 'reminders' && (
        <ModalReminderList
          reminders={data?.reminders}
          phase={phase?.type}
          cycleData={data}
          setModal={() => setModal(null)}
        />
      )}
    </div>
  );
};

export default CyclePhaseCard;

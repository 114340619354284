import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useReload } from 'context/ReloadContext';
import {
  getCultureLocale,
  getLines,
  parseCaption,
  taggedUserTemplate
} from 'utils/HelperUtils';

import ModalTaggedUsers from 'components/modal/culture/ModalTaggedUsers';

function PostText({
  text,
  taggedUsers,
  postId,
  mentions,
  seeMore,
  setSeeMore,
  postType,
  attachedFile,
  goToPost,
  feed
}) {
  const [showTaggedUsers, setShowTaggedUsers] = useState(false);
  const { reloadFeedsId } = useReload();
  const { exist } = seeMore;
  const history = useHistory();
  let captionRef = useRef();
  const multipleMediaContentImageVideo =
    feed.multipleContent?.resources.length > 0;
  const multipleMediaContentFiles = feed.multipleContent?.files.length <= 0;

  const checkLines = () => {
    const lines = getLines(captionRef);

    if (
      lines > 4 &&
      (postType === 'media' ||
        postType === 'multiple_media' ||
        multipleMediaContentImageVideo)
    ) {
      setSeeMore({ exist: true, isOpen: false });
      captionRef?.current?.classList.add('truncate-4-lines');
      return;
    }

    if (lines > 16) {
      setSeeMore({ exist: true, isOpen: false });
      captionRef?.current?.classList.add('truncate-16-lines');
    }
  };

  const handleOnClick = (e) => {
    goToPost(e, feed);
  };

  const deleteTruncateClass = () => {
    if (captionRef?.current?.classList.contains('truncate-16-lines')) {
      captionRef?.current?.classList.remove('truncate-16-lines');
    } else {
      captionRef?.current?.classList.remove('truncate-4-lines');
    }
  };

  const handleClickCaption = (e) => {
    if (e.target.id === 'many-more') {
      setShowTaggedUsers(true);
    }
  };

  useEffect(() => {
    text && checkLines();
  }, [reloadFeedsId]);

  useEffect(() => {
    if (history.location.pathname === `/timeline/${feed.id}`) {
      deleteTruncateClass();
    }
  }, [history.location.pathname, feed.id]);

  return (
    <div className="feed-text">
      {text && (
        <>
          <div
            ref={captionRef}
            onClick={handleClickCaption}
            className="typography-form word-break webkit-box text-n-900"
            data-cy={`post-caption-${postId}`}
            dangerouslySetInnerHTML={{
              __html:
                (text && parseCaption(text, mentions)) +
                taggedUserTemplate(taggedUsers)
            }}
          />

          {exist && (
            <span
              onClick={(e) => handleOnClick(e)}
              className="color-p-600 cursor-pointer"
              data-cy="see-more-action"
            >
              {typeof attachedFile === 'undefined' || multipleMediaContentFiles
                ? getCultureLocale('See More')
                : `+${attachedFile.length} ${`File${
                    attachedFile.length > 1 ||
                    feed.multipleContent?.files.length > 1
                      ? 's'
                      : ''
                  } Attached`}`}
            </span>
          )}
        </>
      )}
      {showTaggedUsers && (
        <ModalTaggedUsers
          onClickClose={() => setShowTaggedUsers(false)}
          postId={postId}
        />
      )}
    </div>
  );
}

export default PostText;

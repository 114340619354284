import React, { useState } from 'react';

import useTasks from 'hooks/useTasks';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import InputSectionName from 'components/project/detail/InputSectionName';
import SVGIcon from 'components/shared/SVGIcon';

const AddSection = ({
  sectionIndex,
  projectId,
  isShowing,
  setIsShowing,
  addAbove,
  customClass = ''
}) => {
  return (
    <div className="flex items-center">
      <div className={`flex items-center sticky ${customClass}`}>
        <SVGIcon
          size="24"
          iconName="icon-keyboard_arrow_down"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <InputSectionName
          sectionIndex={sectionIndex}
          projectId={projectId}
          isShowing={isShowing}
          setIsShowing={setIsShowing}
          addAbove={addAbove}
          isCreateNew={true}
        />
        <SVGIcon
          size="24"
          iconName="icon-add"
          fillColor="var(--n-600)"
          customClass="ml-[16px]"
        />
        <SVGIcon
          size="24"
          iconName="icon-keyboard_control"
          fillColor="var(--n-600)"
          customClass="ml-[16px]"
        />
      </div>
    </div>
  );
};

const AddSectionButton = ({ projectId, type = 'list' }) => {
  const [showInputField, setShowInputField] = useState(false);

  const { listGroups } = useTasks();

  return (
    <div className="flex items-center">
      <div
        className={`flex flex-col items-start sticky ${
          type === 'list' ? 'left-[0px] mb-[16px]' : 'left-[40px] my-[16px]'
        }`}
      >
        {showInputField ? (
          <AddSection
            sectionIndex={listGroups?.section?.length - 1}
            projectId={projectId}
            isShowing={showInputField}
            setIsShowing={setShowInputField}
            addAbove={false}
            isCreateNew={true}
            customClass={type === 'list' ? 'mb-[36px]' : 'mb-[16px]'}
          />
        ) : (
          <Button.Tertiary
            customClass="mb-[16px]"
            onClick={() => setShowInputField(true)}
            datacy="add-section-button"
          >
            <SVGIcon
              size="24"
              iconName="icon-add"
              fillColor="var(--base-600)"
            />
            <p className="ml-[4px]">{getObjectiveLocale('Add Section')}</p>
          </Button.Tertiary>
        )}
      </div>
    </div>
  );
};

AddSection.Button = AddSectionButton;

export default AddSection;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const generateBubble_1 = require("./generateBubble");
const generateSVG = ({ circleX, circleY, pathLine, pathArrow, widthSVG, heightSVG, height, topSVG, leftSVG }) => {
    const svgContainer = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    const circleBullet = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    const pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    circleBullet.setAttribute('cx', `${circleX}`);
    circleBullet.setAttribute('cy', `${circleY}`);
    circleBullet.setAttribute('r', '6');
    circleBullet.setAttribute('stroke', 'none');
    circleBullet.setAttribute('fill', 'var(--base-600)');
    path.style.fill = 'var(--base-600)';
    pathElement.setAttribute('stroke', 'var(--base-600)');
    pathElement.setAttribute('fill', 'none');
    pathElement.setAttribute('stroke-width', '2px');
    const circleBulletShadow = circleBullet.cloneNode(true);
    circleBulletShadow.setAttribute('opacity', '0.20');
    circleBulletShadow.setAttribute('r', '10');
    g.appendChild(circleBullet);
    g.appendChild(circleBulletShadow);
    svgContainer.appendChild(g);
    pathElement.setAttribute('d', pathLine || '');
    path.setAttribute('d', pathArrow || '');
    svgContainer.appendChild(pathElement);
    svgContainer.appendChild(path);
    svgContainer.setAttribute('width', widthSVG + 'px');
    svgContainer.setAttribute('height', `${heightSVG || height}` + 'px');
    svgContainer.style.zIndex = '1';
    svgContainer.style.top = topSVG + 'px';
    svgContainer.style.left = leftSVG + 'px';
    svgContainer.classList.add('absolute');
    return svgContainer;
};
const generateBorder = ({ height, margin, width, topMod, leftMod, borderColor }) => {
    const border = document.createElement('div');
    border.style.height = height + margin * 2 + 'px';
    border.style.width = width + margin * 2 + 'px';
    border.style.top = topMod + 'px';
    border.style.left = leftMod + 'px';
    border.classList.add('border', borderColor ? borderColor : 'border-cy-600', 'border-solid', 'rounded', 'absolute');
    return border;
};
function useGeneratePosition({ targetQuerySelector, borderMargin, fromBubblePosition, borderedTarget, borderColor }) {
    const containerRef = (0, react_1.useRef)(null);
    const bubbleRef = (0, react_1.useRef)(null);
    const [position, setPosition] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c;
        if (!targetQuerySelector)
            return;
        const targetElement = document.querySelector(targetQuerySelector);
        const rect = targetElement === null || targetElement === void 0 ? void 0 : targetElement.getBoundingClientRect();
        const height = (rect === null || rect === void 0 ? void 0 : rect.height) || 0;
        const width = (rect === null || rect === void 0 ? void 0 : rect.width) || 0;
        const margin = borderedTarget ? borderMargin || 4 : 0;
        const top = ((rect === null || rect === void 0 ? void 0 : rect.top) || 0) - margin;
        const left = ((rect === null || rect === void 0 ? void 0 : rect.left) || 0) - margin;
        const right = ((rect === null || rect === void 0 ? void 0 : rect.right) || 0) + margin;
        const topMod = top < 0 ? 0 : top;
        const leftMod = left < 0 ? 0 : left;
        const bubbleElement = bubbleRef.current;
        const bubbleRect = bubbleElement === null || bubbleElement === void 0 ? void 0 : bubbleElement.getBoundingClientRect();
        const bubbleHeight = (bubbleRect === null || bubbleRect === void 0 ? void 0 : bubbleRect.height) || 0;
        const bubbleWidth = (bubbleRect === null || bubbleRect === void 0 ? void 0 : bubbleRect.width) || 0;
        const { widthSVG, topSVG, leftSVG, pathLine, bubbleTop, pathArrow, heightSVG, bubbleLeft, circleX, circleY } = (0, generateBubble_1.generateBubble)({
            position: fromBubblePosition || 'top',
            targetRect: { top: topMod, left: leftMod, right, width, height },
            bubbleRect: { width: bubbleWidth, height: bubbleHeight },
            maxHeight: ((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) || 0,
            targetMargin: borderedTarget ? borderMargin || 4 : 0
        }) || {};
        const svgContainer = generateSVG({
            circleX,
            circleY,
            pathLine,
            pathArrow,
            widthSVG,
            heightSVG,
            height,
            topSVG,
            leftSVG
        });
        setPosition({ top: bubbleTop || 0, left: bubbleLeft || 0 });
        const border = generateBorder({
            height,
            margin,
            width,
            topMod,
            leftMod,
            borderColor
        });
        borderedTarget &&
            ((_b = containerRef.current) === null || _b === void 0 ? void 0 : _b.insertBefore(border, containerRef.current.children[0]));
        (_c = containerRef.current) === null || _c === void 0 ? void 0 : _c.insertBefore(svgContainer, containerRef.current.children[0]);
        return () => {
            border.remove();
            svgContainer.remove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { position, setPosition, containerRef, bubbleRef };
}
exports.default = useGeneratePosition;

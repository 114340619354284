import React from 'react';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

function OverallPerformance(props) {
  const { type, userScoring } = props;

  let text;
  let scoring;
  if (type == 'goal') {
    text = 'Goal';
    scoring = userScoring[1] || null;
  } else if (type == 'task') {
    text = getObjectiveLocale('Task');
    scoring = userScoring[0] || null;
  }

  return (
    <div className="overview-box overall-performance ">
      <h5 className="typography-h200 mb-[24px]">
        {getObjectiveLocale(`Overall ${text} Performance`)}
      </h5>

      {scoring?.personal?.score ? (
        <p className="typography-h900">
          {getNumberLocale(scoring.personal.score.toFixed(2))}
        </p>
      ) : (
        <p className="typography-paragraph text-n-600 w-full">
          {getObjectiveLocale('Not yet available')}
        </p>
      )}

      {scoring?.personal?.scoringMark && (
        <p
          className="score-name"
          style={{ color: `#${scoring.personal.scoringMark.colorHex}` }}
        >
          {scoring.personal.scoringMark.name}
        </p>
      )}

      {scoring?.personal?.score ||
        (scoring?.personal?.scoringMark && (
          <p className="typography-h100 text-n-800 mt-[8px]">
            {getObjectiveLocale('Total rating received')}
          </p>
        ))}
    </div>
  );
}

export default OverallPerformance;

import React, { useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import ctl from '@netlify/classnames-template-literals';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

import { getAllTeam } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getLeader } from 'utils/TeamHelper';

import AWSImage from 'components/design-system/AWSImage';
import InlineDialog from 'components/design-system/inline-dialog/InlineDialog';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';
import useDebounce from 'src/hooks/useDebounce';

const AlignParent = ({ objectiveDetail, updateObjective }) => {
  const { user } = useUser();
  const { parent } = objectiveDetail;

  const [searchValue, setSearchValue] = useState('');
  const [listOption, setListOption] = useState([]);
  const debounceSearchValue = useDebounce(searchValue, 500);

  const updateObjectiveParent = (option) => {
    if (parent?.id != option?.id) {
      updateObjective({ parentId: option?.id });
    }
  };

  const fetchTeams = async (olderThan) => {
    let allParams = {
      reviewsVisibility: 1,
      limit: 15,
      query: debounceSearchValue,
      teamType: 'tribe',
      parentNotAssignedTo: parent?.id,
      involvement: ['pmo', 'leader', 'member'],
      involvedUserId: user?.id,
      ...(olderThan && { olderThan: olderThan })
    };

    return getAllTeam(allParams);
  };

  const queryKey = ['objectives', 'myteams', debounceSearchValue];

  const { hasNextPage, fetchNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery(queryKey, ({ pageParam }) => fetchTeams(pageParam), {
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || undefined,
      onSuccess: (data) => {
        const newTribes = flatten(
          data?.pages?.map((page) => {
            return page?.data?.map((d) => {
              return d;
            });
          })
        );
        setListOption(newTribes);
      }
    });

  const scrollParent = (e) => {
    const loadMore = async () => {
      await fetchNextPage();
    };

    let target = e.target;
    let cond = target.scrollTop + target.clientHeight == target.scrollHeight;

    if (hasNextPage && !isFetchingNextPage && cond) {
      loadMore();
    }
  };

  const inlineDialogCN = ctl(
    `typography-paragraph cursor-not-allowed ml-[4px] ${
      parent?.name ? 'text-base-600' : 'text-n-600 hover:text-base-600'
    }`
  );

  return (
    <div className="px-24 py-12">
      <div className="flex" data-cy="align-tribe-section">
        <SVGIcon
          iconName="icon-arrow_upward"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-16"
        />
        <InlineDialog
          customClass="w-full h-full"
          search={{
            placeholder: 'Search tribe',
            onSearch: (e) => {
              setSearchValue(e.target.value);
            }
          }}
          header="Align to tribe"
        >
          <InlineDialog.Trigger>
            <div className="flex justify-between">
              <div>
                <span className="typography-paragraph text-n-600">
                  {getObjectiveLocale('Tribe')}:{' '}
                </span>
                <span data-cy="align-tribe-team" className={inlineDialogCN}>
                  {isEmpty(parent?.name)
                    ? getObjectiveLocale('Align to Tribe')
                    : parent?.name}
                </span>
              </div>
              {parent?.name && (
                <SVGIcon
                  size="24"
                  customClass="mr-[8px]"
                  iconName="icon-delete"
                  fillColor="var(--n-600)"
                  onClick={() => updateObjective({ parentId: null })}
                  dataCy="delete-align-parent"
                />
              )}
            </div>
          </InlineDialog.Trigger>

          <InlineDialog.MenuItems
            isLoading={isFetching && !isFetchingNextPage}
            position="top"
            type="absolute"
            customClass="w-[300px]"
            onScroll={(e) => scrollParent(e)}
          >
            {listOption?.map((opt) => {
              const leader = getLeader(opt?.involvements)?.user;
              return (
                <InlineDialog.MenuItem
                  onClick={() => updateObjectiveParent(opt)}
                  key={opt?.id}
                  customClass="h-[68px]"
                  dataCy={`dialog-list-${opt?.id}`}
                  emptyText={{
                    text: 'Tribe not found',
                    show: listOption?.length == 0
                  }}
                >
                  <div className="flex">
                    <AWSImage
                      src={opt?.teamPicture || Config.asset.general.brokenImage}
                      className="h-[40px] w-[40px] mr-[16px] rounded-[4px]"
                    />
                    <div className="w-[212px]">
                      <TruncateParagraph className="typography-h400 text-n-900">
                        {opt?.name}
                      </TruncateParagraph>
                      <p className="typography-h100 text-n-800">
                        {leader?.name} · {leader?.jobTitle}
                      </p>
                    </div>
                  </div>
                </InlineDialog.MenuItem>
              );
            })}
          </InlineDialog.MenuItems>
        </InlineDialog>
      </div>
    </div>
  );
};

export default AlignParent;

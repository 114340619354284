import React, { useEffect } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

const RatingOptionView = ({
  currentTrackType,
  templateData,
  setTemplateData,
  customTitleClass = '',
  customWrapperClass = ''
}) => {
  const handleChangeOptionView = (e, optionView) => {
    e.stopPropagation();
    const newConfig = cloneDeep(templateData?.config) || {};
    newConfig.view = optionView;
    setTemplateData({ ...templateData, config: newConfig });
  };

  const handleChangeRangeLabel = (keys, values) => {
    const newConfig = cloneDeep(templateData.config);
    const sliderLabel = { ...newConfig.sliderLabel };
    keys?.map((key, index) => {
      sliderLabel[key] = values[index];
    });

    newConfig.sliderLabel = sliderLabel;

    setTemplateData({ ...templateData, config: newConfig });
  };

  const isBehaviorScoring =
    currentTrackType == 'competencies_scoring' ||
    currentTrackType == 'values_scoring';
  const isScoredAttribute = currentTrackType == 'review_aspects_scoring';
  const isGoalScoring = currentTrackType == 'goals_scoring';

  const options = [
    { key: 'rating', name: 'Rating Scale', icon: 'icon-rating-scale' },
    isBehaviorScoring && {
      key: 'matrix',
      name: 'Matrix Scale',
      icon: 'icon-matrix-scale'
    },
    (isScoredAttribute || isGoalScoring) && {
      key: 'raw',
      name: 'Raw Score',
      icon: 'icon-raw-scale'
    },
    (isScoredAttribute || isGoalScoring) && {
      key: 'slider',
      name: 'Slider Scale',
      icon: 'icon-slider-scale'
    }
  ].filter((val) => val);

  useEffect(() => {
    if (
      templateData?.config?.view == 'slider' &&
      !templateData.config.sliderLabel
    ) {
      handleChangeRangeLabel(['leftLabel', 'rightLabel'], ['Low', 'High']);
    }
  }, [templateData?.config?.view]);

  return (
    <>
      <div
        className={`create-track-template-view pt-[40px] mb-[24px] ${customWrapperClass}`}
        id="rating-option-view"
      >
        <p
          className={`typography-h500 typography-primary text-n-900 mb-[4px] ${customTitleClass}`}
        >
          {getObjectiveLocale('Rating options view')}
        </p>
        <p className="typography-secondary">
          {getObjectiveLocale(
            'What type of view do you want to use in this track template?'
          )}
        </p>
        <div className="wrapper-switch flex">
          {options.map((option, index) => {
            return (
              <div
                className={`switch-question ${index == 0 ? 'left' : 'right'} ${
                  templateData?.config?.view == option.key
                    ? 'active'
                    : 'not-active'
                }`}
                data-cy={option.key}
                onClick={(e) => handleChangeOptionView(e, option.key)}
                key={index}
              >
                <div className="flex justify-center">
                  <SVGIcon
                    width="72"
                    height="52"
                    viewBox="0 0 72 54"
                    iconName={option.icon}
                  />
                </div>
                <p className="typography-paragraph typography-secondary mt-[14px]">
                  {option.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {templateData?.config?.view == 'slider' && (
        <div className="py-[16px] px-[16px] border-n400 bg-n-000 rounded-[4px]">
          <h4 className="typography-h400 text-n-900 mb-[4px]">
            {getObjectiveLocale('Scale range labels')}
          </h4>
          <div>
            <p className="typography-paragraph text-n-800 mb-[16px]">
              {getObjectiveLocale(
                'customize labels on your slider to say what you like'
              )}
            </p>
            <div className="flex justify-between">
              <div className="inline-flex grow flex-col">
                <p className="typography-h100 typography-secondary mb-[8px]">
                  {getObjectiveLocale('Left side')}
                </p>
                <input
                  className={`track-template-input w-full ${
                    templateData.config?.sliderLabel?.leftLabel == ''
                      ? 'error-border'
                      : 'normal-border'
                  }`}
                  value={templateData.config?.sliderLabel?.leftLabel || ''}
                  placeholder="Enter a label"
                  onChange={(e) => {
                    e?.stopPropagation();
                    handleChangeRangeLabel(['leftLabel'], [e.target.value]);
                  }}
                  data-cy="left-label"
                />
              </div>
              {/* dummy separatror */}
              <div className="inline-flex grow-0 w-[18px]"></div>
              <div className="inline-flex grow flex-col">
                <p className="typography-h100 typography-secondary mb-[8px]">
                  {getObjectiveLocale('Center')}
                </p>
                <input
                  className={`track-template-input w-full`}
                  value={templateData.config?.sliderLabel?.centerLabel || ''}
                  placeholder="Enter a label (Optional)"
                  onChange={(e) => {
                    e?.stopPropagation();
                    handleChangeRangeLabel(['centerLabel'], [e.target.value]);
                  }}
                  data-cy="center-label"
                />
              </div>
              {/* dummy separatror */}
              <div className="inline-flex grow-0 w-[18px] "></div>
              <div className="inline-flex grow flex-col">
                <p className="typography-h100 typography-secondary mb-[8px]">
                  {getObjectiveLocale('Right side')}
                </p>
                <input
                  className={`track-template-input w-full ${
                    templateData.config?.sliderLabel?.rightLabel == ''
                      ? 'error-border'
                      : 'normal-border'
                  }`}
                  value={templateData.config?.sliderLabel?.rightLabel || ''}
                  placeholder="Enter a label"
                  onChange={(e) => {
                    e?.stopPropagation();
                    handleChangeRangeLabel(['rightLabel'], [e.target.value]);
                  }}
                  data-cy="right-label"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RatingOptionView;

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';

import {
  getRatingTemplateDetail,
  postRatingTemplate,
  putRatingTemplate
} from 'client/admin/RatingTemplateClient';
import { capitalize, getObjectiveLocale } from 'utils/HelperUtils.js';

import HeaderPage from 'components/admin/HeaderPage';
import ContentNavigator from 'components/shared/ContentNavigator';
import Footer from 'components/shared/Footer';
import LoadingComponent from 'components/shared/LoadingComponent';

import {
  SPConfig,
  defaultBody,
  formulaCalculationConfig,
  percentageRangeConfig,
  ratingConfig
} from '../ConfigRatingTemplate';
import ContentFormulaCalculation from './ContentFormulaCalculation';
import ContentPercentageRangeProgress from './ContentPercentageRangeProgress';
import ContentRatingConfiguration from './ContentRatingConfiguration';
import ContentScoringMechanism from './ContentScoringMechanism';
import ContentTemplateName from './ContentTemplateName';
import ContentValueRecognitionActivites from './ContentValueRecognitionActivites';
import './CreateEditRatingTemplate.scss';
import ModalLeaveRatingTemplate from './ModalLeaveRatingTemplate';
import ModalSuccessSaveTemplate from './ModalSuccessSaveTemplate';

const CreateEditRatingTemplate = () => {
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showOptions, setShowOptions] = useState('score_and_label');
  const [configRating, setConfigRating] = useState(ratingConfig);

  const [listNavigatorContents, setListNavigatorContents] = useState([]);
  const [initialSavedData, setInitialSavedData] = useState({});
  const [isModalLeaveOpen, setIsModalLeaveOpen] = useState(false);
  const [isModalSuccessSaveTemplateOpen, setIsModalSuccessSaveTemplateOpen] =
    useState(false);
  const [stateValueRecognition, setStateValueRecognition] = useState('given');
  const [configPercentageRange, setConfigPercentageRange] = useState(
    percentageRangeConfig
  );
  const { type, action, id, state } = useParams();
  const [isWarningRating, setIsWarningRating] = useState({
    show: false,
    message: ''
  });
  const [isWarningLabel, setIsWarningLabel] = useState({
    show: false,
    message: ''
  });
  const history = useHistory();

  const localizedTitle =
    capitalize(getObjectiveLocale(startCase(type)?.split(' ')[0])) +
    ' ' +
    startCase(type)?.split(' ')?.[1]; // task Ongoing -> Task Ongoing

  // state and setState for percentage range progress
  const [activeMechanismId, setActiveMechanismId] = useState('direct_to_goal');
  const [activeFormulaId, setActiveFormulaId] = useState('total');
  const [selectedListAttribute, setSelectedListAttribute] = useState([]);
  const [activeSection, setActiveSection] = useState('template-name');

  // state and setState for ContentFormulaCalculation
  const [activeFormulaCalculationID, setActiveFormulaCalculationID] =
    useState(1);
  const [configFormulaCalculation, setConfigFormulaCalculation] = useState(
    formulaCalculationConfig
  );
  const [configSP, setConfigSP] = useState(SPConfig);

  const dynamicScoringMarks = () => {
    let lastIndex = configRating.length - 1;
    let scoringMarks = modifiedData(configRating);

    for (let i = 0; i < configRating.length; i++) {
      if (i == lastIndex) {
        scoringMarks[i]['bottomThresholdPercentage'] =
          configPercentageRange[i]['bottomThresholdPercentage'] || 0;
        scoringMarks[i].upperThresholdScore = null;
      } else {
        scoringMarks[i]['bottomThresholdPercentage'] =
          configPercentageRange[i]['bottomThresholdPercentage'] || 0;
        scoringMarks[i]['upperThresholdPercentage'] =
          configPercentageRange[i]['upperThresholdPercentage'] || 0;
      }
    }

    return scoringMarks;
  };

  const _saveData = async () => {
    let body = dynamicBody();
    const { data } = await (action == 'create'
      ? postRatingTemplate(body)
      : putRatingTemplate(body, id));
    if (data) {
      setIsModalSuccessSaveTemplateOpen(true);
    }
  };

  const modifiedData = (array) => {
    let modifiedConfig = JSON.parse(JSON.stringify(array)).map((v) => {
      if (action == 'create' || (action == 'edit' && v.id < 1)) {
        delete v.id;
      }
      if (showOptions == 'score_only') v.name = '';

      delete v.ratingTemplateId;
      delete v.percentage;
      delete v.iconUrl;
      delete v.secondaryIconUrl;
      if (type !== 'value-recognition') {
        delete v.upperThresholdPercentage;
        delete v.bottomThresholdPercentage;
      }
      return v;
    });

    return modifiedConfig;
  };

  const getSingleRatingData = async () => {
    let params = {};
    if (state == 'inactive') params.state = 'deleted';

    const { data } = await getRatingTemplateDetail(id, params);
    if (data) {
      let scoringMarks = modifiedData(data.scoringComponents[0].scoringMarks);
      setConfigRating(scoringMarks);
      if (type == 'goal-ongoing' || type == 'task-ongoing') {
        initialGoalTask(data, scoringMarks);
      } else if (type == 'value-recognition') {
        initialValueRecognition(data, scoringMarks);
      } else if (type == 'performance-final') {
        initialPerformanceFinal(data, scoringMarks);
      } else {
        initialState(data, { scoringMarks });
      }
    }

    setIsLoading(false);
  };

  const initialState = (data, props) => {
    let state = {
      ...defaultBody,
      ...props,
      showOption: data?.showOption || 'score_and_label',
      name: title
    };

    if (data) {
      setTitle(data.name);
      state.name = data.name;
      state.ratingType = data.ratingType;
    }
    setInitialSavedData(state);
    setShowOptions(state.showOption);
  };

  const initialGoalTask = (data, scoringMarks) => {
    if (data.scoringMechanism == 'work_attributes') {
      setSelectedListAttribute(data.workAttributes);
    }
    setActiveMechanismId(data.scoringMechanism);
    setActiveFormulaId(data.formulaCalculation);
    initialState(data, {
      scoringMechanism: data.scoringMechanism,
      formulaCalculation: data.formulaCalculation,
      scoringMarks
    });
  };

  const initialValueRecognition = (data, scoringMarks) => {
    setStateValueRecognition(data.valueRecognitionActivity);
    setConfigPercentageRange(scoringMarks);
    initialState(data, {
      scoringMarks,
      valueRecognitionActivity: data.valueRecognitionActivity
    });
  };

  const initialPerformanceFinal = (data, scoringMarks) => {
    if (data.spConfigs.length > 0) {
      setConfigSP(data.spConfigs);
      setActiveFormulaCalculationID(2);
      initialState(data, { spConfigs: data.spConfigs, scoringMarks });
      return;
    }

    setActiveFormulaCalculationID(1);
    initialState(data, { scoringMarks });
  };

  const removeLastUpperThreshold = () => {
    const scoringMarks = modifiedData(configRating);
    scoringMarks[configRating?.length - 1].upperThresholdScore = null;
    return scoringMarks;
  };

  const typeConfig = () => {
    let object = {
      ...defaultBody,
      name: title,
      ratingType: type.replace(/-/g, '_'),
      scoringMarks: modifiedData(configRating),
      showOption: showOptions
    };

    if (type == 'goal-ongoing' || type == 'task-ongoing') {
      object.scoringMechanism = activeMechanismId;
      object.formulaCalculation = activeFormulaId;

      if (activeMechanismId == 'work_attributes') {
        object.behaviorIds = [...selectedListAttribute.map((val) => val.id)];
      }
    } else if (type == 'value-recognition') {
      object.valueRecognitionActivity = stateValueRecognition;
    } else if (type == 'performance-final') {
      if (activeFormulaCalculationID == 2) {
        object.spConfigs = configSP;
      }
    }
    if (type == 'scored-attributes') {
      object.ratingType = 'review_aspect';
    }
    if (object?.scoringMarks) {
      object.scoringMarks =
        type == 'value-recognition'
          ? dynamicScoringMarks()
          : removeLastUpperThreshold();
    }
    return object;
  };

  const dynamicBody = () => {
    let body = typeConfig();
    if (state == 'inactive') body.state = 'deleted';

    return body;
  };

  useEffect(() => {
    if (action == 'edit') {
      getSingleRatingData();
    } else {
      initialState(null, typeConfig());
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let list = [
      { id: 'template-name', name: 'Template name' },
      { id: 'rating-configuration', name: 'Rating configuration' }
    ];

    if (type == 'goal-ongoing' || type == 'task-ongoing') {
      list.push({ id: 'scoring-mechanism', name: 'Scoring mechanism' });
    }

    if (type == 'value-recognition') {
      list.push(
        { id: 'value-recognition', name: 'Value recognition activities' },
        { id: 'percentage-range-progress', name: 'Percentage range progress' }
      );
    }

    if (type == 'performance-final') {
      list.push({ id: 'performance-final', name: 'Formula calculation' });
    }

    setListNavigatorContents(list);
  }, []);

  const getEndSection = () => {
    if (type == 'goal-ongoing' || type == 'task-ongoing') {
      return 'scoring-mechanism';
    }
    if (type == 'value-recognition') {
      return 'percentage-range-progress';
    }
    if (type == 'performance-final') {
      return 'perfornamce-final';
    }
    return 'rating-configuration';
  };

  const onScroll = (containerEl) => {
    const endSection = getEndSection();
    const scrollTop = containerEl.target.scrollTop;
    const sections = [
      'template-name',
      'rating-configuration',
      'scoring-mechanism',
      'performance-final',
      'value-recognition',
      'percentage-range-progress'
    ];
    sections.forEach((section) => {
      const el = document.getElementById(section);
      if (el && scrollTop >= el.offsetTop) {
        setActiveSection(section);
      }
    });
    if (
      containerEl.target.scrollTop + containerEl.target.offsetHeight ==
      containerEl.target.scrollHeight
    ) {
      setActiveSection(endSection);
    }
  };

  const backFunction = () => {
    if (isEqual(initialSavedData, dynamicBody())) {
      history.push('/reviews/rating-template');
    } else {
      setIsModalLeaveOpen(true);
    }
  };

  const isSaveButtonDisabled = () => {
    if (
      activeMechanismId == 'work_attributes' &&
      selectedListAttribute.length == 0
    )
      return true;

    // activeFormulaCalculationID == 2 means that user apply for SP calculation
    if (activeFormulaCalculationID == 2 && configSP.length == 0) return true;

    if (!title || isWarningRating.show) return true;
    if (showOptions != 'score_only' && isWarningLabel.show) return true;

    return false;
  };

  const isDisabled = isSaveButtonDisabled();

  return (
    <>
      <HeaderPage
        titlePage={`
          ${
            localizedTitle.includes('Calibration')
              ? 'Create Calibration Template'
              : `${localizedTitle} - ${title || 'New Rating Template'}`
          }
        `}
        isHeaderComposer
        closeAction={() => backFunction()}
      />
      <div
        id="scrollable-config"
        className="rating-template-container-create-edit-page bg-n-000 overflow-auto pb-[24px] h-[calc(100%-137px)]"
        onScroll={(e) => onScroll(e)}
      >
        {isLoading && <LoadingComponent />}
        {!isLoading && (
          <>
            <div className="content-navigator-wrapper">
              <ContentNavigator
                title="CONTENTS"
                activeSection={activeSection}
                listNavigatorContents={listNavigatorContents}
                sectionContent="scrollable-config"
              />
            </div>
            <div className="flex content-container-nav">
              <div className="flex-[7]">
                <ContentTemplateName
                  type={type}
                  title={title}
                  setTitle={setTitle}
                />

                <ContentRatingConfiguration
                  configRating={configRating}
                  setConfigRating={setConfigRating}
                  showOptions={showOptions}
                  setShowOptions={setShowOptions}
                  isWarning={isWarningRating}
                  setIsWarning={setIsWarningRating}
                  isWarningLabel={isWarningLabel}
                  setIsWarningLabel={setIsWarningLabel}
                />

                {(type == 'goal-ongoing' || type == 'task-ongoing') && (
                  <ContentScoringMechanism
                    type={type}
                    // state and setState scoring mechanism
                    activeMechanismId={activeMechanismId}
                    setActiveMechanismId={setActiveMechanismId}
                    activeFormulaId={activeFormulaId}
                    setActiveFormulaId={setActiveFormulaId}
                    selectedListAttribute={selectedListAttribute}
                    setSelectedListAttribute={setSelectedListAttribute}
                    // for percentage range progress
                    configArray={configPercentageRange}
                    setConfigArray={setConfigPercentageRange}
                  />
                )}

                {type == 'value-recognition' && (
                  <ContentValueRecognitionActivites
                    stateValueRecognition={stateValueRecognition}
                    setStateValueRecognition={setStateValueRecognition}
                  />
                )}

                {type == 'value-recognition' && (
                  <ContentPercentageRangeProgress
                    configArray={configPercentageRange}
                    setConfigArray={setConfigPercentageRange}
                    configRating={configRating}
                    customSubtitle={
                      'The rating list will be taken from the rating configuration'
                    }
                  />
                )}

                {type == 'performance-final' && (
                  <ContentFormulaCalculation
                    activeFormulaCalculationID={activeFormulaCalculationID}
                    setActiveFormulaCalculationID={
                      setActiveFormulaCalculationID
                    }
                    configFormulaCalculation={configFormulaCalculation}
                    configSP={configSP}
                    setConfigSP={setConfigSP}
                    firstRender={isLoading}
                  />
                )}
              </div>
              <div className="flex-[3]"></div>

              {isModalLeaveOpen && (
                <ModalLeaveRatingTemplate
                  setIsModalLeaveOpen={setIsModalLeaveOpen}
                />
              )}

              {isModalSuccessSaveTemplateOpen && (
                <ModalSuccessSaveTemplate
                  setIsModalSuccessSaveTemplateOpen={
                    setIsModalSuccessSaveTemplateOpen
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
      <Footer
        linkButton={true}
        handleClick={_saveData}
        buttonPrimaryName="Save Template"
        buttonPrimaryDisabled={isDisabled}
        datacy="button-create-rating-template"
      />
    </>
  );
};

export default CreateEditRatingTemplate;

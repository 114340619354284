import React from 'react';

import { useLocale } from 'hooks/useLocale';

import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

const ModalResendActivation = ({ action, setOpenModal, targetUser }) => {
  const { setBothLocale } = useLocale();

  return (
    <Modal
      title="Resend Activation"
      description={`Resend Activation for ${targetUser?.name}?`}
      className="w-[400px]"
      eventOnClickClose={() => setOpenModal(false)}
      withPrimaryBtn={{
        title: setBothLocale('Yes'),
        dataCy: 'deactive-user',
        onClick: action
      }}
      withSecondaryBtn={{
        title: setBothLocale('No'),
        dataCy: 'cancel-deactive',
        onClick: () => setOpenModal(false)
      }}
    />
  );
};

const ModalDeactivation = ({ action, setOpenModal, targetUser }) => {
  const { setBothLocale } = useLocale();

  return (
    <Modal
      title="Deactivate User"
      description={`Do you want to deactivate ${targetUser?.name}?`}
      className="w-[400px]"
      eventOnClickClose={() => setOpenModal(false)}
      withPrimaryBtn={{
        danger: true,
        title: setBothLocale('Yes'),
        dataCy: 'deactive-user',
        onClick: action
      }}
      withSecondaryBtn={{
        title: setBothLocale('No'),
        dataCy: 'cancel-deactive',
        onClick: () => setOpenModal(false)
      }}
    />
  );
};

const ModalReactivation = ({ action, setOpenModal, targetUser }) => {
  const { setBothLocale } = useLocale();

  return (
    <Modal
      title="Reactivate User"
      description={`Do you want to reactivate ${targetUser?.name}?`}
      className="w-[400px]"
      eventOnClickClose={() => setOpenModal(false)}
      withPrimaryBtn={{
        title: setBothLocale('Yes'),
        dataCy: 'deactive-user',
        onClick: action
      }}
      withSecondaryBtn={{
        title: setBothLocale('No'),
        dataCy: 'cancel-deactive',
        onClick: () => setOpenModal(false)
      }}
    />
  );
};

const ModalSwitchTab = ({ action, setOpenModal }) => {
  const { setBothLocale } = useLocale();

  return (
    <Modal
      title="Manage Users"
      className="w-[400px]"
      eventOnClickClose={() => {
        setOpenModal(false);
      }}
      withPrimaryBtn={{
        title: 'Continue',
        dataCy: 'delete-section-button',
        onClick: action
      }}
      withSecondaryBtn={{
        title: 'Cancel',
        dataCy: 'cancel-delete-section-button',
        onClick: () => setOpenModal(false)
      }}
    >
      <p className="typography-paragraph text-n-800">
        {setBothLocale(
          'If you switch to another tab, all selected users automatically will be unselected'
        )}
      </p>
    </Modal>
  );
};

const ModalImportUser = ({
  config,
  action, //handleFileUpload
  uploadFile, // chooseFile
  setOpenModal, //setShowModalImportUser
  setCanImport,
  setSelectedFile,
  setFileErrorMessage,
  setListErrorUser,
  canImport,
  selectedFile,
  fileErrorMessage,
  listErrorUser,
  loadingButton
}) => {
  const { setBothLocale } = useLocale();

  return (
    <Modal
      eventOnClick={() => {
        setOpenModal(false);
        setCanImport(false);
      }}
      title="Upload your Employee List"
      className="w-[600px]"
      eventOnClickClose={() => {
        setOpenModal(false);
        setCanImport(false);
      }}
      withPrimaryBtn={{
        title: setBothLocale('Import Employee'),
        onClick: action,
        disabled: !canImport,
        isLoading: loadingButton
      }}
      withFileBtn={false}
      withDownloadFileBtn={{
        title: setBothLocale('Download CSV Template'),
        href:
          config?.usernameMethod === 'email'
            ? '/template/CSV-Import-Users-email.csv'
            : '/template/CSV-Import-Users-username.csv',
        fileName: 'template_csv.csv'
      }}
    >
      <div className="body-modal-import-user">
        {!selectedFile ? (
          <p className="typography-h400 typography-paragraph typography-tertiary text-center mb-[14px]">
            {setBothLocale(
              'You can add new employees in bulk by download the CSV template and upload it in this modal section'
            )}
          </p>
        ) : (
          <div className="wrapper-file-name">
            <SVGIcon
              iconName={
                fileErrorMessage
                  ? 'icon-warning_red'
                  : 'icon-insert_drive_file_black'
              }
              size={24}
              customClass="icon-file"
            />
            <p className="typography-h700 lowercase">{selectedFile.name}</p>
            <SVGIcon
              iconName="icon-clear"
              size="24"
              fillColor="var(--n-600)"
              customClass="icon-close cursor-pointer"
              onClick={() => {
                setSelectedFile(null);
                setCanImport(false);
                setFileErrorMessage('');
                setListErrorUser([]);
              }}
            />
          </div>
        )}
        {(fileErrorMessage || listErrorUser.length > 0) && (
          <div className="mt-[8px] mb-[16px] max-h-[150px] overflow-auto">
            {fileErrorMessage && (
              <p className="typography-paragraph text-center text-r-600">
                {fileErrorMessage}
              </p>
            )}
            {listErrorUser.length > 0 &&
              listErrorUser.map((error, index) => (
                <p
                  key={index}
                  className="typography-paragraph text-r-600 mb-[4px]"
                >
                  {error}
                </p>
              ))}
          </div>
        )}
        <div>
          <input
            type="file"
            name="fileToUpload"
            id="fileToUpload"
            className="input-upload"
            onChange={uploadFile}
          />
          <label htmlFor="fileToUpload" className="btn text-btn">
            <span className="typography-button">
              {setBothLocale(
                !selectedFile ? 'Click to Upload File' : 'Change File'
              )}
            </span>
          </label>
        </div>
      </div>
    </Modal>
  );
};

export {
  ModalResendActivation,
  ModalDeactivation,
  ModalReactivation,
  ModalSwitchTab,
  ModalImportUser
};

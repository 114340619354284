import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/shared/modal/Modal';

import './ModalSelectRatingType.scss';

const ModalSelectRatingType = ({ setIsModalRatingTypeOpen }) => {
  const history = useHistory();
  const {
    config: { scoringTemplateTypes, scoredAttributesFeature }
  } = useUser();
  const ratingTypeConfig = scoringTemplateTypes.map((type) =>
    type.replace(/_/g, '-')
  );

  const ratingTypeDictionary = {
    'goal-ongoing': {
      title: `${getObjectiveLocale('Goal')} Ongoing Scoring`,
      subtitle: `Set rating configuration for ${getObjectiveLocale(
        'Goal'
      ).toLowerCase()}`,
      path: 'goal-ongoing',
      isVisible: true
    },
    'task-ongoing': {
      title: `Task Ongoing Scoring`,
      subtitle: `Set rating configuration for tasks`,
      path: `task-ongoing`,
      isVisible: true
    },
    'value-recognition': {
      title: `${getObjectiveLocale('Value')} Recognition Scoring`,
      subtitle: `Set rating configuration for recognition activities`,
      path: `value-recognition`,
      isVisible: true
    },
    'goal-final': {
      title: `${getObjectiveLocale('Goal')} Final Scoring`,
      subtitle: `Set rating configuration used for track ${getObjectiveLocale(
        'Goal'
      )} Final Scoring in a review cycle.`,
      path: `goal-final`,
      isVisible: true
    },
    'task-final': {
      title: `Task Final Scoring`,
      subtitle: `Set rating configuration used for track Task Final Scoring in a review cycle.`,
      path: `task-final`,
      isVisible: true
    },
    'competency-final': {
      title: `${getObjectiveLocale('Competency')} Final Scoring`,
      subtitle: `Set rating configuration used for track ${getObjectiveLocale(
        'Competency'
      )} Final Scoring in a review cycle.`,
      path: `competency-final`,
      isVisible: true
    },
    'value-final': {
      title: `${getObjectiveLocale('Value')} Final Scoring`,
      subtitle: `Set rating configuration used for track ${getObjectiveLocale(
        'Value'
      )} Final Scoring in a review cycle.`,
      path: `value-final`,
      isVisible: true
    },
    'performance-final': {
      title: `Performance Final Scoring`,
      subtitle: `Set rating configuration used for track Summary in a review cycle.`,
      path: `performance-final`,
      isVisible: true
    },
    calibration: {
      title: `Calibration`,
      subtitle: `Set rating configuration used for Calibration phase in a review cycle.`,
      path: `calibration`,
      isVisible: true
    },
    'review-aspect': {
      title: `Scored Attributes`,
      subtitle:
        'Set rating configuration used for track Scored Attributes in a review cycle',
      path: `scored-attributes`,
      isVisible: scoredAttributesFeature
    }
  };

  const ratingType = ratingTypeConfig
    .map((type) => {
      return ratingTypeDictionary[type];
    })
    .filter((val) => val?.isVisible);

  const _onClick = (path) => {
    setIsModalRatingTypeOpen(false);
    history.push(`/reviews/rating-template/create/${path}`);
  };

  return (
    <Modal
      className="w-[600px]"
      title={'Select rating type'}
      dataCyModal={'modal-select-rating-type'}
      withCloseIcon={true}
      withFooter={false}
      eventOnClickClose={() => setIsModalRatingTypeOpen(false)}
    >
      <div className="divider-line" />

      <div className="container-list-rating-type">
        {ratingType.map((val, idx) => (
          <div
            key={idx}
            onClick={() => _onClick(val.path)}
            data-cy={'select-rating-' + val.path}
            className="wrapper-rating-type"
          >
            <p className="rating-title">{val.title}</p>
            <p className="rating-subtitle">{val.subtitle}</p>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ModalSelectRatingType;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { getOverviewObjective } from 'client/OverviewClient';

import BarChart from './BarChart';

function OverviewChart({ currentTab, selectedParams = {}, departmentParams }) {
  const [overviewData, setOverviewData] = useState([]);

  const getOverviewData = async () => {
    const type =
      currentTab === 'owned' ? currentTab : currentTab?.replace(/d$/, '');

    let params = {
      division: selectedParams?.division,
      department: [departmentParams, ...selectedParams.department],
      periodBegin: dayjs(selectedParams?.date[0]).toISOString(),
      periodEnd: dayjs(selectedParams?.date[1]).toISOString(),
      type: type,
      limit: 7
    };
    const { data } = await getOverviewObjective(params);
    if (data) {
      setOverviewData(data);
    }
  };

  useEffect(() => {
    getOverviewData();
    // eslint-disable-next-line
  }, [currentTab, selectedParams, departmentParams]);

  return (
    <BarChart
      overviewData={overviewData}
      tab={currentTab}
      currentDepartment={departmentParams}
    />
  );
}

export default OverviewChart;

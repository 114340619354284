import dayjs from 'dayjs';

const getQuarter = (date = new Date()) => {
  return Math.floor(date.getMonth() / 3 + 1);
};

const daysLeftInQuarter = (date = new Date()) => {
  const quarter = getQuarter(date);

  const nextQuarter = new Date();

  if (quarter === 4) {
    nextQuarter.setFullYear(date.getFullYear() + 1, 0, 1);
  } else {
    nextQuarter.setFullYear(date.getFullYear(), quarter * 3, 1);
  }

  const ms1 = date.getTime();
  const ms2 = nextQuarter.getTime();

  return Math.floor((ms2 - ms1) / (24 * 60 * 60 * 1000));
};

const getRange = quarter => {
  let result = [0, 0];
  switch (quarter) {
    case 1:
      result = [0, 2];
      break;
    case 2:
      result = [3, 5];
      break;
    case 3:
      result = [6, 8];
      break;
    case 4:
      result = [9, 11];
      break;
    default:
      break;
  }
  return [
    dayjs()
      .month(result[0])
      .format('MMMM'),
    dayjs()
      .month(result[1])
      .format('MMMM')
  ];
};

const getQuarterRangeMonth = quarter => {
  const [start, end] = getRange(quarter);
  return start + ' - ' + end;
};

export { daysLeftInQuarter, getQuarterRangeMonth, getRange };

import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Redirect, useLocation, withRouter } from 'react-router-dom';

import qs from 'qs';

import {
  activationUser,
  forgotPassword,
  getActivationUser,
  getResetPassword,
  loginUser,
  resetPassword,
  sendActivation,
  submitEmail,
  submitOrganization,
  submitRegistration,
  userInfo
} from 'client/AuthClient';
import { useAuth } from 'context/AuthContext';
import { AuthPageProvider, UseAuthPage } from 'context/AuthPageContext';
import { useUser } from 'context/UserContext';
import {
  capitalize,
  getDeviceId,
  getENV,
  getObjectiveLocale,
  getProvisionToken,
  getUserToken,
  validateEmail
} from 'utils/HelperUtils';
import env from 'utils/env';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import Modal from 'components/shared/modal/Modal';
import { useLocale } from 'src/hooks/useLocale';
import { trackEvent } from 'src/utils/AnalyticUtils';
import Config from 'src/utils/Config';

import AuthAppliBCA from './AuthAppliBCA';
import AuthForgotPassword from './AuthForgotPassword';
import AuthOrganization from './AuthOrganization';
import AuthPassword from './AuthPassword';
import AuthRegistration from './AuthRegistration';
import AuthResetAndActivasion from './AuthResetAndActivasion';
import AuthSSO from './AuthSSO';
import AuthUser from './AuthUser';

function AuthPage(props) {
  const { data } = useAuth();
  const { config } = useUser();
  const { staticContext, ...authPageProps } = props;

  return (
    <div
      className="auth-page-ui flex items-center justify-center"
      {...authPageProps}
    >
      <div className="auth-container">
        <AuthPageProvider>
          <AuthCardComponent {...authPageProps} />
        </AuthPageProvider>
        <div>
          <p className="copyright-text">
            {getObjectiveLocale(
              'If you find any difficulty, please tell us at'
            )}
            <span className="copyright-text ml-[4px]">
              {config?.supportEmail || getObjectiveLocale('support@happy5.co')}
            </span>
          </p>
          {data?.organization?.identifier !== 'bca' && (
            <p className="copyright-text">
              {getObjectiveLocale('Made with ♥ by')}{' '}
              {getObjectiveLocale('Happy5')}
            </p>
          )}
          <p className="typography-h100 text-n-600 text-center">
            {process.env.ENV_VERSION}
          </p>
        </div>
      </div>
    </div>
  );
}

const AuthCardComponent = (props) => {
  const { setBothLocale } = useLocale();
  const { data: authData, loginWithToken, checkUseSSO } = useAuth();
  const { organization: organizationSubDomain, config } = useUser();
  const {
    organization,
    email,
    password,
    confirmPassword,
    resetInputState,
    handleOnChange,
    trueEmail,
    birthdate,
    managerEmail,
    workUnit
  } = UseAuthPage();
  const { search } = useLocation();
  let organizationName = search.substring(search.indexOf('=') + 1);
  // state auth organization
  const [currentOrganization, setCurrentOrganization] =
    useState(organizationName);
  const [dataOrganization, setDataOrganization] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // state warning message
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  // state shaking error input animation
  const [errorShake, setErrorShake] = useState(false);

  // state email / reset password / activation has been sent / modal email has been sent
  const [isSuccessForgotPassword, setIsSuccessForgotPassword] = useState(false);
  const [isSuccessSent, setIsSuccessSent] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [deviceToken, setDeviceToken] = useState('');
  const [isModalShow, setIsModalShow] = useState(false);

  // CAPTCHA
  // Cypress test should not use captcha
  // Force local development or netlify to use captcha
  // to prevent error while login, on organization which has captcha enabled
  const isCaptchaEnabled =
    !window.Cypress && organizationSubDomain?.enableCaptcha;
  const recaptchaRef = React.useRef();

  let match = props.match.path;
  let linkButton = true;
  let linkButtonName;
  let handleSubmitFooter;
  let token = qs.parse(location.search, { ignoreQueryPrefix: true }).token;

  let imgLogo = authData?.appType
    ? authData?.appType === 'performance'
      ? authData?.organization?.logoUrl
      : authData?.organization?.cultureLogoUrl
    : authData?.organization?.companyLogo ||
      dataOrganization?.companyLogo ||
      Config.asset.general.logoHappy5;

  const currentApp = authData?.organization?.currentApp;

  const useSSOMechanism = checkUseSSO();

  if (match === '/login/email' && useSSOMechanism) {
    props.history.push('/login/auth');
  } else if (match === '/login/auth' && !useSSOMechanism) {
    props.history.push('/login/email');
  }

  const routeToOrganization = () => {
    props.history.push('/login/organization');
    setShowWarning(false);
    setIsSuccessSent(false);
    setIsSuccessForgotPassword(false);
    setIsTokenValid(true);
    resetInputState();
  };

  const routeToRecovery = () => {
    props.history.push('/login/recovery');
    trackEvent({
      event: 'forgot password',
      env: currentApp
    });
    setShowWarning(false);
    setIsSuccessSent(false);
    setIsTokenValid(true);
  };

  const validatePassword = (password) => {
    const Regex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))/;
    return Regex.test(password);
  };

  const setTimeErrorShake = () => {
    setErrorShake(true);
    setTimeout(function () {
      setErrorShake(false);
    }, 1000);
  };

  const handleSubmitEmail = async (e) => {
    let captcha;

    // if there is subdomain or isBCA then we can get captcha in submit email process
    // other than that we need to submit captcha in the submit organization steps
    const shouldUseCaptcha =
      isCaptchaEnabled && (organizationSubDomain || getENV('IS_BCA'));

    if (shouldUseCaptcha) {
      try {
        captcha = await recaptchaRef?.current?.executeAsync();
      } catch (error) {
        // eslint-disable-next-line
        console.log('captcha error', error);
      }
    }

    const usernameMethod = getENV('IS_BCA')
      ? 'username'
      : config?.usernameMethod;
    if (email === '') {
      // handler when the input field is empty
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Email should not be empty'));
      setTimeErrorShake();
      trackEvent({
        event: 'login email',
        eventProperties: { status: 'fail', value: email },
        env: currentApp
      });
    } else if (usernameMethod != 'username' && !validateEmail(email)) {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Email address in invalid format'));
      setTimeErrorShake();
      trackEvent({
        event: 'login email',
        eventProperties: { status: 'fail', value: email },
        env: currentApp
      });
    } else if (
      (organizationSubDomain?.enableCaptcha ||
        process.env.NODE_ENV === 'development') &&
      captcha == ''
    ) {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Captcha validation is required'));
      setTimeErrorShake();
    } else {
      setShowWarning(false);
      setIsLoading(true);

      let data;
      if (organizationSubDomain || getENV('IS_BCA')) {
        const organization = getENV('IS_BCA')
          ? 'bca'
          : organizationSubDomain?.identifier;
        data = await submitOrganization(email, organization, captcha);
        setIsLoading(false);
      } else {
        data = await submitEmail(email, captcha);

        if (data?.data?.multipleOrganization) {
          setIsLoading(false);
          trackEvent({
            event: 'login email',
            eventProperties: { status: 'success', value: email },
            env: currentApp
          });
          return props.history.push('/login/organization');
        }
        setIsLoading(false);
      }
      let error = data?.error;
      data = data?.data;

      if (data) {
        trackEvent({
          event: 'login email',
          eventProperties: { status: 'success', value: email },
          env: currentApp
        });
        handleOnChange('organization', data.organization.teamName);
        setCurrentOrganization(data.organization.teamName);
        setDataOrganization(data.organization);

        if (data?.registrationMethod === 'date_of_birth') {
          props.history.push('/login/birthdate');
        } else {
          props.history.push('/login/password');
        }
      } else {
        // reset captcha
        if (shouldUseCaptcha) {
          captcha = await recaptchaRef?.current?.reset();
        }

        trackEvent({
          event: 'login email',
          eventProperties: { status: 'fail', value: email },
          env: currentApp
        });
        setShowWarning(true);
        if (error.code == 404) {
          setWarningMessage(getObjectiveLocale('Email not found'));
        } else {
          setWarningMessage(getObjectiveLocale(error?.message));
        }
      }
    }
  };

  const handleSubmitEmailRegistration = async (e) => {
    if (trueEmail === '') {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Email should not be empty'));
      setTimeErrorShake();
    } else if (!validateEmail(trueEmail)) {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Email address in invalid format'));
      setTimeErrorShake();
    } else {
      setShowWarning(false);
      setIsLoading(true);

      const { error, isSuccess } = await sendActivation({
        email: email,
        trueEmail: trueEmail,
        identifier: authData?.organization.identifier
      });

      if (isSuccess) {
        setIsModalShow(true);
        resetInputState();
      } else {
        setShowWarning(true);
        if (error.code == 404) {
          setWarningMessage(
            getObjectiveLocale('Email registration is invalid')
          );
        } else {
          setWarningMessage(getObjectiveLocale(error?.message));
        }
      }
      setIsLoading(false);
    }
  };

  const handleSubmitBirthDate = async (e) => {
    if (birthdate === '') {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Birth date should not be empty'));
      setTimeErrorShake();
    } else {
      setShowWarning(false);
      setIsLoading(true);
      const { data, error } = await submitRegistration({
        email: email,
        dateOfBirth: birthdate || null
      });

      if (data) {
        props.history.push('/login/manager-registration');
      } else {
        if (error.code == 404) {
          setShowWarning(true);
          setWarningMessage(getObjectiveLocale('Birth date is invalid'));
        }
      }
      setIsLoading(false);
    }
  };

  const handleSubmitWorkUnit = async (e) => {
    if (workUnit === '') {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Work unit should not be empty'));
      setTimeErrorShake();
    } else {
      setShowWarning(false);
      setIsLoading(true);

      const { data, error } = await submitRegistration({
        email: email,
        dateOfBirth: birthdate || null,
        workUnitId: workUnit?.id
      });

      if (data) {
        props.history.push('/login/email-registration');
      } else {
        setShowWarning(true);
        setWarningMessage(error?.message);
      }
      setIsLoading(false);
    }
  };

  const handleSubmitManagerRegistration = async (e) => {
    if (managerEmail === '') {
      setShowWarning(true);
      setWarningMessage(
        getObjectiveLocale('Manager personal number should not be empty')
      );
      setTimeErrorShake();
    } else {
      setShowWarning(false);
      setIsLoading(true);
      const { data, error } = await submitRegistration({
        email: email,
        dateOfBirth: birthdate || null,
        managerPersonalNumber: managerEmail === 'none' ? null : managerEmail
      });

      if (data) {
        handleOnChange('workUnitList', data?.workUnits);
        setIsLoading(false);
        setTimeout(() => {
          props.history.push('/login/workunit');
        }, 300);
      } else {
        if (error.code == 404) {
          setShowWarning(true);
          setWarningMessage(getObjectiveLocale('Email manager not found'));
        }
      }
      setIsLoading(false);
    }
  };

  const handleSubmitOrganization = async (e) => {
    let captcha;

    if (isCaptchaEnabled) {
      try {
        captcha = await recaptchaRef?.current?.executeAsync();
      } catch (error) {
        // eslint-disable-next-line
        console.log('captcha error', error);
      }
    }

    if (organization === '') {
      setShowWarning(true);
      setWarningMessage(
        getObjectiveLocale('Organization name should not be empty')
      );
      setTimeErrorShake();
    } else {
      setShowWarning(false);
      setIsLoading(true);
      const { data } = await submitOrganization(email, organization, captcha);
      if (data) {
        setIsLoading(false);
        trackEvent({
          event: 'login orgname',
          eventProperties: {
            status: 'success',
            value: organization.toLowerCase()
          },
          env: currentApp
        });
        setCurrentOrganization(organization);
        setDataOrganization(data.organization);
        props.history.push('/login/password');
      } else {
        // reset captcha
        if (isCaptchaEnabled) {
          captcha = await recaptchaRef.current.reset();
        }

        setIsLoading(false);
        trackEvent({
          event: 'login orgname',
          eventProperties: {
            status: 'fail',
            value: organization.toLowerCase()
          },
          env: currentApp
        });
        setShowWarning(true);
        setWarningMessage('Organization not found');
        setTimeErrorShake();
      }
    }
  };

  const handleSubmitTokenAppli = async (e) => {
    setIsLoading(true);
    let body = {
      deviceType: 'web',
      email: email,
      password: password,
      deviceToken: deviceToken,
      userToken: getUserToken(),
      deviceId: getDeviceId()
    };
    const isError = await loginWithToken(body, organization);

    if (isError) {
      if (isError.error) {
        setShowWarning(true);
        setWarningMessage(isError.error.message);
      }
    }
    setIsLoading(false);
  };

  const handleSubmitUser = async (e) => {
    if (password === '') {
      // handler when the input field is empty
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Password should not be empty'));
      setTimeErrorShake();
    } else {
      setIsLoading(true);
      let body = {
        organization:
          authData?.organization?.identifier?.toLowerCase() ||
          currentOrganization?.toLowerCase(),
        email: email,
        password: password
      };
      if (getProvisionToken() !== 'null') {
        body = {
          ...body,
          provisionToken: getProvisionToken()
        };
      }
      const { data, error } = await loginUser(body);
      if (data) {
        setShowWarning(false);

        if (data.userToken) {
          setIsLoading(false);
          window.localStorage.setItem('userToken', data.userToken);
          props.history.push('/login/appli');
        } else {
          let token = data.token;
          const authorizationMethod = getENV('AUTHORIZATION_METHOD');
          if (authorizationMethod === 'bearer') {
            localStorage.setItem('bearer', token);
          }

          let userData = {
            email: data.user.email,
            organizationName: data.organization.name
          };

          window.localStorage.setItem('userData', JSON.stringify(userData));
          window.localStorage.setItem('provisionToken', data.provisionToken);
          let prevUrl =
            localStorage.getItem('prevUrlRedirect') || location.origin;
          localStorage.removeItem('prevUrlRedirect');

          // This block of code below, supposed to be a temporary solution (for BRI Renewal)
          if (
            (data.user.email == 'indra.utoyo@bri.co.id' &&
              data.organization.identifier == 'bri') ||
            (data.user.email == 'test+richard@happy5.co' &&
              data.organization.identifier == 'piedpiper')
          ) {
            prevUrl = '/explorer';
          }
          // end of block
          location.href = prevUrl?.includes('recovery') ? '/' : prevUrl;
        }
      } else {
        if (error.code === 401) {
          setShowWarning(true);
          setWarningMessage(
            getObjectiveLocale(
              'The combination of email and password you have entered is incorrect'
            )
          );
          setTimeErrorShake();
        } else if (error.code === 422) {
          setShowWarning(true);
          setWarningMessage(getObjectiveLocale('User is not active'));
          setTimeErrorShake();
        } else if (error.code === 404) {
          setShowWarning(true);
          setWarningMessage(getObjectiveLocale('Email not found'));
          setTimeErrorShake();
        }
        setIsLoading(false);
      }
    }
  };

  const handleSubmitForgotPassword = async (e) => {
    if (email === '') {
      setShowWarning(true);
      setWarningMessage(
        getObjectiveLocale('Email or password should not be empty')
      );
    } else if (config?.usernameMethod !== 'username' && !validateEmail(email)) {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Email address in invalid format'));
    } else {
      setIsLoading(true);
      setShowWarning(false);
      let organization =
        authData?.organization?.identifier.toLowerCase() ||
        currentOrganization?.toLowerCase();
      const data = await forgotPassword(organization, email);
      setIsSuccessForgotPassword(true);
      setIsLoading(false);
    }
  };

  const handleSubmitResetPassword = async () => {
    if (password !== confirmPassword) {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Password do not match'));
    } else if (!validatePassword(password)) {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Password is in invalid format'));
    } else if (password === '') {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Password should not be empty'));
    } else {
      setIsLoading(true);
      setShowWarning(false);
      let dataUser = await userInfo(token);
      setDataOrganization(dataUser);
      let { data, error } = await resetPassword(token, password);

      if (data) {
        setIsSuccessSent(true);
      }
      setIsLoading(false);
    }
  };

  const handleSubmitActivation = async () => {
    if (password !== confirmPassword) {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Password do not match'));
    } else if (!validatePassword(password)) {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Password is in invalid format'));
    } else if (password === '') {
      setShowWarning(true);
      setWarningMessage(getObjectiveLocale('Password should not be empty '));
    } else {
      setIsLoading(true);
      setShowWarning(false);
      let dataUser = await getActivationUser(token);
      setDataOrganization(dataUser);
      let { data } = await activationUser(token, password);
      if (data) {
        setIsSuccessSent(true);
      }
      setIsLoading(false);
    }
  };

  const handleSubmitDirectSSO = () => {
    if (organizationSubDomain?.loginUrl)
      window.location = organizationSubDomain?.loginUrl;
  };

  // body condition
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitOnKeyPress(match);
    }
  };

  const handleSubmitOnKeyPress = (match) => {
    switch (match) {
      case '/login/organization':
        handleSubmitOrganization();
        break;
      case '/login/appli':
        handleSubmitTokenAppli();
        break;
      case '/login/email':
        handleSubmitEmail();
        break;
      case '/login/password':
        handleSubmitUser();
        break;
      case '/login/recovery':
        handleSubmitForgotPassword();
        break;
      case '/recovery':
        handleSubmitResetPassword();
        break;
      case '/activation':
        handleSubmitActivation();
        break;
      case '/login/birthdate':
        handleSubmitBirthDate();
        break;
      case '/login/email-registration':
        handleSubmitEmailRegistration();
        break;
      case '/login/workunit':
        handleSubmitWorkUnit();
        break;
      case '/login/manager-registration':
        handleSubmitManagerRegistration();
        break;
    }
  };

  const getDataResetPassword = async () => {
    const { error } = await getResetPassword(token);
    if (error) {
      if (error.code === 401) {
        setIsTokenValid(false);
      }
    }
  };

  const getDataActivationUser = async () => {
    const { error } = await getActivationUser(token);
    if (error) {
      if (error.code === 401) {
        setIsTokenValid(false);
      }
    }
  };

  useEffect(() => {
    if (match === '/recovery') {
      getDataResetPassword();
    } else if (match === '/activation') {
      getDataActivationUser();
    }
  }, []);

  // footer condition
  switch (match) {
    case '/login/appli':
      linkButtonName = getObjectiveLocale('Back to login');
      handleSubmitFooter = handleSubmitTokenAppli;
      break;
    case '/login/organization':
      linkButtonName = getObjectiveLocale('Back to login');
      handleSubmitFooter = handleSubmitOrganization;
      break;
    case '/login/password':
      linkButtonName = getObjectiveLocale('Back to login');
      handleSubmitFooter = handleSubmitUser;
      break;
    case '/login/email':
      linkButton = false;
      handleSubmitFooter = handleSubmitEmail;
      break;
    case '/login/birthdate':
      linkButtonName = getObjectiveLocale('Back to login');
      handleSubmitFooter = handleSubmitBirthDate;
      break;
    case '/login/recovery':
      linkButtonName = getObjectiveLocale('Back to login');
      handleSubmitFooter = handleSubmitForgotPassword;
      break;
    case '/recovery':
      linkButtonName = getObjectiveLocale('Back to login');
      handleSubmitFooter = handleSubmitResetPassword;
      break;
    case '/activation':
      linkButton = false;
      handleSubmitFooter = handleSubmitActivation;
      break;
    case '/login/auth':
      linkButton = false;
      handleSubmitFooter = handleSubmitDirectSSO;
      break;
    case '/login/email-registration':
      linkButtonName = getObjectiveLocale('Back to login');
      handleSubmitFooter = handleSubmitEmailRegistration;
      break;
    case '/login/workunit':
      linkButtonName = getObjectiveLocale('Back to login');
      handleSubmitFooter = handleSubmitWorkUnit;
      break;
    case '/login/manager-registration':
      linkButtonName = getObjectiveLocale('Back to login');
      handleSubmitFooter = handleSubmitManagerRegistration;
      break;
  }

  const propsFooter = {
    isSuccessForgotPassword,
    isSuccessSent,
    isTokenValid,
    linkButton,
    routeToOrganization,
    linkButtonName,
    handleSubmitFooter,
    isLoading
  };
  return (
    <>
      <div className="auth-card-container !mb-[60px]">
        {isCaptchaEnabled &&
          (match == '/login/email' || match == '/login/organization') && (
            <div className="fixed right-[0px] bottom-[0px]">
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={env.RECAPTCHA_SITE_KEY}
              />
            </div>
          )}
        <div className="flex justify-center">
          <AWSImage
            src={imgLogo}
            className="max-w-[200px] h-[80px] object-contain mt-[24px] mb-[12px]"
          />
        </div>
        <div className="auth-body" {...props}>
          {match === '/login/auth' && (
            <>
              <AuthSSO
                errorShake={errorShake}
                warningMessage={warningMessage}
                showWarning={showWarning}
                routeToRecovery={routeToRecovery}
                handleKeyPress={handleKeyPress}
              />
              <Button
                datacy="submit"
                data-testid="submit"
                onClick={handleSubmitFooter}
                customClass="w-full"
              >
                {organizationSubDomain?.loginButtonImageUrl && (
                  <AWSImage
                    className="w-[24px] h-[24px] mr-[8px]"
                    src={organizationSubDomain?.loginButtonImageUrl}
                  />
                )}
                {getObjectiveLocale('Sign in with')}{' '}
                {capitalize(organizationSubDomain?.loginType)}
              </Button>
            </>
          )}
          {match === '/login/appli' &&
            (email !== '' ? (
              <AuthAppliBCA
                errorShake={errorShake}
                warningMessage={warningMessage}
                showWarning={showWarning}
                routeToRecovery={routeToRecovery}
                handleKeyPress={handleKeyPress}
                setDeviceToken={setDeviceToken}
              />
            ) : (
              <Redirect to="/login/email" />
            ))}
          {match === '/login/organization' &&
            (email !== '' ? (
              <AuthOrganization
                showWarning={showWarning}
                errorShake={errorShake}
                warningMessage={warningMessage}
                handleKeyPress={handleKeyPress}
              />
            ) : (
              <Redirect to="/login/email" />
            ))}
          {match === '/login/password' &&
            (email !== '' ? (
              <AuthPassword
                errorShake={errorShake}
                warningMessage={warningMessage}
                showWarning={showWarning}
                routeToRecovery={routeToRecovery}
                handleKeyPress={handleKeyPress}
                data={dataOrganization}
              />
            ) : (
              <Redirect to="/login/email" />
            ))}
          {(match === '/login/email' ||
            match === '/login/email-registration' ||
            match === '/login/manager-registration') && (
            <AuthUser
              errorShake={errorShake}
              warningMessage={warningMessage}
              setShowWarning={setShowWarning}
              showWarning={showWarning}
              routeToRecovery={routeToRecovery}
              handleKeyPress={handleKeyPress}
              match={match}
            />
          )}
          {match === '/login/birthdate' && (
            <AuthRegistration
              errorShake={errorShake}
              warningMessage={warningMessage}
              showWarning={showWarning}
              routeToRecovery={routeToRecovery}
              handleKeyPress={handleKeyPress}
              type="birthdate"
            />
          )}
          {match === '/login/workunit' && (
            <AuthRegistration
              errorShake={errorShake}
              warningMessage={warningMessage}
              showWarning={showWarning}
              routeToRecovery={routeToRecovery}
              handleKeyPress={handleKeyPress}
              type="workUnit"
            />
          )}
          {match === '/login/recovery' &&
            (email !== '' ? (
              <AuthForgotPassword
                warningMessage={warningMessage}
                showWarning={showWarning}
                handleKeyPress={handleKeyPress}
                isSuccessForgotPassword={!isSuccessForgotPassword}
                routeToOrganization={routeToOrganization}
              />
            ) : (
              <Redirect to="/login/email" />
            ))}
          {(match === '/recovery' || match === '/activation') && (
            <AuthResetAndActivasion
              match={match}
              warningMessage={warningMessage}
              showWarning={showWarning}
              handleKeyPress={handleKeyPress}
              isSuccessSent={isSuccessSent}
              isTokenValid={isTokenValid}
              routeToOrganization={routeToOrganization}
              data={dataOrganization}
            />
          )}
        </div>
        {match !== '/login/auth' && <Footer {...propsFooter} />}
        {isModalShow && (
          <Modal
            title={getObjectiveLocale('Email has been sent')}
            description={getObjectiveLocale(
              'Please check your email to activate your account.'
            )}
            withCloseIcon={false}
            className="w-[400px]"
            withPrimaryBtn={{
              title: getObjectiveLocale('Ok'),
              dataCy: 'back-to-login',
              onClick: () => {
                setIsModalShow(false), props.history.replace('/login/email');
              }
            }}
          />
        )}
      </div>
      {authData?.organization?.companyLogo && (
        <div className="mb-[24px] flex justify-center items-center flex-col">
          <p className="typography-h400 text-n-800 mb-[8px] text-center">
            {setBothLocale('Brought to you by')}
          </p>
          <AWSImage
            src={
              authData?.organization?.companyLogo ||
              dataOrganization?.companyLogo
            }
            className="max-w-[360px] h-[74px] object-contain"
          />
        </div>
      )}
    </>
  );
};

const Footer = ({
  isSuccessForgotPassword,
  isSuccessSent,
  isTokenValid,
  linkButton,
  routeToOrganization,
  linkButtonName,
  handleSubmitFooter,
  isLoading
}) => {
  return (
    <>
      <div
        className="footer-container"
        style={{
          display:
            isSuccessForgotPassword || isSuccessSent || !isTokenValid
              ? 'none'
              : 'block'
        }}
      >
        {linkButton && (
          <Button.Tertiary
            customClass="footer-link-button"
            datacy="changeOrg"
            onClick={routeToOrganization}
          >
            {linkButtonName}
          </Button.Tertiary>
        )}
        <Button
          customClass="footer-button flex items-center"
          datacy="submit"
          data-testid="submit"
          onClick={handleSubmitFooter}
          isLoading={isLoading}
        >
          {getObjectiveLocale('Sign In')}
        </Button>
      </div>
    </>
  );
};

export default withRouter(AuthPage);

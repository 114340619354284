import React from 'react';

import { getCultureLocale } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';

function PostLeaderboard({ content, postId }) {
  const isMostAwesome = content.context === 'most_awesome';
  const rankLength = content.scores.length;
  const others = content.scores.filter((score) => score.rank !== 1);

  const SetPhotoRank = ({ userName, profilePicture, rank, isTop, dataCy }) => {
    return (
      <div
        className={`photo-container ${isTop ? 'top-photo' : 'other-photo'}`}
        key={rank}
      >
        <div className="photo" key={`photo-${rank}`}>
          <Avatar
            name={userName}
            src={profilePicture}
            className="profile-img icon-target"
            dataCy={dataCy}
            size={isTop ? 72 : 48}
          />
        </div>
        <div className="rank" data-cy={`rank-${rank}`}>
          {rank}
        </div>
      </div>
    );
  };

  return (
    <div className="feed-leaderboard" data-cy={`post-leaderboard-${postId}`}>
      <div className={`top-section ${isMostAwesome ? 'awesome' : 'generous'}`}>
        <div className="top-text-title">
          <span className="typography-h500">
            {getCultureLocale('The Most').toUpperCase()}
          </span>
          <span className="typography-h700 caption" data-cy="context">
            {isMostAwesome
              ? getCultureLocale('Awesome').toUpperCase()
              : getCultureLocale('Generous').toUpperCase()}
          </span>
          <span className="typography-h500" data-cy="period">
            {getCultureLocale('Of The Week')}
          </span>
        </div>
        <SetPhotoRank
          userName={content.scores[rankLength - 1].user.fullName}
          profilePicture={
            content.scores[rankLength - 1].user.profilePicture
              ? content.scores[rankLength - 1].user.profilePicture.secureUrl
              : null
          }
          rank={content.scores[rankLength - 1].rank}
          isTop={true}
          dataCy="top-photo"
        />
        <p className="typography-h700 top-name" data-cy="top-name">
          {content.scores[rankLength - 1].user.fullName}
        </p>
        <div className="top-recognition-given">
          <span className="typography-h500">
            {getCultureLocale('Recognition Given')}
          </span>
          <span className="typography-h200" data-cy="top-recognition">
            {getCultureLocale('Total Recognition').replace(
              /%d|% D/g,
              content.scores[rankLength - 1].score
            )}
            {content.scores[rankLength - 1].score > 1 && 's'}
          </span>
        </div>
      </div>
      <div className="other-section">
        {others.map((other) => {
          return (
            <div className="other-ranking" key={other.id}>
              <div className="other-detail">
                <SetPhotoRank
                  userName={other.user.fullName}
                  profilePicture={
                    other.user.profilePicture
                      ? other.user.profilePicture.secureUrl
                      : null
                  }
                  rank={other.rank}
                  dataCy={`other-photo-${other.rank}`}
                />
                <span
                  className="typography-h500 other-name"
                  data-cy={`other-name-${other.rank}`}
                >
                  {other.user.fullName}
                </span>
              </div>
              <span
                className="typography-h200"
                data-cy={`other-recognition-${other.rank}`}
              >
                {getCultureLocale('Total Recognition').replace(
                  /%d|% D/g,
                  other.score
                )}
                {other.score > 1 && 's'}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PostLeaderboard;

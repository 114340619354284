const today = new Date();
export let thisYear = today.getFullYear();

export const pastYearRange = range => thisYear - range;

export const arrayRangeYears = range => {
  let arrayYears = [];
  let pastYear = pastYearRange(range);
  let currentYear = thisYear;

  for (let i = 0; pastYear < currentYear; i++) {
    arrayYears.push({
      id: i,
      name: currentYear.toString(),
      value: currentYear
    });
    currentYear--;
  }
  return arrayYears;
};

export const arrayPastYearsRange = range => {
  let arrayYears = [];
  let pastYear = pastYearRange(range);
  let currentYear = thisYear - 1;
  
  for (let i = 0; pastYear <= currentYear; i++) {
    arrayYears.push({
      id: i,
      name: currentYear.toString(),
      value: currentYear
    });
    currentYear--;
  }
  return arrayYears;
}

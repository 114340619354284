import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import { getDetailStrength, getDetailWeakness } from 'client/UserProfile';
import { useUser } from 'context/UserContext';

import SVGIcon from 'components/shared/SVGIcon';
import WhiteCard from 'components/shared/card/WhiteCardEvidence.js';

import ProfileEvidence from './ProfileEvidence';

const BehaviorCard = ({ behavior, targetId, behaviorType, type, year }) => {
  const [behaviorData, setBehaviorData] = useState([]);
  const { user } = useUser();
  const history = useHistory();

  const getData = async () => {
    const params = {
      periodBegin: dayjs(`${year.value}-01-01`).startOf('year').toISOString(),
      periodEndBefore: dayjs(`${year.value}-01-01`).endOf('year').toISOString()
    };
    let fn = type == 'recognition' ? getDetailStrength : getDetailWeakness;
    const { data } = await fn(
      targetId,
      behaviorType,
      behavior.behaviorId,
      params
    );
    setBehaviorData(data);
  };

  const getSource = (data) => {
    if (data?.objective) return getObjectivesLabel(data.objective);
    return <p>{data?.source}</p>;
  };

  const getObjectivesLabel = (objective) => {
    return (
      <p
        onClick={() => {
          history.replace(`${history.location.pathname}/${objective.id}`);
        }}
        className={'text-base-600 cursor-pointer'}
      >
        {objective.name}
      </p>
    );
  };

  useEffect(() => {
    getData();
  }, [behaviorType, type]);

  return (
    <WhiteCard title={behavior.behaviorTitle}>
      <div
        className={`${
          type == 'recognition' ? 'bg-g-200' : 'bg-y-200'
        } flex items-center h-[20px] width-fit-content rounded-[4px] px-[8px]`}
      >
        <div
          className={`flex ${
            type == 'recognition' ? 'text-g-800' : 'text-y-800'
          } items-center`}
        >
          <SVGIcon
            iconName={
              type == 'recognition' ? 'icon-thumb_up_alt' : 'icon-feedback'
            }
            size="16"
            fillColor={type == 'recognition' ? 'var(--g-600)' : 'var(--y-600)'}
          />
          <span className="ml-[4px] typography-h100">
            <span className="typography-h200">{behavior.performanceCount}</span>{' '}
            performance
          </span>
          {type == 'recognition' && user?.cultureRole && (
            <>
              <span className="mx-[8px]">·</span>
              <span className="typography-h100">
                <span className="typography-h200">{behavior.cultureCount}</span>{' '}
                culture
              </span>
            </>
          )}
        </div>
      </div>

      {behaviorData.map((data, index) => {
        return (
          <div className="mt-[16px]">
            <ProfileEvidence profileData={data?.fromUser} />
            <div className="pl-[40px]">
              <p className="typography-h100 typography-tertiary pt-[12px]">
                Source:{' '}
              </p>
              {getSource(data)}

              {data.comment && (
                <p className="typography-paragraph mt-[8px] break-words">
                  {data.comment}
                </p>
              )}

              {index != behaviorData.length - 1 && (
                <div className="border-bottom-n-400 pb-[16px]" />
              )}
            </div>
          </div>
        );
      })}

      {behaviorData.length == 0 && (
        <div className="flex justify-center mt-[16px]">
          <span className="typography-paragraph typography-tertiary">
            No {type} received yet
          </span>
        </div>
      )}
    </WhiteCard>
  );
};

export default BehaviorCard;

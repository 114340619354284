import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

import { UseAuthPage } from 'context/AuthPageContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

const AuthOrganization = (props) => {
  const { handleOnChange } = UseAuthPage();

  useEffect(() => {
    handleOnChange('organization', '');
    let input = document.getElementById('organization');
    input.focus();
  }, []);

  return (
    <Fragment>
      <span className="auth-organization-title">
        {getObjectiveLocale('Organization Name')}
      </span>
      <span className="auth-organization-subtitle" data-cy="input-login-data">
        {getObjectiveLocale('Please submit your organization name.')}
      </span>
      <div className="container-input">
        <label
          className="styled-label"
          htmlFor={'organization'}
          data-cy="organization"
        >
          {getObjectiveLocale('Organization Name')}
        </label>
        <input
          className={`styled-input ${
            props.errorShake ? 'input-error-shake' : ''
          }`}
          name={'organization'}
          data-cy={'auth-organization'}
          id={'organization'}
          type={'text'}
          onChange={(e) => handleOnChange(e.target.name, e.target.value)}
          onKeyUp={props.handleKeyPress}
          placeholder={'e.g. Happy5'}
        />
      </div>
      {props.showWarning && (
        <div className="container-error">
          <span data-cy="org-not-found" className="error-message">
            {props.warningMessage}
          </span>
        </div>
      )}
    </Fragment>
  );
};

AuthOrganization.contextTypes = {
  handleChange: PropTypes.func
};

AuthOrganization.propTypes = {
  handleClickListOrganization: PropTypes.func,
  handleDeleteListOrganization: PropTypes.func,
  handleKeyPress: PropTypes.func,
  warningMessage: PropTypes.string,
  showWarning: PropTypes.bool
};

export default withRouter(AuthOrganization);

import React, { useState } from 'react';

import { useUser } from 'context/UserContext';

import TwoLine from 'components/design-system/ProfileType';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import Modal from 'components/shared/modal/Modal';

import SVGIcon from '../../shared/SVGIcon';

const groupPhaseDependencies = (array) => {
  return array?.reduce((res, assignment) => {
    const phaseType = assignment.formalReviewPhase.type;
    if (phaseType in res) {
      res[phaseType].push(assignment.actor);
    } else {
      res[phaseType] = [assignment.actor];
    }
    return res;
  }, {});
};

const groupCycleDependencies = (cycle, index) => {
  let count = 0;
  let dependencies = cycle.phases?.reduce((res, phase) => {
    res[phase.type] = phase.assignments.map((assignment) => assignment.actor);
    count += phase.assignments.length;
    return res;
  }, {});

  return {
    count,
    dependencies,
    name: cycle.name,
    visible: index === 0
  };
};

const BlockerListModal = ({
  cycleName,
  phaseDependencies,
  cycleDependencies,
  assignmentDependencies,
  setShowModal
}) => {
  const { user } = useUser();
  const phaseTypes = [
    { key: 'self_review', name: 'SELF' },
    { key: 'peer_review', name: 'PEER' },
    { key: 'upward_review', name: 'UPWARD' },
    { key: 'manager_review', name: 'MANAGER' },
    { key: 'indirect_manager_review', name: 'INDIRECT MANAGER' }
  ];
  const blockedByAssignment = assignmentDependencies?.length > 0;
  const blockedByCycle = cycleDependencies?.length > 0;
  const blockerList = blockedByCycle
    ? cycleDependencies.map(groupCycleDependencies)
    : [
        {
          name: cycleName,
          dependencies: groupPhaseDependencies(phaseDependencies),
          visible: true
        }
      ];

  const [blockers, setBlockers] = useState(blockerList);

  const clickChevron = (blockerIndex) => {
    document
      .getElementById(`blocker-${blockerIndex}`)
      ?.classList?.toggle('-rotate-90');
    setBlockers((prev) => [
      ...prev.slice(0, blockerIndex),
      { ...prev[blockerIndex], visible: !prev[blockerIndex]?.visible },
      ...prev.slice(blockerIndex + 1)
    ]);
  };

  return (
    <Modal
      useBorder
      withFooter={false}
      title={`Review for ${user.name}`}
      eventOnClickClose={() => setShowModal(false)}
      className="w-[400px]"
      contentWrapperClass="overflow-auto mt-[4px] mb-[16px]"
      maxHeight={560}
    >
      <div className="flex px-[14px] py-[8px] rounded-[8px] bg-y-100 mb-[16px]">
        <SVGIcon iconName="icon-warning" size={24} fillColor="var(--y-600)" />
        <p className="typography-paragraph ml-[16px]">
          Assignments that must be completed first
        </p>
      </div>
      {blockers.map((blocker, blockerIndex) => {
        return (
          <div
            className={
              blockedByCycle
                ? 'border-solid border-b border-0 border-n-400 px-[24px] mx-[-24px]'
                : ''
            }
          >
            {blockedByCycle && (
              <div
                data-cy={`blocking-cycle-${blockerIndex}`}
                className={`flex justify-between flex items-center cursor-pointer pt-[18px] ${
                  blocker?.visible ? 'pb-[6px]' : 'pb-[18px]'
                }`}
                onClick={() => clickChevron(blockerIndex)}
              >
                <div>
                  <span className="typography-h500">{blocker?.name}</span>&nbsp;
                  <span className="typography-h500 text-n-600">{`(${blocker?.count})`}</span>
                </div>
                <SVGIcon
                  id={`blocker-${blockerIndex}`}
                  customClass={`transform transition-transform duration-500 ease-out ${
                    !blocker?.visible ? '-rotate-90' : ''
                  }`}
                  iconName="icon-keyboard_arrow_down"
                  size={24}
                />
              </div>
            )}
            {blocker.visible &&
              phaseTypes.map((type, typeIndex) => {
                const { key, name: phaseType } = type;
                const blockingDependencies = blocker.dependencies[key];

                return (
                  blockingDependencies && (
                    <div
                      key={`blocker-${blockerIndex}-${typeIndex}`}
                      id={`blocker-${blockerIndex}-${typeIndex}`}
                      className="mb-[8px]"
                    >
                      <p className="typography-h300 text-n-800 py-[10px] ">
                        {phaseType}
                      </p>
                      {blockingDependencies?.map((actor, actorIndex) => {
                        return (
                          <TwoLine
                            key={actorIndex}
                            user={actor}
                            title={actor.name}
                            subtitle={actor.email}
                          />
                        );
                      })}
                    </div>
                  )
                );
              })}
          </div>
        );
      })}
      {blockedByAssignment &&
        assignmentDependencies.map((dependencies, index) => {
          const { actor, cycle, phase } = dependencies;
          const phaseName = phaseTypes.find((p) => phase.type == p.key)?.name;
          return (
            <>
              <div className="mb-[8px]">
                <TooltipContainer
                  show
                  text={`${phaseName} / ${cycle.name}`}
                  useMaxWidth={false}
                >
                  <p className="typography-h300 text-n-800 py-[10px] truncate">
                    {phaseName} / {cycle.name}{' '}
                  </p>
                </TooltipContainer>
              </div>
              <TwoLine
                key={`blocker-assignment-${index}`}
                user={actor}
                title={actor.name}
                subtitle={actor.email}
              />
            </>
          );
        })}
    </Modal>
  );
};

export default BlockerListModal;

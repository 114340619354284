import React, { useState } from 'react';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Checkbox from 'components/shared/Checkbox';
import RadioButton from 'components/shared/RadioButton';
import TextAreaWithMention from 'components/shared/TextAreaWithMention/TextAreaWithMention';

const CommentSection = ({
  isValid,
  modalType,
  metaMentions,
  setMetaMentions,
  setComment,
  permission,
  commentOptions,
  selectedTemplateComment,
  setSelectedTemplateComment
}) => {
  const { user } = useUser();
  const [addAdditionalComment, setAddAdditionalComment] = useState(false);
  const [listCommentOption, setListCommentOption] = useState(
    commentOptions || []
  );

  const includeOtherOption = listCommentOption?.findIndex(
    (list) => list.text === getObjectiveLocale('Other (Comment Box)')
  );
  const otherOption = { text: getObjectiveLocale('Other (Comment Box)') };
  if (listCommentOption.length > 0 && includeOtherOption < 0) {
    setListCommentOption([...listCommentOption, otherOption]);
  }

  return (
    <div className="update-comment-section mt-[24px]">
      <div className="update-current-value mb-[8px] mt-[0px]">
        <div className="w-full">
          {permission
            ? getObjectiveLocale('Comment (required)')
            : getObjectiveLocale('Comment (optional)')}
        </div>
      </div>
      {listCommentOption && modalType !== 'comment' && (
        <div className="comment-template">
          {listCommentOption.map((val, i) => (
            <div
              key={val?.id}
              className={`flex items-center h-[24px] whitespace-nowrap ${
                listCommentOption?.length - 1 == i ? 'mb-[16px]' : 'mb-[8px]'
              }`}
            >
              <RadioButton
                dataCy={`comment-template-${val?.id || 'other'}`}
                value={val?.id || val?.text}
                id={val?.id || val?.text}
                checked={selectedTemplateComment === val?.id}
                onChange={() => setSelectedTemplateComment(val?.id)}
                addClass="w-full"
              >
                <span
                  className={`${
                    selectedTemplateComment === val?.id
                      ? 'ml-[12px]'
                      : 'ml-[8px]'
                  } fs-14 fw-normal text-n-900`}
                >
                  {val?.text}
                </span>
              </RadioButton>
            </div>
          ))}
          {selectedTemplateComment &&
            selectedTemplateComment !==
              getObjectiveLocale('Other (Comment Box)') && (
              <Checkbox
                dataCy="add-aditional-comment"
                name="add-aditional-comment"
                id="add-aditional-comment"
                customContainerClass="mb-[16px]"
                checked={addAdditionalComment}
                labelClass="w-full"
                onChange={() => setAddAdditionalComment(!addAdditionalComment)}
              >
                <span className="text-n-800 ml-[8px] fw-normal fs-14">
                  Add additional comment
                </span>
              </Checkbox>
            )}
        </div>
      )}
      {(!listCommentOption.length > 0 ||
        addAdditionalComment ||
        selectedTemplateComment === undefined) && (
        <div
          className="sidebar__content__comment"
          data-cy="update-progress-comment"
        >
          <TextAreaWithMention
            currentUserId={user?.id}
            setComment={setComment}
            metaMentions={metaMentions}
            setMetaMentions={setMetaMentions}
            source="modal"
            isValid={isValid}
            modalType={modalType}
          />
        </div>
      )}
    </div>
  );
};

export default CommentSection;

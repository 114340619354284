import React, { useRef } from 'react';

import './_modal-sidebar';

function ModalSidebar({ title, children, customClass = '', top }) {
  return (
    <div className={`modal-sidebar ${customClass}`} style={{ top: top }}>
      {title && (
        <div className="title flex items-center typography-h200">{title}</div>
      )}
      {children}
    </div>
  );
}

export default ModalSidebar;

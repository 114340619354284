import React, { Fragment } from 'react';

const ParticipantSkeletonLoading = () => {
  const Element = () => {
    return (
      <Fragment>
        <table>
          <tbody>
            <tr>
              <td>
                <div className="ph-item">
                  <div className="ph-row">
                    <div className="ph-col-12 big"></div>
                  </div>
                </div>
              </td>
              <td>
                <div className="ph-item">
                  <div className="ph-row">
                    <div className="ph-col-12 big"></div>
                  </div>
                </div>
              </td>
              <td>
                <div className="ph-item">
                  <div className="ph-row">
                    <div className="ph-col-12 big"></div>
                  </div>
                </div>
              </td>
              <td>
                <div className="ph-item">
                  <div className="ph-row">
                    <div className="ph-col-12 big"></div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  };
  return (
    <>
      <Element />
      <Element />
      <Element />
    </>
  );
};

export default ParticipantSkeletonLoading;

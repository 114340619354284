import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import Objectives from 'components/objectives/Objectives';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

function MyTasks({ route: { title } }) {
  const { match } = useUrl();
  const { user, config } = useUser();
  const history = useHistory();
  document.getElementsByTagName('body')[0].classList = 'scroll-locked';

  let filter = {
    phase: config?.defaultFilterPhase,
    owner: {
      assignee: [{ user, userId: user.id }]
    }
  };

  const { newObjectiveLayout } = config;

  return (
    <>
      <HeaderPage
        titlePage={title}
        primaryAction={
          config.permissions?.taskCreate && {
            title: getObjectiveLocale('Create Task'),
            icon: 'icon-add',
            onClick: () => {
              history.replace('/create-objective/task');
            }
          }
        }
      />
      <div
        className={` grid ${
          newObjectiveLayout ? 'gap-[16px]' : 'gap-[24px]'
        } mb-[64px]`}
      >
        <div></div>
        <AdvancedFilterObjectives
          filterOptions={[
            'search',
            'group',
            'sort',
            'dateFilter',
            'menuFilter'
          ]}
          group={'no-group'}
          defaultFilter={filter}
          restructureFilter={(filter) => {
            if (!filter?.owner?.assignee?.length) {
              let newOwner = { ...filter.owner };
              newOwner.assignee = [{ ...user, userId: user.id }];
              return { ...filter, owner: newOwner };
            }
            return filter;
          }}
          page="myTasks"
          filterId="myTasksFilter"
        >
          <Objectives
            cardModel={'goal-list'}
            page={'mytasks'}
            extraFilter={{
              type: ['task'],
              parentNotAssignedTo: user.id
            }}
            withMarginTop={!newObjectiveLayout}
          />
        </AdvancedFilterObjectives>
      </div>

      <React.Suspense fallback={<div></div>}>
        {match && <SidebarMyGoals />}
      </React.Suspense>
    </>
  );
}

export default MyTasks;

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import { getListGroup } from 'client/CultureClient';
import { useFeeds } from 'context/FeedsContext';
import { useUser } from 'context/UserContext';
import { getCultureLocale } from 'utils/HelperUtils';

import Feeds from 'components/culture/Feeds';
import GenerateListGroupSection from 'components/culture/GenerateListGroupSection';
import HighlightPinnedSection from 'components/culture/HighlightPinnedSection';
import LabelFilter from 'components/culture/LabelFilter';
import Text from 'components/shared-2/Text';
import LoadingComponent from 'components/shared/LoadingComponent';

const HomePageDetail = ({
  groupDetail,
  isRefetch,
  eventOnClick,
  setSelectedGroup
}) => {
  const { user } = useUser();
  const history = useHistory();
  const params = useParams();
  const { onScroll, setPage, setFilter } = useFeeds(
    params?.groupId || groupDetail?.id,
    user?.id
  );
  const [listYourGroup, setListYourGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentFilter, setCurrentFilter] = useState('recent');

  const _getListYourGroup = async () => {
    const params = {
      limit: 20
    };
    const { data } = await getListGroup(params);

    if (data && data.length > 0) {
      isEmpty(groupDetail) && setSelectedGroup(data[0]?.id);
      setListYourGroup(data.splice(0, 5));
    }
    setIsLoading(false);
  };

  const setGroup = async (group) => {
    history.replace(`/timeline/group/${group?.id}`);
  };

  const MyGroup = () => {
    return (
      <div>
        <div className="typography-h500 mb-[24px]">
          {getCultureLocale('My Group')}
        </div>
        <div
          className="w-[320px] h-[388px] border border-solid border-n-400 rounded-lg rounded-lg relative bg-n-000"
          data-cy="group-section"
        >
          {isLoading ? (
            <LoadingComponent className="mt-[33%]" />
          ) : (
            <>
              <GenerateListGroupSection
                isUseTitle={false}
                data={listYourGroup}
                clickFunction={setGroup}
                wrapperGroupClassName="p-16"
                itemGroupClasssName="h-[40px] mb-[24px]"
              />
              <div
                data-cy="see-all"
                onClick={() => setGroup(listYourGroup[0])}
                className="h-[50px] typography-button w-full absolute bottom-0 border-solid border-t
                border-0 border-n-400 flex items-center justify-center text-base-600 cursor-pointer"
              >
                {getCultureLocale('See All')}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    setPage(`homepage-${currentFilter}`);
    setFilter('recent');
    _getListYourGroup();
  }, []);

  return (
    <div
      id="scroll-feeds"
      className="flex items-center flex-col mt-[24px] scrollable h-[calc(100vh-64px)]"
      onScroll={(e) => {
        !isRefetch && (e.stopPropagation(), onScroll(e));
      }}
    >
      {/* HIGHLIGHT SECTION */}
      <HighlightPinnedSection widthWrapper={904} type="highlight" />

      <div className="flex w-[904px]">
        <div className="mr-[24px]">
          <Text variant="h500">Timeline</Text>
          {/* FILTER BY LABEL */}
          <LabelFilter
            setCurrentFilter={setCurrentFilter}
            currentFilter={currentFilter}
            setFilter={setFilter}
            setPage={setPage}
          />

          {/* FEEDS SECTION */}
          <Feeds
            widthWrapper="w-[560px]"
            groupDetail={groupDetail}
            isRefetch={isRefetch}
            eventOnClick={eventOnClick}
          />
        </div>

        {/* GROUP SECTION */}
        <MyGroup />
      </div>
    </div>
  );
};

export default HomePageDetail;

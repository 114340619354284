import React from 'react';

import { useProfileSkill } from 'context/ProfileSkillContext';
import { useProfileValue } from 'context/ProfileValueContext';
import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import SVGIcon from 'components/shared/SVGIcon';

const ValuesSkillCard = ({
  titleCard,
  color,
  data,
  datacy,
  typeCard,
  typeOverview,
  withIcon,
  userId
}) => {
  const {
    scroll: valuesScroll,
    strengthOffset: valueStrengthOffset,
    weaknessOffset: valueWeaknessOffset
  } = useProfileValue();
  const {
    scroll: skillScroll,
    strengthOffset: skillStrengthOffset,
    weaknessOffset: skillWeaknessOffset
  } = useProfileSkill();
  const { appType } = useUser();

  const setScroll = (e) => {
    const type = typeCard.toLowerCase();
    const offset =
      typeOverview === 'value'
        ? type == 'strength'
          ? valueStrengthOffset
          : valueWeaknessOffset
        : type === 'strength'
        ? skillStrengthOffset
        : skillWeaknessOffset;
    typeOverview === 'value'
      ? valuesScroll(e, type, userId, offset)
      : skillScroll(e, type, userId, offset);
  };

  const ItemCard = ({ data, index }) => {
    return (
      <div key={index} className="wrapper-list-skill">
        {withIcon && (
          <AWSImage
            src={
              data?.iconImageAttributes?.iconUrl ||
              Config.asset.culture.brokenImageCenter
            }
            className="min-w-[24px] w-[24px] h-[24px] mr-[8px] rounded-[50px]"
          />
        )}
        <span className="list-skill typography-paragraph">
          {data?.behaviorTitle}
        </span>
        <span
          className={`py-[2px] px-[8px] typography-h200 text-${color}-800 bg-${color}-200`}
        >
          {getNumberLocale(
            appType == 'culture' ? data?.cultureCount : data?.totalCount
          )}
        </span>
      </div>
    );
  };

  return (
    <div className="col-md-4" data-cy={datacy}>
      <div className="overview-box">
        <div className="skill-card-container">
          <div className="skill-card-header">
            <span className="typography-h200 capitalize">
              {getObjectiveLocale(titleCard)}
            </span>
            <span className="wrapper-type-skill">
              <SVGIcon
                iconName={
                  typeCard === 'Strength'
                    ? 'icon-thumb_up_alt'
                    : 'icon-feedback'
                }
                fillColor={`var(--${color}-600)`}
                customClass="mr-[8px]"
                size="20"
              />
              <span className={`typography-h200 text-${color}-800`}>
                {getObjectiveLocale(typeCard)}
              </span>
            </span>
          </div>
          <div className="skill-card-body" onScroll={(e) => setScroll(e)}>
            {typeCard == 'strength' && data?.length > 0 ? (
              data.map((value, i) => {
                return (
                  value.strengthCount != 0 && (
                    <ItemCard data={value} index={i} key={i} />
                  )
                );
              })
            ) : data?.length > 0 ? (
              data.map((value, i) => {
                return (
                  value.weaknessCount != 0 && (
                    <ItemCard data={value} index={i} key={i} />
                  )
                );
              })
            ) : (
              <p className="typography-paragraph text-n-600 w-full">
                {getObjectiveLocale('Not yet available')}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuesSkillCard;

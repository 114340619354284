import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';

import { getObjectivesCount } from 'client/ObjectivesClient';
import { useProfileFilter } from 'context/ProfileFilterContext';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { restructureFilter } from 'utils/ObjectivesHelper';

import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import ApprovalPanel from 'components/approval/ApprovalPanel';
import Button from 'components/design-system/Button';
import Objectives from 'components/objectives/Objectives';
import ExportSummaryButton from 'components/objectives/progress-summary/ExportSummaryButton';
import ConditionalWrapper from 'components/shared/ConditionalWrapper';
import ObjectiveEmptyState from 'components/shared/ObjectiveEmptyState';
import SVGIcon from 'components/shared/SVGIcon';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import StatisticsOverallViewProgressCompact from 'src/pages/dashboardOverallViewProgress/compactStatistics/StatisticsOverallViewProgressCompact';

function PerformanceTab({
  placementId,
  userId,
  user,
  objectivesVisibility,
  permission
}) {
  const history = useHistory();
  const { match } = useUrl();
  const { config } = useUser();

  const isUsingApproval = user?.permissions?.approvalSee;
  const { profileObjectiveCountSee } = permission || {};

  const { filter: profileFilter } = useProfileFilter();
  const performanceTabFilter = useFilter(
    (state) => state.filterById?.performanceTabFilter
  );

  const showExportSummaryButton =
    (performanceTabFilter?.switch == 'all' ||
      performanceTabFilter?.switch == 'goal' ||
      performanceTabFilter?.switch == 'company-goal') &&
    config?.exportObjectivesSummaryFeature &&
    config?.permissions?.goalSummaryExport;

  const pageParams = {
    parentNotAssignedTo: userId,
    placementId: placementId,
    assigneeId: [parseInt(userId)],
    sortColumn: performanceTabFilter?.sortColumn,
    sortDirection: performanceTabFilter?.sortDirection
  };

  let filter = {
    phase: config?.defaultFilterPhase,
    switch: 'goal',
    owner: {
      assignee: [{ user, userId }]
    }
  };

  const onClickCreateNewGoal = () => {
    history.replace({
      pathname: '/create-objective/goal',
      state: { prevPath: location.pathname }
    });
  };

  const getCount = () => {
    let newFilter = restructureFilter({
      ...(performanceTabFilter || filter),
      ...profileFilter
    });

    newFilter.assigneeId = [parseInt(userId)];
    newFilter.placementId = placementId;
    return getObjectivesCount(newFilter);
  };

  let { data: progressData, refetch } = useQuery(
    ['overallProgressPerformanceTab', userId, profileFilter],
    getCount
  );

  useEffect(() => {
    refetch();
  }, [performanceTabFilter, profileFilter]);

  const additonalButton = (
    <div className="absolute right-0 bottom-0 flex flex-row gap-[8px]">
      {showExportSummaryButton && (
        <ExportSummaryButton
          filter={performanceTabFilter}
          extraFilter={{
            ...profileFilter,
            parentNotAssignedTo: user?.id
          }}
          owner={user}
          placementId={user?.placementId}
          withTitle={false}
        />
      )}
      {config.permissions?.goalCreate && (
        <Button onClick={onClickCreateNewGoal}>
          <SVGIcon
            size="24"
            fillColor="var(--n-100)"
            iconName="icon-add"
            customClass="mr-[4px]"
          />
          {getObjectiveLocale('Create Objective')}
        </Button>
      )}
    </div>
  );

  return (
    <div className="objective-tab mb-[64px]">
      <div className={`my-goals-container`}>
        {isUsingApproval && (
          <ApprovalPanel
            user={user}
            mode="employee"
            pageObjective={'userProfile'}
            fetchDataOnPanel={true}
          />
        )}
        <div
          className={`space-y-16 grid gap-[24px] ${
            profileObjectiveCountSee ? 'grid-cols-1 mt-[-16px]' : ''
          }`}
          style={
            profileObjectiveCountSee
              ? { gridTemplateAreas: "'analytics' 'filter' 'objectives'" }
              : {}
          }
        >
          {objectivesVisibility ? (
            <AdvancedFilterObjectives
              isCompanyGoalCreator={user?.isCompanyGoalCreator}
              filterOptions={[
                'search',
                'group',
                'sort',
                'menuFilter',
                'switch'
              ]}
              group={'no-group'}
              defaultFilter={filter}
              page="profile"
              filterId="performanceTabFilter"
              childrenInFilter={
                progressData?.data?.totalObjectives > 0 && additonalButton
              }
              customClass="relative"
              gridArea={profileObjectiveCountSee}
            >
              <ConditionalWrapper
                condition={profileObjectiveCountSee}
                wrapper={({ children, ...rest }) => (
                  <StatisticsOverallViewProgressCompact
                    userId={userId}
                    placementId={placementId}
                    visibility={['progress']}
                    page="performanceTab"
                    profileFilter={profileFilter}
                    profilePermission={permission}
                    border="no-border"
                    gridArea
                    useCompactLayout
                    {...rest}
                  >
                    {children}
                  </StatisticsOverallViewProgressCompact>
                )}
                passProps
              >
                <Objectives
                  profileFilter={profileFilter}
                  extraFilter={pageParams}
                  cardModel={'goal-list'}
                  page={'performanceTab+' + userId + placementId}
                  user={{ id: userId }}
                  cantSee={!objectivesVisibility}
                  userPermission={permission}
                  gridArea={profileObjectiveCountSee}
                />
              </ConditionalWrapper>
            </AdvancedFilterObjectives>
          ) : (
            <ObjectiveEmptyState
              cantSee={true}
              useSvg={true}
              userPermission={permission}
            />
          )}
        </div>
        {match && objectivesVisibility && (
          <SidebarMyGoals customClassname="no-sub-header" />
        )}
      </div>
    </div>
  );
}

export default withRouter(PerformanceTab);

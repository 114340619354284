import React, { useEffect, useState } from 'react';

import { updateWeightConfig } from 'client/ObjectivesClient';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale } from 'utils/HelperUtils';

import LoadingComponent from 'components/shared/LoadingComponent';

import ObjectivesFormBox from '../form/ObjectivesFormBox';

function Percentage({ setIsSaving, configData, isReadOnly }) {
  const { config } = useUser();
  const { reload, reloadUser } = useReload();
  const [percentageData, setPercentageData] = useState({});
  const [requestBody, setRequestBody] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isInputMinError, setIsInputMinError] = useState(false);
  const [isInputMaxError, setIsInputMaxError] = useState(false);
  const [isInputMinSubError, setIsInputMinSubError] = useState(false);
  const [isInputMaxSubError, setIsInputMaxSubError] = useState(false);
  const [isToggle, setIsToggle] = useState(true);
  const debouncedPercentageData = useDebounce(percentageData, 2000);
  const toggleKeys = [
    'maxObjectiveWeightOn',
    'minObjectiveWeightOn',
    'maxSubObjectiveWeightOn',
    'minSubObjectiveWeightOn',
    'maxSumOfObjectivesWeightPercentageOn'
  ];
  const inputKeys = [
    'maxObjectiveWeight',
    'minObjectiveWeight',
    'maxSubObjectiveWeight',
    'minSubObjectiveWeight',
    'maxSumOfObjectivesWeightPercentage'
  ];
  const { objectivesConfig } = config;

  useEffect(() => {
    if (percentageData.inputs) {
      if (
        percentageData.inputs.minObjectiveWeight >=
          percentageData.inputs.maxObjectiveWeight &&
        percentageData.toggles.maxObjectiveWeightOn
      ) {
        setIsInputMinError(true);
      } else {
        setIsInputMinError(false);
      }
      if (
        percentageData.inputs.maxObjectiveWeight <=
          percentageData.inputs.minObjectiveWeight &&
        percentageData.toggles.minObjectiveWeightOn
      ) {
        setIsInputMaxError(true);
      } else {
        setIsInputMaxError(false);
      }
    }
  }, [percentageData]);

  const getPercentageData = async () => {
    setIsLoading(true);
    const data = {
      toggles: Object.keys(configData.objectivesConfig)
        .filter((key) => toggleKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = configData.objectivesConfig[key];
          return obj;
        }, {}),
      inputs: Object.keys(configData.objectivesConfig)
        .filter((key) => inputKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = configData.objectivesConfig[key];
          return obj;
        }, {})
    };

    setPercentageData(data);
    setIsLoading(false);
  };

  const handleWeight = async (weight) => {
    let changes = { key: null, value: null };
    switch (weight) {
      case 'toggleMin':
        changes = {
          key: 'minObjectiveWeightOn',
          value: !percentageData.toggles.minObjectiveWeightOn
        };
        break;
      case 'toggleMax':
        changes = {
          key: 'maxObjectiveWeightOn',
          value: !percentageData.toggles.maxObjectiveWeightOn
        };
        break;
      case 'toggleMinSub':
        changes = {
          key: 'minSubObjectiveWeightOn',
          value: !percentageData.toggles.minSubObjectiveWeightOn
        };
        break;
      case 'toggleMaxSub':
        changes = {
          key: 'maxSubObjectiveWeightOn',
          value: !percentageData.toggles.maxSubObjectiveWeightOn
        };
        break;
      case 'toggleSum':
        changes = {
          key: 'maxSumOfObjectivesWeightPercentageOn',
          value: !percentageData.toggles.maxSumOfObjectivesWeightPercentageOn
        };
        break;
    }
    setIsToggle(true);

    const temporaryPercentageData = { ...percentageData };
    temporaryPercentageData.toggles[changes.key] = changes.value;
    setPercentageData(temporaryPercentageData);

    setIsSaving(true);
    let changesRequestBody = { ...requestBody };
    changesRequestBody[changes.key] = changes.value;
    const updatedBody = {
      objectivesConfig: changesRequestBody
    };
    await updateWeightConfig(updatedBody);
    setRequestBody({});
    reload({ reloadUser: !reloadUser });

    if (
      !percentageData.toggles.maxObjectiveWeightOn ||
      !percentageData.toggles.minObjectiveWeightOn
    ) {
      setIsInputMaxError(false);
      setIsInputMinError(false);
    } else if (
      percentageData.toggles.maxObjectiveWeightOn &&
      percentageData.inputs.maxObjectiveWeight <
        percentageData.inputs.minObjectiveWeight
    ) {
      setIsInputMaxError(true);
    } else if (
      percentageData.toggles.minObjectiveWeightOn &&
      percentageData.inputs.minObjectiveWeight >
        percentageData.inputs.maxObjectiveWeight
    ) {
      setIsInputMinError(true);
    }

    if (
      !percentageData.toggles.maxSubObjectiveWeightOn ||
      !percentageData.toggles.minSubObjectiveWeightOn
    ) {
      setIsInputMaxSubError(false);
      setIsInputMinSubError(false);
    } else if (
      percentageData.toggles.maxSubObjectiveWeightOn &&
      percentageData.inputs.maxSubObjectiveWeight <
        percentageData.inputs.minSubObjectiveWeight
    ) {
      setIsInputMaxSubError(true);
    } else if (
      percentageData.toggles.minSubObjectiveWeightOn &&
      percentageData.inputs.minSubObjectiveWeight >
        percentageData.inputs.maxSubObjectiveWeight
    ) {
      setIsInputMinSubError(true);
    }
  };

  const handleWeightInput = (e) => {
    if (
      e.target.value == '' ||
      e.target.value <= 100 ||
      e.target.name === 'maxSumOfObjectivesWeightPercentage'
    ) {
      setIsToggle(false);
      const temporaryPercentageData = { ...percentageData };
      temporaryPercentageData.inputs[e.target.name] =
        parseInt(e.target.value) || null;
      setPercentageData(temporaryPercentageData);

      let changesRequestBody = { ...requestBody };
      if (requestBody.hasOwnProperty(e.target.name)) {
        changesRequestBody[e.target.name] =
          parseInt(e.target.value || objectivesConfig[e.target.name]) || null;
      } else {
        changesRequestBody[e.target.name] =
          parseInt(e.target.value || objectivesConfig[e.target.name]) || null;
      }
      setRequestBody(changesRequestBody);
    }
  };

  const updateWeight = async (body) => {
    const isNotEmpty = !Object.values(body).some((objectBody) =>
      isNaN(objectBody)
    );
    if (isNotEmpty) {
      setIsSaving(true);
      const updatedBody = {
        objectivesConfig: body
      };
      await updateWeightConfig(updatedBody);
      setRequestBody({});
      reload({ reloadUser: !reloadUser });
    }
  };

  useEffect(() => {
    getPercentageData();
  }, []);

  useEffect(() => {
    if (Object.keys(requestBody).length > 0 && !isToggle) {
      updateWeight(requestBody);
    }
  }, [debouncedPercentageData]);

  return (
    <div
      className="objective-container container-weight-percentage"
      data-cy="weight-percentage-section"
    >
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <ObjectivesFormBox
            title="Enable minimum weight value"
            isChecked={percentageData.toggles.minObjectiveWeightOn}
            desc={
              <p>
                {getObjectiveLocale(
                  'If minimum weight value is being enabled, it will be applied when user trying to set weight when creating or editing'
                )}{' '}
                <span className="font-bold">
                  {getObjectiveLocale('Objectives')}
                </span>
                .
              </p>
            }
            onChange={() => handleWeight('toggleMin')}
            colMdDescBeforeSwitch={4}
            useBorderBottom={true}
            idToogleSwitch="minObjectiveWeightOn"
            className="percentage-box"
            disabled={isReadOnly}
          >
            {percentageData.toggles.minObjectiveWeightOn && (
              <div className="input-weight-section">
                <div className="small-description-input">
                  <span className="minimum-number-of-ob typography-h200">
                    Minimum weight value:
                  </span>
                </div>
                <div className="input-weight container-input container-weight">
                  <input
                    type="number"
                    name="minObjectiveWeight"
                    placeholder="e.g 100"
                    className="btn-filter-name styled-input"
                    value={percentageData.inputs.minObjectiveWeight}
                    onChange={(e) => handleWeightInput(e)}
                    data-cy="minObjectiveWeight"
                    disabled={isReadOnly}
                  />
                  %
                </div>
                {isInputMinError && (
                  <div className="warning-message typography-h300">
                    {getObjectiveLocale('Minimum weight value must less than')}{' '}
                    {percentageData.inputs.maxObjectiveWeight}%
                  </div>
                )}
              </div>
            )}
          </ObjectivesFormBox>
          <ObjectivesFormBox
            title="Enable maximum weight value"
            isChecked={percentageData.toggles.maxObjectiveWeightOn}
            desc={
              <p>
                {getObjectiveLocale(
                  'If maximum weight value is being enabled, it will be applied when user trying to set weight when creating or editing'
                )}{' '}
                <span className="font-bold">
                  {getObjectiveLocale('Objectives')}
                </span>
                .
              </p>
            }
            onChange={(e) => handleWeight('toggleMax', e)}
            colMdDescBeforeSwitch={4}
            useBorderBottom={true}
            idToogleSwitch="maxObjectiveWeightOn"
            className="percentage-box"
            disabled={isReadOnly}
          >
            {percentageData.toggles.maxObjectiveWeightOn && (
              <div className="input-weight-section">
                <div className="small-description-input">
                  <span className="minimum-number-of-ob typography-h200">
                    {getObjectiveLocale('Maximum weight value')}:
                  </span>
                </div>
                <div className="input-weight container-input container-weight">
                  <input
                    type="number"
                    name="maxObjectiveWeight"
                    placeholder="e.g 100"
                    className="btn-filter-name styled-input"
                    value={percentageData.inputs.maxObjectiveWeight}
                    onChange={(e) => handleWeightInput(e)}
                    data-cy="maxObjectiveWeight"
                    disabled={isReadOnly}
                  />
                  %
                </div>
                {isInputMaxError && (
                  <div className="warning-message typography-h300">
                    {getObjectiveLocale(
                      'Maximum weight value must bigger than'
                    )}{' '}
                    {percentageData.inputs.minObjectiveWeight}%
                  </div>
                )}
              </div>
            )}
          </ObjectivesFormBox>
          <ObjectivesFormBox
            title={`${getObjectiveLocale(
              'Enable minimum weight value for'
            )} Sub-${getObjectiveLocale('Objectives')}`}
            isChecked={percentageData.toggles.minSubObjectiveWeightOn}
            desc={
              <p>
                {getObjectiveLocale(
                  'If minimum weight value is being enabled, it will be applied when user trying to set weight when creating or editing'
                )}{' '}
                <span className="font-bold">
                  Sub-{getObjectiveLocale('Objectives')}
                </span>
                .
              </p>
            }
            onChange={() => handleWeight('toggleMinSub')}
            colMdDescBeforeSwitch={4}
            useBorderBottom={true}
            idToogleSwitch="minSubObjectiveWeightOn"
            className="percentage-box"
            disabled={isReadOnly}
          >
            {percentageData.toggles.minSubObjectiveWeightOn && (
              <div className="input-weight-section">
                <div className="small-description-input">
                  <span className="minimum-number-of-ob typography-h200">
                    {getObjectiveLocale('Minimum weight value')}:
                  </span>
                </div>
                <div className="input-weight container-input container-weight">
                  <input
                    type="number"
                    name="minSubObjectiveWeight"
                    placeholder="e.g 100"
                    className="btn-filter-name styled-input"
                    value={percentageData.inputs.minSubObjectiveWeight}
                    onChange={(e) => handleWeightInput(e)}
                    data-cy="minSubObjectiveWeight"
                    disabled={isReadOnly}
                  />
                  %
                </div>
                {isInputMinSubError && (
                  <div className="warning-message typography-h300">
                    {getObjectiveLocale('Minimum weight value must less than')}{' '}
                    {percentageData.inputs.maxSubObjectiveWeight}%
                  </div>
                )}
              </div>
            )}
          </ObjectivesFormBox>
          <ObjectivesFormBox
            title={`${getObjectiveLocale(
              'Enable maximum weight value for'
            )} Sub-${getObjectiveLocale('Objectives')}`}
            isChecked={percentageData.toggles.maxSubObjectiveWeightOn}
            desc={
              <p>
                {getObjectiveLocale(
                  'If maximum weight value is being enabled, it will be applied when user trying to set weight when creating or editing'
                )}{' '}
                <span className="font-bold">
                  Sub-{getObjectiveLocale('Objectives')}
                </span>
                .
              </p>
            }
            onChange={(e) => handleWeight('toggleMaxSub', e)}
            colMdDescBeforeSwitch={4}
            useBorderBottom={true}
            idToogleSwitch="maxSubObjectiveWeightOn"
            className="percentage-box"
            disabled={isReadOnly}
          >
            {percentageData.toggles.maxSubObjectiveWeightOn && (
              <div className="input-weight-section">
                <div className="small-description-input">
                  <span className="minimum-number-of-ob typography-h200">
                    {getObjectiveLocale('Maximum weight value')}:
                  </span>
                </div>
                <div className="input-weight container-input container-weight">
                  <input
                    type="number"
                    name="maxSubObjectiveWeight"
                    placeholder="e.g 100"
                    className="btn-filter-name styled-input"
                    value={percentageData.inputs.maxSubObjectiveWeight}
                    onChange={(e) => handleWeightInput(e)}
                    data-cy="maxSubObjectiveWeight"
                    disabled={isReadOnly}
                  />
                  %
                </div>
                {isInputMaxSubError && (
                  <div className="warning-message typography-h300">
                    {getObjectiveLocale(
                      'Maximum weight value must bigger than'
                    )}{' '}
                    {percentageData.inputs.minObjectiveSubWeight}%
                  </div>
                )}
              </div>
            )}
          </ObjectivesFormBox>
          <ObjectivesFormBox
            title={getObjectiveLocale(
              'Enable maximum for the sum of all objective weights'
            )}
            isChecked={
              percentageData.toggles.maxSumOfObjectivesWeightPercentageOn
            }
            desc={getObjectiveLocale(
              'If maximum for the sum of all objective weights is being enabled, there will be information that inform user about the expected max value for total weight of user’s objectives.'
            )}
            onChange={() => handleWeight('toggleSum')}
            colMdDescBeforeSwitch={5}
            useBorderBottom={false}
            idToogleSwitch="maxSumOfObjectivesWeightPercentageOn"
            className="percentage-box"
            disabled={isReadOnly}
          >
            {percentageData.toggles.maxSumOfObjectivesWeightPercentageOn && (
              <div className="input-weight-section">
                <div className="big-description-input">
                  <span className="minimum-number-of-ob typography-h200">
                    {getObjectiveLocale(
                      'Maximum sum of all objective weights:'
                    )}
                  </span>
                </div>
                <div className="input-weight container-input">
                  <input
                    type="number"
                    name="maxSumOfObjectivesWeightPercentage"
                    placeholder="e.g 100"
                    className="btn-filter-name styled-input"
                    value={
                      percentageData.inputs.maxSumOfObjectivesWeightPercentage
                    }
                    onChange={(e) => handleWeightInput(e)}
                    data-cy="maxSumOfObjectivesWeightPercentage"
                    disabled={isReadOnly}
                  />
                  %
                </div>
              </div>
            )}
          </ObjectivesFormBox>
        </>
      )}
    </div>
  );
}

export default Percentage;

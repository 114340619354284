import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import {
  updatePreferencesFilter,
  updateStateObjective
} from 'client/ObjectivesClient';
import { MetricsProvider, useMetrics } from 'context/MetricsContext';
import { ProjectDetailProvider } from 'context/ProjectDetailContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import useTableHeader from 'hooks/useTableHeader';
import useTaskGroup from 'hooks/useTaskGroup';
import useTasks from 'hooks/useTasks';
import { useUrl } from 'hooks/useUrl';
import {
  getDataFilter,
  getDefaultFilterSaved,
  getDefaultHeaderColumnSaved,
  getFilterPayloadBody
} from 'utils/FilterRestructuringHelper';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getPeriod } from 'utils/ObjectivesHelper';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import ConfirmDeleteModal from 'components/modal/ConfirmDeleteModal';
import ObjectivesModal from 'components/objectives/ObjectivesModal';
import FloatingAction from 'components/shared/FloatingAction/FloatingAction';
import ListEmptyState from 'components/shared/ListEmptyState';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import AccordionTask from 'components/tasks/AccordionTask';
import Boards from 'components/tasks/board/Boards';
import { useUserPreferences } from 'src/hooks/usePreferenceFilter';

import { convertGroupNameToLowerCase } from '../utils/TasksHelper';

function NewTasksContent({ route: { title } }) {
  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');

  const {
    user,
    config: {
      defaultObjectiveSorting,
      filterLastUpdate,
      permissions,
      defaultTaskView,
      timeFilterOptions: dateFilterOptions,
      enableTaskMultiParentAlignment
    }
  } = useUser();

  const boardScrollbar = useRef();

  const history = useHistory();
  const { url, match } = useUrl();
  const [currentTab, setCurrentTab] = useState(
    defaultTaskView || tabParams || 'list'
  );

  const filter = useFilter(
    (state) =>
      state.filterById?.[
        currentTab === 'list' ? 'tasksFilterList' : 'tasksFilterBoard'
      ]
  );

  const { group, showTask } = filter || {};
  const [filterGroup, setFilterGroup] = useState(group);

  const {
    listGroups,
    selectedTasks,
    removeAllSelected,
    updateGroupIndexHash,
    setListGroups
  } = useTasks();

  const currentGroup =
    (filter?.priority?.length > 0 || filter?.phase?.length > 0) &&
    filter?.[filterGroup]
      ? filter?.[filterGroup]
      : listGroups?.[filterGroup] || [];

  const {
    refetchObjective,
    refetchSubObjectives,
    refetchObjectives,
    refetchQueries,
    invalidateTaskQueries
  } = useRefetchQuery();

  const { setHeaders, headers } = useTableHeader();

  const { listMetrics } = useMetrics() || {};
  const [showMutipleDelete, setShowMutipleDelete] = useState(false);
  const [draggedId, setDraggedId] = useState(null);
  const [autoShowPlaceholder, setAutoShowPlaceholder] = useState(false);
  const [boardXScrollbarVisible, setBoardXScrollbarVisible] = useState(false);

  const [defaultFilter, setDefaultFilter] = useState({});
  const [filterShowTask, setFilterShowTask] = useState('');
  const [isGroupDataReady, setIsGroupDataReady] = useState(false);

  const filterPayload = useRef([]);

  const filterMenu = [
    { option: 'phase', title: 'Phase', dialog: 'task-phase' },
    { option: 'priority', title: 'Priority', dialog: 'task-priority' },
    {
      option: 'overdue',
      title: 'Overdue',
      subOption: [{ option: 'overdue', title: 'Overdue Only' }]
    },
    {
      option: 'lastUpdated',
      title: 'Last Updated',
      subOption: filterLastUpdate?.map((option) => {
        return {
          option: 'lastUpdated.' + option,
          title: startCase(option),
          calendar: option == 'newer_than' || option == 'older_than'
        };
      })
    },
    { option: 'parentAlignment', title: 'Alignment', dialog: 'alignment-goal' }
  ];

  const tabsList = [
    { id: 'list', name: 'List' },
    { id: 'board', name: 'Board' }
  ];

  const defaultTabIndex = tabsList.findIndex(
    (tab) => tab.id === defaultTaskView
  );

  const {
    data: preferencesFilterData = null,
    isFetching: isFetchingPreferences
  } = useUserPreferences(['preferences', 'mytasks', currentTab], null, {
    suspense: false
  });

  const { data: listPhasesData = null } = useTaskGroup(
    'phase',
    filterShowTask,
    'mytasks',
    filterShowTask == 'project' ? { assigneeId: user?.id } : {},
    {
      suspense: false,
      staleTime: 5 * 60 * 1000,
      enabled:
        !!preferencesFilterData && !isFetchingPreferences && !!filterShowTask
    }
  );

  const { data: listSectionsData = null } = useTaskGroup(
    'section',
    filterShowTask,
    'mytasks',
    filterShowTask == 'project' ? { assigneeId: user?.id } : {},
    {
      suspense: false,
      staleTime: 5 * 60 * 1000,
      enabled:
        !!preferencesFilterData && !isFetchingPreferences && !!filterShowTask
    }
  );

  const { data: listPrioritiesData = null } = useTaskGroup(
    'priority',
    filterShowTask,
    'mytasks',
    {},
    {
      suspense: false,
      staleTime: 5 * 60 * 1000,
      enabled:
        !!preferencesFilterData && !isFetchingPreferences && !!filterShowTask
    }
  );

  const { data: listProjectsData = null } = useTaskGroup(
    'project',
    filterShowTask,
    'mytasks',
    {
      isProject: true,
      involvedUserId: user.id,
      sortColumn: 'name',
      sortDirection: 'asc'
    },
    {
      suspense: false,
      staleTime: 5 * 60 * 1000,
      enabled:
        !!preferencesFilterData && !isFetchingPreferences && !!filterShowTask
    }
  );

  useEffect(() => {
    if (
      preferencesFilterData &&
      listPhasesData &&
      listPrioritiesData &&
      listSectionsData &&
      listProjectsData
    ) {
      const dataFilter = getDataFilter(
        preferencesFilterData?.data,
        currentTab,
        'team_management',
        'task'
      );

      const convertedPhases = convertGroupNameToLowerCase(listPhasesData?.data);
      const convertedPriorities = convertGroupNameToLowerCase(
        listPrioritiesData?.data
      );
      const convertedSections = convertGroupNameToLowerCase(
        listSectionsData?.data
      );
      const convertedProjects = convertGroupNameToLowerCase(
        listProjectsData?.data
      );
      const periodFilter = getPeriod(dateFilterOptions?.[0]);
      const defaultFilter = getDefaultFilterSaved({
        dataFilter,
        phases: convertedPhases,
        priorities: convertedPriorities,
        defaultObjectiveSorting,
        periodFilter,
        isProject: false
      });

      if (!isEmpty(defaultFilter)) {
        setDefaultFilter(defaultFilter);
        getHeaders(dataFilter);
      }

      if (
        convertedPhases &&
        convertedPriorities &&
        convertedSections &&
        convertedProjects
      ) {
        setListGroups({
          phase: convertedPhases,
          priority: convertedPriorities,
          section: convertedSections,
          project: convertedProjects
        });
        setIsGroupDataReady(true);
      }
    }
    // eslint-disable-next-line
  }, [
    preferencesFilterData,
    listPhasesData,
    listPrioritiesData,
    listSectionsData,
    listProjectsData
  ]);

  useEffect(() => {
    if (preferencesFilterData) {
      const dataFilter = getDataFilter(
        preferencesFilterData?.data,
        currentTab,
        'team_management',
        'task'
      );
      setFilterShowTask(dataFilter?.showTask || 'personal');
    }
    // eslint-disable-next-line
  }, [preferencesFilterData]);

  const getHeaders = (dataFilter) => {
    const headerColumnName = [
      'Task Name',
      'Assignee',
      'Period',
      'Priority',
      'Phase',
      'Progress',
      'Metrics',
      'Complexity',
      'Alignment Goal',
      'Dependency',
      'Last Comment',
      'Created by',
      'Last Updated',
      ''
    ];

    const defaultHeaderColumn = [
      'Task Name',
      'Assignee',
      'Period',
      'Priority',
      'Phase',
      'Progress',
      'Metrics'
    ];

    const headerColumn = getDefaultHeaderColumnSaved({
      headerColumnName,
      filterColumnName: dataFilter?.taskColumn || defaultHeaderColumn,
      enableTaskMultiParentAlignment
    });

    if (!headers?.taskManagementTable) {
      setHeaders('taskManagementTable', headerColumn);
    }
  };

  const getFilterPayload = useCallback(() => {
    const payloadBody = getFilterPayloadBody({
      module: 'team_management',
      page: 'task',
      section: currentTab,
      filter: filter,
      headerTable: headers?.taskManagementTable,
      isProject: false
    });

    filterPayload.current = cloneDeep(payloadBody);
  }, [filter, headers?.taskManagementTable, currentTab]);

  const updateFilterSaved = async (event) => {
    if (!isEmpty(filterPayload?.current)) {
      event?.preventDefault();
      await updatePreferencesFilter(filterPayload.current);
    }
  };

  const resultModifier = (filter) => {
    const filterPhase = filter?.phase?.map((e) => e?.name).filter((e) => e);
    const filterPriority = filter?.priority
      ?.map((e) => e?.name)
      .filter((e) => e);

    const result = {
      phase: filterPhase,
      priority: filterPriority,
      alignment:
        filter?.parentAlignment?.length > 0
          ? filter?.parentAlignment
              ?.map(({ objective }) => objective?.name)
              ?.join(', ')
          : [],
      overdue: filter?.overdue,
      lastUpdated: [filter?.lastUpdated?.lastUpdatedType || []]
    };

    return result;
  };

  const deleteObjective = async (deleteChildren) => {
    let body = { state: 'deleted', top_parent: null, deleteChildren };

    selectedTasks.forEach(async (task) => {
      await updateStateObjective(task.id, body);
      await refetchObjective(task?.parent?.id);

      if (task?.parent?.id) {
        await refetchSubObjectives(task?.parent?.id);
      } else {
        history.replace({
          pathname: url.includes('users')
            ? url.replace(/\/objectives/, '')
            : url.includes('direct')
            ? '/goals/reports/direct'
            : '/tasks',
          search: location.search
        });
      }

      if (task?.groupName) {
        refetchQueries(['objectives', 'mytasks', task?.groupName]);
      } else {
        refetchObjectives('mytasks');
      }

      invalidateTaskQueries({ task });
    });
    removeAllSelected();
    setShowMutipleDelete(!showMutipleDelete);
  };

  useEffect(() => {
    let hash = {};
    if (currentGroup?.length > 0) {
      currentGroup?.forEach((group, index) => (hash[index] = group.name));
    } else {
      hash[-1] = 'Task'; // for group = none
    }

    removeAllSelected();
    updateGroupIndexHash(hash);
    setFilterGroup(group === 'no-group' ? 'section' : group);
    // eslint-disable-next-line
  }, [group, currentGroup?.length]);

  useEffect(() => {
    getFilterPayload();
  }, [getFilterPayload]);

  useEffect(() => {
    setFilterShowTask(showTask);
  }, [showTask]);

  useEffect(() => {
    window.addEventListener('beforeunload', updateFilterSaved, {
      capture: true
    });
    return () => {
      updateFilterSaved();
      window.removeEventListener('beforeunload', updateFilterSaved, {
        capture: true
      });
    };
    // eslint-disable-next-line
  }, [currentTab]);

  return (
    <div className="flex flex-col h-full">
      <HeaderPage
        titlePage={title}
        primaryAction={
          permissions?.taskCreate && {
            title: getObjectiveLocale('Create Task'),
            icon: 'icon-add',
            onClick: () => {
              history.replace('/create-objective/task');
            }
          }
        }
        tabs={{
          tabsList: tabsList,
          url: '/tasks',
          defaultIndex: defaultTabIndex,
          onChange: (tab) => {
            setCurrentTab(tab);
          }
        }}
      />
      <div className="my-[24px] px-[40px]">
        <AdvancedFilter
          id={`tasksFilter${currentTab === 'list' ? 'List' : 'Board'}`}
          type={`task-${currentTab}${
            showTask === 'project' ? '-show-project' : ''
          }`}
          filterOptions={[
            'menuFilter',
            'search',
            'group',
            'sort',
            'showTask',
            'dateFilter'
          ]}
          filterMenu={filterMenu}
          defaultFilter={defaultFilter}
          useFilterShowTask
          useFilterGroup
          defaultGroup={defaultFilter?.group || 'phase'}
          defaultShowTask={defaultFilter?.showTask || 'personal'}
          resultModifier={resultModifier}
        />
      </div>

      {currentTab === 'list' && (
        <div className="flex flex-col grow ml-[40px] container-task-table">
          {!isGroupDataReady ? (
            <ListEmptyState
              containerClassname="h-auto mt-[60px]"
              emptyIcon="icon-loading-page"
              withLoadingIndicator
              title={getObjectiveLocale(
                `${filter?.q ? 'Searching' : 'Loading'} your tasks..`
              )}
              subtitle={getObjectiveLocale(
                `${
                  filter?.q ? 'Your search result ' : 'Your tasks list'
                } will be shown up here`
              )}
            />
          ) : (
            <Scrollbar
              scrollerProps={{
                renderer: (props) => {
                  const { elementRef, ...restProps } = props;
                  return (
                    <span
                      {...restProps}
                      id="my-task-section"
                      ref={elementRef}
                      data-cy="tasks-container"
                    />
                  );
                }
              }}
            >
              {group === 'no-group' && showTask == 'personal' ? (
                <AccordionTask
                  key={`accordion-${filterShowTask}-${group}-Task`}
                  listMetrics={listMetrics}
                  filter={filter}
                  groupData={{
                    id: null,
                    name: 'task',
                    index: -1
                  }}
                  group={group}
                  draggedId={draggedId}
                  setDraggedId={setDraggedId}
                  autoShowPlaceholder={autoShowPlaceholder}
                  setAutoShowPlaceholder={setAutoShowPlaceholder}
                />
              ) : (
                currentGroup?.length > 0 &&
                currentGroup.map(({ name, id }, index) => (
                  <AccordionTask
                    filter={filter}
                    listMetrics={listMetrics}
                    groupData={{
                      id: id,
                      name: name?.toLowerCase(),
                      index: index
                    }}
                    key={`accordion-${filterShowTask}-${group}-${
                      id || name || index
                    }`}
                    group={group}
                    draggedId={draggedId}
                    setDraggedId={setDraggedId}
                    currentGroup={group}
                    autoShowPlaceholder={autoShowPlaceholder}
                    setAutoShowPlaceholder={setAutoShowPlaceholder}
                  />
                ))
              )}
            </Scrollbar>
          )}
        </div>
      )}

      {currentTab === 'board' && (
        <>
          {!isGroupDataReady ? (
            <ListEmptyState
              containerClassname="h-auto mt-[60px]"
              emptyIcon="icon-loading-page"
              withLoadingIndicator
              title={getObjectiveLocale(
                `${filter?.q ? 'Searching' : 'Loading'} your tasks..`
              )}
              subtitle={getObjectiveLocale(
                `${
                  filter?.q ? 'Your search result ' : 'Your tasks list'
                } will be shown up here`
              )}
            />
          ) : (
            <Scrollbar
              customClass="w-full"
              ref={boardScrollbar}
              scrollerProps={{
                renderer: (props) => {
                  const { elementRef, ...restProps } = props;
                  return (
                    <span
                      {...restProps}
                      id="my-task-section"
                      ref={elementRef}
                    />
                  );
                }
              }}
              trackXProps={{
                renderer: (props) => {
                  const { elementRef, ...restProps } = props;
                  if (boardScrollbar) {
                    setBoardXScrollbarVisible(
                      boardScrollbar?.current?.state?.trackXVisible
                    );
                  }
                  return (
                    <span
                      {...restProps}
                      id="board-x-scrollbar"
                      ref={elementRef}
                    />
                  );
                }
              }}
            >
              <Boards
                filter={filter}
                listMetrics={listMetrics}
                currentGroup={currentGroup}
                draggedId={draggedId}
                setDraggedId={setDraggedId}
                boardXScrollbarVisible={boardXScrollbarVisible}
              />
            </Scrollbar>
          )}
        </>
      )}

      <ObjectivesModal />

      {showMutipleDelete && (
        <ConfirmDeleteModal
          modalType="mutipleDelete"
          closeModal={() => setShowMutipleDelete(!showMutipleDelete)}
          submitModal={(deleteChildren) => deleteObjective(deleteChildren)}
          type="task"
        />
      )}

      {selectedTasks.length > 0 && (
        <FloatingAction
          text={`${selectedTasks.length} tasks selected`}
          deleteAction={{
            onClick: () => setShowMutipleDelete(!showMutipleDelete),
            text: 'Delete objectives'
          }}
          cancelAction={{
            onClick: () => removeAllSelected(),
            text: 'Cancel selection'
          }}
        />
      )}

      {/* Sidebar */}
      {match && <SidebarMyGoals customClassname="sidebar-compact" />}
    </div>
  );
}

const NewTasks = (props) => {
  return (
    <ProjectDetailProvider>
      <MetricsProvider type="task" showLoading={false}>
        <NewTasksContent {...props} />
      </MetricsProvider>
    </ProjectDetailProvider>
  );
};

export default NewTasks;

import React, { useState } from 'react';

import { deleteAttachment } from 'client/AttachmentClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Chip from 'components/design-system/chip/Chip';
import SidebarRight from 'src/components/design-system/SidebarRight/SidebarRight';

import DocumentItem from './attachments/DocumentItem';
import Header from './attachments/Header';
import ImageItem from './attachments/ImageItem';

const SidebarAttachment = ({ handleClose, objectiveId, attachmentFiles }) => {
  const [active, setActive] = useState('documents');
  const { refetchQueries } = useRefetchQuery();

  const documents = attachmentFiles.filter((file) => file.type == 'document');
  const images = attachmentFiles.filter(
    (file) => file.type == 'image' && file.state == 'available'
  );

  const deleteAttachmentData = async (attachmentId) => {
    const { data } = await deleteAttachment(objectiveId, attachmentId);
    if (data) {
      refetchQueries(['objective', parseInt(objectiveId), 'attachments']);
    }
  };

  const EmptyState = (type = 'documents') => {
    let title, subtitle;
    switch (type) {
      case 'documents':
        title = 'No Documents';
        subtitle =
          "There is no document yet or you don't have permission to see this content.";
        break;
      case 'images':
        title = 'No Images';
        subtitle =
          "There is no image yet or you don't have permission to see this content.";
        break;
      default:
        break;
    }

    return (
      <div className="mt-[40px] px-[24px] text-center">
        <p className="typography-h500">{title}</p>
        <p className="typography-paragraph">{getObjectiveLocale(subtitle)}</p>
      </div>
    );
  };

  return (
    <SidebarRight
      fixed={false}
      customClass="!fixed right-0"
      customWidth="!w-[640px]"
    >
      <SidebarRight.Header>
        <Header handleClose={handleClose} />
      </SidebarRight.Header>
      <SidebarRight.Body withSpace={false} customClass="py-[16px]">
        <div className="px-[24px]">
          <Chip
            behavior="single"
            maxWidth={350}
            onChange={(data) => {
              setActive(data);
            }}
          >
            <Chip.Button index={0} value="documents" isActive={true}>
              {getObjectiveLocale('Documents')}
            </Chip.Button>
            <Chip.Button index={1} value="images">
              {getObjectiveLocale('Images')}
            </Chip.Button>
          </Chip>
        </div>
        {active == 'documents' &&
          documents.length == 0 &&
          EmptyState('documents')}
        {active == 'images' && images.length == 0 && EmptyState('images')}
        {active == 'documents' && documents.length > 0 && (
          <div className="mt-[8px]">
            {documents.map((item) => {
              return (
                <DocumentItem
                  key={item.id}
                  item={item}
                  deleteAttachmentData={deleteAttachmentData}
                />
              );
            })}
          </div>
        )}
        {active == 'images' && images.length > 0 && (
          <div className="mt-[8px]">
            {images.map((item) => {
              return (
                <ImageItem
                  key={item.id}
                  item={item}
                  deleteAttachmentData={deleteAttachmentData}
                />
              );
            })}
          </div>
        )}
      </SidebarRight.Body>
    </SidebarRight>
  );
};

export default SidebarAttachment;

import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';

import {
  getListMeasurement,
  getObjectiveCategory
} from 'client/ObjectivesClient';

import LoadingComponent from 'components/shared/LoadingComponent';

import { useUser } from './UserContext';

//CREATE CONTEXT
const MetricsContext = React.createContext();

async function getMetrics({ metricOptions }) {
  let list = [];
  if (metricOptions.includes('all')) {
    const { data } = await getListMeasurement();
    if (data) {
      list.push(...data);
    }
  }
  if (metricOptions.includes('no_metric')) {
    list.unshift({
      id: 0,
      description: 'No Metrics',
      unit: '',
      unitIcon: null
    });
  }
  return list;
}

async function getType() {
  const { data } = await getObjectiveCategory();
  if (data) {
    return data;
  }
}

async function getData(metricOptions) {
  const metrics = await getMetrics(metricOptions);
  const types = await getType();
  const data = {
    listMetrics: metrics || [],
    objectiveCategories: types || []
  };
  return data;
}

//PROVIDER
function MetricsProvider(props) {
  const { showLoading = true } = props || {};
  const {
    config: { showMetricForObjective, showMetricForTask }
  } = useUser();
  let metricOptions =
    props.type === 'goal' ? showMetricForObjective : showMetricForTask;
  let {
    data = null,
    isRejected,
    isPending,
    isSettled,
    reload
  } = useAsync({
    promiseFn: getData,
    metricOptions: metricOptions
  });

  if (!data && showLoading) {
    return <LoadingComponent />;
  }

  return <MetricsContext.Provider value={data} {...props} />;
}

//MUTATION
function useMetrics() {
  const context = React.useContext(MetricsContext);
  return context;
}

export { MetricsProvider, useMetrics };

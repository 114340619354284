import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getListMeasurement } from 'client/ObjectivesClient';
import {
  CreateObjectiveProvider,
  useCreateObjective
} from 'context/CreateObjectiveContext';
import { MetricsProvider } from 'context/MetricsContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import useModalObjectives from 'hooks/useModalObjectives';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import ListLabel from 'components/objectives/ListLabel';
import InputField from 'components/shared/InputField';
import { useTeamDetail } from 'src/context/team/TeamDetailContext';

import DueDateObjective from '../compact-objective/fragments/DueDateObjective';
import MetricsObjective from '../compact-objective/fragments/MetricsObjective';
import OwnerObjective from '../compact-objective/fragments/OwnerObjective';
import ProgressObjective from '../compact-objective/fragments/ProgressObjective';

const InlineCreate = (props) => {
  return (
    <MetricsProvider type="goal">
      <CreateObjectiveProvider {...props}>
        <InlineCreateContent {...props} />
      </CreateObjectiveProvider>
    </MetricsProvider>
  );
};

const getTeamInvolvements = (teamDetail, currentObjective) => {
  const teamReviewer =
    teamDetail?.involvements?.find(
      (involvement) => involvement.role == 'reviewer'
    ) || null;

  const currentInvolvements = currentObjective?.involvements?.filter(
    (involvement) => involvement.role != 'assigner'
  );
  if (!teamReviewer) return currentInvolvements;

  const assigner = {
    role: 'assigner',
    user: teamReviewer?.user,
    userId: teamReviewer?.user?.id,
    visible: true
  };
  return [...currentInvolvements, assigner];
};

const InlineCreateContent = ({ setInlineCreate }) => {
  const { addToast } = useToastContext();
  const { refetchObjectives } = useRefetchQuery();
  const titleRef = useRef();
  const [saveLoading, setSaveLoading] = useState(false);
  const isTeam = location.pathname.includes('/team');

  const teamDetail = useTeamDetail(!isTeam);

  const {
    config: {
      showMetricForObjective,
      objectiveWeightType,
      objectiveWeightTypeOptions,
      permissions: { objectiveWeightManage }
    }
  } = useUser();

  const { isSuccess, singleObjective, saveGoal, handleChangeObjective } =
    useCreateObjective();

  const { weight, objectiveCategory, type } = singleObjective;

  const getMetrics = useCallback(async () => {
    let list = [];
    let newObjective = { ...singleObjective };
    if (showMetricForObjective.includes('all')) {
      const { data } = await getListMeasurement();
      if (data) {
        list.push(...data);
      }
    }
    if (list.length > 0) {
      const savedMeasurement =
        list.find((item) => item.id == newObjective.measurement.unitId) ||
        list[0];
      newObjective.measurement.unitId = savedMeasurement.id;
      newObjective.measurement.id = savedMeasurement.id;
      newObjective.measurement.description = savedMeasurement.description;
      newObjective.measurement.unit = savedMeasurement.unit;
      newObjective.measurement.unitIcon = savedMeasurement.unitIcon;
    }
    newObjective.measurement.progressColorHex = '44DB5E';
    handleChangeObjective(singleObjective?.id, newObjective);
    // eslint-disable-next-line
  }, []);

  const dueDateHandler = (data) => {
    const { dueDate, startDate, recurrence } = data;
    let newObjective = { ...singleObjective };
    newObjective.startDate = startDate;
    newObjective.dueDate = dueDate;
    newObjective.recurrence = recurrence;
    handleChangeObjective(singleObjective?.id, newObjective);
  };

  const metricsHandler = (data) => {
    const { targetValue, unitId, unit, rollUp, startingValue } = data;
    let newObjective = { ...singleObjective };
    const measurement = {
      targetValue,
      unitId,
      id: unitId,
      unit,
      rollUp,
      startingValue
    };
    newObjective.measurement = measurement;
    handleChangeObjective(singleObjective?.id, newObjective);
  };

  const titleHandler = (e) => {
    let newObjective = { ...singleObjective };
    newObjective.name = e.target.value;
    handleChangeObjective(singleObjective?.id, newObjective);
  };

  const saveHandler = async () => {
    setSaveLoading(true);
    let finalObjective = { ...singleObjective };

    const measurement = {
      rollUp: singleObjective.measurement.rollUp,
      startingValue: singleObjective.measurement.startingValue,
      targetValue: singleObjective.measurement.targetValue,
      unitId: singleObjective.measurement.unitId
    };

    finalObjective.measurement = measurement;
    if (isTeam && teamDetail) {
      finalObjective.involvements = getTeamInvolvements(
        teamDetail,
        finalObjective
      );
    }
    handleChangeObjective(singleObjective?.id, finalObjective);
    saveGoal();
    setSaveLoading(false);
  };

  const { checkValidationSoftWarning, isShowWarning } = useValidationMinMax(
    singleObjective?.weight
  );
  const showModalWeight = useModalObjectives((state) => state.showModal);

  const modalCallback = (objectives) => {
    const reservedData = objectives[0];
    let newObjective = { ...singleObjective };
    if (objectiveWeightType == 'type') {
      if (reservedData?.objectiveCategoryId) {
        newObjective.objectiveCategoryId = reservedData.objectiveCategoryId;
        newObjective.objectiveCategory = reservedData.objectiveCategory;
      } else {
        newObjective.objectiveCategory = null;
        newObjective.objectiveCategoryId = null;
      }
      newObjective.weight = reservedData.weight || 0;
    } else {
      newObjective.weight = reservedData.weight;
    }

    handleChangeObjective(singleObjective?.id, newObjective);
  };

  const propsModal = {
    objectives: [singleObjective],
    isIndividual: true,
    isCreate: true,
    level: 0,
    createCallback: modalCallback
  };

  const isWeightError = objectiveCategory?.isLocked
    ? weight < objectiveCategory?.weight
    : isShowWarning;

  const insertLabel = () => {
    let listLabel = [];
    const goalType = isTeam ? 'team_goal' : type;
    const isAllowedObjectiveCategory =
      objectiveWeightTypeOptions.includes(goalType);
    if (
      objectiveWeightType == 'type' &&
      singleObjective.type !== 'task' &&
      isAllowedObjectiveCategory
    ) {
      listLabel.push({
        id: 'editted',
        title: '',
        content: objectiveCategory?.name ?? 'Select type here',
        colorHex: objectiveCategory?.colorHex ?? 'F84535',
        bgColorHex: objectiveCategory?.bgColorHex ?? 'FFDAD6',
        editable: true,
        onClick: () =>
          showModalWeight({ modalType: 'adjustWeight', props: propsModal }),
        defaultPointer: true
      });
    }

    if (objectiveWeightManage) {
      listLabel.push({
        id: 'weight',
        title: '',
        iconLeft: 'icon-weight',
        iconLeftColor: isWeightError ? 'var(--r-600)' : 'var(--n-600)',
        content: weight > 0 ? `${weight}%` : 'Set Weight',
        colorHex: isWeightError ? 'F84535' : '475569',
        bgColorHex: isWeightError ? 'FFDAD6' : 'f1f5f9',
        editable: true,
        onClick: () =>
          showModalWeight({
            modalType: 'adjustWeight',
            props: { ...propsModal, fromBadgeWeight: true }
          }),
        tooltipText: weight > 0 ? `Weight: ${weight}%` : null
      });
    }

    return listLabel;
  };

  let listBadge = insertLabel();

  useEffect(() => {
    if (isSuccess) {
      setSaveLoading(false);
      setInlineCreate(false);
      refetchObjectives('mygoals');
      addToast({
        title: 'Success',
        msg: 'New goal created',
        type: 'success'
      });
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    getMetrics();
    titleRef?.current?.focus();
  }, [getMetrics]);

  useEffect(() => {
    checkValidationSoftWarning();
  }, [weight, checkValidationSoftWarning]);

  const metricWordingTrigger = (
    <p
      className={`typography-h400 text-n-600 "width-percentage-100 text-center }`}
      data-cy="list-detail-no-progress-metric"
    >
      {getObjectiveLocale('No Metrics')}
    </p>
  );

  return (
    <div className="bg-n-000 border border-solid border-n-300 rounded min-h-[96px] py-16 pr-16 pl-16 relative flex flex-row">
      <div
        style={{ width: 'calc(100% - 525px)' }}
        className="flex flex-row mr-[24px]"
      >
        <div className="mr-16 h-[32px]">
          <OwnerObjective
            objective={singleObjective}
            handleChangeObjective={handleChangeObjective}
          />
        </div>
        <div className="flex flex-col w-full justify-between h-full">
          <InputField
            htmlFor="input-goals-name"
            inputClass="typography-paragraph text-n-900"
            className="w-full h-[32px] flex"
            handleChange={titleHandler}
            ref={titleRef}
            dataCy={'input-title-goal'}
          />
          <div className="flex flex-row">
            <ListLabel data={listBadge} centered={false} />
          </div>
        </div>
      </div>
      <div className="w-[438px] h-[32px] flex flex-row items-center">
        <div className="w-[90px] mr-[24px] relative">
          <DueDateObjective
            overdue={false}
            dueDate={singleObjective?.dueDate}
            startDate={singleObjective?.startDate}
            callback={dueDateHandler}
            objectiveDetail={singleObjective}
            isCreate
          />
        </div>
        <div className="w-[144px] mr-[24px]">
          <ProgressObjective
            progressPercentage={0}
            expectedProgressPercentage={100}
            tooltipProgressText=""
            barColor="var(--g-500)"
            textColor="text-g-500"
            objective={singleObjective}
            isCreate
            allowZero
          />
        </div>
        <div className="w-[144px] flex flex-start cursor-pointer">
          <MetricsObjective
            calculationType={singleObjective?.calculationType}
            isGoalType={
              singleObjective.type == 'goal' && !singleObjective.isProject
            }
            measurement={singleObjective?.measurement}
            callback={metricsHandler}
            isCreate
            customTrigger={
              !singleObjective?.measurement.unit && metricWordingTrigger
            }
            milestoneMode={singleObjective?.milestoneMode}
            currentMilestone={singleObjective?.currentMilestone}
            milestoneType={singleObjective?.milestoneType}
          />
        </div>
      </div>
      <div className="absolute bottom-0 right-0 flex flex-row pr-16 pb-8">
        <Button.Secondary
          onClick={() => setInlineCreate(false)}
          customClass="mr-8"
        >
          {getObjectiveLocale('Cancel')}
        </Button.Secondary>
        <Button
          isLoading={saveLoading}
          onClick={saveHandler}
          datacy="save-inline-goal"
        >
          {getObjectiveLocale('Save')}
        </Button>
      </div>
    </div>
  );
};

export default InlineCreate;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';

import dayjs from 'dayjs';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';

import { getObjectiveDetail } from 'client/ObjectivesClient';
import { useGetProgressOvertime } from 'hooks/api/useObjectives';
import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import {
  changeFormatData,
  seriesGenerator
} from 'utils/ProgressOvertimeHelper';

import TruncateParagraph from 'components/shared/TruncateParagraph';
import SVGWithTooltip from 'pages/objectiveAnalytics/helper/SVGWithTooltip';

import ObjectiveGraph from './ObjectiveGraph';
import ObjectiveProgressBar from './ObjectiveProgressBar';

const areEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

const ObjectiveCard = React.memo(
  ({ onRemove, columnNumber, rowNumber, objective, isViewGraph }) => {
    const history = useHistory();
    const timeFrame = 'monthly';
    const [formattedProgressData, setFormattedProgressData] = useState({});
    const [timeframeProgress, setTimeframeProgress] = useState({});
    const [minimumProgress, setMinimumProgress] = useState(0);
    const [maximumProgress, setMaximumProgress] = useState(0);

    const handleEditObjective = useObjectiveComparative(
      (state) => state.handleEditObjective
    );

    const fetchObjective = () => {
      return getObjectiveDetail(objective?.id);
    };

    const { data: objectivesData } = useQuery(
      ['objective', objective?.id],
      fetchObjective,
      {
        initialData: objective,
        staleTime: Infinity,
        onSuccess: (data) => {
          if (data.data) {
            handleEditObjective({
              columnNumber: columnNumber,
              rowNumber: rowNumber,
              objective: data.data
            });
          }
        }
      }
    );

    const { id, name, startDate, dueDate, measurement } =
      objectivesData.data || objective;

    const { refetch } = useGetProgressOvertime(
      id,
      'comparation-progress-overtime',
      { timeFrame: timeFrame },
      {
        onSuccess: (data) => {
          const progressData = flatten(
            data?.pages?.map((page) => {
              const metadata = page?.metadata?.metadata;
              setTimeframeProgress({ ...metadata, timeFrame });

              return page?.data?.map((data) => {
                return data;
              });
            })
          );

          const changedData = changeFormatData(progressData, timeFrame);
          const firstTick = dayjs(changedData?.[0]?.date);
          const lastTick = dayjs(changedData?.[changedData?.length - 1]?.date);

          const customStartAndLastTick = {
            firstTick: new Date(firstTick).getTime(),
            lastTick: new Date(lastTick).getTime(),
            format: "{value:%b '%y}"
          };

          setFormattedProgressData(
            seriesGenerator(
              changedData,
              timeFrame,
              minimumProgress,
              maximumProgress,
              setMinimumProgress,
              setMaximumProgress
            )
          );
        }
      }
    );

    useEffect(() => {
      setMinimumProgress(0);
      refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div
        className={`w-[288px] bg-n-000 shadow-raised rounded-[4px] cursor-pointer ${
          isViewGraph ? 'pt-[12px] h-[209px]' : 'py-[12px] px-[16px] h-[144px]'
        } group`}
        onClick={() => history.replace(`/objective-analytics/${id}`)}
      >
        <SVGWithTooltip
          iconName="icon-clear"
          text="Remove Objective"
          customClass={`relative float-right invisible group-hover:visible ${
            isViewGraph ? 'mr-[16px]' : ''
          }`}
          action={() => onRemove(rowNumber)}
        />
        <TruncateParagraph
          className={`typography-h200 text-n-900 ${
            isViewGraph ? 'ml-[16px] mb-[8px]' : ''
          }`}
        >
          {name}
        </TruncateParagraph>
        {isViewGraph ? (
          <ObjectiveGraph
            formattedProgressData={formattedProgressData}
            minimumProgress={minimumProgress}
            maximumProgress={maximumProgress}
            measurement={measurement}
          />
        ) : (
          <ObjectiveProgressBar
            startDate={startDate}
            dueDate={dueDate}
            timeframeProgress={timeframeProgress}
            timeFrame={timeFrame}
            measurement={measurement}
          />
        )}
      </div>
    );
  },
  areEqual
);

ObjectiveCard.displayName = 'ObjectiveCard';

export default ObjectiveCard;

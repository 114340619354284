import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useFeeds } from 'context/FeedsContext';
import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import { getCultureLocale } from 'utils/HelperUtils';

import LoadingComponent from 'components/shared/LoadingComponent';

import TimelineCard from './TimelineCard';

const Feeds = ({
  widthWrapper,
  groupDetail,
  isRefetch,
  eventOnClick = () => {},
  userId
}) => {
  const params = useParams();
  const { user } = useUser();

  const { refetch, feeds, isFetching, isFetchingMore, page } = useFeeds(
    params?.groupId || groupDetail?.id,
    userId || user?.id,
    params?.postId
  );

  useEffect(() => {
    if (isRefetch) {
      refetch();
    }
  }, [page, isRefetch]);

  return (
    <>
      <div
        data-cy="timeline-section"
        className={`timeline-feeds-section ${widthWrapper}`}
        id="timeline"
      >
        <div className={`flex relative object-contain`}>
          <div className="w-[560px]">
            {!params.postId && (
              <div className="mt-[40px] mb-[16px] typography-h300 text-n-800 uppercase">
                {getCultureLocale('Feeds')}
              </div>
            )}
            {!isRefetch && feeds?.length > 0 ? (
              <>
                {feeds.map((feed) => (
                  <TimelineCard
                    feed={feed}
                    eventOnClick={eventOnClick}
                    key={feed?.id}
                  />
                ))}
              </>
            ) : (
              !isFetching && (
                <div className="empty-feeds mt-[64px]">
                  <img
                    src={Config.asset.v2.icoEmptyMyTasks}
                    className="w-[200px] mb-[24px] height-auto"
                  />
                  <p>
                    {params.postId
                      ? getCultureLocale('This post is unavailable')
                      : getCultureLocale("You don't have any feeds yet")}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {(isFetchingMore || isFetching) && (
        <LoadingComponent className="w-[560px]" />
      )}
    </>
  );
};

export default Feeds;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import isEqual from 'lodash/isEqual';

import { UsersAdminProvider } from 'context/admin/UsersAdminContext';
import { SelectedUserProvider } from 'context/useSelectedUser/useSelectedUser';
import { useLocale } from 'hooks/useLocale';
import usePulseSurvey from 'hooks/usePulseSurvey';

import SelectMemberComponent from 'components/SelectMemberComponents/SelectMemberComponent';
import Button from 'components/design-system/Button';
import Switcher from 'components/design-system/Switcher';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';
import SelectGroupMember from 'components/surveys/SelectGroupMember';

const ParticipantForm = ({ isReadOnly }) => {
  const { setBothLocale } = useLocale();
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const participantParams = searchParams.get('group');

  const { surveyData, setSurveyData, setTotalParticipant } = usePulseSurvey();

  const [showModal, setShowModal] = useState({ show: false });

  const handleSetViewTab = (tab) => {
    if (
      surveyData?.participantType === 'user' &&
      (surveyData.includes?.length > 0 || surveyData?.selectAll === true)
    ) {
      setShowModal({ show: true, wording: 'employee', targetState: tab });
    } else if (
      surveyData?.participantType === 'team' &&
      (surveyData.includes?.length > 0 || surveyData?.selectAll === true)
    ) {
      setShowModal({ show: true, wording: 'group', targetState: tab });
    } else {
      setSurveyData({ ...surveyData, participantType: tab });
      history.replace(`${location.pathname}?step=Participants&group=${tab}`);
    }
  };

  const handleClearSelection = (e) => {
    e.stopPropagation();
    if (showModal.wording) {
      setShowModal({ show: false });
      setSurveyData({
        ...surveyData,
        includes: [],
        excludes: [],
        selectAll: false,
        participantType: showModal.targetState
      });
    }
  };

  const onHandleCreatePulseSurvey = () => {
    localStorage.setItem(
      'prevUrlRedirect',
      `${location.pathname}${location.search}`
    );
    localStorage.setItem('surveyData', JSON.stringify(surveyData));
    history.replace('/add-participant?source=composer');
  };

  const handleSetMember = ({ selectAll, include, exclude }) => {
    setSurveyData({
      ...surveyData,
      includes: include,
      excludes: exclude,
      selectAll: selectAll
    });
  };

  useEffect(() => {
    participantParams &&
      !isEqual(participantParams, surveyData?.participantType) &&
      setSurveyData({ ...surveyData, participantType: participantParams });
  }, [participantParams]);

  return (
    <>
      <div className="flex justify-between">
        <p className="typography-h500 text-n-900">
          {setBothLocale('Select participant')}
        </p>
        {surveyData?.participantType === 'team' && (
          <Button.Secondary onClick={() => onHandleCreatePulseSurvey()}>
            <SVGIcon
              size="24"
              iconName="icon-add"
              fillColor="var(--n-600)"
              customClass="mr-[8px]"
            />
            {setBothLocale('Create Participant Team')}
          </Button.Secondary>
        )}
      </div>
      <p className="typography-h100 text-n-800 mt-[24px] mb-[8px]">
        {setBothLocale('Select by')}
      </p>
      <Switcher
        options={[
          { id: 'user', name: 'Individual Participant' },
          { id: 'team', name: 'Participant Group' }
        ]}
        onChange={handleSetViewTab}
        selectedOption={surveyData?.participantType}
        fullWidth
        isReadOnly={isReadOnly}
      />
      {surveyData?.participantType === 'user' ? (
        <UsersAdminProvider>
          <SelectedUserProvider
            initialValue={{
              include: surveyData?.includes?.map((id) => ({ id: id })),
              exclude: surveyData?.excludes?.map((id) => ({ id: id })),
              selectAll: surveyData?.selectAll
            }}
          >
            <SelectMemberComponent
              handleSet={(e) => handleSetMember(e)}
              isReadOnly={isReadOnly}
              customClass="mt-[16px]"
              setTotalParticipant={setTotalParticipant}
              type="users"
            />
          </SelectedUserProvider>
        </UsersAdminProvider>
      ) : (
        <SelectGroupMember
          members={surveyData}
          setMembers={setSurveyData}
          isReadOnly={isReadOnly}
          setTotalParticipant={setTotalParticipant}
        />
      )}
      {showModal?.show && (
        <Modal
          className="w-[400px]"
          title={`Clear ${showModal.wording} selection`}
          description={`If you chose this option, your ${showModal.wording} selections will be cleared. Are you sure?`}
          withPrimaryBtn={{
            title: 'Clear selection',
            dataCy: 'button-primary-modal',
            onClick: (e) => handleClearSelection(e)
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            dataCy: 'button-secondary-modal',
            onClick: () =>
              setShowModal({ show: false, wording: null, targetState: null })
          }}
          withCloseIcon={false}
          withWarningIcon={true}
        ></Modal>
      )}
    </>
  );
};

export default ParticipantForm;

import client from './ApiClient';
import fileClient from './FileClient';

function getListObjectives(params, signal) {
  const url = `v3/objectives`;
  return client(url, { params: params, skipNulls: false }, false, signal);
}

function postCloneObjective(id, body) {
  const url = `v1/objectives/${id}/clone`;
  return client(url, { method: 'POST', body: body });
}

function getGoalsScoring(assignmentId, params = {}) {
  const url = `v3/formal_reviews/assignments/${assignmentId}/goals_scoring/objectives`;
  return client(url, { params });
}

function getDueDateClone(id, params) {
  const url = `v1/objectives/${id}/clone`;
  return client(url, { params });
}

function getTasksScoring(assignmentId, params = {}) {
  const url = `v3/formal_reviews/assignments/${assignmentId}/tasks_scoring/objectives`;
  return client(url, { params });
}

function getCountRating(userId, params) {
  const url = `v2/users/${userId}/objectives/count_rating`;
  return client(url, { params });
}

function getPerformanceRating(assignmentId) {
  const url = `v3/formal_reviews/assignments/${assignmentId}/performance_rating/objectives`;
  return client(url);
}

function getAllTeam(params) {
  return client(`/v1/teams`, { params });
}

function getListTeams(params) {
  const url = `v1/users/me/teams`;
  return client(url, { params });
}

function getTeamDetail(teamId) {
  const url = `v1/teams/${teamId}`;
  return client(url);
}

function getChildTeam(teamId, params) {
  const url = `v1/teams/${teamId}/child`;
  return client(url, { params });
}

function postBulkTeam(body) {
  const url = `v1/teams/bulk`;
  return client(url, { method: 'POST', body });
}

function patchTeamAchievement(body) {
  const url = `v1/teams/bulk_update_goals`;
  return client(url, { method: 'PATCH', body });
}

function getTeamRoleHistory(id, query) {
  return client(`v1/teams/${id}/involvement_histories`, { params: query });
}

function getListNeedResponse(params) {
  const url = `v2/objectives/priorities`;
  return client(url, { params });
}
function getSingleObjectiveTree(objectiveParentId) {
  const url = `v3/objectives/${objectiveParentId}/?children=1`;
  return client(url);
}

function getMySingleObjectiveTree(objectiveParentId, params) {
  const url = `v3/objectives/${objectiveParentId}/?children=1&my_goal=1`;
  return client(url, { params: params });
}

function getActivities(params) {
  const url = 'v1/notifications/unseen/count';
  return client(url, { params: params });
}

function deleteActivitiesCount(id) {
  const url = `v1/notifications/unseen/${id}`;
  return client(url, { method: 'DELETE' });
}

function getNeedResponses(params) {
  const url = 'v2/objectives/priorities/count';
  return client(url, { params });
}

function editObjective(objectiveId, body) {
  const url = `v3/objectives/${objectiveId}`;
  return client(url, { method: 'PATCH', body: body });
}

function updateCurrentValue(objectiveId, body) {
  const url = `v3/objectives/${objectiveId}/current_value`;
  return client(url, { method: 'PUT', body: body });
}

function restoreObjective(objectiveId, body = {}) {
  const url = `v3/objectives/${objectiveId}/restore`;
  return client(url, { method: 'PATCH', body: body });
}

function updateStateObjective(objectiveId, body) {
  const url = `v3/objectives/${objectiveId}/state`;
  return client(url, { method: 'PUT', body: body });
}

function getParentSuggestions(params) {
  const url = `v2/objectives/goals/parent_suggestions`;
  return client(url, { params: params });
}

function getTaskParentSuggestions(params) {
  const url = 'v1/objectives/tasks/parent_suggestions';
  return client(url, { params: params });
}

function getListMeasurement() {
  const url = `v1/measurement_units`;
  return client(url);
}

function addMeasurement(body) {
  const url = `v1/measurement_units`;
  return client(url, { method: 'POST', body: body });
}

function editMeasurement(id, body) {
  const url = `v1/measurement_units/${id}`;
  return client(url, { method: 'PUT', body: body });
}

function deleteMeasurement(id) {
  const url = `v1/measurement_units/${id}`;
  return client(url, { method: 'DELETE', body: {} });
}

function getObjectiveCategory(params = {}, signal) {
  const url = `v1/objectives_category`;
  return client(url, { params }, false, signal);
}

function createMultipleObjective(body) {
  const url = `v2/objectives`;
  return client(url, { method: 'POST', body: body });
}

function createTeam(body) {
  const url = `v1/teams`;
  return client(url, { method: 'POST', body: body });
}

function createSingleObjective(body) {
  const url = `v3/objectives`;
  return client(url, { method: 'POST', body: body });
}

function getObjectiveDetail(id, params = {}) {
  const url = `v3/objectives/${id}`;
  return client(url, { params });
}

function getObjectiveLastActivity(id) {
  const url = `v3/objectives/${id}/last_progress_activity`;
  return client(url);
}

function createComment(objectiveId, body) {
  const url = `v3/objectives/${objectiveId}/comments`;
  return client(url, { method: 'POST', body: body });
}

function getObjectiveActivities(objectiveId) {
  const url = `v2/objectives/${objectiveId}/activities`;
  return client(url);
}

function getFormerDirectReport(userId, params) {
  const url = `v1/users/${userId}/direct_reports`;
  return client(url, { params: params });
}

function getFormerInDirectReport(userId, params) {
  const url = `v1/users/${userId}/indirect_reports`;
  return client(url, { params: params });
}

function createAttachment(objectiveId, body) {
  const url = `v1/objectives/${objectiveId}/attachments`;
  return client(url, { method: 'POST', body: body });
}

function followUpNeedResponse(objectiveId, body) {
  const url = `/v1/objectives/${objectiveId}/comments`;
  return client(url, { method: 'POST', body: body });
}

function followObjective(objectiveId, body) {
  const url = `/v1/objectives/${objectiveId}/stakeholders`;
  return client(url, { method: 'POST', body: body });
}

function unfollowObjective(objectiveId, userId) {
  const url = `v1/objectives/${objectiveId}/stakeholders/${userId}`;
  return client(url, { method: 'DELETE', body: {} });
}

function remindObjective(objectiveId) {
  const url = `v1/objectives/${objectiveId}/reminders`;
  return client(url, { method: 'POST', body: {} });
}

function updateWeightConfig(body) {
  const url = `v1/admin/configs`;
  return client(url, { method: 'PUT', body: body });
}

function putObjectivesConfig(payload) {
  return client(`v1/admin/configs`, {
    method: 'PUT',
    body: payload
  });
}

function putObjectivesApprovalConfig(payload) {
  return client(`v1/admin/configs`, {
    method: 'PUT',
    body: payload
  });
}

function getObjectivesCount(params) {
  return client(`v3/objectives/count`, {
    method: 'GET',
    params: params
  });
}

function updateBulkObjective(body) {
  const url = `v1/objectives`;
  return client(url, { method: 'PATCH', body: body });
}

function getTotalObjectives(params, signal) {
  const url = `v3/objectives/count`;
  return client(url, { params: params }, false, signal);
}

function getListFilter(params) {
  const url = `v1/users/filters`;
  return client(url, { params: params });
}

function getMilestoneDetail(id) {
  const url = `v1/objectives/${id}/milestones`;
  return client(url);
}

function updateProgressMilestone(id, body) {
  const url = `v1/objectives/${id}/milestones/bulk_update`;
  return client(url, { method: 'PUT', body: body });
}

// milestone type => monthly, quarterly
function convertMilestone(milestoneType, query) {
  const url = `v1/objectives/milestones/convert/${milestoneType}`;
  return client(url, { params: query });
}

function getObjectivePriorities(params) {
  return client('admin/objective_priority', { params });
}

function getObjectivePhases(params) {
  return client('v1/objective_phases', { params });
}

function updateObjectivePhases(id, body) {
  return client(`v1/objective_phases/${id}`, { method: 'PUT', body });
}

function getObjectiveDependencySuggestions(query) {
  return client('v1/objectives/tasks/dependency_suggestions', {
    method: 'GET',
    params: query
  });
}

function addObjectiveDependencySuggestions(id, body) {
  return client(`/v3/objectives/${id}/dependencies`, { method: 'POST', body });
}

function deleteObjectiveDependencySuggestions(id, body) {
  return client(`/v3/objectives/${id}/dependencies`, {
    method: 'DELETE',
    body: body
  });
}

function getProgressOvertimeData(id, query, signal) {
  const url = `v1/objectives/${id}/progress_over_time`;
  return client(url, { params: query }, false, signal);
}

function exportObjectiveCSV(id, query) {
  const url = `v1/objectives/${id}/export_to_csv`;
  return fileClient(url, { params: query }, 'text/csv');
}

function getObjectivePhaseNames(query) {
  return client(`v1/objective_phases/name`, { params: query });
}

function getObjectiveSectionNames(query) {
  return client(`v1/objective_sections/name`, { params: query });
}

function getProjectStatistics(id, query) {
  return client(`v2/objectives/${id}/statistics`, { params: query });
}

function getProjectCountPhases(id) {
  return client(`v2/objectives/${id}/count_phases`);
}

function getProjectSections(query) {
  return client(`v1/objective_sections`, { params: query });
}

function addProjectSectionAbove(projectId, sectionId, query) {
  return client(`v1/objective_sections/${projectId}/add_above/${sectionId}`, {
    method: 'POST',
    body: query
  });
}

function addProjectSectionBelow(projectId, sectionId, query) {
  return client(`v1/objective_sections/${projectId}/add_below/${sectionId}`, {
    method: 'POST',
    body: query
  });
}

function moveProjectSectionAbove(projectId, sectionId) {
  return client(`v1/objective_sections/${projectId}/move_above/${sectionId}`, {
    method: 'PATCH'
  });
}

function moveProjectSectionBelow(projectId, sectionId) {
  return client(`v1/objective_sections/${projectId}/move_below/${sectionId}`, {
    method: 'PATCH'
  });
}

function renameProjectSection(projectId, sectionId, query) {
  return client(`v1/objective_sections/${projectId}/rename/${sectionId}`, {
    method: 'PATCH',
    body: query
  });
}

function deleteProjectSection(projectId, sectionId) {
  return client(`v1/objective_sections/${projectId}/delete/${sectionId}`, {
    method: 'DELETE'
  });
}

function getTaskCountBySection(projectId, sectionId) {
  return client(`v1/objective_sections/${projectId}/count_task/${sectionId}`);
}

function getTaskCountByPhase(projectId, phaseId) {
  return client(`v1/objective_phases/${projectId}/count_task/${phaseId}`);
}

function getObjectiveInvolvements(id, query) {
  return client(`v1/users/${id}/objective_involvements`, { params: query });
}

function getTopParent(params) {
  return client(`v3/objectives/parents`, { params });
}

function getPreferencesFilter() {
  return client(`v1/users/me/preferences`);
}

function getJIRAProjects(params) {
  return client(`jira/projects`, { params }, true);
}

function getJIRAIssues(params) {
  return client(`jira/issues`, { params }, true);
}

function postJIRALink(id, body) {
  return client(
    `v3/objectives/${id}/integrations`,
    {
      method: 'POST',
      body
    },
    true
  );
}

function syncJIRA(objectiveId, integrationId) {
  const url = `v3/objectives/${objectiveId}/integrations/${integrationId}/sync`;
  return client(url, { method: 'PUT' });
}

function importChildIssueJIRA(objectiveId, integrationId) {
  const url = `v3/objectives/${objectiveId}/integrations/${integrationId}/import_child_issues`;
  return client(url, { method: 'PUT' });
}

function editJIRALink(id, integrationId, body) {
  return client(
    `v3/objectives/${id}/integrations/${integrationId}`,
    {
      method: 'PATCH',
      body
    },
    true
  );
}

function deleteJIRALink(objectiveId, integrationId, params = {}) {
  const url = `v3/objectives/${objectiveId}/integrations/${integrationId}`;
  return client(url, { method: 'DELETE', params });
}

function updatePreferencesFilter(body) {
  const url = `v1/users/me/preferences`;
  return client(url, { method: 'PUT', body });
}

function updateFormalReviewPreferencesFilter(body) {
  const url = `v1/users/me/preferences/append`;
  return client(url, { method: 'PUT', body });
}

function exportObjectiveProgressSummary(params) {
  return client('v1/objectives/summary/export', { method: 'POST', params });
}

function getObjectiveProgressSummaryPdf(params) {
  return client('v1/objectives/summary/export/pdf', { params });
}

function getObjectiveApproval(teamId, objectiveId) {
  return client(`v1/teams/${teamId}/objectives/${objectiveId}/approval`);
}

function getObjectiveConfigs(objectiveId, params = {}) {
  return client(`v3/objectives/${objectiveId}/configs`, { params });
}

function getPreviewJira(body) {
  const url = `v3/objectives/preview/integrations`;
  return client(url, { method: 'POST', body }, true);
}

function getObjectivePermissions(objectiveId, query) {
  return client(`v3/objectives/${objectiveId}/permissions`, { params: query });
}

function getObjectiveChildren(objectiveId, query) {
  return client(`v3/objectives/${objectiveId}/children`, { params: query });
}

//Highlighted Goals
function putHighlightedGoal(objectiveId) {
  const url = `v1/objectives/${objectiveId}/highlight`;
  return client(url, { method: 'PUT' });
}

function deleteHighlightedGoal(objectiveId) {
  const url = `v1/objectives/${objectiveId}/highlight`;
  return client(url, { method: 'DELETE' });
}

export {
  getListObjectives,
  getSingleObjectiveTree,
  getMySingleObjectiveTree,
  getNeedResponses,
  getActivities,
  deleteActivitiesCount,
  updateCurrentValue,
  updateStateObjective,
  getParentSuggestions,
  getTaskParentSuggestions,
  getListMeasurement,
  createSingleObjective,
  createMultipleObjective,
  getObjectiveCategory,
  editObjective,
  getListNeedResponse,
  getObjectiveDetail,
  getObjectiveLastActivity,
  getAllTeam,
  getListTeams,
  getTeamDetail,
  getChildTeam,
  postBulkTeam,
  patchTeamAchievement,
  getTeamRoleHistory,
  createComment,
  getObjectiveActivities,
  getGoalsScoring,
  getPerformanceRating,
  createAttachment,
  getTasksScoring,
  followUpNeedResponse,
  followObjective,
  unfollowObjective,
  remindObjective,
  createTeam,
  updateWeightConfig,
  putObjectivesConfig,
  putObjectivesApprovalConfig,
  getObjectivesCount,
  updateBulkObjective,
  getTotalObjectives,
  getCountRating,
  getListFilter,
  getFormerDirectReport,
  getFormerInDirectReport,
  addMeasurement,
  editMeasurement,
  deleteMeasurement,
  postCloneObjective,
  getDueDateClone,
  getMilestoneDetail,
  updateProgressMilestone,
  convertMilestone,
  getObjectivePriorities,
  getObjectivePhases,
  getObjectiveDependencySuggestions,
  addObjectiveDependencySuggestions,
  deleteObjectiveDependencySuggestions,
  getProgressOvertimeData,
  exportObjectiveCSV,
  getObjectivePhaseNames,
  getObjectiveSectionNames,
  getProjectStatistics,
  getProjectCountPhases,
  updateObjectivePhases,
  getProjectSections,
  addProjectSectionAbove,
  addProjectSectionBelow,
  moveProjectSectionAbove,
  moveProjectSectionBelow,
  renameProjectSection,
  deleteProjectSection,
  getTaskCountBySection,
  getTaskCountByPhase,
  getObjectiveInvolvements,
  restoreObjective,
  getTopParent,
  getPreferencesFilter,
  updatePreferencesFilter,
  updateFormalReviewPreferencesFilter,
  exportObjectiveProgressSummary,
  getObjectiveProgressSummaryPdf,
  getJIRAProjects,
  getJIRAIssues,
  postJIRALink,
  syncJIRA,
  deleteJIRALink,
  getObjectiveApproval,
  getObjectiveConfigs,
  getObjectivePermissions,
  getObjectiveChildren,
  putHighlightedGoal,
  deleteHighlightedGoal,
  getPreviewJira,
  importChildIssueJIRA,
  editJIRALink
};

import React from 'react';

import startCase from 'lodash/startCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/shared/modal/Modal';

function ModalConfirmation({
  postClone,
  handleChange,
  isCloning,
  objectiveType,
  eventOnClickClose
}) {
  return (
    <Modal
      dataCyModal="modal-confirmation-clone"
      title={getObjectiveLocale(`Clone ${objectiveType}`)}
      className={'w-[400px] modal-clone-objective'}
      description={getObjectiveLocale(
        `${startCase(
          objectiveType
        )} due date will be adjusted automatically based on original ${objectiveType} period`
      )}
      withPrimaryBtn={{
        title: 'Clone',
        dataCy: 'clone-confirm',
        onClick: () => postClone(),
        isLoading: isCloning
      }}
      withSecondaryBtn={{
        title: 'Cancel',
        dataCy: 'cancel-confirm',
        onClick: () => handleChange(false)
      }}
      eventOnClickClose={eventOnClickClose}
    />
  );
}

export default ModalConfirmation;

import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getGoalsScoring } from 'client/ObjectivesClient';
import { useFormalReview } from 'context/FormalReviewContext.js';
import { useUser } from 'context/UserContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

import { getSelectedFormula } from 'components/admin/track-template/ConfigurationSettingTrackTemplate';
import LoadingComponent from 'components/shared/LoadingComponent';

import './GoalsScoringEach.scss';
import HeaderWorkScore from './HeaderWorkScore';
import ObjectScoring from './ObjectScoring';

const GoalsScoringEach = ({
  showOption,
  assignmentId,
  template,
  phase,
  currentTrack,
  components,
  answers,
  preFill,
  suggestions
}) => {
  const intersectTarget = createRef();
  const [{ goalsScoringData }, { getSingleTrack, getGoalsScoringData }] =
    useFormalReview();
  const {
    config: { defaultObjectiveSorting }
  } = useUser();
  const {
    trackType,
    trackConfig,
    template: { formula }
  } = getSingleTrack(currentTrack) || {};
  const params = useParams();
  const [objectives, setObjectives] = useState([]);
  const [olderThan, setOlderThan] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const { type } = phase;
  const { useComment, view, sliderLabel } = template;
  const currentAssingmentId = assignmentId || params.assignmentId;

  const getSingleObjective = async (additionalParams) => {
    setLoadMore(true);
    const params = {
      sortColumn: defaultObjectiveSorting?.sortColumn || 'due_date',
      sortDirection: defaultObjectiveSorting?.sortDirection || 'asc',
      limit: 10,
      ...additionalParams
    };
    const { data, pagination } = await getGoalsScoring(
      currentAssingmentId,
      params
    );
    if (data) {
      setOlderThan(pagination?.next?.olderThan);
      setObjectives(additionalParams ? [...objectives, ...data] : data);
    }
    setLoadMore(false);
  };

  const sortedOptionsBasedOnScore = components.options.sort(
    (a, b) => a.score - b.score
  );
  const minimumScore = sortedOptionsBasedOnScore[0].score;
  const maximumScore =
    sortedOptionsBasedOnScore[sortedOptionsBasedOnScore.length - 1].score;

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting &&
      !loadMore &&
      olderThan &&
      getSingleObjective({ olderThan }),
    threshold: 0.5
  });

  useEffect(() => {
    getGoalsScoringData(currentAssingmentId);
    getSingleObjective();
  }, []);

  return (
    <div className="business-target-annual-review-container">
      <HeaderWorkScore
        totalNilai={goalsScoringData?.personal?.score || 0}
        trackType={trackType}
        scoreWithBorder={false}
        scoreWithBackground={true}
        formula={getSelectedFormula(formula)}
      />
      <div className="mt-[40px]">
        {objectives &&
          objectives.map((objective, idx) => {
            const isLast =
              idx == objectives.length - 3 && objectives.length > 9;
            let singleObjective = [objective];
            return (
              <div ref={isLast ? intersectTarget : undefined} key={idx}>
                <ObjectScoring
                  showOption={showOption}
                  id={objective.id}
                  title={objective.name}
                  key={objective.id}
                  idx={idx}
                  components={components}
                  objective={singleObjective}
                  useComment={useComment}
                  view={view}
                  minimumScore={minimumScore}
                  maximumScore={maximumScore}
                  answers={answers}
                  sliderLabel={sliderLabel}
                  preFill={preFill}
                  suggestions={suggestions}
                  currentTrack={currentTrack}
                  type={type}
                  getSingleGoalsScoring={() =>
                    getGoalsScoringData(currentAssingmentId)
                  }
                  mechanism={template?.mechanism}
                  phaseType={type}
                  suggestionSource={trackConfig?.suggestionSource}
                  withLabelInfo={view !== 'raw'}
                />
              </div>
            );
          })}
      </div>
      {loadMore && <LoadingComponent />}
    </div>
  );
};

export default GoalsScoringEach;

import React from 'react';

import { SelectedUserProvider } from 'context/useSelectedUser/useSelectedUser';
import { useLocale } from 'hooks/useLocale';

import SelectMemberComponent from 'components/SelectMemberComponents/SelectMemberComponent';

function StepSetMembers({
  members,
  setMembers,
  isCluster,
  customTableClass = '',
  isReadOnly
}) {
  const { setBothLocale } = useLocale();

  const handleSetMember = ({ selectAll, include, exclude }) => {
    setMembers({
      includes: include,
      excludes: exclude,
      select_all: selectAll
    });
  };

  return (
    <div className="pr-[24px]">
      <p className="typography-h500 mb-[16px]">
        {setBothLocale('Select Members')}
      </p>
      <SelectedUserProvider
        initialValue={{
          selectAll: members?.select_all,
          include: members?.includes?.map((e) => ({ id: e })),
          exclude: members?.excludes?.map((e) => ({ id: e }))
        }}
      >
        <SelectMemberComponent
          handleSet={(e) => handleSetMember(e)}
          isCluster={isCluster}
          isReadOnly={isReadOnly}
          withJobPlacement
          customClass="pr-[24px]"
          customTableClass={customTableClass}
          metadataType="totalAllPlacements"
        />
      </SelectedUserProvider>
    </div>
  );
}

export default StepSetMembers;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import dayjs from 'dayjs';

import { getPulseSurvey as getSurvey } from 'client/adminClient';
import { useLocale } from 'hooks/useLocale';
import { loadMoreValidator } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import BannerBox from 'components/shared/BannerBox';
import Loading from 'components/shared/LoadingComponent';
import Switcher from 'src/components/design-system/Switcher';

import PulseSurveyCard from './PulseSurveyCard';
import PulseSurveyEmptyState from './PulseSurveyEmptyState';

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const PulseSurveyList = ({ route: { title } }) => {
  const { setBothLocale } = useLocale();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');
  const [surveys, setSurveys] = useState([]);
  const [currentTab, setCurrentTab] = useState(tabParams || 'current');
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const tabList = [
    { id: 'current', name: 'Active' },
    { id: 'past', name: 'Past' }
  ];

  const headerProps = {
    titlePage: title
  };

  const getSurveyData = async (additionalParams) => {
    const params = {
      ...additionalParams,
      state: currentTab,
      limit: 10
    };
    const { data, pagination } = await getSurvey(params);
    if (data && pagination.currentSize > 0) {
      setSurveys((prev) => (additionalParams ? [...prev, ...data] : data));
      setOffset(pagination.nextOffset);
    } else {
      setOffset(0);
    }
    setIsLoading(false);
  };

  const _onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      setOnLoadMore(true);
      await getSurveyData({ offset });
      setOnLoadMore(false);
    };

    if (!onLoadMore && offset > 0) {
      loadMoreValidator(target, 100, () => {
        loadMore();
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setSurveys([]);
    setOffset(0);
    getSurveyData();
    history.replace(history.location.pathname + '?tab=' + currentTab);
  }, [currentTab]);

  return (
    <>
      <HeaderPage {...headerProps} />
      <div
        className="bg-n-000 h-[calc(100vh-65px)] overflow-y-auto"
        onScroll={(e) => _onScroll(e)}
      >
        <Switcher
          options={tabList}
          selectedOption={currentTab}
          onChange={(tab) => setCurrentTab(tab)}
          customClass="pr-[16px] mt-[24px]"
          textCustomClass="typography-button"
        />
        {!isLoading && surveys.length > 1 && currentTab === 'upcoming' && (
          <BannerBox type="info" customClass="mt-[16px]">
            {setBothLocale(
              'You can still edit your pulse survey details until Pulse Survey Start'
            )}
          </BannerBox>
        )}
        {isLoading ? (
          <div className="mt-[116px]">
            <Loading />
          </div>
        ) : surveys.length < 1 ? (
          <PulseSurveyEmptyState currentTab={currentTab} isPulseSurvey={true} />
        ) : (
          <PulseSurveyCard surveyData={surveys} currentTab={currentTab} />
        )}
      </div>
    </>
  );
};

export default PulseSurveyList;

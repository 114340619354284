import React from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { getObjectiveLocale } from 'utils/HelperUtils';
import {
  checkAllHandler,
  isAllCheckHandler,
  isSelected,
  selectMembers
} from 'utils/ObjectiveOverviewHelper';

import TwoLine from 'components/design-system/ProfileType';
import FooterLimit from 'components/design-system/footer/FooterLimit';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import Table from 'components/design-system/table/Table';
import Checkbox from 'components/shared/Checkbox';
import ListEmptyState from 'components/shared/ListEmptyState';
import TooltipContainer from 'src/components/shared/Tooltips/TooltipContainer';

import ModalSelectedMembers from './ModalSelectedMembers';

const areEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

// eslint-disable-next-line react/display-name
const TableUser = React.memo(
  ({
    listUsers,
    setShowModalMembers,
    selectedUsers,
    setSelectedUsers,
    showModalMembers,
    isLoading,
    currentPage,
    hasMore,
    getListUsers,
    switchTab,
    appendListUsers,
    setAppendListUsers,
    setCurrentLimit,
    currentLimit,
    sortDirection,
    setSortDirection
  }) => {
    const history = useHistory();
    const isCheckboxShow = () => {
      return ['not_owned', 'remind', 'missed_update'].includes(switchTab);
    };

    const getHeaderName = () => {
      switch (switchTab) {
        case 'owned':
          return ['goal ownership'];
        case 'not_owned':
          return ['last remind'];
        case 'all':
          return ['goals approved', 'pending approve'];
        case 'remind':
          return ['pending approve', 'last remind'];
        case 'updated_regularly':
          return ['last updated'];
        case 'missed_update':
          return ['last updated', 'last remind'];
      }
    };
    let headers = [
      {
        name: 'name',
        widthClass: 'w-[400px]',
        grow: true,
        sort: {
          default: sortDirection,
          onSort: (direction) => {
            setSortDirection(direction);
          }
        }
      },
      { name: 'job title', widthClass: 'w-[160px]', grow: true },
      { name: 'department', widthClass: 'w-[160px]', grow: true },
      { name: 'division', widthClass: 'w-[160px]', grow: true },
      { name: getHeaderName()?.[0], widthClass: 'w-[160px]', grow: true }
    ];

    if (isCheckboxShow()) {
      headers.unshift({ name: 'checkbox', widthClass: 'w-[56px]' });
    }

    getHeaderName()?.[1] &&
      headers.push({
        name: getHeaderName()?.[1],
        widthClass: 'w-[160px]'
      });

    const getInfoUser = (user) => {
      switch (switchTab) {
        case 'owned':
          return [user?.goalCount];
        case 'not_owned':
          return [
            user?.objectiveReminders?.lastRemind
              ? dayjs(user?.objectiveReminders?.lastRemind).format(
                  'DD MMM YYYY'
                )
              : '-'
          ];
        case 'all':
          return [user?.approvedCount, user?.pendingCount];
        case 'remind':
          return [
            user?.pendingCount,
            user?.objectiveReminders?.lastRemind
              ? dayjs(user?.objectiveReminders?.lastRemind).format(
                  'DD MMM YYYY'
                )
              : '-'
          ];
        case 'updated_regularly':
          return [
            user?.objectiveReminders?.lastUpdate
              ? dayjs(user?.objectiveReminders?.lastUpdate).format(
                  'DD MMM YYYY'
                )
              : '-'
          ];
        case 'missed_update':
          return [
            user?.objectiveReminders?.lastUpdate
              ? dayjs(user?.objectiveReminders?.lastUpdate).format(
                  'DD MMM YYYY'
                )
              : '-',
            user?.objectiveReminders?.lastRemind
              ? dayjs(user?.objectiveReminders?.lastRemind).format(
                  'DD MMM YYYY'
                )
              : '-'
          ];
      }
    };

    return (
      <>
        {listUsers && listUsers.length == 0 ? (
          <ListEmptyState
            title={getObjectiveLocale('No Data Matches Your Filter')}
            subtitle={getObjectiveLocale(
              'Try limiting your search and try again.'
            )}
          />
        ) : (
          <>
            <Table
              minFixed={true}
              heightClass="h-[32px]"
              headers={headers}
              useGrow={false}
              headerCheckbox={{
                checked: isAllCheckHandler(listUsers, selectedUsers),
                onClick: () =>
                  checkAllHandler(listUsers, selectedUsers, setSelectedUsers)
              }}
            >
              {isLoading &&
                [...new Array(10)].map((_, index) => {
                  return (
                    <Table.Row key={index} useGrow={false}>
                      {headers.map((_, index) => {
                        return (
                          <Table.Column key={index}>
                            <Shimmer
                              height="20px"
                              widthRandomness={0.25}
                              circle
                            />
                          </Table.Column>
                        );
                      })}
                    </Table.Row>
                  );
                })}
              {!isLoading &&
                listUsers?.map((user, index) => {
                  const infoAdditional = getInfoUser(user);
                  return (
                    <Table.Row
                      key={index}
                      useGrow={false}
                      dataCy={`user-${user?.id}`}
                      customClass={`${
                        isSelected(user, selectedUsers) ? 'bg-base-30024' : ''
                      }`}
                    >
                      {isCheckboxShow() && (
                        <Table.Column heightClass="h-[32px]" grow={false}>
                          <Checkbox
                            dataCy="checkbox-user"
                            id={`select-user-${user?.id}`}
                            checked={isSelected(user, selectedUsers)}
                            onChange={(e) => {
                              e.stopPropagation();
                              selectMembers(
                                e,
                                user,
                                selectedUsers,
                                setSelectedUsers
                              );
                            }}
                          />
                        </Table.Column>
                      )}
                      <Table.Column key={0} heightClass="h-[32px]" grow={false}>
                        <TwoLine
                          customClass="items-center"
                          user={user}
                          title={user.name}
                          subtitle={user.email}
                          onClick={(e) => {
                            e.stopPropagation();
                            history.replace(
                              `/users/${user?.id}/placement/${user?.placementId}`
                            );
                          }}
                          size={32}
                        />
                      </Table.Column>
                      <Table.Column key={1} heightClass="h-[32px]" grow={false}>
                        <TooltipContainer
                          classContainer="truncate w-full"
                          show={!!user?.jobTitle}
                          text={user?.jobTitle}
                          useMaxWidth={false}
                        >
                          {user?.jobTitle || '-'}
                        </TooltipContainer>
                      </Table.Column>
                      <Table.Column key={2} heightClass="h-[32px]" grow={false}>
                        <TooltipContainer
                          classContainer="truncate w-full"
                          show={!!user?.department}
                          text={user?.department}
                          useMaxWidth={false}
                        >
                          {user?.department || '-'}
                        </TooltipContainer>
                      </Table.Column>
                      <Table.Column key={3} heightClass="h-[32px]" grow={false}>
                        <TooltipContainer
                          classContainer="truncate w-full"
                          show={!!user?.division}
                          text={user?.division}
                          useMaxWidth={false}
                        >
                          {user?.division || '-'}
                        </TooltipContainer>
                      </Table.Column>
                      <Table.Column key={4} heightClass="h-[32px]" grow={false}>
                        <TooltipContainer
                          classContainer="truncate w-full"
                          show={!!infoAdditional?.[0]}
                          text={infoAdditional?.[0]}
                          useMaxWidth={false}
                        >
                          {infoAdditional?.[0] || '-'}
                        </TooltipContainer>
                      </Table.Column>
                      {infoAdditional?.[1] !== undefined && (
                        <Table.Column
                          key={5}
                          heightClass="h-[32px]"
                          grow={false}
                        >
                          <TooltipContainer
                            classContainer="truncate w-full"
                            show={!!infoAdditional?.[1]}
                            text={infoAdditional?.[1]}
                            useMaxWidth={false}
                          >
                            {infoAdditional?.[1] || '-'}
                          </TooltipContainer>
                        </Table.Column>
                      )}
                    </Table.Row>
                  );
                })}
            </Table>
            <FooterLimit
              changePage={(type) => getListUsers(type)}
              currentPage={currentPage}
              setCurrentLimit={setCurrentLimit}
              currentLimit={currentLimit}
              listLimit={[20, 50, 100]}
              hasMore={hasMore}
              withFirstLastPage={false}
              useDescription={false}
            />
            {showModalMembers && (
              <ModalSelectedMembers
                hasMore={hasMore}
                handleCloseModal={setShowModalMembers}
                selectedMembers={selectedUsers}
                setSelectedMembers={setSelectedUsers}
                getListUsers={getListUsers}
                isSelectAll={selectedUsers?.selectAll}
                handleChange={selectMembers}
                appendListUsers={appendListUsers}
                setAppendListUsers={setAppendListUsers}
              />
            )}
          </>
        )}
      </>
    );
  },
  areEqual
);

export default TableUser;

import React, { useEffect, useState } from 'react';

import S3Client from 'client/S3Client';
import {
  getDefaultBadge,
  getPreSignUrlValues,
  updateDefaultBadge
} from 'client/adminClient.js';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import InputField from 'components/shared/InputField';

const DefaultBadge = ({ setShowNotif, setNotifMessage }) => {
  const { config } = useUser();
  const [ready, setReady] = useState(false);
  const [defaultBadge, setDefaultBadge] = useState({});
  const debouncedSelectedValues = useDebounce(defaultBadge, 500);
  const isReadOnly =
    config?.permissions?.cultureSettingsBadgeSee === 'read_only';

  const _getPreSignUrl = async () => {
    const query = {
      fileType: 'media-resources',
      fileFormat: 'png'
    };
    const { data, error } = await getPreSignUrlValues(query);
    return data;
  };

  const handleUploadBadge = async (e) => {
    let file = e.target.files[0];

    if (file) {
      if (
        file.type == 'image/png' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/jpg'
      ) {
        let presignData = await _getPreSignUrl();
        if (presignData) {
          const { status } = await S3Client(presignData.presignedUrl, file);

          if (status == 200) {
            setDefaultBadge({
              ...defaultBadge,
              badgeIconUrl: presignData?.secureUrl
            });
          }
        } else {
          alert('Presign Link Not Found!');
        }
      } else {
        alert('Wrong file type! file type must be png, jpg or jpeg!');
        return false;
      }
    } else {
      alert('Empty file image');
    }
  };

  const updateDefaultofBadge = async () => {
    const { data } = await updateDefaultBadge(defaultBadge);
    if (data) {
      setShowNotif(true);
      setNotifMessage('Default badge successfully saved');
      setTimeout(() => {
        setShowNotif(false);
      }, 3000);
    }
  };

  const getDefaultofBagde = async () => {
    const { data } = await getDefaultBadge();
    if (data) {
      setDefaultBadge(data);
    }
  };

  useEffect(() => {
    ready && defaultBadge?.title && updateDefaultofBadge();
  }, [debouncedSelectedValues]);

  useEffect(() => {
    const getData = async () => {
      await getDefaultofBagde();
      setTimeout(() => {
        setReady(true);
      }, 2000);
    };
    getData();
  }, []);

  return (
    <div className="default-badge my-[24px] border-n400 px-[24px] py-[24px] rounded-[4px] w-[70%]">
      <p className="typography-h500">{getObjectiveLocale('Default Badge')}</p>
      <p className="typography-paragraph text-n-800">
        {getObjectiveLocale('Set badge for users ranked outside the top list.')}
      </p>
      <div className="mt-[30px] flex w-full">
        <div className="badge-name flex flex-col mr-[24px] w-9/12">
          <p className="typography-h100 text-n-800 mb-[8px]">
            {getObjectiveLocale('Badge Name')}
          </p>
          <InputField
            dataCy="name-default-badge"
            placeholder="Type here..."
            value={defaultBadge?.title}
            handleChange={(e) =>
              setDefaultBadge({ ...defaultBadge, title: e.target.value })
            }
          />
        </div>
        <div className="badge-name flex flex-col">
          <p className="typography-h100 text-n-800 mb-[8px]">
            {getObjectiveLocale('Icon')}
          </p>
          <div className="flex">
            {defaultBadge?.badgeIconUrl && (
              <AWSImage
                className="w-[36px] h-[36px] mr-[8px]"
                src={defaultBadge?.badgeIconUrl}
              />
            )}
            {!isReadOnly && (
              <>
                <label
                  className="btn text-btn"
                  type="label"
                  htmlFor="attachment-input"
                >
                  {defaultBadge?.badgeIconUrl ? 'Edit Icon' : 'Upload Icon'}
                </label>
                <input
                  className="input-file hide-input"
                  id="attachment-input"
                  data-cy="attachment-input"
                  type="file"
                  onChange={(e) => handleUploadBadge(e)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultBadge;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import './NameDescriptionReviewGroup.scss';

const NameDescriptionReviewGroup = ({
  inputGroupName,
  setInputGroupName,
  inputGroupDesc,
  setInputGroupDesc
}) => {
  return (
    <div className="name-description-container pt-[24px] w-[60%]">
      <div className="mb-[40px]">
        <p className="mb-[8px] typography-h500">Give it a name</p>
        <p className="mb-[16px] typography-secondary">
          Something clear and easily to be recognized.
        </p>

        <label className="mb-[8px] typography-secondary typography-h100">
          Group Name
        </label>
        <input
          type="text"
          autoFocus
          className="input-field input-group-name"
          data-cy="input-group-name"
          onChange={(e) => setInputGroupName(e.target.value)}
          value={inputGroupName || ''}
        />
      </div>
      <div className="mb-[40px]">
        <p className="mb-[8px] typography-h500">Tell something about this</p>
        <p className="mb-[16px] typography-secondary">
          Describe the purpose of creating this group.
        </p>

        <label className="mb-[8px] typography-secondary typography-h100">
          Description ({getObjectiveLocale('optional')})
        </label>
        <textarea
          type="text"
          className="input-field input-group-desc"
          data-cy="input-group-desc"
          onChange={(e) => setInputGroupDesc(e.target.value)}
          value={inputGroupDesc || ''}
        />
      </div>
    </div>
  );
};

export default NameDescriptionReviewGroup;

import React from 'react';

import classSetter from 'classnames';
import PropTypes from 'prop-types';

import AWSImage from 'components/design-system/AWSImage';
import Avatar from 'components/shared/Avatar';

function UserCardBar({
  className,
  fontAwesome,
  name,
  avatarSource,
  buttonSource,
  eventOnClick,
  eventOnClickCard,
  noButton,
  cardData,
  email,
  textButton
}) {
  const userClass = {
    'user-card': true
  };

  function userCardDefault() {
    return (
      <div
        className={classSetter(userClass, className)}
        onClick={eventOnClickCard}
        role="presentation"
        data-card={cardData}
      >
        <span className="user-card__avatar">
          <Avatar src={avatarSource} name={name} size={36} textSizeRatio={1} />
        </span>
        <span className="user-card__name">{name}</span>
        {noButton && (
          <span
            className="user-card__button"
            onClick={eventOnClick}
            role="presentation"
          >
            <AWSImage src={buttonSource} alt="button" />
          </span>
        )}
        {fontAwesome && <i className={`fa fa-${fontAwesome}`} />}
      </div>
    );
  }

  function userCardWithEmail() {
    return (
      <div
        className={classSetter(userClass, className)}
        onClick={eventOnClickCard}
        role="presentation"
        data-card={cardData}
      >
        <span className="user-card__avatar">
          <Avatar src={avatarSource} name={name} size={36} textSizeRatio={1} />
        </span>
        <div className="user-card__info">
          <span className="user-card__name">{name}</span>
          <span className="user-card__email">{email}</span>
        </div>
        {textButton && (
          <button className="user-card__button" onClick={eventOnClick}>
            {textButton}
          </button>
        )}
      </div>
    );
  }

  let DOM = userCardDefault();

  if (email) {
    DOM = userCardWithEmail();
  }

  return DOM;
}

UserCardBar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  avatarSource: PropTypes.string.isRequired,
  buttonSource: PropTypes.string,
  eventOnClick: PropTypes.func,
  eventOnClickCard: PropTypes.func,
  noButton: PropTypes.bool,
  cardData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  fontAwesome: PropTypes.string,
  email: PropTypes.string,
  textButton: PropTypes.string
};

UserCardBar.defaultProps = {
  className: '',
  buttonSource: '',
  fontAwesome: '',
  email: '',
  textButton: '',
  eventOnClick: () => {},
  eventOnClickCard: () => {}
};

export default UserCardBar;

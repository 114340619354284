import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import {
  getPerformanceReportOverviewIndividual,
  getPerformanceReportOverviewTeam
} from 'client/PerformanceReport';
import { useProfileFilter } from 'context/ProfileFilterContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ListEmptyState from 'components/shared/ListEmptyState';

import OverviewBehaviourSummaryReportComponent from '../OverviewBehaviourSummaryReportComponent';
import OverviewPerformanceReportSummaryComponent from '../OverviewPerformanceReportSummaryComponent';
import OverviewPerformanceSourceComponent from '../OverviewPerformanceSourceComponent';
import OverviewTeamIndividualPerformanceComponent from '../OverviewTeamIndividualPerformanceComponent';

function OverviewTab(props) {
  const [teamHighchartData, setTeamHighchartData] = useState([]);
  const [roleHighchartData, setRoleHighchartData] = useState([]);
  const [annualTeamData, setAnnualTeamData] = useState(null);
  const [annualRoleData, setAnnualRoleData] = useState(null);
  const [dataAverage, setDataAverage] = useState([]);
  const [dataAverageRole, setDataAverageRole] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const userId = props.userId;
  const { year } = useProfileFilter();

  const getAnnualTeamData = async () => {
    let data = [];
    let roleData = [];
    let tempDataAverage = [];
    let tempDataAverageRole = [];

    const queryQ1 = {
      period_begin: year + '-01-01T00:00:00.000Z',
      period_end_before: year + '-04-01T00:00:00.000Z'
    };
    const queryQ2 = {
      period_begin: year + '-04-01T00:00:00.000Z',
      period_end_before: year + '-07-01T00:00:00.000Z'
    };
    const queryQ3 = {
      period_begin: year + '-07-01T00:00:00.000Z',
      period_end_before: year + '-10-01T00:00:00.000Z'
    };
    const queryQ4 = {
      period_begin: year + '-10-01T00:00:00.000Z',
      period_end_before: parseInt(year) + 1 + '-01-01T00:00:00.000Z'
    };
    const queryYear = {
      period_begin: year + '-01-01T00:00:00.000Z',
      period_end_before: parseInt(year) + 1 + '-01-01T00:00:00.000Z'
    };
    const { data: dataTeamQ1, error: errorTeamQ1 } =
      await getPerformanceReportOverviewTeam(queryQ1, userId);
    const { data: dataTeamQ2, error: errorTeamQ2 } =
      await getPerformanceReportOverviewTeam(queryQ2, userId);
    const { data: dataTeamQ3, error: errorTeamQ3 } =
      await getPerformanceReportOverviewTeam(queryQ3, userId);
    const { data: dataTeamQ4, error: errorTeamQ4 } =
      await getPerformanceReportOverviewTeam(queryQ4, userId);
    const { data: annualTeamData, error: errorTeamYear } =
      await getPerformanceReportOverviewTeam(queryYear, userId);
    const { data: dataIndividualQ1, error: errorIndividualQ1 } =
      await getPerformanceReportOverviewIndividual(queryQ1, userId);
    const { data: dataIndividualQ2, error: errorIndividualQ2 } =
      await getPerformanceReportOverviewIndividual(queryQ2, userId);
    const { data: dataIndividualQ3, error: errorIndividualQ3 } =
      await getPerformanceReportOverviewIndividual(queryQ3, userId);
    const { data: dataIndividualQ4, error: errorIndividualQ4 } =
      await getPerformanceReportOverviewIndividual(queryQ4, userId);
    const { data: annualRoleData, error: errorIndividualYear } =
      await getPerformanceReportOverviewIndividual(queryYear, userId);

    if (dataTeamQ1) {
      dataTeamQ1.averageScore
        ? data.push(dataTeamQ1.averageScore.score)
        : data.push(null);
      tempDataAverage.push(dataTeamQ1);
    }
    if (dataTeamQ2) {
      dataTeamQ2.averageScore
        ? data.push(dataTeamQ2.averageScore.score)
        : data.push(null);
      tempDataAverage.push(dataTeamQ2);
    }
    if (dataTeamQ3) {
      dataTeamQ3.averageScore
        ? data.push(dataTeamQ3.averageScore.score)
        : data.push(null);
      tempDataAverage.push(dataTeamQ3);
    }
    if (dataTeamQ4) {
      dataTeamQ4.averageScore
        ? data.push(dataTeamQ4.averageScore.score)
        : data.push(null);
      tempDataAverage.push(dataTeamQ4);
    }

    if (dataIndividualQ1) {
      dataIndividualQ1.averageScore
        ? roleData.push(dataIndividualQ1.averageScore.score)
        : roleData.push(null);
      tempDataAverageRole.push(dataIndividualQ1);
    }
    if (dataIndividualQ2) {
      dataIndividualQ2.averageScore
        ? roleData.push(dataIndividualQ2.averageScore.score)
        : roleData.push(null);
      tempDataAverageRole.push(dataIndividualQ2);
    }
    if (dataIndividualQ3) {
      dataIndividualQ3.averageScore
        ? roleData.push(dataIndividualQ3.averageScore.score)
        : roleData.push(null);
      tempDataAverageRole.push(dataIndividualQ3);
    }
    if (dataIndividualQ4) {
      dataIndividualQ4.averageScore
        ? roleData.push(dataIndividualQ4.averageScore.score)
        : roleData.push(null);
      tempDataAverageRole.push(dataIndividualQ4);
    }

    setTeamHighchartData(data);
    setRoleHighchartData(roleData);
    setDataAverage(tempDataAverage);
    setDataAverageRole(tempDataAverageRole);
    setAnnualTeamData(annualTeamData);
    setAnnualRoleData(annualRoleData);
  };

  let isEmptyData =
    teamHighchartData.length !== 4 &&
    roleHighchartData.length !== 4 &&
    dataAverage.length !== 4 &&
    dataAverageRole.length !== 4 &&
    !userInfo;

  useEffect(() => {
    getAnnualTeamData();
  }, [year]);

  return (
    <div>
      {teamHighchartData.length === 4 && roleHighchartData.length === 4 && (
        <OverviewPerformanceReportSummaryComponent
          teamHighchartData={teamHighchartData}
          roleHighchartData={roleHighchartData}
        />
      )}

      {teamHighchartData.length === 4 && roleHighchartData.length === 4 && (
        <OverviewTeamIndividualPerformanceComponent
          teamHighchartData={teamHighchartData}
          roleHighchartData={roleHighchartData}
          annualTeamData={annualTeamData}
          annualRoleData={annualRoleData}
        />
      )}

      {dataAverage.length === 4 && dataAverageRole.length === 4 && userInfo && (
        <OverviewPerformanceSourceComponent
          year={year}
          dataAverageTeam={dataAverage}
          dataAverageRole={dataAverageRole}
          userInfo={userInfo.name}
          setDetailInfo={props.setDetailInfo}
          userId={userId}
        />
      )}

      {userInfo && (
        <OverviewBehaviourSummaryReportComponent
          userInfo={userInfo.name}
          year={year}
          userId={userId}
        />
      )}

      {isEmptyData && (
        <ListEmptyState
          emptyIcon="icon-no-profile-overview"
          containerClassname="w-full h-full"
          title={getObjectiveLocale('No result found')}
          subtitle={getObjectiveLocale(
            'Try to adjust the filter setting and try again.'
          )}
        />
      )}
    </div>
  );
}

export default withRouter(OverviewTab);

const pathQuadrantBottomRight = ({
  widthDistance,
  halfWidthColumn,
  finalHeight,
  leftPositionSourceElement,
  rightPositionDependencyElement
}) => {
  const arrowTransform = `translate(${18},${21})`;
  const pointStart = {
    x: widthDistance - halfWidthColumn,
    y: finalHeight - 24
  };
  const pointEnd = { x: halfWidthColumn, y: 24 };

  const point1 = {
    x: pointStart.x + halfWidthColumn / 2,
    y: pointStart.y - 12
  };
  const point1CurvedStart = { x: point1.x, y: pointStart.y };
  const point1CurvedEnd = { x: point1.x, y: point1.y };

  const point2 = { x: point1.x - halfWidthColumn / 2, y: point1.y - 12 };
  const point2CurvedStart = { x: point1.x, y: point2.y };
  const point2CurvedEnd = { x: point2.x, y: point2.y };

  const point3 = { x: 0, y: finalHeight - 60 };
  const point3CurvedStart = { x: point3.x, y: point3.y + 12 };
  const point3CurvedEnd = { x: point3.x, y: point3.y };

  const point4 = { x: point3.x, y: 36 };

  const point5 = { x: point4.x + halfWidthColumn / 2, y: point4.y - 12 };
  const point5CurvedStart = { x: point4.x, y: point5.y };
  const point5CurvedEnd = { x: point5.x, y: point5.y };

  const pointIntermission1 = {
    x:
      Math.abs(rightPositionDependencyElement - leftPositionSourceElement) -
      halfWidthColumn,
    y: finalHeight - 24
  };

  const point1Alt = {
    x: pointIntermission1.x + halfWidthColumn / 2,
    y: pointIntermission1.y - 12
  };
  const point1AltCurvedStart = { x: point1Alt.x, y: pointIntermission1.y };
  const point1AltCurvedEnd = { x: point1Alt.x, y: point1Alt.y };

  const point2Alt = {
    x: point1Alt.x - halfWidthColumn / 2,
    y: point1Alt.y - 12
  };
  const point2AltCurvedStart = { x: point1Alt.x, y: point2Alt.y };
  const point2AltCurvedEnd = { x: point2Alt.x, y: point2Alt.y };

  const pointIntermission2 = { x: halfWidthColumn / 2, y: finalHeight - 48 };

  const path = `
    M ${pointStart.x} ${pointStart.y}
    ${
      widthDistance <
      Math.abs(rightPositionDependencyElement - leftPositionSourceElement)
        ? `
    L ${pointIntermission1.x} ${pointIntermission1.y}
    C ${point1AltCurvedStart.x} ${point1AltCurvedStart.y},
      ${point1AltCurvedEnd.x} ${point1AltCurvedEnd.y},
      ${point1Alt.x} ${point1Alt.y}
    C ${point2AltCurvedStart.x} ${point2AltCurvedStart.y},
      ${point2AltCurvedEnd.x} ${point2AltCurvedEnd.y},
      ${point2Alt.x} ${point2Alt.y}
    `
        : `
    C ${point1CurvedStart.x} ${point1CurvedStart.y},
      ${point1CurvedEnd.x} ${point1CurvedEnd.y},
      ${point1.x} ${point1.y}
    C ${point2CurvedStart.x} ${point2CurvedStart.y},
      ${point2CurvedEnd.x} ${point2CurvedEnd.y},
      ${point2.x} ${point2.y}
    `
    }
    ${
      widthDistance <= 30
        ? ''
        : `L ${pointIntermission2.x} ${pointIntermission2.y}`
    }
    C ${point3CurvedStart.x} ${point3CurvedStart.y},
      ${point3CurvedStart.x} ${point3CurvedEnd.y},
      ${point3.x} ${point3.y}
    L ${point4.x} ${point4.y}
    C ${point5CurvedStart.x} ${point5CurvedStart.y},
      ${point5CurvedEnd.x} ${point5CurvedEnd.y},
      ${point5.x} ${point5.y}
    L ${pointEnd.x} ${pointEnd.y}
  `;
  return { arrow: arrowTransform, path };
};

const pathQuadrantTopRight = ({
  widthDistance,
  halfWidthColumn,
  finalHeight,
  leftPositionSourceElement,
  rightPositionDependencyElement
}) => {
  const arrowTransform = `translate(${24 - 6},${finalHeight - 24 - 3})`;
  const pointStart = { x: widthDistance - halfWidthColumn, y: 24 };
  const pointEnd = { x: halfWidthColumn, y: finalHeight - 24 };

  const point1 = {
    x: widthDistance - halfWidthColumn / 2,
    y: pointStart.y + 12
  };
  const point1CurvedStart = { x: point1.x, y: pointStart.y };
  const point1CurvedEnd = { x: point1.x, y: point1.y };

  const point2 = { x: point1.x - halfWidthColumn / 2, y: point1.y + 12 };
  const point2CurvedStart = { x: point1.x, y: point2.y };
  const point2CurvedEnd = { x: point2.x, y: point2.y };

  const pointIntermission2 = { x: halfWidthColumn / 2, y: point2.y };

  const point3 = { x: 0, y: 60 };
  const point3CurvedStart = { x: point3.x, y: point3.y - 12 };
  const point3CurvedEnd = { x: point3.x, y: point3.y };

  const point4 = { x: point3.x, y: pointEnd.y - 12 };

  const point5 = { x: point4.x + halfWidthColumn / 2, y: pointEnd.y };
  const point5CurvedStart = { x: point4.x, y: point5.y };
  const point5CurvedEnd = { x: point5.x, y: point5.y };

  const pointIntermission1 = {
    x:
      Math.abs(rightPositionDependencyElement - leftPositionSourceElement) -
      halfWidthColumn,
    y: 24
  };

  const point2Alt = {
    x: pointIntermission1.x + halfWidthColumn / 2,
    y: pointIntermission1.y + 12
  };
  const point2AltCurvedStart = { x: point2Alt.x, y: pointIntermission1.y };
  const point2AltCurvedEnd = { x: point2Alt.x, y: point2Alt.y };

  const point3Alt = {
    x: point2Alt.x - halfWidthColumn / 2,
    y: point2Alt.y + 12
  };
  const point3AltCurvedStart = { x: point2Alt.x, y: point3Alt.y };
  const point3AltCurvedEnd = { x: point3Alt.x, y: point3Alt.y };

  const path = `
    M ${pointStart.x} ${pointStart.y}
    ${
      widthDistance <
      Math.abs(rightPositionDependencyElement - leftPositionSourceElement)
        ? `
      L ${pointIntermission1.x} ${pointIntermission1.y}
      C ${point2AltCurvedStart.x} ${point2AltCurvedStart.y},
        ${point2AltCurvedEnd.x} ${point2AltCurvedEnd.y},
        ${point2Alt.x} ${point2Alt.y}
      C ${point3AltCurvedStart.x} ${point3AltCurvedStart.y},
        ${point3AltCurvedEnd.x} ${point3AltCurvedEnd.y},
        ${point3Alt.x} ${point3Alt.y}
      `
        : `
      C ${point1CurvedStart.x} ${point1CurvedStart.y},
        ${point1CurvedEnd.x} ${point1CurvedEnd.y},
        ${point1.x} ${point1.y}
      C ${point2CurvedStart.x} ${point2CurvedStart.y},
        ${point2CurvedEnd.x} ${point2CurvedEnd.y},
        ${point2.x} ${point2.y}
      `
    }
    ${
      leftPositionSourceElement > rightPositionDependencyElement &&
      leftPositionSourceElement - rightPositionDependencyElement <= 230
        ? ''
        : `L ${pointIntermission2.x} ${pointIntermission2.y}`
    }
    C ${point3CurvedStart.x} ${point3CurvedStart.y},
      ${point3CurvedEnd.x} ${point3CurvedEnd.y},
      ${point3.x} ${point3.y}
    L ${point4.x} ${point4.y}
    C ${point5CurvedStart.x} ${point5CurvedStart.y},
      ${point5CurvedEnd.x} ${point5CurvedEnd.y},
      ${point5.x} ${point5.y}
    L ${pointEnd.x} ${pointEnd.y}
  `;
  return { arrow: arrowTransform, path };
};

const pathQuadrantBottomLeft = ({
  widthDistance,
  halfWidthColumn,
  finalHeight
}) => {
  const arrowTransform = `translate(${widthDistance - 6},${24 - 3})`;
  const pointStart = { x: 0, y: finalHeight - 24 };
  const pointEnd = { x: widthDistance, y: 24 };
  const point1 = {
    x: pointStart.x + halfWidthColumn / 2,
    y: pointStart.y - 12
  };
  const point1CurvedStart = { x: point1.x, y: pointStart.y };
  const point1CurvedEnd = { x: point1.x, y: point1.y };
  const point2 = { x: point1.x, y: pointEnd.y + 12 };
  const point3 = { x: point2.x * 2, y: pointEnd.y };
  const point3CurvedStart = { x: point2.x, y: point3.y };
  const point3CurvedEnd = { x: point3.x, y: point3.y };
  const path = `
    M ${pointStart.x} ${pointStart.y}
    C ${point1CurvedStart.x} ${point1CurvedStart.y},
      ${point1CurvedEnd.x} ${point1CurvedEnd.y},
      ${point1.x} ${point1.y}
    L ${point2.x} ${point2.y}
    C ${point3CurvedStart.x} ${point3CurvedStart.y},
      ${point3CurvedEnd.x} ${point3CurvedEnd.y},
      ${point3.x} ${point3.y}
    L ${pointEnd.x} ${pointEnd.y}
  `;
  return { arrow: arrowTransform, path };
};

const pathQuadrantTopLeft = ({
  widthDistance,
  halfWidthColumn,
  finalHeight
}) => {
  const arrowTransform = `translate(${widthDistance - 6},${finalHeight -
    24 -
    3})`;
  const pointStart = { x: 0, y: 24 };
  const pointEnd = { x: widthDistance, y: finalHeight - 24 };
  const point1 = {
    x: pointStart.x + halfWidthColumn / 2,
    y: pointStart.y + 12
  };
  const point1CurvedStart = { x: point1.x, y: pointStart.y };
  const point1CurvedEnd = { x: point1.x, y: point1.y };
  const point2 = { x: point1.x, y: pointEnd.y - 12 };
  const point3 = { x: point2.x * 2, y: pointEnd.y };
  const point3CurvedStart = { x: point2.x, y: point3.y };
  const point3CurvedEnd = { x: point3.x, y: point3.y };
  const path = `
    M ${pointStart.x} ${pointStart.y}
    C ${point1CurvedStart.x} ${point1CurvedStart.y},
      ${point1CurvedEnd.x} ${point1CurvedEnd.y},
      ${point1.x} ${point1.y}
    L ${point2.x} ${point2.y}
    C ${point3CurvedStart.x} ${point3CurvedStart.y},
      ${point3CurvedEnd.x} ${point3CurvedEnd.y},
      ${point3.x} ${point3.y}
    L ${pointEnd.x} ${pointEnd.y}
  `;
  return { arrow: arrowTransform, path };
};

const pathQuadrantRight = ({
  widthDistance,
  halfWidthColumn,
  finalHeight,
  leftPositionSourceElement,
  rightPositionDependencyElement,
  isBelow
}) => {
  if (isBelow) {
    return pathQuadrantBottomRight({
      widthDistance,
      halfWidthColumn,
      finalHeight,
      leftPositionSourceElement,
      rightPositionDependencyElement
    });
  }
  return pathQuadrantTopRight({
    widthDistance,
    halfWidthColumn,
    finalHeight,
    leftPositionSourceElement,
    rightPositionDependencyElement
  });
};

const pathQuadrantLeft = ({
  widthDistance,
  halfWidthColumn,
  finalHeight,
  isBelow
}) => {
  if (isBelow) {
    return pathQuadrantBottomLeft({
      widthDistance,
      halfWidthColumn,
      finalHeight
    });
  }
  return pathQuadrantTopLeft({
    widthDistance,
    halfWidthColumn,
    finalHeight
  });
};

const rearrangeDependencyLine = ({
  currentDependencyElement,
  finalHeight,
  finalLeft,
  widthDistance,
  dPath,
  arrowTransform,
  finalTop
}) => {
  currentDependencyElement.style.top = finalTop + 'px';
  currentDependencyElement.style.height = finalHeight + 'px';
  currentDependencyElement.style.left = finalLeft + 'px';
  currentDependencyElement.style.width = widthDistance + 'px';
  currentDependencyElement.children[0].setAttribute(
    'height',
    finalHeight + 'px'
  );
  currentDependencyElement.children[0].children[0].setAttribute('d', dPath);
  currentDependencyElement.children[0].children[1].setAttribute(
    'transform',
    arrowTransform
  );
};

const initializeDependencyLine = ({
  arrowTransform,
  finalHeight,
  finalLeft,
  finalTop,
  widthDistance,
  dependantTask,
  task,
  tmContent,
  dPath
}) => {
  let g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  let path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  let pathElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );
  path.setAttribute('d', 'M 0 0 L 6 3 L 0 6 z');
  path.style.fill = 'var(--n-600)';
  g.appendChild(path);
  g.setAttribute('transform', arrowTransform);
  const newElement = document.createElement('div');
  newElement.style.position = 'absolute';
  newElement.style.height = finalHeight + 'px';
  newElement.style.width = widthDistance + 'px';
  newElement.style.zIndex = 1;
  newElement.style.left = finalLeft + 'px';
  newElement.style.top = finalTop + 'px';
  newElement.setAttribute('id', `dep-${task.id}-${dependantTask.id}`);
  const svgContainer = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg'
  );
  pathElement.setAttribute('d', dPath);
  pathElement.setAttribute('stroke', 'var(--n-600)');
  pathElement.setAttribute('fill', 'none');
  svgContainer.appendChild(pathElement);
  svgContainer.appendChild(g);
  svgContainer.setAttribute('width', widthDistance + 'px');
  svgContainer.setAttribute('height', finalHeight + 'px');
  svgContainer.style.overflow = 'visible';
  newElement.appendChild(svgContainer);

  tmContent.insertBefore(newElement, tmContent.children[1]);
};

const topOffsetHandler = (element, topDistance, isBelow, offsetTopParent) => {
  if (isBelow)
    return element.parentElement.parentElement.offsetTop + offsetTopParent;
  return (
    element.parentElement.parentElement.offsetTop +
    offsetTopParent -
    topDistance
  );
};

const generateArrowPath = ({
  halfWidthColumn,
  finalHeight,
  isBelow,
  rightPositionDependencyElement,
  leftPositionSourceElement,
  width
}) => {
  if (leftPositionSourceElement - rightPositionDependencyElement < 36) {
    const widthDistance =
      rightPositionDependencyElement - leftPositionSourceElement + width;
    const { arrow, path } = pathQuadrantRight({
      widthDistance,
      halfWidthColumn,
      finalHeight,
      leftPositionSourceElement,
      rightPositionDependencyElement,
      isBelow
    });
    return {
      arrow,
      path,
      finalLeft: leftPositionSourceElement - halfWidthColumn,
      widthDistance
    };
  }
  const widthDistance = leftPositionSourceElement;

  const { arrow, path } = pathQuadrantLeft({
    widthDistance: leftPositionSourceElement - rightPositionDependencyElement,
    halfWidthColumn,
    finalHeight,
    isBelow
  });
  return {
    arrow,
    path,
    finalLeft: rightPositionDependencyElement,
    widthDistance
  };
};

const generateDependencyLine = ({
  currentElement,
  currentDependencyElement,
  targetEl,
  positionLeft,
  dependantTask,
  task
}) => {
  const topDistance = Math.abs(
    targetEl.getBoundingClientRect().top -
      currentElement.getBoundingClientRect().top
  );
  const isBelow =
    targetEl.getBoundingClientRect().top >
    currentElement.getBoundingClientRect().top;

  const tmContent = document.getElementsByClassName('tm-content')[0];
  let par = null;

  const seekElement = element => {
    if (element.classList.contains('side-group')) {
      par = element;
      return;
    }
    seekElement(element.parentElement);
  };

  seekElement(currentElement.parentElement.parentElement);

  let offsetTopParent = 0;

  let seekTopOffsetParent = element => {
    if (element.classList.contains('side-group')) {
      offsetTopParent = offsetTopParent + element.offsetTop;
    }
    if (element.classList.contains('ground-group')) {
      return;
    }
    seekTopOffsetParent(element.parentElement);
  };

  seekTopOffsetParent(currentElement.parentElement.parentElement);

  const rightPositionDependencyElement =
    targetEl.offsetLeft + targetEl.getBoundingClientRect().width;
  const leftPositionSourceElement = positionLeft;
  const width = 48;
  const finalHeight = 48 + topDistance;

  const finalTop = topOffsetHandler(
    currentElement,
    topDistance,
    isBelow,
    offsetTopParent
  );

  const halfWidthColumn = width / 2;

  const {
    arrow: arrowTransform,
    path: dPath,
    finalLeft,
    widthDistance
  } = generateArrowPath({
    halfWidthColumn,
    finalHeight,
    isBelow,
    rightPositionDependencyElement,
    leftPositionSourceElement,
    width
  });

  if (currentDependencyElement) {
    rearrangeDependencyLine({
      currentDependencyElement,
      finalHeight,
      finalLeft,
      widthDistance,
      dPath,
      arrowTransform,
      finalTop
    });
    return;
  }
  initializeDependencyLine({
    arrowTransform,
    finalHeight,
    finalLeft,
    finalTop,
    widthDistance,
    dependantTask,
    task,
    tmContent,
    dPath
  });
};

const lineDependencyCreation = (task, positionLeft) => {
  task?.blockedBy?.map(dependantTask => {
    const targetEl = document.getElementById('bar' + dependantTask.id);
    const currentElement = document.getElementById(`bar` + task.id);
    const currentDependencyElement = document.getElementById(
      `dep-${task.id}-${dependantTask.id}`
    );

    if (currentElement && targetEl) {
      generateDependencyLine({
        currentElement,
        currentDependencyElement,
        targetEl,
        positionLeft,
        dependantTask,
        task
      });
      return;
    }

    if (currentDependencyElement) {
      currentDependencyElement.remove();
    }
  });
};

export default lineDependencyCreation;

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  getCustomAttributeList,
  getOneReviewGroup,
  postListReviewGroup,
  putListReviewGroup
} from 'client/admin/ReviewGroupClient.js';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Button from 'components/design-system/Button';
import Modal from 'components/shared/modal/Modal';

import VerticalStepsComponent from '../../../shared/VerticalStepsComponent';
import GroupTableUser from '../GroupTableUser';
import NameDescriptionReviewGroup from '../NameDescriptionReviewGroup';
import VerifyView from '../VerifyView';
import Configuration from './Configuration';
import './CreateEditGroupPerformanceProfile.scss';
import PerformanceProfileWeightConfig from './PerformanceProfileWeightConfig';

const CreateEditGroupPerformanceProfile = () => {
  const { type, id } = useParams();

  // Tab
  const [activeStep, setActiveStep] = useState('Name & Description');
  const [steps, setSteps] = useState([
    { disabled: false, completed: false, name: 'Name & Description' },
    { disabled: true, completed: false, name: 'Members' },
    { disabled: true, completed: false, name: 'Configuration' },
    { disabled: true, completed: false, name: 'Verify' }
  ]);
  const [isSubmitAble, setIsSubmitAble] = useState(false);

  // Modal
  const [modalSuccess, setModalSuccess] = useState(false);

  // Table
  const [isSelectAllRow, setIsSelectAllRow] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [paginationProps, setPaginationProps] = useState({});
  const [listIdUser, setListIdUser] = useState([]);
  const [excludedId, setExcludedId] = useState([]);
  const [sortStatus, setSortStatus] = useState('asc');
  const [totalUser, setTotalUser] = useState(0);

  // Name & Description
  const [inputGroupName, setInputGroupName] = useState('');
  const [inputGroupDesc, setInputGroupDesc] = useState('');

  // Configuration
  const [reviewAspectList, setReviewAspectList] = useState([]);
  const [inputFinalScore, setInputFinalScore] = useState(null);
  const [finalDescription, setFinalDescription] = useState(
    'Aspect score times weight divided by total weight'
  );
  const weightSetting = getObjectiveLocale('Weight Setting');
  const finalScoreFormula = getObjectiveLocale('Final Score Formula');

  const history = useHistory();

  const getSpecificEditData = async (id) => {
    const { data } = await getOneReviewGroup(id);
    if (data) {
      // const arrayOfIds = data?.users?.map(user => user.id)
      setListIdUser(data?.memberIds);
      setInputGroupName(data?.name);
      setInputGroupDesc(data?.description);
      const reviewAspectData = data?.reviewAspectsAttributes?.map((dt) => ({
        id: dt.reviewAspectParentId,
        weight: dt.weight
      }));
      setReviewAspectList(reviewAspectData);
    }
  };

  const getFinalScore = async () => {
    const params = {
      group_type: 'review_group'
    };
    const groupId = type == 'edit' ? id : 0;
    const { data } = await getOneReviewGroup(groupId, params);
    if (data) {
      setInputFinalScore(data?.finalScoreFormula);
    }
  };

  const currentIndex = steps.findIndex((step) => step.name === activeStep);

  const handleNextButton = () => {
    const nextIndex = currentIndex + 1;
    setActiveStep(steps[nextIndex].name);
  };

  const handleBackButton = () => {
    const previousIndex = currentIndex - 1;
    setActiveStep(steps[previousIndex].name);
  };

  let params = {
    state: 'active'
  };

  const hasMoreHandler = (recordCount) => {
    setHasMore(recordCount % 10 == 0);
  };

  const appendReviewAspect = (data) => {
    let list = [];
    let toAppend = [];
    data.forEach((object) => {
      const isExist = reviewAspectList.find(
        (aspectList) => aspectList.id == object.id
      );
      if (!isExist) {
        toAppend.push({
          id: object.id,
          weight: object.weight || 0
        });
      }
    });
    list = [...reviewAspectList, ...toAppend];
    setReviewAspectList(list);
  };

  const getConfigAttribute = async () => {
    // id: 0 => default group
    let tmpStore = [];
    const { data, pagination } = await getCustomAttributeList(0, params);
    if (data) {
      tmpStore = [...data];
      if (tmpStore.length == 10 && pagination.next.olderThan) {
        params = {
          ...params,
          olderThan: pagination.next.olderThan
        };
        const { data: additionalData, pagination: additionalPage } =
          await getCustomAttributeList(0, params);
        if (
          additionalData &&
          additionalData.length != 0 &&
          additionalPage.next.olderThan
        ) {
          tmpStore = [...tmpStore, ...additionalData];
          hasMoreHandler(tmpStore.length);
        } else {
          setHasMore(false);
        }
        setPaginationProps(additionalPage);
      }
      setDataList(tmpStore);
      appendReviewAspect(tmpStore);
    }
  };

  const handleEditReviewAspects = (id, weight) => {
    let newData = reviewAspectList.slice();
    const objIndex = reviewAspectList.findIndex((data) => data.id == id);
    newData[objIndex].weight = weight;
    setReviewAspectList(newData);
  };

  const handleSubmit = async () => {
    let submittedData = {
      name: inputGroupName,
      description: inputGroupDesc,
      group_type: 'review_group',
      includes: listIdUser,
      select_all: isSelectAll,
      goal_weight: 0,
      task_weight: 0,
      value_weight: 0,
      competency_weight: 0,
      review_aspects_attributes: reviewAspectList,
      final_score_formula: inputFinalScore
    };

    const { data } = await (type == 'edit'
      ? putListReviewGroup(id, submittedData)
      : postListReviewGroup(submittedData));

    if (data) {
      setModalSuccess(true);
    }
  };

  const updateCompletedStep = (stepName, boolean) => {
    // 1. Make a shallow copy of the items
    let tempSteps = [...steps];
    let intendedIndex = tempSteps.findIndex((step) => step.name === stepName);
    // 2. Make a shallow copy of the item you want to mutate
    let edittedStep = { ...tempSteps[intendedIndex] };
    // 3. Replace the property you're intested in
    edittedStep.completed = boolean;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    tempSteps[intendedIndex] = edittedStep;
    // 5. Set the state to our new copy
    setSteps(tempSteps);
  };

  const _putFinalScoreFormula = async (inputFinalScore) => {
    setFinalDescription(
      inputFinalScore == 'divided_by_total_weight'
        ? 'Aspect score times weight divided by total weight'
        : 'Aspect score times weight'
    );
  };

  const handleDisabledAndCompletedStep = ({
    stepName,
    disabledBoolean,
    completedBoolean
  }) => {
    let changeStepDisabledValue = steps.map((step) => {
      return { ...step, disabled: disabledBoolean };
    });
    let intendedIndex = changeStepDisabledValue.findIndex(
      (step) => step.name === stepName
    );
    let edittedStep = { ...changeStepDisabledValue[intendedIndex] };
    edittedStep.completed = completedBoolean;
    changeStepDisabledValue[intendedIndex] = edittedStep;
    setSteps(changeStepDisabledValue);
  };

  // Check if all Steps are COMPLETED or not
  useEffect(() => {
    const findFalse = steps.find((step) => step.completed === false);
    if (!findFalse) {
      setIsSubmitAble(true);
    }

    return () => {
      setIsSubmitAble(false);
    };
  }, [steps]);

  // Check is Name & Description COMPLETED
  useEffect(() => {
    if (inputGroupName) {
      handleDisabledAndCompletedStep({
        stepName: 'Name & Description',
        disabledBoolean: false,
        completedBoolean: true
      });
    } else {
      handleDisabledAndCompletedStep({
        stepName: 'Name & Description',
        disabledBoolean: true,
        completedBoolean: false
      });
    }
    _putFinalScoreFormula(inputFinalScore);
  }, [inputGroupName, inputFinalScore]);

  // Check is Members COMPLETED
  useEffect(() => {
    updateCompletedStep('Members', listIdUser?.length > 0);
  }, [listIdUser]);

  // Check is Configuration COMPLETED
  useEffect(() => {
    if (reviewAspectList.length !== 0) {
      const total = parseFloat(
        reviewAspectList.reduce((a, b) => ({ weight: a.weight + b.weight }))
          .weight
      );
      updateCompletedStep('Configuration', total > 0);
    }
  }, [reviewAspectList]);

  // Check is Verify COMPLETED
  useEffect(() => {
    const areAllStepsCompleted =
      steps[0].completed && steps[1].completed && steps[2].completed;
    updateCompletedStep('Verify', areAllStepsCompleted);
    if (activeStep == 'Configuration') {
      getConfigAttribute();
      !inputFinalScore && getFinalScore();
    }
  }, [activeStep]);

  // Check if it's edit page
  useEffect(() => {
    if (type == 'edit') {
      getSpecificEditData(id);
    }
  }, []);

  const _onScroll = (e) => {
    const target = e.target;

    if (activeStep == 'Configuration') {
      const loadMore = async () => {
        let prevData = dataList.slice();
        params = {
          ...params,
          olderThan: paginationProps.next.olderThan
        };

        setOnLoadMore(true);
        const { data, pagination } = await getCustomAttributeList(0, params);
        setPaginationProps(pagination);
        if (data && data.length != 0) {
          prevData = [...prevData, ...data];
          setDataList(prevData);
          appendReviewAspect(data);
        }
        setHasMore(data.length == 10 && pagination.next.olderThan);
        setOnLoadMore(false);
      };

      if (!onLoadMore && hasMore) {
        loadMoreValidator(target, 100, () => {
          loadMore();
        });
      }
    }
  };
  return (
    <>
      <HeaderPage
        titlePage={`${
          type == 'edit' ? 'Edit' : 'Create'
        } Performance Profile Group`}
        backToUrl={'/group/performance-profile?tab=groups'}
        isHeaderComposer={true}
      />
      <div
        className="create-group-performance-profile-container bg-n-000 h-[calc(100%-137px)] overflow-auto"
        onScroll={(e) => _onScroll(e)}
      >
        {/* Body */}
        <div className="cgp-body-container -ml-[40px] min-h-[calc(100vh-64px)]">
          {/* Vertical Steps Component */}
          <VerticalStepsComponent
            activeStep={activeStep}
            setActiveStep={(name) => setActiveStep(name)}
            steps={steps}
          />

          <div className="cgp-body-content">
            {activeStep === 'Name & Description' && (
              <NameDescriptionReviewGroup
                inputGroupName={inputGroupName}
                inputGroupDesc={inputGroupDesc}
                setInputGroupName={setInputGroupName}
                setInputGroupDesc={setInputGroupDesc}
              />
            )}
            {activeStep === 'Members' && (
              <>
                <h4 className="typography-h500 mb-[24px]">Select members</h4>
                <GroupTableUser
                  groupType={'review_group'}
                  isSelectAll={isSelectAll}
                  setIsSelectAll={setIsSelectAll}
                  isSelectAllRow={isSelectAllRow}
                  setIsSelectAllRow={setIsSelectAllRow}
                  listIdUser={listIdUser}
                  excludedId={excludedId}
                  setExcludedId={setExcludedId}
                  setListIdUser={setListIdUser}
                  sortStatus={sortStatus}
                  setSortStatus={setSortStatus}
                  setTotalUser={setTotalUser}
                  isReviewGroup={true}
                />
              </>
            )}

            {activeStep === 'Configuration' && (
              <Configuration
                handleEditReviewAspects={handleEditReviewAspects}
                inputFinalScore={inputFinalScore}
                setInputFinalScore={setInputFinalScore}
                _putFinalScoreFormula={_putFinalScoreFormula}
                dataList={dataList}
                reviewAspectList={reviewAspectList}
                finalScoreFormula={finalScoreFormula}
                weightSetting={weightSetting}
              />
            )}

            {activeStep === 'Verify' && (
              <VerifyView
                inputGroupName={inputGroupName}
                inputGroupDesc={inputGroupDesc}
                isSelectAll={isSelectAll}
                listIdUser={listIdUser}
                excludedId={excludedId}
                totalUser={totalUser}
              >
                <h4 className="typography-h500">
                  Performance Profile Configuration
                </h4>
                <div className="flex mb-[24px]">
                  <p className="w-[216px] typography-paragraph typography-secondary">
                    {finalScoreFormula}
                  </p>
                  <p className="typography-paragraph">{finalDescription}</p>
                </div>
                <div className="flex mb-[24px]">
                  <p className="w-[216px] typography-paragraph typography-secondary">
                    {weightSetting}
                  </p>
                  <PerformanceProfileWeightConfig
                    isInputAble={false}
                    dataList={dataList}
                    reviewAspectList={reviewAspectList}
                  />
                </div>
              </VerifyView>
            )}
          </div>

          <div className="cgp-fixed-footer">
            <div className="flex float-right">
              <Button.Tertiary
                disabled={currentIndex == 0}
                onClick={() => handleBackButton()}
              >
                Back
              </Button.Tertiary>
              {currentIndex == steps.length - 1 ? (
                <Button
                  disabled={!isSubmitAble}
                  onClick={() => handleSubmit()}
                  datacy="submit-button"
                >
                  Submit
                </Button>
              ) : (
                <Button
                  onClick={
                    steps[currentIndex + 1].disabled
                      ? () => null
                      : () => handleNextButton()
                  }
                  disabled={steps[currentIndex + 1].disabled}
                  datacy="next-button"
                >
                  Next
                </Button>
              )}
            </div>
          </div>

          {modalSuccess && (
            <Modal
              title="Config Saved"
              eventOnClickClose={() =>
                history.replace('/group/performance-profile?tab=groups')
              }
              headerIcon={{
                name: 'icon-check_circle',
                color: 'var(--g-600)'
              }}
            >
              <p>Your configuration have been saved</p>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateEditGroupPerformanceProfile;

import React from 'react';

import dayjs from 'dayjs';

import { getObjectiveLocale } from 'utils/HelperUtils';
import { getClusters } from 'utils/ObjectivesHelper';

import AWSImage from 'components/design-system/AWSImage';
import Avatar from 'components/shared/Avatar';
import SVGIcon from 'components/shared/SVGIcon';
import Card from 'components/shared/card/Card';

import Star from './Star';

function ReviewSummaryComponent({
  options,
  useWorkAttribute,
  listOfSelected,
  title,
  selectedRecognition,
  selectedFeedback,
  recognitionComment,
  feedbackComment,
  onChangeNavigation,
  ownerName,
  objective,
  useCustomIcon
}) {
  const sortedOptions = options.sort((a, b) => (a.score > b.score ? 1 : -1));
  let temp = listOfSelected.filter((data) => data.scoringMarkId);
  const clusters = getClusters(objective);

  return (
    <div className="review-container review-content-container">
      <div className="container wrapper">
        <div className="user-detail">
          <div className="flex">
            <span className="review-for">
              {getObjectiveLocale('Review for')}:{' '}
            </span>
            <div className="flex user-name">
              <Avatar
                src={ownerName[0].profilePicture}
                name={ownerName[0].name}
                className="mr-[8px]"
                size={24}
              />
              <span>{ownerName[0].name}</span>
            </div>
          </div>
          <div className="date-detail text-n-800 self-center">
            {dayjs(new Date()).format('DD MMMM YYYY')}
          </div>
        </div>
        {temp.map((value, index) => {
          const selectedIndex = sortedOptions.findIndex(
            (option) =>
              option.id == value.scoringMarkId || option.name == value.name
          );
          const selectedOptionColor =
            options?.[selectedIndex]?.colorHex || '323232';

          const resultStyle = {
            color: '#' + selectedOptionColor
          };
          return (
            <>
              <div className="container" key={index}>
                <span className="label label-title  flex justify-between mr-[8px]">
                  {useWorkAttribute ? value.name : title}
                  <SVGIcon
                    size="16"
                    iconName="icon-create"
                    fillColor="var(--base-600)"
                    onClick={() =>
                      onChangeNavigation(useWorkAttribute ? value : 'review')
                    }
                  />
                </span>
                <div
                  className="star-container"
                  onClick={() =>
                    onChangeNavigation(useWorkAttribute ? value : 'review')
                  }
                >
                  {!useCustomIcon && (
                    <span
                      className="label review-results mb-[8px]"
                      style={resultStyle}
                    >
                      {options[selectedIndex]?.name || ''}
                    </span>
                  )}
                  <div className="list-of-star">
                    {!useCustomIcon ? (
                      sortedOptions.map((option, index) => {
                        return (
                          <Star
                            key={index}
                            selected={index <= selectedIndex}
                            isSelected={index == selectedIndex}
                            text={option.name}
                            color={option.colorHex}
                          />
                        );
                      })
                    ) : (
                      <AWSImage src={options[selectedIndex].iconUrl} />
                    )}
                  </div>
                </div>
                {value.comment && value.comment != '' && (
                  <div>
                    <span className="label questions">
                      {getObjectiveLocale('Comment (optional)')}:
                    </span>
                    <span
                      className="label review-comment"
                      dangerouslySetInnerHTML={{ __html: value.comment }}
                    />
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
      {selectedRecognition && (
        <div className="container">
          <span className="label label-title flex justify-between mr-[8px]">
            {'Recognition'}
            <SVGIcon
              size="16"
              iconName="icon-create"
              fillColor="var(--base-600)"
              onClick={() => onChangeNavigation('recognition')}
            />
          </span>
          <div
            className="private-label-container"
            onClick={() => onChangeNavigation('recognition')}
          >
            <SVGIcon iconName="icon-strength" size={32} />
            <span className="skill-name">{selectedRecognition?.title}</span>
          </div>
          {recognitionComment != '' && (
            <div>
              <span className="label questions typography-h100">
                {getObjectiveLocale('Comment (optional)')}:
              </span>
              <span
                className="label review-comment text-n-900"
                dangerouslySetInnerHTML={{ __html: recognitionComment }}
              />
            </div>
          )}
        </div>
      )}
      {selectedFeedback && (
        <div className="container">
          <span className="label label-title flex justify-between mr-[8px]">
            {getObjectiveLocale('Feedback')}
            <SVGIcon
              size="16"
              iconName="icon-create"
              fillColor="var(--base-600)"
              onClick={() => onChangeNavigation('feedback')}
            />
          </span>
          <div className="private-label-container">
            <SVGIcon iconName="icon-improvement" size={32} />
            <div>
              <span className="label private-label">
                {getObjectiveLocale('This improvement is private')}
              </span>
              <span
                className="skill-name"
                onClick={() => onChangeNavigation('feedback')}
              >
                {selectedFeedback?.title}
              </span>
            </div>
          </div>
          {feedbackComment != '' && (
            <div>
              <span className="label questions typography-h100">
                {getObjectiveLocale('Comment (optional)')}:
              </span>
              <span
                className="label review-comment"
                dangerouslySetInnerHTML={{ __html: feedbackComment }}
              />
            </div>
          )}
        </div>
      )}
      <div className="container pb-[0px] no-border">
        <div className="objective-summary mt-[24px]">
          <span className="title">
            {getObjectiveLocale('Objective Summary')}
          </span>
          <Card
            objective={objective}
            withRightIcon
            type="goal"
            customClass="my-[16px] bg-n-200"
            clusters={clusters}
          />
        </div>
      </div>
    </div>
  );
}

export default ReviewSummaryComponent;

import React, { useState } from 'react';
import { useParams } from 'react-router';

import dayjs from 'dayjs';

import { putInclusions } from 'client/admin/FormalReviewRevieweeClient';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

export const ModalFinalScroreProportion = ({
  type,
  _handleActionModal,
  _handleNext,
  inclusions,
  refetchInclusions,
  periodCycle
}) => {
  const finalScoreList = inclusions?.inclusions?.map(
    (data) => data.proportional
  );
  const validFinalScore = finalScoreList.reduce((acc, cur) => acc + cur);
  const [finalScoreProportion, setFinalScoreProportion] = useState([
    ...finalScoreList
  ]);

  // for warning modal
  const warningFinalScore =
    finalScoreProportion.reduce((acc, curr) => acc + curr) !== validFinalScore;

  const isEveryScoreChanges = finalScoreList
    ?.map((item, index) => item == finalScoreProportion[index])
    .includes(false);

  const isTotalChanges =
    finalScoreList.reduce((acc, cur) => acc + cur) !=
    finalScoreProportion.reduce((acc, cur) => acc + cur);

  const isScoreChange = isEveryScoreChanges && !isTotalChanges;

  const { id: userId } = useParams();

  const dateFormatter = (date) => dayjs(date).format('D MMM YYYY');

  const updateHandler = async () => {
    const inclusionsData = inclusions?.inclusions?.map((inclusion, index) => ({
      id: inclusion?.id,
      proportional: finalScoreProportion[index],
      revieweePlacementId: inclusion?.revieweePlacement?.id
    }));

    const payload = {
      inclusions: inclusionsData
    };

    const { isSuccess } = await putInclusions(
      userId,
      inclusions?.id,
      periodCycle,
      payload
    );

    if (isSuccess) {
      _handleNext();
      refetchInclusions();
    }
  };

  return (
    <Modal
      title={'Edit Final Score Proportion'}
      minWidth="600"
      withPrimaryBtn={{
        title: 'Update Final Score Proportion',
        disabled: warningFinalScore || !isScoreChange,
        dataCy: `btn-update-proportion`,
        onClick: updateHandler
      }}
      withSecondaryBtn={{
        title: 'Cancel',
        onClick: () => _handleActionModal()
      }}
      eventOnClickClose={() => _handleActionModal()}
    >
      <p className="typography-h400 text-n-900 mb-[4px]">
        {getObjectiveLocale('Annual Review')}
      </p>
      {warningFinalScore && (
        <div
          className="flex flex-row rounded-[8px] bg-r-200 py-[8px] px-[18px] mb-[4px]"
          data-cy="warning-final-score"
        >
          <SVGIcon
            iconName="icon-warning"
            fillColor="var(--r-600)"
            size="24"
            customClass="mr-[16px]"
          />
          <p className="typography-paragraph text-n-900">
            {getObjectiveLocale(
              'Total final score proportion can’t be lower or over than'
            )}{' '}
            {validFinalScore}.
          </p>
        </div>
      )}
      {!warningFinalScore && isScoreChange && (
        <div className="flex flex-row rounded-[8px] bg-y-200 py-[8px] px-[18px] mb-[4px]">
          <SVGIcon
            iconName="icon-warning"
            fillColor="var(--y-500)"
            size="24"
            customClass="mr-[16px]"
          />
          <p className="typography-paragraph text-n-900">
            {getObjectiveLocale(
              "Are you sure you want to change this position's final score proportion? This might affect the user's overall score"
            )}
            .
          </p>
        </div>
      )}
      <div className="flex flex-row justify-between py-[10px] mb-[16px] border-b border-0 border-solid border-n-300">
        <p className="typography-h300 text-n-800">
          {getObjectiveLocale('Position')}
        </p>
        <p className="typography-h300 text-n-800">
          {getObjectiveLocale('Final Score Proportion')}
        </p>
      </div>
      {inclusions.inclusions.map((inclusion, index) => (
        <div
          className="flex flex-row justify-between mb-[28px]"
          key={inclusion?.id}
        >
          <div className="flex flex-col">
            <p className="typography-h400 text-n-900">
              {inclusion?.revieweePlacement?.name}
            </p>
            <p className="typography-h100 text-n-800">{`${dateFormatter(
              inclusion?.revieweePlacement?.startsAt
            )} - ${
              inclusion?.revieweePlacement?.endsAt
                ? dateFormatter(inclusion?.revieweePlacement?.endsAt)
                : 'Now'
            }`}</p>
          </div>
          <FinalScoreProportionItem
            finalScore={inclusion?.proportional}
            index={index}
            finalScoreProportion={finalScoreProportion}
            setFinalScoreProportion={setFinalScoreProportion}
            id={inclusion?.id}
          />
        </div>
      ))}
    </Modal>
  );
};

const FinalScoreProportionItem = ({
  finalScore,
  setFinalScoreProportion,
  index,
  finalScoreProportion,
  id
}) => {
  const [assignmentScore, setAssigmentScore] = useState(finalScore || 0);
  const handleInputValue = (e) => {
    e.preventDefault();
    const value = e.target.value;
    let finalScore = [...finalScoreProportion];
    finalScore[index] = parseInt(value);
    setAssigmentScore(value);
    setFinalScoreProportion(finalScore);
  };
  return (
    <InputField
      className="max-w-[88px]"
      value={assignmentScore}
      handleChange={handleInputValue}
      dataCy={`input-proportion-${id}`}
    />
  );
};

export default ModalFinalScroreProportion;

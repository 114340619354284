import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import {
  deleteMission,
  getMissionList,
  getShareToken
} from 'client/adminClient';
import useDebounce from 'hooks/useDebounce';
import {
  capitalize,
  getNumberLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';

import Table from 'components/design-system/table/Table';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SVGIcon from 'components/shared/SVGIcon';
import SearchBar from 'components/shared/SearchBar';
import ShareLink from 'components/shared/ShareLink/ShareLink';
import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';

function ListMission({ isReadOnly }) {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [nextDisable, setNextDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [missionData, setMissionData] = useState([]);
  const [incomingMissionData, setIncomingMissionData] = useState([]);
  const [search, setSearch] = useState(null);
  const debouncedSearchValue = useDebounce(search, 500);
  const [newerThanId, setNewerThanId] = useState(null);
  const [deleteModalData, setDeleteModalData] = useState({
    isShow: false,
    data: null
  });
  const [toastData, setIsToastShow] = useState({ message: '', isShow: false });

  const headers = [
    { name: 'MISSION NAME', widthClass: 'w-[320px]', grow: true },
    { name: 'RECIPIENTS', widthClass: 'w-[160px]' },
    { name: 'PERIOD', widthClass: 'w-[320px]' },
    { name: 'STATUS', widthClass: 'w-[104px]' },
    { name: '', widthClass: 'w-[50px]' },
    { name: '', widthClass: 'w-[50px]' },
    { name: '', widthClass: 'w-[50px]' }
  ];

  const getQuery = () => {
    let params = {
      limit: 10,
      q: search
    };
    return params;
  };

  const getListMissionPrev = async () => {
    let query = getQuery();
    setIsLoading(true);
    const params = {
      ...query,
      newerThan: newerThanId
    };
    const { data, pagination } = await getMissionList(params);
    setCurrentPage(currentPage - 1);

    if (data) {
      setMissionData(data);
      setIsLoading(false);
      setNewerThanId(pagination.prev.newerThan);

      if (data?.length == 10) {
        const incomingParams = {
          ...query,
          olderThan: pagination.next.olderThan
        };
        const incomingData = await getMissionList(incomingParams);
        const { data } = incomingData;
        if (data.length > 0) {
          setNextDisable(false);
          setIncomingMissionData(incomingData);
        } else {
          setNextDisable(true);
        }
      }
    }
  };

  const getListMissionNext = async () => {
    setCurrentPage(currentPage + 1);

    let query = getQuery();
    setIsLoading(true);
    const { data, pagination } = incomingMissionData;
    if (data) {
      setMissionData(data);
      setIsLoading(false);
      setNewerThanId(pagination.prev.newerThan);

      if (data?.length == 10) {
        const incomingParams = {
          ...query,
          olderThan: pagination.next.olderThan
        };
        const incomingData = await getMissionList(incomingParams);
        const { data } = incomingData;
        if (data.length > 0) {
          setNextDisable(false);
          setIncomingMissionData(incomingData);
        } else {
          setNextDisable(true);
        }
      }
    }
  };

  const getListMission = async (params) => {
    let query = params ? params : getQuery();
    setIsLoading(true);
    const { data, pagination } = await getMissionList(query);
    if (data) {
      setMissionData(data);
      setIsLoading(false);
      setNewerThanId(pagination.prev.newerThan);

      if (data?.length == 10) {
        const incomingParams = {
          ...query,
          olderThan: pagination.next.olderThan
        };
        const incomingData = await getMissionList(incomingParams);
        const { data } = incomingData;
        if (data.length > 0) {
          setNextDisable(false);
          setIncomingMissionData(incomingData);
        } else {
          setNextDisable(true);
        }
      }
    }
  };

  const onConfirmDelete = async (id) => {
    const { data } = await deleteMission(id);
    if (data) {
      getListMission({ limit: 10 });
      setDeleteModalData({ isShow: false, data: null });
    }
  };

  const onSuccessFunction = (successMessage = 'Link Copied') => {
    setIsToastShow({ isShow: true, message: successMessage });
    setTimeout(function () {
      setIsToastShow({ isShow: false, message: '' });
    }, 3000);
  };

  const getToken = async (missionId) => {
    return await getShareToken(missionId);
  };

  const ShareLinkTrigger = () => {
    return (
      <SVGIcon
        size="24"
        iconName="icon-share"
        dataCy="share-mission"
        fillColor="var(--n-600)"
        customClass="hidden group-hover:flex"
      />
    );
  };

  useEffect(() => {
    setIsLoading(true);
    getListMission();
    setIsLoading(false);
  }, [debouncedSearchValue]);

  return (
    <>
      <div className="admin-culture mission-list bg-n-000 max-h-[calc(100vh-64px)]">
        <ToastNotif
          showNotif={toastData?.isShow}
          message={toastData?.message}
        />
        <SearchBar
          fullWidth={true}
          containerClass="my-[24px]"
          placeholder="Search mission..."
          onChange={(e) => setSearch(e.target.value)}
        />
        <Table headers={headers}>
          {isLoading ? (
            <LoadingComponent />
          ) : missionData?.length === 0 ? (
            <ListEmptyState
              fullHeight
              containerClassname="my-[40px]"
              emptyIcon={search ? 'icon-no-result-found' : 'icon-no-mission'}
              title={getObjectiveLocale(
                search ? 'No result found' : 'There is no mission'
              )}
              subtitle={getObjectiveLocale(
                search
                  ? 'Did you probably type the keyword incorrectly? We are sorry that what you searched was unfortunately not found.'
                  : 'Encourage the employees to do something and collect points by creating some missions'
              )}
              btnText={'Create Mission'}
              onClick={() =>
                history.push({ pathname: '/missions/create-mission' })
              }
              iconBtn="icon-add"
            />
          ) : (
            missionData.map((mission) => (
              <Table.Row key={mission.id} dataCy={`mission-${mission.id}`}>
                <Table.Column>
                  <p className="typography-paragraph text-n-900">
                    {mission?.name}
                  </p>
                </Table.Column>
                <Table.Column>
                  <p className="typography-paragraph text-n-900">
                    {getNumberLocale(mission?.memberIds.length)}{' '}
                    {mission?.memberIds.length == 1
                      ? ' employee'
                      : ' employees'}
                  </p>
                </Table.Column>
                <Table.Column>
                  <p className="typography-paragraph text-n-900">
                    {dayjs(mission?.startsAt).format('DD MMMM YYYY')} -{' '}
                    {dayjs(mission?.endsAt).format('DD MMMM YYYY')}
                  </p>
                </Table.Column>
                <Table.Column>
                  <p className="typography-paragraph text-n-900">
                    {capitalize(mission?.status) || '-'}
                  </p>
                </Table.Column>
                <Table.Column>
                  <div className="w-full justify-between hidden group-hover:flex">
                    {mission?.status !== 'expired' && (
                      <SVGIcon
                        size="24"
                        iconName="icon-create"
                        dataCy="edit-mission"
                        fillColor="var(--n-600)"
                        onClick={() =>
                          history.push({
                            pathname: `/missions/edit/${mission.id}`
                          })
                        }
                      />
                    )}
                  </div>
                </Table.Column>
                <Table.Column customClass="relative">
                  {mission?.status === 'active' && (
                    <ShareLink
                      dataCy="share-mission"
                      getToken={() => getToken(mission?.id)}
                      pathName="share/missions"
                      onSuccessFunction={onSuccessFunction}
                      trigger={<ShareLinkTrigger />}
                    />
                  )}
                </Table.Column>
                <Table.Column>
                  <div className="w-full justify-between hidden group-hover:flex">
                    {mission.status == 'upcoming' && !isReadOnly && (
                      <SVGIcon
                        size="24"
                        iconName="icon-archive"
                        dataCy="delete-mission"
                        fillColor="var(--n-600)"
                        onClick={() =>
                          setDeleteModalData({
                            isShow: true,
                            data: mission
                          })
                        }
                      />
                    )}
                  </div>
                </Table.Column>
              </Table.Row>
            ))
          )}
        </Table>
        <div className="mt-[12px] pb-[40px] flex justify-end">
          <PaginationModule
            pageNumber={currentPage}
            handlePrev={() => getListMissionPrev()}
            handleNext={() => getListMissionNext()}
            hasMore={!nextDisable}
          />
        </div>
      </div>
      {deleteModalData?.isShow && (
        <Modal
          title={`Delete mission ${deleteModalData?.data?.name}`}
          withCloseIcon={false}
          className="w-[400px]"
          description="This mission will be deleted permanently.
          Are you sure want to delete this mission?"
          withPrimaryBtn={{
            danger: true,
            title: 'Delete',
            dataCy: 'delete-mission-btn',
            onClick: () => onConfirmDelete(deleteModalData?.data?.id)
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            dataCy: 'cancel-mission-label',
            onClick: () => setDeleteModalData({ isShow: false, data: null })
          }}
        />
      )}
    </>
  );
}

export default ListMission;

import React, { useEffect } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SectionName from 'components/admin/track-template/QuestionSections/SectionName';
import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import Toggle from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

import QuestionTemplate from './QuestionTemplate';

function AdditionalQuestion({
  templateData,
  setTemplateData,
  deleteOptionTemplate,
  addOptionTemplate,
  copyTemplate,
  deleteTemplate,
  addRemoveSection,
  currentTrackType,
  setData,
  handleAddQuestion,
  handleAddSection,
  setShowModal
}) {
  useEffect(() => {
    currentTrackType === 'general_questionnaire' &&
      (!Object.prototype.hasOwnProperty.call(templateData, 'sections') ||
        templateData?.sections?.length === 0) &&
      addRemoveSection();
  }, [addRemoveSection, currentTrackType, templateData]);

  return (
    <div
      className="create-track-template-question mt-[24px]"
      id="additional-questions"
    >
      <div
        className={`${
          currentTrackType != 'general_questionnaire' && 'wrapper-component'
        } mt-[0px]`}
      >
        {currentTrackType != 'general_questionnaire' && (
          <div
            className={`head-title ${
              templateData?.sections?.length > 0 ? '' : 'no-border'
            }`}
          >
            <div>
              <p className="typography-h500 typography-primary text-n-900 mb-[4px]">
                {getObjectiveLocale('Additional Questions')}
              </p>
              <p className="typography-h400 typography-paragraph typography-secondary">
                {getObjectiveLocale(
                  'Choose to add couple of questions to this track'
                )}
              </p>
            </div>
            <Toggle
              isChecked={templateData?.sections?.length > 0}
              idStr="toggle-additional-question"
              onChange={() =>
                !templateData?.sections?.length > 0
                  ? addRemoveSection()
                  : setShowModal('question')
              }
            />
          </div>
        )}
        {templateData?.sections?.length > 0 && (
          <div className="wrapper-switch">
            <div
              className={
                currentTrackType == 'general_questionnaire' ? '' : 'mt-[16px]'
              }
            >
              {templateData?.sections?.map((section, sectionIndex) => (
                <>
                  <SectionName
                    templateData={templateData}
                    setTemplateData={setTemplateData}
                    sectionName={section?.name}
                    sectionIndex={sectionIndex}
                    key={sectionIndex}
                  />
                  {currentTrackType != 'general_questionnaire' && (
                    <div className="mb-[16px]">
                      <p className="typography-h100 typography-secondary mb-[8px] mt-[16px]">
                        {getObjectiveLocale('Set question position')}
                      </p>
                      <div
                        className={`switch-question left w-[50%] ${
                          section?.position == 'after' ? 'active' : 'not-active'
                        }`}
                        data-cy="after"
                        onClick={() =>
                          setData('after', 'sections', 'position', sectionIndex)
                        }
                      >
                        <p className="typography-button">
                          {getObjectiveLocale('After Scoring')}
                        </p>
                      </div>
                      <div
                        className={`switch-question right w-[50%] ${
                          section?.position == 'before'
                            ? 'active'
                            : 'not-active'
                        }`}
                        data-cy="before"
                        onClick={() =>
                          setData(
                            'before',
                            'sections',
                            'position',
                            sectionIndex
                          )
                        }
                      >
                        <p className="typography-button">
                          {getObjectiveLocale('Before Scoring')}
                        </p>
                      </div>
                    </div>
                  )}
                  {templateData?.sections[sectionIndex]?.questions?.map(
                    (question, questionIndex) => (
                      <QuestionTemplate
                        key={questionIndex}
                        sectionIndex={sectionIndex}
                        questionIndex={questionIndex}
                        question={question.question}
                        questionType={question.questionType}
                        needComment={question.needComment}
                        options={question.options}
                        order={question.order}
                        setQuestion={(value, subPrefix, index, optionIndex) => {
                          setData(
                            value,
                            'questions',
                            subPrefix,
                            index,
                            optionIndex,
                            sectionIndex
                          );
                        }}
                        deleteOptionTemplate={(questionIndex, optionIndex) =>
                          deleteOptionTemplate(
                            sectionIndex,
                            questionIndex,
                            optionIndex
                          )
                        }
                        addOptionTemplate={(questionIndex) =>
                          addOptionTemplate(sectionIndex, questionIndex)
                        }
                        copyTemplate={(questionIndex) =>
                          copyTemplate(sectionIndex, questionIndex)
                        }
                        deleteTemplate={(questionIndex) =>
                          deleteTemplate(sectionIndex, questionIndex)
                        }
                        visibility={question.visibility}
                        totalQuestion={
                          templateData?.sections[sectionIndex]?.questions
                            ?.length
                        }
                        labelVisibility={question.optionsLabelVisibility}
                      />
                    )
                  )}
                  <div className="flex items-center gap-[8px]">
                    <Button.Secondary
                      customClass="grow"
                      onClick={() => handleAddQuestion(sectionIndex)}
                    >
                      <div className="flex items-center gap-[4px]">
                        <SVGIcon
                          iconName="icon-add"
                          size="24"
                          fillColor="var(--base-600)"
                        />
                        <p className="typography-button text-n-900">
                          {getObjectiveLocale('Add Question')}
                        </p>
                      </div>
                    </Button.Secondary>
                    <Button.Secondary
                      customClass="grow"
                      onClick={() => handleAddSection(sectionIndex)}
                    >
                      <div className="flex items-center gap-[4px]">
                        <SVGIcon
                          iconName="icon-add"
                          size="24"
                          fillColor="var(--base-600)"
                        />
                        <p className="typography-button text-n-900">
                          {getObjectiveLocale('Add Section')}
                        </p>
                      </div>
                    </Button.Secondary>
                  </div>
                </>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdditionalQuestion;

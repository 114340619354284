import React, { useCallback, useEffect, useRef, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import startCase from 'lodash/startCase';

import { getCompletionRateSurvey } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import {
  capitalize,
  getCultureLocale,
  getNumberLocale
} from 'utils/HelperUtils';

import Card from 'components/design-system/Card';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

const ChartSurvey = ({ surveyId, occurrenceId }) => {
  const chartRef = useRef();
  const setFilterById = useFilter((state) => state.setFilterById);
  const {
    config: { generalFilterOptions }
  } = useUser();
  const [groupBy, setGroupBy] = useState('none');
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const listOption =
    generalFilterOptions?.length > 0
      ? ['none', ...generalFilterOptions]
      : ['none'];

  const filterByChart = (category, status) => {
    let changedFilter = {};
    if (category?.toLowerCase() !== 'overall') {
      changedFilter[groupBy] = [category];
    }
    changedFilter.status = [status?.toLowerCase()];
    setFilterById('surveyAnalyticFilter', changedFilter);
  };

  const changeGroupBy = (group) => {
    setFilterById('surveyAnalyticFilter', {
      status: ['submitted', 'not_yet_submitted']
    });
    setGroupBy(group.replace('-', '_'));
  };

  const generateChartData = (data) => {
    const categories = data.map((data) =>
      data?.name == null ? 'N/A' : data?.name
    );
    const series = [];

    Object.keys(data[0])
      .reverse()
      .map((name) => {
        if (name !== 'name' && name !== 'percentage' && name !== 'total') {
          series.push({
            data: [],
            key: name,
            name: capitalize(
              name === 'notSubmitted' ? 'not yet submitted' : name
            ),
            color: name == 'submitted' ? '#22C55E' : '#CBD5E1'
          });
        }
      });

    series.map((seriesData) => {
      data.map((item) => {
        seriesData.data.push(item[seriesData.key]);
      });
    });

    return { categories, series };
  };

  const getChartData = useCallback(async () => {
    setIsLoading(true);
    let params = { groupBy: groupBy == 'none' ? null : groupBy };
    params.surveyOccurrenceId = occurrenceId;
    const { data, isSuccess } = await getCompletionRateSurvey(surveyId, params);
    if (isSuccess && data) {
      setChartData(generateChartData(data));
    }
    setIsLoading(false);
  }, [groupBy, surveyId, occurrenceId]);

  let config = {
    chart: {
      backgroundColor: 'var(--n-000)',
      type: 'column',
      marginTop: 50,
      height: '250px'
    },
    legend: {
      align: 'left',
      verticalAlign: 'top',
      floating: true,
      x: 0,
      y: 0,
      itemMarginBottom: 12,
      enabled: true,
      itemStyle: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16px',
        color: 'var(--n-800)'
      },
      reversed: true
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    xAxis: { categories: chartData.categories },
    yAxis: {
      title: {
        text: ''
      },
      min: 0
    },
    tooltip: {
      formatter: function () {
        const currentData = this;
        const pointData = currentData?.points;
        const firstData = pointData?.[0]?.point;
        const secondData = pointData?.[1]?.point;
        const titleTooltip = pointData?.[0]?.key || '--';

        return (
          `<div class="mx-[8px] my-[8px]" style="display:flex;flex-direction:column;">
        <div class="typography-h100 text-n-600 mb-[12px]"> ` +
          titleTooltip +
          `</div>
        <div style="display:flex;justify-content:space-between;align-items:center;">
          <p class="typography-h100 text-n-800 mr-[32px]">
            <span style="width:8px !important;height:8px !important;margin-right:8px;color:#94A3B8">\u25CF</span>` +
          getCultureLocale(firstData?.series?.name) +
          `</p>
          <p>
          <span class="typography-h200 text-n-900"> ` +
          getNumberLocale(firstData?.y) +
          `</span>
          <span class="typography-h100 text-n-600 ml-[8px]"> (` +
          parseFloat(firstData?.percentage.toFixed(2)) +
          `)%</span>
          </p>
        </div>
        <div style="display:flex;justify-content:space-between;align-items:center;">
          <p class="typography-h100 text-n-800 mr-32">
            <span style="width:8px !important;height:8px !important;margin-right:8px;color:#22C55E">\u25CF</span>` +
          getCultureLocale(secondData?.series?.name) +
          `</p>
          <p>
          <span class="typography-h200 text-n-900"> ` +
          getNumberLocale(secondData?.y) +
          `</span>
          <span class="typography-h100 text-n-600 ml-[8px]"> (` +
          parseFloat(secondData?.percentage.toFixed(2)) +
          `)%</span>
          </p>
        </div>
      </div>`
        );
      },
      backgroundColor: '#fff',
      borderWidth: 0,
      useHTML: true,
      shared: true,
      snap: 0
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      },
      series: {
        maxPointWidth: 140,
        stickyTracking: false,
        point: {
          events: {
            click: (e) =>
              filterByChart(
                e.point.category,
                e.point.series.name === 'Not yet submitted'
                  ? 'not_yet_submitted'
                  : 'submitted'
              )
          }
        },
        cursor: 'pointer'
      }
    },
    series: chartData.series
  };

  const FilterAnalytics = () => {
    return (
      <div className="flex items-center">
        <Dropdown customClass="mr-[16px]">
          <Dropdown.Trigger disabled={generalFilterOptions?.length < 1}>
            <div
              className="h-[36px] rounded-[4px] border border-solid border-n-400 bg-n-000 text-n-900 py-[8px] px-[16px] pr-[40px] mt-[4px] relative flex items-center w-full"
              data-cy="filter-group-survey"
            >
              <span className="typography-h400 mr-[4px]">
                {getCultureLocale('Group By')}:
              </span>{' '}
              {startCase(groupBy)}
              {generalFilterOptions?.length > 1 && (
                <SVGIcon
                  size="24"
                  iconName="icon-unfold_more"
                  fillColor="var(--n-700)"
                  customClass="absolute"
                  customStyle={{ top: '4px', right: '8px' }}
                />
              )}
            </div>
          </Dropdown.Trigger>
          <Dropdown.MenuItems customClass="w-full max-h-[200px] overflow-auto">
            {listOption.map((data, index) => (
              <Dropdown.MenuItem
                onClick={() => changeGroupBy(data)}
                dataCy={`filter-group-survey-${index}`}
                key={`filter-group-survey-${index}`}
              >
                {startCase(data.replace('-', ' '))}
              </Dropdown.MenuItem>
            ))}
          </Dropdown.MenuItems>
        </Dropdown>
      </div>
    );
  };

  useEffect(() => {
    getChartData();
  }, [getChartData]);

  return (
    <Card
      titleCard="Completion Rate"
      useHorizontalPadding={false}
      customClass="mx-[40px] mt-[22px] h-[320px]"
      rightComponent={<FilterAnalytics />}
    >
      <div className="w-full border-0 border-t border-solid border-n-400 px-[20px]">
        {isLoading ? (
          <LoadingComponent className="h-[250px] flex items-center" />
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={config}
            ref={chartRef}
          />
        )}
      </div>
    </Card>
  );
};

export default ChartSurvey;

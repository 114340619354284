import React, { useEffect, useState } from 'react';

import {
  getListReviewGroup,
  getOneReviewGroup
} from 'client/admin/ReviewGroupClient';
import { useCreateCycle } from 'context/admin/CreateCycleContext';
import debounce from 'utils/Debounce';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalShowMember from 'components/admin/groups/ModalShowMember';
import Table from 'components/design-system/table/Table';
import Checkbox from 'components/shared/Checkbox';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SearchBar from 'components/shared/SearchBar';
import TruncateParagraph from 'components/shared/TruncateParagraph';

const PerformanceProfileGroup = () => {
  const { cycleData, changeGroupIds } = useCreateCycle();

  const { groupIds } = cycleData;

  const [showModalMembers, setShowModalMembers] = useState(false);
  const [modalMemberLoading, setModalMemberLoading] = useState(false);
  const [listIdUser, setListIdUser] = useState([]);

  const [isSelectAllRowGroup, setIsSelectAllRowGroup] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [groupLoading, setGroupLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [debounceHandler, setDebounceHandler] = useState(null);

  const [paginationObject, setPaginationObject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const tableHeaders = [
    { name: 'checkbox', widthClass: 'w-[56px]', hide: false },
    { name: 'GROUP NAME', widthClass: 'w-[600px]', grow: true, hide: false },
    { name: 'MEMBER', widthClass: 'w-[204px]' },
    { name: '', widthClass: 'w-[204px]' }
  ];

  let params = {
    group_type: 'review_group',
    limit: 10,
    states: ['active'],
    sort_column: 'name',
    sort_direction: 'asc'
  };

  const getListGroup = async (nextOrPrev) => {
    if (searchValue !== '') {
      params.q = searchValue;
    }

    let localParams = { ...params };

    if (nextOrPrev == 'next') {
      localParams.olderThan = paginationObject?.next?.olderThan;
      setCurrentPage(currentPage + 1);
    }

    if (nextOrPrev == 'prev') {
      if (groupList.length == 0) {
        localParams.olderThan = paginationObject?.next?.olderThan;
      } else {
        localParams.newerThan = paginationObject?.prev?.newerThan;
      }
      setCurrentPage(currentPage - 1);
    }

    setGroupLoading(true);
    const { data, pagination } = await getListReviewGroup(localParams);
    if (data || pagination) {
      setGroupList(data);
      setPaginationObject(pagination || {});

      if (data.length == 10) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
    setGroupLoading(false);
  };
  const searchDebounce = async (value) => {
    const callback = async () => {
      setSearchValue(value);
    };

    const handler = await debounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  useEffect(() => {
    getListGroup();
  }, [searchValue]);

  const getDetailGroup = async (id) => {
    setShowModalMembers(true);
    setModalMemberLoading(true);

    const { data } = await getOneReviewGroup(id);

    if (data) {
      setListIdUser(data?.memberIds);
    }
    setModalMemberLoading(false);
  };

  const handleSelect = (groupData) => {
    let checked = groupIds.includes(groupData.id);

    checked ? handleUncheckGroup(groupData.id) : handleCheckGroup(groupData.id);
  };

  const handleCheckGroup = (groupId) => {
    let newIdList = [...groupIds, groupId];
    changeGroupIds(newIdList);
  };

  const handleUncheckGroup = (groupId) => {
    let tempGroupIds = [...groupIds];
    let filteredGroupIds = tempGroupIds.filter((id) => id !== groupId);
    changeGroupIds(filteredGroupIds);
  };

  const isChecked = (groupData) => {
    return groupIds.includes(groupData.id);
  };

  const handleAllFunction = () => {
    let groupIdsOnPage = groupList.map((group) => group.id);

    if (isSelectAllRowGroup) {
      let pullOutIdsOnPage = groupIds.filter((groupId) => {
        if (groupIdsOnPage.includes(groupId)) {
          return;
        } else {
          return groupId;
        }
      });
      changeGroupIds(pullOutIdsOnPage);

      setIsSelectAllRowGroup(false);
    } else {
      let newGroupArray = [...new Set([...groupIdsOnPage, ...groupIds])];
      changeGroupIds(newGroupArray);
      setIsSelectAllRowGroup(true);
    }
  };

  useEffect(() => {
    let groupIdsOnPage = groupList.map((group) => group.id);
    if (groupIdsOnPage.length != 0) {
      let isAllIdsSelected = groupIdsOnPage.every((pageId) =>
        groupIds.includes(pageId)
      );
      setIsSelectAllRowGroup(isAllIdsSelected);
    }
  }, [groupList, groupIds]);

  return (
    <div className="reviewee-profile-group mt-[24px]">
      <SearchBar
        fullWidth={true}
        placeholder="Search group"
        onChange={(e) => searchDebounce(e.target.value)}
      />
      <div className="mb-[24px]"></div>
      {groupLoading ? (
        <LoadingComponent />
      ) : groupList.length > 0 ? (
        <Table
          headers={tableHeaders}
          headerCheckbox={{
            checked: isSelectAllRowGroup,
            onClick: () => handleAllFunction(),
            disabled: false
          }}
          fixedLeftCount={2}
          fixedRightCount={1}
          withHideColumn
        >
          {groupList?.map((group) => {
            const { id, name, memberCount } = group;
            return (
              <Table.Row key={id}>
                <Table.Column key="0">
                  <Checkbox
                    id={`checkbox-${id}`}
                    value={`checkbox-${id}`}
                    dataCy={`checkbox-${id}`}
                    checked={isChecked(group)}
                    onChange={() => handleSelect(group)}
                  />
                </Table.Column>
                <Table.Column key="1">
                  <TruncateParagraph>{name}</TruncateParagraph>
                </Table.Column>
                <Table.Column key="2">
                  <TruncateParagraph>{memberCount}</TruncateParagraph>
                </Table.Column>
                <Table.Column key="3">
                  <div
                    className="flex items-center justify-center w-full h-full cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      getDetailGroup(id);
                    }}
                  >
                    <TruncateParagraph className="text-base-600">
                      {getObjectiveLocale('View All Members')}
                    </TruncateParagraph>
                  </div>
                </Table.Column>
              </Table.Row>
            );
          })}
        </Table>
      ) : (
        <ListEmptyState
          fullHeight
          containerClassname="my-[40px]"
          emptyIcon={searchValue ? 'icon-no-result-found' : 'icon-no-cycles'}
          title={getObjectiveLocale('No Result Found')}
          subtitle={getObjectiveLocale(
            searchValue
              ? 'Did you probably type the keyword incorrectly? Or perhaps try to adjust the filter setting and try again.'
              : ''
          )}
        />
      )}
      {showModalMembers && (
        <ModalShowMember
          setShowModalMembers={setShowModalMembers}
          loading={modalMemberLoading}
          listIdUser={listIdUser}
          withJobPlacement
        />
      )}
      <div className="flex justify-end items-center pb-[40px] pt-[24px]">
        <PaginationModule
          pageNumber={currentPage}
          handlePrev={() => getListGroup('prev')}
          handleNext={() => getListGroup('next')}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
};

export default PerformanceProfileGroup;

import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import cloneDeep from 'lodash/cloneDeep';

import { getListFilter } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import {
  UsersAdminProvider,
  useUsersAdmin
} from 'context/admin/UsersAdminContext';
import useFilter from 'hooks/useFilter';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import ModalShowMember from 'components/admin/groups/ModalShowMember';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import AWSImage from 'components/design-system/AWSImage';
import Avatar from 'components/design-system/Avatar';
import Button from 'components/design-system/Button';
import Table from 'components/design-system/table/Table';
import Checkbox from 'components/shared/Checkbox';
import LoadingComponent from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';

function SelectMembers({
  members,
  setMembers,
  isCluster = false,
  withJobPlacement = false,
  type = 'permission',
  pageTitle = '',
  filterOptions = ['search', 'menuFilter', 'sort'],
  fixedLeftCount = 1,
  fixedRightCount = 0,
  withHideColumn = true,
  useBadge = false,
  isReadOnly
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSelected, setTotalSelected] = useState(0);
  const [isHeaderChecked, setIsHeaderChecked] = useState(false);
  const [currentSort, setCurrentSort] = useState({
    sortColumn: 'name',
    sortDirection: 'asc'
  });

  const { config } = useUser();
  const { totalUser, users, getUsersData, nextPage, prevPage, hasMore } =
    useUsersAdmin();
  const { includes, excludes, selectAll } = members;

  const isFirstRun = useRef(true);
  const filter = useFilter((state) => state.filterById?.selectMembersFilter);

  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState(defaultHeaders);
  const defaultHeaders = [
    { name: 'checkbox', widthClass: 'w-[56px]' },
    { name: 'assigned badge', widthClass: 'w-[200px]', grow: true },
    { name: 'name', widthClass: 'w-[240px]', grow: true },
    { name: 'job title', widthClass: 'w-[200px]', grow: true },
    { name: 'job role', widthClass: 'w-[200px]', grow: true },
    { name: 'job function', widthClass: 'w-[200px]', grow: true },
    { name: 'directorate', widthClass: 'w-[200px]', grow: true },
    { name: 'department', widthClass: 'w-[200px]', grow: true },
    { name: 'division', widthClass: 'w-[200px]', grow: true },
    { name: 'region', widthClass: 'w-[200px]', grow: true },
    { name: 'city', widthClass: 'w-[200px]', grow: true },
    { name: 'country', widthClass: 'w-[200px]', grow: true }
  ];

  const setupHeaders = () => {
    let tempHeaders = [...defaultHeaders];
    tempHeaders.splice(1, useBadge ? 0 : 1);
    setHeaders(tempHeaders);
  };

  const getUserParams = () => {
    const { q, ...allFilter } = filter;
    const query = {
      ...filter,
      limit: 10,
      sortDirection: currentSort.sortDirection,
      sortColumn: currentSort.sortColumn
    };
    if (type === 'permission') {
      query.groupType = isCluster ? 'cluster_group' : 'permission_group';
    } else {
      query.countAvailableUsers = true;
      query.state = ['active'];
    }
    return query;
  };

  const _getUsersData = async () => {
    setIsLoading(true);
    await getUsersData(
      getUserParams(),
      withJobPlacement,
      'totalAvailableUsers'
    );
    setIsLoading(false);
  };

  const _getTotalUsersData = async () => {
    setIsLoading(true);
    const addedParams =
      type === 'permission'
        ? {
            countAvailablePlacements: true,
            groupType: isCluster ? 'cluster_group' : 'permission_group',
            sortDirection: 'asc',
            sortColumn: 'name'
          }
        : {
            countAvailableUsers: true,
            sortDirection: 'asc',
            sortColumn: 'name'
          };
    await getUsersData(addedParams, withJobPlacement);
    setIsLoading(false);
  };

  const _getListFilter = (addedParams) => {
    const fetchFilter = (pageParam) => getListFilter(addedParams);

    const { data, status, isFetchingMore, fetchMore } = useInfiniteQuery(
      ['filter', addedParams],
      ({ pageParam }) => fetchFilter(pageParam),
      {
        getNextPageParam: (lastPage, allPages) => lastPage.pagination?.next || 0
      }
    );

    return { data, status, isFetchingMore, fetchMore };
  };

  const getFilterData = async (addedParams) => {
    return await getListFilter({ q: addedParams?.q, key: addedParams?.key });
  };

  const handleNextPrevPage = (type) => {
    const nextPrevPage = type === 'next' ? nextPage : prevPage;
    nextPrevPage({ limit: 10, ...getUserParams() });
    setCurrentPage(currentPage + (type === 'next' ? 1 : -1));
  };

  const handleCheckMembers = (ids) => {
    let tempIncludes = cloneDeep(includes);
    let tempExcludes = cloneDeep(excludes);

    if (selectAll) {
      let newArray = tempExcludes.filter((value) => ids.indexOf(value) == -1);
      setMembers({ ...members, excludes: newArray });
    } else {
      let uniqueIds = new Set([...tempIncludes, ...ids]);
      setMembers({ ...members, includes: [...uniqueIds] });
    }
  };

  const handleUncheckMembers = (ids) => {
    let tempIncludes = cloneDeep(includes);
    let tempExcludes = cloneDeep(excludes);

    if (selectAll) {
      setMembers({ ...members, excludes: tempExcludes.concat(ids) });
    } else {
      let newArray = tempIncludes.filter((value) => ids.indexOf(value) === -1);
      setMembers({ ...members, includes: newArray });
    }
  };

  const handleSelectMembers = (isCheck, indexes) => {
    let tempRows = cloneDeep(rows);
    indexes.map((index) => {
      isCheck
        ? handleCheckMembers([rows[index]?.id])
        : handleUncheckMembers([rows[index]?.id]);
      tempRows[index].isChecked = isCheck;
    });
    setRows(tempRows);
  };

  const handleClickHeaderCheckbox = (isCheck) => {
    let rowsIds = rows.map((row) => {
      return row?.id;
    });
    isCheck ? handleCheckMembers(rowsIds) : handleUncheckMembers(rowsIds);
    let tempRows = cloneDeep(rows);
    tempRows.map((row) => {
      row.isChecked = isCheck;
    });
    setRows(tempRows);
  };

  useEffect(() => {
    setupHeaders();
    _getTotalUsersData();
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      filter && _getUsersData();
    }
  }, [filter, currentSort]);

  useEffect(() => {
    const tempRows = users.map((user) => {
      return {
        id: user?.id,
        isChecked: selectAll
          ? excludes.length > 0
            ? !excludes.some((id) => id === user?.id)
            : true
          : includes.length > 0
          ? includes.some((id) => id === user?.id)
          : false
      };
    });
    setRows(tempRows);
    setIsHeaderChecked(!tempRows.some((row) => !row?.isChecked));
    setTotalSelected(selectAll ? totalUser - excludes.length : includes.length);
  }, [members, users]);

  return (
    <>
      {isModalOpen && (
        <UsersAdminProvider>
          <ModalShowMember
            setShowModalMembers={setIsModalOpen}
            isSelectAll={selectAll}
            listIdUser={includes}
            excludedId={excludes}
            withJobPlacement={withJobPlacement}
            isAbleToRemoveUser={true}
            onClickRemove={handleUncheckMembers}
            isReadOnly={isReadOnly}
          />
        </UsersAdminProvider>
      )}
      {pageTitle !== '' && (
        <p className="typography-h500 text-n-900 mb-[24px]">{pageTitle}</p>
      )}
      <AdvancedFilter
        filterOptions={filterOptions}
        filterMenu={config.generalFilterOptions}
        filterClient={getFilterData}
        dialogQueryFn={(params) => _getListFilter(params)}
        id="selectMembersFilter"
      />
      <div className="bg-n-200 flex justify-center items-center flex-wrap mt-[21px] py-[16px]">
        <p className="mr-[16px]">
          {totalSelected}{' '}
          {getObjectiveLocale(
            `employee${totalSelected === 1 ? ' is' : 's are'} selected`
          )}
        </p>
        <Button.Tertiary
          className="mr-[8px]"
          onClick={() => setIsModalOpen(true)}
        >
          {getObjectiveLocale('See employees')}
        </Button.Tertiary>
        <Button.Tertiary
          className="mr-[8px]"
          onClick={() =>
            setMembers({ includes: [], excludes: [], selectAll: true })
          }
        >
          {getObjectiveLocale(`Select all [total_user] employees`).replace(
            /\[total_user]/g,
            getNumberLocale(totalUser)
          )}
        </Button.Tertiary>
        <Button.Tertiary
          onClick={() =>
            setMembers({ includes: [], excludes: [], selectAll: false })
          }
        >
          {getObjectiveLocale('Clear Selections')}
        </Button.Tertiary>
      </div>
      {isLoading ? (
        <LoadingComponent className="mt-[16px]" />
      ) : (
        <div className="mt-[16px]">
          <Table
            headers={headers}
            fixedLeftCount={fixedLeftCount}
            fixedRightCount={fixedRightCount}
            headerCheckbox={{
              checked: isHeaderChecked,
              onClick: () => handleClickHeaderCheckbox(!isHeaderChecked)
            }}
            withHideColumn={withHideColumn}
          >
            {users.map((user, index) => (
              <Table.Row
                onClick={() =>
                  handleSelectMembers(!rows[index]?.isChecked, [index])
                }
                key={index}
              >
                {headers.map((header, headerIndex) => {
                  return (
                    <Table.Column key={headerIndex}>
                      {header?.name === 'checkbox' && (
                        <Checkbox
                          id={`checkbox-user-${user?.id}`}
                          value={`checkbox-user-${user?.id}`}
                          dataCy={`checkbox-user-${user?.id}`}
                          checked={rows[index]?.isChecked}
                          onChange={() =>
                            handleSelectMembers(!rows[index]?.isChecked, [
                              index
                            ])
                          }
                          disabled={isReadOnly}
                        />
                      )}
                      {header?.name === 'assigned badge' && useBadge && (
                        <div className="w-full">
                          {user?.assignedBadge?.title ? (
                            <div className="flex items-center w-full">
                              <AWSImage
                                className="w-[24px] h-[24px]"
                                src={user?.assignedBadge?.iconUrl}
                              />
                              <p className="typography-h400 text-p-600 w-full px-[16px] truncate">
                                {user?.assignedBadge?.title}
                              </p>
                            </div>
                          ) : (
                            <p className="typography-paragraph text-n-900">
                              {getObjectiveLocale('no badge')}
                            </p>
                          )}
                        </div>
                      )}
                      {header?.name === 'name' && (
                        <div className="flex items-center w-full">
                          <Avatar
                            name={user?.name}
                            src={user?.profilePicture}
                            size={32}
                          />
                          <div className="w-full px-[16px]">
                            <p className="typography-h400 text-n-900 w-full truncate">
                              {user?.name}
                            </p>
                            <p className="typography-h100 text-n-800 w-full truncate">
                              {user?.email}
                            </p>
                          </div>
                        </div>
                      )}
                      {header?.name === 'job title' && user?.jobTitle}
                      {header?.name === 'job role' && user?.info?.jobRole}
                      {header?.name === 'job function' &&
                        user?.info?.jobFunction}
                      {header?.name === 'directorate' &&
                        user?.info?.directorate}
                      {header?.name === 'department' && user?.info?.department}
                      {header?.name === 'division' && user?.info?.division}
                      {header?.name === 'region' && user?.info?.region}
                      {header?.name === 'city' && user?.info?.city}
                      {header?.name === 'country' && user?.info?.country}
                    </Table.Column>
                  );
                })}
              </Table.Row>
            ))}
          </Table>
          <div className="flex justify-end mt-[18px] mb-[13px]">
            <PaginationModule
              pageNumber={currentPage}
              handleNext={() => handleNextPrevPage('next')}
              handlePrev={() => handleNextPrevPage('prev')}
              hasMore={hasMore}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default SelectMembers;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { useProfileFilter } from 'context/ProfileFilterContext';
import { useUser } from 'context/UserContext';
import { arrayPastYearsRange } from 'utils/DateHelper';
import { getQuarterRange } from 'utils/DateUtils';
import { formatTimezone } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';

function ProfileFilter(props) {
  const { setFilter, setYear } = useProfileFilter();
  const { isBca, config, organization, appType } = useUser();
  const defaultFilter =
    appType == 'culture'
      ? config?.cultureDefaultProfileTimeFilter
      : config?.defaultProfileTimeFilter;
  const optionsFilter =
    appType == 'culture'
      ? config?.cultureProfileTimeFilterOptions
      : config?.profileTimeFilterOptions;

  let filterData = ['today', 'all_time', 'current_year', 'past_years'];
  if (optionsFilter) {
    filterData = optionsFilter;
  }

  const thisYear = new Date().getFullYear();
  const orgCreatedYear = new Date(organization.createdAt).getFullYear();

  const getFilterData = (value) => {
    switch (value) {
      case 'next_year':
        return {
          id: -3,
          name: (thisYear + 1).toString(),
          value: thisYear + 1,
          filterId: 'next_year'
        };
      case 'current_year':
        return {
          id: -3,
          name: thisYear.toString(),
          value: thisYear,
          filterId: 'current_year'
        };
      case 'today':
        return {
          id: -2,
          name: `${getObjectiveLocale('Today')}`,
          value: 'today',
          filterId: 'today'
        };
      case 'all_time':
        return {
          id: -1,
          name: `${getObjectiveLocale('All Time')}`,
          value: 'all',
          filterId: 'all_time'
        };
      case 'past_years':
        return { id: 0, name: (thisYear - 1).toString(), value: thisYear - 1 };
    }
  };

  let years = filterData
    .filter((data) => data !== 'past_years')
    .map((data) => {
      return getFilterData(data);
    });

  if (filterData.find((data) => data == 'past_years')) {
    const rangePastYears = arrayPastYearsRange(thisYear - orgCreatedYear);
    years = [...years, ...rangePastYears];
  }

  const [selectedYears, setSelectedYears] = useState(
    getFilterData(defaultFilter)?.value || years[0]?.value
  );
  const [selectedDropdown, setSelectedDropdown] = useState(
    getFilterData(defaultFilter)?.name || years[0]?.name
  );

  // change value key to val
  years?.map((year) => {
    year['val'] = year?.value;
    delete year?.value;
  });

  const onSelect = (e) => {
    if (isBca) {
      return;
    }

    let type, start, due;
    if (e) {
      const selectedFilter = e.currentTarget;
      type = selectedFilter.classList[1];

      const el = e.currentTarget;
      const allButton = document.querySelectorAll('span.filter-menu-btn');
      allButton.forEach((btn) => {
        btn.classList.remove('is-active');
      });
      el.classList.add('is-active');
    }

    if (selectedYears !== 'all' && selectedYears !== 'today') {
      const range = getQuarterRange(type, selectedYears);
      start = range.start;
      due = range.due;
    } else if (selectedYears === 'today') {
      start = formatTimezone(dayjs().format('YYYY-MM-DD'), 'start');
      due = formatTimezone(dayjs().format('YYYY-MM-DD'), 'end');
    }

    const data =
      selectedYears === 'all'
        ? {
            periodBegin: formatTimezone('2015-01-01', 'start'),
            periodEndBefore: formatTimezone(
              dayjs().add(1, 'year').format('YYYY-MM-DD'),
              'end'
            )
          }
        : {
            periodBegin: start,
            periodEndBefore: due
          };

    setFilter(data);
  };

  const onSelectYears = (data) => {
    if (isBca) {
      return;
    }
    setSelectedDropdown(data.name);
    setSelectedYears(data.val);
    setYear(data.val);
  };

  useEffect(() => {
    onSelect(null);
  }, [selectedYears]);

  useEffect(() => {
    if (location.search.includes('alltime')) {
      setSelectedYears('all');
      setYear('all');
    }
  }, []);

  return (
    <div className="period">
      <span className="placeholder">{getObjectiveLocale('Period')}</span>
      <div className="filter-menu">
        <div className="mr-[30px]">
          <Dropdown>
            <Dropdown.Trigger dataCy="filter-period">
              <Trigger
                icon={false}
                topLabel={false}
                label={false}
                text={selectedDropdown}
              />
            </Dropdown.Trigger>
            <Dropdown.MenuItems>
              {years?.map((year, index) => (
                <Dropdown.MenuItem
                  onClick={() => onSelectYears(year)}
                  key={index}
                  dataCy={`filter-period-option-${index}`}
                >
                  {year?.name}
                </Dropdown.MenuItem>
              ))}
            </Dropdown.MenuItems>
          </Dropdown>
        </div>
        {props.showQuarterFilter &&
          selectedYears !== 'today' &&
          selectedYears !== 'all' && (
            <div>
              <span
                className="filter-menu-btn annual is-active"
                onClick={(e) => onSelect(e)}
              >
                {' '}
                {getObjectiveLocale('Annual')}
              </span>
              {!isBca && (
                <>
                  <span
                    className="filter-menu-btn q1"
                    onClick={(e) => onSelect(e)}
                  >
                    {' '}
                    Q1 Jan - Mar
                  </span>
                  <span
                    className="filter-menu-btn q2"
                    onClick={(e) => onSelect(e)}
                  >
                    {' '}
                    Q2 Apr - Jun
                  </span>
                  <span
                    className="filter-menu-btn q3"
                    onClick={(e) => onSelect(e)}
                  >
                    Q3 Jul - Sept{' '}
                  </span>
                  <span
                    className="filter-menu-btn q4"
                    onClick={(e) => onSelect(e)}
                  >
                    Q4 Oct - Dec
                  </span>
                </>
              )}
            </div>
          )}
      </div>
    </div>
  );
}

export default ProfileFilter;

import React from 'react';

import { bgColorDict, fontColorDict } from './ColorDictionarySidebarEvidence';

const WrapperTotalScoring = ({ totalScore, dataCy }) => {
  return (
    <div
      className="evidence-border h-[48px] mb-[16px] px-[16px] py-[16px] flex items-center justify-between"
      data-cy={dataCy}
    >
      <p className="typography-h400">Total scoring</p>
      <p
        className={`evidence-score-box typography-h200 ${bgColorDict['5E20B3']} ${fontColorDict['5E20B3']}`}
      >
        {totalScore.toFixed(2)}
      </p>
    </div>
  );
};

export default WrapperTotalScoring;

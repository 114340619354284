import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import useEditPhase from 'hooks/useEditPhase';
import useModalObjectives from 'hooks/useModalObjectives';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown, {
  useDropdown
} from 'components/design-system/dropdown/Dropdown';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import Badge from 'components/shared/Badge';
import ConditionalWrapper from 'components/shared/ConditionalWrapper';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const PhaseDropdown = ({
  task,
  onChange,
  useTrigger = false,
  beforeTrigger,
  position = 'left',
  useCustomTrigger,
  useFloating = true,
  listPhases,
  type = 'list',
  withAddPhaseButton = false
}) => {
  const { setEditedPhaseData, setCurrentPhaseData } = useEditPhase();
  const { showModal } = useModalObjectives();
  const { open } = useDropdown();
  const params = useParams();

  const projectId = params.parentId;

  const { phase } = task || {};

  const onChangePhase = (phaseId, phaseName) => {
    onChange && onChange(phaseId, phaseName);
  };

  const onClickAddPhase = () => {
    showModal({ modalType: 'editPhases', props: { projectId, type } });
    setEditedPhaseData(listPhases);
    setCurrentPhaseData(listPhases);
  };

  return (
    <>
      {useTrigger && (
        <Dropdown.Trigger
          customClass={type === 'list' ? 'w-full h-full' : ''}
          beforeTrigger={beforeTrigger}
          dataCy="trigger-dialog-phase"
        >
          {useCustomTrigger ? (
            useCustomTrigger
          ) : (
            <Trigger phase={phase} type={type} />
          )}
        </Dropdown.Trigger>
      )}

      {open && (
        <ConditionalWrapper
          condition={useFloating}
          wrapper={(children) => (
            <FloatingComponent lockScroll={false} customContentHeight={320}>
              {children}
            </FloatingComponent>
          )}
        >
          <Dropdown.MenuItems
            type="absolute"
            position={position}
            useScrollClose
            useTrigger={useTrigger}
          >
            <div
              className={`overflow-hidden hover:overflow-auto hover:overflow-overlay max-h-[287px] ${
                withAddPhaseButton
                  ? 'border-solid border-0 border-b border-n-300 pb-[8px]'
                  : ''
              }`}
            >
              {listPhases?.map((value, index) => (
                <MenuItem
                  onChangePhase={onChangePhase}
                  value={value}
                  key={index}
                />
              ))}
            </div>
            {withAddPhaseButton && (
              <Dropdown.MenuItem
                customClass="w-[192px] h-[32px] mt-[4px]"
                onClick={onClickAddPhase}
                dataCy="menu-item-phase"
              >
                <SVGIcon
                  size="24"
                  iconName="icon-add"
                  fillColor="var(--base-600)"
                />
                <p className="typography-button text-base-600">
                  {getObjectiveLocale('Add phase')}
                </p>
              </Dropdown.MenuItem>
            )}
          </Dropdown.MenuItems>
        </ConditionalWrapper>
      )}
    </>
  );
};

const MenuItem = ({ onChangePhase, value }) => {
  const [isEllipsis, setIsEllipsis] = useState(false);
  return (
    <Dropdown.MenuItem
      customClass="w-[192px] h-[36px]"
      onClick={() => onChangePhase(value.id, value.name)}
      dataCy="menu-item-phase"
    >
      <TooltipContainer show={isEllipsis} text={value.name}>
        <Badge
          defaultPointer
          content={value.name}
          colorHex={value.colorHex}
          bgColorHex={value.bgColorHex}
          className="pointer-events-none w-full"
          customMaxWidth="max-w-[160px]"
          truncate
          setIsEllipsis={setIsEllipsis}
        />
      </TooltipContainer>
    </Dropdown.MenuItem>
  );
};

const Trigger = ({ phase, type }) => {
  const ref = useRef();
  const { name, colorHex, bgColorHex } = phase || {};

  return (
    <TooltipContainer
      show
      text={phase ? `Phase: ${name}` : 'Add phase'}
      classContainer={
        type === 'list'
          ? 'w-full h-full flex items-center px-[16px]'
          : 'mt-[8px] mr-[8px]'
      }
      position="bottom"
    >
      {phase && (
        <>
          <Badge
            defaultPointer
            content={name}
            colorHex={colorHex}
            bgColorHex={bgColorHex}
            className="pointer-events-none"
            customMaxWidth="max-w-[128px]"
            truncate
          />
          {type === 'board' && <div className="w-full h-full" ref={ref} />}
        </>
      )}
    </TooltipContainer>
  );
};

const PhaseDropdownComponent = (props) => {
  return (
    <Dropdown autoOpen={!props.useTrigger} customClass={props.customClass}>
      <PhaseDropdown {...props} />
    </Dropdown>
  );
};

export default PhaseDropdownComponent;

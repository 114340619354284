import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidV4 } from 'uuid';

import { getRatingTemplates } from 'client/RatingTemplateClient';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import { getDatas } from 'src/client/admin/GoalWeight';

import CustomAttributesCard from './CustomAttribute/CustomAttributesCard';

const ratingTypeDict = {
  goals_scoring: 'goal_final',
  tasks_scoring: 'task_final',
  values_scoring: 'value_final',
  competencies_scoring: 'competency_final',
  review_aspects_scoring: 'review_aspect'
};

const ConfigureMultipleSection = ({
  setData,
  templateData,
  currentTrackType,
  setTemplateData,
  customAttributes,
  setCustomAtrributes
}) => {
  const [listRatingTemplate, setListRatingTemplate] = useState([]);
  const [loading, setLoading] = useState(true);

  let { data } = useQuery(
    ['track-template', 'objective', 'categories'],
    getDatas,
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000
    }
  );
  const categories = data?.data;

  const getListRatingTemplate = async () => {
    const { data } = await getRatingTemplates({
      ratingType: ratingTypeDict[currentTrackType]
    });
    if (data) {
      setListRatingTemplate(data);
    }
    setLoading(false);
  };

  const addNewAttributes = () => {
    let attributes = cloneDeep(templateData?.config);
    let reviewAspectAttributes = attributes?.reviewAspects ?? [];

    reviewAspectAttributes.push({
      uniqueId: uuidV4(),
      id: customAttributes?.[0]?.id,
      name: customAttributes?.[0]?.name,
      allowOverride: false,
      objectiveCategoryIds: [],
      ratingTemplateId: {},
      mechanism: 'selected_aspect',
      useComment: 'no',
      view: 'rating'
    });
    attributes.reviewAspects = reviewAspectAttributes;
    setData(attributes, 'config');
  };

  useEffect(() => {
    getListRatingTemplate();
  }, []);

  return (
    <div className="create-track-template-configure" id="configuration">
      <p
        className="typography-h500 typography-primary text-n-900 mb-[4px]"
        id="configuration"
      >
        {getObjectiveLocale('Configure')}
      </p>
      <p className="typography-h400 typography-paragraph typography-secondary mb-[24px]">
        {getObjectiveLocale(
          'What settings do you want to use in this track template?'
        )}
      </p>
      <div className="wrapper-select-setting">
        {loading ? (
          <LoadingComponent className="mb-[24px]" />
        ) : (
          templateData?.config?.reviewAspects?.map((attr, index) => {
            return (
              <CustomAttributesCard
                key={attr.uniqueId}
                dataAttributes={attr}
                customAttributes={customAttributes}
                index={index}
                templateData={templateData}
                setTemplateData={setTemplateData}
                listRatingTemplate={listRatingTemplate}
                setCustomAtrributes={setCustomAtrributes}
                categories={categories}
              />
            );
          })
        )}
      </div>
      <Button.Secondary
        datacy="add-custom-attributes"
        onClick={() => addNewAttributes()}
        customClass="w-full"
      >
        <SVGIcon
          size="24"
          fillColor="var(--base-600)"
          iconName="icon-add"
          customClass="mr-[4px]"
        />
        {getObjectiveLocale('Add Custom Attribute')}
      </Button.Secondary>
    </div>
  );
};

export default ConfigureMultipleSection;

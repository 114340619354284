import React from 'react';

import { useLocale } from 'hooks/useLocale';
import usePulseSurvey from 'hooks/usePulseSurvey';

import Divider from 'components/design-system/Divider';

import FormTemplate from './FormTemplate';

const NameDescriptionSurvey = ({ isReadOnly }) => {
  const { setBothLocale } = useLocale();
  const { surveyData, setSurveyData } = usePulseSurvey();

  const listRequired = {
    element: 'toggle',
    isChecked: !surveyData?.canBePostponed,
    onChange: () =>
      setSurveyData({
        ...surveyData,
        canBePostponed: !surveyData?.canBePostponed
      }),
    cardTitle: setBothLocale('Set Pulse Survey as Required'),
    cardDescription: setBothLocale(
      'Employees cannot access other features if they do not complete the survey'
    ),
    disabled: isReadOnly,
    dataCy: 'is-required'
  };
  return (
    <>
      <FormTemplate
        title="Give it a name"
        description="Title of this pulse survey."
        staticChildren={{
          type: 'input',
          title: 'Survey Name',
          placeholder: 'Type name here...',
          value: surveyData?.title,
          onChange: (value) => setSurveyData({ ...surveyData, title: value }),
          isReadOnly: isReadOnly,
          dataCy: 'name-survey'
        }}
      />

      <Divider
        style={{
          marginTop: '40px',
          marginBottom: '40px',
          height: '1px',
          width: '720px'
        }}
      />

      <FormTemplate
        title="Say something about this survey"
        description="Content will be displayed in the description field of the pulse survey."
        staticChildren={{
          type: 'textarea',
          title: 'Description',
          placeholder: 'Type description here...',
          value: surveyData?.description,
          onChange: (value) =>
            setSurveyData({ ...surveyData, description: value }),
          isReadOnly: isReadOnly,
          dataCy: 'description-survey'
        }}
      />

      <Divider
        style={{
          marginTop: '40px',
          marginBottom: '40px',
          height: '1px',
          width: '720px'
        }}
      />

      <FormTemplate
        title="Thank you message"
        description="Message will appear in the closing page after user finishes survey."
        customClass="mb-[40px]"
        staticChildren={{
          type: 'textarea',
          title: 'Message',
          placeholder: 'Type thank you message here...',
          value: surveyData?.thankMessage,
          onChange: (value) =>
            setSurveyData({ ...surveyData, thankMessage: value }),
          isReadOnly: isReadOnly,
          dataCy: 'thanks-message'
        }}
      />

      {/* COMMENT FOR BCA REQUEST */}

      {/* <Divider
        style={{
          marginTop: '40px',
          marginBottom: '40px',
          height: '1px',
          width: '720px'
        }}
      /> */}

      {/* <FormTemplate
        staticChildren={{
          type: 'toggle',
          input: listRequired,
          isReadOnly: isReadOnly
        }}
      /> */}
    </>
  );
};

export default NameDescriptionSurvey;

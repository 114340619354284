import React, { Fragment, useState } from 'react';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import Modal from 'components/shared/modal/Modal';

import ModalShowMember from '../../groups/ModalShowMember';
import './StepVerify.scss';

function StepVerify({ group, loading, isCluster, isReadOnly }) {
  const [showModal, setShowModal] = useState('');
  const groupPermissions = Object.entries(
    isCluster ? group?.clusters : group?.permissions
  ).map(([group, permissions]) => {
    return { group, permissions };
  });

  return (
    <>
      <div className="w-[60%]">
        <p className="typography-h500 mb-[28px]">
          {getObjectiveLocale('Summary')}
        </p>
        <div className="verify-grid">
          <span className="typography-secondary">
            {getObjectiveLocale('Group Name')}
          </span>
          <span className="typography-secondary">
            {!loading ? (
              group.name
            ) : (
              <Shimmer circle widthRandomness={0.25} height="100%" />
            )}
          </span>

          <span className="typography-secondary">
            {getObjectiveLocale('Description')}
          </span>
          <span className="typography-secondary">
            {!loading ? (
              group.description
            ) : (
              <Shimmer circle widthRandomness={0.25} height="100%" />
            )}
          </span>

          <span className="typography-secondary">
            {getObjectiveLocale('Members')}
          </span>
          <span
            className={`typography-link ${
              group?.member_count ? 'cursor-pointer' : ''
            }`}
            onClick={() => group?.member_count && setShowModal('members')}
          >
            {!loading ? (
              `${getNumberLocale(group?.member_count || 0)} Employees`
            ) : (
              <Shimmer circle widthRandomness={0.25} height="100%" />
            )}
          </span>
        </div>

        <div className="separator mt-[52px] mb-[40px]" />

        <p className="typography-h500 mb-[28px]">
          {getObjectiveLocale(
            isCluster ? 'Objective’s Attribute Visibility' : 'Permissions'
          )}
        </p>
        <div className="verify-grid">
          {loading
            ? [1, 2, 3].map((_, index) => {
                return (
                  <Fragment key={index}>
                    <span className="typography-secondary">
                      <Shimmer circle widthRandomness={0.25} height="20px" />
                    </span>
                    <span className="typography-link cursor-pointer">
                      <Shimmer circle widthRandomness={0.25} height="20px" />
                    </span>
                  </Fragment>
                );
              })
            : groupPermissions?.map((data, index) => {
                const { group, permissions } = data;
                return (
                  <Fragment key={index}>
                    <span className="typography-secondary">
                      {getObjectiveLocale(group)}
                    </span>
                    <span
                      className="typography-link cursor-pointer"
                      onClick={() => setShowModal('permissions-' + index)}
                    >
                      {getNumberLocale(Object.keys(permissions).length)}{' '}
                      {getObjectiveLocale('configurations')}
                    </span>
                  </Fragment>
                );
              })}
        </div>
      </div>

      {showModal.includes('permissions-') &&
        (() => {
          const index = parseInt(showModal.split('-')[1]);
          const { group, permissions } = groupPermissions?.[index] || {};
          const nameHeader = isCluster
            ? `Objective Attributes`
            : `${
                getObjectiveLocale(group?.toUpperCase()) || ''
              } MANAGEMENT PROCESS`;
          const modalTitle = `${getObjectiveLocale(group)} ${
            isCluster ? 'Visibility' : 'Permissions'
          }`;

          return (
            <Modal
              title={modalTitle}
              withCloseIcon={false}
              withPrimaryBtn={{
                onClick: () => setShowModal(''),
                title: 'Close',
                disabled: isReadOnly
              }}
              footerClass="mt-[0px]"
            >
              <div className="overflow-auto h-[316px]">
                <div className="modal-grid w-[552px]">
                  <span className="typography-h300 text-n-800">
                    {getObjectiveLocale(nameHeader)}
                  </span>
                  <span className="typography-h300 text-n-800">
                    {getObjectiveLocale('CONFIGURATION')}
                  </span>
                  {Object.entries(permissions).map(([key, value], index) => {
                    return (
                      <Fragment key={index}>
                        <span className="typography-paragraph">
                          {getObjectiveLocale(key)}
                        </span>
                        <span className="typography-paragraph">
                          {getObjectiveLocale(value)}
                        </span>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
              <div className="separator mx-[-24px] mt-[16px]" />
            </Modal>
          );
        })()}
      {showModal === 'members' && (
        <ModalShowMember
          setShowModalMembers={() => setShowModal('')}
          isSelectAll={false}
          withCloseIcon={false}
          listIdUser={group.member_ids}
          excludedId={[]}
          withJobPlacement={true}
          isReadOnly={isReadOnly}
        />
      )}
    </>
  );
}

export default StepVerify;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import flatten from 'lodash/flatten';

import SingleBarContainer from './SingleBarContainer';

const ChildBarWrapper = ({
  subContentOptions,
  group,
  parentId,
  section,
  childLoading
}) => {
  const [objectivesData, setObjectivesData] = useState([]);
  const getQueryKey = subContentOptions?.getQueryKey;

  const queryKey = getQueryKey(parentId);

  const { data } = useQuery(queryKey, {
    staleTime: 1000,
    refetchOnMount: false
  });

  useEffect(() => {
    if (data) {
      const newObjectivesData = flatten(
        data?.pages?.map((page) => {
          return page?.data;
        })
      );
      setObjectivesData(newObjectivesData);
    }
    // setTask is dependant on another section too and would make infinite loop if include it in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {objectivesData?.length > 0 &&
        objectivesData?.map((child) => (
          <SingleBarContainer
            group={group}
            objective={child}
            section={section}
            key={'bar' + child?.id}
            subContentOptions={subContentOptions}
          />
        ))}
      {childLoading && <div className="h-[48px]" />}
    </>
  );
};

export default ChildBarWrapper;

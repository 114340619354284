import React, { useState } from 'react';

import ctl from '@netlify/classnames-template-literals';

import ChildrenCount from 'components/tasks/board/ChildrenCount';
import LastUpdated from 'components/tasks/board/LastUpdated';
import Progress from 'components/tasks/board/Progress';
import TaskName from 'components/tasks/board/TaskName';
import Assignee from 'components/tasks/task-column/Assignee';
import DueDate from 'components/tasks/task-column/DueDate';
import Phase from 'components/tasks/task-column/Phase';
import Priority from 'components/tasks/task-column/Priority';

const BoardCardContent = ({
  taskNode,
  task,
  setTask,
  groupData,
  sectionData,
  level,
  handleClickCard,
  showChild,
  showChildren,
  isLoadChildren,
  isLastChild,
  isSelected,
  isProject,
  filter,
  listMetrics,
  setShowChild,
  fetchObjectivesChildren,
  cancelAddTask
}) => {
  const [isCardClickable, setIsCardClickable] = useState(true);

  const { name } = task || '';
  const { phase } = task || {};
  const { priority } = task || {};
  const { childrenCount } = task || 0;
  const { updatedAt, measurement, progressUpdated } = task;

  const cardContentCN = ctl(`
    relative group w-full pb-[8px] px-[16px]
    ${
      level === 0
        ? `pt-[12px] ${showChild ? 'rounded-t-[4px]' : 'rounded-[4px]'}`
        : `pt-[8px] ${isLastChild ? 'rounded-b-[4px]' : ''}`
    }
    ${isSelected ? 'bg-base-3008' : level === 0 ? 'bg-n-000' : 'flex bg-n-100'}
  `);

  const subTasksLineCN = ctl(`
    absolute
    top-[0px] left-[16px]
    w-[20px] h-[20px]
    border-solid border-n-400
    border-l-2 border-b-2 border-t-0 border-r-0
    rounded-bl-[4px]
  `);

  const subTasksConnectorLineCN = ctl(`
    absolute
    top-[0px] left-[16px]
    h-full
    border-solid border-n-400
    border-l-2 border-b-0 border-t-0 border-r-0
  `);

  return (
    <div
      className={cardContentCN}
      onClick={(e) => isCardClickable && handleClickCard(e)}
      data-cy="board-card-content"
    >
      <div className="flex flex-col">
        {level !== 0 && <div className={subTasksLineCN} />}
        {level !== 0 && !isLastChild && (
          <div className={subTasksConnectorLineCN} />
        )}
      </div>
      <div className={`flex flex-col w-full ${level === 0 ? '' : 'ml-[24px]'}`}>
        <TaskName
          filter={filter}
          task={task}
          setTask={setTask}
          taskNode={taskNode}
          groupData={groupData}
          sectionData={sectionData}
          level={level}
          listMetrics={listMetrics}
          isProject={isProject}
          setIsTaskClickable={setIsCardClickable}
          setShowChild={setShowChild}
          fetchObjectivesChildren={fetchObjectivesChildren}
          cancelAddTask={cancelAddTask}
        />
        <div className="flex flex-wrap items-end w-full mb-[16px]">
          {(filter?.group === 'phase' || level !== 0) && priority && (
            <Priority
              task={task}
              setTask={setTask}
              type="board"
              isProject={isProject}
            />
          )}
          {(filter?.group === 'priority' || level !== 0) && phase && (
            <Phase task={task} setTask={setTask} type="board" />
          )}
          {updatedAt && <LastUpdated task={task} isProject={isProject} />}
          {level === 0 && childrenCount > 0 && (
            <ChildrenCount
              childrenCount={childrenCount}
              isLoadChildren={isLoadChildren}
              showChildren={showChildren}
              priority={priority}
              isProject={isProject}
            />
          )}
          {(filter?.group === 'phase' || level !== 0) &&
            !priority &&
            name !== '' && (
              <Priority
                task={task}
                setTask={setTask}
                type="board"
                isProject={isProject}
              />
            )}
        </div>
        {measurement && progressUpdated && (
          <Progress measurement={measurement} level={level} />
        )}
        <div className="flex justify-between items-center">
          <Assignee task={task} type="board" isProject={isProject} />
          <DueDate task={task} type="board" />
        </div>
      </div>
    </div>
  );
};

export default BoardCardContent;

/* eslint-disable no-async-promise-executor */
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import {
  addMeasurement,
  deleteMeasurement,
  editMeasurement,
  getListMeasurement
} from 'client/ObjectivesClient';
import { updateConfig } from 'client/admin/Config';
import { updateOrganization } from 'client/adminClient';
import { useAuth } from 'context/AuthContext';
import { useSidebarMenu } from 'context/SidebarMenuContext';
import { useUser } from 'context/UserContext';
import useOrganization from 'hooks/useOrganization';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Button from 'components/design-system/Button';
import SidebarLeftMenuNavigator from 'components/shared/SidebarLeftMenuNavigator';
import Modal from 'components/shared/modal/Modal';

import Administration from './Administration';
import Behavior from './Behavior';
import Feeds from './Feeds';
import FormalReview from './FormalReview';
import GeneralV2 from './GeneralV2';
import Integration from './Integration';
import Missions from './Missions';
import Objectives from './Objectives';
import OnGoingReview from './OnGoingReview';
import { checkTimeParams } from './OrganizationHelper';
import Profile from './Profile';

const Organization = () => {
  const { refetch } = useAuth();
  const { orgId } = useParams();
  const queryClient = useQueryClient();
  const { organization, config, appType } = useUser();
  const { toggleSidebar } = useSidebarMenu();

  const [activeMenu, setActiveMenu] = useState({
    id: 'general',
    title: getObjectiveLocale('General')
  });
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState({
    isShow: false,
    currentMenu: activeMenu
  });
  const [appData, setAppData] = useState(() => null);
  const configParams = {
    organizationId: orgId || organization?.id,
    settingsOrganization: activeMenu?.id
  };
  let keyMainColor = appType === 'culture' ? 'cultureAssets' : 'assets';
  let mainColor = config?.[keyMainColor]?.mainColor;
  const {
    currentColor,
    configData,
    originConfigData,
    originGeneralData,
    changeConfigData,
    setIsCancel,
    isCancel,
    setCurrentColor,
    changeOriginConfigData,
    changeOriginGeneralData,
    measurementUnitData,
    originMeasurementUnitData,
    setMeasurementUnitData,
    setOriginMeasurementUnitData
  } = useOrganization((state) => ({
    currentColor: state.currentColor,
    configData: state.configData,
    originConfigData: state.originConfigData,
    originGeneralData: state.originGeneralData,
    changeConfigData: state.changeConfigData,
    setIsCancel: state.setIsCancel,
    isCancel: state.isCancel,
    setCurrentColor: state.setCurrentColor,
    changeOriginConfigData: state.changeOriginConfigData,
    changeOriginGeneralData: state.changeOriginGeneralData,
    measurementUnitData: state.measurementUnitData,
    originMeasurementUnitData: state.originMeasurementUnitData,
    setMeasurementUnitData: state.setMeasurementUnitData,
    setOriginMeasurementUnitData: state.setOriginMeasurementUnitData
  }));

  const isFeedsVisible = usePermission('settingsOrganizationFeeds');
  const isObjectivesVisible = usePermission('settingsOrganizationObjectives');
  const isOngoingReviewVisible = usePermission(
    'settingsOrganizationOngoingReview'
  );
  const isPerformanceReviewVisible = usePermission(
    'settingsOrganizationPerformanceReview'
  );

  const listMenu = [
    {
      id: 'general',
      title: getObjectiveLocale('General'),
      onClick: () => {
        checkChangeMenu('general');
      },
      isVisible: usePermission('settingsOrganizationGeneral')
    },
    {
      id: 'feeds',
      title: getObjectiveLocale('Feeds'),
      onClick: () => {
        checkChangeMenu('feeds');
      },
      isVisible: appType !== 'performance' && isFeedsVisible
    },
    {
      id: 'behaviors',
      title: getObjectiveLocale('Behaviors'),
      onClick: () => {
        checkChangeMenu('behaviors');
      },
      isVisible: usePermission('settingsOrganizationBehaviors')
    },
    {
      id: 'objectives',
      title: getObjectiveLocale('Objectives'),
      onClick: () => {
        checkChangeMenu('objectives');
      },
      isVisible: appType !== 'culture' && isObjectivesVisible
    },
    {
      id: 'ongoing_review',
      title: getObjectiveLocale('On-going Review'),
      onClick: () => {
        checkChangeMenu('ongoing_review');
      },
      isVisible: appType !== 'culture' && isOngoingReviewVisible
    },
    {
      id: 'performance_review',
      title: getObjectiveLocale('Performance Review'),
      onClick: () => {
        checkChangeMenu('performance_review');
      },
      isVisible: appType !== 'culture' && isPerformanceReviewVisible
    },
    {
      id: 'profile',
      title: getObjectiveLocale('Profile'),
      onClick: () => {
        checkChangeMenu('profile');
      },
      isVisible: usePermission('settingsOrganizationProfile')
    },
    {
      id: 'missions',
      title: getObjectiveLocale('Missions'),
      onClick: () => {
        checkChangeMenu('missions');
      },
      isVisible: usePermission('settingsOrganizationMissions')
    },
    {
      id: 'integration',
      title: getObjectiveLocale('Integration'),
      onClick: () => {
        checkChangeMenu('integration');
      },
      isVisible: usePermission('settingsOrganizationIntegration')
    },
    {
      id: 'administration',
      title: getObjectiveLocale('Administration'),
      onClick: () => {
        checkChangeMenu('administration');
      },
      isVisible: usePermission('settingsOrganizationAdministration')
    }
  ].filter((el) => el.isVisible);

  const checkChangeMenu = (selectedMenu) => {
    const currentMenu = listMenu.find((menu) => menu.id === selectedMenu);
    if (activeMenu !== currentMenu?.id) {
      if (isEdit) {
        setModal({ isShow: true, currentMenu: currentMenu });
      } else {
        setIsEdit(false);
        setActiveMenu(currentMenu);
      }
    }
  };

  const updateMainColor = async () => {
    const mainColorData = {
      assets: { mainColor: currentColor['assets'] },
      cultureAssets: { mainColor: currentColor['cultureAssets'] },
      organizationId: organization?.id
    };

    await updateConfig(mainColorData);
  };

  const editData = async (isAll) => {
    const editedData = cloneDeep(appData);
    let selectedData = {};
    if (isAll) {
      delete editedData.deleted;
      delete editedData.performanceIpaUrl;
      delete editedData.performanceApkUrl;
      delete editedData.cultureApkUrl;
      delete editedData.cultureIpaUrl;
      delete editedData.emailRegex;
      isEmpty(editedData.host) && delete editedData.host;
      isEmpty(editedData.cultureHost) && delete editedData.cultureHost;
      isEmpty(editedData.subdomain) && delete editedData.subdomain;
      isEmpty(editedData.cultureSubdomain) &&
        delete editedData.cultureSubdomain;
    } else {
      selectedData = {
        color: editedData?.color,
        videoConfig: editedData?.videoConfig,
        allowVideo: editedData?.allowVideo,
        feedConfig: editedData?.feedConfig,
        sendReminder: editedData?.sendReminder,
        allowSharing: editedData?.allowSharing
      };
    }

    const { isSuccess, data } = await updateOrganization(
      appData.id,
      isAll ? editedData : selectedData
    );
    if (data) {
      setAppData(data);
      changeOriginGeneralData(data);
    }
    return isSuccess;
  };

  const queryToBeReset = () => {
    queryClient.removeQueries('objective');
    queryClient.removeQueries('objectives');
  };

  const checkMeasurementData = async () => {
    let toBeEdited = [];
    let toBeDeleted = [];
    let toBeAdded = [];
    measurementUnitData.map((unit) => {
      if (!unit?.id) {
        toBeAdded.push(unit);
      } else if (unit?.action === 'edit') {
        toBeEdited.push(unit);
      } else if (unit?.action === 'delete') {
        toBeDeleted.push(unit);
      }
    });
    const promisesAdded = toBeAdded.map(async (data) => {
      return new Promise(async (resolve) => {
        const { data: dataAdded } = await addMeasurement({
          description: data?.description,
          unit: data?.unit,
          isDefaultForGoal: data?.isDefaultForGoal,
          isDefaultForTask: data?.isDefaultForTask
        });
        if (dataAdded) {
          data = dataAdded;
          resolve(dataAdded);
        }
      });
    });
    const promisesEdited = toBeEdited.map(async (data) => {
      return new Promise(async (resolve) => {
        let body = {
          description: data?.description,
          unit: data?.unit
        };
        if (data?.orderLevel) {
          body.orderLevel = parseInt(data?.orderLevel);
        }
        if (data?.isDefaultForGoal) {
          body.isDefaultForGoal = true;
        }
        if (data?.isDefaultForTask) {
          body.isDefaultForTask = true;
        }
        const { data: dataEdited } = await editMeasurement(data?.id, body);
        if (dataEdited) {
          data = dataEdited;
          resolve(dataEdited);
        }
      });
    });
    const promisesDeleted = toBeDeleted.map(async (data) => {
      return new Promise(async (resolve) => {
        const { isSuccess } = await deleteMeasurement(data?.id);
        resolve(isSuccess);
      });
    });
    await Promise.allSettled(promisesAdded);
    await Promise.allSettled(promisesEdited);
    await Promise.allSettled(promisesDeleted);
    return true;
  };

  const edit = async (selectedMenu) => {
    let currentConfig = cloneDeep(configData);
    currentConfig = omitBy(currentConfig, isNil);
    delete currentConfig.formalReviewTopGoalsOnly;
    delete currentConfig.assets;
    delete currentConfig.cultureAssets;
    delete currentConfig.recognitionChooseObjective;
    delete currentConfig.labelsSorting;
    delete currentConfig.supportEmail;
    delete currentConfig.sortGroupBy;
    delete currentConfig.maxNumberOfPinnedPosts;
    delete currentConfig.cmsOption;
    delete currentConfig.permissions;
    delete currentConfig.editablePlacementFormalReview;
    delete currentConfig.valueMaxLevel;
    delete currentConfig.minSumOfRequestedObjectivesWeightPercentage;
    delete currentConfig.minSumOfRequestedObjectivesWeightType;
    delete currentConfig.ceoId;
    delete currentConfig.performanceIpaUrl;
    delete currentConfig.performanceApkUrl;
    delete currentConfig.cultureApkUrl;
    delete currentConfig.cultureIpaUrl;

    let checkinParamsAt = currentConfig?.checkinParams?.at;

    if (checkinParamsAt && dayjs(checkinParamsAt).isValid()) {
      currentConfig.checkinParams.at = dayjs(checkinParamsAt).format('HH:mm');
    }

    let isSuccess = false;
    if (activeMenu?.id === 'general') {
      (await currentColor?.[keyMainColor]) !== mainColor && updateMainColor();
      const isGeneralSuccess = await editData(true);
      if (isGeneralSuccess) {
        setIsEdit(false);
        isSuccess = isGeneralSuccess;
      }
    } else {
      if (
        activeMenu?.id === 'feeds' &&
        (!isEqual(originGeneralData.color, appData.color) ||
          !isEqual(originGeneralData.videoConfig, appData.videoConfig) ||
          !isEqual(originGeneralData.allowVideo, appData.allowVideo) ||
          !isEqual(
            originGeneralData?.feedConfig?.leaderboard,
            appData?.feedConfig?.leaderboard
          ) ||
          !isEqual(originGeneralData?.sendReminder, appData?.sendReminder) ||
          !isEqual(originGeneralData?.allowSharing, appData?.allowSharing))
      ) {
        await editData(false);
      } else if (
        activeMenu?.id === 'objectives' &&
        !isEqual(measurementUnitData, originMeasurementUnitData)
      ) {
        const measurementSuccess = await checkMeasurementData();
        if (measurementSuccess) {
          queryToBeReset();
          const { data } = await getListMeasurement();
          if (data) {
            setOriginMeasurementUnitData(data);
            setMeasurementUnitData(data);
          }
          setIsEdit(false);
          selectedMenu && setActiveMenu(selectedMenu);
          setModal({ isShow: false, currentMenu: null });
        }
        isSuccess = measurementSuccess;
      }

      if (!isEqual(configData, originConfigData)) {
        const { isSuccess: isConfigSuccess, data } = await updateConfig(
          currentConfig,
          configParams
        );
        if (isConfigSuccess) {
          let updatedData = cloneDeep(data);
          const checkinParamsAt = updatedData?.checkinParams?.at;
          if (checkinParamsAt) {
            updatedData.checkinParams.at = checkTimeParams(checkinParamsAt);
          }

          setIsEdit(false);
          selectedMenu && setActiveMenu(selectedMenu);
          data && changeOriginConfigData(updatedData);
          data && changeConfigData(updatedData);
          setModal({ isShow: false, currentMenu: null });
          isSuccess = isConfigSuccess;
        }
      }
    }
    if ((orgId === organization?.id || !orgId) && isSuccess) {
      refetch();
    }
  };

  const cancelChanges = () => {
    setIsCancel(!isCancel);
    setAppData(originGeneralData);
    changeConfigData(originConfigData);
    setCurrentColor(mainColor);
    activeMenu?.id === 'objectives' &&
      setMeasurementUnitData(originMeasurementUnitData);
    setIsEdit(false);
  };

  useEffect(() => {
    if (listMenu.length > 0) {
      setActiveMenu(listMenu[0]);
    }
  }, []);

  useEffect(() => {
    toggleSidebar(true);
    if (activeMenu?.id === 'general') {
      !isEmpty(appData) &&
      !isEmpty(originGeneralData) &&
      (!isEqual(appData, originGeneralData) ||
        currentColor?.[keyMainColor] !== mainColor)
        ? setIsEdit(true)
        : setIsEdit(false);
    } else {
      !isEqual(configData, originConfigData) &&
      !isEmpty(originConfigData) &&
      !isEmpty(configData)
        ? setIsEdit(true)
        : setIsEdit(false);
      activeMenu?.id === 'feeds' &&
        !isEqual(appData, originGeneralData) &&
        !isEmpty(appData) &&
        !isEmpty(originGeneralData) &&
        setIsEdit(true);
      activeMenu?.id === 'objectives' &&
        !isEqual(measurementUnitData, originMeasurementUnitData) &&
        setIsEdit(true);
    }
  }, [
    activeMenu,
    appData,
    configData,
    currentColor,
    originConfigData,
    measurementUnitData,
    originMeasurementUnitData
  ]);

  return (
    <>
      <HeaderPage
        titlePage={orgId ? appData?.name : getObjectiveLocale('Organization')}
        isHeaderComposer={orgId}
        backToUrl={orgId && '/organizations'}
      />
      <div className="flex px-[0px] overflow-hidden bg-n-000">
        <SidebarLeftMenuNavigator
          listMenu={listMenu}
          title={getObjectiveLocale('Configuration')}
          activeMenu={activeMenu?.id}
          customClass="w-[200px] mr-[40px]"
        />
        {activeMenu?.id === 'general' && (
          <GeneralV2 appData={appData} setAppData={setAppData} />
        )}
        {activeMenu?.id === 'feeds' && appType !== 'performance' && (
          <Feeds
            appData={appData}
            setAppData={setAppData}
            configParams={configParams}
          />
        )}
        {activeMenu?.id === 'behaviors' && (
          <Behavior configParams={configParams} />
        )}
        {activeMenu?.id === 'objectives' && appType !== 'culture' && (
          <Objectives configParams={configParams} />
        )}
        {activeMenu?.id === 'ongoing_review' && appType !== 'culture' && (
          <OnGoingReview configParams={configParams} />
        )}
        {activeMenu?.id === 'performance_review' && appType !== 'culture' && (
          <FormalReview configParams={configParams} />
        )}
        {activeMenu?.id === 'profile' && (
          <Profile appData={appData} configParams={configParams} />
        )}
        {activeMenu?.id === 'missions' && (
          <Missions configParams={configParams} />
        )}
        {activeMenu?.id === 'administration' && (
          <Administration configParams={configParams} />
        )}
        {activeMenu?.id === 'integration' && (
          <Integration configParams={configParams} />
        )}
      </div>
      <div className="w-[calc(100vw-64px)] absolute bottom-0 h-[72px] border-solid border-0 border-t border-n-400 bg-n-000 flex justify-around z-index-10">
        <div className="w-[868px] flex items-center">
          <div className="flex flex-col w-4/5">
            <div className="typography-paragraph">
              {isEdit ? `Editing ${activeMenu?.title}....` : 'No Changes'}
            </div>
            <div className="typography-h100 text-n-800">
              {getObjectiveLocale(
                'You need to save this changes first before editing another config'
              )}
            </div>
          </div>
          <div className="flex">
            <Button.Secondary
              onClick={() => cancelChanges()}
              customClass="mr-[8px]"
              disabled={!isEdit}
              datacy="button-cancel"
            >
              Cancel
            </Button.Secondary>
            <Button
              onClick={() => edit()}
              disabled={!isEdit}
              datacy="button-save"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      {modal?.isShow && (
        <Modal
          title={getObjectiveLocale('Unsaved Changes')}
          description={getObjectiveLocale(
            "Are you sure want to leave this tab without saving changes? The changes will be discarded if you don't save it."
          )}
          eventOnClickClose={() =>
            setModal({ isShow: false, currentMenu: null })
          }
          className="w-[400px]"
          withPrimaryBtn={{
            title: getObjectiveLocale('Save'),
            dataCy: 'btn-save-config',
            onClick: () => edit(modal?.currentMenu)
          }}
          withSecondaryBtn={{
            title: getObjectiveLocale('Discard unsaved changes'),
            dataCy: 'btn-withous-save',
            onClick: () => {
              cancelChanges(),
                setActiveMenu(modal?.currentMenu),
                setModal({ isShow: false, currentMenu: null });
            }
          }}
        />
      )}
    </>
  );
};

export default Organization;

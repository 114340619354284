import React from 'react';

import produce, { setAutoFreeze } from 'immer';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

import { getCycle, updateCycle } from 'client/admin/CyclesClient';
import { _getConfirmData } from 'utils/EditCycleHelper';
import { modifyPhaseData } from 'utils/FormalReview';

//CREATE CONTEXT
const EditCycleContext = React.createContext();

//PROVIDER
function EditCycleProvider(props) {
  //INITIAL STATE
  const initialState = {
    cycle: { name: '' },
    editedCycle: { name: '' },
    isDiff: false,
    confirmData: []
  };

  const [state, setState] = React.useState(initialState);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];

  return <EditCycleContext.Provider value={contextValue} {...props} />;
}

//MUTATION
function useEditCycle() {
  const [stateData, immerSetState] = React.useContext(EditCycleContext);

  const configuredPhases = [
    { id: stateData.editedCycle.selfReview ? 'selfReview' : null },
    { id: stateData.editedCycle.managerReview ? 'managerReview' : null },
    { id: stateData.editedCycle.peerReview ? 'peerReview' : null },
    { id: stateData.editedCycle.upwardReview ? 'upwardReview' : null },
    {
      id: stateData.editedCycle.indirectManagerReview
        ? 'indirectManagerReview'
        : null
    }
  ].filter((phase) => phase.id !== null);

  const getCycleData = async (id) => {
    const { data } = await getCycle(id);

    const cycleData = {
      id: data?.id,
      name: data.name,
      restrictedTrackNavigation: data.restrictedTrackNavigation,
      reviewProcessStartsAt: data.reviewProcessStartsAt,
      reviewProcessEndsAt: data.reviewProcessEndsAt,
      selfReview: modifyPhaseData(data.selfReview),
      peerReview: modifyPhaseData(data.peerReview),
      upwardReview: modifyPhaseData(data.upwardReview),
      managerReview: modifyPhaseData(data.managerReview),
      indirectManagerReview: modifyPhaseData(data.indirectManagerReview)
    };

    immerSetState((draft) => {
      (draft.cycle = { ...cycleData }), (draft.editedCycle = { ...cycleData });
    });

    return data;
  };

  const findDifference = (object, base) => {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? findDifference(value, base[key])
            : value;
      }
    });
  };

  const editCycle = async (cycle) => {
    const diff = findDifference(cycle, stateData.cycle);

    const { data } = await updateCycle(cycle?.id, diff);
    if (data) {
      immerSetState((draft) => {
        (draft.cycle = data), (draft.editedCycle = data);
      });
    }
  };

  const getConfirmData = () => {
    const data = _getConfirmData(stateData, configuredPhases);
    immerSetState((draft) => {
      draft.confirmData = data;
    });
  };

  const changeEditedCycle = (key, value) => {
    immerSetState((draft) => {
      draft.editedCycle = { ...draft.editedCycle, [key]: value };
    });
  };

  const checkDifference = () => {
    immerSetState((draft) => {
      draft.isDiff = !isEqual(stateData.cycle, stateData.editedCycle);
    });
  };

  const changePhaseSchedule = (phaseKey, startDate, endDate) => {
    changeEditedCycle(phaseKey, {
      ...stateData.editedCycle[phaseKey],
      startsAt: startDate || stateData.editedCycle[phaseKey].startsAt,
      endsAt: endDate || stateData.editedCycle[phaseKey].endsAt
    });
  };

  return {
    stateData,
    configuredPhases,
    getCycleData,
    changeEditedCycle,
    checkDifference,
    editCycle,
    changePhaseSchedule,
    getConfirmData
  };
}

export { EditCycleProvider, useEditCycle };

import React, { useEffect, useState } from 'react';

import { useFormalReview } from 'context/FormalReviewContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SelectCompetencies from 'components/formal-review/SelectCompetencies.js';

import './RecognitionFeedback.scss';

const RecognitionFeedback = () => {
  const [{ currentTrack }, { getSingleTrack }] = useFormalReview();

  const track = getSingleTrack(currentTrack);
  let { template, objects, answers } = track || {};

  const [recognitionFeedback, setRecognitionFeedback] = useState([]);
  const [localStateAnswers, setLocalStateAnswers] = useState(answers);

  useEffect(() => {
    let mechanismArray;

    let recognitionObject = {
      type: 'recognition',
      objects: objects,
      ...template
    };
    let feedbackObject = {
      type: 'feedback',
      objects: objects,
      ...template
    };

    if (template.mechanism === 'recognition_feedback') {
      mechanismArray = [recognitionObject, feedbackObject];
    } else if (template.mechanism === 'recognition_only') {
      mechanismArray = [recognitionObject];
    } else if (template.mechanism === 'feedback_only') {
      mechanismArray = [feedbackObject];
    }
    setRecognitionFeedback(mechanismArray);
  }, []);

  return (
    <div className="recognition-feedback-container mt-[64px]">
      <div className="select-competencies-container">
        {/* RECOGNITION AND FEEDBACK : SELECT COMPETENCIES */}
        <h5 className="question-text">
          {getObjectiveLocale(
            `[${currentTrack.replace(
              /_/g,
              ' '
            )}] Kompetensi perilaku apa yang harus diperbaiki oleh karyawan?`
          )}
        </h5>
        <div className="competency-recognition-feedback-container">
          {recognitionFeedback &&
            recognitionFeedback.map((rfObject) => {
              return (
                <SelectCompetencies
                  rfObject={rfObject}
                  key={rfObject.type}
                  objects={objects}
                  localStateAnswers={localStateAnswers}
                  setLocalStateAnswers={setLocalStateAnswers}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default RecognitionFeedback;

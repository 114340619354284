import React from 'react';

import S3Client from 'client/S3Client';
import { getPerformancePreSignUrl } from 'client/UploadClient';
import Config from 'utils/Config';

import AWSImage from 'components/design-system/AWSImage';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

const listHashPresignImageProd = [
  '305c10bf-eedc-4df3-88ca-0ec96e794e5e', //presign 1
  '29ce063d-9ae7-49d5-8064-30cdaf19b460', //presign 2
  '8f3f1381-071d-4420-a268-19678ba054f1' //presign 3
];

const listHashPresignImageStaging = [
  'f7a8a1de-5495-4eb7-9c06-95129ba44aaf', //presign 1
  '959bc8cf-857d-4d14-89a7-da7340f93bed', //presign 2
  '4a58f870-7292-4c49-af7d-ec7ce1c441fa' //presign 3
];

const ManageTeamProfile = ({ teamValue, updateImage, disabled }) => {
  const isProductionENV = process.env.NODE_ENV === 'production';
  const listHashPresignImage = isProductionENV
    ? listHashPresignImageProd
    : listHashPresignImageStaging;
  const envUrl = isProductionENV ? 'production' : 'staging';

  const _getPreSignUrl = async () => {
    const presign_query = {
      directory: 'profiles',
      extension: 'png',
      public: true
    };
    const { data } = await getPerformancePreSignUrl(presign_query);
    return data;
  };

  const urlImage = (hashImage) => {
    return `https://storage.googleapis.com/${envUrl}-performance-happy5/profiles/happy5-${
      isProductionENV ? '1' : '5'
    }/${hashImage}.png`;
  };

  const forceCloseDropdown = () => {
    document.getElementById('team-profile').click();
  };

  const selectPresignImage = (hashImage) => {
    updateImage(urlImage(hashImage));
    forceCloseDropdown();
  };

  const handleChange = async (e) => {
    let file = e.target.files[0];

    if (file) {
      if (
        file.type == 'image/png' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/jpg'
      ) {
        let presignData = await _getPreSignUrl(file);
        if (presignData) {
          const { status } = await S3Client(presignData.uploadUrl, file);

          if (status == 200) {
            updateImage(presignData.downloadUrl);
            if (document.getElementById('change-profile')) {
              document.getElementById('change-profile').value = '';
              forceCloseDropdown();
            }
          }
        } else {
          alert('Presign Link Not Found!');
        }
      } else {
        alert('Wrong file type! file type must be png, jpg or jpeg!');
        return false;
      }
    } else {
      alert('Empty file image');
    }
  };

  return (
    <Dropdown>
      <Dropdown.Trigger disabled={disabled}>
        <AWSImage
          imageId="team-profile"
          className={`w-[80px] h-[80px] ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          }`}
          src={teamValue?.teamPicture || Config.asset.general.brokenImage}
          dataCy="team-profile"
        />
      </Dropdown.Trigger>
      <Dropdown.MenuItems>
        <Dropdown.MenuItem customClass="h-[96px] w-[376px]" useHover={false}>
          {listHashPresignImage.map((hashImage, index) => (
            <AWSImage
              imageId={`team-profile-presign-${index}`}
              key={`team-profile-presign-${index}`}
              dataCy={`team-profile-presign-${index}`}
              className="w-[80px] h-[80px] mr-[8px] cursor-pointer"
              src={urlImage(hashImage)}
              onClick={() => selectPresignImage(hashImage)}
            />
          ))}
          <label
            htmlFor="change-profile"
            className="w-[80px] h-[80px] cursor-pointer flex items-center border-dashed border border-n-400 border-radius-4 justify-center"
          >
            <SVGIcon
              iconName="icon-camera_alt"
              size="24"
              fillColor="var(--n-600)"
              onClick={() => {}}
            />
          </label>
        </Dropdown.MenuItem>
      </Dropdown.MenuItems>
      <input
        type="file"
        name="change-profile"
        id="change-profile"
        className="hidden"
        onChange={(e) => handleChange(e)}
      />
    </Dropdown>
  );
};

export default ManageTeamProfile;

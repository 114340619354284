import React from 'react';

import dayjs from 'dayjs';

import { getDateWithForcedTimezone } from 'utils/DateUtils';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

const DateSection = ({ objectiveDetail }) => {
  const { startDate, dueDate, recurrence } = objectiveDetail;

  const getRepetition = () => {
    let text;

    if (recurrence.every == 1) {
      switch (recurrence.type) {
        case 'day':
          text = 'Daily';
          break;
        case 'week':
          text = 'Weekly';
          break;
        case 'month':
          text = 'Monthly';
          break;
        case 'year':
          text = 'Yearly';
          break;
        default:
          text = 'Does Not Repeat';
          break;
      }
    } else {
      text = `${recurrence.every} ${recurrence.type}s`;
    }

    return text;
  };

  const getNextOcurrenceText = () => {
    let recurrenceEndDate =
      recurrence &&
      getDateWithForcedTimezone(
        dayjs(dueDate).add(parseInt(recurrence.every), `${recurrence.type}s`)
      );

    let text =
      'Next occurrence will start on ' + getDateLocale(recurrence.nextDate);
    if (recurrenceEndDate) {
      text += ` end on ${getDateLocale(recurrenceEndDate)}`;
    }

    return text;
  };

  return (
    <div className="px-[24px]">
      <div className="h-[48px] flex items-center">
        <SVGIcon
          iconName="icon-today"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <span data-cy="sidebar-detail-period">
          {getDateLocale(getDateWithForcedTimezone(startDate))}{' '}
          {` - ${
            dueDate
              ? getDateLocale(getDateWithForcedTimezone(dueDate))
              : getObjectiveLocale('No Due Date')
          } `}
        </span>
      </div>
      {recurrence && (
        <div className="pl-[40px] pr-[24px]">
          <div className="flex">
            <span className="mr-[4px]">
              {getObjectiveLocale('Objective Repetition')}:
            </span>
            <span className="text-v-600">{getRepetition()}</span>
          </div>
          <span className="typography-h100" data-cy="sidebar-detail-recurrence">
            {getNextOcurrenceText()}
          </span>
        </div>
      )}
    </div>
  );
};

export default DateSection;

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useUser } from 'context/UserContext';

import HeaderPage from 'components/admin/HeaderPage';
import AdminTabWithCount from 'components/shared/Tabs/AdminTabWithCount.js';

import ListLeaderboard from './ListLeaderboard';
import ListMission from './ListMission';
import ListBadge from './badge/ListBadge';

function MissionLeaderboard({ route: { title, isReadOnly } }) {
  const { config } = useUser();
  const [currentTab, setTab] = useState('mission');
  const history = useHistory();
  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');

  const changeTab = (currentTab) => {
    history.replace(`/missions?tab=${currentTab}`);
    setTab(currentTab);
  };
  const headerProps = {
    titlePage: title,
    primaryAction: currentTab == 'mission' &&
      !isReadOnly && {
        title: 'Create Mission',
        icon: 'icon-add',
        onClick: () => history.push({ pathname: '/missions/create-mission' })
      }
  };

  const positionsList = [
    {
      id: 'mission',
      name: 'Mission',
      isVisible: config?.permissions?.cultureSettingsMissionsSee
    },
    {
      id: 'leaderboard',
      name: 'Leaderboard',
      isVisible: config?.permissions?.cultureSettingsLeaguesSee
    },
    {
      id: 'badge',
      name: 'Badge',
      isVisible: config?.permissions?.cultureSettingsBadgeSee
    }
  ].filter((opt) => opt.isVisible);

  useEffect(() => {
    if (tabParams == 'leaderboard') {
      setTab('leaderboard');
    } else if (tabParams == 'badge') {
      setTab('badge');
    }
  }, [currentTab]);

  return (
    <div className="bg-n-000 h-full overflow-auto">
      <HeaderPage
        {...headerProps}
        tabs={{
          tabsList: positionsList,
          url: '/missions',
          onChange: (tab) => {
            setTab(tab);
          }
        }}
      />
      <div className="px-[40px] mt-[10px]">
        {currentTab == 'mission' && <ListMission isReadOnly={isReadOnly} />}
        {currentTab == 'leaderboard' && <ListLeaderboard />}
        {currentTab == 'badge' && <ListBadge />}
      </div>
    </div>
  );
}

export default MissionLeaderboard;

import { format } from 'timeago.js';

import { getObjectiveLocale } from 'utils/HelperUtils';

let sortOptions = [
  {
    direction: 'desc',
    id: 1,
    name: `${getObjectiveLocale('Newest')}`,
    type: 'created_at',
    visible: true
  },
  {
    direction: 'asc',
    id: 2,
    name: `${getObjectiveLocale('Oldest')}`,
    type: 'created_at',
    visible: true
  },
  {
    direction: 'asc',
    id: 3,
    name: `${getObjectiveLocale('A to Z')}`,
    type: 'name',
    visible: true
  },
  {
    direction: 'desc',
    id: 4,
    name: `${getObjectiveLocale('Z to A')}`,
    type: 'name',
    visible: true
  }
];
const sortOptionTypes = [...new Set(sortOptions.map((opt) => opt.type))];

const defaultFilter = (defaultObjectiveSorting) => {
  return {
    q: '',
    sortColumn: sortOptionTypes.includes(defaultObjectiveSorting?.sortColumn)
      ? defaultObjectiveSorting?.sortColumn
      : 'created_at',
    sortDirection: defaultObjectiveSorting?.sortDirection || 'desc'
  };
};

const getLeader = (involvements) => {
  return involvements?.find(
    ({ role, extendedRole }) => role == 'assignee' && extendedRole === 'leader'
  );
};

const getMembers = (involvements) => {
  return involvements?.filter(({ role }) => role != 'leader');
};

const getCurrentUserRole = (involvements, currentUserId) => {
  const userInvolvement = involvements.find(
    ({ user }) => user.id == currentUserId
  );

  // ** As leader
  if (
    userInvolvement?.role == 'assignee' &&
    userInvolvement?.extendedRole == 'leader'
  ) {
    return 'leader';
  }

  // ** As reviewer
  if (userInvolvement?.role === 'assigner') {
    return 'reviewer';
  }

  // ** As member
  if (
    userInvolvement?.role === 'assignee' &&
    userInvolvement?.extendedRole !== 'leader' &&
    userInvolvement?.extendedRole !== 'pmo'
  ) {
    return 'member';
  }

  // ** As stakeholder
  if (
    userInvolvement?.role == 'follower' &&
    userInvolvement?.extendedRole == null
  ) {
    return 'stakeholder';
  }

  // ** As pmo
  if (
    userInvolvement?.role === 'follower' &&
    userInvolvement?.extendedRole === 'pmo'
  ) {
    return 'pmo';
  }
};

export {
  defaultFilter,
  sortOptions,
  getLeader,
  getMembers,
  getCurrentUserRole
};

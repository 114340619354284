import React from 'react';

import Avatar from 'components/shared/Avatar';
import UserInfoComponent from 'oldComponents/UserInfoComponent';
import { getExtraInformation } from 'src/utils/HelperUtils';

const FormUserComponent = (props) => {
  const {
    data,
    onClick,
    children,
    data: { workloadStatus: workload, name, profilePicture: image }
  } = props;
  let { objectiveCount } = data;

  if (parseInt(objectiveCount) > 1) {
    objectiveCount += ' Tasks & Goals';
  } else if (parseInt(objectiveCount) === 1) {
    objectiveCount += ' Task / Goal';
  } else {
    objectiveCount = '';
  }

  return (
    <div
      className="userbar-container cursor-pointer no-selected"
      onClick={onClick}
    >
      <Avatar className={workload} src={image} name={name} size={40} />
      <UserInfoComponent
        name={name}
        description={getExtraInformation(data?.title, data?.subtitle)}
      >
        <div className="form-group-divider" />
      </UserInfoComponent>
      {children}
    </div>
  );
};

FormUserComponent.defaultProps = {
  type: 'search',
  subtype: false
};

export default FormUserComponent;

import React from 'react';

import dayjs from 'dayjs';

import {
  abbrevNumber,
  getNumberLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';
import { getInfoIconMilestones } from 'utils/MilestoneHelper';
import { whichBgColorClassName } from 'utils/ObjectivesHelper';

import SVGIcon from 'components/shared/SVGIcon';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';
import Tooltips from 'components/shared/Tooltips/Tooltips';

function MilestoneDetailCard({ currentMilestone, useCompactDesign = false }) {
  return (
    <div className={`flex ${useCompactDesign && 'justify-between py-[10px]'}`}>
      <div className={`flex-col flex ${!useCompactDesign ? 'w-[128px]' : ''}`}>
        <p className="typography-h200 mb-[4px]">
          {currentMilestone?.periodName}
        </p>
        <p className="typography-h100 color-n-600">
          {dayjs(currentMilestone?.startsAt).format('DD MMM')}-
          {dayjs(currentMilestone?.endsBefore).format('DD MMM')}
        </p>
      </div>
      <div className="flex-col">
        <div className="flex content-center">
          {currentMilestone?.progressState !== 'not_started' && (
            <>
              <LineProgressBar
                progress={currentMilestone?.progressPercentage}
                width={75}
                primaryColor={whichBgColorClassName(
                  currentMilestone?.progressColorHex
                )}
                fullFirst
                height={8}
                classLine="mt-[4px]"
              />
              <p className="typography-h200 w-[56px] mx-[8px] text-right">
                {currentMilestone?.progressPercentage}%
              </p>
            </>
          )}
          {currentMilestone?.progressState == 'not_started' ? (
            <div className="notstarted-milestone w-[168px] h-[36px] bg-n-200 px-[24px] py-[12px] flex items-center">
              <SVGIcon
                size="16"
                iconName="icon-lock_outline"
                fillColor="var(--n-600)"
                customClass={useCompactDesign ? 'pr-[4px]' : 'pr-[8px]'}
              />
              <p className="typography-h100">
                {getObjectiveLocale('Not yet started')}
              </p>
            </div>
          ) : (
            getInfoIconMilestones(currentMilestone)
          )}
        </div>
        {currentMilestone?.progressState != 'not_started' && (
          <Tooltips
            isEllipsis
            tooltipClass="px-[12px] py-[4px] height-auto"
            text={`${getNumberLocale(
              currentMilestone?.currentValue
            )} / ${getNumberLocale(currentMilestone?.targetValue)}`}
          >
            <p
              className={`typography-h100 color-n-800 ${
                !useCompactDesign ? 'mt-[4px]' : ''
              }`}
            >
              {abbrevNumber(currentMilestone?.currentValue)} /{' '}
              {abbrevNumber(currentMilestone?.targetValue)}
            </p>
          </Tooltips>
        )}
      </div>
    </div>
  );
}

export default MilestoneDetailCard;

import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import { exportObjectiveCSV } from 'client/ObjectivesClient';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Card from 'components/design-system/Card';
import Table from 'components/design-system/table/Table';
import RadioButton from 'components/shared/RadioButton';
import SVGIcon from 'components/shared/SVGIcon';
import ToastNotif from 'components/shared/ToastNotif';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import Modal from 'components/shared/modal/Modal';

const BreakdownProgressOvertime = ({
  progressData,
  selectedTimeFrame,
  selectedDataPeriod,
  id,
  objectiveData
}) => {
  const [isShowNotif, setIsShowNotif] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [csvPeriod, setCsvPeriod] = useState(false);
  const [exportData, setExportData] = useState([]);
  const ref = useRef();
  const currentDate = getDateLocale(new Date(), {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
  const csvPeriodOption = [
    { id: 'all', title: 'All time' },
    { id: 'selected', title: 'Selected year' }
  ];

  let headers = [];
  const defaultWidth =
    selectedTimeFrame === 'quarterly' || selectedTimeFrame === 'semesterly'
      ? 'flex-[1] justify-left'
      : 'w-[104px] justify-left';

  if (progressData.length > 0) {
    Object.keys(progressData?.[0]).map((key) => {
      headers.push({
        name: key,
        isHideTitleHeader: key === 'name',
        widthClass: key === 'name' ? 'w-[144px] pl-[24px]' : defaultWidth,
        childCustomClass: 'typography-h300 text-n-800',
        useHeaderMargin: false,
        grow: true
      });
    });
  }

  const handleExportCSV = async () => {
    setIsShowNotif(true);
    const { response } = await exportObjectiveCSV(id, {
      timeFrame: selectedTimeFrame,
      year: csvPeriod === 'selected' ? selectedDataPeriod : null,
      isCsv: true
    });
    setExportData(response?.data);
    ref.current.click();
    setIsShowNotif(false);
    setShowModal(false);
  };

  const ExportCSV = () => {
    return (
      <Button.Secondary
        customClass="mr-[24px]"
        onClick={() => setShowModal(!showModal)}
        datacy="export-csv-progress-overtime"
      >
        <SVGIcon
          size="24"
          fillColor="var(--n-600)"
          iconName="icon-file_download"
          customClass="mr-[4px]"
        />
        {getObjectiveLocale('Export CSV')}
      </Button.Secondary>
    );
  };

  return (
    <>
      <ToastNotif
        showNotif={isShowNotif}
        message={getObjectiveLocale('Exporting csv progress...')}
      />
      {showModal && (
        <Modal
          title="Export CSV"
          className="w-[400px]"
          dataCyModal="export-csv-progress-overtime-modal"
          eventOnClickClose={() => setShowModal(false)}
          withPrimaryBtn={{
            title: 'Export',
            dataCy: 'export-csv-overtime',
            onClick: () => handleExportCSV()
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            dataCy: 'cancel-csv-overtime',
            onClick: () => setShowModal(false)
          }}
        >
          <p className="mb-[16px] typography-h100 text-n-800">
            {getObjectiveLocale('Please choose the period to be exported')}
          </p>
          {csvPeriodOption.map((period) => (
            <RadioButton
              key={period.id}
              name={`csv-option-${period.id}`}
              dataCy={`csv-option-${period.id}`}
              checked={period.id === csvPeriod}
              onClick={(e) => {
                e.stopPropagation(), setCsvPeriod(period?.id);
              }}
              addClass="mb-[8px]"
              labelClass="typography-paragraph ml-[8px] h-[24px] text-n-900"
            >
              {period.title}
            </RadioButton>
          ))}
        </Modal>
      )}
      <Card
        titleCard="Breakdown"
        customClass="mt-[24px] mx-[40px] mb-[169px]"
        useHorizontalPadding={false}
        rightComponent={<ExportCSV />}
      >
        <div className="w-full border-0 border-t-[1px] border-solid border-n-400">
          <Table headers={headers} fixedLeftCount={1} useGrow={true}>
            {progressData?.map((data) => {
              return (
                <Table.Row grow={true} key={data.name}>
                  {headers.map((header) => {
                    const isPercentageColumn = data?.name === '(%)';
                    const isNameColumn = header?.name === 'name';
                    const isPercentageRow =
                      isPercentageColumn && data?.[header?.name] !== '(%)';

                    return (
                      <Table.Column
                        key={header.name}
                        grow={true}
                        dataCy={`${data?.name}-${header?.name}`}
                        customClass={`
                              ${
                                isNameColumn ||
                                selectedTimeFrame === 'quarterly' ||
                                selectedTimeFrame === 'semesterly'
                                  ? 'justify-left pl-[24px]'
                                  : 'justify-left'
                              }
                              ${
                                isPercentageColumn || isNameColumn
                                  ? progressData?.[0]?.[header?.name] -
                                      progressData?.[1]?.[header?.name] <
                                    0
                                    ? 'text-r-600'
                                    : 'text-n-900'
                                  : 'text-n-800'
                              }
                              ${
                                isPercentageRow || isNameColumn
                                  ? 'typography-h400'
                                  : 'typography-paragraph'
                              }
                          `}
                      >
                        <TooltipContainer
                          show={data?.[header?.name]?.length > 7}
                          position="top"
                          text={`${getObjectiveLocale(data?.[header?.name])}${
                            isPercentageRow ? '%' : ''
                          }`}
                          key={`${header?.name}-${data?.[header?.name]}`}
                          useMaxWidth={false}
                        >
                          <p className="multiline-text-1">
                            {getObjectiveLocale(data?.[header?.name])}
                            {isPercentageRow ? '%' : ''}
                          </p>
                        </TooltipContainer>
                      </Table.Column>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table>
        </div>
      </Card>
      <CSVLink
        data={exportData}
        filename={`${objectiveData?.name}_${
          csvPeriod === 'all' ? 'All' : selectedDataPeriod
        }_${currentDate}.csv`}
      >
        <span ref={ref}></span>
      </CSVLink>
    </>
  );
};

export default BreakdownProgressOvertime;

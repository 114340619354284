import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Tabs from 'components/design-system/tabs/Tabs';
import LoadingComponent from 'components/shared/LoadingComponent';
import TruncateParagraph from 'components/shared/TruncateParagraph';
import { getPeerRevieweeSelections } from 'src/client/FormalReviewClient';

import UserCardRow from './components/UserCardRow';

const ModalPeerRevieweeTab = ({ cycleId, target }) => {
  const [revieweeData, setRevieweeData] = useState([]);
  const fetchPeerRevieweeList = () => {
    return getPeerRevieweeSelections(cycleId, target.id);
  };
  const { isFetching } = useQuery(
    ['peer_assignment_reviewee_list', cycleId, target.id],
    ({ pageParam }) => fetchPeerRevieweeList(pageParam),
    {
      enabled: true,
      refetchOnMount: true,
      onSuccess: ({ data }) => setRevieweeData(data)
    }
  );

  return (
    <Tabs.Content id="reviewee-tab">
      <div className="mt-[24px]">
        <TruncateParagraph
          position="bottom"
          useMaxWidth={true}
          className="mt-[2px] uppercase typography-h300 text-n-800 px-[24px]"
        >
          {getObjectiveLocale('People Who Will Be Reviewed By [user]').replace(
            '[user]',
            target.name
          )}
        </TruncateParagraph>
        <div className="px-[8px] py-[14px]">
          {isFetching && <LoadingComponent />}
          {revieweeData.length > 0 &&
            revieweeData?.map((reviewee) => {
              const data =
                target.id == reviewee?.target?.id
                  ? reviewee?.actor
                  : reviewee?.target;
              return <UserCardRow key={data?.id} userdata={data} />;
            })}
        </div>
      </div>
    </Tabs.Content>
  );
};

export default ModalPeerRevieweeTab;

import React, { useMemo, useState } from 'react';

import dayjs from 'dayjs';

import { useLocale } from 'hooks/useLocale';
import usePulseSurvey from 'hooks/usePulseSurvey';

import BannerBox from 'components/shared/BannerBox';
import RadioButton from 'components/shared/RadioButton';
import ToggledCardAccordion from 'src/components/shared/ToggledCardAccordion';

import RecurringCard from './component/RecurringCard';
import ScheduleRow from './component/ScheduleRow';
import DateSelector from './fragments/DateSelector';

const SurveySchedule = ({ isReadOnly }) => {
  const { setBothLocale } = useLocale();
  const {
    scheduleType,
    setScheduleType,
    surveyData,
    setSurveyData,
    setDesignatedUntilDate
  } = usePulseSurvey();
  const startTime = surveyData?.startTime;
  const expiration = surveyData?.expiration;
  const [dateRange, setDateRange] = useState({
    start: startTime,
    due: expiration
  });
  const { recurrence: recurrenceData } = surveyData;
  const [isRecurring, setRecurring] = useState(
    Object.keys(recurrenceData, surveyData).length > 0
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isReadOnlyStatic = useMemo(() => isReadOnly, []);

  const changeRadio = (type) => {
    const date = new Date();
    setSurveyData({
      ...surveyData,
      startTime: dayjs(date).toISOString(),
      expiration: dayjs(date).add(1, 'days').toISOString()
    });
    setScheduleType(type);
  };

  const changeDateList = (date) => {
    if (isReadOnlyStatic) return;
    const startTime = dayjs(date.start).toISOString();
    const expiration = dayjs(date.due).toISOString();
    setDateRange(date);
    setSurveyData({
      ...surveyData,
      startTime,
      expiration
    });
    if (!isRecurring) return;
    const { every: repetition, interval } = recurrenceData;
    setDesignatedUntilDate({ startTime, expiration, repetition, interval });
  };

  const recurringToggle = (value) => {
    setRecurring(value);
    let recurrenceValue = null;
    if (value) {
      recurrenceValue = { every: 'day', interval: 1 };
      setDesignatedUntilDate({
        startTime,
        expiration,
        repetition: 'day',
        interval: 1
      });
    }
    setSurveyData({
      ...surveyData,
      recurrence: recurrenceValue
        ? { ...surveyData.recurrence, ...recurrenceValue }
        : {}
    });
  };

  return (
    <>
      <p className="typography-h500 text-n-900">
        {setBothLocale('Set schedule')}
      </p>

      <div className="mt-[16px]">
        <p className="typography-paraghraph text-n-800">
          {setBothLocale('When would you like to send this pulse survey?')}
        </p>
        <div>
          <RadioButton
            name="send-now"
            checked={scheduleType == 'now'}
            onClick={() => changeRadio('now')}
            addClass="mt-[16px] w-fit"
            disabled={isReadOnlyStatic}
            dataCyRadio="send-now"
          >
            <span className="ml-[8px] typography-paragraph text-n-900">
              {setBothLocale('Send pulse survey now')}
            </span>
          </RadioButton>
          {scheduleType == 'now' && (
            <div className="!ml-[30px] p-[16px] mt-[12px] bg-n-200 w-[800px] !rounded-lg">
              <BannerBox
                type="info"
                customClass="!rounded-lg"
                iconClass="self-start mr-[8px]"
                iconSize={24}
              >
                {setBothLocale(
                  'System will send notifications when the pulse survey start and as well as a reminder 1 hour before the due date'
                )}
              </BannerBox>
              <div className="flex">
                <DateSelector
                  isReadOnly={isReadOnlyStatic}
                  selectedDate={expiration}
                  callbackFn={(expiration) =>
                    setSurveyData({ ...surveyData, expiration: expiration })
                  }
                />
              </div>
            </div>
          )}
          <RadioButton
            name="custom"
            checked={scheduleType == 'custom'}
            onClick={() => changeRadio('custom')}
            disabled={isReadOnlyStatic}
            dataCyRadio="send-custom"
            addClass="mt-[16px] w-fit"
          >
            <span className="ml-[8px] typography-paragraph text-n-900">
              {setBothLocale('Set custom schedule')}
            </span>
          </RadioButton>
        </div>
        {scheduleType == 'custom' && (
          <div className=" ml-[30px] p-[16px] mt-[12px] bg-n-200 w-[800px] rounded-lg">
            <BannerBox
              type="info"
              customClass="!rounded-lg"
              iconClass="self-start mr-[8px]"
              iconSize={24}
            >
              {setBothLocale(
                'You can still edit your pulse survey details until pulse survey start. When the pulse survey start system will send you notifications and as well as a reminder 1 hour before the due date.'
              )}
            </BannerBox>
            <ScheduleRow
              isReadOnly={isReadOnlyStatic}
              id={0}
              changeDateList={changeDateList}
              date={dateRange}
            />
            <ToggledCardAccordion
              title="Recurring Survey"
              subTitle={
                'Set up your survey schedule to set your recurrence setting'
              }
              toggleCallback={recurringToggle}
              isOpen={isRecurring}
              clearContentSpace
              isReadOnly={isReadOnlyStatic}
            >
              <RecurringCard isReadOnly={isReadOnlyStatic} />
            </ToggledCardAccordion>
          </div>
        )}
      </div>
    </>
  );
};

export default SurveySchedule;

import React from 'react';

import WeightSettingsCheckbox from '../fragments/WeightSettingsCheckbox';
import WeightSettingsObjCategoriesTable from './WeightSettingsObjCategoriesTable';

const WeightSettingsObjCategories = ({ changeConfigData, useDefaultType }) => {
  const checkboxToggleCallback = async (value) => {
    return await changeConfigData({ useDefaultObjectiveCategory: value });
  };

  return (
    <div className="px-[24px] pb-[24px] pt-[20px]">
      <WeightSettingsCheckbox
        label="Use default value"
        onChangeCallback={checkboxToggleCallback}
        defaultValue={useDefaultType}
      />
      <WeightSettingsObjCategoriesTable useDefaultType={useDefaultType} />
    </div>
  );
};

export default WeightSettingsObjCategories;

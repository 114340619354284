import React, { useEffect, useState } from 'react';

import { useSidebarMenu } from 'context/SidebarMenuContext';
import useTasks from 'hooks/useTasks';

import Accordion, {
  useAccordion
} from 'components/design-system/accordion/Accordion';
import AddGroup from 'components/project/detail/AddGroup';
import AddSection from 'components/project/detail/AddSection';
import InputSectionName from 'components/project/detail/InputSectionName';
import SectionOptions from 'components/project/detail/SectionOptions';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';
import BoardCards from 'components/tasks/board/BoardCards';
import BoardHeader from 'components/tasks/board/BoardHeader';
import useIsFetchingTasks from 'src/hooks/useIsFetchingTasks';

const Boards = ({
  filter,
  currentGroup,
  sections = [],
  draggedId,
  setDraggedId,
  isProject,
  projectId,
  currentSectionIndex,
  setCurrentSectionIndex,
  setShowModalDeleteSection,
  boardXScrollbarVisible = false,
  isDataReady,
  listMetrics = []
}) => {
  const [draggable, setDraggable] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [addAbove, setAddAbove] = useState(false);

  const { addNewTaskOneLevel, addNewTaskTwoLevels, setAction } = useTasks();

  const shadowStyle = {
    boxShadow:
      '0px 1px 2px rgba(15, 23, 42, 0.06), 0px 1px 3px rgba(15, 23, 42, 0.1)'
  };

  const handleAddTask = (groupName, sectionName, position) => {
    isProject
      ? addNewTaskTwoLevels(groupName, sectionName, position)
      : addNewTaskOneLevel(groupName, position);
    setAction('create');
  };

  return (
    <div
      className={`flex w-full px-[40px] pb-[24px] ${
        isProject
          ? 'h-full'
          : boardXScrollbarVisible
          ? 'max-h-[calc(100vh-282px)]'
          : 'max-h-[calc(100vh-272px)]'
      }`}
    >
      {isProject ? (
        <div className="flex flex-col">
          <div className="flex">
            {currentGroup?.length > 0 &&
              currentGroup.map(({ name }, index) => (
                <div
                  className={`grow-0 flex-shrink-0 items-center w-[360px] ${
                    index === 0 ? '' : 'ml-[24px]'
                  }`}
                  key={index}
                >
                  <BoardHeader
                    groupName={name}
                    groupIndex={index}
                    shadowStyle={shadowStyle}
                    projectId={projectId}
                    isProject={isProject}
                  />
                </div>
              ))}
            {filter?.group === 'phase' && (
              <AddGroup projectId={projectId} type="board" />
            )}
          </div>
          {sections?.length > 0 &&
            sections.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                {isAdding &&
                  addAbove &&
                  currentSectionIndex === sectionIndex && (
                    <AddSection
                      sectionIndex={currentSectionIndex}
                      projectId={projectId}
                      isShowing={isAdding}
                      setIsShowing={setIsAdding}
                      addAbove={addAbove}
                      customClass={`left-[40px] ${
                        sectionIndex === 0 ? 'mb-[16px]' : 'mt-[16px]'
                      }`}
                    />
                  )}
                <Accordion
                  useBgColor={false}
                  customClass={sectionIndex === 0 ? '' : 'mt-[16px]'}
                >
                  <Accordion.Header
                    useCustomHeader
                    customWrapperClass="mb-[8px]"
                    useWrapperClick={false}
                  >
                    <BoardAccordionHeader
                      filter={filter}
                      projectId={projectId}
                      sectionName={section?.name}
                      sectionIndex={sectionIndex}
                      setIsAdding={setIsAdding}
                      setAddAbove={setAddAbove}
                      setCurrentSectionIndex={setCurrentSectionIndex}
                      setShowModalDeleteSection={setShowModalDeleteSection}
                      groupName={currentGroup[0]?.name}
                      onClickAdd={() =>
                        handleAddTask(
                          currentGroup[0]?.name,
                          section?.name,
                          'top'
                        )
                      }
                      isProject={isProject}
                    />
                  </Accordion.Header>
                  <Accordion.Content customSpaceClass="flex px-[0px] py-[0px]">
                    {currentGroup?.length > 0 &&
                      currentGroup.map(({ name, id }, groupIndex) => (
                        <div
                          className={`flex flex-grow-0 flex-shrink-0 w-[360px] ${
                            groupIndex === 0 ? '' : 'ml-[24px]'
                          }`}
                          key={groupIndex}
                        >
                          <BoardCards
                            listMetrics={listMetrics}
                            filter={filter}
                            groupData={{
                              id: id,
                              name: name,
                              index: groupIndex
                            }}
                            sectionData={{
                              id: section?.id,
                              name: section?.name,
                              index: sectionIndex
                            }}
                            draggedId={draggedId}
                            setDraggedId={setDraggedId}
                            draggable={draggable}
                            setDraggable={setDraggable}
                            handleAddTask={() =>
                              handleAddTask(name, section?.name, 'bottom')
                            }
                            shadowStyle={shadowStyle}
                            isProject={isProject}
                            projectId={projectId}
                            isDataReady={isDataReady}
                          />
                        </div>
                      ))}
                  </Accordion.Content>
                </Accordion>
                {isAdding &&
                  !addAbove &&
                  currentSectionIndex === sectionIndex && (
                    <AddSection
                      sectionIndex={currentSectionIndex}
                      projectId={projectId}
                      isShowing={isAdding}
                      setIsShowing={setIsAdding}
                      addAbove={addAbove}
                      customClass={'left-[40px] mt-[16px]'}
                    />
                  )}
              </div>
            ))}
          <AddSection.Button projectId={projectId} type="board" />
        </div>
      ) : (
        currentGroup?.length > 0 &&
        currentGroup.map(({ name, id }, index) => (
          <div
            className={`flex flex-col flex-grow-0 flex-shrink-0 items-center w-[360px]
            ${index === 0 ? '' : 'ml-[24px]'}`}
            key={index}
          >
            <BoardHeader
              groupName={name}
              groupIndex={index}
              handleAddTask={() => handleAddTask(name, null, 'top')}
              shadowStyle={shadowStyle}
              isProject={isProject}
            />
            <BoardCards
              key={index}
              listMetrics={listMetrics}
              filter={filter}
              groupData={{
                id: id,
                name: name,
                index: index
              }}
              draggedId={draggedId}
              setDraggedId={setDraggedId}
              draggable={draggable}
              setDraggable={setDraggable}
              handleAddTask={() => handleAddTask(name, null, 'bottom')}
              shadowStyle={shadowStyle}
              isProject={isProject}
              projectId={projectId}
              isDataReady={isDataReady}
            />
          </div>
        ))
      )}
    </div>
  );
};

const BoardAccordionHeader = ({
  filter,
  projectId,
  sectionName,
  sectionIndex,
  setIsAdding,
  setAddAbove,
  setCurrentSectionIndex,
  setShowModalDeleteSection,
  onClickAdd = () => null,
  isProject,
  groupName
}) => {
  const isFetchingTasks = useIsFetchingTasks({
    isProject,
    groupName: groupName,
    sectionName: sectionName
  });
  const { listGroups } = useTasks();

  const { section } = listGroups;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [showAction, setShowAction] = useState(false);

  const { isMinimized } = useSidebarMenu();
  const { isAccordionOpen, setIsOpen, isOpen } = useAccordion();

  useEffect(() => {
    setIsOpen(true);
  }, [filter?.group, setIsOpen]);

  useEffect(() => {
    dialogOpen && setCurrentSectionIndex(sectionIndex);
  }, [dialogOpen, setCurrentSectionIndex, sectionIndex]);

  return (
    <div
      className="flex items-center left-[0px]"
      onMouseEnter={() => setShowAction(true)}
      onMouseLeave={() => setShowAction(false)}
    >
      <div
        className="flex items-center sticky left-[40px] mr-[16px] group"
        style={{
          maxWidth: `calc(100vw - ${
            isMinimized
              ? showAction || dialogOpen
                ? '254px'
                : '164px'
              : showAction || dialogOpen
              ? '430px'
              : '344px'
          })`
        }}
      >
        <SVGIcon
          size="24"
          iconName={
            isAccordionOpen
              ? 'icon-keyboard_arrow_down'
              : 'icon-keyboard_arrow_up'
          }
          fillColor="var(--n-600)"
          customClass="cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        />
        {isRenaming ? (
          <InputSectionName
            sectionIndex={sectionIndex}
            projectId={projectId}
            isShowing={isRenaming}
            setIsShowing={setIsRenaming}
            isCreateNew={false}
          />
        ) : (
          <TruncateParagraph className="typography-h500 text-n-900 ml-[16px] whitespace-nowrap uppercase mr-[16px]">
            {sectionName}
          </TruncateParagraph>
        )}
        <div
          className={`flex items-center group-hover:flex ${
            !dialogOpen && 'hidden'
          }`}
        >
          <SVGIcon
            size="24"
            iconName="icon-add"
            fillColor="var(--n-600)"
            customClass="cursor-pointer mr-[16px]"
            onClick={() => !isFetchingTasks && onClickAdd()}
          />
          <SectionOptions
            projectId={projectId}
            sectionIndex={sectionIndex}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            setIsRenaming={setIsRenaming}
            setIsAdding={setIsAdding}
            setAddAbove={setAddAbove}
            setShowModalDeleteSection={setShowModalDeleteSection}
            type="board"
            isCanMove={section?.length > 1}
          />
        </div>
      </div>
    </div>
  );
};

export default Boards;

import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, useHistory } from 'react-router-dom';

import ctl from '@netlify/classnames-template-literals';
import 'scss/admin/_sidebar.scss';
import { format } from 'timeago.js';

import { useSidebarMenu } from 'context/SidebarMenuContext';
import useClickOutside from 'hooks/useClickOutside';
import useSidebar from 'hooks/useSidebar';
import { capitalize, getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Tabs from 'components/design-system/tabs/Tabs';
import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import TruncateParagraph from 'components/shared/TruncateParagraph';

function HeaderPage({
  breadcrumbsData,
  titlePage,
  typographyClassTitlePage = 'typography-h600 w-full text-n-900',
  tertiaryAction = null,
  secondaryAction = null,
  primaryAction = null,
  otherAction = null,
  subAction = null,
  saveStatus = null,
  isHeaderComposer = false,
  badgeTitle,
  lastUpdated,
  lastUpdatedBy,
  backToUrl,
  closeAction,
  customClass,
  customAction,
  noBorder = false,
  useCloseButton = true,
  tabs = {
    tabsList: [],
    defaultIndex: 0,
    url: '',
    onChange: () => null,
    size: 'small',
    withCount: false,
    withBadge: false
  },
  titleOnHover = { showIcon: false, iconName: '', onClickIcon: () => null },
  bottomTitle = { text: '', clickable: false, onClick: () => null },
  preTitle = null,
  headerPicture = null,
  updateTitle = 'Last edit'
}) {
  const [showPrimaryDropdown, setShowPrimaryDropdown] = useState(false);
  const [primaryActionLoading, setPrimaryActionLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [showTitleIcon, setShowTitleIcon] = useState(false);
  const [browserSize, setBrowserSize] = useState(window?.innerWidth);

  const refDropdown = useRef();
  const { toggleSidebar, setUseToggle } = useSidebarMenu();
  const history = useHistory();
  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');

  const closeAllDropdown = () => {
    setShowPrimaryDropdown(false);
  };

  useClickOutside(refDropdown, () => closeAllDropdown());

  if (browserSize < 1200 || isHeaderComposer) {
    toggleSidebar(true);
    setUseToggle(false);
  } else {
    setUseToggle(true);
  }
  const maximizeSidebar = () => {
    toggleSidebar(false);
  };

  const handleClickPrimaryAction = async (e) => {
    primaryAction.withLoading && setPrimaryActionLoading(true);
    await primaryAction.onClick(e);
    primaryAction.withLoading && setPrimaryActionLoading(false);
  };

  const onClickCloseAction = () => {
    maximizeSidebar();
    closeAction ? closeAction() : history.push(backToUrl);
  };

  const changeTab = (tab) => {
    const querySearch = tabs.querySearch || '?';
    const search = querySearch + `tab=${tab}`;
    history.replace(`${tabs?.url}${search}`);
    setCurrentTab(tab);
    if (tabs?.onChange) {
      tabs?.onChange(tab);
    }
  };

  const DropdownButton = ({ listDropdown }) => {
    return (
      <div
        className="fixed rounded-[4px] shadow-overlay bg-n-000 mx-[8px] py-[8px] last:right-[32px] last:top-[60px] !z-[1001] animation-open-dropdown-fadeInDownSlow"
        ref={refDropdown}
      >
        {listDropdown.map((dropdown, index) => {
          return (
            <div
              className={dropdownItemCN}
              key={index}
              data-cy={dropdown.dataCy}
              onClick={(e) => {
                e.stopPropagation();
                closeAllDropdown();
                dropdown.onClick(e);
              }}
            >
              {dropdown.icon && (
                <SVGIcon
                  size="24"
                  fillColor="var(--n-600)"
                  iconName={dropdown.icon}
                  customClass="mr-[4px]"
                />
              )}
              <div className="ml-[8px] mr-[16px]">
                <div className="truncate">
                  {getObjectiveLocale(dropdown.title)}
                </div>
                {dropdown.desc && (
                  <div className="truncate">
                    {getObjectiveLocale(dropdown.desc)}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const DropdownPortal = (listDropdown) => {
    let sharedDropdown = document.querySelectorAll(`[id^=shared-dropdown]`)[0];
    return (
      <div className="is-portal">
        {ReactDOM.createPortal(
          <DropdownButton listDropdown={listDropdown} />,
          sharedDropdown
        )}
      </div>
    );
  };

  const getLastUpdated = () => {
    const lastUpdatedValue = format(lastUpdated);

    if (lastUpdated && lastUpdatedBy) {
      return getObjectiveLocale(`[action] [time] by [user]`)
        .replace('[time]', lastUpdatedValue)
        .replace('[user]', lastUpdatedBy)
        .replace('[action]', updateTitle);
    }

    if (lastUpdated) {
      return lastUpdatedValue;
    }
  };

  const getWidthDimension = () => {
    const { innerWidth: width } = window;
    if (width) {
      setBrowserSize(width);
    }
  };

  useEffect(() => {
    let timeOut;

    function handleResize() {
      clearTimeout(timeOut);
      timeOut = setTimeout(getWidthDimension, 300);
    }

    // for first render
    getWidthDimension();

    // when resize browser
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (tabs?.tabsList?.length > 0 && currentTab === '') {
      if (tabParams) {
        const tabValue =
          tabs?.tabsList?.find(({ id }) => id === tabParams) ||
          tabs?.tabsList?.[tabs?.defaultIndex || 0];
        changeTab(tabValue.id);
      } else {
        setCurrentTab(tabs?.tabsList?.[tabs?.defaultIndex || 0]?.id);
      }
    }
  }, [currentTab]);

  useEffect(() => {
    if (tabParams) {
      setCurrentTab(tabParams);
    }
  }, [tabParams]);

  const headerCN = ctl(`
    header-page
    sticky
    w-full
    top-0
    left-0
    z-index-10
    bg-n-000
    ${customClass ? customClass : ''}
    ${
      noBorder
        ? 'border-b-0'
        : 'border-b border-t-0 border-l-0 border-r-0 border-n-400 border-solid'
    }
    ${isHeaderComposer ? 'px-[24px]' : 'px-[40px]'}
  `);

  const mainHeaderCN = ctl(`
    flex
    items-center
    justify-between
    min-h-[56px]
  `);

  const dropdownItemCN = ctl(`
    flex
    items-center
    h-[36px]
    py-[12px]
    px-[16px]
    border-b
    border-0
    border-n-300
    last:border-b-0
    hover:cursor-pointer
    hover:bg-b-200
    hover:bg-opacity-10
  `);

  // for set top sidebar
  const ref = useRef(null);
  const setTopSidebar = useSidebar((state) => state?.setTopSidebar);

  useEffect(() => {
    const copyRef = ref;
    const resizeHeader = new ResizeObserver(function () {
      setTopSidebar(ref?.current?.getBoundingClientRect()?.bottom);
    });
    resizeHeader.observe(ref?.current);

    return () => {
      if (copyRef.current) {
        resizeHeader.unobserve(copyRef.current);
      }
    };
  }, [ref, setTopSidebar]);

  const getPrimaryButton = (primaryAction) => {
    return (
      <>
        <TooltipContainer
          text={primaryAction.textTooltip}
          show={primaryAction.showTooltip}
          position="bottom"
          classContainer="h-[32px] w-fit"
          align="right"
          useMaxWidth={false}
        >
          <Button
            id={primaryAction.id}
            customClass="mr-[8px] flex h-[32px] items-center"
            onClick={(e) => {
              e.stopPropagation();
              primaryAction.dropdown
                ? setShowPrimaryDropdown(true)
                : handleClickPrimaryAction(e);
            }}
            isLoading={primaryActionLoading}
            disabled={primaryAction.disabled}
          >
            {primaryAction.icon && (
              <SVGIcon
                size={24}
                fillColor={
                  primaryAction.disabled ? 'var(--n-600)' : 'var(--n-100)'
                }
                iconName={primaryAction.icon}
                customClass="mr-[4px]"
              />
            )}
            {primaryAction.title}
          </Button>
        </TooltipContainer>
        {primaryAction.dropdown &&
          showPrimaryDropdown &&
          DropdownPortal(primaryAction.dropdown)}
      </>
    );
  };

  const getSecondaryButton = (secondaryAction) => {
    return (
      <div className="secondary-section mr-[8px]">
        <TooltipContainer
          text={secondaryAction.textTooltip}
          show={secondaryAction.showTooltip}
          position="bottom"
          classContainer="h-[32px] w-fit"
          align="right"
          useMaxWidth={false}
        >
          <Button.Secondary
            id={secondaryAction?.id}
            onClick={(e) => {
              e.stopPropagation();
              secondaryAction.onClick(e);
            }}
            disabled={secondaryAction?.disabled}
            isLoading={secondaryAction?.isLoading || false}
          >
            {secondaryAction.icon && (
              <SVGIcon
                size="24"
                fillColor="var(--n-600)"
                iconName={secondaryAction.icon}
                customClass="mr-[4px]"
              />
            )}
            {secondaryAction.title}
          </Button.Secondary>
        </TooltipContainer>
      </div>
    );
  };
  const getOtherButton = (otherButton) => {
    return (
      <Dropdown>
        <Dropdown.Trigger
          dataCy="trigger-header-other-action-button"
          disabled={otherButton.disabled}
        >
          <div
            className={`flex items-center ${
              otherButton.disabled
                ? 'bg-n-300'
                : 'border-[1px] border-solid border-n-400'
            }  rounded-[4px] p-[4px]`}
          >
            <SVGIcon
              size="24"
              fillColor="var(--n-600)"
              iconName="icon-keyboard_control"
            />
          </div>
        </Dropdown.Trigger>
        <Dropdown.MenuItems
          type="fixed"
          options={otherButton.dropdown}
          position="right"
        />
      </Dropdown>
    );
  };

  return (
    <div className={headerCN} ref={ref}>
      <div className={mainHeaderCN}>
        <div className="title-section flex max-w-full">
          {isHeaderComposer && useCloseButton && (
            <SVGIcon
              size="24"
              fillColor="var(--n-600)"
              iconName="icon-clear"
              customClass="mr-[24px] hover-icon cursor-pointer"
              onClick={onClickCloseAction}
              dataCy="back-icon"
            />
          )}
          <div className="flex flex-col max-w-[800px]">
            {breadcrumbsData && breadcrumbsData.length > 0 && (
              <div className="breadcrumbs mt-[8px] mb-[12px] flex">
                {breadcrumbsData.map((breadcrumbs, index) => (
                  <>
                    <SVGIcon
                      size="16"
                      fillColor="var(--n-600)"
                      iconName="icon-keyboard_arrow_right"
                      customClass="first:hidden mx-[4px]"
                    />
                    <div
                      key={index}
                      data-cy={`breadcrumbs-${index}`}
                      onClick={() =>
                        breadcrumbs?.onClick && breadcrumbs.onClick()
                      }
                    >
                      <Link
                        to={
                          !breadcrumbs.onClick &&
                          (breadcrumbs.link ? breadcrumbs.link : '#')
                        }
                        style={{
                          cursor: breadcrumbs.link ? 'pointer' : 'default'
                        }}
                      >
                        <TruncateParagraph
                          className={`typography-h100 max-w-[160px] ${
                            breadcrumbs.link ? 'text-base-600' : 'text-n-600'
                          }`}
                          dataCy={breadcrumbs.dataCy}
                          position="bottom"
                          useMaxWidth={true}
                        >
                          {getObjectiveLocale(breadcrumbs.title)}
                        </TruncateParagraph>
                      </Link>
                    </div>
                  </>
                ))}
              </div>
            )}

            <div className="flex items-center">
              {headerPicture && (
                <AWSImage
                  src={headerPicture}
                  className="rounded-[4px] w-[24px] h-[24px] mr-[8px]"
                />
              )}
              <div
                className="flex"
                onMouseEnter={
                  titleOnHover?.showIcon
                    ? () => setShowTitleIcon(true)
                    : () => null
                }
                onMouseLeave={
                  titleOnHover?.showIcon
                    ? () => setShowTitleIcon(false)
                    : () => null
                }
              >
                {preTitle && (
                  <span
                    data-cy="pre-title"
                    className="text-n-600 typography-h600 mr-[4px]"
                  >
                    {getObjectiveLocale(preTitle)}
                  </span>
                )}
                <div
                  className={lastUpdated ? 'max-w-[480px]' : 'max-w-[600px]'}
                >
                  <TruncateParagraph
                    className="text-n-900 typography-h600"
                    dataCy="page-title"
                    position="bottom"
                  >
                    {capitalize(getObjectiveLocale(titlePage))}
                  </TruncateParagraph>
                </div>
                {showTitleIcon && (
                  <SVGIcon
                    size="24"
                    fillColor="var(--n-600)"
                    iconName={titleOnHover?.iconName}
                    customClass="ml-[12px]"
                    onClick={() => titleOnHover?.onClickIcon()}
                  />
                )}
              </div>
              {badgeTitle && (
                <Badge
                  className="ml-[8px]"
                  colorHex={badgeTitle?.colorHex}
                  bgColorHex={badgeTitle?.bgColorHex}
                  truncate
                >
                  <p className="typography-h200">{badgeTitle?.text}</p>
                </Badge>
              )}
              {(saveStatus || lastUpdated) && (
                <div className="max-w-[360px]">
                  <TruncateParagraph
                    className={`ml-[8px] typography-secondary ${
                      lastUpdated ? 'underline' : ''
                    }`}
                    position="bottom"
                    dataCy="last-updated"
                  >
                    {saveStatus ? saveStatus : getLastUpdated()}
                  </TruncateParagraph>
                </div>
              )}
              {subAction && (
                <Badge
                  className="ml-[8px] cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    subAction.onClick(e);
                  }}
                  useEvent
                  bgColorHex="bg-transparent"
                  colorHex={getCssVariableValue('--base-600')}
                  iconLeft={subAction.icon}
                  iconLeftColor="var(--base-600)"
                >
                  <p className="typography-paragraph">
                    {getObjectiveLocale(subAction.title)}
                  </p>
                </Badge>
              )}
            </div>

            {(bottomTitle?.title || bottomTitle?.text) && (
              <div className="mt-[4px] py-[4px] px-[8px] h-[28px] bg-n-300 inline-flex items-center w-fit rounded-[4px]">
                <p className="typography-paragraph text-n-600 mr-[4px]">
                  {bottomTitle?.title}:
                </p>
                <p
                  className="typography-paragraph text-base-600 cursor-pointer max-w-[360px] truncate"
                  onClick={() => bottomTitle?.onClick && bottomTitle?.onClick()}
                >
                  {bottomTitle?.text}
                </p>

                {bottomTitle?.badge && (
                  <Badge
                    className="ml-[8px]"
                    colorHex={bottomTitle?.badge?.colorHex}
                    bgColorHex={bottomTitle?.badge?.bgColorHex}
                    truncate
                  >
                    <p className="typography-h200">
                      {bottomTitle?.badge?.text}
                    </p>
                  </Badge>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center">
          {tertiaryAction && getSecondaryButton(tertiaryAction)}
          {secondaryAction && getSecondaryButton(secondaryAction)}
          {primaryAction && getPrimaryButton(primaryAction)}
          {otherAction && getOtherButton(otherAction)}
          {customAction && customAction}
        </div>
      </div>
      {tabs?.tabsList?.length > 0 && (
        <Tabs type="small" currentTab={currentTab}>
          <Tabs.Buttons>
            {tabs.tabsList.map((tab, i) => (
              <Tabs.Button
                withCount={tabs.withCount}
                withBadge={tabs.withBadge}
                badge={tab.count}
                key={i}
                id={tab.id}
                dataCy={tab?.id}
                onClick={() => changeTab(`${tab.id}`)}
              >
                {getObjectiveLocale(tab?.name)}
              </Tabs.Button>
            ))}
          </Tabs.Buttons>
        </Tabs>
      )}
    </div>
  );
}

export default HeaderPage;

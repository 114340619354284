import React from 'react';

import { getBehaviours } from 'client/FormalReviewClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import GoalScoringViewOnly from 'components/formal-review/GoalScoringViewOnly.js';
import TaskScoringViewOnly from 'components/formal-review/TaskScoringViewOnly.js';
import CardOverallInputScore from 'components/formal-review/evidence-sidebar/CardOverallInputScore';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import CompetencyScoringList from 'src/components/formal-review/CompetencyScoringList.js';
import CardEachOrWorkSidebar from 'src/components/formal-review/evidence-sidebar/CardEachOrWorkSidebar';
import CardGeneralQuestionnaireSidebar from 'src/components/formal-review/evidence-sidebar/CardGeneralQuestionnaireSidebar';
import CardSuggestedOngoingSidebar from 'src/components/formal-review/evidence-sidebar/CardSuggestedOngoingSidebar';

import QuestionnaireAnswersContent from './evidence-sidebar/QuestionnaireAnswersContent';

function RevieweeFeedbackList(props) {
  let [{}, { setOverlayRightSidebarData }] = useFormalReview();
  const {
    name,
    type,
    activeTrack,
    template,
    onSetActiveTrack,
    setOverlaySidebar,
    loadingTrack,
    competencyAssessmentData,
    goalScoringData,
    recognitionFeedbackData,
    valuesRecognitionFeedbackData,
    generalQuestionnaireData,
    taskScoringData,
    valueScoringData
  } = props;

  const showCompetencyLevelSidebar = async (name, description, objectId, e) => {
    e.stopPropagation();
    setOverlaySidebar(true);

    let query = {
      behavior_type: 'competency',
      parent_id: objectId
    };
    setOverlayRightSidebarData({ loading: true });
    const { data } = await getBehaviours(query);
    setOverlayRightSidebarData({ name, description, data, loading: false });
  };

  const mechanism = template.mechanism;

  return (
    <div
      key={type}
      className="reviewee-feedback-list"
      onClick={(e) => onSetActiveTrack(type, template.mechanism, e)}
    >
      {/* Grey accordion */}
      <div className="summary-list">
        <h6 className="summary-list-title">{name}</h6>
        <SVGIcon
          iconName="icon-chevron_right"
          fillColor="var(--n-600)"
          size={24}
          customClass={`chevron-summary-list ${
            type === activeTrack ? 'active' : ''
          }`}
        />
      </div>

      {/* Specific content when an accordion is opened */}
      {loadingTrack === type ? (
        <LoadingComponent />
      ) : (
        <>
          {/* The content below Grey Dropdown */}
          {activeTrack === 'competencies_scoring' &&
            type === 'competencies_scoring' && (
              <div className="mb-[32px]">
                {competencyAssessmentData?.answersData?.map(
                  ({ object, answers }, index) => {
                    return (
                      <CompetencyScoringList
                        object={object}
                        answers={answers}
                        showCompetencyLevelSidebar={showCompetencyLevelSidebar}
                        key={index}
                      />
                    );
                  }
                )}
              </div>
            )}
          {activeTrack === 'values_scoring' &&
            type === 'values_scoring' &&
            valueScoringData &&
            valueScoringData?.answersData?.map(({ object, answers }, index) => {
              return (
                <CompetencyScoringList
                  object={object}
                  answers={answers}
                  showCompetencyLevelSidebar={showCompetencyLevelSidebar}
                  key={index}
                />
              );
            })}
          {activeTrack === 'goals_scoring' && type === 'goals_scoring' && (
            <div
              className="competency-assessment-list-feedback"
              onClick={(e) => e.stopPropagation()}
            >
              <CardGeneralQuestionnaireSidebar
                questionnaireData={goalScoringData.questionnaireAnswers}
                trackType={type}
              />
              {goalScoringData.answersData && (
                <div className="mb-[30px]">
                  <div className="container-evidence-sidebar">
                    {(mechanism == 'each' ||
                      mechanism == 'specific_attribute') && (
                      <CardEachOrWorkSidebar
                        showOption={goalScoringData?.answersData.showOption}
                        totalScore={goalScoringData?.answersData.totalScore}
                        answersData={goalScoringData?.answersData.answersData}
                        view={goalScoringData?.answersData.view}
                      />
                    )}
                    {mechanism == 'suggested_ongoing' && (
                      <CardSuggestedOngoingSidebar
                        showOption={goalScoringData.answersData?.showOption}
                        answersData={
                          goalScoringData.answersData.performanceScoringData
                        }
                        trackType={type}
                      />
                    )}
                    {mechanism == 'view_only' && (
                      <GoalScoringViewOnly
                        goalScoringData={goalScoringData.objectives}
                        RevieweeFeedback={true}
                        type={'goals_scoring'}
                      />
                    )}
                    {mechanism == 'overall_score' && (
                      <CardOverallInputScore
                        id="overall"
                        showOption={goalScoringData?.answersData?.showOption}
                        totalScore={goalScoringData?.answersData?.totalScore}
                        answersData={goalScoringData?.answersData?.answers}
                        components={goalScoringData?.answersData?.components}
                        view={goalScoringData?.answersData.view}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {activeTrack === 'tasks_scoring' && type === 'tasks_scoring' && (
            <div
              className="competency-assessment-list-feedback"
              onClick={(e) => e.stopPropagation()}
            >
              <CardGeneralQuestionnaireSidebar
                questionnaireData={taskScoringData.questionnaireAnswers}
                trackType={type}
              />
              {taskScoringData.answersData && (
                <div className="mb-[30px]">
                  <div className="container-evidence-sidebar">
                    {(mechanism == 'each' ||
                      mechanism == 'specific_attribute') && (
                      <CardEachOrWorkSidebar
                        showOption={taskScoringData?.answersData.showOption}
                        totalScore={taskScoringData?.answersData.totalScore}
                        answersData={taskScoringData?.answersData.answersData}
                      />
                    )}
                    {mechanism == 'suggested_ongoing' && (
                      <CardSuggestedOngoingSidebar
                        showOption={taskScoringData.answersData?.showOption}
                        answersData={
                          taskScoringData.answersData.performanceScoringData
                        }
                        trackType={type}
                      />
                    )}
                    {mechanism == 'view_only' && (
                      <TaskScoringViewOnly
                        taskScoringData={taskScoringData.objectives}
                        RevieweeFeedback={true}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {activeTrack === 'competencies_recognition_feedback' &&
            type === 'competencies_recognition_feedback' && (
              <div className="competency-assessment-list-feedback animated-half fadeInDown-listemployee">
                <CardGeneralQuestionnaireSidebar
                  questionnaireData={
                    recognitionFeedbackData.questionnaireAnswers
                  }
                  trackType={type}
                />
                <div className="mb-[40px]">
                  <h6 className="ef-title mb-[16px]">
                    {getObjectiveLocale('Improvement on Competencies')}
                  </h6>
                  {(
                    recognitionFeedbackData?.recognition?.[0]?.answers || []
                  ).map((recognition) => {
                    return (
                      <div
                        key={recognition.id}
                        className="mb-[16px]"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <h4>Recognition</h4>
                        <h6 className="ef-subtitle">
                          {recognition.object.title}
                        </h6>
                        <p className="ef-description">
                          “{recognition.comment}.”
                        </p>
                      </div>
                    );
                  })}
                  {(recognitionFeedbackData?.feedback?.[0]?.answers || []).map(
                    (feedback) => {
                      return (
                        <div
                          key={feedback.id}
                          className="mb-[16px]"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <h4>Feedback</h4>
                          <h6 className="ef-subtitle">
                            {feedback.object.title}
                          </h6>
                          <p className="ef-description">“{feedback.comment}”</p>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          {activeTrack === 'values_recognition_feedback' &&
            type === 'values_recognition_feedback' && (
              <div
                className="competency-assessment-list-feedback animated-half fadeInDown-listemployee"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="mb-[40px]">
                  <h6 className="ef-title mb-[16px]">
                    {getObjectiveLocale('Improvement on Values')}
                  </h6>
                  {(
                    valuesRecognitionFeedbackData?.recognition?.[0]?.answers ||
                    []
                  ).map((recognition) => {
                    return (
                      <div key={recognition?.id} className="mb-[16px]">
                        <h4>Recognition</h4>
                        <h6 className="ef-subtitle">
                          {recognition?.object?.title}
                        </h6>
                        <p className="ef-description">
                          “{recognition?.comment}.”
                        </p>
                      </div>
                    );
                  })}
                  {(
                    valuesRecognitionFeedbackData?.feedback?.[0]?.answers || []
                  ).map((feedback) => {
                    return (
                      <div key={feedback?.id} className="mb-[16px]">
                        <h4>Feedback</h4>
                        <h6 className="ef-subtitle">
                          {feedback?.object?.title}
                        </h6>
                        <p className="ef-description">“{feedback?.comment}”</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          {activeTrack === 'general_questionnaire' &&
            type === 'general_questionnaire' && (
              <div
                className="competency-assessment-list-feedback animated-half fadeInDown-listemployee"
                onClick={(e) => e.stopPropagation()}
              >
                {generalQuestionnaireData?.[0]?.questions?.map(
                  ({ question, answers }) => {
                    return (
                      <div
                        key={question.id}
                        className="reviewee-feedback-questionnaire-container mb-[40px]"
                      >
                        <h6 className="ef-title mb-[16px]">
                          {question?.question}
                        </h6>
                        <QuestionnaireAnswersContent
                          answers={answers}
                          question={question}
                          withProfile={false}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default RevieweeFeedbackList;

import React, { useRef, useState } from 'react';

import 'emoji-mart/css/emoji-mart.css';
import Picker from 'emoji-mart/dist-es/components/picker/nimble-picker';

import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import useEmoticon from 'hooks/useEmoticon';
import Config from 'utils/Config';
import { getCultureLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import SVGIcon from 'components/shared/SVGIcon';

function AdditionalFeedback({
  photo,
  setPhoto,
  caption,
  setCaption,
  setIsButtonModalDisabled
}) {
  let emoticon = useEmoticon();
  const { config } = useUser();
  const [isEmojiCartShow, setIsEmojiCartShow] = useState(false);
  const allowedImageExtensions = config.allowedImageExtensions.map(
    (ext) => '.' + ext
  );
  const emojiRef = useRef();

  useClickOutside(emojiRef, () => {
    setIsEmojiCartShow(false);
  });

  const addEmoji = (emoji) => {
    let currentCaption = caption;
    currentCaption += emoji.native;
    setCaption(currentCaption);
    setIsEmojiCartShow(false);
  };

  const changeCaption = (e) => {
    setCaption(e.target.value);

    e.target.value
      ? setIsButtonModalDisabled(false)
      : setIsButtonModalDisabled(true);
  };

  const addPhoto = (e) => {
    e.stopPropagation();

    const photo = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(photo);

    setPhoto([]);

    let currentPhoto = new Image();
    reader.onload = (r) => {
      currentPhoto.src = r.target.result;
      currentPhoto.fileExtension = photo.name.split('.').pop().toLowerCase();
    };

    reader.onloadend = () => {
      setPhoto([
        {
          src: currentPhoto.src,
          file: photo,
          fileExtension: currentPhoto.fileExtension
        }
      ]);
    };
  };

  const deletePhoto = (e) => {
    e.stopPropagation();
    document.getElementById('media').value = '';
    setPhoto([]);
  };

  return (
    <div className="additional-feedback">
      <div className="caption" data-cy="caption-feedback">
        <textarea
          placeholder={getCultureLocale('Additional Feedback comment')}
          onChange={(e) => {
            e.stopPropagation();
            changeCaption(e);
          }}
          value={caption}
        />
        <div ref={emojiRef}>
          <SVGIcon
            iconName="icon-insert_emoticon"
            customClass="absolute bottom-[0px] right-[15px]"
            size={18}
            fillColor="var(--n-600)"
            onClick={(e) => {
              e.stopPropagation();
              setIsEmojiCartShow(true);
            }}
          />
          {isEmojiCartShow && emoticon && (
            <Picker onSelect={addEmoji} data={emoticon} />
          )}
        </div>
      </div>
      {photo.length > 0 ? (
        <div className="attachment-photo">
          <AWSImage src={photo[0].src} />
          <img
            src={Config.asset.v2.iconCloseCircle}
            className="close-icon"
            onClick={(e) => deletePhoto(e)}
          />
        </div>
      ) : (
        ''
      )}
      <div className="footer-section" data-cy="add-photo">
        <input
          type="file"
          name="media"
          id="media"
          accept={allowedImageExtensions}
          className="input-file"
          onChange={addPhoto}
        />
        <label
          htmlFor="media"
          className={photo.length > 0 ? 'selected-attachment' : ''}
        >
          <div className="flex flex-row mt-[20px] items-center">
            <SVGIcon
              iconName="icon-camera_alt"
              size={24}
              fillColor={photo.length > 0 ? 'var(--base-600)' : 'var(--n-600)'}
              customClass="mr-[5px]"
            />
            {getCultureLocale('Photo')}
          </div>
        </label>
      </div>
    </div>
  );
}

export default AdditionalFeedback;

import React, { useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ReactPlayer from 'react-player';

import { SplideSlide } from '@splidejs/react-splide';

import Config from 'utils/Config';

import AWSImage from 'components/design-system/AWSImage';
import ModalImage from 'components/shared/ModalImage/ModalImage';
import SlickSlider from 'components/slider/SlickSlider';

const PostMediaContent = ({ type, content, postId }) => {
  const [isPlay, setIsPlay] = useState(false);
  const [imgPreview, setImgPreview] = useState([]);
  const [isOpenModalImage, setIsOpenModalImage] = useState(false);

  const isMultipleContentHaveOneImage =
    type === 'multiple_content' &&
    content?.resources &&
    content?.resources.length === 1;
  const isSingleVideo =
    type === 'media' ? content?.video : content?.resources[0]?.video;
  const urlSingleMedia = isSingleVideo
    ? content?.resources
      ? content?.resources[0].video.secureUrl
      : content?.video.secureUrl
    : content?.resources
    ? content?.resources[0].secureUrl
    : content?.secureUrl;

  const modalImageHandler = (show, url) => {
    url && setImgPreview([{ src: url }]);
    setIsOpenModalImage(show);
  };

  const openNewTabPlayer = (e, link) => {
    e.stopPropagation();
    setIsPlay(false);
    window.open(link, '_blank');
  };

  const stopPlayer = () => {
    document.querySelectorAll('video').forEach((v) => {
      v.pause();
    });
  };

  return (
    <LazyLoadComponent threshold={250}>
      <div
        id={`post-media-content-${postId}`}
        className={`feed-content ${type === 'media' ? 'single' : 'multiple'}`}
        data-cy={`post-media-content-${postId}`}
      >
        {type === 'media' || isMultipleContentHaveOneImage ? (
          isSingleVideo ? (
            <div
              data-cy="video"
              className="video-container cursor-pointer"
              onClick={(e) => openNewTabPlayer(e, urlSingleMedia)}
            >
              <ReactPlayer
                url={urlSingleMedia}
                width="560px"
                height="100%"
                controls={true}
                playing={isPlay}
                config={{
                  file: {
                    attributes: {
                      crossOrigin: 'true'
                    }
                  }
                }}
              />
            </div>
          ) : (
            <AWSImage
              imageId={postId}
              src={urlSingleMedia}
              className="resizeable"
              dataCy={`media-${postId}`}
              onClick={() => modalImageHandler(true, urlSingleMedia)}
            />
          )
        ) : (
          <div className="w-[560px]">
            <SlickSlider
              slidesToShow={1}
              slidesToScroll={1}
              fixedWidth="560px"
              useDots={true}
              isInfinite={false}
              afterChange={() => stopPlayer()}
            >
              {content &&
                content?.resources.length > 1 &&
                content?.resources.map((media, index) => {
                  return (
                    <SplideSlide key={index}>
                      {media.video ? (
                        <div
                          key={index}
                          data-cy="video"
                          className="video-container cursor-pointer"
                          onClick={(e) =>
                            openNewTabPlayer(e, media.video.secureUrl)
                          }
                        >
                          <ReactPlayer
                            url={media.video.secureUrl}
                            width="560px"
                            height="100%"
                            controls={true}
                            playing={isPlay}
                            config={{
                              file: {
                                attributes: {
                                  crossOrigin: 'true'
                                }
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <AWSImage
                          src={media.secureUrl}
                          dataCy={`multiple-media-${postId}-${index}`}
                          onClick={() =>
                            modalImageHandler(true, media.secureUrl)
                          }
                        />
                      )}
                    </SplideSlide>
                  );
                })}
            </SlickSlider>
          </div>
        )}
      </div>
      <ModalImage
        imgs={imgPreview}
        isOpen={isOpenModalImage}
        onClose={() => modalImageHandler(false)}
      />
    </LazyLoadComponent>
  );
};

export default PostMediaContent;

import React from 'react';

import InputField from 'components/shared/InputField';
import PollOption from 'components/surveys/PollOption';

const SummaryAnswers = ({ surveyData }) => {
  return (
    <div className="bg-n-000 w-[50%] h-[calc(100vh-64px)] overflow-y-auto flex flex-col">
      {surveyData?.questions?.map((question) => {
        const votedPoll =
          question?.type === 'poll' &&
          question?.pollOptions?.find((opt) => opt.voted);
        return (
          <div className="mx-[40px] mb-[40px]">
            <p className="typography-h700 text-n-900 mb-[24px] mt-[24px]">
              {question?.question}
            </p>
            {question?.type === 'poll' ? (
              <PollOption currentAnswer={votedPoll} opt={votedPoll} disabled />
            ) : (
              <InputField
                dataCy="answer-essay"
                inputClass="w-full border-[0px] border-b-[1px] typography-h600 !text-n-700"
                value={question?.answer}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SummaryAnswers;

import React, { useEffect } from 'react';

import { useFeeds } from 'context/FeedsContext';
import { getCultureLocale } from 'utils/HelperUtils';

import Feeds from 'components/culture/Feeds';
import SVGIcon from 'components/shared/SVGIcon';

const ProfileFeeds = ({ userId, filter, backFunction }) => {
  const { setPage, setFilter, onScroll } = useFeeds(null, userId);

  useEffect(() => {
    setFilter(filter);
    setPage(`profile-${filter}`);
  }, []);

  return (
    <div className="scrollable w-full" onScroll={(e) => onScroll(e)}>
      <div
        className="flex cursor-pointer text-n-600 typography-button items-center mt-[24px] mb-[12px]"
        onClick={() => backFunction()}
      >
        <SVGIcon
          iconName="icon-keyboard_arrow_left"
          size="24"
          fillColor="var(--n-600)"
        />
        {getCultureLocale('Back')}
      </div>
      <Feeds
        widthWrapper="flex items-center"
        page={`profile-${filter}`}
        userId={userId}
      />
    </div>
  );
};

export default ProfileFeeds;

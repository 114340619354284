import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import isEqual from 'lodash/isEqual';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { getCategories, seriesGenerator } from 'utils/ObjectiveOverviewHelper';

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

const BarChart = React.memo(({ overviewData, tab, currentDepartment }) => {
  const history = useHistory();
  const chartRef = useRef();
  const titleName = (type, tab) => {
    if (tab == 'owned') {
      if (type == 'have') {
        return 'Have owned goal';
      } else {
        return 'Have no goal';
      }
    } else if (tab == 'approved') {
      if (type == 'have') {
        return 'All';
      } else {
        return 'Need to remind';
      }
    } else if (tab == 'updated') {
      if (type == 'have') {
        return 'Update regularly';
      } else {
        return 'Missed update';
      }
    }
  };
  let config = {
    chart: {
      style: {
        width: 'auto',
        fontFamily: 'Inter',
        backgroundColor: 'var(--n-000)'
      },
      type: 'column'
    },
    title: {
      text: `${
        currentDepartment
          ? currentDepartment
          : getObjectiveLocale('All Department')
      }`,
      align: 'left',
      floating: false,
      style: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 20,
        lineHeight: 24
      }
    },
    xAxis: {
      categories: getCategories(overviewData, 'department'),
      labels: {
        style: {
          fontFamily: 'Inter',
          fontSize: '12px',
          lineHeight: '16px',
          color: 'var(--n-500)'
        }
      }
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      gridLineWidth: 0.5,
      gridLineColor: 'var(--n-300)',
      title: {
        text: `Number of ${tab !== 'owned' ? 'Goals' : 'Employees'}`,
        margin: 24,
        style: {
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '20px',
          color: 'var(--n-500)'
        }
      },
      labels: {
        style: {
          fontFamily: 'Inter',
          fontSize: '12px',
          lineHeight: '16px',
          color: 'var(--n-500)'
        }
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
        const data = this;
        const pointData = data?.points;
        const seriesNameX = getObjectiveLocale(
          titleName(pointData?.[0]?.series?.name, tab)
        );
        const percentageX = pointData?.[0]?.point?.percentage;
        const totalX = pointData?.[0]?.point?.y;
        const seriesNameY = getObjectiveLocale(
          titleName(pointData?.[1]?.series?.name, tab)
        );
        const percentageY = pointData?.[1]?.point?.percentage;
        const totalY = pointData?.[1]?.point?.y;
        return (
          `<div class="bg-n-000 mx-[8px] my-[8px]" style="width:352px;display:flex;flex-direction:column;border:none;">
            <p class="typography-h100 text-n-600 mb-[16px]">` +
          (pointData?.[0]?.x || 'All') +
          `</p>
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <div class="h-[8px] rounded-full bg-lb-500 mr-[16px]" style="width:8px"></div>
                <p class="typography-h100 text-n-800">` +
          seriesNameY +
          `</p>
              </div>
              <div class="flex items-center">
                <p class="typography-h200 text-n-800 mr-[4px]">` +
          getNumberLocale(percentageY) +
          `%</p>
                <p class="typography-h200 text-n-600">(` +
          getNumberLocale(totalY) +
          ` ${tab !== 'owned' ? 'Goals' : 'Employees'})</p>
              </div>
            </div>
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <div class="h-[8px] rounded-full bg-n-400 mr-[16px]" style="width:8px"></div>
                    <p class="typography-h100 text-n-800">` +
          seriesNameX +
          `</p>
                  </div>
                <div class="flex items-center">
                  <p class="typography-h200 text-n-800 mr-[4px]">` +
          getNumberLocale(percentageX) +
          `%</p>
                  <p class="typography-h200 text-n-600">(` +
          getNumberLocale(totalX) +
          ` ${tab !== 'owned' ? 'Goals' : 'Employees'})</p>
                </div>
            </div>
        </div>`
        );
      },
      backgroundColor: '#fff',
      borderWidth: 0,
      useHTML: true,
      shared: true
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        events: {
          click: (e) => {
            history.push(
              `/objective-overview/${tab}?department=${e.point.category}`
            );
          }
        }
      },
      column: {
        stacking: 'normal',
        pointWidth: '112'
      }
    },
    colors: [
      'var(--n-400)',
      currentDepartment ? 'var(--f-600)' : 'var(--lb-500)'
    ],
    series: seriesGenerator(overviewData)
  };
  return (
    <div className="mt-[24px] px-[40px] py-[40px] rounded-[3px] border border-solid border-n-400">
      <HighchartsReact
        highcharts={Highcharts}
        options={config}
        ref={chartRef}
      />
    </div>
  );
}, areEqual);

BarChart.displayName = 'BarChart';

export default BarChart;

import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { getRatingTemplates } from 'client/RatingTemplateClient';
import { getCustomAttributeList } from 'client/admin/ReviewGroupClient.js';
import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { getObjectiveLocale, setActiveNavigator } from 'utils/HelperUtils';

import RatingComponent from 'components/admin/track-template/RatingComponent';
import ContentNavigator from 'components/shared/ContentNavigator';
import LoadingComponent from 'components/shared/LoadingComponent';

import CycleDeliverables from './CycleDeliverables';
import PhaseDeliverables from './PhaseDeliverables';
import TeamResultDeliverables from './TeamResultDeliverables';

const REVIEW_ASPECTS = 'scoreAspects';

const ConfigureDeliverables = () => {
  const {
    cycleData,
    changePhaseData,
    changePhaseDisclosure,
    changePhaseDeliverable,
    changeInProgressDeliverable,
    changeDisplayResult,
    changeDisplayResultScoreAspect,
    changeDisplayTrack,
    changeViewSummaryConfig,
    changeRatingTemplate
  } = useCreateCycle();
  const { inProgressDeliverable, ratingTemplate } = cycleData;
  const [reviewAspectsList, setReviewAspectsList] = useState([]);
  const [reviewAspectsScoreCardList, setReviewAspectsScoreCardList] = useState(
    []
  );
  const [setupReviewAspectStatus, setSetupReviewAspectStatus] = useState(false);
  const [listRatingTemplate, setListRatingTemplate] = useState([]);
  const [selectedRating, setSelectedRating] = useState({});
  const [activeSection, setActiveSection] = useState('deliverables');

  const configInProgressDeliverable = [
    { id: 1, description: 'After Cycle Has Ended (Default)', value: false },
    { id: 2, description: 'While Cycle Still Running', value: true }
  ];

  let listNavigation = [
    { id: 'deliverables', name: 'Configure deliverables' },
    { id: 'rating', name: 'Configure rating template' },
    { id: 'score-labels', name: 'Configure show scores/labels' }
  ];

  const phasesCard = {
    selfReview: {
      title: getObjectiveLocale('Self Review'),
      description:
        'Review results from yourself will be shared with the reviewee.'
    },
    managerReview: {
      title: getObjectiveLocale('Manager Review'),
      description:
        'Review results from manager will be shared with the reviewee.'
    },
    peerReview: {
      title: getObjectiveLocale('Peer Review'),
      description: 'Review results from peers will be shared with the reviewee.'
    },
    upwardReview: {
      title: getObjectiveLocale('Upward Review'),
      description:
        'Review results from direct report will be shared with the reviewee.'
    },
    indirectManagerReview: {
      title: getObjectiveLocale('Indirect Review'),
      description:
        'Review results from indirect manager will be shared with the reviewee.'
    }
  };

  const onChangePhaseDeliverable = (phaseType, trackConfigs, e) => {
    // build deliverable config
    changePhaseDeliverable(phaseType, e.target.checked);

    // build phase disclosure config
    ['peerReview', 'upwardReview'].includes(phaseType) &&
      onChangePhaseDisclosure(phaseType, 'full_disclosure');

    // build default summary config if there is a summary track
    trackConfigs.length > 0 &&
      trackConfigs.findIndex((trackConfig) => trackConfig.type === 'summary') >=
        0 &&
      changeViewSummaryConfig(
        phaseType,
        e.target.checked ? 'final_score_only' : null
      );

    // build display config
    trackConfigs.length > 0 &&
      trackConfigs.map((trackConfig) => {
        changeDisplayTrack(
          phaseType,
          trackConfig.type,
          trackConfig.name,
          e.target.checked
        );
      });
  };

  const onChangePhaseDisclosure = (phaseType, disclosure) => {
    changePhaseDisclosure(phaseType, disclosure);
  };

  const onChangeDisplayTrack = (phaseType, trackType, trackName, value) => {
    changeDisplayTrack(phaseType, trackType, trackName, value);
  };

  const onChangeViewSummaryConfig = (phaseType, e) => {
    changeViewSummaryConfig(phaseType, e.val);
  };

  const onChangeDisplayResult = (e, resultId, type) => {
    let checked = e.currentTarget.checked;
    let tmpList = reviewAspectsList.slice();
    const index = tmpList.findIndex((list) => list.id == resultId);
    tmpList[index].checked = checked;
    if (type == 'default') {
      changeDisplayResult(resultId, checked);
    } else {
      const scoreAspects = tmpList
        .filter((list) => list.type == REVIEW_ASPECTS)
        .map((list) => {
          return {
            id: list.id,
            visibility: list.checked
          };
        });
      changeDisplayResultScoreAspect(scoreAspects);
    }
    setReviewAspectsList(tmpList);
  };

  const listScores = [
    {
      id: 'finalResultVisible',
      name: 'Final Score',
      type: 'default',
      checked: cycleData?.finalResultVisible
    }
  ];

  const getList = async () => {
    let listData = [];
    let listScoreCard = [];
    let params = {
      state: 'active'
    };
    const fetchReviewAspect = async (olderThan = null, stop) => {
      if (stop) return setSetupReviewAspectStatus(stop);
      if (olderThan) {
        params = {
          ...params,
          olderThan: olderThan
        };
      }
      const { data, pagination } = await getCustomAttributeList(0, params);
      if (data) {
        let modifiedData = [];
        let modifiedScoreCardData = [];
        data.map((dt) => {
          const checked =
            cycleData?.scoreAspects?.find((list) => list.id == dt.id)
              ?.visibility || false;
          modifiedData.push({
            id: dt.id,
            name: dt.name,
            type: REVIEW_ASPECTS,
            checked
          });
          modifiedScoreCardData.push({
            id: dt.id,
            display: dt.name,
            key: `${REVIEW_ASPECTS}+${dt.id}`
          });
        });
        listData = [...listData, ...modifiedData];
        listScoreCard = [...listScoreCard, ...modifiedScoreCardData];
        setReviewAspectsList(listData);
        setReviewAspectsScoreCardList(listScoreCard);
        fetchReviewAspect(
          pagination?.next?.olderThan,
          data.length != 10 || !pagination?.next?.olderThan
        );
      }
    };
    fetchReviewAspect();
  };

  const getListOrDefaultRatingTemplate = async (isDefault) => {
    let params = {
      ratingType: 'performance_final'
    };
    isDefault && (params.isDefault = true);
    const { data } = await getRatingTemplates(params);
    if (data) {
      if (isDefault) {
        setSelectedRating(data[0]);
      } else {
        setListRatingTemplate(data);
      }
    }
  };

  const getConfigureDelivearablesData = async () => {
    if (!setupReviewAspectStatus) {
      await getList();
    }
    await getListOrDefaultRatingTemplate(false);
    isEmpty(ratingTemplate)
      ? await getListOrDefaultRatingTemplate(true)
      : setSelectedRating(ratingTemplate);
  };

  useEffect(() => {
    getConfigureDelivearablesData();
  }, []);

  useEffect(() => {
    if (setupReviewAspectStatus) {
      setReviewAspectsList([...reviewAspectsList, ...listScores]);
    }
  }, [setupReviewAspectStatus]);

  useEffect(() => {
    selectedRating?.id != ratingTemplate?.id &&
      !isEmpty(selectedRating) &&
      changeRatingTemplate(selectedRating);
  }, [selectedRating?.id]);

  return (
    <div className="flex">
      <div
        id="scrollable-config"
        className="overflow-auto w-full h-[calc(100vh-137px)] relative"
        onScroll={(e) =>
          setActiveNavigator(e, listNavigation, setActiveSection)
        }
      >
        <ContentNavigator
          title="CONTENTS"
          activeSection={activeSection}
          listNavigatorContents={listNavigation}
          sectionContent="scrollable-config"
          additionalOffset={-8}
          customClassContainer="fixed min-w-[250px] right-0 mr-[40px] mt-[24px] z-index-10"
        />
        <div className="w-[75%] px-[40px]">
          <p className="typography-h500 mb-[4px] pt-[24px]" id="deliverables">
            Configure deliverables
          </p>
          <CycleDeliverables
            configInProgressDeliverable={configInProgressDeliverable}
            inProgressDeliverable={inProgressDeliverable}
            changeInProgressDeliverable={changeInProgressDeliverable}
          />

          <div className="horizontal-divider my-[40px]" id="rating" />

          <p className="typography-h500 mb-[4px]">
            {getObjectiveLocale('Configure rating template')}
          </p>
          <p className="typography-paragraph typography-secondary mb-[16px]">
            {getObjectiveLocale(
              `Configure which performance final rating template will be used`
            )}
          </p>
          {selectedRating?.id ? (
            <RatingComponent
              listRatingTemplate={listRatingTemplate}
              templateData={selectedRating}
              setTemplateData={setSelectedRating}
              alwaysShowComponent
            />
          ) : (
            <LoadingComponent />
          )}

          <div className="horizontal-divider my-[40px]" id="score-labels" />

          <TeamResultDeliverables
            setupReviewAspectStatus={setupReviewAspectStatus}
            reviewAspectsList={reviewAspectsList}
            onChangeDisplayResult={onChangeDisplayResult}
          />

          <div className="horizontal-divider my-[40px]" />

          <div className="pb-[2px]">
            {Object.entries(phasesCard).map(([phaseKey, phase], index) => {
              const { title, description } = phase;
              const { deliverable, disclosure, scoreCard } =
                cycleData[phaseKey] || {};
              const trackConfigs = cycleData[phaseKey]?.trackConfigs || [];
              const revieweeSummaryVisibility =
                cycleData[phaseKey]?.revieweeSummaryVisibility || '';

              return (
                cycleData[phaseKey] && (
                  <PhaseDeliverables
                    index={index}
                    title={title}
                    description={description}
                    deliverable={deliverable}
                    disclosure={disclosure}
                    scoreCard={scoreCard}
                    trackConfigs={trackConfigs}
                    phaseKey={phaseKey}
                    onChangeViewSummaryConfig={onChangeViewSummaryConfig}
                    onChangePhaseDeliverable={onChangePhaseDeliverable}
                    onChangePhaseDisclosure={onChangePhaseDisclosure}
                    onChangeDisplayTrack={onChangeDisplayTrack}
                    changePhaseData={changePhaseData}
                    reviewAspectsScoreCardList={reviewAspectsScoreCardList}
                    revieweeSummaryVisibility={revieweeSummaryVisibility}
                  />
                )
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureDeliverables;

import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import {
  formatTimezone,
  getDateWithForcedTimezone,
  getSemester
} from 'utils/DateUtils';

import AntdCalendar from 'components/shared/Calendar/AntdCalendar';
import { listOptionDate } from 'components/shared/Calendar/ListAntdDateHelper';

const DueDate = React.memo(({ task, type = 'list' }) => {
  const { id, name, startDate, dueDate } = task || '';
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const {
    config: { defaultPeriodOnCreate }
  } = useUser();
  const { refetchObjective, invalidateTaskQueries } = useRefetchQuery();

  const defaultDueDate =
    defaultPeriodOnCreate === 'day'
      ? dayjs().add(1, 'month')
      : defaultPeriodOnCreate === 'semester'
      ? getSemester('due')
      : dayjs().endOf(defaultPeriodOnCreate);

  const [dateValue, setDateValue] = useState([
    dayjs(startDate),
    dueDate ? dayjs(dueDate) : defaultDueDate.toISOString()
  ]);

  const firstRender = useRef(true);

  const handleUpdateObjectiveDate = async () => {
    const payload = {
      startDate: formatTimezone(
        dayjs(dateValue[0]).format('YYYY-MM-DD'),
        'start'
      ),
      dueDate: formatTimezone(dayjs(dateValue[1]).format('YYYY-MM-DD'), 'end')
    };

    const { isSuccess } = await editObjective(id, payload);
    if (isSuccess) {
      refetchObjective(id);
      invalidateTaskQueries({ task });
    }
  };

  const clickDate = (e) => {
    e.stopPropagation();
    setIsCalendarOpen(!isCalendarOpen);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      !isEqual(dateValue, [dayjs(startDate), dayjs(dueDate)]) &&
        handleUpdateObjectiveDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, isCalendarOpen]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      data-cy="trigger-task-calendar"
      className={`flex items-center cursor-pointer relative ${
        type === 'list' ? 'w-full h-full' : ''
      }`}
    >
      {type === 'board' && name === '' ? (
        <p className="typography-h100 text-n-800 text-right w-[88px]">
          {dayjs(defaultDueDate).format('D MMM YY')}
        </p>
      ) : (
        <>
          <p
            className={`typography-h100 text-n-800  my-[10px] ml-[10px] ${
              type === 'board' ? 'w-full text-right' : ''
            }`}
            onClick={clickDate}
          >
            {startDate && getDateWithForcedTimezone(startDate, 'D MMM YY')} -{' '}
            {dueDate && getDateWithForcedTimezone(dueDate, 'D MMM YY')}
          </p>
          {isCalendarOpen && (
            <div className="relative">
              <AntdCalendar>
                <AntdCalendar.Content
                  dateValue={dateValue ? dateValue : [startDate, dueDate]}
                  setDateValueHandler={setDateValue}
                  isAlwaysOpen
                  listOptionsTime={listOptionDate([
                    '7 days',
                    '14 days',
                    '30 days'
                  ])}
                  type="absolute"
                  useHorizontalIdentifier={true}
                  useScrollClose
                  clickOutsideCallback={() => setIsCalendarOpen(false)}
                />
              </AntdCalendar>
            </div>
          )}
        </>
      )}
    </div>
  );
});

DueDate.displayName = 'DueDate';

export default DueDate;

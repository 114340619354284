export const dictValueRecognition = {
  given: 'Given Only',
  received: 'Received Only',
  entire: 'Given & Received'
};

export const SPConfig = [
  { name: 'SP 1', valueReduction: 150 },
  { name: 'SP 2', valueReduction: 200 },
  { name: 'SP 3', valueReduction: 250 }
];

export const formulaCalculationConfig = [
  { id: 1, title: 'Score will not reduced by SP' },
  { id: 2, title: 'Score will reduced by SP' }
];

export const ratingConfig = [
  {
    id: 1,
    score: 1,
    name: 'Below expectation',
    description: '',
    colorHex: 'ED2E1D',
    bottomThresholdScore: 1.0,
    upperThresholdScore: 1.9,
    askForStrength: false,
    askForWeakness: false
  },
  {
    id: 2,
    score: 2,
    name: 'Almost meet expectation',
    description: '',
    colorHex: 'ED2E1D',
    bottomThresholdScore: 1.9,
    upperThresholdScore: 2.9,
    askForStrength: false,
    askForWeakness: false
  },
  {
    id: 3,
    score: 3,
    name: 'Meet expectation',
    description: '',
    colorHex: 'ED2E1D',
    bottomThresholdScore: 2.9,
    upperThresholdScore: 3.9,
    askForStrength: false,
    askForWeakness: false
  },
  {
    id: 4,
    score: 4,
    name: 'Exceed expectation',
    description: '',
    colorHex: 'ED2E1D',
    bottomThresholdScore: 3.9,
    upperThresholdScore: null,
    askForStrength: false,
    askForWeakness: false
  }
];

export const percentageRangeConfig = [
  {
    id: 1,
    score: 1,
    name: 'Below expectation',
    colorHex: 'ED2E1D',
    upperThresholdPercentage: 25,
    bottomThresholdPercentage: 0
  },
  {
    id: 2,
    score: 2,
    name: 'Almost meet expectation',
    colorHex: 'ED2E1D',
    upperThresholdPercentage: 75,
    bottomThresholdPercentage: 26
  },
  {
    id: 3,
    score: 3,
    name: 'Meet expectation',
    colorHex: 'ED2E1D',
    upperThresholdPercentage: 100,
    bottomThresholdPercentage: 76
  },
  {
    id: 4,
    score: 4,
    name: 'Exceed expectation',
    colorHex: 'ED2E1D',
    upperThresholdPercentage: 125,
    bottomThresholdPercentage: 101
  }
];

export const defaultBody = {
  ordering: 'worst_to_best',
  question: 'How well is the execution?',
  feedbackQuestion: 'What the one thing did this person need to improve?',
  recognitionQuestion: 'What the one thing did this person do well?',
  commentQuestion: 'Do you have specific comment about the result?',
  weight: 1
};

export const configScoringMechanism = [
  { id: 1, titleId: 'direct_to_goal', title: 'Directly to goal' },
  {
    id: 2,
    titleId: 'work_attributes',
    title: 'Given to a set of attributes of the goal'
  }
];

export const optionAFormula = [
  { id: 11, titleId: 'total', title: 'Total rating' },
  { id: 12, titleId: 'average', title: 'Average total rating' },
  {
    id: 13,
    titleId: 'weighted_total',
    title: 'Total rating times goal weight percentage'
  },
  {
    id: 14,
    titleId: 'weighted_average',
    title: 'Average of total rating times goal weight percentage'
  }
];

export const optionBFormula = [
  { id: 21, titleId: 'total', title: 'Total rating for each attribute' },
  {
    id: 22,
    titleId: 'average',
    title: 'Average total rating of each attribute'
  }
];

export const additionalFormula = [
  {
    id: 15,
    titleId: 'parent_total',
    title: 'Total rating (parent goals only)'
  },
  {
    id: 16,
    titleId: 'parent_average',
    title: 'Average of total rating (parent goals only)'
  },
  {
    id: 17,
    titleId: 'parent_weighted_total',
    title: 'Total rating times goal weight percentage (parent goals only)'
  },
  {
    id: 18,
    titleId: 'parent_weighted_average',
    title:
      'Average of total rating times goal weight percentage (parent goals only)'
  }
];

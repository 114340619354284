const TalentMappingData = {
    "data": [{
			"title": "Potential Gem",
			"subtitle": "Very potential talent",
			"description": "Well at current job with potential to do more"
		},
		{
			"title": "High Potential",
			"subtitle": "High Potential/Moderate Performance",
			"description": "Does extremely well at current job with potential to do more"
		},
		{
			"title": "Star",
			"subtitle": "Star Talent",
			"description": "Very extremely well at current job with potential to do more"
		},
		{
			"title": "Inconsistent Player",
			"subtitle": "Inconsistent performance",
			"description": "Underperform at current job with no potential to do more"
		},
		{
			"title": "Core Player",
			"subtitle": "Balance performance and potential",
			"description": "Very well at current job with potential to do more"
		},

		{
			"title": "High Performer",
			"subtitle": "High Performer Talent",
			"description": "Enough extremely well at current job with potential to do more"
		},
		{
			"title": "Risk",
			"subtitle": "Risk Talent",
			"description": "Does not very well at current job with no potential to do more"
		},


		{
			"title": "Average Performer",
			"subtitle": "Average Performer Talent",
			"description": "Average with other when deliver current job"
		},

		{
			"title": "Solid Performer",
			"subtitle": "Solid Performer Talent",
			"description": "Consistently perform well at current job with potential to do more"
		}
	]
}

export default TalentMappingData
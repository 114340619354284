import React from 'react';

import ctl from '@netlify/classnames-template-literals';

const TextCN = (variant, className) => {
  return ctl(`
      ${variant === 'h900' && 'h900'}
      ${variant === 'h800' && 'h800'}
      ${variant === 'h700' && 'h700'}
      ${variant === 'h600' && 'h600'}
      ${variant === 'h500' && 'h500'}
      ${variant === 'h500-long' && 'h500-long'}
      ${variant === 'h400' && 'h400'}
      ${variant === 'h400-long' && 'h400-long'}
      ${variant === 'h300' && 'h300'}
      ${variant === 'h200' && 'h200'}
      ${variant === 'h100' && 'h100'}

      ${variant === 'paragraph' && 'paragraph'}
      ${variant === 'link' && 'link'}
      ${className}
    `);
};

export default Text = ({ variant, children, className }) => {
  return <span className={TextCN(variant, className)}>{children} </span>;
};

import React, { useEffect, useState } from 'react';

import { getFormalReviewGoalsScoring } from 'client/FormalReviewClient';
import { getGoalsScoring } from 'client/ObjectivesClient';
import { useFormalReview } from 'context/FormalReviewContext.js';
import { useUser } from 'context/UserContext';

import HeaderWorkScore from 'components/formal-review/annual-review/HeaderWorkScore.js';
import ObjectScoring from 'components/formal-review/annual-review/ObjectScoring.js';
import BannerBox from 'components/shared/BannerBox';
import Loading from 'components/shared/LoadingComponent';

const GoalsScoringInputScore = ({
  assignmentId,
  actor,
  preFill,
  suggestions,
  view
}) => {
  const {
    config: { defaultObjectiveSorting }
  } = useUser();
  const [{ currentTrack, phase }, { getSingleTrack }] = useFormalReview();
  const { components, template, answers, trackConfig } =
    getSingleTrack(currentTrack) || {};
  const { useComment, mechanism } = template;
  const currentAssingmentId = assignmentId;

  const [objectives, setObjectives] = useState([]);
  const [personalScore, setPersonalScore] = useState(0);
  const [loading, setLoading] = useState(true);

  const getScoring = async () => {
    const { data } = await getFormalReviewGoalsScoring(assignmentId);
    if (data) setPersonalScore(data.personal.score);
  };

  const sortedOptionsBasedOnScore = components.options.sort(
    (a, b) => a.score - b.score
  );
  const minimumScore = sortedOptionsBasedOnScore[0].score;
  const maximumScore =
    sortedOptionsBasedOnScore[sortedOptionsBasedOnScore.length - 1].score;

  const getObjectivesByAssignmentId = async () => {
    const params = {
      sortColumn: defaultObjectiveSorting?.sortColumn || 'due_date',
      sortDirection: defaultObjectiveSorting?.sortDirection || 'asc'
    };
    const { data } = await getGoalsScoring(currentAssingmentId, params);
    if (data) setObjectives(data);
    setLoading(false);
  };

  useEffect(() => {
    getScoring();
    getObjectivesByAssignmentId();
  }, []);

  return (
    <>
      <HeaderWorkScore
        type="input_score"
        totalNilai={personalScore}
        trackType="goals_scoring"
      />

      <BannerBox
        text={`Rating range: ${minimumScore} - ${maximumScore}, you can input max two-digit decimal value`}
        type="info"
        customClass="mb-[40px] h-[56px]"
      />

      {loading ? (
        <Loading />
      ) : (
        objectives &&
        objectives.map((objective, idx) => {
          let singleObjective = [objective];
          return (
            <ObjectScoring
              id={objective.id}
              title={objective.name}
              key={objective.id}
              idx={idx}
              components={components}
              objective={singleObjective}
              useComment={useComment}
              answers={answers}
              currentTrack={currentTrack}
              type={phase.type}
              minimumScore={minimumScore}
              maximumScore={maximumScore}
              mechanism={mechanism}
              getSingleGoalsScoring={getScoring}
              actor={actor}
              preFill={preFill}
              suggestions={suggestions}
              view={view}
              phaseType={phase.type}
              suggestionSource={trackConfig?.suggestionSource}
            />
          );
        })
      )}
    </>
  );
};

export default GoalsScoringInputScore;

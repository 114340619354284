import React, { useEffect, useState } from 'react';

import { SplideSlide } from '@splidejs/react-splide';

import { getHighlightPost, getPinnedPost } from 'client/CultureClient';
import { useReload } from 'context/ReloadContext';
import { getCultureLocale } from 'utils/HelperUtils';

import MiniFeedCard from 'components/culture/MiniFeedCard';
import SlickSlider from 'components/slider/SlickSlider';

const HighlightPinnedSection = ({ widthWrapper, type, groupId }) => {
  const { reloadHighlightPinned } = useReload();
  const [data, setData] = useState([]);

  const _getHighlightPost = async () => {
    const { data } = await getHighlightPost({ highlighted: 1 });
    if (data) setData(data);
  };

  const _getPinnedPost = async () => {
    const { data } = await getPinnedPost(groupId);

    if (data) setData(data);
  };

  const removeFeed = (id) => {
    setData(data.filter((d) => d.id !== id));
  };

  useEffect(() => {
    type === 'pinned' ? _getPinnedPost() : _getHighlightPost();
  }, [groupId, reloadHighlightPinned]);

  return (
    data.length > 0 && (
      <div
        className={`mb-[40px]`}
        style={{ width: widthWrapper + 'px' }}
        data-cy={`${type}-section`}
      >
        <div
          className={`mb-[16px] ${
            type === 'pinned'
              ? 'text-n-800 typography-h300'
              : 'text-n-900 typography-h500'
          }`}
        >
          {type === 'pinned'
            ? getCultureLocale('Pinned')
            : getCultureLocale('Highlighted')}{' '}
          {getCultureLocale('Posts')}
        </div>
        <SlickSlider
          useDots={false}
          isInfinite={false}
          slidesToShow={type === 'pinned' ? 1 : 2}
          slidesToScroll={1}
          gap="16px"
          fixedWidth="320px"
        >
          {data.map((feed, index) => (
            <SplideSlide key={index}>
              <MiniFeedCard
                key={`mini-feed-${index}`}
                feed={feed}
                type={type}
                removeFeed={removeFeed}
              />
            </SplideSlide>
          ))}
        </SlickSlider>
      </div>
    )
  );
};

export default HighlightPinnedSection;

import React, { useEffect, useState } from 'react';

import { getRecognitionSkills } from 'client/CultureClient';
import useDebounce from 'hooks/useDebounce';
import Config from 'utils/Config';
import { getCultureLocale } from 'utils/HelperUtils';

import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

function RecognitionSkill({
  selectedSkills,
  setSelectedSkills,
  setIsButtonModalDisabled
}) {
  const [search, setSearch] = useState('');
  const [skills, setSkills] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearch = useDebounce(search, 2000);

  const getSkills = async () => {
    setIsSearching(true);
    const params = {
      q: search
    };
    const { data } = await getRecognitionSkills(params);

    if (data) {
      setSkills(data);
      setIsSearching(false);
    }
  };

  const changeSearchSkill = (e) => {
    setIsSearching(true);
    setSearch(e.target.value);
  };

  const selectSkill = (e, skill, index) => {
    e.stopPropagation();
    let currentSelectedSkills = JSON.parse(JSON.stringify(selectedSkills));
    currentSelectedSkills = [
      ...currentSelectedSkills,
      {
        id: skill.id,
        title: skill.title
      }
    ];
    setSelectedSkills(currentSelectedSkills);
  };

  const deleteSkill = (e, index) => {
    e.stopPropagation();
    const currentSelectedSkills = JSON.parse(JSON.stringify(selectedSkills));
    currentSelectedSkills.splice(index, 1);
    setSelectedSkills(currentSelectedSkills);
  };

  useEffect(() => {
    getSkills();
  }, [debouncedSearch]);

  useEffect(() => {
    if (selectedSkills.length > 0) {
      setIsButtonModalDisabled(false);
    } else {
      setIsButtonModalDisabled(true);
    }
  }, [selectedSkills]);

  return (
    <div className="recognition-skill">
      <input
        placeholder={getCultureLocale('AddSkills')}
        onChange={(e) => changeSearchSkill(e)}
        value={search}
        data-cy="search-skill"
      />
      {isSearching ? (
        <LoadingComponent />
      ) : (
        <div className="skill-list overflow-auto">
          {skills
            .filter(({ id }) => selectedSkills.every((ss) => ss.id !== id))
            .map((skill, index) => {
              return (
                <div
                  className="skill-item"
                  key={skill.id}
                  onClick={(e) => selectSkill(e, skill, index)}
                  data-cy="skill-item"
                >
                  <SVGIcon
                    iconName="icon-add_circle"
                    size="24"
                    fillColor="var(--base-600)"
                    customClass="mr-[5px]"
                  />
                  <span className="typography-h200">{skill.title}</span>
                </div>
              );
            })}
        </div>
      )}
      <div className="selected-skill-list scroll-label pb-[12px]">
        {selectedSkills.map((skill, index) => {
          return (
            <div
              className="skill-item"
              key={skill.id}
              onClick={(e) => deleteSkill(e, index)}
            >
              <SVGIcon
                iconName="icon-times_delete"
                size={16}
                fillColor="var(--n-100)"
                customClass="mr-[5px]"
              />
              <span className="typography-h200 truncate">{skill.title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RecognitionSkill;

import React from 'react';

import dayjs from 'dayjs';

import CalendarTime from 'components/shared/CalendarTime';

function MissionPeriod({ mission, setMission, isReadOnly }) {
  const startTime = mission?.startsAt;
  const endsTime = mission?.endsAt;

  const handleChangeStartTime = (startTime) => {
    const dateAndTime = dayjs(startTime);
    const endTime = dateAndTime.add(1, 'days').endOf('day').toISOString();

    setMission({
      ...mission,
      startsAt: startTime,
      endsAt: endTime
    });
  };

  return (
    <div
      id="period-section"
      className="form-group bg-n-000 pt-[40px] min-h-[calc(100vh-164px)]"
    >
      <label
        htmlFor="missionPeriod"
        className="typography-h500-longform text-n-900 mt-[16px]"
      >
        Set period
      </label>
      <p className="typography-paragraph typography-secondary mb-[16px]">
        When this mission will be running?
      </p>
      <div className="h-[calc(100%-137px)]">
        <CalendarTime
          date={startTime}
          setDate={handleChangeStartTime}
          title="Start Date"
          isReadOnly={isReadOnly}
          type="startDate"
        />

        <CalendarTime
          date={endsTime}
          setDate={(endTime) => setMission({ ...mission, endsAt: endTime })}
          title="End Date"
          isReadOnly={isReadOnly}
          validDate={startTime}
          type="dueDate"
        />
      </div>
    </div>
  );
}

export default MissionPeriod;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';

const ModalUnsavedPreset = ({
  actionSave,
  actionUnsave,
  onClose,
  isStatus,
  setOpenModal
}) => {
  const title = isStatus === 'exitPage' ? 'page' : 'preset';

  return (
    <Modal onClose={onClose} minWidth={480} customClass="w-[480px]">
      <Modal.Header title="Unsaved Preset" />
      <Modal.Body>
        <div className="w-full px-[24px] my-[4px]">
          <p className="typography-paragraph">
            {getObjectiveLocale(
              `You are about to leave this ${title} without saving, and all changes will be lost. Do you want to save the changes?`
            )}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={{
          text: getObjectiveLocale('Save'),
          dataCy: 'save-preset',
          onClick: isStatus ? actionSave : () => setOpenModal('savePreset')
        }}
        secondaryButton={{
          text: getObjectiveLocale('Leave without Saving'),
          dataCy: 'cancel-save',
          onClick: actionUnsave
        }}
        useBorder={false}
      />
    </Modal>
  );
};

export default ModalUnsavedPreset;

import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  restoreObjective,
  updateStateObjective
} from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import useModalObjectives from 'hooks/useModalObjectives';
import useTeam from 'hooks/useTeam';
import { useUrl } from 'hooks/useUrl';
import useValidationMinMax from 'hooks/useValidationMinMax';
import {
  getObjectiveSummaryPdf,
  getObjectiveSummaryPdfToast
} from 'utils/ObjectivesHelper';

export default function useDropdown({
  objective,
  objectiveConfigs,
  page,
  permissions = [],
  onChangeEditTask,
  isTaskBoard,
  isInNewProjectPage,
  isProjectDetailPage,
  isTeamGoalsApproval,
  onComplete,
  groupName,
  sectionName,
  level,
  isCompact,
  clusters
}) {
  let history = useHistory();
  const params = useParams();
  const { user, config } = useUser();
  const {
    refetchObjectives,
    refetchObjective,
    refetchSubObjectives,
    refetchQueries,
    invalidateQueries
  } = useRefetchQuery();

  const { pathname } = useLocation();
  const { url } = useUrl();
  const urlSearchParams = new URLSearchParams(window.location.search);

  const [isParentObjective, setIsParentObjective] = useState(false);
  const {
    showModalValidationDeleteGoal,
    checkValidationDeleteGoal,
    _handleActionCloseModal,
    totalObjectives: totalObjectivesQty
  } = useValidationMinMax();
  const { setIsAddingApprovalSubGoal } = useTeam();
  const { addToast } = useToastContext();

  let isParent = objective?.parent?.id ? false : true;

  const isInAssignment = pathname.includes('/assignments');
  const isInNewTeamPage = pathname.includes('/teams');

  const { reload, reloadOverallProgress, reloadOverallStatus, reloadSidebar } =
    useReload();

  const changeProjectInvolvements = [
    'assign_leader',
    'assign_reviewer',
    'assign_owner',
    'assign_follower',
    'assign_pmo'
  ].some((permission) => permissions?.includes(permission));

  let menuAll = {
    addGoal: {
      title: 'Add Sub Goal',
      icon: 'icon-add',
      onClick: handleClick,
      type: 'addgoal',
      isVisible: !isInAssignment && permissions?.includes('add_subgoal'),
      dataCy: 'goal-add-sub-goal'
    },
    addTask: {
      title: 'Add Task',
      icon: 'icon-add',
      onClick: handleClick,
      type: 'addtask',
      isVisible: !isInAssignment && permissions?.includes('add_subtask'),
      dataCy: 'goal-add-sub-task'
    },
    addProject: {
      title: 'Add Project',
      icon: 'icon-add',
      onClick: handleClick,
      type: 'addproject',
      isVisible: !isInAssignment && permissions?.includes('add_subproject'),
      dataCy: 'goal-add-sub-project'
    },
    markAsComplete: {
      title: 'Mark as Complete',
      icon: 'icon-check_circle_outline',
      onClick: handleClick,
      type: 'complete',
      isVisible:
        isInNewProjectPage &&
        objective?.stateObject?.state !== 'completed' &&
        permissions?.includes('complete'),
      dataCy: 'goal-mark-as-complete'
    },
    setAsIncomplete: {
      title: 'Set as Incomplete',
      icon: 'icon-check_circle_outline',
      onClick: handleClick,
      type: 'incomplete',
      isVisible:
        isInNewProjectPage &&
        objective?.stateObject?.state === 'completed' &&
        permissions?.includes('uncomplete'),
      dataCy: 'goal-set-as-incomplete'
    },
    edit: {
      title:
        objective?.type == 'task'
          ? 'Edit Task'
          : objective?.type == 'goal' && objective?.isProject
          ? 'Edit Project'
          : 'Edit Goal',
      icon: 'icon-create',
      onClick: handleClick,
      type: 'edit',
      isVisible:
        !isTaskBoard && !isInNewProjectPage && permissions?.includes('update'),
      dataCy: 'goal-edit-goal'
    },
    editName: {
      title: objective?.type == 'task' ? 'Edit Task Name' : 'Rename Project',
      icon: 'icon-create',
      onClick: handleClick,
      type: 'edit',
      isVisible:
        (isTaskBoard || isInNewProjectPage) && permissions?.includes('update'),
      dataCy: 'goal-edit-name'
    },
    addSubTask: {
      title: 'Add Sub Task',
      icon: 'icon-add',
      onClick: handleClick,
      type: 'addSubTask',
      isVisible: (isTaskBoard || isInNewProjectPage) && level === 0
    },
    editMemberProject: {
      title: 'Edit Member',
      icon: 'icon-person',
      onClick: handleClick,
      type: 'editMemberProject',
      isVisible:
        isInNewProjectPage &&
        !isInNewTeamPage &&
        changeProjectInvolvements &&
        urlSearchParams.get('tab') !== 'overview'
    },
    progressOvertime: {
      title: 'Progress Over Time',
      icon: 'icon-show_chart',
      onClick: handleClick,
      type: 'progressovertime',
      isVisible: config?.enableProgressOverTime && !objective?.organization?.id,
      dataCy: 'dropdown-progress-overtime'
    },
    alignToParent: {
      title: 'Align To Parent',
      icon: 'icon-arrow_upward',
      onClick: handleClick,
      type: 'alignToParent',
      isVisible:
        permissions?.includes('align_parent') && clusters?.includes('parent'),
      dataCy: 'align-parent-button'
    },
    exportSummary: {
      title: 'Export Summary',
      icon: 'icon-file_download',
      onClick: handleClick,
      type: 'exportSummary',
      isVisible:
        objective?.type == 'goal' &&
        !objective?.isProject &&
        !page?.includes('directReport+indirect') &&
        config?.exportObjectivesSummaryFeature &&
        permissions?.includes('export_summary'),
      dataCy: 'dropdown-export-summary'
    },
    goToProjectPage: {
      title: 'Go to Project Page',
      icon: 'icon-dvr',
      onClick: handleClick,
      type: 'project',
      isVisible:
        objective?.isProject &&
        config?.permissions?.projectSee &&
        !isProjectDetailPage &&
        (!isInNewProjectPage || isInNewTeamPage),
      dataCy: 'dropdown-project'
    },
    clone: {
      title:
        objective?.type == 'task'
          ? 'Clone Task'
          : objective?.type == 'goal' && objective?.isProject
          ? 'Clone Project'
          : 'Clone Goal',
      icon: 'icon-content_copy',
      onClick: handleClick,
      type: 'clonegoal',
      isVisible: config?.objectiveClone && !objective?.organization?.id,
      dataCy: 'dropdown-clone'
    },
    delete: {
      title:
        objective?.type == 'task'
          ? 'Delete Task'
          : objective?.type == 'goal' && objective?.isProject
          ? 'Delete Project'
          : 'Delete Goal',
      icon: 'icon-delete',
      onClick: handleClick,
      type: 'delete',
      isVisible:
        !isInAssignment &&
        objective?.stateObject?.state !== 'to_be_deleted' &&
        permissions?.includes('delete'),
      colorHex: 'var(--r-500)',
      textColor: 'text-r-500',
      dataCy: 'goal-delete-goal'
    },
    restore: {
      title: `Restore ${objective?.type == 'task' ? 'Task' : 'Goal'}`,
      icon: 'icon-restore_from_trash',
      onClick: handleClick,
      type: 'restore',
      isVisible: !isInAssignment && permissions?.includes('restore'),
      dataCy: 'goal-restore-goal'
    },
    editReview: {
      title: 'Edit Review',
      icon: 'icon-emoji_objects',
      onClick: handleClick,
      type: 'editreview',
      isVisible: permissions?.includes('edit_review')
    },
    pivot: {
      title: 'Change Pivot',
      icon: 'icon-swap_horiz',
      onClick: handleClick,
      type: 'pivot',
      isVisible: pathname.includes('tree')
    }
  };

  const getRedirectToUrl = (url) => {
    if (url.includes('company')) {
      return '/goals/level/company';
    } else if (url.includes('users')) {
      return url.replace(/\/objectives/, '');
    } else if (url.includes('projects')) {
      return '/projects';
    } else {
      return '/goals';
    }
  };

  async function deleteObjective(deleteChildren) {
    let body = { state: 'deleted', top_parent: null, deleteChildren };
    await updateStateObjective(objective.id, body);
    closeModal();
    await refetchObjectives(page);

    if (isTeamGoalsApproval) {
      refetchQueries(['teamGoals', 'approval']);
    }

    // For goal tree deletion
    // if objective (which not a pivot) has parent than refetch sub objectives of the parent
    // if pivot is deleted then redirect to /goals
    const isPivot = page == 'tree' && params.parentId == objective.id;
    if (objective?.parent?.id && !isPivot) {
      await refetchObjective(objective?.parent?.id);
      await refetchSubObjectives(objective?.parent?.id);
    } else {
      history.replace({
        pathname: getRedirectToUrl(url),
        search: location.search
      });
    }

    reload({
      reloadType: 'delete',
      reloadApprovalPanel: ['edited', 'to_be_deleted']
    });

    _handleActionCloseModal({
      typeModal: 'showModalValidationDeleteGoal'
    });

    // refetch Approval Availability
    await refetchQueries(['approvalAvailability', user?.id]);
    await refetchObjective(objective.id);

    isProjectDetailPage && history.replace('/projects');
  }

  async function restoreObjectives(userId, callbackFn) {
    await restoreObjective(objective.id);
    await refetchObjectives();
    await refetchQueries(['approvalAvailability', userId]);
    await refetchObjective(objective.id);
    callbackFn && (await callbackFn());
    reload({
      reloadApprovalPanel: ['to_be_deleted']
    });
    closeModal();
  }

  async function deleteTask(callbackFn, deleteChildren) {
    let body = { state: 'deleted', top_parent: null, deleteChildren };

    await updateStateObjective(objective.id, body);

    if (sectionName && groupName) {
      refetchQueries(['objectives', 'mytasks', groupName, sectionName]);
    } else if (groupName) {
      refetchQueries(['objectives', 'mytasks', groupName]);
    } else {
      refetchObjectives(page);
    }

    callbackFn && (await callbackFn());

    if (objective?.parent?.id) {
      await invalidateQueries(['objective', objective?.parent?.id]);
    }

    closeModal();
  }

  async function completeGoal(newState) {
    const res = await updateStateObjective(objective?.id, newState);
    const { data } = res;
    reload({
      reloadObjectiveId: { id: objective?.id },
      reloadType: 'complete',
      reloadSidebar: !reloadSidebar,
      reloadOverallProgress: !reloadOverallProgress,
      reloadOverallStatus: !reloadOverallStatus
    });
    onComplete && onComplete(data);
    closeModal();
    return res;
  }

  const getMarkAsCompleteProps = () => {
    return {
      objectiveValue: objective,
      objectiveId: objective?.id,
      objectiveName: objective?.name,
      measurement: objective?.measurement,
      currentMilestone: objective?.currentMilestone,
      milestoneType: objective?.milestoneType,
      commentOptions: objective?.commentOptions,
      canUpdateCurrentValue: objective?.permissions?.includes(
        'update_current_value'
      ),
      permission: objective?.permissions?.includes('update_comment'),
      completeGoal: (newState) => completeGoal(newState)
    };
  };

  async function handleClick(menu, callbackFn) {
    let type = menu?.type;

    if (type === 'delete' && objective?.type !== 'task') {
      setIsParentObjective(isParent);
      const { isSettingMinObjectivesOn } = await checkValidationDeleteGoal();
      if (!isSettingMinObjectivesOn) {
        showModal({
          modalType: 'confirmDelete',
          submitModal: deleteObjective,
          props: {
            typeObjective: objective.type,
            totalSubgoal: objective?.childrenCount
          }
        });
      }
    } else if (type === 'delete' && objective?.type === 'task') {
      showModal({
        modalType: 'confirmDeleteTask',
        submitModal: (deleteChildren) => deleteTask(callbackFn, deleteChildren),
        props: {
          typeObjective: objective.type,
          totalSubgoal: objective?.childrenCount
        }
      });
    } else if (type === 'restore') {
      showModal({
        modalType: 'restore',
        submitModal: () => restoreObjectives(user?.id, callbackFn),
        props: { typeObjective: objective.type }
      });
    } else if (type === 'progressovertime') {
      if (isCompact) {
        return window.open(`/progress-overtime/${objective?.id}`, '_blank');
      }
      history.replace({
        pathname: `/progress-overtime/${objective?.id}`,
        state: { prevPath: location.pathname }
      });
    } else if (type === 'pivot') {
      const locationSearch = objective?.organization?.id
        ? `?organizationId=${objective?.organization?.id}`
        : '';
      window.location.assign(`/tree/${objective?.id}${locationSearch}`);
    } else if (type === 'editreview') {
      showModal({ modalType: 'review', props: { objectiveDetail: objective } });
    } else if (type === 'clonegoal') {
      showModal({
        modalType: 'cloneGoal',
        props: { objectiveDetail: objective }
      });
    } else if (type === 'edit' && objective?.type === 'task' && isTaskBoard) {
      onChangeEditTask();
    } else if (type === 'edit' && objective?.isProject && isInNewProjectPage) {
      showModal({
        modalType: 'editProjectName',
        props: { objectiveDetail: objective }
      });
    } else if (type === 'project') {
      history.replace(`/projects/${objective?.id}`);
    } else if (type === 'editMemberProject') {
      callbackFn && callbackFn();
      history.replace(`/projects/${objective.id}?tab=overview`);
    } else if (type === 'complete') {
      callbackFn && callbackFn();
      showModal({ modalType: 'complete', props: getMarkAsCompleteProps() });
    } else if (type === 'incomplete') {
      callbackFn && callbackFn();
      showModal({
        modalType: 'incomplete',
        props: {
          objective: objective,
          onIncomplete: (data) => onComplete(data)
        }
      });
    } else if (type === 'exportSummary') {
      callbackFn && callbackFn();
      let owner = user;
      let placementId = null;
      if (page?.includes('directReport')) {
        const splitPage = page?.split('+');
        owner = objective?.involvements?.find(
          (involvement) => involvement?.user?.id == splitPage[2]
        )?.user;
      }
      if (page?.includes('performanceTab')) {
        owner = objective?.involvements?.find(
          (involvement) => involvement?.user?.id == params?.userId
        )?.user;
        placementId = params?.placementId;
      }
      addToast(getObjectiveSummaryPdfToast(owner, user));
      getObjectiveSummaryPdf({
        ownerId: owner?.id,
        recipientId: user?.id,
        placementId,
        parentObjective: objective
      });
    } else if (type === 'addSubTask') {
      callbackFn && callbackFn();
    } else if (type === 'alignToParent') {
      showModal({
        modalType: 'menuAlign',
        props: {
          objective,
          page,
          permissions,
          isMenuAlignValidate: true
        }
      });
    } else {
      history.replace({
        pathname:
          history.location.pathname.includes('users') &&
          !history.location.pathname.includes('objectives')
            ? `${url}/objectives/${type}/${objective?.id}`
            : `${url}/${type}/${objective?.id}`,
        search: location.search,
        state: { isParentObjective: isParent }
      });
      if (page?.includes('myTeamGoals') && type == 'addgoal') {
        if (objectiveConfigs?.approvalRequired) {
          setIsAddingApprovalSubGoal(true);
        }
      }
    }
  }

  function useMenu({ only, except }) {
    let dataReturn = [];
    if (only?.length > 0) {
      only.map((menuKey) => {
        if (menuAll[menuKey]?.isVisible) {
          dataReturn.push(menuAll[menuKey]);
        }
      });
    } else if (except?.length > 0) {
      Object.keys(menuAll).map((key) => {
        if (!except.includes(key) && menuAll[key]?.isVisible) {
          dataReturn.push(menuAll[key]);
        }
      });
    }

    return dataReturn;
  }

  const showModal = useModalObjectives((state) => state.showModal);
  const closeModal = useModalObjectives((state) => state.closeModal);
  const modalProps = {
    type: 'ValidationDeleteGoal',
    _handleActionModal: () =>
      _handleActionCloseModal({
        typeModal: 'showModalValidationDeleteGoal'
      }),
    _handleNext: deleteObjective,
    datacy: 'goal-confirm-delete-goal',
    totalObjectives: totalObjectivesQty,
    totalSubgoal: objective?.childrenCount,
    objectiveDetail: objective
  };

  const firstRender = useRef(false);
  useEffect(() => {
    if (firstRender.current) {
      if (showModalValidationDeleteGoal) {
        showModal({ modalType: 'minMaxValidation', props: modalProps });
      }

      if (!showModalValidationDeleteGoal) {
        closeModal();
      }
    } else {
      firstRender.current = true;
    }
  }, [showModalValidationDeleteGoal]);

  return { useMenu };
}

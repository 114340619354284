import React from 'react';
import { withRouter } from 'react-router';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';

function User(props) {
  return (
    <div className="col-xs-4 analytic-user">
      <div className="item item-search-result">
        <div className="basic-info">
          <div className="userbar-image img-wrapper-profile">
            <Avatar
              src={props?.user?.profilePicture}
              name={props?.user?.name}
              size={48}
            />
            <span className="userbar-workload manageable" />
          </div>
          <div className="user-info">
            <p className="new-h4 truncate">{props?.user?.name}</p>
            <p className="sm-desc">{props?.user?.jobTitle}</p>
          </div>
        </div>
        <div className="user-performance-container">
          {props?.user?.reviewedObjectiveCount === 0 ? (
            <div className="empty-card-title-container">
              {getObjectiveLocale('No Task Added Yet')}
            </div>
          ) : (
            <span>
              <div>
                <span className="label title">
                  {getObjectiveLocale('Overall Performance')}
                </span>
              </div>
              <div className="performance-container-label">
                <span className="label performance-result">
                  {props?.user?.scoringMark?.name}
                </span>
              </div>
              <div className="performance-count-container">
                <span className="label sm-desc">
                  {getObjectiveLocale('Based on')}{' '}
                  {getNumberLocale(props?.user?.reviewedObjectiveCount)}{' '}
                  {getObjectiveLocale('performance reviews')}
                </span>
              </div>
            </span>
          )}
        </div>
        <div className="active-task-container">
          <div className="active-task-value">
            {getNumberLocale(props?.user?.objectiveCount)}
          </div>
          <div className="active-task-info">
            <div className="active-task-title">
              {getObjectiveLocale('Active Task')}
            </div>
            <div className="active-task-report">
              {getObjectiveLocale('Report to')}{' '}
              {getNumberLocale(props?.user?.assignerCount)}{' '}
              {getObjectiveLocale('people')}{' '}
            </div>
          </div>
        </div>
        <span>
          <div className="strength-title">{getObjectiveLocale('Strength')}</div>
          <div className="strength-list">
            {props?.user?.skills?.length === 0 ? (
              <div className="col-xs-12">
                <label className="no-strength">
                  {getObjectiveLocale('this user has no strength')}
                </label>
              </div>
            ) : (
              props?.user?.skills?.map(function (data, idx) {
                return (
                  <div className="search-result-container-list" key={idx}>
                    <div className="strength-value">
                      {getNumberLocale(data?.strengthCount)}
                    </div>
                    <div className="strength-info">
                      <div className="strength-name">{data?.skill}</div>
                      <div className="strength-report">
                        {getObjectiveLocale('Sent by')}{' '}
                        {getNumberLocale(data?.fromCount)}{' '}
                        {getObjectiveLocale('people')}
                      </div>
                    </div>
                  </div>
                );
              }, this)
            )}
          </div>
        </span>
      </div>
      <br />
      <br />
    </div>
  );
}

export default withRouter(User);

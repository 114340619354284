import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';

import { getLeagueById, postLeague, updateLeague } from 'client/adminClient.js';
import { getCultureLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Button from 'components/design-system/Button';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import Footer from 'components/shared/Footer';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';

import Badges from './Badges';
import Events from './Events';

function CreateLeague({ route: { title, isReadOnly } }) {
  const history = useHistory();
  const params = useParams();
  const group = [
    { text: 'National', onClick: () => handleClickGroup('National') },
    { text: 'Region', onClick: () => handleClickGroup('Region') },
    { text: 'Division', onClick: () => handleClickGroup('Division') },
    { text: 'Department', onClick: () => handleClickGroup('Department') },
    { text: 'Event', onClick: () => handleClickGroup('Event') }
  ];
  const [lastSelectedGroup, setLastSelectedGroup] = useState('');
  const [league, setLeague] = useState({
    name: '',
    sourceInfo: 'default',
    isEvent: false,
    missionIds: [],
    badges: [
      {
        title: '',
        badgeType: 'badge',
        rankMax: 0,
        rankMin: 0,
        badgeIconUrl: null
      }
    ]
  });
  const newBadge = {
    title: '',
    badgeType: 'badge',
    rankMax: 0,
    rankMin: 0,
    badgeIconUrl: null
  };
  const [modal, setModal] = useState({
    isShowing: false,
    type: 'selectedEvents' // selectedEvents, duplicateLeague
  });

  const handleClickGroup = (e) => {
    setLastSelectedGroup(league?.sourceInfo);
    setLeague({
      ...league,
      sourceInfo: e === 'National' ? 'default' : e,
      isEvent: e === 'Event'
    });
  };

  const addBadge = () => {
    const temp = cloneDeep(league);
    const tempBadge = cloneDeep(newBadge);
    temp.badges.push(tempBadge);
    setLeague(temp);
  };

  const deleteBadge = (index) => {
    let newTemp = cloneDeep(league);
    let newBadges = newTemp.badges.filter((data, id) => id !== index);
    newTemp.badges = newBadges;
    setLeague(newTemp);
  };

  const handleChangeBadge = (e, index, type) => {
    let newTemp = cloneDeep(league);
    let currentBadge = newTemp?.badges[index];
    if (currentBadge) {
      if (type == 'rankMin' || type == 'rankMax') {
        currentBadge[type] = parseInt(e);
      } else {
        currentBadge[type] = e;
      }
    }
    setLeague(newTemp);
  };

  const getLeague = async (id) => {
    const { data } = await getLeagueById(id);
    if (data) {
      let newData = data;
      newData.missionIds = data.isEvent
        ? data?.events?.map((value) => value?.id)
        : [];
      setLeague(newData);
    }
  };

  const submit = async () => {
    const body = league;
    const { data } = params?.id
      ? await updateLeague(params?.id, body)
      : await postLeague(body);
    if (data) {
      history.replace('/missions?tab=leaderboard');
    }
  };

  useEffect(() => {
    if (params?.id) {
      getLeague(params?.id);
    }
  }, []);

  return (
    <>
      <HeaderPage
        titlePage={title}
        backToUrl={'/missions?tab=leaderboard'}
        isHeaderComposer={true}
      />
      <div className="bg-n-000 flex justify-center items-center">
        <div className=" w-[720px]">
          <div className="wrapper-league wrapper-create-league bg-n-000 mt-[24px]">
            <div className="form-group pb-[40px] border-bottom-400">
              <p className="typography-h500-longform text-n-900 mb-[4px]">
                {getCultureLocale('Title')}
              </p>
              <p className="typography-secondary typography-paragraph mb-[16px]">
                {getCultureLocale(
                  'Something clear and easily to be recognized.'
                )}
              </p>
              <p className="typography-h100 typography-secondary mb-[8px]">
                {getCultureLocale('League Title')}
              </p>
              <InputField
                dataCy="name-league"
                placeholder="Type here..."
                value={league?.name}
                handleChange={(e) =>
                  setLeague({ ...league, name: e.currentTarget.value })
                }
              />
            </div>
            <div className="form-group mt-[40px] pb-[40px] border-bottom-400">
              <p className="typography-h500-longform text-n-900 mb-[4px]">
                {getCultureLocale('Grouping')}
              </p>
              <p className="typography-secondary typography-paragraph mb-[16px]">
                {getCultureLocale(
                  'This setting will group users based on their details.'
                )}
              </p>
              <p className="typography-h100 typography-secondary mb-[8px]">
                {getCultureLocale('Group by')}
              </p>
              <Dropdown>
                <Dropdown.Trigger dataCy="group-by-dropdown">
                  <Trigger
                    customWrapperClass="w-[240px]"
                    topLabel={false}
                    label={false}
                    icon={false}
                    text={league?.sourceInfo}
                  />
                </Dropdown.Trigger>
                <Dropdown.MenuItems
                  options={group}
                  customClass="w-[240px]"
                  dataCy="group-by-dropdown"
                />
              </Dropdown>
              {league?.isEvent && (
                <Events
                  league={league}
                  setLeague={setLeague}
                  modal={modal}
                  setModal={setModal}
                  lastSelectedGroup={lastSelectedGroup}
                />
              )}
            </div>
            <div className="form-group mt-[40px] pb-[40px]">
              <p className="typography-h500-longform text-n-900 mb-[4px]">
                {getCultureLocale('Badge')}
              </p>
              <p className="typography-secondary typography-paragraph mb-[16px]">
                {getCultureLocale(
                  'Define badge options that will represent all ranks in this league.'
                )}
              </p>
              {league?.badges?.map((badge, index) => {
                return (
                  <Badges
                    key={index}
                    index={index}
                    badge={badge}
                    onDelete={deleteBadge}
                    onChange={handleChangeBadge}
                  />
                );
              })}
              <div className="bg-n-200 h-[116px] flex justify-center items-center border-n300 rounded-[4px]">
                <Button.Secondary
                  datacy={'create-badge'}
                  onClick={() => addBadge()}
                >
                  <SVGIcon
                    iconName="icon-add"
                    size="24"
                    fillColor="var(--base-600)"
                  />
                  <p>{getCultureLocale('Create New Badge')}</p>
                </Button.Secondary>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        secondaryButton={true}
        linkButtonName={'Cancel'}
        save={true}
        datacy={'btn-submit'}
        handleTesting="btn-submit"
        handleClick={() => submit()}
        customClass={'w-[720px]'}
        customButtonContainer={'pr-[0px] py-[14px]'}
        handleRoute={() => history.replace('/missions?tab=leaderboard')}
        style={{ display: 'flex', justifyContent: 'center' }}
      />
    </>
  );
}

export default CreateLeague;

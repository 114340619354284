import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import flatten from 'lodash/flatten';

import { getNotificationList } from 'client/ActivitiesClient';
import { deleteActivitiesCount, getActivities } from 'client/ObjectivesClient';
import {
  getCountStrength,
  getCountWeakness,
  getDetailStrength,
  getDetailWeakness
} from 'client/UserProfile';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';

import ActivityCardBar from 'components/activity/ActivityCardBar';
import HeaderPage from 'components/admin/HeaderPage';
import ActivitiesEmptyState from 'components/shared/ActivitiesEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import ToastNotif from 'components/shared/ToastNotif';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import SidebarBehaviour from 'components/user-profile/tabs/SidebarBehaviour';

function Activities({ route: { title } }) {
  const { user, config } = useUser();
  const { match } = useUrl();
  const history = useHistory();
  const { reload, reloadActivityNotifCount } = useReload();
  const isCultureOnly = user.cultureRole && !user.role;
  const tabNow = config?.permissions?.activitiesSelfSee
    ? 'my-goals'
    : config?.permissions?.activitiesAsReviewerSee
    ? 'reviewer'
    : 'follower';
  const [currentTab, setCurrentTab] = useState(
    isCultureOnly ? 'culture' : history?.location?.state || tabNow
  );
  const [activities, setActivities] = useState([]);
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [toastData, setToastData] = useState({ isShow: false, data: [] });

  const [totalCulture, setTotalCulture] = useState(0);
  const [currentTabs, setCurrentTabs] = useState('');
  const [totalPerformance, setTotalPerformance] = useState(0);
  const [detailData, setDetailData] = useState([]);
  const [showSidebarSkills, setShowSidebarSkills] = useState(false);
  const [type, setType] = useState('');
  const [userId, setUserId] = useState(0);
  const [goalsCounter, setGoalsCounter] = useState(0);
  const [reviewerCounter, setReviewerCounter] = useState(0);
  const [followerCounter, setFollowerCounter] = useState(0);
  const [surveyCounter, setSurveyCounter] = useState(0);
  const [hasMore, sethasMore] = useState(false);
  const scrollRef = useRef(null);
  const lastActivityRef = useRef(null);

  const getDetailSkill = async (
    activityId,
    behaviourId,
    type,
    currentBehaviour,
    userId,
    resource
  ) => {
    const query = {
      recognitionFeedbackId: activityId,
      source: resource
    };
    setCurrentTabs(currentBehaviour);
    const behaviorType = type === 'value' ? 'value' : 'competency';

    const detailStrength =
      currentBehaviour === 'strength'
        ? await getDetailStrength(userId, behaviorType, behaviourId, query)
        : await getDetailWeakness(userId, behaviorType, behaviourId, query);
    const countStrength =
      currentBehaviour === 'strength'
        ? await getCountStrength(userId, behaviorType, behaviourId, query)
        : await getCountWeakness(userId, behaviorType, behaviourId, query);

    const { data: dataCount } = countStrength;
    const { data, isSuccess } = detailStrength;

    if (dataCount) {
      setTotalCulture(dataCount?.cultureCount);
      setTotalPerformance(dataCount?.performanceCount);
    }
    if (isSuccess && data) {
      setUserId(userId);
      setDetailData(data);
      setType(behaviorType == 'value' ? 'values' : 'skills');
      history.replace('/activities');
      setShowSidebarSkills(true);
    }
  };

  const getParams = (type) => {
    let params = {};
    switch (type || currentTab) {
      case 'my-goals':
        params['type'] = 'assignee';
        break;
      case 'reviewer':
        params['type'] = 'assigner';
        break;
      case 'follower':
        params['type'] = 'follower';
        break;
      case 'survey':
        params['type'] = 'survey';
        break;
      default:
        break;
    }

    return params;
  };

  const _fetchActivities = async (olderThan) => {
    let params = getParams();
    if (olderThan) {
      params.olderThan = olderThan;
    }
    params.limit = 10;
    return getNotificationList(params);
  };

  const queryKey = ['notification', currentTab, getParams()];

  const { data, isSuccess, isFetching, fetchNextPage } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => _fetchActivities(pageParam),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || null
    }
  );

  useEffect(() => {
    if (data && isSuccess) {
      const flattenData = flatten(data.pages?.map((page) => page.data)).filter(
        (data) => data
      );
      setActivities(flattenData);
      const length = flattenData.length;
      sethasMore(length && length % 10 == 0);
    }
  }, [data, isSuccess, currentTab]);

  const getCounter = async (type) => {
    let params = getParams(type);
    const { data } = await getActivities(params);
    if (data) {
      params.type === 'assignee' && setGoalsCounter(data);
      params.type === 'assigner' && setReviewerCounter(data);
      params.type === 'follower' && setFollowerCounter(data);
      params.type === 'survey' && setSurveyCounter(data);
    }
  };

  const getAllCounter = () => {
    getCounter('my-goals');
    getCounter('reviewer');
    getCounter('follower');
    getCounter('survey');
  };

  const readAllActivities = async () => {
    const { isSuccess } = await deleteActivitiesCount(0);
    if (isSuccess) {
      setToastData({
        isShow: true,
        warning: !isSuccess,
        message: `${isSuccess ? 'Success' : 'Failed'} read all activities`
      });
      setGoalsCounter(0);
      setReviewerCounter(0);
      setFollowerCounter(0);
      setSurveyCounter(0);
      reload({
        reloadActivityNotifCount: !reloadActivityNotifCount
      });
    }
  };

  useEffect(() => {
    getAllCounter();
  }, []);

  useEffect(() => {
    if (scrollRef?.current && activities.length > 0) {
      scrollRef.current.scrollTo({ top: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const headerProps = {
    titlePage: title,
    secondaryAction: {
      title: 'Mark all as read',
      onClick: () => readAllActivities()
    }
  };

  const listTabActivities = [];
  config?.permissions?.activitiesSelfSee &&
    listTabActivities.push({
      id: 'my-goals',
      name: 'My Goals/Tasks',
      count: goalsCounter
    });
  config?.permissions?.activitiesAsReviewerSee &&
    listTabActivities.push({
      id: 'reviewer',
      name: 'As Reviewer',
      count: reviewerCounter
    });
  config?.permissions?.activitiesAsFollowerSee &&
    listTabActivities.push({
      id: 'follower',
      name: 'As Follower',
      count: followerCounter
    });
  config?.permissions?.surveysAnswersSee &&
    listTabActivities.push({
      id: 'survey',
      name: 'Pulse Survey',
      count: surveyCounter
    });

  useEffect(() => {
    const el = scrollRef?.current;
    const target = lastActivityRef?.current;
    if (target && el && activities.length > 0) {
      let options = {
        root: el,
        rootMargin: '0px',
        threshold: 1.0
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(async (entry) => {
          if (entry.isIntersecting && hasMore && !onLoadMore) {
            setOnLoadMore(true);
            await fetchNextPage();
            setOnLoadMore(false);
          }
        });
      }, options);
      observer.observe(target);
      return () => {
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities, hasMore, onLoadMore]);

  useEffect(() => {
    if (toastData?.isShow) {
      setTimeout(() => {
        setToastData({
          isShow: false,
          message: toastData?.message,
          isWarning: toastData?.isWarning
        });
      }, 3000);
    }
  }, [toastData, setToastData]);

  return (
    <>
      <HeaderPage
        {...headerProps}
        tabs={{
          tabsList: listTabActivities,
          url: '/activities',
          onChange: (tab) => {
            setCurrentTab(tab);
          },
          withBadge: true,
          withCount: true
        }}
      />
      <ToastNotif
        showNotif={toastData?.isShow}
        warning={toastData?.warning}
        message={toastData?.message}
      />
      <div
        id="content-container"
        className="pt-[24px] overflow-auto h-[calc(100%-112px)]"
        ref={scrollRef}
      >
        {
          <div className={' grid gap-[24px] mb-[24px]'}>
            <div className={'col-4-by-4'}>
              <ul className={'activities-wrapper'}>
                {!isFetching && activities?.length === 0 && (
                  <ActivitiesEmptyState />
                )}
                {activities?.length !== 0 &&
                  activities?.map((activity, index) => (
                    <ActivityCardBar
                      key={activity.id}
                      activity={activity}
                      currentTab={currentTab}
                      index={index}
                      getDetailSkill={getDetailSkill}
                      getCounter={getCounter}
                    />
                  ))}
                {(isFetching || onLoadMore) && <LoadingComponent />}
                {match && <SidebarMyGoals />}

                <div ref={lastActivityRef} />
              </ul>
            </div>
          </div>
        }
      </div>
      {showSidebarSkills && detailData && !match && (
        <SidebarBehaviour
          behaviourData={detailData}
          cultureCount={totalCulture}
          performanceCount={totalPerformance}
          type={currentTabs}
          closeSidebar={() => setShowSidebarSkills(false)}
          userId={userId}
          behaviorType={type}
        />
      )}
    </>
  );
}

export default Activities;

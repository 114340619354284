import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import 'scss/culture/share-post';

import { getPostData } from 'client/culture/SharePost';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { userAgentUtils } from 'utils/UserAgentUtils';

import AWSImage from 'components/design-system/AWSImage';
import LoadingSplashScreen from 'components/shared/LoadingSplashScreen';
import { useUser } from 'src/context/UserContext';

const LandingPageSharePost = () => {
  const { config } = useUser();

  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const platform = userAgentUtils();

  const handleAppStore = () => {
    window.open(
      'https://apps.apple.com/id/app/happy5-culture/id887450182',
      '_blank'
    );
  };

  const fetchData = async () => {
    let token = new URLSearchParams(location.search).get('token');

    const { data, error } = await getPostData(token);
    if (data) {
      if (platform === 'Web' || platform === 'iOS' || platform === 'Android') {
        localStorage.setItem(
          'prevUrlRedirect',
          location.origin + `/timeline/${data.post.id}`
        );
        window.location.href = `/timeline/${data.post.id}`;
      } else {
        setData(data);
        setIsLoading(false);
      }
    } else {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="share-post-culture">
      {isLoading ? (
        <LoadingSplashScreen />
      ) : data ? (
        <div className="share-post-content">
          {data && (
            <AWSImage
              className="icon-app"
              src={`${data.organization.logoUrl}`}
            />
          )}
          <h4 className="content-title">
            Please download{' '}
            {data && (
              <span className="content-title app-name">
                {data.organization.cultureAppName}
              </span>
            )}{' '}
            to see this post.
          </h4>
          <div className="button-container">
            {platform === 'Android' && (
              <img
                className="asset google-play"
                src={Config.asset.culture.googlePlay}
                onClick={() => window.open(data.organization.cultureAndroidUrl)}
              />
            )}
            {platform === 'iOS' && (
              <img
                className="asset app-store"
                src={Config.asset.culture.appStore}
                onClick={() => handleAppStore(data.organization.teamName)}
              />
            )}
          </div>
          <p className="p-helper">
            Jika mengalami kesulitan, silahkan hubungi{' '}
            <span className="p-helper email-text">
              {config?.supportEmail || getObjectiveLocale('support@happy5.co')}
            </span>
          </p>
        </div>
      ) : (
        <h2 className="error-message">
          <script>window.prerenderReady = true;</script>
          <Helmet>
            <meta name="prerender-status-code" content="404" />
          </Helmet>
          Oops...! {errorMessage}
        </h2>
      )}
    </div>
  );
};
export default LandingPageSharePost;

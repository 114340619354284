import React, { useCallback, useEffect, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';

import { SurveyProvider, useSurveyContext } from 'context/SurveyContext';
import { useUser } from 'context/UserContext';
import usePermission from 'hooks/usePermission';
import { userAgentUtils } from 'utils/UserAgentUtils';
import { SocketChannels, SocketListener } from 'utils/socket';

import PulseSurveyModal from 'components/modal/culture/PulseSurveyModal';
import ErrorBoundary from 'pages/ErrorBoundary';
import routes from 'src/routes';

import {
  QUERY_KEY_PREFERENCE,
  useUserPreferences
} from './hooks/usePreferenceFilter';
import LandingPage from './pages/LandingPage';

function AuthenticatedApp() {
  return (
    <Router>
      <SurveyProvider>
        <AuthenticatedAppContent />
      </SurveyProvider>
    </Router>
  );
}
const AuthenticatedAppContent = () => {
  const data = routes();
  const { user, appType } = useUser();
  const history = useHistory();
  const { surveyId, occurrenceId: occurrenceIdContext } = useSurveyContext();
  const platform = userAgentUtils();

  const [isSurveyShow, setIsSurveyShow] = useState(false);
  const [pulseSurveyId, setPulseSurveyId] = useState('');
  const [surveyOccurrenceId, setSurveyOccurrenceId] = useState('');
  const surveysAnswersSee = usePermission('surveysAnswersSee');
  const isMobileBrowser =
    platform === 'iOS' || platform === 'Android' || platform === 'unknown';

  useUserPreferences(QUERY_KEY_PREFERENCE, { enabled: appType != 'culture' });

  const setupSharedSurvey = ({ surveyId, occurrenceId }) => {
    if (!surveyId || !occurrenceId) return;
    setPulseSurveyId(surveyId);
    setSurveyOccurrenceId(occurrenceIdContext);
    setIsSurveyShow(true);
    return history.replace('/pulse-surveys');
  };

  const setupSurvey = () => {
    let id = user?.survey?.id;
    let occurrenceId = user?.survey?.surveyOccurrenceId;
    if (!id || !occurrenceId) return;
    setPulseSurveyId(id);
    setSurveyOccurrenceId(occurrenceId);
    setIsSurveyShow(true);
  };

  useEffect(() => {
    const isFromShareSurvey = history.location.pathname == '/share/survey';
    if (!isFromShareSurvey && !pulseSurveyId && !surveyOccurrenceId) {
      setupSurvey();
      return;
    }
    setupSharedSurvey({ surveyId, occurrenceId: occurrenceIdContext });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId, setIsSurveyShow, occurrenceIdContext, user.survey, history]);

  useEffect(() => {
    // the count shows the minimum routes (without permission)
    if (data[0].routes.length == 3) {
      history.replace(`/downloads`);
    }
  }, [data[0].routes]);

  return (
    <ErrorBoundary>
      {isMobileBrowser ? (
        <LandingPage />
      ) : (
        data[0].routes.length > 1 && renderRoutes(data)
      )}
      {isSurveyShow && surveysAnswersSee && !isMobileBrowser && (
        <PulseSurveyModal
          pulseSurveyId={pulseSurveyId}
          setIsSurveyShow={setIsSurveyShow}
          surveyOccurrenceId={surveyOccurrenceId}
        />
      )}
    </ErrorBoundary>
  );
};

export default AuthenticatedApp;

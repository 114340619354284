import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { deleteBadge, getListBadges, restoreBadge } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import Table from 'components/design-system/table/Table';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import AdminTabWithCount from 'components/shared/Tabs/AdminTabWithCount';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

function ListBadge() {
  const [badges, setBadges] = useState([]);

  const [tab, setTab] = useState('active');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevDisable, setPrevDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(false);
  const [newerThanId, setNewerThanId] = useState(null);
  const [olderThanId, setOlderThanId] = useState(null);
  const [showTooltips, setShowTooltips] = useState([]);

  const history = useHistory();
  const ref = useRef([]);
  const { config } = useUser();
  const isReadOnly =
    config?.permissions?.cultureSettingsBadgeSee === 'read_only';

  const tabsList = [
    { key: 'active', name: 'Active' },
    { key: 'archived', name: 'Archived' }
  ];

  const tableHeaders = [
    { name: 'no', widthClass: 'w-[56px]' },
    { name: 'icon', widthClass: 'w-[72px]' },
    { name: 'name', widthClass: 'w-[208px]' },
    { name: 'description', widthClass: 'w-[272px]', grow: true },
    { name: 'recipients', widthClass: 'w-[152px]' },
    { name: '', widthClass: 'w-[56px]' },
    { name: '', widthClass: 'w-[56px]' }
  ];

  const getBadges = async (params, type) => {
    let query = params ? params : { limit: 10 };
    setIsLoading(true);

    const { data, pagination } = await getListBadges({ ...query, state: tab });
    if (data) {
      setBadges(data);
      setIsLoading(false);
      setNextDisable(data.length < 10);
      setOlderThanId(pagination.next.olderThan);
      setNewerThanId(pagination.prev.newerThan);
      if (type === 'next') {
        setCurrentPage(currentPage + 1);
        setPrevDisable(false);
      } else if (type === 'prev') {
        setNextDisable(false);
        setCurrentPage(currentPage - 1);
      }
    } else {
      if (type === 'next') {
        setNextDisable(true);
      } else if (type === 'prev') {
        setPrevDisable(true);
      }
    }
  };

  const changeBadgeState = async (id, type) => {
    let data;
    if (type === 'archive') {
      data = await deleteBadge(id);
    }
    if (type === 'restore') {
      data = await restoreBadge(id);
    }
    if (data) {
      getBadges();
    }
  };

  const handleChangePage = (type) => {
    let params = { limit: 10 };
    if (type === 'next') {
      params = { ...params, olderThan: olderThanId };
    } else if (type === 'prev') {
      params = { ...params, newerThan: newerThanId };
    }
    getBadges(params, type);
  };

  const handleClickTab = (id) => {
    setTab(id);
  };

  useEffect(() => {
    getBadges();
  }, [tab]);

  useEffect(() => {
    let tempShowTooltips = [];
    ref.current.map((value) =>
      tempShowTooltips.push(value?.scrollHeight > value?.clientHeight)
    );
    setShowTooltips(tempShowTooltips);
  }, [ref.current.length]);

  return (
    <div className="wrapper-league">
      <div
        className={`border-n400 rounded-[4px] my-[24px] px-[24px] ${
          badges.length === 0 || isLoading ? 'pb-[24px]' : ''
        }`}
      >
        <div className="flex justify-between items-center">
          <div className="py-[16px]">
            <p className="typography-h500-longform">
              {getObjectiveLocale('Badge')}
            </p>
            <p className="typography-paragraph text-n-800">
              {getObjectiveLocale(
                'Create badges and give to users directly without mission'
              )}
            </p>
          </div>
          {!isReadOnly && (
            <Button
              onClick={() =>
                history.push({ pathname: '/missions/create-badge' })
              }
            >
              <SVGIcon
                iconName="icon-add"
                size="24"
                fillColor="var(--n-000)"
                customClass="mr-[4px]"
              />
              {getObjectiveLocale('Create Badge')}
            </Button>
          )}
        </div>
        <AdminTabWithCount
          active={tab}
          listObjTabs={tabsList}
          changeTab={(id) => handleClickTab(id)}
          withCount={false}
          customClassWrapper="h-[32px]"
          customClassTab="h-full flex items-center"
          customClassText="typography-button"
        />
        {isLoading ? (
          <LoadingComponent className="mt-[24px]" />
        ) : badges.length > 0 ? (
          <div className="mt-[24px]">
            <Table
              headers={tableHeaders}
              fixedLeftCount={3}
              fixedRightCount={2}
              fixedLeftGrow={false}
            >
              {badges.map((badge, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Column>{index + 1}</Table.Column>
                    <Table.Column>
                      <AWSImage
                        className="w-[40px] h-[40px]"
                        src={badge?.iconUrl}
                      />
                    </Table.Column>
                    <Table.Column>
                      <p className="typography-paragraph text-n-900 w-full truncate">
                        {badge?.title}
                      </p>
                    </Table.Column>
                    <Table.Column>
                      <TooltipContainer
                        text={badge?.description}
                        show={showTooltips[index] || false}
                        useMaxWidth={false}
                      >
                        <p
                          className="typography-paragraph text-n-900 w-full multiline-text-2"
                          ref={(e) => (ref.current[index] = e)}
                        >
                          {badge?.description}
                        </p>
                      </TooltipContainer>
                    </Table.Column>
                    <Table.Column>
                      <p className="typography-paragraph text-n-900">
                        {badge?.userIds?.length}{' '}
                        {getObjectiveLocale('employees')}
                      </p>
                    </Table.Column>
                    <Table.Column>
                      {!isReadOnly && (
                        <SVGIcon
                          iconName={
                            tab === 'active' ? 'icon-create' : 'icon-reply'
                          }
                          size="24"
                          fillColor="var(--n-600)"
                          customClass="hidden group-hover:flex"
                          onClick={() =>
                            tab === 'active'
                              ? history.push({
                                  pathname: `/missions/edit-badge/${badge?.id}`
                                })
                              : changeBadgeState(badge?.id, 'restore')
                          }
                        />
                      )}
                    </Table.Column>
                    <Table.Column>
                      {tab === 'active' && !isReadOnly && (
                        <SVGIcon
                          iconName="icon-archive"
                          size="24"
                          fillColor="var(--n-600)"
                          customClass="hidden group-hover:flex"
                          onClick={() => changeBadgeState(badge?.id, 'archive')}
                        />
                      )}
                    </Table.Column>
                  </Table.Row>
                );
              })}
            </Table>
            <div className="flex justify-end items-center my-[16px]">
              <p className="typography-button mr-[8px]">
                {getObjectiveLocale('Page')} {currentPage}
              </p>
              <Button.Secondary
                customClass="mx-[8px] px-[4px] py-[4px]"
                disabled={currentPage === 1 ? true : prevDisable}
                onClick={() => handleChangePage('prev')}
              >
                <SVGIcon
                  iconName="icon-navigate_before"
                  size="24"
                  fillColor="var(--n-600)"
                />
              </Button.Secondary>
              <Button.Secondary
                customClass="px-[4px] py-[4px]"
                disabled={nextDisable}
                onClick={() => handleChangePage('next')}
              >
                <SVGIcon
                  iconName="icon-navigate_next"
                  size="24"
                  fillColor="var(--n-600)"
                />
              </Button.Secondary>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center my-[80px]">
            <SVGIcon iconName="icon-no-badge-available" size="160" />
            <p className="typography-h700 mt-[24px]">
              {getObjectiveLocale(
                `There is no ${
                  tab === 'active' ? 'badge available' : 'archived badge'
                }`
              )}
            </p>
            {tab === 'active' && (
              <div className="flex flex-col items-center mt-[12px]">
                <p className="typography-paragraph">
                  {getObjectiveLocale(
                    'Now you can appreciate users activities with badge.'
                  )}
                </p>
                <p className="typography-paragraph mb-[24px]">
                  {getObjectiveLocale(
                    'Start create a new badge and assign to target user'
                  )}
                </p>
                <Button
                  onClick={() =>
                    history.push({ pathname: '/missions/create-badge' })
                  }
                >
                  <SVGIcon
                    iconName="icon-add"
                    size="24"
                    fillColor="var(--n-000)"
                    customClass="mr-[4px]"
                  />
                  {getObjectiveLocale('Create Badge')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ListBadge;

import React from 'react';

import Badge from 'components/shared/Badge';
import CardLabel from 'components/shared/CardLabel/CardLabel';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const ProjectLabel = ({ label }) => {
  const { useBadge, iconLabel, tooltipText } = label;

  let tooltipContent;

  if (iconLabel == 'status') {
    tooltipContent = `Project status: ${tooltipText}`;
  } else if (iconLabel == 'member' || iconLabel == 'leader') {
    tooltipContent = `You are ${tooltipText} of this project`;
  } else if (iconLabel == 'history') {
    tooltipContent = `Last updated: ${tooltipText}`;
  }

  return (
    <TooltipContainer
      show
      position="top"
      text={tooltipContent}
      useMaxWidth={false}
    >
      {useBadge ? (
        <Badge {...label} truncate customMaxWidth="max-w-[92px]" />
      ) : (
        <CardLabel
          {...label}
          truncate
          withSpace={false}
          maxWidthClass="max-w-[100px]"
        />
      )}
    </TooltipContainer>
  );
};

export default ProjectLabel;

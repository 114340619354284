import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import usePermission from 'hooks/usePermission';
import { getExtraInformation, getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import ProfileSection from 'components/shared/Profile/ProfileSection';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

function DropdownProfile({
  isSidebarMaximized = true,
  user,
  manageableStatus,
  setManageableStatus,
  setModalChangePassword,
  updateWorkloadStatus,
  logout
}) {
  const history = useHistory();
  const { config } = useUser();

  const options = [
    {
      text: getObjectiveLocale('My Profile Page'),
      iconName: 'icon-account_circle',
      divider: true,
      condition: usePermission('profileSee'),
      onClick: () => {
        history.replace(`/users/${user.id}/placement/${user.placementId}`);
      },
      closeOnClick: true
    },
    {
      text: [
        getObjectiveLocale('Manageable'),
        getObjectiveLocale('Unmanageable')
      ],
      iconName: [
        'icon-sentiment_very_satisfied',
        'icon-sentiment_very_dissatisfied'
      ],
      divider: false,
      condition: config?.profileShowWorkloadStatus,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {},
      closeOnClick: false
    },
    {
      text: getObjectiveLocale('Download App'),
      iconName: 'icon-file_download',
      divider: false,
      condition: config?.showDownloadApp,
      onClick: () => {
        history.push('/downloads');
      },
      closeOnClick: true
    },
    {
      text: getObjectiveLocale('Change Password'),
      iconName: 'icon-lock_outline',
      divider: true,
      condition: config?.changePasswordAllowed,
      onClick: setModalChangePassword,
      closeOnClick: true
    },
    {
      text: getObjectiveLocale('Sign Out'),
      iconName: 'icon-exit_to_app',
      divider: false,
      condition: true,
      onClick: logout,
      closeOnClick: true
    }
  ];

  const updateManageableStatus = async (
    e,
    manageableStatus,
    updateWorkloadStatus,
    userId,
    setManageableStatus
  ) => {
    e.preventDefault();
    const newStatus =
      manageableStatus === 'manageable' ? 'unmanageable' : 'manageable';
    const body = { workloadStatus: newStatus };
    const { data } = await updateWorkloadStatus(body, userId);
    if (data) {
      setManageableStatus(data.workloadStatus);
    }
  };

  return (
    <Dropdown customClass="w-full">
      <Dropdown.Trigger>
        {isSidebarMaximized ? (
          <div className="h-[56px] flex justify-between items-center px-[16px]">
            <ProfileSection
              name={user?.name}
              profilePicture={user?.profilePicture}
              useNotif={user?.role}
              status={manageableStatus}
              desc={getExtraInformation(user?.title, user?.subtitle)}
              totalOwners={1}
              customNameJobSectionClass="max-w-[140px] ml-8"
            />
            <SVGIcon
              size="24"
              fillColor="var(--n-000)"
              iconName="icon-arrow_drop_down"
              customClass="dropdown-profile"
            />
          </div>
        ) : (
          <div>
            <ProfileSection
              name={user?.name}
              profilePicture={user?.profilePicture}
              useNotif={user?.role}
              status={manageableStatus}
              desc={user?.jobTitle}
              totalOwners={1}
              showDescription={false}
            />
          </div>
        )}
      </Dropdown.Trigger>
      <Dropdown.MenuItems
        type="fixed"
        customClass={`${
          isSidebarMaximized ? 'left-[16px]' : 'left-[72px]'
        }  } w-[256px]`}
      >
        {options.map((option, index) => {
          if (option?.condition) {
            return (
              <Dropdown.MenuItem
                key={index}
                iconName={
                  index === 1
                    ? manageableStatus === 'manageable'
                      ? option?.iconName[0]
                      : option?.iconName[1]
                    : option?.iconName
                }
                iconColor={index === 1 ? 'var(--g-500)' : 'var(--n-600)'}
                divider={option?.divider}
                onClick={option?.onClick}
                closeOnClick={option?.closeOnClick}
              >
                <p>
                  {index === 1
                    ? manageableStatus === 'manageable'
                      ? option?.text[0]
                      : option?.text[1]
                    : option?.text}
                </p>
                {index === 1 && (
                  <div className="w-full flex justify-end">
                    <SmallToggleSwitchPurple
                      isChecked={
                        manageableStatus === 'manageable' ? true : false
                      }
                      onChange={(e) =>
                        updateManageableStatus(
                          e,
                          manageableStatus,
                          updateWorkloadStatus,
                          user?.id,
                          setManageableStatus
                        )
                      }
                      addClass="h-[16px]"
                    />
                  </div>
                )}
              </Dropdown.MenuItem>
            );
          }
        })}
      </Dropdown.MenuItems>
    </Dropdown>
  );
}

export default DropdownProfile;

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import {
  getDirectManager,
  getIndirectManager
} from 'client/admin/UserManagementClient';
import useUserManagement from 'hooks/useUserManagement';
import { capitalize, getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import Dialog from 'components/shared/Dialog/Dialog';

const ManagerField = ({
  detailPosition,
  setDetailPosition,
  config,
  isReadOnly
}) => {
  const { userId } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [position, setPosition] = useState(null);
  const [selectedManager, setSelectedManager] = useState(
    detailPosition?.directManager
  );
  const [positionSubMenu, setPositionSubMenu] = useState([]);
  const [indirectManager, setIndirectManager] = useState({});

  const isCancelChanges = useUserManagement((state) => state.isCancelChanges);
  const setEditedPosition = useUserManagement(
    (state) => state.setEditedPosition
  );

  const DialogPortal = (props) => {
    let submenu = document.getElementById(
      `sub-menu-templates-${props?.indexPortal}`
    );

    const scrollDiv = document.getElementById('option-dialog');
    const pos = positionSubMenu - scrollDiv?.scrollTop;
    return ReactDOM.createPortal(
      <Dialog {...props} styleContainer={{ top: pos + 'px' }} />,
      submenu
    );
  };

  const _getIndirectManager = async (directData) => {
    const { data } = await getIndirectManager(directData?.id);
    let currentData = cloneDeep(detailPosition);
    currentData.directManager = directData;

    if (data) {
      setIndirectManager(data);
      currentData.indirectManager = data;
    } else {
      currentData.indirectManager = {};
    }
    setEditedPosition(currentData);
    setDetailPosition(currentData);
  };

  const _getManager = ({ q }) => {
    const fetchManager = (pageParam) => {
      let params = {
        q: q,
        excludes: userId,
        olderThan: pageParam
      };
      return getDirectManager(params);
    };

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['manager', { q }],
        ({ pageParam }) => fetchManager(pageParam),
        {
          getNextPageParam: (lastPage, allPages) =>
            lastPage.pagination?.next || 0
        }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  const openDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const setNewManager = (manager) => {
    const allData = {
      id: manager?.id,
      name: position?.name,
      profilePicture: position?.profilePicture,
      jobTitle: manager?.positionName,
      info: {
        workUnit: manager?.workUnit
      },
      placementId: manager?.id
    };
    setSelectedManager(allData);
    _getIndirectManager(allData);
    setPosition(null);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    setSelectedManager(detailPosition?.directManager);
    setIndirectManager(detailPosition?.indirectManager);
  }, [isCancelChanges]);

  const ManagerFieldItem = ({ data, type, customClass, isDisabled }) => {
    return (
      <div
        className={`${customClass} mt-[24px] relative ${
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        onClick={() => !isDisabled && openDialog()}
      >
        <div className="typography-h100 text-n-800 mb-[8px]">
          {capitalize(getObjectiveLocale(`${type} Manager`))}
        </div>
        <div className="flex">
          {isEmpty(data) ? (
            <div className="typography-h100 text-n-600">
              {getObjectiveLocale('No data')}
            </div>
          ) : (
            <>
              <Avatar
                src={data?.profilePicture}
                name={data?.name}
                className="mr-[8px]"
                size={40}
              />
              <div>
                <div className="typography-h500">{data?.name}</div>
                <div className="typography-paragraph text-n-800">
                  {data?.jobTitle || '-'}
                </div>
                {data?.info?.area && (
                  <div className="typography-paragraph text-n-600">
                    {data?.info?.area || '-'}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    !isDialogOpen && setPosition(null);
  }, [isDialogOpen]);

  return (
    <div className="relative flex">
      {config && (
        <>
          <ManagerFieldItem
            data={selectedManager}
            type="direct"
            customClass="w-[344px] mr-[16px]"
            isDisabled={isReadOnly || config === 'disabled'}
          />
          <ManagerFieldItem
            data={indirectManager}
            type="indirect"
            customClass="w-[344px]"
            isDisabled={isReadOnly || true}
          />
        </>
      )}
      <div id={`direct-${selectedManager?.id}`} />
      {isDialogOpen && (
        <Dialog
          title={getObjectiveLocale('Direct Manager')}
          searchPlaceholder={getObjectiveLocale('Search')}
          handleChange={(manager) => {
            !isReadOnly && setPosition(manager);
          }}
          setShowDialog={setIsDialogOpen}
          selectedvalue={selectedManager}
          compareKey="name"
          queryFn={(params) => _getManager(params)}
          containerClassName="mt-[80px]"
          returnSingle
          onHover={(e, manager) => {
            !isReadOnly && setPosition(manager),
              !isReadOnly && setPositionSubMenu(e);
          }}
          listItem={{
            template: 'img-sub-menu',
            config: {
              imgSize: 24,
              labelText: 'name',
              nameSrc: 'name',
              profilePictureSrc: 'profilePicture',
              labelSubtitle: 'class',
              idSrc: 'id'
            }
          }}
        />
      )}
      {position && position?.id && (
        <DialogPortal
          title={getObjectiveLocale('Position')}
          noSearch={true}
          handleChange={(manager) => !isReadOnly && setNewManager(manager)}
          selectedvalue={position.jobPlacements[0]}
          staticOptions={position.jobPlacements}
          indexPortal={position.id}
          returnSingle
          listItem={{
            template: 'default',
            config: {
              labelText: 'positionName',
              labelDescription: 'workUnit',
              labelSize: 'typography-h500',
              labelStartDate: 'startsAt',
              labelEndDate: 'endsAt',
              useGap: false,
              containerClass: 'h-[96px] py-[14px]'
            }
          }}
          containerClassName="ml-[260px] -mt-[20px] w-[360px]"
        />
      )}
    </div>
  );
};

export default ManagerField;

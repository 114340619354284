import React, { useEffect, useRef, useState } from 'react';

import useClickOutside from 'hooks/useClickOutside';

import InlineDialogSelect from './InlineDialogSelect';
import './InlineDialogWrapper.scss';

function InlineDialogWrapper({
  handleChange,
  selectedValue,
  listOfGroups,
  isGroup,
  setShow,
  handleChangeAllGroup,
  type,
  label,
  setSearchGroup = () => {},
  totalGroup
}) {
  const [checkedAll, setCheckedAll] = useState(true);

  const ref = useRef();
  useClickOutside(ref, () => {
    setShow(false);
    setSearchGroup(null);
  });

  const handleCheckedAll = (checked) => {
    if (checked) {
      handleChange(listOfGroups);
      type === 'modal' && handleChangeAllGroup(true);
    } else {
      handleChange([]);
      type === 'modal' && handleChangeAllGroup(false);
    }
    setCheckedAll(checked);
  };

  const _handleChange = (sel, selectedValue) => {
    let newSelectedValue = selectedValue.length > 0 ? [...selectedValue] : [];
    newSelectedValue = newSelectedValue.filter((val) => val.name !== 'Any');

    if (!newSelectedValue.some((value) => value.id === sel.id)) {
      newSelectedValue.push(sel);
    } else {
      newSelectedValue = newSelectedValue.filter(
        (newSelected) => newSelected.id !== sel.id
      );
      type === 'modal' && handleChangeAllGroup(false);
    }

    handleChange(newSelectedValue);
  };

  const isAllCheckHandler = () => {
    let newSelectedValueIds =
      selectedValue.length > 0 ? selectedValue?.map(({ id }) => id) : [];
    let newSelectedValue = listOfGroups.map((val) =>
      newSelectedValueIds.includes(val.id)
    );
    if (
      newSelectedValue.length === totalGroup &&
      newSelectedValue.every((val) => val)
    ) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  };

  useEffect(() => {
    isAllCheckHandler();
  }, [selectedValue]);

  useEffect(() => {
    if (type === 'modal') {
      if (checkedAll) {
        handleChangeAllGroup(true);
      } else {
        handleChangeAllGroup(false);
      }
    }
  }, [checkedAll]);

  return (
    <div className="inline-dialog" ref={ref}>
      <InlineDialogSelect
        selectedValue={selectedValue}
        handleChange={_handleChange}
        listOfGroups={listOfGroups}
        handleCheckedAll={handleCheckedAll}
        checkedAll={checkedAll}
        isGroup={isGroup}
        setSearchGroup={setSearchGroup}
        label={label}
      />
    </div>
  );
}

export default InlineDialogWrapper;

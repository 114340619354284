import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import { getScoringMark } from 'client/admin/AnalyticClient';

import Button from 'components/design-system/Button';
import Checkbox from 'components/shared/Checkbox';

function Search(props) {
  const [scoringOption, setScoringOption] = useState([]);
  const [filter, setFilter] = useState([]);

  function setQuery(id, checked) {
    const keyword = document.getElementById('searchEmployee').value;
    if (!checked) {
      let newFilter = [...filter];
      newFilter = newFilter.filter((val) => val != id);
      setFilter(newFilter);
      props.setQuery({
        q: keyword,
        scoring_mark_id: newFilter
      });
    } else {
      let newFilter = [...filter];
      newFilter.push(id);
      setFilter(newFilter);
      props.setQuery({
        q: keyword,
        scoring_mark_id: newFilter
      });
    }
  }

  const getScoringOption = async () => {
    const { data } = await getScoringMark();
    setScoringOption(data);
  };

  const handleKeyPress = (e) => {
    if (e.key == 'Enter') {
      setQuery();
    }
  };

  useEffect(() => {
    getScoringOption();
  }, []);

  return (
    <div className="row card-section card-objectives">
      <div className="row card-header">
        <span className="label card-header-text">search filter</span>
      </div>
      <div className="row card-content">
        <div className="cardbar-section">
          <div className="row">
            <div className="col-xs-5">
              <p className="mb-[12px]">Employee's Name</p>
              <input
                type="text"
                id="searchEmployee"
                placeholder="e.g John Doe"
                onKeyPress={handleKeyPress}
                className="form-control btn-filter-name"
              />
            </div>
            <div className="col-xs-4">
              <p className="mb-[6px] ml-[16px]">Performance Rating</p>
              <div className="dropdopwn">
                <Button.Tertiary id="btn-performance-rating">
                  Choose Performance Rating
                  <i className="fa fa-angle-down ml-[8px]" />
                </Button.Tertiary>
                <div className="performance-rating-checkbox" id="toggle-btn">
                  {scoringOption.map((value, index) => (
                    <Checkbox
                      key={index}
                      id={index}
                      checked={filter.includes(value.id)}
                      value={value.id}
                      onChange={(e) =>
                        setQuery(value.id, e.currentTarget.checked)
                      }
                    >
                      {value.name}
                    </Checkbox>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-xs-3 mt-[24px]">
              <Button onClick={() => setQuery()}>Update Filter</Button>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Search);

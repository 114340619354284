import { useRouteMatch } from 'react-router-dom';

import { useUser } from 'context/UserContext';

import { useToastContext } from 'src/context/ToastContext';
import { getObjectiveLocale } from 'src/utils/HelperUtils';
import { getObjectiveRoleWording } from 'src/utils/ObjectivesHelper';

const useDisabledUsers = ({
  isEdit = false,
  isProject = false,
  isTeam = false,
  objective
}) => {
  const { params } = useRouteMatch();
  const { addToast } = useToastContext();

  const {
    config: {
      permissions: {
        taskInvolvementsOwnerAssign,
        taskInvolvementsReviewerAssign,
        taskInvolvementsFollowerAssign,
        goalInvolvementsOwnerAssign,
        goalInvolvementsReviewerAssign,
        goalInvolvementsFollowerAssign
      }
    }
  } = useUser();

  const getTeamInvolvementsPermissions = () => {
    const { permissions } = objective;

    return {
      isAbleToChangeLeader: permissions?.includes('change_leader'),
      isAbleToChangeReviewer:
        objective?.permissions?.includes('change_reviewer'),
      isAbleToChangeMember: objective?.permissions?.includes('change_member'),
      isAbleToChangeStakeholder:
        objective?.permissions?.includes('change_stakeholder'),
      isAbleToChangePmo: objective?.permissions?.includes('change_pmo')
    };
  };

  const getProjectInvolvementsPermissions = () => {
    const { permissions } = objective;

    return {
      isAbleToChangeLeader: permissions?.includes('assign_leader'),
      isAbleToChangeReviewer: permissions?.includes('assign_reviewer'),
      isAbleToChangeMember: objective?.permissions?.includes('assign_owner'),
      isAbleToChangeStakeholder: permissions?.includes('assign_follower'),
      isAbleToChangePmo: permissions?.includes('assign_pmo')
    };
  };

  const getObjectiveConfigInvolvements = () => {
    return {
      isAbleToChangeOwner: params?.type?.includes('goal')
        ? goalInvolvementsOwnerAssign
        : taskInvolvementsOwnerAssign,
      isAbleToChangeReviewer: params?.type?.includes('goal')
        ? goalInvolvementsReviewerAssign
        : taskInvolvementsReviewerAssign,
      isAbleToChangeFollower: params?.type?.includes('goal')
        ? goalInvolvementsFollowerAssign
        : taskInvolvementsFollowerAssign
    };
  };

  const getObjectiveInvolvementsPermissions = () => {
    const { permissions } = objective;

    const {
      isAbleToChangeOwner: configChangeOwner,
      isAbleToChangeReviewer: configChangeReviewer,
      isAbleToChangeFollower: configChangeFollower
    } = getObjectiveConfigInvolvements();

    return {
      isAbleToChangeOwner: isEdit
        ? permissions?.includes('assign_owner')
        : configChangeOwner,
      isAbleToChangeReviewer: isEdit
        ? permissions?.includes('assign_reviewer')
        : configChangeReviewer,
      isAbleToChangeFollower: isEdit
        ? permissions?.includes('assign_follower')
        : configChangeFollower
    };
  };

  const getExcludeUserByObjective = (permissions) => {
    let users = [];

    const {
      isAbleToChangeOwner,
      isAbleToChangeReviewer,
      isAbleToChangeFollower
    } = permissions;

    if (!isAbleToChangeOwner) {
      objective?.involvements?.map((involvement) => {
        if (involvement?.role === 'assignee' && !involvement?.extendedRole) {
          users.push(involvement);
        }
      });
    }

    if (!isAbleToChangeReviewer) {
      objective?.involvements?.map((involvement) => {
        if (involvement?.role === 'assigner') {
          users.push(involvement);
        }
      });
    }

    if (!isAbleToChangeFollower) {
      objective?.involvements?.map((involvement) => {
        if (involvement?.role === 'follower' && !involvement?.extendedRole) {
          users.push(involvement);
        }
      });
    }

    return users;
  };

  const getExcludeUserByProjectOrTeam = (permissions) => {
    let users = [];

    const {
      isAbleToChangeLeader,
      isAbleToChangeReviewer,
      isAbleToChangeMember,
      isAbleToChangeStakeholder,
      isAbleToChangePmo
    } = permissions;

    if (!isAbleToChangeLeader) {
      objective?.involvements?.map((involvement) => {
        if (
          involvement?.role === 'assignee' &&
          involvement?.extendedRole === 'leader'
        ) {
          users.push(involvement);
        }
      });
    }

    if (!isAbleToChangeReviewer) {
      objective?.involvements?.map((involvement) => {
        if (involvement?.role === 'assigner') {
          users.push(involvement);
        }
      });
    }

    if (!isAbleToChangeMember) {
      objective?.involvements?.map((involvement) => {
        if (
          involvement?.role === 'assignee' &&
          involvement?.extendedRole !== 'leader' &&
          involvement?.extendedRole !== 'pmo'
        ) {
          users.push(involvement);
        }
      });
    }

    if (!isAbleToChangeStakeholder) {
      objective?.involvements?.map((involvement) => {
        if (involvement?.role === 'follower' && !involvement?.extendedRole) {
          users.push(involvement);
        }
      });
    }

    if (!isAbleToChangePmo) {
      objective?.involvements?.map((involvement) => {
        if (
          involvement?.role === 'follower' &&
          involvement?.extendedRole === 'pmo'
        ) {
          users.push(involvement);
        }
      });
    }

    return users;
  };

  // ** Function to get list all disabled users
  const getDisabledUser = () => {
    if (isTeam || isProject) {
      const permissions = isTeam
        ? getTeamInvolvementsPermissions()
        : getProjectInvolvementsPermissions();

      return getExcludeUserByProjectOrTeam(permissions);
    }

    const permissions = getObjectiveInvolvementsPermissions();
    return getExcludeUserByObjective(permissions);
  };

  // ** Function to check new adding users
  const checkIsUserDisabled = (newUserId, callBackSaveUser) => {
    const listDisabledUsers = getDisabledUser();

    const disabledUser =
      listDisabledUsers?.find((user) => user?.user?.id === newUserId) || null;

    if (disabledUser) {
      addToast({
        title: getObjectiveLocale('Permission not allowed'),
        msg: getObjectiveLocale(
          "You can't choose [name] since he/she is already assigned to [role] and you don't have the permission to change it"
        )
          .replace('[name]', disabledUser.user?.name)
          .replace(
            '[role]',
            getObjectiveRoleWording({ user: disabledUser, isProject, isTeam })
          ),
        type: 'error'
      });
    } else {
      callBackSaveUser && callBackSaveUser();
    }
  };

  return {
    checkIsUserDisabled
  };
};

export default useDisabledUsers;

import React from 'react';

import AWSImage from 'components/design-system/AWSImage';

function Star({
  color,
  text,
  selected,
  onMouseOver,
  onMouseOut,
  onClick,
  optionId,
  customIcon,
  iconUrl,
  showLabel
}) {
  const textStyle = {
    color: selected ? '#' + color : 'var(--n-600)'
  };

  let classes;
  if (selected) {
    classes = customIcon ? '' : 'fa fa-star';
  } else {
    classes = customIcon ? 'gray-icon' : 'fa fa-star gray';
  }
  return (
    <>
      <div className="fa-container">
        {!customIcon ? (
          <i
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            className={classes}
            data-cy={`option-${optionId}`}
          />
        ) : (
          <div className="mr-[54px] flex-center-center flex-col">
            <AWSImage
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              onClick={onClick}
              className={classes}
              dataCy={`option-${optionId}`}
              src={iconUrl}
            />
            {(showLabel == 'label_only' || showLabel == 'score_and_label') && (
              <p className="mt-[8px]" style={textStyle}>
                {text}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Star;

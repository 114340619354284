import compact from 'lodash/compact';

const defaultKey = ['cycle'];

const keys = {
  default: defaultKey,
  cycle: (id) => {
    return compact([...defaultKey, parseInt(id)]);
  }
};

export default keys;

import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { getListFilter } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import {
  UsersAdminProvider,
  useUsersAdmin
} from 'context/admin/UsersAdminContext';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalShowMember from 'components/admin/groups/ModalShowMember';
import UserTableInfo from 'components/admin/groups/UserTableInfo';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import ProfileType from 'components/design-system/ProfileType';
import Table from 'components/design-system/table/Table';
import Checkbox from 'components/shared/Checkbox';
import LoadingComponent from 'components/shared/LoadingComponent';
import LoadingOverlay from 'components/shared/LoadingOverlay/LoadingOverlay';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

function SetRecipients(props) {
  const { config } = useUser();
  const { selectAll, includes, excludes, clearSelection } = props;

  const [showModalMembers, setShowModalMembers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectAllFilter, setSelectAllFilter] = useState(false);
  const [renderInit, setRenderInit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const filter = useFilter((state) => state.filterById?.setRecipientsFilter);
  const setFilterById = useFilter((state) => state.setFilterById);
  const handleNextPage = () => {
    nextPage({ limit: 10, ...getUserParams() });
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    prevPage({ limit: 10, ...getUserParams() });
    setCurrentPage(currentPage - 1);
  };

  const {
    users,
    getUsersData,
    nextPage,
    prevPage,
    hasMore,
    getAllIds,
    allIds,
    getAllIsLoading,
    totalUser
  } = useUsersAdmin();

  const _getUsersData = async () => {
    setIsLoading(true);
    await getUsersData(getUserParams(), false, 'totalAvailableUsers');
    setIsLoading(false);
  };

  const isSelected = (id) => {
    let selectAll = props.selectAll;
    let include = props.includes;
    let exclude = props.excludes;

    if (selectAll) {
      return exclude.indexOf(id) === -1 ? true : false;
    } else {
      return include.indexOf(id) > -1 ? true : false;
    }
  };

  const getUserParams = () => {
    const { q, ...allFilter } = filter;
    const query = {
      q: q,
      state: ['active', 'pending'],
      limit: 10,
      info: allFilter,
      cultureRole: ['culture_user', 'culture_admin'],
      countAvailableUsers: true
    };

    return query;
  };

  const _getListFilter = (addedParams) => {
    const fetchFilter = () => {
      return getListFilter(addedParams);
    };

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['filter', addedParams],
        ({ pageParam }) => fetchFilter(pageParam),
        {
          getNextPageParam: (lastPage, allPages) =>
            lastPage.pagination?.next || 0
        }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  const getFilterData = async (addedParams) => {
    return await getListFilter({ q: addedParams?.q, key: addedParams?.key });
  };

  const filterChecker = () => {
    if (filter) {
      const filterArray = Object.entries(filter);
      if (renderInit && filterArray.length > 0) {
        const result = filterArray.filter((data) => data[1].length > 0);
        if (result.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    setFilterById('setRecipientsFilter', {});
    setRenderInit(true);
  }, []);

  useEffect(() => {
    if (selectAll) {
      setSelectAllFilter(excludes?.length == 0);
    } else {
      setSelectAllFilter(false);
    }
  }, [selectAll, includes, excludes]);

  useEffect(() => {
    filter && _getUsersData();
    if (!filterChecker()) {
      setSelectAllFilter(false);
    }
  }, [filter]);

  useEffect(() => {
    if (filterChecker() && !getAllIsLoading) {
      if (selectAllFilter) {
        if (allIds.length > 0) {
          props.selectBulkRecipients(allIds, true);
        }
      }
    }
  }, [selectAllFilter, allIds, getAllIsLoading]);

  const selectAllHandler = async () => {
    if (filterChecker()) {
      if (!selectAllFilter) {
        getAllIds(getUserParams());
        if (!getAllIsLoading) {
          setSelectAllFilter(true);
        }
      } else {
        setSelectAllFilter(false);
        props.selectBulkRecipients(allIds, false);
      }
    } else {
      props.setSelectAll();
      setSelectAllFilter(!selectAllFilter);
    }
  };

  const headers = [
    { name: 'checkbox', widthClass: 'w-[64px]' },
    { name: getObjectiveLocale('NAME'), widthClass: 'w-[224px]', grow: true },
    { name: getObjectiveLocale('DIRECTORATE'), widthClass: 'w-[160px]' },
    { name: getObjectiveLocale('DEPARTMENT'), widthClass: 'w-[160px]' },
    { name: getObjectiveLocale('DIVISION'), widthClass: 'w-[160px]' },
    { name: getObjectiveLocale('JOB ROLE'), widthClass: 'w-[160px]' },
    { name: getObjectiveLocale('JOB FUNCTION'), widthClass: 'w-[160px]' }
  ];

  return (
    <div className="admin-culture set-recipients px-[24px]">
      {getAllIsLoading && <LoadingOverlay />}
      <div className="create-organization" data-cy="modal-success">
        <p className="typography-h500 pb-[16px]">
          {getObjectiveLocale('Add Recipients')}
        </p>
        <AdvancedFilter
          filterOptions={['search', 'menuFilter']}
          filterMenu={config.generalFilterOptions}
          filterClient={getFilterData}
          dialogQueryFn={(params) => _getListFilter(params)}
          customClass="pt-[16px]"
          id="setRecipientsFilter"
        />

        <UserTableInfo
          setShowModal={setShowModalMembers}
          isSelectAll={selectAll}
          handleSelectAll={selectAllHandler}
          listIdUser={includes}
          totalLength={totalUser}
          excludedId={excludes}
          resetFunction={clearSelection}
          customClass="bg-b-200 mt-[8px]"
        />

        <Table
          headers={headers}
          fixedLeftCount={2}
          headerCheckbox={{
            checked: selectAllFilter,
            onClick: () => selectAllHandler()
          }}
        >
          {isLoading ? (
            <LoadingComponent />
          ) : (
            users.map((value, index) => (
              <Table.Row key={index}>
                <Table.Column>
                  <Checkbox
                    id={value.id}
                    checked={isSelected(value.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      props.selectRecipients(e, value.id);
                    }}
                    dataCy={`user-${index}`}
                  />
                </Table.Column>
                <Table.Column>
                  <ProfileType
                    user={value}
                    title={value.name}
                    subtitle={value.email}
                    size={32}
                    titleClassName="typography-h400"
                    subtitleClassName="typography-h100"
                  />
                </Table.Column>
                <Table.Column>
                  <TooltipContainer
                    show={value.info.directorate?.length > 20}
                    position="top"
                    text={value.info.directorate}
                    key={value.info.directorate}
                    useMaxWidth={false}
                  >
                    <p className="multiline-text-2 typography-primary">
                      {value.info.directorate || '-'}
                    </p>
                  </TooltipContainer>
                </Table.Column>
                <Table.Column>
                  <TooltipContainer
                    show={value.info.department?.length > 20}
                    position="top"
                    text={value.info.department}
                    key={value.info.department}
                    useMaxWidth={false}
                  >
                    <p className="multiline-text-2 typography-primary">
                      {value.info.department || '-'}
                    </p>
                  </TooltipContainer>
                </Table.Column>
                <Table.Column>
                  <TooltipContainer
                    show={value.info.division?.length > 20}
                    position="top"
                    text={value.info.division}
                    key={value.info.division}
                    useMaxWidth={false}
                  >
                    <p className="multiline-text-2 typography-primary">
                      {value.info.division || '-'}
                    </p>
                  </TooltipContainer>
                </Table.Column>
                <Table.Column>
                  <TooltipContainer
                    show={value.info.jobRole?.length > 20}
                    position="top"
                    text={value.info.jobRole}
                    key={value.info.jobRole}
                    useMaxWidth={false}
                  >
                    <p className="multiline-text-2 typography-primary">
                      {value.info.jobRole || '-'}
                    </p>
                  </TooltipContainer>
                </Table.Column>
                <Table.Column>
                  <TooltipContainer
                    show={value.info.jobFunction?.length > 20}
                    position="top"
                    text={value.info.jobFunction}
                    key={value.info.jobFunction}
                    useMaxWidth={false}
                  >
                    <p className="multiline-text-2 typography-primary">
                      {value.info.jobFunction || '-'}
                    </p>
                  </TooltipContainer>
                </Table.Column>
              </Table.Row>
            ))
          )}
        </Table>
        <div className="mt-[16px] mb-[96px] flex justify-end">
          <PaginationModule
            pageNumber={currentPage}
            handlePrev={() => handlePrevPage()}
            handleNext={() => handleNextPage()}
            hasMore={hasMore}
          />
        </div>
      </div>
      {showModalMembers && (
        <UsersAdminProvider>
          <ModalShowMember
            setShowModalMembers={setShowModalMembers}
            listIdUser={includes}
            excludedId={excludes}
            isSelectAll={selectAll}
          />
        </UsersAdminProvider>
      )}
    </div>
  );
}

export default SetRecipients;

import React from 'react';

import 'scss/culture/_list-detail-user';

import Avatar from 'components/shared/Avatar';

function ListDetailUser({
  id,
  title,
  desc,
  image,
  isSelected,
  onClickListDetail,
  isCheckboxExist,
  dataCy
}) {
  return (
    <div
      key={`type-${id}`}
      className="user-detail"
      onClick={onClickListDetail}
      data-cy={dataCy}
    >
      <div className="flex py-[8px] px-[16px] user-detail-item w-full">
        <Avatar name={title} src={image} dataCy="user-profile" size={32} />
        <div className="ml-[16px]">
          <p className="tyopgraphy-400 text-n-900 name">{title}</p>
          <p className="tyopgraphy-100 w-text-n-800 title">{desc}</p>
        </div>
      </div>
      {isCheckboxExist && (
        <div className="selected-checkbox">
          <input
            type="checkbox"
            id="selected-checkbox"
            className="checkbox"
            value={isSelected}
            checked={isSelected}
            readOnly
          />
          <label htmlFor="selected-checkbox" />
        </div>
      )}
    </div>
  );
}

export default ListDetailUser;

import React from 'react';
import Linkify from 'react-linkify';

import { getCssVariableValue, getNumberLocale } from 'utils/HelperUtils';

import Badge from 'components/shared/Badge';
import RangeSlider from 'components/slider/RangeSlider';

import {
  bgColorDict,
  fontColorDict,
  resultColorDict
} from './ColorDictionarySidebarEvidence';
import './WrapperAssessmentScore.scss';

const WrapperAssessmentScore = ({
  showOption,
  statusName,
  statusColorHex,
  description,
  personalScore,
  initialScore,
  isLastIndex,
  components,
  view
}) => {
  const { options } = components || {};

  let sortedScores = [];

  if (options) {
    const scoreOptions = [];

    options?.length > 0 &&
      options?.map((option) => {
        scoreOptions.push(option.score);
      });

    sortedScores = scoreOptions.sort((a, b) => a - b);
  }

  return (
    <>
      <div
        className={`flex items-center justify-between mt-[14px] pl-[40px] mb-[8px]`}
        data-cy="score-wrapper"
      >
        <div className="flex items-center">
          {(!showOption || showOption == 'score_and_label') && (
            <>
              <Badge
                colorHex={getCssVariableValue('--v-800')}
                bgColorHex={getCssVariableValue('--v-200')}
                className="typography-h200"
              >
                {getNumberLocale(personalScore || 0)}
              </Badge>

              <p
                className={`typography-h400 ${resultColorDict[statusColorHex]} ml-[8px]`}
              >
                {statusName || ''}
              </p>
            </>
          )}

          {showOption == 'label_only' && (
            <p className={`typography-h400 ${resultColorDict[statusColorHex]}`}>
              {statusName || ''}
            </p>
          )}

          {showOption == 'score_only' && (
            <p
              className={`evidence-score-box typography-h200 ${bgColorDict[statusColorHex]} ${fontColorDict[statusColorHex]}`}
            >
              {(personalScore || 0).toFixed(2)}
            </p>
          )}
        </div>
        {personalScore > 0 &&
          initialScore &&
          personalScore - initialScore != 0 && (
            <p
              className={
                personalScore - initialScore > 0 ? 'text-g-600' : 'text-r-600'
              }
            >
              {personalScore - initialScore > 0 ? '+' : ''}{' '}
              {(personalScore - initialScore || 0).toFixed(2)}
            </p>
          )}
      </div>

      {view === 'slider' && (
        <div className="slider-result py-[18px] px-[16px] ml-[40px] bg-n-200 rounded-[4px] mb-[8px] border border-solid border-n-300">
          <RangeSlider
            value={personalScore}
            min={sortedScores?.[0]}
            max={sortedScores[sortedScores?.length - 1]}
          />
        </div>
      )}

      {description && (
        <div className="pl-[40px]">
          <Linkify
            className="text-n-800 typography-paragraph whitespace-pre-wrap break-words block"
            properties={{ target: '_blank' }}
          >
            {description}
          </Linkify>
        </div>
      )}

      {isLastIndex && <div className="divider-card" />}
    </>
  );
};

export default WrapperAssessmentScore;

import React, { useMemo } from 'react';

import useDisabledUsers from 'hooks/useDisabledUsers';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getObjectiveInvolvements } from 'utils/ObjectivesHelper';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';
import InlineDialogSelectMultipleUser from 'components/inline-dialog/InlineDialogSelectMultipleUser';
import SVGIcon from 'components/shared/SVGIcon';

import { getNewInvolvements } from '../sidebar-create/SidebarCreateHelper';

const Members = ({ objectiveValue, setObjectiveValue, updateObjective }) => {
  const { permissions, involvements } = objectiveValue;

  const { checkIsUserDisabled } = useDisabledUsers({
    isEdit: true,
    isProject: true,
    objective: objectiveValue
  });

  const selectedMembers = getObjectiveInvolvements(involvements, 'member');

  const currentInvolvements = useMemo(() => {
    return [...involvements];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDialog = (involvements, type) => {
    const lastIndex = involvements?.length - 1;
    const selectedUser = involvements[lastIndex];

    checkIsUserDisabled(selectedUser?.user?.id, () => {
      let newInvolvements = getNewInvolvements(
        involvements,
        type,
        false,
        objectiveValue,
        null,
        null,
        true
      );

      setObjectiveValue((draft) => {
        draft.involvements = newInvolvements;
      });
    });
  };

  const updateMembers = async () => {
    const newInvolvements = objectiveValue?.involvements.map((involvement) => ({
      userId: involvement.user.id,
      role: involvement.role,
      placementId: involvement.user.placementId,
      extendedRole: involvement.extendedRole
    }));

    const { isSuccess } = await updateObjective({
      involvements: newInvolvements
    });
    if (!isSuccess) {
      setObjectiveValue((draft) => {
        draft.involvements = currentInvolvements;
      });
    }
  };

  const MembersTrigger = () => {
    const users = selectedMembers.map(({ user }) => user) || [];

    return users.length > 0 ? (
      <div className="px-[8px] py-[8px] rounded-full hover:bg-base-30024 ml-[-8px] w-full">
        {users.length === 1 ? (
          <SingleUser
            userData={users[0]}
            isDisabled={!permissions?.includes('assign_owner')}
          />
        ) : (
          users.length > 1 && (
            <AvatarGroup
              avatars={users}
              size="24"
              disabled={!permissions?.includes('assign_owner')}
            />
          )
        )}
      </div>
    ) : (
      <div className="text-n-600 px-[8px] py-[8px] rounded-full hover:bg-base-30024 ml-[-8px] w-full flex items-center">
        <SVGIcon
          size="24"
          fillColor="var(--n-600)"
          iconName="icon-default-user"
          customClass="mr-[8px]"
        />
        <span>{getObjectiveLocale('Select User')}</span>
      </div>
    );
  };

  return (
    <>
      <div className="mt-[16px] w-1/2">
        <p className="typography-h100 text-n-800">
          {getObjectiveLocale('Members')}
        </p>
        <InlineDialogSelectMultipleUser
          customTriggerComponent={MembersTrigger}
          header="Members"
          role="assignee"
          position="bottom"
          objectiveValue={objectiveValue}
          selectedValue={selectedMembers}
          handleChange={handleChangeDialog}
          dataCy="inline-dialog-project-member"
          onCloseDialog={updateMembers}
          permission={permissions?.includes('assign_owner')}
          dialogWrapperClass="w-full"
        />
      </div>
    </>
  );
};

export default Members;

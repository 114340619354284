import React from 'react';

import { ADD_BOTTOM, ADD_TOP } from '../utils/const';
import SingleBarContainer from './SingleBarContainer';

const BarsContainer = ({
  objectivesData,
  section,
  contentLoaded,
  isFetching,
  group,
  subContentOptions,
  openAdd
}) => {
  return (
    <div className="tm-bar-content w-full">
      {openAdd == ADD_TOP && <div className="h-[48px]" />}
      {contentLoaded &&
        objectivesData?.map((objective) => (
          <SingleBarContainer
            group={group}
            objective={objective}
            section={section}
            key={'bar' + objective.id}
            subContentOptions={subContentOptions}
          />
        ))}
      {objectivesData?.length == 0 && !isFetching && (
        <div className="h-[48px]" />
      )}
      {openAdd == ADD_BOTTOM && <div className="h-[48px]" />}
      <div className={isFetching ? 'h-[48px]' : ''} />
      <div className="h-[40px]" />
    </div>
  );
};

export default BarsContainer;

import React, { useEffect, useState } from 'react';

import { useUser } from 'context/UserContext';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { getObjectiveLocale } from 'utils/HelperUtils';

import NumberInput from 'components/shared/NumberInput';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import ContentWrapper from 'components/sidebar/sidebar-create/ContentWrapper';
import RangeSlider from 'components/slider/RangeSlider';

function SliderWeight({
  objectiveValue,
  setObjectiveValue,
  parentId,
  objectiveCategories,
  disabled = false,
  isToggleOn,
  setIsToggleOn
}) {
  const {
    config: { objectiveWeightType, enableObjectiveCategoryWithRangedWeight }
  } = useUser();
  const isPercentage = objectiveWeightType === 'percentage';
  const defaultValue = objectiveValue.weight;
  const fakeParentId = objectiveValue.fakeParentId;
  const currentObjectiveCategory = objectiveCategories.find(
    (type) => type.id === objectiveValue?.objectiveCategoryId
  );

  const [weightValue, setWeightValue] = useState(defaultValue);
  const isChildren = parentId || objectiveValue.fakeParentId;
  const { checkValidationSoftWarning, isShowWarning, warningMessage } =
    useValidationMinMax(weightValue, isChildren);

  const isLocked =
    (enableObjectiveCategoryWithRangedWeight &&
      currentObjectiveCategory?.isLocked) ||
    false;

  const isLockedWeightMatch =
    currentObjectiveCategory?.weight == weightValue || false;

  useEffect(() => {
    setWeightValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    checkValidationSoftWarning(true);
  }, [weightValue, checkValidationSoftWarning]);

  function handleChangeWeight(weight) {
    setObjectiveValue((draft) => {
      draft.weight = weight;
    });
  }

  const handleChangeWeightInput = (inputWeight) => {
    let weight;
    if (inputWeight > 100) {
      weight = 100;
    } else {
      weight = inputWeight != '' ? parseFloat(inputWeight) : inputWeight;
    }
    setWeightValue(inputWeight);
    handleChangeWeight(weight);
  };

  const toggleWeight = () => {
    let weight;
    if (weightValue > 0) {
      weight = 0;
    } else {
      weight = isPercentage ? 1 : currentObjectiveCategory?.weight || 1;
    }
    setWeightValue(weight);
    handleChangeWeight(weight);
    setIsToggleOn(!isToggleOn);
  };

  const adjustWeight = () => {
    setWeightValue(currentObjectiveCategory?.weight);
  };

  return (
    <ContentWrapper dataCy="sidebar-create-weight-type">
      <div className="weight-container" data-cy="edit-weight-container">
        <div className="weight-percentage px-[24px] py-[12px]">
          <SVGIcon
            customClass="mr-[16px]"
            iconName="icon-fitness_center"
            size="24"
            fillColor="var(--n-600)"
          />
          <p className="typography-paragraph mt-[0px]">
            {getObjectiveLocale('Weight')}
          </p>
          <SmallToggleSwitchPurple
            idStr="sidebar-create-use-weight"
            isChecked={isToggleOn}
            onChange={toggleWeight}
            addClass="flex items-center"
            disabled={disabled}
          />
        </div>
        {isToggleOn && (
          <div className="bg-n-200 border border-solid border-n-300 rounded px-[16px] py-[16px] ml-[64px] mr-[24px] mt-[4px] mb-[8px]">
            <div className="flex items-center">
              <NumberInput
                type="number"
                placeholder="0"
                max="100"
                value={weightValue}
                onChange={handleChangeWeightInput}
                inputClass={`${
                  !isLocked && isShowWarning && !fakeParentId && !parentId
                    ? 'warning-input'
                    : ''
                }`}
                precision={13}
                className="w-[64px] mr-[8px]"
                dataCy="weight-input"
                disabled={isLocked || disabled}
                ableToInputZero={true}
              />
              <p className="typography-paragraph mt-[0px]">%</p>
            </div>
            <RangeSlider
              handleChange={(e) => setWeightValue(e)}
              onChangeComplete={(e) => handleChangeWeight(weightValue)}
              value={weightValue}
              min={0}
              max={100}
              className="mt-[16px] pt-[8px] h-[24px]"
              disabled={isLocked || disabled}
            />
            {isLocked &&
              (isLockedWeightMatch ? (
                <p className="text-n-600 typography-h100 mt-[12px]">
                  {getObjectiveLocale(
                    'The weight percentage for this type is locked by system'
                  )}
                </p>
              ) : (
                <p className="text-r-600 typography-h100 mt-[12px]">
                  {getObjectiveLocale(
                    `Current weight doesn't fit with the latest requirement`
                  )}
                  <span
                    className="text-base-600 typography-h100 cursor-pointer"
                    onClick={adjustWeight}
                  >
                    {' '}
                    {getObjectiveLocale('Click to adjust')}
                  </span>
                </p>
              ))}
            {!isLocked && isShowWarning && (
              <p className="text-r-600 typography-h100 mt-[16px]">
                {warningMessage}
              </p>
            )}
          </div>
        )}
      </div>
    </ContentWrapper>
  );
}

export default SliderWeight;

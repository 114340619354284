import React from 'react';
import { useLocation } from 'react-router-dom';

import { useValueCompetency } from 'context/admin/ValueCompetencyContext';

import Shimmer from 'components/design-system/shimmer/Shimmer';

const SingleCardSkeletonLoading = ({
  firstClassName,
  secondClassName,
  thirdClassName,
  isModelKeyBehavior
}) => {
  const { isValuesPage } = useValueCompetency();
  const { pathname } = useLocation();
  let currentTab = pathname.includes('model') ? 'model' : 'dictionary';

  if (isModelKeyBehavior) {
    return (
      <tr className="body-table-key-behavior">
        <td
          valign="top"
          className={`table-name ${isValuesPage(pathname, 'custom-padding')}`}
        >
          <Shimmer width="80%" height="24px" />
        </td>
        <td valign="top" colSpan="4" className="table-description">
          <Shimmer width="90%" height="24px" />
        </td>
      </tr>
    );
  } else {
    return (
      <tr className={firstClassName}>
        <td valign="top" className={secondClassName}>
          <Shimmer width="80%" height="24px" />
        </td>
        <td valign="top" className={thirdClassName}>
          <Shimmer width="90%" height="24px" />
          <Shimmer width="80%" height="24px" />
        </td>
        {currentTab == 'model' && (
          <td valign="top" className="table-group-model">
            <Shimmer width="70%" height="24px" />
          </td>
        )}
        <td
          valign="top"
          className={
            currentTab == 'model' ? 'table-status-model' : 'table-status'
          }
        >
          <Shimmer width="70%" height="24px" />
        </td>
        <td
          valign="top"
          className={
            currentTab == 'model' ? 'table-action-model' : 'table-action'
          }
        >
          <Shimmer width="70%" height="24px" />
        </td>
      </tr>
    );
  }
};

export default SingleCardSkeletonLoading;

import React from 'react';

import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';

const Tags = ({ objectiveDetail }) => {
  const { tags } = objectiveDetail;

  return (
    <div className="px-[24px]">
      <div className="flex items-start" data-cy="sidebar-detail-tags">
        <SVGIcon
          iconName="icon-label_outline"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <div>
          {tags.map((name, index) => {
            return (
              <Badge
                key={index}
                title={null}
                content={name}
                customContainerClass="inline-flex"
                className="mr-[8px] mt-[4px] mb-[4px]"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Tags;

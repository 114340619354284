import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

function OverviewListBehaviorComponent(props) {
  const { behaviourReport, quarter, title } = props;
  return (
    <div className="detail-behaviour" id="subbox">
      <span className="title-quarter-behaviour">{title}</span>
      {quarter?.length > 0 ? (
        quarter.map((data, i) => {
          return (
            <div className="behaviour" key={i}>
              {data.object && (
                <span className="behaviour-name">
                  {' '}
                  {behaviourReport && data.object.title}{' '}
                </span>
              )}

              <div className="sign">
                <div className="number">
                  {' '}
                  {data.totalStrength}
                  <div className="color1"> </div>
                </div>
                <div className="number">
                  {' '}
                  {data.totalDevelopment}
                  <div className="color2"> </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className="typography-paragraph text-n-600 w-full">
          {getObjectiveLocale('Not yet available')}
        </p>
      )}
    </div>
  );
}

export default OverviewListBehaviorComponent;

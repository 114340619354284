import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { format } from 'timeago.js';

import {
  createComment,
  updateCurrentValue,
  updateStateObjective
} from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import useModalObjectives from 'hooks/useModalObjectives';
import useParseNumber from 'hooks/useParseNumber';
import { useUrl } from 'hooks/useUrl';
import useValidationMinMax from 'hooks/useValidationMinMax';
import Config from 'utils/Config.js';
import {
  capitalize,
  getCssVariableValue,
  getObjectiveLocale
} from 'utils/HelperUtils';
import { getOwners, getValue } from 'utils/ObjectivesHelper';

import Avatar from 'components/shared/Avatar';
import IconButton from 'components/shared/Button/IconButton';
import CalculationTypeIndicator from 'components/shared/CalculationTypeIndicator';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import { trackEvent } from 'src/utils/AnalyticUtils';

import ListLabel from './ListLabel';
import ProgressBar from './ProgressBar';
import TextArea from './TextAreaCheckin';

function ListObjectiveBody({
  objective,
  action,
  isCheckin,
  permissions,
  clusters,
  level,
  showBadgeObjectiveCount
}) {
  const {
    id,
    name,
    involvements = [],
    stateObject = {},
    dueDate,
    measurement,
    isProject,
    overdue,
    team,
    objectiveCategory,
    weight,
    tags,
    type,
    complexity,
    valueLastUpdatedAt,
    reviews,
    externalId,
    updateProgressCount,
    calculationType,
    milestoneType
  } = objective || [];

  const { state } = stateObject || [];
  const { refetchObjective } = useRefetchQuery();
  const {
    goalOwnersName,
    goalOwnersRoles,
    goalOwnersProfilePic,
    goalOwners,
    goalOwnersDirectorate
  } = getOwners(involvements, isProject);
  const {
    currentValue,
    targetValue,
    valueLabel,
    formattedCurrentValue,
    formattedTargetValue
  } = getValue(measurement);
  const metricWording =
    measurement === null
      ? 'No metric and progress'
      : 'Metric & progress not visible';
  const { config } = useUser();
  const {
    objectiveWeightType,
    customIconOnReview,
    dependentObjectiveCodes,
    objectiveWeightTypeOptions
  } = config;
  const { url } = useUrl();

  const showModalWeight = useModalObjectives((state) => state.showModal);
  const propsModal = {
    objectives: [objective],
    isIndividual: true
  };
  const { checkValidationSoftWarning, isShowWarning } = useValidationMinMax(
    weight,
    objective?.parent
  );

  function insertLabel() {
    let listLabel = [];
    const usingWorkAttribute =
      reviews?.[0]?.reviewChoices[0]?.workAttribute && customIconOnReview;
    const iconReviewUrl =
      reviews?.[0]?.reviewChoices[0]?.scoringMark?.iconUrl ||
      reviews?.scoringMark?.iconUrl;
    const goalType = team?.id ? 'team_goal' : type;
    const isAllowedObjectiveCategory =
      objectiveWeightTypeOptions.includes(goalType);

    if (showBadgeObjectiveCount && updateProgressCount) {
      listLabel.push({
        content: `+${updateProgressCount} Updates`,
        colorHex: getCssVariableValue('--n-000'),
        bgColorHex: getCssVariableValue('--r-500')
      });
    }

    if (
      objectiveWeightType == 'type' &&
      type !== 'task' &&
      isAllowedObjectiveCategory
    ) {
      listLabel.push({
        title: '',
        content: objectiveCategory?.name ?? 'Select type here',
        colorHex: objectiveCategory?.colorHex ?? 'F84535',
        bgColorHex: objectiveCategory?.bgColorHex ?? 'FFDAD6',
        editable: action && permissions?.includes('update'),
        onClick: () =>
          showModalWeight({ modalType: 'adjustWeight', props: propsModal }),
        icon: Config.asset.createObjective.iconEditSidebar
      });
    }

    if (reviews?.length > 0) {
      listLabel.push({
        iconUrl: customIconOnReview
          ? iconReviewUrl
          : Config.asset.createObjective.starYellow,
        content: usingWorkAttribute
          ? reviews[0].reviewChoices[0].workAttribute?.name
          : reviews[0]?.reviewChoices[0]?.scoringMark?.name
      });
    }

    if (weight && type != 'task') {
      listLabel.push({
        title: 'Weight',
        content: `${weight}%`,
        colorHex: isShowWarning ? 'F84535' : '',
        bgColorHex: isShowWarning ? 'FFDAD6' : '',
        editable: action && permissions?.includes('update'),
        onClick: () =>
          showModalWeight({ modalType: 'adjustWeight', props: propsModal }),
        icon: Config.asset.createObjective.iconEditSidebar
      });
    }

    if (type == 'task' && complexity) {
      listLabel.push({
        title: 'Complexity',
        content: complexity,
        editable: true,
        onClick: () =>
          showModalWeight({ modalType: 'adjustWeight', props: propsModal }),
        icon: Config.asset.createObjective.iconEditSidebar
      });
    }
    if (tags?.length > 0) {
      listLabel.push({
        title: 'Label',
        content: tags.join(', '),
        editable: false
      });
    }
    listLabel.push({
      title: 'Last Updated',
      content: format(valueLastUpdatedAt),
      editable: false
    });

    if (milestoneType && milestoneType !== 'disabled') {
      listLabel.push({
        title: 'Milestone',
        content: capitalize(milestoneType),
        editable: false
      });
    }

    return listLabel;
  }

  let listHeader = insertLabel();

  let ownersName =
    typeof goalOwnersName === 'object'
      ? goalOwnersName.join('')
      : goalOwnersName;

  const completeGoal = async (_) => {
    let newState = { state: 'completed', type: 'completed' };
    const { data } = await updateStateObjective(id, newState);
    data &&
      trackEvent({
        event: 'complete',
        eventProperties: { status: 'success', id, type: 'completed' }
      });
    await refetchObjective(id);
  };
  const {
    number: progress,
    formatedNumber: formattedProgress,
    setNumber: setProgress
  } = useParseNumber(currentValue);

  const sendAmplitudeUpdateGoalTask = () => {
    let properties = {
      'previous value': currentValue,
      'current value': progress
    };

    trackEvent({
      event: `update ${type === 'task' ? 'task' : 'goal'} target metric`,
      eventProperties: properties
    });
  };

  const updateProgress = async (_) => {
    let body = { current_value: progress };
    const { data } = await updateCurrentValue(id, body);
    data && sendAmplitudeUpdateGoalTask();
    await refetchObjective(id);
  };

  const [commentValue, setComment] = useState('');
  const comment = async (_) => {
    const body = {
      comment: commentValue
    };
    await createComment(id, body);
    await refetchObjective(id);
  };

  useEffect(() => {
    checkValidationSoftWarning();
  }, []);

  return (
    <React.Fragment>
      <div
        className={`objective-content flex justify-between mb-[16px] mt-[8px]`}
      >
        <div className={`involvements-info ${isCheckin ? 'checkin' : ''} flex`}>
          <Avatar
            src={goalOwnersProfilePic}
            name={ownersName}
            size={40}
            className="list-detail-owner-image mr-[16px]"
            dataCy="list-detail-owner-image"
            isGroup={externalId && dependentObjectiveCodes.length > 0}
          />
          <div className={`goal-card-user mr-[24px] max-w-[200px]`}>
            <p
              className={`typography-h500 text-n-900 goal-name-${
                url.includes('users')
                  ? 'profile'
                  : url.includes('reviews')
                  ? 'review'
                  : 'goals'
              }-${level - 1} font-rem-6 fw-bold`}
              data-cy="goal-card-title"
            >
              {name}
            </p>
            <p
              className={`typography-paragraph flex items-center text-n-800 truncate goal-name-${
                url.includes('users')
                  ? 'profile'
                  : url.includes('reviews')
                  ? 'review'
                  : 'goals'
              }-${level - 1}`}
              data-cy="list-detail-owner-name"
            >
              {team && team.teamPicture && (
                <img
                  className="team-picture rounded-[4px] w-[16px] h-[16px] mr-[4px]"
                  src={team.teamPicture}
                />
              )}
              {isProject && (
                <SVGIcon
                  iconName="icon-project-badge"
                  fillColor="var(--n-400)"
                  size="16"
                  customClass="mr-[8px]"
                />
              )}
              {team && `Team: ${team?.name} · `}
              {Array.isArray(goalOwnersName)
                ? goalOwnersName?.join(', ')
                : goalOwnersName}
              {goalOwners?.length > 2 &&
                `, and ${goalOwners?.length - 2} others`}
              {!team && goalOwnersRoles && (
                <span className="ml-[4px] mr-[4px] interpunct"> · </span>
              )}{' '}
              {!team &&
                (config?.userSubtitleField == 'job_title'
                  ? goalOwnersRoles
                  : goalOwnersDirectorate)}
            </p>
          </div>
        </div>
        <div
          className={`objective-info ${
            isCheckin ? ' flex' : ' flex-center-center'
          }`}
        >
          {isCheckin ? (
            <div className="checkin-list detail-info flex">
              {clusters.includes('metric') || clusters?.includes('progress') ? (
                <>
                  <p
                    data-cy="checkin-list-progress-metric"
                    className="mr-[16px]"
                  >
                    {measurement.unit}
                  </p>
                  <TooltipContainer
                    text={`Target: ${formattedTargetValue?.toLocaleString(
                      'id-ID'
                    )} (${measurement.unit})`}
                    show={targetValue > 0}
                    dataCy="checkin-list-progress-metric"
                    classContainer="update-value-checkin flex-center-center mr-[24px] relative"
                  >
                    <input
                      type="text"
                      placeholder="0"
                      onBlur={updateProgress}
                      className="form-control h-[36px]"
                      onChange={(e) => setProgress(e.target.value)}
                      disabled={!permissions?.includes('update_current_value')}
                      value={formattedProgress}
                    />
                  </TooltipContainer>
                </>
              ) : (
                <p
                  className="mr-[24px]"
                  data-cy="list-detail-no-progress-metric"
                >
                  {metricWording}
                </p>
              )}
              <div className="comment-checkin-box mr-[24px] flex">
                <TextArea
                  name="comment"
                  onHandleChange={(e) => setComment(e.target.value)}
                  onBlur={() => comment()}
                  textPlaceholder="Add comment..."
                  value={commentValue}
                  className="textarea form-control w-[240px] max-h-[84px] px-[8px] pt-[6px] pb-[12px] w-[240px]"
                  height={36}
                  maxHeight={84}
                />
              </div>
              {permissions?.includes('complete') ? (
                <div className="check-content-display">
                  <div className="tooltip">
                    {state === 'completed' ? 'Completed' : 'Mark as complete'}
                  </div>
                  <IconButton
                    customClass="w-[36px] h-[36px] px-[8px] py-[8px]"
                    isSecondary={true}
                    onClick={completeGoal}
                  >
                    <SVGIcon
                      iconName="icon-check_circle"
                      fillColor={
                        state === 'completed' ? 'var(--g-600)' : 'var(--n-600)'
                      }
                      size="20"
                      customClass="mr-[8px]"
                    />
                  </IconButton>
                </div>
              ) : (
                <div className="pl-[36px]"></div>
              )}
            </div>
          ) : (
            <>
              <div className="detail-info relative flex-center-center ">
                <div className="mr-[24px] w-[72px]">
                  <p className="fs-12 text-n-600 typography-h100 mb-[4px]">
                    Due Date
                  </p>
                  <p
                    className="typography-button text-n-800"
                    data-cy="list-detail-due-date"
                  >
                    {dueDate && clusters.includes('dueDate')
                      ? `${dayjs(dueDate).format('MMM, DD')}th`
                      : getObjectiveLocale('Not visible')}
                  </p>
                </div>
                {clusters.includes('metric') ||
                clusters?.includes('progress') ? (
                  <>
                    <div className="progress-objective mr-[24px]">
                      {clusters.includes('progress') ? (
                        <ProgressBar
                          measurement={measurement}
                          permission={permissions}
                          objectiveId={id}
                        />
                      ) : (
                        <p
                          className="not-visible w-[114px]"
                          data-cy="list-detail-progress"
                        >
                          {getObjectiveLocale('Not visible')}
                        </p>
                      )}
                    </div>
                    {measurement.expectedProgressPercentage > 0 && (
                      <div className="tooltip typography-h100">
                        {'Expected: ' +
                          measurement.expectedProgressPercentage +
                          '%'}
                      </div>
                    )}
                    {clusters.includes('metric') ? (
                      <div
                        className="mr-[24px] w-[186px]"
                        data-cy="list-detail-metric-description"
                      >
                        <div className="measurement-section flex items-center typography-h100 text-n-600 font-rem-2">
                          {valueLabel} {measurement && measurement.description}{' '}
                          ({measurement.unit && measurement.unit})
                          <CalculationTypeIndicator
                            calculationType={calculationType}
                            customClass="ml-[8px] !h-[18px]"
                          />
                        </div>
                        <div className="flex items-center mt-[4px]">
                          <p className="typography-h400">
                            {currentValue
                              ? formattedCurrentValue?.toLocaleString('id-ID')
                              : '0'}
                          </p>
                          <span className="mr-[4px] ml-[4px]">/</span>
                          <p className="typography-button text-n-800">
                            {targetValue
                              ? formattedTargetValue?.toLocaleString('id-ID')
                              : null}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <p
                        className="mr-[24px]"
                        data-cy="list-detail-metric-description"
                      >
                        {getObjectiveLocale('Not visible')}
                      </p>
                    )}
                  </>
                ) : (
                  <p
                    className="typography-button text-n-600 ml-[96px] w-[186px] mr-[24px]"
                    data-cy="list-detail-no-progress-metric"
                  >
                    {metricWording}
                  </p>
                )}
              </div>
              <div className="status">
                {overdue === true ? (
                  <SVGIcon
                    iconName="icon-overdue"
                    fillColor="var(--y-600)"
                    size="24"
                  />
                ) : state === 'reviewed' ? (
                  <SVGIcon
                    iconName="icon-reviewed"
                    fillColor="var(--base-600)"
                    size="24"
                  />
                ) : state == 'completed' ? (
                  <SVGIcon
                    iconName="icon-check_circle"
                    fillColor="var(--g-600)"
                    size="24"
                  />
                ) : (
                  <div className="ml-[24px]"></div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="header-label flex items-center">
        <ListLabel data={listHeader} />
      </div>
    </React.Fragment>
  );
}

export default ListObjectiveBody;

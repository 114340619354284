import React, { useEffect, useRef, useState } from 'react';

import startCase from 'lodash/startCase';

import { getRatingTemplateDetail } from 'client/admin/RatingTemplateClient';
import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import FormattedNumber from 'src/components/design-system/FormattedNumber';

import './SidebarRatingTemplate.scss';
import SidebarRatingTemplateSkeleton from './SidebarRatingTemplateSkeleton';

const SidebarRatingTemplate = ({
  templateId,
  handleEdit,
  handleDelete,
  closeSidebar,
  currentTab,
  getRatingType
}) => {
  const [template, setTemplate] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showMoreInUse, setShowMoreInUse] = useState(false);
  const sidebar = useRef();
  useClickOutside(sidebar, closeSidebar);

  const getData = async (id) => {
    const { data } = await getRatingTemplateDetail(id);
    setTemplate(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(templateId);
  }, []);

  const ScoringMechanismDictionary = {
    direct_to_goal: 'Directly to goal',
    work_attributes: 'Given to a set of attributes of the goal'
  };

  const FormulaDictionary = {
    total: 'Total rating',
    average: 'Average total rating',
    weighted_total: 'Total rating times goal weight percentage',
    weighted_average: 'Average of total rating times goal weight percentage',
    parent_total: 'Total rating (parent goals only)',
    parent_average: 'Average of total rating (parent goals only)',
    parent_weighted_total:
      'Total rating times goal weight percentage (parent goals only)',
    parent_weighted_average:
      'Average of total rating times goal weight percentage (parent goals only)'
  };

  const getUsedOn = (template) => {
    let key = '';
    if (
      template.ratingType == 'goal_ongoing' ||
      template.ratingType == 'task_ongoing' ||
      template.ratingType == 'value_recognition'
    ) {
      key = 'groups';
    } else {
      key = 'cycles';
    }

    return showMoreInUse ? (
      <>
        {template[key].map((data, idx) => {
          return (
            <div key={idx} className="grey-box py-[8px] px-[12px] mb-[4px]">
              <p>{data.name}</p>
              <span className="typography-h200 mr-[8px]">
                {startCase(data.state)}
              </span>
              <span className="typography-h100">{data.id}</span>
            </div>
          );
        })}
        {template[key].length > 3 && (
          <Button.Tertiary
            customClass="pl-[0px]"
            onClick={() => setShowMoreInUse(false)}
          >
            Hide {template[key].length - 3} {startCase(key)}
          </Button.Tertiary>
        )}
      </>
    ) : (
      <>
        {template[key].slice(0, 3).map((data, idx) => {
          return (
            <div key={idx} className="grey-box py-[8px] px-[12px] mb-[4px]">
              <p>{data.name}</p>
              <span className="typography-h200 mr-[8px]">
                {startCase(data.state)}
              </span>
              <span className="typography-h100">{data.id}</span>
            </div>
          );
        })}
        {template[key].length > 3 && (
          <Button.Tertiary
            customClass="pl-[0px]"
            onClick={() => setShowMoreInUse(true)}
          >
            Show {template[key].length - 3} More {startCase(key)}
          </Button.Tertiary>
        )}
      </>
    );
  };

  const getValueRecognitionActivity = (activity) => {
    switch (activity) {
      case 'given':
        return 'Given Only';
      case 'received':
        return 'Received Only';
      case 'entire':
        return 'Given & Received';
      default:
        break;
    }
  };

  return (
    <>
      {isLoading && <SidebarRatingTemplateSkeleton />}
      {!isLoading && (
        <div
          ref={sidebar}
          className="sidebar-rating-template surface px-[24px] mt-[24px] shadow-temporary"
        >
          <div className="header-sidebar px-[16px] py-[16px]">
            {template.ratingType == 'goal_ongoing' ||
            template.ratingType == 'task_ongoing' ||
            template.ratingType == 'value_recognition' ||
            template.cycles.length == 0 ? (
              <div className="flex">
                <Button.Secondary
                  onClick={(e) =>
                    handleEdit(
                      e,
                      template.id,
                      getRatingType(template.ratingType)
                    )
                  }
                  customClass="mr-[8px]"
                >
                  <SVGIcon
                    size="24"
                    iconName="icon-pencil"
                    fillColor="var(--n-600)"
                    customClass="mr-[4px]"
                  />
                  {getObjectiveLocale('Edit')}
                </Button.Secondary>
                {currentTab == 'active' &&
                  !template.usageCount &&
                  template.state != 'published' && (
                    <Button.Secondary
                      className="archive"
                      onClick={(e) => {
                        handleDelete(e, template.id);
                      }}
                    >
                      {getObjectiveLocale('Deactivate')}
                    </Button.Secondary>
                  )}
                {currentTab != 'active' &&
                  !template.usageCount &&
                  template.state == 'deleted' && (
                    <Button.Secondary
                      onClick={() => {
                        handleDelete(template.id);
                      }}
                    >
                      {getObjectiveLocale('Activate')}
                    </Button.Secondary>
                  )}
              </div>
            ) : (
              <div className="flex">
                <i className="material-icons typography-secondary mr-[8px]">
                  lock
                </i>
                <p className="typography-secondary">
                  {getObjectiveLocale('Not Editable')}
                </p>
              </div>
            )}
            <SVGIcon
              size="24"
              iconName="icon-clear"
              fillColor="var(--n-600)"
              onClick={closeSidebar}
            />
          </div>
          <div className="sidebar-body mt-[16px]">
            <div className="detail">
              <p className="typography-secondary mb-[4px]">
                {startCase(template.ratingType)}
              </p>
              <p className="typography-secondary mb-[4px]">ID: {template.id}</p>
              <p className="typography-h600">{template.name}</p>
            </div>
            {template.cycles.length + template.groups.length > 0 && (
              <div className="mt-[24px]">
                <p className="typography-h500 mb-[8px]">
                  {getObjectiveLocale('Currently in use')}
                </p>
                {getUsedOn(template)}
              </div>
            )}
            <div className="rating-config pt-[24px] mt-[24px]">
              <p className="typography-h500 mb-[8px]">
                {getObjectiveLocale('Rating configuration')}
              </p>
              <div className="table-container pb-[8px]">
                <div className="t-body inline-block px-[16px] py-[4px] w-full overflow-auto">
                  <div className="pb-[8px] inline-block">
                    <div className="whitespace-nowrap">
                      <div className="inline-block typography-h100 typography-secondary w-[40px] mr-[16px]">
                        {getObjectiveLocale('Rating')}
                      </div>
                      <div className="inline-block typography-h100 typography-secondary w-[128px] mr-[16px]">
                        {getObjectiveLocale('Range')}
                      </div>
                      {template?.showOption !== 'score_only' && (
                        <div className="inline-block typography-h100 typography-secondary">
                          {getObjectiveLocale('Label')}
                        </div>
                      )}
                    </div>
                  </div>
                  {template.scoringComponents[0].scoringMarks.map(
                    (mark, idx) => {
                      let rangeText = (
                        <>
                          <FormattedNumber
                            number={mark.bottomThresholdScore}
                            withTruncate={true}
                          />
                          <p>&nbsp;s.d&nbsp;</p>
                          <FormattedNumber
                            number={mark.upperThresholdScore}
                            withTruncate={true}
                          />
                        </>
                      );

                      if (
                        idx ==
                        template.scoringComponents[0].scoringMarks.length - 1
                      ) {
                        rangeText = (
                          <>
                            &gt;&nbsp;
                            <FormattedNumber
                              number={mark.bottomThresholdScore}
                              withTruncate={true}
                            />
                          </>
                        );
                      } else if (idx != 0) {
                        rangeText = (
                          <>
                            &gt;&nbsp;
                            {rangeText}
                          </>
                        );
                      }

                      return (
                        <div
                          className="whitespace-nowrap score-space"
                          key={idx}
                        >
                          <div className="inline-block typography-paraghraph w-[40px] mr-[16px]">
                            {mark.score}
                          </div>
                          <div className="typography-paraghraph w-[128px] mr-[16px] inline-flex">
                            {rangeText}
                          </div>
                          {template?.showOption !== 'score_only' && (
                            <div className="inline-block typography-paraghraph pr-[16px]">
                              {mark.name}
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            {template.scoringMechanism && (
              <div className="scoring-mechanism pt-[24px] mt-[24px]">
                <p className="typography-h500">
                  {getObjectiveLocale('Scoring mechanism')}
                </p>
                <p className="typography-secondary mt-[4px]">
                  {ScoringMechanismDictionary[template.scoringMechanism]}
                </p>
                {template.workAttributes.length > 0 &&
                  template.workAttributes.map((attr, idx) => {
                    return (
                      <div
                        key={idx}
                        className="grey-box py-[8px] px-[12px] mb-[4px]"
                      >
                        <p>{attr.name}</p>
                      </div>
                    );
                  })}
                {template.formulaCalculation && (
                  <div className="my-[16px]">
                    <p className="typography-h400">
                      {getObjectiveLocale('Formula')}
                    </p>
                    <p
                      className={`mt-[8px] ${
                        !FormulaDictionary[template.formulaCalculation]
                          ? 'bg-n-300 font-mono text-justify px-[8px] py-[8px] typography-h300 text-n-800 leading-loose rounded-[4px]'
                          : 'typography-secondary'
                      }`}
                    >
                      {FormulaDictionary[template.formulaCalculation]
                        ? getObjectiveLocale(
                            FormulaDictionary[template.formulaCalculation]
                          )
                        : template.formulaCalculation}
                    </p>
                  </div>
                )}
              </div>
            )}
            {template.ratingType == 'value_recognition' && (
              <>
                <div className="value-recognition-activities pt-[24px] mt-[24px]">
                  <p className="typography-h500 mb-[4px]">
                    {getObjectiveLocale('Value recognition activities')}
                  </p>
                  <span className="typography-secondary">
                    {getValueRecognitionActivity(
                      template.valueRecognitionActivity
                    )}
                  </span>
                </div>
                <div className="value-recognition-percentage pt-[24px] mt-[24px]">
                  <p className="typography-h500 mb-[8px]">
                    {getObjectiveLocale('Percentage range progress')}
                  </p>
                  <div className="table-container pb-[8px]">
                    <div className="t-body inline-block px-[16px] py-[4px] w-full overflow-auto">
                      <div className="pb-[8px] inline-block">
                        <div className="whitespace-nowrap">
                          <div className="inline-block typography-h100 typography-secondary w-[40px] mr-[16px]">
                            {getObjectiveLocale('Rating')}
                          </div>
                          <div className="inline-block typography-h100 typography-secondary w-[135px]">
                            {getObjectiveLocale('Range')}
                          </div>
                          <div className="inline-block typography-h100 typography-secondary">
                            {getObjectiveLocale('Label')}
                          </div>
                        </div>
                      </div>
                      {template.scoringComponents[0].scoringMarks.map(
                        (mark, idx) => {
                          let rangeText = `${mark.bottomThresholdPercentage} - ${mark.upperThresholdPercentage}`;
                          if (
                            idx ==
                            template.scoringComponents[0].scoringMarks.length -
                              1
                          ) {
                            rangeText = `> ${mark.bottomThresholdPercentage}`;
                          } else if (idx != 0) {
                            rangeText = '> ' + rangeText;
                          }

                          return (
                            <div
                              key={idx}
                              className="whitespace-nowrap score-space"
                            >
                              <div className="inline-block typography-paraghraph w-[40px] mr-[16px]">
                                {mark.score}
                              </div>
                              <div className="inline-block typography-paraghraph w-[135px]">
                                {rangeText}
                              </div>
                              <div className="inline-block typography-paraghraph  pr-[16px]">
                                {mark.name}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {template.ratingType == 'performance_final' && (
              <>
                <div className="performance-final pt-[24px] mt-[24px]">
                  <p className="typography-h500 mb-[4px]">
                    {getObjectiveLocale('Formula calculation')}
                  </p>
                  <span className="typography-secondary">
                    {template.spConfigs.length !== 0
                      ? 'Score will reduced by SP'
                      : 'Score will not reduced by SP'}
                  </span>
                  {template.spConfigs.length !== 0 && (
                    <div className="mt-[8px]">
                      <div className="t-body px-[16px] py-[4px]">
                        <div className="flex justify-between mb-[8px]">
                          <div className="typography-h100 typography-secondary">
                            {getObjectiveLocale('SP name')}
                          </div>
                          <div className="typography-h100 typography-secondary">
                            {getObjectiveLocale('Score reduction')}
                          </div>
                        </div>
                        {template.spConfigs.map((val, idx) => (
                          <div
                            key={idx}
                            className="score-space flex justify-between"
                          >
                            <div>{val.name}</div>
                            <div>-{val.valueReduction}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SidebarRatingTemplate;

import React from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';
import getListBadge from 'utils/ListBadgeHelper';
import {
  getClusters,
  whichBgColorClassName,
  whichColor,
  whichStatus
} from 'utils/ObjectivesHelper';

import ListLabel from 'components/objectives/ListLabel';
import ProgressObjective from 'components/objectives/compact-objective/fragments/ProgressObjective';
import CalculationTypeIndicator from 'components/shared/CalculationTypeIndicator';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

function ObjectivesCard({ objective, key }) {
  const history = useHistory();
  const { url } = useUrl();
  const nowDate = new dayjs();

  const showDetail = (id) => {
    history.replace({
      pathname: `${url}/${id}`,
      search: location.search
    });
  };

  const {
    id,
    name,
    stateObject,
    state,
    measurement,
    permissions,
    progressUpdated,
    startDate,
    calculationType,
    isProject
  } = objective;

  const { progressPercentage, progressColorHex, expectedProgressPercentage } =
    measurement || [];

  const clusters = getClusters(objective);
  const tooltipProgressText = [
    getObjectiveLocale(`Expected: ${expectedProgressPercentage}%`),
    <br key="br" />,
    whichStatus[progressColorHex]
  ];
  const emptyProgress = nowDate.isAfter(startDate) ? (
    !progressUpdated ? (
      <p className={`typography-h400 text-start text-n-600`}>
        {getObjectiveLocale('Not updated')}
      </p>
    ) : (
      <p
        className={`typography-h400 text-start ${whichColor[progressColorHex]}`}
      >
        {whichStatus[progressColorHex]}
      </p>
    )
  ) : (
    <p className={`typography-h400 text-start text-n-600`}>
      {getObjectiveLocale('Not started')}
    </p>
  );

  const listBadge = getListBadge({
    listBadge: ['weight', 'goalType', 'history'],
    objective: objective,
    isObjectivesCard: true
  });

  return (
    <div
      data-cy="objective-card"
      onClick={() => showDetail(id)}
      key={key}
      className="px-[24px] py-[24px] border-n400 rounded-[6px] mt-[8px] w-[364px] m-auto cursor-pointer"
    >
      <div className="top flex items-center justify-between">
        <div className="flex items-center">
          {isProject && (
            <SVGIcon
              iconName="icon-project-badge"
              fillColor="var(--n-400)"
              size={24}
              customClass="mr-[16px]"
            />
          )}
          <TooltipContainer
            show={name.length > 25}
            position="top"
            text={name}
            useMaxWidth={false}
          >
            <p className="multiline-text-1 typography-h500">{name}</p>
          </TooltipContainer>
        </div>
        <div data-cy="goal-tree-state">
          {objective?.overdue === true ? (
            <SVGIcon
              iconName="icon-overdue"
              fillColor="var(--y-600)"
              size={24}
            />
          ) : stateObject?.state === 'reviewed' || state === 'reviewed' ? (
            <SVGIcon
              iconName="icon-reviewed"
              fillColor="var(--base-600)"
              size={24}
            />
          ) : (
            stateObject?.state === 'completed' ||
            (state === 'completed' && (
              <SVGIcon
                iconName="icon-thin-check"
                fillColor="var(--g-600)"
                size={24}
              />
            ))
          )}
        </div>
      </div>
      <div className="flex mt-[16px] !ml-[-8px]">
        <ListLabel data={listBadge} centered={false} />
      </div>
      <div className="w-full mt-[16px] flex" data-cy="list-detail-progress">
        {clusters?.includes('progress') ? (
          <ProgressObjective
            progressPercentage={progressPercentage}
            expectedProgressPercentage={expectedProgressPercentage}
            tooltipProgressText={tooltipProgressText}
            emptyProgress={emptyProgress}
            barColor={whichBgColorClassName(progressColorHex, false, '500')}
            textColor={whichColor[progressColorHex]}
            permissions={permissions}
            objective={objective}
            allowZero={progressUpdated}
            width={236}
            height={16}
            progressWidth="w-[64px]"
          />
        ) : (
          <p className="typography-h400 text-n-600 w-[100%]">
            {getObjectiveLocale('Not Visible')}
          </p>
        )}
        <CalculationTypeIndicator
          calculationType={calculationType}
          customClass="ml-[8px]"
        />
      </div>
    </div>
  );
}

export default ObjectivesCard;

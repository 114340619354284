import React, { useState } from 'react';
import { withRouter } from 'react-router';

import { addOrganization } from 'client/adminClient';

import HeaderPage from 'components/admin/HeaderPage';
import Button from 'components/design-system/Button';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';
import ToastNotif from 'components/shared/ToastNotif';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

function CreateOrganization({ history, route: { title, isReadOnly } }) {
  const [error, setError] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [allowSharing, setAllowSharing] = useState(false);
  const [allowVideo, setAllowVideo] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');
  const [userVideoLength, setUserVideoLength] = useState('');
  const [adminVideoLength, setAdminVideoLength] = useState('');
  const [isShowPasswordValue, setIsShowPasswordValue] = useState(false);
  const [cultureSelected, setCultureSelected] = useState(false);
  const typeInputField = isShowPasswordValue ? 'text' : 'password';

  const [organization, setOrganization] = useState({
    organizationName: '',
    teamName: '',
    adminName: '',
    adminEmail: '',
    password: ''
  });

  const onConfirmAddData = async () => {
    let body = {
      identifier: organization.teamName,
      name: organization.organizationName,
      userName: organization.adminName,
      email: organization.adminEmail,
      password: organization.password
    };

    if (cultureSelected) {
      body = {
        ...body,
        culture: true,
        allowSharing: allowSharing,
        allowVideo: allowVideo
      };
    }

    if (allowVideo) {
      body = {
        ...body,
        videoConfig: {
          adminVideoLength: adminVideoLength,
          userVideoLength: userVideoLength
        }
      };
    }

    const { data, error } = await addOrganization(body);
    if (data) {
      setShowNotif(true);
      setNotifMessage('Successfully Added!');
      setTimeout(() => {
        setShowNotif(false);
        history.replace(`/organizations`);
      }, 2000);
    }
  };

  return (
    <>
      <HeaderPage
        titlePage={title}
        backToUrl={`/organizations`}
        isHeaderComposer={true}
      />
      <div className="admin-culture">
        <div className="create-organization" data-cy="modal-success">
          <ToastNotif showNotif={showNotif} message={notifMessage} />
          <div className="col-xs-7 mt-[24px]">
            <div className="form-group">
              <label htmlFor="orgName">Organization Name</label>
              <input
                type="text"
                disabled={isReadOnly}
                className="form-control"
                href="createOrgName"
                id="orgName"
                placeholder="Organization Name"
                onChange={(e) =>
                  setOrganization({
                    ...organization,
                    organizationName: e.currentTarget.value
                  })
                }
                value={organization.organizationName}
                data-cy="organization-name"
              />
              {error && <p className="error-message">This field is required</p>}
            </div>
            <div className="form-group">
              <label htmlFor="teamName">Team Name</label>
              <input
                type="text"
                className="form-control"
                href="createTeamName"
                id="teamName"
                disabled={isReadOnly}
                placeholder="Team Name"
                onChange={(e) =>
                  setOrganization({
                    ...organization,
                    teamName: e.currentTarget.value
                  })
                }
                value={organization.teamName}
                data-cy="organization-identifier"
              />
              {error && <p className="error-message">This field is required</p>}
            </div>
            <div className="form-group">
              <label htmlFor="teamName">First Admin Name</label>
              <input
                type="text"
                disabled={isReadOnly}
                className="form-control"
                href="createAdminName"
                id="adminName"
                placeholder="First Admin Name"
                onChange={(e) =>
                  setOrganization({
                    ...organization,
                    adminName: e.currentTarget.value
                  })
                }
                value={organization.adminName}
                data-cy="organization-admin-username"
              />
              {error && <p className="error-message">This field is required</p>}
            </div>
            <div className="form-group">
              <label htmlFor="teamName">First Admin Email</label>
              <input
                disabled={isReadOnly}
                type="text"
                className="form-control"
                href="createAdminEmail"
                id="adminEmail"
                placeholder="First Admin Email"
                onChange={(e) =>
                  setOrganization({
                    ...organization,
                    adminEmail: e.currentTarget.value
                  })
                }
                value={organization.adminEmail}
                data-cy="organization-admin-email"
              />
              {error && <p className="error-message">This field is required</p>}
            </div>
            <div className="form-group">
              <label htmlFor="teamName">Password</label>
              <input
                disabled={isReadOnly}
                className="form-control styled-input"
                href="createPassword"
                id="adminPassword"
                type={typeInputField}
                placeholder="Password"
                onChange={(e) =>
                  setOrganization({
                    ...organization,
                    password: e.currentTarget.value
                  })
                }
                value={organization.password}
                data-cy="organization-admin-password"
              />
              <SVGIcon
                iconName={
                  isShowPasswordValue
                    ? 'icon-remove_red_eye'
                    : 'icon-disabled_red_eye'
                }
                size={isShowPasswordValue ? '20' : '24'}
                fillColor="var(--n-600)"
                customClass="icon-eyes items-center justify-center"
                onClick={() =>
                  !isReadOnly && setIsShowPasswordValue(!isShowPasswordValue)
                }
              />
              {error && <p className="error-message">This field is required</p>}
            </div>
            <div className="button-application">
              <div className="flex items-center justify-between">
                <div className="culture-content">
                  <label className="culture-title" htmlFor="culture">
                    Culture{' '}
                  </label>
                  <p>
                    Performance will be created as a default, turn on this
                    toggle to include or create culture only
                  </p>
                </div>
                <SmallToggleSwitchPurple
                  idStr="check-culture"
                  addClass="mt-[16px]"
                  disabled={isReadOnly}
                  onChange={() => {
                    setCultureSelected(!cultureSelected);
                  }}
                  isChecked={cultureSelected}
                  dataCy="check-culture"
                />
              </div>
            </div>
            {cultureSelected && (
              <>
                <Checkbox
                  customContainerClass="mb-[24px]"
                  onChange={() => setAllowSharing(!allowSharing)}
                  checked={allowSharing}
                  name="checkboxAllowSharing"
                  id="allowSharing"
                  value="sharing"
                  disabled={isReadOnly}
                  htmlFor="allowSharing"
                  dataCy="organization-allow-sharing"
                >
                  <p className="ml-[8px] typography-paragraph">Allow Sharing</p>
                </Checkbox>

                <Checkbox
                  customContainerClass="mb-[24px]"
                  onChange={() => setAllowVideo(!allowVideo)}
                  checked={allowVideo}
                  name="checkboxAllowVideo"
                  id="allowVideo"
                  value="video"
                  disabled={isReadOnly}
                  htmlFor="allowVideo"
                  dataCy="organization-allow-video"
                >
                  <p className="ml-[8px] typography-paragraph">Allow Video</p>
                </Checkbox>
                {allowVideo && (
                  <div className="form-group">
                    <label htmlFor="userVideoLength">User Video Length</label>
                    <input
                      disabled={isReadOnly}
                      className="form-control"
                      id="userVideoLength"
                      type="number"
                      placeholder="In Seconds"
                      value={userVideoLength}
                      onChange={(e) =>
                        setUserVideoLength(parseInt(e.currentTarget.value))
                      }
                      data-cy="organization-user-video-length"
                    />
                    {error && (
                      <p className="error-message">This field is required</p>
                    )}
                  </div>
                )}
                {allowVideo && (
                  <div className="form-group">
                    <label htmlFor="adminVideoLength">Admin Video Length</label>
                    <input
                      disabled={isReadOnly}
                      className="form-control"
                      id="adminVideoLength"
                      type="number"
                      placeholder="In Seconds"
                      value={adminVideoLength}
                      onChange={(e) =>
                        setAdminVideoLength(parseInt(e.currentTarget.value))
                      }
                      data-cy="organization-admin-video-length"
                    />
                    {error && (
                      <p className="error-message">This field is required</p>
                    )}
                  </div>
                )}
              </>
            )}

            <Button
              onClick={(e) => onConfirmAddData(e)}
              datacy="btn-submit"
              disabled={isReadOnly}
              customClass="w-full"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(CreateOrganization);

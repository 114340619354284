import React, { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';

import { getObjectivesCount } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import { formatTimezone } from 'utils/DateUtils';
import { restructureFilter } from 'utils/ObjectivesHelper';

import OverallPhase from 'components/shared/card/OverallProgressCard/OverallPhase';
import OverallProgressSetWeight from 'components/shared/card/OverallProgressCard/OverallProgressSetWeight';
import OverallStatus from 'components/shared/card/OverallProgressCard/OverallStatus';
import ErrorBoundary from 'pages/ErrorBoundary';
import useLoadingStatus from 'src/hooks/useLoadingStatus';

const StatistikOverallViewProgressComponent = (props) => {
  const {
    children,
    dispatchFilter,
    visibility,
    annual,
    gridArea,
    filter,
    profileFilter,
    className,
    userId,
    ...rest
  } = props;

  const { user } = useUser();
  const currentUserId = userId ?? user.id;
  const setStatusChange = useLoadingStatus((state) => state?.setStatusChange);
  const filterByStatus = (status) => {
    setStatusChange(true);
    dispatchFilter({ type: 'STATUS-OVERALL', state: status, annual });
  };

  const filterByPhase = (phase) => {
    dispatchFilter({ type: 'PHASE-OVERALL', state: phase });
  };

  const isVisible = (key) => visibility.includes(key);
  const statusActive =
    filter?.status?.length === 4 || filter?.status?.length === 0
      ? 'all'
      : filter?.status;
  const phaseActive =
    filter?.phase?.length === 3 || filter?.phase?.length === 0
      ? 'all'
      : filter?.phase;
  const getCount = () => {
    let newFilter = restructureFilter({ ...filter, ...profileFilter });
    if (annual) {
      newFilter = {
        assigneeId: [...(newFilter?.assigneeId || [user.id])],
        parentNotAssignedTo: currentUserId,
        periodBegin: formatTimezone(
          `${new Date().getFullYear()}-01-01`,
          'start'
        ),
        periodEndBefore: formatTimezone(
          `${new Date().getFullYear()}-12-31`,
          'end'
        ),
        state: ['running', 'completed', 'reviewed', 'draft', 'edited'],
        type: ['goal', 'annual_goal']
      };
    }

    return getObjectivesCount(newFilter);
  };

  let { data, refetch } = useQuery(['overallProgress'], getCount, {
    suspense: true,
    forceFetchOnMount: true
  });

  let overallData = data?.data;
  let listStatus = overallData?.listStatus;
  let listPhases = overallData?.listPhases;
  let totalObjectives = overallData?.totalObjectives;
  let progressColorHex = overallData?.progressColorHex;
  let overallProgress = overallData?.overallProgress;
  let totalWeight = overallData?.totalWeights;
  let canEditWeight = overallData?.editWeightGoal;

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      refetch();
    }
  }, [filter, profileFilter]);

  return (
    <>
      <div
        className={`container-overall-view-progress flex ${className}`}
        style={gridArea && { gridArea: 'analytics' }}
      >
        {isVisible('progress') && (
          <OverallProgressSetWeight
            colorHex={progressColorHex}
            overallProgressPercentage={overallProgress}
            totalObjectives={totalObjectives}
            totalWeight={totalWeight}
            canEditWeight={canEditWeight}
          />
        )}
        {isVisible('phase') && listPhases && (
          <OverallPhase
            onClick={filterByPhase}
            active={phaseActive}
            listPhases={listPhases}
            totalObjectives={totalObjectives}
          />
        )}

        {isVisible('status') && (
          <OverallStatus
            withOpacity={true}
            listStatus={listStatus}
            onClick={filterByStatus}
            active={statusActive}
          />
        )}
      </div>

      {children &&
        React.cloneElement(children, { dispatchFilter, filter, ...rest })}
    </>
  );
};

const StatistikOverallViewProgress = (props) => (
  <React.Suspense fallback={<div></div>}>
    <ErrorBoundary>
      <StatistikOverallViewProgressComponent {...props} />
    </ErrorBoundary>
  </React.Suspense>
);

export default StatistikOverallViewProgress;

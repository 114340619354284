import React, { useEffect, useState } from 'react';

import Dropdown, {
  useDropdown
} from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import ConditionalWrapper from 'components/shared/ConditionalWrapper';

function DropdownMetric({
  handleChange,
  defaultValue = null,
  listMetrics,
  datacy = 'dropdown-metric',
  disabled,
  triggerCustomClass = 'w-[144px]',
  rightIcon,
  customClass = '',
  wrapFloating,
  containerCustomClass = ''
}) {
  const [selectedValue, setSelectedValue] = useState('No Metrics');

  const handleChangeDropdown = (metric) => {
    setSelectedValue(metric.description);
    handleChange(metric);
  };

  useEffect(() => {
    if (defaultValue !== null) {
      const value = listMetrics?.find(
        ({ id }) => id === defaultValue
      )?.description;
      value && setSelectedValue(value);
    }
  }, [defaultValue]);

  return (
    <Dropdown
      customClass={`${containerCustomClass ? containerCustomClass : 'w-full'}`}
    >
      <Dropdown.Trigger
        disabled={disabled}
        dataCy={datacy}
        customClass="w-full"
      >
        <Trigger
          icon={false}
          topLabel={false}
          label={false}
          text={selectedValue}
          disabled={disabled}
          iconName={rightIcon}
          customWrapperClass={triggerCustomClass}
        />
      </Dropdown.Trigger>
      <DropdownOptions
        customClass={customClass}
        listMetrics={listMetrics}
        datacy={datacy}
        handleChangeDropdown={handleChangeDropdown}
        wrapFloating={wrapFloating}
      />
    </Dropdown>
  );
}

const DropdownOptions = ({
  customClass,
  listMetrics,
  datacy,
  handleChangeDropdown,
  wrapFloating
}) => {
  const { open } = useDropdown();
  return (
    <>
      {open && (
        <ConditionalWrapper
          condition={wrapFloating}
          wrapper={(children) => (
            <FloatingComponent usePortal={false}>{children}</FloatingComponent>
          )}
        >
          <Dropdown.MenuItems
            position="right"
            customClass={customClass}
            style={{ width: '100%' }}
            useScrollClose
            maxHeight={186}
          >
            {listMetrics?.map((metric, index) => (
              <Dropdown.MenuItem
                key={metric.id}
                index={index}
                onClick={() => handleChangeDropdown(metric)}
                dataCy={`${datacy}-option-${metric.id}`}
              >
                {metric.description} {metric.unit !== '' && `(${metric.unit})`}
              </Dropdown.MenuItem>
            ))}
          </Dropdown.MenuItems>
        </ConditionalWrapper>
      )}
    </>
  );
};

export default DropdownMetric;

import client from './ApiClient';

function getReview(objectiveId, reviewId) {
  return client(`/v1/objectives/${objectiveId}/reviews/${reviewId}`, {
    params: {}
  });
}

function submitReview(id, data) {
  return client(`/v1/objectives/${id}/reviews`, { method: 'POST', body: data });
}

function updateReview(objectiveId, reviewId, data) {
  return client(`/v1/objectives/${objectiveId}/reviews/${reviewId}`, {
    method: 'PUT',
    body: data
  });
}

export { getReview, submitReview, updateReview };

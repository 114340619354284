import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  assignAdminGroup,
  getAcceptedMemberGroup,
  getAdminMemberGroup,
  getListUser,
  removeAdminGroup,
  removeMemberGroup
} from 'client/CultureClient';
import { useReload } from 'context/ReloadContext';
import useDebounce from 'hooks/useDebounce';
import { getCultureLocale, loadMoreValidator } from 'utils/HelperUtils';

import ManageMemberItem from 'components/modal/culture/components/ManageMemberItem';
import LoadingComponent from 'components/shared/LoadingComponent';
import SearchBar from 'components/shared/SearchBar';
import Modal from 'components/shared/modal/Modal';

const ModalManageMemberV2 = ({
  setIsManageMemberOpen,
  groupDetail,
  setShowInviteModal
}) => {
  const history = useHistory();
  const { reload, reloadCurrentGroup } = useReload();

  const isGroupDefaultModerated =
    groupDetail?.groupType == 'default' ||
    groupDetail?.groupType == 'default_moderated';
  const [searchUser, setSearchUser] = useState('');
  const [totalActiveUsers, setTotalActiveUser] = useState(0);
  const [totalAdmin, setTotalAdmin] = useState(0);
  const [nextScrollCursor, setNextScrollCursor] = useState(null);

  const [showOption, setShowOption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [onLoadMore, setOnLoadMore] = useState(false);

  const [listMember, setListMember] = useState([]);
  const [listAdmin, setListAdmin] = useState([]);
  const [searchMember, setSearchMember] = useState([]);
  const debouncedSearch = useDebounce(searchUser, 2000);
  const members = searchUser ? searchMember : listMember;

  const getAdminMember = useCallback(async () => {
    const { data: adminMember, total_users: totalUserAdmin } =
      await getAdminMemberGroup(groupDetail.id, {
        limit: 100
      });

    if (adminMember) {
      const changedAdminMember = adminMember?.map((member) => {
        member.isAdmin = true;
        member.isSelected = true;
        return member;
      });
      setTotalAdmin(totalUserAdmin);
      setListAdmin(changedAdminMember);
      setIsLoading(false);
    }
  }, [groupDetail.id]);

  const getAcceptedMember = async (isScroll) => {
    const {
      data: acceptedMember,
      next_cursor,
      total_users: totalAccepted
    } = await getAcceptedMemberGroup(groupDetail.id, {
      limit: 10,
      cursor: isScroll ? nextScrollCursor : 0
    });

    if (acceptedMember) {
      const changeAcceptedMember = acceptedMember?.map((member) => {
        member.isAdmin = false;
        member.isSelected = true;
        return member;
      });
      setListMember((list) =>
        isScroll ? [...list, ...changeAcceptedMember] : changeAcceptedMember
      );
      setNextScrollCursor(acceptedMember?.length >= 10 ? next_cursor : null);
      setTotalActiveUser(totalAccepted);
      !isScroll && setIsLoading(false);
    }
  };

  const setOptionFunction = async (e, currentMember) => {
    let isProcessSuccess = false;
    if (e.id === 'remove') {
      const { isSuccess } = await removeMemberGroup(
        groupDetail.id,
        currentMember?.id
      );
      isProcessSuccess = isSuccess;
    } else if (e.id === 'remove-admin') {
      const { isSuccess } = await removeAdminGroup(
        groupDetail.id,
        currentMember?.id
      );
      isProcessSuccess = isSuccess;
    } else {
      const { isSuccess } = await assignAdminGroup(
        groupDetail.id,
        currentMember?.id
      );
      isProcessSuccess = isSuccess;
    }

    if (isProcessSuccess) {
      if (e.id !== 'remove') {
        await getAdminMember(false);
      }
      await getAcceptedMember(false);
    }
    setShowOption(false);
  };

  const onSearchUser = async (isScroll) => {
    const params = {
      limit: 10,
      cursor: isScroll ? nextScrollCursor : 0,
      q: debouncedSearch
    };
    const { data, next_cursor, total_users } = await getListUser(
      groupDetail?.id,
      params
    );

    if (data && next_cursor) {
      setNextScrollCursor(data?.length >= 10 ? next_cursor : null);

      const changeAcceptedMember = data?.map((member) => {
        member.isAdmin = false;
        member.isSelected = true;
        return member;
      });
      setSearchMember((member) =>
        isScroll ? [...member, ...changeAcceptedMember] : changeAcceptedMember
      );
      setTotalActiveUser(total_users);
      !isScroll && setIsLoading(false);
    }
  };

  const onScrollMember = async (e) => {
    if (e.target) {
      const target = e.target;

      const loadMore = async () => {
        setOnLoadMore(true);
        searchUser == ''
          ? await getAcceptedMember(true)
          : await onSearchUser(true);
        setOnLoadMore(false);
      };

      const scroll = nextScrollCursor;
      const hasMore = scroll ? true : false;

      if (!onLoadMore && hasMore) {
        loadMoreValidator(target, 50, () => {
          loadMore();
        });
      }
    }
  };

  const closeModal = () => {
    setIsManageMemberOpen(false);
    reload({ reloadCurrentGroup: !reloadCurrentGroup });
    history.replace(`/timeline/group/${groupDetail?.id}`);
  };

  useEffect(() => {
    setIsLoading(true);
    if (!debouncedSearch) {
      getAcceptedMember(false);
      getAdminMember();
    } else {
      onSearchUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, getAdminMember, setIsLoading]);

  return (
    <Modal
      dataCyModal="manage-member-modal"
      title={getCultureLocale('Group Members')}
      className="w-[600px]"
      eventOnClickClose={() => closeModal()}
      maxHeight={560}
      withSecondaryBtn={{
        title: getCultureLocale('Close'),
        onClick: () => closeModal(),
        dataCy: 'secondary-member-btn'
      }}
      withLeftBtn={
        !isGroupDefaultModerated && {
          title: getCultureLocale('Invite Member'),
          onClick: () => setShowInviteModal(true),
          dataCy: 'invite-member-btn'
        }
      }
    >
      <SearchBar
        fullWidth
        placeholder={getCultureLocale(
          `Search ${isGroupDefaultModerated ? 'admin' : 'member'}...`
        )}
        onChange={(e) => setSearchUser(e.target.value)}
        value={searchUser}
        dataCy="search-member"
      />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <div
          className="h-[200px] overflow-auto pr-[16px] mt-[24px]"
          onScroll={(e) => onScrollMember(e, false)}
        >
          {!searchUser && (
            <div className="mb-[34px]">
              <div className="flex justify-between typography-h100 text-n-800 mb-[20px]">
                <p className="uppercase">{getCultureLocale('HumanResource')}</p>
                <p data-cy="selected-admin" className="uppercase">
                  {getCultureLocale('Selected')}: {totalAdmin}
                </p>
              </div>
              {listAdmin?.length > 0 &&
                listAdmin?.map((member) => {
                  return (
                    <ManageMemberItem
                      member={member}
                      type="admin"
                      key={`admin-${member?.id}`}
                      setShowOption={setShowOption}
                      setOptionFunction={setOptionFunction}
                      totalAdmin={totalAdmin}
                      showOption={showOption}
                      isGroupDefaultModerated={isGroupDefaultModerated}
                    />
                  );
                })}
            </div>
          )}
          {
            <div className="mb-[34px]">
              <div className="flex justify-between typography-h100 text-n-800 mb-[20px]">
                <p className="uppercase">{getCultureLocale('Members')}</p>
                <p data-cy="selected-member" className="uppercase">
                  {getCultureLocale('Selected')}: {totalActiveUsers}
                </p>
              </div>
              {members?.length > 0 &&
                members?.map((member) => {
                  return (
                    <ManageMemberItem
                      member={member}
                      type="member"
                      key={`member-${member?.id}`}
                      setShowOption={setShowOption}
                      setOptionFunction={setOptionFunction}
                      showOption={showOption}
                      isGroupDefaultModerated={isGroupDefaultModerated}
                    />
                  );
                })}
            </div>
          }
        </div>
      )}
    </Modal>
  );
};

export default ModalManageMemberV2;

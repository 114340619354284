import React, { useState } from 'react';

import { getShareToken, likePost, unlikePost } from 'client/CultureClient';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import { getCultureLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import ShareLink from 'components/shared/ShareLink/ShareLink';
import ToastNotif from 'components/shared/ToastNotif';
import { trackEvent } from 'src/utils/AnalyticUtils';

function PostAction({ currentFeed, postId, groupDetail, focus }) {
  const { organization, user } = useUser();
  const { reload } = useReload();
  const { loved, postTypeV2: postType, shareable } = currentFeed;
  const [currentLoved, setCurrentLoved] = useState(loved);
  const [isToastShow, setIsToastShow] = useState(false);

  const setLike = async (e) => {
    e.stopPropagation();
    const { data } = currentLoved
      ? await unlikePost(postId)
      : await likePost(postId);

    if (data) {
      setCurrentLoved(!currentLoved);
      const properties = {
        'post type': postType,
        'post id': postId,
        'group name': groupDetail.name,
        'group type': groupDetail.groupType
      };

      trackEvent({
        event: currentLoved ? 'unlove' : 'love',
        eventProperties: properties,
        env: 'culture'
      });

      reload({
        reloadLikeCountPost: {
          postId: postId,
          action: currentLoved ? 'unlike' : 'like'
        }
      });
    }
  };

  const getToken = async () => {
    const body = {
      postId: postId,
      organization: { teamName: organization?.teamName },
      post: { id: postId },
      user: { id: user?.id }
    };
    return await getShareToken(postId, body);
  };

  const ShareLinkTrigger = () => {
    return (
      <div className="share-to hover-list py-[16px] px-[24px] cursor-pointer relative flex items-center">
        <div className="flex items-center">
          <SVGIcon
            size="24"
            iconName="icon-share"
            fillColor="var(--n-600)"
            customClass="mr-[4px]"
          />
          <p className="typography-h500 text-n-800">
            {getCultureLocale('Share to')}
          </p>
        </div>
      </div>
    );
  };

  const onSuccessFunction = () => {
    setIsToastShow(true);
    setTimeout(function () {
      setIsToastShow(false);
    }, 3000);
  };

  return (
    <div
      className="feed-action justify-between"
      data-cy={`post-action-${postId}`}
    >
      <ToastNotif showNotif={isToastShow} message="Link Copied" />
      <div
        className="like typography-h500 text-n-800 py-[16px] hover-list px-[24px]"
        onClick={(e) => setLike(e)}
        data-cy="like"
      >
        {currentLoved ? (
          <img
            src={Config.asset.culture.likeActive}
            className="like-icon mr-[4px]"
            data-cy="active-icon"
          />
        ) : (
          <img
            src={Config.asset.culture.likeInactive}
            className="unlike-icon mr-[4px]"
            data-cy="inactive-icon"
          />
        )}{' '}
        {getCultureLocale('Like')}
      </div>
      <div
        className="comment typography-h500 text-n-800 py-[16px] hover-list px-[24px]"
        data-cy="comment"
        onClick={focus}
      >
        <img src={Config.asset.culture.comment} className="mr-[4px]" />{' '}
        {getCultureLocale('Comment')}
      </div>
      {shareable ? (
        <ShareLink
          dataCy="share-to"
          getToken={getToken}
          pathName="posts"
          onSuccessFunction={onSuccessFunction}
          trigger={<ShareLinkTrigger />}
        />
      ) : (
        <div className="w-[140px]" />
      )}
    </div>
  );
}

export default PostAction;

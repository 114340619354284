import React, { useEffect, useState } from 'react';

import LoadingComponent from 'components/shared/LoadingComponent';

import EmployeeRole from './PerformanceReportEmployeeRole';
import PerformanceScore from './PerformanceReportPerformanceScore';
import TeamInvolvement from './PerformanceReportTeamInvolvement';

function NewPerformanceReport(props) {
  const [data, setData] = useState();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setData(props.data);
    props.data && setReady(true);
  }, [props.data]);

  return ready ? (
    <div className="new-performance-report-container">
      <PerformanceScore
        marginRight
        score={data.averageScore && data.averageScore.score}
        maxScore={data.averageScore && data.averageScore.maxScore}
        star={data.averageScore && data.averageScore.score}
        description={
          data.averageScore &&
          data.averageScore.scoringOption &&
          data.averageScore.scoringOption.description
        }
        descColor={
          data.averageScore &&
          data.averageScore.scoringOption &&
          `#${data.averageScore.scoringOption.colorHex}`
        }
        teamRating={data.averageTeamScore && data.averageTeamScore.score}
        individualRating={
          data.averageIndividualScore && data.averageIndividualScore.score
        }
      />
      <TeamInvolvement
        numberOfLeaderInvolvement={data.countTeamsLeader}
        numberOfMemberInvolvement={data.countTeamsMember}
      />
      <EmployeeRole numberOfRole={data.countRoles} />
    </div>
  ) : (
    <LoadingComponent />
  );
}

export default NewPerformanceReport;

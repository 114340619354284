import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useUrl } from 'hooks/useUrl';
import { whichColor } from 'utils/ObjectivesHelper';
import { getOwners } from 'utils/ObjectivesHelper';

import Avatar from 'components/design-system/Avatar';
import FormattedNumber from 'components/design-system/FormattedNumber';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';

const GanttObjectiveContainer = ({
  objectiveData,
  level,
  openSidebarDetail,
  showChildToggle,
  showChild,
  subContentOptions
}) => {
  const { location } = useHistory();
  const { url } = useUrl();
  const { pathname } = location;
  const opennedTaskId = pathname.replace(url + '/', '');

  const [isHover, setHover] = useState(false);
  const [isActive, setActive] = useState(false);

  const { goalOwnersName, goalOwnersProfilePic, goalOwners } = getOwners(
    objectiveData.involvements,
    objectiveData.isProject
  );

  useEffect(() => {
    if (pathname == location) return;
    if (opennedTaskId == objectiveData?.id) {
      setActive(true);
      return;
    }
    setActive(false);
  }, [pathname, objectiveData, location, opennedTaskId]);

  useEffect(() => {
    const bgRow = document.getElementById('bg-row-' + objectiveData?.id);
    if (!bgRow) return;
    if (isHover) {
      bgRow.style.backgroundColor = 'var(--base-3008)';
      return;
    }
    if (isActive) return;
    bgRow.style.backgroundColor = '';
  }, [objectiveData, isHover, isActive]);

  return (
    <div
      id={'bg-task-name-' + objectiveData?.id}
      className={`h-[48px] main-row flex w-full px-[16px] ${
        isHover || isActive ? 'bg-active' : ''
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <div
        className={`h-[48px] main-row flex w-full items-center cursor-pointer ${
          level > 0 ? 'is-child z-[1]' : 'z-[2]'
        }`}
        style={{ paddingLeft: level * 42 + 'px' }}
        onClick={() => openSidebarDetail(objectiveData?.id)}
      >
        {level > 0 && <div className="curved-line-tree" />}
        {level == 0 &&
          (objectiveData.childrenCount > 0 && subContentOptions ? (
            <div className="relative">
              {showChild && <div className="additional-line" />}
              <SVGIcon
                id={`icon-accordion-task-${objectiveData?.id}`}
                onClick={showChildToggle}
                size={24}
                fillColor="var(--n-600)"
                customClass="transparent"
                iconName={`${
                  !showChild ? 'icon-arrow_right' : 'icon-arrow_drop_down'
                }`}
              />
            </div>
          ) : (
            <div className="min-w-[24px]" />
          ))}
        <div
          className={`flex space-between ${
            level == 0 ? 'w-[calc(100%-24px)]' : 'w-full'
          } items-center`}
        >
          <div
            style={{
              width: objectiveData?.measurement
                ? `calc(100% - ${40 + 28}px)`
                : `calc(100% - ${32}px)`
            }}
          >
            <TruncateParagraph
              align="left"
              className="typoghraphy-h400 text-n-900 ml-[8px]"
            >
              {objectiveData.name}
            </TruncateParagraph>
          </div>
          <div className="task-name-right flex flex-row items-center">
            {objectiveData?.measurement && (
              <div
                className={`w-[32px] flex flex-row justify-end ${
                  whichColor[objectiveData.measurement.progressColorHex]
                }`}
              >
                <FormattedNumber
                  number={objectiveData.measurement.currentValue}
                  customClass="typography-h100"
                />
                <span className="typography-h100">%</span>
              </div>
            )}
            {goalOwners?.length > 0 && (
              <Avatar
                size="24"
                customClass="cursor-default ml-[4px]"
                src={goalOwnersProfilePic}
                name={goalOwners?.length == 1 ? goalOwnersName : ''}
                tooltip={goalOwners?.length == 1}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GanttObjectiveContainer;

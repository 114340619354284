import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';

import S3Client from 'client/S3Client';
import { getPerformancePreSignUrl } from 'client/UploadClient';
import { getListSwitchPosition } from 'client/adminClient.js';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Avatar from 'components/shared/Avatar';
import ToastNotif from 'components/shared/ToastNotif.js';
import TruncateParagraph from 'components/shared/TruncateParagraph';

import { updateConfigurations, updateUser } from '../../client/UserProfile';
import ProfileConfigurations from './ProfileConfigurations';

function UserProfileComponent(props) {
  const { user: currentUser, config, appType } = useUser();
  const { reload, reloadUser } = useReload();
  const history = useHistory();
  const { userId } = useParams();
  const [openPositions, setOpenPositions] = useState(false);
  const [openConfigurations, setOpenConfigurations] = useState(false);
  const [listPosition, setListPosition] = useState([]);
  const [isSuccessState, setSuccessState] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const positionsRef = useRef();
  const profilePictureRef = useRef();
  const user = props?.user;
  let defaultConfigurations = {
    goalCreationStartsAt: user?.placement?.goalCreationStartsAt,
    goalCreationEndsAt: user?.placement?.goalCreationEndsAt,
    goalDeleteStartsAt: user?.placement?.goalDeleteStartsAt,
    goalDeleteEndsAt: user?.placement?.goalDeleteEndsAt,
    goalEditStartsAt: user?.placement?.goalEditStartsAt,
    goalEditEndsAt: user?.placement?.goalEditEndsAt,
    taskCreationStartsAt: user?.placement?.taskCreationStartsAt,
    taskCreationEndsAt: user?.placement?.taskCreationEndsAt,
    taskDeleteStartsAt: user?.placement?.taskDeleteStartsAt,
    taskDeleteEndsAt: user?.placement?.taskDeleteEndsAt,
    taskEditStartsAt: user?.placement?.taskEditStartsAt,
    taskEditEndsAt: user?.placement?.taskEditEndsAt,
    state: user?.placement?.state,
    startsAt: user?.placement?.startsAt,
    endsAt: user?.placement?.endsAt,
    userId: user?.placement?.userId,
    groups: {},
    info: user?.info
  };
  const [configurations, setConfigurations] = useState(defaultConfigurations);

  useClickOutside(positionsRef, () => setOpenPositions(false));

  const changePositionField = (value, type) => {
    const detail = cloneDeep(configurations);

    detail[type[0]] =
      value[0] && dayjs(value[0])?.startOf('day')?.toISOString();
    detail[type[1]] = value[1] && dayjs(value[1])?.endOf('day')?.toISOString();
    setConfigurations(detail);
  };

  const saveConfigurations = async () => {
    const data = await updateConfigurations(
      user?.id,
      user?.placementId,
      configurations
    );

    if (data) {
      setOpenConfigurations(false);
      setSuccessState(true);
      setSuccessMessage('Saved!');
      setTimeout(() => {
        setSuccessState(false);
        props?.getData();
      }, 3000);
    }
  };

  const resetData = () => {
    setConfigurations(defaultConfigurations);
    setOpenConfigurations(false);
  };

  const _getPresignUrl = async (file) => {
    const filename = file.name;
    const extensions = filename.split('.').pop();

    const presignQuery = {
      directory: 'profiles',
      extension: extensions
    };
    const { data } = await getPerformancePreSignUrl(presignQuery);
    return data;
  };

  const uploadToServer = async (url, file) => {
    const { status } = await S3Client(url, file);
    return status;
  };

  const onChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type == 'image/png' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/jpg'
      ) {
        const url = await _getPresignUrl(file);
        if (url) {
          const status = await uploadToServer(url.uploadUrl, file);
          if (status == 200) {
            const body = {
              profilePicture: url.downloadUrl
            };

            await updateUser(body, currentUser.id);
            props?.getData();
            reload({ reloadUser: !reloadUser });
          }
        }
      } else {
        alert('Wrong file type! file type must be png, jpg or jpeg!');
      }
    }
    return;
  };

  const getList = async () => {
    const { data } = await getListSwitchPosition(user.id);
    if (data) {
      let pos = data?.filter(
        (val, index) => val?.positions?.[index]?.id != user?.placementId
      );
      setListPosition(pos);
    }
  };

  const isAllowedToUpdate = config.featureConfig?.updateProfile;

  return (
    <>
      <div className="user-info-card">
        <ToastNotif showNotif={isSuccessState} message={successMessage} />
        <label
          htmlFor="profile"
          className={`text-center ${
            currentUser.id == user?.id && isAllowedToUpdate
              ? 'cursor-pointer'
              : ''
          }`}
        >
          <Avatar
            name={user?.name || user?.fullName}
            src={
              user?.profilePicture?.secureUrl || user?.profilePicture || null
            }
            size={88}
            onClick={(e) => {
              e?.stopPropagation();
              profilePictureRef?.current?.click();
            }}
          />
        </label>
        {currentUser.id == user?.id && isAllowedToUpdate && (
          <input
            id="profile"
            type="file"
            ref={profilePictureRef}
            accept="image/jpg,image/png,image/jpeg"
            onChange={(e) => onChange(e)}
          />
        )}
        <p className="typography-h600 text-center mt-[16px] mb-[8px]">
          {(user && user?.name) || user?.fullName}
        </p>
        <p className="typography-paragraph text-center text-n-800 mb-[4px]">
          {user?.title}
        </p>
        {user?.subtitle && (
          <p className="position text-center text-n-800 mb-[4px]">
            {user?.subtitle}
          </p>
        )}
        {appType != 'culture' && (
          <p
            className={`text-center typography-h100 mb-[12px] ${
              user?.currentPosition ? 'text-g-600' : 'text-n-600'
            }`}
          >
            {getObjectiveLocale(
              user?.currentPosition
                ? '(Current Position)'
                : '(Previous Position)'
            )}
          </p>
        )}

        {user?.info?.department && (
          <div className="info-container">
            <span className="description mb-[8px] fs-12">
              {getObjectiveLocale('Department')}
            </span>
            <span className="department">{user?.info?.department}</span>
          </div>
        )}
        {user?.info?.orgUnit && (
          <div className="info-container">
            <span className="description mb-[8px] fs-12">
              {getObjectiveLocale('Org Unit')}
            </span>
            <span className="org-unit">{user?.info?.orgUnit}</span>
          </div>
        )}
        {
          <div className="info-container">
            <span className="description mb-[8px] fs-12">
              {getObjectiveLocale('Active Date')}
            </span>
            <span className="active-date">{`${getDateLocale(
              dayjs(user?.placement?.startsAt),
              { month: 'short', day: 'numeric', year: 'numeric' }
            )} - ${
              user?.placement?.endsAt
                ? getDateLocale(dayjs(user?.placement?.endsAt), {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  })
                : 'Now'
            }`}</span>
          </div>
        }
        <div ref={positionsRef} className="relative">
          {props.permission?.profilePositionSee && (
            <Button.Secondary
              customClass="mt-[8px] w-full"
              onClick={() => {
                getList(), setOpenPositions(!openPositions);
              }}
              datacy="button-subordinate"
            >
              {getObjectiveLocale('Show Other Positions')}
            </Button.Secondary>
          )}
          {openPositions && listPosition.length > 0 && (
            <div className="list-positions">
              <p className="typography-h200 text-n-600 px-[16px] pb-[8px]">
                {getObjectiveLocale('Show position details from')}
              </p>
              {listPosition?.map((data, index) => {
                return (
                  <div
                    className="cursor-pointer py-[12px] px-[16px] hover:bg-base-30016"
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenPositions(false);
                      history.replace(
                        `/users/${userId}/placement/${data?.placementId}`
                      );
                    }}
                  >
                    <p className="typography-h400 text-n-900">{data?.name}</p>
                    <TruncateParagraph className="typography-h100 text-n-900 mt-[4px]">
                      {data?.user?.info?.orgUnit}
                    </TruncateParagraph>
                    <p className="typography-h100 text-n-600 truncate">
                      {getDateLocale(data?.user?.placement?.startsAt, {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                      })}{' '}
                      -{' '}
                      {data?.user?.placement?.endsAt
                        ? getDateLocale(data?.user?.placement?.endsAt, {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                          })
                        : getObjectiveLocale('Now')}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {props.permission?.profileSubordinateEdit && (
          <Button.Secondary
            customClass="mt-[8px] w-full"
            onClick={props.setTeamMember}
            datacy="button-subordinate"
          >
            {getObjectiveLocale('Set Team Member')}
          </Button.Secondary>
        )}
        <div className="relative">
          {props.permission?.profileConfigSee && (
            <Button.Secondary
              customClass="mt-[8px] w-full"
              onClick={() => setOpenConfigurations(!openConfigurations)}
              datacy="button-subordinate"
            >
              {getObjectiveLocale('Configurations')}
            </Button.Secondary>
          )}
          {openConfigurations && (
            <ProfileConfigurations
              configurations={configurations}
              closeModal={setOpenConfigurations}
              changeConfig={changePositionField}
              resetData={resetData}
              saveData={saveConfigurations}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default UserProfileComponent;

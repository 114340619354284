import React, { useEffect, useRef, useState } from 'react';

import './FlexibleInputText.scss';

const FlexibleInputText = ({
  value,
  onChange,
  onBlur,
  startFocus,
  dataCy,
  customClass,
  handleKeyPress
}) => {
  const inputRef = useRef('');
  const [isFocused, setFocused] = useState(false);

  const onClickHandler = (e) => e.stopPropagation();

  const onInputHandler = (e) =>
    (e.target.parentNode.dataset.value = e.target.value);

  const onFocusHandler = (e) =>
    (e.target.parentNode.dataset.value = e.target.value);

  const onChangeHanlder = (e) => {
    onChange && onChange(e.target.value);
  };

  const onBlurHandler = (e) => {
    onBlur && onBlur();
  };

  const onKeyUp = (e) => handleKeyPress(e);

  useEffect(() => {
    if (inputRef && startFocus && !isFocused) {
      inputRef.current.focus();
      setFocused(true);
    }
  }, [inputRef]);

  return (
    <label
      className={`inline-textbox-container px-[2px] px-[2px] ${
        customClass ? customClass : ''
      }`}
    >
      <input
        onKeyUp={handleKeyPress && onKeyUp}
        ref={inputRef}
        type="text"
        onInput={onInputHandler}
        onFocus={onFocusHandler}
        className={'inline-textbox typography-h500 text-n-900'}
        size="1"
        onClick={onClickHandler}
        onChange={onChangeHanlder}
        value={value}
        onBlur={onBlurHandler}
        data-cy={dataCy}
      />
    </label>
  );
};

export default FlexibleInputText;

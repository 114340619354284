import client from './ApiClient';

function getListPreset() {
  const url = `v1/groups/objective_comparation_group`;

  return client(url);
}

function createPreset(body) {
  const url = `v1/groups/objective_comparation_group`;
  const method = { method: 'POST', body: body };

  return client(url, method);
}

function getObjectiveComparative(id) {
  const url = `v1/groups/objective_comparation_group/${id}`;

  return client(url);
}

function updateObjectiveComparative(id, body) {
  const url = `v1/groups/objective_comparation_group/${id}`;
  const method = { method: 'PUT', body: body };

  return client(url, method);
}

// ** Will Be Used in Next Release **
function getUsersSharingPreset(params, signal) {
  const url = `v1/groups/objective_comparation_group/placements`;

  return client(url, { params: params }, false, signal);
}
// ** Will Be Used in Next Release **
function shareUsersPreset(id, body, signal) {
  const url = `v1/groups/objective_comparation_group/${id}/share`;
  const method = { method: 'PUT', body: body };

  return client(url, method, false, signal);
}

function patchPreset(id, body) {
  const url = `v1/groups/objective_comparation_group/${id}`;
  const method = { method: 'PATCH', body: body };

  return client(url, method);
}

function deletePreset(id, body) {
  const url = `v1/groups/objective_comparation_group/${id}`;
  const method = { method: 'DELETE', body: body };

  return client(url, method);
}

export {
  getListPreset,
  createPreset,
  getObjectiveComparative,
  updateObjectiveComparative,
  getUsersSharingPreset,
  shareUsersPreset,
  patchPreset,
  deletePreset
};

import React from 'react';

import produce from 'immer';

import { getUsers } from 'client/admin/AnalyticClient';

//CREATE CONTEXT
const AnalyticSearchContext = React.createContext();

//PROVIDER
function AnalyticSearchProvider(props) {
  //INITIAL STATE
  const initialState = {
    users: [],
    limit: 12,
    hasMore: true,
    olderThan: '',
    newerThan: ''
  };

  const [state, setState] = React.useState(initialState);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];

  return <AnalyticSearchContext.Provider value={contextValue} {...props} />;
}

//MUTATION
function useAnalyticUsersAdmin() {
  const [{ limit, users, hasMore, olderThan, newerThan }, immerSetState] =
    React.useContext(AnalyticSearchContext);

  const getUsersData = async (query) => {
    const _query = {
      limit: limit,
      ...query
    };

    const { data, pagination } = await getUsers(_query);

    if (data) {
      immerSetState((draft) => {
        draft.users = data;
        draft.olderThan = pagination.next.olderThan;
        draft.newerThan = pagination.prev.newerThan;
        draft.hasMore = data.length === limit;
      });
    }
  };

  const nextPage = async (query) => {
    const _query = {
      limit: limit,
      olderThan: olderThan,
      ...query
    };

    const { data, pagination } = await getUsers(_query);

    immerSetState((draft) => {
      draft.users = data;
      draft.olderThan = pagination.next.olderThan;
      draft.newerThan = pagination.prev.newerThan;
      draft.hasMore = data.length === limit;
    });
  };

  const prevPage = async (query) => {
    const _query = {
      limit: limit,
      newerThan: newerThan,
      ...query
    };

    const { data, pagination } = await getUsers(_query);
    immerSetState((draft) => {
      draft.users = data;
      draft.olderThan = pagination.next.olderThan;
      draft.newerThan = pagination.prev.newerThan;
      draft.hasMore = true;
    });
  };

  return {
    users,
    hasMore,
    getUsersData,
    nextPage,
    prevPage,
    limit
  };
}

export { AnalyticSearchProvider, useAnalyticUsersAdmin };

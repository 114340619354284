import React, { useState } from 'react';

import { useRefetchQuery } from 'context/RefetchQueryContext';
import useObjectiveDropdown from 'hooks/useDropdown';
import useTasks from 'hooks/useTasks';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown, {
  useDropdown
} from 'components/design-system/dropdown/Dropdown';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import SVGIcon from 'components/shared/SVGIcon';

const TaskDialog = ({
  task,
  useTrigger = true,
  isDialogOpen,
  setIsDialogOpen,
  handleAddChildren,
  level,
  useFloating,
  taskNode,
  groupName,
  sectionName
}) => {
  const { permissions, blocking, blockedBy } = task;

  const { refetchObjective, invalidateTaskQueries } = useRefetchQuery();
  const { open } = useDropdown();
  const [disableAddSubTask, setDisableAddSubTask] = useState(false);

  const { removeTaskChildrenFromParent } = useTasks();

  const { useMenu } = useObjectiveDropdown({
    objective: task,
    permissions: permissions,
    page: 'mytasks',
    groupName,
    sectionName
  });

  const listMenu = useMenu({ only: ['clone', 'delete'] });

  const onClickDropdown = (e, menu) => {
    e.stopPropagation();
    menu.onClick(menu, async () => {
      if (menu.type === 'delete') {
        [...blocking, ...blockedBy]?.map(({ id }) => refetchObjective(id)); // refetch blocking and blocked task to sync dependency between tasks
        level > 0 &&
          removeTaskChildrenFromParent(taskNode.getParent(), taskNode.value.id);

        invalidateTaskQueries({ task });
      }
    });
    setIsDialogOpen(!isDialogOpen);
  };

  const addSubTask = () => {
    setDisableAddSubTask(true);
    setIsDialogOpen(!isDialogOpen);
    handleAddChildren();

    setTimeout(() => {
      // need timeout to prevent multiple clicks add subtasks
      setDisableAddSubTask(false);
    }, 100);
  };

  return (
    <>
      {useTrigger && (
        <Dropdown.Trigger
          onClick={() => setIsDialogOpen(!isDialogOpen)}
          customClass="w-full h-full flex items-center justify-center"
          dataCy="task-dialog"
        >
          <SVGIcon
            size="24"
            iconName="icon-keyboard_control"
            fillColor="var(--n-600)"
            customClass={`task-dialog-dropdown ${open ? 'visible' : ''}`}
          />
        </Dropdown.Trigger>
      )}
      {open && useFloating ? (
        <FloatingComponent lockScroll={false}>
          <Dropdown.MenuItems
            position={useTrigger ? 'right' : 'left'}
            id={useTrigger ? '' : 'context-menu-dropdown'}
            type="absolute"
            setDialogOpen={() => setIsDialogOpen(!isDialogOpen)}
            dataCy="task-dialog-menu-items"
          >
            {level == 0 && (
              <Dropdown.MenuItem
                iconName="icon-add"
                onClick={() => addSubTask()}
                disabled={disableAddSubTask}
                dataCy="dropdown-add-subtask"
              >
                {getObjectiveLocale('Add Sub Task')}
              </Dropdown.MenuItem>
            )}
            {listMenu.map((menu, index) => (
              <Dropdown.MenuItem
                key={index}
                iconColor={menu?.colorHex || 'var(--n-600)'}
                iconName={menu?.icon}
                dataCy={menu.dataCy}
                onClick={(e) => onClickDropdown(e, menu)}
              >
                <p style={{ color: menu?.colorHex || '' }}>{menu?.title}</p>
              </Dropdown.MenuItem>
            ))}
          </Dropdown.MenuItems>
        </FloatingComponent>
      ) : (
        <Dropdown.MenuItems
          position={useTrigger ? 'right' : 'left'}
          id={useTrigger ? '' : 'context-menu-dropdown'}
          type="absolute"
          setDialogOpen={() => setIsDialogOpen(!isDialogOpen)}
        >
          {level == 0 && (
            <Dropdown.MenuItem
              iconName="icon-add"
              onClick={() => {
                handleAddChildren();
                setIsDialogOpen(!isDialogOpen);
              }}
            >
              {getObjectiveLocale('Add Sub Task')}
            </Dropdown.MenuItem>
          )}
          {listMenu.map((menu, index) => (
            <Dropdown.MenuItem
              key={index}
              iconColor={menu?.colorHex || 'var(--n-600)'}
              iconName={menu?.icon}
              onClick={(e) => onClickDropdown(e, menu)}
            >
              <p style={{ color: menu?.colorHex || '' }}>{menu?.title}</p>
            </Dropdown.MenuItem>
          ))}
        </Dropdown.MenuItems>
      )}
    </>
  );
};

const TaskDialogComponent = (props) => {
  return (
    <>
      <div className="absolute left-[-1px] h-full w-[1px] bg-n-000 z-[21]" />
      <div className="w-full h-full flex justify-center items-center">
        <Dropdown autoOpen={!props.useTrigger} customClass="w-full h-full">
          <TaskDialog {...props} />
        </Dropdown>
      </div>
    </>
  );
};

export default TaskDialogComponent;

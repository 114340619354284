import React from 'react';

import { checkBehaviorModelGroup } from 'client/admin/UserManagementClient';
import { useUser } from 'context/UserContext';
import useUserManagement from 'hooks/useUserManagement';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';

import TimeField from './TimeField';

const PositionBackground = ({
  detailPosition,
  changePositionField,
  changePositionDate,
  onGetNewGroupName,
  isReadOnly
}) => {
  const { config } = useUser();
  const { setIsEditingBackground, setIsFetchingNewBehaviorModelGroup } =
    useUserManagement();

  const _checkBehaviorModelGroup = async () => {
    setIsFetchingNewBehaviorModelGroup(true);
    setIsEditingBackground(false);
    const params = {
      info: detailPosition
    };
    const { data } = await checkBehaviorModelGroup(params);
    if (data) {
      onGetNewGroupName(data);
    }
    setIsFetchingNewBehaviorModelGroup(false);
  };

  return (
    <div className="border-solid border border-n-400 rounded-[4px] p-16 pb-[24px] flex flex-col mt-[16px]">
      <div className="typography-h400">
        {getObjectiveLocale('Position Background')}
      </div>
      <div className="typography-paragraph text-n-800">
        {getObjectiveLocale(
          'Showing background informations of this position.'
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementInfo?.jobRole && (
          <InputField
            label="Job Role"
            placeholder="Job Role"
            handleChange={(e) => changePositionField(e, 'jobRole', 'info')}
            value={detailPosition?.jobRole}
            dataCy="user-info-role"
            className="w-[344px]  mr-[16px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementInfo?.jobRole === 'disabled'
            }
            onFocus={() => setIsEditingBackground(true)}
            onBlur={() => _checkBehaviorModelGroup()}
          />
        )}
        {config?.editablePlacementInfo?.jobFunction && (
          <InputField
            label="Function"
            placeholder="Function"
            handleChange={(e) => changePositionField(e, 'jobFunction', 'info')}
            value={detailPosition?.jobFunction}
            dataCy="user-pos-job-function"
            className="w-[344px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementInfo?.jobFunction === 'disabled'
            }
            onFocus={() => setIsEditingBackground(true)}
            onBlur={() => _checkBehaviorModelGroup()}
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementInfo?.directorate && (
          <InputField
            label="Directorate"
            placeholder="Directorate"
            handleChange={(e) => changePositionField(e, 'directorate', 'info')}
            value={detailPosition?.directorate}
            dataCy="user-pos-directorate"
            className="w-[344px] mr-[16px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementInfo?.directorate === 'disabled'
            }
            onFocus={() => setIsEditingBackground(true)}
            onBlur={() => _checkBehaviorModelGroup()}
          />
        )}
        {config?.editablePlacementInfo?.department && (
          <InputField
            label="Department"
            placeholder="Department"
            handleChange={(e) => changePositionField(e, 'department', 'info')}
            value={detailPosition?.department}
            dataCy="user-pos-department"
            className="w-[344px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementInfo?.department === 'disabled'
            }
            onFocus={() => setIsEditingBackground(true)}
            onBlur={() => _checkBehaviorModelGroup()}
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementInfo?.division && (
          <InputField
            label="Division"
            placeholder="Division"
            handleChange={(e) => changePositionField(e, 'division', 'info')}
            value={detailPosition?.division}
            dataCy="user-pos-division"
            className="w-[344px] mr-[16px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementInfo?.division === 'disabled'
            }
            onFocus={() => setIsEditingBackground(true)}
            onBlur={() => _checkBehaviorModelGroup()}
          />
        )}
        {config?.editablePlacementInfo?.salaryLevel && (
          <InputField
            label="Salary Level"
            placeholder="Salary Level"
            handleChange={(e) => changePositionField(e, 'salaryLevel', 'info')}
            value={detailPosition?.salaryLevel}
            dataCy="user-pos-salaryLevel"
            className="w-[344px] mt-[24px]"
            readonly={
              isReadOnly ||
              config?.editablePlacementInfo?.salaryLevel === 'disabled'
            }
          />
        )}
      </div>
      <div className="flex">
        {config?.editablePlacementInfo?.joinDate && (
          <TimeField
            selectedDate={detailPosition?.joinDate}
            onSelectDate={(date) =>
              changePositionDate(date, 'joinDate', 'info')
            }
            label={getObjectiveLocale('Starting Date')}
            customClass="w-[344px] mr-[16px]"
            isSingle
            readonly={
              isReadOnly ||
              config?.editablePlacementInfo?.joinDate === 'disabled'
            }
          />
        )}
        {config?.editablePlacementInfo?.permanentJoinDate && (
          <TimeField
            selectedDate={detailPosition?.permanentJoinDate}
            onSelectDate={(date) =>
              changePositionDate(date, 'permanentJoinDate', 'info')
            }
            label={getObjectiveLocale('Permanent Employment Starting Date')}
            customClass="w-[344px]"
            isSingle
            readonly={
              isReadOnly ||
              config?.editablePlacementInfo?.permanentJoinDate === 'disabled'
            }
          />
        )}
      </div>
    </div>
  );
};

export default PositionBackground;

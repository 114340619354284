import React, { useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import DropdownVisibility from 'components/dropdown/DropdownVisibility';
import MenuOutsideClickWrapper from 'components/objectives/compact-objective/fragments/MenuOutsideClickWrapper';
import SVGIcon from 'components/shared/SVGIcon';

const Privacy = ({ objectiveDetail, updateObjective }) => {
  const {
    permissions = [],
    isPrivate,
    type = 'goal',
    isProject
  } = objectiveDetail || {};

  const [showDropdown, setShowDropdown] = useState(false);

  const getPrivacyText = () => {
    if (isPrivate) {
      return getObjectiveLocale('Private').split(' ').pop();
    }

    return getObjectiveLocale('Public').split(' ').pop();
  };

  return (
    <div className="height-40 bg-n-200 flex items-center px-[16px] py-[8px] mx-[8px] mt-[8px] rounded">
      <span className="typography-paragraph normal-case mr-[8px]">
        {`This ${getObjectiveLocale(
          type == 'task' ? 'task' : isProject ? 'project' : 'goal'
        )} is`}
      </span>
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setShowDropdown(true)}
        data-cy="trigger-project-dropdown-visibility"
      >
        <SVGIcon
          iconName={isPrivate ? 'icon-private' : 'icon-remove_red_eye'}
          size="16"
          fillColor={
            permissions?.includes('edit_privacy')
              ? 'var(--base-600)'
              : 'var(--n-600)'
          }
          customClass="mr-[4px]"
        />
        <p className="typography-paragraph text-base-600">{getPrivacyText()}</p>
      </div>

      {showDropdown && (
        <MenuOutsideClickWrapper event={() => setShowDropdown(false)}>
          <DropdownVisibility
            handleChange={(e) =>
              updateObjective({
                isPrivate: e === 'Private'
              })
            }
            type={type}
            useTrigger={false}
            isProject={objectiveDetail.isProject}
          />
        </MenuOutsideClickWrapper>
      )}
    </div>
  );
};

export default Privacy;

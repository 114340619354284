import React from 'react';

import { useFormalReview } from 'context/FormalReviewContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import BannerBox from 'components/shared/BannerBox';

const BannerPrefillAnswers = ({
  currentTrackId,
  currentTrack,
  isStickyBanner
}) => {
  const [
    { phase },
    { renewPrefillAnswers, getSingleTrack, renewAspectPrefillAnswer }
  ] = useFormalReview();

  const singleTrack = getSingleTrack(currentTrackId);
  const { trackConfig, savedPreFill } = singleTrack;

  const { autoFill, useSuggestion, suggestionSource } = trackConfig || {};

  const isSuggestionAndPrefill = autoFill && useSuggestion;

  const getBannerText = () => {
    if (suggestionSource === 'previous_cycle') {
      return 'You can see results for this review from the previous cycle. If you want to fill all the answers based on that, click Fill my Answers';
    }

    if (isSuggestionAndPrefill || savedPreFill) {
      return `This assessment is automatically filled in based on the ${
        phase.type === 'manager_review' ? 'self review' : 'manager review'
      } results of your subordinate. You can change any answer or comment if needed or click Renew Pre-filled Answers to reset it all`;
    }

    return `You can see the ${
      phase.type === 'manager_review' ? 'self review' : 'manager review'
    } results of your subordinate. If you want to fill all the answers based on that, click Fill my Answers`;
  };

  const getButtonText = () => {
    if (
      suggestionSource === 'previous_cycle'
        ? false
        : isSuggestionAndPrefill || savedPreFill
    ) {
      return 'Renew Pre-filled Answers';
    }

    return 'Fill my answers';
  };

  return (
    <div
      className={`mb-[24px] ${
        isStickyBanner
          ? 'sticky top-[0px] z-[11] py-[16px] px-[40px] mx-[-40px] bg-n-000 border-0 border-b border-solid border-n-400 mb-[24px]'
          : ''
      }`}
      data-cy="banner-prefill-answers"
    >
      <BannerBox
        type="info"
        alignItemsClass="items-start"
        marginClass="m-[0px]"
      >
        <div className="flex items-center justify-between">
          <p
            className="typography-paragraph mr-[16px] w-[80%]"
            data-cy="banner-prefill-answers-content"
          >
            {getObjectiveLocale(getBannerText())}
          </p>
          <p
            className="typography-link cursor-pointer w-[20%] text-center"
            onClick={() =>
              currentTrack == 'review_aspects_scoring'
                ? renewAspectPrefillAnswer(singleTrack)
                : renewPrefillAnswers(singleTrack)
            }
            data-cy="banner-prefill-answers-button"
          >
            {getObjectiveLocale(getButtonText())}
          </p>
        </div>
      </BannerBox>
    </div>
  );
};

export default BannerPrefillAnswers;

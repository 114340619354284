import React, { useEffect, useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';
import useDebounce from 'src/hooks/useDebounce';

function Search({ onChange, onKeyDown, defaultValue = '' }) {
  const [search, setSearch] = useState(defaultValue);
  const debouncedSearchValue = useDebounce(search, 500);
  const onEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onKeyDown(search);
    }
  };

  useEffect(() => {
    setSearch(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    onChange(search);
  }, [debouncedSearchValue]);

  return (
    <InputField
      inputClass="pl-[32px]"
      search
      id="search-label"
      placeholder={getObjectiveLocale('Search')}
      handleChange={(e) => setSearch(e.target.value)}
      value={search ? search : ''}
      dataCy="advance-filter-search"
      onKeyDown={onKeyDown && onEnter}
      positionSearch="absolute left-8 mt-[8px]"
    />
  );
}

export default Search;

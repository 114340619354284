import React, { useState } from 'react';

import { useGoalWeightSettingsContext } from 'context/GoalWeightSettingContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import Toggle from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import WeightSettingsInputNumber from '../WeightSettingsInputNumber';

const CategoryInputWeight = ({
  defaultValue,
  weightLocked,
  id,
  changeDataObject
}) => {
  const { isReadOnly } = useGoalWeightSettingsContext();

  const [weightValue, setWeightValue] = useState(defaultValue || 0);
  const [isLocked, setLock] = useState(weightLocked);

  const onChangeHandler = (value) => {
    let payload = {
      weight: isNaN(value) ? 0 : value
    };
    if (isNaN(value) || value == 0) {
      payload.isLocked = false;
      setLock(false);
    }
    setWeightValue(value);
    if (changeDataObject) {
      changeDataObject(payload);
    }
    return true;
  };

  const toggleWeight = () => {
    if (weightValue == 0) {
      return onChangeHandler(1);
    }
    return onChangeHandler(0);
  };

  const isChecked = weightValue != 0 && weightValue != '';

  const toggleLock = () => {
    const payload = {
      isLocked: !isLocked
    };
    if (changeDataObject) {
      changeDataObject(payload);
    }
    setLock((value) => !value);
  };

  return (
    <div className="flex flex-row items-center">
      <Toggle
        isChecked={isChecked}
        idStr={'toggle-weight-' + id}
        onChange={toggleWeight}
        addClass="ml-[-12px]"
        disabled={isReadOnly}
        dataCy={`toggle-weight-${id}`}
      />
      {isChecked && (
        <WeightSettingsInputNumber
          defaultValue={weightValue}
          onChangeCallback={onChangeHandler}
          className={'w-[80px]'}
          useDebounce={false}
          min={1}
          dataCy={`type-weight-input-${id}`}
        >
          <TooltipContainer
            show
            position="top"
            text={getObjectiveLocale(
              `Weight is ${
                isLocked
                  ? "locked, user can't modify"
                  : 'unlocked, user can modify'
              } the weight when creating or editing objective with this type`
            )}
            useMaxWidth={false}
            classContainer="absolute right-[6px] top-1/2 -translate-y-2/4"
            textChangeable
          >
            <SVGIcon
              size="24"
              fillColor="var(--n-600)"
              iconName={`${isLocked ? 'icon-lock' : 'icon-lock_open'} `}
              onClick={toggleLock}
              disabled={isReadOnly}
              dataCy={`type-lock-weight-toggle-${id}`}
            />
          </TooltipContainer>
        </WeightSettingsInputNumber>
      )}
    </div>
  );
};

export default CategoryInputWeight;

import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import 'scss/culture/modal/modal-post-survey';

import { submitPost } from 'client/CultureClient';
import useClickOutside from 'hooks/useClickOutside';
import { getCultureLocale, getDateLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import LabelSection from 'components/modal/culture/components/LabelSection';
import SingleCalendar from 'components/shared/Calendar';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import { trackEvent } from 'src/utils/AnalyticUtils';

import ModalConfirmationDialogue from './ModalConfirmationDialogue';
import ModalPostTemplate from './ModalPostTemplate';
import ModalTaggedUser from './ModalTaggedUser';

function ModalPostSurvey({ eventOnClick, groupDetail }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isResctricted, setIsRestricted] = useState(false);
  const [isAdminPost, setIsAdminPost] = useState(false);
  const [isModalParticipantOpen, setIsModalParticipantOpen] = useState(false);
  const [isButtonDeleteDisabled, setIsButtonDeleteDisabled] = useState(true);
  const [isButtonAddDisabled, setIsButtonAddDisabled] = useState(false);
  const [isCalendarShow, setIsCalendarShow] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [question, setQuestion] = useState('');
  const [pollOptions, setPollOptions] = useState([
    { option: '', enable: true },
    { option: '', enable: true }
  ]);
  const [surveyDeadline, setSurveyDeadline] = useState(dayjs().add(1, 'days'));
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const ref = useRef();
  useClickOutside(ref, () => setIsCalendarShow(false));

  const validatePostSurvey = (fromConfirmModal) => {
    if (fromConfirmModal) return false;

    if (
      selectedLabels.some(
        (label) => label.title.indexOf('Official') >= 0 && !isAdminPost
      )
    ) {
      setShowModalConfirmation(true);
      return true;
    }
  };

  const postSurvey = async (fromConfirmModal = false) => {
    if (validatePostSurvey(fromConfirmModal)) return;

    setIsButtonDisabled(true);
    setIsSubmit(true);
    const requestBody = {
      post: {
        postType: 'poll',
        groupId: groupDetail.id,
        adminPost: isAdminPost,
        participation: isResctricted
          ? participants.map((participant) => participant.id)
          : [],
        labelIds: selectedLabels.map((label) => label.id),
        pollAttributes: {
          question: question,
          expiration:
            dayjs(surveyDeadline).format('YYYY-MM-DD') + 'T23:59:00+07:00',
          pollOptionsAttributes: pollOptions
        }
      }
    };

    const { isSuccess } = await submitPost(requestBody);

    if (isSuccess) {
      eventOnClick(true);
      const properties = {
        'post as admin': isAdminPost,
        restricted: isResctricted,
        'add more option': pollOptions.length > 2,
        'group name': groupDetail.name,
        'group type': groupDetail.groupType,
        'post from inside group': true,
        'category type': selectedLabels.map((label) => label.title)
      };

      trackEvent({
        event: 'create poll',
        eventProperties: properties,
        env: 'culture'
      });
    }
  };

  const closeModal = () => {
    setIsModalParticipantOpen(false);
  };

  const changeOption = (e, index) => {
    const currentOptions = JSON.parse(JSON.stringify(pollOptions));

    currentOptions[index].option = e.target.value;
    setPollOptions(currentOptions);
  };

  const deleteOption = (index) => {
    if (pollOptions.length > 2) {
      const currentOptions = JSON.parse(JSON.stringify(pollOptions));

      currentOptions.splice(index, 1);
      setPollOptions(currentOptions);

      if (currentOptions.length === 2 || currentOptions.length < 2) {
        setIsButtonDeleteDisabled(true);
      }
    }
  };

  const addOption = () => {
    if (pollOptions.length < 5) {
      const currentOptions = JSON.parse(JSON.stringify(pollOptions));

      currentOptions.push({ option: '', enable: true });
      setPollOptions(currentOptions);

      if (currentOptions.length === 5) {
        setIsButtonAddDisabled(true);
      }

      if (currentOptions.length > 2) {
        setIsButtonDeleteDisabled(false);
      }
    }
  };

  const handleCalendar = (value) => {
    setSurveyDeadline(value);
    setIsCalendarShow(false);
  };

  const disabledDate = (current) => {
    if (!current) {
      return false;
    }
    const date = dayjs().hour(0).minute(0).second(0);
    return current.valueOf() < date.valueOf();
  };

  useEffect(() => {
    question && pollOptions.every((option) => option.option !== '')
      ? setIsButtonDisabled(false)
      : setIsButtonDisabled(true);
  }, [question, pollOptions]);

  return (
    <>
      {isModalParticipantOpen && (
        <ModalTaggedUser
          isRecognition={false}
          closeModal={closeModal}
          setIsModalTaggedUserOpen={setIsModalParticipantOpen}
          groupId={groupDetail.id}
          taggedUsers={participants}
          setTaggedUsers={setParticipants}
        />
      )}
      <ModalPostTemplate
        dataCy="modal-post-survey"
        modalTitle={`${getCultureLocale('Create Polling').toUpperCase()}`}
        isButton
        buttonText="Post"
        isButtonDisabled={isButtonDisabled}
        onClickButton={postSurvey}
        eventOnClick={eventOnClick}
        className="modal-post-survey"
        dataCyButton="post-survey-button"
        isSubmit={isSubmit}
      >
        <div className="content">
          <div className="restricted list-bottom">
            <span className="typography-h100 title">
              {getCultureLocale('Restricted')}
            </span>
            <SmallToggleSwitchPurple
              isChecked={isResctricted}
              onChange={(e) => {
                e.stopPropagation();
                setIsRestricted(!isResctricted);
              }}
              idStr="resctriction"
            />
          </div>
          {isResctricted && (
            <div
              className="participant list-bottom"
              data-cy="restriction-section"
            >
              <div className="typography-h100 title">
                {getCultureLocale('Participants')}
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalParticipantOpen(true);
                }}
                data-cy="choose-participation"
              >
                {participants.length === 0 && (
                  <div className="participant-item">
                    {getCultureLocale('All Members')}
                  </div>
                )}
                {participants.map((participant) => {
                  return (
                    <span
                      className="typography-h200 participant-item"
                      key={participant.id}
                      data-cy={`participant-${participant.id}`}
                    >{` ${participant.fullName}`}</span>
                  );
                })}
              </div>
            </div>
          )}
          <div
            className="deadline list-bottom relative"
            data-cy="survey-deadline"
          >
            <div className="typography-h100 title">
              {getCultureLocale('Polling Deadline')}
            </div>
            <SingleCalendar
              disabledDate={disabledDate}
              defaultValue={dayjs(surveyDeadline)}
              handleChangeCalendar={handleCalendar}
              type="endDate"
              customBox="h-[32px] w-[475px]"
              customDropdown="w-[475px]"
              suffixIcon={true}
              calendarButtonType="no"
            />
          </div>
          <div className="labels">
            <div className="typography-h100 title">
              {getCultureLocale('Label')}
            </div>
            <LabelSection
              setSelectedLabels={setSelectedLabels}
              isAdminPost={isAdminPost}
            />
          </div>
          <div className="ask-question">
            <input
              className="question"
              data-cy="survey-question"
              placeholder={getCultureLocale('Ask a question')}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            {pollOptions.map((option, index) => {
              return (
                <div key={index}>
                  <input
                    className="option-item"
                    data-cy={`option-item-${index}`}
                    placeholder={getCultureLocale('Option')}
                    value={option.option}
                    onChange={(e) => changeOption(e, index)}
                  />
                  {isButtonDeleteDisabled ? (
                    <SVGIcon
                      size="20"
                      iconName="icon-cancel"
                      fillColor="var(--n-600)"
                    />
                  ) : (
                    <SVGIcon
                      size="20"
                      iconName="icon-cancel"
                      fillColor="var(--base-600)"
                      onClick={(e) => deleteOption(index)}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <Button
            customClass="w-full flex-center-center"
            onClick={addOption}
            disabled={isButtonAddDisabled}
            datacy="add-more-option"
          >
            {getCultureLocale('Add more option +')}
          </Button>
          {groupDetail.allowPostAsAdmin && (
            <Checkbox
              id="post-as-admin-survey"
              customContainerClass="checkbox mt-[15px]"
              value={isAdminPost}
              checked={isAdminPost}
              onChange={(e) => {
                e.stopPropagation();
                setIsAdminPost(!isAdminPost);
              }}
              dataCy="post-as-admin"
            >
              <p className="ml-[4px]">{getCultureLocale('Post as')} Admin</p>
            </Checkbox>
          )}
        </div>
      </ModalPostTemplate>
      {showModalConfirmation && (
        <ModalConfirmationDialogue
          isAdminPost={isAdminPost}
          isSubmit={isSubmit}
          setShowModalConfirmation={setShowModalConfirmation}
          sharePost={postSurvey}
        />
      )}
    </>
  );
}

export default ModalPostSurvey;

import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';

import { getParentSuggestions } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';
import { getOwners } from 'utils/ObjectivesHelper';

import Avatar from 'components/design-system/Avatar';
import InlineDialog from 'components/design-system/inline-dialog/InlineDialog';
import MenuOutsideClickWrapper from 'components/objectives/compact-objective/fragments/MenuOutsideClickWrapper';
import SVGIcon from 'components/shared/SVGIcon';
import SearchBar from 'components/shared/SearchBar';

const AddAlignment = ({ objectiveValue, setObjectiveValue, teamId }) => {
  const selectedParent = objectiveValue?.parent;

  const setSelectedParent = (objective) => {
    setObjectiveValue((draft) => {
      draft.parentId = objective.id;
      draft.parent = objective;
    });
  };

  const deleteSelectedParent = () => {
    setObjectiveValue((draft) => {
      draft.parentId = null;
      draft.parent = null;
    });
  };

  return (
    <>
      <div className="mb-[8px] typography-h100 text-n-800">
        {getObjectiveLocale('Search goal')}
      </div>
      <AlignParent
        selectedData={selectedParent}
        setSelectedData={setSelectedParent}
        objectiveValue={objectiveValue}
        teamId={teamId}
      />

      {selectedParent && (
        <>
          <div className="flex items-center justify-between h-[36px] mt-[16px]">
            <p className="typography-h300 text-n-600 uppercase">
              {getObjectiveLocale('Selected goals')}
            </p>
            <div className="flex items-center">
              <p className="typography-h300 text-n-600 uppercase w-[68px]">
                {getObjectiveLocale('Due date')}
              </p>
              <div className="w-[60px]" />
            </div>
          </div>

          <SelectedObjective
            selectedData={selectedParent}
            onClickDelete={() => deleteSelectedParent()}
          />
        </>
      )}
    </>
  );
};

const SelectedObjective = ({ selectedData, onClickDelete }) => {
  const { goalOwnersProfilePic, goalOwnersName, goalOwnersRoles } = getOwners(
    selectedData.involvements,
    selectedData.isProject
  );

  return (
    <div className="h-[56px] flex items-center justify-between">
      <div className="flex items-center">
        <Avatar name={goalOwnersName} src={goalOwnersProfilePic} size={32} />
        <div className="ml-[16px] h-[32px] flex flex-col">
          <p className="typography-h400 text-n-900">{selectedData?.name}</p>
          <span className="typography-h100 text-n-800">
            {goalOwnersName} · {goalOwnersRoles}
          </span>
        </div>
      </div>

      <div className="flex items-center">
        <p className="typography-paragraph text-n-800 w-[68px]">
          {dayjs(selectedData?.dueDate).format('DD MMM YY')}
        </p>
        <div className="w-[60px] flex justify-end">
          <SVGIcon
            iconName="icon-delete"
            size="24"
            fillColor="var(--n-600)"
            onClick={onClickDelete}
            customClass="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

const AlignParent = ({
  selectedData,
  setSelectedData,
  objectiveValue,
  teamId
}) => {
  const { config } = useUser();
  const {
    startDate: periodBegin,
    dueDate: periodEndBefore,
    involvements
  } = objectiveValue;

  const [dataOption, setDataOption] = useState([]);
  const [search, setSearch] = useState('');

  const [useTrigger, setUseTrigger] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(null);
  const [onLoadMore, setLoadMore] = useState(false);

  const firstRender = useRef(true);

  const debouncedSearchValue = useDebounce(search, 500);

  const dateFormatter = (date) => dayjs(date).format('MMM, DD YYYY');

  let arrayAssignee =
    involvements
      ?.filter((member) => member.role == 'assignee')
      ?.map(({ user }) => user.id) || [];
  let arrayAssigner =
    involvements
      ?.filter((member) => member.role == 'assigner')
      ?.map(({ user }) => user.id) || [];

  let assigneeId = {
    assignee_id: arrayAssignee,
    assigner_id: arrayAssigner
  };

  const primaryParams = {
    ...assigneeId,
    periodBegin: periodBegin,
    periodEndBefore: periodEndBefore,
    state: ['running', 'completed', 'reviewed', 'edited']
  };

  const _getParentSuggestionData = async (offset = null, isSearch) => {
    setIsLoading(true);
    let params = {
      ...primaryParams,
      q: search,
      offset: offset
    };

    params.periodBegin &&
      (params.periodBegin = dayjs(params.periodBegin).toISOString());
    params.periodEndBefore &&
      (params.periodEndBefore = dayjs(params.periodEndBefore).toISOString());
    teamId && (params.goals = 'team');
    config?.alignAnyGoal && teamId && (params.teamId = teamId);

    const { data, pagination } = await getParentSuggestions(params);

    if (data) {
      const reservedData = isSearch ? [...data] : [...dataOption, ...data];
      setDataOption(reservedData);
      setOffset(data?.length >= 20 ? pagination.nextOffset : null);
      setHasMore(data?.length >= 20);
    } else {
      setOffset(null);
      setHasMore(false);
    }
    setIsLoading(false);
  };

  const optionFormatter = (data) => {
    const { goalOwnersProfilePic, goalOwnersName } = getOwners(
      data.involvements,
      data.isProject
    );
    return {
      id: data?.id,
      image: goalOwnersProfilePic,
      avatarName: goalOwnersName,
      text: data?.name,
      subText: `${dateFormatter(data?.startDate)} - ${dateFormatter(
        data?.dueDate
      )}`,
      selected: selectedData?.id == data?.id,
      onClick: () => selectParent(data),
      dataCy: 'parent-alignment-item'
    };
  };

  const options = [
    selectedData && optionFormatter(selectedData),
    ...(dataOption
      ?.map((obj) => optionFormatter(obj))
      .filter((option) => option?.id !== selectedData?.id) || [])
  ].filter((e) => e);

  const selectParent = (obj) => {
    const { selected } = options?.find((e) => e.id == obj.id) || {};
    if (!selected) {
      setSelectedData(obj);
    }
    setUseTrigger(true);
  };

  const onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      setLoadMore(true);
      await _getParentSuggestionData(offset);
      setLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 500, () => {
        if (offset) {
          loadMore();
        }
      });
    }
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      _getParentSuggestionData(null, true);
      setOffset(null);
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (useTrigger) {
      setDataOption([]);
    }
  }, [useTrigger]);

  return (
    <MenuOutsideClickWrapper event={() => setUseTrigger(true)}>
      <InlineDialog customClass="w-full">
        <InlineDialog.Trigger>
          <SearchBar
            placeholder="Ex: New Product Launch..."
            onChange={(e) => {
              setSearch(e.currentTarget.value);
            }}
            onFocus={() => {
              setUseTrigger(false);
              setDataOption([]),
                setIsLoading(true),
                setOffset(null),
                _getParentSuggestionData();
            }}
            fullWidth
            iconFillColor="var(--n-600)"
            dataCy="trigger-project-alignment"
          />
        </InlineDialog.Trigger>
        {!useTrigger && (
          <InlineDialog.MenuItems
            options={options}
            useTrigger={useTrigger}
            isLoading={isLoading}
            customClass="w-full"
            onScroll={onScroll}
          />
        )}
      </InlineDialog>
    </MenuOutsideClickWrapper>
  );
};

export default AddAlignment;

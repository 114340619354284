import React, { useState } from 'react';

import dayjs from 'dayjs';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import AntdCalendar from 'components/shared/Calendar/AntdCalendar';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';

const SetupProjectName = ({ objectiveValue, setObjectiveValue }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const dateText = `${dayjs(objectiveValue.startDate).format(
    'DD MMMM YYYY'
  )} - ${dayjs(objectiveValue.dueDate).format('DD MMMM YYYY')}`;
  const clickDate = (e) => {
    e.stopPropagation();
    setIsCalendarOpen(!isCalendarOpen);
  };
  const handleChange = (e) => {
    setObjectiveValue((draft) => {
      draft.startDate = e[0];
      draft.dueDate = e[1];
    });
    setIsCalendarOpen(false);
  };
  return (
    <>
      <InputField
        label="Project Name"
        placeholder="Type your project name here"
        value={objectiveValue?.name}
        handleChange={(e) => {
          e.persist();
          setObjectiveValue((draft) => {
            draft.name = e.target.value;
          });
        }}
        dataCy="input-project-name"
      />

      <div className="my-[16px]">
        <div className="mb-[8px] typography-h100 text-n-800">
          {getObjectiveLocale('Description')}
        </div>
        <TextArea
          textPlaceholder={getObjectiveLocale('Describe your project here')}
          onHandleChange={(e) => {
            e.persist();
            setObjectiveValue((draft) => {
              draft.description = e.target.value;
            });
          }}
          value={objectiveValue?.description}
          height={56}
          dataCy="input-project-description"
        />
      </div>

      <div className="flex items-center">
        <div className="mr-[16px]">
          <p className="typography-h100 text-n-800 mb-[8px]">
            {getObjectiveLocale('Privacy')}
          </p>
          <Dropdown>
            <Dropdown.Trigger dataCy="trigger-project-visibility-dropdown">
              <Trigger
                icon={false}
                topLabel={false}
                label={false}
                iconName="icon-arrow_drop_down"
                text={getObjectiveLocale(
                  objectiveValue.isPrivate ? 'Private' : 'Public'
                )}
                customWrapperClass="w-[200px]"
              />
            </Dropdown.Trigger>
            <Dropdown.MenuItems customClass="w-full">
              <Dropdown.MenuItem
                onClick={() =>
                  setObjectiveValue((draft) => {
                    draft.isPrivate = false;
                  })
                }
                dataCy="dropdown-visibility-option-0"
              >
                {getObjectiveLocale('Public')}
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                onClick={() =>
                  setObjectiveValue((draft) => {
                    draft.isPrivate = true;
                  })
                }
                dataCy="dropdown-visibility-option-1"
              >
                {getObjectiveLocale('Private')}
              </Dropdown.MenuItem>
            </Dropdown.MenuItems>
          </Dropdown>
        </div>
        <div className="w-full relative" onClick={clickDate}>
          <p className="typography-h100 text-n-800 mb-[8px]">
            {getObjectiveLocale('Start & due date')}
          </p>
          <div className="border-solid border-n-500 border bg-n-000 h-[32px] rounded-[4px] flex pl-[16px] pr-[8px] items-center justify-between cursor-pointer">
            <p
              data-cy="trigger-project-calendar"
              className="truncate text-n-900"
            >
              {dateText}
            </p>
            {isCalendarOpen && (
              <AntdCalendar>
                <AntdCalendar.Content
                  position="middle"
                  dateValue={[objectiveValue.startDate, objectiveValue.dueDate]}
                  setDateValueHandler={handleChange}
                  isAlwaysOpen
                  clickOutsideCallback={() => setIsCalendarOpen(false)}
                />
              </AntdCalendar>
            )}
            <SVGIcon
              size="24"
              iconName="icon-calendar_today"
              fillColor="var(--base-600)"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupProjectName;

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import startCase from 'lodash/startCase';

import { getMechanism } from 'client/TrackTemplateClient';
import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils';

import { getMechanismDescription } from 'components/admin/track-template/ConfigurationSettingTrackTemplate.js';
import Button from 'components/design-system/Button';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

import ContentTrackTemplate from './ContentTrackTemplate';
import './SidebarTrackTemplate.scss';

const SidebarTrackTemplate = ({
  trackDetailData,
  setTrackDetailData,
  setShowSidebar,
  setShowDeactivateModal,
  sidebarLoading,
  activeTab,
  handleActivateTrack,
  isReadOnly
}) => {
  const history = useHistory();

  let { isEditable, trackType, name, id, config } = trackDetailData || {};

  const [mechanismData, setMechanismData] = useState([]);

  const mechanismDescription = getMechanismDescription(
    mechanismData,
    config?.mechanism
  );

  const sidebarRef = useRef();
  useClickOutside(sidebarRef, () => closeSidebar());

  const closeSidebar = () => {
    setShowSidebar(false);
    setTrackDetailData(null);
  };

  const handleDeactivate = () => {
    handleActivateTrack(id);
    closeSidebar();
    setShowDeactivateModal(id);
  };

  const handleActivate = () => {
    handleActivateTrack(id);
    closeSidebar();
  };

  const _getMechanismData = async (trackType) => {
    const { data } = await getMechanism({ trackType });
    if (data) {
      setMechanismData(data.trackMechanism);
    }
  };

  useEffect(() => {
    // get list mechanism
    if (trackType) {
      _getMechanismData(trackType);
    }
  }, [trackType]);

  return (
    <div className="sidebar-track-template-container" ref={sidebarRef}>
      {sidebarLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className="header-sidebar-track-template">
            <div className="header-left-sidebar">
              {!isEditable || isReadOnly ? (
                <div className="flex items-center">
                  <SVGIcon
                    iconName="icon-lock_outline"
                    size="24"
                    fillColor="var(--n-600)"
                  />
                  <p className="ml-[8px]">
                    {getObjectiveLocale('Not Editable')}
                  </p>
                </div>
              ) : (
                <div className="button-track-template-container">
                  <Button.Secondary
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(
                        `/appraisals/cycles/tracks/edit/${trackType}/${id}`
                      );
                    }}
                    datacy="edit-btn-track"
                    customClass="mr-[8px]"
                  >
                    <i className="material-icons edit-icon-sidebar-track-template">
                      edit
                    </i>
                    {getObjectiveLocale('Edit')}
                  </Button.Secondary>
                  {activeTab === 'active' ? (
                    <Button.Secondary onClick={() => handleDeactivate()}>
                      {getObjectiveLocale('Deactivate')}
                    </Button.Secondary>
                  ) : (
                    <Button.Secondary onClick={() => handleActivate()}>
                      {getObjectiveLocale('Activate')}
                    </Button.Secondary>
                  )}
                </div>
              )}
            </div>

            <SVGIcon
              iconName="icon-clear"
              size="24"
              fillColor="var(--n-600)"
              customClass="float-right cursor-pointer"
              onClick={() => closeSidebar()}
            />
          </div>
          <div className="content-sidebar-track-template">
            <p className="typography-paragraph mb-[4px] text-n-800">#{id}</p>
            <h6 className="typography-paragraph text-n-800">
              {startCase(trackType)}
            </h6>
            <h4 className="typography-h600">{name}</h4>
            {/* Currently in use : list track template */}
            <ContentTrackTemplate
              trackDetailData={trackDetailData}
              mechanismDescription={mechanismDescription}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SidebarTrackTemplate;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

const DropdownReviewCycle = ({
  phase,
  setEditMode,
  revieweeOrReviewerWording,
  lockAnswerCycle,
  phaseAssignments
}) => {
  const phaseType = phase.type;
  const isSelfReview = phaseType === 'self_review';
  const isPeerReview = phaseType === 'peer_review';
  const isPhaseDone = phase.state === 'done';
  const hasAssignments = phaseAssignments.length > 0;
  const checkAssignmentsState =
    phaseAssignments?.find((assignment) => assignment.state == 'done') || false;

  const options = [
    {
      text: getObjectiveLocale('Reopen'),
      isVisible:
        hasAssignments &&
        ((lockAnswerCycle && checkAssignmentsState) || isPhaseDone),
      onClick: () => setEditMode('reopen'),
      dataCy: 'reopen'
    },
    {
      text: getObjectiveLocale(`Add ${revieweeOrReviewerWording}s`),
      isVisible: !(isSelfReview && hasAssignments) && !isPeerReview,
      onClick: () => setEditMode('add'),
      dataCy: 'add-reviewee'
    },
    {
      text: getObjectiveLocale(`Reschedule ${revieweeOrReviewerWording}s`),
      isVisible: hasAssignments && !isPhaseDone,
      onClick: () => setEditMode('reschedule'),
      dataCy: 'reschedule'
    },
    {
      text: getObjectiveLocale('Override Review'),
      isVisible: hasAssignments && phaseType !== 'peer_selection',
      onClick: () => setEditMode('override'),
      dataCy: 'override-review'
    },
    {
      text: getObjectiveLocale(`Remove ${revieweeOrReviewerWording}s`),
      isVisible: hasAssignments && !isPeerReview,
      onClick: () => setEditMode('remove'),
      dataCy: 'remove-reviewee'
    },
    {
      text: getObjectiveLocale(`Edit Reviewers`),
      isVisible: isPeerReview,
      onClick: () => setEditMode('add'),
      dataCy: 'edit-reviewers'
    }
  ].filter(({ isVisible }) => isVisible);

  return (
    <Dropdown>
      <Dropdown.Trigger>
        <SVGIcon
          iconName="icon-keyboard_control"
          fillColor="var(--n-600)"
          size="24"
          customClass="cursor-pointer"
          dataCy={`action-button-${phaseType}`}
        />
      </Dropdown.Trigger>
      <Dropdown.MenuItems
        customContainerClass="min-w-[212px]"
        position="right"
        options={options}
      />
    </Dropdown>
  );
};

export default DropdownReviewCycle;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import produce, { setAutoFreeze } from 'immer';

import { getFormalReviewObjectivesCount } from 'client/FormalReviewClient';
import { getObjectivesCount } from 'client/ObjectivesClient';
import { getConfig } from 'client/admin/Config';
import { useUser } from 'context/UserContext';
import { formatTimezone } from 'utils/DateUtils';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import {
  _getRangeDateCurrentQuarter,
  _getRangeDateCurrentSemester
} from 'components/shared/card/OverallProgressCard/_overallProgressCardHelper';
import MinMaxValidationModal from 'components/shared/modal/MinMaxValidationModal';

function useValidationMinMax(weightValue, isChildren = false) {
  const initialValues = {
    showModalValidationCreateGoal: '',
    showModalValidationChangeGoalAlignment: '',
    showModalValidationDeleteGoal: '',
    totalObjectives: '',
    isShowWarning: false,
    warningMessage: ''
  };

  const [state, setState] = useState(initialValues);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const { user, config } = useUser();
  const query = new URLSearchParams(useLocation().search);

  const getParams = {
    assigneeId: user.id,
    parentNotAssignedTo: user.id,
    periodBegin: formatTimezone(`${new Date().getFullYear()}-01-01`, 'start'),
    periodEndBefore: formatTimezone(`${new Date().getFullYear()}-12-31`, 'end'),
    state: ['running', 'completed', 'reviewed', 'draft', 'edited'],
    type: ['goal', 'annual_goal']
  };

  const _handleGetConfigAdminObjectives = async () => {
    const { data } = await getConfig();
    return data.objectivesConfig;
  };

  const _handleGetCountObjectives = async (
    isSetWeight,
    maxPeriodObjectives
  ) => {
    let params;
    if (isSetWeight || maxPeriodObjectives === 'yearly') {
      params = getParams;
    } else if (maxPeriodObjectives === 'semester') {
      const semesterlyParam = _getRangeDateCurrentSemester();
      params = {
        ...getParams,
        semesterlyParam
      };
    } else {
      const quarterlyParam = _getRangeDateCurrentQuarter();
      params = {
        ...getParams,
        quarterlyParam
      };
    }
    const { data } = await getObjectivesCount(params);
    return data;
  };

  const getDataMinMax = async (isSetWeight = false) => {
    const {
      maxObjectivesOn,
      minObjectivesOn,
      maxNumberOfObjectives,
      minNumberOfObjectives,
      maxPeriodObjectives,
      maxObjectiveWeight,
      minObjectiveWeight,
      maxObjectiveWeightOn,
      minObjectiveWeightOn,
      maxSumOfObjectivesWeightPercentage,
      maxSumOfObjectivesWeightPercentageOn,
      maxSumOfObjectivesWeightType,
      maxSumOfObjectivesWeightTypeOn
    } = config.objectivesConfig;

    const {
      totalObjectives,
      totalWeights,
      overallProgress,
      progressColorHex,
      listStatus
    } =
      (await _handleGetCountObjectives(isSetWeight, maxPeriodObjectives)) || {};

    return {
      minNumberOfObjectives,
      maxNumberOfObjectives,
      minObjectivesOn,
      maxObjectivesOn,
      totalObjectives,
      totalWeights,
      maxObjectiveWeight,
      minObjectiveWeight,
      maxObjectiveWeightOn,
      minObjectiveWeightOn,
      maxSumOfObjectivesWeightPercentage,
      maxSumOfObjectivesWeightPercentageOn,
      maxSumOfObjectivesWeightType,
      maxSumOfObjectivesWeightTypeOn,
      overallProgress,
      progressColorHex,
      listStatus
    };
  };

  const getDataCoaching = async ({
    paramsCoaching,
    assignmentId,
    trackType,
    isReviewResult
  }) => {
    const {
      maxObjectivesOn,
      minObjectivesOn,
      maxNumberOfObjectives,
      minNumberOfObjectives,
      maxPeriodObjectives,
      maxObjectiveWeight,
      minObjectiveWeight,
      maxObjectiveWeightOn,
      minObjectiveWeightOn,
      maxSumOfObjectivesWeightPercentage,
      maxSumOfObjectivesWeightPercentageOn,
      maxSumOfObjectivesWeightType,
      maxSumOfObjectivesWeightTypeOn
    } = config.objectivesConfig;

    const {
      data: {
        totalObjectives,
        totalWeights,
        overallProgress,
        progressColorHex,
        listStatus
      }
    } = isReviewResult
      ? await getFormalReviewObjectivesCount(
          assignmentId,
          trackType,
          paramsCoaching
        )
      : await getObjectivesCount(paramsCoaching);

    return {
      minNumberOfObjectives,
      maxNumberOfObjectives,
      minObjectivesOn,
      maxObjectivesOn,
      totalObjectives,
      totalWeights,
      maxObjectiveWeight,
      minObjectiveWeight,
      maxObjectiveWeightOn,
      minObjectiveWeightOn,
      maxSumOfObjectivesWeightPercentage,
      maxSumOfObjectivesWeightPercentageOn,
      maxSumOfObjectivesWeightType,
      maxSumOfObjectivesWeightTypeOn,
      overallProgress,
      progressColorHex,
      listStatus
    };
  };

  const checkValidationCreateGoal = async ({ nextUrl }) => {
    let res = { isSettingMaxnObjectivesOn: false };

    const { maxObjectivesOn, maxNumberOfObjectives, totalObjectives } =
      await getDataMinMax();

    if (maxObjectivesOn && totalObjectives >= maxNumberOfObjectives) {
      const props = {
        type: 'ValidationAddNewGoal',
        _handleActionModal: () =>
          _handleActionCloseModal({
            typeModal: 'showModalValidationCreateGoal'
          }),
        _handleNext: () => {
          nextUrl();
          _handleActionCloseModal({
            typeModal: 'showModalValidationCreateGoal'
          });
        },
        totalObjectives,
        datacy: 'modal-primary-action'
      };
      immerSetState((draft) => {
        draft['showModalValidationCreateGoal'] = (
          <MinMaxValidationModal {...props} />
        );
      });
      res.isSettingMaxnObjectivesOn = true;
    }
    return res;
  };

  const checkValidationDeleteGoal = async () => {
    let res = { isSettingMinObjectivesOn: false };
    const { minObjectivesOn, totalObjectives } = await getDataMinMax();
    if (minObjectivesOn) {
      immerSetState((draft) => {
        draft['showModalValidationDeleteGoal'] = true;
        draft['totalObjectives'] = totalObjectives;
      });
      res.isSettingMinObjectivesOn = true;
    }
    return res;
  };

  const checkValidationChangeGoalAlignment = async ({
    nextAction,
    closeAction
  }) => {
    let res = { isSettingMinObjectivesOn: false };

    const { minObjectivesOn, totalObjectives } = await getDataMinMax();
    if (minObjectivesOn) {
      const props = {
        type: 'ValidationChangeGoalAlignment',
        _handleActionModal: () => {
          closeAction && closeAction();
          _handleActionCloseModal({
            typeModal: 'showModalValidationChangeGoalAlignment'
          });
        },
        _handleNext: () => {
          nextAction();
          _handleActionCloseModal({
            typeModal: 'showModalValidationChangeGoalAlignment'
          });
        },
        totalObjectives
      };
      immerSetState((draft) => {
        draft['showModalValidationChangeGoalAlignment'] = (
          <MinMaxValidationModal {...props} />
        );
      });
      res.isSettingMinObjectivesOn = true;
    }
    return res;
  };

  const _handleActionCloseModal = ({ typeModal }) => {
    immerSetState((draft) => {
      draft[typeModal] = '';
    });
  };

  const checkValidationSoftWarning = async (isCheckZeroWeight = false) => {
    const {
      maxObjectiveWeightOn,
      minObjectiveWeightOn,
      maxObjectiveWeight,
      minObjectiveWeight,
      maxSubObjectiveWeight,
      minSubObjectiveWeight,
      maxSubObjectiveWeightOn,
      minSubObjectiveWeightOn
    } = config.objectivesConfig;

    const { objectiveWeightType, enableObjectiveCategoryWithRangedWeight } =
      config;
    let maxWeightOn = maxObjectiveWeightOn;
    let minWeightOn = minObjectiveWeightOn;
    let maxWeight = maxObjectiveWeight;
    let minWeight = minObjectiveWeight;

    if (isChildren) {
      minWeightOn = minSubObjectiveWeightOn;
      minWeight = minSubObjectiveWeight;
      maxWeightOn = maxSubObjectiveWeightOn;
      maxWeight = maxSubObjectiveWeight;
    }

    const isUsingValidation = enableObjectiveCategoryWithRangedWeight
      ? true
      : objectiveWeightType === 'percentage';
    if (isUsingValidation) {
      if (weightValue == 0 && isCheckZeroWeight) {
        immerSetState((draft) => {
          draft.isShowWarning = true;
          draft.warningMessage = getObjectiveLocale(`0 weight is not allowed`);
        });
      } else if (
        maxWeightOn &&
        minWeightOn &&
        (weightValue > maxWeight || weightValue < minWeight)
      ) {
        immerSetState((draft) => {
          draft.isShowWarning = true;
          draft.warningMessage = getObjectiveLocale(
            `Minimum weight for each ${
              isChildren ? 'sub-' : ' '
            }objective [min_value]% and maximum [max_value]%`
          )
            .replace(/\[min_value]/g, getNumberLocale(minWeight))
            .replace(/\[max_value]/g, getNumberLocale(maxWeight));
        });
      } else if (maxWeightOn && !minWeightOn && weightValue > maxWeight) {
        immerSetState((draft) => {
          draft.isShowWarning = true;
          draft.warningMessage = getObjectiveLocale(
            `Maximum weight for each ${
              isChildren ? 'sub-' : ' '
            }objective [max_value]%`
          ).replace(/\[.*\]/g, getNumberLocale(maxWeight));
        });
      } else if (!maxWeightOn && minWeightOn && weightValue < minWeight) {
        immerSetState((draft) => {
          draft.isShowWarning = true;
          draft.warningMessage = getObjectiveLocale(
            `Minimum weight for each ${
              isChildren ? 'sub-' : ' '
            }objective [min_value]%`
          ).replace(/\[.*\]/g, getNumberLocale(minWeight));
        });
      } else {
        immerSetState((draft) => {
          draft.isShowWarning = false;
          draft.warningMessage = '';
        });
      }
    }
  };

  return {
    showModalValidationCreateGoal: state.showModalValidationCreateGoal,
    showModalValidationChangeGoalAlignment:
      state.showModalValidationChangeGoalAlignment,
    showModalValidationDeleteGoal: state.showModalValidationDeleteGoal,
    checkValidationCreateGoal,
    checkValidationDeleteGoal,
    checkValidationChangeGoalAlignment,
    _handleActionCloseModal,
    getDataMinMax,
    immerSetState,
    totalObjectives: state.totalObjectives,
    checkValidationSoftWarning,
    isShowWarning: state.isShowWarning,
    warningMessage: state.warningMessage,
    _handleGetCountObjectives,
    _handleGetConfigAdminObjectives,
    getDataCoaching
  };
}

export default useValidationMinMax;

import React from 'react';

const GeneralCard = ({ children, customClass = '' }) => {
  return (
    <div
      className={`rounded-[4px] border-solid border border-n-400 bg-n-000 py-[16px] px-[16px] ${customClass}`}
    >
      {children}
    </div>
  );
};

export default GeneralCard;

import React from 'react';

import isNil from 'lodash/isNil';

import { getCssVariableValue, getObjectiveLocale } from 'utils/HelperUtils';

import FormattedNumber from 'src/components/design-system/FormattedNumber';
import Badge from 'src/components/shared/Badge';

const BehaviorAverageResult = ({ answersData }) => {
  return (
    answersData?.answersData?.length > 0 &&
    answersData?.answersData?.map((answerData, index) => {
      return <ResultCard answerData={answerData} key={index} />;
    })
  );
};

const ResultCard = ({ answerData }) => {
  return (
    <div className="mb-[24px] p-[16px] border border-solid border-n-400 rounded-[4px]">
      <p className="typography-h500 text-n-900 mb-[8px]">
        {answerData?.object?.title}
      </p>
      {!isNil(answerData?.object?.expectedLevel) && (
        <Badge className="mb-[16px]">
          {getObjectiveLocale('Expected Level')}:{' '}
          {answerData?.object?.expectedLevel + 1}
        </Badge>
      )}
      <p className="mb-[16px] typography-paragraph text-n-800">
        {answerData?.object?.levelDescription}
      </p>
      <p className="mb-[16px] typography-h200 text-n-900">
        {getObjectiveLocale(
          'Average score from [answered_reviewer] out of [total_reviewer] reviewers:'
        )
          .replace('[answered_reviewer]', answerData.answeredReviewer)
          .replace('[total_reviewer]', answerData.totalReviewer)}
      </p>
      <div className="flex items-center">
        <Badge
          colorHex={getCssVariableValue('--v-800')}
          bgColorHex={getCssVariableValue('--v-200')}
          className="typography-h200 mr-[8px]"
        >
          <FormattedNumber number={answerData.score} />
        </Badge>
        <span className="typography-h400 text-n-800">
          {answerData.statusName}
        </span>
      </div>
    </div>
  );
};

export default BehaviorAverageResult;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import 'scss/culture/_pulse-survey-modal';

import { answerLaterSurvey, getPulseSurvey } from 'client/CultureClient';
import { useSurveyContext } from 'context/SurveyContext';
import { useToastContext } from 'context/ToastContext';
import { useLocale } from 'hooks/useLocale';
import Config from 'utils/Config';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const regexToast = new RegExp(/You are not registered as participant/gi);

function PulseSurveyModal({
  pulseSurveyId,
  setIsSurveyShow,
  isThanks = false,
  surveyOccurrenceId: occurrenceIdProps
}) {
  const { setBothLocale } = useLocale();
  const [surveyData, setSurveyData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const { push, goBack } = useHistory();
  const { setSurveyId } = useSurveyContext();
  const { addToast } = useToastContext();
  const firstRender = useRef(true);

  const searchParams = new URLSearchParams(location.search);
  const occurrenceId = searchParams.get('occurrenceId');

  const surveyOccurrenceId = occurrenceIdProps || occurrenceId;

  const answerLater = async () => {
    const { data } = await answerLaterSurvey(pulseSurveyId, {
      surveyOccurrenceId
    });
    if (data) {
      setIsSurveyShow(false);
    }
  };

  const redirectSurvey = () => {
    setIsSurveyShow(false);
    push({
      pathname: `/pulse-survey/answer-${
        surveyData?.canBePostponed ? 'later' : 'now'
      }/${pulseSurveyId}`,
      search: `?occurrenceId=${surveyOccurrenceId}`
    });
  };

  const redirectSurveySummary = useCallback(
    (id) => {
      setIsSurveyShow(false);
      push({
        pathname: `/pulse-survey/summary/${id}`,
        search: `?occurrenceId=${surveyOccurrenceId}`
      });
    },
    [push, setIsSurveyShow]
  );

  const thanksButton = () => {
    setIsSurveyShow(false);
    goBack();
  };

  const showToast = useCallback(
    (msg, type) => {
      return setTimeout(() => {
        addToast({
          msg: msg,
          type: type
        });
      }, 300);
    },
    [addToast]
  );

  const getSurvey = useCallback(
    async (id) => {
      const { data, isSuccess, error } = await getPulseSurvey(id, false, {
        surveyOccurrenceId
      });
      if (isSuccess) {
        const { isParticipant, occurrence } = data;
        const { answered, endDate: expiration } = occurrence;
        if (isThanks) {
          setIsVisible(true);
          setSurveyData(data);
          return;
        }
        if (!isParticipant) {
          setIsSurveyShow(false);
          return showToast('You are not registered as participant', 'info');
        }
        const isExpired = new Date().getTime() > new Date(expiration).getTime();
        setSurveyId(null);
        if (answered || isExpired) {
          setIsSurveyShow(false);
          redirectSurveySummary(id);
          if (isExpired) {
            return showToast('This survey has ended', 'info');
          }
          if (answered && !isExpired) {
            return showToast('You have submitted this survey', 'info');
          }
          return;
        } else {
          setSurveyData(data);
          setIsVisible(true);
        }
      } else {
        const { message } = error;
        return showToast(
          message || 'Something went wrong',
          regexToast.test(message) ? 'info' : 'error'
        );
      }
    },
    [
      redirectSurveySummary,
      setSurveyId,
      setIsSurveyShow,
      isThanks,
      showToast,
      surveyOccurrenceId
    ]
  );

  useEffect(() => {
    if (firstRender.current) {
      getSurvey(pulseSurveyId);
      firstRender.current = false;
    }
  }, [pulseSurveyId, getSurvey]);

  return (
    <div className={`modal-wrapper ${isVisible ? 'visible' : 'invisible'}`}>
      <div className="modal-wrapper-content">
        <div className="modal-post-content" data-cy="pulse-survey-modal">
          <div className="header flex" data-cy="modal-title">
            <span className="grow text-center typography-h200 text-n-900 my-[1px] mr-[18px]">
              {isThanks ? '' : 'Pulse Survey'}
            </span>
          </div>
          <div className="content">
            {isThanks && (
              <div className="thanks-section">
                <img src={Config.asset.culture.illustration} />
                <p
                  className="typography-h600 text-n-900 text-center overflow-y-auto max-h-[145px] h-[145px]  whitespace-pre-line w-full"
                  data-cy="thanks-msg-survey"
                >
                  {surveyData.thankMessage}
                </p>
              </div>
            )}
            {!isThanks && (
              <div className="flex flex-col max-h-[358px] pr-[16px]">
                <p
                  className="typography-h700 text-n-900 title mt-[24px] overflow-y-auto whitespace-pre-line h-[84px]"
                  data-cy="title-survey"
                >
                  {surveyData.title}
                </p>
                <p
                  className="typography-paragraph text-n-800 grey-text desc mt-[16px] overflow-y-auto whitespace-pre-line"
                  data-cy="description-survey"
                >
                  {surveyData.description}
                </p>
              </div>
            )}
          </div>
          <div className="footer pt-[24px]">
            {!isThanks && (
              <div className="flex flex-col max-h-[358px]">
                <div className="flex flex-col grow justify-end">
                  <p className="typography-h100 text-n-800 mt-[16px]">
                    {setBothLocale('This survey will be over in')}
                  </p>
                  {surveyData?.occurrence?.endDate && (
                    <div className="survey-ended">
                      <SVGIcon
                        size="28"
                        iconName="icon-timer"
                        customClass="mr-[8px]"
                        fillColor="var(--base-600)"
                      />
                      <div className="detail">
                        <p
                          className="date typography-h400 text-n-900"
                          data-cy="date-survey"
                        >
                          {dayjs(surveyData?.occurrence?.endDate).format(
                            'dddd, DD MMMM YYYY'
                          )}
                        </p>
                        <p
                          className="time typography-h100 text-n-800 mt-[4px]"
                          data-cy="time-survey"
                        >
                          {dayjs(surveyData?.occurrence?.endDate).format('LT')}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="grid pt-[32px]">
              {!isThanks ? (
                <>
                  <Button
                    fullWidth
                    onClick={redirectSurvey}
                    datacy="click-button"
                  >
                    {setBothLocale("LET'S GET STARTED")}
                  </Button>
                  {surveyData?.canBePostponed && (
                    <Button
                      customClass="mt-[8px]"
                      fullWidth
                      variant="tertiary"
                      onClick={answerLater}
                      disabled={false}
                      datacy="answer-later-button"
                    >
                      {setBothLocale('Answer Later')}
                    </Button>
                  )}
                </>
              ) : (
                <Button fullWidth onClick={thanksButton} datacy="thanks-button">
                  {setBothLocale('NICE')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PulseSurveyModal;

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ctl from '@netlify/classnames-template-literals';

import { getAssignmentsCount } from 'client/FormalReviewClient';
import { useUser } from 'context/UserContext';
import useOpenCycles from 'hooks/useOpenCycles';
import { phaseName } from 'utils/FormalReview';
import { getCycleDisplayName } from 'utils/FormalReview';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { getCssVariableValue } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Divider from 'components/design-system/Divider';
import Accordion, {
  useAccordion
} from 'components/design-system/accordion/Accordion';
import InlineDialog from 'components/design-system/inline-dialog/InlineDialog';
import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import MyAssignmentComponent from './MyAssignmentComponent';
import ReviewProgressComponent from './ReviewProgressComponent';

const AccordionHeader = ({ cycle, currentTab }) => {
  const [assignmentsCount, setAssignmentCounts] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  const history = useHistory();
  const { isAccordionOpen } = useAccordion();
  const { config, user } = useUser();
  const ref = useRef();

  let cycleStatus = '';
  if (cycle.state == 'in_progress' || cycle.state == 'reopen') {
    cycleStatus = 'Active';
  } else if (cycle.state == 'done') {
    cycleStatus = 'Ended';
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const getCounts = async () => {
    const { data } = await getAssignmentsCount(cycle.id);

    // temporary code until backend assign orderlevel to phase
    const defaultLevel = {
      self_review: 1,
      upward_review: 2,
      peer_review: 3,
      manager_review: 4,
      indirect_manager_review: 5
    };
    const orderedData = data
      .map(dt => {
        return { ...dt, orderLevel: defaultLevel[dt.type] };
      })
      .sort((a, b) => (a.orderLevel > b.orderLevel ? 1 : -1));

    setAssignmentCounts(data);
  };

  const showDownloadPDFButton = cycle?.seeResultByTargetDeadline
    ? true
    : cycle?.userPlacements?.length > 0 &&
      config?.formalReviewDownloadPdf &&
      currentTab == 'my reviews' &&
      (cycle.state == 'done' ||
        (cycle.permissions?.progressesSee &&
          cycle.inProgressDeliverable &&
          cycle.state === 'in_progress'));

  const showViewResultButton = cycle?.seeResultByTargetDeadline
    ? true
    : cycle?.userPlacements?.length > 0 &&
      currentTab == 'my reviews' &&
      (cycle.state == 'done' ||
        (cycle.permissions?.progressesSee &&
          cycle.inProgressDeliverable &&
          cycle.state === 'in_progress'));

  const resultActionButton = (type, icon) => {
    let dataCy, buttonText, action;

    switch (type) {
      case 'download':
        dataCy = 'download-pdf';
        buttonText = 'Download PDF';
        action = (placementId) =>
          cycle?.pdfResultProvided
            ? openInNewTab(
                `/pdf/result/my-reviews/target/${user.id}/placement/${placementId}/cycle/${cycle.id}/download_pdf/`
              )
            : openInNewTab(
                `/pdf/result/my-reviews/target/${user.id}/placement/${placementId}/cycle/${cycle.id}/`
              );
        break;
      default:
        dataCy = 'view-result';
        buttonText = 'View Review Result';
        action = (placementId) =>
          history.push(
            `/reviews/result/target/${user.id}/placement/${placementId}/cycle/${cycle.id}`
          );
        break;
    }

    return (
      // If user have more than one placement in this cycle and config view result per placement is true
      // Then show inline dialog with their placements as the options
      // If user has more than one placmenet and config is false then redirect to view result of the latest placement
      // If user only have one placement, show button as usual and redirect to view result of that one placement

      cycle.userPlacements.length > 1 && config.viewResultPerPlacement ? (
        <InlineDialog
          type="multiple"
          header={`Select position that you want to ${type}`}
        >
          <InlineDialog.Trigger customClass="mr-[8px]">
            <Button.Secondary
              datacy="download-pdf-button"
              stopPropagation={false}
            >
              <SVGIcon
                size={24}
                fillColor="var(--n-600)"
                iconName={icon}
                customClass="mr-[4px]"
              />
              {getObjectiveLocale(buttonText)}
            </Button.Secondary>
          </InlineDialog.Trigger>
          <InlineDialog.MenuItems customClass="w-[304px]">
            {cycle.userPlacements.map((placement, index) => (
              <InlineDialog.MenuItem
                onClick={() => action(placement.id)}
                key={index}
              >
                <div className="flex flex-col">
                  <p className="typography-h400 mb-[4px]">{placement.name}</p>
                  <p className="typography-h100 typography-secondary">
                    {getDateLocale(placement.startsAt)} -{' '}
                    {placement.endsAt ? getDateLocale(placement.endsAt) : 'Now'}
                  </p>
                </div>
              </InlineDialog.MenuItem>
            ))}
          </InlineDialog.MenuItems>
        </InlineDialog>
      ) : (
        <Button.Secondary
          datacy={dataCy}
          onClick={() => action(cycle?.userPlacements[0]?.id)} // assuming backend will always return last placement at index 0
          customClass="mr-[8px]"
        >
          <SVGIcon
            size={24}
            fillColor="var(--n-600)"
            iconName={icon}
            customClass="mr-[4px]"
          />
          {getObjectiveLocale(buttonText)}
        </Button.Secondary>
      )
    );
  };

  useEffect(() => {
    getCounts();

    if (ref?.current?.scrollWidth > ref?.current?.clientWidth) {
      setShowTooltip(true);
    }
  }, []);

  return (
    <div
      className="pt-[16px] pb-[8px] px-[24px] border-solid border rounded-t border-n-400 cursor-pointer"
      data-cy="cycle-header"
    >
      <div className="flex mb-[16px] justify-between items-start">
        <div className="flex flex-col grow w-[40%]">
          <TooltipContainer
            show={showTooltip}
            position="top"
            text={getCycleDisplayName(cycle)}
            key={cycle.id}
            useMaxWidth={false}
          >
            <p
              ref={ref}
              className="typography-h500 mb-[4px] overflow-hidden truncate"
              data-cy="title-cycle"
            >
              {getCycleDisplayName(cycle)}
            </p>
          </TooltipContainer>
          <p
            className="typography-button typography-secondary"
            data-cy="status-cycle"
          >
            #{cycle.id} · {cycleStatus}
          </p>
        </div>
        <div className="ml-[16px] flex justify-end">
          {/* Download PDF Button */}
          {showDownloadPDFButton &&
            resultActionButton('download', 'icon-file_download')}

          {/* View Review Result Button */}
          {showViewResultButton &&
            resultActionButton('view', 'icon-insert_drive_file')}

          {/* View Progress and Result Button */}
          {currentTab == 'progress and results' && (
            <Button.Secondary
              customClass="ml-[8px] mr-[16px]"
              onClick={() =>
                history.push(`/appraisals/cycle/${cycle.id}/others`)
              }
              datacy="view-progress-result"
            >
              <SVGIcon
                iconName="icon-table_chart"
                size={24}
                fillColor="var(--n-600)"
                customClass="mr-[4px]"
              />
              {getObjectiveLocale('View Progress and Result')}
            </Button.Secondary>
          )}
          <div className="h-[24px]">
            <Divider direction="vertical" />
          </div>
          <SVGIcon
            iconName={
              isAccordionOpen
                ? 'icon-keyboard_arrow_up'
                : 'icon-keyboard_arrow_down'
            }
            size="32"
            fillColor="var(--base-600)"
            customClass="ml-[16px]"
          />
        </div>
      </div>
      <div className="flex">
        {assignmentsCount?.map((value, index) => {
          if (value.type == 'peer_selection') return;

          const name = phaseName(value.type);
          const progress = value.assignmentProgress;
          const customClass = ctl(`
            w-max
            mr-[8px]
          `);

          return (
            <Badge
              key={index}
              title={getObjectiveLocale(name)}
              content={`${progress.completeCount} / ${progress.totalCount}`}
              className={customClass}
              colorHex={
                progress.completeCount == progress.totalCount
                  ? getCssVariableValue('--g-800')
                  : getCssVariableValue('--r-800')
              }
              bgColorHex={
                progress.completeCount == progress.totalCount
                  ? getCssVariableValue('--g-200')
                  : getCssVariableValue('--r-200')
              }
            />
          );
        })}
      </div>
    </div>
  );
};

function CycleComponent(props) {
  const { cycle, currentTab, filter } = props;
  const { setCycleIds, cycleIds } = useOpenCycles();

  let phases = cycle.phases;
  phases = phases && phases.filter((obj) => obj !== undefined);

  if (phases.length > 0) {
    const calibrationPhase = phases.find((obj) => obj.type === 'calibration');
    const filterListReview = phases.filter((obj) => obj.type !== 'calibration');
    filterListReview.unshift(calibrationPhase);
    cycle.phase = filterListReview;
  }

  const filteredPhases = phases.filter((phase) => {
    return !(phase.type == 'calibration' && !phase.ableToCalibrate);
  });

  const removeSelectedCycle = (cycleId) => {
    const removeSelectedCycle = cycleIds.filter((data) => data !== cycleId);
    setCycleIds(removeSelectedCycle ? removeSelectedCycle : []);
  };

  return (
    filteredPhases.length > 0 && (
      <Accordion
        customClass="mb-[32px]"
        dataCy={`cycle-component-${cycle.id}`}
        onChange={(state) =>
          state.isOpen
            ? removeSelectedCycle(cycle.id)
            : setCycleIds([...cycleIds, cycle.id])
        }
        contentOpen={cycleIds.includes(cycle.id)}
      >
        <Accordion.Header useCustomHeader>
          <AccordionHeader cycle={cycle} currentTab={currentTab} />
        </Accordion.Header>
        <Accordion.Content
          customClass="border-solid border-n-400 border-0 border-l border-b border-r"
          customSpaceClass="p-0"
        >
          {currentTab == 'my reviews' && (
            <MyAssignmentComponent
              filteredPhases={filteredPhases}
              cycle={cycle}
              filter={filter}
            />
          )}
          {cycle.permissions?.progressesSee &&
            cycle.permissions?.assignmentsSee &&
            currentTab == 'my reviews' && (
              <hr className="no-border border-bottom-n-400 mb-[8px]" />
            )}
          {cycle.permissions?.progressesSee && (
            <ReviewProgressComponent
              currentTab={currentTab}
              filteredPhases={filteredPhases}
              cycle={cycle}
            />
          )}
        </Accordion.Content>
      </Accordion>
    )
  );
}

export default CycleComponent;

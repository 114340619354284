import client from './ApiClient';

function fetchObjectiveLocale(identifier) {
  return client(`v1/locales/data/${identifier}`, {}, true);
}

function fetchCultureLocale(identifier) {
  return client(`culture/resource/data/${identifier}`, {}, true);
}

export { fetchObjectiveLocale, fetchCultureLocale };

import React, { useState } from 'react';

import { useUser } from 'context/UserContext';

import ToastNotif from 'components/shared/ToastNotif';

import DefaultBadge from './DefaultBadge';
import ListLeague from './ListLeague';
import ListPoints from './ListPoints';

function ListLeaderboard() {
  const { config } = useUser();
  const [showNotif, setShowNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');
  const [warning, setWarning] = useState(false);

  return (
    <div className="wrapper-league pb-[40px]">
      <ToastNotif
        warning={warning}
        showNotif={showNotif}
        message={notifMessage}
      />
      <DefaultBadge
        setShowNotif={(show) => setShowNotif(show)}
        setNotifMessage={(message) => setNotifMessage(message)}
      />
      <ListLeague
        setWarning={(warning) => setWarning(warning)}
        setShowNotif={(show) => setShowNotif(show)}
        setNotifMessage={(message) => setNotifMessage(message)}
      />
      {config?.permissions?.cultureSettingsLeaguesSee && (
        <ListPoints
          setWarning={(warning) => setWarning(warning)}
          setShowNotif={(show) => setShowNotif(show)}
          setNotifMessage={(message) => setNotifMessage(message)}
        />
      )}
    </div>
  );
}

export default ListLeaderboard;

import React, { useState } from 'react';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getStateIcons } from 'utils/ObjectivesHelper';
import { activeVisibility } from 'utils/VisibilityHelper';
import { useDeepEffect } from 'utils/useDeepEffect';

import CustomInnerIcon from 'components/design-system/CustomInnerIcon';
import BookmarkToggle from 'components/objectives/compact-objective/BookmarkToggle';
import FlexibleInputText from 'components/shared/FlexibleInputText/FlexibleInputText';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import TruncateParagraph from 'components/shared/TruncateParagraph';

const TitleObjective = ({
  isHover,
  isCompanyGoal,
  isDirectorateGoal,
  isDepartmentGoal,
  isDivisionGoal,
  title,
  isProject,
  objectiveId,
  isTask,
  childrenCount,
  showStateIcon,
  state,
  permissions,
  smallerTitle,
  isIntegrated,
  isHighlighted,
  isReadOnly
}) => {
  const enableEdit = permissions?.includes('update');
  const enableHighlighted = permissions?.includes('highlight');

  const { config } = useUser();

  const { refetchObjective } = useRefetchQuery();

  const [isEditing, setEditing] = useState(false);
  const [textValue, setTextValue] = useState(title);

  const editToggle = (e) => {
    e.stopPropagation();
    setEditing(true);
  };

  const stopEdit = async () => {
    setEditing(false);
    if (textValue == title) return;

    if (textValue == '') return setTextValue(title);

    const { isSuccess } = await editObjective(objectiveId, { name: textValue });
    if (isSuccess) {
      refetchObjective(objectiveId);
    } else {
      setTextValue(title);
    }
  };

  const onEnter = (e) => {
    if (e.key === 'Escape' || e.key === 'Enter') {
      stopEdit();
    }
  };

  useDeepEffect(() => {
    setTextValue(title);
  }, [title]);

  const allObjectiveGoalType = {
    isCompanyGoal,
    isDirectorateGoal,
    isDepartmentGoal,
    isDivisionGoal
  };

  const visibility = activeVisibility(allObjectiveGoalType);

  return (
    <div
      className="flex flex-row items-center"
      style={{
        width: isReadOnly
          ? 'w-full'
          : smallerTitle
          ? 'calc(100% - 368px)'
          : 'calc(100% - 544px)'
      }}
    >
      <div className="flex flex-row items-center max-w-[100%]">
        {!isEditing ? (
          <div className="mt-[1px] mb-[3px] flex flex-row items-center h-[20px] w-full">
            {enableHighlighted && (
              <BookmarkToggle
                isHighlighted={isHighlighted}
                id={objectiveId}
                isReadOnly={isReadOnly}
              />
            )}
            {(isCompanyGoal ||
              isDirectorateGoal ||
              isDepartmentGoal ||
              isDivisionGoal) && (
              <TooltipContainer
                show
                text={visibility?.text}
                classContainer="mr-[8px]"
              >
                <SVGIcon
                  size="16"
                  iconName={visibility?.iconName}
                  fillColor="var(--n-600)"
                />
              </TooltipContainer>
            )}
            {isProject && (
              <TooltipContainer show text="Project" classContainer="mr-[8px]">
                <SVGIcon
                  iconName="icon-project-badge"
                  fillColor="var(--n-400)"
                  size="16"
                />
              </TooltipContainer>
            )}
            {isTask && childrenCount > 0 && (
              <TooltipContainer
                show
                text={getObjectiveLocale('Task')}
                classContainer="mr-[8px]"
              >
                <SVGIcon
                  iconName="icon-assignment_turned_in"
                  fillColor="var(--n-600)"
                  size="16"
                />
              </TooltipContainer>
            )}
            {showStateIcon && (
              <TooltipContainer
                show
                text={getObjectiveLocale(getStateIcons('text', state))}
                classContainer="mr-[8px]"
              >
                <CustomInnerIcon
                  size={20}
                  iconSize={16}
                  type="square"
                  iconName={getStateIcons('iconName', state)}
                  iconColor={getStateIcons('iconColor', state)}
                  backgroundColor={getStateIcons('backgroundColor', state)}
                  borderColor={getStateIcons('backgroundColor', state)}
                />
              </TooltipContainer>
            )}
            <TruncateParagraph
              className="typography-h500 text-n-900 w-[100%]"
              classContainer="w-[100%] overflow-hidden"
            >
              {title}
            </TruncateParagraph>
            {isIntegrated && config?.permissions?.jiraComponentSee && (
              <SVGIcon iconName="icon-jira" size={16} customClass="ml-[8px]" />
            )}
            {enableEdit && isHover && !isEditing && (
              <div
                className="ml-[4px]"
                onClick={editToggle}
                data-cy="pencil-edit-title"
              >
                <SVGIcon
                  size="16"
                  fillColor="var(--n-600)"
                  iconName="icon-create"
                  dataCy="inline-edit-title"
                />
              </div>
            )}
          </div>
        ) : (
          <FlexibleInputText
            value={textValue}
            onChange={(text) => setTextValue(text)}
            startFocus={true}
            onBlur={stopEdit}
            customClass="ml-[-3px]"
            dataCy="input-edit-title"
            handleKeyPress={onEnter}
          />
        )}
      </div>
    </div>
  );
};

export default TitleObjective;

import client from 'client/ApiClient';

function getScoringTemplates(query) {
  return client(`/admin/scorings`, { params: query });
}

function getScoringTemplatesbyId(templateId) {
  return client(`/admin/scorings/${templateId}`);
}

function updateScoringTemplate(templateId, body) {
  return client(`/admin/scorings/${templateId}`, {
    method: 'PUT',
    body
  });
}

export { getScoringTemplates, getScoringTemplatesbyId, updateScoringTemplate };

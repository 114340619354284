import React from 'react';

import { useEditCycle } from 'context/admin/EditCycleContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import RadioButton from 'components/shared/RadioButton';

const EditPhase = () => {
  const { stateData, changeEditedCycle } = useEditCycle();

  const configRestrictedTrack = [
    {
      id: 'enabled',
      title:
        'Reviewers can switch freely between tracks even when questions have not been answered yet',
      value: false
    },
    {
      id: 'disabled',
      title:
        "Reviewers can't switch between tracks before answering all questions",
      value: true
    }
  ];

  return (
    <div className="py-[24px] bg-n-000 w-full">
      <p className="typography-h500 text-n-900">
        {getObjectiveLocale('Switch tracks')}
      </p>
      <p className="typography-paragraph text-n-800 mb-[16px]">
        {getObjectiveLocale(
          'Option to let reviewers switch between tracks during review'
        )}
      </p>
      {configRestrictedTrack.map((val) => (
        <div key={val.id} className="mb-[8px]">
          <RadioButton
            dataCy={`restricted-phase-${val.value}`}
            size="small"
            value={val.id}
            id={val.id}
            checked={
              stateData?.editedCycle.restrictedTrackNavigation == val.value
            }
            onChange={() =>
              changeEditedCycle('restrictedTrackNavigation', val.value)
            }
          >
            <span className="ml-[8px] typography-paragraph text-n-900">
              {getObjectiveLocale(val.title)}
            </span>
          </RadioButton>
        </div>
      ))}
    </div>
  );
};

export default EditPhase;

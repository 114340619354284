import React from 'react';

import 'scss/culture/modal/modal-post-template';

import Button from 'components/design-system/Button';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import ErrorBoundary from 'pages/ErrorBoundary';

function ModalPostTemplate({
  dataCy,
  modalTitle,
  isButton,
  buttonText,
  onClickButton,
  isButtonDisabled,
  eventOnClick,
  children,
  className,
  dataCyButton,
  isLoading,
  isSubmit,
  isSecondaryButton,
  secondaryButton
}) {
  return (
    <ErrorBoundary>
      <div className={`modal-post-feed ${className}`}>
        <div className="modal-wrapper">
          <div className="modal-wrapper__content">
            <div className="modal-post-content" data-cy={dataCy}>
              <div className="header">
                <div className="header-detail">
                  <SVGIcon
                    size="24"
                    iconName="icon-clear"
                    onClick={() => eventOnClick(false)}
                  />
                  <span className="typography-h600">{modalTitle}</span>
                </div>
                <div className="flex">
                  {isSecondaryButton && (
                    <Button.Secondary
                      onClick={() => secondaryButton?.onClick()}
                      disabled={secondaryButton?.isDisabled}
                      datacy={secondaryButton?.dataCy}
                    >
                      {secondaryButton?.buttonText}
                    </Button.Secondary>
                  )}
                  {isButton && (
                    <Button
                      onClick={() => onClickButton()}
                      disabled={isButtonDisabled}
                      datacy={dataCyButton}
                      customClass="ml-[16px]"
                    >
                      {buttonText}
                      {isSubmit && <LoadingComponent hasText={false} />}
                    </Button>
                  )}
                </div>
              </div>
              {isLoading ? <LoadingComponent /> : children}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default ModalPostTemplate;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import {
  getPhaseDetail,
  manageAssignment,
  postExtendFormalReview
} from 'client/FormalReviewClient';
import { useUser } from 'context/UserContext';
import { UserSuggestionProvider } from 'context/UserSuggestionContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import SidebarPhaseComponent from 'components/formal-review/SidebarPhaseComponent';
import SingleCalendar from 'components/shared/Calendar';
import Modal from 'components/shared/modal/Modal';

const PhaseDetail = (props) => {
  const { config } = useUser();
  const {
    sidebarId,
    currentTab,
    assignment,
    remindCycle,
    isReadOnly,
    lockAnswerCycle,
    setModalRemoveInfo,
    listPhases
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [phaseData, setPhaseData] = useState([]);

  const [tempSavedData, setTempSavedData] = useState({});
  // Reschedule state
  const [modalReschedule, setModalReschedule] = useState({
    show: false,
    wording: ''
  });
  const [rescheduleDate, setRescheduleDate] = useState(null);

  const pageParams = useParams();
  const revieweeOrReviewerWording =
    currentTab == 'reviewee' ? 'reviewer' : 'reviewee';
  const showRescheduleCalendar =
    modalReschedule.wording == 'reschedule' ||
    modalReschedule?.phase?.state == 'done';
  let searchParams = new URLSearchParams(window.location.search);
  const placementId = parseInt(searchParams.get('placement'));

  let params = {
    activity_visibility: 1
  };
  currentTab === 'reviewer'
    ? (params.actorId = sidebarId)
    : (params.targetId = sidebarId);
  currentTab === 'reviewer'
    ? (params.actorPlacementId = placementId)
    : (params.targetPlacementId = placementId); //TODO: UBAH PLACEMENT ID

  const getPhaseData = async () => {
    setIsLoading(true);

    if (listPhases.length > 0) {
      let currentPhase = [];
      const { activity_visibility } = params;
      let phaseParams = {
        activity_visibility,
        adminMode: true
      };
      currentTab === 'reviewer'
        ? (phaseParams.actorId = sidebarId)
        : (phaseParams.targetId = sidebarId);
      currentTab === 'reviewer'
        ? (phaseParams.actorPlacementId = placementId)
        : (phaseParams.targetPlacementId = placementId); //TODO: UBAH PLACEMENT ID

      const promises = listPhases.map(async (phase) => {
        const { data: phaseDetail } = await getPhaseDetail(
          pageParams.id,
          phase.type,
          phaseParams
        );
        if (phaseDetail) {
          currentPhase.push(phaseDetail);
        }
      });
      await Promise.all(promises);
      currentPhase = currentPhase.sort(
        (phase1, phase2) =>
          new Date(phase1.startedAt).getTime() -
          new Date(phase2.startedAt).getTime()
      );
      setPhaseData(currentPhase);
    }
    setIsLoading(false);
  };

  const handleAddAssignment = async (phaseId, listUser) => {
    setIsLoading(true);

    let body = {
      phaseId: phaseId,
      assignments: []
    };

    listUser.map((user) => {
      let newObject = {
        id: null,
        actorId: null,
        targetId: null,
        state: 'in_progress'
      };
      if (currentTab == 'reviewer') {
        newObject.actorId = assignment.userId;
        newObject.actorPlacementId = placementId;
        newObject.targetId = user?.id;
        newObject.targetPlacementId = user?.placementId;
      } else {
        newObject.actorId = user?.id;
        newObject.actorPlacementId = user?.placementId;
        newObject.targetId = assignment.userId;
        newObject.targetPlacementId = placementId;
      }
      body.assignments.push(newObject);
    });

    const { isSuccess } = await manageAssignment(body);
    if (isSuccess) {
      getPhaseData();
      setTempSavedData({});
    }
  };

  // used for reschedule or reopen
  const handleReschedule = async (phase) => {
    const { phaseId, listUser } = tempSavedData;
    let body = {
      phaseId: phaseId,
      assignments: []
    };

    let formattedDate =
      rescheduleDate !== null && dayjs(rescheduleDate).toISOString();
    // in locked assignment we can reopen a assignment where the phase hasn't been ended yet
    // so we set the assignment endsAt with phase endsAt
    if (phase?.state !== 'done' && !showRescheduleCalendar) {
      formattedDate = phase.endsAt;
    }

    listUser.map((assignment) => {
      let newObject = {
        actorId: assignment.actorId,
        actorPlacementId: assignment.actorPlacementId,
        targetId: assignment.targetId,
        targetPlacementId: assignment.targetPlacementId,
        endsAt: dayjs(formattedDate).endOf('day').toISOString(),
        state: 'in_progress'
      };
      body.assignments.push(newObject);
    });

    const { isSuccess } = await postExtendFormalReview(body);
    if (isSuccess) {
      getPhaseData();
      setTempSavedData({});
      setModalReschedule({ show: false, wording: '' });
    }
  };

  const firstHandleSave = (editMode, phaseId, listUser, phase) => {
    if (editMode == 'add') {
      handleAddAssignment(phaseId, listUser);
    } else if (editMode == 'remove') {
      setModalRemoveInfo({
        show: true,
        type: 'assignments',
        data: { phaseId: phaseId, listUser: listUser }
      });
    } else if (editMode == 'reschedule' || editMode == 'reopen') {
      setTempSavedData({ phaseId: phaseId, listUser: listUser });
      setModalReschedule({
        mode: editMode,
        show: true,
        wording: editMode,
        phase
      });
    }
  };

  const PhaseSkeleton = () => {
    return [1, 2].map((idxParent) => {
      return (
        <div className="w-full mt-[24px]" key={idxParent}>
          <Shimmer customClass="rounded" width="50%" height="16px" />
          <div className="review-phase px-[16px] py-[8px]">
            {[1, 2].map((idx) => {
              return (
                <div
                  className="h-[48px] flex justify-between flex items-center w-full my-[16px]"
                  key={idx}
                >
                  <div className="flex flex-[1]">
                    <Shimmer width="40px" height="40px" circle />
                    <div className="flex flex-col ml-[16px] flex-[1]">
                      <Shimmer
                        customClass="rounded"
                        width="60%"
                        height="20px"
                      />
                      <Shimmer
                        customClass="rounded mt-[4px]"
                        width="40%"
                        height="16px"
                      />
                    </div>
                  </div>
                  <Shimmer customClass="rounded" width="100px" height="20px" />
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  const getReopenWording = () => {
    const configDependency = config.formalReviewAssignmentDependantReopen;

    if (configDependency && modalReschedule?.phase?.state == 'in_progress') {
      return `By reopen this reviewer(s), all phases that has dependency to this reviewer(s) will be opened.`;
    } else if (configDependency && modalReschedule?.phase?.state == 'done') {
      return `By reopen this reviewer(s), all phases that has dependency to this reviewer(s) will be opened and you need to set a reopen schedule for this reviewer(s).`;
    } else if (
      !configDependency &&
      modalReschedule?.phase?.state == 'in_progress'
    ) {
      return `By reopen this reviewer(s), related phase in this cycle will be opened.`;
    } else if (!configDependency && modalReschedule?.phase?.state == 'done') {
      return `By reopen this reviewer(s), related phase in this cycle will be opened and you need to set a reopen schedule for this reviewer(s).`;
    }
  };

  const getModalDescription = () => {
    if (modalReschedule.wording === 'reschedule') {
      return `Please set up the assignment end date for this ${revieweeOrReviewerWording}(s)`;
    } else if (modalReschedule.wording === 'reopen') {
      return getReopenWording();
    }
  };

  useEffect(() => {
    getPhaseData();
  }, [sidebarId, assignment]);

  return (
    <>
      {!isLoading ? (
        phaseData.map((phase, phaseIndex) => {
          return (
            phase.type !== 'calibration' && (
              <UserSuggestionProvider>
                <SidebarPhaseComponent
                  key={phase?.id}
                  currentTab={currentTab}
                  phase={phase}
                  phaseIndex={phaseIndex}
                  assignment={assignment}
                  saveData={(editMode, phaseId, listUser, phase) =>
                    firstHandleSave(editMode, phaseId, listUser, phase)
                  }
                  remindCycle={remindCycle}
                  isReadOnly={isReadOnly}
                  lockAnswerCycle={lockAnswerCycle}
                  sidebarId={sidebarId}
                  refetchSidebar={() => getPhaseData()}
                />
              </UserSuggestionProvider>
            )
          );
        })
      ) : (
        <PhaseSkeleton />
      )}
      {modalReschedule.show && (
        <Modal
          title={`${startCase(
            modalReschedule.wording
          )} the selected ${revieweeOrReviewerWording}(s)?`}
          description={getModalDescription()}
          className="w-[400px]"
          withCloseIcon={false}
          withPrimaryBtn={{
            title: getObjectiveLocale(startCase(modalReschedule.wording)),
            dataCy: 'confirm',
            onClick: () => handleReschedule(modalReschedule?.phase),
            disabled: showRescheduleCalendar && !rescheduleDate
          }}
          withSecondaryBtn={{
            title: getObjectiveLocale('Cancel'),
            dataCy: 'cancel-delete',
            onClick: () => setModalReschedule({ show: false, wording: '' })
          }}
        >
          {showRescheduleCalendar && (
            <div className="w-full" data-cy="reschedule-calendar-container">
              <p className="typography-h100 typography-secondary mb-[8px]">
                {getObjectiveLocale('Choose your end date')}
              </p>
              <SingleCalendar
                handleChangeCalendar={(date) => setRescheduleDate(date)}
                defaultValue={rescheduleDate ? dayjs(rescheduleDate) : null}
                customBox="w-[352px] border-solid border-n-500 border bg-n-000 h-[32px] rounded-[4px]"
                type="dueDate"
                calendarButtonType="no"
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default PhaseDetail;

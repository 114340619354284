import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import {
  deleteProjectSection,
  getTaskCountBySection
} from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import useTasks from 'hooks/useTasks';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import BannerBox from 'components/shared/BannerBox';
import Modal from 'components/shared/modal/Modal';

const DeleteSectionModal = ({
  projectId,
  currentSectionIndex,
  setShowModal
}) => {
  const { invalidateQueries } = useRefetchQuery();
  const [taskCount, setTaskCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { listGroups, setListGroups } = useTasks();
  const { section } = listGroups;

  const deleteSection = async () => {
    setIsLoading(true);
    const { isSuccess } = await deleteProjectSection(
      projectId,
      section[currentSectionIndex]?.id
    );
    if (isSuccess) {
      let tempListGroups = cloneDeep(listGroups);
      tempListGroups?.section.splice(currentSectionIndex, 1);
      setListGroups(tempListGroups);
      setShowModal(false);
      invalidateQueries(['group', 'section', 'projectDetail'], {
        refetchActive: false
      });
    }
    setIsLoading(false);
  };

  const getTaskCount = async () => {
    const { data } = await getTaskCountBySection(
      projectId,
      section[currentSectionIndex]?.id
    );
    if (data) setTaskCount(data);
  };

  useEffect(() => {
    getTaskCount();
  }, []);

  return (
    <Modal
      title="Delete Section"
      description="Are you sure you want to delete this section? This action include deletion of all tasks in this section, and your action can't be reversed"
      className="w-[400px]"
      eventOnClickClose={() => setShowModal(false)}
      withPrimaryBtn={{
        title: 'Delete',
        dataCy: 'btn-delete',
        onClick: () => deleteSection(),
        danger: true,
        isLoading: isLoading
      }}
      withSecondaryBtn={{
        title: 'Cancel',
        dataCy: 'btn-cancel-delete',
        onClick: () => setShowModal(false)
      }}
      withCloseIcon
      dataCyModal="delete-section-modal"
    >
      <BannerBox
        type="warning"
        text={getObjectiveLocale(
          `This section has [task_count] task${taskCount === 1 ? '' : 's'}`
        ).replace(/\[task_count]/g, getNumberLocale(taskCount))}
        customClass="mb-[0px]"
      />
    </Modal>
  );
};

export default DeleteSectionModal;

import React, { useEffect, useState } from 'react';

import { getTrackTemplateData } from 'client/TrackTemplateClient.js';
import { getObjectiveLocale } from 'utils/HelperUtils';

import LoadingComponent from 'components/shared/LoadingComponent';

import PhaseAndListTrackTable from './PhaseAndListTrackTable';

const PhasesContent = ({ setShowModalDeactivate }) => {
  const phasesConfig = [
    {
      id: 'selfReview',
      title: getObjectiveLocale('Self Review'),
      description: 'Reviewee will review themselves'
    },
    {
      id: 'upwardReview',
      title: getObjectiveLocale('Upward Review'),
      description: 'Reviewee will reviewed by their subordinate'
    },
    {
      id: 'peerReview',
      title: getObjectiveLocale('Peer Review'),
      description: 'Reviewee will reviewed by peers'
    },
    {
      id: 'managerReview',
      title: getObjectiveLocale('Manager Review'),
      description: 'Reviewee will reviewed by their manager'
    },
    {
      id: 'indirectManagerReview',
      title: getObjectiveLocale('Indirect Manager Review'),
      description: 'Reviewee will reviewed by their indirect manager'
    }
  ];

  const [defaultTrackTemplate, setDefaultTrackTemplate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const _getTrackTemplateData = async () => {
    const { data } = await getTrackTemplateData({ default: true });
    if (data) {
      setDefaultTrackTemplate(data);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    _getTrackTemplateData();
  }, []);

  return (
    <div className="container-phase-and-track">
      {!isLoading &&
        defaultTrackTemplate.length > 0 &&
        phasesConfig.map((phase) => {
          return (
            <PhaseAndListTrackTable
              key={phase.id}
              phase={phase}
              defaultTrackTemplate={defaultTrackTemplate}
              setShowModalDeactivate={setShowModalDeactivate}
            />
          );
        })}

      {isLoading && <LoadingComponent />}
    </div>
  );
};

export default PhasesContent;

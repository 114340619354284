import React, { useEffect, useState } from 'react';

import compact from 'lodash/compact';
import startCase from 'lodash/startCase';

import {
  allVisibility,
  usePermission as usePermissionCompanyGoal,
  visibility
} from 'utils/VisibilityHelper';

import Dropdown from 'components/design-system/dropdown/Dropdown';

const DropdownVisibility = ({
  handleChange,
  defaultValue = '',
  type,
  isTeam,
  isDisabled = false,
  useTrigger = true,
  isProject
}) => {
  const { isGoalCreator } = usePermissionCompanyGoal();
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const listVisibility = compact(
    allVisibility.map((e) => {
      if (type !== 'task' && !isTeam && !isProject) {
        if (!['public', 'private'].includes(e) && !isGoalCreator(e)) return;

        return visibility[e];
      }

      if (['public', 'private'].includes(e)) return visibility[e];
    })
  );

  const handleChangeDropdown = (visibility) => {
    setSelectedValue(visibility.text);
    handleChange(visibility.text);
  };

  useEffect(() => {
    defaultValue && setSelectedValue(defaultValue);
  }, [defaultValue]);

  return (
    <Dropdown autoOpen={!useTrigger}>
      {useTrigger && (
        <Dropdown.Trigger
          customClass="cursor-pointer w-[240px]"
          disabled={isDisabled}
          dataCy="dropdown-visibility"
        >
          <p className="typography-button text-base-600">
            {startCase(selectedValue)}
          </p>
        </Dropdown.Trigger>
      )}
      <Dropdown.MenuItems position="left">
        {listVisibility.map((visibility, index) => (
          <Dropdown.MenuItem
            iconName={visibility?.iconName}
            onClick={() => handleChangeDropdown(visibility)}
            dataCy={`dropdown-visibility-option-${index + 1}`}
            key={index}
          >
            {startCase(visibility?.text)}
          </Dropdown.MenuItem>
        ))}
      </Dropdown.MenuItems>
    </Dropdown>
  );
};

export default DropdownVisibility;

import React, { useMemo } from 'react';

import useDisabledUsers from 'hooks/useDisabledUsers';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getLeader } from 'utils/TeamHelper';

import InlineDialogSelectUser from 'components/inline-dialog/InlineDialogSelectUser';
import { getNewInvolvements } from 'components/sidebar/sidebar-create/SidebarCreateHelper';

const Leader = ({
  objectiveValue,
  setObjectiveValue,
  updateTeam,
  ableToUpdate = false
}) => {
  const { involvements } = objectiveValue || {};

  const currentInvolvements = useMemo(() => {
    return [...involvements];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { checkIsUserDisabled } = useDisabledUsers({
    isEdit: true,
    isTeam: true,
    objective: objectiveValue
  });

  const selectedLeader = getLeader(involvements);

  const handleChangeDialog = (involvements, type, extendedRoleType) => {
    const lastIndex = involvements?.length - 1;
    const selectedUser = involvements[lastIndex];

    checkIsUserDisabled(selectedUser?.user?.id, () => {
      let newInvolvements = getNewInvolvements(
        involvements,
        type,
        false,
        objectiveValue,
        null,
        extendedRoleType,
        true
      );

      setObjectiveValue((draft) => {
        draft.involvements = newInvolvements;
      });
    });
  };

  const updateLeader = async () => {
    const newInvolvements = objectiveValue.involvements.map((involvement) => ({
      userId: involvement.user.id,
      role: involvement.role,
      ...(involvement?.extendedRole && {
        extendedRole: involvement?.extendedRole
      })
    }));

    const { isSuccess } = await updateTeam({ involvements: newInvolvements });
    if (!isSuccess) {
      setObjectiveValue((draft) => {
        draft.involvements = currentInvolvements;
      });
    }
  };

  return (
    <div className="w-1/2 mr-[20px]" data-cy="leader-team">
      <p className="typography-h100 text-n-800">
        {getObjectiveLocale('Leader')}
      </p>
      <div className="ml-[-8px]">
        <InlineDialogSelectUser
          objectiveValue={objectiveValue}
          selectedValue={selectedLeader ? [selectedLeader] : []}
          handleChange={handleChangeDialog}
          permission={ableToUpdate}
          type="assignee"
          extendedRoleType="leader"
          position="left"
          header="Leader"
          isRemoveable={false}
          onCloseDialog={updateLeader}
          dataCy="leader-team-button"
        />
      </div>
    </div>
  );
};

export default Leader;

import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import ctl from '@netlify/classnames-template-literals';
import isEmpty from 'lodash/isEmpty';

import { useUser } from 'context/UserContext';
import {
  UserSuggestionProvider,
  useUserSuggestion
} from 'context/UserSuggestionContext';
import useClickOutside from 'hooks/useClickOutside';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import Checkbox from 'components/shared/Checkbox';
import InputField from 'components/shared/InputField';
import List from 'components/shared/List';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipPortal from 'components/shared/Tooltips/TooltipPortal';

import DefaultObjectiveProfile from 'assets/v2/ico-default-user';

import './InlineDialogSelectFollower.scss';

// Follower
function imageButton(selected, type) {
  //HMM INI KITA COBA GABUNG JD NYA KAN YA
  if (selected.length > 0) {
    selected.map((sel, i) => {
      return (
        <Avatar
          name={sel.user.name}
          src={sel.user.profilePicture}
          size="medium"
          className={`${selected.length === 1 ? '' : 'mr-[4px]'}`}
          key={i}
        />
      );
    });
  } else {
    let wording;
    // If selected.length === 0
    if (type === 'assignee') {
      wording = `Select ${getObjectiveLocale('Objective / Task Owner')}`;
    } else if (type === 'follower') {
      wording = getObjectiveLocale('Follower');
    } else if (type === 'member') {
      wording = getObjectiveLocale('Select Member');
    } else if (type === 'stakeholder') {
      wording = getObjectiveLocale('Select Stakeholder');
    }
    return wording;
  }
}

function InlineDialogSelectFollower({
  objectiveType,
  handleChange,
  excludeValue,
  type,
  selectedValue,
  selectMultiple,
  dropdownPosition = 'down',
  isTeam,
  showDropdown,
  setShowDropdown,
  permission,
  triggerShowDialog
}) {
  const ref = useRef();
  const firstRender = useRef(true);
  const { params } = useRouteMatch();
  const [isEllipsis, setIsEllipsis] = useState({});
  const [portalData, setPortalData] = useState({});
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [isShowDialog, showDialog] = useState(false);
  useClickOutside(ref, () => {
    showDialog(false);
    if (setShowDropdown) {
      setShowDropdown(false);
    }
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      permission &&
        triggerShowDialog !== undefined &&
        showDialog(!isShowDialog);
    }
  }, [triggerShowDialog]);

  const setPortal = (name, e) => {
    if (isEllipsis) {
      setIsTooltipShown(true);
      setPortalData({
        name: name,
        left: e.currentTarget.getBoundingClientRect().x + 'px',
        top: e.currentTarget.getBoundingClientRect().top - 32 + 'px'
      });
    }
  };

  const getLabelWording = () => {
    let wording = '';
    if (isTeam) {
      if (type == 'member') {
        wording = 'Members';
      } else {
        wording = 'Stakeholders';
      }
    } else {
      if (type == 'assignee') {
        wording = 'Objective / Task Owner';
      } else {
        wording = 'Objective / Task Reviewer';
      }
    }

    return wording;
  };

  const containerDownCN = ctl(`
    absolute w-[304px] h-[400px] top-[50px]
    pt-[16px] pr-[4px] rounded-[4px]
    shadow-overlay bg-n-000 z-[11] right-[0px]
    ${!isTeam ? 'left-[0px]' : ''}
    ${showDropdown ? 'left-[18px] top-[6px]' : ''}
  `);

  const containerUpCN = ctl(`
    absolute bottom-[50px] h-[400px]
    pt-[16px] pr-[4px] w-[304px]
    rounded-[4px] shadow-overlay
    bg-n-000  z-[10] right-[0px]
  `);

  return (
    <div className="dropdown-container">
      {!showDropdown && (
        <div
          className={`dropdown-button mt-[0px] mb-[0px] relative w-[180px] max-w-[180px] ${
            !permission || !isEmpty(params.teamId) ? 'no-permission' : ''
          }`}
          onClick={() =>
            permission &&
            isEmpty(params.teamId) &&
            triggerShowDialog == undefined &&
            showDialog(!isShowDialog)
          }
          data-cy={`${type}-select-inline`}
        >
          {selectedValue.length === 0 && (
            <img
              src={DefaultObjectiveProfile}
              className="mr-[8px]"
              alt="default picture"
            />
          )}
          {imageButton(
            selectedValue.filter((user) => user.role == type),
            type
          )}
          {selectedValue.length === 1 && (
            <>
              <span
                ref={(ref) =>
                  setIsEllipsis(ref?.offsetWidth < ref?.scrollWidth)
                }
                onMouseOver={(e) => setPortal(selectedValue[0].user.name, e)}
                onMouseOut={() => setIsTooltipShown(false)}
                className={`typography-paragraph ml-[8px] truncate`}
              >
                {selectedValue[0].user.name}
              </span>
              <TooltipPortal
                show={isTooltipShown}
                portalData={portalData}
                portalId={'shared-tooltip'}
              />
            </>
          )}
        </div>
      )}

      {(isShowDialog || showDropdown) && (
        <div
          className={dropdownPosition == 'up' ? containerUpCN : containerDownCN}
          ref={ref}
        >
          <div
            className={`header-title text-n-600 typography-h100 pr-[12px] pl-[16px] ${
              dropdownPosition == 'up' ? 'mb-[16px]' : 'mb-[20px]'
            }`}
          >
            {getObjectiveLocale(getLabelWording())}
          </div>
          <Dialog
            selectedValue={selectedValue}
            excludeValue={excludeValue}
            handleChange={handleChange}
            selectMultiple={selectMultiple}
            type={type}
            objectiveType={objectiveType}
          />
        </div>
      )}
    </div>
  );
}

function Dialog({
  ref,
  selectedValue,
  excludeValue,
  handleChange,
  selectMultiple,
  objectiveType,
  type
}) {
  const { params } = useRouteMatch();
  const [search, setSearch] = useState('');
  const { user, config } = useUser();
  const debouncedSearchTerm = useDebounce(search, 500);
  const [isLoading, setIsLoading] = useState([]);
  const { suggestion, hasMore, getSuggestionData, appendSuggestionData } =
    useUserSuggestion();

  useEffect(() => {
    setIsLoading(true);
    let query = {
      q: search,
      exclude: excludeValue,
      role: type,
      type: objectiveType
    };
    if (params?.parentId && location.pathname.includes('team')) {
      query.teamId = params?.parentId;
      query.teamInvolvements = ['leader', 'member'];
    }
    getSuggestionData(query);
    setIsLoading(false);
  }, [debouncedSearchTerm, selectedValue]);

  let arraySelectedValue = selectedValue.map(({ userId }) => userId);

  const _onScroll = (e) => {
    const loadMore = async () => {
      await appendSuggestionData({
        q: search,
        exclude: excludeValue,
        role: type,
        type: objectiveType
      });
    };

    let target = e.target;
    let cond = target.scrollTop + target.clientHeight >= target.scrollHeight;

    if (hasMore && cond) {
      loadMore();
    }
  };

  const _handleChange = (sel) => {
    let newSelectedValue = [...selectedValue];
    if (!newSelectedValue.some((value) => value.userId === sel.id)) {
      if (selectMultiple) {
        newSelectedValue.push({
          user: sel,
          role: type,
          userId: sel.id,
          visible: true,
          manager: sel.manager
        });
      } else {
        newSelectedValue = newSelectedValue.filter(
          (newSelected) => newSelected.role !== type
        );
        newSelectedValue.push({
          user: sel,
          role: type,
          userId: sel.id,
          visible: true,
          manager: sel.manager
        });
      }
    } else {
      newSelectedValue = newSelectedValue.filter(
        (newSelected) => newSelected.userId !== sel.id
      );
    }
    handleChange(newSelectedValue, type);
  };

  const deSelectUser = (id) => {
    let newSelectedValue = [...arraySelectedValue];
    newSelectedValue = newSelectedValue.filter(
      (newSelected) => newSelected !== id
    );
    handleChange(newSelectedValue);
  };

  const dropdownUlCN = ctl(`
  dropdownUl
  ${selectedValue.length > 0 ? 'max-h-[250px]' : 'max-h-[295px]'}
  pl-[0px]
  mb-[8px]
  overflow-hidden
  hover:overflow-auto
`);

  return (
    <>
      <div
        className="selected-follower-wrapper pr-[12px] pl-[16px]"
        data-cy="selected-follower-wrapper"
      >
        {selectedValue.map((value, index) => (
          <div key={index} className="container-selected-follower">
            <Avatar
              name={value.user.name}
              className="mr-[-4px]"
              src={value.user.profilePicture}
              size={24}
            />
            <SVGIcon
              iconName="icon-clear"
              size="16"
              fillColor="var(--n-000)"
              customClass="absolute right-[-10px] top-[0px] z-[2] bg-n-600 rounded-[16px]"
              dataCy="remove-user"
              onClick={() => _handleChange(value.user)}
            />
          </div>
        ))}
      </div>
      <InputField
        type="text"
        search
        positionSearch={'absolute mt-[10px] left-[24px]'}
        inputClass={'pl-[32px]'}
        className={'mb-[8px] pr-[12px] pl-[16px]'}
        placeholder="Search colleagues"
        value={search}
        handleChange={(e) => setSearch(e.target.value)}
      />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          {suggestion.length == 0 ? (
            <div className="empty-state-inline-dialog px-[24px]">
              {type === 'assignee'
                ? getObjectiveLocale(
                    'Employee not found or has been assigned as follower or reviewer'
                  )
                : type === 'follower' &&
                  getObjectiveLocale(
                    'Employee not found or has been assigned as owner or reviewer'
                  )}
            </div>
          ) : (
            <>
              <ul className={dropdownUlCN} onScroll={(e) => _onScroll(e)}>
                {suggestion.map((value) => {
                  return (
                    <>
                      <li
                        className="dropdown-li pl-[16px]"
                        key={value.id || value}
                      >
                        <List className="border-none py-[8px]">
                          <Checkbox
                            name={value.id}
                            value={value.id}
                            id={`user-${value.id}`}
                            checked={arraySelectedValue.includes(value.id)}
                            onChange={() => _handleChange(value)}
                            dataCy={`${type}-checkbox`}
                          >
                            <Avatar
                              name={value.name}
                              src={value.profilePicture}
                              style={{ marginLeft: '16px', marginTop: '-2px' }}
                              size={32}
                              usePortal="shared-tooltip"
                            />
                            <div
                              className="pl-[16px] cursor-pointer"
                              data-cy={`user-${value.id}`}
                            >
                              <p className="typography-h400 truncate w-[180px] mt-[0px]">
                                {value.name}
                              </p>
                              <p className="typography-h100 truncate w-[180px] mt-[0px] text-n-800">
                                {value?.title || '-'}
                              </p>
                            </div>
                          </Checkbox>
                        </List>
                      </li>
                    </>
                  );
                })}
              </ul>
            </>
          )}
        </>
      )}
    </>
  );
}

const InlineDialogSelectFollowerComponent = (props) => (
  <UserSuggestionProvider>
    <InlineDialogSelectFollower {...props} />
  </UserSuggestionProvider>
);

export default InlineDialogSelectFollowerComponent;

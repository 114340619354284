import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import { bgRatingTemplate } from 'components/formal-review/evidence-sidebar/ColorDictionarySidebarEvidence';
import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import Checkbox from 'src/components/shared/Checkbox';

import SkeletonCardRatingTemplate from '../SkeletonCardRatingTemplate';

const TooltipRatingConfiguration = ({ title }) => {
  return (
    <>
      <SVGIcon
        iconName="icon-help"
        size={16}
        customClass="ml-[4px]"
        fillColor="var(--n-500)"
      />
      <div className="wrapper-tooltip">
        <div className="tooltip">{title}</div>
      </div>
    </>
  );
};

const CardRatingConfig = ({
  showOptions,
  onChangeRating,
  configRating,
  setConfigRating,
  isWarning,
  isWarningLabel,
  _onKeyDown,
  _onKeyPress
}) => {
  const { type } = useParams();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(null);
  const [reRenderCard, setReRenderCard] = useState(false);
  const colorRef = useRef();
  useClickOutside(colorRef, () => setIsColorPickerOpen(0));

  const listColor = [
    { colorHex: 'ED2E1D', colorTitle: 'Red' },
    { colorHex: 'EDA01D', colorTitle: 'Yellow' },
    { colorHex: '1DED31', colorTitle: 'Green' },
    { colorHex: '1D4DED', colorTitle: 'Blue' },
    { colorHex: '5417CF', colorTitle: 'Purple' }
  ];

  const removeRating = (idx) => {
    setReRenderCard(true);
    let newConfig = [...configRating];
    newConfig.splice(idx, 1);

    setConfigRating(newConfig);
    setTimeout(() => {
      setReRenderCard(false);
    }, 500);
  };

  const toggleAskForStrength = (isOn, idx) => {
    let arrayConfig = [...configRating];
    arrayConfig[idx].askForStrength = isOn;

    if (isOn) {
      arrayConfig[idx].requireForStrength = false;
    } else {
      delete arrayConfig[idx].requireForStrength;
    }

    setConfigRating(arrayConfig);
  };

  const toggleAskForWeakness = (isOn, idx) => {
    let arrayConfig = [...configRating];
    arrayConfig[idx].askForWeakness = isOn;

    if (isOn) {
      arrayConfig[idx].requireForWeakness = false;
    } else {
      delete arrayConfig[idx].requireForWeakness;
    }

    setConfigRating(arrayConfig);
  };

  const isRequireStrengthOrWeakness = (checked, attr, idx) => {
    let arrayConfig = [...configRating];
    arrayConfig[idx][attr] = !checked;
    setConfigRating(arrayConfig);
  };

  const chooseColor = (idx, color) => {
    let arrayConfig = [...configRating];
    arrayConfig[idx].colorHex = color;
    setConfigRating(arrayConfig);
    setIsColorPickerOpen(null);
  };

  const inputProps = (val, key, idx) => ({
    type: 'text',
    onKeyPress: (e) => _onKeyPress(e),
    onKeyDown: (e) => _onKeyDown(e),
    onChange: (e) => onChangeRating(parseFloat(e.target.value), key, idx),
    defaultValue: val[key]
  });

  return (
    <>
      {reRenderCard &&
        configRating.map((val, idx) => (
          <SkeletonCardRatingTemplate key={idx} />
        ))}

      {!reRenderCard &&
        configRating.map((val, idx) => (
          <div
            key={idx}
            className="box-rating-config bg-n-200 mt-[24px] rounded-[4px]"
          >
            <div className="divider-box flex items-center justify-between py-[16px]">
              <h5 className="typography-h500 mb-[0px] pl-[16px]">
                Rating {idx + 1}
              </h5>
              {configRating.length > 2 && (
                <Button.Tertiary
                  datacy={'remove-wrapper-rating-' + val.id}
                  onClick={() => removeRating(idx)}
                  customClass="mr-[16px] px-[8px]"
                >
                  <SVGIcon
                    customClass="mr-[4px]"
                    iconName="icon-delete"
                    size="24"
                    fillColor="var(--base-600)"
                  />
                  Delete
                </Button.Tertiary>
              )}
            </div>

            <div className="divider-box py-[16px] px-[24px]">
              <div className="flex mb-[8px] justify-between">
                <span className="w-[32%] typography-h100 text-n-800 pr-[24px]">
                  {getObjectiveLocale('Rating')}
                </span>
                <span className="w-[64%] header-range typography-h100 text-n-800 flex relative pr-[24px]">
                  Range
                  <TooltipRatingConfiguration
                    title={
                      'Input text fields on the left will automatically be filled based on the input on the right sections'
                    }
                  />
                </span>
                <span className="w-[4%] header-desc typography-h100 text-n-800">
                  Color
                </span>
              </div>
              <div className="flex mb-[8px] justify-between">
                <div className="w-[32%] pr-[24px]">
                  <input
                    {...inputProps(val, 'score', idx)}
                    className={`input-rating w-full ${
                      val.score || val.score == 0
                        ? 'normal-border'
                        : 'error-border'
                    }`}
                    id={'input-rating-score-' + idx}
                    data-cy={'input-rating-score-' + val.id}
                  />
                </div>

                <div className="flex w-[64%]">
                  <div className="relative custom-width pr-[24px]">
                    {idx != 0 && (
                      <SVGIcon
                        customClass="absolute top-[50%] tranform -translate-y-1/2 ml-[8px]"
                        iconName="icon-greater-than"
                        size="24"
                      />
                    )}
                    <input
                      {...inputProps(val, 'bottomThresholdScore', idx)}
                      className={`input-range-rating w-full
                      ${idx == 0 && '!pl-[16px]'}
                      ${
                        val.bottomThresholdScore ||
                        val.bottomThresholdScore == 0
                          ? 'normal-border'
                          : 'error-border'
                      }
                    `}
                      disabled={idx != 0}
                      id={'input-bottom-rating-' + idx}
                      data-cy={'input-bottom-rating-' + val.id}
                    />
                  </div>
                  <div className="relative custom-width pr-[24px]">
                    {configRating.length - 1 !== idx && (
                      <SVGIcon
                        customClass="absolute top-[50%] tranform -translate-y-1/2 ml-[8px]"
                        iconName="icon-less-than-eq"
                        size="24"
                      />
                    )}
                    <input
                      {...inputProps(val, 'upperThresholdScore', idx)}
                      className={`input-range-rating normal-border
                      ${
                        val.upperThresholdScore
                          ? 'normal-border'
                          : 'error-border'
                      }
                      ${idx == configRating.length - 1 ? 'd-none' : 'd-block'}
                    `}
                      id={'input-upper-rating-' + idx}
                      data-cy={'input-upper-rating-' + val.id}
                    />
                  </div>
                </div>

                <div className="w-[4%]">
                  <div
                    onClick={() => setIsColorPickerOpen(idx)}
                    className={`${
                      bgRatingTemplate[val.colorHex] || 'bg-r-200'
                    } img-36 rounded-[4px] relative cursor-pointer`}
                  />
                  {isColorPickerOpen == idx && (
                    <div className="wrapper-color-picker" ref={colorRef}>
                      {listColor.map(({ colorHex, colorTitle }, i) => (
                        <div
                          key={i}
                          onClick={(e) => {
                            e.stopPropagation();
                            chooseColor(idx, colorHex);
                          }}
                          className="flex items-center cursor-pointer"
                        >
                          <div
                            className={`${bgRatingTemplate[colorHex]} img-24 rounded-[4px] my-[4px]`}
                          />
                          <p className="ml-[8px]">{colorTitle}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* Warning Message */}
              <div className="flex mb-[8px] justify-between">
                <div className="w-[90%]">
                  {isWarning.show &&
                    (isNaN(val.bottomThresholdScore) ||
                      !val.upperThresholdScore ||
                      isNaN(val.score)) && (
                      <p className="typography-h100">{isWarning.message}</p>
                    )}
                </div>
                <div className="w-[10%]" />
              </div>

              {showOptions != 'score_only' && (
                <>
                  <span className="w-[40%] header-label typography-h100 text-n-800 flex relative mb-[8px]">
                    Label
                    <TooltipRatingConfiguration title={'Max 256 characters'} />
                  </span>
                  <TextArea
                    height={36}
                    maxHeight={88}
                    onHandleChange={(e) =>
                      onChangeRating(e.target.value, 'name', idx)
                    }
                    className={`input-rating normal-border w-full max-h-[36px] max-h-[88px] height-auto resize-none input-rating-label
                    ${
                      showOptions != 'score_only' && !val.name
                        ? 'error-border'
                        : ''
                    }
                  `}
                    id={'input-rating-label-' + idx}
                    data-cy={'input-rating-label-' + val.id}
                    value={val.name}
                    maxLength="256"
                    textPlaceholder=""
                  />
                </>
              )}
              {showOptions != 'score_only' &&
                isWarningLabel.show &&
                !val.name && (
                  <p className="typography-h100">{isWarningLabel.message}</p>
                )}
            </div>

            <div
              className={`${
                type == 'goal-ongoing' || type == 'task-ongoing'
                  ? 'divider-box'
                  : ''
              } py-[16px] px-[24px]`}
            >
              <span className="w-[12%] typography-h100 text-n-800">
                Description
              </span>
              <textarea
                onChange={(e) =>
                  onChangeRating(e.target.value, 'description', idx)
                }
                className="input-rating normal-border w-full h-[64px] mt-[8px] resize-none"
                id={'input-rating-desc-' + idx}
                data-cy={'input-rating-desc-' + val.id}
                defaultValue={val.description}
                type="text"
                maxLength="244"
              />
            </div>

            {(type == 'goal-ongoing' || type == 'task-ongoing') && (
              <>
                <div className="divider-box py-[12px] px-[16px] flex justify-between">
                  <div className="flex items-center">
                    <div className="img-24 rounded-full bg-g-100">
                      <SVGIcon
                        iconName="icon-thumb_up_alt"
                        fillColor="var(--g-500)"
                        size="24"
                      />
                    </div>
                    <p className="ml-[16px] text-n-900">Ask for recognition</p>
                  </div>
                  <div className="flex items-center">
                    {val.askForStrength && (
                      <Checkbox
                        dataCy={'checkbox-recognition-' + val.id}
                        id={'checkbox-recognition-' + val.id}
                        checked={!val.requireForStrength}
                        onChange={(e) =>
                          isRequireStrengthOrWeakness(
                            e.target.checked,
                            'requireForStrength',
                            idx
                          )
                        }
                      >
                        <span className="ml-[8px] text-n-900">
                          {getObjectiveLocale('optional')}
                        </span>
                      </Checkbox>
                    )}
                    <SmallToggleSwitchPurple
                      addClass="self-end ml-[44px]"
                      isChecked={val.askForStrength}
                      onChange={(e) =>
                        toggleAskForStrength(e.target.checked, idx)
                      }
                    />
                  </div>
                </div>
                <div className="py-[12px] px-[16px] flex justify-between">
                  <div className="flex items-center">
                    <div className="img-24 rounded-full bg-y-100">
                      <SVGIcon
                        iconName="icon-feedback"
                        fillColor="var(--y-500)"
                        size="24"
                      />
                    </div>
                    <p className="ml-[16px] text-n-900">Ask for feedback</p>
                  </div>
                  <div className="flex items-center">
                    {val.askForWeakness && (
                      <Checkbox
                        dataCy={'checkbox-weakness-' + val.id}
                        id={'checkbox-weakness-' + val.id}
                        checked={!val.requireForWeakness}
                        onChange={(e) =>
                          isRequireStrengthOrWeakness(
                            e.target.checked,
                            'requireForWeakness',
                            idx
                          )
                        }
                      >
                        <span className="ml-[8px] text-n-900">
                          {getObjectiveLocale('optional')}
                        </span>
                      </Checkbox>
                    )}
                    <SmallToggleSwitchPurple
                      addClass="self-end ml-[44px]"
                      isChecked={val.askForWeakness}
                      onChange={(e) =>
                        toggleAskForWeakness(e.target.checked, idx)
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
    </>
  );
};

export default CardRatingConfig;

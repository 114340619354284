import client from './ApiClient';

function getRevieweeDetail(userId, query = {}) {
  return client(`v1/users/${userId}`, { params: query });
}

function getActiveCycles(query = {}) {
  return client(`v1/formal_reviews/cycles`, { params: query });
}

function getPreviousCycle(userId) {
  return client(`v1/formal_reviews/cycles/previous/users/${userId}`);
}

function getCyclesById(cycleId) {
  return client(`v1/formal_reviews/cycles/${cycleId}`);
}

function getEndedCycles(query = {}) {
  return client(`v1/formal_reviews/cycles/ended`, { params: query });
}

function getAssignmentsCount(cycleId) {
  return client(`v1/formal_reviews/cycles/${cycleId}/assignments/count`);
}

function getPhaseDetail(cycleId, phaseType, query = {}) {
  return client(`v1/formal_reviews/cycles/${cycleId}/phases/${phaseType}`, {
    params: query
  });
}

function getPeerHistory(cycleId, targetId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/peer_selections/target/${targetId}/reviewer/history`;
  return client(url, { params });
}

function getPeerSelections(cycleId, targetId) {
  const url = `v1/formal_reviews/cycles/${cycleId}/peer_selections/target/${targetId}/reviewer`;
  return client(url, { method: 'GET' });
}

function getPeerRevieweeSelections(cycleId, targetId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/peer_selections/target/${targetId}/reviewee`;
  return client(url, { params });
}

function getPeerSuggestions(cycleId, targetId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/peer_selections/peers/${targetId}`;
  return client(url, { params });
}

function submitPeerSelections(cycleId, targetId, payload) {
  const url = `v1/formal_reviews/cycles/${cycleId}/peer_selections/target/${targetId}`;
  return client(url, { method: 'PUT', body: payload });
}

function getFormalReviewAssignment(assignmentId, useInterceptor = true) {
  const url = `v1/formal_reviews/assignments/${assignmentId}`;
  return client(url, { useInterceptor: useInterceptor });
}

function getRevieweeFeedbackTracks(cycleId, assignmentId) {
  const url = `v1/formal_reviews/cycles/${cycleId}/assignments/${assignmentId}/reviewee_feedback/manager_tracks`;
  return client(url);
}

function getRevieweeFeedbackGoals(cycleId, assignmentId, query) {
  const url = `v3/formal_reviews/cycles/${cycleId}/assignments/${assignmentId}/reviewee_feedback/objectives`;
  return client(url, { params: query });
}

function getGeneralQuestionnaireResult(cycleId, phaseType, trackType, query) {
  const url = `v1/formal_reviews/cycles/${cycleId}/phases/${phaseType}/tracks/${trackType}/answers`;
  return client(url, { params: query });
}

function getFormalReviewGoalsScoring(assignmentId) {
  const url = `v1/formal_reviews/assignments/${assignmentId}/goals_scoring`;
  return client(url);
}

function getAnnualReviewSummary(assignmentId) {
  const url = `v1/formal_reviews/assignments/${assignmentId}/summary`;
  return client(url);
}

function getFormalReviewTasksScoring(assignmentId) {
  const url = `v1/formal_reviews/assignments/${assignmentId}/tasks_scoring`;
  return client(url);
}

function submitFormalReview(assignmentId) {
  const url = `v1/formal_reviews/assignments/${assignmentId}/submit`;
  return client(url, { method: 'POST', body: {} });
}

function postExtendFormalReview(body) {
  const url = `v1/formal_reviews/assignments/extend`;
  return client(url, { method: 'POST', body: body });
}

function setFormalReviewAnswer(
  assignmentId,
  trackId,
  trackType,
  answers,
  answerType
) {
  let url = `v1/formal_reviews/assignments/${assignmentId}`;

  // // Goal Scoring and Task Scoring with mechanism 'suggested_ongoing'
  if (answerType === 'suggested_ongoing' || answerType === 'view_only') {
    url += `/${trackType}/change_state`;
  } else if (answerType === 'questionnaire' || trackType === 'summary') {
    url += `/tracks/${trackId}/answers`;
  } else if (
    trackType === 'competencies_scoring' ||
    trackType === 'values_scoring' ||
    trackType === 'competencies_recognition_feedback' ||
    trackType === 'values_recognition_feedback' ||
    trackType === 'goals_scoring' ||
    trackType === 'tasks_scoring'
  ) {
    url += `/${trackType}/answers`;
  }
  const timestamp = new Date().toISOString();
  if (Array.isArray(answers)) {
    answers = { answers };
  }
  return client(url, { method: 'PUT', body: { ...answers, timestamp } });
}

function finalizeFormalReview(assignmentId, trackType, body) {
  let url = `v1/formal_reviews/assignments/${assignmentId}/${trackType}/change_state`;
  return client(url, { method: 'PUT', body });
}

function setAnswersScoreAttributesScoring(
  assignmentId,
  trackId,
  answers,
  reviewAspectId
) {
  const timestamp = new Date().toISOString();
  const url = `v1/formal_reviews/assignments/${assignmentId}/review_aspects_scorings/${trackId}/review_aspects/${reviewAspectId}/answers`;
  return client(url, { method: 'PUT', body: { ...answers, timestamp } });
}

function getListPhaseAverageByTargetId(cycleId, trackType, targetId, objectId) {
  const url = `v1/formal_reviews/cycles/${cycleId}/${trackType}/average?target_id=${targetId}&object_id=${objectId}`;
  return client(url);
}

function getFormalReviewAnswers(cycleId, trackType, phaseType, query) {
  const url = `v1/formal_reviews/cycles/${cycleId}/${trackType}/phases/${phaseType}/answers`;
  return client(url, { params: query });
}

function getFormalReviewAveragesResult(cycleId, trackType, phaseType, query) {
  const url = `v1/formal_reviews/cycles/${cycleId}/${trackType}/phases/${phaseType}/averages`;
  return client(url, { params: query });
}

function getFormalReviewAnswersAspectScoring(
  trackConfigId,
  reviewAspectId,
  query
) {
  const url = `v1/formal_reviews/review_aspects_scorings/track_configs/${trackConfigId}/review_aspects/${reviewAspectId}/answers`;
  return client(url, { params: query });
}

function getObjectivesScoreAttribute(
  assignmentId,
  trackId,
  reviewAspectId,
  query
) {
  const url = `v3/formal_reviews/assignments/${assignmentId}/review_aspects_scorings/${trackId}/review_aspects/${reviewAspectId}/objectives`;
  return client(url, { params: query, skipNulls: false });
}

function getTopParentScoredAttribute(assignmentId, trackId, query) {
  const url = `v3/formal_reviews/assignments/${assignmentId}/review_aspects_scorings/${trackId}/parent_objectives`;
  return client(url, { params: query });
}

function getReviewResultObjectivesScoreAttribute(
  cycleId,
  trackConfigId,
  reviewAspectId,
  params
) {
  const url = `v3/formal_reviews/cycles/${cycleId}/review_aspects_scorings/track_configs/${trackConfigId}/review_aspects/${reviewAspectId}/objectives`;
  return client(url, { params });
}

function getListAnswersByTargetId(
  cycleId,
  trackType,
  phaseType,
  targetId,
  objectId
) {
  const url = `v1/formal_reviews/cycles/${cycleId}/${trackType}/phases/${phaseType}/answers?target_id=${targetId}&object_id=${objectId}`;
  return client(url);
}

function getOngoingReviewAverage(
  cycleId,
  trackType,
  targetId,
  objectId,
  strengthType
) {
  const url = `v1/formal_reviews/cycles/${cycleId}/${trackType}/ongoing_peer_average/target/${targetId}?object_id=${objectId}&type=${strengthType}`;
  return client(url);
}

function getListPhaseGeneralSidebar(cycleId, targetId) {
  const url = `v1/formal_reviews/cycles/${cycleId}/general_questionnaire/phases?target_id=${targetId}`;
  return client(url);
}

function getReviewTemplateGeneralSidebar(cycleId, phaseType) {
  const url = `v1/formal_reviews/cycles/${cycleId}/general_questionnaire/phases/${phaseType}/review_template`;
  return client(url);
}

function getAnswerGeneralSidebar(cycleId, phaseType, query) {
  const url = `v1/formal_reviews/cycles/${cycleId}/general_questionnaire/phases/${phaseType}/answers?`;
  return client(url, { params: query });
}

function getListFilter(cycleId, query) {
  let url = `v1/formal_reviews/cycles/${cycleId}/calibration/filters`;
  return client(url, { params: query });
}

function getPhaseProgressFilter(cycleId, query) {
  let url = `v1/formal_reviews/cycles/${cycleId}/progresses/filters`;
  return client(url, { params: query });
}

function getResultFilter(cycleId, query) {
  let url = `v1/formal_reviews/cycles/${cycleId}/result/filters`;
  return client(url, { params: query });
}

function getCalibrationPermission(cycleId, isAdmin = false, params) {
  let url = `v1/formal_reviews/cycles/${cycleId}/calibration`;
  if (isAdmin) {
    url += `?admin_mode=1`;
  }
  return client(url, { params });
}

function getQuoteCalibration(cycleId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/calibration/quota`;
  return client(url, { params });
}

function getResultQuota(cycleId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/result/quota`;
  return client(url, { params });
}

function getCalibrationTargetData(cycleId, targetId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/calibration/targets/${targetId}`;
  return client(url, { params });
}

function getListTarget(cycleId, params, signal) {
  const url = `v1/formal_reviews/cycles/${cycleId}/calibration/targets`;
  return client(url, { params }, false, signal);
}

function getResultTarget(cycleId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/result/targets`;
  return client(url, { params });
}

function submitModalCalibrate(cycleId, answers) {
  const url = `v1/formal_reviews/cycles/${cycleId}/calibration/score`;
  return client(url, { method: 'PUT', body: answers });
}

function updateQuotaCalibration(cycleId, body) {
  const url = `v1/formal_reviews/cycles/${cycleId}/calibration/quota`;
  return client(url, { method: 'POST', body });
}

function getPeerFeedback(query) {
  const url = `v1/formal_reviews/peer_feedbacks`;
  return client(url, { params: query });
}

function generatePeerFeedback(body) {
  const url = `v1/formal_reviews/peer_feedbacks`;
  return client(url, { method: 'POST', body });
}

function sendEmailReminder(cycleId, body) {
  const url = `v1/formal_reviews/cycles/${cycleId}/send_email`;
  return client(url, { method: 'POST', body: body });
}

function getManagerSummaryResultTarget(cycleId, targetId) {
  const url = `v1/formal_reviews/cycles/${cycleId}/manager_summary_result/target/${targetId}`;
  return client(url, { method: 'GET' });
}

function getCyclesFromEvidence(assignmentId, trackType) {
  const url = `v1/formal_reviews/assignments/${assignmentId}/tracks/${trackType}/cycles`;
  return client(url, { method: 'GET' });
}

function getPhasesFromEvidence(cycleId, trackType) {
  const url = `v1/formal_reviews/cycles/${cycleId}/tracks/${trackType}/phases`;
  return client(url, { method: 'GET' });
}

function getBehaviours(query) {
  const url = `v1/behaviors`;
  return client(url, { method: 'GET', params: query });
}

function getCycleProgress(cycleId, type, query) {
  const url = `v1/formal_reviews/cycles/${cycleId}/phases/${type}/progress`;
  return client(url, { method: 'GET', params: query });
}

function getListDirectReport(cycleId, query) {
  const url = `v1/formal_reviews/cycles/${cycleId}/progresses`;
  return client(url, { method: 'GET', params: query });
}

function getSummaryProgress(cycleId, query) {
  const url = `v1/formal_reviews/cycles/${cycleId}/progresses/summary`;
  return client(url, { method: 'GET', params: query });
}

function getReviewerRevieweePhase(cycleId, query) {
  const url = `v1/formal_reviews/cycles/${cycleId}`;
  return client(url, { method: 'GET', params: query });
}

function getReviewerRevieweeProgress(cycleId, role, userId, query) {
  const url = `v1/formal_reviews/cycles/${cycleId}/progresses/${role}/${userId}`;
  return client(url, { method: 'GET', params: query });
}

function getSummaryProgressByPhase(cycleId, type, role, body) {
  const url = `v1/formal_reviews/cycles/${cycleId}/progresses/summary/${type}/${role}`;
  return client(url, { method: 'POST', body: body });
}

function getManagerChildren(userId) {
  const url = `v1/users/${userId}/children`;
  return client(url, { method: 'GET' });
}

function getCycle(cycleId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/result`;
  return client(url, { method: 'GET', params: params });
}

function manageAssignment(params) {
  const url = `v1/formal_reviews/assignments`;
  return client(url, { method: 'POST', body: params });
}

function manageBulkAssignment(params) {
  const url = `v1/formal_reviews/assignments/bulk`;
  return client(url, { method: 'POST', body: params });
}

function confirmReviewAspectScore(assignmentId, trackId, reviewAspectId) {
  const url = `v1/formal_reviews/assignments/${assignmentId}/review_aspects_scorings/${trackId}/review_aspects/${reviewAspectId}/confirm`;
  return client(url, { method: 'PATCH' });
}

function getObjectiveCount(assignmentId, trackId, reviewAspectId) {
  const url = `v1/formal_reviews/assignments/${assignmentId}/review_aspects_scorings/${trackId}/review_aspects/${reviewAspectId}/objective_count`;
  return client(url);
}

function deleteAllRevieweeReviewer(cycleId, params) {
  const url = `admin/formal_reviews/cycles/${cycleId}/participants`;
  return client(url, { method: 'DELETE', body: params });
}

function getScoreCardDetail(cycleId, targetId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/target/${targetId}/score_card`;
  return client(url, { method: 'GET', params });
}

function getFormalReviewObjectivesCount(assignmentId, trackType, params) {
  const url = `v3/formal_reviews/assignments/${assignmentId}/${trackType}/objectives/count`;
  return client(url, { method: 'GET', params });
}

function getFormalReviewObjectivesRating(assignmentId, trackType, params) {
  const url = `v3/formal_reviews/assignments/${assignmentId}/${trackType}/rating`;
  return client(url, { method: 'GET', params });
}

function postAssignmentsImportUser(cycleId, body) {
  return client(
    `admin/formal_reviews/cycles/${cycleId}/assignments/reviewees`,
    {
      method: 'POST',
      body
    }
  );
}

function importResults(cycleId, query) {
  return client(
    `admin/formal_reviews/cycles/${cycleId}/assignments/reviewees`,
    { params: query }
  );
}

function patchBulkUpdateUser(cycleId, body) {
  return client(
    `admin/formal_reviews/cycles/${cycleId}/assignments/reviewees`,
    {
      method: 'PATCH',
      body
    }
  );
}

function getCalibrationHistories(cycleId, targetId, query) {
  return client(
    `v1/formal_reviews/cycles/${cycleId}/calibration/target/${targetId}`,
    { params: query }
  );
}

function getCalibrationResultHistories(cycleId, targetId, query) {
  return client(
    `v1/formal_reviews/cycles/${cycleId}/result/target/${targetId}`,
    { params: query }
  );
}

function getCurrentCalibrationScore(cycleId, targetId, query) {
  return client(
    `v1/formal_reviews/cycles/${cycleId}/calibration/target/${targetId}/current_calibration`,
    { params: query }
  );
}

function getCurrentResultCalibrationScore(cycleId, targetId) {
  return client(
    `v1/formal_reviews/cycles/${cycleId}/result/target/${targetId}/current_calibration`
  );
}

function getPdfFormalReviewFromOutsideSource(cycleId, placementId) {
  const url = `v1/formal_reviews/cycles/${cycleId}/results/${placementId}/download_pdf`;
  return client(url);
}

function updateTrackData(trackId, assignmentId, body) {
  const url = `v1/formal_reviews/assignments/${assignmentId}/tracks/${trackId}`;
  return client(url, { method: 'PATCH', body });
}

function updatePhaseData(cycleId, phaseType, body) {
  const url = `v1/admin/formal_reviews/cycles/${cycleId}/phases/${phaseType}`;
  return client(url, { method: 'PUT', body });
}

function exportCycleProgressCSV(cycleId, body) {
  const url = `v1/formal_reviews/cycles/${cycleId}/progresses/exports`;
  return client(url, { method: 'POST', body });
}

function getLatestCycleProgressCSV(cycleId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/progresses/exports/latest`;
  return client(url, { params, useInterceptor: false });
}

function exportCycleResultCSV(cycleId, body) {
  const url = `v1/formal_reviews/cycles/${cycleId}/result/targets/exports`;
  return client(url, { method: 'POST', body });
}

function getLatestCycleResultCSV(cycleId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/result/targets/exports/latest`;
  return client(url, { params, useInterceptor: false });
}

function importCycleScoreCSV(cycleId, body) {
  const url = `v1/formal_reviews/cycles/${cycleId}/assignments/answers/import`;
  return client(url, { method: 'POST', body }, true);
}

// Bulk Change Reviewer
function importRevieweeSelections(cycleId, body) {
  const url = `v1/formal_reviews/cycles/${cycleId}/assignments/reviewee_selections`;
  return client(url, { method: 'POST', body });
}

function getRevieweeSelections(cycleId, params) {
  const url = `v1/formal_reviews/cycles/${cycleId}/assignments/reviewee_selections`;
  return client(url, { params });
}

function bulkChangeReviewer(cycleId) {
  const url = `v1/formal_reviews/cycles/${cycleId}/assignments/update/reviewer`;
  return client(url, { method: 'POST' });
}

export {
  getActiveCycles,
  getEndedCycles,
  getAssignmentsCount,
  getPhaseDetail,
  getPeerSelections,
  getPeerRevieweeSelections,
  getPeerSuggestions,
  submitPeerSelections,
  getFormalReviewAssignment,
  getRevieweeFeedbackTracks,
  getRevieweeFeedbackGoals,
  getGeneralQuestionnaireResult,
  getFormalReviewGoalsScoring,
  getFormalReviewTasksScoring,
  submitFormalReview,
  postExtendFormalReview,
  getFormalReviewAnswers,
  getFormalReviewAveragesResult,
  setFormalReviewAnswer,
  getListPhaseAverageByTargetId,
  getListAnswersByTargetId,
  getOngoingReviewAverage,
  getListPhaseGeneralSidebar,
  getReviewTemplateGeneralSidebar,
  getAnswerGeneralSidebar,
  getListFilter,
  getCalibrationPermission,
  getQuoteCalibration,
  getListTarget,
  submitModalCalibrate,
  updateQuotaCalibration,
  getPeerFeedback,
  generatePeerFeedback,
  getManagerSummaryResultTarget,
  getCyclesFromEvidence,
  getPhasesFromEvidence,
  getBehaviours,
  getAnnualReviewSummary,
  finalizeFormalReview,
  getCycleProgress,
  getListDirectReport,
  getSummaryProgress,
  getReviewerRevieweePhase,
  getReviewerRevieweeProgress,
  getSummaryProgressByPhase,
  sendEmailReminder,
  getManagerChildren,
  getCycle,
  manageAssignment,
  manageBulkAssignment,
  deleteAllRevieweeReviewer,
  getCyclesById,
  getRevieweeDetail,
  getScoreCardDetail,
  setAnswersScoreAttributesScoring,
  getPhaseProgressFilter,
  getResultFilter,
  getResultTarget,
  getResultQuota,
  getFormalReviewAnswersAspectScoring,
  getObjectivesScoreAttribute,
  getReviewResultObjectivesScoreAttribute,
  getPreviousCycle,
  getFormalReviewObjectivesCount,
  getFormalReviewObjectivesRating,
  importResults,
  postAssignmentsImportUser,
  patchBulkUpdateUser,
  getCalibrationHistories,
  getCurrentCalibrationScore,
  getPdfFormalReviewFromOutsideSource,
  getTopParentScoredAttribute,
  getCalibrationTargetData,
  confirmReviewAspectScore,
  getObjectiveCount,
  updateTrackData,
  updatePhaseData,
  getCalibrationResultHistories,
  getCurrentResultCalibrationScore,
  getPeerHistory,
  exportCycleProgressCSV,
  getLatestCycleProgressCSV,
  exportCycleResultCSV,
  getLatestCycleResultCSV,
  importCycleScoreCSV,
  importRevieweeSelections,
  getRevieweeSelections,
  bulkChangeReviewer
};

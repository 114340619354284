import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import 'scss/culture/_timeline';

import { getPostById, viewPost } from 'client/CultureClient';
import { useReload } from 'context/ReloadContext';
import { getCultureLocale, setPostType } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import PostAttachment from 'components/posts/PostAttachment';
import PostComment from 'components/posts/PostComment';
import PostFeeling from 'components/posts/PostFeeling';
import PostHeader from 'components/posts/PostHeader';
import PostInfo from 'components/posts/PostInfo';
import PostLabel from 'components/posts/PostLabel';
import PostLeaderboard from 'components/posts/PostLeaderboard';
import PostLink from 'components/posts/PostLink';
import PostMediaContent from 'components/posts/PostMediaContent';
import PostRecognition from 'components/posts/PostRecognition';
import PostSurvey from 'components/posts/PostSurvey';
import PostText from 'components/posts/PostText';
import { trackEvent } from 'src/utils/AnalyticUtils';

const TimelineCard = ({ feed, eventOnClick }) => {
  const { reloadFeedsId } = useReload();
  const params = useParams();
  const history = useHistory();
  const [seeMore, setSeeMore] = useState({ exist: false, isOpen: true });
  const [currentFeed, setCurrentFeed] = useState(cloneDeep(feed));
  const postType = currentFeed[setPostType(currentFeed.postTypeV2)];
  let groupDetail = feed?.group;

  const goToPost = (e) => {
    e.stopPropagation();
    if (
      location.pathname.includes('timeline') &&
      !location.pathname.includes(currentFeed?.id)
    ) {
      const path = params?.groupId
        ? `/timeline/${currentFeed.id}?source=${params?.groupId}`
        : `/timeline/${currentFeed.id}?source=homepage`;
      history.replace(path);
    }
  };

  const updateCurrentFeed = async () => {
    const { data } = await getPostById(currentFeed.id);
    if (data) setCurrentFeed(data);
  };

  const viewCurrentPost = async (isVisible) => {
    if (isVisible && !currentFeed?.viewed) {
      const { isSuccess } = await viewPost(currentFeed?.id);

      if (isSuccess) {
        const viewedPost = cloneDeep(currentFeed);
        viewedPost.viewed = true;
        viewedPost.viewersCount += 1;
        setCurrentFeed(viewedPost);
        const properties = {
          'post type': postType,
          'post id': viewedPost?.id,
          'group name': groupDetail.name,
          'group type': groupDetail.groupType,
          'first time': true
        };
        trackEvent({
          event: 'view post',
          eventProperties: properties,
          env: 'culture'
        });
      }
    }
  };

  useEffect(() => {
    if (history.location.pathname === `/timeline/${currentFeed.id}`) {
      setSeeMore({ exist: false, isOpen: true });
    }
  }, [history.location.pathname, currentFeed.id]);

  useEffect(() => {
    if (reloadFeedsId.id === currentFeed.id) {
      updateCurrentFeed();
    }
  }, [reloadFeedsId]);

  useEffect(() => {
    !isEqual(feed, currentFeed) && setCurrentFeed(feed);
  }, [feed]);

  return (
    <VisibilitySensor
      delayedCall
      onChange={(isVisible) => viewCurrentPost(isVisible)}
      active={!currentFeed.viewed}
      partialVisibility
      key={currentFeed?.id}
    >
      <div className="feeds-card" data-cy="post-card" key={currentFeed.id}>
        {(params?.groupId != currentFeed?.group?.id || params?.postId) && (
          <div className="px-[16px] typography-h100 text-n-800 border-bottom-n-400 pb-[12px] mb-[24px]">
            {getCultureLocale('Posted on')}{' '}
            <span className="typography-h200 text-base-600 ml-[4px]">
              {currentFeed.group.name}
            </span>
          </div>
        )}
        {currentFeed.labels?.length > 0 && (
          <PostLabel labels={currentFeed.labels} postId={currentFeed.id} />
        )}

        <PostHeader
          feed={currentFeed}
          goToPost={goToPost}
          eventOnClick={eventOnClick}
        />

        {currentFeed.adminPost &&
          (postType?.caption ||
            currentFeed.link ||
            currentFeed.postTypeV2 === 'media' ||
            currentFeed.postTypeV2 === 'multiple_media' ||
            currentFeed.postTypeV2 === 'poll') &&
          currentFeed.labels.map(
            (label, i) =>
              label.headerUrl !== null && (
                <div
                  className="official-header mb-[24px]"
                  key={i}
                  data-cy="ribbon-top"
                >
                  <AWSImage src={label.headerUrl} alt="Official Header" />
                </div>
              )
          )}

        {(currentFeed.postTypeV2 === 'media' ||
          currentFeed.postTypeV2 === 'multiple_media' ||
          (currentFeed.postTypeV2 === 'multiple_content' &&
            postType?.resources?.length > 0)) && (
          <PostMediaContent
            type={currentFeed.postTypeV2}
            content={postType}
            postId={currentFeed.id}
          />
        )}

        {currentFeed.postTypeV2 !== 'group_recognition' &&
          currentFeed.postTypeV2 !== 'recognition' &&
          currentFeed.postTypeV2 !== 'poll' &&
          currentFeed.postTypeV2 !== 'leaderboard' &&
          (feed[setPostType(currentFeed.postTypeV2)]?.caption ||
            currentFeed.taggedUsers) && (
            <PostText
              text={postType?.caption}
              taggedUsers={currentFeed.taggedUsers}
              postId={currentFeed.id}
              mentions={postType?.metaMentions}
              postType={currentFeed.postTypeV2}
              seeMore={seeMore}
              setSeeMore={setSeeMore}
              goToPost={goToPost}
              feed={currentFeed}
              attachedFile={postType?.files}
            />
          )}

        {currentFeed.postTypeV2 === 'link' && (
          <PostLink
            content={currentFeed[currentFeed.postTypeV2]}
            postId={currentFeed.id}
            postType={currentFeed.postTypeV2}
            groupDetail={groupDetail}
          />
        )}

        {currentFeed.adminPost &&
          (postType?.caption ||
            currentFeed.link ||
            currentFeed.postTypeV2 === 'media' ||
            currentFeed.postTypeV2 === 'multiple_media') &&
          seeMore.isOpen &&
          currentFeed.labels.map(
            (label, i) =>
              label.footerUrl !== null && (
                <div
                  className="text-right official-footer mt-[24px] mb-[24px]"
                  key={i}
                  data-cy="ribbon-bottom"
                >
                  <AWSImage src={label.footerUrl} alt="Official Footer" />
                </div>
              )
          )}

        {(currentFeed.postTypeV2 === 'recognition' ||
          currentFeed.postTypeV2 === 'group_recognition') && (
          <PostRecognition
            type={currentFeed.postTypeV2}
            content={postType}
            postId={currentFeed.id}
          />
        )}

        {(seeMore.exist ? seeMore.isOpen : true) &&
          (currentFeed.postTypeV2 === 'attachment' ||
            currentFeed.postTypeV2 === 'multiple_content') &&
          postType?.files && (
            <PostAttachment content={postType?.files} postId={currentFeed.id} />
          )}

        {currentFeed.postTypeV2 === 'feeling' && (
          <PostFeeling
            content={currentFeed[currentFeed.postTypeV2]}
            stickerUrl={
              currentFeed[currentFeed.postTypeV2]?.sticker
                ? currentFeed[currentFeed.postTypeV2]?.sticker.secureUrl
                : ''
            }
            postId={currentFeed.id}
            groupDetail={groupDetail}
          />
        )}

        {currentFeed.postTypeV2 === 'leaderboard' && (
          <PostLeaderboard
            content={currentFeed[currentFeed.postTypeV2]}
            postId={currentFeed.id}
          />
        )}

        {currentFeed.postTypeV2 === 'poll' && (
          <PostSurvey content={currentFeed} postId={currentFeed.id} />
        )}

        {currentFeed?.repostStatus?.reposted &&
          currentFeed?.repostStatus?.sourcePostId && (
            <a
              href={`/timeline/${currentFeed?.repostStatus?.sourcePostId}`}
              className="px-[24px] text-base-600"
            >
              {getCultureLocale('Original post by')}{' '}
              {currentFeed?.repostStatus?.sourcePostCreatorName}{' '}
              {getCultureLocale('from')}{' '}
              {currentFeed?.repostStatus?.sourceGroupAttributes?.name}
            </a>
          )}

        {currentFeed.postTypeV2 !== 'poll' && (
          <PostInfo
            views={currentFeed.viewersCount}
            likes={currentFeed.loversCount}
            comments={currentFeed.commentsCount}
            postId={currentFeed.id}
            edited={currentFeed.editStatus.edited}
          />
        )}
        {(currentFeed?.commentable ||
          (currentFeed?.postTypeV2 !== 'poll' &&
            currentFeed?.postTypeV2 !== 'feeling')) && (
          <PostComment currentFeed={currentFeed} groupDetail={groupDetail} />
        )}
      </div>
    </VisibilitySensor>
  );
};

export default TimelineCard;

import React, { createRef, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import flatten from 'lodash/flatten';

import {
  getActiveCycles,
  getAssignmentsCount
} from 'client/FormalReviewClient';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import {
  getDateLocale,
  getNumberLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Card from 'components/design-system/Card';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import ListEmptyState from 'components/shared/ListEmptyState';

const TeamFeedbacksSkeleton = () => {
  return (
    <Card
      customClass="mt-[16px] shadow-raised flex items-center"
      borderColor="border-n-300"
    >
      <div className="mr-[16px] w-[390px]">
        <Shimmer width="320px" height="20px" customClass="mb-[4px]" />
        <Shimmer width="240px" height="16px" />
      </div>
      <Shimmer width="72px" height="16px" customClass="mr-[16px]" />
      <Shimmer width="160px" height="32px" customClass="rounded-[4px]" />
    </Card>
  );
};

const TeamFeedbacks = ({ teamId }) => {
  const history = useHistory();
  const {
    user,
    config: {
      permissions: { reviewFeedbackSee }
    }
  } = useUser();
  const { addToast } = useToastContext();

  const [feedbacks, setFeedbacks] = useState([]);

  const getProgress = async (cycleId) => {
    const { data } = await getAssignmentsCount(cycleId);
    const { completeCount, totalCount } = data?.[0]?.assignmentProgress || {};
    return {
      completeCount,
      totalCount,
      progressCount: getNumberLocale((completeCount / totalCount) * 100 || 0)
    };
  };

  const goToReview = (e, name) => {
    e.stopPropagation();
    if (reviewFeedbackSee) {
      return history.push('/feedbacks', { q: name });
    }
    const pageLocale = getObjectiveLocale('Feedback page');
    addToast({
      title: getObjectiveLocale('Cannot view Team Feedback'),
      msg: getObjectiveLocale(
        `You are not allowed to access the ${pageLocale} page`
      ),
      type: 'error'
    });
  };

  const getFeedback = async (olderThan) => {
    const params = {
      limit: 10,
      involverId: user?.id,
      state: ['in_progress', 'done'],
      teamId: teamId,
      sortColumn: 'created_at',
      sortDirection: 'asc',
      assignmentState: ['in_progress', 'done', 'incomplete'],
      ...(olderThan && { olderThan: olderThan })
    };

    return await getActiveCycles(params);
  };

  const { data, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery(
    ['team', 'feedback', parseInt(teamId)],
    ({ pageParam }) => getFeedback(pageParam),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || undefined,
      staleTime: 5 * 60 * 1000
    }
  );

  const intersectTarget = createRef();
  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting && hasNextPage && fetchNextPage(),
    threshold: 0.5
  });

  const setData = async () => {
    const newFeedbacks = flatten(
      data?.pages?.map((page) => {
        return page?.data?.map((d) => {
          return d;
        });
      })
    );

    if (newFeedbacks) {
      let dataPromise = await Promise.all(
        newFeedbacks.map(async (dataItem) => {
          const progressData = await getProgress(dataItem?.id);
          return { ...dataItem, ...progressData };
        })
      );
      setFeedbacks(dataPromise);
    }
  };

  useEffect(() => {
    if (data) setData();
  }, [data]);

  return (
    <div className="h-[calc(100vh-170px)] overflow-y-auto pr-[40px]">
      {feedbacks?.map((feedback) => (
        <Card
          customClass="mt-[16px] shadow-raised flex items-center justify-between"
          borderColor="border-n-300"
          dataCy={`feedback-${feedback?.id}`}
          key={`feedback-${feedback?.id}`}
        >
          <div className="mr-[16px] w-[390px]">
            <p
              className="text-n-900 typography-h500 multiline-text-1"
              data-cy="feedback-name"
            >
              {feedback?.name}
            </p>
            <p
              className="text-n-800 typography-paragraph"
              data-cy="feedback-date"
            >
              {getDateLocale(feedback?.reviewedPeriodStartsAt, {
                month: 'short',
                day: '2-digit',
                year: '2-digit'
              })}{' '}
              -{' '}
              {getDateLocale(feedback?.reviewedPeriodEndsAt, {
                month: 'short',
                day: '2-digit',
                year: '2-digit'
              })}
            </p>
          </div>
          <div className="flex items-center">
            <div className="mr-[16px] w-[72px] text-right">
              <p
                className={`typography-h100 multiline-text-1 ${
                  feedback?.progressCount == 100
                    ? 'text-base-900'
                    : 'text-n-900 '
                }`}
                data-cy="feedback-progress"
              >
                {feedback?.progressCount}% ({feedback?.completeCount}/
                {feedback?.totalCount})
              </p>
            </div>
            {feedback?.progressCount == 100 ? (
              <Button.Secondary
                customClass="flex items-center w-[160px]"
                onClick={(e) => goToReview(e, feedback?.name)}
                datacy="view-review-result"
              >
                {getObjectiveLocale('View Review Result')}
              </Button.Secondary>
            ) : (
              <Button
                customClass="flex items-center w-[160px]"
                onClick={(e) => goToReview(e, feedback?.name)}
                datacy="view-review-result"
              >
                {getObjectiveLocale('View')}
              </Button>
            )}
          </div>
        </Card>
      ))}
      <div ref={intersectTarget} />
      {isFetching && <TeamFeedbacksSkeleton />}

      {feedbacks.length === 0 && !isFetching && (
        <ListEmptyState
          fullHeight
          emptyIcon="icon-no-active-performance-review"
          title="There’s currently no active feedback cycle"
          subtitle="Your feedback form will show up here once they are requested!"
        />
      )}
    </div>
  );
};

export default TeamFeedbacks;

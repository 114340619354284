import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { getTaskCountByPhase } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import useEditPhase from 'hooks/useEditPhase';
import useModalObjectives from 'hooks/useModalObjectives';
import useTasks from 'hooks/useTasks';
import { getObjectiveLocale } from 'utils/HelperUtils';

import BannerBox from 'components/shared/BannerBox';
import Checkbox from 'components/shared/Checkbox';
import Modal from 'components/shared/modal/Modal';
import { convertGroupNameToLowerCase } from 'src/utils/TasksHelper';

const ModalConfirmUpdatePhase = ({ closeModal, projectId, type }) => {
  const { showModal } = useModalObjectives();
  const {
    editedPhaseData,
    deletedPhaseIds,
    updatePhaseData,
    resetEditPhaseData,
    getListPhasesToBeRefetch
  } = useEditPhase();
  const { listGroups, setListGroups, updateTasksTwoLevels } = useTasks();
  const { invalidateQueries } = useRefetchQuery();

  const [excludeDeleteTasks, setExcludeDeleteTasks] = useState(true);
  const [countTask, setCountTask] = useState(0);
  const [isPrimaryBtnLoading, setIsPrimaryBtnLoading] = useState(false);

  const defaultPhaseName = editedPhaseData?.[0]?.name;

  const onCloseModal = () => {
    closeModal();
    showModal({ modalType: 'editPhases', props: { projectId } });
  };

  const getTaskCount = async () => {
    let temporaryCountData = 0;

    const promise = deletedPhaseIds?.map(async (phaseId) => {
      const { data } = await getTaskCountByPhase(projectId, phaseId);
      if (data) {
        temporaryCountData += data;
      }
    });

    await Promise.all(promise);
    setCountTask(temporaryCountData);
  };

  // Need to refetch task if user edited phase color
  const refetchObj = () => {
    if (type == 'list') {
      let listPhasesToBeRefetch = getListPhasesToBeRefetch();

      listGroups?.section?.map((section) => {
        listPhasesToBeRefetch.map((phase) => {
          updateTasksTwoLevels(section?.name, phase?.name?.toLowerCase(), []);
          invalidateQueries([
            'objectives',
            'mytasks',
            phase?.name?.toLowerCase(),
            section?.name?.toLowerCase()
          ]);
        });
      });
    }
  };

  const edit = async () => {
    setIsPrimaryBtnLoading(true);
    const { data } = await updatePhaseData(projectId, excludeDeleteTasks);
    if (data) {
      let tempListGroups = cloneDeep(listGroups);
      tempListGroups.phase = convertGroupNameToLowerCase(data);
      setListGroups(tempListGroups);
      refetchObj();
      resetEditPhaseData();
      closeModal();

      // need to refetch default phase because if users choose exlude delete tasks,
      // the tasks under deleted phase will be moved to default phase
      if (excludeDeleteTasks) {
        listGroups?.section?.map((section) => {
          invalidateQueries([
            'objectives',
            'mytasks',
            defaultPhaseName?.toLowerCase(),
            section?.name?.toLowerCase()
          ]);
        });
        invalidateQueries([
          'objectives',
          'mytasks',
          defaultPhaseName?.toLowerCase()
        ]);
      }

      invalidateQueries(['group', 'phase', 'projectDetail'], {
        refetchActive: false
      });
    }
    setIsPrimaryBtnLoading(false);
  };

  useEffect(() => {
    getTaskCount();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      className="max-w-[464px]"
      title="Update Phase"
      eventOnClickClose={onCloseModal}
      withPrimaryBtn={{
        title: 'Confirm',
        onClick: () => edit(),
        isLoading: isPrimaryBtnLoading,
        dataCy: 'confirm-button'
      }}
      withSecondaryBtn={{ title: 'Cancel', onClick: onCloseModal }}
      maxHeight={640}
      contentClass="overflow-hidden hover:overflow-overlay hover:overflow-auto"
      dataCyModal="confirm-update-phase-modal"
    >
      <p className="typography-paragraph text-n-800 mb-[24px]">
        {getObjectiveLocale(
          `Are you sure you want to update this phase? This action can’t bereversed.`
        )}
      </p>
      <BannerBox
        type="warning"
        customClass="mb-[24px] py-[8px]"
        paddingClass="px-[16px] py-[8px]"
        text={getObjectiveLocale(
          `By deleting the phase, [count] tasks will also be deleted`
        ).replace('[count]', countTask)}
      />
      <div className="flex items-center w-full">
        <Checkbox
          id="checkbox-exclude-delete-tasks"
          name="checkbox-exclude-delete-tasks"
          checked={excludeDeleteTasks}
          onChange={(e) => setExcludeDeleteTasks(e.target.checked)}
          labelClass="cursor-pointer w-full"
          customContainerClass="w-full"
        >
          <span className="ml-[8px] typography-paragraph text-n-900">
            {getObjectiveLocale(
              `Exclude and move [count] tasks to phase ${defaultPhaseName?.toUpperCase()}`
            ).replace('[count]', countTask)}
          </span>
        </Checkbox>
      </div>
    </Modal>
  );
};

export default ModalConfirmUpdatePhase;

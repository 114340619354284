import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {
  getObjectiveDetail,
  getProgressOvertimeData
} from 'client/ObjectivesClient';
import useScrollHeaderBorder from 'hooks/useScrollHeaderBorder';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import {
  SkeletonProgressOvertime,
  changeFormatData,
  generateX,
  seriesGenerator
} from 'utils/ProgressOvertimeHelper';

import HeaderPage from 'components/admin/HeaderPage';
import ModalUpdateProgress from 'components/modal/ModalUpdateProgress/ModalUpdateProgress';
import BreakdownProgressOvertime from 'components/objectives/progress-overtime/BreakdownProgressOvertime';
import ChartProgressOvertime from 'components/objectives/progress-overtime/ChartProgressOvertime';
import SummaryProgressOvertime from 'components/objectives/progress-overtime/SummaryProgressOvertime';
import ToastNotif from 'components/shared/ToastNotif';

const ProgressOvertime = ({ route: { title } }) => {
  const currentYear = new Date().getFullYear().toString();
  const history = useHistory();
  const params = useParams();
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(null);
  const [selectedDataPeriod, setSelectedDataPeriod] = useState(currentYear);
  const [objectiveData, setObjectiveData] = useState({});
  const [formattedProgressData, setFormattedProgressData] = useState({});
  const [breakdownData, setBreakdownData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [minimumProgress, setMinimumProgress] = useState(0);
  const buttonText = `Update ${
    objectiveData?.milestoneType && objectiveData?.milestoneType !== 'disabled'
      ? 'Milestone'
      : ''
  } Progress`;
  const objId = params?.id || params?.objectiveId;

  useScrollHeaderBorder();

  const headerProps = {
    titlePage: objectiveData?.name || title,
    isHeaderComposer: true,
    backToUrl: history?.location?.state?.prevPath || '/goals',
    noBorder: true,
    secondaryAction: {
      title: getObjectiveLocale(buttonText),
      icon: 'icon-bar_chart',
      onClick: () => setShowModal(true),
      dataCy: 'update-progress'
    }
  };

  const getObjective = async () => {
    const { data } = await getObjectiveDetail(objId);
    if (data) {
      setObjectiveData(data);
      const createdYear = new Date(data?.createdAt).getFullYear();
      setSelectedTimeFrame(
        data?.milestoneType !== 'disabled' ? data?.milestoneType : 'weekly'
      );
      setSelectedDataPeriod(
        (createdYear < currentYear ? createdYear : currentYear).toString()
      );
    }
    setIsLoading(false);
  };

  const displayNumber = (number, defaultValue = '--') => {
    return !isNil(number) ? getNumberLocale(number) : defaultValue;
  };

  const getProgressData = async () => {
    const { data, isSuccess } = await getProgressOvertimeData(objId, {
      timeFrame: selectedTimeFrame,
      year: selectedDataPeriod
    });
    if (data && isSuccess) {
      const achievementsList = { name: 'Achievement' };
      const baselinesList = { name: 'Baseline' };
      const targetsList = { name: 'Target' };
      const percentagesList = { name: '(%)' };

      setIsLoading(true);
      const changedData = changeFormatData(data, selectedTimeFrame);
      changedData.map((currData) => {
        const infoPLus = currData?.baseline > 0 ? '+' : '';
        const updatedLabel = generateX(currData, true, selectedTimeFrame);

        achievementsList[updatedLabel] = displayNumber(currData?.achievement);
        baselinesList[updatedLabel] =
          infoPLus + displayNumber(currData?.baseline);
        targetsList[updatedLabel] = displayNumber(currData?.target);
        percentagesList[updatedLabel] = displayNumber(currData?.percentage, 0);

        delete currData?.baseline;
      });
      setBreakdownData([
        achievementsList,
        baselinesList,
        targetsList,
        percentagesList
      ]);
      setFormattedProgressData(
        seriesGenerator(
          changedData,
          selectedTimeFrame,
          minimumProgress,
          null,
          setMinimumProgress
        )
      );
      setIsLoading(false);
    } else {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  };

  const saveCallback = () => {
    getObjective();
    getProgressData();
  };

  useEffect(() => {
    getObjective();
  }, []);

  useEffect(() => {
    setMinimumProgress(0);
    !isEmpty(selectedTimeFrame) && getProgressData();
  }, [selectedTimeFrame, selectedDataPeriod]);

  const componentProps = {
    selectedTimeFrame: selectedTimeFrame,
    setSelectedTimeFrame: setSelectedTimeFrame,
    selectedDataPeriod: selectedDataPeriod,
    setSelectedDataPeriod: setSelectedDataPeriod,
    progressData: formattedProgressData,
    objectiveData: objectiveData,
    minimumProgress: minimumProgress
  };

  return (
    <>
      <ToastNotif
        showNotif={showToast}
        message={getObjectiveLocale('Something went wrong...')}
        warning={true}
      />
      <HeaderPage {...headerProps} />
      {showModal && (
        <ModalUpdateProgress
          measurement={
            objectiveData?.measurement ? objectiveData?.measurement : null
          }
          withCloseIcon
          eventOnClick={() => setShowModal(false)}
          canUpdateCurrentValue={objectiveData?.permissions?.includes(
            'update_current_value'
          )}
          objectiveId={objectiveData?.id}
          setObjectiveDetail={setObjectiveData}
          parentId={objectiveData?.parent?.id}
          modalType={'update'}
          objectiveName={objectiveData?.name}
          commentOptions={objectiveData?.commentOptions}
          currentMilestone={objectiveData?.currentMilestone}
          milestoneType={objectiveData?.milestoneType}
          objectiveValue={objectiveData}
          saveCallback={saveCallback}
        />
      )}
      <div className="min-h-[calc(100vh-64px)] px-[0px]">
        {isLoading ||
        isEmpty(breakdownData) ||
        isEmpty(formattedProgressData) ? (
          <SkeletonProgressOvertime />
        ) : (
          <>
            <SummaryProgressOvertime
              measurement={objectiveData?.measurement}
              id={objId}
              saveCallback={saveCallback}
            />
            <ChartProgressOvertime {...componentProps} />
            <BreakdownProgressOvertime
              progressData={breakdownData}
              selectedTimeFrame={selectedTimeFrame}
              id={objId}
              selectedDataPeriod={selectedDataPeriod}
              objectiveData={objectiveData}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ProgressOvertime;

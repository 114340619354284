import React from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

import { UseAuthPage } from 'context/AuthPageContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';

const AuthResetAndActivasion = (props) => {
  const { handleOnChange } = UseAuthPage();
  const { organization, appType } = useUser();

  const appName =
    appType === 'culture'
      ? organization?.cultureAppName
      : organization?.appName;
  const isSuccessSent = props.isSuccessSent;
  const isTokenValid = props.isTokenValid;
  const match = props.match.path;

  let Title;
  let NotifTitle;
  let NotifSubtitle;
  switch (match) {
    case '/recovery':
      Title = getObjectiveLocale('Reset Password');
      NotifTitle = getObjectiveLocale("You're Set Now!");
      NotifSubtitle = getObjectiveLocale(
        'Please use your new password to login into Happy5!'
      );
      break;
    case '/activation':
      Title = getObjectiveLocale('Account Activation');
      NotifTitle = getObjectiveLocale('Congratulations!');
      NotifSubtitle = getObjectiveLocale(
        'Welcome to Happy5! Your account has already been activated'
      );
      break;
  }

  return (
    <React.Fragment>
      {!isSuccessSent && !isTokenValid && (
        <div className="auth-notif-body">
          <span className="auth-notif-title">{getObjectiveLocale('Oops')}</span>
          <span className="auth-notif-subtitle">
            {getObjectiveLocale(
              'Please request reset password from your device again because the token has expired. We are sorry for the inconvenience.'
            )}
          </span>
          <button
            className="footer-link-button auth-notif-button btn btn-primary"
            onClick={props.routeToOrganization}
          >
            {getObjectiveLocale('Back to login')}
          </button>
        </div>
      )}

      {!isSuccessSent && isTokenValid && (
        <React.Fragment>
          <span className="auth-title text-center w-[304px] typography-h400-longform">
            {appName || Title}
          </span>
          <span className="auth-text">
            {getObjectiveLocale(
              'Password must contain 8-30 character with at least one number and one uppercase character.'
            )}
          </span>
          <InputField
            htmlFor="password"
            label={getObjectiveLocale('New Password')}
            placeholder={getObjectiveLocale('New Password')}
            password
            handleChange={(e) => handleOnChange(e.target.name, e.target.value)}
            onKeyUp={props.handleKeyPress}
          />
          <InputField
            htmlFor="confirmPassword"
            label={getObjectiveLocale('Confirm Password')}
            placeholder={getObjectiveLocale('Confirm Password')}
            password
            handleChange={(e) => handleOnChange(e.target.name, e.target.value)}
            onKeyUp={props.handleKeyPress}
          />
          {props.showWarning && (
            <div className="container-error">
              <span className="error-message">{props.warningMessage}</span>
            </div>
          )}
        </React.Fragment>
      )}

      {isSuccessSent && isTokenValid && (
        <div className="auth-notif-body">
          <span className="auth-notif-title">{NotifTitle}</span>
          <span className="auth-notif-subtitle">{NotifSubtitle}</span>
          <div className="auth-notif-wrapper">
            <div className="auth-notif-label-wrapper">
              <span className="auth-notif-label">
                {getObjectiveLocale('YOUR ORGANIZATION NAME')}
              </span>
              <span className="auth-notif-label-title">
                {props?.data?.data?.organization?.identifier}
              </span>
            </div>
            <div className="auth-notif-label-wrapper">
              <span className="auth-notif-label">
                {getObjectiveLocale('YOUR SIGN-IN EMAIL')}
              </span>
              <span className="auth-notif-label-title">
                {props?.data?.data?.user?.email}
              </span>
            </div>
          </div>
          <button
            className="auth-notif-button btn btn-primary"
            onClick={props.routeToOrganization}
          >
            {getObjectiveLocale('Back to login')}
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

AuthResetAndActivasion.contextTypes = {
  handleChange: PropTypes.func
};

AuthResetAndActivasion.propTypes = {
  handleKeyUp: PropTypes.func,
  routeToOrganization: PropTypes.func,
  showWarning: PropTypes.bool,
  warningMessage: PropTypes.string,
  isSuccessSent: PropTypes.bool,
  match: PropTypes.object
};

export default withRouter(AuthResetAndActivasion);

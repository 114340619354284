import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isEmpty from 'lodash/isEmpty';

import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getObjectiveLeader } from 'utils/ObjectivesHelper';
import { getCurrentUserRole } from 'utils/TeamHelper';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';
import Badge from 'components/shared/Badge';
import GroupingCardLabel from 'components/shared/Label/GroupingCardLabel';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';

dayjs.extend(relativeTime);

const TribeCard = ({ team, setOpenModalCreate }) => {
  const [teamData, setTeamData] = useState(team);

  const {
    id,
    name,
    involvements,
    totalTeams,
    teamPicture,
    clanAndSquads,
    archivedAt
  } = teamData || {};

  const {
    user: currentUser,
    config: { permissions }
  } = useUser();
  const history = useHistory();

  const openTeamDetail = (e) => {
    e?.stopPropagation();
    history.push(`/teams/tribe/${id}`);
  };

  const leader = getObjectiveLeader(involvements, 'role');

  const getLabel = () => {
    const role =
      involvements?.length > 0 &&
      getCurrentUserRole(involvements, currentUser?.id);
    return [
      {
        useBadge: false,
        title: `As ${role}`,
        iconLabel: role === 'leader' ? 'leader' : 'member',
        isVisible: !isEmpty(role),
        dataCy: 'label-role'
      },
      {
        useBadge: false,
        title: `${totalTeams} squads`,
        iconLabel: 'team',
        isVisible: true,
        useMaxWidth: '!max-w-[80px]',
        dataCy: 'total-teams'
      }
    ].filter(({ isVisible }) => isVisible);
  };

  const listLabel = getLabel();

  const listMembers = involvements
    ?.filter(({ role }) => role != 'leader')
    ?.map((involvement) => ({
      name: involvement?.user?.name,
      src: involvement?.user?.profilePicture
    }));

  useEffect(() => {
    setTeamData(team);
  }, [team]);

  return (
    <div
      className="py-[16px] px-[16px] bg-n-000 shadow-raised rounded-[4px] cursor-pointer relative group flex flex-col h-[308px]"
      onClick={openTeamDetail}
      data-cy={`tribe-card-${id}`}
    >
      <div className="flex items-center">
        <AWSImage
          src={teamPicture || Config.asset.general.brokenImage}
          className="rounded-[4px] h-[56px] w-[56px] mr-[8px]"
        />
        <div
          className="pl-[4px] pr-[10px] py-[4px] rounded-[4px]"
          data-cy="tribe-name"
        >
          {!!archivedAt && (
            <Badge
              className="rounded-[4px] typography-h100 text-n-800 bg-n-300 absolute right-[16px]"
              content={getObjectiveLocale('Archived')}
            />
          )}

          <TruncateParagraph
            className="typography-h500 text-n-900"
            position="bottom"
            dataCy="tribe-name"
          >
            {name}
          </TruncateParagraph>
          <div className="mt-[10px] inline-flex gap-[8px] items-center">
            {listLabel.map((label, index) => (
              <GroupingCardLabel label={label} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="my-[16px] flex items-center justify-between">
        <div className="w-[calc(100%-84px)]" data-cy="leader-tribe">
          {leader && <SingleUser userData={leader} customClass="w-full" />}
        </div>
        {involvements?.length > 1 && (
          <AvatarGroup avatars={listMembers} size="24" max={3} />
        )}
      </div>
      <p className="typography-h100 text-n-800">
        {getObjectiveLocale('Squad')}
      </p>
      {clanAndSquads?.length == 0 && permissions?.squadCreate && (
        <Button.Tertiary
          customClass="h-[136px] mt-[8px] !bg-n-200"
          datacy="create-squad-intribe"
          onClick={() =>
            setOpenModalCreate({ show: open, type: 'squad', tribe: teamData })
          }
        >
          <SVGIcon iconName="icon-add" size="24" fillColor="var(--base-600)" />
          {getObjectiveLocale('New Squad')}
        </Button.Tertiary>
      )}
      {clanAndSquads?.slice(0, 2)?.map((team) => (
        <div
          className="px-[16px] py-[8px] bg-n-300 rounded-[4px] flex justify-between mt-[8px] w-[324px]"
          data-cy={`tribe-squad-${team?.id}`}
          key={`tribe-squad-${team?.id}`}
          onClick={(e) => {
            e?.stopPropagation();
            history.push(`/teams/squad/${team?.id}`);
          }}
        >
          <div className="w-[253px]">
            <TruncateParagraph className="typography-paragraph text-n-900">
              {team?.name}
            </TruncateParagraph>
          </div>
          <AvatarGroup avatars={[team?.leader]} size="24" max={1} />
        </div>
      ))}
      {clanAndSquads?.length > 2 && (
        <Button.Tertiary
          customClass="h-[40px] mt-[8px]"
          onClick={() => openTeamDetail()}
        >
          {getObjectiveLocale('See More')}
        </Button.Tertiary>
      )}
    </div>
  );
};

export default TribeCard;

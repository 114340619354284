import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import TooltipPortal from '../shared/Tooltips/TooltipPortal';
import './ScoreBox.scss';

const defaultProps = {
  option: {},
  showOption: 'score_and_label',
  onClick: () => {},
  selectedId: null,
  usePortal: '',
  showLabel: true
};

const ScoreBox = ({
  option,
  showOption,
  onClick,
  selectedId,
  usePortal,
  disabled,
  showLabel
}) => {
  const [portalData, setPortalData] = useState({});
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const setPortal = (e) => {
    setIsTooltipShown(true);
    setPortalData({
      name: option.name || option.option,
      left: e.currentTarget.getBoundingClientRect().x + 'px',
      top: e.currentTarget.getBoundingClientRect().top - 40 + 'px'
    });
  };

  return (
    <div
      className={`score-box ${
        disabled && selectedId !== option.id ? 'opacity-4' : ''
      }
      `}
      onMouseEnter={(e) => !disabled && usePortal && showLabel && setPortal(e)}
      onMouseLeave={() => !disabled && setIsTooltipShown(false)}
    >
      {showOption == 'score_and_label' && (
        <div
          data-cy={`score-box-${option.id}`}
          onClick={() => !disabled && onClick(option.id)}
          className={`wrapper-big-box ${
            selectedId == option.id ? 'active-score' : ''
          }
          ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <p className="number-score mb-[4px]">
            {option.score || option.value}
          </p>
          {showLabel && (
            <p className="text-information" data-cy="option-name">
              {option.name || option.option}
            </p>
          )}
        </div>
      )}

      {showOption == 'score_only' && (
        <div
          data-cy={`score-box-${option.id}`}
          onClick={() => !disabled && onClick(option.id)}
          className={`wrapper-small-box flex-[1] ${
            selectedId == option.id ? 'active-score' : ''
          }
          ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <p className="typography-h400-longform">{option.score}</p>
        </div>
      )}

      {showOption == 'label_only' && (
        <div
          data-cy={`score-box-${option.id}`}
          onClick={() => !disabled && onClick(option.id)}
          className={`wrapper-small-box ${
            selectedId == option.id ? 'active-score' : ''
          }
          ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <p className="typography-h400-longform" data-cy="option-name">
            {option.name}
          </p>
        </div>
      )}

      {!isEmpty(portalData?.name) && showOption != 'score_only' && (
        <TooltipPortal
          show={isTooltipShown}
          portalData={portalData}
          portalId={usePortal}
        />
      )}
    </div>
  );
};

ScoreBox.defaultProps = defaultProps;
export default ScoreBox;

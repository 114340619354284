import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';

import { UseAuthPage } from 'context/AuthPageContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

const AuthPassword = (props) => {
  const { organization, appType } = useUser();

  const appName =
    appType === 'culture'
      ? organization?.cultureAppName
      : organization?.appName;

  const [isShowPasswordValue, setIsShowPasswordValue] = useState(false);
  const { handleOnChange } = UseAuthPage();
  const typeInputField = isShowPasswordValue ? 'text' : 'password';

  useEffect(() => {
    let input = document.getElementById('password');
    input.focus();
  }, []);

  return (
    <React.Fragment>
      <span className="auth-title text-center w-[304px] typography-h400-longform">
        {appName || getObjectiveLocale('Password')}
      </span>
      <span className="auth-text" data-cy="input-login-data">
        {getObjectiveLocale('Please enter password to login to your account.')}
      </span>
      <div className="container-input">
        <label className="styled-label" htmlFor={'password'}>
          {getObjectiveLocale('Password')}
        </label>
        <input
          className={`styled-input ${
            props.errorShake ? 'input-error-shake' : ''
          }`}
          name={'password'}
          id={'password'}
          data-cy={'auth-password'}
          type={typeInputField}
          onChange={(e) => handleOnChange(e.target.name, e.target.value)}
          onKeyUp={props.handleKeyPress}
          placeholder={getObjectiveLocale('Enter your password')}
        />
        <SVGIcon
          iconName={
            isShowPasswordValue
              ? 'icon-remove_red_eye'
              : 'icon-disabled_red_eye'
          }
          size={isShowPasswordValue ? '20' : '24'}
          fillColor="var(--n-600)"
          customClass={`styled-icon items-center justify-center ${
            props.errorShake ? 'input-error-shake-icon' : ''
          }`}
          onClick={() => setIsShowPasswordValue(!isShowPasswordValue)}
        />
      </div>
      {props.showWarning && (
        <div className="container-error">
          <span data-cy="wrong-login-data" className="error-message">
            {props.warningMessage}
          </span>
        </div>
      )}
      {props.data.resetPasswordAllowed && (
        <div className="auth-forgot-container">
          <span className="auth-forgot-text">
            {getObjectiveLocale('First time here or forgot your password?')}
          </span>
          <span
            data-cy="forgot-link"
            className="auth-forgot-link"
            onClick={props.routeToRecovery}
          >
            {getObjectiveLocale('Click here')}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

AuthPassword.contextTypes = {
  handleChange: PropTypes.func
};

AuthPassword.propTypes = {
  handleKeyPress: PropTypes.func,
  routeToRecovery: PropTypes.func,
  showWarning: PropTypes.bool,
  warningMessage: PropTypes.string
};

export default withRouter(AuthPassword);

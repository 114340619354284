import React, { useState } from 'react';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import Modal from 'components/shared/modal/Modal';

const WeightSettingsObjDeleteModal = ({
  dataType,
  modalDeleteId,
  setModalDeleteId,
  deleteGoalWeight,
  setDataType
}) => {
  const dataTypeOptions = dataType.filter((data) => data.id != modalDeleteId);

  const [selectedType, setSelectedType] = useState(dataTypeOptions[0]);

  const confirmDelete = async () => {
    const payload = {
      objectiveCategoryId: selectedType.id
    };
    const { isSuccess } = await deleteGoalWeight(modalDeleteId, payload);
    if (isSuccess) {
      setDataType((prevData) =>
        prevData.filter((data) => data.id != modalDeleteId)
      );
      setModalDeleteId(null);
    }
  };

  return (
    <Modal
      title={`Delete Goal Type Test`}
      withCloseIcon={false}
      headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
      className="admin-page w-[400px] min-h-[250px]"
      description={
        'All goals with deleted goal type will not have type anymore. Set a new goal type for those goals:'
      }
      withPrimaryBtn={{
        danger: true,
        title: 'Delete',
        dataCy: 'delete-goal',
        disabled: dataType.length <= 2,
        onClick: confirmDelete
      }}
      withSecondaryBtn={{
        title: 'Cancel',
        dataCy: 'cancel-delete-type',
        onClick: () => setModalDeleteId(null)
      }}
      headerClass="h-[72px]"
      footerClass="h-[84px]"
      dataCyModal="delete-goal-modal"
    >
      <Dropdown customClass="w-full">
        <Dropdown.Trigger customClass="w-full" dataCy="dropdown-color">
          <Trigger
            icon={false}
            topLabel={false}
            label={false}
            text={selectedType?.name}
            customWrapperClass="w-full"
          />
        </Dropdown.Trigger>
        <Dropdown.MenuItems customClass="w-full">
          {dataTypeOptions.map((type, index) => (
            <Dropdown.MenuItem
              key={index}
              onClick={() => setSelectedType(type)}
            >
              {type.name}
            </Dropdown.MenuItem>
          ))}
        </Dropdown.MenuItems>
      </Dropdown>
    </Modal>
  );
};

export default WeightSettingsObjDeleteModal;

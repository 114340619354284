import React from 'react';

import { useFormalReview } from 'context/FormalReviewContext.js';

import './BehaviorScoringEach.scss';
import HeaderWorkScore from './HeaderWorkScore';
import ObjectScoring from './ObjectScoring';

const BehaviorScoringEach = ({
  showOption,
  average,
  phase,
  objects,
  setOverlaySidebar,
  template,
  components,
  seeBehaviour,
  answers,
  actor,
  currentTrack,
  currentTrackId,
  preFill,
  suggestions,
  view
}) => {
  const [_, { getSingleTrack }] = useFormalReview();
  const { trackConfig, trackType } = getSingleTrack(currentTrackId);
  const { type } = phase;
  const { name, useComment } = template;

  return (
    <div className="value-scoring-annual-review-container">
      <HeaderWorkScore
        title={name}
        totalNilai={average}
        trackType={trackType}
      />

      <div className="value-scoring-body-container">
        {objects &&
          objects.map((object, idx) => (
            <ObjectScoring
              key={object.id}
              idx={idx}
              title={object.name}
              id={object.id}
              description={object.description}
              object={object}
              setOverlaySidebar={setOverlaySidebar}
              components={components}
              level={object.expectedLevel}
              seeBehaviour={seeBehaviour}
              showOption={showOption}
              answers={answers}
              preFill={preFill}
              suggestions={suggestions}
              currentTrack={currentTrack}
              type={type}
              actor={actor}
              useComment={useComment}
              view={view}
              phaseType={type}
              suggestionSource={trackConfig?.suggestionSource}
            />
          ))}
      </div>
    </div>
  );
};

export default BehaviorScoringEach;

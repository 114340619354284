import React, { useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

import { useGoalWeightSettingsContext } from 'context/GoalWeightSettingContext';

import Table from 'components/design-system/table/Table';
import RadioButton from 'components/shared/RadioButton';
import SVGIcon from 'components/shared/SVGIcon';

import {
  CategoryColorSwitch,
  CategoryInputName,
  CategoryInputWeight,
  CategoryNumberCell
} from './tableCells/TableCells';

let _debounced = debounce((action) => action(), 1000);

const WeightSettingsObjCategoriesTableRow = ({
  useDefault,
  data,
  index,
  setDataType,
  updateGoalWeight,
  defaultType,
  setDefaultType,
  setModalDeleteId,
  addGoalWeight,
  refetch
}) => {
  const { isReadOnly, setIsSaving } = useGoalWeightSettingsContext();

  const [rowData, setRowData] = useState(data);
  const [errorMessage, setErrorMessage] = useState(null);

  const addGoalHandler = async (data) => {
    let payload = { ...data };
    delete payload?.id;
    const { isSuccess } = await addGoalWeight([payload]);
    if (isSuccess) refetch();
  };

  const updateData = (data) => {
    _debounced(async () => {
      const isCreate = data?.id.toString().indexOf('add') > -1;
      const name = data.name || '';
      if (name != '' && name.length > 2) {
        if (isCreate) {
          await addGoalHandler(data);
        } else {
          await updateGoalWeight([data]);
        }
        setIsSaving(true);
      }
    });
  };

  const changeDataObject = async (incomingData) => {
    let payload = { ...rowData, ...incomingData };
    delete payload.isDefault;
    setRowData(payload);
    updateData(payload);
  };

  const toggleChangeDefault = async () => {
    if (rowData?.id === defaultType?.id) return;
    let payload = [];
    let nextDefault = { ...rowData, isDefault: true };
    delete nextDefault.isLocked;
    if (defaultType) {
      let prevDefault = { ...defaultType, isDefault: false };
      delete prevDefault.isLocked;
      payload = [prevDefault, nextDefault];
    } else {
      payload = [nextDefault];
    }
    setDataType((prevData) => {
      let cloneData = prevData.map((data) => ({
        ...data,
        isDefault: data?.id == nextDefault?.id
      }));
      return cloneData;
    });
    setDefaultType(nextDefault);
    await updateGoalWeight(payload);
    setIsSaving(true);
  };

  useEffect(() => {
    setRowData(data);
  }, [data, setRowData]);

  if (useDefault) {
    return (
      <Table.Row heightClass={`h-[48px] ${errorMessage ? 'mb-[8px]' : ''}`}>
        <Table.Column noPadding key={`col-0-${rowData?.id}`}>
          <CategoryNumberCell value={index + 1} />
        </Table.Column>
        <Table.Column noPadding key={`col-1-${rowData?.id}`}>
          <CategoryInputName
            defaultValue={rowData.name}
            changeDataObject={changeDataObject}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            id={rowData?.id}
          />
        </Table.Column>
        <Table.Column noPadding key={`col-2-${rowData?.id}`}>
          <CategoryInputWeight
            defaultValue={rowData.weight}
            id={rowData?.id}
            weightLocked={rowData.isLocked}
            changeDataObject={changeDataObject}
          />
        </Table.Column>
        <Table.Column noPadding key={`col-3-${rowData?.id}`}>
          <CategoryColorSwitch
            defaultValue={rowData.colorHex}
            id={rowData?.id}
            changeDataObject={changeDataObject}
          />
        </Table.Column>
        <Table.Column
          noPadding
          key={`col-4-${rowData?.id}`}
          customClass="ml-[8px]"
        >
          <RadioButton
            dataCy={`default-type-${rowData?.id}`}
            size="small"
            value={rowData?.id}
            id={rowData?.id}
            checked={rowData?.id === defaultType?.id}
            onChange={toggleChangeDefault}
            addClass="h-[24px]"
            disabled={isReadOnly}
          />
        </Table.Column>
        <Table.Column noPadding key={`col-5-${rowData?.id}`}>
          <SVGIcon
            size="24"
            fillColor="var(--n-600)"
            iconName="icon-delete"
            onClick={() => setModalDeleteId(rowData?.id)}
            dataCy={'delete-weight-' + rowData?.id}
            disabled={isReadOnly}
          />
        </Table.Column>
      </Table.Row>
    );
  }
  return (
    <Table.Row heightClass={`h-[48px] ${errorMessage ? 'mb-[8px]' : ''}`}>
      <Table.Column noPadding key={`col-0-${rowData?.id}`}>
        <CategoryNumberCell value={index + 1} />
      </Table.Column>
      <Table.Column noPadding key={`col-1-${rowData?.id}`}>
        <CategoryInputName
          defaultValue={rowData.name}
          changeDataObject={changeDataObject}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          id={rowData?.id}
        />
      </Table.Column>
      <Table.Column noPadding key={`col-2-${rowData?.id}`}>
        <CategoryInputWeight
          defaultValue={rowData.weight}
          id={rowData?.id}
          weightLocked={rowData.isLocked}
          changeDataObject={changeDataObject}
        />
      </Table.Column>
      <Table.Column noPadding key={`col-3-${rowData?.id}`}>
        <CategoryColorSwitch
          defaultValue={rowData.colorHex}
          id={rowData?.id}
          changeDataObject={changeDataObject}
        />
      </Table.Column>
      <Table.Column noPadding key={`col-5-${rowData?.id}`}>
        <SVGIcon
          size="24"
          fillColor="var(--n-600)"
          iconName="icon-delete"
          onClick={() => setModalDeleteId(rowData?.id)}
          dataCy={'delete-weight' + rowData?.id}
          disabled={isReadOnly}
        />
      </Table.Column>
    </Table.Row>
  );
};

export default WeightSettingsObjCategoriesTableRow;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';

import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { getObjectiveLocale } from 'utils/HelperUtils';
import {
  getObjectiveSummaryPdf,
  getObjectiveSummaryPdfToast,
  restructureFilter
} from 'utils/ObjectivesHelper';
import { linkToCreateObjectives } from 'utils/objectiveUtils';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import Objectives from 'components/objectives/Objectives';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import StatistikOverallViewProgress from 'pages/dashboardOverallViewProgress/StatistikOverallViewProgress';
import StatisticsOverallViewProgressCompact from 'pages/dashboardOverallViewProgress/compactStatistics/StatisticsOverallViewProgressCompact';
import { getApprovalAvailability } from 'src/client/ApprovalClient';
import { getObjectivesCount } from 'src/client/ObjectivesClient';

import ApprovalPanel from '../components/approval/ApprovalPanel';
import useFilter from '../hooks/useFilter';
import ErrorBoundary from './ErrorBoundary';

function MyGoals({ route: { title } }) {
  const { match } = useUrl();
  const { user, config } = useUser();
  const isUsingApproval = config.permissions?.approvalSee;
  const [approvalRequest, setApprovalRequest] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [gridTemplate, setGridTemplate] = useState('');
  const [isInlineCreate, setInlineCreate] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const { addToast } = useToastContext();

  const history = useHistory();
  const { location } = history;
  const [onBoardStatus, setOnBoardStatus] = useState(
    history.action == 'PUSH' && location?.state?.onboardState
  );
  const { showModalValidationCreateGoal, checkValidationCreateGoal } =
    useValidationMinMax();
  document.getElementsByTagName('body')[0].classList = 'scroll-locked';

  const { newObjectiveLayout } = config;

  const filterBefore = useFilter((state) => state.filterById?.myGoalsFilter);
  const setFilter = useFilter((state) => state.setFilterById);

  const openCreateInline = async () => {
    setInlineCreate(true);
    const content = document.getElementsByClassName('main-body')[0];
    content.scrollTop = 0;
  };

  const onClickCreateNewGoal = (e) => {
    if (newObjectiveLayout) {
      if (filterBefore?.group && filterBefore?.group != 'no-group') {
        setFilter('myGoalsFilter', {
          ...filterBefore,
          group: 'no-group'
        });
        addToast({
          title: 'Filter',
          msg: 'Filter group has been reset',
          type: 'info'
        });
      }
      linkToCreateObjectives(
        e,
        history,
        checkValidationCreateGoal,
        openCreateInline
      );
    } else {
      return linkToCreateObjectives(e, history, checkValidationCreateGoal);
    }
  };

  let extraFilter = {
    type: ['annual_goal', 'goal'],
    parentNotAssignedTo: user.id,
    placementId: user.placementId
  };
  let filter = {
    phase: config?.defaultFilterPhase,
    owner: {
      assignee: [{ user, userId: user.id }]
    }
  };

  const _getApprovalAvailability = async () => {
    setIsLoading(true);
    const { data } = await getApprovalAvailability();
    setApprovalRequest(data);
    setIsLoading(false);
  };

  const getCount = () => {
    let newFilter = restructureFilter({ ...filterBefore, ...filter });

    newFilter.assigneeId = [parseInt(user.id)];
    return getObjectivesCount(newFilter);
  };

  let { data: progressData, refetch: refetchTotalObjective } = useQuery(
    ['overallProgressMyGoals'],
    getCount
  );

  const handleClickExportSummary = async () => {
    setIsExportLoading(true);
    addToast(getObjectiveSummaryPdfToast(user, user));
    await getObjectiveSummaryPdf({
      ownerId: user?.id,
      recipientId: user?.id,
      filter: filterBefore,
      extraFilter
    });
    setIsExportLoading(false);
  };

  useEffect(() => {
    refetchTotalObjective();
  }, [filterBefore]);

  useEffect(() => {
    let template;
    if (newObjectiveLayout) {
      template = ['analytics', 'filter', 'objectives'];
    } else {
      template = ['space', 'analytics', 'filter', 'objectives'];
    }
    if (isUsingApproval && approvalRequest?.analytics?.length > 0) {
      template.splice(1, 0, 'approval');
    }
    setGridTemplate("'" + template.join("' '") + "'");
  }, [newObjectiveLayout, approvalRequest]);

  useEffect(() => {
    isUsingApproval && _getApprovalAvailability();
  }, []);

  return (
    <>
      <HeaderPage
        titlePage={title}
        primaryAction={
          config.permissions?.goalCreate &&
          progressData?.data?.totalObjectives > 0 && {
            title: getObjectiveLocale('Create Objective'),
            icon: 'icon-add',
            onClick: onClickCreateNewGoal,
            dataCy: 'create-new-goal'
          }
        }
        secondaryAction={
          config?.exportObjectivesSummaryFeature &&
          config?.permissions?.goalSummaryExport &&
          progressData?.data?.totalObjectives > 0 && {
            title: getObjectiveLocale('Export Summary'),
            icon: 'icon-file_download',
            onClick: handleClickExportSummary,
            isLoading: isExportLoading,
            dataCy: 'export-summary'
          }
        }
        noBorder={newObjectiveLayout ? true : false}
      />
      {showModalValidationCreateGoal}
      <div
        className={`grid mb-[64px] ${
          newObjectiveLayout
            ? 'compact-main px-[0px]  gap-[24px]'
            : ' gap-[24px]'
        }`}
        style={{ gridTemplateAreas: gridTemplate }}
      >
        {!newObjectiveLayout && <div style={{ gridArea: 'space' }}></div>}
        <AdvancedFilterObjectives
          filterId="myGoalsFilter"
          filterOptions={[
            'search',
            'group',
            'sort',
            'dateFilter',
            'menuFilter'
          ]}
          gridArea
          useConfig={true}
          group={config?.myGoalsGroupByOptions?.[0] || 'no-group'}
          defaultFilter={{ ...filterBefore, ...filter }}
          defaultFilterToReset={filter}
          restructureFilter={(filter) => {
            if (!filter?.owner?.assignee?.length) {
              let newOwner = { ...filter.owner };
              newOwner.assignee = [{ ...user, userId: user.id }];
              return { ...filter, owner: newOwner };
            }
            return filter;
          }}
          page="myGoals"
        >
          {newObjectiveLayout ? (
            <StatisticsOverallViewProgressCompact
              visibility={['progress', 'status']}
              page={'mygoals'}
              annual
              gridArea
            >
              <Objectives
                cardModel={'goal-list'}
                page={'mygoals'}
                extraFilter={extraFilter}
                gridArea
                isInlineCreate={isInlineCreate}
                setInlineCreate={setInlineCreate}
                emptyStateAction={onClickCreateNewGoal}
                onBoardStatus={onBoardStatus}
                setOnBoardStatus={setOnBoardStatus}
              />
            </StatisticsOverallViewProgressCompact>
          ) : (
            <StatistikOverallViewProgress
              visibility={['progress', 'status']}
              annual
              gridArea
            >
              <Objectives
                cardModel={'goal-list'}
                page={'mygoals'}
                extraFilter={extraFilter}
                gridArea
                withMarginTop
              />
            </StatistikOverallViewProgress>
          )}
        </AdvancedFilterObjectives>
        {isUsingApproval && !isLoading && (
          <div
            style={
              approvalRequest?.analytics?.length > 0
                ? { gridArea: 'approval' }
                : {}
            }
          >
            <ErrorBoundary>
              <ApprovalPanel
                user={user}
                mode="employee"
                approvalRequest={approvalRequest}
                clearApprovalRequest={() => setApprovalRequest({})}
                pageObjective={'mygoals'}
                customClass={`${newObjectiveLayout ? 'my-[0px]' : ''}`}
              />
            </ErrorBoundary>
          </div>
        )}
      </div>

      <React.Suspense fallback={<div></div>}>
        {match && <SidebarMyGoals />}
      </React.Suspense>
    </>
  );
}

export default MyGoals;

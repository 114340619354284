import { useEffect, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import flatten from 'lodash/flatten';

import { useDeepEffect } from 'src/utils/useDeepEffect';

const useItemLists = ({
  queryOptions,
  targetPaginateId,
  isLoaded,
  setLoaded,
  targetAppear
}) => {
  const [hasMore, setHasMore] = useState(false);
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [tasks, setTasks] = useState([]);
  const queryClient = useQueryClient();

  const targetAppearElement = targetAppear
    ? targetAppear.current
    : document.getElementById('side-group');

  const { queryParams, fetchQueryFn, queryKey, getSingleQueryKey } =
    queryOptions;
  const { isFetching, fetchNextPage, refetch, data, isSuccess } =
    useInfiniteQuery(
      queryKey,
      ({ pageParam }) => fetchQueryFn(pageParam, queryParams),
      {
        cacheTime: 60 * 1000,
        staleTime: 60 * 1000,
        enabled: typeof targetAppear == 'undefined' ? true : queryEnabled,
        refetchOnMount: false,
        getNextPageParam: (lastPage) => {
          return lastPage?.pagination?.next?.olderThan || undefined;
        }
      }
    );

  const hasMoreHandler = (newTasks) => {
    const { pagination, data } = newTasks;
    setHasMore(data.length == 10 && pagination?.next?.olderThan);
  };

  const initializeObjective = (objectives) => {
    objectives &&
      objectives.map((obj) => {
        queryClient.setQueryData(getSingleQueryKey(obj.id), obj);
      });
  };

  useEffect(() => {
    if (data && isSuccess) {
      const newTasks = flatten(
        data?.pages?.map((page) => {
          return page?.data;
        })
      ).filter((data) => data);
      setTasks(newTasks);
      setLoaded && setLoaded(true);

      const latestData = data.pages[data.pages.length - 1];
      initializeObjective(latestData?.data);
      hasMoreHandler(latestData);
    }
    // setTask is dependant on another section too and would make infinite loop if include it in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess, setHasMore]);

  useEffect(() => {
    // PAGINATE TO NEXT PAGE WHEN ENDS TASK OF SECTION APPEAR IN SCROLL VIEW
    const target = document.getElementById(targetPaginateId);
    const el = document.getElementById('main-scroll');
    if (target && el && isLoaded) {
      let options = {
        root: el,
        rootMargin: '10px',
        threshold: 1.0
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && hasMore) {
            fetchNextPage();
          }
        });
      }, options);
      observer.observe(target);
      return () => {
        observer.disconnect();
      };
    }
  }, [isLoaded, fetchNextPage, hasMore, targetPaginateId, tasks]);

  useEffect(() => {
    if (!targetAppearElement) return;
    // FETCH ONLY WHEN SECTION TITLE ELEMENT IS APPEAR IN SCROLL VIEW
    const target = targetAppearElement;
    const el = document.getElementById('main-scroll');
    if (target && el) {
      let options = {
        root: el,
        rootMargin: '0px',
        threshold: 1.0
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            (!isLoaded || (isLoaded && !queryEnabled))
          ) {
            setQueryEnabled(true);
            if (tasks.length != 0) {
              setLoaded && setLoaded(true);
            }
          }
        });
      }, options);
      observer.observe(target);
      return () => {
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoaded,
    refetch,
    setQueryEnabled,
    targetAppearElement,
    setLoaded,
    tasks
  ]);

  useDeepEffect(() => {
    if (!isLoaded) return;
    setLoaded && setLoaded(false);
    setTasks([]);
  }, [queryParams]);

  return {
    isFetching,
    fetchNextPage,
    refetch,
    data: tasks,
    isSuccess
  };
};

export { useItemLists };

import React from 'react';

import LoadingSplashScreen from 'components/shared/LoadingSplashScreen';

import { AuthProvider } from './AuthContext';
import { ReloadProvider } from './ReloadContext';
import { UserProvider } from './UserContext';

function AppProviders({ children }) {
  return (
    <React.Suspense fallback={<LoadingSplashScreen />}>
      <AuthProvider>
        <ReloadProvider>
          <UserProvider>{children}</UserProvider>
        </ReloadProvider>
      </AuthProvider>
    </React.Suspense>
  );
}

export default AppProviders;

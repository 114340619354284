import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import useScroll from 'hooks/useScroll';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getSelectedOwner } from 'utils/ObjectivesHelper';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';
import InlineDialogSelectMultipleUser from 'components/inline-dialog/InlineDialogSelectMultipleUser';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import MenuOutsideClickWrapper from 'components/objectives/compact-objective/fragments/MenuOutsideClickWrapper';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import { getNewInvolvements } from 'components/sidebar/sidebar-create/SidebarCreateHelper';

const Assignee = React.memo(({ task, type = 'list', isProject }) => {
  const { config, user } = useUser();
  const { params } = useRouteMatch();

  const { refetchObjective, invalidateTaskQueries } = useRefetchQuery();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { id, name } = task;

  const { taskInvolvementsOwnerAssign } = config.permissions;

  const selectedOwner = getSelectedOwner(task) || [];

  const users =
    selectedOwner.map(({ user }) => {
      return {
        type: 'circle',
        name: user.name,
        src: user.profilePicture,
        tooltip: true
      };
    }) || [];

  const handleChangeDialog = async (involvements, type) => {
    const newSelected = involvements[involvements.length - 1];
    let newInvolvements = getNewInvolvements(
      involvements,
      type,
      false,
      task,
      params
    );
    if (
      newInvolvements.some(
        (involvement) =>
          involvement?.id === newSelected?.id &&
          involvement?.role === 'follower'
      )
    ) {
      const index = newInvolvements.findIndex(
        (involvement) =>
          involvement?.id === newSelected?.id &&
          involvement?.role === 'follower'
      );
      newInvolvements.splice(index, 1);
    }
    updateAssignee(newInvolvements);
  };

  const updateAssignee = async (newInvolvements) => {
    newInvolvements = newInvolvements?.map((involvement) => ({
      userId: involvement?.user?.id,
      role: involvement?.role
    }));

    const { isSuccess } = await editObjective(id, {
      involvements: newInvolvements
    });
    if (isSuccess) {
      refetchObjective(id);
      invalidateTaskQueries({ task });
    }
  };

  const openDropdown = (e) => {
    !e.shiftKey && !(e.ctrlKey || e.metaKey) && setIsDialogOpen(true);
  };

  useScroll({
    id: 'my-task-section',
    condition: isDialogOpen,
    eventScroll: () => setIsDialogOpen(false)
  });

  return (
    <div
      className={`relative ${type === 'list' ? 'w-full h-full ' : ''}`}
      onClick={(e) => e.stopPropagation()}
    >
      <MenuOutsideClickWrapper
        customClass={`${type === 'list' ? 'w-full' : 'w-[184px]'} h-full`}
        event={() => setIsDialogOpen(false)}
      >
        {type === 'list' && (
          <div
            className="w-full h-full flex items-center px-[16px]"
            onClick={(e) => openDropdown(e)}
            data-cy="trigger-assignee"
          >
            {users.length === 1 ? (
              <SingleUser userData={users[0]} size={24} customClass="w-full" />
            ) : (
              users.length > 0 && (
                <AvatarGroup
                  avatars={users}
                  size="24"
                  position="bottom"
                  max={4}
                />
              )
            )}
          </div>
        )}
        {type === 'board' &&
          (users.length === 0 ? (
            isProject ? (
              <>
                {task?.id != -1 && (
                  <TooltipContainer
                    show={name?.length > 0}
                    text={getObjectiveLocale('Add assignee')}
                    useMaxWidth={false}
                    classContainer="w-min"
                  >
                    <SVGIcon
                      iconName="icon-person_add"
                      size="24"
                      fillColor="var(--n-600)"
                      customClass={`${
                        name === '' ? 'cursor-default' : 'cursor-pointer'
                      } ${
                        isDialogOpen
                          ? 'visible'
                          : 'invisible group-hover:visible'
                      }`}
                      onClick={(e) => openDropdown(e)}
                    />
                  </TooltipContainer>
                )}
              </>
            ) : (
              <SingleUser
                userData={user}
                size={24}
                onClick={(e) => name && openDropdown(e)}
                dataCy="trigger-assignee"
              />
            )
          ) : (
            <div
              className="cursor-pointer"
              onClick={(e) => openDropdown(e)}
              data-cy="trigger-assignee"
            >
              {users.length === 1 ? (
                <SingleUser
                  userData={users[0]}
                  size={24}
                  customTextClass="typography-h100 text-n-800"
                />
              ) : (
                <AvatarGroup avatars={users} max={4} />
              )}
            </div>
          ))}

        {isDialogOpen && (
          <div className="absolute top-0 left-0 w-full h-full" portal>
            <InlineDialogSelectMultipleUser
              selectedValue={selectedOwner}
              permission={taskInvolvementsOwnerAssign}
              objectiveValue={task}
              type="assignee"
              role="assignee"
              header="Assignee"
              handleChange={handleChangeDialog}
              position="right"
              dialogWidth="w-[315px]"
              triggerWrapperClass="w-full h-full"
              dialogWrapperClass="w-full h-full"
              useTrigger={false}
              additionalQuery={{ taskId: task?.id }}
              useFloating={true}
            />
          </div>
        )}
      </MenuOutsideClickWrapper>
    </div>
  );
});

Assignee.displayName = 'Assignee';

export default Assignee;

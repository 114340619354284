import React from 'react';

import dayjs from 'dayjs';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import {
  whichBgColorClassName,
  whichColor,
  whichStatus
} from 'utils/ObjectivesHelper';

import SVGIcon from 'components/shared/SVGIcon';

import DueDateObjective from './fragments/DueDateObjective';
import MetricsObjective from './fragments/MetricsObjective';
import PhaseObjective from './fragments/PhaseObjective';
import ProgressObjective from './fragments/ProgressObjective';
import TitleObjective from './fragments/TitleObjective';

const CompactObjectiveBody = ({
  objective,
  clusters,
  isHover,
  hideDueDate,
  smallerTitle,
  cardWidth,
  showStateIcon = false,
  isReadOnly
}) => {
  const nowDate = new dayjs();
  const {
    config: { taskManagementFeature: useTaskManagementFeature }
  } = useUser();

  const REVIEWED_STATE = 'reviewed';
  const COMPLETED_STATE = 'completed';

  const {
    isCompanyGoal,
    isDirectorateGoal,
    isDepartmentGoal,
    isDivisionGoal,
    name,
    measurement,
    stateObject,
    overdue,
    calculationType,
    isProject,
    startDate,
    dueDate,
    progressUpdated,
    id,
    permissions,
    type,
    integrations,
    isHighlighted,
    milestoneMode,
    currentMilestone,
    milestoneType
  } = objective || [];

  const isTask = type === 'task';

  const { state } = stateObject || [];

  const { progressPercentage, progressColorHex, expectedProgressPercentage } =
    measurement || [];

  const metricWording =
    measurement === null
      ? isTask && useTaskManagementFeature
        ? 'No metrics'
        : 'No metrics and progress'
      : 'Metric & progress not visible';

  const tooltipProgressText = [
    getObjectiveLocale(`Expected: ${expectedProgressPercentage}%`),
    <br key="br" />,
    whichStatus[progressColorHex]
  ];

  /* TRUNCATE HANDLE TEMPORARY COMMENTED

  const progressIsDecimal = progressPercentage % 1 != 0

  let valueProgressPercentage = progressIsDecimal ? `${(Math.floor(progressPercentage * 10) / 10)}%` : `${progressPercentage}%`

  const progressIsTruncated = progressIsDecimal ? valueProgressPercentage.length > 5 : valueProgressPercentage.length > 4

  if(progressIsTruncated){
    tooltipProgressText.unshift(<br/>)
    tooltipProgressText.unshift(`${progressPercentage}%`)
    valueProgressPercentage = valueProgressPercentage.substr(0, progressIsDecimal ? 4 : 4)
    valueProgressPercentage = valueProgressPercentage.slice(-1) == '.' ? valueProgressPercentage+'.' : valueProgressPercentage+'..'
  }
  */

  const metricWordingTrigger = (
    <p
      className={`typography-h400 text-n-600 ${
        !isTask ? 'w-[100%] text-center ' : 'w-[144px] text-start ml-[24px]'
      }`}
      data-cy="list-detail-no-progress-metric"
    >
      {metricWording}
    </p>
  );

  const colorNotUpdated =
    progressColorHex == 'FE2851' ? 'text-r-600' : 'text-n-600';

  const emptyProgress = nowDate.isAfter(startDate) ? (
    !progressUpdated ? (
      <p className={`typography-h400 text-start ${colorNotUpdated} `}>
        {getObjectiveLocale('Not updated')}
      </p>
    ) : (
      <p
        className={`typography-h400 text-start ${whichColor[progressColorHex]}`}
      >
        {whichStatus[progressColorHex]}
      </p>
    )
  ) : (
    <p className={`typography-h400 text-start text-n-600`}>
      {getObjectiveLocale('Not started')}
    </p>
  );

  return (
    <div className="flex flex-row justify-between mb-[6px]">
      <TitleObjective
        isHover={isHover}
        isCompanyGoal={isCompanyGoal}
        isDirectorateGoal={isDirectorateGoal}
        isDepartmentGoal={isDepartmentGoal}
        isDivisionGoal={isDivisionGoal}
        title={name}
        isProject={isProject}
        objectiveId={id}
        permissions={permissions}
        isTask={isTask}
        childrenCount={objective?.childrenCount}
        smallerTitle={smallerTitle}
        cardWidth={cardWidth}
        isIntegrated={integrations?.[0]?.integrationId}
        showStateIcon={showStateIcon}
        state={state}
        isHighlighted={isHighlighted}
        isReadOnly={isReadOnly}
      />
      {!isReadOnly && (
        <div className="flex flex-row mr-[24px]">
          {!hideDueDate && (
            <div
              className="relative flex flex-row items-center justify-start mr-[24px] w-[90px]"
              data-cy="list-detail-due-date"
            >
              {dueDate && clusters.includes('dueDate') ? (
                <DueDateObjective
                  dueDate={dueDate}
                  overdue={overdue}
                  startDate={startDate}
                  objectiveId={id}
                  permissions={permissions}
                  objectiveDetail={objective}
                />
              ) : (
                <p className="typography-button text-n-600 text-start">
                  {getObjectiveLocale('Not Visible')}
                </p>
              )}
            </div>
          )}
          <div className="flex tw flex-row mr-[16px] w-[328px] justify-center">
            {isTask && useTaskManagementFeature && (
              <PhaseObjective permissions={permissions} objective={objective} />
            )}
            {clusters?.includes('metric') || clusters?.includes('progress') ? (
              <>
                {(!isTask || !useTaskManagementFeature) && (
                  <div
                    className="w-[144px] mr-[24px] flex"
                    data-cy="list-detail-progress"
                  >
                    {clusters?.includes('progress') ? (
                      <ProgressObjective
                        progressPercentage={progressPercentage}
                        expectedProgressPercentage={expectedProgressPercentage}
                        tooltipProgressText={tooltipProgressText}
                        emptyProgress={emptyProgress}
                        barColor={whichBgColorClassName(
                          progressColorHex,
                          false,
                          '500'
                        )}
                        textColor={whichColor[progressColorHex]}
                        permissions={permissions}
                        objective={objective}
                        allowZero={progressUpdated}
                      />
                    ) : (
                      <p className="typography-h400 text-n-600 text-right w-[100%]">
                        {getObjectiveLocale('Not Visible')}
                      </p>
                    )}
                  </div>
                )}
                <div
                  className="w-[160px] flex items-center justify-start relative"
                  data-cy="list-detail-metric-description"
                >
                  {clusters?.includes('metric') ? (
                    <MetricsObjective
                      isGoalType={type == 'goal' && !isProject}
                      measurement={measurement}
                      calculationType={calculationType}
                      permissions={permissions}
                      objectiveId={id}
                      milestoneMode={milestoneMode}
                      currentMilestone={currentMilestone}
                      milestoneType={milestoneType}
                    />
                  ) : (
                    <p className="typography-h400 text-n-600 text-right">
                      {getObjectiveLocale('Not Visible')}
                    </p>
                  )}
                </div>
              </>
            ) : (
              <MetricsObjective
                isGoalType={type == 'goal' && !isProject}
                measurement={measurement}
                calculationType={calculationType}
                objectiveId={id}
                permissions={permissions}
                customTrigger={metricWordingTrigger}
                milestoneMode={milestoneMode}
                currentMilestone={currentMilestone}
                milestoneType={milestoneType}
              />
            )}
          </div>
          <div className="min-w-[20px] flex items-center">
            {state === REVIEWED_STATE ? (
              <SVGIcon
                iconName="icon-reviewed"
                fillColor="var(--base-600)"
                size="20"
              />
            ) : state == COMPLETED_STATE ? (
              <SVGIcon
                iconName="icon-check_circle"
                fillColor={'var(--g-600)'}
                size="20"
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompactObjectiveBody;

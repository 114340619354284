import React from 'react';

import PropTypes from 'prop-types';

function ModalWrapper({
  className = '',
  children,
  closeFunctionWithAnimation,
  dataCyModal
}) {
  return (
    <div
      data-cy={dataCyModal || 'modal'}
      className={`modal-wrapper ${className}`}
    >
      <div
        className="modal-wrapper__overlay cursor-pointer"
        onClick={(e) => {
          e?.stopPropagation();
          closeFunctionWithAnimation();
        }}
        role="presentation"
      />
      <div
        className="modal-wrapper__content"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
}
ModalWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  eventOnClick: PropTypes.func
};
ModalWrapper.defaultProps = {
  className: '',
  eventOnClick: () => {}
};
export default ModalWrapper;

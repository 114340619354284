import { useIsFetching } from 'react-query';

const useIsFetchingTasks = (isProject, groupName, sectionName) => {
  const tasksOneLevelKey = ['objectives', 'mytasks', groupName];

  const tasksTwoLevelKey = ['objectives', 'mytasks', groupName, sectionName];

  const queryKey = isProject ? tasksTwoLevelKey : tasksOneLevelKey;

  return useIsFetching(queryKey);
};

export default useIsFetchingTasks;

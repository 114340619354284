import React from 'react';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getRollupText, whichBgColorClassName } from 'utils/ObjectivesHelper';

import FormattedNumber from 'components/design-system/FormattedNumber';
import SecondaryButton from 'components/shared/Button/SecondaryButton';
import SVGIcon from 'components/shared/SVGIcon';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';
import CalculationTypeIndicator from 'src/components/shared/CalculationTypeIndicator';

const ProgressMetric = ({ objectiveDetail, setModalOpen, setModalType }) => {
  const {
    measurement,
    permissions = [],
    calculationType,
    milestoneType,
    type
  } = objectiveDetail || {};

  const { progressPercentage, progressColorHex, currentValue, unit } =
    measurement || {};

  const {
    config: { includeTaskRollUp }
  } = useUser();

  const showRollUp = type == 'task' ? includeTaskRollUp : true;

  const buttonText = `Update ${
    milestoneType && milestoneType !== 'disabled' ? 'Milestone' : ''
  } Progress`;

  return (
    <div className="px-[24px]">
      <div className="h-[48px] flex items-center">
        <SVGIcon
          iconName="icon-bar_chart"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <span data-cy="sidebar-detail-metric-rollup">
          {measurement.description} {unit && `( ${unit} )`}
          {showRollUp &&
            measurement?.rollUp &&
            `,${' '}${getRollupText(measurement.rollUp)}`}
        </span>
      </div>
      <div className="pl-[40px]">
        <CalculationTypeIndicator
          calculationType={calculationType}
          customClass="mb-[8px]"
          bgColorClass="bg-b-200"
          cySuffix="info"
          showText
        />

        {measurement?.progressColorHex && (
          <div className="flex justify-start items-center">
            <LineProgressBar
              width={480}
              progress={progressPercentage}
              primaryColor={whichBgColorClassName(
                progressColorHex,
                false,
                '500'
              )}
              fullFirst={true}
              height={16}
            />
            <p className="typography-paragraph mb-[0px] ml-[24px] whitespace-nowrap">
              <FormattedNumber
                number={progressPercentage}
                minDecimalLength={2}
              />
              %
            </p>
          </div>
        )}
        <div className="mt-[8px]" data-cy="sidebar-detail-value">
          <span className="mr-[4px] text-v-600">
            <FormattedNumber number={currentValue} minDecimalLength={2} />
          </span>
          of
          <span className="ml-[4px]">
            <FormattedNumber
              number={measurement?.targetValue}
              minDecimalLength={2}
            />
          </span>
        </div>
        {showRollUp &&
          includeTaskRollUp &&
          measurement?.unit &&
          measurement?.rollUp !== 'disabled' && (
            <span className="block typography-secondary my-[8px]">
              {getObjectiveLocale(
                'Update progress will be automatically calculated'
              )}
            </span>
          )}
        {permissions?.includes('update_current_value') &&
          measurement?.progressColorHex && (
            <SecondaryButton
              datacy="sidebar-detail-update"
              customClass="my-[8px]"
              onClick={() => {
                setModalOpen(true);
                setModalType('update');
              }}
            >
              {getObjectiveLocale(buttonText)}
            </SecondaryButton>
          )}
      </div>
    </div>
  );
};

export default ProgressMetric;

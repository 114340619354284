// switch page keys
export const INPUT_PAGE = 'input-ids';
export const SUBMIT_PAGE = 'submit';

// phase status keys
export const IN_PROGRESS_PHASE_ID = 'in_progress';
export const COMPLETED_PHASE_ID = 'done';

//tab keys
export const FOUND_TAB_KEY = 'users_found';
export const NOT_FOUND_TAB_KEY = 'users_not_found';

import client from './ApiClient';

function getScorings(type, query) {
  return client(`v1/scorings/${type}_ongoing`, {
    method: 'GET',
    params: query
  });
}

export { getScorings };

import { Channel } from '@anycable/web';

import { CableSocket } from './ws';

class AnyCableSocketManager {
  _consumer = CableSocket();
  _subscribers = {};

  constructor() {
    if (!this._consumer.transport.connected) {
      this._consumer.connect().catch(() => null);
    }
    AnyCableSocketManager.instance = this;
  }

  static getInstance() {
    if (AnyCableSocketManager.instance) {
      return AnyCableSocketManager.instance;
    }

    return new AnyCableSocketManager();
  }

  connect(successFallback) {
    if (this._consumer.transport.connected) {
      return successFallback();
    }
    this._consumer
      .connect()
      .then(() => successFallback())
      .catch(() => null);
  }

  subscribe(channel, callback, callbackConnected) {
    this.connect(() => {
      if (this._subscribers?.[JSON.stringify(channel)]) return;
      const ch = new Channel(channel);
      this._consumer.subscribe(ch);
      ch.once('connect', () => {
        callbackConnected && callbackConnected();
        this._subscribers[JSON.stringify(channel)] = ch;
      });
      callback && ch.on('message', (msg) => callback(msg));
    });
  }

  getSubscriptions(channel) {
    if (!this._consumer) return;
    return this._subscribers?.[JSON.stringify(channel)];
  }

  perform(channel, action, data, callback) {
    const subscriptions = this.getSubscriptions(channel);
    if (!subscriptions || subscriptions?.state != 'connected') return;
    subscriptions.perform(action, data);
    callback && callback();
  }

  unsubscribe(channel, autoDisconnect = true) {
    const subscriptions = this.getSubscriptions(channel);
    if (!subscriptions) return;

    if (subscriptions.state != 'closed') {
      this._consumer.unsubscribe(subscriptions);
    }

    delete this._subscribers?.[JSON.stringify(channel)];
    if (!autoDisconnect) return;
    this.handleAutoDisconnect();
  }

  unsubscribeMatch(identifier, autoDisconnect = true) {
    const subscriptions = this._subscribers;

    if (autoDisconnect) {
      this.handleAutoDisconnect();
    }

    const keys = Object.keys(subscriptions);
    if (!identifier) return;
    keys.forEach((id) => {
      if (!id.includes(identifier)) return;
      this.unsubscribe(JSON.parse(id));
    });
  }

  handleAutoDisconnect() {
    const subscriptions = this._subscribers;
    if (!subscriptions || Object.keys(subscriptions).length == 0)
      this._consumer.disconnect();
  }
}

// class DefaultCableSocketManager {
//   _consumer = CableSocket();

//   constructor() {
//     if (!this._consumer.connection.isOpen()) {
//       this._consumer.connection.open();
//     }

//     DefaultCableSocketManager.instance = this;
//   }

//   static getInstance() {
//     if (DefaultCableSocketManager.instance) {
//       return DefaultCableSocketManager.instance;
//     }

//     return new DefaultCableSocketManager();
//   }

//   subscribe(channel, callback, callbackConnected) {
//     if (this.getSubscriptions(channel).length > 0) return;
//     this._consumer.subscriptions.create(channel, {
//       ...(callback && { received: callback }),
//       ...(callbackConnected && { connected: callbackConnected })
//     });
//   }

//   getSubscriptions(channel) {
//     if (!this._consumer) return [];

//     const identifier = typeof channel == 'object' ? channel : { channel };
//     return this._consumer.subscriptions.findAll(JSON.stringify(identifier));
//   }

//   perform(channel, action, data, callback) {
//     const subscriptions = this.getSubscriptions(channel);
//     if (!action) return;
//     if (subscriptions.length == 0) {
//       this.subscribe(channel, () => null);
//       this.perform(channel, action, data, callback);
//       this.unsubscribe(channel);
//       return;
//     }
//     subscriptions[0].perform(action, data);
//     callback && callback();
//   }

//   unsubscribe(channel, autoDisconnect = true) {
//     const subscriptions = this.getSubscriptions(channel);
//     if (subscriptions.length == 0) return;

//     subscriptions[0].unsubscribe();
//     if (!autoDisconnect) return;
//     this.disconnect();
//   }

//   unsubscribeMatch(identifier) {
//     const subscriptions = this._consumer.subscriptions.subscriptions.filter(
//       (subscription) => subscription.identifier.includes(identifier)
//     );
//     subscriptions.forEach((subscription) => {
//       subscription.unsubscribe();
//     });
//     this.disconnect();
//   }

//   disconnect(force) {
//     if (force) {
//       this._consumer.disconnect();
//       return;
//     }
//     const subscriptions = this._consumer.subscriptions.subscriptions;
//     if (subscriptions.length == 0) this._consumer.disconnect();
//   }
// }

export default AnyCableSocketManager;

import React, { useEffect, useState } from 'react';

import { getCountRating, getTasksScoring } from 'client/ObjectivesClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';

import Objectives from 'components/objectives/Objectives';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import StatistikOverallViewProgressCoaching from 'src/pages/dashboardOverallViewProgress/StatistikOverallViewProgressCoaching.js';

import './GoalScoringViewOnly.scss';

const TaskScoringViewOnly = ({
  RevieweeFeedback = false,
  taskScoringData = []
}) => {
  const { match } = useUrl();
  const {
    config: { defaultObjectiveSorting }
  } = useUser();
  const [countRatingData, setCountRatingData] = useState([]);

  const [
    { assignmentId, cycle, involvedUser, currentTrack },
    { getSingleTrack }
  ] = useFormalReview();

  const track = getSingleTrack(currentTrack);

  let params = {
    limit: 15,
    reviewsVisibility: 1,
    periodBegin: cycle.reviewedPeriodStartsAt,
    periodEndBefore: cycle.reviewedPeriodEndsAt,
    state: ['running', 'completed', 'reviewed'],
    type: ['task'],
    parentNotAssignedTo: involvedUser.target.id
  };

  const getCountRatingUser = async () => {
    const { data } = await getCountRating(involvedUser.target.id, params);
    setCountRatingData(data);
  };

  useEffect(() => {
    getCountRatingUser();
  }, []);

  return (
    <div className="container-goal-scoring-comment">
      <div className="animated-half fadeInDown-listemployee">
        <StatistikOverallViewProgressCoaching
          paramsCoaching={params}
          countRatingData={countRatingData}
          useProgressCard={false}
          useRatingCard={false}
        />
      </div>
      <Objectives
        cardModel={'goal-list'}
        page={'taskScoringViewOnly'}
        {...(!RevieweeFeedback && {
          queryFn: (params) =>
            getTasksScoring(assignmentId, {
              ...params,
              sortColumn: defaultObjectiveSorting?.sortColumn || 'due_date',
              sortDirection: defaultObjectiveSorting?.sortDirection || 'asc'
            })
        })}
        {...(RevieweeFeedback && { initialData: taskScoringData })}
        extraFilter={params}
        action={false}
      />

      {/* Sidebar */}
      {match && <SidebarMyGoals customClassname="sidebar-compact" />}
    </div>
  );
};

export default TaskScoringViewOnly;

import React from 'react';

import dayjs from 'dayjs';

const GanttTopHeaderWeekly = ({ daysOfYear }) => {
  return (
    <>
      {daysOfYear.map(({ date, id }) => {
        if (date.getDay() == 1) {
          let endWeeks = new Date(date);
          endWeeks.setDate(endWeeks.getDate() + 6);
          return (
            <div
              key={'week' + id}
              className="flex flex-[1] h-[100%] justify-center items-center"
              style={{
                borderRight: '1px solid var(--n-300)'
              }}
            >
              {dayjs(date).format('DD MMM')} -{' '}
              {dayjs(endWeeks).format('DD MMM')}
            </div>
          );
        }
      })}
    </>
  );
};

export default GanttTopHeaderWeekly;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUrl } from 'hooks/useUrl';
import { getNumberLocale } from 'utils/HelperUtils';

import Table from 'components/design-system/table/Table';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';

function ListGroup({
  listData,
  currentSort,
  setCurrentSort,
  currentPage,
  setCurrentPage,
  changePage,
  hasMore,
  onClickDelete,
  isReadOnly = false,
  isSecurityAdmin,
  emptyState
}) {
  const history = useHistory();
  const { url } = useUrl();

  const showEdit = !isReadOnly;
  const showReplyArchive = !isReadOnly && !isSecurityAdmin;

  const tableHeaders = [
    {
      name: 'Group Name',
      widthClass: 'w-[560px]',
      heightClass: 'h-[48px]',
      useMinHeight: false,
      sort: {
        sortColumn: 'name',
        sortDirection: currentSort,
        onSort: (direction) => setCurrentSort(direction)
      }
    },
    {
      name: 'Members',
      widthClass: 'w-[147px]',
      heightClass: 'h-[48px]',
      useMinHeight: false
    },
    {
      name: 'Status',
      widthClass: 'w-[147px]',
      heightClass: 'h-[48px]',
      useMinHeight: false
    },
    showEdit && {
      name: 'Edit',
      widthClass: 'w-[56px]',
      heightClass: 'h-[48px]',
      useMinHeight: false
    },
    showReplyArchive && {
      name: '',
      widthClass: 'w-[56px]',
      heightClass: 'h-[48px]',
      useMinHeight: false
    }
  ]?.filter((val) => val);

  const onClickEdit = (data) => {
    url.includes('objectives')
      ? history.push(`/objective-visibility/edit/${data.id}`)
      : history.push(`/group-permissions/edit/${data.id}`);
  };

  const onChangePage = (type) => {
    changePage(type);
    setCurrentPage(currentPage + (type === 'next' ? 1 : -1));
  };

  return listData?.length === 0 ? (
    emptyState ? (
      emptyState
    ) : (
      <></>
    )
  ) : (
    <>
      <Table
        headers={tableHeaders}
        fixedLeftCount={1}
        customClassHeader="h-[32px]"
      >
        {listData?.map((data, index) => (
          <Table.Row key={`table-row-${index}`}>
            <Table.Column heightClass="h-[48px]" key="table-column-0">
              <TruncateParagraph>{data?.name}</TruncateParagraph>
            </Table.Column>
            <Table.Column heightClass="h-[48px]" key="table-column-1">
              {getNumberLocale(data?.memberCount)} employees
            </Table.Column>
            <Table.Column heightClass="h-[48px]" key="table-column-2">
              {data?.deleted ? 'Archived' : 'Active'}
            </Table.Column>
            {showEdit && (
              <Table.Column heightClass="h-[48px]" key="table-column-3">
                <SVGIcon
                  iconName="icon-create"
                  size={24}
                  fillColor="var(--n-600)"
                  onClick={() => onClickEdit(data)}
                  customClass="hidden group-hover:flex"
                  dataCy="icon-create"
                />
              </Table.Column>
            )}
            {showReplyArchive && (
              <Table.Column heightClass="h-[48px]" key="table-column-4">
                <SVGIcon
                  iconName={data?.deleted ? 'icon-reply' : 'icon-archive'}
                  size={24}
                  fillColor="var(--n-600)"
                  onClick={() => onClickDelete(data)}
                  customClass="hidden group-hover:flex"
                  dataCy="icon-delete"
                />
              </Table.Column>
            )}
          </Table.Row>
        ))}
      </Table>
      <div className="flex justify-end w-full mt-[12px] mb-[40px]">
        <PaginationModule
          pageNumber={currentPage}
          handleNext={() => onChangePage('next')}
          handlePrev={() => onChangePage('prev')}
          hasMore={hasMore}
        />
      </div>
    </>
  );
}

export default ListGroup;

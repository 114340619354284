"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zustand_1 = require("zustand");
const helper_1 = require("./helper");
const useObjectiveComparative = (0, zustand_1.create)((set, get) => ({
    preset: {},
    isShared: false,
    creator: {},
    columns: [],
    rows: [],
    insights: [],
    isViewGraph: false,
    // Set
    setPreset: (preset) => set({ preset: preset }),
    setisShared: (isShared) => set({ isShared: isShared }),
    setCreator: (creator) => set({ creator: creator }),
    setColumns: (columns) => set({ columns: columns }),
    setRows: (rows) => set({ rows: rows }),
    setInsights: (insights) => set({ insights: insights }),
    setIsViewGraph: (isViewGraph) => set({ isViewGraph: isViewGraph }),
    // Function
    isDataChanged: (comparationData) => (0, helper_1.isDataChanged)(comparationData, get),
    setComparationData: (comparationData) => (0, helper_1.setComparationData)(comparationData, set),
    resetAllData: (creator) => (0, helper_1.resetAllData)(creator, set),
    // Function Column
    handleAddNewColumn: (filter) => (0, helper_1.handleAddNewColumn)(filter, get, set),
    handleRemoveColumn: (colNumber) => (0, helper_1.handleRemoveColumn)(colNumber, get, set),
    handleEditColumn: (newFilter, columnNumber) => (0, helper_1.handleEditColumn)(newFilter, columnNumber, get, set),
    // Function Rows
    handleAddNewRow: (textValue) => (0, helper_1.handleAddNewRow)(textValue, get, set),
    handleEditRow: ({ orderId, rowName }) => (0, helper_1.handleEditRow)(orderId, rowName, get, set),
    handleRemoveRow: (orderId) => (0, helper_1.handleRemoveRow)(orderId, get, set),
    // Function Edit Objective
    handleEditObjective: ({ columnNumber, rowNumber, objective }) => (0, helper_1.handleEditObjective)(columnNumber, rowNumber, objective, get, set)
}));
exports.default = useObjectiveComparative;

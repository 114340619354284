import React, { useState } from 'react';

import kebabCase from 'lodash/kebabCase';

import { getObjectiveLocale } from 'utils/HelperUtils.js';

import Checkbox from 'components/shared/Checkbox';
import RadioButton from 'src/components/shared/RadioButton';

const PeerSelectionMode = ({
  configPeerSelection,
  activePeerSelection,
  onChangePeerConfig,
  lockedDefaultValue,
  togglePhase,
  cycleData
}) => {
  const [selectedMode, setSelectedMode] = useState(activePeerSelection);
  const [lockManager, setLockManager] = useState(lockedDefaultValue); //need new params

  const togglePeerMode = (value) => {
    onChangePeerConfig(value);
    setSelectedMode(value);
  };

  const lockedToggle = () => {
    const currentValue = lockManager;
    setLockManager(!currentValue);
    togglePhase('peerReview', {
      ...cycleData,
      allowLockedByManager: !currentValue
    });
  };

  return (
    <div className="flex flex-col px-[16px]">
      <h4 className="typography-h400 mb-[4px]">
        {getObjectiveLocale('Peer selection')}
      </h4>
      <p className="text-n-800 mb-[16px]">
        {getObjectiveLocale('Who select the peers to be reviewed?')}
      </p>
      {configPeerSelection.map((data) => (
        <div
          key={data.id}
          className="wrapper-radio-button flex items-center mb-[8px]"
        >
          <RadioButton
            dataCy={`peer-config-${kebabCase(data.mode)}`}
            size="small"
            value={data.mode}
            id={data.mode}
            checked={selectedMode == data.mode}
            onChange={() => togglePeerMode(data.mode)}
          >
            <span className="ml-[8px]">{data.description}</span>
          </RadioButton>
        </div>
      ))}
      {selectedMode == 'self_nominated' && (
        <div
          className="ml-[24px] bg-n-200 rounded px-[8px] flex items-center cursor-pointer"
          onClick={lockedToggle}
        >
          <Checkbox
            name={'test'}
            id={'id'}
            checked={lockManager}
            onChange={lockedToggle}
          />
          <p className="typography-paragraph text-n-900 ml-[8px]">
            {getObjectiveLocale(
              'Managers can lock/unlock the selected Peer Reviewer'
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default PeerSelectionMode;

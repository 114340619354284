import React from 'react';
import { useParams } from 'react-router-dom';

import { useUser } from 'context/UserContext';

import BannerBox from 'components/shared/BannerBox';
import InputField from 'components/shared/InputField';
import RadioButton from 'components/shared/RadioButton';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

import { dynamicFormulaArray } from './ConfigurationSettingTrackTemplate';

function FormulaComponent({ templateData, setData, defaultFormula }) {
  const {
    config: { defaultGoalFormula }
  } = useUser();

  let mechanism = templateData.config.mechanism
    ? templateData.config.mechanism
    : null;
  const listShowFormula = [
    'each',
    'specific_attribute',
    'attributes',
    'input_score',
    'suggested_ongoing',
    'suggested_ongoing_parent_only'
  ];
  const { trackType } = useParams();
  const localedDict = {
    goals_scoring: 'goal',
    tasks_scoring: 'task',
    values_scoring: 'value',
    competencies_scoring: 'competencies'
  };
  const formula = dynamicFormulaArray(
    localedDict[trackType] || '',
    defaultGoalFormula
  );

  return (
    <>
      {listShowFormula.includes(mechanism) && (
        <div className="wrapper-component">
          <p className="typography-h500 typography-primary text-n-900 mb-[4px]">
            Formula
          </p>
          <p className="typography-h400 typography-paragraph typography-secondary">
            Set up the formula based on configure selection how the score will
            be calculated from?
          </p>
          <div className="wrapper-select-setting">
            {formula.map((formulaData, index) => (
              <div
                key={`formula-${index}`}
                className="cursor-pointer"
                onClick={(e) => {
                  e?.stopPropagation();
                  setData(formulaData.value, 'config', 'formula');
                }}
              >
                <RadioButton
                  size="small"
                  checked={formulaData.value == templateData.config.formula}
                  onChange={(e) => {
                    e?.stopPropagation();
                    setData(formulaData.value, 'config', 'formula');
                  }}
                >
                  <p
                    className="mb-[0px] ml-[8px] cursor-pointer typography-h400 typography-paragraph text-n-900"
                    data-cy={formulaData.value}
                  >
                    {formulaData.name}
                  </p>
                  {formulaData.value == defaultFormula && (
                    <span className="default-formula ml-[8px]">
                      {'(default)'}
                    </span>
                  )}
                </RadioButton>
              </div>
            ))}

            {templateData?.config?.formula == defaultGoalFormula && (
              <div className="ml-[24px] mr-[16px]">
                <BannerBox
                  type="info"
                  customClass="mt-[8px]"
                  alignItemsClass="items-start"
                >
                  <p className="typography-paragraph text-n-900 mb-[0px]">
                    {getObjectiveLocale(
                      'Custom formula for calculating Goal Final Score can only be set on organization level.'
                    )}
                  </p>
                  <p className="typography-paragraph text-n-900 mb-[0px]">
                    {getObjectiveLocale(
                      'Please consult with Happy5 team to configure a new formula.'
                    )}
                  </p>
                </BannerBox>
                <InputField
                  dataCy="config-formula"
                  value={defaultGoalFormula}
                  disabled={true}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default FormulaComponent;

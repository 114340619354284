import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';

import {
  deleteGroup,
  leaveGroup,
  muteGroup,
  unmuteGroup
} from 'client/CultureClient';
import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import {
  capitalize,
  getCultureLocale,
  getNumberLocale
} from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import ModalGroupMember from 'components/modal/culture/ModalGroupMember';
import ModalManageGroup from 'components/modal/culture/ModalManageGroup';
import Dialog from 'components/shared/Dialog/Dialog';
import SVGIcon from 'components/shared/SVGIcon';
import AdminTabsGeneral from 'components/shared/Tabs/AdminTabsGeneral';
import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';

const GroupPageHeader = ({ groupDetail, typeGroupList, setFilter, filter }) => {
  const { config } = useUser();
  const history = useHistory();

  const isReadOnly =
    config?.permissions?.cultureFeedsTimelineSee === 'read_only';

  const [currentGroup, setCurrentGroup] = useState(groupDetail);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isManageGroupOpen, setIsManageGroupOpen] = useState(false);
  const [isManageMemberOpen, setIsManageMemberOpen] = useState(false);
  const [confirmData, setConfirmData] = useState({ isShow: false });
  const [toastData, setToastData] = useState({ isShow: false, message: '' });
  const isGroupDefaultModerated =
    groupDetail?.groupType == 'default' ||
    groupDetail?.groupType == 'default_moderated';

  const isAdmin = currentGroup.membershipType === 'owner';
  const isAbleToManageNotif =
    currentGroup.toggleGroupNotification && groupDetail.id !== -1;
  const listDropdown = [
    {
      id: 'manage',
      name: getCultureLocale('Manage group'),
      icon: 'icon-settings',
      isVisible: isGroupDefaultModerated
        ? config?.permissions?.cultureGroupDefaultManage
        : isAdmin
    },
    {
      id: 'invite',
      name: getCultureLocale(
        isGroupDefaultModerated ? 'Invite Admin' : 'InviteMembers'
      ),
      icon: 'icon-people_alt',
      isVisible: isGroupDefaultModerated
        ? config?.permissions?.cultureGroupDefaultManage
        : isAdmin && groupDetail.id !== -1
    },
    {
      id: 'off',
      name: getCultureLocale('Turn off Notification'),
      icon: 'icon-notifications_off',
      isVisible: isAbleToManageNotif && currentGroup.notification
    },
    {
      id: 'on',
      name: getCultureLocale('Turn on Notification'),
      icon: 'icon-notifications',
      isVisible: isAbleToManageNotif && !currentGroup.notification
    },
    {
      id: 'exit',
      name: getCultureLocale('ExitGroup'),
      icon: 'icon-exit_to_app',
      isVisible: groupDetail.id !== -1 && !isGroupDefaultModerated
    },
    {
      id: 'delete',
      name: getCultureLocale('DeleteGroup'),
      icon: 'icon-delete',
      isVisible: isGroupDefaultModerated
        ? config?.permissions?.cultureGroupDefaultManage
        : isAdmin && groupDetail.id !== -1
    }
  ].filter((dropdown) => dropdown.isVisible);

  const setShowNotif = (msg, isSuccess) => {
    setToastData({ isShow: true, message: msg, isWarning: isSuccess });
    setTimeout(() => {
      setToastData({ isShow: false, message: '', isWarning: false });
    }, 2000);
  };

  const manageNotifGroup = async (type) => {
    const setFunction = () =>
      type === 'off'
        ? muteGroup(currentGroup.id)
        : unmuteGroup(currentGroup.id);
    const { data } = await setFunction();
    data
      ? setShowNotif(`Group notifications ${type}`, false)
      : setShowNotif(`Failed change group notifications`, true);
    if (data) {
      let group = cloneDeep(currentGroup);
      group.notification = type === 'off' ? false : true;
      setCurrentGroup(group);
    }
  };

  const setDropdownFunction = (e) => {
    switch (e[0].id) {
      case 'manage':
        setIsManageGroupOpen(true);
        break;
      case 'exit':
      case 'delete':
        setConfirmData({ isShow: true, type: e[0].id });
        break;
      case 'off':
      case 'on':
        manageNotifGroup(e[0].id);
        break;
      case 'invite':
        setIsManageMemberOpen(true);
    }
    setIsDialogOpen(false);
  };

  const confirmFunction = async () => {
    const { data } =
      confirmData?.type === 'exit'
        ? await leaveGroup(groupDetail.id)
        : await deleteGroup(groupDetail.id);
    if (data) {
      history.replace('/timeline');
    }
    setConfirmData({ isShow: false });
  };

  const listStateTimeline = [
    {
      id: 'group-recent',
      name: getCultureLocale('HomeFeedFilterRecentLabel'),
      isVisible: true
    },
    {
      id: 'group-feeling',
      name: getCultureLocale('Feeling'),
      isVisible: config.featureConfig.allowedPostTypes.includes('feeling')
    },
    {
      id: 'group-popular',
      name: getCultureLocale('HomeFeedFilterPopularLabel'),
      isVisible: true
    }
  ].filter((state) => state.isVisible);

  useEffect(() => {
    setCurrentGroup(groupDetail);
  }, [groupDetail]);

  return (
    <div
      className="relative h-[308px] w-[824px] rounded-md m-24 border-gray"
      data-cy="header-timeline"
    >
      {toastData.isShow && (
        <ToastNotif
          showNotif={toastData.isShow}
          message={toastData.message}
          warning={toastData.isWarning}
        />
      )}
      <AWSImage
        src={
          currentGroup?.groupPicture?.secureUrl ??
          Config.asset.general.defaultGroupCover
        }
        className="rounded-md object-cover"
        dataCy="header-image"
        onErrorImage={Config.asset.general.defaultGroupCover}
      />
      <div className="absolute bottom-0 w-full rounded-b-md px-[16px] py-[16px] bg-n-000 flex flex-col h-[101px]">
        <div className="flex justify-between z-index-20">
          {currentGroup.name || currentGroup?.groupType ? (
            <div>
              <div className="typography-h500" data-cy="header-name">
                {currentGroup.name}
              </div>
              <div
                className="typography-paragraph text-n-800"
                data-cy="header-desc"
              >
                {capitalize(currentGroup?.groupType?.replace(/_group|_/g, ' '))}{' '}
                {getCultureLocale('Group')}
                {currentGroup.id !== -1 && (
                  <span>
                    {' '}
                    · {getNumberLocale(currentGroup?.memberCount)}{' '}
                    {getCultureLocale('Members')}
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              <Shimmer
                circle
                customClass="mt-[6px]"
                width="200px"
                height="18px"
              />
              <Shimmer
                circle
                customClass="mt-[6px]"
                width="100px"
                height="15px"
              />
            </div>
          )}
          <div className="flex items-center">
            <SVGIcon
              iconName="icon-keyboard_control"
              size="24"
              fillColor="var(--n-600)"
              onClick={() => setIsDialogOpen(!isDialogOpen)}
              dataCy="option-header"
            />
            {isDialogOpen && (
              <Dialog
                noSearch
                title=""
                placement="fixed-bottom"
                containerClassName="w-[200px]"
                handleChange={(e) => setDropdownFunction(e)}
                listItem={{
                  template: 'img-label',
                  config: {
                    labelText: 'name',
                    imgSrc: 'icon',
                    imgSize: '24',
                    fillColor: 'var(--n-600)'
                  }
                }}
                staticOptions={listDropdown}
                setShowDialog={setIsDialogOpen}
              />
            )}
          </div>
        </div>
        <AdminTabsGeneral
          listObjTabs={listStateTimeline}
          active={filter}
          changeTab={setFilter}
          small={true}
          useBorder={false}
          customClass="px-[16px]"
        />
      </div>
      {isManageGroupOpen && (
        <ModalManageGroup
          typeManage="edit"
          closeModal={setIsManageGroupOpen}
          existingGroup={currentGroup}
          isReadOnly={isReadOnly}
          typeGroupList={typeGroupList}
        />
      )}
      {confirmData.isShow && (
        <Modal
          dataCyModal={`${confirmData?.type}-modal`}
          title={`${capitalize(confirmData?.type)} Group`}
          className="w-[400px]"
          description={`Are you sure want to ${confirmData?.type} this group?`}
          eventOnClickClose={() => setConfirmData({ isShow: false })}
          withPrimaryBtn={{
            danger: confirmData?.type === 'delete',
            title: 'Yes',
            onClick: confirmFunction,
            dataCy: 'primary-btn'
          }}
          withSecondaryBtn={{
            title: 'No',
            onClick: () => setConfirmData({ isShow: false }),
            dataCy: 'secondary-btn'
          }}
        />
      )}
      {isManageMemberOpen && (
        <ModalGroupMember
          setIsManageMemberOpen={setIsManageMemberOpen}
          groupDetail={groupDetail}
        />
      )}
    </div>
  );
};

export default GroupPageHeader;

import {
  getObjectivePhases,
  getObjectivePhaseNames
} from 'client/ObjectivesClient';
import { useInfiniteQuery } from 'react-query';

export default function useObjectivePhases() {
  const GetObjectivePhases = (params, showTask) => {
    const fetchObjectivePhases = () => {
      return showTask === 'project'
        ? getObjectivePhaseNames(params)
        : getObjectivePhases(params);
    };

    let { data, status, isFetching, refetch } = useInfiniteQuery(
      ['objective-phases', params],
      fetchObjectivePhases
    );

    return {
      data,
      status,
      isFetching,
      refetch
    };
  };

  return { getObjectivePhases: GetObjectivePhases };
}

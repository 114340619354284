import React, { Fragment, useState } from 'react';

export const ItemCompared = ({ text, addOnClick }) => {
  return (
    <div className="item-compared">
      <div className="item-compared-name">{text}</div>
      <div className="item-compared-btn" onClick={addOnClick}>
        <span className="fa fa-close"></span>
      </div>
    </div>
  );
};

export const CardWrapper = ({ text, children }) => {
  return (
    <div className="card-wrapper-number">
      <p className="typography-h500">{text}</p>
      {children}
    </div>
  );
};

export const CardChartWrapper = ({ title, children }) => {
  return (
    <div className="row">
      <div className="col-xs-12 card-wrapper-retention">
        <div className="card-body-growth-chart">
          <h4 className="body-header">
            {title} <i className="fa fa-question-circle-o"></i>
          </h4>
          {children}
        </div>
      </div>
    </div>
  );
};

export const TotalUser = ({ qty }) => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  function isEllipsisActive(element) {
    return element?.offsetWidth < element?.scrollWidth;
  }

  let totalUserRetension = document.getElementById('total-user-retention');
  if (isEllipsisActive(totalUserRetension)) {
    setIsTooltipActive(true);
  }

  return (
    <div className="wrapper-tooltip">
      {isTooltipActive && <div className="tooltip">{qty}</div>}
      <p id="total-user-retention" className="paragraf-number mt-[32px]">
        {qty}
      </p>
    </div>
  );
};

export const DauWauMau = ({
  NumberFirst,
  DateFirst,
  FonAwesome,
  NumberSecond,
  DateSecond
}) => {
  let iClass;
  switch (FonAwesome) {
    case 'minus':
      iClass = 'fa fa-sort-asc plus';
      break;

    default:
      iClass = 'fa fa-sort-asc minus';
      break;
  }

  return (
    <Fragment>
      <div className="wrapper-total-DAU-WAU-MAU">
        <div className="wrapper-percentage-number">
          <p className="first-number">{NumberFirst}</p>
          <span className="percentage"> %</span>
        </div>
        <div className="wrapper-percentage-number">
          <i className={iClass}></i>
          <p className="second-number">{NumberSecond}</p>
          <span className="percentage"> %</span>
        </div>
      </div>
      <div className="wrapper-date">
        <p className="p-retention-date">{DateFirst}</p>
        <p className="p-retention-date">{DateSecond}</p>
      </div>
    </Fragment>
  );
};

import React, { useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/shared/modal/Modal';

function ErrorModal({ error, closeModal }) {
  return (
    <div className="modal-error-boundary">
      <Modal
        title={'Something Went Wrong'}
        eventOnClickClose={() => closeModal()}
        dataCyModal="error-modal"
      >
        <div className="error-modal-header">
          <p className="fs-14">{getObjectiveLocale(error.message)} </p>
        </div>
      </Modal>
    </div>
  );
}

export default ErrorModal;

import client from 'client/ApiClient';

function getRatingTemplates(params = {}) {
  return client(`v1/admin/scorings`, { params });
}

function postRatingTemplate(body) {
  return client(`admin/scorings`, {
    method: 'POST',
    body
  });
}

function deleteRatingTemplate(id) {
  return client(`v1/admin/scorings/${id}`, { method: 'DELETE', body: {} });
}

function getRatingTemplateDetail(id) {
  return client(`v1/admin/scorings/${id}`);
}

export {
  getRatingTemplates,
  deleteRatingTemplate,
  getRatingTemplateDetail,
  postRatingTemplate
};

import React, { useCallback, useEffect, useState } from 'react';

import classSetter from 'classnames';
import debounce from 'lodash/debounce';

import { getSkills } from 'client/SkillClient';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import Config from 'utils/Config';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';

import Label from 'components/shared/Label/Label';
import ListEmptyState from 'components/shared/ListEmptyState';
import Modal from 'components/shared/modal/Modal';

function SkillPopupComponent(props) {
  const [suggestion, setSuggestion] = useState([]);
  const [search, setSearch] = useState('');
  const [searchNotFound, setSearchNotFound] = useState(false);
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [olderThan, setOlderThan] = useState(null);
  const { config } = useUser();
  const propsSkill = props.skill?.title;
  const debouncedSearchValue = useDebounce(search, 500);

  const type = props.type;
  let headerText = getObjectiveLocale('Recognition');
  let skillPlaceholder = `${getObjectiveLocale('Search Skill')}...`;

  if (type.toLowerCase() != 'recognition') {
    headerText = getObjectiveLocale('Feedback');
    skillPlaceholder = getObjectiveLocale('Tag one Skill/Value to improve');
  }

  const _getSuggestion = async (isFetchMore = false) => {
    let query = {
      behavior_type: 'competency',
      placementId: props?.placementId,
      q: search,
      offset: olderThan
    };
    const { data, pagination } = await getSkills(query);
    if (data) {
      setSearchNotFound(data.length === 0);
      setOlderThan(pagination?.nextOffset);
      let mappedData = isFetchMore ? [...suggestion, ...data] : data;

      // swap selected skill position to first element of array
      if (propsSkill) {
        mappedData = mappedData.filter((skill) => {
          return skill.title !== propsSkill;
        });
        mappedData.unshift(propsSkill);
      }

      setSuggestion(mappedData);
    }
  };

  const _onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      setOnLoadMore(true);
      await _getSuggestion(true);
      setOnLoadMore(false);
    };

    if (!onLoadMore && olderThan) {
      loadMoreValidator(target, 100, () => {
        loadMore();
      });
    }
  };

  const _setSkill = (skill) => {
    props.onClickConfirm(skill);
  };

  const _search = (value) => {
    setSearch(value);
  };

  const debounceFn = useCallback(debounce(_getSuggestion, 500), [
    debouncedSearchValue
  ]);

  useEffect(() => {
    debounceFn();
    return debounceFn.cancel;
  }, [debouncedSearchValue]);

  return (
    <div className="form-review-component">
      <Modal title={headerText} eventOnClickClose={props.toClose}>
        <div className="search-container no-selected">
          <div className="form-group has-search">
            <input
              id="search-group"
              type="text"
              className="form-control"
              placeholder={skillPlaceholder}
              onChange={(e) => _search(e.currentTarget.value)}
            />
          </div>
        </div>
        <div
          className="search-result-container recognition-form no-selected"
          onScroll={(e) => _onScroll(e)}
        >
          <div className="form-group">
            {!searchNotFound &&
              suggestion.map((data, key, e) => {
                const text = data.title;
                const labelContainer = {
                  'form-objective-label-container': true,
                  toggle:
                    propsSkill !== '' && propsSkill === text ? true : false
                };

                return (
                  <div
                    className={classSetter(labelContainer)}
                    onClick={() => _setSkill(data)}
                    key={key}
                  >
                    <Label className="form-objective-label">{text}</Label>
                  </div>
                );
              })}
            {searchNotFound && (
              <ListEmptyState
                emptyIcon="icon-no-skill"
                size="small"
                containerClassname="h-full"
                title={getObjectiveLocale('No Skill Found')}
                subtitle={getObjectiveLocale(
                  'You can add this skill to dictionary by clicking action button bellow'
                )}
                iconBtn="icon-add"
                btnText={config.allowAddSkill && 'Add this skill'}
                onClick={() => _setSkill(search)}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SkillPopupComponent;

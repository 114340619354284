import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Config from 'utils/Config';

import AWSImage from 'components/design-system/AWSImage';
import SVGIcon from 'src/components/shared/SVGIcon';

import './OrderingBehavior.scss';

const OrderingBehavior = ({ orderingData, setOrderingData, routeName }) => {
  const naming = routeName == 'competencies' ? 'competency' : 'value';

  const reorder = (startIndex, endIndex) => {
    const result = [...orderingData];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const _onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedArray = reorder(
      result.source.index,
      result.destination.index
    );
    setOrderingData(reorderedArray);
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    let style = { userSelect: 'none', ...draggableStyle };

    if (isDragging) {
      style.background = 'rgba(233, 199, 249, 0.16)';
      style.boxShadow = '0 6px 12px 0 rgba(50, 50, 50, 0.2)';
    }

    return style;
  };

  return (
    <div className="mt-[40px]" id="ordering-section">
      <p className="capitalize text-n-900 typography-h500 mb-[4px]">Ordering</p>
      <p className="text-n-800 typography-paragraph mb-[24px]">
        Set ordering number of selected parent values in this model.
      </p>

      {orderingData.length == 0 && (
        <div className="rounded-[4px] bg-n-200 py-[24px] w-full flex-center-center">
          <p className="typography-h300 text-n-800">No value selected</p>
        </div>
      )}

      {orderingData.length > 0 && (
        <div>
          <div className="flex pb-[10px] border-table">
            <div className="typography-h300 text-n-800 uppercase text-center w-[10%]">
              Order
            </div>
            <div className="typography-h300 text-n-800 uppercase w-[40%] pl-[16px]">
              {naming} list
            </div>
            <div className="typography-h300 text-n-800 uppercase  w-1/2">
              Description
            </div>
          </div>

          <DragDropContext onDragEnd={_onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  className=".body-table-dnd py-[10px] border-table body-dictionary-table"
                  id="table-dnd"
                  style={{
                    height: snapshot.isDraggingOver
                      ? document.getElementById('table-dnd')?.offsetHeight
                      : '100%'
                  }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {orderingData.map((order, index) => (
                    <Draggable
                      key={order.id}
                      draggableId={String(order.id)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={`py-[10px] state-hover flex justify-between items-start ${
                            index == orderingData.length - 1 ? '' : 'mb-[12px]'
                          }`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div className="w-[10%]">
                            <div className="flex-center-center height-fit-content">
                              <SVGIcon
                                iconName="icon-drag_indicator"
                                width="10"
                                height="16"
                                customClass="mr-[24px]"
                              />
                              <p className="typography-paragraph">
                                {index + 1}
                              </p>
                            </div>
                          </div>
                          <div className="pl-[16px] w-[40%]">
                            <div className="flex wrapper-img-name">
                              {routeName == 'values' && (
                                <AWSImage
                                  className="w-[32px] h-[32px] img-value"
                                  src={
                                    order?.iconImageAttributes?.iconUrl ||
                                    Config.asset.admin.defaultValueImg
                                  }
                                />
                              )}
                              <p
                                className={`typography-paragraph text-n-900 ${
                                  routeName == 'values' ? 'ml-[16px]' : ''
                                }`}
                              >
                                {order.title}
                              </p>
                            </div>
                          </div>
                          <div className="w-1/2 typography-paragraph text-n-900">
                            {order.description}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </div>
  );
};

export default OrderingBehavior;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useInfiniteQuery } from 'react-query';

import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import { getBreakdownSurveyAnalytics, getListFilter } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import usePulseSurvey from 'hooks/usePulseSurvey';
import {
  getCultureLocale,
  getDateLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';
import { restructureFilter } from 'utils/SurveyHelper';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Button from 'components/design-system/Button';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';

import TableBreakdownSurvey from './TableBreakdownSurvey';

const BreakdownSurvey = ({ surveyId, occurrenceId }) => {
  const ref = useRef();
  const { config } = useUser();
  const { surveyData } = usePulseSurvey();

  const filterById = useFilter((state) => state.filterById);
  const setFilterById = useFilter((state) => state.setFilterById);

  const [isShowNotif, setIsShowNotif] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pagination, setPagination] = useState({});
  const [breakdownData, setBreakdownData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(dayjs());

  const defaultFilter = {
    status: ['submitted', 'not_yet_submitted']
  };
  const filter = filterById?.['surveyAnalyticFilter'] || defaultFilter;
  const { generalFilterOptions } = config || [];

  const currentDate = getDateLocale(new Date(), {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });

  const statusOption = {
    option: 'status',
    title: 'Status',
    dialog: 'survey-status'
  };
  let filterMenu = generalFilterOptions
    ? [...generalFilterOptions, statusOption]
    : [statusOption];

  let fileHeaders = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Job Title', key: 'jobTitle' },
    { label: 'Job Role', key: 'info.jobRole' },
    { label: 'Job Function', key: 'info.jobFunction' },
    { label: 'Directorate', key: 'info.directorate' },
    { label: 'Division', key: 'info.division' },
    { label: 'Department', key: 'info.department' },
    { label: 'Status', key: 'status' },
    { label: 'Start At', key: 'startsAt' },
    { label: 'Finish At', key: 'endsAt' },
    { label: 'Duration', key: 'duration' }
  ];

  surveyData?.questions?.map(({ question }, index) =>
    fileHeaders.push({ label: question, key: `answers[${index}]` })
  );

  const getFilterData = async (addedParams) => {
    return await getListFilter({
      q: addedParams?.q,
      key: addedParams?.key,
      ...addedParams
    });
  };

  const _getListFilter = (addedParams) => {
    const fetchFilter = () => {
      return getListFilter(addedParams);
    };

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['filter', addedParams],
        ({ pageParam }) => fetchFilter(pageParam),
        { getNextPageParam: (lastPage) => lastPage.pagination?.next || 0 }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  const handleExportCSV = async () => {
    setIsShowNotif(true);
    setTimeout(() => {
      ref.current.link.click();
    }, [1000]);

    setIsShowNotif(false);
    setShowModal(false);
  };

  const getBreakdownData = useCallback(
    async (addedFilter) => {
      if (!isLoading || addedFilter) {
        const updatedFilter = {
          surveyOccurrenceId: occurrenceId,
          limit: currentLimit,
          ...addedFilter,
          ...restructureFilter(filter)
        };
        const { data, pagination, isSuccess, total } =
          await getBreakdownSurveyAnalytics(surveyId, updatedFilter);
        if (data && isSuccess) {
          const mappingData = data?.map((survey) => {
            let answers = [];
            const start = survey?.startsAt
              ? new Date(dayjs(survey?.startsAt).toISOString())
              : '-';
            const end = survey?.endsAt
              ? new Date(dayjs(survey?.endsAt).toISOString())
              : '-';

            const mins =
              end && start ? dayjs(end).diff(dayjs(start), 'minute', true) : 0;
            const totalHours = parseInt(mins / 60);
            const totalMins = dayjs().minute(mins).$m;
            survey.duration =
              totalHours > 0 || totalMins > 0
                ? `${totalHours + 'h'}${totalMins + 'm'}`
                : '-';
            survey.startsAt = survey?.startsAt
              ? getDateLocale(survey?.startsAt, {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })
              : '-';
            survey.endsAt = survey?.endsAt
              ? getDateLocale(survey?.endsAt, {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })
              : '-';
            survey.status = startCase(
              survey?.status == 'not_submitted'
                ? 'not yet submitted'
                : survey?.status
            );
            survey.name = survey?.fullName;

            surveyData?.questions?.map((q) => {
              const answer = survey?.answers?.find((ans) =>
                q?.type == 'poll' ? ans?.pollId == q?.id : ans?.essayId == q?.id
              );
              answers.push(answer?.pollOptionsAnswer || answer?.essayAnswer);
            });
            survey.answers = answers;
            return survey;
          });
          setBreakdownData(mappingData);
          setPagination({
            olderThan: pagination?.next?.olderThan,
            newerThan: pagination?.next?.newerThan
          });
          setHasMore(
            data?.length < currentLimit ? false : pagination?.next?.olderThan
          );
          setTotal(total);
          setLastUpdated(new Date());
        }
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLimit, filter]
  );

  const generateCustomTitle = (title) => {
    return title == 'no_info' ? 'N/A' : title.split('_').join(' ');
  };

  const resetFilter = useCallback(
    () => {
      setFilterById('surveyAnalyticFilter', defaultFilter);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    window.addEventListener('beforeunload', resetFilter, {
      capture: true
    });
    return () => {
      resetFilter();
      window.removeEventListener('beforeunload', resetFilter, {
        capture: true
      });
    };
  }, [resetFilter]);

  useEffect(() => {
    setIsLoading(true);
    setCurrentPage(1);
    getBreakdownData();
  }, [currentLimit, filter, getBreakdownData]);

  return (
    <div className="mt-[40px] mx-[40px]">
      <ToastNotif
        showNotif={isShowNotif}
        message={getObjectiveLocale('Exporting csv progress...')}
      />
      {showModal && (
        <Modal
          title="Export CSV"
          className="w-[400px]"
          dataCyModal="export-csv-progress-overtime-modal"
          eventOnClickClose={() => setShowModal(false)}
          withPrimaryBtn={{
            title: 'Export',
            dataCy: 'export-csv-overtime',
            onClick: () => handleExportCSV(),
            isLoading: isShowNotif
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            dataCy: 'cancel-csv-overtime',
            onClick: () => setShowModal(false)
          }}
        >
          <p className="typography-h100 text-n-800">
            {getObjectiveLocale('Data shown in the table will be exported.')}
          </p>
          <p className="mb-[16px] typography-h100 text-n-800">
            {getObjectiveLocale('100 rows at max.')}
          </p>
        </Modal>
      )}
      <p className="typography-h500 text-n-900 mb-[16px]">
        {getCultureLocale('Completion Data')}
      </p>
      <div className="flex items-start">
        <div className="w-full">
          <AdvancedFilter
            filterOptions={['search', 'menuFilter']}
            filterMenu={filterMenu}
            defaultFilter={filter}
            defaultFilterToReset={{
              status: ['submitted', 'not_yet_submitted']
            }}
            filterClient={getFilterData}
            addedQueryParams={{ surveyId }}
            dialogQueryFn={(params) => _getListFilter(params)}
            generateCustomTitle={(title) => generateCustomTitle(title)}
            id="surveyAnalyticFilter"
            customClass="mb-[24px] w-full"
          />
        </div>
        <Button
          customClass="flex items-center ml-[16px]"
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(!showModal);
          }}
          datacy="export-csv"
        >
          <SVGIcon
            size="24"
            fillColor="var(--n-100)"
            iconName="icon-file_download"
            customClass="mr-[4px]"
          />
          {getCultureLocale('Export CSV')}
        </Button>
      </div>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <TableBreakdownSurvey
          breakdownData={breakdownData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currentLimit={currentLimit}
          setCurrentLimit={setCurrentLimit}
          setIsLoading={setIsLoading}
          getData={getBreakdownData}
          pagination={pagination}
          total={total}
          lastUpdated={lastUpdated}
          hasMore={hasMore}
        />
      )}
      <CSVLink
        headers={fileHeaders}
        data={breakdownData}
        filename={`${surveyData?.title}_filtered_${currentDate}.csv`}
        target="_blank"
        ref={ref}
      />
    </div>
  );
};

export default BreakdownSurvey;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { createUsers, updateUsers } from 'client/admin/UsersClient';
import { getUserInfo } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import useUserManagement from 'hooks/useUserManagement';
import { getObjectiveLocale } from 'utils/HelperUtils';

import BehaviorModelGroupConfirmationModal from 'components/admin/usermanagement/BehaviorModelGroupConfirmationModal';
import Button from 'components/design-system/Button';
import AdminTabsGeneral from 'components/shared/Tabs/AdminTabsGeneral';
import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';

import InformationDetail from './InformationDetail';
import PositionDetail from './PositionDetail';

const UserManagementDetail = ({ isReadOnly }) => {
  const params = useParams();
  const { config } = useUser();
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    toastData,
    editedPosition,
    editedInfoData,
    saveDataPosition,
    newBehaviorModelGroup,
    previousBehaviorModelGroup,
    isButtonSaveShow,
    isEditingBackground,
    isFetchingNewBehaviorModelGroup,
    setIsError,
    setToastData,
    setButtonSave,
    setEditedPosition,
    setEditedInfoData,
    setNewBehaviorModelGroup,
    setPreviousBehaviorModelGroup,
    refetchList,
    cancelChanges
  } = useUserManagement();

  const [infoData, setInfoData] = useState({
    name: null,
    email: null,
    cultureRole: null,
    jobTitle: null,
    role: null,
    isCompanyGoalCreator: null,
    info: {
      profilePicture: null
    }
  });
  const [currentTab, setCurrentTab] = useState('info');
  const [endScroll, setEndScroll] = useState(false);
  const [listPosition, setListPosition] = useState([]);
  const [listPositionNavigator, setListPositionNavigator] = useState([]);
  const [activeSection, setActiveSection] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const checkBehaviorModelGroup =
    (previousBehaviorModelGroup?.involvedMembershipsByInfo?.length > 0 ||
      newBehaviorModelGroup?.involvedMembershipsByInfo?.length > 0) &&
    previousBehaviorModelGroup?.id !== newBehaviorModelGroup?.id;

  const getUserInfoDetail = async (id) => {
    const { data } = await getUserInfo(id);

    if (data) {
      setInfoData(data);
      setEditedInfoData(data);
    }
  };

  const listTab = [
    {
      id: 'info',
      name: getObjectiveLocale('Information'),
      disabled: false
    },
    {
      id: 'position',
      name: getObjectiveLocale('Position'),
      disabled: !infoData?.id
    }
  ];

  const checkChangeTab = (id) => {
    if (
      currentTab === 'info' &&
      (!editedInfoData?.name || !editedInfoData?.email)
    ) {
      setIsError(true);
    } else {
      if (
        currentTab === 'position'
          ? !isEmpty(editedPosition)
          : !isEqual(infoData, editedInfoData)
      ) {
        setIsModalOpen(true);
      } else {
        setCurrentTab(id);
      }
    }
  };

  const validateRole = (key, defaultValue) => {
    return config?.editableUserInformation?.platformAndRole ||
      params.userId ||
      editedInfoData?.id
      ? editedInfoData?.[key]
      : defaultValue;
  };

  const saveTab = async () => {
    if (
      (config?.editableUserInformation?.platformAndRole
        ? !editedInfoData.cultureRole && !editedInfoData.role
        : false) ||
      !editedInfoData?.name ||
      !editedInfoData?.email
    ) {
      setIsError(true);
    } else {
      let data;
      let editBody = {
        email: editedInfoData.email,
        isCompanyGoalCreator: editedInfoData?.isCompanyGoalCreator,
        isDirectorateGoalCreator: editedInfoData?.isDirectorateGoalCreator,
        isDepartmentGoalCreator: editedInfoData?.isDepartmentGoalCreator,
        isDivisionGoalCreator: editedInfoData?.isDivisionGoalCreator,
        jobTitle: editedInfoData.jobTitle,
        name: editedInfoData.name,
        cultureRole: validateRole('cultureRole', 'culture_user'),
        role: validateRole('role', 'user')
      };

      editedInfoData.profilePicture &&
        editedInfoData.profilePicture !== infoData.profilePicture &&
        (editBody.profilePicture = editedInfoData.profilePicture);
      if (editedInfoData.password) {
        editBody.password = editedInfoData.password;
      }

      if (config?.editableUserFields?.email === 'disabled')
        delete editBody.email;
      const paramId = params.userId || editedInfoData?.id;
      if (paramId) {
        data = await updateUsers(editBody, paramId);
      } else {
        data = await createUsers(editBody);
      }

      if (data.data) {
        setInfoData(data.data);
        setEditedInfoData(data.data);
        setToastData({ isShow: true, message: 'Data saved', isWarning: false });
        setButtonSave(false);
        setIsModalOpen(false);
        !paramId && history.replace(`/users/edit/${data?.data?.id}`);
      }
    }
  };

  const savePosition = async () => {
    if (!editedPosition?.name || !editedPosition?.startsAt) {
      setIsError(true);
    } else {
      let tempEditedPosition = cloneDeep(editedPosition);
      if (
        checkBehaviorModelGroup &&
        tempEditedPosition?.groups?.behaviorGroup?.[0]?.id !==
          newBehaviorModelGroup?.id
      ) {
        tempEditedPosition.groups.behaviorGroup[0].id =
          newBehaviorModelGroup?.id;
        setEditedPosition(tempEditedPosition);
      }
      const isSuccess = await saveDataPosition(
        tempEditedPosition,
        params?.userId || infoData?.id
      );
      if (isSuccess) {
        refetchList(true);
        editedPosition?.state === 'active' &&
          setEditedInfoData({
            ...editedInfoData,
            permissionName: editedPosition?.groups?.permissionGroup?.[0]?.name
          });
        setToastData({
          isShow: true,
          message: getObjectiveLocale('Save position success!'),
          isWarning: false
        });
        setEditedPosition({});
        setPreviousBehaviorModelGroup({});
        setNewBehaviorModelGroup({});
      } else {
        setToastData({
          isShow: true,
          message: getObjectiveLocale('Save position failed!'),
          isWarning: true
        });
      }
      setIsModalOpen(false);
      setShowConfirmationModal(false);
    }
  };

  const saveModal = async (e) => {
    e.stopPropagation();
    currentTab === 'info' ? await saveTab() : savePosition();
    setIsModalOpen(false);
    setEditedPosition({});
    setCurrentTab(currentTab === 'info' ? 'position' : 'info');
    setButtonSave(false);
  };

  const cancelEdit = (e) => {
    e.stopPropagation();
    setCurrentTab(currentTab === 'info' ? 'position' : 'info');
    setIsModalOpen(false);
    if (currentTab === 'info') {
      setEditedInfoData(infoData);
    }
    setButtonSave(false);
  };

  const _onScroll = (e) => {
    const scrollTop = e.target.scrollTop - 50;
    const sectionsPos = listPositionNavigator.map((pos) => pos.id);
    sectionsPos.forEach((section) => {
      const el = document.getElementById(section);
      if (el && scrollTop >= el?.offsetTop) {
        setActiveSection(section);
      }
    });
    if (e.target.scrollTop + e.target.offsetHeight == e.target.scrollHeight) {
      setActiveSection(sectionsPos[sectionsPos.length - 1]);
      setEndScroll(true);
    }
  };

  const onClickSave = () => {
    if (checkBehaviorModelGroup) {
      setShowConfirmationModal(true);
    } else {
      savePosition();
    }
  };

  useEffect(() => {
    if (params.userId) getUserInfoDetail(params.userId);
  }, [params.userId]);

  useEffect(() => {
    if (currentTab === 'info') {
      isEqual(editedInfoData, infoData)
        ? setButtonSave(false)
        : setButtonSave(true);
    }
    setEditedPosition({});
  }, [editedInfoData, infoData]);

  useEffect(() => {
    if (toastData?.isShow) {
      setTimeout(() => {
        setToastData({
          isShow: false,
          message: toastData?.message,
          isWarning: toastData?.isWarning
        });
      }, 3000);
    }
  }, [toastData, setToastData]);

  return (
    <div
      className="fixed right-0 top-[65px] z-index-999 bg-n-000 w-[calc(100%-64px)] overflow-auto h-[calc(100%-64px)]"
      id="scrollable-content"
      onScroll={(e) => _onScroll(e)}
    >
      <div className="flex flex-col items-center">
        <ToastNotif
          showNotif={toastData.isShow}
          message={toastData.message}
          warning={toastData.isWarning}
        />
        <AdminTabsGeneral
          listObjTabs={listTab}
          active={currentTab}
          changeTab={(id) => checkChangeTab(id)}
          small={true}
          useBorder={true}
          customClass={`w-[768px] mb-[24px] max-h-[58px] pt-[16px] mr-[270px]`}
        />
        <div className="flex w-full justify-center">
          {currentTab === 'info' && (
            <>
              <InformationDetail
                isReadOnly={isReadOnly}
                setData={setEditedInfoData}
                data={editedInfoData}
              />
              <div className="w-[232px]" />
            </>
          )}
          {currentTab === 'position' && (
            <PositionDetail
              isReadOnly={isReadOnly}
              setToastData={setToastData}
              userAddedId={editedInfoData?.id}
              endScroll={endScroll}
              setEndScroll={setEndScroll}
              listPosition={listPosition}
              setListPosition={setListPosition}
              listPositionNavigator={listPositionNavigator}
              setListPositionNavigator={setListPositionNavigator}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
          )}
        </div>
      </div>
      {isButtonSaveShow && (
        <div className="flex flex-col items-center justify-center h-[72px] border-solid border-t border-0 border-n-400 fixed bottom-0 w-full z-index-10 bg-n-000">
          <div
            className={`w-[768px] flex mr-[270px] ${
              currentTab === 'position' ? 'justify-between' : 'justify-end'
            }`}
          >
            {currentTab === 'position' && (
              <div>
                <div className="typography-paragraph">
                  {isFetchingNewBehaviorModelGroup
                    ? `${getObjectiveLocale(
                        'Waiting for behavior model auto-involvement information'
                      )}...`
                    : `${getObjectiveLocale('Editing')} ${
                        editedPosition?.name
                      }...`}
                </div>
                <div className="typorgraphy-h100 text-n-800">
                  {getObjectiveLocale(
                    'You need to save this changes first before editing another position'
                  )}
                </div>
              </div>
            )}
            <div className="flex">
              <Button.Secondary
                disabled={isReadOnly}
                onClick={() =>
                  currentTab === 'info'
                    ? setEditedInfoData(infoData)
                    : (cancelChanges(editedPosition), setButtonSave(false))
                }
                customClass="mr-[8px]"
                datacy="cancel-user"
              >
                {getObjectiveLocale('Cancel')}
              </Button.Secondary>
              <Button
                disabled={
                  isReadOnly ||
                  isEditingBackground ||
                  isFetchingNewBehaviorModelGroup
                }
                onClick={() =>
                  currentTab === 'info' ? saveTab(currentTab) : onClickSave()
                }
                datacy="save-user"
              >
                {getObjectiveLocale('Save')}
              </Button>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <Modal
          className="max-w-[400px]"
          eventOnClickClose={() => setIsModalOpen(false)}
          withCloseIcon={true}
          withPrimaryBtn={{
            disabled: isReadOnly,
            title: 'Save',
            onClick: (e) => saveModal(e),
            dataCy: 'save-user-modal'
          }}
          withSecondaryBtn={{
            isabled: isReadOnly,
            title: 'Continue without Save',
            onClick: (e) => cancelEdit(e),
            dataCy: 'cancel-changes-user'
          }}
          withFooter={true}
          title={getObjectiveLocale('Unsaved Changes')}
          description={getObjectiveLocale(
            "Are you sure want to leave this page without saving changes? The changes will be discarded if you don't save it."
          )}
        />
      )}
      {showConfirmationModal && (
        <BehaviorModelGroupConfirmationModal
          previousGroupName={previousBehaviorModelGroup?.name}
          newGroupName={newBehaviorModelGroup?.name}
          savePosition={savePosition}
          setShowModal={setShowConfirmationModal}
        />
      )}
    </div>
  );
};

export default UserManagementDetail;

import React, { createRef, useState } from 'react';

function Zoomer(props) {
  const [scale, setScale] = useState(1.0);
  const [padding, setPadding] = useState(0); // for mozilla

  const plusRef = createRef();
  const minusRef = createRef();

  const zoom = (type, { scaleBool, disableBool, addedScale, addedPadding }) => {
    const thisRef = type === 'in' ? plusRef : minusRef;
    const otherRef = type === 'in' ? minusRef : plusRef;

    let superParentDOM = document.getElementsByClassName(
      'goal-tree-super-parent'
    )[0];

    if (!superParentDOM.style.zoom) {
      superParentDOM.style.transform = `scale(${scale})`;
    }

    if (scaleBool) {
      const newScale = scale + addedScale;
      const newPadding = padding + addedPadding;
      setScale(newScale);
      setPadding(newPadding);
      superParentDOM.style.transform = `scale(${newScale})`;
      superParentDOM.style.padding = `${newPadding}%`;
    }

    if (otherRef?.current) {
      otherRef?.current?.removeAttribute('disabled');
      otherRef?.current?.classList?.remove('disabled');
    }

    if (disableBool) {
      thisRef?.current?.setAttribute('disabled', true);
      thisRef?.current?.classList?.add('disabled');
    } else {
      thisRef?.current?.removeAttribute('disabled');
      thisRef?.current?.classList?.remove('disabled');
    }
  };

  const getBigger = () =>
    zoom('in', {
      scaleBool: scale <= 1.9,
      disableBool: scale > 1.9,
      addedScale: 0.1,
      addedPadding: 10
    });
  const getSmaller = () =>
    zoom('out', {
      scaleBool: scale > 0.7,
      disableBool: scale < 0.7,
      addedScale: -0.1,
      addedPadding: -10
    });
  const getOriginal = () =>
    zoom('normal', {
      scaleBool: true,
      disableBool: false,
      addedScale: -scale + 1,
      addedPadding: -padding
    });

  return (
    <div className="zoomer">
      <button
        className="btn bg-transparent rounded-t-[40px]"
        ref={plusRef}
        onClick={() => getBigger()}
      >
        +
      </button>
      <button id="full" onClick={() => getOriginal()}>
        <span />
        <span />
        <span />
      </button>
      <button
        className="btn bg-transparent rounded-b-[40px]"
        ref={minusRef}
        onClick={() => getSmaller()}
      >
        -
      </button>
    </div>
  );
}

export default Zoomer;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

function BehaviourReviewComponent(props) {
  const { recognition, feedback, isHorizontal, type } = props;
  return (
    <div className="behaviour-review-card">
      <div className="behaviour-type">
        {getObjectiveLocale(
          type === 'values' ? 'Standard of Leadership' : 'Skills'
        )}
      </div>
      <div
        className="behaviour-review-type-container"
        style={{ flexDirection: isHorizontal === true ? 'row' : 'column' }}
      >
        {recognition.length > 0 && (
          <div
            className={
              isHorizontal
                ? 'behaviour-review-type'
                : 'behaviour-review-type vertical'
            }
          >
            <div className="behaviour-section-container">
              <SVGIcon
                iconName="icon-strength"
                size={24}
                customClass="mr-[8px]"
              />
              <div className="behaviour">Strength Area</div>
            </div>
            {recognition &&
              recognition.map((recognition, index) => (
                <div key={index} className="behaviour-card">
                  <div className="behaviour">{recognition.object.title}</div>
                  <div className="behaviour-count-container">
                    <div className="behaviour-count">{recognition.count}</div>
                  </div>
                </div>
              ))}
          </div>
        )}
        {feedback.length > 0 && (
          <div
            className={
              isHorizontal
                ? 'behaviour-review-type'
                : 'behaviour-review-type vertical'
            }
          >
            <div className="behaviour-section-container">
              <SVGIcon
                iconName="icon-improvement"
                size={24}
                customClass="mr-[8px]"
              />
              <div className="behaviour improvement">Development Area</div>
            </div>
            {feedback &&
              feedback.map((feedback, index) => (
                <div key={index} className="behaviour-card improvement">
                  <div className="behaviour">{feedback.object.title}</div>
                  <div className="behaviour-count-container">
                    <div className="behaviour-count improvement">
                      {feedback.count}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default BehaviourReviewComponent;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';

const ObjectiveTitle = ({ objectiveDetail, cloneObj, setCloneObj }) => {
  return (
    <div>
      <p className="typography-h100 mb-[8px] text-n-800">
        {getObjectiveLocale('Title')}
      </p>
      <InputField
        dataCy="input-obj-name"
        placeholder={
          objectiveDetail?.type == 'task' ? 'Task Title' : 'Goal Title'
        }
        value={cloneObj?.name}
        handleChange={(e) =>
          setCloneObj({ ...cloneObj, name: e.currentTarget.value })
        }
      />
    </div>
  );
};

export default ObjectiveTitle;

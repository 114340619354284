import React, { useRef, useState } from 'react';

import 'scss/admin/_sidebar';

import { updateWorkloadStatus } from 'client/UserProfile';
import { useAuth } from 'context/AuthContext';
import { useUser } from 'context/UserContext';
import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils';

import DropdownProfile from 'components/dropdown/DropdownProfile';
import SVGIcon from 'components/shared/SVGIcon';

import { AppLogo, ModalColleague, ModalSwitchApp } from './HelperSidebar';
import MenuSection from './MenuSection';

function SidebarMinimized({
  toggleCheckin,
  shortcutMenu,
  dropDownSearchOpen,
  onSearchCollagues,
  isHaveNotif,
  sidebarMinimized,
  setModalChangePassword,
  setIsHaveNotif,
  isAppCulture,
  isAbleToSwitch,
  activitiesCount,
  approvalCount,
  surveyCount,
  feedbackCount,
  formalReviewCount,
  needResponseCount,
  needResponseReviewCount,
  needResponseCommentCount,
  isSwitchOpen,
  setIsSwitchOpen
}) {
  const { user, organization, appType, config } = useUser();
  const { logout } = useAuth();

  const [showRightMenu, setShowRightMenu] = useState(false);
  const [manageableStatus, setManageableStatus] = useState(user.workloadStatus);

  const refColleagueMin = useRef();
  const refRightMenu = useRef();
  const switchAppRefMin = useRef();
  const profileRefMin = useRef();

  useClickOutside(refColleagueMin, () => onSearchCollagues(false));
  useClickOutside(refRightMenu, () => setShowRightMenu(false));
  useClickOutside(switchAppRefMin, () => setIsSwitchOpen(false));

  return (
    <>
      {/* Switch App */}
      <div
        ref={switchAppRefMin}
        id="switch-app"
        className={`with-border ${
          isAbleToSwitch ? 'switch-app' : 'unswitch-app'
        }`}
        onClick={() => isAbleToSwitch && setIsSwitchOpen(!isSwitchOpen)}
      >
        <div className="current-app">
          <AppLogo isAppCulture={isAppCulture} />
        </div>
        {isSwitchOpen && (
          <ModalSwitchApp
            setIsSwitchOpen={setIsSwitchOpen}
            isAppCulture={isAppCulture}
            organization={organization}
            customClass="mt-[50px]"
          />
        )}
      </div>
      {/* Profile */}
      <div
        ref={profileRefMin}
        className="switch-app border-0 border-b-0 mt-[4px]"
      >
        <DropdownProfile
          isSidebarMaximized={false}
          user={user}
          manageableStatus={manageableStatus}
          setManageableStatus={setManageableStatus}
          setModalChangePassword={setModalChangePassword}
          updateWorkloadStatus={updateWorkloadStatus}
          logout={logout}
        />
      </div>
      {/* Check in */}
      {user.role && appType !== 'culture' && config?.showCheckInButton && (
        <div className="check-in" data-cy="button-check-in">
          <div className="tooltip-shortcut typography-h100">
            {getObjectiveLocale('Check-in')}
          </div>
          <SVGIcon
            iconName="icon-where_to_vote"
            fillColor="var(--n-000)"
            size="24"
            onClick={toggleCheckin}
          />
        </div>
      )}
      {/* Shortcut Menu */}
      <div className="shortcut-menu">
        {dropDownSearchOpen && sidebarMinimized && (
          <ModalColleague
            ref={refColleagueMin}
            onSearchCollagues={onSearchCollagues}
          />
        )}
        {shortcutMenu.map((menu, key) => {
          return (
            <div
              className="shortcut-menu-item py-[8px]"
              data-cy={menu.dataCy}
              onClick={(e) => menu.onClick(e)}
              key={key}
            >
              <span className="relative">
                <SVGIcon
                  size="24"
                  fillColor="var(--n-000)"
                  iconName={menu.icon}
                />
                {menu?.notificationCount > 0 && (
                  <SVGIcon
                    iconName="icon-circle"
                    fillColor="var(--r-600)"
                    size="8"
                    customClass="icon-notif"
                  />
                )}
              </span>
              <div className="tooltip-shortcut typography-h100">
                {menu.title}
              </div>
            </div>
          );
        })}
      </div>
      {/* Sidebar Menu */}
      <div
        className="shortcut-menu-item"
        onClick={(e) => {
          e.stopPropagation();
          setShowRightMenu(!showRightMenu);
        }}
      >
        <span className="relative">
          <SVGIcon
            size="24"
            fillColor="var(--n-000)"
            iconName="icon-view_list"
          />
          {isHaveNotif && (
            <SVGIcon
              iconName="icon-circle"
              fillColor="var(--r-600)"
              size="8"
              customClass="icon-notif"
            />
          )}
        </span>
        <div className="tooltip-shortcut typography-h100">
          {getObjectiveLocale('Show menus')}
        </div>
      </div>
      <div
        className={`sidebar-menu-new overflow-y ${
          showRightMenu ? 'show-right-menu' : ''
        }`}
        ref={refRightMenu}
      >
        <MenuSection
          isHaveNotif={isHaveNotif}
          setIsHaveNotif={setIsHaveNotif}
          setShowRightMenu={() => setShowRightMenu(false)}
          activitiesCount={activitiesCount}
          approvalCount={approvalCount}
          needResponseCount={needResponseCount}
          surveyCount={surveyCount}
          feedbackCount={feedbackCount}
          formalReviewCount={formalReviewCount}
          needResponseReviewCount={needResponseReviewCount}
          needResponseCommentCount={needResponseCommentCount}
        />
      </div>
    </>
  );
}

export default SidebarMinimized;

import React, { useState } from 'react';

import { useGoalWeightSettingsContext } from 'context/GoalWeightSettingContext';
import { mappingColor } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';

const colors = [
  '14532D',
  '7C2D12',
  '1E3A8A',
  '713F12',
  '78350F',
  '881337',
  '164E63',
  '064E3B',
  '701A75',
  '312E81',
  '0C4A6E',
  '365314',
  '134E4A'
];
const randomIndex = Math.floor(Math.random(colors.length) * 10);

const colorSelection = colors
  .map((value) => {
    let { colorBackground, bgColorHex } = mappingColor(value, 'full');
    return {
      id: value.toUpperCase(),
      value: colorBackground,
      colorHex: value,
      bgColorHex
    };
  })
  .sort((a, b) => a.value.localeCompare(b.value));

const CategoryColorSwitch = ({ defaultValue, changeDataObject }) => {
  const { isReadOnly } = useGoalWeightSettingsContext();

  const defaultSelected =
    colorSelection.find((data) => data.id == defaultValue) ||
    colorSelection[randomIndex];

  const [selectedColor, setSelectedColor] = useState(defaultSelected);

  const toggleDropdown = (value) => {
    setSelectedColor(value);
    changeDataObject({
      colorHex: value.colorHex,
      bgColorHex: value.bgColorHex
    });
  };

  return (
    <Dropdown>
      <Dropdown.Trigger dataCy="dropdown-color" disabled={isReadOnly}>
        <div
          className={`w-[36px] h-[36px] rounded-[4px] ${
            mappingColor(selectedColor.colorHex.toUpperCase(), 'tw').twBg
          }`}
        />
      </Dropdown.Trigger>
      <Dropdown.MenuItems
        customClass="w-[144px]"
        maxHeight={312}
        dataCy="dropdown-color"
      >
        {colorSelection.map((color) => (
          <Dropdown.MenuItem
            key={color.id}
            onClick={() => toggleDropdown(color)}
            dataCy="dropdown-color"
          >
            <div
              className={`h-[20px] w-[20px] mr-[8px] rounded-[50px] ${
                mappingColor(color.id, 'tw').twBg
              }`}
            />
            {color.value}
          </Dropdown.MenuItem>
        ))}
      </Dropdown.MenuItems>
    </Dropdown>
  );
};

export default CategoryColorSwitch;

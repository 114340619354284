import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { useUser } from 'context/UserContext';
import {
  formattedFloat,
  getNumberLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';
import { getClusters } from 'utils/ObjectivesHelper';
import { CALC_TYPE_LESS_IS_BETTER, CALC_TYPE_ZERO_TARGET } from 'utils/const';

import FormattedNumber from 'components/design-system/FormattedNumber';
import ProgressBar from 'components/design-system/progress-bar/ProgressBar';
import Table from 'components/design-system/table/Table';

const ObjectiveProgressSummaryTable = ({
  parentObjective,
  objectives,
  setShowFootNoteLessIsBetter,
  setShowFootNoteZeroTarget
}) => {
  const {
    config: { objectiveSummaryColumns }
  } = useUser();

  const header = [
    {
      id: 'name',
      name: 'GOAL NAME',
      widthClass: 'w-[224px]',
      heightClass: 'min-h-[56px]',
      customClass: 'border-0 border-b-[1px] border-solid border-n-400'
    },
    {
      id: 'due_date',
      name: 'DUE DATE',
      widthClass: 'w-[144px]',
      heightClass: 'min-h-[56px]',
      customClass: 'border-0 border-b-[1px] border-solid border-n-400'
    },
    {
      id: 'weight',
      name: 'WEIGHT',
      widthClass: 'w-[80px]',
      heightClass: 'min-h-[56px]',
      customClass:
        'justify-end border-0 border-b-[1px] border-solid border-n-400',
      useHeaderMargin: false
    },
    {
      id: 'target',
      name: 'TARGET',
      widthClass: 'w-[96px]',
      heightClass: 'min-h-[56px]',
      customClass:
        'justify-end border-0 border-b-[1px] border-solid border-n-400',
      useHeaderMargin: false
    },
    {
      id: 'current_progress',
      name: 'REALIZATION',
      widthClass: 'w-[144px]',
      heightClass: 'min-h-[56px]',
      customClass:
        'justify-end border-0 border-b-[1px] border-solid border-n-400',
      useHeaderMargin: false
    },
    {
      id: 'current_progress_percentage',
      name: 'PROGRESS (%)',
      widthClass: 'w-[200px]',
      heightClass: 'min-h-[56px]',
      customClass:
        'justify-end border-0 border-b-[1px] border-solid border-n-400',
      useHeaderMargin: false
    },
    {
      id: 'last_updated',
      name: 'LAST UPDATED',
      widthClass: 'w-[144px]',
      heightClass: 'min-h-[56px]',
      customClass:
        'justify-end border-0 border-b-[1px] border-solid border-n-400',
      useHeaderMargin: false
    },
    {
      id: 'score',
      name: 'SCORE',
      widthClass: 'w-[144px]',
      heightClass: 'min-h-[56px]',
      customClass:
        'justify-end border-0 border-b-[1px] border-solid border-n-400',
      useHeaderMargin: false
    }
  ];

  const tableHeaders = objectiveSummaryColumns.map((column) =>
    header.find(({ id }) => id === column)
  );

  return (
    <Table
      headers={tableHeaders}
      useBorderTopBottom={false}
      customClassTypographyHeader="typography-h400 text-n-900"
    >
      {!isEmpty(parentObjective) && (
        <TableContents
          objective={parentObjective}
          isSub={false}
          setShowFootNoteLessIsBetter={setShowFootNoteLessIsBetter}
          setShowFootNoteZeroTarget={setShowFootNoteZeroTarget}
        />
      )}
      {objectives?.length > 0 &&
        objectives?.map((objective, index) => (
          <TableContents
            objective={objective}
            isSub={!isEmpty(parentObjective)}
            setShowFootNoteLessIsBetter={setShowFootNoteLessIsBetter}
            setShowFootNoteZeroTarget={setShowFootNoteZeroTarget}
            key={index}
          />
        ))}
    </Table>
  );
};

const TableContents = ({
  objective,
  isSub,
  setShowFootNoteLessIsBetter,
  setShowFootNoteZeroTarget
}) => {
  const {
    config: { objectiveSummaryColumns }
  } = useUser();

  return (
    <Table.Row
      customClass={isSub ? 'bg-n-200' : ''}
      useRowHover={false}
      isHeader={false}
    >
      {objectiveSummaryColumns?.map((column, index) => {
        return (
          <Table.Column
            key={index}
            customClass={column === 'weight' ? 'justify-end' : ''}
          >
            <TableItem
              columnName={column}
              objective={objective}
              isSub={isSub}
              setShowFootNoteLessIsBetter={setShowFootNoteLessIsBetter}
              setShowFootNoteZeroTarget={setShowFootNoteZeroTarget}
            />
          </Table.Column>
        );
      })}
    </Table.Row>
  );
};

const TableItem = ({
  columnName,
  objective,
  isSub,
  setShowFootNoteLessIsBetter,
  setShowFootNoteZeroTarget
}) => {
  const {
    config: { ratingMechanism }
  } = useUser();

  const clusters = getClusters(objective);

  const {
    name,
    dueDate,
    weight,
    measurement,
    startDate,
    progressUpdated,
    reviews,
    scoringMark,
    scoringTemplate,
    calculationType,
    valueLastUpdatedAt = dayjs()
  } = objective || {};
  const {
    targetValue,
    currentValue,
    unit,
    progressPercentage,
    progressColorHex
  } = measurement || {};

  const { colorHex: scoreColorHex } = scoringMark || {};
  const { showOption } = scoringTemplate || {};

  const usingWorkAttribute = reviews?.[0]?.reviewChoices[0]?.workAttribute;
  const scoringData = usingWorkAttribute
    ? reviews[0].reviewChoices[0].workAttribute
    : reviews[0]?.reviewChoices[0]?.scoringMark;

  const scoreReviews =
    ratingMechanism == 'progress_times_weight' ||
    ratingMechanism == 'custom_formula'
      ? reviews?.[0]?.score
      : scoringData?.score;

  const isUnitPercentage = unit === '%';
  const notVisible = getObjectiveLocale('Not Visible');

  const nowDate = new dayjs();
  const emptyProgress = nowDate.isAfter(startDate);

  const dateFormatted = (date) => {
    return dayjs(date).format('MMM D, YYYY');
  };

  useEffect(() => {
    calculationType === CALC_TYPE_LESS_IS_BETTER &&
      setShowFootNoteLessIsBetter(true);
    calculationType == CALC_TYPE_ZERO_TARGET && setShowFootNoteZeroTarget(true);
  }, [calculationType, setShowFootNoteLessIsBetter, setShowFootNoteZeroTarget]);

  const lastUpdatedColumn = (
    <p className="typography-paragraph text-n-900">
      {clusters?.includes('valueLastUpdatedAt')
        ? dateFormatted(valueLastUpdatedAt)
        : notVisible}
    </p>
  );

  const nameColumn = (
    <p
      className={`typography-paragraph text-n-900 multiline-text-4 ${
        isSub ? 'ml-[16px]' : ''
      }`}
    >
      {name}
    </p>
  );

  const dueDateColumn = (
    <p className="typography-paragraph text-n-900">
      {clusters?.includes('dueDate')
        ? dayjs(dueDate).format('MMM D, YYYY')
        : notVisible}
    </p>
  );

  const weightColumn = (
    <p className="typography-paragraph text-n-900 text-right">
      {clusters?.includes('weight') ? (
        weight != null ? (
          <FormattedNumber
            number={weight}
            minDecimalLength={0}
            customClass="max-w-[80px] truncate"
          />
        ) : (
          '-'
        )
      ) : (
        notVisible
      )}
    </p>
  );

  const targetColumn = (
    <p className="typography-paragraph text-n-900 text-right">
      {clusters?.includes('metric') ? (
        targetValue ? (
          <>
            {!isUnitPercentage && unit + ' '}
            <FormattedNumber
              number={targetValue}
              minDecimalLength={0}
              customClass="max-w-[80px] truncate"
            />
            {isUnitPercentage && unit}
          </>
        ) : (
          '-'
        )
      ) : (
        notVisible
      )}
    </p>
  );

  const currentProgressPercentageColumn = (
    <div className="flex justify-between items-center w-full">
      <ProgressBar
        width={96}
        height={12}
        actualProgress={progressPercentage ? progressPercentage : 0}
        expectedProgress={100}
        actualColor={progressColorHex}
        useAnimation={false}
        containerClass="ml-[8px]"
      />
      <div className="flex items-center text-right">
        {clusters?.includes('progress') ? (
          <>
            <p className="typography-paragraph text-n-900 max-w-[48px] truncate">
              {progressPercentage ? getNumberLocale(progressPercentage) : 0}
            </p>
            <p className="typography-paragraph text-n-900">%</p>
          </>
        ) : (
          notVisible
        )}
      </div>
    </div>
  );

  const currentProgressColumn = (
    <p className="typography-paragraph text-n-900 text-right">
      {clusters?.includes('metric') ? (
        emptyProgress ? (
          !progressUpdated ? (
            getObjectiveLocale('Not updated')
          ) : (
            <>
              {!isUnitPercentage && unit + ' '}
              <FormattedNumber
                number={currentValue}
                minDecimalLength={0}
                customClass="max-w-[80px] truncate"
              />
              {isUnitPercentage && unit}
            </>
          )
        ) : (
          getObjectiveLocale('Not started')
        )
      ) : (
        notVisible
      )}
      {calculationType === CALC_TYPE_LESS_IS_BETTER && <span>*</span>}
      {calculationType === CALC_TYPE_ZERO_TARGET && <span>**</span>}
    </p>
  );

  const scoreColumn = (
    <div className="flex flex-col items-end">
      <p className="typography-paragraph text-n-900">
        {showOption != 'label_only' && scoreReviews != null
          ? formattedFloat(scoreReviews || 0)
          : '-'}
      </p>
      {showOption != 'score_only' && scoringData?.name && (
        <p className="typography-h100" style={{ color: '#' + scoreColorHex }}>
          {scoringData?.name}
        </p>
      )}
    </div>
  );

  switch (columnName) {
    case 'name':
      return nameColumn;
    case 'due_date':
      return dueDateColumn;
    case 'weight':
      return weightColumn;
    case 'target':
      return targetColumn;
    case 'current_progress':
      return currentProgressColumn;
    case 'current_progress_percentage':
      return currentProgressPercentageColumn;
    case 'score':
      return scoreColumn;
    default:
      return lastUpdatedColumn;
  }
};

export default ObjectiveProgressSummaryTable;

import React from 'react';
import Linkify from 'react-linkify';

import { useUser } from 'context/UserContext';

import ProfileEvidence from 'components/formal-review/evidence-sidebar/ProfileEvidence.js';
import Checkbox from 'src/components/shared/Checkbox';
import RadioButton from 'src/components/shared/RadioButton';

const QuestionnaireAnswersContent = ({ answers, question }) => {
  const { organization } = useUser();

  return (
    <div className="questionnaire-answer-content-container px-[16px]">
      {answers.map((answer, idx) => {
        const isLastIndex = idx == answers.length - 1;
        const showAvatar = !(
          organization.identifier == 'bca' && answer?.user?.name == 'anonymous'
        );

        return (
          <React.Fragment key={idx}>
            {showAvatar && <ProfileEvidence profileData={answer?.user} />}
            <div
              className={`wrapper-card-detail mt-[8px] ${
                showAvatar && 'ml-[40px]'
              }`}
              key={idx}
              data-cy="questionnaire-answer-container"
            >
              <div className="w-full relative min-h-[20px]">
                {question?.questionType === 'multiple_choices' &&
                  answer.options && (
                    <div className="mb-[8px] flex items-center">
                      <RadioButton
                        size="small"
                        checked={true}
                        labelClass="typography-h400 text-base-600 ml-[8px]"
                        addClass="w-full"
                      >
                        {answer.options[0].option}
                      </RadioButton>
                    </div>
                  )}
                {question?.questionType === 'multiple_selections' &&
                  answer.options &&
                  answer.options.map((option, index) => {
                    return (
                      <div
                        key={index}
                        className="sidebar-checkmark-label-container mb-[8px] h-[24px] flex items-center"
                      >
                        <Checkbox
                          customContainerClass={'absolute'}
                          checked={true}
                        />
                        <p className="typography-h400 text-base-600 pl-[20px] ml-[8px]">
                          {option?.option}
                        </p>
                      </div>
                    );
                  })}
                {question?.questionType === 'rating' && answer.options && (
                  <div className="sidebar-rating-container mb-[8px]">
                    <div className="sidebar-rating-square">
                      <label className="sidebar-rating-value-text">
                        {answer.options[0].value}
                      </label>
                    </div>
                    {question?.optionsLabelVisibility && (
                      <p className="typography-h400 text-base-600 inline-block pl-[20px] ml-[8px]">
                        {answer.options[0].option}
                      </p>
                    )}
                  </div>
                )}

                {answer?.comment && (
                  <Linkify
                    className="typography-paragraph typography-secondary whitespace-pre-wrap break-words"
                    properties={{ target: '_blank' }}
                  >
                    {answer?.comment}
                  </Linkify>
                )}

                {!isLastIndex && <div className="divider-card ml-[0px]" />}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default QuestionnaireAnswersContent;

import React, { useEffect, useState } from 'react';

import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import {
  getObjectiveLocale,
  getUniqueArrayOfObjects
} from 'utils/HelperUtils.js';

import TextAreaWithMention from 'components/shared/TextAreaWithMention/TextAreaWithMention';
import PrefillSuggestion from 'src/components/formal-review/annual-review/PrefillSuggestion';

import CheckboxGroup from './CheckboxGroup.js';
import Question from './Question';
import RadioGroup from './RadioGroup';
import ScoreBox from './ScoreBox';

function GeneralQuestion({ sections, position }) {
  const [
    { currentTrack, currentTrackId, phase, isPrefillCompleted },
    { getSingleTrack, setAnswerContext }
  ] = useFormalReview();
  let singleTrack = getSingleTrack(currentTrackId);
  let { questionnaireAnswers, questionnaireSuggestion, trackConfig } =
    singleTrack;

  const [localAnswers, setLocalAnswers] = useState(questionnaireAnswers);
  const [shouldUpdateValue, setShouldUpdateValue] = useState(false);

  const {
    config: { formalReviewMentionInCommentEnabled }
  } = useUser();

  const setAnswer = (questionId, optionIds, comment, typeQuestion) => {
    const newAnswers = localAnswers.map((answer) => {
      return {
        questionId: answer.questionId,
        optionIds: answer.optionIds,
        comment: answer.comment
      };
    });

    if (typeQuestion === 'option') {
      if (
        Object.values(newAnswers).some(
          (answer) => answer.questionId == questionId
        )
      ) {
        for (let i = 0; i < newAnswers.length; i++) {
          if (newAnswers[i].questionId === questionId) {
            newAnswers[i].optionIds = optionIds;
          }
        }
      } else {
        newAnswers.push({
          questionId,
          optionIds
        });
      }
    } else if (typeQuestion === 'comment') {
      if (
        Object.values(newAnswers).some(
          (answer) => answer.questionId == questionId
        )
      ) {
        for (let i = 0; i < newAnswers.length; i++) {
          if (newAnswers[i].questionId === questionId) {
            newAnswers[i].comment = comment;
          }
        }
      } else {
        newAnswers.push({
          questionId,
          comment
        });
      }
    }
    const filterUniqueArrayAnswers = getUniqueArrayOfObjects(
      newAnswers,
      'questionId'
    );
    setLocalAnswers(newAnswers);
    setAnswerContext(currentTrack, filterUniqueArrayAnswers, 'questionnaire');
  };

  // Sync answers & comment when prefilled
  useEffect(() => {
    if (isPrefillCompleted) {
      setLocalAnswers(questionnaireAnswers);
    }
  }, [isPrefillCompleted, setLocalAnswers, questionnaireAnswers]);

  useEffect(() => {
    setShouldUpdateValue(isPrefillCompleted);
  }, [isPrefillCompleted]);

  return (
    <div
      className={`questions-container ${
        currentTrack !== 'general_questionnaire' &&
        (position == 'before'
          ? 'mb-[40px] pb-[16px] border-bottom-400'
          : 'mt-[40px] border-top-400')
      }`}
    >
      {sections?.map((section, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <div className="border-solid border-0 border-t-[1px] border-n-400 mt-[40px]"></div>
            )}
            <p className="typography-h600 text-n-900 mt-[24px] mb-[24px]">
              {section?.name}
            </p>
            {section?.questions?.map((question, index) => {
              const answer = localAnswers.filter(
                (obj) => obj.questionId === question.id
              );
              const suggestionData = questionnaireSuggestion?.find(
                (obj) => obj.questionId === question.id
              );
              question?.questionType != 'comments' &&
                question?.options?.sort((a, b) => a['value'] - b['value']);
              return (
                <Question
                  question={`${index + 1}. ${question.question}`}
                  key={question.id}
                >
                  {question?.questionType === 'multiple_choices' ? (
                    <>
                      <RadioGroup
                        name={`question${index + 1}`}
                        align="vertical"
                        options={question.options}
                        answer={
                          answer.length > 0 &&
                          Object.prototype.hasOwnProperty.call(
                            answer?.[0],
                            'optionIds'
                          ) &&
                          answer?.[0].optionIds?.[0]
                        }
                        type={question.questionType}
                        setAnswer={setAnswer}
                        id={question.id}
                      />

                      {question.needComment !== 'no' && (
                        <TextAreaWithMention
                          setComment={(value) =>
                            setAnswer(
                              question.id,
                              null,
                              value,
                              'comment',
                              question.questionType
                            )
                          }
                          defaultValue={answer[0]?.comment}
                          shouldUpdateValue={shouldUpdateValue}
                          setShouldUpdateValue={setShouldUpdateValue}
                          customClass="h-[98px]"
                          ableToFocusGrow={false}
                          mentionComponent={(mentionProps) => {
                            return <span>{mentionProps.children}</span>;
                          }}
                          label={
                            getObjectiveLocale(`Comment only`) +
                            ` (${getObjectiveLocale(
                              question.needComment || 'optional'
                            )})`
                          }
                          placeholder={`[${currentTrack.replace(
                            /_/g,
                            ' '
                          )}] Type your answer here...`}
                          mentionEnabled={formalReviewMentionInCommentEnabled}
                        />
                      )}
                    </>
                  ) : question?.questionType === 'multiple_selections' ? (
                    <>
                      <CheckboxGroup
                        name={`checkbox${index + 1}`}
                        options={question.options}
                        answers={answer.length > 0 ? answer[0].optionIds : ''}
                        type={question.questionType}
                        id={question.id}
                        setAnswer={setAnswer}
                      />
                      {question.needComment !== 'no' && (
                        <TextAreaWithMention
                          setComment={(value) =>
                            setAnswer(
                              question.id,
                              null,
                              value,
                              'comment',
                              question.questionType
                            )
                          }
                          defaultValue={answer[0]?.comment}
                          shouldUpdateValue={shouldUpdateValue}
                          setShouldUpdateValue={setShouldUpdateValue}
                          customClass="h-[98px]"
                          ableToFocusGrow={false}
                          mentionComponent={(mentionProps) => {
                            return <span>{mentionProps.children}</span>;
                          }}
                          label={
                            getObjectiveLocale(`Comment only`) +
                            ` (${getObjectiveLocale(
                              question.needComment || 'optional'
                            )})`
                          }
                          placeholder={`[${currentTrack.replace(
                            /_/g,
                            ' '
                          )}] Type your answer here...`}
                          mentionEnabled={formalReviewMentionInCommentEnabled}
                        />
                      )}
                    </>
                  ) : question?.questionType === 'comments' ? (
                    question.needComment !== 'no' && (
                      <TextAreaWithMention
                        customClass="h-[98px]"
                        setComment={(value) =>
                          setAnswer(
                            question.id,
                            null,
                            value,
                            'comment',
                            question.questionType
                          )
                        }
                        defaultValue={answer[0]?.comment}
                        shouldUpdateValue={shouldUpdateValue}
                        setShouldUpdateValue={setShouldUpdateValue}
                        ableToFocusGrow={false}
                        mentionComponent={(mentionProps) => {
                          return <span>{mentionProps.children}</span>;
                        }}
                        label={
                          getObjectiveLocale(`Comment only`) +
                          ` (${getObjectiveLocale(
                            question.needComment || 'optional'
                          )})`
                        }
                        placeholder={`[${currentTrack.replace(
                          /_/g,
                          ' '
                        )}] Type your answer here...`}
                        mentionEnabled={formalReviewMentionInCommentEnabled}
                      />
                    )
                  ) : (
                    <>
                      <div
                        className={`w-full mb-[16px] pb-[8px] overflow-y-auto flex`}
                      >
                        {question?.options?.map((option) => {
                          return (
                            <>
                              <div id={`portal-score-box-${option.id}`} />
                              <ScoreBox
                                option={option}
                                onClick={() =>
                                  setAnswer(
                                    question.id,
                                    [parseInt(option.id)],
                                    null,
                                    'option'
                                  )
                                }
                                selectedId={
                                  answer.length > 0 &&
                                  Object.prototype.hasOwnProperty.call(
                                    answer?.[0],
                                    'optionIds'
                                  ) &&
                                  answer?.[0]?.optionIds?.[0]
                                }
                                usePortal={`portal-score-box-${option.id}`}
                                showLabel={question?.optionsLabelVisibility}
                              />
                            </>
                          );
                        })}
                      </div>
                      {question.needComment !== 'no' && (
                        <TextAreaWithMention
                          setComment={(value) =>
                            setAnswer(
                              question.id,
                              null,
                              value,
                              'comment',
                              question.questionType
                            )
                          }
                          defaultValue={answer[0]?.comment}
                          shouldUpdateValue={shouldUpdateValue}
                          setShouldUpdateValue={setShouldUpdateValue}
                          customClass="h-[98px]"
                          ableToFocusGrow={false}
                          mentionComponent={(mentionProps) => {
                            return <span>{mentionProps.children}</span>;
                          }}
                          label={
                            getObjectiveLocale(`Comment only`) +
                            ` (${getObjectiveLocale(
                              question.needComment || 'optional'
                            )})`
                          }
                          placeholder={`[${currentTrack.replace(
                            /_/g,
                            ' '
                          )}] Type your answer here...`}
                          mentionEnabled={formalReviewMentionInCommentEnabled}
                        />
                      )}
                    </>
                  )}

                  {suggestionData && (
                    <PrefillSuggestion
                      phaseType={phase.type}
                      viewType={question?.questionType}
                      suggestionData={suggestionData}
                      options={question.options}
                      showLabel={question?.optionsLabelVisibility}
                      suggestionSource={trackConfig?.suggestionSource}
                    />
                  )}
                </Question>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default GeneralQuestion;

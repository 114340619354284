import React from 'react';

import PropTypes from 'prop-types';

import Avatar from 'components/shared/Avatar';

function List({
  className,
  children,
  text,
  withIcon,
  src,
  withIconBold,
  name,
  isCenter = true
}) {
  return (
    <div
      className={`${className}
      rounded-[4px] max-w-[400px] flex justify-center
      ${isCenter ? 'items-center' : 'items-start'}
    `}
    >
      {withIcon && (
        <div className="flex mr-[8px]">
          <Avatar name={name} src={src} size={32} />
          <p className="typography-paragraph mt-[0px]">{text}</p>
        </div>
      )}
      {withIconBold && (
        <div className="flex mr-[8px]">
          <Avatar name={name} src={src} size={32} />
          <p className="typography-h-500 mt-[0px]">{text}</p>
        </div>
      )}
      {children}
    </div>
  );
}

List.PropTypes = {
  withIcon: PropTypes.bool,
  withIconBold: PropTypes.bool
};

export default List;

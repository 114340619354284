import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import TimeField from 'components/admin/usermanagement/TimeField';
import Modal from 'components/design-system/Modal';

function ProfileConfigurations({
  closeModal,
  configurations,
  changeConfig,
  resetData,
  saveData
}) {
  return (
    <>
      <Modal
        onClose={() => {
          resetData();
          closeModal(false);
        }}
      >
        <Modal.Header
          title={getObjectiveLocale('Configurations')}
          onClose={() => {
            resetData();
            closeModal(false);
          }}
          useBorder={false}
        />
        <Modal.Body>
          <div className="w-full h-max px-[24px] pb-[24px]">
            <div className="pb-[24px] border-bottom-400">
              <p className="typography-h300 text-n-600 uppercase">
                {getObjectiveLocale('Goal Period')}
              </p>
              <TimeField
                customClass="mr-[16px]"
                onSelectDate={(date) =>
                  changeConfig(date, [
                    'goalCreationStartsAt',
                    'goalCreationEndsAt'
                  ])
                }
                selectedDate={{
                  start: configurations?.goalCreationStartsAt,
                  end: configurations?.goalCreationEndsAt
                }}
                label={getObjectiveLocale('Goal Creation Period')}
                isSingle={false}
              />
              <TimeField
                customClass="mr-[16px]"
                onSelectDate={(date) =>
                  changeConfig(date, ['goalEditStartsAt', 'goalEditEndsAt'])
                }
                selectedDate={{
                  start: configurations?.goalEditStartsAt,
                  end: configurations?.goalEditEndsAt
                }}
                label={getObjectiveLocale('Goal Editing Period')}
                isSingle={false}
              />
              <TimeField
                customClass="mr-[16px]"
                onSelectDate={(date) =>
                  changeConfig(date, ['goalDeleteStartsAt', 'goalDeleteEndsAt'])
                }
                selectedDate={{
                  start: configurations?.goalDeleteStartsAt,
                  end: configurations?.goalDeleteEndsAt
                }}
                label={getObjectiveLocale('Goal Deletion Period')}
                isSingle={false}
              />
            </div>
            <div className="task-section mt-[24px] mb-[24px]">
              <p className="typography-h300 text-n-600 uppercase">
                {getObjectiveLocale('Task Period')}
              </p>
              <TimeField
                customClass="mr-[16px]"
                onSelectDate={(date) =>
                  changeConfig(date, [
                    'taskCreationStartsAt',
                    'taskCreationEndsAt'
                  ])
                }
                selectedDate={{
                  start: configurations?.taskCreationStartsAt,
                  end: configurations?.taskCreationEndsAt
                }}
                label={getObjectiveLocale('Task Creation Period')}
                isSingle={false}
                position="right"
              />
              <TimeField
                customClass="mr-[16px]"
                onSelectDate={(date) =>
                  changeConfig(date, ['taskEditStartsAt', 'taskEditEndsAt'])
                }
                selectedDate={{
                  start: configurations?.taskEditStartsAt,
                  end: configurations?.taskEditEndsAt
                }}
                label={getObjectiveLocale('Task Editing Period')}
                isSingle={false}
              />
              <TimeField
                customClass="mr-[16px]"
                onSelectDate={(date) =>
                  changeConfig(date, ['taskDeleteStartsAt', 'taskDeleteEndsAt'])
                }
                selectedDate={{
                  start: configurations?.taskDeleteStartsAt,
                  end: configurations?.taskDeleteEndsAt
                }}
                label={getObjectiveLocale('Task Deletion Period')}
                isSingle={false}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          primaryButton={{
            text: 'Save',
            onClick: () => saveData()
          }}
          secondaryButton={{
            text: 'Cancel',
            onClick: () => {
              resetData();
              closeModal(false);
            }
          }}
        />
      </Modal>
    </>
  );
}

export default ProfileConfigurations;

import React from 'react';

import SVGIcon from 'components/shared/SVGIcon';

const Parent = ({ objectiveDetail }) => {
  const { parents } = objectiveDetail;

  return (
    <div className="px-[24px]">
      <div
        className="flex justify-start items-start mt-[12px] mb-[12px]"
        data-cy="sidebar-detail-parent"
      >
        <SVGIcon
          iconName="icon-arrow_upward"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <div className="flex flex-col">
          {parents?.length > 0 &&
            parents?.map((parent, index) => (
              <div
                key={index}
                className={`flex items-center h-[24px] ${
                  index !== parents?.length - 1 ? 'mb-[24px]' : ''
                }`}
              >
                <p className="typography-paragraph text-n-900">
                  {parent?.name}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Parent;

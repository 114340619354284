import React, { useEffect, useState } from 'react';

import { updateTeam } from 'client/TeamClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useTeamDetail } from 'context/team/TeamDetailContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';
import Modal from 'components/shared/modal/Modal';

const EditTeamNameModal = ({ setShowModal }) => {
  const { data: team, refetchTeam } = useTeamDetail();
  const { id: teamId, name: teamName } = team || [];

  const [currentTeamName, setCurrentTeamName] = useState('');

  const { refetchQueries } = useRefetchQuery();

  const handleClickSave = async () => {
    if (currentTeamName === '') return;

    const { isSuccess } = await updateTeam(teamId, {
      name: currentTeamName
    });
    if (isSuccess) {
      refetchTeam(teamId);
      refetchQueries(['team', 'activities', teamId]);
    }

    setShowModal(false);
  };

  useEffect(() => {
    setCurrentTeamName(teamName || '');
  }, [teamName]);

  return (
    <Modal
      title="Rename Squad"
      withPrimaryBtn={{
        title: 'Save',
        onClick: () => handleClickSave(),
        dataCy: 'edit-project-name-save'
      }}
      withSecondaryBtn={{ title: 'Cancel', onClick: () => setShowModal(false) }}
      withCloseIcon
      eventOnClickClose={() => setShowModal(false)}
    >
      <p className="typography-h100 text-n-800 mb-[8px]">
        {getObjectiveLocale('Squad name')}
      </p>
      <InputField
        inputClass="typography-paragraph text-n-900 px-[16px] py-[4px]"
        placeholder="Squad name"
        dataCy="edit-squad-name-input"
        value={currentTeamName}
        handleChange={(e) => setCurrentTeamName(e.currentTarget.value)}
      />
    </Modal>
  );
};

export default EditTeamNameModal;

import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidV4 } from 'uuid';

import { useRefetchQuery } from 'context/RefetchQueryContext';
import useEditPhase from 'hooks/useEditPhase';
import useModalObjectives from 'hooks/useModalObjectives';
import useTasks from 'hooks/useTasks';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { convertGroupNameToLowerCase } from 'utils/TasksHelper';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

import CardPhase from './CardPhase';

const ModalEditPhase = ({ closeModal, projectId, type }) => {
  // ** Hook for useTasks
  const listGroups = useTasks((state) => state.listGroups);
  const setListGroups = useTasks((state) => state.setListGroups);
  const updateTasksTwoLevels = useTasks((state) => state.updateTasksTwoLevels);

  // ** Hook for useEditPhase
  const editedPhaseData = useEditPhase((state) => state.editedPhaseData);
  const setEditedPhaseData = useEditPhase((state) => state.setEditedPhaseData);
  const deletedPhaseIds = useEditPhase((state) => state.deletedPhaseIds);
  const setDeletedPhaseIds = useEditPhase((state) => state.setDeletedPhaseIds);
  const resetEditPhaseData = useEditPhase((state) => state.resetEditPhaseData);
  const updatePhaseData = useEditPhase((state) => state.updatePhaseData);
  const getListPhasesToBeRefetch = useEditPhase(
    (state) => state.getListPhasesToBeRefetch
  );

  const { showModal } = useModalObjectives();
  const { invalidateQueries } = useRefetchQuery();

  const [isPrimaryBtnLoading, setIsPrimaryBtnLoading] = useState(false);

  const listActivePhase = editedPhaseData?.filter(
    ({ isComplete }) => !isComplete
  );
  const completedPhase = editedPhaseData?.find(({ isComplete }) => isComplete);

  const reorder = (startIndex, endIndex) => {
    const result = cloneDeep(editedPhaseData);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const _onDragEnd = (result) => {
    if (result.destination) {
      const reorderedArray = reorder(
        result.source.index,
        result.destination.index
      );
      setEditedPhaseData(reorderedArray);
    }
  };

  const onClickDeletePhase = (id, isNew) => {
    let newPhases = cloneDeep(editedPhaseData);
    newPhases = newPhases.filter((phase) => phase.id != id);
    setEditedPhaseData(newPhases);
    !isNew && setDeletedPhaseIds(id);
  };

  const onChangePhaseName = (newPhaseName, index) => {
    let newPhases = cloneDeep(editedPhaseData);
    if (newPhaseName == '' && editedPhaseData[index].name == '') {
      newPhases.splice(index, 1);
      setEditedPhaseData(newPhases);
      return;
    }

    newPhases[index].name = newPhaseName || editedPhaseData[index].name;
    setEditedPhaseData(newPhases);
  };

  const onChangePhaseColor = (color, index) => {
    let newPhases = cloneDeep(editedPhaseData);
    newPhases[index].colorHex = color.colorHex;
    newPhases[index].bgColorHex = color.bgColorHex;
    setEditedPhaseData(newPhases);
  };

  const onClickAddPhase = () => {
    const dummyPhase = {
      id: uuidV4(),
      name: '',
      bgColorHex: 'F1F5F9',
      colorHex: '475569',
      type: 'active',
      isComplete: false,
      isNew: true
    };

    let newPhases = cloneDeep(editedPhaseData);
    newPhases.splice(newPhases.length - 1, 0, dummyPhase);
    setEditedPhaseData(newPhases);
  };

  // Need to refetch task if user edited phase color
  const refetchObj = () => {
    if (type == 'list') {
      let listPhasesToBeRefetch = getListPhasesToBeRefetch();

      listGroups?.section?.map((section) => {
        listPhasesToBeRefetch.map((phase) => {
          updateTasksTwoLevels(section?.name, phase?.name?.toLowerCase(), []);
          invalidateQueries([
            'objectives',
            'mytasks',
            phase?.name?.toLowerCase(),
            section?.name?.toLowerCase()
          ]);
        });
      });
    }
  };

  const onClickSave = async () => {
    // show confirmation update phase
    if (deletedPhaseIds?.length > 0) {
      showModal({
        modalType: 'confirmUpdatePhase',
        props: { projectId, type }
      });
      return;
    }

    // update phase data
    setIsPrimaryBtnLoading(true);
    const { data } = await updatePhaseData(projectId);
    if (data) {
      let tempListGroups = cloneDeep(listGroups);
      tempListGroups.phase = convertGroupNameToLowerCase(data);
      setListGroups(tempListGroups);
      refetchObj();
      resetEditPhaseData();
      closeModal();
      invalidateQueries(['group', 'phase', 'projectDetail'], {
        refetchActive: false
      });
    }
    setIsPrimaryBtnLoading(false);
  };

  const onClickCancel = () => {
    resetEditPhaseData();
    closeModal();
  };

  return (
    <Modal
      className="max-w-[400px]"
      title="Edit phases"
      eventOnClickClose={onClickCancel}
      withPrimaryBtn={{
        title: 'Save',
        onClick: () => onClickSave(),
        isLoading: isPrimaryBtnLoading,
        dataCy: 'save-button'
      }}
      withSecondaryBtn={{ title: 'Cancel', onClick: () => onClickCancel() }}
      maxHeight={640}
      contentClass="overflow-hidden hover:overflow-overlay hover:overflow-auto"
      useStopPropagationOnScroll={false}
      dataCyModal="edit-phase-modal"
    >
      <p className="typography-h300 text-n-600 mb-[8px]">
        {getObjectiveLocale('Phase For Active Task')}
      </p>
      <DragDropContext onDragEnd={_onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              id="edit-phase-dnd"
              style={{
                height: snapshot.isDraggingOver
                  ? document.getElementById('edit-phase-dnd')?.offsetHeight
                  : '100%',
                cursor: 'grab'
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {listActivePhase?.map((phase, index) => (
                <Draggable
                  key={phase.id}
                  draggableId={String(phase.id)}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <CardPhase
                      showIconDelete={listActivePhase?.length > 1}
                      phase={phase}
                      containerClass="mb-[8px]"
                      onClickDeletePhase={onClickDeletePhase}
                      autoFocus={phase.name == ''}
                      onChangePhaseName={onChangePhaseName}
                      onChangePhaseColor={onChangePhaseColor}
                      index={index}
                      provided={provided}
                      snapshot={snapshot}
                      isDraggable
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button.Tertiary customClass="mt-[8px]" onClick={onClickAddPhase}>
        <SVGIcon
          size="24"
          iconName="icon-add"
          fillColor="var(--base-600)"
          dataCy="add-phase-button"
        />
        {getObjectiveLocale('Add phase')}
      </Button.Tertiary>

      <p className="typography-h300 text-n-600 mb-[8px] mt-[24px]">
        {getObjectiveLocale('Phase for completed task')}
      </p>
      <CardPhase
        phase={completedPhase}
        isCompletedPhase
        index={editedPhaseData.length - 1}
        onChangePhaseName={onChangePhaseName}
        onChangePhaseColor={onChangePhaseColor}
      />
    </Modal>
  );
};

export default ModalEditPhase;

import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import kebabCase from 'lodash/kebabCase';

import { getTrackTemplateData } from 'client/TrackTemplateClient.js';
import { useUser } from 'context/UserContext';
import { useCreateCycle } from 'context/admin/CreateCycleContext';
import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils.js';

import Button from 'components/design-system/Button';
import Divider from 'components/design-system/Divider';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import Checkbox from 'src/components/shared/Checkbox';

import PhasePeerSelection from './PhasePeerSelection/PhasePeerSelection';
import ModalEditTrack from './phases/ModalEditTrack';
import ModalPreviewTemplate from './phases/ModalPreviewTemplate';

const PhaseAndListTrackTable = ({
  phase,
  defaultTrackTemplate,
  setShowModalDeactivate
}) => {
  const [enableTogglePhase, setEnableTogglePhase] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openModalEdit, setOpenModalEdit] = useState({ show: false });
  const [openModalAdd, setOpenModalAdd] = useState({ show: false });
  const [openModalView, setOpenModalView] = useState({ show: false });
  const [isSelectAllRow, setIsSelectAllRow] = useState(false);
  const [activePeerSelection, setActivePeerSelection] =
    useState('manager_selected');
  const { cycleData, togglePhase, changePhaseData, changeEvidenceConfig } =
    useCreateCycle();
  const [trackChecked, setTrackChecked] = useState(0);
  const [scoredTemplate, setScoredTemplate] = useState([]);
  const { config } = useUser();
  const showCheckboxEvidenceConfig =
    ['managerReview', 'indirectManagerReview'].includes(phase.id) &&
    enableTogglePhase;

  const [trackData, setTrackData] = useState([
    {
      name: `${getObjectiveLocale('Goal Final Scoring')}`,
      type: `goals_scoring`,
      config: {
        description: config?.descriptionOfTracks?.goalsScoring
      },
      selected: false
    },
    {
      name: `${getObjectiveLocale('Task Final Scoring')}`,
      type: `tasks_scoring`,
      config: {
        description: config?.descriptionOfTracks?.tasksScoring
      },
      selected: false
    },
    {
      name: `${getObjectiveLocale('Competency Final Scoring')}`,
      type: `competencies_scoring`,
      config: {
        description: config?.descriptionOfTracks?.competenciesScoring
      },
      selected: false
    },
    {
      name: `${getObjectiveLocale('Value Final Scoring')}`,
      type: `values_scoring`,
      config: {
        description: config?.descriptionOfTracks?.valuesScoring
      },
      selected: false
    },
    {
      name: `${getObjectiveLocale('Competency Recognition & Feedback')}`,
      type: `competencies_recognition_feedback`,
      config: {
        description: ''
      },
      selected: false
    },
    {
      name: `${getObjectiveLocale('Value Recognition & Feedback')}`,
      type: `values_recognition_feedback`,
      config: {
        description: ''
      },
      selected: false
    },
    {
      name: `General Questionnaire`,
      type: `general_questionnaire`,
      config: {
        description: ''
      },
      selected: false
    },
    {
      name: `Summary`,
      type: `summary`,
      config: {
        description: ''
      },
      selected: false
    },
    {
      name: `Reviewee Feedback`,
      type: `reviewee_feedback`,
      config: {
        description: ''
      },
      selected: false
    }
  ]);

  const [configPeerSelection, setConfigPeerSelection] = useState([
    {
      id: 1,
      mode: 'manager_selected',
      description: 'Manager will select the peers'
    },
    {
      id: 2,
      mode: 'self_nominated',
      description:
        'Reviewees will select the peers and get approval from their manager'
    }
  ]);

  const ref = useRef();
  useClickOutside(ref, () => {
    setOpenDropdown(null);
  });

  const handleSelectTrack = (checked, index) => {
    let track = trackData[index];
    const count_selected = trackData.filter((data) => data.selected).length;
    let newTrackData;

    if (checked) {
      track['selected'] = true;
      newTrackData = reorder(index, count_selected);

      if (count_selected + 1 == trackData.length) {
        setIsSelectAllRow(true);
      }
      setTrackChecked(count_selected + 1);
    } else {
      track['selected'] = false;
      newTrackData = reorder(index, trackData.length);

      if (trackData.filter((data) => data.selected).length == 0) {
        changePhaseData(phase.id, 'trackConfigs', []);
      }
      setTrackChecked(count_selected - 1);
    }

    setTimeout(() => {
      setTrackData(newTrackData);
    }, 500);
  };

  const openRowDialog = (name) => {
    if (isTrackSelected(name)) {
      setOpenDropdown(name);
    }
  };

  const reorder = (startIndex, endIndex) => {
    const result = [...trackData];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const _onDragEnd = (result) => {
    if (!result.destination || !trackData[result.destination.index].selected) {
      return;
    }

    const reorderedArray = reorder(
      result.source.index,
      result.destination.index
    );
    setTrackData(reorderedArray);
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    let style = { userSelect: 'none', ...draggableStyle };

    if (isDragging) {
      style.background = 'rgba(233, 199, 249, 0.16)';
      style.boxShadow = '0 6px 12px 0 rgba(50, 50, 50, 0.2)';
    }

    return style;
  };

  const isTrackSelected = (name) => {
    return (
      trackData.findIndex((track) => {
        return track.name == name && track.selected;
      }) > -1
    );
  };

  const getDefaultTemplate = (trackType) => {
    return defaultTrackTemplate.find((template) => {
      return template.trackType == trackType;
    });
  };

  const setupTrackData = (defaultTrackData) => {
    let newTrackData = [];
    let selectedDict = {};

    const trackConfigs = cycleData[phase.id]?.trackConfigs || [];
    trackConfigs.forEach((data) => {
      selectedDict[data.type] = true;
      newTrackData.push({
        ...data,
        template: data.template ? data.template : getDefaultTemplate(data.type),
        selected: true
      });
    });
    // append remaining track
    defaultTrackData.forEach((data) => {
      if (!selectedDict[data.type]) {
        newTrackData.push({
          ...data,
          template: getDefaultTemplate(data.type),
          selected: false
        });
      }
    });

    const isEveryTrackChecked = newTrackData.every((val) => val.selected);
    if (isEveryTrackChecked) {
      setIsSelectAllRow(true);
    }
    setTrackChecked(trackConfigs?.length || 0);
    setTrackData(newTrackData);
  };

  const handleSaveModalDeactive = () => {
    setEnableTogglePhase(false);
    setIsSelectAllRow(false);
    togglePhase(phase.id, null);
  };

  const handleActionAllRow = (isChecked) => {
    let newUnselectedTrack = [];
    trackData.map((track) => {
      track.selected = isChecked;
      newUnselectedTrack.push(track);
    });

    setTrackData(newUnselectedTrack);
    setTrackChecked(isChecked ? newUnselectedTrack.length : 0);
  };

  const handleSelectAllRow = (isChecked) => {
    setIsSelectAllRow(isChecked);
    handleActionAllRow(isChecked);

    if (!isChecked) {
      changePhaseData(phase.id, 'trackConfigs', []);
    }
  };

  const onChangeToggle = (isEnable) => {
    if (!isEnable) {
      setShowModalDeactivate({ onClick: handleSaveModalDeactive, show: true });
      return;
    }

    // default date -> null
    let body = {
      ...cycleData[phase.id],
      trackConfigs: [],
      startsAt: null,
      endsAt: null
    };

    if (phase.id == 'peerReview') {
      body = {
        ...body,
        mode: 'manager_selected',
        minimumPeerCount: 1,
        maximumPeerCount: 1,
        maximumPeerCountEnabled: false,
        maximumPeerSelectedEnabled: false,
        allowLockedByManager: false,
        maximumPeerSelected: 1
      };
      setActivePeerSelection('manager_selected');
    }

    togglePhase(phase.id, body);
    handleActionAllRow(false);
    setEnableTogglePhase(true);
  };

  const onChangePeerConfig = (mode) => {
    setActivePeerSelection(mode);
    togglePhase('peerReview', { ...cycleData['peerReview'], mode });
  };

  const onChangeEvidenceConfig = (phaseKey, value) => {
    changeEvidenceConfig(phaseKey, {
      ...cycleData[phaseKey],
      sidebarEvidenceEnabled: value
    });
  };

  useEffect(() => {
    if (cycleData?.[phase.id]?.trackConfigs?.length >= 0) {
      setEnableTogglePhase(true);
    }

    if (phase.id == 'peerReview' && cycleData?.['peerReview']) {
      setActivePeerSelection(cycleData?.['peerReview']?.mode);
    }

    const _getScoredTrackTemplate = async () => {
      const { data } = await getTrackTemplateData({
        trackType: 'review_aspects_scoring',
        state: 'active'
      });
      if (data) {
        setScoredTemplate(data);
      }
    };

    _getScoredTrackTemplate();
  }, []);

  useEffect(() => {
    let excludedTrack = [];
    if (phase.id != 'selfReview') excludedTrack.push('reviewee_feedback');

    let filteredData = trackData.filter(
      (value) => !excludedTrack.includes(value.type)
    );
    setupTrackData(filteredData);
  }, []);

  useEffect(() => {
    if (trackData.filter((data) => data.selected).length > 0) {
      const selectedTracks = trackData.filter((data) => data.selected);

      const uniqueTracks = [];
      const storedTrackKeys = {}; // to store track_type which should be unique.. except for general_questionnaire type
      selectedTracks.forEach((track) => {
        if (
          !storedTrackKeys[track.name] ||
          track.name == 'general_questionnaire'
        ) {
          uniqueTracks.push(track);
          storedTrackKeys[track.name] = true;
        }
      });
      changePhaseData(phase.id, 'trackConfigs', uniqueTracks);
    }
  }, [trackData]);

  return (
    <React.Fragment>
      <div className={`wrapper-phase-and-track bg-n-000`}>
        <div className="px-[16px] pt-[12px] flex justify-between mb-[12px] items-center">
          <div>
            <h5 className="typography-h500">{phase.title}</h5>
            <p className="text-n-800">{phase.description}</p>
          </div>
          <div className="flex items-center">
            {showCheckboxEvidenceConfig && (
              <>
                <Checkbox
                  id={`evidence-config-${phase.id}`}
                  checked={cycleData[phase.id]?.sidebarEvidenceEnabled}
                  onChange={() =>
                    onChangeEvidenceConfig(
                      phase.id,
                      !cycleData[phase.id]?.sidebarEvidenceEnabled
                    )
                  }
                  labelClass="cursor-pointer"
                >
                  <span className="typography-paragraph color-n-900 ml-[8px]">
                    Show sidebar evidence
                  </span>
                </Checkbox>
                <div className="h-[24px] mx-[24px]">
                  <Divider direction="vertical" base={false} />
                </div>
              </>
            )}
            <SmallToggleSwitchPurple
              idStr={`toggle-${kebabCase(phase.title)}`}
              isChecked={enableTogglePhase}
              onChange={() => onChangeToggle(!enableTogglePhase)}
              addClass="h-[13px]"
            />
          </div>
        </div>

        {enableTogglePhase && (
          <>
            <div className="px-[16px] py-[16px] flex justify-between items-end">
              <p className="text-n-800 typography-h400">
                {trackChecked} tracks are activated
              </p>
              {config?.scoredAttributesFeature &&
                scoredTemplate &&
                scoredTemplate.length != 0 &&
                trackData.filter(
                  (data) => data.type == 'review_aspects_scoring'
                ).length < scoredTemplate.length && (
                  <Button.Secondary
                    onClick={() =>
                      setOpenModalAdd({
                        index: trackData.length,
                        trackData,
                        track: {
                          type: 'review_aspects_scoring',
                          config: {
                            description:
                              config?.descriptionOfTracks?.reviewAspectsScoring
                          }
                        },
                        phase,
                        show: true
                      })
                    }
                    customClass="items-center"
                    datacy={`btn-${phase.id}-add-scored-attribute`}
                  >
                    <SVGIcon
                      size="24"
                      fillColor="var(--v-600)"
                      iconName="icon-add"
                      customClass="mr-[4px]"
                    />
                    Add Scored Attribute Track
                  </Button.Secondary>
                )}
            </div>
            <div className="table-list-track">
              <div className="flex items-center header-table">
                <div className="px-[16px] py-[6px] w-[8%] col-checkbox">
                  <Checkbox
                    value={phase.id}
                    id={phase.id}
                    dataCy="track-header-checkbox"
                    checked={isSelectAllRow}
                    onChange={() => handleSelectAllRow(!isSelectAllRow)}
                  />
                </div>
                <div className="px-[16px] w-[13%]">
                  <p className="typography-h300 text-n-800">
                    {getObjectiveLocale('ORDER')}
                  </p>
                </div>
                <div className="px-[16px] w-[39%]">
                  <p className="typography-h300 text-n-800">
                    {getObjectiveLocale('TRACK DETAIL')}
                  </p>
                </div>
                <div className="px-[16px] w-[39%]">
                  <p className="typography-h300 text-n-800">
                    {getObjectiveLocale('TEMPLATE')}
                  </p>
                </div>
                <div className="w-[6%]" />
              </div>

              <DragDropContext onDragEnd={_onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      className=".body-table-dnd"
                      id="table-dnd"
                      style={{
                        height: snapshot.isDraggingOver
                          ? document.getElementById('table-dnd')?.offsetHeight
                          : '100%'
                      }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {trackData.map((track, index) => (
                        <Draggable
                          isDragDisabled={!isTrackSelected(track.name)}
                          key={track.name}
                          draggableId={String(track.name)}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={`row-table ${
                                isTrackSelected(track.name)
                                  ? 'state-hover'
                                  : 'cursor-not-allowed'
                              }`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="px-[16px] py-[16px] w-[8%] col-checkbox">
                                <Checkbox
                                  value={track.name}
                                  id={`${phase.id}-${track.name}`}
                                  dataCy={`checkbox-${kebabCase(
                                    phase.id
                                  )}-${kebabCase(track.type)}`}
                                  checked={isTrackSelected(track.name)}
                                  onChange={(e) =>
                                    handleSelectTrack(e.target.checked, index)
                                  }
                                />
                              </div>
                              <div
                                className={`half-transition px-[16px] flex items-center w-[13%] ${
                                  !isTrackSelected(track.name)
                                    ? 'half-opacity'
                                    : ''
                                }`}
                              >
                                <SVGIcon
                                  iconName="icon-drag_indicator"
                                  width={10}
                                  height={16}
                                  customClass="mr-[24px]"
                                />
                                <p>{index + 1}</p>
                              </div>
                              <div
                                className={`half-transition px-[16px] w-[39%] ${
                                  !isTrackSelected(track.name)
                                    ? 'half-opacity'
                                    : ''
                                }`}
                              >
                                <p className="typography-h400 mb-[4px] truncate-text">
                                  {track.name}
                                </p>
                              </div>
                              <div
                                className={`half-transition px-[16px] w-[39%] ${
                                  !isTrackSelected(track.name)
                                    ? 'half-opacity'
                                    : ''
                                }`}
                              >
                                <p className="truncate-text">
                                  {track.template.name}
                                </p>
                              </div>
                              <div
                                className={`half-transition w-[56px] flex-center-center ${
                                  !isTrackSelected(track.name)
                                    ? 'half-opacity cursor-not-allowed'
                                    : ''
                                }`}
                              >
                                <i
                                  data-cy={`action-icon-${track.type}`}
                                  className={`material-icons ${
                                    !isTrackSelected(track.name)
                                      ? 'cursor-not-allowed'
                                      : ''
                                  }`}
                                  onClick={() => openRowDialog(track.name)}
                                >
                                  more_horiz
                                </i>
                                {openDropdown == track.name && (
                                  <div
                                    className="list-filter-component"
                                    ref={ref}
                                  >
                                    <div
                                      className="row-filter"
                                      data-cy={'edit-track'}
                                      onClick={() =>
                                        setOpenModalEdit({
                                          index,
                                          trackData,
                                          track,
                                          phase,
                                          show: true
                                        })
                                      }
                                    >
                                      {getObjectiveLocale('Edit Track')}
                                    </div>
                                    <div
                                      className="row-filter"
                                      data-cy="view-track"
                                      onClick={() =>
                                        setOpenModalView({
                                          track: track.template,
                                          show: true
                                        })
                                      }
                                    >
                                      {getObjectiveLocale('View Template')}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </>
        )}
        {openModalEdit.show && (
          <ModalEditTrack
            openModalEdit={openModalEdit}
            setOpenModalEdit={setOpenModalEdit}
            setOpenModalView={setOpenModalView}
            setTrackData={setTrackData}
            state="edit"
          />
        )}
        {openModalAdd.show && (
          <ModalEditTrack
            openModalEdit={openModalAdd}
            setOpenModalEdit={setOpenModalAdd}
            setOpenModalView={setOpenModalView}
            setTrackData={setTrackData}
            state="add"
            setTrackChecked={setTrackChecked}
          />
        )}
        {openModalView.show && (
          <ModalPreviewTemplate
            openModalView={openModalView}
            setOpenModalView={setOpenModalView}
          />
        )}
      </div>

      {enableTogglePhase && phase.id == 'peerReview' && (
        <PhasePeerSelection
          configPeerSelection={configPeerSelection}
          activePeerSelection={activePeerSelection}
          onChangePeerConfig={onChangePeerConfig}
          togglePhase={togglePhase}
          reviewEachOtherDefaultValue={
            cycleData?.['peerReview']?.reviewForEachOther
          }
          cycleData={cycleData['peerReview']}
        />
      )}
    </React.Fragment>
  );
};

export default PhaseAndListTrackTable;

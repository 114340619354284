import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';

import S3Client from 'client/S3Client';
import {
  getMissionsAdjustmentHistory,
  getUsersMissionAdjustmentHistory,
  postMissionAdjustmentHistory
} from 'client/adminClient.js';
import { useUser } from 'context/UserContext';
import { ParseFile } from 'utils/CsvHelper';
import { formatBytes, getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/design-system/Avatar';
import Button from 'components/design-system/Button';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import Modal from 'components/shared/modal/Modal';
import { getPerformancePreSignUrl } from 'src/client/UploadClient';

const ListPoints = ({ setWarning, setShowNotif, setNotifMessage }) => {
  const { config } = useUser();
  const [modalOpen, setModalOpen] = useState(false);
  const [missionHistory, setMissionHistory] = useState([]);
  const [listOfFiles, setListOfFiles] = useState(null);
  const [userMissionHistory, setUserMissionHistory] = useState([]);
  const [paginationList, setPagination] = useState({
    olderThan: null,
    newerThan: null
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [paginationKeyPage, setPaginationKeyPage] = useState('');
  const isReadOnly =
    config?.permissions?.cultureSettingsMissionsSee === 'read_only';

  const dateFormatter = (date) => {
    return dayjs(date).format('MMM D, YYYY, h:mm A');
  };

  const _getUrlMissionHistory = async (file) => {
    const filename = file.name;
    const extensions = filename.split('.').pop();

    const presignQuery = {
      public: false,
      directory: 'files',
      extension: extensions
    };

    const { data } = await getPerformancePreSignUrl(presignQuery);
    return data;
  };

  const validateDataMissionHistory = (file) => {
    let editedParsedData = cloneDeep(file);
    let warning = '';
    let result = [];

    editedParsedData.map((data) => {
      let keyEmail = Object.keys(data).find((k) => k.includes('email'));
      let keyPoint = Object.keys(data).find((k) => k.includes('point'));

      if (
        (data[keyEmail] == '' && !keyPoint) ||
        (data[keyEmail] == '' && data[keyPoint] == '')
      ) {
        return;
      } else if (data[keyEmail] == '') {
        warning = 'Upload failed, email cannot be empty';
      } else if (data[keyPoint] == '') {
        warning = 'Upload failed, Point cannot be empty';
      } else if (!keyEmail || !keyPoint) {
        warning = 'File must be contain Email and Points';
      } else if (data[keyEmail] != '' || data[keyPoint] != '') {
        result.push({
          email: data[keyEmail],
          point: parseInt(data[keyPoint])
        });
      }
    });

    if (result.length < 1) warning = 'File must be contain Email and Points';

    return { result, warning };
  };

  const handleUploadMissionHistory = async () => {
    let file = listOfFiles;
    let parsedFile = await ParseFile(file);
    let { result, warning } = validateDataMissionHistory(parsedFile);

    const uploadData = async () => {
      const url = await _getUrlMissionHistory(file);
      if (url) {
        let params = {
          fileUrl: url?.downloadUrl,
          data: result
        };
        await postMissionAdjustmentHistory(params);
        const { status } = await S3Client(url?.uploadUrl, file);

        if (status == 200) {
          let params = {
            limit: 1,
            olderThan: null
          };
          setModalOpen(!modalOpen);
          setListOfFiles(null);
          await getDataMissonHistory({ params, condition: 'upload' });
        }
      } else {
        setNotifMessage(`Upload failed, please contact your administrators`);
      }
    };

    const uploadFailed = () => {
      setModalOpen(!modalOpen);
      setListOfFiles(null);
      setShowNotif(true);
      setWarning(true);
      setNotifMessage(warning);
      setTimeout(() => {
        setShowNotif(false);
      }, 5000);
    };

    if (warning == '') {
      await uploadData();
    } else {
      uploadFailed();
    }
  };

  const uploadMessage = (error) => {
    setShowNotif(true);

    if (!error) {
      setWarning(false);
      setNotifMessage('file upload success');
    } else {
      setWarning(true);
      setNotifMessage(`File upload rejected, ${error}`);
    }

    setTimeout(() => {
      setShowNotif(false);
    }, 5000);
  };

  const getDataMissonHistory = async ({ params, condition }) => {
    const { data, pagination } = await getMissionsAdjustmentHistory(params);

    if (data && pagination) {
      const dataSuccess = data.filter((e) => e.status == 'success');
      const dataUploadStatus = data[0]?.status;

      const uploaderId = dataSuccess
        .map((e, idx) => {
          if (idx == 0) return e.uploaderId;
          else if (e.uploaderId == dataSuccess[idx - 1].uploaderId) return;
        })
        .filter((res) => res);

      const usersData = Promise.all(
        uploaderId.map(async (id) => {
          const { data } = await getUsersMissionAdjustmentHistory(id);
          return data;
        })
      ).then((res) => res);

      const getData = async () => {
        setMissionHistory(dataSuccess);
        setUserMissionHistory(await usersData);
      };

      const loadMore = async () => {
        setMissionHistory([...missionHistory, ...dataSuccess]);
        setUserMissionHistory([...userMissionHistory, await usersData]);
      };

      if (condition == 'upload') {
        if (dataUploadStatus == 'failed') {
          uploadMessage(data[0].error);
        } else {
          await getData();
          uploadMessage(data[0].error);
        }
      } else if (condition == 'loadMore') {
        await loadMore();
      } else {
        await getData();
      }

      if (pagination)
        setPagination({
          olderThan: pagination.next.olderThan,
          newerThan: pagination.prev.newerThan
        });

      if (
        pagination?.next?.olderThan == null ||
        pagination?.next?.olderThan == 1
      ) {
        setHasMore(false);
      } else if (pagination?.next?.olderThan !== 1) {
        let dataLength =
          condition == 'loadMore'
            ? missionHistory?.length
            : dataSuccess?.length;
        if (dataLength < 10) {
          setLoadMore(true);
        }
      }
    }
  };

  const loadMoreData = async () => {
    let params = {
      limit: 10 - missionHistory.length,
      [paginationKeyPage]: paginationList[paginationKeyPage]
    };
    await getDataMissonHistory({ params, condition: 'loadMore' });
  };

  // PAGINATION
  const changePage = async (type) => {
    let paginationKey;

    if (type == 'next') {
      paginationKey = 'olderThan';
      setCurrentPage(currentPage + 1);
    } else {
      paginationKey = 'newerThan';
      setCurrentPage(currentPage - 1);
      setHasMore(true);
    }

    let params = {
      limit: 10,
      [paginationKey]: paginationList[paginationKey]
    };

    setPaginationKeyPage(paginationKey);
    await getDataMissonHistory({ params, condition: 'changePage' });
  };

  useEffect(() => {
    getDataMissonHistory({ params: { limit: 10 } });
  }, []);

  useEffect(() => {
    if (loadMore) {
      if (missionHistory.length < 10) {
        loadMoreData();
      }
      setLoadMore(false);
    }
  }, [loadMore]);

  return (
    <>
      <div className="w-[70%]">
        <div className="points border-n400 py-[24px] rounded-[4px]">
          <div className="flex justify-between mb-[16px] px-[24px]">
            <div>
              <p className="typography-h500">{getObjectiveLocale('Points')}</p>
              <p className="typography-paragraph text-n-800">
                {getObjectiveLocale(
                  'Adjust points for employees by uploading CSV.'
                )}
              </p>
            </div>
            <div className="inline-flex items-center">
              {!isReadOnly && (
                <Button
                  onClick={() => setModalOpen(!modalOpen)}
                  customClass="mr-[8px]"
                >
                  <SVGIcon
                    iconName="icon-file_upload"
                    size="24"
                    fillColor="var(--n-000)"
                    customClass="mr-[4px]"
                  />
                  {getObjectiveLocale('Upload File')}
                </Button>
              )}
              <TooltipContainer text="Download template" show>
                <a
                  href="/template/points_adjustment_template.csv"
                  download="points_adjustment_template.csv"
                >
                  <Button variant="secondary" customClass="w-[32px]">
                    <SVGIcon
                      iconName="icon-file_download"
                      size="24"
                      fillColor="var(--n-600)"
                    />
                  </Button>
                </a>
              </TooltipContainer>
            </div>
          </div>
          <div className="border-n-400 border-0 border-t border-solid px-[24px] py-[16px]">
            <p className="typography-h300 text-n-800">
              {getObjectiveLocale('history log')}
            </p>
            {missionHistory?.map((history) => {
              const user = userMissionHistory?.find(
                (user) => user.id == history.uploaderId
              );
              return (
                <div>
                  <div className="inline-flex items-center pt-[24px]">
                    <Avatar
                      size="32"
                      customClass="mr-[16px]"
                      src={user?.profilePicture}
                    />
                    <p className="flex flex-row typography-h400-longform">
                      {user?.name}&nbsp;
                      <p className="typography-paragraph text-n-900">
                        {getObjectiveLocale('uploaded file')}
                      </p>
                    </p>
                  </div>
                  <div className="flex justify-between items-center ml-[60px] my-[8px] h-[56px] bg-n-200 rounded-[4px]">
                    <div className="flex flex-row items-center">
                      <SVGIcon
                        iconName="icon-attachment"
                        size="24"
                        fillColor="var(--n-600)"
                        customClass="mx-[18px]"
                      />
                      <div>
                        <p className="typography-paragraph text-n-900">
                          {history?.filename}
                        </p>
                        <p className="typography-h100 text-n-800">
                          {Number(history?.size / 1000).toFixed(2)} kB
                        </p>
                      </div>
                    </div>
                    <a href={history?.fileUrl} download={history?.filename}>
                      <SVGIcon
                        iconName="icon-file_download"
                        size="24"
                        fillColor="var(--n-600)"
                        customClass="mx-[18px]"
                      />
                    </a>
                  </div>
                  <p className="typography-h100 ml-[60px]">
                    {dateFormatter(history?.createdAt)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="float-right py-[16px]">
          <PaginationModule
            pageNumber={currentPage}
            handlePrev={() => changePage('prev')}
            handleNext={() => changePage('next')}
            hasMore={hasMore}
          />
        </div>
      </div>

      {modalOpen && (
        <Modal
          title={getObjectiveLocale('Upload File')}
          eventOnClickClose={() => {
            setListOfFiles(null), setModalOpen(!modalOpen);
          }}
          className="max-w-[400px]"
          withPrimaryBtn={{
            title: getObjectiveLocale('Upload'),
            dataCy: 'btn-upload-points',
            onClick: () => {
              handleUploadMissionHistory();
            },
            disabled: !listOfFiles
          }}
          withSecondaryBtn={{
            title: getObjectiveLocale('Cancel'),
            dataCy: 'btn-cancel-points',
            onClick: () => {
              setListOfFiles(null), setModalOpen(!modalOpen);
            }
          }}
          withFileBtn={{
            title: getObjectiveLocale('Attach File'),
            dataCy: 'btn-upload-points',
            onClick: (e) => setListOfFiles(e.target.files[0]),
            accept: '.csv'
          }}
        >
          {listOfFiles ? (
            <div className="flex flex-row items-center justify-between h-[80px] bg-n-200 rounded-[4px]">
              <div className="flex flex-row items-center">
                <SVGIcon
                  iconName="icon-attachment"
                  size="24"
                  fillColor="var(--n-600)"
                  customClass="mx-[18px]"
                />
                <div className="flex flex-col">
                  <span className="typography-paragraph text-n-900 multiline-text-2">
                    <p className="w-[230px]">{listOfFiles?.name}</p>
                  </span>
                  <p className="typography-h100 text-n-800">
                    {formatBytes(listOfFiles?.size / 1000)}
                  </p>
                </div>
              </div>
              <SVGIcon
                iconName="icon-cancel"
                size="24"
                fillColor="var(--n-600)"
                customClass="mx-[18px]"
                onClick={() => setListOfFiles(null)}
              />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-[80px] bg-n-200 rounded-[4px]">
              <p className="typography-h400 text-n-600 pb-[8px]">
                {getObjectiveLocale('No attachment file')}
              </p>
              <p className="typography-h100 text-n-600">
                {getObjectiveLocale(
                  'Attach CSV file to adjust points of employees'
                )}
              </p>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default ListPoints;

import React from 'react';

import startCase from 'lodash/startCase';

import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getDateLocale } from 'utils/HelperUtils';

function SelectedFilter({
  title,
  selectedFilter,
  handleChange = () => {},
  onClick,
  datacy,
  immutableFilter,
  generateCustomTitle
}) {
  const getValue = () => {
    let result = [];
    let isArrayFilter = Array.isArray(selectedFilter);

    if (isArrayFilter) {
      selectedFilter.map((filter, index) => {
        if (
          typeof filter === 'object' &&
          typeof Object.values(filter)[0] !== 'undefined'
        ) {
          let key = Object.keys(filter)[0];
          filter[key].length > 0 &&
            result.push(
              `${index == 0 ? '' : ' '}${getObjectiveLocale(
                key
              ).italics()} (${filter[key].join(', ')})`
            );
        } else if (typeof filter === 'string' && title !== 'lastUpdated') {
          result.push(
            `${index == 0 ? '' : ' '}${getObjectiveLocale(
              generateCustomTitle
                ? generateCustomTitle(filter)
                : filter.split('_').join(' ')
            )}`
          );
        } else if (title === 'lastUpdated') {
          let wordSplit = filter.split('.');
          if (wordSplit.length > 2) {
            result.push(
              getObjectiveLocale(wordSplit[1].split('_').join(' ')).italics(),
              ` (${getDateLocale(new Date(wordSplit[2]), {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
              })})`
            );
          } else {
            result.push(getObjectiveLocale(wordSplit[1].split('_').join(' ')));
          }
        }
      });
    } else {
      result.push(`${getObjectiveLocale(startCase(selectedFilter))}`);
    }

    return result;
  };

  const renderFilter = (title) => {
    if (title === 'owner') {
      let checkArrayNotEmpty = (selectedFilter, index) => {
        return Object.values(selectedFilter[index])[0]?.length > 0;
      };
      if (
        checkArrayNotEmpty(selectedFilter, 0) ||
        checkArrayNotEmpty(selectedFilter, 1) ||
        checkArrayNotEmpty(selectedFilter, 2)
      ) {
        return true;
      } else if (typeof selectedFilter[3] == 'string') {
        return true;
      }
    } else if (title === 'lastUpdated') {
      if (selectedFilter[0]?.length > 0) return true;
    } else if (title === 'group') {
      return false;
    } else {
      if (selectedFilter?.length > 0) return true;
    }
    return false;
  };

  return (
    <>
      {renderFilter(title) && (
        <ul
          className="list-selected-label selected-labels mb-[0px] h-[32px] flex items-center"
          data-cy="selected-filter"
          id={'selected-filter'}
          onClick={onClick}
        >
          <li role="presentation" className="label-card selected">
            <span className="typography-h200 capitalize">
              {getObjectiveLocale(
                title?.includes('/') ? title : startCase(title)
              )}
              :
            </span>
            <span
              data-cy={datacy || title}
              className="ml-[4px] typography-h100 fw-normal capitalize"
              dangerouslySetInnerHTML={{ __html: getValue() }}
            />
          </li>
          {!immutableFilter?.includes(title) && (
            <img
              src={Config.asset.formalReview.icCloseGrey}
              className="close-label cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(), handleChange(title);
              }}
            />
          )}
        </ul>
      )}
    </>
  );
}

export default SelectedFilter;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

const AttachmentSection = ({ modalType, listOfFiles, deleteFile }) => {
  return (
    <>
      {modalType !== 'comment' && (
        <div className="display-flex mt-[0px]">
          <div className="attachment-container no-selected">
            <div className="row update-current-value mt-[24px] mb-[8px]">
              <div className="col-md-3 w-full">
                {getObjectiveLocale('Attachment')}
              </div>
            </div>
            {listOfFiles.length === 0 && (
              <div className="bg-n-200 w-full py-[10px] flex-center-center flex-col rounded-[4px] h-[56px]">
                <p className="mb-[4px] typography-h400 fw-normal text-n-600">
                  No attachment file
                </p>
                <span className="typography-h100 fw-normal text-n-600">
                  Attach photos or other documents to corroborate your progress
                  evidence
                </span>
              </div>
            )}
            <div className="attachment-section">
              {listOfFiles &&
                listOfFiles.map((file, index) => (
                  <div
                    className="file-container-display cursor-pointer py-[16px] px-[16px] mr-[8px]"
                    key={index}
                  >
                    <div className="file-container-section mr-[16px]">
                      <span className="wrap-ellipsis fs-14 text-n-900">
                        {file.name.length > 16
                          ? `${file.name.slice(0, 16)}... .${
                              file.name.split('.')[1]
                            }`
                          : file.name}
                      </span>
                      <span className="wrap-ellipsis fs-12 text-n-800">
                        {parseFloat(file.size / 1000000).toFixed(2)} MB
                      </span>
                    </div>
                    <SVGIcon
                      size="24"
                      customClass="close-icon"
                      iconName="icon-cancel"
                      fillColor="var(--n-600)"
                      onClick={() => deleteFile(index)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AttachmentSection;

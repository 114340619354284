import React from 'react';

import Switcher from 'src/components/design-system/Switcher';

function Show({ lists, showTask, onChange }) {
  return (
    <Switcher
      options={lists}
      selectedOption={showTask}
      onChange={onChange}
      customClass="mb-[16px]"
    />
  );
}

export default Show;

const env = {
  PERFORMANCE_API_HOST                     : process.env.PERFORMANCE_API_HOST || ENVConfig.PERFORMANCE_API_HOST,
  LOCALE_HOST                              : process.env.LOCALE_HOST || ENVConfig.LOCALE_HOST,
  AMPLITUDE_KEY                            : process.env.AMPLITUDE_KEY || ENVConfig.AMPLITUDE_KEY,
  AMPLITUDE_KEY_CULTURE                    : process.env.AMPLITUDE_KEY_CULTURE || ENVConfig.AMPLITUDE_KEY_CULTURE,
  SENTRY_DSN                               : process.env.SENTRY_DSN || ENVConfig.SENTRY_DSN,
  SENTRY_AUTH_TOKEN                        : process.env.SENTRY_AUTH_TOKEN || ENVConfig.SENTRY_AUTH_TOKEN,
  POC_ORGANIZATION                         : process.env.POC_ORGANIZATION || ENVConfig.POC_ORGANIZATION,
  FIREBASE_API_KEY_PERFORMANCE             : process.env.FIREBASE_API_KEY_PERFORMANCE || ENVConfig.FIREBASE_API_KEY_PERFORMANCE,
  FIREBASE_AUTH_DOMAIN_PERFORMANCE         : process.env.FIREBASE_AUTH_DOMAIN_PERFORMANCE || ENVConfig.FIREBASE_AUTH_DOMAIN_PERFORMANCE,
  FIREBASE_PROJECT_ID_PERFORMANCE          : process.env.FIREBASE_PROJECT_ID_PERFORMANCE || ENVConfig.FIREBASE_PROJECT_ID_PERFORMANCE,
  FIREBASE_MESSAGING_SENDER_ID_PERFORMANCE : process.env.FIREBASE_MESSAGING_SENDER_ID_PERFORMANCE || ENVConfig.FIREBASE_MESSAGING_SENDER_ID_PERFORMANCE,
  FIREBASE_APP_ID_PERFORMANCE              : process.env.FIREBASE_APP_ID_PERFORMANCE || ENVConfig.FIREBASE_APP_ID_PERFORMANCE,
  FIREBASE_API_KEY_CULTURE                 : process.env.FIREBASE_API_KEY_CULTURE || ENVConfig.FIREBASE_API_KEY_CULTURE,
  FIREBASE_AUTH_DOMAIN_CULTURE             : process.env.FIREBASE_AUTH_DOMAIN_CULTURE || ENVConfig.FIREBASE_AUTH_DOMAIN_CULTURE,
  FIREBASE_PROJECT_ID_CULTURE              : process.env.FIREBASE_PROJECT_ID_CULTURE || ENVConfig.FIREBASE_PROJECT_ID_CULTURE,
  FIREBASE_MESSAGING_SENDER_ID_CULTURE     : process.env.FIREBASE_MESSAGING_SENDER_ID_CULTURE || ENVConfig.FIREBASE_MESSAGING_SENDER_ID_CULTURE,
  FIREBASE_APP_ID_CULTURE                  : process.env.FIREBASE_APP_ID_CULTURE || ENVConfig.FIREBASE_APP_ID_CULTURE,
  SENTRY_HOST                              : process.env.SENTRY_HOST || ENVConfig.SENTRY_HOST,
  SENTRY_RELEASE                           : process.env.SENTRY_RELEASE,
  LOCALE_TOKEN                             : process.env.LOCALE_TOKEN || ENVConfig.LOCALE_TOKEN,
  AUTHENTICATION_METHOD                    : process.env.AUTHENTICATION_METHOD || ENVConfig.AUTHENTICATION_METHOD,
  ORGANIZATION_NAME                        : process.env.ORGANIZATION_NAME || ENVConfig.ORGANIZATION_NAME,
  CULTURE_URL                              : process.env.CULTURE_URL || ENVConfig.CULTURE_URL,
  PERFORMANCE_URL                          : process.env.PERFORMANCE_URL || ENVConfig.PERFORMANCE_URL,
  PRERENDER_TOKEN                          : process.env.PRERENDER_TOKEN || ENVConfig.PRERENDER_TOKEN,
  AUTHORIZATION_METHOD                     : process.env.AUTHORIZATION_METHOD || ENVConfig.AUTHORIZATION_METHOD || "cookie",
  SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED : process.env.SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED || ENVConfig.SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
  OTP_PASS_TOKEN                           : process.env.OTP_PASS_TOKEN || ENVConfig.OTP_PASS_TOKEN,
  RECAPTCHA_SITE_KEY                       : process.env.RECAPTCHA_SITE_KEY || ENVConfig.RECAPTCHA_SITE_KEY,
  WEB_SOCKET_URL                           : process.env.WEB_SOCKET_URL || ENVConfig.WEB_SOCKET_URL
}

export default env;
import React from 'react';

import PropTypes from 'prop-types';

import Config from 'utils/Config';

import AWSImage from 'components/design-system/AWSImage';

const SuggestionList = ({ mention, theme, ...parentProps }) => {
  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div
          data-role-id={`${mention.id}`}
          className={`flex items-center ${theme.mentionSuggestionsEntryContainerRight}`}
        >
          <AWSImage
            src={mention?.avatar || Config.asset.general.defaultPicture}
            className="h-[24px] w-[24px] rounded-[50%]"
            alt="avatar image"
          />
          <div className={theme.mentionSuggestionsEntryText}>
            {mention.name}
          </div>
        </div>
      </div>
    </div>
  );
};

SuggestionList.propTypes = {
  mention: PropTypes.shape({
    name: PropTypes.string,
    mentionId: PropTypes.string
  }).isRequired,
  theme: PropTypes.shape({
    mentionSuggestionsEntryContainer: PropTypes.string,
    mentionSuggestionsEntryContainerRight: PropTypes.string,
    mentionSuggestionsEntryText: PropTypes.string
  }).isRequired
};

export default SuggestionList;

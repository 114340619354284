import React, { useEffect, useState } from 'react';

import { getObjectiveCategory } from 'client/ObjectivesClient';
import { getObjectiveLocale } from 'utils/HelperUtils';

import DropdownType from 'components/dropdown/DropdownType';
import SVGIcon from 'components/shared/SVGIcon';

const ObjectiveCategory = ({ objectiveDetail, updateObjective }) => {
  const [objectiveCategories, setObjectiveCategories] = useState([]);

  const { objectiveCategory } = objectiveDetail || {};

  const getTypes = async () => {
    const { data } = await getObjectiveCategory();
    if (data) {
      setObjectiveCategories(data);
    }
  };

  const handleChangeType = (el) => {
    updateObjective({ objectiveCategoryId: el.id });
  };

  useEffect(() => {
    getTypes();
  }, []);

  const TextTrigger = ({}) => {
    return objectiveCategory ? (
      <p style={{ color: `#${objectiveCategory?.colorHex}` }}>
        {objectiveCategory?.name}
      </p>
    ) : (
      <p className="typography-paragraph text-n-600 cursor-pointer">
        {getObjectiveLocale('Goal Type')}
      </p>
    );
  };

  return (
    <div className="px-[24px] hover:bg-base-3008">
      <div className="h-[48px] flex items-center" data-cy="sidebar-detail-type">
        <SVGIcon
          iconName="icon-category_outlined"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <div className="flex">
          {objectiveCategory && (
            <p className="mr-[4px]">
              {getObjectiveLocale(
                `${objectiveDetail.isProject ? 'Project' : 'Goal'} type is`
              )}
            </p>
          )}
          <DropdownType
            defaultValue={objectiveCategory?.id}
            handleChange={handleChangeType}
            objectiveCategories={objectiveCategories}
            customTrigger={<TextTrigger />}
            dropdownPosition="left"
          />
        </div>
      </div>
    </div>
  );
};

export default ObjectiveCategory;

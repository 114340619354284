import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import useClickOutside from 'hooks/useClickOutside';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getNested } from 'utils/HelperUtils';
import { loadMoreValidator } from 'utils/HelperUtils';
import { useDeepEffect } from 'utils/useDeepEffect';

import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';

import Checkbox from '../Checkbox';
import RadioButton from '../RadioButton';
import SearchBar from '../SearchBar';
import './Dialog.scss';
import HeaderItemTemplates from './HeaderItemTemplates';
import ListItemTemplates from './ListItemTemplates';

const placements = {
  top: {
    margin: '0 0 0.8rem 0',
    top: 'unset',
    right: '50%',
    bottom: '5.2rem',
    left: 'unset',
    transform: 'translateX(50%)'
  },
  'top-start': {
    margin: '0 0 0.8rem 0',
    top: 'unset',
    right: 'unset',
    bottom: '5.2rem',
    left: 'unset'
  },
  'top-end': {
    margin: '0 0 0.8rem 0',
    top: 'unset',
    right: '0rem',
    bottom: '5.2rem',
    left: 'unset'
  },

  right: {
    margin: '0 0 0 0.8rem',
    top: '50%',
    right: 'unset',
    bottom: 'unset',
    left: '100%',
    transform: 'translateY(-50%)'
  },
  'right-start': {
    margin: '0 0 0 0.8rem',
    top: '0rem',
    right: 'unset',
    bottom: 'unset',
    left: '100%'
  },
  'right-end': {
    margin: '0 0 0 0.8rem',
    top: 'unset',
    right: 'unset',
    bottom: '0rem',
    left: '100%'
  },

  bottom: {
    margin: '0.8rem 0 0 0',
    top: 'auto',
    right: '50%',
    bottom: 'unset',
    left: 'unset',
    transform: 'translateX(50%)'
  },
  'bottom-start': {
    margin: '0.8rem 0 0 0',
    top: 'auto',
    right: 'unset',
    bottom: 'unset',
    left: 'unset'
  },
  'bottom-end': {
    margin: '0.8rem 0 0 0',
    top: 'auto',
    right: '0rem',
    bottom: 'unset',
    left: 'unset'
  },

  left: {
    margin: '0 0.8rem 0 0',
    top: '50%',
    right: '100%',
    bottom: 'unset',
    left: 'unset',
    transform: 'translateY(-50%)'
  },
  'left-start': {
    margin: '0 0.8rem 0 0',
    top: '0rem',
    right: '100%',
    bottom: 'unset',
    left: 'unset'
  },
  'left-end': {
    margin: '0 0.8rem 0 0',
    top: 'unset',
    right: '100%',
    bottom: '0rem',
    left: 'unset'
  },

  'fixed-right': {
    margin: '0 0.8rem 0 0',
    top: 'unset',
    right: '4rem',
    bottom: 'unset',
    left: 'unset'
  },
  'fixed-bottom': {
    margin: '0 0.8rem 0 0',
    top: '6rem',
    right: '0rem',
    bottom: 'unset',
    left: 'unset'
  }
};

// list of dialog that didn't need to hit endpoint (queryFn),
// and only use static option from UseFilterDialog
const whitelistedDialog = ['show', 'survey-status', 'user-state'];

function Dialog({
  title,
  searchPlaceholder,
  noSearch,
  setShowDialog,
  selectedValue,
  handleChange,
  selectMultiple,
  listItem,
  headerItem,
  emptyState,
  addItemSuggestion,
  staticOptions,
  placement,
  queryFn,
  compareKey,
  searchKey,
  payloadFormat,
  returnSingle,
  containerClassName,
  singleChoice,
  dataCy,
  radioButton,
  hasInput,
  inputOptions,
  styleContainer,
  onHover = () => {},
  id = '',
  showSelected = true
}) {
  const ref = createRef();
  const intersectTarget = useRef();
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);
  let { data, status, isFetchingMore, fetchMore, hasMore } =
    queryFn?.({ q: debouncedSearchTerm, key: id }) || {};
  const [arr, setArr] = useState([staticOptions]);
  const [options, setOptions] = useState([]);
  const searchArray = searchKey
    ? staticOptions.map(({ [searchKey]: val }) => val)
    : staticOptions;
  let selectedIds =
    selectedValue?.length > 0
      ? selectedValue.map((sel) => sel?.[compareKey] || sel)
      : [];
  const debounceFn = useCallback(debounce(changeScore, 1000), [selectedValue]);
  const [isLoading, setIsLoading] = useState(false);

  const getOptions = () => {
    if (id == 'label') {
      // since label endpoint response is different from the other (without pages)
      // set it as options manually without looping through pages
      const options = data;
      setOptions(options);
    } else if (queryFn && !whitelistedDialog.includes(id)) {
      // if has queryFn and not whitelisted then we get options from query / endpoint result
      // id = 'show', 'directorate', etc
      const queryResult = data?.pages?.map((page) => page?.data || page) || [];
      setOptions(queryResult);
    } else {
      // use static options
      setOptions(arr);
    }

    setIsLoading(false);
  };

  const onChange = (sel, formatted = false) => {
    let payload = {};
    if (payloadFormat && !formatted) {
      let entries = Object.entries(payloadFormat);

      if (payloadFormat?.valueOnly) {
        payload = getNested(sel, compareKey);
      } else if (entries?.length > 0) {
        entries.map(([key, value]) => {
          payload[key] = getNested(sel, value);
        });
      }
    } else {
      payload = sel;
    }

    if (returnSingle) {
      handleChange(payload);
      if (singleChoice) {
        setShowDialog(false);
      }
      return;
    }

    const compareCallback = (value, equal) => {
      const id = formatted ? sel?.[compareKey] : sel?.id;
      if (payloadFormat?.valueOnly) {
        return equal ? value === id : value !== id;
      } else if (payloadFormat) {
        return equal ? value?.[compareKey] === id : value?.[compareKey] !== id;
      } else {
        return equal ? isEqual(value, sel) : !isEqual(value, sel);
      }
    };

    let newSelectedValue = [...selectedValue];
    if (!newSelectedValue.some((val) => compareCallback(val, true))) {
      if (selectMultiple) {
        newSelectedValue.push(payload);
      } else {
        newSelectedValue = [payload];
      }
    } else {
      newSelectedValue = newSelectedValue.filter((val) =>
        compareCallback(val, false)
      );
    }

    handleChange(newSelectedValue);
  };

  function changeScore(value, index) {
    // index 0 => min score
    // index 1 => max score
    let newScore =
      selectedValue && selectedValue.length > 0 ? [...selectedValue] : ['', ''];
    newScore[index] = value;

    handleChange(newScore);
  }

  useClickOutside(ref, () => {
    setShowDialog(false);
  });

  const _onScroll = (e) => {
    const target = e.target;

    fetchMore &&
      !isFetchingMore &&
      hasMore &&
      loadMoreValidator(target, 200, () => {
        fetchMore();
      });
  };

  useEffect(() => {
    !queryFn &&
      !noSearch &&
      setArr([
        searchArray.filter((option) => {
          return option
            ?.toLowerCase()
            ?.includes(debouncedSearchTerm.toLowerCase());
        })
      ]);
  }, [debouncedSearchTerm]);

  useDeepEffect(() => {
    getOptions();
  }, [data]);

  useEffect(() => {
    if (staticOptions) {
      setArr([staticOptions]);
    }
  }, [staticOptions]);

  useEffect(() => {
    if (status === 'loading') {
      setIsLoading(true);
    } else if (status === 'success' || staticOptions.length > 0) {
      getOptions();
    }
  }, [status, staticOptions]);

  return (
    <div
      ref={ref}
      className={`dlg-container bg-n-000 rounded-[4px] py-[8px] animation-fadeInDownSlow ${containerClassName}`}
      data-cy="dialog-container"
      style={styleContainer || placements[placement]}
    >
      {title && (
        <p className="typography-h100 mt-[0px] capitalize px-[16px] text-n-600 h-[32px] flex items-center">
          {getObjectiveLocale(title?.replace(/-/g, ' ') || title)}
        </p>
      )}
      {headerItem &&
        HeaderItemTemplates[headerItem.template](selectedValue, {
          ...headerItem.config,
          onChange
        })}
      {!noSearch && (
        <div className="px-[16px]">
          <SearchBar
            customClass="z-index-10"
            fullWidth
            placeholder={searchPlaceholder}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      )}
      {isLoading && <LoadingComponent />}
      {(!isLoading || !queryFn) && (
        <div className="options w-full" id="option-dialog" onScroll={_onScroll}>
          {!headerItem &&
            !selectMultiple &&
            selectedValue.length > 0 &&
            !radioButton &&
            showSelected && (
              <div
                id={'selected'}
                value={'selected'}
                onClick={() => onChange(selectedValue[0])}
                className="w-full px-[16px]"
              >
                <div className="flex items-center">
                  {ListItemTemplates[listItem.template](
                    -1,
                    selectedValue[0],
                    selectMultiple,
                    { ...listItem.config, checkMark: true },
                    dataCy
                  )}
                </div>
              </div>
            )}
          {options?.map((page, pageIndex) => {
            let pageData = page?.data || page;
            return (
              pageData?.length > 0 &&
              pageData?.map((value, valueIndex) => {
                let uniq = `page-${pageIndex}-val-${valueIndex}`;
                let isLast =
                  pageIndex === options?.length - 1 &&
                  valueIndex === pageData?.length - 1;
                let singleSelected =
                  selectedValue?.[0]?.[compareKey] || selectedValue?.[0];
                let comparedValue = value?.[compareKey] || value?.id || value;
                return (
                  <div
                    onMouseEnter={(e) => {
                      onHover(e.target.offsetTop, value);
                    }}
                    key={valueIndex}
                  >
                    {(selectMultiple ||
                      !isEqual(comparedValue, singleSelected)) &&
                    !radioButton ? (
                      selectMultiple ? (
                        <Checkbox
                          key={valueIndex}
                          id={uniq}
                          value={uniq}
                          checked={selectedIds.includes(
                            value?.[compareKey] || comparedValue
                          )}
                          customContainerClass={`w-full list-dialog px-[16px] py-[8px] flex ${
                            selectedIds.includes(
                              value?.[compareKey] || comparedValue
                            ) && 'bg-base-30024'
                          }`}
                          boxSize={2}
                          dataCy={dataCy?.checkbox || `checkbox-${valueIndex}`}
                          onClick={() => onChange(value)}
                          isCenter={false}
                        >
                          <div
                            ref={isLast ? intersectTarget : null}
                            className="flex items-center"
                          >
                            {ListItemTemplates[listItem.template](
                              valueIndex,
                              value,
                              selectMultiple,
                              listItem.config,
                              dataCy
                            )}
                          </div>
                        </Checkbox>
                      ) : (
                        <div
                          key={valueIndex}
                          id={uniq}
                          value={uniq}
                          className="w-full list-dialog px-[16px] min-h-[32px] flex"
                          data-cy={`list-dialog-${valueIndex}`}
                          onClick={() => onChange(value)}
                        >
                          <div
                            ref={isLast ? intersectTarget : null}
                            className="flex items-center"
                          >
                            {ListItemTemplates[listItem.template](
                              valueIndex,
                              value,
                              selectMultiple,
                              listItem.config,
                              dataCy
                            )}
                          </div>
                        </div>
                      )
                    ) : radioButton ? (
                      <RadioButton
                        onClick={() => onChange(value)}
                        checked={selectedIds.includes(
                          value?.[compareKey] || comparedValue
                        )}
                        id={uniq}
                        value={uniq}
                        addClass="px-[16px]"
                      >
                        <div
                          key={valueIndex}
                          id={uniq}
                          value={uniq}
                          className={`w-full list-dialog px-[16px] max-h-[32px] flex ${
                            selectedIds.includes(
                              value?.[compareKey] || comparedValue
                            ) && 'bg-base-30024'
                          }`}
                        >
                          <div
                            ref={isLast ? intersectTarget : null}
                            className="flex items-center"
                          >
                            {ListItemTemplates[listItem.template](
                              valueIndex,
                              value,
                              selectMultiple,
                              listItem.config,
                              dataCy
                            )}
                          </div>
                        </div>
                      </RadioButton>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })
            );
          })}
          {isFetchingMore && <LoadingComponent />}
          {!options?.flat?.()?.length && !hasInput && (
            <>
              {typeof emptyState === 'string' ? (
                <ListEmptyState
                  fullHeight
                  emptyIcon="icon-no-result-found"
                  size="small"
                  title={getObjectiveLocale(emptyState)}
                />
              ) : (
                <emptyState.component {...emptyState.props} />
              )}
              {addItemSuggestion &&
                debouncedSearchTerm &&
                (selectMultiple ? (
                  <Checkbox
                    id={debouncedSearchTerm}
                    value={debouncedSearchTerm}
                    onChange={() => onChange(debouncedSearchTerm)}
                    dataCy={dataCy?.checkbox}
                    customContainerClass="px-[16px]"
                  >
                    {ListItemTemplates[listItem.template](
                      -2,
                      debouncedSearchTerm,
                      selectMultiple,
                      listItem.config,
                      dataCy
                    )}
                  </Checkbox>
                ) : (
                  <div
                    id={debouncedSearchTerm}
                    value={debouncedSearchTerm}
                    onClick={() => onChange(debouncedSearchTerm)}
                  >
                    {ListItemTemplates[listItem.template](
                      -2,
                      debouncedSearchTerm,
                      selectMultiple,
                      listItem.config,
                      dataCy
                    )}
                  </div>
                ))}
            </>
          )}
          {hasInput &&
            inputOptions.map((value, index) => (
              <div
                className={`px-[16px] ${
                  index !== inputOptions?.length - 1 && 'mb-[16px]'
                }`}
                key={index}
              >
                <label className="mb-[16px]">{value.name}</label>
                <input
                  id="search-label"
                  type="text"
                  className="form-control"
                  defaultValue={selectedValue[index]}
                  onChange={(e) => debounceFn(e.target.value, index)}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

const DialogInterface = {
  title: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  noSearch: PropTypes.bool,
  setShowDialog: PropTypes.func,
  selectedValue: PropTypes.array,
  handleChange: PropTypes.func,
  selectMultiple: PropTypes.bool,
  listItem: PropTypes.shape({
    template: PropTypes.string,
    config: PropTypes.object
  }),
  headerItem: PropTypes.shape({
    template: PropTypes.string,
    config: PropTypes.object
  }),
  emptyState: PropTypes.string,
  addItemSuggestion: PropTypes.bool,
  staticOptions: PropTypes.array,
  placement: PropTypes.oneOf(Object.keys(placements)),
  queryFn: PropTypes.func,
  compareKey: PropTypes.string,
  searchKey: PropTypes.string,
  payloadFormat: PropTypes.object,
  returnSingle: PropTypes.bool,
  containerClassName: PropTypes.string,
  singleChoice: PropTypes.bool,
  dataCy: PropTypes.string,
  hasInput: PropTypes.bool,
  inputOptions: PropTypes.array
};

const DialogDefaultValue = {
  title: 'Dialog',
  searchPlaceholder: 'Search',
  noSearch: false,
  setShowDialog: () => {},
  selectedValue: [],
  handleChange: () => {},
  selectMultiple: false,
  listItem: {
    template: 'default'
  },
  headerItem: null,
  emptyState: 'No filter available',
  addItemSuggestion: false,
  staticOptions: [],
  placement: 'bottom-start',
  queryFn: null,
  compareKey: 'id',
  searchKey: 'id',
  payloadFormat: null,
  returnSingle: false,
  containerClassName: '',
  singleChoice: false,
  hasInput: false,
  inputOptions: []
};

Dialog.propTypes = DialogInterface;
Dialog.defaultProps = DialogDefaultValue;

export default Dialog;

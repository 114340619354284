import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import dayjs from 'dayjs';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import { calculateRecurrenceSurvey } from 'src/client/CultureClient';
import { useLocale } from 'src/hooks/useLocale';
import usePulseSurvey from 'src/hooks/usePulseSurvey';

const formatDateTime = (date, withTime = true) => {
  const format = withTime ? 'MMM DD, YYYY - LT' : 'MMM DD, YYYY';
  return dayjs(date).format(format);
};

const VerifySurveyScheduleCard = () => {
  const { surveyData } = usePulseSurvey();
  const { recurrence: recurrenceData } = surveyData;
  const startTime = surveyData?.startTime;
  const expiration = surveyData?.expiration;

  if (recurrenceData && Object.keys(recurrenceData, surveyData).length > 0) {
    return (
      <RecurringContentSchedule
        data={recurrenceData}
        firstStart={startTime}
        firstExpire={expiration}
      />
    );
  }

  return <RangeDateInfo startDate={startTime} endDate={expiration} />;
};

const UntilDateInfo = ({ every, untilValue, interval }) => {
  const intervalText = interval > 1 ? interval : '';
  const { setBothLocale } = useLocale();
  return (
    <>
      <p className="typography-h400 text-n-900 mt-[4px]">
        {setBothLocale('Repeating every [interval] [every] until')
          .replace('[every]', every)
          .replace('[interval]', intervalText)}{' '}
        <span className="typography-paragraph text-base-600">
          {dayjs(untilValue).format('D MMM, YYYY')}
        </span>
      </p>
    </>
  );
};

const RecurringContentSchedule = ({ data, firstStart, firstExpire }) => {
  const every = useMemo(() => data?.every, [data]);
  const interval = useMemo(() => data?.interval, [data]);
  const untilValue = useMemo(() => data?.until, [data]);
  const { setBothLocale } = useLocale();
  const { pathname } = useLocation();
  const [isLoading, setLoading] = useState(true);

  const [details, setDetails] = useState({
    nextStart: null,
    nextEnd: null,
    total: '-',
    current: '-',
    past: '-',
    upcoming: '-'
  });

  useEffect(() => {
    (async () => {
      const payload = {
        starts: firstStart,
        ends: firstExpire,
        every: every,
        interval: interval,
        until: untilValue
      };
      const { metadata, isSuccess } = await calculateRecurrenceSurvey(payload);
      if (!isSuccess) return;
      const { current, past, repetition, upcoming, nextPeriod } = metadata;
      setDetails((draft) => ({
        ...draft,
        current,
        total: repetition,
        past,
        upcoming,
        nextStart: nextPeriod?.startDate,
        nextEnd: nextPeriod?.endDate
      }));
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingScheduleRecurring />;

  const isCreateNew =
    pathname.includes('create-pulse-survey') ||
    pathname.includes('duplicate-pulse-survey');
  const isStarted = !isCreateNew && new Date() > new Date(firstStart);

  return (
    <>
      {isStarted ? (
        <>
          {details.nextStart && details.nextEnd && (
            <>
              <p className="text-n-800 typography-h100 mb-[4px]">
                {setBothLocale('Next Period')}
              </p>
              <RangeDateInfo
                startDate={details.nextStart}
                endDate={details.nextEnd}
                spanClass="text-n-800"
              />
            </>
          )}
          <UntilDateInfo
            every={every}
            untilValue={untilValue}
            interval={interval}
          />
          <p className="typography-h100 text-n-800 mt-[4px]">
            {setBothLocale(
              'First survey start on [start-date] and end on [end-date]'
            )
              .replace('[start-date]', formatDateTime(firstStart))
              .replace('[end-date]', formatDateTime(firstExpire))}
          </p>
        </>
      ) : (
        <>
          <RangeDateInfo startDate={firstStart} endDate={firstExpire} />
          <UntilDateInfo
            every={every}
            untilValue={untilValue}
            interval={interval}
          />
          <p className="typography-h100 text-n-800 mt-[4px]">
            {setBothLocale(
              'Next period will be on on [start-date] - [end-date]'
            )
              .replace('[start-date]', formatDateTime(details.nextStart, false))
              .replace('[end-date]', formatDateTime(details.nextEnd, false))}
          </p>
        </>
      )}

      <p className="typography-h100 text-n-800 mt-[4px]">
        {setBothLocale(
          'This survey will be repeated [total-survey] times until schedule end date.'
        ).replace('[total-survey]', details?.total)}
      </p>
      <p className="typography-h100 text-n-800 mt-[4px]">
        {setBothLocale(
          '[current-survey] survey currently running · [upcoming-survey] upcoming survey · [completed-survey] survey completed'
        )
          .replace('[current-survey]', details?.current)
          .replace('[upcoming-survey]', details?.upcoming)
          .replace('[completed-survey]', details?.past)}
      </p>
    </>
  );
};

const LoadingScheduleRecurring = () => {
  return (
    <>
      <Shimmer
        width={'80px'}
        height={18}
        customClass="rounded-[4px] mr-[12px]"
      />
      <Shimmer
        width={'200px'}
        height={48}
        customClass="rounded-[4px] mr-[12px] mt-[12px]"
      />
      <Shimmer
        width={'452px'}
        height={72}
        customClass="rounded-[4px] mr-[12px] mt-[12px]"
      />
    </>
  );
};

const RangeDateInfo = ({ startDate, endDate, spanClass = 'text-base-600' }) => {
  const { setBothLocale } = useLocale();
  return (
    <>
      <p className="typography-paragraph text-n-900">
        {setBothLocale('Start')}:{' '}
        <span className={spanClass} data-cy="start-date-survey">
          {formatDateTime(startDate)}
        </span>
      </p>
      <p className="typography-paragraph text-n-900">
        {setBothLocale('End')}:{' '}
        <span className={spanClass} data-cy="end-date-survey">
          {formatDateTime(endDate)}
        </span>
      </p>
    </>
  );
};

export default VerifySurveyScheduleCard;

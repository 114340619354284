import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import dayjs from 'dayjs';

import { GanttChartContext } from '../GanttChart';
import {
  generateGhostBar,
  useBarPositioning,
  useDrawDependencyLine
} from '../utils/barHelper';
import GanttBar from './GanttBar';

let mouseX = 0;

const setStroke = (action, id) => {
  const dependencies = document.querySelectorAll(`[id*="dep-"][id*="${id}"]`);
  for (let i = 0; i < dependencies?.length; i++) {
    dependencies[i].children[0].children[0].style.strokeWidth =
      action == 'enter' ? 2 : 1;
  }
};

const dragHandler = (e, columnWidth, dataObjective, positionLeft) => {
  const increment = Math.floor((e.clientX - mouseX) / columnWidth);
  const add = increment * columnWidth;
  const ghostElement = document.getElementById('bar-ghost' + dataObjective?.id);
  const ghostTooltip = document.getElementById(
    'bar-ghost-tooltip' + dataObjective?.id
  );
  ghostTooltip.innerHTML =
    dayjs(dataObjective.startDate).add(increment, 'day').format('DD MMM YYYY') +
    ' - ' +
    dayjs(dataObjective.dueDate).add(increment, 'day').format('DD MMM YYYY');
  ghostElement.style.left = positionLeft + add + 'px';
};

const dateHandler = async ({
  dataObjective,
  increment,
  callback,
  subContentOptions,
  columnWidth,
  setDateValue,
  setPositionLeft,
  positionLeft,
  prevLeft
}) => {
  const { singleObjectiveOptions } = subContentOptions || {};
  const callbackUpdateFn = singleObjectiveOptions?.callbackUpdateFn;
  const startDate = dayjs(dataObjective.startDate)
    .add(increment, 'day')
    .toISOString();
  const dueDate = dayjs(dataObjective.dueDate)
    .add(increment, 'day')
    .toISOString();
  const add = increment * columnWidth;
  setDateValue([startDate, dueDate]);
  setPositionLeft(positionLeft + add);
  if (!callbackUpdateFn) {
    callback();
    return;
  }
  const isSuccess = await callbackUpdateFn(dataObjective.id, {
    startDate,
    dueDate
  });
  if (!isSuccess) setPositionLeft(prevLeft.current);
  prevLeft.current = null;
  callback();
};

const SingleBarComponent = ({
  dataObjective,
  startDate,
  dueDate,
  section,
  group,
  subContentOptions
}) => {
  const barRef = useRef();
  const isHeldRef = useRef(false);
  const holdTImeoutRef = useRef(false);
  const prevLeft = useRef();
  const { isBarDraggable, periodStart, columnWidth, setDisableScroll } =
    useContext(GanttChartContext);
  const [isHover, setHover] = useState(false);
  const [dateValue, setDateValue] = useState([
    dayjs(startDate).toISOString(),
    dayjs(dueDate).toISOString()
  ]);

  const { positionLeft, widthElement, setPositionLeft } = useBarPositioning({
    startDate: dataObjective.startDate,
    dueDate,
    periodStart,
    columnWidth
  });

  //Creating dependency lines
  useDrawDependencyLine({
    dataObjective,
    positionLeft,
    effectDependencies: [positionLeft, startDate, dueDate]
  });

  const handleChangeDate = useCallback(
    (dataObjective, increment, callback) => {
      dateHandler({
        dataObjective,
        increment,
        callback,
        subContentOptions,
        columnWidth,
        setDateValue,
        setPositionLeft,
        positionLeft,
        prevLeft
      });
    },
    [columnWidth, positionLeft, setPositionLeft, subContentOptions]
  );

  useEffect(() => {
    const bgRow = document.getElementById('bg-task-name-' + dataObjective?.id);
    if (!bgRow) return;
    if (isHover) {
      bgRow.style.backgroundColor = 'var(--base-3008)';
      return;
    }
    bgRow.style.backgroundColor = '';
  }, [dataObjective, isHover]);

  useEffect(() => {
    if (!isBarDraggable) return;
    if (!barRef.current) return;
    const mainScroll = document.getElementById('main-scroll');
    const barElement = barRef.current;

    const theDragEvent = (e) =>
      dragHandler(e, columnWidth, dataObjective, positionLeft);

    const theMouseDownEvent = (e) => {
      isHeldRef.current = true;
      holdTImeoutRef.current = setTimeout(() => {
        if (!isHeldRef.current) return;
        const barGhost = document.getElementById(
          'bar-ghost' + dataObjective?.id
        );
        if (!barGhost) {
          generateGhostBar({ barRef, dataObjective, e });
        }
        prevLeft.current = positionLeft;

        mouseX = e.clientX;
        setDisableScroll(true);
        mainScroll.addEventListener('mousemove', theDragEvent);
        mainScroll.classList.add('select-none');
        window.addEventListener('mouseup', theMouseUpEvent);
      }, 500);
    };

    const theMouseUpEvent = (e) => {
      const increment = Math.floor((e.clientX - mouseX) / columnWidth);
      const ghostElement = document.getElementById(
        'bar-ghost' + dataObjective?.id
      );
      if (ghostElement) {
        const callback = () => {
          barRef.current.classList.remove('opacity-50');
          ghostElement.remove();
        };
        handleChangeDate(dataObjective, increment, callback);
        window.removeEventListener('mouseup', theMouseUpEvent);
        mainScroll.removeEventListener('mousemove', theDragEvent);
        mainScroll.classList.remove('select-none');
      }
      clearHold();
    };

    const clearHold = () => {
      setDisableScroll(false);
      clearTimeout(holdTImeoutRef.current);
      isHeldRef.current = false;
    };

    barElement.addEventListener('mousedown', theMouseDownEvent);
    barElement.addEventListener('mouseup', clearHold);

    return () => {
      barElement.removeEventListener('mouseup', clearHold);
      barElement.removeEventListener('mousedown', theMouseDownEvent);
      window.removeEventListener('mouseup', theMouseUpEvent);
      mainScroll.removeEventListener('mousemove', theDragEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barRef, positionLeft, columnWidth, dataObjective, isBarDraggable]);

  return (
    <div className="relative w-full h-[48px]">
      <div
        id={`bg-row-${dataObjective?.id}`}
        className={`absolute top-[0] h-full w-full z-[1] ${
          isHover ? 'bg-active' : ''
        }`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          ref={barRef}
          id={'bar' + dataObjective?.id}
          className="absolute top-[12px] z-[1] "
          onMouseEnter={() => setStroke('enter', dataObjective?.id)}
          onMouseLeave={() => setStroke('leave', dataObjective?.id)}
          style={{
            left: positionLeft,
            width: widthElement + 'px'
          }}
        >
          <GanttBar
            dataObjective={dataObjective}
            section={section}
            widthElement={widthElement}
            isHover={isHover}
            group={group}
            subContentOptions={subContentOptions}
            dateValue={dateValue}
            setDateValue={setDateValue}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleBarComponent;

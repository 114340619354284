import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import flatten from 'lodash/flatten';

import BarsContainer from './BarsContainer';

const MainBarContainer = (props) => {
  const { subOptions, showChild, noGroup } = props;

  if ((!subOptions && showChild) || noGroup) {
    return <MainBarWrapper {...props} />;
  }

  return <></>;
};

const MainBarWrapper = ({
  queryKey,
  contentLoaded,
  isFetching,
  group,
  subContentOptions,
  openAdd
}) => {
  const queryClient = useQueryClient();

  const [objectives, setObjectives] = useState([]);

  const data = queryClient.getQueryData(queryKey, { exact: false });
  useEffect(() => {
    if (data) {
      const newObjectives = flatten(
        data?.pages?.map((page) => {
          return page?.data;
        })
      ).filter((data) => data);
      setObjectives(newObjectives);
    } else {
      setObjectives([]);
    }
    // setTask is dependant on another section too and would make infinite loop if include it in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <BarsContainer
      group={group}
      objectivesData={objectives}
      contentLoaded={contentLoaded}
      isFetching={isFetching}
      subContentOptions={subContentOptions}
      openAdd={openAdd}
    />
  );
};

export default MainBarContainer;

import React from 'react';

import dayjs from 'dayjs';

import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';

const defaultPicture = Config.asset.general.defaultPicture;

function PerformanceReportTeamDetailHeaderComponent(props) {
  const { data, cycle, goals } = props;
  const averageScore = data.averageScore;
  const cycleScore = cycle.score;

  const teamRatingDescriptionColor = `#${data?.scoringOption?.colorHex}`;
  const teamRatingDescriptionStyle = {
    color: teamRatingDescriptionColor
  };

  const cycleRatingDescriptionColor = `#${cycle?.scoringOption?.colorHex}`;
  const cycleRatingDescriptionStyle = {
    color: cycleRatingDescriptionColor
  };

  const maxAvatar = 8;

  const goalTitle = () => {
    const title = `${getObjectiveLocale('Goal')}s in this cycle`;

    if (goals.length > 0) {
      return title.toUpperCase();
    }
    return null;
  };

  const getProfilePicture = (member) => {
    if (member && member.profilePicture) {
      return member.profilePicture;
    }

    return defaultPicture;
  };
  const getCyclePeriod = () => {
    const cycle = props.cycle;

    if (
      dayjs(cycle.start_at).format('MMM YYYY') ===
      dayjs(cycle.end_at).format('MMM YYYY')
    ) {
      return dayjs(cycle.end_at).format('MMM YYYY');
    } else if (
      dayjs(cycle.start_at).format('MMM') !== dayjs(cycle.end_at).format('MMM')
    ) {
      if (
        dayjs(cycle.start_at).format('YYYY') !==
        dayjs(cycle.end_at).format('YYYY')
      ) {
        return `${dayjs(cycle.start_at).format('MMM YYYY')}-${dayjs(
          cycle.end_at
        ).format('MMM YYYY')}`;
      }
      return `${dayjs(cycle.start_at).format('MMM')}-${dayjs(
        cycle.end_at
      ).format('MMM YYYY')}`;
    }
    return `${dayjs(cycle.start_at).format('MMM')}-${dayjs(cycle.end_at).format(
      'MMM YYYY'
    )}`;
  };

  const renderProgressView = (goal) => {
    if (goal && goal.dueDate && goal.measurement) {
      const progress =
        goal.measurement.progressPercentage <= 100
          ? goal.measurement.progressPercentage
          : 100;
      const backgroundColor = `#${goal.measurement.progressColorHex}`;

      return (
        <div className="time-period-goal-information">
          <div className="time-period-goal-date">
            {dayjs(goal.dueDate).format('DD MMM')}
          </div>
          <div className="time-period-goal-progress-bar">
            <LineProgressBar
              progress={progress}
              width={100}
              height={8}
              primaryColor={backgroundColor}
            />
          </div>
          <div className="time-period-goal-progress-label">
            {goal.measurement.progressPercentage}%
          </div>
        </div>
      );
    }
  };

  const renderTeamStatus = () => {
    if (data.involvement) {
      const role =
        data.involvement.role.charAt(0).toUpperCase() +
        data.involvement.role.slice(1);

      if (role === 'Leader') {
        return <div className="time-period-team-status leader">{role}</div>;
      }

      return <div className="time-period-team-status">{role}</div>;
    }
  };

  return (
    <div className="time-period-summary-card">
      <div className="time-period-team-detail">
        TEAM DETAIL
        <div className="time-period-team-title">{data.name}</div>
        <div className="time-period-team-rating">
          <i className="fa fa-star small" />
          <div className="time-period-team-rating-number">
            {averageScore.toFixed(1)}
          </div>
          <div className="time-period-team-rating-dot">·</div>
          <div
            className="time-period-team-rating-description"
            style={teamRatingDescriptionStyle}
          >
            {data.scoringOption.name}
          </div>
        </div>
        <div className="time-period-team-role">TEAM ROLE</div>
        {renderTeamStatus()}
        <div className="time-period-team-member">TEAM MEMBERS</div>
        <div className="time-period-team-member-avatars">
          {data.members &&
            data.members
              .filter((member) => member != null)
              .map((member, index) => {
                if (index < maxAvatar) {
                  return (
                    <div key={index}>
                      <AWSImage
                        className="time-period-team-member-avatar img-circle"
                        src={getProfilePicture(member)}
                      />
                    </div>
                  );
                } else if (
                  data.members.length > maxAvatar &&
                  index === maxAvatar
                ) {
                  return (
                    <span className="counter-involvement">{`+${
                      data.members.length - (maxAvatar + 1)
                    }`}</span>
                  );
                }
              })}
        </div>
        <div className="time-period-team-joined-title">DATE JOINED</div>
        <div className="time-period-team-joined-date">
          {dayjs(data.joinDate).format('DD MMMM YYYY')}
        </div>
      </div>
      <div className="time-period-summary">
        <div className="time-period-cycle">CYCLE INFO</div>
        <div className="time-period-title">{cycle.name}</div>
        <div className="time-period-rating-container">
          <div className="time-period-rating-card">
            <div className="time-period-rating-star">
              <i className="fa fa-star" />
            </div>
            <div className="time-period-rating-number">
              {cycleScore.toFixed(1)}
            </div>
            <div className="time-period-rating-dot">·</div>
            <div
              className="time-period-rating-description"
              style={teamRatingDescriptionStyle}
            >
              {cycle.scoringOption.name}
            </div>
          </div>
          <div className="time-period-monthly-card">{getCyclePeriod()}</div>
        </div>
        <div className="time-period-goals">
          {props.goals.length < 1 && (
            <div className="empty-state-container">
              <div className="header">No objective cycle available</div>
              <div className="sub-header">
                You don’t have any objective in this cycle
              </div>
            </div>
          )}
          {goalTitle()}
          {goals.map((goal, index) => (
            <div key={index} className="time-period-goal-card">
              <div className="time-period-goal-title">{goal.name}</div>
              {renderProgressView(goal)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PerformanceReportTeamDetailHeaderComponent;

import React, { useEffect, useState } from 'react';

import {
  AnalyticSearchProvider,
  useAnalyticUsersAdmin
} from 'context/admin/AnalyticSearchContext';

import HeaderPage from 'components/admin/HeaderPage';
import Loader from 'oldComponents/v2/Loader';

import SearchAnalyticUsers from './Search';
import User from './User';

function AnalyticUsers({ title }) {
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState({});
  const { hasMore, users, getUsersData, nextPage, prevPage } =
    useAnalyticUsersAdmin();

  const _getUsersData = async () => {
    setCurrentPage(1);
    setLoader(true);
    await getUsersData(query);
    setLoader(false);
  };

  const _nextPage = async () => {
    setCurrentPage(currentPage + 1);
    setLoader(true);
    await nextPage(query);
    setLoader(false);
  };

  const _prevPage = async () => {
    setCurrentPage(currentPage - 1);
    setLoader(true);
    await prevPage(query);
    setLoader(false);
  };

  useEffect(() => {
    _getUsersData();
  }, [query]);

  return (
    <>
      <HeaderPage titlePage={title} />
      <div className="dashboard_content_main mt-[24px]">
        <SearchAnalyticUsers setQuery={setQuery} query={query} />
        <p className="reviewed-title">Search Results</p>
        <div className="row">
          {loader ? (
            <table>
              <tbody>
                <tr>
                  <td colSpan="7">{<Loader />}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            users.map(function (data, idx) {
              return <User user={data} key={idx} />;
            }, this)
          )}
        </div>
        <div className="row table-footer text-right">
          <span className="page-info">Page {currentPage}</span>
          <button
            type="button"
            className={
              currentPage === 1 ? 'btn btn-disabled' : 'btn admin-btn-prev'
            }
            disabled={currentPage === 1}
            onClick={() => _prevPage()}
          >
            <i className="fa fa-arrow-left" />
            Previous
          </button>
          <button
            type="button"
            className={!hasMore ? 'btn btn-disabled' : 'btn admin-btn-prev'}
            disabled={!hasMore}
            onClick={() => _nextPage()}
          >
            Next <i className="fa fa-arrow-right" />
          </button>
        </div>
      </div>
    </>
  );
}

function AnalyticUsersWrapper({ route: { title } }) {
  return (
    <AnalyticSearchProvider>
      <AnalyticUsers title={title} />
    </AnalyticSearchProvider>
  );
}

export default AnalyticUsersWrapper;

function debounce(callback, handler) {
  if (handler) {
    clearTimeout(handler);
  }

  handler = setTimeout(() => {
    callback();
  }, 500);

  return handler;
}

export default debounce;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Checkbox from 'components/shared/Checkbox';
import LoadingComponent from 'components/shared/LoadingComponent';

const TeamResultDeliverables = ({
  setupReviewAspectStatus,
  reviewAspectsList,
  onChangeDisplayResult
}) => {
  return (
    <>
      <p className="typography-h500 mb-[4px]">
        {getObjectiveLocale(`Configure show scores/labels`)}
      </p>
      <p className="typography-paragraph typography-secondary mb-[16px]">
        {getObjectiveLocale(
          `Configure whether review cycle should show scores/labels for review results`
        )}
      </p>

      {setupReviewAspectStatus ? (
        reviewAspectsList?.map((score, index) => {
          return (
            <div className="flex items-center" key={index}>
              <Checkbox
                onChange={(e) =>
                  onChangeDisplayResult(e, score?.id, score?.type)
                }
                value={score?.name}
                id={`score-id-${score?.id}`}
                checked={score?.checked}
                dataCy={`label-${score?.id}`}
                labelClass="cursor-pointer w-full"
                customContainerClass=" w-full"
              >
                <p className="ml-[4px]">{score?.name}</p>
              </Checkbox>
            </div>
          );
        })
      ) : (
        <LoadingComponent />
      )}
    </>
  );
};

export default TeamResultDeliverables;

import React, { useMemo } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';
import { getObjectiveInvolvements } from 'utils/ObjectivesHelper';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';
import InlineDialogSelectMultipleUser from 'components/inline-dialog/InlineDialogSelectMultipleUser';
import SVGIcon from 'components/shared/SVGIcon';
import { getNewInvolvements } from 'components/sidebar/sidebar-create/SidebarCreateHelper';
import useDisabledUsers from 'src/hooks/useDisabledUsers';

const PMO = ({ objectiveValue, setObjectiveValue, updateObjective }) => {
  const { permissions, involvements } = objectiveValue;

  const { checkIsUserDisabled } = useDisabledUsers({
    isEdit: true,
    isProject: true,
    objective: objectiveValue
  });

  const selectedPmo = getObjectiveInvolvements(involvements, 'pmo');

  const currentInvolvements = useMemo(() => {
    return [...involvements];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PMOTrigger = () => {
    const users = selectedPmo.map(({ user }) => user) || [];

    return users.length > 0 ? (
      <div className="px-[8px] py-[8px] rounded-full hover:bg-base-30024 ml-[-8px] w-full">
        {users.length === 1 ? (
          <SingleUser
            userData={users[0]}
            isDisabled={!permissions?.includes('assign_pmo')}
          />
        ) : (
          users.length > 1 && (
            <AvatarGroup
              avatars={users}
              size="24"
              disabled={!permissions?.includes('assign_pmo')}
            />
          )
        )}
      </div>
    ) : (
      <div className="text-n-600 px-[8px] py-[8px] rounded-full hover:bg-base-30024 ml-[-8px] w-full flex items-center">
        <SVGIcon
          size="24"
          fillColor="var(--n-600)"
          iconName="icon-default-user"
          customClass="mr-[8px]"
        />
        {getObjectiveLocale('Select User')}
      </div>
    );
  };

  const handleChangeDialog = (involvements, type, extendedRoleType) => {
    const lastIndex = involvements?.length - 1;
    const selectedUser = involvements[lastIndex];

    checkIsUserDisabled(selectedUser?.user?.id, () => {
      let newInvolvements = getNewInvolvements(
        involvements,
        type,
        false,
        objectiveValue,
        null,
        extendedRoleType,
        true
      );

      setObjectiveValue((draft) => {
        draft.involvements = newInvolvements;
      });
    });
  };

  const updateStakeholders = async () => {
    const newInvolvements = objectiveValue.involvements.map((involvement) => ({
      userId: involvement.user.id,
      role: involvement.role,
      placementId: involvement.user.placementId,
      extendedRole: involvement.extendedRole
    }));

    const { isSuccess } = await updateObjective({
      involvements: newInvolvements
    });
    if (!isSuccess) {
      setObjectiveValue((draft) => {
        draft.involvements = currentInvolvements;
      });
    }
  };

  return (
    <div className="mt-[16px] w-1/2">
      <p className="typography-h100 text-n-800">{getObjectiveLocale('PMO')}</p>
      <InlineDialogSelectMultipleUser
        customTriggerComponent={PMOTrigger}
        header="PMO"
        role="follower"
        extendedRoleType="pmo"
        position="bottom"
        objectiveValue={objectiveValue}
        selectedValue={selectedPmo}
        handleChange={handleChangeDialog}
        onCloseDialog={updateStakeholders}
        permission={permissions?.includes('assign_pmo')}
        dialogWrapperClass="w-full"
        dataCy="inline-dialog-project-pmo"
      />
    </div>
  );
};

export default PMO;

import React from 'react';

import { useFormalReview } from 'context/FormalReviewContext';

import WhiteCard from 'components/admin/analytic/team-review/WhiteCard.js';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import SidebarRight from 'src/components/design-system/SidebarRight/SidebarRight';

const OverlayRightSidebar = ({ onClose }) => {
  let [{ overlayRightSidebarData }] = useFormalReview();

  return (
    <SidebarRight
      dataCy="sidebar-overlay-right"
      fixed={false}
      customClass="!fixed right-0 !bg-n-200"
      customWidth="!w-[360px]"
    >
      <SidebarRight.Header
        customClass="flex flex-item justify-between"
        useBorder={false}
      >
        <div className="flex flex-col">
          <p className="typography-h600 text-n-900">
            {overlayRightSidebarData.name}
          </p>
          <p className="typography-paragraph whitespace-pre-line text-n-800 mt-[24px] text-justify">
            {overlayRightSidebarData.description}
          </p>
        </div>
        <SVGIcon
          onClick={() => onClose()}
          fillColor="var(--n-600)"
          iconName="icon-clear"
          size="24"
          customClass="ml-[16px]"
          dataCy="sidebar-goal-close"
        />
      </SidebarRight.Header>
      <SidebarRight.Body withSpace={false} customClass="px-[16px] py-[8px]">
        {overlayRightSidebarData.loading ? (
          <LoadingComponent />
        ) : (
          overlayRightSidebarData.data.map((behaviour) => {
            return (
              <div key={behaviour.id} className="mb-[8px]">
                <WhiteCard classes="!px-[16px]">
                  <p className="typography-h200 text-base-600 mb-[12px]">{`Level ${parseInt(
                    behaviour.orderLevel + 1
                  )}`}</p>
                  <h4 className="typography-h400-longform text-n-900 mb-[8px]">
                    {behaviour.title}
                  </h4>
                  <p className="typography-paragraph text-n-800">
                    {behaviour.description}
                  </p>
                </WhiteCard>
              </div>
            );
          })
        )}
      </SidebarRight.Body>
    </SidebarRight>
  );
};

export default OverlayRightSidebar;

import React, { useEffect, useState } from 'react';

import {
  confirmReviewAspectScore,
  getObjectiveCount
} from 'client/FormalReviewClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Card from 'components/design-system/Card';
import ObjectScoring from 'components/formal-review/annual-review/ObjectScoring';

import SidebarCustomAttributesGoal from './SidebarCustomAttributesGoal';

const CustomAttributeCard = ({
  aspect,
  currentTrackId,
  saveCallback,
  currentTrack,
  template,
  assignmentId
}) => {
  const {
    // id: reviewAspectId,
    objects,
    components,
    answers,
    state,
    showOption,
    showTooltip,
    systemRawScore,
    systemAverage,
    suggestions
  } = aspect;
  const object = objects?.[0];

  const {
    mechanism,
    view,
    allowOverride,
    objectiveCategoryIds,
    sliderLabel,
    id,
    useComment
  } = object || {};

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentState, setCurrentState] = useState(state);
  const [isLoading, setIsLoading] = useState(false);
  const [objectiveCount, setObjectiveCount] = useState(0);

  let [{ phase }, { changeLastUpdate }] = useFormalReview();

  const sortedOptionsBasedOnScore = components.options.sort(
    (a, b) => a.score - b.score
  );
  const minimumScore = sortedOptionsBasedOnScore[0].score;
  const maximumScore =
    sortedOptionsBasedOnScore[sortedOptionsBasedOnScore.length - 1].score;

  const confirmScore = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const { isSuccess, data } = await confirmReviewAspectScore(
        assignmentId,
        currentTrackId,
        id
      );
      if (isSuccess && data) {
        setCurrentState(data?.state);
        setIsLoading(false);
        changeLastUpdate(); // to trigger refetch assignment
      }
    }
  };

  const getObjectiveData = async () => {
    const { data, isSuccess } = await getObjectiveCount(
      assignmentId,
      currentTrackId,
      id
    );
    if (isSuccess && data) {
      setObjectiveCount(data?.topParentGoalCount);
    }
  };

  const getReviewAspectSuggestedScore = () => {
    if (objectiveCategoryIds.length == 0) {
      return null;
    }
    return view == 'raw' ? systemRawScore : systemAverage;
  };

  const handleScoringDisabled = () => {
    // as per product request, user cannot change their answers after reveiw aspect scoring is confirmed
    if (state == 'confirmed') return true;

    return !allowOverride;
    // mechanism === 'weight_type' && !allowOverride;
  };

  useEffect(() => {
    getObjectiveData();
  }, []);

  return (
    <>
      <Card customClass="px-[16px] mb-[32px]">
        <div className="flex justify-between mb-[16px]">
          <div className="flex flex-col">
            <p className="typography-h500 text-n-900">{object.name}</p>
          </div>
          <div className="flex">
            {mechanism === 'weight_type' && (
              <Button.Tertiary
                onClick={() => setIsSidebarOpen(true)}
                customClass="mr-[16px] h-[32px]"
              >
                {getObjectiveLocale('See Goals')}
              </Button.Tertiary>
            )}
            {mechanism === 'weight_type' && (
              <Button onClick={() => confirmScore()} customClass="h-[32px]">
                {getObjectiveLocale(
                  `${
                    currentState == 'confirmed' ? 'Reassess' : 'Confirm'
                  } Score`
                )}
              </Button>
            )}
          </div>
        </div>
        <ObjectScoring
          id={id}
          object={object}
          components={components}
          showOption={showOption}
          showTooltip={showTooltip}
          answers={answers}
          currentTrack={currentTrack}
          withLabelInfo={view != 'raw'}
          view={view}
          sliderLabel={sliderLabel}
          currentTrackId={currentTrackId}
          useComment={useComment}
          disabled={handleScoringDisabled()}
          mechanism={mechanism}
          template={template}
          withTitle={false}
          state={state}
          suggestedScoreData={getReviewAspectSuggestedScore()}
          minimumScore={minimumScore}
          maximumScore={maximumScore}
          objectiveCount={objectiveCount}
          suggestions={suggestions}
          allowOverride={allowOverride}
          phaseType={phase.type}
        />
      </Card>
      {isSidebarOpen && (
        <SidebarCustomAttributesGoal
          reviewAspectId={id}
          currentTrackId={currentTrackId}
          saveCallback={saveCallback}
          closeSidebar={() => setIsSidebarOpen(false)}
          title={object?.name}
          objectiveCategoryIds={objectiveCategoryIds}
        />
      )}
    </>
  );
};

export default CustomAttributeCard;

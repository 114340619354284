export const restructurePreviousSummaries = (data) => {

  const temp = data?.previousSummaries ? [...data?.previousSummaries] : [];
  const totalScore = data?.scores?.reduce((curr, score) => {
      return curr + (score?.weightedScore || 0)
  }, 0);

  temp.push({
    positionName: data?.positionName ? data?.positionName : data?.target?.jobTitle,
    scores: data?.scores,
    finalScore: {
      convertedScore: data?.convertedScore,
      score: totalScore,
      proportional: data?.proportional || 0,
      proportionalScore: data?.proportionalScore || totalScore || 0
    }
  })

  return temp;
}
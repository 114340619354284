import React from 'react';

import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import Objectives from 'components/objectives/Objectives';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

const FILTER_ID = 'filter_highlighted_goals';

function Highlighted({ route: { title } }) {
  const { user, config } = useUser();
  const { match } = useUrl();

  const filter = {
    phase: ['running', 'completed'],
    state: 'active'
  };

  const extraFilter = {
    highlightedBy: user.id
  };

  return (
    <>
      <HeaderPage titlePage={title} />
      <div className="pb-[40px] bg-n-100 mt-[24px] min-h-[calc(100vh-64px)]">
        <AdvancedFilterObjectives
          filterOptions={[
            'search',
            'group',
            'sort',
            'dateFilter',
            'menuFilter'
          ]}
          defaultFilter={filter}
          group={config?.myGoalsGroupByOptions?.[0] || 'no-group'}
          page="highlighted"
          customClass="mb-[24px]"
          filterId={FILTER_ID}
          type="explorer"
        >
          <Objectives
            cardModel="goal-list"
            page="highlighted"
            extraFilter={extraFilter}
          />
        </AdvancedFilterObjectives>
      </div>
      <React.Suspense fallback={<div></div>}>
        {match && <SidebarMyGoals />}
      </React.Suspense>
    </>
  );
}

export default Highlighted;

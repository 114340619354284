const version = '[AIV]{version}[/AIV]';
const env = process.env.NODE_ENV;
const localStorageEnv = JSON.parse(localStorage.getItem('env') || '{}');
const redGearEnv = JSON.parse(localStorage.getItem('redLsEnv') || '{}');

/* global ENVConfig */
const appName = 'happy5 performance';
const host = '';
const apiHost =
  redGearEnv.PERFORMANCE_API_HOST ||
  localStorageEnv.PERFORMANCE_API_HOST ||
  process.env.PERFORMANCE_API_HOST ||
  ENVConfig.PERFORMANCE_API_HOST;
const localeHost = '';
const amplitudeKey = process.env.AMPLITUDE_KEY || ENVConfig.AMPLITUDE_KEY;
const amplitudeKeyCulture =
  process.env.AMPLITUDE_KEY_CULTURE || ENVConfig.AMPLITUDE_KEY_CULTURE;
const amplitudeAPIEndpoint =
  process.env.AMPLITUDE_API_ENDPOINT || ENVConfig.AMPLITUDE_API_ENDPOINT;

const assetPrefix = '/assets/img/';
const generalAssetDir = `${assetPrefix}general/`;
const formalReviewAsset = `${assetPrefix}formal-review/`;
const objectiveAssetDir = `${assetPrefix}objective/`;
const adminAsset = `${assetPrefix}admin/`;
const createObjectiveAssetDir = `${assetPrefix}create-objective/`;
const sidebarObjectiveAssetDir = `${assetPrefix}sidebar/`;
const listObjectiveAssetDir = `${assetPrefix}list-objective/`;
const valueAssetDir = `${assetPrefix}value/`;
const cultureDir = `${assetPrefix}culture/`;

const v2AssetDir = `${assetPrefix}v2/`;
const path = {
  index: '/',
  profile: '/profile',
  profileManagerAndSubordinate: '/profile/managerial',
  profileRecognition: '/profile/recognition',
  profileFeedback: '/profile/feedback',
  profileReview: '/profile/review',
  profileSkill: '/profile/skill',
  userProfile: '/user',
  userProfileManagerAndSubordinate: '/managerial',
  userProfileRecognition: '/recognition',
  userProfileFeedback: '/feedback',
  userProfileReview: '/review',
  userProfileSkill: '/skill',
  objectiveDetail: '/objective',
  goal: '/goal',
  multipleObjectives: '/create-objective',
  objectiveTag: '/tag',
  organizationLogin: '/login/organization',
  userLogin: '/login/user',
  userRecovery: '/login/recovery',
  search: '/user/search',
  searchObjective: '/objective/search',
  tutorial: '/tutorial',
  pageNotFound: '/404',
  feeds: '/feeds'
};

const asset = {
  general: {
    userIcon: `${generalAssetDir}user-icon.svg`,
    defaultPicture: `${generalAssetDir}pic-default-profilepict.png`,
    logoHappy5: `${generalAssetDir}logo-full.png`,
    dialog1: `${generalAssetDir}pic-dialog-1@2x.png`,
    dialog7: `${generalAssetDir}pic-dialog-7@2x.png`,
    editOnGoing: `${generalAssetDir}edit_ongoing.png`,
    iconDownload: `${generalAssetDir}download_chart.svg`,
    laptopAsset: `${generalAssetDir}laptop.png`,
    defaultGroupCover: `${generalAssetDir}default-group-cover.png`,
    brokenImage: `${generalAssetDir}broken-image.png`
  },
  formalReview: {
    icCloseGrey: `${formalReviewAsset}icon-close-grey@3x.png`,
    icHandler: `${formalReviewAsset}handler.png`,
    iconFeedback: `${formalReviewAsset}feedback.png`,
    iconStar: `${formalReviewAsset}star.png`,
    thumbIcon: `${formalReviewAsset}recognition.png`
  },
  objective: {
    changeComplexity: `${objectiveAssetDir}changeComplexity.png`,
    iconAddMember: `${objectiveAssetDir}ico-add-member.png`,
    iconCancel: `${objectiveAssetDir}iconCancel@3x.png`,
    iconCommentActivity: `${objectiveAssetDir}iconComment@3x.png`,
    iconEdit: `${objectiveAssetDir}ico-edit.png`,
    iconImportCsv: `${objectiveAssetDir}import-csv@2x.png`,
    iconOngoing: `${objectiveAssetDir}ico-ongoing.png`,
    iconOwnerGroup: `${objectiveAssetDir}ico-owner-group.png`,
    iconProgress: `${objectiveAssetDir}ico-update-progress.png`,
    iconRecognition: `${objectiveAssetDir}ico-recognition.png`
  },
  v2: {
    iconPrivate: `${v2AssetDir}ico-private.png`,
    iconCompanyGoal: `${v2AssetDir}building@2x.png`,
    iconAddProfile: `${v2AssetDir}ico-add-profile.png`,
    iconCloseCircle: `${v2AssetDir}ico-close-circle.png`,
    iconDelete: `${v2AssetDir}ico-delete.png`,
    iconPlus: `${v2AssetDir}ico-plus.png`,
    iconSave: `${v2AssetDir}ico-save.png`,
    iconCommentCircle: `${v2AssetDir}comment-circle-icon.png`,
    iconAttachment: `${v2AssetDir}attachment.png`,
    iconDownload: `${v2AssetDir}download-file-icon.png`,
    iconDoneCircle: `${v2AssetDir}done-circle-icon.png`,
    iconProgressMetric: `${v2AssetDir}progress-matric.png`,
    iconTask: `${v2AssetDir}ico-task.png`,
    icoTaskCreate: `${v2AssetDir}ico-task-create.png`,
    icoExchangeArrows: `${v2AssetDir}exchange-arrows.png`,
    icoArrowLeft: `${v2AssetDir}arrow-left.png`,
    icoArrowRight: `${v2AssetDir}arrow-right.png`,
    icoGroup: `${v2AssetDir}ico-group.png`,
    icoPrivateActive: `${v2AssetDir}ico-private-active.png`,
    MenuDots: `${v2AssetDir}3-dots.png`,
    icoCloseSidebar: `${v2AssetDir}ico-close-sidebar.png`,
    icoSearchCollagues: `${v2AssetDir}icon-search-collagues.png`,
    icoFilter: `${v2AssetDir}ico-filter.png`,
    icoPublicActive: `${v2AssetDir}ico-public-active.png`,
    icoLoader: `${v2AssetDir}ico-loader.gif`,
    icoEmptyMyTasks: `${v2AssetDir}ico-empty-mytask.png`,
    icoFolder: `${v2AssetDir}ico-folder.png`,
    icoEmptyActivities: `${v2AssetDir}ico-empty-activities.png`,
    icoLocked: `${v2AssetDir}ico-locked.png`,
    icoNoReview: `${v2AssetDir}ico-no-review.png`,
    icoEmptyDirectReport: `${v2AssetDir}empty-direct-report.png`,
    icoAlignmentTree: `${v2AssetDir}goal-tree.png`,
    icoAutoAvg: `${v2AssetDir}auto-avg.png`,
    icoAutoSum: `${v2AssetDir}auto-sum.png`,
    icoManual: `${v2AssetDir}manual.png`,
    iconPlusGrey: `${v2AssetDir}icon-add.png`,
    iconStar: `${v2AssetDir}star.svg`,
    icoAutoWeightAvg: `${v2AssetDir}auto-weighted-avg.svg`,
    dropdown: `${v2AssetDir}arrow-dropdown-purple.png`,
    icoDefaultUser: `${v2AssetDir}ico-default-user.png`
  },
  admin: {
    burgerIcon: `${adminAsset}burger-icon.png`,
    logoCulture: `${adminAsset}logo-culture.svg`,
    iconInsight: `${adminAsset}ic_insight.svg`,
    iconOrgDetail: `${adminAsset}ic_org_detail.svg`,
    iconUserManagement: `${adminAsset}ic_user_mngmnt.svg`,
    iconEmployees: `${adminAsset}people.svg`,
    worldContinent: `${adminAsset}world_continents.png`,
    announcementLabel: `${adminAsset}announcement-label.png`,
    pencilPurple: `${adminAsset}edit-purple.svg`,
    copyPurple: `${adminAsset}copy-purple.svg`,
    copyGrey: `${adminAsset}copy-grey.svg`,
    settingPurple: `${adminAsset}settings-strong-purple.svg`,
    attachFileGrey: `${adminAsset}attach-file-grey.svg`,
    archiveGrey: `${adminAsset}archive-grey.svg`,
    defaultValueImg: `${adminAsset}img-circle.png`,
    camera: `${adminAsset}camera.png`,
    informationCircledGrey: `${adminAsset}information-circled-grey.svg`,
    collapseIcon: `${adminAsset}collapse.png`,
    imgCircle: `${adminAsset}img-circle.png`,
    cloneGrey: `${adminAsset}clone.svg`,
    helpCircleGrey: `${adminAsset}help-circled-grey.svg`,
    radioButtonOff: `${adminAsset}radio-button-off.svg`,
    checkboxOff: `${adminAsset}checkbox-outline-blank.svg`,
    removeCircleRed: `${adminAsset}remove-circle-red.svg`,
    successGreenIcon: `${adminAsset}success-green-icon.svg`,
    fileDownloadImg: `${adminAsset}file-download.svg`,
    fileUploadImg: `${adminAsset}file-upload.svg`
  },
  checkin: {
    iconClose: `${generalAssetDir}close-round-grey.svg`,
    iconUserManagement: `${adminAsset}ic_user_mngmnt.svg`,
    worldContinent: `${adminAsset}world_continents.png`,
    checkinButtonIcon: `${adminAsset}checkin-button-icon.svg`
  },
  createObjective: {
    iconDescription: `${sidebarObjectiveAssetDir}description.svg`,
    iconCalendar: `${createObjectiveAssetDir}calendar-grey.svg`,
    iconMetric: `${sidebarObjectiveAssetDir}metric.svg`,
    iconWeight: `${sidebarObjectiveAssetDir}weight.svg`,
    iconAlign: `${createObjectiveAssetDir}align-parent.svg`,
    iconLabel: `${sidebarObjectiveAssetDir}label.svg`,
    iconFollower: `${createObjectiveAssetDir}people-grey.svg`,
    arrowDown: `${createObjectiveAssetDir}arrow-dropdown-purple-down.svg`,
    arrowUp: `${createObjectiveAssetDir}arrow-dropdown-purple-up.svg`,
    iconInfo: `${createObjectiveAssetDir}information-circled.svg`,
    iconInfoGrey: `${createObjectiveAssetDir}information-circled-grey.svg`,
    iconEdit: `${createObjectiveAssetDir}edit-circle.svg`,
    objectiveType: `${createObjectiveAssetDir}objective-type.svg`,
    star: `${sidebarObjectiveAssetDir}star.svg`,
    starYellow: `${sidebarObjectiveAssetDir}star-yellow.svg`,
    iconDoneGrey: `${sidebarObjectiveAssetDir}done-grey.svg`,
    iconMention: `${sidebarObjectiveAssetDir}mention.svg`,
    iconEditSidebar: `${sidebarObjectiveAssetDir}edit.svg`,
    iconKebab: `${listObjectiveAssetDir}kebab.svg`,
    iconCalendarSidebar: `${sidebarObjectiveAssetDir}calendar.svg`,
    iconFollowerSidebar: `${sidebarObjectiveAssetDir}follower.svg`,
    iconArrowBlack: `${listObjectiveAssetDir}arrow-back-black.svg`,
    iconTeams: `${createObjectiveAssetDir}team.png`,
    iconOwnerGroup: `${createObjectiveAssetDir}owner-group.svg`
  },
  value: {
    goodJudgement: `${valueAssetDir}value-good-judgement.png`,
    accountability: `${valueAssetDir}value-accountability.png`,
    agile: `${valueAssetDir}value-agile.png`,
    caring: `${valueAssetDir}value-caring.png`,
    collaborative: `${valueAssetDir}value-collaborative.png`,
    communicationPresence: `${valueAssetDir}value-communication-presence.png`,
    courageous: `${valueAssetDir}value-courageous.png`,
    creativeProblemSolving: `${valueAssetDir}value-creative-problem-solving.png`,
    curious: `${valueAssetDir}value-curious.png`,
    environment: `${valueAssetDir}value-environment.png`,
    excellence: `${valueAssetDir}value-excellence.png`,
    extraMile: `${valueAssetDir}value-extra-mile.png`,
    flexible: `${valueAssetDir}value-flexible.png`,
    generous: `${valueAssetDir}value-generous.png`,
    honest: `${valueAssetDir}value-honest.png`,
    impactful: `${valueAssetDir}value-impactful.png`,
    innovative: `${valueAssetDir}value-innovative.png`,
    integrity: `${valueAssetDir}value-integrity.png`,
    passionate: `${valueAssetDir}value-passionate.png`,
    positiveAttitude: `${valueAssetDir}value-positive-attitude.png`,
    resourceful: `${valueAssetDir}value-resourceful.png`,
    selfless: `${valueAssetDir}value-selfless.png`,
    service: `${valueAssetDir}value-service.png`,
    social: `${valueAssetDir}value-social.png`,
    takingOwnership: `${valueAssetDir}value-taking-ownership.png`,
    techSavvy: `${valueAssetDir}value-tech-savvy.png`
  },
  culture: {
    brokenImage: `${cultureDir}broken-image.svg`,
    comment: `${cultureDir}comment.png`,
    likeActive: `${cultureDir}like-active.png`,
    likeInactive: `${cultureDir}like-inactive.png`,
    ribbonRecognition: `${cultureDir}ribbon-recognition.png`,
    endorsePlus: `${cultureDir}endorse-plus.png`,
    quoteRecognition: `${cultureDir}quote-recognition.png`,
    sparkleFeeling: `${cultureDir}sparkle-feeling.png`,
    sparkleLeaderboard: `${cultureDir}sparkles-leaderboard.png`,
    checkWhite: `${cultureDir}check-white.png`,
    lockedFeelingIcon: `${cultureDir}locked-feeling.png`,
    brokenImageCenter: `${cultureDir}broken-image-center.png`,
    illustration: `${cultureDir}illustration.png`,
    googlePlay: `${cultureDir}google-play.png`,
    appStore: `${cultureDir}app-store.png`,
    cultureInPhone: `${cultureDir}culture-in-phone.jpg`,
    performanceInPhone: `${cultureDir}performance-in-phone.png`,
    howToInstallApp: `${cultureDir}how-to-install-app.jpg`,
    installStep1: `${cultureDir}step-1.jpg`,
    installStep2: `${cultureDir}step-2.jpg`,
    installStep3: `${cultureDir}step-3.jpg`,
    installStep4: `${cultureDir}step-4.jpg`,
    postedRecognition: `${cultureDir}posted-recognition-icon.svg`
  }
};

const locale = {
  localeHost,
  localeToken:
    'd688102e89f4240c388a20fcf13523abe8a2da5902118d2c2f6761a43b439c15c38f0871177a53d67178f1bafd224c6bb1b9d9de6df1d17c60b956c79a9f770c'
};

module.exports = {
  hostName: host,
  appName,
  path,
  asset,
  amplitudeKey,
  version,
  env,
  apiHost,
  locale,
  amplitudeKeyCulture,
  amplitudeAPIEndpoint
};

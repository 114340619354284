import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';
import { getObjectiveInvolvements } from 'utils/ObjectivesHelper';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';

const ProjectInvolvements = ({ objectiveDetail }) => {
  const { involvements } = objectiveDetail;

  const restructureInvolvements = (involvements) => {
    return involvements?.map(({ user }) => {
      return {
        type: 'circle',
        name: user.name,
        src: user.profilePicture,
        tooltip: true
      };
    });
  };

  const selectedLeader = getObjectiveInvolvements(
    involvements,
    'leader',
    restructureInvolvements
  );
  const selectedReviewer = getObjectiveInvolvements(
    involvements,
    'reviewer',
    restructureInvolvements
  );
  const selectedMember = getObjectiveInvolvements(
    involvements,
    'member',
    restructureInvolvements
  );
  const selectedStakeholder = getObjectiveInvolvements(
    involvements,
    'stakeholder',
    restructureInvolvements
  );
  const selectedPmo = getObjectiveInvolvements(
    involvements,
    'pmo',
    restructureInvolvements
  );

  return (
    <>
      <div className="flex ml-[24px] mt-[16px]">
        {selectedLeader.length > 0 && (
          <div className="w-[164px] mr-[24px]">
            <p className="typography-h200 mb-8">
              {getObjectiveLocale('Leader')}
            </p>
            <SingleUser userData={selectedLeader[0]} />
          </div>
        )}
        {selectedReviewer.length > 0 && (
          <div className="w-[164px] mr-[24px]">
            <p className="typography-h200 mb-8">
              {getObjectiveLocale('Reviewer')}
            </p>
            <SingleUser userData={selectedReviewer[0]} />
          </div>
        )}
        {selectedMember.length > 0 && (
          <div className="w-[164px]">
            <p className="typography-h200 mb-8">
              {getObjectiveLocale('Members')}
            </p>
            {selectedMember.length == 1 && (
              <SingleUser userData={selectedMember[0]} />
            )}
            {selectedMember.length > 1 && (
              <AvatarGroup avatars={selectedMember} size="24" />
            )}
          </div>
        )}
      </div>

      <div className="flex ml-[24px] mt-[16px]">
        {selectedStakeholder.length > 0 && (
          <div className="w-[164px] mr-[24px]">
            <p className="typography-h200 mb-8">
              {getObjectiveLocale('Stakeholders')}
            </p>
            {selectedStakeholder.length == 1 && (
              <SingleUser userData={selectedStakeholder[0]} />
            )}
            {selectedStakeholder.length > 1 && (
              <AvatarGroup avatars={selectedStakeholder} size="24" />
            )}
          </div>
        )}
        {selectedPmo.length > 0 && (
          <div className="w-[164px] mr-[24px]">
            <p className="typography-h200 mb-8">{getObjectiveLocale('PMO')}</p>
            {selectedPmo.length == 1 && (
              <SingleUser userData={selectedPmo[0]} />
            )}
            {selectedPmo.length > 1 && (
              <AvatarGroup avatars={selectedPmo} size="24" />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectInvolvements;

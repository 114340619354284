import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router';

import { getApprovalAvailability } from 'client/ApprovalClient';
import {
  getObjectivePhases,
  getObjectivePriorities
} from 'client/ObjectivesClient';
import { useCreateObjective } from 'context/CreateObjectiveContext';
import { MetricsProvider } from 'context/MetricsContext';
import { useMetrics } from 'context/MetricsContext';
import { useToastContext } from 'context/ToastContext';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { capitalize } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Button from 'components/design-system/Button';
import UploadCsvComponent from 'components/modal/UploadCsv';
import ObjectivesModal from 'components/objectives/ObjectivesModal';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';

import CreateListObjective from '../components/create-objective/CreateObjectivePanel';
import SidebarCreateObjective from '../components/sidebar/SidebarCreate';
import { CreateObjectiveProvider } from '../context/CreateObjectiveContext';
import { useUser } from '../context/UserContext';
import '../scss/web/create-objective';
import CreateObjectiveWarning from './create-objective-warning/CreateObjectiveWarning';

function CreateObjective(props) {
  const [activeCreateGoal, setActiveCreateGoal] = useState(true);
  const [modalBulkUpload, setModalBulkUpload] = useState(false);
  const [activeLevel, setActiveLevel] = useState(0);
  const [listPriorities, setListPriorities] = useState([]);
  const [toastNotif, setToastNotif] = useState({
    show: false,
    message: '',
    warning: true
  });
  const {
    isSuccess,
    setIsSuccess,
    singleObjective,
    saveGoal,
    lists,
    isGrouped,
    groupedList,
    handleChangeObjective,
    changeValue,
    keyIntegrationList
  } = useCreateObjective();
  const { listMetrics, objectiveCategories } = useMetrics();
  const {
    config: { showImportCsv }
  } = useUser();
  const { addToast } = useToastContext();
  const params = new URLSearchParams(location.search);

  const prevPath = props?.location?.state?.prevPath;
  let type = props.match.params.type;
  const uploadCSVTitle =
    `Import Multiple ` + capitalize(getObjectiveLocale(type));
  const backUrl = prevPath ? `${prevPath.substring(1)}` : `${type}s`;

  const getPriorities = async () => {
    const { data } = await getObjectivePriorities();
    if (data) {
      setListPriorities(data);
    }
  };

  const _saveGoal = async () => {
    let { isProject, involvements } = singleObjective;
    if (
      isProject &&
      (!involvements.some(
        (involvement) =>
          involvement?.role === 'assignee' &&
          involvement?.extendedRole === 'leader'
      ) ||
        !involvements.some((involvement) => involvement?.role === 'assigner'))
    ) {
      setToastNotif({
        show: true,
        message: 'Must have 1 leader and 1 reviewer',
        warning: true
      });
      setTimeout(function () {
        setToastNotif({ show: false, message: '', warning: true });
      }, 3000);
    } else {
      await saveGoal();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      keyIntegrationList?.length > 0 &&
        addToast({
          title: getObjectiveLocale('Import child issues is started'),
          msg: getObjectiveLocale(
            'We are currently importing your Jira issues. Please wait a few minutes.'
          ),
          type: 'info'
        });
      setTimeout(() => {
        setIsSuccess(false);
        props.history.replace(`/${backUrl}`);
      }, 1500);
    }
  }, [isSuccess]);

  useEffect(() => {
    getPriorities();
  }, []);

  // TEAM
  let isCanSave = true;
  if (type === 'team') {
    let hasMember = lists[0].involvements.some(({ role }) => role === 'member');
    let hasReviewer = lists[0].involvements.some(
      ({ role }) => role === 'reviewer'
    );
    let childrenHasAssignee =
      lists[0].children &&
      lists[0].children.length > 0 &&
      lists[0].children[0].name;
    isCanSave = hasMember && hasReviewer && childrenHasAssignee;
  }

  const disableSave = isGrouped
    ? groupedList.every((group) => group.lists.every((list) => list.name == ''))
    : lists.every((list) => list.name === '');

  const headerProps = {
    titlePage: getObjectiveLocale('Create ' + capitalize(type)),
    primaryAction: {
      title: 'Save',
      onClick: () => _saveGoal(),
      dataCy: 'create-objective-save',
      disabled: disableSave || !isCanSave,
      withLoading: true
    },
    secondaryAction: {
      title: 'Cancel',
      onClick: () => props.history.replace(`/${type}s`),
      dataCy: 'create-objective-cancel'
    },
    backToUrl: `/${backUrl}`,
    isHeaderComposer: true
  };
  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="create-objective-page h-[calc(100vh-64px)]">
        <div className="viewport-wrapper">
          {modalBulkUpload && (
            <Modal
              title={uploadCSVTitle}
              maxHeight={600}
              withFooter={false}
              eventOnClickClose={() => setModalBulkUpload(false)}
            >
              <UploadCsvComponent
                history={props.history}
                type={type}
                format="new"
                eventOnClick={(boolean) => setModalBulkUpload(boolean)}
              />
            </Modal>
          )}
          {isSuccess && (
            <Modal
              title="Success"
              withCloseIcon={false}
              className="width-auto"
              maxHeight="470"
            >
              <div className="save-success" data-cy="modal-rocket">
                <img
                  src={Config.asset.general.dialog1}
                  alt="Rocket"
                  className="rocket-keyframes"
                />
                <p className="text-content typography-paragraph mb-[14px] pt-[32px]">
                  <strong>{getObjectiveLocale('Blast off!')}</strong>
                  <span>
                    {getObjectiveLocale(
                      type === 'goal'
                        ? 'Your goal has been sent to the cloud'
                        : 'Your task has been sent to the cloud'
                    )}
                  </span>
                </p>
              </div>
            </Modal>
          )}
          <div
            className={
              'create-objective-main-container ' +
              (activeCreateGoal ? 'collapsed' : 'expanded')
            }
          >
            <div className="create-objective">
              {type !== 'task' && (
                <CreateObjectiveWarning withWarningIcon={true} />
              )}
              {showImportCsv && (
                <Button.Tertiary
                  onClick={() => setModalBulkUpload(true)}
                  customClass="mt-[24px]"
                >
                  <SVGIcon
                    size="24"
                    fillColor="var(--base-600)"
                    iconName="icon-file_upload"
                    customClass="mr-[4px]"
                  />
                  {uploadCSVTitle + ' Using CSV'}
                </Button.Tertiary>
              )}
              <CreateListObjective
                type={type}
                setActiveLevel={setActiveLevel}
              />
            </div>
            {activeCreateGoal && (
              <div className="sidebar">
                <SidebarCreateObjective
                  singleObjectiveAction={singleObjective}
                  objectiveCategories={objectiveCategories}
                  listMetrics={listMetrics}
                  type={type}
                  handleChangeObjective={handleChangeObjective}
                  activeLevel={activeLevel}
                  listPriorities={listPriorities}
                  changeValue={changeValue}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastNotif
        showNotif={toastNotif?.show}
        message={toastNotif?.message}
        warning={toastNotif?.warning}
        usePortal
      />
      <ObjectivesModal />
    </>
  );
}

function CreateObjectivePage(props) {
  let type = props.match.params.type;
  const [isLoading, setIsLoading] = useState();
  const [showModal, setShowModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('');
  const history = useHistory();
  const { user, config } = useUser();
  const isUsingApprovalCreate = config.permissions?.approvalSee;

  const _getApprovalAvailability = async () => {
    const { data } = await getApprovalAvailability({});

    let isWaitApproval =
      data && data.permissions?.includes('withdraw') && type === 'goal';

    if (isWaitApproval) {
      setApprovalStatus(data.approvalStatus);
    }

    setShowModal(isWaitApproval);
  };

  const isGrouped =
    type == 'goal' &&
    config.objectiveWeightType === 'type' &&
    config.objectiveWeightTypeOptions.includes('goal') &&
    config?.objectiveCreationGroupBy &&
    config?.objectiveCreationGroupBy != 'none';

  useEffect(() => {
    isUsingApprovalCreate && _getApprovalAvailability();

    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 0);
  }, [type]);

  return (
    <>
      {showModal ? (
        <div
          className="modal-create-objective-ban"
          data-cy="create-objective-ban-modal"
        >
          <Modal
            headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
            withCloseIcon={false}
            title={getObjectiveLocale("Can't create goals").replace(
              'goals',
              getObjectiveLocale('goals')
            )}
            className="modal-create-objective-ban"
            dataCyModal="create-objective-ban-modal"
            withPrimaryBtn={{
              title: getObjectiveLocale('Ok'),
              dataCy: 'create-objective-ban-button',
              onClick: () => history.replace('/goals')
            }}
          >
            <div className="body-create-objective-ban">
              <p className="subtitle">
                {getObjectiveLocale(
                  "You can't create goals when on this status."
                ).replace('goals', getObjectiveLocale('goals'))}{' '}
                {getObjectiveLocale('Your status right now:')}
              </p>
              <div className="info-card">
                <img src={Config.asset.createObjective.iconInfoGrey} />
                <span>{approvalStatus}</span>
              </div>
              <p className="subtitle">
                {getObjectiveLocale(
                  'or you can withdraw your approval request by clicking the withdraw approval button first on the approval section.'
                )}
              </p>
            </div>
          </Modal>
        </div>
      ) : isLoading ? (
        <LoadingComponent />
      ) : (
        <MetricsProvider type={type}>
          <CreateObjectiveProvider {...props} isGrouped={isGrouped}>
            <CreateObjective {...props} />
          </CreateObjectiveProvider>
        </MetricsProvider>
      )}
    </>
  );
}

export default withRouter(CreateObjectivePage);

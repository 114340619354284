import React from 'react';

import kebabCase from 'lodash/kebabCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown, {
  useDropdown
} from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const DropdownTrigger = ({ selectedValueName }) => {
  const { open } = useDropdown();

  return (
    <Dropdown.Trigger dataCy="advance-group-by">
      <div className="flex justify-between items-center bg-n-000 h-[32px] py-[8px] pr-[4px] pl-[16px] min-w-[180px] max-w-[224px] border border-solid border-n-400 rounded-[4px]">
        <TooltipContainer
          show={!open && selectedValueName}
          text={`${getObjectiveLocale('Group')} by: ${getObjectiveLocale(
            selectedValueName
          )}`}
        >
          <p className="typography-h400 w-[124px] truncate">
            {getObjectiveLocale('Group')} by:{' '}
            {getObjectiveLocale(selectedValueName)}
          </p>
        </TooltipContainer>
        <SVGIcon
          size="24"
          fillColor="var(--n-600)"
          iconName="icon-unfold_more"
        />
      </div>
    </Dropdown.Trigger>
  );
};

function Group({ lists, defaultValue, onChange }) {
  const selectedValueName =
    lists.find((list) => list.id === defaultValue)?.name || lists[0].name;

  return (
    <div className="ml-[8px]">
      <Dropdown>
        <DropdownTrigger selectedValueName={selectedValueName} />
        <Dropdown.MenuItems type="fixed">
          {lists
            .filter((list) => list.visible)
            .map((group, index) => (
              <Dropdown.MenuItem
                key={index}
                customClass="!min-w-[180px] !max-w-[224px]"
                onClick={() => onChange(group.id)}
                dataCy={`option-${kebabCase(group.name)}`}
              >
                {getObjectiveLocale(group.name)}
              </Dropdown.MenuItem>
            ))}
        </Dropdown.MenuItems>
      </Dropdown>
    </div>
  );
}

export default Group;

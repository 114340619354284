import React, { useEffect, useRef, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';

import { getTrackTemplateData } from 'client/TrackTemplateClient.js';
import useClickOutside from 'hooks/useClickOutside';

import Button from 'components/design-system/Button';
import BannerBox from 'components/shared/BannerBox';
import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';
import Modal from 'components/shared/modal/Modal';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

import './ModalEditTrack.scss';

const ModalEditTrack = ({
  openModalEdit,
  setOpenModalEdit,
  setOpenModalView,
  setTrackData,
  state,
  setTrackChecked
}) => {
  const { index, trackData, track, phase } = openModalEdit;
  const [inputName, setInputName] = useState(track.name || '');
  const [inputDescription, setInputDescription] = useState(
    track?.config?.description
  );
  const [openModalListTrack, setOpenModalListTrack] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  const [selectedDropdownData, setSelectedDropdownData] = useState({});
  const [error, setError] = useState('');
  const modalListTrackRef = useRef();

  const templateData = trackData.filter((data) => data.type == track.type);

  const templateChecker = (id) => {
    return templateData.find((dt) => dt.template.id == id);
  };

  const reorder = (startIndex, endIndex, data) => {
    const result = [...data];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const saveEditTrack = () => {
    let newTrackData = cloneDeep(trackData);
    if (inputName != track.name) {
      if (trackData.find((data) => data.name == inputName)) {
        setError('Track name is already in use');
        return;
      }
    }
    if (state == 'add') {
      newTrackData.push({
        name: inputName,
        config: { description: inputDescription },
        selected: true,
        template: selectedDropdownData,
        type: track.type,
        weight: phase?.id == 'managerReview' ? 100 : 0
      });

      const countSelected = newTrackData.filter((data) => data.selected).length;
      newTrackData = reorder(index, countSelected - 1, newTrackData);
      setTrackChecked(countSelected);
    } else {
      newTrackData[index]['template'] = selectedDropdownData;
      newTrackData[index]['name'] = inputName;
      newTrackData[index]['config']['description'] = inputDescription;
    }
    setError('');
    setTrackData(newTrackData);
    setOpenModalEdit({ show: false });
  };

  const _getTrackTemplateData = async () => {
    const { data } = await getTrackTemplateData({
      trackType: track.type,
      state: 'active'
    });
    if (data) {
      setDropdownData(data);
      if (state == 'add') {
        for (let i = 0; i < data.length; i++) {
          let isAvailable = templateChecker(data[i].id);
          if (!isAvailable) {
            setSelectedDropdownData(data[i]);
            break;
          }
        }
      }
    }
  };

  const selectDropdown = (data) => {
    setOpenModalListTrack(false);
    setSelectedDropdownData(data);
  };

  useEffect(() => {
    _getTrackTemplateData();
    if (state == 'edit') {
      setSelectedDropdownData(track.template);
    }
  }, []);

  useClickOutside(modalListTrackRef, () => setOpenModalListTrack(false));

  return (
    <Modal
      className="w-[600px]"
      withPrimaryBtn={{
        title: 'Save',
        dataCy: 'button-primary-modal',
        onClick: saveEditTrack,
        disabled: inputName == ''
      }}
      withSecondaryBtn={{
        title: 'Cancel',
        dataCy: 'button-secondary-modal',
        onClick: () => setOpenModalEdit({ show: false })
      }}
      eventOnClickClose={() => setOpenModalEdit({ show: false })}
      title={state == 'add' ? 'Add Scored Attribute Track' : 'Edit Track'}
      withCloseIcon={false}
      dataCyModal="modal-add-edit-track"
    >
      {error && <BannerBox text={error} type="error" />}
      <div className="wrapper-title-track mt-[4px] mb-[16px]">
        <p className="typography-h100 text-n-800">{phase.title}</p>
        <p className="typography-paragraph">
          {track.type == 'review_aspects_scoring'
            ? 'Scored Attribute'
            : startCase(track.type)}
        </p>
      </div>
      <p className="typography-h100 text-n-800 mb-[8px]">
        {getObjectiveLocale('Track Name')}
      </p>
      <input
        className="edit-track-input mb-[16px]"
        value={inputName || ''}
        onChange={(e) => setInputName(e.target.value)}
      />
      <p className="typography-h100 text-n-800 mb-[8px]">
        {getObjectiveLocale('Track Description')}
      </p>
      <TextArea
        className="h-[80px] mb-[12px]"
        value={inputDescription || ''}
        onHandleChange={(e) => setInputDescription(e.target.value)}
        textPlaceholder=""
        dataCy="description-track-input"
      />
      <p className="typography-h100 text-n-800 mb-[8px]">
        {getObjectiveLocale('Template')}
      </p>
      <div className="wrapper-dropdown-track flex items-center relative">
        <div
          className="dropdown-template"
          onClick={() => setOpenModalListTrack(true)}
        >
          {selectedDropdownData?.name}
          <SVGIcon
            customClass="icon-fold"
            iconName="icon-unfold_more"
            fillColor="var(--n-600)"
            size={24}
          />
        </div>
        <Button.Secondary
          onClick={() =>
            setOpenModalView({ track: selectedDropdownData, show: true })
          }
        >
          Preview
        </Button.Secondary>
        {openModalListTrack && (
          <div className="list-filter-component" ref={modalListTrackRef}>
            {dropdownData &&
              dropdownData.map((data, index) => {
                const isSelected = templateData.find(
                  (dt) => dt.template.id == data.id
                );
                let isVisible = true;
                if (state == 'add') {
                  isVisible = !isSelected;
                } else {
                  isVisible = track.template.id == data.id ? true : !isSelected;
                }
                return (
                  isVisible && (
                    <div
                      key={index}
                      className="row-filter"
                      data-cy={'dropdown-' + index}
                      onClick={() => selectDropdown(data)}
                    >
                      {data.name}
                    </div>
                  )
                );
              })}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalEditTrack;

import React from 'react';

import 'components/shared/TextArea.scss';

function TextArea({
  className = '',
  textPlaceholder,
  id,
  width,
  height = 36,
  value = '',
  onHandleChange,
  dataCy,
  maxHeight = 111,
  onBlur = () => {}
}) {
  function onHandleChangeDetails(e) {
    const el = e.currentTarget;
    el.style.cssText = `height: ${el.scrollHeight}px !important`;

    if (el.scrollHeight > maxHeight) {
      el.style.cssText = `height: ${maxHeight}px !important; max-height: ${maxHeight}px !important; overflow-y: scroll;`;
    }
    if (onHandleChange) {
      onHandleChange(e);
    }
  }

  return (
    <textarea
      id={id}
      className={`shared-textarea
        ${className}
        text-n-800 rounded-[4px] max-h-[36px]
       overflow-hidden state-secondary-purple
        height-${height}
      `}
      placeholder={textPlaceholder}
      onChange={onHandleChangeDetails}
      cols={width}
      height={height}
      value={value}
      data-cy={dataCy}
      onBlur={onBlur}
    />
  );
}

TextArea.defaultProps = {
  textPlaceholder: 'Placeholder',
  textValue: '',
  cols: 40,
  height: 130
};

export default TextArea;

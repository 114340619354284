import React from 'react';
import { useState } from 'react';

import { useUser } from 'context/UserContext';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { getRollupText, whichBgColorClassName } from 'utils/ObjectivesHelper';

import MetricsDropdown from 'components/objectives/MetricsDropdown';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import SecondaryButton from 'components/shared/Button/SecondaryButton';
import CalculationTypeIndicator from 'components/shared/CalculationTypeIndicator';
import SVGIcon from 'components/shared/SVGIcon';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';

const ProgressMetric = ({
  objectiveDetail,
  setModalOpen,
  setModalType,
  updateObjective
}) => {
  const {
    measurement,
    permissions = [],
    calculationType,
    milestoneType,
    type
  } = objectiveDetail || {};

  const {
    progressPercentage,
    progressColorHex,
    currentValue,
    unit,
    targetValue,
    rollUp,
    startingValue
  } = measurement || {};

  const {
    config: { includeTaskRollUp }
  } = useUser();

  const showRollUp = type == 'task' ? includeTaskRollUp : true;

  const [showDialog, setShowDialog] = useState(false);

  const buttonText = `Update ${
    milestoneType && milestoneType !== 'disabled' ? 'Milestone' : ''
  } Progress`;
  const localeProgressPercentage =
    progressPercentage && progressPercentage.toLocaleString('id-ID');

  const updateMetrics = ({ data }) => {
    if (measurement?.unit == null && !data?.unitId) {
      return;
    }

    if (
      data?.targetValue != targetValue ||
      data?.unitId != measurement?.id ||
      data?.rollUp != rollUp ||
      data?.startingValue != startingValue
    ) {
      if (data.unit == '') {
        delete data.unit;
        data.unitId = null;
      }
      delete data.description;
      updateObjective({ measurement: { ...data } });
    }
  };

  return (
    <>
      {measurement?.unit ? (
        <div className="px-24">
          <div
            className="height-48 hover:bg-base-3008 flex items-center justify-between mx-[-24px] pl-[24px] mb-[8px] cursor-pointer relative"
            onClick={() => setShowDialog(true)}
            data-cy="metric-trigger"
          >
            <div className="flex items-center">
              <SVGIcon
                iconName="icon-bar_chart"
                size="24"
                fillColor="var(--n-600)"
                customClass="mr-16"
              />
              <span data-cy="sidebar-detail-metric-rollup">
                {measurement.description} {unit && `( ${unit} )`}
                {showRollUp &&
                  measurement?.rollUp &&
                  `,${' '}${getRollupText(measurement.rollUp)}`}
              </span>
            </div>
            {showDialog && (
              <FloatingComponent
                lockScroll={false}
                customVerticalMargin={-24}
                customHorizontalMargin={8}
              >
                <MetricsDropdown
                  type="absolute"
                  position="right"
                  measurement={measurement}
                  calculationType={calculationType}
                  setShowDialog={setShowDialog}
                  useAdvanced
                  closeEvent={updateMetrics}
                  usePortal={true}
                />
              </FloatingComponent>
            )}
          </div>
          <div className="pl-40">
            <CalculationTypeIndicator
              calculationType={calculationType}
              customClass="mb-[8px]"
              bgColorClass="bg-b-200"
              cySuffix="info"
              showText
            />

            {measurement?.progressColorHex && (
              <div className="flex justify-start items-center">
                <LineProgressBar
                  width={248}
                  progress={progressPercentage}
                  primaryColor={whichBgColorClassName(progressColorHex)}
                  fullFirst
                  height={16}
                />
                <p className="typography-paragraph mb-0 ml-24">
                  {localeProgressPercentage}%
                </p>
              </div>
            )}
            <div className="mt-8" data-cy="sidebar-detail-value">
              <span className="mr-4 text-v-600">
                {getNumberLocale(currentValue)}
              </span>
              of
              <span className="ml-4">
                {getNumberLocale(measurement?.targetValue)}
              </span>
            </div>
            {showRollUp &&
              measurement?.unit &&
              measurement?.rollUp !== 'disabled' && (
                <span className="block typography-secondary my-8">
                  {getObjectiveLocale(
                    'Update progress will be automatically calculated'
                  )}
                </span>
              )}
            {permissions?.includes('update_current_value') &&
              measurement?.progressColorHex && (
                <SecondaryButton
                  datacy="sidebar-detail-update"
                  customClass="my-8"
                  onClick={() => {
                    setModalOpen(true);
                    setModalType('update');
                  }}
                >
                  {getObjectiveLocale(buttonText)}
                </SecondaryButton>
              )}
          </div>
        </div>
      ) : (
        <div
          className={`hover:bg-base-3008 cursor-pointer relative  ${
            showDialog ? 'bg-base-3008' : 'bg-n-000'
          }`}
        >
          <div
            className="h-[48px] flex items-center py-[12px] px-[24px]"
            onClick={() => setShowDialog(true)}
            data-cy="metric-trigger"
          >
            <SVGIcon
              iconName="icon-bar_chart"
              size="24"
              fillColor="var(--n-600)"
              customClass="mr-[16px]"
            />
            <div className="flex items-center min-height-24 mr-auto">
              <span className="typography-paragraph text-n-600">
                {getObjectiveLocale('Metric')}
              </span>
            </div>
            {showDialog && (
              <FloatingComponent lockScroll={false}>
                <MetricsDropdown
                  type="absolute"
                  measurement={measurement}
                  calculationType={calculationType}
                  setShowDialog={setShowDialog}
                  useAdvanced
                  closeEvent={updateMetrics}
                  usePortal={true}
                />
              </FloatingComponent>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProgressMetric;

import React, { useEffect, useState } from 'react';

import { getListFilter } from 'client/adminClient';

import DateFilter from 'components/advance-filter/DateFilter';

import InlineDialogFilter from './InlineDialogFilter.js';

function HeaderFilter({
  setSelectedParams,
  selectedParams,
  currentDepartment,
  customClass
}) {
  const [listDepartment, setListDepartment] = useState([]);
  const [searchDepartment, setSearchDepartment] = useState('');
  const [listDivision, setListDivision] = useState([]);
  const [searchDivision, setSearchDivision] = useState('');

  let wordingDepartment;

  if (selectedParams?.department?.length == 0 && currentDepartment) {
    wordingDepartment = currentDepartment;
  } else if (selectedParams?.department?.length == 1) {
    wordingDepartment = selectedParams?.department;
  } else if (selectedParams?.department?.length > 1) {
    wordingDepartment = '(Selected Departments)';
  } else {
    wordingDepartment = 'All';
  }

  let wordingDivision =
    selectedParams?.division?.length > 0 ? '(Selected Divisions)' : 'All';

  const getFilterData = async (type) => {
    const { data } = await getListFilter({
      q: type == 'department' ? searchDepartment : searchDivision,
      key: type
    });
    if (data && type == 'department') {
      setListDepartment(data);
    } else if (data && type == 'division') {
      setListDivision(data);
    }
  };

  useEffect(() => {
    getFilterData('department');
    // eslint-disable-next-line
  }, [searchDepartment]);

  useEffect(() => {
    currentDepartment && getFilterData('division');
    // eslint-disable-next-line
  }, [searchDivision, currentDepartment]);

  let filterDepartmentList = currentDepartment
    ? listDepartment?.filter((data) => data !== 'All Department')
    : listDepartment;

  return (
    <div className="flex items-center">
      <DateFilter
        containerClassName="overview-filter-calendar mr-[16px]"
        formatType="DD MMMM YYYY"
        options={['annual', 'quarter', 'custom']}
        onSelect={([periodBegin, periodEnd]) => {
          setSelectedParams({
            ...selectedParams,
            date: [periodBegin, periodEnd]
          });
        }}
        selectedValue={selectedParams?.date}
        noFilterDate={false}
        customClass={customClass}
        position="left"
      />
      <InlineDialogFilter
        subText={!currentDepartment ? 'You can only select 7 department' : ''}
        setSearch={setSearchDepartment}
        title="Department"
        wordingInfo={wordingDepartment}
        listData={filterDepartmentList}
        selectedData={selectedParams?.department}
        setSelectedData={(data) =>
          setSelectedParams({ ...selectedParams, department: data })
        }
        notMultiple={currentDepartment}
        dataCy="dialog-department"
      />
      {currentDepartment && (
        <InlineDialogFilter
          subText="You can only select 7 division"
          setSearch={setSearchDivision}
          title="Division"
          wordingInfo={wordingDivision}
          listData={listDivision}
          selectedData={selectedParams?.division}
          setSelectedData={(data) =>
            setSelectedParams({ ...selectedParams, division: data })
          }
          notMultiple={false}
          dataCy="dialogDivision"
        />
      )}
    </div>
  );
}

export default HeaderFilter;

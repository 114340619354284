import React from 'react';

import classSetter from 'classnames';
import PropTypes from 'prop-types';

function Loader({ text, className }) {
  const loaderClass = {
    'loader-container': true
  };

  return (
    <div className={classSetter(loaderClass, className)}>
      <span>{text}</span>
    </div>
  );
}

Loader.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

Loader.defaultProps = {
  text: 'Loading Data',
  className: ''
};

export default Loader;

import React, { useEffect, useRef, useState } from 'react';

import classSetter from 'classnames';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import {
  getRetentionActivity,
  getUserInfosCMSCulture,
  getUserVisitsCMSCulture
} from 'client/adminClient.js';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import { listOptionDate } from 'components/shared/Calendar/ListAntdDateHelper';
import Checkbox from 'components/shared/Checkbox';
import Loader from 'components/shared/LoadingComponent';
import LineChart from 'components/shared/charts/LineChart';

import {
  InsightCalendar,
  _dailyHandleChartView,
  _monthlyHandleChartView,
  _weeklyHandleChartView,
  getParamsHelper,
  paramsHelperHandleChartView
} from './InsightHelpers';
import InsightRetentionChartFeatureUsage from './InsightRetentionChartFeatureUsage';
import {
  CardChartWrapper,
  CardWrapper,
  DauWauMau,
  ItemCompared,
  TotalUser
} from './InsigthRetentionComponent';

const Retention = ({ route: { title } }) => {
  const [dau, setDau] = useState(null);
  const [wau, setWau] = useState(null);
  const [mau, setMau] = useState(null);
  const [chartAdoption, setChartAdoption] = useState({});
  const [selectedDate, setSelectedDate] = useState([
    dayjs().startOf('week').toISOString(),
    dayjs().endOf('week').toISOString()
  ]);

  const [intervalButton, setIntervalButton] = useState(
    paramsHelperHandleChartView(true, false, false, 'day')
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isNumber, setIsNumber] = useState(true);
  const [userInfoState, setUserInfoState] = useState({});
  const [isViewFilterDropdownShown, setisViewFilterDropdownShown] =
    useState(false);
  const [currentChartCategories, setCurrentChartCategories] = useState([]);
  const [arrayOfFilter, setArrayOfFilter] = useState([]);
  const [isItemComparedDropdownShown, setIsItemComparedDropdownShown] =
    useState(false);
  const [viewFilterState, setViewFilterState] = useState('All');
  const [hideAllUsersSeries, setHideAllUsersSeries] = useState(false);
  const [localeUserActivity, setLocaleUserActivity] = useState({});
  const [totalUsers, setTotalUsers] = useState(null);
  const listOptionsTime = listOptionDate([
    'This Day',
    'This Week',
    'This Month'
  ]);

  const getHandleRetentionActivity = async () => {
    const dailyParams = getParamsHelper('day');
    const weeklyParams = getParamsHelper('week');
    const monthlyParams = getParamsHelper('month');

    let { data: daily } = await getRetentionActivity(dailyParams);
    let { data: weekly } = await getRetentionActivity(weeklyParams);
    let { data: monthly } = await getRetentionActivity(monthlyParams);
    if (daily) {
      const dailyLastKey = daily.result.length - 1;
      setTotalUsers(daily.result[dailyLastKey].activated.allUsers);
      setDau(daily.result);
    }
    if (weekly) setWau(weekly.result);
    if (monthly) setMau(monthly.result);
  };

  const showViewFilterDropdown = () =>
    setisViewFilterDropdownShown(!isViewFilterDropdownShown);

  const handleCancel = () => {
    setIsItemComparedDropdownShown(false);
    setisViewFilterDropdownShown(false);
  };

  const seriesGenerator = (data, isNumber) => {
    const series = [];
    var categories = [];
    Object.keys(data[0]).map((name) => {
      if (isNumber) {
        if (
          name !== 'timeframe' &&
          name !== 'percentage' &&
          name !== 'activated'
        ) {
          Object.keys(data[0][name]).map((item, idx) => {
            series.push({
              name: item.replace('_', ' '), //`All ${name} users`,
              data: [],
              key: name,
              keyName: Object.keys(data[0][name])[idx]
            });
          });
        }
      } else {
        if (name !== 'timeframe' && name === 'percentage') {
          Object.keys(data[0][name]).map((item, idx) => {
            series.push({
              name: item.replace('_', ' '), //'All active users',
              data: [],
              key: name,
              keyName: Object.keys(data[0][name])[idx]
            });
          });
        }
      }
    });

    series.map((item) => {
      data.map((item2) => {
        item.data.push({
          x: new Date(item2.timeframe.start).getTime(),
          y: isNumber
            ? item2[item.key][item.keyName]
            : parseInt(item2[item.key][item.keyName].toFixed(2)),
          timeFrame: item2.timeframe,
          percentage: isNumber
            ? parseInt(item2['percentage'][item.keyName].toFixed(2))
            : ''
        });
      });
      categories.push(item.keyName);
    });

    return { series, categories };
  };

  const funcHideAllUsersSeries = () => {
    let chartScope = chart.current.getChart();
    chartScope.series[0].hide();

    setCurrentChartCategories(currentChartCategories.slice(1));
    setHideAllUsersSeries(true);
  };

  const handleSelectType = (e) => {
    const periodBegin = selectedDate[0];
    const periodEnd = selectedDate[1];

    setIsNumber(!isNumber);
    setIsLoading(true);
    getRetentionData(
      periodBegin,
      periodEnd,
      intervalButton.growth.selected,
      isNumber,
      arrayOfFilter
    );
  };

  const handleShowItemComparedDrowdown = (param, e) => {
    e.preventDefault();
    if (param === 'growth') {
      setIsItemComparedDropdownShown(true);
    }
  };

  const removeFilter = (index, textLabel) => {
    if (textLabel === 'allUsers') {
      funcHideAllUsersSeries();
    } else {
      let timeUnit = intervalButton.growth.selected;
      let selectedIndex = index - 1;
      let arrayOfFilterCurr = arrayOfFilter;

      arrayOfFilterCurr.splice(selectedIndex, 1);
      setArrayOfFilter(arrayOfFilterCurr);
      setIsLoading(true);

      getRetentionData(
        selectedDate[0],
        selectedDate[1],
        timeUnit,
        isNumber,
        arrayOfFilter
      );
    }
  };

  const handleQueryGrowth = (range) => {
    setSelectedDate(range);
    const periodBegin = selectedDate[0];
    const periodEnd = selectedDate[1];
    const timeUnit = intervalButton.growth.selected;

    getRetentionData(periodBegin, periodEnd, timeUnit, isNumber, arrayOfFilter);
  };

  const handleChartView = (interval, e) => {
    e.preventDefault();

    const periodBegin = selectedDate[0];
    const periodEnd = selectedDate[1];

    switch (interval) {
      case 'daily':
        _dailyHandleChartView(
          periodBegin,
          periodEnd,
          isNumber,
          arrayOfFilter,

          getRetentionData,
          setIntervalButton,
          paramsHelperHandleChartView
        );
        break;
      case 'weekly':
        _weeklyHandleChartView(
          periodBegin,
          periodEnd,
          isNumber,
          arrayOfFilter,

          getRetentionData,
          setIntervalButton,
          paramsHelperHandleChartView
        );
        break;
      case 'monthly':
        _monthlyHandleChartView(
          periodBegin,
          periodEnd,
          isNumber,
          arrayOfFilter,

          getRetentionData,
          setIntervalButton,
          paramsHelperHandleChartView
        );
        break;
      default:
    }
  };

  const handleSelectView = async (itemFilter) => {
    const { data } = await getUserInfosCMSCulture(itemFilter);
    if (data) {
      let arrayOfFilter = [];
      let tempObj = {};
      let userInfoData = data;
      for (let i = 0; i < userInfoData.length; i++) {
        tempObj[itemFilter] = userInfoData[i];
        arrayOfFilter.push(tempObj);
        tempObj = {};
      }

      setArrayOfFilter(arrayOfFilter);
      setIsLoading(true);
      setisViewFilterDropdownShown(false);
      setViewFilterState(itemFilter);
      setHideAllUsersSeries(false);

      getRetentionData(
        selectedDate[0],
        selectedDate[1],
        'day',
        isNumber,
        arrayOfFilter
      );
    }
  };

  const confirmAddMetric = () => {
    let timeUnit = intervalButton.growth.selected;
    let tempObj = {};

    Object.keys(userInfoState).map((item, idx) => {
      let sel = document.getElementById(item);
      let key = item;

      if (sel.value !== 'Choose a value') {
        tempObj[key] = sel.value;
      }
    });

    arrayOfFilter.push(tempObj);

    setArrayOfFilter(arrayOfFilter);
    setIsItemComparedDropdownShown(false);
    setIsLoading(true);

    getRetentionData(
      selectedDate[0],
      selectedDate[1],
      timeUnit,
      isNumber,
      arrayOfFilter
    );
  };
  const changeItemLabelFormat = (item) => {
    let itemLabel = item.replace('_', ' ');
    itemLabel = itemLabel.charAt(0).toUpperCase() + itemLabel.slice(1);

    if (itemLabel !== 'All') {
      let lastChar = itemLabel[itemLabel.length - 1];

      if (lastChar !== 'y') {
        itemLabel += 's';
      } else {
        itemLabel = itemLabel.slice(0, -1);
        itemLabel += 'ies';
      }
    }

    itemLabel = itemLabel === 'All' ? itemLabel : 'All ' + itemLabel;

    return itemLabel;
  };

  const getUserInfos = async () => {
    const { data } = await getUserInfosCMSCulture('');
    if (data) {
      let userInfoData = data;

      for (let i = 0; i < userInfoData.length; i++) {
        let keyData = userInfoData[i];
        getUserInfosKey(keyData, userInfoData);
      }
    }
  };

  const getUserInfosKey = async (keyData, userInfoData) => {
    const { data } = await getUserInfosCMSCulture(keyData);
    if (data) {
      let userInfoStateCurr = userInfoState;
      let userInfoDataCurr = data;
      userInfoDataCurr.unshift('Choose a value');
      userInfoStateCurr[keyData] = userInfoDataCurr;

      setUserInfoState(userInfoStateCurr);
    }
  };

  const _getRetentionParamHelper = (
    periodBegin,
    periodEnd,
    customFilter,
    timeUnit
  ) => ({
    periodBegin: dayjs(periodBegin).startOf('day').toISOString(true),
    periodEnd: dayjs(periodEnd).endOf('day').toISOString(true),
    customFilter,
    timeUnit
  });

  const getRetentionData = async (
    periodBegin,
    periodEnd,
    timeUnit,
    isNumber,
    customFilter
  ) => {
    const params = _getRetentionParamHelper(
      periodBegin,
      periodEnd,
      customFilter,
      timeUnit
    );

    const { data } = await getUserVisitsCMSCulture(
      params.periodBegin,
      params.periodEnd,
      params.timeUnit,
      params.customFilter
    );

    if (data) {
      const analyticData = data.result;
      const series = seriesGenerator(analyticData, isNumber);

      setCurrentChartCategories(series.categories);
      setChartAdoption(series);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfos();
    getHandleRetentionActivity();
  }, []);

  useEffect(() => {
    getRetentionData(selectedDate[0], selectedDate[1], 'day', isNumber);
  }, [isNumber, selectedDate]);

  let currViewFilterState = Object.assign({ All: [] }, userInfoState);
  let selectedViewLabel = changeItemLabelFormat(viewFilterState);

  const chart = useRef();

  return (
    <>
      <HeaderPage titlePage={title} />
      <div className="admin-culture ">
        <div
          className="retention-container col-xs-12 mt-[24px]"
          id="dashboard_content"
        >
          {(isItemComparedDropdownShown || isViewFilterDropdownShown) && (
            <div className="datepicker-overlay" onClick={handleCancel}></div>
          )}
          <div className="retention-container-wrapper-card">
            <CardWrapper text="Total User">
              <TotalUser qty={totalUsers} />
            </CardWrapper>
            <CardWrapper text="DAU">
              {dau && (
                <DauWauMau
                  NumberFirst={dau[1].percentage.allUsers.toFixed(0)}
                  FonAwesome={
                    parseInt(dau[1].percentage.allUsers.toFixed(0)) >
                    parseInt(dau[0].percentage.allUsers.toFixed(0))
                      ? 'plus'
                      : 'minus'
                  }
                  NumberSecond={dau[0].percentage.allUsers.toFixed(0)}
                  DateFirst={
                    dayjs(dau[0].timeframe.start).format('MMM DD') +
                    ' - ' +
                    dayjs(dau[0].timeframe.end).format('DD')
                  }
                  DateSecond={
                    dayjs(dau[1].timeframe.start).format('MMM DD') +
                    ' - ' +
                    dayjs(dau[1].timeframe.end).format('DD')
                  }
                />
              )}
            </CardWrapper>
            <CardWrapper text="WAU">
              {wau && (
                <DauWauMau
                  NumberFirst={wau[1].percentage.allUsers.toFixed(0)}
                  FonAwesome={
                    parseInt(wau[1].percentage.allUsers.toFixed(0)) >
                    parseInt(wau[0].percentage.allUsers.toFixed(0))
                      ? 'plus'
                      : 'minus'
                  }
                  NumberSecond={wau[0].percentage.allUsers.toFixed(0)}
                  DateFirst={
                    dayjs(wau[0].timeframe.start).format('MMM DD') +
                    ' - ' +
                    dayjs(wau[0].timeframe.end).format('MMM DD')
                  }
                  DateSecond={
                    dayjs(wau[1].timeframe.start).format('MMM DD') +
                    ' - ' +
                    dayjs(wau[1].timeframe.end).format('MMM DD')
                  }
                />
              )}
            </CardWrapper>
            <CardWrapper text="MAU">
              {mau && (
                <DauWauMau
                  NumberFirst={mau[1].percentage.allUsers.toFixed(0)}
                  FonAwesome={
                    parseInt(mau[1].percentage.allUsers.toFixed(0)) >
                    parseInt(mau[0].percentage.allUsers.toFixed(0))
                      ? 'plus'
                      : 'minus'
                  }
                  NumberSecond={mau[0].percentage.allUsers.toFixed(0)}
                  DateFirst={dayjs(mau[0].timeframe.start).format('MMM YYYY')}
                  DateSecond={dayjs(mau[1].timeframe.start).format('MMM YYYY')}
                />
              )}
            </CardWrapper>
          </div>

          <CardChartWrapper title="Retention">
            <div className="view-filter">
              <div className="view-label">View by</div>
              <div
                className="item-filter-option view-option "
                onClick={showViewFilterDropdown}
              >
                {selectedViewLabel}
              </div>

              {isViewFilterDropdownShown && (
                <div className="list-view-option-wrapper">
                  {Object.keys(currViewFilterState).map((item, idx) => {
                    let itemLabel = changeItemLabelFormat(item);
                    let activeClass = viewFilterState === item ? true : false;

                    return (
                      <div
                        key={idx}
                        className={classSetter('view-option-list', {
                          active: activeClass
                        })}
                        onClick={() => handleSelectView(item)}
                      >
                        {itemLabel}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="filter-compare-chart">
              {currentChartCategories.map((item, idx) => (
                <ItemCompared
                  key={idx}
                  text={startCase(item)}
                  addOnClick={() => removeFilter(idx, item.replace('_', ' '))}
                />
              ))}

              <div className="item-compared-dropdown">
                <div
                  className="item-compared-add item-compared-name"
                  onClick={(e) => handleShowItemComparedDrowdown('growth', e)}
                >
                  Add to Compare
                </div>

                {isItemComparedDropdownShown && (
                  <div className="item-dropdown-content">
                    {Object.keys(userInfoState).map((item, idx) => {
                      let itemInside = userInfoState[item].map(
                        (itemInside, idx) => (
                          <option key={idx} value={itemInside}>
                            {itemInside}
                          </option>
                        )
                      );
                      let itemLabel;
                      itemLabel = item.replace('_', ' ');
                      itemLabel =
                        itemLabel.charAt(0).toUpperCase() + itemLabel.slice(1);

                      return (
                        <div key={idx}>
                          <div className="item-filter">{itemLabel}</div>
                          <select id={item} className="item-filter-option">
                            {itemInside}
                          </select>
                        </div>
                      );
                    })}
                    <div
                      className="btn-confirm-filter"
                      onClick={confirmAddMetric}
                    >
                      <div className="item-compared-add item-compared-name">
                        Add Metric
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-between mb-[24px]">
              <div className="container-filter-period flex flex-col">
                <div className="btn-group mb-[24px]">
                  <div
                    className={
                      'btn btn-chartView ' +
                      (intervalButton.growth.day ? 'is-active' : '')
                    }
                    onClick={(e) => handleChartView('daily', e)}
                  >
                    Day
                  </div>
                  <div
                    className={
                      'btn btn-chartView ' +
                      (intervalButton.growth.week ? 'is-active' : '')
                    }
                    onClick={(e) => handleChartView('weekly', e)}
                  >
                    Week
                  </div>
                  <div
                    className={
                      'btn btn-chartView ' +
                      (intervalButton.growth.month ? 'is-active' : '')
                    }
                    onClick={(e) => handleChartView('monthly', e)}
                  >
                    Month
                  </div>
                </div>
                <div className="checkbox-wrapper flex gap-[16px]">
                  <Checkbox
                    customContainerClass="inline-block"
                    id="number"
                    onChange={handleSelectType}
                    checked={isNumber}
                    value="number"
                  >
                    <p className="ml-[4px]"> {getObjectiveLocale('Number')}</p>
                  </Checkbox>
                  <Checkbox
                    customContainerClass="inline-block"
                    id="percentage"
                    onChange={handleSelectType}
                    checked={!isNumber}
                    value="percentage"
                  >
                    <p className="ml-[4px]">
                      {' '}
                      {getObjectiveLocale('Percentage')}
                    </p>
                  </Checkbox>
                </div>
              </div>
              <InsightCalendar
                endDate={selectedDate[1]}
                startDate={selectedDate[0]}
                selectedDate={selectedDate}
                handleSelectDate={(range) => handleQueryGrowth(range)}
                listOptionsTime={listOptionsTime}
                id="retention"
              />
            </div>

            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <LineChart
                  data={chartAdoption}
                  isNumber={isNumber}
                  chartRef={chart}
                />
              </div>
            )}
          </CardChartWrapper>
          <InsightRetentionChartFeatureUsage
            localeUserActivity={localeUserActivity}
            listOptionsTime={listOptionsTime}
          />
        </div>
      </div>
    </>
  );
};

export default Retention;

import React from 'react';
import { useRouteMatch } from 'react-router';

import { getExcludeOwner, getSelectedOwner } from 'utils/ObjectivesHelper';
import { getOwners } from 'utils/ObjectivesHelper';

import Avatar from 'components/design-system/Avatar';
import InlineDialogSelectMultipleUser from 'components/inline-dialog/InlineDialogSelectMultipleUser';
import { getNewInvolvements } from 'components/sidebar/sidebar-create/SidebarCreateHelper';

const OwnerObjective = ({ objective, handleChangeObjective }) => {
  const selectedOwner = getSelectedOwner(objective) || [];
  const excludeOwner = getExcludeOwner(objective) || [];

  const { params } = useRouteMatch();

  const handleChangeDialog = async (involvements, type) => {
    const newObjective = { ...objective };
    const newInvolvements = getNewInvolvements(
      involvements,
      type,
      true,
      objective,
      params
    );
    newObjective.involvements = newInvolvements;
    handleChangeObjective(objective?.id, newObjective);
  };

  const beforeTrigger = (setOpen, e) => {
    !e.shiftKey && !(e.ctrlKey || e.metaKey) && setOpen();
  };

  return (
    <InlineDialogSelectMultipleUser
      customTriggerComponent={() => (
        <Trigger
          involvements={objective?.involvements}
          isProject={objective?.isProject}
        />
      )}
      selectedValue={selectedOwner}
      excludeValue={excludeOwner}
      permission={true}
      objectiveValue={objective}
      type="assignee"
      role="assignee"
      header="Assignee"
      handleChange={handleChangeDialog}
      position="left"
      dialogWidth="w-[315px]"
      triggerWrapperClass="w-full h-full"
      dialogWrapperClass="w-full h-full"
      horizontalBased
      beforeTrigger={beforeTrigger}
      useFloating
    />
  );
};

const Trigger = ({ involvements, isProject }) => {
  const { goalOwnersProfilePic, goalOwnersName } = getOwners(
    involvements,
    isProject
  );

  return <Avatar src={goalOwnersProfilePic} size={32} name={goalOwnersName} />;
};

export default OwnerObjective;

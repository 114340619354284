import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getTasksScoring } from 'client/ObjectivesClient';
import { useFormalReview } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Button from 'components/design-system/Button';
import Objectives from 'components/objectives/Objectives';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import Tooltips from 'components/shared/Tooltips/Tooltips';
import Modal from 'components/shared/modal/Modal';

function TasksScoring({
  state,
  target,
  assignmentId,
  setAnswer,
  isReviewResult = false,
  cycle
}) {
  let [{ tasksScoringData }, { getTasksScoringData }] = useFormalReview();
  const params = useParams();
  const {
    config: { defaultObjectiveSorting }
  } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [finalize, setFinalize] = useState(
    state === 'in_progress' ? false : true
  );
  const [modalEditPerformance, setModalEditPerformance] = useState(false);
  const [modalFinalize, setModalFinalize] = useState(false);
  const currentAssingmentId = assignmentId ? assignmentId : params.assignmentId;
  const tasks = getObjectiveLocale('Tasks');
  const { reviewedPeriodStartsAt, reviewedPeriodEndsAt } = cycle;

  const listScoring = tasksScoringData || {};

  const showFinalizeModal = () => {
    finalize ? setModalEditPerformance(true) : setModalFinalize(true);
  };

  const closeModal = () => {
    setModalEditPerformance(false);
    setModalFinalize(false);
  };

  const finalizeScore = async (finalize) => {
    const answer = {
      state: finalize ? 'in_progress' : 'done'
    };
    setAnswer('tasks_scoring', answer, 'suggested_ongoing');
    setModalEditPerformance(false);
    setModalFinalize(false);
  };

  useEffect(() => {
    getTasksScoringData(currentAssingmentId);
  }, []);

  useEffect(() => {
    setFinalize(state === 'in_progress' ? false : true);
  }, [state]);

  const { match } = useUrl();

  return (
    <React.Fragment>
      <div className="border border-solid border-n-400 rounded-[4px]">
        <div data-cy="goals-scoring-header">
          <div className="flex items-center justify-between py-[16px] px-[16px] border-b border-0 border-solid border-n-400 mb-[16px] mr-[16px]">
            <div>
              <p className="typography-h500 text-n-900 multiline-text-1">
                {getObjectiveLocale('Finalize [employee_name]’s task').replace(
                  /\[employee_name]/,
                  target?.name
                )}
              </p>
              <p className="typography-h100 text-n-800">
                {getObjectiveLocale(
                  'Lock these score by clicking the finalize button'
                )}
              </p>
            </div>
            {!isReviewResult && (
              <Button onClick={showFinalizeModal} datacy="finalize-scoring">
                {getObjectiveLocale(finalize ? 'Finalized' : 'Finalize Score')}
              </Button>
            )}
          </div>
          <div className="flex justify-between">
            {/* Score section */}
            <div className="px-[16px]">
              <div className="flex items-center mb-[8px]">
                <p>{getObjectiveLocale('Score')}</p>
                <Tooltips
                  text={listScoring.formula}
                  isEllipsis={true}
                  addClass="h-[16px] ml-[8px]"
                >
                  <SVGIcon
                    size="16"
                    fillColor="var(--n-600)"
                    iconName="icon-info"
                  />
                </Tooltips>
              </div>
              <div className="mb-[4px] flex items-end">
                <p className="typography-h600 text-base-600 mr-[8px]">
                  {listScoring.personal?.score
                    ? listScoring.personal?.score.toFixed(2)
                    : '0'}
                </p>
                {(
                  listScoring.personal?.score - listScoring.initial?.score
                ).toFixed(2) > 0 ? (
                  <span className="typography-h400 text-g-600">
                    +{' '}
                    {(
                      listScoring.personal?.score - listScoring.initial?.score
                    ).toFixed(2)}
                  </span>
                ) : listScoring.personal?.score - listScoring.initial?.score ===
                  0 ? (
                  ''
                ) : (
                  <span className="typography-h400 text-r-600">
                    {(
                      listScoring.personal?.score - listScoring.initial?.score
                    ).toFixed(2)}
                  </span>
                )}
              </div>
              <p className="typography-h100 text-n-800">
                Updated on {getDateLocale(listScoring.updatedAt)}
              </p>
            </div>
            {/* Total task, adn total mandays section */}
            <div className="flex mb-[24px]">
              <div className="w-[104px]">
                <p className="typography-h200 text-n-800">
                  {getObjectiveLocale('Total Task')}
                </p>
                <p className="typography-h600 text-n-900 mt-[8px]">
                  {listScoring?.personal?.objectiveCount}
                </p>
                <p className="typography-h100 text-n-800 mt-[4px]">
                  {getObjectiveLocale('Peer avg')}{' '}
                  <span className="typography-h200 text-n-900">
                    {listScoring?.average?.objectiveCount}
                  </span>
                </p>
              </div>
              <div className="ml-lg w-[104px]">
                <p className="typography-h200 text-n-800">
                  {getObjectiveLocale('Total Mandays')}
                </p>
                <p className="typography-h600 text-n-900 mt-[8px]">
                  {listScoring?.personal?.mandays}
                </p>
                <p className="typography-h100 text-n-800 mt-[4px]">
                  {getObjectiveLocale('Peer avg')}{' '}
                  <span className="typography-h200 text-n-900">
                    {listScoring?.average?.mandays}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <AdvancedFilter
            switchOptions={[
              {
                id: 'all',
                name: getObjectiveLocale('All'),
                filter: { state: ['completed', 'running', 'reviewed'] }
              },
              {
                id: 'unreviewed',
                name: getObjectiveLocale('Unreviewed'),
                filter: { state: ['completed', 'running'] }
              },
              {
                id: 'reviewed',
                name: getObjectiveLocale('Reviewed'),
                filter: { state: ['reviewed'] }
              }
            ]}
            filterOptions={['search', 'dateFilter', 'switch']}
            defaultFilter={{
              periodBegin: reviewedPeriodStartsAt,
              periodEndBefore: reviewedPeriodEndsAt
            }}
            customClass="px-[16px]"
            id="tasksScoringFilter"
          >
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <Objectives
                cardModel={'formal-review'}
                page={'tasksScoring'}
                queryFn={(params) =>
                  getTasksScoring(assignmentId, {
                    ...params,
                    sortColumn:
                      defaultObjectiveSorting?.sortColumn || 'due_date',
                    sortDirection:
                      defaultObjectiveSorting?.sortDirection || 'asc'
                  })
                }
                extraFilter={{
                  type: ['task']
                }}
                withMarginTop
                customClass="px-[16px]"
                headerCardList={['Name', 'Complexity', 'Rating']}
              />
            )}
          </AdvancedFilter>
        </div>
      </div>
      {modalFinalize && (
        <Modal
          title="Finalize Performance Score"
          withCloseIcon
          withPrimaryBtn={{
            title: 'Finalize Score',
            onClick: () => finalizeScore(finalize)
          }}
          withSecondaryBtn={{ title: 'Cancel', onClick: closeModal }}
          eventOnClickClose={closeModal}
        >
          <p className="text-n-800 typography-paragraph">
            {getObjectiveLocale(
              "Finalize performance score for this reviewee? Don't worry, you can come back and edit it again later."
            )}
          </p>
        </Modal>
      )}

      {modalEditPerformance && (
        <Modal
          title="Edit performance score"
          withCloseIcon
          withPrimaryBtn={{
            title: 'Edit Score',
            onClick: () => finalizeScore(finalize)
          }}
          withSecondaryBtn={{ title: 'Cancel', onClick: closeModal }}
          eventOnClickClose={closeModal}
        >
          <p className="text-n-800 typography-paragraph">
            {getObjectiveLocale(
              'Edit your finalized performance score for this reviewee?'
            )}
          </p>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default TasksScoring;

import React, { createContext, useContext } from 'react';
import { useInfiniteQuery } from 'react-query';

import flatten from 'lodash/flatten';

import { getTeamActivities } from 'client/ActivitiesClient';

// CREATE CONTEXT
const TeamActivitiesContext = createContext();

// PROVIDER
const TeamActivitiesProvider = (props) => {
  const teamId = props.teamId || null;

  const queryKey = ['team', 'activities', +teamId];

  const fetchActivities = async (olderThan) => {
    const params = {
      limit: 10,
      ...(olderThan && { olderThan })
    };
    return getTeamActivities(teamId, params);
  };

  const { hasNextPage, fetchNextPage, isFetching, data } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => fetchActivities(pageParam),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || undefined
    }
  );

  let lastContributor;
  const newActivities = flatten(
    data?.pages?.map((page) => {
      return page?.data?.map((d, index) => {
        index == 0 && (lastContributor = d);
        return d;
      });
    })
  );

  return (
    <TeamActivitiesContext.Provider
      value={{
        data: newActivities,
        lastContributor,
        hasNextPage,
        fetchNextPage,
        isFetching
      }}
      {...props}
    />
  );
};

// MUTATION

const useActivitiesTeam = () => {
  const { data, lastContributor, hasNextPage, fetchNextPage, isFetching } =
    useContext(TeamActivitiesContext);

  return {
    data,
    lastContributor,
    hasNextPage,
    fetchNextPage,
    isFetching
  };
};

export { TeamActivitiesProvider, useActivitiesTeam };

import React from 'react';

import GroupFeed from 'components/culture/GroupFeed';
import HomePageDetail from 'components/culture/HomePageDetail';
import SingleFeed from 'components/culture/SingleFeed';
import WrapperTimeline from 'components/culture/WrapperTimeline';

const HomePageTimeline = ({ route: { title } }) => {
  return (
    <WrapperTimeline title={title}>
      <HomePageDetail />
    </WrapperTimeline>
  );
};

const SingleFeedComponent = ({ route: { title } }) => {
  return (
    <WrapperTimeline title={title}>
      <SingleFeed />
    </WrapperTimeline>
  );
};

const GroupTimeline = ({ route: { title } }) => {
  return (
    <WrapperTimeline title={title}>
      <GroupFeed />
    </WrapperTimeline>
  );
};

export { HomePageTimeline, SingleFeedComponent, GroupTimeline };

import React, { useState } from 'react';

import dayjs from 'dayjs';

import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import {
  whichBgColorClassName,
  whichColor,
  whichStatus
} from 'utils/ObjectivesHelper';

import ModalUpdateProgress from 'components/modal/ModalUpdateProgress/ModalUpdateProgress';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import FormattedNumber from 'src/components/design-system/FormattedNumber';

const Progress = React.memo(({ task, clusters }) => {
  const { addToast } = useToastContext();
  const { invalidateTaskQueries } = useRefetchQuery();
  const {
    id,
    measurement,
    permissions,
    name,
    commentOptions,
    currentMilestone,
    milestoneType,
    startDate,
    progressUpdated
  } = task || {};

  const { progressPercentage, progressColorHex, expectedProgressPercentage } =
    measurement || [];

  const [showModal, setShowModal] = useState(false);

  const nowDate = new dayjs();

  const getEmptyProgress = () => {
    if (nowDate.isAfter(startDate)) {
      if (!progressUpdated) {
        return (
          <p className={`typography-h400 text-start text-n-600`}>
            {getObjectiveLocale('Not updated')}
          </p>
        );
      } else {
        return (
          <p
            className={`typography-h400 text-start ${whichColor[progressColorHex]}`}
          >
            {whichStatus[progressColorHex]}
          </p>
        );
      }
    } else {
      return (
        <p className={`typography-h400 text-start text-n-600`}>
          {getObjectiveLocale('Not started')}
        </p>
      );
    }
  };

  const tooltipProgressText = [
    getObjectiveLocale(`Expected: ${expectedProgressPercentage}%`),
    <br key="br-space" />,
    whichStatus[progressColorHex]
  ];

  if (progressColorHex == '44DB5E' || progressColorHex == '5A9AEF') {
    tooltipProgressText.shift();
    tooltipProgressText.shift();
  }

  const showUpdateProgress = (e) => {
    if (
      !e.shiftKey &&
      !(e.ctrlKey || e.metaKey) &&
      clusters?.includes('progress')
    ) {
      setShowModal(true);
    } else {
      addToast({
        title: getObjectiveLocale('Set the metrics'),
        msg: getObjectiveLocale('You need to set the task metrics first'),
        type: 'error'
      });
    }
  };

  return (
    <>
      <div className="w-full h-full flex items-center px-[16px]">
        {clusters?.includes('progress') ? (
          <div onClick={showUpdateProgress}>
            <TooltipContainer
              show
              text={tooltipProgressText}
              classContainer={`flex flex-row items-center justify-start w-full`}
            >
              {progressPercentage > 0 ? (
                <>
                  <LineProgressBar
                    progress={progressPercentage}
                    expectedProgress={expectedProgressPercentage}
                    width={72}
                    primaryColor={whichBgColorClassName(
                      progressColorHex,
                      false,
                      '500'
                    )}
                    secondaryColor={'var(--n-500)'}
                    fullFirst={true}
                    height={12}
                    dataCy="update-progress"
                  />
                  <FormattedNumber
                    number={progressPercentage}
                    minDecimalLength={2}
                    customClass={`typography-h400 ml-[4px] ${whichColor[progressColorHex]}`}
                  />
                  <span
                    className={`typography-h400 ${whichColor[progressColorHex]}`}
                  >
                    %
                  </span>
                </>
              ) : (
                getEmptyProgress()
              )}
            </TooltipContainer>
          </div>
        ) : (
          <p className="typography-h400 text-n-600 text-right">
            {getObjectiveLocale('Not Visible')}
          </p>
        )}
      </div>

      {showModal && (
        <ModalUpdateProgress
          measurement={measurement ? measurement : null}
          withCloseIcon
          eventOnClick={() => setShowModal(!showModal)}
          canUpdateCurrentValue={permissions?.includes('update_current_value')}
          objectiveId={id}
          modalType="update"
          objectiveName={name}
          commentOptions={commentOptions}
          currentMilestone={currentMilestone}
          milestoneType={milestoneType}
          objectiveValue={task}
          saveCallback={() => invalidateTaskQueries({ task })}
        />
      )}
    </>
  );
});

Progress.displayName = 'Progress';

export default Progress;

import React from 'react';

import produce, { setAutoFreeze } from 'immer';

// UI Auth Context
const AuthPageContext = React.createContext();

// Provider for UI Auth Page
function AuthPageProvider(props) {
  const initialState = {
    organization: '',
    email: '',
    password: '',
    confirmPassword: '',
    resetPasswordAllowed: true,
    trueEmail: '',
    birthdate: '',
    managerEmail: '',
    workUnit: '',
    workUnitList: []
  };

  const [state, setState] = React.useState(initialState);
  setAutoFreeze(false);
  const immerState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerState];

  return <AuthPageContext.Provider value={contextValue} {...props} />;
}

// MUTATION for UI Auth Page
function UseAuthPage() {
  const [
    {
      organization,
      email,
      password,
      confirmPassword,
      resetPasswordAllowed,
      trueEmail,
      birthdate,
      managerEmail,
      workUnit,
      workUnitList
    },
    immerSetState
  ] = React.useContext(AuthPageContext);

  // General Function
  const handleOnChange = (name, value) => {
    immerSetState((draft) => {
      draft[name] = value;
    });
  };

  const resetInputState = () => {
    immerSetState((draft) => {
      (draft.email = ''),
        (draft.password = ''),
        (draft.confirmPassword = ''),
        (draft.organization = ''),
        (draft.resetPasswordAllowed = true),
        (draft.trueEmail = ''),
        (draft.birthdate = ''),
        (draft.managerEmail = ''),
        (draft.workUnit = ''),
        (draft.workUnitList = []);
    });
  };

  return {
    organization,
    email,
    password,
    trueEmail,
    birthdate,
    managerEmail,
    workUnit,
    workUnitList,
    confirmPassword,
    resetPasswordAllowed,
    handleOnChange,
    resetInputState
  };
}

export { AuthPageProvider, UseAuthPage };

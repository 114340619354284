import React from 'react';

import Checkbox from 'components/shared/Checkbox';
import RangeSlider from 'components/slider/RangeSlider';

function QuotaList({
  id,
  index,
  name,
  inputValue,
  onChangeCheckBox,
  onChangeValue,
  statusCheckBox,
  total
}) {
  let valueScoring = ((inputValue[index] / total) * 100).toFixed(0);
  if (valueScoring === 'NaN') valueScoring = 0;
  if (valueScoring === 'Infinity') valueScoring = 0;

  return (
    <div
      className={`h-[56px] flex items-center px-[16px] ${
        statusCheckBox[index] ? 'bg-n-200' : ''
      }`}
    >
      <div className="w-[10%]">
        <Checkbox
          name={id}
          id={id}
          checked={statusCheckBox[index]}
          onChange={() => onChangeCheckBox()}
        />
      </div>
      <div className="w-[30%]">
        <span className="typography-paragraph">{name}</span>
      </div>
      <div className="w-[45%]">
        <div
          className={`w-4/5 ${statusCheckBox[index] ? 'visible' : 'invisible'}`}
        >
          <RangeSlider
            handleChange={(e) => onChangeValue(e, index)}
            value={inputValue[index]}
            min={0}
            max={total}
          />
        </div>
      </div>
      <div
        className={`w-[15%] ${statusCheckBox[index] ? 'visible' : 'invisible'}`}
      >
        <span className="typography-h400">{inputValue[index]}</span>
        <span className="typography-tertiary"> ({`${valueScoring} %`})</span>
      </div>
    </div>
  );
}

export default QuotaList;

import React, { createRef, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import dayjs from 'dayjs';
import flatten from 'lodash/flatten';

import { getTeamRoleHistory } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useTeamDetail } from 'context/team/TeamDetailContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/design-system/Avatar';
import ModalV2, { useModal } from 'components/design-system/Modal';
import Switcher from 'components/design-system/Switcher';
import Tabs from 'components/design-system/tabs/Tabs';
import ListEmptyState from 'components/shared/ListEmptyState';
import SVGIcon from 'components/shared/SVGIcon';

const RoleHistory = ({ setShowModal }) => {
  const [currentRoleTab, setCurrentRoleTab] = useState('leader');
  const roles = [
    { id: 'leader', name: 'Leader' },
    { id: 'reviewer', name: 'Reviewer' },
    { id: 'member', name: 'Members Team' },
    { id: 'stakeholder', name: 'Stakeholders' },
    { id: 'pmo', name: 'PMO' }
  ];

  return (
    <ModalV2 onClose={() => setShowModal(false)} dataCy="modal-roles-history">
      <Tabs currentTab={currentRoleTab} type="small">
        <Header roles={roles} setCurrentRoleTab={setCurrentRoleTab} />
        <Body currentRoleTab={currentRoleTab} roles={roles} />
        <Footer />
      </Tabs>
    </ModalV2>
  );
};

const Header = ({ roles, setCurrentRoleTab }) => {
  const { closeModal } = useModal();

  return (
    <ModalV2.Header>
      <div className="flex flex-col">
        <div className="flex px-[24px] pt-[24px] pb-[16px] justify-between">
          <div className="flex">
            <span className="typography-h600">
              {getObjectiveLocale('Role History')}
            </span>
          </div>
          <SVGIcon
            iconName="icon-clear"
            size={24}
            onClick={() => closeModal()}
          />
        </div>
        <Tabs.Buttons customClass="px-[24px] border-b-[0px]" fullWidth>
          {roles?.map((role, index) => (
            <Tabs.Button
              key={index}
              id={role?.id}
              dataCy={`role-${role?.id}`}
              onClick={() => setCurrentRoleTab(role?.id)}
            >
              {getObjectiveLocale(role?.name)}
            </Tabs.Button>
          ))}
        </Tabs.Buttons>
      </div>
    </ModalV2.Header>
  );
};

const Body = ({ currentRoleTab, roles }) => {
  const periods = [
    { id: 'current', name: 'Current' },
    { id: 'previous', name: 'Previous' }
  ];

  const [currentRoles, setCurrentRoles] = useState([]);
  const [previousRoles, setPreviousRoles] = useState([]);
  const [currentPeriodTab, setCurrentPeriodTab] = useState('current');
  const intersectTarget = createRef();
  const { refetchQueries } = useRefetchQuery();
  const { teamId } = useTeamDetail();

  const fetchRoleHistory = async (olderThan) => {
    const params = {
      limit: 10,
      role: currentRoleTab,
      olderThan: olderThan,
      sortColumn: 'id',
      sortDirection: 'desc'
    };
    return getTeamRoleHistory(teamId, params);
  };

  const queryKey = ['teams', 'roleHistory', currentRoleTab];

  const { fetchNextPage, hasNextPage } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => fetchRoleHistory(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination?.next?.olderThan || undefined;
      },
      onSuccess: (data) => {
        const newHistories = flatten(
          data?.pages?.map((page) => {
            return page?.data?.map((d) => {
              return d;
            });
          })
        );
        setCurrentRoles(
          newHistories?.filter((roleHistory) => roleHistory?.endDate == null)
        );
        setPreviousRoles(
          newHistories?.filter((roleHistory) => roleHistory?.endDate != null)
        );
      }
    }
  );

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting && hasNextPage && fetchNextPage(),
    threshold: 1
  });

  useEffect(() => {
    setCurrentPeriodTab('current');
    refetchQueries(queryKey);
  }, [currentRoleTab]);

  return (
    <ModalV2.Body>
      <Tabs.Contents customWrapperClass="flex flex-col py-[16px] w-full">
        <Switcher
          options={periods}
          selectedOption={currentPeriodTab}
          onChange={(tab) => setCurrentPeriodTab(tab)}
          customClass="px-[24px]"
          textCustomClass="typography-button"
        />
        {roles?.map((role, index) => {
          const roleHistoryData =
            currentPeriodTab === 'current' ? currentRoles : previousRoles;
          return (
            <Tabs.Content key={index} id={role?.id}>
              <div
                className={`w-full mt-[16px] overflow-auto min-h-[32px] px-[24px] flex-initial`}
              >
                {roleHistoryData?.length > 0 ? (
                  <>
                    <UserHistoryList
                      historyList={roleHistoryData}
                      hasNextPage={hasNextPage}
                      intersectTarget={intersectTarget}
                    />
                    {hasNextPage && (
                      <div className="h-[1px]" ref={intersectTarget}>
                        &nbsp;
                      </div>
                    )}
                  </>
                ) : (
                  <ListEmptyState
                    emptyIcon="icon-no-user"
                    title={getObjectiveLocale('There is no user for this role')}
                    subtitle={getObjectiveLocale(
                      'Any user who had been involved as this role in the current year will be shown in this page.'
                    )}
                    size="small"
                    titleClassname="max-w-[400px]"
                    subtitleClassname="max-w-[400px]"
                    fullHeight
                  />
                )}
              </div>
            </Tabs.Content>
          );
        })}
      </Tabs.Contents>
    </ModalV2.Body>
  );
};

const UserHistoryList = ({ historyList = [] }) => {
  return (
    <>
      {historyList?.map((history, index) => (
        <div
          className={`flex items-center justify-between w-full ${
            index > 0 ? 'pt-[8px]' : ''
          }`}
          key={index}
          data-cy={`history-${index}`}
        >
          <div className="flex items-center py-[4px]">
            <Avatar
              size="24"
              src={history?.user?.profilePicture}
              name={history?.user?.name}
            />
            <p className="typography-paragraph ml-[8px]" data-cy="history-name">
              {history?.user?.name}
            </p>
          </div>
          <p className="typography-h200 text-n-600" data-cy="history-date">
            {dayjs(history?.startDate).format('D MMM YYYY')} -{' '}
            {history?.endDate
              ? dayjs(history?.endDate).format('D MMM YYYY')
              : getObjectiveLocale('Now')}
          </p>
        </div>
      ))}
    </>
  );
};

const Footer = () => {
  const { closeModal } = useModal();

  return (
    <ModalV2.Footer
      secondaryButton={{
        onClick: () => closeModal(),
        text: getObjectiveLocale('Close')
      }}
    />
  );
};

export default RoleHistory;

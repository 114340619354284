import React, { useEffect } from 'react';
import { useState } from 'react';

import {
  postCustomAttribute,
  putCustomAttribute
} from 'client/admin/ReviewGroupClient.js';

import Modal from '../../../shared/modal/Modal';
import './ModalCreateEditCustomAttribute.scss';

const ModalCreateEditCustomAttribute = ({
  setIsModalCreateAttributeOpen,
  action,
  dataEdit = {},
  showSuccessToast,
  refetch
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const saveData = async () => {
    const { isSuccess } = await (action == 'create'
      ? postCustomAttribute(0, {
          review_aspects_attributes: [{ name, description }]
        })
      : putCustomAttribute(0, {
          review_aspects_attributes: [{ id: dataEdit.id, name, description }]
        }));
    if (isSuccess) {
      showSuccessToast();
      refetch();
    }
    setIsModalCreateAttributeOpen(false);
  };

  const modalProps = {
    className: 'w-[560px]',
    title: `${action == 'create' ? 'Create' : 'Edit'} Custom Attribute`,
    useFooter: 'true',
    withCloseIcon: false,
    eventOnClickClose: () => setIsModalCreateAttributeOpen(false),
    withPrimaryBtn: {
      title: 'Save',
      onClick: saveData,
      disabled: name == '' ? true : false,
      dataCy: 'save-attribute'
    },
    withSecondaryBtn: {
      title: 'Cancel',
      onClick: () => setIsModalCreateAttributeOpen(false),
      datacy: 'cancel'
    }
  };

  useEffect(() => {
    if (action == 'edit') {
      setName(dataEdit.name);
      setDescription(dataEdit.description);
    }
  }, []);

  return (
    <Modal {...modalProps}>
      <div className="name-description-container pt-[24px]">
        <div className="mb-[24px]">
          <label className="mb-[8px] typography-secondary typography-h100">
            Attribute Name
          </label>
          <input
            type="text"
            autoFocus
            className="input-field input-group-name"
            data-cy="input-group-name"
            onChange={(e) => setName(e.target.value)}
            value={name || ''}
          />
        </div>
        <div className="mb-[24px]">
          <label className="mb-[8px] typography-secondary typography-h100">
            Description
          </label>
          <textarea
            type="text"
            className="input-field input-group-desc"
            data-cy="input-group-desc"
            onChange={(e) => setDescription(e.target.value)}
            value={description || ''}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalCreateEditCustomAttribute;

import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router';

import isNull from 'lodash/isNull';

import { getListFilter } from 'client/FormalReviewClient';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import { usePreference } from 'src/hooks/usePreferenceFilter';
import { toCamelCase, toKebabCase } from 'src/utils/caseConverter';
import { useDeepEffect } from 'src/utils/useDeepEffect';

const CalibrationFilterSection = ({ currentCalibratorId }) => {
  const [calibratorId, setCalibratorId] = useState(null);

  const { config } = useUser();
  const pageParams = useParams();
  const filterBefore = useFilter((state) => state.filterById?.calibration);
  const filterPayload = useRef(null);

  const cycleId = parseInt(pageParams?.id);

  const {
    data: filterPreference,
    isLoaded,
    saveFilter
  } = usePreference({
    module: 'formal_review',
    page: 'calibration',
    section: 'list',
    cycleId: cycleId,
    calibratorId: currentCalibratorId
  });

  let filterMenu = [...config.cycleDetailFilterOptions] || [];

  let defaultFilterShow = [
    { id: 'direct_reports', name: getObjectiveLocale('My Direct Report') },
    { id: 'indirect_reports', name: getObjectiveLocale('My Indirect Report') },
    {
      id: 'indirect_descendants',
      name: getObjectiveLocale("My Indirect Report's Descendants")
    }
  ];

  const defaultFilter = {
    show: defaultFilterShow
  };

  const getRestructuredFilterShow = (filterShow) => {
    if (filterShow) {
      const restructuredFilterShow = defaultFilterShow.filter((show) => {
        return filterShow.includes(show.id);
      });

      return restructuredFilterShow;
    }
  };

  const getRestructuredFilter = (filterData) => {
    if (!filterData?.filters) return;
    const filterPreferenceIndex = filterData?.filters?.findIndex(
      (filter) => filter?.calibratorId == currentCalibratorId
    );
    if (filterPreferenceIndex === -1) return {};
    const filter = filterData?.filters[filterPreferenceIndex];
    const filterMenu = toKebabCase(filter);
    const restructuredFilterShow = getRestructuredFilterShow(filterMenu?.show);

    return {
      ...filterMenu,
      ...(restructuredFilterShow ? { show: restructuredFilterShow } : [])
    };
  };

  const dataFilterPreference = getRestructuredFilter(filterPreference?.data);

  const _getListFilter = (addedParams) => {
    let paramFilter;
    let dialogParams;

    if (addedParams?.key === 'show') {
      return;
    } else {
      paramFilter = Object.fromEntries(
        Object.entries(filterBefore)
          .filter(([key]) => key !== 'show')
          .filter(([key]) => key !== 'score')
          .filter(([key]) => key !== addedParams?.key)
      );

      let resultParamFilter = {
        ...paramFilter,
        managerIds: paramFilter?.manager?.map((e) => e?.id) || []
      };
      resultParamFilter?.manager?.length > 0 &&
        delete resultParamFilter.manager;

      dialogParams = { ...addedParams, ...resultParamFilter };
    }

    const fetchFilter = (pageParam) => {
      let params = {
        olderThan: pageParam?.olderThan || null,
        ...dialogParams
      };
      return getFilterData(params);
    };

    const {
      data,
      status,
      fetchNextPage,
      isFetchingNextPage
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useInfiniteQuery(
      ['filter', dialogParams],
      ({ pageParam }) => fetchFilter(pageParam),
      {
        getNextPageParam: (lastPage) => lastPage.pagination?.next || 0,
        enabled: isLoaded
      }
    );

    let pagination =
      data?.pages[data?.pages?.length - 1].pagination !== undefined;
    let hasMore = pagination
      ? data?.pages[data?.pages?.length - 1].pagination?.next?.olderThan !==
        null
      : false;

    return {
      data,
      status,
      isFetchingMore: isFetchingNextPage,
      fetchMore: fetchNextPage,
      hasMore: hasMore
    };
  };

  const getFilterData = async (addedParams) => {
    let params = {
      adminMode: true,
      q: addedParams?.q,
      key: addedParams?.key,
      olderThan: addedParams?.olderThan,
      calibratorId: currentCalibratorId,
      ...addedParams
    };
    return await getListFilter(cycleId, params);
  };

  const resultModifier = (filter) => {
    const showName = filter?.show?.map((e) => e?.name) || [];
    const managerName = filter?.manager?.map((e) => e?.name) || [];
    const result = {
      ...filter,
      show: showName,
      manager: managerName
    };
    return result;
  };

  const payloadFormatter = (data) => {
    return {
      module: 'formal_review',
      page: 'calibration',
      section: 'list',
      cycleId: cycleId,
      filters: [
        {
          ...(calibratorId ? { calibratorId: calibratorId } : []),
          ...(data?.directorate ? { directorate: data?.directorate } : []),
          ...(data?.department ? { department: data?.department } : []),
          ...(data?.division ? { division: data?.division } : []),
          ...(data?.jobTitle ? { jobTitle: data?.jobTitle } : []),
          ...(data?.jobRole ? { jobRole: data?.jobRole } : []),
          ...(data?.jobFunction ? { jobFunction: data?.jobFunction } : []),
          ...(data?.manager ? { manager: data?.manager } : []),
          ...(data?.show ? { show: data.show.map(({ id }) => id) } : []),
          ...(data?.score ? { score: data?.score } : []),
          ...(data?.status ? { status: data?.status } : []),
          ...(data?.userState ? { userState: data?.userState } : [])
        }
      ]
    };
  };

  useDeepEffect(() => {
    const payload = payloadFormatter(toCamelCase(filterBefore));
    filterPayload.current = payload;
  }, [filterBefore, calibratorId]);

  const saveFilterCallback = async (payload = filterPayload.current) => {
    await saveFilter(payload);
    setCalibratorId(currentCalibratorId);
  };

  const saveSessionFilter = () => {
    const sessionCache = JSON.parse(sessionStorage?.calibrationFilterCache);
    saveFilterCallback(sessionCache);
    sessionStorage.removeItem('calibrationFilterCache');
  };

  const setCalibratorId_ = () => {
    if (isNull(calibratorId)) {
      setCalibratorId(currentCalibratorId);
      return;
    }
    if (calibratorId === currentCalibratorId) return;
    if (sessionStorage?.calibrationFilterCache) return saveSessionFilter();
    return saveFilterCallback();
  };

  const handleBeforeUnload = () => {
    if (!sessionStorage?.calibrationFilterCache) {
      sessionStorage.calibrationFilterCache = JSON.stringify(
        filterPayload.current
      );
    }
    saveFilterCallback();
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload, {
      capture: true
    });
    return () => {
      saveFilterCallback();
      window.removeEventListener('beforeunload', handleBeforeUnload, {
        capture: true
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentCalibratorId) return;
    setCalibratorId_();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCalibratorId]);

  return (
    <div className="min-h-[40px] border-0 border-b border-solid border-n-400 p-[8px] pt-[0px]">
      <AdvancedFilter
        id="calibration"
        filterOptions={['menuFilter']}
        filterMenu={filterMenu}
        defaultFilter={{
          ...defaultFilter,
          ...dataFilterPreference
        }}
        filterClient={getFilterData}
        dialogQueryFn={(params) => _getListFilter(params)}
        resultModifier={resultModifier}
        defaultFilterToReset={defaultFilter}
      />
    </div>
  );
};

export default CalibrationFilterSection;

import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';

import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { useImmer } from 'use-immer';

import { createSingleObjective, editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import useObjectives from 'hooks/useObjectives';
import useTeam from 'hooks/useTeam';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getClusters } from 'utils/ObjectivesHelper';
import { useDeepEffect } from 'utils/useDeepEffect';

import Button from 'components/design-system/Button';
import DropdownComplexity from 'components/dropdown/DropdownComplexity';
import InlineDialogParentSuggestion from 'components/inline-dialog/InlineDialogParentSuggestion';
import InlineDialogSelectTag from 'components/inline-dialog/InlineDialogSelectTag';
import ModalUpdateProgress from 'components/modal/ModalUpdateProgress/ModalUpdateProgress';
import AddSubGoalCard from 'components/objectives/AddSubGoalCard';
import JiraCard from 'components/objectives/jira/JiraCard';
import ToastNotif from 'components/shared/ToastNotif';
import MetricSection from 'components/sidebar/MetricSection';
import DateSection from 'components/sidebar/sidebar-create/DateSection';
import GoalTypeSetting from 'components/sidebar/sidebar-create/GoalTypeSetting';
import Header from 'components/sidebar/sidebar-create/Header';
import InputObjectiveDescription from 'components/sidebar/sidebar-create/InputObjectiveDescription';
import InputObjectiveTitle from 'components/sidebar/sidebar-create/InputObjectiveTitle';
import MilestoneSection from 'components/sidebar/sidebar-create/MilestoneSection';
import OwnerReviewer from 'components/sidebar/sidebar-create/OwnerReviewer';
import SelectFollower from 'components/sidebar/sidebar-create/SelectFollower';
import SliderWeight from 'components/slider/SliderWeight';
import SidebarRight from 'src/components/design-system/SidebarRight/SidebarRight';
import useSidebar from 'src/hooks/useSidebar';

import AddDependency from './sidebar-create/AddDependency';
import AddPhase from './sidebar-create/AddPhase';
import AddPriority from './sidebar-create/AddPriority';
import ProjectInvolvements from './sidebar-create/ProjectInvolvements';

function SidebarCreate({
  singleObjectiveAction,
  handleChangeObjective,
  parents,
  listMetrics,
  objectiveCategories,
  singleObjectiveDetail,
  activeLevel,
  saveCallback,
  listPriorities,
  objectiveConfigs,
  changeValue,
  team
}) {
  let history = useHistory();
  const { config } = useUser();
  const queryClient = useQueryClient();
  const { addToast } = useToastContext();

  const topSidebar = useSidebar((state) => state?.topSidebar);

  const {
    refetchObjectives,
    refetchObjective,
    refetchQueries,
    refetchSubObjectives,
    invalidateTaskQueries
  } = useRefetchQuery();
  const { reload, reloadSidebar, reloadOverallProgress } = useReload();
  const { params } = useRouteMatch();
  const { url } = useUrl();
  const { isAddingApprovalSubGoal, setIsAddingApprovalSubGoal } = useTeam();
  const clusters = getClusters(singleObjectiveAction);

  const isCreateObjectivePage = url?.includes('create-objective');

  const {
    objectiveWeightType,
    taskManagementFeature,
    permissions: configPermission,
    objectiveWeightTypeOptions,
    enableTaskMultiParentAlignment,
    enableJiraIntegration
  } = config;

  let parentId = parents ? parents?.[0]?.id : null;
  let parentTeamId = parents ? parents?.[0]?.team?.id : null;
  const [objectiveValue, setObjectiveValue] = useImmer(singleObjectiveAction);
  const [showComment, setShowComment] = useState(false);
  const [milestoneDataList, setMilestoneDataList] = useState(
    objectiveValue?.milestones || []
  );
  const [isDeleteRecurrence, setDeleteRecurrence] = useState(false);
  const [toastNotif, setToastNotif] = useState({
    show: false,
    message: '',
    warning: true
  });
  const [isToggleOn, setIsToggleOn] = useState(
    parseFloat(objectiveValue.weight) > 0
  );
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [isDateDisabled, setIsDateDisabled] = useState(false);
  const permissions = singleObjectiveAction.permissions;

  const parentDiff = useMemo(() => {
    if (!parents || !objectiveValue.parentIds) return [];

    const previousParentIds = parents?.map((value) => value.id);
    const newParentIds = objectiveValue.parentIds;

    const combined = [...previousParentIds, ...newParentIds].sort();
    const diff = [];

    combined.forEach((value, index) => {
      if (index > 0 && combined[index - 1] == value) return;
      if (index < combined.length - 1 && value == combined[index + 1]) return;

      diff.push(value);
    });

    return diff;
  }, [parents, objectiveValue.parentIds]);

  const objectiveLabelsManage = permissions?.objectiveLabelsManage
    ? permissions?.objectiveLabelsManage
    : configPermission?.objectiveLabelsManage;
  const {
    approvalRequestCreate: approvalRequestCreate,
    approvalRequestUpdate: approvalRequestUpdate,
    taskInvolvementsFollowerAssign: taskAssignFollower,
    projectInvolvementsFollowerAssign: projectAssignFollower,
    goalInvolvementsFollowerAssign: assignFollower,
    goalInvolvementsReviewerAssign: assignReviewer = false,
    taskInvolvementsOwnerAssign: taskAssignOwner,
    taskInvolvementsReviewerAssign: taskAssignReviewer,
    projectInvolvementsOwnerAssign: projectAssignOwner,
    projectInvolvementsReviewerAssign: projectAssignReviewer,
    goalInvolvementsOwnerAssign: assignOwner,
    objectiveWeightManage
  } = config.permissions;

  let assignOwnerPermission, assignReviewerPermission, assignFollowerPermission;

  if (params?.type === 'edit') {
    assignOwnerPermission = permissions?.includes('assign_owner');
    assignReviewerPermission = permissions?.includes('assign_reviewer');
    assignFollowerPermission = permissions?.includes('assign_follower');
  } else if (params?.type?.includes('task')) {
    assignOwnerPermission = taskAssignOwner;
    assignReviewerPermission = taskAssignReviewer;
    assignFollowerPermission = taskAssignFollower;
  } else if (objectiveValue?.isProject) {
    assignOwnerPermission = projectAssignOwner;
    assignReviewerPermission = projectAssignReviewer;
    assignFollowerPermission = projectAssignFollower;
  } else {
    assignOwnerPermission = assignOwner;
    assignReviewerPermission = assignReviewer;
    assignFollowerPermission = assignFollower;
  }

  const isInApprovalState = ['draft', 'edited'].includes(
    singleObjectiveAction?.stateObject?.state
  );
  const parentInApprovalState = ['draft', 'edited'].includes(
    singleObjectiveAction?.parents?.stateObject?.state
  );

  let type = objectiveValue.type;

  const showSubGoalCard =
    params?.type !== 'edit' &&
    params?.type !== 'create' &&
    document.querySelectorAll(`[id^="add-sub-goal-for-${parents?.[0]?.id}"]`)
      .length > 0;

  let selectedOwner = objectiveValue.involvements?.filter(
    ({ role, visible }) => role == 'assignee' && visible
  );

  const clusterFollower =
    params?.type == 'edit'
      ? objectiveValue.involvements?.filter(
          ({ role, visible }) => role == 'follower' && !visible
        ).length > 0
      : false;

  let selectedReviewer = objectiveValue.involvements?.filter(
    ({ role, visible }) => role == 'assigner' && visible
  );

  let selectedFollower = objectiveValue.involvements?.filter(
    ({ role, visible }) => role == 'follower' && visible
  );

  let arrayAssignee = selectedOwner?.map(({ userId }) => userId);
  let arrayAssigner = selectedReviewer?.map(({ userId }) => userId);
  let assigneeId = {
    assignee_id: arrayAssignee,
    assigner_id: arrayAssigner
  };

  const canSeeAlignParent =
    params?.type === 'edit' ? has(objectiveValue, 'parentIds') : true;

  let setGlobalObjectiveState = useObjectives((state) => state.set);

  const closeSidebar = () => {
    let newUrl = url.replace(location.pathname);
    if (isAddingApprovalSubGoal) {
      refetchQueries(['teamGoals', 'approval', 'childrenStateCount']);
      setIsAddingApprovalSubGoal(false);
    }
    setTimeout(() => {
      history.replace({
        pathname: newUrl,
        search: location.search
      });
    }, 500);
  };

  const iterationRemoveNull = (obj) => {
    for (var key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      } else if (typeof obj[key] == 'object') {
        iterationRemoveNull(obj[key]);
      }
    }
  };

  const save = async (objectiveId, comment, metaMentions) => {
    if (!showComment) setLoadingButton(true);
    let newObjectiveValue = JSON.parse(JSON.stringify(objectiveValue));

    iterationRemoveNull(newObjectiveValue.measurement);
    let isSuccess;

    newObjectiveValue.involvements.map((involvement) => {
      delete involvement.user;
      delete involvement.visible;
      delete involvement.manager;
    });

    !newObjectiveValue.parentIds &&
      (newObjectiveValue.parentIds =
        newObjectiveValue?.parents?.length > 0
          ? newObjectiveValue?.parents?.map(({ id }) => id)
          : []);

    delete newObjectiveValue.permissions;
    delete newObjectiveValue.isShowAdvanced;
    delete newObjectiveValue.parents;
    delete newObjectiveValue.stateObject;
    delete newObjectiveValue.measurement?.unit;
    delete newObjectiveValue.measurement?.description;
    delete newObjectiveValue.currentMilestone;
    delete newObjectiveValue.childrenCount;

    if (newObjectiveValue.milestoneType == 'disabled') {
      delete newObjectiveValue.milestones;
    }

    if (newObjectiveValue?.milestoneType !== 'disabled') {
      newObjectiveValue.milestoneType =
        newObjectiveValue.milestoneType?.toLowerCase();
    }

    if (newObjectiveValue?.measurement?.unitId === 0) {
      newObjectiveValue.measurement.unitId = null;
    }

    if (parentTeamId && !newObjectiveValue.teamId) {
      newObjectiveValue.teamId = parentTeamId;
    }

    if (comment || metaMentions) {
      newObjectiveValue.comment = comment;
      newObjectiveValue.metaMentions = metaMentions;
      setShowComment(false);
    }

    if (newObjectiveValue?.blockedBy?.length > 0) {
      newObjectiveValue.blockedBy = newObjectiveValue.blockedBy.map(
        ({ id }) => id
      );
    }

    if (newObjectiveValue?.blockerBy?.length > 0) {
      newObjectiveValue.blockerBy = newObjectiveValue.blockerBy.map(
        ({ id }) => id
      );
    }

    if (newObjectiveValue?.isProject) {
      newObjectiveValue.grouped = true;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (newObjectiveValue?.hasOwnProperty('weight')) {
      newObjectiveValue.weight = isNull(newObjectiveValue.weight)
        ? 0
        : parseFloat(newObjectiveValue.weight);
    }

    if (params?.type === 'edit') {
      if (
        newObjectiveValue?.milestoneType &&
        newObjectiveValue?.milestoneType !== 'disabled'
      ) {
        let milestonePatch = JSON.parse(JSON.stringify(milestoneDataList));
        milestonePatch?.map((data) => {
          delete data?.progressState;
          delete data?.progressPercentage;
          delete data?.id;
          delete data?.progressColor;
          delete data?.progressColorHex;
          delete data?.isUpdateable;
        });
        newObjectiveValue = {
          ...newObjectiveValue,
          milestones: milestonePatch
        };
      }

      delete newObjectiveValue.type;
      delete newObjectiveValue.milestoneCount;
      delete newObjectiveValue.parentId;
      newObjectiveValue?.taskType && delete newObjectiveValue.taskType;
      newObjectiveValue?.phase && delete newObjectiveValue?.phase;
      newObjectiveValue?.priority && delete newObjectiveValue?.priority;
      newObjectiveValue?.integrations?.[0]?.key &&
        delete newObjectiveValue?.integrations;

      if (isDeleteRecurrence && singleObjectiveAction.recurrence) {
        let stateDelete = { state: 'deleted' };
        newObjectiveValue.recurrence = {
          ...singleObjectiveAction.recurrence,
          ...stateDelete
        };
      }

      let { data } = await editObjective(objectiveValue.id, newObjectiveValue);
      if (data) {
        isSuccess = data;
      }
    } else {
      const { data } = await createSingleObjective(newObjectiveValue);
      if (data) {
        isSuccess = data;
      }
    }

    if (isSuccess) {
      let reloadObjectivesId =
        params?.type === 'edit' ? objectiveValue.id : parentId;
      history.replace({
        pathname: url.includes('progress-overtime')
          ? `${url}/details`
          : `${url}/${reloadObjectivesId}`,
        search: location.search
      });
      refetchQueries(['companyGoals+company-goal']);
      queryClient.invalidateQueries(['objective', parseInt(objectiveValue.id)]);
      queryClient.invalidateQueries([
        'objective',
        parseInt(objectiveValue.id),
        'activities'
      ]);

      if (type === 'task') {
        invalidateTaskQueries({ task: objectiveValue });
      }

      if (newObjectiveValue.blockedBy?.length > 0) {
        newObjectiveValue.blockedBy.map((id) => {
          refetchObjective(id);
        });
      }

      if (newObjectiveValue.blockerBy?.length > 0) {
        newObjectiveValue.blockerBy.map((id) => {
          refetchObjective(id);
        });
      }

      if (parentDiff.length > 0) {
        if (!isInApprovalState) {
          parentDiff.forEach((value) => {
            refetchObjective(value);
            refetchSubObjectives(value);
          });
        } else {
          setTimeout(() => {
            setGlobalObjectiveState((state) => {
              state.showChildrenId = newObjectiveValue?.parentIds?.[0];
            });
          }, 2000);
        }

        singleObjectiveAction?.stateObject?.state === 'edited' &&
          refetchObjectives();
      } else {
        let usingApprovalEditCreate = false;
        if (params?.type === 'edit') {
          usingApprovalEditCreate = approvalRequestUpdate;
        } else if (params?.type === 'addgoal') {
          usingApprovalEditCreate = approvalRequestCreate;
          setGlobalObjectiveState((state) => {
            state.showChildrenId = parentId;
          });
        }

        if (
          usingApprovalEditCreate &&
          !isInApprovalState &&
          !parentInApprovalState
        ) {
          refetchObjectives();
        } else {
          refetchQueries(['objective', parseInt(params?.objectiveId)]);
        }
      }

      url.includes('/projects') &&
        (params?.parentId === params?.objectiveId
          ? refetchQueries(['project', params?.objectiveId])
          : refetchObjectives());

      reload({
        reloadOverallProgress: !reloadOverallProgress,
        reloadSidebar: reloadSidebar,
        reloadType: params?.type,
        ...reloadObjectivesList(reloadObjectivesId)
      });

      if (params?.type != 'edit' && parentId) {
        refetchQueries(['sub-objectives', parentId]);
      }

      if (isAddingApprovalSubGoal) {
        refetchQueries(['teamGoals', 'approval']);
        refetchQueries(['overallProgress']);
        setIsAddingApprovalSubGoal(false);
      }

      if (
        !singleObjectiveDetail?.integrations?.[0]?.importChildIssues &&
        newObjectiveValue?.integrations?.[0]?.importChildIssues
      ) {
        addToast({
          title: getObjectiveLocale('Import child issues is started'),
          msg: getObjectiveLocale(
            'We are currently importing your Jira issues. Please wait a few minutes.'
          ),
          type: 'info'
        });
      }
      saveCallback && saveCallback();
    }
    setLoadingButton(false);
  };

  const reloadObjectivesList = (id) => {
    const isParentChanged = parentDiff.length > 0;

    if (isParentChanged) {
      return {
        ...(!isInApprovalState
          ? { reloadApprovalPanel: ['edited'] }
          : {
              reloadApprovalPanel: [singleObjectiveAction?.stateObject?.state]
            })
      };
    } else {
      return {
        reloadObjectiveId: { id },
        ...(['edit', 'addgoal'].includes(params?.type) && {
          reloadApprovalPanel: parentInApprovalState ? [] : ['edited']
        })
      };
    }
  };

  const onSubmit = () => {
    let { isProject, involvements } = objectiveValue;
    const checkProjectInvolvement =
      isProject &&
      (!involvements.some(
        (involvement) =>
          involvement?.role === 'assignee' &&
          involvement?.extendedRole === 'leader'
      ) ||
        !involvements.some((involvement) => involvement?.role === 'assigner'));
    const showNotif = () => {
      setToastNotif({
        show: true,
        message: 'Must have 1 leader and 1 reviewer',
        warning: true
      });
      setTimeout(function () {
        setToastNotif({ show: false, message: '', warning: true });
      }, 3000);
    };

    const proceedSubmit = () => {
      const hasPermission = permissions?.includes('update_comment');
      const enableOptional = config?.enableOptionalCommentEdit;
      if (params?.type == 'edit' && (hasPermission || enableOptional))
        return setShowComment(true);
      return save();
    };

    if (checkProjectInvolvement) {
      showNotif();
    } else {
      proceedSubmit();
    }
  };

  const goalType =
    objectiveValue?.teamId || objectiveValue?.team?.id ? 'team_goal' : type;
  const isAllowedObjectiveCategory =
    objectiveWeightTypeOptions?.includes(goalType);

  const hideCategory =
    params?.type != 'edit' &&
    !showSubGoalCard &&
    config?.objectiveWeightType === 'type' &&
    ((config?.objectiveCreationGroupBy &&
      config?.objectiveCreationGroupBy != 'none') ||
      false);

  useEffect(() => {
    if (handleChangeObjective) {
      handleChangeObjective(objectiveValue.id, objectiveValue);
    }
  }, [objectiveValue]);

  useDeepEffect(() => {
    if (singleObjectiveAction) {
      parents && (singleObjectiveAction.parents = parents);
      setObjectiveValue(() => singleObjectiveAction);
    }
  }, [singleObjectiveAction, params?.type]);

  useEffect(() => {
    if (
      params?.type &&
      !(
        params?.type === 'goal' ||
        params?.type === 'task' ||
        params?.type === 'team'
      )
    ) {
      const elementTitle = document.getElementById('title');
      elementTitle.focus();
      elementTitle.setSelectionRange(
        elementTitle.value.length,
        elementTitle.value.length
      );
    }
  }, []);

  return (
    <div className="sidebar-create-goals">
      <div
        className="sidebar_container"
        data-cy="sidebar-create"
        style={
          isCreateObjectivePage
            ? {
                height: `calc(100vh - ${topSidebar || 0}px)`
              }
            : {}
        }
      >
        <SidebarRight
          fixed={false}
          customClass="!fixed right-0"
          fullWidth={false}
          disabled={
            !objectiveValue.name &&
            (params?.type === 'task' ||
              params?.type === 'goal' ||
              params?.type === 'team')
          }
        >
          <SidebarRight.Header>
            <Header
              setObjectiveValue={setObjectiveValue}
              objectiveValue={objectiveValue}
              singleObjectiveAction={singleObjectiveAction}
              listMetrics={listMetrics}
            />
          </SidebarRight.Header>
          <SidebarRight.Body
            customClass="sidebar_content px-[0px] overflow-x-hidden"
            withSpace={false}
          >
            {url.includes('projects') &&
            params?.parentId === params?.objectiveId ? (
              <div className="mt-[16px]" />
            ) : objectiveValue?.isProject ? (
              <div className="px-[24px]">
                <ProjectInvolvements
                  objectiveValue={objectiveValue}
                  setObjectiveValue={setObjectiveValue}
                  permissions={permissions}
                  isEdit={params?.type == 'edit'}
                />
              </div>
            ) : (
              <OwnerReviewer
                team={team}
                objectiveValue={objectiveValue}
                selectedOwner={selectedOwner}
                selectedReviewer={selectedReviewer}
                selectedFollower={selectedFollower}
                setObjectiveValue={setObjectiveValue}
                assignReviewer={assignReviewerPermission}
                assignOwner={assignOwnerPermission}
                setIsDateDisabled={setIsDateDisabled}
              />
            )}
            <InputObjectiveTitle
              objectiveValue={objectiveValue}
              setObjectiveValue={setObjectiveValue}
              activeLevel={activeLevel}
              disabled={!permissions?.includes('edit_name')}
            />
            {enableJiraIntegration &&
              (goalType == 'goal' || goalType == 'team_goal') &&
              !objectiveValue?.isProject && (
                <div className="my-[16px]">
                  <JiraCard
                    objectiveDetail={objectiveValue}
                    setObjectiveValue={setObjectiveValue}
                    type="create"
                    changeValue={changeValue}
                  />
                </div>
              )}
            {has(objectiveValue, 'description') && (
              <InputObjectiveDescription
                objectiveValue={objectiveValue}
                setObjectiveValue={setObjectiveValue}
                disabled={!permissions?.includes('edit_description')}
              />
            )}
            {clusters.includes('startDate') && (
              <DateSection
                singleObjectiveAction={singleObjectiveAction}
                objectiveValue={objectiveValue}
                setObjectiveValue={setObjectiveValue}
                parents={parents}
                isDeleteRecurrence={isDeleteRecurrence}
                setDeleteRecurrence={setDeleteRecurrence}
                isDisabled={isDateDisabled}
              />
            )}
            {((!isEmpty(objectiveValue?.measurement) &&
              (has(objectiveValue?.measurement, 'unitId') ||
                has(objectiveValue?.measurement, 'id'))) ||
              objectiveValue?.measurement === null) && (
              <MetricSection
                objectiveValue={objectiveValue}
                setObjectiveValue={setObjectiveValue}
                listMetrics={listMetrics}
                type={type}
                permissions={permissions}
              />
            )}
            {type === 'task' && taskManagementFeature && (
              <AddPriority
                listPriorities={listPriorities}
                setObjectiveValue={setObjectiveValue}
                defaultValue={listPriorities?.find(
                  (priority) => priority.id === objectiveValue.priorityId
                )}
              />
            )}
            {type === 'task' && taskManagementFeature && (
              <AddPhase
                objectiveValue={objectiveValue}
                setObjectiveValue={setObjectiveValue}
              />
            )}
            {objectiveValue?.milestoneType &&
              config?.objectiveMilestonesFeature &&
              type !== 'task' && (
                <MilestoneSection
                  listMetrics={listMetrics}
                  objectiveValue={objectiveValue}
                  setObjectiveValue={setObjectiveValue}
                  changedMilestones={
                    params?.type === 'edit'
                      ? milestoneDataList
                      : objectiveValue?.milestones
                  }
                  isEdit={params?.type == 'edit'}
                  setMilestoneDataList={setMilestoneDataList}
                  disabled={!permissions?.includes('edit_milestones')}
                />
              )}
            {type !== 'task' && (
              <>
                {objectiveWeightType == 'type' &&
                  isAllowedObjectiveCategory &&
                  !hideCategory && (
                    <GoalTypeSetting
                      isAddGoal={params?.type === 'addgoal'}
                      objectiveConfigs={objectiveConfigs}
                      objectiveValue={objectiveValue}
                      setObjectiveValue={setObjectiveValue}
                      objectiveCategories={objectiveCategories}
                      disabled={
                        !permissions?.includes('edit_objective_category') ||
                        objectiveConfigs?.approvalRequired
                      }
                    />
                  )}
                {has(objectiveValue, 'weight') && objectiveWeightManage && (
                  <SliderWeight
                    objectiveValue={objectiveValue}
                    setObjectiveValue={setObjectiveValue}
                    objectiveCategories={objectiveCategories}
                    parentId={parentId}
                    disabled={!permissions?.includes('edit_weight')}
                    setIsToggleOn={setIsToggleOn}
                    isToggleOn={isToggleOn}
                  />
                )}
              </>
            )}
            {type === 'task' && objectiveValue.complexity > 0 && (
              <DropdownComplexity
                objectiveValue={objectiveValue}
                setObjectiveValue={setObjectiveValue}
              />
            )}
            {!objectiveValue.fakeParentId && canSeeAlignParent && (
              <InlineDialogParentSuggestion
                objectiveValue={objectiveValue}
                setObjectiveValue={setObjectiveValue}
                assigneeId={assigneeId}
                permissions={permissions}
                dialogWrapperClass="!flex"
                useTaskParentSuggestion={objectiveValue?.type === 'task'}
                useMultipleParentAlignment={
                  objectiveValue?.type == 'task'
                    ? enableTaskMultiParentAlignment
                    : true
                }
              />
            )}
            {type === 'task' && taskManagementFeature && (
              <AddDependency
                objectiveValue={objectiveValue}
                setObjectiveValue={setObjectiveValue}
                defaultValue={objectiveValue.blockedBy}
              />
            )}
            {objectiveValue.tags && objectiveLabelsManage && (
              <InlineDialogSelectTag
                setObjectiveValue={setObjectiveValue}
                selectedValue={objectiveValue.tags}
                disabled={!permissions?.includes('edit_tags')}
              />
            )}
            {!clusterFollower && !objectiveValue?.isProject && (
              <SelectFollower
                objectiveValue={objectiveValue}
                setObjectiveValue={setObjectiveValue}
                selectedFollower={selectedFollower}
                permissions={permissions}
                assignFollower={assignFollowerPermission}
                selectedReviewer={selectedReviewer}
                selectedOwner={selectedOwner}
              />
            )}
            {showSubGoalCard && (
              <AddSubGoalCard
                objectiveValue={objectiveValue}
                setObjectiveValue={setObjectiveValue}
                selectedOwner={selectedOwner}
                closeSidebar={closeSidebar}
                id={parentId}
              />
            )}
          </SidebarRight.Body>
          {!(
            params?.type === 'goal' ||
            params?.type === 'task' ||
            params?.type === 'team'
          ) && (
            <SidebarRight.Footer customClass="flex justify-end">
              <Button.Secondary
                onClick={() => closeSidebar()}
                customClass="mr-[8px]"
              >
                {getObjectiveLocale('Cancel')}
              </Button.Secondary>
              <Button
                disabled={
                  objectiveValue?.name?.length === 0 ||
                  (!objectiveValue.weight && isToggleOn)
                }
                onClick={() => onSubmit()}
                datacy="sidebar-create-save"
                isLoading={isLoadingButton}
              >
                {getObjectiveLocale('Save')}
              </Button>
            </SidebarRight.Footer>
          )}
        </SidebarRight>
      </div>
      {showComment && (
        <ModalUpdateProgress
          measurement={singleObjectiveDetail?.measurement}
          withCloseIcon
          eventOnClick={() => setShowComment(false)}
          canUpdateCurrentValue={false}
          objectiveId={singleObjectiveDetail?.id}
          objectiveName={singleObjectiveDetail?.name}
          modalType="edit"
          onSubmit={save}
          titleModal="Comment Update"
          objectiveValue={objectiveValue}
          objectiveConfigs={objectiveConfigs}
          shouldReloadCurrentObjective={false}
        />
      )}
      <ToastNotif
        showNotif={toastNotif?.show}
        message={toastNotif?.message}
        warning={toastNotif?.warning}
        usePortal
      />
    </div>
  );
}

export default SidebarCreate;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Trigger from 'components/design-system/dropdown/Trigger';
import NumberInput from 'components/shared/NumberInput';

import DropdownRepetition from '../../dropdown/DropdownRepetition';
import DropdownRepeat from './DropdownRepeat';

const RepetitionContent = ({
  recurrence,
  setRecurrence,
  isGoals,
  startDate,
  dueDate
}) => {
  const [every, setEvery] = useState(recurrence?.every || 1);
  const [type, setType] = useState(
    every == 1 ? recurrence?.type || 'none' : 'Custom'
  );
  const [everyType, setEveryType] = useState(recurrence?.type || 'day');
  const [tempDueDate, setTempDueDate] = useState(
    dayjs(recurrence?.nextDate)
      .add(dayjs(dueDate).diff(dayjs(startDate)))
      .toISOString() || ''
  );

  const handleDropdownChange = (value) => {
    const tmpRecurrence = { ...recurrence };
    if (
      value != getObjectiveLocale('Does Not Repeat') &&
      value != getObjectiveLocale('Custom')
    ) {
      let recurrencesDate = tmpRecurrence?.nextDate;
      let type = recurrence?.type;
      switch (value) {
        case getObjectiveLocale('Daily'):
          recurrencesDate = dayjs(startDate).add(1, 'days').toISOString();
          setTempDueDate(dayjs(dueDate).add(1, 'days').toISOString());
          type = 'day';
          break;
        case getObjectiveLocale('Weekly'):
          recurrencesDate = dayjs(startDate).add(1, 'weeks').toISOString();
          setTempDueDate(dayjs(dueDate).add(1, 'weeks').toISOString());
          type = 'week';
          break;
        case getObjectiveLocale('Monthly'):
          recurrencesDate = dayjs(startDate).add(1, 'months').toISOString();
          setTempDueDate(dayjs(dueDate).add(1, 'months').toISOString());
          type = 'month';
          break;
        case getObjectiveLocale('Yearly'):
          recurrencesDate = dayjs(startDate).add(1, 'years').toISOString();
          setTempDueDate(dayjs(dueDate).add(1, 'years').toISOString());
          type = 'year';
          break;
        default:
          break;
      }
      tmpRecurrence.nextDate = recurrencesDate;
      tmpRecurrence.type = type;
      tmpRecurrence.state = 'active';
      tmpRecurrence.every = 1;
      setEvery(1);
    }
    if (value == getObjectiveLocale('Does Not Repeat')) {
      tmpRecurrence.state = 'deleted';
    }
    if (value == getObjectiveLocale('Custom')) {
      tmpRecurrence.state = 'active';
    }
    setRecurrence(tmpRecurrence);
    setType(value);
  };

  const RepetitionTrigger = ({ value }) => (
    <Trigger
      icon={false}
      topLabel={false}
      label={false}
      text={value}
      customWrapperClass="w-[177px]"
    />
  );

  useEffect(() => {
    if (type == 'Custom' && every) {
      const tmpRecurrence = { ...recurrence };
      let recurrencesDate = tmpRecurrence?.nextDate;
      let servedType = recurrence?.type || 'day';
      let typeData = null;
      switch (everyType) {
        case getObjectiveLocale('Day'):
          servedType = 'day';
          typeData = 'days';
          break;
        case getObjectiveLocale('Week'):
          servedType = 'week';
          typeData = 'weeks';
          break;
        case getObjectiveLocale('Month'):
          servedType = 'month';
          typeData = 'months';
          break;
        case getObjectiveLocale('Year'):
          servedType = 'year';
          typeData = 'years';
          break;
        default:
          break;
      }
      recurrencesDate = dayjs(startDate)
        .add(parseInt(every), typeData)
        .toISOString();
      setTempDueDate(
        dayjs(dueDate).add(parseInt(every), typeData).toISOString()
      );
      tmpRecurrence.type = servedType;
      tmpRecurrence.nextDate = recurrencesDate;
      tmpRecurrence.state = 'active';
      tmpRecurrence.every = parseInt(every);
      setRecurrence(tmpRecurrence);
    }
  }, [every, everyType]);

  useEffect(() => {}, [recurrence]);

  return (
    <div className="flex flex-col">
      <DropdownRepetition
        handleChange={handleDropdownChange}
        defaultValue={
          recurrence && recurrence?.state != 'deleted'
            ? type != 'Custom'
              ? recurrence?.type
              : 'custom'
            : 'never'
        }
        isTask={!isGoals}
        customTrigger={RepetitionTrigger}
      />
      {type == 'Custom' && (
        <div className="mt-[16px]">
          <p className="text-n-800 typography-h100">Every</p>
          <div className="flex flex-row h-[32px] mt-[8px]">
            <div className="w-[64px] mr-[8px]">
              <NumberInput max={30} value={every} onChange={setEvery} />
            </div>
            <DropdownRepeat
              defaultValue={recurrence?.type?.toLowerCase() || 'day'}
              handleChange={setEveryType}
            />
          </div>
        </div>
      )}
      {recurrence && recurrence?.state != 'deleted' && (
        <p className="typography-h100 text-n-800 mt-[16px]">
          {getObjectiveLocale('Next occurrence will start on')}{' '}
          {getDateLocale(recurrence?.nextDate)}, end on{' '}
          {getDateLocale(tempDueDate)}
        </p>
      )}
    </div>
  );
};

export default RepetitionContent;

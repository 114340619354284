import React, { useEffect, useState } from 'react';

import { format } from 'timeago.js';

import {
  getProjectCountPhases,
  getProjectStatistics
} from 'client/ObjectivesClient';
import { useProjectDetail } from 'context/ProjectDetailContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getNumberLocale } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import SingleUser from 'components/design-system/single-user/SingleUser';
import Table from 'components/design-system/table/Table';
import Badge from 'components/shared/Badge';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';

const LIMIT = 10;

const Statistics = () => {
  const { projectId } = useProjectDetail();

  const [overallPhases, setOverallPhases] = useState([]);
  const [overallMembers, setOverallMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [olderThan, setOlderThan] = useState(null);
  const [newerThan, setNewerThan] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);

  const _getProjectStatistics = async (paginateParam) => {
    let params = { limit: LIMIT };
    if (paginateParam == 'next') {
      params.olderThan = olderThan;
      setCurrentPage(currentPage + 1);
    }
    if (paginateParam == 'prev') {
      params.newerThan = newerThan;
      setCurrentPage(currentPage - 1);
    }
    const { data, pagination } = await getProjectStatistics(projectId, params);
    if (data) {
      setOverallMembers(data);
      setOlderThan(pagination?.next?.olderThan);
      setNewerThan(pagination?.prev?.newerThan);
      setHasMore(data?.length === LIMIT);
    }
  };

  const _getProjectCountPhases = async () => {
    const { data } = await getProjectCountPhases(projectId);
    data?.length > 0 && setOverallPhases(data);
  };

  const getNextPrevStatistics = async (paginateParam) => {
    setIsLoadMoreLoading(true);
    await _getProjectStatistics(paginateParam);
    setIsLoadMoreLoading(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await _getProjectStatistics();
      await _getProjectCountPhases();
      setIsLoading(false);
    })();
  }, []);

  const headers = [
    { name: 'Project Member', widthClass: 'min-w-[248px]', grow: true },
    { name: 'Phase Status', widthClass: 'w-[200px]' },
    { name: 'Last Updated', widthClass: 'w-[192px]' }
  ];

  return (
    <div className="py-[16px] px-[16px] mt-[16px] mx-[40px] rounded-[4px] bg-n-000 shadow-raised">
      <p className="typography-h400 text-n-900">
        {getObjectiveLocale('Overall Phase Status in this Project')}
      </p>

      {isLoading ? (
        <div className="flex items-center h-[40px] mt-[8px]">
          <Shimmer
            width="100%"
            height="16px"
            customClass="mr-[24px] rounded-[4px]"
          />
          <Shimmer
            width="100%"
            height="16px"
            customClass="mr-[24px] rounded-[4px]"
          />
          <Shimmer width="100%" height="16px" customClass="rounded-[4px]" />
        </div>
      ) : (
        <>
          <div className="flex items-center w-full mb-[24px] mt-[16px]">
            {overallPhases?.map(({ phase, count, percentage }, index) => (
              <PhaseStatus
                key={index}
                text={phase?.name}
                count={count || 0}
                percentage={`${getNumberLocale(percentage || 0)}%`}
                customClass={index != 0 ? 'ml-[16px]' : ''}
                colorHex={phase?.colorHex}
                bgColorHex={phase?.bgColorHex}
              />
            ))}
          </div>

          <Table headers={headers}>
            {overallMembers.map(
              ({ member, lastUpdated, countPhases }, index) => (
                <Table.Row
                  key={index}
                  useRowHover={false}
                  customClass={index % 2 > 0 ? 'bg-n-200' : ''}
                >
                  <Table.Column>
                    <SingleUser userData={{ ...member }} />
                  </Table.Column>
                  <Table.Column>
                    {countPhases.map(({ phase, count }, index) => (
                      <Badge
                        key={index}
                        colorHex={phase.colorHex}
                        bgColorHex={phase.bgColorHex}
                        className={index != 0 ? 'ml-[8px]' : ''}
                      >
                        <p className="typography-h200 text-n-800">{count}</p>
                      </Badge>
                    ))}
                  </Table.Column>
                  <Table.Column>
                    <p className="typography-paragraph text-n-900">
                      {format(lastUpdated)}
                    </p>
                  </Table.Column>
                </Table.Row>
              )
            )}
          </Table>
          <div className="flex justify-end mt-[18px]">
            <PaginationModule
              loading={isLoadMoreLoading}
              pageNumber={currentPage}
              handleNext={() => getNextPrevStatistics('next')}
              handlePrev={() => getNextPrevStatistics('prev')}
              hasMore={hasMore}
            />
          </div>
        </>
      )}
    </div>
  );
};

const PhaseStatus = ({
  text,
  count,
  percentage,
  customClass,
  colorHex,
  bgColorHex
}) => {
  return (
    <div
      className={`flex items-center bg-n-200 rounded-[4px] border border-solid border-n-300 py-[8px] px-[8px] w-full ${
        customClass ? customClass : ''
      }`}
    >
      <Badge colorHex={colorHex} bgColorHex={bgColorHex}>
        <p className="typography-h400 text-n-800">{count}</p>
      </Badge>
      <div className="flex items-center">
        <p className="typography-h200 text-n-900 ml-[8px]">{text}</p>
        &nbsp;
        <span className="w-[2px] h-[2px] inline-block bg-n-600 rounded-full" />
        &nbsp;
        <p className="typography-h100 text-n-600">{percentage}</p>
      </div>
    </div>
  );
};

export default Statistics;

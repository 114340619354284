import React, { createRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import { format } from 'timeago.js';

import { getListObjectives } from 'client/ObjectivesClient';
import useFilter from 'hooks/useFilter';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { getObjectiveLocale } from 'utils/HelperUtils';
import {
  restructureFilter,
  whichBadgeBgColorHex,
  whichBadgeColorHex,
  whichBgColorClassName,
  whichColor,
  whichStatus
} from 'utils/ObjectivesHelper';

import Table from 'components/design-system/table/Table';
import Badge from 'components/shared/Badge';
import ListEmptyState from 'components/shared/ListEmptyState';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';

const TribeChildDetail = ({ teamId }) => {
  const history = useHistory();
  const filter = useFilter((state) => state.filterById?.teamSquadFilter);
  let params = restructureFilter(filter);
  const intersectTarget = createRef();

  const [projects, setProjects] = useState([]);
  const headers = [
    { name: 'PROJECT NAME', widthClass: 'w-[244px]', grow: true },
    { name: 'PROGRESS', widthClass: 'w-[160px]' },
    { name: 'STATUS', widthClass: 'w-[96px]' },
    { name: 'LAST UPDATED', widthClass: 'w-[144px]' }
  ];

  const fetchProjects = async (olderThan) => {
    let allParams = {
      reviewsVisibility: 1,
      limit: 15,
      isProject: true,
      teamId: teamId,
      ...(olderThan && { olderThan: olderThan }),
      ...params
    };

    return getListObjectives(allParams);
  };

  const queryKey = ['squadProjects', 'myteams', teamId, params];

  const { hasNextPage, fetchNextPage } = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => fetchProjects(pageParam),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.next?.olderThan || undefined,
      onSuccess: (data) => {
        const newSquads = flatten(
          data?.pages?.map((page) => {
            return page?.data?.map((d) => {
              return d;
            });
          })
        );
        setProjects(newSquads);
      }
    }
  );

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting && hasNextPage && fetchNextPage(),
    threshold: 1
  });

  return (
    <Table headers={headers}>
      {projects?.length > 0 ? (
        projects?.map((project, index) => (
          <Table.Row
            key={project?.id}
            dataCy={`squad-${project.id}`}
            onClick={() => history.push(`/projects/${project?.id}`)}
          >
            <Table.Column key={`${index}-1`}>
              <p className="typography-paragraph text-n-900">{project?.name}</p>
            </Table.Column>
            <Table.Column key={`${index}-2`}>
              <LineProgressBar
                progress={project?.measurement?.progressPercentage}
                expectedProgress={
                  project?.measurement?.expectedProgressPercentage
                }
                width={92}
                primaryColor={whichBgColorClassName(
                  project?.measurement?.progressColorHex,
                  false,
                  '500'
                )}
                secondaryColor={'var(--n-500)'}
                fullFirst={true}
                height={12}
                dataCy="update-progress"
              />
              <p
                className={`typography-h400 w-[56px] text-start ml-[4px] ${
                  whichColor[project?.measurement?.progressColorHex]
                }`}
              >
                {project?.measurement?.progressPercentage}%
              </p>
            </Table.Column>
            <Table.Column
              key={`${index}-3`}
              customClass="!px-[0px] justify-center"
            >
              {!isEmpty(project?.measurement) && (
                <Badge
                  defaultPointer
                  content={whichStatus[project?.progressColorHex || '44DB5E']}
                  colorHex={
                    whichBadgeColorHex[project?.progressColorHex || '44DB5E']
                  }
                  bgColorHex={
                    whichBadgeBgColorHex[project?.progressColorHex || '44DB5E']
                  }
                  className="pointer-events-none"
                />
              )}
            </Table.Column>
            <Table.Column
              key={`${index}-4`}
              customClass="!px-[0px] justify-center"
            >
              <p className="typography-paragraph text-n-900">
                {format(project?.valueLastUpdatedAt)}
              </p>
            </Table.Column>
          </Table.Row>
        ))
      ) : (
        <ListEmptyState
          fullHeight
          containerClassname="my-[28px]"
          titleClassname="!mb-[8px]"
          emptyIcon="icon-empty-project-squad"
          size="small"
          title={getObjectiveLocale('No project has been created yet')}
          subtitle={getObjectiveLocale(
            'Visit the squad detail page to create a project in this squad.'
          )}
        />
      )}
    </Table>
  );
};

export default TribeChildDetail;

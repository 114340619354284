import React, { useCallback, useEffect, useRef, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import { useUserSuggestion } from 'context/UserSuggestionContext';
import useClickOutside from 'hooks/useClickOutside';
import useDebounce from 'hooks/useDebounce';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { loadMoreValidator } from 'utils/HelperUtils';

import TwoLine from 'components/design-system/ProfileType';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import SearchBar from 'components/shared/SearchBar';
import SingleUserList from 'components/shared/SingleUserList';
import { getObjectiveRoleWording } from 'src/utils/ObjectivesHelper';

const SelectedMember = ({ member, index, onClickRole, onClickRemove }) => {
  const { user, role } = member || {};

  const dropdownOptions = [
    {
      text: 'Leader',
      dataCy: 'leader',
      onClick: () => onClickRole(role, 'leader', index)
    },
    {
      text: 'Reviewer',
      dataCy: 'reviewer',
      onClick: () => onClickRole(role, 'reviewer', index)
    },
    {
      text: 'Member',
      dataCy: 'member',
      onClick: () => onClickRole(role, 'member', index)
    },
    {
      text: 'Stakeholder',
      dataCy: 'stakeholder',
      onClick: () => onClickRole(role, 'stakeholder', index)
    },
    {
      text: 'PMO',
      dataCy: 'pmo',
      onClick: () => onClickRole(role, 'pmo', index)
    },
    { text: 'Remove', dataCy: 'remove', onClick: () => onClickRemove(index) }
  ];

  return (
    <div className="h-[56px] flex items-center justify-between">
      <TwoLine
        user={user}
        title={user.name}
        subtitle={user.title}
        customClass="!w-[calc(100%-160px)]"
      />
      <Dropdown customClass="min-w-[160px]">
        <Dropdown.Trigger customClass="w-full">
          <div className="flex items-center w-full justify-end">
            <p
              className={`typography-button text-n-800 mr-[4px] ${
                member.extendedRole === 'pmo' ? 'uppercase' : 'capitalize'
              }`}
              data-cy={`selected-${user?.id}-name`}
            >
              {getObjectiveRoleWording({ user: member, isTeam: true })}
            </p>
            <SVGIcon
              size="24"
              iconName="icon-arrow_drop_down"
              fillColor="var(--n-800)"
              dataCy="change-role"
            />
          </div>
        </Dropdown.Trigger>
        <Dropdown.MenuItems
          options={dropdownOptions}
          position="right"
          type="fixed"
        />
      </Dropdown>
    </div>
  );
};

const ChooseMemberTeam = ({ members, setMembers }) => {
  const [showPopupMember, setShowPopupMember] = useState(false);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [onLoadMore, setLoadMore] = useState(false);

  const firstRender = useRef(true);

  const debounceSearchValue = useDebounce(search, 500);

  const { suggestion, hasMore, getSuggestionData, appendSuggestionData } =
    useUserSuggestion();

  const popupMemberRef = useRef();
  useClickOutside(popupMemberRef, () => setShowPopupMember(false));

  const onScroll = (e) => {
    const target = e.target;
    const query = {};

    const loadMore = async () => {
      if (search != '') {
        query.q = search;
      }

      setLoadMore(true);
      await appendSuggestionData(query);
      setLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 200, () => {
        loadMore();
      });
    }
  };

  const getRoleAndExtendedRole = (role) => {
    const list = {
      leader: {
        role: 'assignee',
        extendedRole: 'leader'
      },
      reviewer: {
        role: 'assigner',
        extendedRole: null
      },
      member: {
        role: 'assignee',
        extendedRole: null
      },
      stakeholder: {
        role: 'follower',
        extendedRole: null
      },
      pmo: {
        role: 'follower',
        extendedRole: 'pmo'
      }
    };

    return list[role];
  };

  const onClickUserSuggestion = (user) => {
    if (!members.some((member) => member?.user?.id === user?.id)) {
      const role = getRoleAndExtendedRole('member');
      let tempMembers = cloneDeep(members);
      tempMembers.push({
        role: role.role,
        extendedRole: role.extendedRole,
        user: user
      });
      setMembers(tempMembers);
    }

    setShowPopupMember(false);
  };

  const handleChangeRole = (oldRole, newRole, index) => {
    if (oldRole !== newRole) {
      const role = getRoleAndExtendedRole(newRole);

      let tempMembers = cloneDeep(members);
      tempMembers[index].role = role.role;
      tempMembers[index].extendedRole = role.extendedRole;

      if (newRole === 'leader' || newRole === 'reviewer') {
        let sameRoleIndex = members.findIndex((member) => {
          if (newRole === 'leader') {
            return member?.extendedRole === 'leader';
          }

          return member?.role === 'assigner';
        });

        if (sameRoleIndex !== -1) {
          const memberRole = getRoleAndExtendedRole('member');

          tempMembers[sameRoleIndex].role = memberRole.role;
          tempMembers[sameRoleIndex].extendedRole = memberRole.extendedRole;
        }
      }

      setMembers(tempMembers);
    }
  };

  const handleRemoveSelectedUser = (userIndex) => {
    let tempMembers = cloneDeep(members);
    tempMembers.splice(userIndex, 1);
    setMembers(tempMembers);
  };

  const _getData = useCallback(async (query) => {
    setIsLoading(true);
    await getSuggestionData(query);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      _getData({ q: debounceSearchValue });
    }
  }, [debounceSearchValue, _getData]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  return (
    <>
      <p className="mb-[8px] typography-h100 text-n-800">
        {getObjectiveLocale('Search employee')}
      </p>
      <div className="relative" ref={popupMemberRef}>
        <SearchBar
          placeholder="Ex: Alicia Silverstone..."
          onChange={(e) => setSearch(e.target.value)}
          fullWidth
          iconFillColor="var(--n-600)"
          onFocus={() => setShowPopupMember(true)}
          dataCy="member-team"
        />
        {showPopupMember && (
          <div
            className="absolute w-full overflow-auto max-h-[200px] bg-n-000 z-[2] shadow-raised"
            onScroll={(e) => onScroll(e)}
          >
            {isLoading && <LoadingComponent className="min-h-[64px]" />}
            {!isLoading &&
              suggestion.length > 0 &&
              suggestion.map((user, index) => {
                return (
                  <SingleUserList
                    key={index}
                    user={user}
                    onClick={(user) => onClickUserSuggestion(user)}
                  />
                );
              })}
            {!isLoading && onLoadMore && <LoadingComponent />}
          </div>
        )}
      </div>

      {members && members.length > 0 && (
        <>
          <p className="typography-h300 text-n-600 uppercase h-[36px] flex items-center mt-[24px]">
            {getObjectiveLocale('Selected members')}
          </p>
          <div className="max-h-[280px] overflow-hidden hover:overflow-overlay hover:overflow-auto">
            {members.map((member, index) => (
              <SelectedMember
                member={member}
                index={index}
                onClickRole={handleChangeRole}
                onClickRemove={handleRemoveSelectedUser}
                key={index}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default ChooseMemberTeam;

import client from './ApiClient';

function getReminderCheckin() {
  let url = `v1/checkin_reminder`;
  return client(url);
}

function checkinSetReminder(body) {
  let url = `/v1/checkin_reminder`;
  return client(url, { method: 'PUT', body });
}

export { getReminderCheckin, checkinSetReminder };

import React, { Component } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

class OverviewPerformanceReportSummaryComponent extends Component {
  render() {
    let team = this.props.teamHighchartData;
    let role = this.props.roleHighchartData;
    return (
      <div className="performance-report-summary-container">
        <span className="title-performance-report">
          {' '}
          Performance Report Summary{' '}
        </span>
        <div className="info-quarter">
          <div className="quarter-box">
            <span className="title-quarter"> Q1 Jan-Mar</span>
            {team[0] || role[0] ? (
              <span className="score">
                {team[0] && role[0]
                  ? ((team[0] + role[0]) / 2).toFixed(2)
                  : !team[0] && role[0]
                  ? role[0]
                  : team[0] && !role[0]
                  ? team[0]
                  : ''}
              </span>
            ) : (
              <p className="typography-paragraph text-center text-n-600 mt-[8px] w-full">
                {getObjectiveLocale('Not yet available')}
              </p>
            )}
          </div>

          <div className="quarter-box">
            <span className="title-quarter"> Q2 Apr-Jun </span>
            {team[1] || role[1] ? (
              <span className="score">
                {team[1] && role[1]
                  ? ((team[1] + role[1]) / 2).toFixed(2)
                  : !team[1] && role[1]
                  ? role[1]
                  : team[1] && !role[1]
                  ? team[1]
                  : ''}
              </span>
            ) : (
              <p className="typography-paragraph text-center text-n-600 mt-[8px] w-full">
                {getObjectiveLocale('Not yet available')}
              </p>
            )}
          </div>

          <div className="quarter-box">
            <span className="title-quarter"> Q3 Jul-Sep</span>
            {team[2] || role[2] ? (
              <span className="score">
                {team[2] && role[2]
                  ? ((team[2] + role[2]) / 2).toFixed(2)
                  : !team[2] && role[2]
                  ? role[2]
                  : team[2] && !role[2]
                  ? team[2]
                  : ''}
              </span>
            ) : (
              <p className="typography-paragraph text-center text-n-600 mt-[8px] w-full">
                {getObjectiveLocale('Not yet available')}
              </p>
            )}
          </div>
          <div className="quarter-box">
            <span className="title-quarter"> Q4 Oct-Des </span>
            {team[3] || role[3] ? (
              <span className="score">
                {team[3] && role[3]
                  ? ((team[3] + role[3]) / 2).toFixed(2)
                  : !team[3] && role[3]
                  ? role[3]
                  : team[3] && !role[3]
                  ? team[3]
                  : ''}
              </span>
            ) : (
              <p className="typography-paragraph text-center text-n-600 mt-[8px] w-full">
                {getObjectiveLocale('Not yet available')}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default OverviewPerformanceReportSummaryComponent;

import React from 'react';

import { getExtraInformation } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import Avatar from 'components/shared/Avatar';

import './EmployeeAvatarNameTitle.scss';

const EmployeeAvatarNameTitle = ({ user, isLoading }) => {
  return (
    <div className="employee-review-container">
      {isLoading ? (
        <Shimmer circle width={40} height={40} />
      ) : (
        <Avatar
          src={user?.profilePicture}
          name={user?.name}
          size={40}
          className="card-user-image"
          dataCy="image-initial-name"
        />
      )}
      <div className="ml-[16px] w-[295px]">
        {isLoading ? (
          <Shimmer width={295} height={20} customClass="mb-[8px]" />
        ) : (
          <h5 className="typography-h500 mb-[0px]">{user?.name}</h5>
        )}
        {isLoading ? (
          <Shimmer width={295} height={16} />
        ) : (
          <h6 className="typography-paragraph mb-[0px]">
            {getExtraInformation(user?.title, user?.subtitle)}
          </h6>
        )}
      </div>
    </div>
  );
};

export default EmployeeAvatarNameTitle;

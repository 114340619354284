import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import {
  getSurveyIdCMSCulture,
  patchSurveyIdCMSCulture,
  postSurveyCMSCulture
} from 'client/adminClient';
import useDebounce from 'hooks/useDebounce';
import usePulseSurvey from 'hooks/usePulseSurvey';
import { capitalize } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Footer from 'components/shared/Footer';
import Loading from 'components/shared/LoadingComponent';
import SidebarLeftMenuNavigator from 'components/shared/SidebarLeftMenuNavigator';
import VerticalStepsComponent from 'components/shared/VerticalStepsComponent';
import NameDescriptionSurvey from 'components/surveys/NameDescriptionSurvey';
import ParticipantForm from 'components/surveys/ParticipantForm';
import QuestionForm from 'components/surveys/QuestionForm';
import VerifySurvey from 'components/surveys/VerifySurvey';
import SurveySchedule from 'components/surveys/survey-schedule/SurveySchedule';

import PulseSurveyDropdownOccurrences from './PulseSurveyDropdownOccurrences';
import SurveyAnalytics from './SurveyAnalytics';
import SurveyResult from './surveyResult/SurveyResult';

// eslint-disable-next-line @typescript-eslint/no-var-requires
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const PulseSurveyComposer = ({ route: { isReadOnly } }) => {
  const params = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    surveyData,
    setSurveyData,
    setScheduleType,
    totalParticipant,
    scheduleType,
    initializeData,
    setTotalParticipant
  } = usePulseSurvey();

  const debouncedSurveyData = useDebounce(surveyData, 2000);
  const searchParams = new URLSearchParams(location.search);
  const stepParams = searchParams.get('step');
  const tabParams = searchParams.get('tab');
  const occurrenceId = searchParams.get('occurrenceId');
  const isFieldReadOnly = isReadOnly || pathname.includes('survey-details');
  const isEditSurvey = pathname.includes('edit-pulse-survey');
  const isDuplicateSurvey = pathname.includes('duplicate-pulse-survey');
  const isCreateNew =
    pathname.includes('create-pulse-survey') ||
    pathname.includes('duplicate-pulse-survey');
  const isEditing = isEditSurvey || isDuplicateSurvey;
  const isTabHidden =
    !pathname.includes('survey-details') ||
    isDuplicateSurvey ||
    surveyData?.startTime > new Date().toISOString();
  const isStarted = new Date(surveyData.startTime) < new Date() && !isCreateNew;

  const [active, setActive] = useState(
    params?.id && !isDuplicateSurvey ? 'Verify' : 'Name & Description'
  );
  const firstInit = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('detail');
  const [selectedOccurrence, setSelectedOccurrence] = useState(null);
  const [steps, setSteps] = useState([
    { name: 'Name & Description', completed: false, disabled: false },
    { name: 'Questions', completed: false, disabled: !isEditing },
    { name: 'Participants', completed: false, disabled: !isEditing },
    { name: 'Schedule', completed: false, disabled: !isEditing },
    { name: 'Verify', completed: false, disabled: !isEditSurvey }
  ]);

  const tabs = [
    { id: 'detail', name: 'Detail' }
    // { id: 'analytics', name: 'Data & Analysis' }
    // { id: 'summary', name: 'Summary' }
  ];

  const headerProps = {
    titlePage: params?.id ? surveyData?.title : 'Create Pulse Survey',
    backToUrl: '/surveys',
    isHeaderComposer: true,
    tabs: !isTabHidden && {
      tabsList: tabs,
      url: pathname,
      onChange: (tab) => setCurrentTab(tab),
      customClass: 'pl-[16px]',
      querySearch: `?occurrenceId=${occurrenceId}&`
    },
    customAction: currentTab == 'analytics' && (
      <PulseSurveyDropdownOccurrences
        surveyId={surveyData?.id}
        selectedOccurrence={selectedOccurrence}
        setSelectedOccurrence={setSelectedOccurrence}
      />
    )
  };

  const setCurrentStep = (name) => {
    setActive(name);
    const pathExtra = isCreateNew ? '' : `&occurrenceId=${occurrenceId}`;
    history.push(`${pathname}?step=${name}${pathExtra}&tab=detail`);
  };

  const checkAnswer = () => {
    const cloneSurvey = cloneDeep(surveyData);
    const cloneSteps = cloneDeep(steps);
    const currentIndex = steps.findIndex((step) => step.name === active);

    cloneSteps[0].completed =
      !isEmpty(cloneSurvey?.title) &&
      !isEmpty(cloneSurvey?.description) &&
      !isEmpty(cloneSurvey?.thankMessage);

    let finalResult = cloneSurvey?.questions?.map((q) => {
      if (isEmpty(q?.question)) return false;
      if (
        q?.pollOptionsAttributes &&
        q?.pollOptionsAttributes?.find(
          (opt) => isEmpty(opt?.option) && !opt.customType
        )
      )
        return false;
      return true;
    });
    cloneSteps[1].completed = finalResult?.every((res) => res);
    cloneSteps[2].completed =
      !isEmpty(cloneSurvey.includes) || cloneSurvey.selectAll;
    cloneSteps[3].completed =
      !isEmpty(cloneSurvey.startTime) &&
      !isEmpty(cloneSurvey.expiration) &&
      !isEmpty(scheduleType);

    if (
      cloneSteps[0].completed &&
      cloneSteps[1].completed &&
      cloneSteps[2].completed &&
      cloneSteps[3].completed
    ) {
      cloneSteps[4].completed = true;
    } else {
      cloneSteps[4].completed = false;
    }

    //check able/disable sidebar
    if (cloneSteps[currentIndex]?.completed && cloneSteps[currentIndex + 1]) {
      cloneSteps[currentIndex + 1].disabled = false;
    }
    setSteps(cloneSteps);
  };

  const checkButton = () => {
    return (
      !steps.find((step) => step.name === active).completed || isFieldReadOnly
    );
  };

  const getCurrentData = async () => {
    setIsLoading(true);
    let { data } = await getSurveyIdCMSCulture(params?.id, {
      surveyOccurrenceId: parseInt(occurrenceId)
    });
    if (data) {
      data.title = isDuplicateSurvey ? data.title + '(Copy)' : data.title;
      data.selectAll = false;
      data.includes = data?.participantIds;
      data.excludes = [];
      if (data?.questions?.length > 0) {
        data?.questions?.map((question) => {
          question.pollOptionsAttributes = question?.pollOptions;
          delete question?.pollOptions;
        });
      }
      setSurveyData(data);
      setTotalParticipant(data?.participantIds?.length);
      if (isDuplicateSurvey) {
        setScheduleType('');
      } else if (!pathname.includes('create-pulse-survey')) {
        setScheduleType('custom');
      } else {
        setScheduleType('now');
      }
      if (!firstInit.current) {
        firstInit.current = true;
        setSelectedOccurrence(data?.occurrence);
      }
    }
    setIsLoading(false);
  };

  const handleNextPrevButton = (type) => {
    const currentIndex = steps.findIndex((step) => step.name === active);
    const index = type === 'next' ? currentIndex + 1 : currentIndex - 1;
    setActive(steps[index].name);
  };

  const submitSurvey = async () => {
    const pollsAttributes = isDuplicateSurvey
      ? surveyData?.questions.filter(
          (opt) => (
            opt.type === 'poll',
            delete opt?.id &&
              opt?.pollOptionsAttributes?.filter((poll) => delete poll?.id)
          )
        )
      : surveyData?.questions.filter((opt) => opt.type === 'poll');
    const essaysAttributes = surveyData?.questions.filter(
      (opt) => opt.type === 'essay'
    );

    let formSurveyData = cloneDeep(surveyData);
    delete formSurveyData.question;
    delete formSurveyData.id;
    const body = {
      survey: {
        ...formSurveyData,
        randomize: false,
        visibility: 'selected',
        pollsAttributes: pollsAttributes,
        essaysAttributes: essaysAttributes,
        polls: pollsAttributes
      }
    };

    const { isSuccess } =
      params?.id && !isDuplicateSurvey
        ? await patchSurveyIdCMSCulture(params?.id, body)
        : await postSurveyCMSCulture(body);

    if (isSuccess) {
      history.replace('/surveys');
    }
  };

  const setData = useCallback(() => {
    params?.id ? getCurrentData() : initializeData();

    const existingData = JSON.parse(localStorage.getItem('surveyData'));
    if (existingData) {
      setSurveyData(existingData);
      localStorage.removeItem('surveyData');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, selectedOccurrence]);

  useEffect(() => {
    setData();
  }, [setData]);

  useEffect(() => {
    stepParams &&
      setActive(
        stepParams.includes('Name')
          ? 'Name & Description'
          : capitalize(stepParams)
      );
  }, [stepParams]);

  useEffect(() => {
    tabParams && setCurrentTab(tabParams);
  }, [tabParams]);

  useEffect(() => {
    checkAnswer();
  }, [debouncedSurveyData, scheduleType]);

  useEffect(() => {
    return () => {
      setSurveyData({});
    };
  }, [setSurveyData]);

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="flex flex-col bg-n-000 !pl-[0px] !pr-[0px]">
        <div className="relative flex min-h-[calc(100vh-98px)] padding-0 bg-n-000">
          {!tabParams?.includes('summary') &&
            !tabParams?.includes('analytics') &&
            (isFieldReadOnly ? (
              <SidebarLeftMenuNavigator
                listMenu={steps}
                isUsingGeneralFunction={true}
                onClickMenu={(name) => setCurrentStep(name)}
                activeMenu={active}
                customClass="w-[256px] pt-[12px]"
                minHeightSidebar="!h-[calc(100vh-98px)]"
              />
            ) : (
              <VerticalStepsComponent
                activeStep={active}
                setActiveStep={(name) => setCurrentStep(name)}
                steps={steps}
              />
            ))}

          {isLoading ? (
            <Loading className="w-full" />
          ) : currentTab === 'detail' ? (
            <div
              className={`pb-[64px] px-[40px] pt-[24px] h-[calc(100vh-120px)] overflow-auto w-[calc(100vw-340px)] ${
                isFieldReadOnly ? 'ml-[0px]' : 'ml-[264px]'
              }`}
            >
              {active === 'Name & Description' && (
                <NameDescriptionSurvey
                  isReadOnly={isFieldReadOnly || isStarted}
                />
              )}
              {active === 'Questions' && (
                <QuestionForm isReadOnly={isFieldReadOnly || isStarted} />
              )}
              {active === 'Participants' && (
                <ParticipantForm isReadOnly={isFieldReadOnly || isStarted} />
              )}
              {active === 'Schedule' && (
                <SurveySchedule
                  isReadOnly={isFieldReadOnly || isStarted}
                  isStarted={isStarted}
                />
              )}
              {active === 'Verify' && (
                <VerifySurvey
                  isReadOnly={isFieldReadOnly || isStarted}
                  data={surveyData}
                  type="survey"
                  totalParticipant={totalParticipant}
                />
              )}
              {!isFieldReadOnly && (
                <Footer
                  linkButton={true}
                  customClass={active !== 'Participants' ? 'w-[820px]' : ''}
                  style={{
                    position: 'fixed',
                    bottom: '0',
                    left: '320px',
                    width: 'calc(100vw - 320px)'
                  }}
                  handleClick={() =>
                    active === 'Verify'
                      ? submitSurvey()
                      : handleNextPrevButton('next')
                  }
                  handleRoute={() => handleNextPrevButton('prev')}
                  buttonPrimaryName={active === 'Verify' ? 'Submit' : 'Next'}
                  linkButtonName="Back"
                  datacy={active === 'Verify' ? 'Submit' : 'Next'}
                  buttonPrimaryDisabled={checkButton()}
                  linkButtonDisabled={active === 'Name & Description'}
                />
              )}
            </div>
          ) : currentTab === 'summary' ? (
            <SurveyResult
              title={surveyData?.title}
              isPast={dayjs(surveyData.expiration).isBefore(dayjs())}
            />
          ) : (
            <SurveyAnalytics selectedOccurrence={selectedOccurrence} />
          )}
        </div>
      </div>
    </>
  );
};

export default PulseSurveyComposer;

import React, { useEffect, useRef, useState } from 'react';

import {
  getListReviewGroup,
  patchListReviewGroup
} from 'client/admin/ReviewGroupClient.js';
import useClickOutside from 'hooks/useClickOutside';
import debounce from 'utils/Debounce';
import { loadMoreValidator } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Checkbox from 'components/shared/Checkbox';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import SearchBar from 'components/shared/SearchBar';
import ToastNotif from 'components/shared/ToastNotif.js';

import GroupListTable from './GroupListTable';
import './GroupTabReviewGroup.scss';
import ModalArchiveGroup from './ModalArchiveGroup';

const GroupTabReviewGroup = ({
  description,
  groupType,
  routeToEditPage,
  isReadOnly
}) => {
  const filterRef = useRef();
  const firstRender = useRef(true);
  // List state
  const [groupList, setGroupList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [stateParams, setStateParams] = useState(['active']);

  // UI State
  const [showdropdownFilter, setShowDropdownFilter] = useState(false);
  const [isSuccessState, setIsSuccessState] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  // Archiving State
  const [modalArchiveData, setModalArchiveData] = useState(false);
  const [checkedRadio, setCheckedRadio] = useState('default');
  const [groupOptionValue, setGroupOptionValue] = useState(0); // id
  const [dropdownGroupList, setDropdownGroupList] = useState([]);

  // Pagination
  const [paginationObject, setPaginationObject] = useState(null);
  const [paginationObjectForDropdown, setPaginationObjectForDropdown] =
    useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [hasMoreForDropdown, setHasMoreForDropdown] = useState(false);

  // Search State
  const [searchValue, setSearchValue] = useState('');
  const [debounceHandler, setDebounceHandler] = useState(null);

  // Initial params
  let params = {
    groupType,
    limit: 10,
    sort_direction: 'desc',
    states: stateParams,
    sort_column: 'created_at'
  };

  const getListGroup = async (isFirstRender = false) => {
    if (searchValue !== '') {
      params.q = searchValue;
    }

    setLoadingList(true);
    const { data, pagination } = await getListReviewGroup(params);
    if (data || pagination) {
      // For Dropdown in modal to archive certain_group
      if (isFirstRender) {
        setDropdownGroupList(data || []);
        setPaginationObjectForDropdown(pagination || {});
        setHasMoreForDropdown(
          pagination?.next?.olderThan == null ? false : true
        );
      }
      setGroupList(data || []);
      setPaginationObject(pagination || {});
      setHasMore(data.length === 10);
      setGroupOptionValue(data[0]?.id || 0);
    }
    setLoadingList(false);
  };

  const handleNextOrPrevPagination = async (nextOrPrev) => {
    setGroupList([]);
    setLoadingList(true);

    if (nextOrPrev == 'next') {
      params.olderThan = paginationObject?.next?.olderThan;
    } else {
      params.newerThan = paginationObject?.prev?.newerThan;
    }
    const { data, pagination } = await getListReviewGroup(params);
    if (data.length > 0) {
      setGroupList(data);
      setPaginationObject(pagination);
      setCurrentPage(nextOrPrev === 'next' ? currentPage + 1 : currentPage - 1);
      if (nextOrPrev == 'prev') {
        setHasMore(true);
      } else {
        setHasMore(data.length === 10);
      }
    }
    setLoadingList(false);
  };

  const searchDebounce = async (value) => {
    const callback = async () => {
      setSearchValue(value);
    };

    const handler = await debounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  const toastFunction = (message) => {
    setIsSuccessState(true);
    setSuccessMessage(message);
    setTimeout(() => {
      setIsSuccessState(false);
    }, 4000);
  };

  const updateGroupDeleteProperty = (groupId) => {
    let tempGroupList = [...groupList];
    const newGroupList = tempGroupList.map((group) => {
      if (group.id === groupId) {
        return { ...group, deleted: !group.deleted };
      } else {
        return group;
      }
    });

    return newGroupList;
  };

  const handleArchiveGroup = async () => {
    let params = {
      change_state: 'archived'
    };
    if (checkedRadio === 'default') {
      params.transfer_members_to_group_id = 0;
      const { isSuccess } = await patchListReviewGroup(
        modalArchiveData?.id,
        params
      );
      if (isSuccess) {
        toastFunction('The Group was archived');
        setModalArchiveData(null);
        getListGroup();
      }
    } else if (checkedRadio === 'certain_group') {
      params.transfer_members_to_group_id = groupOptionValue;
      const { isSuccess } = await patchListReviewGroup(
        modalArchiveData?.id,
        params
      );
      if (isSuccess) {
        toastFunction('The Group was archived');
        setModalArchiveData(null);
        getListGroup();
      }
    }
  };

  const reactiveGroup = async (groupId) => {
    let params = {
      change_state: 'active'
    };

    const { isSuccess } = await patchListReviewGroup(groupId, params);
    if (isSuccess) {
      toastFunction('The Group was reactivated');
      const newGroupList = updateGroupDeleteProperty(groupId);
      setGroupList(newGroupList);
    }
  };

  const handleCheckbox = (activeOrArchived, checked) => {
    let tempStateParams = [...stateParams];
    if (checked) {
      tempStateParams.push(activeOrArchived);
    } else {
      tempStateParams = tempStateParams.filter(
        (val) => val != activeOrArchived
      );
    }
    setStateParams(tempStateParams);
  };

  const appendGroupList = async () => {
    // let params = paramsBehaviorModel(type, { offset: nextOffset });
    params.olderThan = paginationObjectForDropdown.next?.olderThan;
    const { data, pagination } = await getListReviewGroup(params);
    if (data) {
      setDropdownGroupList(dropdownGroupList.concat(data));
      setPaginationObjectForDropdown(pagination);
      setHasMoreForDropdown(pagination?.next?.olderThan == null ? false : true);
    }
  };

  const onScroll = (e) => {
    const target = e.target;
    const loadMore = async () => {
      await appendGroupList();
    };
    if (hasMoreForDropdown) {
      loadMoreValidator(target, 100, () => {
        loadMore();
      });
    }
  };
  // End Dropdown

  useClickOutside(filterRef, () => setShowDropdownFilter(false));

  useEffect(() => {
    if (firstRender.current) {
      getListGroup(true);
      firstRender.current = false;
    } else {
      getListGroup(false);
    }
  }, [searchValue, stateParams]);

  return (
    <div className="groups-tab-performance-profile">
      <ToastNotif showNotif={isSuccessState} message={successMessage} />
      <p className="typography-paragraph mt-[24px] mb-[24px]">{description}</p>
      <div className="flex">
        <SearchBar
          placeholder="Search group"
          onChange={(e) => searchDebounce(e.target.value)}
          fullWidth={true}
        />
      </div>
      <div className="gt-filter-and-label-wrapper flex mt-[8px]">
        <div className="gt-filter-container inline-block" ref={filterRef}>
          <Button.Tertiary
            customClass="mr-[8px]"
            onClick={() => setShowDropdownFilter(true)}
          >
            <i className="material-icons text-base-600">filter_list</i>
            Filter
          </Button.Tertiary>
          {showdropdownFilter && (
            <div className="gt-dropdown">
              <div className="pl-[16px] pr-[16px] pt-[8px] pb-[8px]">
                <span className="typography-h100 typography-tertiary">
                  Filter by: Status
                </span>
              </div>
              <div className="gt-dropdown-menu">
                <Checkbox
                  checked={stateParams.includes('active')}
                  id={'1'}
                  onChange={(e) =>
                    handleCheckbox('active', e.currentTarget.checked)
                  }
                >
                  <span className="ml-[8px]">Active</span>
                </Checkbox>
              </div>
              <div className="gt-dropdown-menu">
                <Checkbox
                  checked={stateParams.includes('archived')}
                  id={'2'}
                  onChange={(e) =>
                    handleCheckbox('archived', e.currentTarget.checked)
                  }
                >
                  <span className="ml-[8px]">Archived</span>
                </Checkbox>
              </div>
            </div>
          )}
        </div>
        {stateParams.length > 0 && (
          <div className="gt-filter-label-container">
            <span className="gt-filter-label">
              <span className="typography-h200 typography-secondary">
                Status:
              </span>
              <span className="typography-h100 typography-secondary ml-[4px]">
                {/* {stateParams.map(state => state.charAt(0).toUpperCase() + state.slice(1))} */}
                {stateParams
                  .map(
                    (state) => state.charAt(0).toUpperCase() + state.slice(1)
                  )
                  .join(', ')}
              </span>
            </span>
            <i
              className="material-icons cursor-pointer text-n-600"
              onClick={() => setStateParams([])}
            >
              clear
            </i>
          </div>
        )}
      </div>

      {/* Table */}
      <GroupListTable
        loadingList={loadingList}
        groupList={groupList}
        setModalArchiveData={setModalArchiveData}
        routeToEditPage={routeToEditPage}
        reactiveGroup={reactiveGroup}
        isReadOnly={isReadOnly}
        searchValue={searchValue}
        groupType={groupType}
      />

      <div className="flex justify-end items-center pb-[40px] pt-[24px]">
        <PaginationModule
          pageNumber={currentPage}
          handlePrev={() => handleNextOrPrevPagination('prev')}
          handleNext={() => handleNextOrPrevPagination('next')}
          hasMore={hasMore}
        />
      </div>

      {/* Modal */}
      {modalArchiveData && (
        <ModalArchiveGroup
          checkedRadio={checkedRadio}
          setCheckedRadio={setCheckedRadio}
          modalArchiveData={modalArchiveData}
          setModalArchiveData={setModalArchiveData}
          handleArchiveGroup={handleArchiveGroup}
          dropdownGroupList={dropdownGroupList}
          groupOptionValue={groupOptionValue}
          setGroupOptionValue={setGroupOptionValue}
          onScroll={onScroll}
        />
      )}
    </div>
  );
};

export default GroupTabReviewGroup;

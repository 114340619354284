import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  getCountStrength,
  getCountWeakness,
  getDetailStrength,
  getDetailWeakness
} from 'client/UserProfile';
import { useProfileFilter } from 'context/ProfileFilterContext';
import { useProfileSkill } from 'context/ProfileSkillContext';
import { useProfileValue } from 'context/ProfileValueContext';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import usePermission from 'hooks/usePermission';
import { useUrl } from 'hooks/useUrl';
import Config from 'utils/Config';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import ListEmptyState from 'components/shared/ListEmptyState';
import SVGIcon from 'components/shared/SVGIcon';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import SidebarBehaviour from 'components/user-profile/tabs/SidebarBehaviour';
import SkillValueCard from 'components/user-profile/tabs/SkillValueCard';

const SkillValueComponentTab = ({
  strengths,
  weaknesses,
  props,
  selector = 'value'
}) => {
  const { url, match } = useUrl();
  const { userId, startDate, dueDate } = props;
  const [currentTab, setCurrentTab] = useState('strength');
  const { setYear, filter } = useProfileFilter();
  const [totalCulture, setTotalCulture] = useState(0);
  const [totalPerformance, setTotalPerformance] = useState(0);
  const [detailData, setDetailData] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [paginationData, setPaginationData] = useState({});

  const { appType } = useUser();

  let history = useHistory();
  const params = useParams();

  const { values, type, behaviorId, placementId } = params;
  const permission = props.permission;

  const isSkillStrengthVisible = usePermission(
    'profileSkillStrengthSee',
    permission
  );
  const isValueStrengthVisible = usePermission(
    'profileValueStrengthSee',
    permission
  );

  const strengthSee =
    selector === 'value' ? isValueStrengthVisible : isSkillStrengthVisible;
  const improvementSee =
    selector === 'value'
      ? permission?.profileValueImprovementSee
      : permission?.profileSkillImprovementSee;
  const titleEmptyState =
    selector === 'value'
      ? getObjectiveLocale('There is no value to be displayed')
      : getObjectiveLocale('No skill available yet');

  const {
    scroll: valuesScroll,
    strengthOffset: valueStrengthOffset,
    weaknessOffset: valueWeaknessOffset
  } = useProfileValue();
  const {
    scroll: skillScroll,
    strengthOffset: skillStrengthOffset,
    weaknessOffset: skillWeaknessOffset
  } = useProfileSkill();

  const setScroll = (e, type) => {
    const offset =
      selector === 'value'
        ? type == 'strength'
          ? valueStrengthOffset
          : valueWeaknessOffset
        : type === 'strength'
        ? skillStrengthOffset
        : skillWeaknessOffset;
    selector === 'value'
      ? valuesScroll(e, type, userId, offset)
      : skillScroll(e, type, userId, offset);
  };

  const getDetailSkill = async (behaviourId) => {
    const behaviorType = selector === 'value' ? 'value' : 'competency';

    const query = {
      periodBegin: startDate,
      periodEndBefore: dueDate,
      ...(appType === 'culture' && { source: 'culture' })
    };

    let detailStrength,
      countStrength = {};

    if (currentTab === 'strength' && strengthSee) {
      detailStrength = await getDetailStrength(
        userId,
        behaviorType,
        behaviourId,
        query
      );
      countStrength = await getCountStrength(
        userId,
        behaviorType,
        behaviourId,
        query
      );
    } else if (currentTab !== 'strength' && improvementSee) {
      detailStrength = await getDetailWeakness(
        userId,
        behaviorType,
        behaviourId,
        query
      );
      countStrength = await getCountWeakness(
        userId,
        behaviorType,
        behaviourId,
        query
      );
    }

    const { data: dataCount } = countStrength;
    const { data, isSuccess, pagination } = detailStrength;

    if (dataCount) {
      setTotalCulture(dataCount?.cultureCount);
      setTotalPerformance(dataCount?.performanceCount);
    }

    if (isSuccess && data) {
      setDetailData(data);
      setShowSidebar(true);
      setPaginationData(pagination);
      !behaviorId && history.replace(`${url}/${behaviourId}`);
    }
  };

  const appendData = async () => {
    const behaviorType = selector === 'value' ? 'value' : 'competency';
    const query = {
      periodBegin: startDate,
      periodEndBefore: dueDate,
      offset: paginationData?.nextOffset
    };
    const detailStrength =
      currentTab === 'strength'
        ? await getDetailStrength(userId, behaviorType, behaviorId, query)
        : await getDetailWeakness(userId, behaviorType, behaviorId, query);
    const { data, isSuccess, pagination: nextPagination } = detailStrength;

    if (isSuccess && data) {
      let temp = [...detailData];
      temp = temp.concat(data);
      setDetailData(temp);
      setPaginationData(nextPagination);
    }
  };

  const closeSidebar = () => {
    setShowSidebar(false);
    history.replace(
      `/users/${userId}/placement/${placementId}/${values}/${currentTab}`
    );
  };

  const debouncedFilter = useDebounce(filter, 1000);
  const debouncedTab = useDebounce(currentTab, 1000);

  useEffect(() => {
    if (behaviorId) {
      setYear('all');
      getDetailSkill(behaviorId);
      setShowSidebar(true);
    }
  }, [debouncedFilter, debouncedTab]);

  useEffect(() => {
    setShowSidebar(false);
  }, [currentTab]);

  useEffect(() => {
    if (type) {
      if (type == 'strength') {
        setCurrentTab('strength');
      } else if (type == 'improvement') {
        setCurrentTab('improvement');
      }
    }
  }, []);

  const EmptyPermission = ({ size }) => (
    <ListEmptyState
      fullHeight
      size={size || 'large'}
      emptyIcon="icon-no-permission"
      title={getObjectiveLocale('No permission')}
      subtitle={getObjectiveLocale(
        "You don't have permission to see this content"
      )}
    />
  );

  const EmptyState = ({ size }) => (
    <ListEmptyState
      fullHeight
      size={size || 'large'}
      emptyIcon={selector === 'value' ? 'icon-no-value' : 'icon-no-skill'}
      title={getObjectiveLocale(titleEmptyState)}
      subtitle={getObjectiveLocale(
        selector === 'value'
          ? 'Once you have values, your values will be displayed here'
          : 'You have not received or given any skills yet'
      )}
    />
  );

  const TabItem = ({ index, data, type }) => {
    return (
      <div key={index} className="flex justify-between items-center h-[32px]">
        <div className="flex">
          {selector === 'value' && (
            <AWSImage
              src={
                data?.iconImageAttributes?.iconUrl ||
                Config.asset.culture.brokenImageCenter
              }
              className="w-[24px] h-[24px] rounded-[50px] mr-[8px]"
            />
          )}
          <span className="list-skill multiline-text-1 typography-paragraph">
            {data?.behaviorTitle}
          </span>
        </div>
        <div
          className={`py-[2px] px-[8px] typography-h200 h-[20px] rounded-[4px] ${
            type === 'strength' ? 'text-g-800 bg-g-200' : 'text-y-800 bg-y-200'
          }`}
        >
          {getNumberLocale(
            appType !== 'culture' ? data?.totalCount : data?.cultureCount
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="skills-tab">
      <div className="row">
        <div className="col-md-4">
          <div className="skills-tab-card strength-card skill-container-profile">
            <div className="header flex">
              <SVGIcon
                iconName="icon-thumb_up_alt"
                fillColor="var(--g-600)"
                customClass="mr-8"
                size="16"
              />
              <span className={`typography-h200 text-g-800`}>
                {getObjectiveLocale('Strength')}
              </span>
            </div>
            <div
              className="mt-0 height-200 overflow-y-auto"
              onScroll={(e) => setScroll(e, 'strength')}
            >
              {!strengthSee ? (
                <EmptyPermission size="small" />
              ) : strengths.length > 0 ? (
                strengths.map((strength, index) => (
                  <Fragment key={index}>
                    {strength.strengthCount != 0 && (
                      <TabItem index={index} data={strength} type="strength" />
                    )}
                  </Fragment>
                ))
              ) : (
                <EmptyState size="small" />
              )}
            </div>
          </div>
          {appType !== 'culture' && (
            <div className="skills-tab-card improvement-card skill-container-profile">
              <div className="header flex">
                <SVGIcon
                  iconName="icon-feedback"
                  fillColor="var(--y-600)"
                  customClass="mr-[8px]"
                  size="16"
                />
                <span className={`typography-h200 text-y-800`}>
                  {getObjectiveLocale('Improvement')}
                </span>
              </div>
              <div
                className="mt-[0px] h-[200px] overflow-y-auto"
                onScroll={(e) => setScroll(e, 'weakness')}
              >
                {!improvementSee ? (
                  <EmptyPermission size="small" />
                ) : weaknesses.length > 0 ? (
                  weaknesses?.map((feedback, index) => (
                    <Fragment key={index}>
                      {feedback.weaknessCount != 0 && (
                        <TabItem
                          index={index}
                          data={feedback}
                          type="weakness"
                        />
                      )}
                    </Fragment>
                  ))
                ) : (
                  <EmptyState size="small" />
                )}
              </div>
            </div>
          )}
        </div>

        <div className="col-md-8 pb-[64px]">
          {appType !== 'culture' && (
            <>
              <div className="typography-h200 mb-[8px]">
                {getObjectiveLocale('Sources')}
              </div>
              <div className="flex justify-between mb-[16px]">
                <div className="value-source-status">
                  <span
                    className={`option-filter ${
                      currentTab == 'strength' ? 'active' : null
                    }`}
                    data-type="strength"
                    onClick={() => {
                      setCurrentTab('strength'),
                        history.replace(
                          `/users/${userId}/placement/${placementId}/${values}/strength`
                        );
                    }}
                  >
                    {getObjectiveLocale('Strength')}
                  </span>
                  <span
                    className={`option-filter ${
                      currentTab == 'improvement' ? 'active' : null
                    }`}
                    data-type="improvement"
                    onClick={() => {
                      setCurrentTab('improvement'),
                        history.replace(
                          `/users/${userId}/placement/${placementId}/${values}/improvement`
                        );
                    }}
                  >
                    {getObjectiveLocale('Improvement')}
                  </span>
                </div>
              </div>
            </>
          )}

          {currentTab == 'strength' ? (
            <div className="skill-container-profile skill-comment-strength">
              {!strengthSee ? (
                <EmptyPermission />
              ) : strengths.length > 0 ? (
                strengths.map((strength, index) => (
                  <SkillValueCard
                    getDetailSkill={getDetailSkill}
                    index={index}
                    key={index}
                    data={strength}
                    type="strength"
                    props={props}
                    selector={selector}
                    currentTab={currentTab}
                  />
                ))
              ) : (
                <EmptyState />
              )}
            </div>
          ) : (
            <div className="skill-container-profile skill-comment-improvement ">
              {!improvementSee ? (
                <EmptyPermission />
              ) : weaknesses.length > 0 ? (
                weaknesses.map((feedback, index) => (
                  <SkillValueCard
                    getDetailSkill={getDetailSkill}
                    index={index}
                    key={index}
                    data={feedback}
                    type="feedback"
                    props={props}
                    selector={selector}
                    currentTab={currentTab}
                  />
                ))
              ) : (
                <EmptyState />
              )}
            </div>
          )}
        </div>
      </div>
      {showSidebar && detailData && (
        <SidebarBehaviour
          behaviourData={detailData}
          cultureCount={totalCulture}
          performanceCount={totalPerformance}
          type={currentTab}
          closeSidebar={closeSidebar}
          userId={userId}
          pagination={paginationData}
          appendData={appendData}
        />
      )}
      {match && <SidebarMyGoals />}
    </div>
  );
};

export default SkillValueComponentTab;

import React, { useState } from 'react';

import InputSectionName from 'components/project/detail/InputSectionName';
import PhaseOptions from 'components/project/detail/PhaseOptions';
import SectionOptions from 'components/project/detail/SectionOptions';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';

const TimelineSectionName = ({
  section,
  showTaskToggle,
  showTask,
  group,
  sectionIndex,
  isProject,
  projectId,
  setShowModalDeleteSection,
  openAddTop
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);

  return (
    <>
      <div
        className="flex items-center cursor-pointer"
        onClick={showTaskToggle}
      >
        <SVGIcon
          id={`icon-accordion-${section.id}`}
          size="24"
          fillColor="var(--n-600)"
          iconName={`${
            !showTask ? 'icon-keyboard_arrow_right' : 'icon-keyboard_arrow_down'
          }`}
        />
        {isRenaming ? (
          <InputSectionName
            sectionIndex={sectionIndex}
            projectId={projectId}
            isShowing={isRenaming}
            setIsShowing={setIsRenaming}
            isCreateNew={false}
            customInputClass="h-[24px] typography-h200 text-n-600 min-w-[240px] uppercase"
            useAutoGrowInput={false}
          />
        ) : (
          <div className="max-w-[260px]">
            <TruncateParagraph className="typography-h200 text-n-600 ml-[8px] uppercase">
              {section.name}
            </TruncateParagraph>
          </div>
        )}
      </div>
      <div className="flex">
        <SVGIcon
          id={`icon-add-task-top-${group.id}`}
          onClick={openAddTop}
          size={24}
          fillColor="var(--n-600)"
          customClass={group != 'priority' ? 'mr-[8px]' : ''}
          iconName="icon-add"
        />
        {isProject && group === 'section' && (
          <SectionOptions
            projectId={projectId}
            sectionIndex={sectionIndex}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            setIsRenaming={setIsRenaming}
            setShowModalDeleteSection={setShowModalDeleteSection}
            isCanMove={false}
            isCanAdd={false}
            isItemFixed
          />
        )}
        {isProject && group === 'phase' && (
          <PhaseOptions
            projectId={projectId}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
          />
        )}
      </div>
    </>
  );
};

const GroupSection = (
  // props
  {
    data,
    showTaskToggle,
    showChild,
    group,
    index,
    isProject,
    projectId,
    setCurrentSectionIndex,
    setShowModalDeleteSection,
    openAddTop
  }
) => {
  return (
    <div className="h-[24px] px-[16px] tm-group flex items-center justify-between w-[360px]">
      <TimelineSectionName
        section={data}
        showTaskToggle={showTaskToggle}
        showTask={showChild}
        group={group}
        sectionIndex={index}
        isProject={isProject}
        projectId={projectId}
        setCurrentSectionIndex={setCurrentSectionIndex}
        setShowModalDeleteSection={setShowModalDeleteSection}
        openAddTop={openAddTop}
      />
    </div>
  );
};

export default GroupSection;

const getNewInvolvements = (
  involvements,
  type,
  autoSetManager = true,
  objectiveValue,
  params,
  extendedRoleType = null,
  checkExtendedRole = false
) => {
  const involveId = involvements?.map(({ userId }) => userId);

  let newInvolvements = objectiveValue.involvements.filter(
    ({ role, extendedRole = null }) =>
      checkExtendedRole
        ? role !== type || extendedRole !== extendedRoleType
        : role !== type
  );
  newInvolvements = newInvolvements.filter(
    ({ userId }) => !involveId?.includes(userId)
  );
  newInvolvements.push(...involvements);

  if (type == 'assignee' && params?.type !== 'team' && autoSetManager) {
    if (
      involvements.length > 0 &&
      involvements[0]?.manager &&
      involvements[0]?.manager?.id
    ) {
      let newManager = [
        {
          user: involvements[0].manager,
          role: 'assigner',
          userId: involvements[0].manager.id,
          visible: true
        }
      ];
      newInvolvements = newInvolvements.filter(
        ({ role, userId }) =>
          role !== 'assigner' && userId !== newManager[0].userId
      );
      newInvolvements.push(...newManager);
    } else {
      newInvolvements = newInvolvements.filter(
        (involvement) => involvement.role !== 'assigner'
      );
    }
  }

  const result = newInvolvements.map((involvement) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { manager, ...involvementData } = involvement;
    return involvementData;
  });

  return result;
};

const getUserId = (data) => {
  return data?.map(({ userId }) => userId);
};

export { getNewInvolvements, getUserId };

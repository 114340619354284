import React, { useEffect, useState } from 'react';

function StrengthComponent(props) {
  const employeeStrength = () => {
    let dataSkills = [];
    let i;

    for (i = 0; i < 3; i++) {
      if (props.skills[i] === undefined) {
        dataSkills.push([]);
      } else {
        dataSkills.push(props.skills[i]);
      }
    }

    return (
      <span>
        <div className="strength-title">Strength</div>
        {props.skills.length != 0 ? (
          dataSkills.map((index, key) => {
            if (dataSkills[index].length !== 0) {
              return (
                <div className="search-result-container-list" key={key}>
                  {dataSkills[index].strengthCount && (
                    <div className="strength-value">
                      {dataSkills[index].strengthCount}
                    </div>
                  )}
                  <div className="strength-info">
                    <div className="strength-name">
                      {dataSkills[index].skill}
                    </div>
                    <div className="strength-report">
                      Sent by {dataSkills[index].fromCount} people
                    </div>
                  </div>
                </div>
              );
            } else {
              return '';
            }
          })
        ) : (
          <div className="col-xs-12">
            <div className="no-strength">
              <div>this user has no strength</div>
            </div>
          </div>
        )}
      </span>
    );
  };

  const topFiveStrength = () => {
    return (
      <span>
        {props.strengthSkills.length > 0 &&
          props.strengthSkills.map((value, index) => {
            return (
              <div className="search-result-container-list" key={index}>
                {value.strength_count && (
                  <div className="strength-value">{value.strength_count}</div>
                )}
                <div className="strength-info">
                  <div className="strength-name">{value.skill}</div>
                  <div className="strength-report">
                    Sent by {value.from_count} people
                  </div>
                </div>
              </div>
            );
          })}
      </span>
    );
  };

  const topFiveWeakness = () => {
    return (
      <span>
        {props.weaknessSkills.length > 0 &&
          props.weaknessSkills.map((value, index) => {
            return (
              <div className="search-result-container-list" key={index}>
                {value.weakness_count && (
                  <div className="weakness-value">{value.weakness_count}</div>
                )}
                <div className="strength-info">
                  <div className="strength-name">{value.skill}</div>
                  <div className="strength-report">
                    Sent by {value.from_count} people
                  </div>
                </div>
              </div>
            );
          })}
      </span>
    );
  };

  if (props.type === 'employeeStrength') {
    return employeeStrength();
  } else if (props.type === 'topFiveStrength') {
    return topFiveStrength();
  } else if (props.type === 'topFiveWeakness') {
    return topFiveWeakness();
  }
}

export default StrengthComponent;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import Highcharts from 'highcharts';
// Expects that Highcharts was loaded in the code.
import HighchartsReact from 'highcharts-react-official';

import { getActivationTrend } from 'client/admin/Overview';
import {
  getDateLocale,
  getNumberLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';

import LoadingComponent from 'components/shared/LoadingComponent';

function ActivationComponent() {
  const [todaysDate, setTodaysDate] = useState(
    dayjs().subtract(1, 'days').format('D MMMM YYYY').toString()
  );
  const [chartActivationTrend, setChartActivationTrend] = useState({});
  const [signUpPercentage, setSignUpPercentage] = useState(0);
  const [notYetSignUpPercentage, setNotYetSignUpPercentage] = useState(0);
  const [totalDataRegisteredUser, setTotalDataRegisteredUser] = useState(0);
  const [totalDataActiveUser, setTotalDataActiveUser] = useState(0);
  const [totalDataInactiveUser, setTotalDataInactiveUser] = useState(0);
  const [isLoading, setisLoading] = useState(true);

  let startTime;

  let seriesActivationTrend = [
    {
      name: 'registration',
      data: [],
      color: '#b7429e'
    },
    {
      name: 'activation',
      data: [],
      color: '#75cd51'
    }
  ];

  let totalActiveUser;
  let totalRegisteredUser;
  let totalInactiveUser;

  const _getActivationTrend = async () => {
    const { data, error } = await getActivationTrend();
    if (data) {
      setisLoading(false);
      let lastTotalRegistered = data.registration.length - 1;
      totalRegisteredUser = data.registration[lastTotalRegistered].total;

      let lastTotalActive = data.activation.length - 1;
      totalActiveUser = data.activation[lastTotalActive].total;

      totalInactiveUser = totalRegisteredUser - totalActiveUser;

      let signUpPercentage = (totalActiveUser / totalRegisteredUser) * 100;
      let notYetSignUpPercentage = 100 - signUpPercentage;

      setSignUpPercentage(Math.round(signUpPercentage));
      setNotYetSignUpPercentage(Math.round(notYetSignUpPercentage));

      data.activation.map((data) => {
        let date = [];
        date = data.date.split('-');
        let patt = [];

        patt.push(
          Date.UTC(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]))
        );

        patt.push(data.total);
        startTime = new Date(data.date).getTime();

        seriesActivationTrend[1].data.push({
          x: startTime,
          y: patt[1],
          timeFrame: patt[0]
        });
      });

      data.registration.map((data) => {
        let date = [];
        date = data.date.split('-');
        let patt = [];

        patt.push(
          Date.UTC(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]))
        );
        patt.push(data.total);
        startTime = new Date(data.date).getTime();
        seriesActivationTrend[0].data.push({
          x: startTime,
          y: patt[1],
          timeFrame: patt[0]
        });
      });

      let lastArrayRegistration = data.registration.length - 1;
      let lastArrayActivation = data.activation.length - 1;

      if (
        data.activation[lastArrayActivation].date >
        data.registration[lastArrayRegistration].date
      ) {
        let patt = [];
        let date = [];
        let lastArrayRegistration = data.registration.length - 1;
        let lastArrayActivation = data.activation.length - 1;
        date = data.activation[lastArrayActivation].date.split('-');

        patt.push(
          Date.UTC(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]))
        );
        patt.push(data.registration[lastArrayRegistration].total);
        startTime = new Date(
          data.activation[lastArrayActivation].date
        ).getTime();

        seriesActivationTrend[0].data.push({
          x: startTime,
          y: patt[1],
          timeFrame: patt[0]
        });
      } else {
        let patt = [];
        let date = [];
        let lastArrayRegistration = data.registration.length - 1;
        let lastArrayActivation = data.activation.length - 1;
        date = data.registration[lastArrayRegistration].date.split('-');

        patt.push(
          Date.UTC(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]))
        );
        patt.push(data.activation[lastArrayActivation].total);
        startTime = new Date(
          data.registration[lastArrayRegistration].date
        ).getTime();

        seriesActivationTrend[1].data.push({
          x: startTime,
          y: patt[1],
          timeFrame: patt[0]
        });
      }

      setTotalDataActiveUser(totalActiveUser);
      setTotalDataInactiveUser(totalInactiveUser);
      setTotalDataRegisteredUser(totalRegisteredUser);

      setChartActivationTrend({
        chart: {
          style: {
            fontFamily: 'Inter'
          },
          type: 'areaspline',
          backgroundColor: 'var(--n-200)',
          marginTop: 50,
          overflow: 'hidden'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: '%e. %b',
            year: '%b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          min: 0
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.x:%e %b}: <b>{point.y:f} people</b>'
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: true
            }
          }
        },
        credits: {
          enabled: false
        },
        series: seriesActivationTrend,
        exporting: {
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    _getActivationTrend();
  }, []);

  return (
    <div className=" row">
      <div className="col-xs-8">
        <div className="card">
          <div className="card-body">
            <h4 className="body-header">
              ACTIVATION TREND<span className="description"></span>
            </h4>
            <div className="chart-box">
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartActivationTrend}
                ></HighchartsReact>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-4">
        <div className="card">
          <div className="card-body">
            <h4 className="body-header">
              {getObjectiveLocale('ACTIVATION SUMMARY')}
              <span className="description">{getDateLocale(todaysDate)}</span>
            </h4>
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <div>
                <div className="row">
                  <div className="col-xs-12">
                    <span className="number-first">
                      {getNumberLocale(totalDataRegisteredUser)}
                    </span>
                    <span className="info-first">
                      {getObjectiveLocale('People Registered')}
                    </span>
                    <Link to="/users">{getObjectiveLocale('See Details')}</Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6">
                    <span className="number">
                      {getNumberLocale(totalDataActiveUser)}
                      <span className="small">
                        ({getNumberLocale(signUpPercentage)}%)
                      </span>
                    </span>
                    <span className="info">
                      {getObjectiveLocale('Sign Up')}
                    </span>
                    <Link to="/users?filter=signup">
                      {getObjectiveLocale('See Details')}
                    </Link>
                  </div>
                  <div className="col-xs-6">
                    <span className="number">
                      {getNumberLocale(totalDataInactiveUser)}
                      <span className="small">
                        ({getNumberLocale(notYetSignUpPercentage)}%)
                      </span>
                    </span>
                    <span className="info">
                      {getObjectiveLocale("Haven't Sign Up")}
                    </span>
                    <Link to="/users?filter=not-yet-sign-up">
                      {getObjectiveLocale('See Details')}
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivationComponent;

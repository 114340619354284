import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import Checkbox from 'components/shared/Checkbox';
import List from 'components/shared/List';
import SearchBar from 'components/shared/SearchBar';

function InlineDialogSelectGroup({
  listOfGroups,
  selectedValue,
  handleChange,
  handleCheckedAll,
  checkedAll,
  isGroup,
  setSearchGroup,
  label
}) {
  const [searchValue, setSearchValue] = useState('');
  listOfGroups.length > 1 &&
    listOfGroups.sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchGroup(e.target.value);
  };

  return (
    <div
      className="inline-dialog-select-group"
      data-cy="inline-dialog"
      style={isGroup && { width: '280px' }}
    >
      {isGroup && (
        <div className="header-inline">
          {label && (
            <div className="h-[32px] typography-h100 text-n-600">{label}</div>
          )}
          <div>
            <SearchBar
              fullWidth={true}
              placeholder="Search group"
              onKeyDown={(e) => handleChangeSearch(e)}
            />
          </div>
        </div>
      )}
      {listOfGroups.length > 0 ? (
        <div className={`body-inline ${isGroup ? 'mt-[14px]' : 'mt-[0px]'}`}>
          <div className="list-inline">
            <ul className="dropdown-ui">
              <li className="dropdown-li">
                {isGroup && isEmpty(searchValue) && (
                  <List className="dropdown-list">
                    <Checkbox
                      id="check-all-group"
                      onChange={(e) =>
                        handleCheckedAll(e.currentTarget.checked)
                      }
                      checked={checkedAll}
                      dataCy="check-all-group"
                    >
                      <p className="typography-paragraph my-[0px]">
                        All Groups
                      </p>
                    </Checkbox>
                  </List>
                )}
              </li>
              {listOfGroups.map((sel) => {
                let idSelectedValue =
                  selectedValue.length > 0
                    ? selectedValue?.map(({ id }) => id)
                    : [];
                return (
                  <li className="dropdown-li" key={sel.id}>
                    <List className="dropdown-list">
                      <Checkbox
                        id={`check-group-${sel.id}`}
                        onChange={() => handleChange(sel, selectedValue)}
                        checked={idSelectedValue.includes(sel.id)}
                        dataCy="check-group"
                      >
                        <p className="typography-paragraph my-[0px]">
                          {sel.name}
                        </p>
                      </Checkbox>
                    </List>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        <div className="text-center">No group available</div>
      )}
    </div>
  );
}

export default InlineDialogSelectGroup;

import client from 'client/ApiClient';

function getListReviewGroup(query) {
  return client(`admin/groups`, { params: query });
}

function getOneReviewGroup(groupId, query = {}) {
  return client(`admin/groups/${groupId}`, { params: query });
}

function getCustomAttributeList(groupId, query = {}) {
  return client(`admin/groups/${groupId}/review_aspects`, { params: query });
}

function getGroupMembers(groupId) {
  return client(`admin/groups/${groupId}/members`);
}

function getGroupBehaviorWeights(params) {
  return client(`admin/behavior_weights`, { params });
}

function postListReviewGroup(body = {}) {
  return client(`admin/groups`, { method: 'POST', body });
}

function putListReviewGroup(groupId, body) {
  return client(`admin/groups/${groupId}`, { method: 'PUT', body });
}

function postCustomAttribute(groupId, body) {
  return client(`admin/groups/${groupId}/review_aspects`, {
    method: 'POST',
    body
  });
}

function putCustomAttribute(groupId, body) {
  return client(`admin/groups/${groupId}/review_aspects`, {
    method: 'PUT',
    body
  });
}

function patchListReviewGroup(groupId, data = {}) {
  return client(`admin/groups/${groupId}/change_state`, {
    method: 'PATCH',
    body: data
  });
}

function deleteListReviewGroup(groupId) {
  return client(`admin/groups/${groupId}`, { method: 'DELETE', body: {} });
}

export {
  getListReviewGroup,
  getOneReviewGroup,
  getGroupMembers,
  postListReviewGroup,
  putListReviewGroup,
  patchListReviewGroup,
  deleteListReviewGroup,
  getGroupBehaviorWeights,
  getCustomAttributeList,
  postCustomAttribute,
  putCustomAttribute
};

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

function Position({ lists, defaultValue, onChange }) {
  const selectedValueName =
    lists.find((list) => list.id === defaultValue)?.name || lists[0].name;

  return (
    <div className="ml-[8px]">
      <Dropdown>
        <Dropdown.Trigger dataCy="dropdown-position">
          <div className="flex justify-between items-center bg-n-000 h-[32px] py-[8px] pr-[4px] pl-[16px] w-[224px] border border-solid border-n-400 rounded-[4px]">
            <div className="flex items-center">
              <p className="mr-[4px] typography-h400">
                {getObjectiveLocale('Position')}:
              </p>
              <p className="typography-paragraph truncate w-[85px]">
                {getObjectiveLocale(selectedValueName)}
              </p>
            </div>
            <SVGIcon
              size="24"
              fillColor="var(--n-600)"
              iconName="icon-unfold_more"
            />
          </div>
        </Dropdown.Trigger>
        <Dropdown.MenuItems customClass="w-full">
          {lists
            .filter((list) => list.visible)
            .map((group, index) => (
              <Dropdown.MenuItem
                dataCy={group.name}
                key={index}
                onClick={() => onChange(group.id)}
              >
                {getObjectiveLocale(group.name)}
              </Dropdown.MenuItem>
            ))}
        </Dropdown.MenuItems>
      </Dropdown>
    </div>
  );
}

export default Position;

import React from 'react';

import { getCultureLocale } from 'utils/HelperUtils';

import InputField from 'components/shared/InputField';

function MissionName({ mission, setMission, isReadOnly, haveSubmitted }) {
  return (
    <div id="name-section" className="form-group border-bottom-400 pb-[40px]">
      <label
        htmlFor="missionName"
        className="typography-h500-longform text-n-900 mb-[4px]"
      >
        {getCultureLocale('Give it a name')}
      </label>
      <p className="typography-paragraph typography-secondary mb-[16px]">
        {getCultureLocale("Something that's clear and easily recognizable.")}
      </p>
      <p className="typography-h100 typography-secondary mb-[8px]">
        {getCultureLocale('Mission Name')}
      </p>
      <InputField
        htmlFor="orgName"
        value={mission.name}
        placeholder="Type here..."
        disabled={mission?.status == 'active' || isReadOnly}
        handleChange={(e) =>
          setMission({
            ...mission,
            name: e.currentTarget.value
          })
        }
        dataCy="mission-name"
        inputClass="px-[16px] py-[4px]"
      />
      {haveSubmitted && !mission.name && (
        <p
          className="relative text-r-600 typography-h100 mt-[8px]"
          data-cy="error-name"
        >
          {getCultureLocale('This cannot be blank.')}
        </p>
      )}
    </div>
  );
}

export default MissionName;

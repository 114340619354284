import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { getDurationDays } from 'src/utils/ObjectivesHelper';

import lineDependencyCreation from './lineDependencyCreation';

const useBarPositioning = ({
  startDate,
  dueDate,
  periodStart,
  columnWidth
}) => {
  const [positionLeft, setPositionLeft] = useState(0);
  const [widthElement, setWidthElement] = useState(0);

  useEffect(() => {
    const startDateConverted = new Date(startDate);
    const endDate = new Date(dueDate);
    const duration = getDurationDays(startDateConverted, endDate);

    const width = duration * columnWidth;

    const durationToperiodStart = getDurationDays(
      periodStart,
      startDateConverted
    );

    if (endDate < periodStart) {
      setWidthElement(0);
      setPositionLeft(-100);
      return;
    }

    if (startDateConverted > periodStart) {
      const left = durationToperiodStart * columnWidth;
      setPositionLeft(left);
      setWidthElement(width);
      return;
    }
    if (startDateConverted < periodStart) {
      const left = durationToperiodStart * -1 * columnWidth;
      setPositionLeft(left);
      setWidthElement(width);
      return;
    }
  }, [startDate, dueDate, periodStart, columnWidth]);

  return { positionLeft, widthElement, setPositionLeft };
};

const useObserveChildToggle = ({
  effectDependencies = [],
  observeElementId
}) => {
  const [childLoading, setChildLoading] = useState(false);
  const [showChild, setShowChild] = useState(false);

  const designatedDependencies = [...effectDependencies, observeElementId];

  useEffect(() => {
    const taskNameElement = document.getElementById(observeElementId);
    if (!taskNameElement) return;
    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach(function (mutation) {
        if (mutation.type != 'attributes') return;
        if (mutation.attributeName != 'class') return;
        if (!mutation.target.classList.contains('show-child')) {
          setShowChild(false);
          return;
        }
        setShowChild(true);
        if (!mutation.target.classList.contains('child-loading')) {
          setChildLoading(false);
          return;
        }
        setChildLoading(true);
      });
    });

    observer.observe(taskNameElement, { attributes: true, childList: true });
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, designatedDependencies);

  return { showChild, childLoading };
};

const useDrawDependencyLine = ({
  dataObjective,
  positionLeft,
  effectDependencies = []
}) => {
  const designatedDependencies = [
    ...effectDependencies,
    dataObjective,
    positionLeft
  ];

  useEffect(() => {
    const timelineContent = document.getElementsByClassName('tm-content')[0];
    if (dataObjective.blockedBy?.length == 0) return;

    lineDependencyCreation(dataObjective, positionLeft);
    const observer = new MutationObserver(async (mutationList) => {
      mutationList.forEach(function (mutation) {
        if (mutation.type != 'childList') return;
        lineDependencyCreation(dataObjective, positionLeft);
      });
    });
    observer.observe(timelineContent, { childList: true, subtree: true });

    return () => {
      if (dataObjective.blockedBy?.length == 0) return;
      dataObjective?.blockedBy?.map((dependantTask) => {
        const currentDependencyElement = document.getElementById(
          `dep-${dataObjective?.id}-${dependantTask?.id}`
        );

        if (currentDependencyElement) {
          currentDependencyElement.remove();
        }
      });
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, designatedDependencies);
};

const generateGhostBar = ({ barRef, dataObjective, e }) => {
  const ghostElement = barRef.current.cloneNode(true);
  ghostElement.id = 'bar-ghost' + dataObjective?.id;
  ghostElement.classList.remove('z-[1]');
  ghostElement.style.zIndex = 3;

  barRef.current.classList.add('opacity-50');

  const tooltipElement = document.createElement('p');
  tooltipElement.classList.add(
    'absolute',
    'py-[4px]',
    'px-[8px]',
    'bg-n-900',
    'text-n-000',
    'rounded-lg',
    'z-9999999',
    'pointer-events-non',
    'whitespace-nowrap',
    'typography-h100'
  );
  tooltipElement.id = 'bar-ghost-tooltip' + dataObjective?.id;
  tooltipElement.style.top = '-30px';
  tooltipElement.style.left = e.offsetX + 'px';
  tooltipElement.innerHTML =
    dayjs(dataObjective.startDate).format('DD MMM YYYY') +
    ' - ' +
    dayjs(dataObjective.dueDate).format('DD MMM YYYY');
  ghostElement.firstChild.appendChild(tooltipElement);

  const bgRow = document.getElementById(`bg-row-${dataObjective?.id}`);
  bgRow.appendChild(ghostElement);
};

export {
  useBarPositioning,
  useObserveChildToggle,
  useDrawDependencyLine,
  generateGhostBar
};

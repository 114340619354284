import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import CustomInnerIcon from 'components/design-system/CustomInnerIcon';
import Divider from 'components/design-system/Divider';
import SVGIcon from 'components/shared/SVGIcon';

const HighlightAbout = () => {
  return (
    <>
      <HeaderPage backToUrl={`/highlighted`} isHeaderComposer />
      <div className="px-[72px] pt-[24px] bg-n-000 h-full">
        <p className="typography-h800 text-n-900">
          {getObjectiveLocale('About Highlight')}
        </p>
        <p className="typography-paragraph text-n-800 mt-[8px]">
          {getObjectiveLocale(
            `You can now highlight any ${getObjectiveLocale(
              'Goals'
            ).toLowerCase()} that you consider important. Simply click on the bookmark icon and the ${getObjectiveLocale(
              'Goal'
            ).toLowerCase()} will be added to your highlight list.`
          )}{' '}
        </p>
        <Divider customClass="my-[40px]" />
        <p className="typography-h500 text-n-900">
          {getObjectiveLocale('What are the benefits of this feature?')}
        </p>
        <div className="flex flex-row items-center mt-[40px]">
          <CustomInnerIcon
            size={40}
            iconName="icon-person"
            iconColor="var(--n-600)"
            backgroundColor="var(--n-100)"
            borderColor="var(--n-100)"
          />
          <div className="flex flex-col ml-[24px]">
            <p className="typography-h600 text-n-900">
              {getObjectiveLocale('To Leader')}
            </p>
            <p className="typography-paragraph text-n-800">
              {getObjectiveLocale(
                'Monitor your subordinates goals update into your Highlight page'
              ).replace('goals', getObjectiveLocale('Goals').toLowerCase())}
            </p>
          </div>
        </div>
        <Divider customClass="my-[40px]" dividerColor="bg-n-300" />
        <div className="flex flex-row items-center">
          <SVGIcon
            iconName="icon-star_outline"
            fillColor="var(--n-600)"
            size={40}
          />
          <div className="flex flex-col ml-[24px]">
            <p className="typography-h600 text-n-900">
              {getObjectiveLocale('Mark as important')}
            </p>
            <p className="typography-paragraph text-n-800">
              {getObjectiveLocale(
                'Highlighting the goals mean you can mark them as important.'
              ).replace(
                'goals',
                getObjectiveLocale('Goals').toLowerCase()
              )}{' '}
            </p>
          </div>
        </div>
        <Divider customClass="my-[40px]" dividerColor="bg-n-300" />
        <div className="flex flex-row items-center">
          <SVGIcon
            iconName="icon-arrow_back"
            fillColor="var(--n-600)"
            size={40}
          />
          <div className="flex flex-col ml-[24px]">
            <p className="typography-h600 text-n-900">
              {getObjectiveLocale('Easy reversal')}
            </p>
            <p className="typography-paragraph text-n-800">
              {getObjectiveLocale(
                'Once you bookmark the goals, they will appear on your Highlights page. This helps you remember to look at them later.'
              ).replace('goals', getObjectiveLocale('Goals').toLowerCase())}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HighlightAbout;

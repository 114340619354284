import React from 'react';

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilterObjectives from 'components/advance-filter/AdvancedFilterObjectives';
import Objectives from 'components/objectives/Objectives';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

dayjs.extend(quarterOfYear);

function Explorer({ route: { title } }) {
  const { match } = useUrl();
  const { user, config, organization } = useUser();
  document.getElementsByTagName('body')[0].classList = 'scroll-locked';
  let template = ['space', 'filter', 'analytics', 'objectives'];
  let gridTemplate = "'" + template.join("' '") + "'";
  const { newObjectiveLayout } = config;

  let filter = {
    owner: {
      ownerType: 'subordinates'
    },
    switch: 'all',
    periodBegin: dayjs()
      .quarter(dayjs().quarter())
      .startOf('quarter')
      .toISOString(),
    periodEndBefore: dayjs()
      .quarter(dayjs().quarter())
      .endOf('quarter')
      .toISOString(),
    sortDirection: 'asc',
    sortColumn: 'due_date'
  };

  // This block of code below, supposed to be a temporary solution (for BRI Renewal)
  if (
    user.email == 'indra.utoyo@bri.co.id' &&
    organization.identifier == 'bri'
  ) {
    filter['label'] = ['MSI'];
  }
  // end of block

  return (
    <>
      <HeaderPage titlePage={title} />
      <div
        className="grid gap-[24px] mb-[64px]"
        style={{ gridTemplateAreas: gridTemplate }}
      >
        <div style={{ gridArea: 'space' }}></div>
        <AdvancedFilterObjectives
          filterOptions={[
            'search',
            'group',
            'sort',
            'dateFilter',
            'menuFilter',
            'switch'
          ]}
          group={'no-group'}
          gridArea
          defaultFilter={filter}
          type="explorer"
          page="explorer"
          filterId="explorerFilter"
        >
          <Objectives
            cardModel={'goal-list'}
            group={'no-group'}
            page={'explorer'}
            gridArea
            withMarginTop
            bonusFilter={{
              state: config.defaultFilterPhase,
              ownerType: config.defaultFilterOwner
            }}
          />
        </AdvancedFilterObjectives>
        <React.Suspense fallback={<div></div>}>
          {match && <SidebarMyGoals />}
        </React.Suspense>
      </div>
    </>
  );
}

export default Explorer;

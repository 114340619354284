import React, { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';

import { ColleagueProvider } from 'context/ColleagueContext';
import { useUser } from 'context/UserContext';
import usePermission from 'hooks/usePermission';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Collagues from 'components/header/SearchUserComponent';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import ModalSidebar from 'components/shared/modal/ModalSidebar';

const updateManageableStatus = async (
  e,
  manageableStatus,
  updateWorkloadStatus,
  userId,
  setManageableStatus
) => {
  e.preventDefault();
  const newStatus =
    manageableStatus === 'manageable' ? 'unmanageable' : 'manageable';
  const body = { workloadStatus: newStatus };
  const { data } = await updateWorkloadStatus(body, userId);
  if (data) {
    setManageableStatus(data.workloadStatus);
  }
};

const ModalSwitchApp = ({
  setIsSwitchOpen,
  isAppCulture,
  organization,
  customClass
}) => {
  const { user, host, cultureHost } = useUser();

  const switchApp = (nextApp, setIsSwitchOpen) => {
    const url = nextApp === 'performance' ? host : cultureHost;
    setIsSwitchOpen(false);
    window.open(`https://${url}`, nextApp);
  };

  return (
    <ModalSidebar
      title="Switch to"
      customClass={`pb-[8px] ${customClass ? customClass : ''}`}
    >
      {isAppCulture && user.role && (
        <div
          className="option h-[56px]"
          onClick={() => switchApp('performance', setIsSwitchOpen)}
        >
          <AWSImage
            className="w-[32px] h-[32px] mr-[16px] icon-contain"
            src={organization.logoUrl}
            onErrorImage={Config.asset.culture.brokenImage}
          />
          <div>
            <div className="typography-h400 title-option multiline-text-1">
              {organization.appName}
            </div>
            <div className="typography-h100 desc-option">
              {getObjectiveLocale('Performance Management')}
            </div>
          </div>
        </div>
      )}
      {!isAppCulture && user.cultureRole && (
        <div
          className="option h-[56px]"
          onClick={() => switchApp('culture', setIsSwitchOpen)}
        >
          <AWSImage
            className="w-[32px] h-[32px] mr-[16px] icon-contain"
            src={organization.cultureLogoUrl}
            onErrorImage={Config.asset.culture.brokenImage}
          />
          <div>
            <div className="typography-h400 title-option multiline-text-1">
              {organization.cultureAppName}
            </div>
            <div className="typography-h100 desc-option">
              {getObjectiveLocale('Culture Transformation')}
            </div>
          </div>
        </div>
      )}
    </ModalSidebar>
  );
};

const ModalProfile = ({
  setIsProfileOpen,
  manageableStatus,
  isProfileOpen,
  logout,
  updateWorkloadStatus,
  setManageableStatus,
  setModalChangePassword,
  customClass,
  top
}) => {
  const history = useHistory();
  const { user, appType, config, organization } = useUser();

  return (
    <ModalSidebar customClass={`py-[8px] ${customClass}`} top={top}>
      {usePermission('profileSee') && (
        <>
          <div
            className="flex text-n-900 typography-button option h-[32px]"
            onClick={() => {
              history.replace(
                `/users/${user.id}/placement/${user.placementId}`
              );
              setIsProfileOpen(false);
            }}
          >
            <SVGIcon
              customClass="mr-[8px]"
              size="24"
              fillColor="var(--n-600)"
              iconName="icon-account_circle"
            />
            {getObjectiveLocale('My Profile Page')}
          </div>
          <div className="horizontal-divider my-[4px]" />
        </>
      )}
      {config?.profileShowWorkloadStatus && (
        <div className="option h-[32px] space-between items-center">
          {manageableStatus === 'manageable' ? (
            <div className="flex text-n-900 S800">
              <SVGIcon
                customClass="mr-[8px]"
                size="24"
                fillColor="var(--g-500)"
                iconName="icon-sentiment_very_satisfied"
              />
              {getObjectiveLocale('Manageable')}
            </div>
          ) : (
            <div className="flex text-n-900 S800">
              <SVGIcon
                customClass="mr-[8px]"
                size="24"
                fillColor="var(--r-500)"
                iconName="icon-sentiment_very_dissatisfied"
              />
              {getObjectiveLocale('Unmanageable')}
            </div>
          )}
          <SmallToggleSwitchPurple
            isChecked={manageableStatus === 'manageable' ? true : false}
            onChange={(e) =>
              updateManageableStatus(
                e,
                manageableStatus,
                updateWorkloadStatus,
                user.id,
                setManageableStatus
              )
            }
            addClass="h-[16px]"
          />
        </div>
      )}
      {(appType === 'performance'
        ? config?.showDownloadApp
        : config?.showDownloadAppCulture) && (
        <div
          className="option h-[32px] text-n-900 S800"
          onClick={() => {
            history.push('/downloads');
            setIsProfileOpen(!isProfileOpen);
          }}
        >
          <SVGIcon
            customClass="mr-[8px]"
            size="24"
            fillColor="var(--n-600)"
            iconName="icon-file_download"
          />
          {getObjectiveLocale('Download App')}
        </div>
      )}
      {config?.changePasswordAllowed && (
        <>
          <div
            className="option h-[32px] text-n-900 S800"
            onClick={setModalChangePassword}
          >
            <SVGIcon
              customClass="mr-[8px]"
              size="24"
              fillColor="var(--n-600)"
              iconName="icon-lock_outline"
            />
            {getObjectiveLocale('Change Password')}
          </div>
        </>
      )}
      {process.env.ENV_VERSION && (
        <div className="option h-[32px] text-n-600 S800">
          {process.env.ENV_VERSION}
        </div>
      )}
      <div className="horizontal-divider my-[4px]" />
      <div className="option h-[32px] text-n-900 S800" onClick={logout}>
        <SVGIcon
          customClass="mr-[8px]"
          size="24"
          fillColor="var(--n-600)"
          iconName="icon-exit_to_app"
          onClick={() => {
            setIsProfileOpen(!isProfileOpen);
          }}
        />
        {getObjectiveLocale('Sign Out')}
      </div>
    </ModalSidebar>
  );
};

const ModalColleague = forwardRef(({ onSearchCollagues, top, left }, ref) => {
  const history = useHistory();
  return (
    <ColleagueProvider>
      <div ref={ref} className="modal-sidebar" style={{ top: top, left: left }}>
        <Collagues
          onClick={(userData) => {
            history.replace(
              `/users/${userData.id}/placement/${userData?.placementId}`
            );
            onSearchCollagues(false);
          }}
        />
      </div>
    </ColleagueProvider>
  );
});

ModalColleague.displayName = 'ModalColleague';

const AppLogo = ({ isAppCulture }) => {
  const { organization } = useUser();
  return (
    <div className="wrapper-logo w-[32px] h-[32px] px-[2px] py-[2px] bg-n-000 rounded-[4px]">
      <AWSImage
        src={isAppCulture ? organization.cultureLogoUrl : organization.logoUrl}
        alt="logo"
        className="logo w-[28px] h-[28px]"
        onErrorImage={
          organization?.identifier == 'bca'
            ? Config.asset.general.logoMyGrowth
            : Config.asset.general.logoHappy5
        }
      />
    </div>
  );
};

export { ModalSwitchApp, ModalProfile, ModalColleague, AppLogo };

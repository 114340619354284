
const clearInputElements = (array, idPrefix, key) => {
  for (let i = 2; i < array.length; i++){
    let input = document.getElementById(idPrefix + i);
    input.value = "";
    array[i - 1][key] = ""
  }
}

export {
  clearInputElements
}
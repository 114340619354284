import React, { useState } from 'react';

import dayjs from 'dayjs';

import { phaseName } from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ModalTrackDetail from '../components/ModalTrackDetail';
import VerifySection from './VerifySection';

const PhaseVerify = ({ cycleData }) => {
  const [Modal, setModal] = useState('');

  let startReviewPeriod = dayjs(cycleData.reviewedPeriodStartsAt).format(
    'D MMMM YYYY'
  );
  let endReviewPeriod = dayjs(cycleData.reviewedPeriodEndsAt).format(
    'D MMMM YYYY'
  );
  let nextStartRecurringDate = dayjs(cycleData.reviewedPeriodStartsAt)
    .add(cycleData.recurrence?.interval, 'months')
    .format('D MMM YYYY');
  let nextEndRecurringDate = dayjs(cycleData.reviewedPeriodEndsAt)
    .add(cycleData.recurrence?.interval, 'months')
    .format('D MMM YYYY');

  const phaseContent = (phase) => {
    if (cycleData[phase] === null) {
      return (
        <p className="typography-paragraph typography-tertiary">
          {getObjectiveLocale('Not an active phase')}
        </p>
      );
    } else if (phase === 'peerReview') {
      return (
        <div onClick={() => setModal(phase)}>
          <p className="typography-paragraph text-base-600 cursor-pointer">
            {cycleData[phase]?.trackConfigs?.length}{' '}
            {getObjectiveLocale('tracks')}
          </p>
          <p className="typography-paragraph typography-secondary">
            {getObjectiveLocale('Maximum')} {cycleData.peerReview?.peerCount}{' '}
            {getObjectiveLocale('peers selection')} ·{' '}
            {getObjectiveLocale('selected by')}{' '}
            {cycleData.peerReview?.mode === 'manager_selected'
              ? 'managers'
              : 'reviewees'}
          </p>
          {cycleData?.peerReview?.reviewForEachOther && (
            <p className="typography-paragraph typography-secondary">
              {getObjectiveLocale('Employees reviewing each other')}
            </p>
          )}
        </div>
      );
    } else {
      return (
        <p
          className="typography-paragraph text-base-600 cursor-pointer"
          onClick={() => setModal(phase)}
        >
          {cycleData[phase]?.trackConfigs?.length}{' '}
          {cycleData[phase]?.trackConfigs?.length > 1 ? 'tracks' : 'track'}
        </p>
      );
    }
  };

  let phaseArray = [
    {
      name: 'Review period',
      listClassName: 'h-[56px]',
      content: (
        <div>
          <p className="typography-paragraph">
            {startReviewPeriod} - {endReviewPeriod},{' '}
            {cycleData?.recurrence && (
              <span className="typography-h400">
                {getObjectiveLocale('repeating every')}{' '}
                {cycleData?.recurrence?.interval} {getObjectiveLocale('months')}
              </span>
            )}
          </p>
          {cycleData.recurrence?.interval && (
            <p className="typography-h100 typography-secondary">
              {getObjectiveLocale(
                'Next scoring period to be included will be on'
              )}{' '}
              {nextStartRecurringDate} - {nextEndRecurringDate}
            </p>
          )}
        </div>
      )
    },
    { name: getObjectiveLocale('Self'), content: phaseContent('selfReview') },
    {
      name: getObjectiveLocale('Upward'),
      content: phaseContent('upwardReview')
    },
    {
      name: getObjectiveLocale('Peer'),
      content: phaseContent('peerReview'),
      listClassName: 'h-[56px]'
    },
    {
      name: getObjectiveLocale('Manager'),
      content: phaseContent('managerReview')
    },
    {
      name: getObjectiveLocale('Indirect Manager'),
      content: phaseContent('indirectManagerReview')
    }
  ];

  return (
    <>
      <VerifySection
        title="Phase"
        dataArray={phaseArray}
        className="py-[40px]"
      />
      {Modal && (
        <ModalTrackDetail
          title={phaseName(Modal)}
          trackConfigs={cycleData[Modal].trackConfigs}
          onCloseModal={() => setModal('')}
        />
      )}
    </>
  );
};

export default PhaseVerify;

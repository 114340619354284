import React from 'react';

import ctl from '@netlify/classnames-template-literals';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import Table from 'components/design-system/table/Table';

const TaskSkeleton = ({ tableHeaders }) => {
  const getColumnClass = (index, isLastIndex) => {
    return ctl(`
      !h-[48px] mr-[-1px] mt-[-1px]
      ${
        index == 0
          ? `bg-n-100`
          : `border
        border-solid
        border-n-300
        `
      }
      ${index == 1 && 'rounded-l-[4px] border-l'}
      ${isLastIndex && 'rounded-r-[4px] border-r relative'}
    `);
  };

  return (
    <Table.Row customClass="bg-n-000" useRowHover={false}>
      {tableHeaders?.length > 0 &&
        tableHeaders?.map((header, index) => {
          const isLastIndex = tableHeaders.length - 1 === index;
          return (
            <Table.Column
              customClass={getColumnClass(index, isLastIndex)}
              key={header.name}
            >
              {isLastIndex ? (
                <>
                  <div className="absolute left-[-1px] h-full w-[1px] bg-n-000 z-[21]" />
                  <Shimmer
                    width="100%"
                    height="16px"
                    customClass="rounded-[4px]"
                  />
                </>
              ) : (
                index != 0 && (
                  <Shimmer
                    width="100%"
                    height="16px"
                    customClass="rounded-[4px]"
                  />
                )
              )}
            </Table.Column>
          );
        })}
    </Table.Row>
  );
};

export default TaskSkeleton;

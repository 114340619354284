import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';

import { getObjectiveDetail } from 'client/ObjectivesClient';
import useGoalTree from 'hooks/useGoalTree';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import GoalTreeCard from 'components/objectives/GoalTreeCard';
import ObjectivesModal from 'components/objectives/ObjectivesModal';
import LoadingComponent from 'components/shared/LoadingComponent';
import Zoomer from 'components/shared/Zoomer';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';

function GoalTree() {
  const [objective, setObjective] = useState(null);
  const setTreeObjective = useGoalTree((state) => state.setTreeObjective);

  const { search } = useLocation();
  const { match } = useUrl();
  let params = useParams();

  const getObjectiveParams = () => {
    const params = new URLSearchParams(search);
    const organizationId = params.get('organizationId');
    return organizationId ? { organizationId: parseInt(organizationId) } : {};
  };

  const getObjective = async () => {
    const { data } = await getObjectiveDetail(
      params.parentId,
      getObjectiveParams()
    );
    setObjective(data);
    setTreeObjective(data);
  };

  useEffect(() => {
    getObjective();
  }, []);

  return (
    <>
      <HeaderPage titlePage={`${getObjectiveLocale('Goal')} Tree`} />
      <div>
        {objective ? (
          <>
            <div className="list-view-container tree-container relative">
              <div className="overflow-alignment-tree goal-tree-page">
                <Zoomer />
                <ul className="goal-tree-super-parent pt-[80px]">
                  <GoalTreeCard
                    objective={objective}
                    parentId={objective.parent?.id}
                    key={0}
                  />
                </ul>
              </div>
            </div>
            {match && <SidebarMyGoals />}
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>
      <ObjectivesModal />
    </>
  );
}

export default GoalTree;

import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import * as dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';

import { convertMilestone, getMilestoneDetail } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import { getDateWithForcedTimezone } from 'utils/DateUtils';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { filteredDateMilestone } from 'utils/MilestoneHelper';
import { whichMilestoneMode, whichMonth } from 'utils/ObjectivesHelper';
import { CALC_TYPE_NORMAL } from 'utils/const';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import Loading from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import InputMilestone from 'components/sidebar/InputMilestone';

function MilestoneSection({
  isEdit,
  objectiveValue,
  setObjectiveValue,
  changedMilestones = [],
  setMilestoneDataList,
  isDialog,
  disabled = false,
  listMetrics = []
}) {
  const {
    config: { objectiveMilestoneTypes }
  } = useUser();
  const { params } = useRouteMatch();
  const firstRender = useRef(true);
  const { startDate, dueDate, milestoneType, milestoneMode, measurement } =
    objectiveValue;
  const unitId = measurement ? measurement?.unitId : 0;
  const selectedMetric = listMetrics.find(({ id }) => id === unitId);
  const objStartDate = dayjs(startDate).format('YYYY-MM-DD');
  const objDueDate = dayjs(dueDate).format('YYYY-MM-DD');
  const [isDisabled, setIsDisabled] = useState({
    disabled: false,
    errorWording: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const lessThanWeek =
    dayjs(objectiveValue?.dueDate)?.diff(
      dayjs(objectiveValue?.startDate),
      'days'
    ) < 8;
  const listValue = objectiveMilestoneTypes
    .filter((type) => type != 'disabled')
    .map((type) => ({
      id: type,
      text: startCase(type),
      onClick: () => changeDropdownValue(type)
    }));

  let totalTargetValue = changedMilestones?.reduce((curr, arr) => {
    return curr + (arr?.targetValue || 0);
  }, 0);

  const setUseMilestone = (type, fromDisabled, fromToggle) => {
    setObjectiveValue((draft) => {
      if (fromToggle && !fromDisabled) {
        if (
          confirm(
            getObjectiveLocale('Objective Milestones will be reset. Proceed?')
          )
        ) {
          draft.milestoneType = type;
        }
      } else {
        draft.milestoneType = type;
      }
    });
  };

  const setMilestones = (milestone) => {
    if (params?.type === 'edit' || isDialog) {
      setMilestoneDataList(milestone);
    } else {
      setObjectiveValue((draft) => {
        draft.milestones = milestone;
      });
    }
  };

  const changeDropdownMilestoneMode = (type) => {
    setObjectiveValue((draft) => {
      draft.milestoneMode = type;
    });
  };

  const changeDropdownValue = async (value) => {
    setIsLoading(true);
    setUseMilestone(value);
    setTimeout(() => {
      _convertMilestone(value);
    }, 10);
  };

  const _convertMilestone = async (newMilestoneType) => {
    setIsLoading(true);
    const query = {
      startDate: objectiveValue.startDate,
      dueDate: objectiveValue.dueDate,
      milestoneType: milestoneType,
      milestones: changedMilestones.map((value) => {
        return {
          periodName: value.periodName,
          startsAt: value.startsAt,
          endsBefore: value.endsBefore,
          currentValue: value.currentValue,
          targetValue: value.targetValue
        };
      })
    };
    const { data } = await convertMilestone(newMilestoneType, query);
    setMilestones(data);
    setIsLoading(false);
  };

  const handleInputMilestone = (data, index) => {
    let temp = cloneDeep(changedMilestones);
    temp[index].targetValue = data;
    setMilestones(temp);
  };

  const getDetailData = async () => {
    setIsLoading(true);
    const { data } = await getMilestoneDetail(objectiveValue?.id);
    if (data) {
      filterMilestoneByPeriod(data, setIsLoading);
    }
  };

  const filterMilestoneByPeriod = (milestoneTemp, setLoading, lastValue) => {
    let newMilestones = filteredDateMilestone(
      milestoneTemp,
      objStartDate,
      objDueDate
    );
    newMilestones?.map((data, index) => {
      if (
        lastValue &&
        index == newMilestones.length - 1 &&
        data?.targetValue == 0
      ) {
        newMilestones[index].targetValue = lastValue;
      }
    });
    setTimeout(() => {
      setMilestones(newMilestones);
      setLoading(false);
    }, 400);
  };

  const getError = () => {
    let errorWording;
    if (
      lessThanWeek &&
      objectiveValue?.calculationType?.includes(CALC_TYPE_NORMAL)
    ) {
      errorWording = 'The time period is less than a week.';
    } else if (unitId == 0) {
      errorWording = `No target metric has been set for this ${objectiveValue?.type}`;
    } else if (
      getDateWithForcedTimezone(objectiveValue?.startDate, 'YYYY') !==
      getDateWithForcedTimezone(startDate, 'YYYY')
    ) {
      errorWording = 'The time period is more than a year';
    } else if (!unitId) {
      errorWording = 'Set metric, to enable milestone';
    } else if (
      !objectiveValue?.calculationType?.includes(CALC_TYPE_NORMAL) &&
      !lessThanWeek
    ) {
      errorWording = `The calculation type can't use milestone`;
    } else if (
      !objectiveValue?.calculationType?.includes(CALC_TYPE_NORMAL) &&
      lessThanWeek
    ) {
      errorWording = `The time period is less than a week and the calculation type can't use milestone`;
    }
    return errorWording ? { disabled: true, errorWording: errorWording } : null;
  };

  useEffect(() => {
    const errorMilestone = getError();
    if (!isEqual(errorMilestone, isDisabled)) {
      setIsDisabled(errorMilestone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveValue]);

  useEffect(() => {
    if (isDisabled?.disabled) {
      setTimeout(() => {
        setUseMilestone('disabled');
      }, 50);
    }
  }, [isDisabled]);

  useEffect(() => {
    objectiveValue?.id && isEdit && getDetailData();
  }, [objectiveValue?.id]);

  useEffect(() => {
    if (!lessThanWeek) {
      if (isEdit && firstRender.current) {
        firstRender.current = false;
      } else if (objectiveValue?.milestoneType !== 'disabled') {
        _convertMilestone(milestoneType);
      }
    }
  }, [objectiveValue?.startDate, objectiveValue?.dueDate]);

  const handleChangeToggle = (e) => {
    if (e.target.checked && milestoneType == 'disabled') {
      setIsLoading(true);
      changeDropdownValue(listValue[0].id);
      changeDropdownMilestoneMode('sum_value');
    } else {
      setUseMilestone('disabled', false, true);
    }
  };

  const milestoneCalculationType = [
    {
      icon: 'icon-functions',
      id: 'sum_value',
      name: 'Sum',
      desc: 'Sum progress from the first month to the current month',
      isVisible: true
    },
    {
      icon: 'icon-average',
      id: 'average_value',
      name: 'Average',
      desc: 'Average progress from the first month to the current month',
      isVisible: selectedMetric?.unit == '%' || measurement?.unit == '%'
    },
    {
      icon: 'icon-latest',
      id: 'latest_value',
      name: 'Latest Values',
      desc: 'Display latest updated progress of the current month',
      isVisible: true
    }
  ].filter(({ isVisible }) => isVisible);

  return (
    <div
      className={`milestone-section ${isDialog ? 'w-full' : ''}`}
      data-cy="milestone-section"
    >
      <div
        className={`relative flex content-center section-toggle items-center ${
          isDialog ? 'w-full justify-between' : 'h-[40px] px-[24px] py-[12px]'
        }`}
      >
        {!isDialog && (
          <SVGIcon
            size="24"
            fillColor="var(--n-600)"
            iconName="icon-timeline"
          />
        )}
        <p
          className={`text-n-900 ${
            isDialog ? 'typography-h400' : 'typography-paragraph mx-[16px]'
          }`}
        >
          {getObjectiveLocale('Milestone')}
        </p>
        <SmallToggleSwitchPurple
          idStr="1"
          isChecked={milestoneType !== 'disabled' && !isDisabled?.disabled}
          onChange={(e) => handleChangeToggle(e)}
          addClass={`mt-[6px] ${isDialog ? 'mr-[-8px]' : ''}`}
          disabled={isDisabled?.disabled || !unitId || disabled}
          dataCy="milestone-toggle"
        />
      </div>
      {isDisabled?.disabled && (
        <div
          className={`flex content-center mt-[4px] ${
            !isDialog ? 'mb-[8px] ml-[62px] mr-[16px]' : ''
          }`}
        >
          <SVGIcon size="16" fillColor="var(--n-600)" iconName="icon-info" />
          <p className="typography-h100 color-n-600 ml-[8px]">
            {getObjectiveLocale(isDisabled?.errorWording)}
          </p>
        </div>
      )}
      {milestoneType !== 'disabled' && !isDisabled?.disabled && (
        <div
          className={`bg-n-200 rounded-[4px] border-n300 px-[16px] py-[16px] mr-[24px] overflow-auto overflow-overlay max-h-[560px] ${
            isDialog ? 'mt-[8px]' : 'ml-[62px]'
          }`}
        >
          {milestoneMode && (
            <>
              <p className="typography-h100 color-n-800 mb-[8px]">
                {getObjectiveLocale('Mode')}
              </p>
              <Dropdown customClass="w-full">
                <Dropdown.Trigger dataCy="milestone-mode-dropdown">
                  <Trigger
                    customWrapperClass="w-full"
                    topLabel={false}
                    label={false}
                    icon={whichMilestoneMode(milestoneMode)?.icon}
                    text={milestoneMode?.split('_')[0]}
                    capitalize
                    disabled={disabled}
                  />
                </Dropdown.Trigger>
                <Dropdown.MenuItems customClass="w-full">
                  {milestoneCalculationType.map((type, index) => (
                    <Dropdown.MenuItem
                      onClick={() => changeDropdownMilestoneMode(type?.id)}
                      dataCy={type.name}
                      key={index}
                    >
                      <div className="flex items-center my-[4px]">
                        <SVGIcon
                          iconName={type.icon}
                          size="24"
                          customClass="mr-[16px]"
                          fillColor="var(--n-600)"
                        />
                        <p className="typography-button text-n-900">
                          {type.name}
                        </p>
                      </div>
                    </Dropdown.MenuItem>
                  ))}
                </Dropdown.MenuItems>
              </Dropdown>
              <div className="flex mt-[8px]">
                <SVGIcon
                  size="14"
                  fillColor="var(--n-600)"
                  iconName="icon-info"
                  customClass="mr-[8px] mt-[4px] cursor-pointer"
                />
                <p className="typography-paragraph text-n-800">
                  {whichMilestoneMode(milestoneMode)?.desc}
                </p>
              </div>
            </>
          )}
          <p className="typography-h100 color-n-800 mb-[8px] mt-[16px]">
            {getObjectiveLocale('View period')}
          </p>
          <Dropdown customClass="w-full">
            <Dropdown.Trigger dataCy="milestone-dropdown">
              <Trigger
                customWrapperClass="w-full"
                topLabel={false}
                label={false}
                icon={false}
                text={milestoneType}
                capitalize={true}
                disabled={disabled}
              />
            </Dropdown.Trigger>
            <Dropdown.MenuItems
              options={listValue}
              customClass="w-full"
              dataCy="milestone"
            />
          </Dropdown>
          <p className="typography-h400 color-n-900 mb-[8px] mt-[16px]">
            {getObjectiveLocale('Set milestone target')}
          </p>
          <p className="typography-paragraph color-n-800 mb-[8px]">
            {getObjectiveLocale(
              'Milestone target metrics will be the same as the progress metrics.'
            )}
          </p>
          <div className="flex-col flex">
            <div className="flex self-end">
              <p className="typography-h100 color-n-800 mr-[4px]">
                {`${
                  milestoneMode == 'latest_value'
                    ? getNumberLocale(
                        changedMilestones[changedMilestones?.length - 1]
                          ?.targetValue
                      )
                    : getNumberLocale(totalTargetValue)
                }` + `/${getNumberLocale(measurement?.targetValue)}`}
              </p>
              <TooltipContainer
                show
                text={whichMilestoneMode(milestoneMode)?.calcDesc}
                classContainer="cursor-pointer"
                useMaxWidth={false}
                align="right"
              >
                <SVGIcon
                  size="16"
                  iconName="icon-info"
                  fillColor="var(--n-600)"
                />
              </TooltipContainer>
            </div>
            <div className="flex flex-col">
              {isLoading ? (
                <Loading />
              ) : (
                changedMilestones?.map((data, index) => {
                  return (
                    <div className="flex items-center mt-[8px]" key={index}>
                      <p
                        className="typography-h400 w-[40px] mr-[8px] color-n-900"
                        data-cy="name-milestone"
                      >
                        {milestoneType == 'quarterly'
                          ? data?.periodName
                          : dayjs()
                              .month(whichMonth[data?.periodName])
                              .format('MMM')}
                      </p>
                      <InputMilestone
                        data={data}
                        index={index}
                        objectiveValue={objectiveValue}
                        handleChange={handleInputMilestone}
                        dataCy={`input-milestone-${data?.periodName?.toLowerCase()}`}
                        disabled={disabled}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MilestoneSection;

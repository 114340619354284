import React, { useCallback, useEffect, useRef, useState } from 'react';

import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import {
  ContentState,
  EditorState,
  Modifier,
  RichUtils,
  getDefaultKeyBinding
} from 'draft-js';
import 'emoji-mart/css/emoji-mart.css';
import Picker from 'emoji-mart/dist-es/components/picker/nimble-picker';

import useClickOutside from 'hooks/useClickOutside';
import useEmoticon from 'hooks/useEmoticon';
import { getCultureLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import SuggestionList from 'components/shared/MentionSuggestion/SuggestionList';
import SVGIcon from 'components/shared/SVGIcon';
import 'src/scss/web/editorStyles';

function MentionEditorWithEmoticon({
  suggestions,
  submitData = () => {},
  clearEditor,
  setClearEditor,
  autoFilled,
  setAutoFilled,
  autoFocus,
  setAutoFocus,
  alwaysShowEmoji = false,
  placeholder = 'Write Comment',
  defaultText = null,
  useSendButton = false,
  useNewLineShortcut = false,
  syncData = () => {},
  wrapperClassName = '',
  iconClassName = '',
  onSearchChange = () => {},
  dataCy = 'comment-field'
}) {
  const existingText = defaultText
    ? EditorState.createWithContent(ContentState.createFromText(defaultText))
    : EditorState.createEmpty();

  const [open, setOpen] = useState(true);
  const [textEditor, setTextEditor] = useState(existingText);
  const [isEmojiShow, setIsEmojiShow] = useState(alwaysShowEmoji);
  const [isEmojiCartShow, setIsEmojiCartShow] = useState(false);
  const [metaMentions, setMetaMentions] = useState([]);

  const emojiRef = useRef();
  const wrapperRef = useRef();
  let editor = useRef();
  let emoticon = useEmoticon();

  const [{ plugins, MentionSuggestions }] = useState(() => {
    const mentionPlugin = createMentionPlugin({
      supportWhitespace: true,
      mentionTriggerAllows: '@',
      mentionPrefixBy: '@'
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return {
      plugins,
      MentionSuggestions
    };
  });

  useClickOutside(emojiRef, () => {
    !alwaysShowEmoji && setIsEmojiShow(false);
    setIsEmojiCartShow(false);
  });

  const addOtherElementToEditor = (otherElement) => {
    const editorState = textEditor;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const ncs = Modifier.insertText(contentState, selection, otherElement);
    const es = EditorState.push(editorState, ncs, 'insert-fragment');
    setTextEditor(es);

    setTimeout(() => {
      editor.current.focus();
    }, 300);
  };

  const addEmoji = (emoji) => {
    addOtherElementToEditor(emoji.native);
    setIsEmojiCartShow(false);
  };

  const changeComment = (editorState) => {
    setTextEditor(editorState);
  };

  const addMention = (mention) => {
    const currentCommentText = textEditor.getCurrentContent().getPlainText();

    const startIndex = currentCommentText.indexOf('@');

    const newMetaMention = {
      userId: mention.id,
      startIndex: startIndex,
      length: mention.name.length,
      name: mention.name
    };

    setMetaMentions([...metaMentions, newMetaMention]);
  };

  const sendCommentKeyBinding = (e) => {
    if (e.shiftKey && e.keyCode == 13) {
      return 'create-newline';
    }
    if (e.keyCode === 13) {
      return 'send-comment';
    }
    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (command) => {
    if (command === 'send-comment') {
      restructureData();
      return 'handled';
    } else if (command === 'create-newline' && useNewLineShortcut) {
      changeComment(RichUtils.insertSoftNewline(textEditor));
      return 'handled';
    }
    return 'not-handled';
  };

  const restructureData = async (isSync) => {
    const comment = textEditor.getCurrentContent().getPlainText();
    const selectedMetaMention = metaMentions.filter((mention) =>
      comment.includes(mention.name)
    );
    if (isSync) {
      syncData(comment, selectedMetaMention);
    } else {
      const isSuccess = await submitData(comment, selectedMetaMention);
      if (isSuccess) {
        editor?.current?.blur();
        setTextEditor(EditorState.createEmpty());
        setMetaMentions([]);
      }
    }
  };

  const focus = () => {
    editor.current.focus();
  };

  const autoFillEditor = useCallback((comment) => {
    const newMetaMention = {
      userId: comment.user.id,
      startIndex: 0,
      length: comment.user.fullName.length,
      name: comment.user.fullName
    };
    const editorState = EditorState.createEmpty();

    const stateWithEntity = editorState
      .getCurrentContent()
      .createEntity('mention', 'IMMUTABLE', {
        mention: {
          id: comment.user.fullName,
          name: comment.user.fullName
        }
      });
    const entityKey = stateWithEntity.getLastCreatedEntityKey();
    const stateWithText = Modifier.insertText(
      stateWithEntity,
      editorState.getSelection(),
      comment.user.fullName,
      null,
      entityKey
    );
    let es = EditorState.push(editorState, stateWithText);

    const contentState = es.getCurrentContent();
    const ncs = Modifier.insertText(contentState, es.getSelection(), ' ');
    const ess = EditorState.push(es, ncs, 'insert-fragment');

    setTextEditor(ess);
    setMetaMentions([...metaMentions, newMetaMention]);
    setAutoFilled(false);
    setTimeout(() => {
      editor.current.focus();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenChange = useCallback((open) => {
    setOpen(open);
  }, []);

  useEffect(() => {
    autoFilled?.id && autoFillEditor(autoFilled);
  }, [autoFilled, autoFillEditor]);

  useEffect(() => {
    if (clearEditor) {
      setTextEditor(() => EditorState.createEmpty());
      setClearEditor(false);
    }
  }, [clearEditor, setClearEditor]);

  useEffect(() => {
    if (autoFocus) {
      focus();
      setAutoFocus(false);
    }
  }, [autoFocus, setAutoFocus]);

  useEffect(() => {
    restructureData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textEditor, metaMentions]);

  return (
    <div
      className="flex items-center flex-row px-[16px] py-[12px]"
      onClick={(e) => {
        e.stopPropagation();
        setIsEmojiShow(true);
      }}
      ref={alwaysShowEmoji ? wrapperRef : emojiRef}
    >
      <div
        className={`flex justify-between w-full mr-[8px] pl-[16px] pr-[8px] py-[8px] ${wrapperClassName}`}
      >
        <div className="w-full editor-mention" onClick={focus} data-cy={dataCy}>
          <Editor
            editorState={textEditor}
            onChange={changeComment}
            plugins={plugins}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={sendCommentKeyBinding}
            ref={editor}
            placeholder={placeholder}
          />
          <MentionSuggestions
            suggestions={suggestions}
            onAddMention={addMention}
            entryComponent={SuggestionList}
            onSearchChange={({ value }) => {
              setOpen(true);
              onSearchChange(value);
            }}
            onOpenChange={onOpenChange}
            open={open}
          />
        </div>
        <div
          className="flex flex-col items-end z-[1]"
          ref={alwaysShowEmoji ? emojiRef : wrapperRef}
        >
          {isEmojiShow && (
            <SVGIcon
              size={18}
              iconName="icon-insert_emoticon"
              fillColor="var(--n-600)"
              customClass={iconClassName}
              onClick={(e) => {
                e.stopPropagation();
                setIsEmojiShow(true);
                setIsEmojiCartShow(true);
              }}
            />
          )}
          {isEmojiCartShow && emoticon && (
            <Picker onSelect={addEmoji} data={emoticon} />
          )}
        </div>
      </div>
      {useSendButton && (
        <Button
          className="w-[70px] capitalize"
          datacy="send-comment"
          disabled={textEditor.getCurrentContent().getPlainText().length === 0}
          onClick={() => restructureData()}
        >
          {getCultureLocale('Send')}
        </Button>
      )}
    </div>
  );
}

export default MentionEditorWithEmoticon;

import React from 'react';

import { getObjectivePhases } from 'client/ObjectivesClient';
import useEditPhase from 'hooks/useEditPhase';
import useFilter from 'hooks/useFilter';
import useModalObjectives from 'hooks/useModalObjectives';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

const AddGroup = ({ projectId, type }) => {
  const { setEditedPhaseData, setCurrentPhaseData } = useEditPhase();
  const { showModal } = useModalObjectives();
  const filter = useFilter((state) => state.filterById?.projectDetailBoard);

  const getPhases = async () => {
    let params = { projectId };
    const { data } = await getObjectivePhases(params);
    return data ? data : [];
  };

  const onClick = async () => {
    if (filter?.group === 'phase') {
      const listPhases = await getPhases();
      showModal({ modalType: 'editPhases', props: { projectId, type } });
      setEditedPhaseData(listPhases);
      setCurrentPhaseData(listPhases);
    }
    // else if (filter?.group === 'priority') {
    //   NEXT STORY - show edit priority modal
    // }
  };

  return (
    <div className="flex justify-between items-center rounded-[4px] bg-n-300 w-[360px] h-[48px] ml-[24px] mr-[40px]">
      <Button.Tertiary
        customClass="w-full h-full"
        onClick={() => onClick()}
        datacy="task-board-add-group-button"
      >
        <SVGIcon iconName="icon-add" fillColor="var(--base-600)" size={24} />
        <p className="text-base-600">
          {getObjectiveLocale(
            filter?.group === 'phase' ? 'Add Phase' : 'Add Priority'
          )}
        </p>
      </Button.Tertiary>
    </div>
  );
};

export default AddGroup;

import React, { useState } from 'react';

import { useUser } from 'context/UserContext';

import LogoutModal from 'components/modal/LogoutModal';
import Avatar from 'components/shared/Avatar';

function HeaderMobile() {
  const { user } = useUser();
  const [showLogoutModal, toggleLogoutModal] = useState(false);

  const isCulture = window.location.pathname.includes('/timeline')
    ? true
    : false;

  return (
    <div className="header-admin-page w-full">
      <div
        className={`wrapper-collagues-mobile ${
          isCulture ? 'flex justify-between' : 'flex justify-end'
        }`}
      >
        <div className="flex">
          <div
            className="user-name-on-header"
            onClick={(e) => {
              e.stopPropagation();
              toggleLogoutModal(true);
            }}
          >
            <Avatar
              src={user?.profilePicture}
              name={user?.name}
              className="profile-picture"
              withTooltip={false}
            />
          </div>
        </div>
        {showLogoutModal && (
          <LogoutModal toggleLogoutModal={toggleLogoutModal} />
        )}
      </div>
    </div>
  );
}

export default HeaderMobile;

import React from 'react';

import { phaseName } from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ProgressTracker from 'src/components/design-system/ProgressTracker/ProgressTracker';

function ReviewResultOthersSidebar({
  currentAssignment,
  loadTrack,
  currentTrackId
}) {
  const phase = currentAssignment.formalReviewPhase;
  const tracks = currentAssignment.tracks;

  const options = tracks.map((track, index) => {
    return {
      name: track.name,
      iconText: index + 1,
      iconName: null,
      active: currentTrackId == track.id,
      disabled: false,
      onClick: () => currentTrackId != track.id && loadTrack(track),
      dataCy: `track-${track.name}`.toLocaleLowerCase().replace(/ /g, '-')
    };
  });

  return (
    <div className="result-other-container">
      <div className="col-md-2 result-other-sidebar pt-[24px] pl-[0px] pl-[0px]">
        <div className="upper-sidebar mb-[24px]">
          <div>
            <div>
              <h4 className="typography-h300 typography-secondary mb-[16px] pl-[24px] pr-[24px]">
                {getObjectiveLocale(phaseName(phase.type)).toUpperCase()}
              </h4>
              <div className="sidebar-left-navigator-menu py-[12px] px-[40px] active">
                <p className="typography-h400 text-base-600">
                  {currentAssignment.target.name}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom-400 border-top-400">
          <div className="all-steps-container py-[24px]">
            <ProgressTracker options={options} />
          </div>
        </div>
      </div>
      <div id="blocker" />
    </div>
  );
}

export default ReviewResultOthersSidebar;

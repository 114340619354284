import React, { createContext, useEffect, useRef, useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';
import { getDurationDays } from 'utils/ObjectivesHelper';
import { useDeepEffect } from 'utils/useDeepEffect';

import LoadingIndicator from 'components/shared/LoadingIndicator/LoadingIndicator';

import GanttBackground from './BackgroundComponents/GanttBackground';
import './GanttChart.scss';
import GanttChartSidebarWrapper from './GanttChartSidebarWrapper';
import GanttHeader from './HeaderComponents/GanttHeader';
import ScrollbarHorizontal from './utils/ScrollbarHorizontal';
import ScrollbarVertical from './utils/ScrollbarVertical';
import {
  generateAppendedDate,
  generateRangeDate,
  getAppendIdentifier,
  getColWidthbyGroup,
  getTransform,
  modifyBackground,
  toggleLoading
} from './utils/backgroundHelper';
import { WEEK_GROUP } from './utils/const';
import { modifyScrollPosition } from './utils/scrollHelper';

export const GanttChartContext = createContext({});

export const GanttChart = ({
  timeGroup = WEEK_GROUP,
  contentOptions,
  objectiveOptions,
  isBarDraggable = false,
  periodStart,
  periodEnd,
  isAbleToAppend,
  scrollSpeedMultiplier = 2
}) => {
  const contentRef = useRef(0);
  const [daysOfYear, setDaysOfYear] = useState([]);
  const [firstRender, setFirstRender] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);
  const [columnWidth, setColumnWidth] = useState(48);
  const [isDisableScroll, setDisableScroll] = useState(false);
  const [timeGroupState, setTimeGroupState] = useState(timeGroup);
  const [dataReady, setDataReady] = useState(false);

  const appendDate = async ({
    daysOfYear,
    currentXTransform,
    currentYTransform,
    isToRight
  }) => {
    if (daysOfYear.length == 0) return;
    toggleLoading(true);
    const earliestDays = daysOfYear[0];
    const identifierDay = getAppendIdentifier(timeGroup);
    const colWidth = getColWidthbyGroup(timeGroup);
    const storedays = generateAppendedDate({
      isToRight,
      identifierDay,
      daysOfYear,
      earliestDays
    });

    const current = isToRight
      ? [...daysOfYear, ...storedays]
      : [...storedays, ...daysOfYear];

    setContentWidth(current.length * colWidth);
    setDaysOfYear(current);
    setTimeout(() => {
      modifyScrollPosition(
        isToRight ? currentXTransform : (identifierDay - 1) * colWidth * -1,
        currentYTransform
      );
    }, 0);
    setTimeout(() => {
      toggleLoading();
    }, 500);
  };

  useDeepEffect(() => {
    toggleLoading(true, true);
    setDataReady(false);
    let storedays = generateRangeDate(timeGroup, periodStart, periodEnd);
    const colWidth = getColWidthbyGroup(timeGroup);
    setContentWidth(storedays.length * colWidth);
    setColumnWidth(colWidth);
    setDaysOfYear(storedays);
    setTimeGroupState(timeGroup);
    setFirstRender(true);
    setTimeout(() => {
      toggleLoading(false, true);
      setDataReady(true);
    }, 1000);
  }, [periodEnd, periodStart, timeGroup]);

  useEffect(() => {
    if (!firstRender || !dataReady) return;
    const today = new Date().setHours(0, 0, 0, 0);
    const scroller = document.querySelector('#main-scroll');
    const todayLeftOffset =
      getDurationDays(daysOfYear[0].date, today) * columnWidth;

    const designatedX =
      todayLeftOffset - (scroller.offsetWidth * 3) / 5 || columnWidth * 7 * 3;
    modifyScrollPosition(designatedX * -1, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender, dataReady]);

  useEffect(() => {
    if (contentRef.current) {
      const scroller = document.querySelector('#main-scroll');

      const wheelEvent = (e) => {
        if (isDisableScroll) return;
        e.stopPropagation();
        e.preventDefault();

        const {
          designatedXtransform,
          designatedYtransform,
          currentXTransformTranslate
        } = getTransform(e, scrollSpeedMultiplier);

        modifyBackground({
          designatedXtransform,
          designatedYtransform,
          daysOfYear,
          currentXTransformTranslate,
          appendDate: isAbleToAppend && appendDate
        });
      };

      scroller.addEventListener('wheel', wheelEvent);
      return () => {
        scroller.removeEventListener('wheel', wheelEvent);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef, isDisableScroll, daysOfYear]);

  useEffect(() => {
    const scroller = document.querySelector('#main-scroll');
    if (!scroller) return;
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          const testScroller = document.querySelector('#scroll-content');

          // get transform value of scroll element
          // eslint-disable-next-line no-undef
          const matrix = new WebKitCSSMatrix(
            window.getComputedStyle(testScroller).transform
          );
          const currentXTransformTranslate = matrix.m41;
          const currentYTransformTranslate = matrix.m42;

          if (
            Math.abs(currentXTransformTranslate) >
            testScroller.offsetWidth - scroller.offsetWidth
          ) {
            modifyScrollPosition(
              (testScroller.offsetWidth - scroller.offsetWidth) * -1,
              currentYTransformTranslate
            );
          }
        }
      }
    });

    resizeObserver.observe(scroller);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const contextValue = {
    objectiveOptions,
    isBarDraggable,
    periodStart: daysOfYear[0]?.date,
    columnWidth,
    setDisableScroll
  };

  return (
    <GanttChartContext.Provider value={contextValue}>
      <div
        className="scroller bg-n-000 rounded border border-n-300 border-solid overflow-hidden relative h-[calc(100vh-278px)] w-full"
        id="main-scroll"
        ref={contentRef}
      >
        <div
          id="loading-indicator"
          className="absolute h-full w-[calc(100%-360px)] right-0 z-[10] opacity-50 flex items-center justify-center bg-n-300 invisible"
        >
          <LoadingIndicator />
        </div>
        <div
          className="wrapper flex flex-col relative overflow-hidden"
          style={{ width: contentWidth + 'px', maxWidth: contentWidth + 'px' }}
          id="scroll-content"
        >
          <div className="tm-content w-full h-full pt-[72px] relative min-h-[calc(100vh-284px)]">
            <div className="bg-sidebar h-full bg-n-000 absolute z-[2] w-[360px] top-[0]" />
            <div className="timeline-sidebar absolute z-[4] w-[360px] h-[71px] px-[16px] flex items-center top-[0] bg-n-000">
              <p className="typoghraphy-h300 text-n-800">
                {getObjectiveLocale('NAME')}
              </p>
            </div>
            <GanttChartSidebarWrapper contentOptions={contentOptions} />
          </div>
          <GanttHeader timeGroup={timeGroupState} daysOfYear={daysOfYear} />

          <GanttBackground timeGroup={timeGroupState} daysOfYear={daysOfYear} />
        </div>
        <ScrollbarHorizontal contentWidth={contentWidth} />
        <ScrollbarVertical contentWidth={contentWidth} />
      </div>
    </GanttChartContext.Provider>
  );
};

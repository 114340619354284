import React, { useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';

import { highlightPinnedPost } from 'client/CultureClient';
import { useReload } from 'context/ReloadContext';
import Config from 'utils/Config';
import {
  formatBytes,
  getCultureLocale,
  getNumberLocale,
  parseCaption,
  setPostType,
  taggedUserTemplate
} from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import Avatar from 'components/shared/Avatar';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';
import TruncateParagraph from 'src/components/shared/TruncateParagraph';

const MiniFeedCard = ({ feed, type, removeFeed = () => {}, key }) => {
  const history = useHistory();
  const { reloadFeeds, reload } = useReload();
  const [openModal, setOpenModal] = useState(false);

  const currentCardType = setPostType(feed.postTypeV2);
  const feedTypeData = feed[currentCardType];

  const PollDetail = () => {
    return (
      <div className="px-[16px] pt-[16px]">
        <div className="typography-paragraph" data-cy="poll-question">
          {feedTypeData.question}
        </div>
        <div
          className="typography-h100 text-n-800 mt-[8px]"
          data-cy="poll-description"
        >
          {getNumberLocale(feedTypeData.pollOptions.length)}{' '}
          {getCultureLocale('options')}, {getCultureLocale('voted by')}{' '}
          {getNumberLocale(feedTypeData.voterCount)} {getCultureLocale('from')}{' '}
          {getNumberLocale(feedTypeData.participantCount)}{' '}
          {getCultureLocale('people')}
        </div>
        <div className="mt-[24px] flex justify-center">
          <Button
            datacy="answer-polling"
            data-testid="answer-polling"
            onClick={() => {}}
            customClass="w-full"
          >
            {getCultureLocale('Answer Polling')}
          </Button>
        </div>
      </div>
    );
  };

  const MediaDetail = () => {
    const hasOtherSection =
      feedTypeData.caption ||
      (currentCardType === 'multipleContent' &&
        feedTypeData?.files?.length > 0);

    let imageSource = '';
    switch (currentCardType) {
      case 'multipleMedia':
      case 'multipleContent':
        imageSource = feedTypeData?.resources?.[0]?.video
          ? feedTypeData.resources?.[0].video.secureUrl
          : feedTypeData.resources?.[0].secureUrl;
        break;
      case 'media':
        imageSource = feedTypeData.secureUrl;
        break;
    }

    return (
      <div>
        <div className="relative" data-cy="media-section">
          {feedTypeData?.resources?.[0]?.video ? (
            <ReactPlayer
              url={imageSource}
              width="320px"
              height={hasOtherSection ? '128px' : '192px'}
              controls={false}
              playing={false}
              config={{
                file: {
                  attributes: {
                    crossOrigin: 'true'
                  }
                }
              }}
            />
          ) : (
            <LazyLoadComponent threshold={250}>
              <AWSImage
                className={`object-cover object-top rounded-t-lg ${
                  hasOtherSection ? 'h-[128px]' : 'h-[192px]'
                }`}
                src={imageSource}
                dataCy="image-media"
              />
            </LazyLoadComponent>
          )}
          {feedTypeData?.resources?.length > 1 && (
            <SVGIcon
              iconName="icon-multiple-media"
              size="32"
              fillColor="var(--b-400)"
              customClass="absolute right-8 top-[8px]"
              dataCy="multiple-media"
            />
          )}
          {feedTypeData.video && (
            <SVGIcon
              iconName="icon-recognition-card"
              size="46"
              fillColor="var(--n-000)"
              customClass="absolute left-45p top-40p"
            />
          )}
        </div>
        <div className="relative">
          <div
            data-cy="caption"
            className={`typography-paragraph mt-[4px] px-[16px] text-left ${
              feedTypeData?.files?.length > 0
                ? 'multiline-text-1 h-[24px]'
                : 'multiline-text-2 h-[48px]'
            }`}
            dangerouslySetInnerHTML={{
              __html:
                (feedTypeData.caption &&
                  parseCaption(feedTypeData.caption, feedTypeData.mentions)) +
                taggedUserTemplate(feed.taggedUsers)
            }}
          />
          {feedTypeData?.files?.length > 0 && (
            <div className="text-base-600 absolute text-left px-[16px]">
              {' '}
              +{feedTypeData.files.length} {getCultureLocale('File Attached')}
            </div>
          )}
        </div>
      </div>
    );
  };

  const LinkDetail = () => {
    return (
      <div>
        <LazyLoadComponent threshold={250}>
          <AWSImage
            className="object-cover rounded-t-default h-[129px]"
            src={feedTypeData.imageUrl}
          />
        </LazyLoadComponent>
        <div className="typography-paragraph multiline-text-1 mt-[16px] px-[16px]">
          {feedTypeData.urlDesc}
        </div>
        <div className="typography-h100 text-n-600 multiline-text-1 mt-[4px] px-[16px]">
          {feedTypeData.urlDomain}
        </div>
      </div>
    );
  };

  const AttachmentDetail = () => {
    return (
      <div>
        {feedTypeData.caption && (
          <div
            data-cy="caption"
            className={`typography-paragraph mt-[16px] px-[16px] text-left ${
              feedTypeData?.files?.length > 0
                ? 'multiline-text-2 max-h-[48px]'
                : 'multiline-text-3 max-h-[72px]'
            }`}
            dangerouslySetInnerHTML={{
              __html:
                (feedTypeData.caption &&
                  parseCaption(feedTypeData.caption, feedTypeData.mentions)) +
                taggedUserTemplate(feed.taggedUsers)
            }}
          />
        )}
        <div className="typography-paragraph m-16 mb-[24px] border border-solid border-n-400 rounded-lg h-[64px]">
          <div className="p-16 flex">
            <div className="relative mr-[8px]">
              <SVGIcon
                size="32"
                iconName="icon-insert_drive_file"
                fillColor="var(--n-600)"
              />
              <div className="absolute text-xs text-n-000 bg-r-600 flex items-center top-40p right-45p uppercase font-extrabold h-[10px] p-2">
                {feedTypeData.files[0].format}
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div>
                <div className="typography-h100 text-n-800 multiline-text-1">
                  {feedTypeData.files[0].filename}
                </div>
                <div className="typography-h100 text-n-600 text-left">
                  {formatBytes(feedTypeData.files[0].size)}
                </div>
              </div>
              <a href={feedTypeData.files[0].url}>
                <SVGIcon
                  size="24"
                  iconName="icon-file_download"
                  fillColor="var(--base-600)"
                />
              </a>
            </div>
          </div>
          {currentCardType === 'multipleContent' && (
            <div className="text-base-600 absolute text-left mt-[16px]">
              {' '}
              +{feedTypeData.files.length} {getCultureLocale('File Attached')}
            </div>
          )}
        </div>
      </div>
    );
  };

  const RecognitionDetail = () => {
    let name = [];
    let avatarSize = 32;
    let lengthUser = feedTypeData.targetUsers.length;
    switch (lengthUser) {
      case 1:
        avatarSize = 64;
        break;
      case 2:
        avatarSize = 48;
        break;
    }

    return (
      <div className="mt-[24px]">
        <div
          className={`flex mr-[40px] ${
            lengthUser === 1 ? 'ml-[64px]' : 'ml-[40px]'
          }`}
        >
          <div
            className="icon-target-user flex flex-wrap justify-center items-center"
            data-cy="recognition-user"
          >
            {feedTypeData.targetUsers.map((user, index) => {
              name.push(user.fullName);
              return (
                <Avatar
                  name={user?.fullName}
                  src={user?.profilePicture?.secureUrl || null}
                  dataCy="user-recognition"
                  size={avatarSize}
                  style={index !== lengthUser - 1 ? { marginRight: '8px' } : {}}
                  wrapperClassName={
                    lengthUser === 3 ? 'flex-[50]' : 'flex-[33]'
                  }
                  key={index}
                />
              );
            })}
          </div>
          <LazyLoadComponent threshold={250}>
            <img
              src={Config.asset.culture.postedRecognition}
              className={`w-[64px] h-[64px] ${
                lengthUser > 3 ? 'ml-[40px]' : `ml-${avatarSize}`
              }`}
              onError={(e) =>
                e.target.setAttribute(
                  'src',
                  Config.asset.culture.brokenImageCenter
                )
              }
            />
          </LazyLoadComponent>
        </div>
        <div className="flex flex-col items-center mx-[16px] mt-[16px]">
          <TruncateParagraph
            position="bottom"
            useMaxWidth={true}
            className="typography-h400-longform text-base-600 text-center"
            align="left"
            dataCy="recognition-name"
          >
            {name.length === 2
              ? name.join(getCultureLocale('and'))
              : name.slice(0, 2).join(', ')}
            {name.length > 2 &&
              (name.length === 3 ? (
                <span>
                  {' '}
                  {getCultureLocale('and')} {name[3]}
                </span>
              ) : (
                <span>
                  {' '}
                  {getCultureLocale('and')} {name.length - 2}{' '}
                  {getCultureLocale('others')}
                </span>
              ))}
          </TruncateParagraph>
          <div className="typography-h100 text-n-600 my-[8px] relative w-full">
            <div className="line-text text-center">
              <span>{getCultureLocale('WAS RECOGNIZE FOR')}</span>
            </div>
          </div>
          <div
            className="typography-h600 text-base-600 multiline-text-1"
            data-cy="recognition-value"
          >
            {feedTypeData.values[0].title}
          </div>
        </div>
      </div>
    );
  };

  const submit = async (e) => {
    e.stopPropagation();
    const { isSuccess } = await highlightPinnedPost(feed.id, `un${type}`);

    if (isSuccess) {
      setOpenModal(false);
      removeFeed(feed.id);
      type === 'pinned' && reload({ reloadFeeds: !reloadFeeds });
    }
  };

  const setToDetailPath = () => {
    const path =
      type === 'pinned'
        ? `/timeline/${feed.id}`
        : `/timeline/${feed.id}?source=homepage`;
    history.replace(path);
  };

  return (
    <div
      data-cy="mini-feed"
      key={key}
      onClick={() => setToDetailPath()}
      className="min-w-[320px] w-[320px] h-[240px] mr-[24px] border border-solid border-n-400 rounded-lg relative bg-n-000 cursor-pointer"
    >
      <div>
        {currentCardType === 'poll' && <PollDetail />}
        {currentCardType === 'attachment' && <AttachmentDetail />}
        {(currentCardType === 'multipleMedia' ||
          currentCardType === 'media') && <MediaDetail />}
        {currentCardType === 'multipleContent' &&
          (feedTypeData.resources.length === 0 ? (
            <AttachmentDetail />
          ) : (
            <MediaDetail />
          ))}
        {currentCardType === 'thought' && (
          <div
            data-cy="caption"
            className="typography-paragraph multiline-text-7 p-16 h-[190px]"
            dangerouslySetInnerHTML={{
              __html:
                (feedTypeData.caption &&
                  parseCaption(feedTypeData.caption, feedTypeData.mentions)) +
                taggedUserTemplate(feed.taggedUsers)
            }}
          />
        )}
        {currentCardType === 'link' && <LinkDetail />}
        {currentCardType === 'recognition' && <RecognitionDetail />}
      </div>
      <div className="px-[16px] mt-[16px] py-[4px] h-[32px] absolute bottom-8 flex justify-between w-full">
        <div className="flex">
          <Avatar
            name={feed?.user?.fullName}
            src={feed?.user?.profilePicture?.secureUrl || null}
            dataCy="user-feed"
            size={24}
            style={{ marginRight: '8px' }}
          />
          <div
            className="typography-button text-n-800 multiline-text-1 h-[24px]"
            data-cy="feed-user"
          >
            {feed?.user?.fullName}
          </div>
        </div>
        {(type === 'pinned' ? feed.pinnable : feed.highlightable) && (
          <SVGIcon
            dataCy="option-feed"
            iconName="icon-keyboard_control"
            size="24"
            fillColor="var(--n-600)"
            onClick={() => setOpenModal(true)}
          />
        )}
      </div>
      {openModal && (
        <Modal
          dataCyModal={`${type}-modal`}
          title={type === 'pinned' ? 'Unpin Post' : 'Unhighlight Post'}
          description="Are you sure?"
          eventOnClickClose={() => setOpenModal(false)}
          className="w-[240px]"
          withPrimaryBtn={{
            title: getCultureLocale('Yes'),
            dataCy: 'btn-unpin-unhighlight',
            onClick: (e) => submit(e)
          }}
          withSecondaryBtn={{
            title: getCultureLocale('Cancel'),
            dataCy: 'btn-cancel',
            onClick: (e) => {
              e.stopPropagation();
              setOpenModal(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default MiniFeedCard;

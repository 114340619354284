import { trackEvent } from 'src/utils/AnalyticUtils';

const ObjectiveAmplitude = {
  sendAmplitudeReviewTaskGoals: data => {
    const propertiesReviewTaskGoal = {
      rate: data.scoringMark?.name,
      'rate comment': data?.comment || '',
      feedback: data.weakness?.skill || 'none',
      'feedback comment': data.weakness?.comment,
      recognition: data.strength?.skill || 'none',
      'recognition comment': data.strength?.comment || ''
    };
    trackEvent({
      event: 'review task / goal',
      eventProperties: propertiesReviewTaskGoal
    });
  },
  sendAmplitudeIdentifyStrengthAndSuggestImprovement: (
    body,
    objectiveDetail
  ) => {
    let propertiesSuggestImprovement = '';
    const propertiesIdentifyStrength = {
      source: 'direct',
      skill: body.strength?.skill || 'none',
      'skill type': 'custom',
      objective_id: objectiveDetail.id,
      status: 'success'
    };
    if (body.weakness?.skill !== null)
      propertiesSuggestImprovement = {
        source: 'direct',
        skill: body.weakness?.skill || 'none',
        'skill type': 'custom',
        objective_id: objectiveDetail.id,
        status: 'success'
      };

    trackEvent({
      event: 'identify strength',
      eventProperties: propertiesIdentifyStrength
    });
    propertiesSuggestImprovement !== '' &&
      trackEvent({
        event: 'suggest improvement',
        eventProperties: propertiesSuggestImprovement
      });
  }
};

export default ObjectiveAmplitude;

import React, { useEffect, useState } from 'react';

import 'scss/culture/_label-section';

import { getLabelList } from 'client/CultureClient';
import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import { loadMoreValidator } from 'utils/HelperUtils';

function LabelSection({ setSelectedLabels, selectedLabels = [], groupId }) {
  const {
    config: { allowAnnouncementLabel }
  } = useUser();
  const [labelList, setLabelList] = useState([]);
  const [filteredLabels, setFilteredLabels] = useState([]);
  const [currentSelectedLabels, setCurrentSelectedLabels] =
    useState(selectedLabels);
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [olderThan, setOlderThan] = useState('');

  const getLabels = async () => {
    const params = {
      adminLabel: 1,
      announcementLabel: allowAnnouncementLabel !== 'none',
      limit: 10,
      olderThan: olderThan,
      status: ['Active'],
      feedGroupId: groupId
    };

    const { data, pagination } = await getLabelList(params);

    if (data && pagination) {
      if (olderThan !== pagination.next.olderThan) {
        setLabelList([...labelList, ...data]);
        setOlderThan(pagination.next.olderThan);
      } else {
        setOlderThan('');
      }
    }
  };

  const updateSelectedLabel = (id, title, type) => {
    const currentSelectedLabelIds = currentSelectedLabels.map(
      (label) => label.id
    );
    let filteredLabels = [];
    if (currentSelectedLabelIds.includes(id)) {
      filteredLabels = currentSelectedLabels.filter((label) => label.id !== id);
    } else {
      filteredLabels = [
        ...currentSelectedLabels,
        {
          id: id,
          title: title,
          type: type
        }
      ];
    }
    setCurrentSelectedLabels(filteredLabels);
    setSelectedLabels(filteredLabels);
  };

  const onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      setOnLoadMore(true);
      await getLabels({ olderThan });
      setOnLoadMore(false);
    };

    const hasMore = olderThan ? true : false;

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 50, () => {
        loadMore();
      });
    }
  };

  useEffect(() => {
    getLabels();
  }, []);

  useEffect(() => {
    setFilteredLabels(labelList);
  }, [labelList]);

  return (
    <div
      data-cy="label-section scroll-label"
      className={labelList.length > 0 ? 'label-section' : ''}
      onScroll={onScroll}
      id="styled-scrollbar"
    >
      {filteredLabels.map((label) => {
        const isSelected = currentSelectedLabels
          .map((current) => current.id)
          .includes(label.id);
        return (
          <span
            className={`label-item typography-h200 ${
              isSelected ? 'selected-label' : ''
            }`}
            data-cy={`label-item-${label.id}`}
            style={{ backgroundColor: isSelected ? `${label.colorCode}` : '' }}
            onClick={() =>
              updateSelectedLabel(label.id, label.title, label.labelType)
            }
            key={label.id}
          >
            {label.labelType === 'announcement' && (
              <img
                src={Config.asset.admin.announcementLabel}
                className="announce-icon"
                data-cy="announce-icon"
              />
            )}
            {label.title}
          </span>
        );
      })}
    </div>
  );
}

export default LabelSection;

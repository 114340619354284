import React from 'react';

import SVGIcon from 'components/shared/SVGIcon';

const Attachment = ({ onClick }) => {
  return (
    <div className="px-[24px]" onClick={onClick}>
      <div
        className="h-[48px] flex items-center cursor-pointer"
        data-cy="show-attachment-section"
      >
        <SVGIcon
          iconName="icon-attach_file"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-[16px]"
        />
        <span className="text-v-600">Show all attachment</span>
      </div>
    </div>
  );
};

export default Attachment;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { useAuth } from 'context/AuthContext';
import { useCreateCycle } from 'context/admin/CreateCycleContext';

import Button from 'components/design-system/Button';
import BannerBox from 'components/shared/BannerBox';
import SVGIcon from 'components/shared/SVGIcon';

import ReminderList from './ReminderList';

function ReminderPhase({ endsAt, useReminder, phaseId }) {
  const {
    data: {
      config: { maxNumberOfReviewReminders }
    }
  } = useAuth();

  const { cycleData, changePhaseData } = useCreateCycle();

  const [reminderList, setReminderList] = useState([]);

  let defaultBeforeDeadlineDate = dayjs(endsAt)
    .subtract(30, 'days')
    .toISOString();

  const addReminder = () => {
    // endsAt dikurangin 30 days
    setReminderList([
      ...reminderList,
      {
        remindsAt: defaultBeforeDeadlineDate,
        state: 'active',
        key: 'beforeDeadline',
        description: 'Before Deadline'
      }
    ]);
  };

  // Changes based on dropdown
  const changeStateReminderKey = (reminderDropdownObj, stateIndex) => {
    let tempReminderList = [...reminderList];
    let intendedIndex = tempReminderList.findIndex(
      (step, index) => index === stateIndex
    );
    let edittedReminderObj = { ...tempReminderList[intendedIndex] };
    if (reminderDropdownObj.key === 'deadlineDay') {
      edittedReminderObj.remindsAt = endsAt;
    } else {
      edittedReminderObj.remindsAt = defaultBeforeDeadlineDate;
    }
    edittedReminderObj.key = reminderDropdownObj.key;
    edittedReminderObj.description = reminderDropdownObj.description;
    tempReminderList[intendedIndex] = edittedReminderObj;
    setReminderList(tempReminderList);
  };

  // Change based on daysValue
  const changeStateRemindsAt = (calculatedDate, stateIndex) => {
    let tempReminderList = [...reminderList];
    let intendedIndex = tempReminderList.findIndex(
      (step, index) => index === stateIndex
    );
    let edittedReminderObj = { ...tempReminderList[intendedIndex] };
    edittedReminderObj.remindsAt = calculatedDate;
    tempReminderList[intendedIndex] = edittedReminderObj;
    setReminderList(tempReminderList);
  };

  const handleDeleteReminder = (stateIndex) => {
    let tempReminderList = [...reminderList];
    tempReminderList.splice(stateIndex, 1);
    setReminderList(tempReminderList);
  };

  useEffect(() => {
    let reminderListToContext = reminderList.map((reminder) => ({
      remindsAt: reminder.remindsAt
    }));
    changePhaseData(phaseId, 'reminders', reminderListToContext);
  }, [reminderList]);

  useEffect(() => {
    let phaseReminders = cycleData[phaseId]?.reminders || [];

    if (phaseReminders?.length > 0) {
      let localStateReminders = phaseReminders?.map((reminder) => {
        if (reminder.remindsAt === endsAt) {
          return {
            remindsAt: endsAt,
            state: 'active',
            key: 'deadlineDay',
            description: 'On the day of deadline'
          };
        } else {
          return {
            remindsAt: reminder.remindsAt,
            state: 'active',
            key: 'beforeDeadline',
            description: 'Before Deadline'
          };
        }
      });
      setReminderList(localStateReminders || []);
    } else if (useReminder && reminderList.length === 0) {
      setReminderList([
        {
          remindsAt: endsAt,
          state: 'active',
          key: 'deadlineDay',
          description: 'On the day of deadline'
        }
      ]);
    }
  }, []);

  return (
    <div>
      <BannerBox
        text="System will send email reminders to reviewers who havent submitted a review yet."
        type="info"
        customClass="mt-[24px] mb-[16px]"
      />
      {reminderList.map((reminderObj, index) => (
        <ReminderList
          key={index}
          indexProp={index}
          reminderObj={reminderObj}
          changeStateReminderKey={changeStateReminderKey}
          endsAt={endsAt}
          changeStateRemindsAt={changeStateRemindsAt}
          reminderList={reminderList}
          handleDeleteReminder={handleDeleteReminder}
        />
      ))}
      {reminderList.length < maxNumberOfReviewReminders && (
        <Button.Tertiary customClass="mt-[16px]" onClick={() => addReminder()}>
          <SVGIcon
            customClass="mr-[8px]"
            iconName="icon-add"
            fillColor="var(--base-600)"
            size={24}
          />
          Add Reminder
        </Button.Tertiary>
      )}
    </div>
  );
}

export default ReminderPhase;

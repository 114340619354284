import React, { useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import Checkbox from 'components/shared/Checkbox';
import Modal from 'components/shared/modal/Modal';

const ModalSetDependencies = ({
  showModal,
  onSubmitModal,
  setShowModal,
  tableData,
  toggleTempDependencies,
  tempDependencies
}) => {
  const { notificationRecurrences } = showModal;

  const [useNotification, setUseNotification] = useState(
    notificationRecurrences || false
  );
  const [notifRecurrence, setNotifRecurrence] = useState(
    notificationRecurrences || 'each_time'
  );
  const filteredTableData = tableData.filter(
    (phase) => phase.id !== showModal.id
  );

  const listValue = [
    {
      text: 'Each Time',
      val: 'each_time',
      onClick: () => setNotifRecurrence('each_time')
    },
    {
      text: 'Daily',
      val: 'daily',
      onClick: () => setNotifRecurrence('daily')
    },
    {
      text: 'Weekly',
      val: 'weekly',
      onClick: () => setNotifRecurrence('weekly')
    },
    {
      text: 'Biweekly',
      val: 'biweekly',
      onClick: () => setNotifRecurrence('biweekly')
    },
    {
      text: 'Monthly',
      val: 'monthly',
      onClick: () => setNotifRecurrence('monthly')
    }
  ];

  const defaultDropdownValue = listValue.find(
    (value) => value.val == (notificationRecurrences || notifRecurrence)
  )?.text;

  const recurrenceDictionary = {
    weekly: 7,
    biweekly: 14,
    monthly: 30
  };

  const handleChangeTempDependencis = (val) => {
    toggleTempDependencies(val);
    if (tempDependencies.length == 0 && !useNotification) {
      setUseNotification(true);
    }
  };

  return (
    <Modal
      className="w-[400px]"
      title={`${showModal.name} Dependency`}
      description="Assignment(s) on selected review phases must be completed before reviewer can start reviewing current phase."
      eventOnClickClose={() => setShowModal(null)}
      withPrimaryBtn={{
        onClick: () => onSubmitModal(useNotification, notifRecurrence),
        dataCy:
          showModal.dependencies.length > 0
            ? 'edit-dependency'
            : 'add-dependency',
        title:
          showModal.dependencies.length > 0 ? 'Save Changes' : 'Add Dependency',
        disabled: tempDependencies.length === 0
      }}
      withSecondaryBtn={{
        onClick: () => setShowModal(null),
        dataCy: 'cancel-edit-dependency',
        title: 'Cancel'
      }}
      withFooter
    >
      {filteredTableData.map((phase, index) => (
        <div key={index} className={`${index == 0 ? '' : 'mt-[8px]'}`}>
          <Checkbox
            name={phase.id}
            value={phase.value}
            id={phase.id}
            checked={tempDependencies.includes(phase.value)}
            onChange={(val) => handleChangeTempDependencis(val.target.value)}
            dataCy={`select-${phase.value}`}
            customContainerClass="max-h-[24px]"
          >
            <span className="ml-[8px] typography-paragraph text-n-900">
              {getObjectiveLocale(`${phase.name} Review`)}
            </span>
          </Checkbox>
        </div>
      ))}
      {tempDependencies.length > 0 && (
        <div className="bg-n-100 py-[16px] px-[16px] rounded-[4px] mt-[24px] border border-solid border-n-300">
          <h4 className="typography-h400 mb-[16px]">
            {getObjectiveLocale('Notification Method')}
          </h4>
          <div className="flex">
            <Checkbox
              customContainerClass="h-[24px]"
              value={useNotification}
              id="notification-method"
              checked={useNotification}
              onChange={() => setUseNotification(!useNotification)}
            />
            <span
              className="ml-[8px] typography-paragraph text-n-900"
              onClick={() => setUseNotification(!useNotification)}
            >
              {getObjectiveLocale(
                'Send email when dependent assignment(s) for this review phase has been submitted'
              )}
            </span>
          </div>
          {useNotification && (
            <div className="mt-[16px]">
              <Dropdown customClass="w-full">
                <Dropdown.Trigger>
                  <Trigger
                    customWrapperClass="w-full"
                    topLabel={false}
                    label="Email frequency"
                    icon={false}
                    text={defaultDropdownValue}
                  />
                </Dropdown.Trigger>
                <Dropdown.MenuItems options={listValue} customClass="w-full" />
              </Dropdown>
              <p className="typography-h100 text-n-600 mt-[8px]">
                {notifRecurrence == 'each_time'
                  ? getObjectiveLocale(
                      'Our system will send an individual notification each time an assignment has been unlocked.'
                    )
                  : getObjectiveLocale(
                      `Our system will send a notification summary of assignment(s) that has been unlocked every${
                        recurrenceDictionary[notifRecurrence]
                          ? ` ${recurrenceDictionary[notifRecurrence]} `
                          : ''
                      }day${recurrenceDictionary[notifRecurrence] ? 's' : ''}.`
                    )}
              </p>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ModalSetDependencies;

import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

const CircleChart = ({
  datacy,
  title,
  highchartValue,
  runningValue,
  completedValue,
  reviewedValue,
  color1,
  color2
}) => {
  const configCircleChart = {
    title: {
      text: ''
    },
    chart: {
      style: {
        fontFamily: 'Inter'
      },
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      width: '80',
      height: '80'
    },

    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'auto',
        events: {
          legendItemClick: function () {
            return false;
          }
        },
        allowPointSelect: false,

        dataLabels: {
          enabled: false
        },

        showInLegend: false,
        size: 78
      }
    },
    series: [
      {
        colorByPoint: true,
        animation: false,
        innerSize: '70%',
        states: {
          hover: {
            enabled: false
          }
        },

        data: [
          {
            name: 'Goal Accomplishments',
            y:
              (completedValue /
                (runningValue + completedValue + reviewedValue)) *
              100,
            color: color1
          },
          {
            name: 'Goal Accomplishments Left',
            y:
              ((runningValue + reviewedValue) /
                (runningValue + completedValue + reviewedValue)) *
              100,
            color: color2
          }
        ]
      }
    ]
  };
  return (
    <div className="col-md-4" data-cy={datacy}>
      <div className="overview-box">
        <div className="accomplishment-card-container">
          <span className="typography-h200 mb-[24px]">{title}</span>
          <div className="wrapper-highchart">
            <HighchartsReact
              highcharts={Highcharts}
              options={configCircleChart}
            />
            <span className="highchart-value" style={{ color: color1 }}>
              {isNaN(highchartValue) ? '0' : getNumberLocale(highchartValue)}%
            </span>
          </div>
          <div className="parent-wrapper-status">
            <div className="wrapper-status">
              <span className="typography-h100 text-n-800 mb-[8px]">
                {getObjectiveLocale('Running')}
              </span>
              <span className="typography-h500">
                {getNumberLocale(runningValue)}
              </span>
            </div>
            <div className="wrapper-status">
              <span className="typography-h100 text-n-800 mb-[8px]">
                {getObjectiveLocale('Completed')}
              </span>
              <span className="typography-h500">
                {getNumberLocale(completedValue)}
              </span>
            </div>
            <div className="wrapper-status">
              <span className="typography-h100 text-n-800 mb-[8px]">
                {getObjectiveLocale('Reviewed')}
              </span>
              <span className="typography-h500">
                {getNumberLocale(reviewedValue)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircleChart;

import React, { useEffect, useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import RadioButton from 'components/shared/RadioButton';

const FinalScoreFormula = ({
  formulaFinalScore,
  setFormulaFinalScore,
  putData
}) => {
  const [active, setActive] = useState();
  const config = [
    {
      key: 'divided_by_total_weight',
      description: 'Aspect score times weight divided by total weight'
    },
    { key: 'score_times_weight', description: 'Aspect score times weight' }
  ];

  const handleChangeFinal = (key) => {
    putData && putData(key);
    setActive(key);
    setFormulaFinalScore(key);
  };

  useEffect(() => {
    setActive(formulaFinalScore);
  }, [formulaFinalScore]);

  return (
    <div className="pb-[40px]" id="final-score-formula">
      <p className="typography-h500 mb-[4px]">
        {getObjectiveLocale('Final Score Formula')}
      </p>
      <p className="text-n-800 mb-[16px]">
        {getObjectiveLocale(
          'Which formula do you want to use for final score calculation?'
        )}
      </p>
      {config.map((val) => (
        <div className="wrapper-radio-button" key={val.key}>
          <RadioButton
            dataCy={'final_' + val.key}
            id={val.key}
            value={val.description}
            checked={active == val.key}
            onChange={() => handleChangeFinal(val.key)}
          >
            <span className="ml-[8px]">
              {val.description}{' '}
              {val.key == 'divided_by_total_weight' && (
                <span className="text-n-600">{'(default)'}</span>
              )}
            </span>
          </RadioButton>
        </div>
      ))}
    </div>
  );
};

export default FinalScoreFormula;

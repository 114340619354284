import React, { useEffect, useState } from 'react';

import { getMostDataGiven } from 'client/admin/Overview';

import StrengthComponent from './StrengthComponent';

function MostFeedbackGiven() {
  const [weaknessSkills, setWeaknessSkills] = useState({});

  const _getMostDataGiven = async () => {
    const query = {
      limit: 5,
      sortColumn: 'weakness_count',
      sortDirection: 'desc'
    };
    const { data, error } = await getMostDataGiven(query);
    if (data) {
      setWeaknessSkills(data);
    }
  };

  useEffect(() => {
    _getMostDataGiven();
  }, []);

  return (
    <div className="col-xs-6">
      <div className="item-overview">
        <div className="strength-title">
          Most Feedback Given<span className="description"></span>
        </div>
        <StrengthComponent
          type="topFiveWeakness"
          weaknessSkills={weaknessSkills}
        />
      </div>
    </div>
  );
}

export default MostFeedbackGiven;

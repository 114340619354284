import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import debounce from 'lodash/debounce';

import { getReviewerRevieweePhase } from 'client/FormalReviewClient';
import { getCycleProgress, getCycles } from 'client/admin/CyclesClient';
import { getRevieweeList } from 'client/admin/FormalReviewRevieweeClient';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import CycleSidebar from 'components/admin/Cycles/CycleSidebar';
import ModalWarningEdit from 'components/admin/Cycles/CyclesDetails/ModalWarningEdit';
import CyclesTable from 'components/admin/Cycles/CyclesTable';
import ModalCycleDependency from 'components/admin/Cycles/ModalCycleDependency';
import RevieweeList from 'components/admin/Cycles/RevieweeAssignments/RevieweeList';
import ModalDeleteEndCycle from 'components/admin/Cycles/components/ModalDeleteEndCycle';
import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';

const getCycleCount = (countData) => {
  return getNumberLocale(
    countData?.activeCount + countData?.endedCount + countData?.deletedCount ||
      0
  );
};

const cycleOptions = (cycle, formatProps, sidebar, isReadOnly, permissions) => {
  const { history, setShowModal, setShowWarningModal } = formatProps;

  const options = [
    {
      text: 'Edit Cycle',
      iconName: 'icon-create',
      onClick: () =>
        cycle?.state === 'draft'
          ? history.push(`/appraisals/cycles/${cycle?.id}/edit`)
          : setShowWarningModal({ show: true, data: cycle }),
      isVisible: !sidebar && cycle?.state !== 'done'
    },
    {
      text: 'Add New Reviewee',
      iconName: 'icon-person_add',
      onClick: () => history.push(`/add-new-reviewees/${cycle?.id}`),
      isVisible:
        cycle?.state === 'in_progress' && permissions?.reviewPerformanceCycleSee
    },
    {
      text: 'Cycle Detail',
      iconName: 'icon-table_chart',
      onClick: () => history.push(`/cycles/detail-cycle/${cycle?.id}`),
      isVisible: true
    },
    {
      text: 'Set Schedule',
      iconName: 'icon-calendar_today',
      onClick: () =>
        history.push(
          `/appraisals/cycles/${cycle?.id}/edit?step=set-up-schedule`
        ),
      isVisible: !sidebar && cycle?.state == 'draft'
    },
    {
      text: `${cycle?.dependencies?.length > 0 ? 'Edit' : 'Add'} Dependencies`,
      iconName:
        cycle?.dependencies?.length > 0 ? 'icon-create' : 'icon-add_box',
      onClick: () =>
        setShowModal({
          id: `${cycle?.dependencies?.length > 0 ? 'edit' : 'add'}-dep`,
          cycleId: cycle?.id,
          cycleName: cycle?.name,
          cycleDependencies: cycle?.dependencies?.map((data) => data.id)
        }),
      isVisible: true
    },
    {
      text: 'Duplicate Cycle',
      iconName: 'icon-file_copy',
      onClick: () =>
        history.push(`/appraisals/cycles/${cycle?.id}/copy-cycles`),
      isVisible: true
    },
    cycle?.state !== 'done' && {
      text: 'Edit',
      iconName: 'icon-create',
      onClick: () =>
        cycle?.state === 'draft'
          ? history.push(`/appraisals/cycles/${cycle?.id}/edit`)
          : setShowWarningModal({ show: true, data: cycle })
    },
    cycle?.state == 'in_progress' && {
      text: 'End Cycle',
      iconName: 'icon-power_settings_new',
      onClick: () =>
        setShowModal({
          id: 'end-cycle',
          cycleId: cycle?.id,
          cycleName: cycle?.name
        }),
      isVisible: cycle?.state == 'in_progress'
    },
    {
      text: 'Delete Cycle',
      iconName: 'icon-delete',
      iconColor: 'var(--r-600)',
      textColor: 'text-r-600',
      onClick: () =>
        setShowModal({
          id: 'delete',
          cycleId: cycle?.id,
          cycleName: cycle?.name
        }),
      isVisible: ['draft', 'done'].includes(cycle?.state)
    }
  ].filter(({ isVisible }) => isVisible);

  return isReadOnly ? [options[2]] : options;
};

function Cycles({ route: { title, isReadOnly } }) {
  const history = useHistory();
  const {
    config: {
      permissions,
      involvementCyclesFilter,
      typeCyclesFilter,
      defaultInvolvementCyclesFilter,
      defaultTypeCyclesFilter
    }
  } = useUser();
  const { reloadAdminCycles, reloadSidebar } = useReload();
  const [countData, setCountData] = useState({});
  const [countDataReviewee, setCountDataReviewee] = useState({});
  const [cycles, setCycles] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState('');
  const [sidebar, setSidebar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [activeTab, setActiveTab] = useState('cycle');
  const [showWarningModal, setShowWarningModal] = useState({
    show: false,
    data: {}
  });
  const filter = useFilter((state) => state.filterById?.cyclesFilter);
  const hasMore = pagination?.olderThan !== null;
  const formatProps = { history, setShowModal, setShowWarningModal };
  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');

  const { reviewPerformanceAssignmentsManage } = permissions;

  const getCycleData = async (additionalParams = {}) => {
    setLoading(true);
    const { q: search, type, involvement, ...params } = filter;
    const { data, pagination, metadata } = await getCycles({
      search,
      ...params,
      ...additionalParams,
      limit: 10,
      sortColumn: 'created_at',
      sortDirection: 'desc',
      ...(type?.length == 1 && { type: type[0] }),
      ...(involvement?.length == 1 && {
        involvementBased: involvement[0]
      })
    });

    if (data) {
      const cycleDatas = data.map(async (cycle) => {
        const { data: progress } = await getCycleProgress(cycle.id, {
          userState: ['pending', 'active']
        });
        return { ...cycle, progress };
      });
      await Promise.all(cycleDatas).then((cycles) => setCycles(cycles));
    }
    if (pagination)
      setPagination({
        olderThan: data.length < 10 ? null : pagination.next.olderThan,
        newerThan: pagination.prev.newerThan
      });
    if (reviewPerformanceAssignmentsManage) {
      const { metadata: metaReviewee } = await getRevieweeList();
      if (metaReviewee) setCountDataReviewee(metaReviewee);
    }
    if (metadata) setCountData(metadata);
    !isReady && setIsReady(true);
    setLoading(false);
  };

  const getSidebarData = async () => {
    setLoading(true);
    const { data } = await getReviewerRevieweePhase(sidebar.id, {});
    if (data) setSidebar(data);
    setLoading(false);
  };

  const changePage = (type) => {
    const paginationKey = type === 'next' ? 'olderThan' : 'newerThan';
    getCycleData({ [paginationKey]: pagination[paginationKey] });
    setCurrentPage(currentPage + (type === 'next' ? 1 : -1));
  };

  const debounceFn = useCallback(
    debounce(() => {
      if (!showModal) {
        getCycleData();
        setCurrentPage(1);
      }
    }, 1000),
    [filter, reloadAdminCycles]
  );

  useEffect(() => {
    debounceFn();
    return debounceFn.cancel;
  }, [filter, reloadAdminCycles]);

  useEffect(() => {
    if (sidebar) getSidebarData();
  }, [reloadSidebar]);

  useEffect(() => {
    setActiveTab(tabParams || 'cycle');
  }, [tabParams]);

  const headerProps = {
    titlePage: title,
    primaryAction: {
      title: 'Create New Cycle',
      icon: 'icon-add',
      onClick: () => history.push('/appraisals/cycles/create'),
      dataCy: 'button-cycles',
      disabled: isReadOnly
    }
  };

  const filterMenu = [
    { option: 'state', title: 'Status', dialog: 'cycle-state' },
    { option: 'schedule', title: 'Schedule', dialog: 'cycle-schedule' },
    ...(typeCyclesFilter?.length > 0
      ? [
          {
            option: 'type',
            title: 'Type',
            dialog: 'type'
          }
        ]
      : []),
    ...(involvementCyclesFilter?.length > 0
      ? [
          {
            option: 'involvement',
            title: 'Involvement',
            dialog: 'involvement'
          }
        ]
      : [])
  ];

  let defaultFilter = {
    state: ['in_progress', 'draft', 'done'],
    ...(involvementCyclesFilter?.length > 0 && {
      involvement: defaultInvolvementCyclesFilter
    }),
    ...(typeCyclesFilter?.length > 0 && {
      type: defaultTypeCyclesFilter
    })
  };

  const resultModifier = ({ state, type, ...restFilter }) => {
    let newResult = {
      state: state?.map((state) =>
        state === 'in_progress' ? 'active' : state === 'done' ? 'Ended' : state
      ),
      type: type?.map((type) => getObjectiveLocale(`[filter-type] ${type}`)),
      ...restFilter
    };
    return newResult;
  };

  const listObjTabs = [
    { id: 'cycle', name: 'Cycles', count: getCycleCount(countData) }
  ];

  if (reviewPerformanceAssignmentsManage) {
    listObjTabs.push({
      id: 'reviewee',
      name: 'Reviewee',
      count: getNumberLocale(countDataReviewee?.revieweesCount || 0)
    });
  }

  return (
    <>
      <HeaderPage
        {...headerProps}
        tabs={{
          tabsList: listObjTabs,
          url: '/appraisals/cycles',
          onChange: (tab) => {
            setActiveTab(tab);
          },
          withCount: true
        }}
      />
      <div className="px-[0px] h-screen bg-n-000 overflow-auto">
        <div
          className="px-[40px] pt-[24px] gap-[16px] grid grid-cols-1"
          data-cy="list-cycles-container"
        >
          {activeTab == 'cycle' && (
            <>
              <AdvancedFilter
                filterOptions={['search', 'menuFilter']}
                filterMenu={filterMenu}
                resultModifier={resultModifier}
                defaultFilter={defaultFilter}
                id="cyclesFilter"
              />
              <CyclesTable
                cycles={cycles}
                cycleOptions={(cycle) =>
                  cycleOptions(
                    cycle,
                    formatProps,
                    null,
                    isReadOnly,
                    permissions
                  )
                }
                setSidebar={setSidebar}
                currentPage={currentPage}
                handleNextPage={() => changePage('next')}
                handlePrevPage={() => changePage('prev')}
                hasMore={hasMore}
                isLoading={loading}
              />
              {sidebar && (
                <CycleSidebar
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                  cycleOptions={(cycle) =>
                    cycleOptions(
                      cycle,
                      formatProps,
                      sidebar,
                      isReadOnly,
                      permissions
                    )
                  }
                  isReadOnly={isReadOnly}
                  setCurrentPage={setCurrentPage}
                />
              )}
              {(showModal?.id === 'delete' ||
                showModal?.id === 'end-cycle') && (
                <ModalDeleteEndCycle
                  modalType={showModal?.id}
                  cycleId={showModal?.cycleId}
                  cycleName={showModal?.cycleName}
                  onCloseModal={() => setShowModal(null)}
                />
              )}
              {['add-dep', 'edit-dep'].includes(showModal?.id) && (
                <ModalCycleDependency
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              )}
              {showWarningModal?.show && (
                <ModalWarningEdit
                  cycleId={showWarningModal?.data?.id}
                  stateData={showWarningModal?.data?.state}
                  totalReviewer={showWarningModal?.data?.totalReviewees}
                  source="cycles"
                  onCloseModal={() => setShowWarningModal(null)}
                />
              )}
            </>
          )}
          {activeTab == 'reviewee' && <RevieweeList />}
        </div>
      </div>
    </>
  );
}

export default Cycles;

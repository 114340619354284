import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { Link } from 'react-router-dom';

import { getObjectiveLocale } from 'utils/HelperUtils';
import { capitalize } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import { useUser } from 'src/context/UserContext';
import routes from 'src/routes';

const NotificationMenuCount = ({ count, showCount = true }) => {
  if (!count) return <></>;
  if (!showCount)
    return (
      <SVGIcon
        iconName="icon-circle"
        fillColor="var(--r-600)"
        size="8"
        customClass="mr-[8px]"
      />
    );
  return <div className="number-notification typography-h100">{count}</div>;
};

function MenuSection({
  setIsHaveNotif,
  isHaveNotif,
  setShowRightMenu = () => null,
  activitiesCount,
  needResponseCount,
  approvalCount,
  surveyCount,
  feedbackCount,
  formalReviewCount,
  needResponseReviewCount,
  needResponseCommentCount
}) {
  const data = routes();
  const { organization } = useUser();
  const isBca = organization.identifier === 'bca';

  const [listOpenedMenu, setListOpenMenu] = useState([
    data[0].routes[0].parent
  ]);
  const [listMenu, setListMenu] = useState([]);

  const getParentNotification = (parentName, menuName) => {
    const listParentWithDot = [
      'My Objective',
      'Need Responses',
      'Surveys',
      'Performance Review',
      'Growth'
    ];
    const listMenuWithNotif = [
      'Need Response Objectives',
      'My Activities',
      'Approval',
      'Pulse Surveys',
      'Performance Review',
      'Feedback page'
    ];
    let showNotif = [];
    let isMenuHaveCount = false;

    menuName.map((val) => {
      if (listMenuWithNotif.includes(val.title)) {
        showNotif.push(true);
        if (val.title == 'Approval' && approvalCount > 0) {
          isMenuHaveCount = true;
        } else if (
          val.title == 'Need Response Objectives' &&
          needResponseCount > 0
        ) {
          isMenuHaveCount = true;
        } else if (val.title == 'My Activities' && activitiesCount > 0) {
          isMenuHaveCount = true;
        } else if (val.title == 'Pulse Surveys' && surveyCount > 0) {
          isMenuHaveCount = true;
        } else if (val.title == 'Performance Review' && formalReviewCount > 0) {
          isMenuHaveCount = true;
        } else if (val.title == 'Feedback page' && feedbackCount > 0) {
          isMenuHaveCount = true;
        }
      } else {
        showNotif.push(false);
      }
    });
    if (
      listParentWithDot.includes(parentName) &&
      showNotif &&
      isMenuHaveCount
    ) {
      if (!isHaveNotif) {
        setIsHaveNotif(true);
      }
      return (
        <SVGIcon
          iconName="icon-circle"
          fillColor="var(--r-600)"
          size="8"
          customClass="ml-[8px]"
        />
      );
    }
  };

  const setOpenMenu = (menu) => {
    let newListMenu = listOpenedMenu === menu ? [] : menu;
    setListOpenMenu(newListMenu);
  };

  useEffect(() => {
    let listOfIndex = {};
    let listMenuNew = [];

    data[0].routes.map((menu) => {
      if (menu.parent) {
        const indexOfParent = listOfIndex[menu.parent];
        if (indexOfParent == null) {
          const index = listMenuNew.push({ name: menu.parent }) - 1;
          listOfIndex[menu.parent] = index;
          listMenuNew[index]['menu'] = [menu];
        } else {
          listMenuNew[indexOfParent]['menu'].push(menu);
        }
      }

      const currentPath = matchPath(location.pathname, menu);
      currentPath &&
        !listOpenedMenu.includes(menu.parent) &&
        menu.parent &&
        setListOpenMenu(menu.parent);
    });

    listMenuNew = listMenuNew.filter((data) => data.menu.length > 0);
    setListMenu(listMenuNew);
  }, []);

  return (
    <>
      {listMenu.map((parentMenu, key) => (
        <div
          key={key}
          className="menu-section"
          data-cy={parentMenu.name.replace(/\s+/g, '-').toLowerCase()}
        >
          <div
            className={`h-[48px] ${
              parentMenu.menu.length > 0 ? 'title-section' : 'unhover-title'
            }`}
            onClick={() => setOpenMenu(parentMenu.name)}
          >
            <div className="my-[12px] flex items-center text-n-600 typography-h300">
              {getObjectiveLocale(parentMenu.name).toUpperCase()}
              {getParentNotification(parentMenu.name, parentMenu.menu)}
            </div>
            <SVGIcon
              size="24"
              fillColor="var(--n-000)"
              iconName={
                listOpenedMenu.includes(parentMenu.name)
                  ? 'icon-keyboard_arrow_down'
                  : 'icon-keyboard_arrow_right'
              }
              customClass="toggle-menu-icon"
            />
          </div>
          <div className="navbar-list">
            {listOpenedMenu.includes(parentMenu.name) &&
              parentMenu.menu.map((data, key) => {
                let currentPath = matchPath(location.pathname, data);
                let pathMenu =
                  typeof data.path === 'object' ? data.path[0] : data.path;
                return (
                  <div
                    className="navbar-list-item"
                    key={key}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowRightMenu();
                    }}
                  >
                    <Link to={pathMenu}>
                      <div
                        data-cy={`${data.title
                          .replace(/\s+/g, '-')
                          .toLowerCase()}`}
                        className={`sub-menu text-n-000 py-[6px] typography-button ${
                          currentPath ? 'active' : ''
                        }`}
                      >
                        {capitalize(getObjectiveLocale(data.title))}
                        {data.title == 'Need Response Objectives' && (
                          <NotificationMenuCount count={needResponseCount} />
                        )}
                        {data.title == 'My Activities' && (
                          <NotificationMenuCount count={activitiesCount} />
                        )}
                        {data.title == 'Approval' && (
                          <NotificationMenuCount count={approvalCount} />
                        )}
                        {data.title == 'Pulse Surveys' && (
                          <NotificationMenuCount count={surveyCount} />
                        )}
                        {data.title == 'Give Review' && (
                          <NotificationMenuCount
                            count={needResponseReviewCount}
                          />
                        )}
                        {data.title == 'Give Comment' && (
                          <NotificationMenuCount
                            count={needResponseCommentCount}
                          />
                        )}
                        {data.title == 'Performance Review' && (
                          <NotificationMenuCount
                            count={formalReviewCount}
                            showCount={!isBca}
                          />
                        )}
                        {data.title == 'Feedback page' && (
                          <NotificationMenuCount
                            count={feedbackCount}
                            showCount={!isBca}
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      ))}
    </>
  );
}

export default MenuSection;

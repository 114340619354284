import React from 'react';
import ReactDOM from 'react-dom';

import { useUrl } from 'hooks/useUrl';
import Config from 'utils/Config.js';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';

function AddSubGoalCard({
  objectiveValue,
  setObjectiveValue,
  selectedOwner,
  closeSidebar
}) {
  const { url } = useUrl();
  const name = objectiveValue.name;
  let childName = `Add ${getObjectiveLocale(
    objectiveValue.type === 'goal'
      ? objectiveValue.isProject
        ? 'Project'
        : 'Sub Goal'
      : 'Task Name'
  )}`;

  const handleChangeTitle = (title) => {
    setObjectiveValue((draft) => {
      draft.name = title;
    });
  };

  return (
    <div className="flex p-16">
      <div className="user-icon">
        {!url.includes('reviews') &&
          (selectedOwner.length > 1 ? (
            <img
              className="img-circle w-[36px] mr-[16px]"
              src={Config.asset.v2.icoGroup}
            />
          ) : (
            <Avatar
              name={selectedOwner[0] ? selectedOwner[0].user.name : ''}
              src={
                selectedOwner[0]
                  ? selectedOwner[0].user.profilePicture
                  : Config.asset.general.defaultPicture
              }
              className="mr-[16px]"
            />
          ))}
      </div>
      <InputField
        placeholder={name == '' ? childName : name}
        value={name}
        handleChange={(e) => handleChangeTitle(e.target.value)}
      />
      <SVGIcon
        iconName="icon-clear"
        size="24"
        fillColor="var(--n-000)"
        customClass="bg-n-600 rounded-[16px] h-[24px] ml-[8px] mt-[4px]"
        onClick={() => closeSidebar()}
      />
    </div>
  );
}

const AddSubGoalCardPortal = (props) => {
  let objectiveElementId = document.querySelectorAll(
    `[id^="add-sub-goal-for-${props.id}"]`
  )[0];
  return ReactDOM.createPortal(
    <AddSubGoalCard {...props} />,
    objectiveElementId
  );
};

export default AddSubGoalCardPortal;

import React from 'react';
import { useQuery } from 'react-query';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getCycleProgress } from 'client/FormalReviewClient';
import { useUser } from 'context/UserContext';
import { phaseName } from 'utils/FormalReview';
import { getProgressTooltipComponent } from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

function ReviewProgressChart({ chartData }) {
  const configCircleChart = {
    title: {
      text: ''
    },
    chart: {
      renderTo: 'circle-chart',
      margin: [0, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      width: '52',
      height: '52'
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        size: '100%',
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [
      {
        animation: false,
        innerSize: '70%',
        states: {
          hover: {
            enabled: false
          }
        },
        data: [
          {
            name: 'Goal Accomplishments left',
            y: chartData.progressPercentage,
            color: 'var(--g-600)'
          },
          {
            name: 'Goal Accomplishments Left',
            y: 100 - chartData.progressPercentage,
            color: 'var(--n-400)'
          }
        ]
      }
    ]
  };

  const getNumber = () => {
    let number = chartData.progressPercentage;
    if (chartData.progressPercentage % 1 !== 0) {
      number = parseFloat(chartData.progressPercentage).toFixed(2);
    }
    return number;
  };

  return (
    <div className="review-progress-card w-[184px] mr-[16px] last:mr-[0px]">
      <p
        className="typography-h400 typography-heading typography-secondary capitalize mb-[12px]"
        data-cy="review-type-chart"
      >
        {phaseName(chartData.type)}
      </p>

      <div className="container-chart">
        <TooltipContainer
          show
          classContainer="cursor-pointer flex"
          useMaxWidth={false}
          tooltipClass="!bg-n-000 shadow-overlay"
          tooltipContent={() => getProgressTooltipComponent(chartData)}
        >
          <div className="circle-chart">
            <HighchartsReact
              highcharts={Highcharts}
              options={configCircleChart}
            />
          </div>
        </TooltipContainer>

        <p
          className="typography-h800 typography-heading text-n-900"
          data-cy={`review-percentage-chart-${chartData.type}`}
        >{`${chartData.progressPercentage ? getNumber() : 0}%`}</p>
      </div>
      <p
        className="typography-h400 typography-paragraph typography-tertiary capitalize"
        data-cy={`review-state-chart-${chartData.type}`}
      >
        {chartData.state.replace(/_/g, ' ')}
      </p>
    </div>
  );
}

const PhaseProgress = ({ cycle, phase, currentTab }) => {
  const {
    config: {
      permissions: { reviewPerformanceCycleProgressAllSee }
    }
  } = useUser();
  let query = {
    userState: ['active', 'pending', 'deleted']
  };
  if (currentTab == 'my reviews') {
    query.progressType = 'my_progress';
  } else if (
    currentTab == 'progress and results' &&
    !reviewPerformanceCycleProgressAllSee
  ) {
    query.progressType = 'team_progress';
  }

  const getProgress = () => {
    return getCycleProgress(cycle.id, phase.type, query);
  };

  let { data, isLoading } = useQuery(
    ['progress', cycle.id, phase.type, query],
    getProgress,
    {
      staleTime: 60000 // 1 minute
    }
  );

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col">
          <Shimmer width={80} height={16} />
          <Shimmer width={50} height={50} circle customClass="mt-[8px]" />
        </div>
      ) : (
        <ReviewProgressChart chartData={data?.data} />
      )}
    </>
  );
};

function ReviewProgressComponent({ cycle, currentTab }) {
  const phases = cycle.phases.filter((phase) => {
    return (
      phase?.permissions.progressesSee &&
      phase.type !== 'calibration' &&
      phase.type !== 'peer_selection'
    );
  });

  // temporary code until backend assign orderlevel to phase
  const defaultLevel = {
    self_review: 1,
    upward_review: 2,
    peer_review: 3,
    manager_review: 4,
    indirect_manager_review: 5
  };
  const orderedPhases = phases
    .map((phase) => {
      return { ...phase, orderLevel: defaultLevel[phase.type] };
    })
    .sort((a, b) => (a.orderLevel > b.orderLevel ? 1 : -1));

  return (
    <div className="column-card-phase flex-col">
      <div className="pl-[48px] relative" data-cy="review-progress-desc">
        <div className="icon-card-phase">
          <SVGIcon
            iconName={
              currentTab == 'my reviews'
                ? 'icon-assignment_ind'
                : 'icon-insert_chart'
            }
            size="16"
            fillColor="var(--g-600)"
          />
        </div>
        <p className="typography-h500 text-n-900 mb-[4px]">
          {getObjectiveLocale(
            currentTab == 'my reviews' ? 'Review for Me' : 'Phase Progress'
          )}
        </p>
        <p className="typography-paragraph text-n-800">
          {getObjectiveLocale(
            currentTab == 'my reviews'
              ? 'Progress and status of other employees in reviewing you'
              : 'The progress status on a running review cycle for your direct reports/team members'
          )}
        </p>
      </div>
      <div
        className={`wrapper-phase-chart pl-[48px] mt-[12px] ${
          !cycle.permissions?.assignmentsSee ? 'pb-[16px]' : ''
        }`}
      >
        {orderedPhases.map((phase, index) => (
          <PhaseProgress
            cycle={cycle}
            phase={phase}
            currentTab={currentTab}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

export default ReviewProgressComponent;

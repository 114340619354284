import React from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useAuth } from 'context/AuthContext';

import Modal from 'components/shared/modal/Modal';

dayjs.extend(utc);

const ModalReminderList = ({
  phase = 'selfReview',
  reminders = [],
  setModal = () => {},
  cycleData
}) => {
  const {
    data: {
      config: { reviewReminderTime }
    }
  } = useAuth();
  return (
    <Modal
      className="w-[600px]"
      title={'Notification reminder'}
      withCloseIcon={true}
      eventOnClickClose={() => setModal({ show: false })}
      withFooter={false}
    >
      <h5 className="typography-h300 typography-secondary mb-[30px]">
        {phase.replace(/([a-z](?=[A-Z]))/g, '$1 ')}
      </h5>
      {reminders.map((reminder, index) => {
        let endsAtMoment = dayjs(cycleData[phase]?.endsAt);
        let defaultDaysValue = endsAtMoment.diff(reminder.remindsAt, 'days');

        let defaultDateString = dayjs(reminder.remindsAt).format('DD MMM YYYY');
        const configClockString = dayjs.utc(reviewReminderTime, 'h:mm a');
        const localTime = dayjs(configClockString).local().format('h:mm a');
        return (
          <div className="mb-[32px]" key={index}>
            <p className="typography-paragraph">
              {defaultDaysValue === 0
                ? 'On the day of deadline'
                : `${defaultDaysValue} days, before deadline`}
              <span className="typography-tertiary">
                {' '}
                ({defaultDateString} at {localTime})
              </span>
            </p>
          </div>
        );
      })}
    </Modal>
  );
};

export default ModalReminderList;

import React, { useEffect } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import {
  moveProjectSectionAbove,
  moveProjectSectionBelow
} from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import useTasks from 'hooks/useTasks';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import SVGIcon from 'components/shared/SVGIcon';

const SectionOptions = ({
  projectId,
  sectionIndex,
  dialogOpen,
  setDialogOpen,
  setIsRenaming,
  setIsAdding,
  setAddAbove,
  setShowModalDeleteSection,
  isCanMove = true,
  isCanAdd = true,
  isItemFixed = false
}) => {
  const { invalidateQueries } = useRefetchQuery();

  const {
    listGroups,
    setListGroups,
    setSectionDialogOpen,
    phaseDialogOpen,
    setPhaseDialogOpen
  } = useTasks();
  const { section } = listGroups;

  const options = (add) => {
    return [
      {
        text: 'Above',
        onClick: () => (add ? onClickAdd(true) : onClickMove(true)),
        dataCy: `section-${sectionIndex}-option-${add ? 'add' : 'move'}-above`
      },
      {
        text: 'Below',
        onClick: () => (add ? onClickAdd(false) : onClickMove(false)),
        dataCy: `section-${sectionIndex}-option-${add ? 'add' : 'move'}-below`
      }
    ];
  };

  const onClickRename = () => {
    setIsRenaming(true);
  };

  const onClickAdd = (addAbove) => {
    setAddAbove(addAbove);
    setIsAdding(true);
  };

  const onClickMove = async (moveAbove) => {
    const addSection = moveAbove
      ? moveProjectSectionAbove
      : moveProjectSectionBelow;
    const sectionId = section[sectionIndex]?.id;
    const { data } = await addSection(projectId, sectionId);

    if (data) {
      let tempListGroups = cloneDeep(listGroups);
      const otherSectionIndex = sectionIndex + (moveAbove ? -1 : 1);
      tempListGroups.section[otherSectionIndex] = section[sectionIndex];
      tempListGroups.section[sectionIndex] = section[otherSectionIndex];
      setListGroups(tempListGroups);
      invalidateQueries(['group', 'section', 'projectDetail'], {
        refetchActive: false
      });
    }
  };

  const onClickDelete = () => {
    setShowModalDeleteSection(true);
  };

  useEffect(() => {
    if (dialogOpen) {
      phaseDialogOpen && setPhaseDialogOpen(false);
    }
    setSectionDialogOpen(dialogOpen);
  }, [
    dialogOpen,
    sectionIndex,
    setSectionDialogOpen,
    phaseDialogOpen,
    setPhaseDialogOpen
  ]);

  return (
    <Dropdown>
      <Dropdown.Trigger onClick={() => setDialogOpen(!dialogOpen)}>
        <SVGIcon
          size={24}
          fillColor="var(--n-600)"
          iconName="icon-keyboard_control"
          dataCy={`section-${sectionIndex}-options`}
        />
      </Dropdown.Trigger>
      {dialogOpen && (
        <FloatingComponent usePortal>
          <Dropdown.MenuItems
            customClass="w-[220px]"
            type={isItemFixed ? 'fixed' : 'absolute'}
            useScrollClose
            useParentPortal
            setDialogOpen={() => setDialogOpen(!dialogOpen)}
          >
            <Dropdown.MenuItem
              iconName="icon-pencil"
              onClick={() => onClickRename()}
              dataCy={`section-${sectionIndex}-option-rename`}
            >
              <p className="typography-button text-n-900">
                {getObjectiveLocale('Rename Section')}
              </p>
            </Dropdown.MenuItem>
            {isCanAdd && (
              <Dropdown.MenuItem
                iconName="icon-add"
                dataCy={`section-${sectionIndex}-option-add`}
                customClass="relative"
              >
                <div className="flex justify-between items-center w-full">
                  <p className="typography-button text-n-900">
                    {getObjectiveLocale('Add Section')}
                  </p>
                  <SVGIcon
                    size={24}
                    iconName="icon-arrow_right"
                    fillColor="var(--n-600)"
                  />
                </div>
                <Dropdown.MenuItems
                  isParent={false}
                  position="right"
                  options={options(true)}
                  vPosition="relative"
                />
              </Dropdown.MenuItem>
            )}
            {isCanMove && (
              <Dropdown.MenuItem
                iconName="icon-swap_vert"
                dataCy={`section-${sectionIndex}-option-move`}
                customClass="relative"
              >
                <div className="flex justify-between items-center w-full">
                  <p className="typography-button text-n-900">
                    {getObjectiveLocale('Move Section')}
                  </p>
                  <SVGIcon
                    size={24}
                    iconName="icon-arrow_right"
                    fillColor="var(--n-600)"
                  />
                </div>
                <Dropdown.MenuItems
                  isParent={false}
                  options={
                    sectionIndex === 0
                      ? [options(false)[1]]
                      : sectionIndex === section?.length - 1
                      ? [options(false)[0]]
                      : options(false)
                  }
                  vPosition="relative"
                  position="right"
                />
              </Dropdown.MenuItem>
            )}
            <Dropdown.MenuItem
              iconName="icon-delete"
              iconColor="var(--r-600)"
              textColor="text-r-600"
              onClick={() => onClickDelete()}
              dataCy={`section-${sectionIndex}-option-delete`}
            >
              <p className="typography-button text-n-900">
                {getObjectiveLocale('Delete Section')}
              </p>
            </Dropdown.MenuItem>
          </Dropdown.MenuItems>
        </FloatingComponent>
      )}
    </Dropdown>
  );
};

export default SectionOptions;

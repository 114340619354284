import React from 'react';

import dayjs from 'dayjs';

import { phaseName } from 'utils/FormalReview';

import Avatar from 'components/shared/Avatar';
import { getExtraInformation } from 'src/utils/HelperUtils';

function ResultHeader({ currentPhase, user, currentTrack, cycleDetail }) {
  const { name = '', title, subtitle, profilePicture } = user;
  const isDateValid =
    cycleDetail.reviewProcessStartsAt && cycleDetail.reviewProcessEndsAt;
  const beginningMonth = dayjs(cycleDetail.reviewProcessStartsAt).format('MMM');
  const endingMonth = dayjs(cycleDetail.reviewProcessEndsAt).format('MMM');
  const periodYear = dayjs(cycleDetail.reviewProcessEndsAt).format('YYYY');

  return (
    <div className="contain">
      <h3>{phaseName(currentPhase)}</h3>
      {isDateValid && (
        <p>
          {beginningMonth} - {endingMonth} {periodYear}
        </p>
      )}
      {currentTrack !== 'summary' && (
        <div className="profile-container">
          <Avatar
            src={profilePicture}
            name={name || ''}
            size={50}
            style={{ marginRight: '10px' }}
          />
          <div>
            <h5 className="name">{name || ''}</h5>
            <h6 className="job">{getExtraInformation(title, subtitle)}</h6>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResultHeader;

import React from 'react';

const GanttHeaderDays = ({ daysOfYear }) => {
  return (
    <>
      {daysOfYear.map(({ date, id }) => (
        <div
          key={'days-header-' + id}
          date-id={id}
          className={'timeline-cells h-[36px]'}
          style={{
            ...(date.getDay() == 0
              ? {
                  borderRight: '1px solid var(--n-300)'
                }
              : { borderRight: '1px solid transparent' })
          }}
        >
          {date.getDate()}
        </div>
      ))}
    </>
  );
};

export default GanttHeaderDays;

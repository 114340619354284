import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import { useImmer } from 'use-immer';

import { updateTeam } from 'client/TeamClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { objectiveRestructuring } from 'utils/ObjectiveRestructuring';

import Button from 'components/design-system/Button';
import Description from 'components/sidebar/sidebar-project/Description';
import AlignParent from 'components/sidebar/sidebar-team/AlignParent';
import Leader from 'components/sidebar/sidebar-team/Leader';
import Members from 'components/sidebar/sidebar-team/Members';
import Privacy from 'components/sidebar/sidebar-team/Privacy';
import Reviewer from 'components/sidebar/sidebar-team/Reviewer';
import ManageTeamProfile from 'components/teams/modal/ManageTeamProfile';
import RoleHistory from 'components/teams/modal/RoleHistory';
import SidebarRight from 'src/components/design-system/SidebarRight/SidebarRight';

const SidebarTeam = ({ teamDetail, refetchTeam }) => {
  const [showRoleHistoryModal, setShowRoleHistoryModal] = useState(false);

  const { id, permissions } = teamDetail || {};
  const params = useParams();
  const { refetchQueries, invalidateQueries } = useRefetchQuery();
  const [objectiveValue, setObjectiveValue] = useImmer(
    objectiveRestructuring(teamDetail)
  );

  const ableToEditTeam =
    useMemo(() => permissions?.includes('edit'), [permissions]) || false;
  const ableToShowAligment = useMemo(
    () => permissions?.includes('alignment'),
    [permissions]
  );

  const updateCurrentTeam = async (body) => {
    let changedBody = body;
    const { isSuccess } = await updateTeam(id, changedBody);
    if (isSuccess) {
      refetchTeam(id);
      refetchQueries(['team', 'activities', id]);
      invalidateQueries(['objectives', 'myteams']);
    }

    return { isSuccess: isSuccess };
  };

  useEffect(() => {
    if (!isEqual(objectiveValue, teamDetail)) {
      setObjectiveValue((draft) => objectiveRestructuring(teamDetail));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamDetail]);

  return (
    <SidebarRight
      dataCy="sidebar-team"
      fixed={false}
      customClass="!fixed right-0"
      fullWidth={false}
      customWidth="w-[400px]"
    >
      <SidebarRight.Body withSpace={false} customClass="pb-[60px]">
        <Privacy teamDetail={teamDetail} updateTeam={updateCurrentTeam} />
        <div className="flex items-center justify-between px-[24px] mt-[32px] mb-[16px]">
          <p className="typography-h400 text-n-900">
            {getObjectiveLocale(`${startCase(params?.teamType)} Roles`)}
          </p>
          <Button.Tertiary
            onClick={() => setShowRoleHistoryModal(true)}
            datacy="view-role-history"
          >
            {getObjectiveLocale('View Role History')}
          </Button.Tertiary>
          {showRoleHistoryModal && (
            <RoleHistory setShowModal={setShowRoleHistoryModal} />
          )}
        </div>
        <div className="px-[24px] flex items-center">
          <Leader
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            updateTeam={updateCurrentTeam}
            ableToUpdate={permissions?.includes('change_leader')}
          />
          <Reviewer
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            updateTeam={updateCurrentTeam}
            ableToUpdate={permissions?.includes('change_reviewer')}
          />
        </div>
        <div className="px-[24px] flex items-center">
          <Members
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            updateTeam={updateCurrentTeam}
            roleType="member"
            ableToUpdate={permissions?.includes('change_member')}
          />
          <Members
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            updateTeam={updateCurrentTeam}
            roleType="stakeholder"
            ableToUpdate={permissions?.includes('change_stakeholder')}
          />
        </div>
        <div className="px-[24px] flex items-center">
          <Members
            objectiveValue={objectiveValue}
            setObjectiveValue={setObjectiveValue}
            updateTeam={updateCurrentTeam}
            roleType="pmo"
            ableToUpdate={permissions?.includes('change_pmo')}
          />
          <div className="w-1/2" />
        </div>
        <div className="mt-[16px]">
          <p className="px-[24px] typography-h400 text-n-900 mt-[32px] mb-[8px]">
            {getObjectiveLocale(`${startCase(params?.teamType)} Details`)}
          </p>
          <Description
            objectiveDetail={objectiveValue}
            updateObjective={updateCurrentTeam}
            disabled={!ableToEditTeam}
          />
          {params?.teamType == 'squad' && ableToShowAligment && (
            <AlignParent
              objectiveDetail={objectiveValue}
              updateObjective={updateCurrentTeam}
            />
          )}
        </div>
        <div className="mt-[16px] ml-[24px]">
          <p className="pr-[24px] typography-h400 text-n-900 mt-[32px] mb-[16px]">
            {getObjectiveLocale(`${startCase(params?.teamType)} Image`)}
          </p>
          <ManageTeamProfile
            teamValue={teamDetail}
            updateImage={(url) => updateCurrentTeam({ teamPicture: url })}
            disabled={!ableToEditTeam}
          />
        </div>
      </SidebarRight.Body>
    </SidebarRight>
  );
};

export default SidebarTeam;

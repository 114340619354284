import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import Papa from 'papaparse';

import {
  getGroupCMSCulture,
  getLeaderboardsPostsCMSCulture
} from 'client/adminClient';
import {
  getCultureLocale,
  getNumberLocale,
  setPostType
} from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import { InsightCalendar } from 'components/admin/Insight-retention/InsightHelpers';
import Button from 'components/design-system/Button';
import { listOptionDate } from 'components/shared/Calendar/ListAntdDateHelper';
import LoadingComponent from 'components/shared/LoadingComponent';

function LeaderboardContent({ route: { title } }) {
  const [defaultRanges, setDefaultRanges] = useState({
    'This Week': {
      startDate: dayjs().startOf('week'),
      endDate: dayjs().endOf('week')
    },
    'Last Week': {
      startDate: dayjs().subtract(1, 'week').startOf('week'),
      endDate: dayjs().subtract(1, 'week').endOf('week')
    },
    'This Month': {
      startDate: dayjs().startOf('month'),
      endDate: dayjs().endOf('month')
    },
    'Last Month': {
      startDate: dayjs().subtract(1, 'month').startOf('month'),
      endDate: dayjs().subtract(1, 'month').endOf('month')
    },
    'Last Two Months': {
      startDate: dayjs().subtract(2, 'month').startOf('month'),
      endDate: dayjs().subtract(1, 'month').endOf('month')
    }
  });
  const [selectedDate, setSelectedDate] = useState([
    dayjs().subtract(30, 'days'),
    dayjs()
  ]);

  const [totalPage, setTotalPage] = useState(1);
  const [isDisableNext, setIsDisableNext] = useState(false);
  const [isDisablePrev, setIsDisablePrev] = useState(true);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [groups, setGroups] = useState([]);
  const [filterListType, setFilterListType] = useState('reach_list');
  const [totalOffset, setTotalOffset] = useState(0);
  const [leaderboardData, setLeaderBoardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [intervalButton, setIntervalButton] = useState({
    growth: {
      alltime: false,
      month: false,
      twoMonths: false
    }
  });
  const [searchQuery, setSearchQuery] = useState('');

  const _getLeaderboardData = async () => {
    const params = {
      periodEnd: selectedDate[1] ? dayjs(selectedDate[1])?.toISOString() : null,
      groupId: selectedGroupId == -2 ? null : selectedGroupId,
      periodBegin: selectedDate[0]
        ? dayjs(selectedDate[0])?.toISOString()
        : null,
      offset: totalOffset,
      searchQuery: searchQuery || ''
    };
    const { data, error } = await getLeaderboardsPostsCMSCulture(
      'posts',
      filterListType,
      params
    );
    if (data) {
      setIsLoading(false);
      setIsDisableNext(false);
      if (data['nextOffset'] == -1) {
        setIsDisableNext(true);
      }
      if (totalOffset === 0) {
        setIsDisablePrev(true);
      } else {
        setIsDisablePrev(false);
      }
      setLeaderBoardData(data[camelCase(filterListType)]);
    }
  };

  const _getGroups = async () => {
    const { data, error } = await getGroupCMSCulture();

    if (data) {
      let groupsTemp = data;
      groupsTemp.unshift({
        name: 'Overall - All',
        id: -2
      });
      setGroups(groupsTemp);
    }
  };

  const handleDownloadCSV = async (e) => {
    e.preventDefault();
    const params = {
      periodEnd: selectedDate[1].toISOString(true),
      groupId: selectedGroupId,
      periodBegin: selectedDate[0]?.toISOString(true),
      totalOffset: totalOffset,
      searchQuery: searchQuery || '',
      limit: 1000
    };
    const { data: ContentData, error } = await getLeaderboardsPostsCMSCulture(
      'posts',
      filterListType,
      params
    );

    let CSVDataPapa = [];

    ContentData[camelCase(filterListType)].map(function (post, idx) {
      let CSVDataFormat = [];
      let postType = startCase(post.postTypeV2);
      let postCaption = post[setPostType(post.postTypeV2)].caption;
      let linkUrl = '';

      switch (postType) {
        case 'media':
          linkUrl = post?.media?.secureUrl;
          break;
        case 'link':
          linkUrl = post?.link?.urlLink;
          break;
        default:
          break;
      }

      CSVDataFormat.push(idx + 1);
      CSVDataFormat.push(dayjs(post.created_at).format('DD/MM/YYYY'));
      CSVDataFormat.push(postCaption || '-');
      CSVDataFormat.push(post.user.fullName || '-');
      CSVDataFormat.push(post.user.email || '-');
      CSVDataFormat.push(linkUrl || '-');
      CSVDataFormat.push(postType || '-');
      CSVDataFormat.push(post.viewersCount || 0);
      CSVDataFormat.push(post.loversCount || 0);
      CSVDataFormat.push(post.commentsCount || 0);

      CSVDataPapa.push(CSVDataFormat);
    });

    let csv = Papa.unparse({
      fields: [
        'No.',
        'Published Date',
        'Post',
        'Owner',
        'Email',
        'Item',
        'Type',
        'Reach (views)',
        'Like',
        'Comment'
      ],
      data: CSVDataPapa
    });
    let data, filename, link;

    if (csv == null) return;

    filename = 'leaderboard_content_' + filterListType + '.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);
    data = data.replace(/#/g, '*');
    link = document.createElement('a');
    link.download = filename;
    link.href = data;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 1500);
  };

  const handleNext = () => {
    let totalOffsetTemp = totalOffset;
    totalOffsetTemp += 10;

    if (intervalButton.growth.alltime) {
      setSelectedDate([null, selectedDate[1]]);
    }
    setTotalPage(totalPage + 1);
    setTotalOffset(totalOffsetTemp);
    setIsDisableNext(false);
    setIsDisablePrev(true);
  };

  const handlePrev = () => {
    let totalOffsetTemp = totalOffset;

    totalOffsetTemp = totalOffsetTemp === 0 ? 0 : totalOffsetTemp - 10;

    if (intervalButton.growth.alltime) {
      periodBegin = null;
    }
    setTotalPage(totalPage - 1);
    setTotalOffset(totalOffsetTemp);
  };

  const handleChartView = (interval, e) => {
    e.preventDefault();

    let periodEnd = dayjs();

    switch (interval) {
      case 'month':
        let periodBegin1M = defaultRanges['Last Month'].startDate;
        let periodEnd1M = defaultRanges['Last Month'].endDate;

        setIntervalButton({
          growth: {
            alltime: false,
            month: true,
            twoMonths: false
          }
        });
        setSelectedDate([periodBegin1M, periodEnd1M]);

        break;

      case 'twoMonths':
        let periodBegin2M = defaultRanges['Last Two Months'].startDate;
        let periodEnd2M = defaultRanges['Last Two Months'].endDate;

        setIntervalButton({
          growth: {
            alltime: false,
            month: false,
            twoMonths: true
          }
        });
        setSelectedDate([periodBegin2M, periodEnd2M]);

        break;

      default:
        setIntervalButton({
          growth: {
            alltime: true,
            month: false,
            twoMonths: false
          }
        });
        setSelectedDate([null, periodEnd]);
    }
  };

  const handleSelectDate = (range) => {
    setSelectedDate(range);

    setIntervalButton({
      growth: {
        alltime: false,
        month: false,
        twoMonths: false
      }
    });
  };

  const headerProps = {
    titlePage: title,
    primaryAction: {
      title: 'CSV',
      icon: 'icon-file_download',
      onClick: (e) => handleDownloadCSV(e)
    }
  };

  useEffect(() => {
    _getGroups();
    _getLeaderboardData();
  }, [filterListType, totalOffset, selectedDate, selectedGroupId]);

  return (
    <>
      <HeaderPage {...headerProps} />
      <div className="admin-culture col-xs-12">
        <div className="dashboard_content_main retention">
          <div className="leaderboard-user">
            <div className="card mt-[24px]">
              <div className="card-body">
                <h4 className="body-header">
                  {getCultureLocale('Group')}{' '}
                  <i className="fa fa-question-circle-o"></i>
                </h4>
                <div className="wrapper-dropdown-leaderboard-user">
                  <div className="choose-group">
                    <label htmlFor="selectValue" className="select-value-hour">
                      <select
                        className="select-dropdown"
                        onChange={(e) =>
                          setSelectedGroupId(e.currentTarget.value)
                        }
                      >
                        {groups.map(function (group, index) {
                          return (
                            <option key={index} value={group.id}>
                              {group.name}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  </div>
                  <div className="date-picker-wrapper--leaderboard">
                    <div className="leaderboard-date-picker">
                      <InsightCalendar
                        endDate={selectedDate[1]}
                        startDate={selectedDate[0] || 'Last update'}
                        selectedDate={selectedDate}
                        handleSelectDate={handleSelectDate}
                        customButtonClass="h-[36px]"
                        listOptionsTime={listOptionDate([
                          'This Week',
                          'Last Week',
                          'This Month',
                          'Last Month',
                          'Last 2 Months'
                        ])}
                      />
                    </div>
                    <div className="leaderboard-custom-filter mt-[8px]">
                      <div className="filter-date-wrapper">
                        <button
                          className={
                            'btn-chartView ' +
                            (intervalButton.growth.alltime ? 'is-active' : '')
                          }
                          onClick={(e) => handleChartView('alltime', e)}
                        >
                          {getCultureLocale('All Time')}
                        </button>
                        <button
                          className={
                            'btn-chartView ' +
                            (intervalButton.growth.month ? 'is-active' : '')
                          }
                          onClick={(e) => handleChartView('month', e)}
                        >
                          {getCultureLocale('Last Month')}
                        </button>
                        <button
                          className={
                            'btn-chartView ' +
                            (intervalButton.growth.twoMonths ? 'is-active' : '')
                          }
                          onClick={(e) => handleChartView('twoMonths', e)}
                        >
                          {getCultureLocale('Last 2 Months')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-box-wrapper">
                  <div
                    onClick={() => setFilterListType('reach_list')}
                    className={
                      filterListType === 'reach_list'
                        ? 'filter-box filter-box-active'
                        : 'filter-box'
                    }
                  >
                    <div className="filter-box-heading">
                      <b>{getCultureLocale('TOP REACH POST')}</b>
                    </div>
                    <div className="filter-box-label">
                      {getCultureLocale('post with most view')}
                    </div>
                  </div>

                  <div
                    onClick={() => setFilterListType('influential_list')}
                    className={
                      filterListType === 'influential_list'
                        ? 'filter-box filter-box-active'
                        : 'filter-box'
                    }
                  >
                    <div className="filter-box-heading">
                      <b>{getCultureLocale('TOP INFLUENTIAL POST')}</b>
                    </div>
                    <div className="filter-box-label">
                      {getCultureLocale('post with most response')}
                      <br />( {getCultureLocale('like')} +{' '}
                      {getCultureLocale('comment')} )
                    </div>
                  </div>

                  <div
                    onClick={() => setFilterListType('awesome_list')}
                    className={
                      filterListType === 'awesome_list'
                        ? 'filter-box filter-box-active'
                        : 'filter-box'
                    }
                  >
                    <div className="filter-box-heading">
                      <b>{getCultureLocale('TOP AWESOME POST')}</b>
                    </div>
                    <div className="filter-box-label">
                      {getCultureLocale('post with most like')}
                    </div>
                  </div>

                  <div
                    onClick={() => setFilterListType('generous_list')}
                    className={
                      filterListType === 'generous_list'
                        ? 'filter-box filter-box-active'
                        : 'filter-box'
                    }
                  >
                    <div className="filter-box-heading">
                      <b>{getCultureLocale('TOP BUZZING POST')}</b>
                    </div>
                    <div className="filter-box-label">
                      {getCultureLocale('post with most comment')}
                    </div>
                  </div>
                </div>
                <div className="hr dashboard_content_main mt-[5px]">
                  <div className="table-menu">
                    <div className="col-xs-6">
                      <div className="info">
                        <span className="mb-[1px]">
                          <strong>
                            {getCultureLocale('Leaderboard Content')}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                  <table className="table-list-employee table-user-leaderboard">
                    <thead>
                      <tr>
                        <th>{getCultureLocale('No')}</th>
                        <th>{getCultureLocale('Published Date')}</th>
                        <th>{getCultureLocale('Post')}</th>
                        <th>{getCultureLocale('Owner')}</th>
                        <th>{getCultureLocale('Email')}</th>
                        <th>{getCultureLocale('Item')}</th>
                        <th>{getCultureLocale('Type')}</th>
                        <th>{getCultureLocale('Reach(views)')}</th>
                        <th>{getCultureLocale('Like')}</th>
                        <th>{getCultureLocale('Comment')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="10">
                            <LoadingComponent />
                          </td>
                        </tr>
                      ) : leaderboardData.length === 0 ? (
                        <tr>
                          <td colSpan="9">{getCultureLocale('No Data')}</td>
                        </tr>
                      ) : (
                        leaderboardData.map(function (post, idx) {
                          var postType = startCase(post.postTypeV2);
                          let postCaption =
                            post[setPostType(post.postTypeV2)].caption;
                          let linkUrl = '';

                          switch (postType) {
                            case 'media':
                              linkUrl = post?.media?.secureUrl;
                              break;
                            case 'link':
                              linkUrl = post?.link?.urlLink;
                              break;
                            default:
                              break;
                          }

                          return (
                            <tr key={idx}>
                              <td>{idx + 1 + totalOffset}</td>
                              <td>
                                {dayjs(post.createdAt)
                                  .utc()
                                  .format('DD/MM/YYYY')}
                              </td>
                              <td>{postCaption || '-'}</td>
                              <td>{post.user.fullName || '-'}</td>
                              <td>{post.user.email || '-'}</td>
                              <td>
                                {linkUrl ? (
                                  <a href={linkUrl} target="_blank">
                                    {getCultureLocale('Link')}
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td>{postType}</td>
                              <td>
                                {getNumberLocale(post?.viewersCount || 0)}
                              </td>
                              <td>{getNumberLocale(post.loversCount || 0)}</td>
                              <td>
                                {getNumberLocale(post.commentsCount || 0)}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                  <div className="table-footer text-right">
                    <span className="page-info">Page {totalPage}</span>
                    <Button.Tertiary
                      onClick={() => handlePrev()}
                      disabled={isDisablePrev}
                    >
                      <i className="fa fa-arrow-left"></i> Previous
                    </Button.Tertiary>
                    <Button.Tertiary
                      onClick={() => handleNext()}
                      disabled={isDisableNext}
                    >
                      Next <i className="fa fa-arrow-right"></i>
                    </Button.Tertiary>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaderboardContent;

import React from 'react';

function PerformanceReportTeamInvolvement({
  numberOfLeaderInvolvement,
  numberOfMemberInvolvement
}) {
  return (
    <div className="performance-report-team-involvement-container">
      <h2>Team Involvement</h2>
      <div className="team-boxes">
        <div className="involvement-container leader-container">
          <h2>Leader</h2>
          <p>
            <span className="number">{numberOfLeaderInvolvement}</span>
            <span className="number">Team</span>
          </p>
        </div>

        <div className="involvement-container leader-container">
          <h2>Member</h2>
          <p>
            <span className="number">{numberOfMemberInvolvement}</span>
            <span className="number">Team</span>
          </p>
        </div>
      </div>
    </div>
  );
}

PerformanceReportTeamInvolvement.defaultProps = {
  numberOfLeaderInvolvement: 1,
  numberOfMemberInvolvement: 2
};

export default PerformanceReportTeamInvolvement;

import React, { useEffect, useState } from 'react';

import { putCustomAttribute } from 'client/admin/ReviewGroupClient';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Table from 'components/design-system/table/Table';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import Modal from 'components/shared/modal/Modal';

import './AttributeListTable.scss';
import AttributeListTableCard from './AttributeListTableCard';

const AttributeListTable = ({
  currentFilterTab,
  dataList,
  setModalAction,
  setIsModalCreateAttributeOpen,
  setDataEdit,
  dataEdit,
  setCurrentFilterTab,
  isLoading,
  showSuccessToast
}) => {
  const [isModalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [modalConfirmationProps, setModalConfirmationProps] = useState(null);

  const moveState = async () => {
    const state = currentFilterTab == 'active' ? 'archived' : 'active';
    const params = { review_aspects_attributes: [{ id: dataEdit.id, state }] };
    const { isSuccess } = await putCustomAttribute(0, params);
    if (isSuccess) {
      showSuccessToast();
      setCurrentFilterTab(state);
      setModalConfirmationOpen(false);
    }
  };
  const tableHeaders = [
    { name: 'attribute name', widthClass: 'w-[240px]' },
    { name: 'description', widthClass: 'w-[300px]', grow: true },
    { name: 'weight', widthClass: 'w-[104px]' },
    { name: '', widthClass: 'w-[114px]' }
  ];

  useEffect(() => {
    switch (currentFilterTab) {
      case 'active':
        setModalConfirmationProps({
          className: 'w-[400px]',
          title: 'Archive Attribute',
          description:
            'Archive this attribute will removes it from your active table. You can always access it again from from your "Archived" section',
          withPrimaryBtn: {
            title: 'Archive',
            dataCy: 'archive-attribute',
            onClick: moveState
          },
          withSecondaryBtn: {
            title: 'Cancel',
            dataCy: 'cancel-archive-attribute',
            onClick: () => setModalConfirmationOpen(false)
          },
          eventOnClickClose: () => setModalConfirmationOpen(false)
        });
        return;
      case 'archived':
        setModalConfirmationProps({
          className: 'w-[400px]',
          title: 'Activate Attribute',
          description:
            'Activate this attribute will add it to your active table.',
          withPrimaryBtn: {
            title: 'Activate',
            dataCy: 'activate-attribute',
            onClick: moveState
          },
          withSecondaryBtn: {
            title: 'Cancel',
            dataCy: 'cancel-activate-attribute',
            onClick: () => setModalConfirmationOpen(false)
          },
          eventOnClickClose: () => setModalConfirmationOpen(false)
        });
        return;
    }
  }, [currentFilterTab, dataEdit]);

  return (
    <div className="groups-tab-table">
      <Table headers={tableHeaders}>
        {isLoading ? (
          <LoadingComponent />
        ) : dataList.length == 0 ? (
          <ListEmptyState
            fullHeight
            containerClassname="my-[40px]"
            emptyIcon="icon-no-result-found"
            title={getObjectiveLocale('No results found')}
            subtitle={getObjectiveLocale(
              'Did you probably type the keyword incorrectly? We are sorry that what you searched was unfortunately not found.'
            )}
          />
        ) : (
          dataList.map((data, index) => {
            return (
              <AttributeListTableCard
                key={data.id}
                data={data}
                index={index}
                currentFilterTab={currentFilterTab}
                setModalAction={setModalAction}
                setIsModalCreateAttributeOpen={setIsModalCreateAttributeOpen}
                setDataEdit={setDataEdit}
                setModalConfirmationOpen={setModalConfirmationOpen}
                showSuccessToast={showSuccessToast}
              />
            );
          })
        )}
      </Table>
      {isModalConfirmationOpen && <Modal {...modalConfirmationProps} />}
    </div>
  );
};

export default AttributeListTable;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import isEqual from 'lodash/isEqual';

import GanttObjectiveContainer from './GanttObjectiveContainer';
import GanttSubObjectiveContainer from './GanttSubObjectiveContainer';

const areEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

const showObjectiveHandler = (setShowChild, childLoaded, setChildLoaded) => {
  setShowChild((value) => !value);
  if (!childLoaded) return;
  setChildLoaded(false);
};

const GanttObjective = React.memo(
  ({ objective, level, timeGroup, objectiveOptions, subContentOptions }) => {
    const [childLoaded, setChildLoaded] = useState(false);
    const [showChild, setShowChild] = useState(false);

    const fetchObjective = objectiveOptions?.fetchQueryFn;
    const getQueryKey = objectiveOptions?.getQueryKey;
    const openSidebarDetail = objectiveOptions?.openSidebarDetail;

    const queryKey = getQueryKey(objective?.id);

    const { data: queryData } = useQuery(
      queryKey,
      () => fetchObjective(objective?.id),
      {
        suspense: objective ? false : true,
        initialData: objective,
        refetchOnMount: false,
        enabled: true,
        cacheTime: Infinity
      }
    );

    const objectiveData = queryData?.data ? queryData.data : queryData;

    const showChildToggle = (e) => {
      e.stopPropagation();
      showObjectiveHandler(setShowChild, childLoaded, setChildLoaded);
    };

    useEffect(() => {
      setShowChild(false);
    }, [timeGroup]);

    const isChildLoadedClass =
      showChild && childLoaded ? 'child-loaded' : 'child-loading';

    return (
      <div
        className={`flex items-center w-full flex-col ${
          showChild ? 'show-child' : ''
        } ${isChildLoadedClass}`}
        id={'task' + objectiveData?.id}
      >
        <GanttObjectiveContainer
          objectiveData={objectiveData}
          level={level}
          openSidebarDetail={openSidebarDetail}
          showChildToggle={showChildToggle}
          showChild={showChild}
          subContentOptions={subContentOptions}
        />
        <GanttSubObjectiveContainer
          showChild={showChild}
          objectiveData={objectiveData}
          subContentOptions={subContentOptions}
          level={level}
          timeGroup={timeGroup}
          objectiveOptions={objectiveOptions}
          setChildLoaded={setChildLoaded}
          childLoaded={childLoaded}
        />
      </div>
    );
  },
  areEqual
);

GanttObjective.displayName = 'GanttObjective';

export default GanttObjective;

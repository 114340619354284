import React, { useEffect, useState } from 'react';

import {
  getPerformanceSourceRole,
  getPerformanceSourceTeam
} from 'client/PerformanceReport';

import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

import OverviewListCycleComponent from './OverviewListCycleComponent';

function OverviewPerformanceSourceComponent(props) {
  const [performanceSource, setPerformanceSource] = useState('team');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quartal, setQuartal] = useState([]);
  const [dataModal, setDataModal] = useState(null);
  const [showChevron, setShowChevron] = useState(false);

  let listQuarter = ['Q1 Jan-Mar', 'Q2 Apr-Jun', 'Q3 Jul-Sep', 'Q4 Okt-Des'];
  let body = document.getElementsByTagName('body')[0];

  const handleButton = (performanceSource) => {
    setPerformanceSource(performanceSource);
  };

  useEffect(() => {
    getData();
  }, [performanceSource, props.year]);

  const handleOpenModal = (data) => {
    body.style.overflow = 'hidden';
    setIsModalOpen(true);
    setDataModal(data);
  };

  const handleCloseModal = () => {
    body.style.overflow = 'auto';
    setIsModalOpen(false);
  };

  const handleChevron = () => {
    if (pageXOffset !== undefined) {
      let container = document.getElementById('container');
      if (container) {
        container.scrollLeft += 261;
      }
    }
    let chevron = document.getElementById('performance-wrapper');
    if (chevron) {
      chevron.offsetWidth * 4;
    }
    let subbox = document.getElementById('subbox')?.offsetWidth;
    if (chevron > subbox) {
      setShowChevron(true);
    }
  };

  const getData = async (newYear) => {
    const userId = props.userId;
    let year = newYear ? newYear : props.year;
    const queryQ1 = {
      period_begin: year + '-01-01T00:00:00.000Z',
      period_end_before: year + '-04-01T00:00:00.000Z'
    };
    const queryQ2 = {
      period_begin: year + '-04-01T00:00:00.000Z',
      period_end_before: year + '-07-01T00:00:00.000Z'
    };
    const queryQ3 = {
      period_begin: year + '-07-01T00:00:00.000Z',
      period_end_before: year + '-10-01T00:00:00.000Z'
    };
    const queryQ4 = {
      period_begin: year + '-10-01T00:00:00.000Z',
      period_end_before: parseInt(year) + 1 + '-01-01T00:00:00.000Z'
    };

    const { data: dataQ1, error: error } =
      performanceSource === 'team'
        ? await getPerformanceSourceTeam(queryQ1, userId)
        : await getPerformanceSourceRole(queryQ1, userId);
    const { data: dataQ2, error: errorQ2 } =
      performanceSource === 'team'
        ? await getPerformanceSourceTeam(queryQ2, userId)
        : await getPerformanceSourceRole(queryQ2, userId);
    const { data: dataQ3, error: errorQ3 } =
      performanceSource === 'team'
        ? await getPerformanceSourceTeam(queryQ3, userId)
        : await getPerformanceSourceRole(queryQ3, userId);
    const { data: dataQ4, error: errorQ4 } =
      performanceSource === 'team'
        ? await getPerformanceSourceTeam(queryQ4, userId)
        : await getPerformanceSourceRole(queryQ4, userId);

    let quartal = [];
    if (dataQ1) quartal.push(dataQ1);
    if (dataQ2) quartal.push(dataQ2);
    if (dataQ3) quartal.push(dataQ3);
    if (dataQ4) quartal.push(dataQ4);
    setQuartal(quartal);
    handleChevron();
  };

  let colorbtn = {
    color: 'var(--base-500)',
    backgroundColor: 'var(--base-100)',
    border: 'solid 1px var(--base-600)'
  };

  let backbtn = {
    color: 'var(--n-900)',
    backgroundColor: 'var(--n-200)',
    border: 'solid 1px var(--n-500)'
  };

  let dataAverage =
    performanceSource === 'team'
      ? props.dataAverageTeam
      : props.dataAverageRole;
  return (
    <div className="performance-source-container">
      <span className="title-performance-source"> Performance Source </span>
      <button
        style={performanceSource === 'team' ? colorbtn : backbtn}
        onClick={() => handleButton('team')}
        className="btn-team"
      >
        {props.userInfo} Team
      </button>
      <button
        style={performanceSource === 'role' ? colorbtn : backbtn}
        onClick={() => handleButton('role')}
        className="btn-role"
      >
        {props.userInfo} Role
      </button>

      {showChevron && (
        <SVGIcon
          iconName="icon-chevron_right"
          customClass="img-circle"
          onClick={() => handleChevron()}
        />
      )}

      <div className="performance-box" id="container">
        <div className="detail-performance-wrapper" id="performance-wrapper">
          {listQuarter.map((title, index) => (
            <OverviewListCycleComponent
              key={index}
              title={title}
              total={
                performanceSource === 'team'
                  ? dataAverage[index]?.totalTeams + ' Teams'
                  : dataAverage[index]?.totalRoles + ' Roles'
              }
              average_score={dataAverage[index]?.averageScore}
              quartal={quartal[index]}
              handleOpenModal={handleOpenModal}
            />
          ))}
        </div>
      </div>

      {isModalOpen && (
        <Modal
          className="performance-source-container"
          withIconClose={false}
          withPrimaryBtn={{
            title: 'OK',
            dataCy: 'close-modal-perf-source',
            onClick: () => handleCloseModal()
          }}
        >
          <div className="dialog-container">
            <div className="detail-dialog">
              <span className="title-dialog">{dataModal.name}</span>
              <div className="dialog-detail-rating">
                <SVGIcon
                  iconName="icon-star-rounded"
                  size={16}
                  fillColor="var(--y-500)"
                />
                <span className="count-rating">{dataModal.averageScore}</span>
              </div>
            </div>
            <div className="dialog-box">
              {dataModal.cycles.map((value, index) => (
                <div className="review-dialog" key={index}>
                  <span className="title-review-dialog">{value.name}</span>
                  <span
                    className="see-lyfe-cycle"
                    onClick={() => {
                      handleCloseModal();
                      props.setDetailInfo(dataModal, value);
                    }}
                  >
                    See Cycle Detail
                  </span>
                  <div className="review-detail">
                    <SVGIcon iconName="icon-star-rounded" size={16} />
                    <span className="count-rating">{value.score}</span>
                    <span className="interpunct"> · </span>
                    <span
                      className="status"
                      style={{ color: `#${value.scoringOption.colorHex}` }}
                    >
                      {value.scoringOption.description}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default OverviewPerformanceSourceComponent;

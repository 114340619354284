import React, { createRef, useEffect } from 'react';

import { useUsersAdmin } from 'context/admin/UsersAdminContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import LoadingComponent from 'components/shared/LoadingComponent';

import Modal from '../../shared/modal/Modal';

// Modal member can show member with arrayOfIds or arrayOfObjects
const ModalShowMember = ({
  setShowModalMembers,
  isSelectAll,
  listIdUser,
  excludedId,
  withCloseIcon = true,
  loading = false,
  listObjUser = null,
  withJobPlacement = false,
  title = 'Members',
  isReadOnly,
  isAbleToRemoveUser = false,
  onClickRemove,
  titleNoMember = 'No members',
  additionalState = ''
}) => {
  const { hasMore, users, getUsersData, appendUserData } = useUsersAdmin();
  const noMember =
    !isSelectAll && (listIdUser?.length === 0 || listObjUser?.length === 0);
  const intersectTarget = createRef();

  const getQuery = () => {
    const query = { state: ['active', 'pending', additionalState] };

    if (!isSelectAll) {
      query['id'] = listIdUser;
    } else {
      query['exclude'] = excludedId;
    }

    return query;
  };

  const _appendData = () => {
    if (hasMore) {
      appendUserData({ ...getQuery(), limit: 10 }, withJobPlacement);
    }
  };

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) => entry.isIntersecting && _appendData(),
    threshold: 0.3
  });

  const _getUsersData = async () => {
    getUsersData({ ...getQuery(), limit: 10 }, withJobPlacement);
  };

  useEffect(() => {
    !listObjUser && _getUsersData({});
  }, [listIdUser]);

  const withPrimaryBtn = {
    title: 'Close',
    onClick: () => setShowModalMembers(false),
    disabled: isReadOnly,
    dataCy: ''
  };

  let renderedUsers = listObjUser ? listObjUser : users;

  return (
    <Modal
      className="w-[400px]"
      title={title}
      withCloseIcon={withCloseIcon}
      withPrimaryBtn={withPrimaryBtn}
      eventOnClickClose={() => setShowModalMembers(false)}
      withFooter={false}
      useBorder={true}
    >
      <div
        id="members-container"
        className="modal-members-container max-h-[488px] overflow-y-auto overflow-x-hidden"
      >
        {loading ? (
          <LoadingComponent />
        ) : noMember ? (
          <center>
            <h4 className="typography-h500 typography-secondary">
              {getObjectiveLocale(titleNoMember)}
            </h4>
          </center>
        ) : (
          renderedUsers.map((user, idx) => {
            const isLast = users.length - 1 == idx;
            return (
              <div
                ref={
                  isLast && renderedUsers?.length < listIdUser?.length
                    ? intersectTarget
                    : null
                }
                key={idx}
                className="flex items-center justify-between h-[56px]"
              >
                <div className="flex items-center">
                  <Avatar
                    name={user.name}
                    src={user.profilePicture}
                    size={32}
                  />
                  <div className="mm-user ml-[16px]">
                    <h6 className="typography-h400 mb-[0px] multiline-text-1">
                      {user.name}
                    </h6>
                    <p className="typography-paragraph">{user.email}</p>
                  </div>
                </div>
                {isAbleToRemoveUser && !isReadOnly && (
                  <div
                    className="cursor-pointer typography-paragraph text-n-600 hover:text-n-800"
                    onClick={() => onClickRemove([user.id])}
                  >
                    {getObjectiveLocale('Remove')}
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </Modal>
  );
};

export default ModalShowMember;

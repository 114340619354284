import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  getSurveyIdCMSCulture,
  patchSurveyIdCMSCulture,
  postSurveyCMSCulture
} from 'client/adminClient';

import Button from 'components/design-system/Button';
import Footer from 'components/shared/Footer';
import SVGIcon from 'components/shared/SVGIcon';

import QuestionComponent from './QuestionComponent';

function CreateQuestion({
  backToCreateMission,
  changeTargetId,
  mission,
  indexQuestion
}) {
  const [wordingPolls, setWordingPolls] = useState('pollOptionsAttributes');
  const history = useHistory();
  const templateOptions = { option: null, isCorrectOption: false };
  const templateQuestion = {
    question: null,
    questionPoint: null,
    pollOptionsAttributes: [templateOptions, templateOptions]
  };
  const [listQuestion, setListQuestion] = useState([templateQuestion]);
  const [haveSubmitted, setHaveSubmitted] = useState(false);

  const addMoreQuestion = () => {
    let newList = JSON.parse(JSON.stringify(listQuestion));
    if (wordingPolls == 'pollOptionsAttributes') {
      newList.push(templateQuestion);
    } else {
      newList.push({
        question: null,
        questionPoint: null,
        pollOptions: [templateOptions, templateOptions]
      });
    }
    setListQuestion(newList);
  };

  const addMoreOptions = (index) => {
    let newList = JSON.parse(JSON.stringify(listQuestion));
    newList[index][`${wordingPolls}`].push(templateOptions);
    setListQuestion(newList);
  };

  const onDelete = (index, indexOption) => {
    if (indexOption !== undefined) {
      let newList = JSON.parse(JSON.stringify(listQuestion));
      let newQuestion = newList[index][`${wordingPolls}`].filter(
        (data, index) => index !== indexOption
      );
      newList[index][`${wordingPolls}`] = newQuestion;
      setListQuestion(newList);
    } else {
      let newList = JSON.parse(JSON.stringify(listQuestion));
      newList = newList.filter((data, id) => id !== index);
      setListQuestion(newList);
    }
  };

  const onChange = (e, type, index, isOption, indexOption) => {
    let newList = JSON.parse(JSON.stringify(listQuestion));
    if (!isOption) {
      newList[index][type] = e.target.value;
    } else {
      if (type === 'isCorrectOption') {
        let answer = document.getElementsByName(`answer-correct-${index}`);
        for (var i = 0, length = answer.length; i < length; i++) {
          if (answer[i].checked) {
            newList[index][`${wordingPolls}`][i][type] = true;
          } else {
            newList[index][`${wordingPolls}`][i][type] = false;
          }
        }
      } else {
        newList[index][`${wordingPolls}`][indexOption][type] = e.target.value;
      }
    }
    setListQuestion(newList);
  };

  const checkEmptyQuestion = () => {
    let data = false;
    listQuestion.map((question, index) => {
      if (question[`${wordingPolls}`].length < 2) {
        data = true;
      }
    });
    return data;
  };

  const checkAnswerQuestion = () => {
    let data = [];
    let state = false;
    listQuestion.map((question, index) => {
      question[`${wordingPolls}`].map((answer, index) => {
        if (answer.isCorrectOption) {
          data.push('yes');
        }
      });
    });
    if (data.length == listQuestion.length) {
      state = true;
    }
    return state;
  };

  const submitQuestion = async () => {
    if (checkEmptyQuestion()) {
      alert('Minimum 2 options for each question');
    } else {
      if (!checkAnswerQuestion()) {
        alert('Answer cannot be blank');
      } else {
        setHaveSubmitted(true);
        let newList = JSON.parse(JSON.stringify(listQuestion));
        newList.map((question, index) => {
          question.orderLevel = index;
          if (wordingPolls == 'pollOptions') {
            question.pollOptionsAttributes = question.pollOptions;
            delete question.pollOptions;
          }
        });
        let questionData = {
          survey: {
            visibility: 'mission',
            pollsAttributes: newList
          }
        };
        let number = mission.missionTasks[indexQuestion]?.targetId;
        if (number) {
          const { data, error } = await patchSurveyIdCMSCulture(
            number,
            questionData
          );
          if (data) {
            changeTargetId(listQuestion.length);
            backToCreateMission();
          }
        } else {
          const { data, error } = await postSurveyCMSCulture(questionData);
          if (data) {
            changeTargetId(listQuestion.length, data.id);
            backToCreateMission();
          }
        }
      }
    }
  };

  const getQuestionById = async (id) => {
    const { data } = await getSurveyIdCMSCulture(id);
    if (data) {
      setListQuestion(data.questions);
      setWordingPolls('pollOptions');
    }
  };

  useEffect(() => {
    if (mission.missionTasks[indexQuestion]?.targetId) {
      getQuestionById(mission.missionTasks[indexQuestion]?.targetId);
    }
  }, []);

  return (
    <div className="px-[40px]">
      <div className="header">
        <label className="fw-bold fs-16 mt-[16px]">Create Question</label>
        <p className="typography-secondary mb-[16px]">
          You can create question and add some points for each question in this
          quiz.
        </p>
      </div>
      {listQuestion.map((question, index) => {
        return (
          <QuestionComponent
            key={index}
            wordingPolls={wordingPolls}
            index={index}
            question={question}
            onClick={addMoreOptions}
            onChange={onChange}
            onDelete={onDelete}
            haveSubmitted={haveSubmitted}
          />
        );
      })}
      <div className="footer mt-[16px] h-[116px]">
        <Button.Secondary onClick={addMoreQuestion}>
          <SVGIcon
            size="24"
            fillColor="var(--base-600)"
            iconName="icon-add"
            customClass="mr-[4px]"
          />
          Add question
        </Button.Secondary>
      </div>
      <Footer
        secondaryButton={true}
        linkButtonName={'Back'}
        datacy="submit-question"
        handleClick={() => submitQuestion()}
        handleRoute={() => backToCreateMission()}
        customClassContainer="fixed flex justify-center"
        customClass="w-[720px]"
      />
    </div>
  );
}

export default CreateQuestion;

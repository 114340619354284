import React from 'react';

import PropTypes from 'prop-types';

import { getObjectiveLocale } from 'utils/HelperUtils';

import ConfirmDeleteModal from 'components/modal/ConfirmDeleteModal';
import SVGIcon from 'components/shared/SVGIcon';

import Modal from './Modal';

const Interfaces = {
  type: PropTypes.string.isRequired,
  _handleActionModal: PropTypes.func.isRequired,
  _handleNext: PropTypes.func
};

const DefaultValue = {
  type: '',
  _handleActionModal: () => {},
  _handleNext: () => {}
};

const MinMaxValidationModal = (props) => {
  switch (props.type) {
    case 'ValidationChangeGoalAlignment':
      return <ValidationChangeGoalAlignment {...props} />;
    case 'ValidationDeleteGoal':
      return <ValidationDeleteGoal {...props} />;
    case 'ValidationAddNewGoal':
      return <ValidationAddNewGoal {...props} />;
    default:
      break;
  }
};

export const ValidationAddNewGoal = ({
  _handleActionModal,
  _handleNext,
  totalObjectives,
  datacy
}) => {
  return (
    <Modal
      title={getObjectiveLocale('Add new goal?')}
      description={getObjectiveLocale(
        'Are you sure you want to add new goal? This will affect of goal you own. Your total goals right now:'
      )}
      withPrimaryBtn={{
        title: 'Yes, Add',
        dataCy: datacy,
        onClick: () => _handleNext()
      }}
      withSecondaryBtn={{
        title: 'No',
        dataCy: 'cancel-add-goal',
        onClick: () => _handleActionModal()
      }}
      className="max-w-[400px] max-h-[268px]"
      eventOnClickClose={() => _handleActionModal()}
    >
      <div className="bg-y-100 heigth-40 rounded-[4px] flex items-center">
        <SVGIcon
          iconName="icon-warning"
          fillColor="var(--y-500)"
          size="20"
          customClass="py-[8px] ml-[10px]"
        />
        <span className="ml-[20px] py-[8px] lowercase typography-paragraph">
          {totalObjectives} {getObjectiveLocale('Top Parent Goal(s)')}
        </span>
      </div>
    </Modal>
  );
};

export const ValidationDeleteGoal = ({
  type,
  _handleActionModal,
  _handleNext,
  totalObjectives,
  totalSubgoal,
  datacy,
  objectiveDetail
}) => {
  return (
    <ConfirmDeleteModal
      closeModal={_handleActionModal}
      submitModal={_handleNext}
      totalObjectives={totalObjectives}
      totalSubgoal={totalSubgoal}
      dataCy={datacy}
      modalType={type}
      objectiveDetail={objectiveDetail}
    />
  );
};

export const ValidationChangeGoalAlignment = ({
  _handleActionModal,
  _handleNext,
  totalObjectives
}) => {
  return (
    <Modal
      title={getObjectiveLocale('Change goal alignment?')}
      description={getObjectiveLocale(
        'Are you sure you want to change this goal alignment? This will affect of goal you own. Your total goals right now:'
      )}
      className="max-w-[400px] min-h-[268px]"
      withPrimaryBtn={{
        title: 'Yes, Change',
        dataCy: 'change-goal-alignment',
        onClick: (e) => {
          e.preventDefault();
          _handleNext();
        }
      }}
      withSecondaryBtn={{
        title: 'No',
        dataCy: 'cancel-change-alignment',
        onClick: (e) => {
          e.stopPropagation();
          _handleActionModal();
        }
      }}
      eventOnClickClose={() => _handleActionModal()}
      withFooter={true}
    >
      <div className="bg-y-100 heigth-40 rounded-[4px] flex items-center">
        <SVGIcon
          iconName="icon-warning"
          fillColor="var(--y-500)"
          size="20"
          customClass="py-[8px] ml-[10px]"
        />
        <span className="ml-[20px] py-[8px] lowercase typography-paragraph">
          {totalObjectives} {getObjectiveLocale('Top Parent Goal(s)')}
        </span>
      </div>
    </Modal>
  );
};

MinMaxValidationModal.propTypes = Interfaces;
MinMaxValidationModal.defaultProps = DefaultValue;

export default MinMaxValidationModal;

import { Axios } from './Axios';
import { toCamelCase, toSnakeCase } from '../utils/caseConverter';
import omit from 'lodash/omit';
import qs from 'qs';
import * as Sentry from '@sentry/browser';
import { getENV } from 'utils/HelperUtils';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

function client(
  endpoint,
  {
    body,
    method,
    params,
    headers = {},
    skipNulls = true,
    useInterceptor = true
  } = {},
  noConvertCase,
  signal
) {
  const authorizationMethod = getENV('AUTHORIZATION_METHOD');
  const token = localStorage.getItem('bearer');
  const shouldSendToken = !(endpoint.includes('v1/auth/') && body?.password);
  const OTP_PASS_TOKEN = getENV('OTP_PASS_TOKEN');

  let newHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(authorizationMethod === 'bearer' &&
      token &&
      shouldSendToken && { Authorization: 'Bearer ' + token }),
    ...(OTP_PASS_TOKEN && { 'OTP-Pass-Token': OTP_PASS_TOKEN }),
    ...headers
  };

  let url = getENV('PERFORMANCE_API_HOST');

  const config = {
    url: `${url}/${endpoint}`,
    signal: signal, // for abort request, ref: https://axios-http.com/docs/cancellation
    headers: {
      ...newHeaders
    }
  };

  if (authorizationMethod === 'cookie' && shouldSendToken) {
    Axios.defaults.withCredentials = true;
  }

  if (!isEmpty(params)) {
    if (!params.q) {
      delete params.q;
    }

    params = !noConvertCase ? toSnakeCase(params) : params;
    const convertedParams = qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: true,
      skipNulls: true
    });

    if (convertedParams) {
      config.url = config.url + '?' + convertedParams;
    }
  }

  if (body) {
    config.data = !noConvertCase ? toSnakeCase(body) : body;
    if (!method) {
      (() => {
        throw 'method must be present';
      })();
    }
  }

  if (method) {
    config.method = method;
  }

  function NetworkError(message) {
    this.name = 'NetworkError';
    this.message = message;
    this.stack = new Error().stack;
  }
  NetworkError.prototype = Object.create(Error.prototype);
  NetworkError.prototype.constructor = NetworkError;

  const onSuccess = r => {
    let data = r.data.data != null ? r.data.data : r.data.properties;
    let pagination, metadata, analytics, totalObjectives, totalWeights;

    if (!noConvertCase) {
      data = toCamelCase(data);
      pagination = toCamelCase(r.data.pagination);
      metadata = toCamelCase(omit(r.data, ['data', 'pagination']));
      analytics = toCamelCase(r.data.analytics);
      totalObjectives = toCamelCase(r.data.total_objectives);
      totalWeights = toCamelCase(r.data.total_weights);
    } else {
      pagination = r.data.pagination;
      metadata = omit(r.data, ['data', 'pagination']);
      analytics = r.data.analytics;
      totalObjectives = r.data.total_objectives;
      totalWeights = r.data.total_weights;
    }

    return {
      isSuccess: true,
      error: null,
      ...r.data,
      data,
      metadata,
      pagination,
      analytics,
      totalObjectives,
      totalWeights
    };
  };

  const onError = e => {
    let error = e?.response?.data?.error
      ? e?.response?.data?.error
      : e?.response?.data?.errors;
    error = toCamelCase(error);

    if (error?.code === 'ECONNABORTED') {
      error.message = 'Time Out Please Try Again';
    } else if (!error?.message) {
      error = { ...error, message: 'Something went wrong' };
    }

    let sentError =
      process.env.NODE_ENV === 'production' &&
      error?.code &&
      error?.code !== 401 &&
      error?.code !== 422;

    if (sentError && !error?.message.includes('Goal/Task not found')) {
      Sentry.withScope(function(scope) {
        let getUserData = localStorage.getItem('userData');
        if (getUserData) {
          let userData = JSON.parse(getUserData);
          scope.setUser({ email: userData.email });
          scope.setTag('organization', userData.organizationName);
        }
        scope.setTag('pathname', location.pathname);
        scope.setTag(
          'type',
          `${error?.code ? error?.code : ''} network failed`
        );
        scope.setTag('endpoint', endpoint);
        scope.setExtras({ extra: { ...config, ...e?.response?.data } });
        scope.setFingerprint([
          e?.response?.config?.method,
          error?.code,
          error?.message
        ]);
        Sentry.captureException(new NetworkError(error?.message));
      });
    }

    return { isSuccess: false, data: null, pagination: null, error: error };
  };

  if (!useInterceptor) {
    const uninterceptorAxiosInstance = axios.create();
    uninterceptorAxiosInstance.defaults.withCredentials = true;
    return uninterceptorAxiosInstance(config)
      .then(onSuccess)
      .catch(onError);
  }

  return Axios(config)
    .then(onSuccess)
    .catch(onError);
}

export default client;

import debounce from 'lodash/debounce';
import { MONTH_GROUP, QUARTER_GROUP, WEEK_GROUP } from './const';
import { modifyScrollPosition } from './scrollHelper';

let _debounced = debounce(action => action(), 10);
let _debouncedAppend = debounce(action => action(), 200);

const getColWidthbyGroup = group => {
  if (group == WEEK_GROUP) return 48;
  if (group == MONTH_GROUP) return 12;
  if (group == QUARTER_GROUP) return 6;
};

const getAppendIdentifier = group => {
  if (group == WEEK_GROUP) return 29;
  if (group == MONTH_GROUP) return 85;
  if (group == QUARTER_GROUP) return 169;
};

const fullHideLoadingToggle = (hideClasses, showClasses, proceed) => {
  const loadingComponent = document.getElementById('loading-indicator');
  if (!proceed || !loadingComponent) return;
  hideClasses.map(c => {
    loadingComponent.classList.remove(c);
  });
  showClasses.map(c => {
    loadingComponent.classList.add(c);
  });
};

const toggleLoading = (show, fullHide) => {
  const loadingComponent = document.getElementById('loading-indicator');
  if (!loadingComponent) return;
  if (show) {
    loadingComponent.classList.remove('invisible');
    fullHideLoadingToggle(
      ['opacity-50', 'w-[calc(100%-360px)]'],
      ['opacity', 'w-[100%]'],
      fullHide
    );
  } else {
    loadingComponent.classList.add('invisible');
    fullHideLoadingToggle(
      ['opacity', 'w-[100%]'],
      ['opacity-50', 'w-[calc(100%-360px)]'],
      fullHide
    );
  }
};

const storingDate = ({
  index,
  daysOfYear,
  result,
  isToRight,
  earliestDays
}) => {
  if (isToRight) {
    const t = new Date(
      index == 1
        ? daysOfYear[daysOfYear.length - 1].date
        : result[result.length - 1].date
    );

    const designatedDate = new Date(t.setDate(t.getDate() + 1));
    return [...result, { date: designatedDate, id: designatedDate.getTime() }];
  }
  const t = new Date(index == 1 ? earliestDays.date : result[0].date);
  const designatedDate = new Date(t.setDate(t.getDate() - 1));
  return [
    {
      date: designatedDate,
      id: designatedDate.getTime()
    },
    ...result
  ];
};

const generateAppendedDate = ({
  isToRight,
  identifierDay,
  daysOfYear,
  earliestDays
}) => {
  let result = [];

  for (let i = 1; i < identifierDay; i++) {
    result = storingDate({
      index: i,
      daysOfYear,
      result,
      isToRight,
      earliestDays
    });
  }
  return result;
};

const getTransform = (e, scrollSpeedMultiplier) => {
  const testScroller = document.querySelector('#scroll-content');
  // eslint-disable-next-line no-undef
  const matrix = new WebKitCSSMatrix(
    window.getComputedStyle(testScroller).transform
  );
  const currentXTransformTranslate = matrix.m41;
  const currentYTransformTranslate = matrix.m42;

  // get mouse scroll distance
  let deltaX = e.deltaX;
  let deltaY = e.deltaY;

  if (e.shiftKey) {
    deltaX = e.deltaY;
    deltaY = 0;
  }
  const designatedXtransform =
    currentXTransformTranslate - deltaX * scrollSpeedMultiplier;
  const designatedYtransform =
    currentYTransformTranslate - deltaY * scrollSpeedMultiplier;
  return {
    designatedXtransform,
    designatedYtransform,
    currentXTransformTranslate,
    currentYTransformTranslate
  };
};

const modifyBackground = ({
  designatedXtransform,
  designatedYtransform,
  daysOfYear,
  currentXTransformTranslate,
  appendDate
}) => {
  const testScroller = document.querySelector('#scroll-content');
  _debounced(() => {
    modifyScrollPosition(designatedXtransform, designatedYtransform);
  });
  if (!appendDate) return;
  if (designatedXtransform >= 0) {
    _debouncedAppend(() => {
      appendDate({ daysOfYear, currentYTransform: designatedYtransform });
    });
    return;
  }
  if (testScroller.offsetWidth - Math.abs(currentXTransformTranslate) < 1600) {
    _debouncedAppend(() => {
      appendDate({
        daysOfYear,
        currentXTransform: currentXTransformTranslate,
        currentYTransform: designatedYtransform,
        isToRight: true
      });
    });
    return;
  }
};

const periodEnds = new Date(new Date().setMonth(new Date().getMonth() + 6));
const periodStart = new Date(new Date().setMonth(new Date().getMonth() - 6));

const generateFillDateNotMonday = (startDay, timeGroup, storedays) => {
  let identifierDay = startDay == 0 ? 7 : startDay;
  if (timeGroup == MONTH_GROUP) {
    identifierDay += 28;
  }
  if (timeGroup == QUARTER_GROUP) {
    identifierDay += 91;
  }
  for (let i = 1; i < identifierDay; i++) {
    const t = new Date(storedays[0].date);
    const designatedDate = new Date(t.setDate(t.getDate() - 1));
    storedays.unshift({
      date: designatedDate,
      id: designatedDate.getTime()
    });
  }
};

const generateRangeDate = (
  timeGroup,
  startDate = periodStart,
  endDate = periodEnds
) => {
  let storedays = [];
  for (
    var d = new Date(new Date(startDate).setHours(0, 0, 0, 0));
    d <= new Date(new Date(endDate).setHours(0, 0, 0, 0));
    d.setDate(d.getDate() + 1)
  ) {
    const date = new Date(d);
    storedays.push({ date, id: date.getTime() });
  }

  const startDay = storedays[0].date.getDay();
  const endsDay = storedays[storedays.length - 1].date.getDay();

  if (startDay != 1) {
    generateFillDateNotMonday(startDay, timeGroup, storedays);
  }

  if (endsDay != 0) {
    for (let i = 0; i < 7 - endsDay; i++) {
      const t = new Date(storedays[storedays.length - 1].date);
      const designatedDate = new Date(t.setDate(t.getDate() + 1));
      storedays.push({ date: designatedDate, id: designatedDate.getTime() });
    }
  }
  return storedays;
};

export {
  getColWidthbyGroup,
  getAppendIdentifier,
  toggleLoading,
  generateAppendedDate,
  getTransform,
  modifyBackground,
  generateRangeDate
};

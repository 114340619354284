import React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

import dayjs from 'dayjs';

import {
  createSingleObjective,
  editObjective,
  getListObjectives,
  getObjectiveDetail
} from 'src/client/ObjectivesClient';
import { GanttChart } from 'src/components/shared/GanttChart/GanttChart';
import { useProjectDetail } from 'src/context/ProjectDetailContext';
import { useRefetchQuery } from 'src/context/RefetchQueryContext';
import { useUser } from 'src/context/UserContext';
import useFilter from 'src/hooks/useFilter';
import { formatTimezone } from 'src/utils/DateUtils';
import { extractDefaultRollup } from 'src/utils/ObjectivesHelper';

import GroupSection from './GroupSection';

const Timeline = ({
  currentSection,
  isProject,
  setCurrentSectionIndex,
  setShowModalDeleteSection,
  sections,
  listMetrics
}) => {
  const {
    projectId,
    involvements,
    startDate: projectStartDate,
    dueDate: projectDueDate,
    team
  } = useProjectDetail();
  const { user, config: defaultRollupForParentTask } = useUser();
  const queryClient = useQueryClient();
  const { refetchQueries } = useRefetchQuery();
  const filter =
    useFilter((state) => state.filterById?.['projectDetailTimeline']) || {};
  const { timeGroup, group, sortColumn, sortDirection, assignTo, q } = filter;

  const { replace } = useHistory();

  const phaseOptions = {
    id: 'phase',
    staticData: currentSection,
    paramIdentifier: 'phaseId',
    customHeader: {
      component: (
        <GroupSection
          group="phase"
          projectId={projectId}
          setCurrentSectionIndex={setCurrentSectionIndex}
          setShowModalDeleteSection={setShowModalDeleteSection}
          isProject
        />
      )
    },
    showChild: true
  };

  const sectionOptions = {
    id: 'section',
    staticData: sections,
    paramIdentifier: 'sectionId',
    paramValueKey: 'id',
    showChild: true,
    customHeader: {
      component: (
        <GroupSection
          group="section"
          projectId={projectId}
          setCurrentSectionIndex={setCurrentSectionIndex}
          setShowModalDeleteSection={setShowModalDeleteSection}
          isProject
        />
      )
    }
  };

  const priorityOptions = {
    id: 'priority',
    staticData: currentSection,
    paramIdentifier: 'priorityId',
    showChild: true,
    customHeader: {
      component: (
        <GroupSection
          group="priority"
          projectId={projectId}
          setCurrentSectionIndex={setCurrentSectionIndex}
          setShowModalDeleteSection={setShowModalDeleteSection}
          isProject
        />
      )
    }
  };

  const groupOptions = {
    phase: phaseOptions,
    section: sectionOptions,
    priority: priorityOptions
  };

  const searchAdditionalKey = (obj) => {
    const opt = groupOptions?.[group];
    const optIdentifier = opt.paramIdentifier;
    const id = obj?.[optIdentifier];
    const groupName = opt.staticData?.find((data) => data.id == id)?.name;
    return groupName;
  };

  const getQueryKey = (keyArray = []) => {
    const groupName = searchAdditionalKey(keyArray[0]);
    return ['objectives', 'mytasks', groupName, ...keyArray];
  };

  const getQueryParam = (defaultParams, additionalParam = []) => {
    const params = {
      ...defaultParams,
      ...additionalParam
    };
    return params;
  };

  const fetchQueryFn = async (olderThan, injectedParam) => {
    const params = {
      ...injectedParam,
      ...(olderThan ? { olderThan } : null)
    };
    const queryParams = getQueryParam(params);
    return getListObjectives(queryParams);
  };

  const defaultParams = {
    limit: 10,
    parentId: projectId,
    type: ['task'],
    reviewsVisibility: 1,
    parentNotAssignedTo: user.id,
    sortColumn,
    sortDirection,
    q,
    ...(assignTo === 'me' && { assigneeId: [user?.id] })
  };

  const subObjectiveGetQueryKey = (parentId) => {
    return ['sub-objective', parentId];
  };

  const defaultParamSubObjective = {
    limit: 10,
    reviewsVisibility: 1
  };

  const getSubObjectives = async (olderThan, injectedParam) => {
    const params = {
      ...injectedParam,
      ...(olderThan ? { olderThan } : null)
    };
    const queryParams = getQueryParam(params);
    return getListObjectives(queryParams);
  };

  const getSingleObjective = (id) => {
    return getObjectiveDetail(id);
  };

  const getSingleObjectiveKey = (id) => {
    return ['objective', id];
  };

  const openSidebarDetail = (id) => {
    replace(`/${isProject ? `projects/${projectId}` : 'tasks'}/${id}`);
  };

  const callbackUpdateFn = async (id, payload) => {
    const queryKey = getSingleObjectiveKey(id);
    const { isSuccess, data } = await editObjective(id, payload);

    if (isSuccess) {
      queryClient.setQueryData(queryKey, { data });
    }
    return isSuccess;
  };

  const singleObjectiveOptions = {
    getQueryKey: getSingleObjectiveKey,
    fetchQueryFn: getSingleObjective,
    openSidebarDetail,
    useOpenCalendar: true,
    callbackUpdateFn,
    barColorSelector: ['measurement', 'progressColorHex']
  };

  const getDate = () => {
    let startDate, dueDate;
    const today = dayjs();
    startDate = formatTimezone(today.format('YYYY-MM-DD'), 'start');
    dueDate = formatTimezone(
      today.add(7, 'day').endOf('day').format('YYYY-MM-DD'),
      'end'
    );
    if (today > dayjs(projectDueDate) || today < dayjs(projectStartDate)) {
      startDate = projectStartDate;
      dueDate = formatTimezone(
        dayjs(projectStartDate).add(7, 'day').endOf('day').format('YYYY-MM-DD'),
        'end'
      );
    }
    if (dayjs(dueDate) > dayjs(projectDueDate)) {
      dueDate = projectDueDate;
    }
    return { startDate, dueDate };
  };

  const addNewCallbacks = async ({ mandatoryPayload, parentParams }) => {
    let assigner = [];
    if (involvements?.some((user) => user?.role === 'assigner')) {
      assigner.push({
        role: 'assigner',
        userId: involvements?.find(
          (involvement) => involvement?.role === 'assigner'
        )?.user?.id
      });
    }
    let measurement = {};
    if (listMetrics?.length > 0) {
      const defaultMetric =
        listMetrics?.find(({ isDefaultForTask }) => isDefaultForTask) || false;
      if (defaultMetric) {
        measurement.startingValue = 0;
        measurement.targetValue = 100;
        measurement.rollUp =
          extractDefaultRollup(defaultMetric?.defaultRollUp) ||
          defaultRollupForParentTask;
        measurement.unitId = defaultMetric?.id;
      }
    }

    let payload = {
      type: 'task',
      ...getDate(),
      involvements: assigner,
      grouped: true,
      ...mandatoryPayload
    };

    if (measurement) {
      payload.measurement = measurement;
    }

    if (projectId) {
      payload.parentId = projectId;
    }
    if (team?.id) {
      payload.teamId = team?.id;
    }
    const { isSuccess } = await createSingleObjective(payload);
    const additionalQueryKey = searchAdditionalKey(parentParams);
    isSuccess && refetchQueries('objectives', 'mytasks', additionalQueryKey);
  };

  // getQueryKey, getQueryParam, fetchQueryFn
  const objectiveOptions = {
    options: {
      getQueryKey,
      getQueryParam,
      fetchQueryFn,
      defaultParams,
      singleObjectiveOptions,
      addNewCallbacks
    },
    subObjectiveOptions: {
      fetchQueryFn: getSubObjectives,
      defaultParams: defaultParamSubObjective,
      getQueryKey: subObjectiveGetQueryKey,
      singleObjectiveOptions
    }
  };

  const ganttOptions = {
    options: { ...groupOptions?.[group] }
  };

  const periodEnd = new Date(`${new Date().getFullYear()}-12-31`);
  const periodStart = new Date(`${new Date().getFullYear() - 1}-12-26`);

  return (
    <div className="px-[40px]">
      {timeGroup && group && sortColumn && sortDirection && (
        <GanttChart
          timeGroup={timeGroup}
          contentOptions={ganttOptions}
          objectiveOptions={objectiveOptions}
          periodEnd={periodEnd}
          periodStart={periodStart}
        />
      )}
    </div>
  );
};

export default Timeline;

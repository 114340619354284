import React, { useMemo } from 'react';

import useDisabledUsers from 'hooks/useDisabledUsers';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';
import InlineDialogSelectMultipleUser from 'components/inline-dialog/InlineDialogSelectMultipleUser';
import SVGIcon from 'components/shared/SVGIcon';
import { getNewInvolvements } from 'components/sidebar/sidebar-create/SidebarCreateHelper';

const Members = ({
  objectiveValue,
  setObjectiveValue,
  updateTeam,
  roleType,
  ableToUpdate = false
}) => {
  const { involvements } = objectiveValue || {};

  const currentInvolvements = useMemo(() => {
    return [...involvements];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { checkIsUserDisabled } = useDisabledUsers({
    isEdit: true,
    isTeam: true,
    objective: objectiveValue
  });

  const getCondition = (role, extendedRole) => {
    const conditions = {
      member: role === 'assignee' && !extendedRole,
      stakeholder: role === 'follower' && !extendedRole,
      pmo: role === 'follower' && extendedRole === 'pmo'
    };

    return conditions[roleType];
  };

  const selectedMembers = involvements?.filter(({ role, extendedRole }) =>
    getCondition(role, extendedRole)
  );

  const handleChangeDialog = (involvements, type, extendedRoleType) => {
    const lastIndex = involvements?.length - 1;
    const selectedUser = involvements[lastIndex];

    checkIsUserDisabled(selectedUser?.user?.id, () => {
      let newInvolvements = getNewInvolvements(
        involvements,
        type,
        false,
        objectiveValue,
        null,
        extendedRoleType,
        true
      );

      setObjectiveValue((draft) => {
        draft.involvements = newInvolvements;
      });
    });
  };

  const updateMembers = async () => {
    const newInvolvements = objectiveValue?.involvements?.map(
      (involvement) => ({
        userId: involvement.user.id,
        role: involvement.role,
        ...(involvement?.extendedRole && {
          extendedRole: involvement?.extendedRole
        })
      })
    );

    const { isSuccess } = await updateTeam({ involvements: newInvolvements });
    if (!isSuccess) {
      setObjectiveValue((draft) => {
        draft.involvements = currentInvolvements;
      });
    }
  };

  const getRoleType = () => {
    switch (roleType) {
      case 'member':
        return getObjectiveLocale('Members Team');
      case 'pmo':
        return getObjectiveLocale('PMO');
      case 'stakeholder':
        return getObjectiveLocale('Stakeholders');
    }
  };

  const MembersTrigger = () => {
    const users = selectedMembers?.map(({ user }) => user) || [];

    return users.length > 0 ? (
      <div className="px-[8px] py-[8px] rounded-full hover:bg-base-30024 w-full">
        {users.length === 1 ? (
          <SingleUser userData={users[0]} isDisabled={!ableToUpdate} />
        ) : (
          users.length > 1 && (
            <AvatarGroup
              avatars={users}
              size="24"
              position={roleType == 'stakeholder' ? 'right' : 'middle'}
              tooltipPosition="right"
            />
          )
        )}
      </div>
    ) : (
      <div className="text-n-600 px-[8px] py-[8px] rounded-full hover:bg-base-30024 w-full flex items-center">
        <SVGIcon
          size="24"
          fillColor="var(--n-600)"
          iconName="icon-default-user"
          customClass="mr-[8px]"
        />
        <span>
          {getObjectiveLocale(`${ableToUpdate ? 'Select' : 'No'} ${roleType}`)}
        </span>
      </div>
    );
  };

  const getRoleExtendedRole = () => {
    if (roleType === 'member') {
      return {
        role: 'assignee'
      };
    }

    if (roleType === 'stakeholder') {
      return {
        role: 'follower'
      };
    }

    if (roleType === 'pmo') {
      return {
        role: 'follower',
        extendedRoleType: 'pmo'
      };
    }
  };

  return (
    <div
      className={`mt-[16px] w-1/2 ${
        roleType != 'stakeholder' ? 'mr-[20px]' : ''
      }`}
      data-cy={`${roleType}-team`}
    >
      <p className="typography-h100 text-n-800">{getRoleType(roleType)}</p>
      <InlineDialogSelectMultipleUser
        customTriggerComponent={MembersTrigger}
        header={getRoleType(roleType)}
        position={roleType == 'stakeholder' ? 'right' : 'bottom'}
        objectiveValue={objectiveValue}
        selectedValue={selectedMembers}
        handleChange={handleChangeDialog}
        dataCy={`inline-dialog-select-${roleType}`}
        onCloseDialog={updateMembers}
        permission={ableToUpdate}
        dialogWrapperClass="w-full ml-[-8px]"
        {...getRoleExtendedRole()}
      />
    </div>
  );
};

export default Members;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { getMilestoneDetail } from 'client/ObjectivesClient';
import { abbrevNumber, getObjectiveLocale } from 'utils/HelperUtils';
import { filteredDateMilestone } from 'utils/MilestoneHelper';
import { getRollupText, whichBgColorClassName } from 'utils/ObjectivesHelper';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';

import MilestoneDetailCard from './MilestoneDetailCard';

function MilestoneDetail({
  currentMilestone,
  dataId,
  milestoneType,
  milestoneMode,
  measurement,
  permissions,
  setModalType,
  setModalOpen,
  objectiveValue
}) {
  const { progressPercentage, progressColorHex } = measurement || {};

  const [milestones, setMilestones] = useState([]);

  const objStartDate = dayjs(objectiveValue?.startDate).format('YYYY-MM-DD');
  const objDueDate = dayjs(objectiveValue?.dueDate).format('YYYY-MM-DD');
  const otherMilestone = milestones?.filter(
    (data) => data?.periodName !== currentMilestone?.periodName
  );

  const getDetailMilestone = async () => {
    const { data } = await getMilestoneDetail(dataId);
    if (data) {
      setMilestones(data);
    }
  };

  const inBetweenMilestone = filteredDateMilestone(
    otherMilestone,
    objStartDate,
    objDueDate
  );

  useEffect(() => {
    getDetailMilestone();
  }, []);

  const localeProgressPercentage =
    progressPercentage && progressPercentage.toLocaleString('id-ID');

  return (
    <div className="miletone-detail h-[1000px] overflow-auto">
      <div className="milestone-content mb-[400px] px-[24px]">
        <div className="flex content-center">
          <SVGIcon
            size="24"
            fillColor="var(--n-600)"
            iconName="icon-bar_chart"
          />
          {(measurement?.progressColorHex || measurement?.unit) && (
            <div className="flex-col grow pl-[16px]">
              {measurement?.unit && (
                <p className="typography-paragraph mb-[8px]">
                  {measurement.description}{' '}
                  {measurement?.unit && `( ${measurement?.unit} )`}
                  {measurement?.rollUp &&
                    `,${' '}${getRollupText(measurement.rollUp)}`}
                </p>
              )}
              {measurement?.progressColorHex && (
                <div className="flex justify-center items-center">
                  <LineProgressBar
                    width={247}
                    progress={progressPercentage}
                    primaryColor={whichBgColorClassName(progressColorHex)}
                    fullFirst
                    height={16}
                    classLine="w-full"
                  />
                  <p className="typography-paragraph mb-[0px] ml-[24px]">
                    {localeProgressPercentage}%
                  </p>
                </div>
              )}
              {measurement?.unit && (
                <p className="typography-h400 flex items-center my-[8px]">
                  {abbrevNumber(measurement?.currentValue)}{' '}
                  <span className="typography-paragraph mx-[4px]">of</span>
                  {abbrevNumber(measurement?.targetValue)}
                </p>
              )}
              {permissions?.includes('update_current_value') && (
                <Button.Secondary
                  onClick={() => {
                    setModalOpen(true);
                    setModalType('complete');
                  }}
                >
                  {getObjectiveLocale('Update Milestone Progress')}
                </Button.Secondary>
              )}
            </div>
          )}
        </div>
        <div className="milestone-detail pr-[16px] flex content-center flex-col mt-[24px]">
          <div className="flex content-center">
            <SVGIcon
              size="24"
              fillColor="var(--n-600)"
              iconName="icon-timeline"
            />
            <p className="typography-paragraph capitalize pl-[16px]">
              {getObjectiveLocale(
                `${milestoneType} milestone (${
                  milestoneMode == 'latest_value'
                    ? 'Latest Value'
                    : milestoneMode.split('_')[0]
                }), recent`
              )}
              :
            </p>
          </div>
          <div className="flex ml-[40px] mt-[16px]">
            <MilestoneDetailCard currentMilestone={currentMilestone} />
          </div>
        </div>
        {inBetweenMilestone?.length > 0 && (
          <div className="milestone-detail pr-[16px] flex content-center flex-col mt-[24px] ml-[40px]">
            <p className="header-milestone mb-[8px] typography-h300 color-n-800 uppercase">
              {getObjectiveLocale('Others Period')}
            </p>
            {inBetweenMilestone?.map((data, index) => {
              return (
                <div className="flex py-[8px] border-top-300" key={index}>
                  <MilestoneDetailCard currentMilestone={data} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default MilestoneDetail;

import React, { useCallback, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import debounce from 'lodash/debounce';

import { getApprovalRequests } from 'client/ApprovalClient';
import { getTotalObjectives } from 'client/ObjectivesClient';
import { getListFilter } from 'client/adminClient';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import { useUrl } from 'hooks/useUrl';
import { loadMoreValidator } from 'utils/HelperUtils';
import { getPeriod } from 'utils/ObjectivesHelper';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import ApprovalPanel from 'components/approval/ApprovalPanel';
import ObjectiveEmptyState from 'components/shared/ObjectiveEmptyState';
import ObjectiveSkeleton from 'components/shared/ObjectiveSkeleton';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import ErrorBoundary from 'pages/ErrorBoundary';

function YourApproval({
  setRequestIsEmpty,
  setApprovalRequests,
  approvalRequests,
  refetch
}) {
  const { userId } = useParams();
  const { match } = useUrl();
  const { config, user } = useUser();
  const isUsingApprovalApprove = config.permissions?.approvalSee;
  const [olderThan, setOlderThan] = useState('');
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hasMore = olderThan ? true : false;
  const filter = useFilter((state) => state.filterById?.yourApprovalFilter);
  const urlParams = new URLSearchParams(window.location.search);
  const { approvalTimeFilterOptions } = config;
  const periodFilter = getPeriod(approvalTimeFilterOptions);

  const onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      setOnLoadMore(true);
      const { data, pagination } = await getApprovalRequests({
        approverId: userId || user.id,
        limit: 15,
        olderThan,
        approverPlacementId: user?.placementId
      });
      setApprovalRequests((draft) => {
        draft.push(...data);
      });
      setOlderThan(pagination.next.olderThan);
      setOnLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 50, () => {
        loadMore();
      });
    }
  };

  const _getApprovalRequests = async () => {
    setIsLoading(true);
    const { q, ...allFilter } = filter;
    const query = {
      q: q,
      info: allFilter,
      limit: 15,
      approverId: userId || user.id,
      approverPlacementId: urlParams.get('placement') || user?.placementId
    };
    const { data, pagination } = await getApprovalRequests(query);

    if (data) {
      let promises = data.map(async (newData) => {
        const newParams = {
          type: ['goal'],
          assigneeId: newData?.requester?.id,
          reviewsVisibility: 1,
          placementId: newData?.requester?.placementId,
          state: ['running', 'completed', 'reviewed'],
          parentNotAssignedTo: null,
          ...periodFilter
        };
        const { data: totalObj } = await getTotalObjectives(newParams);
        newData.analytics.push({
          state: 'approved',
          count: totalObj.totalObjectives
        });
        return newData;
      });
      await Promise.all(promises).then((approvals) => {
        setApprovalRequests((draft) => approvals);
        setRequestIsEmpty(data.length === 0);
      });
    }
    if (pagination) {
      setOlderThan(pagination.next.olderThan);
    }
    setIsLoading(false);
  };

  const _getListFilter = (addedParams) => {
    const fetchFilter = () => {
      return getListFilter(addedParams);
    };

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['filter', addedParams],
        ({ pageParam }) => fetchFilter(pageParam),
        {
          getNextPageParam: (lastPage, allPages) =>
            lastPage.pagination?.next || 0
        }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  const getFilterData = async (addedParams) => {
    return await getListFilter({ q: addedParams?.q, key: addedParams?.key });
  };

  const debounceFn = useCallback(debounce(_getApprovalRequests, 500), [
    filter,
    refetch
  ]);

  useEffect(() => {
    isUsingApprovalApprove && debounceFn();

    return debounceFn.cancel;
  }, [filter, refetch]);

  return (
    <div className="viewport-wrapper container-input">
      <AdvancedFilter
        filterOptions={['search']}
        filterMenu={config?.generalFilterOptions}
        defaultFilter={{ role: [] }}
        filterClient={getFilterData}
        dialogQueryFn={(params) => _getListFilter(params)}
        customClass="mt-[24px] mb-[24px]"
        id="yourApprovalFilter"
      />
      <div
        data-cy="list-approval"
        className={`approval-tab-content ${match ? 'collapsed' : 'expanded'}`}
      >
        <div className={`my-goals-container`}>
          <div className="list-goals">
            <ErrorBoundary>
              {isLoading && <ObjectiveSkeleton />}
              {!isLoading && approvalRequests.length === 0 && (
                <ObjectiveEmptyState
                  type={'need-response'}
                  page={'need-response'}
                  withRedirectButton={false}
                />
              )}
              {!isLoading && approvalRequests.length > 0 && (
                <>
                  <div
                    className="direct-reports-container approval"
                    data-cy="approval-requests-container"
                    onScroll={onScroll}
                  >
                    {approvalRequests.map((request, index) => {
                      return (
                        <ApprovalPanel
                          key={index}
                          index={index}
                          user={request.requester}
                          mode="manager"
                          pageObjective="approval"
                          approvalRequest={request}
                          reloadAllRequests={_getApprovalRequests}
                          clearApprovalRequest={() => setApprovalRequests({})}
                          useDateFilterConfig
                        />
                      );
                    })}
                    {onLoadMore && <ObjectiveSkeleton />}
                  </div>
                  {match && <SidebarMyGoals />}
                </>
              )}
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YourApproval;

import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import { useProfileFilter } from 'context/ProfileFilterContext';
import { useProfileSkill } from 'context/ProfileSkillContext';
import { useUser } from 'context/UserContext';
import useDebounce from 'hooks/useDebounce';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ListEmptyState from 'components/shared/ListEmptyState';

import SkillValueComponentTab from './SkillValueComponentTab';

function SkillTab(props) {
  const { appType } = useUser();
  const { filter } = useProfileFilter();
  const { strengths, weaknesses, getWeaknessData, getStrengthData } =
    useProfileSkill();

  const { profileSkillImprovementSee, profileSkillStrengthSee } =
    permission || {};

  const debouncedFilter = useDebounce(filter, 1000);

  useEffect(() => {
    profileSkillStrengthSee && getStrengthData(props.match.params.userId);
    appType !== 'culture' &&
      profileSkillImprovementSee &&
      getWeaknessData(props.match.params.userId);
  }, [debouncedFilter]);

  const permission = props.permission;

  const strengthSee = usePermission('profileSkillStrengthSee', permission);
  const improvementSee = permission?.profileSkillImprovementSee;

  return (
    <>
      {(strengths.length < 1 && weaknesses.length < 1) ||
      (!strengthSee && !improvementSee) ? (
        <ListEmptyState
          fullHeight
          containerClassname="mt-[-80px]"
          emptyIcon={
            !strengthSee && !improvementSee
              ? 'icon-no-permission'
              : 'icon-no-skill'
          }
          title={getObjectiveLocale(
            !strengthSee && !improvementSee
              ? 'No permission'
              : 'No skill available yet'
          )}
          subtitle={getObjectiveLocale(
            !strengthSee && !improvementSee
              ? "You don't have permission to see this content"
              : 'You have not received or given any skills yet'
          )}
        />
      ) : (
        <SkillValueComponentTab
          strengths={strengths}
          weaknesses={weaknesses}
          props={props}
          selector="skill"
        />
      )}
    </>
  );
}

export default withRouter(SkillTab);

import client from './ApiClient';

function getPerformanceSourceTeam(query, userId) {
  const url = `v1/users/${userId}/team_performance_reports`;
  return client(url, { params: query });
}

function getPerformanceSourceRole(query, userId) {
  const url = `v1/users/${userId}/roles`;
  return client(url, { params: query });
}

function getBehaviourReportSummary(query, userId, type) {
  const url = `/v1/users/${userId}/${type}/behavior`;
  return client(url, { params: query });
}

function getBehaviourReview(query) {
  const url = `/v1/formal_reviews/performance_rating/counts`;
  return client(url, { params: query });
}

function getPerformanceRatingSource(query) {
  const url = `/v1/formal_reviews/performance_rating/answers`;
  return client(url, { params: query });
}

function getListTeam(query, userId) {
  const url = `/v1/users/${userId}/team_performance_reports`;
  return client(url, { params: query });
}

function getListRole(query, userId) {
  const url = `/v1/users/${userId}/roles`;
  return client(url, { params: query });
}

function getPerformanceRatingObjectives(query, cycleId) {
  const url = `v3/formal_reviews/cycles/${cycleId}/performance_rating/objectives`;
  return client(url, { params: query });
}

function getPerformanceReportOverviewTeam(query, userId) {
  const url = `v1/users/${userId}/overview_reports`;
  return client(url, { params: { type: 'team', ...query } });
}

function getPerformanceReportOverviewIndividual(query, userId) {
  const url = `v1/users/${userId}/overview_reports`;
  return client(url, { params: { type: 'individual', ...query } });
}

function getPerformanceReportOverview(query, userId) {
  const url = `v1/users/${userId}/overview_performance_reports`;
  return client(url, { params: query });
}

export {
  getPerformanceSourceTeam,
  getPerformanceSourceRole,
  getBehaviourReportSummary,
  getBehaviourReview,
  getPerformanceRatingSource,
  getListTeam,
  getListRole,
  getPerformanceRatingObjectives,
  getPerformanceReportOverviewTeam,
  getPerformanceReportOverviewIndividual,
  getPerformanceReportOverview
};

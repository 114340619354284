import React, { useContext, useRef, useState } from 'react';

import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';

import MainBarContainer from './BarComponents/MainBarContainer';
import { GanttChartContext } from './GanttChart';
import GanttChartSidebarWrapper from './GanttChartSidebarWrapper';
import GanttObjectivesWrapper from './ObjectivesSidebarComponents/GanttObjectivesWrapper';
import { ADD_TOP } from './utils/const';

const GanttChartListWrapper = ({
  subOptions,
  data,
  index,
  mandatoryParams,
  paramIdentifier,
  paramValueKey,
  level,
  contentOptions,
  noGroup
}) => {
  const defaultShowChild = contentOptions?.showChild || false;
  const [isFetching, setIsFetching] = useState(true);
  const [showChild, setShowChild] = useState(defaultShowChild);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [openAdd, setOpenAdd] = useState(null);
  const [queryKeyList, setQueryKeyList] = useState('');
  const headerRef = useRef(null);

  const params = {
    ...mandatoryParams,
    [paramIdentifier]: data?.[paramValueKey]
  };

  const showTaskToggle = () => {
    if (showChild) {
      setContentLoaded(false);
    }
    setShowChild((draft) => !draft);
  };

  const ganttChartContext = useContext(GanttChartContext);
  const objectiveOptions = ganttChartContext?.objectiveOptions;

  return (
    <div
      className={`side-group ${
        level == 0 ? 'ground-group' : ''
      } w-full relative min-h-[32px] mt-[8px] overflow-hidden`}
    >
      {!noGroup && (
        <HeaderGroupWrapper
          headerRef={headerRef}
          contentOptions={contentOptions}
          data={data}
          index={index}
          showChild={showChild}
          showTaskToggle={showTaskToggle}
          level={level}
          setOpenAdd={setOpenAdd}
        />
      )}
      <SubGroupObjectiveName
        noGroup={noGroup}
        subOptions={subOptions}
        showChild={showChild}
        params={params}
        level={level}
        objectiveOptions={objectiveOptions}
        contentLoaded={contentLoaded}
        setContentLoaded={setContentLoaded}
        headerRef={headerRef}
        setIsFetching={setIsFetching}
        isFetching={isFetching}
        setQueryKeyList={setQueryKeyList}
        queryKeyList={queryKeyList}
        data={data}
        setOpenAdd={setOpenAdd}
        openAdd={openAdd}
      />
      <MainBarContainer
        subOptions={subOptions}
        showChild={showChild}
        noGroup={noGroup}
        queryKey={queryKeyList}
        contentLoaded={contentLoaded}
        isFetching={isFetching}
        group={data}
        contentOptions={objectiveOptions?.options}
        subContentOptions={objectiveOptions?.subObjectiveOptions}
        openAdd={openAdd}
      />
    </div>
  );
};

const SubGroupObjectiveName = ({
  noGroup,
  subOptions,
  showChild,
  params,
  level,
  objectiveOptions,
  contentLoaded,
  setContentLoaded,
  headerRef,
  setIsFetching,
  isFetching,
  setQueryKeyList,
  queryKeyList,
  data,
  setOpenAdd,
  openAdd
}) => {
  return (
    <div className={` ${!noGroup ? 'mt-[32px]' : ''}`}>
      {subOptions && showChild && (
        <GanttChartSidebarWrapper
          contentOptions={subOptions}
          mandatoryParams={params}
          level={level + 1}
        />
      )}
      {((!subOptions && objectiveOptions && showChild) ||
        (noGroup && objectiveOptions)) && (
        <div className="absolute z-[2] sidebar-name w-[360px]">
          <GanttObjectivesWrapper
            contentLoaded={contentLoaded}
            setContentLoaded={setContentLoaded}
            headerRef={headerRef}
            setIsFetching={setIsFetching}
            isFetching={isFetching}
            contentOptions={objectiveOptions?.options}
            subContentOptions={objectiveOptions?.subObjectiveOptions}
            parentParams={params}
            setQueryKey={setQueryKeyList}
            queryKey={queryKeyList}
            group={data}
            setOpenAdd={setOpenAdd}
            openAdd={openAdd}
          />
        </div>
      )}
    </div>
  );
};

const HeaderGroupWrapper = ({
  headerRef,
  contentOptions,
  data,
  index,
  showChild,
  showTaskToggle,
  level,
  setOpenAdd
}) => {
  const iconName = !showChild
    ? 'icon-keyboard_arrow_right'
    : 'icon-keyboard_arrow_down';

  const openAddTop = () => setOpenAdd(ADD_TOP);

  return (
    <div ref={headerRef} className="absolute z-[2] sidebar-name">
      {contentOptions?.customHeader?.component ? (
        React.cloneElement(contentOptions?.customHeader?.component, {
          data,
          index,
          showChild,
          showTaskToggle,
          openAddTop
        })
      ) : (
        <div className="z-[3] w-[360px] flex flex-col px-[12px]">
          <div
            className="flex items-center cursor-pointer"
            onClick={showTaskToggle}
            style={{ paddingLeft: level * 12 + 'px' }}
          >
            <SVGIcon
              id={`icon-accordion-${data.id}`}
              size={24}
              fillColor="var(--n-600)"
              iconName={iconName}
            />
            <div className="max-w-[260px]">
              <TruncateParagraph className="typography-h200 text-n-600 ml-[8px] uppercase">
                {data.name}
              </TruncateParagraph>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GanttChartListWrapper;

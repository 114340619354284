import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import BannerBox from 'src/components/shared/BannerBox';
import { getObjectiveInvolvements } from 'src/utils/ObjectivesHelper';

const BannerReset = ({
  initialDefaultMembers,
  objectiveValue,
  setObjectiveValue,
  isTeam
}) => {
  const { involvements, team: selectedTeam } = objectiveValue || {};

  const selectedTeamMembers =
    selectedTeam?.involvements?.length > 0
      ? selectedTeam?.involvements?.map((involvement) => ({
          userId: involvement?.user?.id,
          user: involvement?.user,
          role: involvement?.role,
          extendedRole: involvement?.extendedRole || null,
          visible: true
        }))
      : null;

  const { teamMember, projectMember } = initialDefaultMembers || {};
  const defaultInvolvements = isTeam
    ? selectedTeamMembers || teamMember
    : projectMember;

  const getCondition = (type, involvement) => {
    switch (type) {
      case 'leader': {
        return !(
          involvement?.role == 'assignee' &&
          involvement?.extendedRole == 'leader'
        );
      }
      case 'reviewer': {
        return involvement?.role !== 'assigner';
      }
      case 'member': {
        return !(
          involvement?.role === 'assignee' &&
          involvement?.extendedRole !== 'leader' &&
          involvement?.extendedRole !== 'pmo'
        );
      }
      case 'stakeholder': {
        return !(
          involvement?.role === 'follower' && !involvement?.extendedRole
        );
      }
      case 'pmo': {
        return !(
          involvement?.role === 'follower' &&
          involvement?.extendedRole === 'pmo'
        );
      }
    }
  };

  const resetRoles = (type) => {
    const excludeRole = involvements?.filter((involvement) =>
      getCondition(type, involvement)
    );

    const defaultRole = getObjectiveInvolvements(defaultInvolvements, type);

    setObjectiveValue((draft) => {
      draft.involvements = [...excludeRole, ...defaultRole];
    });
  };

  const handleResetInvolvements = (type) => {
    if (type == 'all') {
      setObjectiveValue((draft) => {
        draft.involvements = defaultInvolvements;
      });
    } else {
      resetRoles(type);
    }
  };

  const options = [
    {
      text: getObjectiveLocale('All Involvement'),
      onClick: () => handleResetInvolvements('all')
    },
    {
      text: getObjectiveLocale('Leader'),
      onClick: () => handleResetInvolvements('leader')
    },
    {
      text: getObjectiveLocale('Reviewer'),
      onClick: () => handleResetInvolvements('reviewer')
    },
    {
      text: getObjectiveLocale('Members'),
      onClick: () => handleResetInvolvements('member')
    },
    {
      text: getObjectiveLocale('Stakeholders'),
      onClick: () => handleResetInvolvements('stakeholder')
    },
    {
      text: getObjectiveLocale('PMO'),
      onClick: () => handleResetInvolvements('pmo')
    }
  ];

  return (
    <BannerBox
      type="info"
      alignItemsClass="items-start"
      marginClass="mb-[0px]"
      paddingClass="py-[8px] px-[16px]"
      iconSize={24}
    >
      <div className="flex items-center justify-between">
        <p className="typography-paragraph text-n-900">
          {getObjectiveLocale(
            'You can change or reset the involvement in this project.'
          )}
        </p>
        <Dropdown>
          <Dropdown.Trigger>
            <p className="typography-link cursor-pointer text-center">Reset</p>
          </Dropdown.Trigger>
          <Dropdown.MenuItems options={options} />
        </Dropdown>
      </div>
    </BannerBox>
  );
};

export default BannerReset;

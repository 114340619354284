import React from 'react';

import Modal from 'components/shared/modal/Modal';

const ModalDeleteAssignment = ({
  type,
  _handleActionModal,
  _handleNext,
  cycle,
  assigmentTitle
}) => {
  const getModalProps = () => {
    switch (type) {
      case 'deleteAssignment':
        return {
          title: 'Delete Assignment',
          description: `Are you sure you want to delete this assignment? This might affect the user's overall assignments dependency and score.`,
          withPrimaryBtn: {
            title: `Delete Assignment`,
            dataCy: 'submit-delete',
            onClick: _handleNext,
            danger: true
          }
        };
      case 'deleteAllAssignment':
        return {
          title: 'Delete All Assignments',
          description: `Are you sure you want to delete all assignments for ${assigmentTitle} in cycle ${cycle?.name}? This might affect the user's overall assignments dependency and score.`,
          withPrimaryBtn: {
            title: `Delete All Assignments`,
            dataCy: 'submit-delete',
            onClick: _handleNext,
            danger: true
          }
        };
      case 'undeleteAssignment':
        return {
          title: 'Restore Assignment',
          description: `Are you sure you want to restore this assignment? This might affect the user's overall assignments dependency and score.`,
          withPrimaryBtn: {
            title: `Restore Assignment`,
            dataCy: 'submit-delete',
            onClick: _handleNext
          }
        };
      default:
        return {
          title: 'Restore All Assignments',
          description: `Are you sure you want to restore all assignments for ${assigmentTitle} in cycle ${cycle?.name}? This might affect the user's overall assignments dependency and score.`,
          withPrimaryBtn: {
            title: `Restore All Assignments`,
            dataCy: 'submit-delete',
            onClick: _handleNext
          }
        };
    }
  };

  return (
    <Modal
      headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
      withSecondaryBtn={{ title: 'No', onClick: _handleActionModal }}
      className="max-w-[400px]"
      eventOnClickClose={_handleActionModal}
      {...getModalProps()}
    />
  );
};

export default ModalDeleteAssignment;

import React, { useRef } from 'react';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';

import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

Highcharts.setOptions({
  lang: {
    numericSymbols: [
      ` ${getObjectiveLocale('K')}`,
      ` ${getObjectiveLocale('M')}`,
      ` ${getObjectiveLocale('B')}`,
      ` ${getObjectiveLocale('T')}`
    ]
  }
});

const ChartObjectiveAnalytics = ({
  data,
  minimumProgress,
  maximumProgress = null,
  height = '270px',
  reversed = false,
  containerProps = null,
  enabledScroll = false,
  xAxis = { min: null, max: null }
}) => {
  const chartRef = useRef();

  let config = {
    chart: {
      style: { fontFamily: 'Inter' },
      backgroundColor: 'var(--n-000)',
      type: 'spline',
      marginTop: 50,
      height: height,
      events: {
        click: function (e) {
          e?.stopPropagation();
        }
      }
    },
    legend: {
      align: 'left',
      verticalAlign: 'top',
      floating: true,
      x: 0,
      y: 0,
      enabled: enabledScroll,
      itemStyle: {
        color: '#475569',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px'
      },
      reversed: reversed
    },
    title: {
      text: '',
      style: {
        display: 'none'
      }
    },
    xAxis: {
      type: 'category',
      min: xAxis.min,
      max: xAxis.max,
      ...data.categories,
      scrollbar: {
        enabled: enabledScroll,
        barBackgroundColor: 'var(--n-400)',
        barBorderRadius: 4,
        barBorderWidth: 0,
        buttonBackgroundColor: 'white',
        buttonBorderWidth: 0,
        buttonArrowColor: 'white',
        buttonBorderRadius: 4,
        rifleColor: 'white',
        trackBackgroundColor: 'white',
        trackBorderWidth: 0,
        trackBorderColor: 'white',
        trackBorderRadius: 4,
        height: 4,
        margin: 0
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      min: minimumProgress,
      max: maximumProgress
    },
    tooltip: {
      formatter: function () {
        const currentData = this;
        const pointData = currentData?.points;
        const achievementData = pointData.find(
          (data) => data.point.series.name === 'Achievement'
        )?.y;
        const totalAchievement = achievementData
          ? getNumberLocale(achievementData)
          : '-';
        const targetData = pointData.find(
          (data) => data.point.series.name === 'Target'
        )?.y;
        const totalTarget = targetData ? getNumberLocale(targetData) : '-';
        const titleTooltip = pointData?.[0]?.point?.xLabel
          ? pointData?.[0]?.point?.xLabel
          : pointData?.[0]?.key;
        return (
          `<div class="mx-[8px] my-[8px]" style="display:flex;flex-direction:column;">
          <div class="typography-h100 text-n-600 mb-[12px]"> ` +
          titleTooltip +
          `</div>
          <div style="display:flex;justify-content:space-between;align-items:center;">
            <p class="typography-h100 text-n-800 mr-[32px]">
              <span style="width:8px !important;height:8px !important;margin-right:8px;color:#5417CF">\u25CF</span>` +
          getObjectiveLocale('Achievement') +
          `</p>
            <span class="typography-h200 text-n-900"> ` +
          totalAchievement +
          `</span>
          </div>
          <div style="display:flex;justify-content:space-between;align-items:center;">
            <p class="typography-h100 text-n-800 mr-[32px]">
              <span style="width:8px !important;height:8px !important;margin-right:8px;color:#94A3B8">\u25CF</span>` +
          getObjectiveLocale('Target') +
          `</p>
            <span class="typography-h200 text-n-900"> ` +
          totalTarget +
          `</span>
          </div>
          <div style="display:flex;justify-content:space-between;align-items:center;" class="mt-[16px]">
            <p class="typography-h100 text-n-800 mr-[32px]">
            (%) ` +
          getObjectiveLocale('Achievement') +
          `</p>
            <p class="typography-h200 text-n-900"> ` +
          getNumberLocale(pointData?.[0]?.percentage) +
          `%</p>
          </div>
        </div>`
        );
      },
      backgroundColor: '#fff',
      borderWidth: 0,
      useHTML: true,
      shared: true,
      outside: true
    },
    credits: {
      enabled: false
    },
    series: data.series
  };

  return (
    <HighchartsReact
      containerProps={containerProps}
      highcharts={Highcharts}
      options={config}
      ref={chartRef}
    />
  );
};
export default ChartObjectiveAnalytics;

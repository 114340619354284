import React from 'react';

import Config from 'utils/Config';

import Avatar from 'components/shared/Avatar';
import SVGIcon from 'components/shared/SVGIcon';

function getActivitiesIcon(event, user, currentTab) {
  if (currentTab === 'culture') {
    let iconName = 'icon-notifications';
    if (event === 'thought.new') {
      iconName = 'icon-post-thought';
    } else if (
      event === 'photo.new' ||
      event === 'multiple_media.new' ||
      event === 'photos.new' ||
      event === 'video.new' ||
      event === 'videos.new'
    ) {
      iconName = 'icon-post-photo';
    } else if (event === 'comment') {
      iconName = 'icon-insert_comment';
    } else if (event === 'poll.new') {
      iconName = 'icon-post-survey';
    } else if (event === 'love') {
      iconName = 'icon-favorite';
    } else if (event === 'attachment.new') {
      iconName = 'icon-attach_file';
    } else if (event === 'group.membership') {
      iconName = 'icon-group';
    } else if (event === 'link.new') {
      iconName = 'icon-link';
    } else if (event === 'recognition.new') {
      iconName = 'icon-star-rounded';
    } else if (event === 'thought.new.tagged') {
      iconName = 'icon-people_alt';
    }

    return (
      <div className="flex w-[46px] mr-[8px] relative">
        <Avatar
          name={user?.fullName}
          src={user?.profilePicture?.secureUrl || null}
          className="user-image change-involvement"
          size={32}
        />
        <SVGIcon
          size="16"
          iconName={iconName}
          fillColor="var(--base-600)"
          customClass="icon-activities"
        />
      </div>
    );
  } else {
    if (event == 'survey.invite' || event == 'survey.reminder') {
      return (
        <div className="pr-[16px]">
          <div className="rounded-full bg-g-600 w-[36px] h-[36px] pt-[3px] pl-[3px]">
            <SVGIcon
              iconName="icon-assignment"
              size="30"
              fillColor="var(--n-000)"
            />
          </div>
        </div>
      );
    } else if (
      event == 'objective.comment' ||
      event == 'objective.comment.mention'
    ) {
      return (
        <img
          src={Config.asset.objective.iconCommentActivity}
          className="user-image comment-image"
        />
      );
    } else if (event == 'objective.complete') {
      return (
        <img src={Config.asset.v2.iconDoneCircle} className="user-image" />
      );
    } else if (event == 'objective.uncomplete') {
      return (
        <img
          src={Config.asset.objective.iconOngoing}
          className="user-image ongoing-image img-circle"
        />
      );
    } else if (event == 'objective.create') {
      return (
        <img
          src={Config.asset.objective.iconOngoing}
          className="user-image ongoing-image img-circle"
        />
      );
    } else if (event == 'objective.update') {
      return (
        <img
          src={Config.asset.objective.iconEdit}
          className="user-image edited-image img-circle"
        />
      );
    } else if (event == 'objective.update_current_value') {
      return (
        <img
          src={Config.asset.objective.iconProgress}
          className="user-image update-image img-circle"
        />
      );
    } else if (event == 'objective.delete') {
      return (
        <SVGIcon
          size="20"
          iconName="icon-clear"
          fillColor="var(--n-000)"
          customClass="user-image bg-n-600 rounded-[16px]"
        />
      );
    } else if (
      event == 'objective.review.update' ||
      event == 'objective.review.create'
    ) {
      return (
        <SVGIcon
          size="20"
          iconName="icon-reviewed"
          fillColor="var(--base-600)"
          customClass="user-image"
        />
      );
    } else if (event == 'skill.strength') {
      return (
        <img
          src={Config.asset.objective.iconRecognition}
          className="user-image img-circle"
        />
      );
    } else if (
      event == 'objective.stakeholders.create' ||
      event == 'objective.stakeholders.change_followers'
    ) {
      return (
        <SVGIcon
          iconName="icon-group"
          customClass="user-image"
          size={20}
          fillColor="var(--n-600)"
        />
      );
    } else if (event == 'objective.remind') {
      return (
        <SVGIcon
          iconName="icon-reminder_activity"
          customClass="user-image"
          size={20}
        />
      );
    } else if (
      event == 'objective.stakeholders.change_assignees' ||
      event == 'objective.stakeholders.change_assigners'
    ) {
      return (
        <Avatar
          name={user.name}
          src={user.profilePicture}
          className="user-image change-involvement"
          size={20}
        />
      );
    } else if (
      event == 'approval_request.approve' ||
      event == 'approval_request.request'
    ) {
      return (
        <SVGIcon
          iconName="icon-approval"
          customClass="user-image"
          size={20}
          fillColor="var(--g-600)"
        />
      );
    } else if (event == 'approval_request.withdraw') {
      return (
        <SVGIcon iconName="icon-reject" customClass="user-image" size={20} />
      );
    } else {
      return (
        <SVGIcon
          iconName="icon-attachment_circle"
          customClass="user-image"
          size={20}
        />
      );
    }
  }
}

export { getActivitiesIcon };

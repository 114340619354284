import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

import dayjs from 'dayjs';

import { getOverviewUsers, remindUsers } from 'client/OverviewClient';
import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';

import UserTableInfo from 'components/admin/groups/UserTableInfo.js';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Switcher from 'components/advance-filter/Switcher';
import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';
import Modal from 'components/shared/modal/Modal';

import TableUser from './TableUser';

function OverviewUser({
  currentTab,
  switchOption,
  setSwitchOption,
  selectedParams,
  departmentParams,
  setToastData
}) {
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalMembers, setShowModalMembers] = useState(false);
  const [paginationData, setPaginationData] = useState({
    olderThan: null,
    newerThan: null
  });
  const [hasMore, setHasMore] = useState(true);
  const filterById = useFilter((state) => state.filterById);
  const [listUsers, setListUsers] = useState([]);
  const [appendListUsers, setAppendListUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [selectedUsers, setSelectedUsers] = useState({
    includes: [],
    excludes: [],
    selectAll: false
  });
  const [sortDirection, setSortDirection] = useState('asc');

  const currentDate = useMemo(() => dayjs().format('DDMMYYYY'), []);

  let fileHeaders = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Job Title', key: 'jobTitle' },
    { label: 'Department', key: 'department' },
    { label: 'Division', key: 'division' },
    { label: 'Goal Ownership', key: 'goalCount' }
  ];

  const optionsTabs = (tab) => {
    if (tab === 'owned') {
      return [
        { id: 'owned', name: 'Have owned goal' },
        { id: 'not_owned', name: 'Have no goal' }
      ];
    } else if (tab === 'approved') {
      return [
        { id: 'all', name: 'All' },
        { id: 'remind', name: 'Need to remind' }
      ];
    } else if (tab === 'updated') {
      return [
        { id: 'updated_regularly', name: 'Update regularly' },
        { id: 'missed_update', name: 'Missed update' }
      ];
    }
  };

  const resetFunction = () => {
    setSelectedUsers({
      includes: [],
      excludes: [],
      selectAll: false
    });
  };

  const remindSelectedUser = async () => {
    const body = {
      division: selectedParams?.division,
      department: selectedParams?.department
        ? [departmentParams, ...selectedParams.department]
        : departmentParams,
      periodBegin: dayjs(selectedParams?.date[0]).toISOString(),
      periodEnd: dayjs(selectedParams?.date[1]).toISOString(),
      includes: selectedUsers?.includes,
      excludes: selectedUsers?.excludes,
      reminderType:
        currentTab == 'owned'
          ? 'objective_ownership'
          : currentTab == 'updated'
          ? 'objective_update'
          : 'objective_approval',
      selectAll: selectedUsers?.selectAll
    };
    const { data } = await remindUsers(body);
    if (data) {
      setToastData({
        show: true,
        message: 'Reminder sent!',
        warning: ''
      });
      setTimeout(() => {
        setToastData({ show: false, msg: '' });
        resetFunction();
      }, 3000);
    }
  };

  const getListUsers = async (type, isAppend = false) => {
    !isAppend && setIsLoading(true);
    let query = {
      division: selectedParams?.division,
      department: selectedParams?.department
        ? [departmentParams, ...selectedParams.department]
        : departmentParams,
      periodBegin: dayjs(selectedParams?.date[0]).toISOString(),
      periodEnd: dayjs(selectedParams?.date[1]).toISOString(),
      limit: currentLimit,
      tab: switchOption,
      sortColumn: 'name',
      sortDirection: sortDirection,
      ...filterById['userFilter']
    };
    if (type == 'next') {
      query.olderThan = paginationData?.olderThan;
      !isAppend && setCurrentPage(currentPage + 1);
    }
    if (type == 'prev') {
      query.newerThan = paginationData?.newerThan;
      !isAppend && setCurrentPage(currentPage - 1);
    }
    const { data, pagination, isSuccess } = await getOverviewUsers(
      `${currentTab}`,
      query
    );
    if (data?.length > 0 && isSuccess) {
      if (isAppend) {
        setAppendListUsers((prev) => [...prev, ...data]);
      } else {
        setListUsers(data);
      }
      setPaginationData({
        olderThan: pagination?.next?.olderThan,
        newerThan: pagination?.prev?.newerThan
      });
      setHasMore(data?.length === currentLimit);
    } else {
      setListUsers([]);
    }
    setIsLoading(false);
  };

  const handleExportCSV = async () => {
    setTimeout(() => {
      ref.current.link.click();
    }, [1000]);

    setShowModal(false);
  };

  useEffect(() => {
    getListUsers();
    // eslint-disable-next-line
  }, [
    filterById,
    selectedParams,
    switchOption,
    departmentParams,
    currentLimit,
    sortDirection
  ]);

  useEffect(() => {
    setCurrentPage(1);
    setPaginationData({ olderThan: null, newerThan: null });
  }, [switchOption]);

  useEffect(() => {
    resetFunction();
  }, [currentTab]);

  return (
    <div className="mt-[24px]">
      {showModal && (
        <Modal
          title="Export CSV"
          className="w-[400px]"
          dataCyModal="export-csv-progress-overtime-modal"
          eventOnClickClose={() => setShowModal(false)}
          withPrimaryBtn={{
            title: 'Export',
            dataCy: 'export-csv-overtime',
            onClick: () => handleExportCSV()
          }}
          withSecondaryBtn={{
            title: 'Cancel',
            dataCy: 'cancel-csv-overtime',
            onClick: () => setShowModal(false)
          }}
        >
          <p className="typography-h100 text-n-800">
            {getObjectiveLocale('Data shown in the table will be exported.')}
          </p>
          <p className="mb-[16px] typography-h100 text-n-800">
            {getObjectiveLocale('100 rows at max.')}
          </p>
        </Modal>
      )}
      <Switcher
        options={optionsTabs(currentTab)}
        onChange={(type) => setSwitchOption(type)}
        value={switchOption}
      />
      {['not_owned', 'remind', 'missed_update'].includes(switchOption) && (
        <UserTableInfo
          setShowModal={setShowModalMembers}
          isSelectAll={selectedUsers?.selectAll}
          handleSelectAll={() => {
            setSelectedUsers({
              includes: [],
              excludes: [],
              selectAll: true
            });
          }}
          listIdUser={listUsers}
          totalLength=""
          excludedId={selectedUsers?.excludes?.length}
          totalSelectedUser={selectedUsers?.includes?.length}
          customClass="mt-[24px] !mb-[0]"
          resetFunction={resetFunction}
          remindSelectedUser={
            selectedUsers?.includes.length > 0 || selectedUsers?.selectAll
              ? remindSelectedUser
              : null
          }
        />
      )}
      <div className="flex items-start mb-[16px] mt-[24px]">
        <AdvancedFilter
          filterOptions={['search']}
          defaultFilter={{
            q: ''
          }}
          customClass="w-full"
          id="userFilter"
        />
        <Button
          customClass="flex items-center ml-[8px]"
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(!showModal);
          }}
          datacy="export-csv"
        >
          <SVGIcon
            size="24"
            fillColor="var(--n-100)"
            iconName="icon-file_download"
            customClass="mr-[4px]"
          />
          {getObjectiveLocale('Export CSV')}
        </Button>
      </div>
      <TableUser
        showModalMembers={showModalMembers}
        setShowModalMembers={setShowModalMembers}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        listUsers={listUsers}
        isLoading={isLoading}
        currentPage={currentPage}
        hasMore={hasMore}
        getListUsers={getListUsers}
        switchTab={switchOption}
        appendListUsers={appendListUsers}
        setAppendListUsers={setAppendListUsers}
        setCurrentLimit={setCurrentLimit}
        currentLimit={currentLimit}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <CSVLink
        headers={fileHeaders}
        data={listUsers}
        filename={`${
          switchOption == 'owned' ? 'Have owned goal' : 'Have no goal'
        }_${currentDate}.csv`}
        target="_blank"
        ref={ref}
      />
    </div>
  );
}

export default OverviewUser;

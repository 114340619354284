import React from 'react';

import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Star from 'components/objective-review-popup/Star';
import SVGIcon from 'components/shared/SVGIcon';

function Summary({
  selectedRating,
  ratingComment,
  selectedRecognitionSOL,
  recognitionSOLComment,
  selectedFeedbackSOL,
  feedbackSOLComment,
  selectedRecognitionSkill,
  recognitionSkillComment,
  selectedFeedbackSkill,
  feedbackSkillComment,
  options,
  onChangeNavigation
}) {
  const selectedId = selectedRating;
  const sortedOptions = options.sort((a, b) => (a.score > b.score ? 1 : -1));
  const selectedIndex = sortedOptions.findIndex(
    (option) => option?.id == selectedId
  );
  const selectedOptionName = options[selectedIndex].name;
  const selectedOptionColor = options[selectedIndex].colorHex;

  const resultStyle = {
    color: '#' + selectedOptionColor
  };
  return (
    <div className="team-rating-box-body">
      <p className="title-rating">
        {getObjectiveLocale('How was this person’s contribution to the team?')}
      </p>
      <div className="rating-summary-box">
        <div className="small-star">
          {sortedOptions.map((option, index) => {
            return (
              <Star
                key={index}
                selected={index <= selectedIndex}
                isSelected={index == selectedIndex}
                text={option?.name}
                color={option?.colorHex}
              />
            );
          })}
        </div>
        <span className="p-rating" style={resultStyle}>
          {selectedOptionName}
        </span>
        <span onClick={() => onChangeNavigation('rating')}>
          <p className="text-edit"> {getObjectiveLocale('Edit')} </p>
          <SVGIcon
            iconName="icon-compose"
            customClass="icon-compose"
            size={20}
          />
        </span>
        <p className="summary-comment">{ratingComment}</p>
      </div>

      {(selectedRecognitionSOL || selectedFeedbackSOL) && (
        <p className="title-rating">{getObjectiveLocale('Value')}</p>
      )}

      {selectedRecognitionSOL && (
        <div className="rating-summary-box">
          <div className="rating-rounded-box">
            <SVGIcon iconName="icon-strength" size={20} />
            <span className="label-green">
              {getObjectiveLocale('Strength')}
            </span>
          </div>
          <span onClick={() => onChangeNavigation('recognitionSOL')}>
            <p className="text-edit"> {getObjectiveLocale('Edit')} </p>
            <SVGIcon
              iconName="icon-compose"
              customClass="icon-compose"
              size={20}
            />
          </span>
          <div className="skill list-recognition green-list">
            <span className="text">{selectedRecognitionSOL.name}</span>
          </div>
          <p className="summary-comment">{recognitionSOLComment}</p>
        </div>
      )}

      {selectedFeedbackSOL && (
        <div className="rating-summary-box">
          <div className="rating-rounded-box">
            <SVGIcon iconName="icon-improvement" size={20} />
            <span className="label-var(--y-500)">
              {getObjectiveLocale('Development')}
            </span>
          </div>
          <span onClick={() => onChangeNavigation('feedbackSOL')}>
            <p className="text-edit"> {getObjectiveLocale('Edit')} </p>
            <SVGIcon
              iconName="icon-compose"
              customClass="icon-compose"
              size={20}
            />
          </span>
          <div className="skill list-recognition">
            <span className="text">{selectedFeedbackSOL.name}</span>
          </div>
          <p className="summary-comment">{feedbackSOLComment}</p>
        </div>
      )}

      {(selectedRecognitionSkill || selectedFeedbackSkill) && (
        <p className="title-rating">{getObjectiveLocale('Competency')}</p>
      )}

      {selectedRecognitionSkill && (
        <div className="rating-summary-box">
          <div className="rating-rounded-box">
            <SVGIcon iconName="icon-strength" size={20} />
            <span className="label-green">
              {getObjectiveLocale('Strength')}
            </span>
          </div>
          <span onClick={() => onChangeNavigation('recognitionSkill')}>
            <p className="text-edit"> {getObjectiveLocale('Edit')} </p>
            <SVGIcon
              iconName="icon-compose"
              customClass="icon-compose"
              size={20}
            />
          </span>
          <div className="skill list-recognition green-list">
            <span className="text">{selectedRecognitionSkill.name}</span>
          </div>
          <p className="summary-comment">{recognitionSkillComment}</p>
        </div>
      )}

      {selectedFeedbackSkill && (
        <div className="rating-summary-box">
          <div className="rating-rounded-box">
            <SVGIcon iconName="icon-improvement" size={20} />
            <span className="label-var(--y-500)">
              {getObjectiveLocale('Development')}
            </span>
          </div>
          <span onClick={() => onChangeNavigation('feedbackSkill')}>
            <p className="text-edit"> {getObjectiveLocale('Edit')} </p>
            <SVGIcon
              iconName="icon-compose"
              customClass="icon-compose"
              size={20}
            />
          </span>
          <div className="skill list-recognition">
            <span className="text">{selectedFeedbackSkill.name}</span>
          </div>
          <p className="summary-comment">{feedbackSkillComment}</p>
        </div>
      )}
    </div>
  );
}

export default Summary;

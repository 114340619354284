import React from 'react';

import ctl from '@netlify/classnames-template-literals';

import { getCssVariableValue, getObjectiveLocale } from 'utils/HelperUtils';

import Badge from 'components/shared/Badge';

const OverallStatusCompact = ({ listStatus, filterByStatus, page }) => {
  const whichBgColor = {
    '5A9AEF': 'b-200',
    '44DB5E': 'g-200',
    FFCD00: 'y-200',
    FE2851: 'r-200'
  };

  const whichTextColor = {
    '5A9AEF': 'b-800',
    '44DB5E': 'g-800',
    FFCD00: 'y-800',
    FE2851: 'r-800'
  };

  const listLabel = listStatus?.map((status) => ({
    id: status?.progressTitle,
    title: status?.progressTitle,
    content: `${status?.qty || 0}`,
    colorHex: getCssVariableValue(
      `--${whichTextColor[status?.progressColorHex]}`
    ),
    bgColorHex: getCssVariableValue(
      `--${whichBgColor[status?.progressColorHex]}`
    ),
    useEvent: filterByStatus,
    onClick: () => filterByStatus(status?.progressTitle)
  }));

  const isPerformanceTab = page == 'performanceTab';

  const wrapperCN = ctl(`
  flex h-[40px] ml-[24px] ${
    isPerformanceTab
      ? 'flex-row items-center pl-0 border-0'
      : `flex-col items-start pl-[24px] border-y-0 border-r-0`
  }`);

  const titleCN = ctl(`
  typography-h200 text-n-800 cursor-default
  ${isPerformanceTab ? 'mr-[8px]' : 'mb-[4px]'}
  `);

  return (
    <div className={wrapperCN}>
      <p className={titleCN}>{getObjectiveLocale('Status')}</p>

      <div className="flex flex-row w-auto">
        {listLabel?.reverse().map((list, index) => {
          return (
            <Badge
              key={list.id}
              dataCy={`list-label-${index}`}
              className={`mr-[8px] ${
                filterByStatus ? 'cursor-pointer' : 'cursor-default'
              }`}
              colorHex={list.colorHex}
              bgColorHex={list.bgColorHex}
              title={list.title}
              content={list.content}
              onClick={list.onClick}
              useEvent={list.useEvent}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OverallStatusCompact;

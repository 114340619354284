import React, { useMemo, useState } from 'react';

import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import useTeam from 'hooks/useTeam';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { defaultFilter, sortOptions } from 'utils/TeamHelper';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Switcher from 'components/design-system/Switcher';
import ObjectivesModal from 'components/objectives/ObjectivesModal';
import ListEmptyState from 'components/shared/ListEmptyState';
import AccordionTeam from 'components/teams/AccordionTeam';
import ModalCreateTeam from 'components/teams/modal/ModalCreateTeam';
import ModalTeamImport from 'components/teams/modal/ModalImportTeam/ModalTeamImport';

import TeamsApproval from './TeamsApproval';

const NewTeams = ({ route: { title } }) => {
  const {
    config: { defaultTeamSorting, permissions }
  } = useUser();
  const filter = useFilter((state) => state.filterById?.teamFilter);
  const filterApproval = useFilter(
    (state) => state.filterById?.approvalTeamFilter
  );
  const { squadCreate, tribeCreate, squadSee, tribeSee } = permissions || {};
  const [selectedSwitcher, setSelectedSwitcher] = useState('active');

  const switcherOptions = [
    { id: 'active', name: getObjectiveLocale('Active') },
    { id: 'archived', name: getObjectiveLocale('Archived') }
  ];

  const { tribes, squads, isTribeLoading, isSquadLoading } = useTeam();

  const [openModalCreate, setOpenModalCreate] = useState({ show: false });
  const [openModalImport, setOpenModalImport] = useState(false);

  const [currentTab, setCurrentTab] = useState('teams');

  const listObjTabs = [
    {
      id: 'teams',
      name: 'Team',
      onClick: () => history.replace('/teams'),
      isVisible: true
    },
    {
      id: 'teams-approval',
      name: 'Approval',
      onClick: () => history.replace('/teams-approval'),
      isVisible: permissions?.squadApprovalSee
    }
  ].filter((opt) => opt.isVisible);

  const listSection = useMemo(
    () => [
      {
        name: 'tribe',
        dataCy: 'new-tribe',
        isViewAll: tribeSee,
        isAbleToCreateTeam: selectedSwitcher == 'active' && tribeCreate,
        isVisible:
          tribeSee || isTribeLoading || filter?.q ? true : tribes?.length > 0,
        isContentRendered:
          !isTribeLoading &&
          !isSquadLoading &&
          (tribeSee ? filter?.q || tribes?.length > 0 : tribes?.length > 0)
      },
      {
        name: 'squad',
        dataCy: 'new-squad',
        isViewAll: squadSee,
        isAbleToCreateTeam: selectedSwitcher == 'active' && squadCreate,
        isVisible:
          squadSee || isSquadLoading || filter?.q ? true : squads?.length > 0,
        isContentRendered:
          !isTribeLoading &&
          !isSquadLoading &&
          (squadSee ? filter?.q || squads?.length > 0 : squads?.length > 0)
      }
    ],
    [
      tribeSee,
      squadSee,
      isTribeLoading,
      isSquadLoading,
      tribeCreate,
      squadCreate,
      tribes?.length,
      squads?.length,
      filter?.q,
      selectedSwitcher
    ]
  );

  const listVisibleSection = listSection.filter(
    (section) => section.isContentRendered
  );

  const newListDropdown = useMemo(
    () =>
      [
        {
          title: 'Tribe',
          icon: 'icon-tribes',
          onClick: (e) => setNewButton(e, 'tribe'),
          isVisible: tribeCreate,
          dataCy: 'new-tribe'
        },
        {
          title: 'Squad',
          icon: 'icon-group-work',
          onClick: (e) => setNewButton(e, 'squad'),
          isVisible: squadCreate,
          dataCy: 'new-squad'
        }
      ].filter(({ isVisible }) => isVisible),
    [tribeCreate, squadCreate]
  );

  const setNewButton = (e, type) => {
    e.stopPropagation();
    setOpenModalCreate({ show: true, type: type });
  };

  return (
    <>
      <HeaderPage
        titlePage={title}
        primaryAction={
          newListDropdown?.length > 0 && {
            title: getObjectiveLocale('New'),
            icon: 'icon-add',
            dropdown: newListDropdown,
            dataCy: 'new-team'
          }
        }
        secondaryAction={
          permissions.squadImport && {
            title: getObjectiveLocale('Import'),
            icon: 'icon-file_upload',
            onClick: () => setOpenModalImport(true)
          }
        }
        tabs={{
          tabsList: listObjTabs,
          url: '/teams',
          onChange: (tab) => {
            setCurrentTab(tab);
          }
        }}
      />

      <div className="my-[24px]">
        <AdvancedFilter
          id={currentTab == 'teams' ? 'teamFilter' : 'approvalTeamFilter'}
          filterOptions={['search', 'sort']}
          defaultFilter={defaultFilter(defaultTeamSorting)}
          sortList={sortOptions}
        />
      </div>
      {currentTab == 'teams' && (
        <Switcher
          options={switcherOptions}
          selectedOption={selectedSwitcher}
          onChange={(option) => setSelectedSwitcher(option)}
          customClass="my-[24px]"
          textCustomClass="typography-button w-[120px]"
        />
      )}
      {currentTab == 'teams' ? (
        <>
          {(isTribeLoading || isSquadLoading) && (
            <ListEmptyState
              containerClassname="h-auto mt-[96px]"
              emptyIcon="icon-loading-page"
              withLoadingIndicator
              title={getObjectiveLocale(
                `${filter?.q ? 'Searching' : 'Loading'} your teams..`
              )}
              subtitle={getObjectiveLocale(
                `${
                  filter?.q ? 'Your search result ' : 'Your teams list'
                } will be shown up here`
              )}
            />
          )}

          {listSection.map((section) => (
            <div
              key={`accordion-team-${section?.name}`}
              className={section.isContentRendered ? 'd-block' : 'd-none'}
            >
              <AccordionTeam
                section={section}
                setOpenModalCreate={setOpenModalCreate}
                tribeCreate={tribeCreate}
                squadCreate={squadCreate}
                selectedSwitcher={selectedSwitcher}
              />
            </div>
          ))}

          {listVisibleSection.length === 0 && (
            <ListEmptyState
              containerClassname="h-auto mt-[96px]"
              emptyIcon="icon-no-team"
              title={getObjectiveLocale(
                "You're not currently involved in any teams"
              )}
              subtitle={getObjectiveLocale(
                "Any teams that you're involved in will be shown up here."
              )}
            />
          )}

          {openModalCreate?.show && (
            <ModalCreateTeam
              eventOnClickClose={() => setOpenModalCreate(false)}
              type={openModalCreate?.type}
              tribe={openModalCreate?.tribe}
            />
          )}

          {openModalImport && (
            <ModalTeamImport
              eventOnClickClose={() => setOpenModalImport(false)}
            />
          )}
          <ObjectivesModal />
        </>
      ) : (
        <TeamsApproval filter={filterApproval} />
      )}
    </>
  );
};

export default NewTeams;

import React, { useEffect, useRef, useState } from 'react';

import { useFormalReview } from 'context/FormalReviewContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

import CompetencyTag from './CompetencyTag';
import FormDevelopmentPlan from './FormDevelopmentPlan';
import ModalCompetencies from './ModalCompetencies';

function SelectCompetencies({
  rfObject,
  objects,
  localStateAnswers,
  setLocalStateAnswers
}) {
  const [showModal, setShowModal] = useState(false);
  const [{ currentTrack }, { setAnswerContext }] = useFormalReview();
  const scrollToElement = useRef(null);
  const strengthOrImprovementImg =
    rfObject.type === 'recognition' ? 'icon-strength' : 'icon-improvement';
  const filteredAnswers = localStateAnswers.filter(
    (answer) => answer.type == rfObject.type
  );
  const objectHash = {};
  const activeIds = [];
  const concatIds = [];

  const checkAndAppendHash = (id) => {
    if (!objectHash[id]) {
      objectHash[id] = true;
      concatIds.push(id);
    }
  };

  objects.forEach((object) => {
    checkAndAppendHash(object.id);
  });

  filteredAnswers.forEach((answer) => {
    activeIds.push(answer.objectId);
    checkAndAppendHash(answer.objectId);
  });

  const [reservedIds, setReservedIds] = useState([]);

  useEffect(() => {
    setReservedIds(concatIds);
  }, []);

  const addCompetency = (objectId) => {
    if (activeIds.length >= rfObject.maxSelection) return; // maximuum

    if (localStateAnswers?.find((answer) => answer?.objectId == objectId))
      return;

    let newAnswers = localStateAnswers.map((answer) => {
      let result = {
        comment: answer.comment,
        objectId: answer.objectId,
        type: answer.type
      };
      return result;
    });
    newAnswers.push({
      objectId: objectId,
      type: rfObject.type,
      comment: ''
    });
    setLocalStateAnswers(newAnswers);
    setAnswerContext(currentTrack, { answers: newAnswers }); // uncomment if comment on textarea is optional
    if (reservedIds.filter((id) => id == objectId).length == 0) {
      reservedIds.push(objectId);
    }
    // setTimeout(() => { scrollToBottom() }, 10);
  };

  const deleteCompetency = (objectId) => {
    const newAnswers = localStateAnswers.filter((answer) => {
      return !(answer.type == rfObject.type && answer.objectId == objectId);
    });
    setLocalStateAnswers(newAnswers);
    setAnswerContext(currentTrack, { answers: newAnswers });
  };

  const setAnswerRecognitionFeedback = (objectId, type, comment) => {
    let newAnswers = localStateAnswers.map((answer) => {
      let result = {
        comment: answer.comment,
        objectId: answer.objectId,
        type: answer.type
      };
      if (result.objectId == objectId && result.type == rfObject.type) {
        result.comment = comment;
      }
      return result;
    });
    setLocalStateAnswers(newAnswers);
    setAnswerContext(currentTrack, { answers: newAnswers });
  };

  const getPath = () => {
    if (currentTrack == 'values_recognition_feedback') {
      return 'values/dictionary';
    } else {
      return 'competencies/dictionary';
    }
  };

  const getBehaviorType = () => {
    if (currentTrack == 'values_recognition_feedback') {
      return 'value';
    } else {
      return 'competency';
    }
  };

  return (
    <div className="mechanism-wrapper mb-[48px]" key={rfObject.type}>
      <div className="head-feedback-container">
        <SVGIcon iconName={strengthOrImprovementImg} size={40} />
        <div className="title-description-container">
          <h6 className="title-recognition-feedback">
            {getObjectiveLocale(
              `[competencies recognition feedback] ${rfObject.type}`
            )}
          </h6>
          <span className="subtitle-recognition-feedback">
            {getObjectiveLocale(
              'Minimum competencies selection is [Min] and maximal [Max]'
            )
              .replace('[Min]', rfObject.minSelection)
              .replace('[Max]', rfObject.maxSelection)}
          </span>
        </div>
      </div>
      <div className="select-competencies-container">
        <h6 className="select-competencies-text">
          {getObjectiveLocale('Select Competencies')}
        </h6>
        <div className="competencies-buttons-container">
          {reservedIds.length > 0 &&
            reservedIds.map((id, index) => {
              const objModel = objects.find((obj) => obj.id == id);
              const isActive = activeIds.includes(id);
              return (
                <CompetencyTag
                  objModel={objModel}
                  key={index}
                  id={id}
                  isActive={isActive}
                  onClick={() =>
                    !isActive ? addCompetency(id) : deleteCompetency(id)
                  }
                  behaviorType={getBehaviorType()}
                />
              );
            })}
        </div>
        <Button.Tertiary onClick={() => setShowModal(true)}>
          {getObjectiveLocale('Show All Competencies')}
        </Button.Tertiary>
      </div>
      {/* Select Competencies with Comment box */}
      {filteredAnswers.length > 0 && (
        <div className="selected-competencies-wrapper" key={rfObject.type}>
          <h5 className="select-competencies-text">
            {getObjectiveLocale('Select Competencies')}
          </h5>
          <div className="rendered-competencies-container">
            {filteredAnswers.map((answer, index) => {
              const objModel = objects.find((obj) => obj.id == answer.objectId);
              return (
                <FormDevelopmentPlan
                  key={index}
                  id={answer.objectId}
                  objModel={objModel}
                  scrollToElement={scrollToElement}
                  objType={rfObject.type}
                  needComment={rfObject.useComment}
                  setAnswer={setAnswerRecognitionFeedback}
                  comment={answer.comment}
                  deleteCompetency={(id, type) => deleteCompetency(id, type)}
                  behaviorType={getBehaviorType()}
                />
              );
            })}
          </div>
        </div>
      )}
      {showModal && (
        <ModalCompetencies
          type={rfObject.type}
          activeIds={activeIds}
          addCompetency={addCompetency}
          close={() => setShowModal(false)}
          objects={objects}
          currentPath={getPath()}
        />
      )}
    </div>
  );
}
export default SelectCompetencies;

import React from 'react';

import Shimmer from 'components/design-system/shimmer/Shimmer';

const SkeletonCardRatingTemplate = () => {
  return (
    <div className="box-rating-config bg-n-200 mt-[24px] rounded-[4px]">
      <div className="divider-box flex items-center justify-between py-[16px] px-[16px]">
        <Shimmer customClass="rounded-lg" width="100px" height="36px" />
        <Shimmer customClass="rounded-lg" width="100px" height="36px" />
      </div>

      <div className="divider-box py-[16px] px-[24px]">
        <div className="flex mb-[8px] justify-between">
          <div className="w-[12%]">
            <Shimmer customClass="rounded-lg" width="50px" height="16px" />
          </div>
          <div className="w-[38%] pl-[24px]">
            <Shimmer customClass="rounded-lg" width="50px" height="16px" />
          </div>
          <div className="w-[40%] pl-[24px]">
            <Shimmer customClass="rounded-lg" width="100px" height="16px" />
          </div>
          <div className="w-[10%] pl-[24px]">
            <Shimmer customClass="rounded-lg" width="50px" height="16px" />
          </div>
        </div>

        <div className="flex mb-[8px] justify-between">
          <div className="w-[12%]">
            <Shimmer customClass="rounded-lg" width="100%" height="38px" />
          </div>
          <div className="w-[38%] pl-[24px]">
            <Shimmer customClass="rounded-lg" width="100%" height="38px" />
          </div>
          <div className="w-[40%] pl-[24px]">
            <Shimmer customClass="rounded-lg" width="100%" height="38px" />
          </div>
          <div className="w-[10%] pl-[24px]">
            <Shimmer customClass="rounded-lg" width="100%" height="38px" />
          </div>
        </div>
      </div>

      <div className="divider-box py-[16px] px-[24px]">
        <div className="mb-[8px]">
          <Shimmer customClass="rounded-lg" width="70px" height="16px" />
        </div>
        <div>
          <Shimmer customClass="rounded-lg" width="100%" height="64px" />
        </div>
      </div>

      <div className="divider-box flex items-center justify-between py-[16px] px-[16px]">
        <Shimmer customClass="rounded-lg" width="100px" height="20px" />
        <Shimmer customClass="rounded-lg" width="100px" height="20px" />
      </div>
      <div className="flex items-center justify-between py-[16px] px-[16px]">
        <Shimmer customClass="rounded-lg" width="100px" height="20px" />
        <Shimmer customClass="rounded-lg" width="100px" height="20px" />
      </div>
    </div>
  );
};

export default SkeletonCardRatingTemplate;

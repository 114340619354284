import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getListPosition } from 'client/admin/UserManagementClient';
import { useUser } from 'context/UserContext';
import useUserManagement from 'hooks/useUserManagement';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import ContentNavigator from 'components/shared/ContentNavigator';
import LoadingComponent from 'components/shared/LoadingComponent';
import SVGIcon from 'components/shared/SVGIcon';

import PositionCard from './PositionCard';

const PositionDetail = ({
  isReadOnly,
  setToastData,
  userAddedId,
  endScroll,
  setEndScroll,
  listPosition,
  setListPosition,
  listPositionNavigator,
  setListPositionNavigator,
  activeSection,
  setActiveSection
}) => {
  const { config } = useUser();
  const { userId } = useParams();
  const [onLoadMore, setOnLoadMore] = useState(false);
  const [isNewPosition, setIsNewPosition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [olderThan, setOlderThan] = useState(0);
  const isRefetchList = useUserManagement((state) => state.isRefetchList);
  const setEditedPosition = useUserManagement(
    (state) => state.setEditedPosition
  );
  const isCancelChanges = useUserManagement((state) => state.isCancelChanges);
  const setButtonSave = useUserManagement((state) => state.setButtonSave);
  const refetchList = useUserManagement((state) => state.refetchList);
  const setIsError = useUserManagement((state) => state.setIsError);

  const newPosition = {
    id: 'new',
    startsAt: null,
    endsAt: null,
    state: 'active',
    name: 'New Position',
    info: {
      echelon: null
    },
    directManager: {},
    indirectManager: {},
    configs: {
      formalReview: false
    },
    goalCreationStartsAt: null,
    goalCreationEndsAt: null,
    goalEditStartsAt: null,
    goalEditEndsAt: null,
    goalDeleteStartsAt: null,
    goalDeleteEndsAt: null,
    taskCreationStartsAt: null,
    taskCreationEndsAt: null,
    taskEditStartsAt: null,
    taskEditEndsAt: null,
    taskDeleteStartsAt: null,
    taskDeleteEndsAt: null,
    groups: {
      clusterGroup: [{ id: 0, name: 'Default' }],
      permissionGroup: [{ id: 0, name: 'Default' }],
      reviewGroup: [{ id: 0, name: 'Default' }],
      behaviorGroup: [{ id: 0, name: 'Default' }],
      ratingTemplateGroup: [{ id: 0, name: 'Default' }]
    }
  };

  const getPositionList = async (isRefetch) => {
    isRefetch && setIsLoading(true);
    const params = {
      limit: 10,
      olderThan: isRefetch ? 0 : olderThan
    };
    const { data, pagination } = await getListPosition(
      userId || userAddedId,
      params
    );
    if (data) {
      const allData = isRefetch ? data : [...listPosition, ...data];
      setListPosition(allData);
      olderThan !== pagination?.next?.olderThan
        ? setOlderThan(pagination?.next?.olderThan)
        : null;

      const nav = allData.map((pos, index) => {
        return { id: `position-${index}`, name: pos?.name };
      });
      setListPositionNavigator(nav);
    } else {
      setToastData({
        isShow: true,
        message: 'Failed get position data',
        isWarning: true
      });
    }
    isRefetch && setIsLoading(false);
  };

  const scrollToElement = (scrollToTop) => {
    let elmnt = document.getElementById(activeSection);
    const content = document.getElementById('scrollable-content');
    content?.scroll({
      top: scrollToTop ? 0 : elmnt?.offsetTop,
      behavior: 'smooth'
    });
  };

  const navigationScroll = () => {
    let elmt = document.getElementById('nav-' + activeSection);
    const content = document.getElementById('nav-scroll');
    content?.scroll({
      top: elmt?.offsetTop,
      behavior: 'smooth'
    });
  };

  const pagination = () => {
    const loadMore = async () => {
      setOnLoadMore(true);
      await getPositionList(false);
      setOnLoadMore(false);
    };

    const hasMore = !isLoading && olderThan ? true : false;
    if (!onLoadMore && hasMore) {
      loadMore();
    }
  };

  useEffect(() => {
    navigationScroll();
  }, [activeSection]);

  useEffect(() => {
    if (endScroll) {
      pagination();
    }
    setEndScroll(false);
  }, [endScroll]);

  useEffect(() => {
    setOlderThan(0);
    getPositionList(true);
    setIsNewPosition(false);
    refetchList(false);
    setActiveSection('position-0');
  }, []);

  useEffect(() => {
    if (isRefetchList) {
      setOlderThan(0);
      getPositionList(true);
      setIsNewPosition(false);
      refetchList(false);
    }
  }, [isRefetchList]);

  useEffect(() => {
    scrollToElement(isNewPosition);
    setIsNewPosition(false);
    setIsError(false);
  }, [isCancelChanges]);

  return (
    <div
      className="flex overflow-auto relative justify-end"
      data-cy="position-detail"
    >
      <div className="w-[768px] mr-[270px]">
        {config?.editableNewPosition && (
          <Button.Secondary
            onClick={() => {
              setIsNewPosition(true),
                setEditedPosition(newPosition),
                setButtonSave(true);
            }}
            customClass="mr-[8px] mb-[16px]"
            datacy="add-new-position"
          >
            <SVGIcon
              iconName="icon-add"
              fillColor="var(--base-600)"
              size="16"
              customClass="mr-[8px]"
            />
            {getObjectiveLocale('Add New Position')}
          </Button.Secondary>
        )}
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            {isNewPosition && (
              <PositionCard
                position={newPosition}
                index={999}
                userId={userId || userAddedId}
                isNewPosition={true}
                isReadOnly={isReadOnly}
              />
            )}
            {listPosition.length > 0 &&
              listPosition.map((position, index) => (
                <PositionCard
                  position={position}
                  index={index}
                  userId={userId || userAddedId}
                  isReadOnly={isReadOnly}
                  key={index}
                />
              ))}
          </>
        )}
      </div>
      {/* NAVIGATOR */}
      {isLoading ? (
        <LoadingComponent hasText={false} className="w-[232px]" />
      ) : (
        <div
          className="fixed w-[232px] h-[472px] overflow-auto"
          id="nav-scroll"
          onScroll={(e) => e.stopPropagation()}
        >
          <ContentNavigator
            title="CONTENTS"
            activeSection={activeSection}
            listNavigatorContents={listPositionNavigator}
            sectionContent="scrollable-content"
            additionalOffset={-75}
            useId={true}
          />
        </div>
      )}
    </div>
  );
};

export default PositionDetail;

import React from 'react';
import { useParams } from 'react-router-dom';

import BreakdownSurvey from './BreakdownSurvey';
import ChartSurvey from './ChartSurvey';

const SurveyAnalytics = ({ selectedOccurrence }) => {
  const params = useParams();

  const occurrenceId = selectedOccurrence?.id;

  return (
    <div className="w-full">
      <ChartSurvey surveyId={params?.id} occurrenceId={occurrenceId} />
      <BreakdownSurvey surveyId={params?.id} occurrenceId={occurrenceId} />
    </div>
  );
};

export default SurveyAnalytics;

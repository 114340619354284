import React from 'react';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

const AuthSSO = () => {
  const { organization, appType } = useUser();

  const appName =
    appType === 'culture'
      ? organization?.cultureAppName
      : organization?.appName;

  return (
    <>
      <span className="auth-title text-center w-[304px] typography-h400-longform">
        {appName || getObjectiveLocale('Authentication')}
      </span>
      <span className="auth-text" data-cy="input-login-data">
        {getObjectiveLocale('Choose sign in method')}
      </span>
    </>
  );
};

export default AuthSSO;

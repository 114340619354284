import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';

import startCase from 'lodash/startCase';

import { getTeamDetail } from 'client/ObjectivesClient';

// CREATE CONTEXT
const TeamDetailContext = createContext();

// PROVIDER
const TeamDetailProvider = (props) => {
  const teamId = props.teamId || null;

  const fetchObjective = () => {
    return getTeamDetail(teamId);
  };

  const { data, refetch } = useQuery(
    ['team', +teamId, props?.teamType],
    fetchObjective,
    {
      enabled: teamId !== null
    }
  );

  return (
    <TeamDetailContext.Provider
      value={{ data: data?.data, refetchTeam: refetch, teamId: teamId }}
      {...props}
    />
  );
};

// MUTATION

const useTeamDetail = (shouldNotBeUsed) => {
  if (shouldNotBeUsed) return null;
  const { data, refetchTeam, teamId } = useContext(TeamDetailContext);

  let breadcrumbsData = [];
  const setBreadCrumbsName = (data) =>
    `[${startCase(data?.teamType?.[0])}] ${data?.name}`;
  const setBreadCrumbsLink = (linkData) =>
    linkData?.teamType == data?.teamType
      ? null
      : `/teams/${linkData?.teamType}/${linkData?.id}`;

  const setBreadcrumbsTeam = (bcData = null) => {
    const processedData = bcData || data?.breadcrumbs;
    if (processedData) {
      breadcrumbsData.unshift({
        title: setBreadCrumbsName(processedData),
        link: setBreadCrumbsLink(processedData)
      });

      if (processedData?.parent) setBreadcrumbsTeam(processedData?.parent);
    }
  };

  setBreadcrumbsTeam();

  return {
    data: data,
    involvements: data?.involvements,
    activities: [],
    refetchTeam: refetchTeam,
    teamId: teamId,
    breadcrumbs: breadcrumbsData
  };
};

export { TeamDetailProvider, useTeamDetail };

import React from 'react';

import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import AntdCalendar, {
  useCalendar
} from 'components/shared/Calendar/AntdCalendar';
import SVGIcon from 'components/shared/SVGIcon';
import { getDateWithForcedTimezone } from 'src/utils/DateUtils';

const DualCalendar = ({
  defaultValue,
  handleChangeStartDate,
  handleChangeDueDate,
  permissionStartDate = true,
  permissionDueDate = true,
  disabledDates = [],
  isDisabled = false
}) => {
  const onClickPeriod = (value) => {
    handleChangeStartDate(value[0].format('YYYY-MM-DD'));
    handleChangeDueDate(value[1].format('YYYY-MM-DD'));
  };

  return (
    <div className="flex w-full">
      <CalendarWrapper
        type="startDate"
        defaultValue={defaultValue[0]}
        onClickDate={handleChangeStartDate}
        onClickPeriod={onClickPeriod}
        disabledDates={disabledDates}
        isDisabled={isDisabled || !permissionStartDate}
      />
      <CalendarWrapper
        type="dueDate"
        defaultValue={defaultValue[1]}
        onClickDate={handleChangeDueDate}
        onClickPeriod={onClickPeriod}
        disabledDates={disabledDates}
        isDisabled={isDisabled || !permissionDueDate}
      />
    </div>
  );
};

const CalendarWrapper = ({ ...rest }) => {
  return (
    <div className="relative">
      <AntdCalendar>
        <CalendarContent {...rest} />
      </AntdCalendar>
    </div>
  );
};

const CalendarContent = ({
  type,
  defaultValue,
  onClickDate,
  onClickPeriod,
  disabledDates,
  isDisabled
}) => {
  const { setOpen } = useCalendar();
  const { config } = useUser();
  const { periodOnCreateOptions } = config || {};

  const annual = [
    {
      id: 'annual',
      name: getObjectiveLocale('Annual'),
      value: [dayjs().startOf('year'), dayjs().endOf('year')],
      dataCy: 'annual-calendar-button'
    }
  ];

  const semester = [1, 2].map((val) => ({
    id: `s-${val}`,
    name: `S${val} - ${dayjs().year()}`,
    value: [
      dayjs()
        .month((val - 1) * 6)
        .startOf('month'),
      dayjs()
        .month(val * 6 - 1)
        .endOf('month')
    ],
    dataCy: `s${val}-calendar-button`
  }));

  const quarter = [1, 2, 3, 4].map((val) => ({
    id: `q-${val}`,
    name: `Q${val} - ${dayjs().year()}`,
    value: [
      dayjs().quarter(val).startOf('quarter'),
      dayjs().quarter(val).endOf('quarter')
    ],
    dataCy: `q${val}-calendar-button`
  }));

  const periodOptions = [
    ...(periodOnCreateOptions?.includes('annual') ? annual : []),
    ...(periodOnCreateOptions?.includes('semester') ? semester : []),
    ...(periodOnCreateOptions?.includes('quarter') ? quarter : [])
  ];

  const checkDateArray = (array1, array2) => {
    return (
      dayjs(array1[0]).isSameOrBefore(dayjs(array2[0])) &&
      dayjs(array1[1]).isSameOrAfter(dayjs(array2[1]))
    );
  };

  const LeftSidebarContent = () => {
    return (
      <div className="min-w-[145px]">
        {periodOptions.map((option, index) => (
          <Button.Secondary
            key={index}
            onClick={() => {
              onClickPeriod(option.value);
              setOpen(false);
            }}
            disabled={
              disabledDates?.length > 0 &&
              checkDateArray(disabledDates, option.value)
            }
            customClass="!border-0 !rounded-0 w-full px-[16px]"
            alignText="left"
            datacy={option.dataCy}
          >
            {option.name}
          </Button.Secondary>
        ))}
      </div>
    );
  };

  return (
    <>
      <AntdCalendar.Trigger
        disabled={isDisabled}
        dataCy={`${type}-date-calendar`}
      >
        <div className="flex flex-col hover:bg-base-30024 w-[164px] px-[8px] py-[12px]">
          <p className="typography-h100 text-n-600">
            {getObjectiveLocale(startCase(type))}
          </p>
          <div className="flex justify-between items-center">
            <p title={dayjs(defaultValue).format('YYYY-MM-DD')}>
              {dayjs(defaultValue).format('MMM D, YYYY')}
            </p>
            <SVGIcon
              size={24}
              iconName="icon-calendar_today"
              fillColor="var(--base-600)"
            />
          </div>
        </div>
      </AntdCalendar.Trigger>
      <AntdCalendar.Content
        dateValue={dayjs(getDateWithForcedTimezone(defaultValue))}
        setDateValueHandler={(newDate) => onClickDate(newDate)}
        calendarType={type}
        leftSidebarContent={<LeftSidebarContent />}
        isDual
      />
    </>
  );
};

export default DualCalendar;

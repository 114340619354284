/* eslint-disable complexity */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Scrollbar from 'react-scrollbars-custom';

import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import { getActivities } from 'client/ActivitiesClient';
import {
  getObjectiveDetail,
  getObjectivePhases,
  updatePreferencesFilter,
  updateStateObjective
} from 'client/ObjectivesClient';
import { MetricsProvider, useMetrics } from 'context/MetricsContext';
import {
  ProjectDetailProvider,
  useProjectDetail
} from 'context/ProjectDetailContext';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import useDropdown from 'hooks/useDropdown';
import useEditPhase from 'hooks/useEditPhase';
import useFilter from 'hooks/useFilter';
import useModalObjectives from 'hooks/useModalObjectives';
import useSidebar from 'hooks/useSidebar';
import useTableHeader from 'hooks/useTableHeader';
import useTaskGroup from 'hooks/useTaskGroup';
import useTasks from 'hooks/useTasks';
import { useUrl } from 'hooks/useUrl';
import {
  getDataFilter,
  getDefaultFilterSaved,
  getDefaultHeaderColumnSaved,
  getFilterPayloadBody
} from 'utils/FilterRestructuringHelper';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import { getPeriod } from 'utils/ObjectivesHelper';
import {
  whichBadgeBgColorHex,
  whichBadgeColorHex,
  whichStatus
} from 'utils/ObjectivesHelper';
import { convertGroupNameToLowerCase } from 'utils/TasksHelper';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import ConfirmDeleteModal from 'components/modal/ConfirmDeleteModal';
import ObjectivesModal from 'components/objectives/ObjectivesModal';
import AddSection from 'components/project/detail/AddSection';
import DeleteSectionModal from 'components/project/detail/DeleteSectionModal';
import EditMembersModal from 'components/project/detail/EditMembersModal';
import EditProjectNameModal from 'components/project/detail/EditProjectNameModal';
import ProjectOverview from 'components/project/detail/ProjectOverview';
import FloatingAction from 'components/shared/FloatingAction/FloatingAction';
import ListEmptyState from 'components/shared/ListEmptyState';
import SidebarDetail from 'components/sidebar/SidebarDetail';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import AccordionTask from 'components/tasks/AccordionTask';
import Timeline from 'components/tasks/Timeline/Timeline';
import Boards from 'components/tasks/board/Boards';
import FormattedNumber from 'src/components/design-system/FormattedNumber';
import { useUserPreferences } from 'src/hooks/usePreferenceFilter';
import { useDeepEffect } from 'src/utils/useDeepEffect';

const ProjectGoalsDetailContent = () => {
  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');
  const topSidebar = useSidebar((state) => state?.topSidebar);

  const {
    config: {
      defaultObjectiveSorting,
      filterLastUpdate,
      timeFilterOptions: dateFilterOptions,
      includeTeamGoals,
      defaultTaskView,
      enableTaskMultiParentAlignment
    }
  } = useUser();

  const { data: project, breadcrumbs, team } = useProjectDetail();

  const [draggedId, setDraggedId] = useState(null);
  const [currentTab, setCurrentTab] = useState(
    defaultTaskView || tabParams || 'list'
  );
  const [showModalEditProjectName, setShowModalEditProjectName] =
    useState(false);
  const [showModalEditMember, setShowModalEditMember] = useState(false);
  const [showModalDeleteSection, setShowModalDeleteSection] = useState(false);
  const [showMutipleDelete, setShowMutipleDelete] = useState(false);
  const [isTopTaskOpen, setIsTopTaskOpen] = useState(false);
  const [autoShowPlaceholder, setAutoShowPlaceholder] = useState(false);
  const [parentDetail, setParentDetail] = useState({});
  const [showSidebarParent, setShowSidebarParent] = useState(false);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [filterGroup, setFilterGroup] = useState(group);
  const [lastUpdated, setLastUpdated] = useState({
    id: 0,
    name: '',
    time: null
  });
  const [tableHeaders, setTableHeaders] = useState([]);
  const [isGroupDataReady, setIsGroupDataReady] = useState(false);
  const { listMetrics } = useMetrics() || {};

  const tabsList = [
    { id: 'overview', name: 'Overview' },
    { id: 'list', name: 'List' },
    { id: 'board', name: 'Board' },
    { id: 'timeline', name: 'Timeline' }
  ];

  const defaultTabIndex = tabsList.findIndex(
    (tab) => tab.id === defaultTaskView
  );

  const history = useHistory();
  const params = useParams();
  const projectId = params.parentId;

  const {
    resetListGroups,
    listGroups,
    selectedTasks,
    removeAllSelected,
    updateGroupIndexHash,
    updateSectionIndexHash,
    addNewTaskTwoLevels,
    setAction,
    setListGroups,
    tasks
  } = useTasks();

  const { url, match } = useUrl();
  const { headers, setHeaders } = useTableHeader();
  const {
    refetchObjective,
    refetchSubObjectives,
    refetchObjectives,
    refetchQueries,
    invalidateTaskQueries
  } = useRefetchQuery();

  const { setEditedPhaseData, setCurrentPhaseData } = useEditPhase();
  const { showModal } = useModalObjectives();

  const additionalParams = team && !includeTeamGoals ? { goals: 'team' } : {};

  const filterId = `projectDetail${startCase(currentTab)}`;

  const filter = useFilter((state) => state.filterById?.[filterId]);

  const { group } = filter || {};
  // TEMPORARY COMMENT - might be used again in next story
  // const currentGroup = ((filter?.priority?.length > 0 || filter?.phase?.length > 0) && filter?.[group]) ? filter?.[group] : listGroups?.[group] || [];
  // const currentGroup =
  //   ((filter?.priority?.length > 0 || filter?.phase?.length > 0) &&
  //   filter?.[group])
  //     ? filter?.[group]
  //     : listGroups?.[group] || [];

  const currentGroup =
    (filter?.priority?.length > 0 ||
      filter?.phase?.length > 0 ||
      filter?.section?.length > 0) &&
    filter?.[filterGroup]
      ? filter?.[filterGroup]
      : listGroups?.[filterGroup] || [];

  const { useMenu } = useDropdown({
    objective: project,
    permissions: project?.permissions,
    page: 'myprojects',
    isInNewProjectPage: true,
    isProjectDetailPage: true
  });
  const listMenu = useMenu({ except: ['addGoal', 'addTask'] });

  const onClickDropdown = (menu) => {
    if (menu.type == 'editMemberProject') {
      menu.onClick(menu, () => {
        setCurrentTab('overview');
      });
    } else {
      menu.onClick(menu);
    }
  };

  const modifiedListMenu = listMenu.map((menu) => {
    return {
      text: menu.title,
      iconName: menu.icon,
      onClick: () => onClickDropdown(menu),
      textColor: menu.textColor,
      iconColor: menu.colorHex,
      type: menu.type,
      dataCy: menu.dataCy
    };
  });

  const [defaultFilter, setDefaultFilter] = useState({});

  const filterPayload = useRef([]);

  const filterMenu = [
    { option: 'phase', title: 'Phase', dialog: 'task-phase' },
    { option: 'priority', title: 'Priority', dialog: 'task-priority' },
    {
      option: 'overdue',
      title: 'Overdue',
      subOption: [{ option: 'overdue', title: 'Overdue Only' }]
    },
    {
      option: 'lastUpdated',
      title: 'Last Updated',
      subOption: filterLastUpdate?.map((option) => ({
        option: 'lastUpdated.' + option,
        title: startCase(option),
        calendar: option == 'newer_than' || option == 'older_than'
      }))
    },
    ...(currentTab == 'timeline'
      ? [
          {
            option: 'assignTo',
            title: 'Assign To',
            subOption: [
              { option: 'me', title: 'Me' },
              { option: 'everyone', title: 'Everyone' }
            ]
          }
        ]
      : [])
  ];

  const {
    data: preferencesFilterData = null,
    isFetching: isFetchingPreferences
  } = useUserPreferences(['preferences', 'projectDetail', currentTab], null, {
    suspense: false
  });

  const { data: listPhasesData = null } = useTaskGroup(
    'phase',
    null,
    'projectDetail',
    { projectId },
    {
      suspense: false,
      staleTime: 5 * 60 * 1000,
      enabled: !!preferencesFilterData && !isFetchingPreferences
    }
  );

  const { data: listSectionsData = null } = useTaskGroup(
    'section',
    null,
    'projectDetail',
    { projectId },
    {
      suspense: false,
      staleTime: 5 * 60 * 1000,
      enabled: !!preferencesFilterData && !isFetchingPreferences
    }
  );

  const { data: listPrioritiesData = null } = useTaskGroup(
    'priority',
    null,
    'projectDetail',
    null,
    {
      suspense: false,
      staleTime: 5 * 60 * 1000,
      enabled: !!preferencesFilterData && !isFetchingPreferences
    }
  );

  useEffect(() => {
    if (
      preferencesFilterData &&
      listPhasesData &&
      listPrioritiesData &&
      listSectionsData
    ) {
      const dataFilter = getDataFilter(
        preferencesFilterData?.data,
        currentTab,
        'project_management',
        'project.details'
      );

      const convertedPhases = convertGroupNameToLowerCase(listPhasesData?.data);
      const convertedPriorities = convertGroupNameToLowerCase(
        listPrioritiesData?.data
      );
      const convertedSections = convertGroupNameToLowerCase(
        listSectionsData?.data
      );
      const periodFilter = getPeriod(dateFilterOptions?.[0]);
      let defaultFilter = getDefaultFilterSaved({
        dataFilter,
        phases: convertedPhases,
        priorities: convertedPriorities,
        defaultObjectiveSorting,
        periodFilter,
        isProject: true
      });

      if (!isEmpty(defaultFilter)) {
        setDefaultFilter(defaultFilter);
        getHeaders(dataFilter);
      }

      if (convertedPhases && convertedPriorities && convertedSections) {
        setListGroups({
          phase: convertedPhases,
          priority: convertedPriorities,
          section: convertedSections
        });
        setIsGroupDataReady(true);
      }
    }
    //eslint-disable-next-line
  }, [
    preferencesFilterData,
    listPhasesData,
    listPrioritiesData,
    listSectionsData
  ]);

  const getPhases = async () => {
    let params = {};
    if (projectId) {
      params.projectId = projectId;
    }
    const { data } = await getObjectivePhases(params);
    return data ? data : [];
  };

  const onClickRename = async () => {
    const listPhases = await getPhases();
    showModal({ modalType: 'editPhases', props: { projectId, type: 'list' } });
    setEditedPhaseData(listPhases);
    setCurrentPhaseData(listPhases);
  };

  const _setHeaders = () => {
    const setHeadersByGroup = (section, groupBy) => {
      listGroups?.[groupBy]?.map((group) => {
        const headerId = `project-${groupBy}-${section?.id}-${group?.id}`;
        if (!headers?.[headerId]) {
          let tempHeaders = cloneDeep(tableHeaders);

          // need to update header index number 1
          // because it's header for the task name
          // and we need to replace the header name with the task count
          // and add badge component to show the phase/priority group table
          // and it's only implemented for task in project detail page
          tempHeaders[1].isAlwaysVisible = true;
          tempHeaders[1].name = '0 task(s)';
          tempHeaders[1].badge = {
            content: group?.name.toUpperCase(),
            colorHex: group?.colorHex,
            bgColorHex: group?.bgColorHex
          };
          if (groupBy === 'phase') {
            tempHeaders[1].options = [
              { text: 'Edit Phase', onClick: () => onClickRename() }
            ];
          }

          setHeaders(headerId, tempHeaders);
        }
      });
    };

    listGroups?.section?.map((section) => {
      setHeadersByGroup(section, 'phase');
      setHeadersByGroup(section, 'priority');
    });
  };

  const updateHeaders = () => {
    const updateHeadersByGroup = (section, groupBy) => {
      listGroups?.[groupBy]?.map((group) => {
        const headerId = `project-${groupBy}-${section?.id}-${group?.id}`;
        if (headers?.[headerId]) {
          let firstHeader = cloneDeep(headers?.[headerId][1]);
          let tempHeaders = cloneDeep(headers?.projectManagementTable);
          tempHeaders[1] = firstHeader;
          setHeaders(headerId, tempHeaders);
        }
      });
    };

    listGroups?.section?.map((section) => {
      updateHeadersByGroup(section, 'phase');
      updateHeadersByGroup(section, 'priority');
    });
  };

  const getHeaders = (dataFilter) => {
    const headerColumnName = [
      'arrow-icon',
      'Task Name',
      'Assignee',
      'Period',
      'Priority',
      'Phase',
      'Progress',
      'Metrics',
      'Complexity',
      'Alignment Goal',
      'Dependency',
      'Last Comment',
      'Created by',
      'Last Updated',
      ''
    ];

    const defaultHeaderColumn = [
      'Task Name',
      'Assignee',
      'Period',
      'Priority',
      'Phase',
      'Progress',
      'Metrics'
    ];

    const headerColumn = getDefaultHeaderColumnSaved({
      headerColumnName,
      filterColumnName: dataFilter?.taskColumn || defaultHeaderColumn,
      enableTaskMultiParentAlignment,
      isProject: true
    });

    if (!headers?.projectManagementTable) {
      setHeaders('projectManagementTable', headerColumn);
    }

    if (tableHeaders?.length === 0) {
      setTableHeaders(headerColumn);
    }
  };

  const getFilterPayload = useCallback(() => {
    if (currentTab === 'overview') return;

    const payloadBody = getFilterPayloadBody({
      module: 'project_management',
      page: 'project.details',
      section: currentTab,
      filter: filter,
      headerTable: headers?.projectManagementTable,
      isProject: true
    });

    filterPayload.current = cloneDeep(payloadBody);
  }, [filter, headers?.projectManagementTable, currentTab]);

  const updateFilterSaved = async () => {
    if (!isEmpty(filterPayload?.current)) {
      if (
        filterPayload?.current?.section !== 'overview' &&
        filterPayload?.current?.section !== 'timeline' // will be deleted when this section is available from backend /preference
      ) {
        await updatePreferencesFilter(filterPayload.current);
      }
    }
  };

  const resultModifier = (filter) => {
    const filterPhase = filter?.phase?.map((e) => e?.name).filter((e) => e);
    const filterPriority = filter?.priority
      ?.map((e) => e?.name)
      .filter((e) => e);

    const result = {
      assignTo: filter?.assignTo,
      phase: filterPhase,
      priority: filterPriority,
      alignment:
        filter?.parentAlignment?.length > 0
          ? filter?.parentAlignment
              ?.map(({ objective }) => objective?.name)
              ?.join(', ')
          : [],
      overdue: filter?.overdue,
      lastUpdated: [filter?.lastUpdated?.lastUpdatedType || []]
    };

    return result;
  };

  const handleAddTask = () => {
    if (currentTab === 'list' && isTopTaskOpen) {
      addNewTaskTwoLevels(
        listGroups?.section[0]?.name,
        currentGroup[0]?.name,
        'top'
      );
    } else if (currentTab === 'board') {
      addNewTaskTwoLevels(
        currentGroup[0]?.name,
        listGroups?.section[0]?.name,
        'top'
      );
    } else {
      setIsTopTaskOpen(true);
      setAutoShowPlaceholder(true);
    }

    setAction('create');
  };

  const deleteObjective = async (deleteChildren) => {
    let body = { state: 'deleted', top_parent: null, deleteChildren };

    selectedTasks.forEach(async (task) => {
      await updateStateObjective(task.id, body);
      await refetchObjective(task?.parent?.id);

      if (task?.parent?.id) {
        await refetchSubObjectives(task?.parent?.id);
      } else {
        history.replace({
          pathname: url.includes('users')
            ? url.replace(/\/objectives/, '')
            : url.includes('direct')
            ? '/goals/reports/direct'
            : `/projects/${projectId}`,
          search: location.search
        });
      }

      if (task?.groupName && task?.section?.name) {
        refetchQueries([
          'objectives',
          'mytasks',
          task?.groupName,
          task?.section?.name.toLowerCase()
        ]);
      } else {
        refetchObjectives('mytasks');
      }
      invalidateTaskQueries({ task });
    });
    removeAllSelected();
    setShowMutipleDelete(!showMutipleDelete);
  };

  const onClickParent = async () => {
    const { data } = await getObjectiveDetail(project?.parent?.id);
    if (data) {
      setParentDetail(data);
      setShowSidebarParent(true);
    }
  };

  const fetchLastActivity = async () => {
    const params = {
      limit: 1,
      isAll: true
    };
    const { data } = await getActivities(projectId, params);
    if (data) {
      setLastUpdated({
        id: data[0]?.id,
        name: data[0]?.user?.name,
        time: data[0]?.doneAt
      });
    }
  };

  useEffect(() => {
    fetchLastActivity();

    return () => {
      resetListGroups();
    };
  }, []);

  useEffect(() => {
    if (
      tableHeaders?.length > 0 &&
      listGroups?.section?.length > 0 &&
      currentGroup?.length > 0
    ) {
      _setHeaders();
    }
  }, [tableHeaders, listGroups?.section, currentGroup, currentTab]);

  useDeepEffect(() => {
    headers?.projectManagementTable && updateHeaders();
  }, [headers]);

  useEffect(() => {
    let hash = {};
    if (currentGroup?.length > 0) {
      currentGroup?.forEach((group, index) => (hash[index] = group.name));
    } else {
      hash[-1] = 'Task';
    }

    removeAllSelected();
    updateGroupIndexHash(hash);
    setFilterGroup(group);
    // eslint-disable-next-line
  }, [group, currentGroup?.length]);

  useEffect(() => {
    getFilterPayload();
  }, [getFilterPayload]);

  useEffect(() => {
    window.addEventListener('beforeunload', updateFilterSaved, {
      capture: true
    });
    return () => {
      updateFilterSaved();
      window.removeEventListener('beforeunload', updateFilterSaved, {
        capture: true
      });
    };
    // eslint-disable-next-line
  }, [currentTab]);

  useEffect(() => {
    let hash = {};
    if (listGroups?.section?.length > 0) {
      listGroups?.section?.forEach(
        (section, index) => (hash[index] = section.name)
      );
    }
    updateSectionIndexHash(hash);
  }, [listGroups, updateSectionIndexHash]);

  const parentColorHex =
    project?.parent?.measurement?.progressColorHex || '44DB5E';
  const projectColorHex = project?.measurement?.progressColorHex || '44DB5E';
  const parentProgress = project?.parent?.measurement?.progressPercentage;
  const projectProgress = project?.measurement?.progressPercentage;

  return (
    <div className="flex flex-col h-full">
      <HeaderPage
        titlePage={project?.name}
        preTitle={getObjectiveLocale('[P]')}
        primaryAction={
          ((currentTab === 'list' &&
            tasks?.[listGroups?.section?.[0]?.name]?.[
              currentGroup?.[0]?.name
            ]) ||
            (currentTab === 'board' &&
              tasks?.[currentGroup?.[0]?.name]?.[
                listGroups?.section?.[0]?.name
              ])) &&
          project?.permissions?.includes('add_subtask') && {
            title: getObjectiveLocale('Add Task'),
            icon: 'icon-add',
            onClick: () => handleAddTask()
          }
        }
        secondaryAction={{
          title: getObjectiveLocale('View Tree'),
          icon: 'icon-device_hub',
          onClick: (e) => {
            e.stopPropagation();
            window.open(`/tree/${project?.id}`, '_blank');
          }
        }}
        otherAction={{ dropdown: modifiedListMenu }}
        tabs={{
          tabsList: tabsList,
          defaultIndex: defaultTabIndex,
          url: `/projects/${projectId}`,
          onChange: (tab) => {
            setCurrentTab(tab);
            setShowSidebarParent(false);
          }
        }}
        titleOnHover={{
          showIcon: true,
          iconName: 'icon-pencil',
          onClickIcon: () => setShowModalEditProjectName(true)
        }}
        bottomTitle={
          project?.parent && {
            title: 'Align to',
            text: project?.parent?.name,
            clickable: true,
            badge: project?.parent?.measurement && {
              colorHex: whichBadgeColorHex[parentColorHex],
              bgColorHex: whichBadgeBgColorHex[parentColorHex],
              text: (
                <>
                  <FormattedNumber
                    number={parentProgress}
                    minDecimalLength={2}
                  />
                  {`% ${whichStatus[parentColorHex]}`}
                </>
              )
            },
            onClick: () => onClickParent()
          }
        }
        badgeTitle={
          project?.measurement && {
            colorHex: whichBadgeColorHex[projectColorHex],
            bgColorHex: whichBadgeBgColorHex[projectColorHex],
            text: (
              <>
                <FormattedNumber
                  number={projectProgress}
                  minDecimalLength={2}
                />
                {`% ${whichStatus[projectColorHex]}`}
              </>
            )
          }
        }
        lastUpdated={lastUpdated?.time}
        lastUpdatedBy={lastUpdated?.name}
        breadcrumbsData={
          project?.teamBreadcrumbs
            ? [
                ...breadcrumbs,
                {
                  title: '[P] ' + project?.name,
                  link: null
                }
              ]
            : compact([
                {
                  title: getObjectiveLocale('My Project'),
                  link: '/projects'
                },
                project?.name && {
                  title: '[P] ' + project?.name,
                  link: null
                }
              ])
        }
      />

      {currentTab === 'overview' && (
        <ProjectOverview
          projectId={projectId}
          lastUpdated={lastUpdated}
          setLastUpdated={setLastUpdated}
        />
      )}

      {(currentTab === 'list' ||
        currentTab === 'board' ||
        currentTab === 'timeline') && (
        <div className="my-[24px] px-[40px]">
          <AdvancedFilter
            id={filterId}
            type={`project-${currentTab}`}
            filterOptions={[
              'menuFilter',
              'search',
              'group',
              'sort',
              ...(currentTab != 'timeline' ? ['assignTo'] : ['timeGroup'])
            ]}
            filterMenu={filterMenu}
            defaultFilter={{ ...defaultFilter, ...additionalParams }}
            useFilterShowTask
            useFilterGroup
            defaultGroup={
              defaultFilter?.group == 'no-group'
                ? 'phase'
                : defaultFilter?.group || 'phase'
            }
            resultModifier={resultModifier}
          />
        </div>
      )}

      {currentTab === 'list' && (
        <div className="flex flex-col grow ml-[40px] container-task-table">
          {!isGroupDataReady ? (
            <ListEmptyState
              containerClassname="h-auto mt-[60px]"
              emptyIcon="icon-loading-page"
              withLoadingIndicator
              title={getObjectiveLocale(
                `${filter?.q ? 'Searching' : 'Loading'} your tasks..`
              )}
              subtitle={getObjectiveLocale(
                `${
                  filter?.q ? 'Your search result ' : 'Your tasks list'
                } will be shown up here`
              )}
            />
          ) : (
            <Scrollbar
              scrollerProps={{
                renderer: (props) => {
                  const { elementRef, ...restProps } = props;
                  return (
                    <span
                      {...restProps}
                      id="my-task-section"
                      ref={elementRef}
                    />
                  );
                }
              }}
            >
              {listGroups?.section?.length > 0 &&
                listGroups?.section?.map(({ name, id }, index) => (
                  <AccordionTask
                    key={id}
                    sectionData={{
                      id: id,
                      name: name?.toLowerCase(),
                      index: index,
                      isLast: index === listGroups?.section?.length - 1
                    }}
                    filter={filter}
                    group={group}
                    draggedId={draggedId}
                    setDraggedId={setDraggedId}
                    currentGroup={currentGroup}
                    autoShowPlaceholder={autoShowPlaceholder}
                    setAutoShowPlaceholder={setAutoShowPlaceholder}
                    isTopTaskOpen={isTopTaskOpen}
                    setIsTopTaskOpen={setIsTopTaskOpen}
                    isProject={true}
                    projectId={projectId}
                    setCurrentSectionIndex={setCurrentSectionIndex}
                    setShowModalDeleteSection={setShowModalDeleteSection}
                    listMetrics={listMetrics}
                  />
                ))}
              <AddSection.Button projectId={projectId} />
            </Scrollbar>
          )}
        </div>
      )}

      {currentTab === 'board' && (
        <>
          {!isGroupDataReady ? (
            <ListEmptyState
              containerClassname="h-auto mt-[60px]"
              emptyIcon="icon-loading-page"
              withLoadingIndicator
              title={getObjectiveLocale(
                `${filter?.q ? 'Searching' : 'Loading'} your tasks..`
              )}
              subtitle={getObjectiveLocale(
                `${
                  filter?.q ? 'Your search result ' : 'Your tasks list'
                } will be shown up here`
              )}
            />
          ) : (
            <Scrollbar
              scrollerProps={{
                renderer: (props) => {
                  const { elementRef, ...restProps } = props;
                  return (
                    <span
                      {...restProps}
                      id="my-task-section"
                      ref={elementRef}
                    />
                  );
                }
              }}
            >
              <Boards
                filter={filter}
                filterGroup={filterGroup}
                currentGroup={currentGroup}
                sections={listGroups?.section}
                draggedId={draggedId}
                setDraggedId={setDraggedId}
                isProject={true}
                projectId={projectId}
                currentSectionIndex={currentSectionIndex}
                setCurrentSectionIndex={setCurrentSectionIndex}
                setShowModalDeleteSection={setShowModalDeleteSection}
                listMetrics={listMetrics}
              />
            </Scrollbar>
          )}
        </>
      )}

      {currentTab === 'timeline' && (
        <>
          {!isGroupDataReady ? (
            <ListEmptyState
              containerClassname="h-auto mt-[60px]"
              emptyIcon="icon-loading-page"
              withLoadingIndicator
              title={getObjectiveLocale(
                `${filter?.q ? 'Searching' : 'Loading'} your tasks..`
              )}
              subtitle={getObjectiveLocale(
                `${
                  filter?.q ? 'Your search result ' : 'Your tasks list'
                } will be shown up here`
              )}
            />
          ) : (
            <Timeline
              currentSection={currentGroup}
              groupBy={group}
              isProject={true}
              setCurrentSectionIndex={setCurrentSectionIndex}
              setShowModalDeleteSection={setShowModalDeleteSection}
              sections={listGroups?.section}
              listMetrics={listMetrics}
            />
          )}
        </>
      )}

      <ObjectivesModal />

      {showModalEditProjectName && (
        <EditProjectNameModal
          setShowModal={setShowModalEditProjectName}
          objective={project}
        />
      )}

      {showModalEditMember && (
        <EditMembersModal setShowModal={setShowModalEditMember} />
      )}

      {showModalDeleteSection && (
        <DeleteSectionModal
          projectId={projectId}
          currentSectionIndex={currentSectionIndex}
          setShowModal={setShowModalDeleteSection}
        />
      )}

      {showMutipleDelete && (
        <ConfirmDeleteModal
          modalType="mutipleDelete"
          closeModal={() => setShowMutipleDelete(!showMutipleDelete)}
          submitModal={(deleteChildren) => deleteObjective(deleteChildren)}
          type="task"
        />
      )}

      {selectedTasks.length > 0 && (
        <FloatingAction
          text={getObjectiveLocale(`[selected_tasks] tasks selected`).replace(
            /\[selected_tasks]/g,
            getNumberLocale(selectedTasks.length)
          )}
          deleteAction={{
            onClick: () => setShowMutipleDelete(!showMutipleDelete),
            text: getObjectiveLocale('Delete objectives')
          }}
          cancelAction={{
            onClick: () => removeAllSelected(),
            text: getObjectiveLocale('Cancel selection')
          }}
        />
      )}

      {match && <SidebarMyGoals customClassname="sidebar-compact" />}

      {showSidebarParent && (
        <div
          className="sidebar-my-goals sidebar-compact !w-[640px] !h-[calc(100vh-106px)] !top-[106px] z-[1001]"
          style={{
            top: `${topSidebar || 0}px`,
            height: `calc(100vh - ${topSidebar || 0}px)`
          }}
        >
          <SidebarDetail
            singleObjectiveDetail={parentDetail}
            header={{
              isEditable: false,
              onClickClose: () => setShowSidebarParent(false)
            }}
          />
        </div>
      )}
    </div>
  );
};

const ProjectGoalsDetail = ({
  match: {
    params: { parentId }
  }
}) => {
  return (
    <ProjectDetailProvider parentId={parentId}>
      <MetricsProvider type="task" showLoading={false}>
        <ProjectGoalsDetailContent />
      </MetricsProvider>
    </ProjectDetailProvider>
  );
};

export default ProjectGoalsDetail;

import React, { useEffect, useState } from 'react';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';

const listRepetition = [
  {
    index: 0,
    value: 1
  }
];

function DropdownCustomEvery({
  handleChange,
  defaultValue = listRepetition[0].value,
  disabled,
  triggerCustomClass = 'w-[120px]',
  itemsCustomClass = 'w-[120px]',
  customTextClass
}) {
  const [selectedValue, setSelectedValue] = useState('');

  const handleChangeDropdown = (repetition) => {
    setSelectedValue(repetition.value);
    handleChange(repetition.value);
  };

  useEffect(() => {
    for (var i = 2; i < 31; i++) {
      listRepetition.length < 30 &&
        listRepetition.push({
          index: i - 1,
          value: i
        });
    }
    const value = listRepetition.find(
      ({ value }) => value === defaultValue
    ).value;
    setSelectedValue(value);
  }, []);

  return (
    <Dropdown>
      <Dropdown.Trigger disabled={disabled}>
        <Trigger
          icon={false}
          topLabel={false}
          label={false}
          text={selectedValue}
          customWrapperClass={triggerCustomClass}
          disabled={disabled}
          customTextClass={customTextClass}
        />
      </Dropdown.Trigger>
      <Dropdown.MenuItems maxHeight={150} customClass={itemsCustomClass}>
        {listRepetition.map((repetition) => (
          <Dropdown.MenuItem
            key={repetition.index}
            onClick={() => handleChangeDropdown(repetition)}
          >
            {repetition.value}
          </Dropdown.MenuItem>
        ))}
      </Dropdown.MenuItems>
    </Dropdown>
  );
}

export default DropdownCustomEvery;

import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import ModalAlignParent from 'components/modal/ModalAlignParent';
import SVGIcon from 'components/shared/SVGIcon';
import ContentWrapper from 'components/sidebar/sidebar-create/ContentWrapper';
import useModalConfirmAlignTask from 'src/hooks/useModalConfirmAlignTask';

function InlineDialogParentSuggestion({
  objectiveValue,
  assigneeId,
  customText = getObjectiveLocale('Align to Parent'),
  permissions,
  setObjectiveValue,
  validateBeforeTrigger,
  isEditAlignment = false,
  useTaskParentSuggestion = false,
  updateObjective,
  useMultipleParentAlignment = true
}) {
  const {
    parents: defaultMultipleValue,
    parentIds: defaultParentIds,
    type: objectiveType
  } = objectiveValue;

  const {
    showModalValidationChangeGoalAlignment,
    checkValidationChangeGoalAlignment
  } = useValidationMinMax();
  const { checkAlignmentTask } = useModalConfirmAlignTask();

  const [openModal, setOpenModal] = useState(false);
  const { config } = useUser();
  const { params } = useRouteMatch();

  const {
    taskAlignmentEdit: taskAlignParent,
    projectAlignmentEdit: projectAlignParent,
    goalAlignmentEdit: alignParent
  } = config.permissions;

  const alignParentPermission =
    params?.type === 'edit' || isEditAlignment
      ? permissions?.includes('align_parent')
      : params?.type?.includes('task')
      ? taskAlignParent
      : objectiveValue?.isProject
      ? projectAlignParent
      : alignParent;

  let parentName =
    defaultMultipleValue?.length > 0
      ? defaultMultipleValue
      : alignParentPermission
      ? customText
      : 'No parent';

  const beforeTrigger = async (openDialog, e) => {
    setOpenModal(false);
    if (validateBeforeTrigger) {
      validateBeforeTrigger(openDialog, e);
      return;
    }

    if (objectiveType == 'task') {
      setOpenModal(true);
    } else {
      const { isSettingMinObjectivesOn } =
        await checkValidationChangeGoalAlignment({
          nextAction: () => setOpenModal(true)
        });
      if (!isSettingMinObjectivesOn) setOpenModal(true);
    }
  };

  const onChangeParents = (tempParentIds, tempParents) => {
    setObjectiveValue((draft) => {
      draft.parentIds = tempParentIds;
      draft.parents = tempParents;
    });
    updateObjective && updateObjective({ parentIds: tempParentIds || null });
    setOpenModal(false);
  };

  const deleteParent = (data) => {
    const changedParents = defaultMultipleValue.filter(
      (parent) => parent?.id != data?.id
    );
    const changedParentIds = defaultParentIds.filter(
      (parentId) => parentId != data?.id
    );
    setObjectiveValue((draft) => {
      draft.parentIds = changedParentIds;
      draft.parents = changedParents;
    });
  };

  const onClickDelete = (data) => {
    if (objectiveType == 'task' && params?.type == 'edit') {
      const isModalConfirmAlignTaskShow = checkAlignmentTask({
        objectiveValue,
        newParent: null,
        nextAction: () => deleteParent(data)
      });

      if (isModalConfirmAlignTaskShow) {
        return;
      }
    }

    deleteParent(data);
  };

  return (
    <>
      {objectiveType !== 'task' && showModalValidationChangeGoalAlignment}
      <ContentWrapper
        iconName="icon-arrow_upward"
        customWrapperClass="hover:bg-base-3008 px-[24px] py-[12px] !items-start"
        wrapperChildrenClass="!items-start"
      >
        <div
          className="list-of-parents flex-wrap w-full"
          data-cy="inline-parent-suggestion-name-sidebar"
        >
          {alignParentPermission ? (
            parentName !== getObjectiveLocale('Align to Parent') &&
            defaultMultipleValue?.length > 0 ? (
              <>
                {defaultMultipleValue?.map((data, id) => (
                  <div className="flex justify-between w-full" key={id}>
                    <p
                      className={`typography-paragraph mb-[16px] ${
                        alignParentPermission ? 'text-n-900' : 'text-n-600'
                      }`}
                      data-cy="parent-status-inline"
                    >
                      {data?.name}
                    </p>
                    <SVGIcon
                      size="24"
                      customClass="mr-[8px]"
                      iconName="icon-delete"
                      fillColor="var(--n-600)"
                      onClick={() => onClickDelete(data)}
                      dataCy="delete-parent"
                    />
                  </div>
                ))}
                <Button.Secondary
                  onClick={() => beforeTrigger()}
                  customClass="mr-[8px]"
                  datacy="edit-parent-goal"
                >
                  <SVGIcon
                    size="24"
                    customClass="mr-[8px]"
                    iconName="icon-pencil"
                    fillColor="var(--n-600)"
                  />
                  {getObjectiveLocale('Edit Parent Goal')}
                </Button.Secondary>
              </>
            ) : (
              <p
                onClick={() => beforeTrigger()}
                className="cursor-pointer w-full text-n-600 typography-paragraph"
              >
                {getObjectiveLocale('Align to Parent')}
              </p>
            )
          ) : (
            <p className="cursor-not-allowed w-full text-n-600 typography-paragraph">
              {getObjectiveLocale('No Parent')}
            </p>
          )}
        </div>
      </ContentWrapper>
      {openModal && (
        <ModalAlignParent
          objectiveValue={objectiveValue}
          setObjectiveValue={setObjectiveValue}
          useTaskParentSuggestion={useTaskParentSuggestion}
          assigneeId={assigneeId}
          permissions={permissions}
          closeModal={() => setOpenModal(!openModal)}
          handleChangeParent={onChangeParents}
          useMultipleParentAlignment={useMultipleParentAlignment}
        />
      )}
    </>
  );
}

export default InlineDialogParentSuggestion;

import React, { useState } from 'react';

import dayjs from 'dayjs';

import { getExtraInformation } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import ProfileSection from 'components/shared/Profile/ProfileSection';
import SVGIcon from 'components/shared/SVGIcon';
import ProgressBarOverallProgress from 'components/shared/progressBar/ProgressBarOverallProgress';
import { useUser } from 'src/context/UserContext';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

import ModalEditReviewDeadline from './ModalEditReviewDeadline';

const UserDetail = (props) => {
  const {
    assignment,
    isLoading,
    permissions,
    setModalRemoveInfo,
    currentTab,
    assignmentProgress,
    assignmentData,
    isReadOnly,
    getSidebarData,
    getUserCycle
  } = props;
  const {
    config: {
      permissions: { reviewPerformanceCycleSee }
    }
  } = useUser();

  const [showEditReviewDeadline, setShowEditReviewDeadline] = useState(false);

  const options = [
    {
      text: `Edit Review Deadline`,
      iconName: 'icon-create',
      onClick: () => setShowEditReviewDeadline(true),
      isVisible: reviewPerformanceCycleSee
    },
    {
      text: `Remove as ${currentTab} from cycle`,
      textColor: 'text-r-600',
      iconColor: 'var(--r-600)',
      iconName: 'icon-delete',
      onClick: () => {
        setModalRemoveInfo({
          show: true,
          type: 'user',
          data: null
        });
      },
      isVisible: true
    }
  ].filter(({ isVisible }) => isVisible);

  return (
    <>
      {isLoading ? (
        <TopSidebarSkeleton />
      ) : (
        <div className="relative">
          <div className="flex justify-between">
            <ProfileSection
              name={assignment.name}
              desc={getExtraInformation(
                assignment?.title,
                assignment?.subtitle
              )}
              profilePicture={assignment.profilePicture}
              totalOwners={1}
              avatarSize={40}
              nameSize="h500"
            />
            <div>
              {permissions?.reviewPerformanceCycleSee && !isReadOnly && (
                <Dropdown>
                  <Dropdown.Trigger>
                    <SVGIcon
                      iconName="icon-keyboard_control"
                      fillColor="var(--n-600)"
                      size="24"
                      customClass="cursor-pointer"
                    />
                  </Dropdown.Trigger>
                  <Dropdown.MenuItems position="right" options={options} />
                </Dropdown>
              )}
            </div>
          </div>
          <div className="py-[4px] px-[8px] bg-n-300 rounded-[4px] my-[12px]">
            <p className="typography-h200 text-n-800">
              {`${getObjectiveLocale('Review Deadline')}: `}
              <span className="typography-h100 text-n-800">
                {assignment?.assignmentProgress?.endsAt
                  ? dayjs(assignment?.assignmentProgress?.endsAt).format(
                      'MMMM DD, YYYY'
                    )
                  : '-'}
              </span>
            </p>
          </div>
          <div className="flex flex-col pb-[24px] border-bottom-n-400">
            <ProgressBarOverallProgress
              widthProgress={parseInt(assignmentProgress)}
              colorProgress="bg-g-600"
            />
            <div className="flex justify-between mt-[16px]">
              <div className="typography-h200 text-n-800">
                Total review received
              </div>
              <div
                className="typography-h200 text-n-900"
                data-cy="assignment-progress"
              >
                {assignmentData?.done}/{assignmentData?.total}
              </div>
            </div>
          </div>
          {showEditReviewDeadline && (
            <ModalEditReviewDeadline
              onClose={() => setShowEditReviewDeadline(false)}
              assignment={assignment}
              getSidebarData={getSidebarData}
              getUserCycle={getUserCycle}
            />
          )}
        </div>
      )}
    </>
  );
};

const TopSidebarSkeleton = () => {
  return (
    <div>
      <div className="flex flex-[1] mt-[16px]">
        <Shimmer circle width="40px" height="40px" />
        <div className="flex flex-col ml-[16px] flex-[1]">
          <Shimmer customClass="rounded" width="80%" height="20px" />
          <Shimmer customClass="rounded mt-[4px]" width="60%" height="16px" />
        </div>
      </div>
      <div className="w-full mt-[24px]">
        <Shimmer customClass="rounded" width="100%" height="10px" />
        <div className="flex justify-between w-full mt-[8px]">
          <Shimmer customClass="rounded" width="100px" height="16px" />
          <Shimmer customClass="rounded" width="50px" height="16px" />
        </div>
      </div>
      <Shimmer customClass="rounded" width="100%" height="1px" />
    </div>
  );
};

export default UserDetail;

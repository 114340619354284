import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import { ToggleCard } from 'components/admin/organization/OrganizationHelper';
import InputField from 'components/shared/InputField';
import TextArea from 'components/shared/TextArea';

const FormTemplate = ({
  title,
  description,
  staticChildren,
  customClass = '',
  children
}) => {
  return (
    <div className={`w-[720px] ${customClass}`}>
      <p className="typography-h500 text-n-900 mb-[4px]">
        {getObjectiveLocale(title)}
      </p>
      <p className="typography-paragraph text-n-800 mb-[4px]">
        {getObjectiveLocale(description)}
      </p>
      {staticChildren?.type === 'input' && (
        <>
          <p className="typography-h100 text-n-800 mb-[8px] mt-[16px]">
            {getObjectiveLocale(staticChildren?.title)}
          </p>
          <InputField
            dataCy={staticChildren?.dataCy}
            placeholder={staticChildren?.placeholder}
            value={staticChildren?.value}
            disabled={staticChildren?.isReadOnly}
            handleChange={(e) => {
              e.stopPropagation();
              staticChildren?.onChange(e.currentTarget.value);
            }}
          />
        </>
      )}
      {staticChildren?.type === 'textarea' && (
        <>
          <p className="typography-h100 text-n-800 mb-[8px] mt-[16px]">
            {getObjectiveLocale(staticChildren?.title)}
          </p>
          <TextArea
            onHandleChange={(e) => {
              e.stopPropagation();
              staticChildren?.onChange(e.currentTarget.value);
            }}
            textPlaceholder={getObjectiveLocale(staticChildren?.placeholder)}
            value={staticChildren?.value}
            dataCy={staticChildren?.dataCy}
            height={96}
            maxHeight={96}
            isLimitedHeight={false}
            disabled={staticChildren?.isReadOnly}
          />
        </>
      )}
      {staticChildren?.type === 'toggle' && (
        <>
          <p className="typography-h100 text-n-800 mb-[8px] mt-[16px]">
            {getObjectiveLocale(staticChildren?.title)}
          </p>
          <ToggleCard {...staticChildren.input} />
        </>
      )}
      {children}
    </div>
  );
};

export default FormTemplate;

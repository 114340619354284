import React, { useState } from 'react';

import dayjs from 'dayjs';

import { editObjective } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { abbrevNumber, getObjectiveLocale } from 'utils/HelperUtils';
import { whichColor, whichMonth } from 'utils/ObjectivesHelper';
import { CALC_TYPE_NORMAL } from 'utils/const';

import MetricsDropdown from 'components/objectives/MetricsDropdown';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import CalculationTypeIndicator from 'src/components/shared/CalculationTypeIndicator';

const toLocalNumber = (value) => {
  if (!value) return 0;
  return value.toLocaleString(navigator.language, {
    maximumFractionDigits: 13
  });
};

const MetricsObjective = ({
  measurement,
  objectiveId,
  isCreate,
  callback,
  customTrigger,
  permissions,
  calculationType,
  isGoalType,
  milestoneMode,
  currentMilestone,
  milestoneType
}) => {
  const enableEdit = isCreate || permissions?.includes('update');
  const { refetchObjective } = useRefetchQuery();

  const [isModalOpen, setModalOpen] = useState(false);

  const {
    progressColorHex,
    unit,
    currentValue,
    targetValue,
    description,
    startingValue
  } = measurement || [];

  const metricsValue = `${abbrevNumber(currentValue, 2)} / ${abbrevNumber(
    targetValue,
    1
  )}`;

  let metricsTargetValue = abbrevNumber(targetValue, 2);

  if (!calculationType?.includes(CALC_TYPE_NORMAL)) {
    if (metricsValue.length > 16) {
      metricsTargetValue = String(metricsTargetValue).substr(0, 5) + '..';
    }
  } else {
    if (metricsValue.length > 19) {
      metricsTargetValue = String(metricsTargetValue).substr(0, 5) + '..';
    }
  }

  const modalOpenHandler = (e) => {
    if (enableEdit && !isModalOpen) {
      e.stopPropagation();
      setModalOpen(true);
    }
  };

  const saveMetrics = async ({ data }) => {
    let measurement = { ...data };
    const calculationType = data?.calculationType;

    if (
      !isCreate &&
      (measurement?.targetValue != targetValue ||
        measurement?.unitId != measurement?.id ||
        measurement?.startingValue != startingValue)
    ) {
      if (measurement?.unit == '') {
        delete measurement.unit;
        measurement.unitId = null;
      }
      if (measurement?.calculationType) {
        delete measurement.calculationType;
      }
      const { isSuccess } = await editObjective(objectiveId, {
        measurement: { ...measurement },
        ...(calculationType ? { calculationType } : [])
      }); // REQ COMMENT TEMP
      if (isSuccess) {
        refetchObjective(objectiveId);
      }
      return;
    }
    callback && callback(measurement);
  };

  return (
    <div className="flex flex-col relative" data-cy="metrics-inline-edit">
      {customTrigger ? (
        <div onClick={modalOpenHandler}>{customTrigger}</div>
      ) : (
        <div
          className="flex flex-row items-center justify-end cursor-pointer"
          onClick={modalOpenHandler}
          data-cy="metrics-inline-edit"
        >
          <TooltipContainer
            show={description != ''}
            text={getObjectiveLocale(description)}
          >
            <p className="m-unit typography-h400 text-n-600 mr-[4px]">{unit}</p>
          </TooltipContainer>
          <div className="flex flex-row items-center">
            <TooltipContainer
              show={description != ''}
              text={toLocalNumber(isCreate ? startingValue : currentValue)}
            >
              <p className={`typography-h400 ${whichColor[progressColorHex]}`}>
                {abbrevNumber(isCreate ? startingValue : currentValue, 2)}
                &nbsp;
              </p>
            </TooltipContainer>
            <TooltipContainer
              show={description != ''}
              text={toLocalNumber(targetValue)}
              classContainer="flex flex-row items-center"
            >
              <p className="typography-button text-n-600">/&nbsp;</p>
              <p className="typography-button text-n-800">
                {metricsTargetValue}
              </p>
            </TooltipContainer>
          </div>
          {milestoneMode == 'latest_value' && currentMilestone && (
            <p className="typography-button text-n-600 ml-[4px]">{`(${
              milestoneType == 'quarterly'
                ? currentMilestone?.periodName
                : dayjs()
                    .month(whichMonth[currentMilestone?.periodName])
                    .format('MMM')
            })`}</p>
          )}
          <CalculationTypeIndicator
            calculationType={calculationType}
            customClass="ml-[8px]"
          />
        </div>
      )}
      {isModalOpen && (
        <MetricsDropdown
          type="absolute"
          measurement={measurement}
          calculationType={calculationType}
          setShowDialog={setModalOpen}
          closeEvent={saveMetrics}
          usePortal={true}
          isGoalType={isGoalType}
          useAdvanced
        />
      )}
    </div>
  );
};

export default MetricsObjective;

import React, { useEffect, useState } from 'react';

import startCase from 'lodash/startCase';

import { capitalize, getObjectiveLocale } from 'utils/HelperUtils';

import { getSelectedFormula } from 'components/admin/track-template/ConfigurationSettingTrackTemplate';
import Button from 'components/design-system/Button';

const ContentTrackTemplate = ({ trackDetailData, mechanismDescription }) => {
  let {
    trackType,
    cycles,
    sections,
    questionsCount,
    ratingTemplate,
    view,
    useComment,
    config,
    mechanism
  } = trackDetailData || {};

  const [listCycles, setListCycles] = useState([]);
  const [showMore, setShowMore] = useState(false);

  let formulaArrValueName =
    config?.formula && getSelectedFormula(config?.formula);

  const handleShowMore = () => {
    setListCycles(cycles);
    setShowMore(false);
  };

  useEffect(() => {
    // show 3 first cycles when there are more than 3 cycles
    if (cycles?.length > 3) {
      let threeListCycles = cycles.slice(0, 3);
      setListCycles(threeListCycles);
      setShowMore(true);
    } else {
      setListCycles(cycles);
    }
  }, [cycles]);

  return (
    <>
      {listCycles?.length > 0 && (
        <div className="track-in-use-outer">
          <h5 className="typography-h500 mb-[4px]">
            {getObjectiveLocale('Currently in use')}
          </h5>
          <div className="track-in-use-container">
            {listCycles.map((cycle) => {
              return (
                <div key={cycle.id} className="track-in-use-list mt-[4px]">
                  <h6 className="typography-form">{cycle.name}</h6>
                  <div className="status-and-id-container">
                    <span className="typography-h200 text-n-900">
                      {startCase(cycle.state)}
                    </span>
                    <span className="dot-divider"> . </span>
                    <span className="typography-h100 typography-secondary">
                      {cycle.id}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {showMore && (
            <Button.Tertiary
              customClass="mt-[4px]"
              onClick={() => handleShowMore()}
            >
              {getObjectiveLocale('Show')} {cycles.length - listCycles.length}{' '}
              {getObjectiveLocale('more cycles')}
            </Button.Tertiary>
          )}
        </div>
      )}

      {/* Configuration Setting */}
      {(mechanismDescription ||
        ratingTemplate?.name ||
        formulaArrValueName?.length > 0) && (
        <div className="configuration-setting-container">
          {mechanismDescription && (
            <>
              <h5 className="typography-h500 mt-[16px]">
                {getObjectiveLocale('Configuration Setting')}
              </h5>
              <p className="typography-form typography-secondary mt-[4px]">
                {mechanismDescription}
              </p>
            </>
          )}

          {ratingTemplate?.name && (
            <>
              <h5 className="typography-h400 mt-[16px]">
                {getObjectiveLocale('Rating template')}
              </h5>
              <p className="typography-form typography-secondary mt-[4px]">
                {ratingTemplate.name}
              </p>
            </>
          )}

          {formulaArrValueName && (
            <>
              <h5 className="typography-h400 mt-[16px]">
                {getObjectiveLocale('Formula')}
              </h5>
              <p className="typography-form typography-secondary mt-[4px]">
                {formulaArrValueName}
              </p>
            </>
          )}

          {view && (mechanism == 'each' || mechanism == 'overall_score') && (
            <>
              <h5 className="typography-h400 mt-[16px]">
                {getObjectiveLocale('Rating Option View')}
              </h5>
              <p className="typography-form typography-secondary mt-[4px]">
                {startCase(view)}{' '}
                {getObjectiveLocale(view == 'raw' ? 'Score' : 'Scale')}
              </p>
            </>
          )}

          {useComment && (
            <>
              <h5 className="typography-h400 mt-[16px]">
                {getObjectiveLocale('Comment')}
              </h5>
              <p className="typography-form typography-secondary mt-[4px]">
                {getObjectiveLocale(
                  useComment == 'no' ? 'Not used' : startCase(useComment)
                )}
              </p>
            </>
          )}
        </div>
      )}

      {/* Additional Question */}
      <h5 className="typography-h500 mt-[24px]">{`${
        trackType != 'general_questionnaire' ? 'Additional ' : ''
      } Question`}</h5>
      {sections?.length > 0 && questionsCount > 0 ? (
        <div className="bg-n-200 mt-[18px] px-[18px] py-[12px]">
          {sections?.map((section, index) => (
            <div key={index}>
              {index !== 0 && (
                <div className="border-solid border-0 border-t-[1px] border-n-400 my-[16px]"></div>
              )}
              <div className="mb-[12px]">
                <p className="typography-h400 text-n-900">{section?.name}</p>
                {trackType !== 'general_questionnaire' && (
                  <p className="typography-h100 text-n-800">{`${capitalize(
                    section?.position
                  )} scoring`}</p>
                )}
              </div>
              {section?.questions?.map((question, index) => (
                <p
                  key={index}
                  className="typography-form typography-paragraph text-n-900"
                >
                  {index + 1}. {question?.question}
                </p>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <p className="typography-form typography-secondary mt-[8px]">
          {getObjectiveLocale('No additional question')}
        </p>
      )}
    </>
  );
};

export default ContentTrackTemplate;

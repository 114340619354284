import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import {
  getFormalReviewAnswers,
  getFormalReviewAnswersAspectScoring,
  getFormalReviewAssignment,
  getGeneralQuestionnaireResult
} from 'client/FormalReviewClient';
import { FormalReviewProvider } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import ReviewResultOthersSidebar from 'components/formal-review/result/ReviewResultOthersSidebar';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingSplashScreen from 'components/shared/LoadingSplashScreen';

import ResultContent from '../components/formal-review/result/ResultContent';

function AssignmentReviewResult() {
  const { assignmentId } = useParams();
  const { config } = useUser();
  const history = useHistory();

  const [ready, setReady] = useState(false);
  const [cycleDetail, setCycleDetail] = useState(null);
  const [currentPhase, setCurrentPhase] = useState(null);
  const [currentPhaseId, setCurrentPhaseId] = useState(null);
  const [currentAssignment, setCurrentAssignment] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [currentTrackId, setCurrentTrackId] = useState(null);
  const [currentTrackConfigId, setCurrentTrackConfigId] = useState(null);
  const [trackTemplate, setTrackTemplate] = useState(null);
  const [empty, setEmpty] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [answersData, setAnswersData] = useState({});
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [emptyStateText, setEmptyStateText] = useState('');

  const cycleId = currentAssignment?.formalReviewCycle?.id;
  const targetId = currentAssignment?.target?.id;

  const getData = async () => {
    const { data, error } = await getFormalReviewAssignment(
      assignmentId,
      false
    );
    if (data) {
      setCycleDetail(data.formalReviewCycle);
      setCurrentPhase(data.formalReviewPhase.type);
      setCurrentPhaseId(data.formalReviewPhase.id);
      setCurrentAssignment(data);

      setCurrentTrackConfigId(data.tracks[0].trackConfigId);
      setTrackTemplate(data.tracks[0].template);
      setCurrentTrackId(data.tracks[0].id);
      setCurrentTrack(data.tracks[0].trackType);

      setEmpty(false);
    } else {
      if (error.message == 'Assignment already out of date') {
        setEmptyStateText(
          'Your response is empty because you had not submitted your review until the cycle ended'
        );
      }
    }

    setReady(true);
  };

  const getAnswers = async () => {
    if (
      currentTrack !== 'general_questionnaire' &&
      currentTrack !== 'reviewee_feedback'
    ) {
      setLoading(true);

      let params = {
        targetId: targetId,
        assignmentId: assignmentId,
        sortColumn: config?.defaultObjectiveSorting?.sortColumn || 'due_date',
        sortDirection: config?.defaultObjectiveSorting?.sortDirection || 'asc'
      };

      const { data } =
        currentTrack !== 'review_aspects_scoring'
          ? await getFormalReviewAnswers(
              cycleId,
              currentTrack,
              currentPhase,
              params
            )
          : await getFormalReviewAnswersAspectScoring(
              currentTrackConfigId,
              'all',
              {
                targetId
              }
            );
      if (data) {
        setAnswersData(data);
        setLoading(false);
      } else {
        setTimeout(() => {
          setAnswersData({});
          setLoading(false);
        }, 500);
      }
    }
  };

  const getQuestionnaireAnswers = async () => {
    let params = { targetId: targetId, assignmentId: assignmentId };
    const { data } = await getGeneralQuestionnaireResult(
      cycleId,
      currentPhase,
      currentTrack,
      params
    );
    if (data) {
      setQuestionnaireData(data);
    }
  };

  const loadTrack = async (track) => {
    setAnswersData(null);
    setCurrentTrackConfigId(track.trackConfigId);
    setCurrentTrackId(track.id);
    setCurrentTrack(track.trackType);
    setTrackTemplate(track.template);
  };

  const headerParams = {
    titlePage: currentAssignment?.formalReviewCycle.name,
    backToUrl: cycleDetail?.team?.id ? '/feedbacks' : `/appraisals`,
    isHeaderComposer: true
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);

    if (currentTrack && currentPhase) {
      getAnswers();
      getQuestionnaireAnswers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrack]);

  return ready ? (
    <>
      <HeaderPage {...headerParams} />
      {!empty ? (
        <div className="formal-review flex px-[0px] h-[calc(100vh-137px)] overflow-x-hidden">
          <div className="row row-formal-review-result row-formal-review-result-others bg-n-000 w-full overflow-y-auto ml-[0px] border-0 border-l border-solid border-n-400">
            {currentAssignment && (
              <Fragment>
                <ReviewResultOthersSidebar
                  currentAssignment={currentAssignment}
                  loadTrack={loadTrack}
                  currentTrackId={currentTrackId}
                />
                {!isLoading && (
                  <ResultContent
                    cycleDetail={cycleDetail}
                    currentPhase={currentPhase}
                    currentPhaseId={currentPhaseId}
                    currentTrackConfigId={currentTrackConfigId}
                    currentAssignment={currentAssignment}
                    currentTrack={currentTrack}
                    isLoading={isLoading}
                    answersData={answersData}
                    questionnaireData={questionnaireData}
                    cycleId={cycleId}
                    targetId={targetId}
                    trackTemplate={trackTemplate}
                  />
                )}
              </Fragment>
            )}
          </div>
        </div>
      ) : (
        <ListEmptyState
          fullHeight
          emptyIcon="icon-no-cycles"
          title={getObjectiveLocale('Review Result')}
          subtitle={getObjectiveLocale(
            emptyStateText || "You don't have any review result yet."
          )}
        />
      )}
    </>
  ) : (
    <LoadingSplashScreen />
  );
}

const AssignmentReviewResultComponent = (props) => (
  <FormalReviewProvider>
    <AssignmentReviewResult {...props} />
  </FormalReviewProvider>
);
export default AssignmentReviewResultComponent;

import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import QuestionComposer, {
  Content,
  Header
} from 'components/design-system/composer/Question';
import Dropdown from 'components/design-system/dropdown/Dropdown';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';
import TooltipPortal from 'components/shared/Tooltips/TooltipPortal';

const SurveyQuestionComposer = ({
  isReadOnly,
  type,
  options,
  questionIndex,
  question,
  changeData,
  pollOptionsAttributes,
  addDeleteOptionTemplate,
  addOrCopyTemplate,
  deleteTemplate,
  isMaxAnswerOption
}) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [portalData, setPortalData] = useState({});

  const setPortal = (e) => {
    setIsTooltipShown(true);
    setPortalData({
      name: "can't be blank",
      left: e.currentTarget.getBoundingClientRect().x + 'px',
      top: e.currentTarget.getBoundingClientRect().top + 40 + 'px'
    });
  };

  const generateTitle = (typeName) => {
    switch (typeName) {
      case 'poll':
        return 'Multiple Choices';
      case 'essay':
        return 'Comment';
      default:
        return startCase(typeName);
    }
  };

  return (
    <QuestionComposer customClass="question-template mb-[24px]">
      <Header
        title={`${getObjectiveLocale('Question')} ${questionIndex + 1}`}
        onCopy={() => addOrCopyTemplate('copy', questionIndex)}
        onDelete={() => deleteTemplate(questionIndex)}
        isReadOnly={isReadOnly}
      />
      <Content>
        <div className="flex px-[24px] py-[16px] w-full">
          <div className="flex flex-col flex-grow mr-[16px]">
            <span className="typography-h100 text-n-800 mb-[8px]">
              {getObjectiveLocale('Question')}
            </span>
            <div className="relative">
              <TextArea
                className={`w-full ${!question ? 'error-message' : ''} ${
                  isReadOnly ? 'bg-n-300' : ''
                }`}
                defaultValue={question}
                textPlaceholder={'Enter Your Question'}
                maxHeight={96}
                onHandleChange={(e) =>
                  changeData(e.target.value, 'question', questionIndex)
                }
                onMouseEnter={(e) => !question && setPortal(e)}
                onMouseLeave={() => setIsTooltipShown(false)}
                paddingClass={'px-[8px] py-[4px]'}
                disabled={isReadOnly}
                dataCy={`question-${questionIndex}`}
              />
              {!question && !isEmpty(portalData) && (
                <TooltipPortal
                  show={isTooltipShown}
                  portalData={portalData}
                  portalId="shared-tooltip"
                />
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <span className="typography-h100 text-n-800 mb-[8px]">
              {getObjectiveLocale('Answer Type')}
            </span>
            <Dropdown>
              <Dropdown.Trigger disabled={isReadOnly}>
                <div
                  className={`flex items-center w-[200px] border border-solid border-n-400 h-[32px]
                      box-border px-[16px] capitalize rounded-[4px] ${
                        isReadOnly ? 'bg-n-300' : 'bg-n-000'
                      }`}
                  data-cy="question-type-dropdown"
                >
                  {generateTitle(type)}
                  <SVGIcon
                    size="24"
                    iconName="icon-arrow_drop_down"
                    fillColor="var(--n-700)"
                    customClass="absolute top-[4px] right-[4px]"
                  />
                </div>
              </Dropdown.Trigger>
              <Dropdown.MenuItems customClass="w-full">
                {options.map(({ name, value }, index) => {
                  return (
                    <Dropdown.MenuItem
                      key={index}
                      dataCy={'answer-type' + index}
                      onClick={() =>
                        changeData(value, 'type', questionIndex, null)
                      }
                    >
                      {name}
                    </Dropdown.MenuItem>
                  );
                })}
              </Dropdown.MenuItems>
            </Dropdown>
          </div>
        </div>
      </Content>
      {(pollOptionsAttributes?.length > 0 || !isReadOnly) && (
        <Content>
          <div className="flex px-[24px] py-[16px] w-full">
            <div className="flex flex-col flex-grow">
              {pollOptionsAttributes &&
                pollOptionsAttributes?.map((value, optionIndex) => {
                  return (
                    <div
                      key={optionIndex}
                      className="flex items-center mb-[16px] group"
                      onMouseEnter={(e) =>
                        !value.option && !value.customType && setPortal(e)
                      }
                      onMouseLeave={() => setIsTooltipShown(false)}
                    >
                      <InputField
                        dataCy={`input-option-${optionIndex}`}
                        value={value.option}
                        inputClass={`w-full ${
                          !value.option && !value.customType
                            ? 'error-message'
                            : ''
                        }`}
                        disabled={isReadOnly || value.customType}
                        placeholder={getObjectiveLocale(
                          value.customType
                            ? 'Other (participant can type their answer here)'
                            : 'Enter an answer choice'
                        )}
                        handleChange={(e) =>
                          changeData(
                            e.currentTarget.value,
                            'pollOptionsAttributes',
                            questionIndex,
                            optionIndex
                          )
                        }
                      />
                      {pollOptionsAttributes?.length > 2 && !isReadOnly && (
                        <SVGIcon
                          size="24"
                          iconName="icon-delete"
                          customClass="ml-[16px] cursor-pointer invisible group-hover:visible"
                          dataCy={`delete-option-${optionIndex}`}
                          fillColor="var(--n-500)"
                          onClick={() =>
                            addDeleteOptionTemplate(
                              'delete',
                              questionIndex,
                              optionIndex
                            )
                          }
                        />
                      )}
                    </div>
                  );
                })}
              {!isReadOnly && type != 'essay' && (
                <div className="flex justify-between items-center">
                  <div className="flex justify-left items-center">
                    <Button.Tertiary
                      onClick={() =>
                        addDeleteOptionTemplate('add', questionIndex, false)
                      }
                      disabled={isMaxAnswerOption}
                      datacy={`add-answer-${questionIndex}`}
                    >
                      <SVGIcon
                        size="24"
                        iconName="icon-add"
                        fillColor={
                          isMaxAnswerOption ? 'var(--n-600)' : 'var(--base-600)'
                        }
                        customClass="mr-[8px]"
                      />
                      {getObjectiveLocale('Add Answer Choice')}
                    </Button.Tertiary>
                    {/* HIDE DARI BCA DULUUUU TAKEOUT */}
                    {/* {pollOptionsAttributes?.filter(
                      (option) => option.customType
                    ).length == 0 && (
                      <>
                        <span className="text-n-600">{' or '}</span>
                        <Button.Tertiary
                          datacy={`add-other-${questionIndex}`}
                          onClick={() =>
                            addDeleteOptionTemplate(
                              'add',
                              questionIndex,
                              false,
                              {
                                option: null,
                                customType: true
                              }
                            )
                          }
                        >
                          {getObjectiveLocale('Add "Other"')}
                        </Button.Tertiary>
                      </>
                    )}
                    */}
                  </div>
                  <p className="typography-h100 text-n-700">
                    {getObjectiveLocale(
                      isMaxAnswerOption
                        ? 'You already reached the maximum number of answer choices'
                        : 'You can add answer choice up to 10 choices'
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Content>
      )}
    </QuestionComposer>
  );
};

export default SurveyQuestionComposer;

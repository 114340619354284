import React, { useState } from 'react';

import dayjs from 'dayjs';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Accordion from 'components/design-system/accordion/Accordion';
import SVGIcon from 'components/shared/SVGIcon';

import AssignmentContent from './AssignmentContent';
import ModalFinalScroreProportion from './ModalFinalScoreProportion';

const AssignmentList = ({ inclusions, refetchInclusions, periodCycle }) => {
  const [deletedAssignment, setDeletedAssignment] = useState([]);
  const [modalType, setModalType] = useState('');
  const [hoverScoreProportion, setHoverScoreProportion] = useState(false);

  const finalScoreProportion = inclusions?.inclusions
    ?.map((data) => data.proportional)
    .reduce((acc, cur) => acc + cur);

  let HeaderAccordion = ({ title, date, id }) => (
    <div className="flex flex-row">
      <p
        className="typography-h500 text-n-900"
        data-cy={`inclusion-title-${id}`}
      >
        {title}&nbsp;
      </p>
      <p className="typography-h500 text-n-600">· {date}</p>
    </div>
  );

  const dateFormatter = (date) => dayjs(date).format('D MMM YYYY');

  return (
    <>
      <div className="flex flex-row justify-between items-center mt-[28px] mb-[20px]">
        <div className="typography-h600">{inclusions?.name}</div>
        {inclusions?.durationType == 'annual' && (
          <div
            className="flex flex-row hover:cursor-pointer mr-[8px]"
            onClick={() => setModalType('finalScoreProportion')}
            onMouseEnter={() => setHoverScoreProportion(true)}
            onMouseLeave={() => setHoverScoreProportion(false)}
            data-cy={`proportion-${inclusions?.id}`}
          >
            <SVGIcon
              iconName="icon-create"
              fillColor="var(--v-600)"
              size="16"
              customClass={`mr-[6px] ${
                hoverScoreProportion ? 'visible' : 'invisible'
              }`}
            />
            <p className="typography-button text-v-600 ">
              {getObjectiveLocale('Final Score Proportion')}:{' '}
              {finalScoreProportion}
            </p>
          </div>
        )}
      </div>
      {inclusions?.inclusions?.map((inclusion, index) => (
        <Accordion useBorder customClass="mb-[8px]" key={inclusion.id}>
          <Accordion.Header
            dataCyChevronButton={`open-assignment-${inclusion?.id}`}
            customSpaceClass="h-[48px] px-[24px] items-center"
          >
            <HeaderAccordion
              id={inclusion?.id}
              title={inclusion?.revieweePlacement?.name}
              date={`${
                inclusion?.revieweePlacement?.startsAt
                  ? dateFormatter(inclusion?.revieweePlacement?.startsAt)
                  : 'Undefined Date'
              } - ${
                inclusion?.revieweePlacement?.endsAt
                  ? dateFormatter(inclusion.revieweePlacement.endsAt)
                  : 'Now'
              }`}
            />
          </Accordion.Header>
          <Accordion.Content customSpaceClass=" " useSeparator>
            <AssignmentContent
              inclusion={inclusion}
              index={index}
              deletedAssignment={deletedAssignment}
              setDeletedAssignment={setDeletedAssignment}
            />
          </Accordion.Content>
        </Accordion>
      ))}

      {modalType && (
        <ModalFinalScroreProportion
          type={modalType}
          _handleActionModal={() => setModalType('')}
          _handleNext={() => setModalType('')}
          inclusions={inclusions}
          refetchInclusions={refetchInclusions}
          periodCycle={periodCycle}
        />
      )}
    </>
  );
};

export default AssignmentList;

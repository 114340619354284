import React from 'react';

import PermissionConfigurationBox from '../PermissionConfigurationBox';

const StepConfigurePermissions = ({
  permissionsData,
  selectedPermissions,
  setSelectedPermissions,
  loading,
  isSecurityAdmin,
  setGroup,
  info
}) => {
  return (
    <div className="mb-[36px] pb-[36px]">
      <PermissionConfigurationBox
        data={permissionsData}
        setGroup={setGroup}
        boxWidth="1100px"
        tableMinWidth="880px"
        selectedValues={selectedPermissions}
        setSelectedValues={setSelectedPermissions}
        loading={loading}
        isSecurityAdmin={isSecurityAdmin}
        info={info}
      />
    </div>
  );
};

export default StepConfigurePermissions;

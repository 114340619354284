import React, { createContext, useContext, useState } from 'react';

import produce, { setAutoFreeze } from 'immer';

import { getBehaviors, getBehaviorsModels } from 'client/adminClient.js';

// Create context
const ValueCompetencyContext = createContext();

// Function Provider
const ValueCompetencyProvider = (props) => {
  const initialState = {
    compentencyOrValue: {}, // onchange state
    dataValueOrCompetency: [], // when get data
    showNotif: false,
    showWarning: false,
    notifMessage: '',
    searchValue: '',
    configValueCompetency: {},
    selectedImgValue: '',
    currentEventTarget: '',

    // show inline create
    showInlineCreate: null,

    // pagination
    hasMore: false,
    nextOffset: 0,
    currentSize: 0,

    // currentTab
    currentTab: 'dictionary',

    // modal list group
    showListGroupModal: false,
    listGroup: [],

    // modal choose value image
    isChooseImageOpen: null,

    // current filter tab => active, archive, all
    currentFilterTab: 'active'
  };

  const [state, setState] = useState(initialState);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];

  return <ValueCompetencyContext.Provider value={contextValue} {...props} />;
};

const useValueCompetency = () => {
  const [
    {
      compentencyOrValue,
      dataValueOrCompetency,
      showNotif,
      showWarning,
      notifMessage,
      searchValue,
      hasMore,
      nextOffset,
      currentSize,
      currentTab,
      showInlineCreate,
      showListGroupModal,
      listGroup,
      isChooseImageOpen,
      currentFilterTab,
      configValueCompetency,
      selectedImgValue,
      currentEventTarget
    },
    immerSetState
  ] = useContext(ValueCompetencyContext);

  const onChangeValueOrCompetency = (value, name) => {
    immerSetState((draft) => {
      draft.compentencyOrValue[name] = value;
    });
  };

  const resetInputState = () => {
    immerSetState((draft) => {
      draft.compentencyOrValue = {};
    });
  };

  const resetFunction = () => {
    setIsChooseImageOpen(null);
    setShowInlineCreate(null);
    setShowListGroupModal(null);
    setSelectedImgValue('');
    setListGroup([]);
  };

  const showNotificationMessage = (message) => {
    immerSetState((draft) => {
      draft.showNotif = true;
      draft.notifMessage = message;
    });
    setTimeout(() => {
      immerSetState((draft) => {
        draft.showNotif = false;
        draft.notifMessage = '';
      });
    }, 2000);
  };

  const showWarningMessage = (message) => {
    immerSetState((draft) => {
      draft.showWarning = true;
      draft.notifMessage = message;
    });
    setTimeout(() => {
      immerSetState((draft) => {
        draft.showWarning = false;
        draft.notifMessage = '';
      });
    }, 4000);
  };

  const getValueOrCompetencyData = async (pathname, tab) => {
    let _params = paramsDictionaryOrModel(pathname, null, tab);
    let _currentTab = tab || currentTab;

    if (_currentTab == 'dictionary') await _getDetailCompetency(_params);
    else if (_currentTab == 'model') await _getDetailCompetencyModel(_params);

    resetFunction();
    resetInputState();
  };

  const _getDetailCompetency = async (params, isAppendData) => {
    const { data, pagination } = await getBehaviors(params);

    if (!isAppendData) setStateData(data, pagination);
    else setStateAppendData(data, pagination);
  };

  const _getDetailCompetencyModel = async (params, isAppendData) => {
    const { data, pagination } = await getBehaviorsModels(params);

    if (!isAppendData) setStateData(data, pagination);
    else setStateAppendData(data, pagination);
  };

  const appendValueOrCompetencyData = async (pathname, tab) => {
    const _params = paramsDictionaryOrModel(
      pathname,
      { offset: nextOffset },
      tab
    );
    let _currentTab = tab || currentTab;

    if (_currentTab == 'dictionary') await _getDetailCompetency(_params, true);
    else if (_currentTab == 'model')
      await _getDetailCompetencyModel(_params, true);
  };

  const getListGroup = (e, value) => {
    e.stopPropagation();
    immerSetState((draft) => {
      draft.showListGroupModal = true;
      value?.map((val) => draft.listGroup.push(val.name));
    });
  };

  const setStateData = (data, pagination) => {
    immerSetState((draft) => {
      if (data) {
        draft.dataValueOrCompetency = data;
        draft.isActiveCard = false;
      }
      if (pagination) {
        draft.nextOffset = pagination.nextOffset;
        draft.hasMore = pagination.nextOffset == null ? false : true;
        draft.currentSize = draft.currentSize + pagination.currentSize;
      }
    });
  };

  const setStateAppendData = (data, pagination) => {
    immerSetState((draft) => {
      if (data) {
        draft.dataValueOrCompetency.push.apply(
          draft.dataValueOrCompetency,
          data
        );
      }
      if (pagination) {
        draft.nextOffset = pagination.nextOffset;
        draft.hasMore = pagination.nextOffset == null ? false : true;
        draft.currentSize = draft.currentSize + pagination.currentSize;
      }
    });
  };

  const setSearchValue = (value) => {
    immerSetState((draft) => {
      draft.searchValue = value;
    });

    resetFunction();
  };

  const setCurrentTab = (tab) => {
    immerSetState((draft) => {
      draft.currentTab = tab;
    });

    resetFunction();
  };

  const setShowInlineCreate = (value) => {
    immerSetState((draft) => {
      draft.showInlineCreate = value;
    });
    resetInputState();
  };

  const setShowListGroupModal = (value) => {
    immerSetState((draft) => {
      draft.showListGroupModal = value;
    });
  };

  const setIsChooseImageOpen = (value) => {
    immerSetState((draft) => {
      draft.isChooseImageOpen = value;
    });
  };

  const setCurrentFilterTab = (value) => {
    immerSetState((draft) => {
      draft.currentFilterTab = value;
    });
  };

  const setSelectedImgValue = (value) => {
    immerSetState((draft) => {
      draft.selectedImgValue = value;
    });
  };

  const setCurrentEventTarget = (value) => {
    immerSetState((draft) => {
      draft.currentEventTarget = value;
    });
  };

  const setConfigValueCompetency = (value) => {
    immerSetState((draft) => {
      draft.configValueCompetency = value;
    });
  };

  const setListGroup = (value) => {
    immerSetState((draft) => {
      draft.listGroup = value;
    });
  };

  const behaviorType = (pathname) => {
    return pathname.includes('competencies') ? 'competency' : 'value';
  };

  const paramsDictionaryOrModel = (
    pathname,
    params,
    tab,
    isAlwaysActive,
    needOrderAndSort = true
  ) => {
    let _params = {
      ...params,
      state: currentFilterTab
    };

    let _currentTab = tab || currentTab;
    if (_currentTab == 'dictionary') {
      _params.listFor = 'cms';
      _params.behaviorType = behaviorType(pathname);
    }

    if (_currentTab == 'model') {
      _params.modelType = behaviorType(pathname);
    }

    if (searchValue !== '') {
      _params.q = searchValue;
    }

    if (isAlwaysActive) {
      _params.state = 'active';
    }

    if (needOrderAndSort) {
      _params.sortBy = 'created_at';
      _params.order = 'desc';
    }

    return _params;
  };

  const bodyDictionaryOrModel = (
    title,
    desc,
    pathname,
    type,
    parentId,
    tab
  ) => {
    let _currentTab = tab || currentTab;
    let body = { title };

    if (_currentTab == 'dictionary') {
      body.behaviorType = behaviorType(pathname);
    }

    if (_currentTab == 'model') {
      body.modelType = behaviorType(pathname);
    }

    if (desc !== '') {
      body.description = desc;
    }

    if (type == 'keyBehaviorName' || type == 'keyBehaviorDesc') {
      body.parentId = parentId;
    }

    if (selectedImgValue !== '') {
      body.iconUrl = selectedImgValue;
    }

    return body;
  };

  const isValuesPage = (pathname, expectReturn) => {
    return pathname.includes('values') ? expectReturn : '';
  };

  return {
    onChangeValueOrCompetency,
    compentencyOrValue,
    dataValueOrCompetency,
    getValueOrCompetencyData,
    resetInputState,
    showNotif,
    showWarning,
    notifMessage,
    showNotificationMessage,
    showWarningMessage,
    searchValue,
    setSearchValue,
    behaviorType,
    paramsDictionaryOrModel,
    bodyDictionaryOrModel,
    configValueCompetency,
    setConfigValueCompetency,
    setSelectedImgValue,
    selectedImgValue,
    isValuesPage,
    currentEventTarget,
    setCurrentEventTarget,
    resetFunction,

    // current filter tab => active, archive, all
    currentFilterTab,
    setCurrentFilterTab,

    // pagination
    hasMore,
    nextOffset,
    currentSize,
    appendValueOrCompetencyData,

    // currentTab
    currentTab,
    setCurrentTab,

    // showInlineCreate
    showInlineCreate,
    setShowInlineCreate,

    // show listgroup modal
    setShowListGroupModal,
    showListGroupModal,
    getListGroup,
    listGroup,

    // modal choose value image
    isChooseImageOpen,
    setIsChooseImageOpen
  };
};

export { ValueCompetencyProvider, useValueCompetency };

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

import { getCycle, getPhaseDetail } from 'client/FormalReviewClient';
import {
  getFormalReviewAnswers,
  getFormalReviewAnswersAspectScoring,
  getFormalReviewAveragesResult,
  getGeneralQuestionnaireResult
} from 'client/FormalReviewClient';
import { FormalReviewProvider } from 'context/FormalReviewContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import ReviewResultSidebar from 'components/formal-review/result/ReviewResultSidebar';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingSplashScreen from 'components/shared/LoadingSplashScreen';

import ResultContent from '../components/formal-review/result/ResultContent';

function FormalReviewResult() {
  const { id, cycleId, placementId } = useParams();
  const { search } = useLocation();
  const { user, config, organization } = useUser();

  const [ready, setReady] = useState(false);
  const [backUrl, setBackUrl] = useState('/appraisals');
  const [cycleDetail, setCycleDetail] = useState([
    {
      formalReviewCycle: {
        name: ''
      }
    }
  ]);
  const [phases, setPhases] = useState([]);
  const [currentPhase, setCurrentPhase] = useState('');
  const [currentPhaseId, setCurrentPhaseId] = useState(null);
  const [currentAssignment, setCurrentAssignment] = useState({});
  const [empty, setEmpty] = useState(true);
  const [tracks, setTracks] = useState([]);
  // MIGRATE FUNCTION AND STATE FROM ResultContent.js
  const [currentTrackConfig, setCurrentTrackConfig] = useState(null);
  const [showCalibrationHistories, setShowCalibrationHistories] =
    useState(false);
  const [isLoading, setLoading] = useState(false);
  const [answersData, setAnswersData] = useState({});
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [isAverageResult, setIsAverageResult] = useState(false);

  const [currentTrackState, setCurrentTrackState] = useState({
    trackType: null,
    trackId: null
  });

  const { trackType: currentTrack, trackId: currentTrackId } =
    currentTrackState;

  const targetId = currentAssignment?.target?.id || id;
  const getData = async () => {
    const queryString = qs.parse(search.substring(1));
    const params = {
      targetId: user?.id != id ? id : null,
      targetPlacementId: placementId
    };
    const { data } = await getCycle(cycleId, params);
    if (data) {
      const { phases } = data;
      const filteredPhases = phases.filter(
        (phase) =>
          phase.type !== 'peer_selection' && phase.type !== 'calibration'
      );
      let currentPhase;

      if (queryString.phase) {
        currentPhase = phases.find((obj) => obj.type === queryString.phase);
        currentPhase = currentPhase === undefined ? phases[0] : currentPhase;
      } else {
        currentPhase = phases[0];
      }

      if (currentPhase) {
        const { data: phaseData } = await getPhaseDetail(
          cycleId,
          currentPhase.type,
          {
            targetId: id,
            targetPlacementId: placementId,
            result: true
          }
        );
        if (phaseData) {
          setCycleDetail(data);
          setPhases(filteredPhases);
          setCurrentPhase(currentPhase.type);
          setCurrentPhaseId(currentPhase.id);
          setCurrentAssignment(phaseData.assignments[0]);
          setEmpty(false);
        }
      }
    }
    setReady(true);
  };

  const getTrackFromAssignment = () => {
    const currentPhaseDetail = phases?.find(
      (phase) => phase.type === currentPhase
    );
    const scoreCardVisibility =
      currentPhaseDetail?.scoreCard &&
      (Object.keys(currentPhaseDetail.scoreCard).length > 1
        ? true
        : currentPhaseDetail.scoreCard?.scoreAspects?.length > 0);
    if (
      currentPhaseDetail &&
      !isEmpty(currentPhaseDetail.scoreCard) &&
      scoreCardVisibility
    ) {
      // now scoreCard.scoreAspects always visible even users not select any scoreAspects (scoreCard: { scoreAspects:[] })
      let finalScoreTrack = {
        name: 'Score Card',
        trackType: 'score_card'
      };
      const addScoreCardTrack =
        currentAssignment?.tracks.concat(finalScoreTrack);
      setTracks(addScoreCardTrack);
    } else {
      setTracks(currentAssignment?.tracks);
    }
  };

  const getAnswers = async () => {
    const usp = new URLSearchParams(window.location.search);
    const assignmentId = usp.get('assignmentId');
    let params = {
      targetId: targetId,
      targetPlacementId: placementId,
      sortColumn: config?.defaultObjectiveSorting?.sortColumn || 'due_date',
      sortDirection: config?.defaultObjectiveSorting?.sortDirection || 'asc',
      requesterAssignmentId: assignmentId // to enable delegated user acting as manager review actor
    };

    if (
      cycleDetail.team &&
      organization.identifier == 'bca' &&
      currentTrack == 'values_scoring'
    ) {
      return getAveragesResult(params);
    }

    if (
      currentTrack !== 'general_questionnaire' &&
      currentTrack !== 'reviewee_feedback' &&
      currentTrack !== 'performance_rating'
    ) {
      const { data } =
        currentTrack !== 'review_aspects_scoring'
          ? await getFormalReviewAnswers(
              cycleId,
              currentTrack,
              currentPhase,
              params
            )
          : await getFormalReviewAnswersAspectScoring(
              currentTrackConfig?.id,
              'all',
              params
            );
      if (data) {
        setAnswersData(data);
      }
    }
  };

  const getAveragesResult = async (params) => {
    setIsAverageResult(true);
    const { data } = await getFormalReviewAveragesResult(
      cycleId,
      currentTrack,
      currentPhase,
      params
    );
    setAnswersData(data);
  };

  const getQuestionnaireAnswers = async () => {
    const usp = new URLSearchParams(window.location.search);
    const assignmentId = usp.get('assignmentId');

    let params = {
      targetId: targetId,
      targetPlacementId: placementId,
      requesterAssignmentId: assignmentId // to enable delegated user acting as manager review actor
    };

    const { data } = await getGeneralQuestionnaireResult(
      cycleId,
      currentPhase,
      currentTrack,
      params
    );
    if (data) {
      setQuestionnaireData(data);
    }
  };

  const findCurrentTrackConfig = (trackType, trackName) => {
    const currentPhaseData = phases?.find(
      (phase) => phase.type === currentPhase
    );
    const currentTrackConfig = currentPhaseData?.trackConfigs?.find(
      (trackConfig) => {
        return trackConfig.type === trackType && trackConfig.name === trackName;
      }
    );

    return currentTrackConfig;
  };

  const loadTrack = async (trackType, trackId, trackName) => {
    setCurrentTrackConfig(findCurrentTrackConfig(trackType, trackName));
    setCurrentTrackState({
      trackType,
      trackId
    });
  };

  const loadAssignment = async (phaseType) => {
    setLoading(true);
    const { data } = await getPhaseDetail(cycleId, phaseType, {
      targetId: id,
      result: true,
      targetPlacementId: placementId
    });
    if (data) {
      setCurrentPhase(phaseType);
      setCurrentPhaseId(data?.id);
      setCurrentAssignment(data.assignments[0]);
    }
    setLoading(false);
  };

  const getFormalReviewResult = async () => {
    if (currentTrack !== 'score_card') {
      setLoading(true);

      if (currentTrack && currentTrackId) {
        await getAnswers();
        await getQuestionnaireAnswers();
      }
      setLoading(false);
    }
  };

  const headerProps = {
    titlePage: cycleDetail.name,
    backToUrl: cycleDetail?.team?.id ? '/feedbacks' : backUrl,
    isHeaderComposer: true
  };

  useEffect(() => {
    getData();
    if (localStorage.getItem('backUrl')) {
      setBackUrl(localStorage.getItem('backUrl'));
      localStorage.removeItem('backUrl');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTrackFromAssignment();
    if (currentAssignment?.tracks?.length > 0) {
      if (phases.length > 0) {
        const selectedPhase = currentPhase
          ? phases.find((phase) => phase.type == currentPhase)
          : phases[0];
        setCurrentTrackConfig(selectedPhase.trackConfigs[0]);
      }
      setCurrentTrackState({
        trackType: currentAssignment.tracks[0].trackType,
        trackId: currentAssignment.tracks[0]?.id
      });
    }
    // eslint-disable-next-line
  }, [currentAssignment]);

  useEffect(() => {
    const currentPhaseDetail = phases?.find(
      (phase) => phase.type === currentPhase
    );
    if (currentPhaseDetail?.scoreCard) {
      setShowCalibrationHistories(
        currentPhaseDetail?.scoreCard?.calibrationHistories
      );
    }
    // eslint-disable-next-line
  }, [currentPhase]);

  useEffect(() => {
    setAnswersData(null);
    getFormalReviewResult();
    // eslint-disable-next-line
  }, [currentTrack, currentTrackId]);

  return ready ? (
    <>
      <HeaderPage {...headerProps} />
      <div className="px-[0px]">
        {!empty ? (
          <div className="row-formal-review-result">
            <ReviewResultSidebar
              phases={phases}
              currentPhase={currentPhase}
              loadAssignment={loadAssignment}
              tracks={tracks}
              loadTrack={loadTrack}
              currentTrack={currentTrack}
              currentTrackId={currentTrackId}
            />
            <ResultContent
              cycleDetail={cycleDetail}
              currentPhase={currentPhase}
              currentPhaseId={currentPhaseId}
              currentAssignment={currentAssignment}
              currentTrack={currentTrack}
              currentTrackConfigId={currentTrackConfig?.id}
              trackTemplate={currentTrackConfig?.template}
              trackConfig={currentTrackConfig?.config}
              isLoading={isLoading}
              answersData={answersData}
              questionnaireData={questionnaireData}
              cycleId={cycleId}
              targetId={targetId}
              showCalibrationHistories={showCalibrationHistories}
              isAverageResult={isAverageResult}
            />
          </div>
        ) : (
          <ListEmptyState
            emptyIcon="icon-no-cycles"
            title={getObjectiveLocale('Review Result')}
            subtitle={getObjectiveLocale(
              "You don't have any review result yet."
            )}
            fullHeight={true}
          />
        )}
      </div>
    </>
  ) : (
    <LoadingSplashScreen />
  );
}

const FormalReviewResultComponent = (props) => (
  <FormalReviewProvider>
    <FormalReviewResult {...props} />
  </FormalReviewProvider>
);

export default FormalReviewResultComponent;

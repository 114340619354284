import React from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/shared/modal/Modal';

import './ModalCreateTrackTemplate.scss';

const ModalCreateTrackTemplate = ({ setShowCreateTrackModal }) => {
  const history = useHistory();
  const { config } = useUser();
  const trackTemplates = [
    {
      mechanism: 'goals_scoring',
      title: `${getObjectiveLocale('Goal')} Final Scoring`,
      description: `Show all ${getObjectiveLocale(
        'goals'
      ).toLowerCase()} owned by a reviewee. Reviewers can later give rating on reviewee’s performance based on reviewers ${getObjectiveLocale(
        'goals'
      ).toLowerCase()}`
    },
    {
      mechanism: 'tasks_scoring',
      title: 'Task Final Scoring',
      description:
        'Show all tasks owned by a reviewee. Reviewers can later give rating on reviewee’s performance based on reviewers tasks'
    },
    {
      mechanism: 'values_scoring',
      title: `${getObjectiveLocale('Value')} Final Scoring`,
      description: `Show all ${getObjectiveLocale(
        'Values'
      ).toLowerCase()} mapped to a reviewee according to its ${getObjectiveLocale(
        'Value'
      ).toLowerCase()} model and asks reviewers to give rating on reviewee’s demonstration for each ${getObjectiveLocale(
        'Value'
      ).toLowerCase()}`
    },
    {
      mechanism: 'competencies_scoring',
      title: `${getObjectiveLocale('Competency')} Final Scoring`,
      description: `Show all ${getObjectiveLocale(
        'Competencies'
      ).toLowerCase()} mapped to a reviewee according to its ${getObjectiveLocale(
        'Competencies'
      ).toLowerCase()} model and asks reviewers to give rating on reviewee’s demonstration for each competency`
    },
    {
      mechanism: 'values_recognition_feedback',
      title: `${getObjectiveLocale('Value')} Recognition & Feedback`,
      description: `Choose one or more than one ${getObjectiveLocale(
        'Value'
      ).toLowerCase()} that a reviewee need to continue doing or need to improve`
    },
    {
      mechanism: 'competencies_recognition_feedback',
      title: `Competency Recognition & Feedback`,
      description: `Choose one or more than one competency that a reviewee need to continue doing or need to improve`
    },
    {
      mechanism: 'general_questionnaire',
      title: `General Questions`,
      description: `Give reviewers a couple of questions that works similarly like a questionnaire. Organisation can used this to ask things that is not covered in other scoring tracks.`
    },
    {
      mechanism: 'summary',
      title: `Summary`,
      description: `Show the final scoring result from other scoring tracks or reviewers can give a final performance rating/scoring`
    },
    {
      mechanism: 'reviewee_feedback',
      title: `Reviewee Feedback`,
      description: `Show the result for each tracks in a Manager Review phase and the reviewee can view then give comment`
    }
  ];

  if (config?.scoredAttributesFeature) {
    trackTemplates.push({
      mechanism: 'review_aspects_scoring',
      title: getObjectiveLocale('Review Aspects Scoring'),
      description: `Show the custom final score attributes. Reviewers can later give rating on the attributes`
    });
  }

  return (
    <Modal
      eventOnClickClose={() => setShowCreateTrackModal(false)}
      title={getObjectiveLocale('Select Track Type')}
      className="modal-with-title-and-description-container"
      dataCyModal="modal-content"
      withFooter={false}
    >
      <div className="add-list-container">
        {trackTemplates.map((track, index) => {
          return (
            <div
              className="single-list-container"
              onClick={() => {
                setShowCreateTrackModal(false);
                history.push(
                  `/appraisals/cycles/tracks/create/${track.mechanism}`
                );
              }}
              key={index}
            >
              <h6 className="typography-h500">{track.title}</h6>
              <p className="list-description">{track.description}</p>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
export default ModalCreateTrackTemplate;

import React from 'react';

import DeliverableRevieweeVerify from './Verify/DeliverableRevieweeVerify';
import NotificationReminderVerify from './Verify/NotificationReminderVerify';
import PhaseVerify from './Verify/PhaseVerify';
import PrefilledVerify from './Verify/PrefilledVerify';
import ReviewPhaseDependencyVerify from './Verify/ReviewPhaseDependencyVerify';
import ReviewScheduleVerify from './Verify/ReviewScheduleVerify';
import SummaryVerify from './Verify/SummaryVerify';
import WeightScoringVerify from './Verify/WeightScoringVerify';

function Verify({ cycleData }) {
  return (
    <div className="pl-[40px] pt-[24px] w-[768px]">
      <SummaryVerify cycleData={cycleData} />
      <PhaseVerify cycleData={cycleData} />
      <PrefilledVerify cycleData={cycleData} />
      <WeightScoringVerify cycleData={cycleData} />
      <ReviewPhaseDependencyVerify cycleData={cycleData} />
      <DeliverableRevieweeVerify cycleData={cycleData} />
      <ReviewScheduleVerify cycleData={cycleData} />
      <NotificationReminderVerify cycleData={cycleData} />
    </div>
  );
}

export default Verify;

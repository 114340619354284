import React, { useEffect, useState } from 'react';

import { getConfig } from 'client/admin/Config';
import { useUser } from 'context/UserContext';
import { useLocale } from 'hooks/useLocale';
import useOrganization from 'hooks/useOrganization';
import usePermission from 'hooks/usePermission';

import ContentNavigator from 'components/shared/ContentNavigator';
import InputField from 'components/shared/InputField';

import {
  DropdownOrganization,
  ToggleCard,
  changeUserData
} from './OrganizationHelper';

const Behavior = ({ configParams }) => {
  const { setBothLocale } = useLocale();
  const { appType, user } = useUser();
  const isReadOnly =
    usePermission('settingsOrganizationBehaviors') === 'read_only';

  const { configData, changeConfigData, changeOriginConfigData } =
    useOrganization((configData) => ({
      configData: configData.configData,
      changeConfigData: configData.changeConfigData,
      changeOriginConfigData: configData.changeOriginConfigData
    }));
  const [activeSection, setActiveSection] = useState('general-section');

  let list = [
    { id: 'general-section', name: 'General' },
    { id: 'recognition-section', name: 'Recognition' }
  ];

  const listCompetency = [
    {
      element: 'toggle',
      cardTitle: setBothLocale('Allow key behavior on competency'),
      cardDescription: setBothLocale(
        'Allow user to add key behavior on each competency.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          competencyDictionary:
            configData.competencyDictionary === 'allow_key_behaviors'
              ? 'no_key_behaviors'
              : 'allow_key_behaviors'
        }),
      isChecked: configData?.competencyDictionary === 'allow_key_behaviors',
      dataCy: 'user-competencyDictionary',
      insideCardPermission: true,
      isVisible: true,
      inputElement: [
        {
          element: 'input',
          label: 'Maximum level of key behavior',
          placeholder: 'Maximum level of key behavior',
          handleChange: (e) =>
            changeConfigData({
              ...configData,
              competenciesMaxLevel: e.currentTarget.value
            }),
          value: configData?.competenciesMaxLevel,
          insideCardPermission: true,
          dataCy: 'max-competency-level',
          isVisible: configData.behaviorsFramework === 'proficiency_levels',
          customClass: 'mt-[0px]'
        }
      ].filter((el) => el.isVisible),
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: setBothLocale(
        'Allow basic level of key behavior on competency'
      ),
      cardDescription: setBothLocale(
        'Allow user to use basic level of key behavior on each competency.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          competenciesUseBasicLevel: !configData.competenciesUseBasicLevel
        }),
      isChecked: configData?.competenciesUseBasicLevel,
      dataCy: 'user-competenciesUseBasicLevel',
      isVisible: configData.behaviorsFramework === 'proficiency_levels',
      disabled: isReadOnly
    }
  ].filter((el) => el.isVisible);

  const listGeneral = [
    {
      element: 'dropdown',
      label: setBothLocale('Behavior Framework'),
      onChange: (id) =>
        changeConfigData({ ...configData, behaviorsFramework: id }),
      selected: configData?.behaviorsFramework,
      dataCy: 'user-behaviorsFramework',
      listOption: [
        { id: 'proficiency_levels', title: 'Proficiency Levels' },
        {
          id: 'development_dimensions_international',
          title: 'Development Dimensions International'
        }
      ],
      disabled: isReadOnly
    }
  ];

  const listValue = [
    {
      element: 'toggle',
      cardTitle: setBothLocale('Allow key behavior on value'),
      cardDescription: setBothLocale(
        'Allow user to add key behavior on each value.'
      ),
      onChange: () =>
        changeUserData(
          'groupedValues',
          'recognitionConfig',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.recognitionConfig?.groupedValues,
      dataCy: 'user-recognitionConfig-groupedValues',
      insideCardPermission: true,
      isVisible: true,
      inputElement: [
        {
          element: 'input',
          label: 'Maximum level of key behavior',
          placeholder: 'Maximum level of key behavior',
          handleChange: (e) =>
            changeConfigData({
              ...configData,
              valuesMaxLevel: e.currentTarget.value
            }),
          value: configData?.valuesMaxLevel,
          insideCardPermission: true,
          dataCy: 'max-competency-level',
          isVisible: configData.behaviorsFramework === 'proficiency_levels',
          customClass: 'mt-[0px]'
        }
      ].filter((el) => el.isVisible)
    },
    {
      element: 'toggle',
      cardTitle: setBothLocale('Allow basic level of key behavior on value'),
      cardDescription: setBothLocale(
        'Allow user to use basic level of key behavior on each value.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          valuesUseBasicLevel: !configData.valuesUseBasicLevel
        }),
      isChecked: configData?.valuesUseBasicLevel,
      dataCy: 'user-valuesUseBasicLevel',
      isVisible: configData.behaviorsFramework === 'proficiency_levels',
      disabled: isReadOnly
    }
  ].filter((el) => el.isVisible);

  const listRecognition = [
    {
      element: 'toggle',
      cardTitle: setBothLocale('Give recognition/feedback using competency'),
      cardDescription: setBothLocale(
        'Allow user to use competency when giving recognition/feedback.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          allowUsingCompetencies: !configData.allowUsingCompetencies
        }),
      isChecked: configData?.allowUsingCompetencies,
      dataCy: 'user-allowUsingCompetencies',
      insideCardPermission: true,
      isVisible: user.role && appType !== 'culture',
      inputElement: [
        {
          element: 'dropdown',
          label: setBothLocale('Recognition Level'),
          onChange: (id) =>
            changeConfigData({
              ...configData,
              privateCompetencyRecognitionLevel: id
            }),
          selected: configData?.privateCompetencyRecognitionLevel,
          dataCy: 'user-privateCompetencyRecognitionLevel',
          listOption: [
            { id: 'key_behavior', title: 'Key Behavior' },
            { id: 'behavior', title: 'Behavior' }
          ],
          customClass: 'mt-[0px]',
          isVisible: true
        }
      ],
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: setBothLocale('Give recognition/feedback using value'),
      cardDescription: setBothLocale(
        'Allow user to use value when giving recognition/feedback.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          allowUsingValues: !configData.allowUsingValues
        }),
      isChecked: configData?.allowUsingValues,
      dataCy: 'user-allowUsingValues',
      isVisible: user.role && appType !== 'culture',
      insideCardPermission: true,
      inputElement: [
        {
          element: 'dropdown',
          label: setBothLocale('Private Recognition Level'),
          onChange: (id) =>
            changeConfigData({
              ...configData,
              privateValueRecognitionLevel: id
            }),
          selected: configData?.privateValueRecognitionLevel,
          dataCy: 'user-privateValueRecognitionLevel',
          listOption: [
            { id: 'key_behavior', title: 'Key Behavior' },
            { id: 'behavior', title: 'Behavior' }
          ],
          customClass: 'mt-[0px]',
          isVisible: true
        },
        {
          element: 'dropdown',
          label: setBothLocale('Public Recognition Level'),
          onChange: (id) =>
            changeConfigData({
              ...configData,
              publicValueRecognitionLevel: id
            }),
          selected: configData?.publicValueRecognitionLevel,
          dataCy: 'user-publicValueRecognitionLevel',
          listOption: [
            { id: 'key_behavior', title: 'Key Behavior' },
            { id: 'behavior', title: 'Behavior' }
          ],
          customClass: 'mt-[24px]',
          isVisible: true
        }
      ],
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: setBothLocale('Show Public Recognition feature'),
      cardDescription: setBothLocale(
        'Allow user to give public recognition in Culture app when using Performance app.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          linkToCultureRecognitionFromPerformance:
            !configData.linkToCultureRecognitionFromPerformance
        }),
      isChecked: configData?.linkToCultureRecognitionFromPerformance,
      isVisible: user.role && appType !== 'culture',
      dataCy: 'user-linkToCultureRecognitionFromPerformance',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: setBothLocale('Allow work attribute'),
      cardDescription: setBothLocale(
        'Allow organization to have work attributes.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          allowUsingWorkAttributes: !configData.allowUsingWorkAttributes
        }),
      isChecked: configData?.allowUsingWorkAttributes,
      isVisible: user.role && appType !== 'culture',
      dataCy: 'user-allowUsingWorkAttributes',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: setBothLocale('Give recognition/feedback with objective'),
      cardDescription: setBothLocale(
        'Allow user to choose objective when giving recognition/feedback.'
      ),
      onChange: () =>
        changeConfigData({
          ...configData,
          objectiveRecognition: !configData.objectiveRecognition
        }),
      isChecked: configData?.objectiveRecognition,
      isVisible: user.role && appType !== 'culture',
      dataCy: 'user-objectiveRecognition',
      disabled: isReadOnly
    },
    {
      element: 'input',
      label: 'Maximum receiver recognition',
      placeholder: 'Maximum receiver recognition',
      handleChange: (e) =>
        changeUserData(
          'maxReceiver',
          'recognitionConfig',
          parseInt(e.target.value),
          configData,
          changeConfigData
        ),
      value: configData?.recognitionConfig?.maxReceiver,
      isVisible: user.cultureRole && appType !== 'performance',
      dataCy: 'max-receiver-recognition',
      disabled: isReadOnly
    },
    {
      element: 'dropdown',
      label: setBothLocale('Recognition Audience'),
      cardDescription: setBothLocale(
        'Menentukan target yang akan dikirimkan notifikasi pada saat ada post recognition pada suatu grup'
      ),
      onChange: (id) =>
        changeConfigData({ ...configData, recognitionAudience: id }),
      selected: configData?.recognitionAudience,
      isVisible: user.cultureRole && appType !== 'performance',
      dataCy: 'user-recognitionAudience',
      listOption: [
        { id: 'group_members', title: 'Everyone in the group' },
        {
          id: 'recognition_targets',
          title: 'Only recognized people in the post'
        }
      ],
      customClass: 'mt-[24px]',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: setBothLocale('Group Recognition'),
      cardDescription: setBothLocale('Enable/disable key behavior on value'),
      onChange: () =>
        changeUserData(
          'groupedValues',
          'recognitionConfig',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.recognitionConfig?.groupedValues,
      isVisible: user.cultureRole && appType !== 'performance',
      dataCy: 'user-groupedValues',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: setBothLocale('Group Skills'),
      cardDescription: setBothLocale(
        'Enable/disable give skill when giving recognition'
      ),
      onChange: () =>
        changeUserData(
          'skills',
          'recognitionConfig',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.recognitionConfig?.skills,
      isVisible: user.cultureRole && appType !== 'performance',
      dataCy: 'user-skills',
      disabled: isReadOnly
    },
    {
      element: 'toggle',
      cardTitle: setBothLocale(
        'Email Notification for Culture Recognition Receiver'
      ),
      cardDescription: setBothLocale(
        'Send email notification when user receiving recognition'
      ),
      onChange: () =>
        changeUserData(
          'emailNotification',
          'recognitionConfig',
          null,
          configData,
          changeConfigData
        ),
      isChecked: configData?.recognitionConfig?.emailNotification,
      isVisible: user.cultureRole && appType !== 'performance',
      dataCy: 'user-emailNotification',
      disabled: isReadOnly
    }
  ].filter(({ isVisible }) => isVisible);

  const getData = async () => {
    const { data } = await getConfig(configParams);
    if (data) {
      changeConfigData(data);
      changeOriginConfigData(data);
    }
  };

  const _onScroll = (containerEl) => {
    const scrollTop = containerEl.target.scrollTop + 100;
    const sections = ['general-section', 'recognition-section'];
    sections.forEach((section) => {
      const el = document.getElementById(section);
      if (el && scrollTop >= el.offsetTop) {
        setActiveSection(section);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="fixed right-0 mt-[24px] w-[160px] z-index-10">
        <ContentNavigator
          title="CONTENTS"
          activeSection={activeSection}
          listNavigatorContents={list}
          additionalOffset={80}
          sectionContent="scrollable-config"
        />
      </div>
      <div
        className="overflow-auto w-[87%] h-[calc(100vh-64px)]"
        id="scrollable-config"
        onScroll={(e) => _onScroll(e)}
      >
        <div className="w-[768px] mt-[24px]">
          <p className="typography-h300 text-n-800 uppercase mb-[24px]">
            {setBothLocale('Behavior Configuration')}
          </p>
          <section className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px] mt-[24px]">
            <p
              className="typography-h500 text-n-900 mb-[24px]"
              id="general-section"
            >
              {setBothLocale('General')}
            </p>
            {listGeneral.map((config, index) => (
              <DropdownOrganization {...config} key={`0-${index}`} />
            ))}
            <p className="typography-h100 text-n-800 my-[24px]">
              {setBothLocale('Competency')}
            </p>
            {listCompetency.map((config, index) =>
              config.element === 'toggle' ? (
                <ToggleCard {...config} key={`1-${index}`} />
              ) : (
                <InputField {...config} key={`1-${index}`} />
              )
            )}
            {configData.behaviorsFramework === 'proficiency_levels' && (
              <>
                <p className="typography-h100 text-n-800 my-[24px]">
                  {setBothLocale('Value')}
                </p>
                {listValue.map((config, index) =>
                  config.element === 'toggle' ? (
                    <ToggleCard {...config} key={`2-${index}`} />
                  ) : (
                    <InputField {...config} key={`2-${index}`} />
                  )
                )}
              </>
            )}
          </section>
          <section className="pb-[40px] mb-[140px] mt-[24px]">
            <p
              className="typography-h500 text-n-900 mb-[24px]"
              id="recognition-section"
            >
              {setBothLocale('Recognition & Feedback')}
            </p>
            {listRecognition.map((config, index) =>
              config.element === 'toggle' ? (
                <ToggleCard {...config} key={`3-${index}`} />
              ) : config.element === 'input' ? (
                <InputField
                  {...config}
                  className="w-[240px] mt-[16px]"
                  key={`3-${index}`}
                />
              ) : (
                <DropdownOrganization {...config} key={`3-${index}`} />
              )
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default Behavior;

import { getNumberLocale, getObjectiveLocale } from "utils/HelperUtils";

export const _setStateHelpers = ({ conditionDoesntHaveConfigComponent, conditionConfigMinOnlyNoValidate, conditionConfigMinOnlyValidateMin, conditionConfigMaxOnlyNoValidate, conditionConfigMaxOnlyValidateMax, conditionConfigMinMaxNoValidate, conditionConfigMinMaxValidateMin, conditionConfigMinMaxValidateMax }, setTopDesc, setMaxObjectives, setDescObjectives, setIsWarning, maxNumberOfObjectives, minNumberOfObjectives, totalObjectives) => {
    if (conditionDoesntHaveConfigComponent) {
        setTopDesc(getObjectiveLocale("Numbers of top parent created."))
    }

    if (conditionConfigMinOnlyNoValidate) {
        setTopDesc(getObjectiveLocale(`Process goal creation up to min [min_objective] goals`).replace(/\[min_objective]/g, getNumberLocale(minNumberOfObjectives)||0))
        setMaxObjectives(`/ ${getNumberLocale(getNumberLocale(minNumberOfObjectives))}`)
    }

    if (conditionConfigMinOnlyValidateMin) {
        setTopDesc(getObjectiveLocale(`Process goal creation up to min [min_objective] goals`).replace(/\[min_objective]/g, getNumberLocale(minNumberOfObjectives)||0))
        setMaxObjectives(`/ ${getNumberLocale(minNumberOfObjectives)}`)
        setDescObjectives(`Minimum ${getNumberLocale(minNumberOfObjectives) || 0} objective(s)`)
        setIsWarning(true)
    }

    if (conditionConfigMaxOnlyNoValidate) {
        setTopDesc(getObjectiveLocale(`Process goal creation up to max [max_objective] goals`).replace(/\[max_objective]/g, getNumberLocale(maxNumberOfObjectives)||0))
        setMaxObjectives(`/ ${getNumberLocale(maxNumberOfObjectives)}`)
        setDescObjectives(`${getNumberLocale(maxNumberOfObjectives) - getNumberLocale(totalObjectives)} quotas left`)
    }

    if (conditionConfigMaxOnlyValidateMax) {
        setTopDesc(getObjectiveLocale(`Process goal creation up to max [max_objective] goals`).replace(/\[max_objective]/g, getNumberLocale(maxNumberOfObjectives)||0))
        setMaxObjectives(`/ ${getNumberLocale(maxNumberOfObjectives) || 0}`)
        setDescObjectives(getObjectiveLocale(`Maximum [max_value] objective(s)`).replace(/\[max_value]/g, getNumberLocale(maxNumberOfObjectives) || 0))
        setIsWarning(true)
    }

    if (conditionConfigMinMaxNoValidate) {
        setTopDesc(getObjectiveLocale("Process goal creation up to [min_objective] / [max_objective] goals").replace(/\[min_objective]/g, getNumberLocale(minNumberOfObjectives)||0).replace(/\[max_objective]/g, getNumberLocale(maxNumberOfObjectives)||0))
        setMaxObjectives(`/ ${getNumberLocale(maxNumberOfObjectives) || 0}`)
        setDescObjectives(`${getNumberLocale(maxNumberOfObjectives) - getNumberLocale(totalObjectives)} quotas left`)
    }

    if (conditionConfigMinMaxValidateMin) {
        setTopDesc(getObjectiveLocale(`Process goal creation up to [min_objective] / [max_objective] goals`).replace(/\[min_objective]/g, getNumberLocale(minNumberOfObjectives) || 0).replace(/\[max_objective]/g, getNumberLocale(maxNumberOfObjectives) || 0))
        setMaxObjectives(`/ ${getNumberLocale(maxNumberOfObjectives) || 0}`)
        setDescObjectives(getObjectiveLocale(`Minimum [min_value] objective(s)`).replace(/\[min_value]/g, getNumberLocale(minNumberOfObjectives) || 0))
        setIsWarning(true)
    }

    if (conditionConfigMinMaxValidateMax) {
        setTopDesc(getObjectiveLocale(`Process goal creation up to [min_objective] / [max_objective] goals`).replace(/\[min_objective]/g, getNumberLocale(minNumberOfObjectives) || 0).replace(/\[max_objective]/g, getNumberLocale(maxNumberOfObjectives) || 0))
        setMaxObjectives(`/ ${getNumberLocale(maxNumberOfObjectives) || 0}`)
        setDescObjectives(getObjectiveLocale(`Maximum [max_value] objective(s)`).replace(/\[max_value]/g, getNumberLocale(maxNumberOfObjectives) || 0))
        setIsWarning(true)
    }
}

export const _conditionMinMaxObjectivesHelper = ({ maxObjectivesOn, minObjectivesOn, maxNumberOfObjectives, minNumberOfObjectives, totalObjectives }) => {
    const conditionDoesntHaveConfigComponent = !maxObjectivesOn && !minObjectivesOn
    const conditionConfigMinOnlyNoValidate = (!maxObjectivesOn && minObjectivesOn) && totalObjectives >= minNumberOfObjectives
    const conditionConfigMinOnlyValidateMin = (!maxObjectivesOn && minObjectivesOn) && totalObjectives < minNumberOfObjectives
    const conditionConfigMaxOnlyNoValidate = (maxObjectivesOn && !minObjectivesOn) && totalObjectives <= maxNumberOfObjectives
    const conditionConfigMaxOnlyValidateMax = (maxObjectivesOn && !minObjectivesOn) && totalObjectives > maxNumberOfObjectives
    const conditionConfigMinMaxNoValidate = (maxObjectivesOn && minObjectivesOn) && (minNumberOfObjectives <= totalObjectives && totalObjectives <= maxNumberOfObjectives)
    const conditionConfigMinMaxValidateMin = (maxObjectivesOn && minObjectivesOn) && (minNumberOfObjectives > totalObjectives && totalObjectives < maxNumberOfObjectives)
    const conditionConfigMinMaxValidateMax = (maxObjectivesOn && minObjectivesOn) && (minNumberOfObjectives < totalObjectives && totalObjectives > maxNumberOfObjectives)

    return {
        conditionDoesntHaveConfigComponent,
        conditionConfigMinOnlyNoValidate,
        conditionConfigMinOnlyValidateMin,
        conditionConfigMaxOnlyNoValidate,
        conditionConfigMaxOnlyValidateMax,
        conditionConfigMinMaxNoValidate,
        conditionConfigMinMaxValidateMin,
        conditionConfigMinMaxValidateMax
    }
}

export const _conditionMinMaxObjectiveTooltipMessageHelper = ({ maxObjectivesOn, minObjectivesOn, maxNumberOfObjectives, minNumberOfObjectives, totalObjectives }) => {
    let message = ""
    let warning = false
    if(minObjectivesOn){
        if(totalObjectives <= minNumberOfObjectives){
            message = `Minimum ${minNumberOfObjectives} Objectives`
            warning = true
        }
    }
    if(maxObjectivesOn){
        if(totalObjectives >= maxNumberOfObjectives){
            message = `Maximum ${maxNumberOfObjectives} Objectives`
            warning = true
        }else{
            message = `${maxNumberOfObjectives - totalObjectives} Quota(s) available`
            warning = false
        }
    }

    return {message: getObjectiveLocale(message), warning}
}



import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useFeeds } from 'context/FeedsContext';
import { useUser } from 'context/UserContext';
import { getCultureLocale } from 'utils/HelperUtils';

import Feeds from 'components/culture/Feeds';
import SVGIcon from 'components/shared/SVGIcon';

const SingleFeed = ({ groupDetail, isRefetch, eventOnClick }) => {
  const history = useHistory();
  const params = useParams();
  const { user } = useUser();
  const { setPage, setFilter } = useFeeds(
    groupDetail?.id,
    user?.id,
    params?.postId
  );
  const searchParams = new URLSearchParams(location.search);

  const backToPreviousSection = () => {
    const source = searchParams.get('source');
    const redirectUrl =
      source && source !== 'homepage'
        ? `/timeline/group/${source}`
        : '/timeline';
    history.replace(redirectUrl);
  };

  useEffect(() => {
    setFilter('single-feed');
    setPage(`single-${params?.postId}`);
  }, []);

  return (
    <div className="flex w-full justify-around">
      <div className="w-[560px]">
        <div
          className="flex cursor-pointer text-n-600 typography-button items-center mt-[24px] mb-[12px]"
          onClick={() => backToPreviousSection()}
        >
          <SVGIcon
            iconName="icon-keyboard_arrow_left"
            size="24"
            fillColor="var(--n-600)"
          />
          {getCultureLocale('Back')}
        </div>
        <Feeds
          widthWrapper="overflow-auto"
          page={`single-${params?.postId}`}
          groupDetail={groupDetail}
          isRefetch={isRefetch}
          eventOnClick={eventOnClick}
        />
      </div>
    </div>
  );
};

export default SingleFeed;

import React from 'react';

import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getPeriod } from 'utils/ObjectivesHelper';
import { useFilterMenuObjectives } from 'utils/ObjectivesHelper';

import ErrorBoundary from 'pages/ErrorBoundary';
import useLoadingStatus from 'src/hooks/useLoadingStatus';

import AdvancedFilter from './AdvancedFilter';

function AdvancedFilterObjective({
  isCompanyGoalCreator = true,
  defaultDateFilterOptions = null,
  useConfig,
  children,
  defaultFilter,
  filterOptions,
  group,
  gridArea,
  page,
  filterId = null,
  exceptFilterMenu,
  resultFilterModifier,
  ...rest
}) {
  const { config, user } = useUser();
  const { objectiveFilterOptions, defaultObjectiveSorting } = config;
  let dateFilterOptions = defaultDateFilterOptions || config.timeFilterOptions;

  let periodFilter = getPeriod(dateFilterOptions?.[0]);

  let filter = {
    ...(filterOptions.includes('search') && { q: null }),
    ...(filterOptions.includes('dateFilter') && {
      periodBegin: periodFilter?.periodBegin,
      periodEndBefore: periodFilter?.periodEndBefore
    }),
    ...(filterOptions.includes('sort') && {
      sortColumn: defaultObjectiveSorting?.sortColumn || 'due_date',
      sortDirection: defaultObjectiveSorting?.sortDirection || 'asc'
    }),
    ...(filterOptions.includes('menuFilter') && {
      state: [],
      phase: [],
      objectiveCategoryId: [],
      tags: [],
      assigner: [],
      type: [],
      integration: []
    }),
    ...(filterOptions.includes('switch') && {
      switch: ''
    }),
    isProject: false,
    isCompanyGoal: false,
    ...defaultFilter
  };

  const whichStatusReverse = {
    'At risk': 'bad',
    'Left behind': 'warning',
    'On track': 'good',
    Exceeded: 'exceed_expectation',
    'Not Updated': 'not_updated'
  };

  const setStatusChange = useLoadingStatus((state) => state?.setStatusChange);

  const filterReducer = (oldState, action) => {
    const { type, state } = action;
    switch (type) {
      case 'STATUS-OVERALL': {
        let status = whichStatusReverse[state];
        let newStatus = { status: [] };

        if (!oldState?.status?.includes(status)) {
          newStatus = { status: [status] };
          if (action.annual) {
            newStatus.periodBegin = dayjs().startOf('year').toISOString();
            newStatus.periodEndBefore = dayjs().endOf('year').toISOString();
          }
        }

        setStatusChange(true);
        return { ...oldState, ...newStatus };
      }
      case 'PHASE-OVERALL': {
        let phase = state;
        let newPhase;
        if (state === 'overdue') {
          newPhase = { overdue: '' };
          if (!oldState?.overdue) {
            newPhase.overdue = state;
          }
        } else {
          newPhase = { phase: [] };
          if (!oldState?.phase?.includes(phase)) {
            newPhase = { phase: [phase] };
          }
        }
        return { ...oldState, ...newPhase };
      }
      case 'SWITCH':
        delete oldState?.goals;
        switch (action.state) {
          case 'goal':
            return {
              ...oldState,
              switch: 'goal'
            };
          case 'task':
            return { ...oldState, switch: 'task' };
          case 'all':
            return {
              ...oldState,
              switch: 'all',
              isProject: false,
              isCompanyGoal: false
            };
          case 'project':
            return {
              ...oldState,
              switch: 'project',
              isProject: true,
              isCompanyGoal: false
            };
          case 'company-goal':
            return {
              ...oldState,
              switch: 'company-goal',
              isProject: false,
              isCompanyGoal: true
            };
          case 'team-goal':
            return {
              ...oldState,
              switch: 'team-goal'
            };
        }
        break;

      case 'RESET':
        return { ...state };
    }

    return { ...oldState, ...state };
  };

  let filterMenu = useFilterMenuObjectives({
    except: exceptFilterMenu,
    config,
    userId: user.id
  });

  let filteredMenu = objectiveFilterOptions[page];
  if (filteredMenu) {
    filterMenu = filterMenu.filter(({ option }) =>
      filteredMenu.includes(option)
    );
  }

  let switchOptions = [
    { id: 'all', name: getObjectiveLocale('All'), isVisible: true },
    {
      id: 'goal',
      name: getObjectiveLocale('Goal'),
      isVisible: config?.permissions?.goalSee
    },
    {
      id: 'task',
      name: getObjectiveLocale('Task'),
      isVisible: config?.permissions?.taskSee
    },
    {
      id: 'project',
      name: getObjectiveLocale('Project'),
      isVisible: config?.permissions?.projectSee
    },
    {
      id: 'company-goal',
      name: getObjectiveLocale('Company Goals'),
      isVisible:
        config?.permissions?.goalCompanySee &&
        page !== 'explorer' &&
        isCompanyGoalCreator
    },
    {
      id: 'team-goal',
      name: getObjectiveLocale('Team Goals'),
      isVisible: config?.permissions?.objectiveTeamSee
    }
  ];

  switchOptions = switchOptions.filter((option) => option.isVisible);

  const resultModifier = (filter) => {
    let assignee = filter?.owner?.assignee?.map(({ user }) => user?.name);
    let phase = filter?.phase?.map((status) =>
      status === 'running' ? 'On Going' : status
    );

    const whichStatus = {
      bad: getObjectiveLocale('At Risk'),
      warning: getObjectiveLocale('Left Behind'),
      good: getObjectiveLocale('On Track'),
      exceed_expectation: getObjectiveLocale('Exceeded'),
      not_updated: getObjectiveLocale('Not Updated')
    };

    let status = filter?.status?.map((progress) => whichStatus[progress]);

    let type = filter?.type?.map((type) => type.name);
    const result = {
      owner: [
        { directorate: filter?.owner?.directorate || [] },
        { department: filter?.owner?.department || [] },
        { division: filter?.owner?.division || [] },
        ...(assignee || []),
        filter?.owner?.ownerType
      ].filter(Boolean),
      phase: phase || [],
      overdue: filter?.overdue,
      status: status,
      reviewer: filter?.reviewer?.map(({ user }) => user?.name),
      follower: filter?.follower?.map(({ user }) => user?.name),
      alignment: filter.alignment || '',
      lastUpdated: [filter?.lastUpdated?.lastUpdatedType || []],
      label: filter?.label,
      type: type,
      integration: filter?.integration || []
    };

    return resultFilterModifier ? resultFilterModifier(result) : result;
  };

  return (
    <>
      <AdvancedFilter
        filterOptions={filterOptions}
        filterMenu={filterMenu}
        filterReducer={filterReducer}
        defaultFilter={filter}
        switchOptions={switchOptions}
        dateFilterOptions={dateFilterOptions}
        resultModifier={resultModifier}
        defaultGroup={group}
        gridArea={gridArea}
        noFilterDate={false}
        useConfig={useConfig}
        id={filterId}
        {...rest}
      >
        {children}
      </AdvancedFilter>
    </>
  );
}

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

const AdvancedFilterObjectiveComponent = React.memo(
  function AdvancedFilterObjectiveComponent(props) {
    return (
      <React.Suspense fallback={<div></div>}>
        <ErrorBoundary>
          <AdvancedFilterObjective {...props} />
        </ErrorBoundary>
      </React.Suspense>
    );
  },
  areEqual
);

export default AdvancedFilterObjectiveComponent;

import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';

import { getCycleProgress } from 'client/FormalReviewClient';
import { startPhase, stopPhase } from 'client/admin/CyclesClient';
import { useNetworkError } from 'context/NetworkErrorContext';
import { useReload } from 'context/ReloadContext';
import { getProgressTooltipComponent, phaseName } from 'utils/FormalReview';
import {
  capitalizeSentence as capitalize,
  getNumberLocale,
  getObjectiveLocale
} from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import Modal from 'components/shared/modal/Modal';
import ProgressBarOverallProgress from 'components/shared/progressBar/ProgressBarOverallProgress';

import CycleSidebarToggleNotification from '../CycleSidebarToggleNotification';
import ModalReminderList from '../components/ModalReminderList';
import ModalTrackDetail from '../components/ModalTrackDetail';
import './phase-card-detail-cycle.scss';

const Interface = {
  title: PropTypes.string,
  startFrom: PropTypes.string,
  dependentOn: PropTypes.element,
  trackQty: PropTypes.number,
  notificationReminderQty: PropTypes.number,
  customClass: PropTypes.string,
  phase: PropTypes.object,
  trackConfigs: PropTypes.array,
  reminders: PropTypes.array,
  cycleData: PropTypes.object,
  _handleGetCyclesById: PropTypes.func
};

const DefaultValue = {
  title: 'Self Review',
  startFrom: '01 Dec 2020 - 20 Dec 2020',
  dependentOn: <></>,
  trackQty: 0,
  notificationReminderQty: 0,
  customClass: '',
  phase: {},
  trackConfigs: [],
  reminders: [],
  cycleData: {},
  _handleGetCyclesById: () => null
};

const PhaseCardDetailCycle = ({
  title,
  startFrom,
  dependentOn,
  trackQty,
  notificationReminderQty,
  customClass,
  phase,
  trackConfigs,
  reminders,
  cycleData,
  _handleGetCyclesById
}) => {
  const [modal, setModal] = useState(null);
  const [phaseDetail, setPhaseDetail] = useState(phase);
  const [isLoading, setIsLoading] = useState(true);
  const [phaseProgresses, setPhaseProgresses] = useState(0);
  const { reload, reloadAdminCycles } = useReload();
  const { pushError } = useNetworkError();

  const confirmPhase = async (type) => {
    const phaseAction = type === 'start' ? startPhase : stopPhase;
    const body = { type: phase.type };
    if (type === 'start') {
      (body.reminds_at = dayjs().toISOString()),
        (body.starting_email_subject = ''),
        (body.starting_email_body = '');
    }
    const { data, error } = await phaseAction(phase.cycleId, body);
    if (data) {
      setPhaseDetail((prev) => ({
        ...prev,
        state: type === 'start' ? 'in_progress' : 'done'
      }));
      reload({ reloadAdminCycles: !reloadAdminCycles });
      pushError({
        isSuccess: true,
        message: `Phase has successfully ${type}ed`
      });
    }
    setModal(null);
  };

  const handlePhase = (type) => {
    setModal({
      type,
      title: startCase(type) + 'ing Phase',
      description: `Do you want to ${startCase(type)} this ${phaseName(
        phaseDetail.type
      )} phase?`,
      action: () => confirmPhase(type)
    });
  };

  const _handleGetProgressPhase = async () => {
    const { data } = await getCycleProgress(phase.cycleId, phase.type, {
      userState: ['active', 'pending', 'deleted']
    });
    if (data) {
      setPhaseProgresses(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setPhaseDetail(phase);
  }, [phase]);

  useEffect(() => {
    _handleGetProgressPhase();
  }, []);

  return (
    <div className={`phase-card-detail-cycle bg-n-000 ${customClass}`}>
      <section className="top-section">
        <p className="typography-h500">
          {isLoading ? (
            <Shimmer height="14px" widthRandomness={0.25} circle />
          ) : (
            startCase(title)
          )}
        </p>
        <p className="typography-h400 flex mt-[4px]">
          <SVGIcon
            size="18"
            iconName="icon-calendar_today"
            fillColor="var(--n-600)"
          />
          <span className="ml-[10px] text-n-800">
            {isLoading ? (
              <Shimmer height="100%" widthRandomness={0.25} circle />
            ) : (
              startFrom
            )}
          </span>
        </p>
        <p className="typography-h100 text-n-600 mt-[8px] dependent-on">
          {getObjectiveLocale('Dependent on')}{' '}
        </p>
        <p className="typography-h400 text-n-800 min-w-[800px]">
          {isLoading ? (
            <Shimmer height="14px" widthRandomness={0.25} circle />
          ) : (
            dependentOn
          )}
        </p>
        <p className="typography-h400 text-base-600 mt-[8px]">
          {isLoading ? (
            <Shimmer height="14px" widthRandomness={0.25} circle />
          ) : trackQty === 0 &&
            notificationReminderQty === 0 &&
            phase?.type !== 'peer_selection' &&
            phase?.type !== 'calibration' ? (
            <span className="text-r-600 typography-paragraph">
              {getObjectiveLocale(
                'No track selected, please add a track on set phase setting'
              )}
            </span>
          ) : (
            phase?.type !== 'peer_selection' &&
            phase?.type !== 'calibration' && (
              <>
                <span
                  className="cursor-pointer hover-track"
                  onClick={() => trackQty && setModal('tracks')}
                >
                  {getNumberLocale(trackQty)} {getObjectiveLocale('track(s)')}
                </span>{' '}
                ·&nbsp;
                <span
                  className="cursor-pointer hover-track"
                  onClick={() =>
                    notificationReminderQty && setModal('reminders')
                  }
                >
                  {getNumberLocale(notificationReminderQty)}{' '}
                  {getObjectiveLocale('Notification Reminders')}
                </span>
              </>
            )
          )}
        </p>

        {phase?.type !== 'calibration' && (
          <div className="my-[8px]">
            <CycleSidebarToggleNotification
              cycleData={cycleData}
              phaseDetail={phaseDetail}
              phaseType={phase?.type}
              onSuccess={() => _handleGetCyclesById()}
            />
          </div>
        )}

        <div className="progress-bar-phase-card mb-[24px]">
          {isLoading ? (
            <Shimmer height="14px" width="100%" circle />
          ) : (
            <TooltipContainer
              show
              classContainer="cursor-pointer flex justify-between w-full"
              useMaxWidth={false}
              tooltipClass="!bg-n-000 shadow-overlay"
              tooltipContent={() =>
                getProgressTooltipComponent(phaseProgresses)
              }
            >
              <ProgressBarOverallProgress
                colorProgress="bg-g-600"
                widthProgress={parseInt(phaseProgresses?.progressPercentage)}
                containerClassName="w-[342px] h-[16px] overflow-hidden rounded-full"
                height={20}
              />
              <p className="typography-h400">
                {getNumberLocale(parseInt(phaseProgresses?.progressPercentage))}
                %
              </p>
            </TooltipContainer>
          )}
        </div>
        {phaseDetail.state === 'draft' ? (
          <Button.Secondary
            disabled={
              trackQty === 0 &&
              phase?.type !== 'peer_selection' &&
              phase?.type !== 'calibration'
            }
            fullWidth={true}
            onClick={() => handlePhase('start')}
          >
            {getObjectiveLocale('Start Phase')}
          </Button.Secondary>
        ) : phaseDetail.state === 'in_progress' ? (
          <Button.Secondary
            disabled={false}
            customClass="w-full"
            onClick={() => handlePhase('end')}
          >
            {getObjectiveLocale('End Phase')}
          </Button.Secondary>
        ) : (
          <Button.Secondary
            disabled={true}
            customClass="w-full"
            onClick={() => handlePhase('end')}
          >
            {getObjectiveLocale('Ended Phase')}
          </Button.Secondary>
        )}
      </section>
      {modal?.type && (
        <Modal
          className="max-w-[400px]"
          eventOnClickClose={() => setModal(null)}
          withCloseIcon={true}
          withPrimaryBtn={{ title: 'Yes', onClick: () => modal.action() }}
          withSecondaryBtn={{ title: 'No', onClick: () => setModal(null) }}
          withFooter={true}
          {...modal}
        />
      )}
      {modal == 'tracks' && (
        <ModalTrackDetail
          title={capitalize(title)}
          trackConfigs={trackConfigs}
          onCloseModal={() => setModal(null)}
        />
      )}
      {modal == 'reminders' && (
        <ModalReminderList
          reminders={reminders}
          phase={phase.type}
          cycleData={cycleData}
          setModal={() => setModal(null)}
        />
      )}
    </div>
  );
};

PhaseCardDetailCycle.propTypes = Interface;
PhaseCardDetailCycle.defaultProps = DefaultValue;

export default PhaseCardDetailCycle;

import React, { useEffect, useRef, useState } from 'react';

import { useColleague } from 'context/ColleagueContext';
import useClickOutside from 'hooks/useClickOutside';
import useDebounce from 'hooks/useDebounce';
import Config from 'utils/Config';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';

import UserCard from 'components/formal-review/landing-page/UserCard';
import LoadingComponent from 'components/shared/LoadingComponent';
import Modal from 'components/shared/modal/Modal';

import SingleUserList from '../shared/SingleUserList';

const GeneratePeerFeedbackModal = ({
  target,
  onHandleCloseModal,
  onHandleSubmitModal
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [onLoadMore, setLoadMore] = useState(false);

  const [search, setSearch] = useState('');
  const debouncedSearchValue = useDebounce(search, 1000);

  const { colleague, hasMore, getColleagueData, appendColleagueData } =
    useColleague();

  const ref = useRef();
  useClickOutside(ref, () => {
    setShowPopup(false);
  });

  let buttonPrimaryAction = {
    title: getObjectiveLocale('Submit'),
    dataCy: 'submit-button',
    onClick: () => onHandleSubmitModal(selectedUsers)
  };

  const onScroll = (e) => {
    const target = e.target;
    const loadMore = async () => {
      const exclude = selectedUsers.map((user) => user.id);
      let query = {
        exclude: exclude
      };
      if (search != '') {
        query.q = search;
      }

      setLoadMore(true);
      await appendColleagueData(query);
      setLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 200, () => {
        loadMore();
      });
    }
  };

  const searchColleague = async (value) => {
    setSearch(value);
  };

  const onSelect = (user) => {
    let newSelectedUsers = [...selectedUsers];
    newSelectedUsers.push(user);
    setSelectedUsers(newSelectedUsers);
    document.getElementById('input-select-peer').value = '';
    setShowPopup(false);
  };

  const onRemoveUser = (user) => {
    const newSelectedUsers = selectedUsers.filter((prevSelected) => {
      return prevSelected.id != user.id;
    });

    setSelectedUsers(newSelectedUsers);
  };

  useEffect(() => {
    const _getColleagueData = async () => {
      setIsLoading(true);
      const exclude = selectedUsers.map((user) => user.id);
      await getColleagueData({
        q: search ? search : null,
        exclude: [...exclude, target.id]
      });
      setIsLoading(false);
    };

    _getColleagueData();
  }, [debouncedSearchValue, selectedUsers]);

  return (
    <Modal
      title={getObjectiveLocale('Peer for') + ' ' + target.name}
      eventOnClickClose={onHandleCloseModal}
      className="select-peers-formal-review peer-selection-modal max-h-[560px]"
      footerClass="h-[84px]"
      withPrimaryBtn={buttonPrimaryAction}
      withSecondaryBtn={{
        title: getObjectiveLocale('Cancel'),
        dataCy: 'cancel-submit-button',
        onClick: () => onHandleCloseModal()
      }}
    >
      <div className="modal-body my-[4px]">
        <>
          <div className="typography-h100 text-n-800 uppercase mt-[4px]">
            {getObjectiveLocale('Select Peers')}
          </div>
          <div
            ref={ref}
            className={`peer-search-container ${
              selectedUsers.length === 0 ? 'mb-[0px]' : ''
            }`}
          >
            <div className="search-container relative">
              <img src={Config.asset.v2.icoDefaultUser} />
              <input
                id="input-select-peer"
                data-cy="search-peers"
                placeholder={getObjectiveLocale('Search Employee')}
                onChange={(e) => searchColleague(e.target.value)}
                onFocus={() => setShowPopup(true)}
              />
            </div>
            {showPopup == true && (
              <div className="search-popup" onScroll={(e) => onScroll(e)}>
                {isLoading && <LoadingComponent />}
                {!isLoading && colleague.length > 0 && (
                  <>
                    <div className="typography-h100 text-n-600 h-[32px] px-[16px] flex items-center">
                      Suggested
                    </div>
                    {colleague.map((user, index) => {
                      return (
                        <SingleUserList
                          key={index}
                          user={user}
                          onClick={onSelect}
                        />
                      );
                    })}
                  </>
                )}
                {!isLoading && colleague.length == 0 && (
                  <div className="flex flex items-center justify-center p-12">
                    <p className="typography-h500 text-n-800 typography-secondary">
                      {getObjectiveLocale('Peer not found')}
                    </p>
                  </div>
                )}
                {!isLoading && onLoadMore && <LoadingComponent />}
              </div>
            )}
          </div>
        </>
        <div className="peer-selection-selected-user-container">
          <div className="peer-selection-text-container h-[36px] flex items-center">
            <span className="typography-h200 text-n-800 uppercase">
              {getObjectiveLocale('Peer Selection')}
            </span>
          </div>
          <div className="selected-user-container">
            {selectedUsers.map((user, index) => {
              return (
                <UserCard
                  key={index}
                  user={user}
                  onRemove={onRemoveUser}
                  selectedUsers={selectedUsers}
                />
              );
            })}
            {selectedUsers.length == 0 && (
              <div className="flex flex items-center justify-center p-12">
                <p className="typography-h500 text-n-800 typography-secondary">
                  {getObjectiveLocale("You haven't selected any peers yet")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GeneratePeerFeedbackModal;

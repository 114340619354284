import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { patchBehaviors, postBehaviors } from 'client/adminClient.js';
import { useValueCompetency } from 'context/admin/ValueCompetencyContext';

export const Textarea = ({
  isCardActive,
  setIsCardActive,
  dataKeyBehavior,
  setDataKeyBehavior,
  levelBasic,
  setLevelBasic,
  orderLevel,
  className,
  additionalClassName,
  value,
  valueId,
  dictionary,
  type,
  placeholder,
  action,
  _getKeyBehaviorData,
  hasKeyBehaviors,
  dataCy
}) => {
  const { pathname } = useLocation();
  const {
    showNotificationMessage,
    onChangeValueOrCompetency,
    resetInputState,
    showWarningMessage,
    compentencyOrValue,
    getValueOrCompetencyData,
    setShowInlineCreate,
    isChooseImageOpen,
    bodyDictionaryOrModel,
    showInlineCreate,
    setIsChooseImageOpen,
    currentEventTarget,
    configValueCompetency
  } = useValueCompetency();
  const config = configValueCompetency;
  const [isTextAreaActive, setIsTextAreaActive] = useState(null);

  useEffect(() => {
    if (action == 'edit') {
      document.querySelectorAll(`[id^="${type}-${valueId}"]`).forEach((el) => {
        el.style.height = el.scrollHeight + 'px';
      });
    }
  }, [compentencyOrValue[type]]);

  useEffect(() => {
    if (isCardActive) {
      setIsTextAreaActive(valueId);
    } else {
      setIsTextAreaActive(null);
    }
  }, [isCardActive]);

  useEffect(() => {
    if (action == 'edit') {
      document.getElementById(`${type}-${valueId}`).value = value;
    }
  }, [value]);

  const _onKeyDown = (e) => {
    const parentName =
      compentencyOrValue.inputName || compentencyOrValue.keyBehaviorName;
    if (e.key === 'Tab') {
      e.preventDefault();
    }

    if (action == 'edit' && (e.key == 'Enter' || e.key == 'Escape')) {
      e.preventDefault();
      setLevelBasic && setLevelBasic({ isActive: false });
      e.target.blur();
    }

    if (action == 'create') {
      if (e.key == 'Escape') {
        setShowInlineCreate(null);
        setIsChooseImageOpen(null);
        setLevelBasic && setLevelBasic({ isActive: false });
      }

      if (e.key == 'Enter') {
        if (type == 'inputName') {
          e.preventDefault();
          if (parentName) {
            document.getElementById('inputDesc-00').focus();
          }
        }

        if (type == `keyBehaviorName`) {
          e.preventDefault();
          if (parentName) {
            document.getElementById(`keyBehaviorDesc-${valueId}`).focus();
          }
        }

        if (type == 'inputDesc' || type == 'keyBehaviorDesc') {
          e.target.blur();
        }
      }
    }
  };

  const _onFocus = (value) => {
    onChangeValueOrCompetency(value, type);

    if (action == 'edit') {
      setIsCardActive(valueId);
    }
  };

  const onHandleChangeDetails = (e) => {
    const el = e.currentTarget;
    el.style.cssText = `height: ${el.scrollHeight}px`;
    if (onChangeValueOrCompetency) {
      let value = e.currentTarget.value;
      onChangeValueOrCompetency(value, type);
    }
  };

  const setStateKeyBehavior = (value) => {
    const activeIndex = dataKeyBehavior.findIndex((data) => valueId == data.id);
    dataKeyBehavior[activeIndex].title = value.title;
    dataKeyBehavior[activeIndex].description = value.description;

    setDataKeyBehavior(dataKeyBehavior);
  };

  const _getValueOrCompetencyData = async () => {
    await getValueOrCompetencyData(pathname);
  };

  const handleCreateValueOrCompetency = async (e) => {
    const parentName =
      compentencyOrValue.inputName || compentencyOrValue.keyBehaviorName;
    const parentDesc =
      compentencyOrValue.inputDesc || compentencyOrValue.keyBehaviorDesc;
    const nextTarget = isChooseImageOpen;

    const body = bodyDictionaryOrModel(
      parentName,
      parentDesc,
      pathname,
      type,
      valueId
    );

    const condName =
      event.relatedTarget?.id == 'inputName-00' ||
      event.relatedTarget?.id == `keyBehaviorName-${showInlineCreate}`;
    const condDesc =
      event.relatedTarget?.id == 'inputDesc-00' ||
      event.relatedTarget?.id == `keyBehaviorDesc-${showInlineCreate}`;
    const condImg = currentEventTarget == 'imagePlace-00';

    if (config?.behaviorsFramework == 'proficiency_levels') {
      // order level accept 0 (basic level) haygroup config
      if (
        orderLevel !== undefined &&
        orderLevel !== null &&
        !levelBasic?.isActive
      ) {
        body.orderLevel = orderLevel + 1;
      } else if (levelBasic?.isActive) {
        body.orderLevel = 0;
      }
    }

    if (
      ((e.relatedTarget && event.key == 'Enter') ||
        condName ||
        condDesc ||
        condImg) &&
      parentName
    )
      return;
    else {
      if (parentName) {
        const { data } = await postBehaviors(body);
        if (data) {
          if (!hasKeyBehaviors) {
            await _getValueOrCompetencyData();
          }

          if (type.includes('keyBehavior')) {
            await _getKeyBehaviorData(valueId, 'refresh');
            if (setLevelBasic && levelBasic?.isActive) {
              dictionary.levels = [...(dictionary?.levels || []), 0];
            } else {
              dictionary.levels = [
                ...(dictionary?.levels || []),
                orderLevel + 1
              ];
            }
            setLevelBasic({ isActive: false });
          }
          setShowInlineCreate(null);
          showNotificationMessage('Created Successfully!');
          resetInputState();
        }
      }
    }

    if (!parentName && nextTarget != '00') {
      setShowInlineCreate(null);
      setLevelBasic && setLevelBasic({ isActive: false });
    }
  };

  const updateCompetency = async () => {
    setIsCardActive(null);
    resetInputState();
    let body = {};

    // order level accept 0 (basic level) haygroup config
    if (
      config?.behaviorsFramework == 'proficiency_levels' &&
      orderLevel !== undefined &&
      orderLevel !== null
    ) {
      body.orderLevel = orderLevel;
    }

    const updateName = () => {
      if (
        compentencyOrValue['inputName'] == '' ||
        compentencyOrValue['keyBehaviorName'] == ''
      ) {
        showWarningMessage("Can't be empty");
        document.getElementById(`${type}-${valueId}`).focus();
      } else {
        body = {
          ...body,
          title:
            compentencyOrValue['inputName'] ||
            compentencyOrValue['keyBehaviorName']
        };
        _patchBehaviors(body);
      }
    };

    const updateDesc = () => {
      body = {
        ...body,
        description:
          compentencyOrValue['inputDesc'] ||
          compentencyOrValue['keyBehaviorDesc']
      };
      _patchBehaviors(body);
    };

    if (type.includes('Name')) {
      updateName();
    } else {
      updateDesc();
    }
  };

  const _patchBehaviors = async (body) => {
    const { data, error } = await patchBehaviors(valueId, body);

    if (data && type.includes('keyBehavior') && action == 'edit') {
      setStateKeyBehavior(data);
    }

    if (error) {
      if (error.code == 422) {
        showWarningMessage('Name has already been taken');
        document.getElementById(`${type}-${valueId}`).focus();
        setIsCardActive(valueId);
      }
    }

    setLevelBasic && setLevelBasic({ isActive: false });
  };

  if (action == 'edit') {
    let fixClass = `input-inline input-inline-edit typography-paragraph
      ${isTextAreaActive == valueId ? className : ''}
      ${additionalClassName || ''}
    `;
    return (
      <textarea
        data-cy={`${type}-${valueId}`}
        maxLength="500"
        placeholder={placeholder}
        spellCheck={false}
        onKeyDown={(e) => _onKeyDown(e)}
        onFocus={(e) => _onFocus(e.target.value)}
        onBlur={() => updateCompetency()}
        defaultValue={value}
        cols="25"
        className={fixClass}
        id={`${type}-${valueId}`}
        onChange={(e) => onHandleChangeDetails(e)}
      />
    );
  }

  if (action == 'create') {
    return (
      <textarea
        autoFocus={`${type}-${valueId}` == 'inputName-00'}
        data-cy={dataCy}
        maxLength="500"
        placeholder={placeholder}
        spellCheck={false}
        cols="25"
        className={`input-inline input-inline-create typography-paragraph ${additionalClassName}`}
        id={`${type}-${valueId}`}
        onBlur={(e) => handleCreateValueOrCompetency(e)}
        onKeyDown={(e) => _onKeyDown(e)}
        onFocus={(e) => _onFocus(e.target.value)}
        onChange={(e) => onHandleChangeDetails(e)}
      />
    );
  }
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import produce, { setAutoFreeze } from 'immer';
import cloneDeep from 'lodash/cloneDeep';

import {
  getFormalReviewAssignment,
  getFormalReviewGoalsScoring,
  getFormalReviewTasksScoring,
  setAnswersScoreAttributesScoring,
  setFormalReviewAnswer,
  updateTrackData
} from 'client/FormalReviewClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import useDebounce from 'hooks/useDebounce';

//CREATE CONTEXT
const FormalReviewContext = React.createContext([{}, function () {}]);

//PROVIDER
function FormalReviewProvider(props) {
  //INITIAL STATE
  const initialState = {
    assignmentId: '',
    status: '',
    cycle: {},
    involvedUser: {
      actor: {},
      target: {}
    },
    phase: {},
    currentTrack: '',
    currentTrackId: '',
    tracks: [],
    trackLoading: false,
    assignmentState: '',
    ready: false,
    lastUpdate: '',
    objectId: 0,
    title: '',
    activeEvidence: 'FormalReview',
    activeTypeTab: 'strength',
    overlayRightSidebarData: {},

    // goals scoring
    listScoring: {},

    // review aspects
    sliderLoading: false,

    // as a flag to determine that answers & comment data need to be synced
    isPrefillCompleted: false,

    error: {}
  };

  const [state, setState] = React.useState(initialState);
  setAutoFreeze(false);
  const immerSetState = (newState) =>
    setState((currentState) => produce(currentState, newState));
  const contextValue = [state, immerSetState];

  return <FormalReviewContext.Provider value={contextValue} {...props} />;
}

//MUTATION
function useFormalReview() {
  const [newAnswer, setNewAnswer] = useState(null);
  const debouncedAnswer = useDebounce(newAnswer, 1000);
  const history = useHistory();

  const { invalidateQueries } = useRefetchQuery();

  const [state, immerSetState] = React.useContext(FormalReviewContext);

  const getSingleTrack = (identifier) => {
    const { tracks } = state || {};

    const isNumber = !isNaN(identifier);
    const currentTrack = tracks?.find((track) => {
      return isNumber ? track.id == identifier : track.trackType == identifier;
    });

    return currentTrack ? currentTrack : tracks[0];
  };

  const getRestructuredAnswers = (answers) => {
    const copyAnswers = cloneDeep(answers);

    let restructuredAnswers = copyAnswers.map((answer) => {
      delete answer?.actor;
      delete answer?.updatedAt;
      return answer;
    });

    return restructuredAnswers;
  };

  const setAnswers = (assignmentId, trackId, trackType, answerParams) => {
    return setFormalReviewAnswer(
      assignmentId,
      trackId,
      trackType,
      answerParams,
      trackType === 'general_questionnaire' ? 'questionnaire' : null
    );
  };

  const setReviewAspsetAnswers = (
    assignmentId,
    trackId,
    answerParams,
    reviewAspectId
  ) => {
    return setAnswersScoreAttributesScoring(
      assignmentId,
      trackId,
      answerParams,
      reviewAspectId
    );
  };

  const getSingleFormalReview = async (assignmentId, isRefetch = false) => {
    const { data, error } = await getFormalReviewAssignment(assignmentId);
    if (error) {
      setTimeout(() => {
        location.replace('/appraisals');
      }, 1500);
      return;
    }

    const { tracks } = data || {};

    if (tracks?.length > 0) {
      tracks?.forEach((track) => {
        if (track?.state === 'done') {
          invalidateQueries([
            'assignments',
            data?.formalReviewCycle?.id,
            data?.formalReviewPhase?.type
          ]);
          invalidateQueries([
            'progress',
            data?.formalReviewCycle?.id,
            data?.formalReviewPhase?.type
          ]);
          invalidateQueries(['notification', 'formal-review']);
        }
      });
    }

    // if assignment is locked and assignment is submitted, then redirect to result page
    if (
      data?.state == 'done' &&
      data?.formalReviewCycle?.lockSubmittedAssignment
    ) {
      history.replace(
        `/appraisals/cycle/${data?.formalReviewCycle?.id}/assignment/${data.id}`
      );
    }

    const trackWithPrefill = tracks?.filter(
      (track) =>
        track?.trackConfig?.autoFill &&
        track?.trackConfig?.suggestionSource !== 'previous_cycle'
    );

    // only need to run prefill once when we first open assignment
    if (!state.ready) {
      trackWithPrefill?.map((track, index) => {
        const isLastIndex = trackWithPrefill?.length - 1 === index;
        if (track.trackType != 'review_aspects_scoring') {
          prefillAnswers(data, track, index, isLastIndex);
        } else {
          prefillReviewAspectAnswers(data, track, index, isLastIndex);
        }
      });
    }

    immerSetState((draft) => {
      draft.assignmentId = data?.id;
      draft.cycle = data?.formalReviewCycle;
      draft.involvedUser = { actor: data?.actor, target: data?.target };
      (draft.targetPlacementId = data.targetPlacementId),
        (draft.phase = data?.formalReviewPhase);
      draft.tracks = data?.tracks || [];
      draft.assignmentState = data?.state;
      draft.ready = true;
      (draft.error = error), (draft.endsAt = data?.endsAt);
      draft.formalReviewPhase = data?.formalReviewPhase;
      draft.lastSubmit = data?.lastSubmit;

      if (!isRefetch) {
        draft.currentTrack =
          state.currentTrack == '' ? data?.currentTrack : state.currentTrack;
        draft.currentTrackId =
          state.currentTrackId == ''
            ? data?.currentTrackId
            : state.currentTrackId;
        draft.currentTrackConfigId = getSingleTrack(
          data?.currentTrackId
        )?.trackConfigId;
      }
    });
  };

  const prefillAnswers = async (assignmentData, track, index, isLastIndex) => {
    let isSuccessFillTrackAnswers = false;
    let isSuccessFillAdditionalQuestionAnswers = false;
    let isRequestedToUpdateTrackAnswers = false;
    let isRequestedToUpdateAdditionalQuestionAnswers = false;

    // If there is no 'answers' AND there is Prefill -> answers = Prefill + setAnswer (Hit API)
    if (track && track?.answers?.length === 0 && track?.preFill?.length > 0) {
      immerSetState((draft) => {
        draft.status = 'Saving...';
      });
      isRequestedToUpdateTrackAnswers = true;

      const restructuredAnswers = getRestructuredAnswers(track?.preFill);

      assignmentData.tracks[index].answers = restructuredAnswers;

      let answerParams = {
        answers: restructuredAnswers
      };

      if (
        track.trackType == 'values_scoring' ||
        track.trackType == 'competencies_scoring'
      ) {
        answerParams['average'] = 0; // Set to 0 deliberately, Back-End re-calculate the average answer value
      }

      const { data, isSuccess } = await setAnswers(
        assignmentData.id,
        track?.id,
        track.trackType,
        answerParams
      );

      if (data && isSuccess) {
        isSuccessFillTrackAnswers = true;
        if (track.trackType == 'goals_scoring') {
          getGoalsScoringData(assignmentData.id);
        } else if (track.trackType == 'tasks_scoring') {
          getTasksScoringData(assignmentData.id);
        }

        assignmentData.tracks[index].state = 'done'; //update track state
      }
    }

    // Prefill for additional questions / general_questionnaire track
    if (
      track &&
      track?.questionnaireAnswers?.length === 0 &&
      track?.questionnairePreFill?.length > 0
    ) {
      immerSetState((draft) => {
        draft.status = 'Saving...';
      });
      isRequestedToUpdateAdditionalQuestionAnswers = true;

      const restructuredAnswers = getRestructuredAnswers(
        track?.questionnairePreFill
      );

      assignmentData.tracks[index].questionnaireAnswers = restructuredAnswers;

      let answerParams = {
        answers: restructuredAnswers
      };

      const { isSuccess } = await setFormalReviewAnswer(
        assignmentData.id,
        track?.id,
        track.trackType,
        answerParams,
        'questionnaire'
      );

      if (isSuccess) {
        isSuccessFillAdditionalQuestionAnswers = true;
      }
    }

    // refetch assignment
    if (
      isRequestedToUpdateTrackAnswers ||
      isRequestedToUpdateAdditionalQuestionAnswers
    ) {
      if (
        (isSuccessFillTrackAnswers || isSuccessFillAdditionalQuestionAnswers) &&
        isLastIndex
      ) {
        immerSetState((draft) => {
          (draft.lastUpdate = Date.now()), (draft.status = 'Saved');
        });
      } else {
        immerSetState((draft) => {
          draft.status = 'Saving Failed!';
        });
      }
    }
  };

  const prefillReviewAspectAnswers = async (
    assignmentData,
    track,
    index,
    isLastIndex
  ) => {
    let isSuccessFillTrackAnswers = false;
    let isSuccessFillAdditionalQuestionAnswers = false;
    let isRequestedToUpdateTrackAnswers = false;
    let isRequestedToUpdateAdditionalQuestionAnswers = false;

    const reviewAspects = track.reviewAspects;

    // prefill main content
    for (let i = 0; i < reviewAspects.length; i++) {
      if (
        !reviewAspects[i].userAnswered &&
        reviewAspects[i]?.answers?.length === 0 &&
        reviewAspects[i].preFill.length > 0
      ) {
        isRequestedToUpdateTrackAnswers = true;

        immerSetState((draft) => {
          draft.status = 'Saving...';
        });

        const restructuredAnswers = getRestructuredAnswers(
          reviewAspects[i]?.preFill
        );

        let answerParams = {
          answers: restructuredAnswers
        };

        const { data, isSuccess } = await setReviewAspsetAnswers(
          assignmentData.id,
          track?.id,
          answerParams,
          reviewAspects[i].objects[0].id
        );
        if (isSuccess) {
          isSuccessFillTrackAnswers = true;
        } else {
          isSuccessFillTrackAnswers = false;
          break;
        }
      }
    }

    // prefill additional questionnaire
    if (
      track.questionnaireAnswers.length == 0 &&
      track.questionnairePreFill.length > 0
    ) {
      isRequestedToUpdateAdditionalQuestionAnswers = true;

      const restructuredAnswers = getRestructuredAnswers(
        track?.questionnairePreFill
      );

      assignmentData.tracks[index].questionnaireAnswers = restructuredAnswers;

      let answerParams = {
        answers: restructuredAnswers
      };

      const { isSuccess } = await setFormalReviewAnswer(
        assignmentData.id,
        track?.id,
        track.trackType,
        answerParams,
        'questionnaire'
      );

      if (isSuccess) {
        isSuccessFillAdditionalQuestionAnswers = true;
      }
    }

    if (
      isRequestedToUpdateTrackAnswers ||
      isRequestedToUpdateAdditionalQuestionAnswers
    ) {
      if (
        (isSuccessFillTrackAnswers || isSuccessFillAdditionalQuestionAnswers) &&
        isLastIndex
      ) {
        immerSetState((draft) => {
          (draft.lastUpdate = Date.now()), (draft.status = 'Saved');
        });
      } else {
        immerSetState((draft) => {
          draft.status = 'Saving Failed!';
        });
      }
    }
  };

  const renewPrefillAnswers = async (track) => {
    immerSetState((draft) => {
      draft.status = 'Saving...';
      draft.isPrefillCompleted = false;
    });

    let isSuccessFillTrackAnswers = false;
    let isSuccessFillAdditionalQuestionAnswers = false;

    // Renew track answers
    if (track?.suggestions?.length > 0) {
      const restructuredAnswers = getRestructuredAnswers(track?.suggestions);

      let answerParams = {
        answers: restructuredAnswers
      };

      const { isSuccess } = await setAnswers(
        state.assignmentId,
        track.id,
        track.trackType,
        answerParams
      );

      if (isSuccess) {
        isSuccessFillTrackAnswers = true;
        if (track.trackType == 'goals_scoring') {
          getGoalsScoringData(state.assignmentId);
        } else if (track.trackType == 'tasks_scoring') {
          getTasksScoringData(state.assignmentId);
        }
      }
    }

    // Renew answers for general questionnaire / additional questions
    if (track?.questionnaireSuggestion?.length > 0) {
      const restructuredAnswers = getRestructuredAnswers(
        track?.questionnaireSuggestion
      );

      let answerParams = {
        answers: restructuredAnswers
      };

      const { isSuccess } = await setFormalReviewAnswer(
        state.assignmentId,
        track.id,
        track.trackType,
        answerParams,
        'questionnaire'
      );

      if (isSuccess) {
        isSuccessFillAdditionalQuestionAnswers = true;
      }
    }

    // update saved prefill config
    if (
      !track?.savedPreFill &&
      !track?.trackConfig?.autoFill &&
      track?.trackConfig?.useSuggestion
    ) {
      await updateTrackData(track?.id, state.assignmentId, {
        savedPreFill: true
      });
    }

    // refetch assignment
    if (isSuccessFillTrackAnswers || isSuccessFillAdditionalQuestionAnswers) {
      await getSingleFormalReview(state.assignmentId);
      immerSetState((draft) => {
        draft.status = 'Saved';
        draft.isPrefillCompleted = true;
      });
    } else {
      immerSetState((draft) => {
        draft.status = 'Saving Failed!';
      });
    }
  };

  const renewAspectPrefillAnswer = async (track) => {
    immerSetState((draft) => {
      draft.status = 'Saving...';
      draft.isPrefillCompleted = false;
    });

    let isSuccessFillTrackAnswers = false;
    let isSuccessFillAdditionalQuestionAnswers = false;

    const reviewAspects = track.reviewAspects;

    // prefill main content
    for (let i = 0; i < reviewAspects.length; i++) {
      if (reviewAspects[i].preFill.length > 0) {
        const restructuredAnswers = getRestructuredAnswers(
          reviewAspects[i]?.preFill
        );

        let answerParams = {
          answers: restructuredAnswers
        };

        const { isSuccess } = await setReviewAspsetAnswers(
          state.assignmentId,
          track?.id,
          answerParams,
          reviewAspects[i].objects[0].id
        );
        if (isSuccess) {
          isSuccessFillTrackAnswers = true;
        } else {
          isSuccessFillTrackAnswers = false;
          break;
        }
      }
    }

    // Renew answers for general questionnaire / additional questions
    if (track?.questionnaireSuggestion?.length > 0) {
      const restructuredAnswers = getRestructuredAnswers(
        track?.questionnaireSuggestion
      );

      let answerParams = {
        answers: restructuredAnswers
      };

      const { isSuccess } = await setFormalReviewAnswer(
        state.assignmentId,
        track.id,
        track.trackType,
        answerParams,
        'questionnaire'
      );

      if (isSuccess) {
        isSuccessFillAdditionalQuestionAnswers = true;
      }
    }

    // update saved prefill config
    if (
      !track?.savedPreFill &&
      !track?.trackConfig?.autoFill &&
      track?.trackConfig?.useSuggestion
    ) {
      await updateTrackData(track?.id, state.assignmentId, {
        savedPreFill: true
      });
    }

    // refetch assignment
    if (isSuccessFillTrackAnswers || isSuccessFillAdditionalQuestionAnswers) {
      await getSingleFormalReview(state.assignmentId);
      immerSetState((draft) => {
        draft.status = 'Saved';
        draft.isPrefillCompleted = true;
      });
    } else {
      immerSetState((draft) => {
        draft.status = 'Saving Failed!';
      });
    }
  };

  const getGoalsScoringData = async (assignmentId) => {
    const { data } = await getFormalReviewGoalsScoring(assignmentId);
    immerSetState((draft) => {
      draft.goalsScoringData = data;
    });
  };

  const getTasksScoringData = async (assignmentId) => {
    const { data } = await getFormalReviewTasksScoring(assignmentId);
    immerSetState((draft) => {
      draft.tasksScoringData = data;
    });
  };

  const changeTrack = (trackId) => {
    // Why need timeout?
    // on input score which answers sent after onBlur
    // we need to ensure the onBlur is fired before changing track

    setTimeout(() => {
      immerSetState((draft) => {
        draft.trackLoading = true;
      });
    }, 100);

    setTimeout(() => {
      immerSetState((draft) => {
        draft.currentTrack = getSingleTrack(trackId)?.trackType;
        draft.currentTrackId = trackId;
        draft.currentTrackConfigId = getSingleTrack(trackId)?.trackConfigId;
        draft.objectId = 0;
        draft.activeEvidence = 'FormalReview';
        draft.activeTypeTab = 'strength';
        draft.trackLoading = false;
      });
    }, 400);
  };

  const setAnswerContext = async (
    trackType,
    answers,
    answerType,
    triggerOnSuccess
  ) => {
    setNewAnswer({ trackType, answers, answerType, triggerOnSuccess });
  };

  const setAspectAnswerContext = (
    trackId,
    reviewAspectId,
    answers,
    triggerOnSuccess
  ) => {
    setNewAnswer({ trackId, reviewAspectId, answers, triggerOnSuccess });
  };

  const updateAnswerContext = async (
    trackType,
    answers,
    answerType,
    triggerOnSuccess
  ) => {
    immerSetState((draft) => {
      draft.status = 'Saving...';
    });

    const { assignmentId, currentTrackId } = state;
    const updatedAnswers = cloneDeep(answers);
    updatedAnswers?.answers &&
      updatedAnswers?.answers?.map((answer) => delete answer.updatedAt);

    const { isSuccess } = await setFormalReviewAnswer(
      assignmentId,
      currentTrackId,
      trackType,
      updatedAnswers,
      answerType && answerType
    );

    if (isSuccess) {
      triggerOnSuccess && triggerOnSuccess();
      immerSetState((draft) => {
        draft.lastUpdate = Date.now();
        draft.status = 'Saved';
      });
    } else {
      immerSetState((draft) => {
        draft.lastUpdate = Date.now();
        draft.status = 'Saving Failed!';
      });
    }
  };

  const updateAspectAnswerContext = async (
    trackId,
    answers,
    reviewAspectId,
    triggerOnSuccess
  ) => {
    immerSetState((draft) => {
      draft.status = 'Saving...';
    });

    const { assignmentId } = state;

    const { isSuccess } = await setAnswersScoreAttributesScoring(
      assignmentId,
      trackId,
      answers,
      reviewAspectId
    );

    if (isSuccess) {
      triggerOnSuccess && triggerOnSuccess();
      immerSetState((draft) => {
        draft.status = 'Saved';
        draft.lastUpdate = Date.now();
      });
    } else {
      immerSetState((draft) => {
        draft.status = 'Saving Failed!';
        draft.lastUpdate = Date.now();
      });
    }
  };

  const setBehaviourEvidence = (objectId, title) => {
    immerSetState((draft) => {
      draft.objectId = objectId;
      draft.title = title;
      draft.activeEvidence = 'FormalReview';
      draft.activeTypeTab = 'strength';
    });
  };

  const setActiveEvidence = (name) => {
    immerSetState((draft) => {
      draft.activeEvidence = name;
    });
  };

  const setActiveTypeTab = (type) => {
    immerSetState((draft) => {
      draft.activeTypeTab = type;
    });
  };

  const setOverlayRightSidebarData = ({ name, description, data, loading }) => {
    immerSetState((draft) => {
      draft.overlayRightSidebarData.name = name;
      draft.overlayRightSidebarData.description = description;
      draft.overlayRightSidebarData.data = data;
      draft.overlayRightSidebarData.loading = loading;
    });
  };

  const setSliderLoading = (state) => {
    immerSetState((draft) => {
      draft.sliderLoading = state;
    });
  };

  const changeLastUpdate = () => {
    immerSetState((draft) => {
      draft.lastUpdate = Date.now();
    });
  };

  useEffect(() => {
    if (!newAnswer) return;

    const {
      trackType,
      answers,
      answerType,
      trackId,
      reviewAspectId,
      triggerOnSuccess
    } = newAnswer;

    if (!reviewAspectId) {
      updateAnswerContext(trackType, answers, answerType, triggerOnSuccess);
    } else {
      updateAspectAnswerContext(
        trackId,
        answers,
        reviewAspectId,
        triggerOnSuccess
      );
    }
  }, [debouncedAnswer]);

  return [
    state,
    {
      getSingleFormalReview,
      getGoalsScoringData,
      getTasksScoringData,
      getSingleTrack,
      changeTrack,
      setAnswerContext,
      setAspectAnswerContext,
      setBehaviourEvidence,
      setActiveEvidence,
      setActiveTypeTab,
      setOverlayRightSidebarData,
      renewPrefillAnswers,
      renewAspectPrefillAnswer,
      updateAspectAnswerContext,
      setSliderLoading,
      changeLastUpdate,
      updateAnswerContext
    }
  ];
}

export { FormalReviewProvider, useFormalReview };

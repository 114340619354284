import React from 'react';

import { useValueCompetency } from 'context/admin/ValueCompetencyContext';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';

import { Textarea } from './InlineTextarea';
import { ModalValuesImage } from './ValueCompetencyReuseableComponent';

const NewDictionaryCreateCard = ({
  pathname,
  chooseValueImage,
  handleChangeInputFile
}) => {
  const { isChooseImageOpen, setIsChooseImageOpen, currentTab } =
    useValueCompetency();

  return (
    <div className="new-dictionary-card on-activated py-[16px] flex justify-between items-start">
      <div className="w-[30%] pl-[28px] column-inline">
        <div className="flex relative">
          {pathname.includes('values') && (
            <AWSImage
              imageId={'imagePlace-00'}
              onMouseDown={() => setIsChooseImageOpen('00')}
              className="icon-value ml-[40px] relative top-[2px]"
              src={Config.asset.admin.defaultValueImg}
              dataCy="create-values-image"
            />
          )}

          <div className="ml-[12px]">
            <Textarea
              dataCy={`input-create-${currentTab}-name`}
              valueId="00"
              action="create"
              type="inputName"
              placeholder={`Input ${
                pathname.includes('competencies')
                  ? getObjectiveLocale('competency')
                  : getObjectiveLocale('Value').toLowerCase()
              } name`}
            />
          </div>

          {isChooseImageOpen == '00' && (
            <ModalValuesImage
              _onChange={handleChangeInputFile}
              _onClick={chooseValueImage}
              id="00"
              type="create"
            />
          )}
        </div>
      </div>

      <div className="w-1/2 column-inline">
        <Textarea
          dataCy={`input-create-${currentTab}-desc`}
          valueId="00"
          action="create"
          type="inputDesc"
          placeholder={`Input ${
            pathname.includes('competencies')
              ? getObjectiveLocale('competency')
              : getObjectiveLocale('Value').toLowerCase()
          } description`}
        />
      </div>

      <div className="w-[10%]" />
      <div className="w-[10%] pr-[16px] column-action" />
    </div>
  );
};

export default NewDictionaryCreateCard;

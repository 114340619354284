import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useQuery } from 'react-query';

import {
  getPhaseDetail,
  getReviewerRevieweePhase
} from 'client/FormalReviewClient';
import { getAssignmentsGroupByDate, phaseName } from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';

import ListObjectiveFormalReview from 'components/formal-review/landing-page/ListObjectiveFormalReview';
import ListObjectiveSkeleton from 'components/formal-review/landing-page/ListObjectiveSkeleton';
import PeerSelection from 'components/formal-review/landing-page/PeerSelection';
import ListEmptyState from 'src/components/shared/ListEmptyState';

import AssignmentInfo from './AssignmentInfo';

const Phase = ({
  phaseType,
  cycleName,
  cycleId,
  godView,
  statePhase,
  teamReview,
  feedbackType,
  cycleState,
  showDueDate,
  inProgressDeliverable,
  lockAnswerCycle,
  allowViewTargetResult,
  filter,
  dueDate
}) => {
  const [peerReviewData, setPeerReviewData] = useState(null);
  const isFetchCycle = useRef(null);

  const query = {
    assignmentState: filter['assignmentStatus']
  };

  const getPhase = () => {
    return getPhaseDetail(cycleId, phaseType, query);
  };

  let { data, isLoading } = useQuery(
    ['assignments', cycleId, phaseType, query],
    getPhase,
    {
      staleTime: 60000 // 1 minute
    }
  );

  let {
    assignments,
    approvalCount,
    minimumApprovalCount,
    allowLockedByManager,
    maximumPeerCountEnabled,
    maximumPeerSelectedEnabled,
    nominationCount
  } = data?.data || {};

  assignments = (assignments || []).sort((a, b) =>
    b.state > a.state ? 1 : a.state > b.state ? -1 : 0
  );

  const assignmentsGroupByDate = getAssignmentsGroupByDate(assignments);

  const getCycleDetail = useCallback(async () => {
    const { data, isSuccess } = await getReviewerRevieweePhase(cycleId);
    if (isSuccess) {
      const peerReview = data.phases.find(
        (phase) => phase.type == 'peer_review'
      );
      if (peerReview) {
        setPeerReviewData(peerReview);
      }
    }
  }, [cycleId]);

  useEffect(() => {
    if (isFetchCycle.current || phaseType != 'peer_selection') return;
    getCycleDetail();
    isFetchCycle.current = true;
  }, [getCycleDetail, phaseType]);

  return (
    <div className="mb-[24px] last:mb-[0px]">
      {isLoading && <ListObjectiveSkeleton />}

      {!isLoading &&
        assignments.length === 0 &&
        phaseType !== 'calibration' && (
          <ListEmptyState
            fullHeight
            emptyIcon="icon-no-result-found"
            title={getObjectiveLocale('No assignments match the filter')}
            titleClassname="typography-h600"
            subtitle={getObjectiveLocale(
              'Try adjusting your filter to find the assignment you’re looking for'
            )}
          />
        )}

      {!isLoading && (assignments.length > 0 || phaseType == 'calibration') && (
        <>
          <div className="flex justify-between mb-[8px]">
            <div className="flex">
              <span className="typography-h400 phase-type-text text-n-900 mr-[4px]">
                {getObjectiveLocale(phaseName(phaseType))}
              </span>
            </div>
          </div>
          <div className="listReviews-container">
            {/* Calibration */}
            {phaseType === 'calibration' && (
              <>
                <AssignmentInfo
                  assignments={assignments}
                  showDueDate={showDueDate}
                  dueDate={dueDate}
                  phaseType={phaseType}
                />

                <ListObjectiveFormalReview
                  statePhase={statePhase}
                  godView={godView}
                  phase={phaseType}
                  cycleName={cycleName}
                  cycleId={cycleId}
                  assignment={{ target: { name: 'Calibrate Your Team' } }} // dummy assignment
                  cycleState={cycleState}
                  inProgressDeliverable={inProgressDeliverable}
                />
              </>
            )}

            {/* Peer Selection */}
            {phaseType === 'peer_selection' && (
              <>
                {Object.keys(assignmentsGroupByDate).map(
                  (dueDateKey, index) => {
                    const assignments = assignmentsGroupByDate[dueDateKey];

                    return (
                      <div className="mb-[16px] last:mb-[0px]" key={index}>
                        <AssignmentInfo
                          assignments={assignments}
                          showDueDate={showDueDate}
                          dueDate={dueDateKey}
                          phaseType={phaseType}
                        />

                        {assignments?.map((assignment) => {
                          if (assignment.id) {
                            return (
                              <PeerSelection
                                key={assignment.id}
                                cycleId={cycleId}
                                assignment={assignment}
                                minimumApprovalCount={minimumApprovalCount}
                                approvalCount={approvalCount}
                                statePhase={statePhase}
                                allowLockedByManager={allowLockedByManager}
                                assignments={assignments}
                                maximumPeerCountEnabled={
                                  maximumPeerCountEnabled
                                }
                                maximumPeerSelectedEnabled={
                                  maximumPeerSelectedEnabled
                                }
                                peerReviewData={peerReviewData}
                                nominationCount={nominationCount}
                              />
                            );
                          }
                        })}
                      </div>
                    );
                  }
                )}
              </>
            )}

            {/* Render Assignments */}
            {phaseType !== 'peer_selection' && phaseType !== 'calibration' && (
              <>
                {Object.keys(assignmentsGroupByDate).map(
                  (dueDateKey, index) => {
                    const assignments = assignmentsGroupByDate[dueDateKey];

                    return (
                      <Fragment key={index}>
                        <AssignmentInfo
                          assignments={assignments}
                          showDueDate={showDueDate}
                          dueDate={dueDateKey}
                          phaseType={phaseType}
                        />

                        {assignments?.map((assignment) => {
                          return (
                            <ListObjectiveFormalReview
                              key={assignment.id}
                              statePhase={statePhase}
                              godView={godView}
                              phase={phaseType}
                              cycleName={cycleName}
                              cycleId={cycleId}
                              teamReview={teamReview}
                              feedbackType={feedbackType}
                              inProgressDeliverable={inProgressDeliverable}
                              assignment={assignment}
                              lockAnswerCycle={lockAnswerCycle}
                              allowViewTargetResult={allowViewTargetResult}
                              cycleState={cycleState}
                              dueDate={dueDateKey}
                            />
                          );
                        })}
                      </Fragment>
                    );
                  }
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Phase;

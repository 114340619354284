import React from 'react';

import { useUser } from 'context/UserContext';
import useModalObjectives from 'hooks/useModalObjectives';
import Config from 'utils/Config';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

import './SetWeight.scss';

function SetWeightComponent({ totalWeight, useSetWeight, useTopMessage }) {
  const { config } = useUser();
  const {
    maxObjectiveWeight,
    minObjectiveWeight,
    maxObjectiveWeightOn,
    minObjectiveWeightOn,
    maxSumOfObjectivesWeightPercentage,
    maxSumOfObjectivesWeightPercentageOn,
    maxSumOfObjectivesWeightType,
    maxSumOfObjectivesWeightTypeOn
  } = config.objectivesConfig;
  const isPercentage =
    config.objectiveWeightType === 'percentage' ? true : false;
  const maxSumOfObjectivesOnBasedOnCategory = isPercentage
    ? maxSumOfObjectivesWeightPercentageOn
    : maxSumOfObjectivesWeightTypeOn;
  const maxSumOfObjectivesBasedOnCategory = isPercentage
    ? maxSumOfObjectivesWeightPercentage
    : maxSumOfObjectivesWeightType;
  const isShowWarning = totalWeight > maxSumOfObjectivesBasedOnCategory;

  const getWarningMessage = () => {
    if (isPercentage) {
      if (maxObjectiveWeightOn && minObjectiveWeightOn) {
        return getObjectiveLocale(
          `Minimum weight for each objectives [min_value]% and maximum [max_value]%`
        )
          .replace(/\[min_value]/g, getNumberLocale(minObjectiveWeight))
          .replace(/\[max_value]/g, getNumberLocale(maxObjectiveWeight));
      } else if (maxObjectiveWeightOn && !minObjectiveWeightOn) {
        return getObjectiveLocale(
          `Maximum weight for each objectives [max_value]%`
        ).replace(/\[max_value]/g, getNumberLocale(maxObjectiveWeight));
      } else if (!maxObjectiveWeightOn && minObjectiveWeightOn) {
        return getObjectiveLocale(
          `Minimum weight for each objectives [min_value]%`
        ).replace(/\[min_value]/g, getNumberLocale(minObjectiveWeight));
      } else {
        return getObjectiveLocale(`Sum of objectives weight`);
      }
    } else {
      return getObjectiveLocale(`Sum of goal type weight`);
    }
  };
  const warningMessage = getWarningMessage();
  const propsModalAdjustWeigth = {
    maxSumOfObjectivesOnBasedOnCategory: maxSumOfObjectivesOnBasedOnCategory,
    maxSumOfObjectivesBasedOnCategory: maxSumOfObjectivesBasedOnCategory,
    isShowWarning: isShowWarning,
    isIndividual: false,
    className: 'show-adjust-weight-modal'
  };

  const showModalAdjustWeight = useModalObjectives((state) => state.showModal);
  return (
    <>
      <div className="total-weight-container">
        <p
          className="text-n-800 typography-h200 weight-title"
          data-cy="weight-title"
        >
          {getObjectiveLocale('Total Weight')}
        </p>
        {useTopMessage && (
          <p className="text-n-800 typography-h100" data-cy="weight-message">
            {warningMessage}
          </p>
        )}
        <div className="flex total-weight-detail mb-[5px]">
          {isShowWarning && maxSumOfObjectivesOnBasedOnCategory && (
            <SVGIcon
              size="16"
              fillColor="var(--y-600)"
              iconName="icon-warning"
            />
          )}
          <div className="flex flex-row items-end mr-[12px]">
            <p
              className={`${
                isShowWarning &&
                maxSumOfObjectivesOnBasedOnCategory &&
                'warning-color'
              } overall-value typography-h600`}
              data-cy="weight"
            >
              {getNumberLocale(totalWeight)}
            </p>
            <p className="overall-percentage typography-h400 mb-[0px] line-height-20">
              %
            </p>
          </div>
          <div
            className="set-weight flex items-center cursor-pointer"
            data-cy="adjust-weight"
            onClick={() =>
              showModalAdjustWeight({
                modalType: 'adjustWeight',
                props: propsModalAdjustWeigth
              })
            }
          >
            {useSetWeight && (
              <SVGIcon
                size="16"
                fillColor="var(--base-600)"
                iconName="icon-create"
              />
            )}
            {useSetWeight && (
              <p className="typography-link ml-[4px]">
                {getObjectiveLocale('Update weight').toLowerCase()}
              </p>
            )}
          </div>
        </div>
        {isShowWarning && maxSumOfObjectivesOnBasedOnCategory && (
          <div className="flex flex-row" data-cy="total-set-weight-message">
            <p className="typography-h100 text-n-600">
              {getObjectiveLocale('Must be')}{' '}
              <span className="typography-h200 text-n-800">
                {getNumberLocale(maxSumOfObjectivesBasedOnCategory)}
              </span>
              {`${isPercentage ? '%' : ''}`}
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default SetWeightComponent;

import React from 'react';

import { useToastContext } from 'context/ToastContext';
import { getExtraInformation, getObjectiveLocale } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import TruncateParagraph from 'components/shared/TruncateParagraph';

const UserCardSuggestion = ({ user, onSelect, maximumPeerSelectedEnabled }) => {
  const { addToast } = useToastContext();

  const profilePicture = user?.profilePicture || user?.image || null;
  const { totalAvailableQuota, remainingQuota } = user;

  const onClickHandler = () => {
    if (maximumPeerSelectedEnabled && remainingQuota == 0) {
      addToast({
        title: getObjectiveLocale('Cannot select user'),
        msg: getObjectiveLocale(
          'The following user has already been selected too many times. Please choose another person.'
        ),
        type: 'error'
      });
      return;
    }
    onSelect && onSelect(user);
  };

  return (
    <div
      className="px-[16px] flex flex-row justify-between items-center py-[8px] hover:bg-base-30024 cursor-pointer"
      onClick={onClickHandler}
    >
      <div className="flex flex-row">
        <Avatar
          src={profilePicture}
          name={user?.name}
          className="mr-[16px]"
          size={32}
        />
        <div className="flex flex-col w-[399px]">
          <TruncateParagraph
            position="bottom"
            useMaxWidth={true}
            className="typography-h400 text-n-900"
          >
            {user.name}
          </TruncateParagraph>
          <TruncateParagraph
            position="bottom"
            useMaxWidth={true}
            className="typography-h100 text-n-800"
          >
            {getExtraInformation(user?.title, user?.subtitle)}
          </TruncateParagraph>
        </div>
      </div>
      {maximumPeerSelectedEnabled && (
        <p className="typography-h200 text-n-800">
          {totalAvailableQuota - remainingQuota}/{totalAvailableQuota} quota
        </p>
      )}
    </div>
  );
};

export default UserCardSuggestion;

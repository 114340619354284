import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import LoadingComponent from 'components/shared/LoadingComponent';

import './UserTableInfo.scss';

const UserTableInfo = ({
  totalLength,
  resetFunction,
  setShowModal,
  excludedId,
  isSelectAll,
  handleSelectAll,
  listIdUser,
  totalSelectedUser,
  saveStatus,
  customClass = '',
  remindSelectedUser,
  disableSelectClearAll
}) => {
  let countUser;

  if (isSelectAll) {
    if (totalLength) {
      countUser = totalLength - excludedId?.length;
    } else {
      countUser = 'All';
    }
  } else {
    if (!isNaN(totalSelectedUser)) {
      countUser = totalSelectedUser;
    } else {
      countUser = listIdUser?.length;
    }
  }
  return (
    <div className={`container-user-table-info ${customClass}`}>
      <p className="px-[12px] flex items-center">
        {saveStatus === 'saving...' ? (
          <LoadingComponent className="mr-[4px]" hasText={false} />
        ) : (
          countUser
        )}
        &nbsp; {getObjectiveLocale('employees are selected')}
      </p>
      <Button.Tertiary
        onClick={() => setShowModal(true)}
        disabled={saveStatus === 'saving...'}
      >
        {getObjectiveLocale('See Employees')}
      </Button.Tertiary>
      <Button.Tertiary
        onClick={() => handleSelectAll()}
        disabled={disableSelectClearAll}
      >
        {getObjectiveLocale('Select All [total_users] Employees').replace(
          '[total_users]',
          totalLength ? totalLength : ''
        )}
      </Button.Tertiary>
      <Button.Tertiary
        onClick={() => resetFunction()}
        disabled={disableSelectClearAll}
      >
        {getObjectiveLocale('Clear All Selections')}
      </Button.Tertiary>
      {remindSelectedUser && (
        <Button.Tertiary
          onClick={() => remindSelectedUser()}
          datacy="remind-button"
        >
          {getObjectiveLocale('Remind Selected Employee')}
        </Button.Tertiary>
      )}
    </div>
  );
};

export default UserTableInfo;

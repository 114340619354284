import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import snakeCase from 'lodash/snakeCase';

import { getApprovalDetail } from 'client/ApprovalClient';
import { TeamActivitiesProvider } from 'context/team/TeamActivitiesContext';
import {
  TeamDetailProvider,
  useTeamDetail
} from 'context/team/TeamDetailContext';
import { TeamSquadProvider } from 'context/team/TeamSquadContext';
import useSidebar from 'hooks/useSidebar';
import { useUrl } from 'hooks/useUrl';
import Config from 'utils/Config';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Objectives from 'components/objectives/Objectives';
import CompactSkeleton from 'components/objectives/compact-objective/CompactSkeleton';
import BannerBox from 'components/shared/BannerBox';
import SidebarMyGoals from 'components/sidebar/SidebarMyGoals';
import SidebarTeam from 'components/teams/SidebarTeam';
import ModalApprove from 'pages/teams/approval/ModalApprove';
import {
  getObjectiveChildren,
  getObjectivePermissions
} from 'src/client/ObjectivesClient';

import WithdrawConfirmationModal from './WithdrawConfirmationModal';

const DetailContent = () => {
  const [prevPath, setPrevPath] = useState('');
  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [currentTab, setCurrentTab] = useState([]);
  const [countState, setCountState] = useState({});
  const [tabsList, setTabsList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [approvalDetail, setApprovalDetail] = useState({});
  const { match } = useUrl();
  const history = useHistory();
  const { pathname } = useLocation();
  const { teamId, parentId, approvalId } = useParams();
  const { data: teamDetail, refetchTeam } = useTeamDetail();
  const topSidebar = useSidebar((state) => state?.topSidebar);

  const paramsSubGoals = () => {
    let state = [];
    if (currentTab === 'existing') {
      state = ['running', 'reviewed', 'completed'];
    } else {
      state = [snakeCase(currentTab)];
    }
    const query = {
      reviewsVisibility: 1,
      sortColumn: 'name',
      sortDirection: 'asc',
      parentId: parentId,
      goals: 'team',
      ...(state?.length > 0 && { state: state })
    };
    return query;
  };

  const fetchChildrenStateCount = () => {
    const query = {
      metadata: 'count',
      group: 'state'
    };
    return getObjectiveChildren(parentId, query);
  };

  const { isLoading: isLoadingState } = useQuery(
    ['teamGoals', 'childrenStateCount', parentId],
    fetchChildrenStateCount,
    {
      onSuccess: (data) => {
        if (data?.metadata?.metadata) {
          const dataApproval = data?.metadata?.metadata;
          setCountState({
            draft: dataApproval?.draft?.count,
            edited: dataApproval?.edited?.count,
            toBeDeleted: dataApproval?.toBeDeleted?.count,
            existing: dataApproval?.running?.count
          });
          setTabsList(
            [
              { id: 'draft', name: 'New', count: dataApproval?.draft?.count },
              {
                id: 'edited',
                name: 'Edited',
                count: dataApproval?.edited?.count
              },
              {
                id: 'toBeDeleted',
                name: 'To Be Deleted',
                count: dataApproval?.toBeDeleted?.count
              },
              {
                id: 'existing',
                name: 'Existing',
                count: dataApproval?.running?.count
              }
            ].filter((data) => data?.count > 0)
          );
        }
      }
    }
  );

  const fetchObjectivePermissions = () => {
    const query = {
      pageType: prevPath?.includes('teams') ? 'requester' : 'approver'
    };
    return getObjectivePermissions(parentId, query);
  };

  useQuery(['teamGoals', 'permissions', parentId], fetchObjectivePermissions, {
    enabled: prevPath !== '',
    onSuccess: (data) => {
      if (data?.data) {
        setPermissions(data?.data?.permissions);
      }
    }
  });

  const fetchApprovalDetail = () => {
    const query = {
      pageType: prevPath?.includes('teams') ? 'requester' : 'approver',
      parentObjectiveIds: [parentId],
      type: 'team'
    };
    return getApprovalDetail(approvalId, query);
  };

  useQuery(['teamGoals', 'approvalDetail', approvalId], fetchApprovalDetail, {
    enabled: prevPath !== '',
    onSuccess: (data) => {
      if (data?.data) {
        setApprovalDetail(data?.data);
      }
    }
  });

  useEffect(() => {
    setPrevPath(history?.location?.state?.prevPath);
  }, []);

  useEffect(() => {
    setCurrentTab(tabsList?.[0]?.id);
  }, [tabsList]);

  return (
    tabsList.length > 0 && (
      <>
        <HeaderPage
          isHeaderComposer
          backToUrl={`${prevPath}?tab=teams-approval`}
          titlePage={`Approval Request ${dayjs(
            approvalDetail?.createdAt
          ).year()}`}
          primaryAction={
            permissions?.includes('approval_approve') &&
            !prevPath?.includes('teams') && {
              title: getObjectiveLocale('Approve'),
              dataCy: 'approve-button',
              onClick: () => {
                setShowApprove(!showApprove);
              }
            }
          }
          secondaryAction={
            !prevPath?.includes('teams') &&
            (permissions?.includes('approval_reject')
              ? {
                  title: getObjectiveLocale('Reject'),
                  dataCy: 'reject-button',
                  onClick: () => {
                    setShowReject(!showReject);
                  }
                }
              : permissions?.includes('approval_withdraw') && {
                  title: getObjectiveLocale('Withdraw Approval'),
                  dataCy: 'withdraw-button',
                  onClick: () => {
                    setShowWithdraw(!showWithdraw);
                  }
                })
          }
          preTitle={teamDetail?.name}
          tabs={{
            tabsList: tabsList,
            defaultIndex: 0,
            onChange: (tab) => {
              setCurrentTab(tab);
            },
            url: pathname,
            withCount: true
          }}
          headerPicture={
            teamDetail?.teamPicture || Config?.asset?.general?.brokenImage
          }
        />

        <div className="w-[calc(100%-400px)] !px-[24px]">
          {approvalDetail?.state && (
            <BannerBox
              text={approvalDetail?.state}
              type="info"
              marginClass="my-[24px]"
              paddingClass="px-[16px] py-[8px]"
              customClass="!rounded-[8px]"
              iconSize="24"
            />
          )}
          {isLoadingState && tabsList.length == 0 ? (
            <CompactSkeleton />
          ) : (
            <Objectives
              cardModel="goal-list-approval-detail"
              page={`goalScoringViewOnly-${parentId}`}
              action={false}
              extraFilter={paramsSubGoals()}
              isForceNewLayout
              withMarginTop
            />
          )}
        </div>

        {teamDetail && (
          <div
            className="sidebar-my-goals sidebar-compact !w-[400px]"
            style={{
              top: `${topSidebar || 0}px`,
              height: `calc(100vh - ${topSidebar || 0}px)`
            }}
          >
            <SidebarTeam
              teamDetail={teamDetail}
              currentTab={currentTab}
              refetchTeam={refetchTeam}
            />
          </div>
        )}

        {showApprove && (
          <ModalApprove
            setShowApprove={setShowApprove}
            countState={countState}
            objectiveId={parentId}
            teamId={teamId}
            approvalId={approvalId}
            eventOnApproveOrReject={() =>
              history.push(`${prevPath}?tab=teams-approval`)
            }
            teamName={teamDetail.name}
            teamPicture={teamDetail.teamPicture}
          />
        )}

        {showReject && (
          <ModalApprove
            isReject={true}
            setShowApprove={setShowReject}
            countState={countState}
            objectiveId={parentId}
            teamId={teamId}
            approvalId={approvalId}
            eventOnApproveOrReject={() =>
              history.push(`${prevPath}?tab=teams-approval`)
            }
            teamName={teamDetail.name}
            teamPicture={teamDetail.teamPicture}
          />
        )}

        {showWithdraw && (
          <WithdrawConfirmationModal
            objectiveId={parentId}
            setShowModal={setShowWithdraw}
          />
        )}

        {match && <SidebarMyGoals />}
      </>
    )
  );
};

const TeamApprovalDetail = ({
  match: {
    params: { teamId, teamType }
  }
}) => {
  return (
    <TeamDetailProvider teamId={teamId} teamType={teamType}>
      <TeamActivitiesProvider teamId={teamId}>
        <TeamSquadProvider teamId={teamId} teamType={teamType}>
          <DetailContent />
        </TeamSquadProvider>
      </TeamActivitiesProvider>
    </TeamDetailProvider>
  );
};

export default TeamApprovalDetail;

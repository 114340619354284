import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import { format } from 'timeago.js';

import { getObjectiveDetail } from 'client/ObjectivesClient';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';
import {
  getObjectiveLeader,
  whichBadgeBgColorHex,
  whichBadgeColorHex,
  whichStatus
} from 'utils/ObjectivesHelper';

import Dropdown, {
  useDropdown
} from 'components/design-system/dropdown/Dropdown';
import SingleUser from 'components/design-system/single-user/SingleUser';
import Table from 'components/design-system/table/Table';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';
import TruncateParagraph from 'components/shared/TruncateParagraph';
import useDropdownMenu from 'src/hooks/useDropdown';

const ProjectRow = ({ objective }) => {
  const history = useHistory();

  const [objectiveData, setObjectiveData] = useState(objective);

  const { involvements, measurement } = objectiveData || {};
  const { progressColorHex } = measurement || {};

  const memberCount =
    involvements.filter(
      ({ role, extendedRole }) =>
        role == 'assignee' && extendedRole != 'leader' && extendedRole != 'pmo'
    )?.length || 0;

  const fetchObjective = () => {
    return getObjectiveDetail(objectiveData.id);
  };

  useQuery(['objective', objectiveData.id], fetchObjective, {
    initialData: objectiveData,
    staleTime: Infinity,
    onSuccess: (data) => {
      data?.data && setObjectiveData(data?.data);
    }
  });

  const openProjectDetail = (id) => {
    const { pathname } = history.location;
    const redirectUrl = pathname.includes('teams') ? '/projects' : pathname;
    history.push(`${redirectUrl}/${id}`);
  };

  return (
    <Table.Row
      onClick={() => openProjectDetail(objectiveData.id)}
      customStickyContainerCN="bg-n-200 z-[1]"
    >
      <Table.Column>
        <TruncateParagraph className="typography-paragraph text-n-900">
          {objectiveData.name}
        </TruncateParagraph>
      </Table.Column>
      <Table.Column>
        {getObjectiveLeader(involvements) && (
          <SingleUser
            userData={getObjectiveLeader(involvements)}
            customClass="w-[250px]"
            avatarPosition="static"
          />
        )}
      </Table.Column>
      <Table.Column>
        <p className="typography-paragraph text-n-900">
          {getObjectiveLocale(`[count] employees`).replace(
            '[count]',
            getNumberLocale(memberCount)
          )}
        </p>
      </Table.Column>
      <Table.Column>
        {!isEmpty(measurement) && (
          <Badge
            defaultPointer
            content={whichStatus[progressColorHex || '44DB5E']}
            colorHex={whichBadgeColorHex[progressColorHex || '44DB5E']}
            bgColorHex={whichBadgeBgColorHex[progressColorHex || '44DB5E']}
            className="pointer-events-none"
          />
        )}
      </Table.Column>
      <Table.Column>
        <p className="typography-paragraph text-n-900">
          {format(objectiveData.valueLastUpdatedAt)}
        </p>
      </Table.Column>
      <Table.Column noPadding>
        <Dropdown>
          <Dropdown.Trigger customClass="w-[56px] h-[56px] flex items-center justify-center">
            <SVGIcon
              size="24"
              iconName="icon-keyboard_control"
              fillColor="var(--n-600)"
              customClass="cursor-pointer"
            />
          </Dropdown.Trigger>
          <MenuItems
            objectiveData={objectiveData}
            setObjectiveData={setObjectiveData}
          />
        </Dropdown>
      </Table.Column>
    </Table.Row>
  );
};

const MenuItems = ({ objectiveData, setObjectiveData }) => {
  const { open } = useDropdown();

  const { permissions } = objectiveData || {};

  const { useMenu } = useDropdownMenu({
    objective: objectiveData,
    permissions,
    page: 'myprojects',
    isInNewProjectPage: true,
    onComplete: (data) => setObjectiveData(data)
  });

  const listMenu = useMenu({ except: ['addGoal', 'addTask'] });

  return (
    <>
      {open && (
        <FloatingComponent lockScroll={false}>
          <Dropdown.MenuItems type="absolute" position="right" useScrollClose>
            {listMenu.map((menu, index) => (
              <Dropdown.MenuItem
                key={index}
                iconColor={menu?.colorHex || 'var(--n-600)'}
                iconName={menu?.icon}
                onClick={(e) => {
                  e.stopPropagation();
                  menu.onClick(menu);
                }}
                textColor={menu?.textColor}
              >
                {menu?.title}
              </Dropdown.MenuItem>
            ))}
          </Dropdown.MenuItems>
        </FloatingComponent>
      )}
    </>
  );
};

export default ProjectRow;

import React, { useState } from 'react';

import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import SVGIcon from 'components/shared/SVGIcon';
import Table from 'src/components/design-system/table/Table';
import TooltipContainer from 'src/components/shared/Tooltips/TooltipContainer';
import TruncateParagraph from 'src/components/shared/TruncateParagraph';
import ProgressBarOverallProgress from 'src/components/shared/progressBar/ProgressBarOverallProgress';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

import ManageSurveyListRowRecurrences from './ManageSurveyListRowRecurrences';
import ManageSurveyOptions from './ManageSurveyOptions';

const formatDateTime = (date, withTime = true) => {
  const format = withTime ? 'MMM DD, YYYY - LT' : 'MMM DD, YYYY';
  return dayjs(date).format(format);
};

const getCompletionProgress = (data) => {
  return (
    parseFloat(
      (
        (data?.completionRate?.submitted / data?.completionRate?.total) *
        100
      ).toFixed(2)
    ) || 0
  );
};

const ManageSurveyListRow = ({
  data,
  index,
  currentTab,
  setModalOpen,
  onClickRow
}) => {
  const [showOther, setShowOther] = useState(true);

  const toggleShowSchedule = (e) => {
    e.stopPropagation();
    setShowOther((draft) => !draft);
  };

  const rowProps = {
    dataCy: `survey-row-${index}`,
    ...(currentTab != 'upcoming'
      ? { onClick: () => onClickRow(data?.id, data?.occurrence?.id) }
      : { useRowHover: false })
  };

  const occurrenceCount = data?.countOccurrence;

  const marginClass = index != 0 ? 'mb-[4px]' : '';

  const showOtherBg =
    occurrenceCount > 1 && showOther ? 'bg-n-100 min-w-fit' : '';

  const rowContainerProps =
    currentTab == 'upcoming'
      ? {
          className: `group hover:bg-base-3008 cursor-pointer ${marginClass} ${showOtherBg}`,
          onClick: () => onClickRow(data?.id, data?.occurrence?.id)
        }
      : { className: `${marginClass} ${showOtherBg}` };

  const isReccurrence = Object.keys(data?.recurrence).length;

  const startDate = data?.occurrence?.startDate || data?.startDate;
  const expiration = data?.occurrence?.endDate || data?.expiration;
  const completionRate =
    data?.occurrence?.completionRate || data?.completionRate;

  return (
    <div {...rowContainerProps}>
      <Table.Row {...rowProps}>
        <Table.Column dataCy="shared-table-cell">
          <div className="flex flex-col w-[100%]">
            <TruncateParagraph className="typography-paragraph text-n-900">
              {data?.title}
            </TruncateParagraph>
            {occurrenceCount > 0 && (
              <p className="typography-h100 text-n-800">
                {occurrenceCount} {getObjectiveLocale('occurrence')}
              </p>
            )}
          </div>
        </Table.Column>
        <Table.Column>
          <p className="typography-paragraph text-n-900">
            {isReccurrence
              ? `${data?.recurrence?.interval} ${startCase(
                  data?.recurrence?.every
                )}`
              : '1 Time'}
          </p>
        </Table.Column>
        <Table.Column dataCy="shared-table-cell">
          <p className="typography-paragraph text-n-900">
            {formatDateTime(startDate)}
          </p>
        </Table.Column>
        <Table.Column dataCy="shared-table-cell">
          <p className="typography-paragraph text-n-900">
            {formatDateTime(expiration)}
          </p>
        </Table.Column>
        <Table.Column dataCy="shared-table-cell">
          <TooltipContainer
            show
            position="top"
            text={`${completionRate?.submitted} / ${
              completionRate?.total || 0
            } ${getObjectiveLocale('Participants')}`}
            key={`progress-${index}`}
            useMaxWidth={false}
            classContainer="flex"
          >
            <ProgressBarOverallProgress
              widthBackground={100}
              widthProgress={parseInt(getCompletionProgress(data.occurrence))}
              height={16}
              colorProgress="bg-g-600"
              containerClassName="w-[136px] h-[16px] overflow-hidden rounded-full"
            />
            <span className="typography-h400 ml-[8px] min-w-[40px] text-right">
              {getCompletionProgress(data.occurrence)}%
            </span>
          </TooltipContainer>
        </Table.Column>
        <Table.Column>
          <SVGIcon
            iconName={showOther ? 'icon-expand_less' : 'icon-expand_more'}
            size="24"
            fillColor="var(--n-600)"
            customClass={`pr-[16px] mt-[4px] ${
              occurrenceCount <= 1 ? 'hidden' : ''
            }`}
            onClick={toggleShowSchedule}
          />
        </Table.Column>
        <Table.Column dataCy="shared-table-cell">
          <ManageSurveyOptions
            id={data?.id}
            occurrenceId={data?.occurrence?.id}
            withRemindOptions={
              completionRate?.submitted != completionRate?.total &&
              currentTab === 'current'
            }
            setModalOpen={setModalOpen}
          />
        </Table.Column>
      </Table.Row>
      {occurrenceCount > 1 && showOther && (
        <ManageSurveyListRowRecurrences
          currentTab={currentTab}
          idSurvey={data?.id}
          excludeIdOccurrence={data?.occurrence?.id}
          onClickRow={onClickRow}
          setModalOpen={setModalOpen}
          firstOccurenceStart={data?.occurrence?.startDate}
          totalOccurrences={occurrenceCount}
        />
      )}
    </div>
  );
};

export default ManageSurveyListRow;

import React from 'react';

import Divider from 'components/design-system/Divider';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import SVGIcon from 'components/shared/SVGIcon';
import WhiteCardWithFooter from 'components/shared/WhiteCardWithFooter';

const CalibrationHistorySidebarSkeleton = ({ setShowCalibrationSidebar }) => {
  return (
    <>
      <div className="py-[16px] mx-[-8px] mb-[16px] flex items-center justify-between">
        <Shimmer width={160} height={32} />
        <SVGIcon
          iconName="icon-clear"
          size={24}
          fillColor="var(--n-600)"
          onClick={() => setShowCalibrationSidebar(false)}
        />
      </div>
      <div className="flex items-center mb-[16px]">
        <Shimmer circle width={40} height={40} />
        <div className="flex flex-col ml-[16px] w-full">
          <Shimmer width="100%" height={20} customClass="mb-[8px]" />
          <Shimmer width="100%" height={16} />
        </div>
      </div>
      <div className="bg-n-000 shadow-raised p-[16px] rounded-[4px]">
        <Shimmer width="100%" height={20} customClass="mb-[16px]" />
        <Shimmer width={80} height={16} />
      </div>

      <div className="my-[24px] mx-[-24px]">
        <Divider />
      </div>

      <Shimmer width={160} height={16} />

      {[1, 2].map((_, index) => (
        <WhiteCardWithFooter
          key={`history-skeleton-${index}`}
          containerClass="shadow-raised mt-[16px] no-border"
          bodyClass="p-16 block"
        >
          <Shimmer
            widthRandomness
            width={240}
            height={16}
            customClass="mb-[20px]"
          />
          <Shimmer width={80} height={16} customClass="mb-[24px]" />
          <Shimmer width={320} height={16} customClass="mb-[8px]" />
          <Shimmer width={320} height={16} customClass="mb-[8px]" />
          <Shimmer width={320} height={16} customClass="mb-[8px]" />
          <Shimmer width={80} height={16} />
        </WhiteCardWithFooter>
      ))}
    </>
  );
};

export default CalibrationHistorySidebarSkeleton;

import React, { useEffect, useRef, useState } from 'react';

import ctl from '@netlify/classnames-template-literals';
import { DatePicker } from 'antd';
import startCase from 'lodash/startCase';

import useClickOutside from 'hooks/useClickOutside';
import { daytz } from 'utils/DateUtils';
import { getDateLocale, getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

import './Calendar.scss';

const Calendar = ({
  defaultValue = [],
  handleChangeCalendar,
  disableAllDate = [],
  permissionStartDate = true,
  permissionDueDate = true,
  type,
  withTitle = false,
  customBox,
  suffixIcon,
  disabledDate,
  calendarButtonType = '',
  allowClear = false,
  customDropdown = 'w-[280px]',
  dateFormat,
  customTrigger,
  fullWidth
}) => {
  const [value, setValue] = useState(defaultValue);
  const ref = useRef();
  const [calendarInfo, setCalendarInfo] = useState({ open: false, type: null });

  const permission =
    calendarInfo.type == 'startDate' ? permissionStartDate : permissionDueDate;
  useClickOutside(ref, () => setCalendarInfo({ open: false, type: null }));
  const cardCN = ctl(`
    flex items-center
    flex
    ${calendarButtonType === 'no' && `hidden`}
    ${calendarButtonType === 'today' ? `justify-center` : `justify-between`}
    py-[8px]`);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onStandaloneSelect = (value) => {
    setValue(value);
    setCalendarInfo({ open: false, type: null });
    handleChangeCalendar(value);
  };

  const getQuarterPeriod = (quarter) => {
    const start = daytz().quarter(quarter).startOf('quarter');
    const end = daytz().quarter(quarter).endOf('quarter');

    return [start, end];
  };

  const checkDateArray = (array1, array2) => {
    if (
      daytz(array1[0]).isSameOrBefore(daytz(array2[0])) &&
      daytz(array1[1]).isSameOrAfter(daytz(array2[1]))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const footerPeriod = () => {
    const startYear = daytz().startOf('year');
    const endYear = daytz().endOf('year');
    const today = daytz();
    const buttonProps = {
      onStandaloneSelect,
      disableAllDate,
      checkDateArray
    };

    const getInDays = (days, defaultValue) => {
      const end = daytz(defaultValue[0]).add(days, 'day').endOf('day');

      return [defaultValue[0], end];
    };

    return (
      <div className={cardCN}>
        {calendarButtonType === 'today' ? (
          <CalendarButton
            {...buttonProps}
            value={today}
            datacy="annual-today-button"
          >
            {getObjectiveLocale('Today')}
          </CalendarButton>
        ) : calendarButtonType === 'in-days' ? (
          [7, 14, 30].map((val, idx) => {
            return (
              <CalendarButton
                key={idx}
                {...buttonProps}
                value={getInDays(val, defaultValue)}
                datacy={`q${val}-calendar-button`}
              >
                {val} {getObjectiveLocale('days')}
              </CalendarButton>
            );
          })
        ) : (
          calendarButtonType !== 'no' && (
            <>
              <CalendarButton
                {...buttonProps}
                value={[startYear, endYear]}
                datacy="annual-calendar-button"
              >
                {getObjectiveLocale('Annual')}
              </CalendarButton>
              {[1, 2, 3, 4].map((val, idx) => {
                return (
                  <CalendarButton
                    key={idx}
                    {...buttonProps}
                    value={getQuarterPeriod(val)}
                    datacy={`q${val}-calendar-button`}
                  >
                    Q{val}
                  </CalendarButton>
                );
              })}
            </>
          )
        )}
      </div>
    );
  }; // End of footerPeriod

  const onStandaloneChange = (value) => {
    const calendarType = calendarInfo.type;
    if (value) {
      handleChangeCalendar(value.format('YYYY-MM-DD'), calendarType);
      if (calendarType === 'startDate') {
        setCalendarInfo({ ...calendarInfo, type: 'dueDate' });
      } else {
        setCalendarInfo({ open: false, type: null });
      }
    } else {
      handleChangeCalendar(null, calendarType);
      setCalendarInfo({ open: false, type: null });
    }
  };

  const handleOpenCalendar = (type) => {
    permission &&
      calendarInfo.type != type &&
      setCalendarInfo({ open: true, type: type });
  };
  return (
    <>
      <div className="single-calendar w-full" ref={ref}>
        <div>
          <div className="flex items-center">
            <div className="relative w-full">
              <DatePickerContainer
                type={type}
                open={calendarInfo.type == type && calendarInfo.open}
                disabled={
                  type == 'startDate'
                    ? !permissionStartDate
                    : !permissionDueDate
                }
                onClick={() => handleOpenCalendar(type)}
                onChangePreset={onStandaloneChange}
                value={value}
                footerPeriod={footerPeriod}
                withTitle={withTitle}
                disabledDate={disabledDate}
                customBox={customBox}
                suffixIcon={suffixIcon}
                allowClear={allowClear}
                customDropdown={customDropdown}
                dateFormat={dateFormat}
                customTrigger={customTrigger}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DatePickerContainer = ({
  type,
  open,
  onClick = () => null,
  onChangePreset,
  disabled,
  value,
  footerPeriod,
  withTitle,
  customBox,
  suffixIcon,
  disabledDate,
  allowClear,
  customDropdown,
  dateFormat,
  customTrigger
}) => {
  const ref = useRef();
  return (
    <div
      className={`grid px-8 rounded ${customBox} ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={onClick}
      data-cy={type + '-date-calendar'}
      ref={ref}
    >
      {withTitle && (
        <span className="typography-h100 text-n-600">
          {getObjectiveLocale(startCase(type))}
        </span>
      )}
      <DatePicker
        bordered={false}
        open={open}
        value={value}
        disabled={disabled}
        disabledDate={disabledDate}
        showToday={false}
        format={
          dateFormat
            ? dateFormat
            : (value) =>
                getDateLocale(value, {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric'
                })
        }
        onChange={onChangePreset}
        getPopupContainer={() => ref.current}
        allowClear={allowClear}
        renderExtraFooter={() => footerPeriod && footerPeriod()}
        dropdownClassName={customDropdown}
        suffixIcon={
          !suffixIcon && (
            <SVGIcon
              size="24"
              iconName="icon-calendar_today"
              fillColor="var(--base-600)"
            />
          )
        }
        {...(customTrigger ? { inputRender: customTrigger } : [])}
      />
    </div>
  );
};

const CalendarButton = ({
  onStandaloneSelect,
  value,
  disableAllDate,
  checkDateArray,
  datacy,
  children
}) => {
  return (
    <button
      className="period-button color-n900 bg-n-000
      cursor-pointer px-[8px] py-[4px] rounded-[4px] leading-[20px] typography-paragraph"
      onClick={() => onStandaloneSelect(value)}
      disabled={
        disableAllDate?.length > 0 && !checkDateArray(disableAllDate, value)
      }
      data-cy={datacy}
    >
      {children}
    </button>
  );
};

export default Calendar;

import client from 'client/ApiClient';

function getUsers(query, noConvertCase = false) {
  return client(`v1/admin/users`, { params: query }, noConvertCase);
}

function getUsersWithPost(body) {
  return client(`v1/admin/users/advance`, { method: 'POST', body });
}

function getJobPlacementUsers(query) {
  return client(`v1/admin/job_placements`, { params: query });
}

function getLatestUsersCSV(params) {
  return client(`v1/admin/users/exports/latest`, {
    params,
    useInterceptor: false
  });
}

function exportCSVUsers(params) {
  return client(`v1/admin/users/exports`, { method: 'POST', params });
}

function getJobPlacementUsersWithPost(body) {
  return client(`v1/admin/job_placements/advance`, { method: 'POST', body });
}

function resendActivationUser(body) {
  return client(`v1/admin/users/activation/resend`, { method: 'PATCH', body });
}

function deactivateUser(body) {
  return client(`v1/admin/users`, { method: 'DELETE', body });
}

function reactivateUsers(body) {
  return client(`v1/admin/users/reactivate`, { method: 'PATCH', body });
}
function createUsers(data) {
  return client(`v1/admin/users`, { method: 'POST', body: data });
}

function importUsers(data) {
  return client(`v1/admin/users/upsert`, { method: 'POST', body: data });
}

function updateUsers(data, userId) {
  return client(`v1/admin/users/${userId}`, { method: 'PATCH', body: data });
}

function resetPassword(id, data) {
  return client(`v1/users/${id}/change_password`, {
    method: 'POST',
    body: data
  });
}

export {
  getUsers,
  getUsersWithPost,
  resendActivationUser,
  reactivateUsers,
  deactivateUser,
  createUsers,
  updateUsers,
  resetPassword,
  getJobPlacementUsers,
  getJobPlacementUsersWithPost,
  importUsers,
  getLatestUsersCSV,
  exportCSVUsers
};

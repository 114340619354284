import React, { useState } from 'react';

import { editObjective } from 'client/ObjectivesClient';
import { getObjectivePriorities } from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import useFilter from 'hooks/useFilter';

import PriorityDropdown from '../dropdown/PriorityDropdown';

const Priority = ({ task, type = 'list', isProject, isSubTask }) => {
  const { id, priority, section } = task || {};
  const {
    refetchQueries,
    refetchObjective,
    invalidateQueries,
    invalidateTaskQueries
  } = useRefetchQuery();
  const filterId =
    (isProject ? 'projectDetail' : 'tasksFilter') +
    (type === 'list' ? 'List' : 'Board');
  const filter = useFilter((state) => state.filterById?.[filterId]);

  const [listPriorities, setListPriorities] = useState([]);

  const getPriorities = async () => {
    let params = {};
    // TEMPORARY COMMENT - will be used in next story -> Custom Priority
    // if (parent?.isProject) {
    //   params.projectId = parent?.id;
    // }
    const { data } = await getObjectivePriorities(params);
    if (data) {
      setListPriorities(data);
    }
  };

  const beforeTrigger = (e, setOpen) => {
    if (!e.shiftKey && !(e.ctrlKey || e.metaKey)) {
      setOpen();
      getPriorities();
    }
  };

  const onChange = async (priorityId, priorityName) => {
    if (priorityId != priority?.id) {
      let body = { priorityId };
      const { isSuccess } = await editObjective(id, body);
      if (isSuccess) {
        if (filter?.group === 'priority' && type === 'list' && !isSubTask) {
          if (isProject) {
            refetchQueries([
              'objectives',
              'mytasks',
              section?.name?.toLowerCase(),
              priority?.name?.toLowerCase()
            ]);
            refetchQueries([
              'objectives',
              'mytasks',
              section?.name?.toLowerCase(),
              priorityName?.toLowerCase()
            ]);
          } else {
            refetchQueries([
              'objectives',
              'mytasks',
              priority?.name?.toLowerCase()
            ]);
            refetchQueries([
              'objectives',
              'mytasks',
              priorityName?.toLowerCase()
            ]);
          }
        }

        refetchObjective(id);

        invalidateQueries(
          ['objectives', 'mytasks', priorityName?.toLowerCase()],
          { refetchActive: false }
        );

        invalidateTaskQueries({ task });
      }
    }
  };

  return (
    <div
      className={type === 'list' ? 'w-full h-full' : 'flex'}
      onClick={(e) => type === 'board' && e.stopPropagation()}
    >
      <PriorityDropdown
        task={task}
        useTrigger
        beforeTrigger={beforeTrigger}
        customClass={type === 'list' ? 'w-full h-full' : ''}
        position="middle"
        onChange={onChange}
        listPriorities={listPriorities}
        type={type}
      />
    </div>
  );
};

export default Priority;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import TextArea from 'components/shared/TextArea';

const InputUserIds = ({ setUserIds, hide, userIds }) => {
  return (
    <div className={`${hide ? 'hidden' : ''}`}>
      <p className="typography-h500 text-n-900 mb-[4px]">
        {getObjectiveLocale('Input user ID')}
      </p>
      <p className="typography-paragraph text-n-800">
        {getObjectiveLocale(
          'You can filter employees in this current cycle by listing User ID in the text area below to change their assignment status'
        )}
        .
      </p>
      <div className="mt-[23px]">
        <p className="typography-h100 text-n-800 pb-[8px]">
          {getObjectiveLocale('User Ids')}
        </p>
        <TextArea
          height={536}
          maxHeight={536}
          onHandleChange={(e) => setUserIds(e.target.value)}
          value={userIds}
          dataCy={'input-ids'}
          textPlaceholder="Input or paste user ID here..."
        />
      </div>
    </div>
  );
};

export default InputUserIds;

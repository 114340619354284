import React from 'react';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import WhiteCardWithFooter from 'components/shared/WhiteCardWithFooter';
import SetWeightComponent from 'components/shared/card/OverallProgressCard/SetWeightComponent';
import 'components/shared/card/OverallProgressCard/_overallViewProgress.scss';

import OverallProgressBar from './OverallProgressBar';
import {
  _getNodeFoooter,
  _getRangeDateCurrentQuarter
} from './_overallProgressCardHelper';

const OverallProgressSetWeight = ({
  colorHex,
  overallProgressPercentage,
  totalObjectives,
  totalWeight,
  canEditWeight
}) => {
  const { config } = useUser();
  const {
    maxObjectivesOn,
    minObjectivesOn,
    maxNumberOfObjectives,
    minNumberOfObjectives
  } = config.objectivesConfig;

  const { nodeFooterWarning } = _getNodeFoooter({
    maxObjectivesOn,
    minObjectivesOn,
    maxNumberOfObjectives,
    minNumberOfObjectives,
    totalObjectives
  });
  const nodeFooter = nodeFooterWarning;

  return (
    <WhiteCardWithFooter
      containerClass="mr-[16px]"
      bodyClass="flex-row flex justify-between pb-[16px] overall-card-set-weight"
    >
      <div className="progressbar-container">
        <p className="overall-objective-title typography-h200">
          {getObjectiveLocale('Annual Progress')}
        </p>
        <OverallProgressBar
          progressPercentage={overallProgressPercentage}
          colorHex={colorHex}
        />
      </div>
      <div className="flex flex-col">
        <p className="overall-objective-title typography-h200">
          {getObjectiveLocale('Top Parent')}
        </p>
        {nodeFooter}
      </div>
      <SetWeightComponent
        totalWeight={totalWeight}
        useSetWeight={canEditWeight}
      />
    </WhiteCardWithFooter>
  );
};

export default OverallProgressSetWeight;

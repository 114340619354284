import React, { useEffect, useMemo, useState } from 'react';

import ctl from '@netlify/classnames-template-literals';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getWordingMetrics } from 'utils/ObjectivesHelper';
import {
  CALC_TYPE_EARLY_COMPLETION,
  CALC_TYPE_LESS_IS_BETTER,
  CALC_TYPE_NORMAL,
  CALC_TYPE_ZERO_TARGET
} from 'utils/const';

import DropdownMetric from 'components/dropdown/DropdownMetric';
import DropdownRollUp from 'components/dropdown/DropdownRollUp';
import NumberInput from 'components/shared/NumberInput';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import ContentWrapper from 'components/sidebar/sidebar-create/ContentWrapper';
import { useDeepEffect } from 'src/utils/useDeepEffect';

const getCalculationType = (checked, isZeroTarget, type) => {
  if (checked && type == 'less_is_better') return CALC_TYPE_LESS_IS_BETTER;
  if (checked && type == 'early_completion') return CALC_TYPE_EARLY_COMPLETION;
  if (isZeroTarget) return CALC_TYPE_ZERO_TARGET;
  return CALC_TYPE_NORMAL;
};

const MetricSection = ({
  objectiveValue,
  setObjectiveValue,
  listMetrics,
  type,
  permissions
}) => {
  const { config } = useUser();

  const enabledZeroTarget = configCalculationType?.includes(
    CALC_TYPE_ZERO_TARGET
  );
  const isLessIsBetter = objectiveValue?.calculationType?.includes(
    CALC_TYPE_LESS_IS_BETTER
  );
  const isEarlyCompletion = objectiveValue?.calculationType?.includes(
    CALC_TYPE_EARLY_COMPLETION
  );

  const unitId = objectiveValue.measurement
    ? objectiveValue.measurement.unitId
    : 0;
  const selectedMetric = listMetrics.find(({ id }) => id === unitId);
  let rollUp = objectiveValue?.measurement?.rollUp
    ? objectiveValue?.measurement?.rollUp
    : selectedMetric?.defaultRollUp;
  const startingValueObjective = objectiveValue.measurement
    ? objectiveValue.measurement.startingValue
    : 0;
  const targetValueObjective = objectiveValue.measurement
    ? objectiveValue.measurement.targetValue
    : 100;

  const { objectiveCalculationType: configCalculationType, includeTaskRollUp } =
    config;

  const permissionEditCalculation = permissions?.includes(
    'edit_calculation_type'
  );
  const showRollUp = type == 'task' ? includeTaskRollUp : true;

  let wordingMetrics;
  switch (rollUp) {
    case 'disabled':
      wordingMetrics = getObjectiveLocale(
        'Manual: objective progress is calculated manually, the target progress is fixed until you update it'
      );
      break;
    case 'auto':
      wordingMetrics = getObjectiveLocale(
        'Auto Sum: objective progress is accumulated automatically by adding the current values of all the sub objectives or tasks together'
      );
      break;
    case 'average':
      wordingMetrics = getObjectiveLocale(
        'Auto Avg: objective progress is calculated automatically by averaging the percentage progress of all the sub objectives or tasks'
      );
      break;
    case 'weighted_average':
      wordingMetrics = getObjectiveLocale(
        'Auto Weighted Avg: objective progress is calculated automatically by weighted averaging the percentage progress of all the sub objectives'
      );
      break;
    case 'average_by_phase':
      wordingMetrics = getObjectiveLocale(
        'Auto Completed Sum: Update progress automatically by the number of completed tasks or sub goals'
      );
      break;
    case 'average_by_jira_phase':
      wordingMetrics = getObjectiveLocale(
        'JIRA: Update progress automatically by JIRA progress'
      );
      break;
    default:
      wordingMetrics = '';
      break;
  }

  const [startingValue, setStartingValue] = useState(startingValueObjective);
  const [targetValue, setTargetValue] = useState(targetValueObjective);
  const [isIntegrated, setIsIntegrated] = useState(false);

  const isZeroTarget = useMemo(
    () =>
      objectiveValue.type == 'goal' &&
      !objectiveValue.isProject &&
      configCalculationType?.includes(CALC_TYPE_ZERO_TARGET) &&
      !objectiveValue?.calculationType?.includes(CALC_TYPE_LESS_IS_BETTER) &&
      !objectiveValue?.calculationType?.includes(CALC_TYPE_EARLY_COMPLETION) &&
      targetValue == 0,
    [targetValue, configCalculationType, objectiveValue]
  );
  const [allowStartingNegative, setAllowStartingNegative] = useState(
    !isZeroTarget
  );

  const handleChangeRollUp = (event) => {
    setObjectiveValue((draft) => {
      draft.measurement.rollUp = event;
    });
  };

  const handleChangeCalculation = (e, type) => {
    const enabledZeroTarget = configCalculationType?.includes(
      CALC_TYPE_ZERO_TARGET
    );
    const zeroTargetStatus = enabledZeroTarget && !targetValue;
    const calculation = getCalculationType(
      e.target.checked,
      zeroTargetStatus,
      type
    );
    setObjectiveValue((draft) => {
      draft.calculationType = calculation;
    });
  };

  const handleChangeMetrics = (event) => {
    setObjectiveValue((draft) => {
      if (!draft.measurement) {
        draft.measurement = {};
        draft.measurement.startingValue = 0;
        draft.measurement.targetValue = 100;
        draft.measurement.rollUp = 'disabled';
      }

      if (!draft.measurement.startingValue) {
        draft.measurement.startingValue = 0;
      }

      if (!draft.measurement.targetValue) {
        draft.measurement.targetValue = 100;
      }

      if (!draft.measurement.rollUp) {
        draft.measurement.rollUp = 'disabled';
      }

      // only metrics percentage can use roll up average and milestone mode average
      if (event.unit !== '%') {
        if (draft.measurement.rollUp.includes('average')) {
          draft.measurement.rollUp = 'disabled';
        }
        if (draft.milestoneMode == 'average_value') {
          draft.milestoneMode = 'sum_value';
        }
      }

      draft.measurement.unitId = event.id;
    });
  };

  const getDefaultCalculationType = () => {
    const defaultType = isLessIsBetter
      ? CALC_TYPE_LESS_IS_BETTER
      : isEarlyCompletion
      ? CALC_TYPE_EARLY_COMPLETION
      : CALC_TYPE_NORMAL;
    const zeroTargetStatus =
      enabledZeroTarget && !parseFloat(targetValue || '0');
    return {
      type: defaultType,
      status: !isLessIsBetter && !isEarlyCompletion && zeroTargetStatus
    };
  };

  useEffect(() => {
    const tmpTargetValue =
      targetValueObjective == 0 ? '0' : targetValueObjective;
    const tmpStartingValue =
      startingValueObjective == 0 ? '0' : startingValueObjective;
    setTargetValue(tmpTargetValue);
    setStartingValue(tmpStartingValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveValue?.measurement]);

  useDeepEffect(() => {
    setAllowStartingNegative(!isZeroTarget);
  }, [isZeroTarget]);

  useEffect(() => {
    if (objectiveValue.measurement) {
      setObjectiveValue((draft) => {
        draft.measurement.startingValue = parseFloat(startingValue || '0');
        if (isZeroTarget) {
          draft.calculationType = CALC_TYPE_ZERO_TARGET;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingValue]);

  useEffect(() => {
    if (objectiveValue.measurement) {
      setObjectiveValue((draft) => {
        draft.measurement.targetValue = parseFloat(targetValue || '0');
        const { status: isStatusZeroTarget, type } =
          getDefaultCalculationType();
        draft.calculationType = isStatusZeroTarget
          ? CALC_TYPE_ZERO_TARGET
          : type;
        if (isStatusZeroTarget) {
          draft.isShowAdvanced = true;
          if (draft?.measurement?.startingValue < 0) {
            draft.measurement.startingValue = Math.abs(
              draft.measurement.startingValue
            );
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetValue]);

  useEffect(() => {
    if (isLessIsBetter) {
      setStartingValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveValue?.calculationType]);

  useEffect(() => {
    setIsIntegrated(objectiveValue?.integrations?.length > 0);
  }, [objectiveValue?.integrations]);

  const inputCN = ctl(`
    w-full
    py-[8px] px-[12px]
    ${unitId == 0 && 'disabled'}
  `);

  return (
    <ContentWrapper
      dataCy="sidebar-create-measurement"
      wrapperChildrenClass="flex-col"
    >
      <div className="flex items-center px-[24px] py-[6px]">
        <SVGIcon
          customClass="mr-[16px]"
          iconName="icon-bar_chart"
          size="24"
          fillColor="var(--n-600)"
        />
        <div className="flex items-center">
          <NumberInput
            inputClass={inputCN}
            className="w-[304px] mr-[8px]"
            value={targetValue}
            onChange={setTargetValue}
            dataCy="metric-target-value"
            disabled={
              unitId === 0 || !permissions?.includes('edit_target_value')
            }
            placeholder="Targets"
            precision={13}
          />
          <DropdownMetric
            defaultValue={selectedMetric ? selectedMetric.id : 0}
            handleChange={handleChangeMetrics}
            listMetrics={listMetrics}
            disabled={!permissions?.includes('edit_measurement_unit')}
            containerCustomClass="w-[240px]"
            triggerCustomClass="w-full"
            customClass="w-[240px]"
          />
        </div>
      </div>
      {parseFloat(startingValue) == parseFloat(targetValue) && unitId != 0 && (
        <div className="pl-[62px]">
          <p className="error-text-value">
            {getObjectiveLocale(
              isLessIsBetter || isEarlyCompletion
                ? "Target value can't be 0"
                : "Initial value can't be equal with target value"
            )}
          </p>
        </div>
      )}
      {isZeroTarget && (
        <div className="ml-[64px] mr-[24px] py-[4px] px-[16px] bg-b-200 rounded flex">
          <TooltipContainer
            wrap
            show
            text={getObjectiveLocale(
              'The closer the current value to 0, the better progress percentage and its status will be'
            )}
            useMaxWidth={false}
            tooltipClass="max-w-[329px]"
          >
            <SVGIcon
              size="24"
              fillColor="var(--b-600)"
              iconName="icon-info"
              customClass="info-less-is cursor-pointer"
            />
          </TooltipContainer>
          <p className="typography-paragraph text-n-900 ml-[16px]">
            {getObjectiveLocale('Zero Target mode is on')}
          </p>
        </div>
      )}
      <div className="ml-[62px] mr-[24px]">
        {configCalculationType?.includes(CALC_TYPE_LESS_IS_BETTER) &&
          objectiveValue?.calculationType &&
          unitId !== 0 && (
            <div
              className="less-is-container flex justify-between items-center section-toggle bg-n-200 rounded-[4px] py-[4px] pl-[16px] mt-[12px] mb-[12px]"
              data-cy="less-is-container"
            >
              <div className="flex justify-center items-center">
                <SVGIcon
                  size="24"
                  fillColor="var(--b-600)"
                  iconName="icon-trending_down"
                />
                <p className="typography-paragraph text-n-900 ml-[10px] mr-[10px]">
                  {getObjectiveLocale('Less is better')}
                </p>
                <TooltipContainer
                  wrap
                  show
                  text={getObjectiveLocale(
                    'Progress % calculated based on how much less current value from target value'
                  )}
                >
                  <SVGIcon
                    size="16"
                    fillColor="var(--n-600)"
                    iconName="icon-info"
                    customClass="info-less-is cursor-pointer"
                  />
                </TooltipContainer>
              </div>
              <SmallToggleSwitchPurple
                addClass="flex items-center mt-[2px]"
                idStr="less-is-btn"
                isChecked={isLessIsBetter}
                disabled={!permissionEditCalculation}
                onChange={(e) =>
                  handleChangeCalculation(e, CALC_TYPE_LESS_IS_BETTER)
                }
              />
            </div>
          )}
        {configCalculationType?.includes(CALC_TYPE_EARLY_COMPLETION) &&
          objectiveValue?.calculationType &&
          unitId !== 0 && (
            <div
              className="less-is-container flex justify-between items-center section-toggle bg-n-200 rounded-[4px] py-[4px] pl-[16px] mt-[12px] mb-[12px]"
              data-cy="early-completion-container"
            >
              <div className="flex justify-center items-center">
                <SVGIcon
                  size="24"
                  fillColor="var(--b-600)"
                  iconName="icon-early-completion"
                />
                <p className="typography-paragraph text-n-900 ml-[10px] mr-[10px]">
                  {getObjectiveLocale('Early completion')}
                </p>
                <TooltipContainer
                  wrap
                  show
                  text={getObjectiveLocale(
                    'The earlier the progress reach 100%, the better the progress status'
                  )}
                >
                  <SVGIcon
                    size="16"
                    fillColor="var(--n-600)"
                    iconName="icon-info"
                    customClass="info-early-completion cursor-pointer"
                  />
                </TooltipContainer>
              </div>
              <SmallToggleSwitchPurple
                addClass="flex items-center mt-[2px]"
                idStr="early-completion"
                isChecked={isEarlyCompletion}
                disabled={!permissionEditCalculation}
                onChange={(e) =>
                  handleChangeCalculation(e, CALC_TYPE_EARLY_COMPLETION)
                }
              />
            </div>
          )}
        {objectiveValue.measurement && unitId !== 0 && (
          <div className="mb-[8px] bg-n-200 border border-solid border-n-300 rounded px-[16px] py-[16px] mt-[8px]">
            <div className="metrics_sections flex flex-col gap-[16px]">
              <div className="advanced_input">
                <p className="typography-h100 text-n-800 mb-[8px]">
                  {getObjectiveLocale('Initial Value')}
                </p>
                {isLessIsBetter ? (
                  <p className="typography-paragraph text-n-600">
                    {getObjectiveLocale(
                      'Initial value is being disabled because you are using less is better'
                    )}
                  </p>
                ) : (
                  <NumberInput
                    inputClass={inputCN}
                    className="w-full"
                    value={startingValue}
                    onChange={setStartingValue}
                    dataCy="metric-initial-value"
                    placeholder="0"
                    disabled={!permissions?.includes('edit_starting_value')}
                    allowNegative={allowStartingNegative}
                  />
                )}
              </div>
              {showRollUp && objectiveValue.measurement?.rollUp && (
                <div className="advanced_input" data-cy="sidebar-create-rollup">
                  <p className="typography-h100 text-n-800 mb-[8px]">
                    {getObjectiveLocale('Set Auto Roll Up')}
                  </p>
                  <DropdownRollUp
                    type={type}
                    selectedMetric={selectedMetric}
                    handleChange={handleChangeRollUp}
                    defaultValue={rollUp}
                    disabled={!permissions?.includes('edit_roll_up')}
                    isIntegrated={isIntegrated}
                  />
                </div>
              )}
            </div>
            {showRollUp && objectiveValue.measurement?.rollUp && (
              <div className="flex items-center mt-[16px]">
                <SVGIcon
                  size={16}
                  fillColor="var(--n-800)"
                  iconName="icon-info"
                />
                <p className="typography-paragraph text-n-800 ml-[8px]">
                  {getWordingMetrics(rollUp)}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </ContentWrapper>
  );
};

export default MetricSection;

import React, { useState } from 'react';

import useFilter from 'hooks/useFilter';
import { getObjectiveLocale } from 'utils/HelperUtils';

import PhaseOptions from 'components/project/detail/PhaseOptions';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import useIsFetchingTasks from 'src/hooks/useIsFetchingTasks';

function BoardHeader({
  groupName,
  groupIndex,
  handleAddTask,
  shadowStyle,
  projectId,
  isProject,
  customClass = ''
}) {
  const isFetchingTasks = useIsFetchingTasks({
    groupName
  });

  const [isEllipsis, setIsEllipsis] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const filter = useFilter(
    (state) =>
      state.filterById?.[isProject ? 'projectDetailBoard' : 'tasksFilterBoard']
  );

  return (
    <div
      className={`w-full mb-[16px] group ${customClass}`}
      data-cy={`board-header-${groupIndex}`}
    >
      <div
        className="flex justify-between items-center rounded-[4px] bg-n-000 w-full h-[48px] px-[24px] py-[8px]"
        style={shadowStyle}
      >
        <TooltipContainer
          show={isEllipsis}
          text={groupName}
          position="top"
          classContainer={isProject ? 'w-full' : 'max-w-[264px]'}
          useMaxWidth={false}
        >
          <p
            ref={(ref) => setIsEllipsis(ref?.offsetWidth < ref?.scrollWidth)}
            data-cy="board-header-text"
            className={`typography-h500 text-n-900 truncate ${
              filter?.group === 'priority' ? 'capitalize' : 'uppercase'
            }`}
          >
            {groupName}
          </p>
        </TooltipContainer>
        <div
          className={`flex items-center group-hover:flex ${
            !dialogOpen && 'hidden'
          }`}
        >
          {isProject ? (
            filter?.group === 'phase' && (
              <PhaseOptions
                projectId={projectId}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                type="board"
              />
            )
          ) : (
            <div className="flex justify-center items-center h-[32px] w-[32px] cursor-pointer ml-[16px]">
              <TooltipContainer
                show
                position="bottom"
                text={getObjectiveLocale('Add Task')}
              >
                <SVGIcon
                  iconName="icon-add"
                  fillColor="var(--n-600)"
                  size="24"
                  onClick={() => !isFetchingTasks && handleAddTask()}
                />
              </TooltipContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BoardHeader;

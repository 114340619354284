import React, { useEffect, useState } from 'react';

import { getBehaviourReportSummary } from 'client/PerformanceReport';

import SVGIcon from 'components/shared/SVGIcon';

import OverviewListBehaviorComponent from './OverviewListBehaviorComponent';

function OverviewBehaviourSummaryReportComponent(props) {
  const [behaviourReport, setBehaviourReport] = useState('competency');
  const [quartal, setQuartal] = useState([]);
  const [showChevron, setShowChevron] = useState(false);

  let listQuarter = ['Q1 Jan-Mar', 'Q2 Apr-Jun', 'Q3 Jul-Sep', 'Q4 Okt-Des'];

  let colorbtn = {
    color: 'var(--base-500)',
    backgroundColor: 'var(--base-100)',
    border: 'solid 1px var(--base-600)'
  };

  let backbtn = {
    color: 'var(--n-900)',
    backgroundColor: 'var(--n-200)',
    border: 'solid 1px var(--n-500)'
  };

  const handleButton = (value) => {
    setBehaviourReport(value);
  };

  const handleChevron = () => {
    if (pageXOffset !== undefined) document.getElementById('container');
    let container = document.getElementById('container');
    if (container) {
      container.scrollLeft += 261;
    }
    var chevron = document.getElementById('behaviour-wrapper');
    if (chevron) {
      chevron.offsetWidth * 4;
    }
    var subbox = document.getElementById('subbox')?.offsetWidth;
    if (chevron > subbox) {
      setShowChevron(true);
    }
  };

  const getData = async (newYear) => {
    const userId = props.userId;
    let type = behaviourReport;
    let year = newYear ? newYear : props.year;
    const queryQ1 = {
      period_begin: year + '-01-01T00:00:00.000Z',
      period_end_before: year + '-04-01T00:00:00.000Z'
    };
    const queryQ2 = {
      period_begin: year + '-04-01T00:00:00.000Z',
      period_end_before: year + '-07-01T00:00:00.000Z'
    };
    const queryQ3 = {
      period_begin: year + '-07-01T00:00:00.000Z',
      period_end_before: year + '-10-01T00:00:00.000Z'
    };
    const queryQ4 = {
      period_begin: year + '-10-01T00:00:00.000Z',
      period_end_before: parseInt(year) + 1 + '-01-01T00:00:00.000Z'
    };

    const { data: dataQ1 } = await getBehaviourReportSummary(
      queryQ1,
      userId,
      type
    );
    const { data: dataQ2 } = await getBehaviourReportSummary(
      queryQ2,
      userId,
      type
    );
    const { data: dataQ3 } = await getBehaviourReportSummary(
      queryQ3,
      userId,
      type
    );
    const { data: dataQ4 } = await getBehaviourReportSummary(
      queryQ4,
      userId,
      type
    );

    let quartal = [];
    if (dataQ1) quartal.push(dataQ1);
    if (dataQ2) quartal.push(dataQ2);
    if (dataQ3) quartal.push(dataQ3);
    if (dataQ4) quartal.push(dataQ4);
    setQuartal(quartal);
    handleChevron();
  };

  useEffect(() => {
    getData();
  }, [behaviourReport, props.year]);

  return (
    <div className="behaviour-report-summary-container">
      <span className="title-behaviour"> Behaviour Report Summary </span>
      <button
        style={behaviourReport === 'competency' ? colorbtn : backbtn}
        onClick={() => handleButton('competency')}
        className="btn-standard"
      >
        Standards of Leadership
      </button>
      <button
        style={behaviourReport === 'value' ? colorbtn : backbtn}
        onClick={() => handleButton('value')}
        className="btn-skill"
      >
        Skill
      </button>
      <div className="strength-development">
        <div className="color-strength" />
        Strength
        <div className="color-development" />
        Development
      </div>

      {showChevron && (
        <SVGIcon
          iconName="icon-chevron_right"
          customClass="img-circle"
          onClick={() => handleChevron()}
        />
      )}

      <div className="behaviour-box" id="container">
        <div className="detail-behaviour-wrapper" id="behaviour-wrapper">
          {listQuarter.map((title, index) => (
            <OverviewListBehaviorComponent
              key={index}
              title={title}
              quarter={quartal[index]}
              behaviourReport={behaviourReport}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default OverviewBehaviourSummaryReportComponent;

import React from 'react';

import S3Client from 'client/S3Client';
import { getPreSignUrlValues } from 'client/adminClient.js';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import InputField from 'components/shared/InputField';
import SVGIcon from 'components/shared/SVGIcon';

function Badges({ index, badge, onDelete, onChange }) {
  const _getPreSignUrl = async () => {
    const query = {
      fileType: 'media-resources',
      fileFormat: 'png'
    };
    const { data, error } = await getPreSignUrlValues(query);
    return data;
  };

  const handleUploadBadge = async (e) => {
    let file = e.target.files[0];

    if (file) {
      if (
        file.type == 'image/png' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/jpg'
      ) {
        let presignData = await _getPreSignUrl();
        if (presignData) {
          const { status } = await S3Client(presignData.presignedUrl, file);

          if (status == 200) {
            onChange(presignData?.secureUrl, index, 'badgeIconUrl');
          }
        } else {
          alert('Presign Link Not Found!');
        }
      } else {
        alert('Wrong file type! file type must be png, jpg or jpeg!');
        return false;
      }
    } else {
      alert('Empty file image');
    }
  };

  return (
    <div className="card-badge border-n400 rounded-[4px] bg-n-200 pt-[16px] pb-[25px] mb-[24px]">
      <div className="px-[24px] header-badge border-bottom-n-400 pb-[16px] flex justify-between items-center">
        <p className="typography-h500 text-n-900">{`Badge ${index + 1}`}</p>
        <Button.Tertiary onClick={() => onDelete(index)}>
          <SVGIcon
            iconName={'icon-delete'}
            fillColor="var(--base-600)"
            size="16"
            customClass="mr-[8px]"
          />
          Delete
        </Button.Tertiary>
      </div>
      <div className=" px-[24px] body-badge mt-[24px] flex justify-between items-center">
        <div className="flex flex-col mr-[16px]">
          <p className="typography-h100 text-n-800 mb-[8px]">Badge Name</p>
          <InputField
            dataCy="name-badge"
            inputClass={'w-[322px]'}
            placeholder="Type here..."
            value={badge?.title}
            handleChange={(e) =>
              onChange(e.currentTarget.value, index, 'title')
            }
          />
        </div>
        <div className="flex flex-col">
          <p className="typography-h100 text-n-800 mb-[8px]">Rank</p>
          <div className="flex items-center mr-[16px]">
            <InputField
              dataCy="rank-min"
              type="number"
              inputClass={'w-[80px]'}
              value={badge?.rankMin}
              handleChange={(e) =>
                onChange(e.currentTarget.value, index, 'rankMin')
              }
            />
            <p className="ml-[8px] mr-[8px] text-n-600">—</p>
            <InputField
              dataCy="rank-max"
              type="number"
              inputClass={'w-[80px]'}
              value={badge?.rankMax}
              handleChange={(e) =>
                onChange(e.currentTarget.value, index, 'rankMax')
              }
            />
          </div>
        </div>
        <div className="flex flex-col">
          <p className="typography-h100 text-n-800 mb-[8px]">Icon</p>
          <div className="flex">
            {badge?.badgeIconUrl && (
              <AWSImage
                className="w-[36px] h-[36px] mr-[8px]"
                src={badge?.badgeIconUrl}
              />
            )}
            <label
              className="btn text-btn px-[8px]"
              type="label"
              htmlFor={`attachment-input-${index}`}
            >
              {badge?.badgeIconUrl ? 'Edit Icon' : 'Upload Icon'}
            </label>
            <input
              className="input-file hide-input"
              id={`attachment-input-${index}`}
              type="file"
              data-cy="attachment-input"
              onChange={(e) => handleUploadBadge(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Badges;

import cloneDeep from 'lodash/cloneDeep';
import { create } from 'zustand';

import { updateObjectivePhases } from 'client/ObjectivesClient';

const useEditPhase = create((set, get) => ({
  editedPhaseData: [],
  setEditedPhaseData: (data) => set({ editedPhaseData: data }),

  currentPhaseData: [],
  setCurrentPhaseData: (data) => set({ currentPhaseData: data }),

  deletedPhaseIds: [],
  setDeletedPhaseIds: (id) =>
    set((state) => ({ deletedPhaseIds: [...state.deletedPhaseIds, id] })),

  resetEditPhaseData: () =>
    set({ editedPhaseData: [], deletedPhaseIds: [], currentPhaseData: [] }),
  updatePhaseData: (projectId, excludeDeleteTasks) =>
    _updatePhaseData(get, projectId, excludeDeleteTasks),

  getListPhasesToBeRefetch: () => _getListPhasesToBeRefetch(get)
}));

const _updatePhaseData = async (get, projectId, excludeDeleteTasks) => {
  const { editedPhaseData } = get();

  let body = cloneDeep(editedPhaseData);
  body = body.map((data) => {
    return {
      id: data?.isNew ? null : data.id,
      name: data.name,
      bgColorHex: data.bgColorHex,
      colorHex: data.colorHex,
      isComplete: data.isComplete
    };
  });

  return updateObjectivePhases(projectId, {
    phases: body,
    excludeDeleteTasks
  });
};

const _getListPhasesToBeRefetch = (get) => {
  const { currentPhaseData, editedPhaseData } = get();

  let listPhasesToBeRefetch = [];
  currentPhaseData.map((currentPhase) => {
    const editedPhase = editedPhaseData?.find(
      (phase) => phase?.id === currentPhase?.id
    );
    if (editedPhase) {
      if (
        currentPhase.bgColorHex != editedPhase.bgColorHex &&
        currentPhase.colorHex != editedPhase.colorHex
      ) {
        listPhasesToBeRefetch.push(editedPhase);
      }
    }
  });

  return listPhasesToBeRefetch;
};

export default useEditPhase;

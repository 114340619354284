import React from 'react';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import SVGIcon from 'components/shared/SVGIcon';
import Tooltips from 'components/shared/Tooltips/Tooltips';

dayjs.extend(isBetween);

function getInfoIconMilestones(milestone, withBorder) {
  let icon, text, color, background;
  if (milestone?.progressState == 'not_started') {
    icon = 'icon-lock_outline';
    background = 'bg-n-100';
    color = 'var(--n-600)';
    text = 'Not Started';
  } else if (milestone?.progressState == 'in_progress') {
    icon = 'icon-access_time';
    background = 'bg-y-100';
    color = 'var(--y-700)';
    text = 'In Progress';
  } else if (milestone?.progressState == 'achieved') {
    icon = 'icon-check';
    background = 'bg-b-100';
    color = 'var(--b-700)';
    text = 'Achieved';
  } else if (milestone?.progressState == 'not_achieved') {
    icon = 'icon-clear';
    background = 'bg-r-100';
    color = 'var(--r-700)';
    text = 'Not Achieved';
  }

  return (
    <Tooltips
      isEllipsis
      tooltipClass="h-[20px] px-[4px] py-[2px] capitalize"
      text={text}
    >
      <SVGIcon
        size="16"
        iconName={icon}
        fillColor={color}
        customClass={`${withBorder ? `${background} rounded-full p-12` : ''}`}
      />
    </Tooltips>
  );
}

function filteredDateMilestone(milestone, objStartDate, objDueDate) {
  let inBetweenMilestone = [];
  milestone?.map((data) => {
    const startFormatDate = dayjs(data?.startsAt).format('YYYY-MM-DD');
    const dueFormatDate = dayjs(data?.endsBefore).format('YYYY-MM-DD');
    let isBetweenStartsAt = dayjs(startFormatDate)?.isBetween(
      objStartDate,
      objDueDate,
      undefined,
      '[]'
    );
    let isBetweenEndsBefore = dayjs(dueFormatDate)?.isBetween(
      objStartDate,
      objDueDate,
      undefined,
      '[]'
    );
    if (isBetweenEndsBefore || isBetweenStartsAt) {
      inBetweenMilestone.push(data);
    }
  });
  return inBetweenMilestone;
}

export { getInfoIconMilestones, filteredDateMilestone };

import React from 'react';

import SVGIcon from 'components/shared/SVGIcon';
import WhiteCardWithFooter from 'components/shared/WhiteCardWithFooter';
import 'components/shared/card/OverallProgressCard/_overallPhase.scss';

const OverallPhase = ({
  onClick,
  active = 'all',
  listPhases,
  totalObjectives
}) => {
  let mapValues = listPhases?.map(({ qty }) => qty);

  return (
    <WhiteCardWithFooter
      containerClass="mr-[16px]"
      bodyClass="overall-card-height"
    >
      <p className="overall-objective-title typography-h200">Phases</p>
      <div className="flex flex-row mt-[16px] flex justify-between">
        <div className="text-center px-[4px] py-[4px]">
          <h5 className="typography-h600 mb-[4px]">{totalObjectives}</h5>
          <p className="typography-h100 text-n-600">Total objectives</p>
        </div>
        <div className="divider-line" />
        <div
          className={`text-center phase-hover ${
            active.includes('running') || active === 'all' ? '' : 'opacity-50'
          }`}
          onClick={() => onClick('running')}
        >
          <div className="tooltip">Filter on this specific status</div>
          <span className="flex flex justify-center items-center mb-[4px]">
            <SVGIcon
              iconName="icon-access_time"
              size="16"
              fillColor="var(--n-600)"
              customClass="mr-[4px]"
            />
            <h5 className="typography-h600 mb-[0px]">{mapValues[0]}</h5>
          </span>
          <p className="typography-h100 text-n-600">On-going</p>
        </div>
        <div
          className={`text-center phase-hover ${
            active.includes('completed') || active === 'all' ? '' : 'opacity-50'
          }`}
          onClick={() => onClick('completed')}
        >
          <div className="tooltip">Filter on this specific status</div>
          <span className="flex flex justify-center items-center mb-[4px]">
            <SVGIcon
              iconName="icon-check_circle"
              size="16"
              fillColor="var(--g-600)"
              customClass="mr-[4px]"
            />
            <h5 className="typography-h600 mb-[0px]">{mapValues[1]}</h5>
          </span>
          <p className="typography-h100 text-n-600">Completed</p>
        </div>
        <div
          className={`text-center phase-hover ${
            active.includes('reviewed') || active === 'all' ? '' : 'opacity-50'
          }`}
          onClick={() => onClick('reviewed')}
        >
          <div className="tooltip">Filter on this specific status</div>
          <span className="flex flex justify-center items-center mb-[4px]">
            <SVGIcon
              iconName="icon-reviewed"
              size="16"
              fillColor="var(--base-600)"
              customClass="mr-[4px]"
            />
            <h5 className="typography-h600 mb-[0px]">{mapValues[2]}</h5>
          </span>
          <p className="typography-h100 text-n-600">Reviewed</p>
        </div>
        <div className="divider-line" />
        <div
          className={`text-center phase-hover`}
          onClick={() => onClick('overdue')}
        >
          <div className="tooltip">Filter on this specific status</div>
          <span className="flex flex justify-center items-center mb-[4px]">
            <SVGIcon
              iconName="icon-overdue"
              size="16"
              fillColor="var(--base-600)"
              customClass="mr-[4px]"
            />
            <h5 className="typography-h600 mb-[0px]">{mapValues[3]}</h5>
          </span>
          <p className="typography-h100 text-n-600">Overdue</p>
        </div>
      </div>
    </WhiteCardWithFooter>
  );
};

export default OverallPhase;

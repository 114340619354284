import React from 'react';

import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';

import { getObjectiveLocale } from 'utils/HelperUtils';

import ListConfig from './ListConfig';

const SuggestionAndPrefilledConfig = ({
  listPhases,
  trackType,
  templateId
}) => {
  return (
    <>
      <p className="typography-button text-n-800">
        {getObjectiveLocale(
          'Select the phases that will use pre-filled answer on the competencies scoring.'
        )}
      </p>
      {listPhases.map((phase, index) => (
        <div
          key={index}
          className="mt-[16px]"
          data-cy={`list_suggestion_n_prefilled_config_${snakeCase(
            phase.phaseType
          )}_${trackType}`}
        >
          <p className="typography-paragraph text-n-800 mb-[4px]">
            {startCase(phase.phaseType.replace('Review', ''))}
          </p>
          {phase.isMeetRequirements ? (
            <ListConfig
              trackType={trackType}
              phaseType={phase.phaseType}
              templateId={templateId}
            />
          ) : (
            <p className="typography-button text-n-800 bg-n-100 p-[8px]">
              {getObjectiveLocale(
                `${startCase(
                  phase.phaseType
                )} do not meet requirements to use pre-filled answers`
              )}
            </p>
          )}
        </div>
      ))}
    </>
  );
};

export default SuggestionAndPrefilledConfig;

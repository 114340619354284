import React, { useEffect, useRef, useState } from 'react';

import {
  UserSuggestionProvider,
  useUserSuggestion
} from 'context/UserSuggestionContext';
import useDebounce from 'hooks/useDebounce';
import { getExtraInformation, getObjectiveLocale } from 'utils/HelperUtils';

import InlineDialog, {
  useInlineDialog
} from 'components/design-system/inline-dialog/InlineDialog';
import SingleUser from 'components/design-system/single-user/SingleUser';
import ConditionalWrapper from 'components/shared/ConditionalWrapper';
import SVGIcon from 'components/shared/SVGIcon';

import FloatingComponent from '../objectives/compact-objective/fragments/FloatingComponent';

function InlineDialogSelectUser({
  objectiveValue,
  handleChange,
  excludeValue,
  selectedValue,
  permission,
  selectedOwner,
  position = 'right',
  type,
  dataCy,
  header = 'Objective / Task Reviewer',
  onCloseDialog,
  extendedRoleType = null,
  isRemoveable = true,
  useRemoveIcon = true,
  useFloating = false,
  customPlaceholder = false,
  additionalQuery,
  removeUserCallback
}) {
  const [search, setSearch] = useState(null);
  const debouncedSearchTerm = useDebounce(search, 500);
  const [isLoading, setIsLoading] = useState([]);
  const { suggestion, hasMore, getSuggestionData, appendSuggestionData } =
    useUserSuggestion();

  const isFirstRender = useRef(true);

  let arrayOwnerId = selectedOwner?.map((user) => user?.userId) || [];
  const reviewers =
    selectedValue?.map((selectedData) => {
      return {
        type: 'circle',
        name: selectedData?.user?.name,
        src: selectedData?.user?.profilePicture,
        tooltip: true
      };
    }) || [];

  const options = suggestion?.map((value) => {
    return {
      id: value?.id,
      image: value?.profilePicture,
      avatarName: value?.name,
      text: value?.name,
      subText: getExtraInformation(value?.title, value?.subtitle),
      customClass: 'w-[350px]',
      onClick: () => _handleChange(value),
      selected: selectedValue[0] && selectedValue[0]?.user?.id == value?.id,
      dataCy: `user-${value?.id}`
    };
  });

  const _handleChange = (sel, isFromRemoveIcon = false) => {
    let newSelectedValue = [...selectedValue];
    if (!newSelectedValue.some((value) => value.userId === sel.id)) {
      newSelectedValue = newSelectedValue.filter(
        (newSelected) => newSelected.role !== type
      );
      newSelectedValue.push({
        user: sel,
        role: type,
        extendedRole: extendedRoleType,
        userId: sel.id,
        visible: true
      });
    } else {
      newSelectedValue = newSelectedValue.filter(
        (newSelected) => newSelected.userId !== sel.id
      );
    }

    handleChange(newSelectedValue, type, extendedRoleType);

    if (isFromRemoveIcon) {
      removeUserCallback && removeUserCallback();
    }
  };

  const onScroll = (e) => {
    const loadMore = async () => {
      const objectiveType = objectiveValue.isProject
        ? 'project'
        : objectiveValue.type;
      const query = {
        q: search,
        exclude: excludeValue,
        role: type,
        type: objectiveType,
        ...(additionalQuery && { ...additionalQuery })
      };
      await appendSuggestionData(query);
    };

    let target = e.target;
    let cond = target.scrollTop + target.clientHeight == target.scrollHeight;

    if (hasMore && cond) {
      loadMore();
    }
  };

  const _getSuggestionData = async () => {
    setIsLoading(true);
    const objectiveType = objectiveValue.isProject
      ? 'project'
      : objectiveValue.type;
    const query = {
      q: search,
      exclude: excludeValue,
      assigneeId: arrayOwnerId,
      role: type,
      type: objectiveType,
      ...(additionalQuery && { ...additionalQuery })
    };
    await getSuggestionData(query);
    setIsLoading(false);
  };

  const onOpenDialog = () => {
    setSearch('');
  };

  const removeIconCallback =
    permission && useRemoveIcon
      ? () => _handleChange(selectedValue[0]?.user, true)
      : null;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      _getSuggestionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <InlineDialog
        header={getObjectiveLocale(header)}
        search={(e) => setSearch(e.target.value)}
        customClass="w-full"
      >
        <InlineDialog.Trigger disabled={!permission} dataCy={dataCy}>
          <div className="px-[8px] py-[8px] rounded-full hover:bg-base-30024 single-user-selected">
            {selectedValue?.length > 0 ? (
              <SingleUser
                userData={reviewers[0]}
                removeIconCallback={isRemoveable ? removeIconCallback : false}
                isDisabled={!permission}
              />
            ) : (
              <div className="text-n-600 flex items-center">
                <SVGIcon
                  size="24"
                  fillColor="var(--n-600)"
                  iconName="icon-default-user"
                  customClass="mr-[8px]"
                />

                {permission
                  ? getObjectiveLocale(
                      `Select ${customPlaceholder ? type : 'User'}`
                    )
                  : getObjectiveLocale(
                      `No ${customPlaceholder ? type : 'reviewer'}`
                    )}
              </div>
            )}
          </div>
        </InlineDialog.Trigger>
        <MenuItems
          onCloseDialog={onCloseDialog}
          position={position}
          options={options}
          onScroll={onScroll}
          onOpenDialog={onOpenDialog}
          isLoading={isLoading}
          useFloating={useFloating}
        />
      </InlineDialog>
    </>
  );
}

const MenuItems = ({
  onCloseDialog,
  position,
  options,
  onScroll,
  onOpenDialog,
  isLoading,
  useFloating
}) => {
  const { open } = useInlineDialog();

  return (
    <ConditionalWrapper
      condition={useFloating}
      wrapper={(children) => (
        <>
          {open && (
            <FloatingComponent customContentHeight={480}>
              {children}
            </FloatingComponent>
          )}
        </>
      )}
    >
      <InlineDialog.MenuItems
        onCloseDialog={onCloseDialog}
        customClass="w-[350px]"
        position={position}
        options={options}
        onScroll={onScroll}
        onOpenDialog={onOpenDialog}
        isLoading={isLoading}
      />
    </ConditionalWrapper>
  );
};

const SelectUserComponent = (props) => (
  <UserSuggestionProvider>
    <InlineDialogSelectUser {...props} />
  </UserSuggestionProvider>
);

export default SelectUserComponent;

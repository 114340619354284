import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { useImmer } from 'use-immer';

import { createTeam } from 'client/ObjectivesClient';
import { getManager } from 'client/UserProfile';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useUser } from 'context/UserContext';
import { UserSuggestionProvider } from 'context/UserSuggestionContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/shared/modal/Modal';
import { useToastContext } from 'src/context/ToastContext';

import AlignTribe from './AlignTribe';
import ChooseMemberTeam from './ChooseMemberTeam';
import SetupTeamName from './SetupTeamName';

dayjs.extend(quarterOfYear);

const listModalSteps = ['Setup Team Name', 'Add member', 'Align team'];

const ModalCreateTeam = ({
  eventOnClickClose,
  type,
  saveCallback,
  tribe = {}
}) => {
  const { user, manager } = useUser();
  const { refetchObjectives, invalidateQueries } = useRefetchQuery();
  const { addToast } = useToastContext();

  const initialTeamValue = {
    name: '',
    description: '',
    visibility: 'public_team',
    teamPicture: null,
    involvements: [
      { role: 'leader', user: user },
      { role: 'reviewer', user: manager }
    ]
  };

  const [teamValue, setTeamValue] = useImmer(initialTeamValue);
  const [currentStep, setCurrentStep] = useState(listModalSteps[0]);
  const [members, setMembers] = useState([]);
  const [selectedTribe, setSelectedTribe] = useState(tribe);

  const stepNumber =
    listModalSteps.findIndex((step) => step === currentStep) + 1;
  const isFirstStep = stepNumber === 1;
  const isLastStep = stepNumber === (type == 'tribe' ? 2 : 3);

  const getModalTitle = () => {
    let text = '';
    switch (currentStep) {
      case 'Setup Team Name':
        text = `Give the ${type} a name and describe it`;
        break;
      case 'Add member':
        text = 'Add member';
        break;
      case 'Align team':
        text = 'Align this squad to tribe';
        break;
    }
    return getObjectiveLocale(`Step ${stepNumber} - ${text}`);
  };

  useEffect(() => {
    const getDefaultMembers = async () => {
      const defaultMembers = [
        { user: user, role: 'assignee', extendedRole: 'leader' }
      ];

      const { data } = await getManager(user?.id);
      if (data?.[0]?.user) {
        defaultMembers.push({
          user: data?.[0]?.user,
          role: 'assigner'
        });
      }

      setMembers(defaultMembers);
    };

    getDefaultMembers();
  }, [user]);

  const handleSubmitModal = async () => {
    let body = {
      ...teamValue,
      parentId: selectedTribe?.id || null,
      teamType: type,
      involvements: members.map((member) => ({
        userId: member.user.id,
        role: member.role,
        ...(member?.extendedRole && { extendedRole: member?.extendedRole })
      }))
    };

    const { isSuccess } = await createTeam(body);

    if (isSuccess) {
      eventOnClickClose();
      refetchObjectives('myteams');
      invalidateQueries(['objectives', 'myteams']);
      saveCallback && saveCallback();
    }
  };

  const toastWarning = (msg) => {
    addToast({
      title: getObjectiveLocale('Cannot go to the next step'),
      msg: getObjectiveLocale(msg),
      type: 'error'
    });
  };

  const handleNextStep = () => {
    const memberId = members?.find(
      ({ role, extendedRole }) => role === 'assignee' && !extendedRole
    )?.user?.id;

    const leaderId = members?.find(
      ({ role, extendedRole }) =>
        role === 'assignee' && extendedRole === 'leader'
    )?.user?.id;

    const checkStep =
      (stepNumber == 1 && isEmpty(teamValue.name)) ||
      (stepNumber == 2 && (!memberId || !leaderId));

    if (checkStep) {
      toastWarning(
        isEmpty(teamValue.name)
          ? `${startCase(type)} name cannot be empty`
          : 'The team must have 1 leader and at least 1 member.'
      );
    } else if (!isLastStep) {
      setCurrentStep(listModalSteps[stepNumber]);
    } else {
      handleSubmitModal();
    }
  };

  return (
    <>
      <Modal
        title={getModalTitle()}
        dataCyModal="modal-create-team"
        withSteps={{
          currentStepNumber: stepNumber,
          lastStepNumber: listModalSteps.length - (type == 'tribe' ? 1 : 0)
        }}
        withPrimaryBtn={{
          title: isLastStep
            ? getObjectiveLocale('Create')
            : getObjectiveLocale('Next'),
          dataCy: 'primary-create-team',
          onClick: () => handleNextStep()
        }}
        withSecondaryBtn={{
          title: isFirstStep
            ? getObjectiveLocale('Cancel')
            : getObjectiveLocale('Back'),
          dataCy: 'secondary-create-team',
          onClick: () =>
            isFirstStep
              ? eventOnClickClose()
              : setCurrentStep(listModalSteps[stepNumber - 2])
        }}
        withCloseIcon
        eventOnClickClose={eventOnClickClose}
        isUseOptionalHeader={stepNumber == 3}
      >
        {currentStep === 'Setup Team Name' && (
          <SetupTeamName
            teamValue={teamValue}
            setTeamValue={setTeamValue}
            type={type}
          />
        )}
        {currentStep === 'Add member' && (
          <UserSuggestionProvider>
            <ChooseMemberTeam members={members} setMembers={setMembers} />
          </UserSuggestionProvider>
        )}
        {currentStep === 'Align team' && (
          <AlignTribe
            selectedTribe={selectedTribe}
            setSelectedTribe={setSelectedTribe}
          />
        )}
      </Modal>
    </>
  );
};

export default ModalCreateTeam;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import Checkbox from 'components/shared/Checkbox';

const CloneSubObjective = ({
  objectiveDetail,
  cloneObj,
  setCloneObj,
  objectiveType
}) => {
  return (
    <>
      {objectiveDetail?.childrenCount > 0 ? (
        <div className="flex items-center mt-[16px]">
          <Checkbox
            dataCy={'checkbox-sub-clone'}
            name={String(0)}
            id={String(0)}
            checked={cloneObj?.cloneParams?.subObjectives}
            onChange={() =>
              setCloneObj({
                ...cloneObj,
                cloneParams: {
                  subObjectives: !cloneObj?.cloneParams?.subObjectives
                }
              })
            }
            customContainerClass="mr-[8px]"
          />
          <p
            onClick={() =>
              setCloneObj({
                ...cloneObj,
                cloneParams: {
                  subObjectives: !cloneObj?.cloneParams?.subObjectives
                }
              })
            }
            className="typography-paragraph cursor-pointer"
          >
            {getObjectiveLocale(
              objectiveType === 'project'
                ? 'Clone Project Task'
                : 'Clone Sub Goal'
            )}
          </p>
        </div>
      ) : (
        <p className="typography-paragraph text-n-600 mt-[16px]">
          {objectiveType == 'project'
            ? 'There’s no tasks in the project to be cloned'
            : objectiveType == 'task'
            ? `There’s no subtasks for this task to be cloned`
            : `There’s no subgoals for this goal to be cloned`}
        </p>
      )}
    </>
  );
};

export default CloneSubObjective;

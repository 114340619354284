import React, { useContext, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import AntdCalendar, {
  useCalendar
} from 'components/shared/Calendar/AntdCalendar';
import FloatingComponent from 'src/components/objectives/compact-objective/fragments/FloatingComponent';
import {
  whichBgColorClassName,
  whichColor,
  whichStatus
} from 'src/utils/ObjectivesHelper';

import { GanttChartContext } from '../GanttChart';

const beforeTrigger = (e, setOpen, setCalendarPosition) => {
  e.stopPropagation();
  const rect = e.target.getBoundingClientRect();
  setCalendarPosition({
    left: e.clientX - rect.left,
    top: e.clientY - rect.top
  });
  !e.shiftKey && !(e.ctrlKey || e.metaKey) && setOpen(true);
};

const setDateHandler = ({
  singleObjectiveOptions,
  value,
  dateValue,
  id,
  setDateValue
}) => {
  const callbackUpdateFn = singleObjectiveOptions?.callbackUpdateFn;
  const [start, due, recurrence] = value;
  if (
    dayjs(start).startOf('day').toISOString() == dateValue[0] &&
    dayjs(due).endOf('day').toISOString() == dateValue[1]
  )
    return;
  const payload = {
    startDate: dayjs(start).startOf('day').toISOString(),
    dueDate: dayjs(due).endOf('day').toISOString(),
    ...(recurrence && { recurrence })
  };

  callbackUpdateFn && callbackUpdateFn(id, payload);

  setDateValue([start, due]);
};

const GanttBar = ({
  widthElement,
  dataObjective,
  group,
  subContentOptions,
  dateValue,
  setDateValue
}) => {
  const { singleObjectiveOptions } = subContentOptions || {};
  const { recurrence, name, id, measurement } = dataObjective;

  const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });

  const { setDisableScroll } = useContext(GanttChartContext);

  const barColor = useMemo(() => {
    const { barColorSelector } = singleObjectiveOptions;

    if (!barColorSelector) {
      return group.bgColorHex;
    }
    let value = { ...dataObjective };

    for (let i = 0; i < barColorSelector.length; i++) {
      if (!value[barColorSelector[i]]) {
        value = null;
        break;
      }
      if (barColorSelector[i] == 'progressColorHex') {
        value = whichBgColorClassName(value[barColorSelector[i]], false, 300);
        break;
      }
      value = value[barColorSelector[i]];
      if (i == barColorSelector.length - 1) {
        value = '#' + value;
      }
    }
    return value;
  }, [group, singleObjectiveOptions, dataObjective]);

  const setDateValueHandler = async (value) => {
    setDateHandler({
      singleObjectiveOptions,
      value,
      dateValue,
      id,
      setDateValue
    });
  };

  return (
    <AntdCalendar customClass="h-[24px] w-full z-[200]">
      <CalendarContent
        beforeTrigger={(e, setOpen) => {
          singleObjectiveOptions?.useOpenCalendar &&
            beforeTrigger(e, setOpen, setCalendarPosition);
        }}
        widthElement={widthElement}
        name={name}
        measurement={measurement}
        calendarPosition={calendarPosition}
        dateValue={dateValue}
        setDateValueHandler={setDateValueHandler}
        isAlwaysOpen
        recurrence={recurrence}
        barColor={barColor}
        setDisableScroll={setDisableScroll}
      />
    </AntdCalendar>
  );
};

const CalendarContent = ({
  beforeTrigger,
  widthElement,
  name,
  measurement,
  calendarPosition,
  dateValue,
  setDateValueHandler,
  recurrence,
  barColor,
  setDisableScroll
}) => {
  const { open } = useCalendar();
  const [tooltipBasePosition, setTooltipBasePosition] = useState({
    left: 0,
    top: 0
  });
  const [isShowTooltip, setShowTooltip] = useState(false);

  const { progressColorHex, progressPercentage } = measurement || {};

  const showTooltip = (e) => {
    setShowTooltip(true);
    const rect = e.target.getBoundingClientRect();
    setTooltipBasePosition({
      left: e.clientX - rect.left,
      top: e.clientY - rect.top
    });
  };

  useEffect(() => {
    if (open) {
      setDisableScroll(true);
      return;
    }
    setDisableScroll(false);
  }, [open, setDisableScroll]);

  return (
    <>
      <AntdCalendar.Trigger
        beforeTrigger={beforeTrigger}
        customClass="h-full w-full"
      >
        <div
          onMouseEnter={showTooltip}
          onMouseLeave={() => setShowTooltip(false)}
          className={`h-full rounded-full ${
            widthElement > 24 ? 'px-[12px]' : ''
          } flex items-center relative overflow-hidden justify-between w-full`}
          style={{
            background: barColor ? barColor : 'var(--n-300)'
          }}
        >
          {widthElement > 24 && (
            <p className="typography-h100 text-n-900 truncate">{name}</p>
          )}
          {measurement && widthElement > 96 && (
            <p className="typography-h100 text-n-900 truncate min-w-[24px] text-right">
              {progressPercentage}%
            </p>
          )}
        </div>
      </AntdCalendar.Trigger>
      <div
        className="absolute"
        style={{ top: '-8px', left: tooltipBasePosition.left }}
      >
        {isShowTooltip && (
          <FloatingComponent customClass="w-[400px]">
            <div
              className="bg-n-000 flex flex-col w-fit max-w-[400px] p-[16px] shadow-overlay rounded "
              style={{ transform: 'translateY(-100%)' }}
            >
              <p className="typography-h400 text-n-900">{name}</p>
              {measurement && (
                <p
                  className={`mt-[8px] typography-h100 ${whichColor[progressColorHex]}`}
                >
                  {progressPercentage}%{' '}
                  {progressColorHex && whichStatus[progressColorHex]}
                </p>
              )}
            </div>
          </FloatingComponent>
        )}
      </div>
      <div
        className="calendar-wrapper absolute"
        style={{
          top: calendarPosition.top,
          left: calendarPosition.left
        }}
      >
        <AntdCalendar.Content
          position="middle"
          dateValue={dateValue}
          setDateValueHandler={setDateValueHandler}
          type="absolute"
          recurrenceValue={recurrence}
          useScrollClose
          isGoals
          useRepetition
        />
      </div>
    </>
  );
};

export default GanttBar;

import React, { useEffect, useState } from 'react';

import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import SuggestionAndPrefilledCard from './SuggestionAndPrefilledCard';

const initialListTracks = [
  {
    trackType: 'values_scoring',
    name: 'Value Final Scoring'
  },
  {
    trackType: 'competencies_scoring',
    name: 'Competencies Final Scoring'
  },
  {
    trackType: 'goals_scoring',
    name: 'Goal Scoring'
  },
  {
    trackType: 'tasks_scoring',
    name: 'Task Scoring'
  },
  {
    trackType: 'general_questionnaire',
    name: 'General Questionnaire'
  }
];

const SuggestionAndPrefilledSection = () => {
  const { cycleData } = useCreateCycle();

  const [listTracks, setListTracks] = useState(initialListTracks);

  useEffect(() => {
    const phases = ['selfReview', 'managerReview', 'indirectManagerReview'];
    const listCustomAttributeTrack = [];

    phases.map((phase) => {
      if (cycleData?.[phase]?.trackConfigs?.length > 0) {
        const customAttributeTrack = cycleData?.[phase]?.trackConfigs?.filter(
          (trackConfig) => trackConfig.type === 'review_aspects_scoring'
        );

        const restructureCustomAttributeTrack =
          customAttributeTrack?.map((track) => {
            return {
              trackType: track.type,
              templateId: track.template.id,
              name: track.template.name
            };
          }) || [];

        restructureCustomAttributeTrack.map((track) => {
          if (
            !listCustomAttributeTrack.find(
              (listCustomAttr) => listCustomAttr.templateId === track.templateId
            )
          ) {
            listCustomAttributeTrack.push(track);
          }
        });
      }
    });

    if (listCustomAttributeTrack?.length > 0) {
      setListTracks([...initialListTracks, ...listCustomAttributeTrack]);
    } else {
      setListTracks(initialListTracks);
    }
  }, [cycleData]);

  return (
    <div id="suggestion-rating-section" className="mt-[40px] py-[40px]">
      <p className="typography-h500 text-n-900 mb-[4px]">
        {getObjectiveLocale('Suggestion and Pre-filled answers')}
      </p>
      <p className="typography-paragraph text-n-800">
        {getObjectiveLocale(
          'The option to let scoring tracks to be pre-filled in based on answers on other review phases. Please Note that the track template used should be the same between these phases and it will only work for some configuration options.'
        )}
      </p>

      {listTracks.map((track, index) => (
        <SuggestionAndPrefilledCard
          trackType={track.trackType}
          templateId={track.templateId}
          label={track.name}
          key={index}
        />
      ))}
    </div>
  );
};

export default SuggestionAndPrefilledSection;

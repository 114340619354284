import { Tree } from '@coutcout/tree-datastructure';
import range from 'lodash/range';
import size from 'lodash/size';
import { create } from 'zustand';

const addNewTaskOneLevel = (group, position, set) => {
  if (position === 'bottom') {
    return set((state) => ({
      tasks: {
        ...state.tasks,
        [group]: [...state.tasks[group], new Tree({ name: '', id: -1 })]
      }
    }));
  }

  if (position === 'top') {
    return set((state) => ({
      tasks: {
        ...state.tasks,
        [group]: [new Tree({ name: '', id: -1 }), ...state.tasks[group]]
      }
    }));
  }
};

const addNewTaskTwoLevels = (group1, group2, position, set) => {
  if (position === 'bottom') {
    return set((state) => ({
      tasks: {
        ...state.tasks,
        [group1]: {
          ...state.tasks[group1],
          [group2]: [
            ...state.tasks[group1][group2],
            new Tree({ name: '', id: -1 })
          ]
        }
      }
    }));
  }

  if (position === 'top') {
    return set((state) => ({
      tasks: {
        ...state.tasks,
        [group1]: {
          ...state.tasks[group1],
          [group2]: [
            new Tree({ name: '', id: -1 }),
            ...state.tasks[group1][group2]
          ]
        }
      }
    }));
  }
};

const removeNullTaskOneLevel = (group, set) => {
  return set((state) => ({
    tasks: {
      ...state.tasks,
      [group]: [
        ...state.tasks[group].filter((task) => task.getRoot().value.name !== '')
      ]
    }
  }));
};

const removeNullTaskTwoLevels = (group1, group2, set) => {
  return set((state) => ({
    tasks: {
      ...state.tasks,
      [group1]: {
        ...state.tasks[group1],
        [group2]: [
          ...state.tasks[group1][group2].filter(
            (task) => task.getRoot().value.name !== ''
          )
        ]
      }
    }
  }));
};

const updateEndTaskOneLevel = (state, endGroupIndex, endTaskId) => {
  const startTaskId = state.startTask.taskId;

  if (startTaskId == endTaskId) {
    return {
      selectedTasks: [],
      startTask: null
    };
  } else {
    return {
      selectedTasks: getSelectedOneLevel(state, endGroupIndex, endTaskId)
    };
  }
};

const updateEndTaskTwoLevels = (
  state,
  endSectionIndex,
  endGroupIndex,
  endTaskId
) => {
  const startTaskId = state.startTask.taskId;

  if (startTaskId == endTaskId) {
    return {
      selectedTasks: [],
      startTask: null
    };
  } else {
    return {
      selectedTasks: getSelectedTwoLevels(
        state,
        endSectionIndex,
        endGroupIndex,
        endTaskId
      )
    };
  }
};

const getSelectedOneLevel = (state, endGroupIndex, endTaskId) => {
  const startGroupIndex = state.startTask.groupIndex;
  const startTaskId = state.startTask.taskId;

  let selectedTasks = [];
  let firstTaskId = null;

  // to enable selection from bottom group to top group
  // get the minGroupIndex (above) and maxGroupIndex (below)
  let min = Math.min(startGroupIndex, endGroupIndex);
  let max = Math.max(startGroupIndex, endGroupIndex);

  // map each group from top to bottom
  // then map flattened task of each group
  // push selectedTask to array
  range(min, max + 1).forEach((groupIndex) => {
    const groupName = state.groupIndexHash[groupIndex];
    const flatten = getFlattenTasks(state.tasks[groupName] ?? []);

    flatten.some((task) => {
      // when first task is found add to array and continue to the next loop
      if (!firstTaskId && (task.id == startTaskId || task.id == endTaskId)) {
        firstTaskId = task.id;
        selectedTasks.push({ ...task, groupName: groupName });

        return false;
      }

      if (firstTaskId) {
        selectedTasks.push({ ...task, groupName: groupName });
      }

      // array.some will stop the loop if we return true
      // here we stop the loop if we found the second task
      return firstTaskId && (task.id == startTaskId || task.id == endTaskId);
    });
  });

  return selectedTasks;
};

const getSelectedTwoLevels = (
  state,
  endSectionIndex,
  endGroupIndex,
  endTaskId
) => {
  const startSectionIndex = state.startTask.sectionIndex;

  const startGroupIndex = state.startTask.groupIndex;
  const startTaskId = state.startTask.taskId;

  let selectedTasks = [];
  let firstTaskId = null;

  let minSection = Math.min(startSectionIndex, endSectionIndex);
  let maxSection = Math.max(startSectionIndex, endSectionIndex);

  range(minSection, maxSection + 1).forEach((sectionIndex) => {
    const sectionName = state.sectionIndexHash[sectionIndex];

    const isFirstSelectedSection = sectionIndex === startSectionIndex;
    const isEndSelectedSection = sectionIndex === endSectionIndex;

    let minGroup;
    let maxGroup;

    if (range(minSection, maxSection + 1).length > 1) {
      // from top to bottom
      if (startSectionIndex < endSectionIndex) {
        if (isFirstSelectedSection) {
          minGroup = Math.min(startGroupIndex, size(state.tasks[sectionName]));
          maxGroup = Math.max(startGroupIndex, size(state.tasks[sectionName]));
        } else if (isEndSelectedSection) {
          minGroup = Math.min(0, endGroupIndex);
          maxGroup = Math.max(0, endGroupIndex) + 1;
        }
      }
      // from bottom to top
      if (startSectionIndex > endSectionIndex) {
        if (isFirstSelectedSection) {
          minGroup = Math.min(0, startGroupIndex);
          maxGroup = Math.max(0, startGroupIndex) + 1;
        } else if (isEndSelectedSection) {
          minGroup = Math.min(endGroupIndex, size(state.tasks[sectionName]));
          maxGroup = Math.max(endGroupIndex, size(state.tasks[sectionName]));
        }
      }
      // for selected between firstSection selected and endSection selected
      if (!isFirstSelectedSection && !isEndSelectedSection) {
        minGroup = Math.min(0, size(state.tasks[sectionName]));
        maxGroup = Math.max(0, size(state.tasks[sectionName]));
      }
    } else {
      minGroup = Math.min(startGroupIndex, endGroupIndex);
      maxGroup = Math.max(startGroupIndex, endGroupIndex) + 1;
    }

    range(minGroup, maxGroup).forEach((groupIndex) => {
      const groupName = state.groupIndexHash[groupIndex];
      const flatten = getFlattenTasks(
        state.tasks[sectionName][groupName] ?? []
      );

      flatten?.some((task) => {
        if (!firstTaskId && (task.id == startTaskId || task.id == endTaskId)) {
          firstTaskId = task.id;
          selectedTasks.push({
            ...task,
            groupName: groupName
          });

          return false;
        }

        if (firstTaskId) {
          selectedTasks.push({
            ...task,
            groupName: groupName
          });
        }

        return firstTaskId && (task.id == startTaskId || task.id == endTaskId);
      });
    });
  });

  return selectedTasks;
};

const getFlattenTasks = (tasks) => {
  const array = [];

  // flatten the task array
  // given 2 task like [A, B] where A has 2 childs [C, D], and B has child [E]
  // this function will return [A, C, D, B, E]

  const appendTaskToArray = (task) => {
    const loopChildrens = (childs) => {
      childs.forEach((child) => {
        appendTaskToArray(child);
      });
    };

    array.push(task.value);
    task.getChilds().length > 0 && loopChildrens(task.getChilds());
  };

  tasks.forEach((task) => {
    const root = task.getRoot();
    appendTaskToArray(root);
  });

  return array;
};

const addTaskToGroupOneLevel = (groupName, targetIndex, task, state) => {
  const { tasks, updateTasksOneLevel } = state;
  const newTasks = [...(tasks?.[groupName] || [])];

  newTasks.splice(targetIndex + 1, 0, new Tree(task));
  updateTasksOneLevel(groupName, newTasks);
};

const addTaskToGroupTwoLevels = (
  group1Name,
  group2Name,
  targetIndex,
  task,
  state
) => {
  const { tasks, updateTasksTwoLevels } = state;
  const newTasks = [...(tasks?.[group1Name]?.[group2Name] || [])];

  newTasks.splice(targetIndex + 1, 0, new Tree(task));
  updateTasksTwoLevels(group1Name, group2Name, newTasks);
};

const removeTaskFromGroupOneLevel = (groupName, taskId, get) => {
  const { tasks, updateTasksOneLevel } = get();
  const newTasks = tasks?.[groupName]?.filter(
    (task) => task.getRoot().value.id !== parseInt(taskId)
  );
  updateTasksOneLevel(groupName, newTasks);
};

const removeTaskFromGroupTwoLevels = (group1Name, group2Name, taskId, get) => {
  const { tasks, updateTasksTwoLevels } = get();
  const newTasks = tasks?.[group1Name]?.[group2Name]?.filter(
    (task) => task.getRoot().value.id !== parseInt(taskId)
  );
  updateTasksTwoLevels(group1Name, group2Name, newTasks);
};

const addTaskAsChildren = (nodeParent, task) => {
  nodeParent.addChild(task);
};

const removeTaskChildrenFromParent = (nodeParent, removeTaskId) => {
  const childs = nodeParent.childs;
  const newChilds = childs.filter(({ value }) => {
    return value.id !== parseInt(removeTaskId);
  });

  // set childs data
  nodeParent.childs = newChilds;
};

const dropTaskOneLevel = (srcData, destData, state) => {
  const { tasks } = state;
  let newSourceTasks = [...(tasks?.[srcData?.group] || [])];
  const newDestTasks = [...(tasks?.[destData?.group] || [])];

  newSourceTasks = newSourceTasks.filter(
    (task) => task.getRoot().value.id !== parseInt(srcData.task.id)
  );
  newDestTasks.splice(destData.index + 1, 0, new Tree(srcData.task));

  return {
    tasks: {
      ...state.tasks,
      [srcData.group]: newSourceTasks,
      [destData.group]: newDestTasks
    }
  };
};

const dropTaskTwoLevels = (srcData, destData, state) => {
  const { tasks } = state;
  let newSourceTasks = [...(tasks?.[srcData?.group1]?.[srcData?.group2] || [])];
  const newDestTasks = [
    ...(tasks?.[destData?.group1]?.[destData?.group2] || [])
  ];

  newSourceTasks = newSourceTasks.filter(
    (task) => task.getRoot().value.id !== parseInt(srcData.task.id)
  );
  newDestTasks.splice(destData.index + 1, 0, new Tree(srcData.task));

  let tempTasks = tasks;
  tempTasks[srcData.group1][srcData.group2] = newSourceTasks;
  tempTasks[destData.group1][destData.group2] = newDestTasks;

  return {
    tasks: tempTasks
  };
};

const useTasks = create((set, get) => ({
  selectedTasks: [],
  startTask: null,
  endTask: null,
  updateStartTaskOneLevel: (groupIndex, taskId) =>
    set({ startTask: { groupIndex: groupIndex, taskId: taskId } }),
  updateStartTaskTwoLevels: (sectionIndex, groupIndex, taskId) =>
    set({
      startTask: {
        sectionIndex: sectionIndex,
        groupIndex: groupIndex,
        taskId: taskId
      }
    }),
  updateEndTaskOneLevel: (groupIndex, taskId) =>
    set((state) => updateEndTaskOneLevel(state, groupIndex, taskId)),
  updateEndTaskTwoLevels: (sectionIndex, groupIndex, taskId) =>
    set((state) =>
      updateEndTaskTwoLevels(state, sectionIndex, groupIndex, taskId)
    ),
  updateSelectedTasks: (task) =>
    set((state) => ({ selectedTasks: [...state.selectedTasks, task] })),
  removeSelected: (id) =>
    set((state) => ({
      selectedTasks: state.selectedTasks.filter((selTask) => selTask.id !== id)
    })),
  removeAllSelected: () => set({ selectedTasks: [], startTask: null }),

  tasks: {},
  updateTasksOneLevel: (group, tasks) => {
    set((state) => ({ tasks: { ...state.tasks, [group]: tasks } }));
  },
  updateTasksTwoLevels: (group1, group2, tasks) => {
    set((state) => ({
      tasks: {
        ...state.tasks,
        [group1]: {
          ...state.tasks[group1],
          [group2]: tasks
        }
      }
    }));
  },
  resetTasksData: () => set({ tasks: {} }),

  addNewTaskOneLevel: (group, position) =>
    addNewTaskOneLevel(group, position, set),
  addNewTaskTwoLevels: (group1, group2, position) =>
    addNewTaskTwoLevels(group1, group2, position, set),
  removeNullTaskOneLevel: (group) => removeNullTaskOneLevel(group, set),
  removeNullTaskTwoLevels: (group1, group2) =>
    removeNullTaskTwoLevels(group1, group2, set),
  setEmptyTasksOneLevel: (group) =>
    set((state) => ({ tasks: { ...state.tasks, [group]: [] } })),
  setEmptyTasksTwoLevels: (group1, group2) => {
    set((state) => ({
      tasks: {
        ...state.tasks,
        [group1]: {
          ...state.tasks[group1],
          [group2]: []
        }
      }
    }));
  },

  dropTaskOneLevel: (srcData, destData) =>
    set((state) => dropTaskOneLevel(srcData, destData, state)),
  dropTaskTwoLevels: (srcData, destData) =>
    set((state) => dropTaskTwoLevels(srcData, destData, state)),
  addTaskToGroupOneLevel: (groupName, targetIndex, task) =>
    set((state) => addTaskToGroupOneLevel(groupName, targetIndex, task, state)), // to move task group
  addTaskToGroupTwoLevels: (group1Name, group2Name, targetIndex, task) =>
    set((state) =>
      addTaskToGroupTwoLevels(group1Name, group2Name, targetIndex, task, state)
    ),
  addTaskAsChildren: (nodeParent, task) => addTaskAsChildren(nodeParent, task), // to add task as children
  removeTaskFromGroupOneLevel: (groupName, taskId) =>
    removeTaskFromGroupOneLevel(groupName, taskId, get), // to remove task from specific group
  removeTaskFromGroupTwoLevels: (group1Name, group2Name, taskId) =>
    removeTaskFromGroupTwoLevels(group1Name, group2Name, taskId, get),
  removeTaskChildrenFromParent: (nodeParent, removeTaskId) =>
    removeTaskChildrenFromParent(nodeParent, removeTaskId), // to remove task children from task parent

  // Need this state to keep track index of each group
  // will be used to get list of task from state according to its index
  // see getSelected Function
  groupIndexHash: {},
  updateGroupIndexHash: (hash) => set(() => ({ groupIndexHash: hash })),
  sectionIndexHash: {},
  updateSectionIndexHash: (hash) => set(() => ({ sectionIndexHash: hash })),

  action: '',
  setAction: (action) => set({ action }),

  listGroups: {},
  setListGroups: (groups) => set({ listGroups: groups }),
  updateListGroups: (group, groupData) => {
    set((state) => ({
      listGroups: { ...state.listGroups, [group]: groupData }
    }));
  },
  resetListGroups: () => set({ listGroups: {} }),

  getCompletePhase: () => {
    const data = get().listGroups?.phase?.find(({ isComplete }) => isComplete);
    return data;
  },

  getFirstPhase: () => {
    const data = get().listGroups?.phase?.[0];
    return data;
  },

  sectionDialogOpen: false,
  setSectionDialogOpen: (open) => set(() => ({ sectionDialogOpen: open })),
  phaseDialogOpen: false,
  setPhaseDialogOpen: (open) => set(() => ({ phaseDialogOpen: open }))
}));

export default useTasks;

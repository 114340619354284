import React, { useEffect, useRef, useState } from 'react';

import { getMilestoneDetail } from 'client/ObjectivesClient';
import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils';

import MilestoneDetailCard from 'components/objectives/MilestoneDetailCard';
import SVGIcon from 'components/shared/SVGIcon';
import MilestoneSection from 'components/sidebar/sidebar-create/MilestoneSection';

const Milestone = ({
  objectiveDetail,
  objectiveValue,
  setObjectiveValue,
  updateObjective
}) => {
  const [milestones, setMilestones] = useState({});
  const [milestoneDataList, setMilestoneDataList] = useState(
    objectiveValue?.milestones || []
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showOtherMilestones, setShowOtherMilestones] = useState(false);

  const { currentMilestone, milestoneType, milestoneCount, milestoneMode } =
    objectiveDetail;

  const getDetailMilestone = async () => {
    const { data } = await getMilestoneDetail(objectiveDetail?.id);
    if (data) {
      setMilestones(data.filter((d) => d?.id !== currentMilestone?.id));
    }
  };

  const updateMilestone = () => {
    let body = {};
    body.milestoneType = objectiveValue?.milestoneType;
    body.milestoneMode = objectiveValue?.milestoneMode;
    if (objectiveValue?.milestoneType !== 'disabled') {
      body.milestones = milestoneDataList;
    }
    updateObjective(body);
  };

  useEffect(() => {
    getDetailMilestone();
  }, [objectiveDetail]);

  return (
    <div
      className={`relative milestone-detail px-[24px] py-[12px] flex content-center hover:bg-base-3008 flex-col  ${
        currentMilestone ? 'group' : ''
      }`}
    >
      <div className="flex content-center">
        <SVGIcon size="24" fillColor="var(--n-600)" iconName="icon-timeline" />
        {!currentMilestone ? (
          <p
            className="typography-paragraph text-n-600 pl-[16px] cursor-pointer"
            data-cy="milestone-trigger"
            onClick={() => setIsDialogOpen(true)}
          >
            {getObjectiveLocale('Milestone')}
          </p>
        ) : (
          <div className="flex justify-between w-full">
            <p className="typography-paragraph pl-[16px] capitalize">
              {getObjectiveLocale(
                `${milestoneType} milestone (${
                  milestoneMode == 'latest_value'
                    ? 'Latest Value'
                    : milestoneMode.split('_')[0]
                }), recent`
              )}
              :
            </p>
            <SVGIcon
              iconName="icon-create"
              size="24"
              fillColor="var(--v-600)"
              customClass="hidden cursor-pointer group-hover:flex"
              onClick={() => setIsDialogOpen(true)}
            />
          </div>
        )}
      </div>
      {isDialogOpen && (
        <MilestoneDialog
          objectiveValue={objectiveValue}
          setObjectiveValue={setObjectiveValue}
          milestoneDataList={milestoneDataList}
          setMilestoneDataList={setMilestoneDataList}
          setIsDialogOpen={setIsDialogOpen}
          updateMilestone={updateMilestone}
        />
      )}
      {currentMilestone && (
        <div className="ml-[40px]">
          <div className="flex mt-[12px]">
            <MilestoneDetailCard currentMilestone={currentMilestone} />
          </div>
          {milestoneCount > 1 && (
            <div
              className={`flex items-center ${
                showOtherMilestones ? 'my-[12px]' : 'mt-[12px]'
              } cursor-pointer`}
              onClick={() => setShowOtherMilestones(!showOtherMilestones)}
            >
              <p className="typography-link text-base-600 flex">
                {getObjectiveLocale(
                  `${
                    showOtherMilestones ? 'Hide' : 'Show'
                  } other milestone period`
                )}
              </p>
              <SVGIcon
                size="24"
                fillColor="var(--base-600)"
                iconName={`icon-arrow_drop_${
                  showOtherMilestones ? 'up' : 'down'
                }`}
                customClass="ml-[8px]"
              />
            </div>
          )}
          {showOtherMilestones &&
            milestones?.map((milestone, index) => (
              <div
                className={`border-solid border-n-300 ${
                  index === 0 ? 'border-t-[1px]' : 'border-t-0'
                } border-b-[1px] border-l-0 border-r-0`}
                key={index}
              >
                <MilestoneDetailCard
                  currentMilestone={milestone}
                  id={index}
                  useCompactDesign
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

const MilestoneDialog = ({
  objectiveValue,
  setObjectiveValue,
  milestoneDataList,
  setMilestoneDataList,
  setIsDialogOpen,
  updateMilestone
}) => {
  const ref = useRef();
  useClickOutside(ref, () => {
    updateMilestone();
    setIsDialogOpen(false);
  });

  return (
    <div
      className="absolute top-[36px] w-[360px] flex justify-between items-center p-[16px] z-20 bg-n-000 rounded-[4px]"
      style={{
        boxShadow:
          '0px 2px 4px rgba(15, 23, 42, 0.06), 0px 4px 6px rgba(15, 23, 42, 0.1)'
      }}
      ref={ref}
    >
      <MilestoneSection
        objectiveValue={objectiveValue}
        setObjectiveValue={setObjectiveValue}
        changedMilestones={milestoneDataList}
        setMilestoneDataList={setMilestoneDataList}
        isEdit
        isDialog
      />
    </div>
  );
};

export default Milestone;

import React from 'react';

import SubGoalStatistics from 'pages/teams/approval/SubGoalStatistics';
import SubGoals from 'pages/teams/approval/SubGoals';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

const TeamGoalApproval = ({
  objectiveId,
  objectiveConfigs,
  subGoalsCount,
  ...rest
}) => {
  const defaultOptions = [
    { id: 'all', name: getObjectiveLocale('All') },
    { id: 'draft', name: getObjectiveLocale('New') },
    { id: 'edited', name: getObjectiveLocale('Edited') },
    { id: 'existing', name: getObjectiveLocale('Existing') },
    { id: 'toBeDeleted', name: getObjectiveLocale('To Be Deleted') }
  ];

  return (
    <div
      className="border-solid border-0 border-t-[1px] border-n-300 pb-[16px] pl-[28px] pr-[16px] cursor-default"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex">
        <div className="border-solid border-[1px] border-t-0 border-r-0 border-n-400 rounded-bl-[4px] w-[28px] h-[52px]" />
        <div className="bg-n-100 w-full mt-[12px] p-[16px]">
          <SubGoalStatistics
            objectiveId={objectiveId}
            objectiveConfigs={objectiveConfigs}
            subGoalsCount={subGoalsCount}
          />
          <SubGoals
            objectiveId={objectiveId}
            subGoalsCount={subGoalsCount}
            defaultOptions={defaultOptions}
            {...rest}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamGoalApproval;

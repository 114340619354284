import React from 'react';

import PropTypes from 'prop-types';

import './progressBar.scss';

const Interface = {
  widthBackground: PropTypes.number.isRequired,
  widthProgress: PropTypes.number,
  widthExpected: PropTypes.number,
  height: PropTypes.number.isRequired,
  colorProgress: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  withTextInProgressBar: PropTypes.bool,
  dataCy: PropTypes.string
};
const DefaultValue = {
  widthBackground: 100,
  widthProgress: 50,
  widthExpected: 0,
  height: 10,
  colorProgress: 'bg-n-500 ',
  containerClassName: '',
  withTextInProgressBar: false,
  dataCy: 'progress-bar-overall'
};
const ProgressBarOverallProgress = ({
  widthBackground,
  widthProgress,
  widthExpected,
  height,
  colorProgress,
  containerClassName,
  withTextInProgressBar,
  dataCy
}) => {
  let classWidthExpected =
    widthExpected < 100
      ? 'progress-bar-overall-progress-expected'
      : 'progress-bar-overall-progress-expected-100';
  let classWidthProgress =
    widthProgress < 100
      ? 'progress-bar-overall-progress'
      : 'progress-bar-overall-progress-100';

  return (
    <div
      className={`progress-bar-overall ${containerClassName}`}
      data-cy={dataCy}
    >
      <div
        data-cy="progress-bar-background"
        className={`bg-n-400 progress-bar-overall-progress-background height-${height} width-percentage-${widthBackground}`}
      >
        {' '}
      </div>
      <div
        data-cy="progress-bar-progress"
        className={`${colorProgress} ${classWidthProgress} height-${height} animated-width-percentage-${
          widthProgress < 100 ? widthProgress : 100
        }`}
      >
        {' '}
      </div>
      <div
        data-cy="progress-bar-expected"
        className={`bg-n-600 ${classWidthExpected} height-${height} width-percentage-${
          widthExpected < 100 ? widthExpected : 100
        }`}
      >
        {' '}
      </div>
      {withTextInProgressBar && (
        <div className="typography-h200 text-in-progress-bar">
          <p>{parseInt(widthProgress)}% (Overall Progres)</p>
        </div>
      )}
    </div>
  );
};
ProgressBarOverallProgress.propTypes = Interface;
ProgressBarOverallProgress.defaultProps = DefaultValue;
export default ProgressBarOverallProgress;

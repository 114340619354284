import React from 'react';

import isEqual from 'lodash/isEqual';

import { MONTH_GROUP, QUARTER_GROUP, WEEK_GROUP } from '../utils/const';
import GanttHeaderDays from './GanttHeaderDays';
import GanttHeaderWeekly from './GanttHeaderWeekly';
import GanttTopHeaderMonthly from './GanttTopHeaderMonthly';
import GanttTopHeaderQuarterly from './GanttTopHeaderQuarterly';
import GanttTopHeaderWeekly from './GanttTopHeaderWeekly';

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

const GanttHeader = React.memo(({ timeGroup, daysOfYear }) => {
  return (
    <div className="header-date flex flex-col h-[71px] absolute z-[2] bg-n-000">
      <div className="tm-border-bottom flex items-center flex-[1]">
        {timeGroup == WEEK_GROUP && (
          <GanttTopHeaderWeekly daysOfYear={daysOfYear} />
        )}
        {timeGroup == MONTH_GROUP && (
          <GanttTopHeaderMonthly daysOfYear={daysOfYear} />
        )}
        {timeGroup == QUARTER_GROUP && (
          <GanttTopHeaderQuarterly daysOfYear={daysOfYear} />
        )}
      </div>
      <div className="tm-border-bottom flex items-center flex-[1]">
        {timeGroup == WEEK_GROUP && <GanttHeaderDays daysOfYear={daysOfYear} />}
        {timeGroup == MONTH_GROUP && (
          <GanttHeaderWeekly daysOfYear={daysOfYear} />
        )}
        {timeGroup == QUARTER_GROUP && (
          <GanttTopHeaderMonthly daysOfYear={daysOfYear} isQuarter />
        )}
      </div>
    </div>
  );
}, areEqual);

GanttHeader.displayName = 'GanttHeader';

export default GanttHeader;

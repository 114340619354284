import React, { useState } from 'react';

import { useUser } from 'context/UserContext';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';

const YearFilter = ({ selectedValue, onSelect, customYearBefore = null }) => {
  const { organization } = useUser();

  const thisYear = new Date().getFullYear();
  const orgCreatedYear = new Date(organization.createdAt).getFullYear();

  const [selected, setSelected] = useState(selectedValue || thisYear);

  let yearList = [];
  const maxYear = customYearBefore
    ? orgCreatedYear - customYearBefore
    : orgCreatedYear;

  for (let y = thisYear; y >= maxYear; y--) {
    yearList.push(y);
  }

  const handleSelect = (value) => {
    setSelected(value);
    onSelect && onSelect(value);
  };

  return (
    <Dropdown customClass="ml-[8px]">
      <Dropdown.Trigger
        customClass="flex flex-row items-center justify-between rounded border-n-400 border-solid border w-[240px] bg-n-000 h-[32px] pl-[16px] pr-[8px]"
        disabled={yearList?.length == 1}
      >
        <p className="text-n-900 typography-button">
          {selected} {selected == thisYear ? '(This year)' : ''}
        </p>
        <SVGIcon
          size="24"
          iconName="icon-calendar_today"
          fillColor="var(--base-600)"
        />
      </Dropdown.Trigger>
      <Dropdown.MenuItems
        type="fixed"
        customClass={'w-[240px]'}
        position="right"
        maxHeight={186}
      >
        {yearList?.map((year, index) => (
          <Dropdown.MenuItem
            key={index}
            onClick={() => handleSelect(year)}
            customClass="w-[240px]"
            dataCy={`dropdown-filter-year={year}`}
          >
            <p className="typography-button text-n-900">
              {year} {year == thisYear ? '(This Year)' : ''}
            </p>
          </Dropdown.MenuItem>
        ))}
      </Dropdown.MenuItems>
    </Dropdown>
  );
};

export default YearFilter;

import React from 'react';

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { getQuarterRange, getSemesterRange } from 'utils/DateUtils';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import { _conditionMinMaxObjectivesHelper } from 'pages/create-objective-warning/CreateObjectivesWarningHelpers';

dayjs.extend(quarterOfYear);

const getElementFooter = ({
  isCoaching,
  type,
  totalObjectives,
  validateNumber,
  maxNumberOfObjectives
}) => {
  const localeTotalObj = getNumberLocale(totalObjectives);
  const localeValidateNumber = getNumberLocale(validateNumber);
  if (isCoaching === false) {
    switch (type) {
      case 'doesntHaveConfigOrNoHaveMaxConfig':
        return (
          <span
            className="overall-value typography-h600 mt-[16px]"
            data-cy="overall-objective-total"
          >
            {localeTotalObj}
          </span>
        );
      case 'ObjectivesUnderConfig':
        return (
          <div className="mt-[16px]">
            <div className="flex items-center flex-row mb-[5px]">
              <SVGIcon
                customClass="mr-[4px]"
                iconName="icon-warning_yellow"
                size={16}
              />
              <p
                className="overall-value text-r-500 typography-h600"
                data-cy="overall-objective-total"
              >
                {localeTotalObj}
              </p>
            </div>
            <p
              className="typography-h100 text-n-600"
              data-cy="overall-objective-minimum"
            >
              {getObjectiveLocale('Minimum')}{' '}
              <span className="typography-h200 black-text">
                {localeValidateNumber}
              </span>{' '}
              {getObjectiveLocale('objective(s)')}
            </p>
          </div>
        );
      case 'ObjectivesOverConfig':
        return (
          <div className="mt-[16px]">
            <div className="flex items-center flex-row mb-[5px]">
              <SVGIcon
                customClass="mr-[4px]"
                iconName="icon-warning_yellow"
                size={16}
              />
              <p
                className="overall-value text-r-500 typography-h600"
                data-cy="overall-objective-total"
              >
                {localeTotalObj}
              </p>
            </div>
            <p
              className="typography-h100 text-n-600"
              data-cy="overall-objective-maximum"
            >
              {getObjectiveLocale('Maximum')}{' '}
              <span className="typography-h200 black-text">
                {localeValidateNumber}
              </span>{' '}
              {getObjectiveLocale('objective(s)')}
            </p>
          </div>
        );
      case 'ObjectivesNotUnderMinOrNotUnderMax':
        return (
          <>
            <div className="mt-[16px]">
              <span
                className="overall-value typography-h600"
                data-cy="overall-objective-total"
              >
                {localeTotalObj}{' '}
                <span className="text-n-600">
                  / {getNumberLocale(maxNumberOfObjectives)}
                </span>
              </span>
              {validateNumber !== 0 && (
                <p
                  className="typography-h100 text-n-600 mt-[5px]"
                  data-cy="overall-objective-maximum"
                >
                  <span className="typography-h100 text-n-900">
                    {localeValidateNumber}
                  </span>{' '}
                  {getObjectiveLocale('quota(s) available')}
                </p>
              )}
            </div>
          </>
        );
    }
  } else {
    // isCoaching === true
    let topParentText = totalObjectives
      ? getObjectiveLocale(
          'From [total_objectives] top parent objectives'
        ).replace(/\[.*\]/g, localeTotalObj)
      : '';
    let minObjectiveText = validateNumber
      ? getObjectiveLocale('Minimum [min_value] objectives').replace(
          /\[.*\]/g,
          localeValidateNumber
        )
      : '';
    let maxObjectiveText = validateNumber
      ? getObjectiveLocale('Maximum [max_value] objectives').replace(
          /\[.*\]/g,
          localeValidateNumber
        )
      : '';

    switch (type) {
      case 'doesntHaveConfigOrNoHaveMaxConfig':
        return <span>{topParentText}</span>;
      case 'ObjectivesUnderConfig':
      case 'ObjectivesOverConfig':
        const datacy =
          type === 'ObjectivesUnderConfig' ? 'validate-min' : 'validate-max';
        const text =
          type === 'ObjectivesUnderConfig'
            ? minObjectiveText
            : maxObjectiveText;
        return (
          <div className="truncate">
            <SVGIcon
              customClass="mr-[5px]"
              iconName="icon-warning_red"
              size={16}
            />
            <span className="black-text">{topParentText} · </span>
            <span className="text-r-500" data-cy={datacy}>
              {text}
            </span>
          </div>
        );
      case 'ObjectivesNotUnderMinOrNotUnderMax':
        return (
          <span>
            {topParentText} · {localeValidateNumber}{' '}
            {getObjectiveLocale('quotas left')}
          </span>
        );
    }
  }
};

export const _getNodeFoooter = (
  {
    maxObjectivesOn,
    minObjectivesOn,
    maxNumberOfObjectives,
    minNumberOfObjectives,
    totalObjectives
  },
  isCoaching = false
) => {
  let res;

  const {
    conditionDoesntHaveConfigComponent,
    conditionConfigMinOnlyNoValidate,
    conditionConfigMinOnlyValidateMin,
    conditionConfigMaxOnlyNoValidate,
    conditionConfigMaxOnlyValidateMax,
    conditionConfigMinMaxNoValidate,
    conditionConfigMinMaxValidateMin,
    conditionConfigMinMaxValidateMax
  } = _conditionMinMaxObjectivesHelper({
    maxObjectivesOn,
    minObjectivesOn,
    maxNumberOfObjectives,
    minNumberOfObjectives,
    totalObjectives
  });

  if (conditionDoesntHaveConfigComponent)
    res = getElementFooter({
      isCoaching,
      type: 'doesntHaveConfigOrNoHaveMaxConfig',
      totalObjectives
    });
  if (conditionConfigMinOnlyNoValidate)
    res = getElementFooter({
      isCoaching,
      type: 'doesntHaveConfigOrNoHaveMaxConfig',
      totalObjectives
    });
  if (conditionConfigMinOnlyValidateMin)
    res = getElementFooter({
      isCoaching,
      type: 'ObjectivesUnderConfig',
      totalObjectives,
      validateNumber: minNumberOfObjectives,
      maxNumberOfObjectives
    });
  if (conditionConfigMaxOnlyNoValidate)
    res = getElementFooter({
      isCoaching,
      type: 'ObjectivesNotUnderMinOrNotUnderMax',
      totalObjectives,
      validateNumber: maxNumberOfObjectives - totalObjectives,
      maxNumberOfObjectives
    });
  if (conditionConfigMaxOnlyValidateMax)
    res = getElementFooter({
      isCoaching,
      type: 'ObjectivesOverConfig',
      totalObjectives,
      validateNumber: maxNumberOfObjectives
    });
  if (conditionConfigMinMaxNoValidate)
    res = getElementFooter({
      isCoaching,
      type: 'ObjectivesNotUnderMinOrNotUnderMax',
      totalObjectives,
      validateNumber: maxNumberOfObjectives - totalObjectives,
      maxNumberOfObjectives
    });
  if (conditionConfigMinMaxValidateMin)
    res = getElementFooter({
      isCoaching,
      type: 'ObjectivesUnderConfig',
      totalObjectives,
      validateNumber: minNumberOfObjectives,
      maxNumberOfObjectives
    });
  if (conditionConfigMinMaxValidateMax)
    res = getElementFooter({
      isCoaching,
      type: 'ObjectivesOverConfig',
      totalObjectives,
      validateNumber: maxNumberOfObjectives
    });

  return { nodeFooterWarning: res };
};

export const _getRangeDateCurrentQuarter = () => {
  const currentQuarter = 'q' + dayjs().quarter();
  const { start: periodBegin, due: periodEndBefore } = getQuarterRange(
    currentQuarter,
    new Date().getFullYear()
  );
  return { periodBegin, periodEndBefore };
};

export const _getRangeDateCurrentSemester = () => {
  const currentSemester = 's' + dayjs().month() < 6 ? '1' : '2';
  const { start: periodBegin, due: periodEndBefore } = getSemesterRange(
    currentSemester,
    new Date().getFullYear()
  );
  return { periodBegin, periodEndBefore };
};

import React, { useState } from 'react';

import camelCase from 'lodash/camelCase';

import { phaseName } from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import Checkbox from 'components/shared/Checkbox';
import RadioButton from 'components/shared/RadioButton';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

import ScoreCardSection from './ScoreCardSection';

const PhaseDeliverables = ({
  index,
  title,
  description,
  deliverable,
  disclosure,
  scoreCard,
  trackConfigs,
  phaseKey,
  onChangeViewSummaryConfig,
  onChangePhaseDeliverable,
  onChangePhaseDisclosure,
  onChangeDisplayTrack,
  changePhaseData,
  reviewAspectsScoreCardList,
  revieweeSummaryVisibility
}) => {
  const initialScoreCardToggle =
    scoreCard &&
    (Object.keys(scoreCard).length > 1
      ? true
      : scoreCard.scoreAspects?.length > 0);

  const [useScoreCard, setUseScoreCard] = useState(
    initialScoreCardToggle || false
  );

  const listValue = [
    {
      title: 'Reviewee will only see the final score',
      val: 'final_score_only'
    },
    {
      title: 'Reviewee will only see the scores result',
      val: 'track_score_only'
    },
    {
      title: 'Reviewee will see all breakdown and the final score',
      val: 'detail_summary'
    }
  ];

  const toggleScoreCardHandler = (e) => {
    setUseScoreCard(e.currentTarget.checked);
    changePhaseData(phaseKey, 'scoreCard', e.currentTarget.checked ? {} : null);
  };

  return (
    <div
      key={index}
      className="height-auto border border-solid border-n-400 py-[12px] rounded-[4px] mb-[24px] bg-n-000"
    >
      <div
        className={`flex items-center flex justify-between px-[16px] ${
          deliverable ? 'mb-[12px]' : ''
        }`}
      >
        <div>
          <p className="typography-h500 mb-[2px]">{title}</p>
          <p className="typography-paragraph typography-secondary">
            {description}
          </p>
        </div>
        <SmallToggleSwitchPurple
          idStr={phaseKey}
          isChecked={deliverable}
          onChange={(e) => onChangePhaseDeliverable(phaseKey, trackConfigs, e)}
        />
      </div>
      {deliverable && ['peerReview', 'upwardReview'].includes(phaseKey) && (
        <div className="px-[16px] mt-[4px] mb-[16px]">
          <div className="flex items-center">
            <RadioButton
              size="small"
              id={`${phaseKey}-full`}
              labelClass="typography-paragraph ml-[8px] whitespace-nowrap"
              checked={disclosure === 'full_disclosure'}
              onChange={() =>
                onChangePhaseDisclosure(phaseKey, 'full_disclosure')
              }
            >
              {getObjectiveLocale(
                `The review result will openly be shared to the reviewee.`
              )}
            </RadioButton>
          </div>
          <div className="flex items-center mt-[8px]">
            <RadioButton
              size="small"
              id={`${phaseKey}-anon`}
              labelClass="typography-paragraph ml-[8px] whitespace-nowrap"
              checked={disclosure === 'anonymous_disclosure'}
              onChange={() =>
                onChangePhaseDisclosure(phaseKey, 'anonymous_disclosure')
              }
            >
              {getObjectiveLocale(
                `The review result will be shared to the reviewee anonymously.`
              )}
            </RadioButton>
          </div>
        </div>
      )}
      {deliverable &&
        trackConfigs.findIndex((track) => track.type === 'summary') >= 0 && (
          <div className="border-solid border-t border-0 border-n-400 px-[16px] pt-[16px] my-[16px]">
            <p className="typography-h100 text-n-800 mb-[8px]">
              {getObjectiveLocale(`Scoring Breakdown Summary View Options`)}
            </p>
            <Dropdown>
              <Dropdown.Trigger dataCy={`${phaseKey}-dropdown-summary-options`}>
                <Trigger
                  icon={false}
                  topLabel={false}
                  label={false}
                  text={
                    revieweeSummaryVisibility
                      ? listValue.find(
                          (e) => e.val === revieweeSummaryVisibility
                        ).title
                      : listValue[0].title
                  }
                />
              </Dropdown.Trigger>
              <Dropdown.MenuItems>
                {listValue.map((value, index) => (
                  <Dropdown.MenuItem
                    onClick={() => onChangeViewSummaryConfig(phaseKey, value)}
                    key={index}
                    dataCy={`${phaseKey}-dropdown-summary-options-option-${index}`}
                  >
                    {value.title}
                  </Dropdown.MenuItem>
                ))}
              </Dropdown.MenuItems>
            </Dropdown>
          </div>
        )}
      {deliverable && trackConfigs.length > 0 && (
        <div className="border-solid border-t border-0 border-n-400 pt-[16px] px-[16px]">
          <p className="typography-h100 text-n-800 mb-[8px]">
            {getObjectiveLocale(`Tracks will be shared to reviewee`)}
          </p>
          {trackConfigs.map((track, index) => {
            const trackType = camelCase(track.name);
            return (
              <div
                className={`flex items-center h-[24px] ${
                  index == trackConfigs.length - 1 ? '' : 'mb-[8px]'
                }`}
              >
                <Checkbox
                  name={`${phaseKey}-${trackType}`}
                  id={`${phaseKey}-${trackType}`}
                  dataCy={`${phaseKey}-${trackType}`}
                  checked={track?.displayOnResult}
                  onChange={() =>
                    onChangeDisplayTrack(
                      phaseKey,
                      track.type,
                      track.name,
                      !track?.displayOnResult
                    )
                  }
                  labelClass="cursor-pointer"
                >
                  <div>
                    <span className="typography-paragraph text-n-900 ml-[8px]">
                      {track.name}&nbsp;
                    </span>
                    <span className="typography-paragraph color-n-300">
                      ({phaseName(track.type)})
                    </span>
                  </div>
                </Checkbox>
              </div>
            );
          })}
          {(phaseKey == 'managerReview' ||
            phaseKey == 'indirectManagerReview') && (
            <div className="flex items-center h-[24px] mt-[8px]">
              <Checkbox
                name={`${phaseKey}-score-card`}
                id={`${phaseKey}-score-card`}
                dataCy={`${phaseKey}-score-card`}
                checked={useScoreCard}
                onChange={toggleScoreCardHandler}
                labelClass="cursor-pointer"
              >
                <div>
                  <span className="typography-paragraph text-n-900 ml-[8px]">
                    {getObjectiveLocale(`Score Card`)}
                  </span>
                </div>
              </Checkbox>
            </div>
          )}

          {useScoreCard && (
            <ScoreCardSection
              phase={phaseKey}
              configs={scoreCard}
              reviewAspectsScoreCardList={reviewAspectsScoreCardList}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PhaseDeliverables;

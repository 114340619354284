import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import ctl from '@netlify/classnames-template-literals';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import useClickOutside from 'hooks/useClickOutside';
import { getDateWithForcedTimezone } from 'utils/DateUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Accordion from 'components/design-system/accordion/Accordion';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import { listOptionDate } from 'components/shared/Calendar/ListAntdDateHelper';
import RepetitionContent from 'components/shared/Calendar/RepetitionContent';

import './AntdCalendar.scss';

const { RangePicker } = DatePicker;

const CalendarContext = createContext();
CalendarContext.displayName = 'CalendarContext';

const AntdCalendar = ({ children, customClass }) => {
  const [open, setOpen] = useState(false);
  const [customTrigger, setCustomTrigger] = useState(false);
  const ref = useRef();
  return (
    <CalendarContext.Provider
      value={{ open, setOpen, customTrigger, setCustomTrigger, ref }}
    >
      <div ref={ref} className={customClass}>
        {children && children}
      </div>
    </CalendarContext.Provider>
  );
};

export const useCalendar = () => useContext(CalendarContext);

function Content(props) {
  const { isAlwaysOpen = false, ...rest } = props;
  const { open, setOpen } = useCalendar();

  useEffect(() => {
    if (isAlwaysOpen) {
      setOpen(true);
    }
  }, [isAlwaysOpen]);

  return (
    <>
      {open && (
        <FloatingComponent customContentHeight={360}>
          <CalendarContent {...rest} />
        </FloatingComponent>
      )}
    </>
  );
}

function CalendarContent({
  useRepetition,
  dateValue,
  setDateValueHandler,
  recurrenceValue,
  isGoals,
  position = 'middle',
  isAlwaysOpen = false,
  leftSidebarContent,
  customBox,
  listOptionsTime = listOptionDate([
    'Annual',
    `S1 ${dayjs().year()}`,
    `S2 ${dayjs().year()}`,
    `Q1 ${dayjs().year()}`,
    `Q2 ${dayjs().year()}`,
    `Q3 ${dayjs().year()}`,
    `Q4 ${dayjs().year()}`
  ]),
  vPosition = 'bottom',
  disabledDate,
  clickOutsideCallback,
  isDual = false,
  calendarType = 'startDate',
  customPosition = '',
  allowClear = false
}) {
  const { open, setOpen, ref } = useCalendar();
  const [recurrence, setRecurrence] = useState(recurrenceValue);
  const [compactCalendarHeight, setCompactCalendarHeight] = useState(325);
  const containerCN = ctl(`
    absolute rounded shadow-raised z-[12] bg-n-000 flex pt-[16px] pb-[8px] ${customPosition}
    ${vPosition === 'relative' && 'top-0'}
    ${vPosition === 'top' && '-top-[8px] -translate-y-full'}
    ${vPosition === 'bottom' && '-bottom-[8px] translate-y-full'}
    ${position == 'middle' && '-translate-x-1/2'}
    ${position == 'left' && 'left-[0]'}
    ${position == 'right' && 'right-[0]'}

  `);

  const onChange = (dates, dateStrings, info) => {
    if (!dates?.[0] || !dates?.[1]) return;
    if (dates && info.range == 'end') {
      setDateValueHandler(dates);
      setOpen(false);
    }
  };
  const startYear = dayjs().startOf('year');
  const endYear = dayjs().endOf('year');
  const getLastPeriod = (type, duration) => {
    // for last week, last year
    const start = dayjs().subtract(duration, type).startOf(type);
    const end = dayjs().subtract(1, type).endOf(type);

    return [start, end];
  };

  const getThisPeriod = (type) => {
    // for This Month, This Week, This Year
    const start = dayjs().startOf(type);
    const end = dayjs().endOf(type);
    return [start, end];
  };

  const getQuarterPeriod = (quarter) => {
    //for Quarter Q1, Q2, Q3 , Q4
    const start = dayjs().quarter(quarter).startOf('quarter');
    const end = dayjs().quarter(quarter).endOf('quarter');
    return [start, end];
  };

  const getSemesterPeriod = (semester) => {
    const start = dayjs()
      .month((semester - 1) * 6)
      .startOf('month');
    const end = dayjs()
      .month(semester * 6 - 1)
      .endOf('month');
    return [start, end];
  };

  const getPeriod = (type, value, duration) => {
    // for 7 days, 14 days, etc
    let increment = 1;

    const isNumber = /\d/;

    if (isNumber.test(duration)) {
      increment = duration;
    }

    let start, end;

    if (value?.includes('next')) {
      start = dayjs().add(increment, type).startOf(type);
      end = start.endOf(type);
    } else {
      start = dayjs(dateValue?.[0]);
      end = start.add(increment, type).endOf(type);
    }
    return [start, end];
  };

  const handleChangeClick = (value, dateValue) => {
    let type = ['day', 'week', 'month', 'year'].filter((val) =>
      value.includes(val)
    )[0];
    let duration = value?.split('-').shift(); // take number for duration ex 7 days => 7
    if (value.includes('S')) {
      // S1 S2
      const typeSemester = value.substring(1);
      setDateValueHandler(getSemesterPeriod(typeSemester));
    } else if (value.includes('Q')) {
      //Q1 Q2 Q3 Q4
      let typeQuarter = value.substring(1); // take first char for quarter so just become number ex Q1 => 1\
      setDateValueHandler(getQuarterPeriod(typeQuarter));
    } else if (value == 'annual') {
      setDateValueHandler([startYear, endYear]);
    } else if (value?.includes('next')) {
      setDateValueHandler(getPeriod(type, value));
    } else if (value.includes('today')) {
      setDateValueHandler(getThisPeriod(type));
    } else if (value.includes('this')) {
      // this year, this week, this month, this day, today
      setDateValueHandler(getThisPeriod(type));
    } else if (value.includes('last')) {
      //last week, last month, last year, last 2 month
      let durationLast =
        value.split('-').length > 2 ? value.split('-')?.[1] : 1; // get the number 2 from last-2-month
      let typeDuration = type;
      setDateValueHandler(getLastPeriod(typeDuration, durationLast));
    } else {
      //7 DAYS, 14 DAYS, ETC
      setDateValueHandler(getPeriod(type, null, duration));
    }

    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };
  const contentRef = useRef();

  useClickOutside(ref, () => {
    setOpen(false);
    clickOutsideCallback && clickOutsideCallback();
  });

  useEffect(() => {
    // Force always choose start date on open
    if (open) {
      const firstInput =
        contentRef?.current?.getElementsByClassName('ant-picker-input')?.[0];
      firstInput && firstInput.click();
    }
  }, [open]);

  useEffect(() => {
    if (isAlwaysOpen) {
      setOpen(true);
    }
  }, [isAlwaysOpen]);

  return (
    <div
      className={containerCN}
      onClick={(e) => e.stopPropagation()}
      ref={ref}
      data-cy="calendar-content"
    >
      <div className="border-0 border-r border-solid border-n-300 w-max">
        {leftSidebarContent == 'no' ? (
          ''
        ) : leftSidebarContent ? (
          leftSidebarContent
        ) : (
          <div className="px-[16px]">
            <Accordion.Group>
              <Accordion id="quick-option" contentOpen>
                <Accordion.Header
                  customSpaceClass="py-[0px] px-[0px]"
                  colorAccordion="var(--n-600)"
                >
                  <p className="typography-h300 text-n-600 uppercase">
                    {getObjectiveLocale('Quick Option')}
                  </p>
                </Accordion.Header>
                <Accordion.Content
                  customSpaceClass="py-[4px]"
                  customClass="flex flex-col"
                >
                  {listOptionsTime?.map((data, index) => (
                    <Button
                      key={index}
                      onClick={() =>
                        handleChangeClick(data?.val, data?.dateVal, dateValue)
                      }
                      customClass={`${
                        data?.disabled
                          ? 'cursor-not-allowed text-n-600'
                          : 'cursor-pointer typography-secondary'
                      }  typography-button border-none text-left py-[8px] justify-start px-[0px]`}
                      disabled={data?.disabled}
                      datacy={data?.dataCy}
                      alignText="left"
                      variant="secondary"
                    >
                      {getObjectiveLocale(data?.name)}
                    </Button>
                  ))}
                </Accordion.Content>
              </Accordion>
              {useRepetition && (
                <Accordion id="repetition">
                  <Accordion.Header
                    customSpaceClass="py-[0px] px-[0px]"
                    colorAccordion="var(--n-600)"
                  >
                    <p className="typography-h300 text-n-600 uppercase">
                      {getObjectiveLocale('Set Repetition')}
                    </p>
                  </Accordion.Header>
                  <Accordion.Content customSpaceClass="py-[4px] w-[200px]">
                    <RepetitionContent
                      recurrence={recurrence}
                      setRecurrence={setRecurrence}
                      isGoals={isGoals}
                      startDate={dateValue?.[0]}
                      dueDate={dateValue?.[1]}
                    />
                  </Accordion.Content>
                </Accordion>
              )}
            </Accordion.Group>
          </div>
        )}
      </div>
      <div
        className={`calendar px-[16px] ${
          customBox ? customBox : isDual ? 'w-[344px]' : 'w-[592px]'
        }`}
        style={isDual ? { height: compactCalendarHeight + 'px' } : {}}
      >
        {isDual ? (
          <div className="flex flex-col">
            <p className="typography-h100 text-n-800 mb-[8px]">
              {startCase(calendarType)}
            </p>
            <DatePicker
              value={dateValue}
              onChange={(date, dateString) => {
                setDateValueHandler(dateString);
                setOpen(false);
              }}
              onPanelChange={(value, mode) =>
                setCompactCalendarHeight(mode === 'date' ? 325 : 363)
              }
              open={open}
              getPopupContainer={() => contentRef.current}
              showToday={false}
              disabledDate={disabledDate}
              allowClear={allowClear}
            />
          </div>
        ) : (
          <>
            <div className="flex mb-[8px]">
              <span className="w-1/2 typography-h100 px-[12px]">
                {getObjectiveLocale('Start Date')}
              </span>
              <span className="w-1/2 typography-h100 px-[12px]">
                {getObjectiveLocale('Due Date')}
              </span>
            </div>
            <RangePicker
              onCalendarChange={onChange}
              showToday={false}
              open={open}
              format="DD-MM-YYYY"
              defaultValue={dateValue?.map((date) =>
                dayjs(getDateWithForcedTimezone(date))
              )}
              getPopupContainer={() => contentRef.current}
              disabledDate={disabledDate}
            />
          </>
        )}
        <div className="calendar-wrapper relative" ref={contentRef}></div>
      </div>
    </div>
  );
}

function Trigger({
  children,
  customClass,
  dataCy,
  disabled = false,
  onClick,
  beforeTrigger
}) {
  const { open, setOpen, setCustomTrigger } = useCalendar();
  setCustomTrigger(true);

  const setOnClick = (e) => {
    if (disabled) return;

    if (beforeTrigger) {
      beforeTrigger(e, setOpen);
    } else {
      e.stopPropagation();
      if (onClick) {
        onClick();
      }

      setOpen(!open);
    }
  };

  return (
    <div
      className={`${customClass} ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={!disabled && setOnClick}
      data-cy={dataCy}
    >
      {children}
    </div>
  );
}

AntdCalendar.Trigger = Trigger;
AntdCalendar.Content = Content;
export default AntdCalendar;

import React, { useState } from 'react';

import Accordion from 'components/design-system/accordion/Accordion';
import TruncateParagraph from 'components/shared/TruncateParagraph';

import ObjectiveAnalyticContent from './ObjectiveAnalyticContent';

const AccordionLabel = ({ label }) => {
  const [objectives, setObjectives] = useState([]);

  return (
    <Accordion
      customClass="mb-[16px] bg-n-300"
      useBorder
      key={label}
      useBgColor={false}
      dataCy={`accordion-overview-${label}`}
    >
      <Accordion.Header
        customSpaceClass="px-[16px] py-[16px]"
        colorAccordion="var(--n-600)"
      >
        <div className="w-[95%] flex items-center">
          <TruncateParagraph className="typography-h500 text-n-900">
            {label}
          </TruncateParagraph>
        </div>
      </Accordion.Header>
      <Accordion.Content
        customClass={`max-h-[656px] ${
          objectives?.length > 2 ? 'overflow-y-auto' : ''
        } !py-[0px] mb-[16px]`}
      >
        <ObjectiveAnalyticContent
          name={label}
          setObjectives={setObjectives}
          objectives={objectives}
        />
      </Accordion.Content>
    </Accordion>
  );
};

export default AccordionLabel;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getShareSurveyToken } from 'client/adminClient';
import { useToastContext } from 'context/ToastContext';
import { getCultureLocale } from 'utils/HelperUtils';
import { sendReminderHandler } from 'utils/SurveyHelper';

import Dropdown, {
  useDropdown
} from 'components/design-system/dropdown/Dropdown';
import SVGIcon from 'components/shared/SVGIcon';
import ShareLink from 'components/shared/ShareLink/ShareLink';

const ManageSurveyOptions = ({
  setModalOpen,
  id,
  withRemindOptions,
  occurrenceId
}) => {
  const [showShareOptions, setShowShareOptions] = useState(false);

  const { addToast } = useToastContext();

  const remindHandler = async () => {
    const { remindSuccess, remainingHours, remainingMinutes } =
      await sendReminderHandler(id, { surveyOccurrenceId: occurrenceId });

    addToast({
      msg: getCultureLocale(
        remindSuccess
          ? 'Email reminder has been sent.'
          : `You can send another reminder in the next ${remainingHours}h ${remainingMinutes}m`
      ),
      title: getCultureLocale(
        remindSuccess ? 'Remind Successful' : 'Remind Failed'
      ),
      type: remindSuccess ? 'success' : 'error'
    });
  };

  return (
    <Dropdown>
      <DropdownContent
        setShowShareOptions={setShowShareOptions}
        showShareOptions={showShareOptions}
        withRemindOptions={withRemindOptions}
        sendReminderHandler={remindHandler}
        id={id}
        addToast={addToast}
        setModalOpen={setModalOpen}
        occurrenceId={occurrenceId}
      />
    </Dropdown>
  );
};

const DropdownContent = ({
  setShowShareOptions,
  showShareOptions,
  withRemindOptions,
  sendReminderHandler,
  id,
  addToast,
  setModalOpen,
  occurrenceId
}) => {
  const history = useHistory();
  const { setOpen } = useDropdown();

  const getToken = () => {
    setOpen(false);
    setShowShareOptions(false);
    return getShareSurveyToken(id, { surveyOccurrenceId: occurrenceId });
  };

  return (
    <>
      <Dropdown.Trigger dataCy="trigger-survey-list">
        <SVGIcon
          iconName="icon-keyboard_control"
          size="24"
          fillColor="var(--n-600)"
          customClass="invisible group-hover:visible"
        />
      </Dropdown.Trigger>
      <Dropdown.MenuItems position="right" type="fixed" customClass="w-[160px]">
        <Dropdown.MenuItem
          onMouseEnter={() => setShowShareOptions(true)}
          onMouseLeave={() => setShowShareOptions(false)}
          iconName="icon-share"
          closeOnClick={false}
          dataCy="share-survey"
        >
          <div className="flex justify-between items-center w-full">
            {getCultureLocale('Share')}
            <SVGIcon
              size="24"
              iconName="icon-arrow_right"
              fillColor="var(--n-600)"
            />
          </div>
          {showShareOptions && (
            <ShareLink.Options
              getToken={getToken}
              pathName="share/survey"
              onSuccessFunction={(successMessage = 'Link Copied') =>
                addToast({ msg: successMessage, type: 'info' })
              }
              closeOnClick={false}
              isDirectOptions
            />
          )}
        </Dropdown.MenuItem>
        {withRemindOptions && (
          <Dropdown.MenuItem
            onClick={sendReminderHandler}
            iconName="icon-notifications_active"
            dataCy="remind-survey"
          >
            {getCultureLocale('Remind')}
          </Dropdown.MenuItem>
        )}
        <Dropdown.MenuItem
          onClick={() =>
            history.push(
              `/duplicate-pulse-survey/${id}?occurrenceId=${occurrenceId}`
            )
          }
          iconName="icon-file_copy"
          closeOnClick={false}
          dataCy="duplicate-survey"
        >
          {getCultureLocale('Duplicate')}
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          onClick={() => setModalOpen({ show: true, id })}
          iconName="icon-delete"
          iconColor="var(--r-600)"
          textColor="text-r-600"
          dataCy="delete-survey"
        >
          {getCultureLocale('Delete')}
        </Dropdown.MenuItem>
      </Dropdown.MenuItems>
    </>
  );
};

export default ManageSurveyOptions;

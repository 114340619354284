import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import startCase from 'lodash/startCase';

import { useFormalReview } from 'context/FormalReviewContext';
import useClickOutside from 'hooks/useClickOutside';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import Shimmer from 'components/design-system/shimmer/Shimmer';

import DropdownSources from './DropdownSources';

function EvidenceSidebarHeader({
  getAnswersData,
  getSidebarSourcesData,
  selectedState,
  setSelectedState,
  stateDropdown,
  setStateDropdown,
  filterPhases,
  setAnswersData,
  setIsLoadingAnswer,
  getData
}) {
  const [isDialogHistoryOpen, setIsDialogHistoryOpen] = useState(false);
  const [isLoading, setIsLoading] = useState([]);
  const dialogHistory = useRef();
  useClickOutside(dialogHistory, () => setIsDialogHistoryOpen(false));

  const [
    {
      phase: { type: phaseType }
    },
    { _ }
  ] = useFormalReview();

  const onChangeDropdown = (val, section) => {
    setIsLoadingAnswer(true);
    setAnswersData(null);

    const newData = JSON.parse(JSON.stringify(selectedState));
    const newDropdownData = JSON.parse(JSON.stringify(stateDropdown));
    const loadingDict = {
      year: ['cycle', 'phase', 'track'],
      source: ['cycle', 'phase', 'track'],
      cycle: ['phase', 'track'],
      phase: ['track'],
      track: []
    };

    if (section == 'year') {
      newData.year = val;
      getSidebarSourcesData(val.value);
    }

    if (section == 'source') {
      newData.source = val;
      if (val.type !== 'formal_review') {
        delete newData.cycle;
        delete newData.phase;
        delete newData.track;
        newDropdownData.cycle.show = false;
        newDropdownData.phase.show = false;
        newDropdownData.track.show = false;
      } else {
        newData.cycle = [];
        newData.phase = [];
        newData.track = [];
        newDropdownData.cycle.show = true;
        newDropdownData.phase.show = true;
        newDropdownData.track.show = true;
      }
    }

    if (section == 'cycle') {
      const filteredPhases = filterPhases(val.phases);
      const newSelectedPhase =
        filteredPhases?.find((phase) => phase.type == phaseType) ||
        filteredPhases[0] ||
        [];
      newData.cycle = val;
      newData.phase = newSelectedPhase;
      newData.track = newSelectedPhase?.trackConfigs?.[0] || [];

      newDropdownData.phase.data = filteredPhases || [];
      newDropdownData.track.data = newSelectedPhase?.trackConfigs || [];

      if (filteredPhases.length == 0) {
        setIsLoadingAnswer(false);
        setAnswersData([]);
      } else {
        getAnswersData(
          val.id,
          newSelectedPhase?.type,
          newSelectedPhase?.trackConfigs?.[0]?.type,
          newSelectedPhase?.trackConfigs?.[0]?.id
        );
      }
    }

    if (section == 'phase') {
      newData.phase = val;
      newData.track = val?.trackConfigs[0];

      newDropdownData.track.data = val?.trackConfigs;
      getAnswersData(
        selectedState?.cycle?.id,
        val?.type,
        val?.trackConfigs[0]?.type,
        val?.trackConfigs[0]?.id
      );
    }

    if (section == 'track') {
      newData.track = val;
      getAnswersData(
        selectedState?.cycle?.id,
        selectedState?.phase?.type,
        val?.type,
        val?.id
      );
    }

    if (section == 'source' && val.type == 'formal_review') {
      getData();
    } else {
      setSelectedState(newData);
      setStateDropdown(newDropdownData);
    }
    setIsLoading(loadingDict[section]);

    setTimeout(() => {
      setIsLoading([]);
    }, 500);
  };

  const DialogHistory = () => {
    return (
      <div
        data-cy="dialog-history"
        ref={dialogHistory}
        className={`container-dialog-history shadow-temporary ${
          selectedState.source.type !== 'formal_review'
            ? 'height-fit-content'
            : ''
        }`}
      >
        {Object.keys(stateDropdown).map((val, idx) => {
          if (isLoading.includes(val)) {
            return <DropdownLoadingSkeleton key={idx} />;
          } else if (stateDropdown[val]['show']) {
            return (
              <div
                className={`${
                  val == 'source' &&
                  selectedState.source.type !== 'formal_review'
                    ? 'mb-[0px]'
                    : 'mb-[16px]'
                } label-and-dropdown`}
                key={idx}
              >
                <p className="typography-h100 mb-[8px] text-n-800 capitalize w-[48px]">
                  {val}
                </p>

                <DropdownSources
                  objectKey={val}
                  defaultValue={selectedState[val]}
                  listValue={stateDropdown[val].data}
                  onChange={(data) => onChangeDropdown(data, val)}
                />
              </div>
            );
          }
        })}
      </div>
    );
  };

  const DialogPortal = () => {
    let portal = document.querySelectorAll('[id^=dialog-history-portal]')[0];

    return ReactDOM.createPortal(<DialogHistory />, portal);
  };

  return (
    <>
      {isDialogHistoryOpen && <DialogPortal />}
      <div className="px-[24px] border-bottom-n-400 pt-[24px] pb-[8px]">
        <h4 className="text-n-900 typography-h600 mb-[8px]">
          {getObjectiveLocale('History')}
        </h4>
        <div
          className="container-history-state cursor-pointer"
          onClick={() => setIsDialogHistoryOpen(true)}
          data-cy="change-sources"
        >
          {Object.keys(selectedState).map((val, idx) => {
            if (
              ['cycle', 'phase', 'track'].includes(val) &&
              selectedState.source.type !== 'formal_review'
            ) {
              return;
            }

            return (
              <div className="flex mb-[8px]" key={idx}>
                <p className="typography-h100 text-n-800 capitalize w-[48px]">
                  {val}
                </p>
                <p
                  data-cy={`${val}-state`}
                  className="typography-h100 ml-[32px] history-ellipsis"
                >
                  {getObjectiveLocale(
                    startCase(
                      selectedState[val]?.name ||
                        selectedState[val]?.title ||
                        selectedState[val]?.type
                    )
                  ) || 'No data available'}
                </p>
              </div>
            );
          })}

          <Button.Tertiary
            onClick={() => setIsDialogHistoryOpen(true)}
            datacy="button-change-sources"
          >
            Change Sources
          </Button.Tertiary>
        </div>
      </div>
    </>
  );
}

const DropdownLoadingSkeleton = () => {
  return (
    <div className="mb-[16px]">
      <div className="mb-[8px] h-[16px]">
        <Shimmer height="100%" customClass="rounded-lg" width="48px" />
      </div>
      <div className="h-[36px]">
        <Shimmer
          height="100%"
          customClass="rounded-lg"
          width="280px"
          borderRadius="4px"
        />
      </div>
    </div>
  );
};

export default EvidenceSidebarHeader;

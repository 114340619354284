import React, { useRef } from 'react';

const Draggable = ({
  children,
  level,
  id,
  draggedId,
  setDraggedId = () => null,
  setDraggable,
  drop,
  task,
  index,
  groupData,
  sectionData,
  taskNode,
  objectiveChildren,
  showChild = false,
  useDrop = true,
  isDraggable = true
}) => {
  const rowContent = useRef(null);

  const onDragStart = (ev) => {
    ev.stopPropagation();
    ev.dataTransfer.setData('text/plain', ev.target.id);
    setDraggedId({
      id: ev.currentTarget.id,
      task: task,
      level: level,
      clientX: ev.clientX,
      groupId: groupData?.id,
      groupName: groupData?.name,
      index: index,
      taskNode: taskNode,
      objectiveChildren: objectiveChildren,
      showChild: showChild,
      ...(sectionData?.id && { sectionId: sectionData?.id }),
      ...(sectionData?.name && { sectionName: sectionData?.name })
    });
    rowContent.current.style.opacity = 0.3;
    if (setDraggable) {
      setDraggable(true);
    }
  };

  let counter = 0;
  const borderdummy = document.getElementById(`border-drag-${id}`);
  const borderpadding = document.getElementById(`border-padding-${id}`);

  return (
    <>
      {useDrop ? (
        <div
          ref={rowContent}
          draggable={isDraggable}
          onDragStart={onDragStart}
          level={level}
          id={id}
          groupId={groupData?.id}
          groupName={groupData?.name}
          sectionId={sectionData?.id}
          sectionName={sectionData?.name}
          onDragEnter={(e) => {
            e.stopPropagation();
            if (draggedId?.id != id) {
              if (draggedId?.level == 1) {
                if (draggedId?.level != level) {
                  borderdummy && borderdummy.classList.remove('invisible');
                }
              } else {
                if (level != 1) {
                  borderdummy && borderdummy.classList.remove('invisible');
                }
              }
            }
            counter++;
          }}
          onDragLeave={() => {
            counter--;
            if (counter == 0) {
              borderdummy && borderdummy.classList.add('invisible');
            }
          }}
          onDragOver={(e) => {
            if (borderpadding != null) {
              if (e.clientX - draggedId?.clientX > 5) {
                borderpadding.style.width = '105px';
              } else {
                borderpadding.style.width = '36px';
              }
            }
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            const sourceId = e.dataTransfer.getData('text/plain');
            borderdummy && borderdummy.classList.add('invisible');
            const sourceElement = document.getElementById(sourceId);
            sourceElement && (sourceElement.style.opacity = 1);
            const isSub = e.clientX - draggedId?.clientX > 5;
            drop(e, sourceId, isSub, sourceElement);
          }}
          onDragEnd={() => {
            rowContent.current.style.opacity = 1;
          }}
        >
          {children}
        </div>
      ) : (
        <div
          ref={rowContent}
          draggable={isDraggable}
          level={level}
          id={id}
          onDragStart={onDragStart}
          onDragEnd={() => {
            rowContent.current.style.opacity = 1;
            setDraggable(false);
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default Draggable;

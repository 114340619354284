import React from 'react';

import QuestionnaireAnswersContent from 'components/formal-review/evidence-sidebar/QuestionnaireAnswersContent';
import WhiteCardEvidence from 'components/shared/card/WhiteCardEvidence.js';

const CardGeneralQuestionnaireSidebar = ({
  questionnaireData,
  trackType,
  position = 'before',
  isEvidenceSidebar = false
}) => {
  return (
    <div
      className="mt-[24px]"
      id={'container-general-questionnaire-sidebar'}
      data-cy={'container-general-questionnaire-sidebar'}
    >
      {questionnaireData?.length > 0 &&
        questionnaireData?.map((section, index) => (
          <div key={index}>
            {position == 'after' &&
              !(index === 0 && trackType === 'general_questionnaire') &&
              section?.questions?.length > 0 && (
                <div
                  className={`border-solid border-0 border-t-[1px] border-n-400 ${
                    isEvidenceSidebar ? 'my-[24px]' : 'my-[40px]'
                  }`}
                ></div>
              )}
            {section?.questions?.length > 0 && (
              <p
                className={`${
                  isEvidenceSidebar ? 'typography-h500' : 'typography-h600'
                } text-n-900 mb-[24px]`}
              >
                {section?.name}
              </p>
            )}

            {section?.questions?.map((question, index) => (
              <WhiteCardEvidence
                key={index}
                title={question.question.question}
                withCloseButton={false}
              >
                <QuestionnaireAnswersContent
                  answers={question.answers}
                  question={question.question}
                />
              </WhiteCardEvidence>
            ))}
            {position == 'before' &&
              !(
                index === questionnaireData?.length - 1 &&
                trackType === 'general_questionnaire'
              ) && (
                <div
                  className={`border-solid border-0 border-t-[1px] border-n-400 ${
                    isEvidenceSidebar ? 'my-[24px]' : 'my-[40px]'
                  }`}
                ></div>
              )}
          </div>
        ))}
    </div>
  );
};

export default CardGeneralQuestionnaireSidebar;

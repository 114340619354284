import React, { useState } from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import TooltipPortal from 'components/shared/Tooltips/TooltipPortal';
import WhiteCardWithFooter from 'components/shared/WhiteCardWithFooter';

import './_overallViewProgress.scss';

const OverallStatus = ({ listStatus = [], onClick, active = 'all' }) => {
  const [portalData, setPortalData] = useState({});
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const setPortal = (e) => {
    setIsTooltipShown(true);
    setPortalData({
      name: getObjectiveLocale('Filter on this specific status'),
      left: e.currentTarget.getBoundingClientRect().x + 'px',
      translateX: '-20%',
      top: e.currentTarget.getBoundingClientRect().top - 36 + 'px',
      noWrap: true
    });
  };

  listStatus = listStatus?.map((v, i) => {
    let progressTitle, bgColor, color, colorLine;
    if (v.progressColorHex === '5A9AEF') {
      progressTitle = getObjectiveLocale('Exceed');
      bgColor = 'bg-b-200';
      color = 'text-b-800';
      colorLine = 'bg-b-600';
    }
    if (v.progressColorHex === '44DB5E') {
      progressTitle = getObjectiveLocale('On track');
      bgColor = 'bg-g-200';
      color = 'text-g-800';
      colorLine = 'bg-g-600';
    }
    if (v.progressColorHex === 'FFCD00') {
      progressTitle = getObjectiveLocale('Left behind');
      bgColor = 'bg-y-200';
      color = 'text-y-700';
      colorLine = 'bg-y-600';
    }
    if (v.progressColorHex === 'FE2851') {
      progressTitle = getObjectiveLocale('At risk');
      bgColor = 'bg-r-200';
      color = 'text-r-600';
      colorLine = 'bg-r-200';
    }

    return {
      ...v,
      key: i,
      progressTitle: progressTitle,
      bgColor: bgColor,
      color: color,
      colorLine: colorLine
    };
  });

  const isFloat = (number) => {
    return number % 1 !== 0;
  };

  const absoluteFooter = (listStatus) => {
    return (
      <div className="flex">
        {listStatus?.map((v, i) => (
          <div
            key={i}
            className={`${v.colorLine} h-[4px] ${
              i !== 0 && parseInt(v.percentage) !== 0 && `ml-[3px]`
            }`}
            style={{ width: `${parseInt(v.percentage.toFixed(0))}%` }}
            data-cy={`line-color-${v.progressTitle}`}
          />
        ))}
      </div>
    );
  };

  const whichStatus = {
    'At risk': 'bad',
    'Left behind': 'warning',
    'On track': 'good',
    Exceeded: 'exceed_expectation'
  };
  return (
    <WhiteCardWithFooter
      withAbsoluteFooter
      absoluteFooterContent={absoluteFooter(listStatus)}
      bodyClass="overall-card-status"
      containerClass={'overflow-hidden'}
    >
      <p className="overall-objective-title typography-h200">
        {getObjectiveLocale('Status')}
      </p>
      <div className="flex justify-between mt-[16px]">
        {listStatus?.map((v, i) => (
          <div
            key={i}
            className={`section-progress ${
              active == 'all'
                ? ''
                : active.includes(whichStatus[v.progressTitle])
                ? ''
                : 'opacity-50'
            }`}
            onClick={() => onClick(v.progressTitle)}
            onMouseOver={(e) => setPortal(e)}
            onMouseOut={() => setIsTooltipShown(false)}
          >
            <span
              className={`${v.bgColor} ${v.color} label-square`}
              data-cy={`qty-${v.progressTitle}`}
            >
              {v.qty}
            </span>
            <span className="flex items-center flex-row mt-[8px]">
              <p
                className="typography-h200"
                data-cy={`title-${v.progressTitle}`}
              >
                {v.progressTitle}
              </p>
              <p
                className="percentage-progress typography-h100"
                data-cy={`percentage-${v.progressTitle}`}
              >
                &nbsp;·&nbsp;
                {isFloat(v.percentage) ? v.percentage.toFixed(2) : v.percentage}
                %
              </p>
            </span>
          </div>
        ))}
      </div>
      <TooltipPortal
        show={isTooltipShown}
        portalData={portalData}
        portalId="shared-tooltip"
      />
    </WhiteCardWithFooter>
  );
};

export default OverallStatus;

import React, { Fragment, useEffect, useState } from 'react';
import Masonry from 'react-masonry-component';
import { withRouter } from 'react-router';

import {
  getListRole,
  getListTeam,
  getPerformanceReportOverview
} from 'client/PerformanceReport';
import { useProfileFilter } from 'context/ProfileFilterContext';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import NewPerformanceReport from 'components/performance-report/NewPerformanceReport';
import ListEmptyState from 'components/shared/ListEmptyState';

import PerformanceReportTeamComponent from '../PerformanceReportTeamComponent';

function PerformanceReportTab(props) {
  const [averageTeamScore, setAverageTeamScore] = useState(null);
  const { user, config } = useUser();
  const [performanceReportTeam, setPerformanceReportTeam] = useState(null);
  const userId = props.userId;
  const [activeState, setActiveState] = useState('team');
  const [listUserTeam, setListUserTeam] = useState([]);
  const { filter } = useProfileFilter();

  let myTeam;
  if (props.profileUser) {
    if (props.profileUser.name.includes(' ')) {
      myTeam = `${props.profileUser.name.substr(
        0,
        props.profileUser.name.indexOf(' ')
      )}'s Team`;
    } else {
      myTeam = `${props.profileUser.name}'s Team`;
    }
  }

  const getListUserScoreAndTeam = async () => {
    const { data, error } =
      activeState === 'team'
        ? await getListTeam(filter, userId)
        : await getListRole(filter, userId);
    if (data) {
      setListUserTeam(data);
    }
  };

  const getPerformanceScore = async () => {
    const { data, error } = await getPerformanceReportOverview(filter, userId);
    if (data) {
      setPerformanceReportTeam(data);
    }
  };

  useEffect(() => {
    getPerformanceScore();
  }, [filter]);

  useEffect(() => {
    getListUserScoreAndTeam();
  }, [activeState, filter]);

  return (
    <div className="performance-report">
      {averageTeamScore != null && <h2>{myTeam}</h2>}
      {performanceReportTeam && (
        <Fragment>
          <NewPerformanceReport userId={userId} data={performanceReportTeam} />
          <div className="button-role-team-container">
            <button
              style={
                activeState === 'team'
                  ? {
                      backgroundColor: 'var(--base-100)',
                      color: 'var(--base-500)',
                      borderColor: 'var(--base-600)'
                    }
                  : {}
              }
              className="button-role"
              onClick={() => setActiveState('team')}
            >
              {props.profileUser.name + ' Team'}
            </button>
            <button
              style={
                activeState === 'role'
                  ? {
                      backgroundColor: 'var(--base-100)',
                      color: 'var(--base-500)',
                      borderColor: 'var(--base-600)'
                    }
                  : {}
              }
              className="button-team"
              onClick={() => setActiveState('role')}
            >
              {props.profileUser.name + ' Role'}
            </button>
          </div>
        </Fragment>
      )}
      <div className="row">
        {listUserTeam?.length > 0 && (
          <Masonry>
            {listUserTeam.map((listUserTeam, index) => (
              <div key={index} className="col-md-6">
                <PerformanceReportTeamComponent
                  data={listUserTeam}
                  setDetailInfo={props.setDetailInfo}
                />
              </div>
            ))}
          </Masonry>
        )}
        {listUserTeam?.length == 0 && (
          <ListEmptyState
            containerClassname="my-[40px]"
            fullHeight
            emptyIcon="icon-no-team"
            title={getObjectiveLocale("You don't have any team yet")}
            subtitle={getObjectiveLocale(
              'The teams establishes a sense of direction that guides your decisions and task \n to be more aligned with your company mission.'
            )}
          />
        )}
      </div>
    </div>
  );
}

export default withRouter(PerformanceReportTab);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import {
  exportCycleProgressCSV,
  getLatestCycleProgressCSV,
  getListDirectReport,
  getSummaryProgress,
  getSummaryProgressByPhase,
  sendEmailReminder
} from 'client/FormalReviewClient';
import { useUser } from 'context/UserContext';
import { getObjectiveLocale, loadMoreValidator } from 'utils/HelperUtils';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import ProgressContentTable from 'components/formal-review/progress-result/ProgressContentTable';
import ExportCSVButton from 'components/shared/ExportCSVButton';
import LoadingComponent from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import ProfileSection from 'components/shared/Profile/ProfileSection';
import SearchBar from 'components/shared/SearchBar';
import AdminTabWithCount from 'components/shared/Tabs/AdminTabWithCount.js';
import HighChart from 'components/shared/charts/HighChart';
import ModalComponent from 'components/shared/modal/Modal';
import SidebarTeamResult from 'components/sidebar/SidebarTeamResult';

import ImportScoreSections from './ImportScoreSections';

const ProgressContent = ({
  filter,
  searchValue,
  setFilter,
  searchDebounce,
  setTotalReviewer,
  currentMenu,
  isReadOnly,
  setToaster,
  lockAnswerCycle,
  listPhases,
  importAnswersEnabled
}) => {
  const history = useHistory();
  const pageParams = useParams();
  const firstRender = useRef(true);
  const searchFirstRender = useRef(true);
  const { config, organization } = useUser();

  const [currentPage, setCurrentPage] = useState(1);
  const [listUsers, setListUsers] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [sort, setSort] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [prevPagination, setPrevPagination] = useState({});
  const [currentTab, setCurrentTab] = useState('reviewee');
  const [highChartData, setHighChartData] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [modalParams, setModalParams] = useState({});
  const [onLoadMoreModal, setOnLoadMoreModal] = useState(false);
  const [olderThan, setOlderThan] = useState('');

  const sidebarId = new URLSearchParams(window.location.search).get('id');
  let searchParams = new URLSearchParams(window.location.search);

  const listObjTabs = [
    { key: 'reviewee', name: 'Reviewees' },
    config?.permissions?.reviewPerformanceCycleProgressAllSee && {
      key: 'reviewer',
      name: 'Reviewers'
    }
  ].filter((val) => val);

  const getParams = () => {
    let query = {
      sortColumn: sort.sortColumn,
      sortDirection: sort.sortDirection,
      sortScore: sort.sortDirection,
      role: currentTab,
      ...filter
    };
    if (filter) {
      delete query.show;
      query.showType = filter?.show?.map((e) => e?.id);
      query.q = filter.q;
      query.role = currentTab;
    }

    return query;
  };

  const params = getParams();

  const getUserCycle = async (paginateParam) => {
    setUserLoading(true);
    const query = getParams();
    query.limit = 10;

    if (paginateParam == 'prev') {
      if (listUsers.length > 0) {
        query.newerThan = pagination?.prev?.newerThan;
      } else {
        query.olderThan = prevPagination?.next?.olderThan;
      }
      setCurrentPage(currentPage - 1);
    }

    if (paginateParam == 'next') {
      query.olderThan = pagination?.next?.olderThan;
      setCurrentPage(currentPage + 1);
    }

    const { data, pagination: dataPagination } = await getListDirectReport(
      pageParams.id,
      query
    );
    if (data) {
      if (data.length > 0 && listUsers.length > 0) {
        setPrevPagination(pagination);
      }
      setListUsers(data);
      setPagination(dataPagination);
      if (dataPagination?.next?.olderThan && data?.length == 10) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      setUserLoading(false);
    }
  };

  const getHighChartData = async () => {
    setChartLoading(true);
    const params = {
      division: filter?.Division,
      department: filter?.Department,
      showType: filter?.show?.map((e) => e?.id),
      role: currentTab,
      ...filter
    };
    delete params.show;

    const { data, metadata } = await getSummaryProgress(pageParams.id, params);
    if (data) {
      setHighChartData(data);
    }
    if (metadata) {
      setTotalReviewer(metadata?.metadata?.totalIncompleteReviewers);
    }
    setChartLoading(false);
  };

  const remindReviewerReviewee = async () => {
    const params = {};
    params.phaseType = modalParams.phase;
    if (currentTab === 'reviewer') {
      let actorIds = modalParams.list.map((singleList) => singleList.id);
      params.actorId = actorIds;
    } else if (currentTab === 'reviewee') {
      let actorIds = modalParams.list.map((singleList) => {
        return singleList.assignments.map((assignment) => {
          if (assignment.state == 'in_progress') {
            return assignment.actor.id;
          }
        });
      });
      // merge possible arrays to 1 array and remove undefined
      let cleanConcatedActorIds = [].concat(...actorIds).filter((id) => id);
      params.actorId = cleanConcatedActorIds;
    }
    const { isSuccess } = await sendEmailReminder(pageParams.id, params);
    if (isSuccess) {
      setToaster(`Successfully sent out notification.`, false);
      setModalParams({});
    } else {
      setToaster(`Failed to send notification.`, true);
      setModalParams({});
    }
  };

  const onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      setOnLoadMoreModal(true);
      await getModalData();
      setOnLoadMoreModal(false);
    };

    const hasMore = olderThan ? true : false;

    if (!onLoadMoreModal && hasMore) {
      loadMoreValidator(target, 50, () => {
        loadMore();
      });
    }
  };

  const getUsers = (data) => {
    const dataUser = data.map((assignment) => {
      let actor = assignment?.actor;
      let target = assignment?.target;
      let revieweeTab = currentTab === 'reviewee';

      let userName = revieweeTab ? actor?.name : target?.name;
      let userProfilePicture = revieweeTab
        ? actor?.profilePicture
        : target?.profilePicture;

      return {
        name: userName,
        src: userProfilePicture
      };
    });

    return dataUser;
  };

  const getModalData = async () => {
    setOnLoadMoreModal(true);
    let modalData = cloneDeep(modalParams);

    const body = {
      userIds: modalParams.userIds,
      olderThan: olderThan,
      limit: 10,
      sortColumn: 'name',
      sortDirection: 'asc'
    };

    const { data, pagination } = await getSummaryProgressByPhase(
      pageParams.id,
      modalParams.phase,
      currentTab,
      body
    );

    if (data) {
      const selectorRole = currentTab === 'reviewee' ? 'actor' : 'target';
      modalData.list = modalData?.list ? [...modalData.list, ...data] : data;
      modalData.selector = selectorRole;
      setModalParams(modalData);
    }
    if (pagination) {
      setOlderThan(pagination.next.olderThan);
    }
    setOnLoadMoreModal(false);
  };

  const closeSidebar = () => {
    if (searchParams.has('id')) {
      searchParams.delete('id');
      searchParams.delete('placement');
      history.replace({
        search: searchParams.toString()
      });
    }
  };

  const handleSelectRow = (e, user) => {
    e.stopPropagation();

    searchParams.set('id', user.userId);
    searchParams.set('placement', user.id);
    history.replace({
      search: searchParams.toString()
    });
  };

  const getData = () => {
    getHighChartData();
    setSort({ sortColumn: 'name', sortDirection: 'asc' });
  };

  const debounceFn = useCallback(debounce(getData, 500), [
    filter,
    currentMenu,
    currentTab
  ]);

  useEffect(() => {
    debounceFn();
    setCurrentPage(1);
    return debounceFn.cancel;
  }, [filter, currentTab, currentMenu, debounceFn]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      getUserCycle();
    }
  }, [sort]);

  useEffect(() => {
    if (!isEmpty(modalParams)) {
      getModalData();
    }
  }, [modalParams?.phase, modalParams?.progress]);

  useEffect(() => {
    if (searchFirstRender.current) {
      searchFirstRender.current = false;
    } else {
      filter.q = searchValue;
      setFilter(filter);
      getUserCycle();
      setCurrentPage(1);
    }
  }, [searchValue]);

  const csvManageDisable = ['tiket', 'tikettrial'].includes(
    organization.identifier
  );

  return (
    <div className="relative px-[40px]">
      {!csvManageDisable && (
        <div className="absolute right-[40px] flex flex-row">
          {/* <ExportCSVButton
            getLatestCSV={() =>
              getLatestCycleProgressCSV(pageParams.id, params)
            }
            exportCSV={() => exportCycleProgressCSV(pageParams.id, params)}
            params={params}
            ready={!userLoading && listUsers.length > 0}
          /> */}
          {importAnswersEnabled &&
            config.permissions.reviewPerformanceAnswersImport && (
              <ImportScoreSections
                cycleId={pageParams.id}
                getUserCycle={getUserCycle}
              />
            )}
        </div>
      )}
      <HighChart
        chartData={highChartData}
        isLoading={chartLoading}
        setModalParams={setModalParams}
      />
      <div className="team-result-table-wrapper mt-[26px]">
        <AdminTabWithCount
          listObjTabs={listObjTabs}
          changeTab={(tab) => {
            setCurrentTab(tab);
            closeSidebar();
          }}
          active={currentTab}
          withCount={false}
        />
        <div className="mb-[16px]">
          <SearchBar
            fullWidth={true}
            placeholder="Search"
            containerClass="mt-[8px]"
            onChange={(e) => searchDebounce(e.target.value)}
          />
        </div>
        <ProgressContentTable
          users={listUsers}
          highCharts={highChartData}
          onClickRow={handleSelectRow}
          currentTab={currentTab}
          currentPage={currentPage}
          sort={sort}
          setSort={setSort}
          isLoading={userLoading}
        />
        <div className="flex justify-end items-center pb-[40px] pt-[24px]">
          <PaginationModule
            pageNumber={currentPage}
            handlePrev={() => getUserCycle('prev')}
            handleNext={() => getUserCycle('next')}
            hasMore={hasMore}
          />
        </div>
      </div>
      {sidebarId && (
        <SidebarTeamResult
          sidebarId={sidebarId}
          closeSidebar={closeSidebar}
          currentTab={currentTab}
          setToaster={setToaster}
          getData={getData}
          isReadOnly={isReadOnly}
          lockAnswerCycle={lockAnswerCycle}
          listPhases={listPhases}
          getUserCycle={getUserCycle}
        />
      )}
      {!isEmpty(modalParams) && (
        <ModalComponent
          title={`${getObjectiveLocale(
            modalParams.phase.replace(/_|_review|_selections|_manager/g, ' ')
          )} - ${modalParams.progress} (${modalParams.count})`}
          withCloseIcon
          eventOnClickClose={() => {
            setModalParams({});
            setOlderThan('');
          }}
          // temporary until audit multi placement in progress
          // withPrimaryBtn={
          //   modalParams.progress === 'In Progress' &&
          //   config?.formalReviewReminder && {
          //     title: `Send Reminder to reviewer(s)`,
          //     onClick: () => remindReviewerReviewee()
          //   }
          // }
          className={`w-[600px] modal-summary-progress ${
            modalParams.progress === 'In Progress' ? 'pb-[0px]' : 'pb-[24px]'
          }`}
          withFooter={
            modalParams.progress === 'In Progress' &&
            config?.formalReviewReminder
          }
        >
          <div className="flex justify-between h-[36px]">
            <div className="typography-h200 text-n-800 uppercase">
              {currentTab === 'reviewee' ? 'REVIEWEE' : 'REVIEWER'}
            </div>
            <div className="typography-h200 text-n-800 uppercase w-[170px]">
              {currentTab === 'reviewee' ? 'REVIEWER' : 'REVIEWEE'}
            </div>
          </div>
          <div
            className="overflow-y-auto max-h-[300px] list-user-cycle"
            onScroll={onScroll}
          >
            {modalParams?.list &&
              modalParams?.list.map((data, idx) => (
                <div
                  key={idx}
                  className="h-[56px] flex justify-between flex-align-center w-full"
                >
                  <ProfileSection
                    name={data.name}
                    profilePicture={data.profilePicture}
                    desc={data.email}
                    totalOwners={1}
                    customNameJobSectionClass="w-[300px]"
                  />
                  <div className="w-[170px] self-center">
                    <AvatarGroup avatars={getUsers(data.assignments)} />
                  </div>
                </div>
              ))}
            {onLoadMoreModal && <LoadingComponent />}
          </div>
        </ModalComponent>
      )}
    </div>
  );
};

export default ProgressContent;

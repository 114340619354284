import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';
import FormattedNumber from 'src/components/design-system/FormattedNumber';

import './HeaderWorkScore.scss';

const HeaderWorkScore = ({
  totalNilai,
  trackType,
  showScore = true,
  scoreWithBorder = true,
  scoreWithBackground = false,
  formula
}) => {
  return (
    <div className="header-work-score-title-container">
      {showScore && (
        <div
          className={`wrapper-score ${
            scoreWithBackground ? 'bg-n-200 p-[12px]' : ''
          }`}
        >
          <p className="text-n-800 typography-h300 mb-[8px] flex">
            {getObjectiveLocale(`[${trackType.replace('_', ' ')}] score`)}
            {formula && (
              <TooltipContainer
                show
                text={formula}
                classContainer="cursor-pointer"
                useMaxWidth={false}
              >
                <SVGIcon
                  size="16"
                  iconName="icon-info"
                  fillColor="var(--n-600)"
                  customClass="ml-[4px]"
                />
              </TooltipContainer>
            )}
          </p>
          <div className="flex">
            <FormattedNumber
              customClass={`text-base-600 typography-h500 w-full ${
                scoreWithBackground ? '' : 'mb-[16px] pb-[8px]'
              } ${
                scoreWithBorder
                  ? 'border-solid border-b border-0 border-n-400'
                  : ''
              }`}
              number={totalNilai || 0}
              useAbbrev={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderWorkScore;

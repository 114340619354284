import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { getConfig } from 'client/admin/Config';
import { useUser } from 'context/UserContext';
import useOrganization from 'hooks/useOrganization';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Calendar from 'components/shared/Calendar';
import ContentNavigator from 'components/shared/ContentNavigator';

import {
  RadioButtonOrganization,
  ToggleCard,
  setActiveNavigator
} from './OrganizationHelper';

const Mission = ({ configParams }) => {
  const { config } = useUser();

  const {
    configData,
    changeConfigData,
    originConfigData,
    changeOriginConfigData
  } = useOrganization((configData) => ({
    configData: configData.configData,
    changeConfigData: configData.changeConfigData,
    originConfigData: configData.originConfigData,
    changeOriginConfigData: configData.changeOriginConfigData
  }));
  const isReadOnly =
    usePermission('settingsOrganizationMissions') === 'read_only';

  const [activeSection, setActiveSection] = useState('enable-mission');

  const listEnableMission = [
    {
      element: 'toggle',
      isChecked: configData?.useMissions,
      onChange: () =>
        changeConfigData({
          ...configData,
          useMissions: !configData.useMissions
        }),
      cardTitle: getObjectiveLocale('Enable Mission'),
      cardDescription: getObjectiveLocale(
        'User can enable mission from this app.'
      ),
      disabled: isReadOnly,
      dataCy: 'enableMission'
    }
  ];

  const selectedDate =
    configData?.annualResetMissionPoint == '/' + new Date().getFullYear()
      ? originConfigData?.annualResetMissionPoint == ''
        ? dayjs()
        : dayjs(
            originConfigData?.annualResetMissionPoint +
              '/' +
              new Date().getFullYear()
          )
      : dayjs(
          configData?.annualResetMissionPoint + '/' + new Date().getFullYear()
        );
  const listResetAnnual = [
    {
      element: 'radio',
      label: 'User can enable annual reset mission point.',
      handleChange: (input) =>
        changeConfigData({
          ...configData,
          annualResetMissionPoint:
            input === 'disable'
              ? ''
              : configData?.annualResetMissionPoint +
                  '/' +
                  new Date().getFullYear() || new Date()
        }),
      selected:
        configData?.annualResetMissionPoint == '' ? 'disable' : 'enable',
      listOption: [
        {
          id: 'enable',
          title: 'Enable',
          children: configData?.annualResetMissionPoint !== '' && (
            <Calendar
              defaultValue={selectedDate}
              handleChangeCalendar={(date) =>
                changeConfigData({
                  ...configData,
                  annualResetMissionPoint: dayjs(date).format('MM/DD')
                })
              }
              type="startDate"
              customBox="border-solid border-n-500 border bg-n-000 h-[32px] rounded-[4px] mb-[24px]"
              calendarButtonType="no"
            />
          )
        },
        { id: 'disable', title: 'Disable' }
      ],
      dataCy: 'annualResetMissionPoint'
    }
  ];

  let list = [
    { id: 'enable-mission', name: 'Enable Mission', list: listEnableMission },
    {
      id: 'annual-reset-mission-point',
      name: 'Reset Annual Mission Point',
      list: listResetAnnual
    }
  ];

  const getData = async () => {
    const { data } = await getConfig(configParams);
    if (data) {
      changeConfigData(data);
      changeOriginConfigData(data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="overflow-auto h-[calc(100vh-64px)] w-[87%] relative"
      id="scrollable-config"
      onScroll={(e) => setActiveNavigator(e, list, setActiveSection, -48)}
    >
      <div className="w-[768px] mt-[24px]">
        <div className="fixed right-0 mt-[24px] w-[160px] z-index-10">
          <ContentNavigator
            title="CONTENTS"
            activeSection={activeSection}
            listNavigatorContents={list}
            sectionContent="scrollable-config"
            additionalOffset={20}
          />
        </div>
        <p className="typography-h300 text-n-800 uppercase mb-[24px]">
          {getObjectiveLocale('Mission Configuration')}
        </p>
        {list.map((el, index) => (
          <section
            className="border-solid border-b border-0 border-n-500 pb-[40px] mb-[40px]"
            key={index}
            id={`${el?.id}`}
          >
            <p className="typography-h500 text-n-900 mb-[24px]">
              {getObjectiveLocale(el.name)}
            </p>
            {el.list?.length > 0 &&
              el.list.map((input, idx) => {
                return input.element === 'toggle' ? (
                  <ToggleCard {...input} key={`${index}-${idx}`} />
                ) : input.element === 'radio' ? (
                  <RadioButtonOrganization {...input} key={`${index}-${idx}`} />
                ) : null;
              })}
          </section>
        ))}
      </div>
    </div>
  );
};

export default Mission;

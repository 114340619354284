import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SingleCalendar from 'components/shared/Calendar';
import SVGIcon from 'components/shared/SVGIcon';
import AdminTabWithCount from 'components/shared/Tabs/AdminTabWithCount.js';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

import {
  COMPLETED_PHASE_ID,
  FOUND_TAB_KEY,
  IN_PROGRESS_PHASE_ID,
  NOT_FOUND_TAB_KEY
} from '../Utils/const';
import DropdownAssignment from './fragments/DropdownAssignment';
import TableAssignment from './fragments/TableAssignment';

const UserResults = ({
  phaseItems,
  goBack,
  selectedPhase,
  selectedStatus,
  setSelectedPhase,
  setSelectedStatus,
  rescheduleDateMoment,
  setRescheduleDateMoment,
  usersFoundCount,
  usersNotFoundCount
}) => {
  const phaseStatus = [
    {
      id: IN_PROGRESS_PHASE_ID,
      name: 'In Progress'
    },
    {
      id: COMPLETED_PHASE_ID,
      name: 'Completed'
    }
  ];

  const [activeTab, setActiveTab] = useState(FOUND_TAB_KEY);
  const handleSelectedPhase = (phase) => setSelectedPhase(phase);

  const handleSelectedStatus = (status) => setSelectedStatus(status);

  useEffect(() => {
    if (!selectedStatus) setSelectedStatus(phaseStatus[0]);
    if (!selectedPhase) setSelectedPhase(phaseItems[0]);
  }, []);

  return (
    <>
      {selectedStatus && selectedPhase && (
        <>
          <div className="flex">
            <DropdownAssignment
              containerClass="flex flex-col mr-[16px]"
              label="Select phase"
              items={phaseItems}
              selectedItem={selectedPhase}
              onSelect={handleSelectedPhase}
              keyName="name"
              id="phase-dropdown"
            />
            <DropdownAssignment
              containerClass="flex flex-col mr-[16px]"
              label="Change phase status to"
              items={phaseStatus}
              selectedItem={selectedStatus}
              onSelect={handleSelectedStatus}
              keyName="name"
              id="status-dropdown"
            />
            {selectedStatus.id == IN_PROGRESS_PHASE_ID && (
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <p className="typography-h100 text-n-800 mb-[8px] mr-[4px]">
                    {getObjectiveLocale('Set end date')}
                  </p>
                  <TooltipContainer
                    show
                    text={
                      'By change the phase status to in progress, all cycle on their reviewer will be opened and you need to set a schedule for their reviewer.'
                    }
                    classContainer="cursor-pointer"
                    useMaxWidth={false}
                  >
                    <SVGIcon
                      size="16"
                      iconName="icon-info"
                      fillColor="var(--n-600)"
                    />
                  </TooltipContainer>
                </div>
                <div
                  className="w-[175px] flex flex-row justify-between items-center px-[8px] cursor-pointer border border-solid border-n-400 rounded h-[32px]"
                  data-cy="reschedule-calendar-container"
                >
                  <SingleCalendar
                    handleChangeCalendar={(date) =>
                      setRescheduleDateMoment(dayjs(date).endOf('day'))
                    }
                    defaultValue={
                      rescheduleDateMoment ? rescheduleDateMoment : null
                    }
                    type="dueDate"
                    customBox="justify-between w-[320px] hover:bg-base-00000"
                    calendarButtonType="no"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-row justify-center items-center h-[48px] bg-b-200 mt-[24px] mb-[24px] rounded-lg">
            <p className="typography-paragraph text-n-900 mr-[24px]">{`${usersFoundCount} users found in this cycle, ${usersNotFoundCount} users not found`}</p>
            <p
              className="typography-button text-base-600 cursor-pointer"
              onClick={goBack}
            >
              Edit data
            </p>
          </div>
          <AdminTabWithCount
            active={activeTab}
            listObjTabs={[
              {
                key: FOUND_TAB_KEY,
                name: 'Users found',
                count: usersFoundCount
              },
              {
                key: NOT_FOUND_TAB_KEY,
                name: 'Users not found',
                count: usersNotFoundCount
              }
            ]}
            changeTab={setActiveTab}
            withCount={true}
          />
          {activeTab == FOUND_TAB_KEY ? (
            <TableAssignment
              key={FOUND_TAB_KEY}
              tab={FOUND_TAB_KEY}
              initialData={null}
              phase={selectedPhase}
            />
          ) : (
            <TableAssignment
              key={NOT_FOUND_TAB_KEY}
              tab={NOT_FOUND_TAB_KEY}
              initialData={null}
              phase={selectedPhase}
            />
          )}
        </>
      )}
    </>
  );
};

export default UserResults;

import React, { useEffect, useState } from 'react';

import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import is from 'is_js';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {
  editPost,
  getListUser,
  getPresignUrlCulture,
  submitPost
} from 'client/CultureClient';
import S3Client from 'client/S3Client';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import Config from 'utils/Config';
import {
  formatBytes,
  getCultureLocale,
  getThumbnailVideo,
  parseCaption,
  setPostType
} from 'utils/HelperUtils';

import MentionEditorWithEmoticon from 'components/design-system/MentionEditorWithEmoticon';
import ModalPostTemplate from 'components/modal/culture/ModalPostTemplate';
import LabelSection from 'components/modal/culture/components/LabelSection';
import Checkbox from 'components/shared/Checkbox';
import SVGIcon from 'components/shared/SVGIcon';
import CircleProgressBar from 'components/shared/SVGProgressBar/Circle/CircleProgressBar';
import Modal from 'components/shared/modal/Modal';
import { trackEvent } from 'src/utils/AnalyticUtils';

import ModalConfirmationDialogue from './ModalConfirmationDialogue';
import AttachmentPostPhoto from './components/AttachmentPostPhoto';
import FooterPostPhoto from './components/FooterPostPhoto';

const initffmpeg = () => {
  ffmpeg = createFFmpeg({
    log: process.env.NODE_ENV !== 'production',
    corePath: 'https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js'
  });
};

let ffmpeg = initffmpeg();

function ModalPostPhoto({
  eventOnClick,
  type = 'create',
  existingData = {},
  groupDetail
}) {
  const { config } = useUser();
  const { reload } = useReload();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAdminPost, setIsAdminPost] = useState(
    existingData?.adminPost || false
  );
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [compressionProgress, setCompressionProgress] = useState(null);
  const [titleCompressionProgress, setTitleCompressionProgress] =
    useState(null);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  let isAbleToTranscode =
    is.not.ie() &&
    is.not.safari() &&
    (is.edge('>=79') ||
      is.firefox('>=79') ||
      is.chrome('>=68') ||
      is.opera('>=64')) &&
    'ArrayBuffer' in window;
  const existingTaggedUser = type === 'edit' ? existingData.taggedUsers : [];
  const existingLabels = type === 'edit' ? existingData.labels : [];

  const [selectedLabels, setSelectedLabels] = useState(existingLabels);
  const [metaMentions, setMetaMentions] = useState([]);
  const [taggedUsers, setTaggedUsers] = useState(existingTaggedUser);
  const [linkAttachment, setLinkAttachment] = useState([]);
  const [fileAttachments, setFileAttachments] = useState([]);
  const [mediaAttachments, setMediaAttachments] = useState([]);
  const [progress, setProgress] = useState([]);
  const [errorData, setErrorData] = useState({});
  const [sourceAttachment, setSourceAttachment] = useState([]);
  const [sourceMedia, setSourceMedia] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [caption, setCaption] = useState('');
  const [suggestions, setSuggestion] = useState([]);

  const maxMedia = config.maxNumberOfMedia;
  const maxFile = config.maxNumberOfAttachments;
  const maxFileSize = config.maxAttachmentSize;
  const allowedPostTypes = config.featureConfig.allowedPostTypes;
  const maxDurationVideo = groupDetail?.allowedVideoDuration;

  const checkProgress = (progressPercent, secureUrl) => {
    setProgress({ progressPercent, secureUrl });
  };

  const uploadToServer = async (
    url,
    file,
    checkProgress,
    handleSetSource,
    type
  ) => {
    const { status } =
      (await S3Client(url, file, checkProgress, handleSetSource, type)) || {};
    return status;
  };

  const uploadFile = async (currentAttachment, type) => {
    let dataPresign = [];
    let allStatus = [];

    if (currentAttachment.length > 0) {
      currentAttachment.map((attach) => {
        let presign = {};
        if (attach.type === 'attachment') {
          presign = {
            fileType: attach.type,
            fileName: attach.fileName
          };
        } else {
          presign = {
            fileType: attach.type,
            fileFormat: attach.fileExtension
          };
        }
        dataPresign.push(presign);
      });

      const { data } = await getPresignUrlCulture({
        files: dataPresign
      });

      if (data) {
        currentAttachment.map(async (attachment, index) => {
          attachment.secureUrl = data[index].video
            ? data[index].video.secureUrl
            : data[index].secureUrl;
          attachment.file.secureUrl = data[index].video
            ? data[index].video.secureUrl
            : data[index].secureUrl;
          attachment.thumbnailPresignUrl =
            data[index].video && data[index].thumbnail.presignedUrl;
          const presignedUrl = data[index].video
            ? data[index].video.presignedUrl
            : data[index].presignedUrl;
          const status =
            (await uploadToServer(
              presignedUrl,
              attachment.file,
              checkProgress,
              handleSetSource,
              type
            )) || {};
          allStatus.push(status);

          if (currentAttachment.length === 1 && data[index].video) {
            const status = await uploadToServer(
              data[index].thumbnail.presignedUrl,
              attachment.fileThumbnail
            );
            allStatus.push(status);
          }
        });
      }

      if (allStatus.every((stat) => stat === 200)) return data;
    }
  };

  const validateSharePost = (fromConfirmModal, postType) => {
    if (
      postType === 'multiple_content' &&
      !allowedPostTypes.includes(postType)
    ) {
      setErrorModal('post', [], []);
      return true;
    }

    if (fromConfirmModal) return false;

    if (selectedLabels.some((label) => label.title.indexOf('Official') >= 0)) {
      const isMultipleFile =
        postType === 'multiple_content' &&
        fileAttachments.length > 1 &&
        mediaAttachments.length === 0;

      if (!isAdminPost) {
        setShowModalConfirmation(true);
        return true;
      }

      if ((postType === 'attachment' || isMultipleFile) && caption === '') {
        setShowModalConfirmation(true);
        return true;
      }
    }
  };

  const sharePost = async (fromConfirmModal = false) => {
    const postType = setCurrentPostType();
    if (validateSharePost(fromConfirmModal, postType)) return;

    setIsSubmit(true);

    let requestBody = {
      post: {
        postType: postType,
        adminPost: isAdminPost,
        groupId: groupDetail.id,
        labelIds: selectedLabels.map((label) => label.id),
        taggedUsers: taggedUsers.map((user) => user.id)
      }
    };

    const promise = new Promise(async (resolve) => {
      if (postType === 'thought') {
        requestBody.post.thoughtAttributes = {
          caption: caption,
          metaMentions: metaMentions
        };
      } else if (postType === 'link') {
        requestBody.post.linkAttributes = {
          caption: caption,
          metaMentions: metaMentions,
          imageUrl: linkAttachment[0].image,
          urlLink: linkAttachment[0].url,
          urlDesc: linkAttachment[0].title,
          urlDomain: linkAttachment[0].providerUrl
        };
      } else {
        const modifiedMedia = cloneDeep(mediaAttachments);
        const modifiedFile = cloneDeep(fileAttachments);
        let currentAttachment = [...modifiedMedia, ...modifiedFile];

        if (postType === 'media') {
          if (currentAttachment[0].type === 'video') {
            requestBody.post.mediaAttributes = {
              caption: caption,
              metaMentions: metaMentions,
              fileExtension: 'jpg',
              secureUrl: uploadedFiles[0].thumbnailSecureUrl,
              width: currentAttachment[0].width,
              height: currentAttachment[0].height,
              videoAttributes: {
                secureUrl: uploadedFiles[0].videoSecureUrl,
                width: currentAttachment[0].width,
                height: currentAttachment[0].height,
                fileExtension: currentAttachment[0].fileExtension
              }
            };
          } else {
            requestBody.post.mediaAttributes = {
              secureUrl: currentAttachment[0].secureUrl,
              width: currentAttachment[0].width,
              height: currentAttachment[0].height,
              fileExtension: currentAttachment[0].fileExtension,
              caption: caption,
              metaMentions: metaMentions
            };
          }
        } else if (postType === 'multiple_media') {
          requestBody.post.multipleMediaAttributes = {
            caption: caption,
            metaMentions: metaMentions,
            resources: currentAttachment.map((attach) => ({
              secureUrl: attach.secureUrl,
              width: attach.width,
              height: attach.height
            }))
          };
        } else if (postType === 'attachment') {
          requestBody.post.attachmentAttributes = {
            fileUrl: [currentAttachment[0].secureUrl],
            caption: caption,
            metaMentions: metaMentions
          };
        } else if (postType === 'multiple_content') {
          let medias = [];
          let files = [];
          currentAttachment.map((attach, index) => {
            if (attach.type === 'attachment') {
              files.push(attach.secureUrl);
            } else if (attach.type === 'video') {
              medias.push({
                secureUrl: uploadedFiles[index].thumbnailSecureUrl,
                width: attach.width,
                height: attach.height,
                videoAttributes: {
                  secureUrl: uploadedFiles[index].videoSecureUrl,
                  width: attach.width,
                  height: attach.height,
                  fileExtension: currentAttachment[0].fileExtension
                }
              });
            } else {
              medias.push({
                secureUrl: attach.secureUrl,
                width: attach.width,
                height: attach.height
              });
            }
          });

          requestBody.post.multipleContentAttributes = {
            caption: caption,
            metaMentions: metaMentions,
            resources: medias,
            fileUrl: files
          };
        }
      }
      resolve(requestBody);
    });

    await Promise.resolve(promise).then(async (requestBody) => {
      const { data: submitData } = await submitPost(requestBody);
      if (submitData) {
        eventOnClick(true);
        const properties = {
          'post as admin': isAdminPost,
          media: postType,
          'with link': postType === 'link',
          'media from lib': true,
          'group name': groupDetail.name,
          'group type': groupDetail.groupType,
          'post from inside group': true,
          'category type': selectedLabels.map((label) => label.title),
          'with someone': taggedUsers.length > 0,
          'mention someone': metaMentions.length > 0
        };

        trackEvent({
          event: 'create post',
          eventProperties: properties,
          env: 'culture'
        });
      }
    });
  };

  const shareEditPost = async () => {
    setIsSubmit(true);
    let requestBody = {
      post: {
        postType: setPostType(existingData.postTypeV2),
        editAttributes: {
          caption: caption
        },
        labelIds: selectedLabels.map((label) => label.id),
        taggedUsers: taggedUsers.map((user) => user.id)
      }
    };

    const { isSuccess } = await editPost(existingData.id, requestBody);

    if (isSuccess) {
      reload({ reloadFeedsId: { id: existingData.id } });
      eventOnClick();
    }
    setIsSubmit(false);
  };

  const getListMentionUser = async (input) => {
    const params = {
      q: input,
      limit: 10
    };
    const { data } = await getListUser(groupDetail.id, params);
    const readablePluginData = data.map((currentData) => {
      const newData = {
        id: currentData.id,
        name: currentData.fullName,
        title: currentData.title,
        avatar: currentData.profilePicture
          ? currentData.profilePicture.secureUrl
          : Config.asset.general.defaultPicture
      };
      return newData;
    });
    setSuggestion(readablePluginData);
  };

  const setErrorModal = (type, errorType, fileName) => {
    if (errorType.length > 0 || type === 'post') {
      const title = getCultureLocale(
        `${type === 'post' ? 'File' : type} couldn't be uploaded`
      );
      const description =
        type === 'post'
          ? getCultureLocale(
              "We're sorry but currently your organization does not allow images, videos, and documents in a single post"
            )
          : getCultureLocale(
              `​​We’re sorry but your ${type.toLowerCase()} exceed our limits`
            );
      const typeItem = type === 'Photos/Videos' ? maxMedia : maxFile;
      const errorMessage = [];
      errorType.includes('size') &&
        errorMessage.push(
          getCultureLocale(
            `Max Size for each ${type} is <span class="typography-h400 mt-[0px]"> ${formatBytes(
              maxFileSize
            )}</span>`
          )
        );
      errorType.includes('max item') &&
        errorMessage.push(
          getCultureLocale(
            `Max Item for ${type} is <span class="typography-h400 mt-[0px]"> ${typeItem} Items</span>`
          )
        );
      errorType.includes('duration') &&
        errorMessage.push(
          getCultureLocale(
            `Max Duration for Video is <span class="typography-h400 mt-[0px]"> ${
              maxDurationVideo / 60
            } Minute</span>`
          )
        );
      const filteredFileName = fileName.filter(
        (a, b) => fileName.indexOf(a) === b
      );

      setErrorData({
        title: title,
        description: description,
        errorMessage: errorMessage,
        fileName: filteredFileName
      });
      setTimeout(() => {
        setIsModalErrorOpen(true);
      }, 1000);
    }
  };

  const isMultipleContent = (totalPhoto, totalVideo) => {
    const mediaLength = mediaAttachments.length;
    const fileLength = fileAttachments.length;
    if (fileLength > 0)
      return mediaLength > 0 || (mediaLength === 0 && fileLength > 1);
    else return totalVideo > 1 || (totalVideo >= 1 && totalPhoto >= 1);
  };

  const setCurrentPostType = () => {
    const totalPhoto = mediaAttachments.filter(
      (attach) => attach.type === 'image'
    ).length;
    const totalVideo = mediaAttachments.filter(
      (attach) => attach.type === 'video'
    ).length;
    let type = '';

    if (linkAttachment.length > 0) {
      type = 'link';
    } else if (isMultipleContent(totalPhoto, totalVideo)) {
      type = 'multiple_content';
    } else if (totalPhoto > 1 && fileAttachments.length === 0) {
      type = 'multiple_media';
    } else if (mediaAttachments.length === 1 && fileAttachments.length === 0) {
      type = 'media';
    } else if (mediaAttachments.length === 0 && fileAttachments.length === 1) {
      type = 'attachment';
    } else {
      type = 'thought';
    }
    return type;
  };

  const validateSelectedMedia = async (medias) => {
    const sortedMedia = [
      ...medias.filter((media) => !media.type.includes('video')),
      ...medias.filter((media) => media.type.includes('video'))
    ];
    let totalToBeAddedMedia = mediaAttachments.length;
    let unSelectedMedia = [];
    let errors = [];
    let filteredPhoto = [];
    let filteredVideo = [];

    const checkMediaBeforeAdded = (type, media) => {
      if (totalToBeAddedMedia < maxMedia) {
        type === 'video'
          ? filteredVideo.push(media)
          : filteredPhoto.push(media);
        totalToBeAddedMedia += 1;
      } else {
        unSelectedMedia.push(media.name);
        errors.push('max item');
      }
    };

    sortedMedia.map((media) => {
      if (media.type.includes('video')) {
        var video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);

          if (video.duration > maxDurationVideo) {
            unSelectedMedia.push(media.name);
            errors.push('duration');
            setErrorModal(
              'Photos/Videos',
              errors,
              sortedMedia.length > 1 ? unSelectedMedia : []
            );
          } else {
            checkMediaBeforeAdded('video', media);
          }
        };
        video.src = URL.createObjectURL(media);
      } else {
        checkMediaBeforeAdded('photo', media);
      }
    });
    setErrorModal(
      'Photos/Videos',
      errors,
      sortedMedia.length > 1 ? unSelectedMedia : []
    );

    setTimeout(() => {
      mappingSelectedMedia(filteredPhoto, filteredVideo);
    }, 500);
  };

  const transcodeMedia = (currentFile) => {
    if (isAbleToTranscode && !compressionProgress) {
      return new Promise(async (resolve) => {
        let { file, width, height } = currentFile;
        let transformedSize = '';
        const { name, type } = file;
        const changedType = type.split('/')[1];
        setTitleCompressionProgress(name);

        let maxAllowedPixelCount = 1920 * 1080;
        const compressedName = `compressed_media.${changedType}`;
        ffmpeg.FS('writeFile', name, await fetchFile(file));

        if (width * height > maxAllowedPixelCount) {
          transformedSize = width > height ? 'scale=1920:-1' : 'scale=1080:-1';
          await ffmpeg.run('-i', name, '-vf', transformedSize, compressedName);
        } else {
          const transformWidth = Math.abs(width % 2) == 1 ? width + 1 : width;
          const transformHeight =
            Math.abs(height % 2) == 1 ? height + 1 : height;
          transformedSize = `${transformWidth}x${transformHeight}`;
          await ffmpeg.run('-i', name, '-s:v', transformedSize, compressedName);
        }
        const data = ffmpeg.FS('readFile', compressedName);
        const transcodedMedia = new File([data.buffer], { type: type });

        ffmpeg.FS('unlink', compressedName);
        setCompressionProgress(null);
        resolve(transcodedMedia);
      });
    } else {
      return;
    }
  };

  const mappingSelectedMedia = (filteredPhoto, filteredVideo) => {
    let currentPromises = [];
    //mapping photo
    filteredPhoto.map((photo) => {
      let filePromise = new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(photo);
        reader.onload = (r) => {
          let currentPhoto = new Image();
          currentPhoto.type = 'image';
          currentPhoto.src = r.target.result;
          currentPhoto.fileExtension = photo.name
            .split('.')
            .pop()
            .toLowerCase();
          currentPhoto.file = photo;

          resolve(currentPhoto);
        };
      });
      currentPromises.push(filePromise);
    });

    //mapping video
    filteredVideo.map((file) => {
      const { filePromise } = getThumbnailVideo(file);
      currentPromises.push(filePromise);
    });

    Promise.all(currentPromises).then((allFiles) => {
      transcodeData(allFiles);
    });
  };

  const transcodeData = async (allFiles) => {
    let listData = [];

    if (isAbleToTranscode) {
      setCompressionProgress(1);
      try {
        if (!ffmpeg.isLoaded()) {
          await ffmpeg.load();
          ffmpeg.setProgress(
            ({ ratio }) =>
              ratio > 0 && setCompressionProgress(Math.round(ratio * 100))
          );
        }
      } catch (err) {
        isAbleToTranscode = false;
      }
    }

    for (let currentFile of allFiles) {
      let selectedPhoto = {};
      let transcodedMedia = {};
      try {
        transcodedMedia = isAbleToTranscode
          ? await transcodeMedia(currentFile)
          : setCompressionProgress(null);
      } catch (err) {
        setCompressionProgress(null);
      }

      selectedPhoto.src = currentFile.src;
      selectedPhoto.fileExtension = currentFile.fileExtension;
      selectedPhoto.width = currentFile.width
        ? currentFile.width
        : currentFile.naturalHeight;
      selectedPhoto.height = currentFile.height
        ? currentFile.height
        : currentFile.naturalHeight;
      selectedPhoto.file =
        isAbleToTranscode && transcodedMedia
          ? transcodedMedia
          : currentFile.file;
      selectedPhoto.fileThumbnail = currentFile.fileThumbnail
        ? currentFile.fileThumbnail
        : '';
      selectedPhoto.videoSrc = currentFile.videoSrc ? currentFile.videoSrc : '';
      selectedPhoto.type = currentFile.type;
      selectedPhoto.progress = 0;

      listData.push(selectedPhoto);
    }
    setMediaToData(listData);
  };

  const setMediaToData = async (lists) => {
    const uploadData = await uploadFile(lists, 'media');

    let uploadLists = [];
    uploadData?.map((file) => {
      let data = {};
      data.thumbnailSecureUrl = file?.thumbnail?.secureUrl;
      data.thumbnailPresignUrl = file?.thumbnail?.presigned_url;
      data.videoSecureUrl = file?.video?.secureUrl;
      data.secureUrl = file?.secureUrl;

      uploadLists.push(data);
    });

    setMediaAttachments([...mediaAttachments, ...lists]);
    const scrollbarAttachment = document.getElementById('scollbar-attachment');
    scrollbarAttachment &&
      (scrollbarAttachment.scrollLeft = scrollbarAttachment?.scrollWidth);
    setUploadedFiles([...uploadedFiles, ...uploadLists]);

    if (document.getElementById('media')) {
      document.getElementById('media').value = '';
    }
  };

  const addPhoto = (e) => {
    let photos = [...e.target.files];
    validateSelectedMedia(photos);
  };

  const deletePhoto = (index) => {
    const unDeletedAttachment = mediaAttachments.filter(
      (attch, idx) => idx !== index
    );
    setMediaAttachments(unDeletedAttachment);

    // cancel upload for media attachments
    sourceMedia[index]?.cancel();
    const newSourceMedia = sourceMedia.filter((src, idx) => idx !== index);
    setSourceMedia(newSourceMedia);

    // delete uploaded file
    const newUploadedData = uploadedFiles.filter(
      (upload, idx) => idx !== index
    );
    setUploadedFiles(newUploadedData);
  };

  const addFile = async (e) => {
    const files = [...e.target.files];
    let totalFiles = fileAttachments.length;
    let toBeAddedFiles = [];
    let unselectedFile = [];
    let errors = [];

    files.map((file) => {
      if (totalFiles < maxFile && file.size <= maxFileSize) {
        const addedFile = {
          src: file,
          fileName: file.name,
          size: file.size,
          fileExtension: file.name.split('.').pop().toLowerCase(),
          type: 'attachment',
          file: file,
          progress: 0
        };
        toBeAddedFiles.push(addedFile);
        totalFiles += 1;
      } else if (totalFiles >= maxFile) {
        unselectedFile.push(file.name);
        errors.push('max item');
      }

      if (file.size > maxFileSize) {
        unselectedFile.push(file.name);
        errors.push('size');
      }
    });

    //upload aws
    await uploadFile(toBeAddedFiles, 'attachment');

    setErrorModal(
      errors.length > 1 ? 'Files' : 'File',
      errors,
      files.length > 1 ? unselectedFile : []
    );
    if (fileAttachments.length < 3) {
      setFileAttachments([...fileAttachments, ...toBeAddedFiles]);
    } else {
      setFileAttachments([...toBeAddedFiles, ...fileAttachments]);
    }
    document.getElementById('attachment').value = '';
  };

  const closeModal = () => {
    eventOnClick();
    try {
      if (ffmpeg.isLoaded()) ffmpeg.exit();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFile = (index) => {
    const unDeletedAttachment = fileAttachments.filter(
      (attch, idx) => idx !== index
    );
    setFileAttachments(unDeletedAttachment);

    // cancel upload for file attachments
    sourceAttachment[index]?.cancel();
    const newSourceAttachment = sourceAttachment.filter(
      (src, idx) => idx !== index
    );
    setSourceAttachment(newSourceAttachment);
  };

  const handleSetSource = (cancelSource, type) => {
    if (type === 'attachment') {
      setSourceAttachment((prevSourceAttachment) => [
        ...prevSourceAttachment,
        cancelSource
      ]);
    } else {
      setSourceMedia((prevSourceMedia) => [...prevSourceMedia, cancelSource]);
    }
  };

  const deleteAttachment = (e) => {
    setLinkAttachment([]);
  };

  const checkSubmitButton = () => {
    if (type === 'create') {
      return (
        isSubmit ||
        fileAttachments.map((file) => file.progress < 100).includes(true) ||
        mediaAttachments.map((media) => media.progress < 100).includes(true) ||
        (caption === '' &&
          isEmpty(mediaAttachments) &&
          isEmpty(fileAttachments) &&
          isEmpty(linkAttachment))
      );
    } else {
      return (
        isSubmit ||
        (caption ===
          existingData[setPostType(existingData.postTypeV2)]?.caption &&
          isEqual(selectedLabels, existingData.labels) &&
          isEqual(taggedUsers, existingData.taggedUsers))
      );
    }
  };

  const syncData = (textEditor, newMetaMentions) => {
    if (!isEqual(textEditor, caption)) setCaption(textEditor);
    if (!isEqual(newMetaMentions, metaMentions))
      setMetaMentions(newMetaMentions);
  };

  useEffect(() => {
    initffmpeg();
  }, []);

  useEffect(() => {
    if (mediaAttachments.length > 0 || fileAttachments.length > 0) {
      setLinkAttachment([]);
    }
  }, [mediaAttachments.length, fileAttachments.length]);

  useEffect(() => {
    if (linkAttachment.length > 0) {
      setMediaAttachments([]);
      setFileAttachments([]);
    }
  }, [linkAttachment.length]);

  useEffect(() => {
    // To set progress in file attachments
    setFileAttachments((prevAttach) =>
      prevAttach.map((attach) =>
        attach.secureUrl === progress.secureUrl
          ? { ...attach, progress: progress.progressPercent }
          : attach
      )
    );

    // To set progress in media attachments
    setMediaAttachments((prevAttach) =>
      prevAttach.map((attach) =>
        attach.secureUrl === progress.secureUrl
          ? { ...attach, progress: progress.progressPercent }
          : attach
      )
    );
  }, [progress]);

  return (
    <>
      {isModalErrorOpen && (
        <Modal
          title={errorData.title}
          withCloseIcon
          eventOnClickClose={() => setIsModalErrorOpen(false)}
          withPrimaryBtn={{
            title: getCultureLocale('OK'),
            dataCy: 'error-message',
            onClick: () => setIsModalErrorOpen(false)
          }}
          className="modal-error-message"
          footerClass={'footer-button'}
        >
          <div>
            <div className="description typography-paragraph">
              {errorData.description}
            </div>
            <ul className="file-list overflow-y">
              {errorData.fileName.map((file, index) => (
                <li key={index} className="typography-h500">
                  {file}
                </li>
              ))}
            </ul>
            {errorData.errorMessage.length > 0 && (
              <div className="message">
                <SVGIcon iconName="icon-warning_red" size={24} />
                <div className="list-message">
                  {errorData.errorMessage.map((message) => (
                    <div
                      key={message}
                      className="typography-paragraph mt-[0px]"
                      dangerouslySetInnerHTML={{
                        __html: parseCaption(message)
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
      <ModalPostTemplate
        dataCy="modal-post-photo"
        modalTitle={`${getCultureLocale(type).toUpperCase()} ${getCultureLocale(
          'POST'
        ).toUpperCase()}`}
        isButton
        buttonText={getCultureLocale('Post')}
        onClickButton={
          type === 'create' ? () => sharePost() : () => shareEditPost()
        }
        isButtonDisabled={checkSubmitButton()}
        eventOnClick={closeModal}
        className="modal-post-photo"
        dataCyButton="share-post"
        isSubmit={isSubmit}
      >
        <div className="flex flex-col">
          <LabelSection
            setSelectedLabels={setSelectedLabels}
            selectedLabels={selectedLabels}
            groupId={groupDetail?.id}
          />
          <MentionEditorWithEmoticon
            getListUser={getListMentionUser}
            placeholder={getCultureLocale("What's happening?")}
            defaultText={
              type == 'edit'
                ? existingData[setPostType(existingData.postTypeV2)]?.caption
                : null
            }
            syncData={syncData}
            wrapperClassName="h-[96px] caption-section"
            alwaysShowEmoji={true}
            iconClassName="absolute right-[16px]"
            onSearchChange={getListMentionUser}
            suggestions={suggestions}
            dataCy="caption-field"
            useNewLineShortcut
          />
          {taggedUsers.length > 0 && (
            <div className="tagged-user-section">
              <span
                className="tagged-user typography-paragraph"
                data-cy="tagged-user"
              >
                {getCultureLocale('With')}
                {taggedUsers.map((user) => {
                  return (
                    <span
                      className="typography-h200"
                      id={user.id}
                      key={user.id}
                    >{` ${user.fullName}`}</span>
                  );
                })}
              </span>
            </div>
          )}
          <AttachmentPostPhoto
            fileAttachments={fileAttachments}
            mediaAttachments={mediaAttachments}
            linkAttachment={linkAttachment}
            deletePhoto={deletePhoto}
            deleteFile={deleteFile}
            deleteAttachment={deleteAttachment}
          />
          {compressionProgress !== null && compressionProgress >= 0 && (
            <div className="flex items-center px-[24px] mb-[16px]">
              <CircleProgressBar
                progress={compressionProgress}
                dataCy="attachment-compression-progress"
                size={32}
                textColor="var(--base-600)"
                circleOneStroke="var(--n-400)"
              />
              <p className="typography-h100 tw-text-n-600 ml-[16px]">
                {getCultureLocale('Compressing ')} {titleCompressionProgress}...
              </p>
            </div>
          )}
          {type === 'create' && groupDetail.allowPostAsAdmin && (
            <Checkbox
              id="post-as-admin-photo"
              customContainerClass="checkbox px-[20px] mb-[12px]"
              value={isAdminPost}
              checked={isAdminPost}
              onChange={(e) => {
                e.stopPropagation();
                setIsAdminPost(!isAdminPost);
              }}
              dataCy="post-as-admin"
            >
              <p className="ml-[4px]">{getCultureLocale('Post as')} Admin</p>
            </Checkbox>
          )}
          <FooterPostPhoto
            mediaAttachments={mediaAttachments}
            fileAttachments={fileAttachments}
            linkAttachment={linkAttachment}
            type={type}
            groupDetail={groupDetail}
            taggedUsers={taggedUsers}
            setTaggedUsers={setTaggedUsers}
            setLinkAttachment={setLinkAttachment}
            addPhoto={addPhoto}
            addFile={addFile}
            disabled={compressionProgress}
          />
        </div>
      </ModalPostTemplate>
      {showModalConfirmation && (
        <ModalConfirmationDialogue
          isAdminPost={isAdminPost}
          isSubmit={isSubmit}
          setShowModalConfirmation={setShowModalConfirmation}
          sharePost={sharePost}
        />
      )}
    </>
  );
}

export default ModalPostPhoto;

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import {
  deactivateUser,
  reactivateUsers,
  resendActivationUser
} from 'client/admin/UsersClient';
import { validateEmail } from 'utils/HelperUtils';

// For Edit Activation
const resendActivation = async (body) => {
  const { isSuccess, error } = await resendActivationUser(body);

  return {
    isSuccess,
    message: isSuccess
      ? 'Resend activation success'
      : `Failed to Resend Activation user, ${error}`
  };
};

const deactivationUser = async (body) => {
  const { isSuccess, error } = await deactivateUser(body);

  return {
    isSuccess,
    message: isSuccess
      ? 'Deactivate user success'
      : `Failed to deactivate user, ${error}`
  };
};

const reactivationUser = async (body) => {
  const { isSuccess, error } = await reactivateUsers(body);

  return {
    isSuccess,
    message: isSuccess
      ? 'Reactivate user success'
      : `Failed to reactivate user, ${error}`
  };
};

// For CSV Helper
const validateCSV = ({ users, config, setErrorMessage }) => {
  let flag = true;
  let editedParsedData = cloneDeep(users);
  editedParsedData.map((key, index) => {
    key['email'] && (key['email'] = key['email'].trim());
    key['manager_email'] &&
      (key['manager_email'] = key['manager_email'].trim());

    if (!key['no'] && !key['email'] && !key['name']) {
      delete editedParsedData[index];
    } else if (key['email'] === '') {
      flag = setErrorMessage('Employee email is required');
    } else if (key['name'] === '') {
      flag = setErrorMessage('Employee name is required');
    } else if (
      config?.usernameMethod === 'email' &&
      !validateEmail(key['email'])
    ) {
      flag = setErrorMessage('Email is invalid format');
    } else if (
      config?.usernameMethod === 'email' &&
      key['manager_email']?.includes('\n')
    ) {
      key['manager_email'] = key['manager_email'].replace(/\n/g, '') + ';';
    } else if (
      !isEmpty(key['manager_email']) &&
      config?.usernameMethod === 'email' &&
      !validateEmail(key['manager_email'])
    ) {
      flag = setErrorMessage('Manager Email is invalid format');
    }
  });

  editedParsedData = editedParsedData.filter((data) => data);
  return { flag, editedParsedData };
};

const promiseParsedData = ({ parsedData, config }) => {
  let promises = parsedData.map((user) => {
    const performanceRole = user.performance_role?.toLowerCase()?.trim();
    const cultureRole = user.culture_role?.toLowerCase()?.trim();

    let query = {
      name: user.name,
      email: config?.usernameMethod === 'email' ? user.email : user.username,
      invite: user.invite?.toLowerCase()?.trim() === 'yes' ? true : false,
      info: {
        jobRole: user.job_role,
        jobFunction: user.job_function,
        salaryLevel: user.salary_level,
        region: user.region,
        division: user.division,
        directorate: user.directorate,
        department: user.department,
        city: user.city,
        country: user.country
      },
      state: user.state
    };
    !isEmpty(user.external_id) && (query.externalId = user.external_id);
    !isEmpty(user.password) && (query.password = user.password);
    !isEmpty(user.performance_role) &&
      (query.role =
        performanceRole == 'none' || performanceRole == 'user'
          ? performanceRole
          : null);
    !isEmpty(user.culture_role) &&
      (query.cultureRole =
        cultureRole == 'none'
          ? 'none'
          : cultureRole == 'user'
          ? 'culture_user'
          : cultureRole == 'admin'
          ? 'culture_admin'
          : null);
    !isEmpty(
      config?.usernameMethod === 'email'
        ? user.manager_email
        : user.manager_username
    ) &&
      (query.managerEmail =
        config?.usernameMethod === 'email'
          ? user.manager_email
          : user.manager_username);
    !isEmpty(user.starts_at) && (query.startsAt = user.starts_at);
    !isEmpty(user.ends_at) && (query.endsAt = user.ends_at);
    !isEmpty(user.job_title) && (query.jobTitle = user.job_title);

    return query;
  });

  return promises;
};

export {
  resendActivation,
  deactivationUser,
  reactivationUser,
  validateCSV,
  promiseParsedData
};

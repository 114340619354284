import React from 'react';

import objectiveComparativeKeys from 'consts/queryKeys/objectiveComparativeKeys';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import { useDeletePreset } from 'hooks/api/useObjectivesComparative';
import useObjectiveComparative from 'hooks/useObjectiveComparative/useObjectiveComparative';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';
import { getUserCreator } from 'pages/objectiveAnalytics/helper/objectiveAnalyticsHelper';

const ModalDeletePreset = ({ onClose }) => {
  const { user } = useUser();
  const { addToast } = useToastContext();
  const { refetchQueries } = useRefetchQuery();

  const preset = useObjectiveComparative((state) => state.preset);
  const setPreset = useObjectiveComparative((state) => state.setPreset);
  const resetAllData = useObjectiveComparative((state) => state.resetAllData);

  const { mutate: deletePreset } = useDeletePreset(preset?.id);

  const deletePresetHandler = () => {
    const payloadBody = { state: 'deleted' };

    deletePreset(payloadBody, {
      onSuccess: () => {
        setPreset({ id: 0, name: 'none' });
        resetAllData(getUserCreator(user));
        refetchQueries(objectiveComparativeKeys.default);
        addToast({
          title: 'Success',
          msg: 'Your preset has been deleted',
          type: 'success'
        });
        onClose();
      }
    });
  };

  return (
    <Modal onClose={onClose} minWidth={480} customClass="w-[480px]">
      <Modal.Header
        title="Delete Preset"
        titleIcon={{
          name: 'icon-warning',
          color: 'var(--y-600)'
        }}
      />
      <Modal.Body>
        <div className="flex flex-col px-[24px]">
          <p className="typography-paragraph text-n-800 my-[4px]">
            {getObjectiveLocale(
              'Are you sure you want to delete this preset? This action cannot be reversed.'
            )}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={{
          text: getObjectiveLocale('Delete'),
          dataCy: 'delete-preset',
          onClick: deletePresetHandler,
          variant: 'danger'
        }}
        secondaryButton={{
          text: getObjectiveLocale('Cancel'),
          dataCy: 'cancel-delete',
          onClick: onClose
        }}
        useBorder={false}
      />
    </Modal>
  );
};

export default ModalDeletePreset;

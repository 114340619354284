import { Axios } from "client/Axios";
import Config from "./Config";
import { getENV } from "utils/HelperUtils";
import * as Sentry from "@sentry/browser";
import { toCamelCase } from "../utils/caseConverter";

var AxiosRequestUtils = function(url, method, data, callback) {
  let token = localStorage.getItem("bearer");
  let authorizationMethod = getENV("AUTHORIZATION_METHOD");

  let axiosParams = {
    url: `${Config.apiHost}${url}`,
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      ...(authorizationMethod === "bearer" && token &&
        { "Authorization": "Bearer " + token}
      )
    }
  };

  if (authorizationMethod === "cookie"){
    Axios.defaults.withCredentials = true;
  }

  if (method.toLowerCase() === "get") {
    axiosParams.params = data;
  } else if (method.toLowerCase() !== "delete") {
    axiosParams.data = data;
  }

  function NetworkError(message) {
    this.name = "NetworkError";
    this.message = message;
    this.stack = new Error().stack;
  }
  NetworkError.prototype = Object.create(Error.prototype);
  NetworkError.prototype.constructor = NetworkError;

  Axios(axiosParams)
    .then(response => {
      callback(true, response.data);
    })
    .catch(e => {
      let error = e?.response?.data?.error
        ? e?.response?.data?.error
        : e?.response?.data?.errors;
      error = toCamelCase(error);

      if (error?.code === "ECONNABORTED") {
        error.message = "Time Out Please Try Again";
      } else if (!error?.message) {
        error = { ...error, message: "Something went wrong"};
      }

      let sentError = (
        process.env.NODE_ENV === "production" &&
        error?.code && error?.code !== 401 &&
        error?.code !== 422 );

      if (sentError && !error?.message.includes("Goal/Task not found")) {
        Sentry.withScope(function (scope) {
          let getUserData = localStorage.getItem("userData");
          if (getUserData) {
            let userData = JSON.parse(getUserData);
            scope.setUser({ email: userData.email });
            scope.setTag("organization", userData.organizationName);
          }
          scope.setTag("pathname", location.pathname);
          scope.setTag("type", `${error?.code ? error?.code : ""} network failed`);
          scope.setTag("endpoint", url);
          scope.setExtras({ extra: { ...error?.response?.data } });
          scope.setFingerprint([
            error?.response?.config?.method,
            error?.code,
            error?.message
          ])
          Sentry.captureException(new NetworkError(error?.message));
        });
      }

      callback(false, error?.message);
    });
};

export default AxiosRequestUtils;

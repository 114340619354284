import React, { useEffect, useState } from 'react';

import { getListObjectives } from 'client/ObjectivesClient';
import {
  getListBehavior,
  submitRecognitionFeedback
} from 'client/PrivateRecognitionClient';
import { ColleagueProvider } from 'context/ColleagueContext';
import { useColleague } from 'context/ColleagueContext';
import { useUser } from 'context/UserContext';
import debounce from 'utils/Debounce';
import {
  capitalize,
  getObjectiveLocale,
  loadMoreValidator,
  parseCaption
} from 'utils/HelperUtils';

import Button from 'components/design-system/Button';
import FormUserComponent from 'components/shared/FormUserComponent';
import ListEmptyState from 'components/shared/ListEmptyState';
import LoadingComponent from 'components/shared/LoadingComponent';
import ProfileSection from 'components/shared/Profile/ProfileSection';
import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';
import Card from 'components/shared/card/Card';
import SelectableCard from 'components/shared/card/SelectableCard';
import Modal from 'components/shared/modal/Modal';

function PrivateRecognition({ closeModal, type }) {
  const { colleague, hasMore, getColleagueData, appendColleagueData } =
    useColleague();

  const { config, isBca, user } = useUser();

  const [onLoadObjective, setOnLoadObjective] = useState(false);
  const [onLoadBehavior, setOnLoadBehavior] = useState(false);
  const [olderThan, setOlderThan] = useState(null);
  const [nextOffsetCompetency, setNextOffsetCompetency] = useState(null);
  const [nextOffsetValue, setNextOffsetValue] = useState(null);
  const [objective, setObjective] = useState([]);
  const [onLoadMore, setLoadMore] = useState(false);
  const [objectiveHasMore, setObjectiveHasMore] = useState(false);
  const [competencyHasMore, setCompetencyHasMore] = useState(false);
  const [valueHasMore, setValueHasMore] = useState(false);
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [search, setSearch] = useState('');
  const [debounceHandler, setDebounceHandler] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [listCompetency, setListCompetency] = useState([]);
  const [selectedCompetency, setSelectedCompetency] = useState(null);
  const [competencyComment, setCompetencyComment] = useState(null);
  const [listValue, setListValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [valueComment, setValueComment] = useState(null);
  const [onSearch, setOnSearch] = useState(false);
  const [step, setStep] = useState(1);
  const [barWidth, setBarWidth] = useState(14.3);
  const [listStepSkipped, setStepSkip] = useState([]);
  const [showAddSkill, setShowAddSkill] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const iconColor = type === 'feedback' ? 'var(--y-600)' : 'var(--g-600)';
  const textColor = type === 'feedback' ? 'text-y-800' : 'text-g-800';
  const backgroundColor = type === 'feedback' ? 'bg-y-100' : 'bg-g-100';
  const borderColor = type === 'feedback' ? 'border-y-300' : 'border-g-300';
  const onScroll = (e) => {
    const target = e.target;

    const loadMore = async () => {
      let query = {};
      if (search != '') {
        query.q = search;
      }

      setLoadMore(true);
      await appendColleagueData(query);
      setLoadMore(false);
    };

    if (!onLoadMore && hasMore) {
      loadMoreValidator(target, 500, () => {
        loadMore();
      });
    }
  };

  const onScrollDiv = (e, scrollTarget) => {
    const target = e.target;
    if (objectiveHasMore && !onLoadObjective && scrollTarget == 'goal') {
      loadMoreValidator(target, 500, () => {
        getObjective('concat', search);
      });
    } else if (
      scrollTarget == 'competency' &&
      nextOffsetCompetency &&
      competencyHasMore
    ) {
      loadMoreValidator(target, 50, () => {
        getCompetency(search, 'concat');
      });
    } else if (scrollTarget == 'value' && nextOffsetValue && valueHasMore) {
      loadMoreValidator(target, 50, () => {
        getValue(search, 'concat');
      });
    }
  };

  const selectCompetencyAndValue = (data, type) => {
    if (type == 'competency') {
      setSelectedCompetency(data);
    } else {
      setSelectedValue(data);
    }
    setStep(step + 1);
    setBarWidth(barWidth + 14.3);
  };

  const selectObjective = (data) => {
    setSelectedObjective(data);
    setStep(step + 1);
    setBarWidth(barWidth + 14.3);
  };

  const selectUser = (user) => {
    setSelectedUser(user);
    nextStep();
  };

  const nextStep = (stepParam, barParam) => {
    let currentStep = stepParam ? stepParam : step;
    let currentBarWidth = barParam ? barParam : barWidth;
    if (step < 7) {
      currentStep += 1;
      currentBarWidth += 14.3;
      setStep(currentStep);
      setBarWidth(currentBarWidth);
      if (listStepSkipped.includes(currentStep)) {
        nextStep(currentStep, currentBarWidth);
      }
    }
  };

  const skipStep = () => {
    let newArray = [...listStepSkipped];
    if (step == 2) {
      newArray.push(3);
      setStepSkip(newArray);
      setStep(step + 2);
      setBarWidth(barWidth + 28.6);
    } else if (step == 4) {
      newArray.push(5);
      setStepSkip(newArray);
      setStep(step + 2);
      setBarWidth(barWidth + 28.6);
    } else if (step == 6) {
      newArray.push(6);
      setStepSkip(newArray);
      setStep(step + 1);
      setBarWidth(barWidth + 14.3);
    } else if (listStepSkipped.includes(currentStep)) {
      newArray.push(6);
      setStepSkip(newArray);
      setStep(step + 1);
      setBarWidth(barWidth + 14.3);
    }
  };

  const backStep = (stepParam, barParam) => {
    let currentStep = stepParam ? stepParam : step;
    let currentBarWidth = barParam ? barParam : barWidth;
    if (step > 1) {
      currentStep -= 1;
      currentBarWidth -= 14.3;
      setStep(currentStep);
      setBarWidth(currentBarWidth);
      if (listStepSkipped.includes(currentStep)) {
        backStep(currentStep, currentBarWidth);
      }
      if (currentStep == 2) {
        setSelectedCompetency(null);
        setCompetencyComment(null);
      } else if (currentStep == 4) {
        setSelectedValue(null);
        setValueComment(null);
      } else if (currentStep == 6) {
        setSelectedObjective(null);
      }
    }
  };

  const searchData = (value) => {
    setSearch(value);
    setOnLoadObjective(true);
    setOnLoadBehavior(true);
    setOlderThan(null);
    setNextOffsetCompetency(null);
    setNextOffsetValue(null);
    if (step == 1) {
      searchColleague(value);
    } else if (step == 2) {
      searchCompetency(value);
    } else if (step == 4) {
      searchValue(value);
    } else if (step == 6) {
      searchObjectives(value, 'search');
    }
  };

  const searchColleague = async (value) => {
    const callback = async () => {
      setOnSearch(true);
      await getColleagueData({ q: value, exclude: [user?.id] });
      setOnSearch(false);
    };

    const handler = await debounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  const searchCompetency = async (value) => {
    const callback = async () => {
      getCompetency(value);
    };

    const handler = await debounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  const searchValue = async (value) => {
    const callback = async () => {
      getValue(value);
    };

    const handler = await debounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  const searchObjectives = async (value, getStatus) => {
    const callback = async () => {
      setOnLoadObjective(true);
      getObjective('new', value, getStatus);
    };

    const handler = await debounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  const _getColleagueData = async () => {
    setLoadMore(true);
    await getColleagueData();
    setLoadMore(false);
  };

  const getCompetency = async (searchValue, getStatus) => {
    let params = {
      behaviorType: 'competency',
      userId: selectedUser.id
    };

    if (nextOffsetCompetency) {
      params.offset = nextOffsetCompetency;
    }

    if (searchValue) {
      params.q = searchValue;
    }

    const { data, error, pagination } = await getListBehavior(params);
    if (data) {
      let newData = [...listCompetency];
      let dataLength = data.length;
      if (dataLength > 0) {
        if (getStatus == 'concat') {
          setListCompetency(newData.concat(data));
        } else {
          setListCompetency(data);
        }
        setCompetencyHasMore(true);
        setOnLoadBehavior(false);
        setNextOffsetCompetency(pagination.nextOffset);
        setShowAddSkill(false);
      } else if (data.length == 0 && searchValue) {
        let newArray = [];
        newArray.push({ id: null, title: searchValue, description: null });
        setListCompetency(newArray);
        setShowAddSkill(true);
      } else {
        setCompetencyHasMore(false);
        setNextOffsetCompetency(null);
      }
      setOnLoadBehavior(false);
    }
  };

  const getValue = async (searchValue, getStatus) => {
    let params = {
      behaviorType: 'value',
      userId: selectedUser.id
    };

    if (nextOffsetValue) {
      params.offset = nextOffsetValue;
    }

    if (searchValue) {
      params.q = searchValue;
    }

    const { data, error, pagination } = await getListBehavior(params);
    if (data) {
      let newData = [...listValue];
      let dataLength = data.length;
      if (dataLength > 0) {
        if (getStatus == 'concat') {
          setListValue(newData.concat(data));
        } else {
          setListValue(data);
        }
        setValueHasMore(true);
        setOnLoadBehavior(false);
        setNextOffsetValue(pagination.nextOffset);
      } else {
        setValueHasMore(false);
        setNextOffsetValue(null);
        setListValue([]);
      }
      setOnLoadBehavior(false);
    }
  };

  const getObjective = async (type, searchValue, getStatus) => {
    const userId = selectedUser.id;
    const query = {
      assigneeId: userId,
      limit: 7
    };
    if (searchValue) {
      query.q = searchValue;
    }
    if (olderThan) {
      query.olderThan = olderThan;
    }

    const { data, pagination } = await getListObjectives(query);
    if (data) {
      let newObjective = objective;
      const objectiveLength = data.length;
      if (objectiveLength > 0) {
        if (type == 'new') {
          setObjective(data);
        } else if (type == 'concat') {
          setObjective(newObjective.concat(data));
        }
        setObjectiveHasMore(true);
        setOnLoadObjective(false);
        setOlderThan(pagination.next.olderThan);
      } else if (getStatus == 'search') {
        setObjective([]);
        setOnLoadObjective(false);
      } else {
        setObjectiveHasMore(false);
        setOnLoadObjective(false);
        setOlderThan(null);
      }
    }
  };

  const getButtonStatus = () => {
    let btnFunction;
    let btnText;
    let disabled = false;
    if (step == 3 || step == 5) {
      btnFunction = nextStep;
      btnText = getObjectiveLocale('Next');
      if (step == 1 && !selectedUser) {
        disabled = true;
      } else if (step == 3 && !competencyComment) {
        disabled = true;
      } else if (step == 5 && !valueComment) {
        disabled = true;
      }
    } else if (step == 7) {
      btnFunction = submitData;
      btnText = getObjectiveLocale('Submit');
    }
    if (step == 1 || step == 2 || step == 4 || step == 6) {
      return null;
    } else {
      return (
        <Button onClick={() => btnFunction()} disabled={disabled}>
          {btnText}
        </Button>
      );
    }
  };

  const getPlaceHolderText = () => {
    let placeholder = getObjectiveLocale('Search');
    return placeholder;
  };

  const getTitleForm = () => {
    let title = getObjectiveLocale('Select');
    if (step == 1) {
      title = getObjectiveLocale('Select Person');
    } else if (step == 2) {
      title = getObjectiveLocale('Select Competency');
    } else if (step == 3 || step == 5) {
      title = getObjectiveLocale('Give Comment');
    } else if (step == 4) {
      title = getObjectiveLocale('Select Value');
    } else if (step == 6) {
      title = getObjectiveLocale('Choose Related Task/Goal');
    } else if (step == 7) {
      title = getObjectiveLocale('Summary');
    }
    return title;
  };

  const submitData = async () => {
    let body = {
      toId: selectedUser.id,
      type: type,
      behaviors: []
    };

    if (selectedObjective && selectedObjective.id) {
      body.objectiveId = selectedObjective.id;
    }

    if (selectedCompetency && selectedCompetency.id) {
      body.behaviors.push({
        id: selectedCompetency.id,
        comment: competencyComment,
        type: 'competency'
      });
    } else if (selectedCompetency && selectedCompetency.title) {
      body.behaviors.push({
        skill: selectedCompetency.title,
        comment: competencyComment,
        type: 'competency'
      });
    }

    if (selectedValue && selectedValue.id) {
      body.behaviors.push({
        id: selectedValue.id,
        comment: valueComment,
        type: 'value'
      });
    }

    const { data, error } = await submitRecognitionFeedback(body);
    if (data) {
      setShowThankYou(true);
      setTimeout(() => {
        closeModal();
      }, 2000);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      let newSkipArray = [...listStepSkipped];
      if (!config.allowUsingValues) {
        newSkipArray.push(4, 5);
        setStepSkip(newSkipArray);
      }
      if (!config.allowUsingCompetencies) {
        newSkipArray.push(2, 3);
        setStepSkip(newSkipArray);
      }
      if (isBca || !config.objectiveRecognition) {
        newSkipArray.push(6);
        setStepSkip(newSkipArray);
      }
      _getColleagueData();
    }, 700);
  }, []);

  useEffect(() => {
    let newSkipArray = [...listStepSkipped];
    setOlderThan(null);
    setNextOffsetCompetency(null);
    setNextOffsetValue(null);
    setSearch('');
    if (step == 6) {
      setOnLoadObjective(true);
      getObjective('new', search);
    } else if (step == 2) {
      setOnLoadBehavior(true);
      setShowAddSkill(false);
      getCompetency();
    } else if (step == 4) {
      setOnLoadBehavior(true);
      setShowAddSkill(false);
      getValue();
    } else if (step == 7 && config.objectiveRecognition && !isBca) {
      newSkipArray = newSkipArray.filter((val) => val != 6);
      setStepSkip(newSkipArray);
    }
  }, [step]);

  return (
    <div className="private-recognition">
      {!showThankYou ? (
        <Modal
          className="private-recognition"
          title={capitalize(type)}
          headerIcon={{
            name: `icon-${
              type === 'feedback' ? 'announcement_outline' : 'star'
            }`,
            customClass: `${backgroundColor} rounded-[50px] h-[24px] w-[24px] flex items-center justify-center`,
            iconSize: '16',
            color: iconColor
          }}
          eventOnClickClose={() => closeModal()}
          withCloseIcon
          footerClass="h-[0px] py-[0px] px-[0px]"
          maxHeight={640}
          decreaseHeight={72}
        >
          <div className="progress-bar-container">
            <span style={{ width: `${barWidth}%` }}></span>
          </div>
          <div className="private-recognition-modal-body">
            {selectedUser && step !== 1 && (
              <div
                className={`flex justify-between items-center border border-solid rounded-[4px] mt-[16px] mb-[24px] ${backgroundColor} ${borderColor} px-[16px] py-[8px]`}
              >
                <p className={`typography-h400 text ${textColor}`}>
                  {getObjectiveLocale(`${capitalize(type)} for`)}
                </p>
                <ProfileSection
                  name={selectedUser.name}
                  profilePicture={selectedUser.profilePicture}
                  desc={selectedUser?.title}
                  totalOwners={1}
                />
              </div>
            )}
            {step !== 7 && (
              <p className="typography-h300 text-n-800 mt-[16px] mb-[8px]">
                {getTitleForm()}
              </p>
            )}
            {(step == 1 || step == 2 || step == 4 || step == 6) && (
              <div className="search-wrapper my-[16px]">
                <input
                  className="pl-[38px]"
                  type="text"
                  value={search}
                  placeholder={getPlaceHolderText()}
                  onChange={(e) => searchData(e.target.value)}
                />
                <SVGIcon
                  size="24"
                  fillColor="var(--n-600)"
                  iconName="icon-search"
                  customClass="absolute top-[4px] left-[8px]"
                />
              </div>
            )}
            {/* {Step 1 select user} */}
            {step == 1 && (
              <div
                className={`w-full overflow-auto ${
                  selectedUser && step !== 1 ? 'h-[296px]' : 'h-[370px]'
                }`}
                onScroll={(e) => onScroll(e)}
              >
                {!onSearch ? (
                  <>
                    {colleague.length > 0 ? (
                      colleague.map((user, key) => (
                        <div
                          className="wrapper-user flex justify-between h-[57px] flex items-center"
                          key={key}
                        >
                          <FormUserComponent
                            type="search"
                            data={user}
                            key={key}
                            onClick={(e) => selectUser(user)}
                          />
                          {selectedUser && selectedUser.id == user.id && (
                            <SVGIcon
                              size="24"
                              fillColor="var(--base-600)"
                              iconName="icon-check"
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <ListEmptyState
                        emptyIcon="icon-no-user-found"
                        size="large"
                        title={getObjectiveLocale('User Not Found')}
                        subtitle={getObjectiveLocale(
                          'Did you probably type the keyword incorrectly?\n We are sorry that what you searched was unfortunately not found.'
                        )}
                        fullHeight={true}
                        containerClassname="mt-[-12px]"
                      />
                    )}
                  </>
                ) : (
                  <LoadingComponent />
                )}
              </div>
            )}
            {step == 2 && (
              <div
                className="w-full overflow-auto h-[296px]"
                onScroll={(e) => onScrollDiv(e, 'competency')}
              >
                {!onLoadBehavior ? (
                  <>
                    {listCompetency.length > 0 ? (
                      listCompetency.map((data, index) => (
                        <SelectableCard
                          key={index}
                          data={data}
                          selectData={(object) =>
                            selectCompetencyAndValue(object, 'competency')
                          }
                          showAddSkillBtn={showAddSkill}
                          customClass="cursor-pointer min-h-[56px] flex flex-col justify-center"
                        />
                      ))
                    ) : (
                      <p className="text-center empty-state-text">
                        {getObjectiveLocale('Competency Not Found')}
                      </p>
                    )}
                  </>
                ) : (
                  <LoadingComponent />
                )}
              </div>
            )}
            {step == 3 && (
              <div className="w-full overflow-auto h-[356px]">
                <SelectableCard
                  data={selectedCompetency}
                  isSelected={true}
                  customClass="min-h-[56px] flex justify-center flex-col"
                />
                <p className="typography-h100 text-n-800 mt-[16px] mb-[8px]">
                  {getObjectiveLocale('Comment')}
                </p>
                <TextArea
                  textPlaceholder={`${getObjectiveLocale(
                    `[${type}] Tell the reason why you choose this competency`
                  )}`}
                  value={competencyComment}
                  onHandleChange={(e) =>
                    setCompetencyComment(e.currentTarget.value)
                  }
                  maxHeight={108}
                  height={108}
                />
              </div>
            )}
            {step == 4 && (
              <div
                className="w-full overflow-auto h-[296px]"
                onScroll={(e) => onScrollDiv(e, 'value')}
              >
                {!onLoadBehavior ? (
                  <>
                    {listValue.length > 0 ? (
                      listValue.map((data, index) => (
                        <SelectableCard
                          key={index}
                          data={data}
                          customClass="cursor-pointer"
                          selectData={(object) =>
                            selectCompetencyAndValue(object, 'value')
                          }
                        />
                      ))
                    ) : (
                      <p className="text-center empty-state-text">
                        {getObjectiveLocale('Value Not Found')}
                      </p>
                    )}
                  </>
                ) : (
                  <LoadingComponent />
                )}
              </div>
            )}
            {step == 5 && (
              <div className="w-full overflow-auto h-[356px]">
                <SelectableCard data={selectedValue} isSelected={true} />
                <p className="typography-h100 text-n-800 mt-[16px] mb-[8px]">
                  {getObjectiveLocale('Comment')}
                </p>
                <TextArea
                  textPlaceholder={`${getObjectiveLocale(
                    'Tell the reason why you choose this value'
                  )}`}
                  value={valueComment}
                  onHandleChange={(e) => setValueComment(e.currentTarget.value)}
                  maxHeight={108}
                  height={108}
                />
              </div>
            )}
            {step == 6 && (
              <div
                className="w-full overflow-auto h-[296px]"
                onScroll={(e) => onScrollDiv(e, 'goal')}
              >
                {!onLoadObjective ? (
                  <>
                    {objective.length > 0 ? (
                      objective.map((data, index) => (
                        <Card
                          key={index}
                          objective={data}
                          withRightIcon
                          type="goal"
                          customClass="mb-[16px]"
                          onClickCard={() => selectObjective(data)}
                        />
                      ))
                    ) : (
                      <p className="text-center empty-state-text">
                        {getObjectiveLocale('Objective Not Found')}
                      </p>
                    )}
                  </>
                ) : (
                  <LoadingComponent />
                )}
              </div>
            )}
            {step == 7 && (
              <div className="w-full overflow-auto mt-[8px] h-[380px]">
                {selectedCompetency && (
                  <>
                    <p className="p-title mb-[8px]">
                      {getObjectiveLocale('Competency')}
                    </p>
                    <div
                      className={
                        'rounded-[4px] bg-n-200 py-[12px] px-[16px] mb-[24px]'
                      }
                    >
                      <p className="typography-h400">
                        {selectedCompetency.title}
                      </p>
                      <div
                        className="typography-paragraph text-n-800"
                        dangerouslySetInnerHTML={{
                          __html:
                            `"` +
                            (competencyComment &&
                              parseCaption(competencyComment)) +
                            `"`
                        }}
                      />
                    </div>
                  </>
                )}
                {selectedValue && (
                  <>
                    <p className="p-title mb-[8px]">
                      {getObjectiveLocale('Value')}
                    </p>
                    <div
                      className={
                        'rounded-[4px] bg-n-200 py-[12px] px-[16px] mb-[24px]'
                      }
                    >
                      <p className="typography-h400">{selectedValue.title}</p>
                      <div
                        className="typography-paragraph text-n-800"
                        dangerouslySetInnerHTML={{
                          __html:
                            `"` +
                            (valueComment && parseCaption(valueComment)) +
                            `"`
                        }}
                      />
                    </div>
                  </>
                )}
                {selectedObjective && (
                  <>
                    <p className="p-title mb-[8px]">{`${getObjectiveLocale(
                      'Related Objective'
                    )}/Task`}</p>
                    <Card
                      objective={selectedObjective}
                      withRightIcon
                      type="goal"
                      customClass="my-[16px]"
                    />
                  </>
                )}
              </div>
            )}
          </div>
          <div className="private-recognition-modal-footer">
            <Button.Secondary
              disabled={step == 1}
              onClick={() => backStep()}
              customClass="absolute mt-[24px] left-[24px]"
            >
              {getObjectiveLocale('Back')}
            </Button.Secondary>
            <span className="absolute mt-[24px] right-[24px]">
              {getButtonStatus()}
            </span>
          </div>
        </Modal>
      ) : (
        <Modal
          className={'bounceIn'}
          withCloseIcon={false}
          footerClass="h-[0px]"
          title={`${getObjectiveLocale('Thank You')}!`}
        >
          <div className="-mt-[20px] flex flex-center-center flex-col bounceIn">
            <p className="capitalize text-center my-[24px]">
              {getObjectiveLocale('Your [type] has been sent').replace(
                '[type]',
                getObjectiveLocale(type)
              )}
              .
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
}

function WrapperPrivateRecognition(props) {
  return (
    <ColleagueProvider>
      <PrivateRecognition {...props} />
    </ColleagueProvider>
  );
}

export default WrapperPrivateRecognition;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import useValidationMinMax from 'hooks/useValidationMinMax';

import OverallProgressCoaching from 'components/shared/card/OverallProgressCard/OverallProgressCoaching.js';
import 'components/shared/card/OverallProgressCard/_overallViewProgress.scss';

const StatistikOverallViewProgressCoaching = ({
  paramsCoaching,
  countRatingData,
  useProgressCard,
  useRatingCard,
  assignmentId,
  trackType,
  isReviewResult,
  customClass = ''
}) => {
  const { getDataCoaching } = useValidationMinMax();

  const getCount = () => {
    return getDataCoaching({
      paramsCoaching,
      assignmentId,
      trackType,
      isReviewResult
    });
  };

  let { data } = useQuery(['overallProgress'], getCount, {
    suspense: true,
    forceFetchOnMount: true
  });

  return (
    <div
      className={`coaching-overall-view-progress-container flex mt-[10px] ${customClass}`}
    >
      <OverallProgressCoaching
        dataCountObjectives={data}
        dataMinMax={data}
        dataMinMaxWeight={data}
        countRatingData={countRatingData}
        useProgressCard={useProgressCard}
        useRatingCard={useRatingCard}
      />
    </div>
  );
};

export default StatistikOverallViewProgressCoaching;

import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useUser } from 'context/UserContext';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { activeVisibility } from 'utils/VisibilityHelper';

import DropdownVisibility from 'components/dropdown/DropdownVisibility';
import { getObjectiveType } from 'src/utils/ObjectivesHelper';

const Header = ({
  setObjectiveValue,
  objectiveValue,
  singleObjectiveAction
}) => {
  const { params } = useRouteMatch();
  const { config } = useUser();

  const permissions = singleObjectiveAction.permissions;

  const { taskPrivacyEdit, projectPrivacyEdit, goalPrivacyEdit } =
    config.permissions;

  const privacyPermission =
    params?.type === 'edit'
      ? permissions?.includes('edit_privacy')
      : params?.type?.includes('task')
      ? taskPrivacyEdit
      : objectiveValue?.isProject
      ? projectPrivacyEdit
      : goalPrivacyEdit;

  const objectiveType = useMemo(
    () => getObjectiveType(objectiveValue),
    [objectiveValue]
  );

  const handleChangeVisibility = (e) => {
    setObjectiveValue((draft) => {
      draft.isPrivate = e === 'Private';
      draft.isCompanyGoal = e === 'Company Goal';
      draft.isDirectorateGoal = e === 'Directorate Goal';
      draft.isDeparmentGoal = e === 'Department Goal';
      draft.isDivisionGoal = e === 'Division Goal';
    });
  };

  return (
    <div className="info flex flex-row items-center h-[20px]">
      <p className="typography-paragraph text-n-900">
        {getObjectiveLocale(`This ${objectiveType} is`)}
        &nbsp;
      </p>
      <DropdownVisibility
        handleChange={handleChangeVisibility}
        defaultValue={activeVisibility(objectiveValue).text}
        type={objectiveType}
        isDisabled={!privacyPermission}
        isProject={objectiveValue.isProject}
      />
    </div>
  );
};

export default Header;

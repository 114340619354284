import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import startCase from 'lodash/startCase';
import upperCase from 'lodash/upperCase';

import useFilterDialog from 'hooks/useFilterDialog';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Dialog from 'components/shared/Dialog/Dialog';
import SVGIcon from 'components/shared/SVGIcon';

const DialogPortal = (props) => {
  let objectiveElementId = document.getElementById(
    `list-filter-${props.indexFilter + 1}`
  );

  return ReactDOM.createPortal(<Dialog {...props} />, objectiveElementId);
};

const ScorePortal = (props) => {
  let objectiveElementId = document.getElementById(
    `list-filter-${props.indexFilter + 1}`
  );

  return ReactDOM.createPortal(<Dialog {...props} />, objectiveElementId);
};

const MenuPortal = (props) => {
  let objectiveElementId = document.getElementById(
    `list-filter-${props.indexFilter + 1}`
  );

  return ReactDOM.createPortal(<Menu {...props} />, objectiveElementId);
};

const CalendarPortal = (props) => {
  let objectiveElementId = document.getElementById(
    `calendar-${props.indexFilter + 1}`
  );

  return ReactDOM.createPortal(
    <DatePicker
      bordered={false}
      open={true}
      dropdownAlign={{ overflow: { adjustY: false } }}
      value={props.value}
      onChange={props.handleChangeCalendar}
      getPopupContainer={() => objectiveElementId}
      placement="bottomLeft"
      style={{
        visibility: 'hidden',
        position: 'absolute',
        height: 0,
        width: 0,
        padding: 0,
        border: 0
      }}
      showToday={false}
    />,
    objectiveElementId
  );
};

function Menu({
  customClass,
  options,
  filter,
  dispatchFilter,
  isSubMenu,
  indexFilter = '',
  parentMenu,
  isComplexMenu,
  filterClient,
  suspense,
  dialogQueryFn
}) {
  const refFilter = useRef();
  const refCalendar = useRef();
  const separateIndex = options.findIndex(
    ({ subOption, dialog, calendar }, index, array) =>
      index > 0 &&
      !subOption &&
      !dialog &&
      !calendar &&
      (array[index - 1]?.subOption ||
        array[index - 1]?.dialog ||
        array[index - 1]?.calendar) &&
      isSubMenu
  );
  const [activeMenu, setActiveMenu] = useState();
  let currentMenu = activeMenu?.option;
  let dialog = useFilterDialog(
    activeMenu?.dialog,
    filterClient,
    suspense,
    filter,
    activeMenu?.additionalQueryFn
  );
  let subMenu = activeMenu?.subOption;
  let isComplexSubMenu = subMenu?.some(
    ({ subOption, calendar, dialog }) => subOption || calendar || dialog
  );
  let calendar = activeMenu?.calendar;
  let score = activeMenu?.dialog === 'score';
  let checkQueryFn = () => {};
  filterClient && (checkQueryFn.queryFn = dialogQueryFn);

  const index = options.findIndex(({ option }) => option === currentMenu);
  const handleChangeDialog = (value) => {
    let newFilter = cloneDeep(filter);
    let newState = set(newFilter, currentMenu, value);

    dispatchFilter({ type: upperCase(currentMenu), state: { ...newState } });
  };
  const handleChangeSubMenu = (value) => {
    let newFilter = cloneDeep(filter);
    let newState;
    if (isComplexMenu) {
      let pathMenu = parentMenu + '.' + parentMenu + 'Type';
      let currentValue = get(newFilter, pathMenu);
      let newValue = value;
      if (currentValue === newValue) newValue = '';
      newState = set(newFilter, pathMenu, newValue);
    } else {
      if (newFilter[parentMenu] === currentMenu) {
        value = '';
      }
      newState = { [parentMenu]: value };
    }
    dispatchFilter({ type: upperCase(currentMenu), state: { ...newState } });
  };

  const handleChangeDate = (date) => {
    let newFilter = cloneDeep(filter);
    let newState;
    let pathMenu = parentMenu + '.' + parentMenu + 'Type';

    newState = set(
      newFilter,
      pathMenu,
      currentMenu + '.' + date.endOf('day').toISOString()
    );
    dispatchFilter({ type: upperCase(currentMenu), state: { ...newState } });
  };

  const getDate = () => {
    let pathMenu = parentMenu + '.' + parentMenu + 'Type';
    let value = get(filter, pathMenu);

    const newValue = value?.replace(currentMenu + '.', '');

    return value !== newValue ? dayjs(newValue) : null;
  };

  return (
    <>
      <div
        className={`list-filter-component ${customClass ? customClass : ''} 
        ${!isSubMenu ? 'w-[200px]' : 'w-[260px]'} py-[8px]`}
        ref={refFilter}
        style={{ left: !isSubMenu ? 'auto' : '97%' }}
      >
        <section id="filter-by">
          {!isSubMenu && (
            <p className="typography-h100 text-n-600 mt-[0px] py-[8px] px-[16px]">
              {getObjectiveLocale('Filter by')}
            </p>
          )}
          {isSubMenu && parentMenu && (
            <p className="typography-h100 text-n-600 mt-[0px] py-[8px] px-[16px]">
              {startCase(parentMenu)}
            </p>
          )}

          {options.map((listFilterItem, index) => {
            let dialog = listFilterItem.dialog;
            let filterOption = listFilterItem.option;
            let filterValue = get(filter, filterOption);
            let subMenu = listFilterItem.subOption;
            let filterCount = filterValue?.length;
            let calendar = listFilterItem.calendar;

            if (subMenu) {
              let count = 0;
              subMenu.map((value) => {
                let suboption = get(filter, value.option);
                if (typeof suboption === 'object') {
                  count += suboption?.length;
                }
                if (filterValue === value.option) {
                  count += 1;
                }
                if (
                  get(filter, filterOption + '.' + filterOption + 'Type') ===
                  value.option
                ) {
                  count += 1;
                }
              });
              filterCount = count;
            }

            return (
              <div key={index} className="relative">
                {calendar && (
                  <div className="absolute w-full translate-x-full">
                    <div
                      id={`calendar-${indexFilter + index + 1}`}
                      className="relative"
                      ref={refCalendar}
                    />
                  </div>
                )}

                {index === separateIndex && (
                  <div className="border-top-400 my-[8px]"></div>
                )}
                <div
                  key={index}
                  className={`row-filter flex items-center justify-between whitespace-nowrap capitalize h-[32px]
                  ${
                    currentMenu === filterOption ||
                    filter[parentMenu] === filterOption ||
                    get(filter, parentMenu + '.' + parentMenu + 'Type') ===
                      filterOption
                      ? 'bg-base-30024'
                      : ''
                  }
                `}
                  id={`list-filter-${indexFilter + index + 1}`}
                  data-cy={
                    (isSubMenu ? 'sub-menu-' : 'list-filter-') + (index + 1)
                  }
                  {...(!subMenu &&
                    !dialog &&
                    !calendar && {
                      onClick: () => handleChangeSubMenu(listFilterItem.option)
                    })}
                  onMouseOver={(e) => {
                    e.stopPropagation();
                    setActiveMenu(listFilterItem);
                  }}
                >
                  <p className="truncate">
                    {getObjectiveLocale(listFilterItem.title)}
                  </p>

                  {(dialog || subMenu || calendar) && (
                    <div className="wrapper-count-filter ml-[8px] flex items-center">
                      {filterCount > 0 && (
                        <div className="count-selected-filter">
                          {filterCount}
                        </div>
                      )}
                      <div>
                        <SVGIcon
                          iconName="icon-arrow_right"
                          size="24"
                          fillColor="var(--n-600)"
                          customClass="arrow-dropright"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {subMenu && (
            <MenuPortal
              options={subMenu}
              filter={filter}
              dispatchFilter={dispatchFilter}
              isSubMenu={true}
              key={index}
              indexFilter={indexFilter + index}
              parentMenu={currentMenu}
              isComplexMenu={isComplexSubMenu}
            />
          )}
          {dialog && !score && (
            <DialogPortal
              {...dialog?.dialog}
              {...checkQueryFn}
              handleChange={handleChangeDialog}
              selectedValue={get(filter, currentMenu)}
              dialogIndex={index}
              key={index}
              dataCy="dialog-filter"
              containerClassName={`ml-[0px] left-[100%] top-[-8px] ${
                dialog?.dialog?.containerClassName
                  ? dialog?.dialog?.containerClassName
                  : ''
              }`}
              indexFilter={indexFilter + index}
              id={dialog?.id}
            />
          )}
          {calendar && (
            <CalendarPortal
              handleChangeCalendar={handleChangeDate}
              value={getDate()}
              type="startDate"
              indexFilter={indexFilter + index}
              refCalendar={refCalendar}
            />
          )}
          {score && (
            <ScorePortal
              {...dialog?.dialog}
              handleChange={handleChangeDialog}
              selectedValue={get(filter, currentMenu)}
              dialogIndex={index}
              key={index}
              dataCy="dialog-filter"
              containerClassName={'ml-[0px] left-[100%] top-[-8px]'}
              indexFilter={indexFilter + index}
              showSelected={false}
            />
          )}
        </section>
      </div>
    </>
  );
}

export default Menu;

import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import startCase from 'lodash/startCase';

import {
  restoreObjective,
  updateStateObjective
} from 'client/ObjectivesClient';
import { useRefetchQuery } from 'context/RefetchQueryContext';
import { useReload } from 'context/ReloadContext';
import { useToastContext } from 'context/ToastContext';
import { useUser } from 'context/UserContext';
import useModalObjectives from 'hooks/useModalObjectives';
import useTeam from 'hooks/useTeam';
import { useUrl } from 'hooks/useUrl';
import useValidationMinMax from 'hooks/useValidationMinMax';
import { getObjectiveLocale } from 'utils/HelperUtils';
import {
  getObjectiveSummaryPdf,
  getObjectiveSummaryPdfToast,
  getObjectiveType
} from 'utils/ObjectivesHelper';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import 'components/design-system/dropdown/Dropdown.scss';
import ConfirmDeleteModal from 'components/modal/ConfirmDeleteModal';
import SVGIcon from 'components/shared/SVGIcon';
import MinMaxValidationModal from 'components/shared/modal/MinMaxValidationModal';

const HeaderDropdown = ({
  objectiveDetail,
  completeGoal,
  setShowReviewModal,
  isEditable
}) => {
  const showModal = useModalObjectives((state) => state.showModal);
  const closeModal = useModalObjectives((state) => state.closeModal);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);

  const {
    id: objectiveId,
    permissions = [],
    isProject,
    stateObject = [],
    involvements = [],
    type
  } = objectiveDetail || {};

  const objectiveType = useMemo(
    () => getObjectiveType(objectiveDetail),
    [objectiveDetail]
  );

  let history = useHistory();
  const params = useParams();
  const { url } = useUrl();
  const { user, config } = useUser();
  const {
    refetchObjectives,
    refetchQueries,
    refetchObjective,
    invalidateTaskQueries
  } = useRefetchQuery();
  const {
    showModalValidationDeleteGoal,
    checkValidationDeleteGoal,
    _handleActionCloseModal,
    totalObjectives: totalObjectivesQty
  } = useValidationMinMax();
  const { reload, reloadObjectives, reloadOverallProgress } = useReload();
  const { approvalParentGoal, setIsAddingApprovalSubGoal } = useTeam();
  const { addToast } = useToastContext();

  const childrenCount = objectiveDetail?.childrenCount;
  const seeGoalTree =
    objectiveType == 'task' ? config.permissions?.taskAlignmentEdit : true;
  const isInApprovalStates = ['draft', 'edited', 'to_be_deleted'].includes(
    stateObject.state
  );

  const isInAssignment = url.includes('/assignments');
  const isGoalsPage =
    url?.includes('goals') &&
    !url?.includes('level') &&
    !url?.includes('reports') &&
    !url?.includes('others');

  const handleClick = async (type) => {
    if (type === 'delete' && objectiveType !== 'task') {
      const { isSettingMinObjectivesOn } = await checkValidationDeleteGoal();
      if (!isSettingMinObjectivesOn) {
        setModalConfirmDeleteIsOpen(true);
      }
    } else if (type === 'delete' && objectiveType === 'task') {
      setModalConfirmDeleteIsOpen(true);
    } else if (type === 'clonegoal') {
      showModal({
        modalType: 'cloneGoal',
        props: { objectiveDetail: objectiveDetail }
      });
    } else if (type === 'restore') {
      showModal({
        modalType: 'restore',
        submitModal: () => restoreObjectives(user.id),
        props: { typeObjective: objectiveDetail.type }
      });
    } else if (type === 'exportSummary') {
      let owner = user;
      let placementId = null;
      if (url?.includes('users') && url?.includes('placement')) {
        if (params?.userId !== user?.id) {
          owner = involvements?.find(
            (involvement) => involvement?.user?.id == params?.userId
          )?.user;
        }
        placementId = params?.placementId;
      }
      addToast(getObjectiveSummaryPdfToast(owner, user));
      getObjectiveSummaryPdf({
        ownerId: owner?.id,
        recipientId: user?.id,
        placementId,
        parentObjective: objectiveDetail
      });
    } else {
      if (url.includes('teams') && objectiveId === approvalParentGoal?.id) {
        setIsAddingApprovalSubGoal(true);
      }
      const loc = history.location.pathname;
      history.replace({
        pathname:
          loc.includes('users') && !loc.includes('objectives')
            ? `${url}/objectives/${type}/${objectiveId}`
            : `${url}/${type}/${objectiveId}`,
        search: location.search
      });
    }
  };

  const visitGoalTree = () => {
    window.open(`/tree/${objectiveId}${location.search}`);
  };

  const visitProgressOverTime = () => {
    const isUsingCompact = document.querySelector('.compact-border');
    if (isUsingCompact) {
      return window.open(`/progress-overtime/${objectiveId}`, '_blank');
    }
    history.replace({
      pathname: `/progress-overtime/${objectiveId}`,
      state: { prevPath: location.pathname }
    });
  };
  async function restoreObjectives(userId) {
    await restoreObjective(objectiveDetail.id);
    await refetchObjectives();
    await refetchQueries(['approvalAvailability', userId]);
    await refetchObjective(objectiveId);

    history.replace({
      pathname: url,
      search: location.search
    });
    closeModal();
  }

  async function deleteObjective(deleteChildren) {
    let body = { state: 'deleted', top_parent: null, deleteChildren };
    const { data } = await updateStateObjective(objectiveId, body);
    if (url.includes('tree')) {
      history.replace({
        pathname: '/goals',
        search: location.search
      });
    } else {
      history.replace({
        pathname: url,
        search: location.search
      });

      // refresh all goals
      refetchObjectives();
      refetchQueries(['approvalAvailability', user?.id]);
      refetchObjective(objectiveId);

      if (type === 'task') {
        invalidateTaskQueries({ task: objectiveDetail });
      }

      reload({
        ...(!isInApprovalStates && { reloadObjectives: !reloadObjectives }),
        reloadApprovalPanel: ['draft', 'edited', 'to_be_deleted'],
        reloadOverallProgress: !reloadOverallProgress
      });
    }
  }
  let dropdownOptions = [
    permissions &&
      permissions?.includes('review') &&
      permissions?.includes('uncomplete') && {
        text: getObjectiveLocale('Incomplete'),
        iconName: 'icon-done',
        onClick: () => {
          completeGoal({ state: 'running' });
        }
      },
    !url.includes('activities') &&
      permissions?.includes('update') &&
      isEditable && {
        text: getObjectiveLocale(`Edit ${startCase(objectiveType)}`),
        iconName: 'icon-pencil',
        onClick: () => handleClick('edit'),
        dataCy: 'goal-edit-goal'
      },
    !url.includes('activities') &&
      !isInAssignment &&
      objectiveType != 'task' &&
      permissions?.includes('add_subgoal') && {
        text: getObjectiveLocale('Add Sub Goal'),
        iconName: 'icon-add',
        onClick: () => handleClick('addgoal'),
        dataCy: 'goal-add-sub-goal'
      },
    !url.includes('activities') &&
      !isInAssignment &&
      objectiveType != 'task' &&
      permissions?.includes('add_subtask') && {
        text: getObjectiveLocale('Add Task'),
        iconName: 'icon-add',
        onClick: () => handleClick('addtask'),
        dataCy: 'goal-add-sub-task'
      },
    !url.includes('activities') &&
      !isInAssignment &&
      objectiveType != 'task' &&
      permissions?.includes('add_subproject') && {
        text: getObjectiveLocale('Add Project'),
        iconName: 'icon-add',
        onClick: () => handleClick('addproject'),
        dataCy: 'goal-add-sub-project'
      },
    (isGoalsPage || (url?.includes('users') && url?.includes('placement'))) &&
      !isProject &&
      objectiveType != 'task' &&
      config?.exportObjectivesSummaryFeature &&
      permissions?.includes('export_summary') && {
        text: getObjectiveLocale('Export Summary'),
        iconName: 'icon-file_download',
        onClick: () => handleClick('exportSummary'),
        dataCy: 'goal-export-summary'
      },
    !url.includes('tree') &&
      seeGoalTree && {
        text: getObjectiveLocale('View on Tree'),
        iconName: 'icon-device_hub',
        onClick: visitGoalTree,
        dataCy: 'dropdown-tree'
      },
    !url.includes('progress-overtime') &&
      config?.enableProgressOverTime &&
      !objectiveDetail?.organization?.id && {
        text: getObjectiveLocale('Progress Over Time'),
        iconName: 'icon-show_chart',
        onClick: visitProgressOverTime,
        dataCy: 'dropdown-progress-overtime'
      },
    url.includes('goals') &&
      isProject && {
        text: getObjectiveLocale('Go to Project Page'),
        iconName: 'icon-dvr',
        onClick: () => history.replace(`/projects/${objectiveId}`),
        dataCy: 'dropdown-project'
      },
    !url?.includes('activities') &&
      !url.includes('progress-overtime') &&
      config?.objectiveClone &&
      !objectiveDetail?.organization?.id && {
        text: getObjectiveLocale(`Clone ${startCase(objectiveType)}`),
        iconName: 'icon-content_copy',
        onClick: () => handleClick('clonegoal'),
        dataCy: 'goal-clone-goal'
      },
    !url?.includes('activities') &&
      !isInAssignment &&
      permissions?.includes('delete') && {
        text: getObjectiveLocale(`Delete ${startCase(objectiveType)}`),
        textColor: 'text-r-500',
        iconName: 'icon-delete',
        iconColor: 'var(--r-500)',
        onClick: () => {
          handleClick('delete');
        },
        dataCy: 'goal-delete-goal'
      },
    !isInAssignment &&
      permissions?.includes('restore') && {
        text: getObjectiveLocale(`Restore ${startCase(objectiveType)}`),
        iconName: 'icon-restore_from_trash',
        onClick: () => handleClick('restore'),
        dataCy: 'goal-restore-goal'
      },
    url.includes('tree') && {
      text: getObjectiveLocale('Change Pivot'),
      iconName: 'icon-swap_vert',
      onClick: () => {
        const locationSearch = objectiveDetail?.organization?.id
          ? `?organizationId=${objectiveDetail?.organization?.id}`
          : '';
        window.location.assign(`/tree/${objectiveId}${locationSearch}`);
      }
    },
    permissions?.includes('edit_review') && {
      text: getObjectiveLocale('Edit Review'),
      iconName: 'icon-review',
      onClick: () => {
        setShowReviewModal(true);
      },
      dataCy: 'goal-edit-review'
    }
  ].filter((val) => val);

  return (
    <>
      <Dropdown>
        <Dropdown.Trigger>
          <SVGIcon
            fillColor="var(--n-600)"
            iconName="icon-keyboard_control"
            size="24"
            dataCy="sidebar-detail-kebab"
            customClass="ml-[16px] cursor-pointer"
          />
        </Dropdown.Trigger>
        <div className="animation-dropdown-rotate">
          <Dropdown.MenuItems position="right" options={dropdownOptions} />
        </div>
      </Dropdown>
      {modalConfirmDeleteIsOpen && (
        <ConfirmDeleteModal
          modalType={'confirmDelete'}
          closeModal={() =>
            setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
          }
          submitModal={(deleteChildren) => deleteObjective(deleteChildren)}
          totalSubgoal={childrenCount}
          dataCy={'goal-confirm-delete-goal'}
        />
      )}
      {showModalValidationDeleteGoal && (
        <MinMaxValidationModal
          type={'ValidationDeleteGoal'}
          _handleActionModal={() =>
            _handleActionCloseModal({
              typeModal: 'showModalValidationDeleteGoal'
            })
          }
          _handleNext={(deleteChildren) => deleteObjective(deleteChildren)}
          datacy="goal-confirm-delete-goal"
          totalObjectives={totalObjectivesQty}
          totalSubgoal={childrenCount}
          objectiveDetail={objectiveDetail}
        />
      )}
    </>
  );
};

export default HeaderDropdown;

import React, { useEffect } from 'react';

import { useUser } from 'context/UserContext';
import { useUrl } from 'hooks/useUrl';
import { getObjectiveLocale } from 'utils/HelperUtils';
import { getObjectiveInvolvements } from 'utils/ObjectivesHelper';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import SingleUser from 'components/design-system/single-user/SingleUser';
import InlineDialogSelectMultipleUser from 'components/inline-dialog/InlineDialogSelectMultipleUser';
import InlineDialogSelectUser from 'components/inline-dialog/InlineDialogSelectUser';
import SVGIcon from 'components/shared/SVGIcon';

import { getNewInvolvements, getUserId } from './SidebarCreateHelper';

const ProjectInvolvements = ({
  objectiveValue,
  setObjectiveValue,
  useFloating = false,
  permissions,
  isEdit
}) => {
  const { user } = useUser();
  const { url } = useUrl();

  const { involvements } = objectiveValue || {};

  const selectedLeader = getObjectiveInvolvements(involvements, 'leader');
  const selectedReviewer = getObjectiveInvolvements(involvements, 'reviewer');
  const selectedMember = getObjectiveInvolvements(involvements, 'member');
  const selectedStakeholder = getObjectiveInvolvements(
    involvements,
    'stakeholder'
  );
  const selectedPmo = getObjectiveInvolvements(involvements, 'pmo');

  const handleChangeDialog = (involvements, type, extendedRoleType) => {
    let newInvolvements = getNewInvolvements(
      involvements,
      type,
      false,
      objectiveValue,
      null,
      extendedRoleType,
      true
    );

    setObjectiveValue((draft) => {
      draft.involvements = newInvolvements;
    });
  };

  const setDefaultLeader = () => {
    if (url.includes('create-objective') && selectedLeader.length === 0) {
      let newLeader = {
        user: user,
        role: 'assignee',
        extendedRole: 'leader',
        userId: user?.id,
        visible: true
      };

      if (
        involvements.some((involvement) => involvement?.user?.id === user?.id)
      ) {
        let index = involvements.findIndex(
          (involvement) => involvement?.user?.id === user?.id
        );
        involvements[index].role = 'assignee';
        involvements[index].extendedRole = 'leader';
      } else {
        involvements.push(newLeader);
      }
    }
  };

  useEffect(() => {
    setDefaultLeader();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex mt-[16px]">
        <div className="w-1/2 mr-[16px]">
          <p className="typography-h100 text-n-800">
            {getObjectiveLocale('Leader')}
          </p>
          <div className="ml-[-8px]">
            <InlineDialogSelectUser
              objectiveValue={objectiveValue}
              selectedValue={selectedLeader}
              handleChange={handleChangeDialog}
              permission={
                isEdit ? permissions?.includes('assign_leader') : true
              }
              type="assignee"
              extendedRoleType="leader"
              position="left"
              header="Leader"
              useRemoveIcon={false}
              dataCy="leader-dialog"
              useFloating={useFloating}
            />
          </div>
        </div>
        <div className="w-1/2">
          <p className="typography-h100 text-n-800">
            {getObjectiveLocale('Reviewer')}
          </p>
          <div className="ml-[-8px]">
            <InlineDialogSelectUser
              objectiveValue={objectiveValue}
              selectedValue={selectedReviewer}
              handleChange={handleChangeDialog}
              permission={
                isEdit ? permissions?.includes('assign_reviewer') : true
              }
              type="assigner"
              position="right"
              header="Reviewer"
              useRemoveIcon={false}
              dataCy="reviewer-dialog"
              useFloating={useFloating}
            />
          </div>
        </div>
      </div>

      <div className="flex mt-[8px]">
        <div className="w-1/2 mr-[16px]">
          <p className="typography-h100 text-n-800">
            {getObjectiveLocale('Members')}
          </p>
          <div className="ml-[-8px]">
            <InlineDialogSelectMultipleUser
              customTriggerComponent={() => (
                <CustomTrigger users={selectedMember} />
              )}
              header="Members"
              role="assignee"
              position="bottom"
              objectiveValue={objectiveValue}
              selectedValue={selectedMember}
              handleChange={handleChangeDialog}
              useFloating={useFloating}
              permission={isEdit ? permissions?.includes('assign_owner') : true}
            />
          </div>
        </div>
        <div className="w-1/2">
          <p className="typography-h100 text-n-800">
            {getObjectiveLocale('Stakeholders')}
          </p>
          <div className="ml-[-8px]">
            <InlineDialogSelectMultipleUser
              customTriggerComponent={() => (
                <CustomTrigger users={selectedStakeholder} />
              )}
              header="Stakeholders"
              role="follower"
              type="follower"
              position="right"
              objectiveValue={objectiveValue}
              handleChange={handleChangeDialog}
              selectedValue={selectedStakeholder}
              useFloating={useFloating}
              permission={
                isEdit ? permissions?.includes('assign_follower') : true
              }
            />
          </div>
        </div>
      </div>

      <div className="flex mt-[8px] mb-[16px]">
        <div className="w-1/2 mr-[16px]">
          <p className="typography-h100 text-n-800">
            {getObjectiveLocale('PMO')}
          </p>
          <div className="ml-[-8px]">
            <InlineDialogSelectMultipleUser
              customTriggerComponent={() => (
                <CustomTrigger users={selectedPmo} />
              )}
              header="PMO"
              role="follower"
              extendedRoleType="pmo"
              position="bottom"
              objectiveValue={objectiveValue}
              selectedValue={selectedPmo}
              handleChange={handleChangeDialog}
              useFloating={useFloating}
              permission={isEdit ? permissions?.includes('assign_pmo') : true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const CustomTrigger = ({ users }) => {
  users = users.map(({ user }) => user) || [];

  return users.length > 0 ? (
    <div className="px-[8px] py-[8px] rounded-full hover:bg-base-30024 w-full">
      {users.length === 1 ? (
        <SingleUser userData={users[0]} />
      ) : (
        users.length > 1 && <AvatarGroup avatars={users} size="24" />
      )}
    </div>
  ) : (
    <div className="text-n-600 px-[8px] py-[8px] rounded-full hover:bg-base-30024 w-full flex items-center">
      <SVGIcon
        size="24"
        fillColor="var(--n-600)"
        iconName="icon-default-user"
        customClass="mr-[8px]"
      />
      {getObjectiveLocale('Select User')}
    </div>
  );
};

export default ProjectInvolvements;

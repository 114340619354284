import React, { useState } from 'react';

import dayjs from 'dayjs';

import { getPostById, submitSurvey } from 'client/CultureClient';
import { getCultureLocale } from 'utils/HelperUtils';

import AWSImage from 'components/design-system/AWSImage';
import Button from 'components/design-system/Button';
import SVGIcon from 'components/shared/SVGIcon';

import PostInfo from './PostInfo';

function PostSurvey({ content, postId }) {
  const [surveyData, setSurveyData] = useState(content);
  const [selectedOption, setSelectedOption] = useState(0);
  const isSurveyExpired =
    new Date(surveyData.poll.expiration) < new Date() ? true : false;

  const SetDueDate = () => {
    const diffTime = Math.abs(
      new Date(dayjs().toISOString()) - new Date(surveyData.poll.expiration)
    );
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const _submitSurvey = async (pollId, optionId) => {
    const { isError } = await submitSurvey(pollId, optionId);

    if (!isError) {
      const { data } = await getPostById(postId);
      if (data) {
        setSurveyData(data);
      }
    }
  };

  return (
    <>
      <div className="feed-survey" data-cy={`post-survey-${postId}`}>
        <div className="question-survey" data-cy="question">
          {surveyData.poll.question}
        </div>
        {isSurveyExpired ? (
          <div className="survey-ended" data-cy="survey-ended">
            {getCultureLocale('Polling Ended')}
          </div>
        ) : (
          <div className="due-date" data-cy="due-date">
            {getCultureLocale('Due date').replace('%d', () => SetDueDate())}
          </div>
        )}
        {isSurveyExpired || surveyData.poll.voted ? (
          surveyData.poll.pollOptions.map((poll) => {
            return (
              <div
                className="survey-result"
                data-cy={`survey-result-${poll.id}`}
                key={poll.id}
              >
                <p className="typography-h400 mt-[16px]" data-cy="result-title">
                  {poll.option}
                </p>
                <div className="survey-result-option flex">
                  <span className="max-bar" />
                  <span
                    className={`survey-bar ${
                      poll.voted ? 'selected-option' : ''
                    }`}
                    style={{
                      width:
                        (poll.count === 0 && surveyData.poll.voterCount === 0
                          ? 0
                          : Math.round(
                              (poll.count / surveyData.poll.voterCount) * 100
                            )) + '%'
                    }}
                  />
                  {poll.voted && (
                    <SVGIcon
                      iconName="icon-thin-check"
                      fillColor="var(--base-600)"
                      size="24"
                      customClass="z-index-10 items-center ml-[8px]"
                    />
                  )}
                  <span
                    className="survey-percentage typography-h400"
                    data-cy="result-count"
                  >
                    {poll.count === 0 && surveyData.poll.voterCount === 0
                      ? 0
                      : Math.round(
                          (poll.count / surveyData.poll.voterCount) * 100
                        )}
                    %
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <>
            {surveyData.poll.pollOptions.map((poll) => {
              return (
                <div
                  className="survey-option flex"
                  onClick={(e) => {
                    e.stopPropagation(), setSelectedOption(poll.id);
                  }}
                  data-cy={`survey-option-${poll.id}`}
                  key={`survey-option-${poll.id}`}
                >
                  <div
                    className={
                      selectedOption === poll.id
                        ? 'selected-option'
                        : 'survey-button '
                    }
                  >
                    {selectedOption === poll.id && (
                      <SVGIcon
                        iconName="icon-thin-check"
                        fillColor="var(--base-600)"
                        size="24"
                        customClass="z-index-10 items-center"
                      />
                    )}
                  </div>
                  <span data-cy="option-title">{poll.option}</span>
                </div>
              );
            })}
            <Button
              onClick={() => _submitSurvey(surveyData.poll.id, selectedOption)}
              datacy="submit-survey"
            >
              {getCultureLocale('Submit')}
            </Button>
          </>
        )}
      </div>
      {content.adminPost &&
        content.labels.map(
          (label, i) =>
            label.footerUrl !== null && (
              <div
                className="text-right official-footer mt-[24px] mb-[24px]"
                key={i}
                data-cy="ribbon-bottom"
              >
                <AWSImage src={label.footerUrl} alt="Official Footer" />
              </div>
            )
        )}
      {(surveyData.poll.voted || isSurveyExpired) && (
        <PostInfo
          isSurvey
          voter={surveyData.poll.voterCount}
          participant={surveyData.poll.participantCount}
          views={surveyData.viewersCount}
          likes={surveyData.loversCount}
          comments={surveyData.commentsCount}
          postId={postId}
        />
      )}
    </>
  );
}

export default PostSurvey;

import React, { createRef, useEffect, useState } from 'react';

import { getCycles, updateCycle } from 'client/admin/CyclesClient';
import { useReload } from 'context/ReloadContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import debounce from 'utils/Debounce';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Modal from 'components/design-system/Modal';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import Checkbox from 'components/shared/Checkbox';
import SearchBar from 'components/shared/SearchBar';

const ModalCycleDependency = ({ showModal, setShowModal }) => {
  const limit = 15;
  const { reload, reloadAdminCycles, reloadSidebar } = useReload();
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [cycles, setCycles] = useState([]);
  const [olderThan, setOlderThan] = useState(null);
  const [selectedValue, setSelectedValue] = useState(
    showModal?.cycleDependencies || []
  );
  const intersectTarget = createRef();

  const [searchValue, setSearchValue] = useState('');
  const [debounceHandler, setDebounceHandler] = useState(null);

  const searchDebounce = async (value) => {
    const callback = async () => {
      setSearchValue(value);
    };

    const handler = await debounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  const getCycleData = async (additionalParams) => {
    const setLoad = additionalParams?.olderThan ? setLoadMore : setLoading;
    setLoad(true);
    const { data, pagination } = await getCycles({
      search: searchValue,
      state: ['draft', 'in_progress', 'done'],
      limit,
      ...additionalParams
    });
    if (data) {
      if (additionalParams?.olderThan) setCycles((prev) => [...prev, ...data]);
      else setCycles(data);
    }
    if (pagination)
      setOlderThan(data.length < limit ? null : pagination.next.olderThan);
    setLoad(false);
  };

  const handleCheckbox = ({ target }) => {
    const targetValue = parseInt(target.value);
    if (selectedValue.some((value) => value === targetValue)) {
      setSelectedValue((prev) => prev.filter((value) => value !== targetValue));
    } else {
      setSelectedValue((prev) => [targetValue, ...prev]);
    }
  };

  const handleSubmit = async () => {
    if (selectedValue) {
      const params = {
        name: showModal?.cycleName,
        dependencyIds: selectedValue
      };
      const { data } = await updateCycle(showModal?.cycleId, params);
      if (data)
        reload({
          reloadAdminCycles: !reloadAdminCycles,
          reloadSidebar: !reloadSidebar
        });
    }
    onCloseModal();
  };

  const onCloseModal = () => {
    setShowModal(null);
  };

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting &&
      !loadMore &&
      olderThan &&
      getCycleData({ olderThan }),
    threshold: 1
  });

  useEffect(() => {
    getCycleData();
  }, [searchValue]);

  return (
    <Modal
      minWidth={400}
      customClass="!max-w-[400px] max-h-[500px]"
      onClose={onCloseModal}
      dataCy="cycle-dependencies-modal"
    >
      <Modal.Header
        title={getObjectiveLocale(
          `${showModal?.id === 'edit-dep' ? 'Edit' : 'Add'} Cycle Dependencies`
        )}
        onClose={onCloseModal}
      />
      <Modal.Body>
        <div className="grid gap-[16px] px-[24px]">
          <p className="typography-paragraph">
            {getObjectiveLocale(
              'Assignment by other reviewers on these review phases must be completed first before a reviewer can do assignment on this review phase'
            )}
          </p>
          <SearchBar
            fullWidth
            onChange={(e) => searchDebounce(e.target.value)}
          />
          {cycles.map((cycle, index) => {
            const isLast = index === cycles.length - 1;
            return (
              cycle?.id !== showModal?.cycleId && (
                <Checkbox
                  key={index}
                  name={`cycle-${cycle.id}`}
                  id={`cycle-${cycle.id}`}
                  checked={selectedValue.includes(cycle.id)}
                  onChange={handleCheckbox}
                  value={cycle.id}
                  disabled={loading}
                  dataCy={`toggle-cycle-${cycle.id}`}
                >
                  {loading ? (
                    <span className="ml-[8px]">
                      <Shimmer widthRandomness={0.25} circle height="14px" />
                    </span>
                  ) : (
                    <span
                      ref={isLast ? intersectTarget : null}
                      className="text-n-900 ml-[8px]"
                    >
                      {cycle.name}
                    </span>
                  )}
                </Checkbox>
              )
            );
          })}
          {(loadMore || loading) &&
            [1, 2].map((_, index) => (
              <Checkbox key={index} disabled>
                <span className="ml-[8px]">
                  <Shimmer widthRandomness={0.25} circle height="14px" />
                </span>
              </Checkbox>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButton={{
          text: getObjectiveLocale(
            showModal?.id === 'edit-dep' ? 'Save Changes' : 'Add Dependency'
          ),
          onClick: handleSubmit,
          dataCy: 'submit-dependency-button'
        }}
        secondaryButton={{
          text: getObjectiveLocale('Cancel'),
          onClick: onCloseModal
        }}
      />
    </Modal>
  );
};

export default ModalCycleDependency;

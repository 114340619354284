import React, { useEffect, useRef, useState } from 'react';

import classSetter from 'classnames';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import startCase from 'lodash/startCase';

import {
  getUserActivationCMSCulture,
  getUserInfosCMSCulture
} from 'client/adminClient.js';
import Config from 'utils/Config';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import Checkbox from 'components/shared/Checkbox';
import Loader from 'components/shared/LoadingComponent';

const CurrentChartAdoption = (props) => {
  const [chartAdoption, setChartAdoption] = useState({});

  const [selectedDate, setSelectedDate] = useState([
    dayjs().startOf('week'),
    dayjs().endOf('week')
  ]);

  const [isDatePickerShown, setIsDatePickerShown] = useState(false);
  const [isSecondDatePickerShown, setIsSecondDatePickerShown] = useState(false);
  const [intervalButton, setIntervalButton] = useState({
    growth: {
      day: true,
      week: false,
      month: false,
      selected: 'day'
    }
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isNumber, setIsNumber] = useState(true);
  const [currentData, setCurrentData] = useState([]);
  const [isItemComparedDropdownShown, setIsItemComparedDropdownShown] =
    useState(false);
  const [currentChartCategories, setCurrentChartCategories] = useState([]);
  const [userInfoState, setUserInfoState] = useState({});
  const [isViewFilterDropdownShown, setisViewFilterDropdownShown] =
    useState(false);
  const [arrayOfFilter, setArrayOfFilter] = useState([]);
  const [viewFilterState, setViewFilterState] = useState('All');
  const [hideAllUsersSeries, setHideAllUsersSeries] = useState(false);
  const [userInfos, setUserInfos] = useState([]);
  const seriesGenerator = (data, isNumber, name) => {
    const series = [];
    let categories = [];

    Object.keys(data[0]).map((name) => {
      if (name === 'registered' || name === 'active') {
        series.push({
          name: name === 'registered' ? 'pending' : name.replace('_', ' '),
          data: [],
          color: name === 'registered' ? '#21d3c5' : 'var(--n-700)'
          // key: key,
          // keyName: key,
        });
      }
    });

    Object.keys(series).map((seriesIdx) => {
      categories = [];
      Object.keys(data[0].active).map((key, index) => {
        // let timeframe = data[0].timeframe;
        let activatedValue = data[0].active[key];
        let pendingValue = data[0].registered[key] - data[0].active[key];
        let percentageActiveValue = parseInt(
          data[0].percentage[key].toFixed(0)
        );
        let percentagePendingValue = 100 - percentageActiveValue;
        let yValue = isNumber ? pendingValue : percentagePendingValue;

        if (series[seriesIdx].name === 'active') {
          yValue = isNumber ? activatedValue : percentageActiveValue;
        }

        series[seriesIdx].data.push(yValue);

        categories.push(key.replace('_', ' '));
      });
    });

    return {
      series,
      categories
    };
  };

  const objectLength = (object) => {
    let length = 0;
    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        ++length;
      }
    }
    return length;
  };

  const handleCancel = () => {
    setIsDatePickerShown(false);
    setIsSecondDatePickerShown(false);
    setIsItemComparedDropdownShown(false);
    setisViewFilterDropdownShown(false);
  };

  const handleSelectType = (e) => {
    setIsNumber(!isNumber);
    setIsLoading(true);
    // getAdoptionData(periodBegin, periodEnd, intervalButton.growth.selected, isNumber, arrayOfFilter);
  };

  const handleShowItemComparedDrowdown = (param, e) => {
    e.preventDefault();
    if (param === 'growth') {
      setIsItemComparedDropdownShown(true);
    }
  };

  const confirmAddMetric = () => {
    let tempObj = {};

    Object.keys(userInfoState).map(function (item, idx) {
      let sel = document.getElementById(item);
      let key = item;

      if (sel.value !== 'Choose a value') {
        tempObj[key] = sel.value;
      }
    });

    arrayOfFilter.push(tempObj);

    setArrayOfFilter(arrayOfFilter);
    setIsItemComparedDropdownShown(false);
    setIsLoading(true);

    getAdoptionData(
      selectedDate[0],
      selectedDate[1],
      'day',
      isNumber,
      arrayOfFilter
    );
  };

  const removeFilter = (index, textLabel) => {
    if (textLabel === 'allUsers') {
      funcHideAllUsersSeries();
    } else {
      let selectedIndex = hideAllUsersSeries ? index : index - 1;
      arrayOfFilter.splice(selectedIndex, 1);

      setArrayOfFilter(arrayOfFilter);
      setIsLoading(true);

      getAdoptionData(
        selectedDate[0],
        selectedDate[1],
        'day',
        isNumber,
        arrayOfFilter
      );
    }
  };

  const showViewFilterDropdown = () => {
    setisViewFilterDropdownShown(!isViewFilterDropdownShown);
  };

  const funcHideAllUsersSeries = () => {
    currentChartCategories.shift();

    let chartScope = chart.current.getChart();
    chartScope.series[0].hide();

    setCurrentChartCategories(currentChartCategories);
    setHideAllUsersSeries(true);
  };

  const handleSelectView = async (itemFilter) => {
    const { data } = await getUserInfosCMSCulture(itemFilter);
    let arrayOfFilter = [];
    let tempObj = {};
    let userInfoData = data;
    for (let i = 0; i < userInfoData.length; i++) {
      tempObj[itemFilter] = userInfoData[i];
      arrayOfFilter.push(tempObj);
      tempObj = {};
    }

    setArrayOfFilter(arrayOfFilter);
    setIsLoading(true);
    setisViewFilterDropdownShown(false);
    setViewFilterState(itemFilter);
    setHideAllUsersSeries(false);

    getAdoptionData(
      selectedDate[0],
      selectedDate[1],
      'day',
      isNumber,
      arrayOfFilter
    );
  };

  const changeItemLabelFormat = (item) => {
    let itemLabel = item.replace('_', ' ');
    itemLabel = itemLabel.charAt(0).toUpperCase() + itemLabel.slice(1);

    if (itemLabel !== 'All') {
      let lastChar = itemLabel[itemLabel.length - 1];

      if (lastChar !== 'y') {
        itemLabel += 's';
      } else {
        itemLabel = itemLabel.slice(0, -1);
        itemLabel += 'ies';
      }
    }

    itemLabel = itemLabel === 'All' ? itemLabel : 'All ' + itemLabel;

    return itemLabel;
  };

  const getUserInfos = async () => {
    const { data } = await getUserInfosCMSCulture('');
    if (data) {
      let dataCurr = data;
      dataCurr.unshift('All');
      setUserInfos(dataCurr);
      let userInfoData = data;

      for (let i = 0; i < userInfoData.length; i++) {
        let keyData = userInfoData[i];
        getUserInfosKey(keyData, userInfoData);
      }
    }
  };

  const getUserInfosKey = async (keyData, userInfoData) => {
    const { data } = await getUserInfosCMSCulture(keyData);
    let userInfoStateCurr = userInfoState;
    let userInfoDataCurr = data;
    userInfoDataCurr.unshift('Choose a value');
    userInfoStateCurr[keyData] = userInfoDataCurr;

    setUserInfoState(userInfoStateCurr);
  };

  const getAdoptionData = async (
    periodBegin,
    periodEnd,
    timeUnit,
    isNumber,
    customFilter
  ) => {
    const params = {
      periodBegin: dayjs(periodBegin).startOf('day').toISOString(true),
      periodEnd: dayjs(periodEnd).endOf('day').toISOString(true),
      timeUnit,
      customFilter
    };

    const { data } = await getUserActivationCMSCulture(
      params.periodBegin,
      params.periodEnd,
      params.timeUnit,
      params.customFilter
    );

    if (data) {
      const analyticData = data.result;
      const analyticDataLength = objectLength(analyticData[0].active);
      const series = seriesGenerator(analyticData, isNumber);
      let currentData = [];
      currentData.push(analyticData[analyticData.length - 1]);
      const resultCategories = series.categories.map((category) =>
        startCase(category)
      );
      setCurrentChartCategories(series.categories);
      setCurrentData(currentData);
      setChartAdoption({
        chart: {
          style: {
            fontFamily: 'Inter'
          },
          backgroundColor: 'var(--n-000)',
          type: 'bar',
          height: analyticDataLength <= 3 ? 400 : analyticDataLength * 100,
          marginTop: 50
        },
        legend: {
          align: 'center',
          verticalAlign: 'top',
          floating: true,
          x: 0,
          y: 0
        },
        title: {
          text: 'Growth'
        },
        xAxis: {
          categories: resultCategories,
          labels: {
            style: {
              width: '100px',
              minWidth: '100px',
              left: '125px'
            },
            useHTML: true
          }
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        tooltip: {
          pointFormatter: function () {
            var pointThis = this;
            return (
              '<span style="color:' +
              pointThis.color +
              '">\u25CF</span> ' +
              pointThis.series.name +
              ': <b>' +
              pointThis.y +
              ' ' +
              (isNumber ? 'users' : '%') +
              '</b><br/>'
            );
          },
          shared: true
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            pointWidth: 30,
            groupPadding: 0
          },
          bar: {
            dataLabels: {
              enabled: true,
              align: 'right',
              formatter: function () {
                var pointThis = this;
                return isNumber ? pointThis.y : pointThis.y + '%';
              }
            }
          }
        },
        series: series.series,
        exporting: {
          buttons: {
            contextButton: {
              symbol: Config.asset.general.iconDownload
            }
          }
        }
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfos();
  }, []);

  useEffect(() => {
    getAdoptionData(
      selectedDate[0],
      selectedDate[1],
      intervalButton.growth.selected,
      isNumber,
      arrayOfFilter
    );
  }, [isNumber]);

  let selectedViewLabel = changeItemLabelFormat(viewFilterState);

  const chart = useRef();

  return (
    <span>
      {(isItemComparedDropdownShown || isViewFilterDropdownShown) && (
        <div className="datepicker-overlay" onClick={handleCancel}></div>
      )}
      <div className="row">
        <div className="col-xs-12">
          <div className="card-body-chart">
            <h4 className="body-header">
              Comparison for adoption{' '}
              <i className="fa fa-question-circle-o"></i>
            </h4>

            <div className="view-filter">
              <div className="view-label">View by</div>
              <div
                className="view-option-adoption"
                onClick={showViewFilterDropdown}
              >
                {selectedViewLabel}
              </div>

              {isViewFilterDropdownShown && (
                <div className="list-view-option-wrapper">
                  {userInfos.map(function (item, idx) {
                    let itemLabel = changeItemLabelFormat(item);
                    let activeClass = viewFilterState === item ? true : false;

                    return (
                      <div
                        key={idx}
                        className={classSetter('view-option-list', {
                          active: activeClass
                        })}
                        onClick={() => handleSelectView(item)}
                      >
                        {itemLabel}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="filter-compare-chart">
              {currentChartCategories.map((item, idx) => {
                return (
                  <div className="item-compared" key={idx}>
                    {/*<div className="item-compared-color" style={{backgroundColor: '#21d3c5'}}></div>*/}
                    <div className="item-compared-name">{startCase(item)}</div>
                    <div
                      className="item-compared-btn"
                      onClick={() => removeFilter(idx, item.replace('_', ' '))}
                    >
                      <span className="fa fa-close"></span>
                    </div>
                  </div>
                );
              })}

              <div className="item-compared-dropdown">
                <div
                  className="compare-button"
                  onClick={handleShowItemComparedDrowdown.bind(null, 'growth')}
                >
                  Add to Compare
                </div>
                {isItemComparedDropdownShown && (
                  <div className="item-dropdown-content">
                    {Object.keys(userInfoState).map(function (item, idx) {
                      let itemInside = userInfoState[item].map(function (
                        itemInside,
                        idx
                      ) {
                        return (
                          <option key={idx} value={itemInside}>
                            {itemInside}
                          </option>
                        );
                      });

                      let itemLabel = item.replace('_', ' ');
                      itemLabel =
                        itemLabel.charAt(0).toUpperCase() + itemLabel.slice(1);

                      return (
                        <div key={idx}>
                          <div className="item-filter">{itemLabel}</div>
                          <select id={item} className="item-filter-option">
                            {itemInside}
                          </select>
                        </div>
                      );
                    })}
                    <div
                      className="btn-confirm-filter"
                      onClick={confirmAddMetric}
                    >
                      <div className="item-compared-add item-compared-name">
                        Add Metric
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-between mb-[24px]">
              <div className="container-filter-period flex flex-col">
                <div className="checkbox-wrapper">
                  <Checkbox
                    customContainerClass="inline-block"
                    name="handleSelectNumberComparison"
                    id="handleSelectNumberComparison"
                    onChange={handleSelectType}
                    checked={isNumber}
                    value="number"
                  >
                    {' '}
                    {getObjectiveLocale('Number')}
                  </Checkbox>
                  <Checkbox
                    customContainerClass="ml-[16px] inline-block"
                    name="handleSelectPercentageComparison"
                    id="handleSelectPercentageComparison"
                    onChange={handleSelectType}
                    checked={!isNumber}
                    value="percentage"
                  >
                    {' '}
                    {getObjectiveLocale('Percentage')}
                  </Checkbox>
                </div>
              </div>
              {/* There wasn't a calendar button before, so i'll just leave this commented */}
              {/* <InsightCalendar
                endDate={selectedDate[1].format("D MMM YYYY").toString()}
                startDate={selectedDate[0].format("D MMM YYYY").toString()}
                rangeList={defaultRanges}
                selectedDate={selectedDate}
                isShowCalendar={isDatePickerShown}
                showCalendar={handleShowDatePicker}
                handleSelectDate={range => setSelectedDate(range)}
                handleApply={handleQueryGrowth}
                handleCancel={handleCancel}
              /> */}
            </div>

            {isLoading ? (
              <Loader />
            ) : (
              <div className="chart-box">
                <HighchartsReact
                  highcharts={Highcharts}
                  ref={chart}
                  options={chartAdoption}
                ></HighchartsReact>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <div className="card-body-chart">
            <h4 className="body-header">
              {getObjectiveLocale('Demography')}{' '}
              <i className="fa fa-question-circle-o"></i>
            </h4>

            <table id="table-default">
              <thead>
                <tr>
                  <th className="table-header group-label">
                    {getObjectiveLocale('Group')}
                  </th>
                  <th className="table-header invited-label">
                    {getObjectiveLocale('Registered')}
                  </th>
                  <th className="table-header activated-label">
                    {getObjectiveLocale('Activated')}
                  </th>
                  <th className="table-header pending-label">
                    {getObjectiveLocale('Pending')}
                  </th>
                  <th className="table-header percentage-label">
                    {getObjectiveLocale('Percentage')}
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentData.length === 0 ? (
                  <></>
                ) : (
                  Object.keys(currentData[0].active).map((key, index) => {
                    let invitedValue = currentData[0].registered[key];
                    let activatedValue = currentData[0].active[key];
                    let pendingValue =
                      currentData[0].registered[key] -
                      currentData[0].active[key];
                    let percentageValue =
                      currentData[0].percentage[key].toFixed(0);
                    let progressColor = ['#21d3c5']; // "#f6a623", "#9072aa"];

                    return (
                      <tr key={index}>
                        <td>{startCase(key)}</td>
                        <td>{getNumberLocale(invitedValue || 0)}</td>
                        <td>{getNumberLocale(activatedValue || 0)}</td>
                        <td>{getNumberLocale(pendingValue || 0)}</td>
                        <td>
                          <div className="percentage-value">
                            {getNumberLocale(percentageValue || 0)}%
                          </div>
                          <div className="percentage-bar">
                            <div
                              className="percentage-bar-value"
                              style={{
                                backgroundColor: progressColor[0],
                                width: percentageValue + '%'
                              }}
                            ></div>
                            <div className="percentage-bar-max"></div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </span>
  );
};

export default CurrentChartAdoption;

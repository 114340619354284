import { format } from 'timeago.js';

import { useUser } from 'context/UserContext';
import useModalObjectives from 'hooks/useModalObjectives';
import useValidationMinMax from 'hooks/useValidationMinMax';
import Config from 'utils/Config.js';
import {
  capitalize,
  getCssVariableValue,
  getExtraInformation,
  getObjectiveLocale
} from 'utils/HelperUtils';
import { getOwners } from 'utils/ObjectivesHelper';

const ListBadgeHelper = ({ listBadge, objective, isReadOnly, ...rest }) => {
  const {
    involvements,
    objectiveCategory,
    weight,
    tags,
    type,
    valueLastUpdatedAt,
    team,
    complexity,
    updateProgressCount,
    milestoneType,
    milestoneMode,
    externalId,
    isProject,
    organization,
    permissions
  } = objective || [];
  const { level, showBadgeObjectiveCount, isObjectivesCard = false } = rest;

  const showModal = useModalObjectives((state) => state.showModal);

  const { config } = useUser();
  const { objectiveWeightType, objectiveWeightTypeOptions } = config;
  const { objectiveWeightManage } = config?.permissions || {};
  const { goalOwnersName, goalOwners, goalOwnersProfilePic } = getOwners(
    involvements,
    isProject
  );
  const propsModal = {
    objectives: [objective],
    isIndividual: true,
    level: level - 1 == 0 ? 0 : null
  };

  // For Badge Owner
  const ownerText = Array.isArray(goalOwnersName)
    ? goalOwners?.length + ' Owners'
    : goalOwnersName +
      ` ${
        goalOwners?.[0]?.user?.title
          ? ` · ${getExtraInformation(
              goalOwners?.[0]?.user?.title,
              goalOwners?.[0]?.user?.subtitle
            )}`
          : '-'
      }`;
  const tooltipOwner = Array.isArray(goalOwnersName)
    ? ownerText
    : `Owner: ${ownerText}`;

  // For Badge Weight
  const { isShowWarning } = useValidationMinMax(weight, objective?.parent);
  const isLocked = objectiveCategory?.isLocked;
  const isErrorWeight = isLocked
    ? objectiveCategory?.weight != weight
    : isShowWarning;

  // For Badge Goal Type
  const goalType = team?.id ? 'team_goal' : type;
  const isAllowedObjectiveCategory =
    objectiveWeightTypeOptions.includes(goalType);

  const listLabel = [
    goalOwners?.length > 0 && {
      id: 'owner',
      iconUrl:
        externalId === null || externalId == ''
          ? goalOwnersProfilePic
          : Config.asset.v2.icoGroup,
      imageVariant: 'rounded-full',
      content: ownerText,
      tooltipText: !isReadOnly && tooltipOwner,
      truncate: true,
      defaultPointer: !isReadOnly,
      useEvent: goalOwners?.length > 1 ? true : false,
      onClick: () =>
        !isReadOnly &&
        showModal({
          modalType: 'ownerList',
          props: { ...propsModal, goalOwners }
        })
    },
    organization && {
      id: 'organization',
      iconUrl: organization?.companyLogo,
      content: organization?.name,
      tooltipText: organization?.name,
      editable: false,
      defaultPointer: true,
      truncate: true
    },
    team && {
      id: 'team',
      iconUrl: team.teamPicture,
      imageVariant: 'rounded',
      content: `${
        team?.teamType ? `${getObjectiveLocale(team?.teamType)}:` : ''
      } ${team.name}`,
      tooltipText:
        !isReadOnly &&
        `${team?.teamType ? `${getObjectiveLocale(team?.teamType)}:` : ''} ${
          team.name
        }`,
      editable: false,
      truncate: true
    },
    type !== 'task' &&
      objectiveWeightType == 'type' &&
      (isObjectivesCard ? objectiveCategory : isAllowedObjectiveCategory) && {
        id: 'goalType',
        content: objectiveCategory?.name ?? 'Select type here',
        tooltipText:
          !isReadOnly &&
          (objectiveCategory?.name
            ? `Goal type: ${objectiveCategory?.name}`
            : 'Select type here'),
        colorHex: objectiveCategory?.colorHex ?? getCssVariableValue('--r-800'),
        bgColorHex: objectiveCategory?.bgColorHex,
        editable:
          !isReadOnly && !isObjectivesCard && permissions?.includes('update'),
        onClick: () =>
          !isReadOnly &&
          showModal({ modalType: 'adjustWeight', props: propsModal }),
        defaultPointer: !isReadOnly
      },
    type != 'task' &&
      weight &&
      objectiveWeightManage && {
        id: 'weight',
        iconLeft: 'icon-weight',
        iconLeftColor: isErrorWeight ? 'var(--r-600)' : 'var(--n-600)',
        content: `${weight}%`,
        tooltipText: !isReadOnly && `Weight: ${weight}%`,
        colorHex: isErrorWeight
          ? getCssVariableValue('--r-800')
          : getCssVariableValue('--n-800'),
        bgColorHex: isErrorWeight
          ? getCssVariableValue('--r-200')
          : getCssVariableValue('--n-300'),
        editable:
          !isReadOnly && !isObjectivesCard && permissions?.includes('update'),
        onClick: () => {
          !isReadOnly &&
            showModal({
              modalType:
                isLocked && objectiveCategory?.weight != weight
                  ? 'adjustWeightType'
                  : 'adjustWeight',
              props: propsModal
            });
        },
        truncate: true,
        customMaxWidth: 'max-w-[90px]'
      },
    milestoneType &&
      milestoneType !== 'disabled' && {
        id: 'milestone',
        iconLeft: 'icon-timeline',
        content: `${capitalize(milestoneType)} (${capitalize(
          milestoneMode == 'latest_value'
            ? 'Latest Value'
            : milestoneMode.split('_')[0]
        )})`,
        tooltipText:
          !isReadOnly &&
          `Milestone: ${capitalize(milestoneType)} (${capitalize(
            milestoneMode == 'latest_value'
              ? 'Latest Value'
              : milestoneMode.split('_')[0]
          )})`,
        editable: false,
        defaultPointer: !isReadOnly
      },
    tags?.length > 0 &&
      permissions?.includes('label_manage') && {
        id: 'labels',
        title: '',
        iconLeft: 'icon-label',
        content: tags.join(', '),
        tooltipText: !isReadOnly && `Label: ${tags.join(', ')}`,
        editable: false,
        defaultPointer: !isReadOnly,
        truncate: true
      },
    showBadgeObjectiveCount &&
      updateProgressCount && {
        id: 'updated',
        content: `+${updateProgressCount} Updates`,
        colorHex: getCssVariableValue('--n-000'),
        bgColorHex: getCssVariableValue('--r-500')
      },
    type == 'task' &&
      complexity && {
        id: 'complexity',
        title: '',
        iconUrl: Config.asset.objective.changeComplexity,
        content: complexity,
        editable: !isReadOnly,
        onClick: () =>
          !isReadOnly &&
          showModal({ modalType: 'adjustWeight', props: propsModal }),
        defaultPointer: !isReadOnly,
        tooltipText: `Complexity: ${complexity}`
      },

    valueLastUpdatedAt && {
      id: 'history',
      title: '',
      iconLeft: 'icon-history',
      content: format(valueLastUpdatedAt),
      tooltipText: !isReadOnly && `Last updated: ${format(valueLastUpdatedAt)}`,
      editable: false,
      defaultPointer: !isReadOnly
    }
  ].filter((e) => e);

  const filteredBadge = listLabel.filter(({ id }) => {
    return listBadge.includes(id);
  });

  return filteredBadge;
};

export default ListBadgeHelper;

import React from 'react';

import Checkbox from 'components/shared/Checkbox';

const ColorItem = ({ id, colorHex, onChange, checked, disabled }) => {
  return (
    <div className="box-color mr-[16px]">
      <Checkbox
        name="color"
        checked={checked}
        onClick={onChange}
        id={id}
        style={{ background: colorHex }}
        labelClass="cursor-pointer"
        disabled={disabled}
        dataCy={id}
      />
    </div>
  );
};

export default ColorItem;

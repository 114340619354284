import React, { createRef } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { getObjectivesCount } from 'client/ObjectivesClient';
import { useUser } from 'context/UserContext';
import { useTeamSquad } from 'context/team/TeamSquadContext';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { getCssVariableValue, getObjectiveLocale } from 'utils/HelperUtils';
import { defaultFilter, getLeader, sortOptions } from 'utils/TeamHelper';

import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import Card from 'components/design-system/Card';
import Accordion from 'components/design-system/accordion/Accordion';
import Shimmer from 'components/design-system/shimmer/Shimmer';
import Avatar from 'components/shared/Avatar';
import Badge from 'components/shared/Badge';
import TruncateParagraph from 'components/shared/TruncateParagraph';
import TribeChildDetail from 'components/teams/TribeChildDetail';

const TeamFeedbacksSkeleton = () => {
  return (
    <Card
      customClass="mt-[16px] shadow-raised flex items-center"
      borderColor="border-n-300"
    >
      <div className="mr-[16px] w-[390px]">
        <Shimmer width="320px" height="20px" customClass="mb-[4px]" />
        <Shimmer width="240px" height="16px" />
      </div>
      <Shimmer width="72px" height="16px" customClass="mr-[16px]" />
      <Shimmer width="160px" height="32px" customClass="rounded-[4px]" />
    </Card>
  );
};

const SquadStatus = ({ listStatus }) => {
  const whichBgColor = {
    '5A9AEF': 'b-200',
    '44DB5E': 'g-200',
    FFCD00: 'y-200',
    FE2851: 'r-200'
  };

  const whichTextColor = {
    '5A9AEF': 'b-800',
    '44DB5E': 'g-800',
    FFCD00: 'y-800',
    FE2851: 'r-800'
  };

  return (
    <div
      className="bg-n-200 border-solid border border-n-300 rounded-[4px] px-[16px] py-[12px] flex items-center h-[36px] w-full"
      onClick={(e) => e?.stopPropagation()}
    >
      <span className="typography-h400 text-n-600 mr-[8px]">
        {getObjectiveLocale('Overall Status')}
      </span>
      {listStatus?.map((status, idx) => {
        return (
          <Badge
            key={idx}
            colorHex={getCssVariableValue(
              `--${whichTextColor[status?.progressColorHex]}`
            )}
            bgColorHex={getCssVariableValue(
              `--${whichBgColor[status?.progressColorHex]}`
            )}
            className="typography-h400 mr-[8px] !cursor-default"
            dataCy={`badge-${status.progressTitle}-count`}
            title={status.progressTitle}
            content={status.qty.toString()}
          />
        );
      })}
    </div>
  );
};

const HeaderAccordion = ({ squad }) => {
  const leader = getLeader(squad?.involvements)?.user;

  return (
    <div className="pb-[8px] flex flex-col justify-between w-full">
      <p
        className="typography-h500 text-n-900 mb-[8px]"
        data-cy="tribe-child-name"
      >
        <span className="typography-h500 text-n-600 mr-[4px]">
          {getObjectiveLocale('Squad')}:{' '}
        </span>
        {squad?.name}
      </p>
      <div className="flex items-center mb-[8px]">
        <Avatar src={leader?.profilePicture} name={leader?.name} size={24} />
        <p className="ml-[8px] max-w-[120px] flex">
          <TruncateParagraph
            className="typography-paragraph text-n-800"
            position="bottom"
            dataCy="tribe-child-leader"
          >
            {leader?.name}
          </TruncateParagraph>{' '}
        </p>
        <div
          className="typography-paragraph text-n-800 ml-[8px]"
          data-cy="tribe-child-members"
        >
          · {squad?.totalMembers}{' '}
          {getObjectiveLocale(`member${squad?.totalMembers > 0 ? 's' : ''}`)}
        </div>
      </div>
    </div>
  );
};

const TeamSquads = (teamId) => {
  const {
    config: { defaultObjectiveSorting }
  } = useUser();

  const history = useHistory();
  const intersectTarget = createRef();
  const {
    data: squads,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage
  } = useTeamSquad();

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) =>
      entry.isIntersecting && hasNextPage && fetchNextPage(),
    threshold: 1
  });

  const getCount = () => {
    let params = {
      parentNotAssignedTo: squads.id,
      type: ['goal', 'annual_goal'],
      team_id: teamId,
      goals: 'team',
      state: ['running', 'completed', 'reviewed', 'draft', 'edited'],
      ignore_involvements: true
    };

    return getObjectivesCount(params);
  };

  let { data } = useQuery(['overallProgress'], getCount);

  let overallData = data?.data;
  let listStatus = overallData?.listStatus.slice().reverse();

  listStatus = listStatus?.map((v) => {
    let progressTitle;
    if (v.progressColorHex === 'FE2851') {
      progressTitle = getObjectiveLocale('At risk');
    }
    if (v.progressColorHex === 'FFCD00') {
      progressTitle = getObjectiveLocale('Left behind');
    }
    if (v.progressColorHex === '44DB5E') {
      progressTitle = getObjectiveLocale('On track');
    }
    if (v.progressColorHex === '5A9AEF') {
      progressTitle = getObjectiveLocale('Exceeded');
    }

    return {
      ...v,
      progressTitle: progressTitle
    };
  });

  return (
    <div className="pr-[40px] mt-[20px]">
      <AdvancedFilter
        id="teamSquadFilter"
        filterOptions={['search', 'sort']}
        defaultFilter={defaultFilter(defaultObjectiveSorting)}
        sortList={sortOptions}
      />
      <div className="h-[calc(100vh-260px)] overflow-y-auto mt-[16px]">
        {squads?.length > 0 &&
          squads?.map((squad) => (
            <Accordion
              customClass="mb-[8px]"
              key={squad?.id}
              dataCy={`tribe-child-${squad?.id}`}
            >
              <Accordion.Header
                dataCyChevronButton={`open-assignment-${squad?.id}`}
                customSpaceClass="items-center"
                customWrapperClass="px-[24px] py-[16px]"
                customWrapperClick={() =>
                  history.push(`/teams/squad/${squad?.id}`)
                }
                subHeader={<SquadStatus listStatus={listStatus} />}
              >
                <HeaderAccordion squad={squad} />
              </Accordion.Header>
              <Accordion.Content customSpaceClass="px-[24px] pb-[24px]">
                <TribeChildDetail teamId={squad?.id} />
              </Accordion.Content>
            </Accordion>
          ))}
        {isFetching && <TeamFeedbacksSkeleton />}
        {hasNextPage && !isFetchingNextPage && (
          <div className="h-[1px]" ref={intersectTarget}>
            &nbsp;
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamSquads;

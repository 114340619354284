import React, { useEffect, useState } from 'react';

import SVGIcon from 'components/shared/SVGIcon';
import TextArea from 'components/shared/TextArea';

const Description = ({
  objectiveDetail,
  updateObjective,
  disabled = false
}) => {
  const { description } = objectiveDetail;

  const [objectiveDescription, setObjectiveDescription] = useState(description);

  const updateObjectiveDescription = () => {
    if (description != objectiveDescription) {
      updateObjective({ description: objectiveDescription });
    }
  };

  useEffect(() => {
    setObjectiveDescription(description);
  }, [description]);

  return (
    <div className="px-24 py-12">
      <div className="flex" data-cy="sidebar-project-description">
        <SVGIcon
          iconName="icon-notes"
          size="24"
          fillColor="var(--n-600)"
          customClass="mr-16"
        />
        <TextArea
          id="input-objective-desc"
          name="description"
          onHandleChange={(e) => setObjectiveDescription(e.target.value)}
          textPlaceholder="Description"
          value={objectiveDescription || ''}
          className="text-area-description typography-paragraph mt-[-4px]"
          isLimitedHeight={false}
          onBlur={updateObjectiveDescription}
          showBorderOnHover
          dataCy="project-description"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default Description;

import React from 'react';

import DropdownMetric from 'components/dropdown/DropdownMetric';
import SVGIcon from 'components/shared/SVGIcon';
import Tooltips from 'components/shared/Tooltips/Tooltips';

import ObjectivesFormBox from './ObjectivesFormBox';

const MinMaxFormBox = ({
  formBox,
  dropdownMetric,
  input,
  error,
  handler,
  disabled
}) => {
  const listOptions = [
    {
      id: 1,
      description: 'Yearly',
      unit: '',
      unitIcon: null
    },
    {
      id: 2,
      description: 'Semesterly',
      unit: '',
      unitIcon: null
    },
    {
      id: 3,
      description: 'Quarterly',
      unit: '',
      unitIcon: null
    }
  ];

  return (
    <ObjectivesFormBox
      title={formBox.title}
      isChecked={formBox.isChecked}
      desc={formBox.description}
      onChange={handler.toggle}
      useBorderBottom={true}
      idToogleSwitch={formBox.idToogleSwitch}
      className="general-setting-box"
      disabled={disabled}
    >
      {formBox.isChecked && (
        <div>
          <div className="mt-[16px] flex items-center">
            <span className="minimum-number-of-ob mt-[2px] w-[220px]">
              {formBox.subtitle}
            </span>
            <span className="ml-[20px]">
              <input
                type="number"
                name={input.name}
                placeholder={input.placeholder}
                value={input.value}
                className={`${
                  input?.className || ''
                } styled-input w-[80px] h-[36px]`}
                onChange={handler.input}
                data-cy={input.name}
                disabled={disabled}
              />
            </span>
            <label className="minimum-number-of-ob ml-[36px] mr-[2px] mt-[2px] w-[41px]">
              Period
            </label>
            <div className="dropdown-triangle-black">
              <DropdownMetric
                defaultValue={dropdownMetric.defaultValue}
                handleChange={(value) =>
                  handler.select(
                    value.description,
                    dropdownMetric.handleChangeType
                  )
                }
                listMetrics={listOptions}
                datacy={dropdownMetric.datacy}
                disabled={disabled}
              />
            </div>
            <Tooltips
              text="Period determines when this setting will be reset"
              isEllipsis
              tooltipClass="px-[12px] py-[8px] w-[170px] h-[40px] flex items-center "
            >
              <SVGIcon
                iconName="icon-info"
                size="16"
                customClass="ml-[10px] mt-[3px]"
                fillColor="var(--n-500)"
              />
            </Tooltips>
            {error.isError && (
              <div className="warning-message typography-h300">
                {error.message}
              </div>
            )}
          </div>
        </div>
      )}
    </ObjectivesFormBox>
  );
};

export default MinMaxFormBox;

import React from 'react';

import useModalObjectives from 'hooks/useModalObjectives';

import FormattedNumber from 'components/design-system/FormattedNumber';
import LineProgressBar from 'components/shared/SVGProgressBar/Line/LineProgressBar';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const ProgressObjective = ({
  progressPercentage,
  expectedProgressPercentage,
  tooltipProgressText,
  barColor,
  textColor,
  emptyProgress,
  objective,
  isCreate,
  allowZero,
  permissions,
  width = 72,
  height = 12,
  progressWidth
}) => {
  const enableEdit = isCreate || permissions?.includes('update_current_value');
  const showModal = useModalObjectives((state) => state.showModal);

  let modalProps = {
    measurement: objective?.measurement,
    canUpdateCurrentValue: objective?.permissions?.includes(
      'update_current_value'
    ),
    objectiveId: objective?.id,
    objectiveName: objective?.name,
    permission: objective?.permissions?.includes('update_comment'),
    commentOptions: objective?.commentOptions,
    milestoneType: objective?.milestoneType,
    objectiveValue: objective,
    currentMilestone: objective?.currentMilestone
  };

  return (
    <div
      className="flex flex-row items-center justify-start"
      onClick={(e) => {
        if (enableEdit) {
          e.stopPropagation();
          !isCreate &&
            showModal({ modalType: 'updateProgress', props: modalProps });
        }
      }}
    >
      {allowZero ? (
        <>
          <TooltipContainer
            show={tooltipProgressText}
            text={tooltipProgressText}
          >
            <LineProgressBar
              progress={progressPercentage}
              expectedProgress={expectedProgressPercentage}
              width={width}
              primaryColor={barColor}
              secondaryColor={'var(--n-500)'}
              fullFirst={true}
              height={height}
              dataCy="update-progress"
            />
          </TooltipContainer>
          <div className={`${progressWidth ? progressWidth : 'w-[72px]'}`}>
            <FormattedNumber
              number={progressPercentage}
              customClass={`typography-h400 whitespace-nowrap text-start ml-[4px] ${textColor}`}
              cursorClass="cursor-pointer"
            />
            <span className={`typography-h400 ml-[4px] ${textColor}`}>%</span>
          </div>
        </>
      ) : progressPercentage > 0 || !emptyProgress ? (
        <>
          <TooltipContainer
            show={tooltipProgressText}
            text={tooltipProgressText}
          >
            <LineProgressBar
              progress={progressPercentage}
              expectedProgress={expectedProgressPercentage}
              width={width}
              primaryColor={barColor}
              secondaryColor={'var(--n-500)'}
              fullFirst={true}
              height={height}
              dataCy="update-progress"
            />
          </TooltipContainer>
          <div
            className={`${
              progressWidth ? progressWidth : 'w-[72px]'
            } flex flex-row`}
          >
            <FormattedNumber
              number={progressPercentage}
              customClass={`typography-h400  text-start ml-[4px] ${textColor}`}
              cursorClass="cursor-pointer"
            />
            <span className={`typography-h400 ml-[4px] ${textColor}`}>%</span>
          </div>
        </>
      ) : (
        emptyProgress
      )}
    </div>
  );
};

export default ProgressObjective;

import React, { useEffect, useState } from 'react';

import startCase from 'lodash/startCase';

import { getPlacementInfo } from 'client/adminClient';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Trigger from 'components/design-system/dropdown/Trigger';
import SVGIcon from 'components/shared/SVGIcon';
import SmallToggleSwitchPurple from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

function PlacementSection({ listKeyPlacement, setGroup, data, placementKey }) {
  const [isHover, setHover] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    data?.[placementKey] || ''
  );
  const [listValue, setListValue] = useState([]);
  const [useValueKey, setUseValueKey] = useState(
    data?.[placementKey] !== 'self'
  );

  const handleChangeDropdownKey = (value) => {
    delete data?.[placementKey];
    delete data?.[`${placementKey}_eid`];
    setGroup('info', {
      ...data,
      [value]: 'self'
    });
    setSelectedValue('self');
    setUseValueKey(false);
  };

  const handleChangeDropdownValue = (value) => {
    setGroup('info', {
      ...data,
      [placementKey]: value?.name,
      [`${placementKey}_eid`]: value?.code
    });
    setSelectedValue(value?.name);
  };

  const handleChangeToggle = () => {
    setGroup('info', {
      ...data,
      [`${placementKey}_eid`]: useValueKey ? 'self' : listValue?.[0]?.code,
      [placementKey]: useValueKey ? 'self' : listValue?.[0]?.name
    });
    setSelectedValue(useValueKey ? 'self' : listValue?.[0]?.name);
    setUseValueKey(!useValueKey);
  };

  useEffect(() => {
    if (placementKey) {
      const getListValueByKey = async () => {
        const { data } = await getPlacementInfo(placementKey);
        if (data) {
          setListValue(data?.values);
        }
      };

      getListValueByKey();
    }
  }, [placementKey]);

  useEffect(() => {
    if (data?.[placementKey] === 'self') {
      setUseValueKey(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.[placementKey]]);

  return (
    <div
      className="flex items-center mb-[24px]"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex-col">
        <Dropdown>
          <Dropdown.Trigger>
            <Trigger
              customWrapperClass="w-[240px]"
              topLabel={false}
              label={false}
              icon={false}
              text={placementKey ? startCase(placementKey) : '(Not selected)'}
            />
          </Dropdown.Trigger>
          <Dropdown.MenuItems
            type="fixed"
            position="right"
            maxHeight={120}
            customClass="w-[240px]"
          >
            {listKeyPlacement?.map((value, index) => (
              <Dropdown.MenuItem
                index={value?.index}
                onClick={() => handleChangeDropdownKey(value)}
                customClass="w-[240px]"
                dataCy={`dropdown-option-${index}`}
                key={index}
              >
                {startCase(value)}
              </Dropdown.MenuItem>
            ))}
          </Dropdown.MenuItems>
        </Dropdown>
      </div>
      <div className="flex-col ml-[16px]">
        <div className="flex items-center h-[32px]">
          <SmallToggleSwitchPurple
            idStr="0"
            isChecked={useValueKey}
            onChange={() => handleChangeToggle()}
            disabled={false}
            addClass="mt-[8px] ml-[-8px]"
          />
          {useValueKey && (
            <Dropdown>
              <Dropdown.Trigger>
                <Trigger
                  customWrapperClass="w-[240px]"
                  topLabel={false}
                  label={false}
                  icon={false}
                  text={
                    listValue?.find((data) => data?.name == selectedValue)
                      ?.name || listValue?.[0]?.name
                  }
                />
              </Dropdown.Trigger>
              <Dropdown.MenuItems
                type="fixed"
                position="right"
                maxHeight={120}
                customClass="w-[240px]"
              >
                {listValue?.map((value, index) => (
                  <Dropdown.MenuItem
                    index={value?.index}
                    onClick={() => handleChangeDropdownValue(value)}
                    customClass="w-[240px]"
                    dataCy={`dropdown-option-${index}`}
                    key={index}
                  >
                    {startCase(value?.name)}
                  </Dropdown.MenuItem>
                ))}
              </Dropdown.MenuItems>
            </Dropdown>
          )}
          {isHover && (
            <SVGIcon
              fillColor="var(--n-600)"
              iconName="icon-delete"
              customClass="ml-[24px]"
              size="24"
              onClick={() => {
                delete data?.[placementKey];
                delete data?.[`${placementKey}_eid`];
                setGroup('info', {
                  ...data
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PlacementSection;

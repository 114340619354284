import React, { useState } from 'react';

import { useCreateCycle } from 'context/admin/CreateCycleContext';
import { getObjectiveLocale, setActiveNavigator } from 'utils/HelperUtils';

import RangeCalendarPhases from 'components/admin/Cycles/RangeCalendarPhases.js';
import ContentNavigator from 'components/shared/ContentNavigator';
import RadioButton from 'src/components/shared/RadioButton';

import PhasesContent from './PhasesContent';
import './SetPhases.scss';
import SetDependencies from './phases/SetDependencies';
import SetWeightSection from './phases/SetWeightSection';
import SuggestionAndPrefilledSection from './phases/suggestion-and-prefilled/SuggestionAndPrefilledSection';

const SetPhases = ({ setShowModalDeactivate }) => {
  const {
    cycleData,
    changeRestrictedTrackNavigation,
    changeLockSubmittedAssignment
  } = useCreateCycle();
  const { restrictedTrackNavigation, lockSubmittedAssignment } = cycleData;
  const configRestrictedTrack = [
    {
      id: 1,
      description:
        "Reviewers can't switch between tracks before answering all questions",
      value: true
    },
    {
      id: 2,
      description:
        'Reviewers can switch freely between tracks even when questions have not been answered yet',
      value: false
    }
  ];
  const configLockAnswer = [
    {
      id: 1,
      description:
        'Reviewers can edit their answers after submitting review (Default)',
      value: false
    },
    {
      id: 2,
      description: 'Reviewers can’t edit their answers after submitting review',
      value: true
    }
  ];

  const [activeSection, setActiveSection] = useState('review-period-section');

  let list = [
    { id: 'review-period-section', name: 'Review Period' },
    { id: 'switch-track-section', name: 'Switch Track' },
    { id: 'switch-lock-answer-section', name: 'Lock Answer' },
    { id: 'phases-section', name: 'Phases' },
    {
      id: 'suggestion-rating-section',
      name: 'Suggestion & Pre-filled Answers'
    }
  ];

  const numActivePhases = () => {
    const phases = [
      cycleData.selfReview,
      cycleData.peerReview,
      cycleData.upwardReview,
      cycleData.managerReview,
      cycleData.indirectManagerReview
    ].filter((phase) => phase);

    return phases.length;
  };

  const handleSelectRestrictedTrack = (value) => {
    changeRestrictedTrackNavigation(value);
  };

  const handleSelectLockAnswer = (value) => {
    changeLockSubmittedAssignment(value);
  };

  // To Render dynamic content navigator
  const hasActivePhases = numActivePhases() > 0;
  const moreThanOnePhase = numActivePhases() > 1;

  if (hasActivePhases) {
    list.push({ id: 'set-weight-section', name: 'Set Weight' });
  }
  if (moreThanOnePhase) {
    list.push({ id: 'set-dependencies-section', name: 'Set Dependencies' });
  }

  return (
    <div className="container-set-phases-cycle">
      <div className="content-navigator-wrapper">
        <ContentNavigator
          title="CONTENTS"
          activeSection={activeSection}
          listNavigatorContents={list}
          sectionContent="scrollable-config"
          additionalOffset={-8}
        />
      </div>

      <div
        id="scrollable-config"
        className="flex content-container-nav"
        onScroll={(e) => setActiveNavigator(e, list, setActiveSection)}
      >
        <div className="w-9/12 pr-[18px]">
          <div id="review-period-section" className="pt-[24px]">
            <h5 className="typography-h500 mb-[4px]">Review period</h5>
            <p className="mb-[16px] text-n-800">
              Configure period for performance and behavior to be included for
              the scoring.
            </p>

            <RangeCalendarPhases
              calendarContext="setPhases"
              customFormatDate={{
                month: 'long',
                day: 'numeric',
                year: 'numeric'
              }}
            />
          </div>
          <div id="switch-track-section" className="mt-[40px] pt-[40px]">
            <h5 className="typography-h500 mb-[4px]">
              {getObjectiveLocale('Switch track')}
            </h5>
            <p className="mb-[16px] text-n-800">
              {getObjectiveLocale(
                'Option to let reviewers switch between tracks during review'
              )}
            </p>

            {configRestrictedTrack.map((val) => (
              <div
                key={val.id}
                className="wrapper-radio-button flex items-center mb-[8px]"
              >
                <RadioButton
                  dataCy={`restricted-phase-${val.value}`}
                  size="small"
                  value={val.id}
                  id={val.id}
                  checked={restrictedTrackNavigation == val.value}
                  onChange={() => handleSelectRestrictedTrack(val.value)}
                >
                  <span className="ml-[8px]">
                    {getObjectiveLocale(val.description)}
                  </span>
                </RadioButton>
              </div>
            ))}
          </div>
          <div id="switch-lock-answer-section" className="mt-[40px] pt-[40px]">
            <h5 className="typography-h500 mb-[4px]">
              {getObjectiveLocale('Lock Answer')}
            </h5>
            <p className="mb-[16px] text-n-800">
              {getObjectiveLocale(
                'Option to let reviewers change their review after submitting.'
              )}
            </p>

            {configLockAnswer.map((val) => (
              <div
                key={val.id}
                className="wrapper-radio-button flex items-center mb-[8px]"
              >
                <RadioButton
                  dataCy={`locked-answer-${val.value}`}
                  size="small"
                  value={`lock-${val.id}`}
                  id={`lock-${val.id}`}
                  checked={lockSubmittedAssignment == val.value}
                  onChange={() => handleSelectLockAnswer(val.value)}
                >
                  <span className="ml-[8px]">
                    {getObjectiveLocale(val.description)}
                  </span>
                </RadioButton>
              </div>
            ))}
          </div>
          <div
            id="phases-section"
            className="container-phase-and-track mt-[40px] pt-[40px]"
          >
            <PhasesContent setShowModalDeactivate={setShowModalDeactivate} />
          </div>
          <SuggestionAndPrefilledSection />
          {hasActivePhases && <SetWeightSection cycleData={cycleData} />}
          {moreThanOnePhase && (
            <div
              id="set-dependencies-section"
              className="mt-[20px] pt-[40px] pb-[5px]"
            >
              <SetDependencies />
            </div>
          )}
        </div>
        <div className="w-3/12"></div>
      </div>
    </div>
  );
};

export default SetPhases;

import React, { Suspense } from 'react';

import { useUser } from 'context/UserContext';
import usePermission from 'hooks/usePermission';
import { getObjectiveLocale } from 'utils/HelperUtils';

import AddNewReviewees from 'components/admin/Cycles/AddNewReviewees';
import CreateCycle from 'components/admin/Cycles/CreateCycle';
import Cycles from 'components/admin/Cycles/Cycles';
import CyclesDetails from 'components/admin/Cycles/CyclesDetails/CyclesDetails';
import EditCycle from 'components/admin/Cycles/EditCycle';
import GoalReviewComponent from 'components/admin/GoalReviewComponent';
import Overview from 'components/admin/Overview';
import OrganizationValuesCompetencies from 'components/admin/competencies-and-values/OrganizationValuesCompetencies';
import ListOrganizations from 'components/admin/culture/ListOrganizations';
import CreateEditBehaviorModelPage from 'components/admin/groups/behavior/CreateEditBehaviorModelPage';
import LandingBehaviorModelPage from 'components/admin/groups/behavior/LandingBehaviorModelPage';
import CreateEditGroupPerformanceProfile from 'components/admin/groups/review/CreateEditGroupPerformanceProfile.js';
import PerformanceProfile from 'components/admin/groups/review/PerformanceProfile.js';
import OrgDetailsLabels from 'components/admin/labels/OrgDetailsLabels';
import LeaderboardContent from 'components/admin/leaderboard-content/LeaderboardContent';
import LeaderboardUser from 'components/admin/leaderboard-user/LeaderboardUser';
import ObjectiveOverview from 'components/admin/objective-overview/ObjectiveOverview';
import ListUsersWrapper from 'components/admin/usermanagement/ListUsersWrapper';
import UserProfile from 'components/user-profile/UserProfile';
import TalentMapping from 'oldPages/TalentMapping';
import Activities from 'pages/Activities';
import AssignmentReviewResult from 'pages/AssignmentReviewResult';
import CompanyGoals from 'pages/CompanyGoals/CompanyGoals';
import CreateObjectivePage from 'pages/CreateObjective';
import DirectReportPage from 'pages/DirectReport';
import Explorer from 'pages/Explorer';
import FormalReview from 'pages/FormalReview';
import FormalReviewResult from 'pages/FormalReviewResult';
import FormalReviewScoring from 'pages/FormalReviewScoring';
import GiveComment from 'pages/GiveComment';
import GiveReview from 'pages/GiveReview';
import GoalTree from 'pages/GoalTree';
import HighlightedPage from 'pages/Highlighted';
import LandingPage from 'pages/LandingPage';
import LandingPageSharePost from 'pages/LandingPageSharePost';
import ListOfObjectivePage from 'pages/MyGoals';
import ListOfMyTask from 'pages/MyTasks';
import NeedResponse from 'pages/NeedResponse';
import NewCalibration from 'pages/NewCalibration';
import NewTasks from 'pages/NewTasks';
import ListOfOtherGoals from 'pages/OtherGoals';
import ProgressOvertime from 'pages/ProgressOvertime';
import ProjectGoalsDetail from 'pages/ProjectGoalsDetail';
import SubordinatePage from 'pages/Subordinate';
import SurveyRedirect from 'pages/SurveyRedirect';
import TeamResult from 'pages/TeamResult';
import TeamReviewScoring from 'pages/TeamReview';
import Approval from 'pages/approval/Approval';
import {
  GroupTimeline,
  HomePageTimeline,
  SingleFeedComponent
} from 'pages/culture/HomePageTimeline';
import ManageSurveyList from 'pages/surveys/ManageSurveyList';
import PulseSurveyAnswer from 'pages/surveys/PulseSurveyAnswer';
import PulseSurveyComposer from 'pages/surveys/PulseSurveyComposer';
import PulseSurveyList from 'pages/surveys/PulseSurveyList';
import NewDetailTeam from 'pages/teams/NewDetailTeam';
import NewTeams from 'pages/teams/NewTeams';
import DashboardPage from 'src/DashboardPage';
import CreateReminder from 'src/components/admin/CreateReminder';
import RevieweeAssignmentList from 'src/components/admin/Cycles/RevieweeAssignments/RevieweeAssignmentList/RevieweeAssignmentList';
import GroupDetail from 'src/components/admin/GroupDetail';
import InsightRetention from 'src/components/admin/Insight-retention/InsightRetention';
import AddEditParticipantTeam from 'src/components/admin/Participant-Team/AddEditParticipantTeam';
import ParticipantTeam from 'src/components/admin/Participant-Team/ParticipantTeam';
import PersonalGrowth from 'src/components/admin/PersonalGrowth/PersonalGrowth';
import AnalyticUsersWrapper from 'src/components/admin/analytic/AnalyticUsers';
import Collaboration from 'src/components/admin/collaboration/collaboration';
import CreateOrganization from 'src/components/admin/culture/CreateOrganization';
import Adoption from 'src/components/admin/insight/Adoption';
import GroupPopulation from 'src/components/admin/insight/GroupPopulation';
import CreateLeague from 'src/components/admin/mission/CreateLeague';
import CreateMission from 'src/components/admin/mission/CreateMission';
import CreateBadge from 'src/components/admin/mission/badge/CreateBadge';
import CreateGroupVisibility from 'src/components/admin/objectives/CreateGroup';
import Objectives from 'src/components/admin/objectives/Objectives';
import Organization from 'src/components/admin/organization/Organization';
import CreateGroupPermission from 'src/components/admin/permissions/CreateGroup';
import Permissions from 'src/components/admin/permissions/Permissions';
import CreateEditRatingTemplate from 'src/components/admin/rating-template/create-update/CreateEditRatingTemplate';
// Rating Template
import HomeRatingTemplate from 'src/components/admin/rating-template/listing/HomeRatingTemplate';
import CreateTrackTemplate from 'src/components/admin/track-template/CreateTrackTemplate';
import TrackTemplate from 'src/components/admin/track-template/TrackTemplate';
import MyProject from 'src/pages/MyProject';

import ChangeAssignment from './components/admin/Cycles/ChangeAssignment/ChangeAssignment';
import ChangeReviewer from './components/admin/Cycles/change-reviewer/ChangeReviewer';
import MissionLeaderboard from './components/admin/mission/MissionLeaderboard';
import SubordinateTree from './pages/SubordinateTree';
import HighlightAbout from './pages/about/HighlightAbout';
import ObjectiveAnalytics from './pages/objectiveAnalytics/ObjectiveAnalytics';
import TeamApprovalDetail from './pages/teams/approval/TeamApprovalDetail';

const PDFResultScoring = React.lazy(() =>
  import('components/formal-review/pdf-result/PDFResultScoring')
);
const PDFResultScoringFromOutsideSource = React.lazy(() =>
  import(
    'components/formal-review/pdf-result/PDFResultScoringFromOutsideSource'
  )
);

function PDFResultScoringComponent() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PDFResultScoring />
    </Suspense>
  );
}

function PDFResultScoringOutsideSource() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PDFResultScoringFromOutsideSource />
    </Suspense>
  );
}

const Routes = () => {
  const { config, organization } = useUser();
  const permission = config?.permissions;
  const isBCA = organization?.identifier == 'bca';
  const isOrgProfileVisible = usePermission('settingsOrganizationProfile');
  const isOrgPerformanceReviewVisible = usePermission(
    'settingsOrganizationPerformanceReview'
  );
  const isOrgOngoingReviewVisible = usePermission(
    'settingsOrganizationOngoingReview'
  );
  const isOrgObjectivesVisible = usePermission(
    'settingsOrganizationObjectives'
  );
  const isOrgGeneralVisible = usePermission('settingsOrganizationGeneral');
  const isOrgFeedsVisible = usePermission('settingsOrganizationFeeds');
  const isOrgBehaviorsVisible = usePermission('settingsOrganizationBehaviors');
  const isOrgAdmVisible = usePermission('settingsOrganizationAdministration');
  const isOrgIntegrationVisible = usePermission(
    'settingsOrganizationIntegration'
  );
  const isOrgMissionsVisible = usePermission('settingsOrganizationMissions');
  const isProfileVisible = usePermission('profileSee');

  const routes = [
    {
      path: [
        '/goals',
        '/goals/:objectiveId',
        '/goals/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: ListOfObjectivePage,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.goalSee,
      title: 'My Goals',
      parent: 'My Objectives',
      exact: true
    },
    {
      path: [
        '/highlighted',
        '/highlighted/:objectiveId',
        '/highlighted/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: HighlightedPage,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.objectiveHighlightSee,
      title: 'Highlights',
      exact: true,
      parent: 'My Objectives'
    },
    {
      path: ['/about/highlighted'],
      component: HighlightAbout,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.objectiveHighlightSee,
      title: 'About Highlighted',
      exact: true
    },
    {
      path: [
        '/tasks',
        '/tasks/:objectiveId',
        '/tasks/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: config?.taskManagementFeature ? NewTasks : ListOfMyTask,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.taskSee,
      title: 'My Tasks',
      parent: 'My Objectives',
      exact: true
    },
    {
      path: '/projects',
      component: MyProject,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.projectSee,
      title: 'My Projects',
      parent: 'My Objectives',
      exact: true
    },
    {
      path: [
        '/projects/:parentId',
        '/projects/:parentId/detail',
        '/projects/:parentId/:objectiveId',
        '/projects/:parentId/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: ProjectGoalsDetail,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.projectSee,
      title: '',
      parent: '',
      exact: true
    },
    {
      path: '/teams',
      component: NewTeams,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.objectiveTeamSee,
      title: 'My Teams',
      parent: 'My Objectives',
      exact: true
    },
    {
      path: [
        '/teams/:teamType(tribe|squad)/:teamId',
        '/teams/:teamType(tribe|squad)/:teamId/:type(edit|addgoal|addtask|addproject)/:objectiveId',
        '/teams/:teamType(tribe|squad)/:teamId/:objectiveId'
      ],
      component: NewDetailTeam,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.objectiveTeamSee,
      title: '',
      exact: true
    },
    {
      path: ['/activities', '/activities/:objectiveId'],
      component: Activities,
      withSidebar: true,
      withHeader: true,
      isVisible:
        permission?.activitiesSelfSee ||
        permission?.activitiesAsReviewerSee ||
        permission?.activitiesAsFollowerSee,
      title: 'Notifications',
      exact: true
    },
    {
      path: [
        '/goals/level/company',
        '/goals/level/:tab(company|manager|directorate)',
        '/goals/level/:tab(company|manager|directorate)/:objectiveId',
        '/goals/level/:tab(company|manager|directorate)/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: CompanyGoals,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.goalCompanySee,
      title: 'Company Goals',
      parent: 'My Objectives',
      exact: true
    },
    {
      path: [
        '/goals/reports/direct',
        '/goals/reports/:tab(direct|indirect)',
        '/goals/reports/:tab(direct|indirect)/:objectiveId',
        '/goals/reports/:tab(direct|indirect)/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: config?.showSubordinatesPage
        ? SubordinatePage
        : DirectReportPage,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.objectiveDirectReportsSee,
      title: config?.showSubordinatesPage ? 'Subordinates' : 'Direct Reports',
      parent: 'My Objectives',
      exact: true
    },
    {
      path: [
        '/subordinates/:tab(direct|indirect)/tree/',
        '/subordinates/:tab(direct|indirect)/tree/:objectiveId',
        '/subordinates/:tab(direct|indirect)/tree/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: SubordinateTree,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.objectiveDirectReportsSee,
      title: config?.showSubordinatesPage
        ? 'Subordinates` Goal Tree'
        : 'Direct Reports` Goal Tree',
      exact: true
    },
    {
      path: [
        '/objective-analytics',
        '/objective-analytics/:objectiveId',
        '/objective-analytics/:type(edit)/:objectiveId'
      ],
      component: ObjectiveAnalytics,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.insightGoalAnalyticSee,
      title: 'Objective Analytics',
      parent: 'My Objectives',
      exact: true
    },
    {
      path: [
        '/goals/others/follower',
        '/goals/others/:tab(follower|reviewer)',
        '/goals/others/:tab(follower|reviewer)/:objectiveId',
        '/goals/others/:tab(follower|reviewer)/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: ListOfOtherGoals,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.objectiveOthersSee,
      title: 'Others',
      parent: 'My Objectives',
      exact: true
    },
    {
      path: ['/timeline'],
      component: HomePageTimeline,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureFeedsTimelineSee,
      title: 'Timeline',
      parent: 'Feeds',
      exact: true
    },
    {
      path: ['/timeline/group/:groupId'],
      component: GroupTimeline,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureFeedsTimelineSee,
      title: 'Timeline',
      parent: '',
      exact: true
    },
    {
      path: ['/timeline/:postId'],
      component: SingleFeedComponent,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureFeedsTimelineSee,
      title: 'Timeline',
      parent: '',
      exact: true
    },
    {
      path: ['/appraisals', '/formal-review', '/appraisals/others'],
      component: FormalReview,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewPerformanceSee,
      title: 'Performance Review',
      parent: 'Performance Review',
      exact: true
    },
    {
      path: '/appraisals/cycles',
      component: Cycles,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewPerformanceCycleSee,
      title: 'Performance Review Cycles',
      parent: 'Performance Review',
      exact: true,
      isReadOnly: permission?.reviewPerformanceCycleSee === 'read_only'
    },
    {
      path: '/feedbacks',
      component: FormalReview,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewFeedbackSee,
      title: 'Feedback page',
      parent: 'Growth',
      exact: true
    },
    {
      path: '/reviews/cycles/user-assignments/:id',
      component: RevieweeAssignmentList,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewPerformanceAssignmentsManage,
      title: '',
      exact: true,
      isReadOnly: permission?.reviewPerformanceCycleSee === 'read_only'
    },
    {
      path: '/cycles/detail-cycle/:id',
      component: CyclesDetails,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewPerformanceCycleSee,
      title: '',
      isReadOnly: permission?.reviewPerformanceCycleSee === 'read_only'
    },
    {
      path: ['/appraisals/cycles/:id/:page', '/appraisals/cycles/create'],
      component: CreateCycle,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewPerformanceCycleSee,
      title: '',
      exact: true,
      isReadOnly: permission?.reviewPerformanceCycleSee === 'read_only'
    },
    {
      path: '/reviews/cycles/:id/change-reviewer',
      component: ChangeReviewer,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewPerformanceCycleSee,
      title: '',
      exact: true,
      isReadOnly: permission?.reviewPerformanceCycleSee === 'read_only'
    },
    {
      path: '/reviews/cycles/change-assignment/:id',
      component: ChangeAssignment,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewPerformanceCycleSee,
      title: '',
      exact: true,
      isReadOnly: permission?.reviewPerformanceCycleSee === 'read_only'
    },
    {
      path: ['/edit-active-cycles/:id'],
      component: EditCycle,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewPerformanceCycleSee,
      title: '',
      isReadOnly: permission?.reviewPerformanceCycleSee === 'read_only'
    },
    {
      path: ['/add-new-reviewees/:id'],
      component: AddNewReviewees,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewPerformanceCycleSee,
      title: ''
    },
    {
      path: '/review/templates/default',
      component: GoalReviewComponent,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewOngoingTemplateSee,
      title: 'Ongoing Review Template',
      parent: 'Performance Review'
    },
    {
      path: '/appraisals/cycles/track-template',
      component: TrackTemplate,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.reviewPerformanceTemplateSee,
      title: 'Track Templates',
      parent: 'Performance Review',
      exact: true,
      isReadOnly: permission?.reviewPerformanceTemplateSee === 'read_only'
    },
    {
      path: [
        '/appraisals/cycles/tracks/:type(edit|create)/:trackType/:id',
        '/appraisals/cycles/tracks/:type(edit|create)/:trackType'
      ],
      component: CreateTrackTemplate,
      withSidebar: true,
      customHeader: true,
      isVisible: permission?.reviewPerformanceTemplateSee,
      title: '',
      isReadOnly: permission?.reviewPerformanceTemplateSee === 'read_only'
    },
    {
      path: '/participants',
      component: ParticipantTeam,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('settingsParticipantsSee'),
      title: 'Survey Participants',
      parent: 'Surveys',
      isReadOnly: usePermission('settingsParticipantsSee') === 'read_only'
    },
    {
      path: '/surveys',
      component: ManageSurveyList,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('surveysSee'),
      title: 'Manage Surveys',
      parent: 'Surveys',
      exact: true,
      isReadOnly: usePermission('surveysSee') == 'read_only'
    },
    {
      path: '/pulse-surveys',
      component: PulseSurveyList,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('surveysAnswersSee'),
      title: 'Pulse Surveys',
      parent: 'Surveys',
      exact: true
    },
    {
      path: '/pulse-survey/:type(answer-later|summary)/:id',
      component: PulseSurveyAnswer,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('surveysAnswersSee'),
      exact: true
    },
    {
      path: '/pulse-survey/:type(answer-now)/:id',
      component: PulseSurveyAnswer,
      withSidebar: false,
      withHeader: true,
      isVisible: usePermission('surveysAnswersSee'),
      exact: true
    },
    {
      path: '/create-pulse-survey',
      component: PulseSurveyComposer,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('surveysSee'),
      title: '',
      isReadOnly: usePermission('surveysSee') == 'read_only'
    },
    {
      path: [
        '/edit-pulse-survey/:id',
        '/duplicate-pulse-survey/:id',
        '/survey-details/:id'
      ],
      component: PulseSurveyComposer,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('surveysSee'),
      title: '',
      isReadOnly: usePermission('surveysSee') == 'read_only'
    },
    {
      path: [
        '/objectives/need-response',
        '/objectives/need-response/:objectiveId',
        '/objectives/need-response/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: NeedResponse,
      withSidebar: true,
      withHeader: true,
      isVisible:
        permission?.objectiveNeedResponseSee &&
        !config.separateObjectiveOngoingReviewPage,
      title: 'Need Response Objectives',
      parent: 'Need Responses',
      exact: true
    },
    {
      path: [
        '/need-response/review',
        '/need-response/review/:objectiveId',
        '/need-response/review/:type(edit|addgoal|addtask|addproject|update)/:objectiveId',
        '/need-response/review/others/:userId'
      ],
      component: GiveReview,
      withSidebar: true,
      withHeader: true,
      isVisible:
        permission?.objectiveNeedResponseSee &&
        config.separateObjectiveOngoingReviewPage,
      title: 'Give Review',
      parent: 'Need Responses',
      exact: true
    },
    {
      path: [
        '/need-response/comment',
        '/need-response/comment/:objectiveId',
        '/need-response/comment/:type(edit|addgoal|addtask|addproject|update)/:objectiveId',
        '/need-response/comment/others/:userId'
      ],
      component: GiveComment,
      withSidebar: true,
      withHeader: true,
      isVisible:
        permission?.objectiveNeedResponseSee &&
        config.separateObjectiveOngoingReviewPage,
      title: 'Give Comment',
      parent: 'Need Responses',
      exact: true
    },
    {
      path: [
        '/approval',
        '/approval/:objectiveId',
        '/approval/:type(edit|addgoal|addtask|addproject|update)/:objectiveId',
        '/approval/others/:userId',
        '/approval/others/:userId/:objectiveId',
        '/approval/others/:userId/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: Approval,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.approvalSee,
      title: 'Approval',
      parent: 'Need Responses',
      exact: true
    },
    {
      path: [
        '/objective-overview',
        '/objective-overview/:typeObj(owned|approved|updated|analytics)',
        '/objective-overview/:typeObj(owned|approved|updated)/:departmentId',
        '/objective-overview/:typeObj(analytics)/:objectiveId',
        '/objective-overview/:typeObj(analytics)/:type(edit)/:objectiveId'
      ],
      component: ObjectiveOverview,
      withSidebar: true,
      withHeader: true,
      isVisible:
        permission?.insightGoalOwnershipSee ||
        permission?.insightGoalApprovalSee ||
        permission?.insightGoalUpdateSee ||
        permission?.insightGoalAnalyticSee,
      title: 'Objective Overview',
      parent: 'Insights',
      exact: true
    },
    {
      path: '/overview',
      component: Overview,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.insightPerformanceOverviewSee,
      title: 'Performance Overview',
      parent: 'Insights'
    },
    {
      path: '/ratings',
      component: AnalyticUsersWrapper,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.insightPerformanceRatingSee,
      title: 'Performance Rating',
      parent: 'Insights'
    },
    {
      path: '/talent-mapping',
      component: TalentMapping,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.insightTalentMappingSee,
      title: 'Talent Mapping',
      parent: 'Insights'
    },
    {
      path: '/adoption',
      component: Adoption,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureInsightAdoptionsSee,
      title: 'Adoptions',
      parent: 'Insights'
    },
    {
      path: '/retention',
      component: InsightRetention,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureInsightRetentionsSee,
      title: 'Retentions',
      parent: 'Insights'
    },
    {
      path: '/leaderboard-user',
      component: LeaderboardUser,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureInsightLeaderboardSee,
      title: 'Leaderboard Users',
      parent: 'Insights'
    },
    {
      path: '/leaderboard-content',
      component: LeaderboardContent,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureInsightLeaderboardSee,
      title: 'Leaderboard Contents',
      parent: 'Insights'
    },
    {
      path: '/group-population',
      component: GroupPopulation,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureInsightGroupSee,
      title: 'Group Population',
      parent: 'Insights'
    },
    {
      path: '/group-details',
      component: GroupDetail,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureInsightGroupSee,
      title: 'Group Details',
      parent: 'Insights'
    },
    {
      path: '/email-reminder/create',
      component: CreateReminder,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureReminderCreate,
      title: 'Create Reminder',
      parent: 'Email Reminder',
      isReadOnly: permission?.cultureReminderCreate === 'read_only'
    },
    {
      path: '/hr/personal-growth',
      component: PersonalGrowth,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.insightPersonalGrowthsSee,
      title: 'Personal Growths',
      parent: 'HR Training'
    },
    {
      path: '/hr/collaboration',
      component: Collaboration,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.insightCollaborationsSee,
      title: 'Collaborations',
      parent: 'HR Training'
    },
    {
      path: '/create-organization',
      component: CreateOrganization,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.settingsOrganizationCreate,
      title: 'Create Organization',
      parent: '',
      isReadOnly: permission?.settingsOrganizationCreate === 'read_only'
    },
    {
      path: '/organizations',
      component: ListOrganizations,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('settingsOrganizationManage'),
      title: 'Manage Organizations',
      parent: 'Settings',
      exact: true,
      isReadOnly: usePermission('settingsOrganizationManage') === 'read_only'
    },
    {
      path: ['/users', '/users/add', '/users/edit/:userId'],
      component: ListUsersWrapper,
      withSidebar: true,
      customHeader: true,
      isVisible: usePermission('settingsUsersSee'),
      title: 'Manage Users',
      parent: 'Settings',
      exact: true,
      isReadOnly: usePermission('settingsUsersSee') === 'read_only'
    },
    {
      path: ['/group/behavior-model'],
      component: LandingBehaviorModelPage,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('settingsBehaviorGroupsSee'),
      title: 'Behavior Model',
      parent: 'Settings',
      exact: true,
      isReadOnly: usePermission('settingsBehaviorGroupsSee') === 'read_only'
    },
    {
      path: [
        '/group/behavior-model/:action',
        '/group/behavior-model/:action/:id'
      ],
      component: CreateEditBehaviorModelPage,
      withSidebar: true,
      customHeader: true,
      isVisible: usePermission('settingsBehaviorGroupsSee'),
      exact: true,
      title: 'Behavior Model / Create Group',
      isReadOnly: usePermission('settingsBehaviorGroupsSee') === 'read_only'
    },
    {
      path: ['/values', '/values/:state'],
      component: OrganizationValuesCompetencies,
      withSidebar: true,
      withHeader: true,
      exact: true,
      isVisible: usePermission('settingsValuesSee'),
      title: 'Values',
      parent: 'Settings',
      isReadOnly: usePermission('settingsValuesSee') === 'read_only'
    },
    {
      path: ['/competencies', '/competencies/:state'],
      component: OrganizationValuesCompetencies,
      withSidebar: true,
      withHeader: true,
      exact: true,
      isVisible: usePermission('settingsCompetenciesSee'),
      title: 'Competencies',
      parent: 'Settings',
      isReadOnly: usePermission('settingsCompetenciesSee') === 'read_only'
    },
    {
      path: '/add-competencies-model',
      component: OrganizationValuesCompetencies,
      withSidebar: true,
      withHeader: true,
      exact: true,
      isVisible: usePermission('settingsCompetenciesSee'),
      title: 'Add New Model',
      isReadOnly: usePermission('settingsCompetenciesSee') === 'read_only'
    },
    {
      path: '/add-values-model',
      component: OrganizationValuesCompetencies,
      withSidebar: true,
      withHeader: true,
      exact: true,
      isVisible: usePermission('settingsValuesSee'),
      title: `Create ${getObjectiveLocale('Values')} Model`,
      isReadOnly: usePermission('settingsValuesSee') === 'read_only'
    },
    {
      path: '/edit-competencies-model/:id',
      component: OrganizationValuesCompetencies,
      withSidebar: true,
      withHeader: true,
      exact: true,
      isVisible: usePermission('settingsCompetenciesSee'),
      title: 'Edit Current Model',
      isReadOnly: usePermission('settingsCompetenciesSee') === 'read_only'
    },
    {
      path: '/edit-values-model/:id',
      component: OrganizationValuesCompetencies,
      withSidebar: true,
      withHeader: true,
      exact: true,
      isVisible: usePermission('settingsValuesSee'),
      title: 'Edit Current Model',
      isReadOnly: usePermission('settingsValuesSee') === 'read_only'
    },
    {
      path: '/objectives/settings',
      component: Objectives,
      withSidebar: true,
      withHeader: true,
      isVisible:
        permission?.settingsObjectivesGeneral ||
        permission?.settingsObjectivesWeightPercentage ||
        permission?.settingsObjectivesWeightType ||
        permission?.settingsObjectivesVisibility,
      title: 'Objectives',
      parent: 'Settings',
      exact: true
    },
    {
      path: '/group/performance-profile',
      component: PerformanceProfile,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.settingsReviewGroupsSee,
      title: 'Performance Profile',
      parent: 'Settings',
      exact: true,
      isReadOnly: permission?.settingsReviewGroupsSee === 'read_only'
    },
    {
      path: [
        '/group/performance-profile/:type(edit|create)/:id',
        '/group/performance-profile/:type(edit|create)'
      ],
      component: CreateEditGroupPerformanceProfile,
      withSidebar: true,
      customHeader: true,
      exact: true,
      isVisible: permission?.settingsReviewGroupsSee,
      title: 'Performance Profile',
      isReadOnly: permission?.settingsReviewGroupsSee === 'read_only'
    },
    {
      path: '/labels',
      component: OrgDetailsLabels,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureSettingsLabelsSee,
      title: 'Labels',
      parent: 'Settings',
      isReadOnly: permission?.cultureSettingsLabelsSee === 'read_only'
    },
    {
      path: '/missions',
      component: MissionLeaderboard,
      withSidebar: true,
      withHeader: true,
      isVisible:
        permission?.cultureSettingsMissionsSee ||
        permission?.cultureSettingsLeaguesSee ||
        permission?.cultureSettingsBadgeSee,
      title: 'Mission, Leaderboard, Badge',
      parent: 'Settings',
      exact: true,
      isReadOnly: permission?.cultureSettingsMissionsSee === 'read_only'
    },
    {
      path: ['/missions/create-league', '/missions/edit-league/:id'],
      component: CreateLeague,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureSettingsLeaguesSee,
      title: 'Create League',
      parent: '',
      exact: true,
      isReadOnly: permission?.cultureSettingsLeaguesSee === 'read_only'
    },
    {
      path: ['/missions/create-mission', '/missions/edit/:id'],
      component: CreateMission,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureSettingsMissionsSee,
      title: 'Create Mission',
      parent: '',
      exact: true,
      isReadOnly: permission?.cultureSettingsMissionsSee === 'read_only'
    },
    {
      path: ['/missions/create-badge', '/missions/edit-badge/:id'],
      component: CreateBadge,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.cultureSettingsMissionsSee,
      title: 'Create Badge',
      parent: '',
      exact: true,
      isReadOnly: permission?.cultureSettingsMissionsSee === 'read_only'
    },
    {
      path: ['/group-permissions'],
      component: Permissions,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('settingsPermissionsSee'),
      title: 'Permissions',
      parent: 'Settings',
      exact: true,
      appType: 'performance',
      isReadOnly: usePermission('settingsPermissionsSee') === 'read_only'
    },
    {
      path: [
        '/group-permissions/:type(edit|create)/:id',
        '/group-permissions/:type(edit|create)'
      ],
      component: CreateGroupPermission,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('settingsPermissionsSee'),
      title: 'Create Permissions Group',
      parent: '',
      exact: true,
      appType: 'performance'
    },
    {
      path: [
        '/objective-visibility/:type(edit|create)/:id',
        '/objective-visibility/:type(edit|create)'
      ],
      component: CreateGroupVisibility,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.settingsObjectivesVisibility,
      title: 'Objective Visibility Group Configuration',
      parent: '',
      exact: true,
      appType: 'performance',
      isReadOnly: permission?.settingsObjectivesVisibility === 'read_only'
    },
    {
      path: '/reviews/rating-template',
      component: HomeRatingTemplate,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.settingsRatingTemplateSee,
      exact: true,
      title: 'Rating Template',
      parent: 'Settings',
      isReadOnly: permission?.settingsRatingTemplateSee === 'read_only'
    },
    {
      path: ['/organization', '/organization/:orgId/:type'],
      component: Organization,
      withSidebar: true,
      withHeader: true,
      isVisible:
        isOrgProfileVisible ||
        isOrgPerformanceReviewVisible ||
        isOrgOngoingReviewVisible ||
        isOrgObjectivesVisible ||
        isOrgGeneralVisible ||
        isOrgFeedsVisible ||
        isOrgBehaviorsVisible ||
        isOrgAdmVisible ||
        isOrgIntegrationVisible ||
        isOrgMissionsVisible,
      title: 'Organization',
      parent: 'Settings',
      exact: true
    },
    {
      path: [
        '/reviews/rating-template/:action/:type',
        '/reviews/rating-template/:action/:type/:id',
        '/reviews/rating-template/:action/:type/:id/:state'
      ],
      component: CreateEditRatingTemplate,
      customHeader: true,
      withSidebar: true,
      isVisible: permission?.settingsRatingTemplateSee,
      exact: true,
      title: 'Goal ongoing scoring',
      isReadOnly: permission?.settingsRatingTemplateSee == 'read_only'
    },
    {
      path: ['/add-participant', '/edit-participant/:id'],
      component: AddEditParticipantTeam,
      withSidebar: true,
      withHeader: true,
      isVisible: usePermission('settingsParticipantsSee'),
      title: ''
    },
    {
      path: [
        '/users/:userId/placement/:placementId',
        '/users/:userId/placement/:placementId/objectives/:objectiveId',
        '/users/:userId/placement/:placementId/objectives/:type(edit|addgoal|addtask|addproject|update)/:objectiveId',
        '/users/:userId/placement/:placementId/:values',
        '/users/:userId/placement/:placementId/:values/:type',
        '/users/:userId/placement/:placementId/:values/:type/:behaviorId'
      ],
      component: UserProfile,
      withSidebar: true,
      withHeader: true,
      isVisible: isBCA ? permission?.profileSee : isProfileVisible,
      title: 'Profile',
      exact: true,
      appType: ['performance', 'culture']
    },
    {
      path: [
        '/reviews/assignments/:assignmentId',
        '/reviews/assignments/:assignmentId/:objectiveId',
        '/reviews/assignments/:assignmentId/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: FormalReviewScoring,
      withSidebar: true,
      withHeader: false,
      isVisible: permission?.reviewPerformanceSee,
      title: '',
      exact: true
    },
    {
      path: '/appraisals/cycle/:id/calibration',
      component: NewCalibration,
      withSidebar: true,
      withHeader: false,
      isVisible: permission?.reviewPerformanceSee,
      title: ''
    },
    {
      path: [
        '/reviews/result/target/:id/placement/:placementId/cycle/:cycleId',
        '/reviews/result/target/:id/placement/:placementId/cycle/:cycleId/:objectiveId',
        '/reviews/result/target/:id/placement/:placementId/cycle/:cycleId/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: FormalReviewResult,
      withSidebar: true,
      withHeader: false,
      isVisible: permission?.reviewPerformanceSee,
      title: '',
      exact: true
    },
    {
      path: [
        '/appraisals/cycle/:cycleId/assignment/:assignmentId',
        '/appraisals/cycle/:cycleId/assignment/:assignmentId/:objectiveId',
        '/appraisals/cycle/:cycleId/assignment/:assignmentId/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: AssignmentReviewResult,
      withSidebar: true,
      withHeader: false,
      isVisible: permission?.reviewPerformanceSee,
      title: '',
      exact: true
    },
    {
      path: ['/appraisals/cycle/:id/others'],
      component: TeamResult,
      withSidebar: true,
      withHeader: false,
      isVisible: permission?.reviewPerformanceSee,
      title: ''
    },
    {
      path: [
        '/reviews/formal-review/team-review/:assignmentId',
        '/reviews/formal-review/team-review/:assignmentId/:objectiveId',
        '/reviews/formal-review/team-review/:assignmentId/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: TeamReviewScoring,
      withSidebar: true,
      withHeader: false,
      isVisible: permission?.reviewPerformanceSee,
      title: '',
      exact: true
    },
    {
      path: [
        '/reviews/formal-review/peer-review/:assignmentId',
        '/reviews/formal-review/peer-review/:assignmentId/:objectiveId',
        '/reviews/formal-review/peer-review/:assignmentId/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: TeamReviewScoring,
      withSidebar: true,
      withHeader: false,
      isVisible: permission?.reviewPerformanceSee,
      title: ''
    },
    {
      path: '/pdf/result/:currentTab/target/:id/placement/:placementId/cycle/:cycleId/download_pdf',
      component: PDFResultScoringOutsideSource,
      withHeader: false,
      withSidebar: false,
      isVisible: permission?.reviewPerformanceSee,
      title: ''
    },
    {
      path: '/pdf/result/:currentTab/target/:id/placement/:placementId/cycle/:cycleId',
      component: PDFResultScoringComponent,
      withHeader: false,
      withSidebar: false,
      isVisible: permission?.reviewPerformanceSee,
      title: ''
    },
    {
      path: [
        '/tree/:parentId',
        '/tree/:parentId/:objectiveId',
        '/tree/:parentId/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: GoalTree,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.goalSee,
      title: 'Goal Tree',
      exact: true
    },
    {
      path: '/create-objective/:type(goal|task|team)',
      component: CreateObjectivePage,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.goalCreate || permission?.taskCreate,
      title: ''
    },
    {
      path: [
        '/explorer',
        '/explorer/:objectiveId',
        '/explorer/:type(edit|addgoal|addtask|addproject|update)/:objectiveId'
      ],
      component: Explorer,
      withSidebar: true,
      withHeader: true,
      isVisible: permission?.objectiveExplorerSee,
      title: 'Explorer',
      exact: true
    },
    {
      path: [
        '/approval/:approvalId/team/:teamId',
        '/approval/:approvalId/team/:teamId/parent/:parentId/',
        '/approval/:approvalId/team/:teamId/parent/:parentId/objectives/:type(edit|addgoal|addtask|addproject|update)/:objectiveId',
        '/approval/:approvalId/team/:teamId/parent/:parentId/objectives/:objectiveId'
      ],
      component: TeamApprovalDetail,
      withSidebar: true,
      withHeader: true,
      isVisible: true,
      title: '',
      exact: true
    },
    {
      path: ['/downloads', '/share/missions'],
      component: LandingPage,
      withSidebar: true,
      withHeader: false,
      isVisible: true,
      exact: true,
      title: 'Download'
    },
    {
      path: '/posts',
      component: LandingPageSharePost,
      withSidebar: true,
      withHeader: false,
      isVisible: true,
      title: 'Share post'
    },
    {
      path: [
        '/progress-overtime/:id',
        '/progress-overtime/:id/:type(edit)/:objectiveId',
        '/progress-overtime/:objectiveId/details'
      ],
      component: ProgressOvertime,
      withSidebar: true,
      withHeader: false,
      isVisible: true,
      title: 'Progress Overtime',
      exact: true
    },
    {
      path: '/share/survey',
      component: SurveyRedirect,
      exact: true,
      isVisible: true
    }
  ];

  let filteredRoute = routes.filter((route) => route.isVisible);

  let data = [
    {
      component: DashboardPage,
      routes: filteredRoute
    }
  ];

  return data;
};

export default Routes;

import React from 'react';

import dayjs from 'dayjs';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SingleCalendar from 'components/shared/Calendar';

const StartAndDueDate = ({
  cloneObj,
  setCloneObj,
  goalPermissionStartDate = false,
  getDueDate
}) => {
  const handleChangeDate = async (date) => {
    const dueDate =
      typeof date !== 'string'
        ? dayjs(date[1]).toISOString()
        : await getDueDate(date);

    if (typeof date === 'string') {
      setCloneObj({
        ...cloneObj,
        startDate: dayjs(date).startOf('day').toISOString(),
        dueDate: dayjs(dueDate).endOf('day').toISOString()
      });
    } else {
      setCloneObj({
        ...cloneObj,
        dueDate: dayjs(dueDate).endOf('day').toISOString(),
        startDate: dayjs(date[0]).startOf('day').toISOString()
      });
    }
  };

  return (
    <div className="flex items-center mt-[16px] modal-calendar-clone">
      <div className="w-[50%] mr-[16px]">
        <p className="typography-h100 mb-[8px]">
          {getObjectiveLocale('Start Date')}
        </p>
        <SingleCalendar
          handleChangeCalendar={(date) => handleChangeDate(date)}
          defaultValue={dayjs(cloneObj?.startDate)}
          type="startDate"
          customBox={`border-solid border-n-500 border rounded-[4px]
            ${
              !goalPermissionStartDate
                ? 'text-n-600 bg-n-300 whitespace-nowrap'
                : ''
            }`}
          permissionStartDate={goalPermissionStartDate}
        />
      </div>
      <div className="w-[50%]">
        <p className="typography-h100 mb-[8px]">
          {getObjectiveLocale('Due Date')}
        </p>
        <SingleCalendar
          defaultValue={dayjs(cloneObj?.dueDate)}
          type="dueDate"
          withTitle={false}
          customBox="rounded-[4px] cursor-pointer text-n-600 bg-n-300 whitespace-nowrap"
          permissionDueDate={false}
        />
      </div>
    </div>
  );
};

export default StartAndDueDate;

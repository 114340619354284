import React, { useEffect, useRef, useState } from 'react';

import {
  UserSuggestionProvider,
  useUserSuggestion
} from 'context/UserSuggestionContext';
import useDebounce from 'hooks/useDebounce';
import { getExtraInformation, getObjectiveLocale } from 'utils/HelperUtils';

import AvatarGroup from 'components/design-system/avatar-group/AvatarGroup';
import InlineDialog from 'components/design-system/inline-dialog/InlineDialog';
import SingleUser from 'components/design-system/single-user/SingleUser';
import FloatingComponent from 'components/objectives/compact-objective/fragments/FloatingComponent';
import ConditionalWrapper from 'components/shared/ConditionalWrapper';
import ContentWrapper from 'components/sidebar/sidebar-create/ContentWrapper';

import DefaultObjectiveProfile from 'assets/v2/ico-default-user';

const Avatar = ({ position, selectedValue, users, permission }) => {
  return selectedValue.length === 1 ? (
    <SingleUser userData={users[0]} isDisabled={!permission} />
  ) : (
    selectedValue.length > 1 && (
      <AvatarGroup
        avatars={users}
        size="24"
        position={position}
        disabled={!permission}
      />
    )
  );
};

const Trigger = ({
  type,
  selectedValue,
  users,
  permission,
  placeholderWording
}) => {
  switch (type) {
    case 'assignee':
      return (
        <div className="py-[8px] rounded-full px-[8px] hover:bg-base-30024">
          {selectedValue?.length > 0 ? (
            <Avatar
              selectedValue={selectedValue}
              users={users}
              permission={permission}
            />
          ) : (
            <div className="text-n-600">
              <img
                className="selected-image mr-[8px] w-[24px] h-[24px]"
                src={DefaultObjectiveProfile}
              />
              {permission
                ? getObjectiveLocale(placeholderWording || 'Select User')
                : getObjectiveLocale('No reviewer')}
            </div>
          )}
        </div>
      );
    case 'follower':
      return (
        <ContentWrapper
          withInfo
          iconName="icon-people_outline"
          customWrapperClass="px-[24px] py-[12px] items-center hover:bg-base-30024 justify-between group"
          infoWording="Those who need to be informed about the goal"
        >
          <div data-cy="follower-status-inline">
            {selectedValue?.length > 0 ? (
              <Avatar
                position="top-right"
                selectedValue={selectedValue}
                users={users}
                permission={permission}
              />
            ) : (
              <p className="text-n-600 typography-paragraph">
                {permission
                  ? getObjectiveLocale(placeholderWording || 'Add Follower')
                  : getObjectiveLocale('No follower')}
              </p>
            )}
          </div>
        </ContentWrapper>
      );
  }
};

const InlineDialogSelectMultipleUser = ({
  objectiveValue,
  selectedValue,
  excludeValue,
  customTriggerComponent,
  handleChange,
  header,
  type,
  role,
  permission = true,
  isTop,
  position = 'right',
  vPosition = 'bottom',
  placeholderWording,
  dataCy,
  customClass,
  triggerWrapperClass = '',
  dialogWrapperClass = '',
  dialogWidth = 'w-[350px]',
  beforeTrigger,
  useTrigger = true,
  onCloseDialog,
  extendedRoleType = null,
  additionalQuery,
  useFloating = false,
  presetSuggestions = []
}) => {
  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);
  const [isLoading, setIsLoading] = useState(false);
  const { suggestion, hasMore, getSuggestionData, appendSuggestionData } =
    useUserSuggestion();

  const isFirstRender = useRef(true);

  const options = (
    presetSuggestions?.length > 0 ? presetSuggestions : suggestion
  )?.map((value) => {
    return {
      id: value.id,
      image: value.profilePicture,
      avatarName: value.name,
      text: value.name,
      subText: getExtraInformation(value?.title, value?.subtitle),
      customClass: dialogWidth,
      onClick: () => _handleChange(value),
      selected: selectedValue?.map(({ userId }) => userId).includes(value.id),
      dataCy: `user-${value.id}`
    };
  });

  const selectedData = selectedValue?.map((value) => {
    return {
      id: value?.user?.id,
      value: value?.user?.name,
      image: value?.user?.profilePicture,
      onRemove: () => _handleChange(value.user)
    };
  });

  const users = selectedValue?.map(({ user }) => {
    return {
      type: 'circle',
      name: user?.name,
      src: user?.profilePicture,
      tooltip: true
    };
  });

  const objectiveType = objectiveValue
    ? objectiveValue?.isProject
      ? 'project'
      : objectiveValue?.type
    : null;

  const _handleChange = (sel) => {
    let newSelectedValue = [...selectedValue];
    if (!newSelectedValue.some((value) => value.userId === sel.id)) {
      newSelectedValue.push({
        user: sel,
        role: role,
        extendedRole: extendedRoleType,
        userId: sel.id,
        visible: true,
        manager: sel.manager
      });
    } else {
      newSelectedValue = newSelectedValue.filter(
        (newSelected) => newSelected.userId !== sel.id
      );
    }
    handleChange(newSelectedValue, role, extendedRoleType);
  };

  const onScroll = (e) => {
    const loadMore = async () => {
      const query = {
        q: search,
        exclude: excludeValue,
        role: role,
        ...(objectiveValue && { type: objectiveType }),
        ...(additionalQuery && { ...additionalQuery })
      };
      await appendSuggestionData(query);
    };

    let target = e.target;
    let cond = target.scrollTop + target.clientHeight == target.scrollHeight;

    if (hasMore && cond && presetSuggestions?.length === 0) {
      loadMore();
    }
  };

  const _getSuggestionData = async () => {
    setIsLoading(true);
    const query = {
      q: search,
      exclude: excludeValue,
      role: role,
      ...(objectiveValue && { type: objectiveType }),
      ...(additionalQuery && { ...additionalQuery })
    };
    await getSuggestionData(query);
    setIsLoading(false);
  };

  const onOpenDialog = () => {
    setSearch('');
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      presetSuggestions?.length === 0 && _getSuggestionData();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    presetSuggestions?.length === 0 && _getSuggestionData();
  }, []);

  return (
    <>
      <InlineDialog
        type="multiple"
        selectedData={selectedData}
        header={getObjectiveLocale(header)}
        search={(e) => setSearch(e.target.value)}
        isBlock
        customClass={`${dialogWrapperClass}`}
      >
        {useTrigger && (
          <InlineDialog.Trigger
            disabled={!permission}
            dataCy={dataCy}
            customClass={triggerWrapperClass}
            beforeTrigger={beforeTrigger}
          >
            {customTriggerComponent ? (
              customTriggerComponent(users)
            ) : (
              <Trigger
                type={type}
                selectedValue={selectedValue}
                users={users}
                permission={permission}
                placeholderWording={placeholderWording}
              />
            )}
          </InlineDialog.Trigger>
        )}
        <MenuItems
          useFloating={useFloating}
          isTop={isTop}
          options={options}
          onOpenDialog={onOpenDialog}
          onScroll={onScroll}
          customClass={customClass}
          dialogWidth={dialogWidth}
          isLoading={isLoading}
          useTrigger={useTrigger}
          onCloseDialog={onCloseDialog}
          vPosition={vPosition}
          position={position}
        />
      </InlineDialog>
    </>
  );
};

const MenuItems = ({
  useFloating,
  isTop,
  options,
  onOpenDialog,
  onScroll,
  customClass,
  dialogWidth,
  isLoading,
  useTrigger,
  onCloseDialog,
  vPosition,
  position
}) => {
  return (
    <ConditionalWrapper
      condition={useFloating}
      wrapper={(children) => (
        <FloatingComponent customContentHeight={480}>
          {children}
        </FloatingComponent>
      )}
    >
      <InlineDialog.MenuItems
        isTop={isTop}
        vPosition={vPosition}
        position={position}
        options={options}
        onOpenDialog={onOpenDialog}
        onScroll={onScroll}
        customClass={`${customClass ? customClass : ''} ${dialogWidth}`}
        isLoading={isLoading}
        useTrigger={useTrigger}
        onCloseDialog={onCloseDialog}
      />
    </ConditionalWrapper>
  );
};

const WrapperComponent = (props) => (
  <UserSuggestionProvider>
    <InlineDialogSelectMultipleUser {...props} />
  </UserSuggestionProvider>
);

export default WrapperComponent;

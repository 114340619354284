import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { format } from 'timeago.js';

const LastUpdated = React.memo(({ task }) => {
  const { updatedAt, valueLastUpdatedAt } = task || {};

  if (isEmpty(updatedAt)) return null;

  return (
    <p className="typography-h100 text-n-800">{format(valueLastUpdatedAt)}</p>
  );
});

LastUpdated.displayName = 'LastUpdated';

export default LastUpdated;

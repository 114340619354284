import React from 'react';

import Label from 'components/shared/Label/Label';
import TruncateParagraph from 'src/components/shared/TruncateParagraph';
import { getObjectiveLocale } from 'src/utils/HelperUtils';

function UserInfoComponent(props) {
  const name = props.name;
  const description = props.description;
  const totalObjective = props.totalObjective || '';

  return (
    <div className={`userbar-info ${description === '' ? 'no-title' : ''}`}>
      <TruncateParagraph
        position="bottom"
        useMaxWidth={true}
        className="typography-h400 text-n-900"
        align="left"
      >
        {name}
      </TruncateParagraph>
      {totalObjective !== '' && (
        <div className="userbar-total-objective">
          <Label>{`${totalObjective} ${getObjectiveLocale(
            'Tasks & Goals'
          )}`}</Label>
        </div>
      )}
      {description !== '' && (
        <TruncateParagraph
          position="bottom"
          className="typography-h100 text-n-800 mt-[4px]"
          align="left"
        >
          {description}
        </TruncateParagraph>
      )}
      {props.children}
    </div>
  );
}

export default UserInfoComponent;

import React from 'react';

import { format } from 'timeago.js';

import useFilter from 'hooks/useFilter';
import { getCssVariableValue } from 'utils/HelperUtils';

import Badge from 'components/shared/Badge';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

function LastUpdated({ task, isProject }) {
  const filter = useFilter(
    (state) =>
      state.filterById?.[isProject ? 'projectDetailBoard' : 'tasksFilterBoard']
  );

  const { valueLastUpdatedAt, childrenCount, priority } = task;

  return (
    <TooltipContainer
      show
      text={`Last updated: ${format(valueLastUpdatedAt)}`}
      classContainer={`mt-[8px] ${
        childrenCount > 0 || (filter?.group === 'phase' && !priority)
          ? 'mr-[8px]'
          : ''
      } whitespace-nowrap`}
      position="bottom"
    >
      <Badge
        content={format(valueLastUpdatedAt)}
        colorHex={getCssVariableValue('--n-800')}
        bgColorHex={getCssVariableValue('--n-300')}
        iconLeft="icon-history"
      />
    </TooltipContainer>
  );
}

export default LastUpdated;

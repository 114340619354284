import React, { useEffect, useRef, useState } from 'react';

import classSetter from 'classnames';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import {
  getUserActivationCMSCulture,
  getUserInfosCMSCulture
} from 'client/adminClient.js';
import { getNumberLocale, getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import { listOptionDate } from 'components/shared/Calendar/ListAntdDateHelper';
import Checkbox from 'components/shared/Checkbox';
import Loader from 'components/shared/LoadingComponent';
import LineChart from 'components/shared/charts/LineChart';

import {
  InsightCalendar,
  _dailyHandleChartView,
  _monthlyHandleChartView,
  _weeklyHandleChartView,
  paramsHelperHandleChartView
} from '../Insight-retention/InsightHelpers';
import './Adoption.scss';
import CurrentChartAdoption from './CurrentChartAdoption';

const Adoption = ({ route: { title } }) => {
  const [chartAdoption, setChartAdoption] = useState({});
  const [selectedDate, setSelectedDate] = useState([
    dayjs().startOf('week'),
    dayjs().endOf('week')
  ]);
  const [intervalButton, setIntervalButton] = useState(
    paramsHelperHandleChartView(true, false, false, 'day')
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isNumber, setIsNumber] = useState(true);
  // user info key
  const [userInfoState, setUserInfoState] = useState({});
  const [isViewFilterDropdownShown, setisViewFilterDropdownShown] =
    useState(false);
  // add to compare
  const [currentChartCategories, setCurrentChartCategories] = useState([]);
  const [arrayOfFilter, setArrayOfFilter] = useState([]);
  const [isItemComparedDropdownShown, setIsItemComparedDropdownShown] =
    useState(false);
  const [viewFilterState, setViewFilterState] = useState('All');
  const [hideAllUsersSeries, setHideAllUsersSeries] = useState(false);
  const [growth, setGrowth] = useState(null);
  const [activation, setActivation] = useState(null);
  const [registered, setRegistered] = useState(null);
  const [cmsAnalyticData, setCmsAnalyticData] = useState([]);
  const [pendingActivation, setPendingActivation] = useState(null);
  const showViewFilterDropdown = () =>
    setisViewFilterDropdownShown(!isViewFilterDropdownShown);

  const handleCancel = () => {
    setIsItemComparedDropdownShown(false);
    setisViewFilterDropdownShown(false);
  };

  const seriesGenerator = (data, isNumberCurr, name) => {
    const series = [];
    let categories = [];

    Object.keys(data[0]).map((name) => {
      if (isNumberCurr) {
        if (
          name !== 'timeframe' &&
          name !== 'percentage' &&
          name !== 'registered'
        ) {
          Object.keys(data[0][name]).map((item, idx) => {
            series.push({
              name: item.replace('_', ' '), //`All ${name} users`,
              data: [],
              key: name,
              keyName: Object.keys(data[0][name])[idx]
            });
          });
        }
      } else {
        if (
          name !== 'timeframe' &&
          name === 'percentage' &&
          name !== 'registered'
        ) {
          Object.keys(data[0][name]).map((item, idx) => {
            series.push({
              name: item.replace('_', ' '), //'All active users',
              data: [],
              key: name,
              keyName: Object.keys(data[0][name])[idx]
            });
          });
        }
      }
    });

    series.map((item) => {
      data.map((item2) => {
        item.data.push({
          x: new Date(item2.timeframe.start).getTime(),
          y: isNumberCurr
            ? item2[item.key][item.keyName]
            : parseInt(item2[item.key][item.keyName].toFixed(2)),
          timeFrame: item2.timeframe,
          percentage: isNumberCurr
            ? parseInt(item2['percentage'][item.keyName].toFixed(2))
            : ''
        });
      });

      categories.push(item.keyName);
    });

    return {
      series,
      categories
    };
  };

  const funcHideAllUsersSeries = () => {
    currentChartCategories.shift();
    let chartScope = chart?.current?.getChart();
    chartScope?.series?.[0]?.hide();

    setCurrentChartCategories(currentChartCategories);
    setHideAllUsersSeries(true);
  };

  const handleSelectType = (e) => {
    setIsNumber(!isNumber);
    setIsLoading(true);
    // getAdoptionData(periodBegin, periodEnd, intervalButton.growth.selected, isNumber, arrayOfFilter);
  };

  const handleShowItemComparedDrowdown = (param, e) => {
    e.preventDefault();
    if (param === 'growth') {
      setIsItemComparedDropdownShown(true);
    }
  };

  const removeFilter = (index, textLabel) => {
    if (textLabel === 'allUsers') {
      funcHideAllUsersSeries();
    } else {
      let timeUnit = intervalButton.growth.selected;
      let selectedIndex = hideAllUsersSeries ? index : index - 1;
      let arrayOfFilterCurr = arrayOfFilter;

      arrayOfFilterCurr.splice(selectedIndex, 1);
      setArrayOfFilter(arrayOfFilterCurr);
      setIsLoading(true);

      getAdoptionData(
        selectedDate[0],
        selectedDate[1],
        timeUnit,
        isNumber,
        arrayOfFilter
      );
    }
  };

  const handleQueryGrowth = (range) => {
    setSelectedDate(range);
    const periodBegin = selectedDate[0];
    const periodEnd = selectedDate[1];
    const timeUnit = intervalButton.growth.selected;

    getAdoptionData(periodBegin, periodEnd, timeUnit, isNumber, arrayOfFilter);
  };

  const handleChartView = (interval, e) => {
    e.preventDefault();

    const periodBegin = selectedDate[0];
    const periodEnd = selectedDate[1];

    switch (interval) {
      case 'daily':
        _dailyHandleChartView(
          periodBegin,
          periodEnd,
          isNumber,
          arrayOfFilter,

          getAdoptionData,
          setIntervalButton,
          paramsHelperHandleChartView
        );
        break;
      case 'weekly':
        _weeklyHandleChartView(
          periodBegin,
          periodEnd,
          isNumber,
          arrayOfFilter,

          getAdoptionData,
          setIntervalButton,
          paramsHelperHandleChartView
        );
        break;
      case 'monthly':
        _monthlyHandleChartView(
          periodBegin,
          periodEnd,
          isNumber,
          arrayOfFilter,

          getAdoptionData,
          setIntervalButton,
          paramsHelperHandleChartView
        );
        break;
      default:
    }
  };

  const handleSelectView = async (itemFilter) => {
    const { data } = await getUserInfosCMSCulture(itemFilter);
    if (data) {
      let arrayOfFilter = [];
      let tempObj = {};
      let userInfoData = data;
      let timeUnit = intervalButton.growth.selected;

      for (let i = 0; i < userInfoData.length; i++) {
        tempObj[itemFilter] = userInfoData[i];
        arrayOfFilter.push(tempObj);
        tempObj = {};
      }

      setArrayOfFilter(arrayOfFilter);
      setIsLoading(true);
      setisViewFilterDropdownShown(false);
      setViewFilterState(itemFilter);
      setHideAllUsersSeries(false);

      getAdoptionData(
        selectedDate[0],
        selectedDate[1],
        timeUnit,
        isNumber,
        arrayOfFilter
      );
    }
  };

  const confirmAddMetric = () => {
    let timeUnit = intervalButton.growth.selected;
    let tempObj = {};

    Object.keys(userInfoState).map(function (item, idx) {
      let sel = document.getElementById(item);
      let key = item;

      if (sel.value !== 'Choose a value') {
        tempObj[key] = sel.value;
      }
    });

    arrayOfFilter.push(tempObj);

    setArrayOfFilter(arrayOfFilter);
    setIsItemComparedDropdownShown(false);
    setIsLoading(true);

    getAdoptionData(
      selectedDate[0],
      selectedDate[1],
      timeUnit,
      isNumber,
      arrayOfFilter
    );
  };

  const changeItemLabelFormat = (item) => {
    let itemLabel = item.replace('_', ' ');
    itemLabel = itemLabel.charAt(0).toUpperCase() + itemLabel.slice(1);

    if (itemLabel !== 'All') {
      let lastChar = itemLabel[itemLabel.length - 1];

      if (lastChar !== 'y') {
        itemLabel += 's';
      } else {
        itemLabel = itemLabel.slice(0, -1);
        itemLabel += 'ies';
      }
    }

    itemLabel = itemLabel === 'All' ? itemLabel : 'All ' + itemLabel;

    return itemLabel;
  };

  const getUserInfos = async () => {
    const { data } = await getUserInfosCMSCulture('');
    if (data) {
      let userInfoData = data;

      for (let i = 0; i < userInfoData.length; i++) {
        let keyData = userInfoData[i];
        getUserInfosKey(keyData, userInfoData);
      }
    }
  };

  const getUserInfosKey = async (keyData, userInfoData) => {
    const { data } = await getUserInfosCMSCulture(keyData);
    if (data) {
      let userInfoStateCurr = userInfoState;
      let userInfoDataCurr = data;
      userInfoDataCurr.unshift('Choose a value');
      userInfoStateCurr[keyData] = userInfoDataCurr;

      setUserInfoState(userInfoStateCurr);
    }
  };

  const _getAdoptionParamHelper = (
    periodBegin,
    periodEnd,
    customFilter,
    timeUnit
  ) => ({
    periodBegin: dayjs(periodBegin).startOf('day').toISOString(true),
    periodEnd: dayjs(periodEnd).endOf('day').toISOString(true),
    customFilter,
    timeUnit
  });

  const getAdoptionData = async (
    periodBegin,
    periodEnd,
    timeUnit,
    isNumber,
    customFilter
  ) => {
    const params = _getAdoptionParamHelper(
      periodBegin,
      periodEnd,
      customFilter,
      timeUnit
    );

    const { data } = await getUserActivationCMSCulture(
      params.periodBegin,
      params.periodEnd,
      params.timeUnit,
      params.customFilter
    );
    if (data) {
      const analyticData = data.result;
      const series = seriesGenerator(analyticData, isNumber);
      const growthCurr =
        (analyticData[analyticData.length - 1].active['allUsers'] /
          analyticData[analyticData.length - 1].registered['allUsers']) *
        100;
      const activationCurr =
        analyticData[analyticData.length - 1].active['allUsers'];
      const registeredCurr =
        analyticData[analyticData.length - 1].registered['allUsers'];
      const pendingActivationCurr = registeredCurr - activationCurr;

      setCurrentChartCategories(series.categories);
      setChartAdoption(series);
      setGrowth(growthCurr.toFixed(0));
      setActivation(activationCurr);
      setRegistered(registeredCurr);
      setPendingActivation(pendingActivationCurr);
      setCmsAnalyticData(analyticData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfos();
  }, []);

  useEffect(() => {
    getAdoptionData(
      selectedDate[0],
      selectedDate[1],
      intervalButton.growth.selected,
      isNumber,
      arrayOfFilter
    );
  }, [isNumber, selectedDate]);

  let currViewFilterState = Object.assign({ All: [] }, userInfoState);
  let selectedViewLabel = changeItemLabelFormat(viewFilterState);

  const chart = useRef();

  let percentageActiveUser = null;
  let indexDate = 0;
  if (cmsAnalyticData.length > 1) {
    if (cmsAnalyticData.length == 2) {
      indexDate = 1;
    } else if (cmsAnalyticData.length > 2) {
      let todayDate = dayjs(new Date()).format('YYYY-MM-DD');
      cmsAnalyticData.map((data, index) => {
        if (data.timeframe?.start == todayDate) {
          indexDate = index;
        }
      });
    }
    percentageActiveUser =
      cmsAnalyticData[indexDate]?.percentage.allUsers.toFixed(0) >
      cmsAnalyticData[indexDate - 1]?.percentage.allUsers.toFixed(0)
        ? 'plus'
        : 'minus';
  }

  function isEllipsisActive(element) {
    return element.offsetWidth < element.scrollWidth;
  }

  Array.from(document.querySelectorAll('.activation-number')).forEach(
    (element) => {
      let paragrafNumber = element.getElementsByClassName('paragraf-number')[0];
      let tooltip = element.getElementsByClassName('tooltip')[0];
      if (isEllipsisActive(paragrafNumber)) {
        tooltip.className = 'tooltip hover';
      }
    }
  );

  return (
    <>
      <HeaderPage titlePage={title} />
      <div className="admin-culture mt-[24px]">
        <div className="col-xs-12 adoption-container" id="dashboard_content">
          {(isItemComparedDropdownShown || isViewFilterDropdownShown) && (
            <div
              className="datepicker-overlay"
              onClick={(e) => handleCancel(e)}
            ></div>
          )}
          <div className="dashboard-content-main">
            <div className="flex justify-between w-full">
              <div className="card-wrapper-adoption">
                <div className="card-body growth">
                  <p className="typography-h500">
                    {getObjectiveLocale('Total Activation')}
                  </p>
                  <p className="paragraf-number mt-[32px]">
                    {getNumberLocale(growth || 0)} <sup>%</sup>
                  </p>
                </div>
              </div>
              <div className="card-wrapper-adoption">
                <div className="card-body activation">
                  <p className="typography-h500">
                    {getObjectiveLocale('Activation')}
                  </p>
                  <div className="activation-number">
                    <div className="tooltip">
                      {getNumberLocale(activation || 0)}
                    </div>
                    {percentageActiveUser && (
                      <i
                        className={
                          percentageActiveUser == 'plus'
                            ? 'fa fa-sort-asc plus'
                            : 'fa fa-sort-asc minus'
                        }
                      ></i>
                    )}
                    <p className="paragraf-number activation-p">
                      {getNumberLocale(activation || 0)}
                    </p>
                  </div>
                  <p className="adoption-status">
                    {getObjectiveLocale('Newly activated last 28 days')}
                  </p>
                </div>
              </div>
              <div className="card-wrapper-adoption">
                <div className="card-body">
                  <p className="typography-h500">
                    {getObjectiveLocale('Registered')}
                  </p>
                  <div className="activation-number">
                    <div className="tooltip">
                      {getNumberLocale(registered || 0)}
                    </div>
                    <p className="paragraf-number">
                      {getNumberLocale(registered || 0)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-wrapper-adoption">
                <div className="card-body">
                  <p className="typography-h500">
                    {getObjectiveLocale('Pending Activation')}
                  </p>
                  <div className="activation-number">
                    <div className="tooltip">
                      {getNumberLocale(pendingActivation || 0)}
                    </div>
                    <p className="paragraf-number">
                      {getNumberLocale(pendingActivation || 0)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <div className="card-body-chart">
                <h4 className="body-header">
                  {getObjectiveLocale('Growth')}{' '}
                  <i className="fa fa-question-circle-o"></i>
                </h4>

                <div className="view-filter">
                  <div className="view-label">
                    {getObjectiveLocale('View by')}
                  </div>
                  <div
                    className="view-option-adoption"
                    onClick={showViewFilterDropdown}
                  >
                    {selectedViewLabel}
                  </div>

                  {isViewFilterDropdownShown && (
                    <div className="list-view-option-wrapper">
                      {Object.keys(currViewFilterState).map(function (
                        item,
                        idx
                      ) {
                        let itemLabel = changeItemLabelFormat(item);
                        let activeClass =
                          viewFilterState === item ? true : false;

                        return (
                          <div
                            key={idx}
                            className={classSetter('view-option-list', {
                              active: activeClass
                            })}
                            onClick={() => handleSelectView(item)}
                          >
                            {itemLabel}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="filter-compare-chart">
                  {currentChartCategories.map((item, idx) => {
                    return (
                      <div className="item-compared" key={idx}>
                        <div className="item-compared-name">
                          {startCase(item)}
                        </div>
                        <div
                          className="item-compared-btn"
                          onClick={() =>
                            removeFilter(idx, item.replace('_', ' '))
                          }
                        >
                          <span className="fa fa-close"></span>
                        </div>
                      </div>
                    );
                  })}

                  <div className="item-compared-dropdown">
                    <div
                      className="compare-button"
                      onClick={(e) =>
                        handleShowItemComparedDrowdown('growth', e)
                      }
                    >
                      {getObjectiveLocale('Add to Compare')}
                    </div>

                    {isItemComparedDropdownShown && (
                      <div className="item-dropdown-content">
                        {Object.keys(userInfoState).map(function (item, idx) {
                          let itemInside = userInfoState[item].map(function (
                            itemInside,
                            idx
                          ) {
                            return (
                              <option key={idx} value={itemInside}>
                                {itemInside}
                              </option>
                            );
                          });
                          let itemLabel;
                          itemLabel = item.replace('_', ' ');
                          itemLabel =
                            itemLabel.charAt(0).toUpperCase() +
                            itemLabel.slice(1);

                          return (
                            <div key={idx}>
                              <div className="item-filter">{itemLabel}</div>
                              <select id={item} className="item-filter-option">
                                {itemInside}
                              </select>
                            </div>
                          );
                        })}
                        <div
                          className="btn-confirm-filter"
                          onClick={confirmAddMetric}
                        >
                          <div className="item-compared-add item-compared-name">
                            {getObjectiveLocale('Add Metric')}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex justify-between mb-[24px]">
                  <div className="container-filter-period flex flex-col">
                    <div className="btn-group">
                      <div
                        className={
                          'btn btn-chartView ' +
                          (intervalButton.growth.day ? 'is-active' : '')
                        }
                        onClick={(e) => handleChartView('daily', e)}
                      >
                        {getObjectiveLocale('Day')}
                      </div>
                      <div
                        className={
                          'btn btn-chartView ' +
                          (intervalButton.growth.week ? 'is-active' : '')
                        }
                        onClick={(e) => handleChartView('weekly', e)}
                      >
                        {getObjectiveLocale('Week')}
                      </div>
                      <div
                        className={
                          'btn btn-chartView ' +
                          (intervalButton.growth.month ? 'is-active' : '')
                        }
                        onClick={(e) => handleChartView('monthly', e)}
                      >
                        {getObjectiveLocale('Month')}
                      </div>
                    </div>
                    <div className="checkbox-wrapper">
                      <Checkbox
                        customContainerClass="inline-block"
                        name="handleSelectNumberGrowth"
                        id="handleSelectNumberGrowth"
                        onChange={handleSelectType}
                        checked={isNumber}
                        value="number"
                      >
                        {' '}
                        {getObjectiveLocale('Number')}
                      </Checkbox>
                      <Checkbox
                        customContainerClass="ml-[16px] inline-block"
                        name="handleSelectPercentageGrowth"
                        id="handleSelectPercentageGrowth"
                        onChange={handleSelectType}
                        checked={!isNumber}
                        value="percentage"
                      >
                        {' '}
                        {getObjectiveLocale('Percentage')}
                      </Checkbox>
                    </div>
                  </div>
                  <InsightCalendar
                    endDate={selectedDate[1]}
                    startDate={selectedDate[0]}
                    selectedDate={selectedDate}
                    handleSelectDate={(range) => handleQueryGrowth(range)}
                    listOptionsTime={listOptionDate([
                      'This Day',
                      'Last Week',
                      'This Month'
                    ])}
                  />
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="chart-box">
                    <LineChart data={chartAdoption} isNumber={isNumber} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <CurrentChartAdoption />
        </div>
      </div>
    </>
  );
};

export default Adoption;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';
import {
  whichBadgeBgColorHex,
  whichBadgeColorHex,
  whichStatus
} from 'utils/ObjectivesHelper';

import Dropdown from 'components/design-system/dropdown/Dropdown';
import Badge from 'components/shared/Badge';
import SVGIcon from 'components/shared/SVGIcon';
import TooltipContainer from 'components/shared/Tooltips/TooltipContainer';

const Status = ({ objectiveDetail, updateObjective }) => {
  const { measurement } = objectiveDetail || {};

  const { progressColorHex } = measurement || {};

  const progressLevels = [
    {
      id: 1,
      label: getObjectiveLocale('On track'),
      colorHex: '14532D',
      bgColorHex: 'DCFCE7'
    },
    {
      id: 2,
      label: getObjectiveLocale('Left behind'),
      colorHex: '713F12',
      bgColorHex: 'FEF9C3'
    },
    {
      id: 3,
      label: getObjectiveLocale('At risk'),
      colorHex: '7F1D1D',
      bgColorHex: 'FEE2E2'
    },
    {
      id: 4,
      label: getObjectiveLocale('Exceeded'),
      colorHex: '1E3A8A',
      bgColorHex: 'DBEAFE'
    }
  ];

  return (
    <div className="px-[24px] mt-[24px] mb-[32px]">
      <div className="flex items-center mb-[8px]">
        <p className="typography-h400 text-n-900 mr-[8px]">
          {getObjectiveLocale('Project Status')}
        </p>
        {measurement?.unit && (
          <TooltipContainer
            show
            text="This status is calculated automatically from current metrics progress"
            position="top"
            useMaxWidth={false}
          >
            <SVGIcon size="16" fillColor="var(--n-600)" iconName="icon-info" />
          </TooltipContainer>
        )}
      </div>

      <Dropdown>
        <Dropdown.Trigger
          disabled={measurement?.unit}
          dataCy="dropdown-project-status"
        >
          <Badge
            defaultPointer
            colorHex={whichBadgeColorHex[progressColorHex || '44DB5E']}
            bgColorHex={whichBadgeBgColorHex[progressColorHex || '44DB5E']}
            useStopPropagation={false}
          >
            <p className="typography-h200">
              {whichStatus[progressColorHex || '44DB5E']}
            </p>
          </Badge>
        </Dropdown.Trigger>
        <Dropdown.MenuItems>
          {progressLevels.map((progressLevel, index) => (
            <Dropdown.MenuItem
              key={index}
              customClass="width-184"
              onClick={() =>
                updateObjective({ progressLevelId: progressLevel.id })
              }
              dataCy={`dropdown-project-status-option-${index}`}
            >
              <Badge
                defaultPointer
                colorHex={progressLevel.colorHex}
                bgColorHex={progressLevel.bgColorHex}
                onClick={() =>
                  updateObjective({ progressLevelId: progressLevel.id })
                }
                useStopPropagation={false}
              >
                <p className="typography-h200">{progressLevel.label}</p>
              </Badge>
            </Dropdown.MenuItem>
          ))}
        </Dropdown.MenuItems>
      </Dropdown>
    </div>
  );
};

export default Status;

import React from 'react';

import { getObjectiveLocale } from 'utils/HelperUtils';

import SVGIcon from 'components/shared/SVGIcon';

function OverviewListCycleComponent(props) {
  return (
    <div className="detail-performance" id="subbox">
      <span className="title-quarter-performance">{props.title}</span>

      <span className="team">{props.total}</span>
      <div className="detail-rating">
        <SVGIcon
          iconName="icon-star-rounded"
          size={16}
          fillColor="var(--y-500)"
        />
        {props.average_score ? (
          <>
            <span className="count-rating">
              {props.average_score && props.average_score.score}
            </span>
            <span className="interpunct"> · </span>
            {props.average_score && props.average_score.scoring_option && (
              <span
                className="status"
                style={{
                  color: '#' + props.average_score.scoring_option.color_hex
                }}
              >
                {props.average_score.scoring_option.description}
              </span>
            )}
          </>
        ) : (
          <p className="typography-paragraph ml-[4px] text-n-600 w-full">
            {getObjectiveLocale('Not yet available')}
          </p>
        )}
      </div>
      {props.quartal &&
        props.quartal.map((data, i) => {
          return (
            <div
              className="detail-overview"
              onClick={() => props.handleOpenModal(data)}
              key={i}
            >
              <span className="team-name">{data.name}</span>
              {data.involvement && (
                <span className="member">{data.involvement.role}</span>
              )}
              <div className="detail-rating">
                <SVGIcon iconName="icon-star-rounded" size={16} />
                <span className="count-rating">
                  {data.averageScore && data.averageScore}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default OverviewListCycleComponent;

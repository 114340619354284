import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router';

import {
  getCyclesById,
  getPhaseProgressFilter,
  getResultFilter,
  sendEmailReminder
} from 'client/FormalReviewClient';
import { useUser } from 'context/UserContext';
import useFilter from 'hooks/useFilter';
import utilsDebounce from 'utils/Debounce';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import AdvancedFilter from 'components/advance-filter/AdvancedFilter';
import ProgressContent from 'components/formal-review/progress-result/ProgressContent';
import ResultContent from 'components/formal-review/progress-result/ResultContent';
import SidebarLeftMenuNavigator from 'components/shared/SidebarLeftMenuNavigator';
import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';
import { trackEvent } from 'src/utils/AnalyticUtils';

import './team-result.scss';

const TeamResult = ({
  withHeaderAndSidebar = true,
  isModalRemindOpenProps,
  setIsModalRemindOpenProps,
  isAdmin = false,
  initialCurrentMenu = 'progress',
  isReadOnly = false,
  lockAnswerCycle
}) => {
  // ===================== GET FILTER ===========================
  const { config } = useUser();
  const pageParams = useParams();

  // Search State
  const [searchValue, setSearchValue] = useState('');
  const [debounceHandler, setDebounceHandler] = useState(null);

  const [warning, setWarning] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [notifMessage, setNotifMessage] = useState('');
  const [totalReviewer, setTotalReviewer] = useState(0);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [cycleData, setCycleData] = useState(null);
  const [isModalRemindOpen, setIsModalRemindOpen] = withHeaderAndSidebar
    ? useState(false)
    : [isModalRemindOpenProps, setIsModalRemindOpenProps];
  const [isLoading, setIsLoading] = useState(false);

  const filter = useFilter(
    (state) =>
      state.filterById?.[
        currentMenu === 'progress'
          ? 'teamResultFilterProgress'
          : 'teamResultFilterResult'
      ]
  );
  const setFilterById = useFilter((state) => state.setFilterById);

  const listMenu = [
    {
      id: 'progress',
      title: 'Progress',
      onClick: () => setCurrentMenu('progress'),
      isVisible: config?.permissions?.reviewPerformanceCycleProgressSee
    },
    {
      id: 'result',
      title: 'Result',
      onClick: () => setCurrentMenu('result'),
      isVisible: config?.permissions?.reviewPerformanceCycleResultSee
    }
  ].filter((opt) => opt.isVisible);

  const setToaster = (message, isWarning) => {
    setNotifMessage(message);
    setWarning(isWarning);
    setShowNotif(true);
    setTimeout(() => {
      setShowNotif(false);
    }, 2000);
  };

  const remindAll = async () => {
    setIsLoading(true);
    const { isSuccess } = await sendEmailReminder(pageParams.id, {});
    if (isSuccess) {
      setToaster(
        `Successfully sent out notification to ${totalReviewer} people.`,
        false
      );
      setIsModalRemindOpen(false);

      trackEvent({
        event: 'send email reminder',
        eventProperties: {
          cycleId: pageParams.id,
          type: 'all'
        }
      });
    } else {
      setToaster(`Reminder failed to send to ${totalReviewer} people.`, true);
    }
    setIsLoading(false);
  };

  const searchDebounce = async (value) => {
    const callback = async () => {
      setSearchValue(value);
    };

    const handler = await utilsDebounce(callback, debounceHandler);
    setDebounceHandler(handler);
  };

  const getFilterData = async (addedParams) => {
    if (currentMenu == 'progress') {
      return await getPhaseProgressFilter(pageParams.id, {
        adminMode: true,
        q: addedParams?.q,
        key: addedParams?.key
      });
    }
    if (currentMenu == 'result') {
      return await getResultFilter(pageParams.id, {
        adminMode: true,
        q: addedParams?.q,
        key: addedParams?.key
      });
    }
  };

  const _getListFilter = (addedParams) => {
    const fetchFilter = () => {
      return getFilterData(addedParams);
    };

    const { data, status, fetchNextPage, isFetchingNextPage } =
      useInfiniteQuery(
        ['filter', addedParams],
        ({ pageParam }) => fetchFilter(pageParam),
        {
          getNextPageParam: (lastPage, allPages) =>
            lastPage.pagination?.next || 0
        }
      );

    return { data, status, isFetchingNextPage, fetchNextPage };
  };

  const getCycleData = async () => {
    const response = await getCyclesById(pageParams.id);
    const { data } = response;
    if (data) {
      setCycleData(data);
    }
  };

  useEffect(() => {
    getCycleData();
    setCurrentMenu(
      !config?.permissions?.reviewPerformanceCycleProgressSee
        ? 'result'
        : initialCurrentMenu
    );
  }, [initialCurrentMenu]);

  const headerProps = {
    // ...(config.formalReviewReminder &&
    //   isAdmin && {
    //     primaryAction: {
    //       title: 'Remind Incompletes',
    //       onClick: () => setIsModalRemindOpen(true)
    //     }
    //   }),
    titlePage: cycleData?.name,
    backToUrl: cycleData?.team?.id ? '/feedbacks' : '/appraisals',
    isHeaderComposer: true
  };

  let defaultFilterShow = [
    { id: 'direct_reports', name: getObjectiveLocale('My Direct Report') },
    { id: 'indirect_reports', name: getObjectiveLocale('My Indirect Report') },
    {
      id: 'indirect_descendants',
      name: getObjectiveLocale("My Indirect Report's Descendants")
    }
  ];

  let defaultFilter = {
    show: defaultFilterShow,
    userState: ['active', 'pending']
  };

  if (config?.permissions?.reviewPerformanceCycleProgressAllSee) {
    defaultFilter = {};
  }

  let filterMenu = config.cycleDetailFilterOptions;

  const restructureFilter = (filter) => {
    if (filter?.manager?.length > 0) {
      const newFilter = { ...filter };
      delete newFilter.manager;
      let managerId = filter?.manager?.map(({ id }) => id);
      return { ...newFilter, managerIds: managerId };
    } else {
      return filter
    }
  };

  const resultModifier = (filter) => {
    const showName = filter?.show?.map((e) => e?.name);
    const managerName = filter?.manager?.map((e) => e?.name);
    const newFilter = { ...filter };
    delete newFilter.managerIds;

    const result = {
      ...newFilter,
      show: showName || [],
      manager: managerName || []
    };

    return result;
  };

  useEffect(() => {
    setSearchValue('');
  }, [currentMenu]);

  return (
    <div className="team-result">
      <ToastNotif
        showNotif={showNotif}
        message={notifMessage}
        warning={warning}
        isToastComposer
      />
      {withHeaderAndSidebar && <HeaderPage {...headerProps} />}
      <div className="flex">
        {withHeaderAndSidebar && (
          <div className="w-[256px] relative">
            <SidebarLeftMenuNavigator
              listMenu={listMenu}
              title={'View Cycle Detail By'}
              activeMenu={currentMenu}
            />
          </div>
        )}
        <div className="team-result-content bg-n-000">
          <div className="team-result-fixed-filter">
            <AdvancedFilter
              filterOptions={['menuFilter']}
              filterMenu={filterMenu}
              defaultFilter={defaultFilter}
              filterClient={getFilterData}
              resultModifier={resultModifier}
              restructureFilter={restructureFilter}
              dialogQueryFn={(params) => _getListFilter(params)}
              id={`teamResultFilter${
                currentMenu === 'progress' ? 'Progress' : 'Result'
              }`}
            />
          </div>
          <div className="px-[40px]">
            {(filter?.Department?.length > 0 ||
              filter?.Division?.length > 0) && (
              <p className="title-highchart pt-[24px]">
                {filter?.Department.length > 0 &&
                  filter?.Department.map((dept, index) => (
                    <span key={index} className="filter-name typography-h600">
                      {dept}
                    </span>
                  ))}
                {filter?.Division.length > 0 &&
                  filter?.Division.map((div, idx) => (
                    <span key={idx} className="filter-name typography-h600">
                      {div}
                    </span>
                  ))}
              </p>
            )}
          </div>
          {currentMenu === 'progress' && (
            <ProgressContent
              filter={filter}
              setFilter={(params) =>
                setFilterById('teamResultFilterProgress', params)
              }
              searchValue={searchValue}
              isAdmin={isAdmin}
              searchDebounce={searchDebounce}
              setTotalReviewer={setTotalReviewer}
              currentMenu={currentMenu}
              isReadOnly={isReadOnly}
              setToaster={setToaster}
              lockAnswerCycle={lockAnswerCycle}
              listPhases={cycleData?.phases || []}
              importAnswersEnabled={cycleData?.importAnswersEnabled}
            />
          )}
          {currentMenu === 'result' && (
            <ResultContent
              filter={filter}
              isAdmin={isAdmin}
              currentMenu={currentMenu}
              searchDebounce={searchDebounce}
              searchValue={searchValue}
              setFilter={(params) =>
                setFilterById('teamResultFilterResult', params)
              }
              isAbleToShowCurrentScore={cycleData?.currentCalibrationCard}
            />
          )}
        </div>
      </div>
      {isModalRemindOpen && (
        <Modal
          title="Remind all incomplete reviewers"
          description="Notifications will be sent out to the reviewer."
          className="w-[400px]"
          withCloseIcon={false}
          withPrimaryBtn={{
            title: getObjectiveLocale('Remind All'),
            dataCy: 'remind-all',
            onClick: () => remindAll(),
            isLoading: isLoading
          }}
          withSecondaryBtn={{
            title: getObjectiveLocale('Cancel'),
            dataCy: 'cancel-remind-all',
            onClick: () => setIsModalRemindOpen(false)
          }}
        />
      )}
    </div>
  );
};

export default TeamResult;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import dayjs from 'dayjs';

import {
  deleteSurveyIdCMSCulture as deleteSurvey,
  getSurveyCMSCulture as getSurvey
} from 'client/adminClient';
import { getObjectiveLocale } from 'utils/HelperUtils';

import HeaderPage from 'components/admin/HeaderPage';
import Table from 'components/design-system/table/Table';
import BannerBox from 'components/shared/BannerBox';
import ListEmptyState from 'components/shared/ListEmptyState';
import Loading from 'components/shared/LoadingComponent';
import PaginationModule from 'components/shared/PaginationModule/PaginationModule';
import ToastNotif from 'components/shared/ToastNotif';
import Modal from 'components/shared/modal/Modal';
import Switcher from 'src/components/design-system/Switcher';

import ManageSurveyListRow from './ManageSurveyListRow';
import PulseSurveyEmptyState from './PulseSurveyEmptyState';

// eslint-disable-next-line @typescript-eslint/no-var-requires
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const ManageSurveyList = ({ route: { title, isReadOnly } }) => {
  const history = useHistory();

  const usp = new URLSearchParams(window.location.search);
  const tabParams = usp.get('tab');
  const [isLoading, setIsLoading] = useState(false);
  const [toastData, setToastData] = useState({ show: false, msg: '' });

  const [surveys, setSurveys] = useState([]);
  const [currentTab, setCurrentTab] = useState(tabParams || 'current');
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState({ show: false, id: null });

  const hasMore = pagination?.olderThan !== null;

  const tabList = [
    { id: 'current', name: 'Active' },
    { id: 'upcoming', name: 'Upcoming' },
    { id: 'past', name: 'Past' }
  ];

  const headerProps = {
    titlePage: title,
    primaryAction: !isReadOnly && {
      title: 'Create Pulse Survey',
      icon: 'icon-add',
      dataCy: 'create-survey',
      onClick: () => {
        history.replace('/create-pulse-survey');
      }
    }
  };

  const getSurveyData = useCallback(
    async (additionalParams) => {
      setIsLoading(true);
      const params = {
        ...additionalParams,
        state: currentTab,
        limit: 10
      };

      const { data, pagination } = await getSurvey(params);
      if (data) {
        setSurveys(data);
        setPagination({
          olderThan: data.length < 10 ? null : pagination?.next?.olderThan,
          newerThan: pagination?.prev?.newerThan
        });
      }
      setIsLoading(false);
    },
    [currentTab]
  );

  const _deleteSurvey = async () => {
    const { isSuccess, error } = await deleteSurvey(modalOpen?.id);
    if (isSuccess) {
      getSurveyData();
      setModalOpen({ isShow: false, id: null });
    }
    setToastData({
      show: true,
      msg: isSuccess ? 'Success delete survey' : error?.message
    });
    setTimeout(() => {
      setToastData({ show: false, msg: '' });
    }, 3000);
  };

  const changePage = (type) => {
    const paginationKey = type === 'next' ? 'olderThan' : 'newerThan';
    setCurrentPage((page) => (type == 'next' ? page + 1 : page - 1));
    getSurveyData({ [paginationKey]: pagination[paginationKey] });
  };

  const headers = [
    {
      name: getObjectiveLocale('Survey Name'),
      widthClass: 'w-[224px]',
      grow: true
    },
    {
      name: 'Recurrence',
      widthClass: 'w-[120px]'
    },
    { name: getObjectiveLocale('Start Date'), widthClass: 'w-[200px]' },
    { name: getObjectiveLocale('End Date'), widthClass: 'w-[200px]' },
    {
      name: getObjectiveLocale('Completion rate'),
      widthClass: 'w-[216px]',
      hide: currentTab == 'upcoming'
    },
    { name: '', widthClass: 'w-[56px]' },
    { name: '', widthClass: 'w-[56px]' }
  ];

  const onClickRow = (id, occurrenceId) => {
    const type =
      currentTab === 'upcoming' ? 'edit-pulse-survey' : 'survey-details';
    const params =
      currentTab !== 'upcoming'
        ? `?occurrenceId=${occurrenceId}&tab=analytics`
        : `?occurrenceId=${occurrenceId}`;

    history.push(`/${type}/${id}${params}`);
  };

  const tableRef = useRef(null);
  useEffect(() => {
    setCurrentPage(1);
    history.replace(history.location.pathname + '?tab=' + currentTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    getSurveyData();
  }, [getSurveyData]);

  return (
    <>
      <HeaderPage {...headerProps} />

      {toastData?.show && (
        <ToastNotif showNotif={toastData?.show} message={toastData?.msg} />
      )}
      <div className="pt-[2px] bg-n-000 min-h-[calc(100vh-64px)]">
        <Switcher
          options={tabList}
          selectedOption={currentTab}
          onChange={(tab) => setCurrentTab(tab)}
          customClass="pr-[16px] mt-[24px]"
          textCustomClass="typography-button"
        />
        {!isLoading && surveys.length > 1 && currentTab === 'upcoming' && (
          <BannerBox type="info" customClass="mt-[16px]">
            {getObjectiveLocale(
              'You can still edit your pulse survey details until Pulse Survey Start'
            )}
          </BannerBox>
        )}
        {isLoading ? (
          <div className="mt-[116px]">
            <Loading />
          </div>
        ) : surveys.length < 1 && currentPage == 1 ? (
          <PulseSurveyEmptyState
            currentTab={currentTab}
            onClick={() => history.replace('/create-pulse-survey')}
            isReadOnly={isReadOnly}
          />
        ) : (
          <div id={'table-survey'} ref={tableRef} className="mt-[24px]">
            <Table headers={headers}>
              {surveys.length == 0 ? (
                <ListEmptyState
                  title={getObjectiveLocale('There is no data in this page')}
                  subtitle={getObjectiveLocale('Please go to previous page.')}
                />
              ) : (
                surveys.map((data, index) => (
                  <ManageSurveyListRow
                    key={index}
                    data={data}
                    index={index}
                    currentTab={currentTab}
                    setModalOpen={setModalOpen}
                    onClickRow={onClickRow}
                    tableRef={tableRef}
                  />
                ))
              )}
            </Table>
            <div className="flex justify-end items-center pb-[40px] pt-[24px]">
              <PaginationModule
                pageNumber={currentPage}
                handlePrev={() => changePage('prev')}
                handleNext={() => changePage('next')}
                hasMore={hasMore}
              />
            </div>
          </div>
        )}
      </div>
      {modalOpen?.show && (
        <Modal
          title={getObjectiveLocale('Delete Survey')}
          description={getObjectiveLocale(
            'Are you sure you want to delete this survey?'
          )}
          headerIcon={{ name: 'icon-warning', color: 'var(--y-600)' }}
          eventOnClickClose={() => setModalOpen({ isShow: false, id: null })}
          className="w-[400px]"
          dataCyModal="delete-survey-modal"
          maxHeight={188}
          withPrimaryBtn={{
            danger: true,
            title: getObjectiveLocale('Delete'),
            dataCy: 'submit-delete-survey',
            onClick: () => _deleteSurvey(),
            isLoading: isLoading
          }}
          withSecondaryBtn={{
            title: getObjectiveLocale('Cancel'),
            dataCy: 'cancel-delete-survey',
            onClick: () => setModalOpen({ isShow: false, id: null })
          }}
        />
      )}
    </>
  );
};

export default ManageSurveyList;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';

import { getCyclesById } from 'client/FormalReviewClient';
import { getCycleProgress } from 'client/admin/CyclesClient';
import { useSidebarMenu } from 'context/SidebarMenuContext';
import { phaseName } from 'utils/FormalReview';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Shimmer from 'components/design-system/shimmer/Shimmer';
import SVGIcon from 'components/shared/SVGIcon';
import ProgressBarOverallProgress from 'components/shared/progressBar/ProgressBarOverallProgress';

import PhaseCardDetailCycle from './PhaseCardDetailCycle';
import './current-cycle-phase.scss';

const CurrentCyclePhase = ({ cycleData }) => {
  const { id: cycleId } = useParams();

  const [cycleOverallProgress, setCycleOverallProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [phases, setPhases] = useState([]);

  const { toggleSidebar } = useSidebarMenu();

  const logicAddComa = (i, dependencies) => {
    return dependencies.length > 1 && i != dependencies.length - 1 ? `, ` : '';
  };

  const getDependentOn = (dependencies, type) => {
    let listDependencies = '';
    for (let i = 0; i < dependencies.length; i++) {
      if (type == 'Cycle')
        listDependencies += `${dependencies[i].name}${logicAddComa(
          i,
          dependencies
        )}`;
      if (type == 'Phase') {
        const phase = getObjectiveLocale(phaseName(dependencies[i]));
        listDependencies += `${phase}${logicAddComa(i, dependencies)}`;
      }
    }
    return listDependencies;
  };

  const _handleGetCycleOverallProgress = async () => {
    const { data } = await getCycleProgress(cycleId);
    setCycleOverallProgress(data);
    setIsLoading(false);
  };

  const _handleGetCyclesById = async () => {
    const { data: phaseInfosData } = await getCyclesById(cycleId);
    setPhases(phaseInfosData.phases);
  };

  const checkDependencies = (cycleDependentOn, type) => {
    if (cycleDependentOn === null) cycleDependentOn = [];
    if (cycleDependentOn?.length > 0)
      return (
        <span className="text-n-800">
          {' '}
          {getDependentOn(cycleDependentOn, type)}
        </span>
      );
    else
      return <span className="text-n-800"> Not Dependent on any {type}</span>;
  };

  const checkCalibration = (cycleCalibration) => {
    return (
      <>
        {cycleCalibration === null && (
          <>
            Calibration:{' '}
            <span className="text-n-800"> Not using calibration </span>{' '}
          </>
        )}
        {cycleCalibration?.type === 'manager' && (
          <>
            Calibration:{' '}
            <span className="text-n-800">
              {' '}
              All manager who have subordinates may calibrate{' '}
            </span>{' '}
          </>
        )}
        {cycleCalibration?.type === 'level' && (
          <>
            <p className="typography-paragraph">
              Calibration:{' '}
              <span className="text-n-800">
                {' '}
                Only certain levels in the organization may calibrate their
                subordinates{' '}
              </span>{' '}
            </p>
            <p className="typography-h100 typography-secondary text-n-800">
              Level {cycleCalibration?.maxManagerLevel}
            </p>
          </>
        )}
      </>
    );
  };

  const getSchedule = (startsAt, endsAt) => {
    if (startsAt && endsAt)
      return `${dayjs(startsAt).format('DD MMM YYYY')} - ${dayjs(endsAt).format(
        'DD MMM YYYY'
      )}`;
    else return 'No Schedule';
  };

  useEffect(() => {
    toggleSidebar(true);
    _handleGetCyclesById();
    _handleGetCycleOverallProgress();
  }, []);

  return (
    <div className="cycles-details-content bg-n-000">
      <div className="cycles-details-description">
        <p className="typography-h600 text-n-900">
          {isLoading ? (
            <Shimmer height="14px" widthRandomness={0.25} circle />
          ) : (
            cycleData.name
          )}
        </p>
        <p className="typography-h400 flex mt-[4px]">
          <SVGIcon
            size="18"
            iconName="icon-calendar_today"
            fillColor="var(--n-600)"
          />
          <span className="ml-[10px] text-n-800">
            {isLoading ? (
              <Shimmer height="20px" widthRandomness={0.25} circle />
            ) : (
              getSchedule(
                cycleData.reviewProcessStartsAt,
                cycleData.reviewProcessEndsAt
              )
            )}
          </span>
        </p>
        <p className="typography-h400 mt-[8px] text-n-900">
          {isLoading ? (
            <Shimmer height="14px" widthRandomness={0.25} circle />
          ) : (
            checkCalibration(cycleData.calibration)
          )}
        </p>
        <p className="typography-h400 dependent-on mt-[8px] text-n-900">
          Dependent On:{' '}
          {isLoading ? (
            <Shimmer height="14px" widthRandomness={0.25} circle />
          ) : (
            checkDependencies(cycleData.dependencies, 'Cycle')
          )}
        </p>
        {isLoading ? (
          <Shimmer height="20px" width="900px" circle />
        ) : (
          <ProgressBarOverallProgress
            colorProgress="bg-g-600"
            widthProgress={parseInt(cycleOverallProgress)}
            withTextInProgressBar
            containerClassName="w-[900px] h-[20px] overflow-hidden rounded-full mt-[18px]"
            height={20}
          />
        )}
      </div>
      <div className="cycles-phase-summary-content admin-culture">
        <div className="flex flex-wrap">
          {phases.map((v, i) => {
            const keyCyclePhaseInfos =
              v.type === 'peer_selection' ? 'peerReview' : camelCase(v.type);
            return (
              <PhaseCardDetailCycle
                key={i}
                customClass="mr-[24px]"
                title={getObjectiveLocale(phaseName(v.type))}
                startFrom={getSchedule(v.startsAt, v.endsAt)}
                dependentOn={checkDependencies(
                  cycleData[keyCyclePhaseInfos]?.dependencies,
                  'Phase'
                )}
                trackConfigs={cycleData[keyCyclePhaseInfos]?.trackConfigs}
                reminders={cycleData[keyCyclePhaseInfos]?.reminders}
                trackQty={
                  v.type !== 'peer_selection'
                    ? cycleData[keyCyclePhaseInfos]?.trackConfigs?.length || 0
                    : 0
                }
                notificationReminderQty={
                  v.type !== 'peer_selection'
                    ? cycleData[keyCyclePhaseInfos]?.reminders?.length || 0
                    : 0
                }
                phase={v}
                cycleData={cycleData}
                _handleGetCyclesById={_handleGetCyclesById}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CurrentCyclePhase;

import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import useDisabledUsers from 'hooks/useDisabledUsers';

import InlineDialogSelectMultipleUser from 'components/inline-dialog/InlineDialogSelectMultipleUser';
import { getNewInvolvements } from 'components/sidebar/sidebar-create/SidebarCreateHelper';

const SelectFollower = ({
  objectiveValue,
  setObjectiveValue,
  selectedFollower,
  assignFollower
}) => {
  const { params } = useRouteMatch();

  const { checkIsUserDisabled } = useDisabledUsers({
    isEdit: params?.type == 'edit',
    objective: objectiveValue
  });

  const handleChange = (involvements, type) => {
    const lastIndex = involvements?.length - 1;
    const selectedUser = involvements[lastIndex];

    checkIsUserDisabled(selectedUser?.user?.id, () => {
      const newInvolvements = getNewInvolvements(
        involvements,
        type,
        true,
        objectiveValue,
        params
      );
      setObjectiveValue((draft) => {
        draft.involvements = newInvolvements;
      });
    });
  };

  return (
    <div data-cy="sidebar-create-follower">
      <InlineDialogSelectMultipleUser
        header="Follower"
        role="follower"
        type="follower"
        vPosition="top"
        position="left"
        objectiveValue={objectiveValue}
        setObjectiveValue={setObjectiveValue}
        selectedValue={selectedFollower}
        handleChange={handleChange}
        permission={assignFollower}
        dataCy="inline-dialog-select-follower"
        customClass="left-[10px]"
        additionalQuery={
          params?.parentId && location.pathname.includes('team')
            ? {
                teamId: params?.parentId,
                teamInvolvements: ['leader', 'member']
              }
            : {}
        }
      />
    </div>
  );
};

export default SelectFollower;

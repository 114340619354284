import React, { useEffect, useState } from 'react';

import { useUser } from 'context/UserContext';
import { setActiveNavigator } from 'utils/HelperUtils';
import { getObjectiveLocale } from 'utils/HelperUtils';

import BannerBox from 'components/shared/BannerBox';
import ContentNavigator from 'components/shared/ContentNavigator';
import ListEmptyState from 'components/shared/ListEmptyState';

import CardBehaviorModel from './CardBehaviorModel';
import ContainerWeightConfig from './ContainerWeightConfig';
import HeaderTableBehaviorModel from './HeaderTableBehaviorModel';
import SectionDropdownBehavior from './SectionDropdownBehavior';

const StepConfiguration = ({
  isLoadingDefaultData,
  dataDefault,
  isEnableSetPercentage,
  setIsEnableSetPercentage,
  modelData,
  setModelData,

  // behavior
  behaviorWeightsCompetency,
  setBehaviorWeightsCompetency,
  behaviorWeightsValue,
  setBehaviorWeightsValue,
  behaviorWeightsWork,
  setBehaviorWeightsWork,

  // warning
  isWarningCompetency,
  setIsWarningCompetency,
  isWarningValue,
  setIsWarningValue,

  // dictionary
  dataCompetencyDictionary,
  setDataCompetencyDictionary,
  dataValueDictionary,
  setDataValueDictionary,
  dataWorkDictionary,
  setDataWorkDictionary,
  isReadOnly
}) => {
  const { config } = useUser();
  const [isHideTableCompetency, setIsHideTableCompetency] = useState(false);
  const [isHideTableValue, setIsHideTableValue] = useState(false);
  const [isHideTableWork, setIsHideTableWork] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState({
    competency: false,
    value: false
  });
  const [isWeightUpdated, setWeightUpdated] = useState(false);
  const enforceValue =
    config?.enforceZeroOrOneHundredTotalValueWeightPercentage;
  const enforceCompetencies =
    config?.enforceZeroOrOneHundredTotalCompetencyWeightPercentage;
  const enforceWorkAttr =
    config?.enforceZeroOrOneHundredTotalWorkAttributeWeightPercentage;

  // Navigator state
  const [activeSection, setActiveSection] = useState('configure');
  const [listNavigatorContents, setListNavigatorContents] = useState([
    { id: 'configure', name: 'Configure' },
    { id: 'competency-model', name: 'Competency Model' },
    { id: 'value-model', name: 'Value Model' },
    { id: 'work-attribute-model', name: 'Work Attribute Model' }
  ]);

  !config?.allowUsingWorkAttributes &&
    delete listNavigatorContents[listNavigatorContents.length - 1];

  const onChangeDropdown = (type, model) => {
    let params = { ...modelData };

    if (type == 'competency') {
      params.competencyModel = model;
      params.competencyModelId = model.id;
    } else if (type == 'value') {
      params.valueModel = model;
      params.valueModelId = model.id;
    } else {
      params.workAttributeModel = model;
      params.workAttributeModelId = model.id;
    }

    setModelData(params);
  };

  useEffect(() => {
    let competencyDictionaryLength = dataCompetencyDictionary.length !== 0;
    if (isEnableSetPercentage && competencyDictionaryLength) {
      let total = [...behaviorWeightsCompetency].reduce(
        (a, b) => a + b.weight,
        0
      );
      setIsWarningCompetency(
        enforceCompetencies ? total != 0 && total != 100 : false
      );
    }
  }, [behaviorWeightsCompetency, isEnableSetPercentage]);

  useEffect(() => {
    let valueDictionaryLength = dataValueDictionary.length !== 0;
    if (isEnableSetPercentage && valueDictionaryLength) {
      let total = [...behaviorWeightsValue].reduce((a, b) => a + b.weight, 0);
      setIsWarningValue(enforceValue ? total != 0 && total != 100 : false);
    }
  }, [behaviorWeightsValue, isEnableSetPercentage]);

  return (
    <React.Fragment>
      <div className="content-navigator-wrapper">
        <ContentNavigator
          title="CONTENTS"
          activeSection={activeSection}
          listNavigatorContents={listNavigatorContents}
          sectionContent="scrollable-config"
          additionalOffset={-8}
        />
      </div>

      <div
        id="scrollable-config"
        className="flex mb-[64px] content-container-nav h-[85vh]"
        onScroll={(e) =>
          setActiveNavigator(e, listNavigatorContents, setActiveSection)
        }
      >
        <div className="w-9/12">
          <ContainerWeightConfig
            setState={setIsEnableSetPercentage}
            state={isEnableSetPercentage}
          />

          {!isLoadingDefaultData && (
            <SectionDropdownBehavior
              id={'competency-model'}
              type="competency"
              groupId={dataDefault?.id}
              behaviorWeights={behaviorWeightsCompetency}
              setBehaviorWeights={setBehaviorWeightsCompetency}
              dataDefaultModel={dataDefault?.competencyModel}
              dataDictionary={dataCompetencyDictionary}
              setDataDictionary={setDataCompetencyDictionary}
              stateTable={isHideTableCompetency}
              setStateTable={setIsHideTableCompetency}
              getOrUpdateDataDropdown={onChangeDropdown}
              defaultValueDropdown={modelData?.competencyModel?.title}
              setIsDataEmpty={setIsDataEmpty}
              isDataEmpty={isDataEmpty}
              setWeightUpdated={setWeightUpdated}
              isWeightUpdated={isWeightUpdated}
            />
          )}

          {isWarningCompetency && isEnableSetPercentage && (
            <BannerBox
              text="Total weight of all competencies must be 100%, or this change will not be saved."
              type="warning"
              dataCy="warning-container-competency"
            />
          )}

          {/* TABLE COMPETENCY */}
          {!isHideTableCompetency && dataCompetencyDictionary.length != 0 && (
            <div className="table-landing-behavior-model mb-[40px]">
              <HeaderTableBehaviorModel type="competency" />
              <div className="body-table">
                {dataCompetencyDictionary.map((dictionary, idx) => {
                  let weight = behaviorWeightsCompetency?.find(
                    (val) => val.behaviorId == dictionary.id
                  );
                  return (
                    <CardBehaviorModel
                      key={dictionary.id}
                      value={dictionary}
                      type="competency"
                      weight={weight?.weight || 0}
                      behaviorWeights={behaviorWeightsCompetency}
                      setBehaviorWeights={setBehaviorWeightsCompetency}
                      setWeightUpdated={setWeightUpdated}
                      isWeightUpdated={isWeightUpdated}
                      canEdit={isEnableSetPercentage && !isReadOnly}
                    />
                  );
                })}
              </div>

              {isDataEmpty.competency &&
                dataCompetencyDictionary.length == 0 && (
                  <ListEmptyState
                    fullHeight
                    emptyIcon="icon-no-result-found"
                    title={getObjectiveLocale('No Result Found')}
                    subtitle={getObjectiveLocale(
                      'Select another competency model instead.'
                    )}
                  />
                )}
            </div>
          )}

          {!isLoadingDefaultData && (
            <SectionDropdownBehavior
              id={'value-model'}
              type="value"
              groupId={dataDefault?.id}
              behaviorWeights={behaviorWeightsValue}
              setBehaviorWeights={setBehaviorWeightsValue}
              dataDefaultModel={dataDefault?.valueModel}
              dataDictionary={dataValueDictionary}
              setDataDictionary={setDataValueDictionary}
              stateTable={isHideTableValue}
              setStateTable={setIsHideTableValue}
              getOrUpdateDataDropdown={onChangeDropdown}
              defaultValueDropdown={modelData?.valueModel?.title}
              setIsDataEmpty={setIsDataEmpty}
              isDataEmpty={isDataEmpty}
              setWeightUpdated={setWeightUpdated}
              isWeightUpdated={isWeightUpdated}
            />
          )}

          {isWarningValue && isEnableSetPercentage && (
            <BannerBox
              text="Total weight of all values must be 100%, or this change will not be saved."
              type="warning"
              dataCy="warning-container-value"
            />
          )}

          {/* TABLE VALUE */}
          {!isHideTableValue && dataValueDictionary?.length != 0 && (
            <div className="table-landing-behavior-model mb-[40px]">
              <HeaderTableBehaviorModel type="value" />
              <div className="body-table">
                {dataValueDictionary.map((value, idx) => {
                  let weight = behaviorWeightsValue?.find(
                    (val) => val.behaviorId == value.id
                  );
                  return (
                    <CardBehaviorModel
                      key={value.id}
                      value={value}
                      type="value"
                      weight={weight?.weight || 0}
                      behaviorWeights={behaviorWeightsValue}
                      setBehaviorWeights={setBehaviorWeightsValue}
                      setWeightUpdated={setWeightUpdated}
                      isWeightUpdated={isWeightUpdated}
                      canEdit={isEnableSetPercentage && !isReadOnly}
                    />
                  );
                })}
              </div>

              {isDataEmpty.value && dataValueDictionary?.length == 0 && (
                <ListEmptyState
                  fullHeight
                  emptyIcon="icon-no-result-found"
                  title={getObjectiveLocale('No Result Found')}
                  subtitle={getObjectiveLocale(
                    'Select another value model instead.'
                  )}
                />
              )}
            </div>
          )}

          {config?.allowUsingWorkAttributes && (
            <>
              {!isLoadingDefaultData && (
                <SectionDropdownBehavior
                  id={'work-attribute-model'}
                  type="work"
                  groupId={dataDefault?.id}
                  behaviorWeights={behaviorWeightsWork}
                  setBehaviorWeights={setBehaviorWeightsWork}
                  dataDefaultModel={dataDefault?.workAttributeModel}
                  dataDictionary={dataWorkDictionary}
                  setDataDictionary={setDataWorkDictionary}
                  stateTable={isHideTableWork}
                  setStateTable={setIsHideTableWork}
                  getOrUpdateDataDropdown={onChangeDropdown}
                  defaultValueDropdown={modelData?.workAttributeModel?.title}
                  setIsDataEmpty={setIsDataEmpty}
                  isDataEmpty={isDataEmpty}
                  setWeightUpdated={setWeightUpdated}
                  isWeightUpdated={isWeightUpdated}
                />
              )}

              {/* TABLE WORK ATTRIBUTE */}
              {!isHideTableWork && dataWorkDictionary?.length != 0 && (
                <div className="table-landing-behavior-model mb-[40px]">
                  <HeaderTableBehaviorModel type="work attribute" />
                  <div className="body-table">
                    {dataWorkDictionary?.map((work, idx) => {
                      let weight = behaviorWeightsWork?.find(
                        (val) => val.behaviorId == work.id
                      );
                      return (
                        <CardBehaviorModel
                          key={work.id}
                          value={work}
                          type="work"
                          weight={weight?.weight || 0}
                          behaviorWeights={behaviorWeightsWork}
                          setBehaviorWeights={setBehaviorWeightsWork}
                          setWeightUpdated={setWeightUpdated}
                          isWeightUpdated={isWeightUpdated}
                          canEdit={isEnableSetPercentage && !isReadOnly}
                        />
                      );
                    })}
                  </div>

                  {isDataEmpty.work && dataWorkDictionary?.length == 0 && (
                    <ListEmptyState
                      fullHeight
                      emptyIcon="icon-no-result-found"
                      title={getObjectiveLocale('No Result Found')}
                      subtitle={getObjectiveLocale(
                        'Select another work attribute model instead.'
                      )}
                    />
                  )}
                </div>
              )}
            </>
          )}

          <div className="dummy" />
        </div>
        <div className="w-3/12"></div>
      </div>
    </React.Fragment>
  );
};

export default StepConfiguration;

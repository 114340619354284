import React from 'react';

import { useGoalWeightSettingsContext } from 'context/GoalWeightSettingContext';
import { getObjectiveLocale } from 'utils/HelperUtils';

import Accordion, {
  useAccordion
} from 'components/design-system/accordion/Accordion';
import Toggle from 'components/shared/ToogleSwitch/SmallToggleSwitchPurple';

const Header = ({
  title,
  subTitle,
  toggleCallback,
  toggleCy = 'toggle-card',
  disabled
}) => {
  const { setIsOpen, isOpen } = useAccordion();
  const { isReadOnly } = useGoalWeightSettingsContext();

  const toggleOpen = async () => {
    setIsOpen(!isOpen);
    if (toggleCallback) {
      const result = await toggleCallback({ value: !isOpen });
      !result && setIsOpen(isOpen);
    }
  };

  return (
    <div className="flex flex-row space-between items-center px-[24px] py-[16px]">
      <div className="flex flex-col">
        <p className="typography-h500 text-n-900">
          {getObjectiveLocale(title)}
        </p>
        <p className="typography-paragraph text-n-900">
          {getObjectiveLocale(subTitle)}
        </p>
      </div>
      <Toggle
        isChecked={isOpen}
        idStr="toggle-maximum-peers"
        onChange={toggleOpen}
        disabled={isReadOnly}
        dataCy={toggleCy}
      />
    </div>
  );
};

const WeightSettingsCard = ({
  title,
  subTitle,
  toggleCallback,
  isOpen,
  children,
  clearContentSpace,
  toggleCy
}) => {
  return (
    <Accordion
      useBorder
      useBgColor
      customClass="mt-[16px]"
      contentOpen={isOpen}
    >
      <Accordion.Header useWrapperClick={false} useCustomHeader>
        <Header
          title={title}
          subTitle={subTitle}
          toggleCallback={toggleCallback}
          toggleCy={toggleCy}
        />
      </Accordion.Header>
      <Accordion.Content
        customSpaceClass={`${clearContentSpace ? ' ' : 'px-[24px] pb-[24px]'}`}
      >
        {children}
      </Accordion.Content>
    </Accordion>
  );
};

export default WeightSettingsCard;

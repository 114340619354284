import { useEffect } from "react";

const useScroll = ({ condition, id, eventScroll }) => {
  useEffect(() => {
    if (condition && id) {
      document.getElementById((id)).addEventListener('scroll', eventScroll)

      return () => {
        document.getElementById(id).removeEventListener('scroll', eventScroll)
      }
    }
  }, [condition]);
}

export default useScroll;
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router';

import dayjs from 'dayjs';
import isObject from 'lodash/isObject';

import { getObjectiveDetail, getTeamDetail } from 'client/ObjectivesClient';
import {
  getListMeasurement,
  getObjectiveCategory
} from 'client/ObjectivesClient';
import { getObjectivePriorities } from 'client/ObjectivesClient';
import { getObjectiveConfigs } from 'client/ObjectivesClient';
import { useReload } from 'context/ReloadContext';
import { useUser } from 'context/UserContext';
import { formatTimezone, getSemester } from 'utils/DateUtils';
import { objectiveRestructuring } from 'utils/ObjectiveRestructuring';
import {
  getMilestoneDefault,
  getObjectiveInvolvements
} from 'utils/ObjectivesHelper';

import SidebarSkeleton from 'components/shared/SidebarSkeleton';

import SidebarCreate from './SidebarCreate';
import SidebarDetail from './SidebarDetail';

function Sidebar({ reloadFunction, saveCallback, team }) {
  const params = useParams();
  const { state, search } = useLocation();
  const [isParent, setIsParent] = useState(state);
  const { reloadSidebar } = useReload();
  const type = params?.type && params?.type?.includes('task') ? 'task' : 'goal';
  const objectiveId = params?.objectiveId
    ? params?.objectiveId
    : params?.parentId;
  const isTeam = params?.type === 'team';
  const isProject = params?.type?.includes('project');

  const { user, config, manager } = useUser();
  const {
    objectiveWeightType,
    defaultObjectiveVisibilityOption,
    defaultPeriodOnCreate,
    showMetricForObjective,
    showMetricForTask,
    useDefaultObjectiveCategory,
    defaultRollupForParentObjective,
    defaultRollupForParentTask,
    objectiveWeightTypeOptions,
    objectiveMilestonesFeature
  } = config;
  const defaultRollUp =
    type === 'goal'
      ? defaultRollupForParentObjective
      : defaultRollupForParentTask;
  const metricOptions =
    type === 'goal' ? showMetricForObjective : showMetricForTask;

  const [isLoadDataCompleted, setIsLoadDataCompleted] = useState(false);
  const [listMetrics, setListMetrics] = useState([]);
  const [listPriorities, setListPriorities] = useState([]);
  const [objectiveConfigs, setObjectiveConfigs] = useState({});
  const [objectiveCategories, setObjectiveCategories] = useState([]);

  const [singleObjectiveAction, setSingleObjectiveAction] = useState(null);
  const [parents, setParents] = useState(null);

  const newUser = {
    role: 'assignee',
    user: user,
    userId: user.id,
    visible: true,
    placementId: user?.placementId,
    ...((isTeam || isProject) && { extendedRole: 'leader' })
  };

  const searchParams = new URLSearchParams(search);
  const organizationId = searchParams.get('organizationId') || null;

  const getObjective = () => {
    const params = organizationId
      ? { organizationId: parseInt(organizationId) }
      : {};
    return getObjectiveDetail(objectiveId, params);
  };

  const queryKey = organizationId
    ? ['objective', parseInt(objectiveId), parseInt(organizationId)]
    : ['objective', parseInt(objectiveId)];

  let { data, isFetching } = useQuery(queryKey, getObjective, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000
  });

  const singleObjectiveDetail = data?.data || data || {};

  const startDate =
    defaultPeriodOnCreate === 'semester'
      ? getSemester('start')
      : dayjs().startOf(defaultPeriodOnCreate);

  const dueDate =
    defaultPeriodOnCreate === 'semester'
      ? getSemester('due')
      : dayjs().endOf(defaultPeriodOnCreate);

  const getDate = (parentData) => {
    let start, end;
    start = parentData?.startDate
      ? parentData?.startDate
      : formatTimezone(startDate.format('YYYY-MM-DD'));
    end = parentData?.dueDate
      ? parentData?.dueDate
      : formatTimezone(dueDate.format('YYYY-MM-DD'), 'end');

    return { start, end };
  };

  const getDefaultMetric = () => {
    const defaultMetricId = listMetrics?.find(
      ({ isDefaultForGoal, isDefaultForTask }) =>
        type === 'goal' ? isDefaultForGoal : isDefaultForTask
    )?.id;
    return defaultMetricId;
  };

  const getDefaultObjectiveCategory = (parentData) => {
    return (
      parentData?.[0]?.objectiveCategory ||
      objectiveCategories.find(({ isDefault }) => isDefault === true) ||
      null
    );
  };

  const getDefaultMeasurement = (defaultMetricId) => {
    const measurement = {
      startingValue: 0,
      targetValue: 100,
      rollUp: defaultRollUp,
      unitId: defaultMetricId
    };
    return measurement;
  };

  const defaultPermissions = [
    'edit_name',
    'edit_description',
    'edit_weight',
    'edit_measurement_unit',
    'edit_roll_up',
    'edit_calculation_type',
    'edit_milestones',
    'edit_objective_category',
    'edit_tags',
    'edit_starting_value',
    'edit_target_value'
  ];

  const restructureTeamInvolvements = (involvements) => {
    return {
      leader: getObjectiveInvolvements(involvements, 'leader'),
      reviewer: getObjectiveInvolvements(involvements, 'reviewer'),
      member: getObjectiveInvolvements(involvements, 'member'),
      stakeholder: getObjectiveInvolvements(involvements, 'stakeholder'),
      pmo: getObjectiveInvolvements(involvements, 'pmo')
    };
  };

  const getOwnerByTeamInvolvements = (involvementByRole) => {
    let ownerTeamInvolvements = [];
    objectiveConfigs?.subgoalOwners?.map((role) => {
      involvementByRole[role] &&
        ownerTeamInvolvements.push(...involvementByRole[role]);
    });
    if (!ownerTeamInvolvements) return [];
    else
      return ownerTeamInvolvements?.map((user) => ({
        user: user?.user,
        visible: true,
        userId: user?.user?.id,
        placementId: user?.user?.placementId,
        role: 'assignee'
      }));
  };

  const getReviewerByTeamInvolvements = (involvementByRole) => {
    if (!involvementByRole?.reviewer) return [];
    else
      return involvementByRole?.reviewer?.map((user) => ({
        user: user?.user,
        visible: true,
        userId: user?.user?.id,
        placementId: user?.user?.placementId,
        role: 'assigner'
      }));
  };

  const getDefaultInvolvementObjective = () => {
    const teamInvolvements = restructureTeamInvolvements(team?.involvements);
    let owners = getOwnerByTeamInvolvements(teamInvolvements);
    let reviewer = getReviewerByTeamInvolvements(teamInvolvements);

    // config subgoalsOwners is empty, then should check if reviewer team is same as login user
    // if SAME then move login user to owner array (login user should have assignee role instead of assigner)
    if (owners.length == 0) {
      if (reviewer?.[0]?.userId == user?.id) {
        reviewer = [];
      }
      owners = [newUser];
    }
    return [...owners, ...reviewer];
  };

  const getDefaultInvolvementProject = async (teamId) => {
    const isUnderTeam = teamId !== null;

    if (isUnderTeam) {
      const { data } = await getTeamDetail(teamId);

      // ** Mapping teams involvements as project involvements
      const teamMember = data?.involvements?.map((involvement) => {
        return {
          userId: involvement?.user?.id,
          user: involvement?.user,
          role: involvement?.role,
          extendedRole: involvement?.extendedRole || null,
          visible: true
        };
      });

      return teamMember || [];
    }

    return [newUser];
  };

  const getGoalData = () => {
    let tempObjective = objectiveRestructuring(singleObjectiveDetail);
    tempObjective.childrenCount = singleObjectiveDetail.childrenCount;
    tempObjective.stateObject = singleObjectiveDetail.stateObject;
    setSingleObjectiveAction(tempObjective);
    setParents(
      singleObjectiveDetail.parents?.length > 0
        ? singleObjectiveDetail.parents
        : isObject(singleObjectiveDetail.parents) &&
          !Array.isArray(singleObjectiveDetail.parents)
        ? [singleObjectiveDetail.parents]
        : []
    );
  };

  const getDefaultGoalData = async () => {
    let parentData = [singleObjectiveDetail];

    const { start, end } = getDate(parentData[0]);

    const defaultMetricId = getDefaultMetric();

    const measurement = getDefaultMeasurement(defaultMetricId);

    const goalType = parentData?.[0]?.teamId ? 'team_goal' : type;
    const isAllowedObjectiveCategory =
      objectiveWeightTypeOptions?.includes(goalType);

    const defaultObjectiveCategory = getDefaultObjectiveCategory(parentData);
    const parentIds =
      parentData?.length > 0
        ? parentData?.map((data) => data?.id)
        : typeof parentData === 'object'
        ? [parentData?.id]
        : [];

    const teamId = parentData?.[0]?.teamId || null;

    let tempObjective = {
      type: type,
      name: '',
      isPrivate: defaultObjectiveVisibilityOption?.includes('private'),
      isCompanyGoal: false,
      isProject,
      weight:
        (useDefaultObjectiveCategory &&
          objectiveWeightType === 'type' &&
          defaultObjectiveCategory?.weight) ||
        0,
      startDate: start,
      dueDate: end,
      involvements: isProject
        ? await getDefaultInvolvementProject(teamId)
        : getDefaultInvolvementObjective(),
      tags: [],
      parentIds: parentIds,
      parents: parentData,
      complexity: 1,
      measurement,
      teamId,
      grouped: true,
      permissions: defaultPermissions,
      description: '',
      calculationType: 'normal'
    };

    if (objectiveMilestonesFeature && type !== 'task') {
      Object.assign(tempObjective, {
        milestones: getMilestoneDefault(),
        milestoneType: 'disabled',
        milestoneMode: 'sum_value'
      });
    }

    if (defaultObjectiveCategory && isAllowedObjectiveCategory) {
      tempObjective.objectiveCategoryId = defaultObjectiveCategory.id;
    }

    if (manager?.id && !team) {
      const newReviewer = {
        role: isTeam ? 'reviewer' : 'assigner',
        user: manager,
        userId: manager?.id,
        visible: true
      };
      tempObjective.involvements.push(newReviewer);
    }

    if (params?.type == 'addtask' && parentData && parentData?.type != 'task') {
      const { start: varStart, end: varEnd } = getDefaultDateTask(start, end);
      tempObjective.startDate = varStart;
      tempObjective.dueDate = varEnd;
    }
    setParents(parentData);
    setSingleObjectiveAction(tempObjective);
  };

  const getDefaultDateTask = (start, end) => {
    const today = dayjs();
    let finalStart = formatTimezone(today.format('YYYY-MM-DD'), 'start');
    let finalEnd = formatTimezone(
      dayjs(finalStart).add(7, 'day').endOf('day').format('YYYY-MM-DD'),
      'end'
    );
    if (today > dayjs(end) || today < dayjs(start)) {
      finalStart = formatTimezone(dayjs(start).format('YYYY-MM-DD'), 'start');
      finalEnd = formatTimezone(
        dayjs(finalStart).add(7, 'day').endOf('day').format('YYYY-MM-DD'),
        'end'
      );
    }
    if (dayjs(finalEnd) > dayjs(end)) {
      finalEnd = end;
    }
    return { start: finalStart, end: finalEnd };
  };

  const getMetrics = async () => {
    let list = [];
    if (metricOptions.includes('all')) {
      const { data } = await getListMeasurement();
      if (data) {
        list.push(...data);
      }
    }
    if (metricOptions.includes('no_metric')) {
      list.unshift({
        id: 0,
        description: 'No Metrics',
        unit: '',
        unitIcon: null
      });
    }
    setListMetrics(list);
  };

  const getPriorities = async () => {
    const { data } = await getObjectivePriorities();
    if (data) {
      setListPriorities(data);
    }
  };

  const getTypes = async () => {
    const { data } = await getObjectiveCategory();
    if (data) {
      setObjectiveCategories(data);
    }
  };

  // fetch All Data needed for sidebar
  useEffect(() => {
    if (isFetching) return;

    setIsParent(state);
    setIsLoadDataCompleted(false);
    (async () => {
      if (params?.type) {
        if (listMetrics.length === 0) {
          await getMetrics();
        }

        if (
          singleObjectiveDetail?.type === 'task' &&
          listPriorities.length === 0
        ) {
          await getPriorities();
        }

        if (
          objectiveCategories.length === 0 &&
          objectiveWeightType === 'type'
        ) {
          await getTypes();
        }

        setIsLoadDataCompleted(true);
      }
    })();

    reloadFunction && reloadFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveId, params.type, reloadSidebar, isFetching]);

  const objectiveToFetchConfigs =
    params?.type == 'addgoal'
      ? singleObjectiveDetail
      : singleObjectiveDetail?.parents?.[0];

  const enableConfigs =
    params?.type == 'addgoal'
      ? true
      : isParent !== undefined && !isParent?.isParentObjective;

  const fetchObjectiveConfigs = async () => {
    const params = objectiveToFetchConfigs?.organization?.id
      ? { organizationId: objectiveToFetchConfigs?.organization?.id }
      : {};
    const { data } = await getObjectiveConfigs(
      objectiveToFetchConfigs?.id,
      params
    );
    if (data) {
      setObjectiveConfigs(data);
    }
  };

  useQuery(
    ['objectiveConfigsSidebar', objectiveToFetchConfigs?.id],
    fetchObjectiveConfigs,
    {
      enabled: enableConfigs && objectiveToFetchConfigs?.id !== undefined
    }
  );

  useEffect(() => {
    if (!isLoadDataCompleted || isFetching) return;

    if (params?.type === 'edit') {
      getGoalData();
    } else {
      getDefaultGoalData();
    }
    // eslint-disable-next-line
  }, [isLoadDataCompleted, isFetching]);

  const ready = params?.type
    ? isLoadDataCompleted && singleObjectiveAction
    : !isFetching;

  return (
    <React.Fragment>
      {!ready ? (
        <SidebarSkeleton />
      ) : params?.type ? (
        <SidebarCreate
          singleObjectiveAction={singleObjectiveAction}
          parents={parents}
          objectiveCategories={objectiveCategories}
          objectiveConfigs={objectiveConfigs}
          listMetrics={listMetrics}
          listPriorities={listPriorities}
          isParent={isParent}
          singleObjectiveDetail={singleObjectiveDetail}
          saveCallback={saveCallback}
          team={team}
        />
      ) : (
        <SidebarDetail
          singleObjectiveDetail={singleObjectiveDetail}
          saveCallback={saveCallback}
          objectiveConfigs={objectiveConfigs}
        />
      )}
    </React.Fragment>
  );
}

export default Sidebar;

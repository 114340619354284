import React from 'react';

import { getNested } from 'utils/HelperUtils';

import Avatar from 'components/shared/Avatar';
import SVGIcon from 'components/shared/SVGIcon';

const labelList = (selectedValue, props = {}) => {
  const { labelText, onChange: handleCancel, isAbleToDelete } = props;

  return (
    selectedValue.length > 0 && (
      <>
        <section className="flex overflow-auto px-[16px] pb-[12px]">
          {selectedValue.map((value, id) => {
            let text =
              typeof value === 'object' ? getNested(value, labelText) : value;

            return (
              <div key={id} className="flex items-center">
                <span className="mr-[4px] bg-n-300 py-[2px] px-[4px] rounded-[4px] typography-h100 text-n-800">
                  {text}
                </span>
                {isAbleToDelete && (
                  <SVGIcon
                    iconName="icon-clear"
                    size="16"
                    fillColor="var(--n-000)"
                    customClass="bg-n-600 rounded-[16px]"
                    onClick={() => handleCancel(value, true)}
                  />
                )}
              </div>
            );
          })}
        </section>
      </>
    )
  );
};

const initialNameList = (selectedValue, props = {}) => {
  const {
    imgSize,
    nameSrc,
    profilePictureSrc,
    onChange: handleCancel,
    isAbleToDelete
  } = props;

  return (
    selectedValue.length > 0 && (
      <>
        <section
          data-cy="reviewer-wrapper"
          className="flex overflow-auto px-[16px] pb-[16px] items-center border-bottom-n-400"
        >
          {selectedValue.map((value, index) => {
            const name = getNested(value, nameSrc);
            const profilePicture = getNested(value, profilePictureSrc);
            return (
              <div key={index} className="relative inline-block">
                <Avatar
                  name={name}
                  src={profilePicture}
                  className={'mr-[12px]'}
                  size={imgSize}
                />
                {isAbleToDelete && (
                  <SVGIcon
                    iconName="icon-clear"
                    size="16"
                    fillColor="var(--n-000)"
                    customClass="bg-n-600 rounded-[16px] absolute right-[6px] top-[0px]"
                    onClick={() => handleCancel(value, true)}
                  />
                )}
              </div>
            );
          })}
        </section>
      </>
    )
  );
};

const HeaderItemTemplates = {
  'label-list': labelList,
  'img-init-name-list': initialNameList
};

export default HeaderItemTemplates;
